import { LandingPageComponent } from '../shared/landing-page/landing-page.component';
import { RegisterFromInviteComponent } from './register-from-invite/register-from-invite.component';
import { Component, NgModule } from '@angular/core';
import { RegisterComponent } from './register/register.component';
import { AuthMainComponent } from './auth-main/auth-main.component';
import { Routes, RouterModule } from '@angular/router';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { LoginComponent } from './login/login.component';
import { RegisterAdminComponent } from './register-admin/register-admin.component';
import { OauthRegistrationComponent } from './oauth-registration/oauth-registration.component';
import { MobileRegistrationComponent } from './mobile-registration/mobile-registration.component';
import { AccountSetupComponent } from './account-setup/account-setup.component';
import { RegistrationRequestComponent } from '../app/registration-request/registration-request.component';
import { PrivacyPolicyComponent } from '../app/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from '../app/terms-conditions/terms-conditions.component';
import { EmployeeAccountSetupComponent } from './employee-account-setup/employee-account-setup.component';
import { HelpCenterComponent } from '../shared/help-center/help-center.component';
import { GuideDetailsComponent } from '../shared/help-center/guide-details/guide-details.component';
import { ResetPasswordComponent } from '../app/reset-password/reset-password.component';

const routes: Routes = [
  {
    path: 'auth',
    // component: LandingPageComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'login',
        component: LoginComponent,
        pathMatch: 'full',
        data: {
          queryParams: ['email', 'password']
        }
      },
      {
        path: 'register/:id',
        component: RegisterFromInviteComponent
      },
      {
        path:'register',
        component: RegisterFromInviteComponent
      },
      {
        path: 'verify-email',
        component: VerifyEmailComponent
      },
      {
        path: 'verify-email/:id',
        component: VerifyEmailComponent
      },
      {
        path: 'change-password',
        component: ChangePasswordComponent
      },
      {
        path: 'help-center',
        component: HelpCenterComponent
      },
      {
        path:'guide-details',
        component: GuideDetailsComponent
      },
      {
        path: 'register-admin',
        component: RegisterAdminComponent
      },
      {
        path: 'mobile-registration/:id',
        component: MobileRegistrationComponent
      },
      {
        path: 'oauth-registeration',
        component: OauthRegistrationComponent
      },
      {
        path:'account-setup',
        component: AccountSetupComponent
      },
      {
        path:'employeeAccount-setup',
        component: EmployeeAccountSetupComponent
      },
      {
        path:'registration-request',
        component: RegistrationRequestComponent
      },
      {
        path:'privacy-policy',
        component:PrivacyPolicyComponent
      },
      {
        path: 'terms-conditions',
        component: TermsConditionsComponent
      },
      {
        path:'reset-password/:id',
        component: ResetPasswordComponent
      },
      // {
      //   path: 'agreement',
      //   component:AgreementComponent
      // }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class AuthRoutingModule { }
