import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { UserService } from 'src/app/services/firebase/user.service';
import { EmployeeServicesService } from 'src/app/services/firebase/employee-services.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { Location} from '@angular/common';
import { ImageModel, ImageUploaderResponseModel } from 'src/app/models/ImageModel';
import { environment } from 'src/environments/environment';
import { ProductsServiceMenuService } from 'src/app/services/api/data.service';
import { PermissionService } from 'src/app/services/firebase/permission.service';
import { HelperService } from 'src/app/services/helper/helper';
import { ScheduleService } from 'src/app/services/firebase/employee-schedule.service';

@Component({
  selector: 'app-edit-service',
  templateUrl: './edit-service.component.html',
  styleUrls: ['./edit-service.component.css']
})
export class EditServiceComponent implements OnInit {
  editServiceForm: UntypedFormGroup;
  itemUnderCategoryForm: UntypedFormGroup;
  currentUser: any;
  model: any;
  binDoc = undefined;
  base64Image = undefined;
  imgErrorMsg: any;
  serviceId: any;
  service: any;
  hasTimer: any;
  employees=[]
  isMobile = false;
  mobileBase64Image: any;
  appSetting: any;
  // modal
  deleteModalOpen = false;
  deleteServiceUnderModalOpen = false;
  shareModalOpen = false;
  cancelModalOpen = false;
  editModalOpen = false;
  cropModalOpen = false;
  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  isList;
  servicesName
  preview = false;
  modelToPreview: any;
  haveAccessPermission = false;
  permission: any;
  numberPattern = /^[-+]?(\d*\.\d+|\d+\.|\.\d+|\d+)([eE][-+]?\d+)?$/;
  imageChangedEvent: any = '';
  videoUpload: File;
  attachedVideo: any;
  permMember: any;
  permPES: any;
  permAnalytics:any;
  permSocial:any;
  permSpecial:any;
  perm:any;
  pricing= '';
  createItemUnderServiceModal = false;
  editItemUnderServiceModal = false;
  serviceCategoryUnderToBeEdited: any;
  useBase64=false;
  allServices = [];
  uploadFileModalOpen = false;
  mediaList = [];
  returnedMediaLinks = {
    imageURL: '',
    videoUrl: ''
  };
  serviceUnderToBeDeleted: any;
  doesAnyoneHaveSchedule = false;
  currency: any;
  employeeScheduleIDs = [];
  selectedEmployeeName = [];
  canTakeAppointment = false;

  constructor(private fb: UntypedFormBuilder,
              private empService: EmployeeServicesService,
              private authService: AuthService,
              private route: ActivatedRoute,
              private location: Location,
              private userService: UserService,
              private productsServiceMenuService:ProductsServiceMenuService,
              private appSettingsService: AppSettingsService,
              private router: Router,
              private permissionService: PermissionService,
              private helperService: HelperService,
              private scheduleService: ScheduleService
              ) {
                if (typeof window['Capacitor'] !== 'undefined' && window['Capacitor']['platform'] !== 'web' ) {
                  this.isMobile = true;
                }
              }

  ngOnInit(): void {
    this.currentUser = this.authService.getGlobalUser();
    this.route.paramMap.subscribe(params => {
      this.serviceId = params.get('id');
    });
    if (this.currentUser){
      if (this.currentUser.role === 'Employee'){
        this.permissionService.getPermissionByEmployeeId(this.currentUser.uid).valueChanges().subscribe((perm: any) => {
          if (perm.length > 0){
            //this.permission = perm[0].permission[0];
            this.perm = perm[0]; 
             this.permMember = this.perm.permission[0]
             this.permPES = this.perm.permission[1]
             this.permAnalytics = this.perm.permission[2];
             this.permSocial = this.perm.permission[3];
             this.permSpecial = this.perm.permission[4];
            if (this.permPES.createEvents){
              this.haveAccessPermission = true;
            }
          }
      })
      }
      if (this.currentUser.role === 'Admin'){
        this.haveAccessPermission = true;
      }
    }

    this.productsServiceMenuService.servicesName.subscribe(p=>{
      this.servicesName=p
    })
    this.productsServiceMenuService.currencyName.subscribe(c => {
      this.currency = c;
    });

    this. editServiceForm = this.fb.group({
      serviceName: ['', Validators.required],
      serviceDescription: [''],
      imgLink: [''],
      videoLink: [''],
      pdfLink: [''],
      shareWithPublic: [true],
      sharedLink: [''],
      serviceHour: [0, [Validators.min(0), Validators.max(23)]],
      serviceMinute: [0, [Validators.min(0), Validators.max(59)]],
      price: ['', Validators.pattern(this.numberPattern)],
      titleColor: [''],
      backgroundColor: [''],
      assignedTo: this.fb.array([]),
      serviceOffers: this.fb.array([]),
      hasAppointment: [],
      hasPrice: [],
      showEmployees: [],
      bufferTime: [0],
      hasSubServices: [, Validators.required],
      commentable: [true]
    });
    this.itemUnderCategoryForm = this.fb.group({
      serviceTitle: new UntypedFormControl('', Validators.required),
      serviceUnderDescription: new UntypedFormControl(''),
      servicePrice: new UntypedFormControl('', Validators.pattern(this.numberPattern)),
      hasAppointment: [],
      serviceHour: [0, [Validators.min(0), Validators.max(23)]],
      serviceMinute: [0, [Validators.min(0), Validators.max(59)]],
      bufferTime: [0],
      assignedTo: this.fb.array([]),
      showEmployees: [],
    });

    this.empService.getServiceById(this.serviceId).valueChanges().subscribe(service => {
      if (service.length > 0){
        this.service = service[0];
        this.service.hasSubServices = this.service.hasSubServices? this.service.hasSubServices : (this.service.hasAppointment? false: true)
        if (this.service.serviceHour > 0 && this.service.serviceMinute){
          this.hasTimer = true;
        }
        this.allServices = this.service.allServices ? this.service.allServices : [];
        this.editServiceForm.patchValue({
          serviceName: this.service.serviceName,
          serviceHour: this.service.serviceHour,
          serviceMinute: this.service.serviceMinute,
          serviceDescription: this.service.serviceDescription,
          imgLink: this.service.imgLink,
          videoLink: this.service.videoLink,
          pdfLink: this.service.pdfLink,
          shareWithPublic: this.service.shareWithPublic,
          sharedLink: this.service.sharedLink,
          serviceTime: this.service.serviceTime,
          price: this.service.price,
          titleColor: this.service.titleColor,
          backgroundColor: this.service.backgroundColor,
          hasAppointment: this.service.hasAppointment ? this.service.hasAppointment : false,
          hasSubServices: this.service.hasSubServices,
          hasPrice: this.service.hasPrice ? this.service.hasPrice : true,
          showEmployees: 'showEmployees' in this.service ? this.service.showEmployees : true,
          bufferTime: this.service.bufferTime ? this.service.bufferTime : 5,
          commentable: this.service.commentable ? this.service.commentable : true,
          serviceOffers: this.service.serviceOffers.forEach((x) => {
            if (!this.serviceOffers.value.includes(x)){
              this.serviceOffers.push(new UntypedFormControl(x));
            }
          })
        });
      }
    });

    this.scheduleService.getAllSchedules().valueChanges().subscribe(schs => {
      if (schs.length > 0) {
        this.doesAnyoneHaveSchedule = true;
        schs.forEach((sch: any) => {
          this.userService.getUserById(sch.employeeId).valueChanges().subscribe((user: any) => {
            if(user.length > 0){ 
              if(this.employees.filter(emp => emp.uid==user[0].uid).length == 0){
                this.employees.push(user[0]);
                // this.assignedTo.push(this.newEmployee(user[0]));
              }
            }
          })
          this.employeeScheduleIDs.push(sch.employeeId);
        })
      }
    })

    this.appSettingsService.getAppSettingsList().valueChanges().subscribe((settings) => {
      if (settings.length > 0){
        this.appSetting = settings[0];
        this.canTakeAppointment = this.appSetting.takeAppointment;
      }
    });
  }

  get serviceName(){return this. editServiceForm.get('serviceName'); }
  get sharedLink(){return this. editServiceForm.get('sharedLink'); }
  get serviceDescription(){return this. editServiceForm.get('serviceDescription'); }
  get hasAppointment(){return this.itemUnderCategoryForm.get('hasAppointment'); }
  get imgLink(){return this. editServiceForm.get('imgLink'); }
  get pdfLink(){return this. editServiceForm.get('pdfLink'); }
  get showEmployees(){return this.itemUnderCategoryForm.get('showEmployees'); }
  get showEmployeesS(){return this.editServiceForm.get('showEmployees'); }
  get hasPrice(){return this.editServiceForm.get('hasPrice'); }
  get bufferTime(){return this.editServiceForm.get('bufferTime'); }
  get price(){ return this.editServiceForm.get('price'); }
  get serviceHour(){return this.itemUnderCategoryForm.get('serviceHour'); }
  get serviceMinute(){return this.itemUnderCategoryForm.get('serviceMinute'); }
  get hasSubServices() { return this.editServiceForm.get('hasSubServices'); }
  get hasAppointmentS() { return this.editServiceForm.get('hasAppointment'); }
  get bufferTime2() { return this.editServiceForm.get('bufferTime'); }
  // get servcieEmployees() {
  //   return (<FormArray>this.editServiceForm.get('servcieEmployees'))
  // }
  get assignedTo() { return (this.editServiceForm.get('assignedTo')); }
  get serviceOffers() { return (<UntypedFormArray>this.editServiceForm.get('serviceOffers')); }
  get serviceTitle(){ return this.itemUnderCategoryForm.get('serviceTitle'); }
  get serviceUnderDescription(){return this.itemUnderCategoryForm.get('serviceUnderDescription'); }
  get servicePrice(){return this.itemUnderCategoryForm.get('servicePrice'); }

  newEmployee(employee, value = false) {
    return this.fb.group({
      employee: [employee],
      value: [value]
    })
  }

  updateService(value: any){
    if(!value.hasSubServices){
      this.allServices = []
      this.pricing = 'dontInclude'
    }
    this.editModalOpen = false;
    this.service.serviceName = value.serviceName;
    this.service.serviceDescription = value.serviceDescription;
    this.service.sharedLink = value.sharedLink;
    this.service.shareWithPublic = value.shareWithPublic;
    // this.service.price = value.price;
    this.service.serviceOffers = value.serviceOffers;
    this.service.serviceMinute = value.serviceMinute || 0;
    this.service.serviceHour = value.serviceHour || 0;
    this.service.hasAppointment = value.hasAppointment;
    this.service.hasPrice =  this.service.hasPrice ? this.service.hasPrice : value.price > 0
    this.service.showEmployees = this.service.hasAppointment ? value.showEmployees : false;
    this.service.backgroundColor = value.backgroundColor || '';
    this.service.titleColor = value.titleColor || '';
    this.service.allServices = this.allServices;
    this.service.commentable = value.commentable;
    this.service.reactions = this.service.reactions ? this.service.reactions: {    likes: {
      count: 0,
      userId: [],
      usernames: []
    }}
    this.service.comments = this.service.comments ? this.service.comments : []
    if(this.pricing == 'paid'){
      this.service.price= value.price;
      this.service.hasPrice = true;
    }else if(this.pricing == 'dontInclude'){
      this.service.hasPrice= false;
      this.service.price = 0;
    }
    if(this.service.showEmployees){
      this.service.assignedTo = value.assignedTo.reduce((res, p) => {
        if (p.value) {res.push(p.employee.uid); }
        return res;
      }, []);
    }else{
      this.service.assignedTo=[]
    }
    if(this.service.hasPrice){
      this.service.price=value.price
    }

    if (this.service.serviceMinute === 0 && this.service.serviceHour === 0){
      this.service.serviceTime = 0;
    }else{
      this.service.serviceTime = `${this.service.serviceHour}:${this.service.serviceMinute}`;
    }

    if(this.mediaList.length > 0){
      this.uploadFileModalOpen = true;
    } else {
      this.saveUpdate(this.service)
    }

  }
  getInnerText(htmlString){
    this.helperService.getInnerText(htmlString);
  }
  saveUpdate(service){
    this.empService.updateService(service).then(() => {
      this.toast({html: `Successfully  edited a ${this.servicesName}`, classes: 'green', type: 'success'});
      }).catch((err) => {
        this.toast({html: err, classes: 'red', type: 'success'});
      });
  }

  backClicked(){
    this.location.back();
  }

  handleVideoInput(files: FileList) {
    this.binDoc = files[0];
    this.videoUpload = files[0]
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.attachedVideo = reader.result as string;
    };
    this.mediaList.push({type:'Video', file: this.videoUpload});
  
  }

  validateImage(name: any) {
    return this.helperService.validateImage(name);
  }

  deleteService(){
    this.closeDeleteModal();
    this.empService.deleteService(this.service).then(() => {
      this.toast({html: `${this.servicesName} deleted successfully`, classes: 'red', type: 'failure'});
    });
  }

  hasTime(){
    this.hasTimer = true;
  }
  hasNoTime(){
    this.hasTimer = false;
  }

  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
      this.redirectToServices();
    }, 2000);
  }
  redirectToServices(){
    this.router.navigate(['/admin/services']);
  }
  openDeleteModal(){
    this.deleteModalOpen = true;
  }
  closeDeleteModal(){
    this.deleteModalOpen = false;
  }
  openShareModal(){
    this.shareModalOpen = true;
  }
  closeShareModal(){
    this.shareModalOpen = false;
  }
  closeCancelModal(){
    this.cancelModalOpen = false;
  }
  closeEditModal(){
    this.editModalOpen = false;
  }
  previewChanges(value){
    this.modelToPreview = {};
    this.modelToPreview.serviceName = value.serviceName;
    this.modelToPreview.serviceDescription = value.serviceDescription;
    this.modelToPreview.sharedLink = value.sharedLink;
    this.modelToPreview.shareWithPublic = value.shareWithPublic;
    // this.modelToPreview.price = value.price;
    this.modelToPreview.serviceOffers = value.serviceOffers;
    this.modelToPreview.serviceMinute = value.serviceMinute || 0;
    this.modelToPreview.serviceHour = value.serviceHour || 0;
    this.modelToPreview.hasAppointment = value.hasAppointment;
    this.modelToPreview.hasPrice = value.hasPrice;
    // this.modelToPreview.showEmployees = this.service.hasAppointment ? value.showEmployees : false;
    this.modelToPreview.backgroundColor = value.backgroundColor || '';
    this.modelToPreview.titleColor = value.titleColor || '';
    this.modelToPreview.bufferTime = value.bufferTime ? value.bufferTime : 0
    this.modelToPreview.assignedTo = [];
    this.modelToPreview.allServices = this.allServices;
    if (this.base64Image){
      this.modelToPreview.imgLink = this.base64Image;
    }
    if (this.binDoc){
      this.modelToPreview.videoLink = this.binDoc;
    }
    this.preview = true;
  }
  onImageUpload(response:ImageUploaderResponseModel) {
    if (response.error){
      this.imgErrorMsg = response.errorMessage
      return
    }

    this.binDoc = response.binDoc;
    this.imageChangedEvent = 'data:image/jpeg;base64,' + response.imgBase64;
    this.mobileBase64Image = response.imgBase64;
    this.useBase64=true
    this.cropModalOpen = true;

  }
  fileChangeEvent(event){
    this.imageChangedEvent = event;
    // this.cropModalOpen = true;

    if (!this.validateImage(event[0].name)) {
      this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';
      return;
    }
    this.binDoc = event[0];
    const reader = new FileReader();
    reader.readAsDataURL(event[0]);
    reader.onload = () => {
      this.base64Image = reader.result as string;
      this.useBase64 = true;
      this.processCroppedImage(this.base64Image);
    };
  }
  closeCroppingModal(){    
    this.cropModalOpen = false;
    this.useBase64 = false;
  }

  processCroppedImage(event){
    this.cropModalOpen = false;
    this.useBase64 = false;
    this.base64Image = event;
    this.binDoc = this.base64Image;
    let base64result = this.base64Image.split(',')[1];
    const img = new ImageModel();
    if(this.isMobile){
      img.imgBase64 = this.mobileBase64Image;
      this.mediaList.push({type:'Image', file: img});
    }else{
      img.imgBase64 = base64result;
      this.mediaList.push({type:'Image', file: img});
    }
  }
  pricingSet(price: string){
    this.pricing = price
  }

  getTheUrlsSaved(service){
    this.returnedMediaLinks = service;
    this.uploadFileModalOpen = false;
    this.service.imgLink = this.returnedMediaLinks.imageURL;
    this.service.videoLink = this.returnedMediaLinks.videoUrl;
    this.saveUpdate(this.service)
  }

  addNewServiceDetails(){
    this.createItemUnderServiceModal = true;
    this.clearUnderCategoryForm();
  }
  removeServiceUnder(){
    this.allServices = this.allServices.filter(s => s.serviceTitle != this.serviceUnderToBeDeleted.serviceTitle);
    this.deleteServiceUnderModalOpen = false;
  }
  editServiceUnder(serviceUnder){
    this.serviceCategoryUnderToBeEdited = serviceUnder;
    this.itemUnderCategoryForm.patchValue({
      serviceTitle: serviceUnder.serviceTitle,
      serviceUnderDescription: serviceUnder.serviceUnderDescription,
      servicePrice: serviceUnder.servicePrice,
      hasAppointment: serviceUnder.hasAppointment,
      bufferTime: serviceUnder.bufferTime,
      serviceHour: serviceUnder.serviceHour,
      serviceMinute: serviceUnder.serviceMinute,
      showEmployees: serviceUnder.showEmployees,
      assignedTo: serviceUnder.assignedTo
    });

    this.editItemUnderServiceModal = true;
  }
  addNewCategoryUnder(){
    // this.allServices.forEach(item => {
    //   if(item.serviceTitle===this.serviceTitle.value){
    //     this.toast({html: 'Item Exists', classes: 'red', type: 'failure'});
    //   }
    //   else {
      let assignedToEmp = [];
      this.assignedTo.value.forEach(element => {
        if (element.value) {
          assignedToEmp.push(element);
        }
      });
      if(this.serviceTitle.value){
        this.allServices.push({
          serviceTitle: this.serviceTitle.value,
          serviceUnderDescription: this.serviceUnderDescription.value,
          servicePrice: this.servicePrice.value,
          hasAppointment: this.hasAppointment.value,
          serviceHour: this.serviceHour.value,
          serviceMinute: this.serviceMinute.value,
          bufferTime: this.bufferTime.value,
          assignedTo: this.assignedTo.value,
          showEmployees: this.showEmployees.value,
        });
      }
    //   }
    // });

    this.createItemUnderServiceModal = false;
    this.itemUnderCategoryForm.patchValue({
      serviceTitle: '',
      serviceUnderDescription: '',
      servicePrice: ''
    })
    this.clearUnderCategoryForm();
  }
  saveUpdateToCategoryUnder(){
    // Filter the first one
    this.allServices = this.allServices.filter(s => s.serviceTitle != this.serviceCategoryUnderToBeEdited.serviceTitle);

    if(this.serviceTitle.value){
      this.allServices.push({
        serviceTitle: this.serviceTitle.value,
        serviceUnderDescription: this.serviceUnderDescription.value,
        servicePrice: this.servicePrice.value
      });
    }

    this.itemUnderCategoryForm.patchValue({
        serviceTitle: '',
        serviceUnderDescription: '',
        servicePrice: ''
    })
    this.editItemUnderServiceModal = false;
  }

  cancelCategoryUnder(){
    this.createItemUnderServiceModal = false;
    this.editItemUnderServiceModal = false;
  }
  removeImage(){
    this.base64Image = undefined;
    this.service.imgLink = '';
    this.mediaList = this.mediaList.filter((file)=> {
      if(file.type !== 'Image'){
        return file;
      }
    })
  }
  removeVideo(){
    this.videoUpload = undefined;
    this.attachedVideo = undefined;
    this.service.videoLink = '';
    this.mediaList = this.mediaList.filter((file)=> {
      if(file.type !== 'Video'){
        return file;
      }
    })
  }

  confirmToRemove(serviceUnder){
    this.serviceUnderToBeDeleted = serviceUnder;
    this.deleteServiceUnderModalOpen = true;
  }
  hasSubServiceClicked(value){
    if(value){
      this.editServiceForm.controls["hasAppointment"].clearValidators();
      this.editServiceForm.controls["hasAppointment"].updateValueAndValidity();
    } else{
        if (this.appSetting.takeAppointment){
          this.editServiceForm.controls["hasAppointment"].setValidators(Validators.required);
          this.editServiceForm.controls["hasAppointment"].updateValueAndValidity();    
        }
    }
  }
  clearUnderCategoryForm(){
    this.itemUnderCategoryForm.patchValue({
      serviceTitle: '',
      serviceUnderDescription: '',
      servicePrice: '',
      hasAppointment: [],
      serviceHour: 0,
      serviceMinute: 0,
      bufferTime: 0,
      assignedTo: [],
      showEmployees: false,
    });


  }
  employeeSelected(employee) {
    if(employee.value.value){
      this.selectedEmployeeName.push(employee.value.employee);
    } else{
      this.selectedEmployeeName = this.selectedEmployeeName.filter(sen =>{
        return sen.uid != employee.value.employee.uid
      })
    }
  }
  savingItem(event){
    this.allServices.push(event);
    this.createItemUnderServiceModal = false;
    this.editItemUnderServiceModal = false;
  }

  editItem(event){
    // Filter the first one
    this.allServices = this.allServices.filter(s => s.serviceTitle != this.serviceCategoryUnderToBeEdited.serviceTitle);

    if(this.serviceTitle.value){
      this.allServices.push(event);
    }
    
    this.createItemUnderServiceModal = false;
    this.editItemUnderServiceModal = false;
  }
}
