import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { EnvironmentService } from 'src/app/services/helper/env';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-page-selection',
  templateUrl: './page-selection.component.html',
  styleUrls: ['./page-selection.component.css']
})
export class PageSelectionComponent implements OnInit {

  @Output() pageSelected = new EventEmitter<string>();
  @Output() closeModal = new EventEmitter()
  @Output() pageUpdated = new EventEmitter()
  @Input() firstPageSelection 

  pageSections: any;
  fromFNLeaders=false
  isNationApp = environment.firstNation.name == "FNLeaders" ? true : false;
  elements = [
    // {
    //   originalName: 'seo',
    //   title: 'Search Engine Optimization', 
    //   isEnabled: false
    // } ,
    {
      originalName: 'employee',
      title: 'Employees', 
      isEnabled: false
    },
    {
      originalName: 'appointment',
      title: 'Take Appointment', 
      isEnabled: false
    }, 
    {
      originalName: 'about',
      title: 'About Us', 
      isEnabled: false
    },

    {
      originalName: 'event',
      title: 'Events', 
      isEnabled: false
    },
    {
      originalName: 'blog',
      title: 'Blogs/News', 
      isEnabled: false
    },
    {
      originalName: 'audience',
      title: 'Send Email/SMS', 
      isEnabled: false
    },
    {
      originalName: 'faq',
      title: 'FAQ',
      isEnabled: false
    },
    {
      originalName: 'custom',
      title: 'Custom Pages', 
      isEnabled: false
    },
    {
      originalName: 'socialMediaPage',
      title: 'Social Media Integration', 
      isEnabled: false
    },

  ]
  selectedPages = []
  section: any;
  allPages = false
  constructor(private settingsService: AppSettingsService,
              private envService: EnvironmentService,) {
    this.getSettings()
    this.fromFNLeaders = this.envService.fromFNLeaders
    
    if(!this.fromFNLeaders){
      this.elements.splice(3,0,{
        originalName: 'product',
        title: 'Products', 
        isEnabled: false
      }, {
        originalName: 'service',
        title: 'Services', 
        isEnabled: false
      })
    }
   }
  getSettings() {
    this.settingsService.getAppSettingsList().valueChanges().subscribe(hp => {
      if (hp) {
        if (hp.length > 0) {
          this.pageSections = hp[0];
          if(this.pageSections.gettingStarted && this.pageSections.gettingStarted.pageSelection.length !=0){
             this.elements.forEach(el =>{
              this.pageSections.gettingStarted.pageSelection.forEach(element => {
                if(el.originalName == element.originalName){
                  el.isEnabled = element.isEnabled
                }
              });
              
             })
          }

        }
    }})
  }

  ngOnInit(): void {
  }

  selectAll(){
    this.allPages = !this.allPages
    if( this.allPages){
      this.elements.forEach(element => {
        if(this.fromFNLeaders){
          if(element.originalName=='product'){
            element.isEnabled = false;
          }
        }
        element.isEnabled = true
      })
    }else{
      this.elements.forEach(element => {
        element.isEnabled = false
      })
    }
  }
saveChanges(){
  let count = 0
  this.pageSelected.emit()

  if(this.pageSections.gettingStarted && this.pageSections.gettingStarted.pageSelection.length == 0){
    this.pageSections.gettingStarted.pageSelection.push({
      originalName: 'appSetup', 
      isEnabled: true
    }, 
    {
      originalName: 'homePage', 
      isEnabled: true
    })
    this.elements.forEach(element => {
      if(element.originalName == 'product' && this.isNationApp){
        this.pageSections.gettingStarted.pageSelection.push({
          originalName: element.originalName, 
          isEnabled: false
        })
      }
      else{
        this.pageSections.gettingStarted.pageSelection.push({
          originalName: element.originalName, 
          isEnabled: element.isEnabled
        })
  
        if(element.originalName == 'audience'){
          this.pageSections.sendSMS = element.isEnabled
        }else if(element.originalName == 'appointment'){
          this.pageSections.takeAppointment = ''
        }else if((element.originalName == 'product' || element.originalName == 'service') && element.isEnabled){
          if(element.originalName == 'product'){
            this.pageSections.serviceType = 'Product'
          }else if(element.originalName == 'service'){
            this.pageSections.serviceType = 'Service'
          }
          count ++ 
          if( count > 1){
            this.pageSections.serviceType = 'Both'
          }
        }
      }
  
  
    })
    if( count == 0){
      this.pageSections.serviceType = 'None'
    }
  }else{
  this.elements.forEach(el =>{
    this.pageSections.gettingStarted.pageSelection.forEach(element=>{
      if(el.originalName == element.originalName){
        element.isEnabled= el.isEnabled
      }
    })
  })
  }

  this.updateData();  

}
updateData(){
  this.settingsService.updateAppSettings(this.pageSections).then(() => {
    // this.toast({html: 'Option saved successfully.', type: 'success', });
    this.pageUpdated.emit()
  });
}


pageEnabled(page){
  this.elements.forEach(element => {
    if(element.originalName == page.originalName){
      element.isEnabled =  !element.isEnabled
    }
  })
}
closeModalEvent(){
  this.closeModal.emit()
}

}

