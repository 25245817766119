import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent implements OnInit {

  @Input() slides: string[] = [];
  @Input() preview;
  @Input() view;
  @Input() landingPageSettings;
  @Input() intervalDuration
  @Output() carouselImageSelected = new EventEmitter<number>();

  currentSlideIndex: number = 0;
  intervalId: any;
  showPlaceholder = true;

  ImageLoad = false
  images: { src: string, loaded: boolean }[] = []
  constructor() { }

  ngOnInit(): void {

    this.slides.forEach(img => {
      // Check if the image is already in the array
      if (!this.images.some(image => image.src === img)) {
        // If it's not in the array, push it
        this.images.push({ src: img, loaded: false });
      }
    });
    this.startInterval();
  }
  ngOnChanges(changes: SimpleChanges){
    if(changes.slides){
      this.removeDuplicates()
    }
  }
  // ngAfterViewInit() {
  //   this.changeDotColor();
  // }
  nextSlide() {
    this.currentSlideIndex = (this.currentSlideIndex + 1) % this.slides.length;
    // this.changeDotColor();
    this.resetInterval();
}

prevSlide() {
  this.currentSlideIndex = (this.currentSlideIndex - 1 + this.slides.length) % this.slides.length;
  // this.changeDotColor();
  this.resetInterval();
}
  startInterval() {
    if (this.images[this.currentSlideIndex].loaded) { // Start interval only if the image has loaded successfully
        this.intervalId = setInterval(() => {
            this.nextSlide();
        }, this.intervalDuration);
    }
}

  changeDotColor() {
    const dots = document.getElementsByClassName('dot');
    // Remove active status from all dots
    for (let i = 0; i < this.slides.length; i++) {
      if(dots[i]){
        dots[i].classList.remove('bg-customTeal');
        dots[i].classList.add('bg-gray-200');
      }
    }
    // Add active status to the current dot
    if(dots[this.currentSlideIndex]){
      dots[this.currentSlideIndex].classList.remove('bg-gray-200');
      dots[this.currentSlideIndex].classList.add('bg-customTeal');
    }
  }

  resetInterval() {
  // this.changeDotColor();

    clearInterval(this.intervalId);
    this.startInterval();
  }

  imageClicked(){
    this.carouselImageSelected.emit(this.currentSlideIndex) //for edit on the settings page
  }

  imageLoaded(index) {
  if(this.images[index]){
    this.images[index].loaded = true
    this.resetInterval(); // Reset interval after image loaded
  }
  }

  imageError() {
    this.showPlaceholder = true; // Show placeholder if there's an error loading the image
  }

  removeDuplicates() {
    this.slides = this.slides.filter((item, index, self) =>
      index === self.findIndex((t) => (
        t === item
      ))
    );
  }
}
