<div class="bg-white mx-3 md:mx-10 rounded-md p-5 mt-3">

  <form [formGroup]="appSettingsForm" (ngSubmit)="SaveSettings(appSettingsForm.value)">

    <div class="">
      <!-- Title -->
      <div class="font-bold text-lg text-gray-600">
        Application Settings

        <div class="tooltip" data-tip="Change your application name, description, theme and logo.">
          <button type="button"><i class="material-icons">help</i></button>
        </div>
      </div>

      <!-- Settings Content -->
      <div class="md:px-12 px-5 ">
        <!-- App name Section -->
        <div class="mt-6  ">
          <label for="Title" class="block tracking-wide text-gray-700 text-sm font-bold mb-2"> Application Name</label>
          <input
            class="w-full md:w-1/2 appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="Title" type="text" placeholder="Application Name" formControlName="appName">
        </div>

        <div class="mt-6  ">
          <label for="Title" class="block tracking-wide text-gray-700 text-sm font-bold mb-2"> Welcome Message</label>
          <input
            class="w-full md:w-1/2 appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="welcomeMessage" type="text" placeholder="Welcome Message" formControlName="welcomeMessage">
        </div>

        <div class="mt-6">
          <div formArrayName="adminEmail" *ngFor="let inviteEmail of adminEmail.controls; let i=index">
            <label for="email" class="block tracking-wide text-gray-700 text-sm font-bold mb-2"> Admin Email</label>

            <input type="email"
              class="w-full md:w-1/2 appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              placeholder="e.g. email@test.com" [formControlName]="i">
          </div>
          <div class="row">
            <br />
            <div class="col s8 m8 l8">
              <a class="add-email-btn" (click)="addAdminEmail()">
                <i class="material-icons blue-text left">add_circle</i>Add Email</a>
            </div>
          </div>

        </div>

        <!-- Description Section -->
        <!-- <div class="w-full mt-6">
          <label for="Description" class="block text-sm font-bold mb-2 ">Application Description </label>
          <textarea id="Description"
            class="textarea appearance-none h-52 block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            placeholder="Description" formControlName="description"></textarea>
        </div> -->

        <!-- Your product -->
        <div class="flex flex-col md:flex-row my-6">
          <div class="w-full md:w-1/2 flex-col">
            <div class="text-sm font-bold mb-3 w-full">Your product is: </div>
            <p class="hidden md:block ">Please specify the type of application you want
              If it is a business application select Business App
              (If you provide a service, a product or both).</p>
          </div>

          <div class="w-full md:w-1/2 flex">
            <div class="w-1/2 flex">
              <div>
                <div class="form-control">
                  <label class="label cursor-pointer">
                    <input type="radio" class="radio checked:bg-customTeal" formControlName="isBusinessApp" [value]=true
                      (change)="onAppTypeChange()" />
                    <span><strong class="label-text pl-2 font-bold ">Business App</strong></span>
                  </label>
                </div>
              </div>
            </div>
            <div class="w-1/2 flex">
              <div>
                <div class="form-control">
                  <label class="label cursor-pointer">
                    <input type="radio" class="radio checked:bg-customTeal" formControlName="isBusinessApp"
                      [value]=false (change)="onAppTypeChange()" />
                    <span><strong class="label-text pl-2 font-bold ">Personal App</strong></span>
                  </label>
                </div>
              </div>
            </div>
          </div>

        </div>
        <hr />

        <!-- Offerring -->
        <div class="flex flex-col md:flex-row my-6" *ngIf="isBusinessApp">
          <div class="w-full md:w-1/2">
            <div>
              <div class="text-sm font-bold mb-3">What are you offering? </div>
              <p class="hidden md:block">Choose what you provide for your customers/users.
                You can choose both Product and Service or
                one of them.</p>
            </div>
          </div>

          <div class="w-full md:w-1/2 flex">

            <div class="w-1/3 flex">
              <div>
                <div class="form-control">
                  <label class="label cursor-pointer">
                    <input type="radio" class="radio checked:bg-customTeal" formControlName="serviceType"
                      value="Product" />
                    <span class="label-text pl-2 font-bold ">Products</span>
                  </label>
                </div>
              </div>
            </div>

            <div class="w-1/3 flex">
              <div>
                <div class="form-control">
                  <label class="label cursor-pointer">
                    <input type="radio" class="radio checked:bg-customTeal" formControlName="serviceType"
                      value="Service" />
                    <span class="label-text pl-2 font-bold ">Services</span>
                  </label>
                </div>
              </div>
            </div>

            <div class="w-1/3 flex">
              <div>
                <div class="form-control">
                  <label class="label cursor-pointer">
                    <input type="radio" class="radio checked:bg-customTeal" formControlName="serviceType"
                      value="Both" />
                    <span class="label-text pl-2 font-bold ">Both</span>
                  </label>
                </div>
              </div>
            </div>
          </div>

        </div>
        <hr />
        <ng-container *ngIf="isBusinessApp && ['Both','Service'].includes(serviceType.value)">
          <!-- Service Name -->

          <div class="flex flex-col md:flex-row my-6">
            <div class="w-full md:w-1/2">
              <div>
                <div class="text-sm font-bold mb-3">What would you like to call the services you provide?</div>
                <p class="hidden md:block">Choose the name you would like to display the service name to your
                  customers/users.</p>
              </div>

            </div>

            <div class="pt-6 w-full md:w-1/3">
              <input
                class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="Title" type="text" placeholder="Application Name" formControlName="serviceName">
            </div>
          </div>
          <hr />

          <!-- Service Category -->
          <!-- <div class="flex flex-col md:flex-row my-6">
            <div class="w-full md:w-1/2">
              <div>
                <div class="text-sm font-bold mb-3">Will you be offering different Categories for your Service?</div>
                <p class="hidden md:block">Choose whether or not you provide different types of catagories for the
                  service
                  you provide for your customers/users.</p>
              </div>
            </div>

            <div class="w-full md:w-1/2 flex">

              <div class="w-1/2 flex">
                <div>
                  <div class="form-control">
                    <label class="label cursor-pointer">
                      <input type="radio" class="radio checked:bg-customTeal" formControlName="hasServicesCategory"
                        [value]=true />
                      <span class="label-text pl-2 font-bold ">Yes</span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="w-1/2 flex">
                <div>
                  <div class="form-control">
                    <label class="label cursor-pointer">
                      <input type="radio" class="radio checked:bg-customTeal" formControlName="hasServicesCategory"
                        [value]=false />
                      <span class="label-text pl-2 font-bold ">No</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <hr /> -->

          <!-- <div class="flex flex-col md:flex-row my-6" *ngIf="hasServicesCategory.value">
            <div class="w-full md:w-1/2">
              <div>
                <div class="text-sm font-bold mb-3">What would you like to call the services category you provide?</div>
                <p class="hidden md:block">Choose the name you would like to display the category name of the services you provide
                  for your customers/users.</p>
              </div>

            </div>

            <div class="pt-6 w-full md:w-1/3">
              <input
                class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="Title" type="text" placeholder="Application Name" formControlName="serviceCategoryName">
            </div>
          </div>
          <hr /> -->
        </ng-container>


        <ng-container *ngIf="isBusinessApp && ['Both','Product'].includes(serviceType.value)">
          <!-- Products Name -->

          <div class="flex flex-col md:flex-row my-6">
            <div class="w-full md:w-1/2">
              <div>
                <div class="text-sm font-bold mb-3">What would you like to call the products you provide?</div>
                <p class="hidden md:block">Choose the name you would like to display the product name to your
                  customers/users.</p>
              </div>

            </div>

            <div class="pt-6 w-full md:w-1/3">
              <input
                class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="Title" type="text" placeholder="Application Name" formControlName="productName">
            </div>
          </div>
          <hr />

          <!-- Products Category -->

          <div class="flex flex-col md:flex-row my-6">
            <div class="w-full md:w-1/2">
              <div>
                <div class="text-sm font-bold mb-3">Will you be offering different Categories for your Products?</div>
                <p class="hidden md:block">Choose whether or not you provide different types of catagories for the
                  products
                  you provide for your customers/users.</p>
              </div>
            </div>

            <div class="w-full md:w-1/2 flex">

              <div class="w-1/2 flex">
                <div>
                  <div class="form-control">
                    <label class="label cursor-pointer">
                      <input type="radio" class="radio checked:bg-customTeal" formControlName="hasProductsCategory"
                        [value]=true />
                      <span class="label-text pl-2 font-bold ">Yes</span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="w-1/2 flex">
                <div>
                  <div class="form-control">
                    <label class="label cursor-pointer">
                      <input type="radio" class="radio checked:bg-customTeal" formControlName="hasProductsCategory"
                        [value]=false />
                      <span class="label-text pl-2 font-bold ">No</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <hr />

          <!-- Products Category Name-->

          <!-- <div class="flex flex-col md:flex-row my-6" *ngIf="hasProductsCategory.value">
            <div class="w-full md:w-1/2">
              <div>
                <div class="text-sm font-bold mb-3">What would you like to call the products category you provide?</div>
                <p class="hidden md:block">Choose the name you would like to display the category name of the provide you provide for your customers/users..</p>
              </div>

            </div>

            <div class="pt-6 w-full md:w-1/3">
              <input
                class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="Title" type="text" placeholder="Application Name" formControlName="productCategoryName">
            </div>
          </div>
          <hr /> -->
        </ng-container>

        <!--ADDED Signup for Subscribers-->
        <div class="flex flex-col md:flex-row my-6">
          <div class="w-full lg:w-1/2">
            <div>
              <div class="text-sm font-bold mb-3">Would you like to verify new users before they can access your app?
              </div>
              <p class="hidden md:block">Please specify the type of Signup application
                you want. If it is a Direct signup application
                or Signup via a registration request </p>
            </div>
          </div>

          <div class="w-full lg:w-1/2 flex">
            <div class="w-1/2 flex">
              <div>
                <div class="form-control">
                  <label class="label cursor-pointer">
                    <input type="radio" class="radio checked:bg-customTeal" formControlName="isDirectSignup"
                      [value]=false (change)="onAppSignupChange()" />
                    <span><strong class="label-text pl-2 font-bold ">Yes</strong></span>
                  </label>
                </div>
              </div>
            </div>
            <div class="w-1/2 flex">
              <div>
                <div class="form-control">
                  <label class="label cursor-pointer">
                    <input type="radio" class="radio checked:bg-customTeal" formControlName="isDirectSignup"
                      [value]=true (change)="onAppSignupChange()" />
                    <span><strong class="label-text pl-2 font-bold ">No</strong></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />

        <!--ADDED Departments for employees to be assigned-->
        <div class="flex flex-col md:flex-row my-6">
          <div class="w-full lg:w-1/2">
            <div>
              <div class="text-sm font-bold mb-3">Do you have more than one department? </div>
              <p class="hidden md:block">Please click yes if you have more than one departments.</p>
            </div>
          </div>

          <div class="w-full lg:w-1/2 flex">
            <div class="w-1/2 flex">
              <div>
                <div class="form-control">
                  <label class="label cursor-pointer">
                    <input type="radio" class="radio checked:bg-customTeal" formControlName="department" [value]=true
                      (change)="onDepartmentChange()" />
                    <span><strong class="label-text pl-2 font-bold ">Yes</strong></span>
                  </label>
                </div>
              </div>
            </div>
            <div class="w-1/2 flex">
              <div>
                <div class="form-control">
                  <label class="label cursor-pointer">
                    <input type="radio" class="radio checked:bg-customTeal" formControlName="department" [value]=false
                      (change)="onDepartmentChange()" />
                    <span><strong class="label-text pl-2 font-bold ">No</strong></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />

        <!-- Appointments -->
        <div class="flex flex-col md:flex-row my-6" *ngIf="isBusinessApp">
          <div class="w-full md:w-1/2">
            <div>
              <div class="text-sm font-bold mb-4">Do you take appointments?
                <span class="tooltip" data-tip="Please specify if you take appointments or not for your services.">
                  <i class="material-icons">help</i>
                </span>
              </div>
              <p class="hidden md:block">Please specify if you take appointments or not.</p>
            </div>
          </div>

          <div class="w-full md:w-1/2 flex">
            <!-- toggle -->
            <div>
              <div class="form-control">
                <label class="label cursor-pointer">
                  <span class="label-text pr-2 font-bold ">YES</span>
                  <input type="checkbox" class="toggle checked:bg-customTeal" checked
                    formControlName="takeAppointment" />

                </label>
              </div>
            </div>
          </div>

        </div>
        <hr />

        <!-- Logo -->
        <div>
          <div class="text-sm font-bold mb-3 mt-4">Upload a logo for your application</div>

          <div class="" *ngIf="!isMobile">
            <div class="flex items-center space-x-6">
              <label class="block">
                <span class="sr-only">Choose photo</span>
                <input type="file" accept="image/*" (change)="handleImageInput($event.target.files)" class="block w-full text-sm text-slate-500
                  file:mr-4 file:py-2 file:px-4
                  file:rounded-full file:border-0
                  file:text-sm file:font-semibold
                  file:bg-violet-50 file:text-violet-700
                  hover:file:bg-violet-100" />
              </label>

            </div>
            <p class="text-red-500 italic text-xs" *ngIf="imgErrorMsg">
              <strong><small><i class="material-icons">error_outline</i> {{imgErrorMsg}}</small></strong>
            </p>
          </div>
          <!-- For mobile -->
          <div class="" *ngIf="isMobile">
            <app-image-uploader class="justify-start items-start"  (onImageUpload)=onImageUpload($event) [customConfig]="{'upload':false}"></app-image-uploader>

            <p class="text-red-500 italic text-xs" *ngIf="imgErrorMsg">
              <strong><small><i class="material-icons">error_outline</i> {{imgErrorMsg}}</small></strong>
            </p>
          </div>
          <!-- Show Logo -->
          <div class="flex flex-wrap my-4" *ngIf="showImageFromServer">


            <div class="relative" *ngIf="oldUrl">
              <img loading="lazy" [src]="oldUrl" class="w-36 h-36 border-2 border-gray-100" alt="Image">
              <button (click)="removeSavedFile()"
                class="absolute bottom-0 right-0 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                <i class="fa fa-trash text-md"></i>
              </button>
            </div>

          </div>


        </div>

        <!-- Theme color -->
        <div class="">
          <p class="text-sm font-bold mb-3 mt-4">Theme color option</p>
          <a class=" col s12 btn bg-gray-100 border-none hover:bg-gray-200 text-black grey lighten-3 black-text"
            style="margin-bottom: 15px;">
            <label>Choose Theme </label> <input formControlName="themeColor" type="color"
              class="themeColorSelector ml-5 h-10 w-10 float-right">
          </a>
        </div>

        <!-- Save button -->
        <div class="">
          <button class="py-2 px-6 mb-10 gap-2 bg-customTeal rounded-md text-white float-right"
            [disabled]="!appSettingsForm.valid" type="submit">
            <i class="material-icons mr-3 float-left">chat_bubble</i>
            Save Updates
          </button>
          <br />
        </div>

      </div>

    </div>

  </form>

</div>

<!-- toast -->
<app-alert class="fixed top-0 flex justify-end w-full" [toastType]="toastType" [toastMessage]="toastMessage"
  [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>
