<div class="flex flex-wrap" *ngIf="notifications">
      <div class="bg-white lg:w-7/12 lg:mx-auto p-5 divide-y divide-dashed"   *ngFor="let notif of notifications">
      <app-notification [notification]="notif"></app-notification>
    </div>

</div>
<div class="mt-5"  *ngIf="!notifications">
  <div class="flex items-center justify-center">
      <img  data-src="../../../../assets/icons/Illustrations/notification Illustration.png" loading="lazy"
      class="block w-64 items-center justify-center">
  </div>
  <div class="text-customTeal text-lg text-center font-bold">You're all caught up</div>
  <div class="text-gray-400 text-sm text-center font-bold">You have no new notifications. Check back later!</div>
</div>

