import { Component, OnInit } from '@angular/core';
// import { LabelType, Options } from '@angular-slider/ngx-slider';
import { environment } from 'src/environments/environment';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, RequiredValidator, Validators } from '@angular/forms';
import { ClientFormService } from 'src/app/services/firebase/client-form.service';
import { ClientAnswersService } from 'src/app/services/firebase/client-answers.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientQuestion, Question, SurveyQuestion } from 'src/app/models/PollsAndSurveyModel';
import { InviteService } from 'src/app/services/firebase/invite.service';
import { EmailService } from 'src/app/services/api/email.service';
import { Location } from '@angular/common';
import { UserService } from 'src/app/services/firebase/user.service';

@Component({
  selector: 'app-create-client',
  templateUrl: './create-client.component.html',
  styleUrls: ['./create-client.component.css']
})
export class CreateClientComponent implements OnInit {


  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  surveyForm: UntypedFormGroup;
  answersArray: UntypedFormArray;
  visibleStepIndex = 0;
  totalQuestions = 0;
  submitDisabled = false;
  showComment = false;
  leaveComment = false;

  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;

  //added
  form: any;
  model: any;
  formGlobal: any;
  names: any;

  currentUser: any;
  userFilled: boolean = false;

  clientGlobal: any;
  invitationPopup: boolean = false;
  id: any;

  inviteGlobal: any;
  host = window.location.hostname !== 'localhost' ? 'https://' + window.location.hostname + '/register-from-invite/' : 'http://localhost:4200/register-from-invite/';

  clientAdded: boolean = false;
  clientPhone: any;

  invite: any;
  value: any;

  isUsa : boolean;
  isCanada: boolean;
  otherCountry: boolean;

  userModel : any;

  // options: Options = {
  //   floor: 0,
  //   ceil: 10,
  //   showTicks: true,
  //   step: 1,
  // }

  userGlobal:any;
  idParticipation: any;

  public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')',
    ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

  public extensionMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ')',
    ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

    countryList = [ "Afghanistan",    "Albania",    "Algeria",    "American Samoa",    "Andorra",    "Angola",    "Anguilla",
    "Antarctica",    "Antigua and Barbuda",    "Argentina",    "Armenia",    "Aruba",    "Australia",    "Austria",
    "Azerbaijan",    "Bahamas (the)",    "Bahrain",    "Bangladesh",    "Barbados",    "Belarus",    "Belgium",    "Belize",
    "Benin",    "Bermuda",    "Bhutan",    "Bolivia (Plurinational State of)",    "Bonaire, Sint Eustatius and Saba",
    "Bosnia and Herzegovina",    "Botswana",    "Bouvet Island",    "Brazil",    "British Indian Ocean Territory (the)",
    "Brunei Darussalam",    "Bulgaria",    "Burkina Faso",    "Burundi",    "Cabo Verde",    "Cambodia",    "Cameroon",
    "Canada",    "Cayman Islands (the)",    "Central African Republic (the)",    "Chad",    "Chile",    "China",
    "Christmas Island",    "Cocos (Keeling) Islands (the)",    "Colombia",    "Comoros (the)",    "Congo (the Democratic Republic of the)",
    "Congo (the)",    "Cook Islands (the)",    "Costa Rica",    "Croatia",    "Cuba",    "Curaçao",    "Cyprus",    "Czechia",
    "Côte d'Ivoire",    "Denmark",    "Djibouti",    "Dominica",    "Dominican Republic (the)",    "Ecuador",    "Egypt",
    "El Salvador",    "Equatorial Guinea",    "Eritrea",    "Estonia",    "Eswatini",    "Ethiopia",    "Falkland Islands (the) [Malvinas]",
    "Faroe Islands (the)",    "Fiji",    "Finland",    "France",    "French Guiana",    "French Polynesia",    "French Southern Territories (the)",
    "Gabon",    "Gambia (the)",    "Georgia",    "Germany",    "Ghana",    "Gibraltar",    "Greece",    "Greenland",    "Grenada",
    "Guadeloupe",    "Guam",    "Guatemala",   "Guernsey",    "Guinea",    "Guinea-Bissau",    "Guyana",    "Haiti",
    "Heard Island and McDonald Islands",    "Holy See (the)",    "Honduras",    "Hong Kong",    "Hungary",    "Iceland",
    "India",    "Indonesia",    "Iran (Islamic Republic of)",    "Iraq",    "Ireland",    "Isle of Man",    "Israel",
    "Italy",    "Jamaica",    "Japan",    "Jersey",    "Jordan",    "Kazakhstan",    "Kenya",    "Kiribati",    "Korea (the Democratic People's Republic of)",
    "Korea (the Republic of)",    "Kuwait",    "Kyrgyzstan",    "Lao People's Democratic Republic (the)",    "Latvia",
    "Lebanon",    "Lesotho",    "Liberia",    "Libya",    "Liechtenstein",    "Lithuania",    "Luxembourg",    "Macao",
    "Madagascar",    "Malawi",    "Malaysia",    "Maldives",    "Mali",    "Malta",    "Marshall Islands (the)",    "Martinique",
    "Mauritania",    "Mauritius",    "Mayotte",    "Mexico",    "Micronesia (Federated States of)",    "Moldova (the Republic of)",
    "Monaco",    "Mongolia",    "Montenegro",    "Montserrat",    "Morocco",    "Mozambique",    "Myanmar",    "Namibia",    "Nauru",
    "Nepal",    "Netherlands (the)",    "New Caledonia",    "New Zealand",    "Nicaragua",    "Niger (the)",    "Nigeria",    "Niue",
    "Norfolk Island",    "Northern Mariana Islands (the)",    "Norway",    "Oman",    "Pakistan",    "Palau",    "Palestine, State of",    "Panama",
    "Papua New Guinea",    "Paraguay",    "Peru",    "Philippines (the)",    "Pitcairn",    "Poland",    "Portugal",    "Puerto Rico",
    "Qatar",    "Republic of North Macedonia",    "Romania",    "Russian Federation (the)",    "Rwanda",    "Réunion",
    "Saint Barthélemy",    "Saint Helena, Ascension and Tristan da Cunha",    "Saint Kitts and Nevis",    "Saint Lucia",
    "Saint Martin (French part)",    "Saint Pierre and Miquelon",    "Saint Vincent and the Grenadines",    "Samoa",
    "San Marino",    "Sao Tome and Principe",    "Saudi Arabia",    "Senegal",    "Serbia",    "Seychelles",    "Sierra Leone",
    "Singapore",    "Sint Maarten (Dutch part)",    "Slovakia",    "Slovenia",    "Solomon Islands",    "Somalia",
    "South Africa",    "South Georgia and the South Sandwich Islands",    "South Sudan",    "Spain",    "Sri Lanka",   
     "Sudan (the)",    "Suriname",    "Svalbard and Jan Mayen",    "Sweden",    "Switzerland",    "Syrian Arab Republic",
    "Taiwan",    "Tajikistan",    "Tanzania, United Republic of",    "Thailand",    "Timor-Leste",    "Togo",    "Tokelau",
    "Tonga",    "Trinidad and Tobago",    "Tunisia",    "Turkey",    "Turkmenistan",    "Turks and Caicos Islands (the)",
    "Tuvalu",    "Uganda",    "Ukraine",    "United Arab Emirates (the)",    "United Kingdom of Great Britain and Northern Ireland (the)",
    "United States Minor Outlying Islands (the)",    "United States of America",    "Uruguay",    "Uzbekistan",    "Vanuatu",    "Venezuela (Bolivarian Republic of)",
    "Viet Nam",    "Virgin Islands (British)",    "Virgin Islands (U.S.)",    "Wallis and Futuna",    "Western Sahara",    "Yemen",    "Zambia",    "Zimbabwe",
    "Åland Islands"
  ];

  province = [  "Ontario", 'Quebec', "Nova Scotia", "Yukon", "Newfoundland and Labrador", "Manitoba", "Sakatchewan", "New Brunswick", 
                "Nunavut", "Alberta", "British Columbia", "Prince Edward Island", "Northwest Territories"
            ];
  
  states = [ "Alabama", "Alaska", "American Samoa", "Arizona", "Arkansas", "California", "Colorado", "Connecticut",
    "Delaware", "District Of Columbia", "Federated States Of Micronesia", "Florida", "Georgia", "Guam",  
     "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Marshall Islands", 
     "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri",  "Montana", "Nebraska", "Nevada",
     "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota",  "Northern Mariana Islands", "Ohio",
     "Oklahoma", "Oregon", "Palau", "Pennsylvania", "Puerto Rico", "Rhode Island", "South Carolina", "South Dakota",
     "Tennessee", "Texas", "Utah", "Vermont", "Virgin Islands", "Virginia",  "Washington",  "West Virginia", "Wisconsin", "Wyoming"
  ];


    participation: any;
    isNationApp = environment.firstNation.Origin == "FNLeaders" ? true : false;

  constructor(private fb: UntypedFormBuilder,
    private clientFormService: ClientFormService,
    private clientAnswerService: ClientAnswersService,
    private authService: AuthService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private inviteService: InviteService,
    private emailService: EmailService,
    private location: Location) { }

  ngOnInit(): void {
    this.currentUser = this.authService.getGlobalUser();
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });

    this.clientGlobal = this.clientFormService.getFormList().valueChanges().subscribe(form => {
      this.model = form[0];
      if (this.model) {
        this.totalQuestions = this.model.questions.length;

        this.model.questions.forEach(question => {
          if (question.answerType === 'Short Answer') {
            this.addAnswer(question);
          } else {
            this.addAnswer2(question);
          }

        });
      }
      this.clientGlobal.unsubscribe();
    })


    this.surveyForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: [''],
      cellPhone: ['', [Validators.required]],
      answerItems: this.fb.array([]),
    });
  }

  addAnswer(questionModel: ClientQuestion) {
    this.answersArray = this.surveyForm.get('answerItems') as UntypedFormArray;
    this.answersArray.push(this.createAnswer(questionModel));
  }

  createAnswer(questionModel: ClientQuestion) {
    return this.fb.group({
      questionNumber: [questionModel.questionNumber],
      question: [questionModel.question],
      questionType: [questionModel.answerType],
      required: [questionModel.required],
      allowMultipleAnswers: [questionModel.allowMultipleAnswers], 
      answer: [''],
    });
  }

  addAnswer2(questionModel: Question) {
    this.answersArray = this.surveyForm.get('answerItems') as UntypedFormArray;
    this.answersArray.push(this.createAnswer2(questionModel));
  }

  createAnswer2(questionModel: Question) {
    let answers = this.fb.array([]);
    questionModel.answers.forEach(x => {
      answers.push(this.addAnswer3(x.answer));
    });
    return this.fb.group({
      questionNumber: [questionModel.questionNumber],
      question: [questionModel.question],
      questionType: [questionModel.answerType],
      required: [questionModel.required],
      allowMultipleAnswers: [questionModel.allowMultipleAnswers],
      answers: answers,
      answer: [''],
    });
  }

  addAnswer3(value) {
    return this.fb.group({
      answer: [value]
    });
  }

  answerSelected(question_index, value) {
    const answerArray = this.surveyForm.get('answerItems') as UntypedFormArray;
    console.log("this is the answer Array from the multiple choice",answerArray.controls[question_index])
    if (answerArray.controls[question_index].get('questionType').value === 'Multiple Choice' && !answerArray.controls[question_index].get('allowMultipleAnswers').value) {
      answerArray.controls[question_index].get('answer').patchValue(value);
    }
    if (answerArray.controls[question_index].get('questionType').value === 'Multiple Choice' && answerArray.controls[question_index].get('allowMultipleAnswers').value) {
      var answer = answerArray.controls[question_index].get('answer').value;
      if (answer == "") answerArray.controls[question_index].get('answer').patchValue(value);
      else {
        const answers: string[] = answer.split(',');
        if (answers.includes(value)) {
          const index = answers.indexOf(value);
          answers.splice(index, 1);
          value = answers.join(' , ');
          //value = [answer].join(',');
        } else {
          value = [answer, value].join(',');
        }
      }
      answerArray.controls[question_index].get('answer').patchValue(value);
    }
  }

  submitClient(){

    console.log("(*************HELOOOOLLOOOLLLOOOO*********")
  }

  submitSurvey(value: any) {
    console.log("the form is being submitted")

    this.submitDisabled = true;

     this.participation = {
      formId: this.model ? this.model.id : '',
      // firstName: value.firstName,
      // lastName: value.lastName,
       cellPhone: value.cellPhone,
      // email: value.email ? value.email : '',
      // date: new Date(),
      answers: []
    };

    this.userModel = {
      uid:  this.userService.createID(),
      GivenName: value.firstName,
      LastName: value.lastName,
      cellPhone: value.cellPhone,
      Email: value.email ? value.email : '',
      DateAdded: new Date(),
      role: 'User',
      verified: false,
      contact: [],
      areas: [],
      lastLogin: new Date(),
      loginCount: 0,
      profileImgLink: '',
      profileImages: []
    }
  //   if(value.email != ''){
  //   this.userService.getUserByEmail(value.email).valueChanges().subscribe((user: any) =>{
  //     if(user && user.length != 0){
  //       this.toast({ html: 'The email already exists !', classes: 'red', type:'failure' });
  //     }else{
  //       this.userService.saveUser(this.userModel);

  //     }
  //   })
  // }
    value.answerItems.forEach(element => {
      const answer = {
        questionNumber: element.questionNumber,
        question: element.question,
        questionType: element.questionType,
        required: element.required,
        answer: element.answer,
        allowMultipleAnswers: element.allowMultipleAnswers
      };
      this.participation.answers.push(answer);
    });
    
    this.clientPhone = this.clientAnswerService.getClientByPhoneNumber(value.cellPhone).valueChanges().subscribe(client => {
      if (client.length != 0) {
        console.log("the client*************",client)
        this.toast({ html: 'This Client has already Registered!', classes: 'red', type:'failure' });
      }
      else{
        
        if (value.email != '') {
          this.userGlobal = this.userService.getUserByEmail(value.email).valueChanges().subscribe((user: any) =>{
            console.log("the user",user)
            if(user && user.length != 0){
              this.toast({ html: 'The email already exists !', classes: 'red', type:'failure' });
              this.invitationPopup = false;
            }else{
              this.userService.saveUser(this.userModel);
              this.invitationPopup = true
            }
            this.userGlobal.unsubscribe();
          })
          this.value = value;
        }
        else {
          this.clientAnswerService.newFormParticipation(this.participation).then(x => {
            let id = this.userModel.uid;
            this.participation.id = id;
            this.toast({ html: 'Your Client Information has Successfully Saved!', classes: 'green' });
            this.submitDisabled = false;
            // this.location.back();
          });
          this.router.navigate(['/admin/search-users']);
    }

      }
      
      this.clientPhone.unsubscribe();
    });

  }

  addClient(value){
    this.clientAdded = true;
    console.log("add client",this.participation);
    let id;
    this.userService.getUserByEmail(this.value.email).valueChanges().subscribe((user:any) => {
      id = user.uid;
      this.participation.id = id;
      this.clientAnswerService.newFormParticipation(this.participation).then(x => {
        this.toast({ html: 'Your Client Information has Successfully Saved!', classes: 'green' });
      });
    })

      this.router.navigate(['/admin/search-users']);
  }


  backToList() {
    this.router.navigate(['/user/view-form']);
  }
  
  closeModal() {
    this.invitationPopup = false;
  }

  inviteClient() {

    console.log("invite client", this.value);

    // this.inviteService.getInviteByEmail(this.value.email).valueChanges().subscribe(invite => {
    //   console.log("the invitation", invite);
    //   if (invite.length == 0) {
    //     const timestamp = new Date();
    //     console.log("we are here")
    //     this.clientAnswerService.newFormParticipation(this.participation).then(x => {
    //       console.log("Here is the client answer", x)
    //     })
    //     this.clientAnswerService.getClientByEmail(this.value.email).valueChanges().subscribe(client => {
    //       console.log("Clients Information for the id to be used while creating a user", client);
    //       this.inviteService.saveInvite({
    //         inviteEmail: this.value.email, timestamp, userType: 'User', firstName: this.value.firstName, lastName: this.value.lastName,
    //         cellPhone: this.value.cellPhone
    //       });
    //     })


    //   }
    // });


    this.invite = this.inviteService.getInviteByEmail(this.value.email).valueChanges().subscribe(invite => {
      if (invite) {
        if (invite.length === 0) {
          const timestamp = new Date();
          this.clientAnswerService.newFormParticipation(this.participation).then(x => {
            console.log("Here is the client answer", x)
          })
          // this.usersService.
          this.inviteService.saveInvite({ inviteEmail: this.value.email, timestamp, userType: 'User', firstName:this.value.firstName, lastName:this.value.lastName });
        }
        let hostname;
        let newUserInviteEmail;
        let appName;
        let FirstName
        let LastName
        let department
        let jobtitle
            if (invite.length === 1) {
              this.inviteGlobal = invite;
              hostname = `${this.host}${this.inviteGlobal[0].id}`;
              newUserInviteEmail = this.value.email;
              appName = this.currentUser.GivenName ? this.currentUser.GivenName : '';
              FirstName = this.inviteGlobal[0].firstName
              LastName = this.inviteGlobal[0].lastName
              department = this.inviteGlobal[0].depart
              jobtitle = this.inviteGlobal[0].jobTitle
              this.invite.unsubscribe();
    
                this.emailService.sendUserInvite({
                  hostname, inviteEmail: newUserInviteEmail, appName, host: this.host, id: this.inviteGlobal[0].id
                },this.currentUser);
    
                this.toast({ html: 'Invitation email sent Successfully', classes: 'green', type: 'success' });
            }
      }
    });
    this.router.navigate(['/admin/search-users']);


    // let hostname;
    // let email;
    // let appName;
    // let FirstName
    // let LastName
    // let cellPhone
    // this.invite = this.inviteService.getInviteByEmail(this.email.value).valueChanges().subscribe(invite => {
    //   this.inviteGlobal = invite;
    //   if (invite.length === 1) {
    //     hostname = `${this.host}${this.inviteGlobal[0].id}`;
    //     email = this.email.value;
    //     FirstName = this.inviteGlobal[0].firstName
    //     LastName = this.inviteGlobal[0].lastName
    //     cellPhone = this.inviteGlobal[0].cellPhone

    //     this.emailService.sendUserInvite({
    //       hostname, inviteEmail: email, appName, host: this.host, id: this.inviteGlobal[0].id, firstName: FirstName, lastName: LastName,
    //       cellPhone: cellPhone,
    //     }, this.currentUser);
    //   }

    //   this.toast({ html: 'Invitation email sent Successfully', classes: 'green', type: 'success' });

    //   this.invite.unsubscribe();
    // })
    // this.router.navigate(['/admin/search-users']);

  }


  answerTypeChangeAddress(event, i){
    console.log("Here is to see what type of event it is",event);
    console.log("and this one is to print the index ", i)
    if(event == 'Canada'){
      this.isCanada = true;
      this.isUsa = false;
      this.otherCountry = false;
    }else if(event == "United States of America"){
      this.isUsa = true;
      this.isCanada = false;
      this.otherCountry = false;
    }else{
      this.otherCountry = true;
      this.isCanada = false;
      this.isUsa = false;
    }
  }

  answerTypeChangeProvince(event, i){}

  answerTypeChangeState(event, i){}



  toast(obj) {
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green';
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
    }, 3000);
  }

  get firstName() { return this.surveyForm.get('firstName'); }
  get lastName() { return this.surveyForm.get('lastName'); }
  get email() { return this.surveyForm.get('email'); }
  get cellPhone() { return this.surveyForm.get('cellPhone'); }

  get answers() {return (this.surveyForm.get('answerItems') as UntypedFormArray).controls;}
  get answerItems(): UntypedFormArray { return this.surveyForm.get("answerItems") as UntypedFormArray }






  
  // continue(index: any) {
  //   this.visibleStepIndex = index + 1;
  // }

  // previous(index: any) {
  //   if (index > 0) { this.visibleStepIndex = index - 1; }

  //   const answerArray = this.surveyForm.get('answerItems') as FormArray;
  //   if (answerArray.controls[index - 1].get('questionType').value === 'Multiple Choice') {
  //     answerArray.controls[index - 1].get('answer').patchValue('');
  //   }
  // }

  // prev() {
  //   this.visibleStepIndex = this.visibleStepIndex - 1;
  // }

    // update(){
  //   this.clientAnswerService.updateFormParticipationByClient()
  // }

    // isMultipleQuestionsAllowed(index) {
  //   return this.model.questions[index].allowMultipleAnswers;
  // }

  // isRequredAllowed(index){
  //   return this.model.questions[index].required;
  // }


  getOptions(index) {
    return this.model.questions[index].answers;
  }

  getScaleOption(index) {

    const scales = this.model.questions[index].scales;
    const start = Number(scales.start);
    const end = Number(scales.end);
    const startLabel = scales.startLabel;
    const endLabel = scales.endLabel;
    // let options: Options = {
    //   floor: start,
    //   ceil: end,
    //   showTicks: true,
    //   showTicksValues: true,
    //   step: 1,
    //   getLegend: (value: number): string => {
    //     if (value == start) return startLabel;
    //     else if (value == end) return endLabel
    //     // else return value.toString();
    //   },
    // }
    // return options;
  }

  getData(index) {
    const scales = this.model.questions[index].scales;
    const startLabel = scales.startLabel;
    const endLabel = scales.endLabel;

    const start = Number(scales.start);
    const end = Number(scales.end);
    var items: string[] = [];
    for (var i = start; i <= end; i++) {
      //answers.push(this.fb.group({value: i}));
      items.push(i.toString());
    }

    const data = {
      range: items,
      label: { start: startLabel, end: endLabel }
    }
    return data;
  }

  createRange(index) {
    const scales = this.model.questions[index].scales;
    const start = Number(scales.start);
    const end = Number(scales.end);
    var items: number[] = [];
    for (var i = start; i <= end; i++) {
      //answers.push(this.fb.group({value: i}));
      items.push(i);
    }

    return items;
  }

  getLables(index) {
    const scales = this.model.questions[index].scales;
    const startLabel = scales.startLabel;
    const endLabel = scales.endLabel;
    return { start: startLabel, end: endLabel };
  }

  getColSize(index) {
    const scales = this.model.questions[index].scales;
    var start = Number(scales.start);
    var end = Number(scales.end);
    var items = end - start;
    items = items + 3;
    // if(items>5){
    //   return `col m${Math.floor(10/items/2)} s${Math.floor(10/items/2)}`;
    // }else{
    //   return `col m${Math.floor(12/items)} s${Math.floor(12/items/2)}`;
    // }
    if (items > 7) {
      return `col s${12 / (items) * 2}`;
    } else {
      return `col s${12 / items}`;
    }
    //  return `col s${12/items}`;
  }

  setScaleValue(index, value) {
    const answerItems = this.surveyForm.get('answerItems') as UntypedFormArray;
    const answer = answerItems.controls[index].get('answer');
    answer.patchValue(value.toString());
  }

}
