
 <div *ngIf="user">
  <div class="row">
  <div class="row col s12  inner-wrapper">
    <div class="card-image">
      <div class="background-img">
  
        <div *ngIf="!isMobile">
          <div class="background-with-color" class="hide-on-med-and-up center">
            <div *ngIf="!coverPhotoLink">
              <img data-src="">
            </div>
            <img loading="lazy" *ngIf="coverPhotoLink" #file [src]="coverPhotoLink" 
              style="width: 100%; height: 120px;" />
    
          </div>
  
          <div class="background-with-color-big hide-on-small-only show-on-medium-and-up" >
            <div *ngIf="!coverPhotoLink"></div>
            <img loading="lazy" *ngIf="coverPhotoLink" #file [src]="coverPhotoLink" 
              style="width: 100%; height: 200px;" />
         
          </div>
        </div>
  
        <div *ngIf="isMobile">
          <div class="background-with-color"  class="hide-on-med-and-up center">
            <div *ngIf="!coverPhotoLink">
              <img data-src="">
            </div>
            <img loading="lazy" *ngIf="coverPhotoLink" #file [src]="coverPhotoLink" 
              style="width: 100%; height: 120px;" />
  
          </div>
  
          <div class="background-with-color-big hide-on-small-only show-on-medium-and-up" >
            <div *ngIf="!coverPhotoLink"></div>
            <img loading="lazy" *ngIf="coverPhotoLink" #file [src]="coverPhotoLink" 
              style="width: 100%; height: 200px;" />
  
            </div>
          </div>
  
        </div>
  
        <div *ngIf="!isMobile">
        <div class="prof-img hide-on-med-and-up center">
          <div *ngIf="!imgLink"  class="center">
            <img
            loading="lazy"

            class="background-img-avatar" 

            style="width: 120px; border: white solid 3px; border-radius: 50%;"
  
            data-src="https://api.goingmobile.app/media/personal-app-assets/img/webp/profile.webp"
                                        
            srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/profile.webp"
                                        
            fallback="https://api.goingmobile.app/media/personal-app-assets/img/jpg/profile.jpg"
                                        
            alt="ananymous profile."> 
      
          </div>
          <div  *ngIf="imgLink"  class="hide-on-med-and-up center">
            <img loading="lazy" class="background-img-avatar"  [src]="imgLink"  style="width: 120px; height: 120px; border: white solid 3px; border-radius: 50%;"/>
          
          </div>
        </div>
  
        <div class="prof-img-big hide-on-small-only show-on-medium-and-up center">
          <div *ngIf="!imgLink" class="">
            <img
            loading="lazy"

            class="background-img-avatar" 

            style="width: 140px; border: white solid 3px; border-radius: 50%;"
  
            data-src="https://api.goingmobile.app/media/personal-app-assets/img/webp/profile.webp"
                                        
            srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/profile.webp"
                                        
            fallback="https://api.goingmobile.app/media/personal-app-assets/img/jpg/profile.jpg"
                                        
            alt="ananymous profile."> 
           
          </div>
          <div  *ngIf="imgLink" class="hide-on-small-only show-on-medium-and-up center">
            <img loading="lazy" class="background-img-avatar"   [src]="imgLink"  style="width: 140px; height: 140px; border: white solid 3px; border-radius: 50%;"/>
           
          </div>
        </div>
        </div>
  
  
        <div *ngIf="isMobile">
          <div class="prof-img hide-on-med-and-up center">
            <div *ngIf="!imgLink"  class="center">
              <img
              loading="lazy"

              class="background-img-avatar" 
  
              style="width: 120px; border: white solid 3px; border-radius: 50%;"
    
              data-src="https://api.goingmobile.app/media/personal-app-assets/img/webp/profile.webp"
                                          
              srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/profile.webp"
                                          
              fallback="https://api.goingmobile.app/media/personal-app-assets/img/jpg/profile.jpg"
                                          
              alt="ananymous profile."> 
  
            </div>
            <div  *ngIf="imgLink"  class="hide-on-med-and-up center">
              <img loading="lazy" class="background-img-avatar"   [src]="imgLink"  style="width: 120px; height: 120px; border: white solid 3px; border-radius: 50%;"/>
  
            </div>
          </div>
  
          <div class="prof-img-big hide-on-small-only show-on-medium-and-up center">
            <div *ngIf="!imgLink" class="">
              <img
              loading="lazy"

              class="background-img-avatar"
  
              style="width: 140px; border: white solid 3px; border-radius: 50%;"
    
              data-src="https://api.goingmobile.app/media/personal-app-assets/img/webp/profile.webp"
                                          
              srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/profile.webp"
                                          
              fallback="https://api.goingmobile.app/media/personal-app-assets/img/jpg/profile.jpg"
                                          
              alt="ananymous profile."> 
  
            </div>
            <div  *ngIf="imgLink" class="hide-on-small-only show-on-medium-and-up center">
              <img loading="lazy" class="background-img-avatar"   [src]="imgLink"  style="width: 140px; height: 140px; border: white solid 3px; border-radius: 50%;"/>
  
            </div>
          </div>
  
          </div>
    </div>
  </div>
  </div>

  <div class="row col s12">


    <ul class="collapsible">
  
      <li>
        <div class="collapsible-header"><i class="material-icons grey-text text-darken-2">photo_camera</i><strong>Photo Gallery</strong></div>
        <div class="collapsible-body">
          
  
         <div class="row" *ngIf="images">
          <gallery *ngIf="images.length > 0" [items]="images"></gallery>
         
           </div>
  
  
  
        </div>
      </li>
  
      <li  class="active">
        <div class="collapsible-header"><i class="material-icons green-text text-darken-2">info</i><strong>Personal Information</strong></div>
        <div class="collapsible-body">
          <form [formGroup]="updateProfileForm">
            <div class="row">
              <div class="input-field col s12 m6">
                <i class="material-icons prefix green-text text-darken-2">account_circle</i>
                  <input id="GivenName" type="text" formControlName='GivenName' class="validate" />
                  <label class="active" for="GivenName" >Given Name</label>
              </div>
              <div class="input-field col s12 m6">
                <i class="material-icons prefix green-text text-darken-2">account_circle</i>
                  <input id="MiddleName" type="text" formControlName='MiddleName' class="validate" />
                  <label class="active" for="MiddleName" >Middle Name</label>
              </div>
              <div class="input-field col s12 m6">
                <i class="material-icons prefix green-text text-darken-2">account_circle</i>
                <input id="LastName" type="text" placeholder="Last Name" formControlName="LastName" class="validate">
                <label class="active" for="LastName">Last Name</label>
              </div>
  
                <div class="input-field col s12 m6">
                  <i class="material-icons prefix green-text text-darken-2">add_location</i>
                    <input id="City" type="text" formControlName='City'/>
                    <label class="active" for="City" >City</label>
                </div>
  
  
                <div class="input-field col s12 m6">
                    <i class="material-icons prefix green-text text-darken-2">phone</i>
                    <input id="cellPhone" type="text" formControlName='cellPhone'  />
                    <label class="active" for="cellPhone">Phone Number</label>
                </div>
  
                <div class="input-field col s12 m6">
                    <i class="material-icons prefix green-text text-darken-2">email</i>
                    <input id="Email" type="text" formControlName='Email' placeholder="Email"/>
                    <label class="active" for="Email" >Email</label>
                </div>
  
                
  
            </div>
        </form>
        </div>
      </li>
  
  
  
      <li>
        <div class="collapsible-header"><i class="material-icons teal-text">mark_email_unread</i> <strong>Contact</strong></div>
        <div class="collapsible-body">
         <div class="row">
  
          <div class="row" *ngIf="user">
           
  
  
            <div *ngIf="user.contactAndBasicInfo">
  
                <div *ngFor="let contact of user.contactAndBasicInfo" class="col s12 m6">
                  <div class="row card-panel col s12">
                    <div class="row col s12">
                      <br />
  
                      <span *ngIf="contact.mobilePhone"><strong class="pink-text">Mobile : </strong> {{contact.mobilePhone}} </span>
                      <br />
                      <span *ngIf="contact.workPhone1"><strong class="pink-text">Work : </strong> {{contact.workPhone1}} </span>
                      <br />
                      <span *ngIf="contact.website"><strong class="pink-text">Website : </strong> {{contact.website}} </span>
  
                    </div>
  
              
                  </div>
                </div>
  
  
  
            </div>
  
     
          </div>
  
       
         </div>
        </div>
      </li>
  
  
  
  <li>
    <div class="collapsible-header"><i class="material-icons purple-text text-lighten-2">share</i><strong>Social Media</strong> </div>
    <div class="collapsible-body">
      <div class="row" *ngIf="user">
        <form [formGroup]="socialMediaForm">
        <!--  instagram  -->
        <div class="input-field col s12">
          <i class="fa fa-instagram prefix " style="color: #8a3ab9;"></i>
          <input id="userInsta" type="text" formControlName='instagramAcc' class="materialize-textarea" (input)="showInstagramPrivacy()">
          <label class="active" for="userInsta">Instagram account</label>
          <div *ngIf="(getInstagramAcc?.dirty || getInstagramAcc?.touched) && getInstagramAcc?.invalid">
            <small *ngIf="getInstagramAcc.errors.pattern" class="red-text"> Wrong Instagram url format </small>
          </div>
        </div>
        <div>
  
          <div class="input-field col s12" *ngIf="showInstagramAcc">
            <p>Show Instagram Account To :</p>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value="Public" formControlName="showInstagramAcc" />
                <span class="text"><strong>General Public</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value="Friends" formControlName="showInstagramAcc" />
                <span class="blue-text"><strong>Friends Only</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value = 'Private' formControlName="showInstagramAcc" />
                <span class="red-text"><strong>Do Not Show</strong></span>
              </label>
            </div>
  
          </div>
        </div>
  
        <!--  twitter  -->
        <div class="input-field col s12">
          <i class="fa fa-twitter prefix" style="color: #1DA1F2;"></i>
          <input id="userTwitter" type="text" formControlName='twitterAcc' class="materialize-textarea" (input)="showTwitterPrivacy()">
          <label class="active" for="userTwitter">Twitter account</label>
          <div *ngIf="(getTwitterAcc?.dirty || getTwitterAcc?.touched) && getTwitterAcc?.invalid">
            <small *ngIf="getTwitterAcc.errors.pattern" class="red-text"> Wrong Twitter url format </small>
          </div>
        </div>
    
  
        <!--  linked in  -->
        <div class="input-field col s12">
          <i class="fa fa-linkedin prefix " style="color: #0077b5;" ></i>
          <input id="userLinkedin" type="text" formControlName='linkedinAcc' class="materialize-textarea" (input)="showLinkedinPrivacy()">
          <label class="active" for="userLinkedin">Linkedin account</label>
          <div *ngIf="(getLinkedinAcc?.dirty || getLinkedinAcc?.touched) && getLinkedinAcc?.invalid">
            <small *ngIf="getLinkedinAcc.errors.pattern" class="red-text"> Wrong Linkedin url format </small>
          </div>
        </div>
       
  
        <!--  youtube  -->
        <div class="input-field col s12">
          <i class="fa fa-youtube prefix red-text"></i>
          <input id="userYoutube" type="text" formControlName='youtubeAcc' class="materialize-textarea" (input)="showYoutubePrivacy()">
          <label class="active" for="userYoutube">Youtube account</label>
          <div *ngIf="(getYoutubeAcc?.dirty || getYoutubeAcc?.touched) && getYoutubeAcc?.invalid">
            <small *ngIf="getYoutubeAcc.errors.pattern" class="red-text"> Wrong Youtube url format </small>
          </div>
        </div>
      
      </form>
      </div>
    </div>
  </li>
  
  
  
      <li>
        <div class="collapsible-header"><i class="material-icons pink-text">assured_workload</i><strong>Work</strong></div>
        <div class="collapsible-body">
         <div class="row" *ngIf="user">
  
    
  
  
            <div *ngIf="user.workplaces">
  
                <div *ngFor="let workplace of user.workplaces" class="col s12 m6">
                  <div class="row card-panel col s12">
                    <div class="row col s12">
                      <br />
                      <span *ngIf="workplace.company"><strong class="pink-text">Company : </strong> {{workplace.company}} </span> <br />
                      <span *ngIf="workplace.position"><strong class="pink-text">Position : </strong> {{workplace.position}} </span>
                      <br />
                      <span *ngIf="workplace.cityOrTown"><strong class="pink-text">City : </strong> {{workplace.cityOrTown}} </span>
                      <br />
                      <span *ngIf="workplace.description"><strong class="pink-text">Job Description : </strong>
                        {{workplace.description}} </span> <br />
                      <span *ngIf="workplace.fromDate"><strong class="pink-text">Duration : </strong> {{workplace.fromDate}} -
                        {{workplace.toDate}} </span>
  
                    </div>
  
                  </div>
                </div>
  
  
  
            </div>
         
          </div>
        </div>
      </li>
  
      <li>
        <div class="collapsible-header"><i class="material-icons blue-text">auto_stories</i><strong>Education</strong></div>
        <div class="collapsible-body">
          <div class="row">
            <div *ngIf="user">
       
              <div *ngIf="user.educations">
  
                <div *ngFor="let education of user.educations" class="col s12 m6">
  
                  <div class="row card-panel col s12">
  
                    <div class="row col s12">
                      <br />
                      <span *ngIf="education.school"><strong class="blue-text">School : </strong> {{education.school}} </span>
                      <br />
                      <span *ngIf="education.level"><strong class="blue-text">Grade : </strong> {{education.level}} </span>
                      <br />
                      <span *ngIf="education.fromDate"><strong class="blue-text">Duration : </strong> {{education.fromDate}} -
                        {{education.toDate}} </span>
                    </div>
  
  
  
  
                  </div>
  
                </div>
              </div>
  
  
            </div>
          </div>
        </div>
      </li>
  
  
    </ul>
  
  </div>
  
  <div class="row col s12">
    <div class="col l1 "></div>
    <div class="col s12">
    
      <a [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backClicked()"><i
          class="material-icons left">arrow_back_ios_new</i> Back To My Profile</a>
    </div>
    <div class="col l1"></div>
  </div>

 </div>