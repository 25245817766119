import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { registerLicense } from '@syncfusion/ej2-base';

registerLicense('ORg4AjUWIQA/Gnt2VVhkQlFacldJWXxMYVF2R2BJeFRxcV9FZkwgOX1dQl9gSX1TcURiXXxbeX1WTmU=');
if (environment.production) {
  enableProdMode();
}

const startApp = () => {
  platformBrowserDynamic().bootstrapModule(AppModule);
};



if (typeof window['cordova'] !== 'undefined') {
  document.addEventListener('deviceready', () => {
    // Start The App After Cordova Loads
    startApp();
    // ---------------------------------
  }, false);
} else {
  startApp();
}


