import { Router, ActivatedRoute } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { EmployeeServicesService } from 'src/app/services/firebase/employee-services.service';
import { ProductsServiceMenuService } from 'src/app/services/api/data.service';
import { HelperService } from 'src/app/services/helper/helper';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectAppSettings } from 'src/app/state/app.selectors';

@Component({
  selector: 'app-services-list',
  templateUrl: './services-list.component.html',
  styleUrls: ['./services-list.component.css']
})
export class ServicesListComponent implements OnInit {
  @Input() calledFrom;

  currentUser: any;
  existingService = [];
  settings: any;
  allServices: any;
  filterOpen = false;
  serviceNameFilter: any;
  servicePriceFilter = '';
  beforeName = [];
  beforePrice = [];
  isAdmin = false;
  isEmployee = false;
  isUser = false;

  categoryId;
  servicesName;
  currency: any;
  appSetting: any;
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  openMoveModal = false;
  appSettings$: Subscription;
  total: number = 0;
  itemsPerPage: number = 5;
  displayedServices = [];
  p = 1;
  constructor(private empService: EmployeeServicesService,
              private authService: AuthService,
              private router: Router,
              private location: Location,
              private route: ActivatedRoute,
              private helperService: HelperService,
              private productsServiceMenuService:ProductsServiceMenuService,
              private store: Store) { 
                this.appSettings$ = this.store.select(selectAppSettings).subscribe((settings) => {
                  this.settings =  this.helperService.cloneDeep(settings);
                });
              }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.currentUser = this.authService.getGlobalUser();
    if (this.currentUser){
      if (this.currentUser.role === 'Admin'){
        this.isAdmin = true;
      }
      if (this.currentUser.role === 'User'){
        this.isUser = true;
      }
      if (this.currentUser.role === 'Employee'){
        this.isEmployee = true;
      }
    }

    this.route.paramMap.subscribe(params => {
      this.categoryId = params.get('id');
    });

      this.empService.getAllServices().valueChanges().subscribe((services) => {
        if (services.length > 0 ){
          this.existingService = services;
          this.allServices = services;
        }
      });

    this.productsServiceMenuService.servicesName.subscribe(p=>{
      this.servicesName = p;
    });
    this.productsServiceMenuService.currencyName.subscribe(c => {
      this.currency = c;
    });
  }

  deleteService(service){
    this.empService.deleteService(service).then(() => {
      // toast({html: 'Service Deleted successfully ', classes: 'green'});
    }).catch(err => {
      // toast({html: err, classes: 'green'});
    });
    this.backClicked();
  }

  backClicked(){
    this.location.back();
  }

  navigate(service){
    if(this.calledFrom === 'onBoarding'){
      if (this.currentUser&&this.calledFrom!=='onBoarding'){
        if (this.isAdmin){
          this.router.navigate(['/admin/service', service.id]);
        }
        if (this.isUser){
          this.router.navigate(['/user/view-service', service.id]);
        }
      } else {
        this.router.navigate(['/view-service', service.id]);
      }
    }

  }

  truncateHTML(text: string, limit: string): string {
    return  this.helperService.truncateHTML(text, limit);
  }

  onServiceNameChange(){
    if (this.beforeName.length === 0){
      this.beforeName = [...this.existingService];
    }
    this.existingService = this.beforeName;
    this.existingService = this.existingService.filter((service) => {
      return service.serviceName.toLowerCase().includes(this.serviceNameFilter.toLowerCase());
    });
  }
  onPriceRangeChange(){
    if (this.beforePrice.length === 0){
      this.beforePrice = [...this.existingService];
    }
    this.existingService = this.beforePrice;

    if (this.servicePriceFilter === 'allPrice'){
      this.existingService = this.beforePrice;
    }

    if (this.servicePriceFilter === 'pricerange1'){
      this.existingService = this.existingService.filter((service) => {
        return service.price <= 100;
      });
    }

    if (this.servicePriceFilter === 'pricerange2'){
      this.existingService = this.existingService.filter((service) => {
        return (100 < service.price && service.price <= 500);
      });
    }

    if (this.servicePriceFilter === 'pricerange3'){
      this.existingService = this.existingService.filter((service) => {
        return (500 < service.price && service.price <= 1000);
      });
    }

    if (this.servicePriceFilter === 'pricerange4'){
      this.existingService = this.existingService.filter((service) => {
        return service.price > 1000;
      });
    }
  }
  toggleFilter(){
    this.filterOpen = !this.filterOpen;
  }
  clearFilters(){
    this.existingService = this.allServices;
    this.beforeName = [];
    this.beforePrice = [];
    this.serviceNameFilter = '';
    this.servicePriceFilter = '';
  }
  getSortedList(unsortedElements: Array<any>){
    let sortedList = unsortedElements.sort((a:any, b:any ) => {
        return a.position - b.position;
    });
    return sortedList
  }
  getInnerText(text){
    return this.helperService.getInnerText(text);
  }
  redirectToservice(serviceId){
    // this.router.navigate(['/view-service/',serviceId])
    if( this.calledFrom!=='onBoarding'){
      if (this.currentUser){
        if (this.isAdmin){
          this.router.navigate(['/admin/service', serviceId]);
        }
        if (this.isUser){
          this.router.navigate(['/user/service', serviceId]);
        }
      } else {
        this.router.navigate(['/view-service', serviceId]);
      }
  }
  }
  BookServiceAppointment(service, serviceUnder){
    if (this.currentUser.role === 'User'){
      this.router.navigateByUrl('/user/book-appointment', {state: {service: service, serviceUnder:serviceUnder}});

    }
    if (this.currentUser.role === 'Admin'){
      this.router.navigateByUrl('/admin/create-appointment', {state: {service: service, serviceUnder:serviceUnder}});
    }
  }
  OpenLoginPopup(){
    this.toast({html: 'You should login first to book appointment!', classes: 'red', type: 'declined'});
  }
  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
    }, 2000);
  }

  pageChangeEvent(event: number) {
    this.total = this.allServices.length;
    const startIndex = (this.total - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.displayedServices = this.allServices.slice(startIndex, endIndex);
  
  }
}

