import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ViewPortService {

  private viewportSizeSubject: BehaviorSubject<{ width: number; height: number }> = new BehaviorSubject(this.getViewportSize());

  constructor() { }

  getViewportSize(): { width: number; height: number } {
    return {
      width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
      height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
    };
  }

  // Listen for window resize events
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.viewportSizeSubject.next(this.getViewportSize());
  }

  // Expose an observable to subscribe to viewport size changes
  getViewportSizeObservable(): BehaviorSubject<{ width: number; height: number }> {
    return this.viewportSizeSubject;
  }
}
