import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AreasOfInterestService } from 'src/app/services/firebase/areas-of-interest.service';
import { environment } from 'src/environments/environment';
// import { toast } from 'materialize-css';
import { Location } from '@angular/common';

// declare var $: any;
// declare var M: any;

@Component({
  selector: 'app-areas-of-interest',
  templateUrl: './areas-of-interest.component.html',
  styleUrls: ['./areas-of-interest.component.css']
})
export class AreasOfInterestComponent implements OnInit {

  areasListModel: any[];
  selectedModel: any;
  areaOfInterestForm: UntypedFormGroup;
  themeColor = environment.appTheme.adminThemeColor;
  showMsg = false;
  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  isUpdating = false;
  areaNames = [];
  areaToBeDeleted: any;

  // modal
  deleteModalOpen = false;

  @Input() calledFromEmptyDashboard:string;
  @Output() closeCollapse = new EventEmitter<string>();
  @Output() areasOfInterestSaved = new EventEmitter<string>();

  constructor(private areaService: AreasOfInterestService, private formBuilder: UntypedFormBuilder, private location: Location) { }

  ngOnInit() {
    this.areaOfInterestForm = this.formBuilder.group({
      name: ['', [Validators.required]],
    });

    this.areaService.getAreasOfInterest().valueChanges().subscribe(a => {
      if (a) {
        this.areasListModel = a;
        this.areasListModel.forEach(area => {
          this.areaNames.push(area.name);
        });
        this.showMsg = true;
      }
    });
  }

  get name() { return this.areaOfInterestForm.get('name'); }

  addArea(){
    this.selectedModel = undefined;
    this.areaOfInterestForm.patchValue({'name': ''});
    setTimeout(() => {
      // $('.modal').modal();
      // $('#editModal').modal('open');
    }, 25);
  }

  selectArea(area: any) {
    if (area) {
      this.selectedModel = area;
      this.areaOfInterestForm.patchValue({'name': area.name});
      setTimeout(() => {
        // $('.modal').modal();
        // M.updateTextFields();
        // $('#editModal').modal('open');
      }, 25);

    }
  }

  addAreasOfInterest(model: any){
      const newArea = {
        id: '',
        name: model.name,
        membersList: []
      };
      if (model.name){
        if (this.areaNames.includes(newArea.name)){
          this.toast({html: 'Area already Exists!', classes: 'red', type: 'failure'});
        } else {
          this.areaService.saveAreasOfInterest(newArea).then( saved => {
            this.toast({ html: 'Area Of Interest Successfully Saved', classes: 'green', type: 'success'});
          }).catch(err => {
            this.toast({html: err, classes: 'red', type: 'failure'});
          });
        }
      }
      else {
        this.toast({ html: 'Area of interest can not be empty', classes: 'red', type: 'failure'});
      }

      this.selectedModel = undefined;
      this.areaOfInterestForm.patchValue({name: ''});

  }
  manageAreasOfInterest(model: any) {
    //  if ! then add
    if (!this.selectedModel) {
      const newArea = {
        id: '',
        name: model.name,
        membersList: []
      };
      if (this.areaNames.includes(this.selectedModel.name)){
        this.toast({html: 'Area already Exists!', classes: 'red', type: 'failure'})
      }
      else {
        this.areaService.saveAreasOfInterest(newArea).then( saved => {
          this.toast({ html: 'Area Of Interest Successfully Saved', classes: 'green', type: 'success' });
          // $('#editModal').modal('close');
        });

      }

    } else {
      // if === then update
      const newArea = {
        id: this.selectedModel.id,
        name: model.name,
        membersList: this.selectedModel.membersList
      };

      if (this.areaNames.includes(this.selectedModel.name)){
        this.toast({html: 'Area already Exists!', classes: 'red', type: 'failure'});
      }
      else {
        this.areaService.updateAreasOfInterest(newArea).then( saved => {
          this.toast({ html: 'Area Of Interest Successfully Updated', classes: 'green', type: 'success' });

          // $('#editModal').modal('close');
        });
      }
    }
    this.selectedModel = undefined;
    this.areaOfInterestForm.patchValue({name: ''});


  }

  deleteArea(){
    this.areaService.deleteArea(this.selectedModel).then(() => {
      this.toast({ html: 'Area Of Interest Deleted', classes: 'red', type: 'failure' });

      this.areaNames = this.areaNames.filter(areaListed => {
        return areaListed !== this.selectedModel.name; });
        // $('#editModal').modal('close');

    });

    this.closeDeleteModal();
    this.selectedModel = undefined;
    this.areaOfInterestForm.patchValue({name: ''});

  }

  backClicked(){
    this.location.back();
  }
  toggleUpdate(){
    this.isUpdating = !this.isUpdating;
    if (!this.isUpdating){
      this.selectedModel = undefined;
      this.areaOfInterestForm.patchValue({name: ''});

    }


  }

  openDeleteModal(area){
    this.areaToBeDeleted = area;
    this.deleteModalOpen = true;
  }
  closeDeleteModal(){
    this.deleteModalOpen = false;
  }
  saveBtnClicked(){
    if(this.calledFromEmptyDashboard == 'areasOfInterest'){
      this.closeCollapse.emit('areasOfInterest');
      this.areasOfInterestSaved.emit('areasOfInterestSaved')
    }
  }

toast(obj){
  this.toastMessage = obj.html;
  this.toastClass = obj.classes ? obj.classes : 'green' ;
  this.toastType = obj.type ? obj.type : 'success';
  this.openToast = true;
  setTimeout(() => {
    this.openToast = false;
  }, 2000);
}
}
