import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { ClientFormService } from 'src/app/services/firebase/client-form.service';


@Component({
  selector: 'app-create-form',
  templateUrl: './create-form.component.html',
  styleUrls: ['./create-form.component.css']
})
export class CreateFormComponent implements OnInit {

  loggedUser: any;
  clientForm: UntypedFormGroup;
  questionForm: UntypedFormGroup;
  questionArray: UntypedFormArray;
  questionNumber = 1;

  answerTypes: any = [
    { 'type': 'Multiple Choice', 'helpText': 'Displays a list of answers the client can choose from' },
    { 'type': 'Open Ended Answer', 'helpText': 'Allows client to answer in open text format' },
    // { 'type': 'Scale', 'helpText': 'Displays a scale from any range from 0 to 10' },
    { 'type': 'Date', 'helpText': 'Displays a calander for the client to choose from'}];
  scales = ['2', '3', '4', '5', '6', '7', '8', '9', '10'];


  step1 = true;
  step2 = false;

  model: any;

   // toast
   toastMessage: any;
   toastClass: any;
   toastType: any;
   openToast = false;

   location: any;
 
  constructor(private fb: UntypedFormBuilder,
      private authService: AuthService,
      private clientFormService: ClientFormService,
      private router: Router) { }


  ngOnInit(): void {
    this.loggedUser = this.authService.getGlobalUser();
   
    this.clientForm = this.fb.group({
      formName: ['', [Validators.required]],
      description:[''],
      themeColor:[''],
      //type: ['', [Validators.required]],
      // startDate: ['', [Validators.required]],
      // endDate: ['', [Validators.required]],
      // showResult: ['', [Validators.required]],
      // showResultTo: ['']
    });
    

  
  }
  submitSurveyInfo(value: any) {

    this.model = {
      id: '',
      name: value.formName,
      description: value.description,
      themeColor: value.themeColor,
      numberOfQuestions: 0,
      questions: [],
      datePosted: new Date(),
      //type: value.type,
      //startDate: value.startDate,
      //endDate: value.endDate,
      //showResult: value.showResult,
      //showResultTo: value.showResultTo,
      //adminID: this.loggedUser.id,
      //adminName: this.loggedUser.FirstName + " " + this.loggedUser.LastName,
    };

    this.questionForm = this.fb.group({
      questionItems: this.fb.array([this.createQuestion()])
    });

    this.step1 = false;
    this.step2 = true;


  }

  submitQuestions(value: any) {
    console.log("Here is the value",value)
    let qNumber = 0;
    value.questionItems.forEach(element => {
      qNumber += 1;
      const question = {
        questionNumber: qNumber,
        question: element.question,
        answerType: element.answerType,
        allowMultipleAnswers: element.allowMultipleAnswers,
        answers: element.answers,
        //scales: element.scales
      };
      console.log("Here is the first modell",this.model)

      // if (element.answerType === 'Scale') {
      //   for (var i = element.scales.start; i <= element.scales.end; i++) {
      //     element.answers.push({ 'answer': i.toString() });
      //   }
      // }

      this.model.questions.push(question);

    });

    this.model.numberOfQuestions = this.model.questions.length;

    console.log("printing the model",this.model);

    this.clientFormService.newForm(this.model);
    
    this.toast({ html: 'New Form Successfully Created!', classes: 'green', type: 'success' });
      this.step2 = false;
      this.router.navigate(['/admin/search-users']);  
  }
  createQuestion() {
    let questionFormGroup = this.fb.group({
      question: ['', [Validators.required]],
      answerType: ['', [Validators.required]],
      allowMultipleAnswers: [false, [Validators.required]],
      answers: this.fb.array([]),
      // scales: this.fb.group({
      //   start: [''],
      //   startLabel: [],
      //   end: [''],
      //   endLabel: []
      // }),
     
    });

    // if(this.createPoll) {
    //   questionFormGroup.get('answerType').patchValue('Multiple Choice');
    //   const answers = questionFormGroup.get('answers') as FormArray;
    //   if (answers.length < 2) {
    //     answers.push(this.addAnswers());
    //     answers.push(this.addAnswers());
    //   }
    // }
    return questionFormGroup
  }

  answerTypeChange(e: any, index: any) {
    console.log("printing the index",index)
    this.resetControls(index);
    const answers = this.getAnswers(index);
    if (e === 'Multiple Choice') {
      if (answers.length < 2) {
        answers.push(this.addAnswers());
        answers.push(this.addAnswers());
      }
    } else if (e === 'Scale') {
      this.getScales(index).get('start').patchValue('1');
      this.getScales(index).get('end').patchValue('10');
    } else {
    }
  
    // setTimeout(() => {
    //   $('select').formSelect();
    // }, 25);
  
  }

  resetControls(index) {
    const answers = this.getAnswers(index);
    console.log("answers",answers)
  
    while (answers.length !== 0) {
      console.log("length of the answers",answers.length)
      answers.removeAt(0);
    }
    const scales = this.getScales(index);
    //answers.reset();
    answers.clearValidators();
    answers.updateValueAndValidity();
    //scales.reset();
    //scales.clearValidators();
    //scales.updateValueAndValidity();
  }

  addAnswers() {
    return this.fb.group({
      answer: ['', [Validators.required]]
    });
  }

  onAddAnswer(index) {
    const answers = this.getAnswers(index);
    answers.push(this.addAnswers());
  }

  addQuestion(): void {
    this.questionNumber += 1;
    this.questionArray = this.questionForm.get('questionItems') as UntypedFormArray;
    this.questionArray.push(this.createQuestion());
   
  }
  removeAnswer(question_index, answer_index) {
    const answers = this.getAnswers(question_index);
    answers.removeAt(answer_index);
  }
  removeFile(index: any) {
    this.questionArray = this.questionForm.get('questionItems') as UntypedFormArray;
    this.questionArray.removeAt(index);
  }

  getAnswers(index) {
    const questions = this.questionForm.get('questionItems') as UntypedFormArray;
    return questions.controls[index].get('answers') as UntypedFormArray;
  }
  getScales(index) {
    const questions = this.questionForm.get('questionItems') as UntypedFormArray;
    return questions.controls[index].get('scales');
  }

  get formName() { return this.clientForm.get('formName'); }
  get description() {return this.clientForm.get('description')}
  get themeColor() {return this.clientForm.get('themeColor')}

  get questions() { return this.questionForm.get('questionItems') as UntypedFormArray; }

  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
      // this.backClicked();
    }, 2000);
  }
}