<angular-editor
  *ngIf="!type"
  [formControl]="control"
  [config]="editorConfig"
  [placeholder]="placeholder"
  class="bg-white"
></angular-editor>
<angular-editor
  *ngIf="type"
  [ngModel]="model"
  (ngModelChange)="onChange($event)"
  [config]="editorConfig"
  [placeholder]="placeholder"
  class="bg-white"
></angular-editor>

<div class="flex justify-end my-1 w-full space-x-2">
  <app-button
    data-te-toggle="tooltip"
    data-te-placement="bottom"
    title="Tooltip on top"
    class="justify-end items-end"
    [customConfig]="{
      text: 'AI Assist',
      type: 'Button',
      buttonType: 'secondaryButton'
    }"
    (click)="onUseChatGpt()"
  ></app-button>
  <div
    class="tooltip tooltip-bottom my-2 text-white cursor-pointer"
    data-tip="ChatGPT is an invaluable tool for content creators seeking inspiration and assistance in generating high-quality content. With its vast knowledge and language capabilities, ChatGPT acts as a creative partner, helping to overcome writer's block and enhancing the content creation process. By simply engaging in a conversation, you can tap into the model's deep understanding of various topics, enabling you to brainstorm ideas, refine concepts, and explore new perspectives. Whether you need assistance with generating engaging headlines, crafting compelling narratives, or conducting research on a specific subject, ChatGPT provides valuable insights and suggestions to elevate your content to new heights. Its ability to adapt to different writing styles and tones ensures that the final output remains authentic and aligned with your vision. ChatGPT is a powerful ally, transforming the content creation experience into a collaborative and efficient journey."
  >
    <span class="font-bold text-lg text-customTeal">&#63;</span>
  </div>
</div>

<!-- <angular-editor formControlName="message" [config]="editorConfig"></angular-editor> -->

<!-- ChatGpt Modal -->
<app-chat-gpt
  *ngIf="chatGptModalOpen"
  [inputType]="inputType"
  [theInput]="selectedInput"
  [fromComponent]="fromComponent"
  [requestMessage]="requestMessage"
  [filter]="chatGptFilter"
  (onMessageSelect)="updateFormValue($event)"
  (closeChatGptModal)="closeChatGptModal()"
></app-chat-gpt>

<!-- Chatgpt Filter Question Modal -->
<div class="modal modal-open" id="filterModal" *ngIf="filterModalOpen">
  <div class="modal-box w-full md:max-w-2xl min-h-max my-4 max-h-128 rounded-sm">

    <div class="flex justify-between w-full">
      <div class="w-full">
       <div class="flex space-x-2 items-center pb-2">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 fill-gray-500">
          <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75" />
        </svg>
        
        <h3 class="font-bold text-xl text-gray-500 ">Filters</h3>
       </div>
        <hr>
      </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-6 h-6 cursor-pointer"
          (click)="closeFilterModal()"
          viewBox="0 0 384 512"
        >
          <path
            d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
          />
        </svg>

    </div>
    <div class="flex-col space-y-3 pt-4">
      <form
        [formGroup]="chatGptFilterForm"
        class=""
        (ngSubmit)="applyFilter(chatGptFilterForm.value)"
      >
        <div class="w-full flex flex-col space-y-4">
          <div class="flex flex-col  gap-4 ">
            <div class="">
              <label class="label">
                <span class="block tracking-wide text-base font-semibold text-gray-500"
                  >Number of Words
                </span>
              </label>
              <input
                type="number"
                placeholder="Number of Words"
                class="appearance-none block input w-full input-bordered rounded-sm border-gray-300 bg-gray-50 text-gray-700 leading-tight focus:outline-none focus:border-gray-500"
                formControlName="wordCount"
              />
            </div>
            <div class="">
              <label class="label p-0">
                <span class="block tracking-wide text-base font-semibold text-gray-500"
                  >Key words
                </span>
              </label>
              <!-- key words list -->
              <div class="flex flex-wrap" *ngIf="keyWords.value.length > 0">
                <div *ngFor="let keyWord of keyWords.value;let i=index">
                  <button type="button"
                      class="border-2 border-customTeal rounded-full py-1 px-4 mx-2 my-2"
                      >
                      <span>{{keyWord}}</span>
                      <span  (click)="removeKeyWord(i)">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline"
                              fill="none" viewBox="0 0 24 24" stroke="currentColor"
                              stroke-width="2">
                              <path stroke-linecap="round" stroke-linejoin="round"
                                  d="M6 18L18 6M6 6l12 12" />
                          </svg>
                      </span>

                  </button>
                </div>
              </div>
                <!-- key word input -->
              <div class="flex flex-row" *ngIf="keyWords.value.length < maxKeyWords">
                <input
                  type="text"
                  placeholder="Key word"
                  class="appearance-none block input input-bordered w-full rounded-sm border-gray-300 bg-gray-50 text-gray-700 leading-tight focus:outline-none focus:border-gray-500"
                  formControlName="keyWordQuery"
                />
                <button type="button" class="border-2 bg-customTeal px-3 " (click)="selectKeyWord()">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline stroke-white"
                      fill="none" viewBox="0 0 24 24" stroke="currentColor"
                      stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round"
                          d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                  </svg>
                </button>
              </div>

            </div>
            <div class="w-max">
              <label class="label">
                <input
                  class="checkbox checked:bg-customTeal "
                  type="checkbox"
                  formControlName="includeHashTag"
                />
                <span class="block tracking-wide  text-base font-semibold text-gray-500 pl-2"
                  >Include hashtags?
                </span>
              </label>
            </div>

            <div class="" *ngIf="includeHashTag.value">
              <label class="label">
                <span class="block tracking-wide text-gray-500 text-base font-semibold"
                  >How many hashtags do you want to include?
                </span>
              </label>
              
              <input
                type="number"
                placeholder="Hashtags"
                class="appearance-none block input input-bordered rounded-sm border-gray-300 bg-gray-50 text-gray-700 leading-tight focus:outline-none focus:border-gray-500"
                formControlName="numberOfHashTags"
              />
            </div>
          </div>
          <div>
            <app-button
            class="justify-end items-end"
            [customConfig]="{
              text: 'Go to Ai Assist',
              type: 'Submit',
              buttonType: 'primaryButton'
            }"
          ></app-button>
          </div>

         
        </div>
      </form>
    </div>
  </div>
</div>
