<div class="modal modal-open" id="chatGptModal">
  <div
    class="modal-box w-full md:max-w-7xl my-1 md:my-8 rounded-md max-h-screen m-6"
  >
    <div class="flex justify-between">
      <div>
        <h3 class="font-bold text-lg">Image generator</h3>
      </div>
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-6 h-6 cursor-pointer"
          (click)="closeModal()"
          viewBox="0 0 384 512"
        >
          <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
          <path
            d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
          />
        </svg>
      </div>
    </div>

    <!-- <div class="flex">
      <div>images list</div> -->

    <div class="py-4" #messagesForm>
      <form
        [formGroup]="chatForm"
        class=""
        (ngSubmit)="submitChat(chatForm.value)"
      >
        <div class="flex flex-col gap-y-4">
          <div class="flex gap-4 space-y-4">
            <div class=" w-full flex space-x-4 space-y-2">
              <!-- Prompt -->
              <div class="w-full md:w-1/3 ">

                  <div
                    class=" md:mb-4 flex w-full flex-col space-y-4  items-stretch"
                  >
                    <textarea
                      class="relative m-0 -mr-0.5  overflow-y-scroll h-auto block w-full min-w-0 flex-auto rounded-md border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:focus:border-primary"
                      [ngClass]="{ ' bg-gray-100': resLoading }"
                      formControlName="content"
                      placeholder="Type something...."
                      maxlength="200"
                      [disabled]="resLoading"
                      aria-label="Prompt ChatGPT"
                      aria-describedby="button-addon1"
                    ></textarea>

                 <div class="flex justify-end">
                     <!-- Search button -->
                     <button
                     *ngIf="!resLoading"
                     class=" flex items-center rounded-r bg-customTeal px-6 py-2 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-primary-700 hover:shadow-lg focus:bg-primary-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary-800 active:shadow-lg"
                     type="submit"
                     id="button-addon1"
                     data-te-ripple-init
                     data-te-ripple-color="light"
                     [disabled]="resLoading"
                   >Generte Image
                     <svg
                       xmlns="http://www.w3.org/2000/svg"
                       viewBox="0 0 512 512"
                       fill="white"
                       class="h-5 w-5"
                     >
                       <path  
                         d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z"
                       />
                     </svg>
                     
                     <!-- SVG Icon or other content for the button -->
                   </button>
                   <button
                     *ngIf="resLoading"
                     class="relative z-[2] flex items-center rounded-r bg-darkGray px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-primary-700 hover:shadow-lg focus:bg-primary-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary-800 active:shadow-lg"
                     type="button"
                     id="button-addon1"
                     data-te-ripple-init
                     data-te-ripple-color="light"
                     [disabled]="resLoading"
                   >
                     <!-- Spinner or other content for the loading button -->
                     <span
                       class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                       >Generating content...</span
                     >
                   </button>
                 </div>
                  </div>
                
              </div>
              <!-- response -->
              <div
                #messagesContainer
                [scrollTop]="messagesContainer.scrollHeight"
                class="h-128 px-1 w-full md:w-2/3 border border-solid border-gray-300 md:px-4 py-4 overflow-y-scroll bg-gray-50 rounded-md"
              >
                <div
                  *ngIf="!selectedImage"
                  class="w-full h-full space-y-3 text-center flex flex-col justify-center items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-7 h-7 fill-slate-400"
                    viewBox="0 0 512 512"
                  >
                    <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                    <path
                      d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM164.1 325.5C182 346.2 212.6 368 256 368s74-21.8 91.9-42.5c5.8-6.7 15.9-7.4 22.6-1.6s7.4 15.9 1.6 22.6C349.8 372.1 311.1 400 256 400s-93.8-27.9-116.1-53.5c-5.8-6.7-5.1-16.8 1.6-22.6s16.8-5.1 22.6 1.6zM144.4 208a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm192-32a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"
                    />
                  </svg>
                  <p class="text-base text-gray-500">
                    Type something...... <br />
                    Get your first response
                  </p>
                </div>

                <div
                  *ngIf="selectedImage"
                  class="w-full"
                >
                <img loading="lazy"
                    [src]="selectedImage.url" class="object-cover w-full md:w-1/3 h-96 rounded-md cover" alt="Image" />
                  <div
                    *ngFor="let response of chatResponse; let i = index"
                    class="py-1"
                  >
                    <!-- User question -->
                    <!-- <div
                        class="flex justify-start pr-8 space-x-2"
                        *ngIf="response.message.role !== 'assistant'"
                      >
                        <app-circle-img
                          [data]="imageData('user')"
                        ></app-circle-img>

                        <div class="px-4 py-2 bg-gray-200 max-w-max rounded-md">
                          <p class="font-bold text-sm">
                            {{ response.message.role | titlecase }}
                          </p>
                          <p class="text-sm">{{ response.message.content }}</p>
                        </div>
                      </div> -->
                  </div>
                </div>
              </div>
            </div>
            <!-- preview -->
            <div>
              <div
                    class="pt-10 flex flex-col gap-5  p-3 items-center border-solid border border-gray-400 rounded h-fit w-full"
                    *ngIf="imageUrls && imageUrls.length >0">
                    <p class="label-text text-[#4F4F4F]  font-bold leading-5 py-3 text-xl">Images</p>
                    <div class="hidden md:flex flex-wrap gap-8">
        
        
                      <div class="relative" *ngFor="let i of imageUrls;let li=index">
                        <img loading="lazy" [src]="i.url" class="object-cover  w-24 h-24 rounded-md cover" alt="Image" (click)="selectImage(i)">
                        <!-- <button (click)="removeImage(li)"
                          class="absolute top-0 translate-x-16 -translate-y-4 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                          <i class="fa fa-trash text-md"></i>
                        </button> -->
                      </div>
        
                    </div>
              </div>
        
            </div>
          </div>
        </div>
      </form>
    </div>
    <!-- </div> -->

    
  </div>
</div>
