import { Component, OnInit, } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ImageModel, ImageUploaderResponseModel } from 'src/app/models/ImageModel';
import { MediaService } from 'src/app/services/api/media.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { UserService } from 'src/app/services/firebase/user.service';
import { EmployeeConversationsModel, GroupConversationsModel } from 'src/app/models/Conversations.Model';
import { ConversationsService } from 'src/app/services/firebase/conversations.service';
import { MessagesModel, NotificationModel } from 'src/app/models/Messages.Model';
import { MessagingService } from 'src/app/services/firebase/messaging.service';
import { AreasOfInterestService } from 'src/app/services/firebase/areas-of-interest.service';
import { DepartmentService } from 'src/app/services/firebase/department.service';
import { FCMPushNotificationsService } from 'src/app/services/firebase/FCMPushNotifications.service';
import { PermissionService } from 'src/app/services/firebase/permission.service';
import { NotificationPreferenceService } from 'src/app/services/firebase/notification-preference.service';

// declare var navigator: any;

@Component({
    selector: 'app-employee-messages',
    templateUrl: './employee-messages.component.html',
    styleUrls: ['./employee-messages.component.css'],

})
export class EmployeeMessagesComponent implements OnInit {

    isMobile = false
    showMessages = false
    isAdmin = false
    chats = []
    filteredChat = []
    currentUser
    userId
    loadingChat = false
    employeeList = []
    filteredEmployeeList = []

    createModalOpen = false
    newChatForm: UntypedFormGroup
    step = 1

    // images
    imgErrorMsg: any;
    base64Image: any;
    binDoc: File;
    mobileBase64Image: any;

    //toast
    toastMessage: any;
    toastClass: any;
    toastType: any;
    openToast = false;

    searchChatQuery = ''
    isSearch = false
    selectedEmployeeChat

    selectedConv;
    convUserList = []
    chatSelected = false
    departments = []
    selectedDepartments = []
    depFilterClicked = false
    onEdit = false

    perm: any;
    permMember: any;
    permPES: any;
    permAnalytics: any;
    permSocial: any;
    permSpecial: any;
    permChat: any;

    constructor(
        private authService: AuthService,
        private userService: UserService,
        private fb: UntypedFormBuilder,
        private mediaService: MediaService,
        private conversationsService: ConversationsService,
        private messagingService: MessagingService,
        private fcmPushService: FCMPushNotificationsService,
        private departService: DepartmentService,
        private permissionService: PermissionService,
        private notificationPreferenceService: NotificationPreferenceService


    ) {
        if (typeof window['Capacitor'] !== 'undefined' && window['Capacitor']['platform'] !== 'web') {
            this.isMobile = true;
        }
    }

    ngOnInit() {
        this.newChatForm = this.fb.group({
            name: ['', Validators.required],
            profilePicture: [''],
            description: [''],
            searchQuery: [''],
            users: this.fb.array([])
        });

        this.currentUser = this.authService.getGlobalUser();
        if (this.currentUser) {
            if (this.currentUser.role == "Admin") {
                this.isAdmin = true;
            }
            this.userId = this.currentUser.uid;

            if (this.currentUser.role == "Admin") {
                this.loadingChat = true
                this.conversationsService.getEmployeeChats().valueChanges().subscribe(chats => {
                    if (chats) {
                        this.loadingChat = false
                        this.chats = chats;
                        this.chats.sort((a, b) => (b as any).lastMessage.timeSent.toDate() - (a as any).lastMessage.timeSent.toDate());
                        this.filteredChat = this.chats
                    }
                    this.loadingChat = false
                });

                this.departService.getDepartment().valueChanges().subscribe(a => {
                    if (a) {
                        this.departments = a;
                    }
                });
            } else if (this.currentUser.role == "Employee") {
                this.loadingChat = true
                this.conversationsService.getEmployeeChatsByUserId(this.userId).valueChanges().subscribe(chats => {
                    if (chats) {
                        this.loadingChat = false
                        this.chats = chats;
                        this.chats.sort((a, b) => (b as any).lastMessage.timeSent.toDate() - (a as any).lastMessage.timeSent.toDate());
                        this.filteredChat = this.chats
                    }
                    this.loadingChat = false
                });
            }

            this.permissionService.getPermissionByEmployeeId(this.currentUser.uid).valueChanges().subscribe(perm => {
                if (perm.length != 0) {
                    this.perm = perm[0];
                    //this.permission = this.perm.permission[0];
                    this.permMember = this.perm.permission[0]
                    this.permPES = this.perm.permission[1]
                    this.permAnalytics = this.perm.permission[2];
                    this.permSocial = this.perm.permission[3];
                    this.permSpecial = this.perm.permission[4];
                    this.permChat = this.perm.permission[5];
                }
            })
        }

    }
    get name() { return this.newChatForm.get('name'); }
    get searchQuery() { return this.newChatForm.get('searchQuery'); }
    get profilePicture() { return this.newChatForm.get('profilePicture'); }
    get users() { return this.newChatForm.get('users') as UntypedFormArray }

    onMessageSent(values) {
        let newMessageObj = new MessagesModel();
        newMessageObj.timeStamp = new Date();
        newMessageObj.message = values.message;
        newMessageObj.fromId = this.currentUser.uid
        newMessageObj.conversationId = this.selectedConv.id

        newMessageObj.toId = this.selectedConv.id

        newMessageObj.edited = false;
        newMessageObj.likes = {
            count: 0,
            users: []
        };
        newMessageObj.attachment = values.attachment
        this.messagingService.createMessage(newMessageObj);
        // create last message for this conversation


        let lastMessage = { message: '', timeSent: null };
        lastMessage.timeSent = newMessageObj.timeStamp;

        lastMessage.message = `${this.currentUser.GivenName || this.currentUser.FirstName}:  ${newMessageObj.message}`;
        // this.conversationsService.updateEmployeelastMessage(this.selectedConv.id, lastMessage);

        let unreadCount = this.selectedConv.unreadCount.map(u => {
            if (u.user !== this.currentUser.uid) {
                u.unreadCount = u.unreadCount + 1
            }
            else {
                u.unreadCount = 0
            }
            return u
        })

        this.selectedConv.unreadCount = unreadCount
        this.selectedConv.lastMessage = lastMessage
        this.conversationsService.updateEmployeeChat(this.selectedConv)
        this.sendNotification(newMessageObj)

    }

    async sendNotification(newMessageObj) {
        let notification = new NotificationModel()
        notification.title = this.selectedConv.name
        notification.body = newMessageObj.message
        notification.data = { chatType: 'employee', notificationType: 'Message' }

        // iterate over the users array and remove the current user id from the list
        let users = this.selectedConv.users.filter(user => user !== this.currentUser.uid)
        if (newMessageObj.attachment.type && newMessageObj.attachment.type === 'image') {
            notification.image = newMessageObj.attachment.link
        }


        // to grab the whole user info ..
        let fullUsersList = [];

        // iterate over the users array and grab the whole user information
        for (let i = 0; i < users?.length; i++) {

            let user = this.selectedConv.usersInfo.find((selectedUser) => selectedUser?.uid == users[i]);
            fullUsersList.push(user);

        }

        this.selectedConv.usersInfo = fullUsersList;

        // send notification for the selected users
        if (fullUsersList?.length) {
            await this.notificationPreferenceService.sendNotificationForListOfUsers(notification, fullUsersList);
        }
    }

    onEmployeeChatSelect(event) {

        this.selectedConv = event.chat
        this.chatSelected = true;
        this.convUserList = this.selectedConv?.usersInfo;

    }
    onMobileChatSelect(event) {
        this.showMessages = true
        this.onEmployeeChatSelect(event)
    }

    onBack(event) {
        this.showMessages = false
    }
    onCreateGroup(updateUser = false) {
        this.createModalOpen = true

        this.userService.getUserByRole('Employee').valueChanges().subscribe((employees: any) => {
            this.employeeList = employees
            this.filteredEmployeeList = employees

            if (updateUser) {
                this.employeeList.push(this.currentUser)
                this.employeeList.map(user => {
                    if (this.selectedConv.users.includes(user.uid)) {
                        this.selectEmployee(user)
                    }

                })
            }
        });
    }
    closeModal() {
        this.onEdit = false
        this.closeCreateModal()
        this.removeFile()
    }

    closeCreateModal() {
        this.step = 1
        this.createModalOpen = false
        this.filteredEmployeeList = []
        this.newChatForm.reset()
        this.users.clear()

    }

    removeEmployeeFromGroup(index) {
        this.users.removeAt(index)
    }

    searchEmployeeChat() {
        this.isSearch = true
        if (this.searchChatQuery && this.searchChatQuery.trim() != '') {
            this.filteredChat = this.chats.filter(group => {
                return group.name.toLowerCase().includes(this.searchChatQuery.toLowerCase())
            })

        } else {
            this.filteredChat = this.chats
            this.isSearch = false

        }
    }

    // applyFilter(){
    //     this.filteredEmployeeList=this.employeeList
    //     if (this.searchFilterClicked){
    //       this.searchEmployee()
    //     }
    //     if(this.depFilterClicked){
    //       this.onDepSelect()
    //     }


    //   }

    onDepSelect(event, department) {
        let deptIndex = this.selectedDepartments.indexOf(department.name)

        if (event.target.checked) {
            if (deptIndex === -1) {
                this.selectedDepartments.push(department.name)
            }
        } else {
            if (deptIndex !== -1) {
                this.selectedDepartments.splice(deptIndex, 1);


            }
        }
        if (this.selectedDepartments.length === 0) {
            this.selectedDepartments = this.departments.map(dep => dep.name)
        }

        // if( deptIndex != 1){


        // }

        this.filteredEmployeeList = this.employeeList.filter(employee => this.selectedDepartments.includes(department.name))

    }

    searchEmployee() {
        let value = this.searchQuery.value
        if (value && value.trim() != '') {
            this.filteredEmployeeList = this.employeeList.filter(friend => {
                return (friend.GivenName.toLowerCase().includes(value.toLowerCase()) || friend.LastName.toLowerCase().includes(value.toLowerCase()))
            })


        } else {
            this.filteredEmployeeList = []
        }
    }
    selectEmployeeChat(chat, search = false) {
        if (search) {
            this.searchChatQuery = ''
            this.isSearch = false

        }
        this.selectedEmployeeChat = chat

    }
    nextStep() {
        this.step = this.step === 2 ? 1 : 2;
    }

    selectEmployee(employee) {
        let userExists = this.users.value.some(u => u.uid == employee.uid)
        if (userExists) return

        let name = employee.GivenName || employee.FirstName
        let userData = new UntypedFormGroup({
            'firstName': new UntypedFormControl(name),
            'lastName': new UntypedFormControl(employee.LastName),
            'uid': new UntypedFormControl(employee.uid),
            'role': new UntypedFormControl(employee?.role)
        })
        this.users.push(userData)


    }
    onGroupEdit(event) {
        this.newChatForm.patchValue({
            name: this.selectedConv.name,
            description: this.selectedConv.description,
            profilePicture: this.selectedConv.profilePicture
        })

        this.onEdit = true
        this.onCreateGroup(true)
    }



    createChat(values) {
        this.closeCreateModal()

        if (this.base64Image) {
            // this.loading=true
            this.uploadImage(values)
        } else {
            this.saveChat(values)
        }
    }


    uploadImage(values) {
        if (this.isMobile) {
            if (this.mobileBase64Image) {
                const img = new ImageModel();
                img.imgBase64 = this.mobileBase64Image;
                this.mediaService.uploadImage(img).subscribe((upload) => {
                    // this.loading = false;
                    if (upload) {
                        values.profilePicture = upload.imgLink;
                        this.saveChat(values)
                        return;
                    }
                });
            }
        }
        else {
            if (this.binDoc) {
                this.mediaService.UploadBinImage(this.binDoc).subscribe(
                    upload => {
                        // this.loading = false;

                        if (upload) {
                            values.profilePicture = upload.imgLink;
                            this.saveChat(values)
                            return;
                        }
                    }
                );
            }
        }
    }


    saveChat(values) {
        if (this.onEdit) {
            this.updateGroup(values)
            this.onEdit = false
            return
        }
        let model = new EmployeeConversationsModel()
        model.admin = this.currentUser.uid
        model.dateCreated = new Date()
        model.name = values.name
        model.description = values.description
        model.profilePicture = values.profilePicture || ''
        model.users = values.users.map(user => user.uid)
        model.usersInfo = values.users;


        model.users.push(this.currentUser.uid)

        model.unreadCount = model.users.map(user => { return { 'user': user, 'unreadCount': 0 } })


        let lastMessage = { message: '', timeSent: null };
        lastMessage.message = '';
        lastMessage.timeSent = new Date();
        model.lastMessage = lastMessage;
        model.previousMembers = [];

        this.conversationsService.createEmployeeConversation(model).then(x => {
            this.toast({ html: 'New Group created!', classes: 'green', type: 'success' });
            // this.subscribe(model.name,model.users)
        }).catch(() => {
            this.toast({ html: 'Something went wrong creating group.', classes: 'red', type: 'failure' });
        });
    }

    updateGroup(values) {

        this.selectedConv.name = values.name
        this.selectedConv.description = values.description || ''
        this.selectedConv.profilePicture = values.profilePicture || ''


        let newUsers = [], allUsers = [], removedUsers = []
        values.users.map(user => {
            if (!this.selectedConv.users.includes(user.uid)) newUsers.push(user.uid)

            allUsers.push(user.uid)
        })
        this.selectedConv.users.map(user => {
            if (!allUsers.includes(user) && user !== this.currentUser.uid) {
                removedUsers.push(user)
            }
        })
        this.selectedConv.users = allUsers

        // remove user from unread count
        removedUsers.map(user => {
            let index = this.selectedConv.unreadCount.findIndex(u => u.user === user)
            if (index !== -1) {
                this.selectedConv.unreadCount.splice(index, 1)
            }
        })

        // if user was a previous memeber and added again remove from previous member list
        newUsers.map(user => {
            this.selectedConv.unreadCount.push({ 'user': user, 'unreadCount': 0 })
            let index = this.selectedConv.previousMembers.findIndex(u => u === user)
            if (index !== -1) {
                this.selectedConv.previousMembers.splice(index, 1)
            }

        })

        this.selectedConv.previousMembers = removedUsers


        this.conversationsService.updateEmployeeChat(this.selectedConv).then(x => {
            this.toast({ html: 'Employee Chat updated successfully!', classes: 'green', type: 'success' });
        }).catch(() => {
            this.toast({ html: 'Something went wrong updating group.', classes: 'red', type: 'failure' });
        });
        this.removeFile()
    }


    getEmployeeData(user) {

        let data = {
            "img": user.profileImgLink || '',
            "imgWidth": 12,
            "text": `${user.GivenName || user.FirstName} ${user.LastName}` || '',
        }
        return data
    }
    getChatData(chat) {
        return {
            "img": chat.profilePicture || '',
            "imgWidth": 14,
            "text": chat.name,
            "hasSecondaryText": Object.keys(chat.lastMessage).length > 0 ? true : false,
            "secondaryText": chat.lastMessage.timeSent,
            "secondaryTextType": 'date'
        }
    }
    onImageUpload(response: ImageUploaderResponseModel) {
        if (response.error) {
            this.imgErrorMsg = response.errorMessage
            return
        }

        this.binDoc = response.binDoc;
        this.mobileBase64Image = response.imgBase64;
        this.base64Image = 'data:image/jpeg;base64,' + response.imgBase64;
    }


    handleMobilePhotoInput() {
        // navigator.camera.getPicture((image: any) => {
        //     const imageName = 'IMG.jpeg';
        //     const imageBlob = this.dataURItoBlob(image);
        //     const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });
        //     this.binDoc = imageFile;
        //     this.base64Image = 'data:image/jpeg;base64,' + image;
        //     this.mobileBase64Image = image;

        // }, (error) => {
        //     alert(error);
        // },
        //     {
        //         quality: 50,
        //         destinationType: navigator.camera.DestinationType.DATA_URL,
        //         encodingType: navigator.camera.EncodingType.JPEG,
        //         mediaType: navigator.camera.MediaType.PICTURE,
        //         sourceType: navigator.camera.PictureSourceType.PHOTOLIBRARY,
        //     }
        // );
    }

    handleImageInput(files: FileList) {
        if (!this.validateImage(files[0].name)) {
            this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';

            return;
        }
        this.binDoc = files[0];
        this.imgErrorMsg = undefined;
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        this.removeSavedFile()

        reader.onload = () => {
            this.base64Image = reader.result as string;
        };
    }

    private validateImage(name: any) {
        const ext = name.substring(name.lastIndexOf('.') + 1);
        if (ext.toLowerCase() === 'png') {
            return true;
        } else if (ext.toLowerCase() === 'jpg') {
            return true;
        } else if (ext.toLowerCase() === 'jpeg') {
            return true;
        } else {
            return false;
        }
    }

    dataURItoBlob(dataURI) {
        const byteString = window.atob(dataURI);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([int8Array], { type: 'image/png' });
        return blob;
    }

    removeFile() {
        this.binDoc = undefined;
        this.base64Image = undefined;
    }
    removeSavedFile() {
        this.profilePicture.patchValue('')
    }

    toast(obj) {
        this.toastMessage = obj.html;
        this.toastClass = obj.classes ? obj.classes : 'green';
        this.toastType = obj.type ? obj.type : 'success';
        this.openToast = true;
        setTimeout(() => {
            this.openToast = false;
        }, 2000);

        this.removeFile()
        this.newChatForm.reset()
    }


}
