<div class="modal modal-open "  id="cancelModal">
  <div class="modal-box  w-11/12 max-w-4xl ">
    <h3 class="font-bold text-lg  mb-2">Image Resize</h3>
    <div class="flex flex-col w-full">
      <div class="flex flex-col-reverse md:flex-row">
        <div class="w-full md:w-5/12 space-y-4">
          <div class="flex flex-col space-y-1">
            <p class="font-bold">Rotate</p>
            <div class="flex flex-row  space-x-4">
              <button (click)="rotateLeft()" class="px-4 py-2 bg-gray-200 rounded-md">left<i class="material-icons float-right">rotate_left</i> </button>
              <button (click)="rotateRight()" class="px-4 py-2 bg-gray-200 rounded-md">right<i class="material-icons float-right">rotate_right</i></button>
            </div>
          </div>

          <div class="flex flex-col space-y-1">
            <p class="font-bold">Flip</p>
            <div class="flex flex-row space-x-4 ">
              <button (click)="flipHorizontal()" class="px-4 py-2 bg-gray-200 rounded-md">horizontal<i class="material-icons rotate-90 float-right">flip</i></button>
              <button (click)="flipVertical()" class="px-4 py-2 bg-gray-200 rounded-md">vertical <i class="material-icons float-right">flip</i></button>
            </div>
          </div>
          <!-- <div class="flex flex-col space-y-1">
            <p class="font-bold">Contain Within Aspect Ratio</p>
            <button (click)="toggleContainWithinAspectRatio()" class="px-4 py-2 bg-gray-200 rounded-md w-max">{{containWithinAspectRatio?'Fill':'Contain'}}</button>
          </div> -->
                
          <div class="flex flex-col space-y-1">
            <p class="font-bold">Reset Image</p>
            <button (click)="resetImage()" class="px-4 py-2 bg-gray-200 rounded-md w-max">Reset</button>
          </div>
          <!-- <div class="flex flex-col space-y-1">
            <p class="font-bold">Rotate</p>
            <input [(ngModel)]="rotation" class="input input-bordered w-full" placeholder="Rotation" type="number" (keyup)="updateRotation()" />   
          </div> -->

           <!-- <div class="flex flex-col space-y-1" [ngClass]= "{'hidden': comingFrom == 'appSetup'}">
            <p class="font-bold">Zoom</p>
            <div class="flex">
              <div (click)="zoomOut()" *ngIf="zoomScale>10"><i class="material-icons">zoom_out</i></div>
              <div class="w-full"> <input type="range" min="10" max="50" (change)="zoomFromRange()" [(ngModel)]="zoomScale" value="10" class="range range-xs w-full" /> </div>
              <div (click)="zoomIn()"><i class="material-icons">zoom_in</i></div>
            </div>
          </div> -->

          <!-- <img [src]="croppedImage" [style.border]="croppedImage ? '1px solid black' : 'none'" /> -->
          <div class="modal-action items-center space-x-4">
            <label ><button class="font-bold text-gray-600 bg-gray-200 border-2 border-gray-500 rounded-md px-4 py-2 hover:bg-red-500 hover:text-white hover:rounded-md hover:font-normal border-none hover:p-2"
              type="button" (click)="closeCroppingModal();">cancel</button>
            </label>
            <label>
            <button (click)="handleCroppedFile()"
            class="py-2 px-5 bg-customTeal text-white border-customTeal rounded-md">
              Save
            </button>
          </label>
          </div>
        </div>

        <div class="w-full flex justify-end md:w-7/12 ">
                
          <div class="flex md:w-8/12 w-full max-h-max backdrop-blur-lg">
            
              <image-cropper *ngIf="!useBase64" class="ngx-ic-cropper"
                  [imageChangedEvent]="theImage"
                  [maintainAspectRatio]="true"
                  [containWithinAspectRatio]="containWithinAspectRatio"
                  [aspectRatio]="aspectRatio"
                  [roundCropper]="false"
                  [canvasRotation]="canvasRotation"
                  [transform]="transform"
                  [alignImage]="'left'"
                  [style.display]="showCropper ? null : 'none'"
                  [allowMoveImage]="true"
                  (imageCropped)="imageCropped($event)"
                  (imageLoaded)="imageLoaded()"
                  (cropperReady)="cropperReady($event)"
                  (loadImageFailed)="loadImageFailed()"
              ></image-cropper>

              <image-cropper *ngIf="useBase64" class="ngx-ic-cropper"
                  [imageBase64]="theImage"
                  [maintainAspectRatio]="true"
                  [containWithinAspectRatio]="containWithinAspectRatio"
                  [aspectRatio]="aspectRatio"
                  [onlyScaleDown]="true"
                  [roundCropper]="false"
                  [canvasRotation]="canvasRotation"
                  [transform]="transform"
                  [alignImage]="'left'"
                  [style.display]="showCropper ? null : 'none'"
                  [allowMoveImage]="true"
                  (imageCropped)="imageCropped($event)"
                  (imageLoaded)="imageLoaded()"
                  (cropperReady)="cropperReady($event)"
                  (loadImageFailed)="loadImageFailed()"
              ></image-cropper>

          </div>

          <img loading="lazy" [src]="croppedImage" [style.border]="croppedImage ? '1px solid black' : 'none'"  class="md:w-4/12 md:h-28 hidden md:block"/>

         


        </div>

      
      </div>

        <!-- <div class="flex justify-end">
          <img [src]="croppedImage" class="hidden md:block" width="72" height="72"/>
        </div> -->

      <!-- <div class="modal-action items-center justify-between">
        <label ><button class="font-bold text-red-500 hover:bg-red-500 hover:text-white hover:rounded-md hover:font-normal border-none hover:p-2"
          type="button" (click)="closeCroppingModal();">cancel</button>
        </label>
        <label>
        <button (click)="handleCroppedFile()"
        class="py-2 px-4 bg-customTeal text-white border-customTeal rounded-md">
          Save
        </button>
      </label>
      </div> -->

     
    </div>
  </div>
</div>