import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-cancel-confirmation',
  templateUrl: './cancel-confirmation.component.html',
  styleUrls: ['./cancel-confirmation.component.css']
})
export class CancelConfirmationComponent implements OnInit {

  @Input() canceledFromAction;
  @Input() canceledFromComponent;
  @Output() cancelClicked = new EventEmitter();
  @Output() continueClicked = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  cancelActionClicked(){
    this.cancelClicked.emit();
  }
  continueActionClicked(){
    this.continueClicked.emit();
  }

}
