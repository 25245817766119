<div class="flex justify-center h-screen" *ngIf="loading">
  <progress class="progress progress-customTeal w-full"></progress>
</div>

<div *ngIf="!loading">
  <div *ngIf="userProfile">
    <div class="border-b-4 border-customTeal py-5">
      <div class="font-bold text-2xl text-left  flex gap-1 items-center">
        <span>Create a post</span>
        <div class="tooltip" class="flex justify-center"
          data-tip="Create a post and share it to one or more of your social media accounts">
          <button type="button" class="flex justify-center"><i class="material-icons">help</i></button>
        </div>
      </div>

    </div>




    <div *ngIf="hasActiveAccounts">
      <div class="grid grid-cols-1 md:grid-cols-3 gap-9 py-5">
        <div class="py-2 col-span-2">
          <form [formGroup]="postForm" class="" (ngSubmit)="submitPost(postForm.value)">

            <div class="grid grid-cols-1 gap-y-3 ">




              <div class="form-control w-full max-w-x">
                <label class="label">
                  <span class="label-text text-[#4F4F4F] text-base font-bold leading-5">Post Text / Caption</span>
                </label>
                <textarea class="textarea textarea-bordered" formControlName='post'></textarea>
                <div class="flex justify-end my-1 space-x-2">
                  <app-button data-te-toggle="tooltip" data-te-placement="bottom" title="Tooltip on top"
                    class="justify-end items-end"
                    [customConfig]="{ text: 'AI Assist', type: 'Button', buttonType: 'secondaryButton' }"
                    (click)="onUseChatGpt()"></app-button>
                  <div class="tooltip tooltip-bottom my-2 text-white cursor-pointer"
                    data-tip="ChatGPT is an invaluable tool for content creators seeking inspiration and assistance in generating high-quality content. With its vast knowledge and language capabilities, ChatGPT acts as a creative partner, helping to overcome writer's block and enhancing the content creation process. By simply engaging in a conversation, you can tap into the model's deep understanding of various topics, enabling you to brainstorm ideas, refine concepts, and explore new perspectives. Whether you need assistance with generating engaging headlines, crafting compelling narratives, or conducting research on a specific subject, ChatGPT provides valuable insights and suggestions to elevate your content to new heights. Its ability to adapt to different writing styles and tones ensures that the final output remains authentic and aligned with your vision. ChatGPT is a powerful ally, transforming the content creation experience into a collaborative and efficient journey.">
                    <span class="font-bold text-lg text-customTeal">&#63;</span>
                  </div>
                </div>

                <!-- <angular-editor formControlName="message" [config]="editorConfig"></angular-editor> -->

                <!-- ChatGpt Modal -->
                <app-chat-gpt *ngIf="chatGptModalOpen" [inputType]="'socialMedia'" [theInput]="selectedInput"
                  [fromComponent]="fromComponent" [requestMessage]="requestMessage"
                  (onMessageSelect)="updateFormValue($event)" (closeChatGptModal)="closeChatGptModal()"></app-chat-gpt>

              </div>

              <div class="form-control w-full max-w-x static">
                <label class="label">
                  <span class="label-text text-[#4F4F4F] text-base font-bold leading-5"> Media(Image/video)</span>
                </label>

                <div class="hidden md:flex gap-x-3 w-full">
                  <div class="w-1/2">

                    <div
                      class="flex justify-center content-center w-full h-44 max-w-x bg-white rounded-lg border-2 gap-x-3"
                      [ngClass]="{'!bg-gray-200 !text-black !border-0 ': (videoUrls.value.length >=1 || uploadingMedia)}">

                      <label class="cursor-pointer self-center flex flex-col"
                        [ngClass]="{'!cursor-not-allowed': videoUrls.value.length >=1 || uploadingMedia}">
                        <i class="fa fa-plus-circle text-customTeal text-3xl text-center self-center"
                          [ngClass]="{'text-gray-500': videoUrls.value.length >=1 || uploadingMedia }"
                          *ngIf="!imageUploading"></i>
                        <app-loader *ngIf="imageUploading" class="text-center self-center"></app-loader>
                        <input type="file" type="text" type="file" accept="image/*"
                          (change)="handleFileInput($event.target.files)"
                          [disabled]='videoUrls.value.length >=1 || uploadingMedia' />
                        <span class="block font-bold pt-1">{{imageUploading ? 'Uploading Image' : 'Upload
                          Images'}}</span>
                      </label>
                    </div>
                    <small *ngIf="videoUrls.value.length >=1" class="text-red-500 italic text-xs">Can't upload images
                      and video at the same time.
                    </small>
                  </div>


                  <div class="w-1/2">
                    <div
                      class="flex justify-center content-center w-full h-44 max-w-x bg-white rounded-lg border-2 gap-x-3"
                      [ngClass]="{'!bg-gray-200  !text-black !border-0': (videoUrls.value.length >=1 || imageUrls.value.length >=1 || uploadingMedia)}">
                      <label class="cursor-pointer  self-center flex flex-col "
                        [ngClass]="{'!cursor-not-allowed': videoUrls.value.length >=1 || imageUrls.value.length >=1 || uploadingMedia}">
                        <i class="fa fa-plus-circle text-customTeal text-3xl text-center self-center"
                          *ngIf="!videoUploading"
                          [ngClass]="{'text-gray-500': videoUrls.value.length >=1 || imageUrls.value.length >=1 || uploadingMedia }"></i>
                        <app-loader *ngIf="videoUploading" class="text-center self-center"></app-loader>
                        <input type="file" type="text" type="file" accept="video/*"
                          (change)="handleVideoInput($event.target.files)"
                          [disabled]='videoUrls.value.length >=1 || imageUrls.value.length >=1 || uploadingMedia' />
                        <span class="block font-bold pt-1">{{videoUploading ? 'Uploading Video' : 'Upload
                          Video'}}</span>
                      </label>

                    </div>
                    <small *ngIf="videoUrls.value.length >=1" class="text-red-500 italic text-xs">Only one video can be
                      posted at a time.
                    </small>
                    <small *ngIf="imageUrls.value.length >=1" class="text-red-500 italic text-xs">Can't upload images
                      and video at the same time.
                    </small>
                  </div>

                </div>
                <div class="md:hidden p-3" *ngIf="!isMobile">
                  <div class="flex">
                    <label
                      class="py-2 px-8 custom-file-upload  self-center flex flex-col text-customTeal border-2 border-customTeal rounded-lg"
                      [ngClass]="{'bg-[#cccccc] text-[#666666] border-0': videoUrls.value.length >=1 || uploadingMedia}">
                      <input type="file" type="text" type="file" accept="image/*"
                        (change)="handleFileInput($event.target.files)"
                        [disabled]='videoUrls.value.length >=1 || uploadingMedia' />
                      <span class="block font-bold pt-1">Upload Image</span>
                    </label>
                  </div>
                  <small *ngIf="videoUrls.value.length >=1" class="text-red-500 italic text-xs">Can't upload images and
                    video at the same time.
                  </small>
                </div>
                <div *ngIf="isMobile">
                  <app-image-uploader class="justify-start items-start p-3" (onImageUpload)=onImageUpload($event)
                    [disabled]='videoUrls.value.length >=1 || uploadingMedia'
                    [ngClass]="{'bg-[#cccccc] text-[#666666] border-0': videoUrls.value.length >=1 || uploadingMedia}"
                    [customConfig]="{'upload':true}"></app-image-uploader>

                  <small *ngIf="videoUrls.value.length >=1" class="text-red-500 italic text-xs">Can't upload images and
                    video at the same time.
                  </small>
                </div>


                <div class="md:hidden p-3">
                  <div class="flex">
                    <label
                      class="py-2 px-8 custom-file-upload  self-center flex flex-col text-customTeal border-2 border-customTeal rounded-lg"
                      [ngClass]="{'bg-[#cccccc] text-[#666666] border-0': (videoUrls.value.length >=1 || imageUrls.value.length >=1)}">
                      <input type="file" type="text" type="file" accept="video/*"
                        (change)="handleVideoInput($event.target.files)"
                        [disabled]='videoUrls.value.length >=1 || imageUrls.value.length >=1' />
                      <span class="block font-bold pt-1">Upload Video</span>
                    </label>

                  </div>
                  <small *ngIf="videoUrls.value.length >=1" class="text-red-500 italic text-xs">Only one video can be
                    posted at a time.
                  </small>
                  <small *ngIf="imageUrls.value.length >=1" class="text-red-500 italic text-xs">Can't upload images and
                    video at the same time.
                  </small>
                </div>
              </div>

              <div class="label-text text-[#4F4F4F] text-base font-bold leading-5">Select Platforms</div>
              <div class="flex flex-row flex-wrap gap-y-2 gap-x-7 justify-start">
                <div class="form-control w-7/12 relative" *ngIf="availablePlatforms.length >0">
                  <div (click)="isList = !isList"
                    class="w-full absolute p-4 shadow rounded bg-white text-sm font-medium leading-none text-gray-800 flex items-center justify-between cursor-pointer">
                    Select Platforms
                    <div>
                      <div class="hidden" id="close">
                        <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M5.00016 0.666664L9.66683 5.33333L0.333496 5.33333L5.00016 0.666664Z"
                            fill="#1F2937" />
                        </svg>
                      </div>
                      <div id="open">
                        <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M5.00016 5.33333L0.333496 0.666664H9.66683L5.00016 5.33333Z" fill="#1F2937" />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="isList" class="absolute mt-12 w-full p-3 bg-white shadow rounded" id="dropdown">
                    <div class="flex flex-col md:flex-wrap justify-start gap-x-2 gap-y-4 ">
                      <div class="" *ngFor="let platform of platforms.controls;let li=index" [formGroup]="platform">
                        <app-social-media-platform formGroupName="platform" (platformPick)="onPlatformSelect($event)"
                          [imageUrls]="imageUrls.value" [videoUrls]="videoUrls.value"
                          (platformError)="onPlatformError($event)">

                        </app-social-media-platform>
                      </div>
                    </div>

                    <button (click)="isList = false"
                      class="text-xs bg-indigo-100 hover:bg-indigo-200 rounded-md mt-6 font-medium py-2 w-full leading-3 text-indigo-700">Select</button>
                  </div>
                </div>

                <ng-container class="pt-10" *ngFor="let v of platforms.value">

                  <div class="pt-2 flex flex-wrap gap-x-2" *ngIf="v.value">
                    <i *ngIf="v.platform !== 'tiktok'"
                      [ngClass]="'text-xl fa ' + socialMediaIcons[v.platform].text + ' ' + socialMediaIcons[v.platform].color"></i>
                    <svg class="p-0" *ngIf="v.platform == 'tiktok'" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                      width="24" height="24" viewBox="0 0 50 50">
                      <path
                        d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z">
                      </path>
                    </svg>
                  </div>


                </ng-container>
                <div class="pt-10" *ngIf="pickedPlatforms(platforms.value).length == 0">
                  <!-- <br/> -->
                </div>

              </div>

              <div class="form-control w-full max-w-x">
                <label class="label py-2">
                  <span class="label-text text-[#4F4F4F] text-base font-bold leading-5">When to post</span>
                </label>
                <div class="flex justify-between w-1/2  ">
                  <div class="form-control">
                    <label class="label cursor-pointer">
                      <input type="radio" name="schedule" value="Schedule" formControlName='schedule'
                        class="radio checked:bg-customTeal" (click)="pickSchedule($event)" />
                      <span class="label-text ml-4 font-normal text-black">Schedule for Later</span>

                    </label>
                  </div>
                  <div class="form-control">
                    <label class="label cursor-pointer">
                      <input type="radio" name="schedule" value="Immediate" formControlName='schedule'
                        class="radio checked:bg-customTeal" (click)="pickSchedule($event)" />
                      <span class="label-text ml-4 font-normal text-black">Post Now</span>

                    </label>
                  </div>
                </div>



              </div>

              <div class="form-control w-full max-w-x md:w-9/12 flex flex-col md:flex-row justify-between gap-4"
                *ngIf="postForm.controls['schedule'].value === 'Schedule'">
                <div class="form-control w-full md:w-3/4">
                  <label class="label">
                    <span class="label-text text-[#636363] text-sm leading-5">Date</span>
                  </label>
                  <input type="date" placeholder="Type here" formControlName='scheduleDate'
                    class="input input-bordered w-full max-w-xs" />
                  <!-- <div *ngIf="(scheduleDate.dirty) &&  scheduleDate.invalid"> -->
                  <small *ngIf="scheduleDate.errors && scheduleDate.errors.required"
                    class="text-red-500 italic text-xs">Date is required for scheduled post.
                  </small>
                  <!-- </div> -->

                </div>
                <div class="w-1/2 md:w-1/4">
                  <label class="label">
                    <span class="label-text text-[#636363] text-sm leading-5">Time</span>
                  </label>
                  <input type="time" placeholder="Type here" formControlName='scheduleTime'
                    class="input input-bordered w-full max-w-xs" />
                  <!-- <div *ngIf="(scheduleTime.dirty) &&  scheduleTime.invalid"> -->
                  <small *ngIf="scheduleTime.errors && scheduleTime.errors.required"
                    class="text-red-500 italic text-xs">Time is required for scheduled post.
                  </small>
                  <!-- </div> -->
                </div>
              </div>

              <div class="form-control w-full max-w-x">
                <label class="label py-2">
                  <span class="label-text text-[#4F4F4F] text-base font-bold leading-5">Additional Options</span>
                </label>

                <div class="px-5 mb-2 md:mb-0">
                  <label class="label">
                    <span class="label-text text-[#4F4F4F] text-base  leading-5">Youtube visibility</span>
                  </label>
                  <select formControlName="youtubeVisibility" class="select select-bordered w-1/2 md:w-1/6  ">
                    <option disabled selected>Select Province</option>
                    <!-- <option class="font-bold text-black text-base" [value]=''> --- </option> -->
                    <ng-container class="pt-10" *ngFor="let visibility of youtubeVisibility">
                      <option [value]="visibility"><span class="font-bold text-black text-base">{{ visibility |
                          titlecase}}</span>
                      </option>
                    </ng-container>

                  </select>
                </div>
              </div>

              <div class="flex flex-row justify-between w-full max-w-x pt-20 p-b10">
                <div class="form-control ">
                  <button type="button" (click)="cancelModalOpen=true"
                    class="text-black font-bold rounded-lg w-full border-[1px] py-2 px-8 ">
                    Cancel
                  </button>
                </div>
                <div class="form-control flex flex-row gap-x-5 ">
                  <button type="button" (click)="openPreviewModal()" [disabled]="!postForm.valid"
                    class="py-2 px-8 modal-button gap-2 text-black border-2 border-customTeal rounded-lg w-full"
                    [ngClass]="{'bg-gray-300 text-black border-0 border-gray-300 cursor-not-allowed': !postForm.valid}">
                    Preview
                  </button>
                  <button type="submit" [disabled]="!postForm.valid"
                    class="py-2 px-12 modal-button gap-2  bg-customTeal rounded-lg text-white w-full flex gap-x-1"
                    [ngClass]="{'bg-gray-300 !text-black cursor-not-allowed': !postForm.valid}">

                    <p class="!inline">
                      Post
                    </p>
                    <app-loader *ngIf="sendingPost" class="!inline" [customConfig]="'h-5 w-5 text-white'"></app-loader>

                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="text-base col-span-1 flex justify-center border">
          <div class="pt-8 hidden md:flex flex-wrap gap-8 border " *ngIf="platforms.value">
            <ul class="list-disc text-red-700">

              <li *ngIf="imageUrls.errors  && pickedPlatforms(platforms.value ).includes('instagram','pinterest')">
                Image is required for Pinterest and Instagram.</li>
              <li *ngIf="videoUrls.errors && pickedPlatforms(platforms.value ).includes('pinterest','reddit')">Video is
                not supported fo Pinterest.</li>
              <li
                *ngIf="videoUrls.errors && (pickedPlatforms(platforms.value ).includes('tiktok') || pickedPlatforms(platforms.value ).includes('youtube')  )">
                Video url is
                required for TikTok and Youtube.</li>
              <li *ngIf="pickedPlatforms(platforms.value).includes('tiktok')">
                TikTok require that after uploading the video, users open their mobile TikTok app to finalize the
                processing.</li>
            </ul>
          </div>


          <div
            class="pt-10 flex flex-col gap-5  p-3 items-center border-solid border border-gray-400 rounded h-fit w-full"
            *ngIf="imageUrls && imageUrls.length >0">
            <p class="label-text text-[#4F4F4F]  font-bold leading-5 py-3 text-xl">Images</p>
            <div class="hidden md:flex flex-wrap gap-8">


              <div class="relative" *ngFor="let i of imageUrls.value;let li=index">
                <img loading="lazy" [src]="i" class="object-cover  w-24 h-24 rounded-md cover" alt="Image">
                <button (click)="removeImage(li)"
                  class="absolute top-0 translate-x-16 -translate-y-4 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                  <i class="fa fa-trash text-md"></i>
                </button>
              </div>

            </div>
          </div>


          <div class="pt-7" *ngIf="videoUrls && videoUrls.length >0">
            <p class="label-text text-[#4F4F4F] text-base font-bold leading-5 py-3">Videos</p>

            <div class="hidden md:flex flex-wrap gap-8">
              <div class="relative" *ngFor="let i of videoUrls.value;let li=index">
                <video controls [src]="i" class="object-cover  w-24 h-24 rounded-md cover" alt="Image"></video>
                <button (click)="removeVideo(li)"
                  class="absolute top-0 translate-x-16 -translate-y-4 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                  <i class="fa fa-trash text-md"></i>
                </button>
              </div>

            </div>
          </div>




        </div>


      </div>
      <div id="previewModal" *ngIf="previewModalOpen" class="modal"
        [ngClass]="{'modal-open': previewModalOpen, 'modal-action': !previewModalOpen }">
        <div class="modal-box  bg-white  w-11/12  md:w-9/12 max-w-5xl flex flex-col">

          <div class="flex justify-end">
            <a (click)="previewModalOpen=false" class="cursor-pointer"> <i class="text-xl fa fa-times"></i></a>
          </div>

          <div class="hidden md:flex tabs flex flex-wrap gap-3 justify-evenly items-center px-3 py-3">
            <ng-container class="pt-10" *ngFor="let v of platforms.value">
              <a (click)="previewPlatform(v.platform)" *ngIf="v.value" class="tab hover:border-b-2 border-customTeal">
                <i *ngIf="v.platform !== 'tiktok'"
                  [ngClass]="'text-xl fa mr-3 ' + socialMediaIcons[v.platform].text + ' ' + socialMediaIcons[v.platform].color"></i>
                <svg class="p-0" *ngIf="v.platform == 'tiktok'" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                  width="24" height="24" viewBox="0 0 50 50">
                  <path
                    d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z">
                  </path>
                </svg>
                <span class="font-bold text-black text-base">{{v.platform | titlecase}}</span></a>
            </ng-container>

          </div>

          <div class="block md:hidden pb-3">
            <select (change)="previewPlatform($event.target.value)" class="select select-bordered w-full max-w-xs">
              <option disabled selected>Select platform</option>
              <ng-container class="pt-10" *ngFor="let v of platforms.value">
                <option *ngIf="v.value" [value]="v.platform"><i
                    [ngClass]="'text-xl fa mr-3 ' + socialMediaIcons[v.platform].text + ' ' + socialMediaIcons[v.platform].color"></i>
                  <span class="font-bold text-black text-base">{{v.platform | titlecase}}</span>
                </option>
              </ng-container>

            </select>
          </div>


          <div class="flex justify-center items-center py-8 w-full">
            <app-post-preview class="flex justify-center items-center w-full " [data]="previewData"> </app-post-preview>
          </div>

        </div>
      </div>

      <app-alert class="fixed top-0 flex justify-end w-full" [toastType]="toastType" [toastMessage]="toastMessage"
        [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>
    </div>

    <div *ngIf="!hasActiveAccounts"
      class="mt-5 px-3 py-6 md:px-12 md:py-8 w-full border-2 border-red bg-white rounded-lg">

      <div class="p-b-8">

        <div class="px-5 md:px-0 py-14 flex flex-col w-full jusify-center gap-y-3">
          <div class="w-full py-4 text-center">
            <span class="text-lg font-bold pb-3 block">Link you accounts and create our first post today!</span>
            <!-- Lorem ipsum dolor, sit amet consectetur
            adipisicing elit. Laboriosam vero porro, ab quibusdam autem consequuntur consectetur iste
            dolores enim quisquam possimus ipsam nesciunt, ut doloremque dicta -->
          </div>

          <div class="flex justify-center">

            <app-link-account [link]="false" [new]="true"></app-link-account>
          </div>

        </div>

      </div>


    </div>
  </div>

  <!-- Cancel Modal -->
  <div class="modal" id="cancelModal" *ngIf="cancelModalOpen"
    [ngClass]="{'modal-open': cancelModalOpen, 'modal-action': !cancelModalOpen }">
    <div class="modal-box">
      <h3 class="font-bold text-lg text-center mb-2">Cancel</h3>
      <p class="py-4"> Are you sure you want to Cancel without
        posting? Any work that has not been posted
        or scheduled will not be saved</p>

      <div class="modal-action items-center justify-between">
        <label (click)="cancelModalOpen=false"
          class="py-2 px-4 bg-customTeal text-white border-customTeal rounded-md">Back to post</label>
        <label><button
            class="font-bold text-red-500 hover:bg-red-500 hover:text-white hover:rounded-md hover:font-normal border-none hover:p-2"
            type="button" (click)="cancel()">Yes, cancel</button></label>
      </div>
    </div>
  </div>