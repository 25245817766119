<div
  data-te-modal-init
  class="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
  id="nationsListModal"
  tabindex="-1"
  aria-labelledby="exampleModalScrollableLabel"
  aria-hidden="true"
>
  <div
    data-te-modal-dialog-ref
    class="pointer-events-none relative h-[calc(100%-1rem)] w-auto translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out max-[576px]:mx-3 min-[576px]:mx-auto mt-7 min-[576px]:h-[calc(100%-3.5rem)] min-[576px]:max-w-[1140px]"
  >
    <div
      class="pointer-events-auto relative flex max-h-[100%] w-full flex-col overflow-hidden rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600"
    >
      <div
        class="flex-col space-x-2 md:space-x-4 rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-2 md:p-4 dark:border-opacity-50"
      >
        <div class="flex flex-shrink-0 items-center justify-between">
          <!--Modal title-->
          <h5
            class="text-xl font-medium leading-normal text-neutral-800 dark:text-neutral-200"
            id="exampleModalScrollableLabel"
          >
            Search by First Nation
          </h5>
          <!--Close button-->
          <button
            type="button"
            class="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
            data-te-modal-dismiss
            aria-label="Close"
            (click)="closeModal()"
            id="closeNationSearch"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="h-6 w-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        <div class="flex flex-col">
          <!--Name, Number and Province filters-->
          <div class="space-x-2 py-2">
            <form
              [formGroup]="searchNationForm"
              (submit)="searchNations(searchNationForm.value)"
              class="w-full"
            >
              <div class="flex flex-wrap -mx-3 mb:2 md:mb-6">
                <div class="w-full md:w-2/6 px-3 mb-2 md:mb-0">
                  <label class="label">
                    <span
                      class="label-text text-[#4F4F4F] text-base font-bold leading-5"
                      >Name</span
                    >
                  </label>
                  <input
                    id="name"
                    type="text"
                    formControlName="name"
                    required
                    (keyup)="searchNations(searchNationForm.value)"
                    placeholder="Search for nation name"
                    class="input input-bordered w-full"
                  />
                </div>
                <!-- <div class="w-1/2 md:w-1/6 px-3 mb-2 md:mb-0">
                  <label class="label">
                    <span
                      class="label-text text-[#4F4F4F] text-base font-bold leading-5"
                      >Number</span
                    >
                  </label>
                  <input
                    id="number"
                    type="number"
                    formControlName="number"
                    required
                    placeholder="Number"
                    (keyup)="searchNations(searchNationForm.value)"
                    class="input input-bordered w-full"
                  />
                </div> -->
                <div class="w-1/2 md:w-1/6 px-3 mb-2 md:mb-0">
                  <label class="label">
                    <span
                      class="label-text text-[#4F4F4F] text-base font-bold leading-5"
                      >Province</span
                    >
                  </label>
                  <select (change)="searchNations(searchNationForm.value)" formControlName="province"  class="select select-bordered w-full ">
                    <option disabled selected>Select Province</option>
                    <!-- <option class="font-bold text-black text-base" [value]=''> --- </option> -->
                    <ng-container class="pt-10" *ngFor="let province of provinces">
                      <option [value]="province.code" ><span class="font-bold text-black text-base">{{ province.name}}</span>
                      </option>
                    </ng-container>
      
                  </select>

                  <!-- <label class="label">
                    <span
                      class="label-text text-[#4F4F4F] text-base font-bold leading-5"
                      >Province</span
                    >
                  </label>
                  <input
                    id="number"
                    type="text"
                    formControlName="province"
                    placeholder="Province"
                    (keyup)="searchNations(searchNationForm.value)"
                    class="input input-bordered w-full"
                  /> -->
                </div>
                <div class="w-full md:w-auto flex md:block px-3 md:mb-0 pt-max self-end justify-end items-end">
                  <button
                    type="submit"
                    class="ml-1 inline-block rounded bg-brandColor px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                  >
                    Search
                  </button>
                  <button
                    type="button"
                    (click)="clearSearch()"
                    class="ml-1 inline-block rounded bg-brandColor px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                  >
                    Clear
                  </button>
                </div>
              </div>
            </form>
          </div>
          <!--Letter filters-->
          <div class="hidden md:inline-flex flex-wrap gap-2" role="toolbar">
            <button
              type="button"
              class="inline-block rounded border border-solid border-brandColor text-brandColor font-bold px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white transition duration-150 ease-in-out hover:bg-primary-600 focus:bg-primary-600 focus:outline-none focus:ring-0 active:bg-primary-700"
              data-te-ripple-init
              data-te-ripple-color="light"
              *ngFor="let letter of letters"
              (click)="applyLetterFilter(letter)"
              [ngClass]="{
                'bg-brandColor text-white': letter == selectedLetter
              }"
            >
              {{ letter | titlecase }}
            </button>
          </div>

          <div class="block md:hidden">
            <select (change)="applyLetterFilter($event.target.value)"  class="select select-bordered w-full ">
              <option disabled selected>Select Letter</option>
              <ng-container class="pt-10" *ngFor="let letter of letters">
                <option [value]="letter"><span class="font-bold text-black text-base">{{ letter | titlecase }}</span>
                </option>
              </ng-container>

            </select>
          </div>
        </div>
      </div>

      <!--Modal body-->
      <div class="relative overflow-y-auto p-2 md:p-4 flex-col gap-x-3">
        <div class="inline-block min-w-full py-2 sm:px-6 lg:px-8">
          <div class="overflow-hidden">
            <table class="min-w-full text-left text-sm font-light border border-solid border-neutral-100">
              <thead class="border-b font-medium dark:border-neutral-500">
                <tr class="font-bold text-lg">
                  <th scope="col" class="px-6 py-4">Name</th>
                  <!-- <th scope="col" class="px-6 py-4">Number</th> -->
                  <th scope="col" class="px-6 py-4">Province</th>
                  <th scope="col" class="px-6 py-4">Address</th>
                </tr>
              </thead>
              <tbody *ngIf="!loading">
                <tr
                  *ngFor="let nation of filteredNations"
                  (click)="selectNation(nation)"
                  class="border-b transition duration-300 ease-in-out hover:bg-neutral-100 hover:cursor-pointer dark:border-neutral-500 dark:hover:bg-neutral-600"
                >
                  <td class="whitespace-nowrap px-6 py-4">
                    {{ nation.nationName }}
                  </td>
                  <!-- <td class="whitespace-nowrap px-6 py-4">
                    {{ nation.nationNumber }}
                  </td> -->
                  <td class="whitespace-nowrap px-6 py-4">
                    {{ nation.province }}
                  </td>
                  <td class="whitespace-nowrap px-6 py-4">
                    {{ nation.address }}
                  </td>
                </tr>
              </tbody>

              <app-loader
                *ngIf="loading"
                class="text-center self-center content-center"
              ></app-loader>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
