<div class="w-full flex justify-between min-h-screen !m-0">
  <div class="w-full md:w-2/3">
    <img loading="lazy" [src]="logo" class="w-52 py-4 pl-8 md:pl-16" alt="Going Mobile Logo" />
    <div
      class="text-lg text-brandColor text-center py-5 px-4 md:px-0"
      [ngClass]="{ 'text-brandColor': fromNationapp }"
    >
      <p class="font-bold text-4xl font-sans" [ngStyle]="{ color: textColor }">
        Welcome to the exclusive First Nation Leaders platform!
      </p>
      <p class="py-4 text-xl md:px-12">
        We are thrilled you're here! Apply now and unlock endless possibilities
        and connections.
      </p>
      <button
        type="button"
        (click)="navigateToURL()"
        class="text-lg font-bold inline-block rounded bg-brandColor px-8 pb-2 pt-2.5 font-medium leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-brandColor-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-brandColor-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-brandColor-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
      >
        Build my app
      </button>
    </div>
  </div>
  <div
    class="hidden md:block md:w-1/3 space-y-10"
    [ngStyle]="{
      background:
        'linear-gradient(
  to bottom,
  #e87a00 0%,
  #e87a00 65%,
  white 65%,
  white 100%
)'
    }"
  >
    <div class="flex justify-start items-start pt-[60%]">
      <img
        loading="lazy"
        [src]="'../../../../../assets/img/' + imgFolder + '/' + bgImg"
        class="w-full"
        alt="Man smiling"
      />
    </div>
  </div>
</div>
