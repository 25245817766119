<div id="Calendar" class="">
    <div class="text-xl p-4 bg-white text-center">
      <h6 ><strong> {{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</strong></h6>
    </div>

    <div class="row">
    <mwl-calendar-month-view
    [viewDate]="viewDate"
    [events]="events"
    [refresh]="refresh"
    [activeDayIsOpen]="activeDayIsOpen"
    (dayClicked)="dayClicked($event.day)"
    (eventClicked)="eventClicked($event)"
    >
    </mwl-calendar-month-view>
    </div>

    <div class="bg-white ">
      <div
        class="btn border-none text-white bg-blue-600 flpat-left rounded-r-none"
        mwlCalendarPreviousView
        [view]="view"
        [(viewDate)]="viewDate"
        (viewDateChange)="closeOpenMonthViewDay()"
      >
      <i class="material-icons float-left" style="margin-right: 5px;">today</i> Previous
      </div>
      <!-- <div
        class="btn blue " style="margin-right: 5px;"
        mwlCalendarToday
        [(viewDate)]="viewDate"
      >
      <i class="material-icons right" style="margin-left: 5px;">calendar_today</i> Today
      </div> -->
      <div
        class="btn border-none rounded-l-none text-white bg-green-600 float-right"
        mwlCalendarNextView
        [view]="view"
        [(viewDate)]="viewDate"
        (viewDateChange)="closeOpenMonthViewDay()"
      >
      <i class="material-icons float-right" style="margin-left: 5px;">event</i>&nbsp; &nbsp; Next &nbsp; &nbsp;
      </div>
    </div>




  </div>


  <!-- <div *ngIf="model">

    <div *ngFor="let evnt of model" class="row col s12 blog-card hoverable">
       <div class="description">
        <a *ngIf="isMember" [routerLink]="['/member/event-detail',evnt.id]">
          <h1 [innerHTML]="evnt.title" class=""></h1>
        </a>

        <a *ngIf="!isMember" [routerLink]="['/event-detail',evnt.id]">
          <h1 [innerHTML]="evnt.title" class=""></h1>
        </a>


        <p>From : {{evnt.eventDate  | date: 'dd/MMM/yyyy'}}
          <br/> To : {{evnt.expiryDate  | date: 'dd/MMM/yyyy'}}
          <br/> Location : {{evnt.eventPlace}}</p>

        <p class="read-more"  *ngIf="isMember">
          <a [routerLink]="['/member/event-detail',evnt.id]">View Event</a>
        </p>

        <p class="read-more"  *ngIf="!isMember">
          <a [routerLink]="['/event-detail',evnt.id]">View Event</a>
        </p>



      </div>
    </div>


  </div> -->
