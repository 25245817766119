import { Component, OnInit } from '@angular/core';
import {  UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {  Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ContactService } from 'src/app/services/firebase/contact.service';
import { FormService } from 'src/app/services/firebase/form.service';
import { UserService } from 'src/app/services/firebase/user.service';
import { LoadingService } from 'src/app/services/loader/loading.service';

@Component({
  selector: 'app-customer-form-builder',
  templateUrl: './customer-form-builder.component.html',
  styleUrl: './customer-form-builder.component.css'
})
export class CustomerFormBuilderComponent implements OnInit {

  fieldForm: UntypedFormGroup;
  showFieldModal: boolean = false;
  selectedFieldForInfo: any;

  // email regex, to check if the field is an email
  validEmailRegex: any = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  // 5 digit zip regex
  zipRegex5: any = /^\d{5}$/;

  // 9 digit zip regex
  zipRegex9: any = /^\d{5}-\d{4}$/;

  // postal code
  postCodeRegex: any = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/

  showModal: boolean = false
  baseFields: any = [
    {
      name: 'First Name',
      type: 'text',
      category: 'text',
      placeholder: 'Jane',
      key: 'GivenName',
      default: true,
      required: true
    },
    {
      name: 'Last Name',
      type: 'text',
      category: 'text',
      placeholder: 'John',
      key: 'LastName',
      default: true
    },
    {
      name: 'Phone Number',
      type: 'text',
      category: 'phone',
      placeholder: '000-000-0000',
      key: 'cellPhone',
      default: true
    },
    {
      name: 'Email',
      type: 'text',
      category: 'email',
      placeholder: 'Jane@gmail.com',
      key: 'Email',
      default: true
    },
  ]
  existingFormFields: any = null;
  formFieldName: any = null;
  selectedFieldForEdit: any = null;
  selectedField: any;
  modalTitle = "";
  showDeleteModal = false


  //toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  showForm = false
  viewEditForm = true;

  // modal
  showConfirmationModal = false
  recordsTobeImported: any[] = []
  baseFieldBoolean: any[] = []
  existingContactEmails: any[] = []


  constructor(private contactService: ContactService,private router: Router, private fb: UntypedFormBuilder, private userService: UserService, private loaderService: LoadingService, private toastr: ToastrService, private formService: FormService) { }

  ngOnInit(): void {
    // fetch fields
    this.fetchContactHeaders();
  }

  // open edit modal
  openEditPage(selectedField) {

    this.resetFields();
    // iterate over the already added selected fields
    let index = this.existingFormFields.findIndex((item) => item?.id === selectedField?.id);

    if (index != -1) {
      this.router.navigate(['/admin/customer-form/config/'+ this.existingFormFields[index]?.id]);
    }

  }

  // reset fields
  resetFields() {
    this.selectedField = null;
    this.selectedFieldForEdit = null;
    this.modalTitle = ""
  }

  // fetch existing contact headers
  fetchContactHeaders() {

    this.showLoader()
    this.formService.getContactHeaderNew().subscribe((querySnapshot) => {
      let contacts = [];

      querySnapshot.forEach((doc) => {
        contacts.push({ id: doc.id, ...doc.data() });
      });

      this.existingFormFields = contacts;
      this.resetBaseFields();

      // iterate over the header and check if the fields have field name which is going to be used while importing
      for (let i = 0; i < this.baseFields?.length; i++) {
        if (this.existingFormFields?.length) {

          let index = this.existingFormFields.findIndex((item) => item?.key === this.baseFields[i]?.key);

          if (index != -1) {
            this.baseFields[i]['active'] = true
            this.baseFields[i]['id'] = this.existingFormFields[index]?.id
          }

        }
      }
      // iterate over the  existing headers
      for (let j = 0; j < this.existingFormFields?.length; j++) {
        let currentHeader = this.existingFormFields?.[j];
        let fieldIndex = this.baseFields.findIndex((field) => field.key === currentHeader?.key);

        if (fieldIndex === -1) {

          this.baseFields.push({
            name: currentHeader?.name,
            type: currentHeader?.type,
            category: currentHeader?.type,
            placeholder: currentHeader?.name,
            key: currentHeader?.key,
            options: currentHeader?.options,
            required: currentHeader?.required,
            fullWidth: currentHeader?.fullWidth,
            active: true,
            id: currentHeader?.id,
            created_at: currentHeader?.created_at,
            updated_at: currentHeader?.updated_at

          })
        }
        else {
          // this.baseFields['id'] = currentHeader?.id
        }

      }

      // extract the default ones 
      let defaultFields = this.baseFields.filter((field) => field?.default);
      let newFields = this.baseFields.filter((field) => !(field?.default));
      const sortedFields : any =  newFields.sort((a: any, b: any) => {
        return new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime();
    });
      this.baseFields = [...defaultFields, ...sortedFields];
      this.hideLoader();
    }, (err) => {
      console.error("err is ", err);
      this.hideLoader();

    });
  }

  // reset base field
  resetBaseFields() {
    this.baseFields = [
      {
        name: 'First Name',
        type: 'text',
        category: 'text',
        placeholder: 'Jane',
        key: 'GivenName',
        default: true,
        required: true
      },
      {
        name: 'Last Name',
        type: 'text',
        category: 'text',
        placeholder: 'John',
        key: 'LastName',
        default: true
      },
      {
        name: 'Phone Number',
        type: 'text',
        category: 'phone',
        placeholder: '000-000-0000',
        key: 'cellPhone',
        default: true
      },
      {
        name: 'Email',
        type: 'text',
        category: 'email',
        placeholder: 'Jane@gmail.com',
        key: 'Email',
        default: true
      },
    ]
  }

  showDeleteConfirmationModal(selectedField){
    this.selectedFieldForEdit = selectedField;
    this.showDeleteModal = true;
  }

  closeDeleteConfirmationModal(){
    this.selectedFieldForEdit = null;
    this.showDeleteModal = false;
  }

  // delete contact header
  deleteField() {
    this.showLoader()

    this.formService.deleteContactHeader(this.selectedFieldForEdit?.id).then(() => {
      this.fetchContactHeaders();

      // hide modal
      this.closeDeleteConfirmationModal();

      this.hideLoader();

    }).catch((err) => {
      console.error("error while deleting a field ", err)
      this.hideLoader()
    })
  }


  // toast message handler
  successMessage(message) {
    this.toastr.success(message, "Success");
  }

  errorMessage(message) {
    this.toastr.error(message, "Error");
  }

  // show Loader
  showLoader() {
    this.loaderService.show()
  }

  // hide loader
  hideLoader() {
    this.loaderService.hide();
  }

  // 
  openFieldAddModal() {
    this.router.navigate(['/admin/customer-form/config']);
  }

  // show confirmation modal
  closeConfirmationModal() {
    this.showConfirmationModal = false;
    this.baseFieldBoolean = [];
  }


}

