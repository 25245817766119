import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-template-detail',
  templateUrl: './template-detail.component.html',
  styleUrls: ['./template-detail.component.css']
})
export class TemplateDetailComponent implements OnInit {

  previewTemplate = false
  
  constructor() { }

  ngOnInit(): void {
  }

}
