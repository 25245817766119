<br/>
<div class="row">
  <div class="col l1"></div>
  <div class="col s12 l10" *ngIf="discussionID">
    <app-user-discussion [discussionID]="discussionID"></app-user-discussion>
  </div>
  <div class="col l1"></div>
</div>


