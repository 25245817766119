import { Router } from '@angular/router';
import { UserService } from './../../../services/firebase/user.service';
// import { toast } from 'materialize-css';
import { ReportsService } from '../../../services/firebase/reports.service';
import { Component, ElementRef, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { Posts, Comment } from 'src/app/models/PostsModel';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { PostsService } from '../../../services/firebase/posts.service';
import { FriendsService } from '../../../services/firebase/friends.service';
import { Subject } from 'rxjs';

// declare var M: any;
// declare var $: any;

@Component({
  selector: 'app-view-post',
  templateUrl: './view-post.component.html',
  styleUrls: ['./view-post.component.css'],
  // changeDetection: ChangeDetectionStrategy.OnPush,

})
export class ViewPostComponent implements OnInit {

  currentUser: any;
  id: any;
  currentUserPosts: any = [];
  isAdmin: boolean;
  commentOnPost: UntypedFormGroup;
  editPostForm: UntypedFormGroup;
  reportPostForm: UntypedFormGroup;
  postForEdit: any;
  reasonForReport: any;
  editAudience = false;
  selectedPost: any;
  currentNationName = '';
  currentNationLogo: any;
  refresh = new Subject<void>();

  editPostGlobal:any;


  constructor(private fb: UntypedFormBuilder,
              private authService: AuthService,
              private postsService: PostsService,
              private friendsService: FriendsService,
              private reportsService: ReportsService,
              private userService: UserService,
              private router: Router,
              // private cdr: ChangeDetectorRef
              ) {   }

  ngOnInit() {
    this.commentOnPost = this.fb.group({
      comment: ['', [Validators.required]]
    });

    this.editPostForm = this.fb.group({
      message: ['', [Validators.required]],
      sharedLink: ['']
    });
    this.reportPostForm = this.fb.group({
      reason: ['', [Validators.required]]
    });

    this.currentUser = this.authService.getGlobalUser();
    if (this.currentUser) {
      if (this.currentUser.role === 'Admin') {
        this.isAdmin = true;
      }
      this.id = this.currentUser.uid;
    }
    if (this.id) {
      if (this.isAdmin) {
        this.postsService.getAllPosts().valueChanges().subscribe(posts => {
          if (posts) {
            this.currentUserPosts = posts;
            this.currentUserPosts = this.currentUserPosts.sort((a, b) => (b as any).timeStamp.toDate() - (a as any).timeStamp.toDate());
            // $(document).ready(() => {
            //   $('.dropdown-trigger').dropdown({ alignment: 'left' });
            //   $('.modal').modal();

            //   $('.posts-display-container').find('.scrollMe').each(function(){
            //     const height = $($(this)[0]).prop('scrollHeight');
            //     $($(this)[0]).animate({ scrollTop: height}, 10);
            //   });

            // });
          }
        });
      } else {
        this.postsService.getAllPosts().valueChanges().subscribe(posts => {
          if (posts) {
            this.currentUserPosts = posts;
            this.currentUserPosts = this.currentUserPosts.sort((a, b) => (b as any).timeStamp.toDate() - (a as any).timeStamp.toDate());
            // $(document).ready(() => {
            //   $('.posts-display-container').find('.scrollMe').each(function(){
            //     const height = $($(this)[0]).prop('scrollHeight');
            //     $($(this)[0]).animate({ scrollTop: height}, 10);
            //   });
            // });
            if ( this.currentUser && this.currentUser.role == 'User') {
              this.currentUserPosts[0].opened.push(this.currentUser.uid);
              let postCopy = this.currentUserPosts[0];
              this.editPostGlobal = this.postsService.editPost(postCopy).then((res) =>{
                  this.editPostGlobal.unsubscribe();
              })
            }
          }
        });
      }
    }

    // $(document).ready(() => {
    //   $('.modal').modal();
    // });
  }



  checkIfReactionGiven(id) {
    const currentPost = this.currentUserPosts.find(post => post.id === id);
    if (currentPost.reactions.likes){
      if (currentPost.reactions.likes.userId.findIndex(userId => userId === this.id) >= 0) {
        return true;
      } else {
        return false;
      }
    }


  }

  reactToPost(postId) {
    if (this.checkIfReactionGiven(postId)) {
      const currentPostindex = this.currentUserPosts.findIndex(post => post.id === postId);
      this.currentUserPosts[currentPostindex].reactions.likes.count -= 1;
      this.currentUserPosts[currentPostindex].reactions.likes.userId.pop(this.id);
      this.currentUserPosts[currentPostindex].reactions.likes.userId.pop(`${this.currentUser.GivenName || this.currentUser.FirstName} ${this.currentUser.LastName}`);
      this.postsService.updateReactionOfApost(this.currentUserPosts[currentPostindex]);

    } else {
      const currentPostindex = this.currentUserPosts.findIndex(post => post.id === postId);
      this.currentUserPosts[currentPostindex].reactions.likes.count += 1;
      this.currentUserPosts[currentPostindex].reactions.likes.userId.push(this.id);
      this.currentUserPosts[currentPostindex].reactions.likes.userId.push(`${this.currentUser.GivenName || this.currentUser.FirstName} ${this.currentUser.LastName}`);
      this.postsService.updateReactionOfApost(this.currentUserPosts[currentPostindex]);

    }

  }

  likeComment(postId, commentId) {
    const currentPostindex = this.currentUserPosts.findIndex(post => post.id === postId);
    const currentComment = this.currentUserPosts[currentPostindex].comments.findIndex(comment => comment.id === commentId);
    if (this.currentUserPosts[currentPostindex].comments[currentComment].likes.userId.findIndex(userId => userId === this.id) >= 0) {
      this.currentUserPosts[currentPostindex].comments[currentComment].likes.count -= 1;
      this.currentUserPosts[currentPostindex].comments[currentComment].likes.userId.pop(this.id);
      this.currentUserPosts[currentPostindex].comments[currentComment].likes.userId.pop(`${this.currentUser.GivenName || this.currentUser.FirstName} ${this.currentUser.LastName}`);
      this.postsService.updateComments(this.currentUserPosts[currentPostindex]);

    } else {
      this.currentUserPosts[currentPostindex].comments[currentComment].likes.count += 1;
      this.currentUserPosts[currentPostindex].comments[currentComment].likes.userId.push(this.id);
      this.currentUserPosts[currentPostindex].comments[currentComment].likes.userId.push(`${this.currentUser.GivenName || this.currentUser.FirstName} ${this.currentUser.LastName}`);

      this.postsService.updateComments(this.currentUserPosts[currentPostindex]);

    }

  }

  replyToComment(postId, commentId) {
  }

  addCommentToPost(post, comment) {
    const newComment = new Comment();
    newComment.postId = post.id;
    newComment.userId = this.id;
    newComment.userName = `${this.currentUser.GivenName || this.currentUser.FirstName} ${this.currentUser.LastName}`;
    newComment.comment = comment;
    newComment.timestamp = new Date();
    newComment.likes = {
      count: 0,
      userId: [],
      usernames: []
    };
    newComment.replies = [];
    this.postsService.addCommentToPost(post, newComment);
    this.commentOnPost.reset();
    // M.textareaAutoResize($('.textarea'));
  }

  selectPost(post){
    this.selectedPost = post;
  }

  archivePost() {
    this.postsService.archivePost(this.selectedPost.id);
    this.selectedPost = [];

  }

  editPostClicked(){
    this.postForEdit = this.selectedPost;
    this.editPostForm.reset();
    this.editPostForm.patchValue({
      message: this.postForEdit.message,
      sharedLink: this.postForEdit.sharedLink ? this.postForEdit.sharedLink : ''
    });
    // $('.modal#editPost').modal('open');

  }

  reportPostClicked(){
    this.reasonForReport = this.selectedPost;
    this.reportPostForm.reset();
    this.reportPostForm.patchValue({
      reason: this.reasonForReport.reason
    });
    // $('.modal#reportPost').modal('open');

  }
  editPost(value){
    this.postForEdit.message = value.message;
    this.postForEdit.sharedLink = value.sharedLink;
    this.postsService.editPost(this.postForEdit);
    this.editPostForm.reset();
    this.selectedPost = [];
    this.postForEdit = [];

  }
  reportPost(value){
    this.reasonForReport.reason = value.reason;
    const report = {
      postId: this.reasonForReport.id,
      reason: this.reasonForReport.reason ? this.reasonForReport.reason : '',
      reportedBy: this.id,
      postOwner: this.reasonForReport.createdById,
      timeStamp: new Date()
    };
    this.reportsService.getReportByReporterAndPostId(this.id, report.postId).valueChanges().subscribe(reportedPost => {
      if (reportedPost.length > 0){
        // already reported
        // toast({ html: 'User reported already!', classes: 'red' });
      } else {
        this.reportsService.reportPost(report);
      }
    });
    this.reportPostForm.reset();
    this.selectedPost = [];
    this.reasonForReport = [];
  }

  editAudienceSelected(){  }

  replaceURLs(message) {
    if (!message) {return; }

    const urlRegex = /((http:\/\/|https:\/\/)?(www.)?(([a-zA-Z0-9-]){2,}\.){1,4}([a-zA-Z]){2,6}(\/([a-zA-Z-_\/\.0-9#:?=&;,]*)?)?)/ig;
    return message.replace(urlRegex, (url) => {
      let hyperlink = url;
      if (!hyperlink.match('^https?:\/\/')) {
        hyperlink = 'http://' + hyperlink;
      }
      return '<a href="' + hyperlink + '" target="_blank" rel="noopener noreferrer">' + url + '</a>';
    });
  }

  viewPostsByAudience(audienceid){
    this.postsService.getPostsByUserId(this.id, audienceid).valueChanges().subscribe(posts => {
      if (posts){
        this.currentUserPosts = posts;
      }
    });
  }

  viewProfile(user){
    if (this.currentUser.role === 'Admin'){
      if(user.uid) this.router.navigate(['admin/view-profile/', user.uid]);
      if(user.userId) this.router.navigate(['admin/view-profile/', user.userId]);
    }
    else{
      if(user.uid) this.router.navigate(['user/view-profile/', user.uid]);
      if(user.userId) this.router.navigate(['user/view-profile/', user.userId]);
    }
  }
  getUrl(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);

    if (match && match[2].length === 11) {
      const youtubeUrl = 'https://www.youtube.com/embed/' + match[2];
      return youtubeUrl;
    } else {
        return url;
    }
  }
}
