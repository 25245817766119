import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {

  collectionRef: AngularFirestoreCollection<any>;
  documentRef: AngularFirestoreDocument<any>;

  constructor(private db: AngularFirestore) { }

  // Contact Settings -------------------------------

  savePushNotificationSettings(model: any) {
    model.id = this.db.createId();
    return this.db.collection('PushNotificationSettings').doc(model.id).set(model);
  }

  saveMetaDataSettings(model: any) {
    model.id = this.db.createId();
    return this.db.collection('MetaTag').doc(model.id).set(model);
  }

  // sample meta tag api
  getMetadata() {
    return this.db.collection('MetaTag');
  }

  updateMetaDataSettings(model: any) {
    return this.db.collection('MetaTag').doc(model.id).update(model);
  }

  updatePushNotificationSettings(model: any) {
    return this.db.collection('PushNotificationSettings').doc(model.id).update(model);
  }

  getPushNotificationSettingsList(): AngularFirestoreCollection<any[]> {
    this.collectionRef = this.db.collection('PushNotificationSettings')
    return this.collectionRef;
  }

  getAdminPushNotificationSettingsList(adminId): AngularFirestoreCollection<any[]> {
    this.collectionRef = this.db.collection('PushNotificationSettings', ref => ref.where("userId", "==", adminId));
    return this.collectionRef;
  }


  // Contact Settings -------------------------------

  saveContactSettings(model: any) {
    model.id = this.db.createId();
    return this.db.collection('ContactSettings').doc(model.id).set(model);
  }

  updateContactSettings(model: any) {
    return this.db.collection('ContactSettings').doc(model.id).update(model);
  }

  getContactSettingsList(): AngularFirestoreCollection<any[]> {
    this.collectionRef = this.db.collection('ContactSettings');
    return this.collectionRef;
  }

  // ------------------------------------------------


  // Landing Page Settings -------------------------------

  saveLandingPageSettings(model: any) {
    model.id = this.db.createId();
    return this.db.collection('LandingPageSettings').doc(model.id).set(model);
  }

  updateLandingPageSettings(model: any) {
    return this.db.collection('LandingPageSettings').doc(model.id).update(model);
  }

  getLandingPageSettings(): AngularFirestoreCollection<any[]> {
    this.collectionRef = this.db.collection('LandingPageSettings');
    return this.collectionRef;
  }

  saveHomePageSettings(model: any) {
    model.uid = this.db.createId();
    return this.db.collection('HomePage').doc(model.uid).set(model);
  }

  updateHomePageSettings(model: any) {
    return this.db.collection('HomePage').doc(model.uid).update(model);
  }

  getHomePageSettings(): AngularFirestoreCollection<any[]> {
    this.collectionRef = this.db.collection('HomePage');
    return this.collectionRef;
  }
  // ------------------------------------------------

  // App Menu Settings -------------------------------

  saveMenuSettings(model: any) {
    model.id = this.db.createId();
    return this.db.collection('AppMenu').doc(model.id).set(model);
  }

  updateMenuSettings(model: any) {
    return this.db.collection('AppMenu').doc(model.id).update(model);
  }

  getMenuSettings(): AngularFirestoreCollection<any[]> {
    this.collectionRef = this.db.collection('AppMenu');
    return this.collectionRef;
  }
  // ------------------------------------------------

  saveAppSettings(model: any) {
    model.id = this.db.createId();
    return this.db.collection('AppSettings').doc(model.id).set(model);
  }

  updateAppSettings(model: any) {
    return this.db.doc(`/AppSettings/${model.id}/`).update(model);
  }

  getAppSettingsList(): AngularFirestoreCollection<any[]> {
    this.collectionRef = this.db.collection('AppSettings');
    return this.collectionRef;
  }

  // -------------- Admin Menu Settings ----------------

  saveAdminMenuSettings(model: any) {
    model.id = this.db.createId();
    return this.db.collection('AdminMenu').doc(model.id).set(model);
  }

  updateAdminMenuSettings(model: any) {
    return this.db.collection('AdminMenu').doc(model.id).update(model);
  }

  getAdminMenuSettings(): AngularFirestoreCollection<any[]> {
    this.collectionRef = this.db.collection('AdminMenu');
    return this.collectionRef;
  }
  getMainAdminMenuSettings() {
    return this.db.collection('AdminMenu', ref => ref.where('buttonType', '==', 'Main'));
  }
  getMembershipAdminMenuSettings() {
    return this.db.collection('AdminMenu', ref => ref.where('buttonType', '==', 'Membership'));
  }

  getNewsEventAdminMenuSettings() {
    return this.db.collection('AdminMenu', ref => ref.where('buttonType', '==', 'NewsEvents'));
  }

  getSettingsAdminMenuSettings() {
    return this.db.collection('AdminMenu', ref => ref.where('buttonType', '==', 'Settings'));
  }
  saveUserMenuSettings(model: any) {
    model.id = this.db.createId();
    return this.db.collection('UserMenu').doc(model.id).set(model);
  }

  updateUserMenuSettings(model: any) {
    return this.db.collection('UserMenu').doc(model.id).update(model);
  }
  getUserMenuSettings(): AngularFirestoreCollection<any[]> {
    this.collectionRef = this.db.collection('UserMenu');
    return this.collectionRef;
  }

  // -------------- Signup/ Signin Settings ----------------

  saveSignupSettings(model: any) {
    model.id = this.db.createId();
    return this.db.collection('SignupSettings').doc(model.id).set(model);
  }

  updateSignupSettings(model: any) {
    return this.db.collection('SignupSettings').doc(model.id).update(model);
  }

  getSignupSettingsList(): AngularFirestoreCollection<any[]> {
    this.collectionRef = this.db.collection('SignupSettings');
    return this.collectionRef;
  }

}
