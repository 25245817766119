import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormArray, FormGroup, Validators, AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
// import { GalleryItem, ImageItem } from 'ng-gallery';
import { UserService } from 'src/app/services/firebase/user.service';
import { AppointmentService } from 'src/app/services/firebase/employee-appointment.service';
import { UserNotesService } from 'src/app/services/firebase/user-notes.service';

import { EmailService } from 'src/app/services/api/email.service';
import { SMSService } from 'src/app/services/api/sms.service';
import { isToday, isSameDay, isAfter, isBefore } from 'date-fns';
import { PermissionService } from 'src/app/services/firebase/permission.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { ClientAnswersService } from 'src/app/services/firebase/client-answers.service';
import { VerificationService } from 'src/app/services/firebase/verification.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { HelperService } from 'src/app/services/helper/helper';
import { environment } from 'src/environments/environment';
import { CountersService } from 'src/app/services/firebase/counters.service';


@Component({
  selector: 'app-client-detail',
  templateUrl: './client-detail.component.html',
  styleUrls: ['./client-detail.component.css']
})
export class ClientDetailComponent implements OnInit {
  id: any;
  user: any = [];
  userLogged: any = [];
  images: any[];
  imgLink = 'https://www.w3schools.com/howto/img_avatar.png';
  profileImages: any = [];
  coverPhotoLink = '';
  GivenName = '';
  LastName: any;
  cellPhone: any;
  Email: any;
  JobTitle: any;

  totalAppointment: any;
  allAppointment: any;
  EmpToAppointment = [];
  isEmpty: boolean = false;
  initial: any;
  profileImgLink: any;

  disableModal: boolean = false;
  disableStopModal: boolean = false;
  enableModal: boolean = false;
  notesOpenModal: boolean = false;
  editOpenModal: boolean = false;

  notesForm: UntypedFormGroup;
  editNoteForm: UntypedFormGroup;
  userNotes = [];
  message : any;
  displayAllNotes: any;

  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;

  note: any;
  noteGlobal: any;
  lastLogin: any;
  showNotes: boolean = false;

  contactForm: UntypedFormGroup;
  isSMS: boolean = false;
  openContactModal: boolean = false;
  isSMSemp: boolean = false;
  storeEmail = [];
  disable: boolean = false;

  archived = [];
  unarchived = [];
  toggleArch: boolean = true;
  archiveOpenModal: boolean = false;
  unarchiveOpenModal: boolean = false;
  toArchive: any;
  toUnArchive: any;
  myArchive: any;
  myUnArchive: any;
  //remind me
  remindMe: boolean = false;
  reminders = [];

  perm: any;
  permission: any;
  permissionSpecial: any;

  currentUser: any;
  role: any;
  isAdmin: boolean;
  notesGlobal: any;
  resetModal: boolean;

  DOB: '';

  //toggle
  isUpcomingClicked: boolean = true;
  isPastClicked: boolean = false;

  clientInfo: any;
  status: any;
  reason: any;
  getVerified: any;

  acceptVerificationModal: boolean = false;
  declineVerificationModal: boolean = false;
  requestReapplyModal: boolean = false;
  reasonDecline: any;

  //for Email
  setUp: any
  greetings: any;
  signOff: any;
  email: any;
  messageForSMS: any;
  contactMsg = '';

  //Employee Signatures
  employee: any;
  signature: any;

  otherDetails :any;


  times = ['Select Time','08:00 AM', '08:30 AM', '09:00 AM', '09:30 AM', '10:00 AM', '10:30 AM', '11:00 AM',
    '11:30 AM', '12:00 PM', '12:30 PM', '01:00 PM', '01:30 PM', '02:00 PM', '02:30 PM', '03:00 PM',
    '03:30 PM', '04:00 PM', '04:30 PM', '05:00 PM', '05:30 PM', '06:00 PM', '06:30 PM', '07:00 PM',
    '07:30 PM', '08:00 PM', '08:30 PM', '09:00 PM', '09:30 PM', '10:00 PM', '10:30 PM', '11:00 PM',
    '11:30 PM', '12:00 AM', '12:30 AM', '01:30 AM', '02:00 AM', '02:30 AM', '03:00 AM', '03:30 AM',
    '04:00 AM', '04:30 AM', '05:00 AM', '05:30 AM', '06:00 AM', '06:30 AM', '07:00 AM', '07:30 AM']

  // for direct message
  @Output() sendDirectMessageToUser = new EventEmitter<{ user: any }>();


  appName: any;
  appLogo: any;
  domain = environment.firstNation.name;
  // domainName = "https://" + this.domain + ".goingmobile.app"
  isNationApp = environment.firstNation.Origin == "FNLeaders" ? true : false;

  domainName = this.isNationApp ? "https://" + this.domain + ".mynation.app" : "https://" + this.domain + ".goingmobile.app";
  
  host = window.location.hostname !== 'localhost' ? 'https://' + window.location.hostname + '/reset-password/' : 'http://localhost:4200/reset-password/';

  permMember: any;
  permPES: any;
  permSocial: any;
  permAnalytics: any;

  theNotes: any;
  appointment: any;

  nonEditable: any;

  sentSMSCount: number = 0;
  sentIndividualSMSCount: number

  adminSMSCounter: number = 0;
  userSMSCounter: number = 0;
  userCounter: any;
  adminCounter: any;
  adminEmailCounter: number = 0;
  userEmailCounter: number = 0;
  tab='profile'

  counterGlobal: any;

  currentDate = new Date();
  isLimitSMS: boolean = false;
  isLimitEmail: boolean = false;

  emitted: any;
  trackNoteStartDate: any


  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private userService: UserService,
    private appointmentService: AppointmentService,
    private noteService: UserNotesService,
    private emailService: EmailService,
    private sMSService: SMSService,
    private permissionService: PermissionService,
    private authService: AuthService,
    private clientAnswerService: ClientAnswersService,
    private verficationService: VerificationService,
    private router: Router,
    private appSettingsService: AppSettingsService,
    private helperService: HelperService,
    private counter: CountersService) { }

  ngOnInit(): void {
// console.log("is first nation", this.isNationApp);
    // const ReminderDate = new Date(this.trackNoteStartDate);
    this.currentUser = this.authService.getGlobalUser();

    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });

    // Get the current month
    const currentMonth = this.currentDate.getMonth();

    // Move to the next day
    const nextDay = new Date(this.currentDate);
    nextDay.setDate(this.currentDate.getDate() + 1);

    // Check if the next day is in a different month
    const isNewMonth = nextDay.getMonth() !== currentMonth;

    this.counter.getCounter().valueChanges().subscribe((adminCounter: any) => {
      // console.log("Got counter", adminCounter);
      if (adminCounter && adminCounter.length != 0) {

        this.adminCounter = adminCounter[0];
        this.adminSMSCounter = this.adminCounter.SMSTotalCounter || 0;
        this.adminEmailCounter = this.adminCounter.emailTotalCounter || 0;

        if (isNewMonth) {

          this.adminSMSCounter = 0;
          this.adminEmailCounter = 0;

        } else {

          if (this.adminSMSCounter <= 100) {
            this.adminSMSCounter = this.adminCounter.SMSTotalCounter || 0;
          }
          if (this.adminEmailCounter <= 100) {
            this.adminEmailCounter = this.adminCounter.emailTotalCounter || 0;
          }

          this.isLimitSMS = this.adminSMSCounter > 100;
          this.isLimitEmail = this.adminEmailCounter > 100;
        }
      } else {
        this.adminSMSCounter = 0;
        const counter = {
          id: '',
          SMSTotalCounter: 0,
          emailTotalCounter: 0,
          socialMediaCount: 0,
          chatGptCounter: 0,
        }
        this.counter.saveCounter(counter);
      }
    });
    console.log("NEW DATE", new Date())
    this.notesForm = this.fb.group({
      notes: ['', [Validators.required]],
      ReminderDate: ['', [ validateStartDate]],
      ReminderTime: ['9:00 AM']
    });
    this.editNoteForm = this.fb.group({
      editNotes: [''],
      EditReminderDate: [''],
      EditReminderTime: ['']
    })
    this.contactForm = this.fb.group({
      contact: [this.contactMsg],
      subject: [''],
      emailbody: [''],
      messageSMS: ['']
    });

    if (this.currentUser) {
      this.role = this.currentUser.role;

      if (this.role == 'Admin') {
        this.isAdmin = true;

        this.appSettingsService.getAppSettingsList().valueChanges().subscribe(setup => {
          this.setUp = setup[0]
          this.appName = this.setUp.appName;
          this.appLogo = this.setUp.logo;
          this.greetings = this.setUp.greetings ? this.setUp.greetings : '';
          this.signOff = this.setUp.signOff ? this.setUp.signOff : '';
          this.email = this.greetings + ' <br /> <br />  <br /> <br />' + this.signOff;
          this.messageForSMS = '\n \n' + this.setUp.messageSMS ? this.setUp.messageSMS : '';
          this.contactForm.patchValue({
            emailbody: this.email,
            messageSMS: this.messageForSMS
          });
        });

      } else if (this.role == 'Employee') {

    this.userService.getUserByEmail(this.currentUser.Email).valueChanges().subscribe(employee => {
          this.employee = employee[0];
          this.email = this.employee.greetings + ' <br /> <br />  <br /> <br />' + this.employee.signOff;
          this.messageForSMS = this.employee.messageSMS;
          this.contactForm.patchValue({
            emailbody: this.email,
            messageSMS: this.messageForSMS
          })
    })

      }

    this.permissionService.getPermissionByEmployeeId(this.currentUser.uid).valueChanges().subscribe(perm => {
        if (perm.length != 0) {
          this.perm = perm[0];
          [this.permMember, this.permPES, this.permAnalytics, this.permSocial, this.permissionSpecial] = this.perm.permission;

        }
      });
    }

    this.noteService.getNotesByUserId(this.id).valueChanges().subscribe((notes: any) => {

      if (notes && notes.length != 0) {
        this.theNotes = notes[0];
      }

    })

    this.noteService.getNotesByArchivedUserId(this.id).valueChanges().subscribe((myArchive: any) => {

      if (myArchive && myArchive.length != 0) {
        this.myArchive = myArchive;
      }

    });

    this.clientAnswerService.getFormParticipationByClientID(this.id).valueChanges().subscribe(x => {
      if (x && x.length > 0) {
        this.user = x[0];
        this.clientInfo = this.user.answers;
        if (this.Email == '') {
          this.contactMsg = 'SMS';
        }
      }
    })

    this.notesGlobal = this.noteService.getNotesByUnArchivedUserId(this.id).valueChanges().subscribe((myUnArchive: any) => {
      if (myUnArchive && myUnArchive.length != 0) {
        this.myUnArchive = myUnArchive;

        // Use Array.prototype.filter to filter reminders for the same day
        this.reminders = this.myUnArchive.filter(note => {
          return note.reminder && isToday(new Date(note.reminder));
        });

        this.displayAllNotes = this.myUnArchive;
        // Set this.message based on the length of myUnArchive
        this.message = myUnArchive.length >= 2 ? myUnArchive : myUnArchive;
      }
    })

    this.noteService.getNotesArchived().valueChanges().subscribe(archive => {
      if (archive && archive.length != 0) {
        this.archived = archive;
      }
    });

    this.userService.getUserById(this.id).valueChanges().subscribe(x => {
      if (x && x.length > 0) {
       
        this.userLogged = x[0];
        this.GivenName = this.userLogged.GivenName
        this.LastName = this.userLogged.LastName;
        this.cellPhone = this.userLogged.cellPhone;
        this.Email = this.userLogged.Email;
        this.profileImgLink = this.userLogged.profileImgLink;
        this.lastLogin = this.userLogged.lastLogin;
        this.initial = this.GivenName.match(/\b(\w)/g);
        this.DOB = this.userLogged.DOB
        this.status = this.userLogged.status;

        let {GivenName,id, uid, LastName, cellPhone, Email, profileImgLink, lastLogin, DOB, status, ...otherInfo } = this.userLogged
        this.otherDetails = otherInfo;

        console.log("user logged in is .............. ", this.userLogged)

        this.verficationService.getVerificationReqByEmail(this.Email).valueChanges().subscribe(getVerified => {
          if (getVerified && getVerified.length != 0) {
            this.getVerified = getVerified[0];
            this.signature = this.getVerified.applicantSignature;
          }
        });
      }

    });

    this.appointment = this.appointmentService.getAppointmentByUserId(this.id).valueChanges().subscribe(appointments => {
      if (appointments.length > 0) {
        this.totalAppointment = appointments.length;
        this.allAppointment = appointments;

        this.allAppointment.forEach(appointment => {
          this.userService.getUserById(appointment.employeeId).valueChanges().subscribe(employee => {
            if (employee && employee.length > 0) {
              this.EmpToAppointment.push({ appointment, employee: employee[0] });
              this.isEmpty = false;
            }
          });
        })
      }
      else {
        this.isEmpty = true;
      }
      this.appointment.unsubscribe();
    })
  }
  SMSselected() {
    this.contactMsg = 'SMS'
  }
  emailSelected() {
    this.contactMsg = 'Email'
  }
  directMessageSelected() {
    this.contactMsg = 'directMessage'
  }
  openModal() {
    this.openContactModal = true;
  }
  closeModal() {
    this.openContactModal = false;
  }
  onSendClicked(event) {
    this.emitted = event;
    this.openContactModal = false;
    this.toast(this.emitted);
  }
  openDisableModal() {
    this.disableModal = true;
  }
  openDisableStopModal() {
    this.disableStopModal = true;
    this.disableModal = false;
  }
  openEnableModal() {
    this.enableModal = true;
  }
  openNotesModal() {
    this.notesOpenModal = true;
    console.log("Open Notes")
  }
  closeNotesModal() {
    this.notesOpenModal = false;
  }
  openEditModal() {
    this.editOpenModal = true;
  }
  closeEditModal() {
    this.editOpenModal = false;
  }
  openArchiveModal(item) {
    this.archiveOpenModal = true;
    this.toArchive = item;
  }
  openUnArchiveModal(item) {
    this.unarchiveOpenModal = true;
    this.toUnArchive = item;
  }
  openResetModal() {
    this.resetModal = true;
  }
  toggleArchive() {
    this.toggleArch = !this.toggleArch;
  }
  toggleRemind() {
    this.remindMe = !this.remindMe;
  }
  allNotes() {
    this.showNotes = true;
    console.log("Show Notes", this.showNotes)
  }
  suspendUser() {
    this.userLogged.status = 'Disabled'
    this.userLogged.reason = this.reason;
    this.disableStopModal = false;
    this.userService.updateUser(this.userLogged).then(res => {
      this.toast({ html: 'User Removed.', classes: 'green', type: 'success' });
    });
  }
  restoreUser() {
    this.userLogged.status = 'Enabled';
    this.userLogged.reason = "";
    this.enableModal = false; 
    this.userService.updateUser(this.userLogged).then(() => {
      this.toast({ html: 'User Restored', classes: 'green', type: 'success' });
    });
  }

  gotoAppointment(appt) {
    this.router.navigate(['/admin/appointment-detail', appt.id]);
  }

  resetPassword() {
    this.resetModal = false;
    // console.log("Reset is toughed")
    this.authService.resetPassword(this.Email).then((res) => {
      this.toast({ html: 'Reset Password Link Successfully Sent To the Subscriber Inbox.', classes: 'green', type: 'success' });
    }).catch((error) => {
    }
    );
  }

  convertTime12to24 = time12h => {
    console.log("Time recieved", time12h)
    const [time, modifier] = time12h.split(" ");

    let [hours, minutes] = time.split(":");

    if (hours === "12") {
      hours = "00";
    }

    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
      if (Math.floor(hours / 10) == 0) {
        hours = '0' + hours;
      }
    }
    return `${hours}:${minutes}`;
  };

  convertTime24to12(time24) {
    var hours = time24.slice(0, -3); // gives the value in 24 hours format
    var AmOrPm = hours >= 12 ? 'PM' : 'AM';
    hours = (hours % 12) || 12;
    if (Math.floor(hours / 10) == 0) {
      hours = '0' + hours

    }
    var minutes = time24.slice(-2);
    var timeFormat = hours + ":" + minutes + " " + AmOrPm;
    return timeFormat;
  }

  userNote(Message: any) {
    // let message: string[] = [];
    // message.push(Message.notes);
    let message:any;
    message = Message.notes;
    this.userNotes = message;
    const timestamp = new Date();
    this.noteService.saveNotes({
      id: '',
      Message: this.userNotes,
      timestamp,
      userId: this.id,
      subscriberName: this.GivenName,
      reminder: Message.ReminderDate ? Message.ReminderDate : "",
      noteMadeBy: this.currentUser.uid,
      archived: false,
      reminderTime: this.convertTime12to24(Message.ReminderTime)? this.convertTime12to24(Message.ReminderTime) : "",
    });
    this.closeNotesModal();
    this.toast({ html: 'User Note Successfully Saved', classes: 'green', type: 'success' });
    this.notesForm.reset();
  }

  editable() {
    this.nonEditable = false;
  }

  edit(item) {

    this.nonEditable = true;
    this.noteGlobal = item;
    this.openEditModal()
    this.noteService.getNoteById(item.id).valueChanges().subscribe(note => {
      if (note && note.length != 0) {
        this.note = note[0];
        this.editNoteForm.patchValue({
          editNotes: this.note.Message ? this.note.Message : item.Message,
          EditReminderDate: this.note.reminder ? this.note.reminder : item.reminder,
          EditReminderTime: this.convertTime24to12(this.note.reminderTime) ? this.convertTime24to12(this.note.reminderTime) : this.convertTime24to12(item.reminderTime)
        });
        if (this.note.reminder != '') {
          this.remindMe = true;
        }

      }
    })
  }
  dateChangeNoteDate() {
   
    this.trackNoteStartDate = $("#ReminderDate").val();
    console.log("Date Note Change",this.trackNoteStartDate);
    if (this.notesForm) {
      this.notesForm.patchValue({
        ReminderDate: $("#ReminderDate").val(),
      });
    }
    // const ReminderDate = new Date(this.trackNoteStartDate);
    // const minEndDate = new Date();
    // minEndDate.setDate(ReminderDate.getDate() + 1);
    // this.notesForm.patchValue({
    //   ReminderDate: this.ReminderDate.value,
    // });
  }
  editNote(note) {
    // let message: string[] = [];
    // message.push(note.editNotes);
    let message:any;
    message = note.editNotes;
    this.noteGlobal.Message = note.editNotes;
    // if (Array.isArray(note.editNotes)) {
    //   this.noteGlobal.Message = note.editNotes;
    // } else {
    //   // this.noteGlobal.Message = [note.editNotes];
    //   this.noteGlobal.Message = note.editNotes;
    // }
    this.noteGlobal.reminder = note.EditReminderDate;
    this.noteGlobal.reminderTime = this.convertTime12to24(note.EditReminderTime);
    this.noteService.updateNote(this.noteGlobal).then(res => {
    })
    this.closeEditModal();
    this.toast({ html: 'The Note is Successfully Updated', classes: 'green', type: 'success' });
  }
  delete(item) {
    this.noteService.deleteNote(item);
    this.toast({ html: 'The Note is Deleted', classes: 'red', type: 'failure' });

  }
  archive() {
    // console.log("Archive Archive Archive");
    this.toArchive.archived = true;
    this.noteService.updateNote(this.toArchive).then(res => { })
    this.archiveOpenModal = false;
    this.toast({ html: 'The Note is Successfully Archived', classes: 'green', type: 'success' })
  }
  unarchive() {
    this.toUnArchive.archived = false;
    this.noteService.updateNote(this.toUnArchive).then(res => { })
    this.unarchiveOpenModal = false;
    this.toast({ html: 'The Note is Successfully UnArchived', classes: 'green', type: 'success' })
  }
  declineVerification() {
    this.declineVerificationModal = true;
  }
  closeDeclineVerification() {
    this.declineVerificationModal = false;
  }
  acceptVerification() {
    this.acceptVerificationModal = true;
  }
  closeAcceptVerification() {
    this.acceptVerificationModal = false;
  }
  openRequestReapply() {
    this.requestReapplyModal = true;
  }
  acceptVerify() {
    console.log("ACCEPTED")
    this.getVerified.status = 'Accepted';
    this.verficationService.updateVerificationRequest(this.getVerified).then();
    this.toast({ html: 'You have Successfully Accepted the Verification Request', classes: 'green', type: 'success' });
    this.closeAcceptVerification();

  }
  declineVerify() {
    this.getVerified.status = 'Declined';
    this.getVerified.reasonDecline = this.reasonDecline;
    this.verficationService.updateVerificationRequest(this.getVerified).then();
    this.closeDeclineVerification();
    this.toast({ html: 'You have Successfully Declined the Verification Request', classes: 'green', type: 'declined' });

  }
  requestReapply() {
    this.getVerified.reapply = true;
    this.verficationService.updateVerificationRequest(this.getVerified).then();
    this.toast({ html: 'You have Successfully Requested for a Re-application', classes: 'green', type: 'success' });
    this.requestReapplyModal = false;

  }
  setEditTime(item) {
    this.EditReminderTime.patchValue(item);
  }
  setStartTime(item) {
    this.ReminderTime.patchValue(item);
  }

  truncateHTML(text: string, limit: string) {
    // console.log("the text being",text, "and the limit being", limit);
    this.helperService.truncateHTML(text,limit);
  }

  // truncateHTML(text: string, limit: string): string {
  //   const charlimit = +limit;
  //   if (!text || text.length <= charlimit) {
  //     return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ');
  //   }
  //   return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ').substring(0, charlimit) + '...';
  // }

  editSubscriber() {
    if (this.user && this.isAdmin) {
      this.router.navigate(['/admin/edit-clientInfo/', this.userLogged.uid]);
    }
  }

  reset(models) {
    this.contactForm.patchValue({
      subject: '',
      emailbody: this.greetings + ' <br /> <br />  <br /> <br />' + this.signOff,
      messageSMS: this.setUp.messageSMS,
    });
  }

  goBack() {
    this.showNotes = false;
  }

  toast(obj) {
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green';
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
    }, 2000);
  }

  changeTab(tab){
    this.tab = tab
  }

  get emailbody() { return this.contactForm.get('emailbody'); }
  get subject() { return this.contactForm.get('subject'); }
  get messageSMS() { return this.contactForm.get('messageSMS'); }
  get contact() { return this.contactForm.get('contact'); }
  get notes() { return this.notesForm.get('notes'); }
  get editNotes() { return this.editNoteForm.get('editNotes'); }
  get EditReminderDate() { return this.editNoteForm.get('EditReminderDate'); }
  get EditReminderTime() { return this.editNoteForm.get('EditReminderTime'); }
  get ReminderDate() { return this.notesForm.get('ReminderDate'); }
  get ReminderTime() { return this.notesForm.get('ReminderTime'); }
}
function validateStartDate(c: AbstractControl): any {
  console.log("It is here")
  if (!c.parent || !c) {
    return;
  }
  const startDate = c.parent.get("ReminderDate");
  const ReminderDate = new Date(startDate.value);
  const today = new Date();
  if (isAfter(ReminderDate, today) || isSameDay(ReminderDate, today)) {
    return;
  }
  if (isBefore(ReminderDate, today)) {
    return { invalidStartDate: true };
  }

  
}

