import { Component, Input, OnInit, Output } from '@angular/core';
import { CustomSectionService } from 'src/app/services/firebase/custom-section.service';
import { HelperService } from 'src/app/services/helper/helper';

@Component({
  selector: 'app-view-custom-lists',
  templateUrl: './view-custom-lists.component.html',
  styleUrls: ['./view-custom-lists.component.css']
})
export class ViewCustomListsComponent implements OnInit {

  constructor(
    private customSectionService: CustomSectionService,
    private helperService: HelperService) { }
  @Input() sectionDetail;
  @Input() view;
  @Input() homepage;
  @Input() template;
  @Input() itemNumber;

  section: any;
  allSections = [];
  ngOnInit(): void {
    this.customSectionService.getCustomSectionBySectionTitle(this.sectionDetail.originalName).valueChanges().subscribe(sec => {
      this.section = sec[0];
      if(this.section){
      this.customSectionService.getAllElementsBySectionId(this.section.id).valueChanges().subscribe((sections: any) => {
        this.allSections = sections;
      })
    }
    })
  }

  getInnerText(html){
    return this.helperService.getInnerText(html);
  }
  truncateHTML(text: string, limit: string): string {
    return this.helperService.truncateHTML(text, limit);
  }

  getSortedList(unsortedElements: Array<any>) {
    let sortedList = unsortedElements.sort((a: any, b: any) => {
      return a.position - b.position;
    });
    return sortedList
  }

}
