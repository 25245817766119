import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filePreview'
})
export class FilePreviewPipe implements PipeTransform {
  transform(file: File): any {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    return new Promise((resolve) => {
      reader.onload = () => {
        resolve(reader.result);
      };
    });
  }
}