import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
// import { toast } from 'materialize-css';
import { SocialMediaService } from 'src/app/services/firebase/social-media.service';
import { PermissionService } from 'src/app/services/firebase/permission.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { ChangeVisibilityService } from 'src/app/services/firebase/change-visibility.service';

// declare var M: any;
// declare var $: any;
@Component({
  selector: 'app-contact-settings',
  templateUrl: './contact-settings.component.html',
  styleUrls: ['./contact-settings.component.css']
})
export class ContactSettingsComponent implements OnInit {
  @Input() calledFromEmptyDashboard:string;
  @Output() socialsCreated = new EventEmitter<string>();
  @Output() skip = new EventEmitter();

  themeColor = environment.appTheme.adminThemeColor;
  nationOfUse = environment.firstNation.name;
  nationCommunities: any[];
  pageSections: any

  editModel: any;
  isEdit = false;
  urlPattern = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-|=|?]*/?';
  socialForm: UntypedFormGroup;
  hasSocial = false;
  socialID = '';

  public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')',
    ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  createModalOpen = false;
  editModalOpen = false;
  currentUser: any;
  permission: any;
  perm: any;
  permSocial:any;
  hasAccess = false;


  constructor(
    private fb: UntypedFormBuilder,
    private location: Location,
    private service: SocialMediaService,
    private settingsService: AppSettingsService,
    private permissionService: PermissionService,
    private authService: AuthService,
    private changeVisibilityService: ChangeVisibilityService
  ) { }

  get facebook() { return this.socialForm.get('facebook'); }
  get twitter() { return this.socialForm.get('twitter'); }
  get instagram() { return this.socialForm.get('instagram'); }
  get youtube() { return this.socialForm.get('youtube'); }
  get pinterest() { return this.socialForm.get('pinterest'); }
  get tiktok() { return this.socialForm.get('tiktok'); }
  get linkedin(){ return this.socialForm.get('linkedin'); }


  ngOnInit() {
    this.currentUser = this.authService.getGlobalUser();
    if(this.currentUser){

      if(this.currentUser.role=='Admin'){
         this.hasAccess = true;
        }
      if(this.currentUser.role=='Employee'){
        this.permissionService.getPermissionByEmployeeId(this.currentUser.uid).valueChanges().subscribe(permission => {
          if (permission && permission.length > 0){
            this.perm = permission[0]
            this.permSocial = this.perm.permission[3];
            if(this.permSocial.linkAccount) {
              this.hasAccess = true;
            }
            
          }
        });
      }
    }
    this.socialForm = this.fb.group({
      facebook: ['', [ Validators.pattern(this.urlPattern)]],
      twitter: ['', [Validators.pattern(this.urlPattern)]],
      instagram: ['', [ Validators.pattern(this.urlPattern)]],
      youtube: ['', [ Validators.pattern(this.urlPattern)]],
      pinterest: ['', [ Validators.pattern(this.urlPattern)]],
      tiktok: ['', [ Validators.pattern(this.urlPattern)]],
      linkedin: ['', [ Validators.pattern(this.urlPattern)]],
    });

    this.service.getSocialMedia().valueChanges().subscribe(x => {
      if (x) {
        if (x.length > 0) {
          this.socialForm.patchValue({
            facebook: x[0].facebook,
            twitter: x[0].twitter,
            instagram: x[0].instagram,
            youtube: x[0].youtube,
            pinterest: x[0].pinterest,
            tiktok: x[0].tiktok,
            linkedin: x[0].linkedin ? x[0].linkedin : ''
          });

          this.hasSocial = true;
          this.socialID = x[0].id;

          setTimeout(() => {
            // M.updateTextFields();
          }, 50);
        }
      }
    });
    this.settingsService.getAppSettingsList().valueChanges().subscribe(hp => {
      if (hp) {
        if (hp.length > 0) {
          this.pageSections = hp[0];

        }
      
      }
    });

  }


  changeVisibility(){
    this.changeVisibilityService.updateAppSections('Social Media');
  }

  submitForm(value: any) {
    this.changeVisibility()
    if (value.facebook){
      const facebookPrefix = value.facebook.split(':')[0].toLowerCase();
      if (!(facebookPrefix === 'http' || facebookPrefix === 'https')){
        value.facebook = 'https://' + value.facebook;
      }
    }
    if (value.twitter){
      const twitterPrefix = value.twitter.split(':')[0].toLowerCase();
      if (!(twitterPrefix === 'http'  || twitterPrefix === 'https')){
        value.twitter = 'https://' + value.twitter;
    }
    }
    if (value.instagram){
      const instaPrefix = value.instagram.split(':')[0].toLowerCase();
      if (!(instaPrefix === 'http'  || instaPrefix === 'https')){
        value.instagram = 'https://' + value.instagram;
    }
    }
    if (value.youtube){
      const youtubePrefix = value.youtube.split(':')[0].toLowerCase();
      if (!(youtubePrefix === 'http'  || youtubePrefix === 'https')){
        value.youtube = 'https://' + value.youtube;
    }
    }

    if (value.pinterest){
      const pinterestPrefix = value.pinterest.split(':')[0].toLowerCase();
      if (!(pinterestPrefix === 'http'  || pinterestPrefix === 'https')){
        value.pinterest = 'https://' + value.pinterest;
    }
    }

    if (value.tiktok){
      const tiktokPrefix = value.tiktok.split(':')[0].toLowerCase();
      if (!(tiktokPrefix === 'http'  || tiktokPrefix === 'https')){
        value.tiktok = 'https://' + value.tiktok;
    }
    }

    if (value.linkedin){
      const linkedinPrefix = value.linkedin.split(':')[0].toLowerCase();
      if (!(linkedinPrefix === 'http'  || linkedinPrefix === 'https')){
        value.linkedin = 'https://' + value.linkedin;
    }
    }
    const model =  {
      facebook: value.facebook ? value.facebook : '',
      twitter: value.twitter ? value.twitter : '',
      instagram: value.instagram ? value.instagram : '',
      youtube: value.youtube ? value.youtube : '',
      pinterest: value.pinterest ? value.pinterest : '',
      tiktok: value.tiktok ? value.tiktok : '',
      linkedin: value.linkedin ? value.linkedin : '',
      id: ''
    };

    if (!this.hasSocial) {
      this.service.saveSocialMedia(model).then(resp => {
        this.toast({ html: 'Social Media Links Successfully Saved!', classes: 'green', type: 'success' });
        if(this.calledFromEmptyDashboard == 'linkSocials'){
          this.socialsCreated.emit('socialsCreated')
        }
        this.backClicked();
      });
    }

    if (this.hasSocial) {
      model.id = this.socialID;
      this.service.updateSocialMedia(model).then(resp => {
        this.toast({ html: 'Social Media Links Successfully Updated!', classes: 'green', type: 'success' });
        this.backClicked();
        if(this.calledFromEmptyDashboard == 'linkSocials'){
          this.socialsCreated.emit('socialsCreated')
        }
      });
    }
    this.closeModal();
  }
  backClicked() {
    if(!this.calledFromEmptyDashboard){
      this.location.back();
    }
  }
  


toast(obj){
  this.toastMessage = obj.html;
  this.toastClass = obj.classes ? obj.classes : 'green' ;
  this.toastType = obj.type ? obj.type : 'success';
  this.openToast = true;
  setTimeout(() => {
    this.openToast = false;
  }, 2000);
}

closeModal(){
  this.createModalOpen = false;
  this.editModalOpen = false;
}
skipSetup(){
  this.skip.emit();
}
}
