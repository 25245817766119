import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LoadingState } from 'src/app/models/loadingState';
@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private loaderSubject = new Subject<LoadingState>();
  loaderState = this.loaderSubject.asObservable();

  constructor() { }

  // show() {
  //   this.loaderSubject.next({ show: true } as LoadingState);
  // }
  // hide() {
  //   this.loaderSubject.next({ show: false } as LoadingState);
  // }

  // async getLoading() {

  //   let show = false;

  //   await this.loaderState.subscribe((value) => {

  //     // console.log("under loader state ", value)
  //     show = value.show
  //   });


  //   return show;
  // }

  private loading: boolean = false;

  setLoading(loading: boolean) {
    this.loading = loading;
  }

  show(){
    this.loading = true;
  }

  hide(){
    this.loading = false;
  }
  
  getLoading(): boolean {
    return this.loading;
  }
}
