<div class="row">

    <div class="col s12 ">
      <div class="body-h">
        <div class="header-setting b-m-2 ">
          <div class="col l9 m9 s12">
            <h4 class="font-bold text-xl flex justify-center"> Survey / Polls List </h4>  
            </div>
  
          <div class="flex justify-end m-4 mr-6">
            <button type = "button" [routerLink]="['/admin/polls-and-survey']" class="flex px-4 py-2 bg-customTeal text-white rounded-md"
              ><i class="material-icons">add</i> Add Polls/Survey</button>
          </div>
  
        </div>

        <div *ngIf="!model">
          <div class="flex items-center justify-center">
            <img loading="lazy" data-src="../../../../assets/icons/Illustrations/RegistrationRequest-Illustration.png"
              class="block w-64 flex items-center justify-center">
          </div>
          <div class="text-customTeal text-lg text-center font-bold"> 0 Survey/ Poll found in the Database.</div>
        </div>
  
        <main class="StickyContent">
  
          <div class="row ">
  
            <div class="col s12 l12">

  
              <div class="flex md:flex-row flex-col" *ngIf="model">
                <div class=" flex flex-col p-6 rounded-lg border-solid border border-gray-200 bg-white flex m-2" *ngFor="let item of model; let i=index;">

                  <div class="col s12">
                    <div class="row">
                        <h6 class="truncate bold-700 title-section3">{{item.name}}</h6>
                    </div>
                    <ul class="row collection">
                      <li class="flex justify-between m-2">
                        <div class=""> <strong class="text-black">End Date</strong></div>
                        <div class="text-black"> <strong class="right"> {{item.endDate}} </strong></div>
                      </li>
                    </ul>

                  </div>

                  <div class="flex md:space-x-10 space-x-4">

                    <div class="">
                      <a class="flex text-customTeal font-bold rounded-md py-2 px-6 w-max border-solid border bg-white border-customTeal"
                        [routerLink]="['/admin/poll-result', item.id]"> <span>
                        Live Results</span></a>
                    </div>

                    <div class="">
                      <a class="flex text-customTeal font-bold rounded-md py-2 px-6 w-max border-solid border bg-white border-customTeal"
                        [routerLink]="['/admin/edit-poll', item.id]">
                        <span>Edit Survey
                        </span></a>
                    </div>

                  </div>

                </div>
              </div>
  
            </div>
  
          </div>
        </main>
      </div>
    </div>
  </div>