import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalConstants } from 'src/app/models/GlobalConstants';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { ScrollToTopService } from 'src/app/services/helper/scroll-to-top.service'
import { Modal, Ripple, initTWE } from "tw-elements";
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { selectAppSettings } from 'src/app/state/app.selectors';
import { HelperService } from 'src/app/services/helper/helper';

@Component({
  selector: 'app-onboarding-modal',
  templateUrl: './onboarding-modal.component.html',
  styleUrls: ['./onboarding-modal.component.css'],
})
export class OnboardingModalComponent implements OnInit, OnChanges {

  @Input() sectionName;
  @Input() appointmentType;
  @Input() finalStep;
  @Output() skipPage = new EventEmitter<string>();
  @Output() previousPage = new EventEmitter<string>();
  @Output() previewPage = new EventEmitter<string>();
  @Output() finalize = new EventEmitter();
  @ViewChild('topSection') targetElement: ElementRef; 
  @Input() allSteps;
  @Input() originalName;
  @Input() displayName;
  @Input() serviceType;
  @Input() isFinalPage;
  view: any
  previewModal = false;
  displaySuccess = false;
  step=0;
  previewSection = false;
  previewHomepage = false;
  successValue = '';
  successType = '';
  emailSMS='chooseNumber';
  quickGuide = GlobalConstants.quickGuide;
  showCreated = false;
  availablePhone : any;
  appSetting: any;
  takeAppointment = '';  
  fromPreview = false
  customSection: any;
  appSettings$: Subscription;

  constructor(private router: Router,
    private appSettingsService: AppSettingsService,
    private scrollService: ScrollToTopService,
    private helperService: HelperService,
    private store: Store) { 
      this.appSettings$ = this.store.select(selectAppSettings).subscribe((settings) =>{
        if(settings){
          let setting = this.helperService.cloneDeep(settings)
          this.appSetting = this.helperService.cloneDeep(settings)

          this.availablePhone = setting.availablePhone;
          this.takeAppointment = setting.takeAppointment;
          if(this.appSetting.viewType){
            if(this.appSetting.viewType == 'list' || this.appSetting.viewType == 'card'){
              this.view ='web'
            }else{
              this.view = this.appSetting.viewType
            }
          }else{
            this.view ='web'
          }
        }
      })
    }
    


  ngOnInit(): void {
    initTWE({ Modal, Ripple });
    this.scrollService.scrollToTopOnNavigation();
    const btn1 = document.getElementById('onBoardingClose');
    this.router.events.subscribe((val) =>{
      btn1.click()
      
    })
    setTimeout(() => {
      const element = this.targetElement.nativeElement;
      if (element) {
        element.scrollIntoView();
      }     
    });

    if(this.appointmentType=='appointment' && this.sectionName=='appointment'){
      this.step = 1
    }else{
      this.stepAdjust()
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.displaySuccess =false;
    this.sectionName = this.sectionName;
    // if there isn't any appointment we want the second step to show i.e only adding employee
    if(this.appointmentType=='appointment' && this.sectionName=='appointment'){
      this.step = 1
    }else{
      this.stepAdjust()
    }
    this.displayName = this.displayName;
    this.scrollService.scrollToTopOnNavigation();
    this.scrollService.scrollToTop();
    this.showCreated = false
    // 
    let name = this.originalName
    this.originalName = name
  }
  goToTop(){
    const element = this.targetElement.nativeElement;
    if (element) {
      element.scrollIntoView();
    }     
  }
  skipClicked(){
    this.step = 0;
    this.displaySuccess = true;
    this.skipPage.emit(this.sectionName)
  }
  previousClicked(){
    this.step = 0;
    this.previousPage.emit(this.sectionName)
  }

  finalizeSetup(){
    const btn1 = document.getElementById('onBoardingClose');
    this.router.events.subscribe((val) =>{
      btn1.click()
    })
    
    this.finalize.emit('true')
    
  }
  stepClicked(index){
    this.step = index;
    this.displaySuccess = false; 
  }

  getSectionName(){
    return this.originalName
  }

  displaySuccessCard(val, type){
    this.scrollService.scrollToTopOnNavigation();
    this.scrollService.scrollToTop();
    this.successValue = val;
    this.displaySuccess = true; 
    this.successType = type;
  }
  keepAdding(name){
    this.displaySuccess = false;
    this.scrollService.scrollToTop();
  }
  progressEvent(name){
  }
  previewHomePage(){
    this.previewPage.emit('true')
  }
  closePreview(){
    this.fromPreview = false
    // this.step=0
  }

  stepAdjust(){
    const myModalEl = document.getElementById("onboardingModal");
    this.step=0

    myModalEl.addEventListener("hidden.te.modal", (e) => {
      if(!this.fromPreview){  
        this.step=0;
      }
    });
  }
  
  nextClicked(){
    if (this.step === 0) {
      if (this.sectionName !== 'socialMediaPage' && this.sectionName!=='faq' ) {
          this.step++;
          this.displaySuccess = false;
      } else {
          this.skipClicked();
      }
  } else if (this.step === 1) {
      if (this.sectionName === 'homePage' || this.sectionName === 'audience' || this.sectionName=='appSetup') {
          this.step++;
          this.displaySuccess = false;
      } else {
          this.skipClicked();
      }
  } else if (this.step === 2 && (this.sectionName === 'homePage'|| this.sectionName === 'audience' || this.sectionName==='appSetup')) {
      this.step++;
      this.displaySuccess = false;
      if(this.sectionName==='audience' || this.sectionName==='appSetup'){
        this.skipClicked()
      }
  } else if (this.step === 3 && (this.sectionName === 'homePage')) {
      this.skipClicked();
  }
  this.scrollService.scrollToTop();
  }

  changeViewType(view){
    this.view = view
    this.appSetting.viewType = view
      this.appSettingsService.updateAppSettings(this.appSetting).then(() => {
      });
  }
  createCustomSection(sectionId){
    this.customSection = sectionId
  }
}
