import { Component, OnInit } from '@angular/core';
import { AyrshareService } from 'src/app/services/social-media/ayrshare.service';
import {  JwtModel, UserProfile, UserProfileModel } from 'src/app/models/SocialMediaPostModel';
import {  UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { environment } from 'src/environments/environment';
import { AyshareUserService } from 'src/app/services/firebase/ayrshare-social-media.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { UserService } from 'src/app/services/firebase/user.service';
import { EmailService } from 'src/app/services/api/email.service';
import { EmailModel } from 'src/app/models/emailModel';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfieKeyService } from 'src/app/services/social-media/profileKey.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { Location } from '@angular/common';
import { PermissionService } from 'src/app/services/firebase/permission.service';

@Component({
  selector: 'app-admin-social-main',
  templateUrl: './admin-social-main.component.html',
  styleUrls: ['./admin-social-main.component.css']
})
export class AdminSocialMainComponent implements OnInit {


  user
  privateKey = environment.socialMedia.privateKey
  url = environment.socialMedia.profileUrl
  profileKey
  profileForm: UntypedFormGroup;

  showProfiles=true
  selectedProfile
  model
  defaultThemeColor='#077373'
  themeColor
  userProfile
  openSidebar = false;
  minimizedMenu = false;


  appLogo: string;
  rightOrLeftSidebar: boolean;
  appName: any;

  loading
  hasProfile=false

  employees:any

  profileModalOpen = false;
  profileCreatedModalOpen = false;
  addEmployee=false

    // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;

  isAdmin: boolean;
  perm: any;
  permission: any;

  sharePost=false

  permMember: any;
  permPES: any;
  permAnalytics: any;
  permSocial: any;
  permSpecial: any;
  isNationApp = environment.firstNation.Origin == "FNLeaders" ? true : false;


  constructor(
      private userProfileService:AyshareUserService,
      private userService:UserService,
      private authService: AuthService,
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private profieKeyService:ProfieKeyService,
      public settingsService: AppSettingsService,
      private location: Location,
      private fb: UntypedFormBuilder,
      private socialMediaService:AyrshareService,
      private emailService: EmailService,
      private permissionService: PermissionService
  ) { }

  ngOnInit() {

      this.user = this.authService.getGlobalUser();
      if(this.user.role == "Admin"){
        this.isAdmin = true
      }

      this.permissionService.getPermissionByEmployeeId(this.user.uid).valueChanges().subscribe(perm =>{
        if(perm.length != 0){
          this.perm = perm[0];
          //this.permission = this.perm.permission[0];
          this.permMember = this.perm.permission[0]
          this.permPES = this.perm.permission[1]
          this.permAnalytics = this.perm.permission[2];
          this.permSocial = this.perm.permission[3];
          this.permSpecial = this.perm.permission[4];
        }
      });

      if(this.user){
        this.loading = true;
          this.userProfileService.getOwnerProfiles(this.user.uid).valueChanges().subscribe(p=>{
              if(p){
                  if(p.length>0){
                      this.userProfile=p
                      this.profileSelect(this.userProfile[0])
                      this.hasProfile=true

                      
                      // this.profileSelect(this.userProfile[0])
                      this.loading=false
                  }
              this.loading = false;

              }
          })
      }

      this.userService.getUserByRole('Employee').valueChanges().subscribe((res: any) => {
          if (res.length > 0 ){
            this.employees = res;
          }
        });



      this.profileForm=this.fb.group({
          title:['',[Validators.required]],
          displayTitle:[''],
          hideTitle:[true],
          employeeId:[''],
          terms:['',[Validators.required]],
      })


    this.appLogo = '';



  
    this.settingsService.getAppSettingsList().valueChanges().subscribe(
      appSettings => {
        if (appSettings) {
          if (appSettings.length > 0) {
            this.model = appSettings[0];
            document.documentElement.style.setProperty('--color-primary',
            this.model.themeColor ? this.model.themeColor : this.defaultThemeColor);

            // this.navbarOption = this.model.navbarOption;
            this.themeColor = this.model.themeColor ? this.model.themeColor : this.defaultThemeColor;
            this.appLogo = this.model.logo ? this.model.logo : '';
            this.appName = this.model.appName ? this.model.appName : 'MyNation';
            // this.rightOrLeftSidebar = this.model.rightOrLeftSidebar;
          } else {
              document.documentElement.style.setProperty('--color-primary', this.defaultThemeColor);

          }

        } else {
            document.documentElement.style.setProperty('--color-primary', this.defaultThemeColor);

        }
      }
      );

  }

  get terms() { return this.profileForm.get('terms'); }
  profileSelect (profile){
      this.profileKey=profile.profileKey
      this.selectedProfile=profile
      this.showProfiles=false

      this.profieKeyService.updateProfileKey(this.profileKey)

      if(this.profieKeyService.shouldShare){
        this.router.navigate(['create-post'], 
          { 
              relativeTo: this.activatedRoute
          }
          )
          return true
      }

      this.router.navigate(['dashboard'], 
          { 
              relativeTo: this.activatedRoute
          }
          )
  }

  // profileChange(event){
  //   let index = event.target.value
  //   this.selectedProfile=this.userProfile[index]
  //   this.profileKey=this.userProfile[index].profileKey
  //   this.profieKeyService.updateProfileKey(this.profileKey)

  // //   this.profileKey=this.userProfile[index].profileKey

  // }
  showProfilesList(){
      this.profileKey=''
      this.showProfiles=true
      if (this.openSidebar) this.toggleSideBar()
  }

  back(){
    this.location.back();
  }
  forward(){
    this.location.forward();
  }

  signOut() {
    this.authService.signOut();
  }
  toggleSideBar(){
    this.openSidebar = !this.openSidebar;
  }
  toggleMinimizeMenu(){
    this.minimizedMenu = !this.minimizedMenu;
  }

  linkSocialMedia(){
    let model = new JwtModel()


        model.privateKey=this.privateKey
        model.profileKey = this.profileKey
        this.socialMediaService.generateJWT(model).subscribe(x=>{
          
            if(x['status'] === 'success'){

                window.open(x['url'],'_blank')
            }else{
            }
        })

}


  openProfileModal(){
    this.profileModalOpen = true;
  }

  openSuccessModal(){
    this.profileCreatedModalOpen = true;
  }

  closeSuccessModal(){
    this.profileCreatedModalOpen = false;
  }
  closeProfileModal(){
    this.profileModalOpen = false;
    this.profileForm.reset()
    this.addEmployee=false
  }

  getEmailForEmployee(email) {
    let msg = new EmailModel();

    let emails: string[] = []

    emails.push(email)
    msg.emailList = emails;
    msg.isNationApp = this.isNationApp;
    msg.subject =  "New Profile created";
    msg.body = "A profile has been created on  your behalf; please login to your account to create posts on your behalf."


    return msg
  }



  createUserProfile(values){
    let model = new UserProfile()
   
     Object.keys(values).forEach(key=>{
        model[key] =values[key]
    })

    this.closeProfileModal()
    this.socialMediaService.createUserProfile(model).toPromise().then(x=>{
        if(x['status'] == 'success'){
            let userProfile={ }
            userProfile['refId']=x['refId']
            userProfile['userId']=this.user.uid
            userProfile['profileKey'] = x['profileKey']
            userProfile['title'] = x['title']

            if (values.employeeId ) userProfile['employeeId'] = values.employeeId

            this.userProfileService.createUserProile(userProfile).then(p=>{
              setTimeout(() => {
                this.openSuccessModal()
              }, 2);

                if (values.employeeId ){
                  let employee = this.employees.filter(x=>x.uid ==values.employeeId)
                    let msg = this.getEmailForEmployee(employee[0].Email)
                    this.emailService.sendEmail(msg).subscribe(x => {

                        if (x && x.Status == 'OK') {
                          let recipient = msg.emailList[0]
                          this.toast({html: 'Email sent', classes: 'green', type: 'success'});


                        } else {
                        }

                      })
                }

            }).catch(err=>{
                console.log(err)
                this.toast({html: 'Error creating profile', classes: 'red', type: 'failure'});

            })

        }else{
        }
    }).catch(err=>{
      this.toast({html: `${err.error.message}`, classes: 'red', type: 'failure'});
    })
}

navigate(){
  this.model.gettingStarted.socialMediaPage  = true
  this.settingsService.updateAppSettings(this.model).then(() => {
    if(this.user.role == 'Admin'){
      this.router.navigate(['/admin/homepage'])
    }else{
      this.router.navigate(['/employee/dashboard'])
    }


  });

}

toast(obj){
  
  this.toastMessage = obj.html;
  this.toastClass = obj.classes ? obj.classes : 'green' ;
  this.toastType = obj.type ? obj.type : 'success';
  this.openToast = true;
  setTimeout(() => {
    this.openToast = false;
  }, 2000);
}




}
