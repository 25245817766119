import { Component, Input, OnInit } from '@angular/core';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
})
export class LoaderComponent implements OnInit {

    @Input() customConfig
    @Input() type = 'spinner'

    customCss



    config={
        "spinner": "h-10 w-10  text-black",
        "pulse": "max-w-sm w-full mx-auto"
    } 
    

    constructor( ) { }

    ngOnInit(): void {
        this.type = this.type in this.config ? this.type : 'spinner'

        if(this.customConfig && typeof this.customConfig !== 'undefined'){
            this.customCss= this.customConfig
        }
        // else if(this.type in this.config){
        //     this.customCss = this.config[this.type]
        // }
        else{
            this.customCss=this.config[this.type]
        }
       
    }
}
