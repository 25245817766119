import { Router } from '@angular/router';
import { UserService } from './../../../services/firebase/user.service';
import { EmailService } from './../../../services/api/email.service';
import { AuthService } from '../../../services/firebase/auth.service';
import { FormBuilder, FormGroup, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SMSService } from 'src/app/services/api/sms.service';
import { SMSModel } from 'src/app/models/SMSModel';
import { EmailModel, CustomAdminEmailModel } from 'src/app/models/emailModel';
import { PermissionService } from 'src/app/services/firebase/permission.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { VerificationService } from 'src/app/services/firebase/verification.service';
import { CountersService } from 'src/app/services/firebase/counters.service';
import { NotificationModel } from 'src/app/models/Messages.Model';
import { FCMPushNotificationsService } from 'src/app/services/firebase/FCMPushNotifications.service';
import { HelperService } from 'src/app/services/helper/helper';
import { FCMTokenService } from 'src/app/services/firebase/FCMToken.service';
import { EnvironmentService } from 'src/app/services/helper/env';
import { read, utils } from 'xlsx';
import { ContactService } from 'src/app/services/firebase/contact.service';
import { ToastrService } from "ngx-toastr";
import { LoadingService } from 'src/app/services/loader/loading.service';
import { environment } from 'src/environments/environment';
import { ViewEncapsulation } from '@angular/core';
import { CompositeIndexService } from 'src/app/services/firebase/composite-index-service';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class UsersListComponent implements OnInit {
  deleteContactOpenModal = false;
  @Input() createdFrom;
  @Output() cancelBtnClicked = new EventEmitter<void>();
  @Output() selectedSubs = new EventEmitter<any>();


  constructor(private userService: UserService,
    private settingsService: AppSettingsService,
    private compositeIndexService: CompositeIndexService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private emailService: EmailService,
    private authService: AuthService,
    private sMSService: SMSService,
    private permissionService: PermissionService,
    private appSettingsService: AppSettingsService,
    private verificationService: VerificationService,
    private counter: CountersService,
    private fcmPushService: FCMPushNotificationsService,
    private helperervice: HelperService,
    private fcmService: FCMTokenService,
    private envService: EnvironmentService,
    private contactService: ContactService,
    private toastr: ToastrService,
    private loaderService: LoadingService
  ) { }

  //User
  allUsers: any;
  totalSubLength: any;
  role: any;
  isAdmin: boolean = false;
  email = '';
  emailMessage: any;

  search_text: any;

  currentUser: any;
  host = window.location.hostname !== 'localhost' ? 'https://' + window.location.hostname + '/register-contact-from-invite' : 'http://localhost:4200/register-contact-from-invite';

  subscribers: any;
  selectAllchecked: boolean = false;
  selectedSubscribers = [];
  selectedImportedSubscribers = [];
  checkUser: boolean = false;

  //for the sending messages
  isSMS: boolean = false;
  notificationType = 'sms'
  message: any;
  disable: boolean = false;

  //modals
  contactUserModal: boolean = false;

  //the emails are stored in this array for email to be send to them
  storeEmail = [];
  storePhone = [];

  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;

  //Global
  verifyGlobal: any;

  //for permission
  perm: any;
  permMember: any;
  permSocial: any;
  permPES: any;
  permSpecial: any;
  permAnalytics: any;

  //appsetup
  model: any;
  appName: any;
  appLogo: any;

  //to fetch all the verified subscribers
  getVerified: any;

  //for emails and SMS signature
  setUp: any;
  greetings = '';
  signOff = '';
  messageForSMS: any;
  contactForm: UntypedFormGroup;

  //this are the arrays used to filter Email/SMS/Both/ and Verified Users
  contact: any;
  SMSPreference = [];
  emailPreference = [];
  bothPreference = [];
  verifiedPreference = [];
  importedUsers = []

  adminCounter: any;
  adminSMSCounter: number = 0;
  adminEmailCounter: number = 0;

  currentDate = new Date();
  isLimitSMS: boolean = false;
  isLimitEmail: boolean = false;
  // isNationApp = this.envService.isNationApp
  availablePhone: any;

  showFileUploadModal: boolean = false;
  existingFormFields: any;
  userModel: any;
  preferenceOptions: any[] = ["All", "Email", "SMS", "Both", "Verified", "Imported"];
  selectedPreference: any = null

  // to manage the pagination
  currentPage: any = 0;
  nextPageAvailable: any = false;
  totalPage: any = 100;

  isNationApp = environment.firstNation.Origin == "FNLeaders" ? true : false;
  domain = environment.firstNation.name;
  domainName = this.isNationApp ? "https://" + this.domain + ".mynation.app" : "https://" + this.domain + ".goingmobile.app";

  sendingInvitation: any = false
  emailHtmlBody: any = ``
  smsBody: any = ``
  // host = window.location.hostname !== 'localhost' ? 'https://' + window.location.hostname + '/register-from-invite/' : 'http://localhost:4200/register-from-invite/';

  showDropdownButton: any = false;

  // composite index
  compositeIndexId = ""
  compositeIndexStatus = null
  paginationDirection = null;
  usersListRef: any = []
  isLoading: boolean = false;
  showConfirmationModal: boolean = false;
  showPaginationModal: boolean = false;
  showFilterModal: boolean = false;

  ngOnInit(): void {

    this.currentUser = this.authService.getGlobalUser();

    this.contactForm = this.fb.group({
      contact: ['Email'],
      subject: [''],
      emailbody: [''],
      messageSMS: ['']
    });

    // Get the current month
    const currentMonth = this.currentDate.getMonth();

    // Move to the next day
    const nextDay = new Date(this.currentDate);
    nextDay.setDate(this.currentDate.getDate() + 1);

    // Check if the next day is in a different month
    const isNewMonth = nextDay.getMonth() !== currentMonth;

    //this is needed to filter out the verified accounts
    this.verificationService.getVerifiedAccounts().valueChanges().subscribe((getVerified: any) => {
      if (getVerified && getVerified.length != 0) {
        this.verifiedPreference = getVerified;
        // for (let i = 0; i < getVerified.length; i++) {
        //   this.getVerified = getVerified[0];
        //   const email = this.getVerified.email;
        //   this.verifyGlobal = this.userService.getUserByEmail(email).valueChanges().subscribe((user) => {
        //     if (user && user.length != 0) {
        //       this.verifiedPreference.push(user[0]);
        //     }
        //     this.verifyGlobal.unsubscribe();
        //   })
        // }
      }
    })

    if (this.currentUser) {
      this.role = this.currentUser.role;

      if (this.role === 'Admin') {
        this.isAdmin = true;
        this.appSettingsService.getAppSettingsList().valueChanges().subscribe((setup: any) => {
          this.setUp = setup[0] || {};
          this.greetings = this.setUp.greetings || '';
          this.signOff = this.setUp.signOff || '';
          this.email = `${this.greetings} \n \n \n \n ${this.signOff}`; // this will properly display the greetings and signoff on the textarea that comes from the admin
          this.messageForSMS = '\n \n' + (this.setUp.messageSMS || '');
          this.availablePhone = this.setUp.availablePhone || '';
          this.contactForm.patchValue({
            emailbody: this.email,
            messageSMS: this.messageForSMS
          });

        });

      }

      this.permissionService.getPermissionByEmployeeId(this.currentUser.uid).valueChanges().subscribe(perm => {
        if (perm && perm.length != 0) {

          this.perm = perm[0];
          [this.permMember, this.permPES, this.permAnalytics, this.permSocial, this.permSpecial] = this.perm.permission;
        }

      })
    }

    this.counter.getCounter().valueChanges().subscribe((adminCounter: any) => {
      if (adminCounter && adminCounter.length !== 0) {
        // Extract the first element from adminCounter array
        this.adminCounter = adminCounter[0];

        // Initialize counters with default values or values from adminData
        this.adminSMSCounter = this.adminCounter.SMSTotalCounter || 0;
        this.adminEmailCounter = this.adminCounter.emailTotalCounter || 0;

        if (isNewMonth) {
          // Reset counters if it's a new month
          this.adminSMSCounter = 0;
          this.adminEmailCounter = 0;
        } else {
          // Check if the counters exceed the limit
          this.isLimitSMS = this.adminSMSCounter > 10000;
          this.isLimitEmail = this.adminEmailCounter > 10000;

        }
      } else {
        // If no adminCounter data is found, initialize with default values
        const counter = {
          id: '',
          SMSTotalCounter: 0,
          emailTotalCounter: 0,
          socialMediaCount: 0,
          chatGptCounter: 0,
        };

        // Save the default counter values
        this.counter.saveCounter(counter);

        // Reset counters to 0
        this.adminSMSCounter = 0;
        this.adminEmailCounter = 0;
      }
    });

    this.settingsService.getAppSettingsList().valueChanges().subscribe((appSettings: any) => {

      if (appSettings && appSettings.length > 0) {
        this.model = appSettings[0];
        this.appName = this.model.appName;
        this.appLogo = this.model.logo;
      }

      // this.invitationEmailTemplate();

      // this.invitationSmsTemplate();

    }
    );

    //This is also needed to filter the users with SMS or Email pereferences or both
    if (this.selectedPreference != "Verified") {
      this.fetchUsersData();
    } else {
      this.subscribers = this.verifiedPreference;
    }


    // fetch contact header
    this.fetchContactHeaders()

    // invitation to email
    // this.invitationEmailTemplate();

  }

  // fetch users data
  fetchUsersData(paginate?) {
    let lastItem = this.allUsers?.length ? this.allUsers[this.allUsers?.length - 1] : [];

    // set paginaton direction
    this.paginationDirection = paginate;

    // paginate the data 
    try {
      if (paginate === 'prev' && this.usersListRef?.length) {
        lastItem = this.allUsers[0];
      }

      // set loader
      this.isLoading = true

      this.userService.getPaginatedUsers(paginate ? lastItem : null, paginate, this.search_text, this.selectedPreference).subscribe((querySnapshot) => {
        let users = [];

        querySnapshot.forEach((doc) => {
          users.push({ id: doc.id, ...doc.data() })
        });

        // set loader
        this.isLoading = false;

        // reset imported users list
        this.importedUsers = []
        this.subscribers = [];
        this.allUsers = [];
        this.totalSubLength = 0;
        if (users && users.length > 0) {
          // set the current page for pagination purpose
          this.currentPage = paginate == "prev" && this.currentPage >= 1 ? this.currentPage - 1 : paginate == "next" ? this.currentPage + 1 : 0;
          this.nextPageAvailable = users?.length === 10 ? true : false;

          this.allUsers = users;

          this.usersListRef.push(this.allUsers[this.allUsers?.length - 1]);
          this.subscribers = users;
          this.totalSubLength = users.length;
          for (let i = 0; i < this.totalSubLength; i++) {
            const users = this.allUsers[i];

            if (users?.contact?.includes('Email') && (users?.contact?.includes('SMS'))) {
              this.bothPreference.push(users)
            }

            if (users?.contact?.includes('SMS')) {
              this.SMSPreference.push(users)

            }

            if (users?.contact?.includes('Email')) {
              this.emailPreference.push(users)
            }

            if (users.verified && !this.verifiedPreference.includes(users)) {
              this.verifiedPreference.push(users);
            }

            if (users.importedUser) {
              this.importedUsers.push(users)
            }


          }

        }
        else {
          this.nextPageAvailable = false
        }


      }, (err) => {
        // loading
        this.isLoading = false;
      });

    } catch (err) {
    }

  }

  fetchAllUsersData() {
    // paginate the data 
    try {
      // set loader
      this.userService.getAllUsers().valueChanges().subscribe((newUsers: any) => {
        let users = newUsers;
        if (users && users.length > 0) {
          // set the current page for pagination purpose
          const eligibleUsers = users.filter(user => (
            !this.selectedSubscribers.includes(user) &&
            (user.contact.includes('Email') || user.contact.includes('SMS') || user.contact.includes('InApp'))
          ));
          this.selectedSubscribers.push(...eligibleUsers);
        }
      }, (err) => {
        console.error("err is ", err)
      });

    } catch (err) {
      console.error("err is ", err)
    }

  }

  // invitation email template
  invitationEmailTemplate(contactName?: any, id?: any) {
    let emailTemplate = ``

    emailTemplate = '<div style="background-color: grey;  font-family: Helvetica, Arial, Sans-Serif;">';
    emailTemplate += '<div style="width: 600px; background-color: white;  display: block; margin-left: auto; margin-right: auto; border-width: 6px; border-bottom-style: solid; border-color: #077373;">';
    emailTemplate += '<center>';
    if (this.appLogo) {
      emailTemplate += `<img
      src="${this.appLogo}"
      width="100" height="100" style=" display: block; margin-top:5px;border-radius:10px; padding:5px " alt="Logo">`;
    }
    emailTemplate += '</center>';
    emailTemplate += '<center>'
    emailTemplate += '<h2 style="text-transform: capitalize; padding:10px; font-size:20px; font-weight: bold; color: #077373;">  Welcome to ' + this.appName + ' </h2></center>';
    emailTemplate += '<h3 style="text-transform: capitalize; padding: 5px;">  Dear <span >' + `${contactName ? contactName : 'ContactName'}` + ' </span>, </h3>';
    // emailTemplate += '<center><p style="padding-left: 20px; padding-right: 20px; padding-bottom: 20px; text-align: justify">' + 'This is ' + '<span>' + this.currentUser.GivenName + '</span>' + ' from ' + 
    // '<span style="font-weight: bold">' + this.appName + '</span>' +
    //   ','+ this.contactForm.get('emailbody').value +' .</p>';
    // emailTemplate += '</center>';

    // emailTemplate += '<center><p style="padding:10px; text-align: justify">' +
    //   + this.contactForm.get('emailbody').value + ' .</p>';
    // emailTemplate += '</center>';

    emailTemplate += this.contactForm.get('emailbody').value

    emailTemplate += '<div style="display:flex; justify-content: center; padding-left: 20px; padding-right: 40px; justify-content: center;">';


    emailTemplate += '</div>';
    emailTemplate += '<div style="display: block; margin-left: auto; margin-right: auto; width: max-content;">';
    emailTemplate += `<a href="${this.host + "/" + id}" style=" background-color: #077373; cursor: pointer; border-radius: 10px; padding: 15px 32px; text-align: center; text-decoration: none; display: inline-block; font-size: 16px; margin: 4px 2px; cursor: pointer; font-weight: bold; color: white; ">Create Account</a>`;
    emailTemplate += '</div>';
    emailTemplate += '<hr> ';
    emailTemplate += '<div style="background-color: #e5e5e5; padding-left: 20px;">';
    emailTemplate += '<p style="font-size: 12px; margin: 0; color:#636363; padding-top: 10px;">' +
      this.currentUser.GivenName + ' ' + this.currentUser.LastName + '</p>';
    emailTemplate += `<p style="font-size: 12px; margin: 0; color:#636363; padding-top: 5px; padding-bottom: 10px;">${this.currentUser.Email}</p>`;

    emailTemplate += '</div>';
    emailTemplate += '</div>';

    this.emailHtmlBody = emailTemplate;

  }

  // invitation sms template
  invitationSmsTemplate(contactName?: any, id?: any) {
    let smsTemplate = ``

    smsTemplate = "hello"


    this.smsBody = `Dear ${contactName ? contactName : 'ContactName'} \n` +
      this.contactForm.get('messageSMS').value + '\n' +
      'Join: ' + this.host + "/" + id;



  }

  sendInvitationForUsers(notificationType: string) {
    if (notificationType === "email") {
      this.invitationEmailTemplate()
    } else if (notificationType === "sms") {
      this.invitationSmsTemplate()
    }
  }

  viewSubscriberProfile(userModel: any) {
    if (this.isAdmin && this.createdFrom !== "'event'") {
      this.router.navigate(['/admin/client-detail/', userModel.uid]);
    } else if (this.currentUser.role === 'Employee') {
      this.router.navigate(['/employee/client-detail/', userModel.uid])
    }
  }

  SMSselected(type) {
    // based on the selection. set the selection type
    if (type === "sms") {
      this.notificationType = 'sms'

    }
    else if (type === "email") {
      this.notificationType = 'email'

    }
    else {
      this.notificationType = 'push'
    }

    // 
    this.isSMS = !this.isSMS;
  }
  openContactUser() {
    this.contactUserModal = true;
  }
  closeContactUser() {
    this.contactUserModal = false;
  }

  openContactsForm() {
    if (this.currentUser?.role === "Employee") {
      this.router.navigate(['/employee/users-list/import-contact']);

    }
    else {
      this.router.navigate(['/admin/users-list/import-contact']);
    }

  }

  openClientRegistrationForm() {
    this.router.navigate(['/admin/create-client-info']);
  }

  closeContactsForm() {
    this.showFileUploadModal = false;
  }

  async selectedAllSubscribers() {
    this.selectAllchecked = !this.selectAllchecked;

    if (this.selectAllchecked) {
      // Fetch user data using async/await
      await this.fetchAllUsersData();
      this.checkUser = true;
      this.selectedImportedSubscribers = this.subscribers;
    } else {
      this.checkUser = false;
      this.selectedSubscribers = [];
      this.selectedImportedSubscribers = [];
    }
  }
  // iterate over the selected users and fetch associated registration tokens
  fetchRegistrationTokens(type, index) {

    if (type == 'multiple') {

      for (let i = 0; i < this.allUsers?.length; i++) {
        let user = this.selectedSubscribers[i];

        // fetch fcm topics so that we c, this.an asssociate them with the subscribers  
        this.fcmService.getTokenByUserId(user?.uid).valueChanges().pipe().subscribe(tokens => {
          if (tokens && tokens.length > 0) {

            this.selectedSubscribers[i].token = tokens;

          }
        });

      }

    }
    else {

      // fetch fcm topics so that we c, this.an asssociate them with the subscribers  
      this.fcmService.getTokenByUserId(this.selectedSubscribers[index]?.uid).valueChanges().pipe().subscribe(tokens => {

        if (tokens && tokens.length > 0) {

          this.selectedSubscribers[index].token = tokens;

        }
      });
    }

  }
  //This is for the selected subscribers to send an email or SMS using the contact users button
  selectedUser(item) {

    const isSelected = this.selectedSubscribers.includes(item);
    const isSelectable = item.contact.includes('Email') || item.contact.includes('SMS') || item.contact.includes('InApp');

    if (!isSelected && isSelectable) {
      this.selectedSubscribers.push(item);
      this.selectAllchecked = this.selectedSubscribers.length === this.allUsers.length;

      // fetch tokens
      this.fetchRegistrationTokens('single', this.selectedSubscribers.length - 1)
    } else {

      let user = this.selectedSubscribers.indexOf(item);
      this.selectedSubscribers.splice(user, 1);
      this.selectAllchecked = false;

      this.selectAllchecked = this.selectedSubscribers.length === this.allUsers.length;

    }
  }
  async sendSubscribers(subject, body, message) {


    if (this.notificationType == 'email') {
      const emailModel = new CustomAdminEmailModel();
      emailModel.subject = subject;
      this.message = subject;
      emailModel.isNationApp = this.isNationApp;

      this.storeEmail = [];

      for (let i = 0; i < this.selectedSubscribers.length; i++) {

        if (this.selectedSubscribers[i].contact.includes('Email')) {
          // this.storeEmail.push(this.selectedSubscribers[i].Email);

          if (!(this.selectedSubscribers[i].contact.includes('Email'))) {
            continue;
          }
          this.storeEmail[0] = this.selectedSubscribers[i].Email;
          emailModel.emailList = this.storeEmail;

          emailModel.body = this.selectedSubscribers[i].GivenName + ", <br/>";

          emailModel.body += body;
          emailModel.body += '<br/> <br/> <br/> <span style="color:blue; margin-top: 10px;"> </span> ' + this.currentUser.GivenName + " " + this.currentUser.LastName + ' <br/> ';
          if (this.currentUser.jobTitle) {
            emailModel.body += '<span style="color:blue;"> </span> ' + this.currentUser.jobTitle + ' <br/> ';
          }
          emailModel.body += '<span style="color:blue;"> </span> ' + this.currentUser.Email + ' <br/> ';
          emailModel.body += '<span style="color:blue;"> </span> ' + this.currentUser.cellPhone ? this.currentUser.cellPhone : ' <br/> ';

          if (environment.firstNation.name !== "nationalchief") {
            emailModel.body += `<p> To stop receiving Email please login to <a href="${this.domainName}">${this.domainName}</a></p>`
            emailModel.body += `<p> Go to your preferences and disable Email, SMS or Both.</p>`

          }

          // custom send email used
          emailModel.host = "smtp.gmail.com",
            emailModel.adminEmail = "mkproject.app@gmail.com",
            emailModel.appPassword = "ytklyidomvqfmtky",
            this.emailService.sendEmail(emailModel).subscribe((res) => {
              if (res) {
                if (this.selectedSubscribers.length != 1) {
                  this.adminEmailCounter += this.selectedSubscribers.length
                  this.adminCounter.emailTotalCounter = this.adminEmailCounter;
                  this.counter.updateCounter(this.adminCounter);

                } else {

                  this.adminEmailCounter++;
                  this.adminCounter.emailTotalCounter = this.adminEmailCounter;
                  this.counter.updateCounter(this.adminCounter);
                }
                this.reset();
              }
              else {
                this.reset();

              }
            })

            // custom send email used
            // this.emailService.sendCustomEmail(emailModel).subscribe((res) => {
            //   if (res) {
            //     if (this.selectedSubscribers.length != 1) {
            //       this.adminEmailCounter += this.selectedSubscribers.length
            //       this.adminCounter.emailTotalCounter = this.adminEmailCounter;
            //       this.counter.updateCounter(this.adminCounter);

            //     } else {

            //       this.adminEmailCounter++;
            //       this.adminCounter.emailTotalCounter = this.adminEmailCounter;
            //       this.counter.updateCounter(this.adminCounter);
            //     }
            //     this.reset();
            //   }
            //   else {
            //     this.reset();

            //   }
            // })
        }
      }

      this.closeContactUser();


    }
    else if (this.notificationType == 'push') {
      let storePushNotification = [];

      const notification = new NotificationModel();
      notification.title = subject;
      notification.body = this.truncateHTML(body, "200");

      for (let i = 0; i < this.selectedSubscribers.length; i++) {

        if (this.selectedSubscribers[i].contact.includes("InApp")) {
          storePushNotification.push(this.selectedSubscribers[i].uid);
        }
      }

      this.closeContactUser();

      if (storePushNotification?.length > 0) {

        await this.fcmPushService.sendNotificationToUsers(notification, storePushNotification);
        this.toast({ html: 'The Push Notfication is successfully sent', classes: 'green', type: "success" });
      }
      else {
        this.toast({ html: 'The selected users do not want to recieve in app notification', classes: 'green', type: "success" });
      }
      this.reset();

    }
    else if (this.notificationType == 'sms' && !this.disable) { //changed if to else if if this doesnt work change it to if
      const smsModel = new SMSModel();

      for (let i = 0; i < this.selectedSubscribers.length; i++) {
        if (this.selectedSubscribers[i].cellPhone === "" || this.selectedSubscribers[i].cellPhone == null || !(this.selectedSubscribers[i].contact.includes('SMS'))) {
          // this.disable = true;
          continue;
        }
        smsModel.body = this.selectedSubscribers[i].GivenName + ", \n";
        smsModel.body += message;
        if (environment.firstNation.name !== "nationalchief") {
          smsModel.body += `\nTo stop receiving SMS please login to ${this.domainName} \n`
          smsModel.body += `Go to your preferences and disable Email, SMS or Both. \n`
        }

        this.storePhone[0] = this.selectedSubscribers[i].cellPhone;
        smsModel.phoneNumberList = this.storePhone;
        // smsModel.phoneNumberList.push(this.selectedSubscribers[i].cellPhone);
        smsModel.from = this.availablePhone;

        this.sMSService.sendSMS(smsModel).subscribe((res) => {
          if (res) {
            this.toast({ html: 'The SMS is successfully sent', classes: 'green', type: "success" });
            if (this.selectedSubscribers.length != 1) {
              this.adminSMSCounter += this.selectedSubscribers.length
              this.adminCounter.SMSTotalCounter = this.adminSMSCounter;
              this.counter.updateCounter(this.adminCounter);
              this.closeContactUser();

            } else {

              this.adminSMSCounter++;
              this.adminCounter.SMSTotalCounter = this.adminSMSCounter;
              this.counter.updateCounter(this.adminCounter);
              this.closeContactUser();

            }
            // this.reset();
          }
          else {
            this.reset();
            this.toast({ html: 'Error sending SMS', classes: 'red', type: "error" });
          }
        });
      }

    }
  }

  // send invitation
  async sendInvitation(notificationType) {
    if (notificationType == 'email') {
      // iterate over the selected subscribers
      for (let i = 0; i < this.selectedSubscribers.length; i++) {

        const emailModel = new CustomAdminEmailModel();
        emailModel.subject = 'Invitation';
        emailModel.isNationApp = this.isNationApp;

        // emailModel.body = this.emailHtmlBody;
        emailModel.body = this.selectedSubscribers[i]?.GivenName + ", <br/>";

        emailModel.body = this.selectedSubscribers[i]?.GivenName + ", <br/>";

        emailModel.body += `<p> Please register on -  <a href="${this.host + "/" + this.selectedSubscribers[i]?.uid}">${this.host + "/" + this.selectedSubscribers[i]?.uid}</a></p>`

        // emailModel.body += body;
        emailModel.body += '<br/> <br/> <br/> <span style="color:blue; margin-top: 10px;"> </span> ' + this.currentUser?.GivenName + " " + this.currentUser?.LastName + ' <br/> ';

        emailModel.body += '<span style="color:blue;"> </span> ' + this.currentUser?.Email + ' <br/> ';
        emailModel.body += '<span style="color:blue;"> </span> ' + this.currentUser?.cellPhone ? this.currentUser?.cellPhone : ' <br/> ';

        this.message = "Invitation";

        emailModel.emailList = [this.selectedSubscribers[i].Email];

        // custom send email used
        emailModel.host = "smtp.gmail.com",
          emailModel.adminEmail = "mkproject.app@gmail.com",
          emailModel.appPassword = "ytklyidomvqfmtky",

          this.closeContactUser();

        // replaced with custom email
        this.emailService.sendEmail(emailModel).subscribe((res) => {
          if (res) {

            this.adminEmailCounter++;
            this.adminCounter.emailTotalCounter = this.adminEmailCounter;
            this.counter.updateCounter(this.adminCounter);

            // this.reset();
          }
          else {
            // this.reset();

          }
        })
        // Client management > client-list
        // this.emailService.sendCustomEmail(emailModel).subscribe((res) => {
        //   if (res) {

        //     this.adminEmailCounter++;
        //     this.adminCounter.emailTotalCounter = this.adminEmailCounter;
        //     this.counter.updateCounter(this.adminCounter);

        //     // this.reset();
        //   }
        //   else {
        //     // this.reset();

        //   }
        // })
      }

    }
    else if (notificationType == 'sms' && !this.disable) { //changed if to else if if this doesnt work change it to if
      for (let i = 0; i < this.selectedSubscribers.length; i++) {
        if (this.selectedSubscribers[i].cellPhone === "" || this.selectedSubscribers[i].cellPhone == null) {
          // this.disable = true;
          continue;
        }

        const smsModel = new SMSModel();
        // this.invitationSmsTemplate(this.selectedSubscribers[i]?.GivenName, this.selectedSubscribers[i]?.id)
        smsModel.body = `Dear ${this.selectedSubscribers?.[i]?.GivenName} \n` +
          'Please register on : ' + this.host + "/" + this.selectedSubscribers[i]?.uid;
        // smsModel.name = this.selectedSubscribers[i].GivenName;
        // smsModel.cell = this.selectedSubscribers[i].cellPhone;
        smsModel.phoneNumberList.push(this.selectedSubscribers[i].cellPhone);
        smsModel.from = this.availablePhone;

        this.sMSService.sendSMS(smsModel).subscribe((res) => {
          if (res) {
            this.toast({ html: 'The SMS is successfully sent', classes: 'green', type: "success" });
            if (this.selectedSubscribers.length != 1) {
              this.adminSMSCounter += 1
              this.adminCounter.SMSTotalCounter = this.adminSMSCounter;
              this.counter.updateCounter(this.adminCounter);
              this.closeContactUser();

            } else {

              this.adminSMSCounter++;
              this.adminCounter.SMSTotalCounter = this.adminSMSCounter;
              this.counter.updateCounter(this.adminCounter);
              this.closeContactUser();

            }
            // this.reset();
          }
          else {
            this.reset();
            this.toast({ html: 'Error sending SMS', classes: 'red', type: "error" });

          }
        });

      }
    }

    this.sendingInvitation = false
  }


  reset() {
    this.contactForm.patchValue({
      subject: '',
      emailbody: this.greetings + ' <br /> <br />  <br /> <br />' + this.signOff,
      messageSMS: this.setUp.messageSMS,
    });

  }
  // truncate html
  truncateHTML(text: string, limit: string): string {
    return this.helperervice.truncateHTML(text, limit);
  }
  //this is to filter out the subscribers with Email / SMS / Both / Verified subscribers
  selectPreference(event) {
    this.selectedPreference = event;
    this.fetchUsersData();
  }
  clearFilters() {
    this.allUsers = this.subscribers;
  }
  toast(obj) {
    this.helperervice.toast(obj);
  }

  // contacts related section

  // upload contacts list file
  uploadFile() {
  }

  csvImport($event: any) {
    const files = $event.target.files;
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;

        if (sheets.length) {
          let rows: any[] = utils.sheet_to_json(wb.Sheets[sheets[0]]);

          let validRecords = [];

          // iterate over the rows 
          for (let i = 0; i < rows?.length; i++) {
            let record = {
              uid: this.userService.createID(),
              Email: '',
              cellPhone: '',
              GivenName: '',
              LastName: ''
            };

            // iterate over the fields
            for (let j = 0; j < this.existingFormFields?.headers?.length; j++) {
              let currentField = this.existingFormFields?.headers[j];
              let currentRow = rows[i];



              if (currentField?.key) {
                if (currentField?.type == "phone") {
                  let phoneNumber = currentRow[currentField?.value];

                  let cleanedPhone = phoneNumber ? (phoneNumber).toString().replace(/[^\w\s]/gi, '').replace(/\s+/g, '') : '';

                  // check  if the phone num contains all nums
                  let isnum = /^\d+$/.test(cleanedPhone)

                  if (cleanedPhone?.length && isnum) {
                    record[currentField?.key] = this.maskPhoneNumber(cleanedPhone)
                  }
                  else {
                    record[currentField?.key] = null;

                  }
                }
                else {
                  record[currentField?.key] = currentRow[currentField?.value];

                }
              }
            }

            if (record) {
              record['role'] = 'User'
              record['contact'] = record?.Email && record?.cellPhone ? ['Email', 'SMS'] : record?.Email && !record?.cellPhone ? ['Email'] : !record?.Email && record?.cellPhone ? ['SMS'] : []
              record['importedUser'] = true;
              validRecords.push(record);

            }
          }


          // insert users 
          this.userService.insertUsers(validRecords).then(async (response) => {
            this.hideLoader();
            this.successMessage("Users list imported succesfully!")
            this.closeContactsForm()


            // this.router.navigate(['/admin/contact-list/']);

          }).catch((err) => {
            this.errorMessage("Something went wrong when saving contacts!")
            this.hideLoader();
          });




        }
      };

      reader.readAsArrayBuffer(file);
    }
  }

  // mask phone number
  maskPhoneNumber(phone: any) {
    const format = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
    const numericString = phone.replace(/\D/g, '');

    let maskedString = '';
    let numericIndex = 0;

    format.forEach(char => {
      if (typeof char === 'string') {
        maskedString += char;
      } else {
        maskedString += numericString.charAt(numericIndex);
        numericIndex++;
      }
    });

    return maskedString;
  }

  fetchContactHeaders() {
    // this.showLoader();

    this.contactService.getContactHeaderNew().subscribe((querySnapshot) => {
      let contacts = [];

      querySnapshot.forEach((doc) => {
        contacts.push({ id: doc.id, ...doc.data() });
      });

      this.existingFormFields = contacts?.[0];

    }, (err) => {
      // this.hideLoader();

    });
  }

  // delete contacts list
  deleteContacts() {
    // show loader
    this.showLoader();

    this.contactService.deleteAllImportedUsers().then(() => {

      // reset selected subscrbers list
      this.selectedSubscribers = [];
      this.selectedImportedSubscribers = [];
      this.checkUser = false;
      this.selectedPreference = "All";

      this.fetchUsersData();

      this.deleteContactOpenModal = false;

      // hide loader
      this.hideLoader();
      // window.location.reload()
      // this.router.navigate(['/admin/homepage']);

    }).catch((err) => {
      this.deleteContactOpenModal = false;
      this.hideLoader();
    })
  }

  // toast message handler
  successMessage(message) {
    this.toastr.success(message, "Success");
  }

  errorMessage(message) {
    this.toastr.error(message, "Error");
  }

  // show Loader
  showLoader() {
    this.loaderService.show()
  }

  // hide loader
  hideLoader() {
    this.loaderService.hide();
  }

  toggleInvitation() {
    this.sendingInvitation = !this.sendingInvitation
  }

  // on change text
  onChangeText($event) {
  }

  get subject() { return this.contactForm.get('subject'); }
  get messageSMS() { return this.contactForm.get('messageSMS'); }
  get emailbody() { return this.contactForm.get('emailbody'); }

  /*
    proceedToNext() {
      this.enablePagination()
    }
  
    // enable pagination
    enablePagination() {
      if (this.search_text) {
        this.showFilterModal = false
      }
      else {
        this.showPaginationModal = false;
      }
  
      let field = {};
      if (this.search_text) {
        field = {
          queryScope: "COLLECTION",
          fields: [
            { fieldPath: "role", order: "ASCENDING" },
            { fieldPath: "GivenName", order: "ASCENDING" },
            { fieldPath: "__name__", order: "ASCENDING" }
          ]
        };
  
      }
      else {
  
        if(this.paginationDirection === 'prev')
        {
          field = {
            queryScope: "COLLECTION",
            fields: [
              { fieldPath: "role", order: "ASCENDING" },
              { fieldPath: "uid", order: "DESCENDING" },
              { fieldPath: "__name__", order: "DESCENDING" }
            ]
          };
  
        }
        else{
          field = {
            queryScope: "COLLECTION",
            fields: [
              { fieldPath: "role", order: "ASCENDING" },
              { fieldPath: "uid", order: "ASCENDING" },
              { fieldPath: "__name__", order: "ASCENDING" }
            ]
          };
  
        }
       
  
      }
  
  
  
      // this.compositeIndexService.createCompositeIndex(field).subscribe(
      //   (response) => {
      //     this.compositeIndexId = response?.metadata?.index
  
      //     this.getCompositeIndex(this.compositeIndexId)
      //   },
      //   (error) => {
      //   }
      // );
  
    }
  
    getCompositeIndex(compositeIndexId) {
  
      let indexValue = compositeIndexId.split("/").pop();
  
      this.compositeIndexService.getCompositeIndex(indexValue).subscribe(
        (response) => {
  
          this.compositeIndexStatus = response.status;
  
          if (response.status !== "READY") {
            this.showConfirmationModal = true;
          }
        },
        (error) => {
        }
      );
  
    }
  
    // enable filter
    enableFilter() {
      this.showFilterModal = false;
  
      let field = {
        queryScope: "COLLECTION",
        fields: [
          { fieldPath: "role", order: "ASCENDING" },
          { fieldPath: "GivenName", order: "ASCENDING" },
          { fieldPath: "__name__", order: "ASCENDING" }
        ]
      };
  
      // this.compositeIndexService.createCompositeIndex(field).subscribe(
      //   (response) => {
      //     this.compositeIndexId = response?.metadata?.index
  
      //     // this.getCompositeIndex()
      //   },
      //   (error) => {
      //   }
      // );
  
    }
  */
  // function which is going to be called when user hit enter
  filterUsersList() {
    this.usersListRef = []
    this.currentPage = 0;
    this.fetchUsersData();
  }

  invite() {
    this.selectedSubs.emit(this.selectedSubscribers);
  }

  cancelClicked() {
    this.cancelBtnClicked.emit();
  }
}