<div class="flex flex-col md:w-9/12 my-2 bg-white h-max p-4 md:p-12 rounded-lg space-y-4 md:translate-x-1/4" *ngIf="currentUser">
   <p class=" font-bold text-xl text-gray-500 ">{{hasSchedule ? 'Update' : 'Add'}} employee Schedule </p>
   <p class="text-xs text-darkGray">Please put in your Schedule here.</p>

     <form [formGroup]="scheduleForm" (ngSubmit)="onSubmit(scheduleForm.value)">
         <p class="font-bold text-sm">Select work days you will be active on: </p>

         <div *ngIf="schedules">
             <div  formArrayName="schedules" *ngFor="let schedule of schedules.controls; let i=index">
                 <div [formGroupName]="i" class="flex flex-row justify-between ">
                     <div class="md:w-2/12 w-5/12 flex flex-col">
                         <input class="border-2 border-customTeal  py-1 my-2" disabled formControlName="day" />
                     </div>
                     <div class="md:w-8/12 w-7/12 flex md:flex-row flex-col md:justify-between justify-center space-y-2 md:space-y-0">

                       <div class="md:w-4/12  ml-3 md:ml-0">
                         <input type="time" step="300" class="input input-bordered" value="09:00:00"
                         formControlName="startTime" />

                       </div>

                       <div class="md:w-4/12  ml-3 md:ml-0">
                         <input type="time" step="300" class="input input-bordered" value="05:00:00"
                             formControlName="endTime" />
                       </div>

                       <p class="md:w-3/12 ml-3 md:ml-0 mb-5 md:mb-0">
                           <label>
                               <input type="checkbox" class="filled-in" formControlName="unAvailable"
                                   (click)="updateDaySchedule(i)" />
                               <span>Unavailable</span>
                           </label>
                       </p>
                     </div>
                 </div>


             </div>
         </div>
         <div class="flex flex-row-reverse mr-3 mt-5">
             <br />
             <button type="submit" [disabled]="!scheduleForm.valid" class="btn-custom-solid">
                  Save Schedule
             </button>

             <button type="button" class="btn-custom-bordered mr-2" (click)="backClicked()"
                 [ngStyle]="{'background-color': themeColor}">
                  Back to Previous page
             </button>


         </div>

     </form>


 </div>



 <!-- toast -->
 <app-alert class="fixed top-0 flex justify-end w-full z-50" [toastType]="toastType" [toastMessage]="toastMessage" [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>
