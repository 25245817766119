<div>

    <div *ngIf="step1">

        <div class="flex mx-48">
            <h5><strong> Create Form </strong></h5>
        </div>


        <form [formGroup]="clientForm" (ngSubmit)="submitSurveyInfo(clientForm.value)">

            <div class="flex flex-col md:w-4/6 m-2 bg-white h-max p-4 md:p-12 rounded-lg space-y-4 md:translate-x-1/4">
                <div class="row">

                    <div class="flex space-y-2 md:w-full  flex-col mb-2">
                        <label class="font-bold">Form Name</label>
                        <input formControlName="formName"
                            class=" border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline bg-gray-100"
                            id="formName" type="text" required autocomplete="off" />
                    </div>
                    <div *ngIf="(formName.dirty || formName.touched) && formName.invalid" class="mb-2">
                        <small *ngIf="formName.errors.required" class="text-red-500 "> Form Name is required.
                        </small>
                    </div>

                    <div class="flex space-y-2 md:w-full  flex-col mb-2">
                        <label class="font-bold ">Form Description: </label>
                        <textarea
                            class="h-36 border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline bg-gray-100"
                            id="Email " type="text " placeholder="" formControlName="description"></textarea>
                    </div>

                    <div class="">
                        <p class="font-bold mb-3 mt-4">Form Theme color</p>
                        <a class=" col s12 btn bg-gray-100 border-none hover:bg-gray-200 text-black grey lighten-3 black-text"
                            style="margin-bottom: 15px;">
                            <label>Choose Color </label> <input formControlName="themeColor" type="color"
                                class="themeColorSelector ml-5 h-10 w-10 float-right">
                        </a>
                    </div>

                </div>
            </div>

            <div class="flex justify-end md:w-4/6 md:translate-x-1/4">
                <button
                    class="flex space-x-2  px-4 md:px-6 py-2 bg-customTeal text-white rounded-lg w-max h-min text-sm md:text-base font-bold hover:bg-darkBlue focus:outline-none  "
                    type="submit" [disabled]="!clientForm.valid">
                    Next
                </button>


            </div>

        </form>
    </div>

    <div *ngIf="step2"
        class="flex flex-col md:w-4/6 m-2 bg-white h-max p-4 md:p-12 rounded-lg space-y-4 md:translate-x-1/4">
        <form [formGroup]="questionForm" (ngSubmit)="submitQuestions(questionForm.value)">

            <div formArrayName="questionItems"
                *ngFor="let item of questionForm.get('questionItems').controls; let i = index;">
                <div [formGroupName]="i">


                    <div class="card-content row">
                        <div class="flex justify-between">
                            <p></p>
                            <div class=" px-4 py-2 mt-2 bg-customTeal rounded-full w-10 h-10 "
                                *ngIf="i > 0">
                                <a class="text-center" (click)="removeFile(i)"><i
                                        class="material-icons text-white text-center justify-center items-center">clear</i></a>
                            </div>
                        </div>
                        <!-- <a class="btn-floating btn-small halfway waves-light red right" *ngIf="i > 0"
                                    (click)="removeFile(i)"><i class="material-icons">clear</i></a> -->

                        <div class="flex space-y-2 md:w-full  flex-col mt-2">
                            <label class="font-bold text-sm">Question {{i+1}}: <span class="text-red-500">*</span>
                            </label>
                            <input
                                class=" border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline "
                                id="question " type="text " placeholder="Question" formControlName="question">
                            <div *ngIf="(item.controls.question.dirty ||
                            item.controls.question.touched) &&
                            item.controls.question.invalid">
                                <small *ngIf="item.controls.question.errors.required" class="text-red-500">
                                    The
                                    Question is required.
                                </small>
                            </div>
                        </div>
                        <br />


                        <div class="flex space-y-2 md:w-full flex-col mb-4">
                            <label class="font-bold text-sm">Question Type: <span class="text-red-500">*</span>
                            </label>
                            <select formControlName="answerType" required
                                class="validate py-3 px-4 select select-bordered w-full bg-veryLightGray"
                                (change)="answerTypeChange($event.target.value, i)">
                                <option value="" disabled selected>Please Select Answer Type</option>
                                <option *ngFor="let answer of answerTypes" [value]="answer.type" title="Tooltip">
                                    {{answer.type}}
                                    <span class="text-green-400"> ( {{answer.helpText}} )</span>
                                </option>
                            </select>

                            <div *ngIf="(item.controls.answerType.dirty ||
                                    item.controls.answerType.touched) &&
                                    item.controls.answerType.invalid">
                                <small *ngIf="item.controls.answerType.errors.required" class="text-red-500">
                                    The
                                    Answer Type is required.
                                </small>
                            </div>
                        </div>



                        <div class="flex space-x-8 my-8" *ngIf="item.controls.answerType.value === 'Scale'"
                            formGroupName="scales">
                            <div>
                                <label for="start" class="font-bold">Start At</label>
                                <select formControlName="start"
                                    class="validate py-1 px-4 select select-bordered w-full bg-veryLightGray">
                                    <option value="0" class="px-2 py-4 text-center hover:bg-customTeal">0
                                    </option>
                                    <option value="1" class="px-2 py-4 text-center hover:bg-customTeal">1
                                    </option>
                                </select>
                            </div>

                            <div>
                                <label for="startLabel" class="font-bold">Label</label>
                                <input id="startLabel" type="text" formControlName='startLabel'
                                    class="w-full border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline" />
                            </div>

                            <div>
                                <label for="end" class="font-bold">End At</label>
                                <select formControlName="end"
                                    class="py-1 px-4 select select-bordered w-full bg-veryLightGray">
                                    <option *ngFor="let s of scales" [value]="s">{{s}}</option>
                                </select>
                            </div>

                            <div>
                                <label for="endLabel" class="font-bold">Label</label>
                                <input id="endLabel" type="text" formControlName='endLabel'
                                    class="w-full border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline" />
                            </div>
                        </div>


                        <div *ngIf="item.controls.answerType.value === 'Multiple Choice'">

                            <div class="row input-field col s12 m5 right">
                                <label class="flex justify-end">
                                    <input class="flex justify-end checkbox checked:bg-customTeal" type="checkbox"
                                        formControlName="allowMultipleAnswers" />
                                    <span>Allow Multiple Answers</span>
                                </label>
                            </div>
                            <br />
                            <div class="row input-field col s12" formArrayName="answers"
                                *ngFor="let ans of getAnswers(i).controls; let j = index;">
                                <div [formGroupName]="j">

                                    <div class="row input-field col s11">
                                        <span class="font-bold text-sm">Choice {{j+1}}: </span>
                                        <input id="answer" type="text" formControlName="answer" required
                                            class="validate border mb-2 rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline "
                                            autocomplete="off" style="text-transform: capitalize;"
                                            placeholder="Answer" />
                                        <a *ngIf="j > 1" class="btn-flat waves-effect waves-light col s1 right"><i
                                                class="material-icons text-red-500"
                                                (click)="removeAnswer(i, j)">delete</i></a>
                                        <div
                                            *ngIf="(ans.controls.answer.dirty || ans.controls.answer.touched) && ans.controls.answer.invalid">
                                            <small *ngIf="ans.controls.answer.errors.required" class="text-red-500">
                                                Answer {{j+1}} is
                                                required.</small>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row col s12">
                                <button type="button" class="mb-4 bg-customTeal px-4 py-2 rounded-md"
                                    (click)="onAddAnswer(i)"
                                    [disabled]="!questionForm.get('questionItems').controls[i].get('answers').valid"
                                    style="cursor: pointer;">
                                    <i class="material-icons text-white float-left">add_circle</i><span
                                        class="text-white">Add Option</span>
                                </button>
                            </div>
                        </div>

                    </div>

                </div>
            </div>


            <div>
                <div class="flex justify-end">
                    <button type="button" (click)="addQuestion()" [disabled]="!questionForm.valid"
                        class="flex space-x-2 border-2 border-customTeal px-4 md:px-6 py-2 text-customTeal rounded-lg w-max h-min text-sm md:text-base font-bold focus:outline-none  ">
                        <i class="material-icons text-customTeal">add_circle</i><span>Add Questions</span>
                    </button>
                </div>


                <div class="pt-6 space-y-2 flex justify-between">
                    <div class="">
                    </div>
                    <div class="">
                        <button type="submit" [disabled]="!questionForm.valid"
                            class="flex space-x-2  px-4 md:px-6 py-2 bg-customTeal text-white rounded-lg w-max h-min text-sm md:text-base font-bold hover:bg-darkBlue focus:outline-none  ">
                            <span>Save Questions</span>
                        </button>
                    </div>
                </div>
            </div>


            <!-- <div class="row col s12">
                <div class="section"></div>
      
                <button type="button" [disabled]="!questionForm.valid" style="margin-bottom: 15px;" class="col s12 btn"
                  (click)="addQuestion()">
                  <i class="material-icons right">add</i> Add New Question
                </button>
      
                <button type="submit" [disabled]="!questionForm.valid" style="margin-bottom: 15px;"
                  class="col s12 btn waves-effect green">
                  <span *ngIf="createPoll"><i class="material-icons right">cloud</i> Save Poll</span>
                  <span *ngIf="!createPoll"><i class="material-icons right">cloud</i> Save Survey</span>
                </button>
      
                <a [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backClicked()"><i
                    class="material-icons left">house</i>
                  Back To Previous Page </a>
      
              </div> -->

        </form>
    </div>

    <!-- toast -->
<app-alert class="fixed top-0 flex justify-end w-full" [toastType]="toastType" [toastMessage]="toastMessage"
[ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>
</div>