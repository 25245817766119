
<div class="mx-auto md:max-w-4xl w-full bg-white py-6 px-6 space-y-4 flex flex-col items-center">
    <img src="../../../../../assets/img/try again.svg" class="w-40" alt="">
    <div class="flex flex-col justify-center rounded-md space-y-2 bg-red-100 border border-solid border-red-600 px-10 py-4">
        <h1 class="text-center text-red-800 font-bold">Tansaction Could Not Be Completed Please try again or contact us</h1>
        <p class="text-base text-center text-red-800">
            Something went wrong with your transaction. Please check your payment details and try again. If the issue persists, wait a moment and retry. Need help? <a class="text-blue-700 underline" href="mailto:info@goingmobile.app">Contact</a> our support team. Thanks for your patience!
        </p>
    </div>

    <!-- TW Elements is free under AGPL, with commercial license required for specific uses. See more details: https://tw-elements.com/license/ and contact us for queries at tailwind@mdbootstrap.com --> 
    <button
        [routerLink]="['/user/homepage']"  
        type="button"
        class="inline-block rounded bg-brandColor px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-brandColor-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-brandColor-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-brandColor-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
        Back to Homepage
    </button>
</div>  

