import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EmailService } from 'src/app/services/api/email.service';
import { MediaService } from 'src/app/services/api/media.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { ClientFormService } from 'src/app/services/firebase/client-form.service';
import { InviteService } from 'src/app/services/firebase/invite.service';
import { UserService } from 'src/app/services/firebase/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-invite-subscriber',
  templateUrl: './invite-subscriber.component.html',
  styleUrls: ['./invite-subscriber.component.css']
})
export class InviteSubscriberComponent implements OnInit {

  //currentUser
  currentUser: any;

  //toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;

  //email to be sent for the invite
  emailInvite: any;
  userType = 'User';

  //invite
  inviteGlobal: any;
  invitationGlobal: any;
  host = window.location.hostname !== 'localhost' ? 'https://' + window.location.hostname + '/register-from-invite/' : 'http://localhost:4200/register-from-invite/';

  //test
  users: any;
  invites: any;
  count: number = 0;

  //finish Modal
  finishModal: boolean = false;
  userExists: boolean = false;


  //image
  mediaType: any;
  binDoc = undefined;
  base64Image = undefined;
  mobileBase64Image: any;
  isMobile = false;
  model: any;
  imgURL: any;
  imgErrorMsg: any;
  pdfErrorMsg: any;
  profileImgLink: any;

  //Showing Employee
  showEmployee: any;

  //name
  names: String = "";
  value: String = "";
  public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')',
    ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public extensionMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ')',
    ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  subscriberForm: UntypedFormGroup;

  surveyForm: UntypedFormGroup;
  questionForm: UntypedFormGroup;
  questionArray: UntypedFormArray;
  answersArray: UntypedFormArray;
  skillsForm: UntypedFormGroup;
  formExists: boolean;
  questionNumber = 1;
  requiredChecked: boolean = false;

  //new clients
  answerTypes: any = [
    { 'type': 'Open Ended', 'helpText': 'Allows participants to answer in open text format' },
    //{ 'type': 'Short Answer', 'helpText': 'Allows participants to answer in open text format' },
    //{ 'type': 'Scale', 'helpText': 'Displays a scale from any range from 0 to 10' }
    { 'type': 'Date', 'helpText': 'Displays a calander for the client to choose from' },
    //{ 'type': 'Long Answer', 'helpText': 'Allows participants to answer in open text box format'},
    { 'type': 'Number', 'helpText': 'Allows participants to answer in open Number format'},
    { 'type': 'Multiple Choice', 'helpText': 'Displays a list of answers the client can choose from' },
  ];
  isNationApp = environment.firstNation.Origin == "FNLeaders" ? true : false;

  constructor(private fb: UntypedFormBuilder,
    private authService: AuthService,
    private userService: UserService,
    private inviteService: InviteService,
    private emailService: EmailService,
    private mediaService: MediaService,
    private router: Router,
    private clientFormService: ClientFormService,
    private firestore: AngularFirestore) { }


  form: UntypedFormGroup;
  get skills(): UntypedFormArray { return this.skillsForm.get("skills") as UntypedFormArray }

  ngOnInit(): void {
    this.currentUser = this.authService.getGlobalUser();

    this.skillsForm = this.fb.group({
      skills: this.fb.array([]) ,
    }); 

  }

  getAnswers(index) {
    const questions = this.skillsForm.get('skills') as UntypedFormArray;
    return questions.controls[index].get('answers') as UntypedFormArray;
  }

  addAnswers() {
    return this.fb.group({
      answer: ['', [Validators.required]]
    });
  }

  onAddAnswer(index) {
    const answers = this.getAnswers(index);
    answers.push(this.addAnswers());
  }

  toggleRequired(event){
    this.requiredChecked = !this.requiredChecked;
  }

  addQuestion() {
    this.questionNumber += 1;
    this.questionArray = this.skillsForm.get('skills') as UntypedFormArray;
    this.skills.push(this.createQuestion());
  }

  createQuestion() {
    let questionFormGroup = this.fb.group({
      question: ['', [Validators.required]],
      answerType: ['', [Validators.required]],
      required:[false],
      allowMultipleAnswers: [false],
      answers: this.fb.array([]),
    });
    return questionFormGroup
  }


  removeSkill(i: number) {
    this.skills.removeAt(i);
  }

  removeAnswer(question_index, answer_index) {
    const answers = this.getAnswers(question_index);
    answers.removeAt(answer_index);
  }

  removeFile(index: any) {
    this.questionArray = this.skillsForm.get('skills') as UntypedFormArray;
    this.questionArray.removeAt(index);
  }

  onSubmit() {
    this.toast({ html: 'Client Form sucessfully added', classes: 'green', type: 'success' });
  }

  answerTypeChange(e: any, index: any) {
    this.resetControls(index);
    const answers = this.getAnswers(index);
    if (e === 'Multiple Choice') {
      if (answers.length < 2) {
        answers.push(this.addAnswers());
        answers.push(this.addAnswers());
      }
     } 
     //else if (e === 'Scale') {
    //   this.getScales(index).get('start').patchValue('1');
    //   this.getScales(index).get('end').patchValue('10');
    // }
     else {
    }

    // setTimeout(() => {
    //   $('select').formSelect();
    // }, 25);

  }
  resetControls(index) {
    const answers = this.getAnswers(index);

    while (answers.length !== 0) {
      answers.removeAt(0);
    }
    //const scales = this.getScales(index);
    //answers.reset();
    answers.clearValidators();
    answers.updateValueAndValidity();
    // scales.reset();
    // scales.clearValidators();
    // scales.updateValueAndValidity();
  }


  submitForm(value: any) {
    this.model = {
      id: '',
      // name: value.surveyName,
      // type: value.type,
      numberOfQuestions: 0,
      // startDate: value.startDate,
      // endDate: value.endDate,
      // showResult: value.showResult,
      // showResultTo: value.showResultTo,
      questions: [],
      adminID: this.currentUser.id,
      adminName: this.currentUser.FirstName + " " + this.currentUser.LastName,
      datePosted: new Date(),
    };
  }

  submitQuestions(value: any) {
    let qNumber = 0;
    this.model = {
      id: '',
      // name: value.surveyName,
      // type: value.type,
      numberOfQuestions: 0,
      // startDate: value.startDate,
      // endDate: value.endDate,
      // showResult: value.showResult,
      // showResultTo: value.showResultTo,
      questions: [],
      // adminID: this.currentUser.uid,
      // adminName: this.currentUser.FirstName + " " + this.currentUser.LastName,
      datePosted: new Date(),
    };

    value.skills.forEach(element => {
      qNumber += 1;
      const question = {
        questionNumber: qNumber,
        question: element.question,
        answerType: element.answerType,
        required: element.required,
        allowMultipleAnswers: element.allowMultipleAnswers,
        answers: element.answers,
        //scales: element.scales
      };

      // if (element.answerType === 'Scale') {
      //   for (var i = element.scales.start; i <= element.scales.end; i++) {
      //     element.answers.push({ 'answer': i.toString() });
      //   }
      // }

      this.model.questions.push(question);

    });

    this.model.numberOfQuestions = this.model.questions.length;
    this.clientFormService.newForm(this.model).then(x => {
      this.toast({ html: 'New Form Successfully Created!', classes: 'green' });
    });
  }

  newForm() {
    //this.newFormClicked = true
    this.router.navigate(['admin/create-form'])
  }



  truncateHTML(text: string, limit: string): string {
    const charlimit = +limit;
    if (!text || text.length <= charlimit) {
      return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ');
    }
    return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ').substring(0, charlimit) + '...';
  }
  openFinishModal() {
    this.finishModal = true;
    setTimeout(() => {
      this.finishModal = false;
      // this.backClicked();
      this.router.navigate(['/admin/search-users']);

    }, 2000);

  }
  closeFinishModal() {
    this.finishModal = false;
    this.router.navigate(['/admin/search-users']);
  }

  toast(obj) {
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green';
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
      // this.backClicked();
    }, 2000);
  }
}
