<div class="p-5  ">
  <div class="flex flex-col   bg-white w-full">
    <!-- <p class="text-base md:text-xl font-bold text-darkGray ">
          Areas of Interest
      </p> -->

      <!-- current area of interest -->
    <div class="space-y-2">
      <p class="text-xs md:text-sm font-bold ">
          Current areas of interest
      </p>
      <div class="flex flex-wrap  " >
        <!-- button -->
        <div *ngFor="let area of areasListModel">
          <button class="border-2 border-customTeal rounded-full py-1 px-4 mx-2 my-2"  type="button">
            <span
            (click)="[selectArea(area), toggleUpdate()]">{{area.name}}</span>
            <span
            (click)="[selectArea(area), openDeleteModal(area)]">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </span>
        </button>
        </div>
      </div>
    </div>

    <!-- Add area of interest  -->
    <div class="space-y-2">
      <br/>
      <p class="text-xs md:text-sm font-bold ">
          Add Area of Interest
      </p>
      <div class="flex space-y-2 md:w-96  flex-col ">
      <form
      [formGroup]="areaOfInterestForm"
      (ngSubmit)="addAreasOfInterest(areaOfInterestForm.value)">
        <input
        class=" w-full border rounded py-3 px-3 text-xs text-gray-700 leading-tight bg-veryLightGray focus:outline-gray-200 focus:shadow-outline "
        id="Area of interest"
        type="text"
        placeholder="Add area of interest"
        formControlName="name"
        required>
        <button type="submit" class="w-full mt-4">
            <div class="flex flex-row justify-end space-x-2">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 fill-customTeal" viewBox="0 0 20 20">
                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd" />
                  </svg>
                <p *ngIf="!selectedModel" class="text-sm font-bold text-customTeal">
                  Add
                  </p>
                  <p *ngIf="selectedModel" class="text-sm font-bold text-customTeal">
                  Update area of interest
                  </p>
            </div>
        </button>
      </form>
      <div *ngIf="calledFromEmptyDashboard=='areasOfInterest'">
        <button type="button" (click)="saveBtnClicked()" class="btn-custom-solid float-right mt-5">Save</button>
      </div>
      </div>
    </div>

  </div>
</div>

<!-- Delete Modal -->
<div id="deleteProductModal"  *ngIf="deleteModalOpen" class="modal" [ngClass]="{'modal-open': deleteModalOpen, 'modal-action': !deleteModalOpen }">
  <div class="modal-box">
    <div class="modal-content">
      <h3 class="font-bold text-lg">Delete Area</h3>
    </div>
    <p class="py-4">Are you sure you want to delete selected Area?</p>
    <div class="modal-action">
      <button type="button" (click)="deleteArea()"
      class = "btn btn-error text-white" style="margin-right: 15px;">
        <i class=" material-icons right white-text">delete</i> Delete
      </button>
      <a class="btn" (click)="closeDeleteModal()"> <i class="material-icons right">close</i>Cancel</a>
    </div>
  </div>
</div>

<app-alert class="fixed top-0 flex justify-end w-full" [toastType]="toastType" [toastMessage]="toastMessage" [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>
