<!-- <div class="flex justify-end flex-row-reverse m-10 ml-10 mt-5 mb-6" *ngIf="isDemoApp">
    <button class="btn-custom-solid mx-5 " (click)="navigateToCreatePage()"><i
        class="material-icons float-left mr-2">inventory_2</i>Add Analytics
      Account</button>
  </div> -->
  
  <div class="text-right  mr-20">
    <button disabled="true" class="rounded-lg p-2"><b>Current Default Account :-
        {{defaultBillingName}} </b></button>
  </div>
  
  
  
  <div class="flex flex-wrap" >
    <div
      class="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 px-4 pt-4 ml-20 m-5"
      *ngFor="let item of listOfBillingAccounts; let i=index 
       
        ">
      <div class="flex flex-col items-center pb-10">
        <h5 class="mb-1 text-xl font-medium text-gray-900 dark:text-white">{{ item.displayName }}</h5>
        <!-- <span class="text-sm text-gray-500 dark:text-gray-400">{{ item.totalNoOfProperties }} linked projects</span>
        <span class="text-sm text-gray-500 dark:text-gray-400">{{ 100 - item.totalNoOfProperties }} project left to be
          linked</span> -->
        <div class="flex mt-4 space-x-3 md:mt-6">
  
  
          <div *ngIf="isDefault(item.name)">
            <button [disabled]="true" (click)="makeItDefault(item.name)"
              class="items-center px-4 py-2 text-md font-medium text-center text-black bg-black-300 rounded-lg ">
              Default
            </button>
  
          </div>

          <div *ngIf="!isDefault(item.name)">


            <button [disabled]="false" (click)="makeItDefault(item.name)" type="button"
              class="inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              data-te-ripple-init data-te-ripple-color="light">
              Make Defult
            </button>
          </div>
  

  
          <button (click)="openListLinkedProjectsModal(item.name)"
            class="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-700">View
            Linked Projects </button>
        </div>
  
      </div>
  
  
    </div>
  
    <div class="mt-3">
  
      <!-- <pagination-controls class="text-right pr-20" (pageChange)="accPage = $event"></pagination-controls> -->
    </div>
  
  
  </div>
  
  <div type="checkbox" *ngIf="listLinkedProjectsModal" class="modal"
    [ngClass]="{'modal-open': listLinkedProjectsModal, 'modal-action': !listLinkedProjectsModal }">
    <div class="modal-box">
      <div class="modal-content">
  
  
        <h3 class="font-bold text-lg text-center mb-1">List of linked projects</h3>
        <hr />
  
        <form [formGroup]="searchForm" (ngSubmit)="search(searchForm.value)">
          <label for="default-search"
            class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
          <div class="relative">
            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
              </svg>
            </div>
            <input formControlName='searchText' type="search" id="default-search"
              class="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-teal-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-teal-500 dark:focus:border-teal-500"
              placeholder="Search app name..." required>
            <button type="submit"
              class="text-white absolute right-2.5 bottom-2.5 bg-teal-700 hover:bg-teal-800 focus:ring-4 focus:outline-none focus:ring-teal-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-teal-600 dark:hover:bg-teal-700 dark:focus:ring-teal-800">Search</button>
          </div>
        </form>
  
        <!-- without filter -->
        <div *ngIf="!filterOn && linkedProjects.length > 0">
          <div class="flow-root" *ngFor="let project of linkedProjects |
        paginate: { itemsPerPage: 10, 
          currentPage: propPage,
          totalItems: linkedProjects.length}; let i=index
          ">
            <br />
  
  
            <div>
              <p class="float-left text-green-600">
                {{ project.projectId }}
              </p>
  
              <p class="float-right text-green-800">
                <button (click)="deactivateProjectModal(project.projectId)"
                  class="bg-rose-600 w-[100px] rounded-lg p-1">Deactivate</button>
  
              </p>
            </div>
          </div>
          <div class="mt-3">
            <pagination-controls class="text-right pr-20" (pageChange)="propPage = $event"></pagination-controls>
          </div>
        </div>
  
        <div *ngIf="!filterOn && linkedProjects.length == 0">
          <p class="text-green-600"> No projects found in here. </p>
  
        </div>
  
        <!-- with filter -->
        <div *ngIf="filterOn && filteredLinkedProjects.length > 0">
          <div class="flow-root" *ngFor="let project of filteredLinkedProjects |
          paginate: { itemsPerPage: 10, 
            currentPage: filterPage,
            totalItems: filteredLinkedProjects.length}; let i=index
            ">
            <br />
  
  
            <div>
              <p class="float-left text-green-600">
                {{ project.projectId }}
              </p>
  
              <p class="float-right text-green-800">
                <button (click)="deactivateProjectModal(project.projectId)"
                  class="bg-rose-600 w-[100px] rounded-lg p-1">Deactivate</button>
  
              </p>
            </div>
          </div>
          <div class="mt-3">
            <pagination-controls class="text-right pr-20" (pageChange)="filterPage = $event"></pagination-controls>
          </div>
        </div>
  
        <div *ngIf="filterOn && filteredLinkedProjects.length == 0">
          <p class="text-green-600"> No projects found that starts with the input you provided. </p>
        </div>
  
        <button type="button" (click)="closeModal()" class=" float-right text-black font-bold ">
          Back
        </button>
  
      </div>
    </div>
  </div>
  
  
  <!-- delete modal -->
  
  <div type="checkbox" *ngIf="deleteModal" class="modal"
    [ngClass]="{'modal-open': deleteModal, 'modal-action': !deleteModal }">
    <div class="modal-box">
      <div class="modal-content">
  
  
        <h3 class="font-bold text-lg text-center mb-1">Are you sure, do you want to remove the project
          from the billing account ?
        </h3>
        <hr />
  
  
  
        <div class="flow-root">
  
          <button type="button" (click)="closeDeleteModal()" class="float-left text-black font-bold ">
            Back
          </button>
          <button type="button" (click)="deleteProperty()" class="float-right text-black font-bold ">
            Yes
          </button>
  
        </div>
  
  
      </div>
    </div>
  </div>
  
  <app-alert class="fixed top-0 flex justify-center w-full" [toastType]="toastType" [toastMessage]="toastMessage"
    [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>