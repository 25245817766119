<div class="flex gap-x-4 w-full" *ngIf="data" [ngClass]="data.ownMessage ? 'flex-row-reverse': 'flex-row'">
    <app-circle-img [data]="data"></app-circle-img>
    <!-- <div class=""> -->
    <div class="px-4 py-2 rounded-md w-max max-w-3/4"
        *ngIf="!data.attachment || !data.attachment.type || data.attachment.type === 'none'"
        [ngClass]="data.ownMessage ? 'bg-customTeal' : 'bg-gray-100'">

        <p class="text-sm font-base break-words" [ngClass]="data.ownMessage ? 'text-white text-right' : 'text-black text-left'" [innerHTML]="helperService.replaceUrl(data.message)"></p>
        <p class="block text-xs " [ngClass]="data.ownMessage ? 'text-gray-100 text-right' : 'text-gray-600 text-left'"> {{ data.timestamp.toDate() | date:'shortTime' }}</p>

    </div>

    <!-- [ngClass]="data.attachment.type === 'image': 'w-full md:w-56', 'md:flex-row'" -->

    <div class="flex flex-col bg-gray-100 w-full md:w-1/2" *ngIf="data.attachment && data.attachment.type && data.attachment.type !== 'none'">
        <!-- [ngClass]="{'w-full md:w-1/2': ['video','image'].includes(data.attachment.type)}"> -->

        <!-- <div class="w-full h-48" *ngIf="data.attachment.type === 'image'"
                    [ngStyle]="{'background-image': 'url(' + data.attachment.link + ')'}">
                </div> -->
        <img loading="lazy" *ngIf="data.attachment.type === 'image'" [src]="data.attachment.link"
            class=" w-full  mt-3 md:mt-0  h-52 rounded-t-md object-contain hover:cursor-pointer" alt="Image" (click)="viewImage(data.attachment.link)">
        <div *ngIf="data.attachment.type === 'video'" class="relative w-full h-52 bg-contain">
            <!-- <video controls *ngIf="post.mediaUrls && post.mediaUrls.length > 0 && !imageMedia(post.mediaUrls[0])" [src]="post.mediaUrls[0]" class="object-cover w-4/12 h-24 mt-3 md:mt-0  md:h-40 rounded-md cover" alt="Video"></video> -->

            <video controls class="absolute z-10 w-full h-full" alt="Video">
                <source [src]="data.attachment.link" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>
        <!-- <div *ngIf="data.attachment.type === 'pdf'" class="w-full">
                        <iframe class="h-48 w-full" *ngIf="!isMobile" title="share-pdf" [src]="data.attachment.link | safeUrl" height="200px" width="100%"></iframe>
                        <iframe class="h-48 w-full" *ngIf="isMobile" title="share-pdf" [src]="'https://docs.google.com/viewer?url='+data.attachment.link+'&embedded=true' | safeUrl" height="500px" width="100%"></iframe>
                
            
                </div> -->
        <div class="gap-x-3 pt-4 px-4 rounded-t-md" *ngIf="data.attachment.type === 'pdf'"
            [ngClass]="data.ownMessage ? 'bg-customTeal text-white' : 'bg-gray-100 text-black'">
            <!-- show file here -->
            <i class="material-icons float-left text-4xl">feed</i><span
                class="font-bold">{{data.attachment.name}}</span>
            <div class="pt-1">
                <a class="hover:underline hover:cursor-pointer" [href]="data.attachment.link" target="_blank">Open File</a>
            </div>
        </div>


        <!-- <div [ngClass]="data.ownMessage ? 'bg-customTeal' : 'bg-customSecondary'" class="p-4" *ngIf="data.message && data.message !== ''"> -->
        <!-- <div [ngClass]="data.ownMessage ? 'bg-customTeal' : 'bg-gray-100'" class="p-4 rounded-md"
            *ngIf="data.message && data.message !== ''">
            <p class="text-base font-base break-words">{{data.message}} </p>
        </div> -->
        <div class="p-4 rounded-b-md"
            [ngClass]="data.ownMessage ? 'bg-customTeal' : 'bg-gray-100'">

            <p class="text-sm font-base break-words" *ngIf="data.message && data.message !== ''" [ngClass]="data.ownMessage ? 'text-white' : 'text-black'" [innerHTML]="helperService.replaceUrl(data.message)"></p>
            <p class="block text-xs text-right" [ngClass]="data.ownMessage ? 'text-gray-100' : 'text-gray-600'"> {{ data.timestamp.toDate() | date:'shortTime' }}</p>
        </div>
    </div>
    <!-- </div> -->
</div>


<div id="imageModal" *ngIf="openImageModal" class="modal"
    [ngClass]="{'modal-open': openImageModal, 'modal-action': !openImageModal }">
    <div class="modal-box px-6">
        <div class="flex justify-end">
            <a (click)="closeModal()" class="cursor-pointer"> <i class="text-xl fa fa-times"></i></a>
          </div>
        <img loading="lazy" class="object-contain" [src]="imgLink">

    </div>

</div>
