<div class="gap-y-8 space-y-6 px-4 md:px-0">
    
<!-- Success Message -->
<div class="md:mx-auto  md:max-w-4xl w-full bg-white py-6 px-6 space-y-4 flex flex-col shadow-sm items-center">
    <div class="flex flex-wrap justify-center items-center space-x-4 w-full rounded-md  bg-green-100 border border-solid border-green-400 px-6 py-4">
       
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width="1.5"  class=" w-8 h-8 fill-green-100 stroke-green-600">
        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
      </svg> 
        <h1 class="text-center text-xl text-green-800 font-semibold">Thank you {{userName}}, Your Purchase is Successful!</h1>
    </div>

    <!-- <button
    [routerLink]="['/user/homepage']"        
    type="button"
    class="inline-block rounded bg-brandColor px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-brandColor-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-brandColor-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-brandColor-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
    Back to Homepage
    </button> -->
</div>  

<!-- Order summary -->
<div class="mx-auto md:max-w-4xl w-full bg-white py-6 shadow-sm   space-y-4 flex flex-col ">
    <p class="font-bold px-6">Order Summary</p>
    <!-- User Detail and date  -->
    <div class=" flex flex-wrap justify-between px-6 bg-gray-100 py-2 border gap-y-3  border-solid border-gray-200">
        <div class="flex space-x-4 items-center ">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"  class="w-6 h-6 stroke-gray-500">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
            </svg>
            
            <div class="flex flex-col">
                <p class=" text-base">Bill To</p>
                <p class="font-bold">{{userName}}</p>
            </div>  
        </div>   
        <div class="flex space-x-4 items-center ">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" class="w-6 h-6 stroke-gray-500">
                <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
              </svg>
              
            <div class="flex flex-col">
                <p class=" text-base">Email</p>
                <p class="font-bold">{{userEmail}}</p>
            </div>    
        </div>   
        <div class="flex space-x-4 items-center ">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" class="w-6 h-6 stroke-gray-500">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
              </svg>
              
            <div class="flex flex-col">
                <p class=" text-base">Created</p>
                <p class="font-bold">{{startPeriod}}</p>
            </div>  
        </div>
        <div class="flex space-x-4 items-center ">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" class="w-6 h-6 stroke-gray-500">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
              </svg>
              
            <div class="flex flex-col">
                <p class=" text-base"> Due</p>
                <p class="font-bold">{{endPeriod}}</p>
            </div>    
        </div>

      
             

    </div>

    <!-- Item, price and payment detail-->
    <div class=" ">
        <!-- item -->
        <div class=" flex flex-col ">
            <div class="flex justify-between bg-gray-100 border border-solid border-gray-200 px-6">
                <p class="text-base">Item</p>
                <p class="text-base">Price</p>
            </div>  
            <hr>
            <div class="flex justify-between px-6">
                <p class="font-bold">GM Monthly Subscription</p>
                <p class="font-bold">$49.99</p>
            </div>            
            <hr>
        </div>

    

        <!-- pricing detail -->
        <div class=" flex flex-col md:w-96 w-full float-right px-6">
            <div class="flex justify-between">
                <p>Amount:</p>
                <p class="font-bold">$49.99</p>
            </div>   
            <div class="flex justify-between">
                <p>Tax:</p>
                <p class="font-bold">$0.00</p>
            </div>   
            <hr>     
            <div class="flex justify-between">
                <p>Total:</p>
                <p class="font-bold">$49.99</p>
            </div> 
            <hr>
        </div>

    </div>


    <div class="row md:mx-auto  md:max-w-4xl w-full bg-white py-6 px-6 space-y-4 flex flex-col shadow-sm items-center">
        
        <button      
        type="button" (click)="validatePurchase();"
        class="inline-block rounded bg-brandColor px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-brandColor-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-brandColor-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-brandColor-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
        Back To Home Page
        </button>
       
    </div>  

    

</div>
</div>

