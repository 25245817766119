import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DepartmentService {
  collectionRef: AngularFirestoreCollection<any>;
  documentRef: AngularFirestoreDocument<any>;

  constructor(private db: AngularFirestore) { }

  saveDepartment(model: any) {
    model.id = this.db.createId();
    return this.db.collection('Department').doc(model.id).set(model);
  }

  updateDepartment(model: any) {
    return this.db.collection('Department').doc(model.id).update(model);
  }

  getDepartment() {
    return this.db.collection('Department');
  }
  getMyDepartment(userId: any){
    return this.db.collection('EmployeeDepartment', ref => ref.where('uid', '==', userId));
  }
  getDepartmentByName(name : any){
    return this.db.collection('Department', ref => ref.where('name', '==', name))
  }
  saveMyDepartments(model: any) {
    model.id = this.db.createId();
    return this.db.collection('EmployeeDepartment').doc(model.id).set(model);
  }
  updateMyDepartment(model: any)  {
    return this.db.collection('EmployeeDepartment').doc(model.id).update(model);
  }
  addEmployeeToDepartment(id: any, userId) {
    this.documentRef = this.db.doc('Department/' + id);
    const item = this.documentRef.valueChanges();
    item.pipe(take(1)).subscribe(i => {
      if (i) {
        i.membersList.push(userId);
        this.updateDepartment(i);
      }
    });
  }

  removeEmployeeFromDepartment(id: any, userId) {
    this.documentRef = this.db.doc('Department/' + id);
    const item = this.documentRef.valueChanges();
    item.pipe(take(1)).subscribe(i => {
      if (i && i.membersList) {
        if (i.membersList.includes(userId)) {
          const index = i.membersList.indexOf(i.membersList.find(selected => selected === id));
          i.membersList.splice(index, 1);

          this.updateDepartment(i);
        }
      }
    });
  }
  deleteDepartment(model: any)  {
    return this.db.collection('Department').doc(model.id).delete();
  }
}
