<div class="w-full min-h-screen p-5">
    <!-- <p class="text-xl font-bold text-center text-customTeal">Contacts List </p> -->


    <hr>
    <!-- Send content -->
    <div class="flex flex-col justify-center space-y-6 ">

        <div class="flex sm:flex-row">
            <label for="simple-search" class="sr-only">Search</label>
            <div class="relative w-full">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 20">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M3 5v10M3 5a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 10a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm12 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm0 0V6a3 3 0 0 0-3-3H9m1.5-2-2 2 2 2" />
                    </svg>
                </div>
                <input type="text" id="simple-search"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Search contact by name..." required [(ngModel)]="searchKey"
                    (keyup)="handleSearchContact()">
            </div>


            <!-- action buttons -->
            <div class="flex items-end justify-end w-full gap-2">
                <button (click)="showConfirmationModal = true"
                    class="px-2 py-2 text-white bg-red-500 border rounded-md modal-button"
                    *ngIf="selectedContacts?.length"><i class="float-left w-2 h-2 pr-8 material-icons">delete</i>Delete
                    Contacts</button>
                <button (click)="openContactsForm()"
                    class="px-2 py-2 text-white border rounded-md modal-button bg-customTeal"><i
                        class="float-left w-2 h-2 pr-8 material-icons">add_circle</i>Import Contact</button>

            </div>
        </div>

        <div class="flex flex-col items-center mx-2 mb-2 md:flex-row md:space-x-4 md:mx-12 lg:mx-4"
            *ngIf="existingContacts && existingContacts.length != 0">
            <div class="flex">
                <div class="w-4 h-4 bg-green-500 rounded-full"></div> <span class="px-2">SMS and Email allowed.</span>
            </div>
            <div class="flex">
                <div class="w-4 h-4 bg-yellow-200 rounded-full"></div> <span class="px-2">Email or SMS</span>
            </div>
            <div class="flex">
                <div class="w-4 h-4 bg-red-500 rounded-full"></div> <span class="px-2">Do not Contact </span>
            </div>
        </div>


        <!-- <div class="relative overflow-x-auto">
            <div class="grid grid-cols-2 gap-2 m-2">
                <div class="flex flex-col p-3 rounded shadow-md bg-gray-50" *ngFor="let contact of existingContacts | 
                paginate: { itemsPerPage: 10, 
                currentPage: activePage,
                totalItems: existingContacts.length}; let i= index">
                    <div class="grid grid-cols-1 p-3 ">
                        <div class="flex gap-2">

                            <input
                                class="float-left w-4 h-4 mt-2 ml-2 mr-2 align-top transition duration-200 bg-white bg-center bg-no-repeat bg-contain border border-gray-300 rounded-sm appearance-none cursor-pointer form-check-input checked:bg-blue-600 checked:border-blue-600 focus:outline-none"
                                type="checkbox" value="" id="flexCheckDefault" [checked]="checkContact"
                                (click)="selectedContactFromList(contact)">
                                <div class="flex flex-col">
                                    <div class="flex gap-2 " *ngFor="let contactDetail of (contact | keyvalue | slice:0:6)">
                                        <ng-container
                                            *ngIf="contactDetail?.key !== 'id' && contactDetail?.key !== 'created_at'">
                                            <p class="text-sm font-bold">{{contactDetail?.key}} :</p>
                                            <p class="text-sm">{{contactDetail?.value}}</p>
                                        </ng-container>
                                    </div>
                                </div>
                           

                        </div>
                    </div>
                    <div class="flex justify-end">
                        <button class="p-2 text-sm text-white border rounded-md modal-button bg-customTeal"
                            (click)="openModal(contact)">
                            Detail</button>
                    </div>
                </div>

            </div>

            <pagination-controls class="mr-20 text-right" (pageChange)="activePage = $event"></pagination-controls>
        </div> -->

        <div class="relative overflow-x-auto" *ngIf="tableHeaders?.length > 0 && existingContacts?.length > 0">
            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" class="px-6 py-3 text-bold">
                            <div class="form-check">

                                <input
                                    class="float-left w-4 h-4 mt-1 mr-2 align-top transition duration-200 bg-white bg-center bg-no-repeat bg-contain border border-gray-300 rounded-sm appearance-none cursor-pointer form-check-input checked:bg-blue-600 checked:border-blue-600 focus:outline-none"
                                    type="checkbox" value="" id="flexCheckDefault" (click)="selectedAllContacts()"
                                    [checked]=selectAllcheckedContact>
                            </div>
                        </th>
                        <th scope="col" class="px-6 py-3 text-bold" *ngFor="let header of tableHeaders">
                            {{header?.value}}
                        </th>
                        <th scope="col" class="px-6 py-3 text-bold">
                            Contact
                        </th>
                        <th scope="col" class="px-6 py-3 text-bold">
                            Detail
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700" *ngFor="let contact of existingContacts 
                     let i= index">
                        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">

                            <input
                                class="float-left w-4 h-4 mt-2 ml-2 mr-2 align-top transition duration-200 bg-white bg-center bg-no-repeat bg-contain border border-gray-300 rounded-sm appearance-none cursor-pointer form-check-input checked:bg-blue-600 checked:border-blue-600 focus:outline-none"
                                type="checkbox" value="" id="flexCheckDefault" [checked]="checkContact"
                                (click)="selectedContactFromList(contact)">
                        </th>

                        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                            *ngFor="let header of tableHeaders">
                            {{contact?.[header?.value]?.value}}
                        </th>
                        <th scope="row"
                            class="px-6 py-4 font-medium text-gray-900 cursor-pointer whitespace-nowrap dark:text-white">
                            <label class="pl-2 modal-button"
                                [ngClass]="{'text-green-500':checkEmailAndPhone(contact) === 'both', 'text-yellow-300': checkEmailAndPhone(contact) == 'email' || checkEmailAndPhone(contact) == 'phone', 'text-red-500' : checkEmailAndPhone(contact) != 'email' && checkEmailAndPhone(contact) != 'phone'  }">
                                <i class="w-5 h-5 material-icons success-text">how_to_reg</i>
                            </label>

                        </th>
                        <th scope="row"
                            class="px-6 py-4 font-medium text-gray-900 cursor-pointer whitespace-nowrap dark:text-white">
                            <!-- <i class="float-left w-2 h-2 pr-8 material-icons text-customTeal"
                                (click)="openModal(contact)">info</i> -->
                            <button (click)="viewContactDetail(contact)" type="button">
                                <i class="float-left material-icons material-symbols-outlined">arrow_circle_right </i>
                            </button>
                        </th>
                    </tr>

                </tbody>
            </table>

            <pagination-controls class="mr-20 text-right" (pageChange)="activePage = $event"></pagination-controls>
        </div>

        <!-- If there is no imported contacts -->
        <div *ngIf="tableHeaders?.length === 0 || existingContacts?.length === 0">
            <div class="flex items-center justify-center">
                <img loading="lazy" data-src="../../../../assets/icons/Illustrations/RegistrationRequest-Illustration.png"
                    class="flex items-center justify-center block w-64">
            </div>
            <div class="text-lg font-bold text-center text-customTeal">There are no contacts registered.
            </div>
        </div>

        <div class="flex flex-col items-baseline justify-center space-x-8 align-middle md:w-1/2">
        </div>

    </div>
</div>

<!-- modal -->
<div class="modal modal-open" *ngIf="showModal">
    <div class="w-11/12 max-w-4xl overflow-hidden modal-box">
        <div class="modal-content">

            <div class="flex items-center mt-4 space-x-8 ">
                <div class="flex-col px-2 text-xl font-bold text-black">Contact Detail Information</div>
            </div>
            <br />

            <div class="grid w-full grid-cols-2 gap-4 px-4 my-3">

                <!-- first label -->
                <div class="flex" *ngFor="let item of selectedContact | keyvalue">
                    <ng-container *ngIf="item?.key !== 'id'">
                        <h1 class="text-sm font-bold">{{generatePascalCase(item.key)}} : </h1>
                        <h1 class="text-sm" *ngIf="item.key === 'created_at'">{{getCreatedDate(item.value)}}</h1>
                        <h1 class="text-sm" *ngIf="item.key !== 'created_at'">{{item.value}}</h1>
                    </ng-container>

                </div>
            </div>


            <div class="flex justify-end mt-2">
                <button (click)="closeModal()"
                    class="p-2 text-white border-none rounded-md bg-customTeal hover:bg-gray-100 hover:text-customTeal"
                    type="submit">Cancel</button>
            </div>

        </div>

    </div>
</div>

<!-- deletion confirmation modal -->
<div class="modal modal-open" id="shareModal" *ngIf="showConfirmationModal">
    <div class="modal-box">
        <h3 class="mb-2 text-lg font-bold text-center">Deletion Confirmation</h3>
        <p class="py-4 text-center"> Are you sure you want to proceed with deleting the selected contacts? This action will permanently remove the chosen contacts from the system.</p>

        <div class="items-center justify-between modal-action">
            <label>
                <button
                    class="font-bold text-red-500 border-none hover:bg-red-500 hover:text-white hover:rounded-md hover:font-normal hover:p-2"
                    type="button" (click)="showConfirmationModal = false">No</button>

            </label>
            <button type="button" (click)="deleteContacts()"
                class="px-4 py-2 text-white rounded-md bg-customTeal border-customTeal">Yes
            </button>
        </div>
    </div>
</div>