import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { UserService } from 'src/app/services/firebase/user.service';
import { InviteService } from 'src/app/services/firebase/invite.service';
import { EmailService } from 'src/app/services/api/email.service';
import { EmailModel } from 'src/app/models/emailModel';
import { GetAppService } from 'src/app/services/firebase/get-app.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.css']
})
export class VerifyEmailComponent implements OnInit{

  model: any;
  themeColor: any;
  appName: any;
  appDescription = '';
  appLogo: any;
  defaultThemeColor = environment.appTheme.themeColor;

  verifyEmail : any;
  currentUser : any;
  id: any = '';
  user: any;
  userGlobal : any;
  storeEmail = [];
  domain = environment.firstNation.name;
  hostname = "https://"+this.domain +".goingmobile.app/login"
  host = window.location.hostname !== 'localhost' ? 'https://' + window.location.hostname + '/auth/verify-email/' : 'http://localhost:4200/auth/verify-email/';

  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;

  success:boolean = false;

  isNationApp = environment.firstNation.Origin == "FNLeaders" ? true : false;

  domainName = this.isNationApp ? "https://" + this.domain + ".mynation.app" : "https://" + this.domain + ".goingmobile.app";

  constructor(private router: Router, 
    private route: ActivatedRoute,
    private authService: AuthService,
    private userService: UserService,
    private emailService: EmailService,
    private settingsService: AppSettingsService) { 

      if (this.router.getCurrentNavigation().extras?.state != null) {
        this.verifyEmail = this.router.getCurrentNavigation()
          .extras.state.forgotpassword;
      }
   // this.forgotpassword = this.router.getCurrentNavigation().extras.state.forgotPassword;
    
  }

  ngOnInit() {
    this.currentUser = this.authService.getCurrentUser();
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });

    // let elements = document.getElementsByClassName('st4’');
    // for (var element of elements) {
    //     element.style.fill = '#FF0000';
    // }
    
    // elements = document.getElementsByClassName('st9’');
    // for (element of elements) {
    //     element.style.fill = '#FF0000';
    // }
    
    // elements = document.getElementsByClassName('st11’');
    // for (element of elements) {
    //     element.style.fill = '#FF0000';
    // }
    if(this.currentUser && this.id == null){
      this.userService.getUserById(this.currentUser.uid).valueChanges().subscribe(user => {
        if(user && user.length){
        this.user = user[0];
        this.user.verified = false;
        }
      })
    }
    else if(this.id != null){
      this.userGlobal = this.userService.getUserById(this.id).valueChanges().subscribe(user =>{
        if(user && user.length){
        this.user = user[0];
        this.user.verified = true;
        if(this.user.verified){
          this.userService.updateUser(this.user).then(x=>{
            const emailModel = new EmailModel();
            emailModel.subject = 'Email Verified';
            emailModel.isNationApp = this.isNationApp;
            emailModel.body = '<p>';
            if (this.appLogo){
              emailModel.body += `<img src="${this.appLogo}"
              width="100" height="100" style=" display: block; alt="Logo">`;
            }
            emailModel.body += '</p>';
    
            emailModel.body += `<p>Your email has been verified. Now you can login to the application using button the below.</p>`
            emailModel.body += `<p><a href="${this.hostname}" style=" background-color: #077373; cursor: pointer; border-radius: 10px; padding: 10px 24px; text-align: center; text-decoration: none; display: inline-block; font-size: 16px; margin: 4px 2px; cursor: pointer; font-weight: bold; color: white; ">Login</a></p>`;
            emailModel.body += ` <p>Thank you.</p>`
            this.storeEmail = [];
            this.storeEmail.push(this.user.Email);
            emailModel.emailList = this.storeEmail;
            // this.emailService.sendEmail(emailModel).subscribe((res) => {
            //   if (res) {
            //   }
            //   else {
            //   }
            // })
          })
  
        }
      }
        this.userGlobal.unsubscribe();
      
      })
    }

    this.settingsService.getAppSettingsList().valueChanges().subscribe(
      appSettings => {
        if (appSettings) {
          if (appSettings.length > 0) {
            this.model = appSettings[0];
            this.themeColor = this.model.themeColor ? this.model.themeColor : this.defaultThemeColor;
            this.appName = this.model.appName;
            this.appDescription = this.model.description;
            this.appLogo = this.model.logo;
          }
        }
      }
      );
  }

  GoToLogin() {
    this.authService.signOutWithoutRedirect();
    this.router.navigate(['auth/login']);
  }
  resendEmail(){
    let hostname = `${this.host}${this.currentUser.uid}`;
    // let domainName1 = `https://${this.domain}.goingmobile.app`
    let domainName1 = this.domainName;
    const emailModel = new EmailModel();
    emailModel.subject = ` ${this.appName} Email Verification`;
    emailModel.isNationApp = this.isNationApp;

    emailModel.body = '<div style="background-color: grey;  font-family: Helvetica, Arial, Sans-Serif;">';
    emailModel.body += '<div style="width: 600px; background-color: white;  display: block; margin-left: auto; margin-right: auto; border-width: 6px; border-bottom-style: solid; border-color: #077373;">';
    emailModel.body += '<center>';
    if (this.appLogo){
      emailModel.body += `<img src="${this.appLogo}"
      width="150" height="150" style=" display: block; alt="Logo">`;
    }
    emailModel.body += '</center>';

    emailModel.body += `<h2 style="text-transform: capitalize; padding-left: 20px; padding-right: 20px;">  Hello , </h2> `+ '<br /> '
    emailModel.body += `<p style="padding-left: 20px; padding-right: 20px; padding-bottom: 5px;">Thank you for registering with ${this.appName}. </p> `;
    emailModel.body += '<p style="padding-left: 20px; padding-right: 20px; padding-bottom: 5px;">Please click the button below to activate your account. </p>';
    emailModel.body += `<p><a href="${hostname}" style=" background-color: #077373; cursor: pointer; border-radius: 10px; padding: 18px 24px; text-align: center; text-decoration: none; display: inline-block; font-size: 16px; margin: 10px 2px; cursor: pointer; font-weight: bold; color: white; ">Activate My Account</a></p>`;
    emailModel.body += '<br /> <p style="padding-left: 20px; padding-right: 20px;"> If you didn’t ask to verify this address, you can ignore this email.</p> <br /> <p style="padding-left: 20px; padding-right: 20px; padding-bottom: 5px;">Thank you </p> ';
    emailModel.body += `<p style="padding-left: 20px; padding-right: 20px; padding-bottom: 5px;"><a href="${domainName1}" style=" cursor: pointer; border-radius: 10px; text-align: center; text-decoration: underline; display: inline-block; font-size: 16px; margin: 4px 2px; cursor: pointer; font-weight: bold; color: blue; text-decoration-color: blue; "><u>${domainName1}</u></a></p><br /><br />`;
    this.storeEmail.push(this.currentUser.email);
    emailModel.emailList = this.storeEmail;
    this.emailService.sendEmail(emailModel).subscribe((res) => {
      if (res) {
        this.toast({ html: 'Verification email has been resent to you Successfully.', classes: 'green', type: 'success' });
        this.success = true;
      }
      else {
      }
    })

  }
  toast(obj) {
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green';
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
    }, 3000);
  }

}
