<!-- <p>send-message-from-users works!</p> -->
<div class="modal modal-open">
    <div class="modal-box max-w-4xl">
        <div class="modal-content">
            <h3 class="font-bold text-lg">Contact </h3>

            <form [formGroup]="contactForm" (ngSubmit)="sendMessage(contactForm.value)">

                <div class="flex items-center mt-4 space-x-8 ">

                    <div class="flex-col text-black px-2">Contact with:</div>

                    <div class=" flex ">

                    <!-- <div class="flex">
                        <input type="radio" id="Email" value="Email" class="mx-2 cursor: text;"
                            formControlName="contact" [(ngModel)]=contactMsg>
                        <label for="">Email</label><br>
                    </div> -->

                    <div>
                        <input type="radio"  value="SMS" class="mx-2 cursor: text;" formControlName="contact"
                            [(ngModel)]=contactMsg >
                        <label for="">SMS</label><br>
                    </div>

                    </div>
                </div>
                <br />

                <div class=" px-2 mt-2 w-full">
                    <label for=" " class="text-sm font-bold mb-2 "> To: </label>
                    <input name="To" formControlName="to" (click)="toSendTo()" [(ngModel)]="search_text"
                        class="form-control mt-1 px-3 py-3 bg-white border shadow-sm border-slate-300 placeholder-slate-400 outline outline-offset-1 outline-1 focus:outline-1 focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                        placeholder="John Hane" />
                    <br />
                </div>

                <div class="-mt-4 bg-gray-200" *ngFor="let emp of all ; let i=index">
                    <button type="button" (click)="clickToSend(emp)" class="py-2 px-4 mx-2">{{emp.GivenName}} {{emp.LastName}}</button>
                    <hr/>
                </div>

                <div *ngIf="(contactMsg == 'SMS') && !isLimitSMS ">
                    <div class="px-2 mt-2 w-full">
                        <label for=" " class="text-sm font-bold mb-2 "> Message: </label>

                        <textarea id="message" formControlName="messageSMS"
                            class="border rounded py-4 px-3 mt-2 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline h-32 w-full "
                            placeholder="Message"></textarea>

                    </div>

                </div>

                <div *ngIf="(contactMsg == 'Email') && !isLimitEmail ">
                    <div class=" px-2 mt-2 w-full">
                        <div>
                            <label for=" " class="text-sm font-bold mb-2 "> Subject: </label>
                            <input name="subject" formControlName="subject" id="subject"
                                class="form-control mt-1 px-3 py-3 bg-white border shadow-sm border-slate-300 placeholder-slate-400 outline outline-offset-1 outline-1 focus:outline-1 focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                                placeholder="Subject" />

                            <br />
                        </div>
                        <label for=" " class="text-sm font-bold mb-2 "> Message: </label>

                   
                        <app-editor [control]="emailbody" class="bg-white" required
                            [fromComponent]="'message'"></app-editor>

                    </div>


                </div>



                <div class="flex justify-between mt-2">
                    <button type="button" (click)="cancelSendBtnClicked()"
                        class="btn bg-white hover:bg-gray-400 hover:text-white text-black border-none">Cancel</button>
                    <button [disabled]="!contactForm.valid" *ngIf="contactMsg !== 'directMessage'"
                        class="p-2 text-white bg-customTeal hover:bg-gray-100 hover:text-customTeal border-none rounded-md"
                        type="submit">Send Message</button>
                </div>

            </form>
        </div>
    </div>
</div> 