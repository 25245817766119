import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationSettingsService {

  collectionRef: AngularFirestoreCollection<any>;
  documentRef: AngularFirestoreDocument<any>;

  constructor(private db: AngularFirestore) { }

  // Contact Settings -------------------------------

  savePushNotificationSettings(model: any) {
    model.id = this.db.createId();
    return this.db.collection('EmployeePushNotificationSettings').doc(model.id).set(model);
  }

  updatePushNotificationSettings(model: any) {
    return this.db.collection('EmployeePushNotificationSettings').doc(model.id).update(model);
  }

  getPushNotificationSettingsList(empId): AngularFirestoreCollection<any[]> {
    this.collectionRef = this.db.collection('EmployeePushNotificationSettings', ref => ref.where("userId", "==", empId));
    return this.collectionRef;
  }


  // clients push notification settings
  saveClientPushNotificationSettings(model: any) {
    model.id = this.db.createId();
    return this.db.collection('ClientPushNotificationSettings').doc(model.id).set(model);
  }

  updateClientPushNotificationSettings(model: any) {
    return this.db.collection('ClientPushNotificationSettings').doc(model.id).update(model);
  }

  getClientPushNotificationSettingsList(clientId): AngularFirestoreCollection<any[]> {
    this.collectionRef = this.db.collection('ClientPushNotificationSettings', ref => ref.where("userId", "==", clientId));
    return this.collectionRef;
  }

}



