<!-- <p>view-form works!</p> -->


<div class="flex flex-col md:flex-row flex-wrap m-3 md:m-6">
    <div class="flex flex-col bg-white w-full md:w-max h-56 rounded-lg p-8 md:p-6 space-y-4 m-4 cursor-pointer"
        *ngFor="let form of forms">
        <div (click)="goToForm(form)">
            <div class="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-32 md:justify-between">
                <p class="font-bold text-lg">{{form.name}}</p>
                <div class="flex space-x-2 items-center">
                    <div class=""></div>
                    <p class="text-sm px-4 py-2 bg-gray-300 text-black font-bold">Unfilled</p>
                </div>
            </div>

            <div class="flex">
                <div class="flex space-x-2 items-center" *ngIf="form && form.description">
                    <div class="rounded-full bg-gray-400 w-3 h-3"></div>
                    <p class="text-sm">{{truncateHTML(form.description,2)}}</p>
                </div>
            </div>

            <div class="flex">
                <div class="avatar-group -space-x-6">
                    <div class="avatar">
                        <div class="w-12">
                            <img loading="lazy" data-src="https://placeimg.com/192/192/people" />
                        </div>
                    </div>
                    <div class="avatar">
                        <div class="w-12">
                            <img loading="lazy" data-src="https://placeimg.com/192/192/people" />
                        </div>
                    </div>
                    <div class="avatar">
                        <div class="w-12">
                            <img loading="lazy" data-src="https://placeimg.com/192/192/people" />
                        </div>
                    </div>
                    <div class="avatar placeholder">
                        <div class="w-12 bg-neutral-focus text-neutral-content">
                            <span>+99</span>
                        </div>
                    </div>
                </div>
                <p class="font-bold text-customTeal text-sm md:text-base mt-3">Responses</p>

            </div>

            <div class="flex  space-x-5 md:space-x-10 py-2 ">
                <button class="flex items-center space-x-2  w-max h-min text-sm md:text-base font-bold">
                    <span>Date Posted on {{form.datePosted.toDate()| date:'short'}}</span>
                </button>
            </div>



        </div>
    </div>
</div>