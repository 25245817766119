<div class="flex justify-center h-screen" *ngIf="loading">
    <progress class="progress progress-customTeal w-full"></progress>
</div>

<div *ngIf="!loading">
    <div *ngIf="!showProfile">
        <div *ngIf="user" class="font-bold text-xl">
            Welcome <span class="text-customTeal">{{user.GivenName}}</span>
          <span class="text-base font-normal"> we're glad your here</span>
        </div>

        <div class="flex justify-end pt-7 md:pt-0" >
            <div class="">
                <button *ngIf = "userProfile && userProfile.length < 1" (click)="openProfileModal()" for="my-modal"  class="py-2 px-6 modal-button gap-2 bg-customTeal rounded-md text-white w-full">
                    <i class="material-icons mr-3 float-left">post_add</i>
                    Create Profile
                </button>
            </div>
        </div>
    </div>


    <div *ngIf="hasProfile" >

        <div class="flex pt-12 lg:pt-24">
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 w-full gap-4">
                <div class="w-full  bg-white rounded-md shadow-md p-4
                md:py-6" *ngFor="let s of userProfile; let li=index">
                    <div (click)="toDashboard(li)" class=" grid grid-cols-1 justify-items-start md:justify-items-center">
                        <div class="text-center text-xl font-bold pb-6 lg:pb-12">
                            {{s.title}}
                        </div>
                        <div class="flex flex-row lg:flex-col">
                            <div class="flex-basis 1/3">
                            <div class="p-1 text-customTeal hover:font-bold"><i class="material-icons mr-4 float-left">link</i>
                                <a (click)="linkSocialMedia(s.profileKey)"
                                >Link social media account</a>
                            </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <!-- <div *ngIf="showProfile">
            <div class="mb-2 md:mb-0">
                <p class='pb-7'>Hello your on.</p>
                <div class="flex flex-col  md:flex-row gap-x-6 gap-y-3">
                    <div class="">
                        <select (change)="profileChange($event)" class="select select-bordered w-full max-w-xs">
                            <option disabled selected>{{profileKey.title}}</option>
                            <option *ngFor="let p of userProfile;let li=index" [value]="li">{{p.title}}</option>
                          </select>
                    </div>
                    <div>
                        <button class="btn btn-ghost" (click)="openProfileModal()">  <i class="text-customTeal material-icons mr-3 float-left">add</i>New Profile </button>
                    </div>

                </div>
            </div>
            <app-social-media-dashboard [profileKey]="profileKey.profileKey"></app-social-media-dashboard>

        </div> -->

    </div>

    <div *ngIf="!hasProfile" class="px-5 md:px-0 py-14 flex flex-col w-full jusify-center">
        <div class="w-full py-4 text-center text-bold">
            Seems like you don't  have any profiles. Create yur profile to start posting to your social accounts!
        </div>

        <!-- <div class="flex justify-center">
            <button  (click)="openProfileModal()" class="py-2 px-6 gap-2 bg-customTeal rounded text-white w-50 md:w-60">
                <i class="material-icons mr-3 float-left">post_add</i>
                Create Profile
            </button>

        </div> -->


    </div>

    <div id="createProfileModal"  *ngIf="profileModalOpen" class="modal" [ngClass]="{'modal-open': profileModalOpen, 'modal-action': !profileModalOpen }">
        <div class="modal-box  bg-white p-6 w-11/12 md:py-14 md:px-20  md:w-4/12 max-w-5xl flex justify-center">
          <div class="modal-content">
              <div class="pb-4 md:pb-6 " >
                <h3 class="font-bold text-lg text-black">Create Profile</h3>
                <p class="text-sm text-lightGray">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc </p>
              </div>


            <form [formGroup]="profileForm" (ngSubmit)="createUserProfile(profileForm.value)">
                <div class="grid grid-cols-1 gap-y-5">
                    <div class="form-control w-full max-w-x" >
                        <label class="label">
                            <span class="label-text text-black text-base font-bold leading-5">Title</span>
                          </label>
                          <input type="text" formControlName='title' placeholder="Type here" class="input input-bordered input-info w-full" />

                    </div>

                    <div class="grid grid-cols-12 form-control w-full max-w-x" *ngIf="!addEmployee && employees">
                        <label class="label col-span-12">
                            <span class="label-text text-[#4F4F4F] text-base font-bold leading-5">Add employee to manage this profile</span>
                          </label>
                        <button class="text-left col-span-8 md:col-span-6  btn btn-ghost text-customTeal" type="button" (click)="addEmployee=true">
                             <i class=" material-icons mr-3 float-left">add_circle</i>Add Employee
                        </button>


                    </div>

                    <div class="form-control w-full" *ngIf="addEmployee && employees">
                        <label class="label">
                          <span class="label-text text-black text-base font-bold leading-5">Pick an employee</span>
                        </label>
                        <select class="select select-bordered w-full" formControlName='employeeId'>
                          <option disabled selected>Pick an employee</option>
                          <option *ngFor="let employee of employees;let li=index" [value]="employee.uid">{{employee.GivenName}} {{employee.LastName}}</option>

                        </select>
                      </div>

                    <div class="flex justify-between py-8">
                        <button type="button" class="font-bold" (click)="closeProfileModal()">Cancel</button>
                        <button type="submit" [disabled]="!profileForm.valid" class="font-bold py-3 px-5 border-none rounded-md bg-customTeal text-white">Create</button>

                    </div>


                </div>
            </form>
          </div>

        </div>

      </div>


      <div id="profileCreatedModal"  *ngIf="profileCreatedModalOpen" class="modal cursor-pointer" [ngClass]="{'modal-open': profileCreatedModalOpen, 'modal-action': !profileCreatedModalOpen }">
        <div class="modal-box  bg-white pt-6 pb-10 w-11/12 md:w-5/12 max-w-5xl px-0">
          <div class="modal-content ">
              <div class="pb-4 md:pb-6 " >
                <h3 class="text-center font-bold text-xl text-black pt-6">You’ve created a profile
                </h3>
                <div class="divider px-0"></div>

                <div class="text-base text-[#636363] text-center px-4 md:px-6">
                    Ayrshare is our partner that helps us make your social media management easy and convinent.
                    Please Link accounts you’d like to manage.
                </div>
              </div>

              <div class="flex justify-center pt-6">
                <button (click)="closeSuccessModal()" class="mx-auto font-bold py-3 px-5 border-none rounded-md bg-customTeal text-white">Got it</button>

              </div>


          </div>

        </div>

      </div>

      <div class="alert alert-success shadow-lg fixed top-0 right-0 "
[ngClass]="{'visible': openToast, 'invisible': !openToast,'alert-success': toastType=='success', 'alert-error': toastType=='failure' }"
>
  <div class="text-white font-bold">
    <span class="material-icons">check_circle</span>
    <span>{{toastMessage}}</span>
  </div>
</div>

</div>
