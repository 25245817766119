<!-- Sections -->
<section id="services" *ngIf="allSections.length > 0">
  <h2 class="text-2xl font-bold text-center">{{sectionDetail.name}}</h2>
  <div *ngIf="view=='list'">
    <div  class="flex flex-col md:mt-5 md:flex-row baseline md:space-x-16 justify-center  mb-5" *ngIf="allSections">
      <app-custom-list-default *ngIf="allSections.length > 1" [itemNumber]="itemNumber" [sections] = 'getSortedList(allSections)'></app-custom-list-default>
      <app-custom-list-minimal *ngIf="allSections.length == 1" [sections] = 'getSortedList(allSections)' class="w-full"></app-custom-list-minimal>
    </div>
  </div>
  <a *ngIf="allSections" [routerLink]="['/custom-section-list', section.id]" (click)="redirectToServices()" class="block md:pt-2 text-md text-customTeal font-bold text-right md:mr-32 mx-5">View More</a>
    
  <a *ngIf="view == 'card'" [routerLink]="['/custom-section-list', section.id]" (click)="redirectToServices()">
    <div class="flex flex-col justify-center "  [style.background-color]="sectionDetail.cardDetail ? sectionDetail.cardDetail.backgroundColor: pageSections.defaultCardView.backgroundColor"  [style.border-radius.px]=" sectionDetail.cardDetail ? sectionDetail.cardDetail.borderRadius : pageSections.defaultCardView.borderRadius" [style.width.px]="sectionDetail.cardDetail ? sectionDetail.cardDetail.boxWidth : pageSections.defaultCardView.boxWidth" [style.height.px]="sectionDetail.cardDetail ? sectionDetail.cardDetail.boxHeight : pageSections.defaultCardView.boxHeight">
      <div class="w-full space-y-2">
        <div class="rounded-full border-2 border-solid p-6 border-white w-min mx-auto">
         <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" fill="none" class="w-7 h-7  fill-white " viewBox="0 0 360 360"><rect x="194.86" y="3.17" width="162.35" height="162.35" rx="7.5" style="fill:white"/><path d="M149.37,3.61H18.78A15.88,15.88,0,0,0,2.9,19.49V150.08A15.88,15.88,0,0,0,18.78,166H149.37a15.88,15.88,0,0,0,15.88-15.88V19.49A15.88,15.88,0,0,0,149.37,3.61Zm-8.82,118.23a19.41,19.41,0,0,1-19.41,19.41H47a19.41,19.41,0,0,1-19.41-19.41V47.73A19.41,19.41,0,0,1,47,28.31h74.12a19.41,19.41,0,0,1,19.41,19.42Z" style="fill:white"/><path d="M149.37,193.31H18.78A15.88,15.88,0,0,0,2.9,209.2V339.78a15.88,15.88,0,0,0,15.88,15.89H149.37a15.88,15.88,0,0,0,15.88-15.89V209.2A15.88,15.88,0,0,0,149.37,193.31Zm-8.82,118.24A19.41,19.41,0,0,1,121.14,331H47a19.41,19.41,0,0,1-19.41-19.41V237.43A19.41,19.41,0,0,1,47,218h74.12a19.41,19.41,0,0,1,19.41,19.41Z" style="fill:white"/><path d="M341.33,193.31H210.74a15.88,15.88,0,0,0-15.88,15.89V339.78a15.88,15.88,0,0,0,15.88,15.89H341.33a15.88,15.88,0,0,0,15.88-15.89V209.2A15.88,15.88,0,0,0,341.33,193.31ZM332.5,311.55A19.41,19.41,0,0,1,313.09,331H239a19.41,19.41,0,0,1-19.41-19.41V237.43A19.41,19.41,0,0,1,239,218h74.12a19.41,19.41,0,0,1,19.41,19.41Z" style="fill:white"/></svg>
        </div>
        <div>
          <p class="text-white text-center  text-xl">
           
            {{sectionDetail.name}}
          </p>
        </div>
      </div>
    
      </div>
  </a>
</section>

