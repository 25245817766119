import { ProductsServiceMenuService } from 'src/app/services/api/data.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { Location } from '@angular/common';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NewsEventService } from '../../../../services/firebase/news-event.service';
import { PermissionService } from 'src/app/services/firebase/permission.service';
import { HelperService } from 'src/app/services/helper/helper';
import { NotificationModel } from 'src/app/models/Messages.Model';
import { FCMPushNotificationsService } from 'src/app/services/firebase/FCMPushNotifications.service';
import { Comment } from 'src/app/models/PostsModel';
import { NotificationsService } from 'src/app/services/firebase/notifications.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommentsService } from 'src/app/services/firebase/comments.service';
import { UserService } from 'src/app/services/firebase/user.service';
import { SMSModel } from 'src/app/models/SMSModel';
import { SMSService } from 'src/app/services/api/sms.service';
import { EmailService } from 'src/app/services/api/email.service';
import { EmailModel, CustomAdminEmailModel } from 'src/app/models/emailModel';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-view-event',
  templateUrl: './view-event.component.html',
  styleUrls: ['./view-event.component.css']
})
export class ViewEventComponent implements OnInit {

  eventId: any;
  event: any;
  isMobile = false;
  currentUser: any;
  deleteModalOpen = false;

  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;

  perm: any;
  permission: any;
  permPES: any;
  isAdmin: boolean;
  isUser: boolean;
  currency;
  @Input() modelToPreview: any;
  @Input() view: any;

  eventsName: any;
  eventTitle;
  notifSettings;
  eventLiked=false
  currentUserEvents: any = [];


  cancelRegistration: boolean = false;
  eventGlobal: any;
  maxRegistration: any;
  userNumber: any;
  itIsFull: boolean = false;

  editEventGlobal: any;
  eventGlobalforUnsub: any;
  isNationApp = environment.firstNation.Origin == "FNLeaders" ? true : false;
  showMap : boolean = false;
  userIds: any;
  viewSubs : boolean = false;
  subscribers = [];
  registrationConfirmed: boolean;
  registrationCancelled: boolean;

  constructor(private route: ActivatedRoute, private location: Location,
    private newsEventService: NewsEventService,
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
    private permissionService: PermissionService,
    private productsServiceMenuService: ProductsServiceMenuService,
    private smsService : SMSService,
    private emailService: EmailService,
    private helperService: HelperService,
              private pushNotificationsService: FCMPushNotificationsService,
              private notificationsService: NotificationsService,
              private commentsService: CommentsService
              ) {
    if (typeof window['cordova'] !== 'undefined') {
      this.isMobile = true;
    }
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.currentUser = this.authService.getGlobalUser();

    this.route.paramMap.subscribe(params => {
      this.eventId = params.get('id');
    });


    if (this.currentUser) {
      if (this.currentUser.role == 'Admin') {
        this.isAdmin = true;
      }
      if (this.currentUser.role == 'Employee') {
        this.permissionService.getPermissionByEmployeeId(this.currentUser.uid).valueChanges().subscribe(perm => {
          if (perm.length != 0) {
            this.perm = perm[0];
            this.permPES = this.perm.permission[1];
          }
        });
      }
      if (this.currentUser.role == 'User') {
        this.isUser = true;

      }
    }

    this.newsEventService.getAllRegisteredUserByEventId(this.eventId).valueChanges().subscribe((user: any) => {
      if (user && user.length > 0) {
        this.userNumber = user[0].uid.length;
        for (let i = 0; i < user[0].uid.length; i++) {
          this.userIds = user[0].uid;
          if (user[0]?.uid[i] === this.currentUser?.uid) {
            this.cancelRegistration = true;
            break;
          }
          this.userService.getUserById(user[0].uid[i]).valueChanges().subscribe((subscriber) =>{
            if(subscriber && subscriber.length > 0){
              this.subscribers.push(subscriber[0]);
            }
          })
        }
      }
    })


    if (!this.eventId) {

      if (this.modelToPreview) {
        this.event = this.modelToPreview;
      }
    }

    this.eventGlobalforUnsub = this.newsEventService.getEventById(this.eventId).valueChanges().subscribe(event => {
      if (event.length > 0) {
        this.event = event[0];
        this.currentUserEvents = event;
        this.eventTitle = this.helperService.getInnerText(this.event.eventTitle)
        this.maxRegistration = this.event.maxRegistration;
        this.event.opened = this.event.opened === '' ? this.event.opened : [];
        this// .eventTitle = this.event.eventTitle.getInnerText()
        // this.eventTitle = this.eventTitle.innerHTML
        if (this.maxRegistration === this.userNumber) {
          this.itIsFull = true;
        }
        if (this.modelToPreview) {
          this.event = this.modelToPreview;

        }
        if ( this.currentUser && this.currentUser.role == 'User') {
          this.event.opened.push(this.currentUser.uid);
 
          let eventCopy = this.event;
          this.editEventGlobal = this.newsEventService.editEvent(eventCopy).then((res) =>{
              // this.editEventGlobal.unsubscribe();
          })
        }
      }
      this.eventGlobalforUnsub.unsubscribe();
    });



    this.productsServiceMenuService.currencyName.subscribe(c => {
      this.currency = c;
    });

    this.productsServiceMenuService.eventsName.subscribe(e => {
      this.eventsName = e;
    });

  }
  ngOnChanges(changes: SimpleChanges): void {
    this.event = this.modelToPreview
  }
  backClicked() {
    this.location.back();
  }
  getUrl(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);

    if (match && match[2].length === 11) {
      const youtubeUrl = 'https://www.youtube.com/embed/' + match[2];
      return youtubeUrl;
    } else {
      return url;
    }
  }

  navigateEditEvent(event) {
    if (this.isAdmin) {
      this.router.navigate(['admin/edit-event', event.id])
    } else {
      this.router.navigate(['employee/edit-event', event.id])
    }
  }

  openDeleteModal() {
    this.deleteModalOpen = true;
  }

  closeDeleteModal() {
    this.deleteModalOpen = false;
  }
  toast(obj) {
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green';
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
      if(obj.redirectBack){
        if (this.currentUser.role === 'Admin'){
          this.router.navigate(['/admin/events']);
        }
        if (this.currentUser.role === 'Employee'){
          this.router.navigate(['/employee/events']);
        }
      }

    }, 2000);
  }

  deleteEvent() {
    this.closeDeleteModal();
    this.newsEventService.deleteEvent(this.event).then(() => {
      this.toast({ html: 'Event deleted successfully', classes: 'red', type: 'failure', redirectBack:true });
    });
  }

  isValidUrl(url) {
    return this.helperService.isValidURL(url);
  }

  register() {
    this.router.navigate(['auth/register']);
  }

  closeViewSubs(){
    this.viewSubs = false;
  }

  confirmRegistration(){
    this.registrationConfirmed = true;
  }
  registerUser() {
    this.eventGlobal = this.newsEventService.getAllRegisteredUserByEventId(this.eventId).valueChanges().subscribe((user: any) => {
      if (user && user.length > 0) {
        const users =  user.uid ? user.uid : [];
        users.push(this.currentUser?.uid)
        const model = {
          id: this.eventId,
          uid: users
        }
        if(user[0].uid.length == 0){
          this.newsEventService.registerUserEvent(model);
        }
        for (let i = 0; i < user[0].uid.length; i++) {
          if (user[0].uid[i] === this.currentUser?.uid) {
            break;
          } else {
            this.newsEventService.registerUserEvent(model);
            // this.newsEventService.updateRegisteredUserEvent(model);
            const smsModel = new SMSModel();
            smsModel.body = "You have registered to an event \n";
            smsModel.body += `The event:- ${this.event.eventTitle}\n`;
            smsModel.body += `Event Date:- ${this.event.eventStartDate} - ${this.event.eventEndDate}\n`;
            smsModel.body += `Start Time ${this.event.eventStartTime} - ${this.event.eventEndTime}`;
            smsModel.from = "";
            smsModel.phoneNumberList = [`${this.currentUser.cellPhone}`]

            this.smsService.sendSMS(smsModel).subscribe((res) =>{
            })

            const emailModel = new CustomAdminEmailModel();
            emailModel.subject = `You have registered to an Event`;
            emailModel.isNationApp = this.isNationApp;
            emailModel.body = `The event:- ${this.event.eventTitle} <br/>` 
            emailModel.body += `Event Date:- ${this.event.eventStartDate} - ${this.event.eventEndDate}<br/>`;
            emailModel.body += `Start Time ${this.event.eventStartTime} - ${this.event.eventEndTime}`;
            emailModel.emailList = [`${this.currentUser.email}`]
            // custom send email used
            emailModel.host = "smtp.gmail.com",
            emailModel.adminEmail = "mkproject.app@gmail.com",
            emailModel.appPassword = "ytklyidomvqfmtky",

            this.emailService.sendCustomEmail(emailModel).subscribe((res) =>{
            })
          }
        }

      } else {

        const users = [];
        users.push(this.currentUser.uid)
        const model = {
          id: this.eventId,
          uid: users
        }

        this.newsEventService.registerUserEvent(model);
      }

      this.eventGlobal.unsubscribe();
    })
    this.registrationConfirmed = false;
  }
  confirmCancelRegistration(){
    this.registrationCancelled = true;
  }

  viewSubscriberProfile(userModel: any) {
    if (this.isAdmin) {
      this.router.navigate(['/admin/client-detail/', userModel.uid]);
    } else if(this.currentUser.role === 'Employee') {
      this.router.navigate(['/employee/client-detail/', userModel.uid])
    }
  }

  // cancelRegister() {
  //   const userId = this.currentUser.uid;
  //   this.newsEventService.deleteRegisteredUser(this.eventId, userId)
  //   this.registrationCancelled = false;
  //   this.cancelRegistration = false;
  // }

  async cancelRegister() {
    const userId = this.currentUser.uid;
    this.registrationCancelled = false;
    await this.newsEventService.deleteRegisteredUser(this.eventId, userId);
    this.cancelRegistration = false;
  }
  
  sendPushNotificationToOwner(title,content){
    let conditions=["blogComment"]
    if(this.notifSettings && this.helperService.checkNotificationForOwner(this.notifSettings,conditions)){
      let notification = new NotificationModel()
      notification.title=title
      notification.body=content
      this.pushNotificationsService.sendNotificationByRole(notification,'Admin')
    }
  }

  checkIfReactionGiven() {
    return this.event?.reactions?.likes?.userId.includes(this.currentUser.uid) || false;
  }

  reactToPost(postId) {
    const postIndex = this.currentUserEvents.findIndex(post => post.id === postId);
  
    if (postIndex === -1) { return; } // Post not found
  
    const currentPost = this.currentUserEvents[postIndex];
    const reactionLikes = currentPost.reactions.likes;
    const userId = this.currentUser.uid;
    const userFullName = `${this.currentUser.GivenName || this.currentUser.FirstName} ${this.currentUser.LastName}`;
  
    const hasReacted = this.checkIfReactionGiven();
    if (hasReacted) {
      // User has already liked, so unlike
      reactionLikes.count -= 1;
      reactionLikes.userId = reactionLikes.userId.filter(id => id !== userId && id !== userFullName);
      this.eventLiked = false;
    } else {
      // User has not liked, so like
      reactionLikes.count += 1;
      reactionLikes.userId.push(userId, userFullName);
      this.eventLiked = true;
      const notification = {
        timeStamp: new Date(),
        owner: this.event.createdById,
        type: 'postReaction',
        refrenceId: postId,
        createdById: userId,
        createdByFullName: userFullName,
      };
      this.notificationsService.addNotification(notification);
      if (!this.isAdmin) {
        const title = `${this.eventsName} liked`;
        const content = `${notification.createdByFullName} liked your post`;
        this.sendPushNotificationToOwner(title, content);
      }
    }
  
    this.commentsService.updateReactionOfApost(currentPost, 'event');
  }

  likeComment(postId, commentId) {
    const post = this.currentUserEvents.find(post => post.id === postId);
    if (!post) { return; } // Post not found
  
    const comment = post.comments.find(comment => comment.id === commentId);
    if (!comment) { return; } // Comment not found in the specified post
  
    const userId = this.currentUser.uid;
    const userFullName = `${this.currentUser.GivenName || this.currentUser.FirstName} ${this.currentUser.LastName}`;
  
    if (comment.likes.userId.includes(userId)) { // User has already liked, so unlike
      comment.likes.count -= 1;
      comment.likes.userId = comment.likes.userId.filter(id => id !== userId && id !== userFullName);
    } else { // User has not liked, so like
      comment.likes.count += 1;
      comment.likes.userId.push(userId, userFullName);
    }
  
    // Update comments
    this.commentsService.updateComments(post, 'event');
  }
  


  openDeleteToast(){
    this.toast({html: 'Comment Deleted!', classes: 'red', type: 'failure', redirectBack: false});
  }

  showOnMap(){
    this.showMap = true;
  }
  donotShowMap(){
    this.showMap = false;
  }
}
