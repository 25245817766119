<div class="modal modal-open " >
    <div class="modal-box  z-[2300] rounded-none max-w-full overflow-x-hidden ">
      <div class="flex justify-between py-4 " >
        <h5
        class="text-xl font-medium leading-normal text-neutral-800 dark:text-neutral-200"
        id="customizeCard">
        Template Name preview
        </h5>
        <button
        type="button"
        (click)="closePreviewModal()"
        >
          <svg class="w-6 h-6 " xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z"/></svg>
        </button>
      </div>
      <div class="flex space-x-4 py-2 justify-center mx-auto w-full">
        <div [ngClass]="{'bg-customTeal stroke-white': preview == 'web'}" class="p-4 rounded-md">
          <svg (click)="preview = 'web'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"  class="w-8 h-8 cursor-pointer " [ngClass]="{'stroke-white': preview == 'web'}">
            <path stroke-linecap="round" stroke-linejoin="round" d="M9 17.25v1.007a3 3 0 0 1-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0 1 15 18.257V17.25m6-12V15a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 15V5.25m18 0A2.25 2.25 0 0 0 18.75 3H5.25A2.25 2.25 0 0 0 3 5.25m18 0V12a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 12V5.25" />
          </svg>
        </div>
        <div [ngClass]="{'bg-customTeal stroke-white': preview == 'mobile'}" class="p-4 rounded-md">
          <svg (click)="preview = 'mobile'"  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 cursor-pointer" [ngClass]="{'stroke-white': preview == 'mobile'}">
            <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
          </svg>
        </div>
        </div>
   <div class="w-full flex justify-center">
    <div class=" shadow-md rounded-sm border-2 border-solid  border-gray-200"  [ngClass]="{'w-full': preview == 'web', 'max-w-sm max-h-screen overflow-y-scroll': preview == 'mobile' }">
  
        <!-- <img src="https://cdn.shopify.com/theme-store/1578a56z1j9cfiirdwkk3s7jluft.jpg" alt="" class="rounded-md w-full  "> -->
     <!-- Blog Posts -->
        <h1 class="text-2xl font-semibold text-center uppercase ">Blog Post</h1>
      <div class="flex flex-wrap gap-x-8 w-full mx-auto py-10 px-16 max-w-max ">
          <!-- first blog -->
          <div class="w-[680px] flex flex-col space-y-2">
            <!-- img -->
            <div class="w-full">
              <img src="https://images.unsplash.com/photo-1706059924488-29e13a25b07b?q=80&w=1469&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" class="object-cover w-full h-44" alt="">
            </div>
            <p class="text-sm text-customTeal font-bold">Date and time</p>
            <h1 class="text-xl font-semibold ">
              Title of blog post
            </h1>
            <p class="text-base text-gray-600 text-justify">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum ad maxime inventore commodi optio dolores dolor molestias harum, incidunt non, quasi necessitatibus temporibus, labore voluptatum totam hic ex numquam nesciunt.</p>

          </div>
          <!-- Next two blogs -->
          <div class="flex flex-col gap-y-5">
            <div class="w-[380px] flex gap-x-2">
              <!-- img -->
              <div class="w-full">
                <img src="https://images.unsplash.com/photo-1706079535076-b46d3b4de316?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" class="object-cover w-56 h-32" alt="">
              </div>
             <div class="flex flex-col space-y-2">
              <p class="text-sm text-customTeal font-bold">Date and time</p>
              <h1 class="text-xl font-semibold ">
                Title of blog post
              </h1>
              <p class="text-base text-gray-600 text-justify">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum ad maxime inventore commodi </p>
  
             </div>
            </div>
            <div class="w-[380px] flex gap-x-2">
              <!-- img -->
              <div class="w-full">
                <img src="https://images.unsplash.com/photo-1705783475180-4ee9bf240cba?q=80&w=1471&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" class="object-cover w-56 h-32" alt="">
              </div>
              <div class="flex flex-col space-y-2">
                <p class="text-sm text-customTeal font-bold">Date and time</p>
                <h1 class="text-xl font-semibold">
                  Title of blog post
                </h1>
                <p class="text-base text-gray-600 text-justify">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum ad maxime inventore c</p>
               </div>
            </div>

          </div>
      </div>


      <!-- Event post -->
      <h1 class="text-2xl font-semibold text-center uppercase ">Events</h1>
      <div class="flex gap-x-5 max-w-max mx-auto py-10">
        <div class="w-[380px]">
          <img src="https://images.unsplash.com/photo-1706018167918-a1b9ef373eac?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D  " alt="" class="h-60 w-full object-cover">
        </div>
        <div class="flex  space-y-4 w-[680px] space-x-4">
          <div class="flex flex-col bg-customTeal py-6 px-4 w-60 space-y-2">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" class="w-10 h-10 stroke-white">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
            </svg>
            
            <h1 class="text-2xl text-white">Friday</h1>
            <h1 class=" text-white">Jan, 18 2024</h1>
            <hr>
            <h1 class=" text-white">9:30 - 2:20</h1>


          </div>
          <div class="flex flex-col space-y-4">
            <h1 class="text-xl text-customTeal font-semibold ">
              Title of blog post
            </h1>
            <p class="text-base text-gray-600 text-justify">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt quidem nobis numquam fugit excepturi aliquam! Blanditiis repellat provident, odio iste eaque fugit mollitia harum ipsa, omnis quas suscipit eligendi quam? </p>

          </div>
        </div>
      
      </div>

      <!-- Product -->
      <h1 class="text-2xl font-semibold text-center uppercase ">Products</h1>
      <div class="flex flex-col gap-5 mx-auto max-w-max py-10">
       <div class="flex flex-wrap gap-5">
        <div class="w-[380px]">
          <img src="https://images.unsplash.com/photo-1523275335684-37898b6baf30?q=80&w=1399&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" class="h-80 w-full object-cover">
        </div>
        <div class="w-[680px]">
          <img src="https://images.unsplash.com/photo-1706018167918-a1b9ef373eac?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" class="h-80 w-full object-cover">
        </div>
       </div>
      <div class="flex flex-wrap gap-5">
        <div class="w-[680px]">
          <img src="https://images.unsplash.com/photo-1581235720704-06d3acfcb36f?q=80&w=1480&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" class="h-80 w-full object-cover">
        </div>
        <div class="w-[380px]">
          <img src="https://images.unsplash.com/photo-1485955900006-10f4d324d411?q=80&w=1472&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D " alt="" class="h-80 w-full object-cover">
        </div>
      </div>
      </div>

      <!-- Service -->
      <h1 class="text-2xl font-semibold text-center uppercase ">service</h1>
      <div class="flex flex-wrap gap-5 py-10 justify-center ">
        <div class="w-[345px] flex flex-col space-y-2">
          <div class="w-full">
            <img src="https://images.unsplash.com/photo-1706018167918-a1b9ef373eac?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D  " alt="" class="h-44 w-full object-cover">
          </div>
          <div>
            <h1 class="text-xl font-semibold ">
              Title of blog post
            </h1>
          </div>
        </div>
        <div class="w-[345px] flex flex-col space-y-2">
          <div class="w-full">
            <img src="https://images.unsplash.com/photo-1706018167918-a1b9ef373eac?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D  " alt="" class="h-44 w-full object-cover">
          </div>
          <div>
            <h1 class="text-xl font-semibold ">
              Title of blog post
            </h1>
          </div>
        </div>
        <div class="w-[345px] flex flex-col space-y-2">
          <div class="w-full">
            <img src="https://images.unsplash.com/photo-1706018167918-a1b9ef373eac?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D  " alt="" class="h-44 w-full object-cover">
          </div>
          <div>
            <h1 class="text-xl font-semibold ">
              Title of Service
            </h1>
          </div>
        </div>
      </div>
     </div>
   </div>
     <div class="modal-action">
      <!-- if there is a button in form, it will close the modal -->
      <button
      type="button"
      class="inline-block rounded bg-white text-customTeal border-2 border-customTeal px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-customTealborder-customTeal transition duration-150 ease-in-out hover:border-customTeal-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-customTealborder-customTeal-600 focus:border-customTeal-600 focus:text-customTealborder-customTeal-600 focus:outline-none focus:ring-0 active:border-customTeal-700 active:text-customTealborder-customTeal-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
      (click)="closePreviewModal()"
      data-te-ripple-init
      data-te-ripple-color="light">
      Close Preview
      </button>
      
    </div>
      </div>
  </div>