import { NotificationsService } from '../../../../services/firebase/notifications.service';
import { PostsService } from 'src/app/services/firebase/posts.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { Component, OnInit,ViewChild, AfterViewInit, ElementRef, Input, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FCMPushNotificationsService } from 'src/app/services/firebase/FCMPushNotifications.service';
import { PermissionService } from 'src/app/services/firebase/permission.service';
import { format, isSameYear } from 'date-fns';
import { HelperService } from 'src/app/services/helper/helper';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { ProductsServiceMenuService } from 'src/app/services/api/data.service';
import { NotificationModel } from 'src/app/models/Messages.Model';
@Component({
  selector: 'app-view-blog',
  templateUrl: './view-blog.component.html',
  styleUrls: ['./view-blog.component.css']
})
export class ViewBlogComponent implements OnInit {

  @ViewChild('commentsContainer') private commentsContainer: ElementRef

  postId: any;
  post: any;
  isMobile = false;
  currentUser: any;
  editPostForm: UntypedFormGroup;
  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;

  commentOnPost: UntypedFormGroup;
  selectedPost: any;
  selectedComment: any;
  currentUserPosts: any = [];
  // modal
  deleteModalOpen = false;
  commentDeleteModalOpen = false;
  isAdmin = false;
  isUser = false;

  perm: any;
  permission: any;
  commentsCount = 3;
  showAllMessages = false;
  @Input() modelToPreview: any;
  notifSettings;
  blogsName;
  postLiked=false

  permMember:any;
  permSocial:any;
  permPES:any;
  permSpecial:any;
  permAnalytics:any;
  calledFrom: any;

  editBlogGlobal:any;
  postGlobalforUnsub: any

  @ViewChild('dataContainer') messageContainer: ElementRef;

  constructor(private route: ActivatedRoute,
              private fb: UntypedFormBuilder,
              private location: Location,
              private postsService: PostsService,
              private router: Router,
              private authService: AuthService,
              private notificationsService: NotificationsService,
              private pushNotificationsService: FCMPushNotificationsService,
              private permissionService: PermissionService,
              private helperervice: HelperService,
              private appSettingsService: AppSettingsService,
              private productsServiceMenuService: ProductsServiceMenuService
              ) {
    if (typeof window['cordova'] !== 'undefined') {
      this.isMobile = true;
    }
   }

  loadData(data) {
        // console.log(this.messageContainer,'message continer')
        // this.messageContainer.nativeElement.innerHTML = data;
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.currentUser = this.authService.getGlobalUser();
    if (this.currentUser){
      if (this.currentUser.role === 'Admin'){
        this.isAdmin = true;
      }
      if (this.currentUser.role === 'User') {
        this.isUser = true;
      }
      if (this.currentUser.role === 'Employee') {
        this.permissionService.getPermissionByEmployeeId(this.currentUser.uid).valueChanges().subscribe(perm => {
          if (perm.length > 0){
            this.perm = perm[0];
            //this.permission = this.perm.permission[0];
            this.permMember = this.perm.permission[0]
            this.permPES = this.perm.permission[1]
            this.permAnalytics = this.perm.permission[2];
            this.permSocial = this.perm.permission[3];
            this.permSpecial = this.perm.permission[4];
          }
        });
      }

        this.appSettingsService.getPushNotificationSettingsList().valueChanges().subscribe(x=>{
          if(x && x.length > 0){
            this.notifSettings=x[0]
          }
        })
  
    }
    this.productsServiceMenuService.blogsName.subscribe((blogsname) => {
      this.blogsName = blogsname;
    });

    this.route.paramMap.subscribe(params => {
      this.postId = params.get('id');
    });
    this.commentOnPost = this.fb.group({
      comment: ['', [Validators.required]]
    });

    this.editPostForm = this.fb.group({
      message: ['', [Validators.required]],
    postTitle: ['', [Validators.required]]
    });
    if (this.postId === 'emptyblog1'){
      this.post = { postTitle: 'What is a blog?',
                    message: `An online journal where you can share
                    and record your ideas, activities, beliefs, thoughts…..
                    this is your world!`,
                    id: 'emptyblog',
                    commentable: false,
                    // timeStamp: Math.round(+new Date() / 1000),
                    comments: [],
                    reactions: {
                      likes: {count: 0},
                      userId: [],
                      usernames: []
                    },
                    readingTime: 1
                    };
    }
    if (this.postId === 'emptyblog2'){
      this.post = { postTitle: 'How to write a blog?',
                    message: `Write from the heart.
                    Think of your topic,
                    your audience and your message!`,
                    id: 'emptyblog',
                    commentable: false,
                    // timeStamp: Math.round(+new Date() / 1000),
                    comments: [],
                    reactions: {
                      likes: {count: 0},
                      userId: [],
                      usernames: []
                    },
                    readingTime: 1
                    };
    }
    if (!this.postId){
      if(this.modelToPreview){
        this.post = this.modelToPreview;
      }
    }
    this.postGlobalforUnsub = this.postsService.getPostById(this.postId).valueChanges().subscribe(post => {
      if (post && post.length > 0 ){
        this.post = post[0];
        this.post.opened = this.post.opened === '' ? this.post.opened : [];

        if(this.modelToPreview){
          this.post = this.modelToPreview;
        }
        this.loadData(this.post.message)
        this.currentUserPosts = post;
        if (this.currentUser && this.checkIfReactionGiven(this.postId)){
          this.postLiked=true
        }
        this.editPostForm.setValue({
          message: this.post.message ? this.post.message : '',
          postTitle: this.post.postTitle ? this.post.postTitle : ''
        });

        if ( this.currentUser && this.currentUser.role == 'User') {
          this.post.opened.push(this.currentUser.uid);
 
          let postCopy = this.post;
          this.editBlogGlobal = this.postsService.editPost(postCopy).then((res) =>{
              this.editBlogGlobal.unsubscribe();
          })
        }
      }
      this.postGlobalforUnsub.unsubscribe();
    });

  }
  ngOnChanges(changes: SimpleChanges): void {
    this.post = this.modelToPreview
  }
  backClicked(){
    const state = this.productsServiceMenuService.getCalledFromData()
    if(state =='onBoarding'){
      this.productsServiceMenuService.setOnboardingSectionData('Blog')
      this.router.navigate(['/admin/onboarding-page']);
    }
  this.location.back();
  }
  getUrl(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);

    if (match && match[2].length === 11) {
      const youtubeUrl = 'https://www.youtube.com/embed/' + match[2];
      return youtubeUrl;
    } else {
        return url;
    }
  }

  checkIfReactionGiven(id) {
    const currentPost = this.currentUserPosts.find(post => post.id === id);
    if (currentPost && currentPost.reactions.likes){
      if (currentPost.reactions.likes.userId.findIndex(userId => userId === this.currentUser.uid) >= 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  sendPushNotificationToOwner(title,content){
    let conditions=["blogComment"]
    if(this.notifSettings && this.helperervice.checkNotificationForOwner(this.notifSettings,conditions)){
      let notification = new NotificationModel()
      notification.title=title
      notification.body=content
      this.pushNotificationsService.sendNotificationByRole(notification,'Admin')
      // this.pushNotificationsService.sendNotificationToAllTheMembers(title,content,'Owner_Notification')      
    }
  }

  reactToPost(postId) {
    if (this.checkIfReactionGiven(postId)) {
      const currentPostindex = this.currentUserPosts.findIndex(post => post.id === postId);
      this.currentUserPosts[currentPostindex].reactions.likes.count -= 1;
      this.currentUserPosts[currentPostindex].reactions.likes.userId.pop(this.currentUser.uid);
      this.currentUserPosts[currentPostindex].reactions.likes.userId.pop(`${this.currentUser.GivenName || this.currentUser.FirstName} ${this.currentUser.LastName}`);
      this.postsService.updateReactionOfApost(this.currentUserPosts[currentPostindex]);
      this.postLiked = false

    } else {
      const currentPostindex = this.currentUserPosts.findIndex(post => post.id === postId);
      this.postLiked = true
      this.currentUserPosts[currentPostindex].reactions.likes.count += 1;
      this.currentUserPosts[currentPostindex].reactions.likes.userId.push(this.currentUser.uid);
      this.currentUserPosts[currentPostindex].reactions.likes.userId.push(`${this.currentUser.GivenName || this.currentUser.FirstName} ${this.currentUser.LastName}`);
      this.postsService.updateReactionOfApost(this.currentUserPosts[currentPostindex]);

      const notif = {
          timeStamp: new Date(),
          owner: this.post.createdById,
          type: 'postReaction',
          refrenceId: postId,
          createdById: this.currentUser.uid,
          createdByFullName: this.currentUser.GivenName + ' ' + this.currentUser.LastName
        };

      this.notificationsService.addNotification(notif);
      if (this.isAdmin === false){
        let title = 'Blog liked'
        let content = `${notif.createdByFullName} liked your blog post`
        this.sendPushNotificationToOwner(title,content)
      }
    }

  }

  likeComment(postId, commentId) {
    const currentPostindex = this.currentUserPosts.findIndex(post => post.id === postId);
    const currentComment = this.currentUserPosts[currentPostindex].comments.findIndex(comment => comment.id === commentId);
    if (this.currentUserPosts[currentPostindex].comments[currentComment].likes.userId.findIndex(userId => userId ===
      this.currentUser.uid) >= 0) {
      this.currentUserPosts[currentPostindex].comments[currentComment].likes.count -= 1;
      this.currentUserPosts[currentPostindex].comments[currentComment].likes.userId.pop(this.currentUser.uid);
      this.currentUserPosts[currentPostindex].comments[currentComment].likes.userId.pop(`${this.currentUser.GivenName || this.currentUser.FirstName} ${this.currentUser.LastName}`);
      this.postsService.updateComments(this.currentUserPosts[currentPostindex]);

    } else {
      this.currentUserPosts[currentPostindex].comments[currentComment].likes.count += 1;
      this.currentUserPosts[currentPostindex].comments[currentComment].likes.userId.push(this.currentUser.uid);
      this.currentUserPosts[currentPostindex].comments[currentComment].likes.userId.push(`${this.currentUser.GivenName || this.currentUser.FirstName} ${this.currentUser.LastName}`);

      this.postsService.updateComments(this.currentUserPosts[currentPostindex]);

    }

  }

  archivePost() {
    this.closeDeleteModal();
    this.postsService.archivePost(this.post.id).then(() => {
    this.toast({html: `${this.blogsName}' deleted successfully.`, type: 'failure', redirectBack: true});
    });
  }
  openDeleteModal(){
    this.deleteModalOpen = true;
  }
  closeDeleteModal(){
    this.deleteModalOpen = false;
  }

  navigateEditBlog(post){
    if(this.isAdmin){
      this.router.navigate(['/admin/edit-blog', post.id])
    }else{
      this.router.navigate(['/employee/edit-blog', post.id])
    }
  }
  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
      if (obj.redirectBack){
        this.router.navigate(['/admin/blogs']);
      }
    }, 2000);
  }

  formatDate(timestamp){
    if (timestamp){
      if(!((new Date(timestamp)).getTime() > 0)){
        const date = new Date(timestamp.toDate());
        const today = new Date();
        if (isSameYear(date, today)){
          return format(date, 'MMM d, h:mm a');
        } else {
          return format(date, 'MMM d yyyy, h:mm a');
        }
      }
      else{
        return timestamp;
      }
    }
  }

  scrollToComments(): void {
    try {
        this.commentsContainer.nativeElement.scrollIntoView({behavior:"smooth"})
    } catch(err) {
      console.log(err)
     }                 
}
openDeleteToast(){
  this.toast({html: 'Comment Deleted!', classes: 'red', type: 'failure', redirectBack: false});
}
}
