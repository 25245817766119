import { Component, Input, OnInit } from '@angular/core';
import { Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { Output, EventEmitter } from '@angular/core';
import{ ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-crop-image',
  templateUrl: './crop-image.component.html',
  styleUrls: ['./crop-image.component.css']
})
export class CropImageComponent implements OnInit {

    imageChangeEvt: any = "";
    cropImgPreview: any;
    imageChangedEvent: any = '';
    croppedImage: any = '';
    canvasRotation = 0;
    rotation = 0;
    scale = 1;
    showCropper = false;
    containWithinAspectRatio = false;
    transform: ImageTransform = {};
    zoomScale = 10;
    createModalOpen = true;
    @Input() theImage = '';
    @Input() useBase64 = false;
    @Input() comingFrom: string;

    @Input() aspectRatio = '';
    @Output() returnCroppedImage = new EventEmitter<string>();
    @Output() closeCropModal = new EventEmitter<string>();
    
    constructor(private cdRef: ChangeDetectorRef) { }

    ngOnInit(): void {
    }
    fileChangeEvent(event: any): void {
        this.theImage = event;
    }

    imageCropped(event: ImageCroppedEvent) {
        this.convertBlobToBase64(event.blob).then(base64String => {
            this.croppedImage = base64String;
        }).catch(error => console.error('Error converting Blob to Base64:', error))            
        this.cdRef.detectChanges();  
    }
    imageLoaded() {
        this.showCropper = true;
    }

    cropperReady(sourceImageDimensions: Dimensions) {
    }

    loadImageFailed() {
    }

    rotateLeft() {
        this.canvasRotation--;
        this.flipAfterRotate();
    }

    rotateRight() {
        this.canvasRotation++;
        this.flipAfterRotate();
    }

    flipAfterRotate() {
        const flippedH = this.transform.flipH;
        const flippedV = this.transform.flipV;
        this.transform = {
            ...this.transform,
            flipH: flippedV,
            flipV: flippedH
        };
    }

    flipHorizontal() {
        this.transform = {
            ...this.transform,
            flipH: !this.transform.flipH
        };
    }

    flipVertical() {
        this.transform = {
            ...this.transform,
            flipV: !this.transform.flipV
        };
    }

    resetImage() {
        this.scale = 1;
        this.rotation = 0;
        this.canvasRotation = 0;
        this.transform = {};
    }

    zoomOut() {
            if(this.scale > 0.11){
                this.zoomScale-=10;
                this.scale -= .1;
                this.transform = {
                    ...this.transform,
                    scale: this.scale
                };
            }

        }

    zoomIn() {
        this.zoomScale+=10;
        if(this.scale < 3.1){
            this.scale += .1;
            this.transform = {
                ...this.transform,
                scale: this.scale
            };
        }
    }

    zoomFromRange(){
        this.scale =this.zoomScale * 0.1;
        this.transform = {
            ...this.transform,
            scale: this.scale
        };
    }

    toggleContainWithinAspectRatio() {
        this.containWithinAspectRatio = !this.containWithinAspectRatio;
    }

    updateRotation() {
        this.transform = {
            ...this.transform,
            rotate: this.rotation
        };
    }
    closeCroppingModal(){
        this.closeCropModal.emit();
    }
    handleCroppedFile(){
        this.returnCroppedImage.emit(this.croppedImage);
    }

    convertBlobToBase64(blob: Blob): Promise<string> {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);  // Read blob as data URL
          reader.onloadend = () => {
            resolve(reader.result as string);  // Resolve the Base64 string
          };
          reader.onerror = error => reject(error);
        });
      }
  }

