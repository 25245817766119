import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {CdkDragDrop, CdkDrag, CdkDropList, moveItemInArray} from '@angular/cdk/drag-drop';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';

@Component({
  selector: 'app-rearrange-cards',
  templateUrl: './rearrange-cards.component.html',
  styleUrls: ['./rearrange-cards.component.css'],

})
export class RearrangeCardsComponent implements OnInit {
  @Input() modalOpen;
  @Output() closeModal = new EventEmitter<string>();
  pageSections: any;
  elements = []
  position: number;
  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  defaultThemeColor = '#077373'

  sortedCard = []
  constructor(private settingsService: AppSettingsService,) { 
    this.getSettings()
  }


  ngOnInit(): void {
    
  }

  closeRearrangeModal(){
    this.closeModal.emit();

  }
  saveSettings(){

    for (const element of this.elements) {
      if(element.name == 'Login/Signup' && this.pageSections ){
          if(this.pageSections.loginCard){
            this.pageSections.loginCard={
              backgroundColor: this.pageSections.loginCard.backgroundColor , 
              textColor: this.pageSections.loginCard.textColor,
              boxWidth: this.pageSections.loginCard.boxWidth,
              boxHeight: this.pageSections.loginCard.boxHeight,
              borderRadius: this.pageSections.loginCard.borderRadius,
              backgroundImage: this.pageSections.loginCard.backgroundImage,
              position: element.position
            }
          }else{
            this.pageSections.loginCard = {
              backgroundColor: this.pageSections.themeColor? this.pageSections.themeColor: this.defaultThemeColor,
              textColor: '#FFFFFF',
              borderRadius: 12,
              boxWidth: 160,
              boxHeight : 160,
              backgroundImage : '', 
              position: element.position
            }
          }
      

      }else {
           this.pageSections.appSections.forEach((section:any) => {
   
            if(element.name == section.name ){
              if(section && section.cardDetail){
                section.cardDetail ={
                  backgroundColor: section.cardDetail.backgroundColor, 
                  textColor: section.cardDetail.textColor,
                  boxWidth: section.cardDetail.boxWidth,
                  boxHeight: section.cardDetail.boxHeight,
                  borderRadius: section.cardDetail.borderRadius,
                  backgroundImage: section.cardDetail.backgroundImage,
                  position : element.position
                }
              }else if(!section.cardDetail && this.pageSections.defaultCardView){
                section.cardDetail ={
                  backgroundColor: this.pageSections.defaultCardView.backgroundColor, 
                  textColor: this.pageSections.defaultCardView.textColor,
                  boxWidth: this.pageSections.defaultCardView.boxWidth,
                  boxHeight: this.pageSections.defaultCardView.boxHeight,
                  borderRadius: this.pageSections.defaultCardView.borderRadius,
                  backgroundImage: this.pageSections.defaultCardView.backgroundImage,
                  position : element.position
                }
              }
              else{
                section.cardDetail = {
                  backgroundColor: this.pageSections.themeColor? this.pageSections.themeColor: this.defaultThemeColor,
                  textColor: '#FFFFFF',
                  borderRadius: 12,
                  boxWidth: 160,
                  boxHeight : 160,
                  backgroundImage : '', 
                  position: element.position
                }
              }
            }
   
      });

    }
  }

  this.updateCard();
  }
  getSettings() {
    this.settingsService.getAppSettingsList().valueChanges().subscribe(hp => {
      if (hp) {
        if (hp.length > 0) {
          this.pageSections = hp[0];
          this.getCardPosition()
        }
    }})

  }

  getCardPosition(){
  if(this.pageSections.appSections.length > 0){
    let index = 1
    let length = 1
    const existingIndexLogin = this.elements.findIndex(element => element.name === 'Login/Signup');
    if (existingIndexLogin !== -1) {
      this.elements[existingIndexLogin] = {name:'Login/Signup', position: this.pageSections.loginCard.position}
    }else{
      if(this.pageSections.loginCard && this.pageSections.loginCard.position){
        this.elements.push({name: 'Login/Signup', position: this.pageSections.loginCard.position}) 
      }else{
          this.elements.push({name: 'Login/Signup', position: 0})
      }
    }
  
    this.pageSections.appSections.forEach(section => {
      // Append the element to the appendedArray
      if((section.originalName == 'Events' || section.originalName == 'Blogs'|| section.originalName == 'Products'|| section.originalName == 'Services'|| section.isCustomSection || section.originalName == 'About Us' || section.originalName == 'Footer' || section.originalName == 'faq')  && section.isEnabled){
        let existingIndex = this.elements.findIndex(element => element.name == section.name);
        if (existingIndex !== -1) {
          this.elements[existingIndex] = {name:section.name, position: section.cardDetail.position}
        }else{
          if(section.cardDetail && section.cardDetail.position){
            this.elements.push({name:section.name, position: section.cardDetail.position});
            length +=1
         }else if(section.cardDetail && section.cardDetail.position == 0){
          this.elements.push({name:section.name, position: 0});
          length +=1
         }
         else if(section.cardDetail){
           this.elements.push({name:section.name, position: index});
           index +=1 
         }else{
          section.cardDetail = {
            backgroundColor: this.pageSections.themeColor ? this.pageSections.themeColor: '#077373',
            textColor: '#FFFFFF',
            borderRadius : 12,
            boxWidth : 160,
            boxHeight : 160,
            backgroundImage : '',
            position: length
          }
          this.elements.push({name: section.name, position: length}) 
          length +=1
  
        }
        }
 
      }
    })
  }     
  this.elements = this.elements.slice().sort((a, b) => a.position - b.position)
}
  
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.elements, event.previousIndex, event.currentIndex);
    const movedItem = this.elements[event.previousIndex];

    // Update position property for all elements
    this.updatePositions();
  }
  updatePositions(): void {
    this.elements.forEach((element, index) => {
      element.position = index;
    });
  }
  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
   
    }, 2000);
  }
  updateCard(){
    this.settingsService.updateAppSettings(this.pageSections).then(x => {
      this.toast({ html: 'App Settings Successfully Saved!', classes: 'green' , type: 'success'});
      this.closeRearrangeModal()
    });
  }
}
