import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit } from '@angular/core';
import { queryReportService } from '../../../services/google-analytics/queryReport.service';
import { compareDesc, format } from "date-fns"
import moment from 'moment';
import { EmployeeServicesService } from 'src/app/services/firebase/employee-services.service';
import { environment } from 'src/environments/environment';
//import { monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip, Color, Label, MultiDataSet, } from 'ng2-charts';
import { PostsService } from 'src/app/services/firebase/posts.service';
import { NewsEventService } from 'src/app/services/firebase/news-event.service';
import { ProductsService } from 'src/app/services/firebase/products.service'
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule } from '@angular/forms';
import { HelperService } from 'src/app/services/helper/helper';
import { PageSectionDetail, PageDetail, TopTenPage, PageHeaderViews, PageNames, PageViews, ProductPage, ServicePage, BlogPage, EventPage } from 'src/app/models/AnalyticsModel'
import { CommonModule } from '@angular/common';
import { FilterPipe } from 'src/app/filter.pipe';
import { NgxChartsModule } from '@swimlane/ngx-charts';

declare var $: any;

@Component({
  selector: 'app-analytics-page',
  templateUrl: './analytics-page.component.html',
  styleUrls: ['./analytics-page.component.css'],
  standalone: true,
  imports: [CommonModule, FormsModule, FilterPipe, NgxChartsModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AnalyticsPageComponent implements OnInit {

  pageSection: PageSectionDetail;

  ispageClicked: boolean = true;
  isuserClicked: boolean = false;
  ischannelClicked: boolean = false;

  isServicePagesClicked: boolean = true;
  isBlogPagesClicked: boolean = false;
  isProductPagesClicked: boolean = false;
  iseventPagesClicked: boolean = false;
  pageDaily: boolean = false;
  pageWeekly: boolean = false;
  pageMonthly: boolean = false;
  filterByDatePage: boolean = false;

  topPageResult: any = []
  startDate: any
  endDate: any
  defaultStartDate: any
  defaultEndDate: any
  colors = [
    "#fcba03",
    "#fa8c5c",
    "#9fc934",
    "#60d17e",
    "#45afed",
    "#7c5cdb",
    "#ce5cdb",
    "#db5c97",
  ];
  background: any = []
  mergePages_arr = []
  merge_reportPageswznames = []
  pageDetailResult: any;
  pageDetails: any = [];
  serviceDetails: any = []
  blogDetails: any = []
  productDetails: any = []
  eventDetails: any = []
  pageViewsRes: any = [];
  page_views: any;
  bounce_rate: any;
  bounceRateRes: any = [];
  avgTimeOnPage: any;
  avgTimeOnPageRes: any = [];

  serviceName: any;
  blogName: any;
  eventName: any;
  productName: any
  pageChart: any = {}

  url: any = [];
  link: any = [];
  sessionDuration: any = []
  pageDetailFilteredRes: any = []
  urlSessionColor: any = []
  compiledPagePath1: any = []
  compiledPagePath2: any = []
  compiledPagePath3: any = []
  compiledPagePath4: any = []

  iteratingRow: any = {}
  // pages: any = []
  mergeTop10Pages = []
  top10PagesWzNames = []
  urlReduced: any = []
  serviceUrl: any = []
  serviceViewCount: any[] = []
  serviceBgColor: any = []
  blogUrl: any = []
  blogViewCount: any = []
  blogBgColor: any = []
  productUrl: any = []
  productViewCount: any = []
  productBgColor: any = []
  eventUrl: any = []
  eventViewCount: any = []
  eventBgColor: any = []
  isLoggedInChecked: any = true;
  loggedInName: any = "Subscribers"

  dateFilterActive: any;
  dateInvalid = false;
  hp_array: any = []
  serviceDisplayName: any;
  eventDisplayName: any;
  blogDisplayName: any;
  propertyId = environment['firebase'].propertyId
  productDisplayName: any;

  createCalenderForm: UntypedFormGroup;
  trackStartDate: any;
  trackEndDate: any;

  isEnabledEvents: any;
  isEnabledBlogs: any;
  isEnabledProducts: any;
  isEnabledServices: any;
  collection = null;

  isEnabledPage:any;
  enabledComponent:any;

  dataVBC: any[] = [];



  constructor(private qs: queryReportService, private fb: UntypedFormBuilder,
    private empService: EmployeeServicesService, private blogService: PostsService,
    private eventService: NewsEventService, private productService: ProductsService,
    private settingsService: AppSettingsService, private helperService: HelperService) {
    // monkeyPatchChartJsTooltip();
    // monkeyPatchChartJsLegend();

  }

  ngOnInit(): void {
    let currYear = (new Date()).getFullYear();
    let defaultStartDate = new Date(currYear, 1, 1)
    // let defaultStartDate = new Date(currYear, 8, 28)

    let defaultEndDate = moment().toDate()
    this.defaultEndDate = format(new Date(defaultEndDate), "yyyy-MM-dd")
    this.defaultStartDate = format(new Date(defaultStartDate), "yyyy-MM-dd")    


    setTimeout(
      () => {
        this.pageDetail(this.defaultStartDate, this.defaultEndDate, true);
        this.pageHeaderViews(this.defaultStartDate, this.defaultEndDate, true);
        this.pageSectionDetail(this.defaultStartDate, this.defaultEndDate, true);

      }, 500)

    this.getDisplayName();

    this.createCalenderForm = this.fb.group({
      analyticsStartDate: ['', [Validators.required]],
      analyticsEndDate: ['', [Validators.required]],

    });


  }

  hms(secondsp) {
    var sec_num = parseInt(secondsp, 10); // don't forget the second param
    var hours: any = Math.floor(sec_num / 3600);
    var minutes: any = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds: any = sec_num - (hours * 3600) - (minutes * 60);

    if (hours < 10) { hours = "0" + hours; }
    if (minutes < 10) { minutes = "0" + minutes; }
    if (seconds < 10) { seconds = "0" + seconds; }
    return hours + ':' + minutes + ':' + seconds;
  }

  toFixed(num, fixed) {
    fixed = fixed || 0;
    fixed = Math.pow(10, fixed);
    return Math.floor(num * fixed) / fixed;
  }


  isValidStartDate(startDate) {
    return startDate.includes("daysAgo") || startDate === "yesterday" || startDate === "today";
  }
  isValidEndDate(endDate) {
    return endDate.includes("daysAgo") || endDate === "yesterday" || endDate === "today";
  }

  isUndefined(result: any): boolean {
    return typeof result === 'undefined' || result === null || result.length === 0
  }

  isUndefinedEventDetails(): boolean { return this.eventDetails.length === 0; }

  isUndefinedServiceDetails(): boolean { return this.serviceDetails.length === 0; }

  isUndefinedBlogDetails(): boolean { return this.blogDetails.length === 0; }

  isUndefinedProductDetails(): boolean { return this.productDetails.length === 0; }

  chart(data: any, backgroundColor: any, label: any) {

    if (data === null || data === undefined) {
      data = [40, 50, 60]
      label = 'Loading ... '

    }

    this.pageChart = {
      "datasets": [
        {
          type: 'horizontalBar',
          label: 'Page Views',
          data: data,
          borderColor: backgroundColor,
          backgroundColor: backgroundColor
        },
      ],
      "labels": label,
      "options": {
        "indexAxis": 'y',
        "legend": {
          "text": "",
          "display": true,
        },
        "scales": {
          "xAxes": [{
            "ticks": {
              "beginAtZero": true,
              callback: function (value) { if (Number.isInteger(value)) { return value; } },
              stepSize: 50

            },
            "scaleLabel": {
              "display": true,
              "labelString": 'Views'

            },

          }],
          "yAxes": [{
            "ticks": {
              "min": "Monday",
              "max": "Sunday",

            },
            "scaleLabel": {
              "display": true,
              "labelString": 'Page'
            },

          }],

        },
        "elements": {
          "point": {
            "radius": 5,
            "hitRadius": 5,
            "hoverRadius": 10,
            "hoverBorderWidth": 2
          }
        }
      }
    }

    setTimeout(
      () => {
        this.pageChart
      }, 100
    )

    this.dataVBC = (data.length == 1) ? [400, 400] : [700, 400];
    // // Ensure data and setLables have the same length
    if (data.length !== label.length) {
      console.error('Data and labels lengths do not match');
    }

    this.dataVBC = [
      {
        name: 'View Count',
        series: data.map((value, index) => ({
          name: label[index],
          value: value ?? 0
        }))
      }
    ];

    this.url = []
    this.sessionDuration = []
    this.urlSessionColor = []
    // this.dataVBC = []
    data = []
    label = []
  }

  setPageTypeFlags(serviceFlag: boolean, blogFlag: boolean, productFlag: boolean, eventFlag: boolean): void {
    this.isServicePagesClicked = serviceFlag;
    this.isBlogPagesClicked = blogFlag;
    this.isProductPagesClicked = productFlag;
    this.iseventPagesClicked = eventFlag;
  }

  handlePageDetails(pageDetails: any[], viewCount: number[], bgColor: string[], url: string[]): void {
    setTimeout(() => {
      if (!(pageDetails.length === 0) && pageDetails.length > 0) {
        this.chart(viewCount, bgColor, [...new Set(url)]);
      } else {
        console.log(`No Pages found FOR ${pageDetails} DETAILS`);
      }
    }, 1500);
  }

  servicePageClicked() {
    this.setPageTypeFlags.call(this, true, false, false, false);
    this.handlePageDetails.call(this, this.serviceDetails, this.serviceViewCount, this.serviceBgColor, this.serviceUrl);
  }

  blogPageClicked() {
    this.setPageTypeFlags.call(this, false, true, false, false);
    this.handlePageDetails.call(this, this.blogDetails, this.blogViewCount, this.blogBgColor, this.blogUrl);
  }

  productPageClicked() {
    this.setPageTypeFlags.call(this, false, false, true, false);
    this.handlePageDetails.call(this, this.productDetails, this.productViewCount, this.productBgColor, this.productUrl);
  }

  eventPageClicked() {
    this.setPageTypeFlags.call(this, false, false, false, true);
    this.handlePageDetails.call(this, this.eventDetails, this.eventViewCount, this.eventBgColor, this.eventUrl);
  }

  validateDate(startDate, endDate) {
    this.startDate = !this.isValidStartDate(startDate) ? format(new Date(startDate), "yyyy-MM-dd") : startDate;
    this.endDate = !this.isValidEndDate(endDate) ? format(new Date(endDate), "yyyy-MM-dd") : endDate;

  }

  getPageType(path, result) {
    const services = ["service", "blog", "event", "product"];
    services.some((service) => {
      if (path.includes(service)) {
        switch (service) {
          case "service":
            this.collection = this.empService.getServiceById(result);
            break;
          case "blog":
            this.collection = this.blogService.getPostById(result);
            break;
          case "event":
            this.collection = this.eventService.getEventById(result);
            break;
          case "product":
            this.collection = this.productService.getProductById(result);
            break;
        }
      }
    })

  }

  pageDetail(startDate, endDate, LogInStatus) {
    this.validateDate(startDate, endDate)
    this.pageDetailFilteredRes = [];
    this.mergeTop10Pages = []
    this.top10PagesWzNames = []

    let api = LogInStatus ? this.qs.getTopTenPages(this.propertyId, this.startDate, this.endDate) :
      this.qs.getTopTenPagesNL(this.propertyId, this.startDate, this.endDate)

    api.subscribe((rep) => {
      if (rep && rep.Result && rep.Result.Rows && rep.Result.Rows.length > 0) {
        console.log("PAGE DETAIL GA4 --------------->", rep);

        const pageDetailFilteredRes = rep.Result.Rows;
        const path_wz_view = {};
        const counts = {};

        if (pageDetailFilteredRes) {
          pageDetailFilteredRes.forEach((row: any) => {
            const path = row.DimensionValues[0].Value;
            const pathTrimmed = path.includes("/user") ? path.replace("/user", "") : path;

            if (path_wz_view[pathTrimmed]) {
              path_wz_view[pathTrimmed] += Math.floor(parseInt(row.MetricValues[2].Value) / 60);
            } else {
              path_wz_view[pathTrimmed] = Math.floor(parseInt(row.MetricValues[2].Value) / 60);
            }
          });

          Object.keys(path_wz_view).forEach((key) => {
            const views = path_wz_view[key];
            this.mergeTop10Pages.push({ path: key, views });
          });

          this.mergeTop10Pages.sort((a, b) => b.views - a.views);

          this.mergeTop10Pages.forEach((obj) => {
            this.iteratingRow = obj.path;
            const n = this.iteratingRow.lastIndexOf('/');
            const result = this.iteratingRow.substring(n + 1);

            const services = ["service", "blog", "event", "product"];
            let collection = null;

            this.getPageType(obj.path, result)
            if (!(this.collection == null)) {
              this.collection
                .valueChanges()
                .subscribe((name) => {
                  if (name && name.length > 0) {
                    const pageName = name[0].serviceName || name[0].postTitle || name[0].eventTitle || name[0].productName;
                    const found = this.top10PagesWzNames.some((el) => el.path === pageName);

                    if (!found) {
                      this.top10PagesWzNames.push({ path: pageName, views: obj.views, link: obj.path });
                    }
                  } else {
                    console.log("THE PAGE IS NOT FOUND");
                  }
                });
              return true;
            } else {
              return false;
            }
          });

          this.top10PagesWzNames.sort((a, b) => b.views - a.views);
        } else {
          console.log("No page detail filter");
        }
      } else {
        console.log("Report is null, no data/analytics/hits received");
      }
    });
  }

  pageHeaderViews(startDate, endDate, LogInStatus) {
    this.validateDate(startDate, endDate)

    this.mergePages_arr = []
    this.pageViewsRes = [];
    this.avgTimeOnPageRes = [];
    this.bounceRateRes = [];
    this.topPageResult = []
    this.page_views = 0;
    this.avgTimeOnPage = 0;
    this.bounce_rate = 0;
    this.iteratingRow
    this.link = []
    this.merge_reportPageswznames = []

    let api = LogInStatus ? this.qs.getPageHeaderViews(this.propertyId, this.startDate, this.endDate) :
      this.qs.getPageHeaderViewsNL(this.propertyId, this.startDate, this.endDate)

    api.subscribe((rep) => {
      if (!rep || Object.keys(rep).length === 0) {
        console.log("Report is null, no data/analytics/hits received");
        return;
      }

      // Page Views - reports[0]
      this.pageViewsRes = rep.Result.Reports[0].Rows;
      console.log(this.pageViewsRes.length, "is the length property working");

      if (LogInStatus) {
        this.page_views = (this.pageViewsRes.length === 0 || this.pageViewsRes === null || typeof this.pageViewsRes === 'undefined') ? 0 : this.pageViewsRes[0].MetricValues[0].Value;
      } else {
        if (this.pageViewsRes.length === 0 || this.pageViewsRes === null || typeof this.pageViewsRes === 'undefined') {
          this.page_views = 0
        } else {
          let sum = 0
          this.pageViewsRes.forEach((row: any) => {
            sum += parseInt(row.MetricValues[0].Value)

          })
          this.page_views = sum;
        }
      }
      console.log(this.pageViewsRes, "PAGE VIEWS");

      // Average time on pages - reports[1]
      this.avgTimeOnPageRes = rep.Result.Reports[1].Rows;
      if (LogInStatus) {
        this.avgTimeOnPage = (this.avgTimeOnPageRes.length === 0 || this.avgTimeOnPageRes === null || typeof this.avgTimeOnPageRes === 'undefined') ? 0 : this.hms(Math.floor(this.avgTimeOnPageRes[0].MetricValues[0].Value));
      } else {
        if (this.avgTimeOnPageRes.length === 0 || this.avgTimeOnPageRes === null || typeof this.avgTimeOnPageRes === 'undefined') {
          this.avgTimeOnPage = 0
        } else {
          let sum = 0;
          let count = 0;
          this.avgTimeOnPageRes.forEach((row: any) => {
            sum += parseInt(row.MetricValues[0].Value)
            count += 1;

          })
          this.avgTimeOnPage = this.hms(Math.floor(sum / count))
        }
      }

      // Bounce rate - reports[2]
      this.bounceRateRes = rep.Result.Reports[2].Rows;
      if (LogInStatus) {
        this.bounce_rate = (!this.isUndefined(this.bounceRateRes)) ? this.toFixed((this.bounceRateRes[0].MetricValues[0].Value) * 100, 0) : 0;
      } else {
        if (!this.isUndefined(this.bounceRateRes)) {
          let sum = 0;
          let count = 0;
          this.bounceRateRes.forEach((row: any) => {
            sum += parseInt(row.MetricValues[0].Value)
            count += 1;

          })
          this.bounce_rate = this.toFixed((sum / count) * 100, 0)
        } else {
          this.bounce_rate = 0

        }
      }

      // Top viewed pages - reports[3]
      this.topPageResult = rep.Result.Reports[3].Rows;
      console.log("THIESE ARE FOR THE TOP VIEWED PAGES", this.topPageResult[0]);

      if (this.topPageResult && this.topPageResult.length > 0) {
        const path_wz_view = {};

        this.topPageResult.forEach((row: any) => {
          const path = row.DimensionValues[0].Value;
          const pathTrimmed = path.includes("/user") ? path.replace("/user", "") : path;

          path_wz_view[pathTrimmed] = (path_wz_view[pathTrimmed] || 0) + parseInt(row.MetricValues[1].Value);
        });

        const uniquePaths = Object.keys(path_wz_view);

        uniquePaths.forEach((pathKey) => {
          const obj = this.mergePages_arr.find((page) => page.path === pathKey);
          if (!obj) return;

          this.iteratingRow = obj.path;
          const n = this.iteratingRow.lastIndexOf('/');
          const result = this.iteratingRow.substring(n + 1);

          this.getPageType(obj.path, result)
   
          if (!this.collection) return false;

          this.collection
            .valueChanges()
            .subscribe((name) => {
              if (name && name.length > 0) {
                const pageName = name[0].serviceName || name[0].postTitle || name[0].eventTitle || name[0].productName;
                const topViewedPage = this.helperService.truncateHTML(pageName, "50");

                this.url.push(topViewedPage.length <= 20 ? topViewedPage : topViewedPage.substring(0, 16) + " ...");
                this.link.push(obj.path);
                this.sessionDuration.push(obj.views);

                const randNum = Math.floor(Math.random() * this.colors.length);
                this.urlSessionColor.push(this.colors[randNum]);

                this.merge_reportPageswznames.push({
                  path: topViewedPage,
                  views: obj.views,
                  link: obj.path,
                });

                const url = topViewedPage.length <= 20 ? topViewedPage : topViewedPage.substring(0, 16) + " ...";
                this.urlReduced.push({ [url]: obj.views });
              } else {
                // ${service.toUpperCase()}
                console.log(`THE PAGE IS NOT FOUND`);
              }
            });

          return true;

        });

        this.merge_reportPageswznames.sort((a, b) => b.views - a.views);
        console.log(this.urlReduced, "this is merged path and views");

        const result = Object.values(
          this.urlReduced.reduce((acc, { name, value }) => {
            acc[name] = acc[name] || { name, value: 0 };
            acc[name].value += value;
            console.log(acc);
            return acc;
          }, {})
        );

        console.log(result, "this is after redundant data merged");
        console.log("MERGE PAGES ARRAY", this.mergePages_arr, "THIS IS APPLIED WITH FILTERS");
        this.servicePageClicked();
      } else {
        console.log('No Pages found with more than 1 session.');
      }
    });

  }

  pageSectionDetail(startDate, endDate, LogInStatus) {
    this.validateDate(startDate, endDate)

    this.pageDetailResult = [];
    this.pageDetails = []
    this.serviceDetails = []
    this.productDetails = []
    this.blogDetails = [];
    this.eventDetails = [];


    let api = LogInStatus ? this.qs.getPageDetail(this.propertyId, this.startDate, this.endDate) :
      this.qs.getPageDetailNL(this.propertyId, this.startDate, this.endDate)

    api.subscribe((report) => {
      if (report && Object.keys(report).length > 0) {
        this.pageDetailResult = report.Result.Rows;
        console.log(report, "page detail result get ------------->")
        if (!this.isUndefined(this.pageDetailResult) && this.pageDetailResult.length > 0 && this.pageDetailResult) {
          this.compiledPagePath1 = [];   this.compiledPagePath2 = [];    this.compiledPagePath3 = [];  this.compiledPagePath4 = [];
          this.serviceDetails = [];
          // Linking to the graph - this.serviceDetails, blog, product, event
          // ***************************************************************
          this.pageDetailResult.forEach((row: any) => {
            this.serviceDetails = []
            this.eventDetails = []
            this.blogDetails = []
            this.productDetails = []
            let serviceName = []
            let blogName = []
            let eventName = []
            let productName = []
            let iteratingRow: any;
            this.serviceUrl = [];
            this.serviceViewCount = []
            this.serviceBgColor = []
            this.blogUrl = [];
            this.blogViewCount = []
            this.blogBgColor = []
            this.eventUrl = [];
            this.eventViewCount = []
            this.eventBgColor = []
            this.productUrl = [];
            this.productViewCount = []
            this.productBgColor = []
            iteratingRow = row.DimensionValues[0].Value;
            var n = row.DimensionValues[0].Value.lastIndexOf('/');
            var result = row.DimensionValues[0].Value.substring(n + 1);

            const handleService = (name) => {
              const found = this.compiledPagePath1.some((el) => el.name === name);
              if (!found) this.compiledPagePath1.push({
                "name": this.helperService.truncateHTML(name, "50"),
                "path": environment['firstNation'].website +
                  iteratingRow.substr(0, iteratingRow.lastIndexOf("/") + 1) + "...",
                "bounceRate": this.toFixed(row.MetricValues[0].Value, 2),
                "pageViews": row.MetricValues[1].Value,
                "avgTimeOnPage": this.hms(Math.floor(row.MetricValues[2].Value)),
              });
              console.log(this.compiledPagePath1, " this is the service page collector ")

              this.serviceUrl.push(name.length <= 20 ? name : name.substring(0, 16) + " ...");
              this.serviceViewCount.push(parseInt(row.MetricValues[1].Value));
              const randNum = Math.floor(Math.random() * this.colors.length);
              this.serviceBgColor.push(this.colors[randNum]);
            };

            const handleBlog = (name) => {
              const found = this.compiledPagePath2.some((el) => el.name === name);
              if (!found) this.compiledPagePath2.push({
                "name": this.helperService.truncateHTML(name, "50"),
                "path": environment['firstNation'].website +
                  iteratingRow.substr(0, iteratingRow.lastIndexOf("/") + 1) + "...",
                "bounceRate": this.toFixed(row.MetricValues[0].Value, 2),
                "pageViews": row.MetricValues[1].Value,
                "avgTimeOnPage": this.hms(Math.floor(row.MetricValues[2].Value)),
              });

              this.blogUrl.push(name.length <= 20 ? name : name.substring(0, 16) + " ...");
              this.blogViewCount.push(parseInt(row.MetricValues[1].Value));
              const randNum = Math.floor(Math.random() * this.colors.length);
              this.blogBgColor.push(this.colors[randNum]);
            };

            const handleEvent = (name) => {
              const found = this.compiledPagePath3.some((el) => el.name === name);
              if (!found) this.compiledPagePath3.push({
                "name": this.helperService.truncateHTML(name, "50"),
                "path": environment['firstNation'].website +
                  iteratingRow.substr(0, iteratingRow.lastIndexOf("/") + 1) + "...",
                "bounceRate": this.toFixed(row.MetricValues[0].Value, 2),
                "pageViews": row.MetricValues[1].Value,
                "avgTimeOnPage": this.hms(Math.floor(row.MetricValues[2].Value)),
              });

              this.eventUrl.push(name.length <= 20 ? name : name.substring(0, 16) + " ...");
              this.eventViewCount.push(parseInt(row.MetricValues[1].Value));
              const randNum = Math.floor(Math.random() * this.colors.length);
              this.eventBgColor.push(this.colors[randNum]);
            };

            const handleProduct = (name) => {
              const found = this.compiledPagePath4.some((el) => el.name === name);
              if (!found) this.compiledPagePath4.push({
                "name": this.helperService.truncateHTML(name, "50"),
                "path": environment['firstNation'].website +
                  iteratingRow.substr(0, iteratingRow.lastIndexOf("/") + 1) + "...",
                "bounceRate": this.toFixed(row.MetricValues[0].Value, 2),
                "pageViews": row.MetricValues[1].Value,
                "avgTimeOnPage": this.hms(Math.floor(row.MetricValues[2].Value)),
              });

              this.productUrl.push(name.length <= 20 ? name : name.substring(0, 16) + " ...");
              this.productViewCount.push(parseInt(row.MetricValues[1].Value));
              const randNum = Math.floor(Math.random() * this.colors.length);
              this.productBgColor.push(this.colors[randNum]);
            };

            if (iteratingRow.includes("service")) {
              this.empService.getServiceById(result).valueChanges().subscribe((name) => {
                if (name && name.length > 0) {
                  serviceName = name;
                  this.serviceName = this.helperService.truncateHTML(serviceName[0].serviceName, "50");
                  handleService(this.serviceName)
                  this.serviceDetails = [...new Set(this.compiledPagePath1)];
                }
                else {
                  console.log("      THIS SERVICE PAGE IS DELETED ")
                }
              })
            } else if (iteratingRow.includes("blog")) {
              this.blogService.getPostById(result).valueChanges().subscribe((name) => {
                if (name && name.length > 0) {
                  blogName = name;
                  this.blogName = this.helperService.truncateHTML(blogName[0].postTitle, "50")
                  handleBlog(this.blogName)
                  this.blogDetails = [...new Set(this.compiledPagePath2)];
                }
                else {
                  console.log("      THIS BLOG PAGE IS DELETED ")
                }
              })
            } else if (iteratingRow.includes("event")) {
              this.eventService.getEventById(result).valueChanges().subscribe((name) => {
                if (name && name.length > 0) {
                  eventName = name;
                  this.eventName = this.helperService.truncateHTML(eventName[0].eventTitle, "50")
                  handleEvent(this.eventName)
                  this.eventDetails = [...new Set(this.compiledPagePath3)]
                } else {
                  console.log("      THIS EVENT PAGE IS DELETED ")
                }
              })
            } else if (iteratingRow.includes("product")) {
              this.productService.getProductById(result).valueChanges().subscribe((name) => {
                if (name && name.length > 0) {
                  productName = name;
                  this.productName = this.helperService.truncateHTML(productName[0].productName, "50")
                  handleProduct(this.productName)
                  this.productDetails = [...new Set(this.compiledPagePath4)]
                } else {
                  console.log("      THIS PRODUCT PAGE IS DELETED ")
                }
              })
            }
          })
        } else {
          console.log('No page detail found.')
          this.serviceDetails = []
          this.productDetails = []
          this.blogDetails = []
          this.eventDetails = []
          this.pageDetails = []
        }
      } else {
        console.log("Report is null, no data/analytics/hits recieved")
      }
    });
  }

  getDisplayName() {

    this.settingsService.getAppSettingsList().valueChanges().subscribe((hp:any) => {
      if (hp && hp.length > 0) {
        const servicesSection = hp[0].appSections.find(el => el.originalName === "Services");
        const eventsSection = hp[0].appSections.find(el => el.originalName === "Events");
        const blogsSection = hp[0].appSections.find(el => el.originalName === "Blogs");
        const productsSection = hp[0].appSections.find(el => el.originalName === "Products");

        this.isEnabledServices = servicesSection?.isEnabled ;
        this.serviceDisplayName = servicesSection?.name || 'Services';
        this.isEnabledEvents = eventsSection?.isEnabled;
        this.eventDisplayName = eventsSection?.name || 'Events';
        this.isEnabledBlogs = blogsSection?.isEnabled;
        this.blogDisplayName = blogsSection?.name || 'Posts';
        this.isEnabledProducts = productsSection?.isEnabled;
        this.productDisplayName = productsSection?.name || 'Products';
    
      }
    }, err => {
      console.log(err, '  getHomePageSettings not working  ');
    });

  }

  loggedInStatusChecker() {
    this.isLoggedInChecked = !this.isLoggedInChecked

    if (this.isLoggedInChecked) {
      this.loggedInName = "Subscribers"
      this.setPageFlags(false, false, false, true)
    } else {
      this.loggedInName = "Non Subscribers"
      this.setPageFlags(false, false, false, false)
    }
  }

  setPageFlags(isDaily: boolean, isWeekly: boolean, isMonthly: boolean, isLoggedIn: boolean): void {
    this.isuserClicked = false;
    this.ispageClicked = true;
    this.ischannelClicked = false;
    this.pageDaily = isDaily;
    this.pageWeekly = isWeekly;
    this.pageMonthly = isMonthly;
    this.filterByDatePage = false;
    this.dateFilterActive = false;

    const dateRange = isDaily ? "yesterday" : isWeekly ? "7daysAgo" : isMonthly ? "30daysAgo" : this.defaultStartDate;
    this.pageDetail(dateRange, 'today', isLoggedIn);
    this.pageHeaderViews(dateRange, 'today', isLoggedIn);
    this.pageSectionDetail(dateRange, 'today', isLoggedIn);

  }

  customDateForPages() {
    this.isuserClicked = false;
    this.ispageClicked = true;
    this.ischannelClicked = false;
    this.pageMonthly = false;
    this.pageDaily = false;
    this.pageWeekly = false;
    this.filterByDatePage = true;
    this.dateFilterActive = false;
  }

  dailyPages() {
    if (this.isLoggedInChecked) {
      this.setPageFlags(true, false, false, true)

    } else {
      this.setPageFlags(true, false, false, false)

    }
  }

  weeklyPages() {
    if (this.isLoggedInChecked) {
      this.setPageFlags.call(this, false, true, false, true);

    } else {
      this.setPageFlags.call(this, false, true, false, false);

    }
  }
  monthlyPages() {
    if (this.isLoggedInChecked) {
      this.setPageFlags.call(this, false, false, true, true);

    } else {
      this.setPageFlags.call(this, false, false, true, false);

    }
  }

  customDateUpdatePages(start_date, end_date) {
    let startdate = start_date.value;
    let endDate = end_date.value;

    let comparison: boolean | any;
    comparison = format(new Date(endDate), "yyyy-MM-dd") > format(new Date(startdate), "yyyy-MM-dd")
    console.log("       input dates comparison      ", comparison)
    if (comparison) {
      this.dateInvalid = false;
      this.pageDetail(startdate, endDate, this.isLoggedInChecked);
      this.pageHeaderViews(startdate, endDate, this.isLoggedInChecked);
      this.pageSectionDetail(startdate, endDate, this.isLoggedInChecked);
    } else {
      this.dateInvalid = true;
    }
  }

  dateChangeStartDate() {  this.trackStartDate = $('#analyticsStartDate').val();  }

  dateChangeEndDate() {  this.trackEndDate = $('#analyticsEndDate').val();  }

  get analyticsStartDate() { return this.createCalenderForm.get('analyticsStartDate'); }
  get analyticsEndDate() { return this.createCalenderForm.get('analyticsEndDate'); }

  truncateHTML(pageNames, number) {  this.helperService.truncateHTML(pageNames, number)  }
}

