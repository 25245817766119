import { Component, OnInit } from '@angular/core';
import { AyrshareService } from 'src/app/services/social-media/ayrshare.service';
import { JwtModel, platforms, PostModel, UserProfile, UserProfileModel } from 'src/app/models/SocialMediaPostModel';
import { FormArray, UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { AyshareUserService } from 'src/app/services/firebase/ayrshare-social-media.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { HttpClient } from '@angular/common/http';
import { UserService } from 'src/app/services/firebase/user.service';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { EmailModel } from 'src/app/models/emailModel';
import { EmailService } from 'src/app/services/api/email.service';





@Component({
  selector: 'app-social-media-profiles',
  templateUrl: './profiles.component.html',
  styleUrls: ['./profiles.component.css']
})
export class SocialMediaProfilesComponent implements OnInit {

    profileForm: UntypedFormGroup;
    profileApplyForm: UntypedFormGroup;
    hasActiveAccounts=false
    user
    loading

    hasProfile=false
    userProfile
    // userProfileFirebase
    privateKey
    url = environment.socialMedia.profileUrl
    hasMultipleProfiles=false
    profileKey
    showProfile=false
    employees:any
    // employeeSearchQuery=''
    searchResult=[]
    selected=false
    selectedEmployee
    profileModalOpen = false;
    profileCreatedModalOpen = false;
    addEmployee=false
    profileSelect=false
    isNationApp = environment.firstNation.Origin == "FNLeaders" ? true : false;

    constructor(
        private socialMediaService:AyrshareService, 
        private userProfileService:AyshareUserService,
        private userService:UserService,
        private authService: AuthService,
        private fb: UntypedFormBuilder,
        private http: HttpClient,
        private fireMembersService: FireMembersService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private emailService: EmailService

    ) { }

    ngOnInit() {
        this.loading = true;

        this.http.get('assets/private.key',{responseType:'text'}).subscribe(x=>{
            this.privateKey=x
        })
        this.user = this.authService.getGlobalUser()

        if(this.user){
            this.userProfileService.getProfiles().valueChanges().subscribe(p=>{
                if(p){
                
                    if(p.length>0){
                        this.userProfile=p
                        this.hasProfile=true
                    }
                this.loading = false;
                
                }
            })
        }
        this.loading = false;

        this.userService.getUserByRole('Employee').valueChanges().subscribe((res: any) => {
            if (res.length > 0 ){
              this.employees = res;
              console.log('emplyees' ,this.employees)
            }
          });

        this.loading = false

        console.log(this.user,'global user')


        this.profileForm=this.fb.group({
            title:['',[Validators.required]],
            displayTitle:[''],
            hideTitle:[true],
            // disableSocial:this.fb.array([]),
            employeeId:[''],
            employee:[''],
            // employeeList:this.fb.array([]),
            employeeSearchQuery:[''],
            // users:this.fb.array([])
        })

        this.profileApplyForm=this.fb.group({
            numberOfProfiles:['',[Validators.required]],
            message:[''],

        })


    }

    get title(){return this.profileForm.get('title')  }
    get employee(){return this.profileForm.get('employee')  }
    get employeeSearchQuery(){return this.profileForm.get('employeeSearchQuery')  }




    linkSocialMedia(profileKey){
        let model = new JwtModel()
    
            model.privateKey=this.privateKey
            model.profileKey = profileKey
            console.log(model)
            this.socialMediaService.generateJWT(model).subscribe(x=>{
                console.log(x)
                if(x['status'] === 'success'){
                  
                    window.open(x['url'],'_blank')
                }else{
                    console.log('something happened')
                }
            })
      
    }

    toDashboard(index){
        // this.router.navigate(['../dashboard'], 
        //     { state:
        //         {   profileKey:profileKey
        //         },
        //         relativeTo: this.activatedRoute
        //     }
        //     )

        this.profileKey=this.userProfile[index]
        // this.profileKey=this.userProfile[index].profileKey
        this.showProfile=true
        this.profileSelect=true
        
      }

      profileChange(event){
          console.log(event.target.value)
          let index = event.target.value
        this.profileKey=this.userProfile[index]

      }

      openProfileModal(){
        this.profileModalOpen = true;
      }

      openSuccessModal(){
        this.profileCreatedModalOpen = true;
      }

      closeSuccessModal(){
        this.profileCreatedModalOpen = false;
      }
      closeProfileModal(){
        this.profileModalOpen = false;
        this.profileForm.reset()
        this.addEmployee=false
      }

      getEmailForEmployee(email) {
        let msg = new EmailModel();

        let emails: string[] = []
     
        emails.push(email)
        msg.emailList = emails;
        msg.subject =  "New Profile created";
        msg.body = "A profile has been created on  your behalf; please login to your account to create posts on your behalf."
        msg.isNationApp = this.isNationApp;


        return msg
      }
      

      createUserProfile(values){
        let model = new UserProfile()
        console.log(values)
         Object.keys(values).forEach(key=>{
            model[key] =values[key]
        })

        this.closeProfileModal()
        this.socialMediaService.createUserProfile(model).toPromise().then(x=>{
            if(x['status'] == 'success'){
                console.log(x,'the data')
                let userProfile={ }
                // Object.keys(model).forEach(key=>{
                //     userProfile[key] =model[key]
                // })
                userProfile['refId']=x['refId']
                userProfile['userId']=this.user.uid
                userProfile['profileKey'] = x['profileKey']
                userProfile['title'] = x['title']

                if (values.employeeId ) userProfile['employeeId'] = values.employeeId

                this.userProfileService.createUserProile(userProfile).then(p=>{
                    console.log( 'Profile Successfully Created');

                    this.openSuccessModal()
                    if (values.employeeId ){
                        let msg = this.getEmailForEmployee('soli@mynation.app')
                        this.emailService.sendEmail(msg).subscribe(x => {
        
                            if (x && x.Status == 'OK') {
                              let recipient = msg.emailList[0]
                              console.log(x,'email sent')

                            //   toast({ html: `Successfully Sent Email To ${recipient}!`, classes: 'green' });
                    
                            } else {
                              console.log(x,'the error emial')
                            //   toast({ html: 'Failed to send Email. Please Try again.', classes: 'red' });
                            }
                    
                          })
                    }

                }).catch(err=>{
                    console.log(err)
                })
                // this.user.profileKeys.push(userProfile)  ? this.user.profileKeys :this.user.profileKeys = [userProfile]
                // this.fireMembersService.updateAccount(this.user);         
                // toast({ html: 'Profile Successfully Posted', classes: 'green' });

            }else{
                console.log(x,'the error')
            }
        }).catch(err=>{
            console.log(err,'the error')

            

        })
    }

    initializeSearch(){
        this.searchResult =this.employees
        this.selected=false
      }
    

      selectEmployee(employee){
        this.selected=true
        this.selectedEmployee = employee
        // this.employeeSearchQuery=  this.selectedEmployee.GivenName.toLocaleLowerCase() + ' '+ this.selectedEmployee.LastName.toLocaleLowerCase()
        this.profileForm.get('employeeSearchQuery').setValue(this.selectedEmployee.GivenName.toLocaleLowerCase() + ' '+ this.selectedEmployee.LastName.toLocaleLowerCase())
        this.employee.setValue(employee)
        // this.profileForm.get('employee').setValue(employee)
        // this.employeeSearchQuery=  this.selectedEmployee.GivenName.toLocaleLowerCase() + ' '+ this.selectedEmployee.LastName.toLocaleLowerCase()
        
        // this.addEmployee(employee)
        this.profileForm.updateValueAndValidity()
      }

    searchEmployee(){
        this.searchResult = this.employees.filter(employee=>{
          return (
            employee.GivenName.toLocaleLowerCase().includes(this.employeeSearchQuery) ||
            employee.LastName.toLocaleLowerCase().includes(this.employeeSearchQuery))
        })

    
      }

}
