import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
  import { Router } from '@angular/router';
  import { GlobalConstants } from 'src/app/models/GlobalConstants';
  import { ImageModel } from 'src/app/models/ImageModel';
  import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
  import {
    Modal,
    Ripple,
    initTE,
  } from "tw-elements";
  
@Component({
  selector: 'app-customize-card-image',
  templateUrl: './customize-card-image.component.html',
  styleUrls: ['./customize-card-image.component.css']
})
export class CustomizeCardImageComponent implements OnInit {

  
    @Input() callingSection;
    @Input() modalOpen;
    @Output() closeModal = new EventEmitter<string>();
  
    //card customization
    hexColor: string = '';
    backgroundColor: string = '';
    textColor: string;
    borderRadius: number;
    boxWidth:number;
    boxHeight: number;
    cardRef: any;
    backgroundImage: any;
    position: number;
    imageChangedEventCardCustomization: any;
    cropModalOpenCardCustomization = false;
    base64ImageCardCustomization: any
    base64Image = '';
    imgErrorMsg: any;
    usePopupBase64=false
    mediaList = [];
    isMobile = false;
  
    appSettings:any
    pageSections: any
    section:any
    applyToEachSection = false
      // toast
      toastMessage: any;
      toastClass: any;
      toastType: any;
      openToast = false;
    sliderRef: any;
    blogsRef: any;
    eventRef: any;
    productRef: any;
    aboutRef: any;
    serviceRef: any;
    appStoreRef: any;
    socialMediaRef: any;
    footerRef: any;
    welcomeRef: any;
    model: any[];
    selectedColor: any;
    returnedMediaLinks = {
      imageURL: ''
    };
    hello = ''
    mediaLink: any;
    uploadFileModalOpen = false;

    constructor(
      private router: Router,
      private settingsService: AppSettingsService) { 
        this.getSettings();
    }
  
    ngOnInit(): void {
      initTE({ Modal, Ripple });
      if (!this.pageSections) {
        this.pageSections = GlobalConstants.EmptyPageSections;
    }
    const btn4 = document.getElementById('close');
    this.router.events.subscribe((val) =>{
      btn4.click()
    })
  
    this.settingsService.getAppSettingsList().valueChanges().subscribe((m: any) => {
      let index = 1
      if (m) {
        if (m.length > 0) {
          this.appSettings = m[0];
          if(this.callingSection == 'Login'){
            if(this.appSettings.loginCard){
              this.section = this.appSettings.loginCard;
              this.section.name = 'Login';
              this.backgroundColor = this.appSettings.loginCard.backgroundColor ? this.appSettings.loginCard.backgroundColor: this.appSettings.themeColor;
              this.textColor = this.appSettings.loginCard.textColor ? this.appSettings.loginCard.textColor: '#FFFFFF';
              this.borderRadius = this.appSettings.loginCard.borderRadius ? this.appSettings.loginCard.borderRadius : 12;
              this.boxWidth = this.appSettings.loginCard.boxWidth ? this.appSettings.loginCard.boxWidth : 160;
              this.boxHeight = this.appSettings.loginCard.boxHeight ? this.appSettings.loginCard.boxHeight : 160;
              this.backgroundImage = this.appSettings.loginCard.backgroundImage ? this.appSettings.loginCard.backgroundImage : '';
              this.position = this.appSettings.loginCard.position ? this.appSettings.loginCard.position : index;
            }else if(!this.appSettings.loginCard && this.appSettings.defaultCardView){
              this.backgroundColor = this.appSettings.defaultCardView.backgroundColor ? this.appSettings.defaultCardView.backgroundColor: this.appSettings.themeColor;
              this.textColor = this.appSettings.defaultCardView.textColor ? this.appSettings.defaultCardView.textColor: '#FFFFFF';
              this.borderRadius = this.appSettings.defaultCardView.borderRadius ? this.appSettings.defaultCardView.borderRadius : 12;
              this.boxWidth = this.appSettings.defaultCardView.boxWidth ? this.appSettings.defaultCardView.boxWidth : 160;
              this.boxHeight = this.appSettings.defaultCardView.boxHeight ? this.appSettings.defaultCardView.boxHeight : 160;
              this.backgroundImage = this.appSettings.defaultCardView.backgroundImage ? this.appSettings.defaultCardView.backgroundImage : '';
              this.position = index;
            }
            if(!this.appSettings.loginCard || !this.appSettings.loginCard.position){
              index++;
            }
          }else {
            this.appSettings.appSections.forEach((section:any) => {
              if((section.originalName == this.callingSection)){
                this.section = section
                if(section.cardDetail){
                  this.backgroundColor = section.cardDetail.backgroundColor ? section.cardDetail.backgroundColor: this.appSettings.themeColor;
                  this.textColor = section.cardDetail.textColor ? section.cardDetail.textColor: '#FFFFFF';
                  this.borderRadius = section.cardDetail.borderRadius ? section.cardDetail.borderRadius : 12;
                  this.boxWidth = section.cardDetail.boxWidth ? section.cardDetail.boxWidth : 160;
                  this.boxHeight = section.cardDetail.boxHeight ? section.cardDetail.boxHeight : 160;
                  this.backgroundImage = section.cardDetail.backgroundImage ? section.cardDetail.backgroundImage : '';
                  this.position = section.cardDetail.position ? section.cardDetail.position : index;
                } else if(!section.cardDetail && this.appSettings.defaultCardView){
                  this.backgroundColor = this.appSettings.defaultCardView.backgroundColor ? this.appSettings.defaultCardView.backgroundColor: this.appSettings.themeColor;
                  this.textColor = this.appSettings.defaultCardView.textColor ? this.appSettings.defaultCardView.textColor: '#FFFFFF';
                  this.borderRadius = this.appSettings.defaultCardView.borderRadius ? this.appSettings.defaultCardView.borderRadius : 12;
                  this.boxWidth = this.appSettings.defaultCardView.boxWidth ? this.appSettings.defaultCardView.boxWidth : 160;
                  this.boxHeight = this.appSettings.defaultCardView.boxHeight ? this.appSettings.defaultCardView.boxHeight : 160;
                  this.backgroundImage = this.appSettings.defaultCardView.backgroundImage ? this.appSettings.defaultCardView.backgroundImage : '';
                  this.position = index;
                } else{
                  this.backgroundColor = this.appSettings.themeColor
                  this.textColor = '#FFFFFF'
                  this.borderRadius = 12
                  this.boxWidth = 160
                  this.boxHeight = 160
                  this.backgroundImage = ''
                  this.position = index;
                }

                if(!section.cardDetail || !section.cardDetail.position ){
                  index++;
                }
              }
            });
          }

        }
      }
    });
    }

    getSettings(){
      this.settingsService.getAppSettingsList().valueChanges().subscribe(hp => {
        if (hp) {
          if (hp.length > 0) {
            this.pageSections = hp[0];}}})
    }

    fileChangeEventCardImage(event){
      // this.cardRef = cardRef
      this.imageChangedEventCardCustomization = event;
      this.cropModalOpenCardCustomization = true;
    }
    removeWelcomeImage(){
      // this.cardRef.imgUrl = '';
      this.base64ImageCardCustomization = '';
      this.mediaList = [];
    }
    removeSavedWelcomeImage(){
      // this.cardRef.imgUrl = '';
      this.base64ImageCardCustomization = '';
      this.mediaList = [];
      this.backgroundImage = '';
      this.mediaLink = ''
    }
    processCroppedImageCardCustomization(event){
      this.cropModalOpenCardCustomization = false;
      this.usePopupBase64 = false;
      this.base64ImageCardCustomization = event;
      if(this.base64ImageCardCustomization){
        if(this.isMobile){
          const img = new ImageModel();
          img.imgBase64 = this.base64ImageCardCustomization.replace(/^data:image\/[a-z]+;base64,/, "" );
          this.mediaList.push({type:'Image', file: img});
        } 
        else{
        let base64result = this.base64ImageCardCustomization.split(',')[1];
        const img = new ImageModel();
        img.imgBase64 = base64result;
        this.mediaList.push({type:'Image', file: img});
        }
      }
    }
  
    closeCroppingModal(){
      this.cropModalOpenCardCustomization = false
    }
  
    getCardDetail(){
      // this.pageSections.appSections.forEach((section:any) => {
      //   if((section.originalName == this.callingSection)){
      //     this.section = section
      //   }
      // });
  
      this.hello = this.callingSection;
    }
  
    saveSettings(){
      if(this.mediaList.length > 0){
        this.uploadFileModalOpen = true;
    }
  
    if(this.mediaList.length === 0){
      this.saveChanges()
    }
    }
  
    saveChanges(){
      if(this.callingSection == 'Login'){
        this.pageSections.loginCard ={
          backgroundColor: this.backgroundColor, 
          textColor: this.textColor,
          boxWidth: this.boxWidth,
          boxHeight: this.boxHeight,
          borderRadius: this.borderRadius,
          backgroundImage: this.mediaLink ? this.mediaLink : this.backgroundImage,
          position: this.position
         }
        if(this.applyToEachSection){
         this.pageSections.appSections.forEach((section:any) => {
            this.section = section;
            this.section.cardDetail={
            backgroundColor: this.section.cardDetail.backgroundColor, 
            textColor: this.section.cardDetail.textColor,
            boxWidth: this.section.cardDetail.boxWidth,
            boxHeight: this.section.cardDetail.boxHeight,
            borderRadius: this.section.cardDetail.borderRadius,
            backgroundImage: this.mediaLink ? this.mediaLink : this.backgroundImage,
            position: this.position
            }
          });         
        }

         this.updateCard()
      } else if (this.applyToEachSection && this.callingSection != 'Login'){
        this.pageSections.defaultCardView,this.pageSections.loginCard ={
          backgroundColor: this.backgroundColor, 
          textColor: this.textColor,
          boxWidth: this.boxWidth,
          boxHeight: this.boxHeight,
          borderRadius: this.borderRadius,
          backgroundImage: this.mediaLink ? this.mediaLink : this.backgroundImage,
          position: this.position
         }
         this.pageSections.appSections.forEach((section:any) => {
          this.section = section;
          this.section.cardDetail={
           backgroundColor: this.section.cardDetail.backgroundColor, 
           textColor: this.section.cardDetail.textColor,
           boxWidth: this.section.cardDetail.boxWidth,
           boxHeight: this.section.cardDetail.boxHeight,
           borderRadius: this.section.cardDetail.borderRadius,
           backgroundImage: this.mediaLink ? this.mediaLink : this.backgroundImage,
           position: this.position
          }
        });

        this.updateCard()
      } else {
        this.pageSections.appSections.forEach((section:any) => {
          if(section.originalName == this.callingSection && !this.applyToEachSection){
            this.section = section;
             this.section.cardDetail={
              backgroundColor: this.backgroundColor, 
              textColor: this.textColor,
              boxWidth: this.boxWidth,
              boxHeight: this.boxHeight,
              borderRadius: this.borderRadius,
              backgroundImage: this.mediaLink ? this.mediaLink : this.backgroundImage,
              position: this.position
             }
             this.updateCard()
          }
  
        });
      }
    }
  
    updateCard(){
      this.settingsService.updateAppSettings(this.pageSections).then(x => {
        this.toast({ html: 'App Settings Successfully Saved!', classes: 'green' , type: 'success'});
        this.closeCustomizeModal()
      });
    }
  
    toast(obj){
      this.toastMessage = obj.html;
      this.toastClass = obj.classes ? obj.classes : 'green' ;
      this.toastType = obj.type ? obj.type : 'success';
      this.openToast = true;
      setTimeout(() => {
        this.openToast = false;
     
      }, 2000);
    }
  
    closeCustomizeModal(){
      this.closeModal.emit();
  
    }
  
    getTheUrlsSaved(event){
      this.uploadFileModalOpen = false;
      this.returnedMediaLinks = event;
      this.mediaLink = this.returnedMediaLinks.imageURL;
      this.saveChanges();
    }
  }
  