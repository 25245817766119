<div class="flex  md:px-16 md:mx-auto w-full  justify-center items-center ">
    <div class="flex flex-wrap gap-5 justify-center">
    <div *ngFor="let product of products| slice:0:itemNumber;" class="w-full h-max   md:mx-4  md:mb-10 flex  md:min-h-max  border  md:w-[341px] md:h-[311px] drop-shadow-md rounded-lg hover:scale-100 hover:ease-in bg-white hover:duration-300" [ngStyle]="{'background-color': product.backgroundColor}" >
      
        <!-- doesnt have product list -->
        <div (click)="navigateDetails(product)" class="flex flex-col w-full   px-4  py-4  md:px-6   md:h-min border   rounded-lg hover:scale-100 hover:ease-in hover:duration-300  ">
          
          <ng-container>
            <div *ngIf="product.imgLink && !product.videoLink" (click)="navigateDetails(product)"  class="w-full md:h-48 rounded-t-xl  cursor-pointer">
              <div class="flex justify-center md:h-48  " >
                <img [src]="product.imgLink" class="md:w-56 h-full w-full rounded-lg mt-2 object-contain" >
              </div>
            </div>
            <div *ngIf="product.videoLink && !product.imgLink" (click)="navigateDetails(product)"  class="w-full h-40 md:h-48 rounded-t-xl cursor-pointer">
              <video controls *ngIf="product.videoLink != ''" [src]="product.videoLink" class="object-cover" alt="Logo"></video>           
            </div>
            <div *ngIf="!product.imgLink && !product.videoLink" (click)="navigateDetails(product)" class="relative w-full h-40 md:h-48 rounded-t-xl cursor-pointer bg-gray-200" >  
              
            </div>
            <div>
              <div *ngIf="product.videoLink && product.imgLink" (click)="navigateDetails(product)"  class="w-full h-40 md:h-48 rounded-t-xl cursor-pointer">
                <video controls *ngIf="product.videoLink != ''" [src]="product.videoLink" class="object-cover" alt="Logo" [poster]="product.imgLink"></video>           
              </div>
              <div *ngIf="product.videoLink && !product.imgLink" (click)="navigateDetails(product)"  class="w-full h-40 md:h-48 rounded-t-xl cursor-pointer">
                <video controls *ngIf="product.videoLink != ''" [src]="product.videoLink" class="object-cover" alt="Logo"></video>           
              </div>
            </div>
            
          </ng-container>
          <div class=" flex flex-col w-full space-y-2 px-4  break-words">
            <h4 (click)="navigate(product)"  class="cursor-pointer max-w-sm text-xl font-bold py-2 text-center " >{{truncateHTML(getInnerText(product.productName),40)}}</h4>
    
            <div *ngIf="product.price">
              <div *ngIf=" product.price!='dontInclude'">
                <div *ngIf="product.price > 0" href="" class="  text-center  rounded-md baseline  bottom-0 md:ml-0">
                  <ng-container *ngIf="currency"> {{product.price | currency: currency:'symbol-narrow'}}</ng-container>
                  <ng-container *ngIf="!currency">{{product.price | currency: 'USD':'symbol-narrow'}}</ng-container>
                </div>
              </div>
            </div>
            </div>
        </div>
      </div>
</div>
</div>