import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, SimpleChanges } from '@angular/core';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { NewsEventService } from 'src/app/services/firebase/news-event.service';
import { SocialMediaService } from 'src/app/services/firebase/social-media.service';
import { map } from 'rxjs/internal/operators/map';
import { EmployeeServicesService } from 'src/app/services/firebase/employee-services.service';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ProductsServiceMenuService } from 'src/app/services/api/data.service';
import { HelperService } from 'src/app/services/helper/helper';
import { GlobalConstants } from 'src/app/models/GlobalConstants';
import { CustomSectionService } from 'src/app/services/firebase/custom-section.service';
import { Modal, Ripple, Dropdown, initTE } from "tw-elements";
import { EnvironmentService } from 'src/app/services/helper/env';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectAppSettings } from 'src/app/state/app.selectors';

@Component({
  selector: 'app-landing-page-settings',
  templateUrl: './landing-page-settings.component.html',
  styleUrls: ['./landing-page-settings.component.css']
})

export class LandingPageSettingsComponent implements OnInit {
  customizeModal= false;
  customizeImageModal = false;
  callingSection: any;
  serviceType: any;
  rearrangeModal = false;
  page: any[];
  sideMenu = 'pageView'

  pageView;
  saveSliderGlobal: any;
  appSettings$: Subscription;
  constructor(private settingsService: AppSettingsService,
              private newsEventService: NewsEventService,
              private socialService: SocialMediaService,
              private employeeServicesService: EmployeeServicesService,
              private fb: UntypedFormBuilder, 
              private customSectionService: CustomSectionService,
              private helperService: HelperService,
              private router: Router,
              private productsServiceMenuService: ProductsServiceMenuService,
              private activatedRoute: ActivatedRoute,
              private envService: EnvironmentService,
              private store: Store
      ) {
        this.appSettings$ = this.store.select(selectAppSettings).subscribe((settings) => {
          this.model = {...settings};
          this.appSettings = {...settings};
          this.selectedColor = this.model.titleColor;
          this.model.title = this.model.title;
        });

        if (typeof window['Capacitor'] !== 'undefined' && window['Capacitor']['platform'] !== 'web' ) {
          this.isMobile = true;
        }
        this.landingPageSettings = this.fb.group({
          pageTitle: ['', [Validators.required]],
          appDescription: [''],
          logo: [''],
          descriptionColor: [''],
          titleColor: [''],
          textPosition: ['left'],
        });
       this.getSettings();
     }

  sectionsSetting = true;
  pageSections: any;
  isNewSetting = true;
  sliderSettings = false;
  sliderRef: any;
  heroSettings = false;
  heroRef: any;
  blogsSettings = false;
  blogsRef: any;
  newsArray: any;
  eventsSettings = false;
  eventRef: any;
  servicesSettings = false;
  serviceRef: any;
  eventsModel: any;
  appStoreSettings = false;
  appStoreRef: any;
  socialMediaSettings = false;
  socialMediaRef: any;
  socialMediaModel: any;
  footerSettings = false;
  footerRef: any;
  welcomeRef: any;
  welcomeSettings = false;

  slideRef: any;
  emptySlideOption = false;
  preview = false;
  servicesArray: any;

  customSectionRef: any;

  model: any;
  landingPageSettings: UntypedFormGroup;
  selectedColor = 'black-text $';
  imgErrorMsg: any;
  showImageFromServer = false;

  isMobile = false;
  mobileBase64Image: any;
  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  viewType;
  boxWidth:number;
  boxHeight: number;
  landingPageView ={
    web : 'list',
    mobile : 'card'
  }
  
  cardDesign =  {
    fontSize: '12px', 
    fontWeight: 'bold', 
    fontPosition: 'bottom', 
    cardShape: 'rectangle',
    imageShape: 'none', 
    imageStyle: 'full', 
    imageWidth: 60,
    imageHeight: 60,
    width: 160,
    height: 160
  }
  designCustomizationForm: FormGroup;
  
  loading = false;

  productsName = '';
  servicesName = '';
  eventsName = '';
  blogsName = '';
  socialMediaName = '';

  appSettings;

  editModalOpen = false;
  refToBeSaved: any;
  landingPageToBeSaved: any;
  editingComponent: any;
  editLandingPageModalOpen = false;

  mediaList = [];
  hasProductsCategory = false;
  hasServicesCategory = false;
  section: any;
  renameModalOpen = false

  sections = GlobalConstants.AppSections;
  sectionSelected = ''
  openPopup = ''
  cardElement = []
  sortedCard  = []
  listElement = []
  selectedRandomIndex

  changeWidthHeight = false

  ngOnInit(): void {
    initTE({ Modal, Ripple, Dropdown });
    if (!this.pageSections) {
        this.pageSections = GlobalConstants.EmptyPageSections;
    }

    this.activatedRoute.queryParams.subscribe((params: any)=>{
      this.openPopup = params.data;
    })

    this.landingPageSettings = this.fb.group({
      pageTitle: ['', [Validators.required]],
      appDescription: [''],
      logo: [''],
      descriptionColor: [''],
      titleColor: [''],
      textPosition: ['left'],
    });
    
    this.productsServiceMenuService.productsName.subscribe(p => {
      this.productsName = p;
    });
    this.productsServiceMenuService.servicesName.subscribe(s => {
      this.servicesName = s;
    });
    this.productsServiceMenuService.blogsName.subscribe(b => {
      this.blogsName = b;
    });
    this.productsServiceMenuService.eventsName.subscribe(e => {
      this.eventsName = e;
    });
    this.productsServiceMenuService.socialMediaName.subscribe(sm => {
      this.socialMediaName = sm;
    });
    if (this.model) {
      this.selectedColor = this.model.titleColor;
      this.model.title = this.model.title;

      this.titleColor.patchValue(this.model.titleColor);
      this.descriptionColor.patchValue(this.model.descriptionColor);
      this.pageTitle.patchValue(this.model.title);
      this.appDescription.patchValue(this.model.description);
      if (this.model.logo && this.model.logo != '') {
        this.showImageFromServer = true;
      }
      if(this.appSettings.viewType){
        if(this.appSettings.viewType == 'list' || this.appSettings.viewType == 'card'){
          this.pageView ='web'
        }else{
          this.pageView = this.appSettings.viewType
        }
      }else{
        this.pageView ='web'
      }
      this.landingPageView = this.appSettings.landingPageView ? this.appSettings.landingPageView : this.landingPageView;
      this.serviceType =  this.appSettings.serviceType;
      if(this.model){
        this.hasProductsCategory = this.model.hasProductsCategory ? this.model.hasProductsCategory : false;
        this.hasServicesCategory = this.model.hasServicesCategory ? this.model.hasServicesCategory : false;
      } else {
        this.hasProductsCategory = false;
        this.hasServicesCategory = false;
      }
    }      
    this.sectionSelected =  this.activatedRoute.snapshot.paramMap.get('section')

    if(this.sectionSelected == 'hero'){
      this.heroSettings = true
      this.sectionsSetting = false;
    }else if(this.sectionSelected == 'slider'){
      this.sliderSettings = true;
      this.sectionsSetting = false;
    }else if(this.sectionSelected == 'welcome-popup'){
      this.welcomeSettings = true;
      this.sectionsSetting = false;
    }else if(this.sectionSelected == 'footer'){
      this.footerSettings = true;
      this.sectionsSetting = false;
    }
  }
  ngOnChanges(changes: SimpleChanges){
    if(changes.sortedCard){
      this.sortedCard = []
        this.getCardPosition()
    }
  
    this.cardDesign.height = this.pageSections.cardDesign.height
    this.cardDesign.width = this.pageSections.cardDesign.width
    this.cardDesign.cardShape = this.pageSections.cardDesign.cardShape
  }
  get pageTitle() { return this.landingPageSettings.get('pageTitle'); }
  get appDescription() { return this.landingPageSettings.get('appDescription'); }
  get logo() { return this.landingPageSettings.get('logo'); }
  get titleColor() { return this.landingPageSettings.get('titleColor'); }
  get descriptionColor() { return this.landingPageSettings.get('descriptionColor'); }
  // get textPosition() { return this.landingPageSettings.get('textPosition'); }




  checkedChange(section: any) {
    section.isEnabled = !section.isEnabled;
    if (section.originalName === 'Hero' && section.isEnabled){
      this.pageSections.appSections.forEach(pSection => {
        if (pSection.originalName === 'Slider'){
          pSection.isEnabled = false;
        }
      });
    }
    if (section.originalName === 'Slider' && section.isEnabled){
      this.pageSections.appSections.forEach(pSection => {
        if (pSection.originalName === 'Hero'){
          pSection.isEnabled = false;
        }
      });
    }
    if (section.originalName === 'Footer'){
      this.pageSections.appSections.forEach(pSection => {
        if (pSection.originalName === 'Footer'){
          pSection.isEnabled = section.isEnabled;
        }
      });
    }
    this.settingsService.updateAppSettings(this.pageSections).then(() => {
      this.toast({ html: 'Successfully Updated!!', classes: 'green', type: 'success'});
    });
  }

  changeView(platform, view: string){
    // this.viewType = view
    // this.pageSections.viewType = view
    // this.settingsService.updateAppSettings(this.pageSections).then(() => {
    //   this.toast({ html: 'Successfully Updated!!', classes: 'green', type: 'success'});
    // });

    this.landingPageView[platform] = view
    this.pageSections.landingPageView = this.landingPageView
    this.settingsService.updateAppSettings(this.pageSections).then(() => {
      this.toast({ html: 'Successfully Updated!!', classes: 'green', type: 'success'});
    });
  }

  changePageView(view){
    this.pageView = view
    this.pageSections.viewType = view
     this.settingsService.updateAppSettings(this.pageSections).then(() => {
    
      this.toast({ html: 'Successfully Updated!!', classes: 'green', type: 'success'});
      }).catch(err => {
        this.toast({html: 'Can\'t create blog!', classes: 'red', type: 'failure'});
        // this.onLoading = false
      });

  }

  openEditSection(section){
    if (section.originalName === 'Slider') {
      this.sliderRef = section;
      this.slideRef = 0;
      this.sliderSettings = true;
      this.sectionsSetting = false;
    }
    if (section.originalName === 'Blogs') {
      this.blogsRef = section;
      this.blogsSettings = true;
      this.sectionsSetting = false;
    }
    if (section.originalName === 'Hero') {
      this.heroRef = section;
      this.heroSettings = true;
      this.sectionsSetting = false;
    }
    if (section.originalName === 'Events') {
      this.eventRef = section;
      this.eventsSettings = true;
      this.sectionsSetting = false;
    }
    if (section.originalName === 'Services') {
      this.serviceRef = section;
      this.servicesSettings = true;
      this.sectionsSetting = false;
    }
    if (section.originalName === 'App Store') {
      this.appStoreRef = section;
      this.appStoreSettings = true;
      this.sectionsSetting = false;
    }
    if (section.originalName === 'Social Media') {
      this.socialMediaRef = section;
      this.socialMediaSettings = true;
      this.sectionsSetting = false;
    }
    if (section.originalName === 'Footer') {
      this.footerRef = section;
      this.footerSettings = true;
      this.sectionsSetting = false;
    }
    if (section.originalName === 'Welcome Popup') {
      this.welcomeRef = section;
      this.welcomeSettings = true;
      this.sectionsSetting = false;
    }
    if (section.isCustomSection){
      this.customSectionService.getCustomSectionBySectionTitle(section.originalName).valueChanges().subscribe(sec => {
        this.customSectionRef = sec[0];
        if(this.customSectionRef){
          this.router.navigate(['admin/edit-custom-section', this.customSectionRef.id])
        }
      });

    }
  }
  openCustomizeModal(section){
    this.customizeModal = true
    this.callingSection = section

  }

  getRandomNumber(max: number): number {
    return Math.floor(Math.random() * max);
  }
  
  openRearrangeModal(){
    this.rearrangeModal = true
  }
  openUploadImageModal(section){
    this.customizeImageModal = true;
    this.callingSection = section;
  }
  editSection(section: any) {
    this.pageSections.appSections.forEach((sectionFiltered: any) => {
      if (sectionFiltered.originalName === section.originalName){
        sectionFiltered.displayName = section.displayName;
      }
    });
    // Main slider
    if (section.originalName === 'Slider') {
      this.sliderRef = section;
      this.slideRef = 0;

      if (this.isNewSetting) {
        this.emptySlideOption = true;
      }
      else {
        if (this.sliderRef.options.length > 0) {
          this.slideRef = 0;
          this.sliderRef.options.forEach(element => {
            element.title = element.title.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
            element.text = element.text.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
          });
        } else {
          this.emptySlideOption = true;
        }
      }
      this.sectionsSetting = false;
      this.sliderSettings = true;
    }

    if (section.originalName === 'Hero') {
      this.heroRef = section;
      this.sectionsSetting = false;
      this.heroSettings = true;
    }

    if (section.originalName === 'Blogs') {
      this.pageSections.appSections.forEach((sectionFiltered: any) => {
        if (sectionFiltered.originalName === section.originalName){
          sectionFiltered.displayName = section.displayName;
        }
      });

      this.newsEventService.getLimitedNewsList(parseInt(section.displayLimit)).valueChanges().subscribe(n => {
        if (n) {
          this.newsArray = n;
          this.blogsRef = section;
          this.sectionsSetting = false;
          this.blogsSettings = true;
        }
      });
    }

    if (section.originalName === 'Events') {
      this.eventRef = section;
      this.newsEventService.getPublicEventsList().valueChanges().subscribe(n => {
        if (n) {
          this.eventsModel = n;
          this.sectionsSetting = false;
          this.eventsSettings = true;
        }
      });
    }

    if (section.originalName === 'Services') {
      this.serviceRef = section;
      this.servicesSettings = true;
      this.sectionsSetting = false;
    }

    if (section.originalName === 'App Store') {
      this.appStoreRef = section;
      this.sectionsSetting = false;
      this.appStoreSettings = true;
    }

    if (section.originalName === 'Social Media') {
      this.socialService.getSocialMedia().valueChanges().subscribe(sm => {
        if (sm) {
          if (sm.length > 0) {
            this.socialMediaModel = sm[0];
            this.socialMediaRef = section;
            this.sectionsSetting = false;
            this.socialMediaSettings = true;
          }
        }
      });
    }

    if (section.originalName === 'Footer') {
      this.footerRef = section;
      this.sectionsSetting = false;
      this.footerSettings = true;
    }

    if (section.originalName === 'Welcome Popup') {
      this.welcomeRef = section;
      this.sectionsSetting = false;
      this.welcomeSettings = true;
    }

    this.settingsService.updateAppSettings(this.pageSections).then(() => {
        this.toast({html: 'Option saved successfully.', type: 'success', });
      });
  }

  moveSection(element, position: number) {
    const index = this.pageSections.appSections.indexOf(element);
    const newIndex = index + position;
    if (newIndex < 1 || newIndex === this.pageSections.appSections.length
      || newIndex === (this.pageSections.appSections.length - 1)) { return; } // Already at the top or bottom.
    const indexes = [index, newIndex].sort(); // Sort the indixes
    // Replace from lowest index, two elements, reverting the order
    this.pageSections.appSections.splice(indexes[0], 2, this.pageSections.appSections[indexes[1]], this.pageSections.appSections[indexes[0]]);
    this.pageSections.appSections.forEach(element => {
      element.index = this.pageSections.appSections.indexOf(element);
    });
  }

  saveChanges() {
    if (this.pageSections && this.isNewSetting) {
      this.settingsService.saveAppSettings(this.pageSections).then(x => {
        this.getSettings();
        this.toast({ html: 'Changes Saved Successfully.', classes: 'green' });
        // this.backClicked();
      });
    }
    
    if (this.pageSections && !this.isNewSetting) {
      this.saveSliderGlobal = this.settingsService.updateAppSettings(this.pageSections).then(x => {
        this.toast({ html: 'Changes Saved Successfully.', classes: 'green' });
        this.getSettings();
        // this.backClicked()
        this.saveSliderGlobal.unsubscribe()
      })

    }
  }

  previewChanges(){
    if (!this.preview){
      const sliderSection = this.pageSections.appSections.filter(s => s.originalName === 'Slider' && s.isEnabled);
      if (sliderSection && sliderSection.length > 0){

      }
      const newsSection = this.pageSections.appSections.filter(s => s.originalName === 'Blogs' && s.isEnabled);
      if (newsSection) {
        if (newsSection.length > 0) {
          this.newsEventService.getLimitedNewsList(parseInt(newsSection[0].displayLimit))
          .valueChanges().pipe(
            map(news => news.map((n: any) => ({... n, iframe: '' })))
          )
          .subscribe(n => {
            if (n) {
              this.newsArray = n.filter(news => news.visibility === 'Public' && news.published === true);
              this.newsArray.forEach(element => {
                if (element.type === 'Video') {
                  //element.iframe = this.embedService.embed(element.videoLink);
                }
              });
            }
          });
        }
      }

      const eventSection = this.pageSections.appSections.filter(s => s.originalName === 'Events' && s.isEnabled);
      if (eventSection) {
        if (eventSection.length > 0) {
          this.newsEventService.getPublicEventsList().valueChanges().subscribe(n => {
            if (n) {
              this.eventsModel = n;
            }
          });
        }
      }

      const socialSection = this.pageSections.appSections.filter(s => s.originalName === 'Social Media' && s.isEnabled);
      if (socialSection) {
        if (socialSection.length > 0) {
          this.socialService.getSocialMedia().valueChanges().subscribe(n => {
            if (n) {
              if (n.length > 0) {
                this.socialMediaModel = n[0];
              }
            }
          });
        }
      }

      const serviceSection = this.pageSections.appSections.filter(s => s.originalName === 'Services' && s.isEnabled);
      if (serviceSection){
        if (serviceSection.length > 0){
          this.employeeServicesService.getPublicServices().valueChanges().subscribe((ser) => {
            if (ser){
              if (ser.length > 0){
                this.servicesArray = ser;
              }
            }
          });
        }
      }
    }
    this.preview = !this.preview;
  }

  backClicked() {
    if (this.welcomeSettings) {this.welcomeSettings = false; }
    if (this.sliderSettings) {this.sliderSettings = false; }
    if (this.heroSettings) {this.heroSettings = false; }
    if (this.blogsSettings) { this.blogsSettings = false; }
    if (this.eventsSettings) { this.eventsSettings = false; }
    if (this.servicesSettings) { this.servicesSettings = false; }
    if (this.appStoreSettings) { this.appStoreSettings = false; }
    if (this.socialMediaSettings){  this.socialMediaSettings = false; }
    if (this.footerSettings){ this.footerSettings = false; }
    this.sectionsSetting = true;
  }

  getSettings() {
    if (this.pageSections) {
      this.pageSections.appSections.forEach(element => {
        if(element.originalName == 'Welcome Popup'){
          element.listIndex = null
        }
        element.listIndex = element.listIndex ? element.listIndex : element.index
      });
      this.getCardPosition()
      this.getElement()

      const contact = this.pageSections.appSections.filter(s => s.originalName === 'Contact Us');
      if(!contact || contact.length === 0){
        this.pageSections.appSections.push({
          index: 9,
          isEnabled: false,
          bgColor: "#FFFFFF",
          textColor: "#000000",
          name: "Contact Us",
          originalName: "Contact Us",
          displayName: "Contact Us"
        })
        this.settingsService.updateAppSettings(this.pageSections).then(()=> {
        })
      }
      this.isNewSetting = false;
      if (this.sliderSettings) {
        this.sliderRef = this.pageSections.appSections.filter(s => s.originalName === 'Slider')[0];
        }
      if (this.blogsSettings) { this.blogsRef = this.pageSections.appSections.filter(s => s.originalName === 'Blogs')[0]; }
      if (this.eventsSettings) { this.eventRef = this.pageSections.appSections.filter(s => s.originalName === 'Events')[0]; }
      if (this.servicesSettings) { this.serviceRef = this.pageSections.appSections.filter(s => s.originalName === 'Services')[0]; }
      if (this.appStoreSettings) { this.appStoreRef = this.pageSections.appSections.filter(s => s.originalName === 'App Store')[0]; }
      if (this.socialMediaSettings) { this.socialMediaRef = this.pageSections.appSections.filter(s => s.originalName === 'SocialMedia')[0]; }
      if (this.footerSettings) { this.footerRef = this.pageSections.appSections.filter(s => s.originalName === 'Footer')[0]; }
      if (this.welcomeSettings) { this.welcomeRef = this.pageSections.appSections.filter(s => s.originalName === 'Welcome Popup')[0]; }
    }
    this.settingsService.getLandingPageSettings().valueChanges().subscribe(
      lpSettings => {
        if (lpSettings) {
          if (lpSettings.length > 0) {
            this.model = lpSettings[0];
            this.selectedColor = this.model.titleColor;
            this.model.title = this.model.title;
            this.titleColor.patchValue(this.model.titleColor);
            this.descriptionColor.patchValue(this.model.descriptionColor);
            this.pageTitle.patchValue(this.model.title);
            this.appDescription.patchValue(this.model.description);
            if (this.model.logo && this.model.logo != '') {
              this.showImageFromServer = true;
            }
            this.model.imgUrl = this.model.imgUrl?this.model.imgUrl : ''
            this.model.videoUrl = this.model.videoUrl?this.model.videoUrl : ''
            // if (this.model.textPosition) {
            //   this.textPosition.patchValue(this.model.textPosition);
            // }
          }
        } 
      }
    );
  }

  truncateHTML(text: string, limit: string): string {
    return this.helperService.truncateHTML(text, limit);
  }
  getElement(){
    let index = 0
    this.pageSections.appSections.forEach(section => {
      // Append the element to the appendedArray

        if(this.validSection(section) || section.isCustomSection){
       let existingIndex = this.listElement.findIndex(element => element.section.originalName == section.originalName);
        
        if (existingIndex !== -1) {
          // Element already exists, update it
          this.listElement[existingIndex] = {name: section.name,section: section, position:section.listIndex }

        }else{
          this.listElement.push({name: section.name,section: section, position: section.listIndex }) 

        }
      }else{
        section.listIndex = null
      }
    })
    this.listElement = this.listElement.slice().sort((a, b) => a.position - b.position)
  }     

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.listElement, event.previousIndex, event.currentIndex);
        // Update position property for all elements
        this.updatePositions();
  }
  updatePositions(): void {
    this.listElement.forEach((element, index) => {
      element.position = index;
      this.pageSections.appSections.forEach(section => {
        if(element.section.originalName == 'Welcome Popup'){
          section.listIndex = null
        }
        if(element.section.originalName == section.originalName){
          section.listIndex = element.position + 1
        }

      
      });
    });
    // this.saveRenameChange();
  }

navigateTo(name){
  if(name == 'Blogs'){
    this.router.navigate(['/admin/blogs']);
  }
  if(name == 'Events'){
    this.router.navigate(['/admin/events']);
  }
  if(name == 'Products'){
    this.router.navigate(['/admin/view-products']);
  }
  if(name == 'Services'){
    this.router.navigate(['/admin/services']);
  }
  if(name == 'First Nations Leaders'){
    this.router.navigate(['/admin/first-nations-leaders-list']);
  }
}
  SaveSettings(value: any) {
    // save slider settings
    if (this.model) {
      if(!this.model.videoUrl){
        this.model.videoUrl = '';
      }
      this.model.title = value.pageTitle;
      this.model.description = value.appDescription;
      this.model.titleColor = (value.titleColor) ? value.titleColor : '#FFFFFF';
      this.model.descriptionColor = value.descriptionColor;
      this.model.logo = value.logo ? value.logo : this.model.logo;
      this.model.videoUrl = value.videoUrl ? value.videoUrl : this.model.videoUrl;

      this.settingsService.updateLandingPageSettings(this.model).then(x=>{
        this.toast({ html: 'Landing Page Settings Successfully Updated!!', classes: 'green', type: 'success'});
      }
        // this.sessionMonitor.newActivity('Landing Page', 'Page Updated'),
      );
    } else {
      this.model = {
        title: value.pageTitle,
        description: value.appDescription,
        descriptionColor: value.descriptionColor,
        titleColor: this.selectedColor,
        // textPosition : value.textPosition
      };

      this.settingsService.saveLandingPageSettings(this.model).then(
        // this.sessionMonitor.newActivity('Landing Page', 'Page Set'),
        x=>{
          this.toast({ html: 'Landing Page Settings Successfully Updated!', classes: 'green' })
        }
      );
    }


    this.mediaList = [];
  }

  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
      this.backClicked();
    }, 2000);
  }

  landingPageConfirmationUpdate(){
    // this.landingPageToBeSaved = landingpagesetting;
    this.editingComponent = 'Landing Page Setting'
    this.editLandingPageModalOpen = true;
  }

  saveLandingPageUpdate(){
    this.closeEditModal();
    this.saveChanges();
  }

  closeRearrangeModal(){
    this.rearrangeModal = false
    this.getSettings();
    this.router.navigate(['/admin/landing-page-settings']);
  }
  closeCustomizeImageModal(){
    this.customizeImageModal = false
    this.getSettings();
  }
  closeCustomizeModal(){
    this.customizeModal = false
    this.getSettings();
  }

  confirmUpdate(updatedRef, fromComponent){
    if(fromComponent=='WelcomePopup'){
      this.editingComponent = 'Welcome page';
    }
    if(fromComponent === 'EventUpdate'){
      this.editingComponent = this.eventsName+' page';
    }
    if(fromComponent === 'BlogsUpdate'){
      this.editingComponent = this.blogsName+' page';
    }
    if(fromComponent==='ServicesUpdate'){
      this.editingComponent = this.servicesName+' page';
    }
    if(fromComponent==='AppStoreUpdate'){
      this.editingComponent = 'App store setting'
    }
    if(fromComponent==='FooterUpdate'){
      this.editingComponent = 'Footer'
    }
    this.editModalOpen = true;
    this.refToBeSaved = updatedRef;
  }
  validSection(section) {
    let validSections = [
      "Events",
      "Blogs",
      "News",
      "Products",
      "Services",
      "About Us",
      "Footer",
      "faq"
    ];
    let chiefSections = ["First Nations Leaders"];
    return (
      (validSections.includes(section.originalName) ||
        (this.envService.isChiefApp && chiefSections.includes(section.originalName))) &&
      section.isEnabled || (section.isCustomSection)
    )
  }

  getCardPosition(){

    let index = 1
    let length = 1


    const existingIndexLogin = this.cardElement.findIndex(element => element.originalName === 'Login/Signup');
    if (existingIndexLogin !== -1) {
      // Element already exists, update it
      this.cardElement[existingIndexLogin] = {name: 'Login/Signup', originalName:'Login/Signup' , position: this.pageSections.loginCard?.position ? this.pageSections.loginCard.position : 0 }
    }else{
      if(this.pageSections.loginCard &&  this.pageSections.loginCard.position){
        this.cardElement.push({name: 'Login/Signup', originalName:'Login/Signup' , position: this.pageSections.loginCard.position})
      }else{
        this.cardElement.push({name: 'Login/Signup', originalName:'Login/Signup', position: 0})
      }
    } 

    this.pageSections.appSections.forEach(section => {
      if(this.validSection(section) || section.isCustomSection){    
        let existingIndex = this.cardElement.findIndex(element => element.originalName == section.originalName);
        if (existingIndex !== -1) {
          // Element already exists, update it
          if( section.cardDetail && section.cardDetail.position){
            this.cardElement[existingIndex] = {name: section.name, originalName:section.originalName, section: section,  position: section.cardDetail.position}
          }else if(section.cardDetail && !section.cardDetail.position ){
            this.cardElement[existingIndex] ={name: section.name, originalName:section.originalName, section: section, position: index} 
            index += 1
            length +=1
          }else{
            section.cardDetail = {
              backgroundColor: this.pageSections.defaultCardView ? this.pageSections.defaultCardView.backgroundColor: this.pageSections.themeColor? this.pageSections.themeColor : '#077373',
              textColor: this.pageSections.defaultCardView ? this.pageSections.defaultCardView.textColor:  '#FFFFFF',
              borderRadius : this.pageSections.defaultCardView ? this.pageSections.defaultCardView.borderRadius :  12,
              boxWidth : this.pageSections.defaultCardView ? this.pageSections.defaultCardView.boxWidth:  160,
              boxHeight : this.pageSections.defaultCardView ? this.pageSections.defaultCardView.boxHeight:  160,
              backgroundImage :this.pageSections.defaultCardView ? this.pageSections.defaultCardView.backgroundImage:  '',
              position: length
            }
            this.cardElement[existingIndex] ={name: section.name, originalName:section.originalName, section: section, position: length}
            length +=1
          }
        } else {

          // Element doesn't exist, append it
          if(section.cardDetail && section.cardDetail.position){
            this.cardElement.push({name: section.name, originalName:section.originalName, section: section,  position: section.cardDetail.position}) 
            length +=1
          }else if(section.cardDetail && section.cardDetail.position == 0){
            this.cardElement.push({name:section.name,originalName:section.originalName, section: section, position: 0});
            length +=1
           }
          else if(section.cardDetail){
            this.cardElement.push({name: section.name, originalName:section.originalName, section: section, position: index}) 
            index += 1
            length +=1
          }else{
            section.cardDetail = {
              backgroundColor: this.pageSections.defaultCardView ? this.pageSections.defaultCardView.backgroundColor: this.pageSections.themeColor? this.pageSections.themeColor : '#077373',
              textColor: this.pageSections.defaultCardView ? this.pageSections.defaultCardView.textColor:  '#FFFFFF',
              borderRadius : this.pageSections.defaultCardView ? this.pageSections.defaultCardView.borderRadius :  12,
              boxWidth : this.pageSections.defaultCardView ? this.pageSections.defaultCardView.boxWidth:  160,
              boxHeight : this.pageSections.defaultCardView ? this.pageSections.defaultCardView.boxHeight:  160,
              backgroundImage :this.pageSections.defaultCardView ? this.pageSections.defaultCardView.backgroundImage:  '',
              position: length
            }
            this.cardElement.push({name: section.name, originalName:section.originalName, section: section, position: length}) 
            length +=1
          }
        }
  
      }
   
    });
    this.cardElement = this.cardElement.slice().sort((a, b) => a.position - b.position)

  }

  saveRefUpdate(){
    this.closeEditModal()
    if(this.refToBeSaved){
      this.editSection(this.refToBeSaved);
    }
  }

  closeEditModal(){
    this.editModalOpen = false;
    this.editLandingPageModalOpen = false;
  }

  redirectToProduct(){
    if (this.hasProductsCategory){
      this.router.navigate(['/admin/product-catagory']);
    }
    if (!this.hasProductsCategory){
      this.router.navigate(['/admin/view-products']);
    }
  }

  redirectToServices(){
    if (this.hasServicesCategory){
      this.router.navigate(['/admin/service-category']);
    }
    if (!this.hasServicesCategory){
      this.router.navigate(['/admin/services']);
    }
  }

  selectItem(offer){
    this.pageSections.appSections.forEach(section => {
      if(section.originalName == offer.originalName){
        this.section = section;
          this.renameModalOpen = true
      }
    });
  }

  sectionInputChange(offer, event){
    this.pageSections.appSections.forEach(section => {
      if (section.originalName == offer.originalName){
        section.name = event.target.value;
      }
    });
  }

  closeRenameModal(){
    this.renameModalOpen = false
  }


  saveRenameChange(){
    this.settingsService.updateAppSettings(this.pageSections).then(x => {
      this.renameModalOpen = false
      this.toast({ html: 'App Settings Successfully Saved!', classes: 'green' , type: 'success'});
      this.getSettings();
    });
  }


  onFontSizeChange(size: string): void {
    switch (size) {
      case 'small':
        this.cardDesign.fontSize = '12px';
        break;
        case 'medium':
          this.cardDesign.fontSize = '14px';
          break;
      case 'large':
        this.cardDesign.fontSize = '16px';
        break;
      case 'extraLarge':
        this.cardDesign.fontSize = '20px';
        break;
      default:
        this.cardDesign.fontSize = '14px';
    }
    this.pageSections.cardDesign = this.cardDesign

  }

  onTextPositionChange(position: string): void{
    this.pageSections.cardDesign.fontPosition = position
  }
  onFontWeightChange(weight){
    this.pageSections.cardDesign.fontWeight = weight
  }
  onCardShape(shape: string){
    this.pageSections.cardDesign.cardShape = shape
    this.cardDesign.cardShape = shape 

  }
  onImageShape(shape: string){
    this.pageSections.cardDesign.imageShape = shape
    this.cardDesign.imageShape = shape 


  }
  onImageStyle(style: string){
    this.pageSections.cardDesign.imageStyle = style
    this.cardDesign.imageStyle = style 
    if(this.cardDesign.fontPosition == 'center'){
      this.cardDesign.fontPosition = 'bottom'
    }

  }

  onWidth(width:number){
    this.pageSections.cardDesign.width = width
    this.cardDesign.width = width
    if(this.cardDesign.cardShape == 'square' || 'circle'){
      this.pageSections.cardDesign.height = width 
      this.cardDesign.height = width 
    }

  }
  onHeight(height:number){
    this.pageSections.cardDesign.height = height

  }

  onImageWidth(width:number){
    this.pageSections.imageWidth.imageWidth = width
    this.cardDesign.width = width
    if(this.cardDesign.imageShape == 'square' || 'circle'){
      this.pageSections.cardDesign.imageWidth = width 
      this.cardDesign.imageWidth = width 
    }

  }
  onImageHeight(height:number){
    this.pageSections.cardDesign.imageHeight = height

  }


  updateChanges(){

    this.settingsService.updateAppSettings(this.pageSections).then(() => {
      this.toast({ html: 'Successfully Updated!!', classes: 'green', type: 'success'});
    });


  }
  updateDimensions() {
    // Ensure the input values are positive numbers
    if (this.boxWidth < 0) {
      this.boxWidth = 0;
    }
    if (this.boxHeight < 0) {
      this.boxHeight = 0;
    }
  }
  changeWidthandHeight(){
    this.changeWidthHeight = !this.changeWidthHeight
  }
  updateAllSections(){
    if(this.changeWidthHeight){
      this.pageSections.loginCard.boxHeight = this.pageSections.cardDesign.height
      this.pageSections.loginCard.boxWidth = this.pageSections.cardDesign.width
      // this.pageSections.defaultCardView.boxHeight = this.pageSections.cardDesign.height
      // this.pageSections.defaultCardView.boxWidth = this.pageSections.cardDesign.width
      this.pageSections.appSections.forEach((section:any) => {
        if(section && section.cardDetail){
          section.cardDetail.boxWidth = this.pageSections.cardDesign.width
          section.cardDetail.boxHeight = this.pageSections.cardDesign.height
        }
    });
  }
    this.settingsService.updateAppSettings(this.pageSections).then(x => {
      this.toast({ html: 'App Settings Successfully Saved!', classes: 'green' , type: 'success'});
    });
  }
}

