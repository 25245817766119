import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-hero-section',
  templateUrl: './hero-section.component.html',
  styleUrls: ['./hero-section.component.css']
})
export class HeroSectionComponent implements OnInit {

  @Input() socialMediaModel;
  @Input() landingPageMain;
  @Input() appStore;

  playStore = environment.mobileAppLinks.playStore;
  appleStore = environment.mobileAppLinks.appleStore;
  constructor() { }

  ngOnInit(): void {
  }

}
