import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { ClientFormService } from 'src/app/services/firebase/client-form.service';
import { FormsService } from 'src/app/services/firebase/forms.service';
import { environment } from 'src/environments/environment';

// declare var navigator: any;

@Component({
  selector: 'app-client-form',
  templateUrl: './client-form.component.html',
  styleUrls: ['./client-form.component.css']
})

export class ClientFormComponent implements OnInit {
  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  loggedUser: any;
  model: any;
  surveyForm: UntypedFormGroup;
  questionForm: UntypedFormGroup;
  questionArray: UntypedFormArray;1
  answersArray: UntypedFormArray;

  //form
  newFormClicked: boolean = false;
  nextClicked: boolean = false;
  currentSection = 0;
  addSection = false;
  addFields = false;
  selectSelected = false;
  selectedFields: any[];

  //sections
  statement = "I declare that the above information to be true, and I authorize the Administrative Authority."
  order = 1;


  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;

  currentUser: any;
  role: any;
  isAdmin: boolean;

  oldUrl: any;
  isMobile = false;
  imgErrorMsg: any;
  base64Image: any;
  binDoc: File;
  mobileBase64Image: any;
  appLogo: any;
  showLogoInput = true;
  showImageFromServer = false;
  fieldsChecked: boolean = false;

  defaultThemeColor = environment.appTheme.themeColor;

  selectFieldType = [];
  checkBoxFieldType = [];

  // answers = [];
  questionNumber = 1;
  step1 = true;
  step2 = false;
  step3 = false;
  showMultiAnswer = false;
  //answerTypes: any = ['Multiple Choice', 'Open Ended Answer', 'Scale', 'Checkboxes', 'Dropdown'];
  answerTypes: any = [
    { 'type': 'Multiple Choice', 'helpText': 'Displays a list of answers the client can choose from' },
    { 'type': 'Open Ended Answer', 'helpText': 'Allows client to answer in open text format' },
    { 'type': 'Scale', 'helpText': 'Displays a scale from any range from 0 to 10' },
    { 'type': 'Date', 'helpText': 'Displays a calander for the client to choose from'}];
  scales = ['2', '3', '4', '5', '6', '7', '8', '9', '10'];

  numberOfAnswers: any = ['2', '3', '4'];
  showSurveyResult = false;
  ShowScaleLimits = false;
  createPoll = false;
  
  skillsForm: UntypedFormGroup;
  formExists: boolean;
  //form: any;

  showFields = [];
  field = 0;
  fieldTypes = ['Input', 'Select', 'CheckBox', "Radio Button", "Text Box"];
  //dataTypes = ['Text', 'Number', 'Date', 'Telephone'];
  dataTypes = ['Text', 'Number', 'Date'];
  icons = ['person', 'tag', 'add_location', 'people', 'location_on', 'location_city', 'mail', 'house', 'phone', 'phone_iphone', 'today', 'apps'];
  selectOptions = ['maritalStatus', 'provinces', 'bandNames'];
  fieldOrder = 0;
  fields: any[];

  location: any;
  forms: any;

  deleteModalOpen: boolean = false;

  constructor(private fb: UntypedFormBuilder,
              private clientFormService: ClientFormService,
              private authService: AuthService,
              private appSettingsService: AppSettingsService,
              private router: Router,
              private formService: FormsService
              ) { }

  form: UntypedFormGroup;
  get skills() : UntypedFormArray {return this.skillsForm.get("skills") as UntypedFormArray }

  ngOnInit(): void {

    this.formService.getFormList().valueChanges().subscribe(form => {
      this.forms = form;
    });

    this.fields = [];
    this.fields.push({ name: 'First Name', checked: false, form_control: 'first_name', field_type: 'Input', select_options: [], data_type: 'text', icon: 'person', required: true, textMask: false });
    this.fields.push({ name: 'Last Name', checked: false, form_control: 'last_name', field_type: 'Input', select_options: [], data_type: 'text', icon: 'person', required: true, textMask: false });
    this.fields.push({ name: 'Indian Status No', checked: false, form_control: 'isn', field_type: 'Input', select_options: [], data_type: 'text', icon: 'tag', required: false, textMask: 'isn' });
    this.fields.push({ name: 'Band Name', checked: false, form_control: 'band_name', field_type: 'Select', select_options: 'Band Names', data_type: 'text', icon: 'add_location', required: false, textMask: false });
    this.fields.push({ name: 'Marital Status', checked: false, form_control: 'marital_status', field_type: 'Select', select_options: 'Marital Stauts', data_type: 'text', icon: 'people', required: true, textMask: false });
    this.fields.push({ name: 'Province', checked: false, form_control: 'province', field_type: 'Select', select_options: 'Provinces', data_type: 'text', icon: 'add_location', required: true, textMask: false });
    this.fields.push({ name: 'Address', checked: false, form_control: 'address', field_type: 'Input', select_options: [], data_type: 'text', icon: 'location_on', required: true, textMask: false });
    this.fields.push({ name: 'City', checked: false, form_control: 'city', field_type: 'Input', select_options: [], data_type: 'text', icon: 'location_city', required: true, textMask: false });
    this.fields.push({ name: 'Postal Code', checked: false, form_control: 'postal_code', field_type: 'Input', select_options: [], data_type: 'text', icon: 'mail', required: true, textMask: 'postalCode' });
    this.fields.push({ name: 'Mailing Address', checked: false, form_control: 'mailing_address', field_type: 'Input', select_options: [], data_type: 'text', icon: 'mail_outline', required: true, textMask: false });
    this.fields.push({ name: 'House Number', checked: false, form_control: 'house_number', field_type: 'Input', select_options: [], data_type: 'text', icon: 'house', required: false, textMask: false });
    this.fields.push({ name: 'Home Number', checked: false, form_control: 'home_number', field_type: 'Input', select_options: [], data_type: 'tel', icon: 'phone', required: false, textMask: false });
    this.fields.push({ name: 'Mobile Number', checked: false, form_control: 'mobile', field_type: 'Input', select_options: [], data_type: 'tel', icon: 'phone_iphone', required: false, textMask: false });
    this.fields.push({ name: 'Health Care Number', checked: false, form_control: 'health_care_no', field_type: 'Input', select_options: [], data_type: 'number', icon: 'tag', required: false, textMask: false });
    this.fields.push({ name: 'Date of Birth', checked: false, form_control: 'dob', field_type: 'Input', select_options: [], data_type: 'date', icon: 'today', required: true, textMask: false });
    this.fields.push({ name: 'SIN', checked: false, form_control: 'sin', field_type: 'Input', select_options: [], data_type: 'text', icon: 'apps', required: false, textMask: 'sin' });

    this.form = this.fb.group({
      name: ['', [Validators.required]],
      description: [''],
      logo: [''],
      themeColor: [''],
      sections: this.fb.array([this.newSection()]),
    });

    this.skillsForm = this.fb.group({
      skills: this.fb.array([]) ,
    }); 

    this.currentUser = this.authService.getGlobalUser();
    if (this.currentUser){
      this.role = this.currentUser.role;
      if (this.role === 'Admin'){
        this.isAdmin = true;
      }
  }



  this.appSettingsService.getAppSettingsList().valueChanges().subscribe(
    appSettings => {
      if (appSettings) {
        if (appSettings.length > 0) {
          this.model = appSettings[0];         

          this.themeColor = this.model.themeColor ? this.model.themeColor : this.defaultThemeColor;
    }
  }
});
}

handleImageInput(files: FileList) {
  if (!this.validateImage(files[0].name)) {
    this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';
    this.appLogo.patchValue('');
    return;
  }
  this.binDoc = files[0];
  this.imgErrorMsg = undefined;
  const reader = new FileReader();
  reader.readAsDataURL(files[0]);
  reader.onload = () => {
    this.base64Image = reader.result as string;
  };
}
private validateImage(name: any) {
  const ext = name.substring(name.lastIndexOf('.') + 1);
  if (ext.toLowerCase() === 'png') {
    return true;
  } else if (ext.toLowerCase() === 'jpg') {
    return true;
  } else if (ext.toLowerCase() === 'jpeg') {
    return true;
  } else {
    return false;
  }
}
removeFiles() {
  this.binDoc = undefined;
  this.base64Image = undefined;
  this.appLogo.patchValue('');
}

removeSavedFile() {
  this.binDoc = undefined;
  this.base64Image = undefined;
  this.oldUrl = this.model.logo;
  this.model.logo = '';
  this.appLogo.patchValue('');
  this.showImageFromServer = false;
  this.showLogoInput = true;
}
handleMobilePhotoInput(){
  // navigator.camera.getPicture((image: any) => {
  //   const imageName = 'IMG.jpeg';
  //   const imageBlob = this.dataURItoBlob(image);
  //   const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });
  //   this.binDoc = imageFile;
  //   this.base64Image = 'data:image/jpeg;base64,' + image;
  //   this.mobileBase64Image = image;

  // }, (error) => {
  //   alert(error);
  // },
  // {
  //   quality: 50,
  //   destinationType: navigator.camera.DestinationType.DATA_URL,
  //   encodingType: navigator.camera.EncodingType.JPEG,
  //   mediaType: navigator.camera.MediaType.PICTURE,
  //   sourceType: navigator.camera.PictureSourceType.PHOTOLIBRARY,
  // }
  // );
}

dataURItoBlob(dataURI) {
  const byteString = window.atob(dataURI);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const int8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    int8Array[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([int8Array], { type: 'image/png' });
  return blob;
}

newSection() {
  return this.fb.group({
    sec_title: ['', [Validators.required]],
    section_name: [this.sectionName, [Validators.required]],
    statement: [this.statement],
    order: [this.order],
    fields: this.fb.array([]),
  });
}

getAnswers(index) {
  const questions = this.skillsForm.get('skills') as UntypedFormArray;
  return questions.controls[index].get('answers') as UntypedFormArray;
}

addAnswers() {
  return this.fb.group({
    answer: ['', [Validators.required]]
  });
}

onAddAnswer(index) {
  const answers = this.getAnswers(index);
  answers.push(this.addAnswers());
}

getScales(index) {
  const questions = this.skillsForm.get('skills') as UntypedFormArray;
  return questions.controls[index].get('scales');
}

  newSkill(): UntypedFormGroup {
    return this.fb.group({
      question: ['', Validators.required],
      answerType: [''],
    })
 }

 newChoice(): UntypedFormGroup{
  return this.fb.group({

  })
 }


 addQuestion() {
    this.questionNumber += 1;
    this.questionArray = this.skillsForm.get('skills') as UntypedFormArray;
    this.skills.push(this.createQuestion  ());
 }

 createQuestion() {
  let questionFormGroup = this.fb.group({
    question: ['', [Validators.required]],
    answerType: ['', [Validators.required]],
    allowMultipleAnswers: [false, [Validators.required]],
    answers: this.fb.array([]),
    scales: this.fb.group({
      start: [''],
      startLabel: [],
      end: [''],
      endLabel: []
    }),
  });
  return questionFormGroup
}


removeSkill(i:number) {
  this.skills.removeAt(i);
}

removeAnswer(question_index, answer_index) {
  const answers = this.getAnswers(question_index);
  answers.removeAt(answer_index);
}

removeFile(index: any) {
  this.questionArray = this.skillsForm.get('skills') as UntypedFormArray;
  this.questionArray.removeAt(index);
}

onSubmit() {
  this.toast({ html: 'Client Form sucessfully added', classes: 'green', type: 'success' });
} 

answerTypeChange(e: any, index: any) {
  this.resetControls(index);
  const answers = this.getAnswers(index);
  if (e === 'Multiple Choice') {
    if (answers.length < 2) {
      answers.push(this.addAnswers());
      answers.push(this.addAnswers());
    }
  } else if (e === 'Scale') {
    this.getScales(index).get('start').patchValue('1');
    this.getScales(index).get('end').patchValue('10');
  } else {
  }

  // setTimeout(() => {
  //   $('select').formSelect();
  // }, 25);

}
resetControls(index) {
  const answers = this.getAnswers(index);

  while (answers.length !== 0) {
    answers.removeAt(0);
  }
  const scales = this.getScales(index);
  //answers.reset();
  answers.clearValidators();
  answers.updateValueAndValidity();
  scales.reset();
  scales.clearValidators();
  scales.updateValueAndValidity();
}

fieldChecked(index) {
  const field = this.fields[index];
  field.checked = !field.checked;
  if (field.checked) {
    this.fieldOrder += 1;
    field.order = this.fieldOrder;
  } else {
    field.order = null;
  }
  this.fields[index] = field;
  if ((this.fields.filter(field => field.checked == true)).length == 0) {
    this.fieldsChecked = false;
  } else {
    this.fieldsChecked = true;
  }
}

fieldTypeSelected(value, f) {
  if (value == 'Select') {
    this.selectFieldType[f] = true;
  } else if (value == 'CheckBox') {
    this.checkBoxFieldType[f] = true;
  } else {
    this.selectFieldType[f] = false;
    this.checkBoxFieldType[f] = false;
  }


  // setTimeout(() => {
  //   $('select').formSelect();
  //   $('.chips').chips();
  //   $('.chips-placeholder').chips({
  //     placeholder: 'Add options',
  //     secondaryPlaceholder: '+Add',
  //     chipData: this.selectOptionValues
  //   });
  // }, 25);
}

submitForm(value: any) {
  this.model = {
    id: '',
    // name: value.surveyName,
    // type: value.type,
    numberOfQuestions: 0,
    // startDate: value.startDate,
    // endDate: value.endDate,
    // showResult: value.showResult,
    // showResultTo: value.showResultTo,
    questions: [],
    adminID: this.currentUser.id,
    adminName: this.currentUser.FirstName + " " + this.currentUser.LastName,
    datePosted: new Date(),
  };
}

submitQuestions(value: any) {
  let qNumber = 0;
  this.model = {
    id: '',
    // name: value.surveyName,
    // type: value.type,
    numberOfQuestions: 0,
    // startDate: value.startDate,
    // endDate: value.endDate,
    // showResult: value.showResult,
    // showResultTo: value.showResultTo,
    questions: [],
    adminID: this.currentUser.uid,
    adminName: this.currentUser.FirstName + " " + this.currentUser.LastName,
    datePosted: new Date(),
  };

  value.skills.forEach(element => {
    qNumber += 1;
    const question = {
      questionNumber: qNumber,
      question: element.question,
      answerType: element.answerType,
      allowMultipleAnswers: element.allowMultipleAnswers,
      answers: element.answers,
      scales: element.scales
    };

    if (element.answerType === 'Scale') {
      for (var i = element.scales.start; i <= element.scales.end; i++) {
        element.answers.push({ 'answer': i.toString() });
      }
    }

    this.model.questions.push(question);

  });

  this.model.numberOfQuestions = this.model.questions.length;
  // this.clientFormService.updateForm(this.model).then(x => {
  //   this.toast({ html: 'New Form Successfully Created!', classes: 'green' });
  // });
  
  this.formService.newForm(this.model).then(x => {
      this.toast({ html: 'New Form is Successfully Created!', classes: 'green' });
  });

}

newForm(){
  //this.newFormClicked = true
  this.router.navigate(['admin/create-form'])
}
next(){
  this.nextClicked = true;
}
previous(){
  this.newFormClicked = true;
  this.nextClicked = false;
}
createApplication(values) {
  // this.application.appName = values.name;
  // this.application.appDescription = values.description;
  // this.application.sections = values.sections;
  // this.CreateApplicationService.saveApplication(this.application).then(x=> {
  //   toast({ html: 'Application Successfully Created!', classes: 'green' });
  // });
  // this.appCreated = true;
}

getSectionFields(s) {
  const section = this.sectionControls.controls[s] as UntypedFormGroup;
  const fields = section.controls.fields as UntypedFormArray;
  return fields
}

onAddSection() {
  this.showFields[this.currentSection] = false;
  this.addSection = true;
  const sections = this.sectionControls;
  this.order += 1;
  sections.push(this.newSection());
  this.selectSelected = false;
  // setTimeout(() => {
  //   M.updateTextFields();
  //   $('.dropdown-trigger').dropdown();
  //   $('.fixed-action-btn').floatingActionButton();
  //   $('.tooltipped').tooltip();
  //   $('select').formSelect();
  // }, 25);
}

sectionSelected(index) {
  this.currentSection = index;
}

expandSection(seciton) {
  this.showFields[seciton] = !this.showFields[seciton];
  // setTimeout(() => {
  //   M.updateTextFields();
  //   $('.dropdown-trigger').dropdown();
  //   $('.fixed-action-btn').floatingActionButton();
  //   $('.tooltipped').tooltip();
  //   $('select').formSelect();
  // }, 25);
}

onAddField() {
  this.addFields = true;
  const section = this.sectionControls.controls[this.currentSection] as UntypedFormGroup;
  const fields = section.controls.fields as UntypedFormArray;
  fields.push(this.newField());
  this.showFields[this.currentSection] = true;
  // setTimeout(() => {
  //   M.updateTextFields();
  //   $('.dropdown-trigger').dropdown();
  //   $('.fixed-action-btn').floatingActionButton();
  //   $('.tooltipped').tooltip();
  //   $('select').formSelect();
  // }, 25);
}
newField() {
  return this.fb.group({
    name: ["", [Validators.required]],
    form_control: [this.fieldName, Validators.required],
    default_value: [],
    field_type: ['Input', Validators.required],
    select_options: [],
    data_type: ['text', Validators.required],
    icon: [],
    color: [],
    required: [false],
    textMask: [false],
    methods: [],
    //validation: this.formBuilder.group([]),
  });
}

selectedField(values: any) {
  return this.fb.group({
    name: [values.name],
    form_control: [values.form_control],
    default_value: [''],
    field_type: [values.field_type],
    select_options: [values.select_options],
    data_type: [values.data_type],
    icon: [values.icon],
    //color: [],
    required: [values.required],
    textMask: [values.textMask],
    methods: [],
    //validation: this.formBuilder.group([]),
  });
}

onAddFields() {
  this.addFields = true;
  this.selectedFields = this.fields.filter(field => field.checked == true);
  this.selectedFields = this.selectedFields.sort((a, b) => a.order > b.order && 1 || -1);
  if (this.sectionControls.controls.length == 0) {
    this.onAddSection();
  }
  const section = this.sectionControls.controls[this.currentSection] as UntypedFormGroup;
  const fields = section.controls.fields as UntypedFormArray;
  this.selectedFields.forEach(field => {
    fields.push(this.selectedField(field));
    field.checked = false;
  });
  this.showFields[this.currentSection] = true;
  // setTimeout(() => {
  //   M.updateTextFields();
  //   $('.fixed-action-btn').floatingActionButton();
  //   $('.tooltipped').tooltip();
  //   $('select').formSelect();
  // }, 25);
  //toast({ html: 'Selected Fields are Successfully Added!', classes: 'green' });
  this.fieldsChecked = false;
}
deleteField(fieldIndex) {
  const section = this.sectionControls.controls[this.currentSection] as UntypedFormGroup;
  const fields = section.controls.fields as UntypedFormArray;
  fields.removeAt(fieldIndex);
}

deleteSection(sectionIndex) {
  const sections = this.sectionControls as UntypedFormArray;
  sections.removeAt(sectionIndex);
}

openDeleteModal(){
  this.deleteModalOpen = true;
}
closeModel(){
  this.deleteModalOpen = false;
}

delete(form){
  this.formService.deleteForm(form);
  this.toast({ html: 'Form Successfully Deleted', classes: 'red', type: 'failure' });
  this.closeModel()
}

truncateHTML(text: string, limit: string): string {
  const charlimit = +limit;
  if (!text || text.length <= charlimit) {
    return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ');
  }
  return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ').substring(0, charlimit) + '...';
}

get sectionName() { return `section${this.order}` };
get sectionControls() { return this.form.get('sections') as UntypedFormArray; }
get fieldName() {this.field += 1; return `field${this.field}`;}

toast(obj){
  this.toastMessage = obj.html;
  this.toastClass = obj.classes ? obj.classes : 'green' ;
  this.toastType = obj.type ? obj.type : 'success';
  this.openToast = true;
  setTimeout(() => {
    this.openToast = false;
    // this.backClicked();
  }, 2000);
}
}
