import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EmployeeConversationsModel, PrivateConversationsModel } from 'src/app/models/Conversations.Model';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { ConversationsService } from 'src/app/services/firebase/conversations.service';
import { MessagingService } from 'src/app/services/firebase/messaging.service';
import { UserService } from 'src/app/services/firebase/user.service';


@Component({
    selector: 'app-chats-list',
    templateUrl: './chats-list.component.html',
    styleUrls: ['./chats-list.component.css'],

})
export class ChatsListComponent implements OnInit {

    @Input() chatType
    @Output() groupSelected = new EventEmitter<{ group: Object }>();
    @Output() chatSelected = new EventEmitter<{ chat: Object }>();
    @Output() employeeChatSelected = new EventEmitter<{ chat: Object }>();

    isAdmin
    currentUser
    userId

    chats = []
    filteredChat = []
    privateChats = []
    employeeUsers = []
    groupChats = []

    isSearch = false
    searchQuery

    selectedGroupChat
    selectedPrivateChat
    selectedEmployeeChat
    loadingChats = false

    constructor(
        private authService: AuthService,
        private conversationsService: ConversationsService,
        private userService: UserService,
        private messagingService: MessagingService,
    ) { }


    ngOnInit() {
        this.currentUser = this.authService.getGlobalUser();
        if (this.currentUser) {
            if (this.currentUser.role == "Admin") {
                this.isAdmin = true;
            }
            this.userId = this.currentUser.uid;
        }
        if (this.userId) {
            if (this.chatType == 'group') {
                this.getGroupChats()

            } else if (this.chatType == 'private') {
                this.getPrivateChats()
            }
            else if (this.chatType == 'employee') {
                this.getEmployeeChats()
            }
        }
    }

    // Group Chats
    getGroupChats() {
        if (this.isAdmin) {
            this.loadingChats = true
            this.conversationsService.getAllGroups().valueChanges().subscribe(chats => {
                this.loadingChats = false
                if (chats) {
                    this.chats = chats;
                    this.chats.sort((a, b) => (b as any).lastMessage.timeSent.toDate() - (a as any).lastMessage.timeSent.toDate());
                    this.filteredChat = this.chats
                    this.groupChats = this.chats
                }
            });
        } else {
            this.loadingChats = true
            this.conversationsService.getGroupChatsByUserId(this.userId).valueChanges().subscribe(chats => {
                this.loadingChats = false
                if (chats) {
                    this.chats = chats;
                    this.chats.sort((a, b) => (b as any).lastMessage.timeSent.toDate() - (a as any).lastMessage.timeSent.toDate());
                    this.filteredChat = this.chats
                    this.groupChats = this.chats
                }
            });
        }
    }

    searchGroup() {
        this.isSearch = true
        if (this.searchQuery && this.searchQuery.trim() != '') {
            this.filteredChat = this.chats.filter(group => {
                return group.name.toLowerCase().includes(this.searchQuery.toLowerCase())
            })

        } else {
            this.filteredChat = this.chats
            this.isSearch = false

        }
    }

    selectGroupChat(chat, search = false) {
        if (search) {
            this.searchQuery = ''
            this.isSearch = false

        }
        this.selectedGroupChat = chat
        let unreadCount = this.selectedGroupChat.unreadCount.map(user=>{
            if(user.user === this.currentUser.uid) user.unreadCount =0
            return user
        })
        this.selectedGroupChat.unreadCount = unreadCount
        this.conversationsService.updateGroupChat(this.selectedGroupChat).then(x=>{
        // this.conversationsService.updateGroupUnreadCount(this.selectedGroupChat.id,unreadCount).then(x=>{
            this.groupSelected.emit({ group: chat })

        })

        // this.groupSelected.emit({ group: chat })
    }

    getGroupData(chat) {
        let user = chat.unreadCount.find(u => u.user === this.currentUser.uid)
        return {
            "img": chat?.profilePicture || '',
            "imgWidth": 14,
            "text": chat?.name,
            "hasSecondaryText": Object.keys(chat.lastMessage).length > 0 ? true : false,
            "secondaryText": chat?.lastMessage?.timeSent,
            "secondaryTextType": 'date',
            "unreadCount": user?.unreadCount
        }
    }


    // Employee chats
    getEmployeeChats() {
        if (this.currentUser) {
            if (this.currentUser.role == "Admin") {
                this.isAdmin = true;
            }
            this.userId = this.currentUser.uid;

            if (this.isAdmin) {
                this.userService.getUserByRole('Employee').valueChanges().subscribe((friends: any) => {
                    this.employeeUsers = friends
                    this.filteredChat = this.employeeUsers
                });
            }
    

            if (this.currentUser.role == "Admin") {
                this.conversationsService.getEmployeeChats().valueChanges().subscribe(chats => {
                    if (chats) {
                        this.chats = chats;
                        this.chats.sort((a, b) => (b as any).lastMessage.timeSent.toDate() - (a as any).lastMessage.timeSent.toDate());
                        // this.employeeChats = this.chats
                    }
                });
            } else if (this.currentUser.role == "Employee") {
                this.conversationsService.getEmployeeChatsByUserId(this.userId).valueChanges().subscribe(chats => {
                    if (chats) {
                        this.chats = chats;
                        this.chats.sort((a, b) => (b as any).lastMessage.timeSent.toDate() - (a as any).lastMessage.timeSent.toDate());
                        this.filteredChat = this.chats
                    }
                });
            }
        }
    }

    searchEmployeeChat() {
        this.isSearch = true
        if (this.searchQuery && this.searchQuery.trim() != '') {
            this.filteredChat = this.chats.filter(group => {
                return group.name.toLowerCase().includes(this.searchQuery.toLowerCase())
            })

        } else {
            this.filteredChat = this.chats
            this.isSearch = false

        }
    }
    searchEmployeeUser() {
        this.isSearch = true
        if (this.searchQuery && this.searchQuery.trim() != '') {
            this.filteredChat = this.employeeUsers.filter(friend => {
                return (friend.GivenName.toLowerCase().includes(this.searchQuery.toLowerCase()) || friend.LastName.toLowerCase().includes(this.searchQuery.toLowerCase()))
            })


        } else {
            this.filteredChat = this.employeeUsers
            this.isSearch = false

        }
    }
    selectEmployeeUser(user){
        this.isSearch = false
        let existingChat = this.chats.find(chat => chat.users.length ==2 && chat.users.includes(user.uid));

        if (!existingChat) {

            let model = new EmployeeConversationsModel()
            model.admin = this.currentUser.uid
            model.dateCreated = new Date()
            model.name =user.GivenName || user.FirstName + '' + user.LastName
            model.description = ''
            model.profilePicture = user.profileImgLink || ''
            model.users = [user.uid,this.currentUser.uid]
            model.usersInfo=[user, this.currentUser]
    
            let lastMessage = { message: '', timeSent: null };
            lastMessage.message = '';
            lastMessage.timeSent = new Date();
            model.lastMessage = lastMessage;
            model.previousMembers = [];
            model.unreadCount = model.users.map(user => { return { 'user': user, 'unreadCount': 0 } })
            
            this.selectEmployeeChat(this.conversationsService.createEmpConversation(model),true);


        } else {
            this.selectEmployeeChat(existingChat,true);

        }
    }

    selectEmployeeChat(chat, search = false) {
        if (search) {
            this.searchQuery = ''
            this.isSearch = false

        }
        this.selectedEmployeeChat = chat
        // this.employeeChatSelected.emit({ chat })

        let unreadCount = this.selectedEmployeeChat.unreadCount.map(user=>{
            if(user.user === this.currentUser.uid) user.unreadCount =0
            return user
        })
        this.selectedEmployeeChat.unreadCount = unreadCount
        this.conversationsService.updateEmployeeChat(this.selectedEmployeeChat).then(x=>{
        // this.conversationsService.updateGroupUnreadCount(this.selectedGroupChat.id,unreadCount).then(x=>{
            this.employeeChatSelected.emit({ chat })

        })
    }



    // Private Chats
    getPrivateChats() {
        // get all users for filter
        if (this.isAdmin) {
            this.userService.getAllUsers().valueChanges().subscribe((friends: any) => {
                this.chats = friends
                this.filteredChat = this.chats
            });
        } else {
            this.userService.getAllAdmins().valueChanges().subscribe((friends: any) => {
                this.chats = friends
                this.filteredChat = this.chats
            });
        }


        this.conversationsService.getPrivateChatsByUserId1(this.userId).valueChanges().subscribe(chats => {
            if (chats) {
                chats.forEach(element => {
                    let index = this.privateChats.findIndex(chat => chat.id === element.id);
                    if (index >= 0) {
                        this.privateChats.splice(index, 1);
                    }
                    this.privateChats.push(element);
                });
                this.privateChats.forEach(element => {
                    if (element.user_2.id === this.userId) {
                        [element.user_1, element.user_2] = [element.user_2, element.user_1];
                    }
                });
                // Sort out the list whenever there has been any change
                this.privateChats.sort((a, b) => (b as any).lastMessage.timeSent.toDate() - (a as any).lastMessage.timeSent.toDate());

            }
        });

        this.conversationsService.getPrivateChatsByUserId2(this.userId).valueChanges().subscribe(chats => {
            if (chats) {

                chats.forEach(element => {
                    let index = this.privateChats.findIndex(chat => chat.id === element.id);
                    if (index >= 0) {
                        this.privateChats.splice(index, 1);
                    }
                    this.privateChats.push(element);
                });
                this.privateChats.forEach(element => {
                    if (element.user_2.id === this.userId) {
                        [element.user_1, element.user_2] = [element.user_2, element.user_1];
                    }
                });
                // Sort out the list whenever there has been any change
                this.privateChats.sort((a, b) => (b as any).lastMessage.timeSent.toDate() - (a as any).lastMessage.timeSent.toDate());
            }
            // this.loadingChats = false;
            // this.loadingGroupChats = false;
        });
    }

    // suggest users during search
    searchChat() {
        this.isSearch = true
        if (this.searchQuery && this.searchQuery.trim() != '') {
            this.filteredChat = this.chats.filter(friend => {
                return (friend.GivenName?.toLowerCase().includes(this.searchQuery?.toLowerCase()) || friend.LastName?.toLowerCase().includes(this.searchQuery?.toLowerCase()))
            })


        } else {
            this.filteredChat = this.chats
            this.isSearch = false

        }
    }
    // select user from search
    selectUser(user) {
        this.isSearch = false
        let existingChat = this.privateChats.find(chat => chat.user_2.id == user.uid);

        if (!existingChat) {
            let newConversation = new PrivateConversationsModel();
            newConversation.dateCreated = new Date();
            newConversation.user_1 = {
                GivenName: this.currentUser.GivenName || this.currentUser.FirstName,
                LastName: this.currentUser.LastName,
                id: this.userId,
                profileImgLink: this.currentUser.profileImgLink || '',
                lastVisited: new Date(),
                unreadCount: 0,
                role: this.currentUser.role
            };
            newConversation.user_2 = {
                GivenName: user.GivenName || user.FirstName,
                LastName: user.LastName,
                id: user.uid,
                profileImgLink: user.profileImgLink || '',
                lastVisited: new Date(),
                unreadCount: 0,
                role: user.role

            };
            let lastMessage = { message: '', timeSent: null };
            lastMessage.message = '';
            lastMessage.timeSent = new Date();
            newConversation.lastMessage = lastMessage;
            this.selectPrivateChat(this.conversationsService.createPrivateConversation(newConversation));


        } else {
            this.selectPrivateChat(existingChat);

        }
    }

    // select private chat
    selectPrivateChat(chat) {
        this.selectedPrivateChat = chat
        this.chatSelected.emit({ chat })

        this.conversationsService.removeUnreadCount(this.selectedPrivateChat.id,this.currentUser.uid)
        // if (this.selectedPrivateChat.user_2.id === this.currentUser.uid){
        //     this.selectedPrivateChat.user_2.unreadCount = 0
        // }else{
        //     this.selectedPrivateChat.user_1.unreadCount = 0

        // }

        // this.conversationsService.upda(this.u).then(x=>{
        // // this.conversationsService.updateGroupUnreadCount(this.selectedGroupChat.id,unreadCount).then(x=>{
        //     this.employeeChatSelected.emit({ chat })

        // })
    }

    getUserData(user) {
        return {
            "img": user.profileImgLink || '',
            // "img": user.profilePicture || '',
            "imgWidth": 14,
            "text": `${user.GivenName || user.FirstName} ${user.LastName}`,
            "secondaryTextType": 'date'
        }
    }
    getPrivateChatData(chat) {
        return {
            "img": chat.user_2.profileImgLink || '',
            "imgWidth": 14,
            "text": `${chat.user_2.GivenName || chat.user_2.FirstName} ${chat.user_2.LastName}`,
            "hasSecondaryText": Object.keys(chat.lastMessage).length > 0 ? true : false,
            "secondaryText": chat.lastMessage.timeSent,
            "secondaryTextType": 'date',
            "unreadCount": chat.user_1.unreadCount

        }
    }



}
