import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Modal, initTE } from "tw-elements";

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.css']
})
export class PreviewComponent implements OnInit {

  @Input() sectionName;
  @Input() section;
  @Input() nationOfuse;
  @Output() closePreview = new EventEmitter<string>();



  view = 'web'
  constructor(private router: Router,) { }

  ngOnInit(): void {
    initTE({ Modal });
    const btn1 = document.getElementById('previewClose');
    this.router.events.subscribe((val) =>{
      btn1.click()
    })
  }
  changeViewType(view){
    this.view = view
  }

  preview(){
    console.log('preview')
  }
  previewClose(){
    this.closePreview.emit()
  }
}
