<ng-container *ngIf="data">
    <div class="flex flex-col justify-between p-5 md:p-7 rounded-xl border-2  h-full w-full md:w2/3 bg-white" 
                [ngClass]="getBorder(data.platform)"
                [ngStyle]="borderdPlatform() ? getStyleBorder() : ''"
                >

        <div class="w-full flex flex-row gap-1 justify-start">
            <i *ngIf="data.platform!== 'tiktok'" [ngClass]="'text-3xl mr-2 fa ' + socialMediaIcons[data.platform].text + ' ' + socialMediaIcons[data.platform].color"></i>      
            <svg class="p-0 mr-2" *ngIf="data.platform == 'tiktok'" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="35" height="35" viewBox="0 0 50 50">    <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z"></path></svg>
            <div class="">
                <p [ngClass]="{'font-bold' : data.middleSubText}">{{(data.middleSubText ? data.platform  : data.username) | titlecase}}</p>
                <p *ngIf="!data.middleSubText" class="text-sm text-[#636363]">{{data.subText}}</p>
            </div>
        </div>
        <div class="px-0  md:px-8" [ngClass]="{'pt-7 md:pt-16' : !data.subContent, 'pt-3 md:pt-6' : data.subContent}">
            <div class="pb-3" *ngIf="data.middleSubText">
                <p class="text-sm text-[#636363]">{{data.subText}}</p>
            </div>
            <div *ngIf="!data.content.showError" [ngClass]="'w-full  grid grid-cols-3 md:grid-cols-4 gap-4 justify-items-start  text-[' + data.color + ']'">
            
                <div *ngFor="let item of data.content | keyvalue" class="text-center ">
                    <p class="text-3xl md:text-4xl  font-extrabold">{{item.value}}</p>
                    <p class="text-black">{{item.key}}</p>
                </div>
            </div>
            <div *ngIf="data.content.showError">
                <p class="text font-extrabold">{{data.content.message}}</p>
            </div>
        </div>
        
        <div *ngIf="data.subContent" class="pt-7 md:pt-10 px-0 md:px-8">
            <div class="pb-3">
                <p class="text-sm text-[#636363]">{{data.secondSubText}}</p>
            </div>
            <div [ngClass]="'w-full  grid grid-cols-3 md:grid-cols-4 gap-4 justify-items-start  text-[' + data.color + ']'">
                <div *ngFor="let item of data.subContent | keyvalue" class="pt-2 text-center ">
                    <p class="text-3xl md:text-4xl  font-extrabold">{{item.value}}</p>
                    <p class="text-black">{{item.key}}</p>
                </div>
            </div>
        </div>
        
        
    </div>
</ng-container>
