import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';


@Injectable({
  providedIn: 'root'
})
export class AboutService {
  constructor(private db: AngularFirestore) { }

  saveAbout(model: any) {
    model.id = this.db.createId();
    return this.db.collection('AboutUs').doc(model.id).set(model);
  }

  updateAbout(model: any) {
    return this.db.collection('AboutUs').doc(model.id).update(model);
  }

  getAbout() {
    return this.db.collection('AboutUs');
  }
  deleteArea(model: any)  {
    return this.db.collection('AboutUs').doc(model.id).delete();
  }

}
