import { createReducer, on } from '@ngrx/store';
import * as AppActions from './app.actions';
import { UserState } from './app.state';

export const initialState = {
    loggedInUser: {},
    isLoggedIn: false,
};
export const initialUserState: UserState = null;
export const initialSettingsState = { settings: {},
  
};


const _userReducer = createReducer(
    initialState,
    on(AppActions.loginUser, (state, { username, password }) => ({
      ...state,
      loggedInUser: { username, password },
      isLoggedIn: true,
    })),
    on(AppActions.addUser, (state, UserState) => ({
      ...state,
      ...UserState
    })),
    on(AppActions.fetchUserData, (state, { userId }) => ({
      ...state,
      loggedInUser: { userId },
    })),
    on(AppActions.updateUserData, (state) => ({
      ...state,
    })),
    on(AppActions.logout, (state, { userId }) => ({
      ...state,
      loggedInUser: { userId },
      isLoggedIn: false,
    }))
  );
const _appSettingsReducer = createReducer(  
    initialSettingsState,
    on(AppActions.getLandingPageSEttings, (state) => ({
      ...state,
    })),
    on(AppActions.addAppSettings, (state, { settings }) => ({
      ...state,
      ...settings
    }))
  );


export function userReducer(state, action) {
    return _userReducer(state, action);
  }
export function appSettingsReducer(state, action) {
    return _appSettingsReducer(state, action);
  }