import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { UserService } from 'src/app/services/firebase/user.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.css']
})
export class EmployeeListComponent implements OnInit {

  loggedUser: any;
  loading = false;
  employees = [];
  hasAppointment = false;

  constructor(
    private location: Location,
    private authService: AuthService,
    private userService: UserService,
    private appSettingsService: AppSettingsService

  ) {
    this.loggedUser = this.authService.getGlobalUser();

  }

  ngOnInit(): void {
    this.userService.getUserByRole('Employee').valueChanges().subscribe(users => {
      if (users.length > 0){
        this.employees = users;
      }
    });
    this.appSettingsService.getAppSettingsList().valueChanges().subscribe((settings: any) => {
      if (settings.length > 0){
        this.hasAppointment = settings[0].takeAppointment;
      }
    });

  }

  backClicked() {
    this.location.back();
    }

  }
