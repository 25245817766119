import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/firebase/user.service';
import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation, Injectable, ChangeDetectorRef } from '@angular/core';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { Location, formatDate} from '@angular/common';
import { AppointmentService } from 'src/app/services/firebase/employee-appointment.service';
import { CalendarEvent, CalendarView, DAYS_OF_WEEK, CalendarDateFormatter, DateFormatterParams, CalendarEventAction } from 'angular-calendar';
import { EmployeeServicesService } from 'src/app/services/firebase/employee-services.service';
import { Subject } from 'rxjs';
import { startOfDay, isAfter, isBefore, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours} from 'date-fns';
import { TaskService } from 'src/app/services/firebase/task.service';
import { isSameYear, isSameWeek, isToday, parseISO, addMinutes, getDay } from 'date-fns';
import { rippleMouseHandler } from '@syncfusion/ej2-buttons';

@Injectable()
export class CustomDateFormatter extends CalendarDateFormatter {
  public weekViewHour({ date, locale }: DateFormatterParams): string {
    return formatDate(date, 'h:mm a', locale);
  }
  public weekViewColumnHeader({ date, locale }: DateFormatterParams): string {
    return formatDate(date, 'EEE', locale);
  }
}

@Component({
  selector: 'app-employee-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
        .cal-week-view .cal-time-events .cal-day-column {
          margin-right: 10px;
        }

        .cal-week-view .cal-hour {
          width: calc(100% + 10px);
        }
        .my-custom-class span {
          color: #000000 !important;
        }
      `,
    ],
  providers: [{
  provide: CalendarDateFormatter,
  useClass: CustomDateFormatter,
  }]
})
export class AppointmentsComponent implements OnInit {

  loggedUser: any;
  loading = false;
  employeeId: any;
  employeeSchedule: any;
  appointmentsList: any;
  deleteAppointmentForm: UntypedFormGroup;
  view: CalendarView = CalendarView.Week;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  refresh = new Subject<void>();
  weekStartsOn = DAYS_OF_WEEK.MONDAY;
  selectedAppointment: any;
  selectedService: any;
  appointmentMadeBy: any;
  deleteModalOpen = false;
  editModalOpen = false;
  isCalendarView = true;
  empTask = [];

  taskDetail: boolean = false;
  selectedTask : any;

  actions: CalendarEventAction[] = [
    // {
    //   label: '<i class="material-icons ">edit</i>',
    //   a11yLabel: 'Edit',
    //   onClick: ({ event }: { event: CalendarEvent }): void => {
    //     this.handleEvent('Edit', event);
    //     this.editModalOpen = true;
    //   },
    // },
    {
      label: '<i class="material-icons">delete</i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter((iEvent) => iEvent !== event);
        this.handleEvent('Delete', event);

        this.deleteModalOpen = true;
      },
    },
  ];

  events: CalendarEvent[] = [
    // {
    //   start: addHours(startOfDay(new Date()), 2),
    //   end: addHours(new Date(), 2),
    //   title: 'A draggable and resizable event',
    //   color: {
    //     primary: '#e3bc08',
    //     secondary: '#FDF1BA',
    //    },
    //   actions: this.actions,
    //   resizable: {
    //     beforeStart: true,
    //     afterEnd: true,
    //   },
    //   draggable: true,
    // },
  ];

  constructor(
    private location: Location,
    private authService: AuthService,
    private appointmentService: AppointmentService,
    private empService: EmployeeServicesService,
    private cdr: ChangeDetectorRef,
    private userService: UserService,
    private fb: UntypedFormBuilder,
    private tasksService : TaskService,
  ) {
    this.employeeId = this.authService.getGlobalUser().uid;
    this.getAppointments();
    this.getAssignTasks();
    this.refresh.next();
  }

  ngOnInit(): void {
    this.deleteAppointmentForm = this.fb.group({
      reason: ['', Validators.required]
    });
    //this.refresh.next();
  }

  get reason() {return this.deleteAppointmentForm.get('reason'); }

  createEvent(appointment) {
    const data = {
      start: appointment.startTime.toDate(),
      end: appointment.endTime.toDate(),
      title: `<p class="font-bold text-lg">${appointment.serviceName}</p>`,
      serviceId: `${appointment.serviceId}`,
      appointmentId: `${appointment.id}`,
      color: {
          primary: '#1e90ff',
          secondary: '#D1E8FF',
          },
      cssClass: 'my-custom-class',
    };
    return data;
  }

  createTask(task){
    const data = {
      start: task.reminderTime ? new Date(task.reminder + 'T' + task.reminderTime) : new Date(task.reminder),
      end: task.endReminderTime ?  addMinutes(new Date(task.endReminder + 'T' + task.endReminderTime),15)  : new Date(task.endReminder),
      title: `<p class="font-bold text-lg"> Task :- ${this.truncateHTML(task.taskDescription, '23')} </p>`,
      appointmentId: `${task.id}`,
      isTask: true,
      allDay: task.reminderTime && task.endReminderTime ? false : true,
      color: {
        primary: '#79e868',
        secondary: '#79e868'
      },
      cssClass: 'my-custom-class',
    }
    return data;
  }

  eventClicked({ event }): void {
    if(event.isTask){
      this.openTaskDetail();
      this.tasksService.getTasksById(event.appointmentId).valueChanges().subscribe((taskSelected) =>{
        if(taskSelected && taskSelected.length != 0){
        this.selectedTask = taskSelected[0];
        }

      })

    }
    this.empService.getServiceById(event?.serviceId).valueChanges().subscribe(service => {
      if (service){
        if (service.length > 0){
          this.selectedService = service[0];
        }
      }
    });

    this.appointmentService.getAppointmentById(event.appointmentId).valueChanges().subscribe((appointment: any) => {
      if (appointment){
        if (appointment.length > 0){
          this.selectedAppointment = appointment[0];
          this.userService.getUserById(appointment[0].userId).valueChanges().subscribe((user) => {
            if (user.length > 0){
              this.appointmentMadeBy = user[0];
              // $('#appointmentDetailsModal').modal('open');
            }
          });
        }
      }
    });
  }

  getAppointments() {
    //this.loading = true;
    this.appointmentService.getAppointmentByEmployeeId(this.employeeId).valueChanges().subscribe(a => {
      this.loading = false;
      if (a.length > 0) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        this.appointmentsList = a;
        this.recreateEventsList();
      } else {
        this.appointmentsList = [];
      }
    });
    this.events = [...this.events];
    //this.loading = false;
  }

  getAssignTasks(){
    this.loading = true;
    this.tasksService.getTasksMadeTo(this.employeeId).valueChanges().subscribe((empTask) =>{
      if(empTask && empTask.length != 0){
        this.empTask = empTask;
        this.recreateEventsList();
      }else{
        this.empTask = [];
      }
    });
    this.events = [...this.events];
    this.loading = false;
  }

  recreateEventsList() {
    this.events = [];
    this.appointmentsList.forEach(appointment => {
      const event = this.createEvent(appointment);
      this.events.push(event);
    });
    this.empTask.forEach(task =>{
      const event = this.createTask(task);
      this.events.push(event);
    });

    this.cdr.detectChanges();
  }
  closeModal() {

  }
  openTaskDetail(){
    this.taskDetail = true;
  }

  closeTaskDetail(){
    this.taskDetail = false;
  }

  backClicked() {
    this.location.back();
  }

  handleEvent(action: string, event: CalendarEvent): void {
    // this.modalData = { event, action };
    if (action === 'Delete'){
      this.deleteModalOpen = true;
    }
    if (action === 'Edit'){
      this.editModalOpen = true;
    }
    // this.modal.open(this.modalContent, { size: 'lg' });
  }
  closeEditModal(){
    this.editModalOpen = false;
  }
  closeDeleteModal(){
    this.deleteModalOpen = false;
  }
  deleteAppointment(value){
    // delete appointment

    // send an email for the user
    this.deleteModalOpen = false;
  }
  toggleView(){
    this.isCalendarView = !this.isCalendarView;
  }
  truncateHTML(text: string, limit: string): string {
    const charlimit = +limit;
    if (!text || text.length <= charlimit) {
      return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ');
    }
    return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ').substring(0, charlimit) + '...';
  }
}
