import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { Comment } from 'src/app/models/PostsModel';

@Injectable({
  providedIn: 'root'
})
export class CommentsService {
  constructor(private db: AngularFirestore, private authService: AuthService){ }

  postNamesMapping = {
    'event': 'Events',
    'blog': 'Posts',
    'service': 'Services',
    'product': 'Products',
    'custom': 'CustomSectionElements'
  }
  postsListRef: AngularFirestoreCollection<any>;
  postRef: AngularFirestoreDocument<any>;

  updateReactionOfApost(post, postType){
    return this.db.collection(this.postNamesMapping[postType]).doc(post.id).update({reactions: post.reactions});
  }

  addCommentToPost(post, newCommentModel: Comment, postType){
    const newContent = {
      id: this.db.createId(),
      postId: newCommentModel.postId,
      userId: newCommentModel.userId,
      userName: newCommentModel.userName,
      comment: newCommentModel.comment,
      timestamp: newCommentModel.timestamp,
      likes: newCommentModel.likes,
      replies: newCommentModel.replies
  };
    post.comments.push(newContent);
    return this.db.collection(this.postNamesMapping[postType]).doc(post.id).update({comments: post.comments});
  }

  updateComments(post, postType){
    return this.db.collection(this.postNamesMapping[postType]).doc(post.id).update({comments: post.comments});
  }

}
