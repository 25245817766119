import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private db: AngularFirestore, private dbF: AngularFireDatabase) { }
  addNotification(model){
    const newContent = {
      id: this.db.createId(),
      owner: model.owner,
      type: model.type,
      timeStamp: model.timeStamp,
      refrenceId: model.refrenceId,
      createdById: model.createdById,
      createdByFullName: model.createdByFullName,
      isSeen: false
    };
    return this.db.collection('Notifications').doc(newContent.id).set(newContent);
  }

  getMyNotifications(userId){
    return this.db.collection('Notifications', ref => ref.where('owner', '==', userId));
  }

  deleteNotification(notificationModel){
    return this.db.collection('Notifications').doc(notificationModel.id).delete();
  }
  getRecordAdded() {
    console.log("Event added: ")
    return this.dbF
      .list('/AppointmentSettings') // Change this to your database path
      .snapshotChanges()
      .pipe(
        map((actions) =>
        actions.map((action) => {
          const data = action.payload.val();
          return {
            key: action.key,
            ...(typeof data === 'object' && data !== null ? data : {}), // Ensure it's an object before spreading
          };
        })
      ))
}
markNotificationAsSeen(id){
  return this.db.collection('Notifications').doc(id).update({ isSeen: true });
}
}