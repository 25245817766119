import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';

@Component({
  selector: 'app-rename-section',
  templateUrl: './rename-section.component.html',
  styleUrls: ['./rename-section.component.css']
})
export class RenameSectionComponent implements OnInit {
  renameModal = false
  pageSections: any;
  @Input() section;
  @Input() calledFromComponent;
  @Output() sectionRenamed = new EventEmitter<string>();
  name = ''
  constructor(  private settingsService: AppSettingsService,) { }

  ngOnInit(): void {
    this.settingsService.getAppSettingsList().valueChanges().subscribe(hp => {
      if (hp) {
        if (hp.length > 0) {
          this.pageSections = hp[0];
          this.pageSections.appSections.forEach(element => {
            if(element.originalName == this.section){
              this.name = element.name;
            }      
          });
        }
      }
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.name = this.section;

  }
  saveChanges(){
    this.pageSections.appSections.forEach(element => {
      if(element.originalName == this.section){
        element.name = this.name;
      }      
    });
    

    this.settingsService.updateAppSettings(this.pageSections).then(x => {
      if(this.calledFromComponent=='onboarding'){
        this.sectionRenamed.emit(this.section)
      }
      // this.toast({ html: 'Changes Saved Successfully.', classes: 'green' });
    });
  }
}
