import { Component, Input, OnInit } from '@angular/core';
import { HelperService } from 'src/app/services/helper/helper';

@Component({
  selector: 'app-custom-list-default',
  templateUrl: './custom-list-default.component.html',
  styleUrls: ['./custom-list-default.component.css']
})
export class CustomListDefaultComponent implements OnInit {

  @Input() sections;
  @Input() itemNumber
  constructor(
    private helperServices: HelperService
  ) { }

  ngOnInit(): void {
  }
  truncateHTML(text: any, limit): string {
    return this.helperServices.truncateHTML(text, limit);
   }

   getInnerText(text){
    return this.helperServices.getInnerText(text);
  }
}
