<!-- To skip setup on the onboarding page -->
<!-- <div *ngIf="calledFromEmptyDashboard!= 'About' " class="flex flex-col justify-center space-y-4">
  <p class="text-xl font-bold">Add a section in your about us page</p>
  <div *ngIf="calledFromEmptyDashboard !== 'About' || calledFrom != 'onBoarding'"class="flex space-x-4" >
    <app-button class="justify-end items-end" (click)="openCreateModal('values')" [customConfig]="{'text':'Add section'}"></app-button>
    <button type="button" *ngIf="calledFromEmptyDashboard == 'About'" (click)="skipSetup()" class="flex space-x-1 items-center rounded border-2 text-customTeal border-customTeal px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:border-primary-accent-100 hover:bg-neutral-500 hover:bg-opacity-10 focus:border-primary-accent-100 focus:outline-none focus:ring-0 active:border-primary-accent-200 dark:text-primary-100 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10">
      Skip 
    </button>
  </div>
</div> -->
<div *ngIf="!hideOnAddSection " class="flex flex-col justify-center space-y-4">
  <!-- section -->
  <div class="w-full" *ngIf=" calledFromEmptyDashboard !='showEmployee'"  [ngClass]="{' py-4 space-y-4':calledFromEmptyDashboard !='showEmployee'}">
    <ng-container *ngIf="model && model.sections">
      <ng-container *ngIf="calledFromEmptyDashboard == 'About'">
        <div class="flex flex-wrap ">
          <div *ngFor="let section of model.sections;let i = index" class="w-full   md:mx-4  md:mb-10 flex    border bg-white md:w-[341px] md:h-[300px] drop-shadow-md rounded-lg hover:scale-100 hover:ease-in hover:duration-300">
            <div class=" flex flex-col w-full   px-4  py-4  md:px-6   md:h-max border border-gray-200    rounded-lg hover:scale-100 hover:ease-in hover:duration-300">
              <ng-container>
                <div class="w-full ">
                  <div *ngIf="section.imgUrl" class="w-full md:h-48 rounded-xl cursor-pointer">
                    <div class="flex justify-center md:h-48 ">
                      <img [src]="section.imgUrl" class="md:w-56 md:h-full w-full rounded-lg md:object-contain" >
                    </div>
                  </div>
                  <div class="break-words">
                    <h6 class=" cursor-pointer max-w-sm text-xl font-bold py-2 text-center">
                      {{truncateHTML(section.sectionTitle,200)  | titlecase }}
                    </h6>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>

<!-- List of sections(header and footer buttons) -->
<div class=" mx-3 lg:mx-10 rounded-md md:px-5 overflow-x-hidden" *ngIf="!addSection && calledFromEmptyDashboard != 'About'"  >
  <div class="flex flex-col space-y-4" *ngIf="model" [ngClass]="{'gap-y-4 mx-3 lg:mx-10  rounded-md md:px-5  mt-3': calledFromEmptyDashboard !='showEmployee'}">
    <!-- Title -->
    <div class="font-bold text-lg text-gray-600" *ngIf="calledFromEmptyDashboard !='showEmployee' && calledFrom != 'onBoarding'">
      {{aboutUsName}} Customization
      <div class="tooltip" data-tip="This page allows you to customize the about page">
        <button type="button"><i class="material-icons">help</i></button>
      </div>
    </div>
    <div class="flex flex-row">
      <app-homepage-section-number  *ngIf="calledFrom != 'onBoarding'" section="About Us" (confirmationModal) = "openConfirmationModal()"></app-homepage-section-number>
      <app-rename-section section="About Us" ></app-rename-section>
    </div>
    <div  class="flex flex-col space-y-6 " >
      <!-- show employee -->
      <div *ngIf="calledFrom != 'onBoarding'" class="form-control  w-full " [ngClass]="{'bg-white px-10 py-6 ':calledFromEmptyDashboard !='showEmployee'}">
        <div class="flex w-full justify-between">
          <div class="flex space-x-4 items-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="md:w-8 md:h-8 w-4 h-4" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M224 256A128 128 0 1 1 224 0a128 128 0 1 1 0 256zM209.1 359.2l-18.6-31c-6.4-10.7 1.3-24.2 13.7-24.2H224h19.7c12.4 0 20.1 13.6 13.7 24.2l-18.6 31 33.4 123.9 36-146.9c2-8.1 9.8-13.4 17.9-11.3c70.1 17.6 121.9 81 121.9 156.4c0 17-13.8 30.7-30.7 30.7H285.5c-2.1 0-4-.4-5.8-1.1l.3 1.1H168l.3-1.1c-1.8 .7-3.8 1.1-5.8 1.1H30.7C13.8 512 0 498.2 0 481.3c0-75.5 51.9-138.9 121.9-156.4c8.1-2 15.9 3.3 17.9 11.3l36 146.9 33.4-123.9z"/></svg>
            <span class=" text-[#4F4F4F] md:text-xl font-bold leading-5">Show Employees</span>
          </div>
          <div class="form-control">
            <label class="label cursor-pointer">
              <input type="checkbox" class="toggle checked:bg-customTeal" [(ngModel)]="model.showEmployees"/>
              <span class=" text-base font-bold pl-2">Show</span> 
            </label>
          </div>
        </div>
      </div>

    <!-- section -->
    <div class="w-full" *ngIf=" calledFromEmptyDashboard !='showEmployee' "  [ngClass]="{'bg-white px-10 py-4 space-y-4':calledFromEmptyDashboard !='showEmployee'}">
      <div class="flex flex-col md:flex-row md:justify-between w-full  gap-y-4" *ngIf="(!model.sections || model.sections.length < 10) && calledFrom != 'onBoarding'"  >
        <div class="flex space-x-4 items-center">
          <svg class="md:w-8 md:h-8 w-4 h-4" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.75 5.625V39.375C3.75 40.5 4.5 41.25 5.625 41.25H26.25V3.75H5.625C4.5 3.75 3.75 4.5 3.75 5.625ZM39.375 3.75H30V41.25H39.375C40.5 41.25 41.25 40.5 41.25 39.375V5.625C41.25 4.5 40.5 3.75 39.375 3.75Z" fill="black"/>
            </svg> 
          <span class=" text-[#4F4F4F] md:text-xl font-bold leading-5">Add a new section</span>
        </div>
        <app-button class="justify-end items-end" (click)="openCreateModal('values')" [customConfig]="{'text':'Add section'}"></app-button>

      </div>
      <hr>
      <ng-container *ngIf="model && model.sections">
        <div class="flex flex-col span-y-3" *ngFor="let section of model.sections;let i = index">
          <div class="flex flex-col md:justify-between md:flex-row divide-y">
            <!-- Description -->
            <div class=" space-y-2  " [ngClass]="{'md:w-7/12': calledFrom != 'onBoarding', 'md:w-full': calledFrom == 'onBoarding'}">
              <p class="font-bold">
                <!-- {{section.sectionTitle}} -->
                {{truncateHTML(section.sectionTitle,200)  | titlecase }}
              </p>
              <p class=" text-xs text-darkGray" *ngIf="section.sectionCatagory=='values'">
                Choose whether or not to feature {{section.displayName}} on your landing page. You may also change this
                section title to better reflect the type of {{section.displayName}} that you post.
              </p>
            </div>
            <!-- toggle & edit -->
            <div class="flex justify-center align-middle mt-3 md:mt-1 md:space-x-8 space-x-5 items-baseline" *ngIf="calledFrom != 'onBoarding'">
              <!-- toggle -->
              <div>
                <div class="form-control">
                  <label class="label cursor-pointer">
                    <span class=" pr-2 font-bold ">ON</span>
                    <input type="checkbox" class="toggle checked:bg-customTeal " [checked]="section.visible"
                      (change)="section.visible = !section.visible" />
                  </label>
                </div>
              </div>
              <!-- edit -->
              <div>
                <button type="button" [disabled]="!section.visible" (click)="openEditSection(section,i);">
                  <i class="material-icons white-text">edit</i>
                </button>

              </div>
              <!-- delete -->
              <div>
                <button type="button" (click)="openDeleteSection(i);">
                  <i class="material-icons text-red-700">delete</i>
                </button>

              </div>
              <!-- move -->
              <div>
                <div class="flex">
                  <div class="w-1/2">
                    <button type="button" class="btn-small btn-flat red" [disabled]="!section.visible"
                      (click)="moveSection(section, 1);"> <i class="material-icons white-text">expand_more</i></button>
                  </div>
                  <div class="w-1/2">
                    <button type="button" class="btn-small btn-flat green" [disabled]="!section.visible "
                      (click)="moveSection(section, -1);">
                      <i class="material-icons white-text">expand_less</i></button>
                  </div>
                </div>


              </div>

            </div>
          </div>
    

        </div>
      </ng-container>

    </div>

    </div>
    <!-- buttons -->
    <div class="flex flex-row justify-end w-full max-w-x ">
      <div class="form-control flex flex-row gap-x-5 ">
        <button type="button" *ngIf="calledFromEmptyDashboard == 'showEmployee'" 
        (click)="skipSetup()"
              class="flex space-x-1 items-center rounded border-2 text-customTeal border-customTeal px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:border-primary-accent-100 hover:bg-neutral-500 hover:bg-opacity-10 focus:border-primary-accent-100 focus:outline-none focus:ring-0 active:border-primary-accent-200 dark:text-primary-100 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                  >
          Skip 
        </button>
        <button type="button" (click)="SaveSettings()"
                *ngIf="calledFrom != 'onBoarding'"
                [disabled]="onLoading"
                class="flex items-center space-x-2 rounded bg-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                  [ngClass]="{'bg-[#cccccc] text-[#666666]': onLoading,
                  'bg-customTeal': !onLoading
                }">
                  <div
                    *ngIf="onLoading"
                    class="inline-block h-4 w-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-neutral-100 motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status">
                    <span
                        class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                        >Loading...</span
                    >
                    </div>
                    Save
                </button>
      </div>
    </div>
  </div>
</div>

<!-- add new section -->
<div class=" mt-3 overflow-x-hidden" *ngIf="addSection"  [ngClass]="{'px-4 md:px-10 ': calledFromEmptyDashboard !='About'}">
  <h3 class="font-bold text-lg mb-6 text">Add new section</h3>
  <form [formGroup]="newSectionForm" (ngSubmit)="saveSection(newSectionForm.value)">

    <div class="flex flex-col gap-y-3" *ngIf="!preview">
      <div class="flex flex-col gap-y-5">
        <!--Title Section-->
        <div class="flex space-y-2   flex-col">
          <div class="flex w-full justify-end ">
            <app-preview   sectionName="About" [section]="previewData" (click)="openPreview(newSectionForm.value)" (closePreview)="closePreviewModal()"></app-preview>
          </div>
          <div class="form-control w-full   bg-white md:px-10 px-2 py-6 space-y-2 shadow-sm ">
            <div class="flex space-x-4 items-center">
        
                <svg width="36" height="36" viewBox="0 0 55 55" fill="blue"  class="text-gray-400 " xmlns="http://www.w3.org/2000/svg">
                  <path d="M9 12H45.6667V16.5833H9V12ZM9 21.1667H31.9167V25.75H9V21.1667Z" fill="black"/>
                  <rect x="9" y="30.3333" width="22.9167" height="4.58333" fill="black"/>
                  <path d="M44.4785 22.375C44.0226 22.375 43.5855 22.5561 43.2631 22.8784C42.9408 23.2007 42.7597 23.6379 42.7597 24.0938C42.7597 24.5496 42.9408 24.9868 43.2631 25.3091C43.5855 25.6314 44.0226 25.8125 44.4785 25.8125C44.9343 25.8125 45.3715 25.6314 45.6938 25.3091C46.0162 24.9868 46.1972 24.5496 46.1972 24.0938C46.1972 23.6379 46.0162 23.2007 45.6938 22.8784C45.3715 22.5561 44.9343 22.375 44.4785 22.375ZM44.066 27.8406C42.7024 27.9552 38.9785 30.9229 38.9785 30.9229C38.7493 31.0948 38.8181 31.0833 39.0014 31.4042C39.1847 31.7135 39.1618 31.7365 39.3795 31.5875C39.6087 31.4385 39.9868 31.1979 40.617 30.8083C43.0462 29.25 41.0066 32.8479 39.9639 38.9094C39.5514 41.9115 42.2556 40.3646 42.9545 39.9063C43.642 39.4594 45.4868 38.1875 45.6701 38.0615C45.9222 37.8896 45.7389 37.7521 45.5441 37.4656C45.4066 37.2708 45.2691 37.4083 45.2691 37.4083C44.5243 37.901 43.1608 38.9323 42.9774 38.2792C42.7597 37.626 44.1576 33.1458 44.9254 30.0635C45.0514 29.3302 45.3951 27.726 44.066 27.8406V27.8406Z" fill="black"/>
                  <rect x="9" y="38.3542" width="22.9167" height="4.58333" fill="black"/>
                  </svg>

                <div class="w-10/12">
                  <p class="font-bold text-lg">Title <span class="text-red-500 text-sm">*</span></p>
                  <p class="text-xs text-gray-500">This is the first part of your post that your readers will see and the part that's responsible for getting them to click through and read the rest.</p>
                </div>
            </div>
            <app-editor [control]="sectionTitle" [inputType]="'title'" [fromComponent]=aboutUsName></app-editor>
            <div *ngIf="(sectionTitle.dirty || sectionTitle.touched) && sectionTitle.invalid">
              <small *ngIf="sectionTitle.errors.required" class="text-red-700">Section title is required.
              </small>
            </div>
          </div>         
        </div>
        
        <!-- Description -->
        <ng-container *ngIf="['main','mission'].includes(sectionCatagory.value)" class="">
          <div class="form-control w-full max-w-x">
            <div class="flex space-y-2   flex-col">
              <label for="sectionDescription" class="text-xs text-gray-500 font-bold ">Section Description</label>

              <app-editor [control]="sectionDescription" [fromComponent]=aboutUsName></app-editor>

              <div *ngIf="(sectionDescription.dirty || sectionDescription.touched) && sectionDescription.invalid">
                <small *ngIf="sectionDescription.errors.required" class="text-red-700">Section description is
                  required.
                </small>
              </div>
            </div>

          </div>
        </ng-container>

        <ng-container *ngIf="sectionCatagory.value == 'main'" class="">
          <div class="form-control w-full">
            <label class="label">
              <span class=" text-black text-base font-bold leading-5">Select position</span>
            </label>
            <select class="select select-bordered w-8/12" formControlName="textPosition">
              <option *ngFor="let item of textPositions | keyvalue" [value]="item.value">
                {{item.key}}</option>
            </select>
          </div>
        </ng-container>

        <ng-container *ngIf="sectionCatagory.value == 'mission'">

          <div class="form-control w-full max-w-x">
            <label class="label py-2">
              <span class=" text-[#4F4F4F] text-base font-bold leading-5">Text position</span>
            </label>
            <div class="flex justify-between w-1/2 ">
              <div class="form-control">
                <label class="label cursor-pointer">
                  <input type="radio" formControlName="leftText" [value]=true class="radio checked:bg-customTeal" />
                  <span class=" ml-4 font-normal text-black">Left</span>

                </label>
              </div>
              <div class="form-control">
                <label class="label cursor-pointer">
                  <input type="radio" formControlName="leftText" [value]=false
                    class="radio checked:bg-customTeal" />
                  <span class=" ml-4 font-normal text-black">Right</span>

                </label>
              </div>
            </div>
          </div>

          <div class="space-y-2">
            <div class="space-y-2">
              <p class="font-bold">
                Bullet points
              </p>
              <div class="flex flex-wrap  ">
                <!-- button -->
                <div *ngFor="let item of newSectionForm.get('missionItems').value;let i=index">
                  <button type="button" class="border-2 border-customTeal rounded-full py-1 px-4 mx-2 my-2"
                    (click)="removeMissionItems(i,true)">
                    <span>{{item}}</span>
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </span>

                  </button>
                </div>

              </div>
            </div>

            <div class="form-control max-w-x w-full md:w-7/12">
              <input
                class=" w-full border rounded py-3 px-3 text-xs text-gray-700 leading-tight bg-veryLightGray focus:outline-gray-200 focus:shadow-outline "
                id="newMission" type="text" placeholder="Type a bullet point, then select 'add'" required>
              <button type="button" class="w-full mt-4 " (click)="addMissionItems(true)">
                <div class="flex flex-row justify-end space-x-2 hover:cursor-pointer">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 fill-customTeal" viewBox="0 0 20 20">
                    <path fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                      clip-rule="evenodd" />
                  </svg>
                  <p class="text-sm font-bold text-customTeal">
                    Add
                  </p>
                </div>
              </button>
            </div>

          </div>
        </ng-container>

        <ng-container *ngIf="['main','mission'].includes(sectionCatagory.value)" class="">
          <div>
            <div class="space-y-2">

              <label class="label py-2">
                <span class=" text-black text-base font-bold leading-5">Image</span>
              </label>
              <div class="w-full max-w-x flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
                <div *ngIf="!isMobile"
                  class="w-1/2 flex justify-center content-center w-full h-44 max-w-x bg-white rounded-lg border-2 gap-x-3">

                  <label class="custom-file-upload  self-center flex flex-col">
                    <i class="fa fa-plus-circle text-customTeal text-3xl text-center self-center"></i>
                    <input type="file" type="file" accept="image/*"
                      (change)="fileChangeEvent($event.target.files)">
                    <span class="block font-bold pt-1">Upload Imageeeeee</span>
                  </label>
                </div>
                <div class="relative" *ngIf="base64Image">
                  <img loading="lazy" [src]="base64Image" class="object-cover  w-32 rounded-md cover" alt="Image">
                  <button (click)="removeFile()"
                    class="absolute top-0 translate-x-24 -translate-y-4 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                    <i class="fa fa-trash text-md"></i>
                  </button>
                </div>


                <div class="flex p-3" *ngIf="isMobile">
                 <app-image-uploader class="justify-start items-start"  (onImageUpload)=onImageUpload($event) [customConfig]="{'upload':false}"></app-image-uploader>

                </div>

                <p class="text-red-700 py-2" *ngIf="imgErrorMsg">
                  <strong><small><i class="material-icons">error_outline</i> {{imgErrorMsg}}</small></strong>
                </p>
              </div>
            </div>

          </div>

        </ng-container>
        <!-- current -->
        <ng-container *ngIf="sectionCatagory.value == 'values'">
          <div formArrayName="items" class="flex flex-col bg-white md:px-10 px-2 py-6 space-y-2 shadow-sm ">
            <div *ngFor="let item of items.controls;let li=index" [formGroup]="item"
              class="flex flex-col space-y-4 p-3">
              <ng-container *ngIf="selectedItem == li">
                <div class="flex space-x-4 items-center">
      
                  <svg width="36" height="36" viewBox="0 0 55 55" fill="blue"  class="text-gray-400 " xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 12H45.6667V16.5833H9V12ZM9 21.1667H31.9167V25.75H9V21.1667Z" fill="black"/>
                    <rect x="9" y="30.3333" width="22.9167" height="4.58333" fill="black"/>
                    <path d="M44.4785 22.375C44.0226 22.375 43.5855 22.5561 43.2631 22.8784C42.9408 23.2007 42.7597 23.6379 42.7597 24.0938C42.7597 24.5496 42.9408 24.9868 43.2631 25.3091C43.5855 25.6314 44.0226 25.8125 44.4785 25.8125C44.9343 25.8125 45.3715 25.6314 45.6938 25.3091C46.0162 24.9868 46.1972 24.5496 46.1972 24.0938C46.1972 23.6379 46.0162 23.2007 45.6938 22.8784C45.3715 22.5561 44.9343 22.375 44.4785 22.375ZM44.066 27.8406C42.7024 27.9552 38.9785 30.9229 38.9785 30.9229C38.7493 31.0948 38.8181 31.0833 39.0014 31.4042C39.1847 31.7135 39.1618 31.7365 39.3795 31.5875C39.6087 31.4385 39.9868 31.1979 40.617 30.8083C43.0462 29.25 41.0066 32.8479 39.9639 38.9094C39.5514 41.9115 42.2556 40.3646 42.9545 39.9063C43.642 39.4594 45.4868 38.1875 45.6701 38.0615C45.9222 37.8896 45.7389 37.7521 45.5441 37.4656C45.4066 37.2708 45.2691 37.4083 45.2691 37.4083C44.5243 37.901 43.1608 38.9323 42.9774 38.2792C42.7597 37.626 44.1576 33.1458 44.9254 30.0635C45.0514 29.3302 45.3951 27.726 44.066 27.8406V27.8406Z" fill="black"/>
                    <rect x="9" y="38.3542" width="22.9167" height="4.58333" fill="black"/>
                    </svg>
    
                  <div class="w-10/12">
                    <p class="font-bold text-lg">Description <span class="text-red-500 text-sm">*</span></p>
                  </div>
              </div>

                <div class="form-control w-full max-w-x">
                  <div class="flex space-y-2   flex-col">

                  <app-editor [control]="getDescription(li)" [inputType]="'description'" [fromComponent]=aboutUsName></app-editor>

                <div
                      *ngIf="(items.at(li).get('description').dirty || items.at(li).get('description').touched) && items.at(li).get('description').invalid">
                      <small *ngIf="items.at(li).get('description').errors.required"
                        class="text-red-700">Description
                        is required.
                      </small>
                    </div>
                  </div>

                </div>

                <p class="font-bold text-lg">Upload an image</p>
                  
                <div class="flex flex-col md:flex-row my-24">
                  <!-- Image -->
                  <div class="w-full mt-5 md:w-1/3">
                    <button type="button" *ngIf="!isMobile">
                      <label class="flex flex-col py-5 px-10 bg-gray-50 rounded-lg">
                        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7704.74 6791.12"><circle cx="2373.6" cy="2638.32" r="695.57" transform="translate(-1413.99 1788.05) rotate(-45)" style="fill:#231f20"/><path d="M7671.44,940c-178.58-178.61-416-276.93-668.57-276.93H1189.17c-252.56,0-490,98.32-668.61,276.93s-276.93,416-276.93,668.57V6508.7c0,252.56,98.36,490,276.93,668.57s416.05,276.93,668.61,276.93h5813.7c252.56,0,490-98.36,668.57-276.93s276.93-416,276.93-668.57V1608.58C7948.37,1356,7850,1118.59,7671.44,940ZM7386.2,6508.7c0,9.58-.34,19.13-1.06,28.56q-582.86-730.58-1077.85-1430.1C5530.21,4014.71,5164.73,3357.57,4738.42,3418.77c-198.19,28.45-370.5,253-715.09,702.14-455.58,593.83-525,924.41-737.08,924-300.22-.57-401.22-663.59-746.59-678.79-230.42-10.15-415.18,274.9-509.58,419.25-325.6,497.92-721.5,1073.86-1219.91,1781.25a381.07,381.07,0,0,1-4.37-57.88V1608.58c0-211.4,172-383.33,383.37-383.33h5813.7c211.36,0,383.33,171.93,383.33,383.33Z" transform="translate(-243.63 -663.08)" style="fill:#231f20"/></svg>
                        <input type="file" type="file" accept="image/*" (change)="fileChangeEvent($event.target.files)" >
                      <span class="block font-bold pt-1 text-center">Image</span>
                      </label>
                    </button>
                    <div *ngIf="isMobile">
                      <app-image-uploader class="justify-start items-start"  (onImageUpload)=onImageUpload($event) [customConfig]="{'upload':false}"></app-image-uploader>
                    </div>
                    <!-- Image upload Preview  -->
                    <!-- <div class=" mt-5 md:pl-2" *ngIf="base64Image">
                      <div class="flex flex-wrap gap-8">
                        <div class="relative">
                          <img loading="lazy" [src]="base64Image" *ngIf="base64Image" class="object-cover  w-36 h-24 rounded-md cover" alt="Image">
                          <button (click)="removeImage()" type="button"
                            class=" top-0 translate-x-16 -translate-y-4 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                            <i class="fa fa-trash text-md"></i>
                          </button>
                          <img loading="lazy" *ngIf="event && event.imgLink != ''" [src]="event.imgLink"
                            class="object-cover  w-24 h-24 rounded-md cover" alt="Event Image">
                        </div>
                      </div>              
                    </div> -->
                    <div class="resp-img-card" *ngIf="base64Image">
                      <img loading="lazy" [src]="base64Image" class="" alt="Image">
                      <button (click)="removeImage()" type="button"
                        class=" top-0 translate-x-16 -translate-y-4 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                        <i class="fa fa-trash text-md"></i>
                      </button>
                    </div>
      
                  </div>
      
                </div>
                <!-- index for position -->
                <!-- <div>
                  <label class="label">
                    <span class=" text-[#4F4F4F] text-base font-bold leading-5">Select position</span>
                  </label>

                  <div class="flex flex-wrap justify-start space-x-5">
                    <label *ngFor="let s of [].constructor(items.value.length); let i = index; let last = last"
                      class="label cursor-pointer">
                      <input name="index" type="radio" class="radio checked:bg-customTeal" [value]="i" [checked]="i == index"
                        formControlName='index' (click)="updatePosition(i)"/>
                      <span class="padding-left: 24px;"></span>
                    </label>
                  </div>
                </div> -->

                <button type="button" class="w-full mt-4" (click)="removeItem(li)" *ngIf="items.value.length > 1">
                  <div class="flex flex-row justify-end space-x-2">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 fill-red-700" viewBox="0 0 20 20">
                      <path fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z"
                        clip-rule="evenodd" />
                    </svg>
                    <p class="text-md font-bold text-red-700">
                      Remove sub-section
                    </p>
                  </div>
                </button>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <div *ngIf="preview">
      <app-about-main *ngIf="previewData.sectionCatagory =='main'" [data]="previewData"></app-about-main>
      <app-about-values *ngIf="previewData.sectionCatagory =='values'" [data]="previewData"></app-about-values>
      <app-about-mission *ngIf="previewData.sectionCatagory =='mission'" [data]="previewData"></app-about-mission>
    </div>

    <div class="flex flex-col-reverse md:flex-row gap-3 md:gap-0 justify-between w-full max-w-x pt-15 pb-5">
      <div class="flex flex-col gap-y-3 md:gap-y-0 md:flex-row md:gap-x-5 ">
        <div class="form-control flex flex-row gap-x-5 ">
          <button type="button" (click)="addSection = false"
            class="py-2 px-4 text-black modal-button gap-2 font-bold w-full text-md rounded-lg border-2 border-customTeal md:border-none">
            Cancel
          </button>
        </div>
      </div>

      <div class="flex flex-col gap-y-3 md:gap-y-0 md:flex-row md:gap-x-5 pt-10">
        <div class="form-control flex flex-row gap-x-5 " *ngIf="preview">
          <button (click)="backToEdit()" type="button"
            class="py-2 px-12 modal-button gap-2  bg-customTeal rounded-lg text-white w-full text-md">
            Back to edit
          </button>
        </div>
        <div *ngIf="!preview" class="form-control flex flex-row gap-x-5 ">
          <app-preview   sectionName="About" [section]="previewData" (click)="openPreview(newSectionForm.value)" (closePreview)="closePreviewModal()"></app-preview>

        </div>

        <div class="form-control flex flex-row gap-x-5 ">
          <button (click)="confirmSavingAboutModal=true" type="button" class="py-2 px-12 flex space-x-2 items-center justify-center modal-button gap-2 rounded-lg text-white w-full"
            [ngClass]="{'bg-[#cccccc] text-[#666666] border-none': !newSectionForm.valid || onLoading,
              'bg-customTeal': newSectionForm.valid && !onLoading
              }"
            [disabled]="!newSectionForm.valid || onLoading">
            <div
            *ngIf="onLoading"
            class="inline-block h-4 w-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-neutral-100 motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status">
            <span
                class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                >Loading...</span
            >
            </div>
            Save 
          </button>
        </div>

      </div>
    </div>
  </form>
</div>

<div id="newSectionModal" *ngIf="newSectionModal" class="modal" 
  [ngClass]="{'modal-open': newSectionModal, 'modal-action': !newSectionModal }">
  <div class="modal-box  bg-white  w-11/12  md:w-10/12 max-w-5xl flex flex-col">
    <app-close-modal (closeBtnClicked)=closeModal()></app-close-modal>

    <div class="px-4 md:px-10">
      <h3 class="font-bold text-lg mb-6 text">Add new section</h3>

      <form [formGroup]="newSectionForm" (ngSubmit)="saveSection(newSectionForm.value)">

        <div class="flex flex-col gap-y-3" *ngIf="!preview">
          <div class="flex flex-col gap-y-5">
            <!-- Section title -->
            <div class="flex space-y-2   flex-col">
              <label for="sectionTitle" class="text-xs text-gray-500 font-bold "> Section Title</label>
              <app-editor [control]="sectionTitle" [inputType]="'title'" [fromComponent]=aboutUsName></app-editor>

              <div *ngIf="(sectionTitle.dirty || sectionTitle.touched) && sectionTitle.invalid">
                <small *ngIf="sectionTitle.errors.required" class="text-red-700">Section title is required.
                </small>
              </div>
            </div>

            <ng-container *ngIf="['main','mission'].includes(sectionCatagory.value)" class="">
              <div class="form-control w-full max-w-x">
                <div class="flex space-y-2   flex-col">
                  <label for="sectionDescription" class="text-xs text-gray-500 font-bold ">Section Description</label>

                  <app-editor [control]="sectionDescription" [fromComponent]=aboutUsName></app-editor>

                  <div *ngIf="(sectionDescription.dirty || sectionDescription.touched) && sectionDescription.invalid">
                    <small *ngIf="sectionDescription.errors.required" class="text-red-700">Section description is
                      required.
                    </small>
                  </div>
                </div>

              </div>
            </ng-container>

            <ng-container *ngIf="sectionCatagory.value == 'main'" class="">
              <div class="form-control w-full">
                <label class="label">
                  <span class=" text-black text-base font-bold leading-5">Select position</span>
                </label>
                <select class="select select-bordered w-8/12" formControlName="textPosition">
                  <option *ngFor="let item of textPositions | keyvalue" [value]="item.value">
                    {{item.key}}</option>
                </select>
              </div>
            </ng-container>

            <ng-container *ngIf="sectionCatagory.value == 'mission'">

              <div class="form-control w-full max-w-x">
                <label class="label py-2">
                  <span class=" text-[#4F4F4F] text-base font-bold leading-5">Text position</span>
                </label>
                <div class="flex justify-between w-1/2 ">
                  <div class="form-control">
                    <label class="label cursor-pointer">
                      <input type="radio" formControlName="leftText" [value]=true class="radio checked:bg-customTeal" />
                      <span class=" ml-4 font-normal text-black">Left</span>

                    </label>
                  </div>
                  <div class="form-control">
                    <label class="label cursor-pointer">
                      <input type="radio" formControlName="leftText" [value]=false
                        class="radio checked:bg-customTeal" />
                      <span class=" ml-4 font-normal text-black">Right</span>

                    </label>
                  </div>
                </div>
              </div>

              <div class="space-y-2">
                <div class="space-y-2">
                  <p class="font-bold">
                    Bullet points
                  </p>
                  <div class="flex flex-wrap  ">
                    <!-- button -->
                    <div *ngFor="let item of newSectionForm.get('missionItems').value;let i=index">
                      <button type="button" class="border-2 border-customTeal rounded-full py-1 px-4 mx-2 my-2"
                        (click)="removeMissionItems(i,true)">
                        <span>{{item}}</span>
                        <span>
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                          </svg>
                        </span>

                      </button>
                    </div>

                  </div>
                </div>

                <div class="form-control max-w-x w-full md:w-7/12">
                  <input
                    class=" w-full border rounded py-3 px-3 text-xs text-gray-700 leading-tight bg-veryLightGray focus:outline-gray-200 focus:shadow-outline "
                    id="newMission" type="text" placeholder="Type a bullet point, then select 'add'" required>
                  <button type="button" class="w-full mt-4 " (click)="addMissionItems(true)">
                    <div class="flex flex-row justify-end space-x-2 hover:cursor-pointer">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 fill-customTeal" viewBox="0 0 20 20">
                        <path fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                          clip-rule="evenodd" />
                      </svg>
                      <p class="text-sm font-bold text-customTeal">
                        Add
                      </p>
                    </div>
                  </button>
                </div>

              </div>
            </ng-container>

            <ng-container *ngIf="['main','mission'].includes(sectionCatagory.value)" class="">
              <div>
                <div class="space-y-2">

                  <label class="label py-2">
                    <span class=" text-black text-base font-bold leading-5">Image</span>
                  </label>
                  <div class="w-full max-w-x flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
                    <div *ngIf="!isMobile"
                      class="w-1/2 flex justify-center content-center w-full h-44 max-w-x bg-white rounded-lg border-2 gap-x-3">

                      <label class="custom-file-upload  self-center flex flex-col">
                        <i class="fa fa-plus-circle text-customTeal text-3xl text-center self-center"></i>
                        <input type="file" type="file" accept="image/*"
                          (change)="handleImageInput($event.target.files)">
                        <span class="block font-bold pt-1">Upload Image</span>
                      </label>
                    </div>
                    <div class="relative" *ngIf="base64Image">
                      <img loading="lazy" [src]="base64Image" class="object-cover  w-32 rounded-md cover" alt="Image">
                      <button (click)="removeFile()"
                        class="absolute top-0 translate-x-24 -translate-y-4 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                        <i class="fa fa-trash text-md"></i>
                      </button>
                    </div>


                    <div class="flex p-3" *ngIf="isMobile">
                     <app-image-uploader class="justify-start items-start"  (onImageUpload)=onImageUpload($event) [customConfig]="{'upload':false}"></app-image-uploader>

                    </div>

                    <p class="text-red-700 py-2" *ngIf="imgErrorMsg">
                      <strong><small><i class="material-icons">error_outline</i> {{imgErrorMsg}}</small></strong>
                    </p>
                  </div>
                </div>

              </div>

            </ng-container>

            <ng-container *ngIf="sectionCatagory.value == 'values'">
              <!-- <div class="flex flex-wrap gap-4 md:gap-8 justify-start pt-4">


                <div class="relative" *ngFor="let item of items.value;let i=index">
                  <img *ngIf="item.imgUrl && item.imgUrl !=''" [src]="item.imgUrl" (click)="itemClicked(i)"
                    class="object-cover  w-24 h-24 rounded-md cover hover:cursor-pointer hover:border-customTeal hover:border-4"
                    alt="Image" [ngClass]="i==selectedItem ? 'border-4 border-customTeal' : ''">
                  <div *ngIf="!item.imgUrl || item.imgUrl ==''"
                    class="avatar placeholder text-customTeal rounded-md w-24 h-24 border hover:cursor-pointer hover:border-customTeal hover:border-4"
                    (click)="itemClicked(i)" [ngClass]="i==selectedItem ? 'border-4 border-customTeal' : ''">
                    <div class="text-customTeal rounded-md ">
                      <h1 class="text-customTeal font-bold text-3xl">{{i + 1}}</h1>
                    </div>
                  </div>

                  <button (click)="itemClicked(i)" type="button"
                  [ngClass]="item.imgUrl && item.imgUrl !='' ? 'translate-x-16 -translate-y-4' : '-translate-x-4 -translate-y-4'"
                    class="absolute top-0  rounded-full bg-white w-10 h-10 border-2 border-veryLightGray">
                    <i class="fa fa-pencil text-md"></i>
                  </button>
                </div>

                <div class="w-8/12 md:w-2/12">
                  <div (click)="addItem()" class="py-6 text-customTeal font-bold hover:cursor-pointer"><i
                      class="fa fa-plus-circle mr-2 float-left"></i>
                    <a>Add sub-section</a>
                  </div>
                </div>

              </div> -->
              <div formArrayName="items" class="flex flex-col space-4">
                <div *ngFor="let item of items.controls;let li=index" [formGroup]="item"
                  class="flex flex-col space-y-4 p-3">
                  <ng-container *ngIf="selectedItem == li">


                    <h3 class="text-lg font-bold leading-5">Add sub-section</h3>
                    <!-- <div class="flex space-y-2   flex-col">
                      <label for="sectionTitle" class="text-xs text-gray-500 font-bold ">Title</label> -->
                      <!-- <input id="sectionTitle" formControlName='title' placeholder="Title" class=" border rounded p-3 md:mr-12 text-gray-700 leading-tight bg-veryLightGray focus:outline-gray-200
                    focus:shadow-outline " id="sectionTitle" type="text " placeholder="Title (required)"> -->
                      <!-- <app-editor id="sectionTitle" [control]="title" ></app-editor> -->
                      
                      <!-- <angular-editor formControlName='title' [config]="inputEditorConfig" class="bg-white" ></angular-editor>


                      <div
                        *ngIf="(items.at(li).get('title').dirty || items.at(li).get('title').touched) && items.at(li).get('title').invalid">
                        <small *ngIf="items.at(li).get('title').errors.required" class="text-red-700">Title is required.
                        </small>
                      </div>
                    </div> -->

                    <div class="form-control w-full max-w-x">
                      <div class="flex space-y-2   flex-col">
                        <label for="description" class="text-xs text-gray-500 font-bold ">Description</label>
                        <!-- <textarea id="description" type="text" formControlName='description' required
                          placeholder="Description (required)"
                          class=" border rounded p-3  md:mr-12 h-24 resize-y text-gray-700  bg-veryLightGray focus:outline-gray-200 focus:shadow-outline ">
                    </textarea> -->
                      <app-editor [control]="getDescription(li)" [inputType]="'description'" [fromComponent]=aboutUsName></app-editor>

                    <div
                          *ngIf="(items.at(li).get('description').dirty || items.at(li).get('description').touched) && items.at(li).get('description').invalid">
                          <small *ngIf="items.at(li).get('description').errors.required"
                            class="text-red-700">Description
                            is required.
                          </small>
                        </div>
                      </div>

                    </div>



                    <div class="w-full max-w-x flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4"
                      >
                      <div *ngIf="!isMobile"
                        class="w-1/2 flex justify-center content-center w-full h-44 max-w-x bg-white rounded-lg border-2 gap-x-3">

                        <label class="custom-file-upload  self-center flex flex-col">
                          <i class="fa fa-plus-circle text-customTeal text-3xl text-center self-center"></i>
                          <input type="file" type="file" accept="image/*"
                            (change)="handleItemsImageInput($event.target.files,li,false)">
                          <span class="block font-bold pt-1">Upload Image</span>
                        </label>
                      </div>
                      <div class="relative"
                        *ngIf="items.at(li).get('imgUrl').value && items.at(li).get('imgUrl').value !=''">
                        <img loading="lazy" [src]="items.at(li).get('imgUrl').value" class="object-cover  w-32 rounded-md cover"
                          alt="Image">
                        <button (click)="removeSavedFile(li,false)"
                          class="absolute top-0 translate-x-24 -translate-y-4 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                          <i class="fa fa-trash text-md"></i>
                        </button>
                      </div>


                      <div class="flex p-3" *ngIf="isMobile">
                        <app-image-uploader class="justify-start items-start"  (onImageUpload)=onItemsImageUpload($event) [customConfig]="{'upload':true}"></app-image-uploader>

                      </div>

                      <p class="text-red-700 py-2" *ngIf="imgErrorMsg">
                        <strong><small><i class="material-icons">error_outline</i> {{imgErrorMsg}}</small></strong>
                      </p>
                    </div>
                    <!-- index for position -->
                    <!-- <div>
                      <label class="label">
                        <span class=" text-[#4F4F4F] text-base font-bold leading-5">Select position</span>
                      </label>

                      <div class="flex flex-wrap justify-start space-x-5">
                        <label *ngFor="let s of [].constructor(items.value.length); let i = index; let last = last"
                          class="label cursor-pointer">
                          <input name="index" type="radio" class="radio checked:bg-customTeal" [value]="i" [checked]="i == index"
                            formControlName='index' (click)="updatePosition(i)"/>
                          <span class="padding-left: 24px;"></span>
                        </label>
                      </div>
                    </div> -->

                    <button type="button" class="w-full mt-4" (click)="removeItem(li)" *ngIf="items.value.length > 1">
                      <div class="flex flex-row justify-end space-x-2">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 fill-red-700" viewBox="0 0 20 20">
                          <path fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z"
                            clip-rule="evenodd" />
                        </svg>
                        <p class="text-md font-bold text-red-700">
                          Remove sub-section
                        </p>
                      </div>
                    </button>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </div>

          <!-- <div>
            <label class="label">
              <span class=" text-[#4F4F4F] text-base font-bold leading-5">Select position</span>
            </label>

            <div class="flex flex-wrap justify-start space-x-5">
              <label *ngFor="let s of [].constructor(model.sections.length +1); let i = index; let last = last"
                class="label cursor-pointer">
                <input name="index" type="radio" class="radio checked:bg-customTeal" [value]="i" [checked]="last"
                  formControlName='index' />
                <span class="padding-left: 24px;"></span>
              </label>
            </div>
          </div> -->
        </div>

        <div *ngIf="preview">
          <app-about-main *ngIf="previewData.sectionCatagory =='main'" [data]="previewData"></app-about-main>
          <app-about-values *ngIf="previewData.sectionCatagory =='values'" [data]="previewData"></app-about-values>
          <app-about-mission *ngIf="previewData.sectionCatagory =='mission'" [data]="previewData"></app-about-mission>
        </div>

        <div class="flex flex-col-reverse md:flex-row gap-3 md:gap-0 justify-between w-full max-w-x pt-15 pb-5">
          <div class="flex flex-col gap-y-3 md:gap-y-0 md:flex-row md:gap-x-5 ">
            <div class="form-control flex flex-row gap-x-5 ">
              <button type="button" (click)="closeModal()"
                class="py-2 px-4 text-black modal-button gap-2 font-bold w-full text-md rounded-lg border-2 border-customTeal md:border-none">
                Cancel
              </button>
            </div>
          </div>

          <div class="flex flex-col gap-y-3 md:gap-y-0 md:flex-row md:gap-x-5 pt-10">
            <div class="form-control flex flex-row gap-x-5 " *ngIf="preview">
              <button (click)="backToEdit()"
                class="py-2 px-12 modal-button gap-2  bg-customTeal rounded-lg text-white w-full text-md">
                Back to edit
              </button>
            </div>
            <div *ngIf="!preview" class="form-control flex flex-row gap-x-5 ">
              <app-preview   sectionName="About" [section]="previewData" (click)="openPreview(newSectionForm.value)" (closePreview)="closePreviewModal()"></app-preview>
            </div>

            <div class="form-control flex flex-row gap-x-5 ">
              <button type="submit" class="py-2 px-12 modal-button gap-2  bg-customTeal rounded-lg text-white w-full"
                [ngClass]="{'bg-[#cccccc] text-[#666666] border-none': !newSectionForm.valid}"
                [disabled]="!newSectionForm.valid">
                Save
              </button>
            </div>

          </div>
        </div>
      </form>
    </div>

  </div>
</div>

<div id="editSectionModal" *ngIf="editSectionModal" class="modal"
  [ngClass]="{'modal-open': editSectionModal, 'modal-action': !editSectionModal }">>
  <div class="modal-box  bg-white  w-11/12  md:w-10/12 max-w-5xl flex flex-col">
    <app-close-modal (closeBtnClicked)=closeEditModal()></app-close-modal>

    <div class="px-4 md:px-10">
      <!-- <h3 class="font-bold text-lg mb-6 text">{{model.sections[editIndex].sectionTitle}} Section</h3> -->
      <h3 class="font-bold text-lg mb-6 text">{{truncateHTML(model.sections[editIndex].sectionTitle,200)  | titlecase }} Section</h3>


      <div class="flex flex-col gap-y-3" *ngIf="!preview">
        <div class="flex flex-col gap-y-5">
          <div class="flex space-y-2   flex-col">
            <label for="sectionTitle" class="text-xs text-gray-500 font-bold "> Section Title</label>
            <!-- <input id="sectionTitle" [(ngModel)]="model.sections[editIndex].sectionTitle" placeholder="Title" class=" border rounded p-3 md:mr-12 text-gray-700 leading-tight bg-veryLightGray focus:outline-gray-200
              focus:shadow-outline " id="sectionTitle" type="text " placeholder="Section title (required)"> -->
            <app-editor  (onModelChange)="onTitleChange($event)" [inputType]="'title'"
              [model]="model.sections[editIndex].sectionTitle" [type]=true ngDefaultControl [fromComponent]=aboutUsName></app-editor>
          </div>

          <ng-container *ngIf="['main','mission'].includes(model.sections[editIndex].sectionCatagory)" class="">
            <div class="form-control w-full max-w-x">
              <div class="flex space-y-2   flex-col">
                <label for="sectionDescription" class="text-xs text-gray-500 font-bold ">Section Description</label>
                <!-- <textarea id="sectionDescription" type="text" [(ngModel)]="model.sections[editIndex].sectionDescription"
                  required placeholder="Section description (required)"
                  class=" border rounded p-3  md:mr-12 h-24 resize-y text-gray-700  bg-veryLightGray focus:outline-gray-200 focus:shadow-outline ">
                </textarea> -->
                <app-editor  (onModelChange)="onDescriptionChange($event)"
                [model]="model.sections[editIndex].sectionDescription" [type]=true ngDefaultControl [fromComponent]=aboutUsName></app-editor>

              </div>

            </div>
          </ng-container>

          <ng-container *ngIf="model.sections[editIndex].sectionCatagory == 'main'" class="">
            <div class="form-control w-full">
              <label class="label">
                <span class=" text-black text-base font-bold leading-5">Select position</span>
              </label>
              <select class="select select-bordered w-8/12" [(ngModel)]="model.sections[editIndex].textPosition">
                <option *ngFor="let item of textPositions | keyvalue" [value]="item.value">
                  {{item.key}}</option>
              </select>
            </div>
          </ng-container>

          <ng-container *ngIf="model.sections[editIndex].sectionCatagory == 'mission'">

            <div class="form-control w-full max-w-x">
              <label class="label py-2">
                <span class=" text-[#4F4F4F] text-base font-bold leading-5">Text position</span>
              </label>
              <div class="flex justify-between w-1/2 ">
                <div class="form-control">
                  <label class="label cursor-pointer">
                    <input type="radio" [(ngModel)]="model.sections[editIndex].leftText" [value]=true
                      class="radio checked:bg-customTeal" />
                    <span class=" ml-4 font-normal text-black">Left</span>

                  </label>
                </div>
                <div class="form-control">
                  <label class="label cursor-pointer">
                    <input type="radio" [(ngModel)]="model.sections[editIndex].leftText" [value]=false
                      class="radio checked:bg-customTeal" />
                    <span class=" ml-4 font-normal text-black">Right</span>

                  </label>
                </div>
              </div>
            </div>

            <div>
              <div class="space-y-2">
                <p class="font-bold ">
                  Bullet points
                </p>
                <div class="flex flex-wrap  ">
                  <!-- button -->
                  <div *ngFor="let item of model.sections[editIndex].missionItems;let i=index">
                    <button type="button" class="border-2 border-customTeal rounded-full py-1 px-4 mx-2 my-2"
                      (click)="removeMissionItems(i,false)">
                      <span>{{item}}</span>
                      <span>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline" fill="none" viewBox="0 0 24 24"
                          stroke="currentColor" stroke-width="2">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </span>

                    </button>
                  </div>

                </div>
              </div>

              <div class="space-y-2">
                <input
                  class=" w-full border rounded py-3 px-3 text-xs text-gray-700 leading-tight bg-veryLightGray focus:outline-gray-200 focus:shadow-outline "
                  id="updateMission" type="text" placeholder="Add new item" required>
                <button type="button" class="w-full mt-4" (click)="addMissionItems(false)">
                  <div class="flex flex-row justify-end space-x-2 hover:cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 fill-customTeal" viewBox="0 0 20 20">
                      <path fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                        clip-rule="evenodd" />
                    </svg>
                    <p class="text-sm font-bold text-customTeal">
                      Add
                    </p>
                  </div>
                </button>
              </div>

            </div>
          </ng-container>


          <ng-container *ngIf="['main','mission'].includes(model.sections[editIndex].sectionCatagory)">
            <div class="space-y-2">

              <label class="label">
                <span class=" text-black text-base font-bold leading-5">Image</span>
              </label>
              <div class="w-full max-w-x flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
                <div *ngIf="!isMobile"
                  class="w-1/2 flex justify-center content-center w-full h-44 max-w-x bg-white rounded-lg border-2 gap-x-3">

                  <label class="custom-file-upload  self-center flex flex-col">
                    <i class="fa fa-plus-circle text-customTeal text-3xl text-center self-center"></i>
                    <input type="file" type="file" accept="image/*" (change)="handleImageInput($event.target.files)">
                    <span class="block font-bold pt-1">Upload Image</span>
                  </label>
                </div>

                <div class="flex p-3" *ngIf="isMobile">
                  <app-image-uploader class="justify-start items-start"  (onImageUpload)=onImageUpload($event) [customConfig]="{'upload':false}"></app-image-uploader>
                </div>

                <div class="relative" *ngIf="base64Image">
                  <img loading="lazy" [src]="base64Image" class="object-cover  w-32 rounded-md cover" alt="Image">
                  <button (click)="removeFile()"
                    class="absolute top-0 translate-x-24 -translate-y-4 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                    <i class="fa fa-trash text-md"></i>
                  </button>
                </div>

                <div class="relative"
                  *ngIf="!base64Image && model.sections[editIndex].imgUrl && model.sections[editIndex].imgUrl !=''">
                  <img loading="lazy" [src]="model.sections[editIndex].imgUrl" class="object-cover  w-32 rounded-md cover"
                    alt="Image">
                  <button (click)="remove()"
                    class="absolute top-0 translate-x-24 -translate-y-4 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                    <i class="fa fa-trash text-md"></i>
                  </button>
                </div>


                <p class="text-red-700 py-2" *ngIf="imgErrorMsg">
                  <strong><small><i class="material-icons">error_outline</i> {{imgErrorMsg}}</small></strong>
                </p>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="model.sections[editIndex].sectionCatagory == 'values'">

            <!-- <div class="flex flex-wrap gap-4 md:gap-8 justify-start pt-4">


              <div class="relative" *ngFor="let item of model.sections[editIndex].items;let i=index">
                <img *ngIf="item.imgUrl && item.imgUrl !=''" [src]="item.imgUrl" (click)="itemClicked(i)"
                  class="object-cover  w-24 h-24 rounded-md cover hover:cursor-pointer hover:border-customTeal hover:border-4"
                  alt="Image" [ngClass]="i==selectedItem ? 'border-4 border-customTeal' : ''">
                <div *ngIf="!item.imgUrl || item.imgUrl ==''"
                  class="avatar placeholder text-customTeal rounded-md w-24 h-24 border hover:cursor-pointer hover:border-customTeal hover:border-4 "
                  (click)="itemClicked(i)" [ngClass]="i==selectedItem ? 'border-4 border-customTeal' : ''">
                  <div class="text-customTeal rounded-md ">
                    <h1 class="text-customTeal font-bold text-3xl">{{i + 1}}</h1>
                  </div>
                </div>
                <button (click)="itemClicked(i)"
                  class="absolute top-0  rounded-full bg-white w-10 h-10 border-2 border-veryLightGray"
                  [ngClass]="item.imgUrl && item.imgUrl !='' ? 'translate-x-16 -translate-y-4' : '-translate-x-4 -translate-y-4'">
                  <i class="fa fa-pencil text-md"></i>
                </button>
              </div>

              <div class="w-8/12 md:w-2/12 ">
                <div (click)="addValueItem()" class="py-6 text-customTeal font-bold hover:cursor-pointer"><i
                    class="fa fa-plus-circle mr-2 float-left"></i>
                  <a>Add sub-section</a>
                </div>
              </div>

            </div> -->

            <div class="flex flex-col space-4">
              <div class="flex flex-col space-y-4 p-3">
                <!-- <div class="flex space-y-2   flex-col">
                  <label for="sectionTitle" class="text-xs text-gray-500 font-bold ">Title</label> -->
                  <!-- <input id="sectionTitle" [(ngModel)]=' model.sections[editIndex].items[selectedItem].title'
                    placeholder="Title" class=" border rounded p-3 md:mr-12 text-gray-700 leading-tight bg-veryLightGray focus:outline-gray-200
                  focus:shadow-outline " id="sectionTitle" type="text " placeholder="Title (required)"> -->
                  <!-- <app-editor  (onModelChange)="onItemTitleChange($event)"
                  [model]="model.sections[editIndex].items[selectedItem].title" [type]=true ngDefaultControl></app-editor>
                </div> -->

                <div class="form-control w-full max-w-x">
                  <div class="flex space-y-2   flex-col">
                    <label for="sectionDescription" class="text-xs text-gray-500 font-bold ">Description</label>
                    <!-- <textarea id="sectionDescription" type="text"
                      [(ngModel)]='model.sections[editIndex].items[selectedItem].description' required
                      placeholder="Description (required)"
                      class=" border rounded p-3  md:mr-12 h-24 resize-y text-gray-700  bg-veryLightGray focus:outline-gray-200 focus:shadow-outline ">
                  </textarea> -->
                  <app-editor  (onModelChange)="onItemDescriptionChange($event)"
                  [model]="model.sections[editIndex].items[selectedItem].description" [type]=true ngDefaultControl [fromComponent]=aboutUsName></app-editor>


                  </div>

                </div>

                <div class="w-full max-w-x flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
                  <div *ngIf="!isMobile"
                    class="w-1/2 flex justify-center content-center w-full h-44 max-w-x bg-white rounded-lg border-2 gap-x-3">

                    <label class="custom-file-upload  self-center flex flex-col">
                      <i class="fa fa-plus-circle text-customTeal text-3xl text-center self-center"></i>
                      <input type="file" type="file" accept="image/*"
                        (change)="handleItemsImageInput($event.target.files,selectedItem,true)">
                      <span class="block font-bold pt-1">Upload Image</span>
                    </label>
                  </div>
                  <div class="relative"
                    *ngIf="model.sections[editIndex].items[selectedItem].imgUrl && model.sections[editIndex].items[selectedItem].imgUrl !=''">
                    <img loading="lazy" [src]=" model.sections[editIndex].items[selectedItem].imgUrl"
                      class="object-cover  w-32 rounded-md cover" alt="Image">
                    <button (click)="removeSavedFile(selectedItem,true)"
                      class="absolute top-0 translate-x-24 -translate-y-4 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                      <i class="fa fa-trash text-md"></i>
                    </button>
                  </div>


                  <div class="flex p-3" *ngIf="isMobile">
                  <app-image-uploader class="justify-start items-start"  (onImageUpload)=onItemsImageUploadUpdate($event) [customConfig]="{'upload':true}"></app-image-uploader>

                  </div>

                  <p class="text-red-700 py-2" *ngIf="imgErrorMsg">
                    <strong><small><i class="material-icons">error_outline</i> {{imgErrorMsg}}</small></strong>
                  </p>
                </div>
              </div>

              <!-- index for position -->
              <div>
                <label class="label">
                  <span class=" text-[#4F4F4F] text-base font-bold leading-5">Select position</span>
                </label>

                <div class="border-2 border-red-700 flex flex-wrap justify-start space-x-5">
                  <label class="" *ngFor="let s of model.sections[editIndex].items; let i = index; let last = last"
                    class="label cursor-pointer">
                    <input name="index" type="radio" class="radio checked:bg-customTeal" [value]="i"
                      [checked]="i == model.sections[editIndex].items[selectedItem].index"
                      [(ngModel)]='model.sections[editIndex].items[selectedItem].index' (click)="updatePosition(i)" />
                    <span class="padding-left: 24px;"></span>
                    <div class="pl-1">{{i + 1}}</div>
                  </label>
                </div>
              </div>

              <button type="button" class="w-full mt-4" (click)="removeValueItem()"
                *ngIf="model.sections[editIndex].items.length > 1">
                <div class="flex flex-row justify-end space-x-2">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 fill-red-700" viewBox="0 0 20 20">
                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z"
                      clip-rule="evenodd" />
                  </svg>
                  <p class="text-md font-bold text-red-700">
                    Remove sub-section
                  </p>
                </div>
              </button>

            </div>
          </ng-container>
        </div>

        <!-- <div>
          <label class="label">
            <span class=" text-[#4F4F4F] text-base font-bold leading-5">Select position</span>
          </label>

          <div class="flex flex-wrap justify-start space-x-5">
            <label *ngFor="let s of [].constructor(model.sections.length +1); let i = index; let last = last"
              class="label cursor-pointer">
              <input name="index" type="radio" class="radio checked:bg-customTeal" [value]="i" [checked]="last"
                formControlName='index' />
              <span class="padding-left: 24px;"></span>
            </label>
          </div>
        </div> -->
      </div>

      <div *ngIf="preview">
        <app-about-main *ngIf="previewData.sectionCatagory =='main'" [data]="previewData"></app-about-main>
        <app-about-values *ngIf="previewData.sectionCatagory =='values'" [data]="previewData"></app-about-values>
        <app-about-mission *ngIf="previewData.sectionCatagory =='mission'" [data]="previewData"></app-about-mission>
      </div>

      <div class="flex flex-col-reverse md:flex-row gap-3 md:gap-0 justify-between w-full max-w-x pt-10 p-b10">
        <div class="flex flex-col gap-y-3 md:gap-y-0 md:flex-row md:gap-x-5 ">
          <div class="form-control flex flex-row gap-x-5 ">
            <button type="button" (click)="closeEditModal()"
              class="py-2 px-4 text-black modal-button gap-2 font-bold w-full text-md rounded-lg border-2 border-customTeal md:border-none">
              Cancel
            </button>
          </div>
          <div class="form-control flex flex-row gap-x-5 ">
            <button (click)="openDeleteSection(editIndex)"
              class="font-bold py-3 px-4 md:px-12 border-2 border-red-700 rounded-md  text-black hover:cursor-pointer  w-full hover:bg-red-700 hover:text-white">Delete</button>
          </div>




        </div>

        <div class="flex flex-col gap-y-3 md:gap-y-0 md:flex-row md:gap-x-5 ">
          <div class="form-control flex flex-row gap-x-5 " *ngIf="!preview">
            <button type="button" (click)="openPreview(model.sections[editIndex])"
              class="flex space-x-2 gap-x-2 py-2 px-12 border-2 border-customTeal items-center text-sm justify-center text-base text-black rounded-lg  font-bold hover:bg-customTeal hover:text-white focus:outline-none  w-full">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                <path fill-rule="evenodd"
                  d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                  clip-rule="evenodd" />
              </svg>
              <span>Preview</span>
            </button>
          </div>


          <div class="form-control flex flex-row gap-x-5 " *ngIf="preview">
            <button (click)="backToEdit()"
              class="py-2 px-12 modal-button gap-2  bg-customTeal rounded-lg text-white w-full font-bold text-md">
              Back to edit
            </button>
          </div>

          <div class="form-control flex flex-row gap-x-5 ">
            <button (click)="confirmUpdatingAboutModal=true"
              class="py-2 px-12 modal-button gap-2  bg-customTeal rounded-lg text-white w-full font-bold text-md">
              Save Update
            </button>
          </div>
        </div>

      </div>

    </div>
  </div>
</div>

<div id="deleteSectionModal" *ngIf="deleteSectionModal" class="modal"
  [ngClass]="{'modal-open': deleteSectionModal, 'modal-action': !deleteSectionModal }">
  <div class="modal-box">
    <div class="flex justify-end">
      <a (click)="closeDeleteModal()" class="cursor-pointer"> <i class="text-xl fa fa-times"></i></a>
    </div>

    <div class="modal-content">
      <h3 class="font-bold text-lg text-center mb-1">Delete Section</h3>

      <hr />
      <h3 class="font-bold text-md text-center mt-2">Are you sure you want to delete this section?</h3>
      <p class="py-4 text-center">You won't be able to acces this section.</p>
      <div class="modal-action items-center justify-between mx-6">
        <button (click)="closeDeleteModal()" class="font-bold text-red-600 border-none">Cancel</button>
        <button type="button" (click)="deleteSection()"
          class="py-2 px-4 bg-red-700 text-white font-bold  rounded-md">Delete</button>
      </div>
    </div>
  </div>

</div>
<!-- toast -->
<app-alert class="fixed top-0 flex justify-end w-full z-[2000]" [toastType]="toastType" [toastMessage]="toastMessage"
  [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>

<!-- Crop Image Modal -->
<app-crop-image class="w-full h-full flex justify-center" *ngIf="cropModalOpen"  [theImage]="imageChangedEvent" [useBase64]="useBase64"  [aspectRatio]="4 / 4" (returnCroppedImage)="processCroppedImage($event)" (closeCropModal)=closeCroppingModal()></app-crop-image>

<!-- Upload File Modal -->
<app-file-upload-modal *ngIf="uploadFileModalOpen" [mediaList]="mediaList" (returnSavedMediaURLs)="getTheUrlsSaved($event)"></app-file-upload-modal>

<app-create-confirmation *ngIf="confirmSavingAboutModal"  (cancelClicked)=closeConfirmationModal() (saveBtnClicked)=saveSection(newSectionForm.value) createdFromComponent='About Us' componentName="AboutUs"></app-create-confirmation>
<app-edit-confirmation *ngIf="confirmUpdatingAboutModal"  (cancelClicked)=closeConfirmationModal() (saveBtnClicked)=saveUpdate() editedFromComponent='About us' componentName="AboutUs"></app-edit-confirmation>
