import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { HelperService } from 'src/app/services/helper/helper';

@Component({
  selector: 'app-blog-list-minimal',
  templateUrl: './blog-list-minimal.component.html',
  styleUrls: ['./blog-list-minimal.component.css']
})
export class BlogListMinimalComponent implements OnInit {


  @Input() posts;
  @Input() currentUser;
  @Input() itemNumber;
  @Input() preview
  @Input() view

  constructor(
    private helperServices: HelperService,
    private router: Router,

  ) { }

  ngOnInit(): void {
  }

  truncateHTML(text: any, limit): string {
    return this.helperServices.truncateHTML(text, limit);
   }

   getInnerText(text){
    return this.helperServices.getInnerText(text);
  }

  navigate(blog) {
    if (this.currentUser) {
      if (this.currentUser.role === 'Admin') {
        // this.productsServiceMenuService.setCalledFromData(this.calledFrom)
        this.router.navigate(['/admin/view-blog', blog.id]);
      }
      if (this.currentUser.role === 'User') {
        this.router.navigate(['/user/view-blog', blog.id]);
      }
      if (this.currentUser.role === 'Employee') {
        this.router.navigate(['/employee/view-blog', blog.id]);
      }
    } else {
      this.router.navigate(['/view-blog', blog.id]);

    }

  }
}
