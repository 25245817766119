<div class="modal modal-open" id="editItemUnderServiceModalw">
  <div class="modal-box max-w-4xl rounded-md">
    <!-- Adding Services Under -->
    <form [formGroup]="itemUnderCategoryForm" (ngSubmit)="saveService(itemUnderCategoryForm.value)">
      <div class="form-control w-full bg-white md:px-6 px-2  py-6 space-y-2 shadow-sm">
        <div class="flex space-x-4 items-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M315.4 15.5C309.7 5.9 299.2 0 288 0s-21.7 5.9-27.4 15.5l-96 160c-5.9 9.9-6.1 22.2-.4 32.2s16.3 16.2 27.8 16.2H384c11.5 0 22.2-6.2 27.8-16.2s5.5-22.3-.4-32.2l-96-160zM288 312V456c0 22.1 17.9 40 40 40H472c22.1 0 40-17.9 40-40V312c0-22.1-17.9-40-40-40H328c-22.1 0-40 17.9-40 40zM128 512a128 128 0 1 0 0-256 128 128 0 1 0 0 256z"/></svg>
            <div class="w-10/12">
              <p class="font-bold text-lg">{{servicesName}} Included</p>
              <p class="text-xs text-gray-500">What does this {{servicesName}} include? </p>
            </div>
        </div>
        <hr>
        <div>
          <div  class="flex flex-col gap-y-4 pb-2" >
            <!-- Title -->
            <div>   
              <label for="serviceTitle" class="block tracking-wide text-gray-700 text-base font-bold mb-2">Service Name <span class="text-red-500">*</span></label>
              <input
                class="w-full input input-bordered input-primary"
                id="Title"
                type="text"
                placeholder="Title"
                formControlName="serviceTitle">
            </div>
            
            <!-- Description -->
            <div>
                <label for="serviceDesc" class="block tracking-wide text-gray-700 text-base font-bold mb-2">Service Description </label>
                <textarea id="Description"
                class="textarea appearance-none h-52 block w-full input input-bordered input-primary"
                placeholder="Description"
                formControlName="serviceUnderDescription"></textarea>
            </div>

            <!-- Price -->
            <div>
              <label for="servicePrice" class="block tracking-wide text-gray-700 text-base font-bold mb-2">Service Price(If any)</label>
              <input class="w-full md:w-1/2 input input-bordered input-primary"
                type="text"   placeholder="Price" formControlName="servicePrice">
            </div>
          </div>
          <hr>
          <!-- Appointment -->
          <ng-container *ngIf="canTakeAppt" >
            <div class="form-control  bg-white px-2  py-6 space-y-2 shadow-sm">
              <div class="flex space-x-4 items-center">
                <svg width="32" height="32" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.20514 6.74359C10.7912 6.74359 12.0769 5.45784 12.0769 3.87179C12.0769 2.28575 10.7912 1 9.20514 1C7.61909 1 6.33334 2.28575 6.33334 3.87179C6.33334 5.45784 7.61909 6.74359 9.20514 6.74359Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M1 16.1795C1 12.5541 4.30626 9.61539 8.38461 9.61539" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M13.3077 17C15.3469 17 17 15.3469 17 13.3077C17 11.2685 15.3469 9.61539 13.3077 9.61539C11.2685 9.61539 9.61539 11.2685 9.61539 13.3077C9.61539 15.3469 11.2685 17 13.3077 17Z" stroke="black" stroke-width="1.5"/>
                  <path d="M12.8974 12.0769V13.7179H14.5385" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  <div class="w-10/12">
                    <p class="font-bold text-lg">Appointment</p>
                    <p class="text-xs text-gray-500">Does this service require an Appointment? </p>
                  </div>
              </div>  

              <div class="flex justify-between w-1/2 ">
                <div class="form-control">
                  <label class="label cursor-pointer">
                    <input type="radio" required formControlName="hasAppointment" [value]="true"
                      class="radio checked:bg-customTeal" />
                    <span class="label-text ml-4 font-normal text-black">Yes</span>
                  </label>
                </div>
                <div class="form-control">
                  <label class="label cursor-pointer">
                    <input type="radio" required formControlName="hasAppointment" [value]="false"
                      class="radio checked:bg-customTeal" />
                    <span class="label-text ml-4 font-normal text-black">No</span>
                  </label>
                </div>
              </div>

              <ng-container *ngIf="hasAppointment.value" class="">
                <!-- Duration -->
                <div class="form-control bg-white md:px-10 px-2  py-3 space-y-2 " >
                  <div class="flex space-x-2 items-center">
                  <svg width="24" height="24" viewBox="0 0 232 232" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M106.333 203C94.2502 203 82.9337 200.744 72.3842 196.233C61.8282 191.722 52.6029 185.519 44.7085 177.625C36.8141 169.731 30.6113 160.505 26.1002 149.949C21.5891 139.4 19.3335 128.083 19.3335 116C19.3335 91.6722 27.7532 71.0887 44.5925 54.2493C61.4254 37.4164 82.0057 29 106.333 29C108.428 29 110.442 29.0806 112.375 29.2417C114.309 29.4028 116.242 29.6444 118.175 29.9667C120.27 30.2889 122.042 31.3748 123.492 33.2243C124.942 35.0803 125.667 37.1361 125.667 39.3917C125.667 42.6139 124.581 45.0692 122.409 46.7577C120.231 48.4526 117.45 49.1389 114.067 48.8167C112.778 48.6556 111.489 48.5331 110.2 48.4493C108.911 48.372 107.622 48.3333 106.333 48.3333C87.3224 48.3333 71.2918 54.8583 58.2418 67.9083C45.1918 80.9583 38.6668 96.9889 38.6668 116C38.6668 135.011 45.1918 151.042 58.2418 164.092C71.2918 177.142 87.3224 183.667 106.333 183.667C125.183 183.667 141.095 177.222 154.068 164.333C167.034 151.444 173.678 135.575 174 116.725C174 113.986 174.928 111.569 176.784 109.475C178.634 107.381 180.928 106.333 183.667 106.333C186.245 106.333 188.5 107.258 190.434 109.108C192.367 110.964 193.333 113.1 193.333 115.517V116C193.333 140.328 184.914 160.908 168.074 177.741C151.242 194.58 130.661 203 106.333 203ZM126.634 149.833L99.5668 122.767C98.6002 121.8 97.8752 120.711 97.3918 119.499C96.9085 118.294 96.6668 117.047 96.6668 115.758V77.3333C96.6668 74.5945 97.5948 72.297 99.4508 70.441C101.3 68.5914 103.595 67.6667 106.333 67.6667C109.072 67.6667 111.37 68.5914 113.226 70.441C115.075 72.297 116 74.5945 116 77.3333V112.133L140.167 136.3C141.939 138.072 142.825 140.328 142.825 143.067C142.825 145.806 141.939 148.061 140.167 149.833C138.395 151.606 136.139 152.492 133.4 152.492C130.661 152.492 128.406 151.606 126.634 149.833Z" fill="black"/>
                    </svg>
                    <span class="label-text text-[#4F4F4F] text-base font-bold  leading-5">Duration</span>
                  </div>
    
                  <div class="flex flex-row gap-2 w-1/2">
                    <div class="form-control w-1/2">
                      <label class="label">
                        <span class="label-text text-[#4F4F4F] text-base font-normal leading-5">Hour</span>
                      </label>
                      <input placeholder="Type here" id="serviceHour" type="number" formControlName="serviceHour" min="0"
                        max="23" class="iinput input-bordered input-primaryw-full " />
                    </div>
                    <div class="form-control w-1/2">
                      <label class="label">
                        <span class="label-text text-[#4F4F4F] text-base font-normal leading-5">Minute</span>
                      </label>
                      <input placeholder="Type here" id="serviceMinute" type="number" formControlName="serviceMinute" min="0"
                        max="59" class="iinput input-bordered input-primary0 w-full" />
                    </div>
                  </div>
    
                </div>
    
                <!-- buffer time -->
                <ng-container *ngIf="hasAppointment.value">
                  <div class="form-control bg-white md:px-10 px-2  py-3 space-y-3 " >
                    <div class="flex gap-2 space-x-2 items-center">
                    <svg width="16" height="16" viewBox="0 0 220 220" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M17.1576 64.1309L101.978 105.089C104.187 106.141 107.134 106.691 110.052 106.691C112.969 106.691 115.917 106.141 118.121 105.089L202.942 64.1309C207.402 61.9824 207.402 58.4676 202.942 56.3105L118.121 15.3527C115.917 14.3 112.965 13.75 110.052 13.75C107.138 13.75 104.187 14.3 101.978 15.3527L17.1576 56.3105C12.6975 58.4676 12.6975 61.9738 17.1576 64.1309Z" fill="black"/>
                      <path d="M202.941 106.09C202.941 106.09 187.451 98.6219 185.397 97.6164C183.343 96.6109 182.789 96.6668 180.632 97.668C178.475 98.6691 118.07 127.845 118.07 127.845C115.523 128.944 112.773 129.492 110 129.452C107.091 129.452 104.135 128.898 101.931 127.845C101.931 127.845 43.1278 99.4856 40.3692 98.1191C37.3829 96.6797 36.5235 96.6797 33.8036 97.9688L17.0458 106.038C12.5856 108.187 12.5856 111.706 17.0458 113.859L101.879 154.816C104.083 155.869 107.035 156.419 109.949 156.419C112.862 156.419 115.814 155.869 118.022 154.816L202.843 113.867C207.402 111.753 207.402 108.247 202.941 106.09Z" fill="black"/>
                      <path d="M202.941 155.869C202.941 155.869 187.451 148.401 185.397 147.383C183.343 146.364 182.789 146.433 180.632 147.434C178.475 148.436 118.121 177.676 118.121 177.676C115.574 178.773 112.824 179.32 110.052 179.278C107.143 179.278 104.186 178.728 101.978 177.676C101.978 177.676 43.175 149.316 40.4207 147.95C37.4129 146.493 36.5535 146.493 33.8508 147.8L17.093 155.869C12.6285 158.018 12.6285 161.532 17.093 163.689L101.913 204.647C104.118 205.747 107.07 206.25 109.983 206.25C112.896 206.25 115.848 205.7 118.052 204.647L202.89 163.711C207.402 161.532 207.402 158.026 202.941 155.869Z" fill="black"/>
                      </svg>
          
                      <span class="label-text text-[#4F4F4F] text-base font-bold leading-5">Buffer time(in Minutes)
                        <div class="tooltip" data-tip="Extra time added to a service(to cleanup for the appointment or to prepare for the next appointment)">
                          <button type="button"><i class="material-icons">help</i></button>
                        </div>
                      </span>
              
                    </div>
                    <div class="flex flex-row gap-2">
                      <div class="form-control w-1/2 ">
                        <input placeholder="Type here" id="bufferTime" type="number" formControlName="bufferTime" min="0"
                          max="120" class="input input-bordered input-primary"
                          [ngClass]="{
                            'border-red-500': (bufferTime.dirty || bufferTime.touched) && bufferTime.invalid,
                            'border-gray-200': !((bufferTime.dirty || bufferTime.touched) && bufferTime.invalid)
                          }" />
                      </div>
                    </div>
                    <div *ngIf="(bufferTime.dirty || bufferTime.touched) && bufferTime.invalid">
                      <small *ngIf="bufferTime.errors.required" class="text-red-500 italic text-xs">Invalid buffer time.
                      </small>
                    </div>
                  </div>
                </ng-container>
                <!-- assign employee -->
                <div class="form-control  bg-white md:px-10 px-2  py-3 space-y-2 ">
                  <div class="flex gap-2 space-x-2 items-center">
                  <svg width="24" height="24" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.5 14.6479C18.9098 14.6479 20.8633 12.719 20.8633 10.3396C20.8633 7.96016 18.9098 6.03125 16.5 6.03125C14.0902 6.03125 12.1367 7.96016 12.1367 10.3396C12.1367 12.719 14.0902 14.6479 16.5 14.6479Z" fill="black"/>
                    <path d="M9.88166 10.7705H10.3217V10.3764C10.3243 9.23018 10.6477 8.1076 11.2552 7.13562C11.8627 6.16364 12.73 5.38105 13.7592 4.87638C13.4545 4.17473 12.9655 3.5687 12.344 3.12265C11.7226 2.6766 10.992 2.4072 10.2297 2.34305C9.46748 2.27889 8.70208 2.4224 8.01484 2.75831C7.3276 3.09422 6.74417 3.60999 6.32652 4.25086C5.90887 4.89172 5.67259 5.63373 5.64278 6.3981C5.61297 7.16246 5.79075 7.92062 6.15722 8.59206C6.5237 9.26351 7.06519 9.82315 7.72418 10.2116C8.38318 10.6 9.13507 10.8026 9.9 10.798L9.88166 10.7705ZM22.6967 10.3397V10.7339H23.1367C23.8914 10.7263 24.6301 10.5154 25.2751 10.1234C25.9201 9.73135 26.4475 9.17269 26.8017 8.5062C27.156 7.83972 27.324 7.09006 27.2881 6.33613C27.2522 5.58221 27.0138 4.8519 26.5978 4.22208C26.1818 3.59226 25.6038 3.08622 24.9245 2.75723C24.2452 2.42824 23.4897 2.28847 22.7377 2.35262C21.9856 2.41677 21.2648 2.68248 20.651 3.12178C20.0373 3.56107 19.5533 4.15771 19.25 4.84888C20.2794 5.35158 21.1478 6.13218 21.7568 7.10249C22.3659 8.07279 22.6914 9.1941 22.6967 10.3397ZM20.4325 15.0789C22.2551 15.4396 24.0173 16.0569 25.6667 16.9122C25.899 17.0399 26.1095 17.2039 26.29 17.3981H31.1667V14.2631C31.1676 14.1428 31.1356 14.0246 31.0742 13.9212C31.0128 13.8178 30.9243 13.7332 30.8183 13.6764C28.4425 12.4337 25.7996 11.7887 23.1183 11.7972H22.5133C22.1956 13.0885 21.465 14.2408 20.4325 15.0789ZM5.98583 19.1764C5.98416 18.7147 6.10883 18.2614 6.34636 17.8655C6.58388 17.4697 6.9252 17.1463 7.33333 16.9305C8.98267 16.0752 10.7449 15.458 12.5675 15.0972C11.54 14.2663 10.8098 13.1244 10.4867 11.8431H9.88166C7.20044 11.8345 4.55753 12.4795 2.18166 13.7222C2.07567 13.779 1.9872 13.8637 1.92581 13.967C1.86442 14.0704 1.83244 14.1886 1.83333 14.3089V20.1664H5.98583V19.1764ZM19.6717 24.4656H25.135V25.7489H19.6717V24.4656Z" fill="black"/>
                    <path d="M30.0758 19.4885H23.7783V18.5718C23.7783 18.3287 23.6818 18.0955 23.5099 17.9236C23.3379 17.7517 23.1048 17.6552 22.8617 17.6552C22.6186 17.6552 22.3854 17.7517 22.2135 17.9236C22.0416 18.0955 21.945 18.3287 21.945 18.5718V19.4885H20.1667V16.8943C18.9606 16.6411 17.7323 16.509 16.5 16.5002C13.6068 16.4881 10.7549 17.1869 8.195 18.5352C8.08098 18.5943 7.98553 18.6838 7.91921 18.7938C7.85288 18.9038 7.81826 19.03 7.81917 19.1585V24.301H14.3367V29.8835C14.3367 30.1266 14.4332 30.3598 14.6052 30.5317C14.7771 30.7036 15.0102 30.8002 15.2533 30.8002H30.0758C30.319 30.8002 30.5521 30.7036 30.724 30.5317C30.8959 30.3598 30.9925 30.1266 30.9925 29.8835V20.4052C30.9925 20.162 30.8959 19.9289 30.724 19.757C30.5521 19.5851 30.319 19.4885 30.0758 19.4885ZM29.1592 28.9852H16.17V21.3218H21.945V22.156C21.945 22.3991 22.0416 22.6323 22.2135 22.8042C22.3854 22.9761 22.6186 23.0727 22.8617 23.0727C23.1048 23.0727 23.3379 22.9761 23.5099 22.8042C23.6818 22.6323 23.7783 22.3991 23.7783 22.156V21.3218H29.1592V28.9852Z" fill="black"/>
                    </svg>
                    <span class="label-text text-[#4F4F4F] text-base font-bold leading-5">Do you want to assign employees to this service?<span class="text-red-500">*</span></span>
                  </div>
                  <div class="flex justify-between w-1/2 ">
                    <div class="form-control">
                      <label class="label cursor-pointer">
                        <input type="radio" formControlName="showEmployees" [value]="true"
                          class="radio checked:bg-customTeal" />
                        <span class="label-text ml-4 font-normal text-black">Yes</span>
                      </label>
                    </div>
                    <div class="form-control">
                      <label class="label cursor-pointer">
                        <input type="radio" formControlName="showEmployees" [value]="false"
                          class="radio checked:bg-customTeal" />
                        <span class="label-text ml-4 font-normal text-black">No</span>
                      </label>
                    </div>
                  </div>
                </div>

                <!-- New employee assign -->
                <ng-container *ngIf="showEmployees.value">
                  <div class="label-text text-[#4F4F4F] text-base font-bold leading-5 pb-3   ">Assign employee(s) from your list</div>
                  <div class="relative">
                    <div class="flex shadow rounded items-center justify-between p-2 border border-gray-300 cursor-pointer" (click)="toggleDropdown()">
                      <div class="flex flex-wrap">
                        <!-- Show selected options or placeholder -->
                        <span *ngIf="selectedEmployeesIds.length === 0" class="text-gray-400">Select employees</span>
                        <span *ngFor="let name of getSelectedOptionNames()" class="mr-2 mb-1 px-2 py-1 bg-blue-200 rounded">{{ name }}</span>
                      </div>
                      <span class="ml-2 text-gray-500">
                        <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
                        </svg>
                      </span>
                    </div>
                    <ul *ngIf="isDropdownOpen" class="absolute mt-1 border border-gray-300 rounded bg-white w-full z-10 max-h-48 overflow-y-auto">
                      <li *ngFor="let option of employees" 
                          class="px-4 py-2 cursor-pointer hover:bg-blue-100 flex justify-between items-center"
                          (click)="selectOption(option)">
                        {{ option.GivenName }} {{option.LastName}}
                        <span *ngIf="isSelected(option)" class="text-blue-500">
                          <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-7.5 7.5a1 1 0 01-1.414 0l-3.5-3.5a1 1 0 011.414-1.414l2.793 2.793 6.793-6.793a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                          </svg>
                        </span>
                      </li>
                    </ul>
                  </div>
                </ng-container>
    
              </ng-container> 


            </div>
          </ng-container>
        </div>
  
        <div class="flex justify-end space-x-4">
          <div (click)="cancelCategoryUnder()"
            class="flex items-center space-x-2 rounded bg-white cursor-pointer  border-solid border-gray-300 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-gray-600 shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out  border ">
            Cancel
          </div>
          <div (click)="saveUpdateToCategoryUnder()">
            <button class="flex items-center space-x-2 rounded bg-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] "
            type="button">
              {{isEdit ? 'Update' : 'Add'}}
            </button>
          </div>
        </div>

      </div>
    </form>

  </div>
</div>