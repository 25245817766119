import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/firebase/auth.service';
import { map, take, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})

export class EmployeeAuthGuard  {

    constructor(private auth: AuthService, private router: Router) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        // if (this.auth.isLogged && this.auth.role !== '') {
        //     if (this.auth.role === 'Employee') {
        //         return true;
        //     } else {
        //         this.router.navigate(['/auth/login']);
        //         return false;
        //     }
        // }

        if (this.auth.isLogged ) {
            if (this.auth.role === 'Employee') {
              return true;
            } else {
              this.router.navigate(['/home']);
              return false; }
          }

        return this.auth.currentUserObservable
          .pipe(
            take(1),
            map(user => !!user),
            tap(
              loggedIn => {
                if (!loggedIn) {
                  this.router.navigate(['/home']);
                }
              }
            )
          );

    }
}
