
<div class="row col s12">
  <div class="col l1 "></div>
  <div class="col s12 m12 l10" *ngIf="model">

    <div *ngIf="step1">
      <div class="row center-align">
        <h5><strong> Edit Survey / Poll </strong></h5>
      </div>
      <form [formGroup]="surveyForm" (ngSubmit)="submitSurveyInfo(surveyForm.value)">

        <div class="card-panel white">
          <div class="row">

            <div class="input-field col s12">
              <i class="material-icons prefix purple-text">poll</i>
              <select formControlName="type" class="validate" (change)="typeChanges($event.target.value)">
                <option value="" disabled selected>Choose your option</option>
                <option value="Survey">Survey</option>
                <option value="Poll">Poll</option>
                <!-- <option value="both">Both</option> -->
              </select>
              <label for="type">Survey or Polls?<span class="red-text">*</span></label>
            </div>

            <div class="input-field col s12">
              <i class="material-icons prefix purple-text">account_circle</i>
              <input id="surveyName" type="text" formControlName='surveyName' required class="validate"
                autocomplete="off" style="text-transform: capitalize;" />
              <label for="surveyName">Poll/Survey Name</label>
              <div *ngIf="(surveyName.dirty || surveyName.touched) && surveyName.invalid">
                <small *ngIf="surveyName.errors.required" class="red-text"> The Poll/Survey Name is
                  required. </small>
              </div>
            </div>

            <div class="input-field col s12">
              <i class="material-icons prefix pink-text">calendar_today</i>
              <input id="startDate" type="text" class="datepicker" (change)="startDateChange()"
                formControlName='startDate' autocomplete="off" />
              <label for="startDate">Start Date</label>
              <div *ngIf="(startDate.dirty || startDate.touched) && startDate.invalid">
                <small *ngIf="startDate.errors.required" class="red-text"> The Start Date is required.
                </small>
              </div>
            </div>

            <div class="input-field col s12">
              <i class="material-icons prefix pink-text">calendar_today</i>
              <input id="endDate" type="text" class="datepicker" (change)="endDateChange()" formControlName='endDate'
                autocomplete="off" />
              <label for="endDate">End Date</label>
              <div *ngIf="(endDate.dirty || endDate.touched) && endDate.invalid">
                <small *ngIf="endDate.errors.required" class="red-text"> The End Date is required.
                </small>
              </div>
            </div>

            <div class="row input-field col s12">
              <div class="row col s12">Do You Want to Show the Results ? <span class="red-text">*</span>
              </div>
              <div class="row col s12">
                <label class="col s6">
                  <input class="with-gap" formControlName="showResult" name="showResult" type="radio" value="yes"
                    (change)="onShowResultYes($event)" />
                  <span>Yes</span>
                </label>
                <label class="col s6">
                  <input class="with-gap" formControlName="showResult" name="showResult" type="radio" value="no"
                    (change)="onShowResultNo($event)" />
                  <span>No</span>
                </label>
              </div>

              <div class="input-field col s12" *ngIf="showSurveyResult">
                <i class="material-icons prefix purple-text">poll</i>
                <select formControlName="showResultTo" class="validate">
                  <option value="" disabled selected>Choose your option</option>
                  <option value="member">Member Only</option>
                  <option value="public">General Public</option>
                  <option value="both">Both</option>
                </select>
                <label for="showResultTo">Show Results To<span class="red-text">*</span></label>
              </div>
            </div>

          </div>
        </div>

        <div class="row col s12">
          <div class="section"></div>
          <button type="submit" [disabled]="!surveyForm.valid" style="margin-bottom: 15px;"
            class="col s12 btn waves-effect green">
            <i class="material-icons right">navigate_next</i> Next
          </button>

          <a [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backClicked()"><i
              class="material-icons left">house</i>
            Back To Home Page </a>
        </div>
      </form>
    </div>

    <div *ngIf="step2">

      <div class="row center-align">
        <h5 *ngIf="createPoll"><strong> Create Poll </strong></h5>
        <h5 *ngIf="!createPoll"><strong> Create Survey</strong></h5>
      </div>

      <form [formGroup]="questionForm" (ngSubmit)="submitQuestions(questionForm.value)">

        <div formArrayName="questionItems"
          *ngFor="let item of questionForm.get('questionItems').controls; let i = index;">
          <div [formGroupName]="i">

            <div class="row">
              <div class="card white">
                <div class="card-content row">

                  <a class="btn-floating btn-small halfway waves-light red right" *ngIf="i > 0"
                    (click)="removeFile(i)"><i class="material-icons">clear</i></a>
                  <h6 class="center"><strong>Question {{i + 1}} :</strong></h6>

                  <div class="input-field col s12">
                    <i class="material-icons prefix blue-text">help_outline</i>
                    <input id="" type="text" formControlName="question" required class="validate" autocomplete="off"
                      style="text-transform: capitalize;" placeholder="Question" />
                    <div *ngIf="(item.controls.question.dirty || item.controls.question.touched) && item.controls.question.invalid">
                      <small *ngIf="item.controls.question.errors.required" class="red-text"> The Question is required.
                      </small>
                    </div>
                  </div>

                  <div class="row input-field col s12">
                    <i class="material-icons teal-text prefix">add_task</i>
                    <select formControlName="answerType" required class="validate"
                      (change)="answerTypeChange($event.target.value, i)">
                      <option value="" disabled selected>Please Select Answer Type</option>
                      <option *ngFor="let answer of answerTypes" [value]="answer.type">{{answer.type}}
                        <span class="green-text right"> ( {{answer.helpText}} )</span>
                      </option>
                    </select>
                    <div *ngIf="(item.controls.answerType.dirty || item.controls.answerType.touched) && item.controls.answerType.invalid">
                      <small *ngIf="item.controls.answerType.errors.required" class="red-text"> The Type is required.
                      </small>
                    </div>
                  </div>

                  <div  *ngIf="item.controls.answerType.value === 'Multiple Choice'">

                    <div class="row input-field col s12 m5 right">
                      <label>
                        <input type="checkbox" formControlName ="allowMultipleAnswers" />
                        <span>Allow Multiple Answers</span>
                      </label>
                    </div>
                    <br/>
                    <div class="row input-field col s12" formArrayName="answers"
                      *ngFor="let ans of getAnswers(i).controls; let j = index;">
                      <div [formGroupName]="j">
                        <a *ngIf="j > 1" class="btn-flat waves-effect waves-light col s1 right"><i
                            class="material-icons red-text prefix"
                            (click)="removeAnswer(i, j)">remove_circle_outline</i></a>
                        <div class="row input-field col s11">
                          <i class="material-icons purple-text prefix">question_answer</i>
                          <input id="answer" type="text" formControlName="answer" required class="validate"
                            autocomplete="off" style="text-transform: capitalize;" placeholder="Answer" />
                          <div
                            *ngIf="(ans.controls.answer.dirty || ans.controls.answer.touched) && ans.controls.answer.invalid">
                            <small *ngIf="ans.controls.answer.errors.required" class="red-text"> Answer {{j+1}} is
                              required.</small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row col s12">
                      <div class="section"></div>
                      <button type="button" style="margin-bottom: 15px;" class="col s12 btn waves-effect blue darken-4"
                        (click)="onAddAnswer(i)" [disabled]="!questionForm.valid" style="cursor: pointer;">
                        <i class="material-icons white-text right">add</i> Add Answer
                      </button>
                    </div>
                    <!-- <div class="row input-field col s12">
                        <i class="material-icons pink-text prefix">format_list_numbered</i>
                        <select formControlName="numberOfAnswers" required class="validate" (change)="numberOfanswerChange($event.target.value, i)">
                          <option value="" disabled selected>Number Of Answers</option>
                          <option *ngFor="let num of numberOfAnswers" [value]="num">{{num}}</option>
                        </select>
                        <div *ngIf="(item.controls.numberOfAnswers.dirty ||
                        item.controls.numberOfAnswers.touched) &&
                        item.controls.numberOfAnswers.invalid">
                          <small *ngIf="item.controls.numberOfAnswers.errors.required" class="red-text"> The Type is required. </small>
                        </div>
                      </div> -->

                  </div>

                  <div *ngIf="item.controls.answerType.value === 'Scale'" formGroupName="scales">
                    <div class="input-field col s6 m3">
                      <i class="material-icons prefix green-text">linear_scale</i>
                      <select formControlName="start" class="validate">
                        <option value="0">0</option>
                        <option value="1">1</option>
                      </select>
                      <!-- <input id="start" type="text" formControlName='start' class="validate" /> -->
                      <label for="start">Start At</label>
                      <!-- <div *ngIf="(surveyName.dirty || surveyName.touched) && surveyName.invalid">
                        <small *ngIf="surveyName.errors.required" class="red-text"> The Poll/Survey Name is required. </small>
                      </div> -->
                    </div>

                    <div class="input-field col s6 m3">
                      <i class="material-icons prefix green-text">label</i>
                      <input id="startLabel" type="text" formControlName='startLabel' class="validate" />
                      <label for="startLabel">Label</label>
                      <!-- <div *ngIf="(surveyName.dirty || surveyName.touched) && surveyName.invalid">
                        <small *ngIf="surveyName.errors.required" class="red-text"> The Poll/Survey Name is required. </small>
                      </div> -->
                    </div>

                    <div class="input-field col s6 m3">
                      <i class="material-icons prefix blue-text">linear_scale</i>
                      <select formControlName="end" class="validate">
                        <option *ngFor="let s of scales" [value]="s">{{s}}</option>
                      </select>
                      <!-- <input id="end" type="text" formControlName='end' class="validate" /> -->
                      <label for="end">End At</label>
                      <!-- <div *ngIf="(surveyName.dirty || surveyName.touched) && surveyName.invalid">
                        <small *ngIf="surveyName.errors.required" class="red-text"> The Poll/Survey Name is required. </small>
                      </div> -->
                    </div>

                    <div class="input-field col s6 m3">
                      <i class="material-icons prefix blue-text">label</i>
                      <input id="endLabel" type="text" formControlName='endLabel' class="validate" />
                      <label for="endLabel">Label</label>
                      <!-- <div *ngIf="(surveyName.dirty || surveyName.touched) && surveyName.invalid">
                        <small *ngIf="surveyName.errors.required" class="red-text"> The Poll/Survey Name is required. </small>
                      </div> -->
                    </div>

                    <!-- <div class="row col s12">
                      <p class="col s1">start lable</p>
                      <div class="col s10">
                        <div [ngClass]="getColSize(0,10)" *ngFor="let i of createRange(0,10)">

                          <label class="row col s12">
                            <input name="group1" class="col s12" type="radio"/>
                            <span class="row col s12">{{i}}</span>
                          </label>

                        </div>

                      </div>

                      <p class="col s1">end lable</p>

                    </div> -->

                  </div>

                  <!-- <div *ngIf="item.controls.numberOfAnswers.value !== ''">
                      <div class="input-field col s12">
                        <i class="material-icons prefix purple-text">question_answer</i>
                        <input id=""  type="text" formControlName="answer1"  class="validate" autocomplete="off"
                          style="text-transform: capitalize;" placeholder="Answer 1" />
                        <div *ngIf="(item.controls.answer1.dirty ||
                        item.controls.answer1.touched) &&
                        item.controls.answer1.invalid">
                          <small *ngIf="item.controls.answer1.errors.required" class="red-text"> Answer 1 is required. </small>
                        </div>
                      </div>

                      <div class="input-field col s12">
                        <i class="material-icons prefix purple-text">question_answer</i>
                        <input type="text" formControlName="answer2"  class="validate" autocomplete="off"
                          style="text-transform: capitalize;" placeholder="Answer 2" />
                        <div *ngIf="(item.controls.answer2.dirty ||
                        item.controls.answer2.touched) &&
                        item.controls.answer2.invalid">
                          <small *ngIf="item.controls.answer2.errors.required" class="red-text"> Answer 2 is required. </small>
                        </div>
                      </div>

                    </div>

                    <div *ngIf="item.controls.numberOfAnswers.value === '3' || item.controls.numberOfAnswers.value === '4'">
                      <div class="input-field col s12">
                        <i class="material-icons prefix purple-text">question_answer</i>
                        <input id=""  type="text" formControlName="answer3"  class="validate" autocomplete="off"
                          style="text-transform: capitalize;" placeholder="Answer 3" />
                        <div *ngIf="(item.controls.answer3.dirty ||
                        item.controls.answer3.touched) &&
                        item.controls.answer3.invalid">
                          <small *ngIf="item.controls.answer3.errors.required" class="red-text"> Answer 3 is required. </small>
                        </div>
                      </div>
                    </div>

                    <div *ngIf="item.controls.numberOfAnswers.value === '4'">
                      <div class="input-field col s12">
                        <i class="material-icons prefix purple-text">question_answer</i>
                        <input id=""  type="text" formControlName="answer4"  class="validate" autocomplete="off"
                          style="text-transform: capitalize;" placeholder="Answer 4" />
                        <div *ngIf="(item.controls.answer4.dirty ||
                        item.controls.answer4.touched) &&
                        item.controls.answer4.invalid">
                          <small *ngIf="item.controls.answer4.errors.required" class="red-text"> Answer 4 is required. </small>
                        </div>
                      </div>
                    </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row col s12">
          <div class="section"></div>

          <button type="button" [disabled]="!questionForm.valid" style="margin-bottom: 15px;" class="col s12 btn"
            (click)="addQuestion()">
            <i class="material-icons right">add</i> Add New Question
          </button>

          <button type="submit" [disabled]="!questionForm.valid" style="margin-bottom: 15px;"
            class="col s12 btn waves-effect green">
            <span *ngIf="createPoll"><i class="material-icons right">cloud</i> Update Poll</span>
            <span *ngIf="!createPoll"><i class="material-icons right">cloud</i> Update Survey</span>
          </button>

          <a [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backClicked()"><i
              class="material-icons left">house</i>
            Back To Previous Page </a>
        </div>
      </form>
    </div>

  </div>
  <div class="col l1 "></div>
</div>
