<div class="px-4 md:px-12 space-y-4 w-full">

    <!-- Send Message Button -->
    <button (click)="openModal()" *ngIf="currentUser.role === 'User'"
    class="flex items-center space-x-2 rounded bg-customTeal px-2 md:px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
          </svg>
          
        <span class="text-white">Send SMS</span>
    </button>

    <div class="flex flex-wrap font-bold md:space-x-4 gap-2" >
        <div class="text-sm text-center" 
          *ngIf="['Admin','User'].includes(userRole) || (permChat && permChat.privateChat)"><button (click)="onChatType('private')" class="px-2 py-1 font-bold border-2 md:px-10 rounded-md border-gray-200 flex items-center space-x-2" 
          [ngClass]="{'text-white bg-customTeal fill-white' : chatType =='private', 'text-customTeal bg-white fill-customTeal': chatType !='private'}">
          <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3" viewBox="0 0 448 512">
            <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"/></svg>
          <span>Private Chat</span>
        </button></div>
        <div class=" text-sm text-center" 
          *ngIf="['Admin','User'].includes(userRole) || (permChat && permChat.groups)"><button (click)="onChatType('group')" class="px-2 py-1 font-bold border-2  rounded-md md:px-10 border-gray-200 flex items-center space-x-2  " 
          [ngClass]="{'text-white bg-customTeal fill-white': chatType =='group', 'text-customTeal bg-white fill-customTeal': chatType !='group'}">
          <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" viewBox="0 0 640 512">
            <path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM504 312V248H440c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V136c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H552v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z"/></svg>
          <span>Group Chat</span>
        </button></div>

        <div class=" text-sm text-center" 
        *ngIf="['Admin','Employee'].includes(userRole)" ><button (click)="onChatType('employee')" class="px-2 py-1 font-bold border-2  rounded-md md:px-10 border-gray-200 flex items-center space-x-2  " 
        [ngClass]="{'text-white bg-customTeal fill-white': chatType =='employee', 'text-customTeal bg-white fill-customTeal': chatType !='employee'}">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" viewBox="0 0 640 512">
          <path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM504 312V248H440c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V136c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H552v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z"/></svg>
        <span>Employee Chat</span>
      </button></div>
      </div>
    
    <div class="flex  w-full ">

    <app-send-message-from-users *ngIf="sendMessage" [userLogged]="currentUser" (cancelSendClicked)="closeModal()"
    (sendClicked)="onSendClicked($event)"></app-send-message-from-users></div>

    <app-private-messages class="w-full" *ngIf="chatType == 'private'"></app-private-messages>
    <app-group-messages class="w-full" *ngIf="chatType == 'group'"></app-group-messages>
    <app-employee-messages class="w-full" *ngIf="chatType == 'employee'"></app-employee-messages>
'' 
</div>