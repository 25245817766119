<div class="flex justify-center h-screen" *ngIf="loading">
    <progress class="progress progress-[#077373] w-full"></progress>
</div>
<div *ngIf="!loading">
    <div class="md:pt-4 grid grid-cols-1 gap-y-5" *ngIf="userProfile">
        <div class="flex flex-col md:flex-row justify-end gap-4  pb:10 md:pb-0 ">
            <div *ngIf="hasActiveAccounts" class="justify-self-end ">
                <button  [routerLink]="['../create-post']" [relativeTo]="activatedRoute"
                class="py-2 px-6 gap-2 bg-customTeal rounded text-white w-50 md:w-60">
                    <i class="material-icons mr-3 float-left">post_add</i>
                    Create A Post
            </button>
            </div>

            <div *ngIf="hasActiveAccounts  && getUserMediaPlatforms().length == 0" class=" justify-self-end">
                <app-link-account [link]="false"></app-link-account>
            </div>

            <div *ngIf="!hasActiveAccounts" class=" justify-self-end">
                <app-link-account [link]="false" [new]="true"></app-link-account>
            </div>
        </div>

        <div *ngIf="hasActiveAccounts && getUserMediaPlatforms().length > 0 && platformAnalytics" class="grid grid-cols-12 gap-y-4">
            <div class="flex pb-10 col-span-12">
                <!-- Select media -->
                <div class="space-y-4">
                    <p class="text-sm">Select Media</p>
                    <div class="flex flex-wrap gap-y-3 md:gap-y-6 items-center">
                        <!-- button -->
                        <div *ngFor="let v of getUserMediaPlatforms()">
                            <ng-container  *ngIf="platformAnalytics && platformAnalytics[v].analytics &&  objectKeys(platformAnalytics[v].analytics).length > 0">
                                <button (click)="selectPlatform(v)"
                            class="rounded-full flex justify-center items-center mx-3 bg-white w-16 h-16 hover:border-4 hover:border-customTeal shadow-md">
                            
                                <i *ngIf="v !== 'tiktok'"
                                [ngClass]="'text-xl fa ' + socialMediaIcons[v].text + ' ' + socialMediaIcons[v].color"></i>
                                <svg class="p-0 justify-self-center" *ngIf="v == 'tiktok'" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 50 50">    <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z"></path></svg>
                            </button>
                            </ng-container>
                            
                        </div>

                    </div>
                </div>
            </div>
            
                <!-- <div class="grid grid-cols-12 gap-4 col-span-12">
                    <app-social-media-platform-detail class="col-span-12 md:col-span-8"
                    [data]="selectedAnalyticsData">
                    </app-social-media-platform-detail>

                    <app-social-media-platform-user class="col-span-12 md:col-span-4"
                    [data]="selectedAnalyticsData">
                    </app-social-media-platform-user>
                </div> -->
                <div class="flex flex-col md:flex-row gap-4 col-span-12">
                    <app-social-media-platform-detail class="w-full md:w-8/12"
                    [data]="selectedAnalyticsData">
                    </app-social-media-platform-detail>

                    <app-social-media-platform-user class="w-full md:w-4/12"
                    [data]="selectedAnalyticsData">
                    </app-social-media-platform-user>
                </div>

                <div class="col-span-12 flex flex-col gap-4"
                [ngClass]="{'md:flex-row-reverse': ['pinterest','instagram','youtube'].includes(this.selectedPlatform),
                            'md:flex-row': !['pinterest','instagram','youtube'].includes(this.selectedPlatform)}">
                    <div class="w-full md:w-4/12 border-2 bg-white rounded-lg p-6" *ngIf="selectedPlatform =='pinterest'"
                    [ngStyle]="borderdPlatform() ? getStyleBorder() : ''" [ngClass]="getBorder()">
                        <p>Engagement rate in %</p>

                        <div class="pt-5">
                            <p class="py-2">Daily</p>
                            <p class="py-2 font-bold" *ngFor="let item of selectedAnalyticsData.daily | keyvalue">{{item.key}}: <span [ngClass]="'text-[' + selectedAnalyticsData.color + ']'"> {{item.value}}</span></p>
                        </div>

                        <div class="pt-5">
                            <p class="py-2">30 days</p>
                            <p class="py-2 font-bold" *ngFor="let item of selectedAnalyticsData.content | keyvalue">{{item.key}}: <span [ngClass]="'text-[' + selectedAnalyticsData.color + ']'"> {{item.value}}</span></p>
                        </div>

                    </div>

                    <div class="w-full md:w-4/12 border-2 bg-white rounded-lg p-6" *ngIf="selectedAnalyticsData.hasExtraContent"
                    [ngStyle]="borderdPlatform() ? getStyleBorder() : ''" [ngClass]="getBorder()">
                        <p>{{selectedAnalyticsData.extraContent.title}}</p>

                        <div class="pt-5 font-bold">
                            <p class="py-2" *ngFor="let item of selectedAnalyticsData.extraContent.content | keyvalue">{{item.key}}: <span [ngClass]="'text-[' + selectedAnalyticsData.color + ']'"> {{item.value}}</span></p>
                        </div>

                    </div>
                    <div class="w-full md:w-8/12 border-2 bg-white rounded-lg py-8 px-12">
                        Media Analysis
                    </div>
        
                </div>

                <!-- <div class="col-span-12 md:col-span-8 px-12 py-8 w-full md:w2/3 border-2 border-red bg-white rounded-lg">
                    Page Fan details

                    <div class="max-w-4xl mx-auto mt-8">
                        <div class="collapse mb-3 collapse-arrow border border-base-300 bg-base-100 rounded-box">
                          <input type="checkbox" />
                          <div class="collapse-title text-base font-bold">
                            Focus me to see content
                          </div>
                          <div class="collapse-content">
                            <p>tabindex="0" attribute is necessary to make the div focusable</p>
                          </div>
                        </div>           
            
            
                    </div>
                </div> -->


        </div>
        <div *ngIf="!hasActiveAccounts || getUserMediaPlatforms().length == 0" 
        class="mt-5 px-3 py-6 md:px-12 md:py-8 w-full border-2 border-red bg-white rounded-lg">
            
            
            <div class="p-b-8">

                <div class="px-5 md:px-0 py-14 flex flex-col w-full jusify-center gap-y-3">
                    <div class="w-full py-4 text-center">
                        <span class="text-lg font-bold pb-3 block" *ngIf="hasActiveAccounts && getUserMediaPlatforms().length == 0">
                            Seems like you have active accounts but not for analytics</span>
                        <span class="text-lg font-bold pb-3 block"  *ngIf="!hasActiveAccounts">
                            Seems like you don't have any account to view analytics</span>
                        <!-- Lorem ipsum dolor, sit amet consectetur
                            adipisicing elit. Laboriosam vero porro, ab quibusdam autem consequuntur consectetur iste
                            dolores enim quisquam possimus ipsam nesciunt, ut doloremque dicta -->
                    </div>

                    <div class="flex justify-center gap-4">
                    <a *ngIf="hasActiveAccounts" [routerLink]="['../create-post']" [relativeTo]="activatedRoute"
                        class="py-2 px-6 gap-2 bg-customTeal rounded text-white w-50 md:w-60">
                        <i class="material-icons mr-3 float-left">post_add</i>
                        Create A Post
                    </a>

                    <app-link-account [link]="false" *ngIf="hasActiveAccounts  && getUserMediaPlatforms().length == 0"></app-link-account>
                    <app-link-account [link]="false" [new]="true" *ngIf="!hasActiveAccounts"></app-link-account>
                </div>

                </div>

            </div>

        </div>
    </div>

    <app-alert class="fixed top-0 flex justify-end w-full" [toastType]="toastType" [toastMessage]="toastMessage" [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>

</div>
