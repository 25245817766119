import { NotificationsService } from './../../../services/firebase/notifications.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {

  @Input () notification;
  constructor(private notificationsService: NotificationsService) { }

  ngOnInit(): void {
  }
  deleteNotification(notif){
    this.notificationsService.deleteNotification(notif);

  }

}
