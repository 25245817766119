<!-- <p>smsverification works!</p> -->
<div class="modal modal-open">
    <div class="modal-box">
      <h3 class="font-bold text-xl text-center mb-2">SMS Verification</h3>
      <!-- <p class="py-4"> A text Message with a six-digit verification code has been sent to your phone number ending in ********</p> -->
      <p class="py-4"> Please enter the code we sent you to the provided phone number.</p>

        <div class="flex justify-center items-center">
            <input [(ngModel)]="code" type="text" class="w-62 px-4 py-2 border-2 border-gray-300 input input-bordered rounded-md pl-10 focus:outline-1 focus:outline-brandColor"  >
        </div>

        <div class="mt-2">Didn't receive the code? <button type="button" (click)="resendCode(code)" class="font-bold text-customTeal">Resend</button></div>
        
        <div *ngIf="wrongCode" class="text-red-600 justify-center">You entered wrong verification code. Try Again.</div>

      <div class="modal-action items-center justify-end">

        <label>
            <button type="button" (click)="cancelVerifying()" class="font-bold text-red-500 border-none"
            type="button"> Cancel</button>
          </label>

        <button type="button" (click)="verifyClicked(code)"
          class="py-2 px-4 bg-customTeal text-white border-customTeal rounded-md">
          Submit
        </button>

      </div>
    </div>
  </div>