// import { AboutService } from './../../../services/firebase/about.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-mission',
  templateUrl: './mission.component.html',
//   styleUrls: ['./mission.component.css']
})
export class AboutMissionComponent implements OnInit {

  constructor() { }

  @Input() data
  ngOnInit(): void {
  }

}
