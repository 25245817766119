import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { Location} from '@angular/common';
import { environment } from 'src/environments/environment';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl } from '@angular/forms';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
// import { toast } from 'materialize-css';
import { MediaService } from 'src/app/services/api/media.service';
import { ImageModel } from 'src/app/models/ImageModel';
import { UserService } from './../../../services/firebase/user.service';
// import { GalleryItem, ImageItem } from 'ng-gallery';

// declare var navigator: any;
// declare var $: any;
// declare var M: any;

@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.css']
})
export class UpdateProfileComponent implements OnInit {

  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  user: any;
  dep = false;
  userId: '';
  updateProfileForm: UntypedFormGroup;
  workplaceForm: UntypedFormGroup;
  educationForm: UntypedFormGroup;
  placesLivedForm: UntypedFormGroup;
  contactAndBasicInfoForm: UntypedFormGroup;
  addFamilyForm: UntypedFormGroup;
  socialMediaForm: UntypedFormGroup;
  mobilePattern = /^\s*([\(])[[0-9]{2}\d{1}[\)]\s*[\-]?[\.]?\s*\d{3}\s*[\-]?[\.]?\s*\d{4}$/;
  public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')' , ' ' , /[0-9]/, /[0-9]/, /[0-9]/, ' ' , '-' , ' ' ,
  /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  base64Image: any;
  imgLink = 'https://www.w3schools.com/howto/img_avatar.png';
  coverPhotoLink = '';
  binDoc: File;
  binDocProfile: File;
  base64ImageProfile: any;
  mobileBase64ImageProfile: any;

  profileImages: any = [];
  imgLinkToBeEdited: any;
  isBusinessAcc = false;
  showInstagramAcc = false;
  showTwitterAcc = false;
  showLinkedinAcc = false;
  showYoutubeAcc = false;
  showFacebookAcc = false;
  showContact = true;
  showEducation = true;
  showPlacesLived = true;
  showFamily = true;
  showWorkplace = true;
  images: any[];
  isMobile = false;
  mobileBase64Image: any;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private location: Location,
    private fb: UntypedFormBuilder,
    private fireMembersService: FireMembersService,
    private mediaService: MediaService) {
      if (typeof window['cordova'] !== 'undefined') {
        this.isMobile = true;
      }

      this.userId = this.authService.getGlobalUser().uid;
      this.fireMembersService.getProfileByuserID(this.userId).valueChanges().subscribe(x => {
        if(x) {
          if(x.length == 0) {
            this.fireMembersService.createNewProfile(this.userId);
          return;
          }

        } });
  }

  ngOnInit() {



    this.updateProfileForm = this.fb.group({
      GivenName: ['', [Validators.required]],
      MiddleName: [''],
      LastName: ['', [Validators.required]],
      City: [''],
      cellPhone: ['', [Validators.pattern(this.mobilePattern)]],
      Email: ['', [Validators.required]],
      isBusinessAcc: [false],

      showContact: [''],
      showEducation: [''],
      showPlacesLived: [''],
      showFamily: [''],
      showWorkplace: [''],
    });

    this.socialMediaForm = this.fb.group({
      instagramAcc: [''],
      twitterAcc: [''],
      linkedinAcc: [''],
      youtubeAcc: [''],
      showInstagramAcc: [''],
      showTwitterAcc: [''],
      showLinkedinAcc: [''],
      showYoutubeAcc: [''],
    })


    this.workplaceForm = this.fb.group({
      company: ['', Validators.required],
      position: ['', Validators.required],
      cityOrTown: [''],
      description: [''],
      currentlyWorkingHere: [''],
      fromDate: [''],
      toDate: ['']
    });

    this.educationForm = this.fb.group({
      school: ['', Validators.required],
      level: ['', Validators.required],
      fromDate: [''],
      toDate: [''],
      learningHere: ['']
    });
    this.placesLivedForm = this.fb.group({
      city: ['', Validators.required],
      dateMoved: ['']
    });
    this.contactAndBasicInfoForm = this.fb.group({
      email: ['', Validators.required],
      mobilePhone: [''],
      languages: ['', ],
      website: [''],
      socialLink: [''],
      politicalView: [''],
      religiousView: [''],
      workPhone1: [''],
      workPhone2: ['']
    });
    this.addFamilyForm = this.fb.group({
      myStatus: [''],
      familyId: ['', Validators.required],
      relation: ['', Validators.required]
    });
    setTimeout(() => {
      // $('.tabs').tabs();
      // $('select').formSelect();
      // $('.datepicker').datepicker();

      // M.updateTextFields();
      // }, 25);


    this.fireMembersService.getProfileByuserID(this.userId).valueChanges().subscribe(x => {

      if(x){

        this.user = x[0];

        this.imgLink = (this.user.profileImgLink) || '';
        this.coverPhotoLink = this.user.coverPhotoLink || '';
        this.profileImages = this.user.profileImages || [];
        if(this.profileImages.length > 0) {
          this.images = [];
          this.profileImages.forEach(element => {
            console.log(element);

            this.images.push(
              ({ src: element, thumb: element }))
          });

          }
        this.showContact = this.user.showContact || false,
        this.showEducation = this.user.showEducation || false,
        this.showPlacesLived = this.user.showPlacesLived || false,
        this.showFamily = this.user.showFamily || false,
        this.showWorkplace = this.user.showWorkplace || false,

        this.updateProfileForm.setValue({
          GivenName: (this.user.GivenName) ? this.user.GivenName : '',
          MiddleName: (this.user.MiddleName) ? this.user.MiddleName : '',
          LastName: (this.user.LastName) ? this.user.LastName : '',
          City: (this.user.City) ? this.user.City : '',
          cellPhone: this.user.cellPhone,
          Email: this.user.Email,
          isBusinessAcc: this.user.isBusinessAcc ? this.user.isBusinessAcc : false,

          showContact: this.user.showContact ? this.user.showContact : this.showContact,
          showEducation: this.user.showEducation ? this.user.showEducation : this.showEducation,
          showPlacesLived: this.user.showPlacesLived ? this.user.showPlacesLived : this.showPlacesLived,
          showFamily: this.user.showFamily ? this.user.showFamily : this.showFamily,
          showWorkplace: this.user.showWorkplace ? this.user.showWorkplace : this.showWorkplace,
        });

        this.socialMediaForm.setValue({
        instagramAcc: this.user.instagramAcc ? this.user.instagramAcc : '',
        twitterAcc: this.user.twitterAcc ? this.user.twitterAcc : '',
        linkedinAcc: this.user.linkedinAcc ? this.user.linkedinAcc : '',
        youtubeAcc: this.user.youtubeAcc ? this.user.youtubeAcc : '',
        showInstagramAcc: this.user.showInstagramAcc ? this.user.showInstagramAcc : 'Private',
        showTwitterAcc: this.user.showTwitterAcc ? this.user.showTwitterAcc : 'Private',
        showLinkedinAcc: this.user.showLinkedinAcc ? this.user.showLinkedinAcc : 'Private',
        showYoutubeAcc: this.user.showYoutubeAcc ? this.user.showYoutubeAcc : 'Private',
        });
      }


      // setTimeout(() => {
      //   ($('.tooltipped')).tooltip();
      //   $('.modal').modal();
      //   $('.collapsible').collapsible();
      //   $('.carousel').carousel();
      // }, 25);

    });



  });
  }

  get getInstagramAcc() { return this.socialMediaForm.get('instagramAcc'); }
  get getTwitterAcc(){ return this.socialMediaForm.get('twitterAcc'); }
  get getLinkedinAcc(){ return this.socialMediaForm.get('linkedinAcc'); }
  get getYoutubeAcc(){ return this.socialMediaForm.get('youtubeAcc'); }

  public getInitials(firstName: any, lastName: any) {
    if (firstName && lastName) {
      return firstName.substring(0, 1) + lastName.substring(0, 1);
    } else {return ''; }
  }

  onUpdateUserProfile(){
    this.user.GivenName = this.updateProfileForm.value.GivenName;
    this.user.MiddleName = this.updateProfileForm.value.MiddleName;
    this.user.LastName = this.updateProfileForm.value.LastName;
    this.user.City = this.updateProfileForm.value.City;
    this.user.cellPhone = this.updateProfileForm.value.cellPhone;
    this.user.Email = this.updateProfileForm.value.Email;
    this.user.showContact = this.showContact;
    this.user.showEducation = this.showEducation;
    this.user.showPlacesLived = this.showPlacesLived;
    this.user.showFamily = this.showFamily;
    this.user.showWorkplace = this.showWorkplace;


      this.user.isBusinessAcc = this.updateProfileForm.value.isBusinessAcc ? this.updateProfileForm.value.isBusinessAcc : false,
      this.user.instagramAcc = this.socialMediaForm.value.instagramAcc,
      this.user.twitterAcc = this.socialMediaForm.value.twitterAcc,
      this.user.linkedinAcc = this.socialMediaForm.value.linkedinAcc,
      this.user.youtubeAcc = this.socialMediaForm.value.youtubeAcc,
      this.user.showInstagramAcc = this.socialMediaForm.value.showInstagramAcc;
      this.user.showTwitterAcc = this.socialMediaForm.value.showTwitterAcc;
      this.user.showLinkedinAcc = this.socialMediaForm.value.showLinkedinAcc;
      this.user.showYoutubeAcc = this.socialMediaForm.value.showYoutubeAcc;



    this.fireMembersService.updateProfile(this.user).then(() => {
      // toast({html: 'Profile Updated Successfully!', classes: 'green'});
    })
  }

  handleImageInput(files: FileList) {
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.base64Image = reader.result as string;

      const img = new ImageModel();
      img.imgBase64 = this.base64Image.replace(/^data:image\/[a-z]+;base64,/, '');
      this.mediaService.uploadImage(img).subscribe(upload => {
        if (upload) {

            this.user.profileImages.push(this.imgLink);
            this.imgLink = upload.imgLink;
            this.user.profileImgLink = this.imgLink;
            this.fireMembersService.updateProfile(this.user);

        }
      });

    };

  }

  handleImageGalleryInput(files: FileList) {
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.base64Image = reader.result as string;

      const img = new ImageModel();
      img.imgBase64 = this.base64Image.replace(/^data:image\/[a-z]+;base64,/, '');
      this.mediaService.uploadImage(img).subscribe(upload => {
        if (upload) {
            this.user.profileImages.push(upload.imgLink);
            this.fireMembersService.updateProfile(this.user);
        }
      });

    };

  }

  handleCoverPhotoImageInput(files: FileList){
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.base64Image = reader.result as string;
    };
    setTimeout(() => {
      const img = new ImageModel();
      img.imgBase64 = this.base64Image.replace(/^data:image\/[a-z]+;base64,/, '');
      this.mediaService.uploadImage(img).subscribe(upload => {
        if (upload) {
          this.coverPhotoLink = upload.imgLink;
          this.user.coverPhotoLink = this.coverPhotoLink;
          this.fireMembersService.updateProfile(this.user); }
      });
    }, 30);

  }
  imageClicked(imgLink){
    console.log(imgLink);
    this.imgLinkToBeEdited = imgLink;
    // $('#imgModal').modal('open');
  }
  makeImgProfile(){
    this.user.profileImages.push(this.imgLink);
    this.imgLink = this.imgLinkToBeEdited;
    this.user.profileImgLink = this.imgLink;
    this.fireMembersService.updateAccount(this.user);
    // $('#imgModal').modal('close');
  }
  removeImage(){
    if (this.user.profileImages.includes(this.imgLinkToBeEdited)) {
      this.user.profileImages = this.user.profileImages.filter(imgLink => imgLink !== this.imgLinkToBeEdited);
    }
    this.fireMembersService.updateAccount(this.user).then(() => {
      // toast({html: 'Image Removed Successfully!', classes: 'green'});
    }).catch(() => {
      // toast({html: 'Cannot Remove Image', classes: 'red'});
    });
    // $('#imgModal').modal('close');
  }

  clickBusinessAccount(){
    this.isBusinessAcc = true;
  }
  clickPersonalAccount(){
    this.isBusinessAcc = false;
  }
  showInstagramPrivacy(){
    this.showInstagramAcc = true;
  }
  showTwitterPrivacy(){
    this.showTwitterAcc = true;
  }
  showLinkedinPrivacy(){
    this.showLinkedinAcc = true;
  }
  showYoutubePrivacy(){
    this.showYoutubeAcc = true;
  }
  showFacebookPrivacy(){
    this.showFacebookAcc = true;
  }
  checkedShowContact(){
    this.showContact = !this.showContact;
  }
  checkedShowEducation(){
    this.showEducation = !this.showEducation;
  }
  checkedShowWorkplace(){
    this.showWorkplace = !this.showWorkplace;
  }
  checkedShowFamily(){
    this.showFamily = !this.showFamily;
  }
  checkedShowPlacesLived(){
    this.showPlacesLived = !this.showPlacesLived;
  }

  addWorkplace(model: any){
    // $('#addWorkplaceModal').modal('close');
    if (this.user.workplaces){
      this.user.workplaces.push(model);
    } else{
      this.user.workplaces = [];
      this.user.workplaces.push(model);

    }
    this.fireMembersService.updateProfile(this.user).then(() => {
      // toast({html: 'Workplace added successfuly!', classes: 'green'});
    }).catch((err) => {
      // toast({html: err, classes: 'red'});
    });
  }
  addEducation(model: any){
    // $('#addEducationModal').modal('close');
    if (this.user.educations){
        this.user.educations.push(model);
    } else{
      this.user.educations = [];
      this.user.educations.push(model);
    }
    this.fireMembersService.updateProfile(this.user).then(() => {
      // toast({html: 'Education added successfully', classes: 'green'});
    }).catch((err) => {
      // toast({html: err, classes: 'red'});
    });
  }
  addPlacesLived(model: any){
    // $('#addPlacesLivedModal').modal('close');
    if (this.user.placesLived){
      this.user.placesLived.push(model);
    } else{
      this.user.placesLived = [];
      this.user.placesLived.push(model);
    }
    this.fireMembersService.updateProfile(this.user).then(() => {
      // toast({html: 'Places Lived Added successfully!'});
    }).catch((err) => {
      // toast({html: err, classes: 'red'});
    });
  }
  addContactAndBasicInfo(model: any){
    // $('#addContactAndBasicInfoModal').modal('close');
    if (this.user.contactAndBasicInfo){
      if (this.user.contactAndBasicInfo.length > 0){
        this.user.contactAndBasicInfo[0] = model;
      } else {
        this.user.contactAndBasicInfo.push(model);
      }
    } else{
      this.user.contactAndBasicInfo = [];
      this.user.contactAndBasicInfo.push(model);
    }
    this.fireMembersService.updateProfile(this.user).then(() => {
      // toast({html: 'Contact and BasicInfo Updated Successfully', classes: 'green'});
    }).catch((err) => {
      // toast({html: err, classes: 'red'});
    });
  }
  addFamily(model: any){
    // $('#addFamilyModal').modal('close');
    if (this.user.families){
      this.user.families.push(model);
    } else{
      this.user.families = [];
      this.user.families.push(model);
    }
    this.userService.updateUser(this.user).then(() => {
      // toast({html: 'Family Updated Successfully'});
    }).catch((err) => {
      // toast({html: err, classes: 'red'});
    });
  }


  openWorkplaceModal(){
    // $('#addWorkplaceModal').modal('open');
  }
  openEducationModal(){
    // $('#addEducationModal').modal('open');
  }
  openPlacesLivedModal(){
    // $('#addPlacesLivedModal').modal('open');
  }
  openContactAndBasicInfoModal(){
    // $('#addContactAndBasicInfoModal').modal('open');
  }
  openFamilyModal(){
    // $('#addFamilyModal').modal('open');
  }
  editContact(contact){
    // console.log('edit contact ', contact);
    this.contactAndBasicInfoForm.setValue({
      email: contact.email,
      mobilePhone: contact.mobilePhone,
      languages: contact.languages,
      website: contact.website,
      socialLink: contact.socialLink,
      politicalView: contact.politicalView,
      religiousView: contact.religiousView,
      workPhone1: contact.workPhone1,
      workPhone2: contact.workPhone2
    });
    // $('#addContactAndBasicInfoModal').modal('open');

  }
  deleteContact(contact){
    // console.log('delete contact ', contact);
  }
  deleteEducation(education){
    // console.log('delete edu ', education);
  }
  editEducation(education){
    // console.log('edit edu ', education);
  }
  editWorkplace(workplace){
    this.workplaceForm.setValue({
      cityOrTown: workplace.cityOrTown,
      company: workplace.company,
      currentlyWorkingHere: workplace.currentlyWorkingHere,
      description: workplace.description,
      fromDate: workplace.fromDate,
      position: workplace.position,
      toDate: workplace.toDate,
    });
    // $('#addWorkplaceModal').modal('open');
    setTimeout(() => {
      // M.updateTextFields();
    }, 25);
  }
  deleteWorkplace(workplace){
    // console.log('delete workplace, ', workplace);
  }
  editFamily(family){
    // console.log('edit family ', family);
  }
  deleteFamily(family){
    // console.log('delete family ', family);
  }
  editPlace(place){
    // console.log('edit place ', place);
  }
  deletePlace(place){
    // console.log('delete place ', place);
  }

  backClicked() {
    this.location.back();
  }
  handleMobilePhotoInput(){
    // navigator.camera.getPicture((image: any) => {
    //   const imageName = 'IMG.jpeg';
    //   const imageBlob = this.dataURItoBlob(image);
    //   const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });
    //   this.binDoc = imageFile;
    //   this.base64Image = 'data:image/jpeg;base64,' + image;
    //   this.mobileBase64Image = image;

    //   const img = new ImageModel();
    //   img.imgBase64 = this.mobileBase64Image;


    //   this.mediaService.uploadImage(img).subscribe(upload => {
    //     if (upload) {
    //       this.coverPhotoLink = upload.imgLink;
    //       this.user.coverPhotoLink = this.coverPhotoLink;
    //       this.fireMembersService.updateAccount(this.user); }
    //   });
    // }, (error) => {
    //   alert(error);
    // },
    // {
    //   quality: 50,
    //   destinationType: navigator.camera.DestinationType.DATA_URL,
    //   encodingType: navigator.camera.EncodingType.JPEG,
    //   mediaType: navigator.camera.MediaType.PICTURE,
    //   sourceType: navigator.camera.PictureSourceType.PHOTOLIBRARY,
    // }
    // );
  }
  handleProfileMobilePhotoInput(){
    // navigator.camera.getPicture((image: any) => {
    //   const imageName = 'IMG.jpeg';
    //   const imageBlob = this.dataURItoBlob(image);
    //   const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });
    //   this.binDocProfile = imageFile;
    //   this.base64ImageProfile = 'data:image/jpeg;base64,' + image;
    //   this.mobileBase64ImageProfile = image;
    //   const img = new ImageModel();
    //   img.imgBase64 = this.mobileBase64ImageProfile;
    //   this.mediaService.uploadImage(img).subscribe(upload => {
    //     if (upload) {
    //       if (this.imgLink){
    //         this.user.profileImages.push(this.imgLink);
    //         this.imgLink = upload.imgLink;
    //         this.user.profileImgLink = this.imgLink;
    //         this.fireMembersService.updateAccount(this.user);
    //       } else {
    //         this.imgLink = upload.imgLink;
    //         this.user.profileImgLink = this.imgLink;
    //         this.fireMembersService.updateAccount(this.user); }
    //     }
    //   });


    // }, (error) => {
    //   alert(error);
    // },
    // {
    //   quality: 50,
    //   destinationType: navigator.camera.DestinationType.DATA_URL,
    //   encodingType: navigator.camera.EncodingType.JPEG,
    //   mediaType: navigator.camera.MediaType.PICTURE,
    //   sourceType: navigator.camera.PictureSourceType.PHOTOLIBRARY,
    // }
    // );


  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
 }
}
