<!-- <div class="flex  md:px-16 md:mx-auto w-full  justify-center items-center ">
    <div class="flex flex-wrap gap-5 justify-center w-full ">
      <div *ngFor="let product of products| slice:0:itemNumber ; index as i " class="flex flex-wrap gap-5 w-full md:w-min ">
   
      <div class="flex flex-col w-full " >
        <div class="flip-card  h-80 " [ngClass]="{' w-full md:w-[380px]': (i==0 || i % 2 == 0) , 'w-full md:w-[680px]': (i == 1 || i % 2 ==1 ) }" [routerLink]="['/view-product', product.id]">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              
                <div *ngIf="product.imgLink && !product.videoLink" (click)="navigateDetails(product)"  >
                  <div class="flex justify-center md:h-48  " >
                    <img [src]="product.imgLink" class="h-80 w-full object-cover">
                  </div>
                </div>
                <div *ngIf="product.videoLink && !product.imgLink" (click)="navigateDetails(product)"  >
                  <video controls *ngIf="product.videoLink != ''" [src]="product.videoLink" class="h-80 w-full object-cover" alt="Logo"></video>           
                </div>
                <div *ngIf="!product.imgLink && !product.videoLink" (click)="navigateDetails(product)" >  
                  
                </div>
                <div>
                  <div *ngIf="product.videoLink && product.imgLink" (click)="navigateDetails(product)"  class="w-full h-40 md:h-48 rounded-t-xl cursor-pointer">
                    <video controls *ngIf="product.videoLink != ''" [src]="product.videoLink" class="h-80 w-full object-cover" alt="Logo" [poster]="product.imgLink"></video>           
                  </div>
                  <div *ngIf="product.videoLink && !product.imgLink" (click)="navigateDetails(product)"  >
                    <video controls *ngIf="product.videoLink != ''" [src]="product.videoLink" class="h-80 w-full object-cover" alt="Logo"></video>           
                  </div>
                </div>
               

            </div>
            <div class="flip-card-back bg-customTeal">
              <div class=" flex flex-col w-full space-y-2 px-4  break-words">
                <h4   class="cursor-pointer  text-xl font-bold py-2 text-center " >{{truncateHTML(getInnerText(product.productName),40)}}</h4>
                <p class="text-white"> {{truncateHTML(getInnerText(product.productDescription),400)}}</p>
                <div *ngIf="product.price">
                  <div *ngIf=" product.price!='dontInclude'">
                    <div *ngIf="product.price > 0" href="" class="  text-center  rounded-md baseline  bottom-0 md:ml-0">
                      <ng-container *ngIf="currency"> {{product.price | currency: currency:'symbol-narrow'}}</ng-container>
                      <ng-container *ngIf="!currency">{{product.price | currency: 'USD':'symbol-narrow'}}</ng-container>
                    </div>
                  </div>
                </div>
                </div>
            
             
            </div>


          </div>
          
        </div>
        <h4   class="cursor-pointer text-xl font-bold py-2 text-center " >{{truncateHTML(getInnerText(product.productName),40)}}</h4>
            
      </div>  
      </div>
  
    </div>
   </div>

   -->


    <div class="flex flex-wrap gap-5 justify-center w-full ">
      <div *ngFor="let product of products| slice:0:itemNumber ; index as i " 
      [ngClass]="{'w-full flex flex-col-reverse  ': preview && view=='mobile', 'w-full flex flex-col-reverse md:flex-row md:space-x-6  items-center ': !preview || (preview && view=='web')}"
      class="gap-5" (click)="navigateDetails(product)">
      <div class=" bg-white shadow-sm p-4 " 
      [ngClass]="{'w-full': preview && view=='mobile', ' w-full md:w-1/2': !preview || (preview && view=='web')}">
        <div *ngIf="product.imgLink && !product.videoLink" class="md:h-full" (click)="navigateDetails(product)"  >
          <div class="flex justify-center md:h-full " >
            <img [src]="product.imgLink" class="object-contain w-full md:h-full">
          </div>
        </div>
        <div *ngIf="product.videoLink && !product.imgLink" (click)="navigateDetails(product)"  >
          <video controls *ngIf="product.videoLink != ''" [src]="product.videoLink" class="h-80 w-full object-cover" alt="Logo"></video>           
        </div>
        <div *ngIf="!product.imgLink && !product.videoLink" (click)="navigateDetails(product)" >  
          
        </div>
      </div>
      <div class=" bg-white flex flex-col p-6 space-y-4 shadow-sm"
      [ngClass]="{'w-full': preview && view=='mobile', ' w-full md:w-1/2': !preview || (preview && view=='web')}">
        <div class=" flex flex-col w-full space-y-2 px-4  break-words">
          <h4   class="cursor-pointer  text-xl font-bold py-2" >{{truncateHTML(getInnerText(product.productName),40)}}</h4>
          <p class=""> {{truncateHTML(getInnerText(product.productDescription),400)}}</p>
          <div *ngIf="product.price">
            <div *ngIf=" product.price!='dontInclude'">
              <div *ngIf="product.price > 0" href="" class="rounded-md baseline  bottom-0 md:ml-0">
                <ng-container *ngIf="currency"> {{product.price | currency: currency:'symbol-narrow'}}</ng-container>
                <ng-container *ngIf="!currency">{{product.price | currency: 'USD':'symbol-narrow'}}</ng-container>
              </div>
            </div>
          </div>
      </div>
      <ng-container *ngIf="product.allProducts">
       
    <div class="flex  flex-col md:flex-row md:items-center space-x-2">
      <div  class="flex gap-2 md:gap-4 flex-wrap mt-4" *ngIf="product.allProducts.length != 0">
        <div  *ngFor="let productFeature of product.allProducts| slice:0:3 ; index as j " class=" flex flex-row flex-wrap gap-4 items-center rounded-md border-2 ">
          <div class="w-full mb-5">
            <button 
            type="button"
            data-te-toggle="modal"
            data-te-target="#exampleModalScrollable"
            data-te-ripple-init
            data-te-ripple-color="light"
            (click)="selectedProduct = productFeature"
            class="flex flex-col bg-white w-32 md:w-[160px]   space-y-3 rounded-md p-4 shadow-md">
            <img loading="lazy" *ngIf="productFeature.imgLink" class="w-full h-16 md:h-28  object-contain" [src]="productFeature.imgLink" alt="">
            <div class="break-words w-full">
              <h4 class="">{{productFeature.productTitle}}</h4>
            </div>
              <p class="font-bold">$ {{productFeature.productPrice}} </p> 
          </button>
        
          </div>
   
        </div>
      </div>
      <p *ngIf="product.allProducts.length > 3" class="font-bold text-customTeal text-base">+ more</p>
      
    </div>
    </ng-container>
    </div>
      </div>

   </div>

