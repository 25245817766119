import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Route } from '@angular/router';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { UserService } from 'src/app/services/firebase/user.service';
import { environment } from 'src/environments/environment';

function passwordConfirming(c: AbstractControl): any {
  if (!c.parent || !c) { return; }
  const pwd = c.parent.get('newPassword');
  const cpwd = c.parent.get('confirmPassword');

  if (!pwd || !cpwd) { return; }
  if (pwd.value !== cpwd.value) {
    return { invalid: true };
  }
}


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  resetPasswordForm : UntypedFormGroup;
  id: any;
  model: any;
  themeColor: any;
  appName: any;
  appDescription = '';
  appLogo: any;
  defaultThemeColor = environment.appTheme.themeColor;

  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  directSignup: boolean = false;

  constructor(private fb: UntypedFormBuilder,
              private route: ActivatedRoute,
              public afAuth: AngularFireAuth,
              public userService: UserService,
              private authService: AuthService,
              private settingsService: AppSettingsService,) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });

    this.settingsService.getAppSettingsList().valueChanges().subscribe(
      appSettings => {
        if (appSettings) {
          if (appSettings.length > 0) {
            this.model = appSettings[0];
            this.themeColor = this.model.themeColor ? this.model.themeColor : this.defaultThemeColor;
            this.appName = this.model.appName;
            this.appDescription = this.model.description;
            this.appLogo = this.model.logo;
          }
        }
      }
      );

    this.resetPasswordForm = this.fb.group({
      newPassword: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required, passwordConfirming]],
    });
  }

  resetPassword(model){

    // this.userService.getUserById(this.id).valueChanges().subscribe((user: any) => {
    //   user.updatePassword(model.newPassword).then(function () {
    //     this.openUpdateModal();
    //     this.toast({ html: 'You have successfully updated your password ', classes: 'green', type: 'success' });
    //   }).catch(error =>  {
    //     this.toast({ html: 'Something went wrong when updating password.', classes: 'red', type: 'error' });
    //   }).catch(error =>  {
    //   this.toast({ html: 'The Current password does not match our records.', classes: 'red', type: 'error' });
    //   });
    // })
    
    // this.afAuth.authState.subscribe(user => {
    //   user.updatePassword(model.newPassword).then(function () {
    //     this.openUpdateModal();
    //     this.toast({ html: 'You have successfully updated your password ', classes: 'green', type: 'success' });
    //   }).catch(error =>  {
    //     this.toast({ html: 'Something went wrong when updating password.', classes: 'red', type: 'error' });
    //   })
    // .catch(error =>  {
    //   this.toast({ html: 'The Current password does not match our records.', classes: 'red', type: 'error' });
    // });

    // });

    // const user = firebase.auth().currentUser;
    // const credentials = firebase.auth.EmailAuthProvider.credential(user.email, model.oldPassword);
    // user.reauthenticateWithCredential(credentials)
    //   .then(() =>{

  }

  cancel(){

  }

  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
    }, 3000);
  }
  get newPassword() { return this.resetPasswordForm.get('newPassword'); }
  get confirmPassword() { return this.resetPasswordForm.get('confirmPassword'); }

}
