import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-edit-confirmation',
  templateUrl: './edit-confirmation.component.html',
  styleUrls: ['./edit-confirmation.component.css']
})
export class EditConfirmationComponent implements OnInit {
  @Input() editedFromComponent;
  @Output() cancelClicked = new EventEmitter();
  @Output() saveBtnClicked = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }
  cancelEditingClicked(){
    this.cancelClicked.emit();
  }
  saveChangesClicked(){
    this.saveBtnClicked.emit();
  }

}
