import { Component, OnInit, Input } from '@angular/core';
import { HelperService } from 'src/app/services/helper/helper';

@Component({
  selector: 'app-event-list-minimal',
  templateUrl: './event-list-minimal.component.html',
  styleUrls: ['./event-list-minimal.component.css']
})
export class EventListMinimalComponent implements OnInit {

  @Input() events;
  @Input() itemNumber;
  @Input() preview
  @Input() view
  
  constructor(
    private helperServices: HelperService,
  ) { }

  ngOnInit(): void {
  }

  truncateHTML(text: any, limit): string {
    return this.helperServices.truncateHTML(text, limit);
   }

   getInnerText(text){
    return this.helperServices.getInnerText(text);
  }

}
