import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';

import { catchError, switchMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompositeIndexService {
  constructor(private db: AngularFirestore, private httpClient: HttpClient) { }

  // url = environment.firstNation.apiUrl + 'api/FCM/';
  url = environment.firstNation.apiUrl + 'api/FCM/';

  getAccessToken(): Observable<any> {
    return this.httpClient.get<any>(this.url + 'GetAccessToken/');
  }


  createCompositeIndex(compositeIndex: any, token: string, appMainName: any): Observable<any> {
    let collectionName = 'Users';

    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json; charset=utf-8'
    });

    return this.httpClient.post<any>(`https://firestore.googleapis.com/v1beta2/projects/${appMainName}/databases/(default)/collectionGroups/${collectionName}/indexes`, JSON.stringify(compositeIndex), { headers });
  }

  getCompositeIndex(indexId: any): Observable<any> {
    let projectId = environment?.firebase?.projectId;
    let collectionName = 'Users';


    return this.getAccessToken().pipe(
      switchMap(token => {
        const headers = new HttpHeaders({
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json; charset=utf-8'
        });

        return this.httpClient.get<any>(`https://firestore.googleapis.com/v1beta2/projects/${projectId}/databases/(default)/collectionGroups/${collectionName}/indexes/${indexId}`, { headers });
      })
    );
  }

  async createCompositeIndexes(appMainName: any) {
    let compositeIndexes = [{
      queryScope: "COLLECTION",
      fields: [
        { fieldPath: "role", order: "ASCENDING" },
        { fieldPath: "GivenName", order: "ASCENDING" },
        { fieldPath: "__name__", order: "ASCENDING" }
      ]
    },
    {
      queryScope: "COLLECTION",
      fields: [
        { fieldPath: "role", order: "ASCENDING" },
        { fieldPath: "uid", order: "ASCENDING" },
        { fieldPath: "__name__", order: "ASCENDING" }
      ]
    },
    {
      queryScope: "COLLECTION",
      fields: [
        { fieldPath: "role", order: "ASCENDING" },
        { fieldPath: "uid", order: "DESCENDING" },
        { fieldPath: "__name__", order: "DESCENDING" }
      ]
    },
    {
      queryScope: "COLLECTION",
      fields: [
        { fieldPath: "contact", order: "ASCENDING" },
        { fieldPath: "role", order: "ASCENDING" },
        { fieldPath: "uid", order: "ASCENDING" },
        { fieldPath: "__name__", order: "ASCENDING" }
      ]
    },
    {
      queryScope: "COLLECTION",
      fields: [
        { fieldPath: "status", order: "ASCENDING" },
        { fieldPath: "role", order: "ASCENDING" },
        { fieldPath: "uid", order: "ASCENDING" },
        { fieldPath: "__name__", order: "ASCENDING" }
      ]
    }
    ]
    // try {

    //   // Get the access token once
    //   const token = await this.getAccessToken().pipe(
    //     take(1), // Ensures that the observable completes after emitting one value
    //     catchError(error => {
    //       throw new Error('Failed to get access token: ' + error.message);
    //     })
    //   ).toPromise(); // Convert observable to promise to use await

    //   const results = await Promise.all(
    //     compositeIndexes.map(compositeIndex => {
    //       return new Promise((resolve, reject) => {
    //         this.createCompositeIndex(compositeIndex, token).subscribe(
    //           result => {
    //             resolve(result);
    //           },
    //           error => {
    //             reject(error);
    //           }
    //         );
    //       });
    //     })
    //   );

    //   console.log('All composite indexes created successfully:', results);
    // } catch (error) {
    //   console.error('Error creating composite indexes:', error);
    // }

    try {
      // Get the access token once
      const token = await this.getAccessToken().toPromise();

      for (const compositeIndex of compositeIndexes) {
        await this.createCompositeIndex(compositeIndex, token, appMainName).toPromise();
      }

      console.log('All composite indexes created successfully');
    } catch (error) {
      console.error('Error creating composite indexes:', error);
    }

  };

}
