<div class="flex m-2" *ngIf="notification.type == 'postReaction'">
  <div class="avatar online float-left">
    <div class="w-12 rounded-full">
      <img
              loading="lazy"

              src="https://api.goingmobile.app/media/personal-app-assets/img/webp/profile.webp"
                              
              srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/profile.webp"
                              
              fallback="https://api.goingmobile.app/media/personal-app-assets/img/jpg/profile.jpg"
                              
              alt="ananymous profile picture."> 

    </div>
  </div>
  <div class="pt-3 ml-3 grow">
      <span class="font-bold">{{notification.createdByFullName}}</span> liked your post.
      <span class="text-xs text-gray-600">{{notification.timeStamp.toDate() | date: 'shortTime'}}</span>
  </div>

  <div class="pt-3 float-right cursor-pointer" (click)="deleteNotification(notification)">
    <i class="material-icons float-right" >close</i>
  </div>
</div>


<div class="flex m-2" *ngIf="notification.type == 'postComment'">
  <div class="avatar online float-left">
    <div class="w-12 rounded-full">
      <img
              loading="lazy"

              data-src="https://api.goingmobile.app/media/personal-app-assets/img/webp/profile.webp"
                              
              srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/profile.webp"
                              
              fallback="https://api.goingmobile.app/media/personal-app-assets/img/jpg/profile.jpg"
                              
              alt="ananymous profile picture."> 

    </div>
  </div>
  <div class="pt-3 ml-3 grow">
      <span class="font-bold">{{notification.createdByFullName}}</span> made a comment on your blog.
      <span class="text-xs text-gray-600">{{notification.timeStamp.toDate() | date: 'shortTime'}}</span>
  </div>

  <div class="pt-3 float-right cursor-pointer">
    <i class="material-icons float-right">close</i>
  </div>
</div>


<div class="flex m-2" *ngIf="notification.type == 'appointment'">
  <div class="avatar online float-left">
    <div class="w-12 rounded-full">
      <img
              loading="lazy"
              
              data-src="https://api.goingmobile.app/media/personal-app-assets/img/webp/profile.webp"
                              
              srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/profile.webp"
                              
              fallback="https://api.goingmobile.app/media/personal-app-assets/img/jpg/profile.jpg"
                              
              alt="ananymous profile picture."> 

    </div>
  </div>
  <div class="pt-3 ml-3 grow">
      <span class="font-bold">{{notification.createdByFullName}}</span> has made an appointment.
      <span class="text-xs text-gray-600">{{notification.timeStamp.toDate() | date: 'shortTime'}}</span>
  </div>

  <div class="pt-3 float-right cursor-pointer">
    <i class="material-icons float-right">close</i>
  </div>
</div>
