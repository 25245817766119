<div  class="relative overflow-hidden w-full md:w-[90%] mx-auto transition-transform   duration-[600ms]  ease-in-out motion-reduce:transition-none" [ngClass]="{'md:h-[43rem]  h-[15rem]': !preview, 'h-[38rem]':  preview && view == 'web' && !landingPageSettings, 'h-[24rem] ': preview &&  view == 'web' && landingPageSettings, ' h-[10rem]': preview && view == 'mobile' }" >

    <img #imageElement class="absolute top-9 md:top-0 md:object-cover h-auto w-full left-0 w-full " 
    [src]="slides[currentSlideIndex]" [ngClass]="{'md:h-[43rem]': !preview}" 
    (click)="imageClicked()" 
    (load)="imageLoaded(currentSlideIndex)" 
    (error)="imageError()"    
    >
  <button *ngIf="slides.length > 1" class="absolute top-[50%] pointer left-0 text-white hover:text-amber-500 cursor-pointer -translate-y-1/2 bg-black/30 hover:bg-black/50" (click)="prevSlide()">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="md:w-6 md:h-6 w-3 h-3">
      <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
    </svg>      
  </button>

  <button *ngIf="slides.length > 1" class="absolute top-[50%] pointer right-0 text-white hover:text-amber-500 cursor-pointer -translate-y-1/2 bg-black/30 hover:bg-black/50" (click)="nextSlide()">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="md:w-6 md:h-6 w-3 h-3">
      <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
    </svg>
  </button>

    <!-- Dots for tracking carousel slides -->
    <div class="absolute bottom-2 left-1/2 transform -translate-x-1/2 flex space-x-2">
      <div *ngFor="let slide of slides; let i = index" class="w-2 h-2 rounded-full " [ngClass]="{'bg-gray-200': i != currentSlideIndex , 'bg-customTeal': i == currentSlideIndex}" (click)="currentSlideIndex = i;"></div>
  </div>
</div>
<!-- <div class="flex justify-center items-center space-x-5 mt-5" *ngIf="slides.length > 1">
  <div *ngFor="let item of slides; let i=index" class="dot w-3 h-3 rounded-full cursor-pointer" [ngClass]="{'bg-gray-200': i != currentSlideIndex , 'bg-customTeal': i == currentSlideIndex}" (click)="currentSlideIndex = i;" >
  </div>

</div> -->




