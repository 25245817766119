<div class="p-4 flex justify-evenly">
    <div class="w-1/3 text-center pt-1 cursor-pointer">
      <p class="text-customTeal font-extrabold text-2xl">{{totalSubLength}}</p>
      <p class="text-gray-500  text-sm">Subscribers</p>
    </div>

    <div class="w-1/3 text-center pt-1 cursor-pointer">
      <p class="text-customTeal font-extrabold text-2xl">{{totalEmpLength}}</p>
      <p class="text-gray-500 text-sm">Employees</p>
    </div>

    <div class="w-1/3 text-center pt-1 cursor-pointer" *ngIf="verifyUsers">
      <p class="text-customTeal font-extrabold text-2xl">{{totalReqLength}}</p>
      <p class="text-gray-500 text-sm">Registration Requests</p>
    </div>
  </div>
  <hr>

  <div class="tabs md:p-4 p-2">
    {{currentUser.role}}
    <a class="tab tab-bordered  font-bold text-xs"  [ngClass]="{
    'tab-active': tabStatus == 'registration' }" (click)="tabStatusSet('registration')"> App request</a>
    <a class="tab tab-bordered font-bold text-xs" [ngClass]="{
    'tab-active': tabStatus == 'verification' }" (click)="tabStatusSet('verification')"> Verification request</a>
  </div>


  <div *ngIf="tabStatus == 'registration' ">
    <ng-container *ngIf="pendingRequests">
      <ng-container *ngIf="pendingRequests.length > 0">
        <div class="" *ngFor="let request of pendingRequests  | slice:0:4;">
          <!-- list items -->
          <div class="bg-gray-200 px-4 pb-2 mb-2 flex w-full flex-col md:flex-row md:items-center space-y-2">
            <div class="flex md:w-2/3">
              
              <div class="break-words text-sm ">
                {{request.email}}
              </div>
            </div>
            <!-- button -->
            <div class=" flex space-x-2 md:justify-end md:w-1/3">
              <div class=""><button type = "button" (click)="decline(request)"
                  class="font-bold  hover:bg-gray-300 md:px-2 border hover:text-customTeal text-customTeal text-base  bg-white shadow-sm px-4 py-1.5 ">Decline</button>
              </div>
              <div class=""><button type = "button" (click)="accept(request)"
                  class="font-bold  hover:bg-gray-300 md:px-2 border hover:text-customTeal text-white bg-customTeal text-base shadow-sm px-4 py-1.5 ">Accept</button>
              </div>
            </div>
          </div>

        </div>
      </ng-container>

    <ng-container *ngIf="pendingRequests.length == 0">
      <div class="flex items-center justify-center py-4">
        <img loading="lazy" data-src="../../../../assets/icons/Illustrations/user registration.svg" class="block w-24 items-center justify-center" alt="">
    </div>
    <div class="text-customTeal text-base text-center font-bold" *ngIf="isAll">
      You don't have any user requesting to join your app.
    </div>
    <div class="text-gray-400 text-sm text-center pb-4">New requests will show here!</div>

    </ng-container>
    </ng-container>

    <ng-container *ngIf="pendingRequests">
      <a href="" class="flex justify-end font-bold text-sm mx-4 my-2" *ngIf="pendingRequests.length > 4"
        [routerLink]="['/admin/registration-request']">View all</a>
    </ng-container>
  </div>

  <div *ngIf="tabStatus == 'verification'">
    <ng-container *ngIf="verification">
      <ng-container *ngIf="verification.length > 0">
        <div class="" *ngFor="let verify of verification  | slice:0:4;">
          <!-- list items -->
          <div class="p-4 flex items-center">
            <!-- <div class="w-1/6 flex items-center space-x-2">
               <div class="avatar placeholder">
                <div class="bg-neutral-focus text-neutral-content rounded-full w-9">
                  <span class="text-xl">{{verify.firstName[0]}}</span>
                </div>
                 <div *ngIf="verify.profileImgLink">
                <img  [src]="verify.profileImgLink" alt="User Profile Image" />
              </div> 
              </div> 
              <div>
                <p class="text-sm font-bold ">{{verify.firstName}} {{verify.lastName}}</p>
                <p class="text-sm ">{{verify.date.toDate() | date }}</p>
              </div>
            </div> -->
            <div class="break-words text-sm w-2/6">
              {{verify.email}}
            </div>
            <!-- button -->
            <div class="w-5/12 flex space-x-4">
              <div class=""><button
                  class="font-bold  hover:bg-gray-300 md:px-8 border hover:text-customTeal text-customTeal text-base  bg-white shadow-sm px-4 py-1.5 ">Decline</button>
              </div>
              <div class=""><button
                  class="font-bold  hover:bg-gray-300 md:px-8 border hover:text-customTeal text-white bg-customTeal text-base shadow-sm px-4 py-1.5 ">Accept</button>
              </div>
            </div>
          </div>

        </div>
      </ng-container>
      <a href="" class="flex justify-end font-bold text-sm mx-4 my-2" *ngIf="verification.length > 4"
        [routerLink]="['/admin/verify-account-request']">View all</a>

        <ng-container *ngIf="verification.length == 0">
          <div class="flex items-center justify-center py-4">
            <img loading="lazy" data-src="../../../../assets/icons/Illustrations/verification.svg" class="block w-24 items-center justify-center" alt="">
        </div>
        <div class="text-customTeal text-base text-center font-bold" *ngIf="isAll">
          You don't have any users requesting to be verified.
        </div>
        <div class="text-gray-400 text-sm text-center pb-4">New requests will show here!</div>

        </ng-container>
    </ng-container>


  </div>