import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FirstNationsService } from "src/app/services/api/firstnations.services";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { GlobalConstants } from "src/app/models/GlobalConstants";

@Component({
  selector: "app-nation-leaders-list",
  templateUrl: "./nation-leaders-list.component.html",
  styleUrls: ["./nation-leaders-list.component.css"],
})
export class FirstNationLeaderLists implements OnInit {
  letters = "abcdefghijklmnopqrstuvwxyz".split("");
  selectedLetter = "";
  filterByLetter = false;

  allNationLeaders = [];
  filteredLeaders = [];

  searchLeaderForm: UntypedFormGroup;
  loading = false;
  provinces = GlobalConstants.provinces;
  page: number = 1;

  constructor(
    private firstNationsService: FirstNationsService,
    private fb: UntypedFormBuilder,
  ) {
    this.loading = true;
    this.firstNationsService.getFirstNationLeaders().subscribe((data) => {
      this.allNationLeaders = data;
      this.filteredLeaders = this.allNationLeaders;
      this.loading = false;
    });
  }

  ngOnInit() {
    this.searchLeaderForm = this.fb.group({
      name: [],
      province: [],
    });
  }

  getSearchValues(values = this.searchLeaderForm.value) {
    let name = values.name == "" || values.name == null ? null : values.name;
    let province =
      values.province == "" || values.province == null ? null : values.province;
    return { name, province };
  }

  searchNationLeaders(values) {
    let { name, province } = this.getSearchValues(values);
    this.applyFilter(name, province);
  }

  applyLetterFilter(letter) {
    if (this.selectedLetter == letter) {
      this.selectedLetter = "";
      this.filterByLetter = false;
    } else {
      this.selectedLetter = letter;
      this.filterByLetter = true;
    }
    let { name, province } = this.getSearchValues();

    this.applyFilter(name, province);
    this.page = 1;
  }

  applyFilter(name = null, province = null) {
    this.filteredLeaders = this.allNationLeaders;
    if (this.filterByLetter) {
      this.onFilterByLetter();
    }
    if (name != null) {
      this.filterByName(name);
    }
    if (province != null) {
      this.filterByProvince(province);
    }
  }

  onFilterByLetter() {
    this.filteredLeaders = this.filteredLeaders.filter(
      (leader) =>
        leader.Nation_Name !== null &&
        leader.Nation_Name[0].toLowerCase() == this.selectedLetter
    );
  }

  filterByName(name) {
    this.filteredLeaders = this.filteredLeaders.filter(
      (leader) =>
        leader.Nation_Name !== null &&
        leader.Nation_Name.toLowerCase().includes(name.toLowerCase())
    );
  }

  filterByNumber(number) {
    this.filteredLeaders = this.filteredLeaders.filter(
      (nation) => nation.nationNumber == number
    );
  }

  filterByProvince(province) {
    this.filteredLeaders = this.filteredLeaders.filter(
      (leader) =>
        leader.Province !== null &&
        leader.Province.toLowerCase() == province.toLowerCase()
    );
  }

  clearSearch() {
    this.filteredLeaders = this.allNationLeaders;
    this.selectedLetter = "";
    this.filterByLetter = false;
    this.searchLeaderForm.reset();
  }
}
