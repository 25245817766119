<div class="flex flex-col h-[45rem] bg-white py-4">
    <div class="px-4 pb-4">
        <!-- <div *ngIf="isAdmin" class="px-4"> -->
        <div  *ngIf="chatType === 'private'" class="w-full">
            <!-- <label class="input input-bordered flex items-center gap-2">
                <input  type="text"  class="grow" placeholder="Search" 
               autocomplete="off"
                [(ngModel)]="searchQuery" (ngModelChange)="searchChat()"/>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="w-4 h-4 opacity-70"><path fill-rule="evenodd" d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z" clip-rule="evenodd" /></svg>
              </label> -->

              <div class="relative text-gray-600 w-full">
                <span class="absolute inset-y-0 left-0 flex items-center pl-2">
                  <button class="p-1 focus:outline-none focus:shadow-outline">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="w-4 h-4 opacity-70"><path fill-rule="evenodd" d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z" clip-rule="evenodd" /></svg>
                
                  </button>
                </span>
                <input type="text"  class="grow" placeholder="Search" 
                autocomplete="off"
                class="input  rounded-md pl-10 focus:outline-1 focus:outline-brandColor w-full border-gray-300"
                 [(ngModel)]="searchQuery" (ngModelChange)="searchChat()"/>
             
              </div>
         
        </div>
        <div class="w-full" *ngIf="chatType === 'group'">

            <div class="relative text-gray-600 w-full">
                <span class="absolute inset-y-0 left-0 flex items-center pl-2">
                  <button class="p-1 focus:outline-none focus:shadow-outline">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="w-4 h-4 opacity-70"><path fill-rule="evenodd" d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z" clip-rule="evenodd" /></svg>
                
                  </button>
                </span>
                <input type="text"  class="grow" placeholder="Search" 
                autocomplete="off"
                class="input  rounded-md pl-10 focus:outline-1 focus:outline-brandColor w-full border-gray-300"
                 [(ngModel)]="searchQuery" (ngModelChange)="searchGroup()"/>
             
              </div>
            <!-- <input placeholder="Search" class="input input-bordered input-sm w-full" type="search" autocomplete="off"
                [(ngModel)]="searchQuery"  /> -->
        </div>
        <div class="w-full" *ngIf="chatType === 'employee'">
            <div class="relative text-gray-600 w-full"  *ngIf="isAdmin">
                <span class="absolute inset-y-0 left-0 flex items-center pl-2">
                  <button class="p-1 focus:outline-none focus:shadow-outline">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="w-4 h-4 opacity-70"><path fill-rule="evenodd" d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z" clip-rule="evenodd" /></svg>
                
                  </button>
                </span>
                <input type="text"  class="grow" placeholder="Search" 
                autocomplete="off"
                class="input  rounded-md pl-10 focus:outline-1 focus:outline-brandColor w-full border-gray-300"
                 [(ngModel)]="searchQuery" (ngModelChange)="searchEmployeeUser()"/>
             
              </div>
              <div class="relative text-gray-600 w-full" *ngIf="!isAdmin">
                <span class="absolute inset-y-0 left-0 flex items-center pl-2">
                  <button class="p-1 focus:outline-none focus:shadow-outline">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="w-4 h-4 opacity-70"><path fill-rule="evenodd" d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z" clip-rule="evenodd" /></svg>
                
                  </button>
                </span>
                <input type="text"  class="grow" placeholder="Search"  
                autocomplete="off"
                class="input  rounded-md pl-10 focus:outline-1 focus:outline-brandColor w-full border-gray-300"
                 [(ngModel)]="searchQuery"  (ngModelChange)="searchEmployeeChat()" />
             
              </div>
            <!-- <input placeholder="Search " class="input input-bordered input-sm w-full" type="search" autocomplete="off"
                [(ngModel)]="searchQuery" (ngModelChange)="searchEmployeeUser()" *ngIf="isAdmin" />
                <input placeholder="Search " class="input input-bordered input-sm w-full" type="search" autocomplete="off"
                [(ngModel)]="searchQuery"*ngIf="!isAdmin"  (ngModelChange)="searchEmployeeChat()"  /> -->
        </div>
    </div>

    <div class="flex flex-col space-y-4 h-full min-h-max  overflow-y-scroll space-y-6 rounded-md">
        <ng-container *ngIf="isSearch">
            <div *ngFor="let x of filteredChat">
                <ng-container *ngIf="chatType === 'private'">
                    <div class="px-4 w-full">
                        <div class="hover:cursor-pointer py-2 border-b">
                            <app-chat-info [data]="getUserData(x)" class="hover:cursor-pointer" (click)="selectUser(x)">

                            </app-chat-info>

                        </div>

                    </div>
                </ng-container>
                <ng-container *ngIf="chatType === 'group'">
                    <div class="px-4 w-full">
                        <div class="hover:cursor-pointer py-2 border-b">
                            <app-chat-info [data]="getGroupData(x)" class="hover:cursor-pointer"
                                (click)="selectGroupChat(x,true)"></app-chat-info>

                        </div>

                    </div>
                </ng-container>
                <ng-container *ngIf="chatType === 'employee'">
                    <div class="px-4 w-full">
                        <div class="hover:cursor-pointer py-2 border-b">
                            <app-chat-info *ngIf="isAdmin" [data]="getUserData(x)" class="hover:cursor-pointer"
                                (click)="selectEmployeeUser(x)"></app-chat-info>
                            <app-chat-info *ngIf="!isAdmin" [data]="getGroupData(x)" class="hover:cursor-pointer"
                                (click)="selectEmployeeChat(x,true)"></app-chat-info>
                        </div>

                    </div>
                </ng-container>

            </div>
        </ng-container>

        <ng-container *ngIf="!isSearch">
            <ng-container *ngIf="chatType === 'group'">
                <div *ngIf="groupChats.length > 0" class="flex flex-col w-full space-y-2 pt-4 ">
                    <div *ngFor="let chat of groupChats;let i=index">
                        <!-- each private chats -->
                        <div [ngClass]="{'border-l-8 border-customTeal': selectedGroupChat && selectedGroupChat.id == chat.id}"
                            class="px-6">
                            <div class="hover:cursor-pointer py-3 border-b">
                                <app-chat-info [data]="getGroupData(chat)" (click)="selectGroupChat(chat)"
                                    class="hover:cursor-pointer"></app-chat-info>
                            </div>

                        </div>

                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="chatType === 'private'">
                <div *ngIf="privateChats.length > 0" class="flex flex-col w-full space-y-2 pt-4 ">
                    <div *ngFor="let chat of privateChats;let i=index">
                        <!-- each private chats -->
                        <div [ngClass]="{'border-l-8 border-customTeal': selectedPrivateChat && selectedPrivateChat.id == chat.id}"
                            class="px-6">
                            <div class="hover:cursor-pointer py-3 border-b">
                                <app-chat-info [data]="getPrivateChatData(chat)" (click)="selectPrivateChat(chat)"
                                    class="hover:cursor-pointer"></app-chat-info>
                            </div>

                        </div>

                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="chatType === 'employee'">
                <div *ngIf="chats.length > 0" class="flex flex-col w-full space-y-2 pt-2 ">
                    <div *ngFor="let chat of chats;let i=index">
                        <!-- each private chats -->
                        <div [ngClass]="{'border-l-8 border-customTeal': selectedEmployeeChat && selectedEmployeeChat.id == chat.id}"
                            class="px-4">
                            <div class="hover:cursor-pointer py-3 border-b">
                                <app-chat-info [data]="getGroupData(chat)" (click)="selectEmployeeChat(chat)"
                                    class="hover:cursor-pointer"></app-chat-info>
                            </div>

                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="!loadingChats">
            <ng-container *ngIf="chatType === 'group'">
                <ng-container *ngIf="filteredChat.length == 0 && !isSearch">
                    <div class="items-center ">
                       
                        <div class="text-center lg:mb-20 lg:mt-28">
                            
                                <div *ngIf="isAdmin" class="flex flex-col items-center justify-center gap-y-4">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"  class="w-8 h-8 stroke-slate-400 ">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
                                    </svg>
                                    
                                    <span class="text-center font-bold" >
                                        No Group yet 
                                        </span>
                                        <span class="text-center text-sm text-gray-500" >
                                            Create a new group chat and start messaging!
                                        </span>
                                </div>
                                
                            
                                <div *ngIf="!isAdmin" class="flex flex-col items-center justify-center gap-y-4">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"  class="w-8 h-8 stroke-slate-400 ">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
                                    </svg>
                                    
                                    <span class="text-center font-bold" >
                                        No Group yet 
                                        </span>
                                        <span class="text-center text-sm text-gray-500" >
                                            Sorry you are not part of any groups yet!
                                        </span>
                                </div>
                            <!-- </ng-container> -->

                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="chatType === 'employee'">
                <ng-container *ngIf="isAdmin && chats.length == 0 && !isSearch">
                    <div class="items-center ">
                        <div class="text-center lg:mb-20 lg:mt-28">
                                <div *ngIf="isAdmin" class="flex flex-col items-center justify-center gap-y-4">
                                
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"  class="w-8 h-8 stroke-slate-400 ">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z" />
                                      </svg>
                                      
                                    <span class="text-center font-bold" >
                                        No Group yet 
                                        </span>
                                        <span class="text-center text-sm text-gray-500" >
                                            Create a new group chat and start
                                messaging!
                                        </span>
                                </div>

                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="!isAdmin && filteredChat.length == 0 && !isSearch">
                    <div class="items-center ">
                        <div class="text-center lg:mb-20 lg:mt-28">
                                <div *ngIf="!isAdmin" class="flex flex-col items-center justify-center gap-y-4">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"  class="w-8 h-8 stroke-slate-400 ">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
                                    </svg>
                                    
                                    <span class="text-center font-bold" >
                                        No Group yet 
                                        </span>
                                        <span class="text-center text-sm text-gray-500" >
                                            Sorry you are not part of any groups yet!
                                        </span>
                                </div>

                        </div>
                    </div>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="chatType === 'private'">
                <ng-container *ngIf="privateChats.length == 0 && !isSearch">
                    <div class="items-center ">
                        <!-- <div class="text-center >
                            <div class="">
                                <span
                                    class="p-10 bg-gray-100 rounded-full object-contain material-icons text-7xl text-customTeal">
                                    message
                                </span>
                            </div>
                        </div> -->
                        <div class="text-center lg:mb-20 lg:mt-28 px-10">
                            <!-- <h5 class="text-center text-2xl">Chats</h5> -->

                            <div *ngIf="isAdmin" class="flex flex-col items-center justify-center gap-y-4">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 stroke-slate-400 ">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                                  </svg>
                                  
                                <span class="text-center font-bold" >
                                    No Messages yet 
                                    </span>
                                    <span class="text-center text-sm text-gray-500" >
                                        Start a new conversation's with your
                                        users by searching from the list
                                    </span>
                            </div>

                            
                            <span class="text-center text-xl" *ngIf="!isAdmin">Start a new conversation's with the owner
                                from the list</span>

                        </div>
                    </div>
                </ng-container>
            </ng-container>

        </ng-container>

    </div>
</div>