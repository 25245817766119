import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService } from '../firebase/auth.service';
import { EmployeeServicesService } from '../firebase/employee-services.service';
import { ProductsService } from '../firebase/products.service';

@Injectable({
  providedIn: 'root'
})

export class CartService {

  cart: any;

  constructor(
    private db: AngularFirestore,
    private authService: AuthService,
    private prodService: ProductsService,
    private srvService: EmployeeServicesService,) {
      if (this.getCartByUserID()){
        this.getCartByUserID()
        .valueChanges().subscribe(c => {
          if (c.length > 0){
            this.cart = c[0];
          }
        });
      }

     }

  getCartByUserID() {
    if(!this.authService.getGlobalUser()) return;
    return this.db.collection('ShoppingCart', ref => ref.where('userID', '==', this.authService.getGlobalUser().uid));
  };

  addProductToCart(productID: any, qty : number){
    if(!this.authService.getGlobalUser()) return;
    var product: any
   return this.prodService.getProductById(productID).valueChanges().subscribe(p =>{
      if(p.length > 0){
        product = p[0];

        const cartItem = {
          productID : productID,
          name : product.productName,
          description : product.productDescription,
          price : product.price,
          img: product.imgLink,
          quantity : qty
        }



          if(this.cart) {
            this.cart.items.push(cartItem);
            this.updateCart(this.cart);
          } else {
           const newCart = {
            userID : this.authService.getGlobalUser().uid,
            items : [cartItem],
            lastUpdate : new Date()

           }
           this.updateCart(newCart);
          }



      }
    });
  }

  addServiceToCart(serviceID: any, qty : number){
    if(!this.authService.getGlobalUser()) return;
    var service: any
   return this.srvService.getServiceById(serviceID).valueChanges().subscribe(p =>{
      if(p.length > 0){
        service = p[0];

        const cartItem = {
          serviceID : serviceID,
          name : service.serviceName,
          description : service.serviceDescription,
          price : service.price,
          img: (service.imgLink) ? service.imgLink : "",
          quantity : qty
        }



          if(this.cart) {
            this.cart.items.push(cartItem);
            this.updateCart(this.cart);
          } else {
           const newCart = {
            userID : this.authService.getGlobalUser().uid,
            items : [cartItem],
            lastUpdate : new Date()

           }
           this.updateCart(newCart);
          }



      }
    });
  }

  updateCart(model: any){
    model.lastUpdate = new Date();
    return this.db.collection('ShoppingCart').doc(model.userID).set(model);
  }

  emptyCartAfterCheckOut(cartID: any){
    return this.db.collection('ShoppingCart').doc(cartID).update({items : []})
  }

  getItemsInCart(userID){
    return this.db.collection('ShoppingCart', ref => ref.where('userID', '==', userID));

  }

}
