import { ProductsService } from 'src/app/services/firebase/products.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ImageModel } from 'src/app/models/ImageModel';
import { MediaService } from 'src/app/services/api/media.service';
import { ProductsServiceMenuService } from 'src/app/services/api/data.service';
import { PermissionService } from 'src/app/services/firebase/permission.service';
import { HelperService } from 'src/app/services/helper/helper';
import { Store } from '@ngrx/store';
import { selectAppSettings } from 'src/app/state/app.selectors';
import { Subscription } from 'rxjs';

declare var navigator: any;

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit, OnDestroy {
  @Input() calledFrom;
  currentUser: any;
  products: any;
  settings: any;
  viewProduct = true;
  selectedModel: any;
  productCategoryForm: UntypedFormGroup;
  showMsg = false;
  currentUserId: any;
  selectedProductsUnderCategory: any;
  editModalOpen = false;
  deleteModalOpen = false;
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  filterOpen = false;

  productCategoryFilter = '';
  existingProducts = [];
  beforeFilter = [];

  // images
  imgErrorMsg: any;
  base64Image: any;
  binDoc: File;
  oldUrl: any;
  mobileBase64Image:any;

  isMobile=false
  productsName
  catagoryId

  //permission
  isAdmin : boolean;
  perm:any;
  permission: any;
  currency;

  permMember:any;
  permPES:any;
  permSocial:any;
  permAnalytics: any;
  permissionSpecial: any;
  openMoveModal = false;
  
  emptyProduct = {
    img: 'https://images.unsplash.com/photo-1699469786384-3717a73da415?q=80&w=2013&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D', 
    date: new Date().toJSON().slice(0, 10),
    shareWith: 'Public',
    price: '122',
    postTitle : 'Examples of Blogs From Every Industry, Purpose, & Readership',
    message: "When you hear the word “blog”, what do you think of? Maybe your mind goes to stories about travel, yoga, and exciting new restaurants to try. What if I told you that, although these thoughts may be valid, other terms and phrases should be coming to mind? These include conversions, a boost in revenue, calls to action, inbound marketing, and improving customer relationships. Blogs are powerful business tools. They improve conversion rate, foster relationships between your business and audience members and customers, boost revenue, promote brand awareness, increase your ranking on search engines, and positively impact your bottom line. In this guide, we’ll review the different types of blogs there are and examples of each. We've also added a 'Niche Industries' section, because there's a blog for everything out there, including your industry. These will give you a better understanding of the various ways your business can write and publish content to help you achieve the benefits we listed above … and more."
  }
appSettings$: Subscription;
  constructor(private productsService: ProductsService,
              private authService: AuthService,
              private router: Router,
              private route: ActivatedRoute,
              private location: Location,
              private formBuilder: UntypedFormBuilder,
              private mediaService: MediaService,
              private permissionService: PermissionService,
              private productsServiceMenuService:ProductsServiceMenuService,
              private helperService: HelperService,
              private store: Store) {
                this.appSettings$ = this.store.select(selectAppSettings).subscribe((settings) => { this.settings = settings;  });
              
                if (typeof window['cordova'] !== 'undefined') {
                  this.isMobile = true;
                }
              }
  ngOnDestroy(){
    this.appSettings$.unsubscribe();
  }
  ngOnInit(): void {
    this.currentUser = this.authService.getGlobalUser();
    if(this.currentUser && this.currentUser.role == 'Admin'){
      this.isAdmin = true;
    }
    this.route.paramMap.subscribe(params => {
      this.catagoryId = params.get('id');
    });
    if(this.currentUser){
      this.permissionService.getPermissionByEmployeeId(this.currentUser.uid).valueChanges().subscribe(perm => {
        if(perm && perm.length != 0){
          this.perm = perm[0];
          //this.permission = this.perm.permission[0];
          this.permMember = this.perm.permission[0]
          this.permPES = this.perm.permission[1]
          this.permAnalytics = this.perm.permission[2];
          this.permSocial = this.perm.permission[3];
          this.permissionSpecial = this.perm.permission[4];
        }
      })
    }

    if(this.catagoryId){
      this.productsService.getProductByCategoryId(this.catagoryId).valueChanges().subscribe((products) => {
        if (products.length > 0) {
            this.products = products;
            this.existingProducts = products;
            if (this.currentUser == null) {
                this.products = this.products.filter(p => p.shareWithPublic == true)
            }
        }
    });
    }else{
      this.productsService.getProductsList().valueChanges().subscribe((products) => {
        if (products.length > 0 ){
          this.products = products;
          this.existingProducts = products;
        }
      });
    }

    this.productCategoryForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      imgUrl: [''],
    });

    this.productsServiceMenuService.productsName.subscribe(p=>{
      this.productsName=p
    }
    )

    this.productsServiceMenuService.currencyName.subscribe(c => {
      this.currency = c;
    });
  }

  truncateHTML(text: string, limit: string): string {
    return this.helperService.truncateHTML(text, limit);
  }

  deleteProduct(product){
    this.productsService.deleteProduct(product).then(() => {
      // toast({html: 'Service Deleted successfully ', classes: 'green'});
    }).catch(err => {
      // toast({html: err, classes: 'green'});
    });
    this.backClicked();
  }

  backClicked(){
    this.location.back();
  }

  navigate(product: any){
    this.router.navigate(['/admin/edit-product', product.id]);
  }
  toggleProductCategoryBtn(){
    this.viewProduct = !this.viewProduct;
  }

  get name() { return this.productCategoryForm.get('name'); }

  addCategory(){
    this.selectedModel = undefined;
    this.productCategoryForm.patchValue({'name': ''});
    this.editModalOpen = true;
  }

  handleImageInput(files: FileList) {
    if (!this.validateImage(files[0].name)) {
      this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';

      return;
    }
    this.binDoc = files[0];
    this.imgErrorMsg = undefined;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.base64Image = reader.result as string;
    };
  }

  private validateImage(name: any) {
    return this.helperService.validateImage(name);
  }

  dataURItoBlob(dataURI) {
    return this.helperService.dataURItoBlob(dataURI);
  }


  removeFile() {
    this.binDoc = undefined;
    this.base64Image = undefined;
  }
  remove() {
    this.oldUrl = this.selectedModel.imgUrl
    this.selectedModel.imgUrl =''
    this.binDoc = undefined;
    this.base64Image = undefined;
  }


  openDeleteModal(){
    this.editModalOpen = false;
    this.deleteModalOpen = true;
  }
  closeDeleteModal(){
    this.deleteModalOpen = false;
  }

  closeEditModal(){
    this.editModalOpen = false;
  }
  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
    }, 2000);
  }
  toggleFilter(){
    this.filterOpen = !this.filterOpen;
  }

  clearFilters(){
    this.productCategoryFilter = 'all';
    this.products = this.existingProducts;
  }
  onCatecoryChange(){
    if (this.productCategoryFilter === 'all'){
      this.products = this.existingProducts;
    }
    if (this.productCategoryFilter !== 'all'){
      this.products = this.existingProducts.filter((product) => {
        return product.categoryId === this.productCategoryFilter;
        // return product.categoryId.toLowerCase().includes(this.productCategoryFilter.toLowerCase());
      });
    }
}
navigateCreate(){
  if(this.isAdmin){
    this.catagoryId ?  this.router.navigate(['/admin/create-product',this.catagoryId]) : this.router.navigate(['/admin/create-product']);

  }else if(this.currentUser && this.currentUser.role == 'Employee'){
    this.router.navigate(['/employee/create-product'])
  }
}

navigateDetails(product){
  if(this.calledFrom!=='onBoarding'){
    if(this.isAdmin){
      this.router.navigate(['admin/view-product',product.id])
    }else if(this.currentUser && this.currentUser.role == 'Employee'){
      this.router.navigate(['/employee/products', product.id])
    }
    else{
      //for the !current user
      this.router.navigate(['/view-product', product.id])
    }
  }
}
getSortedList(unsortedElements: Array<any>){
  let sortedList = unsortedElements.sort((a:any, b:any ) => {
    return a.position- b.position;
  });
  return sortedList
}
getInnerText(text){
  return this.helperService.getInnerText(text);
}
openConfirmationModal(){
  this.toast({html: 'Successfully  posted a blog!', classes: 'green', type: 'success'});
}
}
