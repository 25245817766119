<div class="flex space-x-2 items-center">
    <input type="checkbox" checked="" class="checkbox-xs" />
    <div
        class="flex flex-row bg-white w-full rounded-md space-x-4 md:max-w-max md:min-w-full p-3 md:p-6">
        <!-- product -->

        <div class="avatar">
            <div class="w-16 h-24 md:w-28 md:h-32 rounded">
                <img loading="lazy" [src]="item.img" />
            </div>
        </div>
        <!--  -->
        <div
            class="flex flex-col md:flex-row  md:flex-wrap  md:justify-between space-y-3 md:space-y-0  w-full ">
            <div class="flex flex-col md:w-1/3  ">
                <p class="text-sm md:text-lg font-bold">{{item.productName}}</p>
                <div class="flex space-x-1 hidden md:block items-end">
                    <i class="fa fa-trash fa-sm"></i>
                    <a class="text-sm" (click)="deleteProduct()">Remove</a>
                </div>

            </div>

            <div class="md:w-1/4">
                <form class="flex text-center items-center h-6 md:h-8 ">
                    <div class="border-2 border-gray-300  rounded-l-md w-6 md:w-10 md:p-1 text-sm md:px-2 bg-gray-200 font-bold hover:cursor-pointer"
                        id="decrease"  (click)="updateProduct(false)" value="Decrease Value">-</div>
                    <input type="number" id="number"
                        class="w-8 md:w-10 md:py-1 text-sm border-y-2 text-center" [value]="item.quantity" />
                    <div class="border-2 border-gray-300 md:p-1 md:px-2 w-6  md:w-10 text-sm text-black rounded-r-md bg-gray-200 font-bold hover:cursor-pointer"
                        id="increase" (click)="updateProduct(true)"  value="Increase Value">+</div>
                </form>
            </div>

            <div class="flex justify-between md:justify-evenly md:w-2/5">
                <div class="md:w-1/6">
                    <p class="text-sm md:text-base font-bold">{{item.price | currency}}</p>
                </div>

                <div class="flex md:w-1/6">
                    <p class="text-sm block md:hidden md:text-base">Total:</p>
                    <p class=" text-sm md:text-base text-customTeal font-bold">{{(item.quantity * item.price) | currency}}</p>
                </div>
            </div>

        </div>
    </div>


</div>
