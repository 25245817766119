<div class="modal modal-open" id="chatGptModal">
  <!-- <div class="modal-box w-full max-w-7xl min-h-max my-4 max-h-128"> h-[calc(100vh-1rem)] -->
  <div class="modal-box w-full md:max-w-7xl my-1 md:my-8 rounded-md max-h-screen m-6">
    <div class="flex justify-between">
      <div>
        <h3 class="font-bold text-lg">Generate {{ fromComponent }} Content</h3>
        <p class="text-sm text-gray-500 pt-1">{{ title }}</p>
        <p class="text-sm font-bold pt-1 block md:hidden" [ngClass]="counterCss()">{{counterMessage()}}</p>
      </div>
      <hr>
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-6 h-6 cursor-pointer"
          (click)="closeModal()"
          viewBox="0 0 384 512"
        >
          <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
          <path
            d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
          />
        </svg>
      </div>
    </div>

    <div class="py-4" #messagesForm>
      <form
        [formGroup]="chatForm"
        class=""
        (ngSubmit)="submitChat(chatForm.value)"
      >
        <div class="flex flex-col gap-y-4">
          <div class="flex  gap-4 space-y-4">
            <!-- filters -->
            <div class="md:flex md:w-1/4 md:flex-col gap-y-4 hidden">
              <div class="">
                <div class="font-semibold pt-1" [ngClass]="counterCss()">
                  {{counterMessage()}}
                </div>
        
                <div class="font-semibold  pt-1">
                  Total number of chatgpt asked
                  {{chatCount}}
                </div>
        
              </div>
              <hr>
              <div  class="">
                <!-- <h2 class="text-[#4F4F4F] text-base font-bold leading-5 py-2 md:text-end">Filters</h2> -->
                <div class="flex flex-col gap-y-4">
                  <div class="">
                    <label class="label">
                      <span
                        class="label-text text-gray-500  text-base font-semibold leading-5"
                        >Number of Words</span
                      >
                    </label>
                    <input
                      id="number"
                      type="number"
                      formControlName="wordCount"
                      placeholder="Number"
                      [max]="maxWordCount.value"
                      class="appearance-none block input w-full input-bordered rounded-sm border-gray-300 bg-gray-50 text-gray-700 leading-tight focus:outline-none focus:border-gray-500"
                    />
                  </div>
                  <div class="">
                    <label class="label">
                      <span
                        class="label-text text-[#4F4F4F] text-base font-bold leading-5"
                        >Key words</span
                      >
                    </label>
                    <!-- key words list -->
                    <div class="flex flex-wrap" *ngIf="keyWords.value.length > 0">
                      <div *ngFor="let keyWord of keyWords.value;let i=index">
                        <button type="button"
                            class="border-2 border-customTeal rounded-full py-1 px-4 mx-2 my-2"
                            >
                            <span>{{keyWord}}</span>
                            <span  (click)="removeKeyWord(i)">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline"
                                    fill="none" viewBox="0 0 24 24" stroke="currentColor"
                                    stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </span>

                        </button>
                      </div>
                    </div>
                    <div class="flex flex-row" *ngIf="keyWords.value.length < maxKeyWords">
                      <input
                        id="text"
                        type="text"
                        formControlName="keyWordQuery"
                        placeholder="Keyword"
                        class="appearance-none block input w-full input-bordered rounded-sm border-gray-300 bg-gray-50 text-gray-700 leading-tight focus:outline-none focus:border-gray-500"
                      />
                      <button type="button" class="border-2 bg-customTeal px-3" (click)="selectKeyWord()">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 stroke-white inline"
                            fill="none" viewBox="0 0 24 24" stroke="currentColor"
                            stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                        </svg>
                      </button>
                    </div>
                   
                  </div>

                  <div class="w-max" *ngIf="!inputType || inputType !== 'title'">
                    <label class="label">
                      <input class="flex justify-end checkbox checked:bg-customTeal"
                      type="checkbox" formControlName="includeHashTag" />
                      <span
                        class="label-text text-[#4F4F4F] text-base font-bold leading-5 pl-2"
                        >Include Hashtags</span
                      >
                    </label>
                  </div>
                
                  <div class="" *ngIf="includeHashTag.value">
                    <label class="label">
                      <span
                        class="label-text text-gray-500 text-base font-semibold leading-5"
                        >Number of Hastags</span
                      >
                    </label>
                    <input
                      id="number"
                      type="number"
                      formControlName="numberOfHashTags"
                      placeholder="Number"
                      class="appearance-none block input w-full input-bordered rounded-sm border-gray-300 bg-gray-50 text-gray-700 leading-tight focus:outline-none focus:border-gray-500"
                    />
                  </div>

                </div>
              </div>

            </div>

          
       <div class="md:w-3/4 w-full space-y-2">
             <!-- response -->
             <div #messagesContainer [scrollTop]="messagesContainer.scrollHeight" class="h-128 px-1 border border-solid border-gray-300  md:px-4 py-4 overflow-y-scroll bg-gray-50 rounded-md">
              <div
                *ngIf="chatResponse && chatResponse.length == 0"
                class="w-full h-full space-y-3 text-center flex flex-col justify-center items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-7 h-7 fill-slate-400"
                  viewBox="0 0 512 512"
                >
                  <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                  <path
                    d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM164.1 325.5C182 346.2 212.6 368 256 368s74-21.8 91.9-42.5c5.8-6.7 15.9-7.4 22.6-1.6s7.4 15.9 1.6 22.6C349.8 372.1 311.1 400 256 400s-93.8-27.9-116.1-53.5c-5.8-6.7-5.1-16.8 1.6-22.6s16.8-5.1 22.6 1.6zM144.4 208a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm192-32a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"
                  />
                </svg>
                <p class="text-base text-gray-500">
                  Type something...... <br />
                  Get your first response
                </p>
              </div>

              <div
                *ngIf="chatResponse && chatResponse.length > 0"
                class="w-full"
              >
                <div
                  *ngFor="let response of chatResponse; let i = index"
                  class="py-1"
                >
                  <!-- User question -->
                  <div
                    class="flex justify-start pr-8 space-x-2"
                    *ngIf="response.message.role !== 'assistant'"
                  >
                    <app-circle-img [data]="imageData('user')"></app-circle-img>

                    <div class="px-4 py-2 bg-gray-200 max-w-max rounded-md">
                      <p class="font-bold text-sm">
                        {{ response.message.role | titlecase }}
                      </p>
                      <p class="text-sm">{{ response.message.content }}</p>
                    </div>
                  </div>

                  <!-- ChatGPT response -->
                  <div
                    class="flex justify-end pl-8 space-x-2"
                    *ngIf="response.message.role == 'assistant'"
                  >
                    <div
                      class="px-4 py-2 bg-white border border-solid border-customTeal w-max rounded-md space-x-1"
                    >
                      <p class="font-bold text-sm">ChatGPT</p>
                      <p class="text-sm">{{ response.message.content }}</p>
                      <div
                        class="flex items-center justify-end space-x-2 divide-x-2"
                      >
                        <div
                        *ngIf = '!hideButton'
                          class="tooltip text-sm hover:cursor-pointer text-right font-bold hover:text-customTeal"
                          (click)="selectResponseChoice(response, true)"
                          data-tip="The text can be edited in the input field to suit your needs."
                        >
                          Use in textbox
                        </div>
                        <div    *ngIf = '!hideButton'>|</div>
                        <div
                          id="{{ i }}"
                          class="text-sm flex items-center hover:cursor-pointer text-right font-bold hover:text-customTeal hover:fill-customTeal space-x-1"
                          (click)="copy(response.message, i)"
                          data-tip="Copied"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-3 w-3 hover:fill-customTeal"
                            viewBox="0 0 512 512"
                          >
                            <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                            <path
                              d="M272 0H396.1c12.7 0 24.9 5.1 33.9 14.1l67.9 67.9c9 9 14.1 21.2 14.1 33.9V336c0 26.5-21.5 48-48 48H272c-26.5 0-48-21.5-48-48V48c0-26.5 21.5-48 48-48zM48 128H192v64H64V448H256V416h64v48c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V176c0-26.5 21.5-48 48-48z"
                            />
                          </svg>
                          <span>Copy</span>
                        </div>
                      </div>
                    </div>
                    <app-circle-img class="align-baseline"
                      [data]="imageData('chatgpt')"
                    ></app-circle-img>
                  </div>
                </div>
              </div>
            </div>
            <!-- Prompt -->
            <div class="w-full ">
              <!-- prompt button -->
              <!-- <div class="mb-3">
                <div class="relative mb-4 flex w-full flex-wrap items-stretch">
                  <input
                    type="search"
                    class="relative m-0 -mr-0.5 block w-[1px] min-w-0 flex-auto rounded-l border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:focus:border-primary"
                    [ngClass]="{ ' bg-gray-100': resLoading }"
                    formControlName="content"
                    placeholder="Type something...."
                    maxlength="200"
                    [disabled]="resLoading"
                    aria-label="Prompt ChatGPT"
                    aria-describedby="button-addon1"
                  />

                  <button
                    *ngIf="!resLoading"
                    class="relative z-[2] flex items-center rounded-r bg-customTeal px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-primary-700 hover:shadow-lg focus:bg-primary-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary-800 active:shadow-lg"
                    type="submit"
                    id="button-addon1"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                    [disabled]="onMaxResponse() || resLoading"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      fill="white"
                      class="h-5 w-5"
                    >
                      <path
                        d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z"
                      />
                    </svg>
                  </button>
                  <button
                    *ngIf="resLoading"
                    class="relative z-[2] flex items-center rounded-r bg-darkGray px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-primary-700 hover:shadow-lg focus:bg-primary-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary-800 active:shadow-lg"
                    type="button"
                    id="button-addon1"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                    [disabled]="onMaxResponse() || resLoading"
                  >
                    <div
                      class="inline-block h-5 w-5 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-neutral-100 motion-reduce:animate-[spin_1.5s_linear_infinite]"
                      role="status"
                    >
                      <span
                        class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                        >Generating content...</span
                      >
                    </div>
                  </button>
                </div>
              </div> -->


              <div class="md:mb-3 ">
                <div class="relative md:mb-4  flex w-full flex-wrap items-stretch">
                  <textarea
                    class="relative m-0 -mr-0.5 overflow-y-auto h-auto block w-[1px]  min-w-0 flex-auto rounded-l border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:focus:border-primary"
                    [ngClass]="{ ' bg-gray-100': resLoading }"
                    formControlName="content"
                    placeholder="Type something...."
                    maxlength="200"
                    [disabled]="resLoading"
                    aria-label="Prompt ChatGPT"
                    aria-describedby="button-addon1"
        
                  ></textarea>
              
                  <!-- Search button -->
                  <button
                    *ngIf="!resLoading"
                    class="relative z-[2] flex items-center rounded-r bg-customTeal px-6 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-primary-700 hover:shadow-lg focus:bg-primary-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary-800 active:shadow-lg"
                    type="submit"
                    id="button-addon1"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                    [disabled]="onMaxResponse() || resLoading"
                  >
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      fill="white"
                      class="h-5 w-5"
                    >
                      <path
                        d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z"
                      />
                    </svg>
                    <!-- SVG Icon or other content for the button -->
                  </button>
                  <button
                    *ngIf="resLoading"
                    class="relative z-[2] flex items-center rounded-r bg-darkGray px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-primary-700 hover:shadow-lg focus:bg-primary-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary-800 active:shadow-lg"
                    type="button"
                    id="button-addon1"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                    [disabled]="onMaxResponse() || resLoading"
                  >
                    <!-- Spinner or other content for the loading button -->
                    <span
                        class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                        >Generating content...</span
                      >
                  </button>
                </div>
              </div>
    

              <!-- <div
              *ngIf="!resLoading"
              class="form-control max-h-max w-1/2 border-solid border-2 border-gray-400 rounded-md"
            >
              <textarea
                class="textarea p-4"
                formControlName="content"
                placeholder="Send a message...."
                rows="3"
                required
                maxlength="200"
                [disabled]="onMaxResponse()"
              ></textarea>
            </div> -->

              <!-- loading btn  -->
              <!-- <button
              *ngIf="resLoading"
              type="button"
              class="btn-custom-solid bg-gray-400 border-gray-400 w-1/2"
              disabled
            >
              <i class="animate-spin float-left mr-3 material-icons">
                hourglass_top
              </i>
              <span>Generating content...</span>
            </button> -->
            </div>
       </div>
          </div>
          
        </div>
      </form>

    </div>
  </div>
</div>
