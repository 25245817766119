<div class="row">
    <div class="flex w-full justify-center">

        <div *ngIf="step1">

            <div class="flex items-center justify-center w-full">
                <div class="font-body min-h-screen max-h-max pt-6 w-full items-center">
                  <div class="flex flex-col bg-white h-max p-8 rounded-lg space-y-4">
                        <h4 class="font-bold flex justify-center text-xl mt-2">Create Survey / Poll </h4>



                        <main class="StickyContent white ">
                            <form [formGroup]="surveyForm" (ngSubmit)="submitSurveyInfo(surveyForm.value)">

                                <div class="card-panel white">
                                    <div class="row">

                                        <div class="flex justify-between space-x-16 m-4">
                                            <div class="input-field ">
                                                <div class="label-input font-bold">Survey or Polls?<span
                                                        class="text-red-500">*</span></div>
                                                <select formControlName="type" 
                                                class="select select-bordered w-full bg-veryLightGray validate browser-default"
                                                    (change)="typeChanges($event.target.value)">
                                                    <option value="" disabled selected>Choose your option</option>
                                                    <option value="Survey">Survey</option>
                                                    <option value="Poll">Poll</option>
                                                </select>
                                            </div>

                                            <div class="input-field ">
                                                <div class="label-input font-bold">Poll/Survey Name</div>
                                                <input id="surveyName" type="text" formControlName='surveyName' required
                                                class="input input-bordered w-full rounded-sm bg-gray-100 text-gray-700 leading-tight validate" autocomplete="off"
                                                />
                                                <div
                                                    *ngIf="(surveyName.dirty || surveyName.touched) && surveyName.invalid">
                                                    <small *ngIf="surveyName.errors.required" class="text-red-500"> The
                                                        Poll/Survey Name is required.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="flex justify-between space-x-16 m-4">

                                            <div class="w-7/12">
                                                <div class="label-input font-bold">Start Date</div>
                                                <input id="startDate" type="date" class="datepicker input input-bordered w-full rounded-sm bg-gray-100 text-gray-700 leading-tight" 
                                                    (change)="startDateChange()" formControlName='startDate'
                                                    autocomplete="off" />
                                                <div
                                                    *ngIf="(startDate.dirty || startDate.touched) && startDate.invalid">
                                                    <small *ngIf="startDate.errors.required" class="text-red-500"> The
                                                        Start Date is required. </small>
                                                </div>
                                            </div>

                                            <div class="input-field w-7/12">
                                                <div class="label-input font-bold">End Date</div>
                                                <input id="endDate" type="date"
                                                class="datepicker input input-bordered w-full rounded-sm bg-gray-100 text-gray-700 leading-tight" 
                                                    (change)="endDateChange()" formControlName='endDate'
                                                    autocomplete="off" />
                                                <div *ngIf="(endDate.dirty || endDate.touched) && endDate.invalid">
                                                    <small *ngIf="endDate.errors.required" class="text-red-500"> The End
                                                        Date is required. </small>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row input-field m-4">
                                            <div class="row col s12">Do You Want to Show the Results ? <span
                                                    class="text-red-500">*</span>
                                            </div>
                                            <div class="flex space-x-24">
                                                <label class="flex space-x-4">
                                                    <input class="with-gap" formControlName="showResult"
                                                        name="showResult" type="radio" value="yes"
                                                        (change)="onShowResultYes($event)" />
                                                    <span>Yes</span>
                                                </label>
                                                <label class="flex space-x-4">
                                                    <input class="with-gap" formControlName="showResult"
                                                        name="showResult" type="radio" value="no"
                                                        (change)="onShowResultNo($event)" />
                                                    <span>No</span>
                                                </label>
                                            </div>

                                            <div class="input-field col s12" *ngIf="showSurveyResult">
                                                <div class="label-input font-bold">Show Results To<span
                                                        class="text-red-500">*</span></div>
                                                <select formControlName="showResultTo"
                                                class="select select-bordered w-full bg-veryLightGray validate browser-default">
                                            <option value="" disabled selected>Choose your option</option>
                                                    <option value="subscriber">Subscriber Only</option>
                                                    <option value="public">Public</option>
                                                    <!-- <option value="both">Both</option> -->
                                                </select>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div class="flex justify-between">
                                    <!-- <div class="section"></div> -->
                                    <button type="button" class="font-bold" (click)="backClicked()">
                                        Back </button>
                                    <button type="submit" [disabled]="!surveyForm.valid" style="margin-bottom: 15px;"
                                        class="px-4 py-2 bg-customTeal text-white rounded-md"
                                        [ngClass]="'btn ' + buttonType">
                                        Next >
                                    </button>
                                </div>
                            </form>
                        </main>

                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="step2">

            <div class="flex items-center justify-center w-full">
                <div class="font-body min-h-screen max-h-max pt-6 w-full items-center">
                        <div class="col l9 m9 s12">
                            <h4 *ngIf="createPoll" class="font-bold text-xl">Create Poll</h4>
                            <h4 *ngIf="!createPoll">Create Survey</h4>

                        <div class="flex justify-end items-center m-2">
                            <button [disabled]="!questionForm.valid" (click)="addQuestion()"
                                class="flex px-4 py-2 bg-customTeal text-white rounded-md" [ngClass]="{'bg-gray-500' : !questionForm.valid}">
                                <i class="material-icons">add</i> 
                                New Question</button>
                        </div>
                    </div>

                    <main class="StickyContent white ">
                        <form [formGroup]="questionForm" (ngSubmit)="submitQuestions(questionForm.value)">
                            <ul class="collapsible col s12 l11 m11">
                                <li formArrayName="questionItems" class="flex flex-col bg-white h-max p-8 rounded-lg space-y-4 mb-2"
                                    *ngFor="let item of questionForm.get('questionItems').controls; let i = index;">

                                    <div class="collapsible-header">
                                        <h4 class=" text-lg"><span class="font-bold">Question
                                                <span class="text-customTeal  ">{{i + 1}}</span> :
                                            </span>{{item.question}} </h4>

                                        <!-- //add the question on the collapsible -->
                                        <div class="col s1">
                                            <!-- <a class="btn-floating btn halfway  right" *ngIf="i > 0"
                    (click)="removeFile(i)"><i class="material-icons text-red-500">clear</i></a> -->

                                            <a class="btn-floating btn waves-effect waves-light white" *ngIf="i > 0"
                                                (click)="removeFile(i)"><i
                                                    class="material-symbols-outlined bold-700 center text-red-500">
                                                    remove</i></a>

                                        </div>
                                    </div>

                                    <div class="collapsible-body">
                                        <div [formGroupName]="i">
                                            <div class="row">
                                                <div class=" white">
                                                    <div class=" row">


                                                        <div class="input-field col s12 l10">
                                                            <!-- <div class="label-input">Question</div> -->
                                                            <!-- <i class="material-icons prefix blue-text">help_outline</i> -->
                                                            <input id="" type="text" formControlName="question" required
                                                                class="validate input input-bordered w-full rounded-sm bg-gray-100 text-gray-700 leading-tight" autocomplete="off"
                                                                style="text-transform: capitalize;"
                                                                placeholder="Question" />
                                                            <div *ngIf="(item.controls.question.dirty ||
                    item.controls.question.touched) &&
                    item.controls.question.invalid">
                                                                <small *ngIf="item.controls.question.errors.required"
                                                                    class="red-text"> The Question is
                                                                    required.
                                                                </small>
                                                            </div>
                                                        </div>

                                                        <div class="row input-field col s12 l10">
                                                            <div class="label-input">Answer Type</div>
                                                            <!-- <i class="material-icons teal-text prefix">add_task</i> -->
                                                            <select formControlName="answerType" required
                                                                class="validate browser-default"
                                                                (change)="answerTypeChange($event.target.value, i)">
                                                                <option value="" disabled selected>Please Select Answer
                                                                    Type</option>
                                                                <option *ngFor="let answer of answerTypes"
                                                                    [value]="answer.type" title="Tooltip">
                                                                    {{answer.type}}
                                                                    <span class="green-text right"> (
                                                                        {{answer.helpText}} )</span>
                                                                </option>
                                                            </select>

                                                            <div *ngIf="(item.controls.answerType.dirty ||
                    item.controls.answerType.touched) &&
                    item.controls.answerType.invalid">
                                                                <small *ngIf="item.controls.answerType.errors.required"
                                                                    class="red-text"> The Type is
                                                                    required.
                                                                </small>
                                                            </div>
                                                        </div>

                                                        <div
                                                            *ngIf="item.controls.answerType.value === 'Multiple Choice'">

                                                            <div class="row input-field col s12 m5 right">
                                                                <label>
                                                                    <input type="checkbox"
                                                                        formControlName="allowMultipleAnswers" />
                                                                    <span>Allow Multiple Answers</span>
                                                                </label>
                                                            </div>
                                                            <br />
                                                            <div class="row input-field col s12" formArrayName="answers"
                                                                *ngFor="let ans of getAnswers(i).controls; let j = index;">
                                                                <div [formGroupName]="j">
                                                                    <a *ngIf="j > 1"
                                                                        class="btn-flat waves-effect waves-light col s1 right"><i
                                                                            class="material-icons red-text prefix"
                                                                            (click)="removeAnswer(i, j)">remove_circle_outline</i></a>
                                                                    <div class="row input-field col s11">
                                                                        <div class="label-input">Choice {{j+1}} </div>
                                                                        <!-- <i class="material-icons purple-text prefix">question_answer</i> -->
                                                                        <input id="answer" type="text"
                                                                            formControlName="answer" required
                                                                            class="validate input input-bordered w-full rounded-sm bg-gray-100 text-gray-700 leading-tight" autocomplete="off"
                                                                            style="text-transform: capitalize;"
                                                                            placeholder="Answer" />
                                                                        <div
                                                                            *ngIf="(ans.controls.answer.dirty || ans.controls.answer.touched) && ans.controls.answer.invalid">
                                                                            <small
                                                                                *ngIf="ans.controls.answer.errors.required"
                                                                                class="red-text"> Answer
                                                                                {{j+1}} is
                                                                                required.</small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="m-2">
                                                                <div class="section"></div>
                                                                <button type="button" style="margin-bottom: 15px;"
                                                                    class="px-4 py-2 bg-customTeal text-white rounded-md"
                                                                    (click)="onAddAnswer(i)"
                                                                    [ngStyle]="{'color': themeColor}"
                                                                    [disabled]="!questionForm.get('questionItems').controls[i].get('answers').valid"
                                                                    style="cursor: pointer; text-align: center;">
                                                                    <i class="material-icons">add</i> Add Answer
                                                                </button>
                                                            </div>

                                                        </div>
                                                        <div *ngIf="item.controls.answerType.value === 'Scale'"
                                                            formGroupName="scales">
                                                            <div class="input-field col s6 m3">
                                                                <i
                                                                    class="material-icons prefix green-text">linear_scale</i>
                                                                <select formControlName="start" class="validate">
                                                                    <option value="0">0</option>
                                                                    <option value="1">1</option>
                                                                </select>
                                                                <label for="start">Start At</label>
                                                            </div>

                                                            <div class="input-field col s6 m3">
                                                                <i class="material-icons prefix green-text">label</i>
                                                                <input id="startLabel" type="text"
                                                                    formControlName='startLabel' class="validate" />
                                                                <label for="startLabel">Label</label>
                                                                <!-- <div *ngIf="(startLabel.dirty || startLabel.touched) && startLabel.invalid">
                            <small *ngIf="startLabel.errors.required" class="red-text"> The Label is required. </small>
                          </div> -->
                                                            </div>

                                                            <div class="input-field col s6 m3">
                                                                <i
                                                                    class="material-icons prefix blue-text">linear_scale</i>
                                                                <select formControlName="end" class="validate">
                                                                    <option *ngFor="let s of scales" [value]="s">{{s}}
                                                                    </option>
                                                                </select>
                                                                <label for="end">End At</label>
                                                            </div>

                                                            <div class="input-field col s6 m3">
                                                                <i class="material-icons prefix blue-text">label</i>
                                                                <input id="endLabel" type="text"
                                                                    formControlName='endLabel' class="validate" />
                                                                <label for="endLabel">Label</label>
                                                                <!-- <div *ngIf="(endLabel.dirty || endLabel.touched) && endLabel.invalid">
                            <small *ngIf="endLabel.errors.required" class="red-text"> The Label is required. </small>
                          </div> -->
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>

                            <div class="flex justify-end">
                                <div class=""></div>
                                <!-- <a class="col l3 s12  btn back-button bold-600" (click)="backClicked()">
                                    Back To Previous Page </a> -->


                                <button type="submit" [disabled]="!questionForm.valid" style="margin-bottom: 15px;"
                                    class="col l3 s12 offset-l5 btn save-button" [ngClass]="'btn ' + buttonType">
                                    <span *ngIf="createPoll"> Save Poll</span>
                                    <span *ngIf="!createPoll">Save Survey</span>
                                </button>



                            </div>

                        </form>
                    </main>
                <!-- </div> -->
            </div>
        </div>
        </div>
    </div>

</div>