import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { take } from 'rxjs';
import { AuthService } from 'src/app/services/firebase/auth.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeeServicesService {
  collectionRef: AngularFirestoreCollection<any>;
  documentRef: AngularFirestoreDocument<any>;

  constructor(private db: AngularFirestore, private authService: AuthService){ }

  createService(model: any){
    const newContent = {
      id: this.db.createId(),
      createdById: model.createdById,
      serviceName: model.serviceName,
      serviceId: model.serviceId,
      serviceDescription: model.serviceDescription,
      serviceTime: model.serviceTime,
      serviceMinute: model.serviceMinute,
      serviceHour: model.serviceHour,
      showEmployees: model.showEmployees,
      assignedTo: model.assignedTo,
      serviceOffers: model.serviceOffers,
      imgLink: model.imgLink,
      videoLink: model.videoLink,
      sharedLink: model.sharedLink,
      shareWithPublic: model.shareWithPublic,
      price: model.price,
      hasPrice: model.hasPrice,
      titleColor: model.titleColor,
      backgroundColor: model.backgroundColor,
      timeStamp: new Date(),
      hasAppointment: model.hasAppointment,
      bufferTime: model.bufferTime,
      allServices: model.allServices,
      hasSubServices: model.hasSubServices,
      commentable: model.commentable,
      reactions: model.reactions,
      comments: model.comments
    };
    return this.db.collection('Services').doc(newContent.id).set(newContent);
  }

  createServiceCategory(model){
    const newContent = {
      id: this.db.createId(),
      name: model.name,
      serviceCount: model.serviceCount,
      imgUrl: model.imgUrl
    };
    return this.db.collection('ServiceCategory').doc(newContent.id).set(newContent);
  }
  incrementServiceUnderCategoryCount(id: any) {
    this.documentRef = this.db.doc('ServiceCategory/' + id);
    const item = this.documentRef.valueChanges();
    item.pipe(take(1)).subscribe(i => {
      if (i) {
        if (!i.serviceCount){
          i.serviceCount = 0;
        }
        i.serviceCount += 1;
        this.updateServiceCategory(i);
      }
    });
  }

  decrementServiceUnderCategoryCount(id: any) {
    this.documentRef = this.db.doc('ServiceCategory/' + id);
    const item = this.documentRef.valueChanges();
    item.pipe(take(1)).subscribe(i => {
      if (i) {
        if (!i.serviceCount){
          i.serviceCount = 0;
        }
        if (i.serviceCount > 0) {
          i.serviceCount -= 1;
          this.updateServiceCategory(i);
        }
      }
    });
  }

  updateServiceCategory(model: any){
    return this.db.collection('ServiceCategory').doc(model.id).update(model);
  }
  deleteServiceCategory(model: any){
    return this.db.collection('ServiceCategory').doc(model.id).delete();

  }
  deleteServiceByCategoryId(model){
    this.db.collection('Services', ref => ref.where('categoryId', '==', model.id)).valueChanges().subscribe(row => {
        Object.keys(row).map(x => {
          this.db.collection('Services').doc(row[x].id).delete();
        });
    });

    return    this.db.collection('ServiceCategory').doc(model.id).delete();

  }

  getServiceByUserId(id){
    return this.db.collection('Services', ref => ref.where('createdById', '==', id));
  }

  getAllServices(){
    return this.db.collection('Services');
  }
  getServiceByCategoryId(categoryId){
    return this.db.collection('Services', ref => ref.where('categoryId', '==', categoryId));
  }

  getServiceById(serviceId: any){
    return this.db.collection('Services', ref => ref.where('id', '==', serviceId));
  }
  getServiceByServiceName(serviceName: any){
    return this.db.collection('Services', ref => ref.where('serviceName', '==', serviceName));
  }

  updateService(service: any){
    return this.db.collection('Services').doc(service.id).update(service);
  }
  deleteService(service: any){
    return this.db.collection('Services').doc(service.id).delete();
  }

  // deleteServiceByCategoryId(categoryId){
  //   return this.db.collection('Services').doc(categoryId).delete();
  // }

  getServicesCategoryList(){
    return this.db.collection('ServiceCategory');
  }
  saveServiceToEmployee(model: any){
    const newContent = {
      id: this.db.createId(),
      services: model.services,
      userId: model.userId
    };
    return this.db.collection('EmpServices').doc(newContent.id).set(newContent);
  }

  updateEmployeeService(service: any){
    return this.db.collection('EmpServices').doc(service.id).update(service);
  }

  getServiceByEmpId(userId: any){
    return this.db.collection('EmpServices', ref => ref.where('userId', '==', userId));
  }

  getAllEmpServices(){
    return this.db.collection('EmpServices');
  }

  getPublicServices(){
    return this.db.collection('Services', ref => ref.where('shareWithPublic', '==', true));
  }
  getServicesWithTime(){
    return this.db.collection('Services', ref => ref.where('serviceTime', '!=', 0));
  }
  addEmployeeToService(id:any, employeeId){
    this.documentRef = this.db.doc('Services/'+ id);
    const item = this.documentRef.valueChanges();
    item.pipe(take(1)).subscribe(i => {
      if(i){
        i.assignedTo.push(employeeId);
        this.updateService(i);
      }
    })
  }
  removeEmployeeToService(id: any, employeeId){
    this.documentRef = this.db.doc('Services/'+ id);
    const item = this.documentRef.valueChanges();
    item.pipe(take(1)).subscribe(i => {
      if(i && i.assignedTo.includes(employeeId)){
        const index = i.assignedTo.indexOf(i.assignedTo.find(selected => selected === id));
        i.assignedTo.splice(index, 1);
        this.updateService(i);
      }
    })
  }

}
