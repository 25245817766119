import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { AppSettingsComponent } from '../../admin/settings/app-settings/app-settings.component';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css']
})
export class ConfirmationComponent implements OnInit {

  requestSent:any;
  alreadyRegistered:any;
  alreadyRequested : any;
  appName:any;
  appLogo:any;
  model:any;

  constructor(private router: Router,
              private settingsService : AppSettingsService) {

    this.requestSent = this.router.getCurrentNavigation()
    .extras.state.requestSent;

    this.alreadyRegistered = this.router.getCurrentNavigation()
    .extras.state.alreadyRegistered;

    this.alreadyRequested = this.router.getCurrentNavigation()
    .extras.state.alreadyRequested;    
    
   }

  ngOnInit(): void {
    this.settingsService.getAppSettingsList().valueChanges().subscribe(
      appSettings => {
        if (appSettings) {
          if (appSettings.length > 0) {
            this.model = appSettings[0];
            this.appName = this.model.appName;
            this.appLogo = this.model.logo;

          }
        }
      }
      );


  }

}
