import { Component, OnInit, } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { PermissionService } from 'src/app/services/firebase/permission.service';



declare var M: any;
declare var $: any;

@Component({
    selector: 'app-messaging',
    templateUrl: './messaging.component.html',
    styleUrls: ['./messaging.component.css'],

})
export class ChatMessagingComponent implements OnInit {


    chatType = 'private'
    currentUser
    isAdmin :boolean = false
    userRole = ''
    chatRules={
        "Admin":['private','employee','group'],
        "User":['private','group'],
        "Employee":['employee']
    }
    selectedChat
    perm : any;
    //this.permission = this.perm.permission[0];
    permMember : any;
    permPES: any;
    permAnalytics : any;
    permSocial : any;
    permSpecial : any;
    permChat : any;

    sendMessage: boolean = false;

    constructor(
        private authService: AuthService,
        private route: ActivatedRoute,
        private permissionService: PermissionService,

    ) {
    }

    ngOnInit() {

        this.currentUser = this.authService.getGlobalUser();
        this.isAdmin = this.currentUser.role === 'Admin';
        this.route.paramMap.subscribe(params => {
            this.selectedChat = params.get('chatType');
          });

        if (this.currentUser) {
            this.userRole = this.currentUser.role
            if (this.userRole in this.chatRules){
                if (this.selectedChat && this.chatRules[this.userRole].includes(this.selectedChat)){
                    this.chatType= this.selectedChat
                }else{
                    this.chatType=this.chatRules[this.userRole][0]
                }
            }

            this.permissionService.getPermissionByEmployeeId(this.currentUser.uid).valueChanges().subscribe(perm =>{
                if(perm.length != 0){
                  this.perm = perm[0];
                  //this.permission = this.perm.permission[0];
                  this.permMember = this.perm.permission[0]
                  this.permPES = this.perm.permission[1]
                  this.permAnalytics = this.perm.permission[2];
                  this.permSocial = this.perm.permission[3];
                  this.permSpecial = this.perm.permission[4];
                  this.permChat = this.perm.permission[5];
                }
              })
        }
    }
    onChatType(type) {
        this.chatType = type
    }
    openModal(){
        this.sendMessage = true;
        console.log("Send Message",this.sendMessage);
    }
    onSendClick(){
        console.log("on send click")
    
    }
    closeModal(){
        console.log("close modal")
        this.sendMessage = false;
    }

}
