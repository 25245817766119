<ng-container *ngIf="settings && settings.isBusinessApp">
  <div>
    <div *ngIf="viewService">
      <div class="flex flex-wrap md:px-8 gap-4 w-full justify-center md:justify-start  items-center" *ngIf="isAdmin || (permPES && permPES.createServices) && services" >
        <button (click)="navigateCreate()" 
        class="flex items-center space-x-2 rounded bg-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
     
          <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 fill-white " height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/></svg>
          <span>Add New</span>
          </button>
      
      
          <div>
            <app-rename-section section="Services" ></app-rename-section>
          </div>
          <app-homepage-section-number  section="Services" (confirmationModal) = "openConfirmationModal()"> </app-homepage-section-number>

            <button 
            type="button"
            *ngIf="services && services.length > 0"
            class="flex items-center md:ml-5 text-customTeal space-x-2 rounded bg-white px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal border border-customTeal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            (click)="openMoveModal=true">
            <svg width="24px" fill="currentColor" viewBox="0 0 24 24" class="w-4 h-4">
              <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
              <path d="M0 0h24v24H0z" fill="none"></path>
            </svg>
            <span>Arrange Items</span>  
            
          </button>
     

    
        </div>

      <div *ngIf="services">
        <div class="flex flex-wrap mx-auto md:mx-10">
          <div *ngFor="let service of getSortedList(services)" class="md:w-1/4 w-full m-4 border h-min border-gray-200 drop-shadow-lg rounded-lg hover:scale-110 hover:ease-in hover:duration-300 bg-white" [ngStyle]="{'background-color': service.backgroundColor}">
            <div class=" flex flex-col w-full items-center space-y-4 px-4  py-4  md:px-6 " (click)="navigateDetails(service)">

              <ng-container>
                <div *ngIf="service.imgLink && !service.videoLink"   class="rounded-xl cursor-pointer">
                  <div class="top-0 left-0 flex w-full justify-end right-3 bottom-4 mb-4  translate-y-2 -translate-x-4">
                    <app-share-status  [status] = 'service.shareWithPublic ? "Public" : "User"' design = 'Primary' ></app-share-status>
                  </div>
                  <div class="flex justify-center h-40 md:h-48">
                    <img [src]="service.imgLink" class="md:w-56 h-full w-full rounded-lg" style="object-fit: contain;">
                  </div>
                </div>
                <div>
                  <div *ngIf="service.videoLink && service.imgLink" (click)="redirectToservice(service.id)"  class="w-full h-40 md:h-48 rounded-xl cursor-pointer">
                    <video controls *ngIf="service.videoLink != ''" [src]="service.videoLink" class="object-cover" alt="Logo" [poster]="service.imgLink"></video>           
                  </div>
                  <div *ngIf="service.videoLink && !service.imgLink" (click)="redirectToservice(service.id)"  class="w-full h-40 md:h-48 rounded-xl cursor-pointer">
                    <video controls *ngIf="service.videoLink != ''" [src]="service.videoLink" class="object-cover" alt="Logo"></video>           
                  </div>
                </div>
                
                <div class="break-words w-full">
                  <h6  class=" cursor-pointer max-w-sm font-bold py-2" [ngStyle]="{'color':service.titleColor}">{{truncateHTML(getInnerText(service.serviceName),60)}}</h6>
                </div>
                <div *ngIf=" !service.imgLink">
                  <app-share-status  [status] = 'service.shareWithPublic ? "Public" : "User"' design = 'Secondary' ></app-share-status>
                </div>

              </ng-container>
              <div *ngIf="service.allServices" class="flex flex-col w-full ">
                <ng-container *ngFor="let ser of service.allServices" class="w-full">
                  <div class="flex flex-col w-full mb-5">
                    <div tabindex="0" class="w-full collapse collapse-plus rounded-sm border border-base-300 shadow-sm bg-gray-100 ">
                      <input type="checkbox" class="peer" />
                      <div  class="collapse-title cursor-pointer font-bold text-customTeal" >
                        {{truncateHTML(ser.serviceTitle, 60)}}
                      </div>
                      <div *ngIf="currentUser" class="collapse-content">
                        <p class="font-bold text-customTeal">$ {{ser.servicePrice}}</p>
                        <p class="italic">{{truncateHTML(ser.serviceUnderDescription,60)}}</p>
                        <button *ngIf="ser.hasAppointment==true" class="btn-custom-solid float-right" (click)="BookServiceAppointment(service,ser)">Book</button>
              
                     </div>
                     <div *ngIf="!currentUser" class="collapse-content">
                      <p class="font-bold text-customTeal">$ {{ser.servicePrice}}</p>
                      <p class="italic">{{ser.serviceUnderDescription}}</p>
                      <button *ngIf="ser.hasAppointment==true" class="btn-custom-solid float-right" (click)="OpenLoginPopup()">Book</button>
            
                   </div>
                    </div>
                  </div>
                </ng-container>
              </div>  
              <div *ngIf="!service.allServices || service.allServices.length==0">
                <!-- <div *ngIf="service.serviceDescription">
                  {{truncateHTML(getInnerText(service.serviceDescription), 90)}}
                </div> -->
                
              </div> 
              <div *ngIf="service.price">
                <div *ngIf=" service.price!='dontInclude'">
                  <div *ngIf="service.price > 0" href="" class="px-3 lg:px-6 py-2  justify-start text-white  bg-customTeal rounded-md baseline font-bold bottom-0   md:ml-0">
                    <ng-container *ngIf="currency"> {{service.price | currency: currency:'symbol-narrow'}}</ng-container>
                    <ng-container *ngIf="!currency">{{service.price | currency: 'USD':'symbol-narrow'}}</ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
        <div class="w-full text-center ">
          <!-- <pagination-controls (pageChange)="p = $event"></pagination-controls> -->
        </div>
      </div>
      <div *ngIf="!services" class="w-4/5 mx-auto space-y-4">
        <app-empty-state *ngIf="isAdmin && !(calledFrom == 'onBoarding')" calledFrom="service"></app-empty-state>
        <p class="font-bold pt-4">
          Post Sample
        </p>
        <div class="w-full md:w-[341px] border h-min border-gray-200 drop-shadow-lg rounded-lg  bg-white" >
                    <div class=" flex flex-col w-full items-center space-y-4 px-4  py-4  md:px-6 " >
                      <ng-container>
                        <div class="w-full h-40 md:h-48 rounded-xl cursor-pointer" [style]="'background:linear-gradient(rgba(217, 217, 217, 0), rgba(0, 0, 0, 0)),url('+ emptyService.img +'); background-size: cover; background-repeat: no-repeat; background-position: center;'">
                          <div class="top-0 left-0 flex w-full justify-end right-3 bottom-4 mb-4  translate-y-2 -translate-x-4">
                            <app-share-status  [status] = 'emptyService.status' design = 'Primary' ></app-share-status>
                          </div>
                        </div>
                        <h4 class=" cursor-pointer max-w-sm text-2xl font-bold text-center " >{{getInnerText(emptyService.postTitle)}}</h4>
                      </ng-container>
                      <div class="flex flex-col w-full ">
                        <ng-container class="w-full">
                          <div class="flex flex-col w-full mb-5">
                            <div tabindex="0" class="w-full collapse collapse-plus rounded-sm border border-base-300 shadow-sm bg-gray-100 ">
                              <input type="checkbox" class="peer" />
                              <div  class="collapse-title cursor-pointer font-bold text-customTeal" >
                                {{emptyService.postTitle}}
                              </div>
                              <div *ngIf="currentUser" class="collapse-content">
                                <p class="font-bold text-customTeal">$ {{emptyService.price}}</p>
                                <p class="italic">{{emptyService.message}}</p>
                               
                             </div>
                     
                            </div>
                          </div>
                        </ng-container>
                      </div>  
                   
                      <div *ngIf="emptyService.price">
                          <div  href="" class="px-3 lg:px-6 py-2  justify-start text-white  bg-customTeal rounded-md baseline font-bold bottom-0   md:ml-0">
                            <ng-container > {{emptyService.price | currency: currency:'symbol-narrow'}}</ng-container>
                          </div>
                    
                      </div>
                    </div>
       
        </div>
      </div>
      
      <!-- <div  *ngIf="services" class="mx-5 lg:mx-16">
        <div class="mt-16 mx-3 lg:mx-10 flex flex-col " *ngIf="services.length > 0 && !hasServicesCategory">
          <div class="flex flex-wrap gap-x-7 ">
            <div class="min-h-max pb-10 md:pb-0 lg:w-80 md:w-72 w-full mb-10  border bg-white drop-shadow-md rounded-lg hover:scale-110 hover:ease-in hover:duration-300"
            *ngFor="let service of services | paginate: { itemsPerPage: 6, currentPage: p }"
             (click)="navigateDetails(service)" [ngStyle]="{'background-color': service.backgroundColor}">
              <div class=" flex flex-col w-full items-center space-y-4   px-4  pt-4  md:px-6 md:py-8 ">
                <div *ngIf="service.imgLink" class="w-full h-40 md:h-48 rounded-xl " [style]="'background:linear-gradient(rgba(217, 217, 217, 0), rgba(0, 0, 0, 0)),url('+ service.imgLink +'); background-size: contain; background-repeat: no-repeat; background-position: center;'">
                </div>
                <h4 class="max-w-sm break-words text-2xl font-bold text-center"  [ngStyle]="{'color': service.titleColor}">{{truncateHTML(service.serviceName, 35)}}</h4>
                <div class="md:hidden block my-9"><br/></div>
                <div *ngIf="service.hasPrice && service.price && service.price !== ''" href="" class="px-3 lg:px-6 py-2  justify-start text-white  bg-customTeal rounded-md baseline text-xl font-bold bottom-0 translate-y-[50%] absolute md:ml-0">
                  {{service.price | currency: currency:'symbol-narrow'}}
                </div>
              </div>
          </div>
          </div>

          <div class="w-full text-center ">
            <pagination-controls (pageChange)="p = $event"></pagination-controls>
          </div>
        </div>

        <div class="mt-16 flex flex-col" *ngIf="services.length > 0 && hasServicesCategory">
          <div class="flex flex-wrap gap-x-14
           mx-auto">
            <div class="form-control w-full bg-white shadow-sm  pb-10" 
            *ngFor="let service of getSortedList(services) | paginate: { itemsPerPage: 8, currentPage: p }" [ngStyle]="{'background-color': service.backgroundColor}"
            >
            <div class="bg-customTeal p-5 mb-10 cursor-pointer" (click)="navigateDetails(service)">
              <div class="pl-28 text-3xl text-white"> {{truncateHTML(service.serviceName, 35)}} </div>
            </div>
            <div *ngIf="service.allServices" class="flex flex-wrap">
              <ng-container *ngFor="let ser of service.allServices" class="">
                <div class="md:w-3/12 w-full mb-5">
                  <div tabindex="0" class="mx-5 collapse collapse-plus border border-base-300 bg-gray-200 rounded-box ">
                    <input type="checkbox" class="peer" />
                    <div class="collapse-title text-xl font-medium" >
                      {{ser.serviceTitle}}
                    </div>
                    <div class="collapse-content">
                      <p>$ {{ser.servicePrice}}</p>
                      <p class="italic">{{ser.serviceUnderDescription}}</p>
                    </div>
                  </div>
                </div>
        
        
              </ng-container>
            </div>
          </div>
          </div>
          <div class="w-full text-center ">
            <pagination-controls (pageChange)="p = $event"></pagination-controls>
          </div>
        </div>      
      </div>


      <div *ngIf="!services" class="mt-5">
        <div class="flex items-center justify-center">
          <img  src="../../../../assets/icons/Illustrations/Service Illustration.png"
          class="block w-64 items-center justify-center">
      </div>
      <div class="text-customTeal text-lg text-center font-bold">What are the {{servicesName}}  you provide?</div>
      <div class="text-gray-400 text-sm text-center font-bold">Why not let the world know!</div>
      </div> -->
    </div>

    <!-- <div *ngIf="!viewService">
      <div class="mr-24 flex justify-end">
        <button (click)="addCategory();" class="py-2 px-6 mb-3 bg-customTeal rounded-md text-white float-right">
          <i class="material-icons text-white float-left mr-3" >category</i>
          Add Category</button>
      </div><br/>
      <div class="flex items-center mx-auto w-10/12">
        <div class="w-full">
          <div *ngIf="serviceCategoriesModel && showMsg" >
            <div class="text-gray-500 flex">
              <div class="w-1/2">Category</div>
              <div class="w-1/2">Services under category</div>
            </div>
            <div *ngFor="let serviceCategory of serviceCategoriesModel" tabindex="0" class="p-3 border-base-300 border border-customTeal mb-4 bg-white rounded-md">
              <div class="">
                <div class="flex w-full">
                  <div class="w-6/12">
                    <a class="cursor-pointer hover:bg-red-500" >
                      <span><strong>{{serviceCategory.name}}</strong> </span>
                    </a>
                  </div>
                  <div class="w-5/12" *ngIf="serviceCategory.serviceCount">
                    <strong class="text-green-600">
                      {{serviceCategory.serviceCount }}</strong>

                  </div>
                  <div class="w-5/12" *ngIf="!serviceCategory.serviceCount">
                    <strong class="text-green-600">0</strong>

                  </div>
                  <div class="w-1/12 lg:1/12 cursor-pointer">
                    <button class="cursor-pointer" (click)="selectCategory(serviceCategory)">
                      <i class="material-icons ">edit</i>
                    </button>
                  </div>
                </div>

              </div>

            </div>
          </div>
          <div *ngIf="(!serviceCategoriesModel || serviceCategoriesModel.length === 0) && showMsg">
            <div class="row card-panel">
              <p>
                <strong class="red-text">Please Update Your Service Category List</strong>
              </p>
            </div>
          </div>

          <div id="editModal" *ngIf="editModalOpen" class="modal"  [ngClass]="{'modal-open': editModalOpen, 'modal-action': !editModalOpen }">
            <div class="modal-box">

            <form class=""
              [formGroup]="serviceCategoryForm"
              (ngSubmit)="manageCategory(serviceCategoryForm.value)"
            >
              <div class="">
                <h3 *ngIf="selectedModel" class="font-bold text-lg">Edit Service Category</h3>
                <h3 *ngIf="!selectedModel" class="font-bold text-lg">Create Service Category</h3>
                <div class="text-gray-700">A service category represents a group of similar services that share related characteristics</div>

                <br />
                <div class="">
                  <div class="">
                    <label class="label py-2">
                      <span class="label-text text-[#4F4F4F] text-base font-bold leading-5">Name</span>
                    </label>
                    <input
                      id="name"
                      type="text"
                      formControlName="name"
                      required
                      placeholder="Category name"
                      class="focus:invalid:border-red-500 py-3 px-3 bg-gray-100 input input-bordered w-full"
                      autocomplete="off"
                    />
                    <div *ngIf="(name.dirty || name.touched) && name.invalid">
                      <small *ngIf="name.errors.required" class="text-red-600">
                        Category Name is required.
                      </small>
                    </div>
                  </div>

                  <div class="space-y-2">

                    <label class="label">
                      <span class="label-text text-black text-base font-bold leading-5">Image</span>
                    </label>
                    <div class="w-full max-w-x flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
                      <div *ngIf="!isMobile"
                        class="w-1/2 flex justify-center content-center h-44 max-w-x bg-white rounded-lg border-2 gap-x-3">

                        <label class="custom-file-upload  self-center flex flex-col">
                          <i class="fa fa-plus-circle text-customTeal text-3xl text-center self-center"></i>
                          <input type="file" type="file" accept="image/*" (change)="fileChangeEvent($event)">
                          <span class="block font-bold pt-1">Upload Image</span>
                        </label>
                      </div>

                      <div class="flex p-3" *ngIf="isMobile">
                        <button type="button"
                          class="py-2 px-8 modal-button gap-2 text-black border-2 border-customTeal rounded-lg w-full"
                          type="button" (click)="handleMobilePhotoInput()">
                          Upload Image
                        </button>
                      </div>

                      <div class="relative" *ngIf="base64Image">
                        <img [src]="base64Image" class="object-cover  w-32 h-32 rounded-md cover" alt="Image">
                        <button (click)="removeFile()" type="button"
                          class="absolute top-0 translate-x-24 -translate-y-4 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                          <i class="fa fa-trash text-md"></i>
                        </button>
                      </div>

                      <div class="relative"
                        *ngIf="!base64Image && (selectedModel && selectedModel.imgUrl && selectedModel.imgUrl !='')">
                        <img [src]="selectedModel.imgUrl" class="object-cover  w-32 h-32 rounded-md cover"
                          alt="Image">
                        <button (click)="remove()" type="button"
                          class="absolute top-0 translate-x-24 -translate-y-4 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                          <i class="fa fa-trash text-md"></i>
                        </button>
                      </div>


                      <p class="text-red-700 py-2" *ngIf="imgErrorMsg">
                        <strong><small><i class="material-icons">error_outline</i> {{imgErrorMsg}}</small></strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-action">
                <button
                  type="submit"
                  [disabled]="!serviceCategoryForm.valid"
                  class="btn bg-gray-400 text-customTeal border border-customTeal"
                  style="margin-right: 10px;"
                >
                  <span *ngIf="selectedModel">Update</span>
                  <span *ngIf="!selectedModel"> Save Category</span>
                </button>
                <button
                  *ngIf="selectedModel"
                  type="button"
                  class="modal-close btn bg-red-400 text-white btn-flat"
                  (click)="openDeleteModal()"
                >
                  Delete
                </button>
                <a (click)="closeEditModal()"
                  class="modal-close btn bg-customTeal text-white btn-flat"
                  >Cancel</a
                >
              </div>
            </form>

          </div>

          </div>
          <div id="deleteModal" *ngIf="deleteModalOpen" class="modal"  [ngClass]="{'modal-open': deleteModalOpen, 'modal-action': !deleteModalOpen }">
            <div class="modal-box">
              <h3 class="font-bold text-lg">Delete Category</h3>

            <p class="py-4">Are you sure you want to delete category?</p>

              <div class="modal-action">
                <button
                *ngIf="selectedModel"
                type="button"
                class="btn bg-gray-400 text-customTeal border border-customTeal"
                (click)="deleteCategory(selectedModel)"
              >
                Delete
              </button>
                <a (click)="closeDeleteModal()"
                class="modal-close btn bg-customTeal text-white btn-flat ">Cancel</a>
              </div>
            </div>
            </div>
        </div>
        <div class="col l1"></div>
      </div>

    </div> -->

  </div>
</ng-container>

<!-- toast -->
<app-alert class="fixed top-0 flex justify-end w-full" [toastType]="toastType" [toastMessage]="toastMessage" [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>
  
<!-- Crop Image Modal -->
<app-crop-image *ngIf="cropModalOpen" [theImage]="imageChangedEvent" [aspectRatio]="4 / 3" (returnCroppedImage)="processCroppedImage($event)" (closeCropModal)=closeCroppingModal()></app-crop-image>


<app-move-elements *ngIf="openMoveModal"  [elementsList]="services" sectionType="Services" (closeClicked)="openMoveModal=false"></app-move-elements>