import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AuthService } from '../firebase/auth.service';

@Injectable({
  providedIn: 'root'
})
export class FriendsService {
  contentListRef: AngularFirestoreCollection<any>;
  contentRef: AngularFirestoreDocument<any>;

  constructor(private authService: AuthService,  private db: AngularFirestore ) { }
  // initially no friends
  createFriend(model: any){
    const friend = {
      id: this.db.createId(),
      friends: model.friends,
      userID: model.userID
    };
    return this.db.collection('Friends').doc(friend.id).set(model);
  }

  addOrRemoveFriend(model: any) {
    return this.db.collection('Friends').doc(model.id).set(model);
  }

  getMyFriends(userID: string){
    return this.db.collection('Friends',  ref => ref.where('userID', '==', userID));
  }

  sendFriendRequest(model: any){
    console.log("model is ", model);
    const friendRequest = {
      id: this.db.createId(),
      sentTo: model.sentTo,
      status: 'Pending',
      timestamp: new Date(),
      sentBy: model.sentBy
    };
    console.log("fr", friendRequest);
    return this.db.collection('FriendRequests').doc(friendRequest.id).set(friendRequest);
  }
  myFriendRequests(userId: string){
    return this.db.collection('FriendRequests', ref => ref.where('sentTo', '==', userId));
  }
  sentFriendRequests(userId: string){
    return this.db.collection('FriendRequests', ref => ref.where('sentBy', '==', userId));
  }

  // remove from friend request if accepted
  acceptFriendsRequest(modelId: string){
    return this.db.collection('FriendRequests').doc(modelId).delete();
  }

  rejectFriendsRequest(model: any){
    return this.db.collection('FriendRequests').doc(model.id).update(model);
  }

}
