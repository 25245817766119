import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class ClientAnswersService {

  clientAnswersListRef: AngularFirestoreCollection<any>;
  clientAnswersRef: AngularFirestoreDocument<any>;

  constructor(private db: AngularFirestore) { }

  newFormParticipation(model: any)  {
    model.id  = this.db.createId();
    return this.db.collection('ClientAnswers').doc(model.id).set(model);
  }

  getClientByPhoneNumber(cellPhone: any){
    return this.db.collection('ClientAnswers/', ref => ref.where('cellPhone', '==', cellPhone));
  }
  getClientByEmail(email: any){

    return this.db.collection('ClientAnswers/', ref => ref.where('email', '==', email));
  }

  getAnswers(){
    return this.db.collection('ClientAnswers');
  }


  getFormParticipationByClientID(id: string): AngularFirestoreCollection<any> {
    this.clientAnswersListRef = this.db.collection('ClientAnswers', ref => ref.where('id', '==', id));
    return this.clientAnswersListRef;
  }

  getFormParticipationByFormID(id:string) {
    this.clientAnswersListRef = this.db.collection('ClientAnswers', ref => ref.where('formId', '==', id));
    return this.clientAnswersListRef;

  }

  getFormParticipationByClientAndFormId(id: string, clientID: string  ): AngularFirestoreCollection<any> {
    this.clientAnswersListRef = this.db.collection('ClientAnswers', ref => ref.where('formId', '==', id).where('userId', '==', clientID));
    return this.clientAnswersListRef;
  }

  updateFormParticipationByClient(model: any) {
    //return this.db.collection('ClientAnswers', ref => ref.where('userId', '==', clientID).update(model));
      console.log("this is the one where you update from the service", model)
     return this.db.collection('ClientAnswers').doc(model?.formId).update(model);
  }
   
  deleteClient(id: string){
    console.log("this is the deleted client>>>>>>>");
    return this.db.collection('ClientAnswers').doc(id).delete();
  }

}
