<div class="flex flex-col gap-y-4 mt-6 w-full " >
    <div *ngFor="let event of events | slice:0:itemNumber" class="w-full">
        <div 
        [ngClass]="{'w-full flex flex-col  ': preview && view=='mobile', 'w-full md:p-4 md:p-0  gap-x-5 flex flex-col space-x-4 md:flex-row p-6  items-start ': !preview || (preview && view=='web')}"
        class=" py-5 cursor-pointer rounded-sm shadow-sm" [routerLink]="['/view-event',event.id]">
            <div 
            [ngClass]="{'w-full ': preview && view=='mobile', 'w-full  md:w-[380px]': !preview || (preview && view=='web')}"
            *ngIf="event.imgLink">
                <img [src]="event.imgLink"
                 [ngClass]="{'w-full object-cover ': preview && view=='mobile', 'md:h-56 h-60  object-cover w-full': !preview || (preview && view=='web')}"
                 >
              </div>
              <div class=" space-y-4  space-x-4" 
              [ngClass]="{'w-full flex flex-col': preview && view=='mobile', 'md:w-[680px] flex ': (!preview || (preview && view=='web')) && event.imgLink,'md:w-full flex  md:flex-row flex-col': (!preview || (preview && view=='web'))&& !event.imgLink}"
            >
                <div 
                [ngClass]="{'w-full flex flex-col bg-customTeal py-6 px-4 space-y-2': preview && view=='mobile','md:w-1/3 flex flex-col bg-customTeal py-6 px-4 space-y-2': !preview || (preview && view=='web') }"
                class="">
                  <div class="flex md:flex-col w-full items-center md:items-start">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" class="w-10 h-10 stroke-white">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
                      </svg>
                      
                      <h1 class="text-2xl text-white">{{event.eventStartDate | date: 'EEEE'}},</h1>
                  </div>
                  <h1 class=" text-white">{{event.eventStartDate | date: 'MMM-dd'}}</h1>
                  <hr>
                  <h1 class=" text-white">{{event.eventStartTime }} - {{event.eventEndTime}}</h1>            
                </div>
                <div 
                [ngClass]="{'w-full flex flex-col space-y-4 ': preview && view=='mobile','md:w-2/3 flex flex-col space-y-4 ': !preview || (preview && view=='web') }"
                class="">
                  <h1 class="text-xl text-customTeal font-semibold ">
                    {{truncateHTML(getInnerText(event.eventTitle), 45)}}
                  </h1>
                  <p class="text-base text-gray-600 text-justify">
                    {{truncateHTML(getInnerText(event.description), 150)}}
                 </p>
                 <div class="flex flex-row space-x-1 text-gray-500 items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3 md:h-4 md:w-4 " viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
                    </svg>
                    <span class="text-xs md:text-base text-center  ">{{truncateHTML(event.eventPlace, 30)}}</span>
                </div>
                </div>
              </div>
            
        </div>
    </div>

</div>
