<div class="font-body bg-veryLightGray min-h-screen max-h-max pt-6">
    <div class="flex flex-col md:w-4/6 m-2 bg-white h-max p-4 md:p-12 rounded-lg space-y-4 md:translate-x-1/4">
        <p class=" font-bold text-xl text-center text-customTeal ">Just one more thing </p>
        <!-- Area of interest -->
        <div class="bg-yellow-100 p-4 mx-10 my-4 rounded-md">
            <i class="material-icons float-left">info</i><p>You are assigned to a <span class="font-bold text-customTeal">{{currentUser.department}}</span> department and your Job Title is <span class="font-bold text-customTeal">{{currentUser.jobTitle}}</span></p>
        </div>


        <form [formGroup]="empAccountSetupForm" (ngSubmit)="SubmitSetup(empAccountSetupForm.value)">
            <div class="space-y-2 pt-2 md:flex md:justify-between">
                <div>
                    <p class="font-bold">Work Phone </p>
                    <p class="text-xs text-darkGray">Please put in your Work Phone Number on the space provided.</p>
                </div>

                <!-- button -->
                <div class="flex space-y-2 md:w-96 flex-col ">
                    <input formControlName="workPhone"
                        class=" border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline "
                        type="text" placeholder="000-0000-0000 " required>
                        <div *ngIf="(workPhone.dirty || workPhone.touched) && workPhone.invalid">
                            <small *ngIf="workPhone.errors.required" class="text-red-500 italic"> Work phone is required. </small>
                        </div>
                </div>
            </div>
            <br />
            <hr>
            <div class="space-y-2 pt-2">
                <p class="font-bold">Biography </p>
                <p class="text-xs text-darkGray">Please put in your Biography in the space provided below.</p>
                <!-- button -->
                <div class="input-field">
                    <app-editor [control]="biography" class="bg-white" [fromComponent]="'biography'"></app-editor>
                    <div *ngIf="(biography.dirty || biography.touched) && biography.invalid">
                  <small *ngIf="biography.errors.required" class="text-red-500 italic"> Biography is required. </small>
                </div>
                </div>
            </div>
            <br />
            <hr>
            <!-- Notification -->
            <div class="flex flex-col space-y-6 justify-between md:flex-row md:space-y-0 ">
                <!-- Description -->
                <div class="md:w-1/2 space-y-2  ">
                    <p class="font-bold">
                        Appointment
                    </p>
                    <p class=" text-xs text-darkGray ">
                        Do you accept Appointments?
                    </p>
                </div>

                <!-- toggle & edit -->
                <div class="flex justify-center align-middle space-x-8 items-baseline">
                    <!-- toggle -->
                    <div>
                        <div class="form-control">
                            <label class="label cursor-pointer">
                                <span class="label-text pr-2 font-bold ">ON</span>
                                <input formControlName="appointment" type="checkbox" class="toggle toggle-primary " />
                            </label>
                        </div>
                    </div>

                </div>
            </div>
            <br />
            <hr>
            <!-- Send content -->
            <div class="flex flex-col space-y-6 justify-between md:flex-row md:space-y-0 ">
                <!-- Description -->
                <div class="md:w-1/2 space-y-2 mt-2 ">
                    <p class="font-bold">
                        Choose what you would like to show on public?
                    </p>
                    <p class=" text-xs text-darkGray ">
                        Please choose your preference for your email, work phone or phonenumber to be public.
                    </p>
                </div>


                <div class="flex justify-center align-middle space-x-8 items-baseline">
                    <!-- <span *ngIf="toggleYesorNo"> -->
                    <div class="form-control">
                        <label class="label cursor-pointer space-x-2">
                            <input type="checkbox" class="checkbox checkbox-primary" [checked]="emailChecked" (click) = "toggleEmail($event)"/>

                            <span class="label-text">Email</span>
                        </label>
                    </div>
                    <div class="form-control">
                        <label class="label cursor-pointer space-x-2">
                            <input type="checkbox" class="checkbox checkbox-primary" [checked]="phoneChecked" (click) = "togglePhone($event)"/>
                            <span class="label-text">Work Phone</span>
                        </label>
                    </div>
                    <div class="form-control">
                        <label class="label cursor-pointer space-x-2">
                            <input type="checkbox" class="checkbox checkbox-primary" [checked]="workChecked" (click) = "toggleWork($event)"/>
                            <span class="label-text">Phone Number</span>
                        </label>
                    </div>
                    <!-- </span> -->
                    <!-- <div *ngIf="!errorMessage">This can't be empty</div> -->
                </div>
            </div>
            <hr />

            <div class="mt-3 flex flex-col space-y-6  md:flex-row md:space-y-0 ">
                <!-- Description -->
                <div class="md:w-1/2 space-y-2 mt-2 ">
                    <p class="font-bold">
                        Profile Picture
                    </p>
                    <p class=" text-xs text-darkGray ">
                        Please upload your profile picture.
                    </p>
                </div>


                <div class="flex justify-center align-middle space-x-8 items-baseline">
                    <div class="form-control w-full max-w-x">
                        <div class="flex flex-row w-full">
                          <div *ngIf="!isMobile"
                          class="flex justify-center content-center w-full h-44 max-w-x bg-white rounded-lg border-2 gap-x-3">
    
                          <label class="custom-file-upload  self-center flex flex-col">
                            <i class="fa fa-plus-circle text-customTeal text-3xl text-center self-center"></i>
                            <input type="file" type="file" accept="image/*" (change)="handleFileInput($event.target.files)">
                            <span class="block font-bold pt-1">Upload Image</span>
                          </label>
                        </div>
                          <div *ngIf="isMobile">
                            <button type="button"
                              class="py-2 px-16 modal-button text-black border-2 border-customTeal rounded-lg w-full"
                              type="button" (click)="handleMobilePhotoInput()">
                              Upload Images
                            </button>
                          </div>
                          <div class="pt-5 mx-4" *ngIf="base64Image">
                            <p class="label-text text-[#4F4F4F] text-base font-bold leading-5 py-3">Image</p>
                            <div class="flex flex-wrap gap-8" >
    
                              <div class="relative">
                                  <img loading="lazy" [src]="base64Image" class="object-cover w-48 rounded-md cover" alt="Image">
                                  <button (click)="removeFile()"
                                    class="absolute top-0 translate-x-16 -translate-y-4 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                                    <i class="fa fa-trash text-md"></i>
                                  </button>
                              </div>
    
                            </div>
                          </div>
                        </div>
    
    
                        <p class="text-red-500 italic text-xs" *ngIf="imgErrorMsg">
                            <strong><small><i class="material-icons">error_outline</i> {{imgErrorMsg}}</small></strong>
                        </p>
    
                    </div>

                </div>
            </div>


            <div class="pt-6 space-y-2">
                <div class="">
                </div>
                <div class=" flex justify-end w-full">
                    <button type="submit"
                        class="flex space-x-2  px-4 md:px-6 py-2 bg-customTeal text-white rounded-lg w-max h-min text-sm md:text-base font-bold hover:bg-darkBlue focus:outline-none  ">
                        <span>Finish</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
     
</div>