<!-- <div class="bg-gray-200">
  <div class=" labs-template-default single single-labs postid-3713">
    <div class="container">
      <div class="wrapper">
        <div class="masonry">
          <div class="masonry-brick border-2 border-customTeal "  *ngFor="let blog of blogs">
            <div class="masonry-content" [routerLink]="['/view-blog', blog.id]">  
         
              
              <img (load)="waitForImages()" [src]="blog.mediaLink" class="rounded-md shadow-md" alt="">
      
              <h3 class="masonry-title" [innerHTML]="blog.postTitle"></h3>
              
              <div class="flex justify-between items-center px-6 pb-4 text-gray-500">
                <p class="text-xs text-gray-500 ">{{blog.timeStamp.toDate() | date: 'MMM.dd.yyyy'}}</p>
                <div class="flex flex-row space-x-1 items-center  md:ml-24 md:mt-0">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clip-rule="evenodd" />
                      </svg>
                    <span class="text-xs text-center ">{{blog.readingTime}} min read</span>
                </div>
            </div>
              <p class="masonry-description" >{{truncateHTML(getInnerText(blog.message))}}</p>
        
            </div>
          </div>
        </div> 
      </div>
    </div>
  </div>
</div> 
<div>
  <input type="file" (change)="fileChangeEvent($event)" />
<br />
<br />
<button (click)="rotateLeft()">Rotate left</button>
<button (click)="rotateRight()">Rotate right</button>
<button (click)="flipHorizontal()">Flip horizontal</button>
<button (click)="flipVertical()">Flip vertical</button>
<br />
<br />
<button (click)="toggleContainWithinAspectRatio()">{{containWithinAspectRatio?'Fill Aspect Ratio':'Contain Within Aspect Ratio'}}</button>
<button (click)="resetImage()">Reset image</button>
<br />
<br />
<input [(ngModel)]="rotation" placeholder="Rotation" type="number" (keyup)="updateRotation()" /> <button (click)="zoomOut()">Zoom -</button> <button (click)="zoomIn()">Zoom +</button>

<div>
    <image-cropper
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="true"
        [containWithinAspectRatio]="containWithinAspectRatio"
        [aspectRatio]="4 / 3"
        [resizeToWidth]="256"
        [cropperMinWidth]="128"
        [onlyScaleDown]="true"
        [roundCropper]="false"
        [canvasRotation]="canvasRotation"
        [transform]="transform"
        [alignImage]="'left'"
        [style.display]="showCropper ? null : 'none'"
        format="png"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()"
        (cropperReady)="cropperReady($event)"
        (loadImageFailed)="loadImageFailed()"
    ></image-cropper>
    <div class="flex"> 
      <div (click)="zoomOut()">-</div>
      <div> <input type="range" min="1" max="30" (change)="zoomFromRange()" [(ngModel)]="zoomScale" value="10" class="range range-xs" /> </div>
      <div (click)="zoomIn()">+</div>
    </div>
</div>
<img [src]="croppedImage" [style.border]="croppedImage ? '1px solid black' : 'none'" />

</div> -->




<br/>
<br/>
<br/>
<br/>
<br/>
<br/>

<div class="col-lg-12 control-section" style="width:600px;height:350px;">
  <div class="e-img-editor-sample">
    <ejs-imageeditor id="image-editor"  style="width:600px;height:350px;" >
    </ejs-imageeditor>
  </div>
</div>

<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
