<div>

</div>

<div class="">
  <div class="flex  w-full md:w-full lg:w-10/12  bg-gray-100">

    <div class="w-1/12 my-20"></div>
    <ul class="w-10/12 my-20">
      <li id="app" tabindex="0" class="collapse collapse-plus border border-base-300 bg-base-100 rounded-box">
        <input type="checkbox">
        <div class="collapse-title bg-gray-50">App Setting</div>
        <div class="collapse-content">
          <form [formGroup]="appSettingsForm" (ngSubmit)="saveAppSettings(appSettingsForm.value)">
            <div>
              <div class="row">
                  <div class="input-field col s11">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Application Name</label>

                    <input
                    type="text"
                    placeholder="App Name"
                    formControlName="appName"
                    minlength="3"
                    maxlength="15"
                    class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                />
                  </div>
                  <div class="col s1">
                    <i class="material-icons tooltipped right" data-position="bottom"
                    data-tooltip="This is what will be show on the top of the app">
                    help_outline</i>
                  </div>
                <br/><br/>
              </div>

              <div class="row">
                <div class="input-field col s11">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Description</label>
                  <input
                  type="text"
                  placeholder="Description"
                  formControlName="description"
                  class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  />
              </div>
              <div class="col s1">
                <i class="material-icons tooltipped right" data-position="bottom"
                data-tooltip="A short description about the app. This will also be shown on the top of the app below the application name.">
                help_outline</i>
              </div>
              </div>
              <div class="row">
                <div  class="input-field col s12">
                  <p>
                    <strong>
                      <i class="material-icons blue-text left">event_available</i>Is your app a: <span class="red-text">*</span>
                    </strong>
                    <i class="material-icons tooltipped" data-position="bottom"
                    data-tooltip="Please specify the type of application you want
                    If it is a business application select Business App(If you provide a service, a product or both) ">
                    help_outline</i>

                  </p>
                  <div class="row col s12">
                    <div class="col s12 m6">
                      <label>
                      <input formControlName="isBusinessApp" type="radio" checked  [value]=true (change)="onAppTypeChange()"/>
                      <span>Business App</span>
                    </label></div>
                    <div class="col s12 m6">
                      <label>
                        <input formControlName="isBusinessApp" type="radio" [value]=false (change)="onAppTypeChange()" />
                        <span>Personal App</span>
                      </label>
                    </div>


                  </div>
                </div><br/>
              </div>

              <div class="row" *ngIf="isBusinessApp">
                <div  class="input-field col s12">
                  <p>
                    <strong>
                      <i class="material-icons blue-text left">miscellaneous_services</i>Are you offering a: <span class="red-text">*</span>
                    </strong>

                    <i class="material-icons tooltipped" data-position="bottom"
                    data-tooltip="Please specify what you are selling">
                    help_outline</i>
                  </p>
                  <div class="row col s12">
                    <div class="col s12 m6">
                      <label>
                      <input formControlName="serviceType" type="radio"  value="Service"/>
                      <span>Service</span>
                    </label></div>
                    <div class="col s12 m6">
                      <label>
                        <input formControlName="serviceType" type="radio"  value="Product" />
                        <span>Product</span>
                      </label>
                    </div>
                    <div class="col s12 m6"><label>
                      <input formControlName="serviceType" type="radio" value="Both" />
                      <span>Service and Product</span>
                    </label></div>

                  </div>
                </div>

                <div  class="input-field col s12">
                  <p>
                    <strong>
                      <i class="material-icons blue-text left">event_available</i>Do you take appointments?
                      <span class="tooltip" data-tip="Please specify if you take appointments or not for your services.">
                        <i class="material-icons">help</i>
                      </span> <span class="red-text">*</span>
                    </strong>
                    <i class="material-icons tooltipped" data-position="bottom"
                    data-tooltip="Please specify if you take appointments or not">
                    help_outline</i>
                  </p>
                  <div class="row col s12">
                    <div class="col s12 m6">
                      <label>
                      <input formControlName="takeAppointment" type="radio" checked  [value]=true/>
                      <span>Yes</span>
                    </label></div>
                    <div class="col s12 m6">
                      <label>
                        <input formControlName="takeAppointment" type="radio" [value]=false />
                        <span>No</span>
                      </label>
                    </div>


                  </div>
                </div><br/>
              </div>


              <!-- Logo -->
              <div class="row col s12" *ngIf="!isMobile">
                <p><strong>Please Select A Logo From Your Library </strong></p>
                <div class="file-field input-field" >
                  <div class="btn">
                    <span>Browse</span>
                    <input type="file" accept="image/*" (change)="handleImageInput($event.target.files)" />
                  </div>
                  <div class="file-path-wrapper">
                    <input formControlName="appLogo" class="file-path" type="text" placeholder="Select Logo From Device Library" />
                  </div>
                </div>
                <p class="red-text" *ngIf="imgErrorMsg">
                  <strong><small><i class="material-icons">error_outline</i> {{imgErrorMsg}}</small></strong>
                </p>
              </div>
              <div class="row col s12" *ngIf="isMobile">
                <p><strong>Please Select An Image From Your Library</strong></p>
                <div>
                  <button class="btn" type="button" (click)="handleMobilePhotoInput()">Browse</button>
                </div>
                <p class="red-text" *ngIf="imgErrorMsg">
                  <strong><small><i class="material-icons">error_outline</i> {{imgErrorMsg}}</small></strong>
                </p>
              </div>
              <div class="row white" *ngIf="showImageFromServer">

                <div class="row center" *ngIf="oldUrl">
                  <h6 class="center"><strong>Logo</strong></h6>
                  <div class="row col s12">
                    <img loading="lazy" class="responsive-img" [src]="oldUrl" height="40%" width="40%" />
                  </div>
                  <a class="btn-floating btn-small waves-effect waves-light red right" (click)="removeSavedFile()"><i class="material-icons">delete</i></a>

                </div>

              </div>
              <div class="col l12 m12 s12">
              </div>
              <div class="col l12 m12 s12">
                <p class="card-title">Theme colour option
                <i class="material-icons tooltipped" data-position="bottom"
                data-tooltip="Please select a theme cholor that will be applied to all the application pages">
                help_outline</i>
                </p>

                <a class=" col s12 btn grey lighten-3 black-text"  style="margin-bottom: 15px;">
                  <strong>Select Theme Colour <input formControlName="themeColor" type="color"></strong> </a>
                  <br/><br/><br/><br/>
              </div>
            </div>
            <div class="row">
              <button type="submit" class="col s12 btn waves-effect"style="margin-bottom: 15px;" [ngStyle]="{'background-color': themeColor}" [disabled]="!appSettingsForm.valid">save app settings</button><br/>
            </div>
          </form>
        </div>
      </li>
      <li id="social" tabindex="0" class="collapse collapse-plus border border-base-300 bg-base-100 rounded-box">
        <input type="checkbox">
        <div class="collapse-title  bg-gray-50">Social Media Contact</div>
        <div class="collapse-content">
          <form (ngSubmit)="submitSocialForm(socialForm.value)"  [formGroup]="socialForm">
            <div class="row">
              <h6><strong>Please Enter The Links To Your Social Media Accounts</strong></h6>
              <br/>

              <div class="input-field class col s12">
                <i class="fa fa-facebook prefix text-blue font-bold"></i>
                <input formControlName="facebook" id="facebook-800" class="validate" type="text" maxlength="250" autocomplete="off" >
                <!-- <label for="facebook"> Facebook :  </label> -->
                <div *ngIf="(facebook.dirty || facebook.touched) && facebook.invalid">
                  <small *ngIf="facebook.errors.pattern" class="red-text"> Please enter a valid Url.
                  </small>
                </div>
              </div>

              <div class="input-field class col s12">
                <i class="fa fa-twitter prefix font-bold text-blue-800"></i>
                <input formControlName="twitter" id="twitter" class="validate" type="text" maxlength="250" autocomplete="off" >
                <label for="twitter"> Twitter :  </label>
                <div *ngIf="(twitter.dirty || twitter.touched) && twitter.invalid">
                  <small *ngIf="twitter.errors.pattern" class="red-text"> Please enter a valid Url.
                  </small>
                </div>
              </div>

              <div class="input-field class col s12">
                <i class="fa fa-instagram prefix font-bold" style="color: #3f729b;"></i>
                <input formControlName="instagram" id="instagram" class="validate" type="text" maxlength="250" autocomplete="off" >
                <label for="instagram"> Instagram : </label>

                <div *ngIf="(instagram.dirty || instagram.touched) && instagram.invalid">
                  <small *ngIf="instagram.errors.pattern" class="red-text"> Please enter a valid Url.
                  </small>
                </div>


              </div>

              <div class="input-field class col s12">
                <i class="fa fa-youtube prefix text-red-700"></i>
                <input formControlName="youtube" id="youtube" class="validate" type="text" maxlength="250" autocomplete="off" >
                <label for="youtube"> Youtube :  </label>
                <div *ngIf="(youtube.dirty || youtube.touched) && youtube.invalid">
                  <small *ngIf="youtube.errors.pattern" class="red-text"> Please enter a valid Url.
                  </small>
                </div>
              </div>
            </div>

            <div class="section"></div>

            <div class="row">
              <button *ngIf="!hasSocial" class='at-btn col s12 btn waves-effect success' style="margin-bottom: 15px;" type="submit" [disabled]="!socialForm.valid">
                <i class="material-icons right">cloud_done</i> Save Social Media Links
              </button>
              <button *ngIf="hasSocial" class='at-btn col s12 btn waves-effect' style="margin-bottom: 15px;" type="submit" [disabled]="!socialForm.valid">
                <i class="material-icons right">cloud_done</i> Update Social Media Links
              </button>
            </div>
          </form>
        </div>
      </li>
      <li id="landing" tabindex="0" class="collapse collapse-plus  border border-base-300 bg-base-100 rounded-box">
        <input type="checkbox">
        <div class="collapse-title  bg-gray-50">Landing Page Setting</div>
        <div class="collapse-content">
          <div class="row center">
            <h6><strong> Landing Page Preferences </strong></h6>
          </div>

          <div class="row" *ngIf="!preview">

              <div class="col s12 m12 ">


                  <div *ngIf="sectionsSetting && pageSections">
                      <table class="centered">
                          <thead>
                              <tr>
                                  <th></th>
                                  <th>Section</th>
                                  <th>Edit</th>
                                  <th>Move Up/Down</th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr *ngFor="let section of pageSections.sections">
                                  <td>
                                      <div class="switch">
                                          <label>
                                              <span>Off</span>
                                              <input type="checkbox" [checked]="section.isEnabled"
                                                  (change)="checkedChange(section)">
                                              <span class="lever"></span>
                                              <span>On</span>
                                          </label>
                                      </div>
                                  </td>
                                  <td>
                                      {{section.displayName}}
                                  </td>
                                  <td class="center">
                                      <button class="btn-small btn-flat blue" [disabled]="!section.isEnabled"
                                          (click)="editSection(section);"> <i class="material-icons white-text">edit</i></button>
                                  </td>
                                  <td class="center">
                                      <button class="btn-small btn-flat red"
                                          [disabled]="!section.isEnabled || section.name === 'Footer' || section.index >= pageSections.sections.length - 2"
                                          (click)="moveSection(section, 1);"> <i
                                              class="material-icons white-text">expand_more</i></button>

                                      <button class="btn-small btn-flat green" [disabled]="!section.isEnabled || section.name === 'Footer' || section.index === 0"
                                      (click)="moveSection(section, -1);"> <i class="material-icons white-text">expand_less</i></button>
                                  </td>

                              </tr>
                          </tbody>
                      </table>
                  </div>

                  <div *ngIf="sliderSettings && sliderRef">
                      <div class="row right-align">
                          <a (click)="addSlider()" [ngClass]="'waves-effect waves-light btn ' + themeColor"
                              style="margin-left: 1%;">
                              Add new slide<i class="material-icons left">add</i></a>
                      </div>

                      <div *ngIf="!sliderRef.options.length > 0">
                          <div class="row col s12">
                              <h6><strong class="truncate"> No Slides Create a New Slide </strong></h6>
                          </div>

                      </div>




                      <div class="row" *ngIf="sliderRef.options.length > 0">
          <h6 class="center"><strong class="blue-text">Please Click To Edit</strong></h6>

                          <div class="row slider col s12">
                              <ul class="slides">
                                  <li *ngFor="let s of sliderRef.options; let i = index;" style="cursor: pointer;">

                                      <img loading="lazy" [src]="s.imgUrl" (click)="sliderClicked(i)" />
                                      <div class="caption center-align">
                                          <h5 class="hide-on-med-and-up"  (click)="sliderClicked(i)" [ngStyle]="{'color': s.titleColor}">{{s.title}}</h5>
                                          <h3 class="hide-on-small-only show-on-medium-and-up"  (click)="sliderClicked(i)"
                                              [ngStyle]="{'color': s.titleColor}">
                                              {{s.title}}
                                          </h3>
                                          <h5 class=""  (click)="sliderClicked(i)" [ngStyle]="{'color': s.textColor}">
                                              {{s.text}}
                                          </h5>
                                      </div>

                                  </li>

                              </ul>
                          </div>

                          <div class="row">
                              <div class="input-field col s12 ">
                                  <i class="material-icons prefix teal-text text-darken-3">article</i>
                                  <input id="interval" onkeypress="isNumberKey(event)" type="number" min="1"
                                  [value]="sliderRef.interval/1000"    (input)="intervalChange($event.target.value)">
                                  <label for="interval">Inteval between sliders in seconds</label>
                              </div>
                          </div>

                      </div>


                  </div>


                  <div id="sliderModal" class="modal" *ngIf="sliderSettings && sliderRef && sliderRef.options.length > 0">
                      <div class="modal-content" *ngIf="sliderRef.options[slideRef]">
                          <h5 *ngIf="emptySlideOption">Create Slide</h5>


                          <div class="row col s12">

                              <div class="file-field input-field col s12" *ngIf="!sliderRef.options[slideRef].imgUrl">
                                  <div class="btn">
                                      <span>File</span>
                                      <input type="file"
                                          (change)="handleFileInput($event.target.files, sliderRef.options[slideRef])">
                                  </div>
                                  <div class="file-path-wrapper">
                                      <input class="file-path validate" type="text">
                                  </div>
                              </div>

                              <div class="input-field col s11 ">
                                  <i class="material-icons prefix teal-text text-darken-3">article</i>
                                  <input [(ngModel)]=" sliderRef.options[slideRef].title" type="text"
                                      style="text-transform: capitalize;">
                                  <label>Title</label>
                              </div>
                              <div class="input-field col s1 ">
                                  <input id="titleColor" [(ngModel)]=" sliderRef.options[slideRef].titleColor" type="color">
                              </div>

                              <div class="input-field col s11 ">

                                  <i class="material-icons prefix blue-text text-darken-3">article</i>
                                  <textarea class="materialize-textarea"
                                      [(ngModel)]="sliderRef.options[slideRef].text"></textarea>
                                  <label>Content</label>

                              </div>
                              <div class="input-field col s1 ">
                                  <input id="iconColor" [(ngModel)]="sliderRef.options[slideRef].textColor" type="color">
                              </div>

                              <div class="col s12">
                                  <p>Select Position</p>
                                  <div >
                                      <label *ngFor="let s of sliderRef.options; let i = index;">
                                          <input name="group1" type="radio" [checked] ="sliderRef.options[slideRef].index === i" (click) = "positionChanged(i)" />
                                          <span class="padding-left: 24px;"></span>
                                        </label>
                                  </div>


                              </div>


                          </div>

                      </div>

                      <div class="modal-footer col s12">

                          <div class="row col s12">

                                  <button class="btn col s12 green white-text" style="margin-bottom: 5px;"
                                  (click)="saveNewPositionAndChanges(sliderRef.options[slideRef]);">
                                  Save Changes<i class="material-icons right">cloud_upload</i></button>
                              <button class="btn col s12 red white-text" style="margin-bottom: 5px;"
                                  (click)="removeSlider(sliderRef.options[slideRef]);">
                                  Delete Slider<i class="material-icons right">delete</i></button>
                              <button class="btn col s12  blue white-text" (click)="closeModal();" style="margin-bottom: 5px;"
                                  *ngIf="sliderSettings && sliderRef">Cancel <i class="material-icons left">close</i></button>

                          </div>
                      </div>

                  </div>

                  <div *ngIf="newsSettings && newsRef">

                      <div class="row card-panel">
                          <div class="row">
                              <table>
                                  <tr>
                                      <td>
                                          <div class="row col s12">
                                              <span><strong>Change Backgound Color : </strong></span>
                                          </div>
                                      </td>
                                      <td class="center">
                                          <input id="iconColor" [(ngModel)]="newsRef.bgColor" type="color">
                                      </td>
                                  </tr>
                                  <tr>
                                      <td>
                                          <div class="input-field col s12 ">
                                              <i class="material-icons prefix teal-text text-darken-3">article</i>
                                              <input id="title" [(ngModel)]="newsRef.displayName" type="text">
                                              <label for="title">Section Title</label>
                                          </div>
                                      </td>
                                      <td class="center">
                                          <input id="iconColor" [(ngModel)]="newsRef.displayNameColor" type="color">
                                      </td>
                                  </tr>
                                  <tr>
                                      <td>
                                          <div class="input-field  col s12">
                                              <i class="material-icons prefix teal-text text-darken-3">article</i>
                                              <input id="title" [(ngModel)]="newsRef.displayLimit" type="text"
                                                  onkeypress="isNumberKey(event)" maxlength="1"
                                                  (input)="newsNumberChange($event.target.value)">
                                              <label for="title">Number Of Displayed News</label>
                                          </div>
                                      </td>
                                      <td>

                                      </td>
                                  </tr>
                              </table>


                          </div>
                      </div>

                      <div class="row" *ngIf="newsArray" [ngStyle]="{'background-color': newsRef.bgColor}">
                          <div class="row">
                              <br />
                              <h4 class="center" [ngStyle]="{'color': newsRef.displayNameColor}">
                                  <strong>{{newsRef.displayName}}</strong>
                              </h4>
                          </div>
                          <div class="col s12 m6 l4" *ngFor="let n of newsArray">
                              <div class="card small">
                                  <div class="card-content">
                                      <span class="card-title" [innerHTML]="n.title"></span>
                                      <p>
                                          {{truncateHTML(n.body,150)}}
                                      </p>
                                  </div>
                                  <div class="card-action">
                                      <a href="#">Read More</a>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>

                  <div *ngIf="eventsSettings && eventRef">


                      <div class="row card-panel">
                          <div class="row">
                              <table>
                                  <tr>
                                      <td>
                                          <div class="row col s12">
                                              <span><strong>Change Backgound Color : </strong></span>
                                          </div>
                                      </td>
                                      <td class="center">
                                          <input id="iconColor" [(ngModel)]="eventRef.bgColor" type="color">
                                      </td>
                                  </tr>
                                  <tr>
                                      <td>
                                          <div class="input-field col s12 ">
                                              <i class="material-icons prefix teal-text text-darken-3">article</i>
                                              <input id="title" [(ngModel)]="eventRef.displayName" type="text">
                                              <label for="title">Section Title</label>
                                          </div>
                                      </td>
                                      <td class="center">
                                          <input id="iconColor" [(ngModel)]="eventRef.displayNameColor" type="color">
                                      </td>
                                  </tr>

                              </table>


                          </div>
                      </div>

                      <div class="row" [ngStyle]="{'background-color': eventRef.bgColor}">
                          <div class="row">
                              <br />
                              <h4 class="center" [ngStyle]="{'color': eventRef.displayNameColor}">

                                  <strong>{{eventRef.displayName}}</strong>
                              </h4>

                          </div>

                          <div class="container">
                              <div class="row card-panel col s12" *ngIf="eventsModel">
                                  <app-event-calendar-view class="col s12" [model]="eventsModel" [isMember]="false">
                                  </app-event-calendar-view>
                              </div>
                          </div>
                      </div>
                  </div>

                  <div *ngIf="appStoreSettings && appStoreRef">


                      <div class="row card-panel">
                          <div class="row">
                              <table>
                                  <tr>
                                      <td>
                                          <div class="row col s12">
                                              <span><strong>Change Backgound Color : </strong></span>
                                          </div>
                                      </td>
                                      <td class="center">
                                          <input id="iconColor" [(ngModel)]="appStoreRef.bgColor" type="color">
                                      </td>
                                  </tr>
                                  <tr>
                                      <td>
                                          <div class="input-field col s12 ">
                                              <i class="material-icons prefix teal-text text-darken-3">article</i>
                                              <input id="title" [(ngModel)]="appStoreRef.displayName" type="text">
                                              <label for="title">Section Title</label>
                                          </div>
                                      </td>
                                      <td class="center">
                                          <input id="iconColor" [(ngModel)]="appStoreRef.displayNameColor" type="color">
                                      </td>
                                  </tr>
                                  <tr>
                                      <td>
                                          <div class="input-field col s12 ">
                                              <i class="material-icons prefix green-text text-darken-3">link</i>
                                              <input id="googleUrl" [(ngModel)]="appStoreRef.googlePlayUrl" type="text">
                                              <label for="googleUrl">Google Play Url</label>
                                          </div>
                                      </td>
                                  </tr>
                                  <tr>
                                      <td>
                                          <div class="input-field col s12 ">
                                              <i class="material-icons prefix blue-text text-darken-3">link</i>
                                              <input id="appleUrl" [(ngModel)]="appStoreRef.appleStoreUrl" type="text">
                                              <label for="appleUrl">Apple Store Url</label>
                                          </div>
                                      </td>
                                  </tr>
                              </table>


                          </div>
                      </div>
                      <div class="row" [ngStyle]="{'background-color': appStoreRef.bgColor}">
                          <div class="row">
                              <br />
                              <h4 class="center" [ngStyle]="{'color': appStoreRef.displayNameColor}">
                                  <strong style="text-transform: capitalize;">{{appStoreRef.displayName}}</strong>
                              </h4>

                          </div>

                          <div class="row col s12 center">
                              <a [href]="appStoreRef.googlePlayUrl" target="_blank" style="margin-right: 15px;">
                                  <img loading="lazy" class="responsive-img " data-src="https://img.icons8.com/color/144/000000/google-play.png" />
                              </a>
                              <a [href]="appStoreRef.appleStoreUrl" target="_blank">
                                  <img loading="lazy" class="responsive-img "
                                      data-src="https://img.icons8.com/color/144/000000/apple-app-store--v3.png" />
                              </a>
                          </div>
                      </div>
                  </div>

                  <div *ngIf="socialMediaSettings && socialMediaRef">


                      <div class="row card-panel">
                          <div class="row">
                              <table>
                                  <tr>
                                      <td>
                                          <div class="row col s12">
                                              <span><strong>Change Backgound Color : </strong></span>
                                          </div>
                                      </td>
                                      <td class="center">
                                          <input id="iconColor" [(ngModel)]="socialMediaRef.bgColor" type="color">
                                      </td>
                                  </tr>
                                  <tr>
                                      <td>
                                          <div class="input-field col s12 ">
                                              <i class="material-icons prefix teal-text text-darken-3">article</i>
                                              <input id="title" [(ngModel)]="socialMediaRef.displayName" type="text">
                                              <label for="title">Section Title</label>
                                          </div>
                                      </td>
                                      <td class="center">
                                          <input id="iconColor" [(ngModel)]="socialMediaRef.displayNameColor" type="color">
                                      </td>
                                  </tr>

                              </table>

                              <div class="row center col s12">
                                  <div class="section"></div>
                                  <a [routerLink]="['/admin/contact-settings']" class="btn col s12 center green"> Update Social
                                      Media Links <i class="material-icons right">edit</i></a>
                              </div>
                          </div>
                      </div>

                      <div class="row" [ngStyle]="{'background-color': socialMediaRef.bgColor}">
                          <div class="row">
                              <br />
                              <h4 class="center" [ngStyle]="{'color': socialMediaRef.displayNameColor}">
                                  <strong style="text-transform: capitalize;">{{socialMediaRef.displayName}}</strong>
                              </h4>

                          </div>

                          <div class="row col s12 center" *ngIf="socialMediaModel">
                              <a [href]="socialMediaModel.facebook" *ngIf="socialMediaModel.facebook" target="_blank"
                                  style="margin-right: 15px;">
                                  <img loading="lazy" class="responsive-img " data-src="https://img.icons8.com/color/144/000000/facebook-new.png" />
                              </a>
                              <a [href]="socialMediaModel.twitter" *ngIf="socialMediaModel.twitter" target="_blank"
                                  style="margin-right: 15px;">
                                  <img loading="lazy" class="responsive-img " data-src="https://img.icons8.com/fluency/144/000000/twitter.png" />
                              </a>
                              <a [href]="socialMediaModel.instagram" *ngIf="socialMediaModel.instagram" target="_blank"
                                  style="margin-right: 15px;">
                                  <img loading="lazy" class="responsive-img "
                                      data-src="https://img.icons8.com/color/144/000000/instagram-new--v2.png" />
                              </a>
                              <a [href]="socialMediaModel.youtube" *ngIf="socialMediaModel.youtube" target="_blank"
                                  style="margin-right: 15px;">
                                  <img loading="lazy" class="responsive-img " data-src="https://img.icons8.com/fluency/144/000000/youtube-play.png" />
                              </a>
                          </div>

                          <div class="row col s12 center" *ngIf="!socialMediaModel">
                              <div class="row col s12 card-panel">
                                  <p class="center">Please Update Your Social Media Links ! </p>
                              </div>
                          </div>

                      </div>
                  </div>

                  <div *ngIf="footerSettings && footerRef">


                      <div class="row card-panel">
                          <div class="row">
                              <table>
                                  <tr>
                                      <td>

                                          <span><strong>Change Backgound Color : </strong></span>


                                          <input id="iconColor" [(ngModel)]="footerRef.bgColor" type="color">
                                      </td>
                                  </tr>
                                  <tr>
                                      <td>

                                          <span><strong>Change Text Color : </strong></span>


                                          <input id="iconColor" [(ngModel)]="footerRef.textColor" type="color">
                                      </td>
                                  </tr>
                                  <tr>
                                      <td>
                                          <div class="input-field col s12 ">
                                              <i class="material-icons prefix green-text text-darken-3">email</i>
                                              <input id="address" [(ngModel)]="footerRef.address" type="text">
                                              <label for="address">Address</label>
                                          </div>
                                      </td>
                                  </tr>
                                  <tr>
                                      <td>
                                          <div class="input-field col s12 ">
                                              <i class="material-icons prefix purple-text text-darken-3">location_on</i>
                                              <input id="pcode"
                                                  [(ngModel)]="footerRef.postalCode" type="text">
                                              <label for="pcode">Postal Code</label>
                                          </div>
                                      </td>
                                  </tr>
                                  <tr>
                                      <td>
                                          <div class="input-field col s12 ">
                                              <i class="material-icons prefix pink-text text-darken-3">location_on</i>
                                              <input id="city" [(ngModel)]="footerRef.city" type="text">
                                              <label for="city">City</label>
                                          </div>
                                      </td>
                                  </tr>
                                  <tr>
                                      <td>
                                          <div class="input-field col s12 ">
                                              <i class="material-icons prefix red-text text-darken-3">location_on</i>
                                              <input id="prov" [(ngModel)]="footerRef.province" type="text">
                                              <label for="prov">Province</label>
                                          </div>
                                      </td>
                                  </tr>
                                  <tr>
                                      <td>
                                          <div class="input-field col s12 ">
                                              <i class="material-icons prefix teal-text text-darken-3">email</i>
                                              <input id="email" [(ngModel)]="footerRef.contactEmail" type="text">
                                              <label for="email">Info / Support Email</label>
                                          </div>
                                      </td>

                                  </tr>
                                  <tr>
                                      <td>
                                          <div class="input-field col s12 ">
                                              <i class="material-icons prefix blue-text text-darken-3">phone</i>
                                              <input id="phone" [(ngModel)]="footerRef.phone"
                                                  type="text">
                                              <label for="phone">Phone</label>
                                          </div>
                                      </td>
                                  </tr>
                                  <tr>
                                      <td>
                                          <div class="input-field col s12 ">
                                              <i class="material-icons prefix orange-text text-darken-3">fax</i>
                                              <input id="fax" [(ngModel)]="footerRef.fax"
                                                  type="text">
                                              <label for="fax">Fax</label>
                                          </div>
                                      </td>
                                  </tr>
                                  <tr>
                                      <td>
                                          <div class="input-field col s12 ">
                                              <i class="material-icons prefix orange-text text-darken-3">phone</i>
                                              <input id="tollfree"
                                                  [(ngModel)]="footerRef.tollFree" type="text">
                                              <label for="tollfree">Toll Free</label>
                                          </div>
                                      </td>
                                  </tr>
                              </table>


                          </div>
                      </div>

                      <div class="row">
                          <footer class="page-footer" [ngStyle]="{'background-color': footerRef.bgColor}">
                              <div class="container">
                                  <div class="row">
                                      <div class="col l6 s12">
                                          <h5 class="white-text">{{footerRef.nationName}}</h5>
                                          <p class="grey-text text-lighten-4">
                                              {{footerRef.address}} <br />

                                              {{footerRef.postalCode + ' ' + footerRef.city + ' ' + footerRef.province}}
                                          </p>
                                      </div>
                                      <div class="col l4 offset-l2 s12">
                                          <h5 class="white-text">Contact</h5>
                                          <ul>
                                              <li><a class="grey-text text-lighten-3">Phone: {{footerRef.phone}}</a></li>
                                              <li><a class="grey-text text-lighten-3">Fax: {{footerRef.fax}}</a></li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                              <div class="footer-copyright">
                                  <div class="container">
                                      © Copyright 2021 {{footerRef.nationName}} App All Rights Reserved
                                      <a class="grey-text text-lighten-4 right">Toll free: 1 877 517 6527</a>
                                  </div>
                              </div>
                          </footer>
                      </div>
                  </div>


                  <div class="row">
                      <button class="btn col s12 green white-text" (click)="addSlider();" style="margin-bottom: 5px;"
                          *ngIf="sliderSettings && sliderRef">Add New Slider <i class="material-icons right">add</i></button>
                      <button class="btn col s12 green white-text" (click)="previewChanges();" style="margin-bottom: 5px;">Preview
                              Changes <i class="material-icons right">preview</i></button>
                      <button class="btn col s12 blue white-text" style="margin-bottom: 5px;" (click)="saveChanges();">Save
                          Changes <i class="material-icons right">save</i></button>
                      <button class="btn col s12 teal white-text" style="margin-bottom: 5px;" *ngIf="!sectionsSetting"
                          (click)="landingPageBackClicked();">Back To Previous Page <i
                              class="material-icons left">arrow_back_ios_new</i></button>
                  </div>
              </div>
          </div>

          <div *ngIf="preview">
              <div class="row right-align">
                  <a (click)="previewChanges()" [ngClass]="'waves-effect waves-light btn ' + themeColor" style="margin-left: 1%;">
                      Go Back To Edit <i class="material-icons left">arrow_back_ios_new</i></a>
                  <a (click)="saveChanges()" class="waves-effect waves-light btn green" style="margin-left: 1%;">
                      Save Changes <i class="material-icons right">save</i></a>

              </div>

              <div class="row" *ngFor="let section of pageSections.sections">
                  <!-- ******************** Slider ************************** -->
                  <div class="row" *ngIf="section.name === 'Slider' && section.isEnabled">
                      <div class="slider">
                          <ul class="slides">
                              <li *ngFor="let s of section.options">


                                  <img loading="lazy" [src]="s.imgUrl" />

                                  <div class="caption center-align">
                                      <h5 class="hide-on-med-and-up" [ngStyle]="{'color': s.titleColor}">{{s.title}}</h5>
                                      <h3 class="hide-on-small-only show-on-medium-and-up" [ngStyle]="{'color': s.titleColor}">
                                          {{s.title}}
                                      </h3>
                                      <h5 class="" [ngStyle]="{'color': s.textColor}">{{s.text}}
                                      </h5>

                                  </div>

                              </li>

                          </ul>
                      </div>
                      <div class="row col s12">
                          <div class="section"></div>
                          <div class="row col s12 m6">
                              <a class="btn-large blue col s12 white-text"><i class="material-icons"
                                      style="margin-right: 15px;">person_add</i> Request To
                                  Join</a>
                          </div>

                          <div class="row col s12 m6">
                              <a class="btn-large green col s12 white-text"><i class="material-icons"
                                      style="margin-right: 15px;">lock</i> Login</a>
                          </div>
                      </div>
                  </div>

                  <!-- ******************** News ************************** -->
                  <div class="row" *ngIf="section.name === 'News' && section.isEnabled"
                      [ngStyle]="{'background-color': section.bgColor}">
                      <div class="row">
                          <br />
                          <h5 class="center" [ngStyle]="{'color': section.displayNameColor}">
                              <strong>{{section.displayName}}</strong>
                          </h5>
                      </div>
                      <div class="col s12 m6 l4" *ngFor="let n of newsArray">
                          <div class="card medium hoverable">
                              <div class="card-content">
                                  <div class="row truncate center">
                                      <a><strong>
                                              <h6 [innerHTML]="n.title"></h6>
                                          </strong></a>
                                  </div>

                                  <p *ngIf="n.type === 'Post'">
                                      {{truncateHTML(n.body,450)}}
                                  </p>
                                  <div class="row" *ngIf="n.type === 'Photo'">
                                      <img loading="lazy" class="responsive-img" [src]="n.imgLink" />
                                  </div>
                                  <div class="row center" *ngIf="n.type === 'Video'">
                                      <!-- <img class="responsive-img center" src="https://img.icons8.com/ios-filled/100/000000/video.png"/> -->
                                      <div class="row video-container">
                                          <div [innerHTML]="n.iframe"></div>
                                      </div>
                                  </div>
                                  <div class="row center " *ngIf="n.type === 'PDF'">
                                      <div class="section"></div>
                                      <img loading="lazy" class="responsive-img center"
                                          data-src="https://img.icons8.com/ios/100/000000/pdf-2--v1.png" />
                                      <p>PDF File </p>
                                  </div>
                                  <div class="row center " *ngIf="n.type === 'Link'">
                                      <div class="section"></div>
                                      <img loading="lazy" class="responsive-img center"
                                          data-src="https://img.icons8.com/ios/100/000000/internet--v1.png" />
                                      <p>External Link </p>
                                  </div>
                              </div>
                              <div class="card-action">
                                  <a class="blue-text">Read More</a>
                              </div>
                          </div>
                      </div>

                      <div class="row col s12 center">
                          <a [ngStyle]="{color: section.displayNameColor, text_decoration: 'underline'}">
                              <h6><strong>View All {{section.displayName}}</strong></h6>
                          </a>
                      </div>
                  </div>
                  <!-- ******************** Events ************************** -->
                  <div class="row" *ngIf="section.name === 'Events' && section.isEnabled"
                      [ngStyle]="{'background-color': section.bgColor}">
                      <div class="row">
                          <br />
                          <h5 class="center" [ngStyle]="{'color': section.displayNameColor}">
                              <strong>{{section.displayName}}</strong>
                          </h5>

                      </div>

                      <div class="container">
                          <div class="row card-panel col s12" *ngIf="eventsModel">
                              <app-event-calendar-view class="col s12" [model]="eventsModel" [isMember]="false">
                              </app-event-calendar-view>
                          </div>
                      </div>

                      <div class="row col s12 center">
                          <a [routerLink]="['/events-calendar']" [ngStyle]="{'color': section.displayNameColor}">
                              <h6><strong>View All {{section.displayName}}</strong></h6>
                          </a>
                      </div>
                  </div>

                  <!-- ******************** Services ************************** -->
                  <div class="row" *ngIf="section.name === 'Services' && section.isEnabled"
                      [ngStyle]="{'background-color': section.bgColor}">
                      <div class="row">
                          <br />
                          <h5 class="center" [ngStyle]="{'color': section.displayNameColor}">
                              <strong>{{section.displayName}}</strong>
                          </h5>
                      </div>
                      <div class="col s12 m6 l4" *ngFor="let n of servicesArray">
                          <div class="card medium hoverable">
                              <div class="card-content">
                                  <div class="row truncate center">
                                      <a ><strong><h6 [innerHTML]="n.serviceName"></h6></strong></a>
                                  </div>

                                  <p *ngIf="n.imgLink === '' && n.videoLink === '' && n.pdfLink === ''">
                                      {{truncateHTML(n.serviceDescription,450)}}
                                  </p>
                                  <div class="row" *ngIf="n.imgLink !== ''">
                                      <img loading="lazy" class="responsive-img" [src]="n.imgLink"/>
                                  </div>
                                  <div class="row center" *ngIf="n.videoLink !== ''">
                                      <!-- <img class="responsive-img center" src="https://img.icons8.com/ios-filled/100/000000/video.png"/> -->
                                      <div class="row video-container">
                                              <div [innerHTML]="n.iframe"></div>
                                      </div>
                                  </div>
                                  <div class="row center " *ngIf="n.pdfLink !== ''">
                                      <div class="section"></div>
                                      <img  loading="lazy" class="responsive-img center" data-src="https://img.icons8.com/ios/100/000000/pdf-2--v1.png"/>
                                      <p>PDF File </p>
                                  </div>
                                  <div class="row center " *ngIf="n.type === 'Link'">
                                      <div class="section"></div>
                                      <img loading="lazy" class="responsive-img center"  data-src="https://img.icons8.com/ios/100/000000/internet--v1.png"/>
                                      <p>External Link </p>
                                  </div>
                              </div>
                              <div class="card-action">
                                  <a  class="blue-text">Read More</a>
                                </div>
                          </div>
                      </div>

                      <div class="row col s12 center">
                          <a  [ngStyle]="{color: section.displayNameColor, text_decoration: 'underline'}"><h6><strong>View All {{section.displayName}}</strong></h6></a>
                      </div>
                  </div>

                  <!-- ******************** Download App ************************** -->
                  <div class="row" *ngIf="section.name === 'AppStore' && section.isEnabled"
                      [ngStyle]="{'background-color': section.bgColor}">

                      <div class="row center">
                          <br />
                          <h5 class="center" [ngStyle]="{'color': section.displayNameColor}">
                              <strong style="text-transform: capitalize;">{{section.displayName}}</strong>
                          </h5>


                          <a target="_blank" style="margin-right: 15px;">
                              <img loading="lazy" class="responsive-img " data-src="https://img.icons8.com/color/144/000000/google-play.png" />
                          </a>
                          <a target="_blank">
                              <img loading="lazy" class="responsive-img "
                                  data-src="https://img.icons8.com/color/144/000000/apple-app-store--v3.png" />
                          </a>
                      </div>

                  </div>



                  <!-- ******************** ******* ************************** -->



                  <!-- ******************** Social Media ************************** -->
                  <div class="row" *ngIf="section.name === 'SocialMedia' && section.isEnabled"
                      [ngStyle]="{'background-color': section.bgColor}">

                      <div class="row center">
                          <br />
                          <h5 class="center" [ngStyle]="{'color': section.displayNameColor}">
                              <strong style="text-transform: capitalize;">{{section.displayName}}</strong>
                          </h5>


                          <div class="row col s12 center" *ngIf="socialMediaModel">
                              <a *ngIf="socialMediaModel.facebook" target="_blank" style="margin-right: 15px;">
                                  <img loading="lazy" class="responsive-img " data-src="https://img.icons8.com/color/144/000000/facebook-new.png" />
                              </a>
                              <a *ngIf="socialMediaModel.twitter" target="_blank" style="margin-right: 15px;">
                                  <img loading="lazy" class="responsive-img " data-src="https://img.icons8.com/fluency/144/000000/twitter.png" />
                              </a>
                              <a *ngIf="socialMediaModel.instagram" target="_blank" style="margin-right: 15px;">
                                  <img loading="lazy" class="responsive-img "
                                      data-src="https://img.icons8.com/color/144/000000/instagram-new--v2.png" />
                              </a>
                              <a *ngIf="socialMediaModel.youtube" target="_blank" style="margin-right: 15px;">
                                  <img loading="lazy" class="responsive-img " data-src="https://img.icons8.com/fluency/144/000000/youtube-play.png" />
                              </a>
                          </div>
                      </div>

                  </div>
                  <!-- ******************** ******* ************************** -->


                  <!-- ******************** Footer ************************** -->
                  <div class="row" *ngIf="section.name === 'Footer' && section.isEnabled">

                      <footer class="page-footer" [ngStyle]="{'background-color': section.bgColor}">
                          <div class="container">
                              <div class="row">
                                  <div class="col l6 s12">
                                      <h5 class="white-text"> {{section.nationName}} </h5>
                                      <p class="grey-text text-lighten-4">
                                          {{section.address}}

                                          {{section.city + ' ' + section.province + ' ' + section.postalCode}}
                                      </p>
                                  </div>
                                  <div class="col l4 offset-l2 s12">
                                      <h5 class="white-text">Contact</h5>
                                      <ul>
                                          <li><a class="grey-text text-lighten-3">Phone: {{section.phone}}</a></li>
                                          <li><a class="grey-text text-lighten-3">Fax: {{section.fax}}</a></li>
                                      </ul>
                                  </div>
                              </div>
                          </div>
                          <div class="footer-copyright">
                              <div class="container">
                                  © Copyright 2021 {{section.nationName}} App All Rights Reserved
                              </div>
                          </div>
                      </footer>

                  </div>
                  <!-- ******************** ******* ************************** -->
              </div>

              <div class="row">

                  <button class="btn col s12 blue white-text" style="margin-bottom: 5px;" (click)="saveChanges();">Save Changes
                      <i class="material-icons right">save</i></button>

                  <button class="btn col s12 teal white-text" style="margin-bottom: 5px;" (click)="previewChanges();">Back To Edit
                      <i class="material-icons left">arrow_back_ios_new</i></button>
              </div>
          </div>
        </div>
      </li>
      <li id="areas" tabindex="0" class="collapse collapse-plus  border border-base-300 bg-base-100 rounded-box">
        <input type="checkbox">
        <div class="collapse-title  bg-gray-50">Manage areas of interest</div>
        <div class="collapse-content">
          <div>
            <div *ngIf="areasListModel && showMsg">
              <table class="striped centred">
                <thead>
                  <th class="center">
                    Please Select From The List
                  </th>
                  <th class="center">
                    Intrested Members
                  </th>
                </thead>
                <tbody>
                  <tr *ngFor="let area of areasListModel">
                    <td class="center">
                      <a class="hoverable" (click)="selectArea(area)">
                        <span><strong>{{area.name}}</strong> </span>
                      </a>
                    </td>
                    <td class="center">
                      <strong class="green-text text-darken-1">
                        {{area.membersList.length }}</strong
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div *ngIf="(!areasListModel || areasListModel.length === 0) && showMsg">
              <div class="row card-panel">
                <p>
                  <strong class="red-text">
                    Please Update Your Areas Of Interest List</strong
                  >
                </p>
              </div>
            </div>

          </div>
          <div class="row">
            <button
            (click)="addArea();"
            type="button"
            class="col s12 btn waves-effect green white-text"
            style="margin-bottom: 15px;"
          >
            <i class="material-icons right">cloud</i> New Area Of Interest
          </button>

          </div>
          <div id="editModal" class="modal">
            <form
              [formGroup]="areaOfInterestForm"
              (ngSubmit)="manageAreasOfInterest(areaOfInterestForm.value)"
            >
              <div class="modal-content">
                <h6><strong> New Area Of Interest </strong></h6>
                <br />
                <div class="">
                  <div class="input-field col s12">
                    <i class="material-icons prefix red-text">text_rotation_none</i>
                    <input
                      id="name"
                      type="text"
                      formControlName="name"
                      required
                      class="validate"
                      autocomplete="off"
                    />
                    <label for="name">Area Of Interest Name</label>
                    <div *ngIf="(name.dirty || name.touched) && name.invalid">
                      <small *ngIf="name.errors.required" class="red-text">
                        The Area Of Interest Name is required.
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="submit"
                  [disabled]="!areaOfInterestForm.valid"
                  class="green btn-flat right white-text"
                  style="margin-right: 10px;"
                >
                  <span *ngIf="selectedModel">Update</span>
                  <span *ngIf="!selectedModel"> Save </span>
                </button>
                <button
                  *ngIf="selectedModel"
                  type="button"
                  class="red btn-flat right white-text"
                  style="margin-right: 10px;"
                >
                  Delete
                </button>
                <a
                  class="modal-close grey lighten-1 btn-flat right"
                  style="margin-right: 10px;"
                  >Cancel</a
                >
              </div>
            </form>
          </div>
        </div>
      </li>
      <li id="admin" tabindex="0" class="collapse collapse-plus  border border-base-300 bg-base-100 rounded-box">
        <input type="checkbox">
        <div class="collapse-title  bg-gray-50">Admin Menu</div>
        <div class="collapse-content">
          <div class="row col s12">
            <div class="col l1 "></div>
            <div class="col s12 m12 l10">

                <!-- ---------------------- Menu Items -------------------- -->
                <div *ngIf="isMenuSettings && menu">
                    <table class="centered">
                        <thead>
                            <tr>
                                <th>Visible</th>
                                <th>Menu Item</th>
                                <th>Edit</th>
                                <th>Move Up / Down</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let section of menu.items">
                                <td>
                                    <div class="switch">
                                        <label>
                                            <span>No</span>
                                            <input type="checkbox" [checked]="section.isEnabled" (change)="checkedChangeAdminMenu(section)">
                                            <span class="lever"></span>
                                            <span>Yes</span>
                                        </label>
                                    </div>
                                </td>
                                <td>
                                    {{section.displayName}}
                                </td>
                                <td class="center">
                                    <button class="btn-small btn-flat blue" [disabled]="!section.isEnabled"
                                        (click)="editSectionAdminMenu(section);"> <i class="material-icons white-text">edit</i></button>
                                </td>
                                <td class="center">
                                    <button class="btn-small btn-flat green" [disabled]="!section.isEnabled || section.name === 'Logout' || section.index === 0"
                                    (click)="moveSectionAdminMenu(section, -1);" > <i class="material-icons white-text">expand_less</i></button>
                                    <button class="btn-small btn-flat red" [disabled]="!section.isEnabled || section.name === 'Logout'"
                                    (click)="moveSectionAdminMenu(section, 1);"> <i class="material-icons white-text">expand_more</i></button>
                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>

                <!-- ---------------------- Edit Items -------------------- -->
                <div *ngIf="isEditItem && selectedItem">

                    <div class="row">
                        <div class="col s3 m4 l5"></div>
                        <div class="col s6 m4 l3">
                            <div class="card hoverable" [ngStyle]="{'background-color': selectedItem.bgColor, 'height': '95px'}">
                              <div class="card-content">
                              <div class="row center">
                                <i class="material-icons" [ngStyle]="{'color': selectedItem.iconColor}">{{selectedItem.icon}}</i>
                                <br/>
                                <strong [ngStyle]="{'color': selectedItem.textColor}" class="truncate">{{selectedItem.displayName}}</strong>
                              </div>
                              </div>
                            </div>
                          </div>
                          <div class="col s3 m4 l4"></div>
                    </div>

                    <div class="row card-panel">
                        <div class="row">
                            <table>
                                <tr>
                                    <td>
                                        <div class="row col s12">
                                            <span><strong>Change Button Color : </strong></span>
                                        </div>
                                    </td>
                                    <td class="center">
                                        <input id="bgColor" [(ngModel)]="selectedItem.bgColor" type="color">
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="row col s12">
                                            <span><strong>Change Icon Color : </strong></span>
                                        </div>
                                    </td>
                                    <td class="center">
                                        <input id="iconColor" [(ngModel)]="selectedItem.iconColor" type="color">
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="input-field col s12 ">
                                            <i class="material-icons prefix teal-text text-darken-3">article</i>
                                            <input id="title" [(ngModel)]="selectedItem.displayName" type="text">
                                            <label for="title">Button Name</label>
                                        </div>
                                    </td>
                                    <td class="center">
                                        <input id="titleColor" [(ngModel)]="selectedItem.textColor" type="color">
                                    </td>
                                </tr>

                            </table>


                        </div>
                    </div>


                </div>

                <div class="row">
                    <button class="btn col s12 blue white-text" style="margin-bottom: 5px;" (click)="saveChangesAdminMenu();">Save Changes <i class="material-icons right">save</i></button>
                    <button class="btn col s12 teal white-text" style="margin-bottom: 5px;" *ngIf="!isMenuSettings" (click)="backClickedAdminMenu();">Back To Previous Page <i class="material-icons left">arrow_back_ios_new</i></button>
                </div>
            </div>
            <div class="col l1 "></div>
        </div>
        </div>
      </li>
      <li id="contact" tabindex="0" class="collapse collapse-plus  border border-base-300 bg-base-100 rounded-box" *ngIf="isBusinessApp">
        <input type="checkbox">
        <div class="collapse-title  bg-gray-50">Contact Info</div>
        <div class="collapse-content">
          <form [formGroup]="contactAndBasicInfoForm" (submit)="addContactAndBasicInfo(contactAndBasicInfoForm.value)">

            <div class="input-field col s12 m6">
              <i class="material-icons prefix">email</i>
              <input id="email" type="text" formControlName='email' class="validate"/>
              <label class="active" for="email" >Email </label>
            </div>

            <div class="input-field col s12 m6">
              <i class="material-icons prefix">phone_iphone</i>
              <input id="phoneno" type="text" formControlName='mobilePhone' class="validate"/>
              <label class="active" for="phoneno" >Mobile </label>
            </div>

            <div class="input-field col s12 m6">
              <i class="material-icons prefix  ">call</i>
              <input id="workPhone1" type="text" formControlName='workPhone1' class="validate"  />
              <label class="active" for="workPhone1" >Work Phone 1 </label>
            </div>

            <div class="input-field col s12 m6">
              <i class="material-icons prefix ">public</i>
              <input id="website" type="text" formControlName='website' class="validate"/>
              <label class="active" for="webiste" >Website </label>
            </div>

            <div class="input-field col s12 m6">
              <i class="material-icons prefix ">location_on</i>
              <input id="workPhone1" type="text" formControlName='workLocation' class="validate" />
              <label class="active" for="workPhone1" >Work Location </label>
            </div>


            <div class="row">
              <button class="btn col s12" type="submit" style="margin-right: 15px;" [disabled]="!contactAndBasicInfoForm.valid">Save Changes</button>
            </div>

          </form>
        </div>
      </li>


      <li id="faq" tabindex="0" class="collapse collapse-plus  border border-base-300 bg-base-100 rounded-box" *ngIf="isBusinessApp">
        <input type="checkbox">
        <div class="collapse-title  bg-gray-50">FAQ</div>
        <div class="collapse-content">
          <form [formGroup]="FaqForm" (submit)="addContactAndBasicInfo(contactAndBasicInfoForm.value)">

            <div class="input-field col s12 m6">
              <i class="material-icons prefix">email</i>
              <input id="email" type="text" formControlName='email' class="validate"/>
              <label class="active" for="email" >Email </label>
            </div>

            <div class="input-field col s12 m6">
              <i class="material-icons prefix">phone_iphone</i>
              <input id="phoneno" type="text" formControlName='mobilePhone' class="validate"/>
              <label class="active" for="phoneno" >Mobile </label>
            </div>

            <div class="row">
              <button class="btn col s12" type="submit" style="margin-right: 15px;" [disabled]="!contactAndBasicInfoForm.valid">Save Changes</button>
            </div>

          </form>
        </div>
      </li>

    </ul>

  </div>

</div>
