import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation, Injectable, ChangeDetectorRef, TemplateRef } from '@angular/core';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { UserService } from 'src/app/services/firebase/user.service';
import { Location, formatDate} from '@angular/common';
import { ScheduleService } from 'src/app/services/firebase/employee-schedule.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppointmentService } from 'src/app/services/firebase/employee-appointment.service';
import { EmployeeServicesService } from 'src/app/services/firebase/employee-services.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { EmployeeAppointmentModel } from 'src/app/models/AppointmentModel';
import { isAfter, isBefore, addMinutes, getDay } from 'date-fns';
import { CalendarEvent, CalendarView, CalendarDateFormatter, DateFormatterParams, CalendarWeekViewBeforeRenderEvent, CalendarMonthViewDay
} from 'angular-calendar';
import {
  subMonths, addMonths, addDays, addWeeks, subDays, subWeeks, startOfMonth,
  endOfMonth, startOfWeek, endOfWeek, startOfDay, endOfDay } from 'date-fns';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { HelperService } from 'src/app/services/helper/helper';
import { FCMPushNotificationsService } from 'src/app/services/firebase/FCMPushNotifications.service';
import { EmailService } from 'src/app/services/api/email.service';
import { SMSService } from 'src/app/services/api/sms.service';
import { SMSModel } from 'src/app/models/SMSModel';
import { TaskService } from 'src/app/services/firebase/task.service';
import { takeUntil } from 'rxjs/operators';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { NotificationModel } from 'src/app/models/Messages.Model';
import { environment } from 'src/environments/environment';

@Injectable()
export class CustomDateFormatter extends CalendarDateFormatter {
  public weekViewHour({ date, locale }: DateFormatterParams): string {
    return formatDate(date, 'h:mm a', locale);
  }
  public weekViewColumnHeader({ date, locale }: DateFormatterParams): string {
    return formatDate(date, 'EEE', locale);
  }
}

type CalendarPeriod = 'day' | 'week' | 'month';
function addPeriod(period: CalendarPeriod, date: Date, amount: number): Date {
  return {
    day: addDays,
    week: addWeeks,
    month: addMonths,
  }[period](date, amount);
}

function subPeriod(period: CalendarPeriod, date: Date, amount: number): Date {
  return {
    day: subDays,
    week: subWeeks,
    month: subMonths,
  }[period](date, amount);
}

function startOfPeriod(period: CalendarPeriod, date: Date): Date {
  return {
    day: startOfDay,
    week: startOfWeek,
    month: startOfMonth,
  }[period](date);
}

function endOfPeriod(period: CalendarPeriod, date: Date): Date {
  return {
    day: endOfDay,
    week: endOfWeek,
    month: endOfMonth,
  }[period](date);
}
@Component({
  selector: 'app-book-appointment',
  templateUrl: './book-appointment.component.html',
  styleUrls: ['./book-appointment.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      .cal-week-view .cal-time-events .cal-day-column {
        margin-right: 10px;
      }

      .cal-week-view .cal-hour {
        width: calc(100% + 10px);
      }
      .cal-disabled {
      background-color: #eee;
      pointer-events: none;
      }
      .cal-disabled .cal-day-number {
        opacity: 0.1;
      }

    `,
  ],
  providers: [{
  provide: CalendarDateFormatter,
  useClass: CustomDateFormatter,
  }]
})

export class BookAppointmentComponent implements OnInit {

  private destroy$ = new Subject<void>();
  daysInWeek = 7;
  loggedUser: any;
  employeeId: any;
  employeeSchedule: any;
  excludeDays: any;
  appointmentsList = [];
  serviceId: any;
  service: any;
  selectedServiceTime: any;
  allEmployees = [];
  selectedEmployee: any;
  schedule: any;
  view: CalendarView = CalendarView.Week;
  CalendarView = CalendarView;
  events: CalendarEvent[] = [];
  viewDate: Date = new Date();
  refresh = new Subject<void>();
  weekStartOn = getDay(new Date());
  minDate: Date = subDays(new Date(), 1);
  maxDate: Date = addMonths(new Date(), 1);
  employeeServices: any = [];
  createAppointmentForm: UntypedFormGroup;
  selectedService = { serviceName: 'Click to select a service' };
  unAvailableTimeSlot: any;
  unAvailableTimes = [];
  scheduleModalOpen = false;
  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  serviceDuration = 15;
  selectedServiceEndTime: any;
  prevBtnDisabled = false;
  nextBtnDisabled = false;
  minStartingTime = 9;
  maxEndingTime = 17;
  isAdmin = false;
  allUsers = [];
  appointedFor: any;
  notifSettings
  apptSettings:any;
  allServices:any  = [];
  allTasks= [];
  serviceUnder: any;
  rebookedAppointment: any;
  appSettings: any;
  employeeScheduleIDs = [];
  availableEmployees = [];
  userDetail: any;
  availablePhone: any;
  createModalOpen = false;

  isNationApp = environment.firstNation.Origin == "FNLeaders" ? true : false;
  domain = environment.firstNation.name;
  domainName = this.isNationApp ? "https://" + this.domain + ".mynation.app" : "https://" + this.domain + ".goingmobile.app";
  


  constructor(
    private location: Location,
    private authService: AuthService,
    private scheduleService: ScheduleService,
    private appointmentService: AppointmentService,
    private route: ActivatedRoute,
    private empService: EmployeeServicesService,
    private fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private userService: UserService,
    private helperService: HelperService,
    private appSettingsService: AppSettingsService,
    private pushNotificationsService: FCMPushNotificationsService,
    private emailService : EmailService,
    private SMSService: SMSService,
    private taskService: TaskService,
    private breakpointObserver: BreakpointObserver,
    private cd: ChangeDetectorRef,
    private router: Router
  ) {
    this.loggedUser = this.authService.getGlobalUser();
    this.appointedFor =  this.authService.getGlobalUser();
    if (this.loggedUser){
      if (this.loggedUser.role === 'Admin'){
        this.isAdmin = true;
      }
    }
    this.getService();
    this.dateOrViewChanged();
    this.refresh.next();
  }

  ngOnInit(): void {
    const CALENDAR_RESPONSIVE = {
      small: {
        breakpoint: '(max-width: 576px)',
        daysInWeek: 1,
      },
      medium: {
        breakpoint: '(max-width: 768px)',
        daysInWeek: 3,
      },
      large: {
        breakpoint: '(max-width: 960px)',
        daysInWeek: 5,
      },
    };

    this.breakpointObserver
    .observe(
      Object.values(CALENDAR_RESPONSIVE).map(({ breakpoint }) => breakpoint)
    )
    .pipe(takeUntil(this.destroy$))
    .subscribe((state: BreakpointState) => {
      const foundBreakpoint = Object.values(CALENDAR_RESPONSIVE).find(
        ({ breakpoint }) => !!state.breakpoints[breakpoint]
      );
      if (foundBreakpoint) {
        this.daysInWeek = foundBreakpoint.daysInWeek;
      } else {
        this.daysInWeek = 7;
      }
      this.cd.markForCheck();
    });

    this.createAppointmentForm = this.fb.group({
      // appointmentDate: [, [Validators.required]],
      message: [],
      startTime: [, [Validators.required]],
      endTime: [, [Validators.required]]

    });
    this.refresh.next();

    this.appSettingsService.getPushNotificationSettingsList().valueChanges().subscribe(x=>{
      if(x && x.length > 0){
        this.notifSettings=x[0]
      }
    })
    this.appointmentService.getAppointmentSetting().valueChanges().subscribe((apptSettings:any) =>{
      this.apptSettings = apptSettings[0];
    });
    this.empService.getAllServices().valueChanges().subscribe((allServices: any) => {
      if (allServices && allServices.length > 0) {
        this.allServices = allServices;
      }
    });

    //this.taskService.getTasks().valueChanges().subscribe((tasks: any) => {
      //this.allTasks = tasks[0];
    //})

  }

  get message() { return this.createAppointmentForm.get('message'); }
  get startTime() { return this.createAppointmentForm.get('startTime'); }
  get endTime() { return this.createAppointmentForm.get('endTime'); }

  openServiceDropdown() {

  }

  serviceSelected(service) {
    this.selectedService = service;
    const endTime = this.getEndTIme(this.startTime.value, service.serviceTime);
    const unAvaiableTimes = this.checkDateForAppointment(this.selectedServiceTime, endTime);
    const day = this.employeeSchedule.schedule.filter((day, index) => index === endTime.getDay());
    const dayEndTime = day[0].endTime.split(':');
    let [dayEndTimeHour,dayEndTimeMinute] = [parseInt(dayEndTime[0]) ,parseInt(dayEndTime[1])];

    const dayy = new Date(endTime);
    dayy.setHours(dayEndTimeHour, dayEndTimeMinute, 0, 0);

    if (isAfter(endTime, dayy)){
      this.unAvailableTimeSlot = true;
      this.endTime.patchValue('');
    }
    else if (unAvaiableTimes.length > 0) {
      this.unAvailableTimeSlot = true;
      this.endTime.patchValue('');

    } else {
      this.unAvailableTimeSlot = false;
      this.endTime.patchValue(endTime);
    }
    this.service.patchValue(service.serviceName);
  }
  getEndTIme(date, minutes) {
    return new Date(date.getTime() + minutes * 60000);
  }

  createEvent(appointment) {
    const data = {
      start: appointment.startTime.toDate(),
      end: appointment.endTime.toDate(),
      title: `${appointment.serviceName}`,
      color: {
        primary: '#d0d4d7',
        secondary: '#d0d4d7'
       
      },
    };
    return data;
  }
  createTask(task){
    const data = {
      start: task.reminderTime ? new Date(task.reminder + 'T' + task.reminderTime) : new Date(task.reminder),
      end: task.endReminderTime ?  addMinutes(new Date(task.endReminder + 'T' + task.endReminderTime),15)  : new Date(task.endReminder),
      //title: this.helperService.truncateHTML(task.taskDescription, '23'),
      title : `${task.taskDescription}`,
      appointmentId: `${task.id}`,
      isTask: true,
      allDay: task.reminderTime && task.endReminderTime ? false : true,
      color: {
        primary: '#d0d4d7',
        secondary: '#d0d4d7'
      },
      cssClass: 'my-custom-class ',

    };
    return data;
  }
  eventClicked({ event }: { event: CalendarEvent }): void {
  }

  hourSegmentClicked(event): void {
    const date = event.date;
    if (this.unAvailableTimes.includes(date)){
      // this.toast({ html: 'Employee not available at that time', classes: 'red', type: 'failure' });
      return;
    }
    const today = new Date();
    // today.setHours(0, 0, 0, 0)
    if (isBefore(date, today)){
      // this.toast({ html: 'Can\'t create appointment for past date!', classes: 'red', type: 'failure' });
      return;
    }
    const scheduleExists = this.appointmentsList.filter(a => date.getTime() >= (a.startTime.toDate().getTime() - 1000)
     && date.getTime() + 1000 <= a.endTime.toDate().getTime());
    if (scheduleExists.length <= 0) {
      this.selectedServiceTime = event.date;
      this.startTime.patchValue(this.selectedServiceTime);
      this.selectedServiceEndTime = addMinutes(this.selectedServiceTime, this.serviceDuration);
      this.endTime.patchValue(this.selectedServiceEndTime);

      this.scheduleModalOpen = false;
    } else {
      // this.toast({ html: 'Appointment already exists', classes: 'red', type: 'failure' });
    }

  }

  dateOutsideSchedule(date){
    const day = this.employeeSchedule.schedule.filter((day, index) => index === date.getDay());
    const startTime = day[0].startTime.split(':');
    let [scheduleHour,scheduleMinute] = [parseInt(startTime[0]) ,parseInt(startTime[1])];

    if (date.getHours() < scheduleHour){
      return true;
    }else if ((date.getHours() === scheduleHour) && date.getMinutes() < scheduleMinute){
      return true;
    }
    return false;

  }


  checkDateForAppointment(startTime, endTime) {
    const l = this.appointmentsList.filter(a => endTime.getDay() === a.startTime.toDate().getDay());
    const unBookableTimes = l.filter(a => isAfter(a.startTime.toDate().getTime(), startTime.getTime())
     && isBefore(a.startTime.toDate().getTime(), endTime.getTime()));

    return unBookableTimes;

  }

  beforeViewRender(body: CalendarWeekViewBeforeRenderEvent): void {
    body.hourColumns.forEach(hourCol => {
      if (this.employeeSchedule) {
        if (this.employeeSchedule.schedule.length > 0) {

          hourCol.hours.forEach(hour => {

            hour.segments.forEach(segment => {
              if (isBefore(segment.date, new Date())){
                this.unAvailableTimes.push(segment.date);
                segment.cssClass = 'unavailable';
            }
            }
          );

          });
        }}});

    body.hourColumns.forEach(hourCol => {

      if (this.employeeSchedule) {
        if (this.employeeSchedule.schedule.length > 0) {

          const day = this.employeeSchedule.schedule.filter(
            (day, index) =>
              {
                return index === hourCol.date.getDay() && !day.unAvailable;
              });
          const unavailableDay = this.employeeSchedule.schedule.filter(
            (day, index) =>
              {  return index === hourCol.date.getDay() && day.unAvailable;

              }
          );
          // unavailable day
          if (unavailableDay.length > 0) {
            hourCol.hours.forEach(hour => {

              hour.segments.forEach(segment => {
                  this.unAvailableTimes.push(segment.date);
                  segment.cssClass = 'unavailable';
              });
            });
          }
          // available slot
          if (day.length > 0){
              let startTime = day[0].startTime.split(':');
              let [scheduleHour,scheduleMinute] = [parseInt(startTime[0]) ,parseInt(startTime[1])];

              let endTime = day[0].endTime.split(':');
              let [endScheduleHour,endScheduleMinute] = [parseInt(endTime[0]) ,parseInt(endTime[1])];

              hourCol.hours.forEach(hour => {

                hour.segments.forEach(segment => {
                  if (segment.date.getHours() < scheduleHour){
                    this.unAvailableTimes.push(segment.date);
                    segment.cssClass = 'unavailable';

                  }
                  else if ((segment.date.getHours() === scheduleHour) && (segment.date.getMinutes() < scheduleMinute)){
                    this.unAvailableTimes.push(segment.date);

                    segment.cssClass = 'unavailable';

                  }

                  if (segment.date.getHours() > endScheduleHour){
                    this.unAvailableTimes.push(segment.date);
                    segment.cssClass = 'unavailable';

                  }
                  else if ((segment.date.getHours() === endScheduleHour) && (segment.date.getMinutes() >= endScheduleMinute)){
                    this.unAvailableTimes.push(segment.date);

                    segment.cssClass = 'unavailable';

                  }
                });
              });
          }
        }
      }
    });
  }

  getService(){
    this.appSettingsService.getAppSettingsList().valueChanges().subscribe((settings: any)=>{
      if(settings.length > 0){
        this.appSettings = settings[0];
        this.availablePhone = this.appSettings.availablePhone ? this.appSettings.availablePhone : '';
      }
    })
    this.route.paramMap.subscribe(params => {
      if(window.history.state.service){
        this.serviceUnder = window.history.state.serviceUnder;
        this.rebookedAppointment = window.history.state.rebookedAppointment;
        this.service = window.history.state.service;
        this.serviceId = this.service.id;
      }
    });

    if (this.appointedFor){
      this.scheduleService.getAllSchedules().valueChanges().subscribe(schs =>{
        if(schs.length > 0){
          schs.forEach((sch: any) => {
            this.employeeScheduleIDs.push(sch.employeeId);
          })
        }
      })
      this.empService.getServiceById(this.service.id).valueChanges().subscribe((service:any) =>{
        this.service = service[0];
        this.selectService();
        this.cdr.detectChanges(); 
      })

   }
  }
  selectService(){
    this.allEmployees = [];
    let serviceToBeSaved;
      if(this.service && !this.serviceUnder && !this.rebookedAppointment){
        serviceToBeSaved = this.service;
      } else if (this.service && this.serviceUnder){
        serviceToBeSaved = this.serviceUnder;
        serviceToBeSaved.isServiceUnder = true;
      } else if (this.rebookedAppointment){
        serviceToBeSaved = this.rebookedAppointment
      }

      // if (serviceToBeSaved){
      //   // this.service = service[0];
      //   this.serviceDuration = (serviceToBeSaved.serviceHour * 60) + serviceToBeSaved.serviceMinute;

      //   // get assigned employee
      //   if (serviceToBeSaved.assignedTo && serviceToBeSaved.assignedTo.length > 0){
      //     serviceToBeSaved.assignedTo.forEach(empId => {
      //           if(empId.employee){ // on some parts assigned to is saved as an object of employee 
      //             empId = empId.employee.uid
      //           }
      //           if(this.employeeScheduleIDs.includes(empId)){
      //             this.userService.getUserById(empId).valueChanges().subscribe((employee: any) => {
      //               if (employee.length > 0){
      //                 if(this.employeeScheduleIDs.includes(employee[0].uid)){
      //                   this.allEmployees.push(employee[0]);
      //                 } 
      //                 this.cdr.detectChanges();
      //               }
      //             });
      //           } 

      //     });

      //     this.cdr.detectChanges();
      //   } else {
      //     // appoint to the owner/admin if no employee
      //     this.userService.getAllAdmins().valueChanges().subscribe((admins: any) => {
      //       if (admins.length > 0){
      //         this.allEmployees = [];
      //         admins.forEach(admin => {
      //           this.userService.getUserById(admin.uid).valueChanges().subscribe((employee: any) => {
      //             if (employee.length > 0){
      //               if(this.employeeScheduleIDs.includes(employee[0].uid)){
      //                 this.allEmployees.push(employee[0]);
      //               } 
      //               this.cdr.detectChanges();
      //             }
      //           });
      //         });

      //         this.cdr.detectChanges();
      //       }
      //     });
      //   }
      //   this.availableEmployees = [...this.allEmployees];
      //   this.cdr.detectChanges();
      // }
    // });

  }
  recreateEventsList() {
    this.events = [];
    this.appointmentsList.forEach(appointment => {
      const event = this.createEvent(appointment);
      this.events.push(event);
    });
    this.allTasks.forEach(tasks => {
      const event = this.createTask(tasks);
      this.events.push(event);
    })
    this.cdr.detectChanges();
  }

  closeModal() {
    this.message.patchValue('');
    this.startTime.patchValue('');
    this.endTime.patchValue('');
  }

  sendPushNotificationToOwner(){
    let conditions=["appointment"]
    if(this.notifSettings && this.helperService.checkNotificationForOwner(this.notifSettings,conditions)){
      let fullName = this.loggedUser.GivenName + ' ' + this.loggedUser.LastName
      let serviceName = this.helperService.truncateHTML(this.service.serviceName,'50')
      let notification = new NotificationModel()
      notification.title='Appointment'
      notification.body=`${fullName} has made an appointment for ${serviceName}.`
      this.pushNotificationsService.sendNotificationByRole(notification,'Admin')
      // this.pushNotificationsService.sendNotificationToAllTheMembers('Appointment',`${fullName} has made an appointment for ${serviceName}.`,'Owner_Notification')      
    }
  }

  truncateHTML(serviceName, number){
    this.helperService.truncateHTML(serviceName, number);
  }

  addAppointment(value) {
    this.createModalOpen = false;
    const newAppointment = new EmployeeAppointmentModel();
    newAppointment.employeeId = this.selectedEmployee.uid;
    newAppointment.startTime = new Date(this.selectedServiceTime);
    newAppointment.endTime = new Date(this.selectedServiceEndTime);
    newAppointment.serviceId = this.service.id;
    newAppointment.userId = this.appointedFor.uid;
    newAppointment.userName = this.appointedFor.GivenName + ' ' + (this.appointedFor.LastName ? this.appointedFor.LastName : "");
    newAppointment.message = value.message;
    newAppointment.serviceName = this.service.serviceName;
    newAppointment.employeeName = this.selectedEmployee.GivenName + ' ' + this.selectedEmployee.LastName;
    if(this.apptSettings){
      if(this.apptSettings.sendEmailConfirmation && this.appointedFor.recieveAppointmentEmail){
        this.emailService.sendAppointmentConfirmation(
          { selectedServiceTime :this.selectedServiceTime,
          selectedServiceEndTime: this.selectedServiceEndTime,
          serviceName : this.service.serviceName,
          appointedFor: this.appointedFor.GivenName,
          },this.appointedFor.Email);
        }
       if(this.apptSettings.sendSMSConfirmation && this.appointedFor.recieveAppointmentSMS){
          const smsModel = new SMSModel();
          smsModel.body = `Hello ${this.appointedFor.GivenName}`
          smsModel.body += `Your Service ${this.service.serviceName} is confirmed for date ${this.selectedServiceTime.getDate()}/${this.selectedServiceTime.getMonth() + 1}/${this.selectedServiceTime.getFullYear()} from ${this.selectedServiceTime.getHours()}:${this.selectedServiceTime.getMinutes()} to  ${this.selectedServiceEndTime.getDate()}/${this.selectedServiceEndTime.getMonth() + 1}/${this.selectedServiceEndTime.getFullYear()} and ${this.selectedServiceEndTime.getHours()}:${this.selectedServiceEndTime.getMinutes()}`

          /*if (environment.firstNation.name !== "nationalchief") {
            smsModel.body += `To stop receiving emails please login to the app`
            smsModel.body += `Go to your preferences and disable Email, SMS or Both.`
          }*/

          if (environment.firstNation.name !== "nationalchief" && this.appSettings.general === 'existingNumber') {
            smsModel.body += `\nTo stop receiving emails please login to ${this.domainName} \n`
            smsModel.body += `Go to your preferences and disable Email, SMS or Both. \n`
          }else if(this.appSettings.general === 'buyNum' && this.appSettings.customizeUnsubscribe){
            smsModel.body += this.appSettings.unSubscribe;
          }
    
          
          smsModel.from = this.availablePhone;
          if(this.appointedFor.cellPhone != ''){
          smsModel.phoneNumberList.push(this.appointedFor.cellPhone);
          this.SMSService.sendSMS(smsModel);
          }else{
            this.toast({ html: 'This subscriber doesnt have a phone so s(h)e wont be able to get the SMS', classes: 'red', type: 'failure', redirect: true})
          }
        }
    }

    this.appointmentService.createEmployeeAppointment(newAppointment).then(appointment => {
      this.toast({ html: 'Appointment Successfully Created!', classes: 'green', type: 'success', redirect: true});

      this.sendPushNotificationToOwner()
    }).catch(err => {
      this.toast({ html: 'Error creating Appointment', classes: 'red', type: 'failure', redirect: true });
    });
  }

  backClicked() {
    // this.location.back();
    this.scheduleModalOpen = false;
  }
  goBack(){
    this.location.back();
  }

  selectUser(id){
    this.userService.getUserById(id).valueChanges().subscribe(user => {
      if (user.length > 0){
        this.appointedFor = user[0];
      }
    });
  }

  selectEmployee(id){
    const startTimes = []; const endTimes = [];
    let end: number;
    this.userService.getUserById(id).valueChanges().subscribe(employee => {
      if (employee.length > 0 ){
        this.selectedEmployee = employee[0];
        if(this.selectedEmployee.role == 'Admin' || this.selectedEmployee.role == 'Employee'){
          this.taskService.getTasksByUsersId(id).valueChanges().subscribe((tasks: any) =>{
            this.allTasks = tasks;
            this.recreateEventsList();
          })
        }
        this.scheduleService.getScheduleByEmployeeId(this.selectedEmployee.uid).valueChanges().subscribe(s => {
          if (s && s.length > 0) {
            // this.loading = false;
            this.employeeSchedule = s[0];
            this.employeeSchedule.schedule.forEach(sched => {
              if (sched.startTime){
                startTimes.push(sched.startTime);
              }
              if (sched.endTime){
                endTimes.push(sched.endTime);
              }
            });
            end = parseInt(this.sortTime(endTimes));
            this.maxEndingTime =  end < 24 ? end + 1 : end; // adding 1 hour max time if it is bleow 24 hours
            this.minStartingTime =  parseInt(this.sortTimeAscending(startTimes)) ;

            this.cdr.detectChanges();
            // this.refresh.next();
            this.excludeDays = this.employeeSchedule.schedule.map((day, index) => {
              if (day.unAvailable !== true) { return false; }
              return index;
            }).filter(day => day !== false);

            this.appointmentService.getAppointmentByEmployeeId(this.selectedEmployee.uid).valueChanges().subscribe(a => {
              if (a && a.length > 0) {
                const today = new Date();
                today.setHours(0, 0, 0, 0);

                this.appointmentsList = a;
                // this.appointmentsList = this.appointmentsList.filter(a => a.startTime.toDate() >= today);
                this.recreateEventsList();
              } else {
                this.appointmentsList = [];
              }
            });
            this.events = [...this.events];

            // employee services
            this.empService.getServicesWithTime().valueChanges().subscribe((services: any) => {
              if (services.length > 0) {
                services.forEach(service => {
                  if (service.assignedTo.includes(this.employeeId)){
                    this.employeeServices.push(service);
                  }
                });
              }
            });
            this.cdr.detectChanges();
          }
        });
      }
    });
  }
  sortTime(arr){
      return arr.reduce((a, b) => a <= b ? b : a );
  }
  sortTimeAscending(arr){
    return arr.reduce((a, b) => a >= b ? b : a );
  }
  chooseDateAndTimeClicked(){
    this.scheduleService.getScheduleByEmployeeId(this.selectedEmployee.uid).valueChanges().subscribe(s => {
      if (s && s.length > 0) {
        this.employeeSchedule = s[0];
        this.excludeDays = this.employeeSchedule.schedule.map((day, index) => {
          if (day.unAvailable !== true) { return false; }
          return index;
        }).filter(day => day !== false);


        this.appointmentService.getAppointmentByEmployeeId(this.selectedEmployee.uid).valueChanges().subscribe(a => {
          if (a.length > 0) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            this.appointmentsList = a;
          } else {
            this.appointmentsList = [];
          }
        });
      }
    });

    this.scheduleModalOpen = true;
  }

  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
      if (obj.redirect){
        // this.location.back();
        if(this.loggedUser){
          if(this.loggedUser.role==='User'){
            this.router.navigate(['/user/appointments'])
          }
        }
      }
    }, 2000);
  }
  increment(): void {
    this.changeDate(addPeriod(this.view, this.viewDate, 1));
  }

  decrement(): void {
    this.changeDate(subPeriod(this.view, this.viewDate, 1));
  }

  today(): void {
    this.changeDate(new Date());
  }

  dateIsValid(date: Date): boolean {
    return date >= this.minDate && date <= this.maxDate;
  }

  changeDate(date: Date): void {
    this.viewDate = date;
    this.dateOrViewChanged();
  }

  dateOrViewChanged(): void {
    this.prevBtnDisabled = !this.dateIsValid(
      endOfPeriod(this.view, subPeriod(this.view, this.viewDate, 1))
    );
    this.nextBtnDisabled = !this.dateIsValid(
      startOfPeriod(this.view, addPeriod(this.view, this.viewDate, 1))
    );
    if (this.viewDate < this.minDate) {
      this.changeDate(this.minDate);
    } else if (this.viewDate > this.maxDate) {
      this.changeDate(this.maxDate);
    }
  }

  beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {
    body.forEach((day) => {
      if (!this.dateIsValid(day.date)) {
        day.cssClass = 'cal-disabled';
      }
    });
  }
  getInnerText(txt){
    return this.helperService.getInnerText(txt);
  }
  closeCreateModal(){
    this.createModalOpen = false;
  }
  }
