import { AuthService } from 'src/app/services/firebase/auth.service';
import { MediaService } from 'src/app/services/api/media.service';
import { AboutService } from 'src/app/services/firebase/about.service';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl, FormBuilder, FormGroup } from '@angular/forms';
import { Location } from '@angular/common';
import { ImageModel, ImageUploaderResponseModel } from 'src/app/models/ImageModel';
import { ProductsServiceMenuService } from 'src/app/services/api/data.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import {Collapse, Ripple, initTE} from "tw-elements";
import { GlobalConstants } from 'src/app/models/GlobalConstants';
import { ChangeVisibilityService } from 'src/app/services/firebase/change-visibility.service';


@Component({
  selector: 'app-about-setting',
  templateUrl: './about-setting.component.html',
  styleUrls: ['./about-setting.component.css']
})
export class AboutSettingComponent implements OnInit {
  @Input() calledFromEmptyDashboard:string;
  @Input() calledFrom:string;
  @Input() isShowCreated: boolean;
  @Output() closeCollapse = new EventEmitter<string>();
  @Output() aboutCreated = new EventEmitter<string>();
  @Output() skip = new EventEmitter<string>();
  @Output() previewSelected = new EventEmitter<string>();
  @Output() previewClosed = new EventEmitter<string>();
  @Output() goToTopOfNextPage = new EventEmitter();
  
    
  newSectionForm: FormGroup;
  model: any = {};
  imgErrorMsg: any;
  base64Image: any;
  binDoc: File;
  showLogoInput = true;
  showImageFromServer = false;
  oldUrl: any;
  currentUser: any;
  isMobile = false;
  mobileBase64Image: any;

  hideOnAddSection = false
  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;

  textPositions = {
    left: 'items-start text-left',
    center: 'items-center text-center',
    right: 'items-end text-right',
  };

  newSectionModal = false;
  editSectionModal = false;
  deleteSectionModal = false;
  editSection;
  editIndex;
  deleteIndex;
  step = 1;
  newSetting = true;
  selectedItem;

  preview = false;
  previewData;

  loading = true;
  aboutUsName = 'About Us';

  isPositionChanged = false;
  selectedNewPosition: any;
  addSection = false;

  editorConfig = GlobalConstants.EditorConfig;
  editorCopy= JSON.stringify(this.editorConfig)
  inputEditorConfig = JSON.parse(this.editorCopy)
  onLoading = false
  pageSections: any;
  mediaList = [];
  returnedMediaLinks = {
    imageURL: '',
    videoUrl: '',
    pdfUrl: ''
  };
  cropModalOpen = false;
  useBase64=false
  uploadFileModalOpen = false;
  imageChangedEvent: any = '';
  newSectionValues: any;
  videoUpload: File = null;
  attachedVideo: any;
  confirmSavingAboutModal = false;
  confirmUpdatingAboutModal = false;
  
  constructor(public appSettingsService: AppSettingsService,
              private fb: FormBuilder,
              private location: Location,
              private aboutService: AboutService,
              private mediaService: MediaService,
              private authService: AuthService,
              private productsServiceMenuService: ProductsServiceMenuService,
              private settingsService: AppSettingsService,
              private changeVisibilityService: ChangeVisibilityService,
    ) {

    if (typeof window['Capacitor'] !== 'undefined' && window['Capacitor']['platform'] !== 'web' ) {
      this.isMobile = true;
    }
    this.getSettings()
  }

  ngOnInit() {

    initTE({ Collapse, Ripple });

    this.currentUser = this.authService.getGlobalUser();

    this.newSectionForm = this.fb.group({
      sectionTitle: ['', Validators.required],
      sectionCatagory: [''],
      uploadImg: [true],
    });

    this.loading = true;
    this.aboutService.getAbout().valueChanges().subscribe((about) => {
      if (about && about.length > 0) {
        this.newSetting = false;
        this.model = about[0];
        this.model.sections = this.model.sections ? this.model.sections : []

      } else {
        this.loading = false;
        this.model = {};
        this.model.showEmployees = true;
        this.model.sections = [];
      }
    });
    this.productsServiceMenuService.aboutUsName.subscribe(aboutname => {
      this.aboutUsName = aboutname;
    });

    this.inputEditorConfig.toolbarHiddenButtons[0] =
      ['insertImage', 'subscript',
        'insertUnorderedList','insertOrderedList', 
        'superscript',
        'link','unlink', 
        'backgroundColor',
        'insertVideo',
        'insertHorizontalRule',
        'heading'
      ]
    if(this.calledFromEmptyDashboard == 'About' && !this.isShowCreated){
      this.openCreateModal('values')
    }
   
  }
  ngOnChanges(changes: SimpleChanges): void {
  //  this.openPreview(this.newSectionForm.value)
 
  }


  // section settings
  get sectionTitle() { return this.newSectionForm.get('sectionTitle'); }
  get sectionCatagory() { return this.newSectionForm.get('sectionCatagory'); }
  get sectionDescription() { return this.newSectionForm.get('sectionDescription'); }
  get uploadImg() { return this.newSectionForm.get('uploadImg'); }
  get items() { return this.newSectionForm.get('items') as UntypedFormArray }
  getSettings() {
    this.settingsService.getAppSettingsList().valueChanges().subscribe(hp => {
      if (hp) {
        if (hp.length > 0) {
          this.pageSections = hp[0];                 
        }
      }
    });
  }


  getDescription(index){
    return  this.items.at(index).get('description')
  }
  // make dynamic
  onTitleChange(value: string) {
    this.model.sections[this.editIndex].sectionTitle = value;
  }
   onDescriptionChange(value: string) {
    this.model.sections[this.editIndex].sectionDescription = value;
  }
  onItemDescriptionChange(value: string) {
    this.model.sections[this.editIndex].items[this.selectedItem].description = value
  }
  onItemTitleChange(value: string) {
    this.model.sections[this.editIndex].items[this.selectedItem].title = value
  }
  truncateHTML(text: string, limit: string): string {
    const charlimit = +limit;
    if (!text || text.length <= charlimit) {
      return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ');
    }
    return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ').substring(0, charlimit) + '...';
  }

  moveSection(element, position: number) {
    const index = this.model.sections.indexOf(element);
    const newIndex = index + position;
    if (newIndex < 0 || newIndex === this.model.sections.length) { return; } // Already at the top or bottom.
    const indexes = [index, newIndex].sort(); // Sort the indixes
    // Replace from lowest index, two elements, reverting the order
    this.model.sections.splice(indexes[0], 2, this.model.sections[indexes[1]], this.model.sections[indexes[0]]);
    this.model.sections.forEach(element => {
      element.index = this.model.sections.indexOf(element);
    });
  }


  itemClicked(i) {
    this.selectedItem = i;
  }

  openPreview(section) {
    if (this.base64Image) {
      section.imgUrl = this.base64Image;
    }
    this.previewData = section;
    if(this.calledFromEmptyDashboard == 'About'){
      this.previewSelected.emit()
    }
  }

  backToEdit() {
    this.preview = false;
    this.previewData = undefined;
  }
  removeImage() {
    this.base64Image = undefined;
    this.mediaList = this.mediaList.filter((file) => {
      if (file.type !== "Image") {
        return file;
      }
    });
  }
  removeVideo() {
    this.videoUpload = undefined;
    this.attachedVideo = undefined;
    this.mediaList = this.mediaList.filter((file) => {
      if (file.type !== "Video") {
        return file;
      }
    });
  }
  openEditSection(section, index) {
    this.editSection = JSON.parse(JSON.stringify(section)); // keep deep copy
    this.editIndex = index;

    if (section.sectionCatagory === 'values' && this.model.sections[this.editIndex].items
      && this.model.sections[this.editIndex].items.length > 0) {
      this.itemClicked(0);
    }

    this.editSectionModal = true;


  }
  openDeleteSection(index) {
    this.closeEditModal();
    this.deleteIndex = index;
    this.deleteSectionModal = true;


  }

  openCreateModal(catagory) {

    this.sectionCatagory.setValue(catagory);
    this.hideOnAddSection = true


    switch (catagory) {
      case 'main':
        this.newSectionForm.addControl('textPosition', new UntypedFormControl(this.textPositions.left));
        this.newSectionForm.addControl('sectionDescription', new UntypedFormControl(''));
        this.newSectionForm.addControl('headingTitle', new UntypedFormControl(''));
        this.newSectionForm.addControl('imgUrl', new UntypedFormControl(''));
        this.newSectionForm.removeControl('items');

      case 'mission':
        this.newSectionForm.addControl('missionItems', new UntypedFormArray([]));
        this.newSectionForm.addControl('leftText', new UntypedFormControl(true));
        this.newSectionForm.addControl('sectionDescription', new UntypedFormControl(''));
        this.newSectionForm.addControl('imgUrl', new UntypedFormControl(''));

        this.newSectionForm.removeControl('items');
      case 'values':
        this.newSectionForm.removeControl('items');
        let items = new UntypedFormArray([]);
        let item = new UntypedFormGroup({
          title: new UntypedFormControl('', ),
          description: new UntypedFormControl(''),
          imgUrl: new UntypedFormControl(''),
          uploadImg: new UntypedFormControl(true),
          index: new UntypedFormControl(0),

        });
        items.push(item);
        this.newSectionForm.addControl('items', items);
        this.selectedItem = 0;
        // this.addItem()
        // this.itemClicked
        break;

    }
    this.newSectionForm.updateValueAndValidity();
    // this.newSectionModal = true;
    this.addSection = true


  }


  addMissionItems(create) {
    if (create) {
      let item = document.getElementById('newMission') as HTMLInputElement

      if (item.value === '') {
        return;
      }
      let items = this.newSectionForm.get('missionItems') as UntypedFormArray
      items.push(new UntypedFormControl(item.value));
      item.value = '';
      items.updateValueAndValidity();
    } else {

      let item = document.getElementById('updateMission') as HTMLInputElement
      if (item.value === '') {
        return;
      }
      let items = this.model.sections[this.editIndex].missionItems
      items.push(item.value);
      item.value = '';
    }
  }
  removeMissionItems(index, n) {
    if (n) {
      let items = this.newSectionForm.get('missionItems') as UntypedFormArray
      items.removeAt(index);
      items.updateValueAndValidity();
    } else {

      let items = this.model.sections[this.editIndex].missionItems
      items.splice(index, 1);
    }
  }
  addValueItem() {
    let item = {
      title: '',
      description: '',
      imgUrl: '',
      uploadImg: '',
      index: this.model.sections[this.editIndex].items.length,
    };
    this.model.sections[this.editIndex].items.push(item);
    this.selectedItem = this.model.sections[this.editIndex].items.length - 1
  }

  addItem() {
    let item = new UntypedFormGroup({
      title: new UntypedFormControl('', ),
      description: new UntypedFormControl(''),
      imgUrl: new UntypedFormControl(''),
      uploadImg: new UntypedFormControl(true),
      index: new UntypedFormControl(this.items.value.length),
    });
    this.items.push(item);
    this.selectedItem = this.items.value.length - 1;
  }

  updatePosition(newIndex){
    let arr = this.model.sections[this.editIndex].items
    let oldIndex = arr[this.selectedItem].index;

    if (newIndex === oldIndex) {return; } // Already at same position


    let el =  arr.splice(oldIndex, 1)[0]
    arr.splice(newIndex, 0, el);

    arr.forEach(ele => {
      ele.index =  arr.indexOf(ele);
    });
    this.itemClicked(newIndex)
  }
  removeValueItem() {

    this.model.sections[this.editIndex].items.splice(this.selectedItem, 1);
    if (this.selectedItem === this.model.sections[this.editIndex].items.length) {
      this.selectedItem -= 1;
    }
    else if (this.selectedItem === 0) {
      this.selectedItem = 0;
    }
    else {
      this.selectedItem -= 1;

    }
    // this.selectedItem=this.model.sections[this.editIndex].items.length - 1
  }
  removeItem(i){
    this.items.removeAt(i);
    this.items.updateValueAndValidity();
    if (this.selectedItem === this.items.value.length ) {
      this.selectedItem -= 1;
    } else if (this.selectedItem === 0) {
      this.selectedItem = 0;
    } else {
      this.selectedItem -= 1;

    }
  }


  closeModal() {
    this.newSectionModal = false;
    this.removeFile();
    this.newSectionForm.reset();
    this.newSectionForm.updateValueAndValidity();
    this.backToEdit();
    this.imgErrorMsg = undefined;
    this.selectedItem = 0;
  }
  closeEditModal() {
    this.editSectionModal = false;
    this.model.sections[this.editIndex] = this.editSection;
    this.editIndex = undefined;
    this.editSection = undefined;
    this.imgErrorMsg = undefined;
    this.removeFile();
    this.backToEdit();

  }
  closeDeleteModal() {
    this.deleteSectionModal = false;
    this.deleteIndex = undefined;
    this.imgErrorMsg = undefined;
    this.removeFile();
    this.selectedItem = 0;
  }

  deleteSection() {
    this.model.sections.splice(this.deleteIndex, 1);
    this.closeDeleteModal();
    this.SaveSettings();
  }
  saveSection(values) {
    this.newSectionValues = values;
    this.confirmSavingAboutModal = false;
    this.onLoading = true
    if (this.mediaList.length > 0) {
      // this.uploadImage(values, true);
      this.uploadFileModalOpen = true;
    } else {
      this.saveNewSettings(values);
    }
  }

  saveNewSettings(values) {
    values.visible = true;
    values.index = this.model.sections.length + 1;
    this.model.sections.push(values);
    this.closeModal();
    this.SaveSettings();
  }

  saveUpdate() {
    this.confirmUpdatingAboutModal = false;
    if (this.base64Image || this.oldUrl) {
      this.editSectionModal = false;
      this.uploadImage(this.model.sections[this.editIndex], false);
    } else {
      this.SaveSettings();
    }
  }

  uploadImage(values, callback) {
    if (this.isMobile) {
      if (this.mobileBase64Image) {
        const img = new ImageModel();
        img.imgBase64 = this.mobileBase64Image;
        this.mediaService.uploadImage(img).subscribe((upload) => {
          // this.loading = true;
          if (upload) {
            values.imgUrl = upload.imgLink;
            callback ? this.saveNewSettings(values) : this.SaveSettings();
            return;
          }
          // this.loading = false;
        });
      }
    }
    else {
      if (this.binDoc && !this.oldUrl) {
        this.mediaService.UploadBinImage(this.binDoc).subscribe(
          upload => {
            if (upload) {
              values.imgUrl = upload.imgLink;
              callback ? this.saveNewSettings(values) : this.SaveSettings();


              return;
            }
          }
        );
      } else if (!this.binDoc && this.oldUrl) {
        this.mediaService.DeleteBinImage(this.binDoc).subscribe(
          upload => {
            if (upload) {
              values.imgUrl = '';
              callback ? this.saveNewSettings(values) : this.SaveSettings();

            }
          });

      }
    }
  }

  changeVisibility() {
    this.changeVisibilityService.updateAppSections("About Us");
}

  SaveSettings() {
    this.onLoading = true
    this.changeVisibility()
    if (this.model && !this.newSetting) {
      // modelValues.id = this.model.id;
      this.aboutService.updateAbout(this.model).then(() => {
        this.toast({ html: 'About is saved successfully!', classes: 'green', type: 'success' });
        
       this.onLoading = false
       this.closeCollapseFromEmptydashboard()
       if(this.calledFromEmptyDashboard == 'About'){
        this.aboutCreated.emit('aboutCreated');
        this.goToTopOfNextPage.emit();
       }
      //  if(this.calledFromEmptyDashboard == 'showEmployee'){
      //    this.aboutCreated.emit('showEmployee');
      //    this.goToTopOfNextPage.emit();
      //   }
        this.backClicked()
      }).catch(() => {
        this.toast({ html: 'Something went wrong updating about.', classes: 'red', type: 'failure' });
       this.onLoading = false

      });
    }
    // creating new setting
    else {
      this.aboutService.saveAbout(this.model).then(() => {
        this.toast({ html: 'About is saved successfully!', classes: 'green', type: 'success' });
       this.onLoading = false
    
        this.closeCollapseFromEmptydashboard()
       
        if(this.calledFromEmptyDashboard == 'About'){
          this.aboutCreated.emit('aboutCreated');
         }
        //  if(this.calledFromEmptyDashboard == 'showEmployee'){
        //    this.aboutCreated.emit('showEmployee');
        //   }

      }
      ).catch(err => {
        this.toast({ html: 'Something went wrong creating about.', classes: 'red', type: 'failure' });
       this.onLoading = false

      });
    }

    this.closeEditModal();
    this.closeModal();
  }

  closeCollapseFromEmptydashboard(){
    if(this.calledFromEmptyDashboard == 'About'){
     this.closeCollapse.emit('createAbout');
    }
    if(this.calledFromEmptyDashboard == 'showEmployee'){
      this.closeCollapse.emit('showEmployee');
     }
  }

  backClicked() {
    if(!this.calledFromEmptyDashboard){
      this.location.back();
    }
    
  }
  handleImageInput(files: FileList) {
    if (!this.validateImage(files[0].name)) {
      this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';

      return;
    }
    this.binDoc = files[0];
    this.imgErrorMsg = undefined;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.base64Image = reader.result as string;
    };
  }

  handleItemsImageInput(files: FileList, index, update) {
    if (!this.validateImage(files[0].name)) {
      this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';
      return;
    }
    // this.binDoc = files[0];
    this.imgErrorMsg = undefined;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {

      const img = new ImageModel();
      let base64Image = reader.result as string;
      img.imgBase64 = base64Image.replace(/^data:image\/[a-z]+;base64,/, '')

      this.mediaService.uploadImage(img).subscribe(upload => {
        if (upload) {
          if (update) {
            this.model.sections[this.editIndex].items[index].imgUrl = upload.imgLink;

          } else {
            this.items.at(index).get('imgUrl').setValue(upload.imgLink);
          }
        }
      });

    };
  }


  private validateImage(name: any) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() === 'png') {
      return true;
    } else if (ext.toLowerCase() === 'jpg') {
      return true;
    } else if (ext.toLowerCase() === 'jpeg') {
      return true;
    } else {
      return false;
    }
  }
  removeFile() {
    this.binDoc = undefined;
    this.base64Image = undefined;
    // this.appLogo.patchValue('');
  }

  removeSavedFile(index, saved) {
    if (saved){
      this.model.sections[this.editIndex].items[index].imgUrl = '';

    }else{
      this.items.at(index).get('imgUrl').setValue('');
    }
  }

  remove() {
    this.oldUrl = this.model.sections[this.editIndex].imgUrl;
    this.model.sections[this.editIndex].imgUrl = '';
    this.binDoc = undefined;
    this.base64Image = undefined;
  }
  onImageUpload(response:ImageUploaderResponseModel) {
    if (response.error){
      this.imgErrorMsg = response.errorMessage
      return
    }

    this.binDoc = response.binDoc;
    this.mobileBase64Image = response.imgBase64;
    this.base64Image = 'data:image/jpeg;base64,' + response.imgBase64;
 }

 onItemsImageUploadUpdate(response:ImageUploaderResponseModel) {
  if (response.error){
    this.imgErrorMsg = response.errorMessage
    return
  }

  this.model.sections[this.editIndex].items[this.selectedItem].imgUrl = response.url;
}

onItemsImageUpload(response:ImageUploaderResponseModel) {
if (response.error){
  this.imgErrorMsg = response.errorMessage
  return
}

this.items.at(this.selectedItem).get('imgUrl').setValue(response.url);
}

  handleMobilePhotoInput() {
    // navigator.camera.getPicture((image: any) => {
    //   const imageName = 'IMG.jpeg';
    //   const imageBlob = this.dataURItoBlob(image);
    //   const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });
    //   this.binDoc = imageFile;
    //   this.base64Image = 'data:image/jpeg;base64,' + image;
    //   this.mobileBase64Image = image;

    // }, (error) => {
    //   alert(error);
    // },
    //   {
    //     quality: 50,
    //     destinationType: navigator.camera.DestinationType.DATA_URL,
    //     encodingType: navigator.camera.EncodingType.JPEG,
    //     mediaType: navigator.camera.MediaType.PICTURE,
    //     sourceType: navigator.camera.PictureSourceType.PHOTOLIBRARY,
    //   }
    // );
  }

  handleItemsMobilePhotoInput(index, update) {
    // navigator.camera.getPicture((image: any) => {
    //   const imageName = 'IMG.jpeg';
    //   const imageBlob = this.dataURItoBlob(image);
    //   const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });
    //   this.binDoc = imageFile;
    //   this.base64Image = 'data:image/jpeg;base64,' + image;
    //   this.mobileBase64Image = image;


    //   const img = new ImageModel();
    //   img.imgBase64 = this.mobileBase64Image;
    //   this.mediaService.uploadImage(img).subscribe((upload) => {
    //     // this.loading = true;
    //     if (upload) {
    //       if (update) {
    //         this.model.sections[this.editIndex].items[index].imgUrl = upload.imgLink;

    //       } else {
    //         this.items.at(index).get('imgUrl').setValue(upload.imgLink);

    //       }
    //     }
    //     // this.loading = false;
    //   });


    // }, (error) => {
    //   alert(error);
    // },
    //   {
    //     quality: 50,
    //     destinationType: navigator.camera.DestinationType.DATA_URL,
    //     encodingType: navigator.camera.EncodingType.JPEG,
    //     mediaType: navigator.camera.MediaType.PICTURE,
    //     sourceType: navigator.camera.PictureSourceType.PHOTOLIBRARY,
    //   }
    // );
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }


  toast(obj) {
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green';
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
    }, 2000);
  }
  skipSetup(){
    this.skip.emit();
  }
  fileChangeEvent(event){
    this.imageChangedEvent = event;    
    // this.cropModalOpen = true;
    if (!this.validateImage(event[0].name)) {
      this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';
      return;
    }
    this.binDoc = event[0];
    const reader = new FileReader();
    reader.readAsDataURL(event[0]);
    reader.onload = () => {
      this.base64Image = reader.result as string;
      this.useBase64 = true;
      this.processCroppedImage(this.base64Image);
    };
  }

  closeCroppingModal(){    
    this.cropModalOpen = false;
    this.useBase64 = false;
  }
  getTheUrlsSaved(event){
    this.returnedMediaLinks = event;
    this.uploadFileModalOpen = false;
    this.newSectionValues.imgUrl = this.returnedMediaLinks.imageURL;
    if(this.newSectionValues.items){
      this.newSectionValues.items[0].imgUrl = this.returnedMediaLinks.imageURL;
    }
    this.saveNewSettings(this.newSectionValues);
  }
  processCroppedImage(event){
    this.cropModalOpen = false;
    this.base64Image = event;
    let base64result = this.base64Image.split(',')[1];
    this.useBase64 = false;
    const img = new ImageModel();
   
    if(this.isMobile){
      img.imgBase64 = this.mobileBase64Image;
      this.mediaList.push({type:'Image', file: img});
    }else{
      img.imgBase64 = base64result; 
      this.mediaList.push({type:'Image', file: img});
    }        
  }
  closeConfirmationModal(){
    this.confirmSavingAboutModal = false;
    this.confirmUpdatingAboutModal = false;
  }
  openConfirmationModal(){
    this.toast({html: 'Successfully  posted a blog!', classes: 'green', type: 'success'});
  }
  closePreviewModal(){
    if(this.calledFromEmptyDashboard == 'Blog'){
      this.previewClosed.emit()
    }
  }
}
