<div class="min-h-screen pt-6 font-body bg-veryLightGray max-h-max">
    <div class="flex flex-col p-4 m-2 space-y-4 bg-white rounded-lg md:w-4/6 h-max md:p-12 md:translate-x-1/4">
        <p class="text-xl font-bold text-center text-customTeal">Import your contact </p>


        <hr>
        <!-- Send content -->
        <form [formGroup]="contactForm" (ngSubmit)="pushToField(contactForm.value)" class="flex flex-col justify-center space-y-6 ">

            <!-- add button  to add properties-->
            <div class="flex flex-col items-end justify-end w-full gap-2">
                <button (click)="addField()" class="p-3 text-white border rounded-md modal-button bg-customTeal"
                    *ngIf="!showForm"><i class="float-left w-2 h-2 pr-8 material-icons">add_circle</i> Add
                    Field</button>
                <div class="flex gap-2" *ngIf="showForm">
                    <button (click)="pushToField()" 
                    [disabled]="!contactForm.valid"
                        class="p-3 text-white border rounded-md modal-button bg-customTeal"><i
                            class="float-left w-2 h-2 pr-8 material-icons">check_circle</i> Save</button>
                    <button class="p-3 border rounded-md text-customTeal modal-button bg-gray"
                        (click)="showForm = false">
                        Cancel</button>

                </div>



            </div>

            <!-- iterate over the fields  -->
            <div class="flex justify-center" *ngIf="showForm">
                <div class="grid w-[80%]  grid-cols-1 gap-3">


                    <!-- field type -->
                    <div class="flex flex-col gap-2">
                        <label for="fieldName" class="text-sm font-bold">Display Name <span
                                class="text-red-500">*</span></label>
                        <input id="fieldName" type="text" formControlName='optionalFieldType'
                            class="w-full px-3 py-3 leading-tight text-gray-700 border rounded focus:outline-gray-200 focus:shadow-outline" />
                        <!-- <div *ngIf="(optionalFieldType?.dirty || optionalFieldType?.touched) && optionalFieldType?.invalid">
                            <small *ngIf="optionalFieldType?.errors.required" class="text-red-500"> The display name is required.
                            </small>
                        </div> -->
                    </div>

                    <!-- field label -->
                    <div class="flex flex-col gap-2">
                        <label for="fieldName" class="text-sm font-bold">Field Label <span class="text-red-500">*</span>
                        </label>
                        <input id="fieldName" type="text" formControlName='fieldName'
                            placeholder="Use the name used on the excel sheet"
                            class="w-full px-3 py-3 leading-tight text-gray-700 border rounded focus:outline-gray-200 focus:shadow-outline" />
                    </div>

                    <!-- field label -->
                    <div class="flex flex-col gap-2">
                        <label for="fieldName" class="text-sm font-bold">Field Type <span
                                class="text-red-500">*</span></label>
                        <select formControlName="fieldDataType" required
                            class="px-3 py-1 validate select select-bordered select-gray-600 select-text-gray-800">
                            <option value="" disabled selected>Please Select Field Type.</option>
                            <option value="Date">Date</option>
                            <option value="Text">Text</option>
                            <option value="Email">Email</option>
                            <option value="Phone">Phone Number</option>
                        </select>
                    </div>




                    <!-- check if it is a phone number field or not -->
                    <div class="flex items-center">
                        <input checked id="checked-checkbox" type="checkbox" formControlName='isPhoneNumber'
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                        <label for="checked-checkbox"
                            class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">is phone_number</label>
                    </div>



                </div>

            </div>


            <div class="flex flex-wrap gap-3">
                <div class="p-4 text-white rounded-md bg-customTeal" *ngFor="let field of selectedFields; let i=index">
                    {{field?.fieldType}}

                    <i class="float-left w-1 h-1 pr-8 cursor-pointer material-icons"
                        (click)="deleteSelectedField(i)">delete</i>
                </div>
            </div>



            <!-- upload file -->
            <div class="flex flex-col items-center justify-center w-full">
                <!-- upload file -->
                <div class="w-full" *ngIf="selectedFields.length > 0">
                    <label
                        class="flex justify-center w-full h-32 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
                        <span class="flex items-center space-x-2">
                            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-gray-600" fill="none"
                                viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                            </svg>
                            <span class="font-medium text-gray-600">
                                Drop files to Attach, or
                                <span class="text-blue-600 underline">browse</span>
                            </span>
                        </span>
                        <input type="file" name="file_upload" class="hidden"
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            (change)="csvImport($event)">
                    </label>
                </div>

                <!-- Pleader -->
                <!-- <p>The format of the excel should have Name, Phone and Address. If this information are not included ,
                    the contacts will not be imported</p> -->
            </div>


            <div class="relative overflow-x-auto">

            </div>



        </form>


    </div>

</div>