import { Router } from '@angular/router';
import { FriendsService } from './../../../services/firebase/friends.service';
import { Component, OnInit } from '@angular/core';
import { Form, UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { ConversationsService } from 'src/app/services/firebase/conversations.service';
import { MessagingService } from 'src/app/services/firebase/messaging.service';
import { ForwardedMessageModel, MessagesModel } from 'src/app/models/Messages.Model';
import { PrivateConversationsModel, GroupConversationsModel } from 'src/app/models/Conversations.Model';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { UnreadCountModel } from 'src/app/models/UnreadCountModel';
import { MediaService } from 'src/app/services/api/media.service';
import { UserService } from './../../../services/firebase/user.service';
// declare var M: any;
// declare var $: any;

@Component({
  selector: 'app-chat-list',
  templateUrl: './chat-list.component.html',
  styleUrls: ['./chat-list.component.css']
})
export class ChatListComponent implements OnInit {

  id = ''; currentUser: any;
  privateChats = [];
  groupChats = [];
  scrWidth;
  selectedConver: any;
  chatSelected: any;
  search = false;
  newMessage = '';
  newMessageObj: MessagesModel;
  format = ''; url: any;
  group = false;
  searchQuery = '';
  searchForwardQuery = '';
  searchMemberQuery = '';
  imgLink = 'https://api.goingmobile.app/media/personal-app-assets/img/webp/profile.webp';
  usersForSearch = [];
  chats = [];
  searchResult = [];
  messages = [];
  newConversation;
  loadingMessages = false;
  loadingChats = false;
  selectedImageLink = '';
  today = new Date();
  selectedMember: any = [];
  selectedMemeberName = '';
  groupProfileImg = 'https://api.goingmobile.app/media/personal-app-assets/img/webp/default-group.webp';
  searchCircleMember = false;
  previousMembersList: any = [];
  allMembers: any = [];
  groupUnreadCounts: any = [];
  noNotification = false;
  selectedFilesForDisplay: any = [];

  isAdmin = false;

  useAgeRange = false;
  useGender = false;
  useCommunityName = false;

  isHidden: Boolean = true;
  xPosTabMenu = 0;
  yPosTabMenu = 0;

  selectedMessage: any;
  editMessage = false;

  nationCommunities: any = [];
  isCandidate = false;
  everyone = true;
  specificAudience = false;
  allUnfilteredMembers: any = [];
  allFilteredCandidateContact: any = [];
  searchAndAdd = true;
  friendsList: any;

  constructor(private fb: UntypedFormBuilder,
              private fireMembersService: FireMembersService,
              private conversationsService: ConversationsService,
              private authService: AuthService,
              private messagingService: MessagingService,
              private mediaService: MediaService,
              private friendsService: FriendsService,
              private userService: UserService,
              private router: Router
) { }


ngOnInit() {
  this.loadingChats = true;
  this.currentUser = this.authService.getGlobalUser();
  this.id = this.currentUser.uid;

  if (this.currentUser) {
    if (this.currentUser.role === 'Admin') {
      this.isAdmin = true;
    }
  }

  // get list of chats
  if (this.id) {
    this.conversationsService.getPrivateChatsByUserId1(this.id).valueChanges().subscribe(chats => {
      if (chats) {
        chats.forEach(element => {
          let index = this.privateChats.findIndex(chat => chat.id === element.id);
          if (index >= 0) {
            this.privateChats.splice(index, 1);
          }
          this.privateChats.push(element);
        });
        this.privateChats.forEach(element => {
          if (element.user_2.id === this.id) {
            [element.user_1, element.user_2] = [element.user_2, element.user_1];
          }
        });
        // Sort out the list whenever there has been any change
        this.privateChats.sort((a, b) => (b as any).lastMessage.timeSent.toDate() - (a as any).lastMessage.timeSent.toDate());

      }
    });

    this.conversationsService.getPrivateChatsByUserId2(this.id).valueChanges().subscribe(chats => {
      if (chats) {

        chats.forEach(element => {
          let index = this.privateChats.findIndex(chat => chat.id === element.id);
          if (index >= 0) {
            this.privateChats.splice(index, 1);
          }
          this.privateChats.push(element);
        });
        this.privateChats.forEach(element => {
          if (element.user_2.id === this.id) {
            [element.user_1, element.user_2] = [element.user_2, element.user_1];
          }
        });
        // Sort out the list whenever there has been any change
        this.privateChats.sort((a, b) => (b as any).lastMessage.timeSent.toDate() - (a as any).lastMessage.timeSent.toDate());
      }
      this.loadingChats = false;
    });

  }
  let defaultgroupProfile = this.groupProfileImg;
  // $(document).ready(() => {
  //   $('.materialboxed').materialbox();
  //   $('.tabs').tabs();
  //   $('.modal').modal();
  //   $('.dropdown-trigger').dropdown();
  //   $('.slider').slider({
  //     height: 300
  //   });
  //   $('select').formSelect();

  //   $('.modal#addGroup').modal({

  //     onCloseStart() {
  //       $('.modal#addGroup .profile-picture').attr("src", defaultgroupProfile);
  //       // $('#addGroup form').trigger("reset");
  //     }
  //   });

  // });

}

ngAfterViewChecked() {
  // to ignore changes because of context menu
  // if (this.chatSelected && this.isHidden) {
  //   this.scrollToBottom();
  // }
}

getScreenSize(event?) {
  this.scrWidth = window.innerWidth;
}

// *********************************** For Private Chats ******************

// when a private chat is selected
selectPrivateChat(selectedChat) {
  this.router.navigate(['user/chat/', selectedChat.id], {state: selectedChat});
}

// suggest users during search
openUserSuggestion(id: any) {
  this.usersForSearch = [];

  if (this.usersForSearch.length < 1) {
    this.userService.getAllUsers().valueChanges().subscribe((friends: any) => {
      if (friends.length > 0){
        friends.forEach(friend => {
          this.usersForSearch.push(friend);
        });
      }
    });
  }
}

searchChat() {
  if (this.searchQuery.length > 0) {
    this.searchResult = [];
    this.usersForSearch.find(user => {
      if (user.GivenName.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
      user.LastName.toLowerCase().includes(this.searchQuery.toLowerCase())) {
        this.searchResult.push(user);
      }
    });
    // $('#chats').css('display', 'none');
    // $('#groups').css('display', 'none');

    this.search = true;
  }
  if (this.searchQuery.length === 0) {
    this.search = false;
    this.usersForSearch = [];
    // $('#chats').css('display', 'block');
  }
}

// when user is selected from search
selectedFromSearch(user: any) {


  this.search = false;
  this.searchQuery = '';
  //check if there's an already exsisting conversation
  let checkConversation = this.privateChats.find(chat => chat.user_2.id == user.uid);
  if (!checkConversation) {
    // create new conversation
    this.newConversation = new PrivateConversationsModel();
    this.newConversation.dateCreated = new Date();
    this.newConversation.user_1 = {
      GivenName: this.currentUser.GivenName || this.currentUser.FirstName,
      LastName: this.currentUser.LastName,
      id: this.id,
      profileImgLink: this.currentUser.profileImgLink || '',
      lastVisited: new Date(),
      unreadCount: 0
    };
    this.newConversation.user_2 = {
      GivenName: user.GivenName || user.FirstName,
      LastName: user.LastName,
      id: user.uid,
      profileImgLink: user.profileImgLink || '',
      lastVisited: new Date(),
      unreadCount: 0

    };
    let lastMessage = { message: '', timeSent: null };
    lastMessage.message = '';
    lastMessage.timeSent = new Date();
    this.newConversation.lastMessage = lastMessage;
    this.selectPrivateChat(this.conversationsService.createPrivateConversation(this.newConversation));
  } else {
    this.selectPrivateChat(checkConversation);
  }
}

}
