import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ImageModel } from 'src/app/models/ImageModel';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { GlobalConstants } from 'src/app/models/GlobalConstants';
import { SocialMediaService } from 'src/app/services/firebase/social-media.service';
import { Location} from '@angular/common';
import { HelperService } from 'src/app/services/helper/helper';

@Component({
  selector: 'app-hero-setting',
  templateUrl: './hero-setting.component.html',
  styleUrls: ['./hero-setting.component.css']
})
export class HeroSettingComponent implements OnInit {
  @Input() calledFromEmptyDashboard:string;
  @Output() closeCollapse = new EventEmitter<string>();
  @Output() skip = new EventEmitter<string>();
  @Output() heroCreated = new EventEmitter<string>();


  constructor( private settingsService: AppSettingsService, private fb: UntypedFormBuilder,
    private socialService: SocialMediaService, private location: Location,
    private helperService: HelperService) { 

  }

  landingPageSettings: UntypedFormGroup;
  landingPageToBeSaved: any;
  uploadHeroFileModalOpen = false;
  editHeroPageModalOpen = false;
  imageChangedEventHero: any = '';
  cropModalOpenHero = false;
  modelH: any;
  mediaList = [];
  base64Image: any;
  useHeroBase64 = false;
  binDoc: File;
  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  videoUploadHero: File;
  pageSections: any;
  sections = GlobalConstants.AppSections;
  hero: any
  landingPageMain: any;
  @Input () heroRef;
  preview = false;
  @Output() backClicked = new EventEmitter();
  appStore: any;
  socialMediaModel: any;
  imgErrorMsg = '';
  useBase64 = false;
  ngOnInit(): void {
    this.landingPageSettings = this.fb.group({
      pageTitle: ['', [Validators.required]],
      appDescription: [''],
      logo: [''],
      descriptionColor: [''],
      titleColor: [''],
      textPosition: ['left'],
    });

    if (!this.pageSections) {
      this.pageSections = GlobalConstants.EmptyPageSections;
  }

    this.settingsService.getAppSettingsList().valueChanges().subscribe(hp => {
      if (hp) {
        if (hp.length > 0) {
          this.pageSections = hp[0];
            this.hero = this.pageSections.appSections.filter(s => s.originalName === 'Hero')[0];
            this.appStore = this.pageSections.appSections.filter(s => s.originalName === 'App Store');
        }
      }
    });

    this.settingsService.getLandingPageSettings().valueChanges().subscribe(
      lpSettings => {
        if (lpSettings) {
          if (lpSettings.length > 0) {
            this.modelH = lpSettings[0];
            this.landingPageMain = lpSettings[0];
            // this.selectedColor = this.modelH.titleColor;
            this.modelH.title = this.modelH.title;
            this.titleColor.patchValue(this.modelH.titleColor);
            this.descriptionColor.patchValue(this.modelH.descriptionColor);
            this.pageTitle.patchValue(this.modelH.title);
            this.appDescription.patchValue(this.modelH.description);
            this.modelH.imgUrl = this.modelH.imgUrl?this.modelH.imgUrl : '';
            this.modelH.videoUrl = this.modelH.videoUrl?this.modelH.videoUrl : ''; 
          }
        } 
      }
    );
  }

  get pageTitle() { return this.landingPageSettings.get('pageTitle'); }
  get appDescription() { return this.landingPageSettings.get('appDescription'); }
  get logo() { return this.landingPageSettings.get('logo'); }
  get titleColor() { return this.landingPageSettings.get('titleColor'); }
  get descriptionColor() { return this.landingPageSettings.get('descriptionColor'); }
  // get textPosition() { return this.landingPageSettings.get('textPosition'); }
  
  heroConfirmationUpdate(landingpagesetting){
    this.landingPageToBeSaved = landingpagesetting;
    this.editHeroPageModalOpen = true;
  }
  getTheUrlHero(savedUrls){
    this.uploadHeroFileModalOpen = false;
    this.logo.setValue(savedUrls.imageURL);
    this.landingPageToBeSaved.imgUrl = savedUrls.imageURL;
    this.landingPageToBeSaved.logo = savedUrls.imageURL;
    this.landingPageToBeSaved.videoUrl= savedUrls.videoUrl ? savedUrls.videoUrl : '';
    this.SaveSettings(this.landingPageToBeSaved);
  }
  fileChangeEventHero(event, heroRef){
    this.heroRef = heroRef
    this.imageChangedEventHero = event;
    // this.cropModalOpenHero = true;
        // this.cropModalOpen = true;

    if (!this.validateImage(event[0].name)) {
      this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';

      return;
    }
    this.binDoc = event[0];
    const reader = new FileReader();
    reader.readAsDataURL(event[0]);
    reader.onload = () => {
      this.base64Image = reader.result as string;
      this.useBase64 = true;
      // this.processCroppedImage(this.base64Image);
      this.processCroppedImageHero(this.base64Image)
    };
  }
  SaveSettings(value: any) {
    if (this.modelH) {
      if(!this.modelH.videoUrl){
        this.modelH.videoUrl = '';
      }
      if(!this.modelH.logo){
        this.modelH.logo = '';
      }
      this.modelH.title = value.pageTitle;
      this.modelH.description = value.appDescription;
      this.modelH.titleColor = (value.titleColor) ? value.titleColor : '#FFFFFF';
      this.modelH.descriptionColor = value.descriptionColor;
      this.modelH.logo = value.logo ? value.logo : this.modelH.logo
      this.modelH.videoUrl = value.videoUrl ? value.videoUrl : this.modelH.videoUrl
     
      this.pageSections.appSections.forEach(pSection => {
        if (pSection.originalName === 'Hero'){
          pSection.isEnabled = true;
        }
        if (pSection.originalName === 'Slider'){
          pSection.isEnabled = false;
        }
      });
      this.settingsService.updateAppSettings(this.pageSections).then(() => {
        // this.toast({ html: 'Successfully Updated!!', classes: 'green', type: 'success'});
      
      });

      this.settingsService.updateLandingPageSettings(this.modelH).then(x=>{
        this.toast({ html: 'Successfully Updated!!', classes: 'green', type: 'success'});
      }
        // this.sessionMonitor.newActivity('Landing Page', 'Page Updated'),
      );
    } else {
      this.modelH = {
        title: value.pageTitle,
        description: value.appDescription,
        descriptionColor: value.descriptionColor,
        logo: value.logo ? value.logo : '',
        imgUrl: value.imgUrl ? value.imgUrl : ''
        // titleColor: this.selectedColor,
        // textPosition : value.textPosition
      };

      this.settingsService.saveLandingPageSettings(this.modelH).then(
        // this.sessionMonitor.newActivity('Landing Page', 'Page Set'),
        x=>{
          this.toast({ html: 'Landing Page Settings Successfully Updated!', classes: 'green' })
        }
      );
    }

    this.logo.setValue('');
    this.base64Image = '';
    this.binDoc = undefined;
    this.mediaList = [];
  }

  closeCollapseFromEmptydashboard(){
      this.closeCollapse.emit('heroCarousel');
  }

  closeCroppingModal(){    
    this.cropModalOpenHero = false;

  }

  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
      this.backBtnClicked();
      
      // this.router.navigate(['/admin/dashboard'])
    }, 2000);
  }

  backBtnClicked(){
    if(!this.calledFromEmptyDashboard){
      this.location.back();
    } else {
        if(this.calledFromEmptyDashboard == 'Hero'){
          this.closeCollapse.emit('heroCarousel');
          this.heroCreated.emit('heroCreated');
        }
    }   
  }

  closeEditModal(){
    if(this.calledFromEmptyDashboard){
        if(this.calledFromEmptyDashboard == 'Hero'){
          this.closeCollapse.emit('heroCarousel');
          this.heroCreated.emit('heroCreated');
        }
    }
  }

  saveHeroPageUpdate(){
    this.editHeroPageModalOpen = false;
    this.cropModalOpenHero = false;
    if(this.mediaList.length > 0){
      this.uploadHeroFileModalOpen = true;
    } else {
        this.SaveSettings(this.landingPageToBeSaved);
    }
  }
  handleVideoInputHero(files: FileList) {
    this.binDoc = files[0];
    this.videoUploadHero = files[0];
    this.mediaList.push({type:'Video', file: this.videoUploadHero});
  }
  processCroppedImageHero(event){
    this.cropModalOpenHero = false;
    this.useHeroBase64=false
    this.base64Image=event;
    let base64result = this.base64Image.split(',')[1];
    const img = new ImageModel();
    img.imgBase64 = base64result;
    this.mediaList.push({type:'Image', file: img});
  
  }
  removeHeroImage(){
    this.modelH.logo = '';
    this.modelH.imageUrl = '';
    this.base64Image = undefined;
    this.mediaList = this.mediaList.filter((file)=> {
      if(file.type !== 'Image'){
        return file;
      }
    })
    // this.SaveSettings(this.landingPageToBeSaved);
  }
  removeHeroVideo(){
    this.modelH.videoUrl = '';
    this.videoUploadHero = undefined;
    this.mediaList = this.mediaList.filter((file)=> {
      if(file.type !== 'Video'){
        return file;
      }
    })
  }
  previewChanges(){
    this.preview = true;
  }
  backToEdit(){
    this.preview = false;
  }

  skipSetup(){
    this.skip.emit();
  }
  validateImage(name: any) {
    return this.helperService.validateImage(name);
   }
}
