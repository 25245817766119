import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';


@Injectable({
  providedIn: 'root'
})
export class ContactService {

  messageListRef: AngularFirestoreCollection<any>;
  messageRef: AngularFirestoreDocument<any>;

  constructor(private db: AngularFirestore) { }

  createID() {
    return this.db.createId();
  }

  sendMessage(model: any) {
    model.id = this.db.createId();
    model.discussionID = model.id;
    return this.db.collection('MessageBox').doc(model.id).set(model);
  }

  sendPublicMessage(model: any) {
    model.id = this.db.createId();
    model.discussionID = model.id;
    return this.db.collection('MessageBoxPublic').doc(model.id).set(model);
  }

  respondToMessage(model: any) {
    model.id = this.db.createId();
    return this.db.collection('MessageBox').doc(model.id).set(model);
  }

  getMessageByID(msgID: string): AngularFirestoreDocument<any> {
    this.messageRef = this.db.doc('MessageBox/' + msgID);
    return this.messageRef;
  }


  updateMessage(model: any) {
    return this.db.collection('MessageBox').doc(model.id).update(model);
  }

  deleteMessage(model: any) {
    return this.db.collection('MessageBox').doc(model.id).delete();
  }

  getDiscussion(discussionID: string): AngularFirestoreCollection<any[]> {
    this.messageListRef = this.db.collection('MessageBox', ref => ref.where('discussionID', '==', discussionID));
    return this.messageListRef;
  }

  getUserMessageList(userID: string): AngularFirestoreCollection<any[]> {
    this.messageListRef = this.db.collection('MessageBox', ref => ref.where('toID', '==', userID));
    return this.messageListRef;
  }

  getAllMessageList(): AngularFirestoreCollection<any[]> {
    this.messageListRef = this.db.collection('MessageBox');
    return this.messageListRef;
  }

  saveContactHeader(model: any) {
    return this.db.collection('ContactsHeader').doc(model.uid).set(model);
  }

  updateContactHeader(model: any) {
    return this.db.collection('ContactsHeader').doc(model.uid).update(model);
  }

  saveContactHeaderNew(model: any) {
    model.id = this.db.createId();
    return this.db.collection('ContactsHeaderNew').doc(model.id).set(model);
  }

  updateContactHeaderNew(model: any) {
    return this.db.collection('ContactsHeaderNew').doc(model.id).update(model);
  }

  getContactHeaderNew(): any {
    return this.db.collection('ContactsHeaderNew').get();
  }



  getContactHeader() {
    return this.db.collection('ContactsHeader');
  }

  // insert contacts
  insertContactsData(contactsList) {


    const collectionRef = this.db.collection('Contacts');


    const batch = this.db.firestore.batch();

    contactsList.forEach((doc) => {
      const docRef = collectionRef.ref.doc(); // Generate a new document reference
      batch.set(docRef, { ...doc, });
    });

    return batch.commit()
      .then(() => {
        console.log('Data inserted successfully.');
      })
      .catch((error) => {
        console.error('Error inserting data:', error);
      });
  }

  // delete contact header
  deleteContactHeader(id: any,) {

    // return headersRef
    return this.db.collection('ContactsHeaderNew').doc(id).delete();

  }



  deleteContactsData(contactsList) {

    const deletePromises = [];

    contactsList.forEach((doc) => {
      const collectionRef = this.db.collection('Contacts');
      const docRef = collectionRef.doc(doc.id);

      const deletePromise = docRef.delete();
      deletePromises.push(deletePromise);
    });

    return Promise.all(deletePromises)
      .then(() => {
        console.log('Data deleted successfully.');
      })
      .catch((error) => {
        console.error('Error deleting data:', error);
      });
  }

  deleteUsers(): Promise<void> {

    return new Promise<void>((resolve, reject) => {
      const collectionRef = this.db.collection('Users', ref => ref.where('importedUser', "==", true));

      collectionRef.snapshotChanges().subscribe((snapshot) => {
        const deletionPromises = snapshot.map((doc) => {
          return doc.payload.doc.ref.delete();
        });


        Promise.all(deletionPromises)
          .then(() => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    });
  }

  async deleteUsersNew(userList) {

    // const deletePromises = [];

    // userList.forEach((doc) => {
    // const collectionRef = this.db.collection('Users');
    // const docRef = collectionRef.doc(doc.id);

    //     const deletePromise = docRef.delete();
    //     deletePromises.push(deletePromise);
    //   });

    //   return Promise.all(deletePromises)
    //     .then(() => {
    //       console.log('Data deleted successfully.');
    //     })
    //     .catch((error) => {
    //       console.error('Error deleting data:', error);
    //     });

    try {
      // Create a batch object
      const batch = this.db.firestore.batch();
      const collectionRef = this.db.collection('Users');


      // Iterate through each user ID and add delete operations to the batch
      userList.forEach((user: any) => {

        const documentRef = collectionRef.doc(user?.id).ref;
        batch.delete(documentRef);
      });

      // Commit the batch
      await batch.commit();
      console.log('Batch delete operation successful');
    } catch (error) {
      console.error('Error performing batch delete operation:', error);
    }
  }

  async deleteAllImportedUsers() {
    console.log("Deleting users");

    try {
      const batch = this.db.firestore.batch();
      // const collectionRef = this.db.collection('Users', ref => ref.where('importedUser', '==', true));
      const collectionRef = this.db.collection('Users', ref => ref.where('importedUser', '==', true));
      const querySnapshot = await collectionRef.get().toPromise();

      querySnapshot.forEach(doc => {
        const documentRef = collectionRef.doc(doc.id).ref;
        batch.delete(documentRef);
      });

      await batch.commit();


    } catch (error) {
      console.error('Error performing batch delete operation:', error);
    }

  }

  // Example function to check if users already exist
  async checkExistingUsers(users: any[]) {
    // const userIds = users.map(user => user.id);
    return this.db.collection('Users', ref => ref.where('Email', 'in', users)).get();

  }


  // check existing user using their email ids and use in
  async checkUsersEmail(userEmails: any[]) {

    // // Divide the userIds array into groups of 20
    const chunkSize = 10;
    const userIdsChunks = [];
    for (let i = 0; i < userEmails.length; i += chunkSize) {
      userIdsChunks.push(userEmails.slice(i, i + chunkSize));
    }

    // Query for each chunk separately
    const emailListRefs = [];
    // for (const userIdsChunk of userIdsChunks) {
    //    await  this.db.collection("Users", (ref) => ref.where("Email", "in", userIdsChunk)).get().subscribe((querySnapshot) => {
    //     querySnapshot.forEach((doc) => {
    //       const email = doc.data();

    //       console.log("doc data data data data ........................ ........... ....... ...... ", doc.data())
    //       emailListRefs.push( doc.data()?.['Email']);
    //     });
    //   });

    //   console.log("under for loop ...... ", userIdsChunk)

    //   // emailListRefs.push(emailListRef);
    // }

    const promises = userIdsChunks.map(async (userIdsChunk) => {
      const querySnapshot = await this.db
        .collection("Users", (ref) => ref.where("Email", "in", userIdsChunk))
        .get();


      (await querySnapshot.toPromise()).forEach((res) => {
        const email = res.data()?.['Email'];
        emailListRefs.push(email);

      })


    });

    await Promise.all(promises);
    return emailListRefs;

    // const chunkSize = 10;
    // const userEmailChunks = [];
    // for (let i = 0; i < userEmails.length; i += chunkSize) {
    //   userEmailChunks.push(userEmails.slice(i, i + chunkSize));
    // }

    // const queryPromises = userEmailChunks.map(chunk => {
    //   return this.db
    //     .collection("Users", (ref) => ref.where("Email", "in", chunk))
    //     .get();
    // });

    // return Promise.all(queryPromises)
    //   .then(querySnapshots => {
    //     const existingEmails = new Set<any>();

    //     querySnapshots.forEach(querySnapshot => {
    //       querySnapshot.forEach(doc => {
    //         const email = doc?.data();
    //         existingEmails.add(email);
    //       });
    //     });

    //     return existingEmails;
    //   })
    //   .catch(error => {
    //     console.error("Error checking user emails:", error);
    //     throw error;
    //   });
  }

  dropContactHeaders() {
    const collectionRef = this.db.collection('ContactsHeader');

    collectionRef.get().toPromise().then((querySnapshot) => {
      const deletePromises = [];

      querySnapshot.forEach((doc) => {
        const docRef = collectionRef.doc(doc.id);
        const deletePromise = docRef.delete();
        deletePromises.push(deletePromise);
      });

      return Promise.all(deletePromises);
    }).then(() => {
      console.log('All documents deleted successfully.');
    }).catch((error) => {
      console.error('Error deleting documents:', error);
    });

  }



  getContacts(): any {
    return this.db.collection('Contacts', ref => ref.orderBy('created_at', "desc"))
      .get();
  }

  // get contacts header
  getContactsHeaderWithID(): any {
    // return this.db.collection('ContactsHeader').get();
    return this.db.collection('ContactsHeader').get();

  }

  filterContacts(searchKey: any): AngularFirestoreCollection<any[]> {
    // return this.db.collection('Contacts', ref => ref.where('first_name', ">=", searchKey).where('first_name', ">=", searchKey + '\uf8ff'));
    const searchKeyLower = searchKey.toLowerCase();
    const searchKeyUpper = searchKey.toUpperCase();
    return this.db.collection('Contacts', ref => ref.orderBy('first_name').startAt(searchKeyLower)
      .endAt(searchKeyUpper + '\uf8ff'));
  }

  getContactInformation(): AngularFirestoreCollection<any[]> {
    return this.db.collection('ContactInformation');
  }

  getContactbyId(id: any) {
    return this.db.collection('Contacts').doc(id);

  }


}
