<div *ngIf="nationOfUse != 'Demo' && nationOfUse != 'myaccount'" class="flex flex-col-reverse mx-auto  md:space-y-0   md:flex-row">
  <!-- Signup input -->
  <div class="flex flex-col justify-center w-full p-4 md:p-12 items-center space-y-2  ">

      <div class="flex justify-between items-center  w-full">
        <button class=" flex items-center space-x-2">
            <div  [routerLink]="['/']">
              <i class="material-icons">keyboard_arrow_left</i>
            </div>
            <div  [routerLink]="['/']" class=" ">
                <h4 class=" text-sm font-bold ">
                    Homepage
                </h4>
            </div>
        </button>
        <!-- <img [routerLink]="['/']" *ngIf="!appLogo" src="../../../../assets/img/Going Mobile - 3.webp" class="block w-32 justify-center cursor-pointer" alt="Logo "> -->
        <img [routerLink]="['/']" *ngIf="appLogo" [src]="appLogo" class="block h-28 object-contain justify-center cursor-pointer " alt="Logo ">
      </div>

<div class="bg-white rounded-md border border-solid border-gray-100 shadow-md md:py-12 md:px-16  py-6 px-4 w-full md:max-w-lg" >
  <p class="text-xl font-bold text-center  md:text-2xl ">Log In</p>
  <p class="text-base  text-center text-gray-500 md:pt-1  " *ngIf="model">{{model.welcomeMessage}}</p>
  <hr class=" w-full border-t-1 border-lightGray mt-4">
 
  <form [formGroup]="loginForm" (ngSubmit)="login(loginForm)" class="w-full pt-8">
      <div class="flex flex-col space-y-2 mb-4 lg:w-96 mx-auto">
          <label for=" " class="text-base text-gray-600 font-semibold"> Email <span class="text-gray-400">*</span></label>
          <div class="relative text-gray-600 w-full">
            <span class="absolute inset-y-0 left-0 flex items-center pl-2">
              <button class="p-1 focus:outline-none focus:shadow-outline">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"  stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                </svg>                
              </button>
            </span>
            <input id="email" type="email" formControlName='email' required  autocomplete="off"
            class="input input-bordered rounded-md pl-10 focus:outline-1 focus:outline-brandColor w-full"
            id="Email " type="text " placeholder="Email "
            [ngClass]="{
              'border-red-500': (email.dirty || email.touched) && email.invalid,
              'border-gray-200': !((email.dirty || email.touched) && email.invalid)
            }"
            >
          </div>
        
          <div *ngIf="(email.dirty || email.touched) && email.invalid">
            <small *ngIf="email.errors.required" class="text-red-500 italic"> Email is required. </small>
            <small *ngIf="email.errors.email" class="text-red-500 italic"> Wrong email format. </small>
          </div>
          <label for=" " class="text-base text-gray-600 font-semibold"> Password <span class="text-gray-400">*</span></label>
          <!-- <input  id="password" type="password" formControlName='password' required autocomplete="off" class="focus:invalid:border-red-500 border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline " id="Password "  placeholder="Password "> -->
          <div class="relative text-gray-600 w-full">
            <span class="absolute inset-y-0 left-0 flex items-center pl-2">
              <button type="button"  class="p-1 focus:outline-none focus:shadow-outline">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                </svg>
                             
              </button>
            </span>

            <input #passwordfield
            required 
            autocomplete="off"
            formControlName='password' required 
            class="input input-bordered rounded-md pl-10 focus:outline-1 focus:outline-brandColor w-full "
            id="password"
            placeholder="Password" type="password" 
            [ngClass]="{
              'border-red-500 ': (password.dirty || password.touched) && password.invalid,
              'border-gray-200 ': !((password.dirty || password.touched) && password.invalid)
            }"
              />

            <span class="absolute inset-y-0 right-0 flex items-center pr-3">
              <button type="button" class="p-1 focus:outline-none focus:shadow-outline">
                <span (click)="passwordfield.type=passwordfield.type=='password'?'text':'password'"
                class="fa fa-fw field-icon toggle-password"
                [ngClass]="(passwordfield.type=='password')?'fa-eye-slash':'fa-eye'">
              </span>               
              </button>
            </span>
            </div>

          <div *ngIf="(password.dirty || password.touched) && password.invalid">
            <small *ngIf="password.errors.required" class="text-red-500 italic"> The password is required.
            </small>
            <small *ngIf="password.errors.minlength" class="text-red-500 italic"> Password length must be at least 4 characters .
            </small>
            <small *ngIf="password.errors.maxlength" class="text-red-500 italic"> Password must not exceed 25 characters . </small>
          </div>
          <button type="button" [routerLink]="['/auth/change-password']" class="text-sm text-right text-gray-600 hover:text-customTeal pb-4">Forgot password?</button>
          <button *ngIf="!loadingBtnOn" 
          [disabled]="!loginForm.valid" 
          class="cursor-pointer px-6 py-2 border-2 rounded-md bg-customTeal font-bold text-white focus:outline-none  "
          [ngClass]="{'bg-[#cccccc] text-[#666666]': !loginForm.valid, 'bg-fnl': nationOfUse == 'FNLeaders'}">
              Log In
          </button>
          <!-- loading btn  -->
          <button *ngIf="loadingBtnOn" type="button" class="btn-custom-solid bg-gray-400 border-gray-400 " disabled>
            <i class="animate-spin float-left mr-3 material-icons">
              hourglass_top
            </i>
            <span>Loading...</span>
          </button>

      </div>
  </form>

  <p class=" text-center">Don't have an account? <button type="button" (click)="navigate()" class="inline-block underline text-customTeal font-bold hover:text-darkBlue">Register Here</button>
  </p>
</div>
  </div>
</div>

<!-- toast -->
<div class="alert alert-success shadow-lg fixed top-0 right-0 md:z-50"
[ngClass]="{'visible': openToast, 'invisible': !openToast,'alert-success': toastType=='success', 'alert-error': toastType=='failure' }"
>
  <div class="text-white font-bold">
    <span class="material-icons">check_circle</span>
    <span>{{toastMessage}}</span>
  </div>
</div>

<!-- <app-alert class="fixed top-0 flex justify-end w-full" [toastType]="toastType" [toastMessage]="toastMessage" [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert> -->

<div *ngIf="nationOfUse == 'Demo' || nationOfUse == 'myaccount'" class="flex flex-col md:flex-row justify-between max-h-screen ">
  <!-- image -->
  <div class="md:w-1/2 w-full hidden md:block bg-brandColor h-screen center " >
    <div class="flex px-14 pt-16 space-x-4">
      <div class=" border-solid border-white border-l-8">    </div>    
      <div class="flex flex-col space-y-2">
        <p class="text-white font-light text-2xl  " >Your Mobile Journey Starts Here. </p>  
      <p class="text-white font-semibold text-3xl  ">Simplify Your Business Life with Mobile Web Apps. No Tech Hassles.</p>
      </div>
     </div>

     <div>
      <img  data-src="../../../../assets/img/Login-GM-01.svg" class=" w-1/2 mx-auto" alt="">
    </div>

  </div>
  <!-- form -->
  <div class="md:w-1/2 w-full ">
    <div class="flex flex-col justify-center mx-auto md:w-4/5 w-full p-4 items-center space-y-2  ">

      <div class="flex w-full justify-between items-center ">
        <div class="flex space-x-2">
          <button class=" flex items-center space-x-2">
            <div  [routerLink]="['/']">
              <i class="material-icons">keyboard_arrow_left</i>
            </div>
          
        </button>
        <button class=" flex items-center space-x-2">
          <img
          
          class="md:block hidden w-14 h-14"
          
          src="https://api.goingmobile.app/media/personal-app-assets/img/webp/Going Mobile - 2.webp"
          
          srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/Going Mobile - 2.webp"
          
          fallback="https://api.goingmobile.app/media/personal-app-assets/img/png/Going Mobile - 2.png"
          
          alt="Going mobile logo">
          
        </button>
        </div>
      <div>
       
      </div>
      </div>

<div class="  py-10 px-4 w-full " >
  <p class="text-xl font-bold  md:text-2xl ">Log In</p>
  <!-- <p class="text-base text-gray-500 md:pt-1  " *ngIf="model">{{model.welcomeMessage}}</p> -->
  <hr class=" w-full border-t-1 border-lightGray mt-4">
 
  <form [formGroup]="loginForm" (ngSubmit)="login(loginForm)" class="w-full pt-8">
      <div class="flex flex-col space-y-2 mb-4  mx-auto">
          <label for=" " class="text-base text-gray-600 font-semibold"> Email <span class="text-gray-400">*</span></label>
          <div class="relative text-gray-600 w-full">
            <span class="absolute inset-y-0 left-0 flex items-center pl-2">
              <button class="p-1 focus:outline-none focus:shadow-outline">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"  stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                </svg>                
              </button>
            </span>
            <input id="email  " type="email" formControlName='email' required  autocomplete="off"
            class="input input-bordered rounded-md pl-10 focus:outline-1 focus:outline-brandColor w-full"
            id="Email " type="text " placeholder="Email "
            [ngClass]="{
              'border-red-500': (email.dirty || email.touched) && email.invalid,
              'border-gray-200': !((email.dirty || email.touched) && email.invalid)
            }"
            >
          </div>
        
          <div *ngIf="(email.dirty || email.touched) && email.invalid">
            <small *ngIf="email.errors.required" class="text-red-500 italic"> Email is required. </small>
            <small *ngIf="email.errors.email" class="text-red-500 italic"> Wrong email format. </small>
          </div>
          <label for=" " class="text-base text-gray-600 font-semibold"> Password <span class="text-gray-400">*</span></label>
          <!-- <input  id="password" type="password" formControlName='password' required autocomplete="off" class="focus:invalid:border-red-500 border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline " id="Password "  placeholder="Password "> -->
          <div class="relative text-gray-600 w-full">
            <span class="absolute inset-y-0 left-0 flex items-center pl-2">
              <button  class="p-1 focus:outline-none focus:shadow-outline">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                </svg>
                             
              </button>
            </span>

            <input #passwordfield
            required 
            autocomplete="off"
            formControlName='password' required 
            class="input input-bordered rounded-md pl-10 focus:outline-1 focus:outline-brandColor w-full "
            id="password"
            placeholder="Password" type="password" 
            [ngClass]="{
              'border-red-500 ': (password.dirty || password.touched) && password.invalid,
              'border-gray-200 ': !((password.dirty || password.touched) && password.invalid)
            }"
              />
            <span class="absolute inset-y-0 right-0 flex items-center pr-3">
              <button type="button" class="p-1 focus:outline-none focus:shadow-outline">
                <span (click)="passwordfield.type=passwordfield.type=='password'?'text':'password'"
                class="fa fa-fw field-icon toggle-password"
                [ngClass]="(passwordfield.type=='password')?' fa-eye-slash':' fa-eye'">
              </span>               
              </button>
            </span>
            </div>

          <div *ngIf="(password.dirty || password.touched) && password.invalid">
            <small *ngIf="password.errors.required" class="text-red-500 italic"> The password is required.
            </small>
            <small *ngIf="password.errors.minlength" class="text-red-500 italic"> Password length must be at least 4 characters .
            </small>
            <small *ngIf="password.errors.maxlength" class="text-red-500 italic"> Password must not exceed 25 characters . </small>
          </div>
          <div class="w-full flex justify-end">
            <a [routerLink]="['/auth/change-password']" class=" text-gray-600 hover:text-brandColor py-2 font-bold text-sm">Forgot Password?</a>
          </div>
          <button *ngIf="!loadingBtnOn" 
          [disabled]="!loginForm.valid" 
          class="cursor-pointer px-6 py-2 border-2 rounded-md shadow-sm font-bold  focus:outline-none  "
          [ngClass]="{'bg-gray-300 text-white': !loginForm.valid, 'bg-brandColor text-white': loginForm.valid}">
              Log In
          </button>
          <!-- loading btn  -->
          <button *ngIf="loadingBtnOn" type="button" class="btn-custom-solid bg-gray-400 border-gray-400 " disabled>
            <i class="animate-spin float-left mr-3 material-icons">
              hourglass_top
            </i>
            <span>Loading...</span>
          </button>

      </div>
  </form>

  <p class=" text-center">Don't have an account? <button type="button" (click)="navigate()" class="inline-block underline text-brandColor font-bold hover:text-darkBlue">Apply</button>
  </p>
</div>
  </div>
  </div>
</div>