import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EmailService } from 'src/app/services/api/email.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { UserService } from 'src/app/services/firebase/user.service';
import { environment } from 'src/environments/environment';
import { GlobalConstants } from 'src/app/models/GlobalConstants';
import { EnvironmentService } from 'src/app/services/helper/env';
import { EmailModel } from 'src/app/models/emailModel';

@Component({
  selector: 'app-get-app-confirmation',
  templateUrl: './get-app-confirmation.component.html',
  styleUrls: ['./get-app-confirmation.component.css']
})
export class GetAppConfirmationComponent implements OnInit {
  nationOfuse = environment.firstNation.name;
  resendModal: boolean = false;
  email: any;
  user: any;
  userId: any;
  host = window.location.hostname !== 'localhost' ? 'https://' + window.location.hostname + '/auth/verify-email/' : 'http://localhost:4200/auth/verify-email/';
  domain = environment.firstNation.name;
  storeEmail = [];
  settings: any;
  appLogo: any;

    // toast
    toastMessage: any;
    toastClass: any;
    toastType: any;
    openToast = false;
    // currentAppName= GlobalConstants.currentAppName  
  currentAppName= this.envService.currentAppName  
  imgFolder= this.envService.imgFolder  
  // isNationApp= this.envService.isNationApp
  brandColor= this.envService.getColor('primary')
  textColor = this.envService.getColor('secondary')  
  isNationApp = environment.firstNation.Origin == "FNLeaders" ? true : false;

  domainName = this.isNationApp ? "https://" + this.domain + ".mynation.app" : "https://" + this.domain + ".goingmobile.app";
  

  constructor(private userService: UserService,
              private emailService: EmailService,
              private router: Router,
              private settingsService: AppSettingsService,
              private envService: EnvironmentService
              ) { }

  ngOnInit(): void {
    this.settingsService.getAppSettingsList().valueChanges().subscribe((settings: any) => {
      if (settings){
        this.settings = settings;
        if (settings.length > 0){
          this.appLogo = this.settings[0].logo;
        }
      }
    });
  }
  navigateToURL() {
    if(this.envService.isNationApp) {
      window.location.href = "https://firstnationleaders.app/auth/login";
    } else if(this.nationOfuse === 'Build') {
      window.location.href = "https://myaccount.goingmobile.app/auth/login";
    } else {
      window.location.href = "https://" + this.nationOfuse+".goingmobile.app/auth/login";
    }
  }
  openResend(){
    this.resendModal = true;
  }
  closeResend(){
    this.resendModal = false;
  }
  submit(){
    this.userService.getUserByEmail(this.email).valueChanges().subscribe((x: any) =>{
      if(x && x.length){
        this.user = x[0];
        this.userId = this.user.uid;
        this.sendVerificationEmail(this.userId);
      }else{

      }
    })
    this.closeResend();
  }
  
  sendVerificationEmail(userId: any){
    let hostname = `${this.host}${this.userId}`;
    let domainName1 = `https://${this.domain}.goingmobile.app`
    const emailModel = new EmailModel();
    emailModel.subject = ` Email Verification`;
    emailModel.isNationApp = this.isNationApp;

    emailModel.body = '<div style="background-color: grey;  font-family: Helvetica, Arial, Sans-Serif;">';
    emailModel.body += '<div style="width: 600px; background-color: white;  display: block; margin-left: auto; margin-right: auto; border-width: 6px; border-bottom-style: solid; border-color: #077373;">';
    emailModel.body += '<center>';
    if (this.appLogo){
      emailModel.body += `<img src="${this.appLogo}"
      width="150" height="150" style=" display: block; alt="Logo">`;
    }
    emailModel.body += '</center>';

    emailModel.body += `<h2 style="text-transform: capitalize; padding-left: 20px; padding-right: 20px;">  Hello ${this.user.GivenName}, </h2> `+ '<br /> '
    emailModel.body += `<p style="padding-left: 20px; padding-right: 20px; padding-bottom: 5px;">Thank you for registering with Going Mobile </p> `;
    emailModel.body += '<p style="padding-left: 20px; padding-right: 20px; padding-bottom: 5px;">Please click the button below to activate your account. </p>';
    emailModel.body += `<p><a href="${hostname}" style=" background-color: #077373; cursor: pointer; border-radius: 10px; padding: 18px 24px; text-align: center; text-decoration: none; display: inline-block; font-size: 16px; margin: 10px 2px; cursor: pointer; font-weight: bold; color: white; ">Activate My Account</a></p>`;
    emailModel.body += '<br /> <p style="padding-left: 20px; padding-right: 20px;"> If you didn’t ask to verify this address, you can ignore this email.</p> <br /> <p style="padding-left: 20px; padding-right: 20px; padding-bottom: 5px;">Thank you </p> ';
    emailModel.body += `<p style="padding-left: 20px; padding-right: 20px; padding-bottom: 5px;"><a href="${domainName1}" style=" cursor: pointer; border-radius: 10px; text-align: center; text-decoration: underline; display: inline-block; font-size: 16px; margin: 4px 2px; cursor: pointer; font-weight: bold; color: blue; text-decoration-color: blue; "><u>${domainName1}</u></a></p><br /><br />`;
    this.storeEmail.push(this.email);
    emailModel.emailList = this.storeEmail;
    this.emailService.sendEmail(emailModel).subscribe((res) => {  
      if (res) {
        this.router.navigate(['/get-app-confirmation']);        
      }
      else {
      }
    })

  }
  
  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
    }, 2000);
  }

}
