import { Component, Input, OnInit, } from '@angular/core';

@Component({
    selector: 'app-chat-info',
    templateUrl: './chat-info.component.html',

})
export class ChatInfoComponent implements OnInit {


    @Input() data;
    constructor() { }


    ngOnInit() {
    }

}
