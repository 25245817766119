import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { firstValueFrom } from 'rxjs';
import { RequestToJoinModel } from 'src/app/models/RequestToJoin.Model';

@Injectable({
  providedIn: 'root'
})
export class RequesttojoinService {

  constructor(private db: AngularFirestore) { }

  requestToJoinListRef: AngularFirestoreCollection<any>;
  requestToJoinRef: AngularFirestoreDocument<any>;

  newRegistrationRequest(model: RequestToJoinModel) {
    const newRequest = {
      id: this.db.createId(),
      fullName: model.fullName,
      firstName: model.firstName,
      lastName: model.lastName,
      email: model.email,
      message: model.message,
      declinedMessage: model.declinedMessage ? model.declinedMessage : '',
      cellPhone: model.cellPhone,
      requestDate: new Date(),
      archived: false,
      userType: model.userType,
      status: 'Pending'
    };
    return this.db.collection('RequestToJoin').doc(newRequest.id).set(newRequest);
  }

  archiveRequest(model: RequestToJoinModel) {
    model.archived = true;
    return this.db.collection('RequestToJoin').doc(model.id).update(model);
  }

  getRegistrationRequestList(): AngularFirestoreCollection<RequestToJoinModel> {
    this.requestToJoinListRef = this.db.collection('RequestToJoin');
    return this.requestToJoinListRef;
  }

  getRegistrationRequestByID(id: string) {
    return this.db.collection('/RequestToJoin', ref => ref.where('id', '==', id));
  }

  getRegistrationRequestByEmail(email: string) {
    return this.db.collection('/RequestToJoin', ref => ref.where('email', '==', email));
  }

  // getRegistrationRequestByEmailWithOutSubscription(email: string): Promise<any> {
  //   const query = this.db.collection('/RequestToJoin', ref => ref.where('email', '==', email)).get();
  //   return firstValueFrom(query);
  // }

  getRegistrationRequestByEmailWithOutSubscription(email: string): Promise<any> {
    const query = this.db.collection('/RequestToJoin', ref => ref.where('email', '==', email)).get();

    return firstValueFrom(query).then((querySnapshot) => {
      if (querySnapshot.empty) {
        return null; // Handle the case when no documents are found
      }

      const registrationRequests = querySnapshot.docs.map(doc => doc.data());
      return registrationRequests.length === 1 ? registrationRequests[0] : registrationRequests; // Assuming you expect a single result
    });
  }

  getPendingRequests() {
    return this.db.collection('/RequestToJoin', ref => ref.where('status', '==', 'Pending'));
  }
  getDeclinedRequests() {
    return this.db.collection('/RequestToJoin', ref => ref.where('status', '==', 'Declined'));
  }
  deleteRequestByID(id: string) {
    return this.db.collection('/RequestToJoin').doc(id).delete();
  }

  updateRequest(requestModel: any) {
    return this.db.collection('/RequestToJoin').doc(requestModel.id).update(requestModel);
  }

}
