

export class Posts{
    id: string;
    createdById: string;
    postTitle: string;
    // audiencesId have a value of either "public" or Id: pointing to audiences document
    // audienceId: string;
    sharedLink: string;
    shareWith: string;
    audienceList: [string];
    message: string;
    mediaLink: string;
    pdfLink: string;
    videoLink: string;
    timeStamp: Date;
    opened: any = [];
    areas: any[];
    departments: any[];
    reactions: {
        likes: {
            count: number,
            userId: [],
            usernames: []
        }
    };
    comments: [];
    archived = false;
    commentable: boolean;
    readingTime: number;
    messageUnformated: string;
    mediaUpdates: {
        image: any,
        video: any,
        pdf:any
      }
}

export class Comment{
    id: string;
    postId: string;
    userId: string;
    userName: string;
    comment: string;
    timestamp: Date;
    likes: {
        count: number,
        userId: [],
        usernames: []
    };
    replies: [];
}
