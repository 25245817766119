import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { GlobalConstants } from 'src/app/models/GlobalConstants';
import { Location } from '@angular/common';
import { PermissionService } from 'src/app/services/firebase/permission.service';
import { AuthService } from 'src/app/services/firebase/auth.service';

@Component({
  selector: 'app-footer-setting',
  templateUrl: './footer-setting.component.html',
  styleUrls: ['./footer-setting.component.css']
})
export class FooterSettingComponent implements OnInit {
  @Input() calledFromEmptyDashboard:string;
  @Output() closeCollapse = new EventEmitter<string>();
  @Output() footerCreated = new EventEmitter<string>();


  pageSections: any;
  footerRef: any;
  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  public phoneNumberMask = GlobalConstants.PhoneNumberMask;
  public postalCodeMask = GlobalConstants.PostalCodeMask;
  editModalOpen = false;
  @Output() backClicked = new EventEmitter();
  @Output() skip = new EventEmitter();
  currentUser: any;
  permission: any;
  perm: any;
  permSocial:any;
  hasAccess = false;
  previewFooter = false;

  constructor(private settingsService: AppSettingsService, private location: Location,
    private permissionService: PermissionService,
    private authService: AuthService
    ) { }

  ngOnInit(): void {
    this.currentUser = this.authService.getGlobalUser();
    if(this.currentUser.role=='Admin') this.hasAccess = true;
    this.settingsService.getAppSettingsList().valueChanges().subscribe(
      (sliderSettings: any) => {
        if (sliderSettings) {
          if (sliderSettings.length > 0) {
            this.pageSections = sliderSettings[0];
            const footerSection = sliderSettings[0].appSections.filter(s => s.originalName === 'Footer');
            if (footerSection && footerSection.length > 0){
              this.footerRef = footerSection[0];
            }
          }
        }
      })
      this.permissionService.getPermissionByEmployeeId(this.currentUser.uid).valueChanges().subscribe(permission => {
        if (permission && permission.length > 0){
          this.perm = permission[0]
          this.permSocial = this.perm.permission[3];
          if(this.permSocial.linkAccount) this.hasAccess = true
          
        }
      });
  }

  backBtnClicked(){
    if(this.calledFromEmptyDashboard){
      this.backClicked.emit();
    }else{
      this.location.back();
    }
  
  }

  confirmUpdate(footerRef){
    this.editModalOpen = true;
  }
  saveChanges() {
    this.editModalOpen = false;
    this.pageSections.appSections.forEach(pSection => {
      if (pSection.originalName === 'Footer'){
        pSection.isEnabled = true;
      }
      if (pSection.originalName === 'Contact Us'){
        pSection.isEnabled = true;
      }
    });
    if (this.pageSections) {
      this.settingsService.updateAppSettings(this.pageSections).then(x => {
        this.toast({ html: 'Changes Saved Successfully.', classes: 'green' });
        if(this.calledFromEmptyDashboard == 'Footer'){
          this.closeCollapseFromEmptydashboard()
          this.footerCreated.emit('footerCreated');
        }else{
          this.backBtnClicked()
        }
      });
    }
  }


  closeCollapseFromEmptydashboard(){
    this.closeCollapse.emit('contactInfo');


  }
  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
      this.backBtnClicked();
    }, 2000);
  }
  previewChanges(){
    this.previewFooter = true;
  }
  backToEdit(){
    this.previewFooter = false;
  }
  
  skipSetup(){
    this.skip.emit();
  }
  closeEditModal(){
    this.editModalOpen = false;
  }
}
