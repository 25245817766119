import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingService } from 'src/app/services/loader/loading.service';
import { LoadingState } from 'src/app/models/loadingState';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { NavigationEnd, Router } from '@angular/router';



@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit {
  show = false;
  private subscription: Subscription;
  private user: any;
  isLogged = false;

  constructor(public loadingService: LoadingService, private authService: AuthService, public router: Router,) {
    this.user = authService.getGlobalUser();
  }

  ngOnInit() {
    if(this.user) {this.isLogged = true;}

    this.subscription = this.loadingService.loaderState
    .subscribe((state: LoadingState) => {
      this.show = state.show;
    });
  }


}
