import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppinfoService } from 'src/app/services/backend-automation/appinfo.service';
import { AutomationService } from 'src/app/services/backend-automation/automation.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { CountersService } from 'src/app/services/firebase/counters.service';
import { GetAppService } from 'src/app/services/firebase/get-app.service';
import { UserService } from 'src/app/services/firebase/user.service';
import { ChartOptions, ChartType } from 'chart.js';
//import { Color, Label, MultiDataSet, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';
import * as CryptoJs from 'crypto-js'
import { EnvironmentService } from 'src/app/services/helper/env';
import { HelperService } from 'src/app/services/helper/helper';
// import * as bcrypt from 'bcryptjs'

import {
  Modal,
  Ripple,
  initTE,
} from "tw-elements";
import { environment } from 'src/environments/environment';
import { CheckoutService } from 'src/app/services/checkout/checkout.service';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';




@Component({
  selector: 'app-manage-app',
  templateUrl: './manage-app.component.html',
  styleUrls: ['./manage-app.component.css']
})
export class ManageAppComponent implements OnInit {

  secretKey: any = "zLeCHJYKf25WENP";

  userId: any
  isPendingClicked: any | boolean = false;
  isAcceptedClicked: any | boolean = true;
  isDeclinedClicked: any | boolean = false;
  pendingRequests: any | [] = [];
  acceptedRequests: any | [] = [];
  rejectedRequests: any | [] = [];
  p = 1;
  acceptOpenModal: boolean;
  declineOpenModal: boolean;
  updateRequestModal: boolean = false;
  confirmationModal = false

  createAppData = {
    name: '',
    url: '',
    industry: ''
  };

  createAppForm: UntypedFormGroup;
  updateAppForm: UntypedFormGroup;

  userEmail: any;
  firstName: any;
  lastName: any;
  createPassword: any;
  confirmPassword: any;
  mobileNumberInput: any;
  existingRequests: any = [];
  requestStatus: any = []
  submitModalOpen: boolean = false;
  urlLink:any;
  openDetailModal : boolean = false;
  getAppName: any;

  SMSCounted: number = 0;
  EmailCounted: number = 0;
  ChatGptCounted: number = 0;
  SocialMediaCounted: number = 0;

  currentUser: any;
  app: any;
  counts: any;

  // public doughnutChartOptions: ChartOptions = { responsive: true, }; 
  // public doughnutChartLabels: Label[] = ['Label 1', 'Label 2', 'Label 3']; 
  // public doughnutChartData: SingleDataSet = [100, 200, 150];
  //  public doughnutChartType: ChartType = 'doughnut';

doughnutChartLabels: any[]
doughnutChartData: any = [];
doughnutChartType: ChartType = 'doughnut';
doughnutChartColors: any[];
public doughnutChartLegend = true;
public doughnutChartPlugins = [];
public doughnutChartOptions: ChartOptions = {responsive: true,};

counter_data: any = [];
noCount: boolean = false;


  industry: any = []
  groupedPendingItems: any = {};
  groupOfPendingItems: any = []
  otherServiceClicked: boolean = false;
  selectedIndustry: any;
  options: { label: string; value: string; }[];
  requestsPending: any;
  otherServiceForUpdateClicked: boolean = false;
  userEmailFromRequest: any;
  appRequesterId: any;
  showConfirmationModal: boolean;
  toastClass: any;
  toastMessage: any;
  toastType: any;
  openToast: boolean;
  savedAcceptedRequest:any;

  crossCheckList: any = [
    'Health and Wellness Services (massage therapy, chiropractic, fitness nutrition coaching)',
    'Beauty and Personal Care Services (nails, hair salon, skincare…)',
    'technology',
    'education and tutoring',
    'financial services',
    'real estate',
    'travel',
    'home services (cleaning, gardening…)',
    'skilled trades',
    'marketing'
  ]
  backendConfData: any | [];
  fromFNLeaders = this.envService.fromFNLeaders
  // isNationApp = this.envService.isNationApp
  isNationApp = environment.firstNation.Origin == "FNLeaders" ? true : false;

  extension = this.envService.link
  appCreatedModal = false
  feedbackModal=false;
  emitted: any;
  updateModal= false
  constructor(private route: ActivatedRoute,
    private userService: UserService,
    private getAppService: GetAppService,
    private fb: UntypedFormBuilder,
    private counter: CountersService,
    private automationService: AutomationService,
    private appInfoService: AppinfoService,
   private authService: AuthService, 
    private router: Router,
    private envService: EnvironmentService,
    private helperServices: HelperService,
    private checkoutService: CheckoutService,
    private memberService : FireMembersService

    ) {
     
      // monkeyPatchChartJsTooltip();
      // monkeyPatchChartJsLegend();
     }
 

  

  ngOnInit(): void {
    initTE({ Modal, Ripple });

    this.options = [
      { label: 'Health and Wellness Services (massage therapy, chiropractic, fitness nutrition coaching)', value: 'Health and Wellness Services (massage therapy, chiropractic, fitness nutrition coaching)' },
      { label: 'Beauty and Personal Care Services (nails, hair salon, skincare…)', value: 'OBeauty and Personal Care Services (nails, hair salon, skincare…)' },
      { label: 'Technology', value: 'technology' },
      { label: 'Education and tutoring', value: 'education and tutoring' },
      { label: 'Financial services', value: 'financial services' },
      { label: 'Real estate', value: 'real estate' },
      { label: 'Travel and tourism', value: 'travel' },
      { label: 'Home services (cleaning, gardening…)', value: 'home services (cleaning, gardening…)' },
      { label: 'Skilled Trades', value: 'skilled trades' },
      { label: 'Marketing', value: 'marketing' },
      { label: 'Other', value: 'other' },


    ];
    let regexForUrl = /^[a-z][-a-z0-9]{1,14}$/;

    this.currentUser = this.authService.getGlobalUser();
  
    

    this.createAppForm = this.fb.group({
      appName: [''],
      url: ['', [Validators.required, this.validateUrl(), this.customValidator("webURL", regexForUrl)]],
      // message: ['', Validators.required],
      // otherServiceName: ['',],

    })


    this.updateAppForm = this.fb.group({
      urlUpdate: ['', [Validators.required, this.validateUrlUpdate(), this.customValidator("webURL", regexForUrl)]],
      // messageUpdate: ['', Validators.required],
      otherServiceNameUpdate: ['']

    })

    this.route.paramMap.subscribe(params => {
      this.userId = params.get('id');
    });

    this.userService.getUserById(this.userId).valueChanges().subscribe((user: any) => {
      this.userEmailFromRequest = user[0].Email
      this.getAppService.getRequestByEmail(user[0].Email).valueChanges().subscribe((request: any) => {
        if(request.length > 0){
          var splitFullName = request[0].fullName.split(" ");
          this.firstName = splitFullName[0];
          this.lastName = splitFullName[1];
          this.userEmail = request[0].email;
          this.mobileNumberInput = request[0].mobileNumber
          this.createPassword = request[0].createPassword
          this.confirmPassword = request[0].confirmPassword
    
        }

      })

      this.getAppService.getPendingRequestsByEmail(user[0].Email).valueChanges().subscribe((requests: any) => {
        this.requestStatus = requests;
        this.pendingRequests = requests
        // this.groupedPendingItems = this.pendingRequests.reduce((result: any, item) => {
        //   const industry = item.message;
        //   if (!result[industry]) {
        //     result[industry] = [];
        //   }
        //   result[industry].push(item);
        //   return result;
        // }, {});
      })

      this.getAppService.getAcceptedRequestByEmail(user[0].Email).valueChanges().subscribe((requests: any) => {
        this.acceptedRequests = requests
      })

      this.getAppService.getRejectedRequestsByEmail(user[0].Email).valueChanges().subscribe((requests: any) => {
        this.rejectedRequests = requests
      })


    })

    this.getAppService.getRequests().valueChanges().subscribe((getRequests: any) => {
      if (getRequests.length > 0) {
        this.existingRequests = getRequests;
      }
    });

    // if(this.fromFNLeaders || this.isNationApp){
    //   this.message.clearValidators()
    //   this.messageUpdate.clearValidators()
    // }else{
    //   this.message.addValidators(Validators.required)
    //   this.messageUpdate.addValidators(Validators.required)

    // }
    // this.message.updateValueAndValidity()
    // this.messageUpdate.updateValueAndValidity()

  }

  get appName() { return this.createAppForm.get('appName'); }
  get url() { return this.createAppForm.get('url'); }
  // get message() { return this.createAppForm.get('message'); }
  // get otherServiceName() { return this.createAppForm.get('otherServiceName') }

  get urlUpdate() { return this.updateAppForm.get('urlUpdate') }
  // get messageUpdate() { return this.updateAppForm.get('messageUpdate') }
  // get otherServiceNameUpdate() { return this.updateAppForm.get('otherServiceNameUpdate') }




  pendingClicked() {
    this.isPendingClicked = true;
    this.isAcceptedClicked = false;
    this.isDeclinedClicked = false;

  }
  acceptedClicked() {
    this.isAcceptedClicked = true;
    this.isPendingClicked = false;
    this.isDeclinedClicked = false;


  }
  declinedClicked() {
    this.isDeclinedClicked = true;
    this.isPendingClicked = false;
    this.isAcceptedClicked = false;
  }

  toast(obj) {
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green';
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
    }, 2000);
  }

  createApp(value: any) {


    // if (!this.createAppForm.invalid) {

    //   return;
    // }


    // Access form values using this.myForm.value
    const name = value.appName;
    const url = value.url.toLowerCase();
    // const industry = this.selectedIndustry == 'other' ? value.otherServiceName : value.message;




   
    const model = {
      appName: name,
      email: this.userEmail,
      mobileNumber: this.mobileNumberInput,
      fullName: this.firstName + " " + this.lastName,
      url: url,
      imgLink: '',
      requestDate: new Date(),
      isBusinessApp: '',
      serviceType: '',
      hasStaff: '',
      hasAppointments: '',
      sellingOnline: '',
      companyEmail: '',
      companyPhone: '',
      companyAddress: '',
      companyPostalCode: '',
      companyCity: '',
      companyProvince: '',
      hasContactInfo: '',
      status: 'Pending',
      // message: industry,
      facebook: '',
      twitter: '',
      instagram: '',
      youtube: '',
      pintrest: '',
      tiktok: '',
      linkedin: '',
      businessName: '',
      createPassword: this.createPassword,
      confirmPassword: this.confirmPassword,
      isNationMember: '',
      nation: ''
    };


    this.getAppService.saveRequest(model).then((res) => {
        this.toast({ html: 'App created successfully! ', classes: 'green', type: 'success' });
        const btnAppCreate = document.getElementById('createAppClose');
        btnAppCreate.click()
        this.appCreatedModal = true
        this.confirmationModal = false
    }).catch((error) => {
        this.toast({ html: `App creation failed, ${error} `, classes: 'red', type: 'error' });

    })

    this.closeModal()
     // Clear the form after submission
    //  this.createAppForm.reset();


  }

  updateApp(value: any) {
    

    const updatedUrl = value.urlUpdate.toLowerCase()
    // var messageUpdated: any = this.selectedIndustry == 'other' ? value.otherServiceNameUpdate : value.messageUpdate;
    const otherServiceNameUpdated = value.otherServiceNameUpdate


    const model = {
      id: this.appRequesterId,
      appName: '',
      email: this.userEmail,
      mobileNumber: this.mobileNumberInput,
      fullName: this.firstName + " " + this.lastName,
      url: updatedUrl,
      imgLink: '',
      requestDate: new Date(),
      isBusinessApp: '',
      serviceType: '',
      hasStaff: '',
      hasAppointments: '',
      sellingOnline: '',
      companyEmail: '',
      companyPhone: '',
      companyAddress: '',
      companyPostalCode: '',
      companyCity: '',
      companyProvince: '',
      hasContactInfo: '',
      status: 'Pending',
      // message: messageUpdated,
      facebook: '',
      twitter: '',
      instagram: '',
      youtube: '',
      pintrest: '',
      tiktok: '',
      linkedin: '',
      businessName: '',
      createPassword: this.createPassword,
      confirmPassword: this.confirmPassword,
      isNationMember: '',
      nation: ''
    };



    this.getAppService.updateRequest(model).then(() => {
      this.toast({ html: 'App updated successfully! ', classes: 'green', type: 'success' });
      this.updateModal = false
    }).catch((error) => {
      this.toast({ html: `App update failed, ${error} `, classes: 'red', type: 'error' });

    })

    this.closeModal()
  }
  openAcceptRequestModal() {
    this.acceptOpenModal = true;
  }

  openUpdateRequestModal(item: any) {
    this.updateRequestModal = true;
    this.requestsPending = item;

    this.appRequesterId = item.id

    this.updateAppForm.get('urlUpdate').setValue(item.url);

    // const existsInCrossCheckList = this.crossCheckList.includes(item.message);


    // if (!existsInCrossCheckList) {
    //   this.otherServiceForUpdateClicked = true;
    //   this.updateAppForm.get('otherServiceNameUpdate').setValue(item.message)
    //   this.updateAppForm.get('messageUpdate').setValue('other')
    // } else {
    //   this.updateAppForm.get('messageUpdate').setValue(item.message);

    // }



  }

  openUpdateRejectedRequestModal(item: any) {

  }
  openDenyRequestModal() {
    this.declineOpenModal = true;
  }

  closeModal() {
    this.acceptOpenModal = false;
    this.declineOpenModal = false;
    this.updateRequestModal = false;
    this.showConfirmationModal = false;
    // this.appCreatedModal = false;
    // this.confirmationModal = false;
    


  }

  openDetailInformation(item){
    var url = 'https://' + item.url + '.goingmobile.app/auth/login'

    this.savedAcceptedRequest = item;

    this.appInfoService.getAppByURL(url).valueChanges().subscribe((app : any)=>{
      if(app && app.length != 0){
      this.app = app;
      this.getAppName = app[0].backendConfName;
      this.automationService.readFromCollection(this.getAppName, 'Counter').subscribe((x:any) =>{
        if(x && x.length != 0){
            this.noCount = false;
            this.SMSCounted = x[0].SMSTotalCounter;
            this.EmailCounted = x[0].emailTotalCounter;
            this.ChatGptCounted = x[0].chatGptCounter;
            this.SocialMediaCounted = x[0].socialMediaCount;
            this.counterChart();
        }else{
            this.noCount = true;
            this.SMSCounted = 0;
            this.EmailCounted = 0;
            this.ChatGptCounted = 0;
            this.SocialMediaCounted = 0;
        }
      })

      this.counter_data.push(this.SMSCounted);
      this.counter_data.push(this.EmailCounted);
      this.counter_data.push(this.SocialMediaCounted);
      this.counter_data.push(this.ChatGptCounted);
      }
      this.counter.getCounter().valueChanges().subscribe((counts) => {
        if(counts && counts.length != 0){
          this.counts = counts[0];
          this.SMSCounted = this.counts.SMSTotalCounter;
          this.EmailCounted = this.counts.emailTotalCounter;
          this.SocialMediaCounted = this.counts.socialMediaCount;
          this.ChatGptCounted = this.counts.chatGptCounter;
        }
      })
    })

    // const encodedEmail = CryptoJs.AES.encrypt(this.astrekis, "samsamsam").toString();
    // const encodedPassword = CryptoJs.AES.encrypt(this.pass, "samsamsam").toString()
    // var url1 = `/login?email=${encodedEmail}&password=${encodedPassword}`
    // const data = {
    //   email: encodedEmail,
    //   password: encodedPassword,
    //   url: url
    // }

    // // {state : {displayHelp : "buildMobile"}}
    // this.router.navigate(['/auth/login'], { state : {data :  data }})
    
  }

  closeDetailInformation(){
    this.openDetailModal = false;
  }

  private validateUrl(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      let existingURL = [];
      existingURL = this.existingRequests.filter(req => req.url === control.value);
      if (existingURL.length > 0) {
        return { urlExists: true };
      }

      else {
        return null;
      }
    };
  }
  counterChart() {
    // this.doughnutChartLabels = []
    // this.doughnutChartData = []
    // this.doughnutChartColors = [
    // {
    // // backgroundColor: this.device_color
    // }
    // ]
    // // this.doughnutChartData = [this.device_data]
    // // this.doughnutChartLabels = this.device_label
    // this.doughnutChartOptions = {
    // responsive: true
    // };
    // // this.device_data = [];
    // // this.device_label = [];
    // // this.device_color = [];


    this.doughnutChartData = [[this.SMSCounted,this.EmailCounted,this.SocialMediaCounted,this.ChatGptCounted]]; 
    this.doughnutChartColors= [ { backgroundColor: ['#fcba03', '#58c4e8','#9fc543' ,'#e063d0'] } ];
    this.doughnutChartOptions= { responsive: true };
    this.doughnutChartLabels = ['SMS', 'Email', 'Social Media', 'ChatGpt'];
    this.counter_data = [];
    }
  
  private isValidProjectId(input) {
    const regex = /^[a-z][-a-z0-9]{5,29}$/;
    return regex.test(input);


  }

  private validateUrlUpdate(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      let existingURL = [];
      existingURL = this.existingRequests.filter((req: any) => req.id !== this.appRequesterId && req.url === control.value);
      if (existingURL.length > 0) {
        return { urlExists: true };
      }

      else {
        return null;
      }
    };
  }



  private customValidator(controlName: string, pattern: RegExp) {
    return (formControl: UntypedFormControl) => {
      const controlValue = formControl.value;
      if (controlValue && !pattern.test(controlValue.toLowerCase())) {
        return { [controlName]: true };
      }
      return null;
    };
  }

  
  // async encryptAndStorePassword(password: string): Promise<void>{
  //   const saltRounds = 10
  //   const encryptedPassword = await bcrypt.hash(password, saltRounds)
  //   return encryptedPassword
  // }

  async navigateToURL(url: string) {

    // const plainTextPassword = '123456'
    // let result = await this.encryptAndStorePassword(plainTextPassword)

    const encodedEmail = CryptoJs.AES.encrypt(this.userEmail, this.secretKey).toString();
    const encodedPassword = CryptoJs.AES.encrypt(this.confirmPassword, this.secretKey).toString()

    // // // window.location.href = redirectUrl

    // this.redirectToAnotherDomain(`https://${url}.goingmobile.app/#/auth/login`, {
    //   email: encodedEmail,
    //   password: encodedPassword
    // });

    // this.redirectToAnotherDomain(`https://demo.goingmobile.app/#/auth/login`, {
    //   email: encodedEmail,
    //   password: encodedPassword
    // });
    // // this.redirectToAnotherDomain(`/#/auth/login`, {
    // //   email: encodedEmail,
    // //   password: encodedPassword
    // // });

    const currentUrl = `https://${url}.${this.extension}/auth/login`;
    window.open(currentUrl, '_blank')

  }

  redirectToAnotherDomain(url: string, data: any) {
    // window.location.href 
    const currentUrl = `${url}?${new URLSearchParams(data).toString()}`;
    window.open(currentUrl, '_blank')
  }

  industryType(e: any, index: any) {

    this.selectedIndustry = e

    if (e === 'other') {
      this.otherServiceClicked = true
    } else {
      this.otherServiceClicked = false
      // this.updateAppForm.get('otherServiceNameUpdate').setValue('')

    }

    if (this.updateRequestModal) {
      if (e === 'other') {
        this.otherServiceForUpdateClicked = true
      } else {
        this.otherServiceForUpdateClicked = false
        // this.updateAppForm.get('otherServiceNameUpdate').setValue('')

      }
    }



  }


  deleteModal() {
    this.showConfirmationModal = true;

  }
  truncateHTML(text: any, limit:any): string {
    return this.helperServices.truncateHTML(text, limit);
   }
  closeDeleteModal() {
    this.showConfirmationModal = false;

  }

  continueDeleteModal() {

    let id = this.openDetailModal ? this.savedAcceptedRequest.id : this.appRequesterId
    if(this.showConfirmationModal){
      this.getAppService.deleteAppRequest(id).then(() => {
        console.log("I am getting in here")
        this.toast({ html: 'App request deletion successfull! ', classes: 'green', type: 'success' });
  
      }).catch((error) => {
        console.log("I am not getting in here")
        this.toast({ html: `App request delete failed, ${error} `, classes: 'red', type: 'error' });
  
      })
    }

    if(this.openDetailModal){
      this.deleteProject()
    }
   
    // this.closeModal()
    this.closeDeleteModal();
    this.closeDeleteForAccAppsModal();


    this.updateRequestModal = false


  }

  closeDeleteForAccAppsModal(){
    this.openDetailModal = false;
    this.updateRequestModal = false

  }

  continueDeleteForAccAppsModal(){
    // this.showConfirmationModal = true;
    this.closeDeleteModal()
    this.updateRequestModal = false
  }

  openFeedbackModal(){
    this.feedbackModal = true
  }
  closeFeedbackModal(){
    this.feedbackModal = false
  }
  onSendClicked(event){
    this.emitted = event;
    this.feedbackModal = false;
  }


  deleteProject() {
    console.log("It is getting in here")
    let url = `https://${this.savedAcceptedRequest.url}.${this.extension}/auth/login`;

    this.appInfoService.getAppByURL(url).valueChanges().subscribe((res:any) => {
      this.backendConfData = res;

      if (this.backendConfData.length > 0) {

        this.automationService.deleteCloudProject(this.backendConfData[0].backendConfName).subscribe((res) => {
        }, (err) => {
        })
     
      
        this.automationService.deleteProperty(this.backendConfData[0].backendConfName, this.backendConfData[0].accountId).subscribe((res) => {
    
          this.appInfoService.deleteAppByLink(url).subscribe((res) => {
            this.toast({ html: 'App setup deletion successfull! ', classes: 'green', type: 'success' });
          })
  
          this.getAppService.deleteAppRequest(this.savedAcceptedRequest.id).then(() => {
      
          }).catch((error) => {
            // this.toast({ html: `App setup delete failed, ${error} `, classes: 'red', type: 'error' });
      
          })
    
    
    
        }, (err) => {
        })

        
      }
    })

   

    
 
  
  }
  submitClicked(type, closeButton){
    if(type == 'update'){
      this.updateModal = true
    }else if(type == 'create'){
      this.confirmationModal= true
    }
    let closeBtn = document.getElementById(closeButton);
    closeBtn.click();
  }

  saveClicked(type, value){
    

  }



  UpgradeAPP(mobile:any, url:any, request:any) {
if(this.authService.loggedInUser) {
  var customerID = ""
  if (this.authService.loggedInUser['stripeCustomerID']) {
    customerID = this.authService.loggedInUser['stripeCustomerID']
  }


  var model = {
    priceId: 'price_1O024MBv0LG3RwaxiVJgfSc0',
    customerEmail: this.authService.currentUser.email,
    mobile: mobile,
    appName: url,
    origin: (environment.firstNation.name == "FNLeaders") ? "FNLeaders" : "GoingMobile",
    url: (environment.firstNation.name == "FNLeaders") ? url + ".mynation.app" : url + ".goingmobile.app",
    fbAppRequestID : request,
             fbUserID : this.authService.currentUser.uid,
    customerId: customerID ,
  };

  this.checkoutService.CreateSubscriptionPlan(model).subscribe(response => {
    if (response && response.url) {
      const model = {
        uid: this.authService.userID,
        hasActiveStripeSession: true,              
      };

      this.memberService.updateAccount(model).then(x => {
        window.location.href = response.url;
      })

      
    } else {
      console.error('Stripe Checkout URL not received');
    }
  }, error => {
    console.error('Error during Stripe subscription creation:', error);
  });

}
    
    
  }

}
