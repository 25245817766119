import { AuthService } from './../../../services/firebase/auth.service';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
// import { GalleryItem, ImageItem } from 'ng-gallery';
import { AppointmentService } from 'src/app/services/firebase/employee-appointment.service';

import { UserService } from 'src/app/services/firebase/user.service';

@Component({
  selector: 'app-appointment-detail',
  templateUrl: './appointment-detail.component.html',
  styleUrls: ['./appointment-detail.component.css']
})
export class AppointmentDetailComponent implements OnInit {

  id:any;
  user: any;
  employee: any;

  images: any[];
  imgLink = 'https://www.w3schools.com/howto/img_avatar.png';
  profileImages: any = [];
  coverPhotoLink = '';
  GivenName:any;
  LastName:any;
  cellPhone:any;
  Email:any;
  JobTitle:any;

  appointment:any;
  appointmentStartDate:any;
  apoointmentEndDate:any;
  bookedBy: any;
  loggedUser: any;


  constructor(private fireMembersService: FireMembersService ,
              private fb: UntypedFormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private authService: AuthService,
              private appointmentService: AppointmentService,
              private userService: UserService) { }

  ngOnInit(): void {
    this.loggedUser = this.authService.getGlobalUser();

    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
      });

    this.appointmentService.getAppointmentById(this.id).valueChanges().subscribe(appointment =>{
      this.appointment = appointment[0];
      if(this.appointment.userId=='unregistered'){
        this.user = {
          GivenName: this.appointment.GivenName,
          LastName: this.appointment.LastName,
          Email: this.appointment.Email,
          cellPhone: this.appointment.cellPhone,
        }
      } else {
        this.userService.getUserById(this.appointment.userId).valueChanges().subscribe(user => {
          this.user = user[0];
        });
      }

      if(this.appointment.bookedBy){
        this.userService.getUserById(this.appointment.bookedBy).valueChanges().subscribe(bookedByUser => {
          this.bookedBy = bookedByUser[0];
        })
      }
      this.userService.getUserById(this.appointment.employeeId).valueChanges().subscribe(employee =>{
        this.employee = employee[0];
      })

    });


    }
    gotoClient(user){
      //[routerLink] = "['/admin/employee-detail', employee.uid]"
      this.router.navigate(['/admin/client-detail',user.uid])
    }


  }
      // this.fireMembersService.getProfileByuserID(this.id).valueChanges().subscribe(x => {

      //   if(x){
      //     this.user = x[0];

         // this.imgLink = (this.user.profileImgLink) || '';
         // this.coverPhotoLink = this.user.coverPhotoLink || '';
         // this.profileImages = this.user.profileImages || [];
          // this.GivenName = this.user.GivenName;
          // this.LastName = this.user.LastName;
          // this.cellPhone = this.user.cellPhone;
          // this.Email = this.user.Email;
          // this.JobTitle = this.user.JobTitle;


          // if(this.profileImages.length > 0) {
          //   this.images = [];
          //   this.profileImages.forEach(element => {

          //     this.images.push(
          //       new ImageItem({ src: element, thumb: element }))
          //   });

          //   }

      // })

      // }
