import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ScheduleService } from 'src/app/services/firebase/employee-schedule.service';
import { EmployeeServicesService } from 'src/app/services/firebase/employee-services.service';
import { UserService } from 'src/app/services/firebase/user.service';
interface Option {
  id: number;
  name: string;
}

@Component({
  selector: 'app-item-under-service-form',
  templateUrl: './item-under-service-form.component.html',
  styleUrl: './item-under-service-form.component.css'
})
export class ItemUnderServiceFormComponent implements OnInit {
  itemUnderCategoryForm: UntypedFormGroup;
  @Input() servicesName;
  @Input() isEdit;
  @Input() previousFormValues;
  @Input() appSetting;
  @Input() canTakeAppt;
  numberPattern = /^[-+]?(\d*\.\d+|\d+\.|\.\d+|\d+)([eE][-+]?\d+)?$/;
  @Output() cancelClicked = new EventEmitter();
  @Output() saveItem = new EventEmitter();
  @Output() editItem = new EventEmitter();
  employeeScheduleIDs = [];
  selectedEmployeeName = [];
  employees = [];
  doesAnyoneHaveSchedule = false;

  constructor(private fb: UntypedFormBuilder,
    private empService: EmployeeServicesService,
    private scheduleService: ScheduleService,
    private userService: UserService,){

  }
  isDropdownOpen = false;
  selectedEmployeesIds: number[] = [];

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  selectOption(option) {
    const index = this.selectedEmployeesIds.indexOf(option.uid);
    if (index > -1) {
      // Remove the option if it's already selected
      this.selectedEmployeesIds.splice(index, 1);
    } else {
      // Add the option if it's not selected
      this.selectedEmployeesIds.push(option.uid);
    }
  }

  isSelected(option): boolean {
    return this.selectedEmployeesIds.includes(option.uid);
  }

  getSelectedOptionNames(): string[] {
    return this.employees
      .filter(option => this.selectedEmployeesIds.includes(option.uid))
      .map(option => option.GivenName);
  }
  
  ngOnInit(): void {
    this.itemUnderCategoryForm = this.fb.group({
      serviceTitle: new UntypedFormControl('', Validators.required),
      serviceUnderDescription: new UntypedFormControl(''),
      servicePrice: new UntypedFormControl('', Validators.pattern(this.numberPattern)),
      hasAppointment: [],
      serviceHour: [0, [Validators.min(0), Validators.max(23)]],
      serviceMinute: [0, [Validators.min(0), Validators.max(59)]],
      bufferTime: [0],
      assignedTo: this.fb.array([]),
      showEmployees: [],
    })
    if (this.isEdit){
      this.setFormValues()
    }
    this.scheduleService.getAllSchedules().valueChanges().subscribe(schs => {
      if (schs.length > 0) {
        this.doesAnyoneHaveSchedule = true;
        schs.forEach((sch: any) => {
          this.userService.getUserById(sch.employeeId).valueChanges().subscribe((user: any) => {
            if(user.length > 0){ 
              if(this.employees.filter(emp => emp.uid==user[0].uid).length == 0){
                this.employees.push(user[0]);
                this.assignedTo.push(this.newEmployee(user[0]));
              }
            }
          })
          this.employeeScheduleIDs.push(sch.employeeId);
        })
      }
    })
  }

  get hasAppointment(){ return this.itemUnderCategoryForm.get('hasAppointment'); }
  get showEmployees(){ return this.itemUnderCategoryForm.get('showEmployees'); }
  get serviceHour(){ return this.itemUnderCategoryForm.get('serviceHour'); }
  get serviceMinute(){ return this.itemUnderCategoryForm.get('serviceMinute'); }
  get serviceTitle(){ return this.itemUnderCategoryForm.get('serviceTitle'); }
  get serviceUnderDescription(){ return this.itemUnderCategoryForm.get('serviceUnderDescription'); }
  get servicePrice(){ return this.itemUnderCategoryForm.get('servicePrice'); }
  get bufferTime(){return this.itemUnderCategoryForm.get('bufferTime'); }
  get assignedTo() { return (<UntypedFormArray>this.itemUnderCategoryForm.get('assignedTo')); }
  
  newEmployee(employee, value = false) {
    return this.fb.group({
      employee: [employee],
      value: [value]
    })
  }

  setFormValues(){
    this.itemUnderCategoryForm.patchValue({
      serviceTitle: this.previousFormValues.serviceTitle,
      serviceUnderDescription: this.previousFormValues.serviceUnderDescription,
      servicePrice: this.previousFormValues.servicePrice,
      hasAppointment: this.previousFormValues.hasAppointment,
      bufferTime: this.previousFormValues.bufferTime,
      serviceHour: this.previousFormValues.serviceHour,
      serviceMinute: this.previousFormValues.serviceMinute,
      showEmployees: this.previousFormValues.showEmployees,
      assignedTo: this.previousFormValues.assignedTo
    });
    this.selectedEmployeesIds = this.previousFormValues.assignedTo
    this.getSelectedOptionNames()
  }

  cancelCategoryUnder(){
    this.cancelClicked.emit(); 
  }

  saveUpdateToCategoryUnder(){
    let items = this.itemUnderCategoryForm.value
    items.assignedTo = this.selectedEmployeesIds;
    if(this.isEdit){
      this.editItem.emit(items);
    } else {
      this.saveItem.emit(items);
    }
  }
  saveService(values){
    
  }
  clearUnderCategoryForm(){
    this.itemUnderCategoryForm.patchValue({
      serviceTitle: '',
      serviceUnderDescription: '',
      servicePrice: '',
      hasAppointment: [],
      serviceHour: 0,
      serviceMinute: 0,
      bufferTime: 0,
      assignedTo: [],
      showEmployees: false,
    });

    this.assignedTo.clear();
    this.employees.forEach(p => {
      if (!this.assignedTo.value.some(x => x.employee.uid === p.uid)) {
        this.assignedTo.push(this.newEmployee(p));
      }

    });
  }
  employeeSelected(employee) {
    if(employee.value.value){
      this.selectedEmployeeName.push(employee.value.employee);
    } else{
      this.selectedEmployeeName = this.selectedEmployeeName.filter(sen =>{
        return sen.uid != employee.value.employee.uid
      })
    }
  }
  
}

