<div class="flex flex-col mx-4 md:mx-10 gap-y-2 min-h-max">
    <div class="w-full relative min-h-max">
        <img src="https://images.unsplash.com/photo-1705651460003-2638800d9784?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" class="rounded-md w-full h-64 blur-sm object-cover">
        <div  class="px-3 md:px-6 absolute top-16    w-full   ">
            <div class="w-full flex flex-col md:flex-row gap-8 bg-white p-8 rounded-md shadow-lg">
                <div class="md:w-1/3 w-full space-y-4">
                    <p class="font-bold text-3xl">Title of theme</p>
                    <div class="flex space-x-2">
                        <p class="text-base text-customTeal font-bold">theme type</p>
                    </div>
                    <p class="text-base text-gray-600">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Cupiditate magnam iste accusamus a quo blanditiis consectetur, odit neque, similique pariatur iusto sed debitis, fugit natus ex qui necessitatibus atque libero.</p>
                   <div class="flex flex-col gap-y-2">
                    <div class="flex space-x-2 items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                          </svg>
                        <p class="text-base">Property 1</p>                      
                    </div>
                    <div class="flex space-x-2 items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                          </svg>
                        <p class="text-base">Property 1</p>                      
                    </div><div class="flex space-x-2 items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                          </svg>
                        <p class="text-base">Property 1</p>                      
                    </div>
                   </div>
                    
                    <div class="flex flex-col gap-x-6 pt-4 gap-y-4">
                        <button
                            type="button"
                            class="inline-block rounded bg-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-customTeal-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-customTeal-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-customTeal-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
                            Use Theme
                        </button>
                        <button
                            (click)="previewTemplate = true"
                            type="button"
                            class="inline-block rounded bg-white border-2 border-customTeal px-6 pb-[6px] pt-2 text-xs text-customTeal font-medium uppercase leading-normal text-customborder-customTeal transition duration-150 ease-in-out hover:border-customTeal-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-customborder-customTeal-600 focus:border-customTeal-600 focus:text-customborder-customTeal-600 focus:outline-none focus:ring-0 active:border-customTeal-700 active:text-customborder-customTeal-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                            data-te-ripple-init>
                            Preview
                        </button>
                    </div>
                </div>

                <div class="w-full md:w-2/3">
                     <img src="https://cdn.shopify.com/theme-store/1578a56z1j9cfiirdwkk3s7jluft.jpg" alt="" class="rounded-md w-full  ">
                </div>

           
            </div>
        </div>
        
    </div>
  
</div>

<app-template-preview *ngIf="previewTemplate"  (closeModal)="previewTemplate = false"></app-template-preview>