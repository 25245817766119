import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/services/api/config.service';
import { UserService } from 'src/app/services/firebase/user.service';
import { GetAppService } from './../../../services/firebase/get-app.service';
import { EnvironmentService } from 'src/app/services/helper/env';

@Component({
  selector: 'app-app-request',
  templateUrl: './app-request.component.html',
  styleUrls: ['./app-request.component.css']
})
export class AppRequestComponent implements OnInit {
  nameOfPendingApps: string;

  constructor(private getAppService: GetAppService,
    private userService: UserService,
    private envService: EnvironmentService,
    private configService : ConfigService
    ) { }


  appRequests = [];
  descriptionModalOpen = false;
  requestDetail: any;
  p = 1;
  isPendingClicked = true;
  isAcceptedClicked = false;
  isDeclinedClicked = false;
  acceptedRequests: any[] = [];
  rejectedRequests: any[] = [];
  pendingRequests: any[] = [];
  isChecked = true;
  user: any;
  verifiedPendingAppRequests: any[] = [];
  notVerifiedPendingAppRequests: any[] = [];
  setVerfiedPendApp: any[] = [];
  extension = this.envService.link;
  displayedData: any[] = [];
  total = 0;
  itemsPerPage = 7;

 

  ngOnInit(): void {
    this.fetchRequests();
  }

  fetchRequests(): void {
    this.getAppService.getRequests().valueChanges().subscribe((requests) => {
      if (requests.length > 0) {
        this.appRequests = requests;
        this.appRequests.forEach(request => {
          if (request.status === 'Accepted') {
            this.acceptedRequests.push(request);
            this.acceptedRequests = Array.from(new Set([...this.acceptedRequests, request]));
          } else if (request.status === 'Rejected') {
            this.rejectedRequests.push(request);
            this.rejectedRequests = Array.from(new Set([...this.rejectedRequests, request]));
          } else {
            this.handlePendingRequest(request);
          }
        });
        this.p = 1; // Reset pagination to the first page
        this.updateDisplayedData();
      }
    })
  }

  handlePendingRequest(request: any): void {
    this.pendingRequests.push(request);
    this.userService.getUserByEmail(request.email).valueChanges().subscribe((user: any) => {
      this.user = user;
      if (this.user.length > 0 && this.user[0].verified) {
        this.verifiedPendingAppRequests.push(request);
        this.verifiedPendingAppRequests = Array.from(new Set([...this.verifiedPendingAppRequests, request]));
      } else {
        this.notVerifiedPendingAppRequests.push(request);
        this.notVerifiedPendingAppRequests = Array.from(new Set([...this.notVerifiedPendingAppRequests, request]));
      }
      this.p = 1; // Reset pagination to the first page
      this.updateDisplayedData(); // Ensure displayed data is updated after processing each user
    });
  }

  openRequestDetail(request: any): void {
    this.requestDetail = request;
    this.openDescriptionModalOpen();
  }

  openDescriptionModalOpen(): void {
    this.descriptionModalOpen = true;
  }

  closeDescriptionModalOpen(): void {
    this.descriptionModalOpen = false;
  }

  pendingClicked(): void {
    this.isPendingClicked = true;
    this.isAcceptedClicked = false;
    this.isDeclinedClicked = false;
    this.p = 1; // Reset pagination to the first page
    this.updateDisplayedData();
  }

  acceptedClicked(): void {
    this.isPendingClicked = false;
    this.isAcceptedClicked = true;
    this.isDeclinedClicked = false;
    this.p = 1; // Reset pagination to the first page
    this.updateDisplayedData();
  }

  declinedClicked(): void {
    this.isPendingClicked = false;
    this.isAcceptedClicked = false;
    this.isDeclinedClicked = true;
    this.p = 1; // Reset pagination to the first page
    this.updateDisplayedData();
  }

  clickedStatusChecker(): void {
    this.isChecked = !this.isChecked;
    this.p = 1; // Reset pagination to the first page
    this.updateDisplayedData();
  }
  updateApps(){
    this.configService.updateApps();
  }

  updateDisplayedData(): void {
    let dataArray: any[] = [];
    if (this.isAcceptedClicked) {
      dataArray = this.getSortedList(this.acceptedRequests);
    } else if (this.isPendingClicked && this.isChecked) {
      dataArray = this.getSortedList(this.verifiedPendingAppRequests);
    } else if (this.isPendingClicked && !this.isChecked) {
      dataArray = this.getSortedList(this.notVerifiedPendingAppRequests);
    } else if (this.isDeclinedClicked) {
      dataArray = this.getSortedList(this.rejectedRequests);
    }

    this.total = dataArray.length;
    const start = (this.p - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;
    this.displayedData = dataArray.slice(start, end);
  }

  getSortedList(unsortedElements: any[]): any[] {
    return unsortedElements.sort((a, b) => b.requestDate - a.requestDate);
  }

  pageChangeEvent(event: number): void {
    this.p = event;
    this.updateDisplayedData();
  }
}
