import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { UserService } from './../../../services/firebase/user.service';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl, FormControl } from '@angular/forms';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { GlobalConstants } from 'src/app/models/GlobalConstants';

function passwordConfirming(c: AbstractControl): any {
  if (!c.parent || !c) { return; }
  const pwd = c.parent.get('createPassword');
  const cpwd = c.parent.get('confirmPassword');

  if (!pwd || !cpwd) { return; }
  if (pwd.value !== cpwd.value) {
    return { invalid: true };
  }
}

// declare var $: any;
// declare var M: any;


@Component({
  selector: 'app-register-admin',
  templateUrl: './register-admin.component.html',
  styleUrls: ['./register-admin.component.css']
})
export class RegisterAdminComponent implements OnInit {

  isDOBValid: any;
  requestTojoinForm: UntypedFormGroup;
  public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')',
    ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  adminExists: any;
  appSettings: any;
  appLogo: any;

  signupSettings;
  sideImage;
  backDropColor;
  appSections = GlobalConstants.AppSections;

  constructor( private fb: UntypedFormBuilder, private authService: AuthService, private userService: UserService,
               private appSettingsService: AppSettingsService) { }
  get firstName() { return this.requestTojoinForm.get('firstName'); }
  get middleName() { return this.requestTojoinForm.get('middleName'); }
  get lastName() { return this.requestTojoinForm.get('lastName'); }
  get email() { return this.requestTojoinForm.get('email'); }
  get cellPhone() { return this.requestTojoinForm.get('cellPhone'); }
  get address() { return this.requestTojoinForm.get('address'); }
  get city() { return this.requestTojoinForm.get('city'); }
  get createPassword() { return this.requestTojoinForm.get('createPassword'); }
  get confirmPassword() { return this.requestTojoinForm.get('confirmPassword'); }

  ngOnInit(): void {
    this.userService.getAllAdmins().valueChanges().subscribe(admins => {
      if (admins.length > 0){
        this.adminExists = true;
      }
    });
    this.requestTojoinForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      middleName: [''],
      cellPhone: [''],
      email: ['', [Validators.required, Validators.email]],
      address: [''],
      city: [''],
      createPassword: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required, passwordConfirming]],
      serviceType: ['', [Validators.required]]
    });
    this.appSettingsService.getAppSettingsList().valueChanges().subscribe((response) => {
      if (response.length > 0){
        this.appSettings = response[0];
      }
    });
    this.appSettingsService.getSignupSettingsList().valueChanges().subscribe(settings => {
      if(settings){
        if(settings.length> 0){
          this.signupSettings = settings[0]
          this.sideImage = this.signupSettings.logo && this.signupSettings.logo !='' ? this.signupSettings.logo : ""
          this.backDropColor = this.signupSettings.backDropColor
        }
      }
    })

    // $('select').formSelect();
  }

  dateChange(e: any) {
    if (e) {
      const selectedDate = e.split('/');
      const currentYear = new Date().getFullYear();
      if (Number(selectedDate[2])) {
        const year = Number(selectedDate[2]);
        if (Number(year) >= Number(currentYear)) {
          this.isDOBValid = false;
          return;
        } else if (Number(year) <= Number(currentYear - 100)) {
          this.isDOBValid = false;
          return;
        } else {
          this.isDOBValid = true;
          return;
        }
      }
    }

  }

  register(data: any) {
    const postObj = {
      Address: data.address,
      cellPhone: data.cellPhone,
      City: data.city,
      Email: data.email,
      GivenName: data.firstName,
      LastName: data.lastName,
      MiddleName: data.middleName,
      role: 'Admin',
      status: 'Enabled',
      isAdminApproved: false
    };
    if (!this.adminExists){
      postObj.isAdminApproved = true;
      this.authService.emailSignUp(postObj, data.createPassword).then(() => {
        {
          if (this.appSettings){
            this.appSettings.serviceType = data.serviceType;
            this.appSettings.appSections = this.appSections;
            this.appSettingsService.updateAppSettings(this.appSettings);
          } else {
            this.appSettings = {
              appName: postObj.GivenName,
              buttonOption: 'rectangularbutton',
              description: '',
              logo: '',
              navbarOption: 'sidenavbar',
              rightOrLeftSidebar: false,
              rightOrLeftSider: 'right',
              themeColor: '',
              appSections: this.appSections

            };
            this.appSettingsService.saveAppSettings(this.appSettings);
          }
          // this.AppSettingsService.
        }
      }).catch(error => {
        // toast({ html: error.message, classes: 'red' });
      });
    } else if (this.adminExists){
      const reqObj = {
        email: data.email,
        requestDate: new Date()
      };
      this.authService.adminRegReq(postObj, data.createPassword).then(() => {
        this.userService.requestToBeAdmin(reqObj);
        if (this.appSettings){
          this.appSettings.serviceType = data.serviceType;
          this.appSettings.appSections = this.appSections;
          this.appSettingsService.updateAppSettings(this.appSettings);
        } else {
          this.appSettings = {
            appName: postObj.GivenName,
            buttonOption: 'rectangularbutton',
            description: '',
            logo: '',
            navbarOption: 'sidenavbar',
            rightOrLeftSidebar: false,
            rightOrLeftSider: 'right',
            themeColor: '',
            appSections: this.appSections

          };
          this.appSettingsService.saveAppSettings(this.appSettings);
        }
      });
    }

  }
}

