import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';

@Component({
  selector: 'app-subscription-fail',
  templateUrl: './subscription-fail.component.html',
  styleUrls: ['./subscription-fail.component.css']
})
export class SubscriptionFailComponent implements OnInit {

  userID : any;

  constructor(
    private authService: AuthService,
    private memberService: FireMembersService) { 
    }

  ngOnInit(): void {
    const model = {
      uid: this.authService.userID,
      hasActiveStripeSession: false,
      stripeSessionID: ''
    };
    this.memberService.updateAccount(model).then(x => {console.log('updated');
    })
  }

}
