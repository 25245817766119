import { Component, OnInit } from '@angular/core';
import { AyrshareService } from 'src/app/services/social-media/ayrshare.service';
import {  platforms, PostModel, UserProfile, UserProfileModel } from 'src/app/models/SocialMediaPostModel';
import { FormArray, UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { environment } from 'src/environments/environment';
import { AyshareUserService } from 'src/app/services/firebase/ayrshare-social-media.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { HttpClient } from '@angular/common/http';
import { UserService } from 'src/app/services/firebase/user.service';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { EmailService } from 'src/app/services/api/email.service';
import { EmailModel } from 'src/app/models/emailModel';
import { ActivatedRoute, Router } from '@angular/router';



@Component({
  selector: 'app-social-media',
  templateUrl: './social-media.component.html',
  styleUrls: ['./social-media.component.css']
})
export class SocialMediaComponent implements OnInit {



    profileForm: UntypedFormGroup;
    profileApplyForm: UntypedFormGroup;
    hasActiveAccounts=false
    user
    loading

    hasProfile=false
    userProfile

    privateKey
    url = environment.socialMedia.profileUrl
    profileKey
    showProfile=false
    employees:any

    selectedEmployee
    showProfiles=true
    selectedProfile
    // pickedProfile
    isNationApp = environment.firstNation.Origin == "FNLeaders" ? true : false;

    constructor(
        private socialMediaService:AyrshareService, 
        private userProfileService:AyshareUserService,
        private userService:UserService,
        private authService: AuthService,
        private fb: UntypedFormBuilder,
        private http: HttpClient,
        private fireMembersService: FireMembersService,
        private emailService: EmailService,
        private router: Router,
        private activatedRoute: ActivatedRoute,

    ) { }

    ngOnInit() {
        this.loading = true;

        this.http.get('assets/private.key',{responseType:'text'}).subscribe(x=>{
            this.privateKey=x
        })
        this.user = this.authService.getGlobalUser()
        if(this.user){
            this.userProfileService.getProfiles().valueChanges().subscribe(p=>{
                if(p){
                    if(p.length>0){
                        this.userProfile=p
                        this.hasProfile=true
                    }

                }
            })
        }

        this.userService.getUserByRole('Employee').valueChanges().subscribe((res: any) => {
            if (res.length > 0 ){
              this.employees = res;
            }
          });
    
        this.loading = false
        this.profileForm=this.fb.group({
            title:['',[Validators.required]],
            displayTitle:[''],
            hideTitle:[true],
            employeeId:[''],
            // employee:[''],

        })

        this.profileApplyForm=this.fb.group({
            numberOfProfiles:['',[Validators.required]],
            message:[''],

        })

    }

    get title(){return this.profileForm.get('title')  }



    profilePick(index){
        this.profileKey=this.userProfile[index].profileKey
        this.showProfile=true
    }
    profileSelect (profile){
        // this.profileKey = profile.profileKey
        this.profileKey=profile.profileKey
        this.selectedProfile=profile
        // this.profileKey=profile.profileKey
        this.showProfiles=false

        this.router.navigate(['dashboard'], 
            { 
                state:
                {   profileKey:this.profileKey,
                    soliKey:Math.random()
                },
                relativeTo: this.activatedRoute
            }
            )
    }

    profileChange(event){
        let index = event.target.value
      this.selectedProfile=this.userProfile[index]
      this.profileKey=this.userProfile[index].profileKey
    //   this.profileKey=this.userProfile[index].profileKey

    }
    showProfilesList(){
        this.profileKey=''
        this.showProfiles=true
    }


    getEmailForEmployee(email) {
        let msg = new EmailModel();

        let emails: string[] = []
     
        emails.push(email)
        msg.emailList = emails;
        msg.subject =  "New Profile created";
        msg.body = "A profile has been created on  your behalf; please login to your account to create posts on your behalf."
        msg.isNationApp = this.isNationApp;


        return msg
      }
    

    createUserProfile(values){
        let model = new UserProfile()
         Object.keys(values).forEach(key=>{
            model[key] =values[key]
        })

        this.socialMediaService.createUserProfile(model).toPromise().then(x=>{
            if(x['status'] == 'success'){
                let userProfile={ }
                userProfile['refId']=x['refId']
                userProfile['userId']=this.user.uid
                userProfile['profileKey'] = x['profileKey']
                userProfile['title'] = x['title']

                if (values.employeeId !== '') userProfile['employeeId'] = values.employee.uid

                this.userProfileService.createUserProile(userProfile).then(p=>{
                    // toast({ html: 'Profile Successfully Created', classes: 'green' });
                    if (values.employeeId !== '' ){
                        let msg = this.getEmailForEmployee('soli@mynation.app')
                        this.emailService.sendEmail(msg).subscribe(x => {
        
                            if (x && x.Status == 'OK') {
                              let recipient = msg.emailList[0]
                            //   toast({ html: `Successfully Sent Email To ${recipient}!`, classes: 'green' });
                            } else {
                            //   toast({ html: 'Failed to send Email. Please Try again.', classes: 'red' });
                            }
                    
                          })
                    }

                }).catch(err=>{
                })


            }else{
            }
        }).catch(err=>{
            // toast({ html:  err.error.message, classes: 'red' });
        })
    }

    applyForProfile(values){
        let request={
            user:this.user.uid, 
            email:this.user.Email 
            
        }
    Object.keys(values).forEach(key=>{
        request[key] =values[key]
    })


        // $('.modal').modal('close');

        this.userProfileService.createProfileRequest(request).then(p=>{
            // toast({ html: 'Application For Request Successfully Sent', classes: 'green' });
        }).catch(err=>{
        })
    }


}
