import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { SMSModel } from 'src/app/models/SMSModel';
import { environment } from 'src/environments/environment';
import { ApiKeyManagerService } from './apiKeyManager.Service';
import { AppSettingsService } from '../firebase/app-settings.service';
import { AvailableNumbersModel } from 'src/app/models/AvailableNumbersModel';
import { TwilioPhoneNumberModel } from 'src/app/models/TwilioPhoneNumberModel';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class SMSService {

  accountSid = "AC4565698a05de185681a5c224f46ea317";
  authToken = "ec7a7502b267ae0632f3f46efde63754";
  phoneNumberFrom: string;

  constructor(private httpClient: HttpClient,
    private keyManager: ApiKeyManagerService,
    private appSettingsService: AppSettingsService,
    private db: AngularFirestore,
  ) {
  }

  url = environment.firstNation.apiUrl + 'api/SMS/';

  website = environment.firstNation.website;

  sendSMS(model: SMSModel): Observable<any> {
    // model.from = this.phoneNumberFrom;
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(environment.firstNation.apiUrl + 'api/SMS/sendSMS/', JSON.stringify(model), { headers });
  }

  availablePhoneLocalities(model: AvailableNumbersModel): Observable<any> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(environment.firstNation.apiUrl + 'api/SMS/ListPhoneNumbers/', JSON.stringify(model), { headers });
  }

  activePhone() {
    const headers = new HttpHeaders().set('SOCIALAPIKEY', this.keyManager.getKey());
    return this.httpClient.get<any>(environment.firstNation.apiUrl + 'api/SMS/ListOfActivePhoneNumbers/', { headers });
  }

  createPhoneNumber(model: TwilioPhoneNumberModel) {
    const headers = new HttpHeaders({
      SOCIALAPIKEY: this.keyManager.getKey(),
      'Url': window.location.origin, 'Content-Type': 'application/json; charset=utf-8'
    });
    return this.httpClient.post<any>(environment.firstNation.apiUrl + `api/SMS/createPhoneNumber/`, JSON.stringify(model), { headers });
  }


  fetchAllPhoneNumber() {
    const headers = new HttpHeaders().set('SOCIALAPIKEY', this.keyManager.getKey());

    return this.httpClient.get<any>(environment.firstNation.apiUrl + `api/AppConfig/GetAllTwilioPhoneNumber/`, { headers });

  }

  releasePhoneNumber(phoneNumber: string) {
    const headers = new HttpHeaders().set('SOCIALAPIKEY', this.keyManager.getKey());
    return this.httpClient.get<any>(environment.firstNation.apiUrl + `api/SMS/releasePhoneNumber?phoneNumber=${phoneNumber}/`, { headers });
  }

  GenerateVerification(phoneNumber: string, isNationApp: boolean) {
    const headers = new HttpHeaders().set('SOCIALAPIKEY', this.keyManager.getKey());
    return this.httpClient.get<any>(environment.firstNation.apiUrl + `api/SMS/GenerateVerification?phoneNumber=${phoneNumber}&isNationApp=${isNationApp}`, { headers });
  }

  GenerateVerificationSubscription(phoneNumber: string, isNationApp: boolean): Promise<any> {
    const headers = new HttpHeaders().set('SOCIALAPIKEY', this.keyManager.getKey());
    return firstValueFrom(
      this.httpClient.get<any>(
        `${environment.firstNation.apiUrl}api/SMS/GenerateVerification?phoneNumber=${phoneNumber}&isNationApp=${isNationApp}`,
        { headers }
      )
    );
  }

  deletePhoneNumberFromSQL(model: TwilioPhoneNumberModel) {
    const headers = new HttpHeaders({
      SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8'
    });
    return this.httpClient.post<any>(environment.firstNation.apiUrl + `api/AppConfig/DeleteTwilioPhoneNumber/`, JSON.stringify(model), { headers });
  }

  givesPhoneNumber() {
    this.appSettingsService.getAppSettingsList().valueChanges().subscribe((settings: any) => {
      if (settings && settings.length > 0) {
        this.phoneNumberFrom = settings.availablePhone;
      }
    })
    return this.phoneNumberFrom;
  }


  SaveTwilioPhoneNumber() {
    let config: any;
    let request = new XMLHttpRequest();
    try {
      request.open('GET', 'https://api.goingmobile.app/api/AppConfig/SaveTwilioPhoneNumber', false);  // `false` makes the request synchronous
      request.setRequestHeader("API-KEY", 'F1BCBF46-1225-4E49-BA69-A047C78B364A-9038C86E-7135-4588-A65E-89448B4BF13A-1C3B0AEE');
      request.setRequestHeader("Url", window.location.origin)
      request.send(null);

      if (request.status === 200) {
        config = request.responseText;
      }

      return JSON.parse(config);
    } catch (e) {
      console.error('environment:getConfig: unable to get app config : ', e);
    }

    return config;
  }

  GetAllTwilioPhoneNumber() {
    let config: any;
    let request = new XMLHttpRequest();
    try {
      request.open('GET', 'https://api.goingmobile.app/api/AppConfig/GetAllTwilioPhoneNumber', false);  // `false` makes the request synchronous
      // request.setRequestHeader("API-KEY", 'F1BCBF46-1225-4E49-BA69-A047C78B364A-9038C86E-7135-4588-A65E-89448B4BF13A-1C3B0AEE');
      request.setRequestHeader("Url", window.location.origin)
      request.send(null);

      if (request.status === 200) {
        config = request.responseText;
      }

      return JSON.parse(config);
    } catch (e) {
      console.error('environment:getAllTwilioPhoneNumber: unable to getAllTwilioPhoneNumber : ', e);
    }

    return config;
  }




  // SaveTwilioPhoneNumber(model){
  //   const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
  //   return this.httpClient.post<any>(environment.firstNation.apiUrl + 'api/AppConfig/SaveTwilioPhoneNumber/', JSON.stringify(model), { headers });
  // }

  /* countSentSMS(): Promise<number> {
     const headers = new HttpHeaders({SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8'});
     return this.httpClient.get(this.url, { headers })
       .toPromise()
       .then((response: any) => {
         const messages = response.messages;
         console.log("Message to show", messages);
         const sentSMSCount = messages.length;
         console.log("SMS to show", sentSMSCount);
         return sentSMSCount;
       })
       .catch((error) => {
         console.error('Error retrieving SMS count:', error);
         throw error;
       });
   }*/

  countSentSMS(): Promise<number> {
    const url = `https://api.twilio.com/2010-04-01/Accounts/${this.accountSid}/Messages.json`;

    const headers = {
      Authorization: 'Basic ' + btoa(`${this.accountSid}:${this.authToken}`)
    };

    return this.httpClient.get(url, { headers })
      .toPromise()
      .then((response: any) => {
        const messages = response.messages;
        const sentSMSCount = messages.length;
        return sentSMSCount;
      })
      .catch((error) => {
        console.error('Error retrieving SMS count:', error);
        throw error;
      });
  }

  countIndividualSentSMS(phoneNumber: string): Promise<number> {
    const url = `https://api.twilio.com/2010-04-01/Accounts/${this.accountSid}/Messages.json`;
    const headers = new HttpHeaders().set('Authorization', 'Basic ' + btoa(`${this.accountSid}:${this.authToken}`));

    return this.httpClient.get(url, { headers })
      .toPromise()
      .then((response: any) => {
        const messages = response.messages;
        const sentSMSCount = messages.filter((msg: any) => msg.to === phoneNumber).length;
        return sentSMSCount;
      })
      .catch((error) => {
        console.error('Error retrieving SMS count:', error);
        throw error;
      });
  }

  saveVerificationCode(model) {
    model.id = this.db.createId();
    return this.db.collection('VerificationCode').doc(model.id).set(model);
  }
  getVerificationCode(phoneNumber) {
    return this.db.collection('VerificationCode', ref => ref.where('phoneNumber', '==', phoneNumber));
  }

  // getVerificationCodeWithoutSubscription(phoneNumber: string): Promise<any> {
  //   const query = this.db.collection('VerificationCode', ref => ref.where('phoneNumber', '==', phoneNumber)).get();
  //   return firstValueFrom(query);
  // }

  getVerificationCodeWithoutSubscription(phoneNumber: string): Promise<any> {
    const query = this.db.collection('VerificationCode', ref => ref.where('phoneNumber', '==', phoneNumber)).get();

    return firstValueFrom(query).then((querySnapshot) => {
      if (querySnapshot.empty) {
        return null; // Handle the case when no documents are found
      }

      const verificationCodes = querySnapshot.docs.map(doc => doc.data());
      return verificationCodes.length === 1 ? verificationCodes[0] : verificationCodes; // Assuming you expect a single result
    });
  }

  getVerificationWithPhone(phoneNumber) {

  }
  deleteVerificationCode(model) {
    return this.db.collection('VerificationCode').doc(model.id).delete();

  }

}
