import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { filter } from 'rxjs/internal/operators/filter';
import { Platform } from '@angular/cdk/platform';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { Observable } from 'rxjs';
import { MemberModel } from 'src/app/models/memberModel';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics/analytics.service';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { ProductsServiceMenuService } from 'src/app/services/api/data.service';

@Component({
  selector: 'app-admin-settings-main',
  templateUrl: './admin-settings-main.component.html',
  styleUrls: ['./admin-settings-main.component.css']
})
export class AdminSettingsMainComponent implements OnInit {
  scrWidth: number;
  title = 'MyNation';
  displayName = environment.firstNation.displayName;
  route = '';
  model: any;
  navbarOption: any;
  themeColor: string;
  defaultThemeColor = '#077373';
  landingModel: any;
  appLogo: string;
  rightOrLeftSidebar: boolean;
  appName: any;
  isLogged = false;
  user: any;
  role: any;
  fireMember: Observable<MemberModel>;
  isAdminApproved: any;
  openSidebar = true;
  minimizedMenu = false;
  userId: any;
  servicesName;
  productsName;
  constructor(  private router: Router,
                private swUpdate: SwUpdate,
                private location: Location,
                public settingsService: AppSettingsService,
                private authService: AuthService,
                private fireMembersService: FireMembersService,
                private googleAnalyticsService: GoogleAnalyticsService,
                private productsServiceMenuService: ProductsServiceMenuService) {
    // router.events.subscribe((_: NavigationEnd) => this.route = _.url);
    // this.scrWidth = window.innerWidth;

    // this.userId = this.authService.getGlobalUser().uid;
    // this.fireMembersService.getProfileByuserID(this.userId).valueChanges().subscribe(x => {
    //   if(x) {
    //   } });
  }

  ngOnInit() {
    this.productsServiceMenuService.productsName.subscribe(p => {
      this.productsName = p;
    });
    this.productsServiceMenuService.servicesName.subscribe(p => {
      this.servicesName = p;
    });
    // this.session.startSession();
    this.rightOrLeftSidebar = true;
    this.navbarOption = 'right';
    this.model = '';

    this.landingModel = '';
    this.appLogo = '';
    this.authService.afAuth.authState.subscribe(auth => {

      if (auth) {
        this.fireMember = this.authService.getCurrentUserObservable();

        this.isLogged = true;
        this.fireMember.subscribe(data => {
          this.role = data.role;
          this.user = data;
          if (this.role === 'Admin'){
            this.isAdminApproved = data.isAdminApproved ? data.isAdminApproved : false;
          }
        });

      } else {
        this.role = '';
        this.isLogged = false;
      }
    });

    this.settingsService.getAppSettingsList().valueChanges().subscribe(
      appSettings => {
        if (appSettings) {
          if (appSettings.length > 0) {
            this.model = appSettings[0];
            document.documentElement.style.setProperty('--color-primary',
            this.model.themeColor ? this.model.themeColor : this.defaultThemeColor);
            // this.navbarOption = this.model.navbarOption;
            this.themeColor = this.model.themeColor ? this.model.themeColor : this.defaultThemeColor;
            this.appLogo = this.model.logo ? this.model.logo : '';
            this.appName = this.model.appName ? this.model.appName : 'MyNation';
            // this.rightOrLeftSidebar = this.model.rightOrLeftSidebar;
          } else {
              document.documentElement.style.setProperty('--color-primary', this.defaultThemeColor);

          }

        } else {
            document.documentElement.style.setProperty('--color-primary', this.defaultThemeColor);

        }
      }
      );

  }

  back(){
    this.location.back();
  }
  forward(){
    this.location.forward();
  }

  signOut() {
    this.authService.signOut();
  }
  toggleSideBar(){
    this.openSidebar = !this.openSidebar;
  }
  toggleMinimizeMenu(){
    this.minimizedMenu = !this.minimizedMenu;
  }


}
