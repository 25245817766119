import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  ImageModel,
  ImageUploaderResponseModel,
} from "src/app/models/ImageModel";
import { MediaService } from "src/app/services/api/media.service";

import { Camera, CameraResultType } from "@capacitor/camera";
import { ImageGenerateResponseModel } from "src/app/models/chatGptModel";
@Component({
  selector: "app-image-uploader",
  templateUrl: "./image-uploader.component.html",
  styleUrls: ["./image-uploader.component.css"],
})
export class ImageUploaderComponent implements OnInit {
  @Input() customConfig;
  @Input() isMobile = false;

  @Output() filesUploaded = new EventEmitter<File[]>();

  @Output() onImageUpload: EventEmitter<ImageUploaderResponseModel> =
    new EventEmitter<ImageUploaderResponseModel>();

  @Output() removeImage: EventEmitter<ImageUploaderResponseModel> =
    new EventEmitter<ImageUploaderResponseModel>();
  files: File[] = [];
  config = {
    upload: false,
    // 'multipleImages':false
    // "style":"py-2 px-6 bg-customTeal text-white rounded-md font-bold",
    // "customStyle":"",
    // "text":"Button",
    // "type":"button"
  };

  imageModalOpen = false;
  shake = false;
  constructor(private mediaService: MediaService) {
    if (typeof window["cordova"] !== "undefined") {
      this.isMobile = true;
    }
  }
  imagesList = [];

  // image
  imageChangedEvent: any = "";
  imgErrorMsg: any;
  imageUrl = "";
  base64Image: any;
  binDoc: File;
  useBase64 = false;

  cropModalOpen = false;

  ngOnInit(): void {
    if (this.customConfig) {
      Object.keys(this.customConfig).map((key) => {
        this.config[key] = this.customConfig[key];
      });
    }
  }

  openImageGenerateModal() {
    this.imageModalOpen = true;
  }
  closeImageGenerateModal() {
    this.imageModalOpen = false;
  }

  async handleMobilePhoto() {
    let response = new ImageUploaderResponseModel();

    try {
      let image = await Camera.getPhoto({
        quality: 100,
        allowEditing: true,
        resultType: CameraResultType.Base64,
      });

      response.binDoc = image.base64String;
      response.imgBase64 = image.base64String;

      if (this.config.upload) {
        const img = new ImageModel();
        img.imgBase64 = response.imgBase64;
        // img.imgBase64 = response.imgBase64.replace(
        //   /^data:image\/[a-z]+;base64,/,
        //   ""
        // );
        response.uploaded = this.config.upload;
        this.mediaService.uploadImage(img).subscribe((upload) => {
          if (upload) {
            response.url = upload.imgLink;
            this.onImageUpload.emit(response);
          }
        });
      } else {
        this.onImageUpload.emit(response);
      }
    } catch (error) {
      response.error = true;
      response.errorMessage = error;
      this.onImageUpload.emit(response);
    }
  }

  fileChangeEvent(event) {
    const inputt = event.target as HTMLInputElement;
    const file = inputt.files[0];

    this.files = Array.from(inputt.files);
    this.imageChangedEvent = inputt;

    this.testtt(file);
  }

  testtt(file: File) {
    let response = new ImageUploaderResponseModel();

    if (!this.validateImage(file.name)) {
      // this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';
      // this.imageUrl ='';
      response.error = true;
      response.errorMessage = "Invalid File Type, Please Select an Image File";
      this.onImageUpload.emit(response);

      return;
    }
    this.binDoc = file;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.base64Image = reader.result as string;
      this.useBase64 = true;

      response.binDoc = this.binDoc;
      response.imgBase64 = this.base64Image;
      response.uploaded = false;
      response.isMobile = this.isMobile;

      this.onImageUpload.emit(response);

      // return the base64 image
    };
  }

  onImageSelect(file: File) {
    let response = new ImageUploaderResponseModel();
    // let file = res.file;

    if (!this.validateImage(file)) {
      // this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';
      // this.imageUrl ='';
      response.error = true;
      response.errorMessage = "Invalid File Type, Please Select an Image File";
      this.onImageUpload.emit(response);

      return;
    }
    this.binDoc = file;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.base64Image = reader.result as string;
      this.useBase64 = true;

      response.binDoc = this.binDoc;
      response.imgBase64 = this.base64Image;
      response.uploaded = false;
      response.isMobile = this.isMobile;

      this.onImageUpload.emit(response);

      // return the base64 image
    };
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: "image/png" });
    return blob;
  }

  handleFileInput(files: FileList) {
    let response = new ImageUploaderResponseModel();
    if (!this.validateImage(files[0].name)) {
      response.error = true;
      response.errorMessage = "Invalid File Type, Please Select an Image File";
      this.onImageUpload.emit(response);
      // output error image
      //   this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';

      return;
    }

    response.binDoc = files[0];

    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      let base64Image = reader.result as string;
      response.imgBase64 = base64Image;

      if (this.config.upload) {
        const img = new ImageModel();
        img.imgBase64 = base64Image.replace(/^data:image\/[a-z]+;base64,/, "");
        response.uploaded = this.config.upload;
        this.mediaService.uploadImage(img).subscribe((upload) => {
          if (upload) {
            response.url = upload.imgLink;
            this.onImageUpload.emit(response);
          }
        });
      } else {
        this.onImageUpload.emit(response);
      }
    };
  }

  private validateImage(name: any) {
    const ext = name.substring(name.lastIndexOf(".") + 1);
    if (ext.toLowerCase() === "png") {
      return true;
    } else if (ext.toLowerCase() === "jpg") {
      return true;
    } else if (ext.toLowerCase() === "jpeg") {
      return true;
    } else {
      return false;
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    this.shake = true;
    this.fileChangeEvent(event);
    // Add class to highlight drop area
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    this.shake = false;
    this.fileChangeEvent(event);
    // Remove highlight class
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    if (event.dataTransfer?.files) {
      this.files = Array.from(event.dataTransfer.files);
      // this.uploadFiles(this.files);
      this.testtt(this.files[0]);
    }
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      this.files = Array.from(input.files);
      this.uploadFiles(this.files);
    }
  }

  uploadFiles(files: File[]) {
    this.mediaService.uploadFiles(files).subscribe((response) => {
      this.filesUploaded.emit(this.files);
    });
  }

  closeCroppingModal() {
    this.cropModalOpen = false;
    this.useBase64 = false;
  }

  removeFile(i: number) {
    this.files.splice(i, 1);
    this.removeImage.emit();
  }
}
