<div class="flex">
      <div class="w-full md:w-full">


        <div class="font-bold text-md mb-4 ml-8 md:ml-6">Appointment Details</div>

        <div class="bg-white rounded-lg ml-8 mr-6 md:pl-12 md:pr-6 md:mx-6" *ngIf="user && appointment">
            <div class="md:flex px-6 md:pl-0 pt-12 mb-4">

                <div class="md:w-1/3 font-bold flex-col md:flex-row mb-2"><i class="w-2 h-2 material-icons float-left pr-8">calendar_today</i>{{appointment.startTime.toDate()| date}}</div>
                <div class="md:w-1/3 font-bold flex-col md:flex-row mb-2"><i class="w-2 h-2 material-icons float-left pr-8">access_time</i>{{appointment.startTime.toDate()| date:'shortTime'}}-{{appointment.endTime.toDate()| date:'shortTime'}}</div>
                <div (click)="gotoClient(user)" class="md:w-1/3 font-bold flex-col md:flex-row mb-2"><i class="w-2 h-2 material-icons float-left pr-8">person</i>{{user.GivenName}} {{user.LastName}}</div>

            </div>

            <div class="flex flex-col md:flex-row">

                <div class="w-full px-6 md:pl-0 mb-6 pb-12" *ngIf="appointment.message">
                    <p class="text-gray-400 font-bold">Message</p>
                    {{appointment.message}}
                </div>
                <div class="w-full px-6 md:pl-0 mb-6 pb-12" *ngIf="bookedBy">
                    <p class="text-gray-400 font-bold">Booked by</p>
                    {{bookedBy.GivenName}} {{ bookedBy.LastName}}
                </div>
            </div>
            
            <div *ngIf="loggedUser" class="md:flex mr-10 mb-5 md:mr-0 items-center justify-between w-full  text-center md:text-left">
              <div *ngIf="loggedUser.role=='Admin' ||
                          this.loggedUser.uid === appointment.employeeId ||
                          this.loggedUser.uid === appointment.userId"
                class="flex-col self-center md:flex-row mt-6 md:mr-10 mb-6">
                  <button [routerLink]="['/admin/reschedule-appointment', appointment.id]"
                      class="border rounded-lg py-1 px-3 text-white text-md bg-customTeal border-customTeal">
                      <i class="material-icons material-symbols-outlined w-1 h-1 pr-8  md:float-left">history</i>
                      Reschedule appointment</button>
              </div>

          </div>
        </div>

        <div class="font-bold text-md mb-4 ml-6 md:ml-6">Employee Details</div>

        <div *ngIf="employee"
            class="flex rounded-lg md:flex flex-col items-center mx-6 md:pl-16 bg-white mt-4 py-8 md:mx-6 md:px-14 md:flex-row">
            <div class="md:items-center md:justify-between md:-mt-10">
                <!-- <img src="../../../../assets/img/profile.webp" alt="Account Image"
                                    class="rounded-full mt-4 w-24 h-24 md:w-40 md:h-40"> -->
                <div class="avatar">
                    <div class="w-32 h-32 " *ngIf="employee.profileImgLink">
                        <img loading="lazy" [src]="employee.profileImgLink" />
                    </div>
                </div>
                <div class="avatar placeholder" *ngIf="!employee.profileImgLink">
                    <div class="bg-neutral-focus text-neutral-content rounded-full w-24  h-24 mt-4 md:w-28 md:h-28">
                        <span class="text-4xl" *ngIf="employee.GivenName">{{employee.GivenName[0] | uppercase }}</span>
        
                    </div>
                </div>
        
            </div>

        <div class="flex-col px-2 md:pl-8 items-center">
            <div class="md:flex md:flex-row mb-4 items-center md:justify-between w-full">

                <div class="mt-4 md:-mt-2 flex-col md:flex-row items-center">
                    <div class="font-bold text-lg text-center md:text-left">{{employee.GivenName}} {{employee.LastName}}</div>
                    <div class="flex-row font-bold mt-2 md:mt-4 text-center md:text-left items-center">
                        <i class="material-icons float-left md:pr-2">email</i>{{employee.Email}}
                    </div>
                </div>

                <div
                    class=" flex-col md:flex-row font-bold mt-2 md:mt-6 md:ml-20 text-center md:text-left items-center" *ngIf="cellPhone">
                    <i class="material-icons float-left pr-2"> call </i>
                    {{employee.cellPhone}}
                </div>

                <div *ngIf="employee.jobTitle != ''"
                    class=" flex-col md:flex-row font-bold mt-2 md:mt-6 md:ml-20 text-center md:text-left items-center">
                    <i class="material-icons float-left pr-2"> work </i>
                    {{employee.jobTitle}}
                </div>

            </div>

            <hr />

            <!-- <div class="flex content-center text-center md:text-left items-center justify-between w-full">
                <div class="flex-row mt-4">
                    <div class="font-medium text-md">Address</div>
                    <div class="font-bold mt-3">Lorem Ipsum</div>
                </div>
                <div class="flex-row mt-4  md:mr-80">
                    <div class="font-medium text-md">City</div>
                    <div class="font-bold mt-3">{{user.City}}</div>
                </div>

            </div> -->

            <div *ngIf="loggedUser" class="md:flex mr-10 md:mr-0 items-center justify-between w-full  text-center md:text-left">
                <div *ngIf="loggedUser.role=='Admin'" class="flex-col self-center md:flex-row mt-6 md:mr-10">
                    <button [routerLink] = "['/admin/employee-detail', employee.uid]"
                        class="border rounded-lg py-1 px-3 text-white text-md bg-customTeal border-customTeal">
        
                        View {{employee.GivenName | titlecase}} profile</button>
                </div>

            </div>
        </div>
    </div>

      <br/><br/><br/>
    </div>

</div>
