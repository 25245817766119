import { Component, OnInit } from '@angular/core';
import { ProductsServiceMenuService } from 'src/app/services/api/data.service';

@Component({
  selector: 'app-public-page-setup',
  templateUrl: './public-page-setup.component.html',
  styleUrls: ['./public-page-setup.component.css']
})
export class PublicPageSetupComponent implements OnInit {

  landingPageClicked = true;
  registrationPageClicked = false;
  aboutUsClicked = false;
  aboutUsName = 'About Us';

  constructor(private productsServiceMenuService: ProductsServiceMenuService,
    ) { }

  ngOnInit(): void {
    this.productsServiceMenuService.aboutUsName.subscribe(aboutname => {
      this.aboutUsName = aboutname;
    });
  }
  selectLandingPage(){
    this.landingPageClicked = true;
    this.registrationPageClicked = false;
    this.aboutUsClicked = false;

  }
  selectRegistrationPage(){
    this.landingPageClicked = false;
    this.registrationPageClicked = true;
    this.aboutUsClicked = false;
  }
  selectAboutPage(){
    this.landingPageClicked = false;
    this.registrationPageClicked = false;
    this.aboutUsClicked = true;
  }

}
