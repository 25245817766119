import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { CustomSectionService } from 'src/app/services/firebase/custom-section.service';

@Component({
  selector: 'app-card-view-elements',
  templateUrl: './card-view-elements.component.html',
  styleUrl: './card-view-elements.component.css'
})
export class CardViewElementsComponent implements OnInit{
@Input() cardElement;
@Input() model
cardDesign =  {
  fontSize: '14px', 
  fontWeight: 'bold', 
  fontPosition: 'bottom', 
  cardShape: 'rectangle',
  imageShape: 'none', 
  imageStyle: 'full',
  width: 160,
  height: 160
}
customSectionRef: unknown;
appSettings: any;
boxStyle: {}

constructor(private settingsService: AppSettingsService, private customSectionService: CustomSectionService, private router: Router,){
  this.getSettings()
}
ngOnInit(): void {
}
  getSettings(){
    this.settingsService.getAppSettingsList().valueChanges().subscribe(appSettings => {
      if (appSettings.length > 0) {
        this.appSettings = appSettings[0];
        this.cardDesign = this.appSettings.cardDesign ? this.appSettings.cardDesign : this.cardDesign
      }
    });

  }
navigateTo(section) {
  if (section.isCustomSection){
    this.customSectionService.getCustomSectionBySectionTitle(section.originalName).valueChanges().subscribe((sec: any) => {
      if(sec.length > 0){
        this.customSectionRef = sec[0];
        if(this.customSectionRef){
          this.router.navigate(['/custom-section-list/', sec[0].id]);
        }
      }

    });

  }else{
    if(section.originalName =='Blogs'){
    this.router.navigate(['/blogs']);
  }
  else if (section.originalName == 'Events') {
    this.router.navigate(['/events']);
  }
  else if (section.originalName == 'Products') {
    this.router.navigate(['/products']);

  }
  else if (section.originalName == 'Services') {
    this.router.navigate(['/services']);
  }
  else if (section.originalName == 'About Us') {
    this.router.navigate(['/about']);
  }
  else if (section.originalName == 'Footer') {
    this.router.navigate(['/contact-us']);
  }
  else if(section.originalName =='First Nations Leaders'){
    this.router.navigate(['/nation-leaders']);
  }
  else if(section.originalName =='faq'){
    this.router.navigate(['/faq-list']);
  }
}
}

getBoxStyle(boxStyle){
  switch(boxStyle){
    case 'square':
      this.boxStyle = {

        'border-radius':'0',


      }
      break;
    case 'circle':
      this.boxStyle = {

        'border-radius':'50%',


      }
      break;
    case 'rectangle':
      this.boxStyle = {

        'border-radius':'0',
  
      }
      break;
    default:
      this.boxStyle = {

      }

  }
}

getStyle(type){
  if(type==='image'){
    this.getBoxStyle(this.cardDesign.imageShape)
    return this.boxStyle
  }else if(type==='card'){
    this.getBoxStyle(this.cardDesign.cardShape)
    return this.boxStyle
  }
  
}

}
