<div class="row center-align">
  <h5><strong> My Account </strong></h5>
</div>

<div class="row col s12">
  <div class="col l1 "></div>
  <div class="col s12 m12 l10">

      <div class="row col s12">
        <div class="col l1"></div>

        <div class="col s12 l10">

        <form (ngSubmit)="submitForm(accountUpdateForm.value)"  [formGroup]="accountUpdateForm">
          <div class="row card-panel">

            <h6><strong>Please Enter The Links To Your Social Media Accounts</strong></h6>
            <br/>

            <div class="input-field class col s12">
              <i class="fa fa-facebook prefix blue-text"></i>
              <input formControlName="facebook" id="facebook" class="validate" type="text" maxlength="250" autocomplete="off" >
              <label for="facebook"> Facebook :  </label>
              <div *ngIf="(facebook.dirty || facebook.touched) && facebook.invalid">
                <small *ngIf="facebook.errors.pattern" class="red-text"> Please enter a valid Url.
                </small>
              </div>
            </div>

            <div class="input-field class col s12">
              <i class="fa fa-twitter prefix blue-text text-lighten-2"></i>
              <input formControlName="twitter" id="twitter" class="validate" type="text" maxlength="250" autocomplete="off" >
              <label for="twitter"> Twitter :  </label>
              <div *ngIf="(twitter.dirty || twitter.touched) && twitter.invalid">
                <small *ngIf="twitter.errors.pattern" class="red-text"> Please enter a valid Url.
                </small>
              </div>
            </div>

            <div class="input-field class col s12">
              <i class="fa fa-instagram prefix" style="color: #3f729b;"></i>
              <input formControlName="instagram" id="instagram" class="validate" type="text" maxlength="250" autocomplete="off" >
              <label for="instagram"> Instagram : </label>

              <div *ngIf="(instagram.dirty || instagram.touched) && instagram.invalid">
                <small *ngIf="instagram.errors.pattern" class="red-text"> Please enter a valid Url.
                </small>
              </div>


            </div>

            <div class="input-field class col s12">
              <i class="fa fa-youtube prefix red-text"></i>
              <input formControlName="youtube" id="youtube" class="validate" type="text" maxlength="250" autocomplete="off" >
              <label for="youtube"> Youtube :  </label>
              <div *ngIf="(youtube.dirty || youtube.touched) && youtube.invalid">
                <small *ngIf="youtube.errors.pattern" class="red-text"> Please enter a valid Url.
                </small>
              </div>
            </div>

          </div>

          <div class="section"></div>

          <div class="col s12">

            <button *ngIf="hasSocial" class='at-btn col s12 btn waves-effect' style="margin-bottom: 15px;" type="submit" [disabled]="!accountUpdateForm.valid">
              <i class="material-icons right">cloud_done</i> Update Account
            </button>

            <button type="button" [ngClass]="'col s12 btn waves-effect ' + themeColor " (click)="backClicked()">
              <i class="material-icons left">arrow_back_ios</i> Back To Settings
            </button>
          </div>

        </form>

        </div>


        <div class="col l1"></div>
      </div>

  </div>
  <div class="col l1 "></div>
</div>
