<br/>
<div class="row center-align">
    <h6><strong> Employees </strong></h6>
</div>



<div class="row col s12">
  <div class="col l1 "></div>
  <div class="col s12 m12 l10">

    <app-firebase-loading *ngIf="loading"></app-firebase-loading>

    <div class="flex flex-row items-center bg-white">
      <div class="w-11/12 md:w-8/12" *ngIf="employees && employees.length > 0">
        <div class="flex">
          <!-- firstname -->
          <div>First Name</div>
          <!-- last name -->
          <div>Last Nmae</div>
          <!-- email -->
          <div>Email</div>
          <!-- schedule -->
          <div *ngIf="hasAppointment">Scheduel</div>
        </div>
        <div *ngFor="let employee of employees" class="flex flex-col">
          <div class="flex flex-row">
            <!-- first name -->
            <div class="w-3/12">
              {{employee.GivenName}}
            </div>
            <!-- last name -->
            <div class="w-3/12">
              {{employee.LastName}}
            </div>
            <!-- email -->
            <div class="w-3/12">
              {{employee.Email}}
            </div>
            <!-- action -->
            <div *ngIf="hasAppointment" class="w-3/12"> 
              <div class="custom-btn-bordered" [routerLink]="['/user/employee-appointment/', employee.uid]">View Schedule</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="row card-panel white darken-2" *ngIf="employees && employees.length>0">

      <table class="row-border centred">
        <thead>
          <tr>
              <th>Name</th>
              <th *ngIf="hasAppointment">Schedule</th>
              <th>Middle Name</th>
              <th>Last Name</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let e of employees">
            <td class=""><a [routerLink]="['/user/employee-appointment/', e.uid]"> {{ e.GivenName}} {{ e.MiddleName}}  {{ e.LastName}}</a></td>
            <td class="" *ngIf="hasAppointment"><a [routerLink]="['/user/employee-appointment/', e.uid]"> View Schedule </a></td>

          </tr>
        </tbody>
      </table>

    </div> -->

    <div *ngIf="!employees" class=""> 
      <h6  class="white-text"> 
        <strong>
        We didnt find any employees in the database.
        </strong>
      </h6> 
    </div>

    <div class="section"></div>
    <a class="custom-btn-solid" (click)="backClicked()"><i
      class="material-icons left">house</i> Back To Home Page</a>

  </div>
  <div class="col l1 "></div>
</div>

