

<div class="bg-white mx-3 md:mx-20 md:max-w-7xl  p-5 mt-3 rounded-md overflow-x-hidden md:overflow-visible" *ngIf="welcomeRef && hasAccess">


  <div class="flex justify-between">
    <div class="font-bold mb-2 text-lg  md:text-left  text-gray-600">
      Welcome popup setting
    </div>
  
  <div >
    <app-preview sectionName="Welcome Popup" [section]="welcomeRef" (click)="previewChanges()" (closePreview)="closePreviewModal()"  ></app-preview>
    <!-- <button
    class="flex items-center space-x-2 rounded bg-white text-customTeal border-2 border-customTeal px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-customTealborder-customTeal transition duration-150 ease-in-out hover:border-customTeal-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-customTealborder-customTeal-600 focus:border-customTeal-600 focus:text-customTealborder-customTeal-600 focus:outline-none focus:ring-0 active:border-customTeal-700 active:text-customTealborder-customTeal-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
    (click)="previewChanges();" style="margin-bottom: 5px;">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" class="w-4 h-4 stroke-customTeal">
      <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
      <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
    </svg>
    
    <span class="md:block hidden">
      Preview
    </span> </button> -->
  </div>
  </div>
  <div class="w-full border-b-2 border-solid border-gray-200 p-1">
  </div>

  <ng-container >  

    <div class="space-y-4 pt-4">
      <div class="flex flex-col" >
        <!-- Opening time -->
        <div class="form-control w-full max-w-x mb-3">
          <label class="label">
              <span  class="block tracking-wide text-base font-semibold ">Popup opening time(in seconds between 1 and 60) <span class="text-red-500">*</span></span>
          </label>
          <div class="flex gap-2 justify-start">
            <input type="number" min="1" max="60" placeholder="Opening time Eg. 2"
                class="w-full px-3 pb-[6px] pt-2 lg:w-8/12 appearance-none block  text-gray-700 border border-solid border-gray-300 rounded  mb-3 leading-tight focus:outline-none focus:bg-gray-50 focus:border-customTeal"
                [(ngModel)]=" welcomeRef.openingTime" />
          </div>
        </div>

        <hr>
        <!-- Signup name -->
        <div class="form-control w-full max-w-x mb-3">
          <label class="label">
              <span  class="block tracking-wide text-base font-semibold  ">Call to Action <span class="text-red-500">*</span></span>
          </label>
          <div class="flex gap-2 justify-start">
            <input type="text" required="" placeholder="Signup, Join us, Subscribe or customize your call to action"
                class="w-full px-3 pb-[6px] pt-2 lg:w-8/12 appearance-none block  text-gray-700 border border-solid border-gray-300 rounded  mb-3 leading-tight focus:outline-none focus:bg-gray-50 focus:border-customTeal"
                [(ngModel)]=" welcomeRef.callToAction" />
          </div>
        </div>
        <hr>

        <!-- Title -->
        <div class="form-control w-full max-w-x">
          <label class="label">
              <span  class="block tracking-wide text-base font-semibold  mb-2">Title </span>
          </label>
          <div class="flex gap-2 justify-start w-full" >
            <app-editor 
                [model]="welcomeRef.title" [type]=true ngDefaultControl (onModelChange)="onTitleChange($event)" [fromComponent]="'Landing page'"></app-editor>
          </div>
        </div>
        <hr>

        <!-- Description -->
        <div class="form-control w-full max-w-x">
          <label class="label">
              <span  class="block tracking-wide text-base font-semibold  mb-2">Description </span>
          </label>
          <div class="flex gap-2 justify-start">
            <app-editor 
                [model]="welcomeRef.description" (onModelChange)="onDescriptionChange($event)" [type]=true ngDefaultControl [fromComponent]="'Landing page'"></app-editor>
          </div>
        </div>
        <hr>
        <!-- <app-image-uploader></app-image-uploader> -->
        <div>
          <!-- for mobile  -->
          <label class="label">
            <span  class="block tracking-wide text-base font-semibold  mb-2">Upload Image</span>
          </label>
          <div class="flex flex-col-reverse md:flex-row justify-start gap-2">

            <div
              class="w-1/2 flex justify-center content-center h-32 max-w-x bg-white rounded-lg border-2 gap-x-3">

              <label class="custom-file-upload  self-center flex flex-col" *ngIf="!isMobile">
                  <i class="fa fa-plus-circle text-customTeal text-3xl text-center self-center"></i>
                  <input type="file" type="text" type="file" accept="image/*"
                      (change)="fileChangeEventWelcomePopup($event.target.files, welcomeRef)" />
                  <span class="block font-bold pt-1">Upload Image</span>
              </label>

              <div *ngIf="isMobile">
                  <app-image-uploader class="justify-start items-start"  (onImageUpload)=onPopupImageUpload($event) [customConfig]="{'upload':false}"></app-image-uploader>
                </div>
            </div>
            <div class="relative drop-shadow-sm px-2" *ngIf="welcomeRef.imgUrl && !base64ImageWelcomePopup">
              <img loading="lazy" [src]="welcomeRef.imgUrl" class="object-cover h-32 w-32 rounded-md cover"
                  alt="Image">
                  <button (click)="removeWelcomeImage()"
                      class="absolute top-0 translate-x-16 -translate-y-4 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                      <i class="fa fa-trash text-md"></i>
                  </button>
            </div>
            <div class="relative drop-shadow-sm px-2" *ngIf="base64ImageWelcomePopup">
              <img loading="lazy" [src]="base64ImageWelcomePopup" class="object-cover h-32 w-32 rounded-md cover"
                  alt="Image">
                  <button (click)="removeWelcomeImage()"
                      class="absolute top-0 translate-x-16 -translate-y-4 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                      <i class="fa fa-trash text-md"></i>
                  </button>
                  <div>
                  
                  </div>
            </div>
          </div>
        </div>


      </div>
    </div>

    <div class="py-10 flex md:flex-row flex-col gap-4 w-full justify-end">
    <!-- <div>
      <button 
      *ngIf="calledFromEmptyDashboard != 'Popup'"
      (click)="backBtnClicked()"
      type="button"
      class="flex space-x-1 items-center rounded border-2 text-customTeal border-customTeal px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:border-primary-accent-100 hover:bg-neutral-500 hover:bg-opacity-10 focus:border-primary-accent-100 focus:outline-none focus:ring-0 active:border-primary-accent-200 dark:text-primary-100 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
      >
      <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 fill-customTeal" viewBox="0 0 320 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg>
      <span>
        Back To Previous Page
      </span>
    </button>
    </div> -->

      <div class="form-control flex flex-row gap-x-5 justify-end ">
        <button type="button" *ngIf="calledFromEmptyDashboard == 'Popup'" 
        (click)="skipSetup()"
              class="flex space-x-1 items-center rounded border-2 text-customTeal border-customTeal px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:border-primary-accent-100 hover:bg-neutral-500 hover:bg-opacity-10 focus:border-primary-accent-100 focus:outline-none focus:ring-0 active:border-primary-accent-200 dark:text-primary-100 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                  >
  
          Skip 
        </button>
            <button *ngIf="calledFromEmptyDashboard != 'Popup'" (click)="backBtnClicked()" type="button"
            class="flex space-x-2 items-center rounded border-2 text-customTeal border-customTeal px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:border-primary-accent-100 hover:bg-neutral-500 hover:bg-opacity-10 focus:border-primary-accent-100 focus:outline-none focus:ring-0 active:border-primary-accent-200 dark:text-primary-100 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                >
        Cancel
      </button>
          <button 
              type="button"
              class="inline-block rounded px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              data-te-ripple-init
              data-te-ripple-color="light"
              [disabled]="!welcomeRef.callToAction"
              (click)="confirmUpdate(welcomeRef)"
              [ngClass]="{'bg-[#cccccc] text-[#666666]': !welcomeRef.callToAction, 'bg-customTeal': welcomeRef.callToAction}"
              >
              Save
          </button>
      </div>
  </div>
  </ng-container>
  <ng-container *ngIf="pageSections">
    <div>
      <div *ngFor="let section of pageSections.appSections">
        <ng-container *ngIf="signupModalOpen && (section.originalName=='Welcome Popup' || section.name=='WelcomePopup')">
          <app-welcome-popup [section]="section" [nationOfuse]="nationOfuse" (closeClicked)=closeSignupModal() (navigateToRegisterPage)=navigateToRegisterPageClicked() (navigateToBuildPage)=navigateToBuildPageClicked()></app-welcome-popup>
        </ng-container>

      </div>
    </div>
  </ng-container>


  </div>
<!-- toast -->
<app-alert class="fixed top-0 flex justify-end w-full z-[2000]" [toastType]="toastType" [toastMessage]="toastMessage" [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>

<!-- Edit Modal Hero page-->
<app-edit-confirmation *ngIf="editModalOpen" (cancelClicked)=closeEditModal() (saveBtnClicked)=saveWelcomePageUpdate() editedFromComponent='Welcome Page'></app-edit-confirmation>

<!-- Edit Image Modal Welcome -->
<app-crop-image *ngIf="cropModalOpenWelcomePopup" [theImage]="imageChangedEventWelcomePopup" [useBase64]="usePopupBase64" [aspectRatio]="4 / 3" (returnCroppedImage)="processCroppedImageWelcomePopup($event)" (closeCropModal)=closeCroppingModal()></app-crop-image>

<!-- Upload File Modal For Hero -->
<app-file-upload-modal *ngIf="uploadWelcomeFileModalOpen" [mediaList]="mediaList" (returnSavedMediaURLs)="getTheUrlWelcome($event)"></app-file-upload-modal>

<div *ngIf="addOpeningTimeModal">
  <div class="modal modal-open">
    <div class="modal-box">
      <h3 class="font-bold text-lg text-center mb-2">Add Opening time</h3>
          <!-- Opening time -->
          <div class="form-control w-full max-w-x mb-3">
            <label class="label">
                <span  class="block tracking-wide text-base font-semibold  mb-2">Popup opening time(in seconds between 1 and 60) <span class="text-red-500">*</span></span>
            </label>
            <div class="flex gap-2 justify-start">
              <input type="number" min="1" max="60" placeholder="Opening time Eg. 2"
                  class="w-8/12 appearance-none block text-gray-700 border-gray-300 border rounded py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  [(ngModel)]=" welcomeRef.openingTime" />
            </div>
          </div>
      <div class="modal-action items-center justify-between">
        <label (click)="closeEditModal()"
          class="py-2 px-4 text-red-500 hover:bg-red-500 hover:text-white  border-customTeal rounded-md">
          Cancel
        </label>
        <label>
          <button class="btn-custom-solid"

          [ngClass]="{'bg-[#cccccc] text-[#666666]': !welcomeRef.openingTime}"
          [disabled]="!welcomeRef.openingTime"
          type="button" (click)="saveWelcomePageUpdate()">Save Changes</button>
        </label>
      </div>
    </div>
  </div>
  
</div>