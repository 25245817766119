

    <!-- <div class="flex gap-y-4 flex-col gap-x-5 mx-auto  md:flex-row"> -->

        <!-- first blog -->
        <div 
        [ngClass]="{'w-full flex flex-col  ': preview && view=='mobile', 'w-full flex flex-col space-x-4 md:flex-row p-6  items-start ': !preview || (preview && view=='web')}"
        class=" space-y-2 cursor-pointer bg-white shadow-sm" *ngIf="posts[0]" (click)="navigate(posts[0])"  >
          <!-- img -->
          <div 
          [ngClass]="{'w-full': preview && view=='mobile', 'md:w-[380px]': !preview || (preview && view=='web')}"
           *ngIf="posts[0].mediaLink || posts[0].videoLink"  >
            <div *ngIf="posts[0].mediaLink && !posts[0].videoLink"  >
              <img [src]="posts[0].mediaLink" 
              [ngClass]="{'w-full ': preview && view=='mobile', 'md:h-56 object-contain w-full': !preview || (preview && view=='web')}"
               alt="Post Image">
            </div>

            <div *ngIf="posts[0].mediaLink && posts[0].videoLink">
              <video controls playsinline preload="none"
              [ngClass]="{'w-full ': preview && view=='mobile', 'md:h-56 object-contain w-full': !preview || (preview && view=='web')}"
               [poster]="posts[0].mediaLink">
                <source [src]="posts[0].videoLink | safeUrl"  >
                Your browser does not support HTML video.
              </video>
            </div>
            <div *ngIf="!posts[0].mediaLink && posts[0].videoLink">
              <video controls preload="none" 
              [ngClass]="{'w-full ': preview && view=='mobile', 'md:h-56 object-contain w-full': !preview || (preview && view=='web')}"
              poster="https://api.goingmobile.app/media/personal-app-assets/img/webp/empty-video.webp">
                <source [src]="posts[0].videoLink | safeUrl"  >
                Your browser does not support HTML video.
              </video>
            </div>
          </div>
          <div 
          [ngClass]="{'w-full ': preview && view=='mobile', 'md:w-[680px]': ((!preview || (preview && view=='web'))&& (posts[0].mediaLink || posts[0].videoLink)), 'md:w-full md:px-6 space-y-4': ((!preview || (preview && view=='web'))&& (!posts[0].mediaLink && !posts[0].videoLink))}"
          class=" gap-y-2" >
            
            <p class="text-sm text-customTeal font-bold">{{posts[0].timeStamp.toDate() | date: 'MMM-dd-yyyy'}}</p>
            <h1 class="text-xl font-semibold break-words ">
              {{truncateHTML(posts[0].postTitle,60)  | titlecase }}
            </h1>
            <p class="text-base text-gray-600 text-justify break-words">
              {{truncateHTML(getInnerText(posts[0].message), 350)}}
            </p>
          </div>
    
        </div>
        <!-- Next two blogs -->
<!--         
           <div class="flex flex-col w-full gap-y-4 md:w-[380px] ">
            <div class="w-full md:flex-row flex-col md:w-[380px] break-words flex gap-x-2 cursor-pointer" *ngIf="posts[1]" (click)="navigate(posts[1])">
        
              <div class="md:w-56 w-full" *ngIf="posts[1].mediaLink || posts[1].videoLink" >
                <div *ngIf="posts[1].mediaLink && !posts[1].videoLink"  >
                  <img [src]="posts[1].mediaLink" class="object-cover w-full md:w-56 h-44" alt="">
                </div>
    
                <div *ngIf="posts[1].mediaLink && posts[1].videoLink">
                  <video controls playsinline preload="none" class="object-cover w-full md:w-56 h-44" [poster]="posts[1].mediaLink">
                    <source [src]="posts[1].videoLink | safeUrl"  >
                    Your browser does not support HTML video.
                  </video>
                </div>
                <div *ngIf="!posts[1].mediaLink && posts[1].videoLink">
                  <video controls preload="none" class="object-cover md:w-56 w-full h-44" poster="https://api.goingmobile.app/media/personal-app-assets/img/webp/empty-video.webp">
                    <source [src]="posts[1].videoLink | safeUrl"  >
                    Your browser does not support HTML video.
                  </video>
                </div>
              </div>
              <div class="flex flex-col  space-y-2">
              <p class="text-sm text-customTeal font-bold">{{posts[1].timeStamp.toDate() | date: 'MMM-dd-yyyy'}}</p>
              <h1 class="text-xl font-semibold break-words">
                  {{truncateHTML(posts[1].postTitle,30)  | titlecase }}
              </h1>
              <p class="text-base text-gray-600 text-justify break-words">
                  {{truncateHTML(getInnerText(posts[1].message), 50)}}
              </p>
          
              </div>
              </div>

              
              <div class="w-full md:flex-row flex-col md:w-[380px] break-words flex gap-x-2 cursor-pointer" *ngIf="posts[2]" (click)="navigate(posts[2])" >
              
                <div class="md:w-56 w-full" *ngIf="posts[2].mediaLink || posts[2].videoLink" >
                  <div *ngIf="posts[2].mediaLink && !posts[2].videoLink"  >
                    <img [src]="posts[2].mediaLink" class="object-cover w-full md:w-56 h-32" alt="">
                  </div>
      
                  <div *ngIf="posts[2].mediaLink && posts[2].videoLink">
                    <video controls playsinline preload="none" class="object-cover w-full md:w-56 h-32" [poster]="posts[2].mediaLink">
                      <source [src]="posts[2].videoLink | safeUrl"  >
                      Your browser does not support HTML video.
                    </video>
                  </div>
                  <div *ngIf="!posts[2].mediaLink && posts[2].videoLink">
                    <video controls preload="none" class="object-cover w-full md:w-56 h-32" poster="https://api.goingmobile.app/media/personal-app-assets/img/webp/empty-video.webp">
                      <source [src]="posts[2].videoLink | safeUrl"  >
                      Your browser does not support HTML video.
                    </video>
                  </div>
                </div>
                <div class="flex flex-col  space-y-2">
                <p class="text-sm text-customTeal font-bold">{{posts[2].timeStamp.toDate() | date: 'MMM-dd-yyyy'}}</p>
                <h1 class="text-xl font-semibold break-words">
                    {{truncateHTML(posts[2].postTitle,30)  | titlecase }}
                </h1>
                <p class="text-base text-gray-600 text-justify break-words">
                    {{truncateHTML(getInnerText(posts[2].message), 50)}}
                </p>
            
                </div>
                </div>
           </div>
 
 -->

    <!-- </div> -->
