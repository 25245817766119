<app-firebase-loading *ngIf="loading"></app-firebase-loading>

<div style="padding: 3%" *ngIf="!loading">
    <div class="row" *ngIf="employeeSchedule && employeeSchedule.schedule.length>0">
        <mwl-calendar-header [(view)]="view" [(viewDate)]="viewDate" class="col s12 center" >
        </mwl-calendar-header>

        <div class="row col s12 " >
            <div class="row col l6 m12">

                <!-- <i class="material-icons medium blue-text"> date_range</i> -->
                <span style="font-size: 30px">Click on Available Time Slots to Book an Appointment</span>


            </div>
            <div class="row col l6 m12">
                <div class="roq col m4 s12">
                    <div class="col s2 segment available-segment">
                    </div>
                    <div class="col s10"><strong>Available Times</strong>

                    </div>
                </div>
                <div class="roq col m4 s12">
                    <div class="col s2 segment unavailable-segment">
                    </div>
                    <div class="col s10">
                        Unavailable Times
                    </div>
                </div>
                <div class="roq col m4 s12 ">
                    <div class="col s2 segment booked-segment">
                    </div>
                    <div class="col s10">
                        Booked Appointments
                    </div>
                </div>


            </div>
            <div class="row col s12">

                <div [ngSwitch]="view">
                    <!-- <mwl-calendar-week-view
                      [excludeDays]="excludeDays"
                    </mwl-calendar-week-view>
     -->
     <mwl-calendar-week-view
     *ngSwitchCase="CalendarView.Week"
     [viewDate]="viewDate"
     [events]="events"
     [weekStartsOn]=[weekStartsOn]
     [refresh]="refresh"
     (eventClicked)="eventClicked($event)"
     (eventTimesChanged)="eventTimesChanged($event)"
     (hourSegmentClicked)="hourSegmentClicked($event)"
     (beforeViewRender)="beforeViewRender($event)"
     [excludeDays]="excludeDays"
     [hourSegments]="4">
  </mwl-calendar-week-view>
    </div>
            </div>
        </div>



        <div>
            <mwl-calendar-header [(view)]="view" [(viewDate)]="viewDate" class="col s12 center" >
            </mwl-calendar-header>
        </div>

    </div>


    <div id="scheduleModal" class="modal">
        <div class="modal-content row">

            <form [formGroup]="createAppointmentForm" (ngSubmit)="addAppointment(createAppointmentForm.value)">
                <div class="row">
                    <div class="center">
                        <i class="material-icons"> date_range </i>
                        <span>Book an Appointment</span>
                    </div>

                    <div *ngIf="unAvailableTimeSlot">
                        <small class="red-text"> Service unavailable at picked time please pick another time slot or another service
                            of change service </small>
                    </div>

                    <div class="input-field col s12 ">

                        <a class='dropdown-trigger' href='#' data-target='dropdown1'>
                            <input id="service" formControlName='service' placeholder="Select Service to Book" disabled type="text"
                                (click)="openServiceDropdown()" style="text-transform: capitalize;"> </a>
                        <!-- <label for="service">Service <span class="red-text">*</span></label> -->

                        <ul id='dropdown1' class='dropdown-content'>
                            <li style="padding: 2%;" *ngFor="let item of employeeServices" (click)="serviceSelected(item)">
                                {{item.serviceName}}</li>

                        </ul>


                    </div>
                    <div class="input-field col m6 s12">
                        <i class="material-icons prefix purple-text">schedule</i>
                        <input id="startTime" type="text" formControlName='startTime' required class="validate"
                            autocomplete="off" style="text-transform: capitalize;" disabled />
                        <label for="startTime">Start Time <span class="red-text">*</span></label>

                    </div>

                    <div class="input-field col m6 s12">
                        <i class="material-icons prefix purple-text">schedule</i>
                        <input id="endTime" type="text" formControlName='endTime' required class="validate"
                            autocomplete="off" style="text-transform: capitalize;" disabled />
                        <label for="endTime">End Time <span class="red-text">*</span></label>

                    </div>



                    <br />
                </div>

                <button type="submit" [disabled]="!createAppointmentForm.valid" class="col s12 btn waves-effect green"
                    style="margin-bottom: 15px;">
                    <i class="material-icons right">add_circle_outline</i> Create Appointment
                </button>
                <button type="button" class="col s12 blue waves-effect btn" style="margin-bottom: 15px;"
                    (click)="closeModal()">
                    <i class="material-icons right">add_circle_outline</i> Close Modal
                </button>
            </form>

            <!-- <table>
                <tr>

                    <ul id='dropdown1' class='dropdown-content'>
                        <li style="padding: 2%;" *ngFor="let item of employeeServices" (click)="serviceSelected(item)">
                            {{item.serviceName}}</li>

                    </ul>

                </tr>

            </table> -->
        </div>

        <div class="modal-footer">

            <div class="row" style="padding: 0 5%;">

            </div>
        </div>

    </div>



    <div *ngIf="!employeeSchedule || employeeSchedule.schedule.length === 0 " class="row card-panel blue lighten-3"> <h6  class="white-text"> <strong>
        Employee Has No Schedule </strong>
      </h6> 
    </div>


    <div class="section row">
        <a [ngClass]="'col s12 btn waves-effect '" (click)="backClicked()"><i
          class="material-icons left">house</i> Back To Employee List</a>

    </div>
    <div class="col l1 "></div>
</div>



<!-- <div class="row" *ngIf="!loading ">

    <div *ngIf="!employeeSchedule || employeeSchedule.schedule.length <= 0" class="row col s12 " style="padding: 5%;">

        <h1>Employee Has no schedule yet</h1>>
    </div>



    <div>

    </div>

</div> -->
