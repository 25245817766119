import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { Observable } from 'rxjs';
import { MemberModel } from 'src/app/models/memberModel';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ProductsServiceMenuService } from 'src/app/services/api/data.service';
import { CustomSectionService } from 'src/app/services/firebase/custom-section.service';
import {
  Dropdown,
  Ripple,
  initTE,
} from "tw-elements";
import { EnvironmentService } from 'src/app/services/helper/env';
import { RoutMetaService } from 'src/app/services/helper/rout-meta.service';
import { NewsEventService } from 'src/app/services/firebase/news-event.service';
import { EmployeeServicesService } from 'src/app/services/firebase/employee-services.service';
import { ProductsService } from 'src/app/services/firebase/products.service';
import { PostsService } from 'src/app/services/firebase/posts.service';
import { AboutService } from 'src/app/services/firebase/about.service';
import { UserService } from 'src/app/services/firebase/user.service';

// declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {

  isLogged = false;
  role = '';
  nationOfuse = environment.firstNation.name;
  nationName = environment.firstNation.displayName;
  defaultThemeColor = environment.appTheme.themeColor;
  fireMember: Observable<MemberModel>;
  pageName: any;
  model: any;
  landingModel: any;
  user: any;
  @Input() nationLogo = '';
  @Input() navbarOption = '';
  @Input() themeColor = '';
  @Input() rightOrLeftSidebar = '';
  @Input() appName = '';
  menu: any[];
  showMenu = false;
  isAdminApproved: any;
  routeName: any;
  adminDashboard = false;
  socialMediaDashboard = false;
  openSidebar = true;
  appLogo: any;
  appSetting: any;

  productsName
  servicesName
  hasProductsCategory = false;
  hasServicesCategory = false;
  isNationApp = environment.firstNation.Origin == "FNLeaders" ? true : false;
  hasCustemSectionsMenu = false;
  showInstallButton = false;
  deferredPrompt: any;
  headerState = 'Home'
  isChiefApp = this.envService.isChiefApp
  eventsArray: any = [];
  pageSections: any = [];
  servicesArray: any = [];
  productsArray: any = [];
  postsArray: any = [];
  about: any = [];
  employeeGlobal=[];
  upcomingEvent: any = [];
  currentDate = new Date().toISOString().split('T')[0];

  metaTagSettings: any;

  constructor(private authService: AuthService,
              private newsEventsService: NewsEventService,
              private employeeServicesService: EmployeeServicesService,
              private postsService: PostsService,
              private productsService: ProductsService,
              private aboutService: AboutService,
              private userService: UserService, 
              private router: Router,
              private envService: EnvironmentService,
              private location: Location,
              private productsServiceMenuService:ProductsServiceMenuService,
              private customSectionService: CustomSectionService,
              private settingsService: AppSettingsService,
              private routMeta: RoutMetaService,
            ) {

    this.router.events.subscribe(val => {
      if (location.path() === '/admin/dashboard'){
        this.adminDashboard = true;
      }
      if (location.path() !== '/admin/dashboard'){
        this.adminDashboard = false;
      }
      if (location.path() === '/home') {
        this.pageName = environment.firstNation.displayName ;
      }

      if (location.path() === '') {
        this.pageName = environment.firstNation.displayName ;
        this.routeName = 'LandingPage';
        this.headerState = 'Home'
      }
      if (location.path().includes('/service/')){
        this.routeName = 'ServicesPage';
        this.headerState = 'Services'

      }
      if (location.path() === '/events-calendar'){
        this.routeName = 'ServicesPage';
        this.pageName = 'ServicesPage';
      }
      if (location.path() === '/services'){
        this.headerState = 'Services'
      }
      if (location.path() === '/blogs'){
        this.headerState = 'Blogs'
      }
      if (location.path() === '/products'){
        this.headerState = 'Products'
      }
      if (location.path() === '/about'){
        this.headerState = 'About Us'
      }
      if (location.path() === '/contact-us'){
        this.headerState = 'Contact Us'
      }
      if (location.path() === '/events'){
        this.headerState = 'Events'
      }
      if (location.path() === '/get-app'){
        this.routeName = 'Login';
        this.pageName = 'Login';
      }
      if (location.path() !== ''){
        this.routeName = 'NotLandingPage';
      }

      if (location.path() === '/auth/login') {
        this.pageName = 'Login';
      }

      if (location.path() === '/login') {
        this.pageName = 'Login';
      }

      if (location.path() === '/about-us') {
        this.pageName = 'About Us';
      }

      if (location.path().includes('/auth/register/')) {
        this.pageName = 'Members Sign Up';
      }
      if (location.path().includes('/admin/dashboard')) {
        this.pageName = 'AdminDashboard';
      }

      if (location.path().includes('auth/admin-registration')) {
        this.pageName = 'Admin Sign Up';
      }

      if (location.path() === '/registration-request') {
        this.pageName = 'Register';
      }
      if (location.path() === '/register-admin') {
        this.pageName = 'Register';
      }
      if (location.path() === '/register') {
        this.pageName = 'Register';
      }
      if (location.path().split('/')[2] === 'social-media') {
        this.routeName = 'SocialMedia';
        this.socialMediaDashboard=true
      }
      if (location.path().split('/')[2] !== 'social-media') {
        this.socialMediaDashboard=false
      }
     

      // register A2HS event
    window.addEventListener('beforeinstallprompt', (event: any) => {
      event.preventDefault();
      this.deferredPrompt = event;
      this.showInstallButton = true;
    });
    });
  }

  ngOnInit() {
    initTE({ Dropdown, Ripple });
    this.getHeaderState()

    if (this.router.url === '') {}

    this.authService.afAuth.authState.subscribe(auth => {

      if (auth) {
        this.fireMember = this.authService.getCurrentUserObservable();

        this.isLogged = true;
        this.fireMember.subscribe(data => {
          if(data){
            this.role = data.role ? data.role : '';
            this.user = data;
            if (this.role === 'Admin'){
              this.isAdminApproved = data.isAdminApproved ? data.isAdminApproved : false;
            }
          }else {
            this.role = '';
            this.isLogged = false;
          }
        });

      } else {
        this.role = '';
        this.isLogged = false;
      }
    });

    this.settingsService.getHomePageSettings().valueChanges().subscribe(hpSettings => {
      if (hpSettings.length > 0){
        this.model = hpSettings[0];
      }
    });
    this.settingsService.getAppSettingsList().valueChanges().subscribe(hp => {
      if (hp && hp.length > 0) {
          this.pageSections = hp[0];
          this.pageSections.appSections.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0))

          const eventSection = this.pageSections.appSections.filter(s => s.originalName === 'Events' && s.isEnabled);
          if (eventSection) {
            if (eventSection.length > 0) {
              this.newsEventsService.getPublicEventsList().valueChanges().subscribe(n => {
                if (n) {
                  if (n.length > 0) {
                    this.eventsArray = n;
                  }
                }
              });
            } 
          }

        this.upcomingEvent = this.getSortedList(this.eventsArray)


          const serviceSection = this.pageSections.appSections.filter(s => s.originalName === 'Services' && s.isEnabled);
          if (serviceSection) {
            if (serviceSection.length > 0) {
              this.employeeServicesService.getPublicServices().valueChanges().subscribe((ser) => {
                if (ser) {
                  if (ser.length > 0) {
                    this.servicesArray = ser;
                  }

                }
              });
            }
          }

          const productSection = this.pageSections.appSections.filter(s => s.originalName === 'Products' && s.isEnabled);
          if (productSection) {
            if (productSection.length > 0) {
              this.productsService.getProductsList().valueChanges().subscribe((prod) => {
                if (prod) {
                  if (prod.length > 0) {
                    this.productsArray = prod;
                  }

                }
              });
            } 
          } 

          const blogSection = this.pageSections.appSections.filter(s => s.originalName === 'Blogs' && s.isEnabled);
          if (blogSection) {
            if (blogSection.length > 0) {
              this.postsService.getPostsByShareType('Public').valueChanges().subscribe((blog) => {
                if (blog) {
                  if (blog.length > 0) {
                    this.postsArray = blog;
                  } 
                }
              });
            } 
          } 

   
      } 
    });

    this.settingsService.getAppSettingsList().valueChanges().subscribe(appSettings => {
      if (appSettings.length > 0 ){
        this.appSetting = appSettings[0];
        if(this.appSetting.appSections){
          this.appSetting.appSections.forEach(section => {
            if(section.isCustomSection && section.addToMenu){
              this.hasCustemSectionsMenu = true;
            }
          });
        }
        this.hasProductsCategory = this.appSetting.hasProductsCategory ? this.appSetting.hasProductsCategory : false;
        this.hasServicesCategory = this.appSetting.hasServicesCategory ? this.appSetting.hasServicesCategory : false;
      }
    });

    this.productsServiceMenuService.productsName.subscribe(p=>{
      this.productsName=p
    }
    )
    this.productsServiceMenuService.servicesName.subscribe(p=>{
      this.servicesName=p
    }
    )
    this.aboutService.getAbout().valueChanges().subscribe((aboutA) => {
      if (aboutA) {
        if (aboutA.length > 0) {
          this.about = aboutA[0];
        
        }

      }
    });
    this.userService.getUserByRoles(['Employee']).subscribe(employees => {
      if(employees.length > 0){
        employees.forEach(emp => {
          if(emp?.ShowEmployee == 'public'||emp?.ShowEmployee == 'Public'||emp?.showEmployee == 'public'||emp?.showEmployee == 'public'){
            this.employeeGlobal.push(emp)
          }
        });
      }
    });


  }

  getHeaderState(){
  if(this.router){

  }

  this.headerState = 'Home'
    
  }

  getSortedList(unsortedElements: Array<any>){
    // Filter out past events
    const futureEvents = unsortedElements.filter(event => event.eventStartDate >= this.currentDate);
    // Sort the remaining events by date
    // futureEvents.sort((a, b) => a.eventStartDate.localeCompare(b.eventStartDate));
    return futureEvents;
  }

   // prompt install
   promptInstall() {
    if (this.deferredPrompt) {
      this.deferredPrompt.prompt();
      this.deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
        this.deferredPrompt = null;
        this.showInstallButton = false;
      });
    }
  }

  
  redirectToServices(){
    if (this.hasServicesCategory){
      this.router.navigate(['/service-category']);
    }
    if (!this.hasServicesCategory){
      this.router.navigate(['/services']);
    }
  }
  redirectToProduct(){
    if (this.hasProductsCategory){
      this.router.navigate(['/product-catagory']);
    }
    if (!this.hasProductsCategory){
      this.router.navigate(['/products']);
    }
  }

  backButton(){
    // window.history.back()
    this.location.back();
  }

  openSideNav() {
    // $('#public-mobile-navbar').sidenav('open');
    // $('#member-mobile-navbar').sidenav('open');
  }

  navigate(url: any) {
    // $('.sidenav').sidenav('close');
    if (url) {
      this.router.navigate([url]);
    } else {this.router.navigate(['/']); }
  }
  signOut() {
    this.authService.signOut();
  }

  backClicked(){
    this.location.back();
  }
  forwardClicked(){
    this.location.forward();
  }
  toggleMenu(){
    const btn = document.getElementById('menu-btn');
    const nav = document.getElementById('menu');

    btn.addEventListener('click', () => {
        btn.classList.toggle('open');
        nav.classList.toggle('flex');
        nav.classList.toggle('hidden');
    });
  }

  toggleSideBar(){
    this.openSidebar = !this.openSidebar;
  }
  redirectToSectionPage(section){
    this.customSectionService.updateFromMenu(section.originalName);
    this.customSectionService.getCustomSectionBySectionTitle(section.originalName).valueChanges().subscribe((customSection: any) => {
      if(customSection.length > 0){
        this.router.navigate(['/custom-section-list/', customSection[0].id])
      }
    })
  }

  getMetaTagSettings() {
    this.settingsService.getMetadata().valueChanges().subscribe((metaTags: any) => {
      let lastIndex = metaTags?.length - 1;
      if (lastIndex || lastIndex === 0) {
        this.metaTagSettings = metaTags?.[lastIndex];
        // this.appSettingsForm.get('appTitle').setValue(this.metaTagSettings?.title)
        this.routMeta.updateTitle(metaTags[0]?.appTitle);
        // this.appSettingsForm.get('keywordSEO').setValue(this.metaTagSettings?.description);
        this.routMeta.updateDescription(metaTags[0]?.description);
        // this.appSettingsForm.get('Keywords').setValue(this.metaTagSettings?.KeyWords);
        this.routMeta.updateKeywords(metaTags[0]?.keyWords ? metaTags[0]?.keyWords : "");
  
      }
    });
  }
}

