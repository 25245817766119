<ng-container *ngIf="settings && settings.isBusinessApp">

<div class="flex flex-wrap min-h-screen bg-gray-100 justify-center pt-6 md:gap-x-8" *ngIf="settings && settings.serviceType">
  <div class="lg:w-1/4 w-full h-max hover:scale-105 hover:ease-in hover:duration-200" *ngIf="(settings.serviceType == 'Service' || settings.serviceType =='Both')">
    <div class="card bg-base-100 shadow-xl border m-5"  [routerLink]="[servicesRoute]">
      <i class="material-icons text-6xl text-center text-gray-300 mt-5">settings</i>
      <div class="card-body items-center text-center">
        <h2 class="card-title ">{{servicesName | titlecase}}</h2>
        
        <div class="card-actions mt-4">
          <button  [routerLink]="[servicesRoute]" class="btn  bg-customTeal text-white border-none px-7"> {{servicesName | titlecase}}</button>
        </div>
      </div>
    </div>
  </div>

  <!-- <ng-template #elseBlock>
    <div class="lg:w-1/3 w-full" *ngIf="currentUser.role =='Admin'">
      <div class="card bg-base-100 shadow-xl m-5"  [routerLink]="[servicesRoute]">
        <i class="material-icons text-6xl text-center text-gray-300 mt-5">settings</i>
        <div class="card-body items-center text-center">
          <h2 class="card-title">Services</h2>
          <div class="card-actions mt-4">
            <button  [routerLink]="[servicesRoute]" class="btn  bg-customTeal text-white border-none px-7"> Services</button>
          </div>
        </div>
      </div>
    </div>
  </ng-template> -->

  <div class="lg:w-1/4 w-full h-max hover:scale-105 hover:ease-in hover:duration-200"  *ngIf="(settings.serviceType == 'Product' || settings.serviceType =='Both') ">
    <div class="card bg-base-100  shadow-md m-5"  [routerLink]="[productsRoute]">
      <i class="material-icons text-6xl text-center text-gray-300 mt-5">inventory_2</i>
      <div class="card-body items-center text-center">
        <h2 class="card-title">{{productsName | titlecase}}</h2>
        <div class="card-actions mt-4">
          <button class="btn bg-customTeal text-white border-none px-7"  [routerLink]="[productsRoute]"> {{productsName | titlecase}}</button>
        </div>
      </div>
    </div>
  </div>

  <!-- <ng-template #elseBlock>

    <div class="lg:w-1/3 w-full" *ngIf="currentUser.role =='Admin'">
      <div class="card bg-base-100 shadow-xl m-5"  [routerLink]="[productsRoute]">
        <i class="material-icons text-6xl text-center text-gray-300 mt-5">inventory_2</i>
        <div class="card-body items-center text-center">
          <h2 class="card-title">Products</h2>
          <div class="card-actions mt-4">
            <button class="btn bg-customTeal text-white border-none px-7"  [routerLink]="[productsRoute]"> Products</button>
          </div>
        </div>
      </div>
    </div>
</ng-template> -->

  <!-- <div class="lg:w-1/3 w-full" *ngIf="(settings.serviceType == 'Product' || settings.serviceType =='Both')">
    <div class="card bg-base-100 shadow-xl m-5"  [routerLink]="[promotionRoute]">
      <i class="material-icons text-6xl text-center text-gray-300 mt-5">campaign</i>
      <div class="card-body items-center text-center">
        <h2 class="card-title">Promotions</h2>
        <div class="card-actions mt-4">
          <button class="btn  bg-customTeal text-white border-none px-7" [routerLink]="[promotionRoute]">Promotions</button>
        </div>
      </div>
    </div>
  </div> -->

  <!-- <ng-template #elseBlock>

    <div class="lg:w-1/3 w-full" *ngIf="currentUser.role =='Admin'">
    <div class="card bg-base-100 shadow-xl m-5"  [routerLink]="[promotionRoute]">
      <i class="material-icons text-6xl text-center text-gray-300 mt-5">campaign</i>
      <div class="card-body items-center text-center">
        <h2 class="card-title">Promotions</h2>
        <div class="card-actions mt-4">
          <button class="btn  bg-customTeal text-white border-none px-7" [routerLink]="[promotionRoute]">Promotions</button>
        </div>
      </div>
    </div>
  </div>
</ng-template> -->

</div>


<div class="flex flex-wrap min-h-screen bg-gray-100" *ngIf="(settings && !settings.serviceType) || !settings">
  <div class="lg:w-1/3 w-full">
    <div class="card bg-base-100 shadow-xl m-5"  [routerLink]="[servicesRoute]">
      <i class="material-icons text-6xl text-center text-gray-300 mt-5">settings</i>
      <div class="card-body items-center text-center">
        <h2 class="card-title">Services</h2>
        <div class="card-actions mt-4">
          <button  [routerLink]="[servicesRoute]" class="btn  bg-customTeal text-white border-none px-7"> Services</button>
        </div>
      </div>
    </div>
  </div>

  <div class="lg:w-1/3 w-full">
    <div class="card bg-base-100 shadow-xl m-5"  [routerLink]="[productsRoute]">
      <i class="material-icons text-6xl text-center text-gray-300 mt-5">inventory_2</i>
      <div class="card-body items-center text-center">
        <h2 class="card-title">Products</h2>
        <div class="card-actions mt-4">
          <button class="btn bg-customTeal text-white border-none px-7"  [routerLink]="[productsRoute]"> Products</button>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="lg:w-1/3 w-full">
    <div class="card bg-base-100 shadow-xl m-5"  [routerLink]="[promotionRoute]">
      <i class="material-icons text-6xl text-center text-gray-300 mt-5">campaign</i>
      <div class="card-body items-center text-center">
        <h2 class="card-title">Promotions</h2>
        <div class="card-actions mt-4">
          <button class="btn  bg-customTeal text-white border-none px-7" [routerLink]="[promotionRoute]">Promotions</button>
        </div>
      </div>
    </div>
  </div> -->

</div>
</ng-container>
