<div class="flex">


  <div class="w-full" *ngIf="!isAccess">

    <div class=" bg-gray-100 w-full " *ngIf="employee">
      <div class="flex md:flex-row w-full justify-end">
        
        <!-- <div class="" *ngIf="role == 'Employee'">
          <button (click)="openDeleteModalOne()" *ngIf="role == 'Employee'"
            class=" px-2 md:px-6 mr-2 md:mr-14 py-2 bg-red-600 text-white rounded-lg w-max h-min text-sm md:text-base font-bold hover:bg-darkBlue focus:outline-none ">
            <i class="material-icons material-symbols-outlined text-white w-1 h-1 pr-8 float-left">person_remove</i>
            <span>Delete Account</span>
          </button>
        </div> -->

        <div class=" "
          *ngIf="employee.status === 'Enabled'  && (isAdmin || (permissionEmp && permissionEmp.enableDisableEmp))">
          <button (click)="openDisableModal()"
            class=" px-4 md:px-6  mr-6 md:mr-14 py-2 bg-red-600 text-white rounded-lg w-max h-min text-sm md:text-base font-bold hover:bg-darkBlue focus:outline-none  ">
            <span>Disable</span>
          </button>
        </div>

        <div class=" "
          *ngIf="employee.status !== 'Enabled' && (isAdmin || (permissionEmp && permissionEmp.enableDisableEmp))">
          <button (click)="openEnableModal()"
            class=" px-4 md:px-6  mr-6 md:mr-14 py-2 bg-customTeal text-white rounded-lg w-max h-min text-sm md:text-base font-bold hover:bg-darkBlue focus:outline-none  ">
            <span>Enable</span>
          </button>
        </div>
      </div>
      <br />
      <div class="  bg-white  p-5 rounded-md space-y-4 mx-6 md:mx-14 ">
        <div class="flex flex-col md:flex-row justify-between space-y-4 md:space-y-0">
          <div class="flex flex-col md:flex-row  pt-6 space-x-4">
            <div class="flex  flex-col items-center space-y-4">

              <div class="avatar">
                <div class="w-32 h-32 rounded-full" *ngIf="employee.profileImgLink">
                  <img loading="lazy" [src]="employee.profileImgLink" />
                </div>
              </div>
              <div class="avatar placeholder" *ngIf="!employee.profileImgLink && initial">
                <div class="bg-neutral-focus text-neutral-content w-28 h-28 mt-4 md:w-32 md:h-32 rounded-full -mt-2">
                  <span class="text-5xl">{{initial[0] | uppercase }}</span>
                </div>
              </div>
              <div class="bottom-0  ">
                <button (click)="editEmployee()"
                  *ngIf="isAdmin || (permissionEmp && (permissionSpecialEmp.editEmployees ))"
                  class="border border-customTeal px-4 py-2 bg-white text-customTeal font-bold  rounded-md shadow-lg">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                    class="w-4 h-4 text-customTeal inline mr-2">
                    <path
                      d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z" />
                  </svg>
                  Edit
                </button>
              </div>

            </div>

            <!-- profile detail -->

            <div class=" flex flex-col items-center md:items-start space-y-3 h-full  ">
              <p class="font-bold text-lg pt-4 ">{{GivenName}} {{LastName}}</p>
              <div class="flex space-x-2" *ngIf="cellPhone">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-4 h-4">
                  <path
                    d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
                </svg>
                <p class="font-bold text-sm" *ngIf="cellPhone">{{cellPhone}}</p>
              </div>
              <div class="flex space-x-2">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-4 h-4">
                  <path
                    d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
                </svg>
                <p class="font-bold text-sm">{{Email}}</p>
              </div>
              <div class="flex items-end h-full ">
                <button (click)="openModal()" *ngIf="isAdmin || (permissionEmp && permissionEmp.contactUsers)"
                  class="bg-customTeal px-6 py-2 h-max text-white font-bold  rounded-md flex">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                  </svg>
                  Send Message
                </button>
              </div>
            </div>

          </div>
          <hr>

          <div
            class="flex flex-col space-y-2 md:space-y-0 md:flex-row justify-center items-center md:items-start md:justify-start space-x-4">

            <div>
              <button (click)="openResetModal()" *ngIf="(isAdmin || (permissionEmp && permissionEmp.resetPass))"
                class="border-2 border-red-400 px-3 md:px-6 py-2 text-red-400 font-bold  rounded-md">
                <i class="material-icons material-symbols-outlined text-red-500 w-1 h-1 pr-8 float-left">lock_reset</i>
                Reset Password
              </button>
            </div>

            <!-- it was !isCurrentUserAdmin -->
            <div *ngIf=" isAdmin || (permSpecial && (permSpecial.permissionAccess))">
              <button (click)="giveAccess()" *ngIf="isAdmin && !isCurrentUserAdmin"
                class="bg-customTeal px-3 md:px-6 py-2 text-white font-bold  rounded-md">
                <i class="material-icons material-symbols-outlined text-white w-1 h-1 pr-8 float-left">task_alt</i>
                Give Access
              </button>
            </div>

          </div>
        </div>
      </div>

      <ng-container *ngIf="!isCurrentUserAdmin">
        <div class="mt-2 mx-4 md:mx-14 px-8 py-6 bg-white rounded-lg"
          *ngIf="(employee.status && employee.status == 'Disabled')  && (isAdmin || (permissionEmp && permissionEmp.enableDisableEmp))">
          <span class="text-red-600 font-bold">Disabled: </span>
          <span class="mt-2 text-center">{{employee.reason}}</span>
        </div>
      </ng-container>


      <div class="  bg-white px-3 p-5 rounded-md space-y-4 mx-6 md:mx-14 mt-4">
        <div class="flex flex-col md:flex-row space-y-2 md:space-y-0 justify-between ">
          <p class="font-bold">{{GivenName}}'s Details</p>
          <div>
            <button (click)="openShowEmployee()"
              *ngIf="isAdmin || (permissionSpecialEmp && permissionSpecialEmp.editEmployees)"
              class="border-2 border-customTeal px-3 md:px-6 py-2 text-customTeal font-bold  rounded-md"
              [ngClass]="{'border-red-600 text-red-600': employee.ShowEmployee ==='No'}">
              <span *ngIf="employee.ShowEmployee !== 'No'">Show Employee to {{employee.ShowEmployee}}</span>
              <span *ngIf="employee.ShowEmployee ==='No'">Don't Show Employee</span> </button>
          </div>
        </div>
        <p class="text-justify md:text-left" *ngIf="employee.biography">{{truncateHTML(employee.biography,500)}}</p>
        <div *ngIf="!employee.jobTitle && !employee.department && !employee.biography"
          class="mb-2 mt-2 flex text-justify justify-center text-gray-400">{{GivenName}} haven't filled out their Job
          title, Department and Biography.</div>
        <div class="flex flex-col md:flex-row space-y-2 md:space-x-9 w-full  md:space-y-0">
          <div class="flex items-center space-x-3 w-max" *ngIf="employee.jobTitle">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-7 h-7">
              <path
                d="M184 48H328c4.4 0 8 3.6 8 8V96H176V56c0-4.4 3.6-8 8-8zm-56 8V96H64C28.7 96 0 124.7 0 160v96H192 320 512V160c0-35.3-28.7-64-64-64H384V56c0-30.9-25.1-56-56-56H184c-30.9 0-56 25.1-56 56zM512 288H320v32c0 17.7-14.3 32-32 32H224c-17.7 0-32-14.3-32-32V288H0V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V288z" />
            </svg>
            <div>
              <p class="text-gray-600">Job Title</p>
              <p class="font-bold">{{employee.jobTitle}}</p>
            </div>
          </div>

          <div class="flex items-center space-x-3 w-max" *ngIf="employee.department">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_3911_9815)">
                <path
                  d="M16.0004 4C15.0557 3.9986 14.1411 4.33155 13.4184 4.9399C12.6957 5.54825 12.2117 6.39273 12.052 7.32377C11.8923 8.25481 12.0672 9.21233 12.5459 10.0267C13.0245 10.8411 13.7759 11.4599 14.667 11.7733V14.6667H10.667C9.60617 14.6667 8.58875 15.0881 7.8386 15.8382C7.08846 16.5884 6.66703 17.6058 6.66703 18.6667V20.2267C5.77704 20.5412 5.02691 21.1603 4.54922 21.9744C4.07153 22.7886 3.89705 23.7454 4.05662 24.6757C4.21618 25.6061 4.69952 26.4501 5.4212 27.0585C6.14288 27.6669 7.05643 28.0007 8.00037 28.0007C8.9443 28.0007 9.85785 27.6669 10.5795 27.0585C11.3012 26.4501 11.7845 25.6061 11.9441 24.6757C12.1037 23.7454 11.9292 22.7886 11.4515 21.9744C10.9738 21.1603 10.2237 20.5412 9.3337 20.2267V18.6667C9.3337 18.313 9.47417 17.9739 9.72422 17.7239C9.97427 17.4738 10.3134 17.3333 10.667 17.3333H21.3337C21.6873 17.3333 22.0265 17.4738 22.2765 17.7239C22.5266 17.9739 22.667 18.313 22.667 18.6667V20.2267C21.777 20.5412 21.0269 21.1603 20.5492 21.9744C20.0715 22.7886 19.897 23.7454 20.0566 24.6757C20.2162 25.6061 20.6995 26.4501 21.4212 27.0585C22.1429 27.6669 23.0564 28.0007 24.0004 28.0007C24.9443 28.0007 25.8579 27.6669 26.5795 27.0585C27.3012 26.4501 27.7845 25.6061 27.9441 24.6757C28.1037 23.7454 27.9292 22.7886 27.4515 21.9744C26.9738 21.1603 26.2237 20.5412 25.3337 20.2267V18.6667C25.3337 17.6058 24.9123 16.5884 24.1621 15.8382C23.412 15.0881 22.3946 14.6667 21.3337 14.6667H17.3337V11.7733C18.2232 11.4585 18.9729 10.8394 19.4502 10.0254C19.9275 9.2114 20.1018 8.25491 19.9423 7.32488C19.7827 6.39485 19.2997 5.55112 18.5784 4.94273C17.8571 4.33434 16.944 4.00044 16.0004 4V4Z"
                  fill="black" />
              </g>
              <defs>
                <clipPath id="clip0_3911_9815">
                  <rect width="32" height="32" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <div>
              <p class="text-gray-600">Department</p>
              <p class="font-bold">{{employee.department}}</p>
            </div>
          </div>
        </div>
      </div>


      <div class="  bg-white  p-5 rounded-md space-y-4 mt-4 mx-6 md:mx-14">
        <div class="flex flex-col md:flex-row space-y-2 md:space-x-12 items-center">

          <p class="font-bold">Working Schedule</p>
          <button [routerLink]="['/admin/employee-schedule', employee.uid]"
            class=" flex justify-start text-customTeal-800 font-bold " *ngIf="isAdmin && employee">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="customTeal"
              class="w-4 h-4 text-customTeal inline mr-2">
              <path
                d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z" />
            </svg>
            {{EmpSchedule.length > 0 ? 'Update Schedule' : 'Add Schedule'}}
          </button>
        </div>

        <div *ngIf="EmpSchedule.length == 0">
          <div class="mb-2 mt-2 flex text-justify justify-center text-gray-400">
            {{GivenName}} didn't add their schedule.</div>
        </div>

        <div class="flex flex-wrap  md:divide-x-2" *ngIf="EmpSchedule.length != 0">
          <div class="p-4" *ngFor="let sched of EmpSchedule">
            <p class="text-gray-600">{{sched.day}}</p>
            <p class="font-bold">{{sched.startTime}} - {{sched.endTime}}</p>
          </div>
        </div>
      </div>


      <div class="  bg-white  p-5 rounded-md space-y-4 mx-6 md:mx-14 mt-4">
        <div class="flex space-x-12">
          <p class="font-bold">Services</p>
          <button (click)="linkService()" class=" text-customTeal font-bold " *ngIf="isAdmin">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" fill="black"
              class="w-5 h-5 text-customTeal inline mr-2">
              <path
                d="M579.8 267.7c56.5-56.5 56.5-148 0-204.5c-50-50-128.8-56.5-186.3-15.4l-1.6 1.1c-14.4 10.3-17.7 30.3-7.4 44.6s30.3 17.7 44.6 7.4l1.6-1.1c32.1-22.9 76-19.3 103.8 8.6c31.5 31.5 31.5 82.5 0 114L422.3 334.8c-31.5 31.5-82.5 31.5-114 0c-27.9-27.9-31.5-71.8-8.6-103.8l1.1-1.6c10.3-14.4 6.9-34.4-7.4-44.6s-34.4-6.9-44.6 7.4l-1.1 1.6C206.5 251.2 213 330 263 380c56.5 56.5 148 56.5 204.5 0L579.8 267.7zM60.2 244.3c-56.5 56.5-56.5 148 0 204.5c50 50 128.8 56.5 186.3 15.4l1.6-1.1c14.4-10.3 17.7-30.3 7.4-44.6s-30.3-17.7-44.6-7.4l-1.6 1.1c-32.1 22.9-76 19.3-103.8-8.6C74 372 74 321 105.5 289.5L217.7 177.2c31.5-31.5 82.5-31.5 114 0c27.9 27.9 31.5 71.8 8.6 103.9l-1.1 1.6c-10.3 14.4-6.9 34.4 7.4 44.6s34.4 6.9 44.6-7.4l1.1-1.6C433.5 260.8 427 182 377 132c-56.5-56.5-148-56.5-204.5 0L60.2 244.3z" />
            </svg>
            Link Service
          </button>
        </div>

        <div *ngIf="EmpService.length == 0 && EmpServiceUnder==0">
          <div class="mb-2 flex justify-center text-gray-400">
            {{GivenName}} is not assigned to any service.</div>
        </div>
        <br />

        <div class="flex flex-wrap  gap-4" *ngIf="EmpService.length != 0">
          <div *ngFor="let service of selectedServiceObj">
            <div class=" flex p-4 border w-full md:w-96 rounded-md justify-between ">
              <p class="font-bold"> {{truncateHTML(service.serviceName | titlecase, 50)}}</p>
              <!-- <button class=" text-red-600 font-bold ">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill="red" class="w-5 h-5  inline mr-2">
                  <path
                    d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
                </svg>
                Remove
              </button> -->
            </div>
          </div>
        </div>

        <div class="flex flex-wrap  gap-4" *ngIf="EmpServiceUnder.length != 0">
          <div *ngFor="let service of EmpServiceUnder">
            <div class=" flex p-4 border w-full md:w-96 rounded-md justify-between ">
              <p class="font-bold">{{truncateHTML(service.serviceTitle | titlecase, 50)}}</p>
            </div>
          </div>
        </div>
      </div>


      <!-- toast -->
      <app-alert class="fixed top-0 flex justify-end w-full" [toastType]="toastType" [toastMessage]="toastMessage"
        [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>


      <app-message-send [userLogged]="employee" *ngIf="openContactModal" (cancelSendClicked)="openContactModal=false"
        (sendClicked)="onSendClicked($event)">
      </app-message-send>

      <div id="my-modal-department" class="modal" *ngIf="departmentModal"
        [ngClass]="{'modal-open': departmentModal, 'modal-action': !departmentModal }">

        <div class="modal-box w-10/12 max-w-3xl">
          <div class="modal-content">
            <h3 class="font-bold text-lg text-center mb-2">Assign {{GivenName}} {{LastName}} to a Department</h3>
            <p class="text-center mt-3 mb-2">Assign the employee to the list of the departments you want them in.</p>
            <hr />
            <div class="flex justify-center flex-wrap gap-2 mt-3">
              <div *ngFor="let item of department; let i = index">
                <button (click)="selectDepartment(item)" class="border-2 border-customTeal rounded-full py-1 px-6  my-2"
                  [ngClass]="{'bg-customTeal text-white' : isDepartmentClickedmethod(item)}">
                  {{item.name}}
                </button>
              </div>
            </div>

            <div class="modal-action items-center justify-between m-4">
              <label for="my-modal-department" (click)="closeDepartmentModal()"
                class="text-red-500 font-bold">Cancel</label>
              <label for="my-model"><button class="py-2 px-4 rounded-md text-white bg-customTeal border-none"
                  type="button" (click)="assign()">Save</button></label>
            </div>
          </div>
        </div>
      </div>


      <div id="my-modal-Service" class="modal" *ngIf="openLinkServiceModal"
        [ngClass]="{'modal-open': openLinkServiceModal, 'modal-action': !openLinkServiceModal }">

        <div class="modal-box w-10/12 max-w-3xl">
          <div class="modal-content">
            <h3 class="font-bold text-lg text-center mb-2">Assign {{GivenName}} {{LastName}} to a Service</h3>
            <p class="text-center mt-3 mb-2">Assign the employee to the list of the Services you want them in.</p>
            <hr />
            <div class="flex justify-center flex-wrap gap-2 mt-3">
              <div *ngFor="let item of service; let i = index">
                <button (click)="selectServices(item)" class="border-2 border-customTeal rounded-full py-1 px-6  my-2"
                  [ngClass]="{'bg-customTeal text-white' : isServiceClickedmethod(item)}">
                  {{truncateHTML(item.serviceName, 50)}}
                </button>
              </div>
            </div>

            <div class="modal-action items-center justify-end m-4">
              <!-- <label for="my-modal-department" (click)="closeLinkService()"
                class="text-red-500 font-bold">Cancel</label> -->
              <label for="my-model"><button
                  class="flex justify-end py-2 px-4 rounded-md text-white bg-red-500 border-none" type="button"
                  (click)="assignToService()">Close</button></label>
            </div>
          </div>
        </div>

      </div>

      <div id="my-modal-biography" class="modal" *ngIf="openBiographyModal"
        [ngClass]="{'modal-open': openBiographyModal, 'modal-action': !openBiographyModal }">

        <div class="modal-box w-10/12 max-w-3xl">
          <div class="modal-content">
            <p class="text-justify text-center" *ngIf="employee.department"><span class="font-bold text-md">Department:
              </span> {{employee.department}}</p>

            <div *ngIf="employee.profileImgLink" class="flex justify-center cursor-pointer">
              <img loading="lazy" [src]="employee.profileImgLink" class="w-16 rounded-full flex justify-center items-center" />
            </div>
            <div *ngIf="!employee.profileImgLink" class="avatar placeholder flex justify-center">
              <div class="bg-neutral-focus text-neutral-content rounded-full w-16">
                <span class="text-3xl">{{GivenName[0]}}</span>
              </div>
            </div>
            <p class="text-center font-bold" *ngIf="employee.jobTitle"> {{employee.jobTitle}}</p>
            <h3 class="font-bold text-lg text-center text-customTeal my-2"> {{GivenName}} {{LastName}} Biography</h3>
            <hr />
            <p class="text-center text-justify mt-2 mb-2">{{sliced}}</p>
            <div class="modal-action items-center justify-center m-4">
              <label for="my-modal-biography" (click)="closeBiographyModal()"
                class="text-customTeal font-bold">close</label>
            </div>
          </div>
        </div>

      </div>

      <div id="my-modal-showEmployee" class="modal" *ngIf="showEmployeeModal"
        [ngClass]="{'modal-open': showEmployeeModal, 'modal-action': !showEmployeeModal }">

        <div class="modal-box ">
          <div class="modal-content">
            <p class="text-center font-bold mb-1"> Show Employees?</p>
            <p class="text-center mb-2">
              Please choose your preference on how to show the employee is it to public, subscribe's only or you dont
              want
              to show the employee at all.
            </p>
            <hr />

            <div class="flex justify-center align-middle space-x-8 mt-2 items-baseline">
              <div class="form-control">
                <label class="label cursor-pointer space-x-2">
                  <input type="radio" class="radio checked:bg-customTeal" [checked]="publicShow"
                    (click)="togglePublic($event)" />

                  <span class="label-text">Public</span>
                </label>
              </div>
              <div class="form-control">
                <label class="label cursor-pointer space-x-2">
                  <input type="radio" class="radio checked:bg-customTeal" [checked]="SubscribeShow"
                    (click)="toggleSubscribe($event)" />
                  <span class="label-text">Subscriber's Only</span>
                </label>
              </div>
              <div class="form-control">
                <label class="label cursor-pointer space-x-2">
                  <input type="radio" class="radio checked:bg-customTeal" [checked]="noShow"
                    (click)="toggleDonotshow($event)" />
                  <span class="label-text">Don't show</span>
                </label>
              </div>
            </div>
            <div class="modal-action items-center justify-end m-2 mt-4">
              <label for="my-modal-biography" (click)="closeShowEmployee()"
                class="bg-customTeal text-white rounded-md font-bold px-4 py-2">Save</label>
            </div>
          </div>
        </div>

      </div>

      <!-- Disable Account -->
      <app-modal modalTitle="Disable Account" btnName="Disable" *ngIf="disableModal"
        (cancelBtnClicked)="disableModal=false" (btnClicked)="openDisableStopModal($event)">
        <hr />
        <p class="text-center mt-3">Are you sure you want to Disable {{GivenName}} {{LastName}}'s Account?</p>
        <p class="py-2 text-center">Users with a disabled accounts won't be able to sign in.</p>
        <p class="py-2 px-10 text-gray-400 font-bold text-sm">Reason: </p>

        <textarea [(ngModel)]="reason" (input)="reason = $event.target.value"
          class="h-24 mx-4 md:mx-8 flex justify-center items-center block p-2.5 w-5/6 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 dark:placeholder-gray-400 dark:text-white"></textarea>

      </app-modal>

      <!-- Disable Account -->
      <app-modal modalTitle="Disable Account" btnName="Yes,Disable" *ngIf="disableStopModal"
        (cancelBtnClicked)="disableStopModal=false" (btnClicked)="suspendEmployee($event)">
        <hr />
        <p class="text-center mt-3">Are you sure you want to Disable {{GivenName}} {{LastName}}'s Account?
        </p>
        <p class="py-2 text-center font-bold">Users with a disabled accounts won't be able to sign in.</p>
      </app-modal>

      <!-- Enable Account -->
      <app-modal modalTitle="Enable Account" btnName="Enable" *ngIf="enableModal" (cancelBtnClicked)="enableModal=false"
        (btnClicked)="restoreEmployee($event)">
        <hr />
        <p class="text-center mt-3">Are you sure you want to Enable this Account?</p>
        <p class="py-2 text-center">Users with a enabled accounts will be able to sign in again.</p>
      </app-modal>

      <!-- Reset Modal -->
      <app-modal modalTitle="Reset {{GivenName}}'s password" btnName="Reset" *ngIf="resetModal"
        (cancelBtnClicked)="resetModal=false" (btnClicked)="resetPassword($event)">
        <p class="text-center mt-3 mb-2">Are you sure you want to reset {{GivenName}}'s password?</p>
        <hr />
      </app-modal>

      <!-- Delete Confirmation -->
      <app-modal modalTitle="Delete My Account" btnName="Yes" *ngIf="confirmDeleteModal"
        (cancelBtnClicked)="confirmDeleteModal=false" (btnClicked)="openSecondModalTwo($event)">
        <hr />
        <p class="text-center mt-3"> <span class="font-bold">{{GivenName | titlecase}},</span> are you sure you want to
          delete
          your account?</p>
        <p class="text-center mt-3">If you delete your account you will not be able to login again to {{appName}}.
          <span class="font-bold">Do you want to proceed?</span>
        </p>
      </app-modal>

      <!-- Delete Confirmation for the second time -->
      <app-modal modalTitle="Delete My Account" btnName="Yes" *ngIf="confirmSecondModal"
        (cancelBtnClicked)="confirmSecondModal=false" (btnClicked)="openThreeModal($event)">
        <hr />
        <p class="text-center mt-3"> Once you confirm you will not be able to log in to {{appName}}</p>
        <p class="text-center mt-3"> <span class="font-bold">Do you want to proceed?</span></p>

      </app-modal>

      <!-- Delete Confirmation for the third time -->
      <app-modal modalTitle="Delete My Account" btnName="Delete" *ngIf="confirmThreeModal"
        (cancelBtnClicked)="confirmThreeModal=false" (btnClicked)="beforeDelete($event)">
        <hr />
        <p class="text-center mt-3"> Are you sure?</p>
      </app-modal>

      <div id="my-modal-delete" *ngIf="beforeDeleteModal" class="modal"
        [ngClass]="{'modal-open': beforeDeleteModal, 'modal-action': !beforeDeleteModal }">
        <div class="modal-box">
          <div class="modal-content">
            <h3 class="font-bold text-lg text-center mb-2">Your Account has been deleted</h3>
            <hr />
            <p class="text-center mt-3"> Have a great day.</p>
          </div>
        </div>
      </div>

    </div>
    <br />

  </div>

</div>


<div class="flex items-center justify-center w-full" *ngIf="isAccess">
  <div class="font-body min-h-screen max-h-max pt-6 w-5/6 items-center">
    <div class="flex flex-col mx-4 md:mx-12 m-2 bg-white h-max p-8 rounded-lg space-y-4 ">
      <p class="px-4 font-bold text-lg">Grant Access</p>
      <!-- <div class="space-x-2"><input type="checkbox" class="checkbox checked:bg-customTeal mr-4 space-x-2"
          [checked]="allPriviledge" (click)="toggleAllPrivilege()" />
        Give Admin Privilege</div> -->

      <!-- Member Management -->
      <div tabindex="0" class="collapse collapse-arrow bg-gray-200 border border-base-300 bg-base-100 rounded-box mb-3"
        [ngClass]="{'collapse-open':collapsibleStates['Member'], 'collapse-close':!collapsibleStates['Member']}">
        <input type="checkbox" class="peer" (click)="selectCollapseToOpen('Member')" />
        <div class="collapse-title border-2 rounded-lg font-medium text-lg text-black">
          Member Management
        </div>
        <div class="collapse-content">
          <div class="flex flex-col w-full bg-white px-4 py-8 space-y-8 rounded-md">

            <div class="space-x-2 flex items-center"><input type="checkbox"
                class="checkbox checked:bg-customTeal mr-4 space-x-2" [checked]="allMemberPriviledge"
                (click)="toggleAllMemberPrivilege()" />
              Give Member Managment Privilege access</div>

            <div class="space-y-4">
              <div class="flex flex-col md:flex-row gap-y-4 gap-x-32 ">
                <div class="flex flex-col space-y-1 md:w-1/3">
                  <p class="text-sm text-darkGray font-bold">Subscribers</p>
                  <label class="flex flex-row w-full space-x-2 label cursor-pointer">
                    <input type="checkbox" class="checkbox checked:bg-customTeal autofill:bg-yellow-200"
                      [checked]="permMember.inviteSub" (click)="togglePermission('inviteSub', 0)" />
                    <!-- <span class="label-text">Invite Subscribers</span> -->
                    <span class="w-full label-text">Add Clients</span>
                  </label>

                  <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                    <input type="checkbox" class="checkbox checked:bg-customTeal"
                      [checked]="permMember.enableDisableSub" (click)="togglePermission('enableDisableSub', 0)"  />
                    <span class="w-full label-text">Enable/Disable Subscribers</span>
                  </label>

                </div>
                <div class="flex flex-col space-y-1 md:w-1/3">
                  <p class="text-sm text-darkGray font-bold">Employee</p>
                  <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                    <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permMember.inviteEmp"
                      (click)="togglePermission('inviteEmp', 0)" />
                    <span class="w-full label-text">Invite Employees</span>
                  </label>
                  <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                    <input type="checkbox" class="checkbox checked:bg-customTeal"
                      [checked]="permMember.enableDisableEmp" (click)="togglePermission('enableDisableEmp', 0)" />
                    <span class="w-full label-text">Enable/Disable Employees</span>
                  </label>
                  <label class="flex flex-row w-full space-x-2 label cursor-pointer">
                    <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permMember.scheduleEmp"
                      (click)="togglePermission('scheduleEmp', 0)" />
                    <span class="w-full label-text">Manage Schedule</span>
                  </label>

                  <!-- <label class="label cursor-pointer space-x-4">
                    <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="perm.individualProfile"
                      (click)="toggleIndividualProfile($event)" />
                    <span class="label-text">Edit Profile</span>
                  </label> -->
               
                </div>
                <div class="flex flex-col space-y-1 md:w-1/3" *ngIf="isVerifyUsers">
                  <p class="text-sm text-darkGray font-bold">Requests</p>
                  <label class="flex flex-row w-full space-x-2 label cursor-pointer">
                    <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permMember.acceptRequest"
                      (click)="togglePermission('acceptRequest', 0)" />
                    <span class="w-full label-text">Accept Requests</span>
                  </label>
                  <label class="flex flex-row w-full space-x-2 label cursor-pointer">
                    <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permMember.declineRequest"
                      (click)="togglePermission('declineRequest', 0)" />
                    <span class="w-full label-text">Decline Requests</span>
                  </label>
                </div>
              </div>
            </div>


            <div class="space-y-4">
              <hr>

              <div class="flex flex-col md:flex-row gap-y-4 gap-x-32 ">
                <div class="flex flex-col space-y-1 md:w-1/3">
                  <p class="text-sm text-darkGray font-bold">Departments</p>
                  <p class="flex flex-row w-full space-x-2 label cursor-pointer ">
                    <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permMember.addDepartment"
                      (click)="togglePermission('addDepartment', 0)" />
                    <span class="w-full label-text">Add Departments</span>
                  </p>

                  <p class="flex flex-row w-full space-x-2 label cursor-pointer">
                    <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permMember.editDepartment"
                      (click)="togglePermission('editDepartment', 0)" />
                    <span class="w-full label-text">Edit Departments</span>
                  </p>

                  <p class="flex flex-row w-full space-x-2 label cursor-pointer">
                    <input type="checkbox" class="checkbox checked:bg-customTeal"
                      [checked]="permMember.deleteDepartment" (click)="togglePermission('deleteDepartment', 0)" />
                    <span class="w-full label-text">Delete Departments</span>
                  </p>
                </div>

                <div class="flex flex-col space-y-1 md:w-1/3">
                  <p class="text-sm text-darkGray font-bold">Set Password and Contacting Users</p>
                  <label class="flex flex-row w-full space-x-2 label cursor-pointer">
                    <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permMember.resetPass"
                      (click)="togglePermission('resetPass', 0)" />
                    <span class="w-full label-text mr-24">Reset Password</span>
                  </label>

                  <label class="flex flex-row w-full space-x-2 label cursor-pointer">
                    <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permMember.contactUsers"
                      (click)="togglePermission('contactUsers', 0)" />
                    <span class="w-full label-text mr-24">Contact Users</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Post/Events/Services/Products -->
      <div tabindex="0" class="collapse collapse-arrow bg-gray-200 border border-base-300 bg-base-100 rounded-box mb-3"
        [ngClass]="{'collapse-open': collapsibleStates['Post'], 'collapse-close': !collapsibleStates['Post']}">
        <input type="checkbox" class="peer" (click)="selectCollapseToOpen('Post')" />
        <div class="collapse-title border-2 rounded-lg font-medium text-lg text-black">
          Posts / Events / Services / Products
        </div>

        <div class="collapse-content">
          <div class="flex flex-col w-full bg-white px-4 py-8 space-y-8 rounded-md">
            <div class="space-x-2 flex items-center"><input type="checkbox"
                class="checkbox checked:bg-customTeal mr-4 space-x-2" [checked]="allPESPriviledge"
                (click)="toggleAllPESPrivilege()" />
              Give Posts/Events/Services/Products Privilege access</div>

            <div class="space-y-4">
              <div class="flex flex-col md:flex-row gap-y-4 gap-x-32 ">
                <div class="flex flex-col space-y-1 md:w-1/3">
                  <p class="text-sm text-darkGray font-bold">Posts</p>
                  <label class="flex flex-row w-full space-x-2 label cursor-pointer">
                    <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permPES.createPosts"
                      (click)="togglePermissionPES('createPosts', 1)" />
                    <span class="w-full label-text">Create Posts</span>
                  </label>

                  <label class="flex flex-row w-full space-x-2 label cursor-pointer">
                    <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permPES.deletePost"
                      (click)="togglePermissionPES('deletePost', 1)" />
                    <span class="w-full label-text">Delete Post</span>
                  </label>

                  <label class="flex flex-row w-full space-x-2 label cursor-pointer">
                    <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permPES.editPost"
                      (click)="togglePermissionPES('editPost', 1)" />
                    <span class="w-full label-text">Edit Post</span>
                  </label>

                </div>

                <div class="flex flex-col space-y-1 md:w-1/3">
                  <p class="text-sm text-darkGray font-bold">Service</p>
                  <label class="flex flex-row w-full space-x-2 label cursor-pointer">
                    <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permPES.createServices"
                      (click)="togglePermissionPES('createServices', 1)" />
                    <span class="w-full label-text">Create Service</span>
                  </label>

                  <label class="flex flex-row w-full space-x-2 label cursor-pointer">
                    <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permPES.deleteServices"
                      (click)="togglePermissionPES('deleteServices', 1)" />
                    <span class="label-text w-full">Delete Service</span>
                  </label>

                  <label class="flex flex-row w-full space-x-2 label cursor-pointer">
                    <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permPES.editServices"
                      (click)="togglePermissionPES('editServices', 1)" />
                    <span class="w-full label-text">Edit Service</span>
                  </label>

                </div>

                <div class="flex flex-col space-y-1 md:w-1/3">
                  <p class="text-sm text-darkGray font-bold">Service Category</p>
                  <label class="flex flex-row w-full space-x-2 label cursor-pointer">
                    <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permPES.addServiceCategory"
                      (click)="togglePermissionPES('addServiceCategory', 1)" />
                    <span class="w-full label-text">Add Category</span>
                  </label>

                  <label class="flex flex-row w-full space-x-2 label cursor-pointer">
                    <input type="checkbox" class="checkbox checked:bg-customTeal"
                      [checked]="permPES.editServiceCategory" (click)="togglePermissionPES('editServiceCategory', 1)" />
                    <span class="w-full label-text">Edit/Delete Category</span>
                  </label>
                </div>

              </div>
            </div>

            <div class="space-y-4">
              <hr>
              <div class="flex flex-col md:flex-row gap-y-4 gap-x-32">
                <div class="flex flex-col space-y-1 md:w-1/3">
                  <p class="text-sm text-darkGray font-bold">Events</p>

                  <label class="flex flex-row w-full space-x-2 label cursor-pointer">
                    <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permPES.createEvents"
                      (click)="togglePermissionPES('createEvents', 1)" />
                    <span class="w-full label-text">Create Events</span>
                  </label>

                  <label class="flex flex-row w-full space-x-2 label cursor-pointer">
                    <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permPES.editEvents"
                      (click)="togglePermissionPES('editEvents', 1)" />
                    <span class="w-full label-text">Edit Events</span>
                  </label>

                  <label class="flex flex-row w-full space-x-2 label cursor-pointer">
                    <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permPES.deleteEvents"
                      (click)="togglePermissionPES('deleteEvents', 1)" />
                    <span class="w-full label-text">Delete Events</span>
                  </label>
                </div>

                <div class="flex flex-col space-y-1 md:w-1/3">
                  <p class="text-sm text-darkGray font-bold">Products</p>

                  <label class="flex flex-row w-full space-x-2 label cursor-pointer">
                    <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permPES.createProducts"
                      (click)="togglePermissionPES('createProducts', 1)" />
                    <span class="label-text w-full">Create Products</span>
                  </label>

                  <label class="flex flex-row w-full space-x-2 label cursor-pointer">
                    <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permPES.deleteProducts"
                      (click)="togglePermissionPES('deleteProducts', 1)" />
                    <span class="label-text w-full">Delete Products</span>
                  </label>

                  <label class="flex flex-row w-full space-x-2 label cursor-pointer">
                    <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permPES.editProducts"
                      (click)="togglePermissionPES('editProducts', 1)" />
                    <span class="label-text w-full">Edit Products</span>
                  </label>
                </div>

                <div class="flex flex-col space-y-1 md:w-1/3">
                  <p class="text-sm text-darkGray font-bold">Products Category</p>
                  <label class="flex flex-row w-full space-x-2 label cursor-pointer">
                    <input type="checkbox" class="checkbox checked:bg-customTeal"
                      [checked]="permPES.addCategoryProducts" (click)="togglePermissionPES('addCategoryProducts', 1)" />
                    <span class="w-full label-text">Add Category</span>
                  </label>

                  <label class="flex flex-row w-full space-x-2 label cursor-pointer">
                    <input type="checkbox" class="checkbox checked:bg-customTeal"
                      [checked]="permPES.editCategoryProducts" (click)="togglePermissionPES('editCategoryProducts', 1)" />
                    <span class="label-text w-full">Edit/delete Category</span>
                  </label>

                </div>
              </div>
            </div>


          </div>
        </div>

      </div>

      <!-- Analytics -->
      <div tabindex="0" class="collapse collapse-arrow bg-gray-200 border border-base-300 bg-base-100 rounded-box mb-3"
        [ngClass]="{'collapse-open':collapsibleStates['Analytics'], 'collapse-close':!collapsibleStates['Analytics']}">
        <input type="checkbox" class="peer" (click)="selectCollapseToOpen('Analytics')" />
        <div class="collapse-title border-2 rounded-lg font-medium text-lg text-black">
          Analytics
        </div>
        <div class="collapse-content">
          <div class="flex flex-col w-full bg-white px-4 py-8 space-y-8 rounded-md">
            <div class="space-y-4">
              <div class="flex flex-col md:flex-row gap-y-4 gap-x-32 ">
                <div class="flex flex-col space-y-1 space-x-2">
                  <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                    <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="perm.viewAnalytics"
                      (click)="toggleViewAnalytics($event)" />
                    <span class="label-text w-full font-bold text-md">View Analytics</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>

      <!-- Social Media -->
      <div tabindex="0" class="collapse collapse-arrow bg-gray-200 border border-base-300 bg-base-100 rounded-box mb-3"
        [ngClass]="{'collapse-open':collapsibleStates['Social'], 'collapse-close':!collapsibleStates['Social']}">
        <input type="checkbox" class="peer" (click)="selectCollapseToOpen('Social')" />
        <div class="collapse-title border-2 rounded-lg font-medium text-lg text-black">
          Social Media
        </div>

        <div class="collapse-content">
          <div class="flex flex-col w-full bg-white px-4 py-8 space-y-8 rounded-md">
            <div class="space-x-2 flex items-center"><input type="checkbox"
                class="checkbox checked:bg-customTeal mr-4 space-x-2" [checked]="allSocialPriviledge"
                (click)="toggleAllSocialPrivilege()" />
              Give Social Media Privilege access</div>
            <div class="space-y-4">
              <div class="flex flex-col md:flex-row gap-y-4 gap-x-32 ">
                <div class="flex flex-col space-y-1 md:w-1/3">
                  <p class="text-sm text-darkGray font-bold">Account</p>

                  <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                    <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permSocial.linkAccount"
                      (click)="togglePermissionSocial('linkAccount', 3)" />
                    <span class="label-text w-full">Link Accounts</span>
                  </label>

                  <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                    <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permSocial.unlinkAccount"
                      (click)="togglePermissionSocial('unlinkAccount', 3)" />
                    <span class="label-text w-full">Unlink Accounts</span>
                  </label>

                </div>
                <div class="flex flex-col space-y-1 md:w-1/3">
                  <p class="text-sm text-darkGray font-bold">Social Media Posts</p>
                  <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                    <input type="checkbox" class="checkbox checked:bg-customTeal"
                      [checked]="permSocial.createSocialPost" (click)="togglePermissionSocial('createSocialPost', 3)" />
                    <span class="label-text w-full">Create Posts</span>
                  </label>

                  <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                    <input type="checkbox" class="checkbox checked:bg-customTeal"
                      [checked]="permSocial.deleteSocialPost" (click)="togglePermissionSocial('deleteSocialPost', 3)" />
                    <span class="label-text w-full">Delete Posts</span>
                  </label>

                  <label class="flex flex-row w-full space-x-2 label cursor-pointer">
                    <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permSocial.editSocialPost"
                      (click)="togglePermissionSocial('editSocialPost', 3)" />
                    <span class="label-text w-full">Edit Posts</span>
                  </label>
                </div>

                <div class="flex flex-col space-y-1 md:w-1/3">
                  <p class="text-sm text-darkGray font-bold">Social Media Analytics</p>
                  <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                    <input type="checkbox" class="checkbox checked:bg-customTeal"
                      [checked]="permSocial.socialViewAnalytics" (click)="togglePermissionSocial('socialViewAnalytics', 3)" />
                    <span class="label-text w-full">View Analytics</span>
                  </label>
                </div>
              </div>
            </div>
            <div class="space-y-4">
              <div class="flex flex-col md:flex-row gap-y-4 gap-x-32 ">
                <div class="flex flex-col space-y-1 md:w-1/3">
                  <p class="text-sm text-darkGray font-bold">Profile</p>

                  <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                    <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permSocial.createProfile"
                      (click)="togglePermissionSocial('createProfile', 3)" />
                    <span class="label-text w-full">Create Profile</span>
                  </label>

                  <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                    <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permSocial.deleteProfile"
                      (click)="togglePermissionSocial('deleteProfile', 3)" />
                    <span class="label-text w-full">Delete Profile</span>
                  </label>

                </div>

              </div>
            </div>
          </div>
        </div>

      </div>

      <!-- Chat -->
      <div tabindex="0" class="collapse collapse-arrow bg-gray-200 border border-base-300 bg-base-100 rounded-box mb-3"
        [ngClass]="{'collapse-open': collapsibleStates['Chat'], 'collapse-close':!collapsibleStates['Chat']}">
        <input type="checkbox" class="peer" (click)="selectCollapseToOpen('Chat')" />
        <div class="collapse-title border-2 rounded-lg font-medium text-lg text-black">
          Chats
        </div>

        <div class="collapse-content">
          <div class="flex flex-col w-full bg-white px-4 py-8 space-y-8 rounded-md">
            <div class="space-x-2 flex items-center"><input type="checkbox"
                class="checkbox checked:bg-customTeal mr-4 space-x-2" [checked]="allChatPriviledge"
                (click)="toggleAllChatPrivilege()" />
              Give Chat Privilege access</div>
            <div class="space-y-4">
              <div class="flex flex-col md:flex-row gap-y-4 gap-x-32 ">
                <div class="flex flex-col space-y-1 md:w-1/3">
                  <p class="text-sm text-darkGray font-bold">Chat</p>

                  <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                    <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permChat.privateChat"
                      (click)="togglePermissionChat('privateChat',5)" />
                    <span class="label-text w-full">Private Chat</span>
                  </label>

                  <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                    <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permChat.groups"
                      (click)="togglePermissionChat('groups',5)" />
                    <span class="label-text w-full">Groups</span>
                  </label>

                </div>
                <div class="flex flex-col space-y-1 md:w-1/3">
                  <p class="text-sm text-darkGray font-bold">Create</p>
                  <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                    <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permChat.createGroup"
                      (click)="togglePermissionChat('createGroup',5)" />
                    <span class="label-text w-full">Create Groups</span>
                  </label>

                  <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                    <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permChat.newEmployee"
                      (click)="togglePermissionChat('newEmployee',5)" />
                    <span class="label-text w-full">New Employee Chat</span>
                  </label>


                </div>

              </div>
            </div>

          </div>
        </div>
      </div>

      <!-- Custom pages -->
      <div tabindex="0" class="collapse collapse-arrow bg-gray-200 border border-base-300 bg-base-100 rounded-box mb-3"
      [ngClass]="{'collapse-open': collapsibleStates['CustomPage'], 'collapse-close':!collapsibleStates['CustomPage']}">
      <input type="checkbox" class="peer" (click)="selectCollapseToOpen('CustomPage')" />
      <div class="collapse-title border-2 rounded-lg font-medium text-lg text-black">
        Custom pages
      </div>

      <div class="collapse-content">
        <div class="flex flex-col w-full bg-white px-4 py-8 space-y-8 rounded-md">
          <div class="space-x-2 flex items-center"><input type="checkbox"
              class="checkbox checked:bg-customTeal mr-4 space-x-2" [checked]="allCustomPagePrivilege"
              (click)="toggleAllCustomPagePrivilege()" />
            Give Custom Page privilege access</div>
          <div class="space-y-4">
            <div class="flex flex-col md:flex-row gap-y-4 gap-x-32 ">
              <div class="flex flex-col space-y-1 md:w-1/3">
                <p class="text-sm text-darkGray font-bold">Pages</p>
                <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                  <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permCustomPage.viewPage"
                      (click)="togglePermissionCustomPage('viewPage',6)" />
                  <span class="label-text w-full">View custom pages</span>
              </label>
                <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                  <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permCustomPage.createPage"
                    (click)="togglePermissionCustomPage('createPage',6)" />
                  <span class="label-text w-full">Create custom pages</span>
                </label>

                <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                  <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permCustomPage.editPage"
                    (click)="togglePermissionCustomPage('editPage',6)" />
                  <span class="label-text w-full">Edit custom pages</span>
                </label>
                <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                  <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permCustomPage.deletePage"
                    (click)="togglePermissionCustomPage('deletePage',6)" />
                  <span class="label-text w-full">Delete custom pages</span>
                </label>

              </div>
              <div class="flex flex-col space-y-1 md:w-1/3">
                <p class="text-sm text-darkGray font-bold">Items</p>
                <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                  <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permCustomPage.viewItem"
                      (click)="togglePermissionCustomPage('viewItem',6)" />
                  <span class="label-text w-full">View page items</span>
                </label>
                <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                  <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permCustomPage.createItem"
                    (click)="togglePermissionCustomPage('createItem',6)" />
                  <span class="label-text w-full">Create page Items</span>
                </label>

                <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                  <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permCustomPage.editItem"
                    (click)="togglePermissionCustomPage('editItem',6)" />
                  <span class="label-text w-full">Edit page items</span>
                </label>
                <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                  <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permCustomPage.deleteItem"
                    (click)="togglePermissionCustomPage('deleteItem',6)" />
                  <span class="label-text w-full">Delete page items</span>
                </label>

              </div>

            </div>
          </div>

        </div>
      </div>

      </div>
      <br />

      <p class="px-4 font-bold text-lg">Grant Access for Special Privilege</p>

      <!-- <div class="space-x-2 items-center"><input type="checkbox" class="checkbox checked:bg-customTeal mr-4 space-x-2"
          [checked]="allSpecialPriviledge" (click)="toggleAllSpecialPrivilege()" />
        Give All Special Privilege</div> -->

      <div tabindex="0" class="collapse collapse-arrow bg-gray-200 border border-base-300 bg-base-100 rounded-box mb-3"
        [ngClass]="{'collapse-open':collapsibleStates['Special'], 'collapse-close':!collapsibleStates['Special']}">
        <input type="checkbox" class="peer" (click)="selectCollapseToOpen('Special')" />
        <div class="collapse-title border-2 rounded-lg font-medium text-lg text-black">
          Special Application Setups
        </div>

        <div class="collapse-content">
          <div class="flex flex-col w-full bg-white px-4 py-8 space-y-8 rounded-md">
            <div class="space-y-4">
              <div class="flex flex-col md:flex-row gap-y-4 gap-x-4 ">
                <div class="flex flex-col space-y-1 md:w-1/4">
                  <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                    <input type="checkbox" class="checkbox checked:bg-customTeal"
                      [checked]="permSpecial.landingPageSetup" (click)="togglePermissionSpecial('landingPageSetup',4)" />
                    <span class="label-text w-full">Landing Page Setup</span>
                  </label>
                </div>
                <div class="flex flex-col space-y-1 md:w-1/4">
                  <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                    <input type="checkbox" class="checkbox checked:bg-customTeal"
                      [checked]="permSpecial.aboutUsPageSetup" (click)="togglePermissionSpecial('aboutUsPageSetup',4)" />
                    <span class="label-text w-full">About Us Setup</span>
                  </label>
                </div>

                <!-- <div class="flex flex-col space-y-1 md:w-1/4">
                  <label class="label cursor-pointer ">
                    <input type="checkbox" class="checkbox checked:bg-customTeal"
                      [checked]="permSpecial.registrationSetup" (click)="toggleRegistrationSetup($event)" />
                    <span class="label-text">Sign In/ Login Setup</span>
                  </label>
                </div> -->

                <div class="flex flex-col space-y-1 md:w-1/4">
                  <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                    <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permSpecial.subInfo"
                      (click)="togglePermissionSpecial('subInfo',4)" />
                    <span class="label-text w-full">Customize Subscriber's Information</span>
                  </label>
                </div>

                <div class="flex flex-col space-y-1 md:w-1/4">
                  <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                    <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permSpecial.appSetup"
                      (click)="togglePermissionSpecial('appSetup',4)" />
                    <span class="label-text w-full">App Setup</span>
                  </label>
                </div>

              </div>

              <hr />

              <div class="flex flex-col md:flex-row gap-y-4 gap-x-4 ">
                <div class="flex flex-col space-y-1 md:w-1/4">
                  <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                    <input type="checkbox" class="checkbox checked:bg-customTeal"
                      [checked]="permSpecial.accountVerification" (click)="togglePermissionSpecial('accountVerification',4)" />
                    <span class="label-text w-full">Account Verification Requests</span>
                  </label>
                </div>
                <div class="flex flex-col space-y-1 md:w-1/4">
                  <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                    <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permSpecial.editClients"
                      (click)="togglePermissionSpecial('editClients',4)" />
                    <span class="label-text w-full">Edit Clients</span>
                  </label>
                </div>

                <div class="flex flex-col space-y-1 md:w-1/4">
                  <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                    <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permSpecial.editEmployees"
                      (click)="togglePermissionSpecial('editEmployees',4)" />
                    <span class="label-text w-full">Edit Employees</span>
                  </label>
                </div>

                <div class="flex flex-col space-y-1 md:w-1/4">
                  <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                    <input type="checkbox" class="checkbox checked:bg-customTeal"
                      [checked]="permSpecial.permissionAccess" (click)="togglePermissionSpecial('permissionAccess',4)" />
                    <span class="w-full label-text">Give Permission Access</span>
                  </label>
                </div>
                <div class="flex flex-col space-y-1 md:w-1/4">
                  <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                    <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permSpecial.calendar"
                      (click)="togglePermissionSpecial('calendar',4)" />
                    <span class="w-full label-text">Calendar</span>
                  </label>

                  <!-- 
                    <label class="label cursor-pointer ">
                      <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permSpecial.calendar"
                      (click)="toggleCalendar()"/>
                      <span class="label-text">Calendar</span>
                    </label> -->

                </div>

                <div class="flex flex-col space-y-1 md:w-1/4">
                  <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                    <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permSpecial.newPage"
                      (click)="togglePermissionSpecial('newPage',4)" />
                    <span class="label-text w-full">Create new Page</span>
                  </label>
                </div>
              </div>

              <hr />
              <div class="flex flex-col md:flex-row gap-y-4 gap-x-4 ">

                <div class="flex flex-col space-y-1 md:w-1/4">
                  <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                    <input type="checkbox" class="checkbox checked:bg-customTeal"
                      [checked]="permSpecial.welcomePopup" (click)="togglePermissionSpecial('welcomePopup',4)" />
                    <span class="label-text w-full">Welcome Popup</span>
                  </label>
                </div>

                <div class="flex flex-col space-y-1 md:w-1/4">
                  <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                    <input type="checkbox" class="checkbox checked:bg-customTeal"
                      [checked]="permSpecial.importAndDelete" (click)="togglePermissionSpecial('importAndDelete',4)" />
                    <span class="label-text w-full">Import/Delete Contact</span>
                  </label>
                </div>

                <div class="flex flex-col space-y-1 md:w-1/4">
                  <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                    <input type="checkbox" class="checkbox checked:bg-customTeal"
                      [checked]="permSpecial.editFAQ" (click)="togglePermissionSpecial('editFAQ',4)" />
                    <span class="label-text w-full">Edit FAQ</span>
                  </label>
                </div>

                <div class="flex flex-col space-y-1 md:w-1/4">
                  <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                    <input type="checkbox" class="checkbox checked:bg-customTeal"
                      [checked]="permSpecial.deleteFAQ" (click)="togglePermissionSpecial('deleteFAQ',4)" />
                    <span class="label-text w-full">Delete FAQ</span>
                  </label>
                </div>


              </div>

            </div>
          </div>
        </div>


      </div>

    </div>
    <br />
    <div class="flex justify-end">
      <button (click)="permissionModal()" class="px-4 py-2 bg-customTeal rounded-md text-white">Save
        Changes</button>
    </div>
  </div>

  <!-- Save permissions for the Employee -->
  <app-modal modalTitle="{{GivenName}} {{LastName}} 's Permissions" btnName="Save" *ngIf="openPermissionModal"
    (cancelBtnClicked)="openPermissionModal=false" (btnClicked)="savePermission($event)">
    <p class="text-md text-center">Employees who are given permissions will be able to access the given privileges.
    </p>
    <hr />
    <p class="text-md text-center my-2">Are you sure you want to save the changes?</p>
  </app-modal>

</div>


<app-alert class="fixed top-0 flex justify-end w-full" [toastType]="toastType" [toastMessage]="toastMessage"
  [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>