import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class MenuSettingsService {

  collectionRef: AngularFirestoreCollection<any>;
  documentRef: AngularFirestoreDocument<any>;

  constructor(private db: AngularFirestore) { }

  // -------------- Service/ Products Menu Settings ----------------

  saveProductsServiceMenu(model: any) {
    model.id = this.db.createId();
    return this.db.collection('ProductsServiceMenu').doc(model.id).set(model);
  }

  updateProductsServiceMenu(model: any) {
    return this.db.collection('ProductsServiceMenu').doc(model.id).update(model);
  }

  getProductsServiceMenu(): AngularFirestoreCollection<any[]> {
    this.collectionRef = this.db.collection('ProductsServiceMenu');
    return this.collectionRef
  }

}