import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EmailService } from 'src/app/services/api/email.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { ContactUsService } from 'src/app/services/firebase/contact-us.service';
import { SocialMediaService } from 'src/app/services/firebase/social-media.service';
import { environment } from 'src/environments/environment';
import { Location, NgIf } from '@angular/common';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  @Input() preview

  contactUsForm: UntypedFormGroup;
  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  isNationApp = environment.firstNation.Origin == "FNLeaders" ? true : false;
  pageSections: any;
  socialMediaModel: any;
  email: any;
  contactVisibility: any;
  phoneNumber: any;
  sectionName: any;
  contactUsPage: boolean;

  constructor(private fb: UntypedFormBuilder,
              private contactUsService: ContactUsService,
              private emailService: EmailService,
              private router: Router,
              private location: Location,
              private settingsService: AppSettingsService,
              private socialService: SocialMediaService,
              private route: ActivatedRoute
    ) { 


      this.router.events.subscribe(val =>{
        if(location.path()=== '/contact-us'){
          // console.log(route.snapshot.data['title'])
          this.contactUsPage = true
        }
      })
    }

  ngOnInit(): void {
    this.contactUsForm = this.fb.group({
      email: ['', [Validators.required]],
      name: [''],
      message:['', [Validators.required]],
    });


      
    this.settingsService.getAppSettingsList().valueChanges().subscribe(hp => {
        if (hp && hp.length > 0) {
          this.pageSections = hp[0];
          const socialSection = this.pageSections.appSections.filter(s => s.originalName === 'Social Media' && s.isEnabled);
          if (socialSection) {
            if (socialSection.length > 0) {
              this.socialService.getSocialMedia().valueChanges().subscribe(n => {
                if (n) {
                  if (n.length > 0) {
                    this.socialMediaModel = n[0];
                  }
                }
              });
            }
          }

          this.pageSections.appSections.forEach(section => {
            if(section.originalName == 'Contact Us'){

            }
            if(section.originalName=='Footer' ){
              this.sectionName = section.name;
              this.email =  section.contactEmail;
              this.phoneNumber = section.phone;
              this.contactVisibility = section.isEnabled
            }
          });

        }
    })
  }

  submitForm(value){
    if(value.email && value.message){
      value.timeStamp = new Date();
      console.log("the value that is needed to be saved",value)
      this.contactUsService.saveMessage(value).then(() => {
        this.toast({html: 'Message Successfully send.', classes: 'green', type: 'success'});
        this.emailService.sendContactUsNotification(value);
        
      }).catch((err) => {
        console.log("the error is here",err);
        this.toast({html: err, classes: 'red', type: 'failure'});
  
    });
    }
  }

  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;

      this.router.navigate(['/']);
    }, 2000);
  }
}
