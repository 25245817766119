
import { Component, OnInit, Input } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounceIn } from 'ng-animate';
import { icons } from 'src/app/models/SocialMediaIcons';

@Component({
    selector: 'app-post-preview',
    templateUrl: './preview.component.html',
    styleUrls: ['./preview.component.css'], animations: [
        trigger('swing', [transition('* => *', useAnimation(bounceIn))])
    ],
})

export class SocialMediaPostPreviewComponent implements OnInit {

    constructor(    ) {

    }

    @Input() data
    socialMediaIcons=icons
    currentDate = new Date();
    ngOnInit() {

    }

    getBorder(platform){
        var classList='';
        switch (platform) {
            case 'instagram':
                classList = 'instagram-wrapper wrapper--rounded'; 
                break
            case 'tiktok':
                classList = 'tiktok-wrapper wrapper--rounded'; 
                break
            default:
                classList = 'border-[' +this.data.color +']';
        }
        return classList;
    }
}
