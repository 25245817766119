<div class="flex">
  <div class="hidden lg:block  bg-white" [ngClass]="{'lg:w-2/12': !minimizedMenu, 'lg:w-24': minimizedMenu}">
    <div *ngIf="!minimizedMenu" class="hidden fixed w-2/12 lg:block">
      <div class="pt-2 flex w-8/12 mx-auto items-center ">
      
        <img
 
          *ngIf="!appLogo"

          loading="lazy"

          class="w-full w-max-xl h-20"
                      
          src="https://api.goingmobile.app/media/personal-app-assets/img/webp/Going Mobile - 2.webp"
                      
          srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/Going Mobile - 2.webp"
                      
          fallback=" https://api.goingmobile.app/media/personal-app-assets/img/png/Going Mobile - 2.png"
                      
          alt="Logo">

        <img *ngIf="appLogo" [src]="appLogo" loading="lazy" class="object-cover w-full w-max-xl h-20" alt="Logo">
      </div>
      <div (click)="toggleMinimizeMenu()" class="float-right cursor-pointer w-10 h-10">
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10">
          <rect x="6.42" width="1.05" height="10" style="fill:#231f20" />
          <polygon
            points="2.53 4.99 4.21 3.31 4.72 3.81 3.87 4.66 5.3 4.66 5.3 5.37 3.9 5.37 4.72 6.19 4.21 6.69 2.53 5.01 2.54 5 2.53 4.99"
            style="fill:#231f20" />
          <rect width="10" height="10" style="fill:none" />
        </svg>

      </div>
      <div class="p-3 mt-5 font-bold text-customTeal"><i class="material-icons mr-4 float-left">home</i><a
          [routerLink]="['/admin/dashboard']" class="hover:text-customTeal hover:font-bold">Home</a></div>

      <hr />
      <div class="ml-3">


        <div class="p-2"><a [routerLink]="['/admin-settings/app-settings']"
            class="hover:text-customTeal hover:font-bold">App Settings </a></div>
        <div class="p-2"><a [routerLink]="['/admin-settings/social-media']"
            class="hover:text-customTeal hover:font-bold">Social Media Links</a></div>
        <div class="p-2"><a [routerLink]="['/admin-settings/landing-page-settings']"
            class="hover:text-customTeal hover:font-bold">Landing Page Management</a></div>
        <div class="p-2"><a [routerLink]="['/admin-settings/signup']"
            class="hover:text-customTeal hover:font-bold">Registration Page</a></div>
        <div class="p-2"><a [routerLink]="['/admin-settings/areas-of-interest']"
            class="hover:text-customTeal hover:font-bold">Areas of Interest</a></div>
        <div class="p-2"><a [routerLink]="['/admin-settings/organization']"
            class="hover:text-customTeal hover:font-bold">Organization</a></div>
        <!-- <div class="p-2"><a [routerLink]="['/admin-settings/contact-info']"
            class="hover:text-customTeal hover:font-bold">Contact Info</a></div> -->
        <!-- <div class="p-2"><a [routerLink]="['/admin-settings/faq']"
              class="hover:text-customTeal hover:font-bold">FAQ</a></div> -->
        <div class="p-2"><a [routerLink]="['/admin-settings/about']" class="hover:text-customTeal hover:font-bold">About
            Us Settings</a></div>
        <!-- <div class="p-2" *ngIf="model && model.isBusinessApp">
          <a [routerLink]="['/admin-settings/service-products-menu']" class="hover:text-customTeal hover:font-bold"
            *ngIf="model.serviceType == 'Product'">{{productsName | titlecase}} Menu Settings</a>
          <a [routerLink]="['/admin-settings/service-products-menu']" class="hover:text-customTeal hover:font-bold"
            *ngIf="model.serviceType == 'Both'">{{servicesName | titlecase}} / {{productsName | titlecase}} Menu
            Settings</a>
          <a [routerLink]="['/admin-settings/service-products-menu']" class="hover:text-customTeal hover:font-bold"
            *ngIf="model.serviceType == 'Service'">{{servicesName | titlecase}} Menu Settings</a>

        </div> -->

      </div>
    </div>
    <div *ngIf="minimizedMenu" class="hidden fixed lg:block">

      <ul class="menu py-4 overflow-y-auto">
        <div class="pt-2 flex w-full mx-auto items-center ">
          <img
 
          *ngIf="!appLogo"

          loading="lazy"

          class="object-cover w-full h-12"
                      
          data-src="https://api.goingmobile.app/media/personal-app-assets/img/webp/Going Mobile - 3.webp"
                      
          srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/Going Mobile - 3.webp"
                      
          fallback="https://api.goingmobile.app/media/personal-app-assets/img/png/Going Mobile - 3.png"
                      
          alt="Logo">
    
          <img *ngIf="appLogo" [src]="appLogo" loading="lazy" class="object-cover w-full h-12" alt="Logo">
        </div>
        <li>
          <a (click)="toggleMinimizeMenu()" class="float-right">
            <div class="w-10 h-10">
              <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10">
                <rect x="2.53" width="1.05" height="10" style="fill:#231f20" />
                <polygon
                  points="7.47 4.99 5.79 3.31 5.28 3.81 6.13 4.66 4.7 4.66 4.7 5.37 6.1 5.37 5.28 6.19 5.79 6.69 7.47 5.01 7.46 5 7.47 4.99"
                  style="fill:#231f20" />
                <rect width="10" height="10" style="fill:none" />
              </svg>
            </div>
          </a>
        </li>
        <li>
          <a [routerLink]="['/admin/dashboard']">
            <i class="material-icons mr-4 float-left">home</i>
          </a>
        </li>

        <hr />

        <div tabindex="0" class=" ml-0 collapse collapse-arrow">
          <input type="checkbox">
          <div class="collapse-title font-medium">
            <i class="material-icons mr-1 float-left">language</i>
          </div>
          <div class="collapse-content">
            <div class="p-2"><a [routerLink]="['/admin/blog']" class="hover:text-customTeal hover:font-bold"><i
                  class="material-icons mr-4 float-left">feed</i></a>
            </div>
            <div class="p-2"><a [routerLink]="['/admin/services-setting']"
                class="hover:text-customTeal hover:font-bold"><i class="material-icons mr-4 float-left">list_alt</i></a>
            </div>
            <div class="p-2"><a [routerLink]="['/admin/view-products']" class="hover:text-customTeal hover:font-bold"><i
                  class="material-icons mr-4 float-left">inventory_2</i></a></div>
            <div class="p-2"><a [routerLink]="['/admin/events']" class="hover:text-customTeal hover:font-bold"><i
                  class="material-icons mr-4 float-left">event</i></a></div>
          </div>
        </div>


        <li><a [routerLink]="['/admin-settings/app-settings']" class="hover:text-customTeal hover:font-bold"><i
              class="material-icons mr-4 float-left">settings</i></a></li>
        <li><a [routerLink]="['/admin-settings/social-media']" class="hover:text-customTeal hover:font-bold"><i
              class="material-icons mr-4 float-left">contacts</i></a></li>
        <li><a [routerLink]="['/admin-settings/landing-page-settings']" class="hover:text-customTeal hover:font-bold"><i
              class="material-icons mr-4 float-left">tune</i></a></li>
        <li><a [routerLink]="['/admin-settings/areas-of-interest']" class="hover:text-customTeal hover:font-bold"><i
              class="material-icons mr-4 float-left">interests</i></a></li>
        <!-- <li><a [routerLink]="['/admin-settings/contact-info']"
            class="hover:text-customTeal hover:font-bold"><i class="material-icons mr-4 float-left">contact_phone</i></a></li> -->
        <div class="p-2"><a [routerLink]="['/admin-settings/about']" class="hover:text-customTeal hover:font-bold"><i
              class="material-icons mr-4 float-left">question_mark</i></a></div>
        <!-- <div class="p-2"><a [routerLink]="['/admin-settings/service-products-menu']"
            class="hover:text-customTeal hover:font-bold"><i
              class="material-icons mr-4 float-left">inventory_2</i></a></div> -->

      </ul>
    </div>
    <div class="p-2 fixed bottom-5 cursor-pointer" (click)="back()">
      <div *ngIf="minimizedMenu">
        <i class="material-icons mr-3 ">arrow_back</i>
      </div>
      <div *ngIf="!minimizedMenu">
        <i class="material-icons mr-3 float-left">arrow_back</i>Back
      </div>
    </div>

  </div>
  <div class="w-full bg-gray-100 min-h-screen m-0 overflow-hidden" [ngClass]="{'lg:w-10/12': !minimizedMenu}">
    <div class="w-full navbar bg-gray-100">
      <div class="flex-none lg:hidden" (click)="toggleSideBar()">
        <label for="my-drawer-3" class="btn btn-square btn-ghost">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
            class="inline-block w-6 h-6 stroke-current">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
          </svg>
        </label>
      </div>
      <div class="flex-1 px-2 mx-2"></div>
      <div class="flex-none">
        <!-- <div class="dropdown dropdown-end">
          <label tabindex="0" *ngIf="user" class="btn btn-ghost btn-circle avatar">
            <div *ngIf="user.profileImgLink" class="w-12 rounded-full cursor-pointer">
              <img [src]="user.profileImgLink" class="rounded-full" />
            </div>
            <div *ngIf="!user.profileImgLink" class="avatar placeholder">
              <div class="bg-neutral-focus text-neutral-content rounded-full w-11">
                <span class="text-3xl">{{user.GivenName[0]}}</span>
              </div>
            </div>
          </label>
          <ul tabindex="0" class="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52">
            <li><a class="justify-between" [routerLink]="['/admin/admin-profile']"
                onclick="document.activeElement.blur()">Profile<span class="badge">New</span> </a> </li>
            <li><a (click)="signOut()">Logout</a></li>
          </ul>
        </div> -->
      </div>
    </div>


    <router-outlet></router-outlet>
  </div>

</div>



<div class="z-10 lg:hidden sidebar fixed top-0 bottom-0 lg:left-0  duration-1000
    p-2 w-[300px] overflow-y-auto bg-white shadow h-screen" [ngClass]="{'left-[-300px]':openSidebar}">
  <div (click)="toggleSideBar()">
    <i class="material-icons float-right">close</i>
  </div>
  <div class="pt-2 flex w-8/12 mx-auto items-center ">
    <img
 
          *ngIf="!appLogo"

          loading="lazy"

          class="w-full w-max-xl h-20"
                      
          data-src="https://api.goingmobile.app/media/personal-app-assets/img/webp/Sensuous-logo-01.webp"
                      
          srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/Sensuous-logo-01.webp"
                      
          fallback="https://api.goingmobile.app/media/personal-app-assets/img/png/Sensuous logo-01.png"
                      
          alt="Logo">
    <img *ngIf="appLogo" [src]="appLogo"  loading="lazy" class="object-cover w-full w-max-xl h-20" alt="Logo">
  </div>

  <div class="p-3 mt-5 font-bold text-customTeal"><i class="material-icons mr-4 float-left">home</i><a
      [routerLink]="['/admin/dashboard']" class="hover:text-customTeal hover:font-bold">Home</a></div>

  <hr />
  <div class="ml-3">


    <div class="p-2"><a [routerLink]="['/admin-settings/app-settings']" (click)="toggleSideBar()"
        class="hover:text-customTeal hover:font-bold">App Settings </a></div>
    <div class="p-2"><a [routerLink]="['/admin-settings/social-media']" (click)="toggleSideBar()"
        class="hover:text-customTeal hover:font-bold">Social Media Links</a></div>
    <div class="p-2"><a [routerLink]="['/admin-settings/landing-page-settings']" (click)="toggleSideBar()"
        class="hover:text-customTeal hover:font-bold">Landing Page Management</a></div>
    <div class="p-2"><a [routerLink]="['/admin-settings/signup']" (click)="toggleSideBar()"
        class="hover:text-customTeal hover:font-bold">Signin/ Signup</a></div>
    <div class="p-2"><a [routerLink]="['/admin-settings/areas-of-interest']" (click)="toggleSideBar()"
        class="hover:text-customTeal hover:font-bold">Areas of Interest</a></div>
    <div class="p-2" *ngIf="model.department"><a [routerLink]="['/admin-settings/organization']" (click)="toggleSideBar()"
      class="hover:text-customTeal hover:font-bold">Organization</a></div>

    <!-- <div class="p-2"><a [routerLink]="['/admin-settings/contact-info']"
        class="hover:text-customTeal hover:font-bold">Contact Info</a></div> -->
    <!-- <div class="p-2"><a [routerLink]="['/admin-settings/faq']" (click)="toggleSideBar()"
          class="hover:text-customTeal hover:font-bold">FAQ</a></div> -->
    <!-- <div class="p-2"><a [routerLink]="['/admin-settings/about']" (click)="toggleSideBar()"
            class="hover:text-customTeal hover:font-bold">About Us Settings</a></div> -->
    <div class="p-2"><a [routerLink]="['/admin-settings/about']" (click)="toggleSideBar()"
        class="hover:text-customTeal hover:font-bold">About Us Settings</a></div>
    <!-- <div class="p-2" *ngIf="model && model.isBusinessApp" (click)="toggleSideBar()">
      <a [routerLink]="['/admin-settings/service-products-menu']" class="hover:text-customTeal hover:font-bold"
        *ngIf="model.serviceType == 'Product'">{{productsName | titlecase}} Menu Settings</a>
      <a [routerLink]="['/admin-settings/service-products-menu']" class="hover:text-customTeal hover:font-bold"
        *ngIf="model.serviceType == 'Both'">{{servicesName | titlecase}} / {{productsName | titlecase}} Menu
        Settings</a>
      <a [routerLink]="['/admin-settings/service-products-menu']" class="hover:text-customTeal hover:font-bold"
        *ngIf="model.serviceType == 'Service'">{{servicesName | titlecase}} Menu Settings</a>

    </div> -->

  </div>
</div>