import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class FaqService {

  constructor(private db: AngularFirestore){ }

  postsListRef: AngularFirestoreCollection<any>;
  postRef: AngularFirestoreDocument<any>;

  saveFAQ(model: any){
    const newContent = {
      id: this.db.createId(),
      question: model.question,
      answer: model.answer
    };
    return this.db.collection('FAQ').doc(newContent.id).set(newContent);
  }

  getFAQById(id){
    return this.db.collection('FAQ', ref => ref.where('id', '==', id));
  }
  getFAQs(){
    return this.db.collection('FAQ');
  }

  updateFAQ(faq){
    return this.db.collection('FAQ').doc(faq.id).update(faq);
  }
  deleteFAQ(faq: any){
    return this.db.collection('FAQ').doc(faq.id).delete();
  }
}
