import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/services/firebase/auth.service";
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/firebase/user.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Component({
  selector: "app-notification",
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.css"],
})
export class NotificationComponent implements OnInit {
  
  constructor(private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private afAuth: AngularFireAuth,) { }
  
  currentUser: any;
  toggleYesorNo: boolean = false;
  toggleNotfication: boolean = true;
  SMSchecked: boolean = false;
  emailChecked: boolean = false;
  InappChecked: boolean = false;
  sendNotificationFor = {
    EventCreated : false,
    ServiceCreated: false
  }
  recieveNotificationFor = {
    LoggedInUsers: false,
    RegisteredUsers: false
  }
  // eventChecked: boolean = false;
  // serviceChecked: boolean = false;
  privacyChecked: boolean = true;
  noMessage: boolean = false;
  noAgreement: boolean = false;
  noSelectedInterest: boolean = false;

  //added
  loggedInUser: any;
  userContactPreference = [];
  userReceiveNotificationPreference = [];
  userSendNotificationPreference = [];
  areaGlobal = [];
  globalUser: any;
  userId:any;
  currentUserId: any;
  user: any;
  myProfileMenu: boolean;

  reloadModal: boolean = false;
  //
  a: any;
  ngOnInit(): void {
    this.currentUser = this.authService.getGlobalUser();
     
    // to populate exsisting selected values
    this.populateExistingPreference();

    // this.selectedInterest = undefined;
    this.userService.getUserById(this.currentUser.uid).valueChanges().subscribe(user => {
      if (user.length > 0) {
        this.loggedInUser = user[0];
      }
    });

  }

  // populate selected exsisting preference
  populateExistingPreference(){

    // populate existing preference of the admin related to the notification they want to send
    for(let i = 0; i < this.currentUser.sendNotificationFor?.length; i++)
    {
      let value = this.currentUser.sendNotificationFor[i];
      if(!this.sendNotificationFor[value])
      {
        this.sendNotificationFor[value] = true

      }

    }

     // populate existing preference of the admin related to the notification they want to receive
    for(let i = 0; i < this.currentUser.recieveNotificationFor?.length; i++)
    {
      let value = this.currentUser.recieveNotificationFor[i];
      if(!this.recieveNotificationFor[value])
      {
        this.recieveNotificationFor[value] = true

      }

    }
  }

  finish() {
    this.userService.getUserById(this.currentUser.uid).valueChanges().subscribe(user => {
      if (user.length > 0) {
        this.loggedInUser = user[0];
      }
    });
    
      this.loggedInUser.contact = this.userContactPreference;
      this.loggedInUser.recieveNotificationFor = this.userReceiveNotificationPreference;
      this.loggedInUser.sendNotificationFor = this.userSendNotificationPreference;
      this.userService.updateUser(this.loggedInUser).then(res => {
       });
    // }
      this.toggleNotificationButton(event);
     
      // this.router.navigate(['/admin/dashboard']);
  }


  openReloadModal(){
    this.reloadModal = true;
  }
  closeReloadModal(){
    this.reloadModal = false;
  }

  // toggle the recieve notification for 
  toggleReceiveNotificationFor(type){
    if(type == "loggedInUser"){
      this.recieveNotificationFor.LoggedInUsers = !this.recieveNotificationFor.LoggedInUsers;

      if (this.recieveNotificationFor.LoggedInUsers) {
        this.userReceiveNotificationPreference.push('LoggedInUsers');
      }
      else {
        var loggedInUser = this.userReceiveNotificationPreference.indexOf('LoggedInUsers'); // get  "loggedInUser" index
        this.userReceiveNotificationPreference.splice(loggedInUser, 1);
      }
    }
    else if(type == "registeredUser")
    {
      this.recieveNotificationFor.RegisteredUsers = !this.recieveNotificationFor.RegisteredUsers;

      if (this.recieveNotificationFor.RegisteredUsers) {
        this.userReceiveNotificationPreference.push('RegisteredUsers');
      }
      else {
        var registeredUser = this.userReceiveNotificationPreference.indexOf('RegisteredUsers'); // get  "sms" index
        this.userReceiveNotificationPreference.splice(registeredUser, 1);
      }
    }

  }

  // toggle the send notification for 
  toggleSendNotificationFor(type){
    if(type == "eventCreated"){
      this.sendNotificationFor.EventCreated = !this.sendNotificationFor.EventCreated;

      if (this.sendNotificationFor.EventCreated) {
        this.userSendNotificationPreference.push('EventCreated');
      }
      else {
        var eventCreated = this.userSendNotificationPreference.indexOf('EventCreated'); // get  "EventCreated" index
        this.userSendNotificationPreference.splice(eventCreated, 1);
      }
    }
    else if(type == "serviceCreated")
    {
      this.sendNotificationFor.ServiceCreated = !this.sendNotificationFor.ServiceCreated ;

      if (this.sendNotificationFor.ServiceCreated) {
        this.userSendNotificationPreference.push('ServiceCreated');
      }
      else {
        var serviceCreated = this.userSendNotificationPreference.indexOf('ServiceCreated'); // get  "ServiceCreated" index
        this.userSendNotificationPreference.splice(serviceCreated, 1);
      }
    }

  }

  toggle() {
    this.toggleYesorNo = !this.toggleYesorNo;
  }
  toggleNotificationButton(event) {
    this.toggleNotfication = !this.toggleNotfication;
    if (event.target.checked) {
      this.loggedInUser.notification = 'on';
      // this.userService.updateUser(this.loggedInUser).then(res=>{ });
    }
    else {
      this.loggedInUser.notification = 'off';
      // this.userService.updateUser(this.loggedInUser).then(res=>{})
    }
  }
 
}
