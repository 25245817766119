import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { environment } from 'src/environments/environment';

// declare var $: any;

@Component({
  selector: 'app-more-menu',
  templateUrl: './more-menu.component.html',
  styleUrls: ['./more-menu.component.css']
})
export class MoreMenuComponent implements OnInit {
  moreMenu: any[];
  menu: any;
  showDefault = false;
  nationOfUSe = environment.firstNation.name;

  constructor(private router: Router, private settingService: AppSettingsService) { }

  ngOnInit() {
    this.settingService.getMenuSettings().valueChanges().subscribe(
      m => {
        if (m) {
          if (m.length > 0) {
            this.menu = m[0];
            this.moreMenu = this.menu.MenuItems.filter(mn => mn.index > 2);

          } else {
             this.showDefault = true;
          }
          setTimeout(() => {

            // $('.modal').modal();
          }, 50);
        }
      }
    );

    setTimeout(() => {

      // $('.modal').modal();
    }, 50);
  }


  navigate(url: any) {
    if (url) {
      this.router.navigate([url]);
    } else {this.router.navigate(['/']); }
  }

  termsAndPolicyClicked(event) {

    localStorage.setItem('FromPath', 'TermsAndPolicy');
    this.router.navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/home-sub-menu']));
    // localStorage.removeItem('FromPath');
    // localStorage.setItem('FromPath', 'TermsAndPolicy');
    // this.router.navigate(['/home-sub-menu']);
  }

  socialNetworkClicked(event) {

    localStorage.setItem('FromPath', 'SocialNetwork');
    this.router.navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/home-sub-menu']));

    // localStorage.removeItem('FromPath');
    //
    // this.router.navigate(['/home-sub-menu']);
  }

  employersClicked() {

    localStorage.setItem('FromPath', 'Employers');
    this.router.navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/home-sub-menu']));

  }

  createAccountClick() {
    // $('#infoModal').modal('close');
    // $('#DemoModal').modal('open');
  }

  memberAccountClick() {
    // $('#DemoModal').modal('close');
    localStorage.setItem('AccountType', 'Member');
    this.router.navigate(['/auth/request-demo']);
  }

  adminAccountClick() {
    // $('#DemoModal').modal('close');
    localStorage.setItem('AccountType', 'Admin');
    this.router.navigate(['/auth/request-demo']);
  }


}
