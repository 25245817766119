import { Injectable } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { format, isSameYear } from 'date-fns';
import { RoutMetaService } from './rout-meta.service';
import { AppSettingsService } from '../firebase/app-settings.service';

declare const Pusher: any;
@Injectable({
  providedIn: 'root'
})
export class HelperService {

  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;

  metaTagSettings: any=null;

  settings:any;

  titleOptions = [
    "Ask me anything, I'm here to help you like a trusted friend.",
    "No question is too big or small, I'm here to provide answers to them all.",
    "Think of me as your personal information guru, ask away!",
    "There's no need to hesitate, ask me anything on your mind.",
    "From the simple to the complex, I'm ready to answer any question you ask.",
    "Don't hold back, ask me anything and let's get the answers you need.",
    "Your curiosity is welcome here, ask me anything and satisfy your thirst for knowledge.",
    "I'm your go-to source for information, so ask me anything that comes to mind.",
    "Your questions are my priority, ask away and I'll provide the answers you seek.",
  ];


  constructor(
    private clipboard: Clipboard,
    private routMeta: RoutMetaService,
    private settingsService: AppSettingsService,

  ) {};

  checkNotificationRule(array,condition){
    let foundRule = array.settings.find(section=>section.name == condition)
    return foundRule
  }

  checkNotificationForOwner(settings,conditions){
    let condition = conditions.pop()

    let employeeRules = this.checkNotificationRule(settings, condition)

    if(employeeRules && employeeRules.isEnabled){
        if(conditions.length > 0){
            return this.checkNotificationForOwner(employeeRules,conditions)
        }
        return true
    }
    return false
    
}


  backButton(){
    window.history.back()
  }
  isEmptyObject(obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object
}


  truncateHTML(text: string, limit: string): string{
    const charlimit = +limit;
    if (!text || text.length <= charlimit) {
      return text?.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ');
    }
    return text?.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ').substring(0, charlimit) + '...';

  }
  getInnerText(htmlString){
    return new DOMParser().parseFromString(htmlString, 'text/html').body.innerText
  }

  replaceUrl(message){
    if(!message) return;
 
    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return message.replace(urlRegex, function (url) {
      var hyperlink = url;
      if (!hyperlink.match('^https?:\/\/')) {
        hyperlink = 'http://' + hyperlink;
      }
      return '<a href="' + hyperlink + '" target="_blank" rel="noopener noreferrer">' + url + '</a>'
    });
  }

  uploadVideo(){

  }

  uploadImage(){

  }
  isValidURL(str) {
    var regex = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
    if(!regex .test(str)) {
      return false;
    } else {
      return true;
    }
  }

  calculateWordCount(text) {
    const wpm = 225; // average speed per minutes
    const wordsArr = text.trim().split(' ');
    const wordsCount = wordsArr.filter(word => word !== '').length;
    return Math.ceil(wordsCount / wpm);
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }


  validatePDF(name: any) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() === 'pdf') {
      return true;
    }
    else {
      return false;
    }
  }

  validateImage(name: any) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() === 'png') {
      return true;
    } else if (ext.toLowerCase() === 'jpg') {
      return true;
    } else if (ext.toLowerCase() === 'jpeg') {
      return true;
    } else {
      return false;
    }
  }
  
  getUrl(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);

    if (match && match[2].length === 11) {
      const youtubeUrl = 'https://www.youtube.com/embed/' + match[2];
      return youtubeUrl;
    } else {
        return url;
    }
  }

  getImageType(name: any){
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if(ext.toLowerCase() === 'png'){
      return 'png';
    } else if(ext.toLowerCase() === 'jpg'){
      return 'jpg';
    } else if(ext.toLowerCase() === 'jpeg'){
      return 'jpeg';
    } else if(ext.toLowerCase() === 'gif'){
      return 'gif';
    }
    else {
      return null;
    }
  }

  copy(text){
    this.clipboard.copy(text)
  }

  validateSchedule(schedule){
    return schedule.map(day => {
      day.startTime = (day.unAvailable === true) ? null : day.startTime;
      day.endTime = (day.unAvailable === true) ? null : day.endTime;

      // day.unAvailable = (day.startTime=== null && day.endTime ===null) ? true : false
      return day;
    });

  }
  formatDateForComment(timestamp){
    if (timestamp){
      if(!((new Date(timestamp)).getTime() > 0)){
        const date = new Date(timestamp.toDate());
        const today = new Date();
        if (isSameYear(date, today)){
          return format(date, 'MMM d, h:mm a');
        } else {
          return format(date, 'MMM d yyyy, h:mm a');
        }
      }
      else{
        return timestamp;
      }
    }
  }

  getMetaTagSettings() {
    this.settings = this.settingsService.getMetadata().valueChanges().subscribe((metaTags: any) => {
      let lastIndex = metaTags?.length - 1;
      if (lastIndex || lastIndex === 0) {
        this.metaTagSettings = metaTags?.[lastIndex];
        // this.appSettingsForm.get('appTitle').setValue(this.metaTagSettings?.title)
        this.routMeta.updateTitle(metaTags[0].appTitle ? metaTags[0].appTitle : 'title');
        // this.appSettingsForm.get('keywordSEO').setValue(this.metaTagSettings?.description);
        this.routMeta.updateDescription(metaTags[0]?.description);
        // this.appSettingsForm.get('Keywords').setValue(this.metaTagSettings?.KeyWords);
        this.routMeta.updateKeywords(metaTags[0]?.keyWords ? metaTags[0]?.keyWords : "");
  
      }
      this.settings.unsubscribe();
    });
  }

  toast(obj) {
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green';
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
    }, 2000);
  }




}
