import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ContactUsService } from 'src/app/services/firebase/contact-us.service';
import { EmailModel, CustomAdminEmailModel } from 'src/app/models/emailModel';
import { EmailService } from 'src/app/services/api/email.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-contact-us-messages',
  templateUrl: './contact-us-messages.component.html',
  styleUrls: ['./contact-us-messages.component.css']
})
export class ContactUsMessagesComponent implements OnInit {

  contactsMessagesList = [];
  viewDetailModal = false;
  contactUserModal = false;
  selectedContactMessage: any;
  contactForm: UntypedFormGroup;
  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  
  p = 1;
  storeEmail = [];

  availablePhone: any;
  settings: any;
  isNationApp = environment.firstNation.Origin == "FNLeaders" ? true : false;

  constructor(private contactUsService: ContactUsService,
              private fb: UntypedFormBuilder,
              private emailService: EmailService,
              private appSettingsService: AppSettingsService) { }

  ngOnInit(): void {
    this.contactForm = this.fb.group({
      subject: [''],
      emailbody: [''],
    });

    this.contactUsService.getAllMessages().valueChanges().subscribe((allMessages: any) => {
      this.contactsMessagesList = allMessages;
    })

    this.appSettingsService.getAppSettingsList().valueChanges().subscribe((settings)=>{
      if(settings && settings.length !=0 ){
        this.settings = settings[0];
        this.availablePhone = this.settings.availablePhone ? this.settings.availablePhone : '';

      }
    })
  }

  viewMessageDetail(message){
    this.selectedContactMessage = message;
    this.viewDetailModal = true;
  }

  sendSubscribers(subject, body) {
      const emailModel = new CustomAdminEmailModel();
      emailModel.subject = subject;
      emailModel.isNationApp = this.isNationApp;
      emailModel.body = body;
      emailModel.body += '<br/> <br/> <br/> <span style="color:blue; margin-top: 10px;"> </span> ' + this.selectedContactMessage.name +  ' <br/> ';
      emailModel.body += '<span style="color:blue;"> </span> ' + this.selectedContactMessage.email + ' <br/> ';
      emailModel.emailList = this.selectedContactMessage.email;

      // custom send email used
      emailModel.host = "smtp.gmail.com",
      emailModel.adminEmail = "mkproject.app@gmail.com",
      emailModel.appPassword = "ytklyidomvqfmtky",

      this.emailService.sendCustomEmail(emailModel).subscribe((res) => {
        if (res) {
          this.toast({ html: 'Email sent Successfully', classes: 'green', type: 'success' });                    
        }
      });
      this.closeSendMessageModal();
      this.viewDetailModal = false;
      this.contactForm.patchValue({
        subject: '',
        emailbody: '',
      })
  }
  closeSendMessageModal(){
    this.contactUserModal = false;
  }
  toast(obj) {
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green';
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    if (this.toastType == 'warning') {
      setTimeout(() => {
        this.openToast = false;
      }, 3000);
    }
    else {
      setTimeout(() => {
        this.openToast = false;
      }, 2000);
    }
  }
  getSortedList(unsortedElements: Array<any>){
    let sortedList = unsortedElements.sort((a:any, b:any ) => {
        return b.timeStamp- a.timeStamp;
    });
    return sortedList
  }
}
