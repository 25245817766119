import { Component, OnInit } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { environment } from 'src/environments/environment';
import { GlobalConstants } from 'src/app/models/GlobalConstants';

// import { toast } from 'materialize-css';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  resetPasswordForm: UntypedFormGroup;
  model: any;
  themeColor: any;
  appName: any;
  appDescription = '';
  appLogo: any;
  defaultThemeColor = environment.appTheme.themeColor;

  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  directSignup: boolean = false;
  defaultLogo= GlobalConstants.defaultLogo  

  constructor(private fb: UntypedFormBuilder,
     private authService: AuthService,
     private settingsService: AppSettingsService,
     private router : Router) {

     

    this.settingsService.getAppSettingsList().valueChanges().subscribe(
      appSettings => {
        if (appSettings) {
          if (appSettings.length > 0) {
            this.model = appSettings[0];
            this.themeColor = this.model.themeColor ? this.model.themeColor : this.defaultThemeColor;
            this.appName = this.model.appName;
            this.appDescription = this.model.description;
            this.appLogo = this.model.logo;
          }
        }
      }
      );
   }

  ngOnInit() {
    this.resetPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  get email() {
    return this.resetPasswordForm.get('email');
  }

  resetPassword() {
     this.authService.resetPassword(this.email.value).then((res) => {
     //  toast({html: '<p> Reset Password Link Successfully Sent To Your Inbox. </p>', classes: 'green'})
     }).catch( (error) => {
    }
     );

  }
  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
    }, 3000);
  }
}
