import { Component, Input, OnInit } from '@angular/core';
import { HelperService } from 'src/app/services/helper/helper';

@Component({
  selector: 'app-product-list-minimal',
  templateUrl: './product-list-minimal.component.html',
  styleUrls: ['./product-list-minimal.component.css']
})
export class ProductListMinimalComponent implements OnInit {


  @Input() products;
  @Input() itemNumber;
  @Input() preview
  @Input() view
  constructor(
    private helperServices: HelperService
  ) { }

  ngOnInit(): void {
  }
  truncateHTML(text: any, limit): string {
    return this.helperServices.truncateHTML(text, limit);
   }

   getInnerText(text){
    return this.helperServices.getInnerText(text);
  }
  navigateDetails(p){

  }

}
