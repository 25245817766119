
<div>
<div class="flex space-x-12 md:space-x-28 items-center justify-center">
  <div class="flex-row text-center text-md items-center justify-center mx-6 ">
    <button (click)="pending()"
      class="font-medium border-2 border-customTeal cursor-pointer px-3 md:px-6 py-1 rounded-full md:font-bold"
      [ngClass]="{'text-white bg-customTeal': isPendingClicked, 'text-black': !isPendingClicked}">Pending
    </button>
  </div>
  <div class="flex-row text-center text-md">
    <button (click)="declined()"
      class="font-medium border-2 border-customTeal cursor-pointer px-3 md:px-6 py-1 rounded-full md:font-bold"
      [ngClass]="{'text-white  bg-customTeal': declinedClicked, 'text-black': !declinedClicked}">Declined</button>
  </div>
  <div class="flex-row text-center text-md">
    <button (click)="accepted()"
      class="font-medium border-2 border-customTeal cursor-pointer px-3 md:px-6 py-1 rounded-full md:font-bold"
      [ngClass]="{'text-white  bg-customTeal': acceptedClicked, 'text-black': !acceptedClicked}">Accepted</button>
  </div>
</div>

<div  *ngIf="pendingFriendRequests.length > 0 && isPendingClicked">
  <div class=" flex flex-col space-y-2 mx-2 mt-2 md:mx-12 lg:mx-4">
    <div class=" flex flex-row items-center mt-12 p-2 md:space-x-6">
      <div class="w-10/12 md:w-4/12 flex">
        <p class=" text-sm">Name</p>
      </div>
      <div class="w-4/12 hidden md:block">
        <p class=" text-sm">Email</p>
      </div>
      <div class="w-3/12 hidden md:block">
        <p class=" text-sm">Date</p>
      </div>
      <div class="w-2/12 md:w-1/12">
      </div>
    </div>
  </div>

  <div>
    <div *ngFor="let request of pendingFriendRequestCopy; let i=index"
      class="flex flex-row bg-white cursor-pointer items-center mt-2 p-2 rounded-md mx-2  border-2 md:space-x-6">
      <div class=" w-10/12 lg:w-4/12 flex">
        <div class="flex items-center">
          <div>
            <div class="">{{request.fullName}}</div>
          </div>
        </div>
      </div>
      <div class="w-4/12 hidden lg:block">
        <p class=" text-sm">{{request.email}}</p>
      </div>

      <div class="w-3/12 hidden lg:block">
        <p class=" text-sm">{{request.requestDate.toDate() | date:'MMM d, h:mm a'}}</p>
      </div>

      <!-- <div class="w-9/12 lg:w-4/12 lg:space-x-8 space-x-4">
        <label for="my-modal-acceptPending" (click)="selectAccept(i)"
          class="border-2 py-1 border-customTeal rounded-md px-2 hover:text-white hover:bg-customTeal">Accept</label>

        <button (click)="selectDecline(i)"
          class="border-2 py-1 border-red-400 rounded-md px-2 hover:text-white hover:bg-red-600">Decline</button>
      </div> -->

      <div class="w-2/12 md:1/12">
        <button (click)="selectPendingDetail(i)" type="button">
          <i class="material-icons material-symbols-outlined float-left">arrow_circle_right </i>
        </button>
      </div>




      <div id="my-modal-accept" *ngIf="detailPendingOpenModal" class="modal"
      [ngClass]="{'modal-open': detailPendingOpenModal, 'modal-action': !detailPendingOpenModal }">
      <div class="modal-box w-10/12 max-w-2xl">
        <div class="modal-content w-full max-w-2xl items-center justify-center">
            <div class="px-4 py-2 flex justify-center w-full max-w-2xl">
              <div class="flex flex-col w-full max-w-2xl bg-white  px-4 py-8 space-y-8 rounded-md">
                   <div class="space-y-4">
                      <p class="font-bold text-lg text-customTeal">Personal Information</p>
                      <div class="flex flex-col md:flex-row gap-y-4 gap-x-12 ">
                          <div class="flex flex-col space-y-1 md:w-1/3">
                              <p class="text-sm text-darkGray">Full Name</p>
                              <p class="font-bold">{{activePendingRequestDetail.fullName}}</p>
                          </div>
                          <div class="flex flex-col space-y-1 md:w-1/3">
                              <p class="text-sm text-darkGray">Email</p>
                              <p class="font-bold">{{activePendingRequestDetail.email}}</p>
                          </div>
                          <div class="flex flex-col space-y-1 md:w-1/3"  *ngIf="activePendingRequestDetail.cellPhone">
                              <p class="text-sm text-darkGray">Mobile Number</p>
                              <p class="font-bold">{{activePendingRequestDetail.cellPhone}}</p>
                          </div>
                      </div>
                   </div>

                   <div class="space-y-4">
                      <hr>
                      <div class="flex flex-col md:flex-row gap-y-4 gap-x-12">
                          <div class="flex flex-col space-y-1 md:w-1/3">
                              <p class="text-sm text-darkGray">Status</p>
                              <p class="font-bold">{{activePendingRequestDetail.status}}</p>
                          </div>
                          <div class="flex flex-col space-y-1 md:w-1/3">
                              <p class="text-sm text-darkGray">User Type</p>
                              <p class="font-bold">{{activePendingRequestDetail.userType}}</p>
                          </div>
                          <div class="flex flex-col space-y-1 md:w-1/3">
                              <p class="text-sm text-darkGray">Date</p>
                              <p class="font-bold"> {{activePendingRequestDetail.requestDate.toDate() | date:'MMM d, h:mm a'}}</p>
                          </div>
                      </div>
                   </div>
                   <div class="space-y-4">
                    <hr>
                    <div class="flex flex-col md:flex-row gap-y-4 gap-x-12">
                        <div class="flex flex-col space-y-1 md:w-1/3">
                            <p class="text-sm text-darkGray">Message to the Admin</p>
                            <p class="font-bold mt-2">{{activePendingRequestDetail.message}}</p>
                        </div>
                    </div>
                 </div>
                   <div class="modal-action flex justify-between mx-2">
                    <button (click)="selectAccept(i)" *ngIf="isAdmin || (permMember && permMember.acceptRequest)"
                      class="border-2 py-1 border-customTeal rounded-md px-2 text-white bg-customTeal">Accept</button>

                      <button (click)="selectDecline(i)" *ngIf="isAdmin || (permMember && permMember.declineRequest)"
                      class="border-2 py-1 border-red-600 rounded-md px-2 text-white bg-red-600">Decline</button>
                  </div>

                  <div class="modal-action flex justify-end mx-2">
                    <button (click)="closePendingDetailModal()"
                      class="font-bold text-customTeal px-4 my-10 border-none">Close</button>
                  </div>

              </div>

          </div>
        </div>
      </div>
    </div>


    </div>
  </div>
</div>

<br /> <br />

<div *ngIf="pendingFriendRequestCopy.length == 0 && isPendingClicked"
  class="mt-5">
  <div class="flex items-center justify-center">
    <img  loading="lazy" data-src="../../../../assets/icons/Illustrations/RegistrationRequest-Illustration.png"
    class="flex w-64 items-center justify-center">
</div>
<div class="text-customTeal text-lg text-center font-bold">You have no Pending Registration Requests.</div>
<div class="text-gray-400 text-sm text-center font-bold"> Check back later!</div>
</div>

<div *ngIf="(!invitation || (invitation && invitation.length == 0)) && acceptedClicked"
  class="mt-5">
  <div class="flex items-center justify-center">
    <img loading="lazy" data-src="../../../../assets/icons/Illustrations/RegistrationRequest-Illustration.png"
    class="flex w-64 items-center justify-center">
</div>
<div class="text-customTeal text-lg text-center font-bold">You have no Accepted Registration Requests.</div>
<div class="text-gray-400 text-sm text-center font-bold"> Check back later!</div>
</div>


<div class=" flex flex-col space-y-2 mx-2 mt-4 md:mx-12 lg:mx-4" *ngIf="declinedRequestCopy.length > 0 && declinedClicked">
  <div class=" flex flex-row items-center mt-2 p-2 md:space-x-6">
    <div class="w-10/12 lg:w-4/12 flex">
      <p class=" text-sm">Name</p>
    </div>
    <div class="w-3/12 hidden lg:block">
      <p class=" text-sm">Email</p>
    </div>
    <div class="w-2/12 hidden lg:block">
      <p class=" text-sm">Date</p>
    </div>
    <div class="w-2/12 hidden lg:block">
      <p class=" text-sm">PhoneNumber</p>
    </div>
    <!-- <div class="w-4/12 lg:w-2/12">
    </div> -->
    <div class="w-2/12 md:w-1/12">
    </div>
  </div>
</div>

<div *ngIf="declinedClicked">
  <div *ngFor="let request of declinedRequestCopy; let i = index"
    class="flex flex-row bg-white cursor-pointer items-center  mt-2 p-2 rounded-md mx-2 border-2 md:space-x-6">
    <div class=" w-10/12 lg:w-4/12 flex">
      <div class="flex items-center">
        <div>
          <div class=" ">{{request.fullName}}</div>
        </div>
      </div>
    </div>
    <div class="w-3/12 hidden lg:block">
      <p class=" text-sm">{{request.email}}</p>
    </div>
    <div class="w-2/12 hidden lg:block">
      <p class=" text-sm">{{request.requestDate.toDate() | date:'MMM d, h:mm a'}}</p>
    </div>

    <div class="w-2/12 hidden lg:block">
      <p class=" text-sm" *ngIf="request.cellPhone">{{request.cellPhone}}</p>
      <p class=" text-sm" *ngIf="!request.cellPhone">(---) (---) (----)</p>
    </div>

    <!-- <div class="w-4/12 lg:w-2/12 ml-6 ">

      <button (click)="selectAccept(i)"
        class="border-2 py-1 border-customTeal rounded-md px-2 hover:text-white hover:bg-green-400">Accept</button>
    </div> -->

    <div class="w-1/12 md:2/12">
      <button type="button" (click)="selectDetail(i)">
        <i class="material-icons material-symbols-outlined float-left">arrow_circle_right </i>
      </button>
    </div>

    <!-- <div id="my-modal-accept" *ngIf="acceptOpenModal" class="modal"
      [ngClass]="{'modal-open': acceptOpenModal, 'modal-action': !acceptOpenModal }">
      <div class="modal-box">
        <div class="modal-content">
          <h3 class="font-bold text-lg text-center mb-1">Accept Request</h3>
          <hr />
        <h3 class="font-bold text-md text-center mt-2">Are you sure you want to Accept this Account?</h3>
        <p class="py-4 text-center">An invitation link would be sent to the user when accepted.</p>
        <div class="modal-action items-center justify-between mx-6">
          <button (click)="closeAcceptModal()"
            class="font-bold text-red-600 border-none">Cancel</button>
          <button type="button" (click)="acceptFriendRequest(declinedRequestCopy[currentIndex])"
              class="py-2 px-4 bg-customTeal text-white border-customTeal rounded-md" >Accept</button>
        </div>
      </div>
    </div>

  </div> -->

  <div id="my-modal-accept" *ngIf="detailOpenModal" class="modal"
  [ngClass]="{'modal-open': detailOpenModal, 'modal-action': !detailOpenModal }">
  <div class="modal-box w-10/12 max-w-2xl">
    <app-close-modal (closeBtnClicked)=closeDetailModal()></app-close-modal>
    <div class="modal-content w-full max-w-2xl items-center justify-center">
        <div class="px-4 py-2 flex justify-center w-full max-w-2xl">
          <div class="flex flex-col w-full max-w-2xl bg-white  px-4 py-8 space-y-8 rounded-md">
               <div class="space-y-4">
                  <p class="font-bold text-lg text-customTeal">Personal Information</p>
                  <div class="flex flex-col md:flex-row gap-y-4 gap-x-12 ">
                      <div class="flex flex-col space-y-1 md:w-1/3">
                          <p class="text-sm text-darkGray">Full Name</p>
                          <p class="font-bold">{{declinedName}}</p>
                      </div>
                      <div class="flex flex-col space-y-1 md:w-1/3">
                          <p class="text-sm text-darkGray">Email</p>
                          <p class="font-bold">{{declinedEmail}}</p>
                      </div>
                      <div class="flex flex-col space-y-1 md:w-1/3">
                          <p class="text-sm text-darkGray" *ngIf="declinedCellPhone">Mobile Number</p>
                          <p class="font-bold">{{declinedCellPhone}}</p>
                      </div>
                  </div>
               </div>

               <div class="space-y-4">
                  <hr>
                  <div class="flex flex-col md:flex-row gap-y-4 gap-x-12">
                      <div class="flex flex-col space-y-1 md:w-1/3">
                          <p class="text-sm text-darkGray">Status</p>
                          <p class="font-bold">{{declinedStatus}}</p>
                      </div>
                      <div class="flex flex-col space-y-1 md:w-1/3">
                          <p class="text-sm text-darkGray">User Type</p>
                          <p class="font-bold" *ngIf="declinedUsertype">Subscriber</p>
                      </div>
                      <div class="flex flex-col space-y-1 md:w-1/3">
                          <p class="text-sm text-darkGray">Date</p>
                          <p class="font-bold"> {{declinedDate.toDate() | date:'MMM d, h:mm a'}}</p>
                      </div>
                  </div>
               </div>
               <div class="space-y-4">
                <hr>
                <div class="flex flex-col md:flex-row gap-y-4 gap-x-12">
                  <div class="flex flex-col space-y-1 md:w-2/3">
                    <p class="text-sm text-red-500">The Reason this User is Declined</p>
                    <p class="font-bold" *ngIf="declinedMessage">{{declinedMessage}}</p>
                    <p class="font-bold" *ngIf="!declinedMessage">Reason was not specified</p>
                  </div>
                  <div class="flex flex-col space-y-1 md:w-1/3">
                    <button (click)="openModal()"
                      class=" flex items-center space-x-2 justify-center px-4 md:px-6 py-2 mt-2 bg-customTeal text-white rounded-lg w-max h-min text-sm md:text-base font-bold hover:bg-darkBlue focus:outline-none">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round"
                          d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                      </svg>
                      <span>Send Message</span>
                    </button>
                  </div>
                </div>
              </div>

               <div class="modal-action flex justify-between mx-2">
                <button (click)="closeDetailModal()"
                  class="font-bold text-red-600 px-4 py-2 border-none">Close</button>
                  <button (click)="selectAccept(i)" *ngIf="isAdmin || (permMember && permMember.acceptRequest)"
                  class="font-bold text-white bg-customTeal px-4 py-2 rounded-lg border-none">Accept</button>
              </div>

            </div>

          </div>

      </div>
      </div>
    </div>

  </div>


</div>
<!-- <br /> <br /> -->



<div class=" flex flex-col mx-2 mt-4 md:mx-12 space-y-2 lg:mx-4" *ngIf=" invitation && invitation.length > 0 && acceptedClicked">
  <div class=" flex flex-row items-center mt-2 p-2 md:space-x-6">
    <div class="w-10/12 lg:w-4/12 flex">
      <p class=" text-sm">Name</p>
    </div>
    <div class="w-3/12 hidden lg:block">
      <p class=" text-sm">Email</p>
    </div>
    <div class="w-2/12 hidden lg:block">
      <p class=" text-sm">Date</p>
    </div>
    <div class="w-2/12 hidden lg:block">
      <p class=" text-sm">PhoneNumber</p>
    </div>
    <!-- <div class="w-4/12 lg:w-2/12">
    </div> -->
    <div class="w-2/12 md:w-1/12">
    </div>
  </div>
</div>

<div *ngIf="acceptedClicked">
  <div *ngFor="let invite of invitation; let i = index"
    class="flex flex-row bg-white cursor-pointer items-center  mt-2 p-2 rounded-md mx-2 border-2 md:space-x-6">
    <div class=" w-10/12 lg:w-4/12 flex">
      <div class="flex items-center">
        <div>
          <div class=" ">{{invite.firstName}} {{invite.lastName}}</div>
        </div>
      </div>
    </div>
    <div class="w-3/12 hidden lg:block">
      <p class=" text-sm">{{invite.inviteEmail}}</p>
    </div>
    <div class="w-2/12 hidden lg:block">
      <p class=" text-sm">{{invite.timeStamp.toDate() | date:'MMM d, h:mm a'}}</p>
    </div>

    <div class="w-2/12 hidden lg:block">
      <p class=" text-sm" *ngIf="invite.cellPhone">{{invite.cellPhone}}</p>
      <p class=" text-sm" *ngIf="!invite.cellPhone">(---) (---) (----)</p>
    </div>


    <div class="w-1/12 md:2/12">
      <button type="button" (click)="selectInvitationDetail(i)">
        <i class="material-icons material-symbols-outlined float-left">arrow_circle_right </i>
      </button>
    </div>
  </div>


</div>
<br /> <br />

<div id="my-modal-client" *ngIf="openContactModal" class="modal"
  [ngClass]="{'modal-open': openContactModal, 'modal-action': !openContactModal }">
  <div class="modal-box">
    <app-close-modal (closeBtnClicked)=closeModal()></app-close-modal>
    <div class="modal-content">
      <h3 class="font-bold text-lg">Contact {{GivenName}} {{LastName}}</h3>
      <p class="pt-4 text-gray-400 pl-1">You can contact this {{GivenName}} using email or SMS if the user
        registered his/her phonenumber</p>

      <form [formGroup]="contactForm"
        (ngSubmit)="sendClient(contactForm.value)">
        <div class="flex items-center mt-4 space-x-8 ">
          <div class="flex-col text-black px-2">Contact with:</div>
          <div>
            <input type="radio" id="Email" name="fav_language" value="Email" class="mx-2" [checked]="!isSMS"
              (click)="SMSselected()">
            <label for="pub">Send Email</label><br>
          </div>
          <div>
            <input type="radio" id="Sms" name="fav_language" value="SMS" class="mx-2" [checked]="isSMS"
              (click)="SMSselected()" [disabled]="disable">
            <label for="User">Send SMS</label><br>
          </div>
        </div>
        <br />

        <span *ngIf="disable" class="text-error">This User doesn't have a phone number.</span>

        <div *ngIf="isSMS  && !disable">
          <div class=" px-2 text-error">
            <i class="material-icons blue-text pr-2 float-left">warning</i>
            Some Users don't have their mobile number installed therefore wont recieve an SMS
          </div>

          <div class="px-2 mt-2 w-full">
            <label for=" " class="text-sm font-bold mb-2 "> Message: </label>
            <!-- <textarea id="message" formControlName="messageSMS"
              class="border rounded py-4 px-3 mt-2 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline h-32 w-full "
              placeholder="Message"></textarea> -->
              <app-editor  [control]="messageSMS" class="bg-white" required [fromComponent]="'message'"></app-editor>
          </div>


          <div class="flex justify-between">
            <button type="button" (click)="closeModal()"
              class="btn bg-white hover:bg-gray-400 hover:text-white text-black border-none">Cancel</button>
            <button class="p-2 text-white bg-customTeal hover:bg-gray-100 hover:text-customTeal border-none rounded-md"
              type="submit">Send Message</button>
          </div>
        </div>

        <div *ngIf="!isSMS || disable">
          <div class=" px-2 mt-2 w-full">
            <div>
              <label for=" " class="text-sm font-bold mb-2 "> Subject: </label>
              <input name="subject" formControlName="subject"
                class="form-control mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                placeholder="Subject" />
              <br />
            </div>
            <label for=" " class="text-sm font-bold mb-2 "> Message: </label>

            <!-- <textarea id="body" formControlName="body"
              class="border rounded py-4 px-3 mt-2 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline h-32 w-full "
              id="Description" placeholder="Message"></textarea> -->

            <app-editor  [control]="body" class="bg-white" required [fromComponent]="'message'"></app-editor>


          </div>


          <div class="flex justify-between">
            <button type="button" (click)="closeModal()"
              class="btn bg-white hover:bg-gray-400 hover:text-white text-black border-none">Cancel</button>
            <button class="p-2 text-white bg-customTeal hover:bg-gray-100 hover:text-customTeal border-none rounded-md"
              type="submit">Send Message</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<div *ngIf="declinedRequestCopy.length == 0 && declinedClicked" class="mt-5">
  <div class="flex items-center justify-center">
    <img loading="lazy" src="../../../../assets/icons/Illustrations/RegistrationRequest-Illustration.png"
      class="block w-64 flex items-center justify-center">
  </div>
  <div class="text-customTeal text-lg text-center font-bold">You have no Declined Registration Requests.</div>
  <div class="text-gray-400 text-sm text-center font-bold"> Check back later!</div>
</div>


<!--This is a confirmation to Accept a user-->
<div type="checkbox"  *ngIf="acceptOpenModal" class="modal"
[ngClass]="{'modal-open': acceptOpenModal, 'modal-action': !acceptOpenModal }">
<div class="modal-box">
  <app-close-modal (closeBtnClicked)=closeAcceptModal()></app-close-modal>
  <div class="modal-content">
    <h3 class="font-bold text-lg text-center mb-1">Accept Account</h3>
    <hr />
    <h3 class="font-bold text-md text-center mt-2">Are You sure you want to Accept this Account?</h3>
    <p class="py-4 text-center">Users with accepted accounts will be able to sign in.</p>

    <div class="modal-action items-center justify-between mx-6">
      <label for="my-modal-acceptPending" (click)="closeAcceptModal()"
        class="font-bold text-customTeal border-none">Cancel</label>
      <label for="my-model"><button type="button" class="py-2 px-4 bg-green-400 text-white border-red-400 rounded-md"
          (click)="acceptFriendRequest(request)">Accept</button></label>
    </div>
  </div>
</div>
</div>

<!--This is a confirmation to Decline a user-->
<div id="my-modal-users" *ngIf="declineOpenModal" class="modal"
  [ngClass]="{'modal-open': declineOpenModal, 'modal-action': !declineOpenModal }">
  <div class="modal-box">
  <app-close-modal (closeBtnClicked)=closeDeclineModal()></app-close-modal>
    <div class="modal-content">
      <h3 class="font-bold text-lg text-center mb-1">Reject Account</h3>
      <hr />
      <h3 class="font-bold text-md text-center mt-2">Are you sure you want to Reject this Account?</h3>
      <p class="py-4 text-center">Users who have rejected accounts won't be able to signup.</p>
      <form [formGroup]="declinedForm" (ngSubmit)="rejectFriendRequest(declinedForm.value,pendingFriendRequestCopy)">
        <p class="font-bold">Reason:</p>
        <input id="reason" name="reason" type="text" formControlName="reason" class=" border rounded py-3 w-full h-24 resize-y text-gray-700  bg-veryLightGray focus:outline-gray-200 focus:shadow-outline ">
      <!-- </input> -->
      <div *ngIf="(reason.dirty || reason.touched) && reason.invalid">
        <small *ngIf="reason.errors.required" class="text-error"> Reason is required. </small>
      </div>
      <div class="modal-action items-center justify-between mx-6">
        <button (click)="closeDeclineModal()"
          class="font-bold text-customTeal border-none">Cancel</button>
        <button
          class="py-2 px-4 bg-red-600 text-white border-red-400 rounded-md" type="submit" [disabled] = "!declinedForm.valid"  [ngClass]="{'bg-red-400': !declinedForm.valid}">Reject</button>
      </div>
    </form>
    </div>
  </div>
</div>

<!--This is a invitation to sent to a user-->
<div id="my-modal-users" *ngIf="invitationModal" class="modal"
  [ngClass]="{'modal-open': invitationModal, 'modal-action': !invitationModal }">
  <div class="modal-box">
  <!-- <app-close-modal (closeBtnClicked)=closeDeclineModal()></app-close-modal> -->
  <div (click)="closeInvitation()" class="flex justify-end cursor-pointer"><i
    class="material-icons px-3 py-3 bg-gray-200 rounded-full">close</i></div>
    <div class="modal-content">
      <h3 class="font-bold text-lg text-center mb-1">Invitation</h3>
      <hr />
      <h3 class="text-md text-center mt-2">Do you want to delete the invitation or do you want to invite the Subscriber {{invitationFirstName}} again?</h3>
 
      <div class="modal-action items-center justify-between mx-6">
        <button (click)="openDeleteModal()"
          class="py-2 px-4 font-bold text-white bg-red-600 border-red-400 rounded-md">Delete Invitation</button>
        <button (click)="inviteSubscriber()"
          class="py-2 px-4 bg-customTeal text-white border-red-400 rounded-md" type="button" 
         >
          Invite Subscriber</button>
      </div>
    </div>
  </div>
</div>

<!--This is a delte invitation to sent to a user-->
<div id="my-modal-users" *ngIf="deleteInviteModal" class="modal"
  [ngClass]="{'modal-open': deleteInviteModal, 'modal-action': !deleteInviteModal }">
  <div class="modal-box">
  <!-- <app-close-modal (closeBtnClicked)=closeDeclineModal()></app-close-modal> -->
    <div class="modal-content">
      <h3 class="font-bold text-lg text-center mb-1">Delete Invitation</h3>
      <hr />
      <h3 class="text-md text-center mt-2">Are you sure you want to delete this invitation?</h3>
      <p class="py-4 text-center">Users with invitation deleted won't be able to signup.</p>
 
      <div class="modal-action items-center justify-between mx-6">
        <button (click)="closeDeleteModal()"
          class="py-2 px-4 font-bold text-black rounded-md">Cancel</button>
        <button (click)="deleteInvitation()"
          class="py-2 px-4 bg-red-500 text-white border-red-400 rounded-md" type="button" 
         >
          Yes</button>
      </div>
    </div>
  </div>
</div>

<!-- toast -->
<app-alert class="fixed top-0 flex justify-end w-full" [toastType]="toastType" [toastMessage]="toastMessage"
[ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>



</div>
