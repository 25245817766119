import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { PermissionService } from 'src/app/services/firebase/permission.service';
import { HelperService } from 'src/app/services/helper/helper';
import { Comment } from 'src/app/models/PostsModel';
import { CommentsService } from 'src/app/services/firebase/comments.service';
import { NotificationsService } from 'src/app/services/firebase/notifications.service';
import { FCMPushNotificationsService } from 'src/app/services/firebase/FCMPushNotifications.service';
import { NotificationModel } from 'src/app/models/Messages.Model';

@Component({
  selector: 'app-post-comment',
  templateUrl: './post-comment.component.html',
  styleUrls: ['./post-comment.component.css']
})
export class PostCommentComponent implements OnInit {
  @Input() post;
  @Input() postType;
  @Input() sectionName;
  @Output() showCommentDeletedToast = new EventEmitter();

  commentOnPost: UntypedFormGroup;
  selectedComment: any;
  commentsCount = 3;
  showAllMessages = false;
  currentUser: any;
  isAdmin: boolean;
  isUser: boolean;
  perm: any;
  permission: any;
  permPES: any;
  notifSettings;
  commentDeleteModalOpen = false;

  constructor(private helperService: HelperService,
              private fb: UntypedFormBuilder,
              private authService: AuthService,
              private commentsService: CommentsService,
              private pushNotificationsService: FCMPushNotificationsService,
              private notificationsService: NotificationsService,
              private permissionService: PermissionService) { }

  ngOnInit(): void {
    this.currentUser = this.authService.getGlobalUser();
    if (this.currentUser){
      if(this.currentUser.role == 'Admin'){
        this.isAdmin = true;
      }
      if(this.currentUser.role == 'User'){
        this.isUser = true;
      }
      if(this.currentUser.role == 'Employee'){
        this.permissionService.getPermissionByEmployeeId(this.currentUser.uid).valueChanges().subscribe(perm => {
          if(perm.length != 0){
            this.perm = perm[0];
            this.permPES = this.perm.permission[1];
          }
        });
      }
    }
    this.commentOnPost = this.fb.group({
      comment: ['', [Validators.required]]
    });

  }

  toggleCommentsView() {
    this.showAllMessages = !this.showAllMessages;
    this.commentsCount = this.showAllMessages ? this.post.comments.length : 3;
  }

  addCommentToPostClicked(post, comment){
      const newComment = new Comment();
      newComment.postId = post.id;
      newComment.userId = this.currentUser.uid;
      newComment.userName = `${this.currentUser.GivenName || this.currentUser.FirstName} ${this.currentUser.LastName}`;
      newComment.comment = comment;
      newComment.timestamp = new Date();
      newComment.likes = {
        count: 0,
        userId: [],
        usernames: []
      };
      newComment.replies = [];
      this.commentsService.addCommentToPost(post, newComment, this.postType);
      this.commentOnPost.reset();
      const notif = {
        timeStamp: new Date(),
        owner: this.post.createdById,
        type: 'postComment',
        refrenceId: post.id,
        createdById: this.currentUser.uid,
        createdByFullName: this.currentUser.GivenName + ' ' + this.currentUser.LastName
      };
      if (this.isAdmin === false){
        let title = `${this.sectionName} comment`
        let content = `${notif.createdByFullName} commented on your post`
        this.sendPushNotificationToOwner(title,content)
      }
  
      this.notificationsService.addNotification(notif);
  
  }

  formatDate(timestamp){
    return this.helperService.formatDateForComment(timestamp)
  }

  sendPushNotificationToOwner(title,content){
    let conditions=["blogComment"]
    if(this.notifSettings && this.helperService.checkNotificationForOwner(this.notifSettings,conditions)){
      let notification = new NotificationModel()
      notification.title=title
      notification.body=content
      this.pushNotificationsService.sendNotificationByRole(notification,'Admin')
    }
  }

  deleteComment(){
    this.post.comments = this.post.comments.filter((comment: any) => {
      return comment.id !== this.selectedComment.id;
    });
    this.commentDeleteModalOpen = false;
    this.commentsService.updateComments(this.post,this.postType).then(() => {
      this.showCommentDeletedToast.emit(this.post)
    });
  }

  closeCommentDeleteModal(){
    this.commentDeleteModalOpen = false;
  }

  openCommentDeleteModal(comment){
    this.selectedComment = comment;
    this.commentDeleteModalOpen = true;
  }

}
