import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})

export class CountersService {
  contentListRef: AngularFirestoreCollection<any>;
  contentRef: AngularFirestoreDocument<any>;

  constructor(private db: AngularFirestore,) {

   }

saveCounter(model: any) {
    // model.id = this.db.createId();
    // return this.db.collection('Counter').doc(model.id).set(model);
    return this.db.collection('Counter');
}

updateCounter(model: any) {
    return this.db.collection('Counter').doc(model.id).update(model);
    // return this.db.collection('Counter').doc().update(model);
}

getUserById(userId: string){
  return this.db.collection('Counter', ref => ref.where('id', '==', userId));
}

getCounter(){
  return this.db.collection('Counter');
}


// sample meta tag api
getMetadata(){
  return this.db.collection('MetaTag');
}

}
