import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class ApiKeyManagerService {



  // ----- Firebase Cloud Messaging SERVERS KEYS --------
  // tslint:disable-next-line: max-line-length
  private demoFCMKey = 'key=AAAAYEoEr1U:APA91bFaJ2WrCU4PFsLQYAX-3b57Hs_o7aRYNevOF_e7cfLRN_hoN6yxiFjlXJj_4xzlikTaGfsjvvxi9ORbYV0XvZ08b5tOSk_X5UbSShHo7RfJHGpizsiR9xLw1QCTTosGy9Bp9-B9';
  private lamiaFCMKey = 'key=AAAAYEoEr1U:APA91bFaJ2WrCU4PFsLQYAX-3b57Hs_o7aRYNevOF_e7cfLRN_hoN6yxiFjlXJj_4xzlikTaGfsjvvxi9ORbYV0XvZ08b5tOSk_X5UbSShHo7RfJHGpizsiR9xLw1QCTTosGy9Bp9-B9';
  private teriFCMKey = 'key=AAAAYEoEr1U:APA91bFaJ2WrCU4PFsLQYAX-3b57Hs_o7aRYNevOF_e7cfLRN_hoN6yxiFjlXJj_4xzlikTaGfsjvvxi9ORbYV0XvZ08b5tOSk_X5UbSShHo7RfJHGpizsiR9xLw1QCTTosGy9Bp9-B9';

  private gmFCMKey = 'key=AAAAYEoEr1U:APA91bFaJ2WrCU4PFsLQYAX-3b57Hs_o7aRYNevOF_e7cfLRN_hoN6yxiFjlXJj_4xzlikTaGfsjvvxi9ORbYV0XvZ08b5tOSk_X5UbSShHo7RfJHGpizsiR9xLw1QCTTosGy9Bp9-B9';
  private hermiFCMKey = 'key=AAAAYEoEr1U:APA91bFaJ2WrCU4PFsLQYAX-3b57Hs_o7aRYNevOF_e7cfLRN_hoN6yxiFjlXJj_4xzlikTaGfsjvvxi9ORbYV0XvZ08b5tOSk_X5UbSShHo7RfJHGpizsiR9xLw1QCTTosGy9Bp9-B9';
  private shayFCMKey = 'key=AAAAYEoEr1U:APA91bFaJ2WrCU4PFsLQYAX-3b57Hs_o7aRYNevOF_e7cfLRN_hoN6yxiFjlXJj_4xzlikTaGfsjvvxi9ORbYV0XvZ08b5tOSk_X5UbSShHo7RfJHGpizsiR9xLw1QCTTosGy9Bp9-B9';
  private cheifFCMKey = 'key=AAAAYEoEr1U:APA91bFaJ2WrCU4PFsLQYAX-3b57Hs_o7aRYNevOF_e7cfLRN_hoN6yxiFjlXJj_4xzlikTaGfsjvvxi9ORbYV0XvZ08b5tOSk_X5UbSShHo7RfJHGpizsiR9xLw1QCTTosGy9Bp9-B9';
  constructor() { }

  getKey(): string {
    return 'F1BCBF46-1225-4E49-BA69-A047C78B364A-9038C86E-7135-4588-A65E-89448B4BF13A-1C3B0AEE';
  }

  getFCMKey(): string {
    return this.demoFCMKey;
    // if (environment.firstNation.name === 'Demo') { return this.demoFCMKey; }
    // if (environment.firstNation.name === 'Lamia') { return this.lamiaFCMKey; }
    // if (environment.firstNation.name === 'Teri') { return this.teriFCMKey; }
    // if (environment.firstNation.name === 'Hermi') { return this.hermiFCMKey; }
    // if (environment.firstNation.name === 'GoingMobile') { return this.gmFCMKey; }
    // if (environment.firstNation.name === 'Shay') { return this.shayFCMKey; }
    // if (environment.firstNation.name === 'Chief') { return this.cheifFCMKey; }
  }

}
