// import { AboutService } from './../../../services/firebase/about.service';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-about-values',
  templateUrl: './values.component.html',
//   styleUrls: ['./values.component.css']
})
export class AboutValuesComponent implements OnInit {

  constructor() { }

  @Input() data
  @Input() calledFromEmptyDashboard

  ngOnInit(): void {

  }
  ngOnChanges(changes: SimpleChanges): void {
    this.data
  }
  isEven(index){
    index += 1 
    if(index/2 === 0){
      return true
    }else{
      return false
    }
    
  }

}
