import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ShareDataModel } from 'src/app/models/SocialMediaPostModel';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { HelperService } from 'src/app/services/helper/helper';
import { ProfieKeyService } from 'src/app/services/social-media/profileKey.service';
import {
  Modal,
  Ripple,
  initTE,
} from "tw-elements";



@Component({
  selector: 'app-social-media-share-post',
  templateUrl: './share-post.component.html',
})
export class SocialMediaSharePostComponent implements OnInit {

    @Input() shareData: ShareDataModel
    @Input() sharedFromComponent
    @Input() calledFromDashboard =''

    @Output() onCancel = new EventEmitter();

    user

    constructor(
        private profieKeyService:ProfieKeyService,
        private router: Router,
        private helperSerivce:HelperService,
        private authService: AuthService,


    ){

    }

    ngOnInit() {
        this.user = this.authService.getGlobalUser()
        initTE({ Modal, Ripple });
    }

    cancelActionClicked(){
        this.onCancel.emit();
    }

    onShare(){

        this.shareData.share=false

        this.shareData.share=true
        this.shareData.post = this.helperSerivce.getInnerText(this.shareData.post)
        this.profieKeyService.updateShareData(this.shareData)
        this.profieKeyService.shouldShare=true
          if(this.user.role == 'Admin'){
            this.router.navigate(['admin-social'])
          }
          else{
            this.router.navigate(['employee-social'])
          }
        

    
    }

}