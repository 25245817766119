import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ProductsServiceMenuService {
    private calledFrom: any;
    private onboardingSection: any;
    private products = new BehaviorSubject('');
    private services = new BehaviorSubject('');
    private productsCategory = new BehaviorSubject('');
    private servicesCategory = new BehaviorSubject('');
    private blogs = new BehaviorSubject('');
    private events = new BehaviorSubject('');
    private aboutUs = new BehaviorSubject('');
    private currency = new BehaviorSubject('');
    private socialMedia = new BehaviorSubject('');

    productsName = this.products.asObservable();
    productsCategoryName = this.productsCategory.asObservable();
    servicesName = this.services.asObservable();
    servicesCategoryName = this.servicesCategory.asObservable();
    blogsName = this.blogs.asObservable();
    eventsName = this.events.asObservable();
    aboutUsName = this.aboutUs.asObservable();
    currencyName = this.currency.asObservable();
    socialMediaName = this.socialMedia.asObservable();

    constructor() {

    }
    setCalledFromData(data: any): void {
        this.calledFrom = data;
      }
    
      getCalledFromData(): any {
        return this.calledFrom;
      }
      setOnboardingSectionData(data: any): void {
        this.onboardingSection = data;
      }
    
      getOnboardingSectionData(): any {
        return this.onboardingSection;
      }
    updateMenu(data) {
        this.products.next(this.capitalize(data.products));
        this.services.next(this.capitalize(data.services));
        this.productsCategory.next(this.capitalize(data.productsCategory));
        this.servicesCategory.next(this.capitalize(data.servicesCategory));
        this.blogs.next(this.capitalize(data.blogs));
        this.events.next(this.capitalize(data.events));
        this.aboutUs.next(this.capitalize(data.aboutUs));
        this.currency.next(this.capitalize(data.currency));
        this.socialMedia.next(this.capitalize(data.socialMedia));
    }
    capitalize(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
}
