<!-- <div *ngIf="!forgotpassword"> -->
<span *ngIf="(currentUser || !currentUser) && !id">
	<div class="flex justify-center w-full mt-6">
		<!-- <img [routerLink]="['/']" *ngIf="!appLogo" src="../../../../assets/img/Going Mobile - 3.webp"
			class="block w-32 flex justify-center cursor-pointer" alt="Logo "> -->
		<img loading="lazy" [routerLink]="['/']" *ngIf="appLogo" [src]="appLogo" class="block w-32 ml-20 flex justify-center cursor-pointer "
			alt="Logo ">
	</div>

	<div class="flex flex-col justify-center items-center">
		<p class="mt-10 text-2xl font-normal text-black">Thank you For Registering With</p>
		<p class="mt-2 text-xl text-customTeal font-bold">{{appName | uppercase}}</p>
		<div class=" w-1/2">
			<div class="h-1 text-black bg-black"></div>
		</div>
		<!-- <hr /><hr />  -->
		<p class="mt-2 text-4xl font-extrabold text-black">Verify your email.</p>
		<p class="my-2 text-md">
			You will need to verify your email to complete registration
		</p>
		<div class="w-96">
			<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
				y="0px" viewBox="0 0 500 500" style="enable-background:new 0 0 500 500;" xml:space="preserve">
				<style type="text/css">
					.st0 {
						fill: #263238;
					}

					.st1 {
						fill: #263238;
						stroke: #263238;
						stroke-linecap: round;
						stroke-linejoin: round;
						stroke-miterlimit: 10;
					}

					.st2 {
						fill: #FFFFFF;
						stroke: #263238;
						stroke-miterlimit: 10;
					}

					.st3 {
						fill: none;
						stroke: #263238;
						stroke-miterlimit: 10;
					}

					.st4 {
						fill: #92E3A9;
						stroke: #263238;
						stroke-miterlimit: 10;
					}

					.st5 {
						fill: #FFFFFF;
						stroke: #263238;
						stroke-linecap: round;
						stroke-linejoin: round;
						stroke-miterlimit: 10;
					}

					.st6 {
						fill: none;
						stroke: #263238;
						stroke-linecap: round;
						stroke-linejoin: round;
						stroke-miterlimit: 10;
					}

					.st7 {
						fill: #B0B0B0;
						stroke: #263238;
						stroke-linecap: round;
						stroke-linejoin: round;
						stroke-miterlimit: 10;
					}

					.st8 {
						fill: none;
						stroke: #263238;
						stroke-width: 2;
						stroke-linecap: round;
						stroke-linejoin: round;
						stroke-miterlimit: 10;
					}

					.st9 {
						fill: #92E3A9;
						stroke: #263238;
						stroke-linecap: round;
						stroke-linejoin: round;
						stroke-miterlimit: 10;
					}

					.st10 {
						fill: none;
						stroke: #FFFFFF;
						stroke-miterlimit: 10;
					}

					.st11 {
						fill: #92E3A9;
					}
				</style>
				<g id="Background_Simple">
				</g>
				<g id="Plants">
					<g>
						<path class="st0" d="M19.09,374.66c2.97-0.22,8.82-3.46,8.82-3.47c0.06-0.03-3.31-3.2-3.55-3.38c-2.73-2.03-9.19-4.35-12.29-2.14
			c-2.23,1.59-2.3,4.7-0.57,6.68C13.28,374.38,16.56,374.84,19.09,374.66z" />
						<path class="st0" d="M30.16,390.71c2.7-0.96,7.17-5.27,7.53-5.61c2.39,2.52,5.04,4.9,7.87,7.17c1.61,1.3,3.27,2.56,4.96,3.8
			c-0.85-0.45-1.62-0.85-1.73-0.89c-3.17-1.24-10.01-1.77-12.42,1.18c-1.73,2.13-0.98,5.14,1.22,6.6c2.24,1.48,5.53,1.07,7.92,0.22
			c2.43-0.86,6.32-4.46,7.33-5.42c0.65,0.47,1.3,0.94,1.95,1.41c3.13,2.24,6.27,4.46,9.27,6.73c1.06,0.8,2.07,1.63,3.09,2.45
			c-0.76-0.4-1.4-0.73-1.5-0.77c-3.17-1.24-10.01-1.77-12.42,1.18c-1.73,2.13-0.98,5.15,1.22,6.6c2.24,1.48,5.53,1.07,7.92,0.22
			c2.32-0.82,5.94-4.11,7.15-5.25c1.02,0.87,2.04,1.75,2.98,2.64c0.63,0.62,1.28,1.18,1.88,1.85c0.61,0.64,1.21,1.28,1.81,1.91
			c1.1,1.26,2.25,2.55,3.3,3.8c0.73,0.87,1.42,1.71,2.11,2.55c-1.05-0.81-3.13-2.35-3.32-2.46c-2.94-1.7-9.63-3.27-12.46-0.72
			c-2.04,1.84-1.75,4.94,0.2,6.7c1.99,1.8,5.3,1.89,7.8,1.42c2.75-0.52,7.64-3.94,8.25-4.37c1.14,1.4,2.23,2.77,3.24,4.08
			c2.73,3.52,4.99,6.66,6.75,9.18l2.24-1.18c-1.87-2.56-4.29-5.77-7.23-9.4c-1.15-1.43-2.41-2.93-3.71-4.46
			c0.31-0.36,2.07-2.44,3.47-4.59c2.71,0.96,8.28,0.82,9,0.79c0.18,1.8,0.33,3.54,0.45,5.2c0.32,4.31,0.48,8.09,0.56,11.12
			l2.44-1.28c-0.15-2.84-0.38-6.22-0.75-10.03c-0.17-1.82-0.39-3.77-0.64-5.77c0.7-0.2,6.44-1.88,8.57-3.7
			c1.93-1.65,3.93-4.29,3.77-6.97c-0.16-2.63-2.39-4.79-5.1-4.35c-3.76,0.61-6.72,6.8-7.23,10.17c-0.03,0.2-0.12,2.39-0.15,3.77
			c-0.1-0.81-0.2-1.61-0.31-2.44c-0.22-1.66-0.49-3.31-0.78-5.08c-0.17-0.85-0.34-1.71-0.51-2.58c-0.15-0.86-0.42-1.76-0.63-2.65
			c-0.53-2.05-1.16-4.11-1.84-6.16c1.45-0.2,6.59-0.98,8.76-2.32c2.16-1.34,4.54-3.64,4.78-6.32c0.24-2.62-1.64-5.1-4.38-5.08
			c-3.81,0.03-7.68,5.71-8.69,8.95c-0.06,0.2-0.53,2.61-0.76,3.94c-0.42-1.23-0.83-2.46-1.3-3.7c-1.34-3.56-2.82-7.12-4.27-10.68
			c-0.45-1.09-0.88-2.18-1.31-3.27c0.52-0.06,6.65-0.86,9.08-2.36c2.16-1.34,4.54-3.64,4.79-6.32c0.24-2.62-1.64-5.1-4.38-5.07
			c-3.81,0.03-7.68,5.71-8.69,8.95c-0.08,0.24-0.74,3.71-0.87,4.6c-0.96-2.4-1.89-4.81-2.71-7.21c-1.14-3.32-2.08-6.64-2.74-9.92
			c0.24-0.03,6.65-0.83,9.13-2.37c2.16-1.34,4.54-3.64,4.78-6.32c0.24-2.62-1.64-5.1-4.38-5.07c-3.81,0.03-7.68,5.71-8.69,8.95
			c-0.08,0.25-0.79,3.99-0.88,4.67c-0.04-0.2-0.1-0.39-0.14-0.59c-0.67-3.51-0.88-7-0.91-10.28c-0.02-1.9,0.06-3.72,0.18-5.49
			c1.21-0.03,6.73-0.21,9.12-1.35c2.29-1.09,4.91-3.12,5.45-5.76c0.53-2.58-1.07-5.25-3.8-5.53c-3.79-0.39-8.26,4.83-9.62,7.94
			c-0.07,0.17-0.64,1.97-1.03,3.3c0.07-0.8,0.12-1.62,0.2-2.38c0.29-2.86,0.74-5.47,1.15-7.77c0.24-1.31,0.49-2.49,0.73-3.59
			c1.17-0.64,5.86-3.3,7.43-5.38c1.53-2.03,2.92-5.03,2.18-7.62c-0.72-2.53-3.37-4.16-5.92-3.15c-3.54,1.4-5.1,8.09-4.88,11.48
			c0.02,0.29,0.85,4.84,0.91,4.81c0,0,0.06-0.03,0.07-0.04c-0.26,1.05-0.52,2.19-0.78,3.43c-0.46,2.3-0.96,4.9-1.32,7.77
			c-0.36,2.87-0.67,5.99-0.71,9.31c-0.04,3.33,0.09,6.83,0.7,10.43c0.01,0.04,0.02,0.08,0.03,0.12c-0.5-1-2-3.73-2.14-3.93
			c-1.96-2.78-7.38-7-11.01-5.87c-2.62,0.82-3.66,3.75-2.62,6.17c1.05,2.47,4.02,3.94,6.49,4.55c2.78,0.69,8.89-0.44,9.37-0.53
			c0.6,3.42,1.5,6.87,2.6,10.32c0.63,1.97,1.32,3.94,2.04,5.9c-0.46-0.85-0.88-1.6-0.95-1.7c-1.96-2.78-7.38-7-11.01-5.87
			c-2.62,0.82-3.66,3.75-2.62,6.17c1.05,2.47,4.02,3.94,6.49,4.55c2.5,0.62,7.73-0.23,9.11-0.48c0.28,0.75,0.56,1.5,0.85,2.25
			c1.38,3.59,2.77,7.17,4.02,10.73c0.44,1.25,0.83,2.5,1.23,3.75c-0.41-0.75-0.76-1.38-0.83-1.47c-1.96-2.78-7.38-7-11.01-5.87
			c-2.62,0.82-3.66,3.75-2.62,6.17c1.05,2.47,4.02,3.94,6.49,4.55c2.39,0.6,7.22-0.15,8.86-0.44c0.37,1.29,0.73,2.58,1.03,3.85
			c0.18,0.87,0.42,1.7,0.55,2.58c0.15,0.87,0.3,1.74,0.45,2.59c0.23,1.66,0.47,3.37,0.65,4.99c0.13,1.13,0.24,2.2,0.35,3.29
			c-0.43-1.25-1.31-3.68-1.41-3.88c-1.01-2.02-3.43-4.9-6.04-6.44c-0.15-0.3-0.32-0.59-0.52-0.85c-1.58-2.1-4.64-2.67-6.66-0.81
			c-2.8,2.58-1.85,9.38-0.43,12.47c0.08,0.18,1.22,2.06,1.95,3.23c-0.54-0.62-1.05-1.23-1.61-1.87c-1.1-1.26-2.23-2.49-3.45-3.81
			c-0.61-0.62-1.23-1.24-1.85-1.87c-0.6-0.64-1.32-1.24-1.99-1.86c-1.57-1.42-3.23-2.79-4.94-4.12c1.1-0.97,4.96-4.45,6.03-6.77
			c1.06-2.31,1.77-5.54,0.5-7.91c-1.24-2.32-4.18-3.35-6.46-1.81c-3.16,2.13-3.25,8.99-2.31,12.26c0.06,0.2,1,2.47,1.54,3.71
			c-1.03-0.8-2.06-1.6-3.12-2.37c-3.08-2.23-6.28-4.38-9.45-6.55c-0.97-0.66-1.93-1.34-2.9-2.01c0.4-0.34,5.08-4.38,6.27-6.98
			c1.06-2.31,1.77-5.54,0.51-7.91c-1.24-2.32-4.18-3.35-6.46-1.81c-3.16,2.13-3.25,9-2.31,12.26c0.07,0.25,1.43,3.51,1.82,4.32
			c-2.12-1.48-4.23-2.97-6.24-4.52c-2.78-2.14-5.39-4.39-7.76-6.76c0.18-0.16,5.09-4.36,6.31-7.01c1.06-2.31,1.77-5.54,0.51-7.91
			c-1.24-2.32-4.18-3.35-6.46-1.81c-3.16,2.13-3.25,8.99-2.31,12.26c0.07,0.26,1.54,3.76,1.85,4.38c-0.14-0.14-0.3-0.27-0.44-0.41
			c-2.5-2.56-4.59-5.36-6.43-8.07c-1.07-1.57-2-3.14-2.88-4.68c0.99-0.69,5.5-3.88,6.86-6.16c1.31-2.18,2.37-5.31,1.37-7.81
			c-0.98-2.44-3.79-3.79-6.22-2.51c-3.37,1.77-4.22,8.58-3.64,11.93c0.03,0.18,0.56,2,0.96,3.32c-0.38-0.7-0.79-1.42-1.15-2.1
			c-1.34-2.55-2.4-4.97-3.33-7.12c-0.52-1.23-0.96-2.35-1.37-3.4c0.62-1.18,3.07-5.99,3.23-8.58c0.16-2.53-0.34-5.81-2.38-7.56
			c-2-1.72-5.11-1.61-6.68,0.64c-2.18,3.12,0.21,9.56,2.27,12.27c0.18,0.23,3.38,3.57,3.41,3.51c0,0,0.03-0.06,0.04-0.07
			c0.37,1.02,0.78,2.11,1.25,3.29c0.88,2.17,1.9,4.62,3.19,7.21c1.28,2.59,2.74,5.37,4.55,8.16c1.81,2.8,3.85,5.65,6.33,8.31
			c0.03,0.03,0.06,0.06,0.09,0.09c-0.97-0.55-3.73-2.01-3.95-2.1c-3.17-1.24-10.01-1.77-12.42,1.18c-1.73,2.13-0.98,5.15,1.22,6.6
			C24.48,391.98,27.76,391.56,30.16,390.71z" />
						<path class="st0" d="M79.6,344.46c0.07,0.01-0.99-4.5-1.09-4.77c-1.15-3.2-5.27-8.7-9.07-8.57c-2.74,0.09-4.52,2.65-4.16,5.26
			c0.36,2.66,2.84,4.86,5.05,6.11C72.93,343.94,79.59,344.46,79.6,344.46z" />
					</g>
					<g>
						<path class="st0" d="M479.23,374.66c-2.97-0.22-8.82-3.46-8.82-3.47c-0.06-0.03,3.31-3.2,3.55-3.38
			c2.73-2.03,9.19-4.35,12.29-2.14c2.23,1.59,2.3,4.7,0.57,6.68C485.04,374.38,481.76,374.84,479.23,374.66z" />
						<path class="st0" d="M468.17,390.71c-2.7-0.96-7.17-5.27-7.53-5.61c-2.39,2.52-5.04,4.9-7.87,7.17c-1.61,1.3-3.27,2.56-4.96,3.8
			c0.85-0.45,1.62-0.85,1.73-0.89c3.17-1.24,10.01-1.77,12.42,1.18c1.73,2.13,0.98,5.14-1.22,6.6c-2.24,1.48-5.53,1.07-7.92,0.22
			c-2.43-0.86-6.32-4.46-7.33-5.42c-0.65,0.47-1.3,0.94-1.95,1.41c-3.13,2.24-6.27,4.46-9.27,6.73c-1.06,0.8-2.07,1.63-3.09,2.45
			c0.76-0.4,1.4-0.73,1.5-0.77c3.17-1.24,10.01-1.77,12.42,1.18c1.73,2.13,0.98,5.15-1.22,6.6c-2.24,1.48-5.53,1.07-7.92,0.22
			c-2.32-0.82-5.94-4.11-7.15-5.25c-1.02,0.87-2.04,1.75-2.98,2.64c-0.63,0.62-1.28,1.18-1.88,1.85c-0.61,0.64-1.21,1.28-1.81,1.91
			c-1.1,1.26-2.25,2.55-3.3,3.8c-0.73,0.87-1.42,1.71-2.11,2.55c1.05-0.81,3.13-2.35,3.32-2.46c2.94-1.7,9.63-3.27,12.46-0.72
			c2.04,1.84,1.75,4.94-0.2,6.7c-1.99,1.8-5.3,1.89-7.8,1.42c-2.75-0.52-7.64-3.94-8.25-4.37c-1.14,1.4-2.23,2.77-3.24,4.08
			c-2.73,3.52-4.99,6.66-6.75,9.18l-2.24-1.18c1.87-2.56,4.29-5.77,7.23-9.4c1.15-1.43,2.41-2.93,3.71-4.46
			c-0.31-0.36-2.07-2.44-3.47-4.59c-2.71,0.96-8.28,0.82-9,0.79c-0.18,1.8-0.33,3.54-0.45,5.2c-0.32,4.31-0.48,8.09-0.56,11.12
			l-2.44-1.28c0.15-2.84,0.38-6.22,0.75-10.03c0.17-1.82,0.39-3.77,0.64-5.77c-0.7-0.2-6.44-1.88-8.57-3.7
			c-1.93-1.65-3.93-4.29-3.77-6.97c0.16-2.63,2.39-4.79,5.1-4.35c3.76,0.61,6.72,6.8,7.24,10.17c0.03,0.2,0.12,2.39,0.15,3.77
			c0.1-0.81,0.2-1.61,0.31-2.44c0.22-1.66,0.49-3.31,0.78-5.08c0.17-0.85,0.34-1.71,0.51-2.58c0.15-0.86,0.42-1.76,0.63-2.65
			c0.53-2.05,1.16-4.11,1.84-6.16c-1.45-0.2-6.59-0.98-8.76-2.32c-2.16-1.34-4.54-3.64-4.78-6.32c-0.24-2.62,1.64-5.1,4.38-5.08
			c3.81,0.03,7.68,5.71,8.69,8.95c0.06,0.2,0.53,2.61,0.76,3.94c0.42-1.23,0.83-2.46,1.3-3.7c1.34-3.56,2.82-7.12,4.27-10.68
			c0.45-1.09,0.88-2.18,1.31-3.27c-0.52-0.06-6.65-0.86-9.08-2.36c-2.16-1.34-4.54-3.64-4.79-6.32c-0.24-2.62,1.64-5.1,4.38-5.07
			c3.81,0.03,7.68,5.71,8.69,8.95c0.08,0.24,0.74,3.71,0.87,4.6c0.96-2.4,1.89-4.81,2.71-7.21c1.14-3.32,2.08-6.64,2.74-9.92
			c-0.24-0.03-6.65-0.83-9.13-2.37c-2.16-1.34-4.54-3.64-4.78-6.32c-0.24-2.62,1.64-5.1,4.38-5.07c3.81,0.03,7.68,5.71,8.69,8.95
			c0.08,0.25,0.79,3.99,0.88,4.67c0.04-0.2,0.1-0.39,0.14-0.59c0.67-3.51,0.88-7,0.91-10.28c0.02-1.9-0.06-3.72-0.18-5.49
			c-1.21-0.03-6.73-0.21-9.12-1.35c-2.29-1.09-4.91-3.12-5.45-5.76c-0.53-2.58,1.07-5.25,3.8-5.53c3.79-0.39,8.26,4.83,9.62,7.94
			c0.07,0.17,0.64,1.97,1.03,3.3c-0.07-0.8-0.12-1.62-0.2-2.38c-0.29-2.86-0.74-5.47-1.15-7.77c-0.24-1.31-0.49-2.49-0.73-3.59
			c-1.17-0.64-5.86-3.3-7.43-5.38c-1.53-2.03-2.92-5.03-2.18-7.62c0.72-2.53,3.37-4.16,5.92-3.15c3.54,1.4,5.1,8.09,4.88,11.48
			c-0.02,0.29-0.85,4.84-0.91,4.81c0,0-0.06-0.03-0.07-0.04c0.26,1.05,0.52,2.19,0.78,3.43c0.46,2.3,0.96,4.9,1.32,7.77
			c0.36,2.87,0.67,5.99,0.71,9.31c0.04,3.33-0.09,6.83-0.7,10.43c-0.01,0.04-0.02,0.08-0.03,0.12c0.5-1,2-3.73,2.14-3.93
			c1.96-2.78,7.38-7,11.01-5.87c2.62,0.82,3.66,3.75,2.62,6.17c-1.05,2.47-4.02,3.94-6.49,4.55c-2.78,0.69-8.89-0.44-9.37-0.53
			c-0.6,3.42-1.5,6.87-2.6,10.32c-0.63,1.97-1.32,3.94-2.04,5.9c0.46-0.85,0.88-1.6,0.95-1.7c1.96-2.78,7.38-7,11.01-5.87
			c2.62,0.82,3.66,3.75,2.62,6.17c-1.05,2.47-4.02,3.94-6.49,4.55c-2.5,0.62-7.73-0.23-9.11-0.48c-0.28,0.75-0.56,1.5-0.85,2.25
			c-1.38,3.59-2.77,7.17-4.02,10.73c-0.44,1.25-0.83,2.5-1.23,3.75c0.41-0.75,0.76-1.38,0.83-1.47c1.96-2.78,7.38-7,11.01-5.87
			c2.62,0.82,3.66,3.75,2.62,6.17c-1.05,2.47-4.02,3.94-6.49,4.55c-2.39,0.6-7.22-0.15-8.86-0.44c-0.37,1.29-0.73,2.58-1.03,3.85
			c-0.18,0.87-0.42,1.7-0.55,2.58c-0.15,0.87-0.3,1.74-0.45,2.59c-0.23,1.66-0.47,3.37-0.65,4.99c-0.13,1.13-0.24,2.2-0.35,3.29
			c0.43-1.25,1.31-3.68,1.41-3.88c1.01-2.02,3.43-4.9,6.04-6.44c0.15-0.3,0.32-0.59,0.52-0.85c1.58-2.1,4.64-2.67,6.66-0.81
			c2.8,2.58,1.85,9.38,0.43,12.47c-0.08,0.18-1.22,2.06-1.95,3.23c0.54-0.62,1.05-1.23,1.61-1.87c1.1-1.26,2.23-2.49,3.45-3.81
			c0.61-0.62,1.23-1.24,1.85-1.87c0.6-0.64,1.32-1.24,1.99-1.86c1.57-1.42,3.23-2.79,4.94-4.12c-1.1-0.97-4.96-4.45-6.03-6.77
			c-1.06-2.31-1.77-5.54-0.5-7.91c1.24-2.32,4.18-3.35,6.46-1.81c3.16,2.13,3.25,8.99,2.31,12.26c-0.06,0.2-1,2.47-1.54,3.71
			c1.03-0.8,2.06-1.6,3.12-2.37c3.08-2.23,6.28-4.38,9.45-6.55c0.97-0.66,1.93-1.34,2.9-2.01c-0.4-0.34-5.08-4.38-6.27-6.98
			c-1.06-2.31-1.77-5.54-0.51-7.91c1.24-2.32,4.18-3.35,6.46-1.81c3.16,2.13,3.25,9,2.31,12.26c-0.07,0.25-1.43,3.51-1.82,4.32
			c2.12-1.48,4.23-2.97,6.24-4.52c2.78-2.14,5.39-4.39,7.76-6.76c-0.18-0.16-5.09-4.36-6.31-7.01c-1.06-2.31-1.77-5.54-0.51-7.91
			c1.24-2.32,4.18-3.35,6.46-1.81c3.16,2.13,3.26,8.99,2.31,12.26c-0.07,0.26-1.54,3.76-1.85,4.38c0.14-0.14,0.3-0.27,0.44-0.41
			c2.5-2.56,4.59-5.36,6.43-8.07c1.07-1.57,2-3.14,2.88-4.68c-0.99-0.69-5.5-3.88-6.86-6.16c-1.31-2.18-2.37-5.31-1.37-7.81
			c0.98-2.44,3.79-3.79,6.22-2.51c3.37,1.77,4.22,8.58,3.64,11.93c-0.03,0.18-0.56,2-0.96,3.32c0.38-0.7,0.79-1.42,1.15-2.1
			c1.34-2.55,2.4-4.97,3.33-7.12c0.52-1.23,0.96-2.35,1.37-3.4c-0.62-1.18-3.07-5.99-3.23-8.58c-0.16-2.53,0.34-5.81,2.38-7.56
			c2-1.72,5.11-1.61,6.68,0.64c2.18,3.12-0.21,9.56-2.27,12.27c-0.18,0.23-3.38,3.57-3.41,3.51c0,0-0.03-0.06-0.04-0.07
			c-0.37,1.02-0.78,2.11-1.24,3.29c-0.88,2.17-1.9,4.62-3.19,7.21c-1.28,2.59-2.74,5.37-4.55,8.16c-1.81,2.8-3.85,5.65-6.33,8.31
			c-0.03,0.03-0.06,0.06-0.09,0.09c0.97-0.55,3.73-2.01,3.95-2.1c3.17-1.24,10.01-1.77,12.42,1.18c1.73,2.13,0.98,5.15-1.22,6.6
			C473.85,391.98,470.56,391.56,468.17,390.71z" />
						<path class="st0" d="M418.73,344.46c-0.07,0.01,0.99-4.5,1.09-4.77c1.15-3.2,5.27-8.7,9.07-8.57c2.74,0.09,4.52,2.65,4.16,5.26
			c-0.36,2.66-2.84,4.86-5.05,6.11C425.4,343.94,418.73,344.46,418.73,344.46z" />
					</g>
				</g>
				<g id="Icons">
				</g>
				<g id="Mail">
					<g>
						<polygon class="st1"
							points="90.39,285.14 249.53,195.57 412.58,285.14 412.58,414.99 92.35,414.99 		" />
						<g>
							<rect x="132.38" y="219.73" class="st2" width="238.22" height="211.86" />
							<g>
								<line class="st3" x1="159.71" y1="256.83" x2="340.33" y2="256.83" />
								<line class="st3" x1="159.71" y1="275.38" x2="340.33" y2="275.38" />
								<line class="st3" x1="159.71" y1="293.93" x2="340.33" y2="293.93" />
								<line class="st3" x1="159.71" y1="312.48" x2="340.33" y2="312.48" />
								<line class="st3" x1="159.71" y1="331.03" x2="340.33" y2="331.03" />
								<line class="st3" x1="159.71" y1="349.58" x2="340.33" y2="349.58" />
							</g>
						</g>
						<polygon class="st4"
							points="412.58,463.81 90.39,463.81 90.39,285.14 251.48,394.49 412.58,285.14 		" />
						<line class="st3" x1="90.39" y1="463.81" x2="207.53" y2="364.6" />
						<line class="st3" x1="412.06" y1="463.81" x2="295.79" y2="364.44" />
					</g>
				</g>
				<g id="Character">
					<g>
						<g>
							<path class="st5" d="M286.33,160.67c0,0-1.21,5.66,0.4,7.48s7.68,8.29,7.68,9.3c0,1.01-2.02,30.13-2.02,30.13l-9.3-6.67
				l-5.66,17.39c0,0,14.15,6.07,20.83,7.28c6.67,1.21,7.89-0.61,7.89-5.86c0-5.26-5.26-45.5-5.26-45.5s1.62-9.3,1.62-10.92
				c0-1.62,0.61-2.43-0.81-3.24c-1.42-0.81-12.94-2.02-12.94-2.02S286.33,159.05,286.33,160.67z" />
							<polygon class="st1" points="283.33,203.36 280.26,218.95 277.44,218.3 			" />
							<g>
								<path class="st0" d="M306.04,165.21l-22.91-3.08v-5.71c0-0.54,0.44-0.98,0.98-0.98c0.54,0,0.98,0.44,0.98,0.98v4l19.15,2.58
					l0.33-4.62c0.04-0.54,0.49-0.94,1.04-0.9c0.54,0.04,0.94,0.51,0.9,1.04L306.04,165.21z" />
							</g>
							<polygon class="st5" points="288.36,163.3 300.89,164.51 301.7,160.06 288.76,158.04 			" />
							<path class="st5" d="M286.13,163.91c0,0,0-5.06,0.61-5.46c0.61-0.4,9.3,0.2,9.3,1.42c0,1.21,0,1.82-1.01,1.82
				c-1.01,0-3.03,0.2-3.03,0.2s4.65,4.25,3.24,8.09" />
							<line class="st6" x1="292.4" y1="207.58" x2="291.59" y2="216.48" />
						</g>
						<g>
							<path class="st5" d="M189.71,203.7c0,0-28.58,22.31-27.84,24.71c0.74,2.4,21.94,22.68,28.95,27.66
				c7.01,4.98,10.88,7.19,10.88,7.19l4.43,2.77c0,0-1.84,2.95-0.74,3.5s5.16,2.58,5.72,1.48c0.55-1.11,3.5-9.4,3.13-11.43
				c-0.37-2.03-11.06-3.69-11.8-4.43c-0.74-0.74-18.26-28.03-18.26-28.03l13.65-10.7L189.71,203.7z" />
							<polygon class="st1" points="189.22,204.44 194.41,218.51 197.83,216.42 			" />
							<path class="st6" d="M206.12,266.02c0,0,1.29,2.77,3.5,2.95" />
							<line class="st6" x1="184.18" y1="227.11" x2="180.49" y2="220.66" />
						</g>
						<g>
							<polygon class="st5" points="208.71,429.02 208.41,445.41 221.84,445.7 223.02,427.84 			" />
							<polygon class="st1" points="208.76,428.13 208.76,432.18 222.69,432.83 223.01,428.62 			" />
							<g>
								<path class="st1" d="M215.05,441.13c0,0-1.77-6.94-4.43-7.09c-2.66-0.15-3.99,1.92-5.02,3.84c-1.03,1.92-7.53,8.41-9.74,10.04
					s-7.09,3.69-7.82,8.41c-0.74,4.72,2.07,6.64,3.99,7.09s10.63,0.59,15.8,0c5.17-0.59,19.27,0.81,20.08,0
					c0.81-0.81,1.92-3.4,1.18-5.17c-0.74-1.77-2.07-3.69-2.07-3.69s1.48-4.28,0.59-6.64c-0.89-2.36-2.66-5.31-2.36-6.64
					c0.3-1.33,3.54-4.13,1.33-4.28c-2.21-0.15-5.76,2.51-7.97,3.25C216.38,440.98,215.05,441.13,215.05,441.13z" />
								<path class="st5" d="M224.5,455.15c-2.07,0.3-10.04,4.13-15.06,5.31c-5.02,1.18-9.45,1.33-11.81,0.15s-4.43-4.72-6.2-6.94
					c-1.77-2.21-3.25,3.4-3.25,3.4l-0.24,0.11c-0.3,4.11,2.28,5.82,4.08,6.24c1.92,0.44,10.63,0.59,15.8,0
					c5.17-0.59,19.27,0.81,20.08,0c0.81-0.81,1.92-3.4,1.18-5.17c-0.74-1.77-2.07-3.69-2.07-3.69
					C226.13,454.41,226.57,454.86,224.5,455.15z" />
								<path class="st5"
									d="M202.95,442.01c0,0,3.99-0.89,9.01,3.99l-2.66,2.21c0,0-5.17-3.99-8.41-3.99L202.95,442.01z" />
								<path class="st5"
									d="M199.26,444.97c0,0,3.99-0.89,9.01,3.99l-2.66,2.21c0,0-5.17-3.99-8.41-3.99L199.26,444.97z" />
								<line class="st6" x1="210.92" y1="444.52" x2="215.05" y2="441.13" />
								<path class="st5" d="M205.61,451.17c0,0,1.03,2.21-0.74,3.54c-1.77,1.33-13.43-0.89-13.29-2.51c0.15-1.62,5.61-5.02,5.61-5.02
					S201.62,448.07,205.61,451.17z" />
							</g>
						</g>
						<g>
							<polygon class="st5" points="293.42,429.02 293.71,445.41 280.28,445.7 279.1,427.84 			" />
							<polygon class="st1"
								points="279.36,430.88 279.36,433.31 293.61,433.15 293.45,429.59 279.04,429.43 			" />
							<g>
								<path class="st1" d="M287.07,441.13c0,0,1.77-6.94,4.43-7.09c2.66-0.15,3.99,1.92,5.02,3.84c1.03,1.92,7.53,8.41,9.74,10.04
					c2.21,1.62,7.09,3.69,7.82,8.41c0.74,4.72-2.07,6.64-3.99,7.09c-1.92,0.44-10.63,0.59-15.8,0c-5.17-0.59-19.27,0.81-20.08,0
					c-0.81-0.81-1.92-3.4-1.18-5.17c0.74-1.77,2.07-3.69,2.07-3.69s-1.48-4.28-0.59-6.64c0.89-2.36,2.66-5.31,2.36-6.64
					c-0.3-1.33-3.54-4.13-1.33-4.28c2.21-0.15,5.76,2.51,7.97,3.25C285.74,440.98,287.07,441.13,287.07,441.13z" />
								<path class="st5" d="M277.62,455.15c2.07,0.3,10.04,4.13,15.06,5.31c5.02,1.18,9.45,1.33,11.81,0.15s4.43-4.72,6.2-6.94
					c1.77-2.21,3.25,3.4,3.25,3.4l0.24,0.11c0.3,4.11-2.28,5.82-4.08,6.24c-1.92,0.44-10.63,0.59-15.8,0
					c-5.17-0.59-19.27,0.81-20.08,0c-0.81-0.81-1.92-3.4-1.18-5.17c0.74-1.77,2.07-3.69,2.07-3.69
					C276,454.41,275.55,454.86,277.62,455.15z" />
								<path class="st5"
									d="M299.17,442.01c0,0-3.99-0.89-9.01,3.99l2.66,2.21c0,0,5.17-3.99,8.41-3.99L299.17,442.01z" />
								<path class="st5"
									d="M302.86,444.97c0,0-3.99-0.89-9.01,3.99l2.66,2.21c0,0,5.17-3.99,8.41-3.99L302.86,444.97z" />
								<line class="st6" x1="291.2" y1="444.52" x2="287.07" y2="441.13" />
								<path class="st5" d="M296.52,451.17c0,0-1.03,2.21,0.74,3.54c1.77,1.33,13.43-0.89,13.29-2.51c-0.15-1.62-5.61-5.02-5.61-5.02
					S300.5,448.07,296.52,451.17z" />
							</g>
						</g>
						<g>
							<path class="st7" d="M214.88,256.03c0,0-7.81,31.89-10.74,58.9c-2.93,27.01-7.49,115.53-7.49,115.53h35.8
				c0,0,10.74-89.82,12.37-105.77c1.63-15.95,1.63-26.03,1.63-26.03l20.18,131.8h42.63c0,0-16.27-83.64-22.78-122.04
				c-6.51-38.4-15.62-53.37-15.62-53.37L214.88,256.03z" />
							<g>
								<path class="st7"
									d="M245.47,258.31c-0.31,0.93-8.79,13.67-6.18,14.64c2.6,0.98,7.49,0.98,7.49-1.63c0-2.6,0-10.41,0-10.41
					s0.65,14.64,3.58,13.99c2.93-0.65,6.83,0,5.86-3.25c-0.98-3.25-8.46-13.67-8.46-13.67S246.44,255.38,245.47,258.31z" />
								<path class="st3" d="M246.81,259.38c0,0,7.38,10.48,6.67,11.43c-0.71,0.95-1.67,1.91-2.38,0.48
					C250.38,269.86,246.81,259.38,246.81,259.38z" />
								<path class="st3" d="M246.57,258.19c0,0-5.48,11.67-4.76,12.15c0.71,0.48,2.38,0.48,2.86-1.19
					C245.14,267.48,246.57,258.19,246.57,258.19z" />
							</g>
							<path class="st6" d="M268.81,291.45c0.3,1.66,0.55,3.21,0.74,4.61" />
							<path class="st6" d="M261.09,259.29c0,0,4.06,14.5,6.67,26.81" />
							<path class="st6" d="M258.16,259.29c0,0,2.6,18.22,3.91,23.11" />
							<path class="st6" d="M222.74,284.52c-0.14,1.26-0.26,2.41-0.38,3.4" />
							<path class="st6" d="M225.29,259.29c0,0-1.08,11.14-2.03,20.33" />
							<line class="st6" x1="222.69" y1="259.94" x2="221.38" y2="275.56" />
						</g>
						<g>
							<path class="st1"
								d="M245.49,126.5c0,0,12.13,1.21,9.3,7.48c-2.83,6.27-5.66,7.48-6.27,7.48s-5.46-7.48-5.46-7.48L245.49,126.5z" />
							<g>
								<path class="st5" d="M244.07,132.36c0,0,4.65,2.63,6.07,7.48s5.06,6.27,6.07,6.07c1.01-0.2,2.43,2.83,1.21,4.85
					c-1.21,2.02-1.21,2.02-1.21,2.02s1.01,13.75-0.2,15.57c-1.21,1.82-8.69,0.81-8.69,0.81s-1.62,6.47-0.2,10.51
					c1.42,4.04,5.66,6.07,7.28,6.07s5.06-2.02,9.71,0.4c4.65,2.43,20.22,14.15,20.22,14.15l-8.29,22.65l-8.9-4.65l6.27,38.62
					l-60.66,1.42c0,0,0.61-27.5-1.01-32.56c-1.62-5.06-4.25-9.71-4.25-9.71l-7.68,6.07l-10.72-18.6c0,0,15.77-15.77,22.04-17.19
					c6.27-1.42,13.75,0.4,16.18-2.63c2.43-3.03,0.4-17.39,0.4-17.39s-5.86-2.02-9.3-12.33c-3.44-10.31,8.29-21.03,8.29-21.03
					S231.94,127.51,244.07,132.36z" />
								<polygon class="st1" points="247.31,169.16 241.84,168.24 246.23,173.29 				" />
								<path class="st8" d="M244.74,143.31c0,0,0-3.84,3.44-3.84" />
								<path class="st1"
									d="M253.78,157.23l-7.08-3.44c0,0-0.61,7.28,2.02,7.08C251.35,160.67,253.78,157.23,253.78,157.23z" />
								<path class="st6" d="M246.85,147.06c0,0,2.28-2.47,4.18-0.38" />
								<path class="st5" d="M246.65,156.1l6.36,2.07c0.48-0.54,0.77-0.94,0.77-0.94l-7.08-3.44
					C246.7,153.79,246.62,154.83,246.65,156.1z" />
							</g>
							<g>
								<path class="st1" d="M207.07,141.06c0,0-4.45-9.1-10.72-8.09c-6.27,1.01-11.12,7.89-9.1,17.59c0,0,1.01,2.63,1.82,4.85
					c0,0-7.89-2.43-12.13,2.83c-4.25,5.26-1.01,10.72,3.84,13.35c4.85,2.63,7.68,2.43,7.68,2.43s-10.72,3.24-6.88,7.28
					c3.84,4.04,15.57,1.42,13.14-2.02c-2.43-3.44-4.25-3.03-4.25-3.03s9.71-3.64,8.7-6.27c-1.01-2.63-5.86-4.65-5.86-4.65
					s9.3,3.24,14.36,0.2c5.06-3.03,0.61-15.97,0.61-15.97L207.07,141.06z" />
								<g>
									<path class="st1" d="M247.71,122.05c0,0,1.62,5.66-1.21,10.51c-2.83,4.85-8.9,13.14-9.71,22.45
						c-0.81,9.3,1.82,20.83,3.44,24.67c1.62,3.84-2.02,4.65-4.65,4.45c-2.63-0.2-3.64-1.82-3.64-5.46s-0.81-10.11-0.81-10.11
						s-10.31-1.01-15.17-3.84c-4.85-2.83-11.93-12.54-8.9-23.66c3.03-11.12,11.12-14.36,15.97-16.38
						c4.85-2.02,11.53-1.82,16.18-2.83C243.87,120.83,247.51,119.62,247.71,122.05z" />
									<path class="st5"
										d="M232.95,150.36l-1.82,7.28c0,0-3.84-1.82-4.45-5.06c-0.61-3.24,4.04-4.85,7.28-2.02" />
								</g>
							</g>
						</g>
						<line class="st6" x1="292" y1="161.88" x2="289.37" y2="162.09" />
						<g>
							<polygon class="st9" points="273.08,156.04 340.48,165.47 331.32,115.61 271,144.65 			" />

							<rect x="261.94" y="145.48"
								transform="matrix(0.9837 -0.1798 0.1798 0.9837 -22.8457 50.4905)" class="st9"
								width="10.18" height="11.52" />
						</g>
						<g>
							<path class="st1" d="M284.31,200.3c0,0-15.57-11.73-20.22-14.15c-4.59-2.39-7.99-0.46-9.64-0.41c-2.55,3.66-8.5,9.85-19.48,9.71
				c-13.3-0.17-17.01-7.57-17.81-9.73c-2.01,0.06-4.09,0.18-6.05,0.63c-6.27,1.42-22.04,17.19-22.04,17.19l10.72,18.6l7.68-6.07
				c0,0,2.63,4.65,4.25,9.71c1.62,5.06,1.01,32.56,1.01,32.56l60.66-1.42l-6.27-38.62l8.9,4.65L284.31,200.3z" />
							<g>

								<ellipse transform="matrix(0.9728 -0.2316 0.2316 0.9728 -44.5329 61.9576)" class="st10"
									cx="241.61" cy="220.65" rx="18.66" ry="18.66" />
								<g>
									<polygon class="st11" points="253.01,212.2 230.89,212.2 241.79,222.71 					" />
									<polygon class="st11" points="246.22,220.39 253.92,227.19 253.92,213.17 					" />
									<polygon class="st11" points="237.44,220.37 229.64,212.85 229.64,226.87 					" />
									<polygon class="st11"
										points="245.24,221.3 241.78,224.55 238.41,221.3 230.29,228.07 252.9,228.07 					" />
								</g>
							</g>
						</g>
					</g>
				</g>
				<g id="Floor">
					<line class="st3" x1="30.31" y1="463.81" x2="470.63" y2="463.81" />
				</g>
			</svg>
		</div>
		<p class="mt-2 text-md">
			An email has been sent to you
			<!-- <span class="text-customTeal"*ngIf="user">{{user.Email}}</span> -->
			, with a link to verify your account. If you have not recieved the email after few minutes , please check
			your spam folder.
		</p>
		<div class="flex justify-between space-x-10 mt-5">
			<button (click)="GoToLogin()" class='px-4 py-2 rounded-lg bg-customTeal text-white'>
				<!-- <i class="material-icons float-left">how_to_reg</i> -->
				Go To Login
			</button>

			<button (click)="resendEmail()" class='px-4 py-2 rounded-lg border-customTeal border-2 text-customTeal'>
				Resend Email
			</button>
		</div>
		<!-- <h6 class="mt-4 text-lg text-red-500 font-bold">Didn't get the email?</h6>
    <p>
      Check your spam folder to make sure it didn't end up there.
    </p> -->
	</div>

	<br /> <br />


</span>

<span *ngIf="user && user.verified && id">
	<div class="w-full mt-10" *ngIf="id != ''">
		<!-- <img [routerLink]="['/']" *ngIf="!appLogo" src="../../../../assets/img/Going Mobile - 3.webp"
			class="block w-32 flex justify-center cursor-pointer" alt="Logo "> -->
		<img loading="lazy" [routerLink]="['/']" *ngIf="appLogo" [src]="appLogo" class="block w-32 flex justify-center cursor-pointer "
			alt="Logo ">
	</div>

	<div class="flex flex-col justify-center items-center">
		<div class="w-96">
			<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 5261.13 5424">
				<rect x="4479.06" y="146.17" width="333.77" height="333.77" style="fill:#ffc200" />
				<polygon points="418.48 197.38 155.72 652.48 681.23 652.48 418.48 197.38" style="fill:#ffc200" />
				<circle cx="5287.74" cy="5378.51" r="184" transform="translate(-2623.87 5026.33) rotate(-45)"
					style="fill:#ffc200" />
				<rect x="367.48" y="3663.75" width="158.27" height="60.02"
					transform="translate(-2850.51 1109.68) rotate(-45)" style="fill:#333" />
				<rect x="573.9" y="3457.33" width="158.27" height="60.02"
					transform="translate(-2644.09 1195.19) rotate(-45)" style="fill:#333" />
				<rect x="623.03" y="3614.63" width="60.02" height="158.27"
					transform="translate(-2790.06 1255.66) rotate(-45)" style="fill:#333" />
				<rect x="416.61" y="3408.2" width="60.02" height="158.27"
					transform="translate(-2704.56 1049.23) rotate(-45)" style="fill:#333" />
				<rect x="5267.82" y="2790.82" width="158.27" height="60.02"
					transform="translate(-797.96 4319.14) rotate(-45)" style="fill:#333" />
				<rect x="5474.25" y="2584.4" width="158.27" height="60.02"
					transform="translate(-591.53 4404.64) rotate(-45)" style="fill:#333" />
				<rect x="5523.38" y="2741.7" width="60.02" height="158.27"
					transform="translate(-737.52 4465.04) rotate(-45)" style="fill:#333" />
				<rect x="5316.95" y="2535.27" width="60.02" height="158.27"
					transform="translate(-652.01 4258.62) rotate(-45)" style="fill:#333" />
				<rect x="2613.06" y="562.2" width="158.27" height="60.02"
					transform="translate(0.34 1789.13) rotate(-45)" style="fill:#333" />
				<rect x="2819.49" y="355.78" width="158.27" height="60.02"
					transform="translate(206.76 1874.63) rotate(-45)" style="fill:#333" />
				<rect x="2868.62" y="513.07" width="60.02" height="158.27"
					transform="translate(60.79 1935.09) rotate(-45)" style="fill:#333" />
				<rect x="2662.19" y="306.65" width="60.02" height="158.28"
					transform="translate(146.31 1728.67) rotate(-45)" style="fill:#333" />
				<rect x="1192.66" y="5032.52" width="60.02" height="391.48" style="fill:#333" />
				<rect x="1026.93" y="5198.25" width="391.48" height="60.02" style="fill:#333" />
				<path
					d="M5300.36,681.78H4966.6a30,30,0,0,1-30-30V318a30,30,0,0,1,30-30h333.76a30,30,0,0,1,30,30V651.77A30,30,0,0,1,5300.36,681.78Zm-303.75-60h273.74V348H4996.61Z"
					transform="translate(-369.44 -288)" style="fill:#333" />
				<path
					d="M1168.77,854.33H643.26a30,30,0,0,1-26-45L880,354.21a30,30,0,0,1,52,0l262.76,455.1a30,30,0,0,1-26,45Zm-473.53-60h421.55L906,429.23Z"
					transform="translate(-369.44 -288)" style="fill:#333" />
				<path
					d="M5405.85,5476.35c-118,0-214-96-214-214s96-214,214-214,214,96,214,214S5523.85,5476.35,5405.85,5476.35Zm0-368c-84.92,0-154,69.08-154,154s69.08,154,154,154,154-69.08,154-154S5490.76,5108.35,5405.85,5108.35Z"
					transform="translate(-369.44 -288)" style="fill:#333" />
				<polygon points="4270.98 2913.08 3375.57 3412.35 4270.98 4101.18 4270.98 2913.08"
					style="fill:#ffc200" />
				<path d="M4018.49,2252.55V3477.86l621.93-346.77v-38a332.88,332.88,0,0,0-81.53-218.23Z"
					transform="translate(-369.44 -288)" style="fill:#ffc200" />
				<polygon points="843.47 2913.08 843.47 4101.18 1738.89 3412.35 843.47 2913.08" style="fill:#ffc200" />
				<path d="M1834.83,2252.55l-540.39,622.33a332.83,332.83,0,0,0-81.53,218.23v38l621.92,346.77Z"
					transform="translate(-369.44 -288)" style="fill:#ffc200" />
				<path
					d="M1212.91,4466.31v152.41c0,77.91,63.39,141.3,141.3,141.3H4499.12c77.91,0,141.3-63.39,141.3-141.3V4466.31L2926.66,3148Z"
					transform="translate(-369.44 -288)" style="fill:#ffc200" />
				<path
					d="M1896,3512l265.1,147.82,765.59-588.95,765.59,588.95L3957.36,3512V1605.88c0-88.42-71.93-160.35-160.35-160.35H2056.32c-88.42,0-160.35,71.93-160.35,160.35Zm394.39-1246.52L2536.81,2019l42.44-42.44,42.44,42.44L2785,2182.27l446.66-446.66,42.44-42.44,42.44,42.44L3563,1982.06l42.44,42.44L3563,2066.94,2827.43,2802.5,2785,2844.94l-42.44-42.44-452.19-452.19-42.44-42.44Z"
					transform="translate(-369.44 -288)" style="fill:#ffc200" />
				<path
					d="M4701,2738.72l-540.4-622.32-46.43-53.48V1509.07c0-121.51-98.85-220.37-220.36-220.37H2153.12c-121.51,0-220.37,98.86-220.37,220.37v553.85l-46.43,53.48-540.39,622.32a392.9,392.9,0,0,0-96.23,257.59v73.23h0V4521.91c0,111,90.31,201.32,201.31,201.32H4595.92c111,0,201.32-90.31,201.32-201.32V2996.31A392.91,392.91,0,0,0,4701,2738.72ZM1992.77,1509.07c0-88.42,71.93-160.35,160.35-160.35h1740.7c88.41,0,160.34,71.93,160.34,160.35V3415.15L3789.05,3563l-765.58-589-765.59,589-265.11-147.82Zm212.36,2094.48-895.42,688.83V3104.28Zm-895.42-607.24a332.91,332.91,0,0,1,81.53-218.24l540.4-622.32V3381.06l-621.93-346.77Zm3427.51,1525.6c0,77.92-63.39,141.3-141.3,141.3H1451c-77.91,0-141.3-63.38-141.3-141.3V4369.5L3023.47,3051.14,4737.22,4369.5Zm0-229.53-895.41-688.83,895.41-499.27Zm0-1258.09L4115.3,3381.06V2155.75l540.39,622.32a332.86,332.86,0,0,1,81.53,218.24Z"
					transform="translate(-369.44 -288)" style="fill:#333" />
				<path
					d="M2839.35,2705.69l42.44,42.44,42.44-42.44,735.54-735.55,42.44-42.44-42.44-42.44-246.45-246.45-42.44-42.44-42.44,42.44-446.65,446.66-163.3-163.29-42.44-42.44-42.44,42.44-246.45,246.45-42.44,42.44,42.44,42.44Zm-163.3-741.07,205.74,205.73,489.09-489.1,246.45,246.45-735.54,735.55L2429.6,2211.07Z"
					transform="translate(-369.44 -288)" style="fill:#333" />
			</svg>
		</div>
		<p class="mt-10 text-4xl font-bold text-customTeal">Email Verified </p>
		<!-- <p class="mt-2 text-md">Your Email has been Verified.</p> -->
		<p class="mt-2 text-md">
			Your email has been successfully verified. Thank you for confirming your account.
			You can now sign in with your new account.
		</p>
		<hr />
		<p class="mt-2 text-md">
			You can now login to your account using the email and password you provided during registration.
		</p>
	</div>
	<div class="flex justify-center mt-5">
		<button (click)="GoToLogin()" class='px-4 py-2 rounded-lg bg-customTeal text-white'> Go To Login
		</button>
	</div>
	<br /> <br />
</span>




<!-- <div id="my-modal-delete" *ngIf="beforeDeleteModal" class="modal"
  [ngClass]="{'modal-open': beforeDeleteModal, 'modal-action': !beforeDeleteModal }">
  <div class="modal-box">
    <div class="modal-content">
      <h3 class="font-bold text-lg text-center mb-2">Your Account has been deleted</h3>
      <hr />
      <p class="text-center mt-3"> Have a great day.</p>
    </div>
  </div>
</div> -->




<!-- </div> -->

<!-- <div *ngIf="forgotpassword">
    <div class="flex justify-between w-full">
      <img [routerLink]="['/']" *ngIf="!appLogo" src="../../../../assets/img/Going Mobile - 3.webp"
        class="block w-32 justify-center cursor-pointer" alt="Logo ">
      <img [routerLink]="['/']" *ngIf="appLogo" [src]="appLogo" class="block w-32 justify-center cursor-pointer "
        alt="Logo ">
    </div>
  <div class="flex flex-col justify-center items-center">
    <p class="mt-10 text-4xl font-bold text-customTeal"> Thank you </p>
    <p class="mt-2 text-md">A link will be sent to your email account.</p>
    <p class="mt-2 text-md">
      Please click on the link and Reset your password.
    </p>
    <h6 class="mt-4 text-lg text-red-500 font-bold">Didn't get the email?</h6>
    <p>
      Check your spam folder to make sure it didn't end up there.
    </p>
    </div>
    <div class="flex justify-center mt-5">
        <button (click)="GoToLogin()"
          class='px-4 py-2 rounded-lg bg-customTeal text-white'>
          <i class="material-icons float-left">how_to_reg</i> Go To Login Page
        </button>
    </div>
    <br /> <br />
    </div> -->
<!-- toast -->
<app-alert class="fixed top-0 flex justify-end w-full" [toastType]="toastType" [toastMessage]="toastMessage"
[ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>
	