import { AuthService } from 'src/app/services/firebase/auth.service';
import { Location } from '@angular/common';
import { Component, OnInit , Output, EventEmitter ,Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-cart-item',
    templateUrl: './cart-item.component.html',
    styleUrls: ['./cart-item.component.css']
})
export class CartItemComponent implements OnInit {

    @Input() index
    @Input() item
    @Output() onItemUpdate: EventEmitter<any> = new EventEmitter();
    @Output() onItemDelete: EventEmitter<any> = new EventEmitter();

    constructor(private route: ActivatedRoute, private location: Location){

}
ngOnInit(): void {
    console.log(this.item,this.index)

  }

  
//   removeProduct(){
//     this.onItemUpdate.emit({index:this.index,value:false})
//   }

  updateProduct(value){
    this.onItemUpdate.emit({index:this.index,value})

  }

  deleteProduct(){
    this.onItemDelete.emit(this.index)
  }
}
