<!-- <div class="w-1/2 flex justify-center content-center w-full h-44 max-w-x bg-white rounded-lg border-2 gap-x-3">
    <label class="custom-file-upload  self-center flex flex-col">
    <i class="fa fa-plus-circle text-customTeal text-3xl text-center self-center"></i>
    <input type="file" type="text" type="file" accept="image/*"
        (change)="handleFileInput($event.target.files)" />
    <span class="block font-bold pt-1">Upload Images</span>
    </label>
</div> -->

<!-- <label class="py-2 px-6 custom-file-upload  self-center flex flex-col text-customTeal border-2 border-customTeal rounded-lg">
    <input type="file" type="text" type="file" accept="image/*"
    (change)="handleFileInput($event.target.files)" />
    <span class="block font-bold pt-1">Upload Image</span>
</label> -->

<!-- <label class="py-2 px-6 custom-file-upload  self-center flex flex-col text-customTeal border-2 border-customTeal rounded-lg">
    <input type="file" type="text" type="file" accept="image/*"
    (change)="handleFileInput($event.target.files)" />
    <span class="block font-bold pt-1">Upload Image</span>
</label> -->

<button
  type="button"
  (click)="handleMobilePhoto()"
  class="py-2 px-8 bg-customTeal rounded-sm"
  *ngIf="isMobile"
>
  <div class="w-full flex items-center space-x-2 justify-center">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      class="size-5 stroke-white"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
      />
    </svg>
    <p class="font-bold text-center w-full text-white">upload an image</p>
  </div>
</button>

<div class="flex space-x-4" *ngIf="!isMobile">
  <div
    class="upload-container"
    class="py-2 px-8 modal-button flex flex-col gap-2 md:w-[500px] justify-center h-40 text-black border-2 rounded-lg w-full border-dashed"
    [ngClass]="{ 'border-customTeal': shake, 'border-gray-300 ': !shake }"
  >
    <!-- 
        (drop)="onDrop($event)"
      (dragover)="onDragOver($event)"
      (dragleave)="onDragLeave($event)"
   -->
    <div
      class="w-full flex justify-center"
      [ngClass]="{ 'animate-bounce': shake, 'animate-none': !shake }"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="size-8"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
        />
      </svg>
    </div>

    <input
      type="file"
      multiple
      (change)="fileChangeEvent($event)"
      id="image-uploader"
    />
    <!-- (click)="fileChangeEvent($event)" -->
    <label for="image-uploader" class="font-bold text-center w-full">
      Drop and image here or
      <span class="text-customTeal">Browse</span></label
    >
  </div>

  <div class="preview">
    <div *ngFor="let file of files; let i = index">
      <img
        class="w-72 h-56 object-contain"
        [src]="file | filePreview | async"
      />
      <button
        class="absolute top-1 right-1 flex space-x-2 items-center"
        (click)="removeFile(i)"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="size-4"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
          />
        </svg>
        <span class="font-bold text-base">Remove</span>
      </button>
    </div>
  </div>
</div>

<div class="flex justify-end my-1 w-full space-x-2">
  <app-button
    data-te-toggle="tooltip"
    data-te-placement="bottom"
    title="Tooltip on top"
    class="justify-end items-end"
    [customConfig]="{
      text: 'AI Image Generator',
      type: 'Button',
      buttonType: 'secondaryButton'
    }"
    (click)="openImageGenerateModal()"
  ></app-button>
  <!-- <div
    class="tooltip tooltip-bottom my-2 text-white cursor-pointer"
    data-tip="ChatGPT is an invaluable tool for content creators seeking inspiration and assistance in generating high-quality content. With its vast knowledge and language capabilities, ChatGPT acts as a creative partner, helping to overcome writer's block and enhancing the content creation process. By simply engaging in a conversation, you can tap into the model's deep understanding of various topics, enabling you to brainstorm ideas, refine concepts, and explore new perspectives. Whether you need assistance with generating engaging headlines, crafting compelling narratives, or conducting research on a specific subject, ChatGPT provides valuable insights and suggestions to elevate your content to new heights. Its ability to adapt to different writing styles and tones ensures that the final output remains authentic and aligned with your vision. ChatGPT is a powerful ally, transforming the content creation experience into a collaborative and efficient journey."
  >
    <span class="font-bold text-lg text-customTeal">&#63;</span>
  </div> -->
</div>

<!-- Dalle Image Generator Modal -->
<app-image-generator
  *ngIf="imageModalOpen"
  (closeImageGenerateModal)="closeImageGenerateModal()"
  (onImageSelect)="onImageSelect()"
></app-image-generator>
<!-- Crop Image Modal -->
<app-crop-image
  class="w-full h-full flex justify-center"
  *ngIf="cropModalOpen"
  [theImage]="imageChangedEvent"
  [useBase64]="useBase64"
  [aspectRatio]="4 / 3"
  (returnCroppedImage)="processCroppedImage($event)"
  (closeCropModal)="closeCroppingModal()"
></app-crop-image>
