import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { HelperService } from 'src/app/services/helper/helper';
import { selectAppSettings } from 'src/app/state/app.selectors';

import { FormBuilder, FormArray, FormGroup, Validators, FormControl, NgModel, ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';
@Component({
  selector: 'app-rename-section',
  templateUrl: './rename-section.component.html',
  styleUrls: ['./rename-section.component.css']
})
export class RenameSectionComponent implements OnInit {
  renameModal = false
  pageSections: any;
  @Input() section;
  @Input() calledFromComponent;
  @Output() sectionRenamed = new EventEmitter<string>();
  
  appSettings$: Subscription;
  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  renameForm: FormGroup;
  constructor(  private settingsService: AppSettingsService,
    private helperService: HelperService,
    private store: Store,
    private fb: FormBuilder,) { 
    this.appSettings$ = this.store.select(selectAppSettings).subscribe((settings) => {
      this.pageSections = this.helperService.cloneDeep(settings);
    });
  }
  get name() {
    return this.renameForm.get('name');
  }
  ngOnInit(): void {
    this.renameForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(30)]],
    });
    if (this.pageSections.appSections) {
      
      this.pageSections.appSections.forEach(element => {
        if(element.originalName == this.section){
          // this.name = element.name;
          this.renameForm.patchValue({
            name: element.name
          })
        }      
      });
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    // this.name = this.section;
    this.renameForm.patchValue({
      name: this.section
    })

  }
  saveChanges(){
    this.pageSections.appSections.forEach(element => {
      if(element.originalName == this.section){
        element.name = this.name.value;
      }      
    });
    

    this.settingsService.updateAppSettings(this.pageSections).then(x => {
      if(this.calledFromComponent=='onboarding'){
        this.sectionRenamed.emit(this.section)
      }
      else{
        this.toast({ html: 'Changes Saved Successfully.', classes: 'green' });
      }
    });
  }
  toast(obj) {
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green';
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
    }, 2000);
  }
}
