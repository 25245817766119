<div class="flex flex-wrap gap-4 mx-auto py-12 w-full justify-center" >
          
    <div
    class="w-full md:mx-4  md:mb-10 flex  md:min-h-max  border bg-white md:w-[341px] md:h-[300px]  drop-shadow-md rounded-lg hover:scale-100 hover:ease-in hover:duration-300"
    *ngFor="let service of services | slice:0:itemNumber; " 
      [ngStyle]="{'background-color': service.backgroundColor}"
    >
    <div class=" flex flex-col w-full   px-4  py-4  md:px-6   md:h-min border border-gray-200    rounded-lg hover:scale-100 hover:ease-in hover:duration-300">
            
      <ng-container>
        <div [routerLink]="['/view-service', service.id]" class="w-full ">
          <div *ngIf="service.imgLink && !service.videoLink" [routerLink]="['/admin/service', service.id]"  class="w-full md:h-48 rounded-xl cursor-pointer">
    
            <div class="flex justify-center md:h-48 " *ngIf="service.imgLink" >
              <img [src]="service.imgLink" class="md:w-56 md:h-full w-full rounded-lg md:object-contain" >
            </div>
          </div>
          <div>
            <div *ngIf="service.videoLink && service.imgLink" (click)="redirectToservice(service.id)"  class="w-full h-40 md:h-48 rounded-xl cursor-pointer">
              
              <video controls *ngIf="service.videoLink != ''" [src]="service.videoLink" class="object-cover" alt="Logo" [poster]="service.imgLink"></video>           
            </div>
            <div *ngIf="service.videoLink && !service.imgLink" (click)="redirectToservice(service.id)"  class="w-full h-40 md:h-48 rounded-xl cursor-pointer">
              <video controls *ngIf="service.videoLink != ''" [src]="service.videoLink" class="object-cover" alt="Logo"></video>           
            </div>
          </div>
          <div class="break-words">
            <h6 [routerLink]="['/admin/service', service.id]"  class=" cursor-pointer max-w-sm text-xl font-bold py-2 text-center" [ngStyle]="{'color':service.titleColor}">{{truncateHTML(getInnerText(service.serviceName),40)}}</h6>
          </div>

        </div>
      </ng-container>
      <div *ngIf="service.price">
        <div *ngIf=" service.price!='dontInclude'">
          <div *ngIf="service.price > 0" href="" class="justify-center text-customTeal  ">
            <ng-container *ngIf="currency"> {{service.price | currency: currency:'symbol-narrow'}}</ng-container>
            <ng-container *ngIf="!currency">{{service.price | currency: 'USD':'symbol-narrow'}}</ng-container>
          </div>
        </div>
      </div>
      <div *ngIf="!service.allServices || service.allServices.length==0" [routerLink]="['/view-service', service.id]"> 

        
      </div> 
    
    </div>

  </div>

  </div>
