<div  class="min-h-screen">
<ng-container *ngIf="settings && settings.isBusinessApp">
  <ng-contianer *ngIf="currentUser && (currentUser.role =='Admin' || currentUser.role =='Employee' || (permPES && (permPES.addCategoryProducts || permPES.editCategoryProducts)))">
    <div class="mr-8 flex justify-center md:justify-end">

      <button (click)="addCategory();" *ngIf="currentUser && (currentUser.role =='Admin' ||(permPES && permPES.addCategoryProducts))" class="py-2 px-6 bg-customTeal rounded-md text-white float-right">
        <i class="material-icons text-white float-left mr-3">category</i>
        Add {{productsName}} Category</button>
    </div>

    <div class="flex flex-col gap-y-8 md:gap-x-7 px-4 md:px-8 py-4" *ngIf="productCatagory">
      <h1 class="text-xl font-bold text-center text-black ">
        Select {{productsName}} Category
      </h1>
      <div class="flex flex-wrap gap-y-20 md:gap-x-7">
        <div *ngFor="let catagory of productCatagory | paginate: { itemsPerPage: 6, currentPage: p }">

          <div
            class="flex flex-wrap flex-col bg-white rounded-xl w-72 md:w-80 h-max drop-shadow-md">

            <div class="w-full h-40 md:h-48 rounded-xl " *ngIf="catagory.imgUrl && catagory.imgUrl != ''"
              [style]="'background:linear-gradient(rgba(217, 217, 217, 0), rgba(0, 0, 0, 0.8)),url('+ catagory.imgUrl +'); background-size: contain; background-repeat: no-repeat; background-position: center;'">

            </div>
            <div class="w-full h-40 md:h-48 rounded-xl " *ngIf="!catagory.imgUrl || catagory.imgUrl ==''"
              style="background:linear-gradient(rgba(217, 217, 217, 0), rgba(0, 0, 0, 0.8)); background-size: contain; background-repeat: no-repeat; background-position: center;">
              <div class="text-center text-gray-500 font-bold text-2xl pt-16">
                {{truncateHTML(getInnerText(catagory.name), 40)}}
              </div>
            </div>
            <!-- <div class="absolute top-0 right-0 items-center  w-24 md:w-16"> -->
            <!-- <div (click)="selectCategory(catagory)"
              class="mt-2 mr-2 absolute top-0 right-0 items-center  w-12 p-2 rounded-md bg-white border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
              <i class="fa fa-edit text-md"></i> Edit
            </div> -->
            <div (click)="selectCategory(catagory)" *ngIf="isAdmin || (permPES && permPES.editCategoryProducts)"
              class="mt-2 mr-2 absolute top-1 border-sm rounded-lg right-1 z-50 bg-white py-2 px-3 border-2 border-veryLightGray hover:border-4  hover:border-customTeal  hover:cursor-pointer shadow-md">
              <i class="material-icons float-left mr-2 text-black text-lg">edit</i>Edit
            </div>

            <!-- <h1
              class="absolute flex bottom-0 mb-[30%] md:mb-[40%] h-max content-end text-white font-bold px-4  text-md md:text-2xl ">
              {{catagory.name | titlecase}}</h1> -->
              <!-- <span class="absolute flex bottom-0 mb-[30%] md:mb-[40%] h-max content-end px-4" [innerHTML]="catagory.name | noSanitize"></span> -->

              <span class="my-3 ml-2" [innerHTML]="catagory.name | noSanitize"></span>

            <div class="p-4 space-y-4 flex justify-end  cursor-pointer">
              <div (click)="navigate(catagory)"
                class="flex mt-4 mb-2 text-customTeal font-bold mr-2">
                View Products <i class="material-icons">navigate_next</i></div>
            </div>

          </div>

        </div>
        <div class="w-full text-center mt-5" *ngIf="productCatagory && productCatagory.length>0">
          <pagination-controls (pageChange)="p = $event"></pagination-controls>
        </div>
      </div>

    </div>

    <div *ngIf="!productCatagory" class="mg-5">
      <div class="flex items-center justify-center">
        <img loading="lazy" data-src="../../../../assets/icons/Illustrations/Category-Illustration.png"
        class="block w-64 items-center justify-center"> 
      </div>
      <br/>
      <ng-container *ngIf="!isAdmin">
        <div class="text-customTeal text-lg text-center font-bold">Sorry No Categories Found</div>
        <div class="text-gray-400 text-sm text-center font-bold">{{servicesName}}  will appeare here once added!</div>
      </ng-container>
      <ng-container *ngIf="isAdmin">
        <div class="text-customTeal text-lg text-center font-bold">Create Your First Category Now</div>
        <div class="text-gray-400 text-sm text-center font-bold">Create a category for your {{productsName}}</div>
        <br/>
        <div class="flex justify-center">

          <button (click)="addCategory();" *ngIf="currentUser && (currentUser.role =='Admin' || permPES.addCategoryProducts)" class="py-2 px-6 bg-customTeal rounded-md text-white float-right">
            <i class="material-icons text-white float-left mr-3">category</i>
            Add Category</button>
        </div>
        <div></div>
      </ng-container>
    </div>



    <div id="editModal" *ngIf="editModalOpen" class="modal"
      [ngClass]="{'modal-open': editModalOpen, 'modal-action': !editModalOpen }">
      <div class="modal-box max-w-2xl">

        <form class="" [formGroup]="productCategoryForm" (ngSubmit)="manageCategory(productCategoryForm.value)">
          <div class="">
            <h3 *ngIf="selectedModel" class="font-bold text-lg">Edit Product Category</h3>
            <h3 *ngIf="!selectedModel" class="font-bold text-lg">Create Product Category</h3>
            <div class="text-gray-500 text-sm">A Product Category represents a group of similar products that share
              related
              characteristics</div>

            <br />
            <div class="space-y-4">
              <div class="">
                <label class="label py-2">
                  <span class="label-text text-gray-500 text-base font-bold leading-5">Name</span>
                </label>
                <!-- <input id="name" type="text" formControlName="name" required placeholder="Category name"
                  class="focus:invalid:border-red-500 py-3 px-3 bg-gray-100 input input-bordered w-full"
                  autocomplete="off" /> -->
                  <app-editor [control]="name" class="bg-gray-100" [inputType]="'title'" [fromComponent]="catagoryName"></app-editor>

                <div *ngIf="(name.dirty || name.touched) && name.invalid">
                  <small *ngIf="name.errors.required" class="text-red-600">
                    Product Category Name is required.
                  </small>
                </div>
              </div>

              <div class="space-y-1">

                <label class="label">
                  <span class="label-text text-gray-500 text-base font-bold leading-5">Image</span>
                </label>
                <div class="w-full max-w-x flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
                  <div *ngIf="!isMobile"
                    class="w-1/2 flex justify-center content-center w-full h-44 max-w-x bg-white rounded-lg border-2 gap-x-3">

                    <label class="custom-file-upload  self-center flex flex-col">
                      <i class="fa fa-plus-circle text-customTeal text-3xl text-center self-center"></i>
                      <input type="file" type="file" accept="image/*" (change)="fileChangeEvent($event)">
                      <span class="block font-bold pt-1">Upload Image</span>
                    </label>
                  </div>

                  <div class="flex p-3" *ngIf="isMobile">
                    <app-image-uploader class="justify-start items-start"  (onImageUpload)=onImageUpload($event) [customConfig]="{'upload':false}"></app-image-uploader>

                    <!-- <button type="button"
                      class="py-2 px-8 modal-button gap-2 text-black border-2 border-customTeal rounded-lg w-full"
                      type="button" (click)="handleMobilePhotoInput()">
                      Upload Image
                    </button> -->
                  </div>

                  <div class="relative" *ngIf="base64Image">
                    <img loading="lazy" [src]="base64Image" class="object-cover  w-32 h-32 rounded-md cover" alt="Image">
                    <button (click)="removeFile()" type="button"
                      class="absolute top-0 translate-x-24 -translate-y-4 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                      <i class="fa fa-trash text-md"></i>
                    </button>
                  </div>

                  <div class="relative"
                    *ngIf="!base64Image && (selectedModel && selectedModel.imgUrl && selectedModel.imgUrl !='')">
                    <img loading="lazy" [src]="selectedModel.imgUrl" class="object-cover  w-32 h-32 rounded-md cover" alt="Image">
                    <button (click)="remove()" type="button"
                      class="absolute top-0 translate-x-24 -translate-y-4 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                      <i class="fa fa-trash text-md"></i>
                    </button>
                  </div>


                  <p class="text-red-700 py-2" *ngIf="imgErrorMsg">
                    <strong><small><i class="material-icons">error_outline</i> {{imgErrorMsg}}</small></strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-action max-w-4xl flex justify-between items-center">
           <div>
            <a (click)="selectedModal ? cancelCreateModalOpen=true : cancelEditModalOpen=true" class="modal-close font-bold text-customTeal btn-flat cursor-pointer">Cancel</a>
           </div>
            
          <div class="space-x-4">
            <button *ngIf="selectedModel" type="button" class="modal-close  bg-red-400 py-2.5 rounded-md px-10 text-white btn-flat"
            (click)="openDeleteModal()">
            Delete
          </button>
          <button type="button" (click)="selectedModal?createConfirmationModalOpen=true:editConfirmationModalOpen=true" [disabled]="!productCategoryForm.valid"
            class=" bg-customTeal text-white  rounded-md border-0 py-2.5 px-10" style="margin-right: 10px;">
            <span *ngIf="selectedModel">Update</span>
            <span *ngIf="!selectedModel"> Save {{productsName}} Category</span>
          </button>
          </div>
            
          </div>
        </form>

      </div>

    </div>
    <div id="deleteModal" *ngIf="deleteModalOpen" class="modal"
      [ngClass]="{'modal-open': deleteModalOpen, 'modal-action': !deleteModalOpen }">
      <div class="modal-box">
        <h3 class="font-bold text-lg">Delete Category</h3>

        <p class="py-2">Are you sure you want to delete category?</p>

        <div class="modal-action">
          <button *ngIf="selectedModel" type="button" class=" bg-red-400 py-2.5 rounded-md px-10 text-white"
            (click)="deleteCategory(selectedModel)">
            Delete
          </button>
          <a (click)="closeDeleteModal()" class="modal-close bg-customTeal text-white  rounded-md border-0 py-2.5 px-10 ">Cancel</a>
        </div>
      </div>
    </div>
    <app-alert class="fixed top-0 flex justify-end w-full" [toastType]="toastType" [toastMessage]="toastMessage"
      [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>
  </ng-contianer>

<div *ngIf="(!currentUser || (currentUser && currentUser.role =='User'))">
  <ng-contianer >
  <!-- <ng-contianer *ngIf="!currentUser && (permission && (!permission.addCategoryProducts && !permission.editCategoryProducts)) || !permission"> -->

    <div class="flex flex-col space-y-6 md:space-y-12 m-8 mt-8" *ngIf="productCatagory">
      <h1 class="text-xl font-bold text-center px-12 text-black md:text-2xl py-4">
        Select Product Category
      </h1>
      <div class="flex flex-wrap gap-x-5 gap-y-20 md:gap-x-10 md:gap-y-20">

        <div *ngFor="let catagory of productCatagory | paginate: { itemsPerPage: 6, currentPage: p }"
          (click)="navigate(catagory)" class="">

          <div
            class="flex flex-wrap flex-col bg-white rounded-xl w-72 md:w-80 h-max drop-shadow-md hover:border-2 hover:border-customTeal hover:cursor-pointer">

            <div class="w-full h-40 md:h-48 rounded-xl " *ngIf="catagory.imgUrl && catagory.imgUrl != ''"
              [style]="'background:linear-gradient(rgba(217, 217, 217, 0), rgba(0, 0, 0, 0.8)),url('+ catagory.imgUrl +'); background-size: contain; background-repeat: no-repeat; background-position: center;'">

            </div>            
            <div class="w-full h-40 md:h-48 rounded-xl " *ngIf="!catagory.imgUrl || catagory.imgUrl ==''"
            style="background:linear-gradient(rgba(217, 217, 217, 0), rgba(0, 0, 0, 0.8)); background-size: contain; background-repeat: no-repeat; background-position: center;">
            <div class="text-center text-gray-500 font-bold text-2xl pt-16">
              {{truncateHTML(getInnerText(catagory.name), 40)}}
            </div>
          </div>

            <!-- <h1
              class="absolute flex bottom-0 mb-[30%] md:mb-[43%] h-max content-end text-white font-bold px-4  text-md md:text-2xl ">
              {{catagory.name | titlecase}}</h1> -->
            <!-- <span   class="absolute flex bottom-0 mb-[30%] md:mb-[43%] h-max content-end px-4 " [innerHTML]="catagory.name | noSanitize"></span> -->
            
            <span class="my-3 ml-2" [innerHTML]="catagory.name | noSanitize"></span>


          </div>



        </div>
      </div>
      <!-- <div class="w-full text-center mt-5" *ngIf="productCatagory && productCatagory.length>0">
        <pagination-controls (pageChange)="p = $event"></pagination-controls>
      </div> -->
    </div>

    <div *ngIf="!productCatagory" class="mg-5">
      <div class="flex items-center justify-center">
        <img  loading="lazy" data-src="../../../../assets/icons/Illustrations/Category-Illustration.png"
        class="block w-64 items-center justify-center"> 
      </div>
      <br/>
      <div class="text-customTeal text-lg text-center font-bold">Sorry No Categories Found</div>
      <div class="text-gray-400 text-sm text-center font-bold">{{servicesName}}  will appeare here once added!</div>
    </div>

  </ng-contianer>
</div>

</ng-container>


</div>


<!-- create Modal -->
<app-create-confirmation *ngIf="createConfirmationModalOpen" (cancelClicked)=closeCreateConfirmationModal() (saveBtnClicked)=manageCategory(productCategoryForm.value) [createdFromComponent]=productsName></app-create-confirmation>

<!-- Edit Modal -->
<app-edit-confirmation *ngIf="editConfirmationModalOpen" (cancelClicked)=closeEditConfirmationModal() (saveBtnClicked)=manageCategory(productCategoryForm.value) [editedFromComponent]=productsName></app-edit-confirmation>

<!-- Cancel Modal -->
<app-cancel-confirmation *ngIf="cancelCreateModalOpen" (cancelClicked)=closeAllModals() (continueClicked)=closeCancelModal() canceledFromAction="Creating" [canceledFromComponent]=productsName></app-cancel-confirmation>
<app-cancel-confirmation *ngIf="cancelEditModalOpen" (cancelClicked)=closeAllModals() (continueClicked)=closeCancelModal() canceledFromAction="Editing" [canceledFromComponent]=productsName></app-cancel-confirmation>

<!-- Crop Image Modal -->
<app-crop-image *ngIf="cropModalOpen" [theImage]="imageChangedEvent" [aspectRatio]="4 / 3" (returnCroppedImage)="processCroppedImage($event)" (closeCropModal)=closeCroppingModal()></app-crop-image>