import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation, Injectable, ChangeDetectorRef, TemplateRef } from '@angular/core';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { UserService } from 'src/app/services/firebase/user.service';
import { Location, formatDate} from '@angular/common';
import { ScheduleService } from 'src/app/services/firebase/employee-schedule.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppointmentService } from 'src/app/services/firebase/employee-appointment.service';
import { EmployeeServicesService } from 'src/app/services/firebase/employee-services.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { EmployeeAppointmentModel } from 'src/app/models/AppointmentModel';
import { isAfter, isBefore, addMinutes, max, maxTime, getDay } from 'date-fns';
import { CalendarEvent, CalendarView, CalendarDateFormatter, DateFormatterParams, CalendarWeekViewBeforeRenderEvent, CalendarMonthViewDay
} from 'angular-calendar';
import {
  subMonths, addMonths, addDays, addWeeks, subDays, subWeeks, startOfMonth,
  endOfMonth, startOfWeek, endOfWeek, startOfDay, endOfDay,
} from 'date-fns';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { HelperService } from 'src/app/services/helper/helper';
import { TaskService } from 'src/app/services/firebase/task.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { takeUntil } from 'rxjs/operators';
import { SMSModel } from 'src/app/models/SMSModel';
import { SMSService } from 'src/app/services/api/sms.service';
import { EmailService } from 'src/app/services/api/email.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class CustomDateFormatter extends CalendarDateFormatter {
  public weekViewHour({ date, locale }: DateFormatterParams): string {
    return formatDate(date, 'h:mm a', locale);
  }
  public weekViewColumnHeader({ date, locale }: DateFormatterParams): string {
    return formatDate(date, 'EEE', locale);
  }
}

type CalendarPeriod = 'day' | 'week' | 'month';
function addPeriod(period: CalendarPeriod, date: Date, amount: number): Date {
  return {
    day: addDays,
    week: addWeeks,
    month: addMonths,
  }[period](date, amount);
}

function subPeriod(period: CalendarPeriod, date: Date, amount: number): Date {
  return {
    day: subDays,
    week: subWeeks,
    month: subMonths,
  }[period](date, amount);
}

function startOfPeriod(period: CalendarPeriod, date: Date): Date {
  return {
    day: startOfDay,
    week: startOfWeek,
    month: startOfMonth,
  }[period](date);
}

function endOfPeriod(period: CalendarPeriod, date: Date): Date {
  return {
    day: endOfDay,
    week: endOfWeek,
    month: endOfMonth,
  }[period](date);
}

@Component({
  selector: 'app-create-appointment',
  templateUrl: './create-appointment.component.html',
  styleUrls: ['./create-appointment.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      .cal-week-view .cal-time-events .cal-day-column {
        margin-right: 10px;
      }

      .cal-week-view .cal-hour {
        width: calc(100% + 10px);
      }
      .cal-disabled {
      background-color: #eee;
      pointer-events: none;
      }
      .cal-disabled .cal-day-number {
        opacity: 0.1;
      }

    `,
  ],
  providers: [{
  provide: CalendarDateFormatter,
  useClass: CustomDateFormatter,
  }]
})

export class CreateAppointmentComponent implements OnInit {

  private destroy$ = new Subject<void>();
  daysInWeek = 7;
  loggedUser: any;
  // loading = false;
  employeeId: any;
  employeeSchedule: any;
  excludeDays: any;
  appointmentsList = [];
  serviceId: any;
  service: any;
  selectedServiceTime: any;
  allEmployees = [];
  selectedEmployee: any;
  schedule: any;
  view: CalendarView = CalendarView.Week;
  CalendarView = CalendarView;
  events: CalendarEvent[] = [];
  viewDate: Date = new Date();
  refresh = new Subject<void>();
  weekStartsOn = getDay(new Date())
  minDate: Date = subDays(new Date(), 1);
  maxDate: Date = addMonths(new Date(), 1);
  employeeServices: any = [];
  createAppointmentForm: UntypedFormGroup;
  newUserForm: UntypedFormGroup;
  selectedService = { serviceName: 'Click to select a service' };
  unAvailableTimeSlot: any;
  unAvailableTimes = [];
  scheduleModalOpen = false;
  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  serviceDuration = 15;
  selectedServiceEndTime: any;
  prevBtnDisabled = false;
  nextBtnDisabled = false;
  minStartingTime = 9;
  maxEndingTime = 17;
  isAdmin = false;
  allUsers = [];
  appointedFor: any;
  allServices = [];
  userDetail: any;
  selectedUserId: any;
  sendEmail = false;
  allowReschedule = false;
  allowCancellation = false;
  hostname = window.location.hostname !== 'localhost' ? 'https://' + window.location.hostname : 'http://localhost:4200';
  host: any;
  isMobile = false;
  appointmentOptions: any;
  availableEmployees = [];

  //added
  appSettings:any;
  adminTakeAppointment: any;
  admin:any;
  allSubscribers : any;
  selectedUser: any;
  serviceUnder: any;
  allTasks = [];
  addNewUserModal: any;
  searchQuery = '';  
  filteredUsersList = [];
  employeeScheduleIDs = [];
  createModalOpen = false;
  availablePhone: any;

  isNationApp = environment.firstNation.Origin == "FNLeaders" ? true : false;
  domain = environment.firstNation.name;
  domainName = this.isNationApp ? "https://" + this.domain + ".mynation.app" : "https://" + this.domain + ".goingmobile.app";
  


  constructor(
    private location: Location,
    private authService: AuthService,
    private scheduleService: ScheduleService,
    private appointmentService: AppointmentService,
    private route: ActivatedRoute,
    private empService: EmployeeServicesService,
    private fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private userService: UserService,
    private helperService : HelperService,
    private taskService : TaskService,
    private breakpointObserver: BreakpointObserver,
    private cd: ChangeDetectorRef,
    private appSettingsService: AppSettingsService,
    private emailService : EmailService,
    private SMSService : SMSService,
    private router: Router
  ) {
    if (typeof window['Capacitor'] !== 'undefined' && window['Capacitor']['platform'] !== 'web' ) {
      this.isMobile = true;
    }
    this.loggedUser = this.authService.getGlobalUser();
    this.appointedFor =  this.authService.getGlobalUser();
    if (this.loggedUser){
      if (this.loggedUser.role === 'Admin'){
        this.isAdmin = true;
      }
    }
    this.getService();
    this.dateOrViewChanged();
    this.refresh.next();
  }

  ngOnInit(): void {
    const CALENDAR_RESPONSIVE = {
      small: {
        breakpoint: '(max-width: 576px)',
        daysInWeek: 1,
      },
      medium: {
        breakpoint: '(max-width: 768px)',
        daysInWeek: 3,
      },
      large: {
        breakpoint: '(max-width: 960px)',
        daysInWeek: 5,
      },
    };

    this.breakpointObserver
    .observe(
      Object.values(CALENDAR_RESPONSIVE).map(({ breakpoint }) => breakpoint)
    )
    .pipe(takeUntil(this.destroy$))
    .subscribe((state: BreakpointState) => {
      const foundBreakpoint = Object.values(CALENDAR_RESPONSIVE).find(
        ({ breakpoint }) => !!state.breakpoints[breakpoint]
      );
      if (foundBreakpoint) {
        this.daysInWeek = foundBreakpoint.daysInWeek;
      } else {
        this.daysInWeek = 7;
      }
      this.cd.markForCheck();
    });
    this.createAppointmentForm = this.fb.group({
      // appointmentDate: [, [Validators.required]],
      message: [],
      startTime: [, [Validators.required]],
      endTime: [, [Validators.required]]

    });
    this.newUserForm = this.fb.group({
      GivenName: ['', [Validators.required]],
      LastName: [''],
      Email: [''],
      phoneNo: []
    })
    this.refresh.next();
  }
  getEndTIme(date, minutes) {
    return new Date(date.getTime() + minutes * 60000);
  }
  createEvent(appointment) {
    const data = {
      start: appointment.startTime.toDate(),
      end: appointment.endTime.toDate(),
      title: `${appointment.serviceName}`,
      color: {
        primary: '#d0d4d7',
        secondary: '#d0d4d7'
      },
    };
    return data;
  }
  createTask(task){
    const data = {
      start: task.reminderTime ? new Date(task.reminder + 'T' + task.reminderTime) : new Date(task.reminder),
      end: task.endReminderTime ?  addMinutes(new Date(task.endReminder + 'T' + task.endReminderTime),15)  : new Date(task.endReminder),
      title: "Has a task to do",
      appointmentId: `${task.id}`,
      isTask: true,
      allDay: task.reminderTime && task.endReminderTime ? false : true,
      color: {
        primary: '#F56F89',
        secondary: '#F56F89'
      },
      cssClass: 'my-custom-class ',

    };
    return data;
  }
  eventClicked({ event }: { event: CalendarEvent }): void {
  }
  hourSegmentClicked(event): void {
    const date = event.date;

    if (this.unAvailableTimes.includes(date)){
      // this.toast({ html: 'Employee not available at that time', classes: 'red', type: 'failure' });
      return;
    }
    const today = new Date();
    // today.setHours(0, 0, 0, 0)
    if (isBefore(date, today)){
      // this.toast({ html: 'Can\'t create appointment for past date!', classes: 'red', type: 'failure' });
      return;
    }

    const scheduleExists = this.appointmentsList.filter(a => date.getTime() >= (a.startTime.toDate().getTime() - 1000)
     && date.getTime() + 1000 <= a.endTime.toDate().getTime());

    if (scheduleExists.length <= 0) {
      this.selectedServiceTime = event.date;
      this.startTime.patchValue(this.selectedServiceTime);
      this.selectedServiceEndTime = addMinutes(this.selectedServiceTime, this.serviceDuration);
      this.endTime.patchValue(this.selectedServiceEndTime);

      this.scheduleModalOpen = false;
    } else {
      // this.toast({ html: 'Appointment already exists', classes: 'red', type: 'failure' });
    }
  }
  dateOutsideSchedule(date){
    const day = this.employeeSchedule.schedule.filter((day, index) => index === date.getDay());
    const startTime = day[0].startTime.split(':');
    let [scheduleHour,scheduleMinute] = [parseInt(startTime[0]) ,parseInt(startTime[1])];

    if (date.getHours() < scheduleHour){
      return true;
    }else if ((date.getHours() === scheduleHour) && date.getMinutes() < scheduleMinute){
      return true;
    }
    return false;

  }
  checkDateForAppointment(startTime, endTime) {
    const l = this.appointmentsList.filter(a => endTime.getDay() === a.startTime.toDate().getDay());
    const unBookableTimes = l.filter(a => isAfter(a.startTime.toDate().getTime(), startTime.getTime())
     && isBefore(a.startTime.toDate().getTime(), endTime.getTime()));

    return unBookableTimes;
  }
  beforeViewRender(body: CalendarWeekViewBeforeRenderEvent): void {
    body.hourColumns.forEach(hourCol => {
      if (this.employeeSchedule) {
        if (this.employeeSchedule.schedule.length > 0) {

          hourCol.hours.forEach(hour => {

            hour.segments.forEach(segment => {
              if (isBefore(segment.date, new Date())){
              this.unAvailableTimes.push(segment.date);
              segment.cssClass = 'unavailable';
            }
            }
          );

          });
        }}});

    body.hourColumns.forEach(hourCol => {

      if (this.employeeSchedule) {
        if (this.employeeSchedule.schedule.length > 0) {

          const day = this.employeeSchedule.schedule.filter(
            (day, index) =>
              {
                return index === hourCol.date.getDay() && !day.unAvailable;
              });
          const unavailableDay = this.employeeSchedule.schedule.filter(
            (day, index) =>
              {  return index === hourCol.date.getDay() && day.unAvailable;

              }
          );
          // unavailable day
          if (unavailableDay.length > 0) {
            hourCol.hours.forEach(hour => {

              hour.segments.forEach(segment => {
                  this.unAvailableTimes.push(segment.date);
                  segment.cssClass = 'unavailable';
              });
            });
          }
          // available slot
          if (day.length > 0){
              let startTime = day[0].startTime.split(':');
              let [scheduleHour,scheduleMinute] = [parseInt(startTime[0]) ,parseInt(startTime[1])];

              let endTime = day[0].endTime.split(':');
              let [endScheduleHour,endScheduleMinute] = [parseInt(endTime[0]) ,parseInt(endTime[1])];

              hourCol.hours.forEach(hour => {

                hour.segments.forEach(segment => {
                  if (segment.date.getHours() < scheduleHour){
                    this.unAvailableTimes.push(segment.date);
                    segment.cssClass = 'unavailable';

                  }
                  else if ((segment.date.getHours() === scheduleHour) && (segment.date.getMinutes() < scheduleMinute)){
                    this.unAvailableTimes.push(segment.date);

                    segment.cssClass = 'unavailable';

                  }

                  if (segment.date.getHours() > endScheduleHour){
                    this.unAvailableTimes.push(segment.date);
                    segment.cssClass = 'unavailable';

                  }
                  else if ((segment.date.getHours() === endScheduleHour) && (segment.date.getMinutes() >= endScheduleMinute)){
                    this.unAvailableTimes.push(segment.date);

                    segment.cssClass = 'unavailable';

                  }
                });
              });
          }
        }
      }
    });
  }
  selectService(){
    this.allEmployees = [];
    let serviceToBeSaved;
      if(this.service && !this.serviceUnder){
        serviceToBeSaved = this.service;
      } else if (this.service && this.serviceUnder){
        serviceToBeSaved = this.serviceUnder;
        serviceToBeSaved.isServiceUnder = true;
      }

      if (serviceToBeSaved){
        // this.service = service[0];
        this.serviceDuration = (serviceToBeSaved.serviceHour * 60) + serviceToBeSaved.serviceMinute;

        // get assigned employee
        if (serviceToBeSaved.assignedTo && serviceToBeSaved.assignedTo.length > 0){
          serviceToBeSaved.assignedTo.forEach(empId => {
                if(empId.employee){ // on some parts assigned to is saved as an object of employee 
                  empId = empId.employee.uid
                }
                if(this.employeeScheduleIDs.includes(empId)){
                  this.userService.getUserById(empId).valueChanges().subscribe((employee: any) => {
                    if (employee.length > 0){
                      if(this.employeeScheduleIDs.includes(employee[0].uid)){
                        this.allEmployees.push(employee[0]);
                      } 
                      this.cdr.detectChanges();
                    }
                  });
                } 

          });

          this.cdr.detectChanges();
        } else {
          // appoint to the owner/admin if no employee
          this.userService.getAllAdmins().valueChanges().subscribe((admins: any) => {
            if (admins.length > 0){
              this.allEmployees = [];
              admins.forEach(admin => {
                this.userService.getUserById(admin.uid).valueChanges().subscribe((employee: any) => {
                  if (employee.length > 0){
                    if(this.employeeScheduleIDs.includes(employee[0].uid)){
                      this.allEmployees.push(employee[0]);
                    } 
                    this.cdr.detectChanges();
                  }
                });
              });

              this.cdr.detectChanges();
            }
          });
        }
        this.availableEmployees = [...this.allEmployees];
        this.cdr.detectChanges();
      }
  }
  getService(){
    this.appSettingsService.getAppSettingsList().valueChanges().subscribe((settings: any)=>{
      if(settings.length > 0){
        this.appSettings = settings[0];
        this.availablePhone = this.appSettings.availablePhone ? this.appSettings.availablePhone : '';

      }
    })
    this.route.paramMap.subscribe(params => {
      if(window.history.state.service){
        this.serviceUnder = window.history.state.serviceUnder;
        this.service = window.history.state.service;
        // this.appointedFor = this.service.id;
      }
    });
    // Get every id's of employees with schedule
    if (this.appointedFor){
      this.scheduleService.getAllSchedules().valueChanges().subscribe(schs =>{
        if(schs.length > 0){
          schs.forEach((sch: any) => {
            this.employeeScheduleIDs.push(sch.employeeId);
          })
        }
      })

      this.appointmentService.getAppointmentSetting().valueChanges().subscribe(appSetting => {
        this.appointmentOptions = appSetting[0];
      });

      this.userService.getUsers().valueChanges().subscribe((users: any) => {
        if(users && users.length > 0){
          this.allSubscribers = users;
          this.filteredUsersList = this.allSubscribers;
          this.cdr.detectChanges();
        }
      })
      if(!this.userDetail){
        this.empService.getServiceById(this.service.id).valueChanges().subscribe((service:any) =>{
          this.service = service[0];
          this.selectService();
          this.cdr.detectChanges(); 
        })
      }

    }

  }
  searchUser(){
    if(this.searchQuery.trim() != ''){
      this.filteredUsersList = this.allSubscribers.filter(user => {
        return (user.GivenName.toLowerCase().includes(this.searchQuery.toLowerCase())  || user.LastName.toLowerCase().includes(this.searchQuery.toLowerCase()))
      })
    }
  }
  recreateEventsList() {
    this.events = [];
    this.appointmentsList.forEach(appointment => {
      const event = this.createEvent(appointment);
      this.events.push(event);
    });

    this.allTasks.forEach(tasks => {
      const event = this.createTask(tasks);
      this.events.push(event);
    })
    this.cdr.detectChanges();
  }
  closeModal() {
    this.message.patchValue('');
    this.startTime.patchValue('');
    this.endTime.patchValue('');
  }
  addAppointment(value) {
    this.createModalOpen = false;
    const newAppointment = new EmployeeAppointmentModel();
    newAppointment.employeeId = this.selectedEmployee.uid;
    newAppointment.startTime = this.selectedServiceTime;
    newAppointment.endTime = this.selectedServiceEndTime;
    newAppointment.serviceId = this.service.id;
    newAppointment.userId =  this.userDetail? this.userDetail.uid : this.appointedFor.uid;
    newAppointment.message = value.message;
    if(this.serviceUnder){
      newAppointment.serviceName = this.serviceUnder.serviceTitle;
    } else {

      newAppointment.serviceName = this.service.serviceName;
    }
    newAppointment.allowReschedule = this.allowReschedule;
    newAppointment.allowCancellation = this.allowCancellation;
    newAppointment.userName = this.userDetail ? this.userDetail.GivenName + ' ' + (this.userDetail?.LastName[0] ? this.userDetail.LastName[0] : '') :this.appointedFor.GivenName + ' ' + (this.appointedFor?.LastName[0] ? this.appointedFor.LastName[0] : '');
    newAppointment.employeeName = this.selectedEmployee.GivenName + ' ' + this.selectedEmployee.LastName[0];
    newAppointment.email = this.userDetail.Email;
    newAppointment.phoneNumber = this.userDetail.cellPhone;
    newAppointment.givenName = this.userDetail.GivenName;
    newAppointment.lastName = this.userDetail.LastName;
    newAppointment.bookedBy = this.loggedUser.uid;
      if ((this.userDetail &&this.userDetail.Email) || (this.appointedFor && this.appointedFor.Email)){
        this.appointmentService.getAppointmentSetting().valueChanges().subscribe((settings: any) => {
          if (settings && settings.length > 0 ){
              if(settings[0].sendEmailConfirmation){
                this.emailService.sendAppointmentConfirmation(
                  { selectedServiceTime :this.selectedServiceTime,
                  selectedServiceEndTime: this.selectedServiceEndTime,
                  serviceName : this.service.serviceName,
                  appointedFor: this.appointedFor.GivenName,
                  },this.userDetail.Email);
                }
                if(settings[0].sendSMSConfirmation){
        
                  const smsModel = new SMSModel();
                  smsModel.body = `Hello ${this.userDetail.GivenName},`
                  smsModel.body += `Your Service ${this.service.serviceName} is confirmed for date ${this.selectedServiceTime.getDate()}/${this.selectedServiceTime.getMonth() + 1}/${this.selectedServiceTime.getFullYear()} from ${this.selectedServiceTime.getHours()}:${this.selectedServiceTime.getMinutes()} to  ${this.selectedServiceEndTime.getDate()}/${this.selectedServiceEndTime.getMonth() + 1}/${this.selectedServiceEndTime.getFullYear()} and ${this.selectedServiceEndTime.getHours()}:${this.selectedServiceEndTime.getMinutes()}`
        
                  /*if (environment.firstNation.name !== "nationalchief") {
                    smsModel.body += `To stop receiving emails please login to the app`
                    smsModel.body += `Go to your preferences and disable Email, SMS or Both.`
                  }*/
        
                  if (environment.firstNation.name !== "nationalchief" && this.appSettings.general === 'existingNumber') {
                    smsModel.body += `\nTo stop receiving emails please login to ${this.domainName} \n`
                    smsModel.body += `Go to your preferences and disable Email, SMS or Both. \n`
                  }else if(this.appSettings.general === 'buyNum' && this.appSettings.customizeUnsubscribe){
                    smsModel.body += this.appSettings.unSubscribe;
                  }
            
                  
                  smsModel.from = this.availablePhone;
                  if(this.userDetail.cellPhone != ''){
                  smsModel.phoneNumberList.push(this.userDetail.cellPhone);
                  this.SMSService.sendSMS(smsModel);
                  }else{
                    this.toast({ html: 'This subscriber doesnt have a phone so s(h)e wont be able to get the SMS', classes: 'red', type: 'failure', redirect: true})
                  }
                }
            
            if (settings[0].sendEmailConfirmation && (this.userDetail.Email || this.appointedFor.Email)){
              // this.emailService.sendAppointmentNotification(newAppointment, this.host, this.service, this.loggedUser, this.userDetail || this.appointedFor);
            }
            // if (settings[0].sendSMSConfirmation && (this.userDetail.cellPhone || this.appointedFor.cellPhone)){
            //   const sms = new SMSModel();
            //   sms.Email_Address = '';
            //   sms.body = '';
            //   sms.title = 'Appointment Confirmation';
            //   sms.phone = '';
            //   sms.phoneNumberList = [this.userDetail.cellPhone || this.appointedFor.cellPhone];
            //   sms.show_email = false;
            //   sms.show_title = false;
            //   sms.show_phone = false;
            //   sms.show_cell = false;
            //   // this.smsService.sendSMS(newAppointment, this.host, this.service, this.loggedUser, this.userDetail);
            //   this.smsService.sendSMS(sms);
            // }
          }
        });
      }
       else{
        //console.log("It is inside here")
      }

    this.appointmentService.createEmployeeAppointment(newAppointment).then((appointment : any) => {
      this.toast({ html: 'Appointment Successfully Created!', classes: 'green', type: 'success', redirect: true});
    
    }).catch(err => {
      this.toast({ html: 'Error creating Appointment', classes: 'red', type: 'failure', redirect: true });

    });
  }
  backClicked() {
    // this.location.back();
    this.scheduleModalOpen = false;
  }
  goBack(){
    this.location.back();
  }
  selectUser(id){
    this.userService.getUserById(id).valueChanges().subscribe(user => {
      if (user.length > 0){
        this.appointedFor = user[0];
      }
    });
  }
  selectEmployee(id){
    const startTimes = []; const endTimes = [];
    let end: number;
    this.userService.getUserById(id).valueChanges().subscribe(employee => {
      if (employee.length > 0 ){
        this.selectedEmployee = employee[0];
        this.scheduleService.getScheduleByEmployeeId(this.selectedEmployee.uid).valueChanges().subscribe(s => {
          if (s && s.length > 0) {
            // this.loading = false;
            this.employeeSchedule = s[0];
            if(this.selectedEmployee.role == 'Admin' || this.selectedEmployee.role == 'Employee'){
              this.taskService.getTasksMadeTo(id).valueChanges().subscribe((tasks: any) =>{
                this.allTasks = tasks;
                this.recreateEventsList();
              })
            }

            this.employeeSchedule.schedule.forEach(sched => {
              if (sched.startTime){
                startTimes.push(sched.startTime);
              }
              if (sched.endTime){
                endTimes.push(sched.endTime);
              }
            });
            end = parseInt(this.sortTime(endTimes));
            this.maxEndingTime =  end < 24 ? end + 1 : end; // adding 1 hour max time if it is bleow 24 hours
            this.minStartingTime =  parseInt(this.sortTimeAscending(startTimes)) ;

            this.cdr.detectChanges();
            // this.refresh.next();
            this.excludeDays = this.employeeSchedule.schedule.map((day, index) => {
              if (day.unAvailable !== true) { return false; }
              return index;
            }).filter(day => day !== false);

            this.appointmentService.getAppointmentByEmployeeId(this.selectedEmployee.uid).valueChanges().subscribe(a => {
              if (a.length > 0) {
                const today = new Date();
                today.setHours(0, 0, 0, 0);

                this.appointmentsList = a;
                // this.appointmentsList = this.appointmentsList.filter(a => a.startTime.toDate() >= today);
                this.recreateEventsList();
              } else {
                this.appointmentsList = [];
              }
            });
            this.events = [...this.events];

            // employee services
            this.empService.getServicesWithTime().valueChanges().subscribe((services: any) => {
              if (services.length > 0) {
                services.forEach(service => {
                  if (service.assignedTo.includes(this.employeeId)){
                    this.employeeServices.push(service);
                  }
                });
                // this.employeeServices = services;
              }
            });
            this.cdr.detectChanges();
          }
          else {
            this.employeeSchedule = null;
          }
        });
      }
    });
  }
  sortTime(arr){
    return arr.reduce((a, b) => a <= b ? b : a );
  }
  sortTimeAscending(arr){
  return arr.reduce((a, b) => a >= b ? b : a );
  }
  chooseDateAndTimeClicked(){
    this.scheduleService.getScheduleByEmployeeId(this.selectedEmployee.uid).valueChanges().subscribe(s => {
      if (s && s.length > 0) {
        this.employeeSchedule = s[0];
        this.excludeDays = this.employeeSchedule.schedule.map((day, index) => {
          if (day.unAvailable !== true) { return false; }
          return index;
        }).filter(day => day !== false);


        this.appointmentService.getAppointmentByEmployeeId(this.selectedEmployee.uid).valueChanges().subscribe(a => {
          if (a.length > 0) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            this.appointmentsList = a;
          } else {
            this.appointmentsList = [];
          }
        });
      }
    });
    this.scheduleModalOpen = true;
  }
  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
      if (obj.redirect){
        if(this.loggedUser){
          if(this.loggedUser.role=='Employee'){
            this.router.navigate([`/employee/appointments`])
          }
          if(this.loggedUser.role=='Admin'){
            this.router.navigate([`/admin/appointments`])
          }
        } else {
          this.location.back()
        }
      }
    }, 2000);
  }
  increment(): void {
    this.changeDate(addPeriod(this.view, this.viewDate, 1));
  }
  decrement(): void {
    this.changeDate(subPeriod(this.view, this.viewDate, 1));
  }
  today(): void {
    this.changeDate(new Date());
  }
  dateIsValid(date: Date): boolean {
    return date >= this.minDate && date <= this.maxDate;
  }
  changeDate(date: Date): void {
    this.viewDate = date;
    this.dateOrViewChanged();
  }
  dateOrViewChanged(): void {
    this.prevBtnDisabled = !this.dateIsValid(
      endOfPeriod(this.view, subPeriod(this.view, this.viewDate, 1))
    );
    this.nextBtnDisabled = !this.dateIsValid(
      startOfPeriod(this.view, addPeriod(this.view, this.viewDate, 1))
    );
    if (this.viewDate < this.minDate) {
      this.changeDate(this.minDate);
    } else if (this.viewDate > this.maxDate) {
      this.changeDate(this.maxDate);
    }
  }
  beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {
    body.forEach((day) => {
      if (!this.dateIsValid(day.date)) {
        day.cssClass = 'cal-disabled';
      }
    });
  }
  checkedEmailChange(){
    this.sendEmail = !this.sendEmail;
  }
  checkedRescheduleChange(){
    this.allowReschedule = !this.allowReschedule;
  }
  checkedCancellationChange(){
    this.allowCancellation = !this.allowCancellation;
  }
  getInnerText(txt){
    return this.helperService.getInnerText(txt);
  }
  saveNewUserData(value){
    this.userDetail = {
      GivenName: value.GivenName,
      LastName: value.LastName,
      Email: value.Email,
      cellPhone: value.cellPhone,
      uid: 'unregistered'
    }
    this.searchQuery = this.userDetail.GivenName + ' ' +this.userDetail.LastName;
    this.addNewUserModal = false;
  }
  closeAddModal(){
    this.addNewUserModal = false;
  }
  onUserSelected(user){
    this.userDetail = user;
    this.searchQuery = this.userDetail.GivenName + ' ' +this.userDetail.LastName;
    // this.serviceUnder = window.history.state.serviceUnder;
  }
  get message() { return this.createAppointmentForm.get('message'); }
  get startTime() { return this.createAppointmentForm.get('startTime'); }
  get endTime() { return this.createAppointmentForm.get('endTime'); }
  closeCreateModal(){
    this.createModalOpen = false;
  }
}
