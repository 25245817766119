import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CalendarDateFormatter, CalendarEvent, CalendarView } from 'angular-calendar';
import { addDays, endOfDay, isSameDay, startOfDay, subDays } from 'date-fns';
import moment from 'moment';
import { Subject } from 'rxjs';
import { CustomDateFormatter } from 'src/app/models/custom-date-formatter.provider';

@Component({
  selector: 'app-event-calendar-view',
  templateUrl: './event-calendar-view.component.html',
  styleUrls: ['./event-calendar-view.component.scss'],
  providers: [{
    provide: CalendarDateFormatter,
    useClass: CustomDateFormatter,
  }]
})

export class EventCalendarViewComponent implements OnInit {
  @Input() model: any[];
  @Input() isMember: false;
  @Input() calledFrom;

  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  events: CalendarEvent[] = [];
  viewDate: Date = new Date();
  refresh: Subject<any> = new Subject();
  activeDayIsOpen = false;

  constructor(private router: Router) { }

  ngOnInit() {
    this.fetchEvents(this.model);
    this.refresh.next(this.model);
  }

  fetchEvents(events: any[]) {
    events.forEach(element => {
      const event = {
      eventDate: element.eventStartDate,
      eventEnd : element.eventStartDate,
      id : element.id,
      title : element.eventTitle};

      this.events.push({
        start: new Date(element.eventStartDate),
        end: addDays(new Date(element.eventEndDate), -1),
        title: element.eventTitle,
        allDay: true,
        meta: {
          event,
        },
      });
    });
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (
      (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
      events.length === 0
    ) {
      this.activeDayIsOpen = false;
    } else {
      this.activeDayIsOpen = true;
    }
    this.viewDate = date;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  eventClicked({ event }: { event: CalendarEvent }): void {
    this.router.navigate(['/view-event', event.meta.event.id]);

  }


}
