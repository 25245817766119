import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, FormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppinfoService } from 'src/app/services/backend-automation/appinfo.service';
import { AutomationService } from 'src/app/services/backend-automation/automation.service';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-analytics-setup',
  templateUrl: './analytics-setup.component.html',
  styleUrls: ['./analytics-setup.component.css']
})
export class AnalyticsSetupComponent implements OnInit {
  listOfAccounts: any = [];
  listLinkedProjectsModal: any;
  linkedProjects: any[] = [];

  searchForm: UntypedFormGroup;

  toastClass: any;
  toastMessage: any;
  toastType: any;
  openToast: boolean;

  // options:any[];
  defaultAnalyticsId: any;
  defaultAnalyticsName: any;
  makeButDefault: boolean = false;

  currentClickedAccId: any | string;
  deleteModal: boolean = false;
  currentClickedPropId: any | string;

  filteredLinkedProjects: any[] = [];
  filterOn: boolean = false;
  accPage: number = 1;
  propPage: number = 1;
  filterPage: number = 1;
  isDemoApp = environment.firstNation.name == "Demo" ? true : false

  storedAnalytics:any = [];

  displayedLinkedProj: any[] = []; // Array to hold the users to be displayed on the current page
  // p: number = 1;
  total: number = 0;
  itemsPerPage: number = 10;
  p:number = 1;

  private analyticsSubscription: Subscription;
  private appInfoSubscription: Subscription;



  constructor(private automationService: AutomationService,
    private appInfoService: AppinfoService,
    private fb: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router) { }

  ngOnInit(): void {

    this.analyticsSubscription = this.automationService.getStoredAnalyticsAcc().subscribe((x: any) => {

      this.storedAnalytics = x || {};
      this.storedAnalytics.accounts = this.storedAnalytics.accounts || [];

      const currentDate = new Date().toISOString().split('T')[0];

      if (!this.storedAnalytics || !this.storedAnalytics.accounts.length  || currentDate !== this.storedAnalytics.currentDate) {
        this.updateAccountDetails(currentDate);
      } else {
        this.listOfAccounts = this.storedAnalytics.accounts
        this.getStoredDefaultAccount()



      }
    })

    this.appInfoService.getConfigSetting().valueChanges().subscribe((response) => {
      if (response.length > 0) {
        this.defaultAnalyticsId = response[0];
      }
    });

    this.searchForm = this.fb.group({
      searchText: ['', Validators.required]
    })

  }

  updateAccountDetails(currentDate: string) {
    this.automationService.listAccountDetails().subscribe((res: any) => {
      this.listOfAccounts = res;
      this.getStoredDefaultAccount()

      this.automationService.storeAnalyticsAcc(this.listOfAccounts).add(() => {
        console.log("Firestore updated successfully");
        // Ensure that storedAnalytics is updated with new data and date
        this.storedAnalytics = { accounts: this.listOfAccounts, currentDate: currentDate };
      })
      // (error => {
      //   console.error("Error updating Firestore", error);
      // });
    });
  }
  

  get searchText() { return this.searchForm.get('searchText') }

  openListLinkedProjectsModal(accountId: any | string) {
    this.listLinkedProjectsModal = true;
    console.log(accountId, " this is the accountId passed ")
    this.automationService.listLinkedProjects(accountId).subscribe((res: any) => {
      this.linkedProjects = res;
      this.p = 1; // Reset pagination to the first page
      this.updateDisplayedUsers()
    })
    this.currentClickedAccId = accountId;

  }

  makeItDefault(accountId: any | string) {
    this.appInfoService.updateConfigSetting(accountId).add(() => {
      console.log(" default changed to ", accountId)
      this.toast({ html: 'Default account saved successfully! ', classes: 'green', type: 'success' });

    })

  }

  isDefault(accountId: any | string) {
    if (this.defaultAnalyticsId?.["accountId"] === accountId) {
      this.makeButDefault = true;
      return true;
    } else {
      this.makeButDefault = false;
      return false;
    }
  }

  isFull(accountId: any | string) {
    var account = this.listOfAccounts.filter(account => account["accountId"] === accountId);
    if (account.length != 0) {
      if (account[0].totalNoOfProperties === 100) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  closeModal() {
    this.listLinkedProjectsModal = false;

    this.filteredLinkedProjects = []
    this.filterOn = false;
    this.searchText.setValue("")

  }

  toast(obj) {
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green';
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
    }, 2000);
  }

  getStoredDefaultAccount() {
    this.appInfoService.getConfigSetting().valueChanges().subscribe((response) => {
      if (response.length > 0) {
        this.defaultAnalyticsId = response[0];
        var account = this.listOfAccounts.filter(account => account?.["accountId"] === this.defaultAnalyticsId?.["accountId"]);
        this.defaultAnalyticsName = account[0].name;
        console.log(this.defaultAnalyticsName, " this is the stored full info")

        console.log(this.defaultAnalyticsId["accountId"], " stored default analytics id")
        console.log(account, " filtered from list of accounts")
      }
    });
  }

  navigateToCreatePage() {
    this.router.navigate(['/admin/create-analytics-account']);
  }

  deletePropertyModal(project) {

    this.currentClickedPropId = project
    this.deleteModal = true

  }

  deleteProperty() {
    console.log(this.currentClickedPropId, " this is the project id passed ", this.currentClickedAccId, " current clicked acc id passed is")

    this.automationService.deleteProperty(this.currentClickedPropId, this.currentClickedAccId).subscribe((res) => {
      this.toast({ html: 'Project removed from the account successfully! ', classes: 'green', type: 'success' });
      this.closeDeleteModal()
      this.closeModal()
    }, (err) => {
    })

  }

  closeDeleteModal() {
    this.deleteModal = false
    this.filteredLinkedProjects = []
    this.filterOn = false;
  }

  search(modal) {
    this.filterOn = true;
    console.log(modal.searchText, " input value is here")

    const matchingStrings = [];

    for (const string of this.linkedProjects) {
      if (string.startsWith(modal.searchText)) {
        matchingStrings.push(string);
      }
    }

    console.log(matchingStrings, " here iss the filtered list")
    this.filteredLinkedProjects = matchingStrings
    return matchingStrings;
  }

  // pageChangeEvent(event: number) {
  //   this.p = event;
  //   this.updateDisplayedUsers();
  // }

  // updateDisplayedUsers() {
  //   this.total = this.filteredPosts.length;
  //   console.log("total pages of posts ", this.total)
  //   const startIndex = (this.p - 1) * this.itemsPerPage;
  //   const endIndex = startIndex + this.itemsPerPage;
  //   this.displayedBlogs = this.filteredPosts.slice(startIndex, endIndex);
  // }
  pageChangeEvent(event: number) {
    this.p = event;
    this.updateDisplayedUsers();
  }

  updateDisplayedUsers() {
    this.total = this.linkedProjects.length;
    console.log("total pages of posts ", this.total);
    const startIndex = (this.p - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.displayedLinkedProj = this.linkedProjects.slice(startIndex, endIndex);
  }
}
