import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiKeyManagerService } from '../api/apiKeyManager.Service';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class queryReportService {

  li: any;
  analyticsListRef: AngularFirestoreCollection<any>;
  analyticsRef: AngularFirestoreDocument<any>;

  constructor(private http: HttpClient, private keyManager: ApiKeyManagerService, private db: AngularFirestore
  ) { }

  // put dimensions in an array
  requestDimensions(dimensions: Array<any>) {
    let result: any = []
    dimensions.forEach((item) => {
      // console.log("       ITEM IS   ", item)
      result.push({
        name: item
      })

    });
    // console.log("          RESULT IS    ", result);
    return result;
  }

  // send query in universal analytics -> for GA3
  getAnalyticsData(dataAnalytics: Object | any) {

    const url = environment.firstNation.apiUrl + 'api/Analytics/GetReport/';
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    return this.http.post<any>(url, dataAnalytics, { headers });

  }

  //  get individual user activity -> for GA3
  getUserActivitySearch(activitySearch: Object | any) {


    const url = environment.firstNation.apiUrl + 'api/Analytics/userSearchActivity/';
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    return this.http.post<any>(url, activitySearch, { headers });
  }

  // Get user sessions analytics
  getUserSession(propertyId: string | any, filterSession: Int32Array | any, startDate: string | any, endDate: string | any) {
    const model = {
      PropertyId: propertyId,
      FilterSession: filterSession,
      StartDate: startDate,
      EndDate: endDate
    }
    let url = `https://api.goingmobile.app/api/GoogleAnalyticsV4/UserSessionReport/`;
    return this.http.post<any>(url, model);


  }

  // Get top 10 viewers and top 10 countries
  getTopViewersAndCountries(propertyId: string | any, filterSession: Int32Array | any, startDate: string | any, endDate: string | any) {
    const model = {
      PropertyId: propertyId,
      FilterSession: filterSession,
      StartDate: startDate,
      EndDate: endDate
    }
    let url = `https://api.goingmobile.app/api/GoogleAnalyticsV4/TopViewersAndCountriesReport/`;
    return this.http.post<any>(url, model);

  }

  // Get total number of unsigned users
  getTotalUnsignedUsers(propertyId: string | any, filterSession: Int32Array | any, startDate: string | any, endDate: string | any) {
    const model = {
      PropertyId: propertyId,
      FilterSession: filterSession,
      StartDate: startDate,
      EndDate: endDate
    }
    let url = `https://api.goingmobile.app/api/GoogleAnalyticsV4/TotalUnsignedInUsers/`;
    return this.http.post<any>(url, model);


  }

  // Get page header views
  getPageHeaderViews(propertyId: string | any, startDate: string | any, endDate: string | any) {
    const model = {
      PropertyId: propertyId,
      StartDate: startDate,
      EndDate: endDate
    }
    let url = `https://api.goingmobile.app/api/GoogleAnalyticsV4/PageHeaderViewReport/`;
    return this.http.post<any>(url, model);



  }

  // Get page detail
  getPageDetail(propertyId: string | any, startDate: string | any, endDate: string | any) {
    const model = {
      PropertyId: propertyId,
      StartDate: startDate,
      EndDate: endDate
    }
    let url = `https://api.goingmobile.app/api/GoogleAnalyticsV4/PageDetailReport/`;
    return this.http.post<any>(url, model);



  }

  getTopTenPages(propertyId: string | any, startDate: string | any, endDate: string | any) {
    const model = {
      PropertyId: propertyId,
      StartDate: startDate,
      EndDate: endDate
    }
    let url = `https://api.goingmobile.app/api/GoogleAnalyticsV4/TopTenPagesReport/`;
    return this.http.post<any>(url, model);


  }

  getChannelGrouping(propertyId: string | any, startDate: string | any, endDate: string | any) {
    const model = {
      PropertyId: propertyId,
      StartDate: startDate,
      EndDate: endDate
    }
    let url = `https://api.goingmobile.app/api/GoogleAnalyticsV4/ChannelGroupingReport/`;
    return this.http.post<any>(url, model);
  }

  getSourceAndDevice(propertyId: string | any, startDate: string | any, endDate: string | any) {
    const model = {
      PropertyId: propertyId,
      StartDate: startDate,
      EndDate: endDate
    }
    let url = `https://api.goingmobile.app/api/GoogleAnalyticsV4/SourceAndDeviceReport/`;
    return this.http.post<any>(url, model);
  }

  // FOR NON LOGGED IN USERS -> PAGE ANALYTICS

  // Get page header views for non-logged in
  getPageHeaderViewsNL(propertyId: string | any, startDate: string | any, endDate: string | any) {
    const model = {
      PropertyId: propertyId,
      StartDate: startDate,
      EndDate: endDate
    }
    let url = `https://api.goingmobile.app/api/GoogleAnalyticsV4/PageHeaderViewNLReport/`;
    return this.http.post<any>(url, model);

  }

  // Get page detail for non-loggedin users
  getPageDetailNL(propertyId: string | any, startDate: string | any, endDate: string | any) {
    const model = {
      PropertyId: propertyId,
      StartDate: startDate,
      EndDate: endDate
    }
    let url = `https://api.goingmobile.app/api/GoogleAnalyticsV4/PageDetailNLReport/`;
    return this.http.post<any>(url, model);

  }

  getTopTenPagesNL(propertyId: string | any, startDate: string | any, endDate: string | any) {
    const model = {
      PropertyId: propertyId,
      StartDate: startDate,
      EndDate: endDate
    }
    let url = `https://api.goingmobile.app/api/GoogleAnalyticsV4/TopTenPagesNLReport/`;
    return this.http.post<any>(url, model);

  }


}

