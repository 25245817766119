import { Component, OnInit } from '@angular/core';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { NotificationsService } from 'src/app/services/firebase/notifications.service';
import { AppSettingsComponent } from '../../admin/settings/app-settings/app-settings.component';

@Component({
  selector: 'app-user-notifications',
  templateUrl: './user-notifications.component.html',
  styleUrls: ['./user-notifications.component.css']
})
export class UserNotificationsComponent implements OnInit {

user:any;
notifications:any;
name : any;
setting = [];
  constructor(private authService: AuthService,
              private notificationsService: NotificationsService,
              private settingService: AppSettingsService) { }

  ngOnInit(): void {
    this.user = this.authService.getGlobalUser();
    this.notificationsService.getMyNotifications(this.user.uid).valueChanges().subscribe(notifications => {
      if(notifications.length > 0 ){
        this.notifications = notifications;
      }
    })
    this.settingService.getAppSettingsList().valueChanges().subscribe(setting=>{
      console.log("Here are the settings to see the app name", setting);
      this.setting = setting;
      this.name = this.setting[0].appName;
    })

    }
  }


