<div *ngIf="data" class="flex flex-col align-middle w-full mt-12 gap-y-12 md:gap-y-4 ">
       <div>
            <!-- items -->
            <div *ngFor="let item of data.items; let i = index" class="w-full  ">
                <div class="w-full ">
                    <div class="" [ngClass]="{'w-full': calledFromEmptyDashboard , 'md:min-w-5xl ': !calledFromEmptyDashboard}">

                        <div class=" gap-x-6 flex justify-center flex-wrap" >
                            <!-- <span class=" block md:hidden pb-6 text-2xl text-center font-bold" [innerHTML]="data.sectionTitle| noSanitize"></span> -->
                            <div class="">
                                <div  *ngIf="!calledFromEmptyDashboard && item.imgUrl && item.imgUrl != ''" class="place-content-center flex justify-center">
                                    <img loading="lazy" [src]="item.imgUrl" alt="" class="md:w-64 w-40 object-cover ">


                                </div>
                                <div *ngIf="calledFromEmptyDashboard && data.imgUrl" class="place-content-center flex justify-center">
                                    <img loading="lazy" [src]="data.imgUrl" alt="" class="md:w-64 w-40 object-cover ">
                                </div>

                            </div>
                            <div class="flex flex-col break-words " [ngClass]="{'md:w-1/2': item.imgUrl || data.imgUrl, 'md:w-2/3 text-left ': item.imgUrl=='' || data.imgUrl == '' || !data.imgUrl }">
                                <span class="hidden md:block pb-6 text-3xl font-bold" [innerHTML]="data.sectionTitle| noSanitize"></span>
                    
                                <span class="text-base text-gray-700" [innerHTML]="item.description | noSanitize"></span>
                            </div>
                            
                        </div>
                    
                </div>
                </div>
        </div>

       </div>

</div>