<div class="font-body bg-veryLightGray min-h-screen max-h-max pt-6">
  <div class="flex flex-col md:w-4/6 m-2 bg-white h-max p-4 md:p-12 rounded-lg space-y-4 md:translate-x-1/4">
    <p class=" font-bold text-xl text-center text-customTeal ">Just one more thing </p>

  
    <hr>
    <!-- Send content -->
    <div class="flex flex-col space-y-6 justify-between md:flex-row md:space-y-0 ">
      <!-- Description -->
      <div class="md:w-1/2 space-y-2  ">
        <p class="font-bold">
          Notification Preference
        </p>
        <p class=" text-xs text-darkGray ">
          Please choose your preference for the content about new events, services, registered and logged in users.
        </p>
      </div>


      <div class="flex flex-col justify-center align-middle space-x-8 items-baseline md:w-1/2">
        <!-- <span *ngIf="toggleYesorNo"> -->
        <div class="form-control">
          <label class="label cursor-pointer space-x-2">
            <input type="checkbox" class="checkbox checkbox-primary" [checked]="recieveNotificationFor.LoggedInUsers"
              [disabled]="loggedInUser && loggedInUser.cellPhone == ''" (click)="toggleReceiveNotificationFor('loggedInUser')" />

            <span class="label-text">Receive notification when user logs in</span>
          </label>
        </div>
        <div class="form-control">
          <label class="label cursor-pointer space-x-2">
            <input type="checkbox" class="checkbox checkbox-primary" [checked]="recieveNotificationFor.RegisteredUsers"
              (click)="toggleReceiveNotificationFor('registeredUser')" />
            <span class="label-text">Receive notification when user register</span>
          </label>
        </div>
        <div class="form-control">
          <label class="label cursor-pointer space-x-2">
            <input type="checkbox" class="checkbox checkbox-primary" [checked]="sendNotificationFor.EventCreated"
              (click)="toggleSendNotificationFor('eventCreated')" />
            <span class="label-text">Send notification when an event is created</span>
        </label>
        </div>
        <div class="form-control">
          <label class="label cursor-pointer space-x-2">
            <input type="checkbox" class="checkbox checkbox-primary" [checked]="sendNotificationFor.ServiceCreated"
              (click)="toggleSendNotificationFor('serviceCreated')" />
            <span class="label-text">Send notification when a service is created</span>
          </label>
        </div>
        <!-- </span> -->

      </div>
      <div class="text-yellow-400 flex font-bold" *ngIf="loggedInUser && loggedInUser.cellPhone == ''"><i
          class="material-icons">warning</i><span class="text-yellow-500">You should enter your cell phone in order to
          choose the SMS preference.</span></div>

    </div>
    <span class="text-error" *ngIf="noMessage">You have to choose the connection method</span>


    <div class="pt-6 space-y-2">
      <div class="">
      </div>
      <div class=" flex justify-end w-full">
        <button type="submit" (click)="finish()"
          class="flex space-x-2  px-4 md:px-6 py-2 bg-customTeal text-white rounded-lg w-max h-min text-sm md:text-base font-bold hover:bg-darkBlue focus:outline-none  ">
          <span>Submit</span>
        </button>
      </div>
    </div>

  </div>


  <div id="my-modal-disable" *ngIf="reloadModal" class="modal"
    [ngClass]="{'modal-open': reloadModal, 'modal-action': !reloadModal }">
    <div class="modal-box max-w-4xl">
      <div class="modal-content">
        <div class="flex justify-end " (click)="closeReloadModal()"><i
            class="material-icons border-2 rounded-full px-2 py-2 border-customTeal text-white bg-customTeal">close</i>
        </div>
        <div>
          <h2>Login Again</h2>
          <p>Please login again</p>
        </div>
      </div>
    </div>
  </div>
</div>