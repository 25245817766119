<app-service-product-route
  [servicesRoute]="serviceRoute"
  [productsRoute]="productsRoute"
>
<!-- servicesRoute="/user/services"  -->
<!-- productsRoute="/user/products" -->
<!-- productsRoute="/user/product-catagory" -->
</app-service-product-route>

<!-- promotionRoute="/user/products" -->

