<div class="flex flex-col h-[45rem] bg-white">
    <!-- <div class="bg-white w-full px-4 md:px-16 py-8 h-4/6 min-h-max  md:overflow-y-scroll space-y-6 rounded-md "> -->
    <ng-container *ngIf="chatSelected">
        <!-- User/Group Info -->
        <div class="p-4 flex flex-row justify-between " (click)="onEditGroup()" *ngIf="showHeader">
            <a *ngIf="showBack" class="w-2/12" (click)="backClicked()"><i class="material-icons">keyboard_backspace</i></a>

            <ng-container *ngIf="['group','employee'].includes(chatType)">
                <app-chat-info class="w-8/12" [data]="getGroupData()" class="hover:cursor-pointer"></app-chat-info>
                <div *ngIf="isAdmin" class="w-2/12 pr-4 flex justify-end">
                    <button type="button" (click)="onEditGroup();">
                        <i class="material-icons white-text">edit</i>
                    </button>

                </div>
            </ng-container>
            <app-chat-info  class="w-10/12" *ngIf="chatType==='private'" [data]="getPrivateData()" class="hover:cursor-pointer">
            </app-chat-info>


        </div>
        <hr>

        <div #messagesContainer [scrollTop]="messagesContainer.scrollHeight" class="w-full scroll-smooth py-4 h-5/6 min-h-max  overflow-y-scroll space-y-6 rounded-md">

            <!-- Attachment loader -->
            <div class="flex justify-center h-screen" *ngIf="loadingImages">
                <progress class="progress progress-[#077373] w-full"></progress>
            </div>
            <!-- Messages loader -->
            <div *ngIf="loadingMessages" class="px-8">
                <p class="no-messages">Loading Message History ...</p>

            </div>

            <!-- Messages -->
            <div *ngIf="!loadingMessages && !loadingImages">
                <!-- <div *ngIf="!loadingMessages" class="max-h-screen min-h-screen h-full"> -->

                <div *ngIf="messages && messages.length > 0">
                    <div class="flex flex-col  gap-y-6 px-4" *ngFor="let message of messages; index as i">
                        <div class="row message-date"
                            *ngIf="i == 0 || (message.timeStamp.toDate() | date: 'dd MMMM yyyy') !== (messages[i-1]?.timeStamp.toDate() | date: 'dd MMMM yyyy')">
                            <div class="col s12">
                                <div class="date text-center text-sm" style="margin-top: 0.6rem;">
                                    {{ message.timeStamp.toDate() | date: "EEEE, MMMM d, y" }}
                                </div>
                            </div>
                        </div>
                        <app-message-item class="py-4" [data]="getMessageData(message)"></app-message-item>

                    </div>

                </div>
                <div *ngIf="!messages || messages.length == 0">
                 
                    <div  class="flex flex-col items-center justify-center gap-y-2 md:mt-20">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 stroke-slate-400 ">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 0 1-2.555-.337A5.972 5.972 0 0 1 5.41 20.97a5.969 5.969 0 0 1-.474-.065 4.48 4.48 0 0 0 .978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25Z" />
                          </svg>
                                
                     <div class="flex flex-col space-y-2">
                        <span class="text-center font-bold" >
                            No Message yet!
                        </span>
                        <span class="text-center text-sm text-gray-500" >
                            Select messaging!
                        </span>
                     </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Message form -->
        <div class="flex w-full bottom-0" #messagesForm >
            <form [formGroup]="messageForm" (ngSubmit)="send(messageForm.value)"
                class="space-x-4  bg-white   items-center w-full p-3  ">
                <div class=" flex   space-x-4  bg-white   items-center w-full md:p-3">
                    <div class="w-10 flex items-center content-center  max-w-x  rounded-lg gap-x-3">
                        <label class="custom-file-upload  self-center flex flex-col ">
                            <i class="fa fa-paperclip text-customTeal text-2xl text-center self-center"></i>
                            <input #attachFile id="attachFile" name="attachFile" onclick="value = null"
                            
                                (change)="onSelectFile($event.target.files)" formControlName="attachFile" type="file"
                                hidden />
                        </label>
                    </div>

                    <input type="text " placeholder="Write a message " class="input input-bordered border-gray-300 input-md w-10/12  "
                        formControlName="message" />
                    <button
                        class=" flex items-center space-x-2 justify-center px-4 md:px-6 py-2 bg-customTeal text-white rounded-lg w-max h-min text-sm md:text-base font-bold hover:bg-darkBlue focus:outline-none ">
                        <i class="fa fa-paper-plane text-white "></i>
                        <span>send </span>
                    </button>
                </div>

            </form>
        </div>
    </ng-container>

    <!-- No Messages -->
    <ng-container class="items-center " *ngIf="!chatSelected">
    
        <div class="flex flex-col justify-start items-center w-full h-full mt-48">
            <div  class="flex flex-col items-center justify-center gap-y-2">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 stroke-slate-400 ">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 0 1-2.555-.337A5.972 5.972 0 0 1 5.41 20.97a5.969 5.969 0 0 1-.474-.065 4.48 4.48 0 0 0 .978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25Z" />
                  </svg>
                        
             <div class="flex flex-col space-y-2">
                <span class="text-center font-bold" >
                    No chat Selected!
                </span>
                <span class="text-center text-sm text-gray-500" >
                    Select a chat to start messaging!
                </span>
             </div>
            </div>
        </div>
      
    </ng-container>
</div>

<div id="attachModal" *ngIf="attachModalOpen" class="modal"
    [ngClass]="{'modal-open': attachModalOpen, 'modal-action': !attachModalOpen }">
    <div class="modal-box w-9/12 md:w-4/12 max-w-5xl rounded-md">
        <div class="modal-content flex flex-col bg-white rounded-lg p-2 basis-full md:basis-5/12  md:gap-4">

            <div *ngIf="selectedFilesForDisplay.length > 0">
                <!-- image -->
                <img loading="lazy" *ngIf="selectedFilesForDisplay[0].type ==='image'" [src]="base64Image"
                    class=" w-full h-50 mt-3 md:mt-0  md:h-64 rounded-md object-contain" alt="Image">
        <!-- <img class="object-contain" [src]="imgLink"> -->

                <video controls *ngIf="selectedFilesForDisplay[0].type ==='video'" [src]="attachmentFile"
                    class="object-cover w-full h-24 mt-3 md:mt-0  md:h-64 rounded-md cover" alt="Video"></video>
                <div class="flex flex-row gap-x-3" *ngIf="selectedFilesForDisplay[0].type ==='pdf'">
                    <!-- show file here -->
                    <i class="material-icons float-left text-7xl">feed</i><span
                        class="font-bold">{{selectedFilesForDisplay[0].name}}</span>
                </div>
            </div>
            <div>
                <input [(ngModel)]="caption" type="text " placeholder="Write a message "
                    class="input input-bordered input-md w-full" />
            </div>
            <div class="flex justify-between pt-2">
                <button (click)="cancelAttach()" class="font-bold text-customTeal border-none">Cancel</button>
                <button class="py-2 px-4 bg-customTeal text-white border-customTeal rounded-md"
                    (click)="sendAttachment()" type="button">Send</button>
            </div>
        </div>

    </div>

</div>
<!-- toast -->
<app-alert class="fixed top-0 flex justify-end w-full" [toastType]="toastType" [toastMessage]="toastMessage"
    [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>