import { Component, OnInit } from '@angular/core';
import { timeStamp } from 'console';
import { AreasOfInterestService } from 'src/app/services/firebase/areas-of-interest.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/firebase/user.service';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { AccountService } from 'src/app/services/api/account.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-account-setup',
  templateUrl: './account-setup.component.html',
  styleUrls: ['./account-setup.component.css']
})
export class AccountSetupComponent implements OnInit {

  constructor(private router: Router,
    private areaService: AreasOfInterestService,
    private authService: AuthService,
    private userService: UserService,
    private accountService: AccountService,
    private afAuth: AngularFireAuth,
    private areaOfInterest: AreasOfInterestService,
    private fireMembersService: FireMembersService) { }
  //selectedInterest :any;
  AllareaInterest: any;
  isInterestClicked: boolean = false;
  Interests = [];
  currentInterest: any;
  currentInterestIndex: any;
  selectedInterest = [];
  currentUser: any;
  toggleYesorNo: boolean = false;
  toggleNotfication: boolean = true;
  SMSchecked: boolean = false;
  emailChecked: boolean = false;
  InappChecked: boolean = false;
  privacyChecked: boolean = true;
  noMessage: boolean = false;
  noAgreement: boolean = false;
  noSelectedInterest: boolean = false;

  //added
  loggedInUser: any;
  userContactPreference = [];
  areaGlobal = [];
  globalUser: any;
  userId:any;
  currentUserId: any;
  user: any;
  myProfileMenu: boolean;

  reloadModal: boolean = false;
  //
  a: any;
  isDemo = (environment.firstNation.name === "Demo") ? true : false ;
  ngOnInit(): void {
    // this.globalUser = this.authService.getGlobalUser();
    // this.currentUser = this.authService.getCurrentUser();

    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.currentUser = user;
            }
      });

    // this.accountService.currentAccount.subscribe(a=>{
    //   this.currentUserId=a
    // }
    // )

    this.isInterestClicked = false;
    // this.selectedInterest = undefined;
    this.areaService.getAreasOfInterest().valueChanges().subscribe(areaInterest => {
      if (areaInterest.length > 0) {
        this.AllareaInterest = areaInterest;
        this.userService.getUserById(this.currentUser.uid).valueChanges().subscribe(user => {
          if (user.length > 0) {
            this.loggedInUser = user[0];
          }
        });
      } else {
        this.AllareaInterest = [];
        this.userService.getUserById(this.currentUser.uid).valueChanges().subscribe(user => {
          if (user.length > 0) {
            this.loggedInUser = user[0];
          }
        });
      }
    });

    // this.userService.getUserById(this.currentUser.uid).valueChanges().subscribe(user => {
    //   if (user.length > 0) {
    //     this.loggedInUser = user[0];
    //   }
    // });
  }

  finish() {
    this.userService.getUserById(this.currentUser.uid).valueChanges().subscribe(user => {
      if (user.length > 0) {
        this.loggedInUser = user[0];
      }
    });
    // if (!this.SMSchecked && !this.emailChecked && !this.InappChecked) {
    //   this.noMessage = true;
    // } else {

      this.currentUser = this.authService.getCurrentUser();
      this.loggedInUser.contact = this.userContactPreference;
      this.loggedInUser.areas = this.areaGlobal;
      this.userService.updateUser(this.loggedInUser).then(res => { });
    // }
      this.toggleNotificationButton(event);
      if (this.selectedInterest.length === 0) {
        this.noSelectedInterest = true; // just to make the area of interest not required
      }

      this.loggedInUser.accountSetup = 'filled';
      this.userService.updateUser(this.loggedInUser).then(res => {
        // this.toast({html: 'User Removed.', classes: 'green'});
      });
      this.router.navigate(['/user/home']);
  }


  // isInterestClickedmethod(i){

  //     //this is to know the clicked button and change the color by taking the array and returns true or false

  //     if(this.selectedInterest.length > 0){
  //     return this.selectedInterest.includes(i);
  //   }

  // }

  // selectInterest(item){

  //   //this is to know the clicked button and change the color
  //   if(item){
  //     if(this.selectedInterest.includes(i)){
  //       var selected = this.selectedInterest.indexOf(i);
  //       this.selectedInterest.splice(selected,1)

  //     this.areaOfInterest.removeUserFromArea(item.id,this.currentUser.uid);
  //     var area = this.areaGlobal.indexOf({item,i});
  //       this.areaGlobal.splice(area,1)
  //     }
  //     else{
  //     this.selectedInterest.push(i);
  //     this.areaOfInterest.addUserToArea(item.id,this.currentUser.uid);
  //     this.areaGlobal.push({item, i});
  //     }
  //   }
  //   }

  isInterestClickedmethod(item) {
    // this is to know the clicked button and change the color by taking the array and returns true or false
    if (this.selectedInterest.length > 0) {
      return this.selectedInterest.includes(item.id);
    }
  }
  selectInterest(item) {
    // this is to know the clicked button and change the color
    if (item) {
      if (this.selectedInterest.includes(item.id)) {
        var selected = this.selectedInterest.indexOf(item.id);
        this.selectedInterest.splice(selected, 1);
        this.areaService.removeUserFromArea(item.id, this.currentUser.uid);
        this.areaGlobal = this.selectedInterest;
      }
      else {
        this.selectedInterest.push(item.id);
        this.areaService.addUserToArea(item.id, this.currentUser.uid);
        this.areaGlobal = this.selectedInterest;
      }

    }

  }

  openReloadModal(){
    this.reloadModal = true;
  }
  closeReloadModal(){
    this.reloadModal = false;
  }

  toggle() {
    this.toggleYesorNo = !this.toggleYesorNo;
  }
  toggleNotificationButton(event) {
    this.toggleNotfication = !this.toggleNotfication;
    if (event.target.checked) {
      this.loggedInUser.notification = 'on';
      // this.userService.updateUser(this.loggedInUser).then(res=>{ });
    }
    else {
      this.loggedInUser.notification = 'off';
      // this.userService.updateUser(this.loggedInUser).then(res=>{})
    }
  }
  toggleSMS(event) {
    this.SMSchecked = !this.SMSchecked;
    this.userContactPreference = this.loggedInUser.contact;
    if (event.target.checked) {
      this.userContactPreference.push('SMS');
    }
    else {
      var SMS = this.userContactPreference.indexOf('SMS'); // get  "sms" index
      this.userContactPreference.splice(SMS, 1);
    }
  }
  toggleEmail(event) {
    this.emailChecked = !this.emailChecked;
    this.userContactPreference = this.loggedInUser.contact;
    if (event.target.checked) {
      this.userContactPreference.push('Email');
    }
    else {
      var Email = this.userContactPreference.indexOf('Email'); // get  "sms" index
      this.userContactPreference.splice(Email, 1);
    }
  }
  toggleInapp(event) {
    this.InappChecked = !this.InappChecked;
    this.userContactPreference = this.loggedInUser.contact;
    if (event.target.checked) {
      this.userContactPreference.push('InApp');
    }
    else {
      var InApp = this.userContactPreference.indexOf('InApp'); // get  "sms" index
      this.userContactPreference.splice(InApp, 1);
    }
  }

  togglePrivacy() {
    this.privacyChecked = !this.privacyChecked;
  }


}


