// import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
// import { TextMaskModule } from 'angular2-text-mask';
//import { ChartsModule } from 'ng2-charts';
// import { SortablejsModule } from 'ngx-sortablejs';
import { environment } from 'src/environments/environment';
import { SharedModule } from '../shared/shared.module';
import { AppointmentsComponent } from './appointment/appointment.component';
import { EmployeeHomeComponent } from './employee-home/employee-home.component';
import { EmployeeMainComponent } from './employee-main/employee-main.component';
import { EmployeeRoutingModule } from './employee-routing.module';
import { ScheduleComponent } from '../shared/schedule/schedule.component';
import { SettingsComponent } from './settings/settings.component';
import { ServiceSettingComponent } from './service/service-setting/service-setting.component';
import { CreatEditServiceComponent } from './service/creat-edit-service/creat-edit-service.component';
import { ServiceComponent } from './service/service.component';

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ServiceProductComponent } from './service-product/service-product.component';
import { RemindersComponent } from '../admin/settings/homepage/reminders/reminders.component';
import { HomepageAppointmentsComponent } from '../admin/settings/homepage/homepage-appointments/homepage-appointments.component';
import { UserActivityComponent } from '../admin/settings/homepage/user-activity/user-activity.component';
import { AdminModule } from '../admin/admin.module';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({ declarations: [
        EmployeeHomeComponent,
        ScheduleComponent,
        AppointmentsComponent,
        EmployeeMainComponent,
        SettingsComponent,
        ServiceSettingComponent,
        CreatEditServiceComponent,
        ServiceComponent,
        ServiceProductComponent,
    ], imports: [
        CommonModule,
        BrowserModule,
        EmployeeRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        // SortablejsModule,
        SharedModule,
        AdminModule,
        DataTablesModule,
        // InputMaskModule.forRoot(),
        // TextMaskModule,
        // BsDatepickerModule.forRoot(),
        // DatepickerModule.forRoot(),
        // AngularMyDatePickerModule,
        // ChartsModule,
        // AngularMyDatePickerModule,
        // AgmCoreModule.forRoot({
        //   apiKey: environment.googleMapKey.key
        // }),
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        })], providers: [
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class EmployeeModule { }
