import { Component, OnInit } from '@angular/core';
import { AyrshareService } from 'src/app/services/social-media/ayrshare.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ProfieKeyService } from 'src/app/services/social-media/profileKey.service';
import { icons } from 'src/app/models/SocialMediaIcons';
import { analysisPlatforms } from 'src/app/models/SocialMediaPostModel';
import { PermissionService } from 'src/app/services/firebase/permission.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { CountersService } from 'src/app/services/firebase/counters.service';


@Component({
  selector: 'app-social-media-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.css']
})
export class SocialMediaPostComponent implements OnInit {


  availablePlatforms = []
  allAccountsPosts = []
  selectedAccountPosts = []
  selectedAccount

  profileKey

  hasActiveAccounts = false
  userProfile
  socialMediaIcons = icons
  mediaAnalysisPlatforms = [...analysisPlatforms]
  loading = false

  //For Permissions
  user: any;
  isAdmin: any;
  perm: any;
  permission: any;

  permMember: any;
  permSocial: any;
  permPES: any;
  permSpecial: any;
  permAnalytics: any;

  socialMediaCount: number = 0;
  adminCounter: any;
  currentDate = new Date();
  limitSM: boolean = false;
  currentUser: any;
  limitSocialMedia: boolean = false;

  constructor(
    private socialMediaService: AyrshareService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private location: Location,
    private profieKeyService: ProfieKeyService,
    private permissionService: PermissionService,
    private authService: AuthService,
    private counter: CountersService,
  ) { }

  ngOnInit() {
    this.currentUser = this.authService.getGlobalUser();
    this.getTotalNumberOfShare();

    this.mediaAnalysisPlatforms.push('youtube')

    this.user = this.authService.getGlobalUser();
    if (this.user.role == "Admin") {
      this.isAdmin = true
    }

    this.permissionService.getPermissionByEmployeeId(this.user.uid).valueChanges().subscribe(perm => {
      if (perm.length != 0) {
        this.perm = perm[0];
        //this.permission = this.perm.permission[0];
        this.permMember = this.perm.permission[0]
        this.permPES = this.perm.permission[1]
        this.permAnalytics = this.perm.permission[2];
        this.permSocial = this.perm.permission[3];
        this.permSpecial = this.perm.permission[4];
      }
    });

    this.profieKeyService.currentProfileKey.subscribe(p => {
      this.profileKey = p
    }
    )

    this.getUserProfile()

  }

  getUserProfile() {

    if (this.profileKey) {
      this.loading = true;
      this.socialMediaService.getUserByProfileKey(this.profileKey).toPromise()
        .then(data => {
          this.userProfile = data
          this.hasActiveAccounts = true ? data['activeSocialAccounts'] && data['activeSocialAccounts'].length > 0 : false
          if (this.userProfile.displayNames.some(x => x.platform == 'linkedin' && x.type == 'corporate')) this.mediaAnalysisPlatforms.push('linkedin')
          this.loading = false;

          this.getPosts()
        })
        .catch(err => {

          this.loading = false;


        })


    }


  }
  getPosts() {
    if (this.profileKey) {

      this.socialMediaService.getHistoryByProfileKey(this.profileKey).toPromise().then(hist => {
        this.allAccountsPosts = <Array<any>>hist


        // if (this.allAccountsPosts.length == 0){
        //   this.router.navigate(['../create-post'],
        //   {
        //       relativeTo: this.activatedRoute
        //   }
        //   )
        // }
        this.allAccountsPosts.forEach(post => {
          post.platforms.map(platform => {
            if (!this.availablePlatforms.includes(platform)) this.availablePlatforms.push(platform)
          })
        })

        this.selectedAccountPosts = this.allAccountsPosts


      }).catch(err => {

        this.allAccountsPosts = []
        this.selectedAccountPosts = []
        // History not found
        // if(err.error.code == 221){
        //   this.router.navigate(['../create-post'],
        //   {
        //       relativeTo: this.activatedRoute
        //   }
        //   )
        // }
      })
    }
  }

  getUserMediaPlatforms(post) {
    return post.platforms.filter(s => {
      return this.mediaAnalysisPlatforms.includes(s)
    })
  }

  imageMedia(url) {

    url = new URL(url)

    let urlSplit = url.pathname.split('/')
    let media = urlSplit[3]

    if ((url.host == "images.ayrshare.com") || ["images", 'image'].includes(media.toLowerCase())) {
      return true
    } else {
      return false
    }
  }


  selectPlatform(account) {

    this.selectedAccount = account
    this.selectedAccountPosts = this.allAccountsPosts.filter(p => (<Array<any>>p['platforms']).includes(this.selectedAccount))

  }

  getTotalNumberOfShare() {
    // Get the current month
    const currentMonth = this.currentDate.getMonth();

    // Move to the next day
    const nextDay = new Date(this.currentDate);
    nextDay.setDate(this.currentDate.getDate() + 1);

    // Check if the next day is in a different month
    const isNewMonth = nextDay.getMonth() !== currentMonth;

    this.counter.getCounter().valueChanges().subscribe((counterAdmin) => {
      if (counterAdmin && counterAdmin.length != 0) {
        this.adminCounter = counterAdmin[0];
        this.socialMediaCount = this.adminCounter.socialMediaCount ? this.adminCounter.socialMediaCount : 0;

        if (isNewMonth) {
          this.socialMediaCount = 0;

        } else {
          if (this.socialMediaCount <= 100) {
            this.socialMediaCount = this.adminCounter.socialMediaCount;
          }
          else if (this.socialMediaCount > 100) {
            this.limitSocialMedia = true;
          }

        }
      }
      else {
        const counter = {
          id: '',
          SMSTotalCounter: 0,
          emailTotalCounter: 0,
          socialMediaCount: 0,
          chatGptCounter: 0,
        }
        this.counter.saveCounter(counter);
      }

    })
  }
}
