<!-- Modal -->
<app-modal-box [isOpen]="true" size="extraLarge" (close)="closeMoveDialog()" title="Rearrange items(Drag and drop items)">

    <h3 class="font-bold text-lg m-5 text-center"></h3>
    <div *ngIf="elementsList.length ==0">
      <app-empty-page (newElement)="newElement()"></app-empty-page> 
    </div>
    <div cdkDropListGroup *ngIf="elementsList.length !=0">
      <div cdkDropList class="example-list z-[3000]" (cdkDropListDropped)="drop($event)">
        <ng-container *ngFor="let elt of elementsList;" class="  ">
          <div cdkDrag class="example-box  z-[3000]">
            <div class="example-custom-placeholder flex" *cdkDragPlaceholder></div>
            <div *ngIf="elementsList.length ==0">
              <app-empty-page></app-empty-page> 
              {{elementsList.length}}
            </div>
            <!-- Order -->
            <div class="w-1/12">
              <span *ngIf="elt.position|| elt.position==0">{{elt.position+1 }}</span> 
            </div>
            <ng-container *ngIf="(sectionType!='FAQ')">
            <!-- Images and video -->
            <div *ngIf="elt.videoLink || elt.imgLink || elt.mediaLink" class="w-11/12 md:w-4/12" >
              <div *ngIf="!elt.videoLink || elt.videoLink==''">
                <div >
                  <div class="flex justify-center   " *ngIf="elt.imgLink">
                    <img [src]="elt.imgLink" class="md:w-56 h-full w-full rounded-lg md:object-contain" >
                  </div>
                  <div class="flex justify-center " *ngIf="elt.mediaLink">
                    <img [src]="elt.mediaLink" class="md:w-56 h-full w-full rounded-lg md:object-contain" >
                  </div>
                </div>
              </div>
              <div *ngIf="elt.videoLink && elt.videoLink!=''">
                <div >
                  <ng-container *ngIf="!elt.imgLink || !elt.mediaLink">
                    <video controls *ngIf="elt.videoLink != ''" [src]="elt.videoLink" class="object-cover" alt="Video"></video>
                  </ng-container>
                  <ng-container *ngIf="elt.imgLink || elt.mediaLink">
                    <video controls *ngIf="elt.videoLink != ''&& elt.imgLink" [src]="elt.videoLink" class="object-cover" alt="elt Image" [poster]="elt.imgLink"></video>
                    <video controls *ngIf="elt.videoLink != ''&& elt.mediaLink" [src]="elt.videoLink" class="object-cover" alt="elt Image" [poster]="elt.mediaLink"></video>
                  </ng-container>
                </div>
              </div>
            </div>
           
            <!-- Titles -->
            <div class="w-full md:w-7/12 p-5">
              <ng-container *ngIf="sectionType=='Posts'">
                {{truncateHTML(getInnerText(elt.postTitle),40)}}
                {{elt.timeStamp.toDate() | date: 'MMM.dd.yyyy'}}
              </ng-container>
              <ng-container *ngIf="sectionType=='Events'">
                {{truncateHTML(getInnerText(elt.eventTitle),40)}}
                <div>{{elt.eventStartDate | date: 'YYYY-MMM-dd'}}</div>
              </ng-container>
              <ng-container *ngIf="sectionType=='Services'">
                {{truncateHTML(getInnerText(elt.serviceName),40)}}
                {{truncateHTML(getInnerText(elt.serviceDescription),80)}}
              </ng-container>
              <ng-container *ngIf="sectionType=='Products'">
                {{truncateHTML(getInnerText(elt.productName),40)}}
                {{truncateHTML(getInnerText(elt.productDescription),80)}}
              </ng-container>
              <ng-container *ngIf="sectionType=='CustomSectionElements'">
                {{truncateHTML(getInnerText(elt.title),40)}}
                {{truncateHTML(getInnerText(elt.description),80)}}
              </ng-container>
            </div>
          </ng-container>
          <!-- faq -->
          <ng-container *ngIf="sectionType=='FAQ'">
            <div class="w-11/12">
              {{elt.question}}
            </div>
          </ng-container>
    

          </div>
        </ng-container>
      </div>
    </div>

    <div class="modal-action justify-between  bottom-0 left-0 w-full p-4 bg-gray-100 " *ngIf="elementsList.length !=0">
        <!-- Close Button --> 
        <button
        type="button"
        class="py-2 px-4 text-red-500 hover:bg-red-500 hover:text-white  border-customTeal rounded-md"
        (click)="closeMoveDialog()"
        >
        Close
      </button>
      <button
        type="button"
        class="btn-custom-solid"
        (click)="saveSettings()"
        >
        Save changes
      </button>
      
    </div>

  </app-modal-box> 
