<div class="text-sm breadcrumbs">
  <ul>
    <li><a [routerLink]="['/admin/dashboard']">Home</a></li>
    <li ><a [routerLink]="['/admin/membership']">Membership</a></li>
    <li><strong>Employee List</strong></li>
  </ul>
</div>
<!-- <div class="">
  <br/>
    <h6 class="text-center"><strong> Employee List </strong></h6>
</div> -->

<div class="">


    <app-firebase-loading *ngIf="loading"></app-firebase-loading>

    <div class="overflow-x-auto  flex" *ngIf="employees && employees.length>0">
      <div class="w-1/12"></div>
      <table class="table table-normal table-zebra w-10/12">
        <thead>
          <tr>
              <th>Employee Name</th>
              <th *ngIf="hasAppointment">Schedule</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let e of employees" >
            <td class=""><a [routerLink]="['/admin/employee-appointment/', e.uid]"> {{ e.GivenName}} {{ e.MiddleName}}  {{ e.LastName}}</a></td>
            <td class="" *ngIf="hasAppointment"><a class="btn btn-small blue " [routerLink]="['/admin/employee-appointment/', e.uid]"> <i class="material-icons white-text">calendar_month</i> </a></td>
          </tr>
        </tbody>
      </table>

    </div>

    <div *ngIf="!employees" class="card bg-blue-300">
      <h6  class="text-white card-body">
        <strong>We didnt find any employees in the database.</strong>
      </h6>
    </div>

<!-- <div class="section"></div>
      <a class="col s12 btn waves-effect " (click)="backClicked()"><i
        class="material-icons left">house</i> Back To Home Page</a>

 -->
</div>

