import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AuthService } from 'src/app/services/firebase/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserNotesService {

  constructor(private db: AngularFirestore) { }

  postsListRef: AngularFirestoreCollection<any>;
  postRef: AngularFirestoreDocument<any>;

  saveNotes(model: any){
   // const newContent = {
      model.id = this.db.createId();
      // Message: model.Message,
      // timeStamp: model.timestamp ? model.timeStamp : new Date(),
      // userId: model.uid,
      // reminder: model.reminder ? model.reminder : '',
      // archived: model.archived? model.archived : false,
      // reminderTime: model.reminderTime ? model.reminderTime : '08:00',
      // noteMadeBy: model.noteMadeBy? model.noteMadeBy : ''
    //};
    return this.db.collection('UserNotes').doc(model.id).set(model);
  }
  getNotesByUserId(userId){
    return this.db.collection('UserNotes', ref => ref.where('userId', '==', userId));
  }
  getNotesByAdminId(adminId){
    return this.db.collection('UserNotes', ref => ref.where('noteMadeBy', '==', adminId));
  }

  getNotesByArchivedUserId(userId){
    return this.db.collection('UserNotes', ref => ref.where('userId', '==', userId).where('archived', '==', true));
  }
  getNotesByUnArchivedUserId(userId){
    return this.db.collection('UserNotes', ref => ref.where('userId', '==', userId).where('archived', '==', false));
  }

  getNotesArchived(){
    return this.db.collection('UserNotes', ref => ref.where('archived', '==', true));
  }
  getNotesUnArchived(){
    return this.db.collection('UserNotes', ref => ref.where('archived', '==', false));
  }
  getNotes(){
    return this.db.collection('UserNotes');
  }

  getNoteById(noteId: any){
    return this.db.collection('UserNotes', ref => ref.where('id', '==', noteId));
  }

  updateNote(note){
    return this.db.collection('UserNotes').doc(note.id).update(note);
  }
  updateNoteById(noteId){
    return this.db.collection('UserNotes').doc(noteId).update(ref => ref.where('id', '==', noteId));
  }
  deleteNote(note: any){
    return this.db.collection('UserNotes').doc(note.id).delete();
  }

  deleteNoteById(noteId: any){
    return this.db.collection('UserNotes').doc(noteId).delete();
  }

}
