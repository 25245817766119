import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { AppinfoService } from 'src/app/services/backend-automation/appinfo.service';
import * as CryptoJs from 'crypto-js'
import { AES } from 'crypto-js'


@Component({
  selector: 'app-email-apppassword-guide',
  templateUrl: './email-apppassword-guide.component.html',
  styleUrls: ['./email-apppassword-guide.component.css']
})
export class EmailApppasswordGuideComponent implements OnInit {

  secretKey: any = "zLeCHJYKf25WENP";

  step1:boolean = true;
  step2:boolean = false;
  step3:boolean = false;
  step4: boolean = false;
  step5: boolean = false;
  finalStep:boolean = false;

  toastClass: any;
  toastMessage: any;
  toastType: any;
  openToast: boolean;


  customEmailSmtpForm: FormGroup;
  emailVal:any;
  appPasswordVal:any;

  emailPassSetup: any;
  listOfEmails:any = []
  emailPage: number = 1;

  emailSetup:boolean = false;
  defaultEmail:any = [];

  makeButDefault: boolean = false;


  constructor(private fb: FormBuilder,
    private appInfoService: AppinfoService) { }

  ngOnInit(): void {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    this.customEmailSmtpForm = this.fb.group({
      email: ['', [Validators.required], this.customValidator("emailName", emailRegex)],
      appPassword:['', [Validators.required]]
    })

    this.appInfoService.getEmailPassSetting().valueChanges().subscribe((response:any) => {
      if (response.length > 0) {
       
        this.emailPassSetup = response[0].setup;
        this.listOfEmails = response[0].emailPasswordPairs;
      }
    });

    this.appInfoService.getDefaultEmail().valueChanges().subscribe((response) => {
      if (response.length > 0) {
        this.defaultEmail = response[0];
        // var account = this.listOfBillingAccounts.filter(account => account["name"] === this.defaultBillingAccount["billingAccount"]);
        // this.defaultBillingAccount = account[0].name;
      }
    });

  }

  get email() { return this.customEmailSmtpForm.get('email'); }
  get appPassword() { return this.customEmailSmtpForm.get('appPassword')}


  // private customValidator(controlName: string, pattern: RegExp) {
  //   return (formControl: FormControl) => {
  //     const controlValue = formControl.value;
  //     if (controlValue && !pattern.test(controlValue.toLowerCase())) {
  //       return { [controlName]: true };
  //     }
  //     return null;
  //   };
  // }

  private customValidator(controlName: string, pattern: RegExp): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return new Promise(resolve => {
        const controlValue = control.value;
        if (controlValue && !pattern.test(controlValue.toLowerCase())) {
          resolve({ [controlName]: true });
        } else {
          resolve(null);
        }
      });
    };
  }
  

  incorrectEmail(){
    return this.email.invalid
  }

  next_step1(){
    this.step1 = false  
    this.step2 = true
    this.emailVal = this.customEmailSmtpForm.get('email')?.value;
  }

  addCustomEmail(model){
  }

  next_step2(){
    this.step2 = false  
    this.step3 = true
    // this.emailVal = this.customEmailSmtpForm.get('email')?.value;
  }

  next_step3(){
    this.step3 = false
    this.step4 = true
  }

  next_step4(){
    this.step4 = false
    this.step5 = true
  }

  completeSetup(){
    this.step5 = false
    this.finalStep = true
    this.emailSetup = false;


    this.appPasswordVal = this.customEmailSmtpForm.get('appPassword')?.value;
    // encrypt password
    const encodedPass = CryptoJs.AES.encrypt(this.appPasswordVal, this.secretKey).toString();
    const decryptedPassword = AES.decrypt(encodedPass, this.secretKey).toString(CryptoJs.enc.Utf8)
    this.appInfoService.emailPassSetting(this.emailVal, encodedPass).add(() => {
      this.toast({ html: 'Email password setup successfull! ', classes: 'green', type: 'success' });
    })

  }

  toast(obj) {
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green';
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
    }, 2000);
  }

  navigateToEmailSetup(){
    this.emailSetup = true;
  }

  makeItDefault(email: any | string) {
    this.appInfoService.updateDefaultEmail(email).add(() => {
      this.toast({ html: 'Default account saved successfully! ', classes: 'green', type: 'success' });

    })

  }

  isDefault(email: any | string) {
    if (this.defaultEmail["email"] === email) {
      this.makeButDefault = true;
      return true;
    } else {
      this.makeButDefault = false;
      return false;
    }
  }

}
