import { Component, Input, OnInit } from '@angular/core';
import { HelperService } from 'src/app/services/helper/helper';

@Component({
  selector: 'app-custom-list-minimal',
  templateUrl: './custom-list-minimal.component.html',
  styleUrls: ['./custom-list-minimal.component.css']
})
export class CustomListMinimalComponent implements OnInit {

  @Input() sections;

  constructor(
    private helperServices: HelperService
  ) { }

  ngOnInit(): void {
  }
  truncateHTML(text: any, limit): string {
    return this.helperServices.truncateHTML(text, limit);
   }

   getInnerText(text){
    return this.helperServices.getInnerText(text);
  }

}
