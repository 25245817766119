<div class="w-full min-h-screen pt-6 font-body max-h-max">
    <form [formGroup]="skillsForm" (ngSubmit)="submitQuestions(skillsForm.value)">

        <div class="mt-4 ml-8 text-xl font-bold text-customTeal md:ml-40 md:mt-0">Customize Clients Information</div>
        <div class="flex flex-col p-4 mt-2 space-y-4 bg-white rounded-lg md:w-4/6 h-max md:p-12 md:translate-x-1/4">
            <p class="flex items-center gap-2 text-xs text-customTeal">
                <i class="text-sm text-white rounded-full bg-customTeal material-icons h-fit">add</i>
                If you have not specified the field name to be used during the import of contacts, this icon will be
                displayed
            </p>
            <div class="flex my-3 mb-8 space-x-10">
                <div class="grid w-full grid-cols-2 gap-3 px-4 md:px-0 ">
                    <div class="flex flex-col md:space-y-2 md:w-full " *ngFor="let field of baseFields; let i= index">
                        <label for=" " class="flex items-center gap-1 text-xs font-bold text-gray-500">{{field?.name}}
                            <span class="text-red-500 ">*</span>
                            <!--
                                 <i class="text-sm text-white rounded-sm bg-customTeal material-icons"
                                *ngIf="!field?.active"
                                (click)="openModal(field)">add</i>
                            <i class="text-sm text-white rounded-sm bg-customTeal material-icons"
                                *ngIf="field?.active"
                                (click)="openEditModal(field)">edit</i>
                            -->
                           
                        </label>
                        <input maxlength="60" class="w-full bg-gray-100 input input-bordered input-primary md:w-full"
                            [type]="field?.type" [placeholder]="field?.placeholder">
                    </div>



                    <!--
                    <div class="flex flex-col mt-2 md:flex-row md:space-x-10">
                        <div class="flex flex-col md:space-y-2 md:w-full">
                            <label for=" " class="text-xs font-bold text-gray-500 "> Phone Number <span
                                    class="text-red-500">*</span>
                                <i class="text-sm text-white bg-red-500 rounded-full material-icons"
                                    *ngIf="!checkFieldExistance('Phone Number')"
                                    (click)="openModal('Phone Number','phone', 'phone')">add</i>
                            </label>
                            <input type="text" [textMask]="{mask:phoneNumberMask}" id='cellPhone'
                                class="w-full bg-gray-100 input input-bordered input-primary md:w-full"
                                placeholder="000-000-0000" />
                        </div>

                        <div class="flex flex-col md:space-y-2 md:w-full ">
                            <label for=" " class="text-xs font-bold text-gray-500 "> Email
                                <i class="text-sm text-white bg-red-500 rounded-full material-icons"
                                    *ngIf="!checkFieldExistance('Email')"
                                    (click)="openModal('Email','email', 'email')">add</i>
                            </label>
                            <input class="w-full bg-gray-100 input input-bordered input-primary md:w-full" type="text"
                                placeholder="johnjane@gmail.com">
                        </div>
                    </div>
                  -->

                </div>
                <!-- <hr class="bg-green-500 border-4"/> -->
            </div>
            <hr />

            <div formArrayName="skills">
                <div *ngFor="let skill of skills.controls; let i=index">
                    <div [formGroupName]="i">

                        <!-- <div class="flex justify-between" *ngIf="i > 0">
                            <p></p>
                            <p class="w-12 px-3 py-3 rounded-full md:mr-4 bg-customTeal" (click)="removeFile(i)"><i
                                    class="text-white material-icons ">delete</i></p>
                        </div> -->
                        <div class="flex justify-end" *ngIf="i >= 0">
                            <!-- <p></p> -->
                            <p class="px-3 py-2 bg-red-500 rounded-full cursor-pointer md:pr-2 md:mt-8"
                                (click)="openDeleteModal(i)"><i class="text-white material-icons ">delete</i></p>
                        </div>
                        <div class="flex my-3 space-x-10 gap-x-3">

                            <div
                                class="flex flex-col w-full px-4 space-y-4 md:flex-row md:px-0 md:space-x-8 md:space-y-0">

                                <!-- <div class="flex space-x-2"> -->
                                <div class="flex flex-col mt-1 space-y-2 md:w-1/2 ">
                                    <label class="text-sm font-bold">Field {{i+1}}: <span class="text-red-500">*</span>
                                    </label>
                                    <input
                                        class="px-3 py-3 leading-tight text-gray-700 bg-gray-100 border rounded focus:outline-gray-200 focus:shadow-outline "
                                        id="question " type="text " placeholder="Field Name" formControlName="question">
                                    <div *ngIf="(skill.controls.question.dirty ||
                                                        skill.controls.question.touched) &&
                                                        skill.controls.question.invalid">
                                        <small *ngIf="skill.controls.question.errors.required" class="text-red-500">
                                            The
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                                <br />

                                <div class="flex flex-col mt-1 mb-2 space-y-2 md:w-1/2 ">
                                    <label class="text-sm font-bold">Field Type: <span class="text-red-500">*</span>
                                    </label>
                                    <select formControlName="answerType" required
                                        class="w-full px-4 py-3 bg-gray-100 validate select select-bordered bg-veryLightGray"
                                        (change)="answerTypeChange($event.target.value, i)">
                                        <option value="" disabled selected>Please Select Answer Type</option>
                                        <option *ngFor="let answer of answerTypes" [value]="answer.type"
                                            title="Tooltip">
                                            {{answer.type}}
                                            <span class="text-green-400"> ( {{answer.helpText}} )</span>
                                        </option>
                                    </select>

                                    <div *ngIf="(skill.controls.answerType.dirty ||
                                                                    skill.controls.answerType.touched) &&
                                                                    skill.controls.answerType.invalid">
                                        <small *ngIf="skill.controls.answerType.errors.required" class="text-red-500">
                                            The
                                            {{answer.question}} is required.
                                        </small>
                                    </div>



                                    <div *ngIf="skill.controls.answerType.value === 'Multiple Choice'">

                                        <div class="row input-field col s12 m5 right">
                                            <label class="flex justify-end">
                                                <input class="flex justify-end checkbox checked:bg-customTeal"
                                                    type="checkbox" formControlName="allowMultipleAnswers" />
                                                <span>Allow Multiple Answers</span>
                                            </label>
                                        </div>
                                        <br />
                                        <div class="row input-field col s12" formArrayName="answers"
                                            *ngFor="let ans of getAnswers(i).controls; let j = index;">
                                            <div [formGroupName]="j">

                                                <div class="row input-field col s11">
                                                    <span class="text-sm font-bold">Choice {{j+1}}: </span>
                                                    <input id="answer" type="text" formControlName="answer" required
                                                        class="px-3 py-3 mb-2 leading-tight text-gray-700 bg-gray-100 border rounded validate focus:outline-gray-200 focus:shadow-outline "
                                                        autocomplete="off" style="text-transform: capitalize;"
                                                        placeholder="Answer" />
                                                    <a *ngIf="j > 1"
                                                        class="cursor-pointer btn-flat waves-effect waves-light col s1 right"><i
                                                            class="text-red-500 material-icons"
                                                            (click)="openMultiDeleteModal(i, j)">delete</i></a>
                                                    <div
                                                        *ngIf="(ans.controls.answer.dirty || ans.controls.answer.touched) && ans.controls.answer.invalid">
                                                        <small *ngIf="ans.controls.answer.errors.required"
                                                            class="text-red-500">
                                                            Answer {{j+1}} is
                                                            required.</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row col s12">
                                            <button type="button" class="px-4 py-2 mb-4 rounded-md bg-customTeal"
                                                (click)="onAddAnswer(i)"
                                                [disabled]="!skillsForm.get('skills').controls[i].get('answers').valid"
                                                style="cursor: pointer;">
                                                <i class="float-left text-white material-icons">add_circle</i><span
                                                    class="text-white">Add
                                                    Option</span>
                                            </button>
                                        </div>
                                    </div>




                                </div>

                            </div>
                        </div>

                        <!-- <div class="flex justify-end" *ngIf="i >= 0">
                               <p class="px-3 py-2 bg-red-500 rounded-full md:pr-2 md:mt-8" (click)="removeFile(i)"><i
                                        class="text-white material-icons ">delete</i></p>
                            </div> -->



                        <div class="flex justify-end mb-4">
                            <input [checked]="skill.controls.required.value" id="checked-checkbox" type="checkbox"
                                value="" (click)="toggleRequired($event)" formControlName="required"
                                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <label class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"> Required</label>
                        </div>

                    </div>
                    <hr />
                </div>
            </div>

            <!-- <div class="flex justify-end">
                <button type="button" (click)="addQuestion()" [disabled]="!skillsForm.valid"
                    class="flex px-4 py-2 mt-2 space-x-2 text-sm font-bold border-2 rounded-lg border-customTeal md:px-6 text-customTeal w-max h-min md:text-base focus:outline-none ">
                    <i class="material-icons text-customTeal">add_circle</i><span>Add Field</span>
                </button>
            </div> -->


            <div class="flex justify-between pt-6 space-y-2">
                <div class="">
                    <button type="button" (click)="addQuestion()" [disabled]="!skillsForm.valid"
                        class="flex px-4 py-2 mt-2 space-x-2 text-sm font-bold border-2 rounded-lg border-customTeal md:px-6 text-customTeal w-max h-min md:text-base focus:outline-none ">
                        <i class="material-icons text-customTeal">add_circle</i><span>Add Field</span>
                    </button>
                </div>
                <div class="">
                    <label (click)="openUpdate()"
                        class="flex px-4 py-2 space-x-2 text-sm font-bold text-white rounded-lg md:px-6 bg-customTeal w-max h-min md:text-base hover:bg-darkBlue focus:outline-none ">
                        <span>Update Form</span>
                    </label>
                </div>
            </div>

        </div>
        <div id="my-modal-disable" *ngIf="updateFormPopup" class="modal"
            [ngClass]="{'modal-open': updateFormPopup, 'modal-action': !updateFormPopup }">
            <div class="modal-box">
                <div class="modal-content">
                    <div (click)="cancelUpdate()" class="flex justify-end cursor-pointer"><i
                            class="px-3 py-3 bg-gray-200 rounded-full material-icons">close</i></div>
                    <h3 class="mb-2 text-lg font-bold text-center">Update Form</h3>
                    <hr />
                    <p class="mt-3 text-center">Are you sure you want to Customize the Client Information Form?</p>

                    <div class="items-center justify-between m-4 modal-action">
                        <label (click)="cancelUpdate()"
                            class="px-4 py-2 text-white rounded-md bg-customTeal border-customTeal ">Cancel</label>

                        <button type="submit" [disabled]="!skillsForm.valid"
                            class="px-4 py-2 border-2 rounded-md text-customTeal border-customTeal ">Update
                            Form</button>
                    </div>
                </div>
            </div>
        </div>


        <!-- <app-modal modalTitle="Update Form" btnName="Update Form" *ngIf="updateFormPopup"
    (cancelBtnClicked)="updateFormPopup=false" (btnClicked)="resetPassword($event)">
    <hr />
    <p class="mt-3 text-center">Are you sure you want to Customize the Client Information Form?</p>
</app-modal> -->

        <div id="my-modal-reset" class="modal" *ngIf="deleteQuestion"
            [ngClass]="{'modal-open': deleteQuestion, 'modal-action': !deleteQuestion }">

            <div class="modal-box">
                <div class="modal-content">
                    <h3 class="mb-2 text-lg font-bold text-center">Delete Question</h3>
                    <p class="mt-3 mb-2 text-center">Are you sure you want to delete this question?</p>
                    <p class="mt-3 mb-2 text-center">Question deleted wont appear on the client information profile </p>
                    <hr />
                    <div class="items-center justify-between m-4 modal-action">
                        <label for="my-modal-department" (click)="closeDeleteModal()"
                            class="font-bold text-red-500">Cancel</label>
                        {{i}}
                        <label for="my-model"><button class="px-4 py-1 text-white border-none rounded-md bg-customTeal"
                                type="button" (click)="removeQuestion(i)">Yes</button></label>
                    </div>
                </div>
            </div>
        </div>

        <div id="my-modal-reset" class="modal" *ngIf="deleteMultipleQuestion"
            [ngClass]="{'modal-open': deleteMultipleQuestion, 'modal-action': !deleteMultipleQuestion }">

            <div class="modal-box">
                <div class="modal-content">
                    <h3 class="mb-2 text-lg font-bold text-center">Delete Multiple Choice</h3>
                    <p class="mt-3 mb-2 text-center">Are you sure you want to delete this Choice?</p>
                    <hr />
                    <div class="items-center justify-between m-4 modal-action">
                        <label for="my-modal-department" (click)="closeMultiDeleteModal()"
                            class="font-bold text-red-500">Cancel</label>
                        {{j}}
                        <label for="my-model"><button class="px-4 py-1 text-white border-none rounded-md bg-customTeal"
                                type="button" (click)="removeAnswers()">Yes</button></label>
                    </div>
                </div>
            </div>
        </div>

    </form>

</div>

<app-alert class="fixed top-0 flex justify-center w-full" [toastType]="toastType" [toastMessage]="toastMessage"
    [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>

<app-modal [modalTitle]="modalTitle" btnName="Yes" *ngIf="showModal" (cancelBtnClicked)="closeModal()"
    (btnClicked)="submitFieldType()">
    <hr />
    <!--<p class="mb-1 text-center text-md">Add field name for <span class="font-semibold text-green-500"> {{selectedField?.label}}</span>
    </p>-->
    <div class="flex flex-col md:space-y-2 md:w-full ">
        <label for=" " class="text-xs font-bold text-gray-500 "> Field Name
        </label>
        <input class="w-full bg-gray-100 input input-bordered input-primary md:w-full" type="text"
            placeholder="fieldName" [(ngModel)]="formFieldName">
    </div>

</app-modal>