<div *ngIf="authenticate">
  <div class="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl m-5">
    <div class="md:flex">
      <div class="md:shrink-0">
        <img
          loading="lazy"

          class="h-48 w-full object-cover md:h-full md:w-48"
                      
          data-src="https://api.goingmobile.app/media/personal-app-assets/img/webp/googleconsentscreen.webp"
                      
          srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/googleconsentscreen.webp"
                      
          fallback="https://api.goingmobile.app/media/personal-app-assets/img/png/googleconsentscreen.png"
                      
          alt="Google Consent Screen Image.">
      </div>
      <div class="p-8">
        <div class="uppercase tracking-wide text-sm text-indigo-500 font-semibold">Step 1</div>
        <p class="mt-2 text-slate-500">
          Authenticate and give permission to create an analytics account from this app by
          selecting the admin Google account from the list that will pop up.
          <br /><br />
          The window will close automatically after few minutes once you've selected the account.
        </p>
      </div>
    </div>

    <div *ngIf="!step1" class="flex justify-center m-5 ">
      <button (click)="oauth2()" type="button"
        class="inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
        data-te-ripple-init data-te-ripple-color="light" style="align-self: center;">
        Authenticate
      </button>
    </div>
  </div>

  <div *ngIf="step1" class="flex justify-center m-5 ">
    <button (click)="next_step1()" type="button"
      class="inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
      data-te-ripple-init data-te-ripple-color="light" style="align-self: center;">
      Next
    </button>
  </div>
</div>



<div *ngIf="analyticsCreation">
  <div class="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl m-5">
    <div class="md:flex">
      <div class="md:shrink-0">
        <img
          loading="lazy"

          class="h-48 w-full object-cover md:h-full md:w-48"
                      
          data-src="https://api.goingmobile.app/media/personal-app-assets/img/webp/Google-Analytics-Logo-Transparent.webp"
                      
          srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/Google-Analytics-Logo-Transparent.webp"
                      
          fallback="https://api.goingmobile.app/media/personal-app-assets/img/png/Google-Analytics-Logo-Transparent.png"
                      
          alt="Google Analytics Logo.">

      </div>
      <div class="p-8">
        <div class="uppercase tracking-wide text-sm text-indigo-500 font-semibold">Step 2</div>

        <div class="w-full md:full">
          <form [formGroup]="createAnalyticsForm" (ngSubmit)="createAnalyticsAccount(createAnalyticsForm.value)">


            <div class="flex flex-col gap-y-2">
              <div class="">
                <span class="label-text text-[#4F4F4F] text-base font-bold leading-5">Analytics Name<span
                    class="text-red-500">*</span></span>
                <div class="input-field flex md:1/2 w-full">
                  <input id="analyticsName" type="text" formControlName='analyticsName' required
                    class="input input-bordered w-full md:w-full" autocomplete="off" />

                </div>
                <div class="w-full" *ngIf="(analyticsName?.dirty || analyticsName?.touched) && analyticsName?.invalid">
                  <small *ngIf="analyticsName?.errors.required" class="text-red-500 italic">Analytics name is required.
                  </small>
                  <small *ngIf="analyticsName?.hasError('accExists')" class="text-red-500 italic">Analytics name is used
                    try another.
                  </small>
                  <div *ngIf="analyticsName?.hasError('accName')" class="text-red-500 italic">
                    Should start with a letter, allowed inputs are lowercase letters,<br />
                    digit hyphen or hyphen and should have a total length of 2 chars.
                  </div>
                </div>
              </div>
              <!-- Message -->

              <!-- ----------- -->
              <div class="flex flex-wrap">
                <div class="w-full md:w-1/2 mb-6 md:mb-0">
                  <label class="label">
                    <span class="label-text text-[#4F4F4F] text-base font-bold leading-5">Region<span
                        class="text-red-500">*</span></span>
                  </label>
                  <select formControlName="region" required
                    class="validate py-3 px-5 select select-bordered select-gray-600 select-text-gray-800"
                    (change)="selectRegion($event.target.value)">
                    <option value="" disabled selected>Please Select Region.</option>
                    <option *ngFor="let option of options" [value]="option.value">{{ option.label }}</option>
                  </select>

                  <div class="input-field flex md:1/2 w-full py-5">
                    <div class="w-full">
                      <div *ngIf="(region.dirty || region.touched) && region.invalid">
                        <small class="text-red-500 italic">Region is required. </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- ___________ -->

            </div>

            <br /><br />

            <button *ngIf="!step2" type="submit " [disabled]="!createAnalyticsForm.valid"
              [ngClass]="{'!bg-[#cccccc] !text-[#666666]': !createAnalyticsForm.valid}" href="#"
              class="inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              data-te-ripple-init data-te-ripple-color="light">
              Create Account
            </button>

          </form>
        </div>

      </div>
    </div>
  </div>

  <div *ngIf="step2" class="flex justify-center m-5 ">
    <button (click)="next_step2()" type="button"
      class="inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
      data-te-ripple-init data-te-ripple-color="light" style="align-self: center;">
      Next
    </button>
  </div>
</div>


<div *ngIf="tos">
  <div class="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl m-5">
    <div class="md:flex">
      <div class="md:shrink-0">
        <img
        loading="lazy"

        class="h-48 w-full object-cover md:h-full md:w-48"
                    
        data-src="https://api.goingmobile.app/media/personal-app-assets/img/webp/create-account-terms-of-service.png.webp"
                    
        srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/create-account-terms-of-service.png.webp"
                                        
        alt="Google Consent Screen Image.">

      </div>
      <div class="p-8">
        <div class="uppercase tracking-wide text-sm text-indigo-500 font-semibold">Step 3</div>
        <p class="mt-2 text-slate-500">
          Click on "Go to TOS" to open the analytics usage terms of service agreement window.
          Tick the agreement and click "Accept" to proceed to the final step.
          <br /><br />
          Once you have completed the agreement, you can close the window and move on to the next step.
        </p>
      </div>
    </div>

    <div *ngIf="!step3" class="flex justify-center m-5 ">
      <button (click)="gototos()" type="button"
        class="inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
        data-te-ripple-init data-te-ripple-color="light" style="align-self: center;">
        Go To TOS
      </button>
    </div>
  </div>

  <div *ngIf="step3" class="flex justify-center m-5 ">
    <button (click)="next_step3()" type="button"
      class="inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
      data-te-ripple-init data-te-ripple-color="light" style="align-self: center;">
      Next
    </button>
  </div>
</div>


<div *ngIf="final">
  <div class="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl m-5">
    <div class="md:flex">
      <div class="md:shrink-0">
        <img
        loading="lazy"

        class="h-48 w-full object-cover md:h-full md:w-48"
                    
        data-src="https://api.goingmobile.app/media/personal-app-assets/img/webp/finish.webp"
                    
        srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/finish.webp"
                    
        fallback="https://api.goingmobile.app/media/personal-app-assets/img/png/finish.png"
                    
        alt="Google Consent Screen Image.">

      </div>
      <div class="p-8">
        <div class="uppercase tracking-wide text-sm text-indigo-500 font-semibold">Step 4</div>
        <p class="mt-2 text-slate-500"> This step will connect it to the backend configuration, so that you can be able
          to view and make default the created account.
        </p>
      </div>
    </div>

    <div *ngIf="!step4" class="flex justify-center m-5 ">
      <button (click)="complete()" type="button"
        class="inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
        data-te-ripple-init data-te-ripple-color="light" style="align-self: center;">
        Finish
      </button>
    </div>
  </div>

</div>

<div *ngIf="congra">
  <div class="max-w-md mx-auto bg-neutral-600	 rounded-xl shadow-md overflow-hidden md:max-w-3xl m-5">
    <img
        loading="lazy"
         
        class="h-auto max-w-full"
                    
        data-src="https://api.goingmobile.app/media/personal-app-assets/img/webp/congratulations_message_tips_and_tricks.webp"
                    
        srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/congratulations_message_tips_and_tricks.webp"
                    
        fallback="https://api.goingmobile.app/media/personal-app-assets/img/png/congratulations_message_tips_and_tricks.png"
                    
        alt="congratulations message image.">

  </div>

  <div class="flex justify-center m-5 ">
    <button (click)="back()" type="button"
      class="inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
      data-te-ripple-init data-te-ripple-color="light" style="align-self: center;">
      Back
    </button>
  </div>
</div>





<app-alert class="fixed top-0 flex justify-center w-full" [toastType]="toastType" [toastMessage]="toastMessage"
  [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>