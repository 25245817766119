<div class="px-10">
  <div
    class="flex-col space-x-2 md:space-x-4 rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-2 md:p-4 dark:border-opacity-50"
  >
    <div class="flex flex-shrink-0 items-center justify-between">
      <!--Modal title-->
      <h5
        class="text-xl font-medium leading-normal text-neutral-800 dark:text-neutral-200"
        id="exampleModalScrollableLabel"
      >
        Search by First Nations
      </h5>
    </div>

    <div class="flex flex-col">
      <!--Name, Number and Province filters-->
      <div class="space-x-2 py-2">
        <form
          [formGroup]="searchLeaderForm"
          (submit)="searchNationLeaders(searchLeaderForm.value)"
          class="w-full"
        >
          <div class="flex flex-wrap -mx-3 mb:2 md:mb-6">
            <div class="w-full md:w-2/6 px-3 mb-2 md:mb-0">
              <label class="label">
                <span
                  class="label-text text-[#4F4F4F] text-base font-bold leading-5"
                  >Nation name</span
                >
              </label>
              <input
                id="name"
                type="text"
                formControlName="name"
                required
                (keyup)="searchNationLeaders(searchLeaderForm.value)"
                placeholder="Search nation name"
                class="input input-bordered w-full"
              />
            </div>
            <div class="w-1/2 md:w-1/6 px-3 mb-2 md:mb-0">
              <label class="label">
                <span
                  class="label-text text-[#4F4F4F] text-base font-bold leading-5"
                  >Province</span
                >
              </label>
              <select
                (change)="searchNationLeaders(searchLeaderForm.value)"
                formControlName="province"
                class="select select-bordered w-full"
              >
                <option disabled selected>Select Province</option>
                <ng-container class="pt-10" *ngFor="let province of provinces">
                  <option [value]="province.code">
                    <span class="font-bold text-black text-base">{{
                      province.name
                    }}</span>
                  </option>
                </ng-container>
              </select>
            </div>

            <div
              class="w-full md:w-auto flex md:block px-3 md:mb-0 pt-max self-end justify-end items-end"
            >
              <button
                type="submit"
                class="ml-1 inline-block rounded bg-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                data-te-ripple-init
                data-te-ripple-color="light"
              >
                Search
              </button>
              <button
                type="button"
                (click)="clearSearch()"
                class="ml-1 inline-block rounded bg-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              >
                Clear
              </button>
            </div>
          </div>
        </form>
      </div>
      <!--Letter filters-->
      <div class="hidden md:inline-flex flex-wrap gap-2" role="toolbar">
        <button
          type="button"
          class="inline-block rounded border border-solid border-customTeal text-customTeal font-bold px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white transition duration-150 ease-in-out hover:bg-primary-600 focus:bg-primary-600 focus:outline-none focus:ring-0 active:bg-primary-700"
          data-te-ripple-init
          data-te-ripple-color="light"
          *ngFor="let letter of letters"
          (click)="applyLetterFilter(letter)"
          [ngClass]="{
            'bg-customTeal text-white': letter == selectedLetter
          }"
        >
          {{ letter | titlecase }}
        </button>
      </div>

      <div class="block md:hidden">
        <select
          (change)="applyLetterFilter($event.target.value)"
          class="select select-bordered w-full"
        >
          <option disabled selected>Select Letter</option>
          <ng-container class="pt-10" *ngFor="let letter of letters">
            <option [value]="letter">
              <span class="font-bold text-black text-base">{{
                letter | titlecase
              }}</span>
            </option>
          </ng-container>
        </select>
      </div>
    </div>
  </div>
  <div
    class="flex flex-col bg-gray-200"
    *ngIf="filteredLeaders && filteredLeaders.length != 0"
  >
    <div
      class="flex flex-row items-center p-2 w-full md:space-x-6"
      *ngIf="filteredLeaders.length > 0"
    >
      <div class="w-6/12 lg:w-5/12">
        <p class="pl-8 text-sm">Nation Name</p>
      </div>
      <div class="w-6/12 lg:w-3/12">
        <p class="pl-8 text-sm">Nation Website</p>
      </div>
      <div class="w-4/12 hidden lg:block">
        <p class="pl-8 text-sm">Chief Name</p>
      </div>
    </div>
  </div>

  <!-- The list of first nation chiefs/leaders -->
  <div
    class="flex items-center justify-center w-full space-x-4"
    *ngFor="
      let item of filteredLeaders
        ;
      let i = index
    "
  >
    <!-- the list of first nation chiefs/leaders -->
    <div class="w-full">
      <div
        class="flex flex-row items-center w-full p-2 bg-white border-2 md:space-x-6"
      >
        <div class="w-6/12 lg:w-5/12 lg:block">
          <p class="text-base text-gray-500">{{ item.Nation_Name }}</p>
        </div>
        <div class="w-6/12 lg:w-3/12">
          <a
            [href]="item.Nation_Website"
            target="_blank"
            class="cursor-pointer hover:text-customTeal text-base text-gray-500"
            >{{ item.Nation_Website }}</a
          >
        </div>

        <div class="hidden lg:flex w-4/12">
          <div class="flex items-center space-x-3">
            <div class="avatar placeholder">
              <div
                class="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600"
              >
                <span class="font-medium text-gray-600 dark:text-gray-300">{{
                  item.Chief_First_Name
                    ? item.Chief_First_Name[0]
                    : ("-" | uppercase)
                }}</span>
              </div>
            </div>
            <div>
              <div class="text-base font-bold">
                {{ item.Chief_First_Name | titlecase }}
                {{ item.Chief_Last_Name | titlecase }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </div>
  </div>
  <div>
    <!-- <pagination-controls
      class="p-4 text-center mr-20"
      (pageChange)="page = $event"
    ></pagination-controls> -->
  </div>
</div>
