<!-- <p>upload-image works!</p> -->

<div class="flex bg-white mx-10 pt-8 pb-12 pl-8 pr-12 w-4/6 space-x-10">
    <div>
        <!-- <i class="material-icons text-2xl">photo</i> -->
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14 33.9998H34C34.4 33.9998 34.7 33.8165 34.9 33.4498C35.1 33.0832 35.0667 32.7332 34.8 32.3998L29.3 25.0498C29.1 24.7831 28.8333 24.6498 28.5 24.6498C28.1667 24.6498 27.9 24.7831 27.7 25.0498L22.5 31.9998L18.8 27.0498C18.6 26.7831 18.3333 26.6498 18 26.6498C17.6667 26.6498 17.4 26.7831 17.2 27.0498L13.2 32.3998C12.9333 32.7332 12.9 33.0832 13.1 33.4498C13.3 33.8165 13.6 33.9998 14 33.9998ZM10 41.9998C8.9 41.9998 7.958 41.6085 7.174 40.8258C6.39133 40.0418 6 39.0998 6 37.9998V9.99982C6 8.89982 6.39133 7.95782 7.174 7.17382C7.958 6.39115 8.9 5.99982 10 5.99982H38C39.1 5.99982 40.042 6.39115 40.826 7.17382C41.6087 7.95782 42 8.89982 42 9.99982V37.9998C42 39.0998 41.6087 40.0418 40.826 40.8258C40.042 41.6085 39.1 41.9998 38 41.9998H10ZM10 37.9998H38V9.99982H10V37.9998ZM10 9.99982V37.9998V9.99982Z"
                fill="black" />
        </svg>
    </div>
    <div class="flex flex-col ">
        <p class="text-2xl font-bold">{{title}} Image</p>
        <p class="text-md text-gray-500">{{description}} Your uploaded image will be used as the event cover. Select an image that best
            represents your event.</p>

        <div class="flex w-full space-x-10 mt-4">
            <div
                class="flex w-64 justify-center content-center h-44 max-w-x bg-white rounded-lg border-2 gap-x-3 space-x-10 bg-gray-100">
                <label class="custom-file-upload  self-center flex flex-col ">
                    <i class="fa fa-plus-circle text-customTeal text-3xl text-center self-center"></i>
                    <input type="file" type="file" accept="image/*" (change)="handleFileInput($event.target.files)" />
                    <span class="block font-bold pt-1  text-sm">Upload Image </span>
                </label>
            </div>

            <ng-container>
                <div class=" flex gap-x-3 px-4 pt-4">
                    <span
                        class="font-bold break-all">
                        <img loading="lazy" [src]="image" class="object-cover w-48 rounded-md cover"
                        alt="Image">
                    </span>
                </div>
            </ng-container>

            <div class="flex flex-col space-y-8 mt-6">
                <div class="flex space-x-2 items-center"><span class="bg-gray-200 px-2 py-2 rounded-full"><svg width="18" height="18"
                            viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M2.51501 6.80634C2.8345 5.73555 3.42984 4.76755 4.24134 3.99936C5.05285 3.23116 6.05202 2.68977 7.13871 2.42944C8.66391 2.0603 10.2716 2.26491 11.6557 3.00433C13.0398 3.74374 14.1037 4.96632 14.6448 6.43932"
                                stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path
                                d="M15 3.16077V6.80362H11.3571M14.7313 10.451C14.411 11.5201 13.8156 12.4864 13.0047 13.2532C12.1937 14.02 11.1957 14.5605 10.1104 14.8206C8.5853 15.1895 6.97783 14.9848 5.59392 14.2454C4.21 13.506 3.14627 12.2836 2.60518 10.8108"
                                stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M2.25 14.0893V10.4465H5.89286" stroke="black" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round" />
                        </svg></span> <span>Replace</span>
                    </div>
                <div class="flex space-x-2 items-center"><span class="bg-gray-200 px-2 py-2 rounded-full"><svg width="18" height="18"
                            viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M4.5 14.25C4.5 15.075 5.175 15.75 6 15.75H12C12.825 15.75 13.5 15.075 13.5 14.25V5.25H4.5V14.25ZM14.25 3H11.625L10.875 2.25H7.125L6.375 3H3.75V4.5H14.25V3Z"
                                fill="black" />
                        </svg>
                    </span><span>Remove</span></div>
            </div>
        </div>



    </div>
</div>