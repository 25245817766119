import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
// import { LabelType, Options } from '@angular-slider/ngx-slider';
import { environment } from 'src/environments/environment';
import { PollsAndSurveyModel, Question, SurveyQuestion } from 'src/app/models/PollsAndSurveyModel';
import { ClientFormService } from 'src/app/services/firebase/client-form.service';
import { ClientAnswersService } from 'src/app/services/firebase/client-answers.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { relativeTimeThreshold } from 'moment';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-profile-form',
  templateUrl: './profile-form.component.html',
  styleUrls: ['./profile-form.component.css']
})
export class ProfileFormComponent implements OnInit {

  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  surveyForm: UntypedFormGroup;
  answersArray: UntypedFormArray;
  visibleStepIndex = 0;
  totalQuestions = 0;
  submitDisabled = false;
  showComment = false;
  leaveComment = false;

    // toast
    toastMessage: any;
    toastClass: any;
    toastType: any;
    openToast = false;

  //added
  form: any;
  model: any;
  formGlobal: any;

  currentUser: any;
  userFilled: boolean = false;

  clientGlobal: any;

  id: any;

  // options: Options = {
  //   floor: 0,
  //   ceil: 10,
  //   showTicks: true,
  //   step: 1,
  // }

  //@Input() model: PollsAndSurveyModel;

  constructor(private fb : UntypedFormBuilder,
              private clientFormService: ClientFormService,
              private clientAnswerService: ClientAnswersService,
              private authService: AuthService,
              private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit(): void {

    this.currentUser = this.authService.getGlobalUser();
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });

    this.clientFormService.getFormByID(this.id).valueChanges().subscribe(form => {
      this.model = form;
      console.log("print the model",this.model);
      this.totalQuestions = this.model.questions.length;

      this.model.questions.forEach(question => {
        if (question.answerType === 'Open Ended Answer') {
          this.addAnswer(question);
        } else {
          this.addAnswer2(question);
        }

      });
    })

    // this.clientFormService.getFormList().valueChanges().subscribe(form => {
    //   console.log("***********>>>>>>client form", form);
    //   this.model = form[0]
    //   this.totalQuestions = this.model.questions.length;

    //   this.model.questions.forEach(question => {
    //     if (question.answerType === 'Open Ended Answer') {
    //       this.addAnswer(question);
    //     } else {
    //       this.addAnswer2(question);
    //     }
    //   });
    // })
    
    // this.clientAnswerService.getFormParticipationByClientID(this.currentUser.uid).valueChanges().subscribe(user =>{
    //   if(user && user.length > 0){
    //     console.log("user",user);
    //     this.userFilled = true;
    //   }
    // });


    //if (this.form) {
      this.surveyForm = this.fb.group({
        answerItems: this.fb.array([]),
      });

      // this.surveyForm.get('leaveComment').valueChanges.subscribe(x => {
      //   if (x == 'yes') {
      //     this.surveyForm.get('comment').setValidators([Validators.required]);
      //   } else {
      //     this.surveyForm.get('comment').clearValidators();
      //   }
      //   this.surveyForm.get('comment').updateValueAndValidity();
      // })


    //}
  }

  addAnswer(questionModel: SurveyQuestion) {
    this.answersArray = this.surveyForm.get('answerItems') as UntypedFormArray;
    this.answersArray.push(this.createAnswer(questionModel));
  }

  createAnswer(questionModel: SurveyQuestion) {
    return this.fb.group({
      questionNumber: [questionModel.questionNumber],
      question: [questionModel.question],
      questionType: [questionModel.answerType],
      allowMultipleAnswers: [questionModel.allowMultipleAnswers],
      answer: ['', [Validators.required]],
    });
  }

  addAnswer2(questionModel: Question) {
    this.answersArray = this.surveyForm.get('answerItems') as UntypedFormArray;
    this.answersArray.push(this.createAnswer2(questionModel));
  }

  createAnswer2(questionModel: Question) {
    let answers = this.fb.array([]);
    questionModel.answers.forEach(x => {
      answers.push(this.addAnswer3(x.answer));
    });
    return this.fb.group({
      questionNumber: [questionModel.questionNumber],
      question: [questionModel.question],
      questionType: [questionModel.answerType],
      allowMultipleAnswers: [questionModel.allowMultipleAnswers],
      answers: answers,
      answer: ['', [Validators.required]],
    });
  }

  addAnswer3(value) {
    return this.fb.group({
      answer: [value, [Validators.required]]
    });
  }

  answerSelected(question_index, value) {
    const answerArray = this.surveyForm.get('answerItems') as UntypedFormArray;
    if (answerArray.controls[question_index].get('questionType').value === 'Multiple Choice' && !answerArray.controls[question_index].get('allowMultipleAnswers').value) {
      answerArray.controls[question_index].get('answer').patchValue(value);
    }
    if (answerArray.controls[question_index].get('questionType').value === 'Multiple Choice' && answerArray.controls[question_index].get('allowMultipleAnswers').value) {
      var answer = answerArray.controls[question_index].get('answer').value;
      if (answer == "") answerArray.controls[question_index].get('answer').patchValue(value);
      else {
        const answers: string[] = answer.split(',');
        if (answers.includes(value)) {
          const index = answers.indexOf(value);
          answers.splice(index, 1);
          value = answers.join(',');
          //value = [answer].join(',');
        } else {
          value = [answer, value].join(',');
        }
      }
      answerArray.controls[question_index].get('answer').patchValue(value);
    }
  }

  continue(index: any) {
    this.visibleStepIndex = index + 1;
    // setTimeout(() => {
    //   $('select').formSelect();

    // }, 25);
  }

  previous(index: any) {
    if (index > 0) { this.visibleStepIndex = index - 1; }

    const answerArray = this.surveyForm.get('answerItems') as UntypedFormArray;
    if (answerArray.controls[index-1].get('questionType').value === 'Multiple Choice') {
      answerArray.controls[index-1].get('answer').patchValue(''); 
    }
   
      // setTimeout(() => {
      //   $('select').formSelect();
      // }, 25);
  }

  prev() {
    this.visibleStepIndex = this.visibleStepIndex - 1;
    // setTimeout(() => {
    //   $('select').formSelect();
    // }, 25);
  }

 

  onCommentYes() {
    this.showComment = true;
    // setTimeout(() => {
    //   M.textareaAutoResize($('#comment'));
    // }, 25);

  }
  onCommentNo() {
    this.showComment = false;
    this.surveyForm.get('comment').reset();
  }

  submitSurvey(value: any) {
    console.log("the form is being submitted")
    this.submitDisabled = true;
    const participation = {
      formId: this.model.id,
      //memberName: '',
      userId :this.currentUser.uid,
      //comment: value.comment,
      date: new Date(),
      answers: []
    };

    value.answerItems.forEach(element => {
      const answer = {
        questionNumber: element.questionNumber,
        question: element.question,
        questionType: element.questionType,
        allowMultipleAnswers: element.allowMultipleAnswers,
        answers: element.answers ? element.answers : [],
        answer: element.answer,
      };
      if (answer.questionType == 'Scale') answer.answer = answer.answer.toString();
      participation.answers.push(answer);
    });

    if(!this.userFilled){
      console.log("this user has filled the form",this.userFilled)
       this.clientAnswerService.newFormParticipation(participation).then(x => {
        //this.participationSaved.emit('saved');
        console.log("In here");
        this.toast({ html: 'Your Answer has Successfully Saved!', classes: 'green' });
        console.log("In here");
        this.submitDisabled = false;

      });
    }
    else{
      console.log("this user has not filled the form",this.userFilled)
      this.clientGlobal = this.clientAnswerService.getFormParticipationByClientID(this.currentUser.uid).valueChanges().subscribe(form => {
        this.formGlobal = form[0];
        console.log("the form that is used for the model",participation)
        this.clientAnswerService.updateFormParticipationByClient(participation);
        this.toast({ html: 'Your Answer has Successfully Updated!', classes: 'green' });
        this.clientGlobal.unsubscribe();  
      })
      

    }
  }

  isMultipleQuestionsAllowed(index) {
    return this.model.questions[index].allowMultipleAnswers;
  }

  getOptions(index) {
    return this.model.questions[index].answers;
  }

  // getScaleOption(index) {

  //   const scales = this.model.questions[index].scales;
  //   const start = Number(scales.start);
  //   const end = Number(scales.end);
  //   const startLabel = scales.startLabel;
  //   const endLabel = scales.endLabel;
  //   let options: Options = {
  //     floor: start,
  //     ceil: end,
  //     showTicks: true,
  //     showTicksValues: true,
  //     step: 1,
  //     getLegend: (value: number): string => {
  //       if (value == start) return startLabel;
  //       else if (value == end) return endLabel
  //       // else return value.toString();
  //     },
  //   }
  //   return options;
  // }

  getData(index) {
    const scales = this.model.questions[index].scales;
    const startLabel = scales.startLabel;
    const endLabel = scales.endLabel;

    const start = Number(scales.start);
    const end = Number(scales.end);
    var items: string[] = [];
    for (var i = start; i <= end; i++) {
      //answers.push(this.fb.group({value: i}));
      items.push(i.toString());
    }

    const data = {
      range: items,
      label: { start: startLabel, end: endLabel }
    }
    return data;
  }

  createRange(index) {
    const scales = this.model.questions[index].scales;
    const start = Number(scales.start);
    const end = Number(scales.end);
    var items: number[] = [];
    for (var i = start; i <= end; i++) {
      //answers.push(this.fb.group({value: i}));
      items.push(i);
    }

    return items;
  }
  getLables(index) {
    const scales = this.model.questions[index].scales;
    const startLabel = scales.startLabel;
    const endLabel = scales.endLabel;
    return { start: startLabel, end: endLabel };
  }
  getColSize(index) {
    const scales = this.model.questions[index].scales;
    var start = Number(scales.start);
    var end = Number(scales.end);
    var items = end - start;
    items = items + 3;
    // if(items>5){
    //   return `col m${Math.floor(10/items/2)} s${Math.floor(10/items/2)}`;
    // }else{
    //   return `col m${Math.floor(12/items)} s${Math.floor(12/items/2)}`;
    // }
    if (items > 7) {
      return `col s${12 / (items) * 2}`;
    } else {
      return `col s${12 / items}`;
    }
    //  return `col s${12/items}`;
  }
  setScaleValue(index, value) {
    const answerItems = this.surveyForm.get('answerItems') as UntypedFormArray;
    const answer = answerItems.controls[index].get('answer');
    answer.patchValue(value.toString());
  }

  backToList(){
    this.router.navigate(['/user/view-form']);
  }
  // update(){
  //   this.clientAnswerService.updateFormParticipationByClient()
  // }

  get answers() {
    return (this.surveyForm.get('answerItems') as UntypedFormArray).controls;
  }
  get answerItems() : UntypedFormArray {return this.surveyForm.get("answerItems") as UntypedFormArray }

  toast(obj){
    console.log("Inside the toast")
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
    }, 3000);
  }

}
