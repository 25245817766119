<div class="empty-state-container">
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" class="size-8 stroke-slate-300">
    <path stroke-linecap="round" stroke-linejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
  </svg>
  
    <h2 class="empty-state-title">{{title}}</h2>
    <p class="empty-state-description">
   {{description}}
    </p>

    <app-button (click)="addNewElement()" [customConfig]="{'text':'Add New'}"> </app-button>
  </div>
  