import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';

@Component({
  selector: 'app-service-product',
  templateUrl: './service-product.component.html',
  styleUrls: ['./service-product.component.css']
})
export class ServiceProductComponent implements OnInit {

  settings
  productsRoute
  serviceRoute
  constructor(private appSettingsService: AppSettingsService,private router: Router) { }

  ngOnInit(): void {
    this.appSettingsService.getAppSettingsList().valueChanges().subscribe((settings) => {
      if (settings.length > 0){
        this.settings = settings[0];
        if(this.settings.isBusinessApp){
          this.productsRoute = this.settings.hasProductsCategory ? '../../employee/product-catagory' : '../../employee/products'
          this.serviceRoute = this.settings.hasServicesCategory ? '../../employee/service-category' : '../../employee/services'
         // this.serviceRoute = '../../employee/services'

          if(this.settings.serviceType != 'Both'){
              this.navigate()
          }
        }
      }
    });
  }

  navigate(){
    let route
    if(this.settings.serviceType == 'Service'){
      if (this.settings.hasServicesCategory){
        route = 'employee/service-category'
      }else{
        route = 'employee/services'

      }
    }else{
      if (this.settings.hasProductsCategory){
        route = 'employee/product-catagory'
      }else{
        // get list of products
        route = 'employee/products'

      }
    }
    // console.log(ro)
    this.router.navigate([route])
  }
}
