import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AyrshareService } from 'src/app/services/social-media/ayrshare.service';
import { JwtModel, platforms, UserProfile, UserProfileModel } from 'src/app/models/SocialMediaPostModel';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { AyshareUserService } from 'src/app/services/firebase/ayrshare-social-media.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { HttpClient } from '@angular/common/http';
import { UserService } from 'src/app/services/firebase/user.service';
import { EmailModel, CustomAdminEmailModel } from 'src/app/models/emailModel';
import { EmailService } from 'src/app/services/api/email.service';



@Component({
  selector: 'app-social-media-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class SocialMediaProfileComponent implements OnInit {

  @Output() onProfileSelect: EventEmitter<boolean> = new EventEmitter<boolean>();

    postForm: UntypedFormGroup;
    profileForm: UntypedFormGroup;
    profileApplyForm: UntypedFormGroup;
    user
    loading
    hasProfile=false
    userProfile=[]

    privateKey = environment.socialMedia.privateKey
    url = environment.socialMedia.profileUrl
    profileKey
    showProfile=false
    employees:any

    profileModalOpen = false;
    profileCreatedModalOpen = false;
    addEmployee=false

      // toast
    toastMessage: any;
    toastClass: any;
    toastType: any;
    openToast = false;
    isNationApp = environment.firstNation.Origin == "FNLeaders" ? true : false;

    constructor(
        private socialMediaService:AyrshareService,
        private userProfileService:AyshareUserService,
        private userService:UserService,
        private authService: AuthService,
        private fb: UntypedFormBuilder,
        private http: HttpClient,
        private emailService: EmailService

    ) { }

    ngOnInit() {


        this.user = this.authService.getGlobalUser()

        if(this.user){
          this.loading = true;
            this.userProfileService.getOwnerProfiles(this.user.uid).valueChanges().subscribe(p=>{
                if(p){

                    if(p.length>0){
                        this.userProfile=p
                        this.hasProfile=true
                        this.loading=false
                    }
                this.loading = false;

                }
            })
        }

        this.userService.getUserByRole('Employee').valueChanges().subscribe((res: any) => {
            if (res.length > 0 ){
              this.employees = res;
            }
          });



        this.profileForm=this.fb.group({
            title:['',[Validators.required]],
            displayTitle:[''],
            hideTitle:[true],
            employeeId:[''],
        })

        // this.profileApplyForm=this.fb.group({
        //     numberOfProfiles:['',[Validators.required]],
        //     message:[''],

        // })


    }

    get title(){return this.profileForm.get('title')  }


    linkSocialMedia(profileKey){

        let model = new JwtModel()


            model.privateKey=this.privateKey
            model.profileKey = profileKey
            this.socialMediaService.generateJWT(model).subscribe(x=>{
              
                if(x['status'] === 'success'){

                    window.open(x['url'],'_blank')
                }else{
                }
            })

    }

    toDashboard(index){
        // this.router.navigate(['../dashboard'],
        //     { state:
        //         {   profileKey:profileKey
        //         },
        //         relativeTo: this.activatedRoute
        //     }
        //     )
        
        this.profileKey=this.userProfile[index]
        this.onProfileSelect.emit(this.profileKey);

        // this.profileKey=this.userProfile[index].profileKey
        // this.showProfile=true

      }

      profileChange(event){
          let index = event.target.value
        this.profileKey=this.userProfile[index]

      }

      openProfileModal(){
        this.profileModalOpen = true;
      }

      openSuccessModal(){
        this.profileCreatedModalOpen = true;
      }

      closeSuccessModal(){
        this.profileCreatedModalOpen = false;
      }
      closeProfileModal(){
        this.profileModalOpen = false;
        this.profileForm.reset()
        this.addEmployee=false
      }

      getEmailForEmployee(email) {
        let msg = new CustomAdminEmailModel();

        let emails: string[] = []

        emails.push(email)
        msg.emailList = emails;
        msg.subject =  "New Profile created";
        msg.body = "A profile has been created on  your behalf; please login to your account to create posts on your behalf."
        msg.isNationApp = this.isNationApp;

        // custom send email used
        msg.host = "smtp.gmail.com"
        msg.adminEmail = "mkproject.app@gmail.com"
        msg.appPassword = "ytklyidomvqfmtky"


        return msg
      }


      createUserProfile(values){
        let model = new UserProfile()
       
         Object.keys(values).forEach(key=>{
            model[key] =values[key]
        })

        this.closeProfileModal()
        this.socialMediaService.createUserProfile(model).toPromise().then(x=>{
            if(x['status'] == 'success'){
                let userProfile={ }
                userProfile['refId']=x['refId']
                userProfile['userId']=this.user.uid
                userProfile['profileKey'] = x['profileKey']
                userProfile['title'] = x['title']

                if (values.employeeId ) userProfile['employeeId'] = values.employeeId

                this.userProfileService.createUserProile(userProfile).then(p=>{
                    this.openSuccessModal()
                    if (values.employeeId ){
                      let employee = this.employees.filter(x=>x.uid ==values.employeeId)
                        let msg = this.getEmailForEmployee(employee[0].Email)
                        this.emailService.sendCustomEmail(msg).subscribe(x => {

                            if (x && x.Status == 'OK') {
                              let recipient = msg.emailList[0]
                              this.toast({html: 'Email sent', classes: 'green', type: 'success'});


                            } else {

                            }

                          })
                    }

                }).catch(err=>{
                    this.toast({html: 'Error creating profile', classes: 'red', type: 'failure'});

                })

            }else{
            }
        }).catch(err=>{
          this.toast({html: `${err.error.message}`, classes: 'red', type: 'failure'});
        })
    }

    toast(obj){
      
      this.toastMessage = obj.html;
      this.toastClass = obj.classes ? obj.classes : 'green' ;
      this.toastType = obj.type ? obj.type : 'success';
      this.openToast = true;
      setTimeout(() => {
        this.openToast = false;
      }, 2000);
    }


}
