<div class="row center">
  <h6><strong> User Menu Preferences </strong></h6>
</div>


<div class="row col s12">
  <div class="col l1 "></div>
  <div class="col s12 m12 l10">

      <!-- ---------------------- Menu Items -------------------- -->
      <div *ngIf="isMenuSettings && menu">
          <table class="centered">
              <thead>
                  <tr>
                      <th>Visible</th>
                      <th>Menu Item</th>
                      <th>Edit</th>
                      <th>Move Up / Down</th>
                  </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let section of menu.items">
                      <td>
                          <div class="switch">
                              <label>
                                  <span>No</span>
                                  <input type="checkbox" [checked]="section.isEnabled" (change)="checkedChange(section)">
                                  <span class="lever"></span>
                                  <span>Yes</span>
                              </label>
                          </div>
                      </td>
                      <td>
                          {{section.displayName}}
                      </td>
                      <td class="center">
                          <button class="btn-small btn-flat blue" [disabled]="!section.isEnabled"
                              (click)="editSection(section);"> <i class="material-icons white-text">edit</i></button>
                      </td>
                      <td class="center">
                          <button class="btn-small btn-flat green" [disabled]="!section.isEnabled || section.name === 'Logout' || section.index === 0"
                          (click)="moveSection(section, -1);" > <i class="material-icons white-text">expand_less</i></button>
                          <button class="btn-small btn-flat red" [disabled]="!section.isEnabled || section.name === 'Logout'"
                          (click)="moveSection(section, 1);"> <i class="material-icons white-text">expand_more</i></button>
                      </td>

                  </tr>
              </tbody>
          </table>
      </div>

       <!-- ---------------------- Edit Items -------------------- -->
       <div *ngIf="isEditItem && selectedItem">

          <div class="row">
              <div class="col s3 m4 l5"></div>
              <div class="col s6 m4 l3">
                  <div class="card hoverable" [ngStyle]="{'background-color': selectedItem.bgColor, 'height': '95px'}">
                    <div class="card-content">
                    <div class="row center">
                      <i class="material-icons" [ngStyle]="{'color': selectedItem.iconColor}">{{selectedItem.icon}}</i>
                      <br/>
                      <strong [ngStyle]="{'color': selectedItem.textColor}" class="truncate">{{selectedItem.displayName}}</strong>
                    </div>
                    </div>
                  </div>
                </div>
                <div class="col s3 m4 l4"></div>
          </div>

          <div class="row card-panel">
              <div class="row">
                  <table>
                      <tr>
                          <td>
                              <div class="row col s12">
                                  <span><strong>Change Button Color : </strong></span>
                              </div>
                          </td>
                          <td class="center">
                              <input id="bgColor" [(ngModel)]="selectedItem.bgColor" type="color">
                          </td>
                      </tr>
                      <tr>
                          <td>
                              <div class="row col s12">
                                  <span><strong>Change Icon Color : </strong></span>
                              </div>
                          </td>
                          <td class="center">
                              <input id="iconColor" [(ngModel)]="selectedItem.iconColor" type="color">
                          </td>
                      </tr>
                      <tr>
                          <td>
                              <div class="input-field col s12 ">
                                  <i class="material-icons prefix teal-text text-darken-3">article</i>
                                  <input id="title" [(ngModel)]="selectedItem.displayName" type="text">
                                  <label for="title">Button Name</label>
                              </div>
                          </td>
                          <td class="center">
                              <input id="titleColor" [(ngModel)]="selectedItem.textColor" type="color">
                          </td>
                      </tr>

                  </table>


              </div>
          </div>


       </div>

       <div class="row">
          <button class="btn col s12 blue white-text" style="margin-bottom: 5px;" (click)="saveChanges();">Save Changes <i class="material-icons right">save</i></button>
          <button class="btn col s12 teal white-text" style="margin-bottom: 5px;" *ngIf="!isMenuSettings" (click)="backClicked();">Back To Previous Page <i class="material-icons left">arrow_back_ios_new</i></button>
          <a class="btn col s12 teal white-text" style="margin-bottom: 5px;" *ngIf="isMenuSettings" [routerLink]="['/admin/settings']">Back To Previous Page</a>
        </div>
  </div>
  <div class="col l1 "></div>
</div>
