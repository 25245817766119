import { FireMembersService } from './../../../services/firebase/fire-members.service';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  displayMenu = true;
  currentUser: any;
  id: any;

  constructor(private authService: AuthService, private router: Router, private fireMemberService: FireMembersService) {
  }

  ngOnInit() {
   this.id = this.authService.getCurrentUser().uid;
   this.fireMemberService.getMemberByID(this.id).valueChanges().subscribe(user => {
     if (user){
       this.currentUser = user;
     }
   });

 }

 signOut() {
   this.authService.signOut();
 }

 backClicked(){
   this.router.navigate(['employee/dashboard']);
 }

}
