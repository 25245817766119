<ng-container *ngIf="settings">
  <!-- For admin -->
  <!-- <ng-container *ngIf="currentUser && (currentUser.role =='Admin' || (permission && (permission.addServiceCategory || permission.editServiceCategory)))"> -->
  <ng-container *ngIf="currentUser">
    <div class="mr-8 flex justify-end">

      <button (click)="addCategory();" *ngIf="currentUser && (currentUser.role =='Admin' || (permPES && permPES.addServiceCategory))" class="py-2 px-6 bg-customTeal rounded-md text-white float-right">
        <i class="material-icons text-white float-left mr-3">category</i>
        Add {{servicesName}} Category</button>
    </div>

 <div class="flex flex-col gap-y-8 md:gap-x-7 px-4 md:px-8 py-4" *ngIf="servicesCategory ">
  <h1 class="text-xl font-bold text-center text-black ">
    Select {{servicesName}} Category
  </h1>
  <div class="flex flex-wrap gap-y-20 md:gap-x-7">
    <div *ngFor="let catagory of servicesCategory | paginate: { itemsPerPage: 6, currentPage: p }">

      <div
        class="flex flex-wrap flex-co0l bg-white rounded-xl w-72 md:w-80 h-max drop-shadow-md">

        <div class="w-full h-40 md:h-48 rounded-xl " *ngIf="catagory.imgUrl && catagory.imgUrl != ''"
          [style]="'background:linear-gradient(rgba(217, 217, 217, 0), rgba(0, 0, 0, 0.8)),url('+ catagory.imgUrl +'); background-size: contain; background-repeat: no-repeat; background-position: center;'">

        </div>
        <div class="w-full h-40 md:h-48 rounded-xl " *ngIf="!catagory.imgUrl || catagory.imgUrl ==''"
          style="background:linear-gradient(rgba(217, 217, 217, 0), rgba(0, 0, 0, 0.8)); background-size: contain; background-repeat: no-repeat; background-position: center;">
          <div class="text-center text-gray-500 font-bold text-2xl pt-16">
            {{truncateHTML(getInnerText(catagory.name), 40)}}
          </div>
        </div>

        <div (click)="selectCategory(catagory)" *ngIf="currentUser && currentUser.role =='Admin' || (permPES && permPES.editServiceCategory)"
          class="mt-2 mr-2 absolute top-1 border-sm rounded-lg right-1 z-50 bg-white py-2 px-3 border-2 border-veryLightGray hover:border-4  hover:border-customTeal  hover:cursor-pointer shadow-md">
          <i class="material-icons float-left mr-2 text-black text-lg">edit</i>Edit
        </div>

        <div class="p-4 space-y-4">
          <span class="" [innerHTML]="catagory.name | noSanitize"></span>

          <div (click)="navigate(catagory)"
            class="flex mt-4 mb-2 text-customTeal font-bold cursor-pointer mr-2">
            View {{servicesName}} <i class="material-icons">navigate_next</i></div>
        </div>

      </div>

    </div>
  </div>

</div>

<div *ngIf="!servicesCategory">
  <div class="flex items-center justify-center">
    <img  loading="lazy" data-src="../../../../assets/icons/Illustrations/Category-Illustration.png"
    class="block w-64 items-center justify-center">
    <br/>
</div>
<ng-container *ngIf="!isAdmin">
  <div class="text-customTeal text-lg text-center font-bold">No {{servicesName}} category found.</div>
  <div class="text-gray-400 text-sm text-center font-bold">{{servicesName}} category  will appeare here once added!</div>
</ng-container>
<ng-container *ngIf="isAdmin">
  <div class="text-customTeal text-lg text-center font-bold">What are the {{servicesName}}  you provide?</div>
  <div class="text-gray-400 text-sm text-center font-bold">Why not let the world know!</div>
  <br/>
  <div *ngIf="currentUser" class="flex justify-center">
    <button (click)="addCategory();" *ngIf="currentUser.role =='Admin' || (permPES && permPES.addServiceCategory)" class="py-2 px-6 bg-customTeal rounded-md text-white float-right">
      <i class="material-icons text-white float-left mr-3">category</i>
      Add Category</button>
  </div>
</ng-container>
</div>
    <div id="editModal" *ngIf="editModalOpen" class="modal"  [ngClass]="{'modal-open': editModalOpen, 'modal-action': !editModalOpen }">
      <div class="modal-box max-w-2xl">

      <form class=""
        [formGroup]="serviceCategoryForm"
        (ngSubmit)="manageCategory(serviceCategoryForm.value)"
      >
        <div class="">
          <h3 *ngIf="selectedModel" class="font-bold text-lg">Edit {{servicesName}} Category</h3>
          <h3 *ngIf="!selectedModel" class="font-bold text-lg">Create {{servicesName}} Category</h3>
          <div class="text-gray-500 text-sm">A service category represents a group of similar services that share related characteristics</div>

          <br />
          <div class="space-y-4">
            <div class="">
              <label class="label py-2">
                <span class="label-text text-gray-500 text-base font-bold leading-5">Name</span>
              </label>
              <!-- <input
                id="name"
                type="text"
                formControlName="name"
                required
                placeholder="Category name"
                class="focus:invalid:border-red-500 py-3 px-3 bg-gray-100 input input-bordered w-full"
                autocomplete="off"
              /> -->
              <app-editor [control]="name" class="bg-gray-100" [inputType]="'title'" [fromComponent]="catagoryName"></app-editor>

              <div *ngIf="(name.dirty || name.touched) && name.invalid">
                <small *ngIf="name.errors.required" class="text-red-600">
                  Category Name is required.
                </small>
              </div>
            </div>

            <div class="">

              <label class="label">
                <span class="label-text text-gray-500 text-base font-bold leading-5">Image</span>
              </label>
              <div class="w-full max-w-x flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
                <div *ngIf="!isMobile"
                  class="w-1/2 flex justify-center content-center w-full h-44 max-w-x bg-white rounded-lg border-2 gap-x-3">

                  <label class="custom-file-upload  self-center flex flex-col">
                    <i class="fa fa-plus-circle text-customTeal text-3xl text-center self-center"></i>
                    <input type="file" type="file" accept="image/*" (change)="fileChangeEvent($event)">
                    <span class="block font-bold pt-1">Upload Image</span>
                  </label>
                </div>

                <div class="flex p-3" *ngIf="isMobile">
                  <app-image-uploader class="justify-start items-start"  (onImageUpload)=onImageUpload($event) [customConfig]="{'upload':false}"></app-image-uploader>

                  <!-- <button type="button"
                    class="py-2 px-8 modal-button gap-2 text-black border-2 border-customTeal rounded-lg w-full"
                    type="button" (click)="handleMobilePhotoInput()">
                    Upload Image
                  </button> -->
                </div>

                <div class="relative" *ngIf="base64Image">
                  <img loading="lazy" [src]="base64Image" class="object-cover  w-32 h-32 rounded-md cover" alt="Image">
                  <button (click)="removeFile()" type="button"
                    class="absolute top-0 translate-x-24 -translate-y-4 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                    <i class="fa fa-trash text-md"></i>
                  </button>
                </div>

                <div class="relative"
                  *ngIf="!base64Image && (selectedModel && selectedModel.imgUrl && selectedModel.imgUrl !='')">
                  <img loading="lazy" [src]="selectedModel.imgUrl" class="object-cover  w-32 h-32 rounded-md cover"
                    alt="Image">
                  <button (click)="remove()" type="button"
                    class="absolute top-0 translate-x-24 -translate-y-4 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                    <i class="fa fa-trash text-md"></i>
                  </button>
                </div>


                <p class="text-red-700 py-2" *ngIf="imgErrorMsg">
                  <strong><small><i class="material-icons">error_outline</i> {{imgErrorMsg}}</small></strong>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-action flex justify-between items-center">
          <div>
            <a (click)="closeEditModal()"
            class="modal-close font-bold text-customTeal btn-flat cursor-pointer"
            >Cancel</a>
          </div>
          <div class="space-x-4">
            <button
            *ngIf="selectedModel"
            type="button"
            class="modal-close  bg-red-400 py-2.5 rounded-md px-10 text-white btn-flat"
            (click)="openDeleteModal()"
          >
            Delete
          </button>

          <ng-container *ngIf="currentUser">
            <button *ngIf="currentUser.role == 'Admin' || (permPES && permPES.editServiceCategory)"
            type="submit"
            [disabled]="!serviceCategoryForm.valid"
            class="bg-customTeal text-white  rounded-md border-0 py-2.5 px-10"
            style="margin-right: 10px;"
          >
            <span *ngIf="selectedModel">Update</span>
            <span *ngIf="!selectedModel"> Save Category</span>
          </button>
          </ng-container>
            
          
          </div>
          
  
        </div>
      </form>

    </div>

    </div>

    <div id="deleteModal" *ngIf="deleteModalOpen" class="modal"  [ngClass]="{'modal-open': deleteModalOpen, 'modal-action': !deleteModalOpen }">
      <div class="modal-box">
        <h3 class="font-bold text-lg">Delete Category</h3>

        <p class="py-4"><input class="checkbox" type="checkbox" [(ngModel)]="deleteServicesUnder">Also Delete services in category.</p>
        <p class="py-4">Are you sure you want to delete category?</p>

        <div class="modal-action">
          <button
          *ngIf="selectedModel"
          type="button"
          class="modal-close btn bg-red-400 text-white btn-flat border-none"
          (click)="deleteCategory(selectedModel)"
        >
          Delete
        </button>
          <a (click)="closeDeleteModal()"
          class="modal-close btn bg-customTeal text-white btn-flat ">Cancel</a>
        </div>
      </div>
      </div>
      </ng-container>

      <!-- For public users and registered users -->
      <div *ngIf="(currentUser && currentUser.role !='Admin') || !currentUser">
        <ng-container
          *ngIf="!currentUser && (PermPES && (!PermPES.addServiceCategory && !PermPES.editServiceCategory)) || !PermPES">
          <ng-container *ngTemplateOutlet="servicesList"></ng-container>
        </ng-container>
      </div>

</ng-container>




<!-- <div *ngIf="(!currentUser || (currentUser && currentUser.role =='User'))"> -->

<ng-template #servicesList2>
  <div class="h-screen flex flex-col space-y-6 md:space-y-12 m-8 mt-8" *ngIf="servicesCategory">
    <h1 class="text-xl font-bold text-center px-12 text-black md:text-2xl py-4">
      Select Service Catagory1
    </h1>
    <div class="flex flex-wrap gap-x-5 gap-y-20 md:gap-x-10 md:gap-y-20">

      <div *ngFor="let catagory of servicesCategory | paginate: { itemsPerPage: 6, currentPage: p }"
      (click)="navigate(catagory)" class="">

        <div
          class="flex flex-wrap flex-col bg-white rounded-xl w-72 md:w-80 h-max drop-shadow-md hover:border-2 hover:border-customTeal hover:cursor-pointer">

          <div class="w-full h-40 md:h-48 rounded-xl " *ngIf="catagory.imgUrl && catagory.imgUrl != ''"
            [style]="'background:linear-gradient(rgba(217, 217, 217, 0), rgba(0, 0, 0, 0.8)),url('+ catagory.imgUrl +'); background-size: contain; background-repeat: no-repeat; background-position: center;'">

          </div>
          <div class="w-full h-40 md:h-48 rounded-xl " *ngIf="!catagory.imgUrl || catagory.imgUrl ==''"
          style="background:linear-gradient(rgba(217, 217, 217, 0), rgba(0, 0, 0, 0.8)); background-size: contain; background-repeat: no-repeat; background-position: center;">
          <div class="text-center text-gray-500 font-bold text-2xl pt-16">
            {{truncateHTML(getInnerText(catagory.name), 40)}}
          </div>
        </div>

        <span class="my-3 ml-2" [innerHTML]="catagory.name | noSanitize"></span>
          
        </div>



      </div>
    </div>
    <div class="w-full text-center mt-5" *ngIf="servicesCategory.length>0">
      <pagination-controls (pageChange)="p = $event"></pagination-controls>
    </div>
  </div>
  <div *ngIf="!servicesCategory" class="h-screen">
    <div class="flex items-center justify-center">
      <img  loading="lazy" data-src="../../../../assets/icons/Illustrations/Category-Illustration.png"
      class="block w-64 items-center justify-center">
      <br/>
  </div>
  <div class="text-customTeal text-lg text-center font-bold">No {{servicesName}} category found.</div>
  <div class="text-gray-400 text-sm text-center font-bold">{{servicesName}} category  will appeare here once added!</div>


  </div>
</ng-template>
<!-- </div> -->

<!-- Crop Image Modal -->
<app-crop-image *ngIf="cropModalOpen" [theImage]="imageChangedEvent" [aspectRatio]="4 / 3" (returnCroppedImage)="processCroppedImage($event)" (closeCropModal)=closeCroppingModal()></app-crop-image>



<ng-template ng-template #servicesList>
  <div class="mt-16 mx-3 lg:mx-16 flex flex-col " *ngIf="servicesCategory">
    <div class="flex w-full  gap-x-14
     mx-auto" *ngIf="servicesCategory.length > 0">

      <div class="min-h-max pb-10 md:pb-0  md:w-80 w-full mb-10  border bg-white drop-shadow-md rounded-lg hover:scale-110 hover:ease-in hover:duration-300"
      *ngFor="let catagory of servicesCategory | paginate: { itemsPerPage: 8, currentPage: p }" [ngStyle]="{'background-color': catagory.backgroundColor}"
      (click)="navigate(catagory)">
  
        <div class=" flex flex-col w-full items-center space-y-4    ">
          <div class="w-full h-56 md:h-56 " *ngIf="catagory.imgUrl && catagory.imgUrl != ''"
            [style]="'background:linear-gradient(rgba(217, 217, 217, 0), rgba(0, 0, 0, 0.8)),url('+ catagory.imgUrl +'); background-size: contain; background-repeat: no-repeat; background-position: center;'">

          </div>
          <div class="md:hidden block my-9"><br/></div>
          <div href="" class="px-3 lg:px-6 py-2  justify-start text-black  bg-white border-2 border-black baseline text-xl font-bold bottom-0 translate-y-[50%] absolute md:ml-0">
            {{truncateHTML(getInnerText(catagory.name), 15)}}
          </div>
        </div>
      </div>

    </div>
    <div class="w-full text-center ">
      <pagination-controls (pageChange)="p = $event"></pagination-controls>
    </div>
  </div>
</ng-template>
