<div class="flex  md:px-16 md:mx-auto w-full  justify-center ">
    <div class="flex flex-wrap gap-5 py-10 justify-center w-full ">
        <div *ngFor="let section of sections | slice:0:itemNumber; "  class="w-full md:w-min"
            [routerLink]="['/view-custom-section', section.id]" [ngStyle]="{'background-color': section.backgroundColor}">
        <div class=" flex flex-col w-full md:w-[280px] lg:w-[340px] md:h-[305px] items-center space-y-4   p-4  pt-4  md:px-6 bg-white  rounded-xl  drop-shadow-md hover:scale-105 hover:ease-in hover:duration-300">
  
        <div *ngIf="section.imgLink"   class="w-full md:h-48 rounded-t-xl bg-white cursor-pointer">
            <div class="flex justify-center md:h-48 " >
              <img [src]="section.imgLink" class="md:w-56 h-full w-full rounded-lg mt-2 object-contain" >
            </div>
          </div>
        <h4 class="text-2xl font-bold text-center" [ngStyle]="{'color':section.titleColor}">{{truncateHTML(getInnerText(section.title),40)}}</h4>
        </div>
        </div>
    </div>

</div>