<div class="flex flex-col md:w-9/12 md:mx-auto my-2 bg-white h-max p-4 md:p-12 rounded-lg space-y-4" *ngIf="currentUser">
    <p class=" font-bold text-xl text-gray-500 ">{{hasSchedule ? 'Update' : 'Add'}} Business hours </p>
    <form [formGroup]="scheduleForm" (ngSubmit)="onSubmit(scheduleForm.value)">
        <p class="font-bold text-sm">Select work days you will be active on: </p>
        <div *ngIf="schedules">
            <div  formArrayName="schedules" class="mb-2" *ngFor="let schedule of schedules.controls; let i=index">
                <div [formGroupName]="i" class="flex flex-row justify-between ">
                    <div class="md:w-2/12 w-5/12 flex flex-col">
                        <input class="py-1 my-2" disabled formControlName="day" />
                    </div>
                    <div class="md:w-8/12 w-7/12 flex md:flex-row flex-col md:justify-between justify-center space-y-2 md:space-y-0">

                        <div class="md:w-4/12  ml-3 md:ml-0">
                        <input type="time" step="300" class="input input-bordered" value="09:00:00"
                        formControlName="startTime" [readonly]= isEditable/>

                        </div>

                        <div class="md:w-4/12  ml-3 md:ml-0">
                        <input type="time" step="300" class="input input-bordered" value="05:00:00"
                            formControlName="endTime" [readonly]= isEditable/>
                        </div>

                        <p class="md:w-3/12 ml-3 md:ml-0 mb-5 md:mb-0">
                            <label>
                                <input type="checkbox" class="filled-in" formControlName="unAvailable"
                                    (click)="updateDaySchedule(i)" [readonly]= isEditable/>
                                <span>Unavailable</span>
                            </label>
                        </p>
                    </div>
                </div>


            </div>
        </div>
        <div class="flex flex-row-reverse mr-3 mt-5 gap-4">
            <br />
            <button type="submit"
            class="inline-block rounded px-6 pb-2 pt-2.5 text-xs bg-customTeal font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            
            [disabled]="!scheduleForm.valid" >
                    Save Schedule
            </button>

            <button
             type="button" 
            class="flex space-x-2 items-center bg-white rounded border-2 text-customTeal border-customTeal px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:border-primary-accent-100 hover:bg-neutral-500 hover:bg-opacity-10 focus:border-primary-accent-100 focus:outline-none focus:ring-0 active:border-primary-accent-200 dark:text-primary-100 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
            
             (click)="backClicked()">
                    Back to Previous Page
            </button>


        </div>
    </form>
</div>



<!-- toast -->
<app-alert class="fixed top-0 flex justify-end w-full z-50" [toastType]="toastType" [toastMessage]="toastMessage" [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>
