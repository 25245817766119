<div class="flex space-x-3">
    <app-circle-img [data]="data"></app-circle-img>
    <div class="flex justify-between w-full">
        <div class="flex flex-col space-y-1">
            <div class="font-bold">{{data.text}}</div>
            <ng-container *ngIf="data.hasSecondaryText">

                <div class="text-xs text-darkGray" *ngIf="data.secondaryTextType && data.secondaryTextType =='date'">
                    <div
                        *ngIf="(data.secondaryText?.toDate() | date: 'dd MMMM yyyy') !== (today | date: 'dd MMMM yyyy') ">
                        {{data.secondaryText?.toDate() | date: 'mediumDate'}}</div>
                    <div
                        *ngIf="(data.secondaryText?.toDate() | date: 'dd MMMM yyyy') == (today | date: 'dd MMMM yyyy') ">
                        {{data.secondaryText?.toDate() | date: 'shortTime'}}</div>
                </div>
                <div class="text-xs text-darkGray" *ngIf="!data.secondaryTextType || data.secondaryTextType !='date'">
                    {{data.secondaryText}}
                </div>
            </ng-container>


        </div>
        <!-- <p class="rounded-full float-right h-fit justify-self-end items-end block p-2 bg-customTeal text-white font-bold text-sm"
            *ngIf="data.unreadCount">
            {{data.unreadCount}}
        </p> -->
        <div class="rounded-full float-right flex-row text-center text-md"  *ngIf="data.unreadCount">
            <button 
              class="  text-white font-bold text-sm font-medium hover:text-white bg-customTeal  px-3 py-1 rounded-full"
              >{{data.unreadCount}}</button>
          </div>
    </div>


</div>