import { Component, ViewChild, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MediaService } from 'src/app/services/api/media.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { UserService } from 'src/app/services/firebase/user.service';
import { VerificationService } from 'src/app/services/firebase/verification.service';
import { Location } from '@angular/common';
import { enableRipple } from '@syncfusion/ej2-base';
import SignaturePad from 'signature_pad';
import { ImageModel } from 'src/app/models/ImageModel';
import { AnalyticsUserComponent } from '../../admin/analytics-user/analytics-user.component';

@Component({
  selector: 'app-get-verified',
  templateUrl: './get-verified.component.html',
  styleUrls: ['./get-verified.component.css']
})
export class GetVerifiedComponent implements OnInit {

  verifyForm: UntypedFormGroup;
  currentUser: any;
  names: any;
  lname: any;
  userGlobal: any;

  model: any;
  binDoc = undefined;
  base64Image = undefined;
  mobileBase64Image: any;
  showLogoInput = true;
  pdfErrorMsg: any;
  imgErrorMsg: any;
  imgLink: any;

  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;

  userApplied: any;
  timeStamp: any;

  verify: any;
  attachment: any;
  attachmentFile: any;
  isUsa: boolean;
  isCanada: boolean;
  otherCountry: boolean = true;
  selectedFilesForDisplay: any = [];

  answer: any;
  securityModal: boolean;
  signModal: boolean;
  signaturePad: SignaturePad;

  //Signature
  showCanvas = false;
  signing = '';
  canvas: any;
  applicantSigned = false;
  co_applicantSigned = false;
  signature: any;
  isMobile = false;

  profileImgLink: any;
  imgURL: any;
  signed: boolean = false;
  modelSignature: any;

  public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')',
    ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

  public postalCode = ['(', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ')'];

  public zipCode = ['(', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ')'];

  public postalCodeMask = ['(', /[A-Za-z]/, /[0-9]/, /[A-Za-z]/, ')', ' ', '(', /[0-9]/, /[A-Za-z]/, /[0-9]/, ')'];

  // ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

  countryList = ["Afghanistan", "Albania", "Algeria", "American Samoa", "Andorra", "Angola", "Anguilla",
    "Antarctica", "Antigua and Barbuda", "Argentina", "Armenia", "Aruba", "Australia", "Austria",
    "Azerbaijan", "Bahamas (the)", "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize",
    "Benin", "Bermuda", "Bhutan", "Bolivia (Plurinational State of)", "Bonaire, Sint Eustatius and Saba",
    "Bosnia and Herzegovina", "Botswana", "Bouvet Island", "Brazil", "British Indian Ocean Territory (the)",
    "Brunei Darussalam", "Bulgaria", "Burkina Faso", "Burundi", "Cabo Verde", "Cambodia", "Cameroon",
    "Canada", "Cayman Islands (the)", "Central African Republic (the)", "Chad", "Chile", "China",
    "Christmas Island", "Cocos (Keeling) Islands (the)", "Colombia", "Comoros (the)", "Congo (the Democratic Republic of the)",
    "Congo (the)", "Cook Islands (the)", "Costa Rica", "Croatia", "Cuba", "Curaçao", "Cyprus", "Czechia",
    "Côte d'Ivoire", "Denmark", "Djibouti", "Dominica", "Dominican Republic (the)", "Ecuador", "Egypt",
    "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia", "Eswatini", "Ethiopia", "Falkland Islands (the) [Malvinas]",
    "Faroe Islands (the)", "Fiji", "Finland", "France", "French Guiana", "French Polynesia", "French Southern Territories (the)",
    "Gabon", "Gambia (the)", "Georgia", "Germany", "Ghana", "Gibraltar", "Greece", "Greenland", "Grenada",
    "Guadeloupe", "Guam", "Guatemala", "Guernsey", "Guinea", "Guinea-Bissau", "Guyana", "Haiti",
    "Heard Island and McDonald Islands", "Holy See (the)", "Honduras", "Hong Kong", "Hungary", "Iceland",
    "India", "Indonesia", "Iran (Islamic Republic of)", "Iraq", "Ireland", "Isle of Man", "Israel",
    "Italy", "Jamaica", "Japan", "Jersey", "Jordan", "Kazakhstan", "Kenya", "Kiribati", "Korea (the Democratic People's Republic of)",
    "Korea (the Republic of)", "Kuwait", "Kyrgyzstan", "Lao People's Democratic Republic (the)", "Latvia",
    "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania", "Luxembourg", "Macao",
    "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands (the)", "Martinique",
    "Mauritania", "Mauritius", "Mayotte", "Mexico", "Micronesia (Federated States of)", "Moldova (the Republic of)",
    "Monaco", "Mongolia", "Montenegro", "Montserrat", "Morocco", "Mozambique", "Myanmar", "Namibia", "Nauru",
    "Nepal", "Netherlands (the)", "New Caledonia", "New Zealand", "Nicaragua", "Niger (the)", "Nigeria", "Niue",
    "Norfolk Island", "Northern Mariana Islands (the)", "Norway", "Oman", "Pakistan", "Palau", "Palestine, State of", "Panama",
    "Papua New Guinea", "Paraguay", "Peru", "Philippines (the)", "Pitcairn", "Poland", "Portugal", "Puerto Rico",
    "Qatar", "Republic of North Macedonia", "Romania", "Russian Federation (the)", "Rwanda", "Réunion",
    "Saint Barthélemy", "Saint Helena, Ascension and Tristan da Cunha", "Saint Kitts and Nevis", "Saint Lucia",
    "Saint Martin (French part)", "Saint Pierre and Miquelon", "Saint Vincent and the Grenadines", "Samoa",
    "San Marino", "Sao Tome and Principe", "Saudi Arabia", "Senegal", "Serbia", "Seychelles", "Sierra Leone",
    "Singapore", "Sint Maarten (Dutch part)", "Slovakia", "Slovenia", "Solomon Islands", "Somalia",
    "South Africa", "South Georgia and the South Sandwich Islands", "South Sudan", "Spain", "Sri Lanka",
    "Sudan (the)", "Suriname", "Svalbard and Jan Mayen", "Sweden", "Switzerland", "Syrian Arab Republic",
    "Taiwan", "Tajikistan", "Tanzania, United Republic of", "Thailand", "Timor-Leste", "Togo", "Tokelau",
    "Tonga", "Trinidad and Tobago", "Tunisia", "Turkey", "Turkmenistan", "Turks and Caicos Islands (the)",
    "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates (the)", "United Kingdom of Great Britain and Northern Ireland (the)",
    "United States Minor Outlying Islands (the)", "United States of America", "Uruguay", "Uzbekistan", "Vanuatu", "Venezuela (Bolivarian Republic of)",
    "Viet Nam", "Virgin Islands (British)", "Virgin Islands (U.S.)", "Wallis and Futuna", "Western Sahara", "Yemen", "Zambia", "Zimbabwe",
    "Åland Islands"
  ];

  province = ["Ontario", 'Quebec', "Nova Scotia", "Yukon", "Newfoundland and Labrador", "Manitoba", "Sakatchewan", "New Brunswick",
    "Nunavut", "Alberta", "British Columbia", "Prince Edward Island", "Northwest Territories"
  ];

  states = ["Alabama", "Alaska", "American Samoa", "Arizona", "Arkansas", "California", "Colorado", "Connecticut",
    "Delaware", "District Of Columbia", "Federated States Of Micronesia", "Florida", "Georgia", "Guam",
    "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Marshall Islands",
    "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada",
    "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", "Northern Mariana Islands", "Ohio",
    "Oklahoma", "Oregon", "Palau", "Pennsylvania", "Puerto Rico", "Rhode Island", "South Carolina", "South Dakota",
    "Tennessee", "Texas", "Utah", "Vermont", "Virgin Islands", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"
  ];

  security = ["What was your childhood nickname?", 'In what city did you meet your spouse/significant other?', "What is the name of your favorite childhood friend?",
    "What street did you live on in third grade?", "What is the middle name of your youngest child?", "What is your oldest sibling's middle name?",
    "What school did you attend for sixth grade?", "What is your oldest cousin's first and last name?", "What was the name of your first stuffed animal?",
    "In what city or town was your first job?", "What is your maternal grandmother's maiden name?", "What city were you born in?", "In what city or town did your parents meet?"
  ];


  constructor(private authService: AuthService,
    private fb: UntypedFormBuilder,
    private userService: UserService,
    private verificationService: VerificationService,
    private mediaService: MediaService,
    private location: Location) { }

  ngOnInit(): void {
    this.currentUser = this.authService.getGlobalUser();
    this.verifyForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required]],
      cellPhone: [''],
      // streetAddress: [''],
      // streetAddress2: [''],
      // country: [''],
      // city: [''],
      // state: [''],
      // postal: [''],
      pdfLink: [''],
      applicantSignature: [''],
      fullName: ['', [Validators.required]],
      date: ['', [Validators.required]],
      acceptance: [false, [Validators.required]],
      userType: ['User'],
      securityQuestion: [''],
      answer: ['']
    });
    this.timeStamp = new Date();
    this.verificationService.getVerificationReqByEmail(this.currentUser.Email).valueChanges().subscribe(userApplied => {
      this.userApplied = userApplied[0];
    })

    this.userService.getUserByEmail(this.currentUser.Email).valueChanges().subscribe(user => {
      this.userGlobal = user[0];
      let timestamp = new Date();
      if (this.userApplied) {
        // this.securityModal = true;
        this.verifyForm.patchValue({
          firstName: this.userGlobal.GivenName ? this.userGlobal.GivenName : '',
          lastName: this.userGlobal.LastName ? this.userGlobal.LastName : '',
          email: this.userGlobal.Email ? this.userGlobal.Email : '',
          cellPhone: this.userGlobal.cellPhone ? this.userGlobal.cellPhone : '',
          date: this.userGlobal.date ? this.userGlobal.date : '',
          // streetAddress: this.userApplied.streetAddress ? this.userApplied.streetAddress : '',
          // streetAddress2: this.userApplied.streetAddress2 ? this.userApplied.streetAddress2 : '',
          // country: this.userApplied.country ? this.userApplied.country : '',
          // city: this.userApplied.city ? this.userApplied.city : '',
          // state: this.userApplied.state ? this.userApplied.state : '',
          // postal: this.userApplied.postal ? this.userApplied.postal : '',
          fullName: this.userGlobal.GivenName + ' ' + this.userGlobal.LastName ? this.userGlobal.GivenName + ' ' + this.userGlobal.LastName : '',
          acceptance: this.userApplied.acceptance ? this.userApplied.acceptance : '',
          securityQuestion: this.userApplied.securityQuestion ? this.userApplied.securityQuestion : '',
          answer: this.userApplied.answer ? this.userApplied.answer : '',
          //pdfLink: this.userApplied.pdfLink ? this.userApplied.pdfLink : '',
          applicantSignature: this.userApplied.applicantSignature ? this.userApplied.applicantSignature : '',

        });

        //this.setUpCanvas();
      } else {
        this.verifyForm.patchValue({
          firstName: this.userGlobal.GivenName ? this.userGlobal.GivenName : '',
          lastName: this.userGlobal.LastName ? this.userGlobal.LastName : '',
          email: this.userGlobal.Email ? this.userGlobal.Email : '',
          cellPhone: this.userGlobal.cellPhone ? this.userGlobal.cellPhone : '',
          date: this.userGlobal.date ? this.userGlobal.date : new Date(),
          fullName: this.userGlobal.GivenName + ' ' + this.userGlobal.LastName ? this.userGlobal.GivenName + ' ' + this.userGlobal.LastName : '',
        });
        //this.setUpCanvas();
      }
    })
    //this.setUpCanvas();

  }

  answerTypeChangeState(event) {
  }

  answerTypeChangeAddress(event) {
    if (event == 'Canada') {
      this.isCanada = true;
      this.isUsa = false;
      this.otherCountry = false;
    } else if (event == "United States of America") {
      this.isUsa = true;
      this.isCanada = false;
      this.otherCountry = false;
    } else {
      this.otherCountry = true;
      this.isCanada = false;
      this.isUsa = false;
    }
  }

  handleFileInput(files: FileList) {
    if (!this.validateImage(files[0].name)) {
      this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';
      //this.imgLink.patchValue('');
      return;
    }
    this.binDoc = files[0];
    this.pdfErrorMsg = undefined;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.base64Image = reader.result as string;
    };
  }

  removeFile() {
    this.binDoc = undefined;
    this.base64Image = undefined;
    //this.imgLink.patchValue('');
  }

  removeSavedFile() {
    this.binDoc = undefined;
    this.base64Image = undefined;
   // this.imgLink.patchValue('');
    this.showLogoInput = true;
  }

  private validateImage(name: any) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() === 'png') {
      return true;
    } else if (ext.toLowerCase() === 'jpg') {
      return true;
    } else if (ext.toLowerCase() === 'jpeg') {
      return true;
    } else {
      return false;
    }
  }

  validatePDF(name: any) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() === 'pdf') {
      return true;
    }
    else {
      return false;
    }
  }
  openSignature() {
    this.signModal = true;
    this.setUpCanvas();
  }
  approveSignature() {
    if (this.signaturePad.isEmpty()) {
      this.toast({ html: 'Please Sign the Application!', classes: 'red', type: 'failure' });
      return;
    } else {
      const dataUrl = this.signaturePad.toDataURL();
      this.signature = dataUrl;
      this.verifyForm.get('applicantSignature').patchValue(this.signature);
      this.signed = true;
      //this.updateForm(this.verifyForm);
      this.closeSignature();
    }
  }


  updateForm(verify) {
      verify.status = 'Pending'
      this.verify = this.verificationService.getVerificationReqByEmail(verify.email).valueChanges().subscribe(user => {
        if (user.length == 0) {
          verify.ID = '';
          verify.id = this.currentUser.uid;
          //if()
          if (this.signaturePad.isEmpty()) {
            this.toast({ html: 'Please Sign the Application!', classes: 'red', type: 'failure' });
            return;
          } else {
            const dataUrl = this.signaturePad.toDataURL();
            this.signature = dataUrl;
            this.verifyForm.get('applicantSignature').patchValue(this.signature);
            verify.applicantSignature = this.signature;
            // verify.applicantSignature = this.verifyForm.get('applicantSignature').patchValue(this.signature);
          // }

          if (this.isMobile) {
            const img = new ImageModel();
            img.imgBase64 = this.mobileBase64Image;
            this.mediaService.uploadImage(img).subscribe(upload => {
              if (upload) {
                this.profileImgLink = upload.imgLink;
                this.imgURL = upload.imgLink;
              }
            });
          } else {
            if (this.binDoc) {
              const reader = new FileReader();
              reader.readAsDataURL(this.binDoc);
              reader.onload = () => {
                this.base64Image = reader.result as string;
              };
              const img = new ImageModel();
              img.imgBase64 = this.base64Image.replace(/^data:image\/[a-z]+;base64,/, '');
              this.mediaService.UploadBinImage(this.binDoc).subscribe(
                upload => {
                  this.profileImgLink = upload.imgLink;
                  verify.pdfLink = upload.imgLink;
                  this.verificationService.createVerificationRequest(verify).then(x => { })
                  this.toast({ html: 'You have Successfully requested for a Verification', classes: 'green', type: 'success' });
                  this.goback();
                });
            }

          }
        }
        }
        else {
          if (this.userApplied.reapply == true) {
            verify.status = 'Pending';
            verify.reapply = false;
            verify.ID = this.userApplied.ID;
            verify.id = this.userApplied.id;
            verify.applicantSignature = this.userApplied.applicantSignature;
            if (this.attachment != undefined) {
              this.mediaService.UploadBinImage(this.binDoc).subscribe(upload => {
                if (upload) {
                  verify.pdfLink = upload.imgLink;

                  this.verificationService.updateVerificationRequest(verify).then(x => { })
                  this.toast({ html: 'You have Successfully requested for a re-application', classes: 'green', type: 'success' });
                  this.goback();
                }
              });
            } else {
              verify.ID = this.userApplied.ID;
              verify.id = this.userApplied.id;
              verify.pdfLink = this.userApplied.pdfLink;
              this.verificationService.updateVerificationRequest(verify).then(x => { })
              this.toast({ html: 'You have Successfully requested for a re-application', classes: 'green', type: 'success' });
              this.goback();
            }

          }
        }
        this.verify.unsubscribe();
        this.closeSignature();
      })
    

  }


  goback() {
    setTimeout(() => {
      this.location.back();
    }, 1000);
  }

  sign(signing) {
    this.showCanvas = true;
    this.signing = signing;
    setTimeout(() => {
      this.canvas = document.querySelector('canvas');
      const parentWidth = $(this.canvas).parent().outerWidth();
      this.canvas.setAttribute('width', parentWidth);
      this.canvas.setAttribute('height', '90%');
      this.signaturePad = new SignaturePad(this.canvas, {
        backgroundColor: 'rgb(255,255,255)'
      });
    }, 50);

  }

  setUpCanvas() {
    setTimeout(() => {
      this.canvas = document.querySelector('canvas');
      const parentWidth = $(this.canvas).parent().outerWidth();
      this.canvas.setAttribute('width', parentWidth);
      this.canvas.setAttribute('height', '100%');
      this.signaturePad = new SignaturePad(this.canvas, {
        backgroundColor: 'rgb(211,211,211)',
      });
    }, 50);

  }

  closeSignature() {
    this.signModal = false;
  }


  clearSignature() {
    this.signaturePad.clear();
    this.applicantSignature.reset();
  }

  BackToApp() {
    this.showCanvas = false;
  }
  closeSecurityModal() {
    this.securityModal = false;
  }
  secured() {
    if (this.answer == this.userApplied.answer) {
      this.closeSecurityModal();
    } else {
      this.toast({ html: 'The answer you entered is wrong please try again', classes: 'red', type: 'failure' });
    }
  }

  toast(obj) {
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green';
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
    }, 2000);
  }

  get firstName() { return this.verifyForm.get('firstName'); }
  get lastName() { return this.verifyForm.get('lastName'); }
  get email() { return this.verifyForm.get('email'); }
  get cellPhone() { return this.verifyForm.get('cellPhone'); }
  get streetAddress() { return this.verifyForm.get('streetAddress'); }
  get streetAddress2() { return this.verifyForm.get('streetAddress2') }
  get country() { return this.verifyForm.get('country'); }
  get city() { return this.verifyForm.get('city'); }
  get state() { return this.verifyForm.get('state'); }
  get postal() { return this.verifyForm.get('postal'); }
  get fullName() { return this.verifyForm.get('fullName'); }
  get date() { return this.verifyForm.get('date'); }
  get acceptance() { return this.verifyForm.get('acceptance'); }
  get pdfLink() { return this.verifyForm.get('pdfLink'); }
  get applicantSignature() { return this.verifyForm.get('applicantSignature') }
}
