import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// App Componenets
import { RegisterComponent } from './register/register.component';
import { AuthMainComponent } from './auth-main/auth-main.component';
import { AuthRoutingModule } from './auth-routing.module';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { LoginComponent } from './login/login.component';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { RegisterAdminComponent } from './register-admin/register-admin.component';
import { OauthRegistrationComponent } from './oauth-registration/oauth-registration.component';
import { MobileRegistrationComponent } from './mobile-registration/mobile-registration.component';
// import { TextMaskModule } from 'angular2-text-mask';
import { NgOtpInputModule } from 'ng-otp-input';
import { RegisterFromInviteComponent } from './register-from-invite/register-from-invite.component';
import { AccountSetupComponent } from './account-setup/account-setup.component';
// import {Ng2TelInputModule} from 'ng2-tel-input';
import { EmployeeAccountSetupComponent } from './employee-account-setup/employee-account-setup.component';
import { SharedModule } from '../shared/shared.module';
import { RegisterContactFromInviteComponent } from './register-contact-from-invite/register-contact-from-invite.component';
import { RegisterFromAdminInviteComponent } from './register-from-admin-invite/register-from-admin-invite.component';
// import { SMSVerificationComponent } from '../shared/smsverification/smsverification.component';


@NgModule({

  declarations: [
    AuthMainComponent,
    RegisterComponent,
    VerifyEmailComponent,
    ChangePasswordComponent,
    LoginComponent,
    RegisterAdminComponent,
    OauthRegistrationComponent,
    MobileRegistrationComponent,
    RegisterFromInviteComponent,
    RegisterFromAdminInviteComponent,
    AccountSetupComponent,
    EmployeeAccountSetupComponent,
    RegisterContactFromInviteComponent,
    // SMSVerificationComponent,
  ],

  imports: [
    CommonModule,
    AuthRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    // TextMaskModule,
    NgOtpInputModule,
    // Ng2TelInputModule,
    SharedModule,
  ],
exports:[LoginComponent],
  providers: [
    AuthService,
  ]

})

export class AuthModule { }
