import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit } from '@angular/core';
import { queryReportService } from '../../../services/google-analytics/queryReport.service';
import { format } from "date-fns"
import moment from 'moment';
import { environment } from 'src/environments/environment';
import { ChartOptions } from 'chart.js';
//import { Color, Label, MultiDataSet, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';
import { FormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FilterPipe } from 'src/app/filter.pipe';
import { NgxChartsModule } from '@swimlane/ngx-charts';

declare var $: any;

@Component({
  selector: 'app-analytics-channel',
  templateUrl: './analytics-channel.component.html',
  styleUrls: ['./analytics-channel.component.css'],
  standalone: true,
  imports: [CommonModule, FormsModule, FilterPipe, NgxChartsModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AnalyticsChannelComponent implements OnInit {

  ispageClicked: boolean = true;
  isuserClicked: boolean = false;
  ischannelClicked: boolean = false;
  filterByDateChannel: boolean = false;
  startDate: any
  endDate: any
  defaultStartDate: any
  defaultEndDate: any
  colors = [
    "#fcba03",
    "#fa8c5c",
    "#9fc934",
    "#60d17e",
    "#45afed",
    "#7c5cdb",
    "#ce5cdb",
    "#db5c97",
  ];
  // doughnutChartLabels: Label[]
  // doughnutChartData: MultiDataSet = [];
  // doughnutChartType: ChartType = 'doughnut';
  // doughnutChartColors: Color[];
  public doughnutChartLegend = true;
  public doughnutChartPlugins = [];
  public doughnutChartOptions: ChartOptions = {
    responsive: true,
  };
  // ----------------2--------------------------
  // doughnutChart2Labels: Label[]
  // doughnutChart2Data: MultiDataSet = [];
  // doughnutChart2Type: ChartType = 'doughnut';
  // doughnutChart2Colors: Color[];
  public doughnutChart2Legend = true;
  public doughnutChart2Plugins = [];
  public doughnutChart2Options: ChartOptions = {
    responsive: true,
  };

  sourceResult: any = []
  source_data: any = []
  source_label: any = []
  source_color: any = []

  deviceCategoryResult: any = []
  device_data: any = []
  device_label: any = []
  device_color: any = []

  organicSearchResult: any = []
  organicSearchSession: any;

  socialInteractionRes: any = []
  socialInteractionSess: any;

  directSessionResult: any = []
  directSessions: any;

  referalSessionResult: any = []
  referalSessions: any;

  public sourceDeviceChart = {}

  sourceWithDeviceRes: any = []
  sourceDevice: any = []
  users: any = []
  totalUsers: any;

  totalActiveUsers: any

  dateFilterActive: any;
  dateInvalid = false;
  propertyId = environment['firebase'].propertyId

  sourceDeviceBgColor: any = []
  trackStartDate: string | number | string[];
  trackEndDate: string | number | string[];
  createCalenderForm: UntypedFormGroup;
  totalActiveUsersRes: any;

  dataVBC1: any[];
  viewVBC: [number, number] = [900, 400]; // Adjust the size as needed
  animationsVBC: boolean = true;
  legendVBC: boolean = true;
  xAxisVBC: boolean = true;
  // yAxisLabelVBC: boolean = true;
  showYAxisLabelVBC: boolean = true;
  yAxisLabelVBC: string = 'Users';
  // dataLabelFormatterVBC: any;

  dataVBC2: any[] = [];
  dataVBC3: any[] = [];



  dataLabelFormatterVBC(tooltipText: any) {
    return tooltipText;
  }

  constructor(private qs: queryReportService, private fb: UntypedFormBuilder,) {
    // monkeyPatchChartJsTooltip();
    // monkeyPatchChartJsLegend();
  }
  ngOnInit(): void {

    let currYear = (new Date()).getFullYear();
    let defaultStartDate = new Date(currYear, 1, 1)
    // let defaultStartDate = new Date(currYear, 8, 28)
    let defaultEndDate = moment().toDate()
    this.defaultEndDate = format(new Date(defaultEndDate), "yyyy-MM-dd")
    this.defaultStartDate = format(new Date(defaultStartDate), "yyyy-MM-dd")
    setTimeout(
      () => {
        this.channelGroupingGA4(this.defaultStartDate, this.defaultEndDate);
        this.sourcesAndDevicesGA4(this.defaultStartDate, this.defaultEndDate);
        this.getTotalUnsignedUsers(this.defaultStartDate, this.defaultEndDate, 0)
      }, 500)
    this.createCalenderForm = this.fb.group({
      analyticsStartDate: ['', [Validators.required]],
      analyticsEndDate: ['', [Validators.required]],

    });

  }
  isValidStartDate(startDate) { return startDate.includes("daysAgo") || startDate === "yesterday" || startDate === "today"; }
  isValidEndDate(endDate) { return endDate.includes("daysAgo") || endDate === "yesterday" || endDate === "today"; }
  isUndefinedSource(): boolean { return typeof this.sourceResult === 'undefined' || this.sourceResult === null || this.sourceResult.length === 0; }
  isUndefinedDeviceCategory(): boolean { return typeof this.deviceCategoryResult === 'undefined' || this.deviceCategoryResult === null || this.deviceCategoryResult.length === 0; }
  isUndefinedSourceDevice(): boolean { return typeof this.sourceWithDeviceRes === 'undefined' || this.sourceWithDeviceRes === null || this.sourceWithDeviceRes.length === 0; }
  isUndefined(result: any): boolean { return typeof result === 'undefined' || result === null || result.length === 0 }

  sourceChart() {
    // this.doughnutChart2Labels = []
    // this.doughnutChart2Data = []
    // this.doughnutChart2Colors = [
    //   {
    //     backgroundColor: this.source_color
    //   }
    // ]
    // this.doughnutChart2Data = [this.source_data]
    // this.doughnutChart2Labels = this.source_label
    // this.doughnutChart2Options = {
    //   responsive: true
    // };


    if (this.source_data.length !== this.source_label.length) {
      console.error('Data and labels lengths do not match');
      return;
    }

    this.dataVBC2 = this.source_data.map((value, index) => ({
      name: this.source_label[index],
      value: value ?? 0,
      label: `${(value / this.source_data.reduce((a, b) => a + b, 0)) * 100}%`
    }));


    this.source_data = [];
    this.source_label = [];
    this.source_color = [];
    // this.dataVBC2 = []
  }

  deviceChart() {
    // this.doughnutChartLabels = []
    // this.doughnutChartData = []
    // this.doughnutChartColors = [
    //   {
    //     backgroundColor: this.device_color
    //   }
    // ]
    // this.doughnutChartData = [this.device_data]
    // this.doughnutChartLabels = this.device_label
    // this.doughnutChartOptions = {
    //   responsive: true
    // };

    if (this.device_data.length !== this.device_label.length) {
      console.error('Data and labels lengths do not match');
      return;
    }

    this.dataVBC3 = this.device_data.map((value, index) => ({
      name: this.device_label[index],
      value: value ?? 0,
      label: `${(value / this.device_data.reduce((a, b) => a + b, 0)) * 100}%`
    }));


    this.device_data = [];
    this.device_label = [];
    this.device_color = [];
    // this.dataVBC3 = []
  }

  sourceAndDeviceChart() {
    this.sourceDeviceChart = {
      "datasets": [
        {
          type: 'bar',
          label: 'Source & Device (Bar)',
          data: this.users,
          borderColor: this.sourceDeviceBgColor,
          backgroundColor: this.sourceDeviceBgColor
        }

      ],
      "labels": this.sourceDevice,
      "options": {
        "legend": {
          "text": "",
          "display": true,
        },
        "scales": {
          "yAxes": [{
            "ticks": {
              "beginAtZero": true,
              stepSize: 50
            },
            "scaleLabel": {
              "display": true,
              "labelString": 'Total users'
            }
          }],
          "xAxes": [{
            "ticks": {
              "min": "Monday",
              "max": "Sunday",
            },
            "scaleLabel": {
              "display": true,
              "labelString": 'Device type'
            }
          }],
        }
      }
    }

    if (this.users.length !== this.sourceDevice.length) {
      console.error('Data and labels lengths do not match');
    }

    this.dataVBC1 = [
      {
        name: 'Source and Device',
        series: this.users.map((value, index) => ({
          name: this.sourceDevice[index],
          value: value ?? 0
        }))
      }
    ];


    this.users = []
    this.sourceDevice = []
    this.sourceDeviceBgColor = []
    // this.dataVBC1 = []
  }

  pieChartLabel(series: any[], name: string): string {
    const item = series.find(data => data.name === name);
    return item ? item.label : name;
  }

  channelGroupingGA4(startDate, endDate) {
    this.startDate = !this.isValidStartDate(startDate) ? format(new Date(startDate), "yyyy-MM-dd") : startDate;
    this.endDate = !this.isValidEndDate(endDate) ? format(new Date(endDate), "yyyy-MM-dd") : endDate;
    this.organicSearchResult = [];
    this.socialInteractionRes = [];
    this.directSessionResult = [];
    this.referalSessionResult = [];
    this.qs.getChannelGrouping(this.propertyId, this.startDate, this.endDate).subscribe((report) => {
      if (report && Object.keys(report).length > 0) {
        // Organic Search Sessions - reports[0]
        this.organicSearchResult = report.Result.Reports[0].Rows;
        if (!this.isUndefined(this.organicSearchResult)) {
          this.organicSearchSession = this.organicSearchResult[0].MetricValues[0].Value;
        } else {
          this.organicSearchSession = 0;
        }

        // Social Sessions - reports[1]
        this.socialInteractionRes = report.Result.Reports[1].rows;
        if (!this.isUndefined(this.socialInteractionRes)) {
          this.socialInteractionSess = this.socialInteractionRes[0].MetricValues[0].Value;
        } else {
          this.socialInteractionSess = 0;
        }

        // Direct Sessions - reports[2]
        this.directSessionResult = report.Result.Reports[2].Rows;
        if (!this.isUndefined(this.directSessionResult)) {
          this.directSessions = this.directSessionResult[0].MetricValues[0].Value;
        } else {
          this.directSessions = 0;
        }

        // Direct Sessions - reports[3]
        this.referalSessionResult = report.Result.Reports[3].Rows;
        if (!this.isUndefined(this.referalSessionResult)) {
          this.referalSessions = this.referalSessionResult[0].MetricValues[0].Value;
        } else {
          this.referalSessions = 0;
        }
      } else {
        console.log("Report is null, no data/analytics/hits recieved")
      }
    });

  }
  sourcesAndDevicesGA4(startDate, endDate) {
    this.startDate = !this.isValidStartDate(startDate) ? format(new Date(startDate), "yyyy-MM-dd") : startDate;
    this.endDate = !this.isValidEndDate(endDate) ? format(new Date(endDate), "yyyy-MM-dd") : endDate;
    this.sourceResult = [];
    this.deviceCategoryResult = [];
    this.totalUsers = 0;
    this.qs.getSourceAndDevice(this.propertyId, this.startDate, this.endDate).subscribe((report) => {
      if (report && Object.keys(report).length > 0) {

        // Sources - reports[0]
        this.sourceResult = report.Result.Reports[0].Rows;
        if (!this.isUndefinedSource() && this.sourceResult.length > 0 && this.sourceResult) {
          this.sourceResult.forEach((row: any, idx: any) => {

            this.source_data.push(row.MetricValues[0].Value);
            this.source_label.push(row.DimensionValues[0].Value)
            var randNum = Math.floor(Math.random() * this.colors.length);
            if (this.source_color.includes(this.colors[randNum])) {
              randNum = Math.floor(Math.random() * this.colors.length);
              this.source_color.push(this.colors[randNum])
            } else {
              this.source_color.push(this.colors[randNum])
            }
          })
        } else {
          console.log("No source found")
        }

        // Device category - reports[1]
        this.deviceCategoryResult = report.Result.Reports[1].Rows;
        if (!this.isUndefinedDeviceCategory() && this.deviceCategoryResult.length > 0 && this.deviceCategoryResult) {
          this.deviceCategoryResult.forEach((row: any, idx: any) => {

            this.device_data.push(row.MetricValues[0].Value);
            this.device_label.push(row.DimensionValues[0].Value)
            var randNum = Math.floor(Math.random() * this.colors.length);
            if (this.device_color.includes(this.colors[randNum])) {
              randNum = Math.floor(Math.random() * this.colors.length);
              this.device_color.push(this.colors[randNum])
            } else {
              this.device_color.push(this.colors[randNum])
            }

          })
        } else {
          console.log("No device found.")
        }

        // Source and Device - reports[2]
        this.sourceWithDeviceRes = report.Result.Reports[2].Rows;
        if (!this.isUndefinedSourceDevice() && this.sourceWithDeviceRes.length > 0 && this.sourceWithDeviceRes) {
          this.sourceWithDeviceRes.forEach((row: any) => {
            this.sourceDevice.push(row.DimensionValues[0].Value + " " + row.DimensionValues[1].Value);
            this.users.push(row.MetricValues[0].Value);
            var randNum = Math.floor(Math.random() * this.colors.length);
            this.sourceDeviceBgColor.push(this.colors[randNum])
          })
          let maxStr = this.users[0]
          let max = parseInt(maxStr)
          this.users.forEach((x) => {
            if (max < parseInt(x)) {
              max = x;
              this.totalUsers = max;
            } else {
              this.totalUsers = max;
            }
          })

        } else {
          console.log("No source device found")
        }
      } else {
        console.log("Report is null, no data/analytics/hits recieved")
      }

      setTimeout(() => {
        if (!this.isUndefinedSource() && this.sourceResult.length > 0 && this.sourceResult) {
          this.sourceChart()
        } else {
          console.log('No source data found.')
        }
      }, 1500)
      setTimeout(() => {
        if (!this.isUndefinedDeviceCategory() && this.deviceCategoryResult.length > 0 && this.deviceCategoryResult) {
          this.deviceChart()
        }
        else {
          console.log('No device data found.')
        }
      }, 1500)
      setTimeout(() => {
        if (!this.isUndefinedSourceDevice() && this.sourceWithDeviceRes.length > 0 && this.sourceWithDeviceRes) {
          this.sourceAndDeviceChart()
        }
        else {
          console.log('No device and source data found.')
        }
      }, 1500)

    });
  }

  customDateUpdateChannel(start_date, end_date) {
    let startdate = start_date.value;
    let endDate = end_date.value;
    let comparison: boolean | any;
    comparison = format(new Date(endDate), "yyyy-MM-dd") > format(new Date(startdate), "yyyy-MM-dd")
    if (comparison) {
      this.dateInvalid = false;
      this.channelGroupingGA4(startdate, endDate);
      this.sourcesAndDevicesGA4(startdate, endDate);
      this.getTotalUnsignedUsers(startdate, endDate, 0)
    } else {
      this.dateInvalid = true;
    }
  }
  customDateForChannels() {
    this.isuserClicked = false
    this.ispageClicked = false
    this.ischannelClicked = true
    this.filterByDateChannel = true;
    this.dateFilterActive = false;
  }

  setChannelFlags(isDaily: boolean, isWeekly: boolean, isMonthly: boolean): void {
    this.isuserClicked = false;
    this.ispageClicked = false;
    this.ischannelClicked = true;
    this.filterByDateChannel = false;
    this.dateFilterActive = false;

    const dateRange = isDaily ? "yesterday" : isWeekly ? "7daysAgo" : isMonthly ? "30daysAgo" : this.defaultStartDate;
    this.channelGroupingGA4(dateRange, 'today');
    this.sourcesAndDevicesGA4(dateRange, 'today');
    this.getTotalUnsignedUsers(dateRange, 'today', 0)

  }

  dailyChannels() { this.setChannelFlags(true, false, false) }

  weeklyChannels() { this.setChannelFlags(false, true, false) }

  monthlyChannels() { this.setChannelFlags(false, false, true) }

  dateChangeStartDate() { this.trackStartDate = $('#analyticsStartDate').val(); }

  dateChangeEndDate() { this.trackEndDate = $('#analyticsEndDate').val(); }

  get analyticsStartDate() { return this.createCalenderForm.get('analyticsStartDate'); }
  get analyticsEndDate() { return this.createCalenderForm.get('analyticsEndDate'); }

  getTotalUnsignedUsers(startDate, endDate, filter_session) {
    this.startDate = startDate;
    this.endDate = endDate;
    this.totalActiveUsers = 0
    this.qs.getTotalUnsignedUsers(this.propertyId, filter_session, startDate, endDate).subscribe((rep) => {
      if (rep && Object.keys(rep).length > 0) {
        // this.totalActiveUsersRes = rep.Result.Reports[0].Rows
        this.totalActiveUsersRes = rep.Reports[0].Rows
        if (!this.isUndefined(this.totalActiveUsersRes)) {
          // this.totalActiveUsers = rep.Result.Reports[0].Rows[0].MetricValues[0].Value
          this.totalActiveUsers = rep.Reports[0].Rows[0].MetricValues[0].Value
        }
      }
    })
  }
}
