import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from "@angular/common";
import { environment } from 'src/environments/environment';
// import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { distinctUntilChanged } from 'rxjs/operators';

declare var gtag: any;
declare var window: any;

@Injectable({
    providedIn: 'root'
})
export class GoogleAnalyticsService {

    displayName = environment.firstNation.displayName;
    trackingId: any;


    constructor(
        // public angulartics: Angulartics2GoogleGlobalSiteTag,
        @Inject(DOCUMENT) document: any,
        private router: Router,
        private location: Location,

    ) { }



    // GA3 -> TRACKER
    loadGoogleAnalytics(userid, trackingId) {
        // injecting GA main script asynchronously
        const script = document.createElement('script');
        script.src = `https://www.googletagmanager.com/gtag/js?id=${this.trackingId}`;
        script.async = true;
        document.body.appendChild(script);

        // preparing GA API to be usable even before the async script is loaded
        window.dataLayer = window.dataLayer || [];
        window.gtag = function () {
            window.dataLayer.push(arguments);
        };
        gtag('js', new Date());
        gtag('set',
            {
                'custom_map': { 'dimension1': 'user_id' }
            })
        gtag('config', `${trackingId}`, {
            'user_id': `${userid}`
        });

        this.router.events.pipe(distinctUntilChanged((previous: any, current: any) => {
            if (current instanceof NavigationEnd) {
                return previous.url === current.url;
            }
            return true;
        })).subscribe((x: any) => {
            gtag('event', 'page_view', { page_path: x.url })
        });


    }

    loadGoogleAnalyticsLoggedIn(userid, trackingId) {
        const script = document.createElement('script');
        script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
        script.async = true;
        document.body.appendChild(script);

        // preparing GA API to be usable even before the async script is loaded
        window.dataLayer = window.dataLayer || [];
        window.gtag = function () {
            window.dataLayer.push(arguments);
        };
        gtag('js', new Date());

        gtag('config', trackingId, {
            'user_id': userid
        });
        gtag('set', 'user_properties', {
            'user_id_dimension': userid
        });

        // gtag('send', 'pageview')
        this.router.events.pipe(distinctUntilChanged((previous: any, current: any) => {
            if (current instanceof NavigationEnd) {
                return previous.url === current.url;
            }
            return true;
        })).subscribe((x: any) => {
            gtag('event', 'page_view', { page_path: x.url },)

        });


    }

    loadGoogleAnalyticsNonLoggedIn(trackingId, cookie) {
        const script = document.createElement('script');
        script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
        script.async = true;
        document.body.appendChild(script);

        // preparing GA API to be usable even before the async script is loaded
        window.dataLayer = window.dataLayer || [];
        window.gtag = function () {
            window.dataLayer.push(arguments);
        };

        gtag('js', new Date());

        gtag('config', trackingId, {
            'unsigned_user': cookie
        });
        gtag('set', 'user_properties', {
            'unsigned_user': cookie
        });

        this.router.events.pipe(distinctUntilChanged((previous: any, current: any) => {
            if (current instanceof NavigationEnd) {
                return previous.url === current.url;
            }
            return true;
        })).subscribe((x: any) => {
            gtag('event', 'page_view', { page_path: x.url },)
        });

    }

    setCookie(name, value, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setDate(date.getDate() + days);
            expires = "; expires=" + date.toUTCString();
        } else var expires = "";

        document.cookie = name + "=" + (value || "") + expires + "; path=/goingMobile/;HttpOnly;SameSite=Lax;Secure;";
    }


    getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');

        var cookieValue = ca.length > 0 ? ca[0] : null
        return cookieValue;
    }


    startTracking() {
        // activate router navigation tracking
        if (this.trackingId) {
           // this.angulartics.startTracking();
        }
    }
}