
import { Component, OnInit, Input } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounceIn } from 'ng-animate';
import { icons } from 'src/app/models/SocialMediaIcons';
import { platformColors} from 'src/app/models/SocialMediaPostModel';

@Component({
    selector: 'app-social-media-platform-user',
    templateUrl: './platform-user.component.html',
    styleUrls: ['./platform-user.component.css'], animations: [
        trigger('swing', [transition('* => *', useAnimation(bounceIn))])
    ],
})

export class PlatformUserComponent implements OnInit {

    constructor(    ) {

    }

    @Input() data
    socialMediaIcons=icons
    ngOnInit() {

    }

    getBorder(platform){
        var classList='';
        switch (platform) {
            case 'instagram':
                classList = 'instagram-wrapper wrapper--rounded'; 
                break
            case 'tiktok':
                classList = 'tiktok-wrapper wrapper--rounded'; 
                break
            case 'pineteret':
                classList = 'border-[#E60023]'; 
                break
            case 'twitter':
                classList = 'border-[#1da1f2]'; 
                break
            case 'linkedin':
                classList = 'border-[#0072b1]'; 
                break
            case 'reddit':
                classList = 'border-[#FF4500]'; 
                break
            default:
                classList = 'border-[' +platformColors[this.data.platform] +']';
        }
        return classList;
    }

    getStyleBorder(){
        
        return {'border-color': platformColors[this.data.platform], 'border-style': 'solid'}
    }
    borderdPlatform(){
        return ['instagram','tiktok'].includes(this.data.platform) ? false : true
    }
}
