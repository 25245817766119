import { Component, Input, Output, EventEmitter, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnChanges, OnInit {

  @Input() totalItems: number | any = 0;
  @Input() itemsPerPage: number | any = 1;
  @Input() currentPage: number | any = 0;
  @Output() currentPageChange = new EventEmitter<number>();

  // @Input() userId = '';
  // @Input() selectedOption = '';

  // @Output() updateId = new EventEmitter<string>();
  // @Output() closeClicked = new EventEmitter();
  // @Output() messageNotSent = new EventEmitter();
  // @Output() message_Sent = new EventEmitter();
  // @Output() emailNotSent = new EventEmitter();
  // @Output() email_Sent = new EventEmitter();

  totalPages: number = 1;
  pages: number[] = [];

  ngOnInit(): void {
    this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
    this.pages = Array.from({ length: this.totalPages }, (v, k) => k + 1);

  }

  ngOnChanges() {
    this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
    this.pages = Array.from({ length: this.totalPages }, (v, k) => k + 1);
  }

  changePage(page: number) {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
      this.currentPageChange.emit(this.currentPage);
    }
  }

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.changePage(this.currentPage + 1);
    }
  }

  prevPage() {
    if (this.currentPage > 1) {
      this.changePage(this.currentPage - 1);
    }
  }
}
