import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
// import {CdkDragDrop, CdkDropList, CdkDrag, moveItemInArray} from '@angular/cdk/drag-drop';
import {
  CdkDrag,
  CdkDragDrop,
  CdkDragPlaceholder,
  CdkDropList,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { NewsEventService } from 'src/app/services/firebase/news-event.service';
import { HelperService } from 'src/app/services/helper/helper';
@Component({
  selector: 'app-move-elements',
  templateUrl: './move-elements.component.html',
  styleUrls: ['./move-elements.component.css']
})
export class MoveElementsComponent implements OnInit {

  @Input() elementsList;
  @Output() closeClicked = new EventEmitter();
  @Output() addNewElement = new EventEmitter();
  @Input() sectionType;
  lists = [];
  constructor(private eventsService: NewsEventService, private helperService: HelperService) { 
    if(this.elementsList){
      this.elementsList.sort((a, b) => a.position - b.position);
    }
  }

  ngOnInit(): void {
  }


  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.elementsList, event.previousIndex, event.currentIndex);
    this.updatePositions()
  }

  updatePositions(): void {
    this.elementsList.forEach((element, index) => {
      element.position = index;
    });

    this.elementsList.sort((a, b) => a.position - b.position);
  }
  saveSettings(){
    this.eventsService.saveRecords(this.elementsList, this.sectionType).then((succ)=>{
      this.closeClicked.emit();
    }).catch((err)=>{
      this.closeClicked.emit();
    })
  }
  // trackElement(index, element){
  //   return element;
  // }
  getInnerText(text){
    return this.helperService.getInnerText(text);
  }
  truncateHTML(text: string, limit: string): string {
    return  this.helperService.truncateHTML(text, limit);
  }
  closeMoveDialog(){
      this.closeClicked.emit();
    }

    newElement(){
      this.addNewElement.emit()
    }
}
