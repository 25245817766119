import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalConstants } from 'src/app/models/GlobalConstants';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { CustomSectionService } from 'src/app/services/firebase/custom-section.service';
import { EnvironmentService } from 'src/app/services/helper/env';
import { Modal, Ripple, initTE } from "tw-elements";
import 'chartjs-plugin-datalabels';
import { ChartOptions, ChartType, } from 'chart.js';
import { CountersService } from 'src/app/services/firebase/counters.service';
import { HelperService } from 'src/app/services/helper/helper';
import { Meta, Title } from '@angular/platform-browser';
import { NewsEventService } from 'src/app/services/firebase/news-event.service';
import { EmployeeServicesService } from 'src/app/services/firebase/employee-services.service';
import { PostsService } from 'src/app/services/firebase/posts.service';
import { ProductsService } from 'src/app/services/firebase/products.service';
import { AboutService } from 'src/app/services/firebase/about.service';
import { UserService } from 'src/app/services/firebase/user.service';
import { FaqService } from 'src/app/services/firebase/faq.service';
import { RoutMetaService } from 'src/app/services/helper/rout-meta.service';
import { Subject, takeUntil } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit, OnDestroy {
  appSettings: any
  pageSections: any
  section: any
  options = {};
  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  sliderRef: any;
  blogsRef: any;
  eventRef: any;
  productRef: any;
  aboutRef: any;
  serviceRef: any;
  appStoreRef: any;
  socialMediaRef: any;
  footerRef: any;
  welcomeRef: any;
  faqRef: any;
  model: any[];
  colors = ['background: linear-gradient(90deg, #85D8CE 0%, #3094ca 100%);', 'background: linear-gradient(90deg, #FFEDBC  0%, #ED4264 100%);', 'background: linear-gradient(90deg, #00C9FF 0%, #92FE9D 100%);', 'background: linear-gradient(90deg, #ff8bb0 0%, #6ddaff 100%);', 'background: linear-gradient(90deg, #24C6DC 40%, #8d83ff 100%);', 'background: linear-gradient(90deg, #1CD8D2 0%, #93EDC7 100%);', 'background: linear-gradient(90deg, #6e9aff 0%, #a977fa 100%);', 'background: linear-gradient(90deg, #2ceba8 0%, #a8fac6 100%);', 'background: linear-gradient(90deg, #8bd4ff 0%, #6dffc0 100%);', 'background: linear-gradient(90deg, #22c1c3 0%, #fdbb2d 100%);', 'background: linear-gradient(90deg, #eb8b8b 0%, #febd63 100%);', 'background: linear-gradient(90deg, #ba7ee1 0%, #9dce78 100%);', 'background: linear-gradient(90deg, #f99bff 0%, #32afff 100%);', 'background: linear-gradient(90deg, #85D8CE 0%, #3094ca 100%);', 'background: linear-gradient(90deg, #FFEDBC  0%, #ED4264 100%);', 'background: linear-gradient(90deg, #00C9FF 0%, #92FE9D 100%);']
  currentUser: any;

  role: any;
  viewType;
  customSection: unknown;
  allSections: any;
  serviceType: any;

  themeColor: any;
  defaultThemeColor = this.envService.getColor('primary');
  noCount: boolean;
  counts: any;
  doughnutChartLabels: any[]
  doughnutChartData: any = [];
  doughnutChartType: ChartType = 'doughnut';
  doughnutChartColors: any[];
  public doughnutChartLegend = true;
  public doughnutChartPlugins = [];
  public doughnutChartOptions: ChartOptions = { responsive: true, };
  SMSCounted: number = 0;
  EmailCounted: number = 0;
  ChatGptCounted: number = 0;
  SocialMediaCounted: number = 0;

  fromFNLeaders = this.envService.fromFNLeaders
  isChiefApp = this.envService.isChiefApp
  emailValidation = {
    required: true,
    pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
  };

  filteredActiveAppointments = []
  tabStatus = 'registration'
  verifyUsers: boolean;
  totalSubLength = 0;
  totalEmpLength = 0;
  totalReqLength = 0;
  notes: any;
  noteModal
  userNotes
  showHiddenSections = true;
  isFirstSetup = false;
  deleteCustomPageModalOpen = false;
  gettingStartedPercentage: number;
  selectedPageStatus = [];
  isMenuLocked = true;
  renameModal = false
  gettingStarted = {
    firstLogin: true,
    homepage: false,
    employee: false,
    service: false,
    about: false,
    product: false,
    event: false,
    blog: false,
    audience: false,
    socialMediaPage: false,
    customPage: false
  }
  selectedSectionName: any;
  pageIsEmptyModal= false;
  selectedSection: any;
  eventsArray: any = [];
  servicesArray: any = [];
  productsArray: any = [];
  postsArray: any = [];
  about: any = [];
  currentDate = new Date().toISOString().split('T')[0];
  employeeGlobal = []
  faqsList: any=[];
  customSectionList: any = []
  customSectionId: any;

  metaTagSettings: any =null;
  private destroy$ = new Subject<void>();
  renameForm: FormGroup;
  
  constructor(
    private router: Router,
    private newsEventsService: NewsEventService,
    private employeeServicesService: EmployeeServicesService,
    private postsService: PostsService,
    private faqService: FaqService,
    private userService: UserService, 
    private productsService: ProductsService,
    private aboutService: AboutService,
    private settingsService: AppSettingsService,
    private authService: AuthService,
    private customSectionService: CustomSectionService,
    private envService: EnvironmentService,
    private counterService: CountersService,
    private route: ActivatedRoute,
    private helperServices: HelperService,
    private meta: Meta, 
    private title: Title,
    private routMeta: RoutMetaService,
    private fb: FormBuilder
  ) {
    this.getSettings();
  }
  ngOnDestroy(): void {
    
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    initTE({ Modal, Ripple });
    this.renameForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(30)]],
    });
    this.helperServices.getMetaTagSettings();
    console.log("homepage init")
    this.counterService.getCounter().valueChanges().subscribe((count) => {
      if (count && count.length != 0) {
        this.noCount = false;
        this.counts = count[0];
        this.SMSCounted = this.counts.SMSTotalCounter ? this.counts.SMSTotalCounter : 0;
        this.EmailCounted = this.counts.emailTotalCounter ? this.counts.emailTotalCounter : 0;
        this.ChatGptCounted = this.counts.chatGptCounter ? this.counts.chatGptCounter : 0;
        this.SocialMediaCounted = this.counts.socialMediaCount ? this.counts.socialMediaCount : 0;
        this.counterChart();

      } else {
        this.noCount = true;
        this.SMSCounted = 0;
        this.EmailCounted = 0;
        this.ChatGptCounted = 0;
        this.SocialMediaCounted = 0;
      }
    })


    if (!this.pageSections) {
      this.pageSections = GlobalConstants.EmptyPageSections;
      this.pageSections.appSections = GlobalConstants.AppSections;
    }

    const btn4 = document.getElementById('close');
    this.router.events.subscribe((val) => {
      if (btn4) {
        btn4.click()
      }
    })




    this.currentUser = this.authService.getGlobalUser();
    if (this.currentUser) {
      this.role = this.currentUser.role;
      console.log("has role: this.role", this.role)
    }
    this.route.params.subscribe(params => {
      // Perform actions when navigated to
      if (params) {
        this.appSettings = params;
      }
    });

    this.settingsService.getAppSettingsList().valueChanges().subscribe((m: any) => {
      if (m && m.length > 0) {
        this.appSettings = m[0];
      } else {
        this.isFirstSetup = true;
        this.appSettings = this.pageSections;
      }

      if (this.appSettings) {
        console.log("this.appSettings", this.appSettings)
        this.gettingStartedPercentage = 0;
        this.isMenuLocked = true;

        this.router.navigate(['/admin/onboarding-page']);

        if (this.appSettings.gettingStarted && !this.appSettings.gettingStarted.firstLogin) {
          this.selectedPageStatus = [];
          if (this.appSettings.gettingStarted.pageSelection && this.appSettings.gettingStarted.pageSelection.length > 0) {
            this.appSettings.gettingStarted.pageSelection.forEach(element => {
              if (element.isEnabled) {
                this.selectedPageStatus.push({
                  originalName: element.originalName,
                  status: element.originalName == 'settings' ? this.appSettings.gettingStarted['appSetup'] : this.appSettings.gettingStarted[element.originalName]
                });
              }
            });

            let value = 0;
            this.selectedPageStatus.forEach(el => {
              if (el.status) {
                value += 1;
              }
            });
            this.gettingStartedPercentage = Math.trunc((value / this.selectedPageStatus.length) * 100);
            this.isMenuLocked = this.gettingStartedPercentage < 100;
            if (this.gettingStartedPercentage == 100) {
              this.isMenuLocked = false
              let currentRoute = this.router.url;
              if(currentRoute == '/admin/onboarding-page'){
                this.router.navigate(['/admin/homepage'])
              }
              else{
                this.router.navigate([currentRoute])
              }

            }
            if (this.isMenuLocked) {
              this.router.navigate(['/admin/onboarding-page']);
            }
          }
        }

        this.serviceType = this.appSettings.serviceType;
        this.viewType = this.appSettings.viewType ? this.appSettings.viewType : 'list';
        this.showHiddenSections = this.appSettings.showHiddenSections;
        this.serviceType = this.appSettings.serviceType;

        if (this.appSettings.appSections) {
          this.appSettings.appSections.forEach((section: any) => {
            if(section.originalName=='Login/Signup'){
              // this.selectedSectionName = section.name;
              this.renameForm.patchValue({
                name: section.name
              })
            }
            this.section = section;
          });
        }
      }
    });
  }
  get name() {
    return this.renameForm.get('name');
  }
  getSettings() {
    this.settingsService.getAppSettingsList().valueChanges().subscribe(hp => {
      if (hp) {
        if (hp.length > 0) {
          this.pageSections = hp[0];
          // this.backgroundColor = this.pageSections.themeColor;
          this.sliderRef = this.pageSections.appSections.filter(s => s.name === 'Slider')[0];
          this.blogsRef = this.pageSections.appSections.filter(s => s.originalName === 'Blogs')[0];
          this.eventRef = this.pageSections.appSections.filter(s => s.originalName === 'Events')[0];
          this.productRef = this.pageSections.appSections.filter(s => s.originalName === 'Products')[0];
          this.aboutRef = this.pageSections.appSections.filter(s => s.originalName === 'About Us')[0];
          this.serviceRef = this.pageSections.appSections.filter(s => s.originalName === 'Services')[0];
          this.appStoreRef = this.pageSections.appSections.filter(s => s.name === 'AppStore')[0];
          this.socialMediaRef = this.pageSections.appSections.filter(s => s.name === 'SocialMedia')[0];
          this.footerRef = this.pageSections.appSections.filter(s => s.originalName === 'Footer')[0];
          this.welcomeRef = this.pageSections.appSections.filter(s => s.name === 'WelcomePopup')[0];
          this.faqRef = this.pageSections.appSections.filter(s => s.originalName === 'faq')[0];
          this.pageSections.appSections.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0))

          if (this.eventRef ) {
              this.newsEventsService.getPublicEventsList().valueChanges().subscribe(n => {
                if (n && n.length > 0) {
                  this.eventsArray = n;  
                  this.eventsArray = this.getFutureEventList(n)

                }
              });
          }
          if (this.serviceRef ) {
              this.employeeServicesService.getPublicServices().valueChanges().subscribe((ser) => {
                if (ser && ser.length > 0) {
                    this.servicesArray = ser;
                }
              });
            }
            if (this.productRef ) {
              this.productsService.getProductsList().valueChanges().subscribe((prod) => {
                if (prod && prod.length > 0) {
                    this.productsArray = prod;
                }
              });
            } 
            if (this.faqRef ) {
              this.faqService.getFAQs().valueChanges().subscribe((faqs: any)=>{
                if(faqs && faqs.length > 0){
                  this.faqsList = faqs;
                }
              })
            }
            if (this.blogsRef ) {
              this.postsService.getPostsByShareType('Public').valueChanges().subscribe((blog) => {
                if (blog && blog.length > 0) {
                    this.postsArray = blog;
                }
              });
            } 
            if (this.aboutRef ){
              this.aboutService.getAbout().valueChanges().subscribe((aboutA) => {
                if (aboutA && aboutA.length > 0) {
                  this.about = aboutA[0];
                }
              });
            }
          this.userService.getUserByRoles(['Employee']).subscribe(employees => {
            if(employees.length > 0){
              employees.forEach(emp => {
                if(emp?.ShowEmployee == 'public'||emp?.ShowEmployee == 'Public'||emp?.showEmployee == 'public'||emp?.showEmployee == 'public'){
                  this.employeeGlobal.push(emp)
                }
              });
            }
          });
        }
      }
    });
    this.settingsService.getLandingPageSettings().valueChanges().subscribe(
      lpSettings => {
        if (lpSettings) {
          if (lpSettings.length > 0) {
            this.model = lpSettings[0];
          }

        }
      }
    );

    // this.getCardDetail()

  }

  deleteModal(event) {
    event.stopPropagation()

  }
  closePageIsEmptyModal(){
    this.pageIsEmptyModal = false
    this.pageSections.appSections.forEach(section => {
      if (section.originalName === this.selectedSection.originalName) {
        section.isEnabled = false;
      }
    });
    this.saveDataChanges()

  }
  createNewElement(name){
  if(this.selectedSection.isCustomSection){
    this.router.navigate(['/admin/create-section-element/', this.customSectionId]);
  }else{
    if (name == 'Blogs' || name == 'Blog') {
      this.router.navigate(['/admin/create-blog']);
    }
    else if (name == 'Events') {
      this.router.navigate(['/admin/create-event']);
    }
    else if (name == 'Products') {
        this.router.navigate(['/admin/create-product']);
    }
    else if (name == 'Services') {
      this.router.navigate(['/admin/create-service']);
    }
    else if (name == 'About Us') {
      this.router.navigate(['/admin/about']);
    }
    else if (name == 'faq') {
      this.router.navigate(['/admin/faq-setting']);
    }
  }
 
  }
  checkedChange(section: any) {
    // section.isEnabled = !section.isEnabled; 
    this.pageSections.appSections.forEach(s => {  });

    this.selectedSection = section
    if(!section.isEnabled ){

      if(section.originalName=='Blogs' && this.postsArray.length == 0){
        this.pageIsEmptyModal = true
      }    
      else if (section.originalName == 'Events' && this.eventsArray.length == 0) {
        this.pageIsEmptyModal = true
      }
      else if (section.originalName == 'Products' && this.productsArray.length == 0) {
        this.pageIsEmptyModal = true
      }
      else if (section.originalName == 'Services' && this.servicesArray.length == 0) {
        this.pageIsEmptyModal = true
      }
      else if (section.originalName == 'About Us' && this.about && this.about.section && (this.about.sections.length > 0 || (this.employeeGlobal.length > 0 && this.about.showEmployees) )) {
        this.pageIsEmptyModal = true
      }
      else if(section.originalName== 'faq' && this.faqsList.length == 0 ){
        this.pageIsEmptyModal = true
      }
      else{
        section.isEnabled = !section.isEnabled;         
      }
    } else{
      section.isEnabled = !section.isEnabled
    }

    this.saveDataChanges()
  }

  saveDataChanges(){
    if (this.isFirstSetup) {
      this.settingsService.saveAppSettings(this.pageSections).then(() => {
        this.toast({ html: 'Successfully Updated!!', classes: 'green', type: 'success' });
      });
    }
    else {
      this.settingsService.updateAppSettings(this.pageSections).then(() => {
        this.toast({ html: 'Successfully Updated!!', classes: 'green', type: 'success' });
      });
    }
  }
  customSectionVisibility(section){
    // customSectionService
    this.selectedSection = section
    this.customSectionService.getCustomSectionByOriginalName(section.originalName).valueChanges().subscribe((customSection: any)=>{
      if(customSection){
        this.customSectionId = customSection[0].id
        this.customSectionService.getAllElementsBySectionId(customSection[0].id).valueChanges().subscribe((list)=>{
          this.customSectionList = list
          if(this.customSectionList.length == 0 && !section.isEnabled ){
            this.pageIsEmptyModal = true
           }else{
            section.isEnabled = !section.isEnabled;        
            this.saveDataChanges()
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      
           }
        })
       }
     })
  

  
  }
  getSortedList(unsortedElements: Array<any>){
    // Filter out past events
    const futureEvents = unsortedElements.filter(event => event.eventStartDate >= this.currentDate);
    // Sort the remaining events by date
    // futureEvents.sort((a, b) => a.eventStartDate.localeCompare(b.eventStartDate));
    return futureEvents;
  }


  toast(obj) {
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green';
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
    }, 2000);
  }
  navigateTo(name) {
    if (name == 'Welcome Popup') {
      this.router.navigate(['/admin/welcome-setting']);
    }
    if (name == 'Slider') {
      this.router.navigate(['/admin/slider-setting']);
    }
    if (name == 'Footer') {
      this.router.navigate(['/admin/footer-setting']);
    }
    if (name == 'Social Media') {
      this.router.navigate(['/admin/contact-setting']);
    }
    else if (name == 'Blogs' || name == 'Blog') {
      this.router.navigate(['/admin/blogs']);
    }
    else if (name == 'Events') {
      this.router.navigate(['/admin/events']);
    }
    else if (name == 'Products') {
      this.router.navigate(['/admin/view-products']);
    }
    else if (name == 'Services') {
      this.router.navigate(['/admin/services']);
    }
    else if (name == 'About Us') {
      this.router.navigate(['/admin/about']);
    }
    else if (name == 'Contact Us') {
      this.router.navigate(['/admin/contact-messages']);
    }
    else if (name == 'First Nations Leaders') {
      this.router.navigate(['/admin/first-nations-leaders-list']);
    }
    else if (name == 'e') {
      this.router.navigate(['/admin/faq-list']);
    }else if (name == 'faq') {
      this.router.navigate(['/admin/faq-list']);
    }else if(name == 'Login/Signup'){
      this.renameModal = true
    }

  }

  customSectionRoute(section) {
    this.customSectionService
    .getCustomSectionByOriginalName(section.originalName)
    .valueChanges()
    .pipe(takeUntil(this.destroy$))
    .subscribe((sec: any) => {
      this.customSection = sec;
      if (this.customSection) {
        this.router.navigate(['/admin/custom-section-list', this.customSection[0].id]);
      }
    });
  }
  openDeleteCustomPageModal(section) {
    this.customSection = section;
    this.deleteCustomPageModalOpen = true
  }
  deleteCustomSection(customSection) {
    this.pageSections.appSections = this.pageSections.appSections.filter((a) => {
      return a.originalName != customSection.originalName
    }
    );
    this.settingsService.updateAppSettings(this.pageSections)

    this.deleteCustomPageModalOpen = false;
    this.customSectionService.getCustomSectionBySectionTitle(customSection?.originalName).valueChanges().subscribe((sec: any) => {
      if (sec.length > 0) {
        this.customSection = sec[0];
        this.customSectionService.deleteCustomSection(this.customSection).then(() => {
          this.toast({ html: 'Page Successfully Deleted!!', classes: 'red', type: 'failure' });
        })
      }
    })
    //   this.customSectionService.deleteCustomSection(this.customSection).then(()=>{
    //     this.customSectionService.deleteElementsBySectionId(this.customSection.id).then(() => {

    //     })
    //     this.toast({ html: 'Page Successfully Deleted!!', classes: 'green', type: 'success'});

    //   })


  }

  routeToElement() {
    if (this.customSection) {
      this.router.navigate(['/admin/custom-section-list', this.customSection[0].id])
    }
  }

  newSection() {
    if (this.currentUser) {
      if (this.currentUser.role == 'Admin') {
        this.router.navigate(['/admin/create-new-section'])
      } else {
        this.router.navigate(['/employee/create-new-section'])

      }
    }
  }
  truncateHTML(text: any, limit): string {
    return this.helperServices.truncateHTML(text, limit);
  }

  navigateToLandingPageSetting() {
    this.router.navigate(['/admin/landing-page-settings'])

  }

    changeView(view: string){
      this.viewType = view
      this.pageSections.viewType = view
      this.settingsService.updateAppSettings(this.pageSections).then(() => {
        this.toast({ html: 'Successfully Updated!!', classes: 'green', type: 'success'});
        this.router.navigate(['admin/landing-page-settings'])
      });
    }
    toggleSectionView(sec){
      this.showHiddenSections = sec;
      this.pageSections.showHiddenSections = sec;
      if(this.isFirstSetup){
        this.settingsService.saveAppSettings(this.pageSections)
      }
      else {
        this.settingsService.updateAppSettings(this.pageSections)
      }
    }
    checkProductAndServiceVisibility(sectionName){
      if(sectionName=='Services'){
        if(this.serviceType=='Both' || this.serviceType=='Service'){
          return true
        }
        return false;
      } else if (sectionName=='Products'){
        if(this.serviceType=='Both' || this.serviceType=='Product'){
          return true
        }
        return false;
      }else {
        return true;
      }
    }
    counterChart() {
      this.doughnutChartData = [[this.SMSCounted,this.EmailCounted,this.SocialMediaCounted,this.ChatGptCounted]]; 
      this.doughnutChartColors= [ { backgroundColor: [this.themeColor, '#58c4e8','#9fc543' ,'#e063d0'] } ];
      this.doughnutChartOptions= { 
        responsive: true,
        plugins: {
          legend: {
            display: true,
            position: 'right'
          },
          datalabels: {
            display: true,
            color: '#000000',
            font: {
              weight: 'bold'
            },
            formatter: (value, context) => {
              return context.chart.data.labels[context.dataIndex] + ': ' + value;
            }
          }
        }
      };
      this.doughnutChartLabels = [this.SMSCounted+' SMS' +" "+ (1000 - this.SMSCounted)+' Left',
       this.EmailCounted+' Email' +" "+ (1000 - this.EmailCounted)+' Left',
        this.SocialMediaCounted+' Social Media' +" "+ (1000 - this.SocialMediaCounted)+' Left',
         this.ChatGptCounted+' ChatGpt' +" "+ (1000 - this.ChatGptCounted)+' Left'];
         this.options = {
          data:  [
            { label: this.doughnutChartLabels[0],
              count: this.SMSCounted,
              left: 1000-this.SMSCounted
             },
            { 
              label: this.doughnutChartLabels[1],
              count: this.EmailCounted,
              left: 1000-this.EmailCounted
            },
            { label: this.doughnutChartLabels[2],
              count: this.SocialMediaCounted,
              left: 1000-this.SocialMediaCounted
             },
             { label: this.doughnutChartLabels[3],
              count: this.ChatGptCounted,
              left: 1000-this.ChatGptCounted
             },
          ],
          title: {
            text: "Metric counter",
          },
          series: [
            {
              calloutLabel:{
                offset: 10,
                
              },
              outerRadiusRatio: 1,
              // title: {
              //   text: "ChatGpt",
              // },
              type: "donut",
              calloutLabelKey: "label",
              angleKey: "left",
              innerRadiusRatio: 0.6,
            }
            
          ],
        };
        //  this.options = {
        //   data:  [
        //     { label: 'Left',
        //       smsCount: 1000-this.SMSCounted ,
        //       emailCount: 1000-this.EmailCounted ,
        //       socialMediaCount: 1000-this.SocialMediaCounted ,
        //       chatGptCount: 1000-this.ChatGptCounted ,

        //      },
        //     { 
        //       label: 'Used',
        //       smsCount: this.SMSCounted,
        //       emailCount: this.EmailCounted,  
        //       socialMediaCount: this.SocialMediaCounted,
        //       chatGptCount: this.ChatGptCounted
        //     },
        //   ],
        //   title: {
        //     text: "Metric counter",
        //   },
        //   series: [
        //     {
        //       calloutLabel:{
        //         offset: 10,
                
        //       },
        //       outerRadiusRatio: 1.4,
        //       title: {
        //         text: "ChatGpt",
        //       },
        //       type: "donut",
        //       calloutLabelKey: "label",
        //       angleKey: "chatGptCount",
        //       innerRadiusRatio: 0.8,
        //     },{
        //       type: "donut",
        //       title: {
        //         text: "SocialMedia",
        //       },
        //       outerRadiusRatio: 0.8,
        //       calloutLabelKey: "label",
        //       angleKey: "socialMediaCount",
        //       innerRadiusRatio: 0.6,
        //       showInLegend: false
        //     },
        //     {
        //       type: "donut",
        //       title: {
        //         text: "Email",
        //       },
        //       outerRadiusRatio: 0.6,
        //       calloutLabelKey: "label",
        //       angleKey: "emailCount",
        //       innerRadiusRatio: 0.4,
        //       showInLegend: false
        //     },
        //     {
        //       type: "donut",
        //       title: {
        //         text: "SMS",
        //       },
        //       outerRadiusRatio: 0.4,
        //       calloutLabelKey: "label",
        //       angleKey: "smsCount",
        //       innerRadiusRatio: 0.1,
        //       showInLegend: false
        //     },
        //   ],
        // };
      }

    tabStatusSet(status: string) {
      this.tabStatus = status

    }
    addNote() {
      this.noteModal = true;
    }
    closeNote() {
      this.noteModal = false;
    }

  validSection(section) {
    let validSection = true
    if (section.name == "First Nations Leaders") {
      validSection = this.isChiefApp
    }
    else if (this.fromFNLeaders) {
      validSection = !['Services', 'Products'].includes(section.originalName)
    }
    return validSection
  }

  renameLoginSignupSection(){

  }

  saveChanges(){
    this.appSettings.appSections.forEach(element => {
      if(element.originalName == 'Login/Signup'){
        // element.name = this.selectedSectionName;
        element.name = this.name.value
      }  
           
    });
    this.renameModal = false;
    this.settingsService.updateAppSettings(this.appSettings).then(x => { 
      this.toast({ html: 'Successfully Updated!!', classes: 'green', type: 'success' });
    });
  }
  visibilitySelected(section){
 
  }
  getFutureEventList(unsortedElements: Array<any>){
    // Filter out past events
    const futureEvents = unsortedElements.filter(event => event.eventStartDate >= this.currentDate);
    // Sort the remaining events by date
    futureEvents.sort((a, b) => a.eventStartDate.localeCompare(b.eventStartDate));
    return futureEvents;
  }
displaySuccessCard(event, name){

}

}

