import { DOCUMENT } from '@angular/common';
import { Component, Inject,AfterViewInit ,OnInit,Renderer2 } from '@angular/core';
import { base64ToFile, Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { PostsService } from 'src/app/services/firebase/posts.service';
import { HelperService} from 'src/app/services/helper/helper';
import { Browser, getComponent } from '@syncfusion/ej2-base';
import { ImageEditorComponent } from '@syncfusion/ej2-angular-image-editor';
// import {} from '../../../../assets/img/'
@Component({
  selector: 'app-blog-img-test',
  templateUrl: './blog-img-test.component.html',
  styleUrls: ['./blog-img-test.component.css']
})
export class BlogImgTestComponent implements OnInit {
  blogs: any;
  imageChangeEvt: any = "";
  cropImgPreview: any;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  zoomScale = 10;
  constructor(private blogService: PostsService, private _renderer2: 
    Renderer2,@Inject(DOCUMENT) private _document: Document,
    private helperService: HelperService) { }


  ngOnInit(): void {
    // this.blogService.getAllPosts().valueChanges().subscribe(blogs => {
    //   if(blogs.length > 0){
    //     this.blogs = blogs;
    //   }
    // })
    
    // let masonryEvents = ['load', 'resize'];
    // masonryEvents.forEach( function(event) {
    //   window.addEventListener(event, this.resizeAllMasonryItems());
    // } );        

    // this.waitForImages();
  }
  public created = (): void => {
    const imageEditor: ImageEditorComponent = getComponent(document.getElementById('image-editor'), 'image-editor');
    if (Browser.isDevice) {
      console.log("inside is device");
        imageEditor.open('https://api.goingmobile.app/media/personal-app-assets/img/webp/about1.webp');
    } else {
      console.log("outside is device");
        imageEditor.open('https://api.goingmobile.app/media/personal-app-assets/img/webp/box.webp');
    }
    if (imageEditor.theme && window.location.href.split('#')[1]) {
      console.log('image editor ', imageEditor.theme);
      console.log('window.location.href ', window.location.href);
      console.log("imageeditor theme window.location is device");
        // imageEditor.theme = window.location.href.split('#')[1].split('/')[1];
    }
}
/**
 * Set appropriate spanning to any masonry item
 *
 * Get different properties we already set for the masonry, calculate 
 * height or spanning for any cell of the masonry grid based on its 
 * content-wrapper's height, the (row) gap of the grid, and the size 
 * of the implicit row tracks.
 *
 * param item Object A brick/tile/cell inside the masonry
 */
resizeMasonryItem(item: any){

  /* Get the grid object, its row-gap, and the size of its implicit rows */
  if(document.getElementsByClassName('masonry')[0]){
    var grid = document.getElementsByClassName('masonry')[0],
    rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap')),
    rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));

/*
 * Spanning for any brick = S
 * Grid's row-gap = G
 * Size of grid's implicitly create row-track = R
 * Height of item content = H
 * Net height of the item = H1 = H + G
 * Net height of the implicit row-track = T = G + R
 * S = H1 / T
 */
var rowSpan = Math.ceil((item.querySelector('.masonry-content').getBoundingClientRect().height+rowGap)/(rowHeight+rowGap));

/* Set the spanning as calculated above (S) */
item.style.gridRowEnd = 'span '+rowSpan;
  }

}

/**
 * Apply spanning to all the masonry items
 *
 * Loop through all the items and apply the spanning to them using 
 * `resizeMasonryItem()` function.
 *
 *  resizeMasonryItem
 */
 resizeAllMasonryItems(){
  // Get all item class objects in one list
  var allItems = document.getElementsByClassName('masonry-brick');
  /*
   * Loop through the above list and execute the spanning function to
   * each list-item (i.e. each masonry item)
   */
  if(allItems.length>0){
    for(var i=0;i>allItems.length;i++){
      this.resizeMasonryItem(allItems[i]);
    }
  }

}

/**
 * Resize the items when all the images inside the masonry grid 
 * finish loading. This will ensure that all the content inside our
 * masonry items is visible.
 *
 * @ ImagesLoaded
 * uses resizeMasonryItem
 */
 waitForImages() {
  /* Resize all the grid items on the load and resize events */

  let allItems = document.getElementsByClassName('masonry-brick');
  for(let i=0;i<allItems.length;i++){
    // imagesLoaded( allItems[i], function(instance) {
    //   let item = instance.elements[0];
      this.resizeMasonryItem(allItems[i]);
    // } );
  }


}


getInnerText(text){
  return this.helperService.getInnerText(text);
}
truncateHTML(text){
  return this.helperService.truncateHTML(text,'100');
}


    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
        console.log(event, base64ToFile(event.base64));
    }

    imageLoaded() {
        this.showCropper = true;
        console.log('Image loaded');
    }

    cropperReady(sourceImageDimensions: Dimensions) {
        console.log('Cropper ready', sourceImageDimensions);
    }

    loadImageFailed() {
        console.log('Load failed');
    }

    rotateLeft() {
        this.canvasRotation--;
        this.flipAfterRotate();
    }

    rotateRight() {
        this.canvasRotation++;
        this.flipAfterRotate();
    }

    private flipAfterRotate() {
        const flippedH = this.transform.flipH;
        const flippedV = this.transform.flipV;
        this.transform = {
            ...this.transform,
            flipH: flippedV,
            flipV: flippedH
        };
    }


    flipHorizontal() {
        this.transform = {
            ...this.transform,
            flipH: !this.transform.flipH
        };
    }

    flipVertical() {
        this.transform = {
            ...this.transform,
            flipV: !this.transform.flipV
        };
    }

    resetImage() {
        this.scale = 1;
        this.rotation = 0;
        this.canvasRotation = 0;
        this.transform = {};
    }

    zoomOut() {
      if(this.scale > 0.11){
        this.zoomScale-=10;
        this.scale -= .1;
        this.transform = {
            ...this.transform,
            scale: this.scale
        };
      }

        console.log('zoom out ', this.scale)
    }

    zoomIn() {
      this.zoomScale+=10;
      if(this.scale < 3.1){
        this.scale += .1;
        this.transform = {
            ...this.transform,
            scale: this.scale
        };
      }

        console.log('zoom in ', this.scale);
    }
    zoomFromRange(){

      console.log('changed to ', this.zoomScale);
      this.scale =this.zoomScale * 0.1;
      this.transform = {
          ...this.transform,
          scale: this.scale
      };
    }

    toggleContainWithinAspectRatio() {
        this.containWithinAspectRatio = !this.containWithinAspectRatio;
    }

    updateRotation() {
        this.transform = {
            ...this.transform,
            rotate: this.rotation
        };
    }

}
