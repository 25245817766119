<div class="flex justify-center h-screen" *ngIf="loading">
    <progress class="progress progress-[#077373] w-full"></progress>
</div>
<div *ngIf="!loading">
    <div class="grid grid-cols-1 gap-y-5" *ngIf="userProfile">

        <div class="font-bold  mb-6" *ngIf="!limitSocialMedia" >Total Number of Social Media Shares are <span class="font-bold text-lg text-customTeal">{{socialMediaCount}}</span></div>
        <div class="font-bold text-red mb-6" *ngIf="limitSocialMedia" >You have reached the maximum number of Social Media shares</div>

        <div class="flex flex-col md:flex-row justify-end gap-2 gap-y-4 pb:10 md:pb-0 ">
            <div *ngIf="hasActiveAccounts && isAdmin || (permSocial && permSocial.createSocialPost)" class="justify-self-end ">
                <button (click)="toCreatePost()" class="py-2 px-6 gap-2 bg-customTeal rounded text-white w-50 md:w-60">
                    <i class="material-icons mr-3 float-left">post_add</i>
                    Create A Post
                </button>
            </div>

            <!-- <div class=" justify-self-end">
                <app-link-account [link]="false"></app-link-account>

            </div> -->
        </div>

        <div class="pt-3 flex flex-col md:flex-row-reverse  gap-4">
            <div class="w-full md:w-1/3 border-2 border-red bg-white rounded-lg p-6">

                <div>
                    <p class="text-lightGray">Profile Details</p>
                    <div class="py-6">
                        <div class="p-2"><i class="material-icons mr-2 float-left">email</i>{{userProfile.title}}</div>
                        <div class="p-2"><i class="material-icons mr-2 float-left">post_add</i>
                            <span class="text-customTeal font-bold">{{allAccountsPosts.length}}</span> Posts
                        </div>
                        <!-- <div class="p-2"><i class="material-icons mr-4 float-left">post_add</i>
                            <span class="text-customTeal font-bold">{{allAccountsPosts.length}}</span> Posts
                        </div> -->

                    </div>
                </div>
                <div>
                    <p class="text-lightGray">Active media</p>
                    <div class="py-2 flex flex-col">

                        <div class="flex flex-wrap gap-x-2 pt-3" *ngIf="hasActiveAccounts">
                            <div class="" *ngFor="let v of userProfile['activeSocialAccounts']">
                                <i *ngIf="v !== 'tiktok'"
                                    [ngClass]="'text-xl mr-1 fa ' + socialMediaIcons[v].text + ' ' + socialMediaIcons[v].color"></i>
                                <svg class="mr-2" *ngIf="v == 'tiktok'" xmlns="http://www.w3.org/2000/svg" x="0px"
                                    y="0px" width="24" height="24" viewBox="0 0 50 50">
                                    <path
                                        d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z">
                                    </path>
                                </svg>
                            </div>


                        </div>

                        <div class="block font-bold" *ngIf="!hasActiveAccounts">
                            <p [ngClass]="">No acounts linked yet</p>
                            <!-- <i class="material-icons mr-4 float-left">{{socialMediaIcons[a]}}</i> -->
                        </div>
                        <!-- <div class="p-2"><i class="material-icons mr-4 float-left">email</i>{{userProfile.title}}</div> -->
                        <!-- <div class="p-2"><i class="material-icons mr-4 float-left">post_add</i>
                            <span class="text-customTeal font-bold">{{allAccountsPosts.length}}</span>Posts
                        </div> -->
                    </div>
                    <app-link-account [link]="true" [new]="true" *ngIf="!hasActiveAccounts && (isAdmin || (permSocial && permSocial.linkAccount))"></app-link-account>
                    <app-link-account [link]="true" *ngIf="hasActiveAccounts && (isAdmin || (permSocial && permSocial.linkAccount))"></app-link-account>

                </div>
            </div>
            <div class="px-12 py-8 w-full md:w2/3 border-2 border-red bg-white rounded-lg">
                Media Use Analysis

                <div class="w-full lg:w-2/3 bg-white rounded-lg  mr-2 lg:mr-10">
                    <canvas baseChart width="300" height="150" id="chart" [datasets]="lineChartData"
                        [labels]="lineChartLabels" [options]="lineChartOptions" [legend]="lineChartLegend"
                        [chartType]="lineChartType" [colors]="barChartColors" [plugins]="lineChartPlugins">
                    </canvas>

                </div>
            </div>

        </div>
        <div class=" px-3 py-6 md:px-12 md:py-8 w-full border-2 border-red bg-white rounded-lg">

            <div class="p-b-8">
                Recent Activity
                <div *ngIf="hasHistory">
                    <div class=" flex flex-col space-y-2 text-left">
                        <div class=" flex flex-row items-center  mt-2 p-2 md:space-x-6">
                            <div class="w-4/12 md:w-2/12 md:block text-left">
                                Media
                            </div>
                            <div class="w-4/12 md:w-2/12 md:block">
                                <p class=" text-sm">Date</p>
                            </div>
                            <div class="w-2/12 md:w-2/12 md:block">
                                <p class=" text-sm">Status</p>
                            </div>
                            <div class="w-4/12 hidden md:block">
                                <p class=" text-sm">Platforms </p>
                            </div>
                            <div class="w-1/12 md:w-2/12 hidden md:block">

                            </div>

                        </div>
                    </div>
                    <div class="flex flex-col space-y-2 text-left">

                        <div class="flex flex-row bg-white items-center  mt-2 p-2 rounded-md   border-2 md:space-x-6 "
                            *ngFor="let post of latestPosts;let li=index">
                            <div class="w-4/12 md:w-2/12 flex ">

                                <div class="flex items-center">
                                    <div class="avatar">
                                        <div class="w-10 rounded">
                                            <!-- <img src="https://api.lorem.space/image/face?hash=88560" /> -->
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M0.75 23.25H23.25V0.75H0.75V23.25ZM2.25 2.25H21.75V21.75H2.25V2.25Z"
                                                    fill="black" />
                                                <path
                                                    d="M5.25 8.25H6.75V6.75H10.5V17.25H9V18.75H15V17.25H13.5V6.75H17.25V8.25H18.75V5.25H5.25V8.25Z"
                                                    fill="black" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div class="avatar" *ngIf="post.mediaUrls.length >0">
                                        <div class="w-10 rounded">
                                            <!-- <img src="https://api.lorem.space/image/face?hash=80245"
                                                alt="Tailwind-CSS-Avatar-component" /> -->
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M2 6C2 4.93913 2.42143 3.92172 3.17157 3.17157C3.92172 2.42143 4.93913 2 6 2H18C19.0609 2 20.0783 2.42143 20.8284 3.17157C21.5786 3.92172 22 4.93913 22 6V18C22 19.0609 21.5786 20.0783 20.8284 20.8284C20.0783 21.5786 19.0609 22 18 22H6C4.93913 22 3.92172 21.5786 3.17157 20.8284C2.42143 20.0783 2 19.0609 2 18V6Z"
                                                    stroke="black" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round" />
                                                <path
                                                    d="M8.5 11C9.88071 11 11 9.88071 11 8.5C11 7.11929 9.88071 6 8.5 6C7.11929 6 6 7.11929 6 8.5C6 9.88071 7.11929 11 8.5 11Z"
                                                    stroke="black" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round" />
                                                <path
                                                    d="M14.526 12.6211L6 22.0001H18.133C19.1586 22.0001 20.1422 21.5926 20.8674 20.8674C21.5926 20.1422 22 19.1586 22 18.1331V18.0001C22 17.5341 21.825 17.3551 21.51 17.0101L17.48 12.6151C17.2922 12.4102 17.0637 12.2467 16.8092 12.135C16.5546 12.0234 16.2796 11.966 16.0017 11.9666C15.7237 11.9671 15.449 12.0256 15.1949 12.1383C14.9408 12.251 14.713 12.4154 14.526 12.6211V12.6211Z"
                                                    stroke="black" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round" />
                                            </svg>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="w-4/12 md:w-2/12 ">
                                <p class=" text-sm">{{post.scheduleDate ? post.scheduleDate.utc : post.createDate.utc }}
                                </p>
                            </div>
                            <div class="w-2/12 md:w-2/12">
                                <i class="fa fa-circle text-xl p-0 m-0"
                                    [ngClass]="{'text-red-600': post.status=='error', 'text-green-600': post.status=='success', 'text-yellow-600': post.status=='pending'}"></i>
                                <span class="ml-4 hidden md:inline-block">

                                    {{post.status}}
                                </span>

                            </div>
                            <div class=" w-4/12 hidden md:flex flex-wrap">
                                <ng-container *ngFor="let v of post.platforms">
                                    <i *ngIf="v !== 'tiktok'"
                                        [ngClass]="'text-xl mr-3 fa ' + socialMediaIcons[v].text + ' ' + socialMediaIcons[v].color"></i>
                                    <svg class="p-0" *ngIf="v == 'tiktok'" xmlns="http://www.w3.org/2000/svg" x="0px"
                                        y="0px" width="24" height="24" viewBox="0 0 50 50">
                                        <path
                                            d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z">
                                        </path>
                                    </svg>
                                </ng-container>

                                <!-- <p class=" text-sm">{{Employee Name}}</p> -->
                            </div>
                            <div class="w-1/12 md:w-2/12">
                                <svg [routerLink]="['../post-detail',post.id]" [relativeTo]="activatedRoute"
                                    xmlns="http://www.w3.org/2000/svg" class="block md:hidden h-5 w-5 fill-black  "
                                    viewBox="0 0 20 20">
                                    <path fill-rule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                        clip-rule="evenodd" />
                                </svg>
                                <div class="hidden md:block">
                                    <button class="flex space-x-2 items-center "
                                        [routerLink]="['../post-detail',post.id]" [relativeTo]="activatedRoute">
                                        <span class="font-bold text-sm hover:text-customTeal hover:text-base">View detail</span>


                                    </button>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div class="float-right pt-3">
                        <a [routerLink]="['../posts']" [relativeTo]="activatedRoute"
                            class="underline text-customTeal mr-5 lg:mr-10 hover:font-bold">View all</a>
                    </div>
                </div>

                <div *ngIf="!hasHistory" class="px-5 md:px-0 py-14 flex flex-col w-full jusify-center">
                    <div class="w-full py-4 text-center text-bold">
                        Seems like you haven't posted anything yet. Create your first post today!
                    </div>

                    <div class="flex justify-center">
                        <button *ngIf="hasActiveAccounts" (click)="toCreatePost()"
                            class="py-2 px-6 gap-2 bg-customTeal rounded text-white w-50 md:w-60">
                            <i class="material-icons mr-3 float-left">post_add</i>
                            Create A Post
                        </button>
                        <app-link-account [link]="false" [new]="true" *ngIf="!hasActiveAccounts"></app-link-account>
                    </div>

                </div>

            </div>


        </div>
        <div>

        </div>
        <div *ngIf="hasActiveAccounts && getUserMediaPlatforms().length > 0">
            <p class="font-bold pb-3">Media Analysis</p>
            <div class="grid grid-cols-1 gap-4" *ngIf="platformAnalytics">
                <ng-container class="grid grid-cols-1 gap-4 " *ngFor="let item of platformAnalytics | keyvalue">
                    <div class="grid grid-cols-12 gap-4 h-full"
                        *ngIf="getUserMediaPlatforms().includes(item.key) && item.value.analytics && objectKeys(item.value.analytics).length > 0">
                        <app-social-media-platform-detail class="col-span-12 md:col-span-9"
                            [data]="getPlatformData(item.key,item.value.analytics)">
                        </app-social-media-platform-detail>

                        <app-social-media-platform-user class="hidden md:flex flex-col col-span-3 h-full"
                            [data]="getPlatformData(item.key,item.value.analytics)">
                        </app-social-media-platform-user>
                    </div>
                </ng-container>

            </div>

        </div>

    <app-alert class="fixed top-0 flex justify-end w-full" [toastType]="toastType" [toastMessage]="toastMessage" [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>

    </div>

    <div *ngIf="error" class="px-5 md:px-0 py-14 flex flex-col w-full jusify-center">
        <div class="w-full py-4 text-center font-bold text-customTeal text-xl">
            {{error}}
        </div>

        <!-- <div class="flex justify-center">
            <button *ngIf="hasActiveAccounts" (click)="toCreatePost()" class="py-2 px-6 gap-2 bg-customTeal rounded text-white w-50 md:w-60">
                <i class="material-icons mr-3 float-left">post_add</i>
                Create A Post
            </button>

            <button *ngIf="!hasActiveAccounts" (click)="linkSocialMedia()" class="py-2 px-6 gap-2 bg-customTeal rounded-md text-white w-50 md:w-60">
                <i class="material-icons mr-3 float-left">link</i>
                Link an account
            </button>
        </div> -->

    </div>
</div>