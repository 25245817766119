<div class="row col s12">
  <div class="col l1 s0 hide-on-med-and-down" *ngIf="currentUser && !isAdmin"></div>
  <div class="col s12">


    <div class="row card-panel white" style="padding: 0;">

      <!-- left column - chat list and search -->
      <div class="col l4 s12 left-col-list" style=" height: 100%;">

        <div class="row">
          <br />
          <div class="profile-pic col s2">
            <img loading="lazy" class="avatar" *ngIf="currentUser" alt="User Profile Picture"
              [src]="currentUser.profileImgLink || imgLink">
          </div>
          <div class="col s10">
            <br/>
            {{currentUser.GivenName}} {{currentUser.LastName}}
          </div>
        </div>

        <!-- search bar -->
        <div class="row search-bar" *ngIf="isAdmin">
          <div class="input-field col s12">
            <i class="material-icons prefix color-blue">search</i>
            <input type="search" id="search" placeholder="search to start a new chat" class="autocomplete"
              autocomplete="off" [(ngModel)]="searchQuery" (focus)="openUserSuggestion($event.target.id)"
              (ngModelChange)="searchChat()">
          </div>

        </div>
        <div *ngIf="!isAdmin" style="padding:1px; background-color: lightgray;">
          Conversations
        </div>
        <br />

        <div class="row left-list">
          <!-- private chats list -->
          <div id="chats" class="col s12">
            <div class="chats" *ngIf="!search">

              <div *ngIf="!loadingChats && privateChats.length > 0">
                <div *ngFor="let chat of privateChats">
                  <!-- each private chats -->
                  <div class="each-chat btn-flat" (click)="selectPrivateChat(chat)"
                    [ngClass]="selectedConver?.id == chat.id ? 'background-grey' : '' ">
                    <div class="one-chat-btn">
                      <div class="profile-pic col s2">
                        <img loading="lazy" class="avatar" alt="User Profile Photo" [src]="chat.user_2.profileImgLink || imgLink">
                      </div>
                      <div class="chat-details col s6">
                        <div class="contact-name">{{chat.user_2.GivenName || chat.user_2.FirstName}}
                          {{chat.user_2.LastName}}</div>
                        <div class="contact-last-text" *ngIf="(chat.lastMessage | json)  !== '{}'">
                          {{chat.lastMessage.message}}</div>
                      </div>
                      <div class="chat-details right-detail col s4">

                        <div class="last-text-date" *ngIf="(chat.lastMessage | json)  !== '{}'">
                          <div
                            *ngIf="(chat.lastMessage.timeSent?.toDate() | date: 'dd MMMM yyyy') !== (today | date: 'dd MMMM yyyy') ">
                            {{chat.lastMessage.timeSent?.toDate() | date: 'mediumDate'}}</div>
                          <div
                            *ngIf="(chat.lastMessage.timeSent?.toDate() | date: 'dd MMMM yyyy') == (today | date: 'dd MMMM yyyy') ">
                            {{chat.lastMessage.timeSent?.toDate() | date: 'shortTime'}}</div>
                        </div>
                        <div *ngIf="chat.user_1.unreadCount"><span class="new badge unread-count backg-color-blue"
                            data-badge-caption=" ">{{chat.user_1.unreadCount}}</span></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="!loadingChats && privateChats.length < 1">
                <p class="no-conversations">Your private chats will appear here.</p>
              </div>

              <div *ngIf="loadingChats">
                <p class="no-conversations">Loading Chats...</p>
              </div>

            </div>
          </div>
          <!-- When searching for contact/Chat -->
          <div class="contacts" style="min-height: 340px;" *ngIf="search">
            <div class="chat-list-header"></div>
            <div *ngIf="searchResult.length > 0">
              <div *ngFor="let chat of searchResult">
                <div *ngIf="!chat.name" class="each-chat btn-flat" (click)="selectedFromSearch(chat)">
                  <div class="one-chat-btn">
                    <div class="profile-pic col s2">
                      <img loading="lazy" class="avatar" alt="User Profile Photo" [src]="chat.profileImgLink || imgLink">
                    </div>
                    <div class="chat-details col s6">
                      <div class="contact-name">{{chat.GivenName || chat.FirstName}} {{chat.LastName}}</div>

                    </div>
                  </div>
                </div>
                <div *ngIf="chat.name" class="each-chat btn-flat" (click)="selectedGroup(chat)">
                  <div class="one-chat-btn">
                    <div class="profile-pic col s2">
                      <img loading="lazy" class="avatar" alt="Profile Photo" [src]="chat.profilePicture || imgLink">
                    </div>
                    <div class="chat-details col s6">
                      <div class="contact-name">{{chat.name}}</div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</div>
