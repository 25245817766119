import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state/app.state';
import { selectAppSettings } from 'src/app/state/app.selectors'; 
import * as AppActions from 'src/app/state/app.actions';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ChangeVisibilityService {
  constructor(private store: Store<AppState>) {}

  updateAppSections(sectionName: string) {
    this.store.select(selectAppSettings)
      .pipe(
        distinctUntilChanged((prev, curr) =>
          prev.appSections.every((p, idx) => p.isEnabled === curr.appSections[idx].isEnabled)
        )
      )
      .subscribe((settings) => {
        const newSettings = {
          ...settings,
          appSections: settings.appSections.map((section) => ({
            ...section,
            isEnabled: section.originalName === sectionName ? true : section.isEnabled
          }))
        };

        // Dispatch only if a change is detected
        if (!settings.appSections.every((section, idx) => 
            section.isEnabled === newSettings.appSections[idx].isEnabled)) {
          this.store.dispatch(AppActions.updateAppSettings({ settings: newSettings }));
        }
      });
  }
  sortAppSectionsAndDispatch() {
    this.store.select(selectAppSettings)
      .pipe(
        distinctUntilChanged((prev, curr) => JSON.stringify(prev.appSections) === JSON.stringify(curr.appSections))
      )
      .subscribe((settings) => {
        // Create a new sorted copy of the appSections array
        const sortedAppSections = [...settings.appSections].sort((a, b) => a.index - b.index);

        // Check if the sorted array is actually different from the original
        if (JSON.stringify(sortedAppSections) !== JSON.stringify(settings.appSections)) {
          // Create a new settings object with the sorted appSections
          const newSettings = {
            ...settings,
            appSections: sortedAppSections
          };

          // Dispatch the updated settings to the store only if changes were made
          this.store.dispatch(AppActions.updateAppSettings({ settings: newSettings }));
        }
      });
  }
}
