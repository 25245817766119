import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(private db: AngularFirestore) { }

  postsListRef: AngularFirestoreCollection<any>;
  postRef: AngularFirestoreDocument<any>;

  savePermission(model: any){
    const permission = {
      id: this.db.createId(),
      employeeId: model.employeeId,
      permission: model.permission,
    };
    return this.db.collection('Permission').doc(permission.id).set(permission);
  }
  getPermissionByEmployeeId(employeeId){
    return this.db.collection('Permission', ref => ref.where('employeeId', '==', employeeId));
  }
  updatePermission(permission){
    return this.db.collection('Permission').doc(permission.id).update(permission);
  }
  deleteNote(permission: any){
    return this.db.collection('Permission').doc(permission.id).delete();
  }

}
