import { AuthService } from './../../../../services/firebase/auth.service';
import { MediaService } from './../../../../services/api/media.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { UntypedFormBuilder, UntypedFormArray, UntypedFormGroup, Validators, FormControl, NgModel } from '@angular/forms';
import { Location } from '@angular/common';
import { ImageModel, ImageUploaderResponseModel } from 'src/app/models/ImageModel';
import { CustomSectionService } from 'src/app/services/firebase/custom-section.service';
import { Router } from '@angular/router';
import { GlobalConstants } from 'src/app/models/GlobalConstants';
import { HelperService } from 'src/app/services/helper/helper';
import { EnvironmentService } from 'src/app/services/helper/env';
import { EmployeeScheduleModel } from 'src/app/models/ScheduleModel';
import { ScheduleService } from 'src/app/services/firebase/employee-schedule.service';
import { ManifestService } from 'src/app/services/api/manifest.service';
import {
  Tooltip,
  initTE,
} from "tw-elements";
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { UserState } from 'src/app/state/app.state';
import { selectAppSettings } from 'src/app/state/app.selectors';


@Component({
  selector: 'app-brand-setup',
  templateUrl: './brand-setup.component.html',
  styleUrls: ['./brand-setup.component.css']
})
export class BrandSetupComponent implements OnInit {


  @Input() calledFromEmptyDashboard:string;
  @Input() selectedTab:string;
  @Output() customized = new EventEmitter<string>();

  @Output() closeCollapse = new EventEmitter<string>();
  @Output() brandSetupSaved = new EventEmitter<string>();

  appSettingsForm: UntypedFormGroup;
  generalSettingsForm: UntypedFormGroup;
  manifestForm: UntypedFormGroup;

  model: any;
  themeColor: any;
  defaultThemeColor = environment.appTheme.themeColor;
  isSidebarMenu = false;
  isBottomMenu = false;
  imgErrorMsg: any;
  base64Image: any;
  binDoc: File;
  showLogoInput = true;
  showImageFromServer = false;
  oldUrl: any;
  currentUser: any;
  isBusinessApp = false;
  isMobile = false;
  mobileBase64Image: any;
  useBase64=false

  // Added B
  verifyUsers = false;
  department = false;
  isChecked = false;
  
  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  // modal
  sectionSettingsModalOpen = false;
  eachSectionSettingsModalOpen = false;
  sectionResetModalOpen = false;
  hasActiveSection = false;
  section: any;
  editModalOpen = false;
  settingsToBeSaved: any;
  deleteModalOpen = false;
  sectionToBeDeleted: any;
  appSections = GlobalConstants.AppSections;
  currencies = GlobalConstants.Currencies;


  // signatureForm: FormGroup;
  // greetings : any;
  // signOff : any;
  role: any;
  isAdmin: boolean;

  cropModalOpen = false;
  imageChangedEvent: any = '';
  openBrandCollapse = false;
  openGeneralCollapse = false;
  openSectionCollapse = false;
  openAreasCollapse = false;
  openPushCollapse = false;
  openEmailCollapse = false;
  openSmsCollapse = false;
  openInfoCollapse = false;

  mediaList = [];
  returnedMediaLinks = {
    imageURL: '',
    videoUrl: '',
    pdfUrl: ''
  };
  uploadFileModalOpen = false;
  newModel: any;

  // isNationApp = environment.firstNation.name == "FNLeaders" ? true : false

  // chatgpt

  selectedInput;
  chatGptModalOpen = false;
  requestMessage;

  manifestModel: any;
  urlPattern = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-|=|?]*/?';

  manifest: any;
  newSchedule: any;
  existingSchedule: any;
  defaultSchedule: any;

  appUrlFinal2 = ((environment.firstNation.website).slice(-2)) == '/#' ? (environment.firstNation.website).slice(0, -2) : environment.firstNation.website;

  appUrlFinal = this.appUrlFinal2;

  imageResizer_72: any;
  imageResizer_96: any;
  imageResizer_128: any;
  imageResizer_192: any;
  imageResizer_256: any;
  imageResizer_512: any;
  writeFileParameter: any;

  previewToShow: boolean = false;
  appSettings$: Subscription;

  constructor(public appSettingsService: AppSettingsService,
              private fb: UntypedFormBuilder,
              private location: Location,
              private mediaService: MediaService,
              private authService: AuthService,
              private router: Router,
              private customSectionService: CustomSectionService,
              private helperService: HelperService,
              public envService: EnvironmentService,
              private manifestService: ManifestService,
              private scheduleService: ScheduleService,
              private store: Store<UserState>,
              ) {
                this.appSettings$ = this.store.select(selectAppSettings).subscribe((settings) => { 
                  this.appSections = settings.appSections;
                  this.model = {...settings};
                });

            

                if (typeof window['Capacitor'] !== 'undefined' && window['Capacitor']['platform'] !== 'web' ) {

                  this.isMobile = true;
                }
              }

  ngOnInit() {
    initTE({ Tooltip })
    this.currentUser = this.authService.getGlobalUser();
    if(this.currentUser){
      this.role = this.currentUser.role;
    }
    if(this.role == 'Admin'){
      this.isAdmin = true;
    }

    this.generalSettingsForm = this.fb.group({
      serviceType: [''],
      hasServicesCategory: [],
      hasProductsCategory: [],
    });
    this.manifestForm = this.fb.group({
      name: ['',[Validators.required]],
      shortName: ['', [Validators.required]],
      // image:['',[ Validators.pattern(this.urlPattern)]],
      // size:['',[Validators.required]],
      icons: this.fb.array([]),
    });

    // this.signatureForm = this.fb.group({
    //   greetings: [''],
    //   signOff:['']
    // })

    this.appSettingsForm = this.fb.group({
      appName: ['', [Validators.required]],
      description: [''],
      appLogo: [''],
      buttonOption: [''],
      navbarOption: [''],
      themeColor: [''],
      rightOrLeftSidebar: [false],
      serviceType: [''],
      serviceName: ['Services'],
      // serviceCategoryName: ['Category'],
      productName: ['Products'],
      // productCategoryName: ['Category'],
      takeAppointment: [''],
      hasEmployees: [false],
      isBusinessApp: [true],
      hasProductsCategory: [false],
      hasServicesCategory: [false],
      verifyUsers: [false],
      department: [false],
      welcomeMessage: [''],
      currency: [''],
      greetings:[''],
      signOff : [''], 
      messageSMS:[''],
      // greetingsSMS:[''],
      // signOffSMS: [''],
      adminEmail: this.fb.array([this.fb.control('', [Validators.email])])
    });

       
      if (this.appSections) {
            // this.appSections = appSettings[0].appSections;
            this.appSections.forEach(section => {
              if (section.isEnabled){
                this.hasActiveSection = true;
              }
            });
            // this.model = appSettings[0];
            if(this.model){
              this.isBusinessApp = this.model.isBusinessApp;
              this.verifyUsers = this.model.verifyUsers;
              this.model.logo = this.model.logo ? this.model.logo : '';
              this.appSettingsForm.patchValue({
                appName: this.model.appName ? this.model.appName : '',
                buttonOption: this.model.buttonOption ? this.model.buttonOption : 'rectangularbutton',
                navbarOption: this.model.navbarOption ? this.model.navbarOption : 'bottomnavbar',
                themeColor: this.model.themeColor ? this.model.themeColor : '',
                rightOrLeftSidebar: this.model.rightOrLeftSidebar ? this.model.rightOrLeftSidebar : false,
                description: this.model.description ? this.model.description : '',
                serviceType: this.model.serviceType ? this.model.serviceType : 'Both',
                appLogo: this.model.logo,
                takeAppointment: this.model.takeAppointment ? this.model.takeAppointment : '',
                hasEmployees: this.model.hasEmployees ? this.model.hasEmployees : false,
                isBusinessApp: this.model.isBusinessApp ? this.model.isBusinessApp : true,
                serviceName: this.model.serviceName ? this.model.serviceName : 'Services',
                // serviceCategoryName: this.model.serviceCategoryName ? this.model.serviceCategoryName : 'Category',
                productName: this.model.productName ? this.model.productName : 'Products',
                // productCategoryName: this.model.productCategoryName ? this.model.productCategoryName : 'Category',
                hasProductsCategory: this.model.hasProductsCategory ? this.model.hasProductsCategory : false,
                hasServicesCategory: this.model.hasServicesCategory ? this.model.hasServicesCategory : false,
                verifyUsers: this.model.verifyUsers ? this.model.verifyUsers : false,
                department: this.model.department ? this.model.department : false,
                welcomeMessage: this.model.welcomeMessage ? this.model.welcomeMessage : '',
                currency: this.model.currency ? this.model.currency : 'USD',
                adminEmail: this.model.adminEmail ? this.model.adminEmail : [],
                greetings: this.model.greetings ? this.model.greetings: '',
                signOff: this.model.signOff ? this.model.signOff: '',
                // greetingsSMS: this.model.greetingsSMS ? this.model.greetingsSMS: '',
                // signOffSMS: this.model.signOffSMS ? this.model.signOffSMS: '',
                messageSMS: this.model.messageSMS ? this.model.messageSMS: '',
              });
              this.appSettingsForm.patchValue({
                adminEmail: this.model.adminEmail as UntypedFormArray
              })
              if(this.model.adminEmail.length > 1){
                for(let i = 1; i < this.model.adminEmail.length; i++){
                  this.adminEmail.push(this.fb.control(this.model.adminEmail[i], [Validators.email, Validators.required]));
                }
              }
              if (this.model.navbarOption === 'sidenavbar') {
                this.isSidebarMenu = true;
              }
              if (this.model.logo){
                this.showImageFromServer = true;
                this.oldUrl = this.model.logo;
  
              }
  
              this.themeColor = this.model.themeColor ? this.model.themeColor : this.defaultThemeColor;
            } 

      
            if(this.envService.fromFNLeaders){
              this.updateSettingsDefault()
            }
      }

    // this.manifestService.getManifest().valueChanges().subscribe((x : any) => {
    //     if(x && x.length){
    //       this.manifest = x[0];
    //       const answers = this.manifestForm.get('icons') as FormArray;
    //       this.manifestForm.patchValue({
    //         name : this.manifest.name,
    //         shortName: this.manifest.shortName,
    //       });
    //     }
    // });
 
  if(this.envService.fromFNLeaders){
    this.updateSettingsDefault()
  }
  }
  get businessAppControl(){return this.appSettingsForm.get('isBusinessApp')}
  get welcomeMessage(){return this.appSettingsForm.get('welcomeMessage')}
  get appName(){return this.appSettingsForm.get('appName')}

  get adminEmail(){return this.appSettingsForm.get('adminEmail') as UntypedFormArray; }
  get hasProductsCategory(){return this.appSettingsForm.get('hasProductsCategory') }
  get hasServicesCategory(){return this.appSettingsForm.get('hasServicesCategory') }
  get serviceType(){return this.appSettingsForm.get('serviceType'); }
  get greetings() {return this.appSettingsForm.get('greetings')};
  get signOff() {return this.appSettingsForm.get('signOff')}
  get messageSMS() {return this.appSettingsForm.get('messageSMS')}
  get takeAppointment() {return this.appSettingsForm.get('takeAppointment')}
  get hasEmployees() {return this.appSettingsForm.get('hasEmployees'); }

  get name(){return this.manifestForm.get('name')}
  get shortName(){return this.manifestForm.get('shortName')}
  get icons() : UntypedFormArray {return this.manifestForm.get("icons") as UntypedFormArray}

  // get icons(){return this.appSettingsForm.get('icons') as FormArray; }

  // get greetingsSMS() {return this.appSettingsForm.get('greetingsSMS')};
  // get signOffSMS() {return this.appSettingsForm.get('signOffSMS')}

  newIcon(): UntypedFormGroup {
    return this.fb.group({
      image: '',
      size: '',
    })
  }
  addIcons() {
    this.icons.push(this.newIcon());
  }
 
  removeIcon(i:number) {
    this.icons.removeAt(i);
  }

  // saveManifest(model : any){
  //   if(this.manifest){
  //  this.manifestModel = {
  //       name: model.name,
  //       shortName: model.shortName,
  //       icon : [{
  //         image: model.image ? model.image : '',
  //         size: model.icons.size
  //       }]
  //   }
  //   this.manifestModel.id = this.manifest.id;
  //   this.manifestService.updateManifest(this.manifestModel);
  // }else{
  //       // this.manifestService.saveManifest(this.manifestModel);
  // }
  // }

  addAdminEmail(){
    this.adminEmail.push(this.fb.control('', [Validators.email, Validators.required]));
  }
//   addIcon(){
//     this.icons.push(this.fb.array([
//       new FormControl('image', [Validators.required]), 
//       new FormControl('size',[Validators.required])])
// );

//   }

  removeAdminEmail(indexOfEmailToBeRemoved){
    this.adminEmail.removeAt(indexOfEmailToBeRemoved);
  }

  get appLogo() { return this.appSettingsForm.get('appLogo'); }

  updateSettingsDefault(){
    this.hasServicesCategory.patchValue(false)
    this.hasProductsCategory.patchValue(false)
    this.businessAppControl.patchValue(false)
    this.takeAppointment.patchValue('')
    this.hasEmployees.patchValue(false)
    this.serviceType.patchValue('None')
    
    this.hasServicesCategory.updateValueAndValidity()
    this.hasProductsCategory.updateValueAndValidity()
    this.businessAppControl.updateValueAndValidity()
    this.takeAppointment.updateValueAndValidity()
    this.hasEmployees.patchValue(false)
    this.serviceType.updateValueAndValidity()
  }

  SaveSettings(modelValues: any) {

      // updating
      if (this.model){
        // update option
        this.model.buttonOption = modelValues.buttonOption;
        this.model.navbarOption = modelValues.navbarOption;
        this.model.themeColor = modelValues.themeColor;
        this.model.appName = modelValues.appName;
        this.model.description = modelValues.description;
        this.model.rightOrLeftSidebar = modelValues.rightOrLeftSidebar;
        this.model.serviceType = modelValues.serviceType;
        this.model.takeAppointment = modelValues.takeAppointment;
        this.model.hasEmployees = modelValues.hasEmployees;
        this.model.isBusinessApp = modelValues.isBusinessApp;
        this.model.hasServicesCategory = modelValues.hasServicesCategory;
        this.model.serviceName = modelValues.serviceName;
        // this.model.serviceCategoryName = modelValues.serviceCategoryName;
        this.model.hasProductsCategory = modelValues.hasProductsCategory;
        this.model.productName = modelValues.productName;
        // this.model.productCategoryName = modelValues.productCategoryName;
        this.model.verifyUsers = modelValues.verifyUsers;
        this.model.department = modelValues.department;
        this.model.welcomeMessage = modelValues.welcomeMessage;
        this.model.adminEmail = modelValues.adminEmail;
        this.model.currency = modelValues.currency;
        this.model.appSections = this.appSections;
        this.model.greetings = modelValues.greetings ? modelValues.greetings : '';
        this.model.signOff = modelValues.signOff ? modelValues.signOff : '';
        this.model.messageSMS = modelValues.messageSMS ? modelValues.messageSMS : '';
        // this.model.greetingsSMS = modelValues.greetingsSMS;
        // this.model.signOffSMS = modelValues.signOffSMS;
        if(modelValues.takeAppointment == 'yes'){
          if(this.existingSchedule){
            this.existingSchedule.employeeId = this.currentUser.uid;
            this.scheduleService.updateSchedule(this.existingSchedule)
          }
          else if(this.newSchedule){
            this.newSchedule.employeeId = this.currentUser.uid;
            this.scheduleService.createEmployeeSchedule(this.newSchedule)
          }      
          else{
            if(this.defaultSchedule){
              this.newSchedule = {schedule: [], employeeId: this.currentUser.uid , id: ''}
              this.newSchedule.schedule = this.defaultSchedule.value.schedules;
              this.scheduleService.getScheduleByEmployeeId(this.currentUser.uid).valueChanges().subscribe((schedule: any) => {
                if(schedule && schedule.length > 0){
                  this.newSchedule.id = schedule[0].id;
                  this.scheduleService.updateSchedule(this.existingSchedule)
                } else {
                  this.scheduleService.createEmployeeSchedule(this.newSchedule)
                }
              })
            }
          }
        }

        if(this.mediaList.length > 0){
          this.uploadFileModalOpen = true;
        }
        if(this.mediaList.length == 0){
          this.updateAppSettings(this.model);
        }

      }
      // creating new setting
      else {
        this.newModel = modelValues;

        this.newModel.appSections = this.appSections;
        if(this.mediaList.length > 0){
          this.uploadFileModalOpen = true;
        }
        if(this.mediaList.length == 0){
          this.createAppSettings(this.newModel);
        }
      }

  }

  updateAppSettings(model){
    this.imageResizer_72={
      width: 72,
      height: 72,
      imageUrl: this.model.logo,
      appInfo:{
        appName: environment.firstNation.name,
        appUrl: this.appUrlFinal
      }
    }
    this.imageResizer_96={
      width: 96,
      height: 96,
      imageUrl: this.model.logo,
      appInfo:{
        appName: environment.firstNation.name,
        appUrl: this.appUrlFinal
      }
    }
    this.imageResizer_128={
      width: 128,
      height: 128,
      imageUrl: this.model.logo,
      appInfo:{
        appName: environment.firstNation.name,
        appUrl: this.appUrlFinal
      }
    }
    this.imageResizer_192={
      width: 192,
      height: 192,
      imageUrl: this.model.logo,
      appInfo:{
        appName: environment.firstNation.name,
        appUrl: this.appUrlFinal
      }
    }
    this.imageResizer_256={
      width: 256,
      height: 256,
      imageUrl: this.model.logo,
      appInfo:{
        appName: environment.firstNation.name,
        appUrl: this.appUrlFinal
      }
    }
    this.imageResizer_512={
      width: 512,
      height: 512,
      imageUrl: this.model.logo,
      appInfo:{
        appName: environment.firstNation.name,
        appUrl: this.appUrlFinal
      }
    }
    this.appSettingsService.updateAppSettings(model).then(() => {
      this.toast({ html: 'App Settings Successfully Updated!', classes: 'green', type: 'success' });
      if(this.calledFromEmptyDashboard == 'BrandSetup'){
        this.closeCollapse.emit('BrandSetup');
        this.brandSetupSaved.emit('brandSetupSaved')
      }
    }).then((x)=>{
      this.manifestService.imageResizer(this.imageResizer_72).subscribe(x=>{ })
      this.manifestService.imageResizer(this.imageResizer_96).subscribe(x=>{ });
      this.manifestService.imageResizer(this.imageResizer_128).subscribe(x=>{ });
      this.manifestService.imageResizer(this.imageResizer_192).subscribe(x=>{ });
      this.manifestService.imageResizer(this.imageResizer_256).subscribe(x=>{ });
      this.manifestService.imageResizer(this.imageResizer_512).subscribe(x=>{ });
  
    }).then((y) =>{
      this.writeFileParameter={
        theme_color: this.model.themeColor,
        appInfo:{
          appName: environment.firstNation.name,
          appUrl: this.appUrlFinal
        }
      }
  
      this.manifestService.WriteFile(this.writeFileParameter).subscribe(x=>{ });
    }).catch(err => {
      this.toast({html: err.message, classes: 'red', type: 'failure'});
    });
    this.mediaList = [];
  }
  
  createAppSettings(model){
    if(this.model && this.model.logo){
    this.imageResizer_72={
      width: 72,
      height: 72,
      imageUrl: this.model.logo,
      appInfo:{
        appName: environment.firstNation.name,
        appUrl: this.appUrlFinal
      }
    }
    this.imageResizer_96={
      width: 96,
      height: 96,
      imageUrl: this.model.logo,
      appInfo:{
        appName: environment.firstNation.name,
        appUrl: this.appUrlFinal
      }
    }
    this.imageResizer_128={
      width: 128,
      height: 128,
      imageUrl: this.model.logo,
      appInfo:{
        appName: environment.firstNation.name,
        appUrl: this.appUrlFinal
      }
    }
    this.imageResizer_192={
      width: 192,
      height: 192,
      imageUrl: this.model.logo,
      appInfo:{
        appName: environment.firstNation.name,
        appUrl: this.appUrlFinal
      }
    }
    this.imageResizer_256={
      width: 256,
      height: 256,
      imageUrl: this.model.logo,
      appInfo:{
        appName: environment.firstNation.name,
        appUrl: this.appUrlFinal
      }
    }
    this.imageResizer_512={
      width: 512,
      height: 512,
      imageUrl: this.model.logo,
      appInfo:{
        appName: environment.firstNation.name,
        appUrl: this.appUrlFinal
      }
    }
  }
    this.appSettingsService.saveAppSettings(model).then(
      () => {
      this.toast({ html: 'App Settings Successfully Saved!', classes: 'green' , type: 'success'});
      if(this.calledFromEmptyDashboard == 'BrandSetup'){
        this.closeCollapse.emit('BrandSetup');
        this.brandSetupSaved.emit('brandSetupSaved')
      }
    }).then((x)=>{
      this.manifestService.imageResizer(this.imageResizer_72).subscribe(x=>{ })
      this.manifestService.imageResizer(this.imageResizer_96).subscribe(x=>{ });
      this.manifestService.imageResizer(this.imageResizer_128).subscribe(x=>{ });
      this.manifestService.imageResizer(this.imageResizer_192).subscribe(x=>{ });
      this.manifestService.imageResizer(this.imageResizer_256).subscribe(x=>{ });
      this.manifestService.imageResizer(this.imageResizer_512).subscribe(x=>{ });
    }).then((y) =>{
      this.writeFileParameter={
        theme_color: this.model.themeColor,
        appInfo:{
          appName: environment.firstNation.name,
          appUrl: this.appUrlFinal
        }
      }
  
      this.manifestService.WriteFile(this.writeFileParameter).subscribe(x=>{ });
    }).catch(err => {console.log(err); });
    this.mediaList = [];
  }

  cancelButtonClicked() {
  }

  // to darken and lighten colors
  shadeColor(originalColor: string, amount) {
    return '#' + originalColor.replace(/^#/, '')
    .replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount))
    .toString(16))
    .substr(-2));
  }
  sidebarClicked() {
    this.isBottomMenu = false;
    this.isSidebarMenu = true;
  }
  bottomNavbarClicked() {
    this.isBottomMenu = true;
    this.isSidebarMenu = false;
  }

  backClicked(){
    this.location.back();
  }
  handleImageInput(files: FileList) {
    if (!this.validateImage(files[0].name)) {
      this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';
      this.appLogo.patchValue('');
      return;
    }
    this.binDoc = files[0];
    this.imgErrorMsg = undefined;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.base64Image = reader.result as string;
    };
  }
  private validateImage(name: any) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() === 'png') {
      return true;
    } else if (ext.toLowerCase() === 'jpg') {
      return true;
    } else if (ext.toLowerCase() === 'jpeg') {
      return true;
    } else {
      return false;
    }
  }
  removeFile() {
    this.binDoc = undefined;
    this.base64Image = undefined;
    this.appLogo.patchValue('');
  }

  removeSavedFile() {
    this.binDoc = undefined;
    this.base64Image = undefined;
    this.oldUrl = '';
    this.model.logo = '';
    this.appLogo.patchValue('');
    this.showImageFromServer = false;
    this.showLogoInput = true;
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }

  onAppTypeChange(){
    this.isBusinessApp = this.appSettingsForm.value.isBusinessApp;
  }

  onAppSignupChange(){
    this.verifyUsers = this.appSettingsForm.value.verifyUsers;
  }
  onDepartmentChange(){
    this.department = this.appSettingsForm.value.department;
  }
  // onTakeAppointment(){
  //   this.takeAppointment = this.appSettingsForm
  // }

  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
      this.closeAllCollapse();
      // this.backClicked();
    }, 2000);
  }

  selectItem(offer){
    this.hasActiveSection = false;
    this.appSections.forEach(section => {
      if (section.originalName === offer.originalName){
        section.isEnabled = !section.isEnabled;
        if (section.isEnabled){
          this.section = section;
          this.openEditEachSectionModal();
        }
      }
      if (section.isEnabled){
        this.hasActiveSection = true;
      }
    });
  }
  openDeleteSectionModal(offer){
    this.sectionToBeDeleted = offer;
    // this.hasActiveSection = false;
    this.deleteModalOpen = true;
  }
  redirectToEditPage(offer){
    // this.router.navigate(['/admin/editSection', offer.customSectionId]);
    this.customSectionService.getCustomSectionBySectionTitle(offer.originalName).valueChanges().subscribe((sections: any) => {
      if(sections.length > 0){
        this.router.navigate(['/admin/edit-custom-section/', sections[0].id]);
      }
    });


  }
  deleteItem(){
    this.deleteModalOpen = false;
    let sec: any;
    if(this.sectionToBeDeleted){

      // delete section from db
      this.customSectionService.getCustomSectionBySectionTitle(this.sectionToBeDeleted.originalName).valueChanges().subscribe(sections => {
        sec = sections[0];      
        if(sec){
          this.customSectionService.deleteCustomSection(sec);
          this.customSectionService.deleteElementsBySectionId(sec.customSectionId);
        }
      })

      //delete from menu
      this.appSections = this.appSections.filter(section =>{
        return section.originalName != this.sectionToBeDeleted.originalName
      })
    }
    
    this.model.appsections = this.appSections;
    this.SaveSettings(this.model);
  }



  onOfferChange(){
    if (this.serviceType.value === 'Service'){
      this.appSections.forEach(section => {
        if (section.originalName === 'Services'){
          section.isEnabled= true;
        }
        if (section.originalName === 'Products'){
          section.isEnabled= false;
        }
      });
    }
    if (this.serviceType.value === 'Product'){
      this.appSections.forEach(section => {
        if (section.originalName === 'Products'){
          section.isEnabled= true;
        }
        if (section.originalName === 'Services'){
          section.isEnabled= false;
        }
      });
    }
    if (this.serviceType.value === 'Both'){
      this.appSections.forEach(section => {
        if (section.originalName === 'Services' || section.originalName === 'Products'){
          section.isEnabled= true;
        }
      });
    }
    if (this.serviceType.value === 'Personal'){
      this.appSections.forEach(section => {
        if (section.originalName === 'Services' || section.originalName === 'Products'){
          section.isEnabled= false;
        }
      });
    }
  }
  closeEditSectionModal(){
    this.sectionSettingsModalOpen = false;
    this.eachSectionSettingsModalOpen = false;
  }
  openEditSectionModal(){
    this.sectionSettingsModalOpen = true;
  }
  openEditEachSectionModal(){
    this.eachSectionSettingsModalOpen = true;
  }
  sectionInputChange(offer, event){
    this.hasActiveSection = false;
    this.appSections.forEach(section => {
      if (section.isEnabled){
        this.hasActiveSection = true;
      }
      if (section.originalName === offer.originalName){
        section.name = event.target.value;
      }
    });
  }
  visibilityChange(offer, event){
    this.appSections.forEach(section => {
      if (section.originalName === offer.originalName){
        section.isEnabled = event.target.value;
      }
    });
  }
  saveVisibilityChange(){
    this.model.appSections = this.appSections;
    this.closeEditSectionModal();
    this.imageResizer_72={
      width: 72,
      height: 72,
      imageUrl: this.model.logo,
      appInfo:{
        appName: environment.firstNation.name,
        appUrl: this.appUrlFinal
      }
    }
    this.imageResizer_96={
      width: 96,
      height: 96,
      imageUrl: this.model.logo,
      appInfo:{
        appName: environment.firstNation.name,
        appUrl: this.appUrlFinal
      }
    }
    this.imageResizer_128={
      width: 128,
      height: 128,
      imageUrl: this.model.logo,
      appInfo:{
        appName: environment.firstNation.name,
        appUrl: this.appUrlFinal
      }
    }
    this.imageResizer_192={
      width: 192,
      height: 192,
      imageUrl: this.model.logo,
      appInfo:{
        appName: environment.firstNation.name,
        appUrl: this.appUrlFinal
      }
    }
    this.imageResizer_256={
      width: 256,
      height: 256,
      imageUrl: this.model.logo,
      appInfo:{
        appName: environment.firstNation.name,
        appUrl: this.appUrlFinal
      }
    }
    this.imageResizer_512={
      width: 512,
      height: 512,
      imageUrl: this.model.logo,
      appInfo:{
        appName: environment.firstNation.name,
        appUrl: this.appUrlFinal
      }
    }
    this.appSettingsService.updateAppSettings(this.model).then(
      () => {
        this.toast({ html: 'App Settings Successfully Saved!', classes: 'green' , type: 'success'});
      }).then((x)=>{
        this.manifestService.imageResizer(this.imageResizer_72).subscribe(x=>{ })
        this.manifestService.imageResizer(this.imageResizer_96).subscribe(x=>{ });
        this.manifestService.imageResizer(this.imageResizer_128).subscribe(x=>{ });
        this.manifestService.imageResizer(this.imageResizer_192).subscribe(x=>{ });
        this.manifestService.imageResizer(this.imageResizer_256).subscribe(x=>{ });
        this.manifestService.imageResizer(this.imageResizer_512).subscribe(x=>{ });
      }).then((y) =>{
        this.writeFileParameter={
          theme_color: this.model.themeColor,
          appInfo:{
            appName: environment.firstNation.name,
            appUrl: this.appUrlFinal
          }
        }
        this.manifestService.WriteFile(this.writeFileParameter).subscribe(x=>{ });
      }).catch(err => {console.log(err); });
  }
  resetDefaultSectionNames(){
    this.sectionResetModalOpen = false;
    this.appSections.forEach(section => {
        section.name = section.originalName;
    });
    this.model.appSections = this.appSections;
    this.SaveSettings(this.appSettingsForm.value);
  }
  openSectionResetModal(){
    this.sectionResetModalOpen = true;
  }

  public onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
  }

  saveEmailSign(){
    this.model.greetings = this.greetings;
    this.model.signOff = this.signOff;

    this.appSettingsService.updateAppSettings(this.model).then(() => {
      this.toast({html: 'Email Signature Successfully Saved!', classes: 'green', type:'success'})
    }).catch(err => {console.log(err); });

  }
  saveSmsSign(){
    this.model.messageSMS = this.messageSMS;
    this.appSettingsService.updateAppSettings(this.model).then(() => {
      this.toast({html: 'Email Signature Successfully Saved!', classes: 'green', type:'success'})
    }).catch(err => {console.log(err); });
  }

  // get greetings() { return this.signatureForm.get('greetings'); }
  // get signOff() { return this.signatureForm.get('signOff'); }
  fileChangeEvent(event){
    this.imageChangedEvent = event;
    // this.cropModalOpen = true;

    if (!this.validateImage(event[0].name)) {
      this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';
      return;
    }
    this.binDoc = event[0];
    const reader = new FileReader();
    reader.readAsDataURL(event[0]);
    reader.onload = () => {
      this.base64Image = reader.result as string;
      this.useBase64 = true;
      this.processCroppedImage(this.base64Image);
    };
  }
  closeCroppingModal(){    
    this.cropModalOpen = false;
  }

  onImageUpload(response:ImageUploaderResponseModel) {
    if (response.error){
      this.imgErrorMsg = response.errorMessage
      return
    }

    this.imageChangedEvent = 'data:image/jpeg;base64,' + response.imgBase64;
    this.mobileBase64Image = response.imgBase64;
    this.useBase64=true
    this.cropModalOpen = true;
 }
 
  processCroppedImage(event){
    this.cropModalOpen = false;
    this.base64Image = event;
    // this.binDoc = this.base64Image;
    let base64result = this.base64Image.split(',')[1];
    this.useBase64 = false;
    const img = new ImageModel();
   
    if(this.isMobile){
      img.imgBase64 = this.mobileBase64Image;
      this.mediaList.push({type:'Image', file: img});
    }else{
      img.imgBase64 = base64result; 
      this.mediaList.push({type:'Image', file: img});
    } 
  }
  newSection(){
    if(this.currentUser.role == 'Admin'){
      this.router.navigate(['/admin/create-new-section'])
    }else {
      this.router.navigate(['/employee/create-new-section'])

    }
  }

  confirmUpdate(appsettings){
    this.settingsToBeSaved = appsettings;
    this.editModalOpen = true;
  }
  saveUpdate(){
    this.editModalOpen = false;
    this.SaveSettings(this.settingsToBeSaved);
  }
  closeEditModal(){
    this.editModalOpen = false;
  }
  closeAllCollapse(){
    this.openBrandCollapse = false;
    this.openGeneralCollapse = false;
    this.openSectionCollapse = false;
    this.openAreasCollapse = false;
    this.openPushCollapse = false;
    this.openEmailCollapse = false;
    this.openSmsCollapse = false;
    this.openInfoCollapse = false;
  }
  
  getTheUrlsSaved(event){
    this.returnedMediaLinks = event;
    this.uploadFileModalOpen = false;
    if(this.model){
      this.model.logo = this.returnedMediaLinks.imageURL;
      this.updateAppSettings(this.model);
    } 
    if(this.newModel){
      this.newModel.logo = this.returnedMediaLinks.imageURL;
      this.createAppSettings(this.newModel);
    }
  }

  selectCollapseToOpen(setupName){
    if(setupName==='Brand'){
      if(this.openBrandCollapse===false){
        this.closeAllCollapse();
        this.openBrandCollapse = true;
      } else {
        this.openBrandCollapse = false;
      }
    }
    if(setupName==='General'){    
      if(this.openGeneralCollapse===false){
        this.closeAllCollapse();
        this.openGeneralCollapse = true;
      } else {
        this.openGeneralCollapse = false;
      }
    }
    if(setupName==='Section'){
      if(this.openSectionCollapse===false){
        this.closeAllCollapse();
        this.openSectionCollapse = true;
      } else {
        this.openSectionCollapse = false;
      }
    }
    if(setupName==='Areas'){
      if(this.openAreasCollapse===false){
        this.closeAllCollapse();
        this.openAreasCollapse = true;
      } else {
        this.openAreasCollapse = false;
      }
    }
    if(setupName==='Push'){
      if(this.openPushCollapse===false){
        this.closeAllCollapse();
        this.openPushCollapse = true;
      } else {
        this.openPushCollapse = false;
      }
    }
    if(setupName==='Email'){
      if(this.openEmailCollapse===false){
        this.closeAllCollapse();
        this.openEmailCollapse = true;
      } else {
        this.openEmailCollapse = false;
      }
    }
    if(setupName==='Sms'){
      if(this.openSmsCollapse===false){
        this.closeAllCollapse();
        this.openSmsCollapse = true;
      } else {
        this.openSmsCollapse = false;
      }
    }
    if(setupName==='Info'){
      if(this.openInfoCollapse===false){
        this.closeAllCollapse();
        this.openInfoCollapse = true;
      } else {
        this.openInfoCollapse = false;
      }
    }
  }
  
  showPreview(){
    this.previewToShow = true;
  }


  onUseChatGpt() {
    this.selectedInput = this.welcomeMessage;
    this.requestMessage = this.helperService.getInnerText(this.welcomeMessage.value);
    this.chatGptModalOpen = true;
  }

  closeChatGptModal() {
    this.chatGptModalOpen = false;
  }
  updateWelcomeMessage(event) {
    event.input.patchValue(event.message)
    this.closeChatGptModal();
  }

  initializeSchedule(data){
    this.defaultSchedule = data;
  }
updateSchedule(data){
  const employeeSchedule = {
      employeeId: this.currentUser.uid,
      schedule: this.helperService.validateSchedule(data.data.schedules),
      id: data.savedEmployeeSchedule.id
  };
  this.existingSchedule = employeeSchedule;
}

createSchedule(data){
  const employeeSchedule = new EmployeeScheduleModel();
  employeeSchedule.employeeId = this.currentUser.id;
  employeeSchedule.schedule = this.helperService.validateSchedule(data.schedules);
  this.newSchedule = employeeSchedule;
}
}
