import { Component, OnInit } from '@angular/core';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';

// declare var M: any;

@Component({
  selector: 'app-user-menu-settings',
  templateUrl: './user-menu-settings.component.html',
  styleUrls: ['./user-menu-settings.component.css']
})
export class UserMenuSettingsComponent implements OnInit {

  menu: any;
  isNewSetting = true;
  isMenuSettings = true;
  isEditItem = false;
  selectedItem: any;
  selectedItemName: any;
  constructor(private settingService: AppSettingsService) {
    this.getSettings();
  }

  ngOnInit(): void {
    if (!this.menu) {
      this.menu = {
        id: '',
        items: [
          {
            index : 0,
            name: 'Chats',
            displayName: 'Chats',
            bgColor: '#2196F3',
            textColor: '#FFFFFF',
            icon: 'chat',
            iconColor: '#FFFFFF',
            isEnabled: true,
          },
          {
            index: 1,
            name: 'Staff',
            displayName: 'Staff',
            bgColor: '#00796B',
            textColor: '#FFFFFF',
            icon: 'people',
            iconColor: '#FFFFFF',
            isEnabled: true,
          },
          {
            index: 2,
            name: 'News',
            displayName: 'News',
            bgColor: '#512DA8',
            textColor: '#FFFFFF',
            icon: 'newspaper',
            iconColor: '#FFFFFF',
            isEnabled: true,
          },
          {
            index: 3,
            name: 'Events',
            displayName: 'Events',
            bgColor: '#558B2F',
            textColor: '#FFFFFF',
            icon: 'event',
            iconColor: '#FFFFFF',
            isEnabled: true,
          },
          {
            index: 4,
            name: 'Setting',
            displayName: 'Setting',
            bgColor: '#212121',
            textColor: '#FFFFFF',
            icon: 'settings',
            iconColor: '#FFFFFF',
            isEnabled: true,
          },
          {
            index: 5,
            name: 'UpdateProfile',
            displayName: 'Update Profile',
            bgColor: '#D81B60',
            textColor: '#FFFFFF',
            icon : 'work',
            iconColor: '#FFFFFF',
            isEnabled: true,
          },
          {
            index: 6,
            name: 'Posts',
            displayName: 'Posts',
            bgColor: '#7CB342',
            textColor: '#FFFFFF',
            icon: 'newspaper',
            iconColor: '#FFFFFF',
            isEnabled: true,
          },
          {
            index: 7,
            name: 'Logout',
            displayName: 'Logout',
            bgColor: '#DC143C',
            textColor: '#FFFFFF',
            icon : 'lock',
            iconColor: '#FFFFFF',
            isEnabled: true,
          },
        ]
      };
    }

  }

  checkedChange(section: any) {
    section.isEnabled = !section.isEnabled;
  }

  moveSection(element, position: number) {
    const index = this.menu.items.indexOf(element);
    const newIndex = index + position;
    if (newIndex < 0 || newIndex === this.menu.items.length) {return; } // Already at the top or bottom.

    this.menu.items.splice(index, 1);
    this.menu.items.splice(newIndex, 0, element);
    this.menu.items.forEach(element => {
       element.index =  this.menu.items.indexOf(element);
    });

  }

  editSection(section) {
    this.selectedItem = section;
    this.selectedItemName = section.name;
    this.isMenuSettings = false;
    this.isEditItem = true;
    setTimeout(() => {
      // M.updateTextFields();
    }, 25);
  }
  saveChanges() {
    if (this.menu && this.isNewSetting) {
      this.settingService.saveUserMenuSettings(this.menu).then(x => {
        // M.toast({ html: 'Changes Saved Successfully.', classes: 'green' });
        this.getSettings();
      });
    }

    if (this.menu && !this.isNewSetting) {
      this.settingService.updateUserMenuSettings(this.menu).then(x => {
        // M.toast({ html: 'Changes Saved Successfully.', classes: 'green' });
        this.getSettings();
      });
    }
  }
  backClicked(){
    this.selectedItem = undefined;
    this.isEditItem = false;
    this.isMenuSettings = true;
  }
  getSettings() {
    this.settingService.getUserMenuSettings().valueChanges().subscribe(hp => {
      if (hp) {
        if (hp.length > 0) {
          this.menu = hp[0];
          this.isNewSetting = false;
          if (this.selectedItemName === 'Chats') { this.selectedItem = this.menu.items.filter(s => s.name === 'Chats')[0]; }
          if (this.selectedItemName === 'Staff') {
            this.selectedItem = this.menu.items.filter(s => s.name === 'Staff')[0]; }
          if (this.selectedItemName === 'News') { this.selectedItem = this.menu.items.filter(s => s.name === 'News')[0]; }
          if (this.selectedItemName === 'Events') { this.selectedItem = this.menu.items.filter(s => s.name === 'Events')[0]; }
          if (this.selectedItemName === 'Setting') { this.selectedItem = this.menu.items.filter(s => s.name === 'Setting')[0]; }
          if (this.selectedItemName === 'UpdateProfile') { this.selectedItem = this.menu.items.filter(s => s.name === 'UpdateProfile')[0]; }
          if (this.selectedItemName === 'Posts') { this.selectedItem = this.menu.items.filter(s => s.name === 'Posts')[0]; }
          if (this.selectedItemName === 'Logout') { this.selectedItem = this.menu.items.filter(s => s.name === 'Logout')[0]; }
          setTimeout(() => {
            // M.updateTextFields();
          }, 25);
        }
      }
    });
  }

}
