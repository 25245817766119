export class ChatGptFilterModel {
  wordCount: number = 15;
  includeHashTag: boolean = false;
  numberOfHashTags: number = 3;
  keyWords: string[] = [];
}

export class ChatGptChatModel {
  model: string = "gpt-3.5-turbo";
  message: string;
}

export class ImageGenerateModel {
  model: string = "dall-e-3";
  message: string;
  size: string = "1024x1024";
}

export class ChatGptResponseModel {
  message: string;
  input: string;
}

export class ImageGenerateResponseModel {
  file: File; 
  fileName: string;
}
