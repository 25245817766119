 
 <div class="flex justify-center ">

    <div class="flex gap-5 py-10 justify-center w-full ">
        <div *ngFor="let service of services | slice:0:itemNumber" class="w-full">
            <div class="w-full flex  space-y-2 ">
                <div 
                    [routerLink]="['/view-service', service.id]"
                    [ngClass]="{'w-full flex flex-col-reverse  ': preview && view=='mobile', 'w-full flex flex-col-reverse md:flex-row md:space-x-6  items-center ': !preview || (preview && view=='web')}"
                    class=" space-y-4  ">      
                    <div 
                      class=" bg-white flex flex-col p-6 space-y-4 shadow-sm w-full" 
                      [ngClass]="{'w-full flex flex-col ': preview && view=='mobile', 'w-full': (!service.imgLink && !service.videoLink) && (!preview || (preview && view=='web')), 'md:w-1/2': (service.imgLink || service.videoLink) && (!preview || (preview && view=='web'))}">
                      <div class="break-words" >
                        <h6 [routerLink]="['/admin/service', service.id]"  class="text-xl font-semibold" [ngStyle]="{'color':service.titleColor}">{{truncateHTML(getInnerText(service.serviceName),40)}}</h6>
                      </div>
                      <p class="text-base text-gray-600 text-justify break-words">
                        {{truncateHTML(getInnerText(service.serviceDescription), 350)}}
                      </p>
                         <!-- Services included -->
              <ng-container *ngIf="service.allServices">
                <div  class="flex flex-col space-y-2 " *ngIf="service.allServices.length != 0">
                  <div  *ngFor="let serviceFeature of service.allServices" class=" flex flex-row  items-center rounded-md border-2 ">
                    <div class="flex flex-col w-full ">
                      <div tabindex="0" class="w-full collapse collapse-plus rounded-sm border border-base-300 shadow-sm bg-gray-100 ">
                        <input type="checkbox" class="peer" />
                        <div  class="collapse-title cursor-pointer font-bold text-customTeal" >
                          <div class="break-words">
                          
                              {{serviceFeature.serviceTitle}}
                        
                          </div>
                        </div>
                        <div *ngIf="currentUser"  class="collapse-content break-words">
                          <p>$ {{serviceFeature.servicePrice}}</p> 
                          <div class=" break-words">
                            <p class="italic">{{serviceFeature.serviceUnderDescription}}</p>       
                          </div>       
                          <button *ngIf="serviceFeature.hasAppointment==true" class="btn-custom-solid float-right" (click)="BookServiceAppointment(service,serviceFeature)">Book</button>
                        </div>
                        <div *ngIf="!currentUser" class="collapse-content">
                          <p>$ {{serviceFeature.servicePrice}}</p>
                          <div class=" break-words">
                            <p class="italic">{{serviceFeature.serviceUnderDescription}}</p>       
                          </div>   
                          <button *ngIf="serviceFeature.hasAppointment==true" class="btn-custom-solid float-right" (click)="OpenLoginPopup()">Book</button>
                        </div>
                      </div>
                    </div>
  
             
                  </div>
                </div>
  
              </ng-container>
              <div class="flex justify-end space-x-2 items-center py-4">
                <p>Visit</p>
                <div class="rounded-full p-4 bg-customTeal">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"  class="w-4 h-4 stroke-white">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                  </svg>
                  
                </div>
              </div>
                    </div>
                    <div *ngIf="service.imgLink && !service.videoLink" [routerLink]="['/admin/service', service.id]"  
                    class="w-full " 
                      [ngClass]="{' hidden': !service.imgLink && !service.videoLink, 'md:w-1/2': (service.imgLink || service.videoLink) && (!preview || (preview && view=='web')),'w-full': (service.imgLink || service.videoLink) && (preview && view=='mobile')}">
               
                      <div class="w-full " *ngIf="service.imgLink" >
                        <img [src]="service.imgLink" class="object-contain w-full md:h-96">
                      </div>
                      <div>
                        <div *ngIf="service.videoLink && service.imgLink" (click)="redirectToservice(service.id)"  class="w-full ">
                          
                          <video controls *ngIf="service.videoLink != ''" [src]="service.videoLink" class="object-contain w-full md:h-96" alt="Logo" [poster]="service.imgLink"></video>           
                        </div>
                        <div *ngIf="service.videoLink && !service.imgLink" (click)="redirectToservice(service.id)"  class="w-full ">
                          <video controls *ngIf="service.videoLink != ''" [src]="service.videoLink" class="object-contain w-full md:h-96" alt="Logo"></video>           
                        </div>
                      </div>
            
                    </div>
          
                  </div>
              </div>
        </div>
    
      </div>
    
  </div>