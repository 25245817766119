import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  constructor(private db: AngularFirestore) { }

  postsListRef: AngularFirestoreCollection<any>;
  postRef: AngularFirestoreDocument<any>;


  saveTasks(model: any){
    model.id = this.db.createId();
    return this.db.collection('Tasks').doc(model.id).set(model);
  }

  updateTask (model: any) {
    return this.db.collection('Tasks').doc(model.id).update(model);
}
  getTasksById(taskId: string){
  return this.db.collection('Tasks', ref => ref.where('id', '==', taskId));
}

  getTasksByUsersId(userId: string){
  return this.db.collection('Tasks', ref => ref.where('taskMadeBy', '==', userId))
  }

  getTasksMadeTo(empId: string){
    return this.db.collection('Tasks',ref => ref.where('taskMadeFor', '==', empId))
  }

  getTasks(){
    return this.db.collection('Tasks');
  }
  
  deleteTaskById(taskId: string){
  return this.db.collection('Tasks').doc(taskId).delete()
  }
  updateTaskById (taskId: any) {
    return this.db.collection('Tasks').doc(taskId).update(ref => ref.where('id', "==", taskId));
}

}
