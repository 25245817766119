import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { UserService } from './../../../services/firebase/user.service';
import { Component, OnInit } from '@angular/core';
// import { GalleryItem, ImageItem } from 'ng-gallery';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

// declare var $: any;
// declare var M: any;

@Component({
  selector: 'app-view-profile',
  templateUrl: './view-profile.component.html',
  styleUrls: ['./view-profile.component.css']
})
export class ViewProfileComponent implements OnInit {

  id: any;
  user: any;
  isMobile = false;
  //images: GalleryItem[];
  images: any[];
  imgLink = 'https://www.w3schools.com/howto/img_avatar.png';
  profileImages: any = [];
  coverPhotoLink = '';
  updateProfileForm: UntypedFormGroup;
  socialMediaForm: UntypedFormGroup;
  constructor( private fireMembersService: FireMembersService ,
    private fb: UntypedFormBuilder,private route: ActivatedRoute, private location: Location) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
    this.id = params.get('id');
    });

    this.updateProfileForm = this.fb.group({
      GivenName: ['', [Validators.required]],
      MiddleName: [''],
      LastName: ['', [Validators.required]],
      City: [''],
      cellPhone: [''],
      Email: ['', [Validators.required]],



    });

    this.socialMediaForm = this.fb.group({
      instagramAcc: [''],
      twitterAcc: [''],
      linkedinAcc: [''],
      youtubeAcc: [''],
      showInstagramAcc: [''],
      showTwitterAcc: [''],
      showLinkedinAcc: [''],
      showYoutubeAcc: [''],
    })

    this.fireMembersService.getProfileByuserID(this.id).valueChanges().subscribe(x => {

      if(x){

        this.user = x[0];

        this.imgLink = (this.user.profileImgLink) || '';
        this.coverPhotoLink = this.user.coverPhotoLink || '';
        this.profileImages = this.user.profileImages || [];
        if(this.profileImages.length > 0) {
          this.images = [];
          this.profileImages.forEach(element => {

            this.images.push(
              ({ src: element, thumb: element }))
          });

          }

          this.updateProfileForm.setValue({
            GivenName: (this.user.GivenName) ? this.user.GivenName : '',
            MiddleName: (this.user.MiddleName) ? this.user.MiddleName : '',
            LastName: (this.user.LastName) ? this.user.LastName : '',
            City: (this.user.City) ? this.user.City : '',
            cellPhone: this.user.cellPhone,
            Email: this.user.Email,
          });

          this.socialMediaForm.setValue({
          instagramAcc: this.user.instagramAcc ? this.user.instagramAcc : '',
          twitterAcc: this.user.twitterAcc ? this.user.twitterAcc : '',
          linkedinAcc: this.user.linkedinAcc ? this.user.linkedinAcc : '',
          youtubeAcc: this.user.youtubeAcc ? this.user.youtubeAcc : '',
          showInstagramAcc: this.user.showInstagramAcc ? this.user.showInstagramAcc : 'Private',
          showTwitterAcc: this.user.showTwitterAcc ? this.user.showTwitterAcc : 'Private',
          showLinkedinAcc: this.user.showLinkedinAcc ? this.user.showLinkedinAcc : 'Private',
          showYoutubeAcc: this.user.showYoutubeAcc ? this.user.showYoutubeAcc : 'Private',
          });

        // setTimeout(() => {
        //   ($('.tooltipped')).tooltip();
        //   $('.modal').modal();
        //   $('.collapsible').collapsible();
        //   $('.carousel').carousel();
        // }, 25);

      }



    });

    // setTimeout(() => {
    //   $('.tabs').tabs();
    //   M.updateTextFields();
    //   $('.collapsible').collapsible();
    //   }, 25);


  }
  public getInitials(firstName: any, lastName: any) {
    if (firstName && lastName) {
      return firstName.substring(0, 1) + lastName.substring(0, 1);
    } else {return ''; }
  }
  backClicked(){
    this.location.back();
  }

}
