<!-- <div [class.hidden]="!show"  >
  <div *ngIf="show && isLogged" class="preloader-background center" >
    <progress class="progress w-56"></progress>
  </div>

  <div *ngIf="show && !isLogged && (router.url != '/admin/create-blog' || router.url != '/admin/create-event' )"  class="preloader-background center" >
    <progress class="progress w-56"></progress>
  </div>

  <div *ngIf="show && !isLogged  && (router.url == '/admin/create-blog' || router.url == '/admin/create-event')" class="" >
    <progress class="progress w-56">loading.....</progress>
    loading.....
  </div>
</div>
 -->

 <div  *ngIf="this.loadingService.getLoading()" class="cssload-container">
  <div class="cssload-speeding-wheel"></div>
</div>
