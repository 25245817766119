export class EmployeeAppointmentModel{
    id: any;
    employeeId: any;
    startTime: Date;
    endTime: Date;
    userId: any;
    serviceId: any;
    message: any;
    serviceName: any;
    rescheduled: boolean;
    rescheduleReason: any;
    rescheduledBy: any;
    allowReschedule: boolean;
    allowCancellation: boolean;
    employeeName : any;
    userName: any;
    email: any;
    phoneNumber: any;
    givenName: any;
    lastName: any;
    bookedBy: any;

}
