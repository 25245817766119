// src/app/services/form.service.ts
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  constructor(private firestore: AngularFirestore) { }

  createID() {
    return this.firestore.createId();
  }


  saveForm(formField: any) {
    formField.id = this.firestore.createId();
    let timestamp = new Date();
    formField.created_at = timestamp;
    formField.updated_at = timestamp;
    return this.firestore.collection('form').doc(formField.id).set(formField);
  }


  getForms() {
    return this.firestore.collection('form', ref => ref.orderBy('created_at'));
  }

  getContactHeaderNewId(formId: string) {
    return this.firestore.collection('ContactsHeaderNew').doc(formId).valueChanges();
  }

  saveContactHeader(model: any) {
    return this.firestore.collection('ContactsHeaderNew').doc(model.uid).set(model);
  }

  updateContactHeader(model: any) {
    return this.firestore.collection('ContactsHeaderNew').doc(model.uid).update(model);
  }

  saveContactHeaderNew(model: any) {
    model.id = this.firestore.createId();
    return this.firestore.collection('ContactsHeaderNew').doc(model.id).set(model);
  }

   // delete contact header
   deleteContactHeader(id: any,) {
    return this.firestore.collection('ContactsHeaderNew').doc(id).delete();
  }

  updateContactHeaderNew(model: any) {
    console.log("model is ", model)
    return this.firestore.collection('ContactsHeaderNew').doc(model.id).update(model);
  }

  getContactHeaderNew(): any {
    return this.firestore.collection('ContactsHeaderNew').get();
  }
}
