import { FirstLoginSetupComponent } from './first-login-setup/first-login-setup.component';
import { AppointmentSettingComponent } from './settings/appointment-setting/appointment-setting.component';
import { AppointmentRescheduleComponent } from './../shared/appointment-reschedule/appointment-reschedule.component';
import { AppSetupComponent } from './app-setup/app-setup.component';
import { AppRequestDetailComponent } from './app-request/app-request-detail/app-request-detail.component';
import { AppRequestComponent } from './app-request/app-request.component';
import { AboutSettingComponent } from './settings/about-setting/about-setting.component';
import { FaqSettingComponent } from './settings/faq-setting/faq-setting.component';
import { SeoSettingComponent } from './settings/seo-setting/seo-setting.component'
import { ViewBlogComponent } from '../shared/blog/view-blog/view-blog.component';
import { CreateBlogComponent } from './blog/create-blog/create-blog.component';
import { CreatePollComponent } from './polls/create-poll/create-poll.component';
import { PollsComponent } from './polls/polls.component';
import { EditPollComponent } from './polls/edit-poll/edit-poll.component';
import { ServiceProductComponent } from './service-product/service-product.component';
import { EditProductComponent } from './products/edit-product/edit-product.component';
import { ProductsComponent } from './products/products.component';
import { ManageProductCategoryComponent } from './products/manage-product-category/manage-product-category.component';
import { UserMenuSettingsComponent } from './settings/user-menu-settings/user-menu-settings.component';
import { EditServiceComponent } from './service/edit-service/edit-service.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AdminAuthGuard } from 'src/app/guards/admin-auth.guard';
import { EmployeeAuthGuard } from 'src/app/guards/employee-auth.guard';

import { AdminMainComponent } from './admin-main/admin-main.component';
import { UsersListComponent } from './users-list/users-list.component';
import { RegistrationRequestComponent } from './registration-request/registration-request.component';
import { MyInboxComponent } from './messenger/my-inbox/my-inbox.component';
import { SettingsComponent } from './settings/settings.component';
import { ContactSettingsComponent } from './settings/contact-settings/contact-settings.component';
import { AppSettingsComponent } from './settings/app-settings/app-settings.component';
import { ViewProfileComponent } from '../shared/view-profile/view-profile.component';
import { AccountSettingComponent } from './settings/account-setting/account-setting.component';
import { CreateEventComponent } from './events/create-event/create-event.component';
import { EditEventComponent } from './events/edit-event/edit-event.component';
import { ViewEventComponent } from './../shared/events/view-event/view-event.component';
import { ViewEventsListComponent } from './../shared/events/view-events-list/view-events-list.component';
import { LandingPageSettingsComponent } from './settings/landing-page-settings/landing-page-settings.component';
import { AdminMenuSettingsComponent } from './settings/admin-menu-settings/admin-menu-settings.component';
import { EmployeeMenuSettingsComponent } from './settings/employee-menu-settings/employee-menu-settings.component';
import { ChatListComponent } from '../shared/chat-list/chat-list.component';
import { EmployeeListComponent } from './employee/employee-list/employee-list.component';
import { GroupChatsListComponent } from './../shared/group-chats-list/group-chats-list.component';
import { EmployeeAppointmentComponent } from '../user/employee/employee-appointment/employee-appointment.component';
import { ViewServiceComponent } from '../shared/services/view-service/view-service.component';
import { CreateServiceComponent } from './service/create-service/create-service.component';
import { UpdateOwnerProfileComponent } from './settings/update-owner-profile/update-owner-profile.component';
import { ViewPostComponent } from '../shared/view-post/view-post.component';
import { AreasOfInterestComponent } from './settings/areas-of-interest/areas-of-interest.component';
import { CreateProductComponent } from './products/create-product/create-product.component';
import { PollResultsComponent } from './polls/poll-results/poll-results.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SocialMediaComponent } from './social-media/social-media.component';
import { SocialMediaProfileComponent } from './social-media/profile/profile.component';
import { SocialMediaDashBoardComponent } from '../shared/social-medial/dashboard/dashboard.component';
import { SocialMediaPostComponent } from '../shared/social-medial/post/post.component';
import { SocialMediaProfilesComponent } from '../shared/social-medial/profiles/profiles.component';
import { SocialMediaAnalysisComponent } from '../shared/social-medial/media-analysis/media-analysis.component';
import { SocialMediaSettingsComponent } from '../shared/social-medial/settings/settings.component';
import { SocialMediaAnalyticsComponent } from '../shared/social-medial/analytics/analytics.component';
import { SocialMediaCreatePostComponent } from '../shared/social-medial/post/create-post/create-post.component';
import { AdminSettingsMainComponent } from './admin-settings-main/admin-settings-main.component';
import { AdminSocialMainComponent } from './admin-social-main/admin-social-main.component';
import { ViewProductComponent } from './view-product/view-product.component';

import { EmployeeDetailComponent } from '../shared/employee-detail/employee-detail.component';
import { ClientDetailComponent } from '../shared/client-detail/client-detail.component';
import { AppointmentComponent } from './appointment/appointment.component';
import { AppointmentDetailComponent } from '../shared/appointment-detail/appointment-detail.component';
import { SocialMediaPostDetailComponent } from '../shared/social-medial/post/post-detail/post.detail.component';
import { EditBlogComponent } from './blog/edit-blog/edit-blog.component';
import { AdminNotificationsComponent } from './admin-notifications/admin-notifications.component';
import { BlogListComponent } from '../shared/blog/blog-list/blog-list.component';
import { ServicesListComponent } from '../shared/services/services-list/services-list.component';
import { SignupSettingsComponent } from './settings/signup-settings/signup-settings.component';
import { AdminProfileComponent } from './admin-profile/admin-profile.component';
import { DepartmentsComponent } from './settings/departments/departments.component';
import { EmployeeFormComponent } from './users-list/employee-form/employee-form.component';
import { EmployeeEditComponent } from './users-list/employee-edit/employee-edit.component';
import { ServiceProductsMenuComponent } from './settings/menu-settings/service-products/service-products.component';
import { CatagoryComponent } from '../shared/catagory/catagory.component';
import { ServicesComponent } from './service/services/services.component';
import { ServiceCategoryComponent } from '../shared/service-category/service-category.component';
import { EmployeeMainComponent } from '../employee/employee-main/employee-main.component';
import { HelpCenterComponent } from '../shared/help-center/help-center.component';
import { GuideDetailsComponent } from '../shared/help-center/guide-details/guide-details.component';
import { ScheduleComponent } from '../shared/schedule/schedule.component';
import { EmployeeScheduleComponent } from './employee-schedule/employee-schedule.component';
import { ClientFormComponent } from './client-form/client-form.component';
import { EditClientFormComponent } from './edit-client-form/edit-client-form.component';
import { PublicPageSetupComponent } from './public-page-setup/public-page-setup.component';
import { ChatMessagingComponent } from '../shared/messaging/messaging.component';
import { InviteSubscriberComponent } from './invite-subscriber/invite-subscriber.component';
import { EditSubscriberComponent } from './invite-subscriber/edit-subscriber/edit-subscriber.component';
import { CreateFormComponent } from './create-form/create-form.component';
import { CreateClientComponent } from './invite-subscriber/create-client/create-client.component';
import { EditClientInfoComponent } from './invite-subscriber/create-client/edit-client-info/edit-client-info.component';
import { CreateAppointmentComponent } from './create-appointment/create-appointment.component';

import { AnalyticsUserComponent } from './analytics-user/analytics-user.component';
import { AnalyticsPageComponent } from './analytics-page/analytics-page.component';
import { AnalyticsChannelComponent } from './analytics-channel/analytics-channel.component';
import { AnalyticsAllComponent } from './analytics-all/analytics-all.component';
import { VerifyAccountRequestComponent } from './verify-account-request/verify-account-request.component';
import { UploadImageComponent } from '../shared/upload-image/upload-image.component';
import { ClientFeedComponent } from '../shared/feed/client-feed/client-feed.component';
import { AppRequestAcceptComponent } from './app-request/app-request-accept/app-request-accept.component';
import { EmptyDashboardComponent } from './empty-dashboard/empty-dashboard.component';
import { CreateNewSectionComponent } from './custom_section/create-new-section/create-new-section.component';
import { SectionListComponent } from '../shared/custom-section/section-list/section-list.component';
import { EditCustomSectionComponent } from './custom_section/edit-custom-section/edit-custom-section.component';
import { CreateSectionElementComponent } from './custom_section/create-section-element/create-section-element.component';
import { EditSectionElementComponent } from './custom_section/edit-section-element/edit-section-element.component';
import { ViewSectionComponent } from '../shared/custom-section/view-section/view-section.component';
import { WelcomeSettingComponent } from './settings/welcome-setting/welcome-setting.component';
import { PollsAndSurveyComponent } from './polls-and-survey/polls-and-survey.component';
import { NotificationComponent } from './notification/notification.component';
import { ContactUsMessagesComponent } from './contact-us-messages/contact-us-messages.component';
import { PollsListComponent } from './polls-and-survey/polls-list/polls-list.component';
import { HomePageComponent } from '../app/home/home-page/home-page.component';
import { HomepageComponent } from './settings/homepage/homepage.component';
import { SliderSettingComponent } from './settings/slider-setting/slider-setting.component';
import { HeroSettingComponent } from './settings/hero-setting/hero-setting.component';
import { FooterSettingComponent } from './settings/footer-setting/footer-setting.component';
import { ContactListComponent } from './contact-list/contact-list.component';
import { AnalyticsSetupComponent } from './analytics-setup/analytics-setup.component';
import { CreateAnalyticsAccountComponent } from './analytics-setup/create-analytics-account/create-analytics-account.component';
import { AboutComponent } from '../app/about/about.component';
import { ContactFormComponent } from './contact-list/contact-form/contact-form.component';
import { ContactDetailComponent } from './contact-list/contact-detail/contact-detail.component';
import { ImportContactComponent } from './users-list/import-contact/import-contact.component';
import { UpdateFirebaseRulesComponent } from './update-firebase-rules/update-firebase-rules.component';
import { TemplatesComponent } from './templates/templates.component';
import { TemplateDetailComponent } from './templates/template-detail/template-detail.component';
import { FirstNationLeaderLists } from '../shared/firstnations/nation-leaders/nation-leaders-list.component';
import { LocateMapComponent } from '../shared/locate-map/locate-map.component';
import { EmailApppasswordGuideComponent } from './app-setup/email-apppassword-guide/email-apppassword-guide.component';
import { BillingSetupComponent } from './billing-setup/billing-setup.component';
import { FaqListComponent } from '../shared/faq-list/faq-list.component';
import { CustomerFormBuilderComponent } from './customer-form-builder/customer-form-builder.component';
import { CustomerFormBuilderConfigrationComponent } from './customer-form-builder/custom-form-builder-configration/customer-form-builder-configration.component';
import { AdminClientCreationFormComponent } from './invite-subscriber/create-client/admin-client-creation-form/admin-client-creation-form.component';
const routes: Routes = [
  {
    path: 'admin',
    component: AdminMainComponent,
    canActivate: [AdminAuthGuard],
    data: {
      title: "Glamorous",
      description: "Description Meta Tag Content",
      ogUrl: "your og url",
      keywords: " Keywords Meta Tag Content ",
    },
    children: [
      {
        path: 'viewpost',
        component: ViewPostComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'dashboard',
        component: HomepageComponent,
        canActivate: [AdminAuthGuard]
      },
      // {
      //   path: 'contact-list',
      //   component: ContactListComponent,
      //   canActivate: [AdminAuthGuard]
      // },
      {
        path: 'contact-detail/:id',
        component: ContactDetailComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'contact-form',
        component: ContactFormComponent,
        canActivate: [AdminAuthGuard]
      },

      {
        path: 'notification-preference',
        component: NotificationComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'homepage',
        component: HomepageComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'myinbox',
        component: MyInboxComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'locateMap',
        component: LocateMapComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'settings',
        component: SettingsComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'help-center',
        component: HelpCenterComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'onboarding-page',
        component: EmptyDashboardComponent,
        canActivate:[AdminAuthGuard]
      },
      {
        path: 'guide-details',
        component: GuideDetailsComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'templates',
        component: TemplatesComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'templates/template-detail',
        component: TemplateDetailComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'contact-settings',
        component: ContactSettingsComponent,
        canActivate: [AdminAuthGuard]
      },
      // {
      //   path: 'app-settings',
      //   component: AppSettingsComponent,
      //   canActivate: [AdminAuthGuard]
      // },
      {
        path: 'landing-page-settings',
        component: LandingPageSettingsComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'landing-page-settings/:section',
        component: LandingPageSettingsComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'messages',
        component: ChatMessagingComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'messages/:chatType',
        component: ChatMessagingComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'chat-list',
        component: ChatListComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'group-chats',
        component: GroupChatsListComponent,
        canActivate: [AdminAuthGuard]
      },
      // {
      //   path: 'about',
      //   component: AboutComponent,
      //   canActivate: [AdminAuthGuard]
      // },
      {
        path: 'registration-request',
        component: RegistrationRequestComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'verify-account-request',
        component: VerifyAccountRequestComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'users-list',
        component: UsersListComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'users-list/import-contact',
        component: ImportContactComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'customer-form',
        component: CustomerFormBuilderComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'customer-form/config',
        component: CustomerFormBuilderConfigrationComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'customer-form/config/:id',
        component: CustomerFormBuilderConfigrationComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'employee-detail/:id',
        component: EmployeeDetailComponent,
        canActivate: [AdminAuthGuard]

      },
      {
        path: 'client-detail/:id',
        component: ClientDetailComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'employee-edit/:id',
        component: EmployeeEditComponent,
        canActivate:[AdminAuthGuard]
      },
      {
        path: 'appointment-detail/:id',
        component: AppointmentDetailComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'appointments',
        component: AppointmentComponent,
        canActivate: [AdminAuthGuard]
      },
      // {
      //   path: 'googleAnalytics',
      //   component: AnalyticsComponent,
      //   canActivate: [AdminAuthGuard]
      // },
      {
        path: 'reschedule-appointment/:id',
        component: AppointmentRescheduleComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'create-appointment',
        component: CreateAppointmentComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'search-users',
        component: UsersListComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'employee-form',
        component: EmployeeFormComponent,
      },
      {
        path: 'subscriber-form',
        component: InviteSubscriberComponent,
      },
      {
        path: 'edit-subscriber',
        component: EditSubscriberComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'create-client-info',
        component: AdminClientCreationFormComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path:'create-client',
        component: CreateClientComponent,
      },
      {
        path: 'upload-image',
        component: UploadImageComponent,
      },
      {
        path:'edit-clientInfo/:id',
        component: EditClientInfoComponent
      },
      {
        path: 'my-profile',
       component: UpdateOwnerProfileComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'view-profile/:id',
        component: ViewProfileComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path:'admin-profile',
        component: AdminProfileComponent
      },
      // {
      //   path: 'create-group',
      //   component: CreateGroupComponent,
      //   canActivate: [AdminAuthGuard]
      // },
      {
        path: 'account-setting',
        component: AccountSettingComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'admin-menu-settings',
        component: AdminMenuSettingsComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'user-menu-settings',
        component: UserMenuSettingsComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'services-setting',
        component: ServicesListComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'services',
        component: ServicesComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'services/:id',
        component: ServicesComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'service/:id',
        component: ViewServiceComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'create-service',
        component: CreateServiceComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'create-service/:id',
        component: CreateServiceComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'edit-service/:id',
        component: EditServiceComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'employee-menu-settings',
        component: EmployeeMenuSettingsComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'employee-appointment/:id',
        component: EmployeeAppointmentComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'schedule',
        component: ScheduleComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'appointment-setting',
        component: AppointmentSettingComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'employee-schedule/:id',
        component: EmployeeScheduleComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'create-event',
        component: CreateEventComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'edit-event/:id',
        component: EditEventComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'event/:id',
        component: ViewEventComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'events',
        component: ViewEventsListComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'blogs',
        component: BlogListComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'create-blog',
        component: CreateBlogComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'view-blog/:id',
        component: ViewBlogComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'edit-blog/:id',
        component: EditBlogComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'polls-and-survey',
        component: PollsAndSurveyComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'polls-list',
        component: PollsListComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'registered-employee',
        component: EmployeeListComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'employee-appointment/:id',
        component: EmployeeAppointmentComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'areas-of-interest',
        component: AreasOfInterestComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'department',
        component:DepartmentsComponent,
        canActivate:[AdminAuthGuard]
      },
      {
        path: 'product-catagory',
        component: CatagoryComponent
      },
      {
        path: 'service-category',
        component: ServicesComponent,
      },
      {
        path: 'create-product',
        component: CreateProductComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'create-product/:id',
        component: CreateProductComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'manage-product-category',
        component: ManageProductCategoryComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'view-products',
        component: ProductsComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path:'products/:id',
        component: ProductsComponent
      },
      {
        path: 'edit-product/:id',
        component: EditProductComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'view-product/:id',
        component: ViewProductComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'service-product',
        component: ServiceProductComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'create-poll',
        component: CreatePollComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'client-form',
        component: ClientFormComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'create-form',
        component: CreateFormComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'edit-clientform',
        component: EditClientFormComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'edit-poll',
        component: EditPollComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'poll-results',
        component: PollResultsComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'polls-list',
        component: PollsComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'notifications',
        component: AdminNotificationsComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'app-request',
        component: AppRequestComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'app-request-detail/:id',
        component: AppRequestDetailComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'app-request-accept/:id',
        component: AppRequestAcceptComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'app-setup',
        component: AppSetupComponent,
        canActivate: [AdminAuthGuard],
        data: {
          title: "Title Page",
          description: "Description Meta Tag Content",
          ogUrl: "your og url",
          keywords: " Keywords Meta Tag Content ",
        },
      },
      {
        path: 'analytics-setup',
        component: AnalyticsSetupComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'create-analytics-account',
        component: CreateAnalyticsAccountComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'update-firebase-rules',
        component: UpdateFirebaseRulesComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'create-analytics-account',
        component: CreateAnalyticsAccountComponent,
        pathMatch: 'full',
        data: {
          queryParams: ['code', 'scope']
        },
        canActivate: [AdminAuthGuard]
      },
      {
        path:'email-appPassword-guide',
        component: EmailApppasswordGuideComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path:'billing-setup',
        component: BillingSetupComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'contact-messages',
        component: ContactUsMessagesComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'landing-page-settings',
        component: LandingPageSettingsComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'public-page-setup',
        component: PublicPageSetupComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'employees',
        component: DepartmentsComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'employees/:deptOrEmp',
        component: DepartmentsComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'departments',
        component: DepartmentsComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'departments/:deptOrEmp',
        component: DepartmentsComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'about',
        component: AboutSettingComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'signup',
        component: SignupSettingsComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'empty-dashboard',
        component: EmptyDashboardComponent,
        canActivate: [AdminAuthGuard]
      },      
      {
        path: 'create-new-section',
        component: CreateNewSectionComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'edit-custom-section/:id',
        component: EditCustomSectionComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'custom-section-list/:id',
        component: SectionListComponent,
        canActivate: [AdminAuthGuard]

      },
      {
        path: 'custom-section/:elementId',
        component: SectionListComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'create-section-element/:id',
        component: CreateSectionElementComponent,
        canActivate: [AdminAuthGuard]

      },      
      {
        path: 'edit-section-element/:id',
        component: EditSectionElementComponent,
        canActivate: [AdminAuthGuard]

      },
      {
        path: 'view-custom-section/:id',
        component: ViewSectionComponent,
        canActivate: [AdminAuthGuard]

      },
      {

        path: 'welcome-setting',
        component: WelcomeSettingComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'slider-setting',
        component: SliderSettingComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'hero-setting',
        component: HeroSettingComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'contact-setting',
        component: ContactSettingsComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'footer-setting',
        component: FooterSettingComponent,
        canActivate: [AdminAuthGuard]
      },
      {

        path: 'faq-setting',
        component: FaqSettingComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'faq-list',
        component: FaqListComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'social-media',
        component: SocialMediaComponent,
        canActivate: [AdminAuthGuard],
        children: [
          {
            path: 'profile',
            component: SocialMediaProfileComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'dashboard',
            // component: SocialMediaAnalysisComponent,
            component: SocialMediaDashBoardComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'post',
            component: SocialMediaPostComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'create-post',
            component: SocialMediaCreatePostComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'posts',
            component: SocialMediaPostComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'analytics',
            component: SocialMediaAnalyticsComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'settings',
            component: SocialMediaSettingsComponent,
            canActivate: [AdminAuthGuard]
          },
         
          { path: '', redirectTo: 'social-media', pathMatch: 'full'},


        ]

      },

      {
        path: 'analytics-app',
        component: AnalyticsAllComponent,
        canActivate: [AdminAuthGuard],
        children: [
          {
            path: 'user-analytics',
            component: AnalyticsUserComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'page-analytics',
            component: AnalyticsPageComponent,
            canActivate: [AdminAuthGuard]
          },
          {
            path: 'channle-analytics',
            component: AnalyticsChannelComponent,
            canActivate: [AdminAuthGuard]
          },
        ]


      },

      {
        path: 'social-media-profiles',
        component: SocialMediaProfilesComponent,
        canActivate: [AdminAuthGuard],
        children: [
          // {
          //   path: 'profile',
          //   component: SocialMediaProfileComponent,
          //   canActivate: [AdminAuthGuard]
          // },
          // {
          //   path: 'dashboard',
          //   component: SocialMediaDashBoardComponent,
          //   canActivate: [AdminAuthGuard]
          // },
          // {
          //   path: 'post',
          //   component: SocialMediaPostComponent,
          //   canActivate: [AdminAuthGuard]
          // },

          // { path: '', redirectTo: 'profile', pathMatch: 'full'}
        ]

      },
      {
        path: 'client-feed',
        component: ClientFeedComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'first-nations-leaders-list',
        component: FirstNationLeaderLists,
        canActivate: [AdminAuthGuard]
      },

     
    ]
  },
  {
    path: 'admin-settings',
    component: AdminSettingsMainComponent,
    canActivate: [AdminAuthGuard],
    children: [
      {
        path: 'app-settings',
        component: AppSettingsComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'social-media',
        component: ContactSettingsComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'landing-page-settings',
        component: LandingPageSettingsComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'areas-of-interest',
        component: AreasOfInterestComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path:'department',
        component: DepartmentsComponent,
        canActivate:[AdminAuthGuard]
      },
      {
        path: 'organization',
        component: DepartmentsComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'contact-info',
        component: CreateBlogComponent,
        canActivate: [AdminAuthGuard]
      },
      {

        path: 'faq',
        component: FaqSettingComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'employee-detail/:id',
        component: EmployeeDetailComponent,
        canActivate: [AdminAuthGuard]

      },
      {
        path: 'client-detail/:id',
        component: ClientDetailComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'appointment-detail/:id',
        component: AppointmentDetailComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'appointment',
        component: AppointmentComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'about',
        component: AboutSettingComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'signup',
        component: SignupSettingsComponent,
        canActivate: [AdminAuthGuard]
      },
      // {
      //   path: 'service-products-menu',
      //   component: ServiceProductsMenuComponent,
      //   canActivate: [AdminAuthGuard]
      // }
    ]
  },
  {
    path: 'admin-social',
    component: AdminSocialMainComponent,
    canActivate: [AdminAuthGuard],
    children: [
      // {
      //   path: 'profile',
      //   component: SocialMediaProfileComponent,
      //   canActivate: [AdminAuthGuard]
      // },
      {
        path: 'dashboard',
        // component: SocialMediaAnalysisComponent,
        component: SocialMediaDashBoardComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'post',
        component: SocialMediaPostComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'create-post',
        component: SocialMediaCreatePostComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'posts',
        component: SocialMediaPostComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'analytics',
        component: SocialMediaAnalyticsComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'settings',
        component: SocialMediaSettingsComponent,
        canActivate: [AdminAuthGuard]
      },
      {
        path: 'post-detail/:id',
        component: SocialMediaPostDetailComponent,
        canActivate: [AdminAuthGuard]
      },
      { path: '', redirectTo: 'social-media', pathMatch: 'full'}
    ]

  },
  {
    path: 'employee',
    component: AdminMainComponent,
    canActivate: [EmployeeAuthGuard],
    children: [
      // {
      //   path: 'dashboard',
      //   component: EmployeeMainComponent
      // }
      // {
      //   path: 'schedule',
      //   component: ScheduleComponent,
      //   canActivate: [EmployeeAuthGuard]
      // },
      // {
      //   path: 'appointment',
      //   component: AppointmentComponent,
      //   canActivate: [EmployeeAuthGuard]
      // }
    ]
  },
  // {
  //   path: 'first-login-setup',
  //   component: FirstLoginSetupComponent,
  //   canActivate: [AdminAuthGuard]

  // }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class AdminRoutingModule { }

