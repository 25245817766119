import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppinfoService } from 'src/app/services/backend-automation/appinfo.service';
import { AutomationService } from 'src/app/services/backend-automation/automation.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-billing-setup',
  templateUrl: './billing-setup.component.html',
  styleUrls: ['./billing-setup.component.css']
})
export class BillingSetupComponent implements OnInit {

  listOfBillingAccounts:any = []
  listOfProjects:any = []
  selected:any 
  defaultBillingAccount:any;
  makeButDefault: boolean = false;
  listLinkedProjectsModal: boolean;
  linkedProjects: any = [];
  isDemoApp = environment.firstNation.name == "Demo" ? true : false

  filteredLinkedProjects: any[] = [];
  filterOn: boolean = false;
  accPage: number = 1;
  propPage: number = 1;
  filterPage: number = 1;

  searchForm: FormGroup;

  currentClickedProject: any | string;
  deleteModal: boolean = false;
  // currentClickedPropId: any | string;

  toastClass: any;
  toastMessage: any;
  toastType: any;
  openToast: boolean;

  defaultBillingName:any;



  constructor(private automationService: AutomationService,
    private appInfoService: AppinfoService,
    private fb: FormBuilder,) { }

  ngOnInit(): void {
    this.automationService.listBillingAccounts().subscribe((res:any = []) => {
      this.listOfBillingAccounts = res
      this.getStoreDefaultBilling()

      this.getStoredDefaultAccount();

    })
   
    this.searchForm = this.fb.group({
      searchText: ['', Validators.required]
    })


  }

  get searchText() { return this.searchForm.get('searchText') }


  listProject(billingAccount:any | string){
    this.automationService.listProjectsUnderBilling(billingAccount).subscribe((res:any) => {
      this.listOfProjects = res
    })
  }


  updateDefaultBilling() {

   
      this.appInfoService.updateConfigSetting(this.selected).add(() => {
        // this.valid = true;
        // this.automationService.listAccountDetails().subscribe((res: any) => {
        //   let theAcc: any = res.find((acc: any) => acc.accountId === this.selected);
        //   this.configuredAnalyticsName = theAcc.name;
        // })
      });



  }

  makeItDefault(billingAccount: any | string) {
    this.appInfoService.updateDefaultBilling(billingAccount).add(() => {
      this.toast({ html: 'Default account saved successfully! ', classes: 'green', type: 'success' });

    })

  }

  getStoreDefaultBilling(){
    this.appInfoService.getDefaultBilling().valueChanges().subscribe((response:any) => {
      if (response.length > 0) {
        this.defaultBillingAccount = response[0];
        var account = this.listOfBillingAccounts.filter(account => account["name"] === this.defaultBillingAccount["billingAccount"]);
        this.defaultBillingAccount = account[0].name;
      }
    });
  }

  isDefault(billingAccount: any | string) {
    if (this.defaultBillingAccount === billingAccount) {
      this.makeButDefault = true;
      return true;
    } else {
      this.makeButDefault = false;
      return false;
    }
  }

  openListLinkedProjectsModal(billingAccount: any | string) {
    this.listLinkedProjectsModal = true;
    // this.automationService.
    this.automationService.listProjectsUnderBilling(billingAccount).subscribe((res: any) => {
      this.linkedProjects = res;
    })
    this.currentClickedProject = this.linkedProjects.projectId;

  }

  closeModal() {
    this.listLinkedProjectsModal = false;

    this.filteredLinkedProjects = []
    this.filterOn = false;
    this.searchText.setValue("")

  }

  search(modal) {
    this.filterOn = true;
    const matchingStrings = [];

    // for (const string of this.linkedProjects) {
    //   if (string.startsWith(modal.searchText)) {
    //     matchingStrings.push(string);
    //   }
    // }

    for (const item of this.linkedProjects) {
      // Check if item is a string before using startsWith
      if (item.projectId.startsWith(modal.searchText)) {
        matchingStrings.push(item);
      }
    }

    this.filteredLinkedProjects = matchingStrings
    return matchingStrings;
  }

  // deactivateProjectModal

  deleteProperty() {
    // this.automationService.deleteProperty(this.currentClickedPropId, this.currentClickedAccId).subscribe((res) => {
    //   this.toast({ html: 'Project removed from the account successfully! ', classes: 'green', type: 'success' });
    //   this.closeDeleteModal()
    //   this.closeModal()
    // }, (err) => {
    // })

    // this.automationService.activateBilling(this.currentClickedBillingAcc)

    this.automationService.activateBilling(this.currentClickedProject, "").subscribe((res: any) => {
      this.toast({ html: 'Project deactivated successfully! ', classes: 'green', type: 'success' });
      this.closeDeleteModal()
      this.closeModal()
    })
  }

  deactivateProjectModal(project) {
    this.currentClickedProject = project
    this.deleteModal = true

  }

  toast(obj) {
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green';
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
    }, 2000);
  }

  closeDeleteModal() {
    this.deleteModal = false
    this.filteredLinkedProjects = []
    this.filterOn = false;
  }

  getStoredDefaultAccount() {
    this.appInfoService.getDefaultBilling().valueChanges().subscribe((response) => {
      if (response.length > 0) {
        var account = this.listOfBillingAccounts.filter(account => account["name"] === this.defaultBillingAccount);
        this.defaultBillingName = account[0].displayName;
      }
    });
  }


}
