import { Component, OnInit } from '@angular/core';
import { AyrshareService } from 'src/app/services/social-media/ayrshare.service';
import { PostModel } from 'src/app/models/SocialMediaPostModel';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AyshareUserService } from 'src/app/services/firebase/ayrshare-social-media.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { UserService } from 'src/app/services/firebase/user.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ImageModel, ImageUploaderResponseModel } from 'src/app/models/ImageModel';
import { MediaService } from 'src/app/services/api/media.service';
import { icons } from 'src/app/models/SocialMediaIcons';
import { ValidationService } from 'src/app/services/social-media/validation.service';
import { ProfieKeyService } from 'src/app/services/social-media/profileKey.service';
import { HelperService } from 'src/app/services/helper/helper';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { FCMPushNotificationsService } from 'src/app/services/firebase/FCMPushNotifications.service';
import { NotificationModel } from 'src/app/models/Messages.Model';
import { CountersService } from 'src/app/services/firebase/counters.service';
import { NotificationPreferenceService } from 'src/app/services/firebase/notification-preference.service';


// declare var navigator: any;

@Component({
  selector: 'app-social-media-create-post',
  templateUrl: './create-post.component.html',
  styleUrls: ['./create-post.component.css']
})
export class SocialMediaCreatePostComponent implements OnInit {


  availablePlatforms = []
  profileKey
  postForm: UntypedFormGroup;
  imgErrorMsg
  previewModalOpen = false;

  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  socialMediaIcons = icons
  isList;
  objectKeys = Object.keys

  hasActiveAccounts = false
  userProfile
  loading = false
  previewData

  isMobile = false;

  cancelModalOpen = false
  currentUser: any;
  notifSettings

  uploadingMedia = false
  videoUploading = false
  imageUploading = false
  sendingPost = false

  sharePost: boolean;
  adminCounter: any;
  socialMediaCount: number = 0;
  currentDate = new Date();
  chatGptModalOpen = false;
  requestMessage
  youtubeVisibility=["public","unlisted","private" ]


  constructor(
    private socialMediaService: AyrshareService,
    private fb: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private location: Location,
    private mediaService: MediaService,
    private validationService: ValidationService,
    private profieKeyService: ProfieKeyService,
    private pushNotificationsService: FCMPushNotificationsService,
    private appSettingsService: AppSettingsService,
    private helperervice: HelperService,
    private authService: AuthService,
    private counter: CountersService,
    private notificationPreferenceService: NotificationPreferenceService,
    private helperSerivce: HelperService



  ) {
    if (typeof window['Capacitor'] !== 'undefined' && window['Capacitor']['platform'] !== 'web') {
      this.isMobile = true;
    }
  }

  ngOnInit() {
    this.openToast = false
    this.profieKeyService.currentProfileKey.subscribe(p => {
      this.profileKey = p
    }
    )
    this.currentUser = this.authService.getGlobalUser();

    // Get the current month
    const currentMonth = this.currentDate.getMonth();

    // Move to the next day
    const nextDay = new Date(this.currentDate);
    nextDay.setDate(this.currentDate.getDate() + 1);

    // Check if the next day is in a different month
    const isNewMonth = nextDay.getMonth() !== currentMonth;

    this.counter.getCounter().valueChanges().subscribe((counterAdmin) => {
      if (counterAdmin && counterAdmin.length != 0) {
        this.adminCounter = counterAdmin[0];
        this.socialMediaCount = Number.isNaN(this.adminCounter.socialMediaCount) ? 0 : this.adminCounter.socialMediaCount;

        if (isNewMonth) {
          this.socialMediaCount = 0;
        } else {
          if (this.socialMediaCount <= 100) {
            this.socialMediaCount = Number.isNaN(this.adminCounter.socialMediaCount) ? 0 : this.adminCounter.socialMediaCount;
          } else if (this.socialMediaCount > 100) {
          }

        }
      }
      else {
        this.socialMediaCount = 0;
        const counter = {
          id: '',
          SMSTotalCounter: 0,
          emailTotalCounter: 0,
          chatGptCounter: 0,
          socialMediaCount: 0,
        }
        this.counter.saveCounter(counter);
      }

    });

    this.getUserProfile()



    this.postForm = this.fb.group({
      post: ['', [Validators.required]],
      schedule: ['Immediate'],
      scheduleDate: [''],
      scheduleTime: [''],
      platforms: this.fb.array([], [Validators.required, this.validationService.notFalseValueValidator]),
      imageUrls: this.fb.array([]),
      videoUrls: this.fb.array([], [this.validationService.imageMaxLengthValidator(1)]),
      youtubeVisibility: ['public'],

    })

    // this.availablePlatforms.map(p => this.platforms.push(this.newPlatform(p)))
    this.currentUser = this.authService.getGlobalUser();
    if (this.currentUser && this.currentUser.role == 'Employee') {
      this.appSettingsService.getPushNotificationSettingsList().valueChanges().subscribe(x => {
        if (x && x.length > 0) {
          this.notifSettings = x[0]
        }
      })
    }

    if (this.profieKeyService.shouldShare) {
      this.sharePost = this.profieKeyService.shouldShare;
      this.patchPostForm()

    }

    this.imageUrls.valueChanges.subscribe(new_value => this.checkPlatformsValidity())
    this.videoUrls.valueChanges.subscribe(new_value => this.checkPlatformsValidity())


  }

  getUserProfile() {

    if (this.profileKey) {
      this.loading = true;
      this.socialMediaService.getUserByProfileKey(this.profileKey).toPromise()
        .then(data => {
          this.userProfile = data
          this.hasActiveAccounts = true ? data['activeSocialAccounts'] && data['activeSocialAccounts'].length > 0 : false
          this.loading = false;

          if (this.hasActiveAccounts) {
            this.availablePlatforms = data['activeSocialAccounts']
            this.availablePlatforms.map(p => this.platforms.push(this.newPlatform(p)))

          }
        })
        .catch(err => {

          this.loading = false;


        })


    }

  }

  patchPostForm() {
    this.profieKeyService.currentShareData.subscribe(values => {
      if (values) {
        this.post.patchValue(values['post'])

        if (values['imageUrls'] && values['imageUrls'].length > 0) {
          values['imageUrls'].map(url => this.imageUrls.push(new UntypedFormControl(url)))
        }

        if (values['videoUrls'] && values['videoUrls'].length > 0) {
          values['videoUrls'].map(url => this.videoUrls.push(new UntypedFormControl(url)))
        }
      }
      this.profieKeyService.shouldShare = false

    })
  }

  updateFormValue(event) {
  
    this.post.patchValue(event.message);
    this.closeChatGptModal();
  }


  onUseChatGpt() {   
    this.requestMessage = this.helperSerivce.getInnerText(this.post.value);
    this.chatGptModalOpen = true;
  }

  closeChatGptModal() {
    this.chatGptModalOpen = false;
  }



  get scheduleDate() {
    return this.postForm.get('scheduleDate')
  }
  get post() {
    return this.postForm.get('post')
  }
  get scheduleTime() {
    return this.postForm.get('scheduleTime')
  }

  get platforms() {
    return (<UntypedFormArray>this.postForm.get('platforms'))
  }

  get imageUrls() {
    return (<UntypedFormArray>this.postForm.get('imageUrls'))
  }
  get videoUrls() {
    return (<UntypedFormArray>this.postForm.get('videoUrls'))
  }

  pickSchedule(event) {
    if (event.target.value == 'Schedule') {
      this.postForm.controls['scheduleDate'].setValidators(Validators.required);
      this.postForm.controls['scheduleTime'].setValidators(Validators.required);
    } else {
      this.postForm.controls['scheduleDate'].patchValue('');
      this.postForm.controls['scheduleDate'].clearValidators();
      this.postForm.controls['scheduleTime'].patchValue('');
      this.postForm.controls['scheduleTime'].clearValidators();

    }
    this.postForm.controls['scheduleDate'].updateValueAndValidity()
    this.postForm.controls['scheduleTime'].updateValueAndValidity()
    this.postForm.updateValueAndValidity()
  }

  platformPick(platform, event) {
    let rules = {
      'facebook': this.facebookRules,
      'instagram': this.instagramRules,
      'pinterest': this.pinterestRules,
      "twitter": this.pinterestRules,
      'reddit': this.redditRules,
      'youtube': this.youtubeRules,
      'fbg': this.fbgRules,
      'telegram': this.telegramRules,
      "linkedin": this.linkedinRules,
      'gmb': this.gmbRules,
      'tiktok': this.tiktokRules
    }
    // rules[platform].bind(this)(event)


    if (event.target.checked) {
      if (['pinterest', 'instagram'].includes(platform)) {
        this.imageUrls.addValidators(Validators.required)

      }
      if (['youtube', 'tiktok'].includes(platform)) {
        this.videoUrls.addValidators(Validators.required)
      }
    } else {
      let hasMediaRequiredPlatforms = false
      let p = this.pickedPlatforms(this.platforms.value)
      let mediaRequiredPlatforms = ['pinterest', 'instagram', 'tiktok', 'youtube']

      if (p.includes(platform)) {
        let index = p.indexOf(platform)
        p.splice(index, 1)
      }

      p.forEach(x => {
        if (mediaRequiredPlatforms.includes(x)) {
          hasMediaRequiredPlatforms = true
        }
      })
      if (!hasMediaRequiredPlatforms) {
        this.imageUrls.clearValidators()
        this.videoUrls.clearValidators()
      }
    }
    this.videoUrls.updateValueAndValidity()
    this.imageUrls.updateValueAndValidity()
  }

  onPlatformSelect(event) {
    // let videoRequiredPlatforms = ["tiktok", "youtube"];
    // let imageRequiredPlatforms = ["pinterest"];
    // let imageOrVideoRequiredPlatorms = ["instagram"];

    // let noVideoPlatforms = ["pinterest"];
    // let noImagePlatforms = ["youtube", "tiktok"];
    // let noMediaPlatforms = ["fbg", "gmb", "reddit"];


    // let {platform, checked} = event
    // if (checked) {
    //   // instagram should be either or
    //   if (imageRequiredPlatforms.includes(platform)) {
    //     this.imageUrls.addValidators(Validators.required)

    //   }
    //   else if (videoRequiredPlatforms.includes(platform)) {
    //     this.videoUrls.addValidators(Validators.required)
    //   }
    //   else if (noVideoPlatforms.includes(platform)){

    //   }
    // } else {
    //   let hasMediaRequiredPlatforms = false
    //   let p = this.pickedPlatforms(this.platforms.value)
    //   let mediaRequiredPlatforms = ['pinterest', 'instagram', 'tiktok', 'youtube']

    //   if (p.includes(platform)) {
    //     let index = p.indexOf(platform)
    //     p.splice(index, 1)
    //   }

    //   p.forEach(x => {
    //     if (mediaRequiredPlatforms.includes(x)) {
    //       hasMediaRequiredPlatforms = true
    //     }
    //   })
    //   if (!hasMediaRequiredPlatforms) {
    //     this.imageUrls.clearValidators()
    //     this.videoUrls.clearValidators()
    //   }
    // }
    // this.videoUrls.updateValueAndValidity()
    // this.imageUrls.updateValueAndValidity()
  }

  onPlatformError(pl) {

    let index = this.platforms.value.findIndex(p => p.platform == pl)
    let platfrom = this.platforms.at(index).get('value')
    platfrom.patchValue(false)
    platfrom.updateValueAndValidity()
  }

  checkPlatformsValidity() {

    this.platforms.controls.forEach(platform => {
      this.isValidPlatform(platform as UntypedFormGroup)
    });

  }

  isValidPlatform(platformGroup: UntypedFormGroup) {
    let videoRequiredPlatforms = ["tiktok", "youtube"];
    let imageRequiredPlatforms = ["pinterest"];
    let imageOrVideoRequiredPlatorms = ["instagram"];

    let noVideoPlatforms = ["pinterest"];
    let noImagePlatforms = ["youtube", "tiktok"];
    let noMediaPlatforms = ["fbg", "gmb", "reddit"];

    let platform = platformGroup.get("platform").value;

    let platformHasError = false
    if (
      this.imageUrls.value.length == 0 &&
      imageRequiredPlatforms.includes(platform)
    ) {
      platformHasError = true;
    } else if (
      this.videoUrls.value.length == 0 &&
      videoRequiredPlatforms.includes(platform)
    ) {
      platformHasError = true;
    } else if (
      imageOrVideoRequiredPlatorms.includes(platform) &&
      this.videoUrls.value.length == 0 &&
      this.imageUrls.value.length == 0
    ) {
      platformHasError = true;
    } else if (
      noVideoPlatforms.includes(platform) &&
      this.videoUrls.value.length > 0
    ) {
      platformHasError = true;
    } else if (
      noImagePlatforms.includes(platform) &&
      this.imageUrls.value.length > 0
    ) {
      platformHasError = true;
    } else if (
      noMediaPlatforms.includes(platform) &&
      (this.imageUrls.value.length > 0 || this.videoUrls.value.length > 0)
    ) {
      platformHasError = true;
    } else {
      platformHasError = false;
    }

    if (platformHasError) {
      platformGroup.get("value").patchValue(false);
      platformGroup.get("value").updateValueAndValidity();
    } else {

    }
    platformGroup.get("value").updateValueAndValidity();
    platformGroup.updateValueAndValidity();

  }

  fbgRules(event) {
    // no images no video
    // if(event.target.checked){
    //     this.mediaUrls.addValidators(Validators.required)
    // }else{
    //   // if (!['pinterest','instagram','tiktok'].includes(platform)){
    //   //   this.mediaUrls.clearValidators()
    // }
  }
  youtubeRules(event) {
    // 1 video no images
    // if(event.target.checked){
    //   this.mediaUrls.addValidators([Validators.required,videoValidator])
    // }else{
    //   if(!this.pickedPlatforms(this.platforms.value).includes('tiktok')){
    //     this.mediaUrls.removeValidators(videoValidator)
    //   }
    // }

  }
  linkedinRules(event) {
    if (event.target.checked) {
      // 5 images 1 video
      // this.mediaUrls.addValidators([Validators.required])
      // this.mediaUrls.addValidators(this.validationService.mediaMinLengthValidator(this.mediaUrls,'image',5))

    } else {

    }

  }
  gmbRules(event) {
    if (event.target.checked) {
      // no iumage and no video
    } else {

    }

  }
  twitterRules(event) {
    if (event.target.checked) {
      // 4 images 1 video
    } else {

    }

  }

  instagramRules(event) {
    if (event.target.checked) {
      // 10 images and 1 video

    } else {

    }
  }

  tiktokRules(event) {
    // 1 video no imag
    if (event.target.checked) {

    } else {

    }
  }
  pinterestRules(event) {
    // 1 image no video
    // if (event.target.checked) {

    //   // this.mediaUrls.addValidators(this.validationService.mediaMinLengthValidator('image',5))
    //   // this.mediaUrls.removeValidators([this.validationService.mediaMinLengthValidator2('video',1),this.validationService.mediaMinLengthValidator2('image',1)])
    //   this.mediaUrls.addValidators([
    //     this.validationService.mediaMinLengthValidator('video', 0),
    //     this.validationService.mediaMinLengthValidator('image', 1),
    //     Validators.required
    //   ])
    // } else {
    //   this.mediaUrls.clearValidators()


    // }
  }

  redditRules(event) {
    // no image , no video
    if (event.target.checked) {

    } else {

    }
  }

  telegramRules(event) {
    // 1 image 1 video
    if (event.target.checked) {

    } else {

    }
  }

  facebookRules(event) {
    // max image 10 and 1 video
    if (event.target.checked) {

    } else {

    }
  }

  newPlatform(platform, value = false) {
    return this.fb.group({
      platform: [platform],
      value: [value]
    })
  }

  cancel() {
    // this.location.back()
    this.router.navigate(['../dashboard'],
      {
        relativeTo: this.activatedRoute
      }
    )
  }

  onImageUpload(response: ImageUploaderResponseModel) {
    if (response.error) {
      this.imgErrorMsg = response.errorMessage
      return
    }

    this.imageUrls.push(new UntypedFormControl(response.url))
  }

  handleMobilePhotoInput() {
    // navigator.camera.getPicture((image: any) => {
    //   const imageName = 'IMG.jpeg';
    //   const imageBlob = this.dataURItoBlob(image);
    //   const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });

    //   const img = new ImageModel();
    //   let base64Image = image
    //   img.imgBase64 = base64Image.replace(/^data:image\/[a-z]+;base64,/, '')

    //   this.mediaService.uploadImage(img).subscribe(upload => {
    //     if (upload) {
    //       // this.addMediaUrl(upload.imgLink, 'image')
    //       this.imageUrls.push(new FormControl(upload.imgLink))
    //     }
    //   });

    //   // this.binDoc = imageFile;
    //   // this.base64Image = 'data:image/jpeg;base64,' + image;
    //   // this.mobileBase64Image = image;

    // }, (error) => {
    //   alert(error);
    // },
    // {
    //   quality: 50,
    //   destinationType: navigator.camera.DestinationType.DATA_URL,
    //   encodingType: navigator.camera.EncodingType.JPEG,
    //   mediaType: navigator.camera.MediaType.PICTURE,
    //   sourceType: navigator.camera.PictureSourceType.PHOTOLIBRARY,
    // }
    // );
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }

  handleFileInput(files: FileList) {
    if (!this.validateImage(files[0].name)) {
      this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';

      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      const img = new ImageModel();
      let base64Image = reader.result as string;
      img.imgBase64 = base64Image.replace(/^data:image\/[a-z]+;base64,/, '')
      this.imageUploading = true
      this.uploadingMedia = true

      this.mediaService.uploadImage(img).subscribe(upload => {
        this.imageUploading = false
        this.uploadingMedia = false

        if (upload) {
          // this.addMediaUrl(upload.imgLink, 'image')
          this.imageUrls.push(new UntypedFormControl(upload.imgLink))
        }
      });
    };
  }

  handleVideoInput(files: FileList) {
    let video = files[0];
    this.videoUploading = true
    this.uploadingMedia = true

    this.mediaService.uploadVideo(video).subscribe(x => {
      this.videoUploading = false
      this.uploadingMedia = false

      if (x) {
        // this.addMediaUrl(x.imgLink, 'video')
        this.videoUrls.push(new UntypedFormControl(x.imgLink))

      }

    });
  }



  // addMediaUrl(upload, type) {
  //   this.mediaUrls.push(this.fb.group({
  //     url: upload,
  //     type: type
  //   }))
  // }

  private validateImage(name: any) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() === 'png') {
      return true;
    } else if (ext.toLowerCase() === 'jpg') {
      return true;
    } else if (ext.toLowerCase() === 'jpeg') {
      return true;
    } else {
      return false;
    }

  }
  removeImage(index) {
    this.imageUrls.removeAt(index)
    // this.mediaUrls.removeAt(index)
  }

  removeVideo(index) {
    this.videoUrls.removeAt(index)
  }

  pickedPlatforms(platforms) {
    return platforms.reduce((res, p) => {
      if (p.value) res.push(p.platform)
      return res
    }, [])
  }

  openPreviewModal() {
    this.previewModalOpen = true;
    let platform = this.pickedPlatforms(this.platforms.value)[0]
    this.previewPlatform(platform)
  }


  previewPlatform(p) {
    this.previewData = this.postForm.value
    this.previewData['platform'] = p
    this.previewData['user'] = this.userProfile.displayNames.filter(u => u.platform == p)[0]

  }

  addMediaUrls(model, values) {
    model.mediaUrls = values.imageUrls.concat(values.videoUrls)
    model.mediaUrls.length <= 0 ? delete model.mediaUrls : model.mediaUrls
    delete values.imageUrls
    delete values.videoUrls

    return model
  }

  addPlatformOptions(model, values) {
    if (values.platforms.includes('reddit')) {
      model['redditOptions'] = {
        "title": values.post,     // required
        "subreddit": "test",              // required (no "/r/" needed)
      }
    }

    if (values.platforms.includes('youtube')) {
      model['youTubeOptions'] = {
        "title": values.post,     // required
        // "youTubeVisibility": "public",
        "youTubeVisibility": values.youtubeVisibility,

      }
    }

    if (values.platforms.includes('instagram') && values.imageUrls.length > 0) {
      model['instagramOptions'] = {
        "autoResize": true
      }
    }

    return model
  }


  submitPost(values) {
    values.platforms = this.pickedPlatforms(values.platforms)


    let model = new PostModel()
    this.addPlatformOptions(model, values)

    if (values.schedule == "Schedule") {
      values.scheduleDate = new Date(values.scheduleDate + ' ' + values.scheduleTime).toISOString()
    } else {
      delete values.scheduleDate
    }
    // if (this.sharePost) {
    this.socialMediaCount++;
    // this.adminCounter.socialMediaCount = this.socialMediaCount;
    // this.counter.updateCounter(this.adminCounter);

    // }

    this.addMediaUrls(model, values)
    Object.keys(values).forEach(key => {

      model[key] = values[key]
    })



    model.profileKeys.push(this.profileKey)

    this.sendingPost = true
    this.socialMediaService.sendPost(model).toPromise().then(message => {
      this.sendingPost = false
      this.sendPushNotificationToOwner(model.platforms)

      this.toast({ html: 'Posted Successfully', classes: 'green', type: 'success' });
      setTimeout(() => {
        this.router.navigate(['../posts'],
          {
            relativeTo: this.activatedRoute
          }
        )
      }, 2000);


    }).catch(message => {
      this.sendingPost = false
      message["error"]["posts"].forEach(post => {
        post.errors.forEach(error => {
          this.toast({ html: `${error.platform ? error.platform : ''} : ${[170, 260].includes(error.code) ? error.message.split('.')[0] : error.message}`, classes: 'red', type: 'error' });
        })
      })
    })
  }

  toast(obj) {

    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green';
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
    }, 3000);
  }


  sendPushNotificationToOwner(platforms) {
    if (this.notifSettings && this.currentUser && this.currentUser.role === "Employee") {
      let createdByFullName = this.currentUser.GivenName + ' ' + this.currentUser.LastName
      let notification = new NotificationModel()
      notification.title = 'Employee Created a Social Media Post'
      notification.body = `${createdByFullName} created a new post on ${platforms.join()}.`
      this.notificationPreferenceService.sendNotificationForGroupOfUsers(notification, ['Admin'], 'socialMedias');
    }
  }
}