<div class="flex flex-col lg:p-12  md:p-6 space-y-6">
    <ng-container *ngIf="cart && cart.items.length >0 ">
        <!-- header -->
        <div class="hidden md:flex justify-between   px-12 md:w-2/3 ">
            <p class="font-bold text-sm text-darkGray w-3/4 ">Product</p>
            <div class="flex  justify-evenly w-full  ">
                <p class="font-bold text-sm text-darkGray md:w-1/2">Quantity</p>
                <div class="flex w-2/5 justify-between ">
                    <p class="font-bold text-sm text-darkGray md:w-1/6">Price</p>
                    <p class="font-bold text-sm text-darkGray md:w-2/5">Total Price</p>
                </div>

            </div>
        </div>
        <!-- product list and order summery -->
        <div class=" flex md:flex-row flex-col lg:space-x-16 md:space-x-10">

            <!-- product list -->
            <div class="w-full px-4 md:w-8/12" *ngIf="cart">
                <div class=""  *ngFor="let item of cart.items let i=index">
                    <app-cart-item [item]="item" [index] = "i" (onItemDelete)="deleteItem($event)"
                    (onItemUpdate)="updateItem($event)"
                    ></app-cart-item>
                    <!-- qwewqe -->
                </div>
            </div>


            <div class="flex  md:flex-col md:w-3/12">
                
                <!-- order summary -->
                <div *ngIf="cart"
                    class="flex fixed md:static  w-full drop-shadow-[6px_0_6px_rgba(0,0,0,0.25)]  md:flex-col space-x-4 md:space-x-0  bg-customTeal md:rounded-md  p-6 md:space-y-4 bottom-0 overflow-y-scroll overflow-x-hidden md:overflow-hidden">
                    <p class="text-lg text-white font-bold hidden md:block ">Order Summary</p>
                    <hr class="text-white hidden md:block">
                    <div class="flex md:flex-row flex-col border-r-2 md:border-r-0 pr-4  md:justify-between">
                        <p class="md:font-bold text-xs md:text-base text-white">Items</p>
                        <p class=" font-bold text-white text-base md:text-lg">{{getCount()}}</p>
                    </div>
                    <div class="flex md:flex-row flex-col pl-2 md:pl-0 md:justify-between">
                        <p class="md:font-bold text-xs md:text-base text-white">Subtotal</p>
                        <p class="font-bold text-white text-base md:text-lg">{{getSubTotal() | currency}}</p>
                    </div>
                    <div class="flex w-full md:pt-6 justify-center">
                        <button (click)="checkout()"
                            class=" flex items-center space-x-2 justify-center px-4 md:px-6 py-2 bg-white text-Black rounded-lg w-max h-min text-sm md:text-base font-bold hover:bg-darkBlue focus:outline-none  ">
                            <i class="fa-solid fa-basket-shopping block"></i>
                            <span>Checkout</span>
                        </button>
                    </div>
                </div>


                <!-- what we accept -->
                <div class="pt-12 px-4 ">
                    <p class="text-xs md:text-base font-bold">We accept</p>
                    <div class="flex flex-wrap w-full gap-4 pt-2">
                        <div class="avatar">
                            <div class="w-14 rounded">
                                <img
                                loading="lazy"

                                data-src="https://api.goingmobile.app/media/personal-app-assets/img/webp/mastercard.webp"
                                                            
                                srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/mastercard.webp"
                                                            
                                fallback="https://api.goingmobile.app/media/personal-app-assets/img/jpg/mastercard.png"
                                                            
                                alt="master card logo."> 
                            </div>
                        </div>

                        <div class="avatar">
                            <div class="w-14 rounded">
                                <img
                                loading="lazy"

                                data-src="https://api.goingmobile.app/media/personal-app-assets/img/webp/Visa-logo-1.webp"
                                                            
                                srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/Visa-logo-1.webp"
                                                            
                                fallback="https://api.goingmobile.app/media/personal-app-assets/img/jpg/Visa-logo-1.jpg"
                                                            
                                alt="visa."> 
                            </div>
                        </div>

                        <div class="avatar">
                            <div class="w-14 rounded">
                                <img
                                loading="lazy"

                                src="https://api.goingmobile.app/media/personal-app-assets/img/webp/paypal.webp"
                                                            
                                srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/paypal.webp"
                                                            
                                fallback="https://api.goingmobile.app/media/personal-app-assets/img/jpg/paypal.jpg"
                                                            
                                alt="paypal."> 
                            </div>
                        </div>


                    </div>
                </div>

            </div>



        </div>
    </ng-container>

    <ng-container *ngIf="!cart || cart.items.length == 0 ">
        <div class="mt-5">
            <div class="flex items-center justify-center">
                <img loading="lazy" data-src="../../../../assets/icons/Illustrations/notification Illustration.png"
                    class="block w-64 flex items-center justify-center">
            </div>
            <div class="text-customTeal text-lg text-center font-bold">You're all caught up

            </div>
            <div class="text-gray-400 text-sm text-center font-bold">You have no items in the cart!

            </div>
        </div>
    </ng-container>
</div>

<!-- Delete Modal -->
<div class="modal"  id="cancelModal" *ngIf="deleteModal"
[ngClass]="{'modal-open': deleteModal, 'modal-action': !deleteModal }">
  <div class="modal-box">
    <h3 class="font-bold text-lg text-center mb-2">Remove product</h3>
    <p class="py-4"> Are you sure you want to remove product?</p>

    <div class="modal-action items-center justify-between">
      <label (click)="deleteModal=false"
        class="py-2 px-4 bg-customTeal text-white border-customTeal rounded-md">Cancel</label>
      <label ><button class="font-bold text-red-500 hover:bg-red-500 hover:text-white hover:rounded-md hover:font-normal border-none hover:p-2"
        type="button" (click)="deleteProduct()">Yes, remove</button></label>
    </div>
  </div>
</div>
