<div class="flex flex-col-reverse mx-auto md:space-y-0 md:flex-row ">

    <!-- Signup input -->
    <div class="flex flex-col items-center justify-center w-full p-4 space-y-2 md:p-12 ">
  
      <div class="flex justify-between w-full">
        <button class="flex items-center space-x-2 ">
          <div [routerLink]="['/']">
            <i class="material-icons">keyboard_arrow_left</i>
          </div>
          <div [routerLink]="['/']" class="">
            <h4 class="text-sm font-bold ">
              Homepage
            </h4>
          </div>
        </button>
        <img loading="lazy" [routerLink]="['/']" *ngIf="appLogo" [src]="appLogo" class="justify-center block w-32 cursor-pointer "
          alt="Logo ">
      </div>
  
      <div class="w-full px-4 py-6 bg-white border border-gray-100 border-solid rounded-md shadow-md md:py-12 md:px-16 md:max-w-max">
        <p class="text-xl font-bold text-center md:text-2xl ">Create an account</p>
      <p class="text-base text-center text-gray-500 md:pt-1 " *ngIf="model">{{model.welcomeMessage}}
      </p>
      <hr class="w-full mt-4  border-t-1 border-lightGray">
  
      
      <form [formGroup]="registrationForm" (ngSubmit)="register(registrationForm.value)" class="pt-8">
     
          <div class="space-y-3  md:space-y-4">
            <div class="flex flex-col space-y-3 md:flex-row gap-x-8 md:space-y-0">
              <div class="flex flex-col space-y-2 md:w-96">
                <label for=" " class="text-sm font-bold text-gray-500 "> First Name <span class="text-red-400">*</span> </label>
                <input formControlName='firstName'  [ngModel]="inputvalue" (ngModelChange)="changeToSentenceCase($event)"  
                  class="px-3 py-3 leading-tight text-gray-700 border rounded  focus:outline-gray-200 focus:shadow-outline"
                  id="Email " type="text " placeholder="Jane " required
                  [ngClass]="{
                    'border-red-500': ((firstName.dirty || firstName.touched) && firstName.invalid),
                    'border-gray-200': !((firstName.dirty || firstName.touched) && firstName.invalid)
                  }"
                  >
                <div *ngIf="(firstName.dirty || firstName.touched) && firstName.invalid">
                  <small *ngIf="firstName.errors.required" class="italic text-red-500 text-error"> The First Name is required. </small>
                </div>
              </div>
              <div class="flex flex-col space-y-2 md:w-96 ">
                <label for=" " class="text-sm font-bold text-gray-500 "> Last Name <span class="text-red-400">*</span></label>
                <input formControlName='lastName' [ngModel]="inputLastvalue" (ngModelChange)="changeToSentenceCaseLast($event) "
                  class="px-3 py-3 leading-tight text-gray-700 border rounded  focus:outline-gray-200 focus:shadow-outline"
                  id="Email " type="text " placeholder="John " required
                  [ngClass]="{
                    'border-red-500': ((lastName.dirty || lastName.touched) && lastName.invalid),
                    'border-gray-200': !((lastName.dirty || lastName.touched) && lastName.invalid)
                  }"
                  >
    
                <div *ngIf="(lastName.dirty || lastName.touched) && lastName.invalid">
                  <small *ngIf="lastName.errors.required" class="italic text-red-500 text-error"> The Last Name is required. </small>
                </div>
              </div>
            </div>
           <div class="flex flex-col space-y-3 md:flex-row gap-x-8 md:space-y-0 ">
            <div class="flex flex-col space-y-2 md:w-96 ">
              <label for=" " class="text-sm font-bold text-gray-500 "> Email <span class="text-red-400">*</span></label>
              <!-- <input  formControlName='email' class="px-3 py-3 leading-tight text-gray-700 border rounded  focus:outline-gray-200 focus:shadow-outline" id="Email " type="text " placeholder="JohnSmith@gmail.com " required> -->
              
              <!-- <span class="block text-sm font-medium text-slate-700">Email</span> -->
              <input type="email" formControlName="email"
                class="px-3 py-3 leading-tight text-gray-700 border rounded peer focus:outline-gray-200 focus:shadow-outline"
                id="Email" type="email" placeholder="JaneJohn@gmail.com" 
                [ngClass]="{
                  'border-red-500': ((email.dirty || email.touched) && email.invalid),
                  'border-gray-200': !((email.dirty || email.touched) && email.invalid)
                }"
                />
                <div *ngIf="(email.dirty || email.touched) && email.invalid">
                  <small *ngIf="email.errors.required" class="italic text-red-500 text-error"> The Email is required. </small>
                  <small *ngIf="email.errors.email" class="italic text-red-500 text-error"> Wrong email format. </small>
                </div>
              <!-- <p class="invisible text-sm text-pink-600 peer-invalid:visible">
                Please provide a valid email address.
              </p> -->
              
            </div>
            <div class="flex flex-col space-y-2 md:w-96 ">
              <label for=" " class="text-sm font-bold text-gray-500 "> Phone Number</label>
              <!-- <input formControlName='cellPhone'
                class="px-3 py-3 leading-tight text-gray-700 border rounded  focus:outline-gray-200 focus:shadow-outline"
                id="cellPhone " type="text " placeholder="000-0000-0000"> -->
              <input type="text" formControlName='cellPhone'  id='cellPhone'
                ng2TelInput
                class="w-full px-3 py-3 leading-tight text-gray-700 border rounded  focus:outline-gray-200 focus:shadow-outline" />
  
              <!-- <div *ngIf="(cellPhone.dirty || cellPhone.touched) && cellPhone.invalid">
                  <small *ngIf="(cellPhone.errors.minlength) && !cellPhone.errors.pattern" class="text-red-500 text-error"> Phone number must be at least 10 numbers
                    </small>
                    <small *ngIf="cellPhone.errors.pattern " class="text-red-500 text-error"> Wrong Format
                    </small>
                </div> -->
  
            </div>
           </div>
            <div class="flex flex-col space-y-3 md:flex-row gap-x-8 md:space-y-0">
             
              <div class="flex flex-col space-y-2 md:w-96">
                <label for=" " class="text-sm font-bold text-gray-500 "> Password <span class="text-red-400">*</span></label>
              <div class="relative w-full text-gray-600">
                <input #passwordfield id="Password" type="password" formControlName='createPassword' required
                  autocomplete="off"
                  class="w-full rounded-md input input-bordered focus:outline-1 focus:outline-brandColor "
                  id="Password " placeholder="Password "
                  [ngClass]="{
                    'border-red-500': ((createPassword.dirty || createPassword.touched) && createPassword.invalid),
                    'border-gray-200': !((createPassword.dirty || createPassword.touched) && createPassword.invalid)
                  }"
                  >
              
  
  
                <span class="absolute inset-y-0 right-0 flex items-center pr-3">
                  <button class="p-1 focus:outline-none focus:shadow-outline">
                    <span (click)="passwordfield.type=passwordfield.type=='password'?'text':'password'"
                    class="fa fa-fw field-icon toggle-password"
                    [ngClass]="(passwordfield.type=='password')?' fa-eye-slash':' fa-eye'">
                  </span>               
                  </button>
                </span>
              </div>
  
              <div *ngIf="(createPassword.dirty || createPassword.touched) && createPassword.invalid">
                <small *ngIf="createPassword.errors.required" class="italic text-red-500 text-error"> The Password Is Required.
                </small>
                <small *ngIf="createPassword.errors.minlength" class="italic text-red-500 text-error" > Password Length Must Be At Least 6
                  Characters . </small>
                <small *ngIf="createPassword.errors.maxlength" class="italic text-red-500 text-error"> Password Must Not Exceed 25
                  Characters . </small>
              </div>
   
            </div>
           
                <div class="flex flex-col space-y-2 md:w-96">
                  <label for=" " class="text-sm font-bold text-gray-500 "> Confirm Password <span class="text-red-400">*</span></label>
                <div class="relative w-full text-gray-600">
                  <input #passwordfield2 id="Password" type="password" formControlName='confirmPassword' required
                    autocomplete="off"
                    class="w-full rounded-md input input-bordered focus:outline-1 focus:outline-brandColor "
                    id="Password " placeholder="Confirm Password "
                    [ngClass]="{
                      'border-red-500': ((confirmPassword.dirty || confirmPassword.touched) && confirmPassword.invalid),
                      'border-gray-200': !((confirmPassword.dirty || confirmPassword.touched) && confirmPassword.invalid)
                    }">
                  <!-- <button class="flex justify-end rounded-md" (click)='showHidePassword()'> {{ showPassword ? 'Hide' : 'Show' }}</button> -->
  
     
  
                  <span class="absolute inset-y-0 right-0 flex items-center pr-3">
                    <button class="p-1 focus:outline-none focus:shadow-outline">
                      <span (click)="passwordfield2.type=passwordfield2.type=='password'?'text':'password'"
                      class=" fa fa-fw field-icon toggle-password"
                      [ngClass]="(passwordfield2.type=='password')?' fa-eye-slash':' fa-eye'">
                    </span>                
                    </button>
                  </span>
                </div>
                <div *ngIf="(confirmPassword.dirty || confirmPassword.touched) && confirmPassword.invalid">
                  <small *ngIf="confirmPassword.errors.required" class="italic text-red-500 text-error">Confirm Password is required. </small>
                  <small *ngIf="confirmPassword.hasError('invalid')" class="italic text-red-500 text-error">Passwords Doesnt Match ! </small>
                </div>
                </div>
    
            </div>
  
            <div class="flex flex-col space-y-4">
              <span class="max-w-screen-md text-sm text-gray-500"> <input type="checkbox" class="float-left w-4 h-4 mr-2 rounded-sm checkbox checkbox-primary"
                formControlName="privacyPolicy" required /> By clicking Register, I agree to <a [routerLink]="['/terms-conditions']"
                class="underline-offset-2 text-customTeal">Terms of Service </a>, <a [routerLink]="['/privacy-policy']"
                class="underline-offset-2 text-customTeal">Privacy Policy</a>, and <a
                [routerLink]="['/enduser-agreement']" class="underline-offset-2 text-customTeal">End-User License
                Agreement</a>.</span>
            <div *ngIf="(privacyPolicy.dirty || privacyPolicy.touched) && privacyPolicy.invalid">
              <small *ngIf="privacyPolicy.errors.required" class="text-red-500 text-error">This section is required you need to agree
                to the terms and conditions and privacy policy to proceed.. </small>
            </div>
  
            <button  [disabled]="!registrationForm.valid" class="w-full px-6 py-2 mx-auto font-bold text-white border-2 rounded-lg bg-customTeal md:w-96 focus:outline-none"   [ngClass]="{'bg-customTeal': registrationForm.valid, 'bg-gray-400': !registrationForm.valid}">
              Register
            </button>
            </div>
          </div>
   
      </form>
  
      <p class="pt-3 text-base text-center">Already have an account? <button type="button " [routerLink]="['/login']"
          class="inline-block text-base font-bold underline text-customTeal hover:text-darkBlue ">Login</button>
      </p>
      </div>
    </div>
  </div>
  
  <!-- toast -->
  <div class="fixed top-0 right-0 shadow-lg alert alert-success "
    [ngClass]="{'visible': openToast, 'invisible': !openToast,'alert-success': toastType=='success', 'alert-error': toastType=='failure' }">
    <div class="font-bold text-white">
      <span class="material-icons">check_circle</span>
      <span>{{toastMessage}}</span>
    </div>
  </div>
  
