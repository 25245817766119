import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.css']
})
export class ListItemComponent implements OnInit {

  @Input() filled
  @Input() pageName
  @Input() name
  @Input() idTag
  @Input() isActivePage

  @Output() pageSetup = new EventEmitter<string>();

  modalTarget = {
      appSetup: 'appSetupModal',
      homePage: 'rightTopModal',
      employee: 'organizationModal', 
      appointment: 'organizationModal', 
      about: 'aboutModal',
      service: 'serviceModal',
      product: 'productModal',
      event: 'eventModal',
      blog: 'blogModal', 
      audience: 'audienceModal', 
      socialMediaPage: 'socialMediaIntegrationModal', 
      custom: 'customPageModal'
  }

  openModal 
  currentStep: any;
  allSteps = [
    {
      originalName: 'appSetup',
      name: 'Quick guide',
      quickGuide: [3,4]

    },

    {name: 'General Setup',originalName: 'Blogs',},
    {name: 'Brand Setup',originalName: 'Services',}]

  constructor() { }

  ngOnInit(): void {
   

  }

  setup(name){
    this.pageSetup.emit(this.name)
    // this.openModal = '#'+ this.modalTarget[name]
  }
}
