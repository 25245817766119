import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { GlobalConstants } from 'src/app/models/GlobalConstants';
import { Location } from '@angular/common';
import { PermissionService } from 'src/app/services/firebase/permission.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer-setting',
  templateUrl: './footer-setting.component.html',
  styleUrls: ['./footer-setting.component.css']
})
export class FooterSettingComponent implements OnInit {
  @Input() calledFromEmptyDashboard:string;
  @Output() closeCollapse = new EventEmitter<string>();
  @Output() footerCreated = new EventEmitter<string>();


  pageSections: any;
  footerRef: any;
  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  public phoneNumberMask = GlobalConstants.PhoneNumberMask;
  public postalCodeMask = GlobalConstants.PostalCodeMask;
  editModalOpen = false;
  @Output() backClicked = new EventEmitter();
  @Output() skip = new EventEmitter();
  currentUser: any;
  permission: any;
  perm: any;
  permSocial:any;
  hasAccess = false;
  previewFooter = false;
  contactsForm: UntypedFormGroup;

  constructor(private settingsService: AppSettingsService, private location: Location,
    private permissionService: PermissionService,
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private router: Router
    ) { }
    selectedCountry = { code: '+1', flag: 'https://flagcdn.com/w40/ca.png', name: 'Canada' };

    countries = [
      { code: '+1', flag: 'https://flagcdn.com/w40/ca.png', name: 'Canada' },
      { code: '+1', flag: 'https://flagcdn.com/w40/us.png', name: 'USA' },
      // Add more countries
    ];
  
    selectCountry(country: any) {
      this.selectedCountry = country;
    }
  ngOnInit(): void {
    this.currentUser = this.authService.getGlobalUser();
    if(this.currentUser.role=='Admin') this.hasAccess = true;

    this.contactsForm = this.fb.group({
      name: ['', Validators.required],
      address: [''],
      postalCode: ['', Validators.pattern('^[A-Za-z]\\d[A-Za-z]\\s?\\d[A-Za-z]\\d$')],
      city: [''],
      country: [''],
      province: [''],
      contactEmail: [''],
      phone: ['', Validators.pattern(/^\d{10}$/)],
      fax: ['', Validators.pattern('^((\\+1)?[-.\\s]?)?\\(?\\d{3}\\)?[-.\\s]?\\d{3}[-.\\s]?\\d{4}$')],
    });
    this.settingsService.getAppSettingsList().valueChanges().subscribe(
      (sliderSettings: any) => {
        if (sliderSettings) {
          if (sliderSettings.length > 0) {
            this.pageSections = sliderSettings[0];
            const footerSection = sliderSettings[0].appSections.filter(s => s.originalName === 'Footer');
            if (footerSection && footerSection.length > 0){
              this.footerRef = footerSection[0];
              this.contactsForm.patchValue(this.footerRef);
            }
          }
        }
      })
      this.permissionService.getPermissionByEmployeeId(this.currentUser.uid).valueChanges().subscribe(permission => {
        if (permission && permission.length > 0){
          this.perm = permission[0]
          this.permSocial = this.perm.permission[3];
          if(this.permSocial.linkAccount) this.hasAccess = true
          
        }
      });
  }
  get name(){return this.contactsForm.get('name')}
  get postalCode(){return this.contactsForm.get('postalCode')}
  get phone(){return this.contactsForm.get('phone')}
  get fax(){return this.contactsForm.get('fax')}
  
  backBtnClicked(){
    if(this.calledFromEmptyDashboard){
      this.backClicked.emit();
    }else{
      this.router.navigate(['/admin/homepage']);
    }
  
  }

  confirmUpdate(footerRef){
    this.editModalOpen = true;
  }
  saveChanges() {
    this.editModalOpen = false;
    this.pageSections.appSections.forEach(pSection => {
      if (pSection.originalName === 'Footer'){
        pSection.isEnabled = true;
      }
      if (pSection.originalName === 'Contact Us'){
        pSection.isEnabled = true;
      }
    });
    if (this.pageSections) {
      this.pageSections.appSections.forEach(section => {
        if(section.originalName == 'Footer'){
          section.name = this.contactsForm.value.name;
          section.address = this.contactsForm.value.address;
          section.postalCode = this.contactsForm.value.postalCode;
          section.city = this.contactsForm.value.city;
          section.country = this.contactsForm.value.country;
          section.province = this.contactsForm.value.province;
          section.contactEmail = this.contactsForm.value.contactEmail;
          section.phone = this.contactsForm.value.phone;
          section.fax = this.contactsForm.value.fax;
          section.isEnabled = true;
        }
      });
      this.settingsService.updateAppSettings(this.pageSections).then(x => {
        this.toast({ html: 'Changes Saved Successfully.', classes: 'green' });
        if(this.calledFromEmptyDashboard == 'Footer'){
          this.closeCollapseFromEmptydashboard()
          this.footerCreated.emit('footerCreated');
        }else{
          this.backBtnClicked()
        }
      });
    }
  }


  closeCollapseFromEmptydashboard(){
    this.closeCollapse.emit('contactInfo');


  }
  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
      this.backBtnClicked();
    }, 2000);
  }
  previewChanges(){
    this.previewFooter = true;
  }
  backToEdit(){
    this.previewFooter = false;
  }
  
  skipSetup(){
    this.skip.emit();
  }
  closeEditModal(){
    this.editModalOpen = false;
  }
}
