import { EmailService } from 'src/app/services/api/email.service';
import { Location } from '@angular/common';
import { InviteService } from '../../../services/firebase/invite.service';
import { RequesttojoinService } from 'src/app/services/firebase/requesttojoin.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { UserService } from '../../../services/firebase/user.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EmailModel, CustomAdminEmailModel } from 'src/app/models/emailModel';
import { PermissionService } from 'src/app/services/firebase/permission.service';
import { SMSModel } from 'src/app/models/SMSModel';
import { SMSService } from 'src/app/services/api/sms.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-registration-request',
  templateUrl: './registration-request.component.html',
  styleUrls: ['./registration-request.component.css']
})
export class RegistrationRequestComponent implements OnInit {
  declinedForm: UntypedFormGroup;
  contactForm : UntypedFormGroup;

  pendingRequests = [];
  pendingFriendRequests = [];
  declinedRequests = [];
  declinedRequestCopy = [];
  pendingFriendRequestCopy = [];
  currentUser: any;
  host = window.location.hostname !== 'localhost' ? 'https://' + window.location.hostname + '/register-from-invite/' : 'http://localhost:4200/register-from-invite/';

  isPendingClicked = true;
  acceptedClicked = false;
  declinedClicked = false;

  currentIndex: any;
  declineOpenModal = false;
  acceptOpenModal = false;
  count = 0;
  inviteGlobal = [];

  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  storeEmail = [];
  detailOpenModal = false;
  detailPendingOpenModal = false;

  declinedName: any;
  declinedCellPhone: any;
  declinedEmail: any;
  declinedStatus: any;
  declinedUsertype: any;
  declinedMessage: any;
  declinedDate: any;

  invitationFirstName: any;
  invitationLastName: any;
  invitationCellPhone: any;
  invitationEmail: any;
  invitationUserType: any;
  invitationId: any;
  deleteInviteModal = false;


  declinedRequestDetail: any;
  activePendingRequestDetail: any;

  openContactModal:boolean = false;
  isSMS: boolean = false;
  disable: boolean = false;

  //to unsubscribe
  users: any;
  invites: any;
  role : any;
  isAdmin : boolean = false;
  perm : any;
  permission : any;

  globalUser: any;
  isSMSemp: boolean = false;

  //Email
  setUp: any;
  greetings: any;
  signOff: any;
  //messageSMS: any;
  messageForSMS: any;
  email: any;

  //for the Signature
  employee: any;
  invitation: any;
  invitationModal: boolean = false;

  permMember: any;
  permPES: any;
  permAnalytics:any;
  permSocial:any;
  permSpecial:any;
  availablePhone: any;
  isNationApp = environment.firstNation.Origin == "FNLeaders" ? true : false;

  constructor(private userService: UserService, 
              private authService: AuthService,
              private location: Location,
              private emailService: EmailService,
              private requestToJoinService: RequesttojoinService,
              private inviteService: InviteService,
              private fb: UntypedFormBuilder,
              private permissionService: PermissionService,
              private sMSService: SMSService,
              private appSettingsService: AppSettingsService) { }

  ngOnInit(): void {
    this.currentUser = this.authService.getCurrentUser();
    this.globalUser = this.authService.getGlobalUser();
    

    if(this.globalUser){
      this.role = this.globalUser.role;
      if(this.role == 'Admin'){
        this.isAdmin = true;

        this.appSettingsService.getAppSettingsList().valueChanges().subscribe(setup=>{
          this.setUp = setup[0]
          this.greetings = this.setUp.greetings;
          this.signOff = this.setUp.signOff;
          this.email = this.greetings + ' <br /> <br />  <br /> <br />' + this.signOff;
          this.messageForSMS = this.messageForSMS;
          this.availablePhone = this.setUp.availablePhone ? this.setUp.availablePhone : '';
          this.contactForm.patchValue({
            body: this.email,
            messageSMS : this.messageForSMS,
         });
        });

        this.permissionService.getPermissionByEmployeeId(this.currentUser.uid).valueChanges().subscribe(perm =>{
          if(perm && perm.length != 0){
            this.perm = perm[0];
           //this.permission = this.perm.permission;

            this.permMember = this.perm.permission[0]
            this.permPES = this.perm.permission[1]
            this.permAnalytics = this.perm.permission[2];
            this.permSocial = this.perm.permission[3];
            this.permSpecial = this.perm.permission[4];

          }
        });

        this.inviteService.allInvite().valueChanges().subscribe(invitation => {
          if(invitation && invitation.length != 0){
            this.invitation = invitation;
          }else{
          }
        })

      }else if(this.role == 'Employee'){
        this.userService.getUserByEmail(this.currentUser.Email).valueChanges().subscribe(employee => {
          this.employee = employee[0];
          this.greetings = this.employee.greetings;
          this.signOff = this.employee.signOff;
          this.messageForSMS = this.employee.messageSMS;
          this.email = this.greetings + ' <br /> <br />  <br /> <br />' + this.signOff;
          this.contactForm.patchValue({
            body: this.email,
            messageSMS : this.messageForSMS,
         });

        })
      }
      else{
      this.permissionService.getPermissionByEmployeeId(this.currentUser.uid).valueChanges().subscribe(perm =>{
        if(perm && perm.length != 0){
          this.perm = perm[0];
         this.permission = this.perm.permission[0];
        }
      });
    }
    }

    this.declinedForm = this.fb.group({
      reason: ['', [Validators.required]],
    });

    this.contactForm = this.fb.group({
      subject: [''],
      body: [''],
      message:['']
    });

    this.userService.getPendingAdminRegRequests().valueChanges().subscribe(requests => {
      if (requests.length > 0){
        requests.forEach((request: any) => {
            this.userService.getUserByEmail(request.email).valueChanges().subscribe(user => {
              if (user.length > 0){
                this.pendingRequests.push({user: user[0], request});
              }
            });

        });
      }

    });

    this.requestToJoinService.getPendingRequests().valueChanges().subscribe(req => {
      this.pendingFriendRequests = [];
      this.pendingFriendRequests = req;
      this.pendingFriendRequestCopy = [...this.pendingFriendRequests];

    });
    this.requestToJoinService.getDeclinedRequests().valueChanges().subscribe((declinedReq) => {
      this.declinedRequests = declinedReq;
      this.declinedRequestCopy = [...this.declinedRequests];
    });
  }

  accept(request: any){
    request.user.isAdminApproved = true;
    request.user.status = 'Accepted';
    
    this.userService.updateUser(request.user).then(() => {
      this.userService.deleteAdminRegRequest(request.request);
    });
    // toast({html: 'Registration request accepted', classes: 'green'});
    this.backClicked();
  }
  reject(request: any){
    request.request.status = 'Declined';
    this.userService.updateAdminRegRequest(request.request);
    // toast({html: 'Registration request declined', classes: 'red'});
    this.backClicked();
  }


  acceptFriendRequest(request: any){
    
    request = this.pendingFriendRequestCopy[this.currentIndex];
    const timestamp = new Date();
    request.status = 'Accepted'
    this.users =  this.userService.getUserByEmail(request.email).valueChanges().subscribe(user => {
      if (user.length === 0){
        this.invites = this.inviteService.getInviteByEmail(request.email).valueChanges().subscribe(invite => {
         if(invite){
          if (invite.length === 0){
            this.inviteService.saveInvite({inviteEmail: request.email, timestamp, userType: request.userType, firstName:request.firstName, lastName:request.lastName, cellPhone: request.cellPhone});
            this.requestToJoinService.deleteRequestByID(request.id);
          }
          let hostname;
          let newUserInviteEmail;
          let appName;
          this.inviteGlobal = invite;
          if (invite.length > 0){
          hostname = `${this.host}${this.inviteGlobal[0].id}`;
          newUserInviteEmail = this.inviteGlobal[0].inviteEmail;
          appName = this.currentUser.GivenName ? this.currentUser.GivenName : '';
          this.invites.unsubscribe();
          this.emailService.sendUserInvite({
            hostname, inviteEmail: newUserInviteEmail, appName, host: this.host, id: this.inviteGlobal[0].id,firstName:request.firstName, lastName:request.lastName, cellPhone: request.cellPhone
          },this.globalUser);
          //this.requestToJoinService.deleteRequestByID(request.id);
         } 
         this.toast({ html: 'Invitation email sent Successfully', classes: 'green', type: 'success' }); 
        }   
          });
      } else {
        this.toast({html: `${request.GivenName} is already registered.`, classes: 'red', type: 'Failure'});
        this.toast({ html: 'This User exists', classes: 'red', type: 'faliure' });
        // this.requestToJoinService.deleteRequestByID(request.id);
        this.backClicked();
      }
      this.users.unsubscribe();
    });
    this.closeAcceptModal();

  }

  rejectFriendRequest(reasons, request: any){
    request[this.currentIndex].status = 'Declined';
    request[this.currentIndex].declinedMessage = reasons.reason;
    let emailModel = new CustomAdminEmailModel();
    let subject = 'The reason your registration request is declined';
    emailModel.subject = subject;
    emailModel.isNationApp = this.isNationApp;

    emailModel.body = reasons.reason;
    emailModel.body += '<p>Thank you.</p>'
    let email: string[] = [];
    email.push(request[this.currentIndex].email);
    emailModel.emailList = email;
    // custom send email used
    emailModel.host = "smtp.gmail.com",
    emailModel.adminEmail = "mkproject.app@gmail.com",
    emailModel.appPassword = "ytklyidomvqfmtky",
    this.emailService.sendCustomEmail(emailModel).subscribe((res) => {
      if (res) {
        // this.toast({html:"Email sent", class:'green',type:'Success'});
      }
      else {
      }
    });
    this.closeDeclineModal();
    this.requestToJoinService.updateRequest(request[this.currentIndex]);
    // toast({html: 'Registration request declined', classes: 'red'});





  }
  backClicked(){
    this.location.back();
  }
  // toggleButtons(){
  //   this.isPendingClicked = !this.isPendingClicked;
  // }
  pending(){
    this.isPendingClicked = true;
    this.acceptedClicked = false;
    this.declinedClicked = false;
  }
  declined(){
    this.isPendingClicked = false;
    this.acceptedClicked = false;
    this.declinedClicked = true;
  }
  accepted(){
    this.isPendingClicked = false;
    this.acceptedClicked = true;
    this.declinedClicked = false;
  }

  selectAccept(i){
    // this.currentIndex = i;
    this.acceptOpenModal = true;
    this.closePendingDetailModal();
    this.closeDetailModal();

  }
  selectDecline(i){
    this.currentIndex = i;
    this.closePendingDetailModal();
    this.declineOpenModal = true;


  }
  selectDetail(i){
    this.currentIndex = i;
    this.detailOpenModal = true;
    this.declinedName = this.declinedRequestCopy[i].fullName;
    this.declinedEmail = this.declinedRequestCopy[i].email;
    this.declinedCellPhone = this.declinedRequestCopy[i].cellPhone;
    this.declinedDate = this.declinedRequestCopy[i].requestDate;
    this.declinedUsertype = this.declinedRequestCopy[i].userType;
    this.declinedStatus = this.declinedRequestCopy[i].status;
    this.declinedMessage = this.declinedRequestCopy[i].declinedMessage;

  }
  selectPendingDetail(i){
    this.currentIndex = i;
    this.detailPendingOpenModal = true;
    this.activePendingRequestDetail = this.pendingFriendRequestCopy[i];
  }
  openModal(){
    this.openContactModal = true;
    this.closeDetailModal();
  }
  closeModal(){
    this.openContactModal = false;
  }
  closeDetailModal(){
    this.detailOpenModal = false;
  }
  openDeclineModal(){
    this.closePendingDetailModal();
    this.declineOpenModal = true;
  }
  closeDeclineModal(){
    this.declineOpenModal = false;
  }
  openAcceptModal(){
    this.closePendingDetailModal();
    this.acceptOpenModal = true;
  }
  closeAcceptModal(){
    this.acceptOpenModal = false;
  }
  closePendingDetailModal(){
    this.detailPendingOpenModal = false;
  }
  sendClient(models) {
    if (!this.isSMSemp) {
      const emailModel = new EmailModel();
      emailModel.subject = models.subject;
      emailModel.body = models.body;
      // emailModel.body += '<br/> <br/> <br/> <span style="color:blue; margin-top: 10px;"> </span> ' + this.globalUser.GivenName + " " + this.globalUser.LastName + ' <br/> ';
      // if(this.currentUser.jobTitle){
      //   emailModel.body += '<span style="color:blue;"> </span> ' + this.globalUser.jobTitle + ' <br/> ';
      // }
      // emailModel.body += '<span style="color:blue;"> </span> ' + this.globalUser.Email + ' <br/> ';
      // emailModel.body += '<span style="color:blue;"> </span> ' + this.globalUser.cellPhone + ' <br/> ';

      this.storeEmail = [];
      this.storeEmail.push(this.declinedEmail);

      emailModel.emailList = this.storeEmail;
      this.closeModal();
      this.emailService.sendEmail(emailModel).subscribe((res) => {
        if (res) {
        }
        else {
        }
      })
    }
    if(this.declinedCellPhone == ""){
      this.disable = true;
    }
    if (this.isSMS && !this.disable) {
      const smsModel = new SMSModel();
      smsModel.body = models.message;
      smsModel.cell = this.declinedCellPhone;
      smsModel.from = this.availablePhone;
      this.closeModal();
      this.sMSService.sendSMS(smsModel).subscribe((res) => {
        if (res) {
          // toast({html: res, classes: 'green'});
        }
        else {
          // toast({html: 'Error sending SMS', classes: 'red'});
        }
      });
    }
  }
  selectInvitationDetail(i){
    // this.invitation[i];
    this.currentIndex = i;
    this.invitationId = this.invitation[i].id;
    this.invitationFirstName = this.invitation[i].firstName;
    this.invitationLastName = this.invitation[i].lastName;
    this.invitationCellPhone = this.invitation[i].cellPhone;
    this.invitationEmail = this.invitation[i].inviteEmail;
    this.invitationUserType = this.invitation[i].userType;
    this.openInvitation();

  }
  openInvitation(){
    this.invitationModal = true;
  }
  closeInvitation(){
    this.invitationModal = false;
  }

  inviteSubscriber(){
    let hostname;
    // let newUserInviteEmail;
    let appName;
    //this.inviteGlobal = invite;
    if (this.invitation.length > 0){
    hostname = `${this.host}${this.invitationId}`;
    // newUserInviteEmail = this.invitation.inviteEmail;
    appName = this.currentUser.GivenName ? this.currentUser.GivenName : '';
    this.emailService.sendUserInvite({
      hostname, inviteEmail: this.invitationEmail, appName, host: this.host, id: this.invitationId,firstName:this.invitation.firstName, lastName:this.invitation.lastName, cellPhone: this.invitation.cellPhone
    },this.globalUser);

   } 
   this.closeInvitation();
  }
  deleteInvitation(){
    this.inviteService.deleteInvite(this.invitationId);
    //this.closeInvitation();
    this.closeDeleteModal();
  }
  openDeleteModal(){
    this.closeInvitation();
    this.deleteInviteModal = true;
  }
  closeDeleteModal(){
    this.deleteInviteModal = false;

  }
  sendReason(reasons, email){
    // send an email to the user
    let subject = 'The reason you are declined';
    const emailModel = new EmailModel();
    emailModel.subject = subject;
    emailModel.body = reasons;
    emailModel.emailList = email;
    // this.Message = subject;
    // this.emailService.sendEmail(emailModel);
    // sendReason(declinedForm.value,pendingfriendRequestCopy[currentIndex].email)
  }
  SMSselected(item){
    this.isSMS = !this.isSMS;
  }

  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
    }, 5000);
  }
  get reason() { return this.declinedForm.get('reason'); }
  get body() {return this.contactForm.get('body')}

}
