
<div>
  <ng-container class="flex flex-col w-full " *ngIf="product">
    <!--Image and product detail -->
    <div class="flex flex-col  px-4  pt-4 md:px-12 py-5 space-y-6 md:h-max  md:space-y-0  w-full">
        <div class="flex flex-col space-y-4  w-full">
            <div class=" bg-white w-full px-4 py-8  md:h-full flex flex-col md:flex-row space-x-4  rounded-md ">
              <div class="md:w-1/2 w-full flex flex-col gap-y-4">
                <div *ngIf="product.imgLink && product.sharedLink" class="flex flex-col space-y-2 ">
                    <a [href]="product.sharedLink" target="_blank">
                        <img loading="lazy" [src]="product.imgLink" class="object-contain  h-96 rounded-md" alt="Image">
                    </a>
                </div>
                <div *ngIf="product.imgLink && !product.sharedLink" class="flex flex-col space-y-2 ">
                    <img loading="lazy" [src]="product.imgLink" class="object-contain w-full h-96 rounded-md" alt="Image">
                </div>
                <div *ngIf="product.pdfLink || product.videoLink" class="">

                    <div class="  h-max  bg-white p-8 space-y-6 rounded-md">
                 
                        <div>
                            <!-- if video -->
                        
                            <video controls autoplay *ngIf="product.videoLink && product.videoLink != ''" class="object-cover" alt="Logo">
                                <source [src]="product.videoLink | safeUrl" >
                                Your browser does not support HTML video.
                            </video>
                        
                            <!-- if PDF -->
                            <div class="mt-4" *ngIf="product.pdfLink && product.pdfLink!=''" >
                            <div *ngIf="product.pdfLink" class="font-bold text-darkGray mb-4">
                                Shared Document
                            </div>
                            <div class=" text-white">
                                <div class="bg-customTeal p-1">
                                <a class="download-icon" [href]="product.pdfLink" target="_blank" download>
                                    <span class="material-icons float-left mr-2">
                                    feed
                                    </span>
                                    <span>Open File</span>
                                </a>
                                </div>
                            </div>
                            </div>
                        </div>
                
                    
                </div>
                
                </div>
              </div>
              <div class="flex flex-col space-y-4 w-full md:w-1/2">
                  <div class=" flex justify-between break-words">
                      <!-- {{product.productName}} -->
                      <div class="w-full break-words">
                        <h2 class="font-bold text-3xl" [innerHTML]="product.productName | noSanitize"></h2>
                      </div>

                      <!-- edit -->
                      <div class="float-right text-base md:text-xl font-bold">
                        <div class="dropdown dropdown-end z-50" *ngIf="isAdmin || (permPES && (permPES.editProducts || permPES.deleteProducts))">
                          <label tabindex="0" class="m-1"><i class="material-icons gray-text-600">more_vert</i></label>
                          <ul tabindex="0" class="dropdown-content menu p-2 shadow bg-white rounded-box w-52 font-normal">
                            <li  (click)="navigateEdit(product)" *ngIf="(isAdmin || (permPES && permPES.editProducts))"><a><i class="material-icons gray-text-600">edit</i>Edit</a></li>
                            <li (click)="openDeleteModal()" *ngIf="(isAdmin || (permPES && permPES.deleteProducts))"><a><i class="material-icons gray-text-600">delete</i>Delete</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    
                
                  
          <div class="flex flex-col space-y-4  md:space-y-8 md:flex-col w-full ">
              <div class="h-max  bg-white p-4 space-y-6 rounded-md md:p-8 " *ngIf="product.price || product.category">
                  <div class=" space-y-4 ">
                      <p class=" text-xs font-bold text-darkGray py-4">
                          {{productsName}} Detail
                      </p>



                      <!-- Price -->
                      <div class="space-y-2" *ngIf="product.price">
                          <p class=" text-xs text-darkGray ">
                              price
                          </p>
                          <div class=" flex flex-row  items-center">
                              <div>
                                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                      <path fill-rule="evenodd" d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
                                  </svg>
                              </div>
                              <div class="pl-4">
                                  <h4 class=" text-base font-bold text-customTeal ">
                                    {{product.price | currency: currency:'symbol-narrow'}}
                                  </h4>
                              </div>
                          </div>
                      </div>

                      <hr>
                      <!-- Location -->
                      <div class="space-y-2">
                          <p class=" text-xs  text-darkGray ">
                              {{categoryName}}
                          </p>
                          <div class=" flex flex-row  items-center">
                              <div *ngIf="product.category">
                                  <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M6.5 11 12 2 17.5 11ZM17.5 22Q15.625 22 14.312 20.688Q13 19.375 13 17.5Q13 15.625 14.312 14.312Q15.625 13 17.5 13Q19.375 13 20.688 14.312Q22 15.625 22 17.5Q22 19.375 20.688 20.688Q19.375 22 17.5 22ZM3 21.5V13.5H11V21.5Z"/></svg>
                              </div>
                              <div class="pl-4">
                                  <h4 class=" text-base font-bold text-customTeal ">
                                      {{product.category}}
                                  </h4>
                              </div>
                          </div>
                      </div>





                  </div>
              </div>

                  <ng-template #fullText>
                    <div class="break-words w-full">
                      <span [innerHTML]="productDescription"></span>
                    </div>
                      <button type="button" class="font-bold text-customTeal" (click)="textLimit= true ">less</button >

                  </ng-template>

                  <div *ngIf="textLimit; else fullText" class="break-words">
                      {{truncateHTML(productDescription, 1000) }}    
                      <button *ngIf="productDescription.length > 1000" type="button" class="font-bold text-customTeal" (click)="textLimit= false ">more</button >
                  </div>
                          
                  </div>
                  <!-- <span [innerHTML]="(product.productDescription | noSanitize) "></span> -->

                  <!-- {{truncateHTML(<span [innerHTML]="(product.productDescription | noSanitize) "></span> | noSanitize, 200) }}   -->
                  <br/>
                
              </div>
            </div>
        </div>

        <div>
          <ng-container *ngIf="product.allProducts">
              <div  class="flex flex-row gap-4 flex-wrap mt-4" *ngIf="product.allProducts.length != 0">
                <div  *ngFor="let productFeature of product.allProducts" class=" flex flex-row flex-wrap gap-4 items-center rounded-md border-2 ">
                  <div class="w-full mb-5">
                    <button 
                    type="button"
                    data-te-toggle="modal"
                    data-te-target="#exampleModalScrollable"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                    (click)="selectedProduct = productFeature"
                    class="flex flex-col bg-white w-[160px]  md:w-[269px] space-y-3 rounded-md p-4 shadow-md">
                    <img loading="lazy" *ngIf="productFeature.imgLink" class="w-full h-28 md:h-44 object-contain" [src]="productFeature.imgLink" alt="">
                    <div class="break-words w-full">
                      <h4 class="" [innerHTML]="productFeature.productTitle"></h4>
                    </div>
                      <p class="font-bold"> {{productFeature.productPrice | currency: currency:'symbol-narrow'}} </p> 
                  </button>
                
                  </div>
              
                </div>
              </div>
          
          </ng-container>
        </div>
    </div>

    <!-- Comment section -->
    <div *ngIf="product.commentable" class="flex flex-col  px-4   md:px-12 py-5 space-y-6 md:h-max  md:space-y-0  w-full">
      <app-post-comment [post]="product"
      [sectionName]="productsName"
      postType="product"
      (showCommentDeletedToast)="openDeleteToast()"
      >
      </app-post-comment>
    </div>
  </ng-container>

</div>


<!-- Modal -->
<div
  data-te-modal-init
  class="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
  id="exampleModalScrollable"
  tabindex="-1"
  aria-labelledby="exampleModalScrollableLabel"
  aria-hidden="true">
  <div
    data-te-modal-dialog-ref
    class="pointer-events-none relative h-[calc(100%-1rem)] w-auto flex justify-end translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:h-[calc(100%-3.5rem)] min-[576px]:max-w-[500px]">
    <div
      class="pointer-events-auto relative flex max-h-[100%]  w-2/3 flex-col overflow-hidden rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
      <div
        class=" flex flex-shrink-0 bg-customTeal items-center justify-between rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
        <!--Modal title-->
        <div class="break-words w-full">
          <h5 *ngIf="selectedProduct"
          class="text-xl font-medium leading-normal text-white"
          id="rightBottomModalLabel" [innerHtml]="selectedProduct.productTitle">
          
        </h5>
        </div>

        <!--Close button-->
        <button
          type="button"
          class="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
          data-te-modal-dismiss
          aria-label="Close">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="h-6 w-6 stroke-white">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>

      <!--Modal body-->
      <div class="relative overflow-y-auto p-4">
        <div *ngIf="selectedProduct" class="flex flex-row space-x-4 pl-6">
          <div class="w-2/5">
              <img loading="lazy" *ngIf="selectedProduct.imgLink" class="w-full   object-cover" [src]="selectedProduct.imgLink" alt="">

          </div>
         <div class="flex flex-col w-3/5 space-y-4 break-words">
          <p class="font-bold" [innerHTML]="selectedProduct.productTitle"></p> 
          <p class="text-xl font-bold">$ {{selectedProduct.productPrice}} </p>
          
          <p class="" [innerHTML]="selectedProduct.productUnderDescription"></p> 
         </div>

      </div>
      </div>

      <!--Modal footer-->
      <div
        class="flex flex-shrink-0 flex-wrap items-center justify-end rounded-b-md border-t-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
        <button
          type="button"
          class="inline-block rounded bg-primary-100 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:bg-primary-accent-100 focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200"
          data-te-modal-dismiss
          data-te-ripple-init
          data-te-ripple-color="light">
          Close
        </button>
        <!-- <button
          type="button"
          class="ml-1 inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          data-te-ripple-init
          data-te-ripple-color="light">
          Save changes
        </button> -->
      </div>
    </div>
  </div>
</div>
<!-- Button trigger modal -->
<button
  type="button"
  class="rounded hidden bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
  data-te-toggle="modal"
  data-te-target="#exampleModal"
  data-te-ripple-init
  data-te-ripple-color="light">
  Launch demo modal
</button>


<!-- Delete Modal -->
<app-delete-confirmation *ngIf="deleteModalOpen" (deleteClicked)=deleteProduct() (cancelDeleteClicked)="deleteModalOpen=false" [deletedFromComponent]=productsName  [deletedFromCategory]=" product.category"></app-delete-confirmation>

<!-- toast -->
<app-alert class="fixed top-2 right-1 flex" [toastType]="toastType" [toastMessage]="toastMessage" [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>
