import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-create-confirmation',
  templateUrl: './create-confirmation.component.html',
  styleUrls: ['./create-confirmation.component.css']
})
export class CreateConfirmationComponent implements OnInit {
  @Input() createdFromComponent;
  @Input() componentName;
  @Output() cancelClicked = new EventEmitter();
  @Output() saveBtnClicked = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }
  cancelCreatingClicked(){
    this.cancelClicked.emit();
  }
  saveChangesClicked(){
    this.saveBtnClicked.emit();
  }

}
