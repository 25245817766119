<ng-container *ngIf="settings && settings.isBusinessApp">

    <div class="flex bg-gray-100" *ngIf="service">
        <!-- side drawer -->
        <div class="bg-white hidden  h-screen justify-center w-24 hover:w-64 sticky md:display ">
            logo
            <ul class="menu bg-base-100 w-24 p-2 rounded-box">
                <li>
                    <a>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                        </svg>
                    </a>
                </li>
                <li>
                    <a>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                        </svg>
                    </a>
                </li>
                <li>
                    <a>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path
                                d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM14 11a1 1 0 011 1v1h1a1 1 0 110 2h-1v1a1 1 0 11-2 0v-1h-1a1 1 0 110-2h1v-1a1 1 0 011-1z" />
                        </svg>
                    </a>
                </li>
                <li>
                    <a>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
                        </svg>
                    </a>
                </li>
                <li>
                    <a>
                        <i class="fa-solid fa-chart-line"></i>
                    </a>
                </li>
                <li>
                    <a>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
                    </a>
                </li>
            </ul>

        </div>
        <!-- profile cards -->
        <div class="px-4 md:px-12 py-5 w-full">
            <div class="flex flex-col space-y-4 md:flex-row md:space-x-6 md:space-y-0">
                <!-- list and service about -->
                <div class=" bg-white w-full px-4 md:px-16 py-8 space-y-6 md:w-8/12 rounded-md ">
                    <!-- Service list -->
                    <p class="text-base md:text-xl font-bold border-b-2 border-customTeal">
                        {{truncateHTML(service.serviceName, 35) | titlecase}}
                    </p>
                    <div class="flex flex-col space-y-2 ">
                        <p class="text-sm font-bold text-gray-400" *ngIf="service.serviceOffers.length !==0">
                            {{servicesName}} Includes
                        </p>

                        <!-- service includes- item -->

                        <div *ngFor="let serviceFeature of service.serviceOffers"
                            class=" flex flex-row  items-center bg-veryLightGray    p-2 rounded-md   border-2 ">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 fill-customTeal  "
                                    viewBox="0 0 20 20">
                                    <path fill-rule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                        clip-rule="evenodd" />
                                </svg>
                            </div>
                            <div class="pl-4">
                                <h4 class="text-sm md:text-base font-bold ">
                                    {{serviceFeature}}
                                </h4>
                            </div>
                        </div>
                    </div>

                    <!-- about service -->
                    <div class="md:pt-8 space-y-2">
                        <p class=" text-sm font-bold text-gray-400 ">
                            About this {{servicesName}}
                        </p>

                        <p class=" text-justify text-sm md:text-base text-black ">
                            {{service.serviceDescription}}
                        </p>
                    </div>

                </div>
                <!-- service detail -->

                <div class="flex flex-col w-full md:w-1/3 md:min-w-min space-y-4 md:space-y-8 md:flex-col">

                    <div class="  h-max  bg-white p-8 space-y-4 rounded-md">
                        <p class="text-md font-normal text-gray-600 ">
                            {{servicesName}} Detail
                        </p>
                        <div class="flex justify-between sm:flex-col sm:space-y-4">

                            <!-- Price -->
                            <div class="space-y-2">
                                <p class=" text-sm font-light text-gray-400">
                                    Price
                                </p>
                                <p>
                                    <button class="btn blue white-text" (click)="AddToCart(serviceId)">Add To Cart
                                    </button>
                                </p>
                                <div class=" flex flex-row  items-center">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20"
                                            fill="currentColor">
                                            <path fill-rule="evenodd"
                                                d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z"
                                                clip-rule="evenodd" />
                                        </svg>
                                    </div>
                                    <div class="pl-2">
                                        <h4 class=" text-base font-bold text-customTeal ">
                                            {{service.price | currency: currency:'symbol-narrow'}}
                                            
                                        </h4>
                                    </div>
                                </div>
                            </div>

                            <!-- Duration -->
                            <div class="space-y-2">
                                <p class=" text-sm font-light text-gray-400">
                                    Duration
                                </p>
                                <div class=" flex flex-row  items-center">
                                    <div>
                                        <i class="fa fa-hourglass"></i>
                                    </div>
                                    <div class="pl-2">
                                        <p class=" text-base font-bold text-customTeal "
                                            *ngIf="service.serviceHour || service.serviceMinute ">
                                            <span *ngIf="service.serviceHour">{{service.serviceHour}}h</span>
                                            <span *ngIf="service.serviceMinute"> {{service.serviceMinute}}m</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- More info -->
                        <div class="space-y-2">
                            <p class=" text-sm font-light text-gray-600 ">
                                More Info
                            </p>

                            <!-- <div class="flex flex-wrap gap-2 ">
                            <div>
                                <img src="https://api.lorem.space/image/face?hash=445566" class="object-cover w-32 h-32"
                                    alt="Logo">
                            </div>
                            <div>
                                <img src="https://api.lorem.space/image/face?hash=33795" class="object-cover w-32 h-32"
                                    alt="Logo">
                            </div>
                        </div> -->

                            <div class="flex flex-wrap gap-2 " *ngIf="service.imgLink != '' || service.videoLink != ''">
                                <img loading="lazy" *ngIf="service.imgLink != ''" [src]="service.imgLink"
                                    class="object-cover w-32 h-28" alt="Logo">
                                <video autoplay *ngIf="service.videoLink != ''" [src]="service.videoLink"
                                    class="object-cover w-32 h-28" alt="Logo"></video>
                            </div>
                        </div>

                        <!--
                    <div class="row">
                        <input id="checkout-button" type="button" value="Buy Now"
                            (click)="openHandler(service.serviceName, service.serviceDescription, service.price);" />
                    </div> -->

                        <!-- <div class="row">
                        <form id="payment-form">
                            <div class="form-row">
                                <label for="card-element">Credit or debit card</label>
                                <div id="card-element"></div>
                                <div id="card-errors" role="alert"></div>
                            </div>
                            <button type="submit">Add payment source</button>
                        </form>
                    </div> -->

                    </div>

                    <!-- employee detail -->
                    <div class="h-max  bg-white p-8  rounded-md  " *ngIf="employees.length > 0">
                        <p class=" text-sm font-light text-gray-500">
                            Employee Detail
                        </p>
                        <div class="flex flex-col space-y-4 md:space-y-0 items-center md:flex-row md:space-x-4 pt-6 ">

                            <div class=" space-y-2 ">
                                <div class="avatar">
                                    <div class="w-24 rounded-full">
                                        <img
                                            
                                            loading="lazy"

                                            *ngIf="!employees[0].profileImgLink"
                                                        
                                            data-src="https://api.goingmobile.app/media/personal-app-assets/img/webp/emptyProfilePic.webp"
                                                        
                                            srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/emptyProfilePic.webp"
                                                        
                                            fallback="https://api.goingmobile.app/media/personal-app-assets/img/png/emptyProfilePic.png"
                                                        
                                            alt="Empty profile picture.">

                                        <img loading="lazy" *ngIf="employees[0].profileImgLink" [src]="employees[0].profileImgLink" />

                                    </div>
                                </div>
                                <div>
                                    <p class=" text-base font-bold text-center ">
                                        {{employees[0].GivenName}} {{employees[0].LastName}}
                                    </p>
                                </div>

                            </div>

                            <div class="items-center ">

                                <p class=" text-xs font-bold text-center text-darkGray mb-4">
                                    Working Schedule
                                </p>
                                <!-- working hour list -->
                                <div class=" flex flex-row  items-center" *ngFor="let empSchd of firstEmpSchedule">
                                    <div>
                                        <i class="fa-solid fa-calendar-check"></i>
                                    </div>
                                    <div class="pl-4 mb-2">
                                        <p class=" text-sm font-light text-gray-500">
                                            {{empSchd.day}}, {{empSchd.startTime}} - {{empSchd.endTime}}
                                        </p>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>


                </div>





            </div>
        </div>
    </div>










    <div class="bg-gray-100">
        <div class="card" *ngIf="!service">

            <div class="mt-5" *ngIf="!model">
                <div class="flex items-center justify-center">
                    <img loading="lazy" data-src="../../../../assets/icons/Illustrations/Service Illustration.png"
                        class="block w-64 items-center justify-center">
                </div>
                <div class="text-customTeal text-lg text-center font-bold">No services</div>
                <div class="text-gray-400 text-sm text-center font-bold">We will let you know when we have one!</div>
            </div>


            <div *ngIf="model" class="mt-8 mx-5 lg:mx-16 flex flex-wrap">
                <div class="mt-12 md:mt-8 mx-4 md:mx-2 flex flex-wrap" *ngIf="model.length > 0">
                    <div class="w-full md:w-1/3 mb-12 "
                        *ngFor="let service of model  "
                        (click)="navigate(service)">
                        <div class="mx-4 md:mx-2 mb-10 flex flex-row  md:h-46 bg-white drop-shadow-md rounded-lg hover:cursor-pointer hover:scale-105 hover:ease-in hover:duration-300" [ngStyle]="{'background-color': service.backgroundColor}">
                            <div
                                class=" flex flex-col w-full relative items-center space-y-4   px-4  pt-4 py-8 md:px-6 md:py-8 ">
                                <div class="absolute pr-124 -top-10 md:pr-0">
                                    <span *ngIf="service.imgLink"
                                        class="inline-flex p-1 items-center justify-center bg-customTeal rounded-full shadow-lg">
                                        <img loading="lazy" [src]="service.imgLink" class="w-16 h-16 rounded-full" />
                                    </span>
                                </div>
                                <h4 class="max-w-sm text-lg font-bold text-center" [ngStyle]="{'color': service.titleColor}">{{truncateHTML(service.serviceName, 35) | titlecase}}</h4>
                                <!-- <p class="max-w text-sm text-center font-light text-darkGray">
                                    {{truncateHTML(service.serviceDescription,60)}}
                                </p>
                                <button type="button" (click)="navigate(service)"
                                    class=" flex items-center w-auto  px-6 pt-1 text-darkBlue font-bold  baseline hover:text-customTeal">Details
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 pl-1" viewBox="0 0 20 20"
                                        fill="currentColor">
                                        <path fill-rule="evenodd"
                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z"
                                            clip-rule="evenodd" />
                                    </svg>
                                </button> -->
                                <a href=""
                                    class="px-3 md:px-6 py-1 justify-start text-white  bg-customTeal rounded-md baseline text-xl font-bold bottom-0 translate-y-[50%] absolute md:ml-0"
                                    *ngIf="service.hasPrice && service.price  && service.price !== ''">
                                    <h4 class="font-bold  ">
                                        <span *ngIf="currency=='USD'">$</span>
                                        <span *ngIf="currency=='CAD'"><sup>$</sup></span>
                                        <span *ngIf="currency=='GBP'"><sup>£</sup></span>
                                        <span *ngIf="currency=='EUR'"><sup>€</sup></span>
                                        <span *ngIf="currency=='JPY'"><sup>¥</sup></span>
                                        <span *ngIf="currency=='INR'"><sup>₹</sup></span>
                                        {{service.price | currency}}
                                        <span *ngIf="!['USD','CAD', 'GBP', 'EUR', 'JPY', 'INR'].includes(currency)">{{currency}}</span>
                                      </h4>
                                </a>
                                <!-- <a href=""
                                    class="px-3 md:px-6 py-1 justify-start text-white  bg-customTeal rounded-md baseline text-xl font-bold bottom-0 translate-y-[50%] absolute md:ml-0"
                                    *ngIf="!(service.price)">
                                    $---
                                </a> -->
                            </div>
                        </div>

                    </div>

                    <div class="w-full text-center">
                        <pagination-controls (pageChange)="p = $event"></pagination-controls>
                    </div>

                </div>

            </div>
        </div>

    </div>


    <div class="alert w-1/3 bg-white border-l-8 rounded-sm border shadow-lg fixed top-0 right-0 "
        [ngClass]="{'visible': openToast, 'invisible': !openToast,'alert-success border-emerald-600': toastType=='success', 'alert-error border-red-600': toastType=='failure' }">
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current flex-shrink-0 h-6 w-6" fill="none"
                viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <span>{{toastMessage}}</span>
        </div>
    </div>
</ng-container>