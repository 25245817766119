import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { AppSettingsService } from '../services/firebase/app-settings.service'; 
import * as AppSettingsActions from './app.actions'; 

@Injectable()
export class AppEffects {
    constructor(private actions$: Actions, 
                private appSettingsService: AppSettingsService) {}

    updateAppSettings$ = createEffect(() =>
        this.actions$.pipe(
          ofType(AppSettingsActions.updateAppSettings),
          mergeMap((action) =>
            from(this.appSettingsService.updateAppSettings(action.settings)).pipe(
              catchError((error) => {
                return of(AppSettingsActions.updateAppSettingsFailure({ error }));
              })
            )
          )
        ),
        { dispatch: false }
      );
}