<div class="text-sm breadcrumbs">
  <ul>
    <li><a [routerLink]="['/admin/dashboard']">Home</a></li>
    <li ><strong>Product Category</strong></li>

  </ul>
</div>

<div class="flex space-x-0">
  <div class="w-1/12"></div>
  <div (click)="backClicked()" class="w-5/12 px-4 py-2 hover:bg-orange-100 bg-orange-200 rounded-lg shadow-lg text-center">
    <a (click)="backClicked()">
      <i class="material-icons white-text">home</i><br/>
      <strong>Back</strong>
    </a>
  </div>
  <div (click)="addCategory();" class="w-5/12 px-4 py-2 hover:bg-green-100 bg-green-200 rounded-lg shadow-lg text-center">
    <a (click)="addCategory();" >
        <i class="material-icons white-text">category</i><br/>
        <strong class="white-text">New Product Category</strong>
  </a>
  </div>

</div>
<div class="flex">
  <div class="w-1/12"></div>
  <div class="w-10/12 bg-slate-500">
    <div *ngIf="productCategoriesModel && showMsg" class="place-content-center">
      <table class="table table-zebra place-content-center w-full">
        <thead>
          <th class="center">
            Please Select From The List
          </th>
          <th class="center">
            Products under category
          </th>
        </thead>
        <tbody>
          <tr *ngFor="let productCategory of productCategoriesModel">
            <td class="center" *ngIf="productCategory.category">
              <a class="hoverable" (click)="selectCategory(productCategory.category, productCategory.productsUnder)">
                <span><strong>{{productCategory.category.name}}</strong> </span>
              </a>
            </td>
            <td class="center" *ngIf="productCategory.noOfProducts">
              <strong class="text-green-600">
                {{productCategory.noOfProducts }}</strong>

            </td>
            <td class="center" *ngIf="!productCategory.noOfProducts">
              <strong class="text-green-600">0</strong>

            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div *ngIf="(!productCategoriesModel || productCategoriesModel.length === 0) && showMsg">
      <div class="row card-panel">
        <p>
          <strong class="red-text">
            Please Update Your Product Category List</strong
          >
        </p>
      </div>
    </div>

    <div class="section"></div>


    <div id="editModal" *ngIf="editModalOpen" class="modal"  [ngClass]="{'modal-open': editModalOpen, 'modal-action': !editModalOpen }">
      <div class="modal-box w-11/12 max-w-3xl absolute top-4">
        <app-close-modal (closeBtnClicked)=closeEditModal()></app-close-modal>

      <form class=""
        [formGroup]="productCategoryForm"
        (ngSubmit)="manageCategory(productCategoryForm.value)"
      >
        <div class="w-full">
          <div class="text-center"><strong > Edit Product Category </strong></div>

          <div class="">
            <div class="">
              <i class="material-icons prefix text-red-600">text_rotation_none</i>
              <input
                id="name"
                type="text"
                formControlName="name"
                required
                class="focus:invalid:border-red-500 validate border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline "
                autocomplete="off"
              />
              <!-- <label for="name">Product Category Name</label> -->
              <div *ngIf="(name.dirty || name.touched) && name.invalid">
                <small *ngIf="name.errors.required" class="text-red-600">
                  Category Name is required.
                </small>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-action">
          <button
            type="submit"
            [disabled]="!productCategoryForm.valid"
            class="green btn-flat right"
            style="margin-right: 10px;"
          >
            <span *ngIf="selectedModel">Update</span>
            <span *ngIf="!selectedModel"> Save </span>
          </button>
          <button
            *ngIf="selectedModel"
            type="button"
            class="red btn-flat right "
            (click)="openDeleteModal()"
          >
            Delete
          </button>
          <a (click)="closeEditModal()"
            class="grey lighten-1 btn-flat right"
            >Cancel</a
          >
        </div>
      </form>



      <table class="table" *ngIf="selectedModel">
        <thead>
        <tr>
            <th>Product</th>
            <th>Price</th>
            <th>Edit</th>
            <th>Delete</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of selectedProductsUnderCategory let i=index">

          <td>{{item.productName}} </td>
          <td>${{item.price}}CAD</td>
          <td>
            <button (click)="editProductUnder(item)" class="btn btn-small green"> <i class="material-icons white-text">edit</i></button>
          </td>
          <td>
            <button (click)="deleteProductUnder(item)"  class="btn btn-small red"> <i class="material-icons white-text">delete</i></button>
          </td>

        </tr>
      </tbody>
      </table>

    </div>

    </div>
    <div id="createModal" *ngIf="createModalOpen" class="modal"  [ngClass]="{'modal-open': createModalOpen, 'modal-action': !createModalOpen }">
      <div class="modal-box w-11/12 max-w-3xl absolute top-4">
        <app-close-modal (closeBtnClicked)=closeCreateModal()></app-close-modal>
      <form class=""
        [formGroup]="productCategoryForm"
        (ngSubmit)="manageCategory(productCategoryForm.value)"
      >
        <div class="w-full">
          <div class="text-center"><strong> Create Product Category </strong></div>

          <div class="">
            <div class="">
              <i class="material-icons prefix text-red-600">text_rotation_none</i>
              <input
                id="name"
                type="text"
                formControlName="name"
                required
                class="focus:invalid:border-red-500 validate border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline "
                autocomplete="off"
              />
              <!-- <label for="name">Product Category Name</label> -->
              <div *ngIf="(name.dirty || name.touched) && name.invalid">
                <small *ngIf="name.errors.required" class="text-red-600">
                  Category Name is required.
                </small>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-action">
          <button
            type="submit"
            [disabled]="!productCategoryForm.valid"
            class="green btn-flat right"
            style="margin-right: 10px;"
          >
            <span > Save </span>
          </button>

          <a (click)="closeCreateModal()"
            class="grey lighten-1 btn-flat right"
            >Cancel</a
          >
        </div>
      </form>

    </div>

    </div>
    <div id="deleteModal" *ngIf="deleteModalOpen" class="modal"  [ngClass]="{'modal-open': deleteModalOpen, 'modal-action': !deleteModalOpen }">
      <div class="modal-content">
          <h6><strong> Delete Product Category </strong></h6>

        </div>
        <div class="modal-footer">
          <button
          *ngIf="selectedModel"
          type="button"
          class="red btn-flat right white-text"
          style="margin-right: 10px;"
          (click)="deleteCategory(selectedModel)"
        >
          Delete
        </button>
          <a
            class="modal-close grey lighten-1 btn-flat right"
            style="margin-right: 10px;"
            >Cancel</a>
        </div>

    </div>
  </div>
  <div class="col l1"></div>
</div>



<div class="alert alert-success shadow-lg fixed top-0 right-0 "
[ngClass]="{'visible': openToast, 'invisible': !openToast,'alert-success': toastType=='success', 'alert-error': toastType=='failure' }"
>
  <div class="text-white font-bold">
    <span class="material-icons">check_circle</span>
    <span>{{toastMessage}}</span>
  </div>
</div>

