import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ApiKeyManagerService } from './apiKeyManager.Service';
import { FireMembersService } from '../firebase/fire-members.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ImageResizerModel } from 'src/app/models/ImageResizerModel';
import { WriteToFileModel } from 'src/app/models/WriteToFileModel';

@Injectable({
  providedIn: 'root'
})
export class ManifestService {

  constructor(private httpClient: HttpClient,
     private keyManager: ApiKeyManagerService,
    private fireMembersService: FireMembersService ) {}

  //  postsListRef: AngularFirestoreCollection<any>;
  //  postRef: AngularFirestoreDocument<any>;

  url = 'https://api.goingmobile.app/api/AppManagment/';
  website = environment.firstNation.website;

  // generateManifest(apiUrl,appName): Observable<any> {
  //   const headers = new HttpHeaders({'API-KEY': this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8'});
  //   return this.httpClient.post<any>(this.url + 'GenerateManifest/', JSON.stringify(apiUrl,appName), {headers});
  // }

  imageResizer(model: ImageResizerModel): Observable<any> {
    const headers = new HttpHeaders({'API-KEY': this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8'});
    return this.httpClient.post<any>(this.url + 'ImageResizer/', JSON.stringify(model), {headers});
  }

  WriteFile(model: WriteToFileModel): Observable<any> {
    const headers = new HttpHeaders({'API-KEY': this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8'});
    return this.httpClient.post<any>(this.url + 'WriteFile/', JSON.stringify(model), {headers});
  }
  //  saveManifest(model: any){
  //   model.id = this.db.createId();
  //   return this.db.collection('Manifest').doc(model.id).set(model);
  // }
  // getManifest(){
  //   return this.db.collection('Manifest');
  // }

  // updateManifest(model){
  //   return this.db.collection('Manifest').doc(model.id).update(model);
  // }
  // deleteNote(model: any){
  //   return this.db.collection('Manifest').doc(model.id).delete();
  // }
}
