import { Component, OnInit, Output, Input, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { CustomAdminEmailModel, EmailModel } from 'src/app/models/emailModel';
import { EmailService } from 'src/app/services/api/email.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { UserService } from 'src/app/services/firebase/user.service';
import { environment } from 'src/environments/environment';
import { AppRequestAcceptComponent } from '../../admin/app-request/app-request-accept/app-request-accept.component';
import { GetAppService } from 'src/app/services/firebase/get-app.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {

  @Input() userLogged : any;
  @Output() cancelSendClicked = new EventEmitter();
  @Output() sendClicked: EventEmitter<any> = new EventEmitter<any>();

  contactForm: UntypedFormGroup;
  setUp: any;
  appLogo: any;
  storeEmail: any;
  domain = environment.firstNation.name;
  // domainName = "https://" + this.domain + ".goingmobile.app"
  isNationApp = environment.firstNation.Origin == "FNLeaders" ? true : false;
  domainName = this.isNationApp ? "https://" + this.domain + ".mynation.app" : "https://" + this.domain + ".goingmobile.app";
  
  user: any;

  appRequest: any;

  constructor(private fb: UntypedFormBuilder,
              private cdr: ChangeDetectorRef,
              private appSettingsService: AppSettingsService,
              private emailService: EmailService,
              private userService: UserService,
              private AppRequestService: GetAppService
    ) { }

  ngOnInit(): void {
    this.appSettingsService.getAppSettingsList().valueChanges().subscribe(setup => {
      this.setUp = setup[0]
      this.appLogo = this.setUp.logo;
    });

    this.contactForm = this.fb.group({
      subject: [''],
      emailbody: [''],
    });

    if(this.userLogged.email === 'admin@goingmobile.app'){
      this.AppRequestService.getRequestByUrl(this.domain).valueChanges().subscribe((x) =>{
        if(x && x.length != 0){
          this.user = x[0];
        }else{
          this.userService.getUserByEmail(this.userLogged.Email).valueChanges().subscribe((user) =>{
            if(user && user.length != 0){
              this.user = user[0];      
            }
        })
      
        }
      })
    }else{
      this.userService.getUserByEmail(this.userLogged.Email).valueChanges().subscribe((user) =>{
        if(user && user.length != 0){
          this.user = user[0];
        }
    })
    }
  }
  sendClient(models) {
    const emailModel = new CustomAdminEmailModel();
    emailModel.subject = models.subject;
    emailModel.body = models.emailbody;
    emailModel.isNationApp = this.isNationApp;
    emailModel.body += '<p>';

    emailModel.body += '</p>';
    emailModel.body += `<p> <b>App Name:</b> ${this.domain}</a></p>`
    emailModel.body += `<p> <b> App Url:</b> https://${this.domain}.goingmobile.app</a></p>`
    emailModel.body += `<p> <b>Client Name:<b/> ${this.user.GivenName ? this.user.GivenName : this.user.fullName} ${this.user.LastName ? this.user.LastName : ''} </p>`
    emailModel.body += `<p> <b>Client Email Address:</b> ${this.user.Email ? this.user.Email : this.user.email} </p>`
    emailModel.body += `<p> <b>Client Mobile Phone:</b> ${this.user.cellPhone ? this.user.cellPhone : '--'}</p>`
    if (this.appLogo) {
      emailModel.body += `<img src="${this.appLogo}"
      width="100" height="100" style=" display: block; alt="Logo">`;
    }
    emailModel.body += '</p>';

    // if (environment.firstNation.name !== "nationalchief") {
      // emailModel.body += `<p> To stop receiving SMS please login to <a href="${this.domainName}">${this.domainName}</a></p>`
      // emailModel.body += `<p> Go to your preferences and disable Email, SMS or Both.</p>`
    // }
    
    this.storeEmail = ['info@goingmobile.app'];
    // this.storeEmail.push(this.userLogged.Email);
    emailModel.emailList = this.storeEmail;

    // custom send email used
    emailModel.host = "smtp.gmail.com",
    emailModel.adminEmail = "mkproject.app@gmail.com",
    emailModel.appPassword = "ytklyidomvqfmtky",

    // this.emailService.sendCustomEmail(emailModel).subscribe((res) => {
    //   if (res) {
        
    //     this.sendBtnClicked({ html: 'Email sent sucessfully ', classes: 'green', type: 'success' });
    //     this.cdr.detectChanges();
    //     this.reset(models);
        
    //   }
    //   else {
    //   }
    // })

    this.emailService.sendEmail(emailModel).subscribe((res) => {
      if (res) {
        
        this.sendBtnClicked({ html: 'Email sent sucessfully ', classes: 'green', type: 'success' });
        this.cdr.detectChanges();
        this.reset(models);
        
      }
      else {
      }
    })
  }
  reset(models) {
    this.contactForm.patchValue({
      subject: '',
      emailbody: '',
    });
  }

  sendBtnClicked(response){
    this.cancelSendClicked.emit();
    this.sendClicked.emit(response);
  }

  cancelSendBtnClicked(){
    this.cancelSendClicked.emit();
  }

  get emailbody() { return this.contactForm.get('emailbody'); }
  get subject() { return this.contactForm.get('subject'); }
}
