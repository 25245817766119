import { AuthService } from './../../../../services/firebase/auth.service';
import { ScheduleService } from './../../../../services/firebase/employee-schedule.service';
import { UserService } from 'src/app/services/firebase/user.service';
import { Location } from '@angular/common';
import { EmployeeServicesService } from './../../../../services/firebase/employee-services.service';
import { Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CartService } from 'src/app/services/checkout/cart.service';
import { ProductsServiceMenuService } from 'src/app/services/api/data.service';
import { PermissionService } from 'src/app/services/firebase/permission.service';
import { HelperService } from 'src/app/services/helper/helper';
import { filter, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectAppSettings } from 'src/app/state/app.selectors';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-view-service',
  templateUrl: './view-service.component.html',
  styleUrls: ['./view-service.component.css']
})
export class ViewServiceComponent implements OnInit,OnDestroy {

  model: any[];
  serviceId: any;
  showNews = false;
  service: any;
  noNews = false;
  adminThemeColor = '';
  employees = [];
  firstEmployee = [];
  firstEmpSchedule = [];
  currentUser: any;
  // modal
  deleteModalOpen = false;

  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;

  servicesName
  catagory

  //Permission
  isAdmin: boolean;
  perm:any;
  permission:any;
  currency: any;
  @Input() modelToPreview: any;
  @Input() view

  //to unsubscribe(to remove employee detail displaying twice)
  user: any;
  permMember:any;
  permSocial:any;
  permPES:any;
  permSpecial:any;
  permAnalytics:any;

  //employee schedule modal controller 
  viewEmployeeSchedule = false;
  selectedEmployee:any;
  signupFirstModalOpen = false;
  scheduleSubscription: any;
  appSetting: any;
  hasServicesCategory: any;
  appSettings$: Subscription;
  constructor(
    private empService: EmployeeServicesService,
    private router: Router,
    private location: Location,
    private userService: UserService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private scheduleService: ScheduleService,
    private productsServiceMenuService:ProductsServiceMenuService,
    private cartService : CartService,
    private helperService: HelperService,
    private permissionService: PermissionService,
    private store: Store) { 
      this.appSettings$ = this.store.select(selectAppSettings).subscribe((settings) => { this.appSetting = {...settings}; 
    });
      this.hasServicesCategory = this.appSetting.hasServicesCategory ? this.appSetting.hasServicesCategory : false;
    }

  ngOnDestroy(){
    this.appSettings$.unsubscribe();
  }
  ngOnInit() {
    window.scroll(0, 0);
    this.route.paramMap.subscribe(params => {
      this.serviceId = params.get('id');
    });
    this.currentUser = this.authService.getGlobalUser();

    if(this.currentUser){
      if(this.currentUser.role == 'Admin'){
        this.isAdmin = true;
      }

      if(this.currentUser.role == 'Employee'){
        this.permissionService.getPermissionByEmployeeId(this.currentUser.uid).valueChanges().subscribe(perm => {
          if(perm && perm.length != 0){
            this.perm = perm[0];
            //this.permission = this.perm.permission[0];
            this.permMember = this.perm.permission[0]
            this.permPES = this.perm.permission[1]
            this.permAnalytics = this.perm.permission[2];
            this.permSocial = this.perm.permission[3];
            this.permSpecial = this.perm.permission[4];
          }
        });
      }

    }
    if (this.modelToPreview){
      this.service = this.modelToPreview;
    }
    // get employee

    if (this.serviceId){
      if (this.serviceId === 'emptyservice1'){
        this.service = {
          id: 'emptyservice1',
          serviceName: 'Business Services',
          serviceDescription: `services by business organizations  to conduct their business activities. create something that assists the business, like transportation, communication etc`,
          price: '0',
          serviceOffers: []
         };
      }
      if (this.serviceId === 'emptyservice2'){
        this.service = {
          id: 'emptyservice2',
          serviceName: 'Social Services',
          serviceDescription: `the aim is an accomplishment of a social goal, it could be providing education, healthy facility and many others    `,
          price: '0',
          serviceOffers: []
        };
      }
      if (this.serviceId === 'emptyservice3'){
        this.service = {
          id: 'emptyservice3',
          serviceName: 'Personal Services',
          serviceDescription: `is where everyone has a different experience. It could be tourism, restaurants etc`,
          price: '0',
          serviceOffers: []
        };
      }
      this.empService.getServiceById(this.serviceId).valueChanges().pipe(
        filter(service => service && service.length > 0),
        tap(service => {
          this.service = this.modelToPreview ? this.modelToPreview : service[0];
        }),
        switchMap((service: any) => {
          return service.assignedTo && service.assignedTo.length > 0
          ? from(service.assignedTo)
          : of([])
        }),
        mergeMap((empId:any) => this.userService.getUserById(empId).valueChanges()),
        filter(user => user && user.length > 0),
        tap((user: any) => {
          const userExists = this.employees.some(employee => employee.uid === user[0].uid);
          if (!userExists) {
            this.employees.push(user[0]);
          }
          if (this.firstEmployee.length === 0) {
            this.firstEmployee = this.employees[0];
            this.scheduleSubscription = this.scheduleService.getScheduleByEmployeeId(this.employees[0].uid).valueChanges().subscribe((empSchedule: any) => {
              if (empSchedule.length > 0) {
                empSchedule[0].schedule.forEach((schd: any) => {
                  if (!schd.unAvailable) {
                    this.firstEmpSchedule.push(schd);
                  }
                });
              }
            });
          }
        }),
        take(1) // Ensures subscription completes after processing the first emitted value
      ).subscribe(() => {
        // Clean up subscriptions
        if (this.scheduleSubscription) {
          this.scheduleSubscription.unsubscribe();
        }
      });

    }
    else{

      this.empService.getPublicServices().valueChanges().subscribe(x => {
        if (x) {
          if (x.length > 0) {
            this.model = x;
          }

        }
      });
    }

    this.productsServiceMenuService.servicesName.subscribe(p=>{
      this.servicesName = p;
    });
    this.productsServiceMenuService.servicesCategoryName.subscribe(p=>{
      this.catagory = p;
    });
    this.productsServiceMenuService.currencyName.subscribe(c => {
      this.currency = c;
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.service = this.modelToPreview
    // if(this.modelToPreview){
    //   this.productDescription = this.modelToPreview.productDescription
    // }
  }
  getUrl(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);

    if (match && match[2].length === 11) {
      const youtubeUrl = 'https://www.youtube.com/embed/' + match[2];
      return youtubeUrl;
    } else {
        return url;
    }
  }
  backClicked() {
    this.location.back();
  }

  openDeleteModal(){
    this.deleteModalOpen = true;
  }
  closeDeleteModal(){
    this.deleteModalOpen = false;
  }

  deleteService(){
    this.closeDeleteModal();
    this.empService.deleteService(this.service).then(() => {
      this.toast({html: 'Successfully deleted', classes: 'red', type: 'failure', redirect: true});
    });
  }

  AddToCart(productID: any) {
    if (this.currentUser){
      this.cartService.addProductToCart(productID, 1)
      this.toast({html: 'Product Successfully added to cart', classes: 'green', type: 'success'});
    } else {
      this.toast({html: 'You need to be Registered to add Item to your cart.', classes: 'red', type: 'failure', redirect: true});
    }
  }

  BookAppointment(service,id){
    if (this.currentUser.role === 'User'){
      this.router.navigateByUrl('/user/book-appointment', {state: {service: service, serviceUnder: null, employeeId:id}});
    }
    if (this.currentUser.role === 'Admin'){
      this.router.navigateByUrl('/admin/create-appointment', {state: {service: service, serviceUnder: null, employeeId:id}});
    }
  }
  BookServiceAppointment(service, serviceUnder){
    if (this.currentUser.role === 'User'){
      this.router.navigateByUrl('/user/book-appointment', {state: {service: service, serviceUnder:serviceUnder}});
    }
    if (this.currentUser.role === 'Admin'){
      this.router.navigateByUrl('/admin/create-appointment', {state: {service: service, serviceUnder:serviceUnder}});
    }
    if (this.currentUser.role === 'Employee'){
      this.router.navigateByUrl('/employee/book-appointment', {state: {service: service, serviceUnder:serviceUnder}})
    }
  }
  navigateEdit(service){
    if (this.isAdmin){
      this.router.navigate(['/admin/edit-service', service.id]);
    }
    else if (this.currentUser.role === 'Employee'){
      this.router.navigate(['/employee/edit-service', service.id]);
    }
    // for !currentUser
    else{
      this.router.navigate(['/']);
    }
  }

  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
      if (obj.redirect){
        this.router.navigate(['/admin/services']);
      }
    }, 2000);
  }
  isValidUrl(url){
    return this.helperService.isValidURL(url);
  }
  OpenLoginPopup(){
    // this.toast({html: 'You should login first to book appointment!', classes: 'red', type: 'declined'});
    this.signupFirstModalOpen = true;
  }
  openDeleteToast(){
    this.toast({html: 'Comment Deleted!', classes: 'red', type: 'failure', redirect: false});
  }
  checkIfAppointment(hasSubServices, takeAppointment){
    return !hasSubServices.value && 
      takeAppointment && 
      ['yes', true].some(val => takeAppointment.includes(val));
    }
}
