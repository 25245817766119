import { AuthService } from 'src/app/services/firebase/auth.service';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductsService } from './../../../services/firebase/products.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MediaService } from 'src/app/services/api/media.service';
import { ImageModel, ImageUploaderResponseModel } from 'src/app/models/ImageModel';
import { ProductsServiceMenuService } from 'src/app/services/api/data.service';
import { PermissionService } from 'src/app/services/firebase/permission.service';
import { HelperService } from 'src/app/services/helper/helper';

// declare var navigator: any;

@Component({
  selector: 'app-product-catagory',
  templateUrl: './catagory.component.html',
  styleUrls: ['./catagory.component.css']
})
export class CatagoryComponent implements OnInit {


  productCatagory: any;
  currentUser: any;

  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  settings: any;


  editModalOpen = false;
  deleteModalOpen = false;
  productCategoryForm: UntypedFormGroup;
  selectedProductsUnderCategory: any;

  // images
  imgErrorMsg: any;
  base64Image: any;
  binDoc: File;
  oldUrl: any;
  mobileBase64Image: any;
  selectedModel

  isMobile=false
  catagoryName

  //permission
  perm: any;
  permission:any;
  isAdmin : boolean;
  productsName = '';
  createConfirmationModalOpen = false;
  editConfirmationModalOpen = false;
  cancelCreateModalOpen = false;
  cancelEditModalOpen = false;
  cropModalOpen = false;
  imageChangedEvent: any = '';

  permMember:any;
  permSocial:any;
  permPES:any;
  permSpecial:any;
  permAnalytics:any;
  
  constructor(private route: ActivatedRoute, private location: Location,
    private router: Router,
    private productsService: ProductsService,
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    public settingsService: AppSettingsService,
    private mediaService: MediaService,
    private helperService: HelperService,
    private productsServiceMenuService:ProductsServiceMenuService,
    private permissionService : PermissionService) {

    if (typeof window['Capacitor'] !== 'undefined' && window['Capacitor']['platform'] !== 'web' ) {
      this.isMobile = true;
    }
  }

  ngOnInit(): void {
    // this.catagoryName = localStorage.getItem('productsCategory')
    // console.log(this.catagoryName,localStorage.getItem('productsCategory'))

    this.currentUser = this.authService.getGlobalUser();
    if(this.currentUser && this.currentUser.role == 'Admin'){
      this.isAdmin = true;
    }
    if(this.currentUser){
      this.permissionService.getPermissionByEmployeeId(this.currentUser.uid).valueChanges().subscribe(perm =>{
        // console.log("print name",this.currentUser.GivenName)
        if(perm && perm.length != 0){
          this.perm = perm[0];
          //this.permission = this.perm.permission[0];
          this.permMember = this.perm.permission[0]
          this.permPES = this.perm.permission[1]
          this.permAnalytics = this.perm.permission[2];
          this.permSocial = this.perm.permission[3];
          this.permSpecial = this.perm.permission[4];

        }
      })
    }

    this.productCategoryForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      imgUrl: [''],
    });

    this.settingsService.getAppSettingsList().valueChanges().subscribe(menu => {
      if (menu && menu.length > 0) {
        this.settings = menu[0]
        if (this.settings.isBusinessApp) {

          this.productsService.getProductCategoryList().valueChanges().subscribe(productCatagory => {
            if (productCatagory.length > 0) {
              this.productCatagory = productCatagory;
            }
          });
        }
      }
    });
    this.productsServiceMenuService.productsCategoryName.subscribe(p=>{
      this.catagoryName=p
    })
    this.productsServiceMenuService.productsName.subscribe(p => {
      this.productsName = p;
    });
  }
  
  get name() { return this.productCategoryForm.get('name'); }


  backClicked() {
    this.location.back();
  }

  addCategory() {
    this.selectedModel = undefined;
    this.productCategoryForm.patchValue({ 'name': '' });
    this.editModalOpen = true;
  }


  selectCategory(area: any) {
    // console.log(area,'the upated')
    this.selectedProductsUnderCategory = area;
    if (area) {
      this.selectedModel = area;
      this.productCategoryForm.patchValue({name: area.name});
      this.editModalOpen = true;
    }
  }

  onImageUpload(response:ImageUploaderResponseModel) {
    if (response.error){
      this.imgErrorMsg = response.errorMessage
      return
    }

    this.binDoc = response.binDoc;
    this.mobileBase64Image = response.imgBase64;
    this.base64Image = 'data:image/jpeg;base64,' + response.imgBase64;
 }
  handleMobilePhotoInput() {
    // navigator.camera.getPicture((image: any) => {
    //   const imageName = 'IMG.jpeg';
    //   const imageBlob = this.dataURItoBlob(image);
    //   const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });
    //   this.binDoc = imageFile;
    //   this.base64Image = 'data:image/jpeg;base64,' + image;
    //   this.mobileBase64Image = image;

    // }, (error) => {
    //   alert(error);
    // },
    //   {
    //     quality: 50,
    //     destinationType: navigator.camera.DestinationType.DATA_URL,
    //     encodingType: navigator.camera.EncodingType.JPEG,
    //     mediaType: navigator.camera.MediaType.PICTURE,
    //     sourceType: navigator.camera.PictureSourceType.PHOTOLIBRARY,
    //   }
    // );
  }

  handleImageInput(files: FileList) {
    if (!this.validateImage(files[0].name)) {
      this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';

      return;
    }
    this.binDoc = files[0];
    this.imgErrorMsg = undefined;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.base64Image = reader.result as string;
    };
  }

  private validateImage(name: any) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() === 'png') {
      return true;
    } else if (ext.toLowerCase() === 'jpg') {
      return true;
    } else if (ext.toLowerCase() === 'jpeg') {
      return true;
    } else {
      return false;
    }
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }


  removeFile() {
    this.binDoc = undefined;
    this.base64Image = undefined;
  }

  remove() {
    // this.oldUrl = this.selectedModel.imgUrl
    // this.selectedModel.imgUrl =''
    this.binDoc = undefined;
    this.base64Image = undefined;
  }

  uploadImage(values) {
    if (this.isMobile) {
      if (this.mobileBase64Image) {
        const img = new ImageModel();
        img.imgBase64 = this.mobileBase64Image;
        this.mediaService.uploadImage(img).subscribe((upload) => {
          // this.loading = true;
          if (upload) {
            values.imgUrl = upload.imgLink;
            this.saveCatagory(values)
            return;
          }
        });
      }
    }
    else {
      if (this.binDoc && this.base64Image) {
        let base64result = this.base64Image.split(',')[1];
        const img = new ImageModel();
        img.imgBase64 = base64result;
        this.mediaService.uploadImage(img).subscribe(
          upload => {
            if (upload) {
              values.imgUrl = upload.imgLink;
              // console.log('img',values,upload)
              this.saveCatagory(values)
              return;
            }
          }
        );
      }
    }
  }

  saveCatagory(model: any) {
    if (!this.selectedModel) {
      this.productsService.createProductCategory(model).then(saved => {
        this.toast({ html: 'Product Category Successfully Saved', classes: 'green', type: 'success' });
      }).catch(err=>{
        console.log(err,'the error')
      })
    } else {
      this.productsService.updateProductCategory(model).then(saved => {
        this.toast({ html: 'Product Category Successfully Saved', classes: 'green', type: 'success' });
      });
    }
    this.removeFile()
  }

  manageCategory(model: any) {

    this.editModalOpen = false;
    this.createConfirmationModalOpen = false;
    this.editConfirmationModalOpen = false;
    let newArea = {}
    if (!this.selectedModel) {
      newArea = {
        id: '',
        name: model.name,
        productCount: 0,
        imgUrl: ''
      };
    } else {
      newArea = {
        id: this.selectedModel.id,
        name: model.name,
        productCount: this.selectedModel.productCount ? this.selectedModel.productCount : 0,
        imgUrl: this.selectedModel.imgUrl ? this.selectedModel.imgUrl : '',

      };
    }
    if (this.base64Image) {
      this.uploadImage(newArea)
    } else {
      this.saveCatagory(newArea)
    }

  }
  deleteCategory(category) {
    this.productsService.deleteProductCategory(category).then(() => {
      this.deleteModalOpen = false;
      this.toast({ html: 'Category deleted.', classes: 'red', type: 'failure' });
      // this.backClicked();
    });

  }

  closeEditModal(){
    this.editModalOpen = false;
  }
  openDeleteModal() {
    // $('#deleteModal').modal('open');
    this.editModalOpen = false;
    this.deleteModalOpen = true;
  }
  closeDeleteModal() {
    this.deleteModalOpen = false;
  }


  toast(obj) {
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green';
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
      // this.backClicked();
    }, 2000);
  }

  navigate(catagory) {
    if (this.currentUser == null) {
      this.router.navigate(['/view-products', catagory.id])
    }
    else if (this.currentUser.role == 'User') {
      this.router.navigate(['/user/products', catagory.id])
    }
    else if (this.currentUser.role == 'Employee') {
      this.router.navigate(['/employee/view-products', catagory.id])
    }
    else if (this.currentUser.role == 'Admin') {
      this.router.navigate(['/admin/products', catagory.id])
    }
  }
  truncateHTML(text: string, limit: any): string {
    return this.helperService.truncateHTML(text,limit);
  }
  getInnerText(htmlString){
    return this.helperService.getInnerText(htmlString);
  }


  closeCreateConfirmationModal(){
    this.createConfirmationModalOpen = false;
  } 

  closeEditConfirmationModal(){
    this.editConfirmationModalOpen = false;
  } 
  closeAllModals(){
    this.createConfirmationModalOpen = false;
    this.editModalOpen = false;
    this.createConfirmationModalOpen = false;
    this.cancelCreateModalOpen = false;
    this.cancelEditModalOpen = false;
  }
  closeCancelModal(){
    this.cancelCreateModalOpen = false;
    this.cancelEditModalOpen = false;
  }
  fileChangeEvent(event){
    this.imageChangedEvent = event;
    this.cropModalOpen = true;
  }
  closeCroppingModal(){    
    this.cropModalOpen = false;
  }
  
  processCroppedImage(event){
    this.cropModalOpen = false;
    this.base64Image = event;
    this.binDoc = this.base64Image;
  }
}

