   <div class="flex md:flex-row flex-col md:gap-10 gap-4 py-4   md:mx-auto w-full ">
        <div *ngFor="let service of sections | slice:0:2; " 
            [routerLink]="['/view-custom-section', service.id]" class="w-full">
        <div class=" flex flex-col md:flex-row w-full rounded-md  space-y-4   px-4  py-4  md:px-6 bg-white  border border-solid border-b-4 border-customTeal  drop-shadow-md ">
        <div *ngIf="service.imgLink" class="w-full  h-40 md:h-48 rounded-xl " [style]="'background:linear-gradient(rgba(217, 217, 217, 0), rgba(0, 0, 0, 0)),url('+ service.imgLink +'); background-size: contain; background-repeat: no-repeat; background-position: center;'">
        </div>
        <div class=" w-full flex flex-col gap-y-4 ">
            <h4 class="text-2xl font-bold " [ngStyle]="{'color':service.titleColor}">{{truncateHTML(getInnerText(service.title),40)}}</h4>
            <p>{{truncateHTML(getInnerText(service.description),200)}}</p>
        </div>
        </div>
        </div>
    </div>

