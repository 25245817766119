import { Component, Input, OnInit } from '@angular/core';
import { HelperService } from 'src/app/services/helper/helper';
import {
  Carousel,
  initTE,
} from "tw-elements";

@Component({
  selector: 'app-about-list-minimal',
  templateUrl: './about-list-minimal.component.html',
  styleUrls: ['./about-list-minimal.component.css']
})
export class AboutListMinimalComponent implements OnInit {

  @Input() sections;
  @Input() name;

  constructor(
    private helperServices: HelperService
  ) { }

  ngOnInit(): void {
    initTE({ Carousel });
  }
  truncateHTML(text: any, limit): string {
    return this.helperServices.truncateHTML(text, limit);
   }

   getInnerText(text){
    return this.helperServices.getInnerText(text);
  }
}
