<div *ngIf="!newFormClicked">
    <div class="flex justify-end mt-4">
        <button (click)="newForm()" class="px-4 py-2 rounded-md text-white bg-customTeal mx-10"><i
                class="material-icons float-left">add_circle</i>New Form</button>
    </div>

        <div class="flex flex-col md:flex-row flex-wrap m-3 md:m-6">
            <div class="flex flex-col bg-white w-full md:w-max h-56 rounded-lg p-8 md:p-6 space-y-4 m-4" *ngFor="let form of forms">
                <div class="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-32 md:justify-between">
                    <p class="font-bold text-lg">{{form.name}}</p>
                    <div class="flex space-x-2 items-center">
                        <div class=""></div>
                        <!-- <div class="float-right" *ngIf="currentUser"> -->
                            <div class="dropdown dropdown-end">
                              <label tabindex="0" class="m-1"><i class="material-icons gray-text-600">more_vert</i></label>
                              <ul tabindex="0" class="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52 font-normal">
                                <li  (click)="navigateEdit(form.id)"><a><i class="material-icons gray-text-600" >edit</i>Edit</a></li>
                                <li (click)="openDeleteModal()"><a><i class="material-icons gray-text-600" >delete</i>Delete</a></li>
                              </ul>
                            </div>
                          
                        <!-- <p class="text-sm px-4 py-2 bg-gray-300 text-black">form</p> -->
                    </div>
                </div>

                <div class="flex">
                    <div class="flex space-x-2 items-center" *ngIf="form && form.description">
                        <div class="rounded-full bg-gray-400 w-3 h-3"></div>
                        <p class="text-sm">{{truncateHTML(form.description,20)}}</p>
                    </div>
                </div>

                <div class="flex">
                    <div class="avatar-group -space-x-6">
                        <div class="avatar">
                            <div class="w-12">
                                <img loading="lazy" data-src="https://placeimg.com/192/192/people" />
                            </div>
                        </div>
                        <div class="avatar">
                            <div class="w-12">
                                <img loading="lazy" data-src="https://placeimg.com/192/192/people" />
                            </div>
                        </div>
                        <div class="avatar">
                            <div class="w-12">
                                <img loading="lazy" data-src="https://placeimg.com/192/192/people" />
                            </div>
                        </div>
                        <div class="avatar placeholder">
                            <div class="w-12 bg-neutral-focus text-neutral-content">
                                <span>+99</span>
                            </div>
                        </div>
                    </div>
                    <p class="font-bold text-customTeal text-sm md:text-base mt-3">Responses</p>

                </div>

                <div class="flex  space-x-5 md:space-x-10 py-2 ">
                    <button class="flex items-center space-x-2  w-max h-min text-sm md:text-base font-bold">
                        <span>Date Posted on {{form.datePosted.toDate()| date:'short'}}</span>
                    </button>
                </div>
            
                <div id="my-modal-users" *ngIf="deleteModalOpen" class="modal"
      [ngClass]="{'modal-open': deleteModalOpen, 'modal-action': !deleteModalOpen }">
      <div class="modal-box">
        <div class="modal-content">
          <h3 class="font-bold text-lg mb-1 text-center">Delete Form</h3>
          <hr />
          <p class="py-4 text-center">Are you sure you want to delete the form?</p>



            <div class="flex justify-between">
              <button type="button" class="font-bold text-black border-none"
                (click)="closeModel()">Cancel</button>
              <button  (click)="delete(form)"
              class="px-4 py-2 bg-red-500 text-white rounded-md border-none"
               >Delete </button>
            </div>

          
        </div>
      </div>
    </div>

            </div>
        </div>

</div>





<div class="font-body min-h-screen max-h-max pt-6" *ngIf="newFormClicked">

    <form class="" [formGroup]="form" (ngSubmit)="createApplication(form.value)">
        <div *ngIf="!nextClicked">
            <div class="flex justify-between md:mx-56 mb-4">
                <p class=" font-bold text-lg text-center text-black md:ml-4">Create Form </p>
                <!-- <button class="border-2 border-customTeal px-4 py-1 rounded-md"><i
                        class="material-icons float-left text-customTeal">add_circle</i>Use Template</button> -->
            </div>
            <div class="flex flex-col md:w-4/6 m-2 bg-white h-max p-4 md:p-12 rounded-lg space-y-4 md:translate-x-1/4">

                <div class="flex space-y-2 md:w-full  flex-col">
                    <label class="font-bold text-sm">Form Name: </label>
                    <input
                        class=" border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline bg-gray-100"
                        id="name" type="text " placeholder="" formControlName="name">
                    <div *ngIf="(name.dirty || name.touched) && name.invalid">
                        <small *ngIf="name.errors.required" class="text-red-500 "> Form Name is required.
                        </small>
                    </div>
                </div>

                <div class="flex space-y-2 md:w-full  flex-col">
                    <label class="font-bold text-sm">Form Description: </label>
                    <textarea
                        class="h-36 border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline bg-gray-100"
                        id="Email " type="text " placeholder="" formControlName="description"></textarea>
                </div>

                <!-- 
            <div>
                <div class="text-sm font-bold mb-3 mt-4">Upload form logo (optional)</div>

                <div class="" *ngIf="!isMobile">
                    <div class="flex items-center space-x-6">
                        <label class="block">
                            <span class="sr-only">Choose photo</span>
                            <input type="file" accept="image/*" (change)="handleImageInput($event.target.files)" formControlName="logo"
                            class="block w-full text-sm text-slate-500
                    file:mr-4 file:py-2 file:px-4
                    file:rounded-full file:border-0
                    file:text-sm file:font-semibold
                    file:bg-violet-50 file:text-violet-700
                    hover:file:bg-violet-100" />
                        </label>

                    </div>
                    <p class="text-red-500 italic text-xs" *ngIf="imgErrorMsg">
                        <strong><small><i class="material-icons">error_outline</i> {{imgErrorMsg}}</small></strong>
                    </p>
                </div>

                <div class="" *ngIf="isMobile">
                    <div>
                        <button class="btn" type="button" (click)="handleMobilePhotoInput()">Browse</button>
                    </div>
                    <p class="text-red-500 italic text-xs" *ngIf="imgErrorMsg">
                        <strong><small><i class="material-icons">error_outline</i> {{imgErrorMsg}}</small></strong>
                    </p>
                </div>
                <div class="flex flex-wrap my-4" *ngIf="showImageFromServer">


                    <div class="relative" *ngIf="oldUrl">
                        <img [src]="oldUrl" class="w-36 h-36 border-2 border-gray-100" alt="Image">
                        <button (click)="removeSavedFile()"
                            class="absolute bottom-0 right-0 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                            <i class="fa fa-trash text-md"></i>
                        </button>
                    </div>

                </div>

            </div> -->

                <div class="">
                    <p class="text-sm font-bold mb-3 mt-4">Form Theme color</p>
                    <a class=" col s12 btn bg-gray-100 border-none hover:bg-gray-200 text-black grey lighten-3 black-text"
                        style="margin-bottom: 15px;">
                        <label>Choose Color </label> <input formControlName="themeColor" type="color"
                            class="themeColorSelector ml-5 h-10 w-10 float-right">
                    </a>
                </div>


                <div class="pt-6 space-y-2 flex justify-between">
                    <div class="">
                        <button type="submit"
                            class="flex space-x-2 px-4 md:px-6 pb-4 text-black rounded-lg w-max h-min text-sm md:text-base font-bold ">
                            <span>Cancel</span>
                        </button>
                    </div>
                    <div class="">
                        <button type="submit" (click)="next()"
                            class="flex space-x-2  px-4 md:px-6 py-2 bg-customTeal text-white rounded-lg w-max h-min text-sm md:text-base font-bold hover:bg-darkBlue focus:outline-none  ">
                            <span>Next</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="nextClicked">
            <!-- <div class="flex flex-col md:w-4/6 bg-white h-max p-2 md:p-12 rounded-lg space-y-4 md:translate-x-1/4">
                <p class="md:ml-4 text-lg">Create Form</p>
            </div> -->

            <!-- <div class="">
                <div class="" formArrayName="sections" (click)="sectionSelected(s)"
                    *ngFor="let section of sectionControls.controls;let s=index">
                    <div
                        class="flex items-center my-4 mx-56 before:flex-1 before:border-t before:border-gray-300 before:mt-0.5 after:flex-1 after:border-t after:border-gray-300 after:mt-0.5">
                        <p class="text-center font-semibold mx-4 mb-0 text-customTeal">Section {{s+1}}</p>
                    </div>
                    <div class="flex flex-col my-4 md:w-4/6 bg-white h-max p-4 md:p-12 rounded-lg space-y-4 md:translate-x-1/4"
                        [formGroupName]='s' [ngClass]="{'currentSection': (currentSection == s)}">
                        <div class="flex justify-end">
                            <a *ngIf="!showFields[s]" class="cursor-pointer flex justify-end" (click)="expandSection(s)"
                                class="col s6 m3 right-align">
                                Show Fields<i class="material-icons prefix blue-text float-right">expand_more</i></a>
                            <a *ngIf="showFields[s]" class="cursor-pointer flex justify-end" (click)="expandSection(s)"
                                class="col s6 m3 right-align">
                                Hide Fields<i class="material-icons prefix blue-text float-right">expand_less</i></a>
                        </div>
                        <br>

                        <div class="flex justify-between">
                            <button (click)="onAddField()" class="px-4 py-2 bg-customTeal text-white rounded-md"><i
                                    class="material-icons text-white float-left">add_circle</i>New Question</button>
                            <div (click)="deleteSection(s)" *ngIf="s != 0" class="mt-2"><i
                                    class="material-icons text-red-800 float-right">delete</i>Remove</div>
                        </div>

                        <div class="flex space-y-2 md:w-full flex-col mb-2">
                            <label class="font-bold text-sm">Section Title: <span class="text-red">*</span></label>
                            <input id="sec_title" type="text" formControlName="sec_title" placeholder="Section title"
                                autocomplete="off"
                                class="px-4 py-2 w-full border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline bg-gray-100 w-full">
                            <div
                                *ngIf="(sectionControls.controls[s].get('sec_title').dirty || sectionControls.controls[s].get('sec_title').touched) && sectionControls.controls[s].get('sec_title').invalid">
                                <small *ngIf="sectionControls.controls[s].get('sec_title').errors.required"
                                    class="text-red-500"> Section Title is required. </small>
                            </div>
                        </div>

                        <div class="flex space-y-2 md:w-full flex-col">
                            <label class="font-bold text-sm">Section Description: </label>
                            <textarea id="statement" class="materialize-textarea" type="text"
                                formControlName="statement"
                                class="h-28 border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline bg-gray-100"></textarea>
                        </div>



                        <div [sortablejs]="getSectionFields(s).controls" [sortablejsOptions]="options"
                            *ngIf="showFields[s]">
                            <div class="col s11 " style="margin-left: 25px;" formArrayName="fields">
                                <h6 *ngIf="getSectionFields(s).controls.lenght >0" class="center-align col s12">Fields
                                </h6>
                                <div [sortablejs]="getSectionFields(s).controls" [sortablejsOptions]="options">
                                    <div class="row col s12 card-panel white"
                                        *ngFor="let field of getSectionFields(s).controls; let f=index">
                                        <div class="flex justify-end" style="font-weight: bold;">
                                            <a class="flex justify-end" (click)="deleteField(f)"><i
                                                    class="material-icons float-left text-red-400">delete</i>Remove</a>
                                        </div>

                                        <div [formGroupName]="f">
                                            <div class="m-2 mb-4">
                                                <label for="name" class="font-bold">Question Name<span
                                                        class="text-red-400">*</span></label>
                                                <input id="name" type="text" formControlName="name"
                                                    placeholder="Question" autocomplete="off"
                                                    class="px-4 py-2 w-full border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline bg-gray-100 w-full">
                                                <div
                                                    *ngIf="(getSectionFields(s).controls[f].get('name').dirty || getSectionFields(s).controls[f].get('name').touched) && getSectionFields(s).controls[f].get('name').invalid">
                                                    <small
                                                        *ngIf="getSectionFields(s).controls[f].get('name').errors.required"
                                                        class="red-text errorMsg"> Field Name is required. </small>
                                                </div>
                                            </div>

                                            <div class="flex w-full justify-between m-2 space-x-4 mb-4">
                                                <div class="flex flex-col w-full">
                                                    <label for="field_type" class="font-bold">Question Type<span
                                                            class="text-red-600">*</span></label>

                                                    <select formControlName="field_type"
                                                        class="px-4 py-3 w-full border rounded text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline bg-gray-100 w-full"
                                                        id="field_type"
                                                        (change)="fieldTypeSelected($event.target.value,f)">
                                                        <option value="" disabled selected>Choose your option</option>
                                                        <option *ngFor="let type of answerTypes" [value]="type">{{type.type}}
                                                        </option>
                                                    </select>
                                                </div>

                                            </div>

                                            
                                            <div class="flex m-2 w-full"><strong>Is the field required?</strong><span class="text-red-500">*</span></div>
                                            <div class="flex flex-col w-1/2">
                                                <div class=" flex justify-between mt-4 mx-4">
                                                    <label class="space-x-2">
                                                        <input type="radio" class="radio checked:bg-customTeal" [value]="true" formControlName="required" />
                                                        <span>Required</span>
                                                    </label>
                                                    <label class="space-x-2">
                                                        <input type="radio" class="radio checked:bg-customTeal" [value]="false" formControlName="required" />
                                                        <span>Optional</span>
                                                    </label>
                                                </div>
                                            
                                            </div>

                                            <div class="flex justify-end">
                                                <input checked id="checked-checkbox" type="checkbox" value="" class="w-4 h-4 text-customTeal bg-gray-100 rounded border-gray-300 focus:ring-customTeal dark:focus:ring-customTeal dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                                <label for="checked-checkbox" class="ml-2 font-medium text-gray-900 dark:text-gray-300">Required</label>
                                            </div>




                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>

                  </div>
                </div>
            </div>
            <div class="flex justify-end text-customTeal mr-56">
                <button (click)="onAddSection()" class="px-4 py-2 border-customTeal border-2 rounded-md"><i
                        class="material-icons text-customTeal float-left pr-2">add_circle</i>Add Section</button>
            </div>
            <br />
            <div class="flex justify-between mx-56">
                <button class="" (click)="previous()">previous</button>
                <button class="btn bg-green-500" type="submit" [disabled]="!form.valid">Create Application</button>

                </div> -->





            <div class="font-body min-h-screen max-h-max pt-6">

                <p class=" font-bold text-xl text-center text-customTeal my-2">Informations to be included in the
                    Subscribers' Profile
                </p>

                <div class="flex flex-col md:w-4/6 m-2 bg-white h-max p-4 md:p-12 rounded-lg space-y-4 md:translate-x-1/4"
                    *ngIf="!formExists">
                    <p class="my-3 text-center mx-2 md:mx-10 text-md"> You can add as many questions as you like. You
                        can ask a question with a format open ended question, Date and also multiple choice below.</p>
                    <hr />
                    <form [formGroup]="skillsForm" (ngSubmit)="submitQuestions(skillsForm.value)">
                        <div formArrayName="skills">
                            <div *ngFor="let skill of skills.controls; let i=index">
                                <div [formGroupName]="i">

                                    <div class="flex justify-end px-4 py-2 mt-2 bg-customTeal rounded-full  w-10 "
                                        *ngIf="i > 0">
                                        <a class="text-center" (click)="removeFile(i)"><i
                                                class="material-icons text-white justify-center items-center ml-4">clear</i></a>
                                    </div>

                                    <div class="flex space-y-2 md:w-full  flex-col mt-2">
                                        <label class="font-bold text-sm">Question {{i+1}}: <span
                                                class="text-red-500">*</span>
                                        </label>
                                        <input
                                            class=" border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline "
                                            id="question " type="text " placeholder="Question"
                                            formControlName="question">
                                        <div *ngIf="(skill.controls.question.dirty ||
                            skill.controls.question.touched) &&
                            skill.controls.question.invalid">
                                            <small *ngIf="skill.controls.question.errors.required" class="text-red-500">
                                                The
                                                Question is required.
                                            </small>
                                        </div>
                                    </div>
                                    <br />

                                    <div class="flex space-y-2 md:w-full flex-col mb-4">
                                        <label class="font-bold text-sm">Question Type: <span
                                                class="text-red-500">*</span> </label>
                                        <select formControlName="answerType" required
                                            class="validate py-3 px-4 select select-bordered w-full bg-veryLightGray"
                                            (change)="answerTypeChange($event.target.value, i)">
                                            <option value="" disabled selected>Please Select Answer Type</option>
                                            <option *ngFor="let answer of answerTypes" [value]="answer.type"
                                                title="Tooltip">
                                                {{answer.type}}
                                                <span class="text-green-400"> ( {{answer.helpText}} )</span>
                                            </option>
                                        </select>

                                        <div *ngIf="(skill.controls.answerType.dirty ||
                                        skill.controls.answerType.touched) &&
                                        skill.controls.answerType.invalid">
                                            <small *ngIf="skill.controls.answerType.errors.required"
                                                class="text-red-500"> The
                                                Answer Type is required.
                                            </small>
                                        </div>
                                    </div>

                                    <div class="flex space-x-8 my-8" *ngIf="skill.controls.answerType.value === 'Scale'"
                                        formGroupName="scales">
                                        <div>
                                            <label for="start" class="font-bold">Start At</label>
                                            <select formControlName="start"
                                                class="validate py-1 px-4 select select-bordered w-full bg-veryLightGray">
                                                <option value="0" class="px-2 py-4 text-center hover:bg-customTeal">0
                                                </option>
                                                <option value="1" class="px-2 py-4 text-center hover:bg-customTeal">1
                                                </option>
                                            </select>
                                        </div>

                                        <div>
                                            <label for="startLabel" class="font-bold">Label</label>
                                            <input id="startLabel" type="text" formControlName='startLabel'
                                                class="w-full border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline" />
                                        </div>

                                        <div>
                                            <label for="end" class="font-bold">End At</label>
                                            <select formControlName="end"
                                                class="py-1 px-4 select select-bordered w-full bg-veryLightGray">
                                                <option *ngFor="let s of scales" [value]="s">{{s}}</option>
                                            </select>
                                        </div>

                                        <div>
                                            <label for="endLabel" class="font-bold">Label</label>
                                            <input id="endLabel" type="text" formControlName='endLabel'
                                                class="w-full border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline" />
                                        </div>
                                    </div>

                                    <div *ngIf="skill.controls.answerType.value === 'Multiple Choice'">

                                        <div class="row input-field col s12 m5 right">
                                            <label class="flex justify-end">
                                                <input class="flex justify-end checkbox checked:bg-customTeal"
                                                    type="checkbox" formControlName="allowMultipleAnswers" />
                                                <span>Allow Multiple Answers</span>
                                            </label>
                                        </div>
                                        <br />
                                        <div class="row input-field col s12" formArrayName="answers"
                                            *ngFor="let ans of getAnswers(i).controls; let j = index;">
                                            <div [formGroupName]="j">

                                                <div class="row input-field col s11">
                                                    <span class="font-bold text-sm">Choice {{j+1}}: </span>
                                                    <input id="answer" type="text" formControlName="answer" required
                                                        class="validate border mb-2 rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline "
                                                        autocomplete="off" style="text-transform: capitalize;"
                                                        placeholder="Answer" />
                                                    <a *ngIf="j > 1"
                                                        class="btn-flat waves-effect waves-light col s1 right"><i
                                                            class="material-icons text-red-500"
                                                            (click)="removeAnswer(i, j)">delete</i></a>
                                                    <div
                                                        *ngIf="(ans.controls.answer.dirty || ans.controls.answer.touched) && ans.controls.answer.invalid">
                                                        <small *ngIf="ans.controls.answer.errors.required"
                                                            class="text-red-500">
                                                            Answer {{j+1}} is
                                                            required.</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row col s12">
                                            <button type="button" class="mb-4 bg-customTeal px-4 py-2 rounded-md"
                                                (click)="onAddAnswer(i)"
                                                [disabled]="!skillsForm.get('skills').controls[i].get('answers').valid"
                                                style="cursor: pointer;">
                                                <i class="material-icons text-white float-left">add_circle</i><span
                                                    class="text-white">Add Option</span>
                                            </button>
                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>

                        <div class="flex justify-end">
                            <button type="button" (click)="addQuestion()" [disabled]="!skillsForm.valid"
                                class="flex space-x-2 border-2 border-customTeal px-4 md:px-6 py-2 text-customTeal rounded-lg w-max h-min text-sm md:text-base font-bold focus:outline-none  ">
                                <i class="material-icons text-customTeal">add_circle</i><span>Add Questions</span>
                            </button>
                        </div>


                        <div class="pt-6 space-y-2 flex justify-between">
                            <div class="">
                            </div>
                            <div class="">
                                <button type="submit" [disabled]="!skillsForm.valid"
                                    class="flex space-x-2  px-4 md:px-6 py-2 bg-customTeal text-white rounded-lg w-max h-min text-sm md:text-base font-bold hover:bg-darkBlue focus:outline-none  ">
                                    <span>Save Questions</span>
                                </button>
                            </div>
                        </div>
                    </form>

                </div>

                <div>

                </div>
                <app-alert class="fixed top-0 flex justify-end w-full" [toastType]="toastType"
                    [toastMessage]="toastMessage" [ngClass]="{'visible': openToast, 'invisible': !openToast}">
                </app-alert>
            </div>

        </div>

    </form>
</div>



<!-- <div class="font-body min-h-screen max-h-max pt-6">

    <p class=" font-bold text-xl text-center text-customTeal my-2">Informations to be included in the Clients' Profile
    </p>

    <div class="flex flex-col md:w-4/6 m-2 bg-white h-max p-4 md:p-12 rounded-lg space-y-4 md:translate-x-1/4" *ngIf="!formExists">
        <p class="my-3 text-center mx-2 md:mx-10 text-md"> You can add as many questions as you like. You can ask a question
            with a format open ended question, Date and also multiple choice below.</p>
            <hr />
        <form [formGroup]="skillsForm" (ngSubmit)="submitQuestions(skillsForm.value)">
            <div formArrayName="skills">
                <div *ngFor="let skill of skills.controls; let i=index">
                    <div [formGroupName]="i">

                        <div class="flex justify-end px-4 py-2 mt-2 bg-customTeal rounded-full  w-10 " *ngIf="i > 0">
                            <a class="text-center flex justify-center" 
                                (click)="removeFile(i)"><i class="material-icons text-white justify-center items-center ml-4">clear</i></a>
                        </div>     
                        <div class="flex space-y-2 md:w-full  flex-col mt-2">
                            <label class="font-bold text-sm">Question {{i+1}}: <span class="text-red-500">*</span>
                            </label>
                            <input
                                class=" border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline "
                                id="question " type="text " placeholder="Question" formControlName="question">
                            <div *ngIf="(skill.controls.question.dirty ||
                            skill.controls.question.touched) &&
                            skill.controls.question.invalid">
                                <small *ngIf="skill.controls.question.errors.required" class="text-red-500"> The
                                    Question is required.
                                </small>
                            </div>
                        </div>
                        <br />

                        <div class="flex space-y-2 md:w-full flex-col mb-4">
                            <label class="font-bold text-sm">Question Type: <span class="text-red-500">*</span> </label>
                            <select formControlName="answerType" required
                                class="validate py-3 px-4 select select-bordered w-full bg-veryLightGray"
                                (change)="answerTypeChange($event.target.value, i)">
                                <option value="" disabled selected>Please Select Answer Type</option>
                                <option *ngFor="let answer of answerTypes" [value]="answer.type" title="Tooltip">
                                    {{answer.type}}
                                    <span class="text-green-400"> ( {{answer.helpText}} )</span>
                                </option>
                            </select>

                            <div *ngIf="(skill.controls.answerType.dirty ||
                                        skill.controls.answerType.touched) &&
                                        skill.controls.answerType.invalid">
                                <small *ngIf="skill.controls.answerType.errors.required" class="text-red-500"> The
                                    Answer Type is required.
                                </small>
                            </div>
                        </div>

                        <div class="flex space-x-8 my-8" *ngIf="skill.controls.answerType.value === 'Scale'"
                            formGroupName="scales">
                            <div>
                                <label for="start" class="font-bold">Start At</label>
                                <select formControlName="start"
                                    class="validate py-1 px-4 select select-bordered w-full bg-veryLightGray">
                                    <option value="0" class="px-2 py-4 text-center hover:bg-customTeal">0</option>
                                    <option value="1" class="px-2 py-4 text-center hover:bg-customTeal">1</option>
                                </select>
                            </div>

                            <div>
                                <label for="startLabel" class="font-bold">Label</label>
                                <input id="startLabel" type="text" formControlName='startLabel'
                                    class="w-full border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline" />
                            </div>

                            <div>
                                <label for="end" class="font-bold">End At</label>
                                <select formControlName="end"
                                    class="py-1 px-4 select select-bordered w-full bg-veryLightGray">
                                    <option *ngFor="let s of scales" [value]="s">{{s}}</option>
                                </select>
                            </div>

                            <div>
                                <label for="endLabel" class="font-bold">Label</label>
                                <input id="endLabel" type="text" formControlName='endLabel'
                                    class="w-full border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline" />
                            </div>
                        </div>

                        <div *ngIf="skill.controls.answerType.value === 'Multiple Choice'">

                            <div class="row input-field col s12 m5 right">
                                <label class="flex">
                                    <input class="flex justify-end checkbox checked:bg-customTeal" type="checkbox"
                                        formControlName="allowMultipleAnswers" />
                                    <span>Allow Multiple Answers</span>
                                </label>
                            </div>
                            <br />
                            <div class="row input-field col s12" formArrayName="answers"
                                *ngFor="let ans of getAnswers(i).controls; let j = index;">
                                <div [formGroupName]="j">
                                    {{j}}
                                    <div class="row input-field col s11">
                                        <span class="font-bold text-sm">Choice {{j+1}}: </span>
                                        <input id="answer" type="text" formControlName="answer" required
                                            class="validate border mb-2 rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline "
                                            autocomplete="off" style="text-transform: capitalize;"
                                            placeholder="Answer" />
                                        <a *ngIf="j > 1" class="btn-flat waves-effect waves-light col s1 right"><i
                                                class="material-icons text-red-500"
                                                (click)="removeAnswer(i, j)">delete</i></a>
                                        <div
                                            *ngIf="(ans.controls.answer.dirty || ans.controls.answer.touched) && ans.controls.answer.invalid">
                                            <small *ngIf="ans.controls.answer.errors.required" class="text-red-500">
                                                Answer {{j+1}} is
                                                required.</small>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row col s12">
                                <button type="button" class="mb-4 bg-customTeal px-4 py-2 rounded-md"
                                    (click)="onAddAnswer(i)"
                                    [disabled]="!skillsForm.get('skills').controls[i].get('answers').valid"
                                    style="cursor: pointer;">
                                    <i class="material-icons text-white float-left">add_circle</i><span
                                        class="text-white">Add Option</span>
                                </button>
                            </div>
                        </div>



                    </div>
                </div>
            </div>

            <div class="flex justify-end">
                <button type="button" (click)="addQuestion()" [disabled]="!skillsForm.valid"
                    class="flex space-x-2 border-2 border-customTeal px-4 md:px-6 py-2 text-customTeal rounded-lg w-max h-min text-sm md:text-base font-bold focus:outline-none  ">
                    <i class="material-icons text-customTeal">add_circle</i><span>Add Questions</span>
                </button>
            </div>


            <div class="pt-6 space-y-2 flex justify-between">
                <div class="">
                </div>
                <div class="">
                    <button type="submit" [disabled]="!skillsForm.valid"
                        class="flex space-x-2  px-4 md:px-6 py-2 bg-customTeal text-white rounded-lg w-max h-min text-sm md:text-base font-bold hover:bg-darkBlue focus:outline-none  ">
                        <span>Save Questions</span>
                    </button>
                </div>
            </div>
        </form>

    </div>

    <div>
        
    </div>
    <app-alert class="fixed top-0 flex justify-end w-full" [toastType]="toastType" [toastMessage]="toastMessage"
        [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>
</div> -->


<!-- <form [formGroup]="skillsForm" (ngSubmit)="onSubmit()">
    Skills:
    <div formArrayName="skills">
      <div *ngFor="let skill of skills.controls; let i=index">
        <div [formGroupName]="i">
          {{i}}
          skill name :
          <input type="text" formControlName="skill">
          exp:
          <input type="text" formControlName="exp">
   
          <button (click)="removeSkill(i)">Remove</button>
        </div>
      </div>
    </div>
   
    <p>
      <button type="submit">Submit</button>
    </p>
   
  </form>
  <p>
    <button type="button" (click)="addSkills()">Add</button>
  </p> -->

<!-- toast -->
<app-alert class="fixed top-0 flex justify-end w-full" [toastType]="toastType" [toastMessage]="toastMessage"
[ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>
