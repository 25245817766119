import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from "@angular/fire/compat/firestore";

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  constructor(private db: AngularFirestore) { }
  createEmployeeSchedule(model: any){
    const newContent = {
      id: this.db.createId(),
      employeeId: model.employeeId,
      schedule: model.schedule,
    };
    return this.db.collection('EmployeeSchedule').doc(newContent.id).set(newContent);
  }

  getScheduleByEmployeeId(employeeId){
    return this.db.collection('EmployeeSchedule', ref => ref.where('employeeId', '==', employeeId));
  }

  updateSchedule(model:any){
    return this.db.collection('EmployeeSchedule').doc(model.id).update(model)
  }

  getAllSchedules(){
    return this.db.collection('EmployeeSchedule');
  }
}
