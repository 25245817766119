<!-- new design for SEO -->
<div class="flex flex-col justify-center gap-y-4 pt-6">
  <!-- Step 1 -->
  <div class="w-full flex justify-center">
    <ul class="steps steps-horizontal">
      <li class="step " [ngClass]="{'step-primary': seoStep==0 }" (click)="seoStep=0" >Basic Info</li>
      <li class="step " [ngClass]="{'step-primary': seoStep==1 }" (click)="nextStep(1)"> Generated Question</li>
      <li class="step"  [ngClass]="{'step-primary': seoStep==2 }" (click)="nextStep(2)">Generated Content</li>
    </ul>
  </div>

  <!-- Step 2 -->
   <div class="md:mx-10 mx-4 bg-white md:py-8 md:px-12 p-4 shadow-md rounded-md border border-solid border-gray-200">
    <form [formGroup]="appSettingsForm" (ngSubmit)="confirmUpdate(appSettingsForm.value)">
    <!-- 0 -->
    <div *ngIf="seoStep == 0">
      <div class="flex flex-col space-y-4">
        <div class="flex flex-col">
          <label for="appTitle" class="block tracking-wide text-gray-700 text-base font-bold mb-2">
            <div class="flex space-x-4 items-center">
        
              <svg width="36" height="36" viewBox="0 0 55 55" fill="blue"  class="text-gray-400 " xmlns="http://www.w3.org/2000/svg">
                <path d="M9 12H45.6667V16.5833H9V12ZM9 21.1667H31.9167V25.75H9V21.1667Z" fill="black"/>
                <rect x="9" y="30.3333" width="22.9167" height="4.58333" fill="black"/>
                <path d="M44.4785 22.375C44.0226 22.375 43.5855 22.5561 43.2631 22.8784C42.9408 23.2007 42.7597 23.6379 42.7597 24.0938C42.7597 24.5496 42.9408 24.9868 43.2631 25.3091C43.5855 25.6314 44.0226 25.8125 44.4785 25.8125C44.9343 25.8125 45.3715 25.6314 45.6938 25.3091C46.0162 24.9868 46.1972 24.5496 46.1972 24.0938C46.1972 23.6379 46.0162 23.2007 45.6938 22.8784C45.3715 22.5561 44.9343 22.375 44.4785 22.375ZM44.066 27.8406C42.7024 27.9552 38.9785 30.9229 38.9785 30.9229C38.7493 31.0948 38.8181 31.0833 39.0014 31.4042C39.1847 31.7135 39.1618 31.7365 39.3795 31.5875C39.6087 31.4385 39.9868 31.1979 40.617 30.8083C43.0462 29.25 41.0066 32.8479 39.9639 38.9094C39.5514 41.9115 42.2556 40.3646 42.9545 39.9063C43.642 39.4594 45.4868 38.1875 45.6701 38.0615C45.9222 37.8896 45.7389 37.7521 45.5441 37.4656C45.4066 37.2708 45.2691 37.4083 45.2691 37.4083C44.5243 37.901 43.1608 38.9323 42.9774 38.2792C42.7597 37.626 44.1576 33.1458 44.9254 30.0635C45.0514 29.3302 45.3951 27.726 44.066 27.8406V27.8406Z" fill="black"/>
                <rect x="9" y="38.3542" width="22.9167" height="4.58333" fill="black"/>
                </svg>
  
              <div class="w-10/12 flex space-x-2">
                <p class="font-bold text-lg">Website Title <span class="text-red-500 text-sm">*</span></p>
                     <!-- Website Title -->
                <div class="tooltip" data-tip="This is what will show to the public">
                  <button type="button"><i class="material-icons text-sm">help</i></button>
                </div>
                <!-- <p class="text-xs text-gray-500">This is the first part of your post that your readers will see and the part that's responsible for getting them to click through and read the rest.</p> -->
              </div>
          </div>
            
       
          </label>
          <input
            class="w-full px-3 pb-[6px] pt-2 lg:w-8/12 appearance-none block  text-gray-700 border border-solid border-gray-300 rounded  mb-3 leading-tight focus:outline-none focus:bg-gray-50 focus:border-customTeal"
            id="appTitle" type="text" placeholder="Website title" formControlName="appTitle">
      
        </div>
        <div class="input-field w-full">
          <label for="appTitle" class="block tracking-wide text-gray-700 text-base font-bold mb-2">
            <div class="flex space-x-4 items-center">
              <svg width="36" height="36" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" >
                <path d="M4 37H16.3357V24.5531H4V37ZM7.08574 27.6667H13.25V33.8865H7.08574V27.6667ZM19.4143 35.4469H41V32.3333H19.4143V35.4469ZM4 21.4469H16.3357V9H4V21.4469ZM7.08574 12.1135H13.25V18.3333H7.08574V12.1135V12.1135ZM19.4143 10.5531V13.6667H41V10.5531H19.4143V10.5531ZM19.4143 29.2198H41V26.1062H19.4143V29.2198ZM19.4143 19.8865H41V16.7729H19.4143V19.8865Z" fill="black"/>
                </svg>
                
                <div class="w-10/12 flex space-x-2">
                  <p class="font-bold text-lg">Tell us about ur business<span class="text-red-500 text-sm">*</span></p>
                        <!-- Tell us about ur business -->
                  <div class="tooltip" data-tip="This is what will show to the public">
                    <button type="button"><i class="material-icons text-sm">help</i></button>
                  </div>
                  <!-- <p class="text-xs text-gray-500">Discription/Content of your post in the form of text </p> -->
                </div>
            </div>
            
      
          </label>
          <app-editor [control]="description" class="bg-gray-100" [inputType]="'description'"></app-editor>
  
          <!-- <textarea maxlength="150" formControlName="keywordSEO"
            class="h-28 border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline w-full"
            type="text" placeholder="title, description and keywords goes here..."></textarea> -->
        </div>
  
        <div class="flex w-full justify-end">
         
          <button type="button" (click)="suggest(description.value)"
          class="inline-block rounded justify-end bg-customTeal text-white px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-brandcotext-customTeal-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-brandcotext-customTeal-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-brandcotext-customTeal-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"        
          >Submit</button></div>
        </div>
    </div>

    <!-- 1 -->
    <div *ngIf="seoStep == 1">
      <div class="">
        <div *ngIf="questionArray[0]" class="font-bold text-base text-gray-500 flex flex-col pb-6">We will ask you more questions to understand about your business based on what you inputed above</div>

        <div formArrayName="answers">
          <div *ngFor="let option of flatArray; let i = index">
            <p class="text-base">{{ i + 1 }}. {{ option }}</p>
            <textarea
              class="w-full px-3 pb-[6px] pt-2 lg:w-8/12 appearance-none block text-gray-700 border border-solid border-gray-300 rounded mb-3 leading-tight focus:outline-none focus:bg-gray-50 focus:border-customTeal"
              type="text"
              placeholder="Answer"
              [formControlName]="i"  
              [required]="true"
            ></textarea>
          </div>
        </div>
      </div>
      <div >
       
        <div class="flex w-full items-center space-x-4 justify-end">
          <button data-te-ripple-inittype="button"
          class="inline-block rounded bg-white text-customTeal border-2 border-customTeal px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-customTealborder-customTeal transition duration-150 ease-in-out hover:border-customTeal-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-customTealborder-customTeal-600 focus:border-customTeal-600 focus:text-customTealborder-customTeal-600 focus:outline-none focus:ring-0 active:border-customTeal-700 active:text-customTealborder-customTeal-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
          (click)="seoStep=0 && reset()"
          data-te-ripple-color="light">
              Previous
      </button><button *ngIf="saveButton" type="button" (click)="saveSuggestion()" [disabled]="answers.length === 0"
          class="inline-block rounded justify-end bg-customTeal text-white px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-brandcotext-customTeal-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-brandcotext-customTeal-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-brandcotext-customTeal-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"        
           >Submit</button></div>

      </div>
    </div>

    <!-- 2 -->
    <div *ngIf="seoStep == 2">
      <div class="space-y-4">
        <div class="flex flex-col">
          <p class="font-bold text-base text-gray-500 " *ngIf="generateDescription">Description:</p >
            <p class="text-base">{{generateDescription}}</p>
        </div>
             <div>
              
              <p class="font-bold text-base text-gray-500 " *ngIf="generateKeyword">Keyword:</p>
              <p class="text-base">{{generateKeyword}}</p>
              </div>


                              <!-- Chatgpt keywords -->
                              <div class="my-2">
                                <label class="label">
                                  <span class="block tracking-wide text-gray-700 text-base font-bold mb-2">Key words</span>
                                </label>
                                <!-- key words list -->
                                <div class="flex flex-wrap" *ngIf="generateKeyword.length > 0">
                                  <div *ngFor="let keyWord of generateKeyword;let i=index">
                                    <button type="button" class="border-2 border-customTeal rounded-full py-1 px-4 mx-2 my-2">
                                      <span>{{keyWord}}</span>
                                      <span (click)="removeKeyWord(i)">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline" fill="none" viewBox="0 0 24 24"
                                          stroke="currentColor" stroke-width="2">
                                          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                    
                                      </span>
                    
                                    </button>
                                  </div>
                                </div>
                                <div class="flex flex-row" *ngIf="generateKeyword.length < maxKeyWords">
                                  <input type="text" formControlName="keyWordQuery" placeholder="Keyword"
                                    class="input input-bordered lg:w-1/3 w-2/3" />
                                   
                                  <button type="button" class=" bg-customTeal flex items-center text-white px-3 border-l-0"
                                    (click)="selectKeyWord()">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 inline stroke-white" fill="none"
                                      viewBox="0 0 24 24" stroke-width="2">
                                      <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                    </svg>
                                    <p class="text-white text-base">Add Keyword</p>
                                  </button>
                                </div>
                    
                              </div>
                              <hr>
              
                              <!-- Chatgpt hashTag -->
                              <div class="my-2">
                                <label class="label">
                                  <span class="block tracking-wide text-gray-700 text-base font-bold mb-2">HashTags</span>
                                </label>
                                <!-- Hash Tags list -->
                                <div class="flex flex-wrap" *ngIf="generateHashTag.length > 0">
                                  <div *ngFor="let hashTag of generateHashTag;let i=index">
                                    <button type="button" class="border-2 border-customTeal rounded-full py-1 px-4 mx-2 my-2">
                                      <span>{{hashTag}}</span>
                                      <span (click)="removeHashTags(i)">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline" fill="none" viewBox="0 0 24 24"
                                          stroke="currentColor" stroke-width="2">
                                          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                    
                                      </span>
                    
                                    </button>
                                  </div>
                                </div>
                                <div class="flex flex-row" *ngIf="generateHashTag.length < maxHashTags">
                                  <input id="text" type="text" formControlName="HashTagsQuery" placeholder="HashTags"
                                    class="input input-bordered lg:w-1/3 w-2/3" />
                                    {{HashTagsQuery.value}}
                                  <button type="button" class=" bg-customTeal flex items-center text-white px-3 border-l-0"
                                    (click)="selectHashTags()">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 inline stroke-white" fill="none"
                                      viewBox="0 0 24 24" stroke-width="2">
                                      <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                    </svg>
                                    <p class="text-white text-base">Add HashTags</p>
                                  </button>
                                </div>
                    
                              </div>


              <div>
              <p class="font-bold text-base text-gray-500 " *ngIf="generateHashTag">HashTag:</p>
                <p class="text-base">  {{generateHashTag}}</p>
             </div>
      <div class="flex w-full items-center space-x-4 justify-end">
        <button data-te-ripple-inittype="button"
          class="inline-block rounded bg-white text-customTeal border-2 border-customTeal px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-customTealborder-customTeal transition duration-150 ease-in-out hover:border-customTeal-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-customTealborder-customTeal-600 focus:border-customTeal-600 focus:text-customTealborder-customTeal-600 focus:outline-none focus:ring-0 active:border-customTeal-700 active:text-customTealborder-customTeal-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
          (click)="seoStep=1"
          data-te-ripple-color="light">
              Previous
      </button><button  type="button" *ngIf="saveButton" (click)="saveAllSuggestions()"
        class="inline-block rounded justify-end bg-customTeal text-white px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-brandcotext-customTeal-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-brandcotext-customTeal-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-brandcotext-customTeal-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"        
        >Save</button></div>
      </div>
    </div>

    </form>

   </div>
</div>


<app-edit-confirmation *ngIf="editModalOpen" (cancelClicked)=closeEditModal() (saveBtnClicked)=SaveSettings()
  editedFromComponent='App Setup'></app-edit-confirmation>
<!-- toast -->
<app-alert class="fixed top-0 flex justify-end w-full" [toastType]="toastType" [toastMessage]="toastMessage"
  [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>
