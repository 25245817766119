<div class="p-5 mx-3 mt-3 bg-white rounded-md ">

  <form [formGroup]="apptSettingsForm" (ngSubmit)="saveAppointmentSettings(apptSettingsForm.value)">

    <div class="">
      <!-- Title -->
      <div class="text-lg font-bold text-gray-600 pb-4">
        Appointment Settings
        <div class="tooltip" data-tip="Change appointment settings.">
          <button type="button"><i class="material-icons">help</i></button>
        </div>
      </div>

      <!-- Settings Content -->
      <div class=" flex gap-4 flex-wrap ">
        <!-- can Reschedule -->
        <div class="flex flex-col w-full md:w-[300px] bg-white shadow-md border border-solid rounded-md border-gray-200 px-4 py-4">
          <div class="flex items-center w-full ">
            <!-- <div> -->
              <div class="text-sm font-bold">Enable clients to reschedule appointments?</div>
              <!-- <p class="hidden md:block">The client will be able to reschedule their appointment.</p> -->
            <!-- </div> -->
          </div>

          <div class="flex  w-full ">
            <!-- toggle -->
            <div>
              <div class="form-control">
                <label class="cursor-pointer label">
                  <span class="pr-2 font-bold label-text ">YES</span>
                  <input type="checkbox" class="toggle checked:bg-customTeal" checked formControlName="canReschedule" />

                </label>
              </div>
            </div>
          </div>

        </div>
       

        <!-- reschedule hour -->
        <div class="flex flex-col w-full md:w-[300px] bg-white shadow-md border border-solid rounded-md border-gray-200 px-4 py-4" *ngIf="canReschedule.value">
          <div class="flex items-center w-full ">
            <div>
              <div class="text-sm font-bold">Window of time within which client can reschedule an appointment?<span class="text-red-500">*</span></div>
              <!-- <p class="hidden md:block">The time where the client can reschedule without additional fee.</p> -->
            </div>
          </div>
          <div class="flex flex-col justify-start w-full ">
          <div class="flex space-x-2">
            
            <div class="flex w-full ">
              <div>
                <div class="form-control">
                  <label class="cursor-pointer label">
                    <input type="radio" class="radio checked:bg-customTeal" formControlName="rescheduleOption"
                      value="Days" (change)="onRescheduleChange('Days')" />
                    <span class="pl-2 font-bold label-text ">Days</span>
                  </label>
                </div>
              </div>
            </div>

            <div class="flex w-full ">
              <div>
                <div class="form-control">
                  <label class="cursor-pointer label">
                    <input type="radio" class="radio checked:bg-customTeal" formControlName="rescheduleOption"
                      value="Hours" (change)="onRescheduleChange('Hours')" />
                    <span class="pl-2 font-bold label-text ">Hours</span>
                  </label>
                </div>
              </div>
            </div>

          </div>
            <div class="flex w-full ">
              <div *ngIf="rescheduleOption=='Hours'">
                <div class="form-control">
                  <label class="cursor-pointer label">
                    <select formControlName="canRescheduleBeforeHr"
                      class="w-full select select-bordered bg-veryLightGray">
                      <option disabled selected>Select Hour</option>
                      <option value={{hour}}
                        *ngFor="let hour of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]">{{hour}}
                        {{hour==1 ? 'hour' : 'hours'}}</option>
                    </select>
                  </label>
                </div>
              </div>
              <div *ngIf="rescheduleOption=='Days'">
                <div class="form-control">
                  <label class="cursor-pointer label">
                    <select formControlName="canRescheduleBeforeHr"
                      class="w-full select select-bordered bg-veryLightGray">
                      <option disabled selected>Select Days</option>
                      <option value={{day*24}} *ngFor="let day of [1,2,3,4,5,6,7]">{{day}} {{day==1 ? 'day' : 'days'}}
                      </option>
                    </select>
                  </label>
                </div>
              </div>
              <!-- <div class="form-control">
                <label class="cursor-pointer label">
                  <input type="number" required min="1" max="72" class="input input-bordered" checked
                    formControlName="canRescheduleBeforeHr"
                    [ngClass]="{
                      'input input-bordered': true,
                      'border-red-500': (canRescheduleBeforeHr.dirty || canRescheduleBeforeHr.touched) && canRescheduleBeforeHr.invalid,
                      'border-gray-200': !((canRescheduleBeforeHr.dirty || canRescheduleBeforeHr.touched) && canRescheduleBeforeHr.invalid)
                    }" />
                </label>
              </div> -->
            </div>
          </div>

        </div>
       

        <!-- Can Cancel -->
        <div class="flex flex-col w-full md:w-[300px] bg-white shadow-md border border-solid rounded-md border-gray-200 px-4 py-4">
          <div class="flex items-center w-full ">
            <div>
              <div class="text-sm font-bold">Enable clients to cancel appointment?</div>
              <!-- <p class="hidden md:block">The client will be able to cancel their appointment without a fee before
                specified time.</p> -->
            </div>
          </div>

          <div class="flex  w-full ">
            <!-- toggle -->
            <div>
              <div class="form-control">
                <label class="cursor-pointer label">
                  <span class="pr-2 font-bold label-text ">YES</span>
                  <input type="checkbox" class="toggle checked:bg-customTeal" checked formControlName="canCancel" />

                </label>
              </div>
            </div>
          </div>

        </div>
       

        <!-- cancellation hour -->
        <div class="flex flex-col w-full md:w-[300px] bg-white shadow-md border border-solid rounded-md border-gray-200 px-4 py-4" *ngIf="canCancel.value">
          <div class="flex items-center w-full ">
            <div>
              <div class="text-sm font-bold">Window of time within which client can cancel appointments  <span
                  class="text-red-500">*</span></div>
              <!-- <p class="hidden md:block">The time where the client can reschedule without additional fee.</p> -->
            </div>
          </div>
          <div class="flex flex-col justify-start w-full  ">

       <div class="flex space-x-2">
        <div class="flex w-full ">
          <div>
            <div class="form-control">
              <label class="cursor-pointer label">
                <input type="radio" class="radio checked:bg-customTeal" formControlName="cancelOption" value="Days"
                  (change)="onCancelChange('Days')" />
                <span class="pl-2 font-bold label-text ">Days</span>
              </label>
            </div>
          </div>
        </div>

        <div class="flex w-full ">
          <div>
            <div class="form-control">
              <label class="cursor-pointer label">
                <input type="radio" class="radio checked:bg-customTeal" formControlName="cancelOption" value="Hours"
                  (change)="onCancelChange('Hours')" />
                <span class="pl-2 font-bold label-text ">Hours</span>
              </label>
            </div>
          </div>
        </div>
       </div>

            <div class="flex w-full ">
              <div *ngIf="cancelOption=='Hours'">
                <div class="form-control">
                  <label class="cursor-pointer label">
                    <select formControlName="canCancelBeforeHr" class="w-full select select-bordered bg-veryLightGray">
                      <option disabled selected>Select Hour</option>
                      <option value={{hour}}
                        *ngFor="let hour of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]">{{hour}}
                        {{hour==1 ? 'hour' : 'hours'}}</option>
                    </select>
                  </label>
                </div>
              </div>
              <div *ngIf="cancelOption=='Days'">
                <div class="form-control">
                  <label class="cursor-pointer label">
                    <select formControlName="canCancelBeforeHr" class="w-full select select-bordered bg-veryLightGray">
                      <option disabled selected>Select Days</option>
                      <option value={{day*24}} *ngFor="let day of [1,2,3,4,5,6,7]">{{day}} {{day==1 ? 'day' : 'days'}}
                      </option>
                    </select>
                  </label>
                </div>
              </div>
              <!-- <div class="form-control">
                <label class="cursor-pointer label">
                  <input type="number" required min="1" max="72" class="input input-bordered" checked
                    formControlName="canCancelBeforeHr"
                    [ngClass]="{
                      'input input-bordered': true,
                      'border-red-500': (canCancelBeforeHr.dirty || canCancelBeforeHr.touched) && canCancelBeforeHr.invalid,
                      'border-gray-200': !((canCancelBeforeHr.dirty || canCancelBeforeHr.touched) && canCancelBeforeHr.invalid)
                    }" />
                </label>
              </div> -->
            </div>
          </div>

        </div>
       

        <!-- Email Confirmation -->
        <div class="flex flex-col w-full md:w-[300px] bg-white shadow-md border border-solid rounded-md border-gray-200 px-4 py-4">
          <div class="flex items-center w-full ">
            <div>
              <div class="text-sm font-bold">Send confirmation email to clients?</div>
              <!-- <p class="hidden md:block">The client will recieve an email confirming details of the scheduled session. -->
              <!-- </p> -->
            </div>
          </div>

          <div class="flex  w-full ">
            <!-- toggle -->
            <div>
              <div class="form-control">
                <label class="cursor-pointer label">
                  <span class="pr-2 font-bold label-text ">YES</span>
                  <input type="checkbox" class="toggle checked:bg-customTeal" checked
                    formControlName="sendEmailConfirmation" />

                </label>
              </div>
            </div>
          </div>

        </div>
       

        <!-- SMS Confirmation -->
        <div class="flex flex-col w-full md:w-[300px] bg-white shadow-md border border-solid rounded-md border-gray-200 px-4 py-4">
          <div class="flex items-center w-full ">
            <div>
              <div class="text-sm font-bold">Send confirmation SMS to clients ?</div>
              <!-- <p class="hidden md:block">The client will recieve confirmation SMS about scheduled session.</p> -->
            </div>
          </div>

          <div class="flex  w-full ">
            <!-- toggle -->
            <div>
              <div class="form-control">
                <label class="cursor-pointer label">
                  <span class="pr-2 font-bold label-text ">YES</span>
                  <input type="checkbox" class="toggle checked:bg-customTeal" checked
                    formControlName="sendSMSConfirmation" />

                </label>
              </div>
            </div>
          </div>

        </div>
       

        <!-- Reminder Email -->
        <div class="flex flex-col w-full md:w-[300px] bg-white shadow-md border border-solid rounded-md border-gray-200 px-4 py-4">
          <div class="flex items-center w-full ">
            <div>
              <div class="text-sm font-bold">Send reminder email to clients?</div>
              <!-- <p class="hidden md:block">The client will recieve a reminder of a scheduled session.</p> -->
            </div>
          </div>

          <div class="flex  w-full ">
            <!-- toggle -->
            <div>
              <div class="form-control">
                <label class="cursor-pointer label">
                  <span class="pr-2 font-bold label-text ">YES</span>
                  <input type="checkbox" class="toggle checked:bg-customTeal" checked
                    formControlName="sendEmailReminder" />

                </label>
              </div>
            </div>
          </div>

        </div>
       



        <!-- SMS Reminder -->
        <div class="flex flex-col w-full md:w-[300px] bg-white shadow-md border border-solid rounded-md border-gray-200 px-4 py-4">
          <div class="flex items-center w-full ">
            <div>
              <div class="text-sm font-bold">Send reminder SMS to clients ?</div>
              <!-- <p class="hidden md:block">The client will recieve a reminder of a scheduled session.</p> -->
            </div>
          </div>

          <div class="flex  w-full ">
            <!-- toggle -->
            <div>
              <div class="form-control">
                <label class="cursor-pointer label">
                  <span class="pr-2 font-bold label-text ">YES</span>
                  <input type="checkbox" class="toggle checked:bg-customTeal" checked
                    formControlName="sendSMSReminder" />

                </label>
              </div>
            </div>
          </div>

        </div>
       

        <!-- reminder hour -->
        <div class="flex flex-col w-full md:w-[300px] bg-white shadow-md border border-solid rounded-md border-gray-200 px-4 py-4" *ngIf="sendSMSReminder.value || sendEmailReminder.value">
          <div class="flex items-center w-full ">
            <div>
              <div class="text-sm font-bold">Preferred time to send email reminders to clients <span
                  class="text-red-500">*</span></div>
              <!-- <p class="hidden md:block">The time where the client recieves reminder about appointment.</p> -->
            </div>
          </div>
          <!-- Choose hrs or number of days -->
            <div class="flex flex-col justify-start w-full ">

       <div class="flex space-x-2">
        <div class="flex w-full ">
          <div>
            <div class="form-control">
              <label class="cursor-pointer label">
                <input type="radio" class="radio checked:bg-customTeal" formControlName="preferedEmailReminderOption"
                  value="Days" (change)="onEmailReminderChange('Days')" />
                <span class="pl-2 font-bold label-text ">Days</span>
              </label>
            </div>
          </div>
        </div>

        <div class="flex w-full ">
          <div>
            <div class="form-control">
              <label class="cursor-pointer label">
                <input type="radio" class="radio checked:bg-customTeal" formControlName="preferedEmailReminderOption"
                  value="Hours" (change)="onEmailReminderChange('Hours')" />
                <span class="pl-2 font-bold label-text ">Hours</span>
              </label>
            </div>
          </div>
        </div>
       </div>
  
              <div class="flex w-full ">
                <div *ngIf="preferedEmailReminderOption=='Hours'">
                  <div class="form-control">
                    <label class="cursor-pointer label">
                      <select formControlName="sendReminderBeforeHr"
                        class="w-full select select-bordered bg-veryLightGray">
                        <option disabled selected>Select Hour</option>
                        <option value={{hour}}
                          *ngFor="let hour of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]">{{hour}}
                          {{hour==1 ? 'hour' : 'hours'}}</option>
                      </select>
                    </label>
                  </div>
                </div>
                <div *ngIf="preferedEmailReminderOption=='Days'">
                  <div class="form-control">
                    <label class="cursor-pointer label">
                      <select formControlName="sendReminderBeforeHr"
                        class="w-full select select-bordered bg-veryLightGray">
                        <option disabled selected>Select Days</option>
                        <option value={{day*24}} *ngFor="let day of [1,2,3,4,5,6,7]">{{day}} {{day==1 ? 'day' : 'days'}}
                        </option>
                      </select>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            
            <!-- <div>
              <div class="form-control">
                <label class="cursor-pointer label">
                  <input type="number" required min="1" max="72" class="input input-bordered" checked
                    formControlName="sendReminderBeforeHr" [ngClass]="{
                      'input input-bordered': true,
                      'border-red-500': (sendReminderBeforeHr.dirty || sendReminderBeforeHr.touched) && sendReminderBeforeHr.invalid,
                      'border-gray-200': !((sendReminderBeforeHr.dirty || sendReminderBeforeHr.touched) && sendReminderBeforeHr.invalid)
                    }" />
                </label>
              </div>
            </div> -->

        </div>
       

        <div class="flex flex-col w-full md:w-[300px] bg-white shadow-md border border-solid rounded-md border-gray-200 px-4 py-4">
          <div class="flex items-center w-full ">
            <div>
              <div class="text-sm font-bold">Send email reminder to admin (employee)?</div>
              <!-- <p class="hidden md:block">The client will recieve a reminder of a scheduled session.</p> -->
            </div>
          </div>

          <div class="flex  w-full ">
            <!-- toggle -->
            <div>
              <div class="form-control">
                <label class="cursor-pointer label">
                  <span class="pr-2 font-bold label-text ">YES</span>
                  <input type="checkbox" class="toggle checked:bg-customTeal" checked
                    formControlName="sendNTAEmailReminder" />

                </label>
              </div>
            </div>
          </div>

        </div>
       

        <div class="flex flex-col w-full md:w-[300px] bg-white shadow-md border border-solid rounded-md border-gray-200 px-4 py-4">
          <div class="flex items-center w-full ">
            <div>
              <div class="text-sm font-bold">Send SMS Reminder to admin (or employee)?</div>
              <!-- <p class="hidden md:block">You will recieve a reminder of a scheduled session.</p> -->
            </div>
          </div>

          <div class="flex  w-full ">
            <!-- toggle -->
            <div>
              <div class="form-control">
                <label class="cursor-pointer label">
                  <span class="pr-2 font-bold label-text ">YES</span>
                  <input type="checkbox" class="toggle checked:bg-customTeal" checked
                    formControlName="sendNTASMSReminder" />

                </label>
              </div>
            </div>
          </div>

        </div>
       

        <div class="flex flex-col w-full md:w-[300px] bg-white shadow-md border border-solid rounded-md border-gray-200 px-4 py-4" *ngIf="sendNTASMSReminder.value || sendNTAEmailReminder.value">
          <div class="flex items-center w-full ">
            <div>
              <div class="text-sm font-bold">Preferred time to send SMS reminders to admin (employees)? <span
                  class="text-red-500">*</span></div>
              <!-- <p class="hidden md:block">The time where the you recieve reminders about appointment, Notes and also
                Tasks.</p> -->
            </div>
          </div>
          <!-- Choose hrs or number of days -->
          <div class="flex flex-col justify-start w-full  md:flex-row md:justify-end">
            <div class="flex w-full md:w-3/12">
              <div>
                <div class="form-control">
                  <label class="cursor-pointer label">
                    <input type="radio" class="radio checked:bg-customTeal" formControlName="preferedSMSReminderOption"
                      value="Days" (change)="onSMSReminderChange('Days')" />
                    <span class="pl-2 font-bold label-text ">Days</span>
                  </label>
                </div>
              </div>
            </div>

            <div class="flex w-full md:w-3/12">
              <div>
                <div class="form-control">
                  <label class="cursor-pointer label">
                    <input type="radio" class="radio checked:bg-customTeal" formControlName="preferedSMSReminderOption"
                      value="Hours" (change)="onSMSReminderChange('Hours')" />
                    <span class="pl-2 font-bold label-text ">Hours</span>
                  </label>
                </div>
              </div>
            </div>

            <div class="flex w-full md:w-5/12">
              <div *ngIf="preferedSMSReminderOption=='Hours'">
                <div class="form-control">
                  <label class="cursor-pointer label">
                    <select formControlName="sendReminderNTABeforeHr"
                      class="w-full select select-bordered bg-veryLightGray">
                      <option disabled selected>Select Hour</option>
                      <option value={{hour}}
                        *ngFor="let hour of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]">{{hour}}
                        {{hour==1 ? 'hour' : 'hours'}}</option>
                    </select>
                  </label>
                </div>
              </div>
              <div *ngIf="preferedSMSReminderOption=='Days'">
                <div class="form-control">
                  <label class="cursor-pointer label">
                    <select formControlName="sendReminderNTABeforeHr"
                      class="w-full select select-bordered bg-veryLightGray">
                      <option disabled selected>Select Days</option>
                      <option value={{day*24}} *ngFor="let day of [1,2,3,4,5,6,7]">{{day}} {{day==1 ? 'day' : 'days'}}
                      </option>
                    </select>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="flex  w-full ">
            <div>
              <div class="form-control">
                <label class="cursor-pointer label">
                  <input type="number" required min="1" max="72" class="input input-bordered" checked
                    formControlName="sendReminderNTABeforeHr" [ngClass]="{
                      'input input-bordered': true,
                      'border-red-500': (sendReminderNTABeforeHr.dirty || sendReminderNTABeforeHr.touched) && sendReminderNTABeforeHr.invalid,
                      'border-gray-200': !((sendReminderNTABeforeHr.dirty || sendReminderNTABeforeHr.touched) && sendReminderNTABeforeHr.invalid)
                    }" />
                </label>
              </div>
            </div>
          </div> -->

        </div>
       

        <!-- BackGround Color for the notes -->
        <div class="flex flex-col w-full md:w-[300px] bg-white shadow-md border border-solid rounded-md border-gray-200 px-4 py-4">
          <div class="flex items-center w-full ">
            <div>
              <div class="text-sm font-bold">Pick background color for tasks</div>
              <!-- <p class="hidden md:block">The client will recieve a reminder of a scheduled session.</p> -->
            </div>
          </div>

          <div class="flex  w-full ">
            <!-- toggle -->
            <div>
              <div class="form-control">
                <label class="cursor-pointer label">
                  <!-- <span class="pr-2 font-bold label-text ">YES</span> -->
                  <input type="color" class="float-right w-10 h-10 ml-5" checked
                    formControlName="appointmentBgTaskColor" />

                </label>
              </div>
            </div>
          </div>

        </div>
       

        <div class="flex flex-col w-full md:w-[300px] bg-white shadow-md border border-solid rounded-md border-gray-200 px-4 py-4">
          <div class="flex items-center w-full ">
            <div>
              <div class="text-sm font-bold">Pick background color for notes</div>
              <!-- <p class="hidden md:block">The client will recieve a reminder of a scheduled session.</p> -->
            </div>
          </div>

          <div class="flex  w-full ">
            <!-- toggle -->
            <div>
              <div class="form-control">
                <label class="cursor-pointer label">
                  <!-- <span class="pr-2 font-bold label-text ">YES</span> -->
                  <input type="color" class="float-right w-10 h-10 ml-5" checked
                    formControlName="appointmentBgNoteColor" />

                </label>
              </div>
            </div>
          </div>

        </div>
       

        <div class="flex flex-col w-full md:w-[300px] bg-white shadow-md border border-solid rounded-md border-gray-200 px-4 py-4">
          <div class="flex items-center w-full ">
            <div>
              <div class="text-sm font-bold">Pick background color for appointments</div>
              <!-- <p class="hidden md:block">The client will recieve a reminder of a scheduled session.</p> -->
            </div>
          </div>

          <div class="flex  w-full ">
            <!-- toggle -->
            <div>
              <div class="form-control">
                <label class="cursor-pointer label">
                  <!-- <span class="pr-2 font-bold label-text ">YES</span> -->
                  <input type="color" class="float-right w-10 h-10 ml-5" checked formControlName="appointmentBgApptColor" />
            
                </label>
              </div>
            </div>
            </div>
            
            </div>
           
            
            <div class="flex flex-col w-full md:w-[300px] bg-white shadow-md border border-solid rounded-md border-gray-200 px-4 py-4" >
                      <div class="flex items-center w-full ">
                        <div>
                          <div class="text-sm font-bold">Pick font size</div>
                          <!-- <p class="hidden md:block">The font size will be changed on the calendar.</p> -->
                        </div>
                      </div>
            
                      <div class="flex  w-full ">
                        <div>
                          <div class="form-control">
                            <label class="cursor-pointer label">
                              <!-- <input type="color" class="float-right w-10 h-10 ml-5 rounded-full" checked
                                formControlName="appointmentFontSize" /> -->

                                <select formControlName="appointmentFontSize"
                                class="w-full select select-bordered bg-veryLightGray">
                                <option disabled selected>Select Font Size</option>
                                <option value={{font.value}}
                                  *ngFor="let font of sortedFontSize">{{font.key}}
                                  </option>
                              </select>
            
                            </label>
                          </div>
                        </div>
                      </div>
            
                    </div>
                   

        <!-- <div class="flex flex-col w-full md:w-[300px] bg-white shadow-md border border-solid rounded-md border-gray-200 px-4 py-4md:flex-row" >
          <div class="flex items-center w-full ">
            <div>
              <div class="text-sm font-bold">Pick the text color?</div>
              <p class="hidden md:block">The client will recieve a reminder of a scheduled session.</p>
            </div>
          </div>

          <div class="flex  w-full ">
            <div>
              <div class="form-control">
                <label class="cursor-pointer label">
                  <input type="color" class="float-right w-10 h-10 ml-5 rounded-full" checked
                    formControlName="appointmentTextColor" />

                </label>
              </div>
            </div>
          </div>

        </div>
        <hr /> -->


     

      </div>
   <!-- Save button -->
   <div class="m-5">
    <button type="button" 
    class="flex items-center rounded bg-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] border border-customTeal"
      [disabled]="!apptSettingsForm.valid" (click)="openSaveModal()"
      [ngClass]="{'bg-[#cccccc] text-[#666666]': !apptSettingsForm.valid}">
     
      Save 
    </button>
    <br />
  </div>
    </div>


  </form>

</div>

<!-- create Modal -->
<app-create-confirmation *ngIf="openSave"  (cancelClicked)=closeSaveModal() (saveBtnClicked)=saveAppointmentSettings(apptSettingsForm.value) createdFromComponent='Appointment Settings' componentName="ApptSetting"></app-create-confirmation>

<!-- toast -->
<app-alert class="fixed top-0 flex justify-end w-full" [toastType]="toastType" [toastMessage]="toastMessage"
 [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>