import { AngularFireDatabase } from "@angular/fire/compat/database";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { switchMap, take } from "rxjs/operators";
// import firebase from "firebase";
import { AngularFireMessaging } from "@angular/fire/compat/messaging";
import { FCMService } from "src/app/services/api/fcm.service";
import { FCMPushNotificationsService } from "src/app/services/firebase/FCMPushNotifications.service";
import { NotificationModel } from "src/app/models/Messages.Model";
import { UserService } from 'src/app/services/firebase/user.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
// import { toast } from 'materialize-css';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { environment } from 'src/environments/environment';
import { WindowService } from 'src/app/services/window-service/window.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import * as firebase from 'firebase/compat/app';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountService } from 'src/app/services/api/account.service';
import { RoutMetaService } from "src/app/services/helper/rout-meta.service";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/state/app.state";
// declare var $: any;

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  secretKey: any = "zLeCHJYKf25WENP";

  @Input() emailInput;
  @Input() passwordInput;

  windowRef: any;
  defaultThemeColor = environment.appTheme.themeColor;
  loginForm: UntypedFormGroup;
  phoneLoginForm: UntypedFormGroup;
  nationOfUse = environment.firstNation.name;
  step1 = true;
  step2 = false;
  recaptchaVerified = false;
  model: any;
  themeColor: any;
  appName: any;
  appDescription = "";
  appLogo: any;
  noAdmin = true;
  admins = [];
  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  isVerifyUsers: boolean = false;
  signupSettings;
  sideImage;
  backDropColor;

  //to save the user for the loading
  accountUser;

  // for the FCM
  userInfo: any;

  loading = false;
  mobilePattern =
    /^\s*([\(])[[0-9]{2}\d{1}[\)]\s*[\-]?[\.]?\s*\d{3}\s*[\-]?[\.]?\s*\d{4}$/;
  // tslint:disable-next-line: max-line-length
  public phoneNumberMask = [
    "(",
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    ")",
    " ",
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    " ",
    "-",
    " ",
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
  ];
  public verificationCodeMask = [
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
  ];
  loadingBtnOn = false;
  displayEmail: any;
  displayPassword: any;
  currentUrl: any;
  getEmail: string;
  getPassword: string;
  metaTagSettings: any;
  isNationApp = environment.firstNation.Origin == "FNLeaders" ? true : false;

  constructor( private store: Store<AppState>,
    private fb: UntypedFormBuilder,
    public router: Router,
    private authService: AuthService,
    private winService: WindowService,
    private userService: UserService,
    private settingsService: AppSettingsService,
    private accountService: AccountService,
    private route: ActivatedRoute,
    private db: AngularFireDatabase,
    private afs: AngularFirestore,
    private messaging: AngularFireMessaging,
    private fcmService: FCMService,
    private appSettingsService: AppSettingsService,
    private routMeta : RoutMetaService,
    private pushNotificationsService: FCMPushNotificationsService) {
    this.userService.getAllAdmins().valueChanges().subscribe(admins => {
      if (admins) {
        if (admins.length > 0) {
          this.noAdmin = false;
          this.admins = admins;
        }
      }
    });
    this.settingsService
      .getAppSettingsList()
      .valueChanges()
      .subscribe((appSettings) => {
        if (appSettings) {
          if (appSettings.length > 0) {
            this.model = appSettings[0];
            this.themeColor = this.model.themeColor
              ? this.model.themeColor
              : this.defaultThemeColor;
            this.appName = this.model.appName;
            this.appDescription = this.model.description;
            this.appLogo = this.model.logo;
            this.isVerifyUsers = this.model.verifyUsers;
          }
        }
      });
    this.loading = true;
    this.settingsService
      .getSignupSettingsList()
      .valueChanges()
      .subscribe((s) => {
        if (s) {
          if (s.length > 0) {
            this.signupSettings = s[0];
            this.sideImage =
              this.signupSettings.logo && this.signupSettings.logo != ""
                ? this.signupSettings.logo
                : "https://api.goingmobile.app/media/personal-app-assets/img/webp/ryan-ancill-aJYO8JmVodY-unsplash.webp";
            this.backDropColor = this.signupSettings.backDropColor;
            this.loading = false;
          }
        }
        this.loading = false;
      });


    this.getEmail = this.route.snapshot.queryParamMap.get('email');
    this.getPassword = this.route.snapshot.queryParamMap.get('password');
  }

  ngOnInit() {
    this.loginForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required]],
    });

    // this.listen();
    this.getMetaTagSettings();


    // const decryptedPassword = AES.decrypt("U2FsdGVkX18VMUrGtI6KospHub1lOXmaV3QKAqBtn6g=", this.secretKey).toString(CryptoJS.enc.Utf8)
    // let email = this.route.snapshot.queryParamMap.get('email');
    // let password = this.route.snapshot.queryParamMap.get('password');
    // if(this.getEmail && this.getPassword){


    //     const decryptedEmail = AES.decrypt(this.getEmail, this.secretKey).toString(CryptoJS.enc.Utf8)
    //     const decryptedPassword = AES.decrypt(this.getPassword, this.secretKey).toString(CryptoJS.enc.Utf8)
    //     this.authService.emailLogin(decryptedEmail, decryptedPassword)

    //     this.router.navigate(['/admin/dashboard'])

    // }


    // this.phoneLoginForm = this.fb.group({
    //   phoneNumber: ['', [Validators.required]],
    //   // phoneNumber: ['', [Validators.required, Validators.pattern(this.mobilePattern)]],
    //   verificationCode: ['', [Validators.required]],
    // });

    setTimeout(() => {
      // this.windowRef = this.winService.windowRef;
      // this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      //   size: 'normal',
      //   callback: (response) => {
      //     this.recaptchaVerified = true;
      //   }
      // });
      // this.windowRef.recaptchaVerifier.render();
      // $('.tabs').tabs();
    }, 25);
  }

  get email() {
    return this.loginForm.get("email");
  }
  get password() {
    return this.loginForm.get("password");
  }

  get phoneNumber() {
    return this.phoneLoginForm.get("phoneNumber");
  }
  get verificationCode() {
    return this.phoneLoginForm.get("verificationCode");
  }

  login(data: any) {
    this.loadingBtnOn = true;
    this.authService.emailLogin(data.value.email, data.value.password).then((x: any) => {
      // this.authService.emailLogin(email, password).then((x: any) => {

      // this.accountUser = x.uid;
      // this.accountService.updateProfileKey(this.accountUser);
      if (x) {
        this.userService.getUserById(x.uid).valueChanges().subscribe((user: any) => {
          if (user) {
            if (user.length > 0) {
              // if (user[0].role === 'User' && user[0].accountSetup === 'empty' && !x.emailVerified && !this.isVerifyUsers){
              if (user[0].role === 'User' && user[0].accountSetup === 'empty' && !user[0].verified && !this.isVerifyUsers) {
                this.toast({ html: 'Please verify your email.', classes: 'red', type: 'failure' });
                this.authService.signOutWithoutRedirect();
                return;
              }
              else {

                this.userInfo = user[0];

                // this.store.dispatch({ type: '[LoggedInUser] AddUser', userInfo: user[0] });
                let userIds = [];

                // iterate over admins array, to check which admins want to recieve notification
                for (let i = 0; i < this.admins?.length; i++) {

                  // check if admin wants to send notification when an event is created, for the admin
                  if (this.admins[i].recieveNotificationFor?.length) {
                    let notificationPreferenceIndex = this.admins[i].recieveNotificationFor.findIndex((item) => item === "LoggedInUsers");
                    if (notificationPreferenceIndex != -1) {
                      userIds.push(this.admins[i]?.uid)
                    }

                  }


                }


                // if there are admin who wants to receive a notification, send push notification
                if (userIds?.length) {
                  // if the role is admin or employee and if they want to send notification for user on event creation
                  if ((user[0]?.role != "Admin" && user[0].role != "Employee")) {
                    let fullName = user[0]?.GivenName + ' ' + user[0]?.LastName
                    let notification = new NotificationModel()
                    notification.title = 'User Login'
                    notification.body = `${fullName} has logged in to your app.`
                    this.pushNotificationsService.sendNotificationToUsers(notification, userIds);

                  }

                }

              }
            }
          }
        })
        this.loadingBtnOn = false;

      } else {
        this.loadingBtnOn = false;
      }
    })
      .catch((error) => {
        this.loadingBtnOn = false;
        this.toast({ html: error.message, classes: "red", type: "failure" });
      });

    if (this.userInfo) {

      //  if user is logged in , set the registration token
      this.afs.collection("Users").doc(this.userInfo.uid).set(
        {
          registrationToken: "new value",
        },
        { merge: true }
      );
    }
  }

  requestPermission(user: any) {
    const messaging = firebase.default.messaging();

    messaging
      .getToken({ vapidKey: environment.firebase.vapidFcmKey })
      .then((currentToken) => {
        if (currentToken) {

          this.manageDeviceGroup(user, currentToken);
        } else {
          
        }
      })
      .catch((err) => {
        this.toast({ html: err.message, classes: "red", type: "failure" });
      });
  }

  async manageDeviceGroup(user, currentToken) {

    try {
      if (user?.deviceGroupKey) {
        // if a device group key is available , then what we need to do is to add the current token to the device group key
        this.fcmService
          .updateDeviceGroup(
            "new_group",
            user?.deviceGroupKey,
            [currentToken],
            "add"
          )
          .subscribe((notificationToken) => {
            this.updateRegistrationToken(user, currentToken, notificationToken);
          });
      } else {
        // we need to create the device group key and save the notification key to the database
        this.fcmService
          .createFCMDeviceGroup("new_group", [currentToken])
          .subscribe((notificationToken) => {

            this.updateRegistrationToken(user, currentToken, notificationToken);
          });
      }
    } catch (err) {
      this.toast({ html: err.message, classes: "red", type: "failure" });
    }
  }

  async updateRegistrationToken(user, currentToken, deviceGroupKey) {
    await this.afs
      .collection("Users")
      .doc(user.uid)
      .update({
        registrationToken:
          firebase.default.firestore.FieldValue.arrayUnion(currentToken),
        deviceGroupKey: deviceGroupKey?.notification_key,
      });

    // // save it in the local storage
    localStorage.setItem("registrationToken", currentToken);
    localStorage.setItem("deviceGroupKey", deviceGroupKey?.notification_key);
  }

  listen() {
    const messaging = firebase.default.messaging();
    messaging.onMessage((payload) => {
      // this.message=payload;
    });
  }

  hexToRGB(hex: string) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    // return `rgb(${r}, ${g}, ${b})`;
    return `rgb(${r}, ${g}, ${b}, 0.5)`;
  }

  //   sendVerificationCode() {

  //     if (!this.recaptchaVerified) {
  //      // tslint:disable-next-line: max-line-length
  //       toast({ html: '<span> <i class="material-icons left">error_outline</i>
  //        Please Check Im Not A Robot Button ! </span>', classes: 'red' })
  //       return;
  //     }

  //     const appVerifier = this.windowRef.recaptchaVerifier;
  //     let formatedNumber = this.phoneLoginForm.value.phoneNumber.replace('(', '');
  //     formatedNumber = formatedNumber.replace(')', '' );
  //     formatedNumber = formatedNumber.replace('-', '' );
  //     formatedNumber = formatedNumber.replace(/\s/g, '');
  //     const num = '+1' + formatedNumber;

  //     this.authService.phoneNumberVerification(num, appVerifier)
  //             .then(result => {
  //                 this.step1 = false;
  //                 this.step2 = true;
  //             })
  //       .catch(error => {
  //         this.recaptchaVerified = false;
  //         toast({ html: error.message, classes: 'red' });
  //       });
  //   }

  //   loginWithPhone(data: any) {
  // this.authService.phoneNumberLogin(data.value.verificationCode);
  //   }
  public getInitials(appName: any) {
    if (appName) {
      appName = appName.replace(/<(?:.|\n)*?>/gm, "");
      return appName.substring(0, 1);
    } else {
      return "";
    }
  }

  navigate() {
    if(this.nationOfUse == 'Demo' || this.nationOfUse == 'myaccount'){
      this.router.navigate(["/get-app"]);
    }else{
      if (this.isVerifyUsers) {
        this.router.navigate(["/registration-request"]);
      } else {
        this.router.navigate(["/register"]);
      }
    }
  }

  getMetaTagSettings() {
    this.appSettingsService.getMetadata().valueChanges().subscribe((metaTags: any) => {
      let lastIndex = metaTags?.length - 1;
      if (lastIndex || lastIndex === 0) {
        this.metaTagSettings = metaTags?.[lastIndex];
        // this.appSettingsForm.get('appTitle').setValue(this.metaTagSettings?.title)
        this.routMeta.updateTitle(metaTags[0]?.appTitle);
        // this.appSettingsForm.get('keywordSEO').setValue(this.metaTagSettings?.description);
        this.routMeta.updateDescription(metaTags[0]?.description);
        // this.appSettingsForm.get('Keywords').setValue(this.metaTagSettings?.KeyWords);
        this.routMeta.updateKeywords(metaTags[0]?.keyWords ? metaTags[0]?.keyWords : "");
  
      }
    });
  }
  // navigateToURL() {
  //   window.location.href =(this.envService.isNationApp) ? "https://firstnationleaders.app/" : "https://goingmobile.app/";
  // }
  toast(obj) {
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : "green";
    this.toastType = obj.type ? obj.type : "success";
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
    }, 3000);
  }

}