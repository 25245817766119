import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.css']
})
export class EmptyStateComponent implements OnInit {

  @Input() calledFrom;
  constructor(    private router: Router,) { }

  ngOnInit(): void {
  }

  navigateTo(){
    if(this.calledFrom=='event'){
      this.router.navigate(['/admin/create-event']);
    }else if(this.calledFrom=='blog'){
      this.router.navigate(['/admin/create-blog']);

    }else if(this.calledFrom=='service'){
      this.router.navigate(['/admin/create-service']);

    }else if(this.calledFrom=='product'){
      this.router.navigate(['/admin/create-product']);

    }
    
  }

}
