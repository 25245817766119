<div class="row text-center" >
    <div class="col-md-4">
      <div class="btn-group">
        <div
          class="btn btn-primary"
          mwlCalendarPreviousView
          [view]="view"
          [(viewDate)]="viewDate"
          (viewDateChange)="viewDateChange.next(viewDate)"
        >
          Previous
        </div>
        <div
          class="btn btn-outline-secondary"
          mwlCalendarToday
          [(viewDate)]="viewDate"
          (viewDateChange)="viewDateChange.next(viewDate)"
        >
          Today
        </div>
        <div
          class="btn btn-primary"
          mwlCalendarNextView
          [view]="view"
          [(viewDate)]="viewDate"
          (viewDateChange)="viewDateChange.next(viewDate)"
        >
          Next
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <h5>{{ viewDate | calendarDate: view + 'ViewTitle':locale }}</h5>
    </div>
  </div>
  <br />