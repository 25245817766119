import { Component, OnInit } from '@angular/core';
import { FCMpushService } from 'src/app/services/firebase/FCMpush.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { NotificationPreferenceService } from 'src/app/services/firebase/notification-preference.service';
import { UserService } from 'src/app/services/firebase/user.service';

@Component({
    selector: 'app-push-notification-settings',
    templateUrl: './push-notification-settings.component.html',
    //   styleUrls: ['./push-notification-setting.component.css']
})
export class PushNotificationSettingComponent implements OnInit {

    newSettings: boolean
    notifSettings
    defaultNotifications = {
        id: '',
        userId: '',
        settings: [
            {
                "displayName": "Comments on Blog",
                "name": "blogComment",
                "isEnabled": false
            },
            {
                "displayName": "User Signup",
                "name": "signUp",
                "isEnabled": false
            },
            {
                "displayName": "Appointment Request",
                "name": "appointment",
                "isEnabled": false
            },
            {
                "displayName": "New message from client",
                "name": "chat",
                "isEnabled": false
            },
            {
                "displayName": "Client verification request",
                "name": "verificationRequest",
                "isEnabled": false
            },
            {
                "displayName": "Task Reminder",
                "name": "taskReminder",
                "isEnabled": false
            },
            {
                "displayName": "New order placed",
                "name": "order",
                "isEnabled": false
            },
            {
                "displayName": "Employee makes changes",
                "name": "employeeChange",
                "isEnabled": true,
                "settings": [
                    {
                        "displayName": "Social Media Post",
                        "name": "socialMedias",
                        "isEnabled": false
                    },
                    {
                        "displayName": "Blog",
                        "name": "blogs",
                        "isEnabled": false
                    },
                    {
                        "displayName": "Products",
                        "name": "products",
                        "isEnabled": false
                    },
                    {
                        "displayName": "Service",
                        "name": "services",
                        "isEnabled": false
                    },
                    {
                        "displayName": "Event",
                        "name": "events",
                        "isEnabled": false
                    }
                ]
            },

        ],
    }

    // toast
    toastMessage: any;
    toastClass: any;
    toastType: any;
    openToast = false;
    allowPushNotification = false;

    // user info
    currentUser;
    receiveNotificationFor = [];
    sendNotificationFor = [];


    constructor(
        private appSettingsService: AppSettingsService,
        private authService: AuthService,
        private userService: UserService,
        private notificationPreferenceService: NotificationPreferenceService,
        private fcmPushService: FCMpushService
    ) { }

    ngOnInit(): void {

        this.currentUser = this.authService.getGlobalUser();

        // check if push notification setting is available
        if (this.currentUser?.allowPushNotification) {
            this.allowPushNotification = true;
        }

        // this.appSettingsService.getAdminPushNotificationSettingsList(this.currentUser?.uid).valueChanges().subscribe(x => {


        //     if (x && x.length > 0) {
        //         this.notifSettings = x[0]
        //         // this.notifSettings = this.defaultNotifications;
        //         this.newSettings = false
        //     } else {
        //         this.newSettings = true
        //         this.notifSettings = this.defaultNotifications
        //     }
        // })



    }

    save() {

        // this.receiveNotificationFor = this.notificationPreferenceService.selectTheChoosenNotificationPreference(this.notifSettings)

        // update the current user info receive notification for section
        // if (this.currentUser.receiveNotificationFor) {
        //     this.currentUser.receiveNotificationFor = this.receiveNotificationFor;
        // }
        // else {
        //     this.currentUser['receiveNotificationFor'] = this.receiveNotificationFor;
        // }


        // this.notifSettings.userId = this.currentUser.uid;

        // if (this.newSettings) {
        //     this.appSettingsService.savePushNotificationSettings(this.notifSettings).then(async x => {

        //         // update user data
        //         await this.updateUser();

        //         // success toast
        //         this.toast({ html: 'Successfully  Updated Push Notification!', classes: 'green', type: 'success' });

        //     })
        // } else {

        //     this.appSettingsService.updatePushNotificationSettings(this.notifSettings).then(async x => {
        //         // update user data
        //         await this.updateUser();

        //         // success toast
        //         this.toast({ html: 'Successfully  Updated Push Notification!', classes: 'green', type: 'success' });
        //     })
        // }

        // update users model by appending push notfication 


    }

    // update user data
    updateUser() {

        // set push notification selected
        let prevousNotificationOption = this.currentUser.allowPushNotification
        this.currentUser.allowPushNotification = this.allowPushNotification;

        this.userService.updateUser(this.currentUser).then(res => {
            // check if users previous notification preference is the same as the current one.
            if(prevousNotificationOption != this.allowPushNotification){
                // check if the user wants to recieve notification or not 
                if(this.allowPushNotification){
                    this.subscribeUserToTopic();
                }
                else{
                    this.unSubscribeUserFromTopic();
                }

            }
        }).catch((err) => console.log("err is ... "));
    }

    // based on the user preference subscribe user to the topic
    subscribeUserToTopic() {

        try {
            let currentToken = this.authService.getUserCurrentToken();
            let topic = null;

            if (this.currentUser.role === "User") {
                topic = "User_Notification";
            } else if (this.currentUser.role === "Admin")
                topic = "Owner_Notification";
            else if (this.currentUser.role === "Employee")
                topic = "Employee_Notification"

            this.fcmPushService.subscribeToTopic(topic, currentToken);

        }
        catch (error: any) {
            console.log("error is ... .. .. ", error)
        }

    }

    // // based on the user preference unsubscribe user from a topic
    unSubscribeUserFromTopic(){
        try {
            let currentToken = this.authService.getUserCurrentToken();
            let topic = null;

            if (this.currentUser.role === "User") {
                topic = "User_Notification";
            } else if (this.currentUser.role === "Admin")
                topic = "Owner_Notification";
            else if (this.currentUser.role === "Employee")
                topic = "Employee_Notification"

            this.fcmPushService.unSubscribeToTopic(topic, currentToken);

        }
        catch (error: any) {
            console.log("error is ... .. .. ", error)
        }

        

    }

    toast(obj) {
        this.toastMessage = obj.html;
        this.toastClass = obj.classes ? obj.classes : 'green';
        this.toastType = obj.type ? obj.type : 'success';
        this.openToast = true;
        setTimeout(() => {
            this.openToast = false;
        }, 2000);
    }

}
