import { Modal, Ripple, initTE } from "tw-elements";
import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FirstNationsService } from "src/app/services/api/firstnations.services";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { GlobalConstants } from 'src/app/models/GlobalConstants';

@Component({
  selector: "app-get-firstnations",
  templateUrl: "./get-firstnations.component.html",
  styleUrls: ["./get-firstnations.component.css"],
})
export class GetFirstNations implements OnInit {
  @Output() onCloseModal = new EventEmitter();
  @Output() onNationSelect = new EventEmitter();

  modal;

  letters = "abcdefghijklmnopqrstuvwxyz".split("");
  selectedLetter = "";
  filterByLetter = false;

  allNations = [];
  filteredNations = [];

  searchNationForm: UntypedFormGroup;
  loading=false
  provinces = GlobalConstants.provinces

  constructor(
    private firstNationsService: FirstNationsService,
    private fb: UntypedFormBuilder,
    private router: Router,
  ) {
    this.loading=true
    this.firstNationsService.getFirstNations().subscribe((nations) => {
      this.loading=false
      if (nations && nations.length > 0) {
        // this.allNations = nations;
        this.allNations = nations.map((nation) => {
          nation.nationName = nation.Nation_Name;
          nation.province = nation.Province;
          nation.address = nation.Nation_Website;

          return nation;
        })
        this.filteredNations = this.allNations;
      }
    });
  }

  ngOnInit() {
    const btn1 = document.getElementById('closeNationSearch');
    this.router.events.subscribe((val) =>{
      btn1.click()
    })

    this.searchNationForm = this.fb.group({
      name: [],
      number: [],
      province: [],
    });
    initTE({ Modal, Ripple });
    this.modal = new Modal(document.getElementById("nationsListModal"));
    this.modal.show();
  }

  getSearchValues(values = this.searchNationForm.value) {
    let name = values.name == "" || values.name == null ? null : values.name;
    let number =
      values.number == "" || values.number == null ? null : values.number;

    let province =
      values.province == "" || values.province == null ? null : values.province;
    return { name, number, province };
  }

  searchNations(values) {
    let { name, number, province } = this.getSearchValues(values);
    this.applyFilter(name, number, province);
  }

  applyLetterFilter(letter) {
    if (this.selectedLetter == letter) {
      this.selectedLetter = "";
      this.filterByLetter = false;
    } else {
      this.selectedLetter = letter;
      this.filterByLetter = true;
    }
    let { name, number, province } = this.getSearchValues();

    this.applyFilter(name, number, province);
  }

  applyFilter(name = null, number = null, province = null) {
    this.filteredNations = this.allNations;
    if (this.filterByLetter) {
      this.onFilterByLetter();
    }
    if (name != null) {
      this.filterByName(name);
    }
    if (number != null) {
      this.filterByNumber(number);
    }
    if (province != null) {
      this.filterByProvince(province);
    }
  }

  onFilterByLetter() {
    this.filteredNations = this.filteredNations.filter(
      (nation) => nation.nationName[0].toLowerCase() == this.selectedLetter
    );
  }

  filterByName(name) {
    this.filteredNations = this.filteredNations.filter((nation) =>
      nation.nationName.toLowerCase().includes(name.toLowerCase())
    );
  }

  filterByNumber(number) {
    this.filteredNations = this.filteredNations.filter(
      (nation) => nation.nationNumber == number
    );
  }

  filterByProvince(province) {
    this.filteredNations = this.filteredNations.filter(
      (nation) =>nation.province !== null && nation.province.toLowerCase().includes(province.toLowerCase())
    );
  }

  selectNation(nation) {
    this.onNationSelect.emit(nation);
    this.modal.hide();
  }

  closeModal(){
    this.onCloseModal.emit()

  }
  clearSearch(){
    this.filteredNations= this.allNations
    this.selectedLetter=""
    this.filterByLetter=false
    this.searchNationForm.reset()
  }
}
