import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
// import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
// import { GalleryItem, ImageItem } from 'ng-gallery';
import { UserService } from 'src/app/services/firebase/user.service';
import { EmployeeServicesService } from 'src/app/services/firebase/employee-services.service';
import { ScheduleService } from 'src/app/services/firebase/employee-schedule.service';
import { EmailModel, CustomAdminEmailModel } from 'src/app/models/emailModel';
import { EmailService } from 'src/app/services/api/email.service';
import { SMSModel } from 'src/app/models/SMSModel';
import { SMSService } from 'src/app/services/api/sms.service';
import { DepartmentService } from 'src/app/services/firebase/department.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { AboutService } from 'src/app/services/firebase/about.service';
import { PermissionService } from 'src/app/services/firebase/permission.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { Location } from '@angular/common';
import { HelperService } from 'src/app/services/helper/helper';
import { environment } from 'src/environments/environment';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { CountersService } from 'src/app/services/firebase/counters.service';



@Component({
  selector: 'app-employee-detail',
  templateUrl: './employee-detail.component.html',
  styleUrls: ['./employee-detail.component.css']
})
export class EmployeeDetailComponent implements OnInit {

  id: any;
  employee: any;
  openContactModal = false;
  //images: GalleryItem[];
  images: any[];
  imgLink = 'https://www.w3schools.com/howto/img_avatar.png';
  profileImages: any = [];
  coverPhotoLink = '';
  GivenName: any;
  LastName: any;
  cellPhone: any;
  Email: any;
  initial: any;
  serviceGlobal: any;
  service: any;
  EmpSchedule = [];
  EmpService = [];
  EmpServiceUnder = [];

  contactForm: UntypedFormGroup;
  isSMS = false;
  isSMSemp = false;
  storeEmail = [];
  selectedEmployees = [];
  disable = false;
  disableModal = false;
  enableModal = false;
  departmentModal = false;

  department: any;
  selectedDepartment = [];
  selectedService = [];
  selectedServiceObj = [];
  noSelectedDepartment = false;
  departmentGlobal = [];
  model: any;
  openLinkServiceModal = false;

  showEmp = false;
  aboutSettings : any;
  showEmpFromAdmin = false;
  sliced: any;
  openBiographyModal = false;
  showEmployeeModal = false;
  resetModal = false;
  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;

  publicShow = false;
  SubscribeShow = false;
  noShow = false;
  showEmployee: any;

  isAccess = false;

  inviteEmp: boolean;
  inviteSub: boolean;
  enableDisableSub: boolean;
  enableDisableEmp: boolean;
  acceptRequest: boolean;
  declineRequest: boolean;

  createPosts: boolean;
  editPost: boolean;
  deletePost: boolean;

  createEvents: boolean;
  editEvents: boolean;
  deleteEvents: boolean;

  createServices: boolean;
  editServices: boolean;
  deleteServices: boolean;

  createProducts: boolean;
  editProducts: boolean;
  deleteProducts: boolean;

  viewAnalytics: boolean;
  linkAccount: boolean;
  unlinkAccount: boolean;

  createSocialPost: boolean;
  editSocialPost: boolean;
  deleteSocialPost: boolean;

  socialViewAnalytics: boolean;

  permissionGlobal: any;
  allSpecialPriviledge: boolean;
  isCurrentUserAdmin = false;


  permission = [
    {
      inviteSub: false, inviteEmp: false, enableDisableSub: false, enableDisableEmp: false, acceptRequest: false,
      declineRequest: false, resetPass: false, contactUsers: false, scheduleEmp: false, addDepartment: false,
      editDepartment: false, deleteDepartment: false
    },

    {
      createPosts: false, editPost: false, deletePost: false, createEvents: false, editEvents: false,
      deleteEvents: false, createServices: false, editServices: false, deleteServices: false, createProducts: false,
      editProducts: false, deleteProducts: false, addCategoryProducts: false, editCategoryProducts: false,
      addServiceCategory: false, editServiceCategory: false
    },

    { viewAnalytics: false },

    {
      linkAccount: false, unlinkAccount: false, createSocialPost: false, createProfile: false,
      deleteProfile: false, editSocialPost: false, deleteSocialPost: false, socialViewAnalytics: false,
    },

    {
      landingPageSetup: false, aboutUsPageSetup: false, appSetup: false,
      accountVerification: false, editClients: false, editEmployees: false,
      subInfo: false, permissionAccess: false, calendar: false, newPage: false,
      welcomePopup: false, importAndDelete : false, editFAQ: false, deleteFAQ: false,
    },

    {
      privateChat: false, groups: false, createGroup: false, newEmployee: false
    },
    {
      viewPage: false, createPage: false, editPage: false, deletePage: false, 
      viewItem: false, createItem: false, editItem: false, deleteItem: false
    }
  ]

  permissionDisplay: any;

  perm = this.permission[2];
  permMember = this.permission[0];
  permSocial: any = this.permission[3]
  permPES = this.permission[1];
  permSpecial = this.permission[4];
  permChat = this.permission[5];
  permCustomPage = this.permission[6]

  permissionEdit: any;
  permissionSpecialEdit: any;

  allPriviledge: any;
  allMemberPriviledge: any
  allPESPriviledge: any;
  allSocialPriviledge: any;
  allChatPriviledge: any;
  allCustomPagePrivilege: any;

  currentUser: any;
  role: any;
  isAdmin: boolean;

  permEmp: any;
  permissionEmp: any;
  permissionSpecialEmp: any;

  numOfEmp: boolean;
  isVerifyUsers: boolean;
  found = false;
  foundSpecial = false;
  reason: any;

  contactMsg = 'Email';

  //Email
  setUp: any;
  greetings: any;
  signOff: any;
  email: any;
  messageForSMS: any;

  employeeService: any;
  openPermissionModal: boolean = false;
  disableStopModal: boolean = false;

  appName: any;
  appLogo: any;
  domain = environment.firstNation.name;
  // domainName = "https://" + this.domain + ".goingmobile.app"
  isNationApp = environment.firstNation.Origin == "FNLeaders" ? true : false;

  domainName = this.isNationApp ? "https://" + this.domain + ".mynation.app" : "https://" + this.domain + ".goingmobile.app";
  

  beforeDeleteModal: boolean;
  confirmDeleteModal: boolean;
  confirmSecondModal: boolean;
  confirmThreeModal: boolean;
  deleteModal: boolean;

  openMemberCollapse = false;
  openPostCollapse = false;
  openSocialCollapse = false;
  openAnalyticsCollapse = false;
  openSpecialCollapse = false;
  openChatCollapse = false;

  foundPES: boolean = false;
  foundMember: boolean = false;
  foundSocial: boolean = false;
  foundChat: boolean = false;
  foundCustomPage: boolean = false;
  distinctServices: any;
  // selectedServiceObj = [];


  adminSMSCounter: number = 0;
  userSMSCounter: number = 0;
  userCounter: any;
  adminCounter: any;
  adminEmailCounter: number = 0;

  counterGlobal: any;
  emitted: any;
  currentDate = new Date();

  isLimitSMS: boolean;
  isLimitEmail: boolean;

  availablePhone: any;

   collapsibleStates = {
    Member: false,
    Post: false,
    Social: false,
    Analytics: false,
    Special: false,
    Chat: false,
    CustomPage: false
  };

  permissionCategories: any = {
    Member: this.permMember,
    PES: this.permPES,
    // Add other categories here
    perm: this.perm,
    Social : this.permSocial,
    Special: this.permSpecial,
    Chat: this.permChat,
    CustomPage: this.permCustomPage
  };

  permissionToggled: any

  constructor(private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private userService: UserService,
    private employeeServicesService: EmployeeServicesService,
    private scheduleService: ScheduleService,
    private emailService: EmailService,
    private sMSService: SMSService,
    private departService: DepartmentService,
    private settingsService: AppSettingsService,
    private aboutService: AboutService,
    private router: Router,
    private permissionService: PermissionService,
    private authService: AuthService,
    private location: Location,
    private appSettingsService: AppSettingsService,
    private helperService: HelperService,
    public afAuth: AngularFireAuth,
    private counter: CountersService,
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });

    this.contactForm = this.fb.group({
      contact: ['Email'],
      subject: [''],
      body: [''],
      message: ['']
    });

    // Get the current month
    const currentMonth = this.currentDate.getMonth();

    // Move to the next day
    const nextDay = new Date(this.currentDate);
    nextDay.setDate(this.currentDate.getDate() + 1);

    // Check if the next day is in a different month
    const isNewMonth = nextDay.getMonth() !== currentMonth;

    this.currentUser = this.authService.getGlobalUser();

    this.counter.getCounter().valueChanges().subscribe((adminCounter: any) => {
      if (adminCounter && adminCounter.length != 0) {
        this.adminCounter = adminCounter[0];
        this.adminSMSCounter = this.adminCounter.SMSTotalCounter;
        this.adminEmailCounter = this.adminCounter.emailTotalCounter ? this.adminCounter.emailTotalCounter : 0;


        if (isNewMonth) {
          this.adminSMSCounter = 0;
          this.adminEmailCounter = 0;

        } else {
          if (this.adminSMSCounter <= 100) {
            this.adminSMSCounter = this.adminCounter.SMSTotalCounter;
          }
          if (this.adminEmailCounter <= 100) {
            this.adminEmailCounter = this.adminCounter.emailTotalCounter ? this.adminCounter.emailTotalCounter : 0;
          }
          if (this.adminSMSCounter > 100) {
            this.isLimitSMS = true;
          }
          if (this.adminEmailCounter > 100) {
            this.isLimitEmail = true;
          }
        }


      } else {
        this.adminSMSCounter = 0;
        const counter = {
          id: '',
          SMSTotalCounter: 0,
          emailTotalCounter: 0,
          chatGptCounter: 0,
          socialMediaCount: 0,
        }
        this.counter.saveCounter(counter);
      }
    });


    if (this.currentUser) {
      this.role = this.currentUser.role;
      if (this.role === 'Admin') {
        this.isAdmin = true;
        this.appSettingsService.getAppSettingsList().valueChanges().subscribe(setup => {
          this.setUp = setup[0];
          this.appName = this.setUp.appName;
          this.appLogo = this.setUp.logo;
          this.greetings = this.setUp.greetings ? this.setUp.greetings : '';
          this.availablePhone = (this.setUp.availablePhone) ? (this.setUp.availablePhone) : '';
          this.signOff = this.setUp.signOff ? this.setUp.signOff : '';
          this.email = this.greetings + ' <br /> <br />  <br /> <br />' + this.signOff;
          this.messageForSMS = '\n \n' + this.setUp.messageSMS ? this.setUp.messageSMS : '';

          this.contactForm.patchValue({
            body: this.email,
            message: this.messageForSMS
          });
          
        });
      } else if (this.role == 'Employee') {
        this.userService.getUserByEmail(this.currentUser.Email).valueChanges().subscribe(employee => {
          this.employee = employee[0];
          this.email = this.employee.greetings + ' <br /> <br />  <br /> <br />' + this.employee.signOff;
          this.availablePhone = this.setUp.availablePhone ? this.setUp.availablePhone : '';

          this.messageForSMS = this.employee.messageSMS;
          this.contactForm.patchValue({
            body: this.email,
            message: this.messageForSMS
          })
        })

      }

      this.permissionService.getPermissionByEmployeeId(this.id).valueChanges().subscribe((perm: any) => {
        if (perm && perm.length !== 0) {

          this.permissionDisplay = perm[0];
          [this.permMember, this.permPES, this.perm, this.permSocial, this.permSpecial, this.permChat, this.permCustomPage] = this.permissionDisplay.permission;

          this.permission = this.permissionDisplay.permission;

          this.foundMember = Object.values(this.permMember).some(value => value === false);
          this.foundPES = Object.values(this.permPES).some(value => value === false);
          this.foundSocial = Object.values(this.permSocial).some(value => value === false);
          this.foundChat = Object.values(this.permChat).some(value => value === false);
          this.foundCustomPage = Object.values(this.permCustomPage).some(value => value === false);

          this.allSocialPriviledge = !this.foundSocial;
          this.allMemberPriviledge = !this.foundMember;
          this.allPESPriviledge = !this.foundPES;
          this.allChatPriviledge = !this.foundChat;
          this.allCustomPagePrivilege = !this.foundCustomPage;

        }
        else {
          [this.permMember, this.permPES,  this.perm, this.permSocial, this.permSpecial, this.permChat, this.permCustomPage] = this.permission;
        }

      });

      // Permission from the Global user....the Employee can't give access to other employees
      this.permissionService.getPermissionByEmployeeId(this.currentUser.uid).valueChanges().subscribe(permEmp => {
        if (permEmp.length !== 0) {
          this.permEmp = permEmp[0];
          this.permissionEmp = this.permEmp.permission[0];
          this.permissionSpecialEmp = this.permEmp.permission[4];
        }
      });

    }

    this.userService.getUserById(this.id).valueChanges().subscribe(employee => {
      if (employee && employee.length > 0) {
        this.employee = employee[0];
        // if (this.employee.role == 'Admin' && this.currentUser.role == 'Admin') {
        //   this.isCurrentUserAdmin = true;
        // }
        this.isCurrentUserAdmin = (this.employee.role == 'Admin' && this.currentUser.role == 'Admin');
        this.GivenName = this.employee.GivenName;
        this.LastName = this.employee.LastName;
        this.cellPhone = this.employee.cellPhone;
        this.Email = this.employee.Email;
        this.initial = this.employee.GivenName.match(/\b(\w)/g);
        this.sliced = this.employee.biography ? this.employee.biography.slice(3, this.employee.biography.length - 10): '';
        
        switch (this.employee.ShowEmployee) {
          case 'Public':
            this.publicShow = true;
            this.SubscribeShow = false;
            this.noShow = false;
            break;
          case 'Subscriber':
            this.publicShow = false;
            this.SubscribeShow = true;
            this.noShow = false;
            break;
          default:
            this.publicShow = false;
            this.SubscribeShow = false;
            this.noShow = true;
        }

      }
    });

    this.employeeServicesService.getAllServices().valueChanges().subscribe(services => {
      this.service = services;

      this.service.forEach((service) => {
        if (service.allServices && service.allServices.length > 0) {
          service.allServices.forEach(element => {
            if (element.assignedTo) {
              element.assignedTo.forEach(assignedTo => {
                if (assignedTo.employee.uid == this.id) {
                  this.EmpServiceUnder.push(element);
                }
              });
            }
          });
        }
        service.assignedTo.forEach((empId) => {
          if (empId === this.id) {
            if (!(this.EmpService.includes(service))) {
              this.EmpService.push(service);
              this.EmpService = [...new Set(this.EmpService)]
            }

            if (this.selectedService.includes(service.id)) {
            }
            else {
              this.selectedServiceObj.push(service);
              this.selectedService.push(service.id);
            }
          }
        });
      });

    });

    this.scheduleService.getScheduleByEmployeeId(this.id).valueChanges().subscribe((empSchedule: any) => {
      if (empSchedule && empSchedule.length > 0) {
        this.EmpSchedule = empSchedule[0].schedule.filter((schd: any) => !schd.unAvailable);
      }
    });

    this.departService.getDepartment().valueChanges().subscribe((department: any) => {
      if (department.length > 0) {
        this.department = department;
        this.selectedDepartment = this.department
          .filter(dept => dept.membersList.some(memberUid => memberUid === this.employee.uid))
          .map(dept => dept.id);
      }
    });

    this.aboutService.getAbout().valueChanges().subscribe(aboutSettings => {
      if (aboutSettings.length > 0) {
        this.aboutSettings = aboutSettings[0];
        this.showEmpFromAdmin = this.aboutSettings.showEmployees;
      }
    });

    this.settingsService.getAppSettingsList().valueChanges().subscribe( appSettings => {
          if (appSettings && appSettings.length > 0) {
            this.model = appSettings[0];
            this.isVerifyUsers = this.model.verifyUsers;
          }
      }
    );

  }
  editEmployee() {
    if (this.isAdmin) {
      this.router.navigate(['/admin/employee-edit/', this.employee.uid]);
    }
    else {
      this.router.navigate(['/employee/employee-edit/', this.employee.uid])
    }
  }
  openModal() {
    this.openContactModal = true;
  }
  onSendClicked(event) {
    this.emitted = event;
    this.openContactModal = false;
    this.toast(this.emitted);
  }
  openEnableModal() {
    this.enableModal = true;
  }
  openDepartmentModal() {
    this.departmentModal = true;
  }
  openShowEmployee() {
    if (this.isAdmin) {
      this.showEmployeeModal = true;
    }
  }
  closeShowEmployee() {
    this.showEmployeeModal = false;
    this.toast({ html: 'Your change have been Successfully saved.', classes: 'green', type: 'success' });

  }
  closeEnableModal() {
    this.enableModal = false;
  }
  openDisableModal() {
    this.disableModal = true;
  }
  openDisableStopModal() {
    this.disableStopModal = true;
    this.disableModal = false;
  }
  closeDepartmentModal() {
    this.departmentModal = false;
  }
  BiographyModal() {
    this.openBiographyModal = true;
  }
  closeBiographyModal() {
    this.openBiographyModal = false;
  }
  openResetModal() {
    this.resetModal = true;
  }
  closeResetModal() {
    this.resetModal = false;
  }
  giveAccess() {
    this.isAccess = !this.isAccess;
  }
  resetPassword() {
    this.authService.resetPassword(this.Email).then((res) => {
      this.toast({ html: 'Reset Password Link Successfully Sent To the Employee Inbox.', classes: 'green', type: 'success' });
    }).catch((error) => {
      this.toast({ html: `${error}`, classes: 'red', type: 'error' });
    }
    );
    this.closeResetModal();
  }
  SMSselectedEmp() {
    this.isSMSemp = !this.isSMSemp;
  }
  smsSelected() {
    this.contactMsg = 'SMS'
    this.isSMS = true;
    if (this.cellPhone == '') {
      this.disable = true;
    }
  }
  emailSelected() {
    this.contactMsg = 'Email'
  }
  linkService() {
    this.openLinkServiceModal = true;
  }
  closeLinkService() {
    this.openLinkServiceModal = false;
  }
  isDepartmentClickedmethod(item) {
    // this is to know the clicked button and change the color by taking the array and returns true or false
    if (this.selectedDepartment.length > 0) {
      return this.selectedDepartment.includes(item.id);
    }
  }
  selectDepartment(item) {
    // this is to know the clicked button and change the color
    if (item) {
      if (this.selectedDepartment.includes(item.id)) {
        const selected = this.selectedDepartment.indexOf(item.id);
        this.selectedDepartment.splice(selected, 1);
        this.departService.removeEmployeeFromDepartment(item.id, this.employee.uid);
        this.departmentGlobal = this.selectedDepartment;
      }
      else {
        this.selectedDepartment.push(item.id);
        this.departService.addEmployeeToDepartment(item.id, this.employee.uid);
        this.departmentGlobal = this.selectedDepartment;
      }
    }
  }
  isServiceClickedmethod(item) {
    if (this.selectedService.length > 0) {
      return this.selectedService.includes(item.id);
    }
  }
  selectServices(item) {
    if (item) {
      if (this.selectedService.includes(item.id)) {
        const selected = this.selectedService.indexOf(item.id);
        this.selectedService.splice(selected, 1);
        this.selectedServiceObj.splice(selected, 1);
        this.employeeServicesService.removeEmployeeToService(item.id, this.employee.uid);
        this.serviceGlobal = this.selectedService;
      }
      else {
        this.selectedService.push(item.id);
        this.selectedServiceObj.push(item);
        this.employeeServicesService.addEmployeeToService(item.id, this.employee.uid);
        this.serviceGlobal = [...new Set(this.selectedService)];
      }
    }
  }
  assign() {
    this.closeDepartmentModal();
  }
  assignToService() {
    this.closeLinkService();
  }
  reset() {
    this.contactForm.patchValue({
      subject: '',
      emailbody: this.greetings + ' <br /> <br />  <br /> <br />' + this.signOff,
      messageSMS: this.setUp.messageSMS,
    });
  }
  sendEmployee(subject, body, message) {
    if (this.contactMsg == 'Email') {
      // const emailModel = new EmailModel();
      const emailModel = new CustomAdminEmailModel();
      emailModel.subject = subject;
      emailModel.body = body;
      emailModel.isNationApp = this.isNationApp;

      emailModel.body += '<p>';

      if (this.appLogo) {
        emailModel.body += `<img src="${this.appLogo}"
        width="100" height="100" style=" display: block; alt="Logo">`;
      }
      emailModel.body += '</p>';

      if (environment.firstNation.name !== "nationalchief") {
        emailModel.body += `<p>To stop receiving emails please login to <a href="${this.domainName}">${this.domainName}</a></p>`
        emailModel.body += `<p class="space-y-2"> Go to your preferences and disable Email, SMS or Both.</p>`
      }
      
      this.storeEmail = [];
      this.storeEmail.push(this.Email);
      emailModel.emailList = this.storeEmail;

      // custom send email used
      emailModel.host = "smtp.gmail.com",
      emailModel.adminEmail = "mkproject.app@gmail.com",
      emailModel.appPassword = "ytklyidomvqfmtky",

      this.openContactModal = false;
      this.emailService.sendCustomEmail(emailModel).subscribe((res) => {
        if (res) {
          this.reset();
          this.toast({ html: 'Email sent Successfully', classes: 'green', type: 'success' });

        }
        else {
          this.toast({ html: 'Error occured', classes: 'red', type: 'failure' });
        }
      });
    }
    else if (this.contactMsg == 'SMS') {
      let smsModel = new SMSModel();
      if(this.setUp.general === 'buyNum'){
        smsModel.body = this.setUp.greetingMessage + ", \n";
      }else{
        smsModel.body = this.employee.GivenName + ", \n";
      }
      smsModel.body += this.helperService.truncateHTML(message, "1000");
      if(this.setUp.links && this.setUp.general === 'buyNum' && this.setUp.linkSocial){
        smsModel.body += this.setUp.links + "\n";
      }
    
      if (environment.firstNation.name !== "nationalchief" && this.setUp.general === 'existingNumber') {
        smsModel.body += `\nTo stop receiving emails please login to ${this.domainName} \n`
        smsModel.body += `Go to your preferences and disable Email, SMS or Both. \n`
      }else if(this.setUp.general === 'buyNum' && this.setUp.customizeUnsubscribe){
        smsModel.body += this.setUp.unSubscribe;
      }

      /*
      smsModel.body = this.helperService.truncateHTML(message, "1000");

      if (environment.firstNation.name !== "nationalchief") {
        smsModel.body += `\n To stop receiving emails please login to ${this.domainName} \n`;
        smsModel.body += `\n Go to your preferences and disable Email, SMS or Both.`
      }*/
      smsModel.phoneNumberList = [];
      smsModel.phoneNumberList.push(this.cellPhone);
      smsModel.from = this.availablePhone;
      this.openContactModal = false

      this.sMSService.sendSMS(smsModel).subscribe((res) => {
        if (res) {
          this.adminSMSCounter++;
          this.adminCounter.SMSTotalCounter = this.adminSMSCounter;
          this.counter.updateCounter(this.adminCounter);
          this.reset();
          this.toast({ html: 'SMS Message sent Successfully', classes: 'green', type: 'success' });
        }
        else {
          this.toast({ html: 'Error occured sending SMS', classes: 'red', type: 'failure' });
        }
      });
    }
  }
  suspendEmployee() {
    this.employee.status = 'Disabled';
    this.employee.reason = this.reason;
    this.disableStopModal =  false;
    this.userService.updateUser(this.employee).then(res => {
      this.toast({ html: 'User Disabled', classes: 'red', type: 'failure' });
    });
  }
  restoreEmployee() {
    this.employee.status = 'Enabled';
    this.employee.reason = "";
    this.closeEnableModal();
    this.userService.updateUser(this.employee).then(() => {
      this.toast({ html: 'User Restored', classes: 'red', type: 'success' });
    });
  }
  truncateHTML(text: string, limit: string): string {
    const charlimit = +limit;
    if (!text || text.length <= charlimit) {
      return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ');
    }
    return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ').substring(0, charlimit) + '...';
  }

  togglePublic(event) {
    this.publicShow = !this.publicShow;
    this.SubscribeShow = false;
    this.noShow = false;
    if (event.target.checked) {
      this.employee.ShowEmployee = 'Public';
      this.userService.updateUser(this.employee).then(() => { });
    }
  }
  toggleSubscribe(event) {
    this.SubscribeShow = !this.SubscribeShow;
    this.publicShow = false;
    this.noShow = false;
    if (event.target.checked) {
      this.employee.ShowEmployee = 'Subscriber';
      this.userService.updateUser(this.employee).then(() => { });
    }
  }
  toggleDonotshow(event) {
    this.noShow = !this.noShow;
    this.publicShow = false;
    this.SubscribeShow = false;
    if (event.target.checked) {
      this.employee.ShowEmployee = 'No';
      this.userService.updateUser(this.employee).then(() => { });

    }
  }
  togglePermission(propertyName, permissionIndex) {
    this[propertyName] = !this[propertyName];
    this.permission[permissionIndex][propertyName] = !this.permMember[propertyName];
  }

  togglePermissionPES(propertyName, permissionIndex) {
    this[propertyName] = !this[propertyName];
    this.permission[permissionIndex][propertyName] = !this.permPES[propertyName];
  }

  togglePermissionSocial(propertyName, permissionIndex) {
    this[propertyName] = !this[propertyName];
    this.permission[permissionIndex][propertyName] = !this.permSocial[propertyName];
  }

  togglePermissionChat(propertyName, permissionIndex) {
    this[propertyName] = !this[propertyName];
    this.permission[permissionIndex][propertyName] = !this.permChat[propertyName];
  }
  togglePermissionCustomPage(propertyName, permissionIndex) {
    this[propertyName] = !this[propertyName];
    this.permission[permissionIndex][propertyName] = !this.permCustomPage[propertyName];
  }
  
  togglePermissionSpecial(propertyName, permissionIndex) {
    this[propertyName] = !this[propertyName];
    this.permission[permissionIndex][propertyName] = !this.permSpecial[propertyName];
  }

  //Analytics
  toggleViewAnalytics() {
    this.viewAnalytics = !this.viewAnalytics;
    if (!this.perm.viewAnalytics) {
      this.permission[2].viewAnalytics = true;
    } else {
      this.permission[2].viewAnalytics = false;
    }
  }
  toggleAllChatPrivilege() {
    this.allChatPriviledge = !this.allChatPriviledge;
    if (this.allChatPriviledge) {
      this.permChat = {
        privateChat: true, groups: true, createGroup: true, newEmployee: true
      }
      this.permission[5] = this.permChat;

    } else {
      this.permChat = {
        privateChat: false, groups: false, createGroup: false, newEmployee: false

      }
      this.permission[5] = this.permChat;
    }
  }

  toggleAllCustomPagePrivilege() {
    this.allCustomPagePrivilege = !this.allCustomPagePrivilege;
    if (this.allCustomPagePrivilege) {
      this.permCustomPage = {
        viewPage: true,createPage: true, editPage: true, deletePage: true, 
        viewItem: true, createItem: true, editItem: true, deleteItem: true
      }
      this.permission[6] = this.permCustomPage;

    } else {
      this.permCustomPage = {
        viewPage: false, createPage: false, editPage: false, deletePage: false, 
        viewItem: false, createItem: false, editItem: false, deleteItem: false
      }
      this.permission[6] = this.permCustomPage;
    }
  }



  toggleAllMemberPrivilege() {
    this.allMemberPriviledge = !this.allMemberPriviledge;
    if (this.allMemberPriviledge) {
      this.permMember = {
        inviteSub: true, inviteEmp: true, enableDisableSub: true, enableDisableEmp: true, acceptRequest: true,
        declineRequest: true, resetPass: true, contactUsers: true, scheduleEmp: true, addDepartment: true,
        editDepartment: true, deleteDepartment: true,
      }
      this.permission[0] = this.permMember;

    } else {
      this.permMember = {
        inviteSub: false, inviteEmp: false, enableDisableSub: false, enableDisableEmp: false, acceptRequest: false,
        declineRequest: false, resetPass: false, contactUsers: false, scheduleEmp: false, addDepartment: false,
        editDepartment: false, deleteDepartment: false
      }
      this.permission[0] = this.permMember;
    }
  }
    
  toggleAllPESPrivilege() {
    this.allPESPriviledge = !this.allPESPriviledge;
    if (this.allPESPriviledge) {
      this.permPES = {
        createPosts: true, editPost: true, deletePost: true, createEvents: true, editEvents: true, deleteEvents: true, createServices: true,
        editServices: true, deleteServices: true, createProducts: true, editProducts: true, deleteProducts: true,
        addCategoryProducts: true, editCategoryProducts: true, addServiceCategory: true, editServiceCategory: true,
      }
      this.permission[1] = this.permPES;
    } else {
      this.permPES = {
        createPosts: false, editPost: false, deletePost: false, createEvents: false, editEvents: false, deleteEvents: false, createServices: false,
        editServices: false, deleteServices: false, createProducts: false, editProducts: false, deleteProducts: false,
        addCategoryProducts: false, editCategoryProducts: false, addServiceCategory: false, editServiceCategory: false,
      }
      this.permission[1] = this.permPES;

    }
  }
  toggleAllSocialPrivilege() {
    this.allSocialPriviledge = !this.allSocialPriviledge;
    if (this.allSocialPriviledge) {
      this.permSocial = {
        linkAccount: true, unlinkAccount: true, createSocialPost: true,
        editSocialPost: true, deleteSocialPost: true, socialViewAnalytics: true, createProfile: true, deleteProfile: true
      }
      this.permission[3] = this.permSocial;

    } else {
      this.permSocial = {
        linkAccount: false, unlinkAccount: false, createSocialPost: false,
        editSocialPost: false, deleteSocialPost: false, socialViewAnalytics: false, createProfile: false, deleteProfile: false
      }
    }
    this.permission[3] = this.permSocial;

  }
  permissionModal() {
    this.openPermissionModal = true;
  }
  closePermissionModal() {
    this.openPermissionModal = false;
  }
  savePermission() {
    this.permissionGlobal = this.permissionService.getPermissionByEmployeeId(this.id).valueChanges().subscribe((permission: any) => {
      this.permissionEdit = permission[0];
      if (permission && (permission.length !== 0)) {

        this.permissionEdit.permission = this.permission.slice();
        this.permissionService.updatePermission(this.permissionEdit).then(res => { });
        this.toast({ html: 'Permissions has been edited Successfully', classes: 'green', type: 'success' });
      }
      else {
        this.toast({ html: 'Permissions has been saved Successfully', classes: 'green', type: 'success' });
        this.permission = [this.permMember, this.permPES, this.perm, this.permSocial, this.permSpecial, this.permChat, this.permCustomPage]
        this.permissionService.savePermission({ id: '', permission: this.permission, employeeId: this.id });
      }
      this.permissionGlobal.unsubscribe();
      this.closePermissionModal();
    });
    setTimeout(() => {
      this.location.back();
    }, 2000);
  }
  openDeleteModal() {
    this.deleteModal = true;
  }
  openDeleteModalOne() {
    this.confirmDeleteModal = true;
  }
  openSecondModalTwo() {
    this.confirmDeleteModal = false;
    this.confirmSecondModal = true;
  }
  openThreeModal() {
    this.confirmSecondModal = false;
    this.confirmThreeModal = true;
  }
  beforeDelete() {
    this.confirmThreeModal = false;
    this.beforeDeleteModal = true
    setTimeout(() => {
      this.beforeDeleteModal = false
      this.deleteUser(this.currentUser);
    }, 2500);
  }

  deleteUser(currentUser) {
    this.deleteModal = false;
    this.afAuth.currentUser.then(user => {
      this.userService.deleteUserAccount(currentUser.uid);
      user?.delete();
      setTimeout(() => {
        this.router.navigate(['/'])
      }, 800)
    }).catch((error) => {
      this.toast({ html: `${{ error }}`, classes: 'red', type: 'failure' });
    });

  }
  selectCollapseToOpen(collapsable) {
      // Toggle the state of the specified collapsible
    if(!this.collapsibleStates[collapsable]){
      // Close all collapsible sections first
      this.closeAllCollapse();
      this.collapsibleStates[collapsable] = !this.collapsibleStates[collapsable];

    }else{
    this.collapsibleStates[collapsable] = !this.collapsibleStates[collapsable];
    }

  }
  closeAllCollapse() {
    // Reset all collapsible states to false
    for (const collapsible in this.collapsibleStates) {
      this.collapsibleStates[collapsible] = false;
    }
  }
  toast(obj){
    this.helperService.toast(obj);
  }

  get subject() { return this.contactForm.get('subject'); }
  get message() { return this.contactForm.get('message'); }
  get body() { return this.contactForm.get('body'); }
  get contact() { return this.contactForm.get('contact') }
}
