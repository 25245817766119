<!-- <nav class="pagination">
  <ul class="pagination">
    <li class="page-item">
      <button class="page-button" [disabled]="currentPage === 1" (click)="prevPage()">Previous</button>
    </li>
    <li class="page-item">
      <span class="page-info">{{ currentPage }} / {{ totalPages }}</span>
    </li>
    <li class="page-item">
      <button class="page-button" [disabled]="currentPage === totalPages" (click)="nextPage()">Next</button>
    </li>
  </ul>
</nav>
 -->

<nav class="flex w-full justify-center items-center" *ngIf="totalItems > 0">
  <ul class="flex gap-[5px] list-none">
    <li class="list-none" [ngClass]="{'cursor-not-allowed': currentPage === 1, 'cursor-pointer': currentPage > 1}">
      <button class="text-gray-500 flex justify-center items-center py-[10px] px-[20px] shadow-sm"
        [ngClass]="{'cursor-not-allowed': currentPage === 1, 'cursor-pointer': currentPage > 1}"
        [disabled]="currentPage === 1" (click)="prevPage()">&laquo;</button>
    </li>
    <li class="list-none flex justify-center items-center">
      <span class="page-info">{{ currentPage }} / {{ totalPages }}</span>
    </li>
    <li class="list-none" [ngClass]="{'cursor-not-allowed': currentPage === totalPages, 'cursor-pointer': currentPage > totalPages}">
      <button class="text-gray-500 flex justify-center items-center py-[10px] px-[20px] shadow-sm"
        [ngClass]="{'cursor-not-allowed': currentPage === totalPages, 'cursor-pointer': currentPage > totalPages}"
        [disabled]="currentPage === totalPages" (click)="nextPage()">&raquo;</button>
    </li>
  </ul>
</nav>