import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { UserService } from 'src/app/services/firebase/user.service';
@Component({
  selector: 'app-invited-employees',
  templateUrl: './invited-employees.component.html',
  styleUrls: ['./invited-employees.component.css']
})
export class InvitedEmployeesComponent implements OnInit {

  invitedEmployees: any = [];
  totalInvitesLength: any;
  p: 1
  currentUser: any;
  isAdmin = false;
  constructor(private userService: UserService, 
              private authService: AuthService,
              private router: Router) { }

  ngOnInit(): void {

    this.currentUser = this.authService.getGlobalUser();
    if (this.currentUser && this.currentUser.role) {
      
        this.isAdmin = true;
      }
    this.userService.getAllInvitedEmployee().valueChanges().subscribe(invites => {
      if (invites.length > 0) {
        this.invitedEmployees = invites;
        this.totalInvitesLength = invites.length;
      }
    });
  }
  viewEmployeeProfile(userModel: any) {
    if (this.isAdmin) {
      this.router.navigate(['/admin/employee-detail/', userModel.uid]);
    } else {
      this.router.navigate(['/employee/employee-detail/', userModel.uid]);
    }

  }

}
