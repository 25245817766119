import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-empty-page',
  templateUrl: './empty-page.component.html',
  styleUrl: './empty-page.component.css'
})
export class EmptyPageComponent {
  @Input() description = "It looks like there's nothing here yet. Start adding data."
  @Input() title = "No Data Available"
  @Output() newElement = new EventEmitter();

  onAction() {
    // Handle the action, e.g., navigate to a data creation page
    console.log('Add Data button clicked');
  }
  addNewElement(){
    this.newElement.emit()
  }
}
