import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-form-button',
  templateUrl: './form-button.component.html',
  styleUrls: ['./form-button.component.css']
})
export class FormButtonComponent implements OnInit {

  @Input() buttonText;
  @Input() disabled;
  @Input() buttonType;
  @Input() buttonStyle;
  @Output() click = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    console.log("Disabled: ", this.disabled)
  }
  buttonClicked(){
    console.log("Clicked")
    this.click.emit()
  }

}
