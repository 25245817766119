<div class="row">

  <div class="col s6 m4 l3">
    <div class="card blue hoverable" (click)="backClicked()" style="height: 95px;">
      <div class="card-content">
        <div class="row center">
          <i class="material-icons white-text">house</i>
          <br />
          <strong class="white-text">Back</strong>
        </div>
      </div>
    </div>
  </div>

  <div class="col s6 m4 l3" >
    <a [routerLink]="['/employee/services-setting']">
      <div class="card green darken-2 hoverable " style="height: 95px;">
        <div class="card-content">
          <div class="row center">
            <i class="material-icons white-text">apps</i><br />
            <strong class="white-text">Services Setting</strong>
          </div>
        </div>
      </div>
    </a>
  </div>

  <div class="col s6 m4 l3">
    <a [routerLink]="['/employee/contact-settings']">
      <div class="card purple lighten-1 hoverable " style="height: 95px;">
        <div class="card-content">
          <div class="row center">
            <i class="material-icons white-text">contact_page</i><br />
            <strong class="white-text">Social Media Contact</strong>
          </div>
        </div>
      </div>
    </a>
  </div>

  <div class="col s6 m4 l3">
    <a [routerLink]="['/employee/my-profile']">
      <div class="card red lighten-1 hoverable " style="height: 95px;">
        <div class="card-content">
          <div class="row center">
            <i class="material-icons white-text">manage_accounts</i><br />
            <strong  class="white-text">Profile Setting</strong>
          </div>
        </div>
      </div>
    </a>
  </div>
</div>
