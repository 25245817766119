export class AnalyticAccModel {
    accountName: string;
    regionCode: string;
    redirectUrl: string;
    accessToken: string;
}


export class UserCollectionModel {
    AppName: string;
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    id: string;
    role: string;
    contact: string[] | null;
    trialPeriod: number = 30;
    mobileNumber: string;
    allowPushNotification: boolean;

}

export class WebAppModel {
    AppName: string;
    AccountId: string;
}

export class BillingModel {
    AppName: string;
    BillingAccount: string = "billingAccounts/01E866-F50682-393BF0";
}

export class AddUserToFirebaseModel {
    AppName: string;
    email: string;
    password: string;
}

export class UpdateFirebaseRulesModel {
    CollectionName: string;
    ReadAuth: boolean;
    WriteAuth: boolean;
}

export class DataStreamParamModel {
    displayName: string;
    defaultUri: string;
    propertyId: string;
}