import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-analytics-all',
  templateUrl: './analytics-all.component.html',
  styleUrls: ['./analytics-all.component.css']
})
export class AnalyticsAllComponent implements OnInit {

  ispageClicked: boolean = true;
  isuserClicked: boolean = false;
  ischannelClicked: boolean = false;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.userClicked();
  }

  userClicked(){
    this.ispageClicked = false;
    this.isuserClicked = true;
    this.ischannelClicked = false;
    this.router.navigate(['admin/analytics-app/user-analytics']);
  }

  pageClicked(){
    this.ispageClicked = true;
    this.isuserClicked = false;
    this.ischannelClicked = false;
    this.router.navigate(['admin/analytics-app/page-analytics']);
  }

  channelClicked(){
    this.ispageClicked = false;
    this.isuserClicked = false;
    this.ischannelClicked = true;
    this.router.navigate(['admin/analytics-app/channle-analytics']);
  }

}
