import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnySoaRecord } from 'dns';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { GlobalConstants } from 'src/app/models/GlobalConstants';

@Component({
  selector: 'app-help-center',
  templateUrl: './help-center.component.html',
  styleUrls: ['./help-center.component.css']
})
export class HelpCenterComponent implements OnInit {

  buildMobile: boolean;
  setUpMobile: boolean;
  aboutYou: boolean;
  offerCustomers: boolean;
  blog: boolean;
  analytic: boolean;
  communication: boolean;
  employees: boolean;
  techinicalReq: boolean;
  socialMedia: boolean;
  gettingStart:boolean;

  currentUser: any;
  isAdmin : boolean;
  isEmployee: boolean;
  defaultLogo= GlobalConstants.defaultLogo  
  currentAppName= GlobalConstants.currentAppName

  constructor(private router: Router,
              private authService: AuthService) { }

  ngOnInit(): void {
    this.currentUser = this.authService.getGlobalUser();
    if(this.currentUser && this.currentUser.role == 'Admin'){
      this.isAdmin = true;
    }
    else if(this.currentUser && this.currentUser.role == 'Employee'){
      this.isEmployee = true;
    }
  }

  mobileBuild(){
    if(this.isAdmin){
      this.router.navigate(['/admin/guide-details'], {state : {displayHelp : "buildMobile"}});
    }
    else if(this.isEmployee){
      this.router.navigate(['/employee/guide-details'], {state : {displayHelp : "buildMobile"}});
    }
    else{
      this.router.navigate(['/auth/guide-details'], {state : {displayHelp : "buildMobile"}});
    }
  }

  mobileSetUp(){
    if(this.isAdmin){
      this.router.navigate(['/admin/guide-details'], {state : {displayHelp : "setUp"}});
    }
    else if(this.isEmployee){
      this.router.navigate(['/employee/guide-details'], {state : {displayHelp : "setUp"}});
    }
    else{
      this.router.navigate(['/auth/guide-details'], {state : {displayHelp : "setUp"}});
    }
  }

  about(){
    if(this.isAdmin){
      this.router.navigate(['/admin/guide-details'], {state : {displayHelp : "about"}});
    }
    else if(this.isEmployee){
      this.router.navigate(['/employee/guide-details'], {state : {displayHelp : "about"}});
    }
    else{
      this.router.navigate(['/auth/guide-details'], {state : {displayHelp : "about"}});
    }
  }

  customerOffer(){
    if(this.isAdmin){
      this.router.navigate(['/admin/guide-details'], {state : {displayHelp : "customerOffer"}});
    }
    else if(this.isEmployee){
      this.router.navigate(['/employee/guide-details'], {state : {displayHelp : "customerOffer"}});
    }
    else{
      this.router.navigate(['/auth/guide-details'], {state : {displayHelp : "customerOffer"}});

    }
  }

  gettingStarted(){
    if(this.isAdmin){
      this.router.navigate(['/admin/guide-details'], {state : {displayHelp : "gettingStarted"}});
    }
    else if(this.isEmployee){
      this.router.navigate(['/employee/guide-details'], {state : {displayHelp : "gettingStarted"}});
    }
    else{
      this.router.navigate(['/auth/guide-details'], {state : {displayHelp : "gettingStarted"}});

    }
  }
  
  blogs(){
    if(this.isAdmin){
      this.router.navigate(['/admin/guide-details'], {state : {displayHelp : "blog"}});

    }
    else if(this.isEmployee){
      this.router.navigate(['/employee/guide-details'], {state : {displayHelp : "blog"}});
    }    
    else{
      this.router.navigate(['/auth/guide-details'], {state : {displayHelp : "blog"}});

    }
  }

  analytics(){
    if(this.isAdmin){
      this.router.navigate(['/admin/guide-details'], {state : {displayHelp : "analytics"}});
    }
    else if(this.isEmployee){
      this.router.navigate(['/employee/guide-details'], {state : {displayHelp : "analytics"}});
    }
    else{
      this.router.navigate(['/auth/guide-details'], {state : {displayHelp : "analytics"}});

    }
  }

  communications(){
    if(this.isAdmin){
      this.router.navigate(['/admin/guide-details'], {state : {displayHelp : "communications"}});
    }

    else if(this.isEmployee){
      this.router.navigate(['/employee/guide-details'], {state : {displayHelp : "communications"}});
    }

    else{
      this.router.navigate(['/auth/guide-details'], {state : {displayHelp : "communications"}});

    }
  }

  employee(){
    if(this.isAdmin){
      this.router.navigate(['/admin/guide-details'], {state : {displayHelp : "employee"}});
    }

    else if(this.isEmployee){
      this.router.navigate(['/employee/guide-details'], {state : {displayHelp : "employee"}});
    }

    else{
      this.router.navigate(['/auth/guide-details'], {state : {displayHelp : "employee"}});

    }
  }

  tRequirement(){
    if(this.isAdmin){
      this.router.navigate(['/admin/guide-details'], {state : {displayHelp : "requirement"}});

    }
    else if(this.isEmployee){
      this.router.navigate(['/employee/guide-details'], {state : {displayHelp : "requirement"}});
    }
    else{
      this.router.navigate(['/auth/guide-details'], {state : {displayHelp : "requirement"}});
    }

  }

  socialMedias(){
    if(this.isAdmin){
      this.router.navigate(['/admin/guide-details'], {state : {displayHelp : "socialMedia"}});
    }
    else if(this.isEmployee){
      this.router.navigate(['/employee/guide-details'], {state : {displayHelp : "socialMedia"}});
    }
    else{
      this.router.navigate(['/auth/guide-details'], {state : {displayHelp : "socialMedia"}});

    }
  }

}
