import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-service-product',
  templateUrl: './service-product.component.html',
  styleUrls: ['./service-product.component.css']
})
export class ServiceProductComponent implements OnInit {
  settings: any;
  productsRoute;
  serviceRoute;

  constructor(private appSettingsService: AppSettingsService,
              private authService: AuthService,
              private activatedRoute: ActivatedRoute,
              private router: Router) { }

    currentUser: any;

  ngOnInit(): void {
    this.currentUser = this.authService.getGlobalUser();

    this.appSettingsService.getAppSettingsList().valueChanges().subscribe((settings) => {
      if (settings.length > 0){
        this.settings = settings[0];
        if (this.settings.isBusinessApp){
          this.productsRoute = this.settings.hasProductsCategory ? '../../admin/product-catagory' : '../../admin/view-products';
          this.serviceRoute = this.settings.hasServicesCategory ? '../../admin/service-category' : '../../admin/services';

          if (this.settings.serviceType !== 'Both'){
              this.navigate();
          }
        }
      }
    });
  }
  navigate(){
    let route;
    if (this.settings.serviceType === 'Service'){
      if (this.settings.hasServicesCategory){
        route = 'admin/service-category';
      }else{
        route = 'admin/services';

      }
    }else {
      if (this.settings.hasProductsCategory){
        route = 'admin/product-catagory';
      }else{
        route = 'admin/view-products';

      }
    }
    this.router.navigate([route]);

  }

}
