<div class="flex flex-col justify-center rounded-xl border-2  w-full md:w2/3 bg-white h-full" *ngIf="data"
[ngClass]="getBorder(data.platform)" [ngStyle]="borderdPlatform() ? getStyleBorder() : ''">
    <div class="py-3 flex flex-col self-center">
        <div class="avatar self-center">
            <div class="w-24 rounded-full">
                <img loading="lazy" *ngIf="data.image" [src]="data.image" />
                <img
                      loading="lazy"

                      *ngIf="!data.image"

                      data-src="https://api.goingmobile.app/media/personal-app-assets/img/webp/emptyProfilePic.webp"
                                      
                      srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/emptyProfilePic.webp"
                                      
                      fallback="https://api.goingmobile.app/media/personal-app-assets/img/png/emptyProfilePic.png"
                                      
                      alt="ananymous profile picture."> 
            </div>
        </div>
        <div [ngClass]="{'font-bold text-xl' : !data.middleSubText}" class="text-center pt-2">
            {{data.username}}
        </div>
    </div>
    <!-- <div class="flex flex-col p-8 pb-0" *ngIf="data.middleSubText">
        <div class="">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
            Odio molestie habitasse quis ipsum, nunc. Massa senectus felis ultrices sed scelerisque consequat ut lacus, a.
        </div>
        <div class="flex flex-col self-center">
            <div class=" text-center pt-2">
                {{data.username}}
            </div>
            <div class=" text-center pt-2">
                {{data.username}}
            </div>
        </div>
    </div> -->
    <div *ngIf="data.userContent" class="md:pt-5 px-0 md:px-8 pb-8">
        <div [ngClass]="'w-full flex flex-row gap-4 justify-around text-[' + data.color + ']'">
            <div *ngFor="let item of data.userContent | keyvalue" class="pt-2 text-center">
                <p class="text-3xl md:text-4xl  font-extrabold">{{item.value}}</p>
                <p class="text-black">{{item.key}}</p>
            </div>
        </div>
    </div>
</div>