<div class="font-body ">
    <!-- TOP HEADER BUTTONS FOR THE ANALYTICS PAGE  -->
    <div class="flex space-x-2 md:space-x-28 items-center justify-center">
        <div class="flex-row text-center  text-md rounded-full items-center justify-center mx-4">
            <button (click)="userClicked()"
              class="font-medium cursor-pointer border-2 border-customTeal px-3 md:px-6 py-2 md:py-1 rounded-full md:font-bold"
              [ngClass]="{'text-white bg-customTeal': isuserClicked, 'text-black': !isuserClicked}">Subscribers
            </button>
        </div>
        <div class="flex-row text-center rounded-full text-md">
            <!--Added the click function-->
            <button (click)="pageClicked()"
              class="font-medium cursor-pointer border-2 border-customTeal px-3 md:px-6 py-2 md:py-1 rounded-full md:font-bold"
              [ngClass]="{'text-white bg-customTeal': ispageClicked, 'text-black': !ispageClicked}">Pages</button>  
        </div>
        <div class="flex-row text-center rounded-full text-md">
            <!--Added the click function-->
            <button (click)="channelClicked()"
              class="font-medium cursor-pointer border-2 border-customTeal px-3 md:px-6 py-2 md:py-1 rounded-full md:font-bold"
              [ngClass]="{'text-white bg-customTeal': ischannelClicked, 'text-black': !ischannelClicked}">Channel</button>                  
        </div>
        <br />      
    </div>      
</div>    
<router-outlet></router-outlet>

