// import { EditBlogComponent } from './../admin/blog/edit-blog/edit-blog.component';
// import { EditEventComponent } from './../admin/events/edit-event/edit-event.component';
import { AppointmentDetailComponent } from './../shared/appointment-detail/appointment-detail.component';
import { ViewPostComponent } from './../shared/view-post/view-post.component';
import { ServiceComponent } from '../app/service/service.component';
import { CreatEditServiceComponent } from './service/creat-edit-service/creat-edit-service.component';
import { ViewEventComponent } from '../shared/events/view-event/view-event.component';
import { ViewProfileComponent } from './../shared/view-profile/view-profile.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { EmployeeAuthGuard } from 'src/app/guards/employee-auth.guard';
import { AppointmentComponent } from './../admin/appointment/appointment.component';
import { AppointmentsComponent } from './appointment/appointment.component';

import { EmployeeHomeComponent } from './employee-home/employee-home.component';
import { EmployeeMainComponent } from './employee-main/employee-main.component';
import { ScheduleComponent } from '../shared/schedule/schedule.component';
import { SettingsComponent } from './settings/settings.component';
import { ViewEventsListComponent } from '../shared/events/view-events-list/view-events-list.component';
import { BlogListComponent } from '../shared/blog/blog-list/blog-list.component';
import { ViewBlogComponent } from '../shared/blog/view-blog/view-blog.component';
import { ProductsComponent } from '../admin/products/products.component';
import { ServiceProductComponent } from '../employee/service-product/service-product.component';
import { ViewProductComponent } from '../admin/view-product/view-product.component';
import { CatagoryComponent } from '../shared/catagory/catagory.component';
import { EditProfileComponent } from '../shared/edit-profile/edit-profile.component';
// import { AnalyticsComponent } from '../admin/analytics/analytics.component';
import { CreateEventComponent } from '../admin/events/create-event/create-event.component';
import { CreateBlogComponent } from '../admin/blog/create-blog/create-blog.component';
import { AppRequestComponent } from '../admin/app-request/app-request.component';
import { AppRequestDetailComponent } from '../admin/app-request/app-request-detail/app-request-detail.component';
import { UsersListComponent } from '../admin/users-list/users-list.component';
import { RegistrationRequestComponent } from '../admin/registration-request/registration-request.component';
import { EmployeeDetailComponent } from '../shared/employee-detail/employee-detail.component';
import { ClientDetailComponent } from '../shared/client-detail/client-detail.component';
import { ServiceCategoryComponent } from '../shared/service-category/service-category.component';
import { AdminSocialMainComponent } from '../admin/admin-social-main/admin-social-main.component';
import { SocialMediaDashBoardComponent } from '../shared/social-medial/dashboard/dashboard.component';
import { SocialMediaPostComponent } from '../shared/social-medial/post/post.component';
import { SocialMediaCreatePostComponent } from '../shared/social-medial/post/create-post/create-post.component';
import { SocialMediaAnalyticsComponent } from '../shared/social-medial/analytics/analytics.component';
import { SocialMediaSettingsComponent } from '../shared/social-medial/settings/settings.component';
import { SocialMediaPostDetailComponent } from '../shared/social-medial/post/post-detail/post.detail.component';
import { ServicesComponent } from '../admin/service/services/services.component';
import { ViewServiceComponent } from '../shared/services/view-service/view-service.component';
import { CreateServiceComponent } from '../admin/service/create-service/create-service.component';
import { EditServiceComponent } from '../admin/service/edit-service/edit-service.component';
import { CreateProductComponent } from '../admin/products/create-product/create-product.component';
import { EditProductComponent } from '../admin/products/edit-product/edit-product.component';
import { DepartmentsComponent } from '../admin/settings/departments/departments.component';
import { EmployeeSocialMainComponent } from '../admin/employee-social-main/employee-social-main.component';
import { ChatMessagingComponent } from '../shared/messaging/messaging.component';
import { AppointmentRescheduleComponent } from '../shared/appointment-reschedule/appointment-reschedule.component';
import { CreateClientComponent } from '../admin/invite-subscriber/create-client/create-client.component';
import { EditEventComponent } from '../admin/events/edit-event/edit-event.component';
import { EditBlogComponent } from '../admin/blog/edit-blog/edit-blog.component';
import { VerifyAccountRequestComponent } from '../admin/verify-account-request/verify-account-request.component';
import { LandingPageSettingsComponent } from '../admin/settings/landing-page-settings/landing-page-settings.component';
import { AboutSettingComponent } from '../admin/settings/about-setting/about-setting.component';
import { SignupSettingsComponent } from '../admin/settings/signup-settings/signup-settings.component';
import { AppSetupComponent } from '../admin/app-setup/app-setup.component';
import { HelpCenterComponent } from '../shared/help-center/help-center.component';
import { GuideDetailsComponent } from '../shared/help-center/guide-details/guide-details.component';
import { EmployeeEditComponent } from '../admin/users-list/employee-edit/employee-edit.component';
import { EditClientInfoComponent } from '../admin/invite-subscriber/create-client/edit-client-info/edit-client-info.component';
import { EmployeeFormComponent } from '../admin/users-list/employee-form/employee-form.component';
import { EditSubscriberComponent } from '../admin/invite-subscriber/edit-subscriber/edit-subscriber.component';
import { InviteSubscriberComponent } from '../admin/invite-subscriber/invite-subscriber.component';
import { CreateNewSectionComponent } from '../admin/custom_section/create-new-section/create-new-section.component';
import { SectionListComponent } from '../shared/custom-section/section-list/section-list.component';
import { AnalyticsSetupComponent } from '../admin/analytics-setup/analytics-setup.component';
import { CreateAnalyticsAccountComponent } from '../admin/analytics-setup/create-analytics-account/create-analytics-account.component';
import { ContactUsMessagesComponent } from '../admin/contact-us-messages/contact-us-messages.component';
import { AnalyticsAllComponent } from '../admin/analytics-all/analytics-all.component';
import { AnalyticsUserComponent } from '../admin/analytics-user/analytics-user.component';
import { AnalyticsPageComponent } from '../admin/analytics-page/analytics-page.component';
import { AnalyticsChannelComponent } from '../admin/analytics-channel/analytics-channel.component';
import { ContactListComponent } from '../admin/contact-list/contact-list.component';
import { WelcomeSettingComponent } from '../admin/settings/welcome-setting/welcome-setting.component';
import { ImportContactComponent } from '../admin/users-list/import-contact/import-contact.component';
import { SliderSettingComponent } from '../admin/settings/slider-setting/slider-setting.component';
import { ContactSettingsComponent } from '../admin/settings/contact-settings/contact-settings.component';
import { FooterSettingComponent } from '../admin/settings/footer-setting/footer-setting.component';

import { CreateSectionElementComponent } from '../admin/custom_section/create-section-element/create-section-element.component';
import { EditSectionElementComponent } from '../admin/custom_section/edit-section-element/edit-section-element.component';
import { ViewSectionComponent } from '../shared/custom-section/view-section/view-section.component';
import { EditCustomSectionComponent } from '../admin/custom_section/edit-custom-section/edit-custom-section.component';
import { AppointmentSettingComponent } from '../admin/settings/appointment-setting/appointment-setting.component';
import { FaqSettingComponent } from '../admin/settings/faq-setting/faq-setting.component';
import { FaqListComponent } from '../shared/faq-list/faq-list.component';
import { CreateAppointmentComponent } from '../admin/create-appointment/create-appointment.component';

const routes: Routes = [
  {
    path: 'employee',
    component: EmployeeMainComponent,
    canActivate: [EmployeeAuthGuard],
    children: [
      {
        path: 'dashboard',
        component: EmployeeHomeComponent,
        canActivate: [EmployeeAuthGuard]
      },
      {
        path: 'schedule',
        component: ScheduleComponent,
        canActivate: [EmployeeAuthGuard]
      },
      {
        path: 'appointments',
        component: AppointmentComponent,
        canActivate: [EmployeeAuthGuard]
      },      
      {
        path: 'appointment-setting',
        component: AppointmentSettingComponent,
        canActivate: [EmployeeAuthGuard]
      },
      {
        path:'create-new-section',
        component: CreateNewSectionComponent,
        canActivate: [EmployeeAuthGuard]
      },
      {
        path:'landing-page-settings',
        component: LandingPageSettingsComponent,
        canActivate: [EmployeeAuthGuard]
      },
      {
        path: 'appointment',
        component: AppointmentsComponent,
        canActivate: [EmployeeAuthGuard]
      },
      {
        path: 'appointment-detail/:id',
        component: AppointmentDetailComponent,
        canActivate: [EmployeeAuthGuard]
      },
      {
        path: 'reschedule-appointment/:id',
        component: AppointmentRescheduleComponent,
        canActivate: [EmployeeAuthGuard]
      },
      {
        path: 'book-appointment',
        component: CreateAppointmentComponent,
        canActivate: [EmployeeAuthGuard]
      },
      {
        path: 'appointment-detail/:id',
        component: AppointmentDetailComponent,
        canActivate: [EmployeeAuthGuard]
      },
      {
        path: 'view-profile/:id',
        component: ViewProfileComponent,
        canActivate: [EmployeeAuthGuard]
      },
      {
        path: 'event/:id',
        component: ViewEventComponent,
        canActivate: [EmployeeAuthGuard]
      },
      {

        path: 'welcome-setting',
        component: WelcomeSettingComponent,
        canActivate: [EmployeeAuthGuard]
      },
      {
        path: 'slider-setting',
        component: SliderSettingComponent,
        canActivate: [EmployeeAuthGuard]
      },
      {
        path: 'contact-setting',
        component: ContactSettingsComponent,
        canActivate: [EmployeeAuthGuard]
      },
      {
        path: 'footer-setting',
        component: FooterSettingComponent,
        canActivate: [EmployeeAuthGuard]
      },
      {
        path: 'events',
        component: ViewEventsListComponent,
        canActivate: [EmployeeAuthGuard]
      },
      {
        path: 'create-event',
        component: CreateEventComponent,
        canActivate: [EmployeeAuthGuard]
      },
      {
        path: 'edit-event/:id',
        component: EditEventComponent,
        canActivate: [EmployeeAuthGuard]
      },
      {
        path: 'edit-blog/:id',
        component: EditBlogComponent,
        canActivate: [EmployeeAuthGuard]
      },
      {
        path: 'events/:id',
        component: ViewEventComponent
      },
      {
        path: 'edit-event/:id',
        component: EditEventComponent,
      },
      {
        path: 'edit-blog/:id',
        component: EditBlogComponent,
      },
      {
        path: 'app-setup',
        component: AppSetupComponent,
      },
      {
        path: 'faq-setting',
        component: FaqSettingComponent,
      },
      {
        path: 'faq-list',
        component: FaqListComponent,
      },
      // {
      //   path:'landing-page-settings',
      //   component: LandingPageSettingsComponent,
      // },
      {
        path: 'settings',
        component: SettingsComponent,
        canActivate: [EmployeeAuthGuard]
      },
      {
        path: 'services-setting',
        component: ServiceComponent,
        canActivate: [EmployeeAuthGuard]
      },
      {
        path: 'create-service',
        component: CreateServiceComponent,
        canActivate: [EmployeeAuthGuard]
      },
      {
        path: 'users-list/import-contact',
        component: ImportContactComponent,
        canActivate: [EmployeeAuthGuard]
      },
      // {
      //   path: 'edit-service/:id',
      //   component: CreatEditServiceComponent,
      //   canActivate: [EmployeeAuthGuard]
      // },
      {
        path: 'blogs',
        component: BlogListComponent,
        canActivate: [EmployeeAuthGuard]
      },

      {
        path: 'create-blog',
        component: CreateBlogComponent,
      },
      {
        path: 'view-blog/:id',
        component: ViewBlogComponent
      },
      {
        path: 'edit-profile/:id',
        component: EditProfileComponent
      },
      {
        path: 'product-catagory',
        component: CatagoryComponent
      },
      {
        path: 'products/:id',
        component: ViewProductComponent
        //component: ProductsComponent
      },
      {
        path: 'products',
        component: ProductsComponent
      },
      {
        path: 'create-product',
        component: CreateProductComponent,
      },
      {
        path: 'edit-product/:id',
        component: EditProductComponent,
      },
      {
        path: 'view-products/:id',
        component: ProductsComponent
        //component: ViewProductComponent
      },
      {
        path: 'view-products',
        component: ProductsComponent,
      },
      {
        path: 'services',
        component: ServicesComponent,
      },
      {
        path:'edit-subscriber',
        component: EditSubscriberComponent
      },
      {
        path:'subscriber-form',
        // component: InviteSubscriberComponent
        component: ImportContactComponent
      },
      // {
      //   path:'services',
      //   component: ServiceComponent
      // },
      {
        path: 'service-category',
        component: ServiceCategoryComponent
      },
      {
        path: 'services/:id',
        component: ServicesComponent
      },
      {
        path: 'service/:id',
        component: ViewServiceComponent,
      },
      {
        path: 'create-service',
        component: CreateServiceComponent,
      },
      {
        path: 'edit-service/:id',
        component: EditServiceComponent,
      },
      {
        path: 'analytics-app',
        component: AnalyticsAllComponent,
        canActivate: [EmployeeAuthGuard],
        children: [
          {
            path: 'user-analytics',
            component: AnalyticsUserComponent,
          },
          {
            path: 'page-analytics',
            component: AnalyticsPageComponent,
          },
          {
            path: 'channle-analytics',
            component: AnalyticsChannelComponent,
          },
        ]
      },
      {
        path: 'service-product',
        component: ServiceProductComponent
      },
      {
        path: 'analytics-setup',
        component: AnalyticsSetupComponent,
      },
      {
        path: 'create-analytics-account',
        component: CreateAnalyticsAccountComponent,
      },
      {
        path: 'create-analytics-account',
        component: CreateAnalyticsAccountComponent,
        pathMatch: 'full',
        data: {
          queryParams: ['code', 'scope']
        },
      },

      {
        path: 'contact-messages',
        component: ContactUsMessagesComponent,
        canActivate: [EmployeeAuthGuard]

      },
      {
        path: 'contact-list',
        component: ContactListComponent,
        canActivate: [EmployeeAuthGuard]

      },
      // {
      //   path: 'analytics',
      //   component: AnalyticsComponent
      // },
      // {
      //   path: 'landing-page-settings',
      //   component: LandingPageSettingsComponent,
      // },
      {
        path: 'about',
        component: AboutSettingComponent,
      },
      {
        path: 'signup',
        component: SignupSettingsComponent,
      },

      {
        path: 'app-request',
        component: AppRequestComponent,
      },
      {
        path: 'app-request-detail/:id',
        component: AppRequestDetailComponent,
      },
      {
        path: 'search-users',
        component: UsersListComponent,
      },
      {
        path: 'employee-edit/:id',
        component: EmployeeEditComponent,
      },
      {
        path: 'edit-clientInfo',
        component: EditClientInfoComponent
      },
      {
        path: 'verify-account-request',
        component: VerifyAccountRequestComponent
      },
      {
        path: 'registration-request',
        component: RegistrationRequestComponent,
      },
      {
        path: 'employee-detail/:id',
        component: EmployeeDetailComponent,
      },
      {
        path: 'employee-profile',
        component: EditProfileComponent
      },
      {
        path: 'client-detail/:id',
        component: ClientDetailComponent,
      },
      {
        path: 'create-client',
        component: CreateClientComponent
      },
      {
        path: 'organization',
        component: DepartmentsComponent,
      },
      {
        path: 'help-center',
        component: HelpCenterComponent,
      },
      {
        path: 'guide-details',
        component: GuideDetailsComponent,
      },
      {
        path: 'chat-list',
        component: ChatMessagingComponent,
      },

      {
        path: 'chat-list/:chatType',
        component: ChatMessagingComponent,
      },
      {
        path: 'employees',
        component: DepartmentsComponent,
        canActivate: [EmployeeAuthGuard]
      },
      {
        path: 'employees/:deptOrEmp',
        component: DepartmentsComponent,
        canActivate: [EmployeeAuthGuard]
      },
      {
        path: 'departments',
        component: DepartmentsComponent,
        canActivate: [EmployeeAuthGuard]
      },
      {
        path: 'departments/:deptOrEmp',
        component: DepartmentsComponent,
        canActivate: [EmployeeAuthGuard]
      },
      {
        path: 'employee-form',
        component: EmployeeFormComponent
      },
      {
        path: 'custom-section-list/:id',
        component: SectionListComponent,
        canActivate: [EmployeeAuthGuard]

      },
      {
        path: 'create-section-element/:id',
        component: CreateSectionElementComponent,
        canActivate: [EmployeeAuthGuard]

      },      
      {
        path: 'edit-section-element/:id',
        component: EditSectionElementComponent,
        canActivate: [EmployeeAuthGuard]

      },
      {
        path: 'view-custom-section/:id',
        component: ViewSectionComponent,
        canActivate: [EmployeeAuthGuard]

      },      
      {
        path: 'edit-custom-section/:id',
        component: EditCustomSectionComponent,
        canActivate: [EmployeeAuthGuard]
      },
    ],

  },
  {
    path: 'employee-social',
    component: EmployeeSocialMainComponent,
    children: [
      {
        path: 'dashboard',
        // component: SocialMediaAnalysisComponent,
        component: SocialMediaDashBoardComponent,
      },
      {
        path: 'post',
        component: SocialMediaPostComponent,
      },
      {
        path: 'create-post',
        component: SocialMediaCreatePostComponent,
      },
      {
        path: 'posts',
        component: SocialMediaPostComponent,
      },
      {
        path: 'analytics',
        component: SocialMediaAnalyticsComponent,
      },
      {
        path: 'settings',
        component: SocialMediaSettingsComponent,
      },
      {
        path: 'post-detail/:id',
        component: SocialMediaPostDetailComponent,
      },
      { path: '', redirectTo: 'social-media', pathMatch: 'full' }
    ]

  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class EmployeeRoutingModule { }

