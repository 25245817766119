import { AuthService } from 'src/app/services/firebase/auth.service';
import { NotificationsService } from './../../../services/firebase/notifications.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-notifications',
  templateUrl: './admin-notifications.component.html',
  styleUrls: ['./admin-notifications.component.css']
})
export class AdminNotificationsComponent implements OnInit {

  user: any;
  notifications: any;
  constructor(private authService: AuthService,
              private notificationsService: NotificationsService) { }

  ngOnInit(): void {
    this.user = this.authService.getGlobalUser();
    this.notificationsService.getMyNotifications(this.user.uid).valueChanges().subscribe(notifications => {
      if(notifications.length > 0 ){
        this.notifications = notifications;
      }
    });
  }

}
