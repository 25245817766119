import { Component, OnInit } from '@angular/core';
import { AppointmentService } from 'src/app/services/firebase/employee-appointment.service';
import { UserService } from 'src/app/services/firebase/user.service';
import { HelperService } from 'src/app/services/helper/helper';

@Component({
  selector: 'app-homepage-appointments',
  templateUrl: './homepage-appointments.component.html',
  styleUrls: ['./homepage-appointments.component.css']
})
export class HomepageAppointmentsComponent implements OnInit {
  
  filteredActiveAppointments = []
  appointment: any;
  found: boolean;
  appointmentGlobal:any;
  isAppointmentClicked = false;
  activeAppointments = [];
  constructor(private appointmentService: AppointmentService,
              private userService: UserService,
              private helperService: HelperService ) { }

  ngOnInit(): void {
    this.appointment = this.appointmentService.getAllAppointments().valueChanges().subscribe((s) => {
      if (s && s.length > 0){
        const today = new Date().getTime();
        s.forEach((appt: any) => {
          this.appointmentGlobal = this.userService.getUserById(appt.employeeId).valueChanges().subscribe(user => {
            if (user && user.length > 0 && appt.endTime.toDate().getTime() >= today){
              // this.activeAppointments.push({user: user[0], appt });
              this.found = this.activeAppointments.some((el) => el.appt.id === appt.id);
              if(this.found){
                this.found = this.activeAppointments.some((el) => el.appt.id === appt.id);

              }else{
                this.activeAppointments.push({user: user[0], appt });
                this.found = this.activeAppointments.some((el) => el.appt.id === appt.id);
              }
            }
            this.appointmentGlobal.unsubscribe();
          });
          return ;
        });
        
  }
  this.filteredActiveAppointments = this.activeAppointments;

  this.appointment.unsubscribe();

    });
  }

  truncateHTML(text: string, limit: string): string {
    return this.helperService.truncateHTML(text, limit);
  }

}
