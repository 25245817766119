
<!-- <div id="my-modal-client" *ngIf="openContactModal" class="modal"
[ngClass]="{'modal-open': openContactModal, 'modal-action': !openContactModal }"> -->

<div class="modal modal-open">
    <div class="modal-box max-w-4xl">
        <div class="modal-content">
            <h3 class="font-bold text-lg">Contact {{userLogged.GivenName | titlecase}} {{userLogged.LastName | titlecase}}</h3>
            <p class="pt-4 text-gray-400 pl-1">You can contact this {{userLogged.GivenName | titlecase}} using email or SMS
                if the user
                registed
                his/her phonenumber</p>

            <form [formGroup]="contactForm" (ngSubmit)="sendClient(contactForm.value)">

                <div class="flex items-center mt-4 space-x-8 ">
                    <div class="flex-col text-black px-2">Contact with:</div>

                    <div class=" flex " *ngIf="userLogged.role == 'User'">
                    <div class="flex" *ngIf="(userLogged.Email != '' && userLogged.contact.includes('Email'))">
                        <input type="radio" id="Email" value="Email" class="mx-2 cursor: text;"
                            formControlName="contact" [(ngModel)]=contactMsg
                            [disabled]="(!userLogged.contact.includes('Email')) || isLimitEmail">
                        <label for="">Email</label><br>
                    </div>

                    <div *ngIf="(userLogged.cellPhone != ''  && userLogged.contact.includes('SMS'))">
                        <input type="radio" id="Sms" value="SMS" class="mx-2 cursor: text;" formControlName="contact"
                            [(ngModel)]=contactMsg [disabled]="(!(userLogged.contact.includes('SMS'))) || isLimitSMS">
                        <label for="">SMS</label><br>
                    </div>

                    <div *ngIf="userLogged.contact.includes('InApp')">
                        <input type="radio" id="directMessage" value="directMessage" class="mx-2"
                            formControlName="contact"[(ngModel)]=contactMsg [checked]="contactMsg === 'directMessage'"
                            (click)="directMessageSelected()" [disabled]="disable">
                        <label for="">Direct Message</label><br>
                    </div>
                    </div>

                    <div *ngIf="userLogged.role == 'Employee'">
                        <div class="flex items-center mt-4 space-x-8 ">
                            <div>
                              <input type="radio" id="Email" value="Email" class="mx-2" formControlName="contact"
                              [(ngModel)]=contactMsg [checked]="contactMsg == 'Email'" [disabled]="isLimitEmail">
                              <label for="">Email</label><br>
                            </div>
                            <div *ngIf="cellPhone != ''">
                              <input type="radio" id="Sms" value="SMS" class="mx-2" formControlName="contact"
                                [checked]="contactMsg == 'SMS'"[(ngModel)]=contactMsg [disabled]="isLimitSMS">
                              <label for="">SMS</label><br>
                            </div>
            
                          </div>

                          
                <span *ngIf="userLogged.cellPhone == ''" class="text-red-500 text-center">This User doesn't have a phone
                    number. Therefore this Employee wont recieve SMS</span>
                    </div>


                </div>
                <br />

                <div *ngIf="(contactMsg == 'SMS' && userLogged.cellPhone != '') && !isLimitSMS ">
                    <div class="px-2 mt-2 w-full">
                        <label for=" " class="text-sm font-bold mb-2 "> Message: </label>

                        <textarea id="message" formControlName="messageSMS"
                            class="border rounded py-4 px-3 mt-2 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline h-32 w-full "
                            placeholder="Message"></textarea>

                    </div>

                </div>

                <div *ngIf="contactMsg == 'Email' && !isLimitEmail">
                    <div class=" px-2 mt-2 w-full">
                        <div>
                            <label for=" " class="text-sm font-bold mb-2 "> Subject: </label>
                            <input name="subject" formControlName="subject" id="subject"
                                class="form-control mt-1 px-3 py-3 bg-white border shadow-sm border-slate-300 placeholder-slate-400 outline outline-offset-1 outline-1 focus:outline-1 focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                                placeholder="Subject" />

                            <br />
                        </div>
                        <label for=" " class="text-sm font-bold mb-2 "> Message: </label>

                        <!-- <textarea id="body" formControlName="body" 
      class="border rounded py-4 px-3 mt-2 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline h-32 w-full "
      id="Description" placeholder="Message" [required]="!isSMS"></textarea> -->

                        <app-editor [control]="emailbody" class="bg-white" required
                            [fromComponent]="'message'"></app-editor>

                    </div>


                </div>

                <div *ngIf="contactMsg == 'directMessage'">
                    <app-private-messages [userToMessage]="userLogged" [directMessage]='true'
                        (sendDirectMessageToUser)="SendDirectMessageToUser($event)"></app-private-messages>
                </div>

                <div class="flex justify-between mt-2">
                    <button type="button" (click)="cancelSendBtnClicked()"
                        class="btn bg-white hover:bg-gray-400 hover:text-white text-black border-none">Cancel</button>
                    <button [disabled]="!contactForm.valid" *ngIf="contactMsg !== 'directMessage'"
                        class="p-2 text-white bg-customTeal hover:bg-gray-100 hover:text-customTeal border-none rounded-md"
                        type="submit">Send Message</button>
                </div>

            </form>
            <div *ngIf="contactMsg !== 'directMessage'"
                class="text-blue-600 underline underline-offset-2 text sm mx-2 font-bold pt-2"
                [routerLink]="['/admin/app-setup']">Create Signature</div>

        </div>
    </div>
</div>