
<div class="px-10 w-full">
  
<div class=" flex justify-end mt-8 md:mt-16 mx-4 px-10 md:mr-20 w-full" >

  <div class="flex shadow-lg w-full md:w-1/2 rounded-md " [ngClass]="{'bg-green-100  border border-solid border-green-300 ': toastType=='success', 
  ' bg-red-100 border-solid  border-2 border-red-700': toastType == 'failure' || toastType=='declined', 'bg-blue-100 border-solid border-2 border-blue-700': toastType=='information',
  'bg-yellow-100 border-solid border-2 border-yellow-700' : toastType=='warning',  ' bg-red-200 border-solid  border-2 border-red-600': toastType == 'error'
}">
 
    <div class="flex flex-col space-y-2 py-3 w-full ">
      <div class="px-6 ">
        <div class="flex text-green-600 items-center space-x-2" *ngIf="toastType=='success'">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-4 h-4 fill-green-600">
            <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clip-rule="evenodd" />
          </svg>
          
          <span class="font-bold  ">Success</span></div>
      <div class="text-yellow-500 font-bold items-center" *ngIf="toastType=='warning'"><i
        class="material-icons text-sm">warning</i><span class="font-bold  px-4">Warning</span></div>
      <div class="text-blue-500 font-bold items-center" *ngIf="toastType=='information'"><i
        class="material-icons text-sm  items-center">info</i><span class="font-bold text-xl px-4">Information</span></div>
      <div class="text-red-500 w-full h-max font-bold items-center" *ngIf="toastType=='failure'"><i
          class="material-icons text-sm ">dangerous</i><span class="font-bold text-xl px-4">Delete</span></div>
      <div class="text-red-500  font-bold items-center" *ngIf="toastType=='error'"><i
        class="material-icons ">dangerous</i><span class="font-bold text-xl px-4"></span></div>
        <div class="text-red-500  font-bold items-center" *ngIf="toastType=='declined'"><i
          class="material-icons text-sm ">dangerous</i><span class="font-bold text-xl px-4">Declined</span></div>
          <div class="text-red-500  font-bold items-center" *ngIf="toastType=='disable'"><i
            class="material-icons text-sm ">dangerous</i><span class="font-bold text-xl px-4">Disable</span></div>
      </div>
      <div class=" pb-0.5 w-full" [ngClass]="{'bg-green-200   ': toastType=='success', 
      ' bg-red-200': toastType == 'failure' || toastType=='declined', 'bg-blue-200 ': toastType=='information',
      'bg-yellow-200 ' : toastType=='warning',  ' bg-red-200  ': toastType == 'error'
    }">
      </div>
      <div class="flex px-6  py-2  text-base" [ngClass]="{'text-green-700   ': toastType=='success', 
      ' text-red-700': toastType == 'failure' || toastType=='declined', 'text-blue-700 ': toastType=='information',
      'text-yellow-700' : toastType=='warning',  ' text-red-700 ': toastType == 'error'
    }">
        {{toastMessage}}
      </div>
      
    </div>

    <!-- <div class="flex justify-between mr-10 pt-4"><p></p><i class="material-icons w-8 text-3xl">close</i></div> -->

  </div>

</div>
</div>