import { Component, OnInit, Input } from '@angular/core';
import { ProductsServiceMenuService } from 'src/app/services/api/data.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { AuthService } from 'src/app/services/firebase/auth.service';

@Component({
  selector: 'app-service-product-route',
  templateUrl: './service-product-route.component.html',
  styleUrls: ['./service-product-route.component.css']
})
export class ServiceProductRouteComponent implements OnInit {

  @Input () servicesRoute;
  @Input () productsRoute;
  @Input () promotionRoute;
  servicesName
  productsName
  constructor(private appSettingService: AppSettingsService,
              private authService: AuthService,
              private productsServiceMenuService:ProductsServiceMenuService
              ) {
               }
  settings: any;
  currentUser: any;
  ngOnInit(): void {
    this.currentUser = this.authService.getGlobalUser();
    this.appSettingService.getAppSettingsList().valueChanges().subscribe((settings) =>{
      this.settings = settings[0];
      this.productsServiceMenuService.productsName.subscribe(p=>{
        this.productsName=p
      }
      )
      this.productsServiceMenuService.servicesName.subscribe(p=>{
        this.servicesName=p
      })
    });
    

  }

}
