import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { ImageModel } from 'src/app/models/ImageModel';
import { MediaService } from 'src/app/services/api/media.service';


// declare var navigator: any;

@Component({
	selector: 'app-signup-settings',
	templateUrl: './signup-settings.component.html',
	styleUrls: ['./signup-settings.component.css']
})
export class SignupSettingsComponent implements OnInit {


	editModel: any;
	isEdit = false;
	urlPattern = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
	settingsForm: UntypedFormGroup;
	hasSocial = false;

	// toast
	toastMessage: any;
	toastClass: any;
	toastType: any;
	openToast = false;
	settingsModel
	imgErrorMsg: any;
	base64Image: any;
	binDoc: File;
	oldUrl

	isMobile = false;
	mobileBase64Image: any;

	constructor(
		private fb: UntypedFormBuilder,
		private location: Location,
		private appSettings: AppSettingsService,
		private mediaService: MediaService
	) { 
		if (typeof window['cordova'] !== 'undefined') {
			this.isMobile = true;
		 }

	}

	get title() { return this.settingsForm.get('title'); }
	get description() { return this.settingsForm.get('description'); }
	get logo() { return this.settingsForm.get('logo'); }
	// get backDropColor() { return this.settingsForm.get('backDropColor'); }
	// get firstColor() { return this.settingsForm.get('firstColor'); }
	// get secondColor() { return this.settingsForm.get('secondColor'); }


	ngOnInit() {
		this.settingsForm = this.fb.group({
			title: ['',Validators.required],
			description: ['',],
			logo: ['',],
			// backDropColor: ['',],
			// firstColor: ['',],
			// secondColor: ['',],

		});

		this.appSettings.getSignupSettingsList().valueChanges().subscribe(
			signupSettings => {
				if (signupSettings) {
					if (signupSettings.length > 0) {
						this.settingsModel = signupSettings[0];

						this.title.patchValue(this.settingsModel.title);
						this.description.patchValue(this.settingsModel.description);
						// this.backDropColor.patchValue(this.settingsModel.backDropColor);
						// this.firstColor.patchValue(this.settingsModel.firstColor);
						// this.secondColor.patchValue(this.settingsModel.secondColor);
						if (this.settingsModel.logo && this.settingsModel.logo != '') {
							// this.oldUrl = this.settingsModel.logo;
							// this.showImageFromServer = true;
						}
					}

				}
			}
		);

	}
	saveSettings(value) {
		if (this.settingsModel) {
			this.settingsModel.title = value.title;
			this.settingsModel.description = value.description;
			// this.settingsModel.backDropColor = value.backDropColor
			// this.settingsModel.secondColor = value.secondColor ? value.secondColor : ''
			// this.settingsModel.firstColor = value.firstColor ? value.firstColor : ''

			if (this.isMobile){
			  if (this.mobileBase64Image){
				 const img = new ImageModel();
				 img.imgBase64 = this.mobileBase64Image;
				 this.mediaService.uploadImage(img).subscribe((upload) => {

					if (upload){
					  this.settingsModel.logo = upload.imgLink;
					  this.updateSettings()
					  return
					}
				 });
			  } else {
				 if (!this.settingsModel.logo) { this.settingsModel.logo = ''; }
				 		this.updateSettings()
						 return
			  }
	
			}
			else{
			  if (this.binDoc && this.oldUrl) {
				 this.mediaService.UpdateBinImage(this.binDoc, this.oldUrl).subscribe(
					upload => {
					  if (upload) {
							this.settingsModel.logo = upload.imgLink;
							this.updateSettings()
							return
					  }
					}
				 );
			  } else if (this.binDoc && !this.oldUrl) {
				 this.mediaService.UploadBinImage(this.binDoc).subscribe(
					upload => {
					  if (upload) {
						 this.settingsModel.logo = upload.imgLink;
						 this.updateSettings()
						 return;
					  }
					}
				 );
			  } else if (!this.binDoc && this.oldUrl) {
				 this.mediaService.DeleteBinImage(this.oldUrl).subscribe(
					upload => {
					  if (upload) {
						this.settingsModel.logo = upload.imgLink;
						this.updateSettings()
					  }
					});
	
			  } else if (!this.binDoc && !this.oldUrl) {
				 if (!this.settingsModel.logo) { this.settingsModel.logo = ''; }
				 this.updateSettings()
			  }
			}
	
		 } else {
	
			this.settingsModel = {
			  title: value.title,
			  description: value.description,
			//   backDropColor: value.backDropColor,
			//   secondColor : value.secondColor ?value.secondColor : '',
			//   firstColor :value.firstColor ? value.firstColor : ''
	
			};
			if (this.isMobile){
			  if (this.mobileBase64Image){
				 const img = new ImageModel();
				 img.imgBase64 = this.mobileBase64Image;
				 this.mediaService.uploadImage(img).subscribe((upload) => {
					if (upload){
					  this.settingsModel.logo = upload.imgLink;
					  this.createSettings()
					  return;
					}
				 });
			  } else {
				 if (!this.settingsModel.logo) { this.settingsModel.logo = ''; }
				 this.createSettings()
			  }
	
			} else {
	
			  if (this.binDoc) {
				 this.mediaService.UploadBinImage(this.binDoc).subscribe(
					upload => {
					  if (upload) {
						this.settingsModel.logo = upload.imgLink;
					  	this.createSettings()
					  }
					}
				 );
			  } else {
					this.createSettings()

			  }
			}
	
		 }
	
	}

	updateSettings(){
		this.appSettings.updateSignupSettings(this.settingsModel).then(x=>{
			  this.callToast()
			}
		 );
	}
	createSettings(){
		this.appSettings.saveSignupSettings(this.settingsModel).then(x=>{
			this.callToast()
		 }
	  );
	}
	
	callToast(){
		this.toast({ html: 'Signup Settings Successfully Updated!!', classes: 'green', type: 'success'});
		this.binDoc = undefined;
		this.base64Image = undefined;
	 }

	handleMobilePhotoInput(){
		// navigator.camera.getPicture((image: any) => {
		//   const imageName = 'IMG.jpeg';
		//   const imageBlob = this.dataURItoBlob(image);
		//   const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });
		//   this.binDoc = imageFile;
		//   this.base64Image = 'data:image/jpeg;base64,' + image;
		//   this.mobileBase64Image = image;
  
		// }, (error) => {
		//   alert(error);
		// },
		// {
		//   quality: 50,
		//   destinationType: navigator.camera.DestinationType.DATA_URL,
		//   encodingType: navigator.camera.EncodingType.JPEG,
		//   mediaType: navigator.camera.MediaType.PICTURE,
		//   sourceType: navigator.camera.PictureSourceType.PHOTOLIBRARY,
		// }
		// );
	 }

	 dataURItoBlob(dataURI) {
		const byteString = window.atob(dataURI);
		const arrayBuffer = new ArrayBuffer(byteString.length);
		const int8Array = new Uint8Array(arrayBuffer);
		for (let i = 0; i < byteString.length; i++) {
		  int8Array[i] = byteString.charCodeAt(i);
		}
		const blob = new Blob([int8Array], { type: 'image/png' });
		return blob;
	}

	handleImageInput(files: FileList) {
		if (!this.validateImage(files[0].name)) {
			this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';
			this.logo.patchValue('');
			return;
		}
		this.binDoc = files[0];
		this.imgErrorMsg = undefined;

		const reader = new FileReader();
		reader.readAsDataURL(files[0]);
		reader.onload = () => {
			this.base64Image = reader.result as string;
		};

	}

	private validateImage(name: any) {
		const ext = name.substring(name.lastIndexOf('.') + 1);
		if (ext.toLowerCase() === 'png') {
			return true;
		} else if (ext.toLowerCase() === 'jpg') {
			return true;
		} else if (ext.toLowerCase() === 'jpeg') {
			return true;
		} else {
			return false;
		}
	}

	back() {
		this.location.back();
	}
	removeImage() {
		this.binDoc = undefined;
		this.base64Image = undefined;
		// this.logo.patchValue('');
	 }

	 removeSavedFile() {
		this.binDoc = undefined;
		this.base64Image = undefined;
		this.oldUrl = this.settingsModel.logo;
		this.settingsModel.logo = '';
		this.logo.patchValue('');
	 }

	toast(obj) {
		this.toastMessage = obj.html;
		this.toastClass = obj.classes ? obj.classes : 'green';
		this.toastType = obj.type ? obj.type : 'success';
		this.openToast = true;
		setTimeout(() => {
			this.openToast = false;
		}, 2000);
	}
}
