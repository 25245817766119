import { Component, OnInit } from '@angular/core';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
// declare var M: any;
@Component({
  selector: 'app-employee-menu-settings',
  templateUrl: './employee-menu-settings.component.html',
  styleUrls: ['./employee-menu-settings.component.css']
})
export class EmployeeMenuSettingsComponent implements OnInit {

//   menu: any;
//   isNewSetting = true;
//   isMenuSettings = true;
//   isEditItem = false;
//   selectedItem: any;
//   selectedItemName: any;
  constructor(private settingService: AppSettingsService,) {
    // this.getSettings();
  }

  ngOnInit(): void {
    // if(!this.menu) {
    //   this.menu = {
    //     id: '',
    //     items: [
    //       {
    //         index : 0,
    //         name: 'RegistrationRequest',
    //         displayName: 'Registration Request',
    //         bgColor: '#2196F3',
    //         textColor: '#FFFFFF',
    //         icon: 'person_add',
    //         iconColor: '#FFFFFF',
    //         isEnabled: true,
    //       },
    //       {
    //         index: 1,
    //         name: 'RegisteredAdmins',
    //         displayName: 'Registered Admins',
    //         bgColor: '#00796B',
    //         textColor: '#FFFFFF',
    //         icon: 'group_add',
    //         iconColor: '#FFFFFF',
    //         isEnabled: true,
    //       },
    //       {
    //         index: 3,
    //         name: 'Logout',
    //         displayName: 'Logout',
    //         bgColor: '#DC143C',
    //         textColor: '#FFFFFF',
    //         icon : 'sync',
    //         iconColor: '#FFFFFF',
    //         isEnabled: true,
    //       },
    //     ]
    //   }
    // }

  }

  checkedChange(section: any) {
    section.isEnabled = !section.isEnabled;
  }

//   moveSection(element, position: number) {
//      var index = this.menu.items.indexOf(element);
//      var newIndex = index + position;
//      if (newIndex < 0 || newIndex == this.menu.items.length) return; //Already at the top or bottom.

//     this.menu.items.splice(index, 1);
//     this.menu.items.splice(newIndex, 0, element);
//     this.menu.items.forEach(element => {
//       element.index =  this.menu.items.indexOf(element);
//     });

//   };

//   editSection(section) {
//     this.selectedItem = section;
//     this.selectedItemName = section.name;
//     this.isMenuSettings = false;
//     this.isEditItem = true;
//     setTimeout(() => {
//       M.updateTextFields();
//     }, 25);
//   }
//   saveChanges() {
//     if (this.menu && this.isNewSetting) {
//       this.settingService.saveAdminMenuSettings(this.menu).then(x => {
//         M.toast({ html: 'Changes Saved Successfully.', classes: 'green' });
//         this.getSettings();
//       });
//     }

//     if(this.menu && !this.isNewSetting) {
//       this.settingService.updateAdminMenuSettings(this.menu).then(x => {
//         M.toast({ html: 'Changes Saved Successfully.', classes: 'green' });
//         this.getSettings();
//       });
//     }
//   }
//   backClicked(){
//     this.selectedItem = undefined;
//     this.isEditItem = false;
//     this.isMenuSettings = true;
//   }
//   getSettings() {
//     this.settingService.getAdminMenuSettings().valueChanges().subscribe(hp => {
//       if (hp) {
//         if (hp.length > 0) {
//           this.menu = hp[0];
//           this.isNewSetting = false;
//           if (this.selectedItemName === 'RegistrationRequest') { this.selectedItem = this.menu.items.filter(s => s.name === 'RegistrationRequest')[0] };
//           if (this.selectedItemName === 'RegisteredAdmins') { this.selectedItem = this.menu.items.filter(s => s.name === 'RegisteredAdmins')[0] };
//           if (this.selectedItemName === 'Reports') { this.selectedItem = this.menu.items.filter(s => s.name === 'Reports')[0] };
//           if (this.selectedItemName === 'VerificationRequests') { this.selectedItem = this.menu.items.filter(s => s.name === 'VerificationRequests')[0] };
//           if (this.selectedItemName === 'InviteUsers') { this.selectedItem = this.menu.items.filter(s => s.name === 'InviteUsers')[0] };
//           if (this.selectedItemName === 'ManageUsers') { this.selectedItem = this.menu.items.filter(s => s.name === 'ManageUsers')[0] };
//           if (this.selectedItemName === 'News') { this.selectedItem = this.menu.items.filter(s => s.name === 'News')[0] };
//           if (this.selectedItemName === 'Events') { this.selectedItem = this.menu.items.filter(s => s.name === 'Events')[0] };
//           if (this.selectedItemName === 'Settings') { this.selectedItem = this.menu.items.filter(s => s.name === 'Settings')[0] };
//           if (this.selectedItemName === 'Logout') { this.selectedItem = this.menu.items.filter(s => s.name === 'Logout')[0] };
//          setTimeout(() => {
//             M.updateTextFields();
//           }, 25);
//         }
//       }
//     });
//   }

}
