
<div *ngIf="requestSent">
    <div class=" text-center mt-24">
        <div class="text-customTeal text-4xl font-bold text-center px-4">Thank you for signing up</div>
        <br />
        <p class="lg:mx-64 md:mx-56 sm-mx-28 text-center font-bold px-4"> Your application is in progress. The
            adminstrator will review the form and send you a
            registration link. Please follow the link when you recieve it
        </p>
        <br />
        <p class="font-bold px-4">Until then why not explore our landing page</p>
        <br />
        <button [routerLink]="['/home']" class="px-2 py-2 bg-customTeal items-center rounded-md text-white">Go to
            Landing Page</button>
        <br />
        <br />
    </div>
</div>

<div *ngIf="alreadyRequested">
    <div class=" text-center mt-24">
        <div class="text-customTeal text-2xl md:text-4xl font-bold text-center px-4">Thank you for registering with {{appName}}
        </div>
        <br />
        <p class="lg:mx-64 md:mx-56 sm-mx-28 text-center font-bold px-4"> You already Requested an your application is in progress. The
            adminstrator will review the form and send you a
            registration link. Please follow the link when you recieve it
        </p>
        <br />
        <p class="font-bold px-4">Until then why not explore our landing page</p>
        <br />
        <button [routerLink]="['/home']" class="px-2 py-2 bg-customTeal items-center rounded-md text-white">Go to
            Landing Page</button>
        <br />
        <br />
    </div>
</div>

<div *ngIf="alreadyRegistered">
    <div class=" text-center mt-24">
        <div class="text-customTeal text-2xl md:text-4xl font-bold text-center px-4">Thank you for signing up</div>
        <br />
        <p class="lg:mx-64 md:mx-56 sm-mx-28 text-center font-bold px-4"> But you have already registered to this app
            {{appName}}.  </p>
        <br />
        <p class="font-bold px-4">You already have an account so go ahead and login. If you forgot your password please <a [routerLink]="['/change-password']" class="">click here</a></p>
        <br />
        <button [routerLink]="['/login']" class="px-2 py-2 bg-customTeal items-center rounded-md text-white">Go to Login
            Page</button>
        <br />
        <br />
    </div>
</div>