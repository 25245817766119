<div class=" flex flex-col  space-y-4 md:flex-row w-full md:space-x-6 md:space-y-0 h-max md:min-h-screen md:max-h-max " *ngIf="!directMessage">
    <div class="hidden md:flex md:flex-row space-x-6 mb-4 w-full">

        <app-chats-list class="flex flex-col w-1/3"
            [chatType]="'private'" (chatSelected)=onChatSelect($event)>
        </app-chats-list>

        <!-- <app-messages class="w-2/3 p-4 bg-white max-h-screen min-h-screen overflow-y-auto scroll-smooth" -->
        <app-messages class="flex flex-col w-2/3"
            [chatType]="'private'" [chatSelected]='chatSelected' [selectedConv]='selectedConv'
            (sendPrivate)=onMessageSent($event)>
        </app-messages>
    </div>


    <div class="flex flex-col md:hidden space-x-6 mb-4 w-full">
        <app-chats-list class="flex flex-col w-full"
            *ngIf="!showMessages" [chatType]="'private'" (chatSelected)=onMobileChatSelect($event)>
        </app-chats-list>

        <!-- <app-messages class="w-full p-4 bg-white max-h-screen min-h-screen overflow-y-auto scroll-smooth" *ngIf="showMessages" [showBack]="true" -->
        <app-messages class="flex flex-col w-full" *ngIf="showMessages" [showBack]="true"
            (back)="onBack($event)" [chatType]="'private'" [chatSelected]='chatSelected' [selectedConv]='selectedConv'
            (sendPrivate)=onMessageSent($event)>
        </app-messages>
    </div>
</div>

<div *ngIf="directMessage">
    <app-messages class="flex flex-col w-full" [showHeader]="false"
           [chatType]="'private'" (sendDirectMessageToUser)="sendDirectMessageToUser($event)" [chatSelected]='chatSelected' [selectedConv]='selectedConv'
            (sendPrivate)=onMessageSent($event)>
    </app-messages>
</div>