import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { EnvironmentService } from "src/app/services/helper/env";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-welcome-firstnations",
  templateUrl: "./welcome.component.html",
  styleUrls: ["./welcome.component.css"],
})
export class WelcomeFirstNations implements OnInit {
  nationOfuse = environment.firstNation.name;

  isNationApp = this.envService.isNationApp;
  fromNationapp = this.envService.isNationApp || this.envService.fromFNLeaders;

  logo = this.envService.getLogo(1);
  imgFolder = this.envService.imgFolder;
  bgImg = this.fromNationapp ? 
  "https://api.goingmobile.app/media/personal-app-assets/img/webp/fnleaders/NATIVE%20MAN.webp" : 
  "https://api.goingmobile.app/media/personal-app-assets/img/webp/demo/FrontPageImg.webp";
  textColor = this.envService.getColor("secondary");

  constructor(
    private router: Router,
    private envService: EnvironmentService
  ) {}

  ngOnInit() {}

  navigateToURL() {
    window.location.href =(this.envService.isNationApp) ? "https://firstnationleaders.app/#/get-app" : "https://" + this.nationOfuse+".goingmobile.app/#/get-app";
  }
}
