import { AboutService } from './../../../services/firebase/about.service';
import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/firebase/user.service';
import { ConfigService } from 'src/app/services/api/config.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  constructor(private aboutService: AboutService,
              private userService: UserService, private configService: ConfigService) { }

  about: any;
  loading = false
  employees : any;
  employeeGlobal = [];
  aboutSettings: any;
  showEmpFromAdmin: boolean = false;
  viewMoreClicked : boolean = false;
  allEmployees = [];
  empty: boolean = false;

  aboutModal: boolean = false;
  clickedTeam: any;
  contactTeam : any;

  ngOnInit(): void {

    window.scroll(0, 0);
    this.loading = true

    // this.userService.getUserByShowEmployee().valueChanges().subscribe(employees => {
    //   this.allEmployees =  employees;
    //   if(this.allEmployees.length == 0){
    //     this.empty = true;
    //   }

    // })

    this.aboutService.getAbout().valueChanges().subscribe((aboutA) => {
      if (aboutA) {
        if (aboutA.length > 0) {
          this.loading=false
          this.about = aboutA[0];

        }
        this.loading=false

      }
      this.loading=false

    });

    this.aboutService.getAbout().valueChanges().subscribe(aboutSettings => {
      this.aboutSettings = aboutSettings;
      this.showEmpFromAdmin = this.aboutSettings[0]?.showEmployees;
    })

    this.userService.getUserByRoles(['Employee']).subscribe(employees => {
      if(employees.length > 0){
        employees.forEach(emp => {
          if(emp?.ShowEmployee == 'public'||emp?.ShowEmployee == 'Public'||emp?.showEmployee == 'public'||emp?.showEmployee == 'public'){
            this.empty = false;
            this.employeeGlobal.push(emp)
          }
        });
      }
    });


  }

  viewMore(){
    this.viewMoreClicked = !this.viewMoreClicked;
  }
  navigateModal(){
    //this.openAboutModal();
  }
  openAboutModal(team){
    this.aboutModal = true;
    this.clickedTeam = team;
    console.log("Clicked ", this.clickedTeam)
    if (team.role == 'Admin'){
      this.clickedTeam.jobTitle = 'Admin';
    }

    this.contactTeam = this.clickedTeam.contact[0];
    console.log("Contact tema ", this.contactTeam)
  }
  closeAboutModal(){
    this.aboutModal = false;
  }

  truncateHTML(text: string, limit: string): string {
    const charlimit = +limit;
    if (!text || text.length <= charlimit) {
      return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ');
    }
    return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ').substring(0, charlimit) + '...';
  }

  updateApps(){
this.configService.updateApps();
  }

 createApp(){
    this.configService.createApp();
      }

}
