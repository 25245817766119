<div *ngIf="post" class="  flex   space-y-4 flex-col md:flex-row md:space-y-0 min-h-screen " [ngClass]="{'p-5': !currentUser, 'mx-5': currentUser}">
  <!-- post comment -->
  <div *ngIf="!modelToPreview" class="hidden md:flex md:w-1/12   md:min-w-min gap-y-4   md:gap-y-8 md:flex-col">
    <div class="flex justify-center  h-max p-3  ">
      <div class="flex w-max md:flex-col fixed md:justify-evenly  space-x-12 md:space-x-0   md:gap-y-12 items-center md:bg-none md:border-0 md:p-0  border p-3   mb-10 px-6 rounded-full">
        <ng-container *ngTemplateOutlet="likeCommentCount;">
        </ng-container>
      </div>
    </div>
  </div>

  <div class="flex flex-col w-full md:max-w-4xl  mx-auto md:gap-y-4  ">
      <!-- list and service about -->
      <div class=" p-4 md:p-8 space-y-6 rounded-md bg-white ">
        <!-- Service list -->
        <div class=" flex justify-between text-base break-words md:text-3xl font-bold w-full  ">
            <!-- {{post.postTitle}} -->
            <div class="break-words w-full">
              <span [innerHTML]="post.postTitle | noSanitize"></span>
            </div>

          <!-- edit -->
          <div *ngIf="currentUser" >
            <div *ngIf="currentUser.role=='Admin' ||(permPES && (permPES.editPost || permPES.deletePost))" >
              <div class="dropdown dropdown-end z-50">
                <label tabindex="0" class="m-1"><i class="material-icons gray-text-600">more_vert</i></label>
                <ul tabindex="0" class="dropdown-content menu p-2 shadow bg-white rounded-box w-52 font-normal text-sm">
                  <li (click)="navigateEditBlog(post)" *ngIf="(currentUser.role=='Admin' ||(permPES && permPES.editPost))"><a><i class="material-icons gray-text-600">edit</i>Edit</a></li>
                  <li (click)="openDeleteModal()" *ngIf="(currentUser.role=='Admin' ||(permPES && permPES.deletePost))"><a><i class="material-icons gray-text-600">delete</i>Delete</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="flex justify-between  ">
          <div *ngIf="post.timeStamp" class="flex flex-row items-center space-x-1">
              <span class="text-xs text-gray-700">{{formatDate(post.timeStamp)}}</span>
          </div>

      </div>
        <div class="flex flex-col space-y-2 ">
            <img loading="lazy" id="blogImg" *ngIf="post.mediaLink && !post.videoLink" [src]="post.mediaLink" class="object-cover w-full mx-auto rounded-md" alt="Post Image">
            <video autoplay *ngIf="post.videoLink " class="w-100%"  controls>
              <source [src]="post.videoLink | safeUrl"  >
              Your browser does not support HTML video.
            </video>
        </div>


        <div class="md:pt-2 space-y-2 ">
            <div class="break-words w-full">
              <span [innerHTML]="post.message | noSanitize"></span>
            </div>
                <div *ngIf="post.pdfLink">
                  <div *ngIf="post.pdfLink" class="font-bold text-darkGray mb-4">
                    Shared Document
                  </div>
                  <!-- <iframe *ngIf="!isMobile" [src]="post.pdfLink | safeUrl"  width="100%" height="300px"></iframe>
                  <iframe *ngIf="isMobile" title="share-pdf" [src]="'https://docs.google.com/viewer?url='+post.pdfLink+'&embedded=true' | safeUrl" height="500px" width="100%"></iframe> -->

                  <div class=" text-white">
                    <div class=" bg-customTeal p-1">
                      <a class="download-icon" [href]="post.pdfLink" target="_blank" download>
                        <span class="material-icons float-left mr-2">
                          feed
                        </span>
                        <span>Open File</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div *ngIf="post.sharedLink">
                  <br/>
                  <p class="font-bold text-darkGray mb-4">Additional link</p>
                  <!-- <iframe [src]="getUrl(post.sharedLink) | safeUrl"  width="100%" height="300px"></iframe> -->
                  <a target="_blank" [href]="post.sharedLink" class="underline text-blue-500">{{post.title}}</a>
                </div>
                <!-- <iframe *ngIf="!isMobile" [src]="post.pdfLink| safeUrl"  width="100%" height="600px"></iframe> -->
                <iframe *ngIf="post.pdfLink" title="share-pdf" [src]="'https://docs.google.com/viewer?url='+ post.pdfLink
                +'&embedded=true' | safeUrl" height="300px" width="100%"></iframe>
                <!-- <embed [src] = "post.pdfLink" width="800px" height="2100px" /> -->
        </div>

        <div *ngIf="!modelToPreview" class="flex bg-gray-100 px-2 flex-row w-10/12 justify-between md:hidden items-center rounded-xl border-r-4">
          <br/><br/>
        </div>
      </div>
      <!-- comments section -->
      <div *ngIf="post.commentable && currentUser" >
        <app-post-comment [post]="post"
        [sectionName]="blogsName"
        postType="blog"
        (showCommentDeletedToast)="openDeleteToast()"
        >
        </app-post-comment>
      </div>
  </div>
</div>

<ng-template #likeCommentCount>
  <!-- clap -->
  <div class="flex flex-row items-center space-x-1 ">
      <button (click)="reactToPost(post.id)" class="hover:cursor-pointer" 
      [ngClass]="{'hover:cursor-pointer': currentUser && currentUser != null}" [disabled]="!currentUser || currentUser == null">
        <!-- <div *ngIf="checkIfReactionGiven(post.id); then liked; else notLiked">   </div> -->
      <span>
        <i class="fa fa-heart text-xl" [ngClass]="{'text-red-700': postLiked, 'hover:text-red-700': (currentUser && currentUser != null) &&  !postLiked}"></i>
        {{post.reactions.likes.count}}
      </span>
    </button>
      <!-- <span class="text-xs text-center ">{{post.reactions.likes.count}}</span> -->
  </div>
  <!-- comment -->
  <div class="flex flex-row items-center   space-x-1  ">
    <!-- onclick="location.href='#commentsContainer'"  -->
      <button (click)="scrollToComments()"
       type="button">
              <svg xmlns="http://www.w3.org/2000/svg " class="h-6 w-6 " fill="none " viewBox="0 0 24 24 " stroke="currentColor " stroke-width="2 ">
                  <path stroke-linecap="round " stroke-linejoin="round " d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z " />
                </svg>

          </button>
      <span class="text-xs text-center ">{{post.comments.length}}</span>
  </div>
</ng-template>


<!-- Delete Modal -->
<app-delete-confirmation *ngIf="deleteModalOpen" (deleteClicked)=archivePost() (cancelDeleteClicked)="deleteModalOpen=false" [deletedFromComponent]=blogsName></app-delete-confirmation>

<!-- toast -->
<app-alert class="fixed top-0 flex justify-end w-full" [toastType]="toastType" [toastMessage]="toastMessage" [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>