import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-template-preview',
  templateUrl: './template-preview.component.html',
  styleUrls: ['./template-preview.component.css']
})
export class TemplatePreviewComponent implements OnInit {

  @Output() closeModal = new EventEmitter<string>();
  preview = 'web'
  constructor() { }

  ngOnInit(): void {
  }

  closePreviewModal(){
    this.closeModal.emit();
  }

}
