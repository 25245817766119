<div class="font-body min-h-screen max-h-max pt-6">
  <!-- <div *ngIf="model && currentUser && currentUser.role == 'Admin'" [routerLink]="['/admin/edit-subscriber']" class="flex justify-end mr-10 cursor-pointer">
    <p class="px-4 py-2 bg-customTeal text-white">Edit Client Information</p>
  </div>

  <div *ngIf="!model && currentUser && currentUser.role == 'Admin'" [routerLink]="['/admin/subscriber-form']" class="flex justify-end mr-10 cursor-pointer">
    <p class="px-4 py-2 bg-customTeal text-white">Edit Client Information</p>
  </div> -->

<div class="font-bold text-customTeal ml-8 md:ml-40 text-xl mt-4 md:mt-0">Add Client Information</div>
  <div class="flex flex-col md:w-4/6 m-2 bg-white h-max p-4 md:p-12 md:mt-6 rounded-lg space-y-4 md:translate-x-1/4">
    <form [formGroup]="surveyForm" (ngSubmit)="submitSurvey(surveyForm.value)">

      <div class="flex flex-col space-x-10 gap-x-3 my-3">
        <div class="px-4 md:px-0 space-y-4 md:space-y-0  md:grid grid-cols-2 gap-6">
      
          <div class="flex space-y-2 md:w-1/2  flex-col">
            <label for=" " class="text-xs text-gray-500 font-bold "> First Name <span class="text-red-500">*</span>
            </label>
            <input maxlength="60" [(ngModel)]="names"
              class=" border rounded py-3 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-gray-200 focus:shadow-outline w-80 md:w-96"
              formControlName="firstName" type="text" placeholder="Jane">
      
            <div *ngIf="(firstName.dirty || firstName.touched) && firstName.invalid">
              <small *ngIf="firstName.errors.required" class="text-red-500"> The First Name is required.
              </small>
            </div>
          </div>
      
      
          <div class="flex space-y-2 md:w-1/2 flex-col ">
            <label for=" " class="text-xs text-gray-500 font-bold "> Last Name <span class="text-red-500">*</span>
            </label>
            <input
              class=" border rounded py-3 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-gray-200 focus:shadow-outline w-80 md:w-96"
              formControlName="lastName" type="text" placeholder="John">
      
            <div *ngIf="(lastName.dirty || lastName.touched) && lastName.invalid">
              <small *ngIf="lastName.errors.required" class="text-red-500"> The Last Name is required.
              </small>
            </div>
          </div>
      
        
      
        <!-- <div class="flex space-x-10 gap-x-3"> -->
        <div class="flex space-y-2 md:w-1/2 flex-col">
          <label for=" " class="text-xs text-gray-500 font-bold "> Phone Number <span class="text-red-500">*</span>
          </label>
          <input type="text"  id='cellPhone'
            class=" w-full border rounded py-3 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-gray-200 focus:shadow-outline w-80 md:w-96"
            formControlName="cellPhone" placeholder="000-000-0000" />
          <div *ngIf="(cellPhone.dirty || cellPhone.touched) && cellPhone.invalid">
            <small *ngIf="cellPhone.errors.required" class="text-red-500"> Cell Phone is required.
            </small>
          </div>
        </div>
      
        <div class="flex space-y-2 md:w-1/2 flex-col ">
          <label for=" " class="text-xs text-gray-500 font-bold "> Email
          </label>
          <input
            class=" border rounded py-3 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-gray-200 focus:shadow-outline w-80 md:w-96"
            formControlName="email" type="text" placeholder="johnjane@gmail.com">
        </div>
      </div>
        <!-- </div> -->
      </div>

      <div formArrayName="answerItems">
        <div *ngFor="let item of answers; let i = index;">
          <div [formGroupName]="i">

            <div class="mt-4">
              <div class="row card-content">
                <span class="mb-2 text-xs text-gray-500 font-bold">
                  <!-- <strong class="text-blue-500">
                    {{item.controls.questionNumber.value}}
                    : </strong>  -->
                  {{item.controls.question.value | titlecase}} <span class="text-red-500" *ngIf="item.controls.required.value">*</span> :
                </span>
                <br />

                  <div class="flex space-y-2 md:w-1/2 flex-col" *ngIf="item.controls.questionType.value === 'Short Answer'">
                    <!-- <div class="flex space-y-2 md:w-96  flex-col"> -->
                      <input id="answer" type="text" formControlName="answer" [required]="item.controls.required.value"
                        class="validate bg-gray-100 border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline w-80 md:w-96"
                        autocomplete="off" />

                      <div *ngIf="(item.controls.answer.dirty ||
                                item.controls.answer.touched) &&
                                item.controls.answer.invalid">
                        <small *ngIf="item.controls.answer.errors.required" class="text-red-500"> {{item.controls.question.value}} is
                          required.
                        </small>
                      </div>
                    <!-- </div> -->
                  </div>

                  <div class="flex space-y-2 md:w-1/2  flex-col" *ngIf="item.controls.questionType.value === 'Date'">
                    <!-- <div class="flex space-y-2 md:w-96  flex-col mt-2"> -->
                      <input type="date" id="birthday" name="birthday" formControlName="answer" [required]="item.controls.required.value"
                        class="bg-gray-100 border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline w-80 md:w-96">
                      <div *ngIf="(item.controls.answer.dirty ||
                                item.controls.answer.touched) &&
                                item.controls.answer.invalid">
                        <small *ngIf="item.controls.answer.errors.required" class="text-red-500"> {{item.controls.question.value}} is
                          required.
                        </small>
                      </div>
                    <!-- </div> -->
                  </div>
                

                  <div  class="flex space-y-2 md:w-1/2  flex-col" *ngIf="item.controls.questionType.value === 'Number'">
                    <!-- <div class="flex space-y-2 md:w-96  flex-col mt-2"> -->
                      <input type="number" id="quantity" name="quantity" formControlName="answer" [required]="item.controls.required.value"
                        class="bg-gray-100 border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline w-80 md:w-96">
                      <div *ngIf="(item.controls.answer.dirty ||
                                item.controls.answer.touched) &&
                                item.controls.answer.invalid">
                        <small *ngIf="item.controls.answer.errors.required" class="text-red-500"> {{item.controls.question.value}} is
                          required.
                        </small>
                        <small *ngIf="item.controls.answer.errors.invalid" class="text-red-500"> {{item.controls.question.value}} is
                          not in correct format.
                        </small>
                      </div>
                    <!-- </div> -->
                  </div>
                

                <div *ngIf="item.controls.questionType.value === 'Open Ended'">
                  <div class="flex space-y-2 md:w-full  flex-col mt-2">
                    <textarea type="text" id="longAnswer" formControlName="answer" [required]="item.controls.required.value"
                      class="bg-gray-100 h-14 border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline "></textarea>
                    <div *ngIf="(item.controls.answer.dirty ||
                                item.controls.answer.touched) &&
                                item.controls.answer.invalid">
                      <small *ngIf="item.controls.answer.errors.required" class="text-red-500"> {{item.controls.question.value}} is required.
                      </small>
                    </div>
                  </div>
                </div>

                <div *ngIf="item.controls.questionType.value === 'Address'">
                  <select formControlName="address" required
                                    class="validate py-3 px-4 select select-bordered w-full bg-veryLightGray"
                                    (change)="answerTypeChangeAddress($event.target.value, i)">
                                    <option value="" disabled selected>Please Select the Country </option>
                                    <option *ngFor="let country of countryList" [value]="country" title="Tooltip">
                                        {{country}}
                                    </option>
                                </select>

                        <span *ngIf="isCanada" class="flex justify-between space-x-2 mt-2">
                          <select formControlName="province" required
                          class="validate py-3 px-4 select select-bordered w-full bg-veryLightGray"
                          (change)="answerTypeChangeProvince($event.target.value, i)">
                          <option value="" disabled selected>Please Select the Country </option>
                          <option *ngFor="let prov of province" [value]="prov" title="Tooltip">
                              {{prov}}
                          </option>
                      </select>
                        </span>

                        <span *ngIf="isUsa" class="flex justify-between space-x-2 mt-2">
                          <select formControlName="states" required
                          class="validate py-3 px-4 select select-bordered w-1/2 bg-veryLightGray"
                          (change)="answerTypeChangeState($event.target.value, i)">
                          <option value="" disabled selected>Please Select the State </option>
                          <option *ngFor="let state of states" [value]="state" title="Tooltip">
                              {{state}}
                          </option>
                      </select>

                      <input id="city" type="text" formControlName="city"
                        class="validate bg-gray-100 border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline w-72 "
                        autocomplete="off" />

                        </span>

                        
                  <!-- <div class="flex space-y-2 md:w-full  flex-col mt-2">
                    <textarea type="text" id="longAnswer" formControlName="answer" [required]="item.controls.required.value"
                      class="bg-gray-100 h-14 border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline "></textarea>
                    <div *ngIf="(item.controls.answer.dirty ||
                                item.controls.answer.touched) &&
                                item.controls.answer.invalid">
                      <small *ngIf="item.controls.answer.errors.required" class="text-red-500"> {{item.controls.question.value}} is required.
                      </small>
                    </div>
                  </div> -->
                </div>


                 <div *ngIf="item.controls.questionType.value === 'Multiple Choice'">
                  <div formArrayName="answers"
                    *ngFor="let ans of surveyForm.get('answerItems').controls[i].get('answers').controls; let j = index;">
                    <div class="flex space-x-4 space-y-4 my-2" [formGroupName]="j"
                      *ngIf="!model.questions[i].allowMultipleAnswers">
                      <label class="flex space-x-4 ml-8">
                        <input name="answer" value="{{ans.controls.answer.value}}"
                          (change)="answerSelected(i,ans.controls.answer.value)" type="radio"
                          class="radio checked:bg-customTeal" />
                        <p class="ml-12">{{ans.controls.answer.value | titlecase}}</p>
                      </label>
                    </div>

                    <div class="flex space-x-4 space-y-4 my-2" [formGroupName]="j"
                      *ngIf="model.questions[i].allowMultipleAnswers">
                      <label class="flex space-x-4 ml-8">
                        <input name="answer" value="{{ans.controls.answer.value}}"
                          (change)="answerSelected(i,ans.controls.answer.value)" type="checkbox"
                          class="checkbox checked:bg-customTeal" />
                        <p class="ml-10">{{ans.controls.answer.value | titlecase}}</p>
                      </label>
                    </div>
                  </div>
                </div> 



              </div>

              <!-- <div class="card-action">
                <div class="flex justify-between">
                  <div class="" *ngIf="i === 0">
                    <button type="button" class="px-4 py-2 rounded-lg bg-white text-customTeal border my-4 border-customTeal" (click)="backToList()">
                      <i class="material-icons float-left">cancel</i> Cancel
                    </button>
                  </div>
                  <div class="" *ngIf="i !== 0 && i != (totalQuestions - 1)">
                    <button type="button" class="px-4 py-2 rounded-lg bg-white text-customTeal border my-4 border-customTeal" (click)="previous(i)">
                      <i class="material-icons float-left">arrow_back_ios</i> Previous
                    </button>
                  </div>
    
                  <div class="" *ngIf="i < totalQuestions - 1">
                    <button type="button" class="px-4 py-2 rounded-lg bg-white text-customTeal border my-4 border-customTeal" (click)="continue(i)"
                      [disabled]="!item.valid">
                      Continue <i class="material-icons float-right">arrow_forward_ios</i>
                    </button>
                  </div>  
                </div>
              </div> -->

            </div>
          </div>
        </div>

      </div>
      <div class="row card white col s12">

        <div class="card-action">
          <div class="flex justify-between">
            <!-- <div class="">
                      <button type="button" class="px-4 py-2 rounded-lg bg-white text-customTeal border my-4" (click)="prev()">
                          <i class="material-icons float-left">arrow_back_ios</i> Previous
                      </button>
                  </div> -->

            <div class="flex justify-end space-x-2">
              <button type="submit" (click)="submitClient()" class=" flex justify-end px-4 py-2 rounded-lg text-white bg-customTeal border my-4"
                [disabled]="!surveyForm.valid">
                Add Client 
                <!-- <i class="material-icons float-right">send</i> -->
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
    
    <div id="my-modal-disable" *ngIf="invitationPopup" class="modal"
        [ngClass]="{'modal-open': invitationPopup, 'modal-action': !invitationPopup }">
        <div class="modal-box">
            <div class="modal-content">
                <div (click)="closeModal()" class="flex justify-end cursor-pointer"><i
                    class="material-icons px-3 py-3 bg-gray-200 rounded-full">close</i></div>
                <h3 class="font-bold text-lg text-center mb-2">Invitation Email</h3>
                <hr />
                <p class="text-center mt-3">Do you want to invite {{names | titlecase}}?</p>
                
                <p class="text-center mt-3">An Invitation email has been sent to {{names | titlecase}}. The account is created
                  successfully.</p>
                <div class="modal-action items-center justify-between m-4">
                  
                  <label (click)="addClient()" class="py-2 px-4  text-customTeal border-customTeal border-2 rounded-md ">Add
                    Client</label>

                  <label (click)="inviteClient()"
                    class="py-2 px-4 bg-customTeal text-white border-customTeal  rounded-md ">Invite</label>
                
                </div>
                </div>
                </div>
    </div>
    
      <!-- toast -->
  <app-alert class="fixed top-0 flex justify-end w-full" [toastType]="toastType" [toastMessage]="toastMessage"
  [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>


</div> 