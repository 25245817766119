<div class="flex mx-5 font-bold flex-row justify-center">
  <!-- <div class="tabs">
    <a class="tab tab-bordered" [ngClass]="{'tab-active':landingPageClicked}" (click)="selectLandingPage()">Landing Page</a>
    <a class="tab tab-bordered" [ngClass]="{'tab-active':registrationPageClicked}" (click)="selectRegistrationPage()">Registration Page</a>
    <a class="tab tab-bordered" [ngClass]="{'tab-active':aboutUsClicked}" (click)="selectAboutPage()">{{aboutUsName | titlecase}}</a>
  </div> -->
  <div class="w-1/3 text-center text-sm"><button (click)="selectLandingPage()" class="font-bold rounded-full md:px-10 border-2 border-customTeal px-2 py-1" [ngClass]="{'text-white bg-customTeal': landingPageClicked, 'text-black': !landingPageClicked}">Landing Page</button></div>
  <div class="w-1/3 text-center text-sm"><button (click)="selectRegistrationPage()" class="font-bold rounded-full md:px-10 border-2 border-customTeal px-2 py-1" [ngClass]="{'text-white bg-customTeal': registrationPageClicked, 'text-black': !registrationPageClicked}">Registration</button></div>
  <div class="w-1/3 text-center text-sm"><button (click)="selectAboutPage()" class="font-bold rounded-full md:px-10 border-2 border-customTeal px-2 py-1" [ngClass]="{'text-white bg-customTeal': aboutUsClicked, 'text-black': !aboutUsClicked}">{{aboutUsName ? aboutUsName:'About Us'}}</button></div>
</div>
<br/>

<!-- Landing Page setting -->
<div *ngIf="landingPageClicked">
  <app-landing-page-settings></app-landing-page-settings>
</div>

<!-- Registration setting -->
<div *ngIf="registrationPageClicked">
  <app-signup-settings></app-signup-settings>
</div>

<!-- About us setting -->
<div *ngIf="aboutUsClicked">
  <app-about-setting [isShowCreated]="false"></app-about-setting>
</div>
