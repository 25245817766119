<div class="mb-10">
    
    <div class="flex flex-col md:flex-row justify-between gap-y-3 gap-x-8 p-4 md:p-12 ">
      <div class="flex flex-col space-y-4 md:w-2/3">

        <!-- CHECKBOX LOGGEDIN/NON-LOGGEDIN -->
        <div class="flex  w-1/2 justify-center md:justify-start md:w-full ">
          <input
            class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-2 align-top bg-no-repeat bg-center bg-contain float-left mr-1 cursor-pointer"
            type="checkbox" value="" id="flexCheckDefault" (click)="loggedInStatusChecker()"
            [checked]="isLoggedInChecked">{{loggedInName}}
        </div>

        <!-- DROPDOWN  DATE FILTER-->
        <div class="flex justify-center md:justify-end ">
          <div tabindex="0" class="ml-3 collapse collapse-arrow w:3/12 md:w-3/12 ">
            <input type="checkbox" [(ngModel)]="dateFilterActive">
            <div class="collapse-title font-medium bg-white">
              <div class="flex flex-row ">

                <i class="material-icons mr-4 float-left">calendar_today</i>

                <span><b>Date</b></span>

              </div>

            </div>
            <div class="collapse-content bg-white">
              <div>
                <button (click)="dailyPages()"
                  class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100 ">
                  <span><b>Daily</b></span>
                </button>
                <hr />
              </div>
              <div>
                <button (click)="weeklyPages()"
                  class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100">
                  <span><b>Last 7 days</b></span>
                </button>
                <hr />
              </div>
              <div>
                <button (click)="monthlyPages()"
                  class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg  hover:bg-gray-100 ">
                  <span><b>Last 30 days</b></span>
                </button>
                <hr />
              </div>
              <div>
                <button (click)="customDateForPages()"
                  class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg  hover:bg-gray-100 ">
                  <span><b>Between dates</b></span>
                </button>
                <hr />
              </div>
            </div>
          </div>
        </div>
        <!-- END  DATE DROPDOWN -->


        <!-- NEW DATE PICKER START-->

        <div *ngIf="filterByDatePage">
          <div class="w-full  bg-white md:px-10 px-2  py-6 space-y-2 shadow-sm">
            <div class="flex space-x-4 items-center">
              <svg width="28" height="28"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zm64 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm128 0v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H336zM64 400v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H208zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H336c-8.8 0-16 7.2-16 16z"/></svg>
              <div class="w-10/12">
                <p class="font-bold text-lg">Date </p>
                <p class="text-xs text-gray-500">Enter the start date, end date to filter the pages</p>
              </div>
            </div>
            <div  class="flex justify-center">
              <div class="w-full ">
                <div class=" w-full md:w-1/2">
                  <div class="w-full px-3 md:px-0 md:mb-0">
                    <label class="label">
                      <span class="label-text text-[#4F4F4F] text-base font-bold leading-5">Start Date
                        <span class="text-red-500">*</span>
                      </span>
                    </label>
                  </div>
                 
                    <div class="w-full  px-3 md:mb-0">
                      <div class=" bg-gray-100 p-1 rounded-lg" [formGroup]="createCalenderForm">
                        <input id="analyticsStartDate" formControlName="analyticsStartDate" type="date"
                        class="input w-full  rounded-sm bg-gray-100 text-gray-700" [ngClass]="{
                        'border-red-500': (analyticsStartDate.dirty || analyticsStartDate.touched) && analyticsStartDate.invalid,
                        'border-gray-200': !((analyticsStartDate.dirty || analyticsStartDate.touched) && analyticsStartDate.invalid)
                      }" (change)="dateChangeStartDate()">
                      </div>
                    </div>
                </div>
      
            
               
                
              </div>
              <br/>
      
              <div class="w-full">
             <div class="w-full md:w-1/2 ">
              <div class="w-full px-3 md:px-0 md:mb-0">
                <label class="label">
                  <span class="label-text text-[#4F4F4F] text-base font-bold leading-5">End Date
                    <span class="text-red-500">*</span>
                  </span>
                </label>
              </div>
              <div class="w-full  px-3 md:mb-0">
                <div class=" bg-gray-100 p-1 rounded-lg" [formGroup]="createCalenderForm">
                  <input id="analyticsEndDate" formControlName="analyticsEndDate" type="date"
                class="input w-full leading-tight rounded-sm bg-gray-100 text-gray-700"
                [ngClass]="{
                  'border-red-500': (analyticsEndDate.dirty || analyticsEndDate.touched) && analyticsEndDate.invalid,
                  'border-gray-200': !((analyticsEndDate.dirty || analyticsEndDate.touched) && analyticsEndDate.invalid)
                }" (change)="dateChangeEndDate()">
                </div>
              </div>
             </div>
      
         
              </div>
      
            </div>
            <p *ngIf="dateInvalid" class="text-red-600">Please enter the dates correctly. End date should be greater than
              Start date.</p>
            <div class="flex-wrap text-center  text-md rounded-full items-center justify-center mx-4 mt-6 ">
              <button
                class="font-medium cursor-pointer border-2 border-customTeal px-3 md:px-6 py-2 md:py-1 rounded-full md:font-bold"
                (click)="customDateUpdatePages(analyticsStartDate, analyticsEndDate)">Apply</button>
            </div>
          </div>
          </div>

        <!-- NEW DATE PICKER END -->
        

        <div class="flex w-full justify-end">

        </div>

        <div class="flex bg-white rounded-md py-10 justify-evenly">
          <div class="flex-col text-center">
            <div class="tooltip" data-tip="Total views of the pages on your app.">
              <i class="material-icons flex justify-end">info</i>

            </div>
           
            <p class="font-bold text-customTeal">{{page_views}}</p>
            <p class="text-xs ">Page View</p>

          </div>

          <div class="flex-col text-center">
            <div class="tooltip" data-tip="Total amount of time spent on your app.">
              <i class="material-icons flex justify-end">info</i>

            </div>
            <p class="font-bold text-customTeal">{{avgTimeOnPage}}</p>
            <p class="text-xs ">Engagement time</p>
          </div>
          <div class="flex-col text-center">
            <div class="tooltip"
              data-tip="The percentage of visitors who access your app but never interact with it. Website visits that are single-page sessions—where a visitor leaves without reading a second page—is represented by this rate.">
              <i class="material-icons flex justify-end">info</i>
            </div>
            <p class="font-bold text-customTeal">{{bounce_rate}}%</p>
            <p class="text-xs ">Bounce rate</p>
          </div>
        </div>

        <div class="mx-2 lg:mx-2 mt-10"><strong>Page Analytics</strong></div>


        <div>

          <!-- PAGE GRAPH -->
          <div class="flex mt-2">

            <div *ngIf="startDate && endDate && !isUndefinedProductDetails() && isProductPagesClicked"
              class="w-full bg-white rounded-lg">
              <div>
                <!-- <canvas class="h-60 md:h-20" baseChart chartType="horizontalBar" [datasets]="pageChart.datasets"
                  [labels]="pageChart.labels" [options]="pageChart.options" legend="true">
                </canvas> -->
                <ngx-charts-bar-horizontal-2d
                [results]="dataVBC"
                [view]="viewVBC"
                [animations]="animationsVBC"
                [legend]="legendVBC"
                [xAxis]="xAxisVBC"
                [yAxis]="yAxisLabelVBC"
                [showYAxisLabel]="showYAxisLabelVBC"
                [yAxisLabel]="yAxisLabelVBC"
                [dataLabelFormatting]="dataLabelFormatterVBC"
                [barPadding]="20"
                class="custom-bar-chart">
              </ngx-charts-bar-horizontal-2d>

              </div>
            </div>

            <div class="flex mt-2">
              <div *ngIf="isUndefinedProductDetails() && isProductPagesClicked" class="w-full bg-white rounded-lg">
                <div class="relative w-full h-auto  overflow-hidden"
                  *ngIf="isProductPagesClicked && isUndefinedProductDetails()">
                  <p class="absolute py-2.5  inset-x-0 text-center mt-10   leading-4 ">Sorry, seems like there isn't
                    enough data for analysis.</p>
                  <p class="absolute py-10 inset-x-0 mt-10 text-center">Graph will be available after few visits.</p>
                  <img

                      loading="lazy"

                      class="object-cover w-full h-auto"
                      
                      data-src="https://api.goingmobile.app/media/personal-app-assets/img/webp/graph.webp"
                      
                      srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/graph.webp"
                      
                      fallback="https://api.goingmobile.app/media/personal-app-assets/img/png/graph.png"
                      
                      alt="Null data, graph with no data">
                </div>
              </div>

            </div>

            <!-- SERVICE GRAPH -->

            <div *ngIf="startDate && endDate && !isUndefinedServiceDetails() && isServicePagesClicked"
              class="w-full bg-white rounded-lg">
              <div>
                <!-- <canvas class="h-60 md:h-20" baseChart chartType="horizontalBar" [datasets]="pageChart.datasets"
                  [labels]="pageChart.labels" [options]="pageChart.options" legend="true">
                </canvas> -->
                <ngx-charts-bar-horizontal-2d
                [results]="dataVBC"
                [view]="viewVBC"
                [animations]="animationsVBC"
                [legend]="legendVBC"
                [xAxis]="xAxisVBC"
                [yAxis]="yAxisLabelVBC"
                [showYAxisLabel]="showYAxisLabelVBC"
                [yAxisLabel]="yAxisLabelVBC"
                [dataLabelFormatting]="dataLabelFormatterVBC"
                [barPadding]="20"
                class="custom-bar-chart">
              </ngx-charts-bar-horizontal-2d>
              </div>

            </div>

            <div class="flex mt-2">
              <div *ngIf="isUndefinedServiceDetails() && isServicePagesClicked" class="w-full bg-white rounded-lg">
                <div class="relative w-full h-auto  overflow-hidden"
                  *ngIf="isServicePagesClicked && isUndefinedServiceDetails()">
                  <p class="absolute py-2.5  inset-x-0 text-center mt-10   leading-4 ">Sorry, seems like there isn't
                    enough data for analysis.</p>
                  <p class="absolute py-10 inset-x-0 mt-10 text-center">Graph will be available after few visits.</p>
                  <img
                      loading="lazy"

                      class="object-cover w-full h-auto"
                      
                      data-src="https://api.goingmobile.app/media/personal-app-assets/img/webp/graph.webp"
                      
                      srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/graph.webp"
                      
                      fallback="https://api.goingmobile.app/media/personal-app-assets/img/png/graph.png"
                      
                      alt="Null data, graph with no data">

                </div>
              </div>


            </div>


            <!-- END SERVICE GRAPH -->

            <!-- BLOG GRAPH -->

            <div *ngIf="startDate && endDate && !isUndefinedBlogDetails() && isBlogPagesClicked"
              class="w-full bg-white rounded-lg">
              <div>
                <!-- <canvas class="h-60 md:h-20" baseChart chartType="horizontalBar" [datasets]="pageChart.datasets"
                  [labels]="pageChart.labels" [options]="pageChart.options" legend="true">
                </canvas> -->
                <ngx-charts-bar-horizontal-2d
                [results]="dataVBC"
                [view]="viewVBC"
                [animations]="animationsVBC"
                [legend]="legendVBC"
                [xAxis]="xAxisVBC"
                [yAxis]="yAxisLabelVBC"
                [showYAxisLabel]="showYAxisLabelVBC"
                [yAxisLabel]="yAxisLabelVBC"
                [dataLabelFormatting]="dataLabelFormatterVBC"
                [barPadding]="20"
                class="custom-bar-chart">
              </ngx-charts-bar-horizontal-2d>
              </div>

            </div>

            <div class="flex mt-2">
              <div *ngIf="isUndefinedBlogDetails() && isBlogPagesClicked" class="w-full bg-white rounded-lg">
                <div class="relative w-full h-auto  overflow-hidden"
                  *ngIf="isBlogPagesClicked && isUndefinedBlogDetails()">
                  <p class="absolute py-2.5  inset-x-0 text-center mt-10   leading-4 ">Sorry, seems like there isn't
                    enough data for analysis.</p>
                  <p class="absolute py-10 inset-x-0 mt-10 text-center">Graph will be available after few visits.</p>
                  <img
                      loading="lazy"

                      class="object-cover w-full h-auto"
                      
                      data-src="https://api.goingmobile.app/media/personal-app-assets/img/webp/graph.webp"
                      
                      srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/graph.webp"
                      
                      fallback="https://api.goingmobile.app/media/personal-app-assets/img/png/graph.png"
                      
                      alt="Null data, graph with no data">

                </div>
              </div>


            </div>


            <!-- END BLOG GRAPH -->

            <!-- EVENT GRAPH -->

            <div *ngIf="startDate && endDate && !isUndefinedEventDetails() && iseventPagesClicked"
              class="w-full bg-white rounded-lg">
              <div>
                <!-- <canvas class="h-60 md:h-20" baseChart chartType="horizontalBar" [datasets]="pageChart.datasets"
                  [labels]="pageChart.labels" [options]="pageChart.options" legend="true">
                </canvas> -->
                <ngx-charts-bar-horizontal-2d
                [results]="dataVBC"
                [view]="viewVBC"
                [animations]="animationsVBC"
                [legend]="legendVBC"
                [xAxis]="true"
                [yAxis]="true"
                [showYAxisLabel]="true"
                [showXAxisLabel]="true"
                [dataLabelFormatting]="dataLabelFormatterVBC"
                [barPadding]="20"
                class="custom-bar-chart">
              </ngx-charts-bar-horizontal-2d>
              </div>

            </div>

            <div class="flex mt-2">
              <div *ngIf="isUndefinedEventDetails() && iseventPagesClicked" class="w-full bg-white rounded-lg">
                <div class="relative w-full h-auto  overflow-hidden"
                  *ngIf="iseventPagesClicked && isUndefinedEventDetails()">
                  <p class="absolute py-2.5  inset-x-0 text-center mt-10   leading-4 ">Sorry, seems like there isn't
                    enough data for analysis.</p>
                  <p class="absolute py-10 inset-x-0 mt-10 text-center">Graph will be available after few visits.</p>
                  <img
                      loading="lazy"

                      class="object-cover w-full h-auto"
                      
                      data-src="https://api.goingmobile.app/media/personal-app-assets/img/webp/graph.webp"
                      
                      srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/graph.webp"
                      
                      fallback="https://api.goingmobile.app/media/personal-app-assets/img/png/graph.png"
                      
                      alt="Null data, graph with no data">

                </div>
              </div>

            </div>

            <!-- END EVENT GRAPH -->

          </div>
          <!--  -->

        </div>

      </div>

      <div class="flex flex-col md:w-2/6 gap-y-4">
        <div class="bg-white rounded-md p-6 space-y-6">
          <p class="text-center text-lg font-bold">Top 10 visited pages</p>
          <div class="flex justify-between pl-8" *ngIf="top10PagesWzNames && top10PagesWzNames.length > 0">

            <p class="text-xs ">page</p>
            <p class="text-xs ">time spent</p>
          </div>

          <!-- LIST OF TOP TEN PAGES -->
          <div *ngIf="top10PagesWzNames  && top10PagesWzNames.length >0">
            <!-- <div *ngFor="let item of top10PagesWzNames  | filter:search_text | 
               paginate: { itemsPerPage: 10, 
               currentPage: page,
               totalItems: totalLength}; let i=index"
              class="flex flex-row bg-white cursor-pointer items-center  px-5 py-3  md:space-x-6"> -->

              <div *ngFor="let item of top10PagesWzNames  | filter:search_text 
               let i=index"
             class="flex flex-row bg-white cursor-pointer items-center  px-5 py-3  md:space-x-6">

              <div class="w-full space-y-3">
                <!-- ITEM -->
                <div class="flex ">
                  <b>
                    <p class=" ">{{i+1}}</p>
                  </b>
                  <div class="flex justify-between pl-6  w-full ">
                    <button (click)="linkToPage2(i)" class="">
                      <b>
                        <p class=" ">{{item.path}}</p>
                      </b>

                    </button>

                    <b>
                      <p *ngIf="item.views == 1" class=" ">{{item.views}} min.</p>
                      <p *ngIf="item.views != 1" class=" ">{{item.views}} mins.</p>

                    </b>
                  </div>
                </div>
                <hr>

              </div>
            </div>
          </div>

          <div class=" justify-center w-full mt-8 bg-white rounded-md p-6 space-y-6"
            *ngIf="top10PagesWzNames.length == 0">
            <img
                      loading="lazy"

                      class="object-cover w-10 h-10 mt-3 md:mt-0  md:w-20 md:h-20 rounded-md cover m-auto"
                      
                      data-src="https://api.goingmobile.app/media/personal-app-assets/img/webp/searchEmoji.webp"
                      
                      srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/searchEmoji.webp"
                      
                      fallback="https://api.goingmobile.app/media/personal-app-assets/img/png/searchEmoji.png"
                      
                      alt="Search emoji. ">

            <p class="text-center">Top pages visited will show up here.</p>

          </div>
        </div>

      </div>

    </div>
    <div class="mx-10 mt-10"><strong>Page Details</strong></div>

    <!-- SERVICE BLOG EVENT PRODUCT -->

    <!-- SERVICE, BLOG, PRODUCT , EVENT BUTTONS -->

    <div class="flex space-x-2 md:space-x-28 items-center justify-center">
      <div class="flex-row text-center  text-md rounded-full items-center justify-center mx-4"  *ngIf="isEnabledServices">
        <button (click)="servicePageClicked()"
          class="font-medium cursor-pointer border-2 border-customTeal px-3 md:px-6 py-2 md:py-1 rounded-full md:font-bold"
          [ngClass]="{'text-white bg-customTeal': isServicePagesClicked, 'text-black': !isServicePagesClicked}">{{serviceDisplayName}}
        </button>
      </div>
      <div class="flex-row text-center rounded-full text-md" *ngIf="isEnabledBlogs">
        <button (click)="blogPageClicked()"
          class="font-medium cursor-pointer border-2 border-customTeal px-3 md:px-6 py-2 md:py-1 rounded-full md:font-bold"
          [ngClass]="{'text-white bg-customTeal': isBlogPagesClicked, 'text-black': !isBlogPagesClicked}">{{blogDisplayName}}</button>
      </div>
      <div class="flex-row text-center rounded-full text-md" *ngIf="isEnabledProducts">
        <button (click)="productPageClicked()"
          class="font-medium cursor-pointer border-2 border-customTeal px-3 md:px-6 py-2 md:py-1 rounded-full md:font-bold"
          [ngClass]="{'text-white bg-customTeal': isProductPagesClicked, 'text-black': !isProductPagesClicked}">{{productDisplayName}}</button>
      </div>
      <div class="flex-row text-center rounded-full text-md" *ngIf="isEnabledEvents">
        <button (click)="eventPageClicked()"
          class="font-medium cursor-pointer border-2 border-customTeal px-3 md:px-6 py-2 md:py-1 rounded-full md:font-bold"
          [ngClass]="{'text-white bg-customTeal': iseventPagesClicked, 'text-black': !iseventPagesClicked}">{{eventDisplayName}}</button>
      </div>
      <br />

    </div>

    <!-- *******************************END *********************** -->

    <!--  ALL PAGE DETAIL HEADER  -->

    <div class=" flex flex-col space-y-2 mx-6 md:mx-12 lg:mx-12">
      <div class=" flex flex-row items-center mt-2 p-2 md:space-x-6">
        <div class="w-1/12 ">
        </div>
        <div class="w-9/12 md:w-5/12">
          <p class=" text-sm">Name</p>
        </div>
        <div class="w-3/12 md:w-5/12">
          <p class=" text-sm">Engagement time</p>
          <p class=" text-sm">[hh:mm:ss]</p>
        </div>
        <div class="w-2/12 hidden lg:block">
          <p class=" text-sm">Bounce rate</p>
        </div>
      </div>
    </div>

  

    <!-- SERVICE PAGE DETAIL -->
    <div *ngIf="isServicePagesClicked">
      <!-- FOR SERVICE RELATED PAGES - DETAIL LIST OF PAGES -->

      <div class="flex flex-col space-y-2 mx-2 lg:mx-10"
        *ngIf="isServicePagesClicked && serviceDetails.length > 0 && startDate && endDate">

        <!-- <div class="flex flex-row bg-white items-center mt-2 p-5 rounded-md  border-2 md:space-x-6" *ngFor="let item of serviceDetails | 
      paginate: { itemsPerPage: 10, 
      currentPage: pg2,
      totalItems: serviceDetails.length}; let i= index "> -->

      <div class="flex flex-row bg-white items-center mt-2 p-5 rounded-md  border-2 md:space-x-6" 
      *ngFor="let item of serviceDetails 
       let i= index ">

          <div class="w-1/12 md:pl-4">
            <p class=" text-sm">{{i+1}}</p>

          </div>
          <div class="w-9/12 md:w-5/12">
            <p class=" text-sm"><b>{{item.name}}</b></p>

          </div>
          <div class="w-3/12 md:w-5/12">
            <p class=" text-sm">{{item.avgTimeOnPage}}</p>

          </div>
          <div class="w-2/12 pl-2 hidden lg:block">
            <p class=" text-sm">{{item.bounceRate}}</p>

          </div>

        </div>
        <br />
        <!-- <pagination-controls class="text-right mr-20" (pageChange)="pg2 = $event"></pagination-controls> -->
      </div>

      <div class=" flex flex-col mx-6 md:mx-12 lg:mx-4 bg-white rounded-md p-6 space-y-6"
        *ngIf="startDate && endDate && serviceDetails.length == 0">

        <img
          loading="lazy"

          class="object-cover w-10 h-10 mt-3 md:mt-0  md:w-20 md:h-20 rounded-md cover m-auto"
                      
          data-src="https://api.goingmobile.app/media/personal-app-assets/img/webp/emptyFolder.webp"
                      
          srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/emptyFolder.webp"
                      
          fallback="https://api.goingmobile.app/media/personal-app-assets/img/png/emptyFolder.png"
                      
          alt="Empty folder image.">

        <p class="text-center">Visited page detail will show up here.</p>
      </div>

    </div>
    <!-- BLOG PAGE DETAIL -->
    <div *ngIf="isBlogPagesClicked">

      <!-- FOR BLOG RELATED PAGES  -->

      <div class="flex flex-col space-y-2 mx-2 lg:mx-10"
        *ngIf="isBlogPagesClicked && blogDetails.length > 0 && startDate && endDate">

        <!-- <div class="flex flex-row bg-white items-center mt-2 p-5 rounded-md  border-2 md:space-x-6" *ngFor="let item of blogDetails | 
        paginate: { itemsPerPage: 10, 
        currentPage: pg3,
        totalItems: blogDetails.length}; let i= index "> -->

        <div class="flex flex-row bg-white items-center mt-2 p-5 rounded-md  border-2 md:space-x-6" 
        *ngFor="let item of blogDetails  let i= index ">

          <div class="w-1/12 md:pl-4">
            <p class=" text-sm">{{i+1}}</p>

          </div>
          <div class="w-9/12 md:w-5/12 ">
            <p class=" text-sm"><b>{{item.name}}</b></p>

          </div>
          <div class="w-3/12 md:w-5/12">
            <p class=" text-sm">{{item.avgTimeOnPage}}</p>

          </div>
          <div class="w-2/12 hidden lg:block">
            <p class=" text-sm">{{item.bounceRate}}</p>

          </div>

        </div>
        <br />
        <!-- <pagination-controls class="text-right mr-20" (pageChange)="pg3 = $event"></pagination-controls> -->
      </div>

      <div class=" flex flex-col mx-6 md:mx-12 lg:mx-4 bg-white rounded-md p-6 space-y-6"
        *ngIf="startDate && endDate && blogDetails.length == 0">
        <img
                      loading="lazy"

                      class="object-cover w-10 h-10 mt-3 md:mt-0  md:w-20 md:h-20 rounded-md cover m-auto"
                      
                      data-src="https://api.goingmobile.app/media/personal-app-assets/img/webp/emptyFolder.webp"
                      
                      srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/emptyFolder.webp"
                      
                      fallback="https://api.goingmobile.app/media/personal-app-assets/img/png/emptyFolder.png"
                      
                      alt="Empty folder. ">
    
        <p class="text-center">Visited page detail will show up here.</p>
      </div>
    </div>

    <!-- PRODUCT PAGE DETAIL -->
    <div *ngIf="isProductPagesClicked">

      <!-- FOR PRODUCTS PAGE RELATED  -->

      <div class="flex flex-col space-y-2 mx-2 lg:mx-10"
        *ngIf="isProductPagesClicked && productDetails.length > 0 && startDate && endDate">

        <!-- <div class="flex flex-row bg-white items-center mt-2 p-5 rounded-md  border-2 md:space-x-6" *ngFor="let item of productDetails | 
      paginate: { itemsPerPage: 10, 
      currentPage: pg4,
      totalItems: productDetails.length}; let i= index "> -->

      <div class="flex flex-row bg-white items-center mt-2 p-5 rounded-md  border-2 md:space-x-6" 
      *ngFor="let item of productDetails let i= index ">


          <div class="w-1/12 md:pl-4">
            <p class=" text-sm">{{i+1}}</p>

          </div>

          <div class="w-9/12 md:w-5/12 ">
            <p class=" text-sm"><b>{{item.name}}</b></p>

          </div>
          <div class="w-3/12 md:w-5/12 ">
            <p class=" text-sm">{{item.avgTimeOnPage}}</p>

          </div>
          <div class="w-2/12  hidden lg:block">
            <p class=" text-sm">{{item.bounceRate}}</p>

          </div>

        </div>
        <br />
        <!-- <pagination-controls class="text-right mr-20" (pageChange)="pg4 = $event"></pagination-controls> -->
      </div>

      <div class=" flex flex-col mx-6 md:mx-12 lg:mx-4 bg-white rounded-md p-6 space-y-6"
        *ngIf="startDate && endDate && productDetails.length == 0">
        
        <img
        loading="lazy"

        class="object-cover w-10 h-10 mt-3 md:mt-0  md:w-20 md:h-20 rounded-md cover m-auto"
        
        data-src="https://api.goingmobile.app/media/personal-app-assets/img/webp/emptyFolder.webp"
        
        srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/emptyFolder.webp"
        
        fallback="https://api.goingmobile.app/media/personal-app-assets/img/png/emptyFolder.png"
        
        alt="Empty folder. ">

        <p class="text-center">Visited page detail will show up here.</p>
      </div>



    </div>

    <!-- EVENT PAGE DETAIL -->
    <div *ngIf="iseventPagesClicked">

      <!-- FOR EVENT PAGE DETAILS  -->
      <div class="flex flex-col space-y-2 mx-2 lg:mx-10"
        *ngIf="iseventPagesClicked && eventDetails.length > 0 && startDate && endDate">

        <!-- <div class="flex flex-row bg-white items-center mt-2 p-5 rounded-md  border-2 md:space-x-6" *ngFor="let item of eventDetails | 
      paginate: { itemsPerPage: 10, 
      currentPage: pg5,
      totalItems: eventDetails.length}; let i= index "> -->

      <div class="flex flex-row bg-white items-center mt-2 p-5 rounded-md  border-2 md:space-x-6" 
      *ngFor="let item of eventDetails  let i= index ">

          <div class="w-1/12 md:pl-4">
            <p class=" text-sm">{{i+1}}</p>

          </div>
          <div class="w-9/12 md:w-5/12 ">
            <p class=" text-sm"><b>{{item.name}}</b></p>

          </div>
          <div class="w-3/12 md:w-5/12">
            <p class=" text-sm">{{item.avgTimeOnPage}}</p>

          </div>
          <div class="w-2/12  hidden lg:block">
            <p class=" text-sm">{{item.bounceRate}}</p>

          </div>

        </div>
        <br />
        <!-- <pagination-controls class="text-right mr-20" (pageChange)="pg5 = $event"></pagination-controls> -->
      </div>

      <div class=" flex flex-col mx-6 md:mx-12 lg:mx-4 bg-white rounded-md p-6 space-y-6"
        *ngIf="startDate && endDate && eventDetails.length == 0">
        <img
          loading="lazy"
          
          class="object-cover w-10 h-10 mt-3 md:mt-0  md:w-20 md:h-20 rounded-md cover m-auto"
                      
          data-src="https://api.goingmobile.app/media/personal-app-assets/img/webp/emptyFolder.webp"
                      
          srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/emptyFolder.webp"
                      
          fallback="https://api.goingmobile.app/media/personal-app-assets/img/png/emptyFolder.png"
                      
          alt="Empty folder image.">

        <p class="text-center">Visited page detail will show up here.</p>
      </div>

    </div>

  </div>