<!-- Cancel Modal -->
<div class="modal modal-open"  id="cancelModal">
  <div class="modal-box rounded-md shadow-md ">
    <h3 class="font-bold text-lg text-center mb-2">Cancel</h3>
    <p class="py-2 text-center"> Are you sure you want to cancel {{canceledFromAction}} a {{canceledFromComponent}}?</p>

    <div class="modal-action items-center justify-between">
  
      <button type="button" (click)="cancelActionClicked()"
      class="flex items-center space-x-2 rounded border-2 border-solid border-gray-200 px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-brandColor-700 transition duration-150 ease-in-out hover:border-brandColor-accent-100 hover:bg-neutral-500 hover:bg-opacity-10 focus:border-brandColor-accent-100 focus:outline-none focus:ring-0 active:border-brandColor-accent-200 dark:text-brandColor-100 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
      >
      <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 " height="1em" viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
        <span>Cancel</span>
      </button>

      <button  
      class="flex items-center space-x-2  rounded bg-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-brandColor-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-brandColor-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-brandColor-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
      type="button" (click)="continueActionClicked()">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 fill-white" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
        <span>Back to {{canceledFromAction}}</span>
      </button>
    </div>
  </div>
</div>
