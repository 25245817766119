

<div class="font-body bg-veryLightGray min-h-screen max-h-max pt-6">
    <div class="flex flex-col md:w-4/6 m-2 bg-white h-max p-4 md:p-12 rounded-lg space-y-4 md:translate-x-1/4">
       <div class="text-center" *ngIf="isDemo">  
        <i class="text-green-500 material-icons text-7xl">check_circle</i>
        <div class="text-customTeal text-2xl font-bold text-center px-4">
          Thank you for choosing Going Mobile</div>
        </div>
        <p class=" font-bold text-xl text-center text-customTeal ">Just one more thing </p>
        <!-- Area of interest -->
        <div class="space-y-2 pt-2" *ngIf="AllareaInterest && AllareaInterest.length > 0">
            <p class="font-bold">What's your Area of interest? </p>
            <p class="text-xs text-darkGray">Please put in  your area of interest.</p>
            <!-- button -->
            <div class="flex flex-wrap gap-2 " >
                <div *ngFor="let item of AllareaInterest; let i = index">
                    <button (click)="selectInterest(item)" class="border-2 border-customTeal rounded-full py-1 px-6  my-2"
                        [ngClass]="{'bg-customTeal text-white' : isInterestClickedmethod(item)}">
                        {{item.name}}
                    </button>
                </div>
            </div>
            <span class="text-error" *ngIf="selectedInterest.length === 0 ">There is no area of interest choosen</span>
        </div>
        <hr>
        <!-- Notification -->
        <div class="flex flex-col space-y-6 justify-between md:flex-row md:space-y-0 ">
            <!-- Description -->
            <div class="md:w-1/2 space-y-2  ">
                <p class="font-bold">
                    Notification
                </p>
                <p class=" text-xs text-darkGray ">
                    Please fill out your notifications settings.
                </p>
            </div>

            <!-- toggle & edit -->
            <div class="flex justify-center align-middle space-x-8 items-baseline">
                <!-- toggle -->
                <div>
                    <div class="form-control">
                        <label class="label cursor-pointer">
                            <span class="label-text pr-2 font-bold ">ON</span>
                            <input type="checkbox" class="toggle toggle-primary " [checked] = "toggleNotfication" (click) = "toggleNotificationButton($event)"/>
                        </label>
                    </div>
                </div>

            </div>
        </div>
        <hr>
        <!-- Send content -->
        <div class="flex flex-col space-y-6 justify-between md:flex-row md:space-y-0 ">
            <!-- Description -->
            <div class="md:w-1/2 space-y-2  ">
                <p class="font-bold">
                    Send content about new offers, Events and promotion?
                </p>
                <p class=" text-xs text-darkGray ">
                    Please choose your preference for the content about new offers, Events and promotions.
                </p>
            </div>


            <div class="flex justify-center align-middle space-x-8 items-baseline">
                <!-- <span *ngIf="toggleYesorNo"> -->
                  <div class="form-control">
                    <label class="label cursor-pointer space-x-2">
                        <input type="checkbox" class="checkbox checkbox-primary" [checked]="SMSchecked"  [disabled]="loggedInUser && loggedInUser.cellPhone == ''" (click)="toggleSMS($event)"/>

                        <span class="label-text">SMS</span>
                    </label>
                </div>
                <div class="form-control">
                    <label class="label cursor-pointer space-x-2">
                        <input type="checkbox" class="checkbox checkbox-primary" [checked]="emailChecked" (click)="toggleEmail($event)"/>
                        <span class="label-text">Email</span>
                    </label>
                </div>
                <div class="form-control">
                    <label class="label cursor-pointer space-x-2">
                        <input type="checkbox" class="checkbox checkbox-primary" [checked]="InappChecked" (click)="toggleInapp($event)"/>
                        <span class="label-text">In App</span>
                    </label>
                </div>
            <!-- </span> -->

            </div>
            <div class="text-yellow-400 flex font-bold" *ngIf="loggedInUser && loggedInUser.cellPhone == ''"><i class="material-icons">warning</i><span class="text-yellow-500">You should enter your cell phone in order to choose the SMS preference.</span></div>

        </div>
        <span class="text-error" *ngIf="noMessage">You have to choose the connection method</span>


        <div class="pt-6 space-y-2">
            <div class="">
            </div>
            <div class=" flex justify-end w-full">
                <button type="submit" (click)="finish()"
                    class="flex space-x-2  px-4 md:px-6 py-2 bg-customTeal text-white rounded-lg w-max h-min text-sm md:text-base font-bold hover:bg-darkBlue focus:outline-none  ">
                    <span>Submit</span>
                </button>
            </div>
        </div>

    </div>




<div id="my-modal-disable" *ngIf="reloadModal" class="modal"
    [ngClass]="{'modal-open': reloadModal, 'modal-action': !reloadModal }">
    <div class="modal-box max-w-4xl">
        <div class="modal-content">
          <div class="flex justify-end "  (click)="closeReloadModal()"><i class="material-icons border-2 rounded-full px-2 py-2 border-customTeal text-white bg-customTeal">close</i></div>
            <div>
                <h2>Login Again</h2>
                <p>Please login again</p>
            </div>
        </div>
    </div>
</div>
</div>
