<!-- The column name for the list of the messages -->
<div class=" flex flex-col space-y-2 mx-2 md:mx-12 lg:mx-4" *ngIf="contactsMessagesList && contactsMessagesList.length != 0">
<div class=" flex flex-row items-center mt-2 p-2 md:space-x-6" *ngIf="contactsMessagesList.length > 0">
    <div class="w-5/12 lg:w-4/12 ">
    <p class=" text-sm">Name</p>
    </div>
    <div class="w-3/12">
    <p class=" text-sm">Email</p>
    </div>
    <div class="lg:w-3/12 hidden lg:block">
    <p class=" text-sm">Date</p>
    </div>
    <div class="lg:w-5/12 hidden lg:block">
    <p class=" text-sm">Message</p>
    </div>
    <div class="w-1/12">
    </div>
</div>

  
<!-- The list of the messages -->
<div class="flex w-full items-center justify-center "
*ngFor="let item of getSortedList(contactsMessagesList)| paginate: { itemsPerPage: 6, currentPage: p }; let i=index">
<div (click)="viewMessageDetail(item)"
    class="flex flex-row bg-white cursor-pointer items-center  mt-2 p-2 rounded-md mx-2  border-2 md:space-x-6 w-full">
    <div class=" w-5/12 lg:w-4/12">
    <p *ngIf="item.name">{{item.name | titlecase}}</p>
    </div>
    <div class="w-5/12 lg:w-3/12">
    <p class=" text-sm" *ngIf="item.email">{{item.email}}</p>
    </div>
    <div class="hidden lg:block w-3/12">
    <p class=" text-sm" *ngIf="item.timeStamp">{{item.timeStamp.toDate() | date:'medium'}}</p>
    </div>
    <div class="hidden lg:block lg:w-5/12">
    <p class=" text-sm" *ngIf="item.message">{{item.message}}</p>
    </div>
    <div class="w-1/12 lg:1/12">
    <button (click)="viewMessageDetail(item)" type="button">
        <i class="material-icons material-symbols-outlined float-left">arrow_circle_right </i>
    </button>
    </div>
</div>

</div>
<!-- Pagination -->
<div class="w-full text-center mt-10">
    <pagination-controls (pageChange)="p = $event"></pagination-controls>
</div>
</div>

<div *ngIf="!contactsMessagesList || contactsMessagesList.length == 0">
  <div class="flex items-center justify-center">
    <img loading="lazy" data-src="../../../../assets/icons/Illustrations/notification Illustration.png"
    class="block w-64 items-center justify-center">
</div>
<div class="text-customTeal text-lg text-center font-bold">No Messages found.</div>

</div>

<!-- Message Detail Modal -->
<div id="my-modal-users" *ngIf="viewDetailModal" class="modal" [ngClass]="{'modal-open': viewDetailModal, 'modal-action': !viewDetailModal }">
    <div class="modal-box max-w-xl">
      <div class="modal-content">

          <ng-container *ngIf="selectedContactMessage">
              <h3 class="font-bold text-lg text-center">Message</h3>
              <div>
                <button (click)="contactUserModal=true" class="border rounded-md py-2 px-2 text-black  modal-button float-right" type="button">
                  <i class="material-icons w-2 h-2 pr-8 float-left">group</i>
                  Send an Email
                </button>
              </div>
              <p class="pt-4 pl-1">
                  <span class="font-bold">Name: </span>{{selectedContactMessage.name}}
              </p>
              <p class="pt-4 pl-1">
                  <span class="font-bold">Email: </span>{{selectedContactMessage.email}}
              </p>
              <p class="pt-4 pl-1">
                  <span class="font-bold">Date: </span>{{selectedContactMessage.timeStamp.toDate() | date:'medium'}}
              </p>
              <div class="flex md:flex-row flex-col">
                <div>
                  <p class="pt-4 pl-1">
                  <span class="font-bold">Message: </span>
                  </p>
                </div>
                <div>
                  <p class="pt-4 pl-1">
                    {{selectedContactMessage.message}}
                </p>
                </div>
              </div>
              
              
          </ng-container>
          <ng-container *ngIf="!selectedContactMessage">
              <h3 class="font-bold text-lg text-center">No message is selected</h3>
          </ng-container>
          <div class="modal-action items-center justify-between">
              <label>
                <button class="btn-custom-solid"
                type="button" (click)="viewDetailModal=false">Close</button>
              </label>
          </div>
      </div>
    </div>
</div>

  <!---------------Contact Clients using email ---------------------->
   <div id="my-modal-users" *ngIf="contactUserModal" class="modal"
    [ngClass]="{'modal-open': contactUserModal, 'modal-action': !contactUserModal }">
    <div class="modal-box max-w-3xl">
      <div class="modal-content">
        <h3 class="font-bold text-lg text-center">Contact User</h3>
        <p class="pt-4 text-gray-400 pl-1 text-center">Contact user selected.</p>

        <form [formGroup]="contactForm"
          (ngSubmit)="sendSubscribers(contactForm.value.subject, contactForm.value.emailbody)">

          <div class="flex flex-col">
            <p class="flex font-bold">Selected Email</p>
          </div>
          <div class="flex flex-wrap px-2 bg-gray-100">
            <p class="flex py-2">{{selectedContactMessage.email}}</p>
          </div>

          <div>
            <div class=" px-2 mt-2 w-full">
              <div>
                <label for=" " class="text-sm font-bold mb-2 "> Subject: </label>
                <input type="email" formControlName='subject'
                  class="form-control mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                  placeholder="Subject" />
                <br />
              </div>
              <label for=" " class="text-sm font-bold mb-2 "> Message: </label>
              <app-editor [control]="emailbody" class="bg-white" required [fromComponent]="'message'"></app-editor>
            </div>


            <div class="flex justify-between mt-2">
              <button type="button" (click)="closeSendMessageModal()"
                class="btn bg-white hover:bg-gray-400 hover:text-white text-black border-none">Cancel</button>
              <button
                class="p-2 text-white bg-customTeal hover:bg-gray-100 hover:text-customTeal border-none rounded-md"
                type="submit">Send Message</button>
            </div>
          </div>

        </form>
      </div>
    </div>
  </div> 

  <!-- toast -->
<app-alert class="fixed top-0 flex justify-center w-full" [toastType]="toastType" [toastMessage]="toastMessage"
[ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>
