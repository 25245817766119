import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class FormsService {

  formListRef: AngularFirestoreCollection<any>;
  formRef: AngularFirestoreDocument<any>;
  

  constructor(private db: AngularFirestore) { }


  newForm(model: any)  {
    model.id  = this.db.createId();
    return this.db.collection('Forms').doc(model.id).set(model);
  }

  updateForm(model: any)  {
    return this.db.collection('Forms').doc(model.id).update(model);
  }

  getFormByID(id: string): AngularFirestoreDocument<any> {
    this.formRef = this.db.doc('Forms/' + id);
    return this.formRef;
  }

  // getClientByPhoneNumber(cellPhone: any){
  //   return this.db.collection('Forms/', ref => ref.where('cellPhone', '==', cellPhone));
  // }
  getClientByEmail(email: any){
    return this.db.collection('Forms/', ref => ref.where('Email', '==', email));
  }

  getFormList(): AngularFirestoreCollection<any[]> {
    this.formListRef = this.db.collection('Forms');
    return this.formListRef;
  }

  deleteForm(model){
    return this.db.collection('Forms').doc(model.id).delete();

  }
}
