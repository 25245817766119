import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { AuthService } from 'src/app/services/firebase/auth.service';
import firebase from 'firebase/app';
import 'firebase/firestore';
@Injectable({
  providedIn: 'root'
})
export class NewsEventService {
  constructor(private db: AngularFirestore, 
    private authService: AuthService){ }

  createNews(model: any){
    const newContent = {
      id: this.db.createId(),
      createdById: model.createdById,
      newsTitle: model.newsTitle,
      titleColor: model.titleColor,
      description: model.description,
      backgroundColor: model.backgroundColor,
      imgLink: model.imgLink,
      videoLink: model.videoLink,
      sharedLink: model.sharedLink,
      shareToFriends: model.shareToFriends,
      // shareWith: model.shareWith,
      areas: model.areas,
      archived: false,
      timeStamp: new Date(),
      commentable: model.commentable,
      reactions: model.reactions,
      comments: model.comments
    };
    return this.db.collection('News').doc(newContent.id).set(newContent);
  }

  async createEvent(model: any){
    const newContent = {
      id: this.db.createId(),
      createdById: model.createdById,
      eventTitle: model.eventTitle,
      titleColor: model.titleColor,
      eventPlace: model.eventPlace,
      eventStartDate: model.eventStartDate,
      eventEndDate: model.eventEndDate,
      eventStartTime: model.eventStartTime,
      eventEndTime: model.eventEndTime,
      description: model.description,
      backgroundColor: model.backgroundColor,
      isOnline: model.isOnline,
      imgLink: model.imgLink,
      videoLink: model.videoLink,
      sharedLink: model.sharedLink,
      shareToFriends: model.shareToFriends,
      shareWith: model.shareWith,
      archived: false,
      areas: model.areas,
      timeStamp: new Date(),
      price: model.price,
      users:model.users,
      commentable: model.commentable,
      reactions: model.reactions,
      comments: model.comments,
      registerRequired: model.registerRequired,
      maxRegistration: model.maxRegistration,
      opened : model.opened ? model.opened : [],
      latitude: model.latitude ? model.latitude : '',
      longitude: model.longitude ? model.longitude : '',
      eventLink : model.eventList ? model.eventList : '',
      invitedSubs : model.invitedSubs ? model.invitedSubs : '',
    };
    await this.db.collection('Events').doc(newContent.id).set(newContent);
    return newContent.id;
  }

  updateReactionOfAnEvent(event){
    return this.db.collection('Events').doc(event.id).update({reactions: event.reactions});
  }
  updateComments(event){
    return this.db.collection('Events').doc(event.id).update({comments: event.comments});
  }
  registerUserEvent(model){
    console.log("Got here", model)
    const registered = {
      id: model.id,
      uid: model.uid,
    }
    return this.db.collection('UserRegisteredEvent').doc(registered.id).set(registered);
  }
  updateRegisteredUserEvent(model){
    const registered = {
      id: model.id,
      uid: model.uid,
    }
    return this.db.collection('UserRegisteredEvent').doc(registered.id).update(registered)
  }
  getAllRegisteredUsers(){
    return this.db.collection('UserRegisteredEvent');
  }
  getAllRegisteredUserByEventId(eventId){
    return this.db.collection('UserRegisteredEvent', ref => ref.where('id', '==', eventId));
  }
  // deleteRegisteredUser(eventId,userId){
  //   console.log("the sent event and user", eventId,"user", userId)
  //   const event = this.db.collection('UserRegisteredEvent', ref =>
  //     ref.where('id', '==', eventId).where('uid', '==', userId));
  //   console.log("the events id",eventId, "the users id", userId, 'the return', event);


  //   event.delete().then(() => {
  //     console.log("Document successfully deleted!");
  //   }).catch((error) => {
  //     console.error("Error removing document: ", error);
  //   });
  //   return event;
  // }


  deleteRegisteredUser(eventId: string, userId: string) {
    const eventDoc = this.db.collection('UserRegisteredEvent').doc(eventId);
    eventDoc.update({
      //uid: firebase.firestore.FieldValue.arrayRemove(userId)
    }).then(() => {
      console.log("userId successfully removed from the uid array field!");
    }).catch((error) => {
      console.error("Error removing userId from the uid array field: ", error);
    });
  }
  editNews(news){
    return this.db.collection('News').doc(news.id).update(news);
  }

  editEvent(event){
    console.log("UPDATED",event)
    return this.db.collection('Events').doc(event.id).update(event);
  }

  getNewsById(newsId: any){
    return this.db.collection('News', ref => ref.where('id', '==', newsId));
  }

  getEventById(eventId: any){
    return this.db.collection('Events', ref => ref.where('id', '==', eventId));
  }

  getUserByEvent(userId: any){

  }

  getRecentNews(){
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    return this.db.collection('News', ref => ref.where('timeStamp', '>=', yesterday));
  }

  getRecentEvents(){
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    return this.db.collection('Events', ref => ref.where('timeStamp', '>=', yesterday));
  }

  getAllEvents(){
    return this.db.collection('Events');
  }

  getEventsByShareType(type){
    return this.db.collection('Events', ref => ref.where('shareWith', '==', type));
  }

  getUserEvents(){
    return this.db.collection('Events', ref => ref.where('shareWith', 'in', ['Public','User']));
  }
  

  getAllNews(){
    return this.db.collection('News');
  }
  deleteNews(newsModel: any){
    return this.db.collection('News').doc(newsModel.id).delete();
  }
  deleteEvent(eventModel: any){
    return this.db.collection('Events').doc(eventModel.id).delete();
  }

  getLimitedNewsList(limit: number): AngularFirestoreCollection<any[]> {
    return this.db.collection('News', ref => ref.where('shareWith', '==', 'Public').limit(limit));
    // return this.db.collection('News', ref => ref.where('shareWith', '==', 'Public').orderBy('timeStamp', 'desc').limit(limit));

  }

  getPublicEventsList(): AngularFirestoreCollection<any[]> {
    return this.db.collection('Events', ref => ref.where('shareWith', '==', 'Public'));
  }

  getPublicNewsList(): AngularFirestoreCollection<any[]> {
    return this.db.collection('News', ref => ref.where('shareToFriends', '==', false).limit(20));
  }

  saveRecords(records: any[], collectionName: string): Promise<void> {
    const batch = this.db.firestore.batch();
    records.forEach(record => {
      const docRef = this.db.collection(collectionName).doc(record.id).ref;
      batch.set(docRef, record);
    });

    return batch.commit();
  }

  async getTimestamps(collectionName: string) {
    const snapshot = await this.db.collection(collectionName).get().toPromise();
    const dates = [];

    snapshot.forEach((doc: any) => {
      const data = doc.data();
      // Assuming your timestamp field is called 'timestamp'
      if (data && data.eventStartDate) {
        const timestamp = data.eventStartDate;
        // dates.push(timestamp.toDate());
        data.eventStartDate = timestamp.toDate()
        dates.push(data)
      }
    });

    return dates;
  }
}
