<div *ngIf="data" class="flex flex-col w-full">
    <div class="flex md:space-x-12 justify-center flex-col" [ngClass]="data.leftText ? 'md:flex-row': 'md:flex-row-reverse'">
        <div class="flex flex-col text-center items-center md:items-start md:text-left md:w-1/2 space-y-4 h-max  p-4 md:pl-4 " >
            <!-- title -->
            <!-- <p class="text-2xl  font-bold text-customTeal">{{data.sectionTitle}}</p> -->
            <span [innerHTML]="data.sectionTitle| noSanitize"></span>

            
            <!-- <p class="text-darkGray md:w-5/6  text-sm">
                {{data.sectionDescription}}
            </p> -->
            <span [innerHTML]="data.sectionDescription| noSanitize"></span>

            <ul class="pl-4 md:pl-12 text-left space-y-2 text-sm" style=" list-style-type: square; ">
                <li *ngFor="let mission of data.missionItems">{{mission}} </li>
            </ul>

        </div>
        <div class="flex justify-center rounded-lg  space-y-2 p-4" *ngIf="data.imgUrl">
            <img loading="lazy" [src]="data.imgUrl" class="object-cover w-96 border-8 rounded-md" alt="Logo">
        </div>
    </div>

</div>