<div class="flex justify-center h-screen" *ngIf="loading">
    <progress class="progress progress-customTeal w-full"></progress>
</div>

<div class="flex flex-col space-y-4" *ngIf="!loading">

    <div class="border-b-4 border-customTeal py-5">
        <div class="font-bold text-2xl text-left items-center">
            <span>Post Detail</span>
            <div class="tooltip" data-tip="Create a ppost to you social media">
                <button type="button"><i class="material-icons">help</i></button>
            </div>
        </div>

    </div>


    <ng-container *ngIf="userProfile">
        <div *ngIf="post">
            <div class="flex flex-col md:flex-row w-full bg-white p-6 md:p-12   md:space-x-10 space-y-4 md:space-y-0 ">
                <!-- image -->

                <img loading="lazy" *ngIf="post.mediaUrls && post.mediaUrls.length > 0 && imageMedia(post.mediaUrls[0])"
                    [src]="post.mediaUrls[0]" class="object-cover w-full md:w-1/3 h-96 rounded-md cover" alt="Image" />
                <video controls *ngIf="post.mediaUrls && post.mediaUrls.length > 0 && !imageMedia(post.mediaUrls[0])"
                    [src]="post.mediaUrls[0]" class="object-cover w-full md:w-1/3 h-96 rounded-md cover"
                    alt="Video"></video>


                <!-- Detail -->
                <div class="flex flex-col w-full md:w-2/3 space-y-12">

                    <div class="flex flex-col gap-3 space-y-2 md:space-y-0">
                        <p class=" text-justify">{{post.post}}</p>
                        <div class="text-sm text-darkGray">
                            {{post.scheduleDate ? post.scheduleDate.utc : post.createDate.utc}}

                            <div class="block md:inline-block indicator ml-0 py-3 md:py-0 md:ml-10 z-0 text-base">

                                <i class="fa fa-circle text-xl p-0 m-0"
                                    [ngClass]="{'text-red-600': post.status=='error', 'text-green-600': post.status=='success', 'text-yellow-600': post.status=='pending'}"></i>
                                <span class="ml-4">

                                    {{post.status}}
                                </span>

                            </div>
                        </div>
                    </div>
                    <!-- Medias posted on -->
                    <div class="flex space-x-3 ">

                        <ng-container *ngFor="let v of post.platforms">
                            <i *ngIf="v !== 'tiktok'"
                                [ngClass]="'text-xl fa ' + socialMediaIcons[v].text + ' ' + socialMediaIcons[v].color"></i>
                            <svg *ngIf="v == 'tiktok'" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24"
                                height="24" viewBox="0 0 50 50">
                                <path
                                    d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z">
                                </path>
                            </svg>
                        </ng-container>
                    </div>

                    <div class=" align-bottom" *ngIf="commentable() && getUserCommentablePlatforms().length > 0&&getCommentsPlatforms().length > 0 && post.status === 'success' ">
                        <button class="flex space-x-2 items-center hover:text-customTeal hover:text-base" (click)="commentModalOpen=true">
                            <i class="fa fa-comment fa-xl"></i>
                            <span class="font-bold text-sm hover:text-base">Comment</span>
                        </button>
                    </div>
                    <!-- other media -->
                    <div class="flex flex-wrap gap-4" *ngIf="post.mediaUrls && post.mediaUrls.length > 0">
                        <div *ngFor="let i of post.mediaUrls">
                            <img loading="lazy" *ngIf="imageMedia(i)" [src]="i"
                                class="object-cover w-24 h-24  md:w-32 md:h-32 rounded-md cover" alt="Image">
                            <video *ngIf="!imageMedia(i)" [src]="i"
                                class="object-cover w-24 h-24  md:w-32 md:h-32 rounded-md cover"></video>
                        </div>

                    </div>
                </div>
            </div>

            <div class="flex justify-end pt-5 text-xl">
                <button class="flex space-x-2 items-center hover:text-red-500 hover:text-base" (click)="deleteModalOpen=true">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd"
                            d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                            clip-rule="evenodd" />
                    </svg>
                    <span class="font-bold text-sm hover:text-base">Delete</span>
                </button>
            </div>

            <div *ngIf="platformAnalytics" class="pt-3 md:pt-5">

                <p class="font-bold pb-3">Analytics</p>

                <div class="grid grid-cols-1 md:grid-cols-2 gap-6 ">
                    <ng-container class="w-full lg:w-1/2" *ngFor="let item of platformAnalytics | keyvalue">
                        <div class="h-full grid grid-cols-12 gap-4"
                            *ngIf="getUserMediaPlatforms().includes(item.key) && item.value.analytics && objectKeys(item.value.analytics).length > 0">
                            <app-social-media-platform-detail class="col-span-12 h-full"
                                [data]="getPlatformData(item.key,item.value.analytics)">
                            </app-social-media-platform-detail>
                        </div>
                    </ng-container>

                </div>


            </div>
        </div>
        <app-alert class="fixed top-0 flex justify-end w-full" [toastType]="toastType" [toastMessage]="toastMessage" [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>

    </ng-container>

    <div id="commentModalOpen" *ngIf="commentModalOpen" class="modal"
        [ngClass]="{'modal-open': commentModalOpen, 'modal-action': !commentModalOpen }">
        <div class="modal-box  bg-white  w-11/12  md:w-9/12 max-w-5xl flex flex-col justify-center py-8 px-4 md:px-12 ">
            <div class="flex justify-end">
                <a (click)="commentModalOpen=false" class="cursor-pointer"> <i class="text-xl fa fa-times"></i></a>
              </div>

            <div class="hidden md:flex tabs flex flex-wrap gap-3 justify-evenly items-center px-3 py-3">
                <a (click)="selectPlatform(v)" *ngFor="let v of getCommentsPlatforms()"
                    class="tab hover:border-b-2 border-customTeal"> <i
                        [ngClass]="'text-xl fa mr-3 ' + socialMediaIcons[v].text + ' ' + socialMediaIcons[v].color"></i>
                    <span class="font-bold text-black text-base">{{v | titlecase}}</span></a>

            </div>
            <div class="block md:hidden pb-3">
                <select (change)="selectPlatform($event.target.value)" class="select select-bordered w-full max-w-xs">
                    <option disabled selected>{{selectedPlatform | titlecase}}</option>
                    <option *ngFor="let v of getCommentsPlatforms()" [value]="v"><i
                            [ngClass]="'text-xl fa mr-3 ' + socialMediaIcons[v].text + ' ' + socialMediaIcons[v].color"></i>
                        <span class="font-bold text-black text-base">{{v | titlecase}}</span>
                    </option>
                </select>
            </div>
            <div class="md:mx-10 my-5 md:my-8 px-8 py-4 flex flex-col gap-2 overflow-y-scroll h-4/5 border-2 rounded" *ngIf="selectedComments">
                <ng-container *ngIf="selectedComments.length > 0">
                    <div class="w-full border-2 rounded-xl p-4 flex flex-col gap-4 my-3"
                    [ngClass]="getBorder(selectedPlatform)"
                    *ngFor="let comment of selectedComments">

                    <div class="flex space-x-4 ">
                        <div class="avatar ">
                            <div class="w-14 rounded-full ">
                                <img loading="lazy" *ngIf= "selectedPlatform == 'twitter' && comment.profileImageUrl" [src]="comment.profileImageUrl" />
                                <img
                                loading="lazy"
                                
                                *ngIf= "selectedPlatform != 'twitter' || (selectedPlatform == 'twitter' && !comment.profileImageUrl)"
          
                                data-src="https://api.goingmobile.app/media/personal-app-assets/img/webp/emptyProfilePic.webp"
                                                
                                srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/emptyProfilePic.webp"
                                                
                                fallback="https://api.goingmobile.app/media/personal-app-assets/img/png/emptyProfilePic.png"
                                                
                                alt="ananymous profile picture."> 

                            </div>
                        </div>
                        <div class="flex flex-col space-y-1 ">
                            <p class="text-bold font-bold ">{{comment.userName | titlecase}}</p>
                            <p class="text-xs text-darkGray ">{{comment.created}}</p>
                        </div>
                    </div>
                        <div class="pt-3">
                            {{comment.comment}}
                        </div>
                        <div class="flex flex-row items-center " *ngIf= "selectedPlatform != 'twitter'">
                            <button>
                            <i class="font-bold fa fa-heart-o"  ></i> {{comment.likeCount}}
                            </button>
                        </div>
                    </div>

                </ng-container>
                <div *ngIf="selectedComments.length == 0">
                    no {{selectedPlatform}} comments yet
                </div>

            </div>
            <div class="md:mx-10 my-5 md:my-8" *ngIf="selectedPlatform !='youtube'">
                <form [formGroup]="postCommentForm" class=" w-full" (ngSubmit)="commentOnPost(postCommentForm.value)">

                    <div class="flex justify-between gap-x-3 ">
                        <input type="text" placeholder="Write a comment" formControlName='comment'
                            class="input input-bordered w-4/5" />
                        <button type="submit" [disabled]="!postCommentForm.valid"
                            class="py-2 px-8 modal-button gap-2 text-black bg-customTeal rounded-lg "
                            [ngClass]="{'bg-[#cccccc] text-[#666666] border-0': !postCommentForm.valid}">
                            Post
                        </button>
                    </div>

                </form>

            </div>


        </div>
    </div>

    <div id="deleteModalOpen" *ngIf="deleteModalOpen" class="modal"
        [ngClass]="{'modal-open': deleteModalOpen, 'modal-action': !deleteModalOpen }">
        <div class="modal-box  bg-white pt-6 pb-10 w-11/12 md:w-5/12 max-w-5xl px-0 ">
            <div class="modal-content ">
                <div class="pb-4 md:pb-6 " >
                  <h3 class="text-center font-bold text-xl text-black pt-6">Delete post
                  </h3>
                  <div class="divider px-0"></div>
    
                  <div class="text-base text-[#636363] text-center px-4 md:px-6">
                      Are you sure you want to delete yout post
                      <div>
                        <small *ngIf="post.platforms.includes('instagram')"
                      class="text-red-500 italic text-xs font-bold">Note: Instagram posts must be removed from the app. .
                    </small>
                      </div>
                  </div>
                </div>
    
                <div class="flex justify-evenly pt-6">
                  <button (click)="deleteModalOpen=false" class="font-bold py-3 px-5 border-none rounded-md bg-customTeal text-white">Cancel</button>
                  <button (click)="deletePost()" class="font-bold py-3 text-red-500 px-5 border-none rounded-md">Delete post</button>
    
                </div>
    
    
            </div>
        </div>

        

    </div>

</div>