<!-- Share Modal -->
<div  >
    <div class="modal modal-open"  id="shareModal">
      <div class="modal-box">
        <h3 class="font-bold text-lg text-center mb-2">Send push notfication</h3>
        <p class="py-4"> Would you like to send push notification?</p>
    
        <div class="modal-action items-center justify-between">
          <label>
            <button class="font-bold text-red-500 hover:bg-red-500 hover:text-white hover:rounded-md hover:font-normal border-none hover:p-2"
            type="button" (click)="cancelActionClicked()" >No</button>
  
          </label>
          <button    type="button" (click)="sendPushNotfication()"
          class="py-2 px-4 bg-customTeal text-white border-customTeal rounded-md">Yes
        </button>
        </div>
      </div>
  </div>
  </div>
    