import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { LoadingComponent } from './loading/loading.component';
import { IsnStarsPipe } from 'src/app/Pipes/IsnStarsPipe';
import { SinStarsPipe } from 'src/app/Pipes/SinStarsPipe';
import { NameStarPipe } from 'src/app/Pipes/NameStarPipe';
import { FirebaseLoadingComponent } from './loading/firebase-loading/firebase-loading.component';
import { SearchMemberComponent } from './search-member/search-member.component';
import { DateAgoPipe } from 'src/app/Pipes/DateAgoPipe';
import { TruncateTextPipe } from 'src/app/Pipes/TruncateTextPipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { UserInboxComponent } from './user-inbox/user-inbox.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

import { SafeUrlPipe } from 'src/app/Pipes/SafeUrlPipe';
import {FilePreviewPipe} from 'src/app/Pipes/file-preview.pipe';
import { MessagingMainComponent } from './messaging-main/messaging-main.component';
import { SmokeSignalComponent } from './messaging-main/smoke-signal/smoke-signal.component';
import { ChatContextMenuComponent } from './messaging-main/chat-context-menu/chat-context-menu.component';
import { AdminCircleComponent } from './messaging-main/admin-circle/admin-circle.component';
import { MessagingMemberComponent } from './messaging-main/messaging-member/messaging-member.component';
import { PostsDisplayComponent } from './messaging-main/posts-display/posts-display.component';
import { ViewPostComponent } from './view-post/view-post.component';

import { ShortNumberPipe } from '../../Pipes/short-number.pipe';
import { FileUploadPreviewComponent } from './messaging-main/file-upload-preview/file-upload-preview.component';
import { NewNotificationBadgeComponent } from './new-notification-badge/new-notification-badge.component';
// import { ChartsModule } from 'ng2-charts';
// import { NgxSliderModule } from '@angular-slider/ngx-slider';
// import { GaugeChartModule } from 'angular-gauge-chart';
import { ViewProfileComponent } from './view-profile/view-profile.component';
import { ChatListComponent } from './chat-list/chat-list.component';
import { ChatComponent } from './chat/chat.component';
import { GroupChatsListComponent } from './group-chats-list/group-chats-list.component';
import { GroupChatComponent } from './group-chat/group-chat.component';
import { UpdateProfileComponent } from './update-profile/update-profile.component';
import { ViewEventsListComponent } from './events/view-events-list/view-events-list.component';
import { ViewEventComponent } from './events/view-event/view-event.component';
import { EventCalendarViewComponent } from './events/event-calendar-view/event-calendar-view.component';
import { CalendarHeaderComponent } from './calendar-header/calendar-header.component';
// import { GalleryModule } from 'ng-gallery';
import { PollsResultComponent } from './polls-result/polls-result.component';
import { PollsViewComponent } from './polls-view/polls-view.component';
import { SocialMediaDashBoardComponent } from './social-medial/dashboard/dashboard.component';
import { SocialMediaAnalysisComponent } from './social-medial/media-analysis/media-analysis.component';
import { SocialMediaPostComponent } from './social-medial/post/post.component';
import { SocialMediaProfilesComponent } from './social-medial/profiles/profiles.component';
import { SocialMediaSettingsComponent } from './social-medial/settings/settings.component';
import { SocialMediaAnalyticsComponent } from './social-medial/analytics/analytics.component';
import { SocialMediaCreatePostComponent } from './social-medial/post/create-post/create-post.component';
import { EmployeeDetailComponent } from './employee-detail/employee-detail.component';
import { ClientDetailComponent } from './client-detail/client-detail.component';
// import { AppointmentComponent } from './appointment/appointment.component';
import { AppointmentDetailComponent } from './appointment-detail/appointment-detail.component';
import { AlertComponent } from './alert/alert.component';
import { PlatformDetailsComponent } from './social-medial/media-analysis/platform-detail/platform-detail.component';
import { PlatformUserComponent } from './social-medial/media-analysis/platform-user/platform-user.component';
import { SocialMediaPostDetailComponent } from './social-medial/post/post-detail/post.detail.component';
import { LinkAccountComponent } from './social-medial/link-account/link-account.component';
import { SocialMediaPostPreviewComponent } from './social-medial/post/preview/preview.component';
import { ServiceProductRouteComponent } from './service-product-route/service-product-route.component';
import { ViewProductComponent } from './view-product/view-product.component';
import { NotificationComponent } from './notification/notification.component';
import { BlogListComponent } from './blog/blog-list/blog-list.component';
import { ViewBlogComponent } from './blog/view-blog/view-blog.component';
import { GetAppComponent } from './get-app/get-app.component';
import { ServicesListComponent } from './services/services-list/services-list.component';
import { ViewServiceComponent } from './services/view-service/view-service.component';
import { GetAppConfirmationComponent } from './get-app/get-app-confirmation/get-app-confirmation.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { AboutMainComponent } from './about-us/main/main.component';
import { AboutValuesComponent } from './about-us/values/values.component';
import { AboutMissionComponent } from './about-us/mission/mission.component';
import { CatagoryComponent } from './catagory/catagory.component';
import { CategoryProductsComponent } from './category-products/category-products.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { ServiceCategoryComponent } from './service-category/service-category.component';
import { HelpCenterComponent } from './help-center/help-center.component';
import { GuideDetailsComponent } from './help-center/guide-details/guide-details.component';
import { MessagsComponent } from './messaging/messages/messages.component';
import { ChatsListComponent } from './messaging/chats-list/chats-list.component';
import { ChatMessagingComponent } from './messaging/messaging.component';
import { PrivateMessagesComponent } from './messaging/private-messages/private-messages.component';
import { GroupMessagesComponent } from './messaging/group-messages/group-messages.component';
import { ChatInfoComponent } from './messaging/chat-info/chat-info.component';
import { CircleImgComponent } from './messaging/chat-info/img-icon/circle-img.component';
import { MessageItemComponent } from './messaging/messages/message-item/message-item.component';
import { EmployeeMessagesComponent } from './messaging/employee-messages/employee-messages.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NoSanitizePipe } from 'src/app/Pipes/NoSanitizePipe';
import { EditorComponent } from './editor/editor.component';

import { AnalyticsUserGraphComponent } from './analytics-user-graph/analytics-user-graph.component';
import { CloseModalComponent } from './close-modal/close-modal.component';
import { CancelConfirmationComponent } from './cancel-confirmation/cancel-confirmation.component';
import { DeleteConfirmationComponent } from './delete-confirmation/delete-confirmation.component';
import { ButtonComponent } from './components/button/button.component';
import { NoAccessComponent } from './no-access/no-access.component';
import { EditConfirmationComponent } from './edit-confirmation/edit-confirmation.component';
import { BlogImgTestComponent } from './blog-img-test/blog-img-test.component';
import { UploadImageComponent } from './upload-image/upload-image.component';
import { OnlineStatusComponent } from './online-status/online-status.component';
import { CreateConfirmationComponent } from './create-confirmation/create-confirmation.component';

import { CropImageComponent } from './crop-image/crop-image.component';
import { ClientFeedComponent } from './feed/client-feed/client-feed.component';
import { CreateFeedComponent } from './feed/create-feed/create-feed.component';
import { ImageUploaderComponent } from './components/image-uploader/image-uploader.component';
import { SocialMediaSharePostComponent } from './social-medial/post/share-post/share-post.component';
import { SocialMediaPlatformComponent } from './social-medial/post/platform/platform.component';
import { ImageEditorModule } from '@syncfusion/ej2-angular-image-editor';
import { FileUploadModalComponent } from './file-upload-modal/file-upload-modal.component';
import { ChatGptComponent } from './chat-gpt/chat-gpt.component';

import { SectionListComponent } from './custom-section/section-list/section-list.component';
import { ViewSectionComponent } from './custom-section/view-section/view-section.component';
import { ViewCustomListsComponent } from './landing-page/view-custom-lists/view-custom-lists.component';
import { LoaderComponent } from './components/loader/loader.component';
import { GetFirstNations } from './firstnations/get-firstnations/get-firstnations.component';
import { SendMessageComponent } from './send-message/send-message.component';
import { MessageSendComponent } from './message-send/message-send.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { FooterComponent } from '../navigation/footer/footer.component';

// toaster
import { ToastrModule } from 'ngx-toastr';
import { LoadingInterceptor } from '../../loading.interceptor';
import { WelcomePopupComponent } from './welcome-popup/welcome-popup.component';
import { HeroSectionComponent } from './hero-section/hero-section.component';
import { DownloadPwAButtonComponent } from '../common/download-pw-abutton/download-pw-abutton.component';
import { PwaPopupComponent } from './pwa-popup/pwa-popup.component';
import { WelcomeFirstNations } from './firstnations/welcome/welcome.component';
import { PushNotificationComponent } from './push-notification/push-notification.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { MultipleFileUploadComponent } from './multiple-file-upload/multiple-file-upload.component';
import { ModalComponent } from './modal/modal.component';
import { ShareStatusComponent } from './share-status/share-status.component';
import { SMSVerificationComponent } from './smsverification/smsverification.component';
import { EmptyStateComponent } from './empty-state/empty-state.component';
import { SendMessageFromUsersComponent } from '../user/send-message-from-users/send-message-from-users.component';
// import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { RenameSectionComponent } from './rename-section/rename-section.component';
import { PostCommentComponent } from './post-comment/post-comment.component';
import { MoveElementsComponent } from './move-elements/move-elements.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { BlogListMinimalComponent } from './blog/blog-list/blog-list-minimal/blog-list-minimal.component';
import { BlogListDefaultComponent } from './blog/blog-list/blog-list-default/blog-list-default.component';
import { FirstNationLeaderLists } from './firstnations/nation-leaders/nation-leaders-list.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { LocateMapComponent } from './locate-map/locate-map.component';

import { FormButtonComponent } from './components/form-button/form-button.component';
import { EventListMinimalComponent } from './events/view-events-list/event-list-minimal/event-list-minimal.component';
import { ProductListComponent } from './product/product-list/product-list.component';
import { ProductListMinimalComponent } from './product/product-list/product-list-minimal/product-list-minimal.component';
import { ProductListDefaultComponent } from './product/product-list/product-list-default/product-list-default.component';
import { ServiceListDefaultComponent } from './services/services-list/service-list-default/service-list-default.component';
import { ServiceListMinimalComponent } from './services/services-list/service-list-minimal/service-list-minimal.component';
import { AboutListMinimalComponent } from './about-us/about-list-minimal/about-list-minimal.component';
import { CustomListMinimalComponent } from './landing-page/view-custom-lists/custom-list-minimal/custom-list-minimal.component';
import { CustomListDefaultComponent } from './landing-page/view-custom-lists/custom-list-default/custom-list-default.component';
import { HomepageSectionNumberComponent } from './homepage-section-number/homepage-section-number.component';
import { BlogListDarkComponent } from './blog/blog-list/blog-list-dark/blog-list-dark.component';
import { EventListDarkComponent } from './events/view-events-list/event-list-dark/event-list-dark.component';
import { AboutListDarkComponent } from './about-us/about-list-dark/about-list-dark.component';
import { ProductListDarkComponent } from './product/product-list/product-list-dark/product-list-dark.component';
import { SliderComponent } from './slider/slider.component';
import { InviteSubscribersComponent } from './invite-subscribers/invite-subscribers.component';
import { UsersListComponent } from '../admin/users-list/users-list.component';
import { AdminModule } from '../admin/admin.module';
import { FaqListComponent } from './faq-list/faq-list.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImageGeneratorComponent } from './components/image-generator/image-generator.component';
import { PaginationComponent } from './pagination/pagination.component';
import { CardViewElementsComponent } from './landing-page/card-view-elements/card-view-elements.component';


@NgModule({ declarations: [
        LoadingComponent,
        IsnStarsPipe,
        SinStarsPipe,
        NameStarPipe,
        DateAgoPipe,
        TruncateTextPipe,
        NoSanitizePipe,
        FirebaseLoadingComponent,
        SearchMemberComponent,
        UserInboxComponent,
        SafeUrlPipe,
        FilePreviewPipe,
        MessagingMainComponent,
        SmokeSignalComponent,
        ChatContextMenuComponent,
        AdminCircleComponent,
        MessagingMemberComponent,
        PostsDisplayComponent,
        ShortNumberPipe,
        FileUploadPreviewComponent,
        NewNotificationBadgeComponent,
        ViewProfileComponent,
        ViewPostComponent,
        ChatListComponent,
        ChatComponent,
        GroupChatsListComponent,
        GroupChatComponent,
        UpdateProfileComponent,
        ViewEventsListComponent,
        ViewEventComponent,
        ViewBlogComponent,
        EventCalendarViewComponent,
        CalendarHeaderComponent,
        PollsResultComponent,
        PollsViewComponent,
        SocialMediaDashBoardComponent,
        SocialMediaAnalysisComponent,
        SocialMediaPostComponent,
        SocialMediaProfilesComponent,
        SocialMediaSettingsComponent,
        SocialMediaAnalyticsComponent,
        SocialMediaCreatePostComponent,
        SocialMediaPlatformComponent,
        EmployeeDetailComponent,
        ClientDetailComponent,
        // AppointmentComponent,
        AppointmentDetailComponent,
        AlertComponent,
        PlatformDetailsComponent,
        PlatformUserComponent,
        SocialMediaPostDetailComponent,
        LinkAccountComponent,
        SocialMediaPostPreviewComponent,
        ServiceProductRouteComponent,
        ViewProductComponent,
        NotificationComponent,
        BlogListComponent,
        GetAppComponent,
        ServicesListComponent,
        ViewServiceComponent,
        GetAppConfirmationComponent,
        EditProfileComponent,
        AboutValuesComponent,
        AboutMissionComponent,
        AboutMainComponent,
        CatagoryComponent,
        LandingPageComponent,
        CardViewElementsComponent,
        CategoryProductsComponent,
        ServiceCategoryComponent,
        HelpCenterComponent,
        GuideDetailsComponent,
        MessagsComponent,
        ChatsListComponent,
        ChatMessagingComponent,
        PrivateMessagesComponent,
        GroupMessagesComponent,
        EmployeeMessagesComponent,
        ChatInfoComponent,
        CircleImgComponent,
        MessageItemComponent,
        EditorComponent,
        GuideDetailsComponent,
        AnalyticsUserGraphComponent,
        CloseModalComponent,
        CancelConfirmationComponent,
        DeleteConfirmationComponent,
        ButtonComponent,
        NoAccessComponent,
        EditConfirmationComponent,
        BlogImgTestComponent,
        UploadImageComponent,
        OnlineStatusComponent,
        CreateConfirmationComponent,
        CropImageComponent,
        SocialMediaSharePostComponent,
        ImageUploaderComponent,
        ImageGeneratorComponent,
        ClientFeedComponent,
        CreateFeedComponent,
        FileUploadModalComponent,
        ChatGptComponent,
        SectionListComponent,
        ViewSectionComponent,
        ViewCustomListsComponent,
        LoaderComponent,
        GetFirstNations,
        SendMessageComponent,
        MessageSendComponent,
        ContactUsComponent,
        FooterComponent,
        WelcomePopupComponent,
        HeroSectionComponent,
        DownloadPwAButtonComponent,
        PwaPopupComponent,
        WelcomeFirstNations,
        PushNotificationComponent,
        FeedbackComponent,
        MultipleFileUploadComponent,
        ModalComponent,
        ShareStatusComponent,
        SMSVerificationComponent,
        EmptyStateComponent,
        SendMessageFromUsersComponent,
        RenameSectionComponent,
        PostCommentComponent,
        MoveElementsComponent,
        BlogListMinimalComponent,
        BlogListDefaultComponent,
        FirstNationLeaderLists,
        BreadcrumbsComponent,
        LocateMapComponent,
        FormButtonComponent,
        EventListMinimalComponent,
        ProductListComponent,
        ProductListMinimalComponent,
        ProductListDefaultComponent,
        ServiceListDefaultComponent,
        ServiceListMinimalComponent,
        AboutListMinimalComponent,
        CustomListMinimalComponent,
        CustomListDefaultComponent,
        HomepageSectionNumberComponent,
        BlogListDarkComponent,
        EventListDarkComponent,
        AboutListDarkComponent,
        ProductListDarkComponent,
        SliderComponent,
        InviteSubscribersComponent,
        FaqListComponent,
        PaginationComponent
        // UsersListComponent
    ],
    exports: [
        RenameSectionComponent,
        LoadingComponent,
        FirebaseLoadingComponent,
        DateAgoPipe,
        TruncateTextPipe,
        NameStarPipe,
        UserInboxComponent,
        SafeUrlPipe,
        FilePreviewPipe,
        NoSanitizePipe,
        MessagingMainComponent,
        SmokeSignalComponent,
        ChatContextMenuComponent,
        AdminCircleComponent,
        MessagingMemberComponent,
        PostsDisplayComponent,
        NewNotificationBadgeComponent,
        ChatListComponent,
        ChatComponent,
        ViewPostComponent,
        EventCalendarViewComponent,
        CalendarHeaderComponent,
        UpdateProfileComponent,
        SocialMediaDashBoardComponent,
        SocialMediaAnalysisComponent,
        SocialMediaPostComponent,
        SocialMediaProfilesComponent,
        SocialMediaSettingsComponent,
        SocialMediaAnalyticsComponent,
        SocialMediaCreatePostComponent,
        SocialMediaPlatformComponent,
        AlertComponent,
        PlatformDetailsComponent,
        PlatformUserComponent,
        SocialMediaPostDetailComponent,
        LinkAccountComponent,
        SocialMediaPostPreviewComponent,
        ServiceProductRouteComponent,
        ViewProductComponent,
        NotificationComponent,
        GetAppComponent,
        AboutValuesComponent,
        AboutMissionComponent,
        AboutMainComponent,
        CatagoryComponent,
        MessagsComponent,
        ChatsListComponent,
        ChatMessagingComponent,
        PrivateMessagesComponent,
        GroupMessagesComponent,
        EmployeeMessagesComponent,
        ChatInfoComponent,
        CircleImgComponent,
        MessageItemComponent,
        EditorComponent,
        AnalyticsUserGraphComponent,
        CloseModalComponent,
        CancelConfirmationComponent,
        DeleteConfirmationComponent,
        ButtonComponent,
        NoAccessComponent,
        LandingPageComponent,
        CardViewElementsComponent,
        ViewBlogComponent,
        ViewEventComponent,
        ViewServiceComponent,
        EditConfirmationComponent,
        OnlineStatusComponent,
        SectionListComponent,
        CreateConfirmationComponent,
        CropImageComponent,
        ClientFeedComponent,
        CreateFeedComponent,
        ImageUploaderComponent,
        ImageGeneratorComponent,
        SocialMediaSharePostComponent,
        FileUploadModalComponent,
        ViewSectionComponent,
        ChatGptComponent,
        ViewCustomListsComponent,
        LoaderComponent,
        GetFirstNations,
        SendMessageComponent,
        FooterComponent,
        WelcomePopupComponent,
        HeroSectionComponent,
        DownloadPwAButtonComponent,
        PwaPopupComponent,
        WelcomeFirstNations,
        PushNotificationComponent,
        FeedbackComponent,
        MultipleFileUploadComponent,
        BlogListComponent,
        ViewEventsListComponent,
        ServicesListComponent,
        ViewProductComponent,
        ShareStatusComponent,
        EmptyStateComponent,
        SMSVerificationComponent,
        // SettingsComponent,
        PostCommentComponent,
        MoveElementsComponent,
        BreadcrumbsComponent,
        FormButtonComponent,
        LocateMapComponent,
        HomepageSectionNumberComponent,
        SliderComponent,
        InviteSubscribersComponent,
        FooterComponent,
        FaqListComponent,
        PaginationComponent
    ], imports: [
        // AdminModule,
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        FormsModule,
        AngularEditorModule,
        // ChartsModule,
        // NgxSliderModule,
        ImageEditorModule,
        // Ng2SearchPipeModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
        // GaugeChartModule,
        // GalleryModule,
        ImageCropperModule,
        ToastrModule.forRoot(),
        DragDropModule], providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoadingInterceptor,
            multi: true,
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class SharedModule { }
