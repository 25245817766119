import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
@Pipe({ name: 'noSanitize' })
export class NoSanitizePipe implements PipeTransform {
   constructor(private DOMsanitizer: DomSanitizer) {
   }
   
   

   public transform(value: string, type: string): SafeHtml | SafeStyle  {
	return this.DOMsanitizer.bypassSecurityTrustHtml(value);

		// switch (type) {
			// case 'html':
			// case 'style':
			// 	return this.DOMsanitizer.bypassSecurityTrustStyle(value);
		// }
	// }
	// transform(html: string): SafeHtml {
	// 	return this.DOMsanitizer.bypassSecurityTrustHtml(html);
	 }
}
