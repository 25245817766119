import { Injectable } from '@angular/core';
import { AngularFirestoreCollection } from '@angular/fire/compat/firestore/collection/collection';
import { AngularFirestoreDocument } from '@angular/fire/compat/firestore/document/document';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { map } from 'rxjs/operators';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class FireMembersService {

  constructor(private db: AngularFirestore, private userService: UserService) { }

  membersListRef: AngularFirestoreCollection<any>;
  memberRef: AngularFirestoreDocument<any>;



  getMemberByID(id: string): AngularFirestoreDocument<any> {
    this.memberRef = this.db.doc('Users/' + id);
    return this.memberRef;
 
  }

  getMembersList(): AngularFirestoreCollection<any> {
    this.membersListRef = this.db.collection('Users');
    return this.membersListRef;
  }

  getMemberByUserId(userID: any){
    this.membersListRef = this.db.collection('Users', ref => ref.where('uid', '==', userID));
    return this.membersListRef;
  }
  getMemberByEmail(email: any){
    this.membersListRef = this.db.collection('Users', ref => ref.where('Email', '==', email));
    return this.membersListRef;
  }

  getAdminAccounts(){
    this.membersListRef = this.db.collection('Users', ref => ref.where('role', '==', 'Admin'));
    return this.membersListRef;
  }

  updateAccount(model: any) {
    return this.db.collection('Users').doc(model.uid).update(model);
  }

  updateProfile(model: any) {
    return this.db.collection('UserProfile').doc(model.uid).update(model);
  }

  createNewProfile(userID: string) {
    this.userService.getUserById(userID).valueChanges().subscribe(u => {
      if(!u) return;
      if(u.length > 0 ) {
        let user: any = u[0];
        const profile = {
          uid: this.db.createId(),
          userID : userID,
          GivenName: user.GivenName,
          MiddleName: user.MiddleName,
          LastName: user.LastName,
          cellPhone: user.cellPhone,
          Email: user.Email,
          imgLink : '',
        coverPhotoLink :  '',
        profileImages :  [],
        showContact :false,
        showEducation : false,
        showPlacesLived : false,
        showFamily :  false,
        showWorkplace : false,
        //added
        accountSetup: false,
        }
        return this.db.collection('UserProfile').doc(profile.uid).set(profile);
      }
    })

  }

  getProfileByuserID(userId: string){
    return this.db.collection('UserProfile', ref => ref.where('userID', '==', userId));
  }


}
