import { FormGroup, FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { Location } from '@angular/common';
import { NewsEventService } from './../../../../services/firebase/news-event.service';
import { Component, Input, OnInit } from '@angular/core';
import { AreasOfInterestService } from './../../../../services/firebase/areas-of-interest.service';
import { Router, ActivatedRoute } from '@angular/router';
import { isSameYear, isSameWeek, isSameMonth,  isToday } from 'date-fns';
import { PermissionService } from 'src/app/services/firebase/permission.service';
import { DepartmentService } from 'src/app/services/firebase/department.service';
import { ProductsServiceMenuService } from 'src/app/services/api/data.service';
import { HelperService } from 'src/app/services/helper/helper';


@Component({
  selector: 'app-view-events-list',
  templateUrl: './view-events-list.component.html',
  styleUrls: ['./view-events-list.component.css']
})
export class ViewEventsListComponent implements OnInit {
  @Input() calledFrom;

  showLoading = false;
  events: any;
  currentUser: any;
  isMobile = false;
  filteredEventsList: any = [];
  interestAreas: any = [];
  selectedInterestAreas: any = [];
  selectedInterestAreaIDs: any = [];
  departments = [];
  addInterestModalOpen = false;
  filterOpen = false;
  departmentFilter ='';
  eventTitleFilter: any;
  eventPlaceFilter: any;
  eventTimelineFilter = '';
  beforeTitle = [];
  beforePlace = [];
  beforeInterest = [];
  beforeTimeline = [];

  isAdmin = false;
  isUser = false;
  isEmployee = false;

  permission: any;
  perm: any;

  areaFilterClicked=false
  depFilterClicked=false
  timeFilterClicked=false
  titleFilterClicked=false
  placeFilterClicked=false
  eventsName = 'Events';

  permMember:any;
  permSocial:any;
  permPES:any;
  permSpecial:any;
  permAnalytics:any;

  emptyEvent = {
    img: 'https://images.unsplash.com/photo-1699469786384-3717a73da415?q=80&w=2013&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D', 
    date: new Date().toJSON().slice(0, 10),
    shareWith: 'Public',
    place: 'location',
    postTitle : 'Examples of Blogs From Every Industry, Purpose, & Readership',
    message: "When you hear the word “blog”, what do you think of? Maybe your mind goes to stories about travel, yoga, and exciting new restaurants to try. What if I told you that, although these thoughts may be valid, other terms and phrases should be coming to mind? These include conversions, a boost in revenue, calls to action, inbound marketing, and improving customer relationships. Blogs are powerful business tools. They improve conversion rate, foster relationships between your business and audience members and customers, boost revenue, promote brand awareness, increase your ranking on search engines, and positively impact your bottom line. In this guide, we’ll review the different types of blogs there are and examples of each. We've also added a 'Niche Industries' section, because there's a blog for everything out there, including your industry. These will give you a better understanding of the various ways your business can write and publish content to help you achieve the benefits we listed above … and more."
  }
  renameModalOpen= false;
  viewPastEvents = false;

  currentDate = new Date().toISOString().split('T')[0];

  
  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;

  invitedGlobal: any;

  constructor(private newsEventService: NewsEventService,
              private location: Location,
              private authService: AuthService,
              private router: Router,
              private permissionService: PermissionService,
              private areasOfInterestService: AreasOfInterestService,
              private departService: DepartmentService,
              private helperService: HelperService,
              private productsServiceMenuService: ProductsServiceMenuService,
    ) {
                if (typeof window['cordova'] !== 'undefined') {
                  this.isMobile = true;
                }
               }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.currentUser = this.authService.getGlobalUser();
    if (this.currentUser){
      if (this.currentUser.role === 'Admin'){
        this.isAdmin = true;
      }
      if (this.currentUser.role === 'User'){
        this.isUser = true;
      }
      if (this.currentUser.role === 'Employee'){
        this.isEmployee = true;
      }
      this.permissionService.getPermissionByEmployeeId(this.currentUser.uid).valueChanges().subscribe(permission => {
        if(permission && permission.length != 0){
          this.perm = permission[0];
          //this.permission = this.perm.permission[0];
          this.permMember = this.perm.permission[0]
          this.permPES = this.perm.permission[1]
          this.permAnalytics = this.perm.permission[2];
          this.permSocial = this.perm.permission[3];
          this.permSpecial = this.perm.permission[4];
        }
      });
      if (['Admin','Employee'].includes(this.currentUser.role)){
        this.departService.getDepartment().valueChanges().subscribe(a => {
          if (a) {
            this.departments = a;
          }
        });
      }
    }
    this.showLoading = true;
    this.getEvents();

    this.areasOfInterestService.getAreasOfInterest().valueChanges().subscribe((areas: any) => {
      if (areas.length > 0){
        this.interestAreas = areas;
      }
    });

    if (this.currentUser){
      this.permissionService.getPermissionByEmployeeId(this.currentUser.uid).valueChanges().subscribe(permission => {
        this.perm = permission[0];
        if (this.perm){
          this.permission = this.perm.permission[0];
        }
      });
    }

    // $('.modal').modal();
    this.productsServiceMenuService.eventsName.subscribe(p => {
      this.eventsName = p;
    });


  }


  renameSection(){
    this.renameModalOpen = false
  }

  getEvents(){
    if (this.currentUser){
      if (['Admin','Employee'].includes(this.currentUser.role)){
        this.newsEventService.getAllEvents().valueChanges().subscribe(events => {
          if (events.length > 0 ){
            this.events = events;
            this.filteredEventsList = this.getSortedList(events);;
            this.showLoading = false;
          }
        });
      }
      if (this.currentUser.role === 'User'){
        this.newsEventService.getUserEvents().valueChanges().subscribe(events => {
          if (events.length > 0 ){
            this.events = events;
            this.filteredEventsList = this.getSortedList(events);;
            this.showLoading = false;

            // this.invitedGlobal = this.newsEventService.getEventsByShareType('Invited').valueChanges().subscribe((events:any) => {
            //   if(events && events.length > 0){
            //     events.forEach((element) =>{
                  
            //       element.invitedSubs.forEach(elements => {
            //         console.log("Elements", elements, "CurrentUser", this.currentUser);
            //         if(this.currentUser.uid === elements.uid){
            //           this.filteredEventsList = [...this.filteredEventsList, element];                      
            //         }
            //         this.invitedGlobal.unsubscribe();
            //       });
                
            //     })    
            //   }
            // })

          }
        });
      
      }
    } else {
      this.newsEventService.getEventsByShareType('Public').valueChanges().subscribe(events => {
        if (events.length > 0 ){
          this.events = events;
          this.filteredEventsList = this.getSortedList(events);
          this.showLoading = false;
        }
      });

    }
    if(this.events){
      for(let i = 0; i < this.events.length; i++){
        this.newsEventService.getAllRegisteredUserByEventId(this.events.uid).valueChanges().subscribe((amount) => {
          
        })
      }
    }

  }
  navigate(events){
    if (this.currentUser){
      if (this.currentUser.role === 'Admin'){
        this.router.navigate(['/admin/event', events.id]);
      }
      if (this.currentUser.role === 'User'){
        this.router.navigate(['/user/event', events.id]);
      }
      if(this.currentUser.role === 'Employee'){
        this.router.navigate(['/employee/events',events.id]);
      }
    } else {
      this.router.navigate(['/view-event', events.id]);

    }


  }
  backClicked(){
    this.location.back();
  }
  getUrl(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);

    if (match && match[2].length === 11) {
      const youtubeUrl = 'https://www.youtube.com/embed/' + match[2];
      return youtubeUrl;
    } else {
        return url;
    }
  }
  filterByInterests(){
    // if (this.beforeInterest.length === 0){
    //   this.beforeInterest = [...this.filteredEventsList];
    // }
    // this.filteredEventsList = this.beforeInterest;
    // if (this.selectedInterestAreaIDs.length === 0){
    //   this.filteredEventsList = this.events;
    // }
    // this.events.filter(news => {
    //   if (news.areas){
    //     news.areas.forEach(area => {
    //       if (this.selectedInterestAreaIDs.includes(area)){
    //         if (this.filteredEventsList.indexOf(news) <= -1){
    //           this.filteredEventsList.push(news);
    //         }
    //       }
    //     });

    //   } else {
    //     return news;
    //   }

    // });


    if(this.selectedInterestAreas.length ===0 ){
      this.areaFilterClicked=false
      this.applyFilter()
      return
    }
    this.filteredEventsList = this.filteredEventsList.filter(post => {
      if (post.areas) {
        let found = post.areas.some(a =>this.selectedInterestAreaIDs.includes(a) )
        if( found )return post

      }
    });
  }
  removeInterest(interest){
    this.selectedInterestAreaIDs = this.selectedInterestAreaIDs.filter((val) => val !== interest.id );
    this.selectedInterestAreas = this.selectedInterestAreas.filter((val) => val !== interest );

    // this.filterByInterests();
    this.applyFilter()
  }
  addInterest(){
    this.addInterestModalOpen = true;
  }
  closeInterestModal(){
    this.addInterestModalOpen = false;
  }

  onChange(event, interest){
    if (interest === 'selectAll'){
      this.selectedInterestAreaIDs = [];
      this.selectedInterestAreas = [];

      if (event.target.checked){
      this.interestAreas.forEach(element => {
        this.selectedInterestAreaIDs.push(element.id);
        this.selectedInterestAreas.push(element);

        });
      } else {
        this.selectedInterestAreaIDs = [];
        this.selectedInterestAreas = [];

      }

    } else {
    if (this.selectedInterestAreaIDs.find(selected => selected ===  interest.id )) {
      const index = this.selectedInterestAreaIDs.indexOf(this.selectedInterestAreaIDs.find(selected => selected ===  interest.id));
      this.selectedInterestAreaIDs.splice(index, 1);
      this.selectedInterestAreas.splice(index, 1);

    } else {
      this.selectedInterestAreaIDs.push(interest.id);
      this.selectedInterestAreas.push(interest);
    }
    }
    // this.filterByInterests();
    this.areaFilterClicked=true
    this.applyFilter()
  }
  toggleFilter(){
    this.filterOpen = !this.filterOpen;
  }
  onEventTitleChange(){
    // if (this.beforeTitle.length === 0){
    //   this.beforeTitle = [...this.filteredEventsList];
    // }
    // this.filteredEventsList = this.beforeTitle;
    this.filteredEventsList = this.filteredEventsList.filter((event) => {
      return event.eventTitle.toLowerCase().includes(this.eventTitleFilter.toLowerCase());
    });
  }
  onTimeframeChange(){
    // if (this.beforeTimeline.length === 0){
    //   this.beforeTimeline = [...this.filteredEventsList];
    // }
    // this.filteredEventsList = this.beforeTimeline;

    if (this.eventTimelineFilter === 'allTime'){
      this.filteredEventsList = this.filteredEventsList;  //fix
    }

    if (this.eventTimelineFilter === 'thisYear'){
      this.filteredEventsList = this.filteredEventsList.filter((event) => {
        return isSameYear(new Date(event.eventStartDate), new Date());
      });
    }

    if (this.eventTimelineFilter === 'thisWeek'){
      this.filteredEventsList = this.filteredEventsList.filter((event) => {
        return isSameWeek(new Date(event.eventStartDate), new Date());
      });
    }

    if (this.eventTimelineFilter === 'thisMonth'){
      this.filteredEventsList = this.filteredEventsList.filter((event) => {
        return isSameMonth(new Date(event.eventStartDate), new Date());
      });
    }

    if (this.eventTimelineFilter === 'today'){
      this.filteredEventsList = this.filteredEventsList.filter((event) => {
        return isToday(new Date(event.eventStartDate));
      });
    }
  }

  onEventPlaceChange(){
    // if (this.beforePlace.length === 0){
    //   this.beforePlace = [...this.filteredEventsList];
    // }
    // this.filteredEventsList = this.beforePlace;
    this.filteredEventsList = this.filteredEventsList.filter((event) => {
      return event.eventPlace.toLowerCase().includes(this.eventPlaceFilter.toLowerCase());
    });

  }
  onDepartmentChange(){
    this.filteredEventsList = this.events.filter((post) =>{
      if (post.departments){
        return post.departments.includes(this.departmentFilter)
      }
      })


  }

  applyFilter(){
    this.filteredEventsList=this.events
    if (this.timeFilterClicked){
      this.onTimeframeChange()
    }
    if(this.depFilterClicked){
      this.onDepartmentChange()
    }

    if(this.areaFilterClicked){
      this.filterByInterests()
    }

    if(this.placeFilterClicked){
      this.onEventPlaceChange()
    }
    if(this.titleFilterClicked){
      this.onEventTitleChange()
    }
  }

  applyTimeFilter(){
    this.timeFilterClicked=true
    this.applyFilter()
  }
  applyDepFilter(){
    this.depFilterClicked=true
    this.applyFilter()
  }
  applyTitleFilter(){
    this.titleFilterClicked=true
    this.applyFilter()
  }
  applyPlaceFilter(){
    this.placeFilterClicked=true
    this.applyFilter()
  }

  clearFilters(){
    this.filteredEventsList = this.events;
    this.beforeTitle = [];
    this.beforePlace = [];
    this.beforeInterest = [];
    this.beforeTimeline = [];
    this.selectedInterestAreas = [];
    this.selectedInterestAreaIDs = [];
    this.eventTitleFilter = '';
    this.eventPlaceFilter = '';
    this.eventTimelineFilter = '';
    this.departmentFilter = '';
    this.depFilterClicked=false
    this.timeFilterClicked=false
    this.areaFilterClicked=false
    this.titleFilterClicked=false
    this.placeFilterClicked=false
  }

  truncateHTML(text: string, limit: any): string {
    return this.helperService.truncateHTML(text,limit);
  }
  getInnerText(htmlString){
    return this.helperService.getInnerText(htmlString);
  }
  getSortedList(unsortedElements: Array<any>){
    // Filter out past events
    const futureEvents = unsortedElements.filter(event => event.eventStartDate >= this.currentDate);
    // Sort the remaining events by date
    futureEvents.sort((a, b) => a.eventStartDate.localeCompare(b.eventStartDate));
    return futureEvents;
  }

  toggleEventsView(){
    this.viewPastEvents = !this.viewPastEvents;
    if(this.viewPastEvents){
      this.filteredEventsList = this.events.filter(event => event.eventStartDate < this.currentDate);
    } else {
      this.filteredEventsList = this.events.filter(event => event.eventStartDate >= this.currentDate);
    }
  }
  routeToCreateEvent(){
    if(this.isAdmin){
      this.router.navigate(['/admin/create-event']);
    }
    else if(this.isEmployee && this.permPES && this.permPES.createEvents){
      this.router.navigate(['/employee/create-event']);
    }
  }
  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
    }, 2000);
  }
  openConfirmationModal(){
    this.toast({html: 'Successfully  posted a blog!', classes: 'green', type: 'success'});
  }
}
