import { Injectable } from '@angular/core';
import { AngularFirestore} from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {

  constructor(private db: AngularFirestore){ }
  saveMessage(model: any){
    const newContent = {
      id: this.db.createId(),
      email: model.email,
      name: model.name,
      message: model.message,
      timeStamp: new Date()
    };
    return this.db.collection('ContactUs').doc(newContent.id).set(newContent);
  }
  getMessageById(id){
    return this.db.collection('ContactUs', ref => ref.where('id', '==', id));
  }
  getAllMessages(){
    return this.db.collection('ContactUs');
  }
  updateMessage(msg){
    return this.db.collection('ContactUs').doc(msg.id).update(msg);
  }
  deleteMessage(msg: any){
    return this.db.collection('ContactUs').doc(msg.id).delete();
  }
}
