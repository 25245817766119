<div class="font-body min-h-screen max-h-max pt-1 md:px-10">
    <div class="md:pl-10">

      <div class="flex space-x-2 md:space-x-4 py-4 items-center ">
        <div class="flex-row text-center text-md">
            <button (click)="onTimeframeChange()"
              class="font-bold border-2 border-customTeal cursor-pointer px-4 py-1 bg-white md:px-8 text-sm  rounded-md "
              [ngClass]="{'text-white bg-customTeal': isUpcomingClicked && appointmentTimelineFilter=='today', 'text-customTeal bg-white' : appointmentTimelineFilter!='today'}">Today</button>
          </div>
        <div class="flex-row text-center text-md">
            <button (click)="upcomingClicked()"
              class="font-bold border-2 border-customTeal cursor-pointer px-4 py-1  md:px-8 text-sm rounded-md "
              [ngClass]="{'text-white bg-customTeal': isUpcomingClicked && appointmentTimelineFilter=='upcomingApp', 'text-customTeal bg-white' : appointmentTimelineFilter!='upcomingApp'}">Upcoming</button>
          </div>
          <div class="flex-row text-center text-md">
            <button (click)="pastClicked()"
              class="font-bold border-2 border-customTeal cursor-pointer px-4 py-1  md:px-8 text-sm rounded-md"
              [ngClass]="{'text-white bg-customTeal': !isUpcomingClicked && appointmentTimelineFilter=='pastTime', 'text-customTeal bg-white' : appointmentTimelineFilter !='pastTime' }">Past</button>
          </div>
      </div>
    </div>
        <!-- <div class="flex justify-end">
            <button class=" btn hover:bg-customTeal hover:text-white hover:border-customTeal border-customTeal md:mr-16 bg-gray-100 text-customTeal hover:text-white"
                (click)="toggleFilter()"><i class="material-icons">tune</i>Filter</button>
        </div> -->
        <div *ngIf="filterOpen" class="p-2 mt-5">
            <div class="flex flex-wrap mt-5">
                <div class="w-full md:w-4/12 mb-3">
                    <div class="mx-2">
                        <select [(ngModel)]="appointmentTimelineFilter" class="select select-bordered w-full" (change)="onTimeframeChange()">
                            <option disabled selected value="">Select a timeframe</option>
                            <option value="allTime">All Time</option>
                            <option value="pastTime">Past Appointment</option>
                            <option value="upcomingApp">Upcoming Appointment</option>
                   
                   
                            <option value="today">Today</option>
                        </select>
                    </div>
                </div>
            </div>
            <!-- <div class="flex flex-row-reverse">
                <button class="btn bg-slate-300 text-black hover:bg-gray-50 " (click)="clearFilters()">Clear
                    Filters</button>
            </div> -->
        </div>

    <div class="flex flex-col  gap-4 md:gap-4 m-2 pl-4 md:max-w-7xl" *ngIf="allAppointments.length !== 0">
             <div class="flex  bg-white w-full justify-between items-center border border-solid border-gray-200 rounded-md px-8 py-4  "
            *ngFor="let appointment of allAppointments; let i = index">
            <!-- <div class="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-32 md:justify-between">
                <p class="font-bold text-lg">{{appointment.service}}</p>
                <div class="flex space-x-2 items-center" *ngIf="appointment.startTime.toDate() > date">
                    <div class="rounded-full bg-green-500 w-3 h-3" ></div>
                    <p class="text-sm">Upcoming</p>
                </div>
                <div class="flex space-x-2 items-center" *ngIf="appointment.startTime.toDate().toDateString() == date.toDateString()">
                  <div class="rounded-full bg-green-500 w-3 h-3" ></div>
                  <p class="text-sm">Today</p>
              </div>
            </div> -->
            <div class="flex space-x-2 w-1/4">
                <div class="flex flex-col">
                    <h5 class="text-base md:text-2xl font-bold  text-customTeal">{{appointment.startTime.toDate()  | date: 'dd'}}</h5>
                    <h5 class="text-xs md:text-base text-customTeal">{{appointment.startTime.toDate()  | date: 'MMM'}}</h5>
                </div>
                <!-- <p class="font-bold text-customTeal text-sm md:text-base ">{{appointment.startTime.toDate() | date:'medium'}}</p> -->
            </div>

            <div class="font-bold w-1/3">
                {{truncateHTML(appointment.serviceName, 40) }}
            </div>
           
            <div class="flex space-x-2 w-1/3">
                <div class="avatar">
                    <div class="w-6 rounded-full">
                        <img
                        loading="lazy"

                        data-src="https://api.goingmobile.app/media/personal-app-assets/img/webp/profile.webp"
                                                    
                        srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/profile.webp"
                                                    
                        fallback="https://api.goingmobile.app/media/personal-app-assets/img/jpg/profile.jpg"
                                                    
                        alt="ananymous profile."> 
                    </div>
                </div>
                <div *ngIf="EmpToAppointment[i]">
                  <p class="font-bold text-customTeal text-sm md:text-base " *ngIf="EmpToAppointment[i].employee">{{EmpToAppointment[i].employee.GivenName}} {{EmpToAppointment[i].employee.LastName}}</p>
                </div>
            </div>

            <!-- Cancel and reschedule -->
            <ng-container *ngIf="EmpToAppointment[i]">
              <div class="flex  space-x-5 md:space-x-10 justify-end pt-3">
                <button *ngIf="EmpToAppointment[i].options.canCancel && this.appointmentTimelineFilter=='upcomingApp'" (click)="openModalCancel(appointment)"
                    class="flex space-x-2 px-4 md:px-6 py-2 border bg-veryLightGray items-center text-sm justify-center md:text-base text-black rounded-lg w-min h-min text-md font-bold hover:bg-customTeal hover:text-white focus:outline-none  ">
                    <span>Cancel</span>
                </button>
                <button *ngIf="EmpToAppointment[i].options.canReschedule && this.appointmentTimelineFilter=='upcomingApp'" (click)="reschedule(appointment)"
                    class=" flex items-center space-x-2 justify-center px-4 md:px-6 py-2 bg-customTeal text-white rounded-lg w-max h-min text-sm md:text-base font-bold hover:bg-darkBlue focus:outline-none  ">
                    <span>reschedule</span>
                </button>
              </div>
            </ng-container>
            <ng-container *ngIf="!isUpcomingClicked">
                <button (click)="bookAgainClicked(appointment)"
                    class=" flex items-center space-x-2 justify-center px-4 md:px-6 py-2 bg-gray-200 text-gray-950 text-white rounded-lg w-max h-min text-sm md:text-base font-bold hover:bg-customTeal hover:text-white focus:outline-none  ">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                      </svg>
                      
                    <span>Rebook</span>
                </button>

            </ng-container>

        </div>
    </div>

    <div id="my-modal-users" *ngIf="openCancelModal" class="modal"
    [ngClass]="{'modal-open': openCancelModal, 'modal-action': !openCancelModal }">
    <div class="modal-box">
      <div class="modal-content">
        <h3 class="font-bold text-lg text-center">Cancel Appointments</h3>
        <hr />
        <p class="py-2 text-center">Are you sure you want to cancel this appointment?</p>
        <p class="text-center">Appointment who are cancelled won't be shown.</p>

        <div class="modal-action items-center justify-between mx-10">
          <button (click)="closeCancelModal()"
            class="text-red-400 font-bold border-none">No</button>
          <button (click)="cancelAppointment()"
            class="px-4 py-2 bg-customTeal text-white rounded-md pr-5 border-none" type="button">Yes</button>
        </div>
      </div>
    </div>
  </div>


    <div class="mt-5"  *ngIf="allAppointments.length == 0">
        <div class="flex items-center justify-center">
            <img  loading="lazy" data-src="../../../../assets/icons/Illustrations/Appointment-Illustration.png"
            class="block w-64 flex items-center justify-center">
        </div>

        <div class="flex justify-center items-center">
            <button (click) ="bookAppointment()"
                class=" flex justify-center items-center space-x-2  px-4 md:px-6 py-2 bg-customTeal text-white rounded-lg w-max h-min text-sm md:text-base font-bold hover:bg-darkBlue focus:outline-none  ">
                <span>Go to {{servicesName}}</span>
            </button> 
        </div>
        <div class="text-gray-400 text-sm text-center font-bold mt-2">You didn't make any appointments.</div>
        <div class="text-gray-400 text-sm text-center font-bold mt-2">Book an appointment for the {{servicesName}} provided by the app</div>
    <div>

</div>

<!-- toast -->
<app-alert class="z-50 fixed top-0 flex justify-end w-full" [toastType]="toastType" [toastMessage]="toastMessage"
  [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>

