import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-delete-confirmation',
  templateUrl: './delete-confirmation.component.html',
  styleUrls: ['./delete-confirmation.component.css']
})
export class DeleteConfirmationComponent implements OnInit {

  @Input() deletedFromComponent;
  @Input () deletedFromCategory;
  @Output() cancelDeleteClicked = new EventEmitter();
  @Output() deleteClicked = new EventEmitter();

  confirmDelete = false
  constructor() { }

  ngOnInit(): void {
  }

  cancelDeleteBtnClicked(){
    this.cancelDeleteClicked.emit();
  }
  deleteBtnClicked(){
    this.deleteClicked.emit();
  }
  

}
