import { Component, OnInit, Input } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounceIn } from 'ng-animate';
import {
    Toast,
    initTE,
  } from "tw-elements";


@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.css'], 
    // animations: [
    //     trigger('swing', [transition('* => *', useAnimation(bounceIn))])
    // ],
})

export class AlertComponent implements OnInit {

    constructor( ) {

    }

    @Input() toastMessage;
    @Input() toastType;

    ngOnInit() {
        initTE({ Toast });
    }



}
