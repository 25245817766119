<div class="modal modal-open " >
  <div class="modal-box  z-[2300] rounded-none max-w-full overflow-x-hidden ">
  <div class="flex flex-col gap-y-6">
    <div class=" w-full">
      <div class="flex justify-between py-4 " >
        <h5
        class="text-xl font-medium leading-normal text-neutral-800 dark:text-neutral-200"
        id="customizeCard">
        Homepage preview
        </h5>
        <button
        type="button"
        (click)="closePreviewModal()"
        >
          <svg class="w-6 h-6 " xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z"/></svg>
        </button>
      </div>
      <div class="flex space-x-4 py-2 justify-center mx-auto w-full">
        <div [ngClass]="{'bg-customTeal stroke-white': view == 'web'}" class="p-4 rounded-md">
          <svg (click)="changeViewType('web')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"  class="w-8 h-8 cursor-pointer " [ngClass]="{'stroke-white': view == 'web'}">
            <path stroke-linecap="round" stroke-linejoin="round" d="M9 17.25v1.007a3 3 0 0 1-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0 1 15 18.257V17.25m6-12V15a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 15V5.25m18 0A2.25 2.25 0 0 0 18.75 3H5.25A2.25 2.25 0 0 0 3 5.25m18 0V12a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 12V5.25" />
          </svg>
        </div>
        <div [ngClass]="{'bg-customTeal stroke-white': view == 'mobile'}" class="p-4 rounded-md">
          <svg (click)="changeViewType('mobile')"  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 cursor-pointer" [ngClass]="{'stroke-white': view == 'mobile'}">
            <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
          </svg>
        </div>
        </div>
    </div>
    <div class="w-full flex justify-center">
      <div class="scrollbar shadow-md rounded-sm border-2 border-solid  border-gray-200 bg-green-200"  [ngClass]="{'w-full': view == 'web', 'max-w-sm max-h-screen ': view == 'mobile' }">
        <!-- <app-header></app-header> -->
      <app-landing-page [preview]="true" [view]='view' ></app-landing-page>
        <app-footer></app-footer>
      </div>
    </div>
  </div>
   <div class="modal-action">
    <!-- if there is a button in form, it will close the modal -->
    <button
    type="button"
    class="inline-block rounded bg-white text-customTeal border-2 border-customTeal px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-customTealborder-customTeal transition duration-150 ease-in-out hover:border-customTeal-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-customTealborder-customTeal-600 focus:border-customTeal-600 focus:text-customTealborder-customTeal-600 focus:outline-none focus:ring-0 active:border-customTeal-700 active:text-customTealborder-customTeal-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
    (click)="closePreviewModal()"
    data-te-ripple-init
    data-te-ripple-color="light">
    Close Preview
    </button>
    
  </div>
    </div>
</div>