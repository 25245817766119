import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { PollsAndSurveyService } from 'src/app/services/firebase/polls-and-survey.service';

@Component({
  selector: 'app-polls-and-survey',
  templateUrl: './polls-and-survey.component.html',
  styleUrls: ['./polls-and-survey.component.css']
})
export class PollsAndSurveyComponent implements OnInit {
  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;
  buttonType: any;
  user: any;

  fontColor = environment.appTheme.fontColor;
  loggedUser: any;
  model: any;
  surveyForm: UntypedFormGroup;
  questionForm: UntypedFormGroup;
  questionArray: UntypedFormArray;
  answersArray: UntypedFormArray;
  // answers = [];
  questionNumber = 1;
  step1 = true;
  step2 = false;
  step3 = false;
  showMultiAnswer = false;
  //answerTypes: any = ['Multiple Choice', 'Open Ended Answer', 'Scale', 'Checkboxes', 'Dropdown'];
  answerTypes: any = [
    { 'type': 'Multiple Choice', 'helpText': 'Displays a list of answers the participant can choose from' },
    { 'type': 'Open Ended Answer', 'helpText': 'Allows participants to answer in open text format' },
    { 'type': 'Scale', 'helpText': 'Displays a scale from any range from 0 to 10' }];
  scales = ['2', '3', '4', '5', '6', '7', '8', '9', '10'];

  numberOfAnswers: any = ['2', '3', '4'];
  showSurveyResult = false;
  ShowScaleLimits = false;
  createPoll = false;
  

  constructor(private fb: UntypedFormBuilder,
    private location: Location,
    private surveyService: PollsAndSurveyService,
    private authService: AuthService,
      private appSettingsService: AppSettingsService
  ) {
    this.user = this.authService.getGlobalUser();
    // this.themeColor = this.appSettingsService.getAppThemeColor() ? this.appSettingsService.getAppThemeColor(): this.defaultThemeColor;
    // this.buttonType =  this.appSettingsService.getButtonType() ? this.appSettingsService.getButtonType(): 'rectangularButton';
   }

  get surveyName() { return this.surveyForm.get('surveyName'); }
  get startDate() { return this.surveyForm.get('startDate'); }
  get endDate() { return this.surveyForm.get('endDate'); }
  get showResult() { return this.surveyForm.get('showResult'); }
  get showResultTo() { return this.surveyForm.get('showResultTo'); }
  get questions() { return this.questionForm.get('questionItems') as UntypedFormArray; }
  getAnswers(index) {
    const questions = this.questionForm.get('questionItems') as UntypedFormArray;
    return questions.controls[index].get('answers') as UntypedFormArray;
  }
  getScales(index) {
    const questions = this.questionForm.get('questionItems') as UntypedFormArray;
    return questions.controls[index].get('scales');
  }

  ngOnInit() {
    this.loggedUser = this.authService.getGlobalUser();
   
    this.surveyForm = this.fb.group({
      type: ['', [Validators.required]],
      surveyName: ['', [Validators.required]],
      startDate: ['', [Validators.required]],
      endDate: ['', [Validators.required]],
      showResult: ['', [Validators.required]],
      showResultTo: ['']
    });

    this.surveyForm.get('showResult').valueChanges.subscribe(x => {
      if (x == 'yes') {
        this.surveyForm.get('showResultTo').setValidators([Validators.required]);
      } else {
        this.surveyForm.get('showResultTo').clearValidators();
      }
      this.surveyForm.get('showResultTo').updateValueAndValidity();
    });
    

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const endDate1 = new Date();
    endDate1.setDate(endDate1.getDate() + 30);

    setTimeout(() => {
      // ('#startDate').datepicker(
      //   {
      //     format: 'dd/mmm/yyyy',
      //     minDate: tomorrow,
      //     maxDate: endDate1,
      //     defaultDate: tomorrow,
      //     setDefaultDate: tomorrow,
      //     yearRange: 1,
      //     onSelect: (date => {
      //       const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      //       const selectedDate = new Date(date);
      //       const formattedDate = selectedDate.getDate() + '/' + months[selectedDate.getMonth()] + '/' + selectedDate.getFullYear();
      //       localStorage.setItem('surveyStartDate', date);
      //     })
      //   }
      // );

      const startDate2 = new Date();
      startDate2.setDate(startDate2.getDate() + 2);
      const endDate2 = new Date();
      endDate2.setDate(endDate2.getDate() + 120);

      // $('#endDate').datepicker(
      //   {
      //     format: 'dd/mmm/yyyy',
      //     minDate: startDate2,
      //     maxDate: endDate2,
      //     defaultDate: startDate2,
      //     setDefaultDate: startDate2,
      //     yearRange: 1,
      //     onSelect: (date => {
      //       const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      //       const selectedDate = new Date(date);
      //       const formattedDate = selectedDate.getDate() + '/' + months[selectedDate.getMonth()] + '/' + selectedDate.getFullYear();
      //       localStorage.setItem('surveyEndDate', date);
      //     })
      //   }
      // );
    
    }, 25);
  }


  typeChanges(value) {
    if (value === 'Poll') {
      this.createPoll = true;
      this.answerTypes = [{ 'type': 'Multiple Choice', 'helpText': 'Displays a list of answers the participant can choose from' }];
    } else {
      this.createPoll = false;
      this.answerTypes = [
        { 'type': 'Multiple Choice', 'helpText': 'Displays a list of answers the participant can choose from' },
        { 'type': 'Open Ended Answer', 'helpText': 'Allows participants to answer in open text format' },
        { 'type': 'Scale', 'helpText': 'Displays a scale from any range from 0 to 10' }];
    }
  }


  startDateChange() {
    if (this.surveyForm) { this.surveyForm.patchValue({ startDate: $('#startDate').val() }); }
  }

  endDateChange() {
    if (this.surveyForm) { this.surveyForm.patchValue({ endDate: $('#endDate').val() }); }
  }

  onShowResultNo() {
    this.showSurveyResult = false;
    this.showResultTo.reset();
  }

  onShowResultYes() {
    this.showSurveyResult = true;
    setTimeout(() => {
    
    }, 25);
  }

  submitSurveyInfo(value: any) {

    this.model = {
      id: '',
      name: value.surveyName,
      type: value.type,
      numberOfQuestions: 0,
      startDate: value.startDate,
      endDate: value.endDate,
      showResult: value.showResult,
      showResultTo: value.showResultTo,
      questions: [],
      adminID: this.loggedUser.uid,
      adminName: this.loggedUser.GivenName + " " + this.loggedUser.LastName,
      datePosted: new Date(),
    };

    this.questionForm = this.fb.group({
      questionItems: this.fb.array([this.createQuestion()])
    });

    this.step1 = false;
    this.step2 = true;


  }

  // get startDate() { return this.surveyForm.get('startDate'); }
  // get endDate() { return this.surveyForm.get('endDate'); }

  createQuestion() {
    let questionFormGroup = this.fb.group({
      question: ['', [Validators.required]],
      answerType: ['', [Validators.required]],
      allowMultipleAnswers: [false, [Validators.required]],
      //numberOfAnswers: [''],
      answers: this.fb.array([]),
      scales: this.fb.group({
        start: [''],
        startLabel: [],
        end: [''],
        endLabel: []
      }),
      // answer1 : [''],
      // answer2 : [''],
      // answer3 : [''],
      // answer4 : [''],
    });

    if(this.createPoll) {
      questionFormGroup.get('answerType').patchValue('Multiple Choice');
      const answers = questionFormGroup.get('answers') as UntypedFormArray;
      if (answers.length < 2) {
        answers.push(this.addAnswers());
        answers.push(this.addAnswers());
      }
    }
    return questionFormGroup
  }


  answerTypeChange(e: any, index: any) {
    this.resetControls(index);
    const answers = this.getAnswers(index);
    if (e === 'Multiple Choice') {
      if (answers.length < 2) {
        answers.push(this.addAnswers());
        answers.push(this.addAnswers());
      }
    } else if (e === 'Scale') {
      this.getScales(index).get('start').patchValue('1');
      this.getScales(index).get('end').patchValue('10');
    } else {
      // this.getScales(index).reset();

      //answers.setValue([]);
      // answers.clearValidators();
      // answers.updateValueAndValidity();
    }

  }

  resetControls(index) {
    const answers = this.getAnswers(index);
    while (answers.length !== 0) {
      answers.removeAt(0);
    }
    const scales = this.getScales(index);
    //answers.reset();
    answers.clearValidators();
    answers.updateValueAndValidity();
    scales.reset();
    scales.clearValidators();
    scales.updateValueAndValidity();
  }


  addQuestion(): void {
    this.questionNumber += 1;
    this.questionArray = this.questionForm.get('questionItems') as UntypedFormArray;
    this.questionArray.push(this.createQuestion());
    setTimeout(() => {
      
    }, 25);
  }

  removeFile(index: any) {
    this.questionArray = this.questionForm.get('questionItems') as UntypedFormArray;
    this.questionArray.removeAt(index);
  }

  addAnswers() {
    return this.fb.group({
      answer: ['', [Validators.required]]
    });
  }

  onAddAnswer(index) {
    const answers = this.getAnswers(index);
    answers.push(this.addAnswers());
  }

  removeAnswer(question_index, answer_index) {
    const answers = this.getAnswers(question_index);
    answers.removeAt(answer_index);
  }

  submitQuestions(value: any) {
    console.log("It is inside the submit questions", value);
    let qNumber = 0;

    value.questionItems.forEach(element => {
      qNumber += 1;
      const question = {
        questionNumber: qNumber,
        question: element.question,
        answerType: element.answerType,
        allowMultipleAnswers: element.allowMultipleAnswers,
        answers: element.answers,
        scales: element.scales
      };
      if (element.answerType === 'Scale') {
        for (var i = element.scales.start; i <= element.scales.end; i++) {
          element.answers.push({ 'answer': i.toString() });
        }
      }
      this.model.questions.push(question);
    });

    this.model.numberOfQuestions = this.model.questions.length;

    this.surveyService.newSurvey(this.model).then(x => {
      // toast({ html: 'New Survey Successfully Created!', classes: 'green' });
      this.step2 = false;
      this.location.back();
    });
    this.surveyService.newPoll(this.model).then(x => {
      this.step2 = false;
      this.location.back();
    });
  }

  backClicked() {
    this.location.back();
  }

}
