<div
  class="border border-customTeal-300 shadow rounded-md p-4"
  *ngIf="type === 'pulse'"
  [ngClass]="customCss"
>
  <div class="animate-pulse flex space-x-4">
    <div class="rounded-full bg-slate-700 h-10 w-10"></div>
    <div class="flex-1 space-y-6 py-1">
      <div class="h-2 bg-slate-700 rounded"></div>
      <div class="space-y-3pulse">
        <div class="grid grid-cols-3 gap-4">
          <div class="h-2 bg-slate-700 rounded col-span-2"></div>
          <div class="h-2 bg-slate-700 rounded col-span-1"></div>
        </div>
        <div class="h-2 bg-slate-700 rounded"></div>
      </div>
    </div>
  </div>
</div>
<!-- <svg class="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24" *ngIf="config && config.type == 'spinner'">
</svg> -->

<svg
  *ngIf="type === 'spinner'"
  [ngClass]="customCss"
  class="animate-spin border-4 rounded-full border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
  viewBox="0 0 24 24"
></svg>
