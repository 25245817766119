import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { EmployeeServicesService } from 'src/app/services/firebase/employee-services.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ImageModel } from 'src/app/models/ImageModel';
import { MediaService } from 'src/app/services/api/media.service';
import { ProductsServiceMenuService } from 'src/app/services/api/data.service';
import { PermissionService } from 'src/app/services/firebase/permission.service';
import { HelperService } from 'src/app/services/helper/helper';

// declare var navigator: any;

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {

  currentUser: any;
  services: any;
  settings: any;
  viewService = true;
  serviceCategoriesModel: any = [];
  selectedModel: any;
  serviceCategoryForm: UntypedFormGroup;
  showMsg = false;
  currentUserId: any;
  selectedServicesUnderCategory: any;
  editModalOpen = false;
  deleteModalOpen = false;
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  filterOpen = false;

  serviceCategoryFilter = '';
  existingServices = [];
  beforeFilter = [];

  // images
  imgErrorMsg: any;
  base64Image: any;
  binDoc: File;
  oldUrl: any;
  mobileBase64Image: any;

  isMobile = false;
  categoryId;
  servicesName;
  catagoryName;

  // permission
  perm: any;
  permission: any;
  isAdmin: boolean;
  currency: any;
  cropModalOpen = false;
  imageChangedEvent: any = '';

  permMember:any;
  permSocial:any;
  permPES:any;
  permSpecial:any;
  permAnalytics:any;
  appSetting: any;
  hasServicesCategory = false; 
  emptyService = {
    img: 'https://images.unsplash.com/photo-1699469786384-3717a73da415?q=80&w=2013&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D', 
    date: new Date().toJSON().slice(0, 10),
    shareWith: 'Public',
    price: '122',
    postTitle : 'Examples of Blogs From Every Industry, Purpose, & Readership',
    message: "When you hear the word “blog”, what do you think of? Maybe your mind goes to stories about travel, yoga, and exciting new restaurants to try. What if I told you that, although these thoughts may be valid, other terms and phrases should be coming to mind? These include conversions, a boost in revenue, calls to action, inbound marketing, and improving customer relationships. Blogs are powerful business tools. They improve conversion rate, foster relationships between your business and audience members and customers, boost revenue, promote brand awareness, increase your ranking on search engines, and positively impact your bottom line. In this guide, we’ll review the different types of blogs there are and examples of each. We've also added a 'Niche Industries' section, because there's a blog for everything out there, including your industry. These will give you a better understanding of the various ways your business can write and publish content to help you achieve the benefits we listed above … and more."
  }
  openMoveModal = false;


  constructor(private employeeService: EmployeeServicesService,
              private authService: AuthService,
              private router: Router,
              private location: Location,
              private formBuilder: UntypedFormBuilder,
              private appSettingsService: AppSettingsService,
              private mediaService: MediaService,
              private route: ActivatedRoute,
              private productsServiceMenuService: ProductsServiceMenuService,
              private permissionService: PermissionService,
              private helperService: HelperService) {
                if (typeof window['cordova'] !== 'undefined') {
                  this.isMobile = true;
                }
              }

  ngOnInit(): void {
    this.currentUser = this.authService.getGlobalUser();
    if (this.currentUser){
      if (this.currentUser.role === 'Admin'){
        this.isAdmin = true;
      }
      if(this.currentUser.role == 'Employee'){
      this.permissionService.getPermissionByEmployeeId(this.currentUser.uid).valueChanges().subscribe(perm => {
        if (perm && perm.length !== 0){
          this.perm = perm[0];
          //this.permission = this.perm.permission[0];

           this.permMember = this.perm.permission[0]
           this.permPES = this.perm.permission[1]
           this.permAnalytics = this.perm.permission[2];
           this.permSocial = this.perm.permission[3];
           this.permSpecial = this.perm.permission[4];
        }
      });
    }
  }
  this.appSettingsService.getAppSettingsList().valueChanges().subscribe(appSettings => {
    if (appSettings.length > 0 ){
      this.appSetting = appSettings[0];
      this.hasServicesCategory = this.appSetting.hasServicesCategory ? this.appSetting.hasServicesCategory : false;
    }
  });

    this.route.paramMap.subscribe(params => {
      this.categoryId = params.get('id');
    });
    if (this.categoryId){
      this.employeeService.getServiceByCategoryId(this.categoryId).valueChanges().subscribe((services) => {
        if (services.length > 0){
          this.services = services;
          if (this.currentUser == null) {
            this.services = this.services.filter(p => p.shareWithPublic === true);
        }

        }
      });
    } else {
        this.employeeService.getAllServices().valueChanges().subscribe((services) => {
          if (services.length > 0 ){
            this.services = services;
            this.existingServices = services;
          }
        });
    }

    this.serviceCategoryForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      imgUrl: [''],
    });

    this.employeeService.getServicesCategoryList().valueChanges().subscribe(a => {
      if (a) {
        if (a.length > 0){
          this.serviceCategoriesModel = a;
        }
        this.showMsg = true;
      }
    });

    this.appSettingsService.getAppSettingsList().valueChanges().subscribe((settings) => {
      if (settings.length > 0){
        this.settings = settings[0];
      }
    });

    this.productsServiceMenuService.servicesName.subscribe(p => {
      this.servicesName = p;
    });
    this.productsServiceMenuService.servicesCategoryName.subscribe(p => {
      this.catagoryName = p;
    });
    this.productsServiceMenuService.currencyName.subscribe(c => {
      this.currency = c;
    });
  }

  deleteService(service){
    this.employeeService.deleteService(service).then(() => {
      // toast({html: 'Service Deleted successfully ', classes: 'green'});
    }).catch(err => {
      // toast({html: err, classes: 'green'});
    });
    this.backClicked();
  }

  backClicked(){
    this.location.back();
  }

  navigate(service: any){
    this.router.navigate(['/admin/edit-service', service.id]);
  }
  navigateDetails(service: any){
    if (this.isAdmin){
      this.router.navigate(['/admin/service', service.id]);
    }
    else if (this.currentUser.role === 'Employee'){
      this.router.navigate(['/employee/service', service.id]);
    }
    else if (this.currentUser.role === 'User'){
      this.router.navigate(['/user/service', service.id]);
    }
  }
  navigateCreate(){
    if (this.isAdmin){
     this.categoryId ?  this.router.navigate(['/admin/create-service', this.categoryId]) : this.router.navigate(['/admin/create-service']);
    }
    else if (this.currentUser.role === 'Employee'){
      this.router.navigate(['/employee/create-service']);
    }
  }
  toggleServiceCategoryBtn(){
    this.viewService = !this.viewService;
  }

  get name() { return this.serviceCategoryForm.get('name'); }

  addCategory(){
    this.selectedModel = undefined;
    this.serviceCategoryForm.patchValue({name: ''});
    this.editModalOpen = true;
    setTimeout(() => {
      // $('.modal').modal();
      // $('#editModal').modal('open');
    }, 25);
  }
  handleMobilePhotoInput() {
    // navigator.camera.getPicture((image: any) => {
    //   const imageName = 'IMG.jpeg';
    //   const imageBlob = this.dataURItoBlob(image);
    //   const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });
    //   this.binDoc = imageFile;
    //   this.base64Image = 'data:image/jpeg;base64,' + image;
    //   this.mobileBase64Image = image;

    // }, (error) => {
    //   alert(error);
    // },
    //   {
    //     quality: 50,
    //     destinationType: navigator.camera.DestinationType.DATA_URL,
    //     encodingType: navigator.camera.EncodingType.JPEG,
    //     mediaType: navigator.camera.MediaType.PICTURE,
    //     sourceType: navigator.camera.PictureSourceType.PHOTOLIBRARY,
    //   }
    // );
  }

  handleImageInput(files: FileList) {
    if (!this.validateImage(files[0].name)) {
      this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';

      return;
    }
    this.binDoc = files[0];
    this.imgErrorMsg = undefined;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.base64Image = reader.result as string;
    };
  }

  private validateImage(name: any) {
    return this.helperService.validateImage(name);
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }


  removeFile() {
    this.binDoc = undefined;
    this.base64Image = undefined;
  }
  remove() {
    this.oldUrl = this.selectedModel.imgUrl;
    this.selectedModel.imgUrl = '';
    this.binDoc = undefined;
    this.base64Image = undefined;
  }


  selectCategory(area: any) {
    this.selectedServicesUnderCategory = area;
    if (area) {
      this.selectedModel = area;
      this.serviceCategoryForm.patchValue({name: area.name});
      this.editModalOpen = true;
      setTimeout(() => {
        // $('.modal').modal();
        // M.updateTextFields();
        // $('#editModal').modal('open');
      }, 25);

    }
  }

  uploadImage(values) {
    if (this.isMobile) {
      if (this.mobileBase64Image) {
        const img = new ImageModel();
        img.imgBase64 = this.mobileBase64Image;
        this.mediaService.uploadImage(img).subscribe((upload) => {
          // this.loading = true;
          if (upload) {
            values.imgUrl = upload.imgLink;
            this.saveCatagory(values);
            return;
          }
        });
      }
    }
    else {
      if (this.binDoc) {
        let base64result = this.base64Image.split(',')[1];
        const img = new ImageModel();
        img.imgBase64 = base64result;
        this.mediaService.uploadImage(img).subscribe(
          upload => {
            if (upload) {
              values.imgUrl = upload.imgLink;
              this.saveCatagory(values);
              return;
            }
          }
        );
      }
    }
  }

  saveCatagory(model: any){
    if (!this.selectedModel) {
      this.employeeService.createServiceCategory(model).then( saved => {
        this.toast({ html: 'Service Category Successfully Saved', classes: 'green', type: 'success' });
      });
    }else{
      this.employeeService.updateServiceCategory(model).then( saved => {
        this.toast({ html: 'Service Category Successfully Saved', classes: 'green', type: 'success' });
      });
    }
    this.removeFile();
  }
  manageCategory(model: any) {

    this.editModalOpen = false;
    let newArea = { };
    if (!this.selectedModel) {
      newArea = {
        id: '',
        name: model.name,
        serviceCount: 0,
        imgUrl: ''
      };
    } else {
      newArea = {
        id: this.selectedModel.id,
        name: model.name,
        serviceCount: this.selectedModel.serviceCount ? this.selectedModel.serviceCount : 0,
        imgUrl: this.selectedModel.imgUrl ? this.selectedModel.imgUrl : '',

      };
    }
    if (this.base64Image) {
      this.uploadImage(newArea);
    } else {
      this.saveCatagory(newArea);
    }

  }
  deleteCategory(category){
    this.employeeService.deleteServiceCategory(category).then(() => {
      this.deleteModalOpen = false;
      this.toast({html: 'Category deleted.', classes: 'red', type: 'failure'});
      // this.backClicked();
    });

  }
  openDeleteModal(){
    // $('#deleteModal').modal('open');
    this.editModalOpen = false;
    this.deleteModalOpen = true;
  }
  closeDeleteModal(){
    this.deleteModalOpen = false;
  }

  deleteServiceUnder(item){
    console.log('item', item);
  }
  editServiceUnder(item){
    console.log('edit item ', item);
  }
  closeEditModal(){
    this.editModalOpen = false;
  }
  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
    }, 2000);
  }
  toggleFilter(){
    this.filterOpen = !this.filterOpen;
  }

  clearFilters(){
    this.serviceCategoryFilter = 'all';
    this.services = this.existingServices;
  }
  onCatecoryChange(){
    if (this.serviceCategoryFilter === 'all'){
      this.services = this.existingServices;
    }
    if (this.serviceCategoryFilter !== 'all'){
      this.services = this.existingServices.filter((service) => {
        return service.categoryId === this.serviceCategoryFilter;
        // return service.categoryId.toLowerCase().includes(this.serviceCategoryFilter.toLowerCase());
      });
    }
}
truncateHTML(text: string, limit: string): string {
  return this.helperService.truncateHTML(text, limit);
}

fileChangeEvent(event){
  this.imageChangedEvent = event;
  this.cropModalOpen = true;
}

closeCroppingModal(){    
  this.cropModalOpen = false;
}

processCroppedImage(event){
  this.cropModalOpen = false;
  this.base64Image = event;
  this.binDoc = this.base64Image;
}
getSortedList(unsortedElements: Array<any>){
  let sortedList = unsortedElements.sort((a:any, b:any ) => {
      return a.position- b.position;
  });
  return sortedList
}
getInnerText(text){
  return this.helperService.getInnerText(text);
}
BookServiceAppointment(service, serviceUnder){
  if (this.currentUser.role === 'User'){
    this.router.navigateByUrl('/user/book-appointment', {state: {service: service, serviceUnder:serviceUnder}});
  }
  if (this.currentUser.role === 'Admin'){
    this.router.navigateByUrl('/admin/create-appointment', {state: {service: service, serviceUnder:serviceUnder}});
  }
}
OpenLoginPopup(){
  
  this.toast({html: 'You should login first to book appointment!', classes: 'red', type: 'failure'});
}
openConfirmationModal(){
  this.toast({html: 'Successfully  posted a blog!', classes: 'green', type: 'success'});
}
}
