<div class="flex max-w-7xl md:mx-10 mx-5">
  <div class="w-full">
    <div class="pb-4  flex justify-between">
      <div>
        <select (change)="selectEmployee($event.target)" class="select select-bordered w-full text-base bg-veryLightGray" >
          <option disabled selected>Select Employee</option>
          <option default value = ''>Show all</option>
          <option value={{emp.uid}} *ngFor="let emp of employees">{{emp.GivenName}} {{emp.LastName}}
          </option>
        </select>
      </div>

      <div *ngIf="appSettings">
        <button *ngIf="appSettings.takeAppointment=='yes' || appSettings.takeAppointment==true" class="btn btn-custom-solid" (click)="navigateToServices()">
          Book an Appointment
        </button>
      </div>
    </div>

    <div class="flex items-center justify-between p-3 rounded-md bg-white ">
      <div class="flex items-center space-x-3">

     <div class="flex   cursor-pointer">
      <div class="py-3 px-4 rounded-l-md border border-solid border-gray-300" (click)="toggleView()">
        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512">
          <path d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192h80v56H48V192zm0 104h80v64H48V296zm128 0h96v64H176V296zm144 0h80v64H320V296zm80-48H320V192h80v56zm0 160v40c0 8.8-7.2 16-16 16H320V408h80zm-128 0v56H176V408h96zm-144 0v56H64c-8.8 0-16-7.2-16-16V408h80zM272 248H176V192h96v56z"/></svg>
      </div>
      <div class="py-3 px-4 rounded-r-md border border-solid border-gray-300" (click)="toggleView()">
        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512">
          <path d="M40 48C26.7 48 16 58.7 16 72v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V72c0-13.3-10.7-24-24-24H40zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zM16 232v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V232c0-13.3-10.7-24-24-24H40c-13.3 0-24 10.7-24 24zM40 368c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V392c0-13.3-10.7-24-24-24H40z"/></svg>
      </div>
     </div>
        <div>
          <button class="py-2 px-4 border rounded-md border-solid border-gray-300 text-base font-semibold bg-white " (click)="today()">Today</button>
        </div>
       
        <div class="">
          <div class="flex items-center">
            <button class="py-3 px-4 rounded-l-md border border-solid border-gray-300" (click)="decrement()" [disabled]="prevBtnDisabled">
              <span class="">
                <svg width="12" height="20" viewBox="0 0 12 20" class="w-4 h-4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9.125 19.1L0.700003 10.7C0.600003 10.6 0.529336 10.4917 0.488003 10.375C0.446003 10.2584 0.425003 10.1334 0.425003 10C0.425003 9.86669 0.446003 9.74169 0.488003 9.62502C0.529336 9.50836 0.600003 9.40002 0.700003 9.30002L9.125 0.875024C9.35834 0.641691 9.65 0.525024 10 0.525024C10.35 0.525024 10.65 0.650024 10.9 0.900024C11.15 1.15002 11.275 1.44169 11.275 1.77502C11.275 2.10836 11.15 2.40002 10.9 2.65002L3.55 10L10.9 17.35C11.1333 17.5834 11.25 17.8707 11.25 18.212C11.25 18.554 11.125 18.85 10.875 19.1C10.625 19.35 10.3333 19.475 10 19.475C9.66667 19.475 9.375 19.35 9.125 19.1Z"
                    fill="black" />
                </svg>
              </span>
            </button>

            <button class="py-3 px-4 rounded-r-md border border-solid border-gray-300" (click)="increment()" [disabled]="nextBtnDisabled">
              <span class=" ">
                <svg width="12" height="20" class="w-4 h-4" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M2.875 0.899974L11.3 9.29998C11.4 9.39998 11.4707 9.50831 11.512 9.62498C11.554 9.74164 11.575 9.86664 11.575 9.99998C11.575 10.1333 11.554 10.2583 11.512 10.375C11.4707 10.4916 11.4 10.6 11.3 10.7L2.875 19.125C2.64167 19.3583 2.35 19.475 2 19.475C1.65 19.475 1.35 19.35 1.1 19.1C0.850001 18.85 0.725001 18.5583 0.725001 18.225C0.725001 17.8916 0.850001 17.6 1.1 17.35L8.45 9.99997L1.1 2.64997C0.866669 2.41664 0.750002 2.12931 0.750002 1.78797C0.750003 1.44597 0.875003 1.14997 1.125 0.899974C1.375 0.649974 1.66667 0.524974 2 0.524974C2.33334 0.524974 2.625 0.649974 2.875 0.899974Z"
                    fill="black" />
                </svg>
              </span>
            </button>
          </div>

        </div>
        <div class="mt-2 mr-3">
          <div class="mb-3 w-full hidden md:flex justify-center text-base ">
            {{ viewDate | calendarDate:(view + 'ViewTitle'):'en':weekStartsOn }}
          </div>
          <div class="mb-3 w-full flex md:hidden text-base justify-center">
            {{ viewDate | calendarDate:(view + 'ViewTitle'):'en':weekStartsOn }}
          </div>
        </div>
      </div>
      <div class="hidden md:flex items-center space-x-2 ">
        
    <div>
        <div class="flex">
          
          <div class="py-2 px-4 text-base rounded-l-md border border-solid border-gray-300" [ngClass]="{'bg-gray-200 font-bold ': view === CalendarView.Month}"
            (click)="setView(CalendarView.Month)" [class.active]="view === CalendarView.Month">
            Month
          </div>
          <div class="py-2 px-4 text-base border border-solid border-gray-300" [ngClass]="{'bg-gray-200 font-bold ': view === CalendarView.Week}"
            (click)="setView(CalendarView.Week)" [class.active]="view === CalendarView.Week">
            Week
          </div>
          <div class="py-2 px-4 text-base rounded-r-md border border-solid border-gray-300" [ngClass]="{'bg-gray-200 font-bold': view === CalendarView.Day}"
            (click)="setView(CalendarView.Day)" [class.active]="view === CalendarView.Day">
            Day
          </div>
        </div>
    </div>
       
      
        <div class="flex cursor-pointer py-2 px-4 rounded-md border text-base border-solid border-gray-200"  (click)="navigateToSettings('schedule')"><i
            class="material-icons float-left mr-1">event_available</i><span>Schedule</span></div>
        <div class="flex cursor-pointer h-max py-3 px-4 rounded-md border border-solid border-gray-300"  (click)="toggleFilter()">
          <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512">
            <path d="M0 416c0 17.7 14.3 32 32 32l54.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48L480 448c17.7 0 32-14.3 32-32s-14.3-32-32-32l-246.7 0c-12.3-28.3-40.5-48-73.3-48s-61 19.7-73.3 48L32 384c-17.7 0-32 14.3-32 32zm128 0a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zM320 256a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm32-80c-32.8 0-61 19.7-73.3 48L32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l246.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48l54.7 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-54.7 0c-12.3-28.3-40.5-48-73.3-48zM192 128a32 32 0 1 1 0-64 32 32 0 1 1 0 64zm73.3-64C253 35.7 224.8 16 192 16s-61 19.7-73.3 48L32 64C14.3 64 0 78.3 0 96s14.3 32 32 32l86.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48L480 128c17.7 0 32-14.3 32-32s-14.3-32-32-32L265.3 64z"/></svg>
        </div>
        <div class="flex cursor-pointer py-3 px-4 h-max rounded-md border border-solid border-gray-300" (click)="navigateToSettings('appointment-setting')">
          <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512">
            <path d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"/></svg>
        </div>

      </div>
      <div class="md:hidden flex">
        <div class="dropdown dropdown-bottom dropdown-end">
          <label tabindex="0" class="m-1 mt-2">
            <i class="material-icons">menu</i>
          </label>
          <ul tabindex="0" class="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
            <li><a class="flex" (click)="toggleFilter()" onclick="document.activeElement.blur()"><i
                  class="material-icons float-left mr-1">tune</i><span>Filter</span></a></li>
            <li><a class="flex" (click)="navigateToSettings('schedule')"onclick="document.activeElement.blur()"><i
                  class="material-icons float-left mr-1">event_available</i><span>Schedule</span></a></li>
            <li><a class="flex" (click)="toggleView()" onclick="document.activeElement.blur()"><i
                  class="material-icons float-left mr-1"> {{isCalendarView ? 'format_list_bulleted' :
                  'calendar_today'}}</i> <span> {{isCalendarView ? 'List View' : 'Calender View'}}</span></a></li>
            <li (click)="navigateToSettings('appointment-setting')"><a class="flex"  onclick="document.activeElement.blur()"><i
                  class="material-icons float-left mr-1">settings</i>Settings</a></li>
          </ul>
        </div>
      </div>
    </div>


    <!-- Top buttons -->
    <div class="flex flex-col md:flex-row mt-3 mb-2 mx-4 justify-between "
      [ngClass]="{'justify-between': !isCalendarView, 'justify-end':isCalendarView}" *ngIf="activeAppointments">
      <div class="flex" *ngIf="!isCalendarView">

        <div class="flex flex-row mb-2 md:mb-0" *ngFor="let item of dayAndmonth">
          <label
            class="border bg-white text-black focus:text-white focus-bg-customTeal hover:bg-customTeal  font-medium px-2 py-2 md:py-2 md:px-4 rounded-lg text-center space-x-1"
            (click)="buttonClicked(item.day_month)">
            <p class="text-center">{{(item.day_month).format("[\r\n]DD")}}</p>
            {{(item.day_month).format("[\r\n]MMM")}}
          </label>
        </div>
      </div>

    </div>

    <!-- Filter dropdown -->
    <div *ngIf="filterOpen" class="p-2 mb-2 mt-0 ">
      <div class="w-full lg:w-4/12 mb-1">
        <div class="mx-6">
          <select [(ngModel)]="appointmentTimelineFilter" (change)="onTimeframeChange()"
            class="select select-bordered w-full">
            <option disabled selected value="">Select a timeframe</option>
            <option value="allTime">All Time</option>
            <option value="thisYear">This Year</option>
            <option value="thisWeek">This Week</option>
            <option value="thisMonth">This Month</option>
            <option value="today">Today</option>
          </select>
        </div>
      </div>
    </div>

    <div *ngIf="!isCalendarView">
      <div class=" flex flex-col space-y-2 mx-2 lg:mx-10" *ngIf="activeAppointments.length != 0">
        <div class=" flex flex-row items-center  mt-2 p-2 md:space-x-6">
          <div class="w-4/12 hidden lg:block">
            Name
          </div>
          <div class="w-2/12 hidden lg:block">
            <p class=" text-sm">Phone no/email</p>
          </div>
          <div class="w-2/12 hidden lg:block">
            <p class=" text-sm">Date</p>
          </div>
          <div class="w-3/12 hidden lg:block">
            <p class=" text-sm">Employee </p>
          </div>
          <div class="w-1/12 hidden lg:block">

          </div>

        </div>
      </div>

      <div *ngIf="activeAppointments.length == 0">
        <div class="flex items-center justify-center">
          <img loading="lazy" data-src="../../../../assets/icons/Illustrations/Appointment-Illustration.png"
            class="block w-64 items-center justify-center">
        </div>
        <div class="text-customTeal text-lg text-center font-bold"> No appointments found!</div>

      </div>

      <div class="flex flex-col space-y-2 mx-2 lg:mx-10" *ngIf="activeAppointments">
        <!-- Appointment list -->
        <ng-container *ngIf="activeAppointments.length > 0">
          <div class=" flex flex-row bg-white items-center  mt-2 p-2 rounded-md   border-2 md:space-x-6" *ngFor="let apptWithEmp of activeAppointments  let i= index ">
            <div class="w-7/12 md:w-4/12 flex">

              <div class="flex items-center space-x-3">
                <div *ngIf="apptWithEmp.appointment">
                  <div class="break-words">{{truncateHTML(apptWithEmp.appointment.serviceName, 40)}}</div>
                </div>
              </div>
            </div>
            <div class="w-2/12 hidden md:block">
              <p class=" text-sm" *ngIf="apptWithEmp.user.cellPhone; else elseBlock">
                {{apptWithEmp.user.cellPhone}}</p>
              <ng-template #elseBlock>
                <p *ngIf="apptWithEmp.user.Email" class=" text-sm">{{apptWithEmp.user.Email}}</p>
              </ng-template>
              <p *ngIf="!apptWithEmp.user.Email && !apptWithEmp.user.cellPhone">(----) (---) (---)</p>

            </div>
            <div class="w-4/12 md:w-2/12">
              <p class=" text-sm">{{(apptWithEmp.appointment.startTime.toDate()) | date }}</p>
            </div>
            <div class="w-3/12 hidden md:block">
              <p class=" text-sm">{{apptWithEmp.user.GivenName}} {{apptWithEmp.user.LastName}}</p>
            </div>
            <div class="w-1/12 md:w-1/12 cursor-pointer" (click)="viewAppointment(apptWithEmp.appointment)">
              <i class="material-icons">arrow_circle_right</i>
            </div>

          </div>

        </ng-container>
        <div>
          <!-- <pagination-controls class="text-right mr-20" (pageChange)="page = $event"></pagination-controls> -->
        </div>
        <!-- Notes -->
        <ng-container *ngIf="this.notes.length > 0">
          <div>Notes</div>
          <div *ngFor="let note of notes; let i= index ">
            <div *ngIf="note.reminder"
              class="flex flex-row bg-yellow-50 items-center mt-2 p-2 rounded-md border-2 md:space-x-6">
              <div class="flex flex-row w-full space-x-3">
                <div class="w-5/12" *ngIf="note.Message">
                  {{note.Message}}
                  <div class="break-words">{{truncateHTML(note.Message[0], 40)}}</div>
                </div>
                <div class="w-3/12">
                  <ng-container *ngIf="note.reminder">
                    {{note.reminder}}
                  </ng-container>
                </div>
                <div class="w-2/12">
                  <span></span>
                  <ng-container *ngIf="note.reminderTime">
                    {{note.reminderTime}}
                  </ng-container>
                </div>
                <div class="w-2/12 flex justify-center">NOTE</div>
              </div>
            </div>
          </div>
        </ng-container>
        <!-- Tasks -->
        <ng-container *ngIf="tasks.length > 0">
          <div>Tasks</div>
          <div *ngFor="let task of tasks">
            <div *ngIf="task.reminder"
            class="flex flex-row bg-yellow-50 items-center p-2 rounded-md border-2 md:space-x-6">
            <div class="flex flex-row w-full space-x-3">
              <div class="w-5/12">

                <div class="break-words">{{truncateHTML(task.taskDescription, 40)}}</div>
              </div>
              <div class="w-3/12">
                <ng-container *ngIf="task.reminder">
                  {{task.reminder}}
                </ng-container>
              </div>
              <div class="w-2/12">
                <span></span>
                <ng-container *ngIf="task.reminderTime">
                  {{task.reminderTime}}
                </ng-container>
              </div>
              <div class="w-2/12 flex justify-center">TASK</div>
            </div>
          </div>
          </div>
        </ng-container>
        <br />

      </div>

      <br /> <br />
    </div>

  </div>
</div>

<!-- calender view -->
<div *ngIf="isCalendarView">
<div>
  <div>
    <div  class="row col s12 mx-5 md:mx-10">
      <div [ngSwitch]="view">
        <mwl-calendar-month-view class="text-base p-4" 
          *ngSwitchCase="CalendarView.Month" 
          [viewDate]="viewDate" 
          [events]="events"
          [refresh]="refresh" 
          [activeDayIsOpen]="activeDayIsOpen" 
          (dayClicked)="dayClicked($event.day)"
          (eventClicked)="handleEvent('Clicked', $event.event)" 
          (eventTimesChanged)="eventTimesChanged($event)"
          (hourSegmentClicked)="hourSegmentClicked($event.date)">
        </mwl-calendar-month-view>
        <mwl-calendar-week-view 
          *ngSwitchCase="CalendarView.Week"
          [viewDate]="viewDate" 
          [events]="events"
          [weekStartsOn]="[weekStartsOn]"
          [refresh]="refresh" 
          (eventClicked)="eventClicked($event)"
          (eventTimesChanged)="eventTimesChanged($event)" 
          (hourSegmentClicked)="hourSegmentClicked($event)"
          (beforeViewRender)="beforeViewRender($event)" 
          [hourSegments]="4" 
          [dayStartHour]="minStartingTime"
          [dayEndHour]="maxEndingTime ">
        </mwl-calendar-week-view>
        <mwl-calendar-day-view class="text-base p-4" 
          *ngSwitchCase="CalendarView.Day" 
          [viewDate]="viewDate" 
          [events]="events"
          [refresh]="refresh" 
          [dayStartHour]=8 
          [hourSegments]="4" 
          (eventClicked)="handleEvent('Clicked', $event.event)"
          (eventTimesChanged)="eventTimesChanged($event)" 
          (hourSegmentClicked)="hourSegmentClicked($event.date)">
        </mwl-calendar-day-view>
      </div>
    </div>
    <div class="flex justify-center flex-col ">
      <div class="mb-3 w-full flex justify-center text-customTeal text-lg font-bold">
        {{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}
      </div>
      <div class="btn-group w-full justify-center">
        <button class="btn btn-primary" (click)="decrement()" [disabled]="prevBtnDisabled"> <i
            class="material-icons float-left mr-2">chevron_left</i>
          Previous
        </button>
        <button class="btn bg-white text-black border border-customTeal" (click)="today()">Today</button>
        <button class="btn btn-primary" (click)="increment()" [disabled]="nextBtnDisabled">
          Next <i class="material-icons float-right ml-2">navigate_next</i>
        </button>
      </div>
    </div>
  </div>
</div>

</div>


<div id="my-modal-createTask" *ngIf="openCreateTaskModal" class="modal"
  [ngClass]="{'modal-open': openCreateTaskModal, 'modal-action': !openCreateTaskModal }">
  <div class="modal-box max-w-3xl">
    <div class="modal-content">
      <div (click)="cancelTaskModal()" class="flex justify-end cursor-pointer"><span
          class="px-2 py-2 bg-gray-200 rounded-full"><i class="material-icons">close</i></span></div>
      <h3 class="font-bold text-lg text-center mb-2" *ngIf="!selectedEmployee">Create Task</h3>
      <h3 class="font-bold text-lg text-center mb-2" *ngIf="selectedEmployee">Assign {{selectedEmployee.GivenName | titlecase}} a task</h3>
      <p class="py-2 text-center font-medium">Users with a disabled accounts won't be able to sign in.</p>

      <hr />
      <form [formGroup]="tasksForm" (ngSubmit)="createTask(tasksForm.value)">

        <textarea id="message" rows="4" formControlName="taskDescription" [readonly]="nonEditable"
          class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Put your tasks over here..." required></textarea>

          <div *ngIf="(taskDescription.dirty || taskDescription.touched) && taskDescription.invalid">
            <small *ngIf="taskDescription.errors.required" class="text-red-500 italic"> The Task field is required.
            </small>
          </div>
        <hr />
        <div class="w-full px-3 my-2 md:mb-0 space-x-2 flex-row flex justify-between">
          <div class="flex flex-col w-full">
            <span class="label-text text-[#4F4F4F] text-base font-bold leading-5 py-2"> Start Date
            </span>

            <input id="reminder" formControlName="reminder" type="date" class="input input-bordered " [readonly]="nonEditable"
            [ngClass]="{
              'border-red-500': (reminder.dirty || reminder.touched) && reminder.invalid,
              'border-gray-200': !((reminder.dirty || reminder.touched) && reminder.invalid)
            }" (change)="dateChangeReminderStartDate()" required >
            <div *ngIf="(reminder.dirty || reminder.touched) && reminder.invalid">
              <small *ngIf="reminder.errors.required" class="text-red-500 italic"> Starting Date of the
                Reminder is required. </small>
            </div>
          </div>

          <div class="flex flex-col w-full">
            <span class="label-text text-[#4F4F4F] text-base font-bold leading-5 py-2"> Start Time
            </span>
              <div class="flex w-full">
                <div class="border-2 border-gray-400  bg-gray-100  rounded-lg w-full">

                  <select [disabled] = "nonEditable" maxlength="8" formControlName="reminderTime" [readonly]="nonEditable" [ngClass]="{
                    'border-red-500': (reminderTime.dirty || reminderTime.touched) && reminderTime.invalid,
                    'border-gray-200': !((reminderTime.dirty || reminderTime.touched) && reminderTime.invalid)}"class="select w-full border-2 border-gray-200">
                    <option disabled selected>Reminder Time</option>
                    <option [disabled] = "nonEditable" *ngFor="let item of times" (click)="setStartTime(item)">{{item}}</option>
                  </select>

                </div>
              </div>
           
            <div *ngIf="(reminderTime.dirty || reminderTime.touched) && reminderTime.invalid">
              <small *ngIf="reminderTime.errors.required" class="text-red-500 italic"> Starting Time of the
                Reminder is required. </small>
            </div> 
            <small *ngIf="reminderTime.hasError('invalid')" class="text-red-500">Start Time must be after/the same as
              time now! </small>
          </div>

        </div>
        
        <div class="w-full px-3 my-2 md:mb-0 space-x-2 flex-row flex justify-between">
          <div class="flex flex-col w-full">
            <span class="label-text text-[#4F4F4F] text-base font-bold leading-5 py-2"> End Date
            </span>

            <input id="endReminder" formControlName="endReminder" type="date" class="input input-bordered " [readonly]="nonEditable"
            [ngClass]="{
              'border-red-500': (endReminder.dirty || endReminder.touched) && endReminder.invalid,
              'border-gray-200': !((endReminder.dirty || endReminder.touched) && endReminder.invalid)
            }"required>

            <div *ngIf="(endReminder.dirty || endReminder.touched) && endReminder.invalid">
              <small *ngIf="endReminder.hasError('invalid')" class="text-red-500">End date must be after/the same as
                start date! </small>
            </div>

          </div>

          <div class="flex flex-col w-full">
            <span class="label-text text-[#4F4F4F] text-base font-bold leading-5 py-2"> End Time
            </span>

            <div class="flex w-full">
              <div class="border-2 border-gray-400 bg-gray-100 rounded-lg w-full">

                <input type="text" *ngIf="nonEditable" formControlName="endReminderTime" [readonly]="nonEditable"
                                         class="input input-bordered w-full">

                <select *ngIf="!nonEditable" maxlength="8" formControlName="endReminderTime" [readonly]="nonEditable" class="select w-full border-2 border-gray-200">
                  <option disabled selected>Reminder Time</option>
                  <option  *ngFor="let item of times" (click)="setEndTime(item)">{{item}}</option>
                </select>

              </div>
            </div>
              <div>

              </div>
              <div *ngIf="(endReminderTime.dirty || endReminderTime.touched) && endReminderTime.invalid">
                <small *ngIf="endReminderTime.hasError('pattern')" class="text-red-500">
                  Invalid time format</small>
              </div>
              <small *ngIf="endReminderTime.hasError('invalid')" class="text-red-500">End Time must be after five minutes or more the
                start time </small>
          </div>

        </div>



        <div class="modal-action items-center justify-between mx-6"
          *ngIf="(eventEditObj != undefined && eventEditObj.appointmentId != undefined)">
          <!-- (click)="handleEvent('Delete',eventEditObj)" -->
          <button type="button" *ngIf="(!nonEditable)" (click)="openDeleteModal()"
            class="text-white font-bold px-4 py-2 bg-red-500 rounded-md">Delete</button>

            <button type="button" *ngIf="(nonEditable)" (click)="cancelTaskModal()"
            class="font-bold text-customTeal border-customTeal border-2 px-4 py-2 ">Cancel</button>

          <span class="flex justify-end space-x-4">
            <button type="button" *ngIf="!(nonEditable)" (click)="cancelTaskModal()"
              class="font-bold text-customTeal border-customTeal border-2 px-4 py-2 ">Cancel</button>
            
        
            <button type="button" *ngIf="(nonEditable)" (click)="editTask()" [disabled]="!tasksForm.valid"
              class="text-white font-bold px-4 py-2 bg-customTeal rounded-md justify-end">Edit</button>

            <button type="button" *ngIf="!(nonEditable)" (click)="handleEvent('Edit', $event)" [disabled]="!tasksForm.valid"
              class="text-white font-bold px-4 py-2 bg-customTeal rounded-md">Update</button>
          </span>

        </div>
        
        <div class="modal-action items-center justify-between mx-6"
          *ngIf="(eventEditObj == undefined || eventEditObj.appointmentId == undefined) || !eventEditObj">
        
          <button type="button" *ngIf=" !(nonEditable)" (click)="cancelTaskModal()"
            class="font-bold text-customTeal border-none">Cancel</button>
         
          <button type="button" *ngIf="" (click)="cancelTaskModal()"
                    class="font-bold text-customTeal border-none">Cancel</button>

          <button class="py-2 px-4 bg-customTeal text-white border-customTeal rounded-md" type="submit"
            [disabled]="!tasksForm.valid">Save</button>
        
        </div>



      </form>

    </div>
  </div>
</div>

<div id="my-modal-deleteModal" class="modal" *ngIf="deleteModalOpen"
  [ngClass]="{'modal-open': deleteModalOpen, 'modal-action': !deleteModalOpen }">

  <div class="modal-box">
    <div class="modal-content">

      <h3 class="font-bold text-lg text-center text-customTeal my-2"> Delete from calender </h3>
      <p class="text-md text-center">Appointments, Notes or Tasks deleted from the calender wont be able to be shown on
        the calender.
      </p>
      <hr />
      <p class="text-md text-center my-2">Are you sure you want to Delete from the calender?</p>
      <!-- <p class="text-md text-center my-2" *ngIf="eventObj.isNote"><span class="font-bold space-x-4">Note:
        </span>{{noteGlobal.Message[0]}}</p> -->
      <p class="text-md text-center my-2" *ngIf="eventEditObj.isTask && (eventEditObj && eventEditObj.title)"><span class="font-bold space-x-4">Task:
        </span>{{truncateHTML(eventEditObj.title, 1000)}}</p>
      <div class="flex justify-between m-4">
        <button (click)="closeDeleteModal()" class="font-bold">Cancel</button>

        <button (click)="handleEvent('Delete', eventEditObj)"
          class="text-white font-bold px-4 py-2 bg-red-500 rounded-md">Delete</button>
      </div>
    </div>
  </div>

</div>

<div *ngIf="!activeAppointments" class="row card-panel blue lighten-3">
  <h6 class="white-text">
    <strong>You don't have any appointments</strong>
  </h6>
</div>



