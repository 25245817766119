<div>
    <div *ngIf="filteredActiveAppointments.length > 0">
        <div class="flex  p-4 bg-gray-50 px-4 py-1">
          <p class="text-sm w-2/6">User Name</p>
          <p class="text-sm w-2/6">Date</p>
          <p class="text-sm w-2/6">Service</p>
          <p class="text-sm  md:block hidden w-1/12">Details</p>

        </div>
        <!-- appointment user list -->
        <div class="bg-white px-4 py-3 flex items-center min-h-16"
          *ngFor="let apptWithEmp of filteredActiveAppointments | slice:0:4;"
          [routerLink]="['/admin/appointment-detail',apptWithEmp.appt.id]">
          <div class="w-2/6 flex items-center space-x-2">
            <div class="avatar placeholder">
              <div class="bg-neutral-focus text-neutral-content rounded-full w-9">
                <div *ngIf="!apptWithEmp.profileImgLink">
                  <span class="text-xl">{{apptWithEmp.user.GivenName[0]}}</span>
                </div>
                <div *ngIf="apptWithEmp.profileImgLink">
                  <img loading="lazy" [src]="apptWithEmp.profileImgLink" alt="User Profile Image" />
                </div>
              </div>
            </div>
            <div class="text-sm font-bold ">
              {{apptWithEmp.user.GivenName}} {{apptWithEmp.user.LastName}}
            </div>
          </div>
          <div class="text-sm w-2/6">
            {{(apptWithEmp.appt.startTime.toDate()) | date }}

          </div>
          <div class="break-words text-sm w-2/6">
            {{truncateHTML(apptWithEmp.appt.serviceName, 40)}}
          </div>
          <div class="md:block hidden w-1/12 md:w-1/12 cursor-pointer"
            [routerLink]="['/admin/appointment-detail',apptWithEmp.appt.id]">
            <i class="material-icons">arrow_circle_right</i>
          </div>
        </div>
        <a href="" class="flex justify-end font-bold text-sm mx-4 my-2" *ngIf="filteredActiveAppointments.length > 4"
          [routerLink]="['/admin/appointments']">View all</a>

      </div>
      <!-- Empty appointment -->
      <div *ngIf="filteredActiveAppointments.length==0" class="my-4">
        <div class="flex items-center justify-center">
            <img loading="lazy" data-src="../../../../assets/icons/Illustrations/empty appointment-01.svg" class="block w-24 items-center justify-center" alt="">
        </div>

        <div class="text-customTeal text-lg text-center font-bold" *ngIf="!isAll">
          No appointments found!
        </div>

        <div class="text-customTeal text-base text-center font-bold" *ngIf="isAll">
          There's nothing on your schedule yet.
        </div>
        <!-- <div class="text-gray-400 text-sm text-center">Enjoy it!</div> -->

      </div>
</div>