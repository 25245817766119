<!-- <p>update-firebase-rules works!</p> -->

<!-- <div class="m-5 w-full md:full">
    <form [formGroup]="firebaseRulesForm" (ngSubmit)="updateRules(firebaseRulesForm.value)">


      <div class="flex flex-col gap-y-2">
        <div class="">
          <span class="label-text text-[#4F4F4F] text-base font-bold leading-5">Collection Name<span
              class="text-red-500">*</span></span>
          <div class="input-field flex md:1/2 w-full">
            <input id="collectionName" type="text" formControlName='collectionName' required
              class="input input-bordered w-1/8 md:w-1/8" autocomplete="off" />

          </div>
          <div class="w-full" *ngIf="(collectionName?.dirty || collectionName?.touched) && collectionName?.invalid">
            <small *ngIf="collectionName?.errors.required" class="text-red-500 italic">Analytics name is required.
            </small>
            <small *ngIf="collectionName?.hasError('accExists')" class="text-red-500 italic">Analytics name is used
              try another.
            </small>
            <div *ngIf="collectionName?.hasError('accName')" class="text-red-500 italic">
              Should start with a letter, allowed inputs are lowercase letters,<br />
              digit hyphen or hyphen and should have a total length of 2 chars.
            </div>
          </div>
        </div>

        <div class="flex flex-wrap">
          <div class="w-full md:w-1/2 mb-6 md:mb-0">
            <label class="label">
              <span class="label-text text-[#4F4F4F] text-base font-bold leading-5">Authorized read or write</span>
            </label>


            <div class="flex items-center">
                <input type="checkbox" id="myCheckbox" class="form-checkbox h-5 w-5 text-indigo-600"  formControlName="readAuth">
                <label for="myCheckbox" class="ml-2 text-gray-700">Authorized Read</label> 

                <input type="checkbox" id="myCheckbox" class="form-checkbox h-5 w-5 text-indigo-600 ml-5" formControlName="writeAuth">
                <label for="myCheckbox" class="ml-2 text-gray-700">Authorized Write</label>
              </div>
              

          </div>
        </div>


      </div>

      <br /><br />

      <button  type="submit " [disabled]="!firebaseRulesForm.valid"
        [ngClass]="{'!bg-[#cccccc] !text-[#666666]': !firebaseRulesForm.valid}" href="#"
        class="inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
        data-te-ripple-init data-te-ripple-color="light">
        Update Rules
      </button>

    </form>

  </div> -->


  <!-- Your Angular component template -->
<div class="flex justify-center w-full">
    <div class="text-left mr-5">
      <!-- Your left-aligned text content goes here -->
      <!-- <p>Your left-aligned text content goes here.</p> -->

      <!-- form start -->
      <form [formGroup]="firebaseRulesForm" (ngSubmit)="updateRules(firebaseRulesForm.value)">


        <div class="flex flex-col gap-y-2">
          <div class="">
            <span class="label-text text-[#4F4F4F] text-base font-bold leading-5">Collection Name<span
                class="text-red-500">*</span></span>
            <div class="input-field flex md:1/2 w-full">
              <input id="collectionName" type="text" formControlName='collectionName' required
                class="input input-bordered w-1/8 md:w-1/8" autocomplete="off" />
  
            </div>
            <div class="w-full" *ngIf="(collectionName?.dirty || collectionName?.touched) && collectionName?.invalid">
              <small *ngIf="collectionName?.errors.required" class="text-red-500 italic">Analytics name is required.
              </small>
              <small *ngIf="collectionName?.hasError('accExists')" class="text-red-500 italic">Analytics name is used
                try another.
              </small>
              <div *ngIf="collectionName?.hasError('accName')" class="text-red-500 italic">
                Should start with a letter, allowed inputs are lowercase letters,<br />
                digit hyphen or hyphen and should have a total length of 2 chars.
              </div>
            </div>
          </div>
          <!-- Message -->
  
          <!-- ----------- -->
          <div class="flex flex-wrap">
            <div class="w-full md:w-1/2 mb-6 md:mb-0">
              <label class="label">
                <span class="label-text text-[#4F4F4F] text-base font-bold leading-5">Authorized read or write</span>
              </label>
              <!-- <select formControlName="region" required
                class="validate py-3 px-5 select select-bordered select-gray-600 select-text-gray-800"
                (change)="selectRegion($event.target.value)">
                <option value="" disabled selected>Please Select Region.</option>
                <option *ngFor="let option of options" [value]="option.value">{{ option.label }}</option>
              </select> -->
  
              <div class="flex items-center">
                  <input type="checkbox" id="myCheckbox" class="form-checkbox h-5 w-5 text-indigo-600"  formControlName="readAuth">
                  <label for="myCheckbox" class="ml-2 text-gray-700">Authorized Read</label> 
  
                  <input type="checkbox" id="myCheckbox" class="form-checkbox h-5 w-5 text-indigo-600 ml-5" formControlName="writeAuth">
                  <label for="myCheckbox" class="ml-2 text-gray-700">Authorized Write</label>
                </div>
                
              <!-- <div class="input-field flex md:1/2 w-full py-5">
                <div class="w-full">
                  <div *ngIf="(region.dirty || region.touched) && region.invalid">
                    <small class="text-red-500 italic">Region is required. </small>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
  
          <!-- ___________ -->
  
        </div>
  
        <br /><br />
  
        <button  type="submit " [disabled]="!firebaseRulesForm.valid"
          [ngClass]="{'!bg-[#cccccc] !text-[#666666]': !firebaseRulesForm.valid}" href="#"
          class="inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          data-te-ripple-init data-te-ripple-color="light">
          Update Rules
        </button>
  
      </form>
      <!-- form end -->
    </div>

    <div class="border-l border-black-500 h-full"></div>


    <div class="text-right ml-5">
      <!-- Your right-aligned text content goes here -->
      <!-- <p>Your right-aligned text content goes here.</p> -->
    </div>
  </div>