<div class="font-body max-h-max p-4 md:px-0 ">
  <div class="flex flex-col md:w-4/6 shadow-md border border-solid border-gray-300 bg-white h-max p-4 md:px-10 md:py-3 rounded-md space-y-4 md:translate-x-1/4">
    <div class="py-2 space-y-2">
      <div class="flex space-x-2">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" class="w-6 h-6 stroke-customTeal">
          <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75" />
        </svg>
        
        <p class=" font-bold  text-brandColor ">Account Preferences </p>
      </div>
      <hr>
    </div>
    <!-- Notification -->
    <div class="flex flex-col space-y-4 md:justify-between md:flex-row md:space-y-0 ">
      <!-- Description -->
      <div class="md:w-1/2 space-y-2  ">
        <p class="font-semibold text-base text-gray-600">
          Turn On All Notifications
        </p>
    </div>
      <!-- toggle & edit -->
      <div class="flex md:justify-center  space-x-8 items-baseline">
        <!-- toggle -->
        <div>
          <div class="form-control">
            <label class="label cursor-pointer">
              <span class="label-text pr-2 font-semibold " *ngIf="!toggleNotfication">Off</span>
              <input type="checkbox" class="toggle toggle-primary " [checked]="toggleNotfication"
                (click)="toggleNotificationButton($event)" />
              <span class="label-text pl-2 font-semibold " *ngIf="toggleNotfication">On</span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <hr>
    <!-- Send content -->
    <div class="flex flex-col space-y-4 justify-between md:flex-row md:space-y-0 ">
      <!-- Description -->
      <div class="md:w-1/2 space-y-2  ">
        <p class="font-semibold text-base text-gray-600">
          Send content about new offers, Events and promotion?
        </p>
      </div>
      <div class="flex md:justify-center align-middle space-x-8 items-baseline">
        <div class="form-control">
          <label class="label cursor-pointer space-x-2">
            
            <input type="checkbox" class="checkbox checkbox-primary rounded-sm checkbox-sm" [checked]='toggleNotfication && SMSchecked'
              [disabled]="loggedInUser?.cellPhone == ''" (click)="toggleSMS($event)" />
            <span class="label-text inline-block pl-[0.15rem] text-base hover:cursor-pointer  ">SMS</span>
          </label>
        </div>
        <div class="form-control">
          <label class="label cursor-pointer space-x-2">
            <input type="checkbox" class="checkbox checkbox-primary rounded-sm checkbox-sm" [checked]='toggleNotfication || Emailchecked'
              (click)="toggleEmail($event)" />
            <span class="label-text">Email</span>
          </label>
        </div>

      </div>
    </div>
    <div class="text-yellow-400 flex font-bold" *ngIf="loggedInUser?.cellPhone == ''"><i
        class="material-icons">warning</i><span class="text-yellow-500">You should enter your cell phone in order to
        choose the SMS preference.</span></div>
    <hr />

    <!-- push notification preference -->
    <div class="flex flex-col space-y-4 justify-between md:flex-row md:space-y-0 ">
      <!-- Description -->
      <div class="md:w-1/2 space-y-2  ">
        <p class="font-semibold text-base text-gray-600">
          Receive in App Notifications?
        </p>
      </div>

      <div class="flex flex-col justify-center align-middle space-x-8 items-baseline">
        <div class="form-control">
          <label class="label cursor-pointer space-x-2">
            <input type="checkbox" class="checkbox checkbox-primary rounded-sm checkbox-sm" [checked]='toggleNotfication || allowPushNotification'
              (change)="allowPushNotification = !allowPushNotification" />
            <span class="label-text">Enable push notifications</span>
          </label>
        </div>
      </div>
    </div>
    <hr />

    <!-- Change Password -->
    <div class="flex flex-col space-y-4 justify-between md:flex-row md:space-y-0 " *ngIf="currentUser.role !== 'Admin'">
      <!-- Description -->
      <div class="md:w-1/2 space-y-2  ">
        <p class="font-semibold text-base text-gray-600">
          Change Password
        </p>
      </div>

      <!-- toggle & edit -->
      <div class="flex md:w-full md:flex md:justify-end  align-middle space-x-8 items-baseline">
        <!-- toggle -->
        <div>
          <div class="form-control">
            <label class="label cursor-pointer">
              <button (click)="openChangePasswordModal()" 
              class="inline-block rounded border-2 border-brandColor text-brandColor px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-brandcolor transition duration-150 ease-in-out hover:border-brandcolor-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-brandcolor-600 focus:border-brandcolor-600 focus:text-brandcolor-600 focus:outline-none focus:ring-0 active:border-brandcolor-700 active:text-brandcolor-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
              data-te-ripple-init
             >Change Password</button>
            </label>
          </div>
        </div>
      </div>
      <hr>
    </div>
    
   
  
<br>
    <div class=" flex justify-end ">
      <button
      (click)="finish()" type="submit"
      class="inline-block rounded bg-brandColor px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
      Save Changes
    </button>
    </div>
  </div>

<!-- <app-alert class="fixed top-0 flex justify-end w-full" [toastType]="toastType" [toastMessage]="toastMessage"
[ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert> -->

</div>

<!-- Modals -->
<div id="my-modal-confirmation" *ngIf="confirmationModal" class="modal"
  [ngClass]="{'modal-open': confirmationModal, 'modal-action': !confirmationModal }">
  <div class="modal-box">
    <div class="modal-content">
      <h2 class="font-bold flex items-center justify-center mb-4">Save My Preference</h2>
      <h3 class=" text-lg text-center">Are you sure you want to Save Changes?</h3>
      <hr />
      <div class="modal-action items-center justify-between mx-6">
        <button (click)="closeModal()"
          class="text-customTeal px-4 py-2 rounded-md border-2 border-customTeal flex justify-center">Cancel</button>
        <button (click)="closeConfirmationModal()"
          class="text-white bg-customTeal px-4 py-2 rounded-md border-none flex justify-center">Save</button>
      </div>
    </div>
  </div>
</div>

<div id="my-modal-changePassword" class="modal" *ngIf="changePasswordModal"
  [ngClass]="{'modal-open': changePasswordModal, 'modal-action': !changePasswordModal }">

  <div class="modal-box max-w-4xl rounded-md shadow-md ease-out overflow-x-hidden ">
    <div class="modal-content">
      <h3 class="font-bold text-lg">Change Password</h3>
      <p class="text-base py-2 text-gray-500">Please input your New password and Confirm password.</p>
      <hr />

      <form [formGroup]="changePasswordForm" (ngSubmit)="changePassword(changePasswordForm.value)">
        <div class="flex  flex-wrap mt-3 ">

          <!-- previous password  -->
          <div class="flex space-y-2 w-full md:w-1/2 flex-col ">
            <label for=" " class="text-sm text-gray-500 font-bold "> Current Password <span
                class="text-red-500">*</span></label>
               
                <div class="relative mb-4 flex flex-wrap items-stretch">
                  <input
                    #passwordfield id="oldPassword" type="password" formControlName='oldPassword' required
                    autocomplete="off"
                    [ngClass]="{
                      'border-red-500': ((oldPassword.dirty || oldPassword.touched) && oldPassword.invalid),
                      '': !((oldPassword.dirty || oldPassword.touched) && oldPassword.invalid)
                    }" 
                    placeholder="Current Password "
                    class="relative m-0 block w-[1px] min-w-0 flex-auto rounded-l border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.35rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:focus:border-primary"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2" />

                 <span (click)="passwordfield.type=passwordfield.type=='password'?'text':'password'"
                 class="flex items-center whitespace-nowrap  fa fa-fw field-icon toggle-password rounded-r border border-l-0 border-solid border-neutral-300 pl-2 pr-6 py-[0.25rem] text-center text-base font-normal leading-[1.6] text-neutral-700 dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200"
                 id="basic-addon2"
                 [ngClass]="(passwordfield.type=='password')?' fa-eye-slash':' fa-eye '">
              </span>
                </div>


            <div *ngIf="(oldPassword.dirty || oldPassword.touched) && oldPassword.invalid">
              <small *ngIf="oldPassword.errors.required" class="text-red-500"> Current Password Is Required.
              </small>
              <small *ngIf="oldPassword.errors.minlength" class="text-red-500"> Password Length Must Be At Least 6
                Characters . </small>
              <small *ngIf="oldPassword.errors.maxlength" class="text-red-500"> Password Must Not Exceed 25
                Characters . </small>
            </div>
          </div>
          <div class="flex flex-col md:flex-row md:space-x-4 pt-6 w-full">
            <!-- new password  -->
          <div class="flex space-y-2 md:w-1/2 flex-col ">
            <label for=" " class="text-sm text-gray-500 font-bold "> New Password <span
                class="text-red-500">*</span></label>
            <div class="flex flex-row w-full">

              <div class="relative mb-4 flex w-full flex-wrap items-stretch">
                <input
                #passwordfield2 id="newPassword" type="password" formControlName='newPassword' required
                autocomplete="off"
                class="focus:invalid:border-red-500 border rounded py-3 px-3 my-2 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline w-full"
                placeholder="New Password" [ngClass]="{
                  'border-red-500': ((oldPassword.dirty || oldPassword.touched) && oldPassword.invalid),
                  '': !((oldPassword.dirty || oldPassword.touched) && oldPassword.invalid)
                }"
                  class="relative m-0 block w-[1px] min-w-0 flex-auto rounded-l border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.35rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:focus:border-primary"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2" />

               <span (click)="passwordfield2.type=passwordfield2.type=='password'?'text':'password'"
               class="flex items-center whitespace-nowrap  fa fa-fw field-icon toggle-password rounded-r border border-l-0 border-solid border-neutral-300 pl-2 pr-6 py-[0.25rem] text-center text-base font-normal leading-[1.6] text-neutral-700 dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200"
               id="basic-addon2"
               [ngClass]="(passwordfield2.type=='password')?' fa-eye-slash':' fa-eye'">
            </span>
              </div>
            </div>

            <div *ngIf="(newPassword.dirty || newPassword.touched) && newPassword.invalid">
              <small *ngIf="newPassword.errors.required" class="text-red-500"> New Password Is Required.
              </small>
              <small *ngIf="newPassword.errors.minlength" class="text-red-500"> Password Length Must Be At Least 6
                Characters . </small>
              <small *ngIf="newPassword.errors.maxlength" class="text-red-500"> Password Must Not Exceed 25
                Characters . </small>
            </div>
          </div>
          <!-- confirm password  -->
          <div class="flex space-y-2 md:w-1/2 flex-col ">
            <label for=" " class="text-sm text-gray-500 font-bold "> Confirm Password <span
                class="text-red-500">*</span></label>
            <div class="flex flex-row w-full">
              <div class="relative mb-4 flex w-full flex-wrap items-stretch">
                <input
                    #passwordfield3 id="Password" type="password" formControlName='confirmPassword' required
                    autocomplete="off"
                    class="focus:invalid:border-red-500 border rounded py-3 px-3 my-2 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline w-full"
                    id="Password " [ngClass]="{
                    'border-red-500': ((confirmPassword.dirty || confirmPassword.touched) && confirmPassword.invalid),
                    '': !((confirmPassword.dirty || confirmPassword.touched) && confirmPassword.invalid)
                  }" placeholder="Confirm Password "
                  class="relative m-0 block w-[1px] min-w-0 flex-auto rounded-l border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.35rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:focus:border-primary"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2" />

               <span (click)="passwordfield3.type=passwordfield3.type=='password'?'text':'password'"
               class="flex items-center whitespace-nowrap  fa fa-fw field-icon toggle-password rounded-r border border-l-0 border-solid border-neutral-300 pl-2 pr-6 py-[0.25rem] text-center text-base font-normal leading-[1.6] text-neutral-700 dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200"
               id="basic-addon2"
               [ngClass]="(passwordfield3.type=='password')?' fa-eye-slash':' fa-eye'">
            </span>
              </div>
            </div>

            <div *ngIf="(confirmPassword.dirty || confirmPassword.touched) && confirmPassword.invalid">
              <small *ngIf="confirmPassword.errors.required" class="text-red-500">Confirm Password is required. </small>
              <small *ngIf="confirmPassword.hasError('invalid')" class="text-red-500">Passwords Doesnt Match ! </small>
            </div>
          </div>
          </div>
        </div>
        <div
        class="flex flex-shrink-0 space-x-6 flex-wrap items-center justify-end rounded-b-md border-t-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
      <button
        type="button"
        (click)="closeChangePasswordModal()"
        class="inline-block rounded border-2 border-gray-200 px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
        data-te-ripple-init>
        cancel
      </button>
      <button
 
      type="submit" 
      class="inline-block rounded bg-brandColor px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
      data-te-ripple-init
      data-te-ripple-color="light">
      Save
    </button>
    <button type="button" *ngIf="reopenModal" (click)="passwordReset()"
      class="py-2 px-4 rounded-md text-red-500 border-none">Request password reset</button>
       
      </div>
      </form>
    </div>
    <!-- toast -->
    <!-- <app-alert class="fixed top-0 flex justify-end w-full" [toastType]="toastType" [toastMessage]="toastMessage"
      [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert> -->

  </div>
</div>

<div id="my-modal-requestModal" *ngIf="requestModal" class="modal"
  [ngClass]="{'modal-open': requestModal, 'modal-action': !requestModal }">
  <div class="modal-box">
    <div class="modal-content">
      <h3 class="font-bold text-lg text-center mb-2">Request Password Reset</h3>
      <hr />
      <p class="text-center mt-3"> Are you sure you want to Reset your password?</p>
      <p class="text-center my-3">If you request for a password reset the admin will be notified and it will be answered
        shortly.</p>

      <div class="flex justify-between mx-4 my-2 ">
        <label><button type="button" (click)="resetPassword()"
            class="py-2 px-4 rounded-md text-white bg-customTeal border-none">Yes</button></label>

        <label><button type="button" (click)="closePasswordReset()"
            class="py-2 px-4 rounded-md text-red-500 border-none">No</button></label>
      </div>
    </div>
  </div>
</div>

<div id="my-modal-disable" *ngIf="updateModal" class="modal"
  [ngClass]="{'modal-open': updateModal, 'modal-action': !updateModal }">
  <div class="modal-box">
    <div class="modal-content">
      <h3 class="font-bold text-lg text-center mb-2">Subscribers Password Update</h3>
      <hr />
      <p class="text-center mt-3"><span class="font-bold">{{loggedInUser.GivenName |
          titlecase}} </span> have successfully updated your password .</p>
    </div>
  </div>




  
</div>