<ng-container *ngIf="appSettings && appSettings.takeAppointment==true">
  <div *ngIf="service && serviceId" class="font-body min-h-screen max-h-max pt-1">
  <div class="flex flex-col md:flex-row m-4 md:m-12 gap-y-4 gap-x-6">
    <!-- Main Content -->
    <div class="flex flex-col p-4 bg-white rounded-md md:p-12 md:w-2/3 space-y-8 ">
        <p class="text-base md:text-xl font-bold "> Book an Appointment </p>
        <form [formGroup]="createAppointmentForm" (ngSubmit)="createModalOpen=true" action="" class="space-y-4">
          <!-- Service Name -->
          <div class="flex space-y-2  md:w-2/3 flex-col">
            <label for="services" class="text-xs text-gray-500 font-bold ">Service Name</label>
            <input  *ngIf="serviceUnder"  disabled class="input input-bordered w-full bg-veryLightGray" [value]="getInnerText(serviceUnder.serviceTitle)">
            <input *ngIf="service && !serviceUnder" disabled class="input input-bordered w-full bg-veryLightGray" [value]="getInnerText(service.serviceName)">
          </div>

          <!-- Select Employee -->
          <div class="flex space-y-2  md:w-2/3 flex-col">
            <label for="services" class="text-xs text-gray-500 font-bold ">Select an Employee</label>
            <select class="select select-bordered select-primary w-full  bg-veryLightGray" (change)="selectEmployee($event.target.value)">
                <option disabled selected>Employee Name</option>
                <option value = {{employee.uid}} *ngFor="let employee of allEmployees">{{employee.GivenName}} {{employee.LastName}}</option>
            </select>
          </div>

          <!-- Date and time -->
          <div>
            <div class="flex space-y-2  md:w-2/3 flex-col">
              <label for="services" class="text-xs text-gray-500 font-bold ">Date <span class="text-sm text-red-500">(Select employee first)</span></label>
              <button class="input input-bordered input-primary" type="button" (click)="chooseDateAndTimeClicked()"
              [disabled]="!selectedEmployee">Choose Date and Time</button>
            </div>
            <div *ngIf="selectedServiceTime || selectedServiceEndTime" class="flex space-y-2  md:w-2/3 flex-col">
              <label for="services" class="text-xs text-gray-500 font-bold ">Selected Time</label>
              <div>
                {{selectedServiceTime | date}}{{selectedServiceTime | date:'shortTime'}} - {{selectedServiceEndTime | date:'shortTime'}}
              </div>
              <br/>
            </div>
          </div>

          <!-- Message -->
          <div class="flex space-y-2 md:w-2/3   flex-col">
            <label for=" " class="text-xs text-gray-500 font-bold ">Message</label>
            <textarea formControlName="message" class=" py-3   h-24 resize-y input input-bordered input-primary">
            </textarea>
          </div>

          <!-- Save -->
          <div class=" flex justify-end w-full pt-12">
            <button [disabled]="!createAppointmentForm.valid"
                  class="py-2 px-10 modal-button gap-2  bg-customTeal rounded-lg text-white w-full"
                  [ngClass]="{'bg-[#cccccc] text-[#666666]': !createAppointmentForm.valid}">
              <span>Book</span>
            </button>
          </div>
        </form>
    </div>

    <!-- Sider Employee schedule -->
    <div class="flex flex-col bg-white rounded-md p-12 md:w-1/3 space-y-2">
        <p class="text-base text-center font-bold ">Select an Employee and a Date</p>
        <p class="text-center text-xs text-lightGray"> Schedule based on a given date of an employee showes here</p>
        <div class=" flex-row bg-white py-8 my-4 md:mx-6 w-full rounded-md md:flex-col">
          <div class="font-bold text-md mb-4 text-center"> Working Schedule</div>
          <div *ngIf="employeeSchedule">
            <div class="mb-2 mt-2 flex flex-row text-justify justify-center" *ngFor="let sched of employeeSchedule.schedule">
              <div *ngIf="!sched.unAvailable">
                <i class="material-icons w-3 h-3 pr-8 float-left"> access_time </i>
                {{sched.day}}, {{sched.startTime}} - {{sched.endTime}}
              </div>

            </div>
          </div>
          <div *ngIf="!employeeSchedule">
            <div *ngIf="employee" class="mb-2 mt-2 flex text-justify justify-center text-gray-400">
              {{employee.GivenName}} didn't add their schedule.</div>
          </div>
          <div class="flex justify-center" *ngIf="isAdmin && employee">
            <button [routerLink]="['/admin/employee-schedule', employee.uid]"
              class=" border cursor-pointer text-md rounded-lg py-2 px-2 ml:2 text-black font-bold text-md bg-white border-white justify-center md:text-left">
              <i class="material-icons material-symbols-outlined text-customTeal w-2 h-2 pr-6 float-left">add_circle</i>
                {{employeeSchedule.length > 0 ? 'Update Schedule' : 'Add Schedule'}}
            </button>
          </div>
        </div>
    </div>
  </div>
  </div>

</ng-container>
<div *ngIf="!appSettings || !appSettings.takeAppointment">
  <div class="  w-full flex flex-col space-y-4 text-center px-4 md:px-6 py-8">
    <div class="flex items-center justify-center">
      <img loading="lazy" data-src="../../../../assets/icons/Illustrations/Appointment-Illustration.png"
        class="block w-64 items-center justify-center">
    </div>
    <p class="text-customTeal font-bold text-xl ">Can't take appointments now.</p>
    <div>
      <button class="btn btn-outline border border-white text-white bg-customTeal px-10" (click)="goBack()">
        Go back
      </button>
    </div>
  </div>
</div>
<!-- Schedule Modal -->
<div id="scheduleModal" class="modal scroll" *ngIf="scheduleModalOpen"
[ngClass]="{'modal-open': scheduleModalOpen, 'modal-action': !scheduleModalOpen }"
>
  <div style="padding: 3%" class="modal-box md:max-w-6xl" >
    <div class="flex justify-end cursor-pointer" (click)="backClicked()"><i class="text-white bg-gray-300 px-4 py-2 rounded-full material-icons">close</i></div>
      <div class="row" *ngIf="employeeSchedule && employeeSchedule.schedule.length>0">
        <div class="text-center">
          <span class="text-center text-gray-700 text-lg font-bold">Click on available time slots to book an appointment</span>
        </div>
        <div class="flex flex-row mt-3">
          <div class="md:w-2/6 w-3/12 flex flex-col">
            <div class="">Available</div>
            <div class="segment available-segment"> </div>
          </div>
          <div class="md:w-2/6 w-1/12"></div>
          <div class="md:w-2/6 w-3/12 flex flex-col">
            <div class="">Unavailable</div>
            <div class="segment unavailable-segment"> </div>
          </div>

        </div>
        <br/><hr/><br/>
        <div class="flex justify-center flex-col">
          <div class="mb-3 w-full flex justify-center text-customTeal text-lg font-bold">
            {{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}
          </div>
          <div class="btn-group w-full justify-center">
            <button
              class="btn btn-primary"
              (click)="decrement()"
              [disabled]="prevBtnDisabled"
            > <i class="material-icons float-left mr-2">chevron_left</i>
              Previous
            </button>
            <button class="btn bg-white text-black border border-customTeal" (click)="today()">Today</button>
            <button
              class="btn btn-primary"
              (click)="increment()"
              [disabled]="nextBtnDisabled"
            >
              Next <i class="material-icons float-right ml-2">navigate_next</i>
            </button>
          </div>
        </div>
        <br/>
        <div class="row col s12 " >
          <div class="row col s12">
            <div [ngSwitch]="view">
              <mwl-calendar-week-view
              *ngSwitchCase="CalendarView.Week"
              [viewDate]="viewDate"
              [events]="events"
              [weekStartsOn]=[weekStartsOn]
              [refresh]="refresh"
              (eventClicked)="eventClicked($event)"
              (eventTimesChanged)="eventTimesChanged($event)"
              (hourSegmentClicked)="hourSegmentClicked($event)"
              (beforeViewRender)="beforeViewRender($event)"
              [daysInWeek]="daysInWeek"
              [hourSegments]="4"
              [dayStartHour]="minStartingTime"
              [dayEndHour]="maxEndingTime "
              >
              </mwl-calendar-week-view>
            </div>
          </div>
        </div>
        <br/>
        <div class="flex justify-center flex-col">
          <div class="mb-3 w-full flex justify-center text-customTeal text-lg font-bold">
            {{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}
          </div>
          <div class="btn-group w-full justify-center">
            <button
              class="btn btn-primary"
              (click)="decrement()"
              [disabled]="prevBtnDisabled"
            > <i class="material-icons float-left mr-2">chevron_left</i>
              Previous
            </button>
            <button class="btn btn-outline-secondary border border-customTeal" (click)="today()">Today</button>
            <button
              class="btn btn-primary"
              (click)="increment()"
              [disabled]="nextBtnDisabled"
            >
              Next <i class="material-icons float-right ml-2">navigate_next</i>
            </button>
          </div>
        </div><br/>
      </div>

      <div *ngIf="!employeeSchedule || employeeSchedule.schedule.length === 0 " class="row card-panel blue lighten-3"> <h6  class="white-text"> <strong>
          Employee Has No Schedule </strong>
        </h6>
      </div>

      <div class="float-right">
          <a class="btn-custom-solid-red" (click)="backClicked()"><i
            class="material-icons left">house</i> Cancel </a>
      </div>
  </div>


</div>

<!-- toast -->
<app-alert class="z-50 fixed top-0 flex justify-end w-full" [toastType]="toastType" [toastMessage]="toastMessage"
  [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>

  <!-- create Modal -->
<app-create-confirmation *ngIf="createModalOpen"  (cancelClicked)=closeCreateModal() (saveBtnClicked)=addAppointment(createAppointmentForm.value) createdFromComponent='Appointment' componentName="Appointment"></app-create-confirmation>


