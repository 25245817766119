import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounceIn } from 'ng-animate';
import { Location } from '@angular/common';
import { PlatformAnalyticsModel ,analysisPlatforms, analyticsPlatforms, platformColors} from 'src/app/models/SocialMediaPostModel';
import { AyrshareService } from 'src/app/services/social-media/ayrshare.service';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { ProfieKeyService } from 'src/app/services/social-media/profileKey.service';
import { icons } from 'src/app/models/SocialMediaIcons';


@Component({
    selector: 'app-social-media-analytics',
    templateUrl: './analytics.component.html',
    styleUrls: ['./analytics.component.css'], animations: [
        trigger('swing', [transition('* => *', useAnimation(bounceIn))])
    ],
})

export class SocialMediaAnalyticsComponent implements OnInit {

    constructor(
       
        private router: Router,
        private location: Location,
        private socialMediaService: AyrshareService,
        private activatedRoute: ActivatedRoute,
        private profieKeyService:ProfieKeyService


    ) {

    }
    profileKey
    privateKey
    userProfile
    // mediaAnalysisPlatforms=[...analysisPlatforms]
    mediaAnalysisPlatforms=[...analyticsPlatforms]

    analyticsPlatforms
    user
    socialMediaIcons=icons
    hasActiveAccounts = false
    loading
    platformAnalyticsmodel:PlatformAnalyticsModel = new PlatformAnalyticsModel()
    platformAnalytics
    selectedAnalytics
    selectedAnalyticsData
    objectKeys = Object.keys

    selectedPlatform
    // toast
    toastMessage: any;
    toastClass: any;
    toastType: any;
    openToast = false;
    ngOnInit() {
        this.openToast = false
        this.mediaAnalysisPlatforms.push('tiktok')
        this.profieKeyService.currentProfileKey.subscribe(p=>{
            this.profileKey=p
          }
          )
          this.getPlatformAnalytics()
        }


    getPlatformAnalytics() {

        if(this.profileKey){
            this.loading = true;
            this.socialMediaService.getUserByProfileKey(this.profileKey).toPromise()
            .then(data => {

                this.userProfile = data
                this.hasActiveAccounts = true ? data['activeSocialAccounts'] && data['activeSocialAccounts'].length > 0 : false
                if (this.hasActiveAccounts) {
                    this.loading = false
                    
                    if ( this.userProfile.displayNames.some(x=>x.platform=='linkedin' && x.type=='corporate')) this.mediaAnalysisPlatforms.push('linkedin')
                    
                    let analyticsPlatforms = this.getUserMediaPlatforms()

                    if(analyticsPlatforms.length>0){
                        this.platformAnalyticsmodel.platforms=analyticsPlatforms
                        this.platformAnalyticsmodel.profileKey=this.profileKey
                        this.socialMediaService.getSocialNetworkAnalytics(this.platformAnalyticsmodel).toPromise()
                        .then(a=>{
                            this.platformAnalytics = a
                            this.selectPlatform(analyticsPlatforms[0])
                           
                        }).catch(err=>{
                            console.log(err,'platform analytics error')
                            Object.keys(err.error).forEach(p=>{
                                if(err.error[p]['status'] == 'error'){
                                    this.toast({ html: `Analytics error:  ${p} - ${ err.error[p]['message']}. Please try reloading the page` , classes: 'red', type: 'failure' });
                                }
                            })
                         
                        })
                    }


                }
                this.loading = false;
            })
            .catch(err => {

                this.loading = false;


            })


        }


    }
    toast(obj) {

        this.toastMessage = obj.html;
        this.toastClass = obj.classes ? obj.classes : 'green';
        this.toastType = obj.type ? obj.type : 'success';
        this.openToast = true;
        setTimeout(() => {
          this.openToast = false;
        }, 10000);
      }


    selectPlatform(platform){
        this.selectedPlatform = platform
        this.selectedAnalytics=this.platformAnalytics[platform]
        this.selectedAnalyticsData= this.getPlatformData(platform,this.selectedAnalytics.analytics)
    }

    getUserMediaPlatforms(){
        return this.userProfile['activeSocialAccounts'].filter(s=>{
            return this.mediaAnalysisPlatforms.includes(s)
        })
    }

    getPlatformData(platform,analytics){
        let handler
        switch (platform) {
            case 'facebook':
                handler = this.getFacebookData
                break
            case 'twitter':
                handler = this.getTwitterData
                break
            case 'pinterest':
                handler = this.getPinterestData
                break
            case 'instagram':
                handler = this.getInstagramData
                break
            case 'linkedin':
                handler = this.getLinkedinData
                break
            case 'tiktok':
                handler = this.getTikTokData
                break
            case 'reddit':
                handler = this.getRedditData
                break
            case 'youtube':
                handler = this.getYoutubeData
                break
            case 'gmb':
                handler = this.getGmbData
                break

        }
        // return handler(analytics)
        return handler.bind(this)(analytics)
    }

    getBorder(){
        var classList='';
        switch (this.selectedPlatform) {
            case 'instagram':
                classList = 'instagram-wrapper wrapper--rounded'; 
                break
            case 'tiktok':
                classList = 'tiktok-wrapper wrapper--rounded'; 
                break
            default:
                classList = 'border-[' +platformColors[this.selectedPlatform] +']';
        }
        return classList;
    }

    getStyleBorder(){
        return {'border-color': platformColors[this.selectedPlatform], 'border-style': 'solid'}
    }

    borderdPlatform(){
        return ['instagram','tiktok'].includes(this.selectedPlatform) ? false : true
    }

    getGmbData(analytics){
        let content 
        let result = {
            color:"#4267B2",
            subText:'Views detail',
            middleSubText:true,
            username:this.userProfile.displayNames.filter(u=>u.platform =="gmb")[0].displayName,
            platform:"gmb",
            image:this.userProfile.displayNames.filter(u=>u.platform =="gmb")[0].userImage
        }
        if (analytics.code == 322){
            result['content'] ={
                "message":analytics.message,
                "showError":true 
            }

        }else{    
            result['content']= {
                 'View search':analytics.viewsSearch,
                 'View map':analytics.viewsMaps,
                 'Photo view customers':analytics.photosViewsCustomers,
                 'Photo view merchants':analytics.photosViewsMerchant,
             }
             result['userContent']={
                'Phone actions': analytics.actionsPhone,
                'Website actions':analytics.actionsWebsite,
                'Driving actions':analytics.actionsDrivingDirections
             }
        }
        return result
    }


    getRedditData(analytics){
        return {
            color:"#FF4500",
            subText:'Karma detail',
            middleSubText:true,
            content:
               {
                    'Karma awardee':analytics.awardeeKarma,
                    'Karma awarder':analytics.awarderKarma,
                    'Karma coment':analytics.commentKarma,
                    'Total karma':analytics.totalKarma,
                },
            // secondSubText:'User status',
            userContent: {
                'Employee': analytics.isGold ? 'Yes' :'No',
                'Gold member':analytics.isGold ? 'Yes' :'No',
                'Friend':analytics.isFriend ? 'Yes' :'No',
                },
            username:this.userProfile.displayNames.filter(u=>u.platform =="reddit")[0].displayName,
            platform:"reddit",
            image:analytics.profileImageUrl
        }
    }

    getYoutubeData(analytics){
        return {
            color:"#c4302b",
            subText:'',
            middleSubText:true,
            content:
               {
                'Comments':analytics.comments,
                'Dislikes':analytics.dislikes,
                'Likes':analytics.likes,                    
                },
            secondSubText:'Videos',
            subContent:
            {
                'Vides in playlist':analytics.videosAddedToPlaylists,
                'Videos count':analytics.videoCount,
            },
            userContent:
            {
                Subscribers:analytics.subscriberCount,
                'Subscribers lost':analytics.subscribersLost,
                'Subscribers gained':analytics.subscribersGained,
            },
            hasExtraContent:true,
            extraContent:{
                title:'Video views details',
                content:{
                    'Views':analytics.views,
                    'Average view duration':analytics.averageViewDuration,
                    'Average view percentage':analytics.averageViewPercentage,
                    'Estimated minutes watched':analytics.estimatedMinutesWatched,
                },
            },
            username:this.userProfile.displayNames.filter(u=>u.platform =="youtube")[0].displayName,
            platform:"youtube",
            image:this.userProfile.displayNames.filter(u=>u.platform =="youtube")[0].userImage
        }
    }

    getLinkedinData(analytics){
        return {
            color:"#0072b1",
            subText:'Page view',
            middleSubText:true,
            content:
               {
                    'Career page views':analytics.views.careersPageViews,
                    'Job pge view':analytics.views.jobsPageViews,
                    'People Page views':analytics.views.peoplePageViews,
                    'Products page views':analytics.views.productsPageViews,
                },
            secondSubText:'Daily fan page like',
            subContent:
            {
                Followers:analytics.followers.organicFollowerCount ? analytics.followers.organicFollowerCount : 0,
                'Career page clicks':analytics.clicks.careersPageClicks.careersPagePromoLinksClicks,
            },
            username:this.userProfile.displayNames.filter(u=>u.platform =="linkedin")[0].displayName,
            platform:"linkedin",
            image:this.userProfile.displayNames.filter(u=>u.platform =="linkedin")[0].userImage
        }
    }

    getFacebookData(analytics){
        return {
            color:"#4267B2",
            subText:'Past 500 post reaction',
            middleSubText:true,
            content:
               {
                    Engagment:(analytics && analytics.engagement)? analytics.engagement.count : '-',
                    Followers:analytics.followersCount,
                    Fan:analytics.fanCount,
                    Reactions:analytics.reactions ? analytics.reactions.total : '-'
                },
            secondSubText:'Daily fan page like',
            subContent:
            {
                Like:analytics.reactions.like,
                'Unique user like':analytics.followersCount,
            },
            username:analytics.name,
            platform:"facebook",
            image:this.userProfile.displayNames.filter(u=>u.platform =="facebook")[0].userImage
        }
    }
    getTwitterData(analytics){
        return {
            color:"#1da1f2",
            middleSubText:true,
            content:
               {
                    Favourites:analytics.favoritesCount,
                    'Listed count':analytics.listedCount,
                    Friends:analytics.friendsCount,
                },
            subContent:
            {
                Following:analytics.friendsCount,
                Followers:analytics.followersCount,
            },
            username:analytics.username,
            subText:'Past 500 post reaction',
            platform:"twitter",
            image:analytics.profileImageUrl
        }
    }
    getPinterestData(analytics){
        let daily_data = analytics.daily ? analytics.daily.reduce((a, b) => {
            return new Date(a.date) > new Date(b.date) ? a : b;
          }) : {}
        return {
            color:"#E60023",
            middleSubText:true,
            content:
               {
                    Engagment:analytics.engagement,
                    Impression:analytics.impression,
                    Save:analytics.save,
                    'Pin click':analytics.pinClick,
                }
            ,
            daily : Object.keys(daily_data).length > 0 ?{
                'Engagment rate':daily_data.metrics.ENGAGEMENT_RATE,
                'Outbound click rate':daily_data.metrics.OUTBOUND_CLICK_RATE,
                'Save rate':daily_data.metrics.SAVE_DATE,
                'Pin click rate':daily_data.metrics.PIN_CLICK_RATE,
            } : {},
            username:this.userProfile.displayNames.filter(u=>u.platform =="pinterest")[0].displayName,
            subText:'30 days summary and daily details',
            platform:"pinterest",
            image:this.userProfile.displayNames.filter(u=>u.platform =="pinterest")[0].userImage
        }
    }
    getInstagramData(analytics){
        return {
            color:"#C32889",
            middleSubText:true,
            content:
               {
                    'Profile View':analytics.profileViewsCount,
                    Reach:analytics.reachCount,
                    Impression:analytics.impressionsCount,
                },
            secondSubText:'Daily fan page like',
            subContent:
            {
                Likes:analytics.likeCount,
                Comments:analytics.commentsCount,
            },
            userContent:
            {
                Following:analytics.followsCount,
                Followers:analytics.followersCount,
                Posts:analytics.mediaCount,
            },
            hasExtraContent:true,
            extraContent:{
                title:'Contact reach Detail',
                content:{
                    'Email contacts':analytics.emailContactsCount,
                    'Phone call clicks':analytics.phoneCallClicksCount,
                    'Message clicks':analytics.textMessageClicksCount,
                    'Website clicks':analytics.websiteClicksCount,
                },
            },
            username:analytics.name,
            subText:'Past 500 post reaction',
            platform:"instagram"
        }
    }

    getTikTokData(analytics){
        return {
            color:"#014E4B",
            middleSubText:true,
            content:
               {
                Views:analytics.durationAverage,
                Likes:analytics.likeCountTotal,
                Comments:analytics.commentCountTotal,
                Share:analytics.shareCountTotal,
                }
            ,
            username:this.userProfile.displayNames.filter(u=>u.platform =="tiktok")[0].displayName,
            image:this.userProfile.displayNames.filter(u=>u.platform =="tiktok")[0].userImage,
            subText:'Past 500 post reaction',
            platform:"tiktok"
        }
    }

}
