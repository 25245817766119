<div class="row center-align">
  <h5><strong> My Inbox </strong></h5>
</div>

<div class="row">
  <div class="col l1"></div>
  <div class="col s12 l10">

    <ul class="row collection" *ngIf="messageList">
      <li class="collection-item valign-wrapper" *ngFor="let msg of messageList">

         <div class="col s2 m1 l1">
           <i class="material-icons red-text">mark_email_unread</i>
         </div>
         <div class="col s10 m11 l11">
           <p> <strong class="blue-text">{{msg.memberName}}</strong> <br>
            <span class="teal-text">{{msg.subject}}</span><br>
            <span class="truncate"><small>{{msg.body}} </small> </span>
           </p>
         </div>


      </li>
    </ul>

  </div>
  <div class="col l1 "></div>
</div>
