<div class="flex flex-wrap w-full mt-2 md:ml-8 items-center">

  <div
    class=" mx-4 my-5  bg-white rounded-lg flex place-content-center justify-center items-center shadow-lg w-full md:w-1/4 h-48">
    <button data-te-toggle="modal" data-te-target="#rightBottomModal" data-te-ripple-init data-te-ripple-color="light"
      type="button"
      class="flex flex-col space-y-2 items-center justify-center px-8 py-5 border border-dashed border-gray-300 rounded-md h-min">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
        class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round"
          d="M12 10.5v6m3-3H9m4.06-7.19l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z" />
      </svg>

      <p class="text-base font-bold ">New Project</p>
    </button>
  </div>
  <!-- Pending request -->
  <div class="mx-4 my-5 flex h-48 bg-white rounded-lg shadow-lg w-full md:w-1/4"
    *ngFor="let pending of pendingRequests; index as i">

    <div class="w-full">
      <div class="px-6 py-3 w-full">
        <span class="my-4 space-y-4" *ngIf="pending && pending.imgLink">
          <img loading="lazy"  [src]="pending.imgLink" width="102" height="92">
        </span>

        <div class="my-2 space-y-4 bg-gray-200 rounded-md shadow-sm px-4 py-4 w-min "
          *ngIf="pending && !pending.imgLink">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            class="w-7 h-7 stroke-gray-500">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
          </svg>
        </div>

        <p *ngIf="!pending.appName" class="font-bold text-base">{{pending.url}}</p>
        <p *ngIf="pending.appName" class="font-bold text-base">{{pending.appName}}</p>

        <div class="flex items-center space-x-1">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            class="w-3 h-3 stroke-gray-400">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244" />
          </svg>
          <button class="text-gray-400 text-sm hover:text-brandColor">{{pending.url}}.{{extension}}</button>
        </div>

      </div>
      <hr>
      <div class=" flex justify-between px-6 py-3 items-center">


        <div class="flex items-center space-x-2 ">
          <div class="rounded-full bg-orange-600 font-bold text-sm w-2 h-2">
          </div>
          <p class="font-bold text-sm text-orange-600">{{pending.status}}</p>
        </div>

        <div class="flex items-center space-x-1">
          <button href="" (click)="openUpdateRequestModal(pending)" data-te-toggle="modal"
            data-te-target="#updateAppDetailModal" data-te-ripple-init data-te-ripple-color="light" type="button"
            class="text-sm font-semibold">Edit Detail</button>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="w-3 h-3">
            <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
          </svg>

        </div>


      </div>

    </div>



  </div>
  <!-- accepted request -->
  <div class="mx-4 my-5 h-48 bg-white rounded-lg shadow-lg w-full md:w-1/4"
    *ngFor="let accepted of acceptedRequests; index as i">
    <div>
      <div class="px-6 py-3">
       <div class="flex justify-between items-center">
        <div>
          <span class="my-4 space-y-4" *ngIf="accepted && accepted.imgLink">
            <img loading="lazy" [src]="accepted.imgLink" width="102" height="92">
          </span>

          <div class="my-2 space-y-4 bg-gray-200 rounded-md shadow-sm px-4 py-4 w-min "
            *ngIf="accepted && !accepted.imgLink">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              class="w-7 h-7 stroke-gray-500">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
            </svg>
          </div>
        </div>
        <div class="flex items-center space-x-1 border border-solid border-brandColor rounded-md px-6 py-2">
          <a   (click)="UpgradeAPP(accepted.mobileNumber,accepted.url, accepted.savedAcceptedRequest);"
            class="text-sm font-semibold text-brandColor">Upgrade</a>
        </div>

       </div>
        <p *ngIf="!accepted.appName" class="font-bold text-base">{{accepted.url}}</p>
        <p *ngIf="accepted.appName" class="font-bold text-base">{{accepted.appName}}</p>

        <div class="flex items-center space-x-1">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            class="w-3 h-3 stroke-blue-600">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244" />
          </svg>
          <button (click)="navigateToURL(accepted.url)" class="text-blue-500 text-sm hover:text-brandColor underline">
            {{accepted.url}}.{{extension}}
          </button>
        </div>

      </div>
      <hr>
      <div class=" flex justify-between px-6 py-3 items-center">


        <div class="flex items-center space-x-2 ">
          <div class="rounded-full bg-green-600 font-bold text-sm w-2 h-2">
          </div>
          <p class="font-bold text-sm text-green-600">Online</p>
        </div>

       

        <div class="flex items-center space-x-1">
          <button data-te-toggle="modal" data-te-target="#appDetailModal" data-te-ripple-init
            data-te-ripple-color="light" (click)="openDetailInformation(accepted)" type="button"
            class="text-sm font-semibold">Details</button>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="w-3 h-3">
            <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
          </svg>

        </div>


     




      </div>

    </div>


  </div>

</div>

<div id="my-modal-users" *ngIf="openDetailModal" class="modal"
  [ngClass]="{'modal-open': openDetailModal, 'modal-action': !openDetailModal }">
  <div class="modal-box max-w-4xl">
    <div class="modal-content">
      <div (click)="closeDetailInformation()" class="flex justify-end cursor-pointer"><i
          class="material-icons px-3 py-3 bg-gray-200 rounded-full">close</i></div>
      <h3 class="font-bold text-xl text-center mb-1">Application Details</h3>
      <hr />
      <div class="flex justify-between mt-6 mb-6">
        <p> <b>URL: </b> <button class="text-blue-500 italic text-md" (click)="navigateToURL(savedAcceptedRequest.url)">
            {{savedAcceptedRequest.url}}.{{extension}}</button></p>
        <p></p>
      </div>


      <hr />
      <h3 class="text-gray-500 text-md text-center my-6">Application Detail provides comprehensive information about
        your application, including SMS, email, shared social media, and ChatGPT counts. It offers a detailed overview
        to help you make informed decisions and optimize your application.</h3>

      <hr />

      <div class="mx-2 lg:mx-8 mt-10" *ngIf="!noCount">
        <div class="w-full flex justify-between lg:w-full bg-white rounded-lg mr-2 lg:mr-10">

          <div class="flex w-1/2">
            <canvas baseChart width="100" height="50" [data]="doughnutChartData" [labels]="doughnutChartLabels"
              [chartType]="doughnutChartType" [colors]="doughnutChartColors" [options]="doughnutChartOptions">
            </canvas>
          </div>



          <div>
            <span class="flex flex-col justify-center items-center mt-2">
              <div *ngIf="SMSCounted >= 0"> <b>SMS:- </b>{{SMSCounted}} out of 100 </div>
              <div *ngIf="EmailCounted >= 0"><b>Email:- </b> {{EmailCounted}} out of 100 </div>
              <div *ngIf="ChatGptCounted >= 0"> <b>ChatGpt:- </b> {{ChatGptCounted}} out of 100 </div>
              <div *ngIf="SocialMediaCounted >= 0 "><b>Social media:-</b> {{SocialMediaCounted}} out of 100 </div>
            </span>
          </div>
        </div>

      </div>

      <div *ngIf="noCount">
        <span class="flex flex-col justify-center items-center mt-2">
          <div *ngIf="SMSCounted >= 0"> <b>SMS:- </b>{{SMSCounted}} out of 100 </div>
          <div *ngIf="EmailCounted >= 0"><b>Email:- </b> {{EmailCounted}} out of 100 </div>
          <div *ngIf="ChatGptCounted >= 0"> <b>ChatGpt:- </b> {{ChatGptCounted}} out of 100 </div>
          <div *ngIf="SocialMediaCounted >= 0 "><b>Social media:-</b> {{SocialMediaCounted}} out of 100 </div>
        </span>
      </div>

    </div>

    <div class="form-control flex flex-row gap-x-5 my-4">
      <button type="button" (click)="closeDeleteForAccAppsModal()"
        class="py-2 px-12 modal-button gap-2  rounded-lg text-black w-full">
        Cancel
      </button>
      <button type="button" (click)="continueDeleteForAccAppsModal()"
        class="py-2 px-12 modal-button gap-2  bg-red-500 rounded-lg text-white w-full">
        Delete App Setup
      </button>
    </div>

  </div>
</div>

<!-- update modal -->

<!-- <div type="checkbox" *ngIf="updateRequestModal" class="modal"
  [ngClass]="{'modal-open': updateRequestModal, 'modal-action': !updateRequestModal }">
  <div class="modal-box max-w-3xl">
    <div class="modal-content">


      <h3 class="font-bold text-lg text-center mb-1">Update App</h3>
      <hr />

      <div class="px-4 md:px-14 py-5 flex justify-center ">
        <form [formGroup]="updateAppForm" (ngSubmit)="updateApp(updateAppForm.value)">
          <div class="flex flex-col bg-white">

            <div class="space-y-6 mt-8">
              <p class="font-bold text-customTeal">{{isNationApp ? 'App' : 'Company'}} Information</p>
              <div class="flex flex-col md:flex-row gap-y-4 gap-x-12">

                <div class="flex flex-col w-full ">
                  <div class="">
                    <span class="label-text text-[#4F4F4F] text-base font-bold leading-5">Website URL<span
                        class="text-red-500">*</span></span>
                    <div class="input-field flex md:1/2 w-full">
                      <input id="url" type="text" formControlName='urlUpdate' required
                        class="input input-bordered w-full md:w-full" placeholder="Website url" />
                      <div class="bg-customTeal text-center w-full md:w-full pt-2 rounded-r-md text-white">
                        .{{extension}}</div>
                    </div>
                    <div class="w-full" *ngIf="(urlUpdate?.dirty || urlUpdate?.touched) && urlUpdate?.invalid">
                      <small *ngIf="urlUpdate?.errors.required" class="text-red-500 italic">Website is required.
                      </small>
                      <small *ngIf="urlUpdate?.hasError('urlExists')" class="text-red-500 italic">Website is used try
                        another. </small>
                      <div *ngIf="urlUpdate?.hasError('webURL')" class="text-red-500 italic">
                        Should start with a letter, allowed inputs are lowercase letters,<br />
                        digit hyphen or hyphen and should have a total length of 6 chars.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr>
            </div>

            <div class="flex flex-row justify-between w-full max-w-x pt-10 p-b10">
              <div class="form-control flex flex-row gap-x-5 ">
                <button type="button" (click)="closeModal()" class="text-black font-bold rounded-lg w-full">
                  Cancel
                </button>


              </div>
              <div class="form-control flex flex-row gap-x-5 ">
                <button type="button" (click)="deleteModal()"
                  class="py-2 px-12 modal-button gap-2  bg-red-500 rounded-lg text-white w-full">
                  Delete
                </button>
                <button type="button" (click)="submitClicked('update','updateApp')" [disabled]="!updateAppForm.valid"
                  class="py-2 px-12 modal-button gap-2  bg-customTeal rounded-lg text-white w-full"
                  [ngClass]="{'!bg-[#cccccc] !text-[#666666]': !updateAppForm.valid}">
                  Update
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div> -->


<!-- confirmation modal for deletion of app request for a user -->

<div *ngIf="showConfirmationModal" class="modal z-[2300]"
  [ngClass]="{'modal-open': showConfirmationModal, 'modal-action': !showConfirmationModal }">
  <div class="modal-box w-full z-[2000] rounded-md">
    <div class="modal-content">
      <h3 class="font-bold text-lg px-4 pt-2 pb-3">Are you sure you want to delete your app request?</h3>
      <hr>
      <div class="px-4 text-sm py-2">
        <p> Please note that this app will be removed and that this process can't be reversed.</p>
      </div>
      <div class="flex flex-row justify-between w-full max-w-x py-4">
        <div class="form-control flex flex-row gap-x-5 ">
          <button type="button" (click)="closeDeleteModal()"
            class="inline-block rounded border-2 border-gray-200 px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10">
            Cancel
          </button>
        </div>
        <div class="form-control flex flex-row gap-x-5 ">
          <button (click)="continueDeleteModal()" type="button"
            class="flex bg-red-500 rounded items-center space-x-2 px-4 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            data-te-ripple-init data-te-ripple-color="light">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 fill-white ml-2" height="1em" viewBox="0 0 384 512">
              <path
                d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
            </svg>
            <span>
              Delete App
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- create app -->
<div data-te-modal-init
  class="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
  id="rightBottomModal"
  tabindex="-1"
  aria-labelledby="rightBottomModalLabel"
  aria-hidden="true">
  <div
    data-te-modal-dialog-ref
    class="pointer-events-none absolute bottom-7 md:right-7 h-auto w-full translate-x-[100%] opacity-0 flex justify-end transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:max-w-[500px]">
    <div
      class="min-[576px]:max-w-[700px] w-full md:w-3/4 min-[576px]:shadow-[0_0.5rem_1rem_rgba(#000, 0.15)] pointer-events-auto relative flex w-full md:w-1/2 justify-end flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
      <div
        class="flex flex-shrink-0 items-center justify-between rounded-t-md bg-brandColor p-4 dark:border-b dark:border-neutral-500 dark:bg-transparent">
        <h5 class="text-xl font-medium leading-normal text-white" id="rightBottomModalLabel">
          Create App
        </h5>
        <button type="button"
          class="box-content rounded-none border-none text-white hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
          data-te-modal-dismiss aria-label="Close" id="createAppClose">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="h-6 w-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      <div class="relative grid flex-auto  p-4" data-te-modal-body-ref>

        <div class="pl-6">
          <form [formGroup]="createAppForm" (ngSubmit)="createApp(createAppForm.value)">

            <div class="flex flex-col gap-y-2 md:w-3/4 w-full">
              <div class="">
                <span class="label-text text-[#4F4F4F] text-base font-semibold leading-5">Website URL<span
                    class="text-red-500">*</span></span>
                <div class="input-field flex w-full pt-2">
                  <div class="relative mb-4 w-full flex flex-wrap items-stretch">
                    <input id="url" type="text" formControlName='url' required type="text"
                      class="relative m-0 block w-[1px] min-w-0 flex-auto rounded-l border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.35rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-brandColor  focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:focus:border-brandColor "
                      placeholder="myappname" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                    <span
                      class="flex items-center whitespace-nowrap rounded-r border border-l-0 border-solid border-neutral-300 px-3 py-[0.25rem] text-center text-base font-normal leading-[1.6] text-neutral-700 dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200">.{{extension}}</span>
                  </div>

                </div>
                <div class="w-full" *ngIf="(url?.dirty || url?.touched) && url?.invalid">
                  <small *ngIf="url?.errors.required" class="text-red-500 italic">Website is required. </small>
                  <small *ngIf="url?.hasError('urlExists')" class="text-red-500 italic">Website is used try another.
                  </small>
                  <div *ngIf="url?.hasError('webURL')" class="text-red-500 italic text-sm">
                    Should start with a letter, allowed inputs are lowercase letters,<br />
                    digit hyphen or hyphen and should have a total length of 2 chars.
                  </div>
                </div>
              </div>
              <!-- Message -->
            </div>

            <br /><br />
            <div
            class="flex flex-shrink-0 flex-wrap items-center justify-end rounded-b-md border-t-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
            <button
            type="button"
            class="ml-1 inline-block rounded bg-primary-100 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:bg-primary-accent-100 focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200"
            data-te-modal-dismiss
            data-te-ripple-init
            data-te-ripple-color="light"
            id="createApp"
            >
            Close
          </button>
          <button
          (click)="submitClicked('create', 'createApp')"
          type="button" [disabled]="!createAppForm.valid"
          class="inline-block rounded bg-brandColor px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          data-te-ripple-init
          data-te-ripple-color="light"
          [ngClass]="{'!bg-[#cccccc] !text-[#666666]': !createAppForm.valid}">
          Submit
        </button>        
          </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- App Detail -->
<div data-te-modal-init
  class="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
  id="appDetailModal" tabindex="-1" aria-labelledby="appDetailModalLabel" aria-hidden="true">
  <div data-te-modal-dialog-ref
    class="pointer-events-none absolute bottom-7 md:right-7 h-auto w-full translate-x-[100%] opacity-0 flex justify-end transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:max-w-[700px]">
    <div
      class="min-[576px]:shadow-[0_0.5rem_1rem_rgba(#000, 0.15)] pointer-events-auto relative flex w-full md:w-1/2 justify-end flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
      <div
        class="flex flex-shrink-0 items-center justify-between rounded-t-md bg-brandColor p-4 dark:border-b dark:border-neutral-500 dark:bg-transparent">
        <h5 class="text-xl font-medium leading-normal text-white" id="rightBottomModalLabel">
          App Details
        </h5>
        <button type="button"
          class="box-content rounded-none border-none text-white hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
          data-te-modal-dismiss aria-label="Close">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="h-6 w-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>

      <div class="relative grid flex-auto  p-4" data-te-modal-body-ref>

        <div class="pt-4 space-y-4">
          <p class="text-base font-bold px-2"> Basic app detail</p>

          <div class="flex justify-between px-2">
            <div class="flex flex-col space-y-2 " *ngIf="savedAcceptedRequest">
              <p> <span class=" text-gray-600">URL: </span> <button
                  class="text-blue-500 underline font-bold  italic text-md"
                  (click)="navigateToURL(savedAcceptedRequest.url)">
                  {{savedAcceptedRequest.url}}.{{extension}}</button></p>
              <p></p>
              <div class="flex space-x-2">
                <p class=" text-gray-600">Status</p>
                <div class="flex items-center space-x-2 ">
                  <div class="rounded-full bg-green-600 font-bold text-sm w-2 h-2">
                  </div>
                  <p class="font-bold text-sm text-green-600">Online</p>
                </div>
              </div>
            </div>
          </div>


          <div class="mx-2" *ngIf="!noCount">
            <p class="text-base font-bold"> Data usage analysis</p>
            <div class="w-full flex  lg:w-full rounded-lg mr-2 ">

              <div>
                <span class="flex flex-col mt-2">
                  <div *ngIf="SMSCounted >= 0"> SMS:- {{SMSCounted}} out of 100 </div>
                  <div *ngIf="EmailCounted >= 0">Email:- {{EmailCounted}} out of 100 </div>
                  <div *ngIf="ChatGptCounted >= 0"> ChatGpt:- {{ChatGptCounted}} out of 100 </div>
                  <div *ngIf="SocialMediaCounted >= 0 ">Social media:- {{SocialMediaCounted}} out of 100</div>
                </span>
              </div>
              <div class="flex w-1/2 ">
                <canvas baseChart width="100" height="50" [data]="doughnutChartData" [labels]="doughnutChartLabels"
                  [chartType]="doughnutChartType" [colors]="doughnutChartColors" [options]="doughnutChartOptions">
                </canvas>
              </div>



            </div>
          </div>

          <div *ngIf="noCount" class="">
            <span class="flex flex-col mt-2">
              <div *ngIf="SMSCounted >= 0"> <b>SMS:- </b>{{SMSCounted}} out of 100 </div>
              <div *ngIf="EmailCounted >= 0"><b>Email:- </b> {{EmailCounted}} out of 100 </div>
              <div *ngIf="ChatGptCounted >= 0"> <b>ChatGpt:- </b> {{ChatGptCounted}} out of 100 </div>
              <div *ngIf="SocialMediaCounted >= 0 "><b>Social media:-</b> {{SocialMediaCounted}} out of 100 </div>
            </span>
          </div>
          <div class="flex justify-between items-center py-2">

           

            <div class="flex justify-end h-min">
              <button (click)="deleteModal()" type="button" [disabled]="!createAppForm.valid"
                class="flex bg-red-500 rounded items-center px-4 space-x-2 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                data-te-ripple-init data-te-ripple-color="light">
                <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 fill-white ml-2" height="1em"
                  viewBox="0 0 384 512">
                  <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                  <path
                    d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                </svg>
                <span> Delete App</span>
              </button>
            </div>
            <div
              class="flex flex-shrink-0 flex-wrap items-center justify-end rounded-b-md border-t-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
              <button type="button"
                class="inline-block rounded border-2 border-gray-200 px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                data-te-modal-dismiss data-te-ripple-init data-te-ripple-color="light">
                Close
              </button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>


<!-- update App Detail -->
<div data-te-modal-init *ngIf="updateRequestModal"
  class="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
  id="updateAppDetailModal" tabindex="-1" aria-labelledby="updateAppDetailModalLabel" aria-hidden="true">
  <!-- <div class="modal-box max-w-3xl"> -->
  <div data-te-modal-dialog-ref
    class="pointer-events-none absolute bottom-7 md:right-7 h-auto w-full translate-x-[100%] opacity-0 flex justify-end transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:max-w-[500px]">
    <div
      class="min-[576px]:max-w-[700px] w-full md:w-3/4 min-[576px]:shadow-[0_0.5rem_1rem_rgba(#000, 0.15)] pointer-events-auto relative flex w-full md:w-1/2 justify-end flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
      <div
        class="flex flex-shrink-0 items-center justify-between rounded-t-md bg-brandColor p-4 dark:border-b dark:border-neutral-500 dark:bg-transparent">
        <h5 class="text-xl font-medium leading-normal text-white" id="updateAppDetailModalLabel">
          Update App Details
        </h5>
        <button type="button"
          class="box-content rounded-none border-none text-white hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
          data-te-modal-dismiss aria-label="Close">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="h-6 w-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>

      <div class="relative grid flex-auto " data-te-modal-body-ref>
        <div class="px-4 md:px-6 py-4 flex w-full ">
          <form [formGroup]="updateAppForm" (ngSubmit)="updateApp(updateAppForm.value)" class="w-full">
            <div class="flex flex-col  w-full">

              <div class="space-y-4 mt-4 w-full ">
                <div class="flex flex-col md:flex-row ">

                  <div class="flex flex-col w-full ">

                    <div class="">
                      <span class="label-text text-[#4F4F4F] text-base font-semibold leading-5">Website URL<span
                          class="text-red-500">*</span></span>
                      <div class="input-field flex w-full pt-2">
                        <div class="relative mb-4 w-full flex flex-wrap items-stretch">
                          <input id="url" type="text" formControlName='urlUpdate' required
                            class="input input-bordered w-full md:w-full" placeholder="Website url"
                            class="relative m-0 block w-[1px] min-w-0 flex-auto rounded-l border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.35rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-brandColor  focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:focus:border-brandColor "
                            aria-label="Website URL" aria-describedby="basic-addon2" autocomplete="off" />
                          <span
                            class="flex items-center whitespace-nowrap rounded-r border border-l-0 border-solid border-neutral-300 px-3 py-[0.25rem] text-center text-base font-normal leading-[1.6] text-neutral-700 dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200"
                            id="basic-addon2">.{{extension}}</span>
                        </div>

                      </div>
                      <div class="w-full" *ngIf="(urlUpdate?.dirty || urlUpdate?.touched) && urlUpdate?.invalid">
                        <small *ngIf="urlUpdate?.errors.required" class="text-red-500 text-sm italic">Website is
                          required.
                        </small>
                        <small *ngIf="urlUpdate?.hasError('urlExists')" class="text-red-500 text-sm italic">Website is
                          used try
                          another. </small>
                        <div *ngIf="urlUpdate?.hasError('webURL')" class="text-red-500 italic">
                          Should start with a letter, allowed inputs are lowercase letters,<br />
                          digit hyphen or hyphen and should have a total length of 6 chars.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr>
              </div>



              <div class="flex flex-row justify-between w-full max-w-x pt-10 p-b10">
                <div class="form-control flex flex-row gap-x-5 ">

                  <button (click)="deleteModal()" type="button"
                    class="flex bg-red-500 rounded items-center space-x-2 px-4 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                    data-te-ripple-init data-te-ripple-color="light">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 fill-white ml-2" height="1em"
                      viewBox="0 0 384 512">
                      <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                      <path
                        d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                    </svg>
                    <span>
                      Delete App
                    </span>
                  </button>
                </div>

                <div class="form-control flex flex-row gap-x-5 ">
                  <button type="button" data-te-modal-dismiss aria-label="Close" id="updateApp"
                    class="inline-block rounded border-2 border-gray-200 px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10">
                    Cancel
                  </button>
                  <button type="button" [disabled]="!updateAppForm.valid" (click)="submitClicked('update','updateApp')"
                    class="inline-block rounded bg-brandColor px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                    [ngClass]="{'!bg-[#cccccc] !text-[#666666]': !updateAppForm.valid}">
                    Update
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<app-alert class="fixed top-0 flex z-[2300] justify-center w-full" [toastType]="toastType" [toastMessage]="toastMessage"
  [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>

<div class="modal modal-open" id="newAppCreated" *ngIf="appCreatedModal">
  <div class="modal-box rounded-md">
    <label for="my-modal-4" class="cursor-pointer ">
      <label class="relative flex flex-col justify-center" for="">
        <div class="w-full flex justify-center">
        </div>
        <h3 class="text-xl font-bold text-brandColor px-4 py-4">You've added a new app to the list</h3>
        <hr>
        <p class="py-4 px-4 text-base text-gray-500">
          Thank you for submitting your application. Our team is currently in the process
          of reviewing it. Please keep an eye on your inbox for an email form us once it has been approved.
        </p>
        <div class="w-full flex justify-center">
        </div>
      </label>
    </label>

    <div>
      <button type="button" (click)="appCreatedModal=false"
        class="py-2 px-12 modal-button gap-2  bg-brandColor rounded-lg text-white w-full">
        Done
      </button>
    </div>
  </div>
</div>

<app-feedback [userLogged]="currentUser" *ngIf="feedbackModal" (cancelSendClicked)="closeFeedbackModal()"
  (sendClicked)="onSendClicked($event)"> </app-feedback>

<app-confirmation-modal *ngIf="confirmationModal" [confirmationModal]="true"
  text="Are you sure you want to create a new App?" [url]=createAppForm.value.url
  (closeModal)="confirmationModal = false" (saveButton)="createApp(createAppForm.value)"></app-confirmation-modal>
<app-confirmation-modal *ngIf="updateModal" [confirmationModal]="true"
  text="Are you sure you want to update the detail of this App?" [url]=createAppForm.value.url
  (closeModal)="updateModal = false" (saveButton)="updateApp(updateAppForm.value)"></app-confirmation-modal>