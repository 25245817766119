

<br/>
<div class="row center-align">
  <h6> <strong> Polls List</strong></h6>
</div>

<div class="row col s12">
  <app-firebase-loading *ngIf="showLoader"></app-firebase-loading>
  <div class="col l1"></div>
  <div class="col s12 l10">

    <div class="row card-panel white col s12" *ngIf="!model && !showLoader">
      <p class="center">  <strong class="red-text"> <i class="material-icons" >warning</i> 0 Survey /Poll Found In the Database</strong> </p>
    </div>

<div *ngIf="model">
  <div class="row card-panel white" *ngFor="let item of model; let i=index;">
    <!-- <button class="btn" (click)="openPdf(i)">PDf</button> -->
    <div class="col s12">
      <div class="row">
        <!-- <a [routerLink]="['/admin/edit-survey', item.id]" class="truncate">{{item.name}}</a> -->
        <h6 class="truncate"><strong>{{item.name}}</strong> </h6>
      </div>
      <ul class="row collection">
        <li class="row collection-item">
            <div class="col s4"> <strong class="black-text"> End Date : </strong></div>
            <div class="col s8  red-text"> <strong class="right"> {{item.endDate}} </strong></div>
        </li>
      </ul>
      <!-- <div class="col s4"> -->
            <!-- <span>Ends In 3 Days</span> -->
            <!-- <span class="col s12">{{status[i].endAt}}</span>
            <span *ngIf="status[i].open" class="green-text col s12">OPEN</span>
            <span *ngIf="!status[i].open" class="red-text col s12">CLOSED</span>
      </div> -->
    </div>
    <div class="row">
      <div class="col s6">
        <a  class="center col btn-flat s12 blue lighten-4" [routerLink]="['/admin/survey-result', item.id]"> <span style="cursor: pointer;"><i class="material-icons black-text" style="margin-right: 7px;">poll</i> <strong
          class="black-text">Live Results</strong></span> </a>
      </div>
      <div class="col s6">
        <a  class="center btn-flat col s12 green lighten-4" [routerLink]="['/admin/edit-survey', item.id]"><span  style="cursor: pointer;"><i class="center material-icons black-text" style="margin-right: 7px;">edit</i><strong
          class="center black-text">Edit Poll</strong> </span></a>

      </div>


    </div>
  </div>
</div>



<div class="section"></div>

  <a [routerLink]="['/admin/create-poll']" style="margin-bottom: 10px;" class="col s12 btn waves-effect green">
  New Poll <i class="material-icons right">add</i></a>
      <button [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backClicked()">
        <i class="material-icons left">house</i> Back To Home Page
      </button>

  </div>
  <div class="col l1"></div>
</div>

