import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiKeyManagerService } from '../api/apiKeyManager.Service';
import { AngularFirestore } from '@angular/fire/compat/firestore';


@Injectable({
  providedIn: 'root'
})
export class OAuthcodeService {

  constructor(private httpClient: HttpClient, private keyManager: ApiKeyManagerService, private db: AngularFirestore) { }

  addOauthcode(model) {

    const newContent = {
      id: "code",
      code: model.code
    }

    return this.db.collection('OauthCode').doc(newContent.id).set(newContent);


  }

  getOauthByCode(code) {
    return this.db.collection('OauthCode', ref => ref.where('code', '==', code));
  }

  getOauthcode() {
    return this.db.collection('OauthCode');

  }

  updateOauthcode(newCode: any) {
    let code = "code"

    const docRef = this.db.collection('OauthCode').doc(code);
    return docRef.get().subscribe((doc) => {
      if (doc.exists) {
        docRef.update({
          code: newCode,
        });
      } else {
        docRef.set({
          code: newCode,
        });
      }
    });
  }



  // terms of service link

  addTos(model) {

    const newContent = {
      id: "link",
      link: model.link
    }

    return this.db.collection('TOSLink').doc(newContent.id).set(newContent);


  }

  getTos(link) {
    return this.db.collection('TOSLink', ref => ref.where('link', '==', link));
  }

  getTosLink() {
    return this.db.collection('TOSLink');

  }

  updateTos(newLink: any) {
    let link = "link"

    const docRef = this.db.collection('TOSLink').doc(link);
    return docRef.get().subscribe((doc) => {
      if (doc.exists) {
        docRef.update({
          link: newLink,
        });
      } else {
        docRef.set({
          link: newLink,
        });
      }
    });
  }


  // account id to be created

  addAccountId(model) {

    const newContent = {
      id: "accountId",
      accountId: model.accountId
    }

    return this.db.collection('AccountIdTobeCreated').doc(newContent.id).set(newContent);


  }

  getAccountId(accountId) {
    return this.db.collection('AccountIdTobeCreated', ref => ref.where('accountId', '==', accountId));
  }

  getAccountIdObj() {
    return this.db.collection('AccountIdTobeCreated');

  }

  updateAccountId(newAccId: any) {
    let accountId = "accountId"

    const docRef = this.db.collection('AccountIdTobeCreated').doc(newAccId);
    return docRef.get().subscribe((doc) => {
      if (doc.exists) {
        docRef.update({
          accountId: newAccId,
        });
      } else {
        docRef.set({
          accountId: newAccId,
        });
      }
    });
  }


}
