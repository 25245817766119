<div class="section hide-on-med-and-down"></div>

<div class="row col s12">
  <div class="col l1 s0 hide-on-med-and-down" *ngIf="currentUser"></div>
  <div class="col s12">

    <div class="row card-panel white" style="padding: 0;">


      <!-- Right column - messaging area -->
      <div class="col s12 l8 right-col-list" style=" min-height: inherit; box-shadow: none;">

        <!-- If a private chat is selected -->
        <div *ngIf="selectedConver" class="chat-selected ">
          <div class="row" style="margin-bottom: 0;" >
            <div class="back-arrow col s1 hide-on-large-only	" (click)="backClicked()">
              <span class="material-icons">
                arrow_back_ios_new
              </span>
            </div>
            <div class="profile-pic col s2 l1">

              <img loading="lazy" class="avatar" alt="User Profile Photo" [src]="selectedConver.user_2.profileImgLink || imgLink">
            </div>
            <div class="chat-details col s7 l10">
              <div class="" style="margin-top: 0.4rem;">{{selectedConver.user_2.GivenName ||
                selectedConver.user_2.FirstName}}
                {{selectedConver.user_2.LastName}}</div>
            </div>
            <div class="chat-details  btn-flat col s2 l1 dropdown-trigger " href='#' data-target='private-chat-options'>
              <span class="material-icons">
                more_vert
              </span>
            </div>
            <!-- options for private chats -->
            <ul id='private-chat-options' class='dropdown-content'>
              <li><a class="waves-effect waves-light btn-flat">Delete Chat</a></li>
            </ul>
          </div>

          <!-- chat history container for private chat -->
          <div id="scrollMe" class="chat-container peer row" *ngIf="!group">
            <div *ngIf="loadingMessages">
              <p class="no-messages">Loading Message History ...</p>

            </div>
            <div *ngIf="!loadingMessages && messages.length < 1">
              <p class="no-messages text-center center">No Messages Here Yet...</p>
            </div>
            <div *ngIf="!loadingMessages && messages.length > 0">
              <div class="message-history" *ngFor="let message of messages; index as i">
                <div class="row message-date"
                  *ngIf="i == 0 || (message.timeStamp.toDate() | date: 'dd MMMM yyyy') !== (messages[i-1]?.timeStamp.toDate() | date: 'dd MMMM yyyy')">
                  <div class="col-lg-12">
                    <div class="date text-center">
                      {{ message.timeStamp.toDate() | date: "EEEE, MMMM d, y" }}
                    </div>
                  </div>
                </div>
                <div *ngIf="
                      message.fromId == selectedConver.user_2.id;
                      then incoming_msg;
                      else outgoing_msg
                    "></div>
                <ng-template #outgoing_msg>
                  <div class="message-wrapper me" *ngIf="!message.attachment.type || message.attachment.type == 'none'">


                    <div class="wrapper " #wrapper (contextmenu)="onRightClick($event, message)">
                      <ng-container *ngTemplateOutlet="normalMessageWrap; context: {message: message}"></ng-container>
                    </div>
                    <div class="message-more-option" (click)="onRightClick($event, message)">
                      <span class="material-icons" style="font-size: 18px;">
                        more_vert
                      </span>
                    </div>
                    <div class="date">
                      <div *ngIf="message.edited" style="padding-right: 8px;">edited</div>
                      <div>
                        {{ message.timeStamp.toDate() | date:'shortTime' }}
                      </div>
                    </div>
                  </div>
                  <!-- if message is media - Video or PDF -->
                  <div class="message-wrapper me"
                    *ngIf="message.attachment?.type == 'video' || message.attachment?.type == 'pdf'">
                    <!-- <img class="avatar ng-star-inserted" [src]="message.profileImgLink || imgLink"> -->
                    <div class="wrapper media file" (contextmenu)="onRightClick($event, message)">
                      <ng-container *ngTemplateOutlet="videoAndFileMessageWrap; context: {message: message}">
                      </ng-container>

                    </div>
                    <div class="date">{{ message.timeStamp.toDate() | date:'shortTime' }}</div>
                  </div>
                  <!-- if message is media - image -->
                  <div class="message-wrapper me" *ngIf="message.attachment?.type == 'image'">
                    <div class="wrapper media " (contextmenu)="onRightClick($event, message)">
                      <ng-container *ngTemplateOutlet="imageMessageWrap; context: {message: message}"></ng-container>

                    </div>
                    <div class="date">{{ message.timeStamp.toDate() | date:'shortTime' }}</div>
                  </div>
                </ng-template>
                <ng-template #incoming_msg>
                  <div class="message-wrapper contact"
                    *ngIf="!message.attachment.type || message.attachment.type == 'none'">
                    <div class="wrapper " (contextmenu)="onRightClick($event, message)">
                      <ng-container *ngTemplateOutlet="normalMessageWrap; context: {message: message}"></ng-container>
                    </div>
                    <div class="date">
                      {{ message.timeStamp.toDate() | date:'shortTime' }}
                      <div *ngIf="message.edited" style="display: inline;padding-right: 8px;">edited</div>

                    </div>
                  </div>
                  <!-- if message is media - Video or PDF -->
                  <div class="message-wrapper contact"
                    *ngIf="message.attachment?.type == 'video' || message.attachment?.type == 'pdf'">
                    <!-- <img class="avatar ng-star-inserted" [src]="message.profileImgLink || imgLink"> -->
                    <div class="wrapper media file" (contextmenu)="onRightClick($event, message)">
                      <ng-container *ngTemplateOutlet="videoAndFileMessageWrap; context: {message: message}">
                      </ng-container>

                    </div>
                    <div class="date">{{ message.timeStamp.toDate() | date:'shortTime' }}</div>
                  </div>
                  <div class="message-wrapper contact" *ngIf="message.attachment?.type == 'image'">
                    <div class="wrapper media " (contextmenu)="onRightClick($event, message)">
                      <ng-container *ngTemplateOutlet="imageMessageWrap; context: {message: message}"></ng-container>

                    </div>
                    <div class="date">{{ message.timeStamp.toDate() | date:'shortTime' }}</div>
                  </div>
                </ng-template>

              </div>
            </div>

          </div>



          <!-- Message text area and send -->
          <div class="row chat-footer">
            <div *ngIf="!group || (group && isMember(selectedConver, id))">
              <form class="col s12" [formGroup]="messageForm" (ngSubmit)="send(messageForm.value)">
                <div class="row message">
                  <div class="attach-wrap btn-flat col s1" (click)="attachFile.click()">
                    <span class="material-icons attach color-blue">
                      attach_file
                    </span>

                  </div> <input #attachFile id="attachFile" name="attachFile" onclick="value = null"
                    (change)="onSelectFile($event.target.files)" formControlName="attachFile" type="file" multiple
                    hidden />

                  <div class="input-field col s11">
                    <textarea class="textarea materialize-textarea" placeholder="Message"
                      formControlName="message"></textarea>
                    <label for="message"></label>
                  </div>

                </div>
                <button class="btn-flat send" type="submit">
                  <span class="material-icons color-blue" *ngIf="!editMessage">
                    send
                  </span>
                  <span class="material-icons color-blue"
                    *ngIf="editMessage && (messageForm.value.message == selectedMessage.message)">
                    close
                  </span>
                  <span class="material-icons color-blue"
                    *ngIf="editMessage && !(messageForm.value.message == selectedMessage.message)">
                    edit
                  </span>
                </button>
              </form>
            </div>
            <div *ngIf="group && !isMember(selectedConver, id)">
              <div class="waves-effect btn-flat joinCircleBtn" (click)="joinGroup()"
>
                Join Circle
              </div>
            </div>


          </div>

        </div>

        <!-- upload files preview -->
        <div id="uploadFilesViewer" class="modal">

          <div class="modal-content">
            <h5>Selected Document</h5>

            <div class="row center">
              <div class="main-container">
                <div class="selected-all row" *ngIf="selectedFilesForDisplay.length > 0">
                  <div class="col s5 selected-wrap" *ngFor="let file of selectedFilesForDisplay; index as i">
                    <button class="file-wrap" *ngIf="file.name != 'image'">
                      <div class="row icon-wrap">
                        <span class="material-icons outlined">
                          insert_drive_file
                        </span>
                      </div>
                      <div class="row file-name">
                        <span>
                          {{file.name}}
                        </span>
                      </div>
                      <div class="row file-size">
                        <span>
                          {{(file.size / 1024 / 1024 | number:'1.0-2')}} MB
                        </span>
                      </div>
                    </button>
                    <button class="image-wrap" style="padding: 0" *ngIf="file.name == 'image'">
                      <img loading="lazy" class="col s12 materialboxed" [src]="file.result">
                    </button>
                    <div class="cancel-wrap btn-flat" (click)="cancelFile(i)">
                      <span class="material-icons">
                        cancel
                      </span>
                    </div>
                  </div>
                  <button class="add-more-files btn-flat" (click)="attachFile2.click()">
                    <span class="material-icons" style="color: white;">
                      add
                    </span>
                  </button>
                  <input #attachFile2 id="attachFile2" name="attachFile2" onclick="value = null"
                    (change)="onSelectFile($event.target.files)" type="file" multiple hidden />

                </div>

              </div>

            </div>

          </div>
          <div class="modal-footer">
            <button class="modal-close grey btn" type="button" style="margin-right: 15px;" (click)="cancelUpload()">
              Cancel</button>

            <button type="button" class="modal-close btn  backg-color-blue" (click)="onSendFiles()">
              Send
            </button>
          </div>
        </div>

        <!-- <div class="right-click-menu" *ngIf="!isHidden"
          [ngStyle]="{'top.px': (yPosTabMenu-20), 'left.px': xPosTabMenu}">
          <ul class='content' >
            <li tabindex="0"><a class="waves-effect waves-light btn-flat"
                *ngIf="id == selectedMessage.fromId && selectedMessage.attachment.type == 'none'"
                (click)="editSelectedMessage(selectedMessage)">Edit Message</a></li>
            <li tabindex="0"><a class="waves-effect waves-light btn-flat"
                (click)="forwardSelectedMessage(selectedMessage)">Forward Message</a></li>
            <li tabindex="0"><a class="waves-effect waves-light btn-flat"
                *ngIf="selectedMessage.attachment.type == 'none'" (click)="copyMessage(selectedMessage)">Copy</a></li>

            <li class="divider" tabindex="-1"></li>
          </ul>
        </div> -->

        <!-- Forward Message Modal -->
        <div id="forwardMessage" class="modal">
          <div class="modal-content modal-header center">
            <h5 class=""><strong>Forward Message </strong></h5>
            <br />
          </div>
          <div class="modal-content">
            <div class="message-forward" *ngIf="selectedMessage">
              <span> "
                {{selectedMessage.message}} "
              </span>

            </div>

            <!-- <div class="row search-bar">
              <div class="input-field col s12">
                <i class="material-icons prefix">search</i>
                <input type="search" id="search" placeholder="search to start a new chat" class="autocomplete"
                  autocomplete="off" [(ngModel)]="searchForwardQuery" (focus)="openUserSuggestion($event.target.id)"
                  (ngModelChange)="searchChat()">

              </div>
            </div> -->
            <div class="contacts" *ngIf="!search">
              <ul *ngIf="privateChats.length > 0">
                <span>Private Chats</span>
                <li *ngFor="let chat of privateChats">
                  <div class="each-chat btn-flat">
                    <div class="one-chat-btn">
                      <div class="profile-pic col s2">
                        <img loading="lazy" class="avatar" alt="User Profile Photo" [src]="chat.profileImgLink || imgLink">
                      </div>
                      <div class="chat-details col s6">
                        <div class="contact-name">{{chat.user_2.GivenName || chat.user_2.FirstName}}
                          {{chat.user_2.LastName}}</div>

                      </div>
                      <div class="col s4 right-align" >
                        <a class="modal-close waves-effect btn-flat backg-color-blue" style="color: white;
                        border-radius: 20px;height: 28px; line-height: 26px;"
                          (click)="sendForwardMessage(selectedMessage, chat.id, chat.user_2.id)">send</a>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <ul *ngIf="groupChats.length > 0">
                <span>Circles</span>
                <li *ngFor="let chat of groupChats">
                  <div class="each-chat btn-flat">
                    <div class="one-chat-btn">
                      <div class="profile-pic col s2">
                        <img loading="lazy" class="avatar" alt="Profile Photo" [src]="chat.profilePicture || imgLink">
                      </div>
                      <div class="chat-details col s6">
                        <div class="contact-name">{{chat.name}}</div>

                      </div>
                      <div class="col s4 right-align" >
                        <a class="modal-close waves-effect btn-flat backg-color-blue" style="color: white;
                        border-radius: 20px;height: 28px; line-height: 26px;"
                          (click)="sendForwardMessage(selectedMessage, chat.id, chat.id)">send</a>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="contacts" *ngIf="search">
              <ul *ngIf="searchResult.length > 0">
                <li *ngFor="let chat of searchResult">
                  <div *ngIf="!chat.name" class="each-chat btn-flat">
                    <div class="one-chat-btn">
                      <div class="profile-pic col s2">
                        <img loading="lazy" class="avatar" alt="User Profile Photo" [src]="chat.profileImgLink || imgLink">
                      </div>
                      <div class="chat-details col s6">
                        <div class="contact-name">{{chat.GivenName || chat.FirstName}} {{chat.LastName}}</div>

                      </div>
                      <div class="col s4 right-align" >
                        <a class="modal-close waves-effect btn-flat backg-color-blue" style="color: white;
                        border-radius: 20px;height: 28px; line-height: 26px;"
                          (click)="sendForwardMessage(selectedMessage, '', chat.id)">send</a>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="chat.name" class="each-chat btn-flat">
                    <div class="one-chat-btn">
                      <div class="profile-pic col s2">
                        <img loading="lazy" class="avatar" alt="Profile Photo" [src]="chat.profilePicture || imgLink">
                      </div>
                      <div class="chat-details col s6">
                        <div class="contact-name">{{chat.name}}</div>

                      </div>
                      <div class="col s4 right-align" >
                        <a class="modal-close waves-effect btn-flat backg-color-blue" style="color: white;
                        border-radius: 20px;height: 28px; line-height: 26px;"
                          (click)="sendForwardMessage(selectedMessage, chat.id, chat.id)">send</a>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="modal-footer">
            <div class="row" style="margin-top: 0.5rem;">
              <div class="col s3 m3 offset-m5 offset-s6">
                <a class="modal-close grey btn">Cancel</a>
              </div>
              <div class="col s3 m3">
                <a class="modal-close btn backg-color-blue">Done</a>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <!-- normal message container -->
  <ng-template #normalMessageWrap let-message='message'>
    <div class="forwarded-message" *ngIf="message.forwardedMessage"><span class="material-icons" style="margin-top: 0;
    font-size: 18px;">arrow_forward </span> Forwarded</div>
    <!-- *ngIf="!((message.fromId == messages[i-1]?.fromId) && (message.timeStamp.toDate() | date: 'dd MMMM yyyy') == (messages[i-1]?.timeStamp.toDate() | date: 'dd MMMM yyyy'))" -->
    <div class="likeMessage" (click)="likeMessage(message.id)">
      <div *ngIf="!message.likes || !message.likes.count || !message.likes.count > 0; then emptyLikes; else liked">
      </div>
      <ng-template #liked><span class="material-icons color-red">
          favorite
        </span><span class="likes-count" *ngIf="group">{{message.likes.count}}</span></ng-template>
      <ng-template #emptyLikes><span class="material-icons">
          favorite_border
        </span></ng-template>

    </div>
    <div class="message" *ngIf="replaceURLs(message.message); let messageContent">
      <span [innerHtml]="messageContent"></span>
    </div>

    <div class="forwarded-message" *ngIf="message.forwardedMessage">{{message.forwardedFrom}},
      {{message.forwardedMessage.timeStamp.toDate() | date: 'M/d/yy, h:mm a'}}</div>
  </ng-template>

  <!-- Image message container -->
  <ng-template #imageMessageWrap let-message='message'>
    <div class="forwarded-message" *ngIf="message.forwardedMessage"><span class="material-icons" style="margin-top: 0;
    font-size: 18px;">arrow_forward </span> Forwarded</div>

    <div class="likeMessage" (click)="likeMessage(message.id)">
      <div *ngIf="!message.likes || !message.likes.count || !message.likes.count > 0; then emptyLikes; else liked">
      </div>
      <ng-template #liked><span class="material-icons color-red">
          favorite
        </span><span class="likes-count" *ngIf="group">{{message.likes.count}}</span></ng-template>
      <ng-template #emptyLikes><span class="material-icons">
          favorite_border
        </span></ng-template>

    </div>
    <div class="media-message"><img loading="lazy" [src]="message.attachment.link" class="materialboxed "> <br />
    </div>
    <div class="forwarded-message" *ngIf="message.forwardedMessage">{{message.forwardedFrom}},
      {{message.forwardedMessage.timeStamp.toDate() | date: 'M/d/yy, h:mm a'}}</div>

  </ng-template>

  <!-- video and File message container -->
  <ng-template #videoAndFileMessageWrap let-message='message'>
    <div class="forwarded-message" *ngIf="message.forwardedMessage"><span class="material-icons" style="margin-top: 0;
    font-size: 18px;">arrow_forward </span> Forwarded</div>
    <div class="likeMessage" (click)="likeMessage(message.id)">
      <div *ngIf="!message.likes || !message.likes.count || !message.likes.count > 0; then emptyLikes; else liked">
      </div>
      <ng-template #liked><span class="material-icons color-red">
          favorite
        </span><span class="likes-count" *ngIf="group">{{message.likes.count}}</span></ng-template>
      <ng-template #emptyLikes><span class="material-icons">
          favorite_border
        </span></ng-template>

    </div>
    <div class="media-message ">
      <a class="download-icon" [href]="message.attachment.link" target="_blank" download>
        <span class="material-icons">
          download
        </span>
      </a>
      <div class="document-info">
        <div class="document-name">
          <a class="document-name-only" [href]="message.attachment.link" target="_blank"
            download>{{message.attachment.name.substring(0,
            message.attachment.name.lastIndexOf('.'))}}</a>
          <a class="document-extension">.{{message.attachment.name.substring(message.attachment.name.lastIndexOf('.')
            + 1)}}</a>
        </div>
        <div class="document-action">
          <a [href]="message.attachment.link" target="_blank" download>download</a>
        </div>
      </div>
    </div>
    <div class="forwarded-message" *ngIf="message.forwardedMessage">{{message.forwardedFrom}},
      {{message.forwardedMessage.timeStamp.toDate() | date: 'M/d/yy, h:mm a'}}</div>

  </ng-template>


</div>
