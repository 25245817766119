
import { RegisterFromInviteComponent } from './comp/auth/register-from-invite/register-from-invite.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegistrationRequestComponent } from './comp/app/registration-request/registration-request.component';

import { TermsConditionsComponent } from './comp/app/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './comp/app/privacy-policy/privacy-policy.component';
import { HomeSubMenuComponent } from './comp/app/home/home-sub-menu/home-sub-menu.component';
import { MoreMenuComponent } from './comp/app/more-menu/more-menu.component';
import { LoginComponent } from './comp/auth/login/login.component';

import { RegisterAdminComponent } from './comp/auth/register-admin/register-admin.component';
import { EulaComponent } from './comp/app/eula/eula.component';
import { LandingPageComponent } from './comp/shared/landing-page/landing-page.component';
import { ViewServiceComponent } from './comp/shared/services/view-service/view-service.component';
import { ServicesListComponent } from './comp/shared/services/services-list/services-list.component';
import { ViewEventComponent } from './comp/shared/events/view-event/view-event.component';
import { EventCalendarComponent } from './comp/app/event-calandar/event-calendar.component';
import { ViewEventsListComponent } from './comp/shared/events/view-events-list/view-events-list.component';
import { ViewBlogComponent } from './comp/shared/blog/view-blog/view-blog.component';
import { ContactComponent } from './comp/app/contact/contact.component';
import { AboutComponent } from './comp/app/about/about.component';
import { ServiceProductComponent } from './comp/app/service-product/service-product.component';
import { ProductsComponent } from './comp/app/products/products.component';
import { ViewProductComponent } from './comp/shared/view-product/view-product.component';
import { BlogListComponent } from './comp/shared/blog/blog-list/blog-list.component';
import { GetAppConfirmationComponent } from './comp/shared/get-app/get-app-confirmation/get-app-confirmation.component';
import { GetAppComponent } from './comp/shared/get-app/get-app.component';
import { EnduserAgreementComponent } from './comp/app/enduser-agreement/enduser-agreement.component';
import { ConfirmationComponent } from './comp/app/confirmation/confirmation.component';
import { CatagoryComponent } from './comp/shared/catagory/catagory.component';
import { BlogImgTestComponent } from './comp/shared/blog-img-test/blog-img-test.component';
import { ResetPasswordComponent } from './comp/app/reset-password/reset-password.component';
import { ViewSectionComponent } from './comp/shared/custom-section/view-section/view-section.component';
import { SectionListComponent } from './comp/shared/custom-section/section-list/section-list.component';
import { ContactUsComponent } from './comp/shared/contact-us/contact-us.component';
import { WelcomeFirstNations } from './comp/shared/firstnations/welcome/welcome.component';
import { RegisterContactFromInviteComponent } from './comp/auth/register-contact-from-invite/register-contact-from-invite.component';
import { FirstNationLeaderLists } from './comp/shared/firstnations/nation-leaders/nation-leaders-list.component';
import { FaqListComponent } from './comp/shared/faq-list/faq-list.component';
import { RegisterFromAdminInviteComponent } from './comp/auth/register-from-admin-invite/register-from-admin-invite.component';

const routes: Routes = [
  {
    path: '',
    component: LandingPageComponent
  },
  {
    path: 'home',
    component: LandingPageComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'events-calendar',
    component: EventCalendarComponent
  },
  {
    path: 'more',
    component: MoreMenuComponent
  },
  {
    path: 'home-sub-menu',
    component: HomeSubMenuComponent
  },
  {
    path: 'registration-request',
    component: RegistrationRequestComponent
  },
  {
    path:'confirmation',
    component: ConfirmationComponent
  },
  {
    path: 'register',
    component: RegisterFromInviteComponent
  },
  {
    path: 'register-from-invite/:id',
    component: RegisterFromInviteComponent
  },
  {
    path: 'register-from-admin-invite/:id',
    component: RegisterFromAdminInviteComponent
  },
  {
    path: 'register-contact-from-invite/:id',
    component: RegisterContactFromInviteComponent
    
  },
  {
    path: 'register-from-invite',
    component: RegisterFromInviteComponent
  },

  {
    path: 'register-admin',
    component: RegisterAdminComponent
  },
  {
    path: 'terms-conditions',
    component: TermsConditionsComponent
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'enduser-agreement',
    component: EnduserAgreementComponent
  },
  {
    path: 'eula',
    component: EulaComponent
  },
  {
    path: 'event/:id',
    component: EventCalendarComponent
  },
  {
    path: 'events',
    component: ViewEventsListComponent
  },
  {
    path: 'view-event/:id',
    component: ViewEventComponent
  },
  {
    path: 'view-service/:id',
    component: ViewServiceComponent
  },
  {
    path: 'service/:id',
    component: ViewServiceComponent
  },
  {
    path: 'services',
    component: ServicesListComponent
  },
  {
    path: 'services/:id',
    component: ServicesListComponent
  },
  {
    path: 'services-products',
    component: ServiceProductComponent
  },
  {
    path: 'product-catagory',
    component: CatagoryComponent
  },
  {
    path: 'service-category',
    component: ServicesListComponent
  },
  {
    path: 'view-products/:id',
    component: ProductsComponent
  },
  {
    path: 'products',
    component: ProductsComponent
  },
  {
    path: 'view-product/:id',
    component: ViewProductComponent
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'contact',
    component: ContactComponent
},
  {
    path: 'blogs',
    component: BlogListComponent
  },
  {
    path: 'view-blog/:id',
    component: ViewBlogComponent
  },
  {
    path: 'get-app',
    component: GetAppComponent
  },
  {
    path: 'get-app-confirmation',
    component: GetAppConfirmationComponent
  },
  {
    path: 'img-test',
    component: BlogImgTestComponent
  },
  {
    path:'reset-password/:id',
    component: ResetPasswordComponent
  },
  {
    path: 'view-custom-section/:id',
    component: ViewSectionComponent
  },
  {
    path: 'custom-section-list/:id',
    component: SectionListComponent
  },
  {
    path: 'contact-us',
    component: ContactUsComponent
  },
  {
    path: 'welcome',
    component: WelcomeFirstNations
  },
  {
    path: 'nation-leaders',
    component: FirstNationLeaderLists
  },
  {
    path: 'faq-list',
    component: FaqListComponent
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, 
    { useHash: false,
    scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }

