import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChatGptResponseModel } from 'src/app/models/chatGptModel';
import { GlobalConstants } from 'src/app/models/GlobalConstants';
import { ChatGptService } from 'src/app/services/api/chat-gpt.serivce';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { EnvironmentService } from 'src/app/services/helper/env';
import { HelperService } from 'src/app/services/helper/helper';
import { RoutMetaService } from 'src/app/services/helper/rout-meta.service';
import { KeyWordsHighlight } from '../../../../Pipes/KeyWordsHighlightPipe';

@Component({
  selector: 'app-seo-setting',
  templateUrl: './seo-setting.component.html',
  styleUrl: './seo-setting.component.css'
})
export class SeoSettingComponent implements OnInit {
  @Output() seoCreated = new EventEmitter<string>();

  appSettingsForm: FormGroup;
  metaTagSettings: any = null
  changeMetaTagSetting: boolean = false;
  maxKeyWords: number = GlobalConstants.maxChatGptKeywords
  appSections = GlobalConstants.AppSections;
  model: any = {};
  editModalOpen = false;

  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;

  generateDescription: any;
  generateKeyword: any;
  generateHashTag: any;

  chatResponse = [];
  resLoading: boolean = false;
  selectedResponse: any;
  user: any;
  questionArray: any = [];

  @Input() theInput;
  @Output() onMessageSelect = new EventEmitter();
  generatedQuestion: string = '';
  chatGlobal: any;
  flatArray: any;

  questionAnswerArray = []; // This will store the question-answer pairs

  saveSuggest: boolean = false;
  saveButton = false;
  appSettings: any
  fetchedSuggestion: boolean = false;

  meta : any;

  seoStep = 0 
  firstStep: boolean;
  secondStep: boolean;
  metasetting


  constructor(private fb: FormBuilder,
    public appSettingsService: AppSettingsService,
    private routMeta: RoutMetaService,
    private helperService: HelperService,
    private envService: EnvironmentService,
    private chatGptService: ChatGptService,
  ) {

  }
  ngOnInit(): void {

    // fetch exsting metatag
    this.getMetaTagSettings();


    this.appSettingsForm = this.fb.group({
      appTitle: [''],
      description: [''],
      keyWords: this.fb.array([]),
      keyWordQuery: [''],
      HashTagsQuery: [''],
      hashTags: this.fb.array([]),
      quesAns: this.fb.array([]),
      answers: this.fb.array([]),
      questions: this.fb.array([]),

    });


    //gets the metadata to render 
    this.metasetting = this.appSettingsService.getMetadata().valueChanges().subscribe((meta: any) => {
      if (meta && meta.length > 0) {
        this.meta = meta[0];
        this.model = meta[0];
        if (this.model) {
          if (this.model.keyWords && this.model.keyWords.length > 0) {
            this.appSettingsForm.patchValue({
              appTitle: this.model.appTitle ? this.model.appTitle : 'title',
              description: this.model.description ? this.model.description : 'Description',
              answers: this.model.answers ? this.model.answers : '',
            })

          }
        }
      }
      this.metasetting.unsubscribe();
    })

  }

  suggest(values) {
    this.saveButton = true;
    let message = this.helperService.truncateHTML(this.description.value, '300')

    if (message) {
      this.generateDescription = message + `\n write a description from the above sentence using 25 words only`;
      this.generateKeyword = message + `\n generate only 5 keywords from the sentence above and make it inside an array`;
      this.generateHashTag = message + '\n generate only 5 hashtags from the sentence above'
      message = message + ` in ${values.wordCount} words`


      // this.chatGptService.startChat(this.generateKeyword).subscribe((res: any) => {
      //   this.resLoading = false;
      //   if (res) {
      //     let response = JSON.parse(JSON.parse(res));
      //     if (response.choices && response.choices.length > 0) {
      //       response.choices.map((choice) => this.chatResponse.push(choice));
      //       this.selectResponseChoice(
      //         this.chatResponse[this.chatResponse.length - 1],
      //         false
      //       );
      //     }
      //     this.generateKeyword = this.chatResponse[this.chatResponse.length - 1].message.content;
      //   }
      // });

      this.chatGptService.startChat(this.generateDescription).subscribe((res: any) => {
        this.resLoading = false;
        if (res) {
          let response = JSON.parse(JSON.parse(res));
          if (response.choices && response.choices.length > 0) {
            response.choices.map((choice) => this.chatResponse.push(choice));
            this.selectResponseChoice(
              this.chatResponse[this.chatResponse.length - 1],
              false
            );
          }
          this.generateDescription = this.chatResponse[this.chatResponse.length - 1].message.content;
        }
      });

      // this.chatGptService.startChat(this.generateHashTag).subscribe((res: any) => {
      //   this.resLoading = false;
      //   if (res) {
      //     let response = JSON.parse(JSON.parse(res));
      //     if (response.choices && response.choices.length > 0) {
      //       response.choices.map((choice) => this.chatResponse.push(choice));
      //       this.selectResponseChoice(
      //         this.chatResponse[this.chatResponse.length - 1],
      //         false
      //       );
      //     }
      //     this.generateHashTag = this.chatResponse[this.chatResponse.length - 1].message.content;
      //     this.parsedGeneratedHashTag = JSON.parse(this.generateHashTag);
      //   }
      // });
      this.onSubmit();
    }
  }

  selectResponseChoice(choice, useText: boolean) {
    this.selectedResponse = choice.message.content;
    if (useText) {
      this.selectMessage();
    }
  }

  selectMessage() {
    let response = new ChatGptResponseModel();
    response.input = this.theInput;
    response.message = this.selectedResponse;

    this.onMessageSelect.emit(response);
  }


  confirmUpdate(appsettings) {

    this.editModalOpen = true;
    const questionAnswerArray = [];
    for (let i = 0; i < this.flatArray.length; i++) {
      questionAnswerArray.push({
        question: this.flatArray[i], // Store the question
        answer: this.answers.value[i] ? this.answers.value[i] : "" // Initialize the corresponding answer as an empty string
      });
    }
    if (this.model && this.model?.length != 0) {
      appsettings.id = this.model[0].id;
      appsettings.description = this.generateDescription;
      appsettings.keyWords = this.generateKeyword;
      appsettings.hashTags = this.generateHashTag;

      appsettings.quesAns = questionAnswerArray;

      this.appSettingsService.updateMetaDataSettings(appsettings).then((response) => {
        this.seoCreated.emit('seoCreated')
        this.getMetaTagSettings();
      })
    } else {

      this.appSettingsService.saveMetaDataSettings(appsettings).then((response) => {
        this.getMetaTagSettings();
        this.seoCreated.emit('seoCreated')
      })
    }

  }

  getMetaTagSettings() {
    this.appSettingsService.getMetadata().valueChanges().subscribe((metaTags: any) => {
      let lastIndex = metaTags?.length - 1;
      if (lastIndex || lastIndex === 0) {
        this.metaTagSettings = metaTags?.[lastIndex];
        this.appSettingsForm.get('appTitle').setValue(this.metaTagSettings?.appTitle)
        this.routMeta.updateTitle(this.appSettingsForm.get('appTitle').value);
        this.appSettingsForm.get('description').setValue(this.metaTagSettings?.description);
        this.routMeta.updateDescription(this.appSettingsForm.get('description').value);
        // this.appSettingsForm.get('Keywords').setValue(this.metaTagSettings?.KeyWords);
        this.routMeta.updateKeywords(this.appSettingsForm.get('keyWords').value);

      }
    });
  }

  toast(obj) {
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green';
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
      // this.backClicked();
    }, 2000);
  }

  selectKeyWord() {
    // let wordExists = this.keyWords.value.some(w => w === this.keyWordQuery.value)
    // if (wordExists) return
    // this.keyWords.push(this.fb.control(this.keyWordQuery.value))
    // this.keyWordQuery.patchValue('')
    let wordExists = this.keyWords.value.some(w => w === this.keyWordQuery.value)
    if (wordExists) return
    this.keyWords.push(this.fb.control(this.keyWordQuery.value))
    this.keyWordQuery.patchValue('')
  }

  removeKeyWord(index) {
    this.keyWords.removeAt(index)
  }

  closeEditModal() {
    this.editModalOpen = false;
  }

  onSubmit() {
    if (this.description) {
      this.chatGlobal = this.chatGptService.generateQuestion(this.description.value).subscribe(response => {
        this.generatedQuestion = response.choices[0].message.content.trim();
        this.questionArray=[];
        this.questionArray.push(this.generatedQuestion)

        // Convert each string into an array
        const parsedArrays = this.questionArray.map(str => JSON.parse(str));

        // If you want to flatten them into a single array of questions
        this.flatArray = parsedArrays.flat();

        this.answers.clear();
        this.flatArray.forEach(() => {
          this.answers.push(this.fb.control(''));  // Add an empty control for each answer
        });

        // Initialize an empty array to hold the question-answer objects
        this.seoStep = 1
        this.firstStep = true
        this.chatGlobal.unsubscribe();

      });

    }
  }
  close() {
    this.saveSuggest = false;
  }
  saveSuggestion() {
    this.fetchedSuggestion = true;
    const fetchMetaInfo = "Based on the sentences in the array below and the description please generate 5 hashtags and put them in an array. The array: " + this.answers.value + 'and' + this.description
    this.chatGptService.startChat(fetchMetaInfo).subscribe((res: any) => {
      if (res) {
        let response = JSON.parse(JSON.parse(res));
        this.generateHashTag = response.choices[0].message.content;
        console.log("Generated", this.generateHashTag)
        if( Array.isArray(this.generateHashTag) && this.generateHashTag.length > 0 ){
          console.log("It is here")
          this.generateHashTag.forEach((word) => this.hashTags.push(this.fb.control(word)));
        }else if(this.isString(this.generateHashTag)){
          this.generateHashTag = this.generateHashTag.split(' ').map(tag => tag.trim())
          console.log("the generated hashTag",this.generateHashTag)
        }else{
          JSON.parse(this.generateHashTag).forEach((word) => this.hashTags.push(this.fb.control(word)));
        }


      }
    })
    //-------
    const fetchMetaKeyword = "Based on the sentences in the array below and the description please generate 5 keywords and put them in an array. The array: " + this.answers.value + 'and' + this.description
    this.chatGptService.startChat(fetchMetaKeyword).subscribe((res: any) => {
      if (res) {
        let response = JSON.parse(JSON.parse(res));
        this.generateKeyword = response.choices[0].message.content
        if( Array.isArray(this.generateKeyword) && this.generateKeyword.length > 0 ){
          console.log("It is here")
          this.generateKeyword.forEach((word) => this.keyWords.push(this.fb.control(this.helperService.truncateHTML(word,'100'))));
        }else if(this.isString(this.generateKeyword)){
          this.generateKeyword = this.generateKeyword.split(',').map(tag => tag.trim())
          console.log("the generated hashTag",this.generateKeyword)
        }else{
          JSON.parse(this.generateKeyword).forEach((word) => this.keyWords.push(this.fb.control(this.helperService.truncateHTML(word,'100'))));
        }



      }
    })

    this.seoStep = 2
    this.secondStep = true 
  }
  nextStep(number){
    if(this.firstStep && number==1){
      this.seoStep=1
    }else if(this.secondStep && number==2){

      this.seoStep=2
    }
  }
  saveAllSuggestions() {
    console.log("the generateHashTag", this.generateHashTag, "and", "the generateKeyword", this.generateKeyword)
    if (this.generateHashTag && this.generateKeyword) {
      this.model.hashTags = this.generateHashTag ? this.generateHashTag : [];
      this.model.keyWords = this.generateKeyword ? this.generateKeyword : [];
      this.model.answers = this.answers.value;
      this.model.questions = this.flatArray;
      this.model.description = this.generateDescription
      this.model.appTitle = this.appTitle.value;

      this.appSettingsService.getMetadata().valueChanges().subscribe((data)=>{
        if(data && data.length > 0 ){
          this.appSettingsService.updateMetaDataSettings(this.model);
          this.seoCreated.emit('seoCreated')
        }else{
          this.appSettingsService.saveMetaDataSettings(this.model);
          this.seoCreated.emit('seoCreated')
        }
      })
    }


  }
  reset(){
    this.questionArray = []
  }
  isString(value: any): boolean {
    return typeof value === 'string';
  }

  get appTitle() { return this.appSettingsForm.get('appTitle') };
  get description() { return this.appSettingsForm.get('description') };
  get keyWords() { return this.appSettingsForm.get('keyWords') as FormArray }
  get hashTags() { return this.appSettingsForm.get('hashTags') as FormArray }
  get answers() { return this.appSettingsForm.get('answers') as FormArray; }
  get questions() { return this.appSettingsForm.get('questions') as FormArray; }
  get keyWordQuery () {return this.appSettingsForm.get('keyWordQuery') }
  get HashTagsQuery () { return this.appSettingsForm.get('hashTagsQuery') }

}
