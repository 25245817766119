import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { GlobalConstants } from 'src/app/models/GlobalConstants';
import { ImageModel, ImageUploaderResponseModel } from 'src/app/models/ImageModel';
import { MediaService } from 'src/app/services/api/media.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { HelperService } from 'src/app/services/helper/helper';
import { Location} from '@angular/common';
import { PermissionService } from 'src/app/services/firebase/permission.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectAppSettings } from 'src/app/state/app.selectors';
import * as AppActions from 'src/app/state/app.actions';

@Component({
  selector: 'app-slider-setting',
  templateUrl: './slider-setting.component.html',
  styleUrls: ['./slider-setting.component.css']
})
export class SliderSettingComponent implements OnInit {
  @Input() calledFromEmptyDashboard:string;
  @Output() closeCollapse = new EventEmitter<string>();
  @Output() carouselCreated = new EventEmitter<string>();
  @Output() skip = new EventEmitter<string>();
  
  sliderRef: any;
  createModalOpen = false;
  newSlideForm: UntypedFormGroup;
  textPositions = GlobalConstants.TextPositions;
  base64Image: any;
  cropModalOpen = false;
  imageChangedEvent: any = '';
  useBase64;
  base64ImageEditSlider: any;
  cropModalOpenEditSlider = false;
  imageChangedEventEditSlider: any = '';
  useEditModalBase64=false

  pageSections: any;
  editModalOpen = false;
  slideIndex = 1;
  selectedNewPosition: any;
  isPositionChanged = false;
  slideRef: any;
  binDoc: File;
  imgErrorMsg: any;
  mediaList = [];
  editConfirmationModalOpen = false;
  deleteModalOpen = false;
  emptySlideOption = false;
  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  isMobile = false;
  newSlideToBeSaved: any;
  uploadFileModalOpen = false;
  uploadFileEditModalOpen = false;
  elementToBeEdited: any;  
  currentUser: any;
  permission: any;
  perm: any;
  permSpecial:any;
  hasAccess = false;
  slideElements: string[] = [];
  @Output() backClicked = new EventEmitter();
  addMoreModal = false;
  sliderChangesGlobal: Promise<void>;
  appSettings$: Subscription;
  constructor(private fb: FormBuilder,
              private settingsService: AppSettingsService,
              private helperService: HelperService,
              private mediaService: MediaService,
              private location: Location,
              private permissionService: PermissionService,
              private authService: AuthService,
              private router: Router,
              private store: Store,
              ) {
                this.appSettings$ = this.store.select(selectAppSettings).subscribe((settings) => { 
                  this.pageSections = JSON.parse(JSON.stringify(settings));
                });
      
                if (typeof window['Capacitor'] !== 'undefined' && window['Capacitor']['platform'] !== 'web' ) {
                  this.isMobile = true;
                }
               }
  ngOnInit(): void {
    this.currentUser = this.authService.getGlobalUser();
    if(this.currentUser.role=='Admin') this.hasAccess = true;
    this.newSlideForm = this.fb.group({
      imgUrl: ['', [Validators.required]],
      title: [''],
      titleColor: [''],
      text: [''],
      textColor: [''],
      url: [''],
      urlColor: [''],
      textPosition: [this.textPositions.center],
      position: []
    });
        
          if (this.pageSections) {
            const sliderSection = this.pageSections.appSections.filter(s => s.originalName === 'Slider');
            if (sliderSection && sliderSection.length > 0){
              this.sliderRef = sliderSection[0];
              this.sliderRef.options.forEach(element => {
                this.slideElements.push(element.imgUrl)
              });
              this.slideRef = 0;
              setTimeout(() => {
                if(this.sliderRef.options.length > 0){
                  this.showSlide(this.slideIndex);
                  this.automaticSlide();
                }
              }, 25);
            }
          }
        
      this.permissionService.getPermissionByEmployeeId(this.currentUser.uid).valueChanges().subscribe(permission => {
        if (permission && permission.length > 0){
          this.perm = permission[0]
          this.permSpecial = this.perm.permission[4];
          if(this.permSpecial.landingPageSetup) this.hasAccess = true
          
        }
      });
  }

  get imgUrl() { return this.newSlideForm.get('imgUrl'); }
  get position() { return this.newSlideForm.get('position'); }
  get title() { return this.newSlideForm.get('title'); }

  intervalChange(interval){
    this.sliderRef.interval = interval * 1000;
  }

  closeCreateSliderModal(){
    this.createModalOpen = false;
    this.newSlideForm.reset();
    this.newSlideForm.get('textPosition').setValue(this.textPositions.center);
  }

  landingPageConfirmationUpdate(){
    // this.landingPageToBeSaved = landingpagesetting;
    this.editConfirmationModalOpen = true;
  }

  saveLandingPageUpdate(){
    this.closeEditModal();
    this.saveChanges();
  }

  closeEditModal(){
    this.cropModalOpen = false;
    this.editConfirmationModalOpen = false;
    this.editModalOpen = false;
  }

  saveChanges() {
    this.closeCreateSliderModal();
    this.closeEditModal();
    this.pageSections.appSections.forEach(pSection => {
      if (pSection.originalName === 'Hero'){
        pSection.isEnabled = false;
      }
      if (pSection.originalName === 'Slider'){
        pSection.isEnabled = true;
      }
    });
    // save
    if (this.pageSections ) {
      this.store.dispatch(AppActions.updateAppSettings({ settings: this.pageSections }));
      this.sliderRef.options.forEach(element => {
          this.slideElements.push(element.imgUrl)
        });
        this.mediaList = [];
        this.toast({ html: 'Changes Saved Successfully.', classes: 'green', redirect: false});
        this.addMoreModal = true;
    }

    this.removeImage()
  }
  closeAddMoreModal(){
    this.addMoreModal=false;
    if(this.calledFromEmptyDashboard == 'Slider'){
      this.closeCollapse.emit('heroCarousel');
      this.carouselCreated.emit('carouselCreated');
    }
  }

  closeCollapseFromEmptydashboard(){
    this.closeCollapse.emit('heroCarousel');
}

  // edit slider image modal
  fileChangeEventEditSlider(event){
    if(this.getImageType(event.target.files[0].name) === 'gif'){
      // this.handleFileInput(event.target.files, this.sliderRef);
      this.binDoc = event.target.files[0];
      this.imgErrorMsg = undefined;
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        this.base64Image = reader.result as string;
        if(this.base64Image){
          let base64result = this.base64Image.split(',')[1];
          const img = new ImageModel();
          img.imgBase64 = base64result;
          this.mediaList.push({type:'Image', file: img});
        }
      };
    } else {
    this.imageChangedEventEditSlider = event;
    this.cropModalOpenEditSlider = true;
  }
  }

  // set the default active slide to the first one
  // change slide with the prev/next button
  moveSlide(moveStep) {
    this.showSlide(this.slideIndex += moveStep);
  }

  // change slide with the dots
  currentSlide(n) {
      this.showSlide(this.slideIndex = n);
  }

  showSlide(n) {
    let i;
    const slides = document.getElementsByClassName('slide');
    const dots = document.getElementsByClassName('dot');
  
    if (n > slides.length) { this.slideIndex = 1; }
    if (n < 1) { this.slideIndex = slides.length; }
  
    // Preload images
    const imagesToPreload = [];
    for (i = 0; i < slides.length; i++) {
      const slideImage = slides[i].querySelector('img');
      if (slideImage && !slideImage.complete) {
        imagesToPreload.push(slideImage.src);
      }
    }
  
    // Wait for images to preload
    Promise.all(imagesToPreload.map(src => new Promise(resolve => {
      const img = new Image();
      img.onload = resolve;
      img.src = src;
    }))).then(() => {
      // Hide all slides
      for (i = 0; i < slides.length; i++) {
        slides[i].classList.add('hidden');
      }
  
      // Remove active status from all dots
      for (i = 0; i < dots.length; i++) {
        dots[i].classList.remove('bg-customTeal');
        dots[i].classList.add('bg-gray-200');
      }
  
      // Show the active slide
      if (slides[this.slideIndex - 1]) {
        slides[this.slideIndex - 1].classList.remove('hidden');
      }
  
      // Highlight the active dot
      if (dots[this.slideIndex - 1]) {
        dots[this.slideIndex - 1].classList.remove('bg-gray-200');
        dots[this.slideIndex - 1].classList.add('bg-customTeal');
      }
    });
  }

  automaticSlide(){
    const interval = this.sliderRef.interval ? this.sliderRef.interval : 5000;
    setTimeout(() => {
      if(this.sliderRef.options.length > 0){
        this.showSlide(this.slideIndex);
        this.moveSlide(1);
        this.automaticSlide();
      }
    }, interval);
  }

  addSlider() {
    this.addMoreModal = false;
    this.position.setValue(this.sliderRef.options.length)
    this.createModalOpen = true;
  }

  moveSlider(element, position: number) {
    const index = this.sliderRef.options.indexOf(element);
    const newIndex = index + position;
    if (newIndex < 0 || newIndex === this.sliderRef.options.length) { return; } // Already at the top or bottom.
    const indexes = [index, newIndex].sort(); // Sort the indixes
    this.sliderRef.options.splice(
                  indexes[0], 2, this.sliderRef.options[indexes[1]], this.sliderRef.options[indexes[0]]
                  ); // Replace from lowest index, two elements, reverting the order
    this.sliderRef.options.forEach(element => {
      element.index = this.sliderRef.options.indexOf(element);
    });
  }

  moveSection(element, position: number) {
    const index = this.pageSections.appSections.indexOf(element);
    const newIndex = index + position;
    if (newIndex < 1 || newIndex === this.pageSections.appSections.length
      || newIndex === (this.pageSections.appSections.length - 1)) { return; } // Already at the top or bottom.
    const indexes = [index, newIndex].sort(); // Sort the indixes
    // Replace from lowest index, two elements, reverting the order
    this.pageSections.appSections.splice(indexes[0], 2, this.pageSections.sections[indexes[1]], this.pageSections.sections[indexes[0]]);
    this.pageSections.appSections.forEach(element => {
      element.index = this.pageSections.appSections.indexOf(element);
    });
  }

  positionChanged(position: any) {
    this.isPositionChanged = true;
    this.selectedNewPosition = position;
  }

  sliderClicked(index){
    this.slideRef = index;
    this.editModalOpen = true;
  }

  onSlideTitleChange(value: string) {
    this.sliderRef.options[this.slideRef].title = value;
  }

  fileChangeEvent(event){
    // if the image type is gif just upload the image without opening the cropper
    if(this.getImageType(event.target.files[0].name) === 'gif'){
      // this.handleFileInput(event.target.files, this.sliderRef);
      this.binDoc = event.target.files[0];
      this.imgErrorMsg = undefined;
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        this.base64Image = reader.result as string;
        if(this.base64Image){
          let base64result = this.base64Image.split(',')[1];
          const img = new ImageModel();
          img.imgBase64 = base64result;
          this.mediaList.push({type:'Image', file: img});
        }
      };
    } else {
      this.imageChangedEvent = event;
      this.cropModalOpen = true;
    }
  }

  getImageType(name: any){
    return this.helperService.getImageType(name);
  }

  removeSlider(slider: any) {
    this.deleteModalOpen = false;
    if  (this.sliderRef.options.length > 0){
      const index = this.sliderRef.options.indexOf(slider);
      if (index !== -1) {
        this.sliderRef.options.splice(index, 1);
        this.sliderRef.options.forEach(element => {
          element.index = this.sliderRef.options.indexOf(element);
        });
        this.editModalOpen = false;
        if(this.sliderRef.options.length >0){
          this.moveSlide(1)
        }
      }
      else{
        this.emptySlideOption = true;
      }
    }

    this.saveChanges();
    this.toast({html: 'Carousel deleted successfully.', type: 'failure', redirect: false});
  }

  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
      if(obj.redirect){
        this.backBtnClicked();
      }
      // this.router.navigate(['/admin/dashboard'])
    }, 2000);
  }

  processCroppedImageEditSlider(event){
    this.cropModalOpenEditSlider = false;
    this.useEditModalBase64=false
    this.base64ImageEditSlider = event;
    if(this.base64ImageEditSlider){
      if(this.isMobile){
        const img = new ImageModel();
        img.imgBase64 = this.base64ImageEditSlider.replace(/^data:image\/[a-z]+;base64,/, "" );
        this.mediaList.push({type:'Image', file: img});
      }else{
      let base64result = this.base64ImageEditSlider.split(',')[1];
      const img = new ImageModel();
      img.imgBase64 = base64result;
      img.imgBase64 = base64result;
      this.mediaList.push({type:'Image', file: img});
    }
    }
  }

  processCroppedImage(event){
    this.cropModalOpen = false;
    this.useBase64=false
    this.base64Image = event;
    this.binDoc = this.base64Image;
   
    if(this.base64Image){
      if(this.isMobile){
        const img = new ImageModel();
        img.imgBase64 = this.base64ImageEditSlider.replace(/^data:image\/[a-z]+;base64,/,"");
        this.mediaList.push({type:'Image', file: img});
      }else{
      let base64result = this.base64Image.split(',')[1];
      const img = new ImageModel();
      img.imgBase64 = base64result;
      this.mediaList.push({type:'Image', file: img});
    }
    }
  }

  closeCroppingModal(){    
    this.cropModalOpen = false;
    this.useEditModalBase64=false
  }

  updateSlider(element){
    this.elementToBeEdited = element;
    this.cropModalOpenEditSlider = false;
    if(this.mediaList.length > 0){
      this.uploadFileEditModalOpen = true;
    }
    else {
      this.saveNewPositionAndChanges(element);
    }
  }

  saveNewPositionAndChanges(element){
    this.cropModalOpen = false;
    if (this.isPositionChanged) {
      let newIndex = this.selectedNewPosition; //parseInt((document.getElementById("position") as HTMLInputElement).value);
      const oldIndex = element.index;
      if (newIndex === oldIndex) {return; } // Already at the top or bottom.
      if (newIndex >= this.sliderRef.options.length){
        newIndex = this.sliderRef.options.length - 1
      }
      const el = this.sliderRef.options.splice(oldIndex, 1)[0]
      this.sliderRef.options.splice(newIndex, 0, el);
      this.sliderRef.options.forEach(ele => {
        ele.index = this.sliderRef.options.indexOf(ele);
      });
    }
    this.saveChanges();
  }

  saveNewslide(values){
    this.newSlideToBeSaved = {
      index: this.sliderRef.options.length,
      title: values.title || '',
      titleColor: values.titleColor || '',
      text: values.text || '',
      textColor: values.textColor || '',
      url: values.url || '',
      urlColor: values.textColor || '',
      imgUrl: '',
      videoUrl: '',
      textPosition:values.textPosition || ''
    };
    if(this.mediaList.length > 0){
      this.uploadFileModalOpen = true;
    }
  }

  getTheUrlSlide(savedUrls){
    this.uploadFileModalOpen = false;
    this.imgUrl.setValue(savedUrls.imageURL);
    this.newSlideToBeSaved.imgUrl = savedUrls.imageURL;
    this.newSlideToBeSaved.videoUrl= savedUrls.videoUrl ? savedUrls.videoUrl : '';
    this.sliderRef.options.push(this.newSlideToBeSaved);
    this.closeCreateSliderModal()
    this.saveNewPositionAndChanges(this.sliderRef.options[this.newSlideToBeSaved.index]);
  }

  getTheUrlSlideEdit(savedUrls){
    this.uploadFileEditModalOpen = false;
    this.imgUrl.setValue(savedUrls.imageURL);
    this.elementToBeEdited.imgUrl = savedUrls.imageURL;
    this.elementToBeEdited.videoUrl= savedUrls.videoUrl ? savedUrls.videoUrl : '';
    this.sliderRef.options.forEach(opt => {
      if(opt.index === this.elementToBeEdited.index){
        opt = this.elementToBeEdited;
      }
    });
    this.saveNewPositionAndChanges(this.sliderRef.options[this.elementToBeEdited.index]);

  }
  onModalImageUpload(response: ImageUploaderResponseModel){
    if (response.error){
      this.imgErrorMsg = response.errorMessage
      return
    }  
    this.imageChangedEvent = 'data:image/jpeg;base64,' + response.imgBase64;
    this.useEditModalBase64=true
    this.cropModalOpen = true;
    this.useBase64=true
  }
  onModalImageUploadUpdate(response:ImageUploaderResponseModel){
    if (response.error){
      this.imgErrorMsg = response.errorMessage
      return
    }  
    this.imageChangedEventEditSlider = 'data:image/jpeg;base64,' + response.imgBase64;
    this.useEditModalBase64=true
  }
  removeImage(){
    this.imgUrl.patchValue('');
    this.base64Image = '';
    this.base64ImageEditSlider = '';
    this.mediaList = [];
  }

  removeSliderImage(ref){
    this.sliderRef.options.forEach(element => {
      if(element.index === ref.index){
        element.imgUrl = '';
      }
    });
  }

  // backBtnClicked(){
  //   this.backClicked.emit();
  // }

  backBtnClicked(){
    if(this.calledFromEmptyDashboard){
      this.backClicked.emit()
    }else{
      this.location.back();
    }
  }
  skipSetup(){
    this.skip.emit();
  }

  reloadComponent(self:boolean,urlToNavigateTo ?:string){

    const url=self ? this.router.url :urlToNavigateTo;
    this.router.navigateByUrl('/',{skipLocationChange:true}).then(()=>{
      this.router.navigate([`/${url}`]).then(()=>{
   
      })
    })
  }
}
