import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormArray } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Injectable({
 providedIn: 'root'
})
export class ValidationService{
    minLengthArray(min: number) {
        return (c: AbstractControl): {[key: string]: any} => {
            if (c.value.length >= min)
                return null;
    
            return { 'minLengthArray': {valid: false }};
        }
    }

    mediaValidator(formArray: UntypedFormArray,media:any) {

        let valid = false
        formArray.value.forEach(value => {
          if (value.type === media) {
            valid = true
          }
        })
        return valid ? null : {
          invalidValue: true
        }
      }
      
    mediaMinLengthValidator(media,min: number) {
        return (c: AbstractControl): {[key: string]: any} => {
            let valid=false

            let mediaLength= c.value.filter(x=>x.type=media).length
            if(mediaLength <=min){
                valid=true
            }
            return valid ? null : {
                invalidValue: true
              }
            // return { 'minLengthArray': {valid: false }};
        }
    }

    notFalseValueValidator(formArray: UntypedFormArray) {

        let valid = false
        formArray.value.forEach(value => {
          if (value.value === true) {
            valid = true
          }
        })
        return valid ? null : {
          invalidValue: true
        }
      
      }

    imageMaxLengthValidator(min: number) {
        return (c: AbstractControl): {[key: string]: any} => {
            let valid=false
            if (c.value.length <= min){
              valid=true

            }
            return valid ? null : {
                invalidValue: true
              }
        }
    }
}