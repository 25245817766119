<!-- <div class="md:min-h-screen py-8 md:py-0 flex flex-col-reverse mx-auto  md:space-y-0  md:flex-row">
    <div class="flex flex-col md:flex-row  w-full p-4 md:md:p-12 justify-center  h-min ">

        <div class="md:w-1/4 space-y-2 bg-customTeal rounded-l-md px-8 shadow-md py-6">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
              </svg>
              
            <p class="text-xl font-bold   md:text-4xl text-white ">Contact Us</p>
            <p class="text-base font-medium  text-gray-100 md:pt-2">We are here to help and answer any questions you might have. We look forward to hearing from you.</p>
        
        </div>
        <div class="bg-white shadow-md rounded-r-md border-solid border border-gray-100  md:py-8 md:px-8 md:w-1/2 py-2 px-4    " >
           
            <form class="w-full " [formGroup]="contactUsForm" (ngSubmit)="submitForm(contactUsForm.value)">
                <div class="flex flex-col space-y-4  ">
                    Name
                    <div>
                        <label for=" " class="text-sm font-bold pt-3 text-gray-600"> Name</label>
                        <input id="email" type="email" formControlName='name' required  autocomplete="off"
                        class="input input-bordered rounded-md pl-4 text-base focus:outline-1 focus:outline-brandColor w-full"
                        id="Email " type="text " placeholder="Name "
                        >
                    </div>

                    Email
                    <div>
                        <label for=" " class="text-sm font-bold  text-gray-600"> Email <span class="text-red-400">*</span></label>
                        <input id="email" type="email" formControlName='email' required  autocomplete="off"
                        class="input input-bordered rounded-md pl-4 text-base focus:outline-1 focus:outline-brandColor w-full"
                        id="Email " type="text " placeholder="Email "
                        >
                    </div>    
                    
                    Message
                    <div>
                        <label for=" " class="text-sm font-bold pt-3"> Message <span class="text-red-400">*</span></label>
                        <textarea placeholder="Message" formControlName="message" class="textarea pl-4 py-2 text-base textarea-bordered rounded-sm textarea-lg w-full ">
    
                        </textarea>
                    </div>

                    Submit
                    <div class="flex justify-end">
                        <button [disabled]="!contactUsForm.valid"  
                    class="flex items-center w-min rounded bg-customTeal px-6 py-2 text-xs font-medium  leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                    [ngClass]="{'bg-[#cccccc] text-[#666666]': !contactUsForm.valid}">
                        Submit
                    </button>
                    </div>
        
        
                </div>
            </form>
        
        </div>
    </div>
</div> -->


<div class="w-full flex justify-center px-4 " *ngIf="phoneNumber || email || socialMediaModel">
    

<div class=" place-content-center mx-auto my-12 bg-white h-max flex flex-col rounded-md shadow-md  " [ngClass]="{ 'md:max-w-2xl w-full justify-center ' : !preview}" >
    <p class=" text-center text-xl  mb-2  mx-auto bg-customTeal w-full font-bold text-white p-4 " >{{sectionName}}</p>
    <div *ngIf="phoneNumber || email" class="mx-auto flex flex-wrap md:flex-row flex-col items-center gap-4 md:gap-8 py-6 px-6">

        <!-- phone number -->
        <div *ngIf="phoneNumber" class="flex space-x-2 items-center ">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 fill-customTeal" viewBox="0 0 20 20"
            fill="currentColor">
                <path
                d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
            </svg>
            <a [href]="'tel:' + phoneNumber" class=" text-xl text-customTeal">{{phoneNumber}}</a>
        </div>
        <div *ngIf="email" class="flex space-x-2 items-center  ">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 fill-customTeal" viewBox="0 0 20 20"
                fill="currentColor">
                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
            </svg> 
            <a href='mailto:{{email}}' class=" text-xl  text-customTeal">{{email}}</a>
        </div>
      </div>
  
   

  <hr *ngIf="phoneNumber || email">
  <div class="mx-auto py-4">
    <ng-container *ngIf="socialMediaModel">

        <div class="flex justify-center md:justify-start space-x-2 md:mt-6 ">
            <div class="max-w-max flex items-center space-x-6 py-2">
                <a class="cursor-pointer rounded-full " *ngIf="socialMediaModel.facebook"
                [href]="socialMediaModel.facebook" target="_blank"><i
                    class="fa fa-facebook prefix text-customTeal text-2xl "></i>
                </a>
                <a class="cursor-pointer pr-2 text-2xl  " *ngIf="socialMediaModel.twitter"
                    [href]="socialMediaModel.twitter" target="_blank" >
                    <svg class="fill-customTeal" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path  class="fill-customTeal" d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg>
                </a>
                <a class="cursor-pointer" *ngIf="socialMediaModel.instagram"
                    [href]="socialMediaModel.instagram" target="_blank"><i
                        class="fa fa-instagram prefix text-2xl text-customTeal mr-3"></i>
                </a>
                <a class="cursor-pointer" *ngIf="socialMediaModel.youtube"
                    [href]="socialMediaModel.youtube" target="_blank"><i
                        class="fa fa-youtube prefix text-customTeal text-2xl mr-3"></i>
                </a>
                <a class="cursor-pointer" *ngIf="socialMediaModel.linkedin"
                    [href]="socialMediaModel.linkedin" target="_blank"><i
                        class="fa fa-linkedin prefix text-customTeal text-2xl mr-3"></i>
                </a>
                <a class="cursor-pointer" *ngIf="socialMediaModel.pinterest"
                    [href]="socialMediaModel.pinterest" target="_blank"><i
                        class="fa fa-pinterest prefix text-customTeal text-2xl mr-3"></i>
                </a>
                <a class="cursor-pointer" *ngIf="socialMediaModel.tiktok"
                [href]="socialMediaModel.tiktok" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" class="fill-customTeal w-5 h-5 mr-3" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"/></svg>
         
            </a>
            </div>        
        </div>
        
    </ng-container>
</div>


  </div>






</div>


<!-- toast -->
<app-alert class="fixed top-0 flex justify-end w-full z-[2000]" [toastType]="toastType" [toastMessage]="toastMessage"
  [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>
