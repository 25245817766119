import { Component, OnInit ,Input, Output} from '@angular/core';
import { AyrshareService } from 'src/app/services/social-media/ayrshare.service';
import { JwtModel} from 'src/app/models/SocialMediaPostModel';

import { environment } from 'src/environments/environment';
import { ProfieKeyService } from 'src/app/services/social-media/profileKey.service';
import { PermissionService } from 'src/app/services/firebase/permission.service';
import { AuthService } from 'src/app/services/firebase/auth.service';


@Component({
  selector: 'app-link-account',
  templateUrl: './link-account.component.html',
  styleUrls: ['./link-account.component.css']
})
export class LinkAccountComponent implements OnInit {



    profileKey
    privateKey = environment.socialMedia.privateKey

    perm: any;
    permission: any;
    currentUser: any;
    isAdmin : boolean;

    @Input() link;
    @Input() new;
    @Output() action;
    text

    permMember:any;
    permSocial:any;
    permPES:any;
    permSpecial:any;
    permAnalytics:any;
    
    constructor(
        private socialMediaService:AyrshareService,
        private profieKeyService:ProfieKeyService,
        private permissionService: PermissionService,
        private authService: AuthService



    ) { }

    ngOnInit() {

      this.currentUser = this.authService.getGlobalUser();
      if(this.currentUser.role == 'Admin'){
        this.isAdmin = true;
      }
      this.profieKeyService.currentProfileKey.subscribe(p=>{
        this.profileKey=p
      }
      )
      this.text = this.new ? "Link social media account" : "Add more accounts"

      this.permissionService.getPermissionByEmployeeId(this.currentUser.uid).valueChanges().subscribe(perm =>{
        if(perm.length != 0){
          this.perm = perm[0];
          //this.permission = this.perm.permission[0];
          this.permMember = this.perm.permission[0]
          this.permPES = this.perm.permission[1]
          this.permAnalytics = this.perm.permission[2];
          this.permSocial = this.perm.permission[3];
          this.permSpecial = this.perm.permission[4];
        }
      })
    }

 
    linkSocialMedia(){
        let model = new JwtModel()

        model.privateKey=this.privateKey
        model.profileKey = this.profileKey
        
        this.socialMediaService.generateJWT(model).subscribe(x=>{
            if(x['status'] === 'success'){

                window.open(x['url'],'_blank')
            }else{
                console.log(x,'something happened')
            }
        })

    }




}
