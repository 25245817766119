import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class EnvironmentService {

  logos = {
    'Demo':[
      "https://api.goingmobile.app/media/personal-app-assets/img/webp/Going%20Mobile%20-%203.webp",
      "https://api.goingmobile.app/media/personal-app-assets/img/webp/Going%20Mobile%20-%203_C.webp",
      "../../../../assets/img/Going Mobile - 1.png",
      "https://api.goingmobile.app/media/personal-app-assets/img/webp/Going%20Mobile%20-%202.webp",
    ],
    'FNLeaders':[
      "https://api.goingmobile.app/media/personal-app-assets/img/webp/Nation-logo.webp",
      "https://api.goingmobile.app/media/personal-app-assets/img/webp/Nation-logo.webp",
    ]
  }

  // Brand colors of the app
  colors= {
    'Demo':{
      'primary':'#077373',
      'primaryVariant':'#038C7F', //primary and primary variant
      'secondary': '#73BFB8',
      'secondary-variant':  '#F2F2F2', //seconday and secondary variant
      'accent': '#262626' //accent color(noth are the same)
    },
    'FNLeaders':{
      'primary':'#e87a00',
      'primary-variant':'#fa9939', //primary and primary variant
      'secondary': '#001f3d',
      'secondary-variant':  '#045174', //seconday and secondary variant
      'accent': '#f8dcbf' //accent color(noth are the same)
    }
  }

  private origin = environment.firstNation.Origin;
  isNationApp = environment.firstNation.Origin == "FNLeaders" ? true : false;
  // currentAppName = this.isNationApp ? 'First Nation Leaders App' : 'Going Mobile'
  // defaultLogo = this.isNationApp ? '../../../../assets/img/Nation-logo.webp' : '../../../../assets/img/Going Mobile - 3.webp'
  //   demoUrl = this.isNationApp
  //     ? "https://firstnationleaders.app"
  //     : "https://demo.goingmobile.app";

  constructor() {}

  get isChiefApp() {
    return  ["chief","nationalchief"].includes(environment.firstNation.name) ? true : false;
  }


  get fromFNLeaders() {
    return this.origin === "FNLeaders";
  }

  get fromDemo() {
    return this.origin === "GoingMobile";
  }

  get currentAppName() {
    return this.isNationApp ? "First Nation Leaders App" : "Going Mobile";
  }

  get defaultLogo() {
    return this.isNationApp
      ? "https://api.goingmobile.app/media/personal-app-assets/img/webp/Nation-logo.webp"
      : "https://api.goingmobile.app/media/personal-app-assets/img/webp/Going%20Mobile%20-%203.webp";
  }

  get demoUrl() {
    return this.isNationApp
      ? "https://firstnationleaders.app"
      : "https://demo.goingmobile.app";
  }

  get link() {
    return this.isNationApp || this.fromFNLeaders
      ? "mynation.app"
      : "goingmobile.app";
  }

  get phoneNumber() {
    return this.isNationApp
      ? "mynation.app"
      : "goingmobile.app";
  }

  get supportEmail() {
    return this.isNationApp
      ? "noreply@mynation.app"
      : "info@goingmobile.app";

      // return this.isNationApp
      // ? "info@mynation.app"
      // : "info@goingmobile.app";
  }

  get brandColor() {
    return this.isNationApp
      ? "#d28227"
      : "#06797d";
  }

  get imgFolder() {
    return this.isNationApp || this.fromFNLeaders
      ? "fnleaders"
      : "demo";
  }


  getLogo(type=0){
    let key =  this.isNationApp || this.fromFNLeaders ? "FNLeaders"  : "Demo"
    return this.logos[key].length - 1 <= type ? this.logos[key][type] : this.logos[key][1]
  }


  getColor(type='primary'){
    let key =  this.isNationApp || this.fromFNLeaders ? "FNLeaders"  : "Demo"
    return type in this.colors[key] ? this.colors[key][type] : this.colors[key]['primary']
    
  }
}
