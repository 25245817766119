import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { GetAppService } from 'src/app/services/firebase/get-app.service';
import { RequesttojoinService } from 'src/app/services/firebase/requesttojoin.service';
import { UserService } from 'src/app/services/firebase/user.service';
import { VerificationService } from 'src/app/services/firebase/verification.service';
import { HelperService } from 'src/app/services/helper/helper';

@Component({
  selector: 'app-user-activity',
  templateUrl: './user-activity.component.html',
  styleUrls: ['./user-activity.component.css']
})
export class UserActivityComponent implements OnInit {
  tabStatus = 'registration'
  verifyUsers: boolean;
  totalSubLength = 0;
  totalEmpLength = 0;
  totalReqLength = 0;
  notes: any;
  noteModal
  userNotes
  requests: any;
  verification: any;
  appRequests: any;
  pendingRequests = [];
  user: any;
  currentUser: any;
  loggedUser


  constructor(private helperService: HelperService,
    private verificationService: VerificationService,
    private requestToJoinService: RequesttojoinService,
    private getAppService: GetAppService,
    private router: Router,
    private authService: AuthService,
    private userService: UserService,) { }

  ngOnInit(): void {
    this.currentUser = this.authService.getCurrentUser();
    this.userService.getUserByEmail(this.currentUser.email).valueChanges().subscribe((user:any) => {
      if(user && user.length != 0){
        this.loggedUser = user[0];
        this.tabStatus = user[0].role==='Admin'? 'registration' : 'verification';
        console.log("ROLE ROLE ROLE", this.loggedUser)

      }
    })

    this.userService.getUserByRole('Employee').valueChanges().subscribe((emp)=>{
      if(emp && emp.length>=0){
        this.totalEmpLength = emp.length

      }
    })
    this.userService.getUserByRole('User').valueChanges().subscribe((user) =>{
      if(user && user.length >=0 ){
        this.totalSubLength = user.length;
      }
    })
    this.getAppService.getRequests().valueChanges().subscribe((requests) => {
      if (requests.length > 0){
        this.appRequests = requests; 
        this.appRequests.forEach(request => {
          if (request.status){
              this.pendingRequests.push(request);
          } else {
            this.pendingRequests.push(request);
          }
        });
      }
    });
 
    this.requestToJoinService.getPendingRequests().valueChanges().subscribe((requests : any) => {
      this.requests = requests;
    })
    this.verificationService.getAllVerificationRequests().valueChanges().subscribe((verification: any)=>{
      this.verification = verification;
    })
  }

  tabStatusSet(status: string){
    this.tabStatus = status
    
  }
  truncateHTML(text: string, limit: string): string {
    return this.helperService.truncateHTML(text, limit);
  }
  accept(request){
    this.router.navigate(['/admin/app-request'])
  }
  decline(request){
    this.router.navigate(['/admin/app-request'])
  }
}
