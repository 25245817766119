import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ImageModel, ImageUploaderResponseModel } from 'src/app/models/ImageModel';
import { Location} from '@angular/common';
import { FCMPushNotificationsService } from 'src/app/services/firebase/FCMPushNotifications.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { HelperService } from 'src/app/services/helper/helper';
import { PermissionService } from 'src/app/services/firebase/permission.service';
import { ShareDataModel } from 'src/app/models/SocialMediaPostModel';
import { NotificationModel } from 'src/app/models/Messages.Model';
import { CustomSectionService } from 'src/app/services/firebase/custom-section.service';

@Component({
  selector: 'app-create-section-element',
  templateUrl: './create-section-element.component.html',
  styleUrls: ['./create-section-element.component.css']
})
export class CreateSectionElementComponent implements OnInit {

  @Input() sectionID 
  @Input() calledFromOnboarding
  @Input() calledFromEmptyDashboard;
  @Output() customElementCreated = new EventEmitter<string>();
  @Output() previewSelected = new EventEmitter<string>();
  @Output() previewClosed = new EventEmitter<string>();
  @Output() goToTopOfNextPage = new EventEmitter();

  imageUrl = '';
  newSectionElementForm: UntypedFormGroup;
  selectedFilesForDisplay: any = [];
  currentUser: any;
  id: any;
  base64Image: any;
  binDoc: File;
  isMobile = false;
  mobileBase64Image: any;
   // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  videoUpload: File = null;
  pdfUpload: File = null;
  pdfLink: string= null;
  videoLink: string= null;
  imageLink: string = null;
  attachmentFile: any;
  cancelModalOpen = false;
  notifSettings;
  permission: any;
  haveAccessPermission = false;
  preview = false;
  modelToPreview: any;
  createModalOpen = false;
  pdfErrorMsg: any;
  imgErrorMsg: any;
  attachedVideo: any;
  cropModalOpen = false;
  imageChangeEvt: any = "";
  cropImgPreview: any;
  imageChangedEvent: any = '';
  videoType ='';
  uploadedDocument : File;
  newSectionElementValues: any;
  shareToSocialMedia=false
  shareData: ShareDataModel = new ShareDataModel()
  perm:any;
  permCustomPage:any;
  
  useBase64=false
  allUsers=[]
  videoUploaded = false;
  uploadingMedia = false;
  uploadFileModalOpen = false;
  uploadStatus = 'Pending';
  mediaList = [];
  returnedMediaLinks = {
    imageURL: '',
    videoUrl: '',
    pdfUrl: ''
  };
  customSectionId: any;
  sectionOptions = [];
  customSection: any;
  constructor(private fb: UntypedFormBuilder, 
              private router: Router,
              private route: ActivatedRoute,
              private authService: AuthService,
              private location: Location,
              private pushNotificationsService: FCMPushNotificationsService,
              private appSettingsService: AppSettingsService,
              private helperervice: HelperService,
              private permissionService: PermissionService,
              private customSectionService: CustomSectionService

              ) {
                if (typeof window['Capacitor'] !== 'undefined' && window['Capacitor']['platform'] !== 'web' ) {
                  this.isMobile = true;
                }
  }

  ngOnInit(): void {
    this.currentUser = this.authService.getGlobalUser();
    if (this.currentUser) {
      this.id = this.currentUser.uid;
      if (this.currentUser.role === 'Employee'){
        this.permissionService.getPermissionByEmployeeId(this.currentUser.uid).valueChanges().subscribe((perm: any) => {
          if (perm && perm.length > 0){
            this.perm = perm[0];
            this.permCustomPage = this.perm.permission[6]
            if (this.permCustomPage.createItem){
              this.haveAccessPermission = true;
            }
          }
        });
      }
      if (this.currentUser.role === 'Admin'){
        this.haveAccessPermission = true;
      }
    }
    this.newSectionElementForm = this.fb.group({
      title: [''],
      description: [''],
      link: [''],
      imgLink: [''],
      videoLink: [''],
      pdfLink: [true],
      commentable: [true],
      shareWith: ['', Validators.required],
    });
    
    if(this.calledFromOnboarding=='custom'){
      this.customSectionId = this.sectionID
    }else{
      this.route.paramMap.subscribe(params => {
        this.customSectionId = params.get('id');
      });
    }

    if(this.customSectionId){

      this.customSectionService.getCustomSectionById(this.customSectionId).valueChanges().subscribe((customSection: any) => {
        if(customSection.length > 0){
          this.sectionOptions = customSection[0].options;
          this.customSection = customSection[0];
        }
      })
    }

    if(this.currentUser && (this.currentUser.role == 'Employee' || this.currentUser.role == 'Admin')){
      this.appSettingsService.getPushNotificationSettingsList().valueChanges().subscribe(x=>{
        if(x && x.length > 0){
          this.notifSettings=x[0]
        }
      })
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.previewChanges(this.newSectionElementForm.value)

    if(this.calledFromOnboarding=='custom'){
      this.customSectionId = this.sectionID
    }
    if(this.customSectionId){

      this.customSectionService.getCustomSectionById(this.customSectionId).valueChanges().subscribe((customSection: any) => {
        if(customSection.length > 0){
          this.sectionOptions = customSection[0].options;
          this.customSection = customSection[0];
        }
      })
    }
  }

  get description(){ return this.newSectionElementForm.get('description'); }
  get title(){ return this.newSectionElementForm.get('title'); }
  get shareWith(){ return this.newSectionElementForm.get('shareWith'); }
  get link() { return this.newSectionElementForm.get('link'); }
  get commentable() { return this.newSectionElementForm.get('commentable')}

  truncateHTML(text: string, limit: string): string {
    return this.helperervice.truncateHTML(text,limit)
  }

  sendPushNotification(model){
    // let title = this.truncateHTML(model.postTitle,'200')
    // let notification = new NotificationModel()
    // notification.title='Section'
    // notification.body=this.truncateHTML(model.postTitle,'200')
    // this.pushNotificationsService.sendNotificationByRole(notification,'User')
    this.sendPushNotificationToUser(model.title);

    // this.pushNotificationsService.sendNotificationToAllTheMembers('Blog',title,'User_Notification')

    // send Notif to admin
    if(this.notifSettings && this.currentUser && this.currentUser.role === "Employee"){
      this.sendPushNotificationToOwner()
    }
  }

  sendPushNotificationToUser(postTitle){

    if(this.notifSettings && this.currentUser && (this.currentUser.role === "Admin" || this.currentUser.role === "Employee")){

      let conditions=["product","adminChange"]
      if(this.helperervice.checkNotificationForOwner(this.notifSettings,conditions)){
        let notification = new NotificationModel()
        // let  createdByFullName= this.currentUser.GivenName + ' ' + this.currentUser.LastName
        notification.title='Product'
        notification.body= this.truncateHTML(postTitle,'200')
        this.pushNotificationsService.sendNotificationByRole(notification,'User')

        // this.pushNotificationsService.sendNotificationToAllTheMembers('Employee Created a new product',`${createdByFullName} created a new product - ${productName}.`,'Owner_Notification')
      }
    }
  }


  sendPushNotificationToOwner(){
    let conditions=["section","employeeChange"]
    if(this.helperervice.checkNotificationForOwner(this.notifSettings,conditions)){
      let  createdByFullName= this.currentUser.GivenName + ' ' + this.currentUser.LastName

      let notification = new NotificationModel()
      notification.title='Employee Created a section post'
      notification.body=`${createdByFullName} created a new section post`
      this.pushNotificationsService.sendNotificationByRole(notification,'Admin')

      // this.pushNotificationsService.sendNotificationToAllTheMembers('Employee Created a Blog',`${createdByFullName} created a new  post`,'Owner_Notification')
      
    }
  }

  newPost(value) {
    this.createModalOpen = false;

    let newSectionElement = {
      customSectionId: this.customSectionId,
      title: value.title,
      description: value.description,
      link: value.link,
      timeStamp: new Date(),
      commentable: value.commentable,
      createdById: this.currentUser.uid,
      shareWith: value.shareWith
    };
    

    this.newSectionElementValues = newSectionElement;

    if(value.title == '' && value.description == '' && value.link == ''){ // if the user didn't input anything
      this.toast({html: 'Please input atleast one field to continue!', classes: 'red', type: 'warning', notRedirect: true});
    }
    else {
      if(this.mediaList.length > 0){
        this.uploadFileModalOpen = true;
      }

      if(this.mediaList.length === 0){

        this.createPost(this.newSectionElementValues)
      }

      if (this.newSectionElementValues.imageURL && this.newSectionElementValues.imgLink !== ''){
        this.shareData.imageUrls = [this.newSectionElementValues.imgLink]
      }
    }

  }

  createPost(newSectionElement){

    newSectionElement.reactions = {
      likes: {
        count: 0,
        userId: [],
        usernames: []
      }
    };
    newSectionElement.comments = [];
    newSectionElement.createdById = this.currentUser.uid
    this.customSectionService.saveSectionElement(newSectionElement).then(() => {
      this.uploadFileModalOpen = false;
      this.sendPushNotification(newSectionElement);
      //   if(newSectionElement.shareWith.toLowerCase()=== 'public'){
      //     this.shareToSocialMedia=true
      //     this.sharePost(newSectionElement)
      //   }
      this.toast({html: 'Successfully  created section post!', classes: 'green', type: 'success'});
      if(this.calledFromOnboarding=='custom'){
        this.customElementCreated.emit('customElementCreated')
        this.goToTopOfNextPage.emit();
      }

    }).catch(err => {
        this.toast({html: 'Can\'t create!', classes: 'red', type: 'failure'});
      });

  }

  cancelShare(){
    this.shareToSocialMedia=false
    this.backClicked()
  }

  sharePost(newSectionElement){
    this.shareData.share=true
    this.shareData.post = newSectionElement.description
    if (newSectionElement.videoLink && newSectionElement.videoLink !== ''){
      this.shareData.videoUrls = [newSectionElement.videoLink]
    }

    if (newSectionElement.imgLink && newSectionElement.imgLink !== ''){
      this.shareData.imageUrls = [newSectionElement.imgLink]
    }
  }


  onImageUpload(response:ImageUploaderResponseModel) {
    if (response.error){
      this.imgErrorMsg = response.errorMessage
      // this.imgLink.patchValue('');
      return
    }

    this.binDoc = response.binDoc;
    this.imageChangedEvent = 'data:image/jpeg;base64,' + response.imgBase64;
    this.mobileBase64Image = response.imgBase64;
    this.useBase64=true
    this.cropModalOpen = true;

 }

  handlePDFInput(files: FileList) {
    if (!this.validatePDF(files[0].name)) {
      this.pdfErrorMsg = 'Invalid Document Type, Please Select a PDF File';
      // this.imgLink.patchValue('');
      return;
    }
    this.uploadedDocument = files[0];
    this.pdfErrorMsg = undefined;
    this.pdfUpload = files[0] 
    this.mediaList.push({type:'PDF', file: this.pdfUpload});
  }

  handleVideoInput(files: FileList) {
    this.videoUploaded = true;
    this.videoUpload = files[0];
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.attachedVideo = reader.result as string;
    };
    this.mediaList.push({type:'Video', file: this.videoUpload});
  }
 
   validatePDF(name: any) {
     return this.helperervice.validatePDF(name);
   }


  backClicked(){
    this.location.back();
  }

  removeImage(){    
    this.base64Image = undefined;
    this.mediaList = this.mediaList.filter((file)=> {
      if(file.type !== 'Image'){
        return file;
      }
    })
  }

  removeVideo(){
    this.videoUpload = undefined;
    this.attachedVideo = undefined;
    this.mediaList = this.mediaList.filter((file)=> {
      if(file.type !== 'Video'){
        return file;
      }
    })
  }

  removePdf(){
    this.uploadedDocument = undefined;
    this.pdfUpload = undefined;
    this.mediaList = this.mediaList.filter((file)=> {
      if(file.type !== 'PDF'){
        return file;
      }
    })
  }

  closeCancelModal(){
    this.cancelModalOpen = false;
  }

  closeCreateModal(){
    this.createModalOpen = false;
  }

  closeCroppingModal(){    
    this.cropModalOpen = false;
    this.useBase64 = false;
  }

  fileChangeEvent(event){
    this.imageChangedEvent = event;
    // this.cropModalOpen = true;

    if (!this.validateImage(event[0].name)) {
      this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';
      this.imageUrl ='';
      return;
    }
    this.binDoc = event[0];
    const reader = new FileReader();
    reader.readAsDataURL(event[0]);
    reader.onload = () => {
      this.base64Image = reader.result as string;
      this.useBase64 = true;
      this.processCroppedImage(this.base64Image);
    };
  }
  validateImage(name: any) {
    return this.helperervice.validateImage(name);
   }
  getTheUrlsSaved(event){
    this.returnedMediaLinks = event;
    this.uploadFileModalOpen = false;
    this.newSectionElementValues.imgLink = this.returnedMediaLinks.imageURL;
    this.newSectionElementValues.videoLink = this.returnedMediaLinks.videoUrl;
    this.newSectionElementValues.pdfLink = this.returnedMediaLinks.pdfUrl;
    
    this.createPost(this.newSectionElementValues);
  }

  processCroppedImage(event){
    this.cropModalOpen = false;
    this.base64Image = event;
    let base64result = this.base64Image.split(',')[1];
    this.useBase64 = false;
    const img = new ImageModel();
   
    if(this.isMobile){
      img.imgBase64 = this.mobileBase64Image;
      this.mediaList.push({type:'Image', file: img});
    }else{
      img.imgBase64 = base64result; 
      this.mediaList.push({type:'Image', file: img});
    }        
  }

  previewChanges(value){
    this.modelToPreview = {};
    this.modelToPreview.customSectionId = this.customSectionId;
    this.modelToPreview.title = value.title;
    this.modelToPreview.description = value.description;
    this.modelToPreview.link = value.link;
    if (this.base64Image){
      this.modelToPreview.imgLink = this.base64Image;
    }
    if (this.attachmentFile){
      this.modelToPreview.imgLink = this.attachmentFile;
    }
    if (this.attachedVideo){
      this.modelToPreview.videoLink = this.attachedVideo;
    }
    // this.preview = true;
    if(this.calledFromEmptyDashboard == 'Custom'){
      this.previewSelected.emit()
    }
  }

  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
      if(!this.shareToSocialMedia && !obj.notRedirect && !this.calledFromOnboarding ){
        this.backClicked();
      }
    }, 2000);
  }

  closePreviewModal(){
    if(this.calledFromEmptyDashboard == 'Blog'){
      this.previewClosed.emit()
    }
  }
}
