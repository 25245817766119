import { FaqService } from './../../../../services/firebase/faq.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { Location} from '@angular/common';
import { HelperService } from 'src/app/services/helper/helper';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { selectAppSettings } from 'src/app/state/app.selectors';
import { ChangeVisibilityService } from 'src/app/services/firebase/change-visibility.service';

@Component({
  selector: 'app-faq-setting',
  templateUrl: './faq-setting.component.html',
  styleUrls: ['./faq-setting.component.css']
})
export class FaqSettingComponent implements OnInit {

  @Input() calledFromComponent:string;
  @Input() isShowCreated: boolean;
  @Output() closeCollapse = new EventEmitter<string>();
  @Output() faqCreated = new EventEmitter<string>();
  @Output() goToTopOfNextPage = new EventEmitter();

  faqForm: FormGroup;
  faqsList: []
  createModalOpen = false;
  pageSections: any
  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  chatGptModalOpen = false;
  requestMessage: any;
  selectedInput: any;
  appSettings$: Subscription;
  constructor(private fb: FormBuilder, 
              private faqService: FaqService,
              private location: Location,
              private helperService: HelperService,
              private settingsService: AppSettingsService,
              private store: Store,
              private changeVisibilityService: ChangeVisibilityService) { 
                this.appSettings$ = this.store.select(selectAppSettings).subscribe((settings) => {
                  this.pageSections = settings;
                }); 
              }

  ngOnInit(): void {
    this.faqForm = this.fb.group({
      question: ['', Validators.required],
      answer: ['', Validators.required]
    });
    this.faqService.getFAQs().valueChanges().subscribe((faqs: any)=>{
      if(faqs){
        this.faqsList = faqs;
      }
    })
  }
  get question(){return this.faqForm.get('question')}
  get answer(){return this.faqForm.get('answer')}

  saveFAQ(value: any){
    this.createModalOpen = false;
    this.faqService.saveFAQ(value).then(()=>{
      this.toast({html: 'Successfully added a FAQ!', classes: 'green', type: 'success'});
      if(this.calledFromComponent == 'faq'){
        this.closeCollapse.emit('createFaq');
        this.faqCreated.emit('faqCreated')
        this.goToTopOfNextPage.emit();
        this.changeVisibility();
      }
    }).catch(error => {
      this.toast({html: 'Can\'t create a FAQ!', classes: 'red', type: 'failure'});
    })

  }

  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
      if(!this.calledFromComponent){
      this.backClicked();
      }
    }, 2000);
  }

  backClicked(){
    this.location.back();
  }
  closeCreateModal(){
    this.createModalOpen = false;
  }
  onUseChatGpt() {
    this.selectedInput = this.question;
    this.requestMessage = this.helperService.getInnerText(this.question.value);
    this.chatGptModalOpen = true;
  }
  onUseChatGptA() {
    this.selectedInput = this.answer;
    this.requestMessage = this.helperService.getInnerText(this.answer.value);
    this.chatGptModalOpen = true;
  }
  closeChatGptModal() {
    this.chatGptModalOpen = false;
  }
  updateFAQquestion(event) {
    event.input.patchValue(event.message)
    this.closeChatGptModal();
  }
  changeVisibility(){
    this.changeVisibilityService.updateAppSections('faq');
  }
}
