<div class="px-4 md:px-12 py-5  ">
    <div class="flex flex-col justify-center  w-full   bg-white md:w-10/12 px-4 md:px-16 py-8 space-y-6 rounded-md">

        <!-- <button class=" flex flex-row  items-center" (click)="back()">
            <div>
                <i class="fa fa-arrow-left"></i>
            </div>
            <div class="pl-2">
                <h4 class=" text-sm  ">
                    Back to main setting
                </h4>
            </div>
        </button> -->
        <div>
          <div class="font-bold">
            Registration Page Customization
          </div>
          <div class="font-small">Manage the appearance of
              your user registration page.
              Preview your changes below
          </div>
        </div>
        <!-- Header -->
        <form [formGroup]="settingsForm" (ngSubmit)="saveSettings(settingsForm.value)" class="space-y-4">
            <div class="flex space-y-2   flex-col w-full">
                <label for=" " class="text-xs text-gray-500 font-bold "> Title</label>
                <!-- <input formControlName="title"
                    class=" border rounded p-3  md:mr-12 text-gray-700 leading-tight bg-veryLightGray focus:outline-gray-200 focus:shadow-outline "
                    id="Title" type="text " placeholder="Title"> -->
                <app-editor [control]="title" [inputType]="'title'" [fromComponent]="'Signup settings'"></app-editor>
                
                <div *ngIf="(title.dirty || title.touched) && title.invalid">
                    <small *ngIf="title.errors.required" class="text-red-700">Title is required.
                    </small>
                </div>
            </div>
            <div class="flex space-y-2   flex-col">
                <label for=" " class="text-xs text-gray-500 font-bold ">Description</label>
                <!-- <textarea formControlName="description"
                    class=" border rounded p-3  md:mr-12 h-24 resize-y text-gray-700  bg-veryLightGray focus:outline-gray-200 focus:shadow-outline ">
                </textarea> -->
                <app-editor [control]="description" [fromComponent]="'Signup settings'"></app-editor>

                <div *ngIf="(description.dirty || description.touched) && description.invalid">
                    <small *ngIf="description.errors.required" class="text-red-700">Description is required.
                    </small>
                </div>
            </div>


            <hr>
            <!-- Text position -->
            <div class="flex flex-col space-y-6  ">
                <!-- Description -->
                <div class="md:w-1/3 space-y-2  ">
                    <p class="font-bold">
                        Upload an image
                    </p>

                </div>
                <div class="flex flex-col md:flex-row space-x-3">

                    <div class="flex md:hidden p-3" *ngIf="!isMobile">
                        <label
                          class="py-2 px-8 custom-file-upload  self-center flex flex-col text-customTeal border-2 border-customTeal rounded-lg">
                          <input type="file" type="text" type="file" accept="image/*"
                          (change)="handleImageInput($event.target.files)" id="logoImage"/>
                          <span class="block font-bold pt-1">Upload Image</span>
                        </label>
                      </div>

                    <div *ngIf="!isMobile"
                    class="w-1/4 hidden md:flex justify-center content-center w-full h-44 max-w-x bg-white rounded-lg border-2 gap-x-3">

                    <label class="custom-file-upload  self-center flex flex-col">
                      <i class="fa fa-plus-circle text-customTeal text-3xl text-center self-center"></i>
                      <input type="file" type="text" type="file" accept="image/*" id="logoImage"
                      (change)="handleImageInput($event.target.files)" />
                      <span class="block font-bold pt-1">Upload Image</span>
                    </label>
                  </div>

                  <button class="btn btn-circle btn-outline" type="button" (click)="handleMobilePhotoInput()" *ngIf="isMobile">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                </button>
                    <div class="space-y-2"  class="relative" *ngIf="settingsModel && settingsModel.logo && !base64Image">
                        <img loading="lazy" [src]="settingsModel.logo"
                            class="object-cover w-36 h-32 rounded-md" alt="Image">
                        <button type="button" (click)="removeSavedFile()"
                            class="absolute top-0 translate-x-28 -translate-y-4 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                            <i class="fa fa-trash text-md"></i>
                        </button>
                    </div>



                    <div class="relative" *ngIf="base64Image">
                        <img loading="lazy" [src]="base64Image" class="object-cover  w-36 h-32 rounded-md cover" alt="Image">
                        <button (click)="removeImage()"
                            class="absolute top-0 translate-x-28 -translate-y-4 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                            <i class="fa fa-trash text-md"></i>
                        </button>
                    </div>
                </div>

            </div>
            <hr>
            <!-- download app toggle -->
            <!-- <div class="flex flex-col space-y-6 justify-between md:flex-row md:space-y-0 md:mr-12">
                <div class="md:w-1/3 space-y-2  ">
                    <p class="font-bold">
                        Backdrop color for the image
                    </p>
                    <p class=" text-xs text-darkGray ">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora dolore quis, sed eum officia
                        harum eius suscipit aspernatur. Rerum sapiente laudantium saepe, provident perspiciatis et ex
                        quia delectus ullam. Nulla!
                    </p>
                </div>

                <div class="flex  flex-col space-y-4 items-baseline">
                    <div class="flex justify-center align-middle space-x-8 items-baseline">
                        <div class="form-control ">
                            <label class="label cursor-pointer space-x-2">

                                <input type="radio" name="backDropColor" value="Solid" formControlName='backDropColor' class="radio radio-primary" checked />
                                <span class="label-text font-bold">Solid</span>
                            </label>
                        </div>
                        <div class="form-control">
                            <label class="label cursor-pointer space-x-2">

                                <input type="radio" name="backDropColor" value="Gradient" formControlName='backDropColor' class="radio radio-primary" checked />
                                <span class="label-text font-bold">Gradient</span>
                            </label>
                        </div>
                        <div class="form-control">
                            <label class="label cursor-pointer space-x-2">

                                <input type="radio"name="backDropColor" value="None" formControlName='backDropColor' class="radio radio-primary" checked />
                                <span class="label-text font-bold">None</span>
                            </label>
                        </div>
                    </div>
                    <div class="flex space-y-2 md:space-x-2 items-baseline" *ngIf="['Gradient','Solid'].includes(backDropColor.value)">
                        <div>
                            <label for=" " class="text-xs text-gray-500 font-bold ">Gradient color 1</label>
                            <div class="flex gap-2 justify-start">
                                <input id="titleColor" formControlName="firstColor" type="color"
                                    class="themeColorSelector ml-3 h-10 w-10">
                            </div>
                        </div>
                        <div *ngIf="backDropColor.value == 'Gradient'">
                            <label for=" " class="text-xs text-gray-500 font-bold ">Gradient color 2</label>
                            <div class="flex gap-2 justify-start">
                                <input id="titleColor" formControlName="secondColor" type="color"
                                    class="themeColorSelector ml-3 h-10 w-10">
                            </div>
                        </div>

                    </div>

                </div>




            </div>
            <hr> -->

            <!-- Save and preview button  -->
            <div class="flex md:mr-12 py-6 space-x-5 md:space-x-10 md:justify-end justify-center ">
                <!-- <button type="button"
                    class="flex space-x-2 px-4 md:px-6 py-2 border border-customTeal items-center text-sm justify-center md:text-base text-black rounded-lg w-min h-min text-md font-bold hover:bg-customTeal hover:text-white focus:outline-none  ">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                        <path fill-rule="evenodd"
                            d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                            clip-rule="evenodd" />
                    </svg>
                    <span>Preview</span>
                </button> -->
                <button type="submit" [disabled]="!settingsForm.valid"
                [ngClass]="{'bg-[#cccccc] text-[#666666] border-0': !settingsForm.valid}"
                    class=" flex items-center space-x-2 justify-center px-4 md:px-6 py-2 bg-customTeal text-white rounded-lg w-max h-min text-sm md:text-base font-bold hover:bg-darkBlue focus:outline-none  ">
                    <i class="fa-solid fa-floppy-disk block"></i>
                    <span>Save changes</span>
                </button>
            </div>
        </form>

    </div>

</div>

<!-- toast -->
<app-alert class="fixed top-0 flex justify-end w-full" [toastType]="toastType" [toastMessage]="toastMessage"
    [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>
