<div class="min-h-screen pt-6 font-body overflow-x-hidden  max-h-max">
    <div class="flex flex-col space-y-4 rounded-lg"
        [ngClass]="{'mx-4 md:mx-24 m-2 bg-white h-max p-4 md:p-12': employeeFromHome!= 'Employee'}">
        <p class="text-xl font-bold text-center text-customTeal" *ngIf="!calledFromEmptyDashboard">Invite Employee </p>
        <p class="py-2 text-center">Add an Employee to your app. An invitation email will be sent to them.</p>

        <form [formGroup]="employeeForm" (ngSubmit)="SubmitForm(employeeForm.value)" class="overflow-hidden">
            <div class="flex flex-col pt-12 mb-4 space-y-4 md:flex-col ">
                <div class="flex flex-col md:flex-row w-full gap-4 ">
                    <div class="flex flex-col space-y-2 md:w-1/2 ">
                        <label for=" " class="text-xs font-bold text-gray-500 "> First Name <span
                                class="text-red-500">*</span> </label>
                        <input [(ngModel)]="names" formControlName="firstName" class="w-full input input-bordered"
                            type="text" placeholder="Jane" required>
                        <div *ngIf="(firstName.dirty || firstName.touched) && firstName.invalid">
                            <small *ngIf="firstName.errors.required" class="text-red-500"> The First Name is required.
                            </small>
                        </div>
                    </div>

                    <div class="flex flex-col space-y-2 md:w-1/2 ">
                        <label for=" " class="text-xs font-bold text-gray-500 "> Last Name <span
                                class="text-red-500">*</span> </label>
                        <input formControlName="lastName" [(ngModel)]="lname" class="w-full input input-bordered "
                            type="text" placeholder="John" required>
                        <div *ngIf="(lastName.dirty || lastName.touched) && lastName.invalid">
                            <small *ngIf="lastName.errors.required" class="text-red-500"> The Last Name is required.
                            </small>
                        </div>
                    </div>
                </div>

                <div class="flex flex-col md:flex-row w-full gap-4 ">
                    <div class="flex flex-col space-y-2 md:w-1/2">
                        <label for=" " class="text-xs font-bold text-gray-500 "> Email <span
                                class="text-red-500">*</span></label>
                        <input type="email" formControlName="email" class="w-full input input-bordered " id="Email"
                            type="email" placeholder="JaneJohn@gmail.com" required [ngClass]="{
                                    'border-red-500': (email.dirty || email.touched) && email.invalid,
                                    'border-gray-200': !((email.dirty || email.touched) && email.invalid)}" />

                        <div *ngIf="(email.dirty || email.touched) && email.invalid">
                                <small *ngIf="email.errors.required" class="text-red-500"> The Email is required.
                                </small>                     
                                <small *ngIf="email.errors.emailInviteExists" class="text-red-500"> The user is already invited.
                                </small>
                                <small *ngIf="email.errors.email" class="text-red-500"> This email is not valid (e.g., admin&#64;goingmobile.app).
                                </small>
                                <small *ngIf="email.errors.emailExists" class="text-red-500 italic">This User already exists. (Email is in-use)</small>
                            </div>

                        <!-- <div *ngIf="employeeForm.get('email').pending">Checking email...</div> -->
                        <!-- <div *ngIf="employeeForm.get('email').hasError('invalidEmail')" class="text-red-500 italic">
                            This email is not allowed (e.g., admingoingmobile.app).
                        </div>
                        <div *ngIf="employeeForm.get('email').hasError('required')" class="text-red-500 italic">
                            Email is required.
                        </div>
                        <div *ngIf="employeeForm.get('email').hasError('email')" class="text-red-500 italic">
                            Please enter a valid email address.
                        </div>
                        <div *ngIf="emailExists && !employeeForm.get('email').hasError('required')"  class="text-red-500 italic">
                            This email already exists.
                        </div> -->
                    </div>

                    <div class="flex flex-col space-y-2 md:w-1/2 ">
                        <label for=" " class="text-xs font-bold text-gray-500 ">{{names }}<span
                                *ngIf="names != ''">'s</span>
                            Phone Number</label>
                        <input type="text" formControlName='cellPhone' id='cellPhone' placeholder="000-000-0000"
                            class="w-full input input-bordered" maxlength="10" />
                        <div *ngIf="(cellPhone.dirty || cellPhone.touched) && cellPhone.invalid">
                            <small *ngIf="(cellPhone.errors.minlength) && !cellPhone.errors.pattern" class="text-error">
                                Phone number must be at least 10 numbers
                            </small>
                            <small *ngIf="cellPhone.errors.pattern " class="text-error"> Wrong Format (phone no must be
                                10 digit number)
                            </small>
                        </div>
                    </div>
                </div>

                <div class="flex w-full space-x-4 ">
                    <div class="flex flex-col space-y-2 w-full md:w-1/2 ">
                        <label for=" " class="text-xs font-bold text-gray-500 ">{{names }}<span
                                *ngIf="names != ''">'s</span> Job
                            Title </label>
                        <input formControlName="jobTitle" class="w-full input input-bordered" type="text"
                            placeholder="Job title">
                    </div>
                </div>
                <hr />

                <div class="pt-2 space-y-2 md:flex md:justify-between">
                    <div>
                        <p class="font-bold"> {{names }}<span *ngIf="names != ''">'s</span> Work Phone </p>
                        <p class="text-xs text-darkGray">Please put in {{names }}<span *ngIf="names != ''">'s</span>
                            Work
                            Phone Number on the space provided.</p>
                    </div>

                <!-- button -->
                    <div class="flex flex-col space-y-2 md:w-1/2">
                        <div class="flex justify-between space-x-2">
                            <input formControlName="workPhone"
                                class="w-1/2 px-3 py-3 leading-tight text-gray-700 border rounded focus:outline-gray-200 focus:shadow-outline"
                                type="text" placeholder="000-0000-0000 ">
                            <input type="text" formControlName='extension' id='extension' placeholder="Ext"
                                class="w-1/2 w-full px-3 py-3 leading-tight text-gray-700 border rounded focus:outline-gray-200 focus:shadow-outline" />
                            <div *ngIf="(workPhone.dirty || workPhone.touched) && workPhone.invalid">
                                <small *ngIf="(workPhone.errors.minlength) && !workPhone.errors.pattern"
                                    class="text-error"> Phone number must be at least 10 numbers
                                </small>
                                <small *ngIf="workPhone.errors.pattern " class="text-error"> Wrong Format(phone no must
                                    be 10 digit number)
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <hr />

                <div class="pt-2 space-y-2">
                    <p class="font-bold">{{names }}<span *ngIf="names != ''">'s</span> Biography </p>
                    <p class="text-xs text-darkGray">Please put in your Biography in the space provided below.</p>

                    <div class="input-field">
                        <app-editor [control]="biography" class="bg-gray-100" [inputType]="'Biography'"></app-editor>
                    </div>
                </div>
                <br />
                <hr>

                <ng-container *ngIf="model && model.takeAppointment">
                    <div class="flex flex-col justify-between space-y-6 md:flex-row md:space-y-0 ">
                        <div class="space-y-2 md:w-1/2 ">
                            <p class="font-bold">
                                {{names }}<span *ngIf="names != ''">'s</span> Appointment
                            </p>
                            <p class="text-xs text-darkGray">
                                Do the employee accept Appointments?
                            </p>
                        </div>

                        <!-- toggle & edit -->
                        <div class="flex items-baseline justify-center space-x-8 align-middle">
                            <!-- toggle -->
                            <div>
                                <div class="form-control">
                                    <label class="cursor-pointer label">
                                        <span class="label-text text-sm pr-2 font-bold "
                                            [ngClass]="{'invisible text-gray-400': appointment.value}">No</span>

                                        <input formControlName="appointment" type="checkbox"
                                            class="toggle toggle-primary " />
                                        <span class="label-text text-sm pr-2 font-bold "
                                            [ngClass]="{'invisible text-customTeal': !appointment.value}">Yes</span>
                                    </label>
                                </div>
                            </div>

                        </div>
                    </div>
                    <br />
                    <hr>
                    <!-- If Employee Accepts appointment add schedule -->
                    <div *ngIf="appointment.value" class="flex flex-col justify-between w-full space-y-6 md:space-y-0 ">
                        <div class="md:w-8/12">
                            <p class="text-sm font-bold">Select the work days that the employee is active on(can be
                                changed anytime): </p>
                            <br />
                            <div *ngIf="schedules">
                                <div formArrayName="schedules" class="mb-2"
                                    *ngFor="let schedule of schedules.controls; let i=index">
                                    <div [formGroupName]="i" class="flex flex-row justify-between w-full ">
                                        <div class="flex flex-col w-5/12 md:w-2/12">
                                            <input class="py-1 my-2" disabled formControlName="day" />
                                        </div>
                                        <div
                                            class="flex flex-col justify-center w-7/12 space-y-2 md:w-10/12 md:flex-row md:justify-between md:space-y-0">

                                            <div class="ml-3 md:w-4/12 md:ml-0">
                                                <input type="time" step="300" class="input input-bordered"
                                                    value="09:00:00" formControlName="startTime"
                                                    [readonly]=isEditable />

                                            </div>

                                            <div class="ml-3 md:w-4/12 md:ml-0">
                                                <input type="time" step="300" class="input input-bordered"
                                                    value="05:00:00" formControlName="endTime" [readonly]=isEditable />
                                            </div>

                                            <p class="mb-5 ml-3 md:w-3/12 md:ml-0 md:mb-0">
                                                <label>
                                                    <input type="checkbox" class="filled-in"
                                                        formControlName="unAvailable" (click)="updateDaySchedule(i)"
                                                        [readonly]=isEditable />
                                                    <span class="ml-1">Unavailable</span>
                                                </label>
                                            </p>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>

                        <hr *ngIf="appointment.value" />
                        <br />

                        <div class="flex flex-col justify-between space-y-6 md:flex-row md:space-y-0 ">
                            <div class="space-y-2 md:w-1/2 ">
                                <p class="font-bold">
                                    Recieve appointment Email
                                </p>
                                <p class="text-xs text-darkGray">
                                    Allows the employee to recieve emails about appointments and reschedules
                                </p>
                            </div>

                            <!-- toggle & edit -->
                            <div class="flex items-baseline justify-center space-x-8 align-middle">
                                <!-- toggle -->
                                <div>
                                    <div class="form-control">
                                        <label class="cursor-pointer label">
                                            <span class="label-text text-sm pr-2 font-bold "
                                                [ngClass]="{'invisible text-gray-400': recieveAppointmentEmail.value}">No</span>

                                            <input formControlName="recieveAppointmentEmail" type="checkbox"
                                                class="toggle toggle-primary " />
                                            <span class="label-text text-sm pr-2 font-bold "
                                                [ngClass]="{'invisible text-customTeal': !recieveAppointmentEmail.value}">Yes</span>
                                        </label>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <hr *ngIf="appointment.value" />
                        <br />
                        <div class="flex flex-col justify-between space-y-6 md:flex-row md:space-y-0 ">
                            <div class="space-y-2 md:w-1/2 ">
                                <p class="font-bold">
                                    Recieve appointment SMS
                                </p>
                                <p class="text-xs text-darkGray">
                                    Allows the employee to recieve SMS about appointments and reschedules
                                </p>
                            </div>

                            <!-- toggle & edit -->
                            <div class="flex items-baseline justify-center space-x-8 align-middle">
                                <!-- toggle -->
                                <div>
                                    <div class="form-control">
                                        <label class="cursor-pointer label">
                                            <span class="label-text text-sm pr-2 font-bold "
                                                [ngClass]="{'invisible text-gray-400': recieveAppointmentSMS.value}">No</span>

                                            <input formControlName="recieveAppointmentSMS" type="checkbox"
                                                class="toggle toggle-primary " />
                                            <span class="label-text text-sm pr-2 font-bold "
                                                [ngClass]="{'invisible text-customTeal': !recieveAppointmentSMS.value}">Yes</span>
                                        </label>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <hr *ngIf="appointment.value" />
                </ng-container>

                <div class="flex flex-col justify-between space-y-6 md:flex-row md:space-y-0 ">
                    <!-- Description -->
                    <div class="mt-2 space-y-2 md:w-1/2 ">
                        <p class="font-bold">
                            {{names}} profile visibility? <span class="text-red-500">*</span>
                        </p>
                        <p class="text-xs text-darkGray">
                            Please choose your preference on how to show the employee is it to public, subscribe's only
                            or you dont want to show the employee at all.
                        </p>
                    </div>


                    <div class="flex md:flex-row flex-col gap-y-2 items-baseline justify-center space-x-2 align-middle md:space-x-8">
                        <div class="form-control">
                            <label class="space-x-2 cursor-pointer label">
                                <input type="radio" class="radio checked:bg-customTeal" formControlName="toggleShow"
                                    value="public" />
                                <span class="label-text">Public</span>
                            </label>
                        </div>
                        <div class="form-control">
                            <label class="space-x-2 cursor-pointer label">
                                <input type="radio" class="radio checked:bg-customTeal" formControlName="toggleShow"
                                    value="subscriber" />
                                <span class="label-text">Subscriber's Only</span>
                            </label>
                        </div>
                        <div class="form-control">
                            <label class="space-x-2 cursor-pointer label">
                                <input type="radio" class="radio checked:bg-customTeal" formControlName="toggleShow"
                                    value="no" />
                                <span class="label-text">Don't show</span>
                            </label>
                        </div>
                    </div>
                </div>
                <hr />

                <!-- Send content -->
                <div class="flex flex-col space-y-6 md:space-y-0 ">
                    <!-- Description -->
                    <div class="mt-2 space-y-2 md:w-full ">
                        <p class="font-bold">
                            Choose {{names }}<span *ngIf="names != ''">'s</span> preference? <span
                                class="text-red-500">*</span>
                        </p>
                        <p class="text-xs text-darkGray">
                            Please choose {{names}}<span *ngIf="names != ''">'s</span> preference for
                            {{names }}<span *ngIf="names != ''">'s</span> email, work phone or phonenumber to
                            be shown on
                            public, to subscribers' only and not to show at all.
                        </p>
                    </div>

                    <div class="flex flex-col">
                        <div
                            class="flex flex-col items-baseline justify-center mt-2 space-x-2 align-middle md:flex-row md:space-x-8">
                            <div class="form-control md:w-48">
                                <label class="space-x-2 cursor-pointer label">
                                    <span class="font-bold label-text">Email</span>
                                </label>
                            </div>
                            <div class="flex md:flex-row flex-col gap-y-2 items-baseline justify-center space-x-2 align-middle md:space-x-8">
                                <div class="form-control">
                                    <label class="space-x-2 cursor-pointer label">
                                        <input type="radio" class="radio checked:bg-customTeal"
                                            formControlName="toggleEmail" value="public" />

                                        <span class="label-text">Public</span>
                                    </label>
                                </div>
                                <div class="form-control">
                                    <label class="space-x-2 cursor-pointer label">
                                        <input type="radio" class="radio checked:bg-customTeal"
                                            formControlName="toggleEmail" value="subscriber" />
                                        <span class="label-text">Subscriber's Only</span>
                                    </label>
                                </div>
                                <div class="form-control ">
                                    <label class="space-x-2 cursor-pointer label">
                                        <input type="radio" class="radio checked:bg-customTeal"
                                            formControlName="toggleEmail" value="donotShow" />
                                        <span class="label-text">Don't show</span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div
                            class="flex flex-col items-baseline justify-center space-x-2 align-middle md:flex-row md:space-x-8">
                            <div class="w-48 form-control">
                                <label class="space-x-2 cursor-pointer label">
                                    <span class="font-bold label-text">Phone Number </span>
                                </label>
                            </div>

                            <div class="flex md:flex-row flex-col gap-y-2 items-baseline justify-center space-x-2 align-middle md:space-x-8">
                                <div class="form-control">
                                    <label class="space-x-2 cursor-pointer label">
                                        <input type="radio" class="radio checked:bg-customTeal"
                                            formControlName="togglePhoneNumber" value="public" />

                                        <span class="label-text">Public</span>
                                    </label>
                                </div>
                                <div class="form-control">
                                    <label class="space-x-2 cursor-pointer label">
                                        <input type="radio" class="radio checked:bg-customTeal"
                                            formControlName="togglePhoneNumber" value="subscriber" />
                                        <span class="label-text">Subscriber's Only</span>
                                    </label>
                                </div>
                                <div class="form-control">
                                    <label class="space-x-2 cursor-pointer label">
                                        <input type="radio" class="radio checked:bg-customTeal"
                                            formControlName="togglePhoneNumber" value="donotShow" />
                                        <span class="label-text">Don't show</span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div
                            class="flex flex-col items-baseline justify-center space-x-2 align-middle md:flex-row md:space-x-8">
                            <div class="form-control md:w-48">
                                <label class="space-x-2 cursor-pointer label">
                                    <span class="font-bold label-text">Work Phone</span>
                                </label>
                            </div>

                            <div class="flex md:flex-row flex-col gap-y-2 items-baseline justify-center space-x-2 align-middle md:space-x-8">
                                <div class="form-control">
                                    <label class="space-x-2 cursor-pointer label">
                                        <input type="radio" class="radio checked:bg-customTeal"
                                            formControlName="toggleWork" value="public" />

                                        <span class="label-text">Public</span>
                                    </label>
                                </div>
                                <div class="form-control">
                                    <label class="space-x-2 cursor-pointer label">
                                        <input type="radio" class="radio checked:bg-customTeal"
                                            formControlName="toggleWork" value="subscriber" />
                                        <span class="label-text">Subscriber's Only</span>
                                    </label>
                                </div>
                                <div class="form-control">
                                    <label class="space-x-2 cursor-pointer label">
                                        <input type="radio" class="radio checked:bg-customTeal"
                                            formControlName="toggleWork" value="donotShow" />
                                        <span class="label-text">Don't show</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <hr />

            </div>
            <br />
            <hr>

            <div class="flex flex-col mt-3 space-y-6 md:flex-row md:space-y-0 ">
                <!-- Description -->
                <div class="mt-2 space-y-2 md:w-1/2 ">
                    <p class="font-bold">
                        {{names }}<span *ngIf="names != ''">'s</span> Profile Picture
                    </p>
                    <p class="text-xs text-darkGray">
                        Please upload {{names }}<span *ngIf="names != ''">'s</span> profile picture.
                    </p>
                </div>


                <div class="flex items-baseline justify-center space-x-8 align-middle">
                    <!-- Image -->
                    <div class="w-full mt-5 md:w-1/3">
                        <button type="button" *ngIf="!isMobile">
                            <label class="flex flex-col px-10 py-5 rounded-lg bg-gray-50">
                                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 7704.74 6791.12">
                                    <circle cx="2373.6" cy="2638.32" r="695.57"
                                        transform="translate(-1413.99 1788.05) rotate(-45)" style="fill:#231f20" />
                                    <path
                                        d="M7671.44,940c-178.58-178.61-416-276.93-668.57-276.93H1189.17c-252.56,0-490,98.32-668.61,276.93s-276.93,416-276.93,668.57V6508.7c0,252.56,98.36,490,276.93,668.57s416.05,276.93,668.61,276.93h5813.7c252.56,0,490-98.36,668.57-276.93s276.93-416,276.93-668.57V1608.58C7948.37,1356,7850,1118.59,7671.44,940ZM7386.2,6508.7c0,9.58-.34,19.13-1.06,28.56q-582.86-730.58-1077.85-1430.1C5530.21,4014.71,5164.73,3357.57,4738.42,3418.77c-198.19,28.45-370.5,253-715.09,702.14-455.58,593.83-525,924.41-737.08,924-300.22-.57-401.22-663.59-746.59-678.79-230.42-10.15-415.18,274.9-509.58,419.25-325.6,497.92-721.5,1073.86-1219.91,1781.25a381.07,381.07,0,0,1-4.37-57.88V1608.58c0-211.4,172-383.33,383.37-383.33h5813.7c211.36,0,383.33,171.93,383.33,383.33Z"
                                        transform="translate(-243.63 -663.08)" style="fill:#231f20" />
                                </svg>
                                <input type="file" type="file" accept="image/*"
                                    (change)="fileChangeEvent($event.target.files)">
                                <span class="block pt-1 font-bold text-center">Image</span>
                            </label>
                        </button>
                        <div *ngIf="isMobile">
                            <app-image-uploader class="items-start justify-start" (onImageUpload)=onImageUpload($event)
                                [customConfig]="{'upload':false}"></app-image-uploader>
                        </div>
                        <!-- Image upload Preview  -->
                        <div class="mt-5 md:pl-2" *ngIf="base64Image">
                            <div class="flex flex-wrap gap-8">
                                <div class="relative">
                                    <img loading="lazy" [src]="base64Image" *ngIf="base64Image"
                                        class="object-cover w-56 h-24 rounded-md cover" alt="Image">
                                    <button (click)="removeImage()" type="button"
                                        class="top-0 w-10 h-10 translate-x-16 -translate-y-4 bg-white border-2 rounded-full shadow-md border-veryLightGray hover:border-4 hover:border-customTeal">
                                        <i class="fa fa-trash text-md"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


            </div>
            <hr />

            <div>
                <p class="px-4 py-2 text-lg font-bold">Grant Access <span *ngIf="names">to</span> {{names }}</p>
                <!-- <br />
                    <div class="flex items-center space-x-2"><input type="checkbox"
                            class="mr-4 space-x-2 checkbox checked:bg-customTeal" [checked]="allPriviledge"
                            (click)="toggleAllPrivilege()" />
                        Give Admin Privilege</div>
                    <br /> -->

                <!-- Member Management -->
                <div tabindex="0"
                    class="mb-3 bg-gray-200 border collapse collapse-arrow border-base-300 bg-base-100 rounded-box"
                    [ngClass]="{'collapse-open':openMemberCollapse, 'collapse-close':!openMemberCollapse}">
                    <input type="checkbox" class="peer" (click)="selectCollapseToOpen('Member')" />
                    <div class="text-lg font-medium text-black border-2 rounded-lg collapse-title">
                        Member Management
                    </div>
                    <div class="collapse-content">
                        <div class="flex flex-col w-full px-4 py-8 space-y-8 bg-white rounded-md">

                            <!-- <app-checkbox
                            (valueChanged)="allMemberPriviledgeChange($event)"
                            (click)="toggleAllMemberPrivilege()" 
                            label="Give Member Managment Privilege access"
                          ></app-checkbox> -->

                            <div class="flex items-center space-x-2"><input type="checkbox"
                                    class="mr-4 space-x-2 checkbox checked:bg-customTeal"
                                    [checked]="allMemberPriviledge" (click)="toggleAllMemberPrivilege()" />
                                Give Member Managment Privilege access</div>

                            <div class="space-y-4">
                                <div class="flex flex-col md:flex-row gap-y-4 gap-x-32 ">
                                    <div class="flex flex-col space-y-1 md:w-1/3">
                                        <p class="text-sm font-bold text-darkGray">Subscribers</p>
                                        <label class="flex flex-row w-full space-x-2 cursor-pointer label">
                                            <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                [checked]="permMember.inviteSub"
                                                (click)="togglePermission('inviteSub', 0)" />
                                            <span class="w-full label-text">Add Subscribers</span>
                                        </label>

                                        <label class="flex flex-row w-full space-x-2 cursor-pointer label">
                                            <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                [checked]="permMember.enableDisableSub"
                                                (click)="togglePermission('enableDisableSub', 0)" />
                                            <span class="w-full label-text">Enable/Disable Subscribers</span>
                                        </label>

                                    </div>
                                    <div class="flex flex-col space-y-1 md:w-1/3">
                                        <p class="text-sm font-bold text-darkGray">Employee</p>
                                        <label class="flex flex-row w-full space-x-2 cursor-pointer label">
                                            <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                [checked]="permMember.inviteEmp"
                                                (click)="togglePermission('inviteEmp', 0)" />
                                            <span class="w-full label-text">Invite Employees</span>
                                        </label>
                                        <label class="flex flex-row w-full space-x-2 cursor-pointer label ">
                                            <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                [checked]="permMember.enableDisableEmp"
                                                (click)="togglePermission('enableDisableEmp', 0)" />
                                            <span class="w-full label-text">Enable/Disable Employees</span>
                                        </label>
                                        <label class="flex flex-row w-full space-x-2 cursor-pointer label">
                                            <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                [checked]="permMember.scheduleEmp"
                                                (click)="togglePermission('scheduleEmp', 0)" />
                                            <span class="w-full label-text">Manage Schedule</span>
                                        </label>
                                    </div>
                                    <div class="flex flex-col space-y-1 md:w-1/3" *ngIf="isVerifyUsers">
                                        <p class="text-sm font-bold text-darkGray">Requests</p>
                                        <label class="flex flex-row w-full space-x-2 cursor-pointer label">
                                            <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                [checked]="permMember.acceptRequest"
                                                (click)="togglePermission('acceptRequest', 0)" />
                                            <span class="w-full label-text">Accept Requests</span>
                                        </label>
                                        <label class="flex flex-row w-full space-x-2 cursor-pointer label ">
                                            <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                [checked]="permMember.declineRequest"
                                                (click)="togglePermission('declineRequest', 0)" />
                                            <span class="w-full label-text">Decline Requests</span>
                                        </label>
                                    </div>
                                </div>
                            </div>


                            <div class="space-y-4">
                                <hr>

                                <div class="flex flex-col md:flex-row gap-y-4 gap-x-32 ">
                                    <div class="flex flex-col space-y-1 md:w-1/3">
                                        <p class="text-sm font-bold text-darkGray">Set Password and Contacting Users
                                        </p>
                                        <label class="flex flex-row w-full space-x-2 cursor-pointer label ">
                                            <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                [checked]="permMember.resetPass"
                                                (click)="togglePermission('resetPass', 0)" />
                                            <span class="w-full mr-24 label-text">Reset Password</span>
                                        </label>

                                        <label class="flex flex-row w-full space-x-2 cursor-pointer label">
                                            <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                [checked]="permMember.contactUsers"
                                                (click)="togglePermission('contactUsers', 0)" />
                                            <span class="w-full mr-24 label-text">Contact Users</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Post/Events/Services/Products -->
                <div tabindex="0"
                    class="mb-3 bg-gray-200 border collapse collapse-arrow border-base-300 bg-base-100 rounded-box"
                    [ngClass]="{'collapse-open':openPostCollapse, 'collapse-close':!openPostCollapse}">
                    <input type="checkbox" class="peer" (click)="selectCollapseToOpen('Post')" />
                    <div class="text-lg font-medium text-black border-2 rounded-lg collapse-title">
                        Posts / Events / Services / Products
                    </div>

                    <div class="collapse-content">
                        <div class="flex flex-col w-full px-4 py-8 space-y-8 bg-white rounded-md">
                            <div class="flex items-center space-x-2"><input type="checkbox"
                                    class="mr-4 space-x-2 checkbox checked:bg-customTeal" [checked]="allPESPriviledge"
                                    (click)="toggleAllPESPrivilege()" />
                                Give Posts/Events/Services/Products Privilege access</div>
                            <div class="space-y-4">
                                <div class="flex flex-col md:flex-row gap-y-4 gap-x-32 ">
                                    <div class="flex flex-col space-y-1 md:w-1/3">
                                        <p class="text-sm font-bold text-darkGray">Posts</p>
                                        <label class="flex flex-row w-full space-x-2 cursor-pointer label ">
                                            <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                [checked]="permPES.createPosts"
                                                (click)="togglePermissionPES('createPosts', 1)" />
                                            <span class="w-full label-text">Create Posts</span>
                                        </label>

                                        <label class="flex flex-row w-full space-x-2 cursor-pointer label ">
                                            <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                [checked]="permPES.deletePost"
                                                (click)="togglePermissionPES('deletePost', 1)" />
                                            <span class="w-full label-text">Delete Post</span>
                                        </label>

                                        <label class="flex flex-row w-full space-x-2 cursor-pointer label ">
                                            <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                [checked]="permPES.editPost"
                                                (click)="togglePermissionPES('editPost', 1)" />
                                            <span class="w-full label-text">Edit Post</span>
                                        </label>

                                    </div>

                                    <div class="flex flex-col space-y-1 md:w-1/3">
                                        <p class="text-sm font-bold text-darkGray">Service</p>
                                        <label class="flex flex-row w-full space-x-2 cursor-pointer label ">
                                            <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                [checked]="permPES.createServices"
                                                (click)="togglePermissionPES('createServices', 1)" />
                                            <span class="w-full label-text">Create Service</span>
                                        </label>

                                        <label class="flex flex-row w-full space-x-2 cursor-pointer label ">
                                            <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                [checked]="permPES.deleteServices"
                                                (click)="togglePermissionPES('deleteServices', 1)" />
                                            <span class="w-full label-text">Delete Service</span>
                                        </label>

                                        <label class="flex flex-row w-full space-x-2 cursor-pointer label">
                                            <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                [checked]="permPES.editServices"
                                                (click)="togglePermissionPES('editServices', 1)" />
                                            <span class="w-full label-text">Edit Service</span>
                                        </label>

                                    </div>

                                    <div class="flex flex-col space-y-1 md:w-1/3">
                                        <p class="text-sm font-bold text-darkGray">Service Category</p>
                                        <label class="flex flex-row w-full space-x-2 cursor-pointer label ">
                                            <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                [checked]="permPES.addServiceCategory"
                                                (click)="togglePermissionPES('addServiceCategory', 1)" />
                                            <span class="w-full label-text">Add Category</span>
                                        </label>

                                        <label class="flex flex-row w-full space-x-2 cursor-pointer label ">
                                            <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                [checked]="permPES.editServiceCategory"
                                                (click)="togglePermissionPES('editServiceCategory', 1)" />
                                            <span class="w-full label-text">Edit/Delete Category</span>
                                        </label>
                                    </div>

                                </div>
                            </div>

                            <div class="space-y-4">
                                <hr>
                                <div class="flex flex-col md:flex-row gap-y-4 gap-x-32">
                                    <div class="flex flex-col space-y-1 md:w-1/3">
                                        <p class="text-sm font-bold text-darkGray">Events</p>

                                        <label class="flex flex-row w-full space-x-2 cursor-pointer label">
                                            <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                [checked]="permPES.createEvents"
                                                (click)="togglePermissionPES('createEvents', 1)" />
                                            <span class="w-full label-text">Create Events</span>
                                        </label>

                                        <label class="flex flex-row w-full space-x-2 cursor-pointer label">
                                            <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                [checked]="permPES.editEvents"
                                                (click)="togglePermissionPES('editEvents', 1)" />
                                            <span class="w-full label-text">Edit Events</span>
                                        </label>

                                        <label class="flex flex-row w-full space-x-2 cursor-pointer label">
                                            <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                [checked]="permPES.deleteEvents"
                                                (click)="togglePermissionPES('deleteEvents', 1)" />
                                            <span class="w-full label-text">Delete Events</span>
                                        </label>
                                    </div>

                                    <div class="flex flex-col space-y-1 md:w-1/3">
                                        <p class="text-sm font-bold text-darkGray">Products</p>

                                        <label class="flex flex-row w-full space-x-2 cursor-pointer label ">
                                            <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                [checked]="permPES.createProducts"
                                                (click)="togglePermissionPES('createProducts', 1)" />
                                            <span class="w-full label-text">Create Products</span>
                                        </label>

                                        <label class="flex flex-row w-full space-x-2 cursor-pointer label ">
                                            <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                [checked]="permPES.deleteProducts"
                                                (click)="togglePermissionPES('deleteProducts', 1)" />
                                            <span class="w-full label-text">Delete Products</span>
                                        </label>

                                        <label class="flex flex-row w-full space-x-2 cursor-pointer label ">
                                            <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                [checked]="permPES.editProducts"
                                                (click)="togglePermissionPES('editProducts', 1)" />
                                            <span class="w-full label-text">Edit Products</span>
                                        </label>
                                    </div>

                                    <div class="flex flex-col space-y-1 md:w-1/3">
                                        <p class="text-sm font-bold text-darkGray">Products Category</p>
                                        <label class="flex flex-row w-full space-x-2 cursor-pointer label">
                                            <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                [checked]="permPES.addCategoryProducts"
                                                (click)="togglePermissionPES('addCategoryProducts', 1)" />
                                            <span class="w-full label-text">Add Category</span>
                                        </label>

                                        <label class="flex flex-row w-full space-x-2 cursor-pointer label">
                                            <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                [checked]="permPES.editCategoryProducts"
                                                (click)="togglePermissionPES('editCategoryProducts', 1)" />
                                            <span class="w-full label-text">Edit/delete Category</span>
                                        </label>

                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                </div>

                <!-- Analytics -->
                <div tabindex="0"
                    class="mb-3 bg-gray-200 border collapse collapse-arrow border-base-300 bg-base-100 rounded-box"
                    [ngClass]="{'collapse-open':openAnalyticsCollapse, 'collapse-close':!openAnalyticsCollapse}">
                    <input type="checkbox" class="peer" (click)="selectCollapseToOpen('Analytics')" />
                    <div class="text-lg font-medium text-black border-2 rounded-lg collapse-title">
                        Analytics
                    </div>
                    <div class="collapse-content">
                        <div class="flex flex-col w-full px-4 py-8 space-y-8 bg-white rounded-md">
                            <div class="space-y-4">
                                <div class="flex flex-col md:flex-row gap-y-4 gap-x-32 ">
                                    <div class="flex flex-col space-x-2 space-y-1">
                                        <label class="flex flex-row w-full space-x-2 cursor-pointer label ">
                                            <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                [checked]="perm.viewAnalytics" (click)="toggleViewAnalytics()" />
                                            <span class="w-full font-bold label-text text-md">View Analytics</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

                <!-- Social Media -->
                <div tabindex="0"
                    class="mb-3 bg-gray-200 border collapse collapse-arrow border-base-300 bg-base-100 rounded-box"
                    [ngClass]="{'collapse-open':openSocialCollapse, 'collapse-close':!openSocialCollapse}">
                    <input type="checkbox" class="peer" (click)="selectCollapseToOpen('Social')" />
                    <div class="text-lg font-medium text-black border-2 rounded-lg collapse-title">
                        Social Media
                    </div>

                    <div class="collapse-content">
                        <div class="flex flex-col w-full px-4 py-8 space-y-8 bg-white rounded-md">
                            <div class="flex items-center space-x-2"><input type="checkbox"
                                    class="mr-4 space-x-2 checkbox checked:bg-customTeal"
                                    [checked]="allSocialPriviledge" (click)="toggleAllSocialPrivilege()" />
                                Give Social Media Privilege access</div>
                            <div class="space-y-4">
                                <div class="flex flex-col md:flex-row gap-y-4 gap-x-32 ">
                                    <div class="flex flex-col space-y-1 md:w-1/3">
                                        <p class="text-sm font-bold text-darkGray">Account</p>

                                        <div class="flex flex-row w-full space-x-2 cursor-pointer label ">
                                            <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                [checked]="permSocial.linkAccount"
                                                (click)="togglePermissionSocial('linkAccount', 3)" />
                                            <span class="w-full label-text">Link Accounts</span>
                                        </div>

                                        <div class="flex flex-row w-full space-x-2 cursor-pointer label ">
                                            <input type="checkbox" class=" checkbox checked:bg-customTeal"
                                                [checked]="permSocial.unlinkAccount"
                                                (click)="togglePermissionSocial('unlinkAccount', 3)" />
                                            <span class="w-full label-text">Unlink Accounts</span>
                                        </div>

                                    </div>
                                    <div class="flex flex-col space-y-1 md:w-1/3">
                                        <p class="text-sm font-bold text-darkGray">Social Media Posts</p>
                                        <label class="flex flex-row w-full space-x-2 cursor-pointer label ">
                                            <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                [checked]="permSocial.createSocialPost"
                                                (click)="togglePermissionSocial('createSocialPost', 3)" />
                                            <span class="w-full label-text">Create Posts</span>
                                        </label>

                                        <label class="flex flex-row w-full space-x-2 cursor-pointer label ">
                                            <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                [checked]="permSocial.deleteSocialPost"
                                                (click)="togglePermissionSocial('deleteSocialPost', 3)" />
                                            <span class="w-full label-text">Delete Posts</span>
                                        </label>

                                        <label class="flex flex-row w-full space-x-2 cursor-pointer label">
                                            <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                [checked]="permSocial.editSocialPost"
                                                (click)="togglePermissionSocial('editSocialPost', 3)" />
                                            <span class="w-full label-text">Edit Posts</span>
                                        </label>
                                    </div>

                                    <div class="flex flex-col space-y-1 md:w-1/3">
                                        <p class="text-sm font-bold text-darkGray">Social Media Analytics</p>
                                        <label class="flex flex-row w-full space-x-2 cursor-pointer ">
                                            <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                [checked]="permSocial.socialViewAnalytics"
                                                (click)="togglePermissionSocial('socialViewAnalytics', 3)" />
                                            <span class="w-full label-text">View Analytics</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="space-y-4">
                                <div class="flex flex-col md:flex-row gap-y-4 gap-x-32 ">
                                    <div class="flex flex-col space-y-1 md:w-1/3">
                                        <p class="text-sm text-darkGray font-bold">Profile</p>

                                        <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                                            <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                [checked]="permSocial.createProfile"
                                                (click)="togglePermissionSocial('createProfile', 3)" />
                                            <span class="label-text w-full">Create Profile</span>
                                        </label>

                                        <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                                            <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                [checked]="permSocial.deleteProfile"
                                                (click)="togglePermissionSocial('deleteProfile', 3)" />
                                            <span class="label-text w-full">Delete Profile</span>
                                        </label>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <!-- Chat -->
                <div tabindex="0"
                    class="mb-3 bg-gray-200 border collapse collapse-arrow border-base-300 bg-base-100 rounded-box"
                    [ngClass]="{'collapse-open':openChatCollapse, 'collapse-close':!openChatCollapse}">
                    <input type="checkbox" class="peer" (click)="selectCollapseToOpen('Chat')" />
                    <div class="text-lg font-medium text-black border-2 rounded-lg collapse-title">
                        Chats
                    </div>

                    <div class="collapse-content">
                        <div class="flex flex-col w-full px-4 py-8 space-y-8 bg-white rounded-md">
                            <div class="flex items-center space-x-2"><input type="checkbox"
                                    class="mr-4 space-x-2 checkbox checked:bg-customTeal" [checked]="allChatPriviledge"
                                    (click)="toggleAllChatPrivilege()" />
                                Give Chat Privilege access</div>
                            <div class="space-y-4">
                                <div class="flex flex-col md:flex-row gap-y-4 gap-x-32 ">
                                    <div class="flex flex-col space-y-1 md:w-1/3">
                                        <p class="text-sm font-bold text-darkGray">Chat</p>

                                        <label class="flex flex-row w-full space-x-2 cursor-pointer label ">
                                            <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                [checked]="permChat.privateChat"
                                                (click)="togglePermissionChat('privateChat',5)" />
                                            <span class="w-full label-text">Private Chat</span>
                                        </label>

                                        <label class="flex flex-row w-full space-x-2 cursor-pointer label ">
                                            <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                [checked]="permChat.groups"
                                                (click)="togglePermissionChat('groups',5)" />
                                            <span class="w-full label-text">Groups</span>
                                        </label>

                                    </div>
                                    <div class="flex flex-col space-y-1 md:w-1/3">
                                        <p class="text-sm font-bold text-darkGray">Create</p>
                                        <label class="flex flex-row w-full space-x-2 cursor-pointer label ">
                                            <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                [checked]="permChat.createGroup"
                                                (click)="togglePermissionChat('createGroup',5)" />
                                            <span class="w-full label-text">Create Groups</span>
                                        </label>

                                        <label class="flex flex-row w-full space-x-2 cursor-pointer label ">
                                            <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                [checked]="permChat.newEmployee"
                                                (click)="togglePermissionChat('newEmployee',5)" />
                                            <span class="w-full label-text">New Employee Chat</span>
                                        </label>


                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                </div>

                <!-- Custom pages -->
                <div tabindex="0"
                    class="collapse collapse-arrow bg-gray-200 border border-base-300 bg-base-100 rounded-box mb-3"
                    [ngClass]="{'collapse-open': openCustomPageCollapse, 'collapse-close':!openCustomPageCollapse}">
                    <input type="checkbox" class="peer" (click)="selectCollapseToOpen('CustomPage')" />
                    <div class="collapse-title border-2 rounded-lg font-medium text-lg text-black">
                        Custom pages
                    </div>

                    <div class="collapse-content">
                        <div class="flex flex-col w-full bg-white px-4 py-8 space-y-8 rounded-md">
                            <div class="space-x-2 flex items-center"><input type="checkbox"
                                    class="checkbox checked:bg-customTeal mr-4 space-x-2"
                                    [checked]="allCustomPagePrivilege" (click)="toggleAllCustomPagePrivilege()" />
                                Give Custom Page privilege access</div>
                            <div class="space-y-4">
                                <div class="flex flex-col md:flex-row gap-y-4 gap-x-32 ">
                                    <div class="flex flex-col space-y-1 md:w-1/3">
                                        <p class="text-sm text-darkGray font-bold">Pages</p>
                                        <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                                            <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                [checked]="permCustomPage.viewPage"
                                                (click)="togglePermissionCustomPage('viewPage',6)" />
                                            <span class="label-text w-full">View custom pages</span>
                                        </label>

                                        <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                                            <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                [checked]="permCustomPage.createPage"
                                                (click)="togglePermissionCustomPage('createPage',6)" />
                                            <span class="label-text w-full">Create custom pages</span>
                                        </label>

                                        <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                                            <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                [checked]="permCustomPage.editPage"
                                                (click)="togglePermissionCustomPage('editPage',6)" />
                                            <span class="label-text w-full">Edit custom pages</span>
                                        </label>
                                        <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                                            <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                [checked]="permCustomPage.deletePage"
                                                (click)="togglePermissionCustomPage('deletePage',6)" />
                                            <span class="label-text w-full">Delete custom pages</span>
                                        </label>

                                    </div>
                                    <div class="flex flex-col space-y-1 md:w-1/3">
                                        <p class="text-sm text-darkGray font-bold">Items</p>
                                        <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                                            <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                [checked]="permCustomPage.viewItem"
                                                (click)="togglePermissionCustomPage('viewItem',6)" />
                                            <span class="label-text w-full">View page items</span>
                                        </label>
                                        <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                                            <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                [checked]="permCustomPage.createItem"
                                                (click)="togglePermissionCustomPage('createItem',6)" />
                                            <span class="label-text w-full">Create page Items</span>
                                        </label>

                                        <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                                            <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                [checked]="permCustomPage.editItem"
                                                (click)="togglePermissionCustomPage('editItem',6)" />
                                            <span class="label-text w-full">Edit page items</span>
                                        </label>
                                        <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                                            <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                [checked]="permCustomPage.deleteItem"
                                                (click)="togglePermissionCustomPage('deleteItem',6)" />
                                            <span class="label-text w-full">Delete page items</span>
                                        </label>

                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                </div>




                <br />
                <p class="px-4 text-lg font-bold">Grant Access for Special Privilege</p>
                <br />
                <!-- <div class="items-center space-x-2"><input type="checkbox" class="mr-4 space-x-2 checkbox checked:bg-customTeal"
                        [checked]="allSpecialPriviledge" (click)="toggleAllSpecialPrivilege()" />
                      Give All Special Privilege</div> -->

                <div tabindex="0"
                    class="mb-3 bg-gray-200 border collapse collapse-arrow border-base-300 bg-base-100 rounded-box"
                    [ngClass]="{'collapse-open':openSpecialCollapse, 'collapse-close':!openSpecialCollapse}">
                    <input type="checkbox" class="peer" (click)="selectCollapseToOpen('Special')" />
                    <div class="text-lg font-medium text-black border-2 rounded-lg collapse-title">
                        Special Application Setups
                    </div>

                        <div class="collapse-content">
                            <div class="flex flex-col w-full px-4 py-8 space-y-8 bg-white rounded-md">
                                <div class="space-y-4">
                                    <div class="flex flex-col md:grid md:grid-cols-3 gap-y-4 gap-x-4 items-start ">
                                        <div class="flex flex-col space-y-1 items-start ">
                                            <label class="flex flex-row w-full space-x-2 cursor-pointer label items-start ">
                                                <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                    [checked]="permSpecial.landingPageSetup"
                                                    (click)="togglePermissionSpecial('landingPageSetup',4)" />
                                                <span class="w-full label-text">Landing Page Setup</span>
                                            </label>
                                        </div>
                                        <div class="flex flex-col space-y-1 items-start">
                                            <label class="flex flex-row w-full space-x-2 cursor-pointer label items-start">
                                                <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                    [checked]="permSpecial.aboutUsPageSetup"
                                                    (click)="togglePermissionSpecial('aboutUsPageSetup',4)" />
                                                <span class="w-full label-text">About Us Setup</span>
                                            </label>
                                        </div>

                                        <div class="flex flex-col space-y-1 items-start">
                                            <label class="flex flex-row w-full space-x-2 cursor-pointer label items-start">
                                                <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                    [checked]="permSpecial.subInfo" (click)="togglePermissionSpecial('subInfo',4)" />
                                                <span class="w-full label-text">Customize Subscriber's
                                                    Information</span>
                                            </label>
                                        </div>

                                        <div class="flex flex-col space-y-1 items-start ">
                                            <label class="flex flex-row w-full space-x-2 cursor-pointer label items-start">
                                                <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                    [checked]="permSpecial.appSetup" (click)="togglePermissionSpecial('appSetup',4)" />
                                                <span class="w-full label-text">App Setup</span>
                                            </label>
                                        </div>

                                </div>

                                <hr />

                                    <div class="flex flex-col md:grid md:grid-cols-3 flex-wrap gap-y-4 gap-x-4 ">
                                        <div class="flex flex-col space-y-1">
                                            <label class="flex flex-row w-full space-x-2 cursor-pointer label ">
                                                <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                    [checked]="permSpecial.accountVerification"
                                                    (click)="togglePermissionSpecial('accountVerification',4)" />
                                                <span class="w-full label-text">Account Verification Requests</span>
                                            </label>
                                        </div>
                                        <div class="flex flex-col space-y-1">
                                            <label class="flex flex-row w-full space-x-2 cursor-pointer label ">
                                                <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                    [checked]="permSpecial.editClients" (click)="togglePermissionSpecial('editClients',4)" />
                                                <span class="w-full label-text">Edit Subscribers</span>
                                            </label>
                                        </div>

                                        <div class="flex flex-col space-y-1">
                                            <label class="flex flex-row w-full space-x-2 cursor-pointer label ">
                                                <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                    [checked]="permSpecial.editEmployees"
                                                    (click)="togglePermissionSpecial('editEmployees',4)" />
                                                <span class="w-full label-text">Edit Employees</span>
                                            </label>
                                        </div>

                                        <div class="flex flex-col space-y-1">
                                            <label class="flex flex-row w-full space-x-2 cursor-pointer label ">
                                                <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                    [checked]="permSpecial.permissionAccess"
                                                    (click)="togglePermissionSpecial('permissionAccess',4)" />
                                                <span class="w-full label-text">Give Permission Access</span>
                                            </label>
                                        </div>


                                        <div class="flex flex-col space-y-1">
                                            <label class="flex flex-row w-full space-x-2 cursor-pointer label ">
                                                <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                    [checked]="permSpecial.calendar" (click)="togglePermissionSpecial('calendar',4)" />
                                                <span class="w-full label-text">Calendar</span>
                                            </label>
                                        </div>

                                        <div class="flex flex-col space-y-1">
                                            <label class="flex flex-row w-full space-x-2 cursor-pointer label ">
                                                <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                    [checked]="permSpecial.newPage" (click)="togglePermissionSpecial('newPage',4)" />
                                                <span class="w-full label-text">Create New Page</span>
                                            </label>
                                        </div>

                                </div>

                                    <hr />
                                    <div class="flex flex-col md:grid md:grid-cols-3 gap-y-4 gap-x-4 ">
                                      <div class="flex flex-col space-y-1">
                                        <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                                            <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                [checked]="permSpecial.welcomePopup"
                                                (click)="togglePermissionSpecial('welcomePopup',4)" />
                                            <span class="label-text w-full">Welcome Popup</span>
                                        </label>
                                      </div>  
                                      
                                      <div class="flex flex-col space-y-1">
                                        <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                                            <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                [checked]="permSpecial.importAndDelete"
                                                (click)="togglePermissionSpecial('importAndDelete',4)" />
                                            <span class="label-text w-full">Import/Delete Contacts</span>
                                        </label>
                                      </div>
                                      
                                      <div class="flex flex-col space-y-1">
                                        <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                                            <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                [checked]="permSpecial?.editFAQ"
                                                (click)="togglePermissionSpecial('editFAQ',4)" />
                                            <span class="label-text w-full">Edit FAQ</span>
                                        </label>
                                      </div>
                      
                                      <div class="flex flex-col space-y-1">
                                        <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                                            <input type="checkbox" class="checkbox checked:bg-customTeal"
                                                [checked]="permSpecial?.editFAQ"
                                                (click)="togglePermissionSpecial('deleteFAQ',4)" />
                                            <span class="label-text w-full">Delete FAQ</span>
                                        </label>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                </div>

                <br />

            </div>

            <div class="pt-6 space-y-2">
                <!-- <div type="button" (click)="openEmployeeModal()" 
                        class="flex px-4 py-2 space-x-2 text-sm font-bold text-black rounded-lg md:px-6 w-max h-min md:text-base focus:outline-none ">
                            submit
                        </div> -->
                <div class="flex justify-end w-full space-x-4 ">
                    <button type="button" *ngIf="employeeFromHome == 'Employee'" (click)="skipSetup()"
                        class="flex space-x-1 items-center rounded border-2 text-customTeal border-customTeal px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:border-primary-accent-100 hover:bg-neutral-500 hover:bg-opacity-10 focus:border-primary-accent-100 focus:outline-none focus:ring-0 active:border-primary-accent-200 dark:text-primary-100 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10">
                        Skip
                    </button>

                    <button type="button" (click)="openEmployeeModal()"
                        class="flex items-center space-x-2  rounded bg-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-brandColor-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-brandColor-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-brandColor-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                        [ngClass]="{'bg-customTeal': employeeForm.valid, 'bg-gray-300 text-black': !employeeForm.valid}"
                        [disabled]="!employeeForm.valid ">
                        <span>Submit</span>
                    </button>
                </div>
            </div>


            <div id="my-modal-addEmployee" *ngIf="addEmployeeModal" class="modal"
                [ngClass]="{'modal-open': addEmployeeModal, 'modal-action': !addEmployeeModal }">
                <div class="modal-box">
                    <div class="modal-content">
                        <h3 class="mb-2 text-lg font-bold text-center">Add Employee</h3>

                        <p class="mt-3 text-center">An Invitation email will be sent to {{names }} {{lname}}. So that
                            S(H)E can register to this app.</p>
                        <hr />

                        <p class="mt-3 text-center">Are you sure you want to add <span class="font-bold">{{names
                                }}</span> to this app?</p>

                        <div class="flex justify-between my-4">
                            <button class="font-bold" (click)="closeEmployeeModal()">Cancel</button>
                            <button type="submit"
                                class="flex px-4 py-2 space-x-2 text-sm font-bold text-white rounded-lg md:px-6 bg-customTeal w-max h-min md:text-base hover:bg-darkBlue focus:outline-none "
                                [disabled]="!employeeForm.valid">
                                <span>Yes</span>
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<div id="my-modal-Invitation" *ngIf="finishModal" class="modal"
    [ngClass]="{'modal-open': finishModal, 'modal-action': !finishModal }">
    <div class="modal-box">
        <div class="modal-content">
            <h3 class="mb-2 text-lg font-bold text-center">Invitation Email</h3>
            <hr />
            <p class="mt-3 text-center" *ngIf="!userExists">An Invitation email will be sent to {{names
                }}
                {{lname }}. So that they can register to
                this app.</p>
            <p class="my-3 text-center" *ngIf="userExists">This account (email) already exists.</p>

        </div>
    </div>
</div>




<!-- toast -->
<app-alert class="fixed top-0 flex justify-end w-full z-[2000]" [toastType]="toastType" [toastMessage]="toastMessage"
    [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>

<!-- Crop Image Modal -->
<app-crop-image *ngIf="cropModalOpen" [theImage]="imageChangedEvent" [aspectRatio]="8 / 5" [useBase64]="useBase64"
    (returnCroppedImage)="processCroppedImage($event)" (closeCropModal)=closeCroppingModal()></app-crop-image>

<!-- Upload File Modal -->
<app-file-upload-modal *ngIf="uploadFileModalOpen" [mediaList]="mediaList"
    (returnSavedMediaURLs)="getTheUrlsSaved($event)"></app-file-upload-modal>