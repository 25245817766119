import { UserService } from './../../../services/firebase/user.service';
import { RequestToJoinModel } from 'src/app/models/RequestToJoin.Model';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { RequesttojoinService } from 'src/app/services/firebase/requesttojoin.service';
import { DatePipe, Location } from '@angular/common';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { Router } from '@angular/router';
import { InviteService } from 'src/app/services/firebase/invite.service';
import { EmailService } from 'src/app/services/api/email.service';
import { EmailModel } from 'src/app/models/emailModel';
import { GlobalConstants } from 'src/app/models/GlobalConstants';

@Component({
  selector: 'app-registration-request',
  templateUrl: './registration-request.component.html',
  styleUrls: ['./registration-request.component.css']
})
export class RegistrationRequestComponent implements OnInit {

  nationofUse = environment.firstNation.name;
  appName = environment.firstNation.displayName;
  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  requestTojoinForm: UntypedFormGroup;
  requestModel: RequestToJoinModel;
  inviteGlobal = [];
  public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')',
    ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  isMobile = false;
  requestSent = false;
  alreadyRequested = false;
  alreadyRegistered = false;
  appLogo: any;
  model: any;
  appDescription: any;
  requestCopy = [];
  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  directSignup = false;
  signupSettings;
  sideImage;
  backDropColor;

  loading = false;
  openRequestModal: boolean = false;
  openRegisteredModal: boolean = false;
  openRegisterModal: boolean = false;

  newRegistrationRequests: any;
  existingRegistrationRequests: any;
  storeEmail = [];

  currentAppName= GlobalConstants.currentAppName 
  isNationApp = environment.firstNation.Origin == "FNLeaders" ? true : false;

  constructor(
    private fb: UntypedFormBuilder,
    private requesttojoinService: RequesttojoinService,
    private location: Location,
    private appSettingsService: AppSettingsService,
    private userService: UserService,
    private router: Router,
    private emailService: EmailService,
    private inviteService: InviteService) { }

  ngOnInit() {
    this.appSettingsService.getAppSettingsList().valueChanges().subscribe((settings: any) => {
      if (settings.length > 0) {
        this.model = settings[0];
        this.appName = settings[0].appName;
        this.appLogo = settings[0].logo;
        this.appDescription = settings[0].description;

      }
      this.loading = true;
      this.appSettingsService.getSignupSettingsList().valueChanges().subscribe(s => {
        if (s) {
          if (s.length > 0) {
            this.signupSettings = s[0];
            this.sideImage = this.signupSettings.logo && this.signupSettings.logo !== '' ? this.signupSettings.logo : 'https://api.goingmobile.app/media/personal-app-assets/img/webp/ryan-ancill-aJYO8JmVodY-unsplash.webp';
            this.backDropColor = this.signupSettings.backDropColor;
            this.loading = false;

          }
        }
        this.loading = false;
      });
    });
    this.requestTojoinForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      cellPhone: [''],
      email: ['', [Validators.required, Validators.email]],
      message: [''],
      userType: ['User']
    });

    this.loading = true;
    this.appSettingsService.getSignupSettingsList().valueChanges().subscribe(s => {
      if (s) {
        if (s.length > 0) {
          this.signupSettings = s[0];
          this.sideImage = this.signupSettings.logo && this.signupSettings.logo !== '' ? this.signupSettings.logo : "https://api.goingmobile.app/media/personal-app-assets/img/webp/ryan-ancill-aJYO8JmVodY-unsplash.webp"
          this.backDropColor = this.signupSettings.backDropColor;
          this.loading = false;

        }
      }
      this.loading = false;
    });
  }

  Send(msg: any) {

  }
  SubmitRequest(model: any) {
    this.requestModel = {
      fullName: model.firstName + ' ' + model.lastName,
      firstName: model.firstName,
      lastName: model.lastName,
      email: model.email,
      cellPhone: model.cellPhone ? model.cellPhone : '',
      message: model.message,
      id: '',
      requestDate: new Date(),
      archived: false,
      userType: model.userType,
      declinedMessage: model.declinedMessage
    };

    this.userService.getUserByEmail(model.email).valueChanges().subscribe((user) => {
      if (user && user.length > 0) {
        // user already registered
        // this.alreadyRegistered = true;
        // this.alreadyRequested = false;
        this.openRegistered();
      } else {
        this.existingRegistrationRequests = this.requesttojoinService.getRegistrationRequestByEmail(model.email).valueChanges().subscribe((request) => {
          if (request && request.length > 0) {
            // this.alreadyRequested = true;
            // this.alreadyRegistered = false;
            this.openRequest();
            
          } else {
            // new registration
            this.newRegistrationRequests =  this.requesttojoinService.newRegistrationRequest(this.requestModel).then(() => {
              // this.requestSent = true;
              //this.alreadyRequested = false;
              //this.alreadyRegistered = false;
              // this.router.navigate(['/confirmation'], { state: { requestSent: this.requestSent } });
              this.openNewRegister();

              const emailModel = new EmailModel();
              emailModel.subject = `Thank you for registering with ${this.appName}`;
              emailModel.isNationApp = this.isNationApp;
              emailModel.body = '<p>';
              if (this.appLogo) {
                emailModel.body += `<img src="${this.appLogo}"
        width="100" height="100" style=" display: block; alt="Logo">`;
              }
              emailModel.body += '</p>';
              emailModel.body += `<p>Thank you for your application</p>`;
              emailModel.body += `<p>The administration will review your application and get back to you shortly. </p>`;
              emailModel.body += `<br /><p style="font-size: 18px;"><b>Thank you for using our app</b></p>`

              this.storeEmail = [];
              this.storeEmail.push(this.requestModel.email);
              emailModel.emailList = this.storeEmail;

              this.newRegistrationRequests.unsubscribe();
              this.emailService.sendEmail(emailModel).subscribe((res) => {
                if (res) {
                }
                else {
                }
              })


            });
          }
          this.existingRegistrationRequests.unsubscribe();
        });
      }

    });
  }
  backClicked() {
    this.location.back();
  }
  openRegistered() {
    this.openRegisteredModal = true;
    this.openRequestModal = false;
    this.openRegisterModal = false;

  }
  closeRegisteredModal() {
    this.openRegisteredModal = false;
  }

  openRequest() {
    this.openRequestModal = true;
    this.openRegisterModal = false;
    this.openRegisteredModal = false;
  }
  closeRequestModal() {
    this.openRequestModal = false;
  }
  openNewRegister(){
    this.openRegisterModal = true;
    this.openRequestModal = false;
    this.openRegisteredModal = false;

  }
  closeNewRegister(){
    this.openRegisterModal = false;
  }

  toast(obj) {
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green';
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
    }, 5000);
  }

  
  get firstName() { return this.requestTojoinForm.get('firstName'); }
  get lastName() { return this.requestTojoinForm.get('lastName'); }
  get email() { return this.requestTojoinForm.get('email'); }
  get cellPhone() { return this.requestTojoinForm.get('cellPhone'); }
  get message() { return this.requestTojoinForm.get('message'); }
}
