

<div class="flex flex-col-reverse mx-auto  md:space-y-0  md:flex-row">
  <!-- image -->
  <div  class="md:h-screen md:pt-32 px-3 space-y-6  py-12  justify-center w-full md:w-1/3"
  style='background-size: cover;' 
  [ngStyle]="{'background-image': 'linear-gradient(rgb(33,158,188,0.5), rgb(192,72,72,0.5))'}"
  *ngIf="signupSettings">
    <div [innerHTML]="signupSettings.title | noSanitize"></div>
    <div [innerHTML]="signupSettings.description | noSanitize"></div>

</div>

  <!-- Signup input -->
  <div class="flex flex-col px-6 py-12 items-center space-y-2 md:w-2/3  ">

    <div class="flex justify-between w-full">
      <button class=" flex flex-row  items-center text-customTeal">
          <div [routerLink]="['/']">
              <i class="material-icons">keyboard_backspace</i>
          </div>
          <div [routerLink]="['/']" class="pl-2 invisible md:visible">
              <h4 class=" text-sm  ">
                  Back to Landing page
              </h4>
          </div>
      </button>
      <img loading="lazy" [routerLink]="['/']" *ngIf="appLogo" [src]="appLogo" class="block w-32 justify-center cursor-pointer " alt="Logo ">
    </div>
    <h1 class="text-3xl font-bold text-center opacity-100 md:text-4xl ">Admin Registration</h1>
      <!-- <p class="text-md font-bold text-center text-customTeal pb-2 md:text-left ">we're glad you're here</p> -->
      <hr class=" w-48 md:w-96 border-t-1 border-lightGray ">


      <form [formGroup]="requestTojoinForm" (ngSubmit)="register(requestTojoinForm.value)">
        <div class="flex flex-col pt-12 space-y-4 mb-4 md:flex-col  ">
              <div class="px-4 md:px-0 space-y-4 md:space-y-0  md:grid grid-cols-2 gap-6">
                  <div class="flex space-y-2 md:w-96  flex-col">
                      <label for=" " class="text-xs text-gray-500 font-bold "> First Name <span class="text-red-500">*</span></label>
                      <input maxlength="60" formControlName="firstName" class=" border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline "  type="text" placeholder="John" required>
                      <div *ngIf="(firstName.dirty || firstName.touched) && firstName.invalid">
                        <small *ngIf="firstName.errors.required" class="text-red-500"> The First Name is required. </small>
                      </div>
                  </div>
                  <!-- <div class="flex space-y-2 md:w-96 flex-col ">
                      <label for=" " class="text-xs text-gray-500 font-bold "> Middle Name *</label>
                      <input formControlName="middleName" class=" border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline "  type="text" placeholder="Doe " required>
                      <div *ngIf="(middleName.dirty || middleName.touched) && middleName.invalid">
                        <small *ngIf="middleName.errors.required" class="text-red-500"> Middle Name is required. </small>
                      </div>
                  </div> -->

                  <div class="flex space-y-2 md:w-96 flex-col ">
                    <label for=" " class="text-xs text-gray-500 font-bold "> Last Name <span class="text-red-500">*</span></label>
                    <input formControlName="lastName" class=" border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline "  type="text" placeholder="Jane " required>
                    <div *ngIf="(lastName.dirty || lastName.touched) && lastName.invalid">
                      <small *ngIf="lastName.errors.required" class="text-red-500"> The Last Name is required. </small>
                    </div>
                </div>
                  <div class="flex space-y-2 md:w-96 flex-col ">
                      <label for=" " class="text-xs text-gray-500 font-bold "> Email <span class="text-red-500">*</span></label>
                      <input
                      class=" border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline "
                      type="text"
                      placeholder="JohnJane@gmail.com "
                      formControlName='email'
                      required>
                      <div *ngIf="(email.dirty || email.touched) && email.invalid">
                        <small *ngIf="email.errors.required" class="text-red-500"> The Email is required. </small>
                        <small *ngIf="email.errors.email" class="text-red-500"> wrong email format. </small>
                      </div>
                  </div>
                  <div class="flex space-y-2 md:w-96 flex-col ">
                      <label for=" " class="text-xs text-gray-500 font-bold "> Phone Number</label>
                      <input
                      formControlName='cellPhone'
                      class=" border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline "
                      type="text" placeholder="000-0000-0000 ">
                  </div>

                  <!-- <div class="">
                    <p class="text-xs text-gray-500 font-bold">Service Type <span class="text-red-500">*</span></p>
                    <div class="flex">
                      <div class="w-1/2">
                        <label>
                          <input type="checkbox" class="checked:bg-customTeal mr-2 radio-sm mt-4" formControlName="serviceType" value='Product' checked/>
                          <span>A Service</span>
                        </label>
                      </div>
                      <div class="w-1/2">
                        <label>
                          <input type="checkbox"  class="checked:bg-customTeal mr-2 radio-sm mt-4" formControlName="serviceType" value='Service'/>
                          <span>A product</span>
                        </label>
                      </div>
                    </div>
                  </div> -->

                  <div class="row">
                    <div class="flex space-y-2 md:w-96 flex-col ">
                      <label for="createPassword" class="text-xs text-gray-500 font-bold ">Create Password <span class="text-red-500">*</span></label>
                      <input id="createPassword" type="password" formControlName='createPassword' required
                      class=" border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline "
                        minlength="4" maxlength="25" />
                      <div *ngIf="(createPassword.dirty || createPassword.touched) && createPassword.invalid">
                        <small *ngIf="createPassword.errors.required" class="text-red-500"> The Password Is Required.
                        </small>
                        <small *ngIf="createPassword.errors.minlength" class="text-red-500"> Password Length Must Be At Least 4
                          Characters . </small>
                        <small *ngIf="createPassword.errors.maxlength" class="text-red-500"> Password Must Not Exceed 25
                          Characters . </small>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="flex space-y-2 md:w-96 flex-col ">
                      <label for="confirmPassword" class="text-xs text-gray-500 font-bold ">Confirm Password <span class="text-red-500">*</span></label>
                      <input id="confirmPassword" type="password" required formControlName='confirmPassword'
                      class=" border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline " >
                      <div *ngIf="(confirmPassword.dirty || confirmPassword.touched) && confirmPassword.invalid">
                        <small *ngIf="confirmPassword.errors.required" class="text-red-500">Confirm Password is required. </small>
                        <small *ngIf="confirmPassword.hasError('invalid')" class="text-red-500">Passwords Doesnt Match ! </small>
                      </div>
                    </div>
                  </div>




              </div>
              <div class="text-xs text-center text-gray-400 ">
                <p><strong>By Clicking <span class="green-text">Request To Join</span> I hereby :</strong></p>
              <p><strong>Agree and consent to the <a [routerLink]="['/eula']" target="_blank">User Agreement</a>, its
                  policies and the
                  <a [routerLink]="['/privacy-policy']" target="_blank">Privacy Policy</a>.</strong> </p>
            </div>
              <button class="px-6 py-2 border-2 rounded-lg bg-customTeal text-white focus:outline-none ">
                  Send
              </button>

          </div>
      </form>

      <p class="text-sm ">Already have an account? <button type="button " [routerLink]="['/auth/login']" class="inline-block text-sm text-customTeal font-bold hover:text-darkBlue ">Login</button>
      </p>
  </div>
</div>





<!-- <div class="container">
    <form [formGroup]="requestTojoinForm" (ngSubmit)="register(requestTojoinForm.value)">
      <div class="row card">
        <div class="center">
          <br/>
          <h6>Admin Registration</h6>
        </div>
        <div class="card-content">
          <div class="row">

            <div class="input-field col s12">
              <i class="material-icons prefix purple-text">account_circle</i>
              <input id="firstName" type="text" formControlName='firstName' maxlength="49" required class="validate"
                autocomplete="off" style="text-transform: capitalize;" />
              <label for="firstName">First Name <span class="text-red-500">*</span></label>
              <div *ngIf="(firstName.dirty || firstName.touched) && firstName.invalid">
                <small *ngIf="firstName.errors.required" class="text-red-500"> The First Name is required. </small>
              </div>
            </div>

            <div class="input-field col s12">
              <i class="material-icons prefix pink-text">account_circle</i>
              <input id="middleName" type="text" formControlName='middleName' maxlength="49" autocomplete="off"
                style="text-transform: capitalize;" />
              <label for="middleName">Middle Name</label>
            </div>

            <div class="input-field col s12">
              <i class="material-icons prefix green-text">account_circle</i>
              <input id="lastName" type="text" formControlName='lastName' maxlength="49" required class="validate"
                autocomplete="off" style="text-transform: capitalize;" />
              <label for="lastName">Last Name <span class="text-red-500">*</span></label>
              <div *ngIf="(lastName.dirty || lastName.touched) && lastName.invalid">
                <small *ngIf="lastName.errors.required" class="text-red-500"> The Last Name is required. </small>
              </div>
            </div>

            <div class="input-field col s12">
              <i class="material-icons prefix green-text text-darken-2">smartphone</i>
              <input id="cellPhone" type="text" formControlName='cellPhone' class="validate" autocomplete="off"
                [textMask]="{mask: phoneNumberMask}" />
              <label for="cellPhone">Mobile Number </label>
            </div>

            <div class="input-field col s12">
              <i class="material-icons prefix blue-text text-darken-2">email</i>
              <input id="email" type="email" formControlName='email' maxlength="49" required class="validate"
                autocomplete="off" />
              <label for="email">Email <span class="text-red-500">*</span> </label>
              <div *ngIf="(email.dirty || email.touched) && email.invalid">
                <small *ngIf="email.errors.required" class="text-red-500"> The Email is required. </small>
                <small *ngIf="email.errors.email" class="text-red-500"> wrong email format. </small>
              </div>
            </div>

            <div class="input-field col s12">
              <i class="material-icons prefix pink-text text-darken-2">place</i>
              <input id="city" type="text" formControlName='city' maxlength="49" class="validate" autocomplete="off"
                style="text-transform: capitalize;" />
              <label for="city">City : </label>
            </div>

            <br/>
            <div  class="input-field col s12">
              <p><i class="material-icons blue-text left">miscellaneous_services</i>What are you providing? <span class="text-red-500">*</span></p>
              <div class="row col s12">
                <div class="col s2"></div>
                <select formControlName="serviceType" class="col s10 ">
                  <option value="" disabled selected>select service type</option>
                  <option value="Service">A Service</option>
                  <option value="Product">A product</option>
                  <option value="Both">Both Service and Product</option>
                  <option value="None">None</option>
                </select>
              </div>

            </div>
            <br/><br/>

            <div class="row">
              <div class="input-field col s12 l12">
                <i class="material-icons prefix">lock</i>
                <input id="createPassword" type="password" formControlName='createPassword' required class="validate"
                  minlength="4" maxlength="25" />
                <label for="createPassword">Create Password</label>
                <div *ngIf="(createPassword.dirty || createPassword.touched) && createPassword.invalid">
                  <small *ngIf="createPassword.errors.required" class="text-red-500"> The Password Is Required.
                  </small>
                  <small *ngIf="createPassword.errors.minlength" class="text-red-500"> Password Length Must Be At Least 4
                    Characters . </small>
                  <small *ngIf="createPassword.errors.maxlength" class="text-red-500"> Password Must Not Exceed 25
                    Characters . </small>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="input-field col s12">
                <i class="material-icons prefix">lock</i>
                <input id="confirmPassword" type="password" required formControlName='confirmPassword' class="validate">
                <label for="confirmPassword">Confirm Password</label>
                <div *ngIf="(confirmPassword.dirty || confirmPassword.touched) && confirmPassword.invalid">
                  <small *ngIf="confirmPassword.errors.required" class="text-red-500">Confirm Password is required. </small>
                  <small *ngIf="confirmPassword.hasError('invalid')" class="text-red-500">Passwords Doesnt Match ! </small>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>



      <div class="row ">
        <div class="row center">
          <p><strong>By Clicking <span class="green-text">Signup</span> I hereby :</strong></p>
          <p><strong>Agree and consent to the <a [routerLink]="['/eula']" target="_blank">User Agreement</a>, its
              policies and
              the
              <a [routerLink]="['/privacy-policy']" target="_blank">Privacy Policy</a>.</strong> </p>
        </div>

        <button type="submit" [disabled]="!requestTojoinForm.valid" style="margin-bottom: 15px;"
          class="col s12 btn  green">
          <i class="material-icons right">cloud</i> Sign Up
        </button>

      </div>


    </form>
    <div>
      <p>Aleady have an account? <a [routerLink]="['/auth/login']">Login</a></p>
    </div>
</div> -->
