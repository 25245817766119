import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-share-status',
  templateUrl: './share-status.component.html',
  styleUrls: ['./share-status.component.css']
})
export class ShareStatusComponent implements OnInit {

  @Input() status;
  @Input() design;

  constructor() { }

  ngOnInit(): void {
  }

}
