<div>
    <div  *ngIf="preview">
        <button
        class="py-2 px-6 mr-5 mb-10 gap-2 border-customTeal border rounded-md text-black bg-white float-right"
        (click)="backToEdit();" style="margin-bottom: 5px;">
        <i class="material-icons mr-3 text-customTeal float-left">preview</i>Back to Edit 
    </button>
    </div>

    <br/>
</div>

<div class="mx-auto flex flex-col justify-center">
    <div  *ngIf="!preview" class="flex justify-end  max-w-5xl ">
        <button
        class="flex items-center space-x-2 rounded border border-solid border-customTeal bg-white justify-end  text-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal  shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-brandcotext-customTeal-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-brandcotext-customTeal-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-brandcotext-customTeal-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"        
        (click)="previewChanges()">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" class="w-3 h-3" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
            <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
          </svg>
          <span>Preview</span> 
    </button>
    </div>
    <div class="bg-white md:mx-20  max-w-5xl mx-auto p-5 mt-1 rounded-md overflow-x-hidden">
        <ng-container *ngIf="!preview">  
            <div class="py-4">
                <p class="text-gray-700 text-xl font-bold pb-1 ">Hero Settings</p>
                <div class="w-full py-0.5 bg-gray-200">
                </div>
              </div>
            
            <form [formGroup]="landingPageSettings" (ngSubmit)="heroConfirmationUpdate(landingPageSettings.value)" class="space-y-4">
                <!-- Title -->
                <div class="flex space-y-2   flex-col">
                    <label for="pageTitle" class="block tracking-wide text-gray-700 text-base font-bold mb-2">Landing Page Title</label>
                    <app-editor [control]="pageTitle" [inputType]="'title'" [fromComponent]="'Landing page'"></app-editor>
                    <div *ngIf="(pageTitle.dirty || pageTitle.touched) && pageTitle.invalid">
                        <small *ngIf="pageTitle.errors.required" class="red-text">Landing page title is required.
                        </small>
                    </div>
                </div>
    
                <!-- Description -->
                <div class="flex space-y-2   flex-col">
                    <label for="appDescription" class="block tracking-wide text-gray-700 text-base font-bold mb-2">Description</label>
                    <app-editor [control]="appDescription" [fromComponent]="'Landing page'"></app-editor>
                </div>
    
                <!-- File upload -->
                <div>
                    <div class="flex space-x-4   items-center">
                        <svg width="32" height="32" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.125 31.875H31.875C32.25 31.875 32.5313 31.7031 32.7188 31.3594C32.9063 31.0156 32.875 30.6875 32.625 30.375L27.4688 23.4844C27.2812 23.2344 27.0312 23.1094 26.7188 23.1094C26.4062 23.1094 26.1562 23.2344 25.9688 23.4844L21.0938 30L17.625 25.3594C17.4375 25.1094 17.1875 24.9844 16.875 24.9844C16.5625 24.9844 16.3125 25.1094 16.125 25.3594L12.375 30.375C12.125 30.6875 12.0938 31.0156 12.2813 31.3594C12.4688 31.7031 12.75 31.875 13.125 31.875ZM9.375 39.375C8.34375 39.375 7.46062 39.0081 6.72562 38.2744C5.99187 37.5394 5.625 36.6562 5.625 35.625V9.375C5.625 8.34375 5.99187 7.46062 6.72562 6.72562C7.46062 5.99187 8.34375 5.625 9.375 5.625H35.625C36.6562 5.625 37.5394 5.99187 38.2744 6.72562C39.0081 7.46062 39.375 8.34375 39.375 9.375V35.625C39.375 36.6562 39.0081 37.5394 38.2744 38.2744C37.5394 39.0081 36.6562 39.375 35.625 39.375H9.375ZM9.375 35.625H35.625V9.375H9.375V35.625ZM9.375 9.375V35.625V9.375Z" fill="black"/>
                            </svg>              
                            <div class="w-10/12">
                            <span class="label-text text-[#4F4F4F] text-base font-bold leading-5">Upload File</span>
                            <p class="text-xs text-gray-500">This image/video is what the users will see in the landing page.</p>
                            </div>
                    </div>
                    <div class="flex flex-col md:flex-row gap-5 py-3 w-full">
                        <!-- Image -->
                        <div class="w-full mt-5 md:w-1/2">
                            <button type="button">
                            <label class="flex flex-col py-5 px-10 bg-gray-50 rounded-lg">
                                <svg id="Layer_1" class="fill-customTeal" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7704.74 6791.12"><circle cx="2373.6" cy="2638.32" r="695.57" transform="translate(-1413.99 1788.05) rotate(-45)" style="fill:#231f20"/><path d="M7671.44,940c-178.58-178.61-416-276.93-668.57-276.93H1189.17c-252.56,0-490,98.32-668.61,276.93s-276.93,416-276.93,668.57V6508.7c0,252.56,98.36,490,276.93,668.57s416.05,276.93,668.61,276.93h5813.7c252.56,0,490-98.36,668.57-276.93s276.93-416,276.93-668.57V1608.58C7948.37,1356,7850,1118.59,7671.44,940ZM7386.2,6508.7c0,9.58-.34,19.13-1.06,28.56q-582.86-730.58-1077.85-1430.1C5530.21,4014.71,5164.73,3357.57,4738.42,3418.77c-198.19,28.45-370.5,253-715.09,702.14-455.58,593.83-525,924.41-737.08,924-300.22-.57-401.22-663.59-746.59-678.79-230.42-10.15-415.18,274.9-509.58,419.25-325.6,497.92-721.5,1073.86-1219.91,1781.25a381.07,381.07,0,0,1-4.37-57.88V1608.58c0-211.4,172-383.33,383.37-383.33h5813.7c211.36,0,383.33,171.93,383.33,383.33Z" transform="translate(-243.63 -663.08)" style="fill:#231f20"/></svg>
                                <input type="file" type="file" accept="image/*" (change)="fileChangeEventHero($event.target.files)" >
                                <span class="block text-base pt-1  text-center">Image</span>
                            </label>
                            </button>
                            <!-- <div *ngIf="isMobile">
                                <app-image-uploader class="justify-start items-start"  (onImageUpload)=onImageUpload($event) [customConfig]="{'upload':false}"></app-image-uploader>
                            </div> -->
                            <!-- Image upload Preview  -->
                            <div class="pt-5 md:pt-0 md:pl-2">
                            <div class="flex flex-wrap gap-8">
                                <div class="relative">
                                <ng-container *ngIf="base64Image" >
                                    <img loading="lazy" [src]="base64Image" class="object-cover  w-40 h-24 rounded-md cover" alt="Image">
                                    <button (click)="removeHeroImage()" type="button"
                                    class=" top-0 translate-x-16 -translate-y-4 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                                    <i class="fa fa-trash text-md"></i>
                                    </button>
                                </ng-container>
            
                                <ng-container *ngIf="modelH && modelH.logo!= '' && !base64Image">
                                <img loading="lazy" [src]="modelH.logo"
                                    class="object-cover  w-24 h-24 rounded-md cover" alt="Hero Image">
                                <button (click)="removeHeroImage()" type="button"
                                    class=" top-0 translate-x-16 -translate-y-4 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                                    <i class="fa fa-trash text-md"></i>
                                </button>{{modelH.appName}}
                                </ng-container>
                                </div>
                
                            </div>
                            </div>
                        </div>
            
                        <!-- Video -->
                        <div class="w-full mt-5 md:w-1/2">
                            <button type="button">
                            <label class="flex flex-col py-5 px-10 bg-gray-50 rounded-lg">
                                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7540.61 4795.88"><rect width="4703.35" height="4795.88" rx="348.07" style="fill:#231f20"/><path d="M7092.71,5797.22,5670.1,4995.05V3122.23l1422.61-802.17c358.38-202.07,773.6-152.74,773.6,131.07v3215C7866.31,5950,7451.09,5999.3,7092.71,5797.22Z" transform="translate(-325.69 -1660.7)" style="fill:#231f20"/></svg>
                                <input type="file" accept="video/*"
                                (change)="handleVideoInputHero($event.target.files)" />
                                <span class="block font-bold pt-1 text-center">Video</span>
                            </label>
                            </button>
            
                            <!-- Video upload Preview  -->
                            <div class="pt-5 md:pt-0 md:pl-2 w-2/3">
                            <ng-container *ngIf="videoUploadHero">
                                <video controls [src]="videoUploadHero"
                                class="object-cover w-full mt-3 md:mt-0 w-45  rounded-md cover" alt="Video">
                                </video>
                                <button (click)="removeHeroVideo()" type="button"
                                class=" bottom-0 right-0 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                                <i class="fa fa-trash text-md"></i>
                                </button>
                            </ng-container>
                
                            <ng-container *ngIf="modelH">
                                <ng-container *ngIf="modelH.videoUrl && !videoUploadHero">
                                    <video controls [src]="modelH.videoUrl"
                                    class="object-cover w-full mt-3 md:mt-0 w-45  rounded-md cover" alt="Video">
                                    </video>
                                    <button (click)="removeHeroVideo()" type="button"
                                    class=" bottom-0 right-0 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                                    <i class="fa fa-trash text-md"></i>
                                    </button>
                                </ng-container>
                            </ng-container>
                        
                            </div>
    
                        </div>
                    </div>
                </div>
    
                <div class="py-10 flex space-x-4 w-full justify-end">
                    <button
                    *ngIf="calledFromEmptyDashboard != 'Hero'"
                    (click)="backBtnClicked()"
                        type="button"
                        class="flex space-x-1 items-center rounded border-2 text-customTeal border-customTeal px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:border-primary-accent-100 hover:bg-neutral-500 hover:bg-opacity-10 focus:border-primary-accent-100 focus:outline-none focus:ring-0 active:border-primary-accent-200 dark:text-primary-100 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                        data-te-ripple-init>
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 fill-customTeal" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg>
                        <span>
                        Back To Previous Page
                        </span>
                    </button>
    
                    <button
                        *ngIf="calledFromEmptyDashboard == 'Hero'"
                        (click)="skipSetup()"
                        
                        type="button"
                        class="flex space-x-1 items-center rounded border-2 text-customTeal border-customTeal px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:border-primary-accent-100 hover:bg-neutral-500 hover:bg-opacity-10 focus:border-primary-accent-100 focus:outline-none focus:ring-0 active:border-primary-accent-200 dark:text-primary-100 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                            >
                        <span>
                        Skip
                        </span>
                    </button>
    
    
                    <button
                    type="submit"
                    class="inline-block rounded bg-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                    data-te-ripple-init
                    data-te-ripple-color="light">
                    Save Changes
                </button>               
    
                </div>
    
            </form>
        </ng-container>
    
        <ng-container *ngIf="preview">
            <app-hero-section [socialMediaModel]="" [landingPageMain]="landingPageMain" [appStore]="appStore"></app-hero-section>
        </ng-container>
    </div>
    
</div>
<!-- toast -->
<app-alert class="fixed top-0 flex justify-end w-full z-[2000]" [toastType]="toastType" [toastMessage]="toastMessage" [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>

<!-- Hero Image Modal -->
<app-crop-image *ngIf="cropModalOpenHero" [theImage]="imageChangedEventHero" [aspectRatio]="5 / 3" [useBase64]="useHeroBase64" (returnCroppedImage)="processCroppedImageHero($event)" (closeCropModal)=closeCroppingModal()></app-crop-image>

<!-- Edit Modal Hero page-->
<app-edit-confirmation *ngIf="editHeroPageModalOpen" (cancelClicked)=closeEditModal() (saveBtnClicked)=saveHeroPageUpdate() editedFromComponent='Hero Setting'></app-edit-confirmation>

<!-- Upload File Modal For Hero -->
<app-file-upload-modal *ngIf="uploadHeroFileModalOpen" [mediaList]="mediaList" (returnSavedMediaURLs)="getTheUrlHero($event)"></app-file-upload-modal>
