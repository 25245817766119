import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
// import MyCustomUploadAdapterPlugin from '../../../uploadAdapter.js';
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { ChatGptFilterModel } from "src/app/models/chatGptModel";
import { AppSettingsService } from "src/app/services/firebase/app-settings.service";
import { AuthService } from "src/app/services/firebase/auth.service";
import { CountersService } from "src/app/services/firebase/counters.service";
import { HelperService } from "src/app/services/helper/helper";

@Component({
  selector: "app-editor",
  templateUrl: "./editor.component.html",
  styleUrls: ["./editor.component.css"],
})
export class EditorComponent implements OnInit {
  @Input() control: UntypedFormControl = new UntypedFormControl();
  @Input() type;
  @Input() inputType;
  @Input() model;
  @Input() fromComponent;
  @Output() onModelChange = new EventEmitter<boolean>();

  selectedInput: UntypedFormControl;
  chatGptModalOpen = false;
  requestMessage;
  placeholder = ''
  filterModalOpen=false
  chatGptFilterForm: UntypedFormGroup
  chatGptFilter = new ChatGptFilterModel()
 

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "auto",
    minHeight: "200",
    maxHeight: "auto",
    width: "auto",
    minWidth: "0",
    translate: "yes",
    enableToolbar: true,
    showToolbar: true,
    // placeholder: 'Enter text here...',
    defaultParagraphSeparator: "",
    defaultFontName: "",
    defaultFontSize: "",
    fonts: [
      { class: "arial", name: "Arial" },
      { class: "book-antiqua", name: "Book Antiqua" },
      { class: "bookman", name: "Bookman" },
      { class: "georgia", name: "Georgia" },
      { class: "times-new-roman", name: "Times New Roman" },
      { class: "hoefler-text", name: "Hoefler Text" },
      { class: "lucida-bright", name: "Lucida Bright" },
      { class: "courier", name: "Courier" },
      { class: "calibri", name: "Calibri" },
      { class: "comic-sans-ms", name: "Comic Sans MS" },
      { class: "candara", name: "Candara" },
    ],
    uploadWithCredentials: false,
    sanitize: false,
    toolbarPosition: "top",
    toolbarHiddenButtons: [
      ["insertImage", "insertVideo", "backgroundColor", "heading"],
    ],
  };

  placeHolders = [
    "unleash your creativity with ChatGPT - Your ultimate content creation partner!",
    "let ChatGPT bring your ideas to life - Effortlessly.",
    "create captivating content with ChatGPT",
    "transform your words into magic with ChatGPT.",
    "revolutionize your writing game with ChatGPT.",
    "chatGPT - The smarter way to create content.",
    "say goodbye to writer's block with ChatGPT by your side.",
    "experience seamless content creation with ChatGPT.",
    "get inspired, get creative with ChatGPT.",
    "chatGPT - Empowering your content creation journey.",
  ];
  aboutChatGpt = "ChatGPT is an invaluable tool for content creators seeking inspiration and assistance in generating high-quality content. With its vast knowledge and language capabilities, ChatGPT acts as a creative partner, helping to overcome writer's block and enhancing the content creation process. By simply engaging in a conversation, you can tap into the model's deep understanding of various topics, enabling you to brainstorm ideas, refine concepts, and explore new perspectives. Whether you need assistance with generating engaging headlines, crafting compelling narratives, or conducting research on a specific subject, ChatGPT provides valuable insights and suggestions to elevate your content to new heights. Its ability to adapt to different writing styles and tones ensures that the final output remains authentic and aligned with your vision. ChatGPT is a powerful ally, transforming the content creation experience into a collaborative and efficient journey."
  currentDate = new Date();
  isLimitGPT: boolean = false;
  user: any;
  userCounter: any;
  chatCount: number;
  maxKeyWords: number = 3;

  constructor(private helperSerivce: HelperService,
    private counter: CountersService,
    private authService: AuthService,
    private fb: FormBuilder,
    public settingsService: AppSettingsService
    ) { }

  ngOnInit(): void {
    this.user = this.authService.getGlobalUser();

    this.chatGptFilterForm = this.fb.group({
      includeHashTag: [false],
      numberOfHashTags: [3],
      wordCount: [100],
      maxWordCount:[500],
      keyWordQuery: [''],
      keyWords: this.fb.array([])

    });
    // Get the current month
    const currentMonth = this.currentDate.getMonth();

    // Move to the next day
    const nextDay = new Date(this.currentDate);
    nextDay.setDate(this.currentDate.getDate() + 1);

    // Check if the next day is in a different month
    const isNewMonth = nextDay.getMonth() !== currentMonth;
    this.counter.getCounter().valueChanges().subscribe((userCounter: any) => {
      if (userCounter && userCounter.length != 0) {
        this.userCounter = userCounter[0];
        this.chatCount = this.userCounter.chatGptCounter;
        if (isNewMonth) {
          this.chatCount = 0;

        } else {
          if (this.chatCount <= 100) {
            this.chatCount = this.userCounter.chatGptCounter;
          }
          else {
            this.isLimitGPT = true;
          }

        }
      }
    });

    if (this.inputType && this.inputType == "title") {
      this.editorConfig.toolbarHiddenButtons[0] = [
        "insertImage",
        "subscript",
        "superscript",
        // 'justifyCenter',
        // 'justifyRight',
        // 'justifyFull',
        // 'indent',
        // 'outdent',
        "insertUnorderedList",
        "insertOrderedList",
        "link",
        "unlink",
        "backgroundColor",
        "insertVideo",
        "insertHorizontalRule",
        "heading",
      ];
    }
    let index = Math.floor(Math.random() * this.placeHolders.length);
    let type = this.inputType && this.inputType == "title" ? "title" : "content"
    let p = this.fromComponent && this.fromComponent != '' ? this.fromComponent : "text"
    this.placeholder = `Enter ${p.toLowerCase()} ${type.toLowerCase()} or ${this.placeHolders[index]}`;


    this.settingsService.getAppSettingsList().valueChanges().subscribe(
      appSettings => {
        if (appSettings && appSettings.length > 0 ) {
          let appSetting: any  = appSettings[0];
          if (appSetting && appSetting.keyWords && appSetting.keyWords.length > 0) {
            appSetting.keyWords.forEach((word: any) => {
              this.keyWords.push(this.fb.control(word))
            })
          }  
        }
      }
      );
  
  }
  get keyWordQuery() { return this.chatGptFilterForm.get('keyWordQuery')  }

  get keyWords() { return this.chatGptFilterForm.get('keyWords') as FormArray }


  get includeHashTag() {
    return this.chatGptFilterForm.get("includeHashTag");
  }

  selectKeyWord() {
    let wordExists = this.keyWords.value.some(w => w === this.keyWordQuery.value)
    if (wordExists) return
    this.keyWords.push(this.fb.control(this.keyWordQuery.value))
    this.keyWordQuery.patchValue('')

  }
  removeKeyWord(index) {
    this.keyWords.removeAt(index)
  }

  public onReady(editor) {
    editor.ui
      .getEditableElement()
      .parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
      );
  }

  onChange(event) {
    this.model = event;
    this.onModelChange.emit(this.model);
  }

  updateFormValue(event) {
    if (this.type) {
      this.model = event.message;
      this.onChange(this.model)
    } else {
      this.control.patchValue(event.message);
    }
    this.closeChatGptModal();
  }

  onUseChatGpt() {
    if(this.inputType && this.inputType == "title"){
      this.openChatGptModal();
  }else{
    this.filterModalOpen = true;
  }
  }

  applyFilter(values){
    this.closeFilterModal();
    if(values.keyWordQuery && values.keyWordQuery != ''){
      let wordExists = values.keyWords.some(w => w === values.keyWordQuery)
      if (!wordExists) values.keyWords.push(values.keyWordQuery)
    }
    
    this.chatGptFilter.includeHashTag = values.includeHashTag;
    this.chatGptFilter.numberOfHashTags = values.numberOfHashTags;
    this.chatGptFilter.wordCount = values.wordCount;
    this.chatGptFilter.keyWords = values.keyWords;
    this.openChatGptModal()
    this.keyWordQuery.patchValue('')
  }
  openChatGptModal() {
    if (this.type) {
      this.selectedInput = this.model;
      this.requestMessage = this.helperSerivce.getInnerText(this.model);
    } else {
      this.selectedInput = this.control;
      this.requestMessage = this.helperSerivce.getInnerText(this.control.value);
    }
    this.chatGptModalOpen = true;
  }

  closeChatGptModal() {
    this.chatGptModalOpen = false;
  }
  closeFilterModal(){
    this.filterModalOpen = false;
  }
}
