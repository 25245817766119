<div *ngIf="appRequests">
  <div class="flex mx-5 font-bold">
    <div class="w-1/3 text-center text-sm">
      <button (click)="pendingClicked()" 
              class="font-bold rounded-full md:px-10 border-2 border-customTeal px-2 py-1" 
              [ngClass]="{'text-white bg-customTeal': isPendingClicked, 'text-black': !isPendingClicked}">
        Pending <span *ngIf="isChecked">(Verified email)</span> <span *ngIf="!isChecked">(Unverified email)</span>
      </button>
    </div>
    <div class="w-1/3 text-center text-sm">
      <button (click)="acceptedClicked()" 
              class="font-bold rounded-full md:px-10 border-2 border-customTeal px-2 py-1" 
              [ngClass]="{'text-white bg-customTeal': isAcceptedClicked, 'text-black': !isAcceptedClicked}">
        Accepted
      </button>
    </div>
    <div class="w-1/3 text-center text-sm">
      <button (click)="declinedClicked()" 
              class="font-bold rounded-full md:px-10 border-2 border-customTeal px-2 py-1" 
              [ngClass]="{'text-white bg-customTeal': isDeclinedClicked, 'text-black': !isDeclinedClicked}">
        Declined
      </button>
    </div>
  </div>

  <!-- Pending requests (verified/unverified) -->
  <div *ngIf="isPendingClicked" class="flex flex-col space-y-2 mx-2 md:mx-10">
    <div class="flex items-center mt-5">
      <input type="checkbox" id="myCheckbox" class="mr-2" (change)="clickedStatusChecker($event)" [checked]="isChecked">
      <label class="font-bold" for="myCheckbox">View Pending Apps with verified email</label>
    </div>
  </div>

  <div *ngIf="(pendingRequests.length > 0 && isPendingClicked) || (acceptedRequests.length > 0 && isAcceptedClicked) || (rejectedRequests.length > 0 && isDeclinedClicked)" 
       class="flex flex-col space-y-2 mx-2 md:mx-10">
    <div class="flex flex-row items-center mt-2 p-2 md:space-x-6">
      <div class="w-7/12 md:w-4/12">Name</div>
      <div class="w-3/12 hidden md:block"><p class="text-sm"> Email </p></div>
      <div class="w-3/12 md:w-2/12"><p class="text-sm">Request Date</p></div>
      <div class="w-2/12 hidden md:block"><p class="text-sm">App Name</p></div>
      <div class="w-1/12 hidden md:block"></div>
    </div>
  </div>

  <!-- Display data based on selecteappd tab -->
  <div *ngIf="isPendingClicked" class="flex flex-col space-y-2 mx-2 md:mx-10">
    <div *ngIf="displayedData.length > 0" class="flex flex-col space-y-2">
      <div class="flex flex-row bg-white items-center mt-2 p-2 rounded-md border-2 md:space-x-6" 
           *ngFor="let request of displayedData">
        <div class="w-7/12 md:w-4/12 flex">
          <div class="flex items-center space-x-3 p-2">
            <div class="avatar" *ngIf="request.imgLink">
              <div class="mask mask-squircle w-10 h-10 rounded-full border border-customTeal">
                <img [src]="request.imgLink" alt="Logo" loading="lazy"/>
              </div>
            </div>
            <div>
              <div> {{request.fullName}}</div>
            </div>
          </div>
        </div>
        <div class="w-3/12 hidden md:block">{{request.email}}</div>
        <div class="w-4/12 md:w-2/12"><p class="text-sm">{{request.requestDate.toDate() | date }}</p></div>
        <div class="w-2/12 hidden md:block"><p class="text-sm">{{request.url}}.{{extension}}</p></div>
        <div class="w-1/12 cursor-pointer" [routerLink]="['/admin/app-request-detail', request.id]">
          <button type="button"><i class="material-icons material-symbols-outlined float-left">arrow_circle_right</i></button>
        </div>
      </div>
      <div class="w-full text-center mt-10">
        <app-pagination [totalItems]="total" [itemsPerPage]="itemsPerPage" [currentPage]="p" (currentPageChange)="pageChangeEvent($event)"></app-pagination>
      </div>
    </div>
    <!-- <div *ngIf="displayedData.length == 0">
      <div class="flex items-center justify-center">
        <img [src]="'../../../../assets/icons/Illustrations/RegistrationRequest-Illustration.png'"
             class="w-1/2 md:w-1/3 h-auto" alt="Illustration">
      </div>
      <p class="text-sm text-gray-400 text-center mt-4 font-bold" *ngIf="isChecked">
        No Pending (with verified email) App registration request found!
      </p>
      <p class="text-sm text-gray-400 text-center mt-4 font-bold" *ngIf="!isChecked">
        No Pending (with not verified email) App registration request found!
      </p>
    </div> -->
  </div>

  <div *ngIf="isAcceptedClicked" class="flex flex-col space-y-2 mx-2 md:mx-10">
    <div *ngIf="displayedData.length > 0" class="flex flex-col space-y-2">
      <div class="flex flex-row bg-white items-center mt-2 p-2 rounded-md border-2 md:space-x-6" 
           *ngFor="let request of displayedData">
        <div class="w-7/12 md:w-4/12 flex">
          <div class="flex items-center space-x-3 p-2">
            <div class="avatar" *ngIf="request.imgLink">
              <div class="mask mask-squircle w-10 h-10 rounded-full border border-customTeal">
                <img [src]="request.imgLink" alt="Logo" loading="lazy"/>
              </div>
            </div>
            <div>
              <div>{{request.fullName}}</div>
            </div>
          </div>
        </div>
        <div class="w-3/12 hidden md:block">{{request.email}}</div>
        <div class="w-4/12 md:w-2/12"><p class="text-sm">{{request.requestDate.toDate() | date }}</p></div>
        <div class="w-2/12 hidden md:block"><p class="text-sm">{{request.url}}.{{extension}}</p></div>
        <div class="w-1/12 cursor-pointer" [routerLink]="['/admin/app-request-detail', request.id]">
          <button type="button"><i class="material-icons material-symbols-outlined float-left">arrow_circle_right</i></button>
        </div>
      </div>
      <div class="w-full text-center mt-10">
        <app-pagination [totalItems]="total" [itemsPerPage]="itemsPerPage" [currentPage]="p" (currentPageChange)="pageChangeEvent($event)"></app-pagination>
      </div>
    </div>
    <!-- <div *ngIf="displayedData.length == 0">
      <div class="flex items-center justify-center">
        <img [src]="'../../../../assets/icons/Illustrations/RegistrationRequest-Illustration.png'"
             class="w-1/2 md:w-1/3 h-auto" alt="Illustration">
      </div>
      <p class="text-sm text-gray-400 text-center mt-4 font-bold">
        No Accepted App found!
      </p> 
    </div> -->
  </div>

  <div *ngIf="isDeclinedClicked" class="flex flex-col space-y-2 mx-2 md:mx-10">
    <div *ngIf="displayedData.length > 0" class="flex flex-col space-y-2">
      <div class="flex flex-row bg-white items-center mt-2 p-2 rounded-md border-2 md:space-x-6" 
           *ngFor="let request of displayedData">
        <div class="w-7/12 md:w-4/12 flex">
          <div class="flex items-center space-x-3 p-2">
            <div class="avatar" *ngIf="request.imgLink">
              <div class="mask mask-squircle w-10 h-10 rounded-full border border-customTeal">
                <img [src]="request.imgLink" alt="Logo" loading="lazy"/>
              </div>
            </div>
            <div>
              <div>{{request.fullName}}</div>
            </div>
          </div>
        </div>
        <div class="w-3/12 hidden md:block">{{request.email}}</div>
        <div class="w-4/12 md:w-2/12"><p class="text-sm">{{request.requestDate.toDate() | date }}</p></div>
        <div class="w-2/12 hidden md:block"><p class="text-sm">{{request.url}}.{{extension}}</p></div>
        <div class="w-1/12 cursor-pointer" [routerLink]="['/admin/app-request-detail', request.id]">
          <button type="button"><i class="material-icons material-symbols-outlined float-left">arrow_circle_right</i></button>
        </div>
      </div>
      <div class="w-full text-center mt-10">
        <app-pagination [totalItems]="total" [itemsPerPage]="itemsPerPage" [currentPage]="p" (currentPageChange)="pageChangeEvent($event)"></app-pagination>
      </div>
    </div>
    <div class="row">
      <button (click)="updateApps()" class="font-bold rounded-full md:px-10 border-2 border-customTeal px-2 py-1">Update Apps</button>
    </div>
    <!-- <div *ngIf="displayedData.length == 0">
      <div class="flex items-center justify-center">
        <img [src]="'../../../../assets/icons/Illustrations/RegistrationRequest-Illustration.png'"
             class="w-1/2 md:w-1/3 h-auto" alt="Illustration">
      </div>
      <p class="text- text-gray-400 text-center mt-4 font-bold">
        No Declined App registration request found!
      </p>
    </div> -->
  </div>
</div>


<div *ngIf="(appRequests.length == 0) ||
            (pendingRequests.length == 0 && isPendingClicked) ||
            (acceptedRequests.length == 0 && isAcceptedClicked) ||
            (rejectedRequests.length == 0 && isDeclinedClicked) ||
            (verifiedPendingAppRequests.length == 0 && isPendingClicked && isChecked) ||
            (notVerifiedPendingAppRequests.length == 0 && isPendingClicked  && !isChecked)"

  class="mt-5">
  <div class="flex items-center justify-center">
    <img  data-src="../../../../assets/icons/Illustrations/RegistrationRequest-Illustration.png"
    loading="lazy"
    class="flex w-64 items-center justify-center">
  </div>
  <div class="text-customTeal text-lg text-center font-bold">
    No
    <span *ngIf="isPendingClicked && (verifiedPendingAppRequests.length == 0)">Pending </span>
    <span *ngIf="isPendingClicked && (notVerifiedPendingAppRequests.length == 0)">Pending ( with not verified email ) </span>
    <span *ngIf="isAcceptedClicked" >Accepted</span>
    <span *ngIf="isDeclinedClicked">Declined</span>
    App registration request found.
  </div>
</div>

<!-- view description modal -->
<!-- <div id="my-modal-users" *ngIf="descriptionModalOpen" class="modal"
  [ngClass]="{'modal-open': descriptionModalOpen, 'modal-action': !descriptionModalOpen }">
  <div class="modal-box">
    <app-close-modal (closeBtnClicked)=closeDescriptionModalOpen()></app-close-modal>
    <div class="modal-content">
      <div *ngIf="requestDetail.imgURL" class="mx-auto">
        <div class="avatar">
          <div class="mask mask-squircle w-20 h-20 rounded-full">
            <img  [src]="requestDetail.imgURL" alt="Account Logo" loading="lazy"/>
          </div>
        </div>
      </div>
      <h3 class="font-bold text-lg text-center">{{requestDetail.fullName}}</h3>
      <p class="py-4 text-center">{{requestDetail.email}}</p>
      <p class="py-4 text-center">{{requestDetail.mobileNumber}}</p>
      <p class="py-4 text-center">{{requestDetail.url}}</p>
      <p class="py-4 text-center">{{requestDetail.appName}}</p>
      <div class="modal-action items-center justify-between mx-6">
        <button (click)="closeDescriptionModalOpen()"
          class="btn btn-error hover:bg-customTeal-400 text-white  border-none" type="button">Close</button>
      </div>
    </div>
  </div>
</div> -->