<div *ngIf="footerRef" class="bg-white mx-3 md:mx-20 m p-5 mt-3 rounded-md">

  <ng-container *ngIf="previewFooter">  
    <div>
        <button
            class="py-2 px-6 mr-5 mb-10 gap-2 border-customTeal border rounded-md text-black bg-white float-right"
            (click)="backToEdit();" style="margin-bottom: 5px;">
            <i class="material-icons mr-3 text-customTeal float-left">arrow_back_ios_new</i>Back to Edit 
        </button>
        
    </div>
    <app-footer></app-footer>
    
  </ng-container>

  <ng-container *ngIf="!previewFooter" >
  <div class="flex md:justify-between flex-wrap">
     
    <div class="font-bold mb-2 text-lg text-gray-600">
        Contact Information/Footer
      </div>
   
    <button  (click)="previewChanges();" 
    class="flex space-x-2 items-center rounded border-2 text-customTeal border-customTeal px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:border-primary-accent-100 hover:bg-neutral-500 hover:bg-opacity-10 focus:border-primary-accent-100 focus:outline-none focus:ring-0 active:border-primary-accent-200 dark:text-primary-100 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
    >
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" class="w-4 h-4 stroke-customTeal">
      <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
      <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
    </svg>
    <span>Preview</span>
  </button>
  </div>
  <div class="w-full border-b-2 border-solid border-gray-200 p-1">
</div>
    <label class="block pt-4">
        <span class="block tracking-wide text-base font-semibold"> Section Title  </span>
        <input type="text" id="title" [(ngModel)]="footerRef.name"
            class="w-full px-3 pb-[6px] pt-2 lg:w-8/12 appearance-none block  text-gray-700 border border-solid border-gray-300 rounded  mb-3 leading-tight focus:outline-none focus:bg-gray-50 focus:border-customTeal"
            
            placeholder="section title" />
    </label>
    <div>
        <!-- Address -->
        <label class="block">
            <span class="block tracking-wide text-base font-semibold"> Address  </span>
            <input id="address" placeholder="Address" [(ngModel)]="footerRef.address" type="text"
                class="w-full px-3 pb-[6px] pt-2 lg:w-8/12 appearance-none block  text-gray-700 border border-solid border-gray-300 rounded  mb-3 leading-tight focus:outline-none focus:bg-gray-50 focus:border-customTeal">
        </label>
        <!-- postal code -->
        <label class="block">
            <span class="block tracking-wide text-base font-semibold"> Postal Code  </span>
            <input id="pcode" placeholder="Postal Code"  [(ngModel)]="footerRef.postalCode" type="text"
                class="w-full px-3 pb-[6px] pt-2 lg:w-8/12 appearance-none block  text-gray-700 border border-solid border-gray-300 rounded  mb-3 leading-tight focus:outline-none focus:bg-gray-50 focus:border-customTeal">
        </label>
        <!-- city -->
        <label class="block">
            <span class="block tracking-wide text-base font-semibold"> City </span>
            <input id="city" placeholder="City" [(ngModel)]="footerRef.city" type="text"
                class="w-full px-3 pb-[6px] pt-2 lg:w-8/12 appearance-none block  text-gray-700 border border-solid border-gray-300 rounded  mb-3 leading-tight focus:outline-none focus:bg-gray-50 focus:border-customTeal">
        </label>
    
        <label class="block">
            <span class="block tracking-wide text-base font-semibold"> Country  </span>
            <input id="prov" placeholder="Country" [(ngModel)]="footerRef.country" type="text"
                class="w-full px-3 pb-[6px] pt-2 lg:w-8/12 appearance-none block  text-gray-700 border border-solid border-gray-300 rounded  mb-3 leading-tight focus:outline-none focus:bg-gray-50 focus:border-customTeal">
        </label>
    
        <label class="block">
            <span class="block tracking-wide text-base font-semibold"> Province </span>
            <input id="prov" placeholder="Province" [(ngModel)]="footerRef.province" type="text"
                class="w-full px-3 pb-[6px] pt-2 lg:w-8/12 appearance-none block  text-gray-700 border border-solid border-gray-300 rounded  mb-3 leading-tight focus:outline-none focus:bg-gray-50 focus:border-customTeal">
        </label>
    
        <label class="block">
            <span class="block tracking-wide text-base font-semibold">
                Info / Support Email
            </span>
            <input id="email" placeholder="Support Email" [(ngModel)]="footerRef.contactEmail" type="text"
                class="w-full px-3 pb-[6px] pt-2 lg:w-8/12 appearance-none block  text-gray-700 border border-solid border-gray-300 rounded  mb-3 leading-tight focus:outline-none focus:bg-gray-50 focus:border-customTeal">
        </label>
    
        <label>
            <span class="block tracking-wide text-base font-semibold">Phone </span>
            <input id="phone" placeholder="Phone Number"  [(ngModel)]="footerRef.phone" type="text"
                class="w-full px-3 pb-[6px] pt-2 lg:w-8/12 appearance-none block  text-gray-700 border border-solid border-gray-300 rounded  mb-3 leading-tight focus:outline-none focus:bg-gray-50 focus:border-customTeal">
        </label>
    
        <label class="block">
            <span class="block tracking-wide text-base font-semibold">
                Fax
            </span>
            <input id="fax" placeholder="Fax" [(ngModel)]="footerRef.fax" type="text"
                class="input input-bordered input-primary w-2/3">
        </label>
    </div>
    <br />
    <div class="py-10 flex space-x-4 w-full justify-end">
        <div class="form-control flex flex-col md:flex-row gap-y-2 gap-x-5 ">
          <button 
            type="button " 
            *ngIf="calledFromEmptyDashboard != 'Footer'" 
            (click)="backBtnClicked()"
            class="flex space-x-1 items-center rounded border-2 text-customTeal border-customTeal px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:border-primary-accent-100 hover:bg-neutral-500 hover:bg-opacity-10 focus:border-primary-accent-100 focus:outline-none focus:ring-0 active:border-primary-accent-200 dark:text-primary-100 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
            >
            <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 fill-customTeal" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg>

            <span>Back To Previous Page</span>
        </button>
    
       <button 
            *ngIf="calledFromEmptyDashboard != 'Footer'" type="button" 
            (click)="backBtnClicked()"
            type="button"
            class="flex space-x-1 items-center rounded border-2 text-customTeal border-customTeal px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:border-primary-accent-100 hover:bg-neutral-500 hover:bg-opacity-10 focus:border-primary-accent-100 focus:outline-none focus:ring-0 active:border-primary-accent-200 dark:text-primary-100 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
       >
          Cancel
        </button>

        <button *ngIf="calledFromEmptyDashboard == 'Footer'"
        (click)="skipSetup()"
        type="button"
        class="flex space-x-1 items-center rounded border-2 text-customTeal border-customTeal px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:border-primary-accent-100 hover:bg-neutral-500 hover:bg-opacity-10 focus:border-primary-accent-100 focus:outline-none focus:ring-0 active:border-primary-accent-200 dark:text-primary-100 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
            >
        skip
        </button>
        </div>
        <div class="form-control flex flex-row gap-x-5 ">
            <button 
            type="button"
            class="inline-block rounded bg-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            data-te-ripple-init
            data-te-ripple-color="light"
                [ngClass]="{'bg-[#cccccc] text-[#666666]': !footerRef.displayName}"
                (click)="confirmUpdate(footerRef)">
                Save
            </button>
        </div>
    </div>
</ng-container>
</div>

<!-- Edit Confirmation Modal -->
<app-edit-confirmation *ngIf="editModalOpen" (cancelClicked)=closeEditModal() (saveBtnClicked)=saveChanges() editedFromComponent='Contact Setting'></app-edit-confirmation>

<!-- toast -->
<app-alert class="fixed top-0 flex justify-end w-full z-[2000]" [toastType]="toastType" [toastMessage]="toastMessage" [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>
