import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomSectionService {

  constructor(private db: AngularFirestore) { }

  postsListRef: AngularFirestoreCollection<any>;
  postRef: AngularFirestoreDocument<any>;
  private fromMenu = new BehaviorSubject<string>(null);
  
  saveCustomSection(model: any){
    const newContent = {
      id: this.db.createId(),
      sectionTitle: model.sectionTitle,
      sectionDesign: model.sectionDesign,
      options: model.options,
      originalName: model.originalName,
      addToMenu: model.addToMenu, 
      enableInLandingPage: model.enableInLandingPage
    };
    return this.db.collection('CustomSections').doc(newContent.id).set(newContent);
  }

  getCustomSectionById(id){
    return this.db.collection('CustomSections', ref => ref.where('id', '==', id));
  }
  deleteElementsBySectionId(secId){
    return this.db.collection('CustomSectionElements', ref => ref.where('customSectionId', '==', secId)).doc().delete();
  }
  
  getCustomSectionBySectionTitle(sectionTitle){
    return this.db.collection('CustomSections', ref => ref.where('originalName', '==', sectionTitle));
  }
  getCustomSectionByOriginalName(sectionTitle){
    return this.db.collection('CustomSections', ref => ref.where('originalName', '==', sectionTitle));
  }
  getCustomSections(){
    return this.db.collection('CustomSections');
  }

  updateCustomSection(section){
    return this.db.collection('CustomSections').doc(section.id).update(section);
  }

  deleteCustomSection(section: any){
    return this.db.collection('CustomSections').doc(section.id).delete();
  }

  saveSectionElement(elt: any){
    const newContent = {
      id: this.db.createId(),
      customSectionId: elt.customSectionId,
      title: elt.title ? elt.title : '',
      description: elt.description ? elt.description : '',
      link: elt.link ? elt.link : '',
      timeStamp: elt.timeStamp ? elt.timeStamp : '',
      imgLink: elt.imgLink ? elt.imgLink : '',
      videoLink: elt.videoLink ? elt.videoLink : '',
      commentable: elt.commentable,
      reactions: elt.reactions,
      comments: elt.comments,
      createdById: elt.createdById,
      shareWith: elt.shareWith
    }
    return this.db.collection('CustomSectionElements').doc(newContent.id).set(newContent);
  }

  getSectionElementById(id){
    return this.db.collection('CustomSectionElements', ref => ref.where('id', '==', id));
  }
  getAllElementsBySectionId(customSectionId){
    return this.db.collection('CustomSectionElements', ref => ref.where('customSectionId', '==', customSectionId));
  }

  getCustomSectionElement(){
    return this.db.collection('CustomSectionElements');
  }

  updateCustomSectionElement(elt){
    return this.db.collection('CustomSectionElements').doc(elt.id).update(elt);
  }

  deleteCustomSectionElement(elt: any){
    return this.db.collection('CustomSectionElements').doc(elt.id).delete();
  }

  updateFromMenu(from: string){
    this.fromMenu.next(from);
  }

  getFromMenu(){
    return this.fromMenu.asObservable();
  }
}
