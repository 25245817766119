<div *ngIf="blog && !preview" class=" flex flex-col max-w-5xl md:mx-auto mx-4">

  <div class="border-b-4 border-customTeal">
    <div class="flex flex-row justify-between">
      <div class="flex justify-start">
        <div class="font-bold text ">
          <span class="font-bold md:text-lg">Edit</span>
        </div>
      </div>
      <div class="">
        <button 
        class="btn-custom-solid"
        [ngClass]="{'bg-[#cccccc] text-[#666666]': !editBlogForm.valid}"
        [disabled]="!editBlogForm.valid"
        (click)="previewChanges();">
        <i class="material-icons mr-3 text-white float-left">preview</i>
        Preview 
      </button>
      </div>
    </div>
  </div>

  <div class="flex flex-col lg:flex-row gap-5 overflow-x-hidden w-full py-3">
    <div class="w-full mx-auto">
      <form [formGroup]="editBlogForm" (ngSubmit)="updateBlog(editBlogForm.value)">
        <div class="flex flex-col gap-y-4  mx-auto">
          
          <!--Title Section-->        
          <div class="form-control w-full bg-white md:px-10 px-2 py-6 space-y-2 shadow-sm ">
            <div class="flex space-x-4 items-center">
      
              <svg width="36" height="36" viewBox="0 0 55 55" fill="blue"  class="text-gray-400 " xmlns="http://www.w3.org/2000/svg">
                <path d="M9 12H45.6667V16.5833H9V12ZM9 21.1667H31.9167V25.75H9V21.1667Z" fill="black"/>
                <rect x="9" y="30.3333" width="22.9167" height="4.58333" fill="black"/>
                <path d="M44.4785 22.375C44.0226 22.375 43.5855 22.5561 43.2631 22.8784C42.9408 23.2007 42.7597 23.6379 42.7597 24.0938C42.7597 24.5496 42.9408 24.9868 43.2631 25.3091C43.5855 25.6314 44.0226 25.8125 44.4785 25.8125C44.9343 25.8125 45.3715 25.6314 45.6938 25.3091C46.0162 24.9868 46.1972 24.5496 46.1972 24.0938C46.1972 23.6379 46.0162 23.2007 45.6938 22.8784C45.3715 22.5561 44.9343 22.375 44.4785 22.375ZM44.066 27.8406C42.7024 27.9552 38.9785 30.9229 38.9785 30.9229C38.7493 31.0948 38.8181 31.0833 39.0014 31.4042C39.1847 31.7135 39.1618 31.7365 39.3795 31.5875C39.6087 31.4385 39.9868 31.1979 40.617 30.8083C43.0462 29.25 41.0066 32.8479 39.9639 38.9094C39.5514 41.9115 42.2556 40.3646 42.9545 39.9063C43.642 39.4594 45.4868 38.1875 45.6701 38.0615C45.9222 37.8896 45.7389 37.7521 45.5441 37.4656C45.4066 37.2708 45.2691 37.4083 45.2691 37.4083C44.5243 37.901 43.1608 38.9323 42.9774 38.2792C42.7597 37.626 44.1576 33.1458 44.9254 30.0635C45.0514 29.3302 45.3951 27.726 44.066 27.8406V27.8406Z" fill="black"/>
                <rect x="9" y="38.3542" width="22.9167" height="4.58333" fill="black"/>
                </svg>

              <div class="w-10/12">
                <p class="font-bold text-lg">Title <span class="text-red-500 text-sm">*</span></p>
                <p class="text-xs text-gray-500">This is the first part of your post that your readers will see and the part that's responsible for getting them to click through and read the rest.</p>
              </div>
          </div>

            <app-editor [control]="postTitle" class="bg-white" [inputType]="'title'" [fromComponent]=blogsName></app-editor>
              <div *ngIf="(postTitle.dirty || postTitle.touched) && postTitle.invalid">
                <small *ngIf="postTitle.errors.required" class="text-red-500 italic"> Title is required. </small>
              </div>
          </div>

          <!-- Description Section -->
          <div class="form-control w-full bg-white md:px-10 px-2 py-6 space-y-2 shadow-sm">
            <div class="flex space-x-4 items-center">
              <svg width="36" height="36" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" >
                <path d="M4 37H16.3357V24.5531H4V37ZM7.08574 27.6667H13.25V33.8865H7.08574V27.6667ZM19.4143 35.4469H41V32.3333H19.4143V35.4469ZM4 21.4469H16.3357V9H4V21.4469ZM7.08574 12.1135H13.25V18.3333H7.08574V12.1135V12.1135ZM19.4143 10.5531V13.6667H41V10.5531H19.4143V10.5531ZM19.4143 29.2198H41V26.1062H19.4143V29.2198ZM19.4143 19.8865H41V16.7729H19.4143V19.8865Z" fill="black"/>
                </svg>
                
                <div class="w-10/12">
                  <p class="font-bold text-lg">Content <span class="text-red-500 text-sm">*</span></p>
                  <p class="text-xs text-gray-500">Discription/Content of your post in the form of text </p>
                </div>
            </div>
            <app-editor [control]="message" [fromComponent]=blogsName></app-editor>
            <div *ngIf="(message.dirty || message.touched) && message.invalid">
              <small *ngIf="message.errors.required" class="text-red-500 italic"> The Description is required. </small>
            </div>
          </div>

          <!-- Allow Comment -->
          <div class="form-control w-full bg-white md:px-10 px-2 py-6 space-y-2 shadow-sm">
            <div class="flex space-x-4 items-center">
              <svg width="36" height="36" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 32.2838H37C37.51 32.2838 37.9372 32.1135 38.2816 31.773C38.6272 31.4336 38.8 31.0127 38.8 30.5101C38.8 30.0076 38.6272 29.5861 38.2816 29.2455C37.9372 28.9062 37.51 28.7365 37 28.7365H19C18.49 28.7365 18.0622 28.9062 17.7166 29.2455C17.3722 29.5861 17.2 30.0076 17.2 30.5101C17.2 31.0127 17.3722 31.4336 17.7166 31.773C18.0622 32.1135 18.49 32.2838 19 32.2838ZM19 26.9628H37C37.51 26.9628 37.9372 26.7926 38.2816 26.452C38.6272 26.1127 38.8 25.6917 38.8 25.1892C38.8 24.6866 38.6272 24.2651 38.2816 23.9246C37.9372 23.5852 37.51 23.4155 37 23.4155H19C18.49 23.4155 18.0622 23.5852 17.7166 23.9246C17.3722 24.2651 17.2 24.6866 17.2 25.1892C17.2 25.6917 17.3722 26.1127 17.7166 26.452C18.0622 26.7926 18.49 26.9628 19 26.9628ZM19 21.6419H37C37.51 21.6419 37.9372 21.4722 38.2816 21.1328C38.6272 20.7923 38.8 20.3708 38.8 19.8682C38.8 19.3657 38.6272 18.9442 38.2816 18.6036C37.9372 18.2643 37.51 18.0946 37 18.0946H19C18.49 18.0946 18.0622 18.2643 17.7166 18.6036C17.3722 18.9442 17.2 19.3657 17.2 19.8682C17.2 20.3708 17.3722 20.7923 17.7166 21.1328C18.0622 21.4722 18.49 21.6419 19 21.6419ZM42.94 43.4578L38.8 39.3784H13.6C12.61 39.3784 11.7628 39.0313 11.0584 38.3372C10.3528 37.642 10 36.8066 10 35.8311V14.5473C10 13.5718 10.3528 12.7364 11.0584 12.0411C11.7628 11.347 12.61 11 13.6 11H42.4C43.39 11 44.2378 11.347 44.9434 12.0411C45.6478 12.7364 46 13.5718 46 14.5473V42.1719C46 42.97 45.6328 43.524 44.8984 43.8338C44.1628 44.1447 43.51 44.0194 42.94 43.4578ZM13.6 14.5473V35.8311H40.285L42.4 37.9151V14.5473H13.6ZM13.6 14.5473V37.9151V14.5473Z" fill="black"/>
                </svg>
                <div class="w-10/12">
                  <span class="label-text text-[#4F4F4F] text-base font-bold leading-5">Allow Comments?</span>
                  <p class="text-xs text-gray-500">Would you want your users to be able to comment on this post</p>
                </div>
            </div>

            <div class="flex gap-x-10 w-1/2 ">
              <div class="form-control">
                <label class="label cursor-pointer">
                  <input type="radio" formControlName="commentable" [value]=true
                    class="radio checked:bg-customTeal" />
                  <span class="label-text ml-4 font-normal text-black">Yes</span>

                </label>
              </div>
              <div class="form-control">
                <label class="label cursor-pointer">
                  <input type="radio" formControlName="commentable" [value]=false
                    class="radio checked:bg-customTeal" />
                  <span class="label-text ml-4 font-normal text-black">No</span>

                </label>
              </div>
            </div>
          </div>

          <!-- File Upload -->
          <div class="form-control w-full   bg-white md:px-10 px-2 py-6 space-y-2 shadow-sm ">
            <div class="flex space-x-4 items-center">
              <svg width="32" height="32" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.125 31.875H31.875C32.25 31.875 32.5313 31.7031 32.7188 31.3594C32.9063 31.0156 32.875 30.6875 32.625 30.375L27.4688 23.4844C27.2812 23.2344 27.0312 23.1094 26.7188 23.1094C26.4062 23.1094 26.1562 23.2344 25.9688 23.4844L21.0938 30L17.625 25.3594C17.4375 25.1094 17.1875 24.9844 16.875 24.9844C16.5625 24.9844 16.3125 25.1094 16.125 25.3594L12.375 30.375C12.125 30.6875 12.0938 31.0156 12.2813 31.3594C12.4688 31.7031 12.75 31.875 13.125 31.875ZM9.375 39.375C8.34375 39.375 7.46062 39.0081 6.72562 38.2744C5.99187 37.5394 5.625 36.6562 5.625 35.625V9.375C5.625 8.34375 5.99187 7.46062 6.72562 6.72562C7.46062 5.99187 8.34375 5.625 9.375 5.625H35.625C36.6562 5.625 37.5394 5.99187 38.2744 6.72562C39.0081 7.46062 39.375 8.34375 39.375 9.375V35.625C39.375 36.6562 39.0081 37.5394 38.2744 38.2744C37.5394 39.0081 36.6562 39.375 35.625 39.375H9.375ZM9.375 35.625H35.625V9.375H9.375V35.625ZM9.375 9.375V35.625V9.375Z" fill="black"/>
                </svg>              
                <div class="w-10/12">
                  <span class="label-text text-[#4F4F4F] text-base font-bold leading-5">Upload Files</span>
                 <p class="text-xs text-gray-500">This video is what the users will see first.</p>
  
                </div>
            </div>
            <div class="flex flex-col md:flex-row my-24">
              <!-- Image -->
              <div class="w-full mt-5 md:w-1/3">
                <button type="button" *ngIf="!isMobile">
                  <label class="flex flex-col py-5 px-10 bg-gray-50 rounded-lg">
                  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7704.74 6791.12"><circle cx="2373.6" cy="2638.32" r="695.57" transform="translate(-1413.99 1788.05) rotate(-45)" style="fill:#231f20"/><path d="M7671.44,940c-178.58-178.61-416-276.93-668.57-276.93H1189.17c-252.56,0-490,98.32-668.61,276.93s-276.93,416-276.93,668.57V6508.7c0,252.56,98.36,490,276.93,668.57s416.05,276.93,668.61,276.93h5813.7c252.56,0,490-98.36,668.57-276.93s276.93-416,276.93-668.57V1608.58C7948.37,1356,7850,1118.59,7671.44,940ZM7386.2,6508.7c0,9.58-.34,19.13-1.06,28.56q-582.86-730.58-1077.85-1430.1C5530.21,4014.71,5164.73,3357.57,4738.42,3418.77c-198.19,28.45-370.5,253-715.09,702.14-455.58,593.83-525,924.41-737.08,924-300.22-.57-401.22-663.59-746.59-678.79-230.42-10.15-415.18,274.9-509.58,419.25-325.6,497.92-721.5,1073.86-1219.91,1781.25a381.07,381.07,0,0,1-4.37-57.88V1608.58c0-211.4,172-383.33,383.37-383.33h5813.7c211.36,0,383.33,171.93,383.33,383.33Z" transform="translate(-243.63 -663.08)" style="fill:#231f20"/></svg>
                  <input type="file" type="file" accept="image/*" (change)="fileChangeEvent($event.target.files)" >
                  <span class="block font-bold pt-1 text-center">Image</span>
                  </label>
                </button>
                <div *ngIf="isMobile">
                  <app-image-uploader class="justify-start items-start"  (onImageUpload)=onImageUpload($event) [customConfig]="{'upload':false}"></app-image-uploader>
                </div>
                <!-- Image upload Preview  -->
                <div class="pt-5 md:pt-0 md:pl-2">
                  <div class="flex flex-wrap gap-8">
                    <div class="relative">
                      <ng-container *ngIf="base64Image" >
                        <img loading="lazy" [src]="base64Image" class="object-cover  w-36 h-24 rounded-md cover" alt="Image">
                        <button (click)="removeImage()" type="button"
                          class=" top-0 translate-x-16 -translate-y-4 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                          <i class="fa fa-trash text-md"></i>
                        </button>
                      </ng-container>
  
                      <ng-container *ngIf="blog && blog.mediaLink!= '' && !base64Image">
                      <img loading="lazy" [src]="blog.mediaLink"
                        class="object-cover  w-24 h-24 rounded-md cover" alt="Blog Image">
                      <button (click)="removeImage()" type="button"
                        class=" top-0 translate-x-16 -translate-y-4 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                        <i class="fa fa-trash text-md"></i>
                      </button>
                      </ng-container>
                    </div>
    
                  </div>
                </div>
              </div>
  
              <!-- Video -->
              <div class="w-full mt-5 md:w-1/3">
                <button type="button">
                  <label class="flex flex-col py-5 px-10 bg-gray-50 rounded-lg">
                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7540.61 4795.88"><rect width="4703.35" height="4795.88" rx="348.07" style="fill:#231f20"/><path d="M7092.71,5797.22,5670.1,4995.05V3122.23l1422.61-802.17c358.38-202.07,773.6-152.74,773.6,131.07v3215C7866.31,5950,7451.09,5999.3,7092.71,5797.22Z" transform="translate(-325.69 -1660.7)" style="fill:#231f20"/></svg>
                    <input type="file" accept="video/*"
                    (change)="handleVideoInput($event.target.files)" />
                    <span class="block font-bold pt-1 text-center">Video</span>
                  </label>
                </button>
  
                <!-- Video upload Preview  -->
                <div class="pt-5 md:pt-0 md:pl-2 w-2/3">
                  <ng-container *ngIf="attachedVideo">
                    <div>UPLoad preview</div>
                    <video controls [src]="attachedVideo"
                    class="object-cover w-full mt-3 md:mt-0 w-45  rounded-md cover" alt="Video">
                    </video>
                    <button (click)="removeVideo()" type="button"
                      class=" bottom-0 right-0 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                      <i class="fa fa-trash text-md"></i>
                    </button>
                  </ng-container>
    
                  <ng-container *ngIf="blog.videoLink && !attachedVideo">
                    <div>Video link</div>
                    <video controls [src]="blog.videoLink"
                    class="object-cover w-full mt-3 md:mt-0 w-45  rounded-md cover" alt="Video">
                    </video>
                    <button (click)="removeVideo()" type="button"
                      class=" bottom-0 right-0 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                      <i class="fa fa-trash text-md"></i>
                    </button>
                  </ng-container>
                </div>

              </div>

            </div>
          </div>

          <!-- Additional Media -->
          <div class="form-control w-full bg-white md:px-10 px-2 py-6 space-y-2 shadow-sm">

            <div class="flex space-x-4 items-center">
              <svg width="36" height="36" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.125 31.875H31.875C32.25 31.875 32.5313 31.7031 32.7188 31.3594C32.9063 31.0156 32.875 30.6875 32.625 30.375L27.4688 23.4844C27.2812 23.2344 27.0312 23.1094 26.7188 23.1094C26.4062 23.1094 26.1562 23.2344 25.9688 23.4844L21.0938 30L17.625 25.3594C17.4375 25.1094 17.1875 24.9844 16.875 24.9844C16.5625 24.9844 16.3125 25.1094 16.125 25.3594L12.375 30.375C12.125 30.6875 12.0938 31.0156 12.2813 31.3594C12.4688 31.7031 12.75 31.875 13.125 31.875ZM9.375 39.375C8.34375 39.375 7.46062 39.0081 6.72562 38.2744C5.99187 37.5394 5.625 36.6562 5.625 35.625V9.375C5.625 8.34375 5.99187 7.46062 6.72562 6.72562C7.46062 5.99187 8.34375 5.625 9.375 5.625H35.625C36.6562 5.625 37.5394 5.99187 38.2744 6.72562C39.0081 7.46062 39.375 8.34375 39.375 9.375V35.625C39.375 36.6562 39.0081 37.5394 38.2744 38.2744C37.5394 39.0081 36.6562 39.375 35.625 39.375H9.375ZM9.375 35.625H35.625V9.375H9.375V35.625ZM9.375 9.375V35.625V9.375Z" fill="black"/>
                </svg>              
                <div class="w-10/12">
                  <span class="label-text text-[#4F4F4F] text-base font-bold leading-5">Additional Media</span>
                <p class="text-xs text-gray-500">Attach additional information regarding your post</p>
                </div>
            </div>
          <!-- Additional blog Link -->
          <div >
            <div class="space-y-2 pt-4">
              <span class="label-text text-[#4F4F4F] text-base font-bold leading-5">
                Additional Link or URL</span>
              <div class="input-field">
                <input id="sharedLink" type="text" formControlName='sharedLink'
                  class="input input-bordered rounded-sm bg-gray-100 w-full md:w-7/12 text-gray-700 leading-tight"
                  [ngClass]="{
                    'border-red-500': (sharedLink.dirty || sharedLink.touched) && sharedLink.invalid,
                    'border-gray-200': !((sharedLink.dirty || sharedLink.touched) && sharedLink.invalid)
                  }" autocomplete="off" />
                <div *ngIf="(sharedLink.dirty || sharedLink.touched) && sharedLink.invalid">
                  <small class="text-red-500 italic">Please enter a valid URL. </small>
                </div>
              </div>
            </div>
          </div>

          </div>

          <!-- Share with -->
          <div class="form-control w-full bg-white md:px-10 px-2 py-6 space-y-2 shadow-sm">

          <div class="flex space-x-4 items-center">
            <svg width="36" height="36" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M41.565 6.43502C41.3626 6.23353 41.1068 6.09403 40.8278 6.03291C40.5488 5.97179 40.2581 5.99161 39.99 6.09002L6.99 18.09C6.7054 18.198 6.46038 18.3899 6.28748 18.6405C6.11457 18.891 6.02197 19.1881 6.02197 19.4925C6.02197 19.7969 6.11457 20.0941 6.28748 20.3446C6.46038 20.5951 6.7054 20.7871 6.99 20.895L21.39 26.655L27.15 41.055C27.2582 41.3271 27.4437 41.5616 27.6836 41.7295C27.9235 41.8974 28.2073 41.9915 28.5 42C28.8031 41.9938 29.0973 41.8959 29.3436 41.7191C29.59 41.5424 29.777 41.2952 29.88 41.01L41.88 8.01002C41.9822 7.74464 42.0068 7.45567 41.9511 7.17681C41.8953 6.89795 41.7614 6.64069 41.565 6.43502ZM28.5 36.3L24.315 25.8L31.5 18.615L29.385 16.5L22.14 23.745L11.7 19.5L37.995 10.005L28.5 36.3Z" fill="black"/>
            </svg>
              <div class="w-10/12">
                <p class="font-bold text-lg">Share with <span class="text-red-500 text-sm">*</span></p>
                <p class="text-xs text-gray-500">Manage the visibility of this post by sharing it either to your subscribers, employees or to the public </p>
              </div>
            </div>   
        <div class="w-min">
          <div class="flex">
            <div class="form-control ">
              <label class=" label cursor-pointer">
                <input type="radio" formControlName="shareWith" value="Public"
                  class="radio checked:bg-customTeal" (click)="onShareChange('Public')"/>
                <span class=" ml-4 font-normal text-black">Public</span>
              </label>
            </div>
          </div>
          <div class="form-control">
            <div>
              <label class="label cursor-pointer">
                <input type="radio" formControlName="shareWith" value="Employee"
                  class="radio checked:bg-customTeal" (click)="onShareChange('Employee')"/>
                <span class="ml-4 font-normal text-black">Employees</span>
              </label>
            </div>
          </div>
          <div class="form-control">
            <div>
              <label class="label cursor-pointer">
                <input type="radio" formControlName="shareWith" value="User"
                  class="radio checked:bg-customTeal" (click)="onShareChange('User')"/>
                <span class=" ml-4 font-normal text-black">Subscribers</span>
              </label>
            </div>
          </div>
        </div>
          </div>

          <!-- Areas of interest Section -->
          <div class="form-control w-full bg-white md:px-10 px-2 py-6 space-y-2 shadow-sm" *ngIf="['User','Employee'].includes(shareWith.value) && interestAreas.length > 0">
            <p class="label-text text-[#4F4F4F] text-base font-bold leading-5">Areas of Interest relevant to your post.</p>
            <div>
              <label class="col s6">
                <input class="checkbox" type="checkbox" [checked]="selectedInterestAreas && selectedInterestAreas.length==interestAreas.length"
                  (change)="onChange($event, 'selectAll')" />
                <span class="ml-3">Select All</span>
              </label>

              <div *ngFor="let interestArea of interestAreas" class="col s6">
                <label class="ml-4">
                  <input class="checkbox" [checked]="selectedInterestAreas.indexOf(interestArea.id) > -1" type="checkbox"
                    (change)="onChange($event, interestArea)" />
                  <span class="ml-3">{{interestArea.name}}</span>
                </label>
              </div>
            </div>


          </div>

          <!-- Departments section -->
          <div class="form-control w-full bg-white md:px-10 px-2 py-6 space-y-2 shadow-sm" *ngIf="shareWith.value === 'Employee' && departments.length > 0">
            <p class="label-text text-[#4F4F4F] text-base font-bold leading-5">Department relevant to your post.</p>
            <div>
              <label class="col s6">
                <input class="checkbox" type="checkbox" [checked]="selectedDepartments.length==departments.length"
                  (change)="onDepartmentChange($event, 'selectAll')" />
                <span class="ml-3">Select All</span>
              </label>

              <div *ngFor="let dep of departments" class="col s6">
                <label class="ml-4">
                  <input class="checkbox" [checked]="selectedDepartments.includes(dep.id)" type="checkbox"
                    (change)="onDepartmentChange($event, dep)" />
                  <span class="ml-3">{{dep.name}}</span>
                </label>
              </div>
            </div>
          </div>

          <div>
              <app-form-button  *ngIf="editBlogForm.valid" buttonStyle="preview" buttonType="button" (click)="previewChanges()"></app-form-button>
          </div>
        <div class="flex md:flex-row flex-col justify-between w-full max-w-x pt-2 md:pt-20 gap-y-4   ">
          <div class="form-control flex flex-row justify-between gap-x-4 w-full">
            <div class="flex">
              <app-form-button buttonText="Cancel" buttonStyle="cancel" buttonType="button" (click)="cancelModalOpen=true"></app-form-button>
              <app-form-button buttonText="Delete" buttonStyle="delete" buttonType="button" (click)="openDeleteModal()" *ngIf="isAdmin || (permPES && permPES.deletePost)"></app-form-button>
            </div>
            <app-form-button buttonText="Update" [disabled]="!editBlogForm.valid" buttonStyle="filled" buttonType="button" (click)="editModalOpen=true"></app-form-button>
            
          </div>
        </div>
      </div>
      </form>

    </div>
  </div>
</div>


<div *ngIf="preview && blog">
  <div class="flex flex-row-reverse mb-2 mr-5 md:ml-0">
    <button class="btn-custom-bordered" (click)="editModalOpen=true">Save Changes</button>
    <button class="btn-custom-solid" (click)="preview=false">Back to previous page</button>
  </div>
  <app-view-blog [modelToPreview]="blogToPreview"></app-view-blog>
</div>

<!-- Delete Modal -->
<app-delete-confirmation *ngIf="deleteModalOpen" (deleteClicked)=archivePost() (cancelDeleteClicked)="deleteModalOpen=false" [deletedFromComponent]=blogsName></app-delete-confirmation>

<!-- Cancel Modal -->
<app-cancel-confirmation *ngIf="cancelModalOpen" (cancelClicked)=backClicked() (continueClicked)=closeCancelModal() canceledFromAction="Editing" [canceledFromComponent]=blogsName></app-cancel-confirmation>

<!-- Edit Modal -->
<app-edit-confirmation *ngIf="editModalOpen" (cancelClicked)=closeEditModal() (saveBtnClicked)=updateBlog(editBlogForm.value) [editedFromComponent]=blogsName></app-edit-confirmation>

<!-- Success/failure alert -->
<app-alert class="fixed top-0 flex justify-end w-full" [toastType]="toastType" [toastMessage]="toastMessage" [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>

  <!-- Crop Image Modal -->
<app-crop-image *ngIf="cropModalOpen" [theImage]="imageChangedEvent" [useBase64]="useBase64" [aspectRatio]="4 / 3" (returnCroppedImage)="processCroppedImage($event)" (closeCropModal)=closeCroppingModal()></app-crop-image>

<!-- Upload File Modal -->
<app-file-upload-modal *ngIf="uploadFileModalOpen" [mediaList]="mediaList" (returnSavedMediaURLs)="getTheUrlsSaved($event)"></app-file-upload-modal>
