import { Component, Input, OnInit, } from '@angular/core';
import { retry } from 'rxjs/operators';

@Component({
    selector: 'app-circle-img',
    templateUrl: './circle-img.component.html',

})
export class CircleImgComponent implements OnInit {


    @Input() data;
    constructor() { }


    ngOnInit() {
    }

    getCustomCss(){
        let defaultCss = 'rounded-full w-14 text-white font-bold';
        let imgWidth  = this.data.imgWidth && this.data.imgWidth !== '' ? this.data.imgWidth : 'w-14 text-white'
        defaultCss = this.extendCss(defaultCss,imgWidth)

        let style  = this.data.style && this.data.style !== '' ? this.data.style : 'bg-customTeal text-neutral-content'
        defaultCss = this.extendCss(defaultCss,style)

        return defaultCss

    }

    extendCss(clas, newCss){
        clas  = clas + ' ' + newCss + ' '
        return clas
    }
}
