<div class="flex flex-col items-center justify-center p-4 pt-12 md:p-32 space-y-2">
    <i class="fa-solid fa-circle-check fa-2xl text-customTeal"></i>
    <p class="text-2xl font-bold text-customTeal pt-4">Thank you for your order</p>
    <p class="font-sm text-center w-full md:w-2/5 ">Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam
        et dignissimos, repellendus nobis nulla at. Totam, molestias? Corrupti odio, omnis, </p>
    <div class="flex flex-col  w-full md:w-2/5 bg-white rounded-md p-9 space-y-6   ">
        <div class="flex justify-between ">
            <p class="font-medium">Date</p>
            <p class="font-bold">Jul 2,2020</p>
        </div>
        <div class="flex justify-between">
            <p class="font-medium">Items</p>
            <p class="font-bold">20</p>
        </div>
        <div class="flex justify-between">
            <p class="font-medium">Amount Paid</p>
            <p class="font-bold">$220</p>
        </div>
        <div class="flex w-full md:pt-6 justify-center">
            <button [routerLink]="['/user/home']"
                class=" flex items-center space-x-2 justify-center px-4 md:px-6 py-2 bg-customTeal text-white rounded-lg w-max h-min text-sm md:text-base font-bold hover:bg-darkBlue focus:outline-none  ">
                <i class="fa fa-house"></i>
                <span>Back to home page</span>
            </button>
        </div>
    </div>
</div>