import { Component, OnInit } from '@angular/core';
import { ContactService } from 'src/app/services/firebase/contact.service';
import { read, utils } from 'xlsx';
import { ToastrService } from "ngx-toastr";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoadingService } from 'src/app/services/loader/loading.service';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css']
})
export class ContactFormComponent implements OnInit {

  contacts: any[] = []
  email: boolean = false;
  name: boolean = false;
  tel: boolean = false;
  address: boolean = false;
  icon: boolean = false;
  results: boolean = false;
  rawResults: boolean = false;
  multiple: boolean = true;
  supported: boolean = false;
  newContacts: any[] = [];
  existingContacts: any[] = [];
  contactHeaders: any = [];
  contactForm: UntypedFormGroup;
  showForm: boolean = false;

  // to keep track of if we have existing headers 
  existingHeader: any = null


  activePage: any = 1;
  availableFields: any[] = [
    {
      label: "First Name",
      colName: "first_name"
    },
    {
      label: "Middle Name",
      colName: "middle_name"
    },
    {
      label: "Last Name",
      colName: "last_name"
    },
    {
      label: "Prefix",
      colName: "prefix"
    },
    {
      label: "Suffix",
      colName: "suffix"
    },
    {
      label: "Primary Phone Number",
      colName: "phoneOne"
    },
    {
      label: "Secondary Phone Number",
      colName: "phoneTwo"
    },
    {
      label: "Other",
      colName: "other"
    }

  ];

  contactDataFormat: any = {};
  selectedFields: any[] = [];

  // email regex, to check if the field is an email
  validEmailRegex: any = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  constructor(private contactService: ContactService, private toastr: ToastrService, private fb: UntypedFormBuilder,
    private router: Router, private loaderService: LoadingService
  ) {

  }

  ngOnInit(): void {
    this.supported = ('contacts' in navigator && 'ContactsManager' in window);

    this.contactForm = this.fb.group({
      fieldName: ['', [Validators.required]],
      fieldDataType: ['', [Validators.required]],
      optionalFieldType: [''],
      isPhoneNumber: [false]
    });

    if (this.supported) {
      this.checkProperties();
    }

    // iterate over the available fields and structure contacts data format
    this.resetContactDataFormat();

    // fetch contacts
    this.fetchContacts();

    // fetch contact headers
    this.fetchContactHeaders();
  }

  async checkProperties() {

    let newNavigator: any = navigator;
    const supportedProperties = await newNavigator.contacts.getProperties();

    if (supportedProperties.includes('name')) {
      this.name = true;
    }
    if (supportedProperties.includes('email')) {
      this.email = true;
    }
    if (supportedProperties.includes('tel')) {
      this.tel = true;
    }
    if (supportedProperties.includes('address')) {
      this.address = true
    }
    if (supportedProperties.includes('icon')) {
      this.icon = true
    }
  }

  async getContacts() {

    const props = [];
    if (this.name) props.push('name');
    if (this.email) props.push('email');
    if (this.tel) props.push('tel');
    if (this.address) props.push('address');
    if (this.icon) props.push('icon');


    const opts = { multiple: this.multiple };

    let newNavigator: any = navigator;

    try {
      const contacts = await newNavigator.contacts.select(props, opts);

      // iterate over the contacts list 
      if (contacts?.length > 0) {
        for (let i = 0; i < contacts?.length; i++) {
          let contact = contacts[i];
          this.contacts.push({
            name: contact?.name,
            email: contact?.email,
            tel: contact?.tel,
          });

        }
      }

      // handleResults(contacts);
    } catch (ex) {
      console.log("error fetching contacts ", ex)
    }

  }

  // csv import
  csvImport($event: any) {
    const files = $event.target.files;
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;

        if (sheets.length) {
          let rows: any[] = utils.sheet_to_json(wb.Sheets[sheets[0]]);

          // this.newContacts = rows;
          let validContacts = [];


          // iterate over the contacts and check if the contacts exist already
          if (rows?.length) {
            for (let i = 0; i < rows?.length; i++) {

              // reset data
              this.resetContactDataFormat()
              let pushToContacts = true;
              let contactData = {};

              // check if we have an email field bsed on the first row
              this.checkEmailField(i, rows[0]);


              // iterate over the added fields, so that we can extract data related to that
              for (let j = 0; j < this.selectedFields?.length; j++) {

                // console.log("under selected fields for loop .... ", rows)

                let selectedField = this.selectedFields[j];

                let currentColumnValue = this.getExcelColValue(rows[i], selectedField);

                if (selectedField?.isPhoneNumber) {

                  let phoneNumber = currentColumnValue;

                  let cleanedPhone = phoneNumber ? (phoneNumber).toString().replace(/[^\w\s]/gi, '').replace(/\s+/g, '') : '';

                  // check  if the phone num contains all nums
                  let isnum = /^\d+$/.test(cleanedPhone)


                  // check the length of the phone number
                  if (cleanedPhone?.length == 10 && isnum) {

                    rows[i][selectedField?.fieldName] = this.maskPhoneNumber(cleanedPhone)

                    // check if there are existing contacts, if there are any, check if the phone number exist or not.
                    if (this.existingContacts?.length > 0) {

                      // find if there is a user with the specified phone number
                      let contact = this.existingContacts.find((contact) => contact?.[selectedField?.fieldType] == currentColumnValue);

                      if (!contact) {
                        // validContacts.push(rows[i]);
                        contactData[selectedField?.fieldType] = {
                          value: currentColumnValue ? currentColumnValue : null,
                          isPhoneNumber: selectedField?.isPhoneNumber,
                          isEmail: selectedField?.isEmail ?  selectedField?.isEmail : false
      
                        }

                      }
                      else {
                        pushToContacts = false
                      }

                    }
                    else {
                      contactData[selectedField?.fieldType] = {
                        value: currentColumnValue ? currentColumnValue : null,
                        isPhoneNumber: selectedField?.isPhoneNumber,
                        isEmail: selectedField?.isEmail ?  selectedField?.isEmail : false
    
                      }
                    }

                  }

                }
                else if (selectedField?.isEmail) {
                  if (this.validEmailRegex.test(rows[i]?.[selectedField?.fieldName])) {
                    contactData[selectedField?.fieldType] = {
                      value: currentColumnValue ? currentColumnValue : null,
                      isPhoneNumber: selectedField?.isPhoneNumber,
                      isEmail: selectedField?.isEmail ?  selectedField?.isEmail : false
  
                    }
                  }
                }
                else {

                  contactData[selectedField?.fieldType] = {
                    value: currentColumnValue ? currentColumnValue : null,
                    isPhoneNumber: selectedField?.isPhoneNumber,
                    isEmail: selectedField?.isEmail ?  selectedField?.isEmail : false

                  }
                  
                }
              }



              // we need to check , at least one of the key value pair are not null
              if (this.validContactDataFormat({ ...contactData }) && pushToContacts) {
                validContacts.push({ ...contactData, created_at: new Date().toISOString() })
              }
            }

          }


          // check if we have valid contacts which we can insert to the database
          if (validContacts?.length) {


            // console.log("valid contacts list .... ", validContacts)
            // show loader
            this.showLoader();

            // save it to the database
            this.contactService.insertContactsData(validContacts).then(async (response) => {
              this.hideLoader();

              // insert the contacts header by extracting header from the first row
              let contactsHeader = [];

              for (let i = 0; i < this.selectedFields?.length; i++) {
                contactsHeader.push({
                  value : this.selectedFields[i]?.fieldType,
                  type: this.checkFieldType(this.selectedFields[i])
                });
              }

              await this.saveContactsHeader(contactsHeader)

              this.router.navigate(['/admin/contact-list/']);

            }).catch((err) => {
              console.log("err is is ", err)
              this.errorMessage("Something went wrong when saving contacts!")
              this.hideLoader();
            });

          }
          else {
            this.errorMessage("Please import a valid excel file or update the fields you added! We could not extract infromation from you file!")
          }

        }
      };

      reader.readAsArrayBuffer(file);
    }
  }

  // check data type 
  checkFieldType(selectedField){
    let type = "";
    if(selectedField?.isPhoneNumber)
    {
      type="Phone";
    }
    else if(selectedField?.isEmail)
    {
      type="Email"

    }
    else{
      type= selectedField?.fieldDataType;
    }

    return type;


  }


  // get specfic excel column value using a key, since the user can add space and quotation around the key, we need to trim the spce and the quotation
  getExcelColValue(row, selectedField)
  {
    let columnValue = null;
    // iterate over the object keys and trim the spaces
    Object.keys(row).map((rowKey) => {
      let newKey = rowKey.replace(/^\s+|\s+$/gm,'')
     
      if(newKey === selectedField?.fieldName)
      {
        columnValue = row?.[rowKey]

      }

    })
    return columnValue;
  }

  // check if we have an email field
  checkEmailField(currentIndex, firstRow) {
    // if it is the first row , iterate over the selected fields and identify if we have any email fields
    if (currentIndex === 0) {
      for (let i = 0; i < this.selectedFields?.length; i++) {

        if (this.validEmailRegex.test(firstRow?.[this.selectedFields[i]?.fieldName]) || this.selectedFields[i]?.fieldDataType === "Email") {
          this.selectedFields[i]['isEmail'] = true
        }
      }

    }

  }

  saveContactsHeader(contactHeaders) {
    // check if we have existing contact headers , if not add it

    if(this.existingHeader)
    {

      let headerData = {
        uid: this.existingHeader?.id,
        headers: contactHeaders
      };
  
      this.contactService.updateContactHeader(headerData).then((response) => {
        console.log("header submitted succesfully ..... ")
  
      }).catch((err) => {
        console.log("error while adding contact headers ", err)
      })

    }
    else{
      let headerData = {
        uid: this.contactService.createID(),
        headers: contactHeaders
      };
  
  
      this.contactService.saveContactHeader(headerData).then((response) => {
        console.log("header submitted succesfully ..... ")
  
      }).catch((err) => {
        console.log("error while adding contact headers ", err)
      })
    }
  

  }

  // reset contact data format
  resetContactDataFormat() {

    for (let i = 0; i < this.availableFields?.length; i++) {
      if (this.availableFields[i]?.colName === "other") {
        this.contactDataFormat[this.availableFields[i].colName] = []

      }
      else {
        this.contactDataFormat[this.availableFields[i].colName] = null;

      }

    }
  }

  // check if the object values are not null
  validContactDataFormat(contactData) {

    let isValid = false;

    for (let i = 0; i < Object.keys(contactData)?.length; i++) {

      let objectKey = Object.keys(contactData)?.[i]

      // if the curren field is added as additional field, use another loop to loop inside that object
      if (objectKey == "other") {

        let additionalInfo = contactData["other"];

        // iterate over the object keys
        for (let j = 0; j < Object.keys(additionalInfo)?.length; j++) {
          let otherKey = Object.keys(additionalInfo)?.[j]
          if (additionalInfo[otherKey]) {
            isValid = true;
            return isValid;

          }

        }

      }
      else {
        if (contactData[objectKey]) {
          isValid = true;
          return isValid;

        }
      }
    }

    return isValid;

  }

  // mask phone number
  maskPhoneNumber(phone: any) {
    const format = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
    const numericString = phone.replace(/\D/g, '');

    let maskedString = '';
    let numericIndex = 0;

    format.forEach(char => {
      if (typeof char === 'string') {
        maskedString += char;
      } else {
        maskedString += numericString.charAt(numericIndex);
        numericIndex++;
      }
    });

    return maskedString;
  }

  // toast message handler
  successMessage(message) {
    this.toastr.success(message, "Success");
  }

  errorMessage(message) {
    this.toastr.error(message, "Error");
  }

  addField() {
    this.showForm = true;
  }

  pushToField(value) {
 
    let fieldData = {
      fieldType: this.contactForm.value.optionalFieldType,
      fieldName: this.contactForm.value.fieldName,
      fieldDataType : this.contactForm.value.fieldDataType,
      isPhoneNumber: this.contactForm.value.isPhoneNumber ? this.contactForm.value.isPhoneNumber : this.contactForm.value.fieldDataType === "Phone" ? true : false
    }

    this.selectedFields.push({ ...fieldData });

    // reset the form data
    this.contactForm.reset();


    this.showForm = false;


  }

  // delete fields
  deleteSelectedField(index){
    this.selectedFields.splice(index, 1)
  }

  // fetch contacts
  fetchContacts() {

    this.showLoader();

    this.contactService.getContacts().subscribe((querySnapshot) => {
      // console.log("contacts ... ", contacts)
      let contacts = [];

      querySnapshot.forEach((doc) => {
        contacts.push({ id: doc.id, ...doc.data() })
        // console.log(doc.id, " => ", doc.data());
      });

      this.existingContacts = contacts

      this.hideLoader();



    }, (err) => {
      console.log("err is ", err);
      this.hideLoader();

    });
  }

  fetchContactHeaders() {

    this.showLoader();

    this.contactService.getContactsHeaderWithID().subscribe((querySnapshot) => {
      // console.log("contacts ... ", contacts)
      let contacts = [];

      querySnapshot.forEach((doc) => {
        contacts.push({ id: doc.id, ...doc.data() });
      });
      
      if(contacts?.length)
      {
        let headers = contacts?.length ? contacts[0]?.headers : [];   
        this.existingHeader  = contacts[0];

        // iterate over the ontacts header and push it to select fields .....
        this.selectedFields = [];
        for(let i = 0 ; i < headers?.length ; i++)
        {
          this.selectedFields.push({
            fieldType : headers[i]?.value,
            fieldName: headers[i]?.value,
            fieldDataType: headers[i]?.type,
            isPhoneNumber: headers[i]?.type === "Phone" ? true : false,
            isEmail: headers[i]?.type === "Email" ? true : false
            
          })
  
        }

      }

      this.hideLoader();



    }, (err) => {
      console.log("err is ", err);
      this.hideLoader();

    });
  }

  // on option selected
  onOptionsSelected() {

  }

  // show Loader
  showLoader() {
    this.loaderService.show()
  }

  // hide loader
  hideLoader() {
    this.loaderService.hide();
  }


}
