<div class="row">
    <div class="card">
        <div class="card-content">
          <div class="center">
            <h6>Subscription plan 1</h6>
          </div>
        
            <div class="row">
    <p>All Features Unlocked</p>
            </div>
            <div class="row">
                <p>Price : <strong>$49.99</strong></p>
                        </div>
            <!-- <div class="row" (click)="CreateSubscription();">
              <button class="btn green col s6" type="submit">Subscribe</button>
            </div> -->
       
    
        </div>
      </div>
</div>


<div class="row">
    <div class="card">
        <div class="card-content">
          <div class="center">
            <h6>Subscription plan 1</h6>
          </div>
        
            <div class="row">
                <p>Subscription : <strong>$49.99</strong></p>
                        </div>
            <div class="row" (click)="ManageSubscription();">
              <button class="btn green col s6" type="submit">Manage My Subscribtions</button>
            </div>
       
    
        </div>
      </div>
</div>