// import { AboutService } from './../../../services/firebase/about.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-main',
  templateUrl: './main.component.html',
//   styleUrls: ['./mian.component.css']
})
export class AboutMainComponent implements OnInit {

  constructor() { }

  @Input() data

  ngOnInit(): void {
  }

}
