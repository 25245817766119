import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/firebase/auth.service';
import MyCustomUploadAdapterPlugin from '../../admin/uploadAdapter.js';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl } from '@angular/forms';
import { UserService } from './../../../services/firebase/user.service';
import {NavigationEnd, Router } from '@angular/router';
import { DepartmentService } from 'src/app/services/firebase/department.service';
import { MediaService } from 'src/app/services/api/media.service';
import { ImageModel } from 'src/app/models/ImageModel';

@Component({
  selector: 'app-employee-account-setup',
  templateUrl: './employee-account-setup.component.html',
  styleUrls: ['./employee-account-setup.component.css']
})
export class EmployeeAccountSetupComponent implements OnInit {

  currentUser: any;
  emailChecked: boolean = false;
  workChecked: boolean = false;
  phoneChecked: boolean = false;
  EmployeePublicPreference = [];
  loggedInUser : any;
  department: any;
  departmentGlobal: any;
  jobtitle: any;
  //added
  scrWidth:number;
  id:any;
  route: string;
  errorMessage:any; //can be put to boolean

  //toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;

  //image
  user: any;
  mediaType: any;
  binDoc = undefined;
  base64Image = undefined;
  mobileBase64Image: any;
  isMobile = false;
  model: any;
  imgURL: any;
  imgErrorMsg: any;
  pdfErrorMsg: any;
  //loggedUser: any;

  public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')',
  ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  empAccountSetupForm: UntypedFormGroup;
  editorConfig = {
    removePlugins: ['EasyImage', 'Image', 'ImageCaption', 'ImageStyle', 'ImageToolbar', 'ImageUpload', 'MediaEmbed'],
    extraPlugins: [MyCustomUploadAdapterPlugin]
  };
  constructor(private authService: AuthService,
              private userService: UserService,
              private fb: UntypedFormBuilder,
              private router: Router,
              private departService: DepartmentService,
              private mediaService: MediaService) {
  
                //this.id = this.authService.getGlobalUser().uid;
                this.currentUser = this.authService.getCurrentUser();
               }

  ngOnInit(): void {
    // this.currentUser = this.authService.getCurrentUserObservable();
    //this.currentUser = this.authService.getGlobalUser();
    this.empAccountSetupForm = this.fb.group({
      workPhone: ['', [Validators.required]],
      biography: ['', [Validators.required]],
      appointment: [false],
      //imgLink: [''],
      userType: ['Employee']
    });

  }
  public onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
  }
  SubmitSetup(model){
    this.userService.getUserById(this.currentUser.uid).valueChanges().subscribe(user => {
      if(user.length > 0){
        this.loggedInUser = user[0];
        this.loggedInUser.workPhone = model.workPhone;
        this.loggedInUser.biography = model.biography;
        this.loggedInUser.appointment = model.appointment;
        //this.loggedInUser.department = model.depart;
        this.loggedInUser.contact = this.EmployeePublicPreference;
        this.loggedInUser.accountSetup = 'filled';
        if(this.EmployeePublicPreference.length === 0){
          this.errorMessage = true;
        }
        this.userService.updateUser(this.loggedInUser).then(res => { });
      }
    });
    


    if (this.isMobile) {
      const img = new ImageModel();
      img.imgBase64 = this.mobileBase64Image;
      this.mediaService.uploadImage(img).subscribe(upload => {
        if (upload) {
          this.loggedInUser.profileImgLink = upload.imgLink;
          this.imgURL = upload.imgLink;
          //this.loggedInUser = this.currentUser;
          this.userService.updateUser(this.loggedInUser).then(res => { })
        }
      });
    } else {
      if (this.binDoc) {
        const reader = new FileReader();
        reader.readAsDataURL(this.binDoc);
        reader.onload = () => {
          this.base64Image = reader.result as string;
        };
        const img = new ImageModel();
        img.imgBase64 = this.base64Image.replace(/^data:image\/[a-z]+;base64,/, '');
        this.mediaService.UploadBinImage(this.binDoc).subscribe(
          upload => {
            this.loggedInUser.profileImgLink = upload.imgLink;
            this.imgURL = upload.imgLink;
            //this.loggedInUser = this.currentUser;
            this.userService.updateUser(this.loggedInUser).then(res => { });
          });

      }
    }

    this.departService.getDepartmentByName(this.currentUser.department).valueChanges().subscribe(depart => {
        this.department = depart;
        this.departService.addEmployeeToDepartment(this.department.id,this.loggedInUser.uid);
    });
        
  }

  private validateImage(name: any) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() === 'png') {
      return true;
    } else if (ext.toLowerCase() === 'jpg') {
      return true;
    } else if (ext.toLowerCase() === 'jpeg') {
      return true;
    } else {
      return false;
    }
  }
  uploadPhoto() {
    this.mediaType = 'Photo';
  }

  removeFile() {
    this.binDoc = undefined;
    this.base64Image = undefined;
    this.imgLink.patchValue('');
  }

  handleFileInput(files: FileList) {
    if (!this.validateImage(files[0].name)) {
      this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';
      //this.imgLink.patchValue('');
      return;
    }
    this.binDoc = files[0];
    this.pdfErrorMsg = undefined;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.base64Image = reader.result as string;
    };
  }

  toggleEmail(event){
    this.emailChecked = !this.emailChecked;
    //this.EmployeePublicPreference = this.loggedInUser.contact;
    if (event.target.checked) {
      this.EmployeePublicPreference.push('Email');
    }
    else {
      var email = this.EmployeePublicPreference.indexOf('Email');//get  "email" index
      this.EmployeePublicPreference.splice(email, 1);
    }
  }
  togglePhone(event){
    this.phoneChecked = !this.phoneChecked;
     //this.EmployeePublicPreference = this.loggedInUser.contact;
     if (event.target.checked) {
      this.EmployeePublicPreference.push('cellPhone');
    }
    else {
      var cellPhone = this.EmployeePublicPreference.indexOf('cellPhone');//get  "cellphone" index
      this.EmployeePublicPreference.splice(cellPhone, 1);
    }
  }
  toggleWork(event){
    this.workChecked = !this.workChecked;
    //this.EmployeePublicPreference = this.loggedInUser.contact;
    if(event.target.checked){
      this.EmployeePublicPreference.push('WorkPhone');
    }
    else {
      var WorkPhone = this.EmployeePublicPreference.indexOf('WorkPhone');//get  "workphone" index
      this.EmployeePublicPreference.splice(WorkPhone, 1);
    }
  }



  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
    }, 3000);
  }

  get workPhone() { return this.empAccountSetupForm.get('workPhone'); }
  get biography() { return this.empAccountSetupForm.get('biography'); }
  get appointment() { return this.empAccountSetupForm.get('appointment'); }
  get imgLink() { return this.empAccountSetupForm.get('imgLink'); }

  // get cellPhone() { return this.empAccountSetupForm.get('cellPhone'); }
  // get message() { return this.empAccountSetupForm.get('message'); }
}
