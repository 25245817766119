<!-- <div class="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl m-5"> -->
  <div class="flex justify-end flex-row-reverse m-10 ml-10 mt-5 mb-6" >
    <button class="btn-custom-solid mx-5 " (click)="navigateToEmailSetup()"><i
        class="material-icons float-left mr-2">inventory_2</i>Add Email</button>
  </div>
  
<div *ngIf="emailSetup" class="ml-7 justify-center items-center">
  <div *ngIf="step1">
    <div class="uppercase tracking-wide text-sm text-indigo-500 font-semibold">Step 1</div>
    <p class="mt-2 text-slate-500 w-1/2">
      Write your prefered email address to send messages to your subscribers in your app
      <span class="text-red-500">*</span>
    </p>
    <div class="w-1/2 md:1/2" >
    <form class="w-1/2 md:1/2" [formGroup]="customEmailSmtpForm" (ngSubmit)="addCustomEmail(customEmailSmtpForm.value)">
    <div class="input-field flex md:1/2 w-full">
        <input id="email" type="text" formControlName='email' required
          class="input input-bordered w-full md:w-full" autocomplete="off" />
    </div>
    </form>
</div>
<div class="w-full" *ngIf="(email?.dirty || email?.touched) && email?.invalid">
    <small *ngIf="email?.errors.required" class="text-red-500 italic">Email is required.
    </small>
  
    <div *ngIf="email?.hasError('emailName')" class="text-red-500 italic">
      Email format not correct (e.g john&#64;gmail.com)
    </div>
  </div>

    <button (click)="next_step1()" type="button"
      [disabled]="incorrectEmail()"
      class="m-5 inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
      data-te-ripple-init data-te-ripple-color="light" style="align-self: center;">
      Next
    </button>
  </div>

  <div *ngIf="step2">
    <div class="uppercase tracking-wide text-sm text-indigo-500 font-semibold">Step 2</div>
    <p class="mt-2 text-slate-500 w-1/2">
      Login to your prefered email address that you wrote on step 1, go to your Account
    </p>
    <div  >
      <img

          class=" w-1/2 object-cover  md:h-1/2"
                                            
          srcset="../../../../../assets/img/email-password-guide/first.webp"
     
          alt="Google Account.">
  
   </div>

    <button (click)="next_step2()" type="button"
    [disabled]="incorrectEmail()"
    class="m-5 inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
    data-te-ripple-init data-te-ripple-color="light" style="align-self: center;">
    Next
    </button>
</div>

<div *ngIf="step3">
  <div class="uppercase tracking-wide text-sm text-indigo-500 font-semibold">Step 3</div>
  <p class="mt-2 text-slate-500 w-1/2">
    <!-- Login to your prefered email address that you wrote, go to your Account -->
    On the search bar, search for " app password " and navigate to it
  </p>
  <div  >
    <img

        class=" w-1/2 object-cover md:h-1/2"
                                          
        src="../../../../../assets/img/email-password-guide/second.webp"
                                          
        alt="App password Navigation.">

 </div>

  <button (click)="next_step3()" type="button"
  [disabled]="incorrectEmail()"
  class="m-5 inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
  data-te-ripple-init data-te-ripple-color="light" style="align-self: center;">
  Next
  </button>
</div>

<div *ngIf="step4">
  <div class="uppercase tracking-wide text-sm text-indigo-500 font-semibold">Step 4</div>
  <p class="mt-2 text-slate-500 w-1/2">
    <!-- Login to your prefered email address that you wrote, go to your Account -->
    Write name of your app and create it
  </p>
  <div  >
    <img

        class="w-1/2 object-cover md:h-1/2 "
                                          
        src="../../../../../assets/img/email-password-guide/third.webp"
                                          
        alt="App password Navigation.">

 </div>

  <button (click)="next_step4()" type="button"
  [disabled]="incorrectEmail()"
  class="m-5 inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
  data-te-ripple-init data-te-ripple-color="light" style="align-self: center;">
  Next
  </button>
</div>



<div *ngIf="step5">
  <div class="uppercase tracking-wide text-sm text-indigo-500 font-semibold">Step 5</div>
  <p class="mt-2 text-slate-500 w-1/2">
    <!-- Login to your prefered email address that you wrote, go to your Account -->
    Copy the generated password on the below input
  </p>
  <div  >
    <img

        class="w-1/2 object-cover md:h-1/2 "
                                          
        src="../../../../../assets/img/email-password-guide/fourth.webp"
                                          
        alt="App password Navigation.">

 </div>

  <div class="w-1/2 md:1/2" >
    <form class="w-1/2 md:1/2" [formGroup]="customEmailSmtpForm" (ngSubmit)="addCustomEmail(customEmailSmtpForm.value)">
    <div class="input-field flex md:1/2 w-full">
        <input id="appPassword" type="text" formControlName='appPassword' required
          class="input input-bordered w-full md:w-full" autocomplete="off" />
    </div>
    </form>
  </div>

  <button (click)="completeSetup()" type="button"
  [disabled]="incorrectEmail()"
  class="m-5 inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
  data-te-ripple-init data-te-ripple-color="light" style="align-self: center;">
  Complete setup
  </button>
</div>

</div>

<!-- <div *ngIf="finalStep || emailPassSetup">
  <h1 class="m-5">Your email is already setup.</h1>
</div> -->
<app-alert class="fixed top-0 flex justify-center w-full" [toastType]="toastType" [toastMessage]="toastMessage"
  [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>

  <div *ngIf="!emailSetup" class="flex flex-wrap">
    <div
      class="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 px-4 pt-4 ml-20 m-5"
      *ngFor="let item of listOfEmails | 
        paginate: { itemsPerPage: 6, 
        currentPage: emailPage,
        totalItems: listOfEmails.length}; let i=index
        ">
      <div class="flex flex-col items-center pb-10">
        <h5 class="mb-1 text-xl font-medium text-gray-900 dark:text-white">{{ item.email }}</h5>
       
        <div class="flex mt-4 space-x-3 md:mt-6">
  
  
          <div *ngIf="isDefault(item.email)">
            <button [disabled]="true" (click)="makeItDefault(item.email)"
              class="items-center px-4 py-2 text-md font-medium text-center text-black bg-black-300 rounded-lg ">
              Default
            </button>
  
          </div>

          <div *ngIf="!isDefault(item.email)">


            <button [disabled]="false" (click)="makeItDefault(item.email)" type="button"
              class="inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              data-te-ripple-init data-te-ripple-color="light">
              Make Defult
            </button>
          </div>
  

<!--   
          <button (click)="openListLinkedProjectsModal(item.name)"
            class="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-700">View
            Linked Projects </button> -->
        </div>
  
      </div>
  
  
    </div>
  </div>