
    <button 
        class="flex items-center space-x-2 w-min rounded bg-white  text-customTeal  border-2 border-solid border-customTeal px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-customTealborder-customTeal transition duration-150 ease-in-out hover:border-customTeal-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-customTealborder-customTeal-600 focus:border-customTeal-600 focus:text-customTealborder-customTeal-600 focus:outline-none focus:ring-0 active:border-customTeal-700 active:text-customTealborder-customTeal-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
        data-te-toggle="modal"
        data-te-target ="#previewModal" 
        data-te-ripple-init
        data-te-ripple-color="light"
  
        type="button"
        (click)="preview()"
        >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" class="w-4 h-4 stroke-customTeal">
            <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
            <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
          </svg>
          <span>Preview</span>
</button>

<div 
 data-te-modal-init 
  id="previewModal" 
  class="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none "
     tabindex="-1" 
     aria-labelledby="previewModalLabel" aria-hidden="true">    <div data-te-modal-dialog-ref class="pointer-events-none absolute flex justify-end h-auto w-full overflow-x-hidden translate-x-[100%] opacity-0 transition-all duration-300 ease-in-out ]">
        <div class="min-[576px]:shadow-[0_0.5rem_1rem_rgba(#000, 0.15)] pointer-events-auto overflow-x-hidden relative overflow-y-scroll  h-screen flex w-full md:w-5/6 flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
            <div class="flex  overflow-y-auto flex-col md:flex-row flex-shrink-0 py-4 bg-white space-y-4 md:space-y-0 items-center md:justify-beteen rounded-t-md  border-neutral-100 border-opacity-100 px-4 dark:border-opacity-50">
             <div class="flex flex-col px-6 py-4 w-full space-y-3">
                   <!-- Modal Title -->
              <div class="flex items-center justify-between">
                <h5 class="md:w-1/2 w-full  text-xl font-bold text-customTeal leading-normal text-neutral-800 dark:text-neutral-200"
                id="exampleModalLabel">
                    Preview!
                  </h5>
                  <button
                  (click)="previewClose()"
            type="button"
            class="box-content rounded-none border-none text-neutral-500 hover:text-neutral-800 hover:no-underline focus:text-neutral-800 focus:opacity-100 focus:shadow-none focus:outline-none dark:text-neutral-400 dark:hover:text-neutral-300 dark:focus:text-neutral-300"
            data-te-modal-dismiss
            id="previewClose"
            aria-label="ClosePreview">
            <span class="[&>svg]:h-6 [&>svg]:w-6">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                class="w-6 h-6"
                stroke="currentColor">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12" />
              </svg>
            </span>
          </button>
              </div>
               <hr>

           <!-- Mobile/Web view Tabs -->
           <div>
               <div class="flex space-x-4 py-2 justify-center mx-auto w-full">
                   <div [ngClass]="{'bg-customTeal stroke-white': view == 'web'}" class="p-4 rounded-md">
                     <svg (click)="changeViewType('web')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"  class="w-8 h-8 cursor-pointer " [ngClass]="{'stroke-white': view == 'web'}">
                       <path stroke-linecap="round" stroke-linejoin="round" d="M9 17.25v1.007a3 3 0 0 1-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0 1 15 18.257V17.25m6-12V15a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 15V5.25m18 0A2.25 2.25 0 0 0 18.75 3H5.25A2.25 2.25 0 0 0 3 5.25m18 0V12a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 12V5.25" />
                     </svg>
                   </div>
                   <div [ngClass]="{'bg-customTeal stroke-white': view == 'mobile'}" class="p-4 rounded-md">
                     <svg (click)="changeViewType('mobile')"  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 cursor-pointer" [ngClass]="{'stroke-white': view == 'mobile'}">
                       <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
                     </svg>
                   </div>
                   </div>
           </div>

           <!-- Preview section -->
    
           <div class="w-full flex justify-center">
            <div class="scrollbar flex justify-center "  [ngClass]="{'w-full ': view == 'web', 'w-96 max-w-sm max-h-screen ': view == 'mobile' }">
                <!-- Welcome Popup   -->
                <!-- <app-welcome-popup [section]="section" [nationOfuse]="nationOfuse" ></app-welcome-popup> -->
                <div *ngIf="sectionName == 'Welcome Popup' && section" class="w-full md:max-w-4xl rounded-md max-h-screen shadow-md p-4 m-4">
                    <div class="flex flex-col">
                        <p class="text-center" *ngIf="section.title"  [innerHTML]="section.title | noSanitize"></p>
                        <p  *ngIf="section.description" class="py-4 text-center"  [innerHTML]="section.description | noSanitize"></p>
                        <img loading="lazy" *ngIf="section.imgUrl" class="" [src]="section.imgUrl" />
                      </div>
                
                      <div class="modal-action flex justify-center">
                        <button
                        type="button"
                        class="btn-custom-solid"
                        (click)="navigateToRegisterPageClicked()"
                        >
                          {{section.callToAction}}
                        </button>
                        <button *ngIf="nationOfuse=='Demo' || nationOfuse=='FNLeaders' || nationOfuse=='Build'"
                        type="button"
                        class="btn-custom-bordered" (click)="navigateToBuildPageClicked()">
                          Build Your app
                        </button>
                      </div>
                </div>
                <div *ngIf="sectionName == 'Blog' && section" class="w-full">
                  <app-view-blog [modelToPreview]="section"></app-view-blog>
                </div>
                  <div  *ngIf="sectionName == 'Event' && section" class="w-full">
                    
                    <app-view-event [modelToPreview]="section" [view]="view"></app-view-event>
                  </div>
                  <div  *ngIf="sectionName == 'Product' && section" class="w-full">
                    {{modelToPreview?.productName}}
                    <app-view-product [modelToPreview]="section" [view]="view"></app-view-product>
                  </div>
                  <div *ngIf="sectionName == 'Service' && section" class="w-full">
                    <app-view-service [modelToPreview]="section" [view]="view"></app-view-service>
                  </div>
                  <div *ngIf="sectionName == 'About'" class=" w-full">
                     <app-about-values  [data]="section" [view]="view" calledFromEmptyDashboard = "true"></app-about-values>
                  </div>
                  <div *ngIf="sectionName == 'Custom'" class=" w-full">
                    <app-view-section  [modelToPreview]="section" [view]="view" calledFromEmptyDashboard = "true"   ></app-view-section>

                 </div>

            </div>
          </div>
             </div>
            </div>
    
        </div>
    </div>
</div>
