<div  class="flex flex-col-reverse mx-auto  md:space-y-0  md:flex-row ">

  <!-- Signup input -->
  <div class="flex flex-col p-4 md:p-12 w-full items-center space-y-2 justify-center  ">

    <div class="flex justify-between w-full">
      <button class=" flex items-center space-x-2">
        <div [routerLink]="['/']">
          <i class="material-icons">keyboard_arrow_left</i>
        </div>
        <div [routerLink]="['/']" class="">
          <h4 class=" text-sm  font-bold">
            Homepage
          </h4>
        </div>
      </button>
      <img loading="lazy" [routerLink]="['/']" *ngIf="appLogo" [src]="appLogo"
        class="block w-32 justify-center cursor-pointer " alt="Logo ">
    </div>

    <div
      class="bg-white rounded-md border border-solid border-gray-100 shadow-md md:py-12 md:px-16 py-6 px-4 w-full md:max-w-max">
      <p class="text-xl font-bold text-center  md:text-2xl  ">Create an account</p>
      <p class="text-base text-center text-gray-500 md:pt-1 " *ngIf="model">{{model.welcomeMessage}}
      </p>
      <hr class=" w-full border-t-1 border-lightGray mt-4">


      <form [formGroup]="registrationForm" (ngSubmit)="register(registrationForm.value)" class="pt-8">

        <div class=" space-y-3 md:space-y-4  ">
          <div class="flex flex-col md:flex-row gap-x-8 space-y-3  md:space-y-0">
            <div class="flex space-y-2 md:w-96  flex-col">
              <label for=" " class="text-sm text-gray-500 font-bold "> First Name <span class="text-red-400">*</span>
              </label>
              <input formControlName='firstName' [ngModel]="inputvalue" (ngModelChange)="changeToSentenceCase($event)"
                class=" border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline "
                id="Email " type="text " placeholder="Jane " required [ngClass]="{
                  'border-red-500': ((firstName.dirty || firstName.touched) && firstName.invalid),
                  'border-gray-200': !((firstName.dirty || firstName.touched) && firstName.invalid)
                }">
              <div *ngIf="(firstName.dirty || firstName.touched) && firstName.invalid">
                <small *ngIf="firstName.errors.required" class="text-error text-red-500 italic"> The First Name is
                  required. </small>
              </div>
            </div>
            <div class="flex space-y-2 md:w-96 flex-col ">
              <label for=" " class="text-sm text-gray-500 font-bold "> Last Name <span
                  class="text-red-400">*</span></label>
              <input formControlName='lastName' [ngModel]="inputLastvalue"
                (ngModelChange)="changeToSentenceCaseLast($event) "
                class=" border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline "
                id="Email " type="text " placeholder="John " required [ngClass]="{
                  'border-red-500': ((lastName.dirty || lastName.touched) && lastName.invalid),
                  'border-gray-200': !((lastName.dirty || lastName.touched) && lastName.invalid)
                }">

              <div *ngIf="(lastName.dirty || lastName.touched) && lastName.invalid">
                <small *ngIf="lastName.errors.required" class="text-error text-red-500 italic"> The Last Name is
                  required. </small>
              </div>
            </div>
          </div>

          <div class="flex flex-col md:flex-row gap-x-8 space-y-3  md:space-y-0  ">
            <div class="flex space-y-2 md:w-96 flex-col ">
              <label for=" " class="text-sm text-gray-500 font-bold "> Email <span class="text-red-400">*</span></label>
              <!-- <input  formControlName='email' class=" border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline " id="Email " type="text " placeholder="JohnSmith@gmail.com " required> -->

              <!-- <span class="block text-sm font-medium text-slate-700">Email</span> -->
              <input type="email" formControlName="email"
                class="peer border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline"
                id="Email" type="email" placeholder="JaneJohn@gmail.com" [ngClass]="{
                'border-red-500': ((email.dirty || email.touched) && email.invalid),
                'border-gray-200': !((email.dirty || email.touched) && email.invalid)
              }" />
              <div *ngIf="(email.dirty || email.touched) && email.invalid">
                <small *ngIf="email.errors.required" class="text-error text-red-500 italic"> The Email is required.
                </small>
                <small *ngIf="email.errors.email" class="text-error text-red-500 italic"> Wrong email format. </small>
              </div>
              <!-- <p class="invisible peer-invalid:visible text-pink-600 text-sm">
              Please provide a valid email address.
            </p> -->

            </div>
            <div class="flex space-y-2 md:w-96 flex-col ">
              <label for=" " class="text-sm text-gray-500 font-bold "> Phone Number <span class="text-red-400"
                  *ngIf="showAstrikes">*</span></label> 
              <!-- <input formControlName='cellPhone'
              class=" border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline "
              id="cellPhone " type="text " placeholder="000-0000-0000"> -->
              <input type="text" formControlName='cellPhone'  id='cellPhone'
                required  ng2TelInput
                class=" w-full border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline " />

              <!-- <div *ngIf="(cellPhone.dirty || cellPhone.touched) && cellPhone.invalid">
                <small *ngIf="(cellPhone.errors.minlength) && !cellPhone.errors.pattern" class="text-error text-red-500"> Phone number must be at least 10 numbers
                  </small>
                  <small *ngIf="cellPhone.errors.pattern " class="text-error text-red-500"> Wrong Format
                  </small>
              </div> -->

            </div>
          </div>

          <div class="flex flex-col md:flex-row  gap-x-8 space-y-3  md:space-y-0">

            <div class="flex space-y-2 md:w-96 flex-col">
              <label for=" " class="text-sm text-gray-500 font-bold "> Password <span
                  class="text-red-400">*</span></label>
              <div class="relative text-gray-600 w-full">
                <input #passwordfield id="Password" type="password" formControlName='createPassword' required
                  autocomplete="off"
                  class="input input-bordered rounded-md focus:outline-1 focus:outline-brandColor w-full "
                  id="Password " placeholder="Password " [ngClass]="{
                  'border-red-500': ((createPassword.dirty || createPassword.touched) && createPassword.invalid),
                  'border-gray-200': !((createPassword.dirty || createPassword.touched) && createPassword.invalid)
                }">



              <span class="absolute inset-y-0 right-0 flex items-center pr-3">
                <button class="p-1 focus:outline-none focus:shadow-outline" type="button">
                  <span (click)="passwordfield.type=passwordfield.type=='password'?'text':'password'"
                  class="fa fa-fw field-icon toggle-password"
                  [ngClass]="(passwordfield.type=='password')?' fa-eye-slash':' fa-eye'">
                </span>               
                </button>
              </span>
            </div>

              <div *ngIf="(createPassword.dirty || createPassword.touched) && createPassword.invalid">
                <small *ngIf="createPassword.errors.required" class="text-error text-red-500 italic"> The Password Is
                  Required.
                </small>
                <small *ngIf="createPassword.errors.minlength" class="text-error text-red-500 italic"> Password Length
                  Must Be At Least 6
                  Characters . </small>
                <small *ngIf="createPassword.errors.maxlength" class="text-error text-red-500 italic"> Password Must Not
                  Exceed 25
                  Characters . </small>
              </div>

            </div>

            <div class="flex space-y-2 md:w-96 flex-col">
              <label for=" " class="text-sm text-gray-500 font-bold "> Confirm Password <span
                  class="text-red-400">*</span></label>
              <div class="relative text-gray-600 w-full">
                <input #passwordfield2 id="Password" type="password" formControlName='confirmPassword' required
                  autocomplete="off"
                  class="input input-bordered rounded-md focus:outline-1 focus:outline-brandColor w-full "
                  id="Password " placeholder="Confirm Password " [ngClass]="{
                    'border-red-500': ((confirmPassword.dirty || confirmPassword.touched) && confirmPassword.invalid),
                    'border-gray-200': !((confirmPassword.dirty || confirmPassword.touched) && confirmPassword.invalid)
                  }">
                <!-- <button class="rounded-md flex justify-end" (click)='showHidePassword()'> {{ showPassword ? 'Hide' : 'Show' }}</button> -->



                <span class="absolute inset-y-0 right-0 flex items-center pr-3">
                  <button class="p-1 focus:outline-none focus:shadow-outline" type="button">
                    <span (click)="passwordfield2.type=passwordfield2.type=='password'?'text':'password'"
                      class=" fa fa-fw field-icon toggle-password"
                      [ngClass]="(passwordfield2.type=='password')?' fa-eye-slash':' fa-eye'">
                    </span>
                  </button>
                </span>
              </div>
              <div *ngIf="(confirmPassword.dirty || confirmPassword.touched) && confirmPassword.invalid">
                <small *ngIf="confirmPassword.errors.required" class="text-error text-red-500 italic">Confirm Password
                  is required. </small>
                <small *ngIf="confirmPassword.hasError('invalid')" class="text-error text-red-500 italic">Passwords
                  Doesnt Match ! </small>
              </div>
            </div>

          </div>

          <div class="flex flex-col space-y-4">
            <span class="text-sm text-gray-500 max-w-screen-md"> <input type="checkbox"
                class="checkbox checkbox-primary mr-2 w-4 h-4  rounded-sm float-left" formControlName="privacyPolicy"
                required /> By clicking Register, I agree to <a [routerLink]="['/terms-conditions']"
                class="underline-offset-2 text-customTeal">Terms of Service </a>, <a [routerLink]="['/privacy-policy']"
                class="underline-offset-2 text-customTeal">Privacy Policy</a>, and <a
                [routerLink]="['/enduser-agreement']" class="underline-offset-2 text-customTeal">End-User License
                Agreement</a>.</span>
            <div *ngIf="(privacyPolicy.dirty || privacyPolicy.touched) && privacyPolicy.invalid">
              <small *ngIf="privacyPolicy.errors.required" class="text-error text-red-500">This section is required you
                need to agree
                to the terms and conditions and privacy policy to proceed.. </small>
            </div>

            <button [disabled]="!registrationForm.valid"
              class="mx-auto px-6 py-2 border-2 font-bold rounded-lg bg-customTeal w-full md:w-96 text-white focus:outline-none"
              [ngClass]="{'bg-customTeal': registrationForm.valid, 'bg-gray-400': !registrationForm.valid}">
              Register
            </button>
          </div>
        </div>

      </form>

      <p class="text-base text-center pt-3">Already have an account? <button type="button " [routerLink]="['/login']"
          class="inline-block text-base underline text-customTeal font-bold hover:text-darkBlue ">Login</button>
      </p>
    </div>
  </div>
</div>

<!-- toast -->
<div class="alert alert-success shadow-lg fixed top-0 right-0 "
  [ngClass]="{'visible': openToast, 'invisible': !openToast,'alert-success': toastType=='success', 'alert-error': toastType=='failure' }">
  <div class="text-white font-bold">
    <span class="material-icons">check_circle</span>
    <span>{{toastMessage}}</span>
  </div>
</div>


<app-smsverification *ngIf="submitModalOpen" [wrongCode]="wrongCode" (cancelVerification)="closeModal()"
  (resend)="resendCode($event)" (verify)="verifyClicked($event)"></app-smsverification>