import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ContactService } from 'src/app/services/firebase/contact.service';
import { UserService } from 'src/app/services/firebase/user.service';
import { LoadingService } from 'src/app/services/loader/loading.service';
import { read, utils } from 'xlsx';

@Component({
  selector: 'app-import-contact',
  templateUrl: './import-contact.component.html',
  styleUrls: ['./import-contact.component.css']
})
export class ImportContactComponent implements OnInit {

  fieldForm: any;
  showFieldModal: boolean = false;
  selectedFieldForInfo: any;

  // email regex, to check if the field is an email
  validEmailRegex: any = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  // 5 digit zip regex
  zipRegex5: any = /^\d{5}$/;

  // 9 digit zip regex
  zipRegex9: any = /^\d{5}-\d{4}$/;

  // postal code
  postCodeRegex: any = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/

  showModal: boolean = false
  baseFields: any = [
    {
      name: 'First Name',
      type: 'text',
      category: 'text',
      placeholder: 'Jane',
      key: 'GivenName',
      default: true,
      required: true
    },
    {
      name: 'Last Name',
      type: 'text',
      category: 'text',
      placeholder: 'John',
      key: 'LastName',
      default: true
    },
    {
      name: 'Phone Number',
      type: 'text',
      category: 'phone',
      placeholder: '000-000-0000',
      key: 'cellPhone',
      default: true
    },
    {
      name: 'Email',
      type: 'text',
      category: 'email',
      placeholder: 'Jane@gmail.com',
      key: 'Email',
      default: true
    },
  ]
  existingFormFields: any = null;
  formFieldName: any = null;
  selectedFieldForEdit: any = null;
  selectedField: any;
  modalTitle = "";


  //toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  showForm = false
  viewEditForm = true;

  // modal
  showConfirmationModal = false
  recordsTobeImported: any[] = []
  baseFieldBoolean: any[] = []
  existingContactEmails: any[] = []


  constructor(private contactService: ContactService, private router: Router, private fb: UntypedFormBuilder, private userService: UserService, private loaderService: LoadingService, private toastr: ToastrService,) { }

  ngOnInit(): void {

    // fetch fields
    this.fetchContactHeaders();
  }

  submitFieldType(event: any) {
    // check if the user wants to edit 
    if (this.selectedFieldForEdit) {

      // iterate over the existing headers and grab the index
      this.updateContactsHeader({
        type: this.selectedFieldForEdit?.type,
        value: this.formFieldName,
        name: this.selectedFieldForEdit?.name,
        key: this.selectedFieldForEdit?.key
      })

    }
    else {
      this.saveContactsHeader({
        type: this.selectedField?.type,
        value: this.formFieldName,
        name: this.selectedField?.label,
        key: this.selectedField?.key
      })

    }
  }

  // open add modal
  openModal(selectedField) {

    this.resetFields();

    this.selectedField = {
      label: selectedField?.name,
      value: selectedField?.value,
      type: selectedField?.category,
      key: selectedField?.key

    };


    this.modalTitle = "Add field data"

    this.showFieldModal = true;
  }


  deleteUsers() {
    // show loader
    this.showLoader();

    this.contactService.deleteAllImportedUsers().then((res) => {

      //  hide loader
      this.hideLoader();
      this.successMessage("User deletion succed!")

    }).catch((err) => {
      console.error(" under err ", err)

      this.hideLoader();
      this.errorMessage("User deletion failed!")

    })
  }

  // open edit modal
  openEditModal(selectedField) {

    this.resetFields();
    // iterate over the already added selected fields
    let index = this.existingFormFields.findIndex((item) => item?.id === selectedField?.id);

    if (index != -1) {
      this.selectedFieldForEdit = this.existingFormFields[index];
      this.formFieldName = this.selectedFieldForEdit?.value || this.selectedFieldForEdit?.name;

      // check it under the base fields
      let selectedBaseField = this.baseFields.find((baseField) => baseField?.id === this.selectedFieldForEdit?.id);
      if (selectedBaseField) {
        this.selectedFieldForEdit['default'] = selectedBaseField?.default;
      }

    }

    this.fieldForm = {
      fieldName: this.selectedFieldForEdit?.key || this.selectedFieldForEdit?.name,
      fieldDataType: this.selectedFieldForEdit?.type,
      fieldLabel: this.selectedFieldForEdit?.name,
      fieldKey: this.selectedFieldForEdit?.key

    }

    this.modalTitle = "Update Field Data"
    this.showFieldModal = true;
    this.viewEditForm = true;

  }

  // reset fields
  resetFields() {
    this.selectedField = null;
    this.selectedFieldForEdit = null;
    this.modalTitle = ""
    this.formFieldName = null

  }

  // close modal
  closeModal() {
    this.showFieldModal = false;
    this.showModal = false;

    this.resetFields();
  }


  // show delete button
  showDeleteButton() {
    // check if the field is an existing one 
    let fieldIndex = this.baseFields.findIndex((field) => field.name === this.selectedFieldForEdit?.name);
    if (!this.selectedFieldForEdit) {
      return false;
    }
    else if (fieldIndex != -1 && this.baseFields[fieldIndex]?.default) {
      return false;
    }

    return true;

  }


  // fetch existing contact headers
  fetchContactHeaders() {

    this.showLoader()
    this.contactService.getContactHeaderNew().subscribe((querySnapshot) => {
      let contacts = [];

      querySnapshot.forEach((doc) => {
        contacts.push({ id: doc.id, ...doc.data() });
      });

      this.existingFormFields = contacts;

      this.resetBaseFields()

      // iterate over the header and check if the fields have field name which is going to be used while importing

      for (let i = 0; i < this.baseFields?.length; i++) {
        if (this.existingFormFields?.length) {

          let index = this.existingFormFields.findIndex((item) => item?.key === this.baseFields[i]?.key);

          if (index != -1) {
            this.baseFields[i]['active'] = true
            this.baseFields[i]['id'] = this.existingFormFields[index]?.id
          }

        }
      }

      // iterate over the  existing headers
      for (let j = 0; j < this.existingFormFields?.length; j++) {


        let currentHeader = this.existingFormFields?.[j];

        let fieldIndex = this.baseFields.findIndex((field) => field.key === currentHeader?.key);

        // consol
        if (fieldIndex === -1) {

          this.baseFields.push({
            name: currentHeader?.name,
            type: 'text',
            category: currentHeader?.type,
            placeholder: currentHeader?.name,
            key: currentHeader?.key,
            active: true,
            id: currentHeader?.id

          })
        }
        else {
          // this.baseFields['id'] = currentHeader?.id
        }

      }

      this.hideLoader();


    }, (err) => {
      this.hideLoader();

    });
  }

  // reset base field
  resetBaseFields() {
    this.baseFields = [
      {
        name: 'First Name',
        type: 'text',
        category: 'text',
        placeholder: 'Jane',
        key: 'GivenName',
        default: true,
        required: true
      },
      {
        name: 'Last Name',
        type: 'text',
        category: 'text',
        placeholder: 'John',
        key: 'LastName',
        default: true
      },
      {
        name: 'Phone Number',
        type: 'text',
        category: 'phone',
        placeholder: '000-000-0000',
        key: 'cellPhone',
        default: true
      },
      {
        name: 'Email',
        type: 'text',
        category: 'email',
        placeholder: 'Jane@gmail.com',
        key: 'Email',
        default: true
      },
    ]
  }

  // add new contact headers
  saveContactsHeader(currentFormField) {

    this.showLoader()

    // check if the current form field is from the base fields
    let baseField = this.baseFields.find((field) => field?.name === currentFormField?.name);

    if (baseField) {
      currentFormField['default'] = true
    }

    this.contactService.saveContactHeaderNew(currentFormField).then((response) => {
      this.fetchContactHeaders();
      this.closeModal();

      this.hideLoader();

    }).catch((err) => {
      this.hideLoader();
    })
  }

  // delete contact header
  deleteField() {
    this.showLoader()

    this.contactService.deleteContactHeader(this.selectedFieldForEdit?.id).then(() => {
      this.fetchContactHeaders();

      this.resetFields();

      // hide modal
      this.closeModal();

      this.hideLoader();

    }).catch((err) => {
      console.error("error while deleting a field ", err)

      this.hideLoader()
    })
  }

  // open edit modal
  editField() {
    // iterate over the already added selected fields
    let index = this.existingFormFields.findIndex((item) => item?.id === this.selectedFieldForEdit?.id);

    if (index != -1) {
      this.router.navigate(['/admin/customer-form/config/' + this.existingFormFields[index]?.id], { queryParams: { from: 'importContact' } });
    }

  }

  navigateToFieldForm() {
    this.router.navigate(['/admin/customer-form/config'], { queryParams: { from: 'importContact' } });
  }

  updateContactsHeader(currentFormField) {

    this.showLoader();

    // check if we have existing contact headers , if not add it
    if (this.existingFormFields) {

      let fieldIndex = this.existingFormFields?.findIndex((item) => item?.id === this.selectedFieldForEdit?.id);
      this.contactService.updateContactHeaderNew({ ...currentFormField, id: this.selectedFieldForEdit?.id }).then((response) => {

        this.hideLoader();

        this.fetchContactHeaders();

        this.closeModal();

        this.hideLoader()



      }).catch((err) => {
        this.hideLoader()
        console.error("error while adding contact headers ", err)

      })
    }
  }

  // delete specific header
  deleteContactsHeader() {
    this.showLoader();

    // check if we have existing contact headers , if not add it
    if (this.existingFormFields) {

      let newHeaders = [];

      for (let i = 0; i < this.existingFormFields?.length; i++) {
        let currentField = this.existingFormFields?.[i];

        if (currentField?.id != this.selectedFieldForEdit?.id) {
          newHeaders.push(currentField)
        }


      }

      let headerData = {
        uid: this.existingFormFields?.id,
        headers: []
      };


      this.contactService.updateContactHeaderNew(headerData).then((response) => {

        this.hideLoader();

        this.fetchContactHeaders();

        this.closeModal();



      }).catch((err) => {
        this.hideLoader()
        console.error("error while adding contact headers ", err)

      })

    }



  }

  // check field existance
  checkFieldExistance(fieldName) {
    let fieldExist = false;
    if (this.existingFormFields?.headers?.length) {

      let index = this.existingFormFields.findIndex((item) => item?.name === fieldName);

      if (index != -1) {
        fieldExist = true;

      }

    }
    return fieldExist;
  }

  csvImport($event: any) {
    const files = $event.target.files;
    // check if files are uploaded
    if (files.length) {

      // show loader
      this.showLoader();

      const file = files[0];
      const reader = new FileReader();

      // process the file
      reader.onload = async (event: any) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;

        // check if the sheets length is greater than zero
        if (sheets.length) {
          let rows: any[] = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          //  initialize variables
          let validRecords = [];
          let existingEmails = [];

          // create array of booleans , to track which fields exist in the file
          let fieldBooleans = [];
          for (let i = 0; i < this.existingFormFields?.length; i++) {
            let currentField = this.existingFormFields[i];
            fieldBooleans[currentField?.key] = false;
          }

          // iterate over the rows 
          for (let i = 0; i < rows?.length; i++) {
            let id = this.userService.createID()
            let record = {
              uid: id,
              id: id
            };

            // iterate over the fields and extract the Values from the excel
            for (let j = 0; j < this.existingFormFields?.length; j++) {
              let currentField = this.existingFormFields[j];
              let currentColumnValue = this.getExcelColValue(rows[i], currentField?.key);
              if (currentField?.key) {
                if (currentField?.type == "phone") {
                  let phoneNumber = currentColumnValue;

                  let cleanedPhone = phoneNumber ? (phoneNumber).toString().replace(/[^\w\s]/gi, '').replace(/\s+/g, '') : '';

                  // check  if the phone num contains all nums
                  let isnum = /^\d+$/.test(cleanedPhone)

                  if (cleanedPhone?.length == 10 && isnum) {
                    record[currentField?.key] = this.maskPhoneNumber(cleanedPhone)
                  }
                  else {
                    record[currentField?.key] = null
                  }
                }
                else if (currentField?.type == "email") {
                  if (this.validEmailRegex.test(currentColumnValue)) {
                    record[currentField?.key] = currentColumnValue
                  }
                }
                else if (currentField?.type == "zip") {
                  if (this.zipRegex5.test(currentColumnValue) || this.zipRegex9.test(currentColumnValue)) {
                    record[currentField?.key] = currentColumnValue
                  }

                }
                else if (currentField?.type == "postal") {
                  if (this.postCodeRegex.test(currentColumnValue)) {
                    record[currentField?.key] = currentColumnValue
                  }
                }
                else {
                  record[currentField?.key] = currentColumnValue
                }
              }

              // check if the specfic ol have a value

              if (record[currentField?.key]) {
                fieldBooleans[currentField?.key] = true;
              }
            }
            // check if there is a record
            if (record) {
              let isValid = true

              // check if the record got all the required fields
              let requiredFields = this.baseFields.filter((item) => item?.required);
              for (let i = 0; i < requiredFields?.length; i++) {
                if (!record[requiredFields[i]?.key]) {
                  isValid = false
                }
              }

              // if all is correct, add additional fields
              if (isValid) {
                record['role'] = 'User'
                record['contact'] = record['Email'] && record['cellPhone'] ? ['Email', 'SMS'] : record?.['Email'] && !record?.['cellPhone'] ? ['Email'] : !record['Email'] && record['cellPhone'] ? ['SMS'] : []
                record['importedUser'] = true;
                validRecords.push(record);
              }

            }
          }

          // filter the fields with no data 
          this.baseFieldBoolean = [];
          for (let i = 0; i < this.existingFormFields?.length; i++) {
            let currentField = this.existingFormFields[i];
            if (!fieldBooleans[currentField?.key]) {
              this.baseFieldBoolean.push({
                field: currentField?.key
              });
            }

          }

          this.recordsTobeImported = validRecords;
          this.hideLoader();

          //  check existing emails
          existingEmails = await this.checkContactEmail(validRecords);
          if (this.baseFields?.length || existingEmails?.length) {
            this.existingContactEmails = existingEmails;
            // show modal
            this.showConfirmationModal = true;

          }
          else {
            this.importToDB()
          }
        }

        this.hideLoader()
      };

      reader.readAsArrayBuffer(file);
    }
  }

  downloadSampleCSV() {
    // Define the header row for the CSV file
    const header = [];

    for (let i = 0; i < this.baseFields?.length; i++) {
      header.push(this.baseFields?.[i]?.key)
    }

    for (let i = 0; i < this.existingFormFields?.length; i++) {
      const fieldKey = this.existingFormFields[i]?.key;

      // Only add to header if it's not already included in baseFields
      if (fieldKey && !this.baseFields.find((field) => field?.key === fieldKey)) {
        header.push(fieldKey);
      }
    }

    // Create a CSV content string with only the header row
    const csvContent =
      'data:text/csv;charset=utf-8,' + header.join(',') + '\n';

    // Encode the CSV content and create a downloadable link
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'sample_header.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  // check if email exist on the database 
  async checkContactEmail(validRecords: any) {
    let existingEmails = [];

    this.showLoader();

    let emailList = [];

    // iterate and extract emails
    for (let i = 0; i < validRecords?.length; i++) {
      if (validRecords[i]?.['Email']) {
        emailList.push(validRecords[i]?.['Email'])
      }
    }

    try {
      existingEmails = (await this.contactService.checkUsersEmail(emailList));

      this.hideLoader();
      // emailExist = user?.length ? true : false;
    } catch (err) {
      console.error("err is", err);

      this.hideLoader();
    }

    return existingEmails;

  }

  // save the imported data to the database 
  importToDB() {
    if (this.recordsTobeImported?.length > 0) {

      this.showLoader();

      // insert users 
      this.userService.insertUsers(this.recordsTobeImported).then(async (response) => {
        this.router.navigate(['/admin/users-list/']);
        this.hideLoader();

      }).catch((err) => {
        this.errorMessage("Something went wrong when saving contacts!");
        this.hideLoader();
      });
    }
    else {
      this.errorMessage("Please upload a valid file!")
      this.hideLoader()
    }
  }

  // get specfic excel column value using a key, since the user can add space and quotation around the key, we need to trim the spce and the quotation
  getExcelColValue(row, fieldValue) {
    let columnValue = null;
    // iterate over the object keys and trim the spaces
    Object.keys(row).map((rowKey) => {
      let newKey = rowKey.replace(/^\s+|\s+$/gm, '')

      if (newKey === fieldValue) {
        columnValue = row?.[rowKey]

      }

    })
    return columnValue;
  }

  // mask phone number
  maskPhoneNumber(phone: any) {
    const format = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
    const numericString = phone.replace(/\D/g, '');

    let maskedString = '';
    let numericIndex = 0;

    format.forEach(char => {
      if (typeof char === 'string') {
        maskedString += char;
      } else {
        maskedString += numericString.charAt(numericIndex);
        numericIndex++;
      }
    });

    return maskedString;
  }


  // toast message handler
  successMessage(message) {
    this.toastr.success(message, "Success");
  }

  errorMessage(message) {
    this.toastr.error(message, "Error");
  }

  // show Loader
  showLoader() {
    this.loaderService.show()
  }

  // hide loader
  hideLoader() {
    this.loaderService.hide();
  }

  // 
  openFieldAddModal() {
    this.showFieldModal = true;
    this.modalTitle = "Add Field"
  }

  // save additional fields
  saveFieldData(fieldData: any) {


    let { fieldDataType, fieldName, fieldLabel, fieldKey } = fieldData


    // key, name,type,value - 
    if (this.selectedFieldForEdit && this.viewEditForm) {
      // iterate over the existing headers and grab the index
      this.updateContactsHeader({
        type: this.selectedFieldForEdit?.default ? (this.selectedFieldForEdit?.type)?.trim() : (fieldDataType)?.trim(),
        value: (fieldName)?.trim(),
        name: this.selectedFieldForEdit?.default ? (this.selectedFieldForEdit?.name)?.trim() : (fieldLabel)?.trim(),
        key: this.selectedFieldForEdit?.default ? (this.selectedFieldForEdit?.key)?.trim() : (fieldKey)?.trim()
      })

    }
    else {
      this.saveContactsHeader({
        type: this.selectedField ? (this.selectedField?.type)?.trim() : (fieldDataType)?.trim(),
        value: (fieldName).trim(),
        name: this.selectedField ? (this.selectedField?.label)?.trim() : (fieldLabel)?.trim(),
        key: this.selectedField?.key ? (this.selectedField?.key)?.trim() : (fieldKey)?.trim()
      })

    }

  }

  // show confirmation modal
  closeConfirmationModal() {
    this.showConfirmationModal = false;
    this.baseFieldBoolean = [];
  }


}
