import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NewsEventService } from 'src/app/services/firebase/news-event.service';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-event-calendar',
  templateUrl: './event-calendar.component.html',
  styleUrls: ['./event-calendar.component.css'],

})
export class EventCalendarComponent implements OnInit {

  model: any[];
  themeColor = environment.appTheme.themeColor;
  nationName = environment.firstNation.displayName;
  isMember = false;
  showLoader = false;

   constructor(private eventService: NewsEventService, private router: Router) { }

  ngOnInit() {
    this.showLoader = true;
    this.eventService.getPublicEventsList().valueChanges().subscribe(
      evnt => {
        if (evnt) {
          if (evnt.length > 0 ) {
            this.model = evnt;
            this.showLoader = false;
          }
          this.showLoader = false;
        }
      }
    );
  }


  back() {
    localStorage.setItem('FromPath', 'NewsEvents');
    this.router.navigate(['/home']);
  }
}
