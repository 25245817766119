export class SMSModel {
    phoneNumberList : string[] = [];
    body : string = '';
    name: string = '';
    from: string = '';
    Email_Address = '';
    cell: string = '';
    phone: string = '';
    title: string = '';
    show_email : boolean;
    show_title : boolean;
    show_phone : boolean;
    show_cell : boolean;
    //attachments?: string[];
  }

  // export class Attachment{
  //   type: string;
  //   name: string;
  //   link: string;
  // }
  
  