<ng-container *ngIf="appSetting && appSetting.isBusinessApp">

<div *ngIf="haveAccessPermission && !preview" class="w-full px-5 py-3 md:px-14">

  <div class="border-b-4 border-customTeal py-5">
    <div class="flex text-left space-x-2 items-center ">
      <div class="w-full flex flex-row justify-between">
        <div class="font-bold text-lg">
          <span>Edit</span>
        </div>
        <div>
          <button type="button"
          [disabled]="!editServiceForm.valid"
            [ngClass]="{'bg-[#cccccc] text-[#666666]': !editServiceForm.valid}"
            (click)="previewChanges(editServiceForm.value)"
          class="btn-custom-solid" >
          <i class="material-icons mr-3 text-white float-left">preview</i>
          Preview Changes
        </button>
        </div>
      </div>
     
    </div>

  </div>
  <div class="flex flex-col lg:flex-row gap-5 overflow-x-hidden w-full py-3">
    <div class="w-full mx-auto">
      <form [formGroup]="editServiceForm" (ngSubmit)="updateService(editServiceForm.value)">

        <div class="flex flex-col gap-y-2">

          <!-- Title -->
          <div class="form-control w-full bg-white md:px-10 px-2  py-6 space-y-2 shadow-sm rounded-md">
            <div class="flex space-x-4 items-center">
              <svg width="36" height="36" viewBox="0 0 55 55" fill="blue"  class="text-gray-400" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 12H45.6667V16.5833H9V12ZM9 21.1667H31.9167V25.75H9V21.1667Z" fill="black"/>
                <rect x="9" y="30.3333" width="22.9167" height="4.58333" fill="black"/>
                <path d="M44.4785 22.375C44.0226 22.375 43.5855 22.5561 43.2631 22.8784C42.9408 23.2007 42.7597 23.6379 42.7597 24.0938C42.7597 24.5496 42.9408 24.9868 43.2631 25.3091C43.5855 25.6314 44.0226 25.8125 44.4785 25.8125C44.9343 25.8125 45.3715 25.6314 45.6938 25.3091C46.0162 24.9868 46.1972 24.5496 46.1972 24.0938C46.1972 23.6379 46.0162 23.2007 45.6938 22.8784C45.3715 22.5561 44.9343 22.375 44.4785 22.375ZM44.066 27.8406C42.7024 27.9552 38.9785 30.9229 38.9785 30.9229C38.7493 31.0948 38.8181 31.0833 39.0014 31.4042C39.1847 31.7135 39.1618 31.7365 39.3795 31.5875C39.6087 31.4385 39.9868 31.1979 40.617 30.8083C43.0462 29.25 41.0066 32.8479 39.9639 38.9094C39.5514 41.9115 42.2556 40.3646 42.9545 39.9063C43.642 39.4594 45.4868 38.1875 45.6701 38.0615C45.9222 37.8896 45.7389 37.7521 45.5441 37.4656C45.4066 37.2708 45.2691 37.4083 45.2691 37.4083C44.5243 37.901 43.1608 38.9323 42.9774 38.2792C42.7597 37.626 44.1576 33.1458 44.9254 30.0635C45.0514 29.3302 45.3951 27.726 44.066 27.8406V27.8406Z" fill="black"/>
                <rect x="9" y="38.3542" width="22.9167" height="4.58333" fill="black"/>
              </svg>
              <div class="w-10/12">
                <p class="font-bold text-lg">Title <span class="text-red-500 text-sm">*</span></p>
                <p class="text-xs text-gray-500">This is the first part of your post that your readers will see and the part that's responsible for getting them to click through and read the rest.</p>
              </div>
            </div>

            <app-editor [control]="serviceName" class="bg-white" [inputType]="'title'" [fromComponent]=servicesName></app-editor>
            <div *ngIf="(serviceName.dirty || serviceName.touched) && serviceName.invalid">
              <small *ngIf="serviceName.errors.required" class="text-red-500 italic text-xs">The Title is required.
              </small>
            </div>
          </div>
 
          <!-- Description -->
          <div class="form-control w-full bg-white md:px-10 px-2  py-6 space-y-2 shadow-sm rounded-md">
            <div class="flex space-x-4 items-center">
              <svg width="36" height="36" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 37H16.3357V24.5531H4V37ZM7.08574 27.6667H13.25V33.8865H7.08574V27.6667ZM19.4143 35.4469H41V32.3333H19.4143V35.4469ZM4 21.4469H16.3357V9H4V21.4469ZM7.08574 12.1135H13.25V18.3333H7.08574V12.1135V12.1135ZM19.4143 10.5531V13.6667H41V10.5531H19.4143V10.5531ZM19.4143 29.2198H41V26.1062H19.4143V29.2198ZM19.4143 19.8865H41V16.7729H19.4143V19.8865Z" fill="black"/>
              </svg>
              <div class="w-10/12">
                <p class="font-bold text-lg">Description</p>
                <p class="text-xs text-gray-500">Discription/Content of your post in the form of text.</p>
              </div>
            </div>
            <app-editor [control]="serviceDescription" [fromComponent]=servicesName></app-editor>
            <div *ngIf="(serviceDescription.dirty || serviceDescription.touched) && serviceDescription.invalid">
              <small *ngIf="serviceDescription.errors.required" class="text-red-500 italic text-xs">The Description is
                required.
              </small>
            </div>
          </div>

          <!-- Background color -->
          <div class="form-control w-full bg-white md:px-10 px-2  py-6 space-y-4 shadow-sm rounded-md">
            <div class="flex space-x-4 items-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 384 512"><path d="M192 64L160 0H128L96 64 64 0H48C21.5 0 0 21.5 0 48V256H384V48c0-26.5-21.5-48-48-48H224L192 64zM0 288v32c0 35.3 28.7 64 64 64h64v64c0 35.3 28.7 64 64 64s64-28.7 64-64V384h64c35.3 0 64-28.7 64-64V288H0zM192 432a16 16 0 1 1 0 32 16 16 0 1 1 0-32z"/></svg>
              <div class="w-10/12">
                <p class="font-bold text-lg">background Color</p>
                <p class="text-xs text-gray-500">Discription/Content of your post in the form of text.</p>
              </div>
            </div>
            <div class="flex space-x-4 items-center">
              <div> Select background Color  </div>
              <div class="flex gap-2 justify-start">
                <input id="backgroundColor" formControlName="backgroundColor" type="color" value="#000000"
                    class="themeColorSelector h-10 w-10">
              </div>
            </div>
          </div>

          <!-- File Upload -->
          <div class="form-control w-full   bg-white md:px-10 px-2 py-6 space-y-2 shadow-sm ">
            <div class="flex space-x-4 items-center">
              <svg width="32" height="32" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.125 31.875H31.875C32.25 31.875 32.5313 31.7031 32.7188 31.3594C32.9063 31.0156 32.875 30.6875 32.625 30.375L27.4688 23.4844C27.2812 23.2344 27.0312 23.1094 26.7188 23.1094C26.4062 23.1094 26.1562 23.2344 25.9688 23.4844L21.0938 30L17.625 25.3594C17.4375 25.1094 17.1875 24.9844 16.875 24.9844C16.5625 24.9844 16.3125 25.1094 16.125 25.3594L12.375 30.375C12.125 30.6875 12.0938 31.0156 12.2813 31.3594C12.4688 31.7031 12.75 31.875 13.125 31.875ZM9.375 39.375C8.34375 39.375 7.46062 39.0081 6.72562 38.2744C5.99187 37.5394 5.625 36.6562 5.625 35.625V9.375C5.625 8.34375 5.99187 7.46062 6.72562 6.72562C7.46062 5.99187 8.34375 5.625 9.375 5.625H35.625C36.6562 5.625 37.5394 5.99187 38.2744 6.72562C39.0081 7.46062 39.375 8.34375 39.375 9.375V35.625C39.375 36.6562 39.0081 37.5394 38.2744 38.2744C37.5394 39.0081 36.6562 39.375 35.625 39.375H9.375ZM9.375 35.625H35.625V9.375H9.375V35.625ZM9.375 9.375V35.625V9.375Z" fill="black"/>
                </svg>              
                <div class="w-10/12">
                  <span class="label-text text-[#4F4F4F] text-base font-bold leading-5">Upload Files</span>
                  <p class="text-xs text-gray-500">This video is what the users will see first.</p>
  
                </div>
            </div>
            <div class="flex flex-col md:flex-row my-24">
              <!-- Image -->
              <div class="w-full mt-5 md:w-1/3">
                <button type="button" *ngIf="!isMobile">
                  <label class="flex flex-col py-5 px-10 bg-gray-50 rounded-lg">
                  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7704.74 6791.12"><circle cx="2373.6" cy="2638.32" r="695.57" transform="translate(-1413.99 1788.05) rotate(-45)" style="fill:#231f20"/><path d="M7671.44,940c-178.58-178.61-416-276.93-668.57-276.93H1189.17c-252.56,0-490,98.32-668.61,276.93s-276.93,416-276.93,668.57V6508.7c0,252.56,98.36,490,276.93,668.57s416.05,276.93,668.61,276.93h5813.7c252.56,0,490-98.36,668.57-276.93s276.93-416,276.93-668.57V1608.58C7948.37,1356,7850,1118.59,7671.44,940ZM7386.2,6508.7c0,9.58-.34,19.13-1.06,28.56q-582.86-730.58-1077.85-1430.1C5530.21,4014.71,5164.73,3357.57,4738.42,3418.77c-198.19,28.45-370.5,253-715.09,702.14-455.58,593.83-525,924.41-737.08,924-300.22-.57-401.22-663.59-746.59-678.79-230.42-10.15-415.18,274.9-509.58,419.25-325.6,497.92-721.5,1073.86-1219.91,1781.25a381.07,381.07,0,0,1-4.37-57.88V1608.58c0-211.4,172-383.33,383.37-383.33h5813.7c211.36,0,383.33,171.93,383.33,383.33Z" transform="translate(-243.63 -663.08)" style="fill:#231f20"/></svg>
                  <input type="file" type="file" accept="image/*" (change)="fileChangeEvent($event.target.files)" >
                  <span class="block font-bold pt-1 text-center">Image</span>
                  </label>
                </button>
                <div *ngIf="isMobile">
                  <app-image-uploader class="justify-start items-start"  (onImageUpload)=onImageUpload($event) [customConfig]="{'upload':false}"></app-image-uploader>
                </div>
                
                <div class="pt-5 md:pt-0 md:pl-2">
                  <div class="flex flex-wrap gap-8">
                    <div class="relative">
                      <ng-container *ngIf="base64Image" >
                        <img loading="lazy" [src]="base64Image" class="object-cover  w-36 h-24 rounded-md cover" alt="Image">
                        <button (click)="removeImage()" type="button"
                          class=" top-0 translate-x-16 -translate-y-4 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                          <i class="fa fa-trash text-md"></i>
                        </button>
                      </ng-container>
  
                      <ng-container *ngIf="service && service.imgLink && service.imgLink!= '' && !base64Image ">
                      <img loading="lazy" [src]="service.imgLink"
                        class="object-cover  w-24 h-24 rounded-md cover" alt="service Image">
                      <button (click)="removeImage()" type="button"
                        class=" top-0 translate-x-16 -translate-y-4 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                        <i class="fa fa-trash text-md"></i>
                      </button>
                      </ng-container>
                    </div>
    
                  </div>
                </div>
              </div>
  
              <!-- Video -->
              <div class="w-full mt-5 md:w-1/3">
                <button type="button">
                  <label class="flex flex-col py-5 px-10 bg-gray-50 rounded-lg">
                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7540.61 4795.88"><rect width="4703.35" height="4795.88" rx="348.07" style="fill:#231f20"/><path d="M7092.71,5797.22,5670.1,4995.05V3122.23l1422.61-802.17c358.38-202.07,773.6-152.74,773.6,131.07v3215C7866.31,5950,7451.09,5999.3,7092.71,5797.22Z" transform="translate(-325.69 -1660.7)" style="fill:#231f20"/></svg>
                    <input type="file" accept="video/*"
                    (change)="handleVideoInput($event.target.files)" />
                    <span class="block font-bold pt-1 text-center">Video</span>
                  </label>
                </button>
  
                <div class="pt-5 md:pt-0 md:pl-2 w-2/3">
                  <ng-container *ngIf="attachedVideo">
                    <video controls [src]="attachedVideo"
                    class="object-cover w-full mt-3 md:mt-0 w-45  rounded-md cover" alt="Video">
                    </video>
                    <button (click)="removeVideo()" type="button"
                      class=" bottom-0 right-0 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                      <i class="fa fa-trash text-md"></i>
                    </button>
                  </ng-container>
    
                  <ng-container *ngIf="service.videoLink && !attachedVideo">
                    <video controls [src]="service.videoLink"
                    class="object-cover w-full mt-3 md:mt-0 w-45  rounded-md cover" alt="Video">
                    </video>
                    <button (click)="removeVideo()" type="button"
                      class=" bottom-0 right-0 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                      <i class="fa fa-trash text-md"></i>
                    </button>
                  </ng-container>
                </div>

              </div>
    
            </div>
          </div>

          <!-- Add category under/sub service -->
          <div class="form-control w-full bg-white md:px-10 px-2 mt-2 py-6 space-y-2 shadow-sm">
            <div class="flex space-x-4 items-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M315.4 15.5C309.7 5.9 299.2 0 288 0s-21.7 5.9-27.4 15.5l-96 160c-5.9 9.9-6.1 22.2-.4 32.2s16.3 16.2 27.8 16.2H384c11.5 0 22.2-6.2 27.8-16.2s5.5-22.3-.4-32.2l-96-160zM288 312V456c0 22.1 17.9 40 40 40H472c22.1 0 40-17.9 40-40V312c0-22.1-17.9-40-40-40H328c-22.1 0-40 17.9-40 40zM128 512a128 128 0 1 0 0-256 128 128 0 1 0 0 256z"/></svg>
                <div class="w-10/12">
                  <p class="font-bold text-lg">{{servicesName}} Included <span class=" text-red-500">*</span></p>
                  <p class="text-xs text-gray-500">Does this {{servicesName}} have sub-categories or sub-{{servicesName}} it includes? </p>
                </div>
            </div>

            <div>
              <div class="flex justify-between w-1/2 ">
                <div class="form-control">
                  <label class="label cursor-pointer">
                    <input type="radio" required formControlName="hasSubServices" (change)="hasSubServiceClicked(true)" [value]="true"
                      class="radio checked:bg-customTeal" />
                    <span class="label-text ml-4 font-normal text-black">Yes</span>
                  </label>
                </div>
                <div class="form-control">
                  <label class="label cursor-pointer">
                    <input type="radio" required formControlName="hasSubServices" [value]="false"  (change)="hasSubServiceClicked(false)"
                      class="radio checked:bg-customTeal" />
                    <span class="label-text ml-4 font-normal text-black">No</span>
                  </label>
                </div>
              </div>
            </div>

            <div *ngIf="hasSubServices.value==true">
              <div>
                <button class="btn border-customTeal  bg-gray-100 text-customTeal hover:text-white" type="button" (click)="addNewServiceDetails()"><i class="material-icons">add</i>Add Item</button>
              </div>
              <div *ngIf="allServices.length > 0" class="flex flex-row gap-4 flex-wrap mt-4">
                <div class="  gap-x-2"
                  *ngFor="let serviceUnder of allServices; let i=index"  class="w-full  md:w-3/12  flex flex-row flex-wrap gap-4 items-center rounded-md border-2 ">
                  <div class="w-full mb-5" *ngIf="serviceUnder">
                    <div class="flex flex-col bg-gray-50 space-y-3 rounded-md p-4 shadow-md break-words">
                      <span *ngIf="serviceUnder.imgLink">
                        <img loading="lazy" class="w-full h-28 md:h-44 object-cover" [src]="serviceUnder.imgLink" alt="">
                      </span>
                      <p class="">{{serviceUnder.serviceTitle}}</p>
                      <p class="font-bold">$ {{serviceUnder.servicePrice}} </p> 
                      <div class="flex justify-between">
                        <button class="btn btn-sm btn-primary" type="button" (click)="editServiceUnder(serviceUnder)">Edit</button>
                        <button class="btn btn-sm btn-error" type="button" (click)="removeServiceUnder(serviceUnder)">Delete</button>
                      </div>

                    </div>
                  </div> 
                </div>  
              </div>
            </div>
           
          </div>
          <br/>
          <!-- Pricing -->
          <div class="form-control bg-white md:px-10 px-2  py-6 space-y-2 shadow-sm"  *ngIf="hasSubServices.value==false">
            <div class="flex space-x-4 items-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 448 512"><path d="M0 80V229.5c0 17 6.7 33.3 18.7 45.3l176 176c25 25 65.5 25 90.5 0L418.7 317.3c25-25 25-65.5 0-90.5l-176-176c-12-12-28.3-18.7-45.3-18.7H48C21.5 32 0 53.5 0 80zm112 96c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z"/></svg>
             
                <div class="w-10/12">
                  <p class="font-bold text-lg">Pricing <span class="text-red-500 text-sm">*</span></p>
                  <p class="text-xs text-gray-500">Let users know what the cost is in order to attend, pricing could be paid, free, or hidden.  </p>
                </div>
              </div>   

              <div  class="flex gap-x-5 px-3">
                <button type="button" (click)="pricingSet('paid')" 
                class="text-black font-bold rounded-lg py-2 px-4 md:py-2 md:px-10 w-max border-solid border bg-white border-gray-400" 
                [ngClass]="{'bg-customTeal text-white': (pricing =='paid')}">
                  Paid
                </button>
                
                <button type="button" (click)="pricingSet('dontInclude')" 
                class="text-black font-bold rounded-lg px-4 md:py-2 md:px-10 w-max border-solid border bg-white border-gray-400"
                [ngClass]="{'bg-customTeal text-white': (pricing =='dontInclude')}">
                  Don't Include
                </button>
              </div>
            <div *ngIf="pricing =='paid' || hasPrice.value" class="form-control w-full ">
              <label class="label">
                <span class="label-text text-[#4F4F4F] text-base font-bold leading-5">Price</span>
              </label>
              <input id="price" type="text" [placeholder]="'Price (IN '+currency+')'" 
              class="input input-bordered input-primary w-full md:w-7/12" 
              [ngClass]="{
                'border-red-500': (price.dirty || price.touched) && price.invalid,
                'border-gray-200': !((price.dirty || price.touched) && price.invalid)
              }" autocomplete="off"
                formControlName="price"  />
                <div *ngIf="(price.dirty || price.touched) && price.invalid">
                  <small  class="text-red-500 italic">Price could only be a positive number.</small>
                </div>
            </div>
       
          </div>
          <!-- Has Appointment(No sub service) -->
          <ng-container  *ngIf="appSetting" >
            <div *ngIf="hasSubServices.value == false && appSetting.takeAppointment" class="form-control mb-3 w-full bg-white md:px-10 px-2  py-6 space-y-2 shadow-sm">
              <div class="form-control px-2  py-6 space-y-2 shadow-sm">
                <div c                                                                                      lass="flex space-x-4 items-center">
                  <svg width="32" height="32" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.20514 6.74359C10.7912 6.74359 12.0769 5.45784 12.0769 3.87179C12.0769 2.28575 10.7912 1 9.20514 1C7.61909 1 6.33334 2.28575 6.33334 3.87179C6.33334 5.45784 7.61909 6.74359 9.20514 6.74359Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M1 16.1795C1 12.5541 4.30626 9.61539 8.38461 9.61539" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M13.3077 17C15.3469 17 17 15.3469 17 13.3077C17 11.2685 15.3469 9.61539 13.3077 9.61539C11.2685 9.61539 9.61539 11.2685 9.61539 13.3077C9.61539 15.3469 11.2685 17 13.3077 17Z" stroke="black" stroke-width="1.5"/>
                    <path d="M12.8974 12.0769V13.7179H14.5385" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <div class="w-10/12">
                      <p class="font-bold text-lg">Appointment <span class="text-red-500">*</span></p>
                      <p class="text-xs text-gray-500">Does this service require an Appointment? </p>
                    </div>
                </div>  
                <div class="flex justify-between w-1/2 ">
                  <div class="form-control">
                    <label class="label cursor-pointer">
                      <input type="radio" required formControlName="hasAppointment" [value]="true"
                        class="radio checked:bg-customTeal" />
                      <span class="label-text ml-4 font-normal text-black">Yes</span>
                    </label>
                  </div>
                  <div class="form-control">
                    <label class="label cursor-pointer">
                      <input type="radio" required formControlName="hasAppointment" [value]="false"
                        class="radio checked:bg-customTeal" />
                      <span class="label-text ml-4 font-normal text-black">No</span>
                    </label>
                  </div>
                </div>
                <div *ngIf="doesAnyoneHaveSchedule" >
                <ng-container *ngIf="hasAppointmentS.value" class="">
                  <hr/>
                  <!-- Duration -->
                  <div class="form-control md:px-10 px-2  py-3 space-y-2 " >
                    <div class="flex space-x-2 items-center">
                    <svg width="24" height="24" viewBox="0 0 232 232" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M106.333 203C94.2502 203 82.9337 200.744 72.3842 196.233C61.8282 191.722 52.6029 185.519 44.7085 177.625C36.8141 169.731 30.6113 160.505 26.1002 149.949C21.5891 139.4 19.3335 128.083 19.3335 116C19.3335 91.6722 27.7532 71.0887 44.5925 54.2493C61.4254 37.4164 82.0057 29 106.333 29C108.428 29 110.442 29.0806 112.375 29.2417C114.309 29.4028 116.242 29.6444 118.175 29.9667C120.27 30.2889 122.042 31.3748 123.492 33.2243C124.942 35.0803 125.667 37.1361 125.667 39.3917C125.667 42.6139 124.581 45.0692 122.409 46.7577C120.231 48.4526 117.45 49.1389 114.067 48.8167C112.778 48.6556 111.489 48.5331 110.2 48.4493C108.911 48.372 107.622 48.3333 106.333 48.3333C87.3224 48.3333 71.2918 54.8583 58.2418 67.9083C45.1918 80.9583 38.6668 96.9889 38.6668 116C38.6668 135.011 45.1918 151.042 58.2418 164.092C71.2918 177.142 87.3224 183.667 106.333 183.667C125.183 183.667 141.095 177.222 154.068 164.333C167.034 151.444 173.678 135.575 174 116.725C174 113.986 174.928 111.569 176.784 109.475C178.634 107.381 180.928 106.333 183.667 106.333C186.245 106.333 188.5 107.258 190.434 109.108C192.367 110.964 193.333 113.1 193.333 115.517V116C193.333 140.328 184.914 160.908 168.074 177.741C151.242 194.58 130.661 203 106.333 203ZM126.634 149.833L99.5668 122.767C98.6002 121.8 97.8752 120.711 97.3918 119.499C96.9085 118.294 96.6668 117.047 96.6668 115.758V77.3333C96.6668 74.5945 97.5948 72.297 99.4508 70.441C101.3 68.5914 103.595 67.6667 106.333 67.6667C109.072 67.6667 111.37 68.5914 113.226 70.441C115.075 72.297 116 74.5945 116 77.3333V112.133L140.167 136.3C141.939 138.072 142.825 140.328 142.825 143.067C142.825 145.806 141.939 148.061 140.167 149.833C138.395 151.606 136.139 152.492 133.4 152.492C130.661 152.492 128.406 151.606 126.634 149.833Z" fill="black"/>
                      </svg>
                      <span class="label-text text-[#4F4F4F] text-base font-bold  leading-5">Duration(How much time does it take)</span>
                    </div>

                    <div class="flex flex-row gap-2 w-1/2">
                      <div class="form-control w-1/2">
                        <label class="label">
                          <span class="label-text text-[#4F4F4F] text-base font-normal leading-5">Hour</span>
                        </label>
                        <input placeholder="Type here" id="serviceHour" type="number" formControlName="serviceHour" min="0"
                          max="23" class="input input-bordered input-primary w-full" />
                      </div>
                      <div class="form-control w-1/2">
                        <label class="label">
                          <span class="label-text text-[#4F4F4F] text-base font-normal leading-5">Minute</span>
                        </label>
                        <input placeholder="Type here" id="serviceMinute" type="number" formControlName="serviceMinute" min="0"
                          max="59" class="input input-bordered input-primary w-full" />
                      </div>
                    </div>

                  </div>
                  <hr/>

                  <!-- buffer time -->
                  <ng-container>
                    <div class="form-control bg-white md:px-10 px-2  py-3 space-y-3 " >
                      <div class="flex gap-2 space-x-2 items-center">
                      <svg width="16" height="16" viewBox="0 0 220 220" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.1576 64.1309L101.978 105.089C104.187 106.141 107.134 106.691 110.052 106.691C112.969 106.691 115.917 106.141 118.121 105.089L202.942 64.1309C207.402 61.9824 207.402 58.4676 202.942 56.3105L118.121 15.3527C115.917 14.3 112.965 13.75 110.052 13.75C107.138 13.75 104.187 14.3 101.978 15.3527L17.1576 56.3105C12.6975 58.4676 12.6975 61.9738 17.1576 64.1309Z" fill="black"/>
                        <path d="M202.941 106.09C202.941 106.09 187.451 98.6219 185.397 97.6164C183.343 96.6109 182.789 96.6668 180.632 97.668C178.475 98.6691 118.07 127.845 118.07 127.845C115.523 128.944 112.773 129.492 110 129.452C107.091 129.452 104.135 128.898 101.931 127.845C101.931 127.845 43.1278 99.4856 40.3692 98.1191C37.3829 96.6797 36.5235 96.6797 33.8036 97.9688L17.0458 106.038C12.5856 108.187 12.5856 111.706 17.0458 113.859L101.879 154.816C104.083 155.869 107.035 156.419 109.949 156.419C112.862 156.419 115.814 155.869 118.022 154.816L202.843 113.867C207.402 111.753 207.402 108.247 202.941 106.09Z" fill="black"/>
                        <path d="M202.941 155.869C202.941 155.869 187.451 148.401 185.397 147.383C183.343 146.364 182.789 146.433 180.632 147.434C178.475 148.436 118.121 177.676 118.121 177.676C115.574 178.773 112.824 179.32 110.052 179.278C107.143 179.278 104.186 178.728 101.978 177.676C101.978 177.676 43.175 149.316 40.4207 147.95C37.4129 146.493 36.5535 146.493 33.8508 147.8L17.093 155.869C12.6285 158.018 12.6285 161.532 17.093 163.689L101.913 204.647C104.118 205.747 107.07 206.25 109.983 206.25C112.896 206.25 115.848 205.7 118.052 204.647L202.89 163.711C207.402 161.532 207.402 158.026 202.941 155.869Z" fill="black"/>
                        </svg>

                        <span class="label-text text-[#4F4F4F] text-base font-bold leading-5">Buffer time(in Minutes)
                          <div class="tooltip" data-tip="Extra time added to a service(to cleanup for the appointment or to prepare for the next appointment)">
                            <button type="button"><i class="material-icons">help</i></button>
                          </div>
                        </span>
                
                      </div>
                      <div class="flex flex-row gap-2">
                        <div class="form-control w-1/2 ">
                          <input placeholder="Type here" id="bufferTime" type="number" formControlName="bufferTime" min="0"
                            max="120" class="input input-bordered input-primary"
                            [ngClass]="{
                              'border-red-500': (bufferTime.dirty || bufferTime.touched) && bufferTime.invalid,
                              'border-gray-200': !((bufferTime.dirty || bufferTime.touched) && bufferTime.invalid)
                            }" />
                        </div>
                      </div>
                      <div *ngIf="(bufferTime.dirty || bufferTime.touched) && bufferTime.invalid">
                        <small *ngIf="bufferTime.errors.required" class="text-red-500 italic text-xs">Invalid buffer time.
                        </small>
                      </div>
                    </div>
                  </ng-container>
                  <hr/>
                  
                  <!-- assign employee -->
                  <div *ngIf="employees.length >0" class="form-control md:px-10 px-2  py-3 space-y-2 ">
                    <div class="flex gap-2 space-x-2 items-center">
                    <svg width="24" height="24" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M16.5 14.6479C18.9098 14.6479 20.8633 12.719 20.8633 10.3396C20.8633 7.96016 18.9098 6.03125 16.5 6.03125C14.0902 6.03125 12.1367 7.96016 12.1367 10.3396C12.1367 12.719 14.0902 14.6479 16.5 14.6479Z" fill="black"/>
                      <path d="M9.88166 10.7705H10.3217V10.3764C10.3243 9.23018 10.6477 8.1076 11.2552 7.13562C11.8627 6.16364 12.73 5.38105 13.7592 4.87638C13.4545 4.17473 12.9655 3.5687 12.344 3.12265C11.7226 2.6766 10.992 2.4072 10.2297 2.34305C9.46748 2.27889 8.70208 2.4224 8.01484 2.75831C7.3276 3.09422 6.74417 3.60999 6.32652 4.25086C5.90887 4.89172 5.67259 5.63373 5.64278 6.3981C5.61297 7.16246 5.79075 7.92062 6.15722 8.59206C6.5237 9.26351 7.06519 9.82315 7.72418 10.2116C8.38318 10.6 9.13507 10.8026 9.9 10.798L9.88166 10.7705ZM22.6967 10.3397V10.7339H23.1367C23.8914 10.7263 24.6301 10.5154 25.2751 10.1234C25.9201 9.73135 26.4475 9.17269 26.8017 8.5062C27.156 7.83972 27.324 7.09006 27.2881 6.33613C27.2522 5.58221 27.0138 4.8519 26.5978 4.22208C26.1818 3.59226 25.6038 3.08622 24.9245 2.75723C24.2452 2.42824 23.4897 2.28847 22.7377 2.35262C21.9856 2.41677 21.2648 2.68248 20.651 3.12178C20.0373 3.56107 19.5533 4.15771 19.25 4.84888C20.2794 5.35158 21.1478 6.13218 21.7568 7.10249C22.3659 8.07279 22.6914 9.1941 22.6967 10.3397ZM20.4325 15.0789C22.2551 15.4396 24.0173 16.0569 25.6667 16.9122C25.899 17.0399 26.1095 17.2039 26.29 17.3981H31.1667V14.2631C31.1676 14.1428 31.1356 14.0246 31.0742 13.9212C31.0128 13.8178 30.9243 13.7332 30.8183 13.6764C28.4425 12.4337 25.7996 11.7887 23.1183 11.7972H22.5133C22.1956 13.0885 21.465 14.2408 20.4325 15.0789ZM5.98583 19.1764C5.98416 18.7147 6.10883 18.2614 6.34636 17.8655C6.58388 17.4697 6.9252 17.1463 7.33333 16.9305C8.98267 16.0752 10.7449 15.458 12.5675 15.0972C11.54 14.2663 10.8098 13.1244 10.4867 11.8431H9.88166C7.20044 11.8345 4.55753 12.4795 2.18166 13.7222C2.07567 13.779 1.9872 13.8637 1.92581 13.967C1.86442 14.0704 1.83244 14.1886 1.83333 14.3089V20.1664H5.98583V19.1764ZM19.6717 24.4656H25.135V25.7489H19.6717V24.4656Z" fill="black"/>
                      <path d="M30.0758 19.4885H23.7783V18.5718C23.7783 18.3287 23.6818 18.0955 23.5099 17.9236C23.3379 17.7517 23.1048 17.6552 22.8617 17.6552C22.6186 17.6552 22.3854 17.7517 22.2135 17.9236C22.0416 18.0955 21.945 18.3287 21.945 18.5718V19.4885H20.1667V16.8943C18.9606 16.6411 17.7323 16.509 16.5 16.5002C13.6068 16.4881 10.7549 17.1869 8.195 18.5352C8.08098 18.5943 7.98553 18.6838 7.91921 18.7938C7.85288 18.9038 7.81826 19.03 7.81917 19.1585V24.301H14.3367V29.8835C14.3367 30.1266 14.4332 30.3598 14.6052 30.5317C14.7771 30.7036 15.0102 30.8002 15.2533 30.8002H30.0758C30.319 30.8002 30.5521 30.7036 30.724 30.5317C30.8959 30.3598 30.9925 30.1266 30.9925 29.8835V20.4052C30.9925 20.162 30.8959 19.9289 30.724 19.757C30.5521 19.5851 30.319 19.4885 30.0758 19.4885ZM29.1592 28.9852H16.17V21.3218H21.945V22.156C21.945 22.3991 22.0416 22.6323 22.2135 22.8042C22.3854 22.9761 22.6186 23.0727 22.8617 23.0727C23.1048 23.0727 23.3379 22.9761 23.5099 22.8042C23.6818 22.6323 23.7783 22.3991 23.7783 22.156V21.3218H29.1592V28.9852Z" fill="black"/>
                      </svg>
                      <span class="label-text text-[#4F4F4F] text-base font-bold leading-5">Do you want to assign employees to this service?<span class="text-red-500">*</span></span>
                    </div>
                    <div class="flex justify-between w-1/2 ">
                      <div class="form-control">
                        <label class="label cursor-pointer">
                          <input type="radio" formControlName="showEmployees" [value]="true"
                            class="radio checked:bg-customTeal" />
                          <span class="label-text ml-4 font-normal text-black">Yes</span>
                        </label>
                      </div>
                      <div class="form-control">
                        <label class="label cursor-pointer">
                          <input type="radio" formControlName="showEmployees" [value]="false"
                            class="radio checked:bg-customTeal" />
                          <span class="label-text ml-4 font-normal text-black">No</span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <!-- employee List-->
                  <ng-container *ngIf="showEmployeesS.value">
                    <div class="md:px-10 px-2  py-3 space-y-2 ">
                      <div class="label-text text-[#4F4F4F] text-base font-bold leading-5 pb-3   ">Assign employee(s) from your list</div>
                    <div class="flex flex-row flex-wrap gap-y-2 gap-x-7 justify-start">
                      <div *ngIf="selectedEmployeeName">
                        <span *ngFor="let emp of selectedEmployeeName">
                          <div class="badge badge-ghost">{{emp.GivenName}} {{emp.LastName}}</div>
                        </span>
                      </div>
                      <div class="form-control w-full" *ngIf="employees.length >0">
                        <div (click)="isList = !isList"
                          class="w-full p-4 shadow rounded bg-white text-sm font-medium leading-none text-gray-800 flex items-center justify-between cursor-pointer">
                          Select Employees 
                          <div>
                            <div class="hidden" id="close">
                              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.00016 0.666664L9.66683 5.33333L0.333496 5.33333L5.00016 0.666664Z" fill="#1F2937" />
                              </svg>
                            </div>
                            <div id="open">
                              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.00016 5.33333L0.333496 0.666664H9.66683L5.00016 5.33333Z" fill="#1F2937" />
                              </svg>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="isList" class="w-full mt-2 p-3 bg-white shadow rounded" id="dropdown">
                          <div class="flex flex-col md:flex-wrap justify-start gap-x-2 gap-y-4">
                            <div class="flex items-center w-1/2" *ngFor="let employee of assignedTo.controls;let li=index"
                              [formGroup]="employee">
                              <div
                                class="bg-gray-100 dark:bg-gray-800 border rounded-sm border-gray-200 dark:border-gray-700 w-3 h-3 flex flex-shrink-0 justify-center items-center relative">
                                <input type="checkbox" class="checkbox checked:bg-customTeal absolute cursor-pointer w-full h-full"
                                  formControlName='value' (change)="employeeSelected(employee)"/>
                                <div class="check-icon  text-white rounded-sm">
                                  <svg class="icon icon-tabler icon-tabler-check" xmlns="http://www.w3.org/2000/svg" width="12"
                                    height="12" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" />
                                    <path d="M5 12l5 5l10 -10" />
                                  </svg>
                                </div>
                              </div>
                              <p class="text-sm leading-normal ml-2 text-gray-800">
                                {{employee.controls['employee'].value.GivenName}} {{employee.controls['employee'].value.LastName}}
                              </p>
                            </div>
                          </div>
                          <button (click)="isList = false"
                            class="text-xs bg-indigo-100 hover:bg-indigo-200 rounded-md mt-6 font-medium py-2 w-full leading-3 text-indigo-700">Select</button>
                        </div>
                      </div>
                    </div>
                    
                    </div>
                  </ng-container>

                </ng-container> 
              
                </div>

                <div *ngIf="!doesAnyoneHaveSchedule && hasAppointmentS.value">
                  <br/>
                  <p class="italic">Add a schedule first to setup appointments</p>

                  <button  [routerLink]="['/admin/schedule']"
              class="flex py-2 md:px-10 space-x-2 md:border-customTeal md:border rounded-md text-black w-max  md:bg-white  justify-center"
              >
              <i class="material-icons mr-3 text-customTeal float-left">add</i>
              <span class="font-bold ">
                Add Schedule
              </span>
            </button>
                </div>

              </div>
              
            </div>
          </ng-container>

          <!-- Allow Comment -->
          <div class="form-control w-full bg-white md:px-10 px-2 py-6 space-y-2 shadow-sm">
            <div class="flex space-x-4 items-center">
              <svg width="36" height="36" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 32.2838H37C37.51 32.2838 37.9372 32.1135 38.2816 31.773C38.6272 31.4336 38.8 31.0127 38.8 30.5101C38.8 30.0076 38.6272 29.5861 38.2816 29.2455C37.9372 28.9062 37.51 28.7365 37 28.7365H19C18.49 28.7365 18.0622 28.9062 17.7166 29.2455C17.3722 29.5861 17.2 30.0076 17.2 30.5101C17.2 31.0127 17.3722 31.4336 17.7166 31.773C18.0622 32.1135 18.49 32.2838 19 32.2838ZM19 26.9628H37C37.51 26.9628 37.9372 26.7926 38.2816 26.452C38.6272 26.1127 38.8 25.6917 38.8 25.1892C38.8 24.6866 38.6272 24.2651 38.2816 23.9246C37.9372 23.5852 37.51 23.4155 37 23.4155H19C18.49 23.4155 18.0622 23.5852 17.7166 23.9246C17.3722 24.2651 17.2 24.6866 17.2 25.1892C17.2 25.6917 17.3722 26.1127 17.7166 26.452C18.0622 26.7926 18.49 26.9628 19 26.9628ZM19 21.6419H37C37.51 21.6419 37.9372 21.4722 38.2816 21.1328C38.6272 20.7923 38.8 20.3708 38.8 19.8682C38.8 19.3657 38.6272 18.9442 38.2816 18.6036C37.9372 18.2643 37.51 18.0946 37 18.0946H19C18.49 18.0946 18.0622 18.2643 17.7166 18.6036C17.3722 18.9442 17.2 19.3657 17.2 19.8682C17.2 20.3708 17.3722 20.7923 17.7166 21.1328C18.0622 21.4722 18.49 21.6419 19 21.6419ZM42.94 43.4578L38.8 39.3784H13.6C12.61 39.3784 11.7628 39.0313 11.0584 38.3372C10.3528 37.642 10 36.8066 10 35.8311V14.5473C10 13.5718 10.3528 12.7364 11.0584 12.0411C11.7628 11.347 12.61 11 13.6 11H42.4C43.39 11 44.2378 11.347 44.9434 12.0411C45.6478 12.7364 46 13.5718 46 14.5473V42.1719C46 42.97 45.6328 43.524 44.8984 43.8338C44.1628 44.1447 43.51 44.0194 42.94 43.4578ZM13.6 14.5473V35.8311H40.285L42.4 37.9151V14.5473H13.6ZM13.6 14.5473V37.9151V14.5473Z" fill="black"/>
                </svg>
                <div class="w-10/12">
                  <span class="label-text text-[#4F4F4F] text-base font-bold leading-5">Allow Comments?</span>
                  <p class="text-xs text-gray-500">Would you want your users to be able to comment?</p>
                </div>
            </div>

            <div class="flex gap-x-10 w-1/2 ">
              <div class="form-control">
                <label class="label cursor-pointer">
                  <input type="radio" formControlName="commentable" [value]=true
                    class="radio checked:bg-customTeal" />
                  <span class="label-text ml-4 font-normal text-black">Yes</span>

                </label>
              </div>
              <div class="form-control">
                <label class="label cursor-pointer">
                  <input type="radio" formControlName="commentable" [value]=false
                    class="radio checked:bg-customTeal" />
                  <span class="label-text ml-4 font-normal text-black">No</span>

                </label>
              </div>
            </div>
          </div>

          <!-- Share with -->
          <div class="form-control bg-white md:px-10 px-2  py-6 space-y-2 shadow-sm" *ngIf="!categoryId">
            <label class="label py-2">
              <span class="label-text text-[#4F4F4F] text-base font-bold leading-5">Share With</span>
            </label>
            <div class="flex justify-between w-1/2 ">
              <div class="form-control">
                <label class="label cursor-pointer">
                  <input type="radio" formControlName="shareWithPublic" [value]=true
                    class="radio checked:bg-customTeal" />
                  <span class="label-text ml-4 font-normal text-black">Public</span>

                </label>
              </div>
              <div class="form-control">
                <label class="label cursor-pointer">
                  <input type="radio" formControlName="shareWithPublic" [value]=false
                    class="radio checked:bg-customTeal" />
                  <span class="label-text ml-4 font-normal text-black">Friends Only</span>

                </label>
              </div>
            </div>
          </div>
        </div>



        <div>
          <app-form-button  *ngIf="editServiceForm.valid" buttonStyle="preview" buttonType="button" (click)="previewChanges(editServiceForm.value)"></app-form-button>
        </div>
        <div class="flex md:flex-row flex-col justify-between w-full max-w-x pt-2 md:pt-20 gap-y-4   ">
          <div class="form-control flex flex-row justify-between gap-x-4 w-full">
            <div class="flex">
              <app-form-button buttonText="Cancel" buttonStyle="cancel" buttonType="button" (click)="cancelModalOpen=true"></app-form-button>
              <app-form-button buttonText="Delete" buttonStyle="delete" buttonType="button" (click)="openDeleteModal()"></app-form-button>
            </div>
            <div class="flex gap-x-4">
              <app-form-button buttonText="Update" [disabled]="!editServiceForm.valid" buttonStyle="filled" buttonType="button" (click)="editModalOpen=true"></app-form-button> 
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<div *ngIf="!haveAccessPermission">
  <app-no-access></app-no-access>
</div>
<div *ngIf="preview">
  <div class="flex flex-row-reverse mb-2 mr-5 md:ml-0">
    <button class="btn-custom-bordered" (click)="editModalOpen=true">Save Changes</button>
    <button class="btn-custom-solid" (click)="preview=false">Back to previous page</button>
  </div>
  <app-view-service [modelToPreview]="modelToPreview"></app-view-service>
</div>



<app-item-under-service-form *ngIf="createItemUnderServiceModal" [servicesName]="servicesName" [isEdit]="false" [canTakeAppt]="canTakeAppointment" (cancelClicked)="createItemUnderServiceModal=false" (saveItem)="savingItem($event)"></app-item-under-service-form>

<app-item-under-service-form *ngIf="editItemUnderServiceModal" [servicesName]="servicesName" [isEdit]="true" [canTakeAppt]="canTakeAppointment" (cancelClicked)="editItemUnderServiceModal=false" (editItem)="editItem($event)" [previousFormValues]="serviceCategoryUnderToBeEdited"  ></app-item-under-service-form>

<!-- Delete Modal -->
<app-delete-confirmation *ngIf="deleteModalOpen" (deleteClicked)=deleteService() (cancelDeleteClicked)="deleteModalOpen=false" [deletedFromComponent]=servicesName></app-delete-confirmation>

<!-- Edit Modal -->
<app-edit-confirmation *ngIf="editModalOpen" (cancelClicked)=closeEditModal() (saveBtnClicked)=updateService(editServiceForm.value) [editedFromComponent]=servicesName></app-edit-confirmation>

<!-- Cancel Modal -->
<app-cancel-confirmation *ngIf="cancelModalOpen" (cancelClicked)=backClicked() (continueClicked)=closeCancelModal() canceledFromAction="Editing" [canceledFromComponent]=servicesName></app-cancel-confirmation>

<!-- toast -->
<app-alert class="fixed top-0 flex justify-end w-full z-50" [toastType]="toastType" [toastMessage]="toastMessage" [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>
</ng-container>

<!-- Crop Image Modal -->
<app-crop-image *ngIf="cropModalOpen" [theImage]="imageChangedEvent" [useBase64]="useBase64" [aspectRatio]="4 / 3" (returnCroppedImage)="processCroppedImage($event)" (closeCropModal)=closeCroppingModal()></app-crop-image>

<!-- Upload File Modal -->
<app-file-upload-modal *ngIf="uploadFileModalOpen" [mediaList]="mediaList" (returnSavedMediaURLs)="getTheUrlsSaved($event)"></app-file-upload-modal>

<!-- Delete Service Under Modal -->
<app-delete-confirmation *ngIf="deleteServiceUnderModalOpen" (deleteClicked)=removeServiceUnder() (cancelDeleteClicked)=" deleteServiceUnderModalOpen=false" [deletedFromComponent]=servicesName></app-delete-confirmation>
