<div class="bg-white mx-3 lg:mx-10 rounded-md p-5 mt-3">
  <!-- Title -->
  <div class="font-bold text-lg text-gray-600">
    FAQ Settings
    <div class="tooltip" data-tip="This page allows you to add frequently asked questions with their answer">
      <button type="button"><i class="material-icons">help</i></button>
    </div>
  </div>
  <div>

    <form [formGroup]="faqForm" (ngSubmit)="saveFAQ(faqForm.value)">
      <div class="lg:px-12 px-5">
        <!-- Question -->
        <div class="mt-6  ">
          <label for="Title" class="block tracking-wide text-gray-700 text-sm font-bold mb-2"> Question
            <span class="text-red-500">*</span></label>
          <div class="w-[92%]">
            <app-editor [control]="question" class="bg-gray-100 " [inputType]="'question'"
              fromComponent="FAQ"></app-editor>
          </div>
        </div>
        <!-- Answer -->
        <div class="w-full my-6">
          <label for="Description" class="block text-sm font-bold mb-2 ">Answer
            <span class="text-red-500">*</span> </label>
          <div class="w-[92%]">
            <app-editor [control]="answer" class="bg-gray-100 " [inputType]="'answer'" fromComponent="FAQ"></app-editor>
          </div>
        </div>

        <!-- the reason the width 92% is because of the editor , its making the page to overflow -->
        <div class="flex justify-end w-[92%]">
          <button (click)="createModalOpen=true" type="button" [ngClass]="{'bg-[#cccccc] text-[#666666]': !faqForm.valid,
          'bg-customTeal': faqForm.valid}" [disabled]="!faqForm.valid"
            class="py-2 px-6 mt-5 mb-10 gap-2 rounded-md text-white">Save</button>
        </div>

      </div>
    </form>

  </div>


</div>

<!-- create Modal -->
<app-create-confirmation *ngIf="createModalOpen" (cancelClicked)=closeCreateModal()
  (saveBtnClicked)=saveFAQ(faqForm.value) createdFromComponent='FAQ' componentName="FAQ"></app-create-confirmation>

<!-- ChatGpt Modal -->
<app-chat-gpt *ngIf="chatGptModalOpen" [theInput]="selectedInput" [fromComponent]="'FAQ'"
  [requestMessage]="requestMessage" (onMessageSelect)="updateFAQquestion($event)"
  (closeChatGptModal)="closeChatGptModal()"></app-chat-gpt>

<!-- toast -->
<app-alert class="fixed top-0 flex justify-end w-full z-[2000]" [toastType]="toastType" [toastMessage]="toastMessage"
  [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>