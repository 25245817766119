import { Component, OnInit } from '@angular/core';
import { AutomationService } from 'src/app/services/backend-automation/automation.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { OAuthcodeService } from 'src/app/services/backend-automation/oauthcode.service';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { AnalyticAccModel } from 'src/app/models/AutomationModel';

@Component({
  selector: 'app-create-analytics-account',
  templateUrl: './create-analytics-account.component.html',
  styleUrls: ['./create-analytics-account.component.css']
})
export class CreateAnalyticsAccountComponent implements OnInit {

  createAnalyticsForm: UntypedFormGroup;
  options:any;
  listOfAccounts:any = [];
  selectedRegion:any;
  isNextClicked: boolean;
  // createAnalyticsModal: boolean;
  authCode:string;
  inputAnalyticsName: string;
  Oauth2AccessToken: string | any;
  step1:boolean = false;
  step2:boolean = false;
  step3:boolean = false;
  step4: boolean = false;
  toastClass: any;
  toastMessage: any;
  toastType: any;
  openToast: boolean;

  authenticate:boolean = true
  analyticsCreation:boolean = false
  tos:boolean = false
  final:boolean = false
  congra:boolean = false

  constructor(private automationService: AutomationService,
    private route: ActivatedRoute, private router: Router,
    private oauthservice: OAuthcodeService,
    private fb: UntypedFormBuilder) { }

  ngOnInit(): void {

    this.options = [
      { label: 'United States', value: 'US' },
      { label: 'United Kingdom', value: 'GB' },
      { label: 'Canada', value: 'CA' },
      { label: 'Australia', value: 'AU' },
      { label: 'Germany', value: 'DE' },
      { label: 'Japan', value: 'JP' },
      { label: 'India', value: 'IN' },
      { label: 'France', value: 'FR' },
      { label: 'Brazil', value: 'BR' },
      { label: 'China', value: 'CN' }
    ];

    // this.oauth2()
    this.route.queryParams.subscribe(params => {
      if (params['code']) {
        // Do something with the code query parameter
        const code = params['code'];
        // Your logic here, for example, making an API call
        console.log('Code:', code);
        this.oauthservice.updateOauthcode(code);
        // window.history.replaceState({}, "http://localhost:4200/admin/create-analytics-account")

      }
    })

    this.automationService.listAccountDetails().subscribe((res2: any) => {
      this.listOfAccounts = res2;
      
    })

    let regexForAnalayticsName = /^[a-zA-Z0-9_]+\s?[a-zA-Z0-9_]+|\w+$/
    this.createAnalyticsForm = this.fb.group({
      analyticsName: ['', [Validators.required, this.validateAnalyticsName(), this.customValidator("accName", regexForAnalayticsName)]],
      region:['US', Validators.required]
    })

  }

  get analyticsName() { return this.createAnalyticsForm.get('analyticsName'); }
  get region() { return this.createAnalyticsForm.get('region')}

  next_step1(){
    this.authenticate = false
    this.analyticsCreation = true
  }
  next_step2(){
    this.analyticsCreation = false
    this.tos = true
  }
  next_step3(){
    this.tos = false
    this.final = true
  }

  next_step4(){
    this.final = false
    this.congra = true

  }

  oauth2(){
    
    let scope = "https://www.googleapis.com/auth/analytics.edit+https://www.googleapis.com/auth/analytics.manage.users"
    let redirect_uri =  "https://demo.goingmobile.app/admin/create-analytics-account"
    this.automationService.authorizeInOAuth2(scope, redirect_uri).subscribe((res:any) => {
      console.log(res, " yes recieved");
      window.open(res, 'Consent Window', 'width=600,height=400')
      this.step1 = true;
    })
 
  }

  navigateToAnalyticsSetup() {
    const queryParams = {
      code: '4/0Adeu5BUu5VMxR8YT6LCxQsOh9EpPHb5NcBcfVGBKQqZ71tl8OQ9Fi4kNhPDkL5sBSyqW6A',
      scope: 'https://www.googleapis.com/auth/analytics.manage.users',
    };
    this.router.navigate(['/admin/create-analytics-account'], { queryParams });
  }

  private validateAnalyticsName(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      let existingAcc = [];
      existingAcc = this.listOfAccounts.filter(acc => acc.name.toLowerCase() === control.value.toLowerCase());
      if (existingAcc.length > 0) {
        return { accExists: true };
      }

      else {
        return null;
      }
    };
  }

  createAnalyticsAccount(model){
    this.isNextClicked = true;
    // this.createAnalyticsModal = false
    this.oauthservice.getOauthcode().valueChanges().subscribe((res:string  |any) => {
          this.authCode = res[0].code
          console.log(res, " fetched stored code is here")
    
          console.log(model.analyticsName, " analytics name")
          console.log(model.region, " region code ")
          this.createAnalyticsForm.get('region')?.value
    
          this.inputAnalyticsName = model.analyticsName;
    
          this.automationService.oauth2AccessToken(res[0].code, "https://demo.goingmobile.app/admin/create-analytics-account").subscribe((token:any) => {
            this.Oauth2AccessToken = token;

            const newAnalyticAccModel = new AnalyticAccModel();
            newAnalyticAccModel.accountName = model.analyticsName
            newAnalyticAccModel.regionCode = model.region
            newAnalyticAccModel.redirectUrl = "https://demo.goingmobile.app/admin/create-analytics-account"
            newAnalyticAccModel.accessToken =  this.Oauth2AccessToken
            // model.analyticsName, model.region, "https://demo.goingmobile.app/admin/create-analytics-account", this.Oauth2AccessToken
            this.automationService.createAnalyticsWithOAuth2(newAnalyticAccModel).subscribe((res:any) => {
              let obj = JSON.parse(res)
              console.log(obj, " object returned from create analytics")
              this.toast({ html: 'Provision ticket id successfully created! ', classes: 'green', type: 'success' });
              this.step2 = true;
              this.oauthservice.updateTos(`https://analytics.google.com/analytics/web/?provisioningSignup=true#/termsofservice/${obj.accountTicketId}`)
        
            })
          })      
        })        
  }


  selectRegion(e: any){ this.selectedRegion = e  }

  private customValidator(controlName: string, pattern: RegExp) {
    return (formControl: UntypedFormControl) => {
      const controlValue = formControl.value;
      if (controlValue && !pattern.test(controlValue.toLowerCase())) {
        return { [controlName]: true };
      }
      return null;
    };
  }

  gototos(){
    
    this.oauthservice.getTosLink().valueChanges().subscribe((res:string  |any) => {
      console.log(res, " tos link")
      var tosPopup = window.open(res[0].link, 'Consent Window', 'width=600,height=400')
      this.step3 = true;
    })

  }

  complete(){
    let redirect_uri =  "https://demo.goingmobile.app/admin/create-analytics-account"
    this.oauthservice.getOauthcode().valueChanges().subscribe((res:string  |any) => {
      this.authCode = res[0].code

    

      this.automationService.getAccountId(this.inputAnalyticsName, this.Oauth2AccessToken).subscribe((res: string  |any) => {
        console.log("hello added ", res)
        let accId = this.parseString(res)

        this.automationService.addServiceAccountToAnalytics(accId, this.Oauth2AccessToken).subscribe((res:any | boolean) => {
          console.log("hello added ", res)
          this.toast({ html: 'Successfully linked to backend configuration! ', classes: 'green', type: 'success' });
          this.step4 = true

          this.final = false
          this.congra = true
        })
      })

    })

    console.log(this.inputAnalyticsName, " this is the analytics name inputed by user")

  }

  parseString(string) {
    const slashIndex = string.indexOf("/");
    if (slashIndex === -1) { 
      return string;
    } else {
      return string.slice(slashIndex + 1);
    }
  }

  toast(obj) {
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green';
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
    }, 2000);
  }

  back(){  this.router.navigateByUrl('/admin/analytics-setup');  }

}
