<div class="flex flex-col mx-2 space-y-2 md:mx-12 lg:mx-4" *ngIf="invitedEmployees">
  <div class="flex flex-row items-center p-2 mt-2 md:space-x-6" *ngIf="invitedEmployees.length > 0">
    <div class="w-7/12 lg:w-4/12 ">
      <p class="text-sm ">Name</p>
    </div>
    <div class="hidden w-3/12 lg:block">
      <p class="text-sm ">Email</p>
    </div>
    <div class="w-5/12 lg:w-1/12">
      <p class="text-sm ">Status</p>
    </div>
    <!-- <div class="w-1/12">
      </div> -->
    <div class="w-1/12">
    </div>
  </div>
</div>


  <div>
    <div *ngFor="let item of invitedEmployees   
     let i=index" class="flex items-center justify-center w-full">
  
      <div (click)="viewEmployeeProfile(item)"
        class="flex flex-row items-center w-full p-2 mx-2 mt-2 bg-white border-2 rounded-md cursor-pointer md:space-x-6">
        <div class="flex w-7/12 lg:w-4/12">
          <div class="flex items-center space-x-3">
            <div class="form-check">
  
            </div>
            <div class="avatar placeholder">
  
              <div class="w-10 h-10 rounded-full bg-neutral-focus text-neutral-content ">
                <span class="text-2xl">{{item.firstName[0] | uppercase}} </span>
              </div>
            </div>
            <div>
              <div> {{item.firstName}} {{item.lastName}}
              </div>
  
            </div>
          </div>
        </div>
        <div class="hidden w-3/12 lg:block">
          <p class="text-sm ">{{item.inviteEmail}}</p>
        </div>
  
        <div class="w-3/12 lg:block">
          <p class="text-sm ">{{item.status}}</p>
        </div>
  
  
      </div>
    </div>
    <div class="w-full text-center ">
        <!-- <pagination-controls (pageChange)="p = $event"></pagination-controls> -->
      </div>
  </div>