<!-- <div class="container">
  <div class="row">
    <div class="col l1 "></div>
    <div class="col s12 l10 ">
      <br />
      <div class="row card white">
        <div class="card-content">
          <div class="row">
            <h6 class="center"><strong> Privacy Policy</strong></h6>
            <br />
            <p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your
              information when You use the Service and tells You about Your privacy rights and how the law protects You.
            </p>
            <br>
            <p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the
              collection and use of information in accordance with this Privacy Policy.</p>

            <div class="section"></div>
            <h5><strong>Interpretation and Definitions</strong></h5>
            <h5>Interpretation :</h5>
            <p>The words of which the initial letter is capitalized have meanings defined under the following
              conditions.</p>
            <br />
            <p>The following definitions shall have the same meaning regardless of whether they appear in singular or in
              plural.</p>
            <div class="section"></div>
            <h5>Definitions</h5>
            <p>For the purposes of this Privacy Policy:</p>
            <br />
            <ul>
              <li style="list-style-type:square; margin-left: 20px;"><strong>You</strong> means the individual accessing
                or using the Service, or the company, or other legal entity on behalf of which such individual is
                accessing or using the Service, as applicable.</li>
              <li style="list-style-type:square; margin-left: 20px;"><strong>Company </strong>(referred to as either
                "the Company", "We", "Us" or "Our" in this Agreement) refers to 360 Nation Administration Solutions, Box
                1410 High Level Alberta T0H 1Z0.</li>
              <li style="list-style-type:square; margin-left: 20px;"><strong>Application </strong>means the software
                program provided by the Company downloaded by You on any electronic device, named goingmobile.app</li>
              <li style="list-style-type:square; margin-left: 20px;"><strong>Affiliate </strong>means an entity that
                controls, is controlled by or is under common control with a party, where "control" means ownership of
                50% or more of the shares, equity interest or other securities entitled to vote for election of
                directors or other managing authority.</li>
              <li style="list-style-type:square; margin-left: 20px;"><strong>Account </strong>means a unique account
                created for You to access our Service or parts of our Service.</li>
              <li style="list-style-type:square; margin-left: 20px;"><strong>Website </strong>refers to My Nation ,
                accessible from https://goingmobile.app</li>
              <li style="list-style-type:square; margin-left: 20px;"><strong>Service </strong>refers to the Application
                or the Website or both.</li>
              <li style="list-style-type:square; margin-left: 20px;"><strong>Country </strong>refers to: Alberta, Canada
              </li>
              <li style="list-style-type:square; margin-left: 20px;"><strong>Service Provider </strong>means any natural
                or legal person who processes the data on behalf of the Company. It refers to third-party companies or
                individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the
                Company, to perform services related to the Service or to assist the Company in analyzing how the
                Service is used.</li>
              <li style="list-style-type:square; margin-left: 20px;"><strong>Third-party Social Media Service
                </strong>refers to any website or any social network website through which a User can log in or create
                an account to use the Service.</li>
              <li style="list-style-type:square; margin-left: 20px;"><strong>Personal Data </strong>is any information
                that relates to an identified or identifiable individual.</li>
              <li style="list-style-type:square; margin-left: 20px;"><strong>Cookies</strong> are small files that are
                placed on Your computer, mobile device or any other device by a website, containing the details of Your
                browsing history on that website among its many uses.</li>
              <li style="list-style-type:square; margin-left: 20px;"><strong>Device </strong>means any device that can
                access the Service such as a computer, a cellphone or a digital tablet.</li>
              <li style="list-style-type:square; margin-left: 20px;"><strong>Usage Data </strong>refers to data
                collected automatically, either generated by the use of the Service or from the Service infrastructure
                itself (for example, the duration of a page visit).</li>
            </ul>

            <div class="section"></div>
            <h5><strong>Collecting and Using Your Personal Data</strong></h5>
            <h5>Types of Data Collected</h5>
            <br />
            <h6>Personal Data</h6>
            <p>While using Our Service, We may ask You to provide Us with certain personally identifiable information
              that can be used to contact or identify You. Personally identifiable information may include, but is not
              limited to:</p>
            <ul>
              <li style="list-style-type:square; margin-left: 20px;">Email address</li>
              <li style="list-style-type:square; margin-left: 20px;">First name and last name.</li>
              <li style="list-style-type:square; margin-left: 20px;">Phone number</li>
              <li style="list-style-type:square; margin-left: 20px;">Address, State, Province, ZIP/Postal code, City
              </li>
              <li style="list-style-type:square; margin-left: 20px;">Usage Data</li>
            </ul>

            <br />
            <h6>Usage Data</h6>
            <p>Usage Data is collected automatically when using the Service.</p>
            <br />
            <p>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address),
              browser type, browser version, the pages of our Service that You visit, the time and date of Your visit,
              the time spent on those pages, unique device identifiers and other diagnostic data.</p>
            <br />
            <p>When You access the Service by or through a mobile device, We may collect certain information
              automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique
              ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet
              browser You use, unique device identifiers and other diagnostic data.</p>
            <br />
            <p>We may also collect information that Your browser sends whenever You visit our Service or when You access
              the Service by or through a mobile device.</p>

            <br />
            <h6>Information from Third-Party Social Media Services</h6>

            <p>The Company allows You to create an account and log in to use the Service through the following
              Third-party Social Media Services:</p>

            <ul>
              <li style="list-style-type:square; margin-left: 20px;">Google</li>
              <li style="list-style-type:square; margin-left: 20px;">Facebook</li>
              <li style="list-style-type:square; margin-left: 20px;">Twiter</li>
            </ul>

            <br />
            <p>If You decide to register through or otherwise grant us access to a Third-Party Social Media Service, We
              may collect Personal data that is already associated with Your Third-Party Social Media Service's account,
              such as Your name, Your email address, Your activities or Your contact list associated with that account.
            </p>
            <br />
            <p>If You decide to register through or otherwise grant us access to a Third-Party Social Media Service, We
              may collect Personal data that is already associated with Your Third-Party Social Media Service's account,
              such as Your name, Your email address, Your activities or Your contact list associated with that account.
            </p>
            <br />
            <p></p>

            <div class="section"></div>
            <h5>Information Collected while Using the Application</h5>
            <p>While using Our Application, in order to provide features of Our Application, We may collect, with your
              prior permission:</p>
            <ul>
              <li style="list-style-type:square; margin-left: 20px;">Information regarding your location</li>
              <li style="list-style-type:square; margin-left: 20px;">Information from your Device's phone book (contacts
                list)</li>
              <li style="list-style-type:square; margin-left: 20px;">Pictures and other information from your Device's
                camera and photo library</li>
            </ul>


            <p>We use this information to provide features of Our Service, to improve and customize Our Service. The
              information may be uploaded to the Company's servers and/or a Service Provider's server or it be simply
              stored on Your device.</p>
            <br />
            <p>You can enable or disable access to this information at any time, through Your Device settings.</p>

            <div class="section"></div>
            <h5>Tracking Technologies and Cookies</h5>
            <p>We use Cookies and similar tracking technologies to track the activity on Our Service and store certain
              information. Tracking technologies used are beacons, tags, and scripts to collect and track information
              and to improve and analyze Our Service.</p>
            <br />
            <p>You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However,
              if You do not accept Cookies, You may not be able to use some parts of our Service.</p>
            <br />
            <p>Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on your personal computer or
              mobile device when You go offline, while Session Cookies are deleted as soon as You close your web
              browser. Learn more about cookies: All About Cookies.</p>
            <p>We use both session and persistent Cookies for the purposes set out below:</p>

            <h6><strong>Necessary / Essential Cookies</strong></h6>
            <br />
            <p>Type: Session Cookies</p>
            <br />
            <p>Administered by: Us</p>
            <br />
            <p>Purpose: These Cookies are essential to provide You with services available through the Website and to
              enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user
              accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use
              these Cookies to provide You with those services.</p>

            <br />
            <h6><strong>Tracking and Performance Cookies</strong></h6>
            <br />
            <p>Type: Persistent Cookies</p>
            <br />
            <p>Administered by: Third-Parties</p>
            <br />
            <p>Purpose: These Cookies are used to track information about traffic to the Website and how users use the
              Website. The information gathered via these Cookies may directly or indirectly identify you as an
              individual visitor. This is because the information collected is typically linked to a pseudonymous
              identifier associated with the device you use to access the Website. We may also use these Cookies to test
              new advertisements, pages, features or new functionality of the Website to see how our users react to
              them.</p>
            <br />
            <p>For more information about the cookies we use and your choices regarding cookies, please visit our
              Cookies Policy.</p>

            <div class="section"></div>
            <h5><strong>Use of Your Personal Data</strong></h5>
            <p>The Company may use Personal Data for the following purposes:</p>
            <ul>
              <li style="list-style-type:square; margin-left: 20px;"><strong>To provide and maintain our Service,
                </strong>including to monitor the usage of our Service.</li>
              <li style="list-style-type:square; margin-left: 20px;"><strong>To manage Your Account:</strong> to manage
                Your registration as a user of the Service. The Personal Data You provide can give You access to
                different functionalities of the Service that are available to You as a registered user.</li>
              <li style="list-style-type:square; margin-left: 20px;"><strong>For the performance of a contract:</strong>
                the development, compliance and undertaking of the purchase contract for the products, items or services
                You have purchased or of any other contract with Us through the Service.</li>
              <li style="list-style-type:square; margin-left: 20px;"><strong>To contact You: </strong>To contact You by
                email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile
                application's push notifications regarding updates or informative communications related to the
                functionalities, products or contracted services, including the security updates, when necessary or
                reasonable for their implementation.</li>
              <li style="list-style-type:square; margin-left: 20px;"><strong>To provide You</strong> with news, special
                offers and general information about other goods, services and events which we offer that are similar to
                those that you have already purchased or enquired about unless You have opted not to receive such
                information.</li>
              <li style="list-style-type:square; margin-left: 20px;"><strong>To manage Your requests: </strong>To attend
                and manage Your requests to Us.</li>
            </ul>
            <br />
            <p>We may share your personal information in the following situations:</p>
            <ul>
              <li style="list-style-type:square; margin-left: 20px;"><strong>With Service Providers: </strong>We may
                share Your personal information with Service Providers to monitor and analyze the use of our Service, to
                contact You.</li>
              <li style="list-style-type:square; margin-left: 20px;"><strong>For Business transfers: </strong>We may
                share or transfer Your personal information in connection with, or during negotiations of, any merger,
                sale of Company assets, financing, or acquisition of all or a portion of our business to another
                company.</li>
              <li style="list-style-type:square; margin-left: 20px;"><strong>With Affiliates: </strong>We may share Your
                information with Our affiliates, in which case we will require those affiliates to honor this Privacy
                Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or
                other companies that We control or that are under common control with Us.</li>
              <li style="list-style-type:square; margin-left: 20px;"><strong>With Business partners: </strong>We may
                share Your information with Our business partners to offer You certain products, services or promotions.
              </li>
              <li style="list-style-type:square; margin-left: 20px;"><strong>With other users: </strong>when You share
                personal information or otherwise interact in the public areas with other users, such information may be
                viewed by all users and may be publicly distributed outside. If You interact with other users or
                register through a Third-Party Social Media Service, Your contacts on the Third-Party Social Media
                Service may see Your name, profile, pictures and description of Your activity. Similarly, other users
                will be able to view descriptions of Your activity, communicate with You and view Your profile.</li>
            </ul>


            <div class="section"></div>
            <h5><strong>Retention of Your Personal Data</strong> </h5>
            <p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in
              this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our
              legal obligations (for example, if we are required to retain your data to comply with applicable laws),
              resolve disputes, and enforce our legal agreements and policies.</p>
            <br />
            <p>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained
              for a shorter period of time, except when this data is used to strengthen the security or to improve the
              functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</p>

            <div class="section"></div>
            <h5><strong>Transfer of Your Personal Data</strong> </h5>
            <p>Your information, including Personal Data, is processed at the Company's operating offices and in any
              other places where the parties involved in the processing are located. It means that this information may
              be transferred to — and maintained on — computers located outside of Your state, province, country or
              other governmental jurisdiction where the data protection laws may differ than those from Your
              jurisdiction.</p>
            <br />
            <p>Your consent to this Privacy Policy followed by Your submission of such information represents Your
              agreement to that transfer.</p>
            <br />
            <p>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in
              accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an
              organization or a country unless there are adequate controls in place including the security of Your data
              and other personal information.</p>


            <div class="section"></div>
            <h5><strong>Disclosure of Your Personal Data</strong> </h5>
            <h6><strong>Business Transactions</strong></h6>
            <p>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred.
              We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy
              Policy.</p>
            <br />
            <h6><strong>Law enforcement</strong></h6>
            <p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do
              so by law or in response to valid requests by public authorities (e.g. a court or a government agency).
            </p>
            <br />
            <h6><strong>Other legal requirements</strong></h6>
            <p>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:
            </p>

            <ul>
              <li style="list-style-type:square; margin-left: 20px;">Comply with a legal obligation</li>
              <li style="list-style-type:square; margin-left: 20px;">Protect and defend the rights or property of the
                Company</li>
              <li style="list-style-type:square; margin-left: 20px;">Prevent or investigate possible wrongdoing in
                connection with the Service</li>
              <li style="list-style-type:square; margin-left: 20px;">Protect the personal safety of Users of the Service
                or the public</li>
              <li style="list-style-type:square; margin-left: 20px;">Protect against legal liability</li>
            </ul>

            <br />
            <h6><strong>Security of Your Personal Data</strong></h6>
            <p>The security of Your Personal Data is important to Us, but remember that no method of transmission over
              the Internet, or method of electronic storage is 100% secure. While We strive to use commercially
              acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>

            <div class="section"></div>
            <h5><strong>Detailed Information on the Processing of Your Personal Data</strong> </h5>
            <p>Service Providers have access to Your Personal Data only to perform their tasks on Our behalf and are
              obligated not to disclose or use it for any other purpose.</p>
            <br />
            <h5>Analytics</h5>
            <p>We may use third-party Service providers to monitor and analyze the use of our Service.</p>
            <br />
            <h6><strong>Google Analytics</strong></h6>
            <p>Google Analytics is a web analytics service offered by Google that tracks and reports website traffic.
              Google uses the data collected to track and monitor the use of our Service. This data is shared with other
              Google services. Google may use the collected data to contextualise and personalise the ads of its own
              advertising network.</p>
            <br />
            <p>For more information on the privacy practices of Google, please visit the Google Privacy & Terms web
              page: https://policies.google.com/privacy?hl=en</p>
            <br />
            <h6><strong>Firebase</strong></h6>
            <p>Firebase is an analytics service provided by Google Inc.</p>
            <br />
            <p>You may opt-out of certain Firebase features through your mobile device settings, such as your device
              advertising settings or by following the instructions provided by Google in their Privacy Policy:
              https://policies.google.com/privacy?hl=en</p>
            <br />
            <p>We also encourage you to review the Google's policy for safeguarding your data:
              https://support.google.com/analytics/answer/6004245.</p>
            <br />
            <p>For more information on what type of information Firebase collects, please visit the Google Privacy &
              Terms web page: https://policies.google.com/privacy?hl=en</p>

            <div class="section"></div>
            <h5><strong>Email Marketing</strong> </h5>
            <p>We may use Your Personal Data to contact You with newsletters, marketing or promotional materials and
              other information that may be of interest to You. You may opt-out of receiving any, or all, of these
              communications from Us by following the unsubscribe link or instructions provided in any email We send or
              by contacting Us.</p>
            <br />
            <p>We may use Email Marketing Service Providers to manage and send emails to You.</p>
            <br />
            <h6><strong>Mailchimp</strong></h6>
            <p>Mailchimp is an email marketing sending service provided by The Rocket Science Group LLC.</p>
            <br />
            <p>For more information on the privacy practices of Mailchimp, please visit their Privacy policy:
              https://mailchimp.com/legal/privacy/</p>
            <br />
            <h6><strong>Constant Contact</strong></h6>
            <p>Constant Contact is an email marketing sending service provided by Constant Contact, Inc.</p>
            <br />
            <p>For more information on the privacy practices of Constant Contact, please visit their Privacy policy:
              https://www.constantcontact.com/forward/privacy-center</p>
            <br />
            <h6><strong>AWeber</strong></h6>
            <p>AWeber is an email marketing sending service provided by AWeber Communications.</p>
            <br />
            <p>For more information on the privacy practices of AWeber, please visit their Privacy policy:
              https://www.aweber.com/privacy.htm</p>
            <br />
            <h6><strong>GetResponse</strong></h6>
            <p>GetResponse is an email marketing sending service provided by GetResponse.</p>
            <br />
            <p>For more information on the privacy practices of GetResponse, please visit their Privacy policy:
              https://www.getresponse.com/legal/privacy.html</p>



            <div class="section"></div>
            <h5><strong>Children's Privacy</strong> </h5>
            <p>Our Service does not address anyone under the age of 13. We do not knowingly collect personally
              identifiable information from anyone under the age of 13. If You are a parent or guardian and You are
              aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We
              have collected Personal Data from anyone under the age of 13 without verification of parental consent, We
              take steps to remove that information from Our servers.</p>
            <br />
            <p>If We need to rely on consent as a legal basis for processing Your information and Your country requires
              consent from a parent, We may require Your parent's consent before We collect and use that information.
            </p>

            <div class="section"></div>
            <h5><strong>Links to Other Websites</strong> </h5>
            <p>Our Service may contain links to other websites that are not operated by Us. If You click on a third
              party link, You will be directed to that third party's site. We strongly advise You to review the Privacy
              Policy of every site You visit.</p>
            <br />
            <p>Our Service may contain links to other websites that are not operated by Us. If You click on a third
              party link, You will be directed to that third party's site. We strongly advise You to review the Privacy
              Policy of every site You visit.</p>

            <div class="section"></div>
            <h5><strong>Changes to this Privacy Policy</strong></h5>
            <p>We may update our Privacy Policy from time to time. We will notify You of any changes by posting the new
              Privacy Policy on this page.</p>
            <br />
            <p>We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming
              effective and update the "Last updated" date at the top of this Privacy Policy.</p>
            <br />
            <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy
              Policy are effective when they are posted on this page.</p>

            <div class="section"></div>
            <h5><strong>Contact Us</strong></h5>
            <p>If you have any questions about this Privacy Policy, You can contact us:</p>
            <br />
            <ul>
              <li style="list-style-type:square; margin-left: 20px;"> By email: info@goingmobile.app </li>
              <li style="list-style-type:square; margin-left: 20px;"> By visiting this page on our website:
                https://goingmobile.app</li>
            </ul>

          </div>
        </div>
      </div>
    </div>
    <div class="col l1 "></div>
  </div>

  <div class="row col s12">
    <div class="col l1 "></div>
    <div class="col s12 l10">
      <a [ngClass]="'col s12 btn ' + themeColor" (click)="backClicked()"><i
          class="material-icons left">arrow_back_ios_new</i> Back To Previous Page </a>
    </div>
    <div class="col l1"></div>
  </div>
</div> -->

<div class="flex flex-col mx-10 lg:mx-32 text-justify">
  <div class="text-xl text-customTeal text-center mt-4 mb-2"><strong>Privacy Policy</strong></div>

  <div class="ml-4 mb-4 text-md lg:text-sm">This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.
  </div>

  <div class="ml-4 mb-4 text-md lg:text-sm">We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.
  </div>
  <div class="text-xl text-black mt-4 mb-2"><strong>Interpretation and Definitions</strong></div>
  <div class="text-xl text-black mt-4 mb-2"><strong>Interpretation :</strong></div>

  <div class="ml-4 mb-4 text-md lg:text-sm">The words of which the initial letter is capitalized have meanings defined under the following conditions.
  </div>

  <div class="ml-4 mb-4 text-md lg:text-sm">The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
  </div>

  <div class="text-xl text-black mt-4 mb-2"><strong>Definitions</strong></div>
  <div class="ml-4 mb-4 text-md lg:text-sm">For the purposes of this Privacy Policy:
  </div>

  <ul class="list-disc ml-16 mb-4 text-md lg:text-sm">
    <li>You means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
    <li>Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to 360 Nation Administration Solutions, Box 1410 High Level Alberta T0H 1Z0.</li>
    <li>Application means the software program provided by the Company downloaded by You on any electronic device, named goingmobile.app</li>
    <li>Affiliate means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</li>
    <li>Account means a unique account created for You to access our Service or parts of our Service.</li>
    <li>Website refers to My Nation , accessible from https://goingmobile.app</li>
    <li>Service refers to the Application or the Website or both.</li>
    <li>Country refers to: Alberta, Canada</li>
    <li>Service Provider means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</li>
    <li>Third-party Social Media Service refers to any website or any social network website through which a User can log in or create an account to use the Service.</li>
    <li>Personal Data is any information that relates to an identified or identifiable individual.</li>
    <li>Cookies are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.
    </li>
    <li>Device means any device that can access the Service such as a computer, a cellphone or a digital tablet.</li>
    <li>Usage Data refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</li>
  </ul>

  <div class="text-xl text-black mt-4 mb-2"><strong>Collecting and Using Your Personal Data</strong></div>
  <div class="text-xl text-black mt-4 mb-2"><strong>Types of Data Collected</strong></div>
  <div class="text-md text-customTeal mt-4 mb-2"><strong>Personal Data </strong></div>
  <div class="ml-4 mb-4 text-md lg:text-sm">While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:
  </div>

  <ul class="list-disc ml-16 mb-4 text-md lg:text-sm">
    <li>Email address</li>
    <li>First name and last name.</li>
    <li>Phone number</li>
    <li>Address, State, Province, ZIP/Postal code, City</li>
    <li>Usage Data</li>
  </ul>

  <div class="text-md text-customTeal mt-4 mb-2"><strong>Usage Data</strong></div>
  <div class="ml-4 mb-4 text-md lg:text-sm">Usage Data is collected automatically when using the Service.
  </div>
  <div class="ml-4 mb-4 text-md lg:text-sm">Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
  </div>
  <div class="ml-4 mb-4 text-md lg:text-sm">When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.
  </div>
  <div class="ml-4 mb-4 text-md lg:text-sm">We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.
  </div>
  
  <div class="text-md text-customTeal mt-4 mb-2"><strong>Information from Third-Party Social Media Services</strong></div>
  <div class="ml-4 mb-4 text-md lg:text-sm">The Company allows You to create an account and log in to use the Service through the following Third-party Social Media Services:
  </div>
  <ul class="list-disc ml-16 mb-4 text-md lg:text-sm">
    <li>Google</li>
    <li>Facebook</li>
    <li>Twiter</li>
  </ul>

  <div class="ml-4 mb-4 text-md lg:text-sm">If You decide to register through or otherwise grant us access to a Third-Party Social Media Service, We may collect Personal data that is already associated with Your Third-Party Social Media Service's account, such as Your name, Your email address, Your activities or Your contact list associated with that account.
  </div>
  <div class="ml-4 mb-4 text-md lg:text-sm">If You decide to register through or otherwise grant us access to a Third-Party Social Media Service, We may collect Personal data that is already associated with Your Third-Party Social Media Service's account, such as Your name, Your email address, Your activities or Your contact list associated with that account.
  </div>

  <div class="text-md text-customTeal mt-4 mb-2"><strong>Information Collected while Using the Application
  </strong></div>
  <div class="ml-4 mb-4 text-md lg:text-sm">While using Our Application, in order to provide features of Our Application, We may collect, with your prior permission:
  </div>

  <ul class="list-disc ml-16 mb-4 text-md lg:text-sm">
    <li>Information regarding your location</li>
    <li>Information from your Device's phone book (contacts list)</li>
    <li>Pictures and other information from your Device's camera and photo library</li>
  </ul>

  <div class="ml-4 mb-4 text-md lg:text-sm">We use this information to provide features of Our Service, to improve and customize Our Service. The information may be uploaded to the Company's servers and/or a Service Provider's server or it be simply stored on Your device.
  </div>
  <div class="ml-4 mb-4 text-md lg:text-sm">You can enable or disable access to this information at any time, through Your Device settings.
  </div>

  <div class="text-xl text-black mt-4 mb-2"><strong>Tracking Technologies and Cookies
  </strong></div>

  <div class="ml-4 mb-4 text-md lg:text-sm">We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service.
  </div>

  <div class="ml-4 mb-4 text-md lg:text-sm">You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service.
  </div>

  <div class="ml-4 mb-4 text-md lg:text-sm">Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close your web browser. Learn more about cookies: All About Cookies.
    We use both session and persistent Cookies for the purposes set out below:
  </div>

  <div class="text-xl text-black mt-4 mb-2"><strong>Tracking Technologies and Cookies
  </strong></div>

  <div class="ml-4 mb-4 text-md lg:text-sm">Type: Session Cookies
  </div>

  <div class="ml-4 mb-4 text-md lg:text-sm">Administered by: Us
  </div>
  
  <div class="ml-4 mb-4 text-md lg:text-sm">Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.
  </div>

  <div class="text-xl text-black mt-4 mb-2"><strong>Tracking Technologies and Cookies
  </strong></div>

  <div class="ml-4 mb-4 text-md lg:text-sm">Type: Persistent Cookies
  </div>

  <div class="ml-4 mb-4 text-md lg:text-sm">Administered by: Third-Parties
  </div>

  <div class="ml-4 mb-4 text-md lg:text-sm">Purpose: These Cookies are used to track information about traffic to the Website and how users use the Website. The information gathered via these Cookies may directly or indirectly identify you as an individual visitor. This is because the information collected is typically linked to a pseudonymous identifier associated with the device you use to access the Website. We may also use these Cookies to test new advertisements, pages, features or new functionality of the Website to see how our users react to them.
  </div>

  <div class="ml-4 mb-4 text-md lg:text-sm">For more information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy.
  </div>

  <div class="text-xl text-black mt-4 mb-2"><strong>Use of Your Personal Data
  </strong></div>

  <div class="ml-4 mb-4 text-md lg:text-sm">The Company may use Personal Data for the following purposes:
  </div>

  <ul class="list-disc ml-16 mb-4 text-md lg:text-sm">
    <li>To provide and maintain our Service, including to monitor the usage of our Service.</li>
    <li>To manage Your Account: to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</li>
    <li>For the performance of a contract: the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.</li>
    <li>To contact You: To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</li>
    <li>To provide You with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.</li>
    <li>To manage Your requests: To attend and manage Your requests to Us.</li>
  </ul>

  <div class="ml-4 mb-4 text-md lg:text-sm">We may share your personal information in the following situations:
  </div>
  <ul class="list-disc ml-16 mb-4 text-md lg:text-sm">
    <li>With Service Providers: We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You.</li>
    <li>For Business transfers: We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of our business to another company.</li>
    <li>With Affiliates: We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.</li>
    <li>With Business partners: We may share Your information with Our business partners to offer You certain products, services or promotions.</li>
    <li>With other users: when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. If You interact with other users or register through a Third-Party Social Media Service, Your contacts on the Third-Party Social Media Service may see Your name, profile, pictures and description of Your activity. Similarly, other users will be able to view descriptions of Your activity, communicate with You and view Your profile.</li>
  </ul>

  <div class="text-xl text-black mt-4 mb-2"><strong>Retention of Your Personal Data
  </strong></div>

  <div class="ml-4 mb-4 text-md lg:text-sm">The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
  </div>

  <div class="ml-4 mb-4 text-md lg:text-sm">The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.
  </div>

  <div class="text-xl text-black mt-4 mb-2"><strong>Transfer of Your Personal Data
  </strong></div>

  <div class="ml-4 mb-4 text-md lg:text-sm">Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.
  </div>

  <div class="ml-4 mb-4 text-md lg:text-sm">Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.
  </div>

  <div class="ml-4 mb-4 text-md lg:text-sm">The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.
  </div>

  <div class="text-xl text-black mt-4 mb-2"><strong>Disclosure of Your Personal Data
  </strong></div>
  <div class="text-md text-black mt-4 mb-2"><strong>Business Transactions
  </strong></div>

  <div class="ml-4 mb-4 text-md lg:text-sm">If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.
  </div>

  <div class="text-md text-customTeal mt-4 mb-2"><strong>Law enforcement
  </strong></div>
  
  <div class="ml-4 mb-4 text-md lg:text-sm">Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).
  </div>

  <div class="text-md text-customTeal mt-4 mb-2"><strong>Other legal requirements
  </strong></div>

  <div class="ml-4 mb-4 text-md lg:text-sm">The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:
  </div>

  <ul class="list-disc ml-16 mb-4 text-md lg:text-sm">
    <li>Comply with a legal obligation</li>
    <li>Protect and defend the rights or property of the Company</li>
    <li>Prevent or investigate possible wrongdoing in connection with the Service.</li>
    <li>Protect the personal safety of Users of the Service or the public.</li>
    <li>Protect against legal liability.</li>
  </ul>

  <div class="text-md text-customTeal mt-4 mb-2"><strong>Security of Your Personal Data
  </strong></div>

  <div class="ml-4 mb-4 text-md lg:text-sm">The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.
  </div>

  <div class="text-xl text-black mt-4 mb-2"><strong>Detailed Information on the Processing of Your Personal Data
  </strong></div>

  <div class="ml-4 mb-4 text-md lg:text-sm">Service Providers have access to Your Personal Data only to perform their tasks on Our behalf and are obligated not to disclose or use it for any other purpose.
  </div>

  <div class="text-md text-customTeal mt-4 mb-2"><strong>Analytics
  </strong></div>

  <div class="ml-4 mb-4 text-md lg:text-sm">We may use third-party Service providers to monitor and analyze the use of our Service.
  </div>

  <div class="text-md text-customTeal mt-4 mb-2"><strong>Google Analytics
  </strong></div>

  <div class="ml-4 mb-4 text-md lg:text-sm">Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualise and personalise the ads of its own advertising network.
  </div>

  <div class="ml-4 mb-4 text-md lg:text-sm">For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: https://policies.google.com/privacy?hl=en
  </div>

  <div class="text-md text-customTeal mt-4 mb-2"><strong>Firebase
  </strong></div>

  <div class="ml-4 mb-4 text-md lg:text-sm">Firebase is an analytics service provided by Google Inc.
  </div>

  <div class="ml-4 mb-4 text-md lg:text-sm">You may opt-out of certain Firebase features through your mobile device settings, such as your device advertising settings or by following the instructions provided by Google in their Privacy Policy: https://policies.google.com/privacy?hl=en
  </div>

  <div class="ml-4 mb-4 text-md lg:text-sm">We also encourage you to review the Google's policy for safeguarding your data: https://support.google.com/analytics/answer/6004245.
  </div>

  <div class="ml-4 mb-4 text-md lg:text-sm">For more information on what type of information Firebase collects, please visit the Google Privacy & Terms web page: https://policies.google.com/privacy?hl=en
  </div>

  <div class="text-md text-black mt-4 mb-2"><strong>Email Marketing
  </strong></div>

  <div class="ml-4 mb-4 text-md lg:text-sm">We may use Your Personal Data to contact You with newsletters, marketing or promotional materials and other information that may be of interest to You. You may opt-out of receiving any, or all, of these communications from Us by following the unsubscribe link or instructions provided in any email We send or by contacting Us.
  </div>

  <div class="ml-4 mb-4 text-md lg:text-sm">We may use Email Marketing Service Providers to manage and send emails to You.
  </div>

  <div class="text-md text-customTeal mt-4 mb-2"><strong>Mailchimp
  </strong></div>

  <div class="ml-4 mb-4 text-md lg:text-sm">Mailchimp is an email marketing sending service provided by The Rocket Science Group LLC.
  </div>

  <div class="ml-4 mb-4 text-md lg:text-sm">For more information on the privacy practices of Mailchimp, please visit their Privacy policy: https://mailchimp.com/legal/privacy/
  </div>

  <div class="text-md text-customTeal mt-4 mb-2"><strong>Constant Contact
  </strong></div>

  <div class="ml-4 mb-4 text-md lg:text-sm">Constant Contact is an email marketing sending service provided by Constant Contact, Inc.
  </div>

  <div class="ml-4 mb-4 text-md lg:text-sm">For more information on the privacy practices of Constant Contact, please visit their Privacy policy: https://www.constantcontact.com/forward/privacy-center
  </div>

  <div class="text-md text-customTeal mt-4 mb-2"><strong>AWeber
  </strong></div>

  <div class="ml-4 mb-4 text-md lg:text-sm">AWeber is an email marketing sending service provided by AWeber Communications.
  </div>

  <div class="ml-4 mb-4 text-md lg:text-sm">For more information on the privacy practices of AWeber, please visit their Privacy policy: https://www.aweber.com/privacy.htm
  </div>

  <div class="text-md text-customTeal mt-4 mb-2"><strong>GetResponse
  </strong></div>

  <div class="ml-4 mb-4 text-md lg:text-sm">GetResponse is an email marketing sending service provided by GetResponse.
  </div>

  <div class="ml-4 mb-4 text-md lg:text-sm">For more information on the privacy practices of GetResponse, please visit their Privacy policy: https://www.getresponse.com/legal/privacy.html
  </div>

  <div class="text-md text-black mt-4 mb-2"><strong>Children's Privacy
</strong></div>

  <div class="ml-4 mb-4 text-md lg:text-sm">Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.
  </div>

  <div class="ml-4 mb-4 text-md lg:text-sm">If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.
  </div>

  <div class="text-md text-black mt-4 mb-2"><strong>Links to Other Websites
  </strong></div>
  
    <div class="ml-4 mb-4 text-md lg:text-sm">Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.
    </div>
  
    <div class="ml-4 mb-4 text-md lg:text-sm">Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.
    </div>
  
    <div class="text-md text-black mt-4 mb-2"><strong>Changes to this Privacy Policy
    </strong></div>
    
      <div class="ml-4 mb-4 text-md lg:text-sm">We may update our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.
      </div>
    
      <div class="ml-4 mb-4 text-md lg:text-sm">We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.
      </div>

      <div class="ml-4 mb-4 text-md lg:text-sm">You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
      </div>

      <div class="text-md text-black mt-4 mb-2"><strong>Contact Us
      </strong></div>
      
        <div class="ml-4 mb-4 text-md lg:text-sm">If you have any questions about this Privacy Policy, You can contact us:
        </div>
      
        <ul class="list-disc ml-16 mb-4 text-md lg:text-sm">
          <li>By email: info&#64;goingmobile.app</li>
          <li>By visiting this page on our website: https://goingmobile.app</li>
        </ul>

        <a (click)="backClicked()">
          <div class="px-4 py-2 mx-4 bg-customTeal w-28 text-white rounded-lg">
            <i
              class="material-icons float-left text-white">arrow_back</i> Back
          </div>
        </a>
          <br /> <br />

</div>