<div class="px-4 md:px-12 py-5 space-y-4 w-full ">
  <div *ngIf="currentUser">
    <div *ngIf="currentUser.role=='Admin'" class="flex items-center space-x-4">
      <input
      placeholder="Search to start a new chat" class="input input-bordered input-md md:w-2/5"
      type="search" autocomplete="off" [(ngModel)]="searchQuery"
      (focus)="openUserSuggestion($event.target.id)"
      (ngModelChange)="searchChat()"/>
      <button class=" flex items-center space-x-2 justify-center px-4 md:px-6 py-2 bg-customTeal text-white  rounded-lg w-max h-min text-sm md:text-base font-bold hover:bg-darkBlue focus:outline-none ">
        <i class="fa fa-paper-plane text-white "></i>
        <span>Go </span>
      </button>
    </div>
  </div>

  <div class="flex flex-col space-y-4 md:flex-row w-full md:space-x-6 md:space-y-0 h-max md:min-h-screen md:max-h-max ">
    <div class="flex flex-col  w-full space-y-4 md:space-y-0 md:flex-row md:gap-x-4 ">
      <!-- left chat list -->
      <div class="bg-white p-8 space-y-8 md:w-4/12 md:min-w-max rounded-md max-h-screen min-h-screen">
        <div class="space-y-10 ">
          <div class="flex space-x-6 items-center ">
            <button class="py-1 px-3 border border-customTeal  rounded-full font-bold text-customTeal ">
              Private Chats
            </button>
            <button class="text-sm">
              Groups
            </button>
          </div>
          <!-- chat list -->
          <div class="flex space-y-4 ">
              <div *ngIf="search" class="flex flex-col w-full space-y-4 ">
                <!-- chat item -->
                <div  *ngFor="let chat of searchResult" >
                  <!-- Personal chat -->
                  <div *ngIf="chat.name" class="flex space-x-4 "  (click)="selectedFromSearch(chat)" >
                    <div class="avatar ">
                      <div class="w-14 rounded-full ">
                          <img loading="lazy" [src]="chat.profileImgLink || 'https://api.lorem.space/image/face?hash=92310'" />
                      </div>
                    </div>
                    <div class="flex flex-col space-y-1 ">
                        <p class="text-bold ">{{chat.GivenName}} {{chat.LastName}}</p>
                        <!-- <p class="text-xs text-darkGray ">Date</p> -->
                    </div>
                  </div>

                  <!-- Groups -->
                  <div *ngIf="!chat.name" class="flex space-x-4 " (click)="selectedGroup(chat)">
                    <div class="avatar ">
                      <div class="w-14 rounded-full ">
                          <img loading="lazy" [src]="chat.profileImgLink || 'https://api.lorem.space/image/face?hash=92310'" />
                      </div>
                    </div>
                    <div class="flex flex-col space-y-1 ">
                        <p class="text-bold ">{{chat.GivenName}} {{chat.LastName}}</p>
                        <!-- <p class="text-xs text-darkGray ">Date</p> -->
                    </div>
                  </div>


                  <hr class="w-full ">
                </div>

              </div>
              <div *ngIf="!search" class="flex space-y-4 " >

                <div *ngIf="!loadingChats && privateChats.length > 0"  class="flex flex-col w-full space-y-4 ">
                  <div *ngFor="let chat of privateChats">
                    <!-- each private chats -->
                    <div class="flex space-x-4" (click)="selectPrivateChat(chat)"
                      [ngClass]="selectedConver?.id == chat.id ? 'background-grey' : '' ">

                        <div class="avatar">
                          <div class="w-14 rounded-full">
                            <img loading="lazy" alt="User Profile Photo" [src]="chat.user_2.profileImgLink || imgLink">
                          </div>
                        </div>
                        <div class="flex flex-col space-y-1">
                          <div class="text-bold">{{chat.user_2.GivenName || chat.user_2.FirstName}}
                            {{chat.user_2.LastName}}</div>
                          <!-- <div class="text-xs text-darkGray" *ngIf="(chat.lastMessage | json)  !== '{}'">
                            {{chat.lastMessage.message}}</div> -->
                            <div class="text-xs text-darkGray" *ngIf="(chat.lastMessage | json)  !== '{}'">
                              <div
                                *ngIf="(chat.lastMessage.timeSent?.toDate() | date: 'dd MMMM yyyy') !== (today | date: 'dd MMMM yyyy') ">
                                {{chat.lastMessage.timeSent?.toDate() | date: 'mediumDate'}}</div>
                              <div
                                *ngIf="(chat.lastMessage.timeSent?.toDate() | date: 'dd MMMM yyyy') == (today | date: 'dd MMMM yyyy') ">
                                {{chat.lastMessage.timeSent?.toDate() | date: 'shortTime'}}</div>
                            </div>
                        </div>
                        <div class="">
                          <div *ngIf="chat.user_1.unreadCount"><span class="badge border-none text-white bg-customTeal"
                              data-badge-caption=" ">{{chat.user_1.unreadCount}}</span></div>
                        </div>
                    </div>
                  </div>
                </div>

                <div *ngIf="!loadingChats && privateChats.length < 1">
                  <p class="no-conversations">Your private chats will appear here.</p>
                </div>

                <div *ngIf="loadingChats">
                  <p class="no-conversations">Loading Chats...</p>
                </div>

              </div>
          </div>

        </div>
      </div>
      <!-- Message displaying and sending area -->
      <div class=" flex flex-col md:w-2/3 max-h-screen min-h-screen">
          <!-- chat page -->
          <!-- if chat is selected -->
          <div *ngIf="chatSelected && selectedConver" class="bg-white w-full px-4 md:px-16 py-8 h-4/6 min-h-max  md:overflow-y-scroll space-y-6 rounded-md ">
            <div class="row" style="margin-bottom: 0;" *ngIf="!group && isPrivate">

              <div class="flex space-x-4">
                <div class="back-arrow col s1 hide-on-large-only	" (click)="backClicked()">
                  <span class="material-icons">
                    arrow_back_ios_new
                  </span>
                </div>
                <div class="avatar">
                  <div class="w-14 rounded-full">
                    <img loading="lazy" class="avatar" alt="User Profile Photo" [src]="selectedConver.user_2.profileImgLink || imgLink">
                  </div>
                </div>
                <div class="">
                  <div class="text-bold" >{{selectedConver.user_2.GivenName ||
                    selectedConver.user_2.FirstName}}
                    {{selectedConver.user_2.LastName}}</div>
                </div>
                <div class="dropdown dropdown-end">
                  <label tabindex="0" class="m-1"><i class="material-icons">more_vert</i></label>
                  <ul id='private-chat-options' tabindex="0" class="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
                    <li><a>Delete Chat</a></li>
                  </ul>
                </div>
              </div>

            </div>

            <!-- chat history container for private chat -->
            <div id="scrollMe" class="chat-container peer row" *ngIf="!group">
              <div *ngIf="loadingMessages">
                <p class="no-messages">Loading Message History ...</p>

              </div>
              <div *ngIf="!loadingMessages && messages.length < 1">
                <p class="no-messages text-center center">No Messages Here Yet...</p>
              </div>
              <div *ngIf="!loadingMessages && messages.length > 0">
                <div class="message-history" *ngFor="let message of messages; index as i">
                  <div class="row message-date"
                    *ngIf="i == 0 || (message.timeStamp.toDate() | date: 'dd MMMM yyyy') !== (messages[i-1]?.timeStamp.toDate() | date: 'dd MMMM yyyy')">
                    <div class="col-lg-12">
                      <div class="date text-center  text-sm">
                        <span class="bg-gray-100 px-3 rounded-full">{{ message.timeStamp.toDate() | date: "EEEE, MMMM d, y" }}</span>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="
                        message.fromId == selectedConver.user_2.id;
                        then incoming_msg;
                        else outgoing_msg
                      "></div>
                  <ng-template #outgoing_msg>
                    <div class="flex gap-x-4 m-1" *ngIf="!message.attachment.type || message.attachment.type == 'none'">

                      <div class="avatar ">
                        <div class="w-6 h-6 md:w-14 md:h-14 rounded-full ">
                            <img loading="lazy" data-src="https://api.lorem.space/image/face?hash=92310 " />
                        </div>
                    </div>
                      <div class="bg-customTeal text-white p-4 rounded-md w-max" #wrapper (contextmenu)="onRightClick($event, message)">
                        <ng-container *ngTemplateOutlet="normalMessageWrap; context: {message: message}"></ng-container>

                        <div class="block text-xs text-veryLightGray">
                          <div *ngIf="message.edited" style="padding-right: 8px;">edited</div>
                          <div>
                            {{ message.timeStamp.toDate() | date:'shortTime' }}
                          </div>
                        </div>
                      </div>
                      <div class="flex flex-col justify-between">
                        <div>
                        </div>
                        <div  class="likeMessage text-customTeal align-bottom -translate-x-4" (click)="likeMessage(message.id)">
                          <div *ngIf="!message.likes || !message.likes.count || !message.likes.count > 0; then emptyLikes; else liked">
                          </div>
                          <ng-template #liked><span class="material-icons color-red">
                              favorite
                            </span><span class="likes-count" *ngIf="group">{{message.likes.count}}</span></ng-template>
                          <ng-template #emptyLikes><span class="material-icons">
                              favorite_border
                            </span></ng-template>

                        </div>
                      </div>
                      <!-- <div class="message-more-option" (click)="onRightClick($event, message)">
                        <span class="material-icons" style="font-size: 18px;">
                          more_vert
                        </span>
                      </div> -->
                    </div>
                    <!-- if message is media - Video or PDF -->
                    <div class="message-wrapper me"
                      *ngIf="message.attachment?.type == 'video' || message.attachment?.type == 'pdf'">
                      <!-- <img class="avatar ng-star-inserted" [src]="message.profileImgLink || imgLink"> -->
                      <div class="wrapper media file" (contextmenu)="onRightClick($event, message)">
                        <ng-container *ngTemplateOutlet="videoAndFileMessageWrap; context: {message: message}">
                        </ng-container>

                      </div>
                      <div class="date">{{ message.timeStamp.toDate() | date:'shortTime' }}</div>
                    </div>
                    <!-- if message is media - image -->
                    <div class="message-wrapper me" *ngIf="message.attachment?.type == 'image'">
                      <div class="wrapper media " (contextmenu)="onRightClick($event, message)">
                        <ng-container *ngTemplateOutlet="imageMessageWrap; context: {message: message}"></ng-container>

                      </div>
                      <div class="date">{{ message.timeStamp.toDate() | date:'shortTime' }}</div>
                    </div>
                  </ng-template>
                  <ng-template #incoming_msg>
                    <div class="flex flex-row-reverse gap-x-4 m-1"
                      *ngIf="!message.attachment.type || message.attachment.type == 'none'">
                      <div class="avatar ">
                        <div class="w-6 h-6 md:w-14 md:h-14 rounded-full ">
                            <img loading="lazy" data-src="https://api.lorem.space/image/face?hash=92310 " />
                        </div>
                    </div>
                      <div class=" bg-veryLightGray p-4 rounded-md w-max" (contextmenu)="onRightClick($event, message)">
                        <ng-container *ngTemplateOutlet="normalMessageWrap; context: {message: message}"></ng-container>

                        <div class="block text-xs text-darkGray">
                          {{ message.timeStamp.toDate() | date:'shortTime' }}
                          <div *ngIf="message.edited" style="display: inline;padding-right: 8px;">edited</div>

                        </div>
                      </div>

                      <div class="flex flex-col justify-between">
                        <div></div>
                        <div  class="flex-end likeMessage text-customTeal translate-x-4" (click)="likeMessage(message.id)">
                          <div *ngIf="!message.likes || !message.likes.count || !message.likes.count > 0; then emptyLikes; else liked">
                          </div>
                          <ng-template #liked><span class="material-icons color-red">
                              favorite
                            </span><span class="likes-count" *ngIf="group">{{message.likes.count}}</span></ng-template>
                          <ng-template #emptyLikes><span class="material-icons">
                              favorite_border
                            </span></ng-template>

                        </div>
                      </div>
                    </div>
                    <!-- if message is media - Video or PDF -->
                    <div class="message-wrapper contact"
                      *ngIf="message.attachment?.type == 'video' || message.attachment?.type == 'pdf'">
                      <!-- <img class="avatar ng-star-inserted" [src]="message.profileImgLink || imgLink"> -->
                      <div class="wrapper media file" (contextmenu)="onRightClick($event, message)">
                        <ng-container *ngTemplateOutlet="videoAndFileMessageWrap; context: {message: message}">
                        </ng-container>

                      </div>
                      <div class="date">{{ message.timeStamp.toDate() | date:'shortTime' }}</div>
                    </div>
                    <div class="message-wrapper contact" *ngIf="message.attachment?.type == 'image'">
                      <div class="wrapper media " (contextmenu)="onRightClick($event, message)">
                        <ng-container *ngTemplateOutlet="imageMessageWrap; context: {message: message}"></ng-container>

                      </div>
                      <div class="date">{{ message.timeStamp.toDate() | date:'shortTime' }}</div>
                    </div>
                  </ng-template>

                </div>
              </div>

            </div>

            <!-- when group chat is selected - chat area top header-->
            <div class="row" style="margin-bottom: 0;" *ngIf="group && selectedConver">
              <div class="back-arrow col s1 hide-on-large-only	" (click)="backClicked()">
                <span class="material-icons">
                  arrow_back_ios_new
                </span>
              </div>
              <div class="profile-pic col s2 l1">

                <img loading="lazy" class="avatar" alt="Group Profile Photo" [src]="selectedConver.profilePicture || groupProfileImg">
              </div>
              <div class="chat-details col s7 l10">
                <div class="">{{selectedConver.name}}</div>
                <span class="user-count btn-flat modal-trigger" href="#circleDetails">{{selectedConver.users.length}}
                  users</span>
              </div>
              <div class="chat-details  btn-flat col s2 l1 dropdown-trigger " href='#' data-target='dropdown1'>
                <span class="material-icons">
                  more_vert
                </span>
              </div>
              <!-- options for group chats -->
              <ul id='dropdown1' class='dropdown-content'>
                <li *ngIf="isMember(selectedConver, id)"><a class="waves-effect waves-light btn-flat modal-trigger"
                    href="#addMember">Add Members</a></li>
                <li><a class="waves-effect waves-light btn-flat modal-trigger" href="#circleDetails">Circle Details</a>
                </li>
                <li *ngIf="selectedConver.admin == id"><a class="waves-effect waves-light btn-flat modal-trigger"
                    href="#editCircle">Edit Circle Info</a></li>
                <li *ngIf="selectedConver.admin == id"><a class="waves-effect waves-light btn-flat modal-trigger"
                    href="#deleteCircle">Delete Circle</a></li>
                <!-- (click)="leaveCircle(id)" -->
                <li *ngIf="selectedConver.admin != id && isMember(selectedConver, id)"><a
                    class="waves-effect waves-light btn-flat modal-trigger" href="#leaveCircle">Leave Circle</a></li>
              </ul>
            </div>



            <!-- Chat history container for group chats -->
            <div class="chat-container group row" *ngIf="group" id="scrollMe">
              <div *ngIf="loadingMessages">
                <p class="no-messages">Loading Message History ...</p>

              </div>
              <div *ngIf="!loadingMessages && messages.length < 1">
                <p class="no-messages">No Messages Here Yet.</p>
              </div>
              <div *ngIf="!loadingMessages && messages.length > 0">
                <div class="message-history" *ngFor="let message of messages; index as i">
                  <div class="row message-date"
                    *ngIf="i == 0 || (message.timeStamp.toDate() | date: 'dd MMMM yyyy') !== (messages[i-1]?.timeStamp.toDate() | date: 'dd MMMM yyyy')">
                    <div class="col s12">
                      <div class="date text-center" style="margin-top: 0.6rem;">
                        {{ message.timeStamp.toDate() | date: "EEEE, MMMM d, y" }}
                      </div>
                    </div>
                  </div>
                  <div class="row message-flag center" *ngIf="message.attachment?.type == 'flag'">
                    <div class="col s12">
                      <div class="flag text-center" style="margin-top: 0.6rem;">
                        {{ message.message }}
                      </div>
                    </div>
                  </div>
                  <div *ngIf="
                        message.fromId == id
                        then outgoing_msg;
                        else incoming_msg
                      "></div>
                  <ng-template #outgoing_msg>
                    <div class="message-wrapper me" *ngIf="!message.attachment.type || message.attachment.type == 'none'">
                      <img loading="lazy" class="avatar ng-star-inserted" [src]="message.profileImgLink || imgLink">
                      <div class="wrapper " (contextmenu)="onRightClick($event, message)">
                        <ng-container *ngTemplateOutlet="normalMessageWrap; context: {message: message}"></ng-container>
                      </div>
                      <div class="date">
                        <div *ngIf="message.edited" style="padding-right: 8px;">edited</div>
                        {{ message.timeStamp.toDate() | date:'shortTime' }}
                      </div>

                    </div>
                    <!-- if message is media - image -->
                    <div class="message-wrapper me" *ngIf="message.attachment?.type == 'image'">
                      <img loading="lazy" class="avatar ng-star-inserted" [src]="message.profileImgLink || imgLink">
                      <div class="wrapper media img" (contextmenu)="onRightClick($event, message)">
                        <ng-container *ngTemplateOutlet="imageMessageWrap; context: {message: message}"></ng-container>

                      </div>
                      <div class="date">{{ message.timeStamp.toDate() | date:'shortTime' }}</div>

                    </div>
                    <!-- if message is media - Video or PDF -->
                    <div class="message-wrapper me"
                      *ngIf="message.attachment?.type == 'video' || message.attachment?.type == 'pdf'">
                      <img loading="lazy" class="avatar ng-star-inserted" [src]="message.profileImgLink || imgLink">
                      <div class="wrapper media file" (contextmenu)="onRightClick($event, message)">
                        <ng-container *ngTemplateOutlet="videoAndFileMessageWrap; context: {message: message}">
                        </ng-container>
                      </div>
                      <div class="date">{{ message.timeStamp.toDate() | date:'shortTime' }} </div>

                    </div>
                  </ng-template>
                  <ng-template #incoming_msg>
                    <div class="message-wrapper contact"
                      *ngIf="!message.attachment.type || message.attachment.type == 'none'">
                      <img loading="lazy" class="avatar ng-star-inserted" [src]="message.profileImgLink || imgLink">
                      <div class="wrapper " (contextmenu)="onRightClick($event, message)">
                        <div class="name user-color-{{message.userIndex}}">{{message.GivenName || message.FirstName}}
                          {{message.LastName}}</div>
                        <ng-container *ngTemplateOutlet="normalMessageWrap; context: {message: message}"></ng-container>
                      </div>
                      <div class="date">
                        {{ message.timeStamp.toDate() | date:'shortTime' }}
                        <div *ngIf="message.edited" style="display: inline;padding-right: 8px;">edited</div>
                      </div>

                    </div>
                    <div class="message-wrapper contact" *ngIf="message.attachment?.type == 'image'">
                      <img loading="lazy" class="avatar ng-star-inserted" [src]="message.profileImgLink || imgLink">
                      <div class="wrapper media img" (contextmenu)="onRightClick($event, message)">
                        <div class="name user-color-{{message.userIndex}}">{{message.GivenName || message.FirstName}}
                          {{message.LastName}}</div>
                          <ng-container *ngTemplateOutlet="imageMessageWrap; context: {message: message}"></ng-container>

                      </div>
                      <div class="date">{{ message.timeStamp.toDate() | date:'shortTime' }}</div>

                    </div>
                    <!-- if message is media - Video or PDF -->
                    <div class="message-wrapper contact"
                      *ngIf="message.attachment?.type == 'video' || message.attachment?.type == 'pdf'">
                      <img loading="lazy" class="avatar ng-star-inserted" [src]="message.profileImgLink || imgLink">
                      <div class="wrapper media file" (contextmenu)="onRightClick($event, message)">
                        <div class="name user-color-{{message.userIndex}}">{{message.GivenName || message.FirstName}}
                          {{message.LastName}}</div>
                        <ng-container *ngTemplateOutlet="videoAndFileMessageWrap; context: {message: message}">
                        </ng-container>

                      </div>
                      <div class="date">{{ message.timeStamp.toDate() | date:'shortTime' }} </div>
                    </div>
                  </ng-template>

                </div>
              </div>
            </div>


          </div>
          <!-- chat page not selected -->
          <div class="items-center " *ngIf="!chatSelected" class="bg-white">
            <div class="text-center lg:mt-28">
              <div class="">
                <span class="p-10 bg-gray-100 rounded-full object-contain material-icons text-7xl text-customTeal">
                  message
                </span>
              </div>
            </div>
            <div class="text-center lg:mb-20">
              <h5 class="text-center text-2xl">Chats</h5>
              <span class="text-center text-xl">Select a chat to start messaging!</span>
            </div>
          </div>

          <!-- Message text area and send -->
          <div *ngIf="chatSelected" >
            <div  *ngIf="!group || (group && isMember(selectedConver, id))">
              <div class="flex bottom-0 w-full">
                <form  [formGroup]="messageForm" (ngSubmit)="send(messageForm.value)" class=" flex   space-x-4  bg-white   items-center w-full p-3  ">
                    <button (click)="attachFile.click()" type="button" class=" flex items-center space-x-2 justify-center px-4 border py-4 bg-veryLightGray text-white rounded-lg w-max h-min text-sm md:text-base font-bold hover:bg-darkBlue focus:outline-none ">
                      <i class="fa fa-paperclip text-black "></i>

                    </button>
                    <input #attachFile id="attachFile" name="attachFile" onclick="value = null"
                    (change)="onSelectFile($event.target.files)" formControlName="attachFile" type="file" multiple
                    hidden />
                    <input type="text " placeholder="Write a message " class="input input-bordered input-md w-10/12  " formControlName="message"/>
                    <button *ngIf="!editMessage" class=" flex items-center space-x-2 justify-center px-4 md:px-6 py-2 bg-customTeal text-white rounded-lg w-max h-min text-sm md:text-base font-bold hover:bg-darkBlue focus:outline-none ">
                      <i class="fa fa-paper-plane text-white "></i>
                      <span>send </span>
                    </button>
                    <button *ngIf="editMessage && (messageForm.value.message == selectedMessage.message)"
                      class=" flex items-center space-x-2 justify-center px-4 md:px-6 py-2 bg-customTeal text-white rounded-lg w-max h-min text-sm md:text-base font-bold hover:bg-darkBlue focus:outline-none ">
                      <i class="fa fa-paper-plane text-white "></i>
                      <span>send </span>
                    </button>
                    <button *ngIf="editMessage && !(messageForm.value.message == selectedMessage.message)"

                      class=" flex items-center space-x-2 justify-center px-4 md:px-6 py-2 bg-customTeal text-white rounded-lg w-max h-min text-sm md:text-base font-bold hover:bg-darkBlue focus:outline-none ">
                      <i class="fa fa-paper-plane text-white "></i>
                      <span>send </span>
                    </button>
                </form>
              </div>
              <div *ngIf="group && !isMember(selectedConver, id)">
                <div class="waves-effect btn-flat joinCircleBtn" (click)="joinGroup()">
                  Join Circle
                </div>
              </div>

            </div>
          </div>


      </div>
    </div>
  </div>
</div>















































<div class="section hide-on-med-and-down"></div>

<div class="row col s12 hidden">
  <div class="col l1 s0 hide-on-med-and-down" *ngIf="currentUser && !isAdmin"></div>
  <div class="col s12">


    <div class="row card-panel white" style="padding: 0;">

      <!-- left column - chat list and search -->
      <div class="col l4 s12 left-col-list" style=" height: 100%;">

        <div class="row">
          <br />
          <div class="profile-pic col s2">
            <img loading="lazy" class="avatar" *ngIf="currentUser" alt="User Profile Picture"
              [src]="currentUser.profileImgLink || imgLink">
          </div>
          <div class="col s10" style="text-align: right; padding-right: 0;">
            {{currentUser.GivenName}} {{currentUser.LastName}}
            <!-- <button class="posts-for-large btn-flat hide-on-med-and-down" style="color: #12A3E0;margin-right: 2%;"
              (click)="myPosts()" *ngIf="!isAdmin">
              Posts
            </button> -->
            <div class="chat-details  btn-flat hide-on-large-only">
              <span class="material-icons color-blue">
                more_vert
              </span>
            </div>
          </div>
          <!-- <div class="col s2">

          </div> -->
        </div>

        <!-- search bar -->
        <div class="row search-bar" *ngIf="isAdmin">
          <div class="input-field col s12">
            <i class="material-icons prefix color-blue">search</i>
            <input
              type="search"
              id="search"
              placeholder="search to start a new chat" class="autocomplete"
              autocomplete="off"
              [(ngModel)]="searchQuery"
              (focus)="openUserSuggestion($event.target.id)"
              (ngModelChange)="searchChat()">
          </div>

        </div>
        <br />

        <div class="row left-list">
          <!-- tabs -->
          <div class="col s12" *ngIf="!search" style="position: sticky; top: 0;">
            <ul class="tabs" *ngIf="isAdmin">
              <li [ngClass]="scrWidth < 992 ? 'tab col s4' : 'tab col s6' "><a class="active" href="#chats">Chats</a>
              </li>
              <li [ngClass]="scrWidth < 992 ? 'tab col s4' : 'tab col s6' "><a href="#groups">Groups</a></li>

            </ul>
            <!-- <ul class="tabs" *ngIf="isAdmin">
              <li class="tab col s12"><a class="active" href="#chats">Chats</a></li>
            </ul> -->
          </div>

          <!-- private chats list -->
          <div id="chats" class="col s12">
            <div class="chats" *ngIf="!search">

              <div *ngIf="!loadingChats && privateChats.length > 0">
                <div *ngFor="let chat of privateChats">
                  <!-- each private chats -->
                  <div class="each-chat btn-flat" (click)="selectPrivateChat(chat)"
                    [ngClass]="selectedConver?.id == chat.id ? 'background-grey' : '' ">
                    <div class="one-chat-btn">
                      <div class="profile-pic col s2">
                        <img loading="lazy" class="avatar" alt="User Profile Photo" [src]="chat.user_2.profileImgLink || imgLink">
                      </div>
                      <div class="chat-details col s6">
                        <div class="contact-name">{{chat.user_2.GivenName || chat.user_2.FirstName}}
                          {{chat.user_2.LastName}}</div>
                        <div class="contact-last-text" *ngIf="(chat.lastMessage | json)  !== '{}'">
                          {{chat.lastMessage.message}}</div>
                      </div>
                      <div class="chat-details right-detail col s4">

                        <div class="last-text-date" *ngIf="(chat.lastMessage | json)  !== '{}'">
                          <div
                            *ngIf="(chat.lastMessage.timeSent?.toDate() | date: 'dd MMMM yyyy') !== (today | date: 'dd MMMM yyyy') ">
                            {{chat.lastMessage.timeSent?.toDate() | date: 'mediumDate'}}</div>
                          <div
                            *ngIf="(chat.lastMessage.timeSent?.toDate() | date: 'dd MMMM yyyy') == (today | date: 'dd MMMM yyyy') ">
                            {{chat.lastMessage.timeSent?.toDate() | date: 'shortTime'}}</div>
                        </div>
                        <div *ngIf="chat.user_1.unreadCount"><span class="new badge unread-count backg-color-blue"
                            data-badge-caption=" ">{{chat.user_1.unreadCount}}</span></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="!loadingChats && privateChats.length < 1">
                <p class="no-conversations">Your private chats will appear here.</p>
              </div>

              <div *ngIf="loadingChats">
                <p class="no-conversations">Loading Chats...</p>
              </div>

            </div>
          </div>

          <!-- Group chats List -->
          <div id="groups" class="col s12">
            <div class="groups" *ngIf="!search">
              <div class="col s12">
                <!-- <div class="row chat-list-header">
                  <div class="col s10" style="padding:0;"></div>
                  Add New Group
                  <div class="col s1" class="btn-flat" style="height: inherit;
                                line-height: inherit;
                                padding: 0;"><span class="material-icons modal-trigger"
                      data-target="addGroup" style="margin-top:0;    color: #12c78b;">
                      add_circle
                    </span></div>
                </div> -->
              </div>

              <div *ngIf="!loadingGroupChats && groupChats.length > 0">
                <div *ngFor="let group of groupChats">
                  <!-- each group chat -->
                  <div class="each-chat btn-flat" (click)="selectedGroup(group)"
                    [ngClass]="selectedConver?.id == group.id ? 'background-grey' : '' ">
                    <div class="one-chat-btn">
                      <div class="profile-pic col s2">
                        <img loading="lazy" class="avatar" alt="User Profile Photo" [src]="group.profilePicture || groupProfileImg">
                      </div>
                      <div class="chat-details col s6">
                        <div class="contact-name">{{group.name}}</div>
                        <div class="contact-last-text" *ngIf="(group.lastMessage | json)  !== '{}'">
                          {{group.lastMessage.message}}</div>

                      </div>
                      <div class="chat-details right-detail col s4">

                        <div class="last-text-date" *ngIf="(group.lastMessage | json)  !== '{}'">
                          <div
                            *ngIf="(group.lastMessage.timeSent?.toDate() | date: 'dd MMMM yyyy') !== (today | date: 'dd MMMM yyyy') ">
                            {{group.lastMessage.timeSent?.toDate() | date: 'mediumDate'}}</div>
                          <div
                            *ngIf="(group.lastMessage.timeSent?.toDate() | date: 'dd MMMM yyyy') == (today | date: 'dd MMMM yyyy') ">
                            {{group.lastMessage.timeSent?.toDate() | date: 'shortTime'}}</div>
                        </div>
                        <div *ngIf="group.unreadCount"><span class="new badge unread-count backg-color-blue"
                            data-badge-caption=" ">{{group.unreadCount}}</span></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="!loadingGroupChats && groupChats.length < 1">
                <p class="no-conversations">Chat Circles allow you to group chat with more than one member at a time. Your Chat Circles will appear here.</p>
              </div>

              <div *ngIf="loadingGroupChats">
                <p class="no-conversations">Loading Groups...</p>
              </div>

              <!-- src="assets/img/group_1.webp" src="assets/img/groups.webp" -->
              <!-- Add New Group -->
              <div class="btn-flat add-group-btn" *ngIf="isAdmin">
                <span class="material-icons color-blue modal-trigger" data-target="addGroup"
                  style="background: white;
                  z-index: 1000; margin-top:0; font-size: 2rem;border-radius: 80%;
                  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%); ">
                  add_circle
                </span>
              </div>
            </div>
          </div>


          <div id="nationWall" class="col s12" *ngIf="!isAdmin">
            <div class="hide-on-large-only">
              <!-- <app-posts-display></app-posts-display> -->
              <p class="no-conversations">Your Activities on posts will appear here.</p>
            </div>
          </div>

          <!-- Add Group Modal -->
          <div id="addGroup" class="modal">
            <form [formGroup]="newCircleForm" (ngSubmit)="createNewCircle(newCircleForm.value)">
              <div class="modal-content modal-header">
                <h6 class="center-align">Create New Group</h6>
              </div>
              <div class="modal-content">

                <div class="input-field class col s2">

                  <div class="row center" style="margin-bottom: 0;">
                    <img loading="lazy" class="profile-picture responsive-img materialboxed " data-src="{{groupProfileImg}}">

                  </div>
                  <div class="row center" style="padding-top: 8%;">
                    <button #file class="btn-floating btn-small  blue tooltipped" data-position="top" type="button"
                      data-tooltip="Upload Photo" (click)="myProfilePicInput.click()"><i class="material-icons"
                        style="    margin-top: 0.1rem; display: block;">camera_alt</i></button>
                    <div style="display: inline-block">
                      <input #myProfilePicInput name="file" (change)="onSelectFiles($event.target.files, 'newGroup')"
                        type="file" hidden />
                    </div>
                  </div>
                  <!-- <button #file class="btn-floating btn-large  blue" type="button" (click)="myInput.click()"><span
                      class="material-icons blue" style="    margin-top: 0;
                    vertical-align: middle;
                    font-size: 1.9rem;
                    margin-bottom: 0.2rem;">
                      add_a_photo
                    </span></button>
                  <div style="display: inline-block">
                    <input #myInput id="file" name="file" (change)="onSelectFiles($event.target.files)" type="file"
                      hidden />
                  </div> -->
                </div>

                <div class="input-field class col s8 offset-s1 m9 offset-m0">
                  <input formControlName="circleName" id="circleName" type="text" maxlength="75" autocomplete="off"
                    required class="validate">
                  <label for="circleName"> Group Name : <strong class="red-text">*</strong> </label>
                  <span class="helper-text" data-error="Group Name is required." data-success=""></span>

                </div>

                <div class="row col s12" style="margin-left: 15px;">
                  <div>
                    <span class="material-icons icon">security </span>
                    <strong><span style="padding-left: 1.1rem;">Private Circle </span></strong><strong class="red-text">
                      *</strong>
                  </div><br />
                  <div class="col s6" style="padding-left: 2.9rem;">
                    <label>
                      <input type="radio" [value]="true" name="isPrivateCircle" formControlName="isPrivateCircle"
                        class="backg-color-blue" />
                      <span><strong class="color-blue">Yes</strong></span>
                    </label>
                  </div>
                  <div class="col s6">
                    <label>
                      <input type="radio" [value]="false" name="isPrivateCircle" formControlName="isPrivateCircle"
                        class="backg-color-blue" checked />
                      <span><strong class="blue-text">No</strong></span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="modal-footer">
                <div class="row">
                  <div class="col s6 m3 offset-m5">
                    <button class="modal-close grey btn" type="button" style="margin-top: 2.5rem;">
                      Cancel</button>
                  </div>
                  <div class="col s6 m3">
                    <button [disabled]="!newCircleForm.valid" type="submit"
                      class="modal-close btn waves-effect backg-color-blue"
                      style="margin-right: 15px; margin-top: 2.5rem;">
                      Create Circle
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <!-- end of modal -->

          <!-- When searching for contact/Chat -->
          <div class="contacts" style="min-height: 340px;" *ngIf="search">
            <div class="chat-list-header"></div>
            <div *ngIf="searchResult.length > 0">
              <div *ngFor="let chat of searchResult">
                <div *ngIf="!chat.name" class="each-chat btn-flat" (click)="selectedFromSearch(chat)">
                  <div class="one-chat-btn">
                    <div class="profile-pic col s2">
                      <img loading="lazy" class="avatar" alt="User Profile Photo" [src]="chat.profileImgLink || imgLink">
                    </div>
                    <div class="chat-details col s6">
                      <div class="contact-name">{{chat.GivenName || chat.FirstName}} {{chat.LastName}}</div>

                    </div>
                  </div>
                </div>
                <div *ngIf="chat.name" class="each-chat btn-flat" (click)="selectedGroup(chat)">
                  <div class="one-chat-btn">
                    <div class="profile-pic col s2">
                      <img loading="lazy" class="avatar" alt="Profile Photo" [src]="chat.profilePicture || imgLink">
                    </div>
                    <div class="chat-details col s6">
                      <div class="contact-name">{{chat.name}}</div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <!-- Right column - messaging area -->
      <div class="col s12 l8 for-large right-col-list" style=" min-height: inherit; box-shadow: none;">

        <!-- If no chat is selected -->
        <div class="chat-start " *ngIf="!chatSelected" style="margin-top: 6.5rem;">
          <div class="row big-circle">
            <span class="material-icons">
              message
            </span>
          </div>
          <div class="row">
            <h5 class="app-title">Chats</h5>
            <span>Select a chat to start messaging!</span>
          </div>
        </div>
        <!-- <div class="posts-container " *ngIf="!chatSelected && !isAdmin">
          <app-posts-display></app-posts-display>
        </div> -->


        <!-- If a private chat is selected -->
        <div *ngIf="chatSelected && selectedConver" class="chat-selected ">
          <div class="row" style="margin-bottom: 0;" *ngIf="!group">
            <div class="back-arrow col s1 hide-on-large-only	" (click)="backClicked()">
              <span class="material-icons">
                arrow_back_ios_new
              </span>
            </div>
            <div class="profile-pic col s2 l1">

              <img loading="lazy" class="avatar" alt="User Profile Photo" [src]="selectedConver.user_2.profileImgLink || imgLink">
            </div>
            <div class="chat-details col s7 l10">
              <div class="" style="margin-top: 0.4rem;">{{selectedConver.user_2.GivenName ||
                selectedConver.user_2.FirstName}}
                {{selectedConver.user_2.LastName}}</div>
            </div>
            <div class="chat-details  btn-flat col s2 l1 dropdown-trigger " href='#' data-target='private-chat-options'>
              <span class="material-icons">
                more_vert
              </span>
            </div>
            <!-- options for private chats -->
            <ul id='private-chat-options' class='dropdown-content'>
              <li><a class="waves-effect waves-light btn-flat">Delete Chat</a></li>
            </ul>
          </div>

          <!-- chat history container for private chat -->
          <div id="scrollMe" class="chat-container peer row" *ngIf="!group">
            <div *ngIf="loadingMessages">
              <p class="no-messages">Loading Message History ...</p>

            </div>
            <div *ngIf="!loadingMessages && messages.length < 1">
              <p class="no-messages text-center center">No Messages Here Yet...</p>
            </div>
            <div *ngIf="!loadingMessages && messages.length > 0">
              <div class="message-history" *ngFor="let message of messages; index as i">
                <div class="row message-date"
                  *ngIf="i == 0 || (message.timeStamp.toDate() | date: 'dd MMMM yyyy') !== (messages[i-1]?.timeStamp.toDate() | date: 'dd MMMM yyyy')">
                  <div class="col-lg-12">
                    <div class="date text-center">
                      {{ message.timeStamp.toDate() | date: "EEEE, MMMM d, y" }}
                    </div>
                  </div>
                </div>
                <div *ngIf="
                      message.fromId == selectedConver.user_2.id;
                      then incoming_msg;
                      else outgoing_msg
                    "></div>
                <ng-template #outgoing_msg>
                  <div class="message-wrapper me" *ngIf="!message.attachment.type || message.attachment.type == 'none'">


                    <div class="wrapper " #wrapper (contextmenu)="onRightClick($event, message)">
                      <ng-container *ngTemplateOutlet="normalMessageWrap; context: {message: message}"></ng-container>
                    </div>
                    <div class="message-more-option" (click)="onRightClick($event, message)">
                      <span class="material-icons" style="font-size: 18px;">
                        more_vert
                      </span>
                    </div>
                    <div class="date">
                      <div *ngIf="message.edited" style="padding-right: 8px;">edited</div>
                      <div>
                        {{ message.timeStamp.toDate() | date:'shortTime' }}
                      </div>
                    </div>
                  </div>
                  <!-- if message is media - Video or PDF -->
                  <div class="message-wrapper me"
                    *ngIf="message.attachment?.type == 'video' || message.attachment?.type == 'pdf'">
                    <!-- <img class="avatar ng-star-inserted" [src]="message.profileImgLink || imgLink"> -->
                    <div class="wrapper media file" (contextmenu)="onRightClick($event, message)">
                      <ng-container *ngTemplateOutlet="videoAndFileMessageWrap; context: {message: message}">
                      </ng-container>

                    </div>
                    <div class="date">{{ message.timeStamp.toDate() | date:'shortTime' }}</div>
                  </div>
                  <!-- if message is media - image -->
                  <div class="message-wrapper me" *ngIf="message.attachment?.type == 'image'">
                    <div class="wrapper media " (contextmenu)="onRightClick($event, message)">
                      <ng-container *ngTemplateOutlet="imageMessageWrap; context: {message: message}"></ng-container>

                    </div>
                    <div class="date">{{ message.timeStamp.toDate() | date:'shortTime' }}</div>
                  </div>
                </ng-template>
                <ng-template #incoming_msg>
                  <div class="message-wrapper contact"
                    *ngIf="!message.attachment.type || message.attachment.type == 'none'">
                    <div class="wrapper " (contextmenu)="onRightClick($event, message)">
                      <ng-container *ngTemplateOutlet="normalMessageWrap; context: {message: message}"></ng-container>
                    </div>
                    <div class="date">
                      {{ message.timeStamp.toDate() | date:'shortTime' }}
                      <div *ngIf="message.edited" style="display: inline;padding-right: 8px;">edited</div>

                    </div>
                  </div>
                  <!-- if message is media - Video or PDF -->
                  <div class="message-wrapper contact"
                    *ngIf="message.attachment?.type == 'video' || message.attachment?.type == 'pdf'">
                    <!-- <img class="avatar ng-star-inserted" [src]="message.profileImgLink || imgLink"> -->
                    <div class="wrapper media file" (contextmenu)="onRightClick($event, message)">
                      <ng-container *ngTemplateOutlet="videoAndFileMessageWrap; context: {message: message}">
                      </ng-container>

                    </div>
                    <div class="date">{{ message.timeStamp.toDate() | date:'shortTime' }}</div>
                  </div>
                  <div class="message-wrapper contact" *ngIf="message.attachment?.type == 'image'">
                    <div class="wrapper media " (contextmenu)="onRightClick($event, message)">
                      <ng-container *ngTemplateOutlet="imageMessageWrap; context: {message: message}"></ng-container>

                    </div>
                    <div class="date">{{ message.timeStamp.toDate() | date:'shortTime' }}</div>
                  </div>
                </ng-template>

              </div>
            </div>

          </div>

          <!-- when group chat is selected - chat area top header-->
          <div class="row" style="margin-bottom: 0;" *ngIf="group && selectedConver">
            <div class="back-arrow col s1 hide-on-large-only	" (click)="backClicked()">
              <span class="material-icons">
                arrow_back_ios_new
              </span>
            </div>
            <div class="profile-pic col s2 l1">

              <img loading="lazy" class="avatar" alt="Group Profile Photo" [src]="selectedConver.profilePicture || groupProfileImg">
            </div>
            <div class="chat-details col s7 l10">
              <div class="">{{selectedConver.name}}</div>
              <span class="user-count btn-flat modal-trigger" href="#circleDetails">{{selectedConver.users.length}}
                users</span>
            </div>
            <div class="chat-details  btn-flat col s2 l1 dropdown-trigger " href='#' data-target='dropdown1'>
              <span class="material-icons">
                more_vert
              </span>
            </div>
            <!-- options for group chats -->
            <ul id='dropdown1' class='dropdown-content'>
              <li *ngIf="isMember(selectedConver, id)"><a class="waves-effect waves-light btn-flat modal-trigger"
                  href="#addMember">Add Members</a></li>
              <li><a class="waves-effect waves-light btn-flat modal-trigger" href="#circleDetails">Circle Details</a>
              </li>
              <li *ngIf="selectedConver.admin == id"><a class="waves-effect waves-light btn-flat modal-trigger"
                  href="#editCircle">Edit Circle Info</a></li>
              <li *ngIf="selectedConver.admin == id"><a class="waves-effect waves-light btn-flat modal-trigger"
                  href="#deleteCircle">Delete Circle</a></li>
              <!-- (click)="leaveCircle(id)" -->
              <li *ngIf="selectedConver.admin != id && isMember(selectedConver, id)"><a
                  class="waves-effect waves-light btn-flat modal-trigger" href="#leaveCircle">Leave Circle</a></li>
            </ul>
          </div>



          <!-- Chat history container for group chats -->
          <div class="chat-container group row" *ngIf="group" id="scrollMe">
            <div *ngIf="loadingMessages">
              <p class="no-messages">Loading Message History ...</p>

            </div>
            <div *ngIf="!loadingMessages && messages.length < 1">
              <p class="no-messages">No Messages Here Yet.</p>
            </div>
            <div *ngIf="!loadingMessages && messages.length > 0">
              <div class="message-history" *ngFor="let message of messages; index as i">
                <div class="row message-date"
                  *ngIf="i == 0 || (message.timeStamp.toDate() | date: 'dd MMMM yyyy') !== (messages[i-1]?.timeStamp.toDate() | date: 'dd MMMM yyyy')">
                  <div class="col s12">
                    <div class="date text-center" style="margin-top: 0.6rem;">
                      {{ message.timeStamp.toDate() | date: "EEEE, MMMM d, y" }}
                    </div>
                  </div>
                </div>
                <div class="row message-flag center" *ngIf="message.attachment?.type == 'flag'">
                  <div class="col s12">
                    <div class="flag text-center" style="margin-top: 0.6rem;">
                      {{ message.message }}
                    </div>
                  </div>
                </div>
                <div *ngIf="
                      message.fromId == id
                      then outgoing_msg;
                      else incoming_msg
                    "></div>
                <ng-template #outgoing_msg>
                  <div class="message-wrapper me" *ngIf="!message.attachment.type || message.attachment.type == 'none'">
                    <img loading="lazy" class="avatar ng-star-inserted" [src]="message.profileImgLink || imgLink">
                    <div class="wrapper " (contextmenu)="onRightClick($event, message)">
                      <ng-container *ngTemplateOutlet="normalMessageWrap; context: {message: message}"></ng-container>
                    </div>
                    <div class="date">
                      <div *ngIf="message.edited" style="padding-right: 8px;">edited</div>
                      {{ message.timeStamp.toDate() | date:'shortTime' }}
                    </div>

                  </div>
                  <!-- if message is media - image -->
                  <div class="message-wrapper me" *ngIf="message.attachment?.type == 'image'">
                    <img loading="lazy" class="avatar ng-star-inserted" [src]="message.profileImgLink || imgLink">
                    <div class="wrapper media img" (contextmenu)="onRightClick($event, message)">
                      <ng-container *ngTemplateOutlet="imageMessageWrap; context: {message: message}"></ng-container>

                    </div>
                    <div class="date">{{ message.timeStamp.toDate() | date:'shortTime' }}</div>

                  </div>
                  <!-- if message is media - Video or PDF -->
                  <div class="message-wrapper me"
                    *ngIf="message.attachment?.type == 'video' || message.attachment?.type == 'pdf'">
                    <img loading="lazy" class="avatar ng-star-inserted" [src]="message.profileImgLink || imgLink">
                    <div class="wrapper media file" (contextmenu)="onRightClick($event, message)">
                      <ng-container *ngTemplateOutlet="videoAndFileMessageWrap; context: {message: message}">
                      </ng-container>
                    </div>
                    <div class="date">{{ message.timeStamp.toDate() | date:'shortTime' }} </div>

                  </div>
                </ng-template>
                <ng-template #incoming_msg>
                  <div class="message-wrapper contact"
                    *ngIf="!message.attachment.type || message.attachment.type == 'none'">
                    <img loading="lazy" class="avatar ng-star-inserted" [src]="message.profileImgLink || imgLink">
                    <div class="wrapper " (contextmenu)="onRightClick($event, message)">
                      <div class="name user-color-{{message.userIndex}}">{{message.GivenName || message.FirstName}}
                        {{message.LastName}}</div>
                      <ng-container *ngTemplateOutlet="normalMessageWrap; context: {message: message}"></ng-container>
                    </div>
                    <div class="date">
                      {{ message.timeStamp.toDate() | date:'shortTime' }}
                      <div *ngIf="message.edited" style="display: inline;padding-right: 8px;">edited</div>
                    </div>

                  </div>
                  <div class="message-wrapper contact" *ngIf="message.attachment?.type == 'image'">
                    <img loading="lazy" class="avatar ng-star-inserted" [src]="message.profileImgLink || imgLink">
                    <div class="wrapper media img" (contextmenu)="onRightClick($event, message)">
                      <div class="name user-color-{{message.userIndex}}">{{message.GivenName || message.FirstName}}
                        {{message.LastName}}</div>
                        <ng-container *ngTemplateOutlet="imageMessageWrap; context: {message: message}"></ng-container>

                    </div>
                    <div class="date">{{ message.timeStamp.toDate() | date:'shortTime' }}</div>

                  </div>
                  <!-- if message is media - Video or PDF -->
                  <div class="message-wrapper contact"
                    *ngIf="message.attachment?.type == 'video' || message.attachment?.type == 'pdf'">
                    <img loading="lazy" class="avatar ng-star-inserted" [src]="message.profileImgLink || imgLink">
                    <div class="wrapper media file" (contextmenu)="onRightClick($event, message)">
                      <div class="name user-color-{{message.userIndex}}">{{message.GivenName || message.FirstName}}
                        {{message.LastName}}</div>
                      <ng-container *ngTemplateOutlet="videoAndFileMessageWrap; context: {message: message}">
                      </ng-container>

                    </div>
                    <div class="date">{{ message.timeStamp.toDate() | date:'shortTime' }} </div>
                  </div>
                </ng-template>

              </div>
            </div>
          </div>

          <!-- Message text area and send -->
          <div class="row chat-footer">
            <div *ngIf="!group || (group && isMember(selectedConver, id))">
              <form class="col s12" [formGroup]="messageForm" (ngSubmit)="send(messageForm.value)">
                <div class="row message">
                  <div class="attach-wrap btn-flat col s1" (click)="attachFile.click()">
                    <span class="material-icons attach color-blue">
                      attach_file
                    </span>

                  </div> <input #attachFile id="attachFile" name="attachFile" onclick="value = null"
                    (change)="onSelectFile($event.target.files)" formControlName="attachFile" type="file" multiple
                    hidden />

                  <div class="input-field col s11">
                    <textarea class="textarea materialize-textarea" placeholder="Message"
                      formControlName="message"></textarea>
                    <label for="message"></label>
                  </div>

                </div>
                <button class="btn-flat send" type="submit">
                  <span class="material-icons color-blue" *ngIf="!editMessage">
                    send
                  </span>
                  <span class="material-icons color-blue"
                    *ngIf="editMessage && (messageForm.value.message == selectedMessage.message)">
                    close
                  </span>
                  <span class="material-icons color-blue"
                    *ngIf="editMessage && !(messageForm.value.message == selectedMessage.message)">
                    edit
                  </span>
                </button>
              </form>
            </div>
            <div *ngIf="group && !isMember(selectedConver, id)">
              <div class="waves-effect btn-flat joinCircleBtn" (click)="joinGroup()"
>
                Join Circle
              </div>
            </div>


          </div>

        </div>
        <!-- Add Member Modal -->
        <div id="addMember" class="modal" >
          <div class="modal-content modal-header center">
            <h5 class=""><strong>Add New Member</strong></h5>
            <span>Search for user to add to circle:</span>
            <br />
          </div>

          <div *ngIf="isCandidate">
            <div *ngIf="!searchAndAdd">
              <div class="center">
                <br/>
                <button class=" waves-effect btn backg-color-blue" (click)="toggleAddingPreference(searchAndAdd)">
                  Search Members
                </button>
                <br/>
              </div>
              <form  [formGroup]="FilterMemberForm"  (ngSubmit)="addMembersToCircle(FilterMemberForm.value)">

              <div class="modal-content"  style="max-height: 550px; overflow: hidden scroll;">
                  <div class="input-field class col s10 offset-s1 m9 offset-m2 padding-right-0 margin-top-0">
                    <div class="blue-grey-text text-darken-4" style="margin: 2% 0;">Who would you like to add in your circle
                      to?</div>
                    <div class="col m5 s12">
                      <label>
                        <input type="radio" value="true" name="audience" class="backg-color-blue" [checked]="everyone"
                          (click)="allMembersSelected()" />
                        <span><strong>Everyone</strong></span>
                      </label>
                    </div>
                    <div class="col m6 offset-m1 s12">
                      <label>
                        <input type="radio" value="false" name="audience" [checked]="specificAudience"
                          (click)="specificAudienceSelected()" class="backg-color-blue" />
                        <span><strong>Specific Audience</strong></span>
                      </label>
                    </div>
                  </div>
                  <div *ngIf="specificAudience">

                    <div class="row filter-methods">
                      <div class="row">
                          <div [ngClass]="useAgeRange ? 'method-name input-field class col m4 offset-m2  s10 offset-s1' : 'method-name input-field class col s10 offset-s1  m9 offset-m2' ">
                            <div class="col s12">
                              <label>
                                <input type="radio" value="Yes" name="ageLimit" [checked]="useAgeRange"
                                  (click)="useAgeRangeSelected()" />
                                <span><span class="material-icons material-icon-margin-top-zero">
                                    date_range
                                  </span>Filter using Age</span>
                              </label>
                            </div>
                          </div>
                          <div *ngIf="useAgeRange" class="col m6 offset-m0 s10 offset-s2">
                            <div class="input-field class col s5 margin-bottom-0" >
                              <input formControlName="minAge" type="number" min="0" maxlength="75" autocomplete="off"
                                class="validate margin-bottom-0" placeholder="Min Age :" style=" height: 2.5rem;">
                              <span class="helper-text" data-error="A valid Min Age is required." data-success=""
                                style="min-width: 200px;"></span>
                            </div>
                            <div class="input-field class col s5 offset-s1 margin-bottom-0" >
                              <input formControlName="maxAge" type="number" [min]="FilterMemberForm.value.minAge"
                                maxlength="75" autocomplete="off" class="validate margin-bottom-0" placeholder=" Max Age :"
                                style="height: 2.5rem;">
                              <span class="helper-text" data-error="A valid Maximum Age is required." data-success=""
                                style="min-width: 200px;"></span>

                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div
                            [ngClass]="useGender ? 'method-name input-field class col m4 offset-m2  s10 offset-s1' : 'method-name input-field class col s10 offset-s1  m9 offset-m2' ">
                            <div class="col s12 padding-right-0">
                              <label>
                                <input type="radio" value="Yes" name="useGender" [checked]="useGender"
                                  (click)="useGenderSelected()" />
                                <span><span class="material-icons material-icon-margin-top-zero">
                                    wc
                                  </span>Filter using Gender</span>
                              </label>
                            </div>
                          </div>
                          <div *ngIf="useGender" class="col m5 offset-m0 s10 offset-s2"
                            style="margin-top: 2.5%; margin-bottom:  4.5%;">
                            <div class="col s5">
                              <label>
                                <input type="radio" value="M" name="gender" formControlName="gender" class="backg-color-blue"
                                  checked />
                                <span><strong>Male</strong></span>
                              </label>
                            </div>
                            <div class="col s5 offset-s1">
                              <label>
                                <input type="radio" value="F" name="gender" formControlName="gender"
                                  class="backg-color-blue" />
                                <span><strong>Female</strong></span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div [ngClass]="useCommunityName ? 'method-name input-field class col m4 offset-m2  s11 offset-s1' : 'method-name input-field class col s10 offset-s1  m9 offset-m2' ">
                            <div class="col s12">
                              <label>
                                <input type="radio" value="Yes" name="communityName" [checked]="useCommunityName"
                                  (click)="useCommunityNameSelected()" />
                                <span><span class="material-icons material-icon-margin-top-zero">
                                    groups
                                  </span>Community</span>
                              </label>
                            </div>
                          </div>
                          <div *ngIf="useCommunityName" class="col m6 offset-m0 s10 offset-s2">
                            <div class="input-field col s12">
                              <select formControlName="community" id="communityName">
                                <option value="" disabled selected>Choose your option</option>
                              </select>
                              <label for="communityName">Chooose Community: </label>
                            </div>
                          </div>
                        </div>
                        <div class="row" style="margin-top: 0.5rem;">
                          <div class="col s5 m3 offset-m5">
                            <button (click)="closeAddMemberModal()" class=" grey btn" type="button">
                              Cancel</button>
                          </div>
                          <div class="col s6 m3">
                            <button type="submit" class=" waves-effect btn backg-color-blue">Add Members</button>
                          </div>
                        </div>
                        <br/>
                        <br/>
                    </div>

                  </div>
                  <div class="modal-footer" >
                    <div class="row" style="margin-top: 0.5rem;">
                      <div class="col s5 m3 offset-m5">
                        <button class="modal-close grey btn" type="button">
                          Cancel</button>
                      </div>
                      <div class="col s6 m3">
                        <button type="submit" class="modal-close waves-effect btn backg-color-blue">Add Members</button>
                      </div>
                    </div>
                  </div>
              </div>


            </form>


            </div>

            <div *ngIf="searchAndAdd">

                <div class="modal-content" style="max-height: 550px; overflow: hidden scroll;">
                  <div class="center">
                    <button class=" waves-effect btn backg-color-blue" (click)="toggleAddingPreference(searchAndAdd)">
                      Add Multiple Members
                    </button>
                    <br/><br/>
                  </div>
                  <div class="" *ngIf="selectedMember.length > 0">
                    <div class="input-field col s12">
                      <input disabled [(ngModel)]="selectedMemeberName" type="text" class="validate">
                    </div>
                  </div><br />
                  <div class="row search-bar">
                    <div class="input-field col s12">
                      <i class="material-icons prefix">search</i>
                      <input type="search" id="searchMember" placeholder="search to start a new chat" class="autocomplete"
                        autocomplete="off" [(ngModel)]="searchMemberQuery" (focus)="openUserSuggestion($event.target.id)"
                        (ngModelChange)="searchForCircleMember()">

                    </div>
                  </div>

                  <div class="contacts" *ngIf="searchCircleMember">
                    <ul *ngIf="searchResult.length > 0">
                      <li *ngFor="let user of searchResult">
                        <div class="each-chat btn-flat" (click)="selectedGroupMember(user)">
                          <div class="one-chat-btn">
                            <div class="profile-pic col s2">
                              <img loading="lazy" class="avatar" alt="User Profile Photo" [src]="user.profileImgLink || imgLink">
                            </div>
                            <div class="chat-details col s6">
                              <div class="contact-name">{{user.GivenName || user.FirstName}} {{user.LastName}}</div>

                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>

                <div class="modal-footer">
                  <div class="row" style="margin-top: 0.5rem;">
                    <div class="col s5 m3 offset-m5">
                      <button class="modal-close grey btn" type="button">
                        Cancel</button>
                    </div>
                    <div class="col s6 m3">
                      <a class="modal-close waves-effect btn backg-color-blue" (click)="addMembersToGroup()">Add Members</a>
                    </div>
                  </div>
                </div>

            </div>

            </div>

          </div>

          <div *ngIf="!isCandidate">
            <div class="modal-content" style="max-height: 550px; overflow: hidden scroll;">

              <div class="" *ngIf="selectedMember.length > 0">
                <div class="input-field col s12">
                  <input disabled [(ngModel)]="selectedMemeberName" type="text" class="validate">
                </div>
              </div><br />
              <div class="row search-bar">
                <div class="input-field col s12">
                  <i class="material-icons prefix">search</i>
                  <input type="search" id="searchMember" placeholder="search to start a new chat" class="autocomplete"
                    autocomplete="off" [(ngModel)]="searchMemberQuery" (focus)="openUserSuggestion($event.target.id)"
                    (ngModelChange)="searchForCircleMember()">

                </div>
              </div>
              <div class="contacts" *ngIf="searchCircleMember">
                <ul *ngIf="searchResult.length > 0">
                  <li *ngFor="let user of searchResult">
                    <div class="each-chat btn-flat" (click)="selectedGroupMember(user)">
                      <div class="one-chat-btn">
                        <div class="profile-pic col s2">
                          <img loading="lazy" class="avatar" alt="User Profile Photo" [src]="user.profileImgLink || imgLink">
                        </div>
                        <div class="chat-details col s6">
                          <div class="contact-name">{{user.GivenName || user.FirstName}} {{user.LastName}}</div>

                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

            </div>
            <div class="modal-footer">
              <div class="row" style="margin-top: 0.5rem;">
                <div class="col s5 m3 offset-m5">
                  <button class="modal-close grey btn" type="button">
                    Cancel</button>
                </div>
                <div class="col s6 m3">
                  <a class="modal-close waves-effect btn backg-color-blue" (click)="addMembersToGroup()">Add Members</a>
                </div>
              </div>
            </div>

          </div>

        </div>

        <!-- Circle detail Info -->
        <div id="circleDetails" class="modal">
          <div class="modal-content modal-header" *ngIf="group">
            <div class="row margin-bottom-0">
              <div class="col s6">
                <span>Circle Details</span>
              </div>
              <div class="col s4 offset-s2 right-align" style=" height: 18px;">
                <a class="modal-close close-btn btn-flat" style="line-height: unset; color: inherit;">Close</a>
              </div>
            </div>

          </div>

          <div class="modal-content" *ngIf="group">
            <div class="row">
              <div class="col s5 m3">
                <img loading="lazy" class="profile-picture responsive-img materialboxed "
                  [src]="selectedConver.profilePicture || groupProfileImg"
                  style="width: 100px; height: 100px; border-radius: 50%;">

              </div>
              <div class="col s7 m7" style="padding-top: 8%;">
                <h6>{{selectedConver.name}} </h6>
                <span>{{selectedConver.users.length}} members <span class="color-blue" *ngIf="selectedConver.isPrivate">
                    (Private) </span><span *ngIf="!selectedConver.isPrivate" class="color-blue"> (Public)</span></span>
              </div>
            </div>
            <div class="row" style="margin-bottom: 0.2rem; padding-left: 16px;">
              <span class="col">Members</span>
            </div>
            <div class="divider"></div>
            <div class="modal-content-main">
              <ul *ngIf="groupMembersList.length > 0">
                <li *ngFor="let user of groupMembersList">
                  <div class="each-chat btn-flat">
                    <div class="one-chat-btn">
                      <div class="profile-pic col s2 offset-s1 offset-m2">
                        <img loading="lazy" class="avatar" alt="User Profile Photo" [src]="user.profileImgLink || imgLink">
                      </div>
                      <div class="chat-details col s6">
                        <div class="contact-name">{{user.GivenName || user.FirstName}} {{user.LastName}}</div>

                      </div>
                      <div class="col s2 right-align"  *ngIf="selectedConver.admin == user.uid">
                        <span>admin</span>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

          </div>
          <div class="modal-footer">
          </div>
        </div>

        <!-- Edit Circle Info -->
        <div id="editCircle" class="modal">
          <form [formGroup]="editCircleInfoForm" (ngSubmit)="editCircleInfo(editCircleInfoForm.value)">

            <div class="modal-content modal-header" *ngIf="group">
              <div class="row margin-bottom-0">
                <div class="col s5">
                  <span>Edit Circle</span>
                </div>
                <div class="col s6 offset-s1 right-align" >
                  <a type="button" class="col s6 m4 offset-m4 modal-close close-btn btn-flat"
                    style="line-height: unset; color: inherit;  height: 18px;">Cancel</a>

                  <button type="submit" class="col s6 m4 modal-close close-btn btn-flat right-align"
                    style="line-height: unset; height: 18px; color: inherit;">Save</button>
                </div>
              </div>
            </div>
            <div class="modal-content" *ngIf="group">

              <div class="row">
                <div class="col s4 m3">
                  <div class="row center" style="margin-bottom: 0;">
                    <img loading="lazy" class="profile-picture responsive-img materialboxed "
                    data-src="{{selectedConver.profilePicture || groupProfileImg}}">

                  </div>
                  <div class="row center" style="margin-right: auto;padding-top: 8%;">
                    <button #file class="btn-floating btn-small  blue tooltipped" data-position="top" type="button"
                      data-tooltip="Upload Photo" (click)="myProfilePicInput.click()"><i class="material-icons"
                        style="    margin-top: 0.1rem;display: block;">camera_alt</i></button>
                    <div style="display: inline-block">
                      <input #myProfilePicInput name="file" (change)="onSelectFiles($event.target.files, 'editCircle')"
                        type="file" hidden />
                    </div>
                  </div>

                </div>
                <!--  -->
                <div class="col s8 m7">
                  <div class="input-field class col s10 offset-s1 m9 offset-m0">
                    <input formControlName="circleName" id="circleName" type="text" maxlength="75" autocomplete="off"
                      required class="validate">
                    <label for="circleName"> Circle Name : <strong class="red-text">*</strong> </label>
                    <span class="helper-text" data-error="Circle Name is required." data-success=""></span>

                  </div>
                  <div>
                    <div class="row col s11 offset-s1 m9 offset-m0">
                      <span class="material-icons icon">security </span>
                      <strong><span>Private Circle </span></strong><strong class="red-text"> *</strong>
                    </div><br />
                    <div class="col s3 m6" style="padding-left: 2.9rem;">
                      <label>
                        <input type="radio" [value]="true" name="isPrivateCircle" formControlName="isPrivateCircle"
                          class="backg-color-blue" />
                        <span><strong class="blue-text">Yes</strong></span>
                      </label>
                    </div>
                    <div class="col s3 offset-s1 m6" style="padding-left: 2.9rem; ">
                      <label>
                        <input type="radio" [value]="false" name="isPrivateCircle" formControlName="isPrivateCircle"
                          class="backg-color-blue" />
                        <span><strong class="blue-text">No</strong></span>
                      </label>
                    </div>
                  </div>

                </div>
              </div>

              <div class="row" style="margin-bottom: 0.2rem; padding-left: 16px;">
                <span class="col">Members</span>
              </div>
              <div class="divider"></div>
              <div class="modal-content-main">
                <ul *ngIf="groupMembersList.length > 0">
                  <li *ngFor="let user of groupMembersList">
                    <div class="each-chat btn-flat">
                      <div class="one-chat-btn">
                        <div class="profile-pic col s2 offset-s1 offset-m2">
                          <img loading="lazy" class="avatar" alt="User Profile Photo" [src]="user.profileImgLink || imgLink">
                        </div>
                        <div class="chat-details col s6">
                          <div class="contact-name">{{user.GivenName || user.FirstName}} {{user.LastName}}</div>

                        </div>
                        <div class="col s2 right-align"  *ngIf="selectedConver.admin == user.uid">
                          <span>admin</span>
                        </div>
                        <div class="col s2 right-align"  *ngIf="selectedConver.admin != user.uid"
                          (click)="leaveCircle(user.uid)">
                          <span class="flat-btn">remove</span>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

            </div>
          </form>
        </div>

        <!-- Leave Circe Modal -->
        <div id="leaveCircle" class="modal">
          <div class="modal-content modal-header center">
            <h4 class="center">Leave Circle</h4>
            <p>Are You Sure You want to Leave this circle?</p>

          </div>
          <div class="modal-footer">
            <div class="row center" style="margin-top: 1.5rem;">
              <div class="col s10 offset-s1">
                <label>
                  <input [(ngModel)]="noNotification" type="checkbox" />
                  <span>Don't Notify Others.</span>
                </label>
              </div>

            </div>
            <div class="row" style="margin-top: 2.5rem;">
              <div class="col s5 m3 offset-m3">
                <button class="modal-close grey btn" type="button">
                  Cancel</button>
              </div>
              <div class="col s6 m3">
                <a class="modal-close waves-effect grey btn" (click)="leaveCircle(id)">Leave Circle</a>
              </div>
            </div>
          </div>
        </div>
        <!-- Delete Circe Modal -->
        <div id="deleteCircle" class="modal">
          <div class="modal-content modal-header">
            <h4 class="center">Delete Circle</h4>
            <p>Are You Sure You want to Permanently delete this circle?</p>
          </div>
          <div class="modal-footer">
            <div class="row" style="margin-top: 2.5rem;">
              <div class="col s5 m3 offset-m5">
                <button class="modal-close grey btn" type="button">
                  Cancel</button>
              </div>
              <div class="col s6 m3">
                <a class="modal-close waves-effect grey btn" (click)="deleteCircle(selectedConver.id)">Delete Circle</a>
              </div>
            </div>
          </div>
        </div>
        <!-- End of Modal -->

        <!-- upload files preview -->
        <div id="uploadFilesViewer" class="modal">

          <div class="modal-content">
            <h5>Selected Document</h5>

            <div class="row center">
              <div class="main-container">
                <div class="selected-all row" *ngIf="selectedFilesForDisplay.length > 0">
                  <div class="col s5 selected-wrap" *ngFor="let file of selectedFilesForDisplay; index as i">
                    <button class="file-wrap" *ngIf="file.name != 'image'">
                      <div class="row icon-wrap">
                        <span class="material-icons outlined">
                          insert_drive_file
                        </span>
                      </div>
                      <div class="row file-name">
                        <span>
                          {{file.name}}
                        </span>
                      </div>
                      <div class="row file-size">
                        <span>
                          {{(file.size / 1024 / 1024 | number:'1.0-2')}} MB
                        </span>
                      </div>
                    </button>
                    <button class="image-wrap" style="padding: 0" *ngIf="file.name == 'image'">
                      <img loading="lazy" class="col s12 materialboxed" [src]="file.result">
                    </button>
                    <div class="cancel-wrap btn-flat" (click)="cancelFile(i)">
                      <span class="material-icons">
                        cancel
                      </span>
                    </div>
                  </div>
                  <button class="add-more-files btn-flat" (click)="attachFile2.click()">
                    <span class="material-icons" style="color: white;">
                      add
                    </span>
                  </button>
                  <input #attachFile2 id="attachFile2" name="attachFile2" onclick="value = null"
                    (change)="onSelectFile($event.target.files)" type="file" multiple hidden />

                </div>

              </div>

            </div>

          </div>
          <div class="modal-footer">
            <button class="modal-close grey btn" type="button" style="margin-right: 15px;" (click)="cancelUpload()">
              Cancel</button>

            <button type="button" class="modal-close btn  backg-color-blue" (click)="onSendFiles()">
              Send
            </button>
          </div>
        </div>

        <div class="right-click-menu" *ngIf="!isHidden"
          [ngStyle]="{'top.px': (yPosTabMenu-20), 'left.px': xPosTabMenu}">
          <ul class='content' >
            <li tabindex="0"><a class="waves-effect waves-light btn-flat"
                *ngIf="id == selectedMessage.fromId && selectedMessage.attachment.type == 'none'"
                (click)="editSelectedMessage(selectedMessage)">Edit Message</a></li>
            <li tabindex="0"><a class="waves-effect waves-light btn-flat"
                (click)="forwardSelectedMessage(selectedMessage)">Forward Message</a></li>
            <li tabindex="0"><a class="waves-effect waves-light btn-flat"
                *ngIf="selectedMessage.attachment.type == 'none'" (click)="copyMessage(selectedMessage)">Copy</a></li>

            <li class="divider" tabindex="-1"></li>
          </ul>
        </div>


        <!-- Forward Message Modal -->
        <div id="forwardMessage" class="modal">
          <div class="modal-content modal-header center">
            <h5 class=""><strong>Forward Message </strong></h5>
            <br />
          </div>
          <div class="modal-content">
            <div class="message-forward" *ngIf="selectedMessage">
              <span> "
                {{selectedMessage.message}} "
              </span>

            </div>

            <!-- <div class="row search-bar">
              <div class="input-field col s12">
                <i class="material-icons prefix">search</i>
                <input type="search" id="search" placeholder="search to start a new chat" class="autocomplete"
                  autocomplete="off" [(ngModel)]="searchForwardQuery" (focus)="openUserSuggestion($event.target.id)"
                  (ngModelChange)="searchChat()">

              </div>
            </div> -->
            <div class="contacts" *ngIf="!search">
              <ul *ngIf="privateChats.length > 0">
                <span>Private Chats</span>
                <li *ngFor="let chat of privateChats">
                  <div class="each-chat btn-flat">
                    <div class="one-chat-btn">
                      <div class="profile-pic col s2">
                        <img loading="lazy" class="avatar" alt="User Profile Photo" [src]="chat.profileImgLink || imgLink">
                      </div>
                      <div class="chat-details col s6">
                        <div class="contact-name">{{chat.user_2.GivenName || chat.user_2.FirstName}}
                          {{chat.user_2.LastName}}</div>

                      </div>
                      <div class="col s4 right-align" >
                        <a class="modal-close waves-effect btn-flat backg-color-blue" style="color: white;
                        border-radius: 20px;height: 28px; line-height: 26px;"
                          (click)="sendForwardMessage(selectedMessage, chat.id, chat.user_2.id)">send</a>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <ul *ngIf="groupChats.length > 0">
                <span>Groups</span>
                <li *ngFor="let chat of groupChats">
                  <div class="each-chat btn-flat">
                    <div class="one-chat-btn">
                      <div class="profile-pic col s2">
                        <img loading="lazy" class="avatar" alt="Profile Photo" [src]="chat.profilePicture || imgLink">
                      </div>
                      <div class="chat-details col s6">
                        <div class="contact-name">{{chat.name}}</div>

                      </div>
                      <div class="col s4 right-align" >
                        <a class="modal-close waves-effect btn-flat backg-color-blue" style="color: white;
                        border-radius: 20px;height: 28px; line-height: 26px;"
                          (click)="sendForwardMessage(selectedMessage, chat.id, chat.id)">send</a>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="contacts" *ngIf="search">
              <ul *ngIf="searchResult.length > 0">
                <li *ngFor="let chat of searchResult">
                  <div *ngIf="!chat.name" class="each-chat btn-flat">
                    <div class="one-chat-btn">
                      <div class="profile-pic col s2">
                        <img loading="lazy" class="avatar" alt="User Profile Photo" [src]="chat.profileImgLink || imgLink">
                      </div>
                      <div class="chat-details col s6">
                        <div class="contact-name">{{chat.GivenName || chat.FirstName}} {{chat.LastName}}</div>

                      </div>
                      <div class="col s4 right-align" >
                        <a class="modal-close waves-effect btn-flat backg-color-blue" style="color: white;
                        border-radius: 20px;height: 28px; line-height: 26px;"
                          (click)="sendForwardMessage(selectedMessage, '', chat.id)">send</a>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="chat.name" class="each-chat btn-flat">
                    <div class="one-chat-btn">
                      <div class="profile-pic col s2">
                        <img loading="lazy" class="avatar" alt="Profile Photo" [src]="chat.profilePicture || imgLink">
                      </div>
                      <div class="chat-details col s6">
                        <div class="contact-name">{{chat.name}}</div>

                      </div>
                      <div class="col s4 right-align" >
                        <a class="modal-close waves-effect btn-flat backg-color-blue" style="color: white;
                        border-radius: 20px;height: 28px; line-height: 26px;"
                          (click)="sendForwardMessage(selectedMessage, chat.id, chat.id)">send</a>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="modal-footer">
            <div class="row" style="margin-top: 0.5rem;">
              <div class="col s3 m3 offset-m5 offset-s6">
                <a class="modal-close grey btn">Cancel</a>
              </div>
              <div class="col s3 m3">
                <a class="modal-close btn backg-color-blue">Done</a>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>



</div>


  <!-- normal message container -->
  <ng-template #normalMessageWrap let-message='message'>
    <div class="forwarded-message" *ngIf="message.forwardedMessage"><span class="material-icons" style="margin-top: 0;
    font-size: 18px;">arrow_forward </span> Forwarded</div>
    <!-- *ngIf="!((message.fromId == messages[i-1]?.fromId) && (message.timeStamp.toDate() | date: 'dd MMMM yyyy') == (messages[i-1]?.timeStamp.toDate() | date: 'dd MMMM yyyy'))" -->

    <div class="message" *ngIf="replaceURLs(message.message); let messageContent">
      <span [innerHtml]="messageContent"></span>
    </div>

    <!-- <div style="position: relative">
      <div style="position: absolute" class="likeMessage text-customTeal float-right translate-x-20" (click)="likeMessage(message.id)">
        <div *ngIf="!message.likes || !message.likes.count || !message.likes.count > 0; then emptyLikes; else liked">
        </div>
        <ng-template #liked><span class="material-icons color-red">
            favorite
          </span><span class="likes-count" *ngIf="group">{{message.likes.count}}</span></ng-template>
        <ng-template #emptyLikes><span class="material-icons">
            favorite_border
          </span></ng-template>

      </div>
    </div> -->


    <div class="forwarded-message" *ngIf="message.forwardedMessage">{{message.forwardedFrom}},
      {{message.forwardedMessage.timeStamp.toDate() | date: 'M/d/yy, h:mm a'}}
    </div>
  </ng-template>

  <!-- Image message container -->
  <ng-template #imageMessageWrap let-message='message'>
    <div class="forwarded-message" *ngIf="message.forwardedMessage"><span class="material-icons" style="margin-top: 0;
    font-size: 18px;">arrow_forward </span> Forwarded</div>

    <div class="likeMessage" (click)="likeMessage(message.id)">
      <div *ngIf="!message.likes || !message.likes.count || !message.likes.count > 0; then emptyLikes; else liked">
      </div>
      <ng-template #liked><span class="material-icons color-red">
          favorite
        </span><span class="likes-count" *ngIf="group">{{message.likes.count}}</span></ng-template>
      <ng-template #emptyLikes><span class="material-icons">
          favorite_border
        </span></ng-template>

    </div>
    <div class="media-message"><img loading="lazy" [src]="message.attachment.link" class="materialboxed "> <br />
    </div>
    <div class="forwarded-message" *ngIf="message.forwardedMessage">{{message.forwardedFrom}},
      {{message.forwardedMessage.timeStamp.toDate() | date: 'M/d/yy, h:mm a'}}</div>

  </ng-template>

  <!-- video and File message container -->
  <ng-template #videoAndFileMessageWrap let-message='message'>
    <div class="forwarded-message" *ngIf="message.forwardedMessage"><span class="material-icons" style="margin-top: 0;
    font-size: 18px;">arrow_forward </span> Forwarded</div>
    <div class="likeMessage" (click)="likeMessage(message.id)">
      <div *ngIf="!message.likes || !message.likes.count || !message.likes.count > 0; then emptyLikes; else liked">
      </div>
      <ng-template #liked><span class="material-icons color-red">
          favorite
        </span><span class="likes-count" *ngIf="group">{{message.likes.count}}</span></ng-template>
      <ng-template #emptyLikes><span class="material-icons">
          favorite_border
        </span></ng-template>

    </div>
    <div class="media-message ">
      <a class="download-icon" [href]="message.attachment.link" target="_blank" download>
        <span class="material-icons">
          download
        </span>
      </a>
      <div class="document-info">
        <div class="document-name">
          <a class="document-name-only" [href]="message.attachment.link" target="_blank"
            download>{{message.attachment.name.substring(0,
            message.attachment.name.lastIndexOf('.'))}}</a>
          <a class="document-extension">.{{message.attachment.name.substring(message.attachment.name.lastIndexOf('.')
            + 1)}}</a>
        </div>
        <div class="document-action">
          <a [href]="message.attachment.link" target="_blank" download>download</a>
        </div>
      </div>
    </div>
    <div class="forwarded-message" *ngIf="message.forwardedMessage">{{message.forwardedFrom}},
      {{message.forwardedMessage.timeStamp.toDate() | date: 'M/d/yy, h:mm a'}}</div>

  </ng-template>
