import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { AppointmentService } from 'src/app/services/firebase/employee-appointment.service';
import moment from 'moment';
import { UserService } from 'src/app/services/firebase/user.service';
import { isPast, isSameWeek, isSameMonth, isToday, isFuture, differenceInHours, isBefore } from 'date-fns';
import { Router } from '@angular/router';
import { ProductsServiceMenuService } from 'src/app/services/api/data.service';
import { EmployeeServicesService } from 'src/app/services/firebase/employee-services.service';
import { HelperService } from 'src/app/services/helper/helper';


@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.css']
})
export class AppointmentsComponent implements OnInit {
currentUser: any;
currentUserId: any;

totalAppointments: number;
allAppointments = [];
upcomingAppointments = [];
date = new Date();
filterOpen = false;

EmpToAppointment = [];
usersName: any;

appointmentTimelineFilter = '';
beforeTimeline = [];
openCancelModal = false;
appointmentToBeCanceled: any;
// toast
toastMessage: any;
toastClass: any;
toastType: any;
openToast = false;
canReschedule = false;
canCancel  = false;
canCancelBeforeHr = 24;
canRescheduleBeforeHr = 24;
isUpcomingClicked = true;
servicesName;
//added
//canReschedule : boolean = false;

  constructor(
    private appointmentService: AppointmentService,
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
    private location: Location,
    private productsServiceMenuService: ProductsServiceMenuService,
    private empService: EmployeeServicesService,
    private helperService: HelperService
    ) { }

  ngOnInit(): void {
    this.currentUser = this.authService.getCurrentUser();
    this.currentUserId = this.currentUser.uid;
    const today = new Date();

    this.productsServiceMenuService.servicesName.subscribe(p => {
      this.servicesName = p
    }
    )
    this.userService.getUserByEmail(this.currentUser.email).valueChanges().subscribe((user) => {
      if (user){
        this.usersName = user;
      }
    });
    this.appointmentService.getAppointmentSetting().valueChanges().subscribe((settings: any) => {
      if (settings.length > 0){
        this.canReschedule = settings[0].canReschedule;
        this.canCancel  = settings[0].canCancel;
        this.canCancelBeforeHr = settings[0].canCancelBeforeHr;
        this.canRescheduleBeforeHr = settings[0].canRescheduleBeforeHr;
      }
    });

    this.appointmentService.getAppointmentByUserId(this.currentUserId).valueChanges().subscribe((appointments) => {
      if (appointments){
        this.totalAppointments = appointments.length;
        this.allAppointments = appointments;
        let apptOptions = {canCancel: false, canReschedule: false};
        this.allAppointments.forEach(appointment => {
          this.userService.getUserById(appointment.employeeId).valueChanges().subscribe(employee => {
            if (employee.length > 0) {
              const dateDiffInHrs = Math.abs(differenceInHours(appointment.startTime.toDate(), new Date()));
              if (dateDiffInHrs){
                if (this.canCancel && this.canCancelBeforeHr){
                  if (dateDiffInHrs >= this.canCancelBeforeHr){
                    apptOptions.canCancel = true;
                  }
                }
                if (this.canReschedule && this.canRescheduleBeforeHr){
                  if (dateDiffInHrs >= this.canRescheduleBeforeHr){
                    apptOptions.canReschedule = true;
                  }
                }
              }
              this.EmpToAppointment.push({
                appointment,
                employee: employee[0],
                options: apptOptions});
            }
            apptOptions = {canCancel: false, canReschedule: false};
          });
        });

        this.upcomingClicked();
      }
    });
  }

  toggleFilter(){
    this.filterOpen = !this.filterOpen;
  }
  onTimeframeChange(){
    if (this.beforeTimeline.length === 0){
      this.beforeTimeline = [...this.allAppointments];
    }
    this.allAppointments = this.beforeTimeline;

    if (this.appointmentTimelineFilter === 'allTime'){
      this.allAppointments = this.beforeTimeline;
    }
    if (this.appointmentTimelineFilter === 'today'){
      this.allAppointments = this.allAppointments.filter((appointment) => {
        return isToday(new Date(appointment.startTime.toDate()));
      });
    }
    if (this.appointmentTimelineFilter === 'pastTime'){
      this.allAppointments = this.allAppointments.filter((appointment) => {
        return isPast(new Date(appointment.startTime.toDate()));
      });
    }
    if (this.appointmentTimelineFilter === 'upcomingApp'){
      this.allAppointments = this.allAppointments.filter((appointment) => {
        return isFuture(new Date(appointment.startTime.toDate()));
      });
    }

  }
  openModalCancel(appointment){
    this.appointmentToBeCanceled = appointment;
    this.openCancelModal = true;
  }
  closeCancelModal(){
    this.openCancelModal = false;
  }
  clearFilters(){

  }

  bookAppointment(){
    // this.router.navigate(['/user/book-appointment', this.currentUserId]);
    this.router.navigate(['/user/services']);

  }

  reschedule(appt){
    this.router.navigate(['/user/appointment-reschedule', appt.id]);
  }
  cancelAppointment(){
    this.openCancelModal = false;
    this.appointmentService.deleteAppointment(this.appointmentToBeCanceled).then(() => {
      this.toast({ html: 'Appointment Cancelled!', classes: 'red', type: 'failure' });
    }).catch((err) => {
      this.toast({ html: 'Can\'t cancel an appointment.', classes: 'red', type: 'failure' });
    });
  }
  upcomingClicked(){
    this.isUpcomingClicked = true;
    this.appointmentTimelineFilter = 'upcomingApp';
    this.onTimeframeChange();
  }
  pastClicked(){
    this.isUpcomingClicked = false;
    this.appointmentTimelineFilter = 'pastTime';
    this.onTimeframeChange();
  }
  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
      if (obj.redirect){
        this.location.back();
      }
    }, 2000);
  }
  bookAgainClicked(appointment){
    let service;
    this.empService.getServiceById(appointment.serviceId).valueChanges().subscribe(apptDetails => {
      service = apptDetails[0];
      this.router.navigateByUrl('/user/book-appointment', {state: {service, serviceUnder:null, rebookedAppointment: appointment}});
    })
    
  }
  truncateHTML(text: string, limit: string): string {
    return this.helperService.truncateHTML(text,limit)
  }
}
