<div class="w-full md:w-[650px] mx-auto rounded-md shadow-md border border-solid px-8 py-6 border-gray-300">
  <div class="py-4">
    <p class="text-gray-700 text-xl font-bold pb-1 ">General Settings</p>
    <div class="w-full py-0.5 bg-gray-200">
    </div>
  </div>
  <form [formGroup]="appSettingsForm" (ngSubmit)="confirmUpdate(appSettingsForm.value)">
    <div>
        <div>
    <!-- Admin email -->
    <div class="py-2">
        <label for="email" class=" tracking-wide flex items-center space-x-2 text-gray-700 text-base font-bold mb-2"> <span>Admin Email</span>
          <div class="tooltip" data-tip="Add an admin email to recieve notification">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
              <path stroke-linecap="round" stroke-linejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z" />
            </svg>
            
          </div>
        </label>
     
        <div formArrayName="adminEmail" *ngFor="let inviteEmail of adminEmail.controls; let i=index">
        <div class="flex flex-col">
            <div class="">
            <input type="email"
            class="  appearance-none block input text-base py-[0.32rem] px-3 leading-[1.6] border-2 min-h-[auto] input-bordered rounded-md bg-white w-full  text-gray-700  focus:outline-none  focus:border-gray-500"
                [ngClass]="{
            'border-red-500': (inviteEmail.dirty || inviteEmail.touched) && inviteEmail.invalid
            }" placeholder="e.g. email@test.com" [formControlName]="i">

            </div>
            <div class="justify-start">
            <button (click)="removeAdminEmail(i)" class="mt-2 text-xs flex items-center space-x-2  font-semibold"><i class="material-icons text-sm">delete</i>Remove Email</button>
            </div>
        </div>
        </div>
        <div class="flex  w-full justify-end">
        <div class="">
            <a class="add-email-btn text-customTeal font-bold" (click)="addAdminEmail()">
            <i class="material-icons float-left mr-2">add_circle</i>Add Email</a>
        </div>
        </div>
    </div>
    <hr>
    <!-- Offerring -->
    <div class="flex flex-col my-4 space-y-4" *ngIf="!envService.fromFNLeaders && (model && model.serviceType != 'None')">
        <div class="w-full ">
        <div>
            <div class="text-base font-bold mb-1">What are you offering? <span class=" text-red-500">*</span></div>
            <p class="hidden md:block text-sm text-gray-500">Choose what you provide for your customers/users.
            You can choose both Product and Service or
            one of them.</p>
        </div>
        </div>

        <div class="w-full  flex flex-col md:flex-row md: justify-start">

        <div class="w-full md:w-3/12 flex">
            <div>
            <div class="form-control">
                <label class="label cursor-pointer">
                <input type="radio" class="radio checked:bg-customTeal" formControlName="serviceType"
                    value="Product" (change)="onOfferChange()" />
                <span class="label-text pl-2 font-bold ">Products</span>
                </label>
            </div>
            </div>
        </div>

        <div class="w-full md:w-3/12 flex">
            <div>
            <div class="form-control">
                <label class="label cursor-pointer">
                <input type="radio" class="radio checked:bg-customTeal" formControlName="serviceType"
                    value="Service" (change)="onOfferChange()" />
                <span class="label-text pl-2 font-bold ">Services</span>
                </label>
            </div>
            </div>
        </div>

        <div class="w-full md:w-3/12 flex">
            <div>
            <div class="form-control">
                <label class="label cursor-pointer">
                <input type="radio" class="radio checked:bg-customTeal" formControlName="serviceType"
                    value="Both" (change)="onOfferChange()" />
                <span class="label-text pl-2 font-bold ">Both</span>
                </label>
            </div>
            </div>
        </div>

        <div class="w-full md:w-3/12 flex">
            <div>
            <div class="form-control">
                <label class="label cursor-pointer">
                <input type="radio" class="radio checked:bg-customTeal" formControlName="serviceType"
                    value="None" (change)="onOfferChange()" />
                <span class="label-text pl-2 font-bold ">None</span>
                </label>
            </div>
            </div>
        </div>
        </div>

    </div>
    <hr />

    <!-- Products Category -->
    <!-- <div class="flex flex-col md:flex-row my-6" *ngIf="['Both','Product'].includes(serviceType.value)">
        <div class="w-full md:w-1/2">
        <div>
            <div class="text-sm font-bold mb-3">Will you be offering different Categories for your Products?
            </div>
            <p class="hidden md:block">Choose whether or not you provide different types of catagories for the
            products
            you provide for your customers/subscribers.</p>
        </div>
        </div>

        <div class="w-full md:w-1/2 flex ">
        <div>
            <div class="form-control">
            <label class="label cursor-pointer space-x-2">
                <span class="label-text text-sm pr-2 font-bold "
                [ngClass]="{'invisible text-gray-400': hasProductsCategory.value}">No</span>
                <input type="checkbox" class="toggle checked:bg-customTeal" checked
                formControlName="hasProductsCategory" />
                <span class="label-text text-sm pr-2 font-bold "
                [ngClass]="{'invisible text-customTeal': !hasProductsCategory.value}">Yes</span>
            </label>
            </div>
        </div>
        </div>
    </div>
    <hr /> -->
   
    <!-- Currency -->
    <div class="flex flex-col  my-4 space-y-2"
        *ngIf="(serviceType.value !='None' && serviceType.value !='') && !envService.fromFNLeaders">
        <div class="w-full ">
        <div>
            <div class="text-base font-bold mb-1">Currency?</div>
            <p class="hidden md:block text-sm text-gray-500">Choose the currency you will be using
            </p>
        </div>
        </div>

        <div class="w-full flex ">
        <!-- toggle -->
        <div>
            <div class="form-control">
            <label class="label cursor-pointer ">
                <select formControlName="currency" class="select select-bordered w-full border-2 bg-veryLightGray">
                <option disabled selected>Select Currency</option>
                <option value={{currency.id}} *ngFor="let currency of currencies">{{currency.name}}</option>
                </select>
            </label>
            </div>
        </div>

        </div>

    </div>
    <hr />
<div class="flex flex-wrap gap-4 mt-4">
      

    <!-- Appointments -->
       <div class=" flex flex-col w-full md:w-full bg-white shadow-md border border-solid rounded-md border-gray-200 px-4 py-4" *ngIf="!envService.fromFNLeaders ">
        <div class="w-full ">
        <div class="flex">
            <div class="text-base font-bold mb-4">Do you take appointments?<span class=" text-red-500">*</span></div>
            <span class="tooltip" data-tip="Please specify if you take appointments or not for your services.">
              <i class="material-icons">info</i>
            </span>
        </div>
        </div>

        <div class="w-full  flex ">

        <div>
            <div class="form-control">
            <!-- <label class="label cursor-pointer space-x-2">
                <span *ngIf="!takeAppointment.value" class="label-text text-sm pr-2 font-bold "
                [ngClass]="{' text-gray-400': takeAppointment.value}">No</span>

                <input type="checkbox" class="toggle checked:bg-customTeal" checked
                formControlName="takeAppointment" required (change)="addScheduleModal = true && takeAppointment.value" />
                <span *ngIf="takeAppointment.value" class="label-text text-sm pr-2 font-bold "
                [ngClass]="{' text-customTeal': !takeAppointment.value}">Yes</span>
            </label> -->
            <div class="w-full  flex flex-col md:flex-row md: justify-start">

              <div class="w-full md:w-1/2 flex">
                  <div>
                  <div class="form-control">
                      <label class="label cursor-pointer">
                      <input type="radio" class="radio checked:bg-customTeal" formControlName="takeAppointment"
                          value="yes"
                          (change)="addScheduleModal=true" />
                      <span class="label-text pl-2 font-bold ">Yes</span>
                      </label>
                  </div>
                  </div>
              </div>
      
              <div class="w-full md:w-1/2 flex">
                  <div>
                  <div class="form-control">
                      <label class="label cursor-pointer">
                      <input type="radio" class="radio checked:bg-customTeal" formControlName="takeAppointment"
                          value="no" />
                      <span class="label-text pl-2 font-bold ">No</span>
                      </label>
                  </div>
                  </div>
         </div>
      </div>

            <div *ngIf="takeAppointment.value == '' && !appSettingsForm.valid" class="text-xs italic text-red-600">
              you must select an option to proceed  
            </div>
            </div>

            

            <button
            *ngIf="takeAppointment.value == 'yes'"
            type="button"
            class="inline-block rounded border border-solid border-customTeal justify-end  text-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal  shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-brandcotext-customTeal-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-brandcotext-customTeal-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-brandcotext-customTeal-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"        
            data-te-ripple-color="light"
            (click)="addScheduleModal=true"
            >
            Update Schedule 
            </button>
          <!-- On first login setup -->
          <dialog id="my_modal_2" class="modal" [ngClass]="{'modal-open': addScheduleModal && takeAppointment.value=='yes'}" >
            <form method="dialog" class="modal-box rounded-md shadow-md max-w-5xl">


              <app-add-schedule *ngIf="takeAppointment.value=='yes'" (createClicked)="createSchedule($event)" (updateClicked)="updateSchedule($event)" (defaultScheduleDisplayed)="initializeSchedule($event)"></app-add-schedule>


              <div class="modal-action ">
                <!-- if there is a button in form, it will close the modal -->
              <div class="flex space-x-4 w-full justify-end">
                  <button
                  type="button"
                  class="inline-block rounded justify-end bg-customTeal text-white px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-brandcotext-customTeal-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-brandcotext-customTeal-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-brandcotext-customTeal-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"        
                  data-te-ripple-color="light"
                  (click)="addScheduleModal=false"
                  >
                  Save
                  </button>
          
              </div>
              </div>
            </form>
          </dialog>

        </div>
        </div>

    </div>

  </div>

  <!-- <div *ngIf="!verifyUsers" class="flex flex-col w-full md:w-[536px] bg-white shadow-md border border-solid rounded-md border-gray-200 px-4 py-4 my-6">
    <div class="w-full ">
        <div class="text-base font-bold mb-4">Do you want to sent them an SMS with a code?<span class="text-red-500">*</span></div>
    </div>

    <div class="w-full flex gap-x-10 flex-wrap">

    <div class="flex">
        <div>
        <div class="form-control">
            <label class="label cursor-pointer">
            <input type="radio" class="radio checked:bg-customTeal" formControlName="verifySubscribers"
                value="SMS" (click)="smsClicked = true"/>
            <span class="label-text pl-2 font-bold ">Yes</span>
            </label>
        </div>
        </div>
    </div>

    <div class="flex">
        <div>
        <div class="form-control">
            <label class="label cursor-pointer">
            <input type="radio" class="radio checked:bg-customTeal" formControlName="verifySubscribers"
                value="noVerification" />
            <span class="label-text pl-2 font-bold ">No</span>
            </label>
        </div>
        </div>
    </div>
</div>

</div> -->

 <!--  <div *ngIf="(model && model.sendSMS) " class="flex flex-col w-full md:w-[536px] bg-white shadow-md border border-solid rounded-md border-gray-200 px-4 py-4 my-6">
    <div >
        <div class="w-full ">
            <div class="text-base font-bold mb-4">Do you want to send SMS to ur clients?</div>
        </div>
    </div>
    
    <div *ngIf="sendSMS.value || model.sendSMS">
        <div *ngIf="((model && model.availablePhone === '') || (model && (!model.availablePhone))) || toBeChanged">


              <div class="flex space-x-8 w-full align-middle items-baseline">
              <div class="form-control py-2">
                <label class="label cursor-pointer space-x-2">
                  <input type="radio" formControlName="general" class="radio checked:bg-customTeal" 
                    (change)="toggleGenericPhone()" value="existingNumber" required />
                  <span class="label-text">Use one of our existing Numbers</span>
                </label>
              </div>

              <div class="form-control py-2">
                <label class="label cursor-pointer space-x-2">
                  <input type="radio"  formControlName="general" class="radio checked:bg-customTeal" 
                    (change)="toggleGenericPhone()" value="buyNum" required/>
                  <span class="label-text">Buy your own number</span>
                </label>
              </div>
            </div>

             <div class="mt-4 mb-2" *ngIf="(!generic && general.value == 'buyNum') && subscribe">

              <div class="flex flex-col gap-2  w-full  ">
              <div class="w-full flex space-x-4">
                <select class="select select-bordered w-full md:w-1/2 mt-2 bg-veryLightGray"
                (change)="selectCountry($event.target.value)" required>
                <option disabled selected>Choose a Country</option>
                <option value="US"> United States (US) </option>
                <option value="CA"> Canada (CA) </option>
              </select>

              <select class="select select-bordered w-full md:w-1/2 mt-2 bg-veryLightGray"
                (change)="selectPreference($event.target.value)" required>
                <option disabled selected>Choose to Filter</option>
                <option value="number"> Number </option>
                <option value="locality"> Locality </option>
              </select>
              </div>
                <div class="w-full">
                  <div class="w-full md:w-1/2" *ngIf="selectedPreference == 'locality'" >
                    <select class="select select-bordered w-full md:w-full mt-2  bg-veryLightGray"
                      *ngIf="(selectedCountry == 'CA') " (change)="selectProvince($event.target.value)" required>
                      <option disabled selected>Provinces</option>
                      <option value={{pro.code}} *ngFor="let pro of provinces"> {{pro.name}} , {{pro.code}}</option>
                    </select>
                  </div>

                  <div class="w-full md:w-1/2" *ngIf="selectedPreference == 'locality'">
                    <select class="select select-bordered w-full md:w-full mt-2 bg-veryLightGray"
                      *ngIf="(selectedCountry == 'US')" (change)="selectState($event.target.value)" required>
                      <option disabled selected>States</option>
                      <option value={{state.code}} *ngFor="let state of states"> {{state.name}} , {{state.code}}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="w-full md:w-1/2 flex flex-col space-y-1" *ngIf="selectedPreference == 'number' && selectedCountry != '' ">
                  <span class="font-bold text-sm mb-1">Area Code</span>
                <input 
                    class="  appearance-none block input text-base py-[0.32rem] px-3 leading-[1.6] border-2 min-h-[auto] input-bordered rounded-md bg-white w-full  text-gray-700  focus:outline-none  focus:border-gray-500"

                  id="Title" type="text" placeholder="Area Code" formControlName="localityNumber">
                </div>

              </div>


              <div class="flex  mt-2 " *ngIf="(selectedState || selectedProvince) || selectedPreference == 'number'">
              <span 
              class="flex space-x-1 items-center rounded border-2 text-customTeal border-solid border-customTeal px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:border-primary-accent-100 hover:bg-neutral-500 hover:bg-opacity-10 focus:border-primary-accent-100 focus:outline-none focus:ring-0 active:border-primary-accent-200 dark:text-primary-100 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
              (click)="filter()">Search</span></div>

              <div class="my-2" *ngIf=" availablePhones && (availablePhones.length != 0)">
              <select formControlName="availablePhone" class="select select-bordered w-full md:w-1/2 md:mt-2 mt-0 bg-veryLightGray"
            (change)="selectPhone($event.target.value)" required>
             <option disabled selected>Phone Numbers</option>
            <option value={{availPhone}} *ngFor="let availPhone of availablePhones">
              {{availPhone}}
            </option> 
          </select>
          </div>

          <button type="button" class="flex  mt-2 " *ngIf="selectedPhone && (availablePhones || activePhones)"><span class="inline-block rounded bg-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            (click)="buy()">Buy this number</span></button>

            </div>  
            
            <div *ngIf="general.value === 'buyNum'" class="px-4 py-4 bg-orange-200 flex space-x-2">
              <i class="material-icons">warning</i>You have to have an active subscription to buy your own number. <span class="text-blue-500 cursor-pointer" (click)="subscribe = true">Subscribe</span>
            </div>


            <div *ngIf="availablePhone && general.value ==='existingNumber'" class="flex space-y-2  md:w-1/2 flex-col">
              <label for="availablePhone" class="text-xs text-gray-500 font-bold ">Select a PhoneNumber <span
                  class="text-red-500">*</span></label>
              <select formControlName="availablePhone" class="select select-bordered w-full  bg-veryLightGray"
                (change)="selectPhone($event.target.value)">
                <option disabled selected>PhoneNumbers</option>
                <option value={{phone}} 
                  *ngFor="let phone of activePhones">
                  {{phone}}
                </option>
              </select>
              <div *ngIf="(availablePhone.dirty || availablePhone.touched) && availablePhone.invalid">
                <small *ngIf="availablePhone.errors.required" class="text-red-500"> Phone Number is required.
                </small>
              </div>
            </div>
            <div class="flex  mt-2 " *ngIf="generic &&  model.availablePhone != ''"><span class="bg-customTeal text-white font-bold  px-4 py-2 cursor-pointer"  (click)="use()">Use this Number</span></div>
          </div>

          <div class="" *ngIf = "((model && model.availablePhone)) && !toBeChanged">
            <span class="font-bold text-sm mb-4">Selected Phone Number</span>
            <input type="text" formControlName="availablePhone" [readonly]="model.availablePhone !== ''"
            class="mt-4 w-full md:w-1/2 appearance-none block bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 ">
            <div class=" flex-col md:flex-row flex md:justify-between ">
            <div class="flex  my-2 "><span class="bg-customTeal text-white font-bold  px-4 py-2 cursor-pointer"  (click)="change()">Change Phone Number</span></div>
            <div class="flex  mt-2 md:mx-4 " *ngIf="fetched && fetched.includes(model.availablePhone)"><span class="bg-red-500 text-white font-bold  px-4 py-2 cursor-pointer"  (click)="release()">Release the Number</span></div>

            </div>
          </div>

        </div> 
</div>-->

</div>
    <div class="w-full flex justify-end pt-6"><button 
      type="submit"
      [disabled]="!appSettingsForm.valid "
        class="inline-block rounded  px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
        data-te-ripple-init
        data-te-ripple-color="light"
              [ngClass]="{'bg-[#cccccc] text-[#666666]': !appSettingsForm.valid, 'bg-customTeal': appSettingsForm.valid }"
        >Save</button></div>

    </div>
</form>

</div>



<!-- toast -->
<app-alert class="fixed top-0 flex  w-full" [toastType]="toastType" [toastMessage]="toastMessage"
  [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>

<!-- Edit Modal -->
<app-edit-confirmation *ngIf="editModalOpen" (cancelClicked)=closeEditModal() (saveBtnClicked)=saveUpdate()
  editedFromComponent='App Setup'></app-edit-confirmation>

<!-- Delete Modal -->
<app-delete-confirmation *ngIf="deleteModalOpen" (deleteClicked)=deleteItem()
  (cancelDeleteClicked)="deleteModalOpen=false" deletedFromComponent='Section'></app-delete-confirmation>
