import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { ClientFormService } from 'src/app/services/firebase/client-form.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-client-form',
  templateUrl: './edit-client-form.component.html',
  styleUrls: ['./edit-client-form.component.css']
})
export class EditClientFormComponent implements OnInit {

  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  loggedUser: any;
  model: any;
  surveyForm: UntypedFormGroup;
  questionForm: UntypedFormGroup;
  questionArray: UntypedFormArray;
  answersArray: UntypedFormArray;

  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;

  currentUser: any;
  role: any;
  isAdmin: boolean;

  // answers = [];
  questionNumber = 1;
  step1 = true;
  step2 = false;
  step3 = false;
  showMultiAnswer = false;
  //answerTypes: any = ['Multiple Choice', 'Open Ended Answer', 'Scale', 'Checkboxes', 'Dropdown'];
  answerTypes: any = [
    { 'type': 'Multiple Choice', 'helpText': 'Displays a list of answers the client can choose from' },
    { 'type': 'Open Ended Answer', 'helpText': 'Allows client to answer in open text format' },
    // { 'type': 'Scale', 'helpText': 'Displays a scale from any range from 0 to 10' },
    { 'type': 'Date', 'helpText': 'Displays a calander for the client to choose from'}];
  scales = ['2', '3', '4', '5', '6', '7', '8', '9', '10'];

  numberOfAnswers: any = ['2', '3', '4'];
  showSurveyResult = false;
  ShowScaleLimits = false;
  createPoll = false;
  
  skillsForm: UntypedFormGroup;
  formExists: boolean;
  //form: any;

  location: any;

  clientFormGlobal: any;

  get skills() : UntypedFormArray {return this.skillsForm.get("skills") as UntypedFormArray }
  //get questions() : FormArray  { return this.skillsForm.get('skills') as FormArray; }

  constructor(private fb: UntypedFormBuilder,
    private clientFormService: ClientFormService,
    private authService: AuthService) { }

  ngOnInit(): void {
    this.skillsForm = this.fb.group({
      skills: this.fb.array([]) ,
    }); 

    this.currentUser = this.authService.getGlobalUser();
    if (this.currentUser){
      this.role = this.currentUser.role;
      if (this.role === 'Admin'){
        this.isAdmin = true;
      }
  }

    this.clientFormGlobal = this.clientFormService.getFormList().valueChanges().subscribe(form => {
      this.model = form[0];
      this.model.questions.forEach(question => {
        this.addQuestionFromModel(question);
      });

      this.clientFormGlobal.unsubscribe();
    });


  }


  
  getAnswers(index) {
    const questions = this.skillsForm.get('skills') as UntypedFormArray;
    return questions.controls[index].get('answers') as UntypedFormArray;
  }
  
  
  addAnswers() {
    return this.fb.group({
      answer: ['', [Validators.required]]
    });
  }
  
  onAddAnswer(index) {
    const answers = this.getAnswers(index);
    answers.push(this.addAnswers());
  }
  
  getScales(index) {
    const questions = this.skillsForm.get('skills') as UntypedFormArray;
    return questions.controls[index].get('scales');
  }
  
    newSkill(): UntypedFormGroup {
      return this.fb.group({
        question: ['', Validators.required],
        answerType: [''],
      })
   }
  
   newChoice(): UntypedFormGroup{
    return this.fb.group({
  
    })
   }
   addAnswersFromModel(value) {
    return this.fb.group({
      answer: [value, [Validators.required]]
    });
  }
   addQuestionFromModel(question: any): void {
    this.questionNumber += 1;
    this.questionArray = this.skillsForm.get('skills') as UntypedFormArray;
    
    this.questionArray.push(this.createQuestionFromModel(question));
  }
   createQuestionFromModel(question: any) {

    const scales =  this.fb.group({
      start: [""],
      startLabel: [],
      end: [""],
      endLabel: []
    });
    
    let answers = this.fb.array([]);
    if(question.answerType === 'Multiple Choice'){
      question.answers.forEach(a => {
        answers.push(this.addAnswersFromModel(a.answer));
      });
    }else if(question.answerType === 'Scale'){
      scales.get('start').patchValue(question.scales.start);
      scales.get('startLabel').patchValue(question.scales.startLabel);
      scales.get('end').patchValue(question.scales.end);
      scales.get('endLabel').patchValue(question.scales.endLabel);
    }
   
    return this.fb.group({
      question: [question.question, [Validators.required]],
      answerType: [question.answerType, [Validators.required]],
      allowMultipleAnswers: [question.allowMultipleAnswers, [Validators.required]],
      answers: answers,
      scales : scales,
    });
  }
  
  
   addQuestion() {
      this.questionNumber += 1;
      this.questionArray = this.skillsForm.get('skills') as UntypedFormArray;
      this.skills.push(this.createQuestion());
   }
  
   createQuestion() {
   return this.fb.group({
      question: ['', [Validators.required]],
      answerType: ['', [Validators.required]],
      allowMultipleAnswers: [false, [Validators.required]],
      answers: this.fb.array([]),
      scales: this.fb.group({
        start: [''],
        startLabel: [],
        end: [''],
        endLabel: []
      }),
    });
  }
  
  
  removeSkill(i:number) {
    this.skills.removeAt(i);
  }
  
  removeAnswer(question_index, answer_index) {
    const answers = this.getAnswers(question_index);
    answers.removeAt(answer_index);
  }
  
  removeFile(index: any) {
    this.questionArray = this.skillsForm.get('skills') as UntypedFormArray;
    this.questionArray.removeAt(index);
  }
  
  onSubmit() {
    this.toast({ html: 'Client Form sucessfully added', classes: 'green', type: 'success' });
  } 
  
  answerTypeChange(e: any, index: any) {
    this.resetControls(index);
    const answers = this.getAnswers(index);
    if (e === 'Multiple Choice') {
      if (answers.length < 2) {
        answers.push(this.addAnswers());
        answers.push(this.addAnswers());
      }
    } else if (e === 'Scale') {
      this.getScales(index).get('start').patchValue('1');
      this.getScales(index).get('end').patchValue('10');
    } else {
    }
  
    // setTimeout(() => {
    //   $('select').formSelect();
    // }, 25);
  
  }
  resetControls(index) {
    const answers = this.getAnswers(index);
  
    while (answers.length !== 0) {
      answers.removeAt(0);
    }
    const scales = this.getScales(index);
    //answers.reset();
    answers.clearValidators();
    answers.updateValueAndValidity();
    scales.reset();
    scales.clearValidators();
    scales.updateValueAndValidity();
  }
  
  submitForm(value: any) {
    this.model = {
      id: this.model.id,

      numberOfQuestions: 0,
      questions: [],
      adminID: this.currentUser.id,
      adminName: this.currentUser.FirstName + " " + this.currentUser.LastName,
      datePosted: new Date(),
    };
  }
  
  submitQuestions(value: any) {
    let qNumber = 0;
    this.model = {
      id: this.model.id,
      // name: value.surveyName,
      // type: value.type,
      numberOfQuestions: this.model.numberOfQuestions,
      // startDate: value.startDate,
      // endDate: value.endDate,
      // showResult: value.showResult,
      // showResultTo: value.showResultTo,
      questions: [],
      adminID: this.currentUser.uid,
      adminName: this.currentUser.FirstName + " " + this.currentUser.LastName,
      datePosted: new Date(),
    };
  
    value.skills.forEach(element => {
      qNumber += 1;
      const question = {
        questionNumber: qNumber,
        question: element.question,
        answerType: element.answerType,
        allowMultipleAnswers: element.allowMultipleAnswers,
        answers: element.answers,
        scales: element.scales
      };
  
      if (element.answerType === 'Scale') {
        for (var i = element.scales.start; i <= element.scales.end; i++) {
          element.answers.push({ 'answer': i.toString() });
        }
      }
  
      this.model.questions.push(question);
  
    });
  
    this.model.numberOfQuestions = this.model.questions.length;
    this.clientFormService.updateForm(this.model).then(x => {
      this.toast({ html: 'The Client Form is updated!', classes: 'green' });
    });
  }
  
  
  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
    }, 3000);
  }
  
  
}
