import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'app-client-feed',
  templateUrl: './client-feed.component.html',
//   styleUrls: ['./view-event.component.css']
})
export class ClientFeedComponent implements OnInit {



  constructor() {

  }

  ngOnInit(): void {

    }
}
