import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { UserNotesService } from 'src/app/services/firebase/user-notes.service';
import { UserService } from 'src/app/services/firebase/user.service';
import { HelperService } from 'src/app/services/helper/helper';

@Component({
  selector: 'app-reminders',
  templateUrl: './reminders.component.html',
  styleUrls: ['./reminders.component.css']
})
export class RemindersComponent implements OnInit {

  notes: any;
  userNotes
  noteModal
  id
  users
  userLastName
  userName
  notesForm: UntypedFormGroup;
  declinedForm: UntypedFormGroup;
  totalSubLength = 0;
  subscriberId: any;
  reminder: any;
  reminderTime: any;
  times = ['08:00 AM','08:30 AM', '09:00 AM','09:30 AM', '10:00 AM','10:30 AM', '11:00 AM', 
  '11:30 AM', '12:00 PM','12:30 PM','01:00 PM','01:30 PM', '02:00 PM','02:30 PM', '03:00 PM', 
  '03:30 PM', '04:00 PM','04:30 PM', '05:00 PM', '05:30 PM', '06:00 PM','06:30 PM', '07:00 PM',
  '07:30 PM', '08:00 PM','08:30 PM', '09:00 PM', '09:30 PM', '10:00 PM','10:30 PM', '11:00 PM',
  '11:30 PM','12:00 AM','12:30 AM', '01:30 AM', '02:00 AM', '02:30 AM', '03:00 AM', '03:30 AM',
   '04:00 AM','04:30 AM', '05:00 AM',  '05:30 AM', '06:00 AM','06:30 AM', '07:00 AM',  '07:30 AM']
  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  constructor(
    private fb: UntypedFormBuilder,
    private noteService: UserNotesService,
    private helperService: HelperService,
    private userService: UserService, 
    private userNoteService: UserNotesService,
    private authService: AuthService) {
      this.id = this.authService.getCurrentUser().uid;
     }

  ngOnInit(): void {
    this.notesForm = this.fb.group({
      note: ['', [Validators.required]],
      subscriber:['', [Validators.required]],
      ReminderDate: [''],
      ReminderTime: ['']
    });

    this.declinedForm = this.fb.group({
      reason: ['', [Validators.required]],
    });
    this.userService.getAllUsers().valueChanges().subscribe( (users:any) => {
      this.totalSubLength = users.length;
      this.users = users;
    })
    this.userNoteService.getNotes().valueChanges().subscribe((notes:any) => {
      this.notes = notes;
      this.reminder = notes.reminder;
      this.reminderTime = notes.reminderTime;
      
    });
  }
  get note() { return this.notesForm.get('note'); }
  get subscriber() {return this.notesForm.get('subscriber')}
  get ReminderDate() { return this.notesForm.get('ReminderDate'); }
  get ReminderTime() { return this.notesForm.get('ReminderTime'); }
  get reason() { return this.declinedForm.get('reason'); }

  addNote(){
    this.noteModal = true;
  }
  closeNote(){
    this.noteModal = false;
  }
  setStartTime(item){
    this.ReminderTime.patchValue(item);
  }
  userNote(Message : any){
    let message: string[] = [];
    message.push(Message.note);
    this.userNotes = message; 
    const timestamp = new Date();

    this.noteService.saveNotes({
      id:'',
      Message: Message.note,
      timestamp,
      userId: this.subscriberId, 
      // subscriberName: this.userName + ' ' + this.userLastName,
      subscriberName : Message.subscriber,
      reminder: Message.ReminderDate,
      noteMadeBy: this.id,
      archived : false,
      reminderTime: this.convertTime12to24(Message.ReminderTime),
    }).then((res)=>{
      this.toast({ html: 'Note successfully saved', classes: 'green', type: 'success' });
      this.closeNote()
      
    });

    this.notesForm.reset();
  }
  convertTime12to24 = time12h => {
    const [time, modifier] = time12h.split(" ");
   
    let [hours, minutes] = time.split(":");
   
    if (hours === "12") {
      hours = "00";
    }
   
    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
      if(Math.floor(hours / 10) == 0){
        hours = '0'+ hours;  
      }
    }
    return `${hours}:${minutes}`;
  };

  truncateHTML(text: string, limit: string): string {
    return this.helperService.truncateHTML(text, limit);
  }
  setSubscriber(sub){
    this.subscriber.patchValue(sub.GivenName + ' ' + sub.LastName);
    this.subscriberId = sub.uid;
  }
  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
    }, 2000);
  }
}
