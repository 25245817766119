<div class="flex">

  <!-- <div class="hidden lg:block shadow-lg"
    [ngClass]="{'lg:w-2/12': !minimizedMenu, 'lg:w-28': minimizedMenu, 'bg-white': theme =='light', 'bg-customTeal': theme=='dark'}">
    <div *ngIf="!minimizedMenu" class="hidden overflow-y-auto   max-h-screen fixed w-2/12 md:block scrollbar">
      <div class="pt-10 px-4 pb-2 flex items-center space-x-4 ">
        <img *ngIf="appLogo" [src]="appLogo" class="object-cover h-10 rounded-sm shadow-sm" alt="Logo">
        <p class="w-3/5 text-xl font-bold "> {{appName1}} </p>
      </div>
      <div class="w-full border-b-2 pb-2 border-solid border-gray-100 p-1">
      </div>
      <div (click)="toggleMinimizeMenu()" class="float-right cursor-pointer w-7 h-7">
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10">
          <rect x="6.42" width="1.05" height="10" style="fill:#231f20" />
          <polygon
            points="2.53 4.99 4.21 3.31 4.72 3.81 3.87 4.66 5.3 4.66 5.3 5.37 3.9 5.37 4.72 6.19 4.21 6.69 2.53 5.01 2.54 5 2.53 4.99"
            style="fill:#231f20" />
          <rect width="10" height="10" style="fill:none" />
        </svg>
      </div>
      <ng-container *ngTemplateOutlet="menu; context: {onMobile:false, minimizedMenu:false}">
      </ng-container>

    </div>

    <div *ngIf="minimizedMenu" class="hidden overflow-y-auto  max-h-screen fixed md:block scrollbar">
      <ul class="menu py-4 overflow-y-auto">
        <div class="pt-2 flex w-full mx-auto items-center justify-center"
          [ngClass]="{'w-24': minimizedMenu, 'w-full': !minimizedMenu}">
          <img loading="lazy" *ngIf="appLogo" [src]="appLogo" class="object-cover w-full h-12" alt="Logo">
        </div>
        <li>
          <a (click)="toggleMinimizeMenu()" class="float-right ">
            <div class="w-10 h-10">
              <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10">
                <rect x="2.53" width="1.05" height="10" style="fill:#231f20" />
                <polygon
                  points="7.47 4.99 5.79 3.31 5.28 3.81 6.13 4.66 4.7 4.66 4.7 5.37 6.1 5.37 5.28 6.19 5.79 6.69 7.47 5.01 7.46 5 7.47 4.99"
                  style="fill:#231f20" />
                <rect width="10" height="10" style="fill:none" />
              </svg>
            </div>
          </a>
        </li>
        <ng-container *ngTemplateOutlet="menu; context: {onMobile:false, minimizedMenu:true}">
        </ng-container>

        
      </ul>
    </div>
    <div class="p-2 fixed bottom-5 cursor-pointer" (click)="back()">
      <div *ngIf="minimizedMenu">
        <i class="material-icons mr-3 ">arrow_back</i>
      </div>
      <div *ngIf="!minimizedMenu">
        <i class="material-icons mr-3 float-left">arrow_back</i>Back
      </div>

    </div>
  </div> -->

  <!-- <div class="w-full bg-gray-100 min-h-screen m-0 overflow-hidden" [ngClass]="{'lg:w-10/12': !minimizedMenu}"> -->

  <div class="w-full bg-gray-100 min-h-screen m-0 overflow-hidden">
    <div class="w-full navbar md:bg-transparent md:shadow-none bg-white shadow-md ">
      <div class="flex-none lg:hidden" (click)="toggleSideBar()">
        <label for="my-drawer-3" class="btn btn-square btn-ghost">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
            class="inline-block w-6 h-6 stroke-current">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
          </svg>
        </label>
      </div>
      <div class="flex-1 px-2 mx-2"></div>
      <div class="flex-none pt-2 px-4 ">
        <a [routerLink]="['/user/homepage']" class="px-3 md:flex items-center space-x-2  hidden "> 
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
          <path stroke-linecap="round" stroke-linejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
        </svg>
        <p class="text-darkGray hover:text-customTeal font-bold text-base ">Home</p></a>
        <a [routerLink]="['/user/cart']" class="px-3 font-bold hidden lg:block" *ngIf="shoppingCart && (cartItems.length != 0)"> <i
            class="fa fa-shopping-cart text-2xl mr-2"></i>Cart</a>
        <a (click)="openModal()" class="px-3 md:flex items-center space-x-2  hidden"> 
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="m6.75 7.5 3 2.25-3 2.25m4.5 0h3m-9 8.25h13.5A2.25 2.25 0 0 0 21 18V6a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 6v12a2.25 2.25 0 0 0 2.25 2.25Z" />
          </svg>          
          <p class="text-darkGray hover:text-customTeal font-bold text-base">Give Us Feedback</p></a>
        <a (click)="getNavigateVerified()" class="px-3 md:flex items-center space-x-2  hidden "
          *ngIf="getVerified || (verification && verification.reapply)"> 
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z" />
          </svg>
          
            <p class="text-darkGray hover:text-customTeal font-bold text-base">Get Verified</p></a>
        <p class="px-3 flex font-bold items-center text-yellow-500"
          *ngIf="isPending && (verification && !verification.reapply)"> <i
            class="float-left material-icons text-2xl mr-2 font-bold text-yellow-500">schedule</i><span>Pending</span>
        </p>
        <p class="px-3 flex font-bold items-center text-green-500"
          *ngIf="isAccepted && (verification && !verification.reapply)"> <i
            class="float-left material-icons text-2xl mr-2 font-bold text-green-500">schedule</i><span>Verified</span>
        </p>
        <p class="px-3 flexfont-bold items-center text-red-500"
          *ngIf="isDeclined && (verification && !verification.reapply)"> <i
            class="material-icons text-2xl mr-2 font-bold text-red-500">schedule</i><span>Declined</span></p>

        <div *ngIf="user" class="dropdown dropdown-end">
          <label tabindex="0" class="btn btn-ghost btn-circle avatar">
            <div *ngIf="user.profileImgLink" class="w-12 rounded-full cursor-pointer">
              <img loading="lazy" [src]="user.profileImgLink" class="rounded-full" />
            </div>
            <div *ngIf="!user.profileImgLink" class="avatar placeholder">
              <div class="bg-white text-neutral-content rounded-full w-11">
                <span class="text-3xl">{{user.GivenName[0]}}</span>
              </div>
            </div>
          </label>
          <ul tabindex="0" class="menu menu-compact dropdown-content mt-3 p-2 z-50 shadow bg-white rounded-box w-52">
            <div class="flex justify-end mr-2"><a onclick="document.activeElement.blur()"
                [routerLink]="['/user/edit-profile',id]"><i class="material-icons w-4">edit</i></a></div>
            <div class="flex justify-center items-center">
              <img loading="lazy" [src]="user.profileImgLink" class=" w-16 h-16 md:w-24 md:h-24 rounded-full">
            </div>
            <div>
              <p class="font-bold flex justify-center mt-2 mb-4">{{user.GivenName}} {{user.LastName}}</p>
            </div>

            <li><a (click)="signOut()" class="flex justify-center bg-gray-100 text-md"><i
                  class="material-icons">logout</i>Log out</a></li>
          </ul>
        </div>
      </div>
    </div>
    <hr>
    <div class=" pl-4 py-2">
   
      <button [ngClass]="{'hidden': pageName == 'home' || pageName == 'subscriptionSuccess' || pageName == 'subscriptionFail'}" class="flex items-center" (click)="back()" >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="w-4 h-4" ><path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 278.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg>
        <p class="font-bold ">Back</p>
      </button>
     
    </div>
    <router-outlet></router-outlet>
  </div>
</div>


<!-- Mobile sidebar -->
<div>
  <div class="z-10 lg:hidden sidebar fixed top-0 bottom-0 lg:left-0  duration-1000
    p-2 w-[300px] overflow-y-auto bg-white shadow h-screen" [ngClass]="{'left-[-300px]':!openSidebar}">
    <div (click)="toggleSideBar()">
      <i class="material-icons float-right">close</i>
    </div>
    <div class="pt-2 flex w-8/12 mx-auto mb-6 items-center ">
      <img loading="lazy" *ngIf="appLogo" [src]="appLogo" class="object-cover w-full h-16" alt="Logo">
    </div>

    <ng-container *ngTemplateOutlet="menu; context: {onMobile:true, minimizedMenu:false}">
     
    </ng-container>

     <ng-template class="mt-4 pt-6" #menu let-onMobile="onMobile" let-minimizedMenu="minimizedMenu">

      <div [routerLink]="['/user/homepage']" class="px-3 py-2 font-bold"  [ngClass]="{'bg-customTeal rounded-md text-white hover:bg-customTeal': router.url == '/user/homepage'}"> <i class="fa fa-home text-2xl mr-2"></i>Home</div>

        <div class="hover:bg-gray-100 mt-4" [ngClass]="{'bg-customTeal rounded-md text-white hover:bg-customTeal': router.url == '/user/cart'}">
            <a [routerLink]="['/user/cart']" class="px-3 font-bold" *ngIf="shoppingCart && (cartItems.length != 0)"> <i
              class="fa fa-shopping-cart text-2xl mr-2"></i>Cart</a>
        </div>

        <div class="hover:bg-gray-100 mt-4" [ngClass]="{'bg-customTeal rounded-md text-white hover:bg-customTeal': feedbackModal}">
          <a (click)="openModal()" class="px-3 flex font-bold items-center cursor-pointer"> <i
            class="float-left material-icons text-2xl mr-2">help</i><span>Give Us Feedback</span></a>
        </div>

      </ng-template>
  </div>
</div>


<!-- <ng-template #menu let-onMobile="onMobile" let-minimizedMenu="minimizedMenu">
  <div class="px-2" [ngClass]="{'w-24': minimizedMenu}">
    <div  *ngIf="!hasAnApp" [ngClass]="{'bg-customTeal rounded-md text-white hover:bg-customTeal': router.url == '/user/homepage' }"
    class="md:pl-2 mt-3 text-customTeal pt-4 md:py-2 md:mx-1 hover:bg-gray-50 hover:rounded-sm">
      <a [routerLink]="['/user/homepage']"  (click)="closeSideBar()" class="flex space-x-3 items-center" >
        <svg xmlns="http://www.w3.org/2000/svg" height="1em" class="w-4 h-4 " viewBox="0 0 576 512">
          <path d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z"/>
        </svg>
        <span *ngIf="!minimizedMenu" class="text-base font-bold" 
        [ngClass]="{' text-white ': router.url == '/user/homepage', ' text-gray-600':router.url != '/user/homepage'}">Homepage</span>
      </a>
    </div>
    <div>
      <div *ngIf="hasAnApp" class="hover:bg-gray-100" [ngClass]="{'bg-customTeal rounded-md text-white hover:bg-customTeal': router.url == '/user/manage-app/' + userId}">
        <a [routerLink]="['/user/manage-app',userId]" (click)="closeSideBar()" class="flex items-center p-2 text-base font-normal rounded-lg ">
          <i class="material-icons mr-4 w-5 h-5 float-left text-xl" [ngClass]="{' text-white ': router.url == '/user/manage-app/' + userId, ' text-gray-400':router.url != '/user/manage-app/'+ userId }">apps</i>
          <span *ngIf="!minimizedMenu" class="text-base  font-semibold" [ngClass]="{' text-white ': router.url == '/user/settings', ' text-gray-600':router.url != '/user/settings'}" [ngClass]="{' text-white ': router.url == '/user/manage-app/' + userId, ' text-gray-600':router.url != '/user/manage-app/' + userId}">Manage My Apps</span>
        </a>
      </div>

      <div *ngIf="currentUser && currentUser.stripeCustomerID"  class="bg-customTeal rounded-md text-white hover:bg-customTeal">
        <a  (click)="ManageSubscription()" class="flex items-center p-2 text-base font-normal rounded-lg ">
          <i class="material-icons mr-4 w-5 h-5 float-left text-xl" >apps</i>
          <span *ngIf="!minimizedMenu" class="text-base  font-semibold" class="text-white "> My Subscriptions</span>
        </a>
      </div>
      


      <div class="hover:bg-gray-100" [ngClass]="{'bg-customTeal rounded-md text-white hover:bg-customTeal': router.url == '/user/edit-clientInfo/' + userId}">
        <a [routerLink]="['/user/edit-clientInfo',userId]" (click)="closeSideBar()" class="flex items-center p-2 text-base font-normal rounded-lg ">
          <i class="material-icons mr-4 w-5 h-5 float-left text-xl" [ngClass]="{' text-white ': router.url == '/user/edit-clientInfo/' + userId, ' text-gray-400':router.url != '/user/edit-clientInfo/'+ userId }">account_circle</i>
          <span *ngIf="!minimizedMenu" class="text-base font-semibold" [ngClass]="{' text-white ': router.url == '/user/edit-clientInfo/' + userId, ' text-gray-600':router.url != '/user/edit-clientInfo/' + userId}">My Account</span>
        </a>
      </div>
    
      
      <ng-container *ngIf="model && model.appSections">
        <ng-container *ngFor="let section of model.appSections">
          <ng-container *ngIf="section.isEnabled">

            <div *ngIf="section.originalName=='Events'">
              <div class="hover:bg-gray-100" [ngClass]="{'bg-customTeal rounded-md text-white hover:bg-customTeal': router.url == '/user/events' }">
                <a [routerLink]="['/user/events']" (click)="closeSideBar()" class="flex items-center p-2 text-base font-normal rounded-lg ">
                  <i class="material-icons mr-2 w-5 h-5 text-xl float-left" [ngClass]="{' text-white ': router.url == '/user/events', ' text-gray-400':router.url != '/user/events' }">event</i>
                  <span  *ngIf="!minimizedMenu" class="text-base  font-semibold" [ngClass]="{' text-white ': router.url == '/user/events', ' text-gray-600':router.url != '/user/events'}">{{section.name | titlecase}}</span>
                </a>
              </div>
            </div>
            <div *ngIf="section.originalName=='Blogs'">
              <div class="hover:bg-gray-100" [ngClass]="{'bg-customTeal rounded-md text-white hover:bg-customTeal': router.url == '/user/blog' }">
                <a [routerLink]="['/user/blog']" (click)="closeSideBar()" class="flex items-center p-2 text-base font-normal rounded-lg ">
                  <i class="material-icons mr-2 w-5 h-5 text-xl float-left" [ngClass]="{' text-white ': router.url == '/user/blog', ' text-gray-400':router.url != '/user/blog' }">feed</i>
                  <span  *ngIf="!minimizedMenu" class="text-base  font-semibold" [ngClass]="{' text-white ': router.url == '/user/blog', ' text-gray-600':router.url != '/user/blog'}">{{section.name | titlecase}}</span>
                </a>
              </div>
            </div>
            <div *ngIf="section.originalName=='Services' && !envService.isNationApp && !envService.fromFNLeaders">
              <div class="hover:bg-gray-100" [ngClass]="{'bg-customTeal rounded-md text-white hover:bg-customTeal': router.url == '/user/services' }">
                <a (click)="[redirectToServices(),closeSideBar()]" class="flex items-center p-2 text-base font-normal rounded-lg ">
                  <i class="material-icons mr-2 w-5 h-5 text-xl float-left" [ngClass]="{' text-white ': router.url == '/user/services', ' text-gray-400':router.url != '/user/services' }">list_alt</i>
                  <span *ngIf="!minimizedMenu" class="text-base  font-semibold" [ngClass]="{' text-white ': router.url == '/user/services', ' text-gray-600':router.url != '/user/services'}">{{section.name | titlecase}}</span>
                </a>
              </div>
            </div>
            <div class="" *ngIf="section.originalName=='Products'  && !envService.isNationApp && !envService.fromFNLeaders">
              <div class="hover:bg-gray-100" [ngClass]="{'bg-customTeal rounded-md text-white hover:bg-customTeal': router.url == '/user/view-products' }">
                <a (click)="[redirectToProduct(),closeSideBar()]" class="flex items-center p-2 text-base font-normal rounded-lg ">
                  <i class="material-icons mr-2 w-5 h-5 text-xl float-left" [ngClass]="{' text-white ': router.url == '/user/view-products', ' text-gray-400':router.url != '/user/view-products' }">inventory_2</i>
                  <span  *ngIf="!minimizedMenu" class="text-base  font-semibold" [ngClass]="{' text-white ': router.url == '/user/view-products', ' text-gray-600':router.url != '/user/view-products'}">{{section.name | titlecase}}</span>
                </a>
              </div>
            </div>

          </ng-container>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="model" >
        <div *ngIf="model.takeAppointment && isServiceEnabled" class="hover:bg-gray-100" [ngClass]="{'bg-customTeal rounded-md text-white hover:bg-customTeal': router.url == '/user/appointments' }">
          <a [routerLink]="['/user/appointments']" (click)="closeSideBar()"
            class="flex items-center p-2 text-base font-normal rounded-lg ">
            <i  class="material-icons mr-2 w-5 h-5 text-xl float-left" [ngClass]="{' text-white ': router.url == '/user/appointments', ' text-gray-400':router.url != '/user/appointments' }">quiz</i>
            <span *ngIf="!minimizedMenu" class="text-base  font-semibold" [ngClass]="{' text-white ': router.url == '/user/appointments', ' text-gray-600':router.url != '/user/appointments'}">Appointments</span>
          </a>
        </div>
      </ng-container>

      <div class="hover:bg-gray-100" [ngClass]="{'bg-customTeal rounded-md text-white hover:bg-customTeal': router.url == '/user/chat-list' }">
        <a [routerLink]="['/user/chat-list']" (click)="closeSideBar()"
        class="flex items-center p-2 text-base font-normal rounded-lg ">
          <i class="material-icons mr-2 w-5 h-5 text-xl float-left" [ngClass]="{' text-white ': router.url == '/user/chat-list', ' text-gray-400':router.url != '/user/chat-list' }">chat_bubble</i>
          <span *ngIf="!minimizedMenu" class="text-base  font-semibold" [ngClass]="{' text-white ': router.url == '/user/chat-list', ' text-gray-600':router.url != '/user/chat-list'}">Messages</span>
        </a>
      </div>

      <div class="hover:bg-gray-100" [ngClass]="{'bg-customTeal rounded-md text-white hover:bg-customTeal': router.url == '/user/get-verified' && feedbackModal != true}">
        <a (click)="toggleSideBar()" (click)="getNavigateVerified()"
        class="flex items-center p-2 text-base font-normal rounded-lg ">
          <i class="material-icons mr-2 w-5 h-5 text-xl float-left" [ngClass]="{' text-white ': router.url == '/user/get-verified' && feedbackModal != true, ' text-gray-400':router.url != '/user/get-verified' && feedbackModal != true }">check_circle</i>
          <span *ngIf="!minimizedMenu" class="text-base  font-semibold" [ngClass]="{' text-white ': router.url == '/user/get-verified' && feedbackModal != true, ' text-gray-600':router.url != '/user/get-verified' && feedbackModal != true} "> Get Verified</span>
        </a>
      </div>

      <div class="hover:bg-gray-100" [ngClass]="{'bg-customTeal rounded-md text-white hover:bg-customTeal': feedbackModal == true }">
        <a (click)="toggleSideBar()" (click)="openModal()"
        class="flex items-center p-2 text-base font-normal rounded-lg ">
          <i class="material-icons mr-2 w-5 h-5 text-xl float-left" [ngClass]="{' text-white ': feedbackModal == true, ' text-gray-400':feedbackModal != true }">help</i>
          <span *ngIf="!minimizedMenu" class="text-base  font-semibold" [ngClass]="{' text-white ': feedbackModal == true, ' text-gray-600':feedbackModal != true}"> Give us Feedback</span>
        </a>
      </div>
      <div class="hover:bg-gray-100" (click)="signOut()">
        <a   class="flex items-center p-2 text-base font-normal rounded-lg ">
          <svg xmlns="http://www.w3.org/2000/svg" class="fill-gray-400 mr-2 w-4 h-4 " height="1em" viewBox="0 0 512 512">
            <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"/></svg>
          <span *ngIf="!minimizedMenu" class="text-base  font-semibold text-gray-600" >Log Out</span>
        </a>
      </div>
    </div>
  </div>
</ng-template> -->

<app-feedback [userLogged]="currentUser" *ngIf="feedbackModal" (cancelSendClicked)="closeModal()"
  (sendClicked)="onSendClicked($event)"> </app-feedback>