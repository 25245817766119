<!-- <div class="row center-align">
  <h5><strong> Create Poll / Poll </strong></h5>
</div> -->





<div class="font-body min-h-screen max-h-max pt-6">
  <p class=" font-bold text-lg text-center text-customTeal ">Create Survey/Poll </p>
  <form [formGroup]="surveyForm" (ngSubmit)="survey(surveyForm.value)">

    <div class="flex flex-col md:w-4/6 m-2 bg-white h-max p-4 md:p-12 rounded-lg space-y-4 md:translate-x-1/4">
      <!-- Create Survey/Poll -->
      <div class="flex flex-col space-y-6 justify-between md:flex-row md:space-y-0 ">
        <!-- Description -->
        <div class="md:w-1/2 space-y-2  ">
          <p class="text-sm">
            Survey or Poll
          </p>
        </div>

        <!-- toggle & edit -->
        <div class="flex justify-center align-middle space-x-8 items-baseline">
          <!-- toggle -->
          <div class="flex justify-start w-1/2 gap-x-8">
            <div class="form-control">
              <label class="label cursor-pointer">
                <input type="radio" class="radio checked:bg-customTeal" value="Survey" formControlName="surveyType" />
                <span class="label-text ml-4 font-normal text-black">Survey</span>
              </label>
            </div>
            <div class="form-control">
              <label class="label cursor-pointer">
                <input type="radio" class="radio checked:bg-customTeal" value="Poll" formControlName="surveyType" />
                <span class="label-text ml-4 font-normal text-black">Poll</span>

              </label>
            </div>
          </div>
          <div>
          </div>

        </div>
      </div>
      <!-- chooses public or employee or subscriber -->
      <!-- <div class="flex flex-col space-y-6 justify-between md:flex-row md:space-y-0 ">
        <div class="md:w-1/2 space-y-2  ">
          <p class="text-sm">
            Share Poll
          </p>
        </div>


        <div class="flex justify-center align-middle space-x-8 items-baseline">
          <div class="form-control">
            <label class="label cursor-pointer space-x-1">
              <input type="checkbox" class="checkbox checkbox-primary" [checked]="publicChecked"
                (click)="togglePublic($event)" />

              <span class="label-text">Public</span>
            </label>
          </div>
          <div class="form-control">
            <label class="label cursor-pointer space-x-1">
              <input type="checkbox" class="checkbox checkbox-primary" [checked]="subscribersChecked"
                (click)="toggleSubscriber($event)" />
              <span class="label-text">Subscribers</span>
            </label>
          </div>
          <div class="form-control">
            <label class="label cursor-pointer space-x-1">
              <input type="checkbox" class="checkbox checkbox-primary" [checked]="employeeChecked"
                (click)="toggleEmployee($event)" />
              <span class="label-text">Employee</span>
            </label>
          </div>

        </div>
      </div> -->
      <hr>

      <div class="flex space-y-2 md:w-full  flex-col">
        <label class="font-bold text-sm">Name: </label>
        <input
          class=" border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline "
          id="Email " type="text " placeholder="" formControlName="surveyName">
      </div>

      <div class="flex space-y-2 md:w-full  flex-col">
        <label class="font-bold text-sm">Description: </label>
        <textarea
          class="h-44 border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline "
          id="Email " type="text " placeholder="" formControlName="surveyDesc"></textarea>
      </div>



      <div class="flex flex-wrap -mx-3 ">
        <div class="w-full md:w-1/2 px-3 md:mb-0">
          <label class="label">
            <span class="label-text text-[#4F4F4F] text-base leading-5">Start date
              <span class="text-red-500">*</span>
            </span>
          </label>
          <input id="pollStartDate" type="date" class="input input-bordered w-full" formControlName="startDate">

        </div>

        <div class="w-full md:w-1/2 px-3 md:mb-0">
          <label class="label">
            <span class="label-text text-[#4F4F4F] text-base leading-5">End date</span>
          </label>
          <input id="pollEndDate" type="date" formControlName="endDate"
            class="input input-bordered w-full leading-tight">

        </div>

      </div>




      <div class="flex flex-col space-y-6 justify-between md:flex-row md:space-y-0 ">
        <div class="md:w-1/2 space-y-2  ">
          <p class="text-sm">
            Show Result
          </p>
        </div>

        <div class="flex justify-center align-middle space-x-8 items-baseline">
          <div class="flex justify-start w-1/2 gap-x-8">
            <div class="form-control">
              <label class="label cursor-pointer">
                <input type="radio" class="radio checked:bg-customTeal" value="yes" formControlName="showResult" name="showResult"
                  (change)="onShowResultYes($event)" />
                <span class="label-text ml-4 font-normal text-black">Yes</span>

              </label>
            </div>
            <div class="form-control">
              <label class="label cursor-pointer">
                <input type="radio" class="radio checked:bg-customTeal" value="no" formControlName="showResult" name="showResult"
                  (change)="onShowResultNo($event)" />
                <span class="label-text ml-4 font-normal text-black">No</span>

              </label>
            </div>
          </div>
          <div>
          </div>

        </div>
      </div>

      <div class="flex justify-end align-middle space-x-8 items-baseline" *ngIf="showPollResult">
        <div class="form-control">
          <label class="label cursor-pointer space-x-1">
            <input type="checkbox" class="checkbox checkbox-primary" formArrayName="selectedCountries" [value]="Public" (change)="onCheckboxChange($event)"/>

            <span class="label-text">Public</span>
          </label>
        </div>
        <div class="form-control">
          <label class="label cursor-pointer space-x-1">
            <input type="checkbox" class="checkbox checkbox-primary" formArrayName="selectedCountries" [value]="Subscriber" (change)="onCheckboxChange($event)"/>
            <span class="label-text">Subscribers</span>
          </label>
        </div>
        <div class="form-control">
          <label class="label cursor-pointer space-x-1">
            <input type="checkbox" class="checkbox checkbox-primary" formArrayName="selectedCountries" [value]="Employee" (change)="onCheckboxChange($event)"/>
            <span class="label-text">Employee</span>
          </label>
        </div>

      </div>

      <div class="flex justify-end">
        <label class="label cursor-pointer space-x-1">
          <input type="checkbox" class="checkbox checkbox-primary" />
          <span class="label-text">Make it anonymous</span>
        </label>
      </div>




      <div class="pt-6 space-y-2 flex justify-between">
        <div class="">
          <button type="submit"
            class="flex space-x-2 px-4 md:px-6 pb-4 text-black rounded-lg w-max h-min text-sm md:text-base font-bold ">
            <span>Cancel</span>
          </button>
        </div>
        <div class="">
          <button type="submit"
            class="flex space-x-2  px-4 md:px-6 py-2 bg-customTeal text-white rounded-lg w-max h-min text-sm md:text-base font-bold hover:bg-darkBlue focus:outline-none  ">
            <span>Next</span>
          </button>
        </div>
      </div>

    </div>

  </form>
</div>

<div class="flex flex-col md:flex-row flex-wrap m-3 md:m-6">
  <div class="flex flex-col bg-white w-full md:w-max h-56 rounded-lg p-8 md:p-6 space-y-4 m-4">
      <div class="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-32 md:justify-between">
          <p class="font-bold text-lg">Poll Name</p>
          <div class="flex space-x-2 items-center">
              <div class=""></div>
              <p class="text-sm px-4 py-2 bg-gray-300 text-black">Poll</p>
          </div>
      </div>

      <div class="flex">
        <div class="flex space-x-2 items-center">
          <div class="rounded-full bg-gray-400 w-3 h-3"></div>
          <p class="text-sm">Public</p>

          <div class="flex space-x-2 items-center">
            <div class="rounded-full bg-gray-400 w-3 h-3"></div>
            <p class="text-sm">Subscriber</p>
        </div>

        <div class="flex space-x-2 items-center">
          <div class="rounded-full bg-gray-400 w-3 h-3"></div>
          <p class="text-sm">Employee</p>
      </div>
      </div>  
      </div>

      <div class="flex">
            <div class="avatar-group -space-x-6">
              <div class="avatar">
                <div class="w-12">
                  <img loading="lazy" data-src="https://placeimg.com/192/192/people" />
                </div>
              </div>
              <div class="avatar">
                <div class="w-12">
                  <img loading="lazy" data-src="https://placeimg.com/192/192/people" />
                </div>
              </div>
              <div class="avatar">
                <div class="w-12">
                  <img loading="lazy" data-src="https://placeimg.com/192/192/people" />
                </div>
              </div>
              <div class="avatar placeholder">
                <div class="w-12 bg-neutral-focus text-neutral-content">
                  <span>+99</span>
                </div>
              </div>
            </div>
            <p class="font-bold text-customTeal text-sm md:text-base mt-3">Responses</p>

      </div>

      <!-- Cancel and reschedule -->
      <div class="flex  space-x-5 md:space-x-10 py-2 ">
          <button class="flex items-center space-x-2  w-max h-min text-sm md:text-base font-bold">
              <span>Ends on Dec 28, 2020</span> 
          </button>
      </div>
  </div>

  <div class="flex flex-col bg-white w-full md:w-max h-56 rounded-lg p-8 md:p-6 space-y-4 m-4">
    <div class="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-32 md:justify-between">
        <p class="font-bold text-lg">Poll Name</p>
        <div class="flex space-x-2 items-center">
            <div class=""></div>
            <p class="text-sm px-4 py-2 bg-gray-300 text-black">Poll</p>
        </div>
    </div>

    <div class="flex">
      <div class="flex space-x-2 items-center">
        <div class="rounded-full bg-gray-400 w-3 h-3"></div>
        <p class="text-sm">Public</p>

        <div class="flex space-x-2 items-center">
          <div class="rounded-full bg-gray-400 w-3 h-3"></div>
          <p class="text-sm">Subscriber</p>
      </div>

      <div class="flex space-x-2 items-center">
        <div class="rounded-full bg-gray-400 w-3 h-3"></div>
        <p class="text-sm">Employee</p>
    </div>
    </div>  
    </div>

    <div class="flex">
          <div class="avatar-group -space-x-6">
            <div class="avatar">
              <div class="w-12">
                <img loading="lazy" data-src="https://placeimg.com/192/192/people" />
              </div>
            </div>
            <div class="avatar">
              <div class="w-12">
                <img loading="lazy" data-src="https://placeimg.com/192/192/people" />
              </div>
            </div>
            <div class="avatar">
              <div class="w-12">
                <img loading="lazy" data-src="https://placeimg.com/192/192/people" />
              </div>
            </div>
            <div class="avatar placeholder">
              <div class="w-12 bg-neutral-focus text-neutral-content">
                <span>+99</span>
              </div>
            </div>
          </div>
          <p class="font-bold text-customTeal text-sm md:text-base mt-3">Responses</p>

    </div>

    <!-- Cancel and reschedule -->
    <div class="flex  space-x-5 md:space-x-10 py-2 ">
        <button class="flex items-center space-x-2  w-max h-min text-sm md:text-base font-bold">
            <span>Ends on Dec 28, 2020</span> 
        </button>
    </div>
</div>

<div class="flex flex-col bg-white w-full md:w-max h-56 rounded-lg p-8 md:p-6 space-y-4 m-4">
  <div class="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-32 md:justify-between">
      <p class="font-bold text-lg">Poll Name</p>
      <div class="flex space-x-2 items-center">
          <div class=""></div>
          <p class="text-sm px-4 py-2 bg-gray-300 text-black">Poll</p>
      </div>
  </div>

  <div class="flex">
    <div class="flex space-x-2 items-center">
      <div class="rounded-full bg-gray-400 w-3 h-3"></div>
      <p class="text-sm">Public</p>

      <div class="flex space-x-2 items-center">
        <div class="rounded-full bg-gray-400 w-3 h-3"></div>
        <p class="text-sm">Subscriber</p>
    </div>

    <div class="flex space-x-2 items-center">
      <div class="rounded-full bg-gray-400 w-3 h-3"></div>
      <p class="text-sm">Employee</p>
  </div>
  </div>  
  </div>

  <div class="flex">
        <div class="avatar-group -space-x-6">
          <div class="avatar">
            <div class="w-12">
              <img loading="lazy" data-src="https://placeimg.com/192/192/people" />
            </div>
          </div>
          <div class="avatar">
            <div class="w-12">
              <img loading="lazy" data-src="https://placeimg.com/192/192/people" />
            </div>
          </div>
          <div class="avatar">
            <div class="w-12">
              <img loading="lazy" data-src="https://placeimg.com/192/192/people" />
            </div>
          </div>
          <div class="avatar placeholder">
            <div class="w-12 bg-neutral-focus text-neutral-content">
              <span>+99</span>
            </div>
          </div>
        </div>
        <p class="font-bold text-customTeal text-sm md:text-base mt-3">Responses</p>

  </div>

  <!-- Cancel and reschedule -->
  <div class="flex  space-x-5 md:space-x-10 py-2 ">
      <button class="flex items-center space-x-2  w-max h-min text-sm md:text-base font-bold">
          <span>Ends on Dec 28, 2020</span> 
      </button>
  </div>
</div>

</div>