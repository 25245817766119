import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GetAppService } from './../../../../services/firebase/get-app.service';
import { AutomationService } from 'src/app/services/backend-automation/automation.service';
import { AppinfoService } from 'src/app/services/backend-automation/appinfo.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { EmailService } from 'src/app/services/api/email.service';
import { CustomAdminEmailModel, EmailModel } from 'src/app/models/emailModel';
import { SMSService } from 'src/app/services/api/sms.service';
import { HelperService } from 'src/app/services/helper/helper';
import { SMSModel } from 'src/app/models/SMSModel';
import { environment } from 'src/environments/environment';

import { GlobalConstants } from 'src/app/models/GlobalConstants';
import { EnvironmentService } from 'src/app/services/helper/env';
import { AES } from 'crypto-js'
import * as CryptoJS from 'crypto-js';
import { AddUserToFirebaseModel, UserCollectionModel, WebAppModel, DataStreamParamModel } from 'src/app/models/AutomationModel';
import { CompositeIndexService } from 'src/app/services/firebase/composite-index-service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-app-request-accept',
  templateUrl: './app-request-accept.component.html',
  styleUrls: ['./app-request-accept.component.css']
})
export class AppRequestAcceptComponent implements OnInit {

  secretKey: any = "zLeCHJYKf25WENP";
  appManagerId: any;
  currentAppName = GlobalConstants.currentAppName
  deployedUrl: any;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private getAppService: GetAppService,
    private automationService: AutomationService,
    private compositeIndexService : CompositeIndexService,
    private appInfoService: AppinfoService,
    private appSettingsService: AppSettingsService,
    private emailService: EmailService,
    private smsService: SMSService,
    private helperService: HelperService,
    private envService: EnvironmentService) { }

  requestId: any;
  request: any;
  appName: any;
  percent: any = 0;
  response: any;

  failedTasks: any = []
  successTasks: any = []
  taskswzno: any = {
    1: "Create cloud project. ",
    2: "Add firebase project. ",
    3: "Create web app project. ",
    4: "Configure analytics. ",
    5: "Create cloud firestore database. ",
    6: "Add firestore rules. ",
    7: "Activate billing.",
    8: "Enable identity platform.",
    9: "Enable email/password sign-in method.",
    10: "Create Realtime database",
    11: "Creating owner account.",
    12: "Creating admin account",
    13: "Creating firestore indexes",
    14: "Deploy app.",
  }

  currentTaskName: any

  taskno: any | number;

  cloudProjectApi: any | boolean = true;
  firebaseProjectApi: any | boolean = false;
  webappApi: any | boolean = false;
  analyticsApi: any | boolean = false;
  firestoreApi: any | boolean = false;
  firestoreRulesApi: any | boolean = false;
  deployApi: any | boolean = false;

  cloudProjectCalled = false
  firebaseProjectCalled = false
  webappCalled = false
  analyticsCalled = false
  firestoreCalled = false
  firestoreRulesCalled = false
  deployAppCalled = false


  cloudProjError: any = ""
  firebaseProjError: any = ""
  webappError: any = ""
  analyticsError: any = ""
  firestoreError: any = ""
  firestoreRulesError: any = ""
  deployError: any = ""


  cloudProjSuccess: any = ""
  firebaseProjSuccess: any = ""
  webappSuccess: any = ""
  analyticsSuccess: any = ""
  firestoreSuccess: any = ""
  firestoreRulesSuccess: any = ""
  deploySuccess: any = ""
  i = 0

  valOfAppName: any

  link = ""

  defaultAccountId: any;

  openTab = 1;

  acceptOpenModal = false;
  declineOpenModal = false;


  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  finalName: any

  appStatus: any = "Not started"
  appMainName: any
  setupDone: any = false
  getLink: any;
  deployAppName: any;
  backendConfName: any;
  storedAppName: any;
  disabled = true
  listOfAccounts: any
  listOfValidAccountIds: any = []
  selected: any;
  tempAccountId: any;
  valid: any = false;

  configuredAnalyticsName: any;
  activateBillingAccountApi: any = false
  enableIdentityPlatformApi: any = false
  enableEmailPassApi: any = false
  addUserApi: any = false
  defaultAccName: any;
  configuredId: any;
  id: any;
  executedStatus: any;

  deletedProject: any = false;
  firstName: any;
  lastName: any;
  email: any;
  phoneNo: any | string;
  password: any;

  //added
  typeOfOffice: any;

  appSettings: any;
  signupSettings: any;
  sideImage: any;
  backDropColor: any;
  retry: any = false;

  goingmobileLink = "goingmobile.app";
  mynationLink = "mynation.app"
  isNationApp = environment.firstNation.Origin == "FNLeaders" ? true : false
  is_NationApp = environment.firstNation.name == "FNLeaders" ? true : false

  availablePhone: any;
  extentionDomain: any | string;
  nationOfuse = environment.firstNation.name;
  defaultBillingAccount:any = []
  measurementId:any;
  storedAnalytics:any = [];

  private analyticsSubscription: Subscription;
  private appInfoSubscription: Subscription;



  openAcceptRequestModal() { this.router.navigate(['/admin/analytics-setup']) }

  navigateToBilling() { this.router.navigate(['/admin/billing-setup']) }

  openDenyRequestModal() { this.declineOpenModal = true; }

  closeModal() {
    this.acceptOpenModal = false;
    this.declineOpenModal = false;
  }

  toggleTabs($tabNumber: number) { this.openTab = $tabNumber; }


  generateRandomNumbers = (max = 9, times = 3) => {
    const randoms = []
    var randomString = '';
    var ranNo

    for (let i = 0; i < times; i++) {
      ranNo = Math.floor(Math.floor(Math.random() * max))
      randomString += randomString + ranNo
      randoms.push(ranNo)
    }

    return randoms.join('')
  }

  selectOption(id: any) { this.selected = id }

  ngOnInit(): void {

    this.getDefaultBillingAcc()

    var randnum = this.generateRandomNumbers();

    this.route.paramMap.subscribe(params => { this.requestId = params.get('id'); });

    this.getAppService.getRequestById(this.requestId).valueChanges().subscribe((requests) => {
      if (requests.length > 0) {
        this.request = requests[0];
        this.deployedUrl = this.request.url

        this.appName = this.request.url;

        var splitFullName = this.request.fullName.split(" ");
        this.firstName = splitFullName[0] ? splitFullName[0] : '';
        this.lastName = splitFullName[1] ? splitFullName[1] : '';
        this.email = this.request.email;
        this.phoneNo = this.request.mobileNumber;
        this.password = this.request.createPassword;
        this.typeOfOffice = this.request.typeOfOffice ? this.request.typeOfOffice : "Dear";

        const modifiedName = this.appName.replace(/[^a-zA-Z0-9 ]/g, '');
        const removeSpace = modifiedName.split(' ').join('');
        this.appMainName = (removeSpace + "-" + randnum).toLowerCase();
        this.deployAppName = removeSpace;


        this.appInfoService.getAppByName(this.deployAppName).valueChanges().subscribe((res: any) => {
          if (res.length === 0) { this.setupDone = false }
          else {
            this.setupDone = true
            this.getLink = res[0].link;
            this.backendConfName = res[0].backendConfName;
            this.storedAppName = res[0].appName;
            this.configuredId = res[0].accountId
            this.id = res[0].id
            this.executedStatus = res[0].executedStatus

            if (this.executedStatus === "deleted") { this.deletedProject = true; }
          }
        }, (err: any) => { });

      }

    });

  

      this.analyticsSubscription = this.automationService.getStoredAnalyticsAcc().subscribe((x: any) => {
        this.storedAnalytics = x || {};
        this.storedAnalytics.accounts = this.storedAnalytics.accounts || [];
  
        const currentDate = new Date().toISOString().split('T')[0];
  
        if (!this.storedAnalytics || !this.storedAnalytics.accounts.length  || currentDate !== this.storedAnalytics.currentDate) {
          this.updateAccountDetails(currentDate);
        } else {
          this.processStoredAnalytics();
        }
      })


      this.appSettingsService.getAppSettingsList().valueChanges().subscribe((response) => {
        if (response.length > 0) {
          this.appSettings = response[0];
          this.availablePhone = this.appSettings.availablePhone ? this.appSettings.availablePhone : '';
        }
      });

      this.appSettingsService.getSignupSettingsList().valueChanges().subscribe(settings => {
        if (settings) {
          if (settings.length > 0) {
            this.signupSettings = settings[0]
            this.sideImage = this.signupSettings.logo && this.signupSettings.logo != '' ? this.signupSettings.logo : ""
            this.backDropColor = this.signupSettings.backDropColor
          }
        }
      })

    this.extentionDomain = this.is_NationApp ? "mynation.app" : "goingmobile.app"
  }

  updateAccountDetails(currentDate: string) {
    this.automationService.listAccountDetails().subscribe((res: any) => {
      this.listOfAccounts = res;

      this.automationService.storeAnalyticsAcc(this.listOfAccounts).add(() => {
        // Ensure that storedAnalytics is updated with new data and date
        this.storedAnalytics = { accounts: this.listOfAccounts, currentDate: currentDate };
        this.processStoredAnalytics();
      })
      // (error => {
      //   console.error("Error updating Firestore", error);
      // });
    });
  }
  

  processStoredAnalytics() {
    if (this.storedAnalytics && Array.isArray(this.storedAnalytics.accounts)) {
      this.listOfValidAccountIds = [];  // Ensure this is cleared each time to avoid duplication
      this.storedAnalytics.accounts.forEach((acc: any) => {
        if (acc.totalNoOfProperties < 100) {
          this.listOfValidAccountIds.push(acc);
        }
      });
  
      this.appInfoSubscription = this.appInfoService.getConfigSetting().valueChanges().subscribe((res: any) => {
        this.tempAccountId = res[0].accountId;
  
        this.listOfValidAccountIds.forEach((acc: any) => {
          if (acc.accountId === this.tempAccountId) {
            this.defaultAccountId = this.tempAccountId;
            this.defaultAccName = acc.name;
            this.valid = true;
          }
        });
      });
  
      this.valOfAppName = this.appName;
    } else {
    }
  }
  

  ngOnDestroy() {
    if (this.analyticsSubscription) {
      this.analyticsSubscription.unsubscribe();
    }
    if (this.appInfoSubscription) {
      this.appInfoSubscription.unsubscribe();
    }
  }


  appendNewId() {
    var randnum = this.generateRandomNumbers();
    const modifiedName = this.appName.replace(/[^a-zA-Z0-9 ]/g, '');
    const removeSpace = modifiedName.split(' ').join('');
    this.appMainName = (removeSpace + "-" + randnum).toLowerCase();
    this.cloudProject();
  }

  generateCipherKeyForApp() {
    const characters = "abcdefghijklmnopqrstuvwxyz0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";

    let key = "";
    for (let i = 0; i < 32; i++) {
      key += characters[Math.floor(Math.random() * characters.length)];
    }
    return key;
  }

  // 1
  cloudProject() {

    this.currentTaskName = this.taskswzno[1];
    this.cloudProjectCalled = true

    this.appStatus = "In Progress"

    this.successTasks = []
    this.failedTasks = []

    if (this.retry) { this.retry = false; }

    var res = this.automationService.createCloudProject(this.appMainName).subscribe((rep) => {
      this.response = rep;

      this.cloudProjectApi = true;
      this.percent += 7
      this.successTasks.push(this.taskswzno[1])
      this.cloudProjSuccess = rep
      this.appStatus = "In Progress"

      this.firebaseProject()

    }, (err) => {
      this.cloudProjError = err.error;
      this.failedTasks.push(this.taskswzno[1])
      this.appStatus = "Failed"
      this.retry = true;
      this.percent = 0;
    })

    this.taskno = 1;

    return;

  }

  // 2
  firebaseProject() {

    this.currentTaskName = this.taskswzno[2];
    this.taskno = 2;

    var res = this.automationService.addFirebaseProject(this.appMainName).subscribe((rep) => {
      this.response = rep;

      this.successTasks.push(this.taskswzno[2])
      this.percent += 7
      this.firebaseProjSuccess = rep
      this.appStatus = "In Progress"

      this.firebaseProjectApi = true;

      this.webappProject()

    }, (err) => {
      this.firebaseProjError = err.error
      this.failedTasks.push(this.taskswzno[2])
      this.appStatus = "Failed"
      this.retry = true;
      this.percent = 0;

    })

    this.firebaseProjectCalled = true;

    return;

  }

  //  3
  webappProject() {


    this.currentTaskName = this.taskswzno[3];
    this.taskno = 8;

    const newWebAppModel = new WebAppModel()
    newWebAppModel.AppName = this.appMainName
    newWebAppModel.AccountId = this.defaultAccountId
    var res = this.automationService.createWebappProject(newWebAppModel).subscribe((rep) => {
      this.response = rep;

      this.successTasks.push(this.taskswzno[3])
      this.percent += 7
      this.webappSuccess = rep
      this.webappApi = true;
      this.appStatus = "In Progress"

      this.configAnalytics()

    }, (err) => {
      this.webappError = err.error
      this.failedTasks.push(this.taskswzno[3])
      this.appStatus = "Failed"
      this.retry = true;
      this.percent = 0;

      this.automationService.deleteCloudProject(this.appMainName).subscribe((res) => { }, (err) => { })
      this.automationService.deleteProperty(this.appMainName, this.defaultAccountId).subscribe((res) => { }, (err) => { })

    })
    this.webappCalled = true;

    return;

  }

  //  4
  configAnalytics() {

    this.currentTaskName = this.taskswzno[4];
    this.taskno = 4;


    var res = this.automationService.configureAnalytics(this.appMainName).subscribe((rep) => {
      this.response = rep;

      this.successTasks.push(this.taskswzno[4])
      this.percent += 7
      this.analyticsSuccess = rep
      this.analyticsApi = true;
      this.appStatus = "In Progress"

      this.cloudFirestore()

    }, (err) => {
      this.analyticsError = err.error
      this.failedTasks.push(this.taskswzno[4])
      this.appStatus = "Failed"
      this.retry = true;
      this.percent = 0;

      this.automationService.deleteCloudProject(this.appMainName).subscribe((res) => { }, (err) => { })
      this.automationService.deleteProperty(this.appMainName, this.defaultAccountId).subscribe((res) => { }, (err) => { })

    })

    this.analyticsCalled = true;
    return;

  }

  // 5
  cloudFirestore() {

    this.currentTaskName = this.taskswzno[5];
    this.taskno = 5;


    var res = this.automationService.addCloudFirestore(this.appMainName).subscribe((rep) => {
      this.response = rep;
      this.successTasks.push(this.taskswzno[5])

      this.percent += 7
      this.firestoreSuccess = rep
      this.firestoreApi = true;
      this.appStatus = "In Progress"

      this.firestoreRules()

    }, (err) => {
      this.firestoreError = err.error
      this.failedTasks.push(this.taskswzno[5])
      this.appStatus = "Failed"
      this.retry = true;
      this.percent = 0;

      this.automationService.deleteCloudProject(this.appMainName).subscribe((res) => { }, (err) => { })
      this.automationService.deleteProperty(this.appMainName, this.defaultAccountId).subscribe((res) => { }, (err) => { })

    })

    this.firestoreCalled = true;

    return;

  }

  // 6
  firestoreRules() {

    this.currentTaskName = this.taskswzno[6];
    this.taskno = 6;


    var res = this.automationService.addFirestoreRules(this.appMainName).subscribe((rep) => {
      this.response = rep;
      this.successTasks.push(this.taskswzno[6])
      this.percent += 7
      this.firestoreRulesSuccess = rep

      this.firestoreRulesApi = true;
      this.appStatus = "In Progress"

      this.cloudProjectCalled = true;
      this.activateBillingAccountApi = true;

      this.activateBilling()

    }, (err) => {
      this.firestoreRulesError = err.error
      this.failedTasks.push(this.taskswzno[6])
      this.appStatus = "Failed"
      this.retry = true;
      this.percent = 0;

      this.automationService.deleteCloudProject(this.appMainName).subscribe((res) => { }, (err) => { })
      this.automationService.deleteProperty(this.appMainName, this.defaultAccountId).subscribe((res) => { }, (err) => { })

    })

    this.firestoreRulesCalled = true;

    return;

  }

  // 7
  activateBilling() {

    this.currentTaskName = this.taskswzno[7];
    this.appStatus = "In Progress"

    //this.defaultBillingAccount -> that reads from firebase removed
    // to make the current default  "billingAccounts/01E866-F50682-393BF0" always
    this.automationService.activateBilling(this.appMainName, "billingAccounts/01E866-F50682-393BF0").subscribe((res: any) => {
      this.percent += 7
      this.successTasks.push(this.taskswzno[7])
      this.appStatus = "In Progress"

      this.enableIdentityPlatform();
      this.activateBillingAccountApi = false;
      this.enableIdentityPlatformApi = true;

    }, (err) => {

      this.failedTasks.push(this.taskswzno[7])
      this.appStatus = "Failed"
      this.retry = true;
      this.percent = 0;

      this.automationService.deleteCloudProject(this.appMainName).subscribe((res) => { }, (err) => { })
      this.automationService.deleteProperty(this.appMainName, this.defaultAccountId).subscribe((res) => { }, (err) => { })

    })

  }

  // 8
  enableIdentityPlatform() {
    this.currentTaskName = this.taskswzno[8];
    this.appStatus = "In Progress"
    this.automationService.enableIdentityPlatform(this.appMainName).subscribe((res: any) => {

      this.percent += 7
      this.successTasks.push(this.taskswzno[8])
      this.appStatus = "In Progress"
      this.enableIdentityPlatformApi = false;
      this.enableEmailPassApi = true;

      this.enableSigninMethod()
    }, (err) => {

      this.failedTasks.push(this.taskswzno[8])
      this.appStatus = "Failed"
      this.retry = true;
      this.percent = 0;

      this.automationService.deleteCloudProject(this.appMainName).subscribe((res) => { }, (err) => { })
      this.automationService.deleteProperty(this.appMainName, this.defaultAccountId).subscribe((res) => { }, (err) => { })
      this.automationService.activateBilling(this.appMainName, "").subscribe((res: any) => { }, (err: any) => { })

    })

  }

  // 9
  enableSigninMethod() {
    this.currentTaskName = this.taskswzno[9];
    this.appStatus = "In Progress"
    this.automationService.enableSignInMethod(this.appMainName).subscribe((res: any) => {

      this.percent += 7
      this.successTasks.push(this.taskswzno[9])
      this.appStatus = "In Progress"
      this.enableEmailPassApi = false;
      this.addUserApi = true;
      this.createRealtimedatabase()


    }, (err) => {

      this.failedTasks.push(this.taskswzno[9])
      this.appStatus = "Failed"
      this.retry = true;
      this.percent = 0;

      this.automationService.deleteCloudProject(this.appMainName).subscribe((res) => { }, (err) => { })
      this.automationService.deleteProperty(this.appMainName, this.defaultAccountId).subscribe((res) => { }, (err) => { })
      this.automationService.activateBilling("myapp-044", "").subscribe((res: any) => { }, (err: any) => { })

    })

  }

  getConfigVar() {

    var res = this.automationService.getConfigEnvVar(this.appName).subscribe((rep) => {
      this.response = rep;
      this.successTasks.push(this.taskswzno[7])
      this.deployApi = true

      this.automationService.deployApp(this.response).subscribe((rep2) => { })
    }, (err) => { this.appStatus = "Failed" })

    res.add(() => this.deployApp)
    this.deployAppCalled = true;
    return;
  }

  // 10
  createRealtimedatabase() {
    this.currentTaskName = this.taskswzno[10];
    this.appStatus = "In Progress"
    this.automationService.createRealtimeDatabase(this.appMainName).subscribe((res: any) => {
      this.percent += 7
      this.successTasks.push(this.taskswzno[10])
      this.appStatus = "In Progress"

      // this.deactivate()
      this.addOwnerToFirebase()

    }, (err: any) => {
      this.failedTasks.push(this.taskswzno[10])
      this.appStatus = "Failed"
      this.retry = true;
      this.percent = 0;

      this.automationService.deleteCloudProject(this.appMainName).subscribe((res) => { }, (err) => { })
      this.automationService.deleteProperty(this.appMainName, this.defaultAccountId).subscribe((res) => { }, (err) => { })
      this.automationService.activateBilling(this.appMainName, "").subscribe((res: any) => { }, (err: any) => { })

    })
  }



  // 11
  addOwnerToFirebase() {
    this.currentTaskName = this.taskswzno[11];
    this.appStatus = "In Progress"

    const decryptedPassword = AES.decrypt(this.password, this.secretKey).toString(CryptoJS.enc.Utf8)

    const newUserFirebaseModel = new AddUserToFirebaseModel()
    newUserFirebaseModel.AppName = this.appMainName
    newUserFirebaseModel.email = this.email
    newUserFirebaseModel.password = decryptedPassword
    this.automationService.addUserAndGetId(newUserFirebaseModel).subscribe((res: any) => {

      this.percent += 7
      this.successTasks.push(this.taskswzno[11])
      this.appStatus = "In Progress"
      this.addUserApi = false;

      this.appManagerId = res;

      const newUserCollectionModel = new UserCollectionModel()
      newUserCollectionModel.AppName = this.appMainName
      newUserCollectionModel.email = this.email
      newUserCollectionModel.password = decryptedPassword
      newUserCollectionModel.firstName = this.firstName
      newUserCollectionModel.lastName = this.lastName
      newUserCollectionModel.id = res
      newUserCollectionModel.role = "Admin"
      newUserCollectionModel.trialPeriod = 30
      newUserCollectionModel.contact = ['SMS', 'Email', 'InApp']
      newUserCollectionModel.mobileNumber = this.phoneNo
      newUserCollectionModel.allowPushNotification = true

      this.automationService.addUserToCollection(newUserCollectionModel).subscribe((res) => {
        this.addAdminToFirebase()

      }, (err) => {

        this.failedTasks.push(this.taskswzno[11])
        this.appStatus = "Failed"
        this.retry = true;
        this.percent = 0;

        this.automationService.deleteCloudProject(this.appMainName).subscribe((res) => { }, (err) => { })
        this.automationService.deleteProperty(this.appMainName, this.defaultAccountId).subscribe((res) => { }, (err) => { })

      })

    }, (err) => {

      this.failedTasks.push(this.taskswzno[10])
      this.appStatus = "Failed"
      this.retry = true
      this.percent = 0

      this.automationService.deleteCloudProject(this.appMainName).subscribe((res) => { }, (err) => { })
      this.automationService.deleteProperty(this.appMainName, this.defaultAccountId).subscribe((res) => { }, (err) => { })

    })

  }

  // 12
  addAdminToFirebase() {
    this.currentTaskName = this.taskswzno[12];
    this.appStatus = "In Progress"

    const newAdminFirebaseModel = new AddUserToFirebaseModel()
    newAdminFirebaseModel.AppName = this.appMainName
    newAdminFirebaseModel.email = "admin@goingmobile.app"
    newAdminFirebaseModel.password = "!456321!"
    this.automationService.addUserAndGetId(newAdminFirebaseModel).subscribe((res: any) => {

      this.percent += 7
      this.successTasks.push(this.taskswzno[12])
      this.appStatus = "In Progress"
      this.addUserApi = false;

      const newAdminCollectionModel = new UserCollectionModel()
      newAdminCollectionModel.AppName = this.appMainName
      newAdminCollectionModel.email = "admin@goingmobile.app"
      newAdminCollectionModel.password = "!456321!"
      newAdminCollectionModel.firstName = "admin"
      newAdminCollectionModel.lastName = "admin"
      newAdminCollectionModel.id = res
      newAdminCollectionModel.role = "Admin"
      newAdminCollectionModel.trialPeriod = 30
      newAdminCollectionModel.mobileNumber = this.phoneNo

      this.automationService.addUserToCollection(newAdminCollectionModel).subscribe((res) => {
        // this.deployApp()
        this.addFirestoreCompositeIndexes()

      }, (err) => {

        this.failedTasks.push(this.taskswzno[12])
        this.appStatus = "Failed"
        this.retry = true;
        this.percent = 0;

        this.automationService.deleteCloudProject(this.appMainName).subscribe((res) => { }, (err) => { })
        this.automationService.deleteProperty(this.appMainName, this.defaultAccountId).subscribe((res) => { }, (err) => { })

      })



    }, (err) => {

      this.failedTasks.push(this.taskswzno[11])
      this.appStatus = "Failed"
      this.retry = true
      this.percent = 0

      this.automationService.deleteCloudProject(this.appMainName).subscribe((res) => { }, (err) => { })
      this.automationService.deleteProperty(this.appMainName, this.defaultAccountId).subscribe((res) => { }, (err) => { })

    })

  }

  deactivate() {
    this.automationService.activateBilling(this.appMainName, "").subscribe((res: any) => {
      // this.addOwnerToFirebase()
      this.deployApp()
      this.currentTaskName = this.taskswzno[9];
    }, (err: any) => { })
  }

  // add firestore composite indexes
  addFirestoreCompositeIndexes() {
    this.currentTaskName = this.taskswzno[13];
    this.appStatus = "In Progress"

    this.compositeIndexService.createCompositeIndexes(this.appMainName).then((res) => {
      this.deactivate()

    }).catch((err) => {
      this.failedTasks.push(this.taskswzno[13])
      this.appStatus = "Failed"
      this.retry = true;
      this.percent = 0;

      this.automationService.deleteCloudProject(this.appMainName).subscribe((res) => { }, (err) => { })
      this.automationService.deleteProperty(this.appMainName, this.defaultAccountId).subscribe((res) => { }, (err) => { })
    })



  }


  fnLeaderEmailBody(title: string, loginLink: string) {

    let appName = this.appMainName.split('-')[0];

    return `
        <div style="padding: 0; margin: 0; background-color: white; ">
          <div style="text-align: center; padding: 0">
            <p
              style="
                font-style: italic;
                margin: 20px;
                font-size: 1.3em;
                font-weight:700;
              "
            >
              LET'S MAKE IT YOUR OWN
            </p>
          </div>

          <table cellpadding="0" cellspacing="0" border="0" width="100%">
            <tr
              style="
                background: linear-gradient(
                  to bottom,
                  #d28227 0%,
                  #d28227 50%,
                  white 50%,
                  white 100%
                );
              "
            >
              <td align="center" style="width: 30%; padding-top: 3%">
                <img
                  src="https://api.goingmobile.app/Media/FNLeaders/Images/88642de1-0ba9-475b-94d9-6cd02c7aec34.png"
                  alt="Image 1"
                  width="200px"
                  height="200px"
                  style="display: block"
                />
              </td>
              <td align="center" style="width: 30%; padding-top: 3%">
                <img
                  src="https://api.goingmobile.app/Media/FNLeaders/Images/f24f712f-c338-4a37-a59c-acfa1e1ef18b.png"
                  alt="Image 2"
                  width="200px"
                  height="200px"
                  style="display: block"
                />
              </td>
              <td align="center" style="width: 30%; padding-top: 3%">
                <img
                  src="https://api.goingmobile.app/Media/FNLeaders/Images/0489371f-bcf3-4595-aef4-4c3aaeecfed6.png"
                  alt="Image 3"
                  width="200px"
                  height="200px"
                  style="display: block"
                />
              </td>
            </tr>
          </table>

          <p style="padding-left: 1%">${title}</p>

          <div style="padding: 0 3%; text-align: left; color: #0e7b7e">
            <p>
              Your website and mobile app request has been approved! 
              We are excited to work with you on creating a unique and user-friendly platform that perfectly aligns with your
            </p>
            <p style="font-weight: bold">Here is what you do next:</p>
            <p>
              Now that you have created your account, it's time to get started! Here's a
              quick overview of the next steps:
            </p>
            <ul>
              <li>Login to your First Nations Leader account and click on manage my apps <a href="${loginLink}">${appName}</a> </li>
              <li>Your app is listed under approved, click on the link.</li>
              <li>As soon as you log in under your own platform, you will be prompted to begin customizing. Use the same login credentials</li>
            </ul>

            <p>
              Our user-friendly interface will guide you through the process, making it easy for you to tailor your website and mobile app to reflect your vision as a leader.
            </p>

            
            <p>
              Throughout the process, our dedicated team will be available to support you. Whether you have questions, need assistance, or want to explore additional features, we're just a click away.
            </p>
            <p>
              Please do not hesitate to reach out to us at ${this.envService.supportEmail}
            </p>


            <div style="padding-right: 2%;margin-bottom: 1% ;">
              <p>Sincerely <span style="display: block">Going Mobile Team</span></p>
              <a
              style="
                background-color: #d28227;
                border: none;
                color: white;
                padding: 8px 32px;
                text-align: center;
                text-decoration: none;
                display: inline-block;
                font-size: 16px;
                font-style: normal;
                cursor: pointer;
                float: right;
              "
              href="${loginLink}"
              class="button"
              >Login</a
            >

            </div>
          </div>
        </div>
      `
  }

  goingMobEmailBody(loginLink: string) {
    return ` 
          
<div bgcolor="#3f3f3f" text="#919191" alink="#cccccc" vlink="#cccccc" style="margin: 0; padding: 0; background-color: #3f3f3f; color: #919191;"><!--<![endif]--><center>

<table role="presentation" class="vb-outer" width="100%" cellpadding="0" border="0" cellspacing="0" bgcolor="#bfbfbf" id="ko_logoBlock_1" style="background-color: #bfbfbf">

    <tbody><tr><td class="vb-outer" align="center" valign="top" style="padding-left: 9px; padding-right: 9px; font-size: 0">

    

    <!--[if (gte mso 9)|(lte ie 8)]><table role="presentation" align="center" border="0" cellspacing="0" cellpadding="0" width="570"><tr><td align="center" valign="top"><![endif]--><!--

    --><div style="margin: 0 auto; max-width: 570px; -mru-width: 0px"><table role="presentation" border="0" cellpadding="0" cellspacing="9" bgcolor="#ffffff" width="570" class="vb-row" style="border-collapse: separate; width: 100%; background-color: #ffffff; mso-cellspacing: 9px; border-spacing: 9px; max-width: 570px; -mru-width: 0px">

      

      <tbody><tr>

    <td align="center" valign="top" style="font-size: 0"><div style="vertical-align: top; width: 100%; max-width: 184px; -mru-width: 0px"><!--

      --><table role="presentation" class="vb-content" border="0" cellspacing="9" cellpadding="0" width="184" style="border-collapse: separate; width: 100%; mso-cellspacing: 9px; border-spacing: 9px">

        

        <tbody><tr><td width="100%" valign="top" align="center" class="links-color"><!--[if (lte ie 8)]><div style="display: inline-block; width: 166px; -mru-width: 0px"><![endif]--><img border="0" hspace="0" align="center" vspace="0" width="166" style="border: 0px; display: block; vertical-align: top; height: auto; margin: 0 auto; color: #3f3f3f; font-size: 13px; font-family: Arial, Helvetica, sans-serif; width: 100%; max-width: 166px; height: auto;" src="https://mosaico.io/srv/f-jo6prx4/img?src=https%3A%2F%2Fmosaico.io%2Ffiles%2Fjo6prx4%2FLogo%2520with%2520text.png&amp;method=resize&amp;params=166%2Cnull"><!--[if (lte ie 8)]></div><![endif]--></td></tr>

      

      </tbody></table></div></td>

  </tr>

    

    </tbody></table></div><!--

  --><!--[if (gte mso 9)|(lte ie 8)]></td></tr></table><![endif]-->

  </td></tr>

  </tbody></table><table role="presentation" class="vb-outer" width="100%" cellpadding="0" border="0" cellspacing="0" bgcolor="#bfbfbf" id="ko_singleArticleBlock_1" style="background-color: #bfbfbf">

    <tbody><tr><td class="vb-outer" align="center" valign="top" style="padding-left: 9px; padding-right: 9px; font-size: 0">

    <!--[if (gte mso 9)|(lte ie 8)]><table role="presentation" align="center" border="0" cellspacing="0" cellpadding="0" width="570"><tr><td align="center" valign="top"><![endif]--><!--

    --><div style="margin: 0 auto; max-width: 570px; -mru-width: 0px"><table role="presentation" border="0" cellpadding="0" cellspacing="9" bgcolor="#ffffff" width="570" class="vb-row" style="border-collapse: separate; width: 100%; background-color: #ffffff; mso-cellspacing: 9px; border-spacing: 9px; max-width: 570px; -mru-width: 0px">

      

      <tbody><tr>

    <td align="center" valign="top" style="font-size: 0"><div style="vertical-align: top; width: 100%; max-width: 552px; -mru-width: 0px"><!--

      --><table role="presentation" class="vb-content" border="0" cellspacing="9" cellpadding="0" style="border-collapse: separate; width: 100%; mso-cellspacing: 9px; border-spacing: 9px" width="552">

        

        <tbody><tr><td width="100%" valign="top" align="center" class="links-color" style="padding-bottom: 9px"><!--[if (lte ie 8)]><div style="display: inline-block; width: 534px; -mru-width: 0px"><![endif]--><img border="0" hspace="0" align="center" vspace="0" width="534" style="border: 0px; display: block; vertical-align: top; height: auto; margin: 0 auto; color: #3f3f3f; font-size: 13px; font-family: Arial, Helvetica, sans-serif; width: 100%; max-width: 534px; height: auto;" src="https://mosaico.io/srv/f-jo6prx4/img?src=https%3A%2F%2Fmosaico.io%2Ffiles%2Fjo6prx4%2FCopy%2520of%2520Your%2520App%2520%25281%2529.png&amp;method=resize&amp;params=534%2Cnull"><!--[if (lte ie 8)]></div><![endif]--></td></tr>

        <tr>

    <td width="100%" valign="top" align="left" style="font-weight: normal; color: #3f3f3f; font-size: 18px; font-family: Arial, Helvetica, sans-serif; text-align: left"><span style="font-weight: normal">Hi ${this.firstName},</span></td>

  </tr>

        <tr><td class="long-text links-color" width="100%" valign="top" align="left" style="font-weight: normal; color: #3f3f3f; font-size: 13px; font-family: Arial, Helvetica, sans-serif; text-align: left; line-height: normal"><p style="margin: 1em 0px; margin-top: 0px;">We're thrilled to tell you - it's a green light!&nbsp;</p>

<p style="margin: 1em 0px;">Your application to create a mobile web app with GoingMobile.app is approved, opening doors to a world where technology and innovation meet.&nbsp;</p>

<p style="margin: 1em 0px;"><strong>What's Next?</strong></p>

<p style="margin: 1em 0px;"><strong>Step In</strong>:&nbsp;Log into your account.&nbsp;<br><strong>Discover</strong>:&nbsp;Find your approved app under 'My Apps'<br><strong>Create</strong>:&nbsp;Follow prompts or let your imagination lead.<br>With a blend of feature-rich and user-friendly design options, your web app is set to be a game changer.&nbsp;</p>

<p style="margin: 1em 0px;">We've got your back:&nbsp;Our team is your team! For questions or guidance, reach out to us at&nbsp;${this.envService.supportEmail}.&nbsp;</p>

<p style="margin: 1em 0px; margin-bottom: 0px;">The future is digital, and yours is about to shine brighter. Ready to begin?</p></td></tr>

        <tr>

    <td valign="top" align="left"><table role="presentation" cellpadding="6" border="0" align="left" cellspacing="0" style="border-spacing: 0; mso-padding-alt: 6px 6px 6px 6px; padding-top: 4px"><tbody><tr>

      <td width="auto" valign="middle" align="left" bgcolor="03989e" style="text-align: center; font-weight: normal; padding: 6px; padding-left: 18px; padding-right: 18px; background-color: 03989e; color: #ffffff; font-size: 13px; font-family: Arial, Helvetica, sans-serif; border-radius: 4px"><a style="text-decoration: none; font-weight: normal; color: #ffffff; font-size: 13px; font-family: Arial, Helvetica, sans-serif" target="_new" href="${loginLink}">Login Now</a></td>

    </tr></tbody></table></td>

  </tr>

      

      </tbody></table></div></td>

  </tr>

    

    </tbody></table></div><!--

  --><!--[if (gte mso 9)|(lte ie 8)]></td></tr></table><![endif]-->

  </td></tr>

  </tbody></table>





  <!-- footerBlock -->

  <table role="presentation" class="vb-outer" width="100%" cellpadding="0" border="0" cellspacing="0" bgcolor="#3f3f3f" id="" style="background-color: #3f3f3f">

    <tbody><tr><td class="vb-outer" align="center" valign="top" style="padding-left: 9px; padding-right: 9px; font-size: 0">

  <!--[if (gte mso 9)|(lte ie 8)]><table role="presentation" align="center" border="0" cellspacing="0" cellpadding="0" width="570"><tr><td align="center" valign="top"><![endif]--><!--

    --><div style="margin: 0 auto; max-width: 570px; -mru-width: 0px"><table role="presentation" border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate; width: 100%; mso-cellspacing: 0px; border-spacing: 0px; max-width: 570px; -mru-width: 0px" width="570" class="vb-row">
    <tbody><tr>

    <td align="center" valign="top" style="font-size: 0; padding: 0 9px"><div style="vertical-align: top; width: 100%; max-width: 552px; -mru-width: 0px"><!--

      --><table role="presentation" class="vb-content" border="0" cellspacing="9" cellpadding="0" style="border-collapse: separate; width: 100%; mso-cellspacing: 9px; border-spacing: 9px" width="552">

        

      <tbody><tr><td class="long-text links-color" width="100%" valign="top" align="center" style="font-weight: normal; color: #919191; font-size: 13px; font-family: Arial, Helvetica, sans-serif; text-align: center"><p style="margin: 1em 0px; margin-bottom: 0px; margin-top: 0px;">Email sent to <a href="mailto:[mail]">[mail]</a></p></td></tr>

      <tr><td width="100%" valign="top" align="center" style="font-weight: normal; color: #ffffff; font-size: 13px; font-family: Arial, Helvetica, sans-serif; text-align: center"><a href="" style="color: #ffffff; text-decoration: underline" target="_new">Unsubscribe</a></td></tr>

      </tbody></table></div></td>
  </tr>
    </tbody></table></div><!--

  --><!--[if (gte mso 9)|(lte ie 8)]></td></tr></table><![endif]-->
</td></tr>
  </tbody></table>
  <!-- /footerBlock -->
</center><!--[if !(gte mso 16)]--></div>
      `
  }

  // 13
  deployApp() {

    var mainName = this.appMainName;

    this.currentTaskName = this.taskswzno[13];
    this.taskno = 16;
    this.automationService.getConfigEnvVar(this.appMainName).subscribe((rep) => {
      this.response = rep;
      this.appStatus = "In Progress"

      const newDataStreamModel = new DataStreamParamModel()

      newDataStreamModel.defaultUri = environment.firstNation.name === "FNLeaders" ? `https://www.${this.deployAppName}.mynation.app` : `https://www.${this.deployAppName}.goingmobile.app`
      newDataStreamModel.displayName = this.deployAppName
      newDataStreamModel.propertyId = this.response.PropertyId

      this.automationService.createDatastream(newDataStreamModel).subscribe((res:any) => {
          // get measurement id from api
          this.automationService.getMeasurementId(`${newDataStreamModel.propertyId}`).subscribe((res:any) => {
            this.measurementId = res;
            const env = {
              "apiKey": this.response.ApiKey,
              "appName": this.deployAppName,
              "appId": this.response.AppId,
              "authDomain": this.response.AuthDomain,
              "measurementId": this.measurementId,
              "projectId": this.response.ProjectId,
              "storageBucket": this.response.StorageBucket,
              "messagingSenderId": this.response.MessagingSenderId,
              "propertyId": this.response.PropertyId,
              "databaseURL": `https://${this.response.ProjectId}.firebaseio.com`,
              "origin": (environment.firstNation.name === 'Build' || environment.firstNation.name === 'Demo') ? "GoingMobile" : "FNLeaders",
      
            }
      
            this.automationService.deployApp(env).subscribe((rep2: any | string) => {
              //change status to accepted
      
              this.successTasks.push(this.taskswzno[13])
              this.percent += 16
              this.deploySuccess = rep2
              this.link = rep2
      
              let appLoginLink = rep2 + "/auth/login"
      
              this.deployApi = true
              this.appStatus = "Completed"
      
              this.cloudProjectCalled = true;
              this.activateBillingAccountApi = true;
              this.goingmobileLink = "goingmobile.app"
              if (this.envService.fromFNLeaders || this.envService.isNationApp) {
                let condition = this.request.isNationMember && this.request.isInOffice && ['Chief', 'Councillor'].includes(this.request.typeOfOffice)
                let name = this.request.fullName.split(' ')
                let title = condition ? `${this.request.typeOfOffice}  ${name[0]}` : `Dear ${name[name.length - 1]}`
                var emailContent = {
                  subject: "App created successfully",
                  emailbody: this.fnLeaderEmailBody(title, appLoginLink)
                }
              }
              else {
                var emailContent = {
                  subject: "App created successfully",
                  emailbody: this.goingMobEmailBody(appLoginLink)
               
                }
              }
      
              const emailModel = new EmailModel();
              emailModel.subject = emailContent.subject;
              emailModel.body = emailContent.emailbody;
              emailModel.isNationApp = this.isNationApp;
      
              var emailList = [this.email]
      
              emailModel.emailList = emailList;
              if (this.envService.fromFNLeaders || this.envService.isNationApp) {
                var messageModel = {
                  messageSMS:
                    `
                    Welcome ${this.typeOfOffice} ${this.lastName},\nExciting news! Your branded app is now live and ready for use. <a href="${appLoginLink}">${appLoginLink}</a>\nWelcome to a world where innovation meets traditon!\nConnect, engage, and empower your community while preserving and celebrating your rich cultural heritage.\n\n🌐 <a href="info@mynation.app">info@mynation.app</a>\n\n`
                }
              } else {
                var messageModel = {
                  messageSMS:
                    `
                  Welcome ${this.firstName},\nWe are thrilled to have you create your branded app <a href="${appLoginLink}">${appLoginLink}</a>\nWe hope you enjoy your experience and we are here to help if you have any questions\n\n🌐 <a href="info@goingmobile.app">info@goingmobile.app</a>\n\n`
                  //  ` Good Day ${this.firstName},\nWelcome to your branded Progressive Web App <a href="${rep2 + "/#/auth/login"}">${rep2 + "/#/auth/login"}</a>\nIt a  powerful tool that will enhance your online presence, engage your users, and provide a seamless experience across devices. It's a game-changer!\nHappy Building, we can’t wait to see the amazing things you’ll achieve with ${this.deployAppName}.\n\nBest regards,\n\nGoing mobile team\n\n🌐 <a href="${this.goingmobileLink}">${this.goingmobileLink}</a>\n\n📞 780 841 1880`
                }
              }
      
              if (this.phoneNo) {
                const smsModel = new SMSModel();
      
                smsModel.body = this.helperService.truncateHTML(messageModel.messageSMS, "1000");
      
                smsModel.phoneNumberList.push(this.phoneNo);
                smsModel.from = this.availablePhone;
      
                this.smsService.sendSMS(smsModel).subscribe((res) => {
      
                }, (err) => {
                  this.deployError = err.error
                  this.failedTasks.push(this.taskswzno[13])
                  this.appStatus = "Failed"
                  this.retry = true;
      
                  this.automationService.deleteCloudProject(this.appMainName).subscribe((res) => {
                  }, (err) => {
                  })
      
                  this.automationService.deleteProperty(this.appMainName, this.defaultAccountId).subscribe((res) => {
      
                  }, (err) => {
                  })
      
                })
              }
      
              // this.request.status = 'Accepted';

              this.emailService.sendEmail(emailModel).subscribe((res) => {
      
                this.request.status = 'Accepted';
                this.request.userId = this.appManagerId;
                this.getAppService.updateRequest(this.request).then(() => { });
      
                let acc = !this.isUndefiendSelected() ? this.selected : this.tempAccountId
      
                const newContent = {
                  appName: this.deployAppName,
                  backendConfName: mainName,
                  executedStatus: "success",
                  link: this.link + "/auth/login",
                  accountId: acc
                }
      
                this.getLink = this.link
                this.appInfoService.addAppInfo(newContent).then(x => { });
              }, (err) => {
                this.deployError = err.error
                this.failedTasks.push(this.taskswzno[13])
                this.appStatus = "Failed"
                this.retry = true;
                this.percent = 0;
      
                this.automationService.deleteCloudProject(this.appMainName).subscribe((res) => { }, (err) => { })
                this.automationService.deleteProperty(this.appMainName, this.defaultAccountId).subscribe((res) => { }, (err) => { })
              })
      
      
            }, (err) => {
              this.deployError = err.error
              this.failedTasks.push(this.taskswzno[13])
              this.appStatus = "Failed"
              this.retry = true;
              this.percent = 0;
      
              this.automationService.deleteCloudProject(this.appMainName).subscribe((res) => { }, (err) => { })
              this.automationService.deleteProperty(this.appMainName, this.defaultAccountId).subscribe((res) => { }, (err) => { })
      
            })
          })
      })

    
    }, (err) => { })
    this.deployAppCalled = true;
    return;
  }

  deleteProject() {
    this.automationService.deleteCloudProject(this.backendConfName).subscribe((res) => { }, (err) => { })
    this.automationService.deleteProperty(this.backendConfName, this.configuredId).subscribe((res) => {

      var model = {
        id: this.id,
        appName: this.storedAppName,
        backendConfName: this.backendConfName,
        executedStatus: "deleted",
        link: this.getLink,
        accountId: this.configuredId
      }
      this.appInfoService.updateAppInfo(model);

    }, (err) => { })

    //Start HERE

    this.automationService.DeleteProjectFrom_Server_DNS_AppPool(this.appName).subscribe(x => {
      if (x) { }
    }, (err) => {
      

    });


  }


  updateConfigSetting() {

    if (this.listOfValidAccountIds.length != 0 && !this.isUndefiendSelected()) {

      this.appInfoService.updateConfigSetting(this.selected).add(() => {
        this.valid = true;
        this.automationService.listAccountDetails().subscribe((res: any) => {
          let theAcc: any = res.find((acc: any) => acc.accountId === this.selected);
          this.configuredAnalyticsName = theAcc.name;
        })
      });

      this.closeModal();

    } else { this.closeModal(); }

  }

  toast(obj) {
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green';
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
    }, 2000);
  }

  isUndefiendSelected() { return typeof (this.selected) === 'undefined' }

  getDefaultBillingAcc() {
    this.appInfoService.getDefaultBilling().valueChanges().subscribe((response) => {
      if (response.length > 0) {
        this.defaultBillingAccount = response[0]['billingAccount'];
      }
    });
  }
}
