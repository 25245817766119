export class EmailModel {
  emailList : string[];
  subject : string;
  body : string;
  isNationApp: boolean;
  from: string;
  role: string;

  //attachments?: string[];
}

// export class Attachment{
//   type: string;
//   name: string;
//   link: string;
// }


export class CustomAdminEmailModel {
  emailList : string[];
  subject : string;
  body : string;
  host: string;
  adminEmail: string;
  appPassword: string;
  sendTo: string;
  sendFrom: string;
  from: string;
  isNationApp: boolean;
  role: string;
}