import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { CustomSectionService } from 'src/app/services/firebase/custom-section.service';
import { PermissionService } from 'src/app/services/firebase/permission.service';

@Component({
  selector: 'app-view-section',
  templateUrl: './view-section.component.html',
  styleUrls: ['./view-section.component.css']
})
export class ViewSectionComponent implements OnInit {
  @Input() calledFromEmptyDashboard
  @Input() view

  sectionElementId: any;
  sectionElement: any;
  isMobile = false;
  currentUser: any;
  deleteModalOpen = false;

  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;

  perm: any;
  permission: any;

  isAdmin : boolean;
  isUser: boolean;
  @Input() modelToPreview: any;
  customSectionId: any;
  permCustomPage: any;


  constructor(private route: ActivatedRoute,
              private customSectionService: CustomSectionService,
              private authService: AuthService,
              private router: Router,
              private permissionService: PermissionService,
              ) { }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.currentUser = this.authService.getGlobalUser();
    if (this.currentUser){
      if(this.currentUser.role == 'Admin'){
        this.isAdmin = true;
      }
     if(this.currentUser.role == 'Employee'){
        this.permissionService.getPermissionByEmployeeId(this.currentUser.uid).valueChanges().subscribe(perm => {
          if(perm.length != 0){
            this.perm = perm[0];
            this.permission = this.perm.permission[0];
            this.permCustomPage = this.perm.permission[6];
          }
        });
      }
     if(this.currentUser.role == 'User'){
      this.isUser = true;
     }
    }
    this.route.paramMap.subscribe(params => {
      this.sectionElementId = params.get('id');
    });
    if (!this.sectionElementId){
      if (this.modelToPreview){
        this.sectionElement = this.modelToPreview;
      }
    }
    this.customSectionService.getSectionElementById(this.sectionElementId).valueChanges().subscribe(elt => {
      this.sectionElement = elt[0];
      if(this.sectionElement){
        this.customSectionId = this.sectionElement.customSectionId;
      }
      if (this.modelToPreview){
        this.sectionElement = this.modelToPreview;
      }
      
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.sectionElement = this.modelToPreview
    this.calledFromEmptyDashboard
  }
  openDeleteModal(){
    this.deleteModalOpen = true;
  }
  closeDeleteModal(){
    this.deleteModalOpen = false;
  }
  navigateEditElement(sectionElt){
    if(this.isAdmin){
      this.router.navigate(['admin/edit-section-element',sectionElt.id])
    }else{
      this.router.navigate(['employee/edit-section-element',sectionElt.id])
    }
  }
  deleteSectionElement(){
    this.closeDeleteModal();
    this.customSectionService.deleteCustomSectionElement(this.sectionElement).then(() => {
      this.toast({html: 'Section deleted successfully', classes: 'red', type: 'failure'});
    });
  }
  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
      if (this.currentUser.role === 'Admin'){
        this.router.navigate(['/admin/custom-section-list/', this.customSectionId]);
      }
      if (this.currentUser.role === 'Employee'){
        this.router.navigate(['/employee/custom-section-list/', this.customSectionId]);
      }
    }, 2000);
  }
  openDeleteToast(){
    this.toast({html: 'Comment Deleted!', classes: 'red', type: 'failure', redirect: false});
  }
}
