import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CheckoutService } from 'src/app/services/checkout/checkout.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';

@Component({
  selector: 'app-subscription-success',
  templateUrl: './subscription-success.component.html',
  styleUrls: ['./subscription-success.component.css']
})
export class SubscriptionSuccessComponent implements OnInit {

  stripeSessionID: any;
  userID: any;
  showMSG = true;
  userName = '';
userEmail = ''
currency = ''
startPeriod : any;
endPeriod : any;

  constructor(private route: ActivatedRoute,
    private authService: AuthService,
    private checkout: CheckoutService,
    private memberService: FireMembersService) { 
    
    }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.stripeSessionID = params.get('id');
    });

  
    this.checkout.SubscriptionSuccess(this.stripeSessionID).subscribe(session =>{
      if(session){
        if(this.authService.loggedInUser) {
          this.userName = this.authService.loggedInUser.LastName + ' ' + this.authService.loggedInUser.GivenName
          this.userEmail = this.authService.currentUser.email;
        }
        this.startPeriod = this.convertTimestampToDate(session.current_period_start);
        this.endPeriod = this.convertTimestampToDate(session.current_period_end);
        this.currency = session.currency;

        const model = {
          uid : this.authService.currentUser.uid,
          hasActiveStripeSession : true,
          stripeCustomerID : session.customer
        }
       this.memberService.updateAccount(model).then(x => {
          this.showMSG = true;
          
        })
      }
    })


  }


  validatePurchase(){
    if(this.authService.currentUser) {

      const model = {
        uid : this.authService.currentUser.uid,
        hasActiveStripeSession : false,
      }

      this.memberService.updateAccount(model).then(x => {
       
        
      })

    }
  }


  convertTimestampToDate(timestamp: number): string {
    const date = new Date(timestamp * 1000); // Convert to milliseconds
    return date.toLocaleDateString('en-GB', { 
      day: '2-digit', 
      month: 'short', 
      year: 'numeric' 
    });
  }
}
