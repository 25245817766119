import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { Location } from '@angular/common';
import { ImageModel, ImageUploaderResponseModel } from './../../../../models/ImageModel';
import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { ProductsServiceMenuService } from 'src/app/services/api/data.service';
import { HelperService } from 'src/app/services/helper/helper';
import { PermissionService } from 'src/app/services/firebase/permission.service';
import { NotificationModel } from 'src/app/models/Messages.Model';
import { FCMService } from 'src/app/services/api/fcm.service';
import { NotificationPreferenceService } from 'src/app/services/firebase/notification-preference.service';
import { ProductsService } from 'src/app/services/firebase/products.service';
import { MediaService } from 'src/app/services/api/media.service';
import { Store } from '@ngrx/store';
import * as AppActions from 'src/app/state/app.actions';
import { Subscription } from 'rxjs';
import { selectAppSettings } from 'src/app/state/app.selectors';
import { distinctUntilChanged } from 'rxjs/operators';
import { ChangeVisibilityService } from 'src/app/services/firebase/change-visibility.service';
@Component({
  selector: 'app-create-product',
  templateUrl: './create-product.component.html',
  styleUrls: ['./create-product.component.css']
})
export class CreateProductComponent  implements OnInit {
  @Input() calledFromEmptyDashboard = ''
  @Output() closeCollapse = new EventEmitter<string>();
  @Output() productCreated = new EventEmitter<string>();
  @Output() skip = new EventEmitter<string>();
  @Output() previewSelected = new EventEmitter<string>();
  @Output() previewClosed = new EventEmitter<string>();
  @Output() goToTopOfNextPage = new EventEmitter();

  createProductForm: UntypedFormGroup;
  itemUnderCategoryForm: UntypedFormGroup;
  currentUser: any;
  model: any;
  binDoc = undefined;
  base64Image = undefined;
  base64ImageMain = undefined;
  imgErrorMsg: any;
  hasTimer = false;
  employees = []
  adminThemeColor: '';
  selectedEmployee = [];
  pricing = '';
  selectedEmployeeName = []
  videoLink: string = null;
  imageLink: string = null;
  videoUpload: File = null;
  attachedVideo: any;
  isMobile = false;
  mobileBase64Image: any;
  imgURL: any;
  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  step = 1;
  isList;
  productsName;
  currency;
  notifSettings;
  permission: any;
  haveAccessPermission = false;
  preview = false;
  modelToPreview:any = {}
  product: any;
  cancelModalOpen = false;
  createModalOpen = false;
  urlPattern = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-|=|?]*/?';
  numberPattern = /^[-+]?(\d*\.\d+|\d+\.|\.\d+|\d+)([eE][-+]?\d+)?$/;
  cropModalOpen = false;
  imageChangedEvent: any = '';

  permMember: any;
  permPES: any;
  permAnalytics: any;
  permSocial: any;
  permSpecial: any;
  perm: any;
  useBase64 = false
  uploadFileModalOpen = false;
  mediaList = [];
  newProductValues: any = {};
  returnedMediaLinks = {
    imageURL: '',
    videoUrl: ''
  };
  productRef: any;
  onLoading = false;
  createItemUnderProductModal = false;
  editItemUnderProductModal = false;
  allProducts = [];
  productCategoryUnderToBeEdited: any;
  appSetting: any;
  addDetailToProduct = true;
  base64Images = [];
  isAddingCategoryImage = false;
  // to ask the admin if he/she wants to send notification
  showPushNotificationModal = false;
  productData;
  oldUrl
  imgCounter = 0;
  uploadedImageLink = '';
  appSettings$: Subscription;
  constructor(private fb: UntypedFormBuilder,
    private authService: AuthService,
    private location: Location,
    private appSettingsService: AppSettingsService,
    private productsServiceMenuService: ProductsServiceMenuService,
    private helperService: HelperService,
    private permissionService: PermissionService,
    private apiService: FCMService,
    private productService: ProductsService,
    private notificationPreferenceService: NotificationPreferenceService,
    private mediaService: MediaService,
    private store: Store,
    private changeVisibilityService: ChangeVisibilityService) 
    {
    this.appSettings$ = this.store.select(selectAppSettings).subscribe((settings) => {
      this.appSetting = {...settings};
      this.productRef = this.appSetting.appSections.filter(s => s.originalName === 'Products')[0];
    
    });
    if (typeof window['Capacitor'] !== 'undefined' && window['Capacitor']['platform'] !== 'web') {
      this.isMobile = true;
    }
  }

  ngOnInit(): void {
    this.currentUser = this.authService.getGlobalUser();
    if (this.currentUser) {
      if (this.currentUser.role === 'Employee') {
        this.permissionService.getPermissionByEmployeeId(this.currentUser.uid).valueChanges().subscribe((perm: any) => {
          if (perm && perm.length > 0) {
            this.perm = perm[0];
            //this.permission = this.perm.permission;

            this.permMember = this.perm.permission[0]
            this.permPES = this.perm.permission[1]
            this.permAnalytics = this.perm.permission[2];
            this.permSocial = this.perm.permission[3];
            this.permSpecial = this.perm.permission[4];

            if (this.permPES.createProducts) {
              this.haveAccessPermission = true;
            }
          }
        });
      }
      if (this.currentUser.role === 'Admin') {
        this.haveAccessPermission = true;
      }
    }
    
    this.itemUnderCategoryForm = this.fb.group({
      productTitle: new UntypedFormControl('', Validators.required),
      productUnderDescription: new UntypedFormControl(''),
      productPrice: new UntypedFormControl('', Validators.pattern(this.numberPattern)),
    })
    this.createProductForm = this.fb.group({
      createdById: [''],
      productName: ['', Validators.required],
      productDescription: [''],
      backgroundColor: [''],
      hasPrice: [''],
      price: ['', [Validators.pattern(this.numberPattern)]],
      imgLink: [''],
      videoLink: [''],
      shareWithPublic: [, Validators.required],
      sharedLink: [''],
      hasSubProducts: [, Validators.required],
      commentable: [true]
    });

    if (this.currentUser && (this.currentUser.role == 'Employee' || this.currentUser.role == 'Admin')) {
      this.appSettingsService.getPushNotificationSettingsList().valueChanges().subscribe(x => {
        if (x && x.length > 0) {
          this.notifSettings = x[0]
        }
      })
    }

    this.productsServiceMenuService.productsName.subscribe(p => {
      this.productsName = p
    })

    this.productsServiceMenuService.currencyName.subscribe(c => {
      this.currency = c;
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.previewChanges(this.createProductForm?.value)
  }

  get productName() { return this.createProductForm.get('productName'); }
  get sharedLink() { return this.createProductForm.get('sharedLink'); }
  get productDescription() { return this.createProductForm.get('productDescription'); }
  get imgLink() { return this.createProductForm.get('imgLink'); }
  get hasPrice() { return this.createProductForm.get('hasPrice'); }
  get shareWithPublic() { return this.createProductForm.get('shareWithPublic'); }
  get productTitle() { return this.itemUnderCategoryForm.get('productTitle'); }
  get productUnderDescription() { return this.itemUnderCategoryForm.get('productUnderDescription'); }
  get productPrice() { return this.itemUnderCategoryForm.get('productPrice'); }
  get hasSubProducts() { return this.createProductForm.get('hasSubProducts'); }
  get price() { return this.createProductForm.get('price'); }


  truncateHTML(text: string, limit: string): string {
    return this.helperService.truncateHTML(text, limit);
  }

  getInnerText(htmlString) {
    this.helperService.getInnerText(htmlString);
  }

   // hide push notification modal
   cancelPushNotification() {
    this.showPushNotificationModal = false;
    this.backClicked();
  }

  sendPushNotification() {
    let title = this.truncateHTML(this.productData?.productName, '200')

    let notification = new NotificationModel()
    notification.title = 'Product'
    notification.body = this.truncateHTML(this.productData?.productName, '200')
    this.sendPushNotificationToUser(notification)
    this.cancelPushNotification()

    // send notification for the admin , if it is created by the employee
    if (this.notifSettings && this.currentUser && this.currentUser.role === "Employee") {
      this.sendPushNotificationToOwner(title)
    }
  }

  // send notification using role
  sendNotificationByRole(notification, role) {
    try {
      this.apiService
        .senFCMMessageToTopic(
          notification?.title,
          notification?.body,
          "",
          role === "User" ? "User_Notification" : "Owner_Notification"
        )
        .subscribe((data) => {
          // this.successMessage("Message sent successful");
        });
    } catch (err) {
      // this.errorMessage("Subscription failed");
    }

  }

  pricingSet(price: string) {
    this.pricing = price;
  }

  // send notification to user
  async sendPushNotificationToUser(notification) {

    if (this.notifSettings && this.currentUser && (this.currentUser.role === "Admin" || this.currentUser.role === "Employee")) {

      // check for whom to share the event with
      if (this.shareWithPublic?.value) {
        // share the event for users
        await this.notificationPreferenceService.sendNotificationForGroupOfUsers(notification, ['User', 'Employee'], 'events')

      }
      else {
        // share the event for users
        await this.notificationPreferenceService.sendNotificationForGroupOfUsers(notification, ['User'], 'events')

      }
    }
  }

  async sendPushNotificationToOwner(productName) {
    if (this.notifSettings && this.currentUser && this.currentUser.role === "Employee") {

      let createdByFullName = this.currentUser.GivenName + ' ' + this.currentUser.LastName
      let notification = new NotificationModel()
      notification.title = 'Employee Created a new Product'
      notification.body = `${createdByFullName} created a new product - ${productName}.`

      await this.notificationPreferenceService.sendNotificationForGroupOfUsers(notification, ['Admin'], 'products')
    }

  }

  changeVisibility() {
    this.changeVisibilityService.updateAppSections('Products');
  }

  saveProduct(value: any) {
    this.createModalOpen = false;
    const model = {
      createdById: this.currentUser.uid ? this.currentUser : '',
      productName: value.productName,
      productDescription: value.productDescription,
      hasPrice: value.hasPrice,
      price: value.price,
      backgroundColor: value.backgroundColor || '',
      imgLink: this.imageLink,
      videoLink: this.videoLink,
      productTime: '',
      sharedLink: value.sharedLink,
      shareWithPublic: value.shareWithPublic,
      productId: value.productName.replace(/\s/g, ''),
      allProducts: this.allProducts,
      hasSubProducts: value.hasSubProducts ? value.hasSubProducts : false,
      commentable: value.commentable,
      reactions: {
        likes: {
            count: 0,
            userId: [],
            usernames: []
          }
        },
        comments: [],
    };
    this.newProductValues = model;

    if (this.pricing == 'paid') {
      model.price = value.price;
      model.hasPrice = true;
    } else if (this.pricing == 'dontInclude') {
      model.price = 0;
      model.hasPrice = false;
    }

    if (this.mediaList.length > 0) {
      this.uploadFileModalOpen = true;
    } else {
      this.createProduct(model)
    }
  }
  createProduct(model) {
    if(this.calledFromEmptyDashboard){
      this.changeVisibility();
    }
    this.productService.createProduct(model).then(() => {
      // if (model.shareWithPublic) {
      if(this.calledFromEmptyDashboard){
        this.changeVisibility();
        }
      // }
      
      // set product data , so that oit can be accessble by the send push notificaton method
      this.showPushNotificationModal = true;
      this.productData = model;

      this.toast({ html: 'Successfully created a product', classes: 'green', type: 'success' });
      this.onLoading = false
      this.closeCollapseFromEmptydashboard()
      this.productCreated.emit('productCreated')
      this.goToTopOfNextPage.emit();
    }).catch((err) => {
      this.toast({ html: err, classes: 'red', type: 'failure' });
      this.onLoading = false

    });
  }

  closeCollapseFromEmptydashboard() {
    this.closeCollapse.emit('createProduct');
  }

  employeeSelected(firstName: string, lastName: string) {
    this.selectedEmployeeName.push(firstName + lastName)
  }

  backClicked() {
    this.location.back();
  }

  handleVideoInput(files: FileList) {
    this.binDoc = files[0];
    this.videoUpload = files[0]
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.attachedVideo = reader.result as string;
    };
    this.mediaList.push({ type: 'Video', file: this.videoUpload });

  }

  removeImage() {
    this.base64Image = undefined;
    this.base64ImageMain = undefined;
    this.mediaList = this.mediaList.filter((file) => {
      if (file.type !== 'Image') {
        return file;
      }
    })
  }
  removeVideo() {
    this.videoUpload = undefined;
    this.attachedVideo = undefined;
    this.mediaList = this.mediaList.filter((file) => {
      if (file.type !== 'Video') {
        return file;
      }
    })
  }

  toast(obj) {
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green';
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
      if (this.calledFromEmptyDashboard != 'Product' && !this.showPushNotificationModal) {
        this.backClicked()
      }
    }, 2000);
  }

  closeCancelModal() {
    this.cancelModalOpen = false;
  }
  previewChanges(value) {
    // this.modelToPreview = {};
    this.modelToPreview.id = 'modeltopreviewid';
    this.modelToPreview.productName = value?.productName;
    this.modelToPreview.productDescription = value?.productDescription;
    this.modelToPreview.price = value?.price;
    this.modelToPreview.backgroundColor = value?.backgroundColor || '';
    this.modelToPreview.sharedLink = value?.sharedLink;
    this.modelToPreview.productId = value?.productName.replace(/\s/g, '');
    this.modelToPreview.allProducts = this.allProducts ? this.allProducts: '';
    if (this.base64ImageMain) {
      this.modelToPreview.imgLink = this.base64ImageMain;
    }
    if (this.attachedVideo) {
      this.modelToPreview.videoLink = this.attachedVideo;
    }
    this.product = this.modelToPreview;
    // this.preview = true;
    if(this.calledFromEmptyDashboard == 'Product'){
      this.previewSelected.emit()
    }
  }

  closePreviewModal(){
    if(this.calledFromEmptyDashboard == 'Product'){
      this.previewClosed.emit()
    }
  }
  
  closeCreateModal() {
    this.createModalOpen = false;
  }

  onImageUpload(response: ImageUploaderResponseModel) {
    if (response.error) {
      this.imgErrorMsg = response.errorMessage
      return
    }
    this.binDoc = response.binDoc;
    this.imageChangedEvent = 'data:image/jpeg;base64,' + response.imgBase64;
    this.mobileBase64Image = response.imgBase64;
    this.useBase64 = true
    this.cropModalOpen = true;
  }

  fileChangeEvent(event) {
    this.imageChangedEvent = event;
    // this.cropModalOpen = true;

    if (!this.validateImage(event[0].name)) {
      this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';
      return;
    }
    this.binDoc = event[0];
    const reader = new FileReader();
    reader.readAsDataURL(event[0]);
    reader.onload = () => {
      this.base64ImageMain = reader.result as string;
      this.useBase64 = true;
      this.processCroppedImage(this.base64ImageMain);
    };
  }
  closeCroppingModal() {
    this.cropModalOpen = false;
    this.useBase64 = false;
  }

  getTheUrlsSaved(product) {
    this.returnedMediaLinks = product;
    this.uploadFileModalOpen = false;
    this.newProductValues.imgLink = this.returnedMediaLinks.imageURL;
    this.newProductValues.videoLink = this.returnedMediaLinks.videoUrl;
    this.createProduct(this.newProductValues)
  }

  processCroppedImage(event) {
    this.cropModalOpen = false;
    this.useBase64 = false;
    this.base64ImageMain = event;
    let base64result = this.base64ImageMain.split(',')[1];
    const img = new ImageModel();

    if (this.isMobile) {
      img.imgBase64 = this.mobileBase64Image;
      this.mediaList.push({ type: 'Image',imageID: this.mediaList.length+1, file: img });
    } else {
      img.imgBase64 = base64result;
      this.mediaList.push({ type: 'Image',imageID: this.mediaList.length+1, file: img });
    }
  }

  addNewProductDetails() {
    this.createItemUnderProductModal = true;
    this.clearUnderCategoryForm()
  }
  addNewCategoryUnder() {
    if(this.binDoc){
      let productUnderObj = {
        productTitle: this.productTitle.value,
        productUnderDescription: this.productUnderDescription.value,
        productPrice: this.productPrice.value
      }
      this.uploadImage(productUnderObj, false)
    } else{
      this.allProducts.push({
        productTitle: this.productTitle.value,
        productUnderDescription: this.productUnderDescription.value,
        productPrice: this.productPrice.value,
        imgLink: ''
      });
    }

    this.base64Image = '';
    this.uploadedImageLink = '';
    this.createItemUnderProductModal = false;
    this.clearUnderCategoryForm();
  }
  saveUpdateToCategoryUnder() {
    if(this.binDoc){
      let productUnderObj = {
        productTitle: this.productTitle.value,
        productUnderDescription: this.productUnderDescription.value,
        productPrice: this.productPrice.value
      }
      this.uploadImage(productUnderObj,true)
    } else{
      this.allProducts = this.allProducts.map((s) => 
      {
        if(s.productTitle == this.productCategoryUnderToBeEdited.productTitle){
          s.productTitle = this.productTitle.value;
          s.productUnderDescription = this.productUnderDescription.value;
          s.productPrice = this.productPrice.value;
          return s
        }
        return s
      }
      );
    }
    this.itemUnderCategoryForm.patchValue({
      productTitle: '',
      productUnderDescription: '',
      productPrice: ''
    })
    this.base64Image = '';
    this.editItemUnderProductModal = false;
  }

  cancelCategoryUnder() {
    this.createItemUnderProductModal = false;
    this.editItemUnderProductModal = false;
  }

  removeProductUnder(product) {
    this.allProducts = this.allProducts.filter(s => s.productTitle != product.productTitle);
  }
  editProductUnder(productUnder) {
    this.productCategoryUnderToBeEdited = productUnder;
    this.itemUnderCategoryForm.patchValue({
      productTitle: productUnder.productTitle,
      productUnderDescription: productUnder.productUnderDescription,
      productPrice: productUnder.productPrice,
      
    });
    this.editItemUnderProductModal = true;
  }
  clearUnderCategoryForm() {
    this.itemUnderCategoryForm.patchValue({
      productTitle: '',
      productUnderDescription: '',
      productPrice: '',
    });
  }
  skipSetup(){
    this.skip.emit();
  }

  handleImageInput(files: FileList) {
    if (!this.validateImage(files[0].name)) {
      this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';

      return;
    }
    this.binDoc = files[0];
    this.imgErrorMsg = undefined;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.base64Image = reader.result as string;
    };
  }
  private validateImage(name: any) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() === 'png') {
      return true;
    } else if (ext.toLowerCase() === 'jpg') {
      return true;
    } else if (ext.toLowerCase() === 'jpeg') {
      return true;
    } else {
      return false;
    }
  }
  uploadImage(prodUnderObje, isUpdate) {
    if (this.isMobile) {
      if (this.mobileBase64Image) {
        const img = new ImageModel();
        img.imgBase64 = this.mobileBase64Image;
        this.mediaService.uploadImage(img).subscribe((upload) => {
          // this.loading = true;
          if (upload) {
            // values.imgUrl = upload.imgLink;
            this.uploadFileModalOpen = false;
            this.uploadedImageLink = upload.imgLink
            if(isUpdate){
              this.allProducts = this.allProducts.map((s) => 
              {
                if(s.productTitle == this.productCategoryUnderToBeEdited.productTitle){
                  s.productTitle = prodUnderObje.productTitle;
                  s.productUnderDescription = prodUnderObje.productUnderDescription;
                  s.productPrice = prodUnderObje.productPrice;
                  s.imgLink = this.uploadedImageLink
                  return s
                }
                return s
              }
              );
            }
            else{
              this.allProducts.push({
                productTitle: prodUnderObje.productTitle,
                productUnderDescription: prodUnderObje.productUnderDescription,
                productPrice: prodUnderObje.productPrice,
                imgLink: this.uploadedImageLink
              });
            }
            this.uploadedImageLink = '';
            return;
          }
          // this.loading = false;
        });
      }
    }
    else {
      if (this.binDoc) {
        this.mediaService.UploadBinImage(this.binDoc).subscribe(
          upload => {
            if (upload) {
              this.uploadFileModalOpen = false;
              this.uploadedImageLink = upload.imgLink;
              if(isUpdate){
                this.allProducts = this.allProducts.map((s) => 
                {
                  if(s.productTitle == this.productCategoryUnderToBeEdited.productTitle){
                    s.productTitle = prodUnderObje.productTitle;
                    s.productUnderDescription = prodUnderObje.productUnderDescription;
                    s.productPrice = prodUnderObje.productPrice;
                    s.imgLink = this.uploadedImageLink
                    return s
                  }
                  return s
                }
                );
              }
              else{ 
                this.allProducts.push({
                  productTitle: prodUnderObje.productTitle,
                  productUnderDescription: prodUnderObje.productUnderDescription,
                  productPrice: prodUnderObje.productPrice,
                  imgLink: this.uploadedImageLink
                });
              }
  
              this.uploadedImageLink = '';
              this.binDoc = undefined;
              return;
            }
          }
        );
      }
    }
  }

}
