import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { FaqService } from 'src/app/services/firebase/faq.service';
import { HelperService } from 'src/app/services/helper/helper';
import { PermissionService } from 'src/app/services/firebase/permission.service';

@Component({
  selector: 'app-faq-list',
  templateUrl: './faq-list.component.html',
  styleUrls: ['./faq-list.component.css']
})
export class FaqListComponent implements OnInit {
  @Input() calledFromComponent:string;
  @Input() isShowCreated: boolean;
  @Output() closeCollapse = new EventEmitter<string>();
  @Output() faqCreated = new EventEmitter<string>();
  @Input() preview
  @Input() view

  faqsList: [];
  deleteModalOpen = false;
  faqToBeDeleted: any;
  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  editFaqForm: FormGroup;
  faqToBeEdited: any;
  openEditFaqModal = false;
  chatGptModalOpen = false;
  requestMessage: any;
  selectedInput: any;
  editModalOpen = false;
  currentUser: any;
  isAdmin = false;
  isUser = false;
  openMoveModal = false;
  p = 1;
  perm: any;
  permMember: any;
  permPES: any;
  permAnalytics: any;
  permSocial: any;
  permSpecial: any;
  permCustomPage: any;

  constructor(private fb: FormBuilder, 
    private faqService: FaqService,
    private helperService: HelperService,
    private authService: AuthService,
    private permissionService: PermissionService,
      ) { }

  ngOnInit(): void {
    this.currentUser = this.authService.getGlobalUser();
    if (this.currentUser) {
      if (this.currentUser.role === 'Admin') {
        this.isAdmin = true;
      }
      if (this.currentUser.role === 'User') {
        this.isUser = true;
      }
    }
    // this.permissionService.getPermissionByEmployeeId(this.currentUser?.uid).valueChanges().subscribe(perm => {
    //   if (perm && perm.length != 0) {
    //     this.perm = perm[0];
    //     [this.permMember, this.permPES, this.permAnalytics, this.permSocial, this.permSpecial, ,this.permCustomPage ] = this.perm.permission;
    //   }
    // });
    this.faqService.getFAQs().valueChanges().subscribe((faqs: any)=>{
      if(faqs){
        this.faqsList = faqs;
      }
    })
    this.editFaqForm = this.fb.group({
      question: ['', Validators.required],
      answer: ['', Validators.required]
    });
  }
  get question(){return this.editFaqForm.get('question')}
  get answer(){return this.editFaqForm.get('answer')}

  deleteOpen(faq){
    this.faqToBeDeleted = faq;
    this.deleteModalOpen = true;
  }
  deleteFAQ(){
    this.deleteModalOpen = false;
    this.faqService.deleteFAQ(this.faqToBeDeleted).then(()=>{
      
    this.toast({html: 'Successfully  deleted a FAQ!', classes: 'red', type: 'failure'});
    })
  }
  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
    }, 2000);
  }
  editFAQ(faq){
    this.faqToBeEdited = faq;
    this.editFaqForm.patchValue({
      question: faq.question,
      answer: faq.answer
    })
    this.openEditFaqModal = true;
  }
  closeEditModal(){
    this.editModalOpen = false;
  }
  saveFaqChanges(value){
    this.closeEditModal()
    this.openEditFaqModal = false;
    const faq = {
      question: value.question,
      answer: value.answer,
      id: this.faqToBeEdited.id
    }
    this.faqService.updateFAQ(faq).then(()=>{
      this.toast({html: 'Successfully edited a FAQ!', classes: 'green', type: 'success'});
      if(this.calledFromComponent == 'faq'){
        this.closeCollapse.emit('createFaq');
        this.faqCreated.emit('faqCreated')
      }
    }).catch(error => {
      this.toast({html: 'Can\'t edit a FAQ!', classes: 'red', type: 'failure'});
    })
  }
  onUseChatGpt() {
    this.selectedInput = this.question;
    this.requestMessage = this.helperService.getInnerText(this.question.value);
    this.chatGptModalOpen = true;
  }
  onUseChatGptA() {
    this.selectedInput = this.answer;
    this.requestMessage = this.helperService.getInnerText(this.answer.value);
    this.chatGptModalOpen = true;
  }
  closeChatGptModal() {
    this.chatGptModalOpen = false;
  }
  updateFAQquestion(event) {
    event.input.patchValue(event.message)
    this.closeChatGptModal();
  }

  getSortedList(unsortedElements: Array<any>){
    let sortedList = unsortedElements?.sort((a:any, b:any ) => {
        return a.position- b.position;
    });
    return sortedList
  }


}
