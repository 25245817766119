<div class="flex flex-col md:flex-row md:space-x-4 md:min-h-screen ">
  <!-- Image and welcome message -->
  <div class="md:w-2/5 w-full bg-brandColor center ">
    <div class="  flex flex-col justify-center mx-auto ">
      <!-- <p class="px-8 text-white text-base text-center w-9/12 mx-auto  ">Experience the Joy of Mobile Convenience. Create a Mobile Web App Without Any Coding Skills.</p> -->
      <!-- <img loading="lazy" *ngIf="isNationApp" [src]="'../../../../../assets/img/'+ imgFolder + '/Nation-logo.webp'" alt="logo"
      width="150" height="150"
    > -->
      <img *ngIf="isNationApp" src="https://demo.goingmobile.app/assets/img/Nation-logo.webp" alt="logo" width="150" height="150" > 
  
      
      <div class="relative">

    <img src="../../../../assets/img/Purple Minimalist Healthy App Instagram Post (6).png" class="md:block hidden w-full h-screen mx-auto" alt="">
    <div class="absolute top-0 w-full">
        
    </div>
    <div class="md:absolute md:block hidden top-1/3">
      <div class="bg-white px-12 py-6 rounded-lg mx-auto w-2/3 shadow-md  ">
        <div class="flex flex-col gap-y-6 justify-center">
         <div class="w-full mx-auto">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="fill-brandColor w-6 h-6 mx-auto">
            <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
            <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
          </svg>    
         </div> 
          <div>
            <p class="text-brandColor text-base mx-auto  text-center"> Login to your account and effortlessly begin the process of customizing your platform.</p>
          </div>        
        </div>
     
      
      </div>
    </div>
  </div>
    </div>
  </div>

  <!-- Form -->
  <div class=" md:w-3/5 w-full flex flex-col space-y-4  px-4 md:px-6 py-8">
    <img src="https://demo.goingmobile.app/assets/img/Going%20Mobile%20-%202.webp" class="w-10 h-10" alt="Logo" *ngIf="!isNationApp">
    <!-- Info -->

    <ng-container *ngIf="!isNationApp">
      <div class="place-content-start">
   
       <p class="font-bold text-brandColor text-2xl mx-auto md:text-4xl font-sans md:pt-12">Thank you for Registering!</p>
       <div class="md:pr-10 space-y-9 mx-auto ">
         <p class="text-brandColor text-xl   pt-4 mx-auto font-sans">  You have now joined the Mobile Web App Revolution! Your Business, Your Rules. Create and Update on Your Terms.</p> 
         <div class="place-content-start flex flex-col space-y-1 justify-start ">
         <div class="w-full flex justify-start">
           <button
             type="button"
             (click) = "navigateToURL()"    
             class="inline-block rounded bg-brandColor px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-brandColor-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-brandColor-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-brandColor-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
             Login
           </button>
         </div>
         <p class="text-brandColor text-base text-start   font-sans">Need Help? Contact us at 
         
         <a class="underline" href="https://goingmobile.app/">going mobile </a></p>
         </div>
       </div>
      </div>
     </ng-container>
   
     <ng-container *ngIf="isNationApp">
       <p class="font-bold text-center text-brandColor text-4xl font-sans" [ngStyle]="{'color': textColor}">Thank you!</p>
       <div class="space-y-4 text-lg">
         <p class="text-brandColor  text-justify font-sans">
           Your registration is complete! an email with detailed instructions will be arriving shortly guiding you through the exciting possibilities that await.
   
         </p>
         <p class="text-brandColor text-justify font-sans"> If you have any questions, don't hesitate to reach out to our team</p>
         <p [ngStyle]="{'color': textColor}" class="text-justify font-sans"> Get ready to explore, connect and thrive with us!</p>
         
         <button
           type="button"
           (click)="navigateToURL()"
           class="float-right font-bold inline-block rounded bg-brandColor px-8 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-brandColor-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-brandColor-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-brandColor-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
           Learn more
         </button>
   
       </div>
   
     </ng-container>
 
  </div>
</div>











<!-- Resend Email Modals -->
<div id="my-modal-resend" *ngIf="resendModal" class="modal"
  [ngClass]="{'modal-open': resendModal, 'modal-action': !resendModal }">
  <div class="modal-box">
    <div class="modal-content">
      <h3 class="font-bold text-lg text-center mb-2">Email Resend</h3>
      <hr />
      <p class="text-md text-center mb-1">Please enter your email address on the input box below?
      </p>
      <!-- <p class="text-md text-center mb-1">Comments archived can be found in the archived
        section</p> -->
        <div class="flex justify-center mt-2">
        <input id="email" type="email" [(ngModel)]='email' required  autocomplete="off"
        class="input input-bordered rounded-sm"
        id="Email " type="text " placeholder="Email">
        </div>

      <div class="modal-action items-center justify-between mx-6">
        <button (click)="closeResend()" class="font-bold text-customTeal border-none">Cancel</button>
        <button (click)="submit()" class="py-2 px-4 bg-customTeal text-white border-customTeal rounded-md">Resend</button>
      </div>
    </div>
  </div>
</div>