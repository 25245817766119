import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.css']
})
export class ConfirmationModalComponent implements OnInit {
  @Input() confirmationModal;
  @Input() url;
  @Input() text;
  @Output() closeModal = new EventEmitter<string>();
  @Output() saveButton = new EventEmitter<string>();

  
  constructor() { }

  

  ngOnInit(): void {
  }
  save(){
    this.saveButton.emit();
  }
  close(){
    this.closeModal.emit();
  }
}
