<ng-container *ngIf="settings">

  <div  class="min-h-screen">
    <div class="" >
      <div class="flex flex-wrap md:px-8 gap-4 w-full justify-center md:justify-start items-center pb-4"  *ngIf="(isAdmin || (permPES && permPES.createProducts)) && products && !calledFrom"  >
     
          <button (click)="navigateCreate()"
          class="flex items-center space-x-2 rounded bg-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
       
            <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 fill-white " height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/></svg>
            <span>Add {{productsName}}</span>
          </button>
          
            <div >
              <app-rename-section section="Products" ></app-rename-section>
            </div>
         
      
  
           
        <app-homepage-section-number section="Products" (confirmationModal) = "openConfirmationModal()" ></app-homepage-section-number>
            
        <button 
        type="button"
        *ngIf="products.length > 0"
        (click)="openMoveModal=true"
        class="flex items-center md:ml-5 text-customTeal space-x-2 rounded bg-white px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal border border-customTeal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
        <svg width="24px" fill="currentColor" viewBox="0 0 24 24" class="w-4 h-4">
          <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
          <path d="M0 0h24v24H0z" fill="none"></path>
        </svg>
        <span>Arrange Items</span>  
        
      </button>
  
      </div>
     
      <div *ngIf="products" class="mx-5 lg:mx-10">
        <div *ngIf="products.length > 0" class="flex flex-wrap gap-6 ">
          <div *ngFor="let product of getSortedList(products)" class="md:w-[341px] w-full">
            <!--has product list  -->
            <div  *ngIf="product.allProducts && product.allProducts.length > 0" class=" border border-gray-200 drop-shadow-lg rounded-lg hover:scale-105 hover:ease-in hover:duration-300 bg-white">
            <ng-container (click)="navigateDetails(product)">
              <div *ngIf="product.imgLink && !product.videoLink" (click)="navigateDetails(product)"  class="bg-white relative w-full h-40 md:h-48 rounded-t-xl cursor-pointer">
                
                <div class="flex justify-center h-40 md:h-48" >
                  <img [src]="product.imgLink" class="md:w-56 h-full w-full rounded-lg mt-2" style="object-fit: contain;">
                </div>
                
              </div>
              <div>
                <div *ngIf="product.videoLink && product.imgLink" (click)="navigateDetails(product)"  class="w-full h-40 md:h-48 rounded-t-xl cursor-pointer">
                  <video controls *ngIf="product.videoLink != ''" [src]="product.videoLink" class="object-cover" alt="Logo" [poster]="product.imgLink"></video>           
                </div>
                <div *ngIf="product.videoLink && !product.imgLink" (click)="navigateDetails(product)"  class="w-full h-40 md:h-48 rounded-t-xl cursor-pointer">
                  <video controls *ngIf="product.videoLink != ''" [src]="product.videoLink" class="object-cover" alt="Logo"></video>           
                </div>
                <div *ngIf="!product.imgLink && !product.videoLink" (click)="navigateDetails(product)" class="relative w-full h-40 md:h-48 rounded-t-xl cursor-pointer bg-gray-200" >  
                  
                </div>
              </div>
              
            </ng-container> 
            <div class="break-words">
              <h4  (click)="navigateDetails(product)" class="w-full bottom-0 text-black bg-white font-bold cursor-pointer max-w-sm px-4 py-2 " >{{truncateHTML(getInnerText(product.productName),60)}}</h4>
                
            </div>
            <div class=" flex flex-col w-full space-y-4 px-4 rounded-b-xl  py-4  bg-white ">
                    
              <!-- <h4  (click)="navigateDetails(product)" class=" cursor-pointer max-w-sm  " [ngStyle]="{'color':product.titleColor}">{{getInnerText(product.productName)}}</h4> -->
                
                <div *ngIf="product.allProducts " class="flex flex-col">
                  <ng-container *ngFor="let ser of product.allProducts | slice:0:2;" class="w-full">
                    <div class="flex flex-col w-full mb-5">
                      <div tabindex="0" class="w-full collapse collapse-plus border  rounded-sm border-base-300 bg-gray-100 ">
                        <input type="checkbox" class="peer" />
                        <div  class="collapse-title cursor-pointer text-base font-semibold break-words" >
                          {{ser.productTitle}}
                        </div>
                        <div class="collapse-content">
                          <p>$ {{ser.productPrice}}</p>
                          <p class="italic">{{ser.productUnderDescription}}</p>
                      
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <div class="flex justify-end" (click)="navigateDetails(product) " *ngIf="product.allProducts.length > 2 ">
                    <p class="text-sm font-bold text-customTeal">Show all</p>

                  </div>
                </div>  
         
              </div>
            </div> 
            <!-- doesnt have product list -->
            <div (click)="navigateDetails(product)" *ngIf="!product.allProducts || product.allProducts.length == 0" class=" border border-gray-200 drop-shadow-lg rounded-lg hover:scale-105 hover:ease-in hover:duration-300 ">
              
              <ng-container>
                <div *ngIf="product.imgLink && !product.videoLink" (click)="navigateDetails(product)"  class="w-full h-40 md:h-48 rounded-t-xl bg-white cursor-pointer">
                  <div class="flex justify-center h-40 md:h-48 " >
                    <img [src]="product.imgLink" class="md:w-56 h-full w-full rounded-lg mt-2" style="object-fit: contain;">
                  </div>
                </div>
                <div *ngIf="product.videoLink && !product.imgLink" (click)="navigateDetails(product)"  class="w-full h-40 md:h-48 rounded-t-xl cursor-pointer">
                  <video controls *ngIf="product.videoLink != ''" [src]="product.videoLink" class="object-cover" alt="Logo"></video>           
                </div>
                <div *ngIf="!product.imgLink && !product.videoLink" (click)="navigateDetails(product)" class="relative w-full h-40 md:h-48 rounded-t-xl cursor-pointer bg-gray-200" >  
                  
                </div>
                <div>
                  <div *ngIf="product.videoLink && product.imgLink" (click)="navigateDetails(product)"  class="w-full h-40 md:h-48 rounded-t-xl cursor-pointer">
                    <video controls *ngIf="product.videoLink != ''" [src]="product.videoLink" class="object-cover" alt="Logo" [poster]="product.imgLink"></video>           
                  </div>
                  <div *ngIf="product.videoLink && !product.imgLink" (click)="navigateDetails(product)"  class="w-full h-40 md:h-48 rounded-t-xl cursor-pointer">
                    <video controls *ngIf="product.videoLink != ''" [src]="product.videoLink" class="object-cover" alt="Logo"></video>           
                  </div>
                </div>
                
              </ng-container>
              <div class=" flex flex-col w-full space-y-4 px-4 rounded-b-xl  py-4  bg-white break-words">
                    
               
                <h4 (click)="navigateDetails(product)"  class=" cursor-pointer max-w-sm " >{{truncateHTML(getInnerText(product.productName),60)}}</h4>

                <div *ngIf="product.price">
                  <div *ngIf=" product.price!='dontInclude'">
                    <div *ngIf="product.price > 0" href="" class=" pb-2  justify-start   rounded-md baseline text-xl font-bold bottom-0 md:ml-0">
                      <ng-container *ngIf="currency"> {{product.price | currency: currency:'symbol-narrow'}}</ng-container>
                      <ng-container *ngIf="!currency">{{product.price | currency: 'USD':'symbol-narrow'}}</ng-container>
                    </div>
                  </div>
                </div>
                </div>
            </div>
          </div>
          
          <div class="w-full text-center">
            <pagination-controls (pageChange)="p = $event"></pagination-controls>
          </div>
        </div>
        <div *ngIf="products.length==0"
          class="mt-10 p-6 max-w-sm mx-auto bg-white rounded-xl shadow-lg flex items-center space-x-4 text-center">
          <div>
            <div><i class="material-icons w-20 h-20">search</i></div>
            <p class="font-bold">Sorry! No results found</p>
            <p>We couldn't find {{productsName}} that matches your search! </p>
            <button class="btn bg-slate-300 text-black hover:bg-gray-50 " (click)="clearFilters()">Okay</button>
          </div>
        </div>

      </div>
      <!-- <div class="mt-5"  *ngIf="!products">
        <div class="flex items-center justify-center">
          <img  loading="lazy" src="../../../../assets/icons/Illustrations/product-empty-Illustration-10.png"
          class="block w-64 items-center justify-center"> 
        </div>
        <br/>
        <ng-container *ngIf="!isAdmin">
          <div class="text-customTeal text-lg text-center font-bold">No {{productsName}} found.</div>
          <div class="text-gray-400 text-sm text-center font-bold">{{productsName}}  will appeare here once added!</div>
        </ng-container>
        <ng-container *ngIf="isAdmin">
          <div class="text-customTeal text-lg text-center font-bold">What are the {{productsName}}  you provide?</div>
          <div class="text-gray-400 text-sm text-center font-bold">Why not let the world know!</div>
        </ng-container>
      </div> -->

      <div *ngIf="!products" class="w-4/5 mx-auto space-y-4">
        <app-empty-state *ngIf="isAdmin && !(calledFrom == 'onBoarding')" calledFrom="product"></app-empty-state>
     <!--has product list  -->
     <p class="font-bold pt-4">
      Post Sample
    </p>
     <div   class=" border border-gray-200 drop-shadow-lg rounded-lg md:w-[341px] w-full" >
      <ng-container (click)="navigateDetails(product)">
        <div   class="relative w-full h-40 md:h-48 rounded-t-xl cursor-pointer" [style]="'background:linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.73)),url('+ emptyProduct.img +'); background-size: cover; background-repeat: no-repeat; background-position: center;'">
          <h4  class="absolute bottom-0 text-white font-bold cursor-pointer max-w-sm px-4 py-2 " >{{getInnerText(emptyProduct.postTitle)}}</h4>
        
        </div>
        <div>
        </div>
        
      </ng-container> 
      <div class=" flex flex-col w-full space-y-4 px-4 rounded-b-xl  py-4  bg-white ">
              
        <!-- <h4  (click)="navigateDetails(product)" class=" cursor-pointer max-w-sm  " [ngStyle]="{'color':product.titleColor}">{{getInnerText(product.productName)}}</h4> -->
          
          <div class="flex flex-col">
            <ng-container class="w-full">
              <div class="flex flex-col w-full mb-5">
                <div tabindex="0" class="w-full collapse collapse-plus border  rounded-sm border-base-300 bg-gray-100 ">
                  <input type="checkbox" class="peer" />
                  <div  class="collapse-title cursor-pointer text-base font-semibold" >
                    {{emptyProduct.postTitle}}
                  </div>
                  <div class="collapse-content">
                    <p>$ {{emptyProduct.price}}</p>
                    <p class="italic">{{emptyProduct.message}}</p>
                
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="product">
              <div class="flex justify-end" (click)="navigateDetails(product) " *ngIf="product.allProducts.length > 2 ">
                <p class="text-sm font-bold text-customTeal">Show all</p>
  
              </div>
            </ng-container>
          </div>  
   
        </div>
      </div> 


        
      </div>

    </div>

  </div>


  <!-- toast -->
  <app-alert class="fixed top-0 flex justify-end w-full" [toastType]="toastType" [toastMessage]="toastMessage"
    [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>
</ng-container>

<app-move-elements *ngIf="openMoveModal" [elementsList]="products" (closeClicked)="openMoveModal=false" sectionType="Products"></app-move-elements>

  