import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
// import { toast } from 'materialize-css';
import { SocialMediaService } from 'src/app/services/firebase/social-media.service';

// declare var M: any;
// declare var $: any;
@Component({
  selector: 'app-account-setting',
  templateUrl: './account-setting.component.html',
  styleUrls: ['./account-setting.component.css']
})
export class AccountSettingComponent implements OnInit {

  themeColor = environment.appTheme.adminThemeColor;
  nationOfUse = environment.firstNation.name;
  nationCommunities: any[];


  editModel: any;
  isEdit = false;
  urlPattern = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
  accountUpdateForm: UntypedFormGroup;
  hasSocial = false;
  socialID = '';

  public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')',
    ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

  constructor(
    private fb: UntypedFormBuilder,
    private location: Location,
    private appSettings: AppSettingsService,
    private service: SocialMediaService) { }

  get facebook() { return this.accountUpdateForm.get('facebook'); }
  get twitter() { return this.accountUpdateForm.get('twitter'); }
  get instagram() { return this.accountUpdateForm.get('instagram'); }
  get youtube() { return this.accountUpdateForm.get('youtube'); }

  ngOnInit() {
    this.accountUpdateForm = this.fb.group({
      facebook: ['', [ Validators.pattern(this.urlPattern)]],
      twitter: ['', [Validators.pattern(this.urlPattern)]],
      instagram: ['', [ Validators.pattern(this.urlPattern)]],
      youtube: ['', [ Validators.pattern(this.urlPattern)]],
    });

    this.service.getSocialMedia().valueChanges().subscribe(x => {
      if (x) {
        if (x.length > 0) {
          this.accountUpdateForm.patchValue({
            facebook: x[0].facebook,
            twitter: x[0].twitter,
            instagram: x[0].instagram,
            youtube: x[0].youtube
          });

          this.hasSocial = true;
          this.socialID = x[0].id;

          setTimeout(() => {
            // M.updateTextFields();
          }, 50);
        }
      }
    });

  }

  submitForm(value: any) {
    console.log('i submit ofrm');
    const model =  {
      facebook: value.facebook ? value.facebook : '',
      twitter: value.twitter ? value.twitter : '',
      instagram: value.instagram ? value.instagram : '',
      youtube: value.youtube ? value.youtube : '',
      id: ''
    };

    if (!this.hasSocial) {
      this.service.saveSocialMedia(model).then(resp => {
        // toast({ html: 'Social Media Links Successfully Saved!', classes: 'green' });
        this.backClicked();
      });
    }

    if (this.hasSocial) {
      model.id = this.socialID;
      this.service.updateSocialMedia(model).then(resp => {
        // toast({ html: 'Social Media Links Successfully Updated!', classes: 'green' });
        this.backClicked();
      });
    }
  }
  backClicked() {
    this.location.back();
  }

}
