<div #commentsContainer id="commentsContainer" class="bg-white rounded-md px-4 py-8">
  <!-- View comment -->
  <div class="space-y-4" *ngIf="post.commentable">
    <p class="text-xs font-bold text-darkGray py-4">Comment</p>
    <!-- No comment -->
    <div *ngIf="post.comments?.length == 0">No Comments</div>
    <!-- List of comments -->
    <div *ngIf="post.comments?.length > 0" class="max-h-[32rem] overflow-y-scroll">
      <div *ngFor="let comment of post.comments | slice : 0 : commentsCount">
        <div class="border-b-1 space-y-1 m-2">
          <p class="text-sm font-bold">
            {{ comment.userName }}
            <span class="float-right">
              <button
                *ngIf="isAdmin"
                (click)="openCommentDeleteModal(comment)"
                class="flex items-center space-x-2 p-2 text-red-500 border border-red-500 rounded hover:bg-red-500 hover:text-white transition duration-300 cursor-pointer"
              >
                <i class="material-icons w-5 h-5">delete</i>
                <span class="text-sm">Delete</span>
              </button>
            </span>
          </p>
          <p class="text-xs text-darkGray">
            {{ formatDate(comment.timestamp) }}
          </p>
          <p class="text-md pt-1">
            {{ comment.comment }}
          </p>
        </div>
        <hr />
      </div>
    </div>

    <!-- Show more -->
    <ng-container *ngIf="post.comments?.length > 3">
      <a (click)="toggleCommentsView()">
        <p class="text-md font-bold text-right pt-4">
          {{ showAllMessages ? "Show less comments" : "View all comments" }}
        </p>
      </a>
    </ng-container>
  </div>

  <!-- comment form -->
  <div class="form-control mt-6" *ngIf="post.commentable && currentUser">
    <form [formGroup]="commentOnPost" (ngSubmit)="addCommentToPostClicked(post, commentOnPost.value.comment)">
      <div class="input-group">
        <!-- input -->
        <input
          type="text"
          placeholder="Comment...."
          class="input input-bordered w-full"
          formControlName="comment"
        />
        <button [disabled]="!commentOnPost.valid" type="submit" class="btn btn-square bg-customTeal border-0">
          <i class="material-icons">send</i>
        </button>
      </div>
    </form>
  </div>
</div>

<!-- Delete Comment Modal -->
<app-delete-confirmation
  *ngIf="commentDeleteModalOpen"
  (deleteClicked)="deleteComment()"
  (cancelDeleteClicked)="closeCommentDeleteModal()"
  deletedFromComponent="Comment"
></app-delete-confirmation>
