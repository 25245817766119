
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})

export class ClientFormService {

  formListRef: AngularFirestoreCollection<any>;
  formRef: AngularFirestoreDocument<any>;
  
  constructor(private db: AngularFirestore) { }

  // saveNotes(model: any){
  //   const newContent = {
  //     id: this.db.createId(),
  //     Message: model.Message,
  //     timeStamp: model.timestamp,
  //     userId: model.uid,
  //     reminder: model.reminder ? model.reminder : '',
  //     archived: false,
  //   };
  //   return this.db.collection('UserNotes').doc(newContent.id).set(newContent);
  // }

  newForm(model: any)  {
    model.id  = this.db.createId();
    // const newContent={
    //   id: this.db.createId(),

    // }
    return this.db.collection('ClientForm').doc(model.id).set(model);
  }

  updateForm(model: any)  {
    return this.db.collection('ClientForm').doc(model.id).update(model);
  }

  getFormByID(id: string): AngularFirestoreDocument<any> {
    this.formRef = this.db.doc('ClientForm/' + id);
    return this.formRef;
  }

  getClientByPhoneNumber(cellPhone: any){
    return this.db.collection('ClientForm/', ref => ref.where('cellPhone', '==', cellPhone));
  }
  getClientByEmail(email: any){
    return this.db.collection('ClientForm/', ref => ref.where('Email', '==', email));
  }

  getFormList(): AngularFirestoreCollection<any[]> {
    this.formListRef = this.db.collection('ClientForm');
    return this.formListRef;
  }

  deleteForm(model){
    return this.db.collection('ClientForm').doc(model.id).delete();

  }

}
