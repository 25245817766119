import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { queryReportService } from '../../../services/google-analytics/queryReport.service';
import { format } from "date-fns"
import moment from 'moment';
import { UserService } from 'src/app/services/firebase/user.service';
import { environment } from 'src/environments/environment';
//import { monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';
import Highcharts from "highcharts/highmaps";
import worldMap from "@highcharts/map-collection/custom/world.geo.json";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { SendMessageComponent } from '../../../comp/shared/send-message/send-message.component';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { PermissionService } from 'src/app/services/firebase/permission.service';
import { FilterPipe } from '../../../filter.pipe';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'; // Import FormsModule
import { BaseChartDirective } from 'ng2-charts';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
// import { Chart, BarController, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';

// Chart.register(BarController, BarElement, CategoryScale, LinearScale, Tooltip, Legend);
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { Color, ScaleType } from '@swimlane/ngx-charts';  // Import Color if necessary
import { UserChart } from 'src/app/models/AnalyticsModel';
import { HighchartsChartModule } from 'highcharts-angular';



declare var $: any;
declare var M: any;
@Component({
  selector: 'app-analytics-user',
  templateUrl: './analytics-user.component.html',
  styleUrls: ['./analytics-user.component.css'],
  standalone: true,
  imports: [CommonModule, FormsModule, FilterPipe, BaseChartDirective, NgxChartsModule, HighchartsChartModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

})
export class AnalyticsUserComponent implements OnInit {

  public userchart1: { datasets: any[]; labels: any[]; options: any };


  ispageClicked: boolean = true;
  isuserClicked: boolean = false;
  ischannelClicked: boolean = false;
  filterByDateUser: boolean = false;
  INITIAL_STATE: any = {
    labels: [],
    values: [],
    bgColors: [],
    hoverColors: []
  };
  reportData: any = this.INITIAL_STATE
  filterResult: any
  topUserResult: any = []
  topUser: any = []
  topUsers: any = []
  filteredData: any = []
  startDate: any
  endDate: any
  defaultStartDate: any
  defaultEndDate: any
  colors = [
    "#fcba03",
    "#fa8c5c",
    "#9fc934",
    "#60d17e",
    "#45afed",
    "#7c5cdb",
    "#ce5cdb",
    "#db5c97",
  ];
  setTotal: any
  setLables: any = []
  background: any = []
  data: any = []
  personActivityClicked = false
  filterClicked = false
  filteredUser: any = []
  merge_report = {}
  merge_arr = []
  sessionValue: any
  topCountriesResult: any = [];
  top_countries_data: any = [];
  top_countries_label: any = [];
  top_countries_color: any = [];
  topLogCountriesResult: any = []
  topNonLogCountriesResult: any = []
  public userchart:UserChart | any = {};
  bgUserChart: any = []
  subscribersListId: any = [];
  subscribersDetail: any = []
  subscibers_report: any = {}
  subscribers_arr: any = []
  userIdAndName: any = []
  topUsersIdAndName: any = []
  totalSignedInUsers: any
  totalUnsignedInUsers: any
  totalActiveUsers: any
  isLoggedInChecked: any = true;
  loggedInName: any = "Non Subscribers"

  dateFilterActive: any;
  dateInvalid = false;
  propertyId = environment['firebase'].propertyId
  totalActiveUsersRes: any = []
  totalSignedInUsersRes: any = []
  totalNonLoggedInUsersRes: any = []
  // MAP  VARIABLE - HIGHCHART 
  Highcharts: typeof Highcharts = Highcharts;
  chartConstructor = "mapChart";
  chartData = [{ code3: "ABW", z: 105 }, { code3: "AFG", z: 35530 }];
  highChartDataVal: any = []
  chartOptions: Highcharts.Options = {
  };
  // Map end  
  trackStartDate: any;
  trackEndDate: any;
  createCalenderForm: UntypedFormGroup;
  openContactModal: boolean = false;
  isAdmin: boolean;
  permissionEmp: any;
  currentUser: any;
  role: any;
  setUp: any;
  appName: any;
  appLogo: any;
  greetings: any;
  signOff: any;
  email: string;
  messageForSMS: any;
  contactForm: any;
  employee: any;

  permEmp: any;
  permissionSpecialEmp: any;
  recievedData: any;
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast: boolean;
  clickedId: any;
  selectedOption: string;
  GivenName: any;
  LastName: any;

  //from a child component
  emitted: any;

  

  constructor(private qs: queryReportService, private userService: UserService, private fb: UntypedFormBuilder, private router: Router,
    private authService: AuthService,
    private appSettingsService: AppSettingsService,
    private permissionService: PermissionService,

  ) {
    // monkeyPatchChartJsTooltip();
    // monkeyPatchChartJsLegend();

    this.userchart1 = {
      datasets: [
        {
          label: 'Dataset 1',
          data: [65, 59, 80, 81, 56, 55, 40],
          fill: false,
          backgroundColor: 'rgba(75,192,192,0.4)',
          borderColor: 'rgba(75,192,192,1)',
        },
      ],
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      options: {
        responsive: true,
      },
    };

  }

  // DummyData:any = {
  //   totalNominalGDP: [
  //     { name: 'United States', value: 21.43 },
  //     { name: 'China', value: 14.34 },
  //     { name: 'Japan', value: 5.08 },
  //     { name: 'Germany', value: 3.86 },
  //     { name: 'India', value: 2.87 },
  //     { name: 'United Kingdom', value: 2.83 },
  //     { name: 'France', value: 2.72 },
  //     { name: 'Brazil', value: 2.05 },
  //     { name: 'Italy', value: 2.00 },
  //     { name: 'Canada', value: 1.74 },
  //   ]
  // };
  // dataVBC = this.DummyData.totalNominalGDP;
  // viewVBC: [number, number] = [800, 300];
  // animationsVBC = false;
  // legendVBC = true;
  // xAxisVBC = true;
  // yAxisVBC = true;
  // showYAxisLabelVBC = true;
  // yAxisLabelVBC = "Amount in Trillions ($)";

  ngx_userbarchart:any;

  dataVBC: any[];
  viewVBC: [number, number] // = [400, 400]; // Adjust the size as needed
  animationsVBC: boolean = true;
  legendVBC: boolean = true;
  xAxisVBC: boolean = true;
  // yAxisLabelVBC: boolean = true;
  showYAxisLabelVBC: boolean = true;
  yAxisLabelVBC: string = 'Sessions';
  // dataLabelFormatterVBC: any;

  dataLabelFormatterVBC(tooltipText: any) {
    return tooltipText;
  }

  ngOnInit(): void {

    // this.data = [10, 20, 30, 40, 50];
    // this.setLables = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

    // // Ensure data and setLables have the same length
    // if (this.data.length !== this.setLables.length) {
    //   console.error('Data and labels lengths do not match');
    // }

    // this.dataVBC = [
    //   {
    //     name: 'User session (Bar)',
    //     series: this.data.map((value, index) => ({
    //       name: this.setLables[index],
    //       value: value ?? 0
    //     }))
    //   }
    // ];

    // console.log('Prepared dataVBC:', this.dataVBC);


    this.currentUser = this.authService.getGlobalUser();

    if (this.currentUser) {
      this.role = this.currentUser.role;
      if (this.role === 'Admin') {
        this.isAdmin = true;
        this.appSettingsService.getAppSettingsList().valueChanges().subscribe(setup => {
          this.setUp = setup[0];
          this.appName = this.setUp.appName;
          this.appLogo = this.setUp.logo;
          this.greetings = this.setUp.greetings ? this.setUp.greetings : '';
          this.signOff = this.setUp.signOff ? this.setUp.signOff : '';
          this.email = this.greetings + ' <br /> <br />  <br /> <br />' + this.signOff;
          this.messageForSMS = '\n \n' + this.setUp.messageSMS ? this.setUp.messageSMS : '';;

        });
      } else if (this.role == 'Employee') {
        this.userService.getUserByEmail(this.currentUser.Email).valueChanges().subscribe(employee => {
          this.employee = employee[0];
          this.email = this.employee.greetings + ' <br /> <br />  <br /> <br />' + this.employee.signOff;

          this.messageForSMS = this.employee.messageSMS;
          this.contactForm.patchValue({
            body: this.email,
            message: this.messageForSMS
          })
        })

      }

      // Permission from the Global user....the Employee can't give access to other employees
      this.permissionService.getPermissionByEmployeeId(this.currentUser.uid).valueChanges().subscribe(permEmp => {
        if (permEmp.length !== 0) {
          this.permEmp = permEmp[0];
          this.permissionEmp = this.permEmp.permission[0];
          this.permissionSpecialEmp = this.permEmp.permission[4];
        }
      });

    }
    let currYear = (new Date()).getFullYear();
    let defaultStartDate = new Date(currYear, 1, 1)

    let defaultEndDate = moment().toDate()
    this.defaultEndDate = format(new Date(defaultEndDate), "yyyy-MM-dd")
    this.defaultStartDate = format(new Date(defaultStartDate), "yyyy-MM-dd")

    setTimeout(
      () => {
        this.drawGraphGA4(this.defaultStartDate, this.defaultEndDate);
        this.topViewersAndCountriesGA4(this.defaultStartDate, this.defaultEndDate);

      }, 500)

    this.createCalenderForm = this.fb.group({
      analyticsStartDate: ['', [Validators.required]],
      analyticsEndDate: ['', [Validators.required]],

    });

  }


  userGraph() {

    this.userIdAndName.forEach((uidn) => {
      this.setLables.push(uidn.name);
      this.data.push(uidn.sessions)
    })

    this.userchart = {
      "datasets": [
        {
          type: 'bar',
          label: 'User session (Bar)',
          data: this.data,
          borderColor: this.bgUserChart,
          backgroundColor: this.bgUserChart,
        }, {
          type: 'line',
          label: 'User session ( Line)',
          data: this.data,
          fill: false,
          borderColor: 'rgb(54, 162, 235)'
        }
      ],
      "labels": this.setLables,
      "options": {
        "legend": {
          "text": "",
          "display": true,
        },
        "scales": {
          "yAxes": [{
            "ticks": {
              "beginAtZero": true
            },
            "scaleLabel": {
              "display": true,
              "labelString": 'Sessions'
            }
          }],
          "xAxes": [{
            "ticks": {
              "min": "Monday",
              "max": "Sunday",
            },
            "scaleLabel": {
              "display": true,
              "labelString": 'Users'
            }
          }],
        },
        "elements": {
          "point": {
            "radius": 5,
            "hitRadius": 5,
            "hoverRadius": 10,
            "hoverBorderWidth": 2
          }
        }
      }
    }
     // Initialize data
  // this.data = [10, 20, 30, 40, 50]; // Replace with your data
  // this.setLables = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']; // Replace with your labels

  //   // ngx-charts replacement
  //   this.dataVBC = [
  //     {
  //       name: 'User session (Bar)',
  //       series: this.data.map((value, index) => ({
  //         name: this.setLables[index],
  //         value: value
  //       }))
  //     }
  //   ]

    // this.data = [10, 20, 30, 40, 50];
    // this.setLables = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];


    this.viewVBC = (this.data.length == 1) ? [400, 400] : [700, 400];
    // // Ensure data and setLables have the same length
    if (this.data.length !== this.setLables.length) {
      console.error('Data and labels lengths do not match');
    }

    this.dataVBC = [
      {
        name: 'User session',
        series: this.data.map((value, index) => ({
          name: this.setLables[index],
          value: value ?? 0
        }))
      }
    ];

    this.setLables = [];
    this.data = [];
    this.bgUserChart = []
    // this.dataVBC = []

  }

  map(isLoggedIn: boolean) {

    const lower = this.top_countries_label.map(element => {
      console.log(element, "country code")
      return element.toLowerCase();
    });

    console.log(this.totalActiveUsersRes[0]?.MetricValues[0]?.Value, "     total active users result here   -------------->")
    console.log("top countries list what does this var contain ", this.top_countries_data)
    const intData = this.top_countries_data.map(element => {
      let percentage = this.toFixed((parseInt(element) / parseInt(this.totalActiveUsersRes[0]?.MetricValues[0].Value)) * 100, 2)
      console.log(percentage, ' in percentage', this.totalActiveUsersRes[0], "total")
      
      return percentage;
    });
    let results = [
      this.top_countries_label,
      intData

    ]
    for (let i = 0; i < this.top_countries_label.length; i++) {
      let cardValues = [];
      results.forEach(el => {

        cardValues.push(el[i]);
      });
      // this.highChartDataVal.push(col)
      this.highChartDataVal.push(cardValues);
    }

    this.chartOptions = {
      chart: {
        map: worldMap,

      },

      title: {
        text: isLoggedIn ? "Location of logged in user who visited your site" : "Location of non-logged in user who visited your site",
      },

      mapNavigation: {
        enabled: true,
        buttonOptions: {
          alignTo: "spacingBox"
        }
      },
      legend: {
        enabled: true
      },
      colorAxis: {
        min: 0,

      },
      xAxis: {
        labels: {
          enabled: false
        }
      },

      credits: {
        enabled: false
      },
      series: [
        {
          type: "map",
          name: "Country & total visitors",
          states: {
            hover: {
              color: isLoggedIn ? "#00BFFF" : "#90EE90"
            },
          },

          dataLabels: {
            enabled: false,
            format: "{point.name}",
            color: 'red',
          },
          tooltip: {
            valueSuffix: '%'
          },
          allAreas: true,
          data: this.highChartDataVal,

        }
      ],

    }

    this.top_countries_data = [];
    this.top_countries_label = [];
    this.top_countries_color = [];
    this.highChartDataVal = []

  }


  isUndefinedFilterRes(): boolean { return typeof this.filterResult === 'undefined' || this.filterResult === null || this.filterResult.length === 0; }

  isUndefinedTopUser(): boolean { return typeof this.topUserResult === 'undefined' || this.topUserResult === null || this.topUserResult.length === 0; }

  isUndefinedTopLogCountries(): boolean { return typeof this.topLogCountriesResult === 'undefined' || this.topLogCountriesResult === null || this.topLogCountriesResult.length === 0; }

  isUndefinedTopNonLogCountries(): boolean { return typeof this.topNonLogCountriesResult === 'undefined' || this.topNonLogCountriesResult === null || this.topNonLogCountriesResult.length === 0; }

  isUndefined(result): boolean { return typeof result === 'undefined' || result === null || result.length === 0 }

  onKey(event: any, type: string) { this.sessionValue = event.target.value;  }

  drawGraphGA4(startDate, endDate) {

    this.filterClicked = true;
    this.personActivityClicked = false;

    this.reportData.labels = []
    this.reportData.values = []
    this.reportData.bgColors = []
    this.data = []

    this.setTotal = 0

    this.startDate = !this.isValidStartDate(startDate) ? format(new Date(startDate), "yyyy-MM-dd") : startDate;
    this.endDate = !this.isValidEndDate(endDate) ? format(new Date(endDate), "yyyy-MM-dd") : endDate;

    let filter_session = this.sessionValue ? this.sessionValue : 0

    this.filterResult = []
    this.filteredData = []
    this.filteredUser = []
    this.merge_report = {}
    this.merge_arr = []

    this.subscribersListId = []
    this.subscribersDetail = []
    this.subscibers_report = {}
    this.subscribers_arr = []
    this.userIdAndName = []


    this.getTotalUnsignedUsers(this.startDate, this.endDate, filter_session);

    this.qs.getUserSession(this.propertyId, filter_session, this.startDate, this.endDate).subscribe((rep) => {
      if (rep && Object.keys(rep).length > 0) {

        console.log("   WHAT IS GA4 REPORT LOOK LIKE  user session    ", rep.Result.Rows)
        let session_wz_id: any = []
        let counts = {}
        this.filterResult = rep.Result.Rows;

        if (!this.isUndefinedFilterRes()) {
          console.log(" BEFORE ITERATING WHAT IS IN filterResult array   ", this.filterResult)
          // Get the unique user IDs.
          const userIds = this.filterResult.map(row => row.DimensionValues[0].Value);

          // Create a map of user IDs to session counts.
          const sessionCounts = userIds.reduce((counts, userId) => {
            counts[userId] = (counts[userId] || 0) + 1;
            return counts;
          }, {});

          // Convert the map to an array of objects.
          const filteredData = Object.entries(sessionCounts).map(([k, v]) => {
            return { [k]: v };
          });

          // Set the filtered data and total count.
          this.filteredData = filteredData;
          this.setTotal = filteredData.length;

          this.subscribersListId = this.filteredData;
          this.filteredData.forEach((obj) => {
            Object.keys(obj).forEach((key) => {
              var randNum = Math.floor(Math.random() * this.colors.length);
              this.bgUserChart.push(this.colors[randNum])

              this.userService.getUserById(key).valueChanges().subscribe(user => {
                if (user && user.length > 0) {
                  this.filteredUser = user;
                  this.merge_report = {
                    id: this.filteredUser[0].GivenName,
                    sessions: obj[key]
                  }
                  this.merge_arr.push(this.merge_report);

                  if (this.userIdAndName.some((uidn) => uidn.id === key)) {
                    // console.log("        GETTING SAME ID NOT TO BE ADDED TO THE ARRAY    ", key)
                  } else {
                    let firstInitial: string = (typeof (this.filteredUser[0].GivenName) == 'string' && this.filteredUser[0].GivenName.length > 0) ? (Array.from(this.filteredUser[0].GivenName)[0]).toString().toUpperCase() : ""
                    let lastInitial: string = (typeof (this.filteredUser[0].LastName) == 'string' && this.filteredUser[0].LastName.length > 0) ? (Array.from(this.filteredUser[0].LastName)[0]).toString().toUpperCase() : ""
                    let nameInitials: string = (typeof (firstInitial) == 'string' && typeof (lastInitial)) != 'string' ?
                      firstInitial + "." : (typeof (firstInitial) == 'string' && typeof (lastInitial)) == 'string' ?
                        firstInitial + "." + lastInitial : ""
                    // (Array.from(this.filteredUser[0].GivenName)[0]).toString().toUpperCase() + "." + (Array.from(this.filteredUser[0].LastName)[0]).toString().toUpperCase()
                    this.userIdAndName.push({
                      id: key,
                      name: nameInitials,
                      sessions: obj[key],
                      role: this.filteredUser[0].role
                    })

                  }

                } else {
                  console.log("user not found")
                }
              })
            });
          });

          // FOR THE SUBSCRIBERS

          this.subscribersListId.forEach((obj) => {
            Object.keys(obj).forEach((key) => {

              this.userService.getUserById(key).valueChanges().subscribe(user => {
                if (user && user.length > 0) {
                  this.subscribersDetail = user;
                  let firstName = (typeof this.subscribersDetail[0].GivenName === 'string' 
                  && typeof this.subscribersDetail[0].GivenName != null) ? this.subscribersDetail[0].GivenName : ""
                  let lastName = (typeof this.subscribersDetail[0].LastName === 'string'
                  && typeof this.subscribersDetail[0].LastName != null) ? this.subscribersDetail[0].LastName : ""
                  if (this.subscribersDetail[0].role == 'Employee' || this.subscribersDetail[0].role == 'User') {
                    this.subscibers_report = {
                      name: firstName + " " + lastName,
                      email: this.subscribersDetail[0].Email,
                      phone: this.subscribersDetail[0].CellPhone,
                      role: this.subscribersDetail[0].role,
                      id: key,
                      session: obj[key],
                      img: this.subscribersDetail[0].profileImgLink

                    }
                    const found = this.subscribers_arr.some(el => el.id === key);
                    if (!found) this.subscribers_arr.push(this.subscibers_report);

                  }

                } else {
                  console.log("user not found")
                }
              })
            });
          });

          // -------------------      


        } else {
          console.log('No Users found for the specified sessions')
        }
      } else {
        console.log("                 GETTING IN HERE rep lenght is 0             ")
      }

      setTimeout(() => {
        if (!this.isUndefinedFilterRes() && this.filterResult.length > 0 && this.filterResult && this.userIdAndName.length > 0) {
          this.userGraph()
        } else {
          this.filterResult = null;
          console.log('No Users found for the specified sessions')
        }

      }, 1500)

    });

  }

  getTotalUnsignedUsers(startDate, endDate, filter_session) {
    this.startDate = startDate;
    this.endDate = endDate;

    this.totalSignedInUsers = 0
    this.totalUnsignedInUsers = 0
    this.totalActiveUsers = 0

    this.qs.getTotalUnsignedUsers(this.propertyId, filter_session, startDate, endDate).subscribe((rep) => {
      if (rep && Object.keys(rep).length > 0) {

        console.log("getting the data    ------->    ", rep.Reports[0].Rows)

        this.totalActiveUsersRes = rep.Reports[0].Rows
        console.log(" total active users    ", this.totalActiveUsersRes)


        if (!this.isUndefined(this.totalActiveUsersRes)) {
          this.totalActiveUsers = rep.Reports[0].Rows[0].MetricValues[0].Value

        }

        this.totalSignedInUsersRes = rep.Reports[1].Rows

        if (!this.isUndefined(this.totalSignedInUsersRes)) {
          this.totalSignedInUsers = rep.Reports[1].Rows[0].MetricValues[0].Value

        }
        this.totalNonLoggedInUsersRes = rep.Reports[2].Rows
        if (!this.isUndefined(this.totalNonLoggedInUsersRes)) {
          let rows = this.totalNonLoggedInUsersRes;
          let sumOfNonLoggedIn = 0;

          if (rows.length > 1) {
            rows.forEach((r) => {
              sumOfNonLoggedIn += parseInt(r.MetricValues[0].Value)

            })
          } else {
            sumOfNonLoggedIn = rows[0].MetricValues[0].Value
          }
          this.totalUnsignedInUsers = sumOfNonLoggedIn;

        }

      }
    })
  }

  toFixed(num, fixed) {
    fixed = fixed || 0;
    fixed = Math.pow(10, fixed);
    return Math.floor(num * fixed) / fixed;
  }

  isValidStartDate(startDate) { return startDate.includes("daysAgo") || startDate === "yesterday" || startDate === "today";  }
  isValidEndDate(endDate) { return endDate.includes("daysAgo") || endDate === "yesterday" || endDate === "today";  }

  // --------------  top 10 users,  countries

  topViewersAndCountriesGA4(startDate, endDate) {
    this.startDate = startDate;
    this.endDate = endDate;
    this.topUsers = []
    this.topUsersIdAndName = []

    let filter_session = this.sessionValue ? this.sessionValue : 0


    this.topUserResult = []
    this.topCountriesResult = [];
    this.topLogCountriesResult = [];
    this.topNonLogCountriesResult = [];

    this.startDate = !this.isValidStartDate(startDate) ? format(new Date(startDate), "yyyy-MM-dd") : startDate;
    this.endDate = !this.isValidEndDate(endDate) ? format(new Date(endDate), "yyyy-MM-dd") : endDate;

    this.qs.getTopViewersAndCountries(this.propertyId, filter_session, this.startDate, this.endDate).subscribe((rep) => {
      if (rep && Object.keys(rep).length > 0) {
        this.topUserResult = rep.Result.Reports[0].Rows;
        if (!this.isUndefinedTopUser()) {
          this.topUserResult.forEach((row: any) => {
            this.userService.getUserById(row.DimensionValues[0].Value).valueChanges().subscribe(user => {
              if (user && user.length > 0) {
                this.topUser = user;
                if (this.topUsers.includes(this.topUser[0].GivenName) && this.topUsers != null) {

                } else {
                  this.topUsers.push(this.topUser[0].GivenName)
                }
              }
            })
          })

        } else {
          console.log('No Users found with session duration more than 3 minutes.')
        }


        this.topLogCountriesResult = rep.Result.Reports[2].Rows
        this.topNonLogCountriesResult = rep.Result.Reports[3].Rows

        this.loggedInStatusChecker()

      } else {
        console.log("Report is null, no data/analytics/hits recieved")
      }

      setTimeout(() => {

        if (!this.isUndefinedTopUser() && this.topUsers.length > 0 && this.topUserResult) {

        } else {
          this.topUserResult = null;
        }

      }, 1500)

    })

  }

  setUserFlags(isDaily: boolean, isWeekly: boolean, isMonthly: boolean): void {
    this.isuserClicked = true;
    this.ispageClicked = false;
    this.ischannelClicked = false;
    this.filterByDateUser = false;
    this.dateFilterActive = false;

    const dateRange = isDaily ? "yesterday" : isWeekly ? "7daysAgo" : isMonthly ? "30daysAgo" : this.defaultStartDate;
    this.drawGraphGA4(dateRange, 'today')
    this.topViewersAndCountriesGA4(dateRange, 'today')

  }

  dailyUsers() { this.setUserFlags.call(this, true, false, false)  }
  weeklyUsers() { this.setUserFlags.call(this, false, true, false)  }
  monthlyUsers() {  this.setUserFlags.call(this, false, false, true)   }

  customDateForUsers() {
    this.isuserClicked = true;
    this.ispageClicked = false
    this.ischannelClicked = false;

    this.filterByDateUser = true;
    this.dateFilterActive = false;
  }

  customDateUpdateUsers(start_date, end_date) {
    let startdate = start_date.value;
    let endDate = end_date.value;
    let comparison: boolean | any;
    comparison = format(new Date(endDate), "yyyy-MM-dd") > format(new Date(startdate), "yyyy-MM-dd")
    if (comparison) {
      this.dateInvalid = false;

      this.drawGraphGA4(startdate, endDate);
      this.topViewersAndCountriesGA4(startdate, endDate)
    } else {
      this.dateInvalid = true;
    }
  }


  topCountriesData(isLoggedIn: boolean) {
    this.loggedInName = isLoggedIn ? "Subscribers" : "Non Subscribers"
    let undefinedChecker = () => isLoggedIn ? this.isUndefinedTopLogCountries() : this.isUndefinedTopNonLogCountries()
    if (!undefinedChecker()) {

      let country_log: any = []
      let counts = {}

      let result = isLoggedIn ? this.topLogCountriesResult : this.topNonLogCountriesResult
      result.forEach((row: any) => {
        let country = row.DimensionValues[1].Value
        country_log.push({
          [country]: parseInt(row.MetricValues[0].Value)
        })
      })

      country_log.forEach(function (x) {
        counts[x] = (counts[x] || 0) + 1;

      });

      let tot = country_log.reduce((a, obj) => {
        let [k, v] = Object.entries(obj)[0]
        a[k] = (a[k] || 0) + v
        return a
      }, {})

      let final = Object.entries(tot).map(([k, v]) => {
        return { [k]: v }
      })
      final.forEach((obj) => {
        Object.keys(obj).forEach((key) => {
          var randNum = Math.floor(Math.random() * this.colors.length);
          this.background.push(this.colors[randNum])
          this.top_countries_data.push(obj[key]);
          console.log("what are the added objects to the top countries data ", obj)
          this.top_countries_label.push(key.toLowerCase());
          if (this.top_countries_color.includes(this.colors[randNum])) {
            randNum = Math.floor(Math.random() * this.colors.length);
            this.top_countries_color.push(this.colors[randNum])
          } else {
            this.top_countries_color.push(this.colors[randNum])

          }
        });
      });

    } else {
      console.log("No top countris.")
    }


  }


  loggedInStatusChecker() {
    this.isLoggedInChecked = !this.isLoggedInChecked

    if (this.isLoggedInChecked) {
      this.topCountriesData(true)
      if (!this.isUndefinedTopLogCountries() && this.topLogCountriesResult.length > 0 && this.topLogCountriesResult && this.isLoggedInChecked === true) {
        this.map(true)
      } else {
        this.topLogCountriesResult = null;
        console.log('No countries found')
      }

    } else {
      this.topCountriesData(false)
      if (!this.isUndefinedTopNonLogCountries() && this.topNonLogCountriesResult.length > 0 && this.topNonLogCountriesResult && this.isLoggedInChecked === false) {
        this.map(false)
      } else {
        this.topNonLogCountriesResult = null;
        console.log('No countries found')
      }
    }

  }


  dateChangeStartDate() {  this.trackStartDate = $('#analyticsStartDate').val();  }

  dateChangeEndDate() {  this.trackEndDate = $('#analyticsEndDate').val();  }

  get analyticsStartDate() { return this.createCalenderForm.get('analyticsStartDate'); }
  get analyticsEndDate() { return this.createCalenderForm.get('analyticsEndDate'); }

  viewEmployeeProfile(item) {
    if (item.role == 'Employee') {
      this.router.navigate(['/admin/employee-detail/', item.id]);
    }
    if (item.role == 'User') {
      this.router.navigate(['/admin/client-detail/', item.id]);
    }

  }

  openModal(item, option) {
    this.openContactModal = true;
    this.clickedId = item.id;
    this.selectedOption = option ? option : "Email";
    this.getUserName(this.clickedId);
  }

  closeModal() { this.openContactModal = false;  }

  onSendClicked(event) {
    this.emitted = event;
    this.toast(this.emitted);
  }

  statusIdentifier(data: any) {
    this.recievedData = data;
    console.log(this.recievedData, " this is the data received from child.")
    if (this.recievedData.messageSent === true) {
      this.toast({ html: 'SMS Message sent Successfully', classes: 'green', type: 'success' });
    }
    if (this.recievedData.messageSent === false) {
      this.toast({ html: 'Error occured sending SMS', classes: 'red', type: 'failure' });
    }
    if (this.recievedData.emailSent === true) {
      this.toast({ html: 'Email sent Successfully', classes: 'green', type: 'success' });
    }
    if (this.recievedData.emailSent === false) {
      this.toast({ html: 'Error occured', classes: 'red', type: 'failure' });
    }
    if (this.recievedData.closeClicked === true) {
      this.closeModal();
    }

  }

  toast(obj) {
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green';
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
    }, 3000);
  }


  openModal1(option: string) {
    this.selectedOption = option;
    // Add code to show the modal
  }

  getUserName(id: any | string) {
    this.userService.getUserById(id).valueChanges().subscribe(employee => {
      if (employee.length > 0) {
        this.employee = employee[0];
        this.GivenName = this.employee.GivenName;
        this.LastName = this.employee.LastName;
      }
    });
  }

  closeEventClicked(data: any) { this.closeModal(); }

  messageNotSentEvent(data: any) {
    if (data) {
      this.toast({ html: 'Error occured sending SMS', classes: 'red', type: 'failure' });
    }
    this.closeModal()
  }

  emailNotSentEvent(data: any) {
    if (data) {
      this.toast({ html: 'Error occured', classes: 'red', type: 'failure' });
    }
    this.closeModal()
  }

  emailSentEvent(data: any) {
    console.log(data, "    is the email successfully sent?")
    if (data) {
      this.toast({ html: 'Email sent Successfully', classes: 'green', type: 'success' });
    }
    this.closeModal()
  }

  messageSentEvent(data: any) {
    if (data) {
      this.toast({ html: 'SMS Message sent Successfully', classes: 'green', type: 'success' });
    }
    this.closeModal()
  }

}


