import { Component, OnInit } from '@angular/core';


declare var M: any;
declare var $: any;

@Component({
  selector: 'app-messaging',
  templateUrl: './messaging.component.html',
  styleUrls: ['./messaging.component.css'],

})
export class MessagingComponent implements OnInit {

 
  constructor() {
  }

  ngOnInit() {    
  }

}
