import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  ChatGptFilterModel,
  ImageGenerateModel,
  ImageGenerateResponseModel,
} from "src/app/models/chatGptModel";
import { ChatGptService } from "src/app/services/api/chat-gpt.serivce";

import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-image-generator",
  templateUrl: "./image-generator.component.html",
  styleUrls: ["./image-generator.component.css"],
})
export class ImageGeneratorComponent implements OnInit {
  chatForm: FormGroup;
  chatResponse = [];
  ayrshareResponse = "";
  selectedResponse: any;
  resLoading: boolean = false;
  maxResponseCount: number = 5;
  originalQuestion: string = "";

  chatCount: number = 0;

  @ViewChild("messagesContainer") private messagesContainer: ElementRef;
  @ViewChild("messagesForm") private messagesForm: ElementRef;

  @Input() inputType;
  @Input() theInput;
  @Input() fromComponent;
  @Input() requestMessage;
  @Input() hideButton = false;
  @Input() filter = new ChatGptFilterModel();

  @Output() onImageSelect = new EventEmitter();
  @Output() closeImageGenerateModal = new EventEmitter();
  title;
  user;

  titleOptions = [
    "Ask me anything, I'm here to help you like a trusted friend.",
    "No question is too big or small, I'm here to provide answers to them all.",
    "Think of me as your personal information guru, ask away!",
    "There's no need to hesitate, ask me anything on your mind.",
    "From the simple to the complex, I'm ready to answer any question you ask.",
    "Don't hold back, ask me anything and let's get the answers you need.",
    "Your curiosity is welcome here, ask me anything and satisfy your thirst for knowledge.",
    "I'm your go-to source for information, so ask me anything that comes to mind.",
    "Your questions are my priority, ask away and I'll provide the answers you seek.",
  ];

  userCounter: any;
  currentDate = new Date();
  isLimitGPT: boolean = false;
  maxKeyWords: number = 3;
  appDescription: any;
  selectedImage: any;
  imageUrls = [];
  constructor(
    private chatGptService: ChatGptService,
    private fb: FormBuilder,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.chatForm = this.fb.group({
      content: ["", [Validators.required]],
      response: [""],
    });
  }

  closeModal() {
    this.closeImageGenerateModal.emit();
  }

  parseEscapedJson(escapedJsonString) {
    // Step 1: Remove surrounding double quotes from the string
    let jsonString = escapedJsonString.slice(1, -1); // Remove leading and trailing double quotes

    // Step 2: Replace double backslashes with a single backslash (escaping)
    jsonString = jsonString.replace(/\\\\/g, "\\");

    // Step 3: Replace escaped double quotes with actual double quotes
    jsonString = jsonString.replace(/\\"/g, '"');

    // Step 4: Replace escaped newline characters with actual newlines
    jsonString = jsonString.replace(/\\n/g, "\n");

    // Step 5: Parse the JSON string into an object
    let jsonObject = JSON.parse(jsonString);

    return jsonObject;
  }

  async convertUrlToFile(url: string, fileName: string): Promise<File> {
    let fileNames = "image.jpg";
    // Fetch the image data
    const response = await fetch(url);

    // Convert response to Blob
    const blob = await response.blob();

    // Convert Blob to File
    const file = new File([blob], fileName, { type: blob.type });

    return file;
  }

  selectImage(image) {
    this.selectedImage = image;

    console.log(image, " the image");

    fetch(image["url"])
      .then((response) => {
        console.log(response, "The response from fetch");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob(); // Convert response to Blob
      })
      .then((blob) => {
        const file = new File([blob], "image.jpg", { type: blob.type });
        console.log(file, "The file object");
        this.onImageSelect.emit(file); // Emit the File object
      })
      .catch((error) => {
        console.error("Error fetching or converting image:", error);
      });
  }

  submitChat(values) {
    let model = new ImageGenerateModel();
    model.message = values.content;
    // model.message = this.generateContent(values);

    let role = this.user ? this.user.GivenName : "User";

    let userRequest = {
      message: {
        role: role,
        content: values.content,
      },
    };
    this.chatResponse.push(userRequest);

    this.resLoading = true;
    this.chatGptService.generateImage(model).subscribe((res: any) => {
      this.resLoading = false;
      console.log(res, " the response from the api");

      if (res) {
        console.log(res, " the res");
        let response = JSON.parse(JSON.parse(res));
        console.log(response, " the response");
        let img = response["data"][0];
        this.selectedImage = response["data"][0];
        this.imageUrls.push(response["data"][0]);

        // if (response.choices && response.choices.length > 0) {
        //   response.choices.map((choice) => this.chatResponse.push(choice));
        //   this.selectResponseChoice(
        //     this.chatResponse[this.chatResponse.length - 1],
        //     false
        //   );
        // }
        // //added
        // if(this.user.role == 'Admin' || this.user.role == 'Employee'){
        //   this.chatCount++;
        //   if(this.userCounter){
        //     this.userCounter.chatGptCounter = this.chatCount;
        //     this.counter.updateCounter(this.userCounter);
        //   }else{
        //     const counter = {
        //       id: this.user.uid,
        //       chatGptCounter : this.chatCount,
        //       SMSTotalCounter: 0,
        //     }
        //     this.counter.saveCounter(counter);
        //   }
        //   }
      }
    });
  }
}
