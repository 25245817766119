import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-update-owner-profile',
  templateUrl: './update-owner-profile.component.html',
  styleUrls: ['./update-owner-profile.component.css']
})
export class UpdateOwnerProfileComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
