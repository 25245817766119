<!-- facebook , fbg , linkedin  -->

<div class="flex flex-col w-full md:w-1/2 border border-gray-300 rounded-lg h-max p-4 gap-y-4" *ngIf="['facebook','linkedin','fbg'].includes(data.platform)">
    <!-- Profile Picture, Name and Date -->
    <div class="flex space-x-4 ">
        <div class="avatar ">
            <div class="w-14 rounded-full ">
                <img loading="lazy" *ngIf= "data.user.userImage" [src]="data.user.userImage" />
                
                <img
                    loading="lazy"

                    *ngIf= "!data.user.userImage"
          
                    data-src="https://api.goingmobile.app/media/personal-app-assets/img/webp/emptyProfilePic.webp"
                                                
                    srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/emptyProfilePic.webp"
                                                
                    fallback="https://api.goingmobile.app/media/personal-app-assets/img/png/emptyProfilePic.png"
                                                
                    alt="ananymous profile picture."> 
            </div>
        </div>
        <div class="flex flex-col space-y-1 ">
            <p class="text-bold font-bold ">{{data.user.displayName}}</p>
            <p class="text-xs text-darkGray ">{{data.scheduleDate != '' ? data.scheduleDate : currentDate | date:'short'}}</p>
        </div>
    </div>
    <!-- Content -->
    <div class="">
        <p class=" text-xs">{{data.post}}</p>
    </div>
    <!-- Image -->
    <div class=" ">
        <img loading="lazy" *ngIf="data.imageUrls && data.imageUrls.length > 0" [src]="data.imageUrls[0]" class="object-cover w-full h-52 " alt="Image">
        <div class="row video-container" *ngIf="(!data.imageUrls || data.imageUrls.length > 0) && (data.videoUrls && data.videoUrls.length > 0)">
            <video   [src]="data.videoUrls[0]"  class="object-cover w-full h-52 "alt="Video"></video>
        </div>
        <!-- <img *ngIf="!data.imageUrls || data.imageUrls.length == 0" src="../../../../assets/img/emptyProfilePic.webp" class="object-cover w-full h-52 " alt="Image"> -->
    </div>

    <!-- Reactions -->

    <div class="">
        <div class="flex sticky justify-evenly  items-center   ">
            <!-- Like -->
            <div class="flex flex-row items-center space-x-2 ">
                <button>
                    <i class="fa fa-thumbs-up fa-xl"></i>
                    </button>
                <span class="text-xs text-center font-bold">Like</span>
            </div>
            <!-- comment -->
            <div class="flex flex-row items-center space-x-2">
                <button>
                    <i class="fa fa-comment"></i>
                          
                    </button>
                <span class="text-xs text-center font-bold">Comment</span>
            </div>
            <!-- share -->
            <div class="flex flex-row items-center space-x-2">
                <button>
                    <i class="fa fa-share fa-xl"></i>
                    </button>
                <span class="text-xs text-center font-bold">Share</span>
            </div>

        </div>
    </div>
</div>

<!-- instagram  -->

<div class="flex flex-col w-full md:w-1/2 border border-gray-300 rounded-lg h-max p-4 gap-y-4" *ngIf="data.platform =='instagram'">
    <!-- Profile Picture, Name  -->
    <div class="flex space-x-4 ">
        <div class="avatar ">
            <div class="w-14 rounded-full ">
                <img loading="lazy" *ngIf= "data.user.userImage" [src]="data.user.userImage" />
                <img
                loading="lazy"

                *ngIf= "!data.user.userImage"
      
                data-src="https://api.goingmobile.app/media/personal-app-assets/img/webp/emptyProfilePic.webp"
                                            
                srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/emptyProfilePic.webp"
                                            
                fallback="https://api.goingmobile.app/media/personal-app-assets/img/png/emptyProfilePic.png"
                                            
                alt="ananymous profile picture."> 
            </div>
        </div>
        <div class="flex flex-col space-y-1 ">
            <p class="text-bold font-bold ">{{data.user.displayName}}</p>

        </div>
    </div>
    <!-- Image -->
    <div >
        <img loading="lazy" *ngIf="data.imageUrls && data.imageUrls.length > 0" [src]="data.imageUrls[0]" class="object-cover w-full h-52 " alt="Image">
    </div>

    <!-- Reactions -->

    <div>
        <div class="flex sticky space-x-6 items-center   ">
            <!-- Like -->
            <div class="flex flex-row items-center space-x-2 ">
                <button>
                    <i class="fa fa-heart-o"></i>
                    </button>

            </div>
            <!-- comment -->
            <div class="flex flex-row items-center space-x-2">
                <button>
                    <i class="fa fa-comment-o"></i>
                          
                    </button>

            </div>
            <!-- share -->
            <div class="flex flex-row items-center space-x-2">
                <button>
                    <i class="fa fa-paper-plane-o"></i>
                    </button>

            </div>

        </div>
    </div>
    <!-- Content -->
    <div>
        <p class=" text-xs">{{data.post}}</p>
    </div>
</div>

<!-- pinterest  -->


<div class="flex flex-col w-full md:w-1/2 border border-gray-300 rounded-lg h-max p-4 gap-y-4" *ngIf="data.platform =='pinterest'">
    <!-- Image -->
    <div>
        <img loading="lazy" *ngIf="data.imageUrls && data.imageUrls.length > 0" [src]="data.imageUrls[0]" class="object-cover w-full h-52 " alt="Image">
    </div>
    <!-- Profile Picture, Name  -->
    <div class="flex space-x-4 ">
        <div class="avatar ">
            <div class="w-10 rounded-full ">
                <img loading="lazy" *ngIf= "data.user.userImage" [src]="data.user.userImage" />
                <img
                loading="lazy"

                *ngIf= "!data.user.userImage"
      
                data-src="https://api.goingmobile.app/media/personal-app-assets/img/webp/emptyProfilePic.webp"
                                            
                srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/emptyProfilePic.webp"
                                            
                fallback="https://api.goingmobile.app/media/personal-app-assets/img/png/emptyProfilePic.png"
                                            
                alt="ananymous profile picture."> 
            </div>
        </div>
        <div class="flex flex-col space-y-1 ">
            <p class="text-bold font-bold ">{{data.user.displayName}}</p>

        </div>
    </div>

    <div class="space-y-2">
        <p class="font-bold text-center">Title</p>
        <p class=" text-xs text-center">{{data.post}}</p>
    </div>

    <!-- Reactions -->

    <div>
        <div class="flex sticky justify-evenly  items-center   ">
            <!-- comment -->
            <div class="flex flex-row items-center">
                <button>
                    <i class="fa fa-comment"></i>
                </button>

            </div>
            <!-- down -->
            <div class="flex flex-row items-center space-x-2 ">
                <button class="p-2 px-4  rounded-full bg-veryLightGray">
                    <span class="text-xs text-center font-bold">read it</span>
                 </button>

            </div>

            <!-- comment -->
            <div class="flex flex-row items-center space-x-2">
                <button class="p-2 px-4  rounded-full bg-red-700">
                    <span class="text-xs text-center text-white font-bold">Save</span>
            </button>

            </div>
            <!-- share -->
            <div class="flex flex-row items-center space-x-2">
                <button>
                    <i class="fa fa-share"></i>
                    </button>
            </div>

        </div>
    </div>

</div>

<!-- reddit  -->

<div class="flex flex-col w-full md:w-1/2 border border-gray-300 rounded-lg h-max p-4 gap-y-4" *ngIf="data.platform =='reddit'">
    <!-- Profile Picture, Name and Date -->
    <div class="flex space-x-4 ">
        <div class="avatar ">
            <div class="w-14 rounded-full ">
                <img loading="lazy" *ngIf= "data.user.userImage" [src]="data.user.userImage" />
                <img
                loading="lazy"

                *ngIf= "!data.user.userImage"
      
                data-src="https://api.goingmobile.app/media/personal-app-assets/img/webp/emptyProfilePic.webp"
                                            
                srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/emptyProfilePic.webp"
                                            
                fallback="https://api.goingmobile.app/media/personal-app-assets/img/png/emptyProfilePic.png"
                                            
                alt="ananymous profile picture."> 
            </div>
        </div>
        <div class="flex flex-col space-y-1 ">
            <p class="text-bold font-bold ">{{data.user.displayName}}</p>
            <p class="text-xs text-darkGray ">{{data.scheduleDate != '' ? data.scheduleDate : currentDate | date:'short'}}</p>
        </div>
    </div>
    <!-- Content -->
    <div>
        <p class=" text-xs">{{data.post}}</p>
    </div>
    <!-- Image -->
    <div class=" ">
        <img loading="lazy" *ngIf="data.imageUrls && data.imageUrls.length > 0" [src]="data.imageUrls[0]" class="object-cover w-full h-52 " alt="Image">
    </div>

    <!-- Reactions -->

    <div>
        <div class="flex sticky justify-evenly  items-center   ">
            <!-- Like -->
            <div class="flex flex-row items-center space-x-2 ">
                <button>
                    <i class="fa fa-thumbs-o-up"></i>
                </button>
                <span class="text-xs text-center font-bold">11.2k</span>
            </div>
            <!-- down -->
            <div class="flex flex-row items-center space-x-2 ">
                <button>
                    <i class="fa fa-thumbs-o-down"></i>
                 </button>
                <span class="text-xs text-center font-bold">1.2k</span>
            </div>

            <!-- comment -->
            <div class="flex flex-row items-center space-x-2">
                <button>
                    <i class="fa fa-comment"></i>
                          
                    </button>
                <span class="text-xs text-center font-bold">123</span>
            </div>
            <!-- share -->
            <div class="flex flex-row items-center space-x-2">
                <button>
                    <i class="fa fa-share"></i>
                    </button>
                <span class="text-xs text-center font-bold">Share</span>
            </div>

        </div>
    </div>
</div>

<!-- twitter  -->

<div class="flex  w-full md:w-1/2 border border-gray-300 rounded-lg h-max p-4 space-x-4" *ngIf="data.platform =='twitter'">
    <div class="avatar ">
        <div class="w-14 h-14 rounded-full ">
            <img loading="lazy" *ngIf= "data.user.userImage" [src]="data.user.userImage" />
            <img
               loading="lazy"

               *ngIf= "!data.user.userImage"
      
               data-src="https://api.goingmobile.app/media/personal-app-assets/img/webp/emptyProfilePic.webp"
                                            
                srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/emptyProfilePic.webp"
                                            
                fallback="https://api.goingmobile.app/media/personal-app-assets/img/png/emptyProfilePic.png"
                                            
                alt="ananymous profile picture."> 
        </div>
    </div>

    <div class="flex flex-col space-y-4">
        <p class="text-bold font-bold ">{{data.user.displayName}}</p>
        <p class=" text-xs">{{data.post}}</p>
        <div class=" ">
            <img loading="lazy" *ngIf="data.imageUrls && data.imageUrls.length > 0" [src]="data.imageUrls[0]" class="object-cover w-full h-52 " alt="Image">
        </div>
        <!-- Reactions -->

        <div>
            <div class="flex sticky justify-evenly space-x-6  items-center   ">

                <!-- comment -->
                <div class="flex flex-row items-center">
                    <button>
                    <i class="fa fa-comment-o"></i>
                          
                    </button>

                </div>

                <!-- Retweet -->
                <div class="flex flex-row items-center ">
                    <button>
                        <i class="fa fa-retweet"></i>
                </button>

                </div>
                <!-- Like -->
                <div class="flex flex-row items-center  ">
                    <button>
                    <i class="fa fa-heart-o"></i>
                    </button>

                </div>
                <!-- share -->
                <div class="flex flex-row items-center ">
                    <button>
                        <i class="fa fa-share"></i>
                    </button>

                </div>

            </div>
        </div>
    </div>
</div>

<!-- tiktok  -->

<div class="flex space-x-4 w-full md:w-1/2 border border-gray-300 rounded-lg h-max p-4 gap-y-4" *ngIf="data.platform =='tiktok'">
    <!-- Profile Picture  -->
    <div class="avatar ">
        <div class="w-14 h-14 rounded-full ">
            <img loading="lazy" *ngIf= "data.user.userImage" [src]="data.user.userImage" />
            <img
            loading="lazy"

            *ngIf= "!data.user.userImage"
   
            data-src="https://api.goingmobile.app/media/personal-app-assets/img/webp/emptyProfilePic.webp"
                                         
             srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/emptyProfilePic.webp"
                                         
             fallback="https://api.goingmobile.app/media/personal-app-assets/img/png/emptyProfilePic.png"
                                         
             alt="ananymous profile picture."> 
        </div>
    </div>
    <div class="space-y-4">
        <div class="flex space-x-4 ">

            <div class="flex flex-col space-y-1 ">
                <p class="text-bold font-bold ">{{data.user.displayName}}</p>
                <p class=" text-xs ">{{data.post}}</p>

            </div>
        </div>
        <div class="flex space-x-2">
            <!-- Image -->
            <div>
                <!-- <img src="https://api.lorem.space/image/face?hash=445566" class="object-cover w-72 h-96 " alt="Image"> -->
            <video *ngIf="data.videoUrls && data.videoUrls.length > 0" [src]="data.videoUrls[0]" class="object-cover w-full h-52 " alt="Image"></video>

            </div>
            <div class="flex flex-col space-y-4 justify-end">
                <!-- Like -->
                <div class="flex flex-col items-center space-y-1 ">
                    <button class="bg-veryLightGray px-4 py-3 rounded-full">
        <i class="fa fa-heart-o"></i>
        </button>
                    <span class="text-xs font-bold">9876</span>
                </div>
                <!-- Comment -->
                <div class="flex flex-col items-center ">
                    <button class="bg-veryLightGray px-4 py-3 rounded-full">
            <i class="fa fa-commenting-o"></i>
            </button>
                    <span class="text-xs font-bold">98</span>
                </div>

                <!-- share -->
                <div class="flex flex-row items-center ">
                    <button class="bg-veryLightGray px-4 py-3 rounded-full">
            <i class="fa fa-share"></i>
        </button>

                </div>


            </div>
        </div>
    </div>
</div>

<!-- youtube  -->

<div class="flex flex-col w-full md:w-1/2 border border-gray-300 rounded-lg h-max p-4 gap-y-4" *ngIf="data.platform =='youtube'">
    <!-- Image -->
    <div>
        <!-- <img src="https://api.lorem.space/image/face?hash=445566" class="object-cover w-full h-52 " alt="Image"> -->
        <video *ngIf="data.videoUrls && data.videoUrls.length > 0" [src]="data.videoUrls[0]" class="object-cover w-full h-52 " alt="Image"></video>

    </div>
    <div>
        <p class="font-bold">{{data.post}}</p>

    </div>


    <!-- Reactions -->

    <div>
        <div class="flex  justify-evenly  items-center   ">
            <!-- like -->
            <div class="flex flex-col items-center">
                <button>
                    <i class="fa fa-thumbs-o-up"></i>
                </button>
                <span class="text-xs">2.5k</span>
            </div>
            <!-- like -->
            <div class="flex flex-col items-center">
                <button>
                    <i class="fa fa-thumbs-o-down"></i>
                </button>
                <span class="text-xs">2.5k</span>
            </div>

            <!-- share -->
            <div class="flex flex-col items-center">
                <button>
                    <i class="fa fa-share"></i>
                </button>
                <span class="text-xs">share</span>
            </div>
            <!-- clip -->
            <div class="flex flex-col items-center">
                <button>
<i class="fa fa-scissors"></i>
</button>
                <span class="text-xs">clip</span>
            </div>

            <!-- save -->
            <div class="flex flex-col items-center">
                <button>
                    <i class="fa fa-square-plus"></i>
</button>
                <span class="text-xs">save</span>
            </div>
        </div>


    </div>
    <hr>
    <!-- Profile Picture, Name  -->
    <div class="flex space-x-4 ">
        <div class="avatar ">
            <div class="w-10 rounded-full ">
                <img loading="lazy" *ngIf= "data.user.userImage" [src]="data.user.userImage" />
                <img
                loading="lazy"
                
                *ngIf= "!data.user.userImage"
    
                data-src="https://api.goingmobile.app/media/personal-app-assets/img/webp/emptyProfilePic.webp"
                                            
                srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/emptyProfilePic.webp"
                                            
                fallback="https://api.goingmobile.app/media/personal-app-assets/img/png/emptyProfilePic.png"
                                            
                alt="ananymous profile picture."> 
            </div>
        </div>
        <div class="flex flex-col space-y-1 ">
            <p class="text-bold font-bold ">{{data.user.displayName}}</p>

        </div>
    </div>
</div>