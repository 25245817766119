import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiKeyManagerService } from './apiKeyManager.Service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class  FCMService{

  url = environment.firstNation.apiUrl + 'api/FCM/';
  fcmUrl = environment.externalApi.fcmApiUrl + 'fcm/';
  projectId = environment.firebase.projectId;
  senderId = environment.fcm.senderID;
  serverKey = environment.fcm.serverKey;
  

  constructor(private httpClient: HttpClient,  private keyManager: ApiKeyManagerService) { }

//   subscribe user a topic
  subscribeToTopic(topic: any, registrationToken: any): Observable<any> {
    const headers = new HttpHeaders({  'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(this.url + 'SubscribeToTopicUsingRegistrationToken/', JSON.stringify({token: registrationToken, topic: topic, projectId: this.projectId}), { headers });
  }

  //   subscribe multiple user registration tokens to a specfic topic
  subscribeTokensToTopic(topic: any, registrationTokens: any): Observable<any> {
    const headers = new HttpHeaders({  'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(this.url + 'SubscribeMultipleRegistrationTokensToTopic/', JSON.stringify({tokens: registrationTokens, topic: topic, projectId: this.projectId}), { headers });
  }

  //   subscribe user a topic
  subscribeToTopicUsingUUUID(topic: any, userId: any): Observable<any> {
    const headers = new HttpHeaders({  'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(this.url + 'SubscribeToTopic/', JSON.stringify({userId: userId, topic: topic, projectId: this.projectId}), { headers });
  }

  //   subscribe user multiple topic
  subscribeToMultipleTopic(topics: any, userId: any): Observable<any> {
    const headers = new HttpHeaders({  'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(this.url + 'SubscribeToMultipleTopics/', JSON.stringify({topics: topics, userId: userId, projectId: this.projectId}), { headers });
  }

  //   subscribe user to multiple topic using registration token
  subscribeRegistrationTokenToMultipleTopic(topics: any, registrationTokens: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(this.url + 'SubscribeRegistrationTokenToTopic/', JSON.stringify({topics: topics, registrationTokens: registrationTokens, projectId: this.projectId}), { headers });
  }

  //   unsubscribe user a topic
  unsubscribeToTopic(topic: any, token: any): Observable<any> {
    const headers = new HttpHeaders({  'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(this.url + 'UnSubscribeRegistrationTokensFromTopic/', JSON.stringify({tokens: [token], topic: topic, projectId: this.projectId}), { headers });
  }

   //   unsubscribe user a topic
   unsubscribeRegistrationTokensFromTopic(topic: any, tokens: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(this.url + 'UnSubscribeRegistrationTokensFromTopic/', JSON.stringify({tokens: tokens, topic: topic, projectId: this.projectId}), { headers });
  }

  // un subscribe user from a topi uisng uuis
  unsubscribeToTopicUsingUUID(topic: any, userId: any): Observable<any> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(this.url + 'UnsubscribeToTopicUsingUUID/', JSON.stringify({userID: userId, topic: topic, projectId: this.projectId}), { headers });
  }

//   to send FCM message for specfic user
  senFCMMessageForUser(title: any, message: any, registrationToken: any): Observable<any> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(this.url + 'SendFCMMessage/', JSON.stringify({title, message, registrationToken}), { headers });
  }

  //   to send FCM message for specfic user
  senFCMMessageForAllUser(title: any, message: any): Observable<any> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(this.url + 'SendMessageForAll/', JSON.stringify({title, message}), { headers });
  }

  //   to send FCM message for group of users using topic
  senFCMMessageToTopic(title: any, message: any, image: any,topic: any): Observable<any> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(this.url + 'SendFCMMessageToTopic/', JSON.stringify({title, message, image, topic, projectId: this.projectId}), { headers });
  }
  

  // creating device group for existing user 
  createFCMDeviceGroup(notification_key_name: any, registration_ids: any): Observable<any> {
    const headers = new HttpHeaders({ Authorization: "key="+this.serverKey,  'Content-Type': 'application/json', 'project_id' : this.senderId });

    return this.httpClient.post<any>(this.url + 'CreateUserDeviceGroup/', JSON.stringify({operation: "create", registration_ids, notification_key_name,notification_key: null,}), { headers });

    // return this.httpClient.post<any>(this.fcmUrl + 'notification/', JSON.stringify({operation: "create", notification_key_name, registration_ids}), { headers });
  }

  // creating device group for existing user 
  updateDeviceGroup(notification_key_name: any,notification_key: any,  registration_ids: any, operation: any): Observable<any> {
    const headers = new HttpHeaders({ Authorization: "key="+this.serverKey,  mode: 'no-cors','Content-Type': 'application/json; charset=utf-8', 'project_id' : this.senderId });
    // return this.httpClient.post<any>(this.fcmUrl + 'notification/', JSON.stringify({notification_key_name, notification_key, registration_ids}), { headers });

    return this.httpClient.post<any>(this.url + 'UpdateUserDeviceGroup/', JSON.stringify({operation: operation, registration_ids, notification_key_name,notification_key: notification_key}), { headers });
  }



}
