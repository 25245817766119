<div class="bg-veryLightBlue ">
  <div class="flex flex-col space-y-5 p-8 md:py-20 pt-12 "
    [ngClass]="data.textPosition ? data.textPosition : 'text-center items-center'">
    <!-- <p class=" md:w-2/6 font-bold text-3xl text-customTeal">
      {{data.sectionTitle}}</p> -->
    <span [innerHTML]="data.sectionTitle| noSanitize"></span>
    
    <!-- <p class="md:w-2/4">{{data.sectionDescription}}</p> -->
    <span [innerHTML]="data.sectionDescription| noSanitize"></span>

    <div class="flex justify-center rounded-lg  space-y-2 p-4" *ngIf="data.imgUrl">
      <img loading="lazy" [src]="data.imgUrl" class="object-cover w-72 border-8 rounded-md" alt="Logo">
    </div>
  </div>

</div>