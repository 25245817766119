<div class="modal modal-open">
    <div class="modal-box w-11/12 max-w-4xl overflow-hidden">
        <div class="modal-content">

            <!-- <form #feedbackForm = "ngForm">
        {{ feedbackForm.value | json }}
            <br>
            <br>
            Name: <input type="text" name = 'name' ngModel>
            Email: <input type="email" name = 'email' ngModel>
    </form> -->

            <form [formGroup]="contactForm" (ngSubmit)="sendClient(contactForm.value)">

                <div class="flex items-center mt-4 space-x-8 ">
                    <div class="flex-col text-black px-2 font-bold text-xl">Give us Feedback</div>
                </div>
                <br />


                <div>
                    <div class=" px-2 mt-2 w-full">
                        <div>
                            <label for=" " class="text-sm font-bold mb-2 "> Subject: </label>
                            <input name="subject" formControlName="subject" id="subject"
                                class="form-control mt-1 px-3 py-3 bg-white border shadow-sm border-slate-300 placeholder-slate-400 outline outline-offset-1 outline-1 focus:outline-1 focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                                placeholder="Subject" />

                            <br />
                        </div>
                        <label for=" " class="text-sm font-bold mb-2 "> Message: </label>

                        <app-editor [control]="emailbody" class="bg-white" required
                            [fromComponent]="'message'"></app-editor>

                    </div>


                </div>


                <div class="flex justify-between mt-2">
                    <button type="button" (click)="cancelSendBtnClicked()"
                        class="btn bg-white hover:bg-gray-400 hover:text-white text-black border-none">Cancel</button>
                    <button [disabled]="!contactForm.valid"
                        class="p-2 text-white bg-customTeal hover:bg-gray-100 hover:text-customTeal border-none rounded-md"
                        type="submit">Send FeedBack</button>
                </div>

            </form>
        </div>

    </div>
</div>