<div class="flex">
    <div class="w-full md:w-full">
        <div class="bg-gray-100 w-10/12 w-full ">
            <div class="font-body min-h-screen max-h-max pt-6 w-full" *ngIf="currentUser.role !== 'Employee'">
                <div class="w-full flex justify-center">
                    <!-- <div class="text-red-500 font-bold flex justify-end mb-6" (click)="deleteUser(currentUser)">Delete User</div> -->
                    <div
                        class="flex flex-col md:w-10/12 my-2 bg-white h-max p-4 md:p-12 rounded-lg space-y-4 md:justify-center">
                        <p class=" font-bold text-xl text-gray-500 ">Profile Settings</p>
                        <p class="text-sm text-black">Profile Picture.</p>

                        <form [formGroup]="EditProfileForm" (ngSubmit)="SubmitRequest(EditProfileForm.value)">
                            <div class="flex flex-col space-y-4 mb-4 md:flex-col  ">
                                <div class="flex align-middle space-x-2 items-baseline">
                                    <div class="form-control w-1/2 max-w-x justify-between">
                                        <div class="flex flex-row px-6 gap-x-3 w-full">
                                            <div *ngIf="!isMobile"
                                                class="flex justify-center content-center w-full h-44 max-w-x bg-white rounded-lg border-2 gap-x-3">

                                                <label class="custom-file-upload  self-center flex flex-col">
                                                    <i
                                                        class="fa fa-plus-circle text-customTeal text-3xl text-center self-center"></i>
                                                    <input type="file" type="file" accept="image/*"
                                                        (change)="handleFileInput($event.target.files)">
                                                    <span class="block font-bold pt-1">Upload Image</span>
                                                </label>
                                                
                                            </div>
                                            <div class="w-full" *ngIf="isMobile">
                                                <button type="button"
                                                    class="py-2 px-8 modal-button gap-2 text-black border-2 border-customTeal rounded-lg w-full"
                                                    type="button" (click)="handleMobilePhotoInput()">
                                                    Upload Images
                                                </button>
                                            </div>
                                            <div class="pt-5" *ngIf="base64Image">
                                                <p class="label-text text-[#4F4F4F] text-base font-bold leading-5 py-3">
                                                    Image</p>
                                                <div class="flex flex-wrap gap-8">

                                                    <div class="relative">
                                                        <img loading="lazy" [src]="base64Image"
                                                            class="object-cover  w-24 h-24 rounded-md cover"
                                                            alt="Image">
                                                        <button (click)="removeModal()"
                                                            class="absolute top-0 translate-x-16 -translate-y-4 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                                                            <i class="fa fa-trash text-md"></i>
                                                        </button>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class=" mx-4" *ngIf="user && user.profileImgLink">
                                                <!-- <p class="label-text text-[#4F4F4F] text-base font-bold leading-5 py-3">Previous Image</p> -->
                                                <div class="flex flex-wrap gap-8">
            
                                                    <div class="relative">
                                                        <img loading="lazy" [src]="user.profileImgLink" class="object-cover w-48 rounded-md cover"
                                                            alt="Image">
                                                        <button (click)="removeModal()"
                                                            class="absolute top-0 translate-x-16 -translate-y-4 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                                                            <i class="fa fa-trash text-md"></i>
                                                        </button>
                                                    </div>
            
                                                </div>
                                            </div>
                                        </div>


                                        <p class="text-red-500 italic text-xs" *ngIf="imgErrorMsg">
                                            <strong><small><i class="material-icons">error_outline</i>
                                                    {{imgErrorMsg}}</small></strong>
                                        </p>

                                    </div>
                                </div>

                                <div class="px-4 md:px-0 space-y-4 md:space-y-0  md:grid grid-cols-2 gap-6">
                                    <div class="flex space-y-2 md:w-96  flex-col">
                                        <label for=" " class="text-xs text-gray-500 font-bold "> First Name * </label>
                                        <input maxlength="60" formControlName="firstName" id="firstName" #firstName
                                            class=" border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline "
                                            type="text" placeholder="" required>
                                        <div *ngIf="currentUser.firstName =='' ">
                                            <small class="text-red-500 italic"> The First Name is required. </small>
                                        </div>
                                    </div>
                                    <div class="flex space-y-2 md:w-96 flex-col ">
                                        <label for=" " class="text-xs text-gray-500 font-bold "> Last Name *</label>
                                        <input formControlName="lastName" #lastName id="lastName"
                                            class=" border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline "
                                            type="text" placeholder="" required>
                                        <!-- <div *ngIf="(lastName.dirty || lastName.touched) && lastName.invalid">
                                        <small *ngIf="lastName.errors.required" class="text-red-500 italic"> The Last Name is
                                            required. </small>
                                    </div> -->
                                    </div>
                                    <!-- <div class="flex space-y-2 md:w-96 flex-col ">
                                    <label for=" " class="text-xs text-gray-500 font-bold "> Email *</label>
                                    <input #email id="email"
                                        class=" border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline "
                                        type="text" placeholder="" formControlName="email" required>
                                    <div *ngIf="(email.dirty || email.touched) && email.invalid">
                                        <small *ngIf="email.errors.required" class="text-red-500 italic"> The Email is required.
                                        </small>
                                        <small *ngIf="email.errors.email" class="text-red-500 italic"> Wrong email format.
                                        </small>
                                    </div>
                                </div> -->
                                <div class="flex space-y-2 md:w-96 flex-col ">
                                    <label for=" " class="text-xs text-gray-500 font-bold "> Phone Number</label>
                                    <input formControlName="cellPhone"  #cellPhone id="cellPhone"
                                        class=" border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-gray-200 focus:shadow-outline "
                                        type="text" placeholder=" ">
                                </div>

                                </div>
                            </div>

                            <div class="pt-6 space-y-2 flex justify-between">
                                <div class="">
                                    <button type="submit"
                                        class="flex space-x-2 px-4 md:px-6 py-2  w-max h-min text-sm focus:outline-none  ">
                                        <span>Cancel</span>
                                    </button>
                                </div>
                                <div class=" ">
                                    <button type="button" (click)="saveModal()"
                                        class="flex space-x-2 px-2 md:px-4 py-1 bg-customTeal text-white rounded-lg w-max h-min text-sm md:text-base hover:bg-darkBlue focus:outline-none  ">
                                        <span>Save Changes</span>
                                    </button>
                                </div>
                            </div>

                            <div id="my-modal-requestModal" *ngIf="saveChangeModal" class="modal"
                            [ngClass]="{'modal-open': saveChangeModal, 'modal-action': !saveChangeModal }">
                            <div class="modal-box">
                              <div class="modal-content">
                                <h3 class="font-bold text-lg text-center mb-2">Save Changes</h3>
                                <hr />
                                <p class="text-center mt-3"> Are you sure you want to edit your profile?</p>
                                <!-- <p class="text-center my-3"></p> -->
                          
                                <div class="flex justify-between mx-4 my-2 ">
                                    <label ><button type="button" (click)="closeModal()"
                                        class="py-2 px-4 rounded-md text-red-500 border-none">Cancel</button></label>

                                    <label ><button type="submit" 
                                        class="py-2 px-4 rounded-md text-white bg-customTeal border-none">Update</button></label>
                                  </div>
                              </div>
                            </div>
                          </div>

                        </form>
                    </div>
                </div>
            </div>

            <div *ngIf="currentUser.role === 'Employee'">
                <div class="flex flex-wrap flex-col md:flex-row px-4 md:gap-x-6 md:px-8 py-5 md:gap-y-4 "
                    *ngIf="currentUser">
                    <div
                        class="flex flex-col items-center md:items-start md:flex-row w-full space-x-6  bg-white md:max-w-2xl px-6 py-8 space-y-6 ">
                        <div class="avatar">
                            <div class="w-32 h-32 " *ngIf="currentUser.profileImgLink">
                                <img loading="lazy" [src]="currentUser.profileImgLink" />
                            </div>
                        </div>
                        <div class="avatar placeholder" *ngIf="!currentUser.profileImgLink">
                            <div class="bg-neutral-focus text-neutral-content w-28 h-28 mt-4 md:w-36 md:h-36">
                                <span class="text-6xl">{{currentUser.GivenName[0] | uppercase }}</span>
                            </div>
                        </div>
                        <div class="flex items-center md:items-start flex-col space-y-4 ">
                            <p class="text-lg font-bold">
                                {{currentUser.GivenName}} {{currentUser.LastName}}
                            </p>
                            <!-- <p class="text-sm text-darkGray">Last logged in {{employee.lastLogin.toDate() | date}}</p> -->
                            <div class="flex space-x-1">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20"
                                    fill="currentColor">
                                    <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                                </svg>
                                <p>{{currentUser.Email}}</p>
                            </div>
                            <div class="flex space-x-1" *ngIf="cellPhone">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20"
                                    fill="currentColor">
                                    <path
                                        d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                                </svg>
                                <p *ngIf="currentUser.cellPhone">{{currentUser.cellPhone}}</p>
                            </div>
                            <div>
                                <p *ngIf="currentUser.jobTitle"><i class="material-icons pr-2 float-left">work</i>
                                    {{currentUser.jobTitle}}</p>
                            </div>

                        </div>




                    </div>
                    <div class="flex flex-col  w-full  md:max-w-xs   space-y-2 rounded-md">
                        <div class="flex flex-col  w-full  bg-white h-max px-2 md:px-4 py-4 space-y-4 rounded-md">
                            <p class="text-center text-lg font-bold">
                                Employee Details
                            </p>
                            <div *ngIf="showEmpFromAdmin">
                                <div class="flex justify-center">
                                    <button (click)="openShowEmployee()"
                                        class=" flex items-center space-x-2 justify-center px-4 md:px-6 py-2 bg-white border-2 border-customTeal  rounded-lg w-full h-min text-sm md:text-base font-bold hover:bg-darkBlue hover:text-white focus:outline-none  ">
                                        <!-- <i class="material-icons material-symbols-outlined text-customTeal w-1 h-1 pr-6 float-left">visibility</i> -->
                                        <span *ngIf="currentUser.ShowEmployee !== 'No'">Show Employee to
                                            {{currentUser.ShowEmployee}}</span>
                                        <span *ngIf="currentUser.ShowEmployee ==='No'">Don't Show Employee</span>
                                    </button>
                                </div>

                            </div>
                            <div *ngIf="currentUser.department">
                                <p class="text-sm text-justify"><span class="font-bold text-md">Department:</span>
                                    {{currentUser.department}}</p>
                            </div>

                            <div *ngIf="currentUser.extension">
                                <p class="text-sm text-justify"><span class="font-bold text-md">Extension:</span>
                                    {{currentUser.extension}}</p>
                            </div>

                            <div *ngIf="currentUser.workPhone">
                                <p class="text-sm text-justify"><span class="font-bold text-md">Work Phone:</span>
                                    {{currentUser.workPhone}}</p>
                            </div>

                            <div *ngIf="currentUser.ShowEmployee !== 'No'">
                                <p class="text-sm text-justify"><span class="font-bold text-md">Visibility:</span> For
                                    {{currentUser.ShowEmployee}} <span *ngIf="currentUser.ShowEmployee === 'Subscriber'">Only</span></p>
                            </div>

                            <!-- <div class="py-2 bg-green-200" *ngIf="currentUser.appointment">
                                <p class="text-sm text-justify text-center flex justify-center  "><span
                                        class="font-bold text-md">{{currentUser.GivenName}} {{currentUser.LastName}}
                                        takes appointment</span></p>
                            </div>

                            <div class="py-2 bg-green-200" *ngIf="!currentUser.appointment">
                                <p class="text-sm text-justify flex justify-center"><span
                                        class="font-bold text-md">{{currentUser.GivenName}} {{currentUser.LastName}} do
                                        not take appointment</span></p>
                            </div> -->
                        </div>

                    </div>

                </div>

                <div>
                    <div class="space-y-2 pt-2 mx-8 md:max-w-2xl">
                        <p class="font-bold">Biography </p>
                        <p class="text-xs text-darkGray">Please put in your Biography in the space provided below.</p>
                        <!-- button -->
                        <form [formGroup]="biographyForm" (ngSubmit)="SubmitForm(biographyForm.value)">
                            <div class="input-field">
                                <app-editor [control]="biography" class="bg-white" [fromComponent]="'biography'"></app-editor>
                            </div>
                            <div class="md:max-w-2xl pt-3" >
                                <div class=" flex justify-end w-full">
                                    <button type="submit" [disabled]="!biographyForm.valid"
                                        class="flex space-x-2  px-4 md:px-6 py-2 bg-customTeal text-white rounded-lg w-max h-min text-sm md:text-base font-bold hover:bg-darkBlue focus:outline-none  ">
                                        <span>Submit</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <br /> <br />
    </div>

</div>



<div id="my-modal-removePicture" *ngIf="removePicture" class="modal"
[ngClass]="{'modal-open': removePicture, 'modal-action': !removePicture }">
<div class="modal-box">
    <div class="modal-content">
        <h3 class="font-bold text-lg text-center mb-2">Remove Picture</h3>
        <p class="text-center mt-3" ><span class="font-bold">{{names | titlecase}}</span> ,are you sure you want to delete your profile picture?</p>
            <hr />
            <div class="flex justify-between my-4">
                <button class="font-bold" (click)="closeRemoveModal()">Cancel</button>
                <button type="button" (click)="removeFile()"
                class="flex space-x-2  px-4 md:px-6 py-2 bg-customTeal text-white rounded-lg w-max h-min text-sm md:text-base font-bold hover:bg-darkBlue focus:outline-none  "
                >
                <span>Yes</span>
            </button>
            </div>

    </div>
</div>
</div>

<!-- Modals -->
<div id="my-modal-disable" *ngIf="updateModal" class="modal"
  [ngClass]="{'modal-open': updateModal, 'modal-action': !updateModal }">
  <div class="modal-box">
    <div class="modal-content">
      <h3 class="font-bold text-lg text-center mb-2">Subscribers Profile Update</h3>
      <hr />
      <p class="text-center mt-3"><span class="font-bold">{{user.GivenName |
          titlecase}} </span> have successfully updated profile .</p>
    </div>
  </div>
</div>


<!-- 
<div id="my-modal-addEmployee" *ngIf="addEmployeeModal" class="modal"
[ngClass]="{'modal-open': addEmployeeModal, 'modal-action': !addEmployeeModal }">
<div class="modal-box">
    <div class="modal-content">
        <h3 class="font-bold text-lg text-center mb-2">Add Employee</h3>
        
        <p class="text-center mt-3" >An Invitation email will be sent to {{names | titlecase}} {{lname | titlecase}}. So that they can register to
            this app.</p>

            <hr />

            <p class="text-center mt-3" >Are you sure you want to add <span class="font-bold">{{names | titlecase}}</span> to this app?</p>

            <div class="flex justify-between my-4">
                <button class="font-bold" (click)="closeEmployeeModal()">Cancel</button>
                <button type="submit"
                class="flex space-x-2  px-4 md:px-6 py-2 bg-customTeal text-white rounded-lg w-max h-min text-sm md:text-base font-bold hover:bg-darkBlue focus:outline-none  "
                [disabled]="!employeeForm.valid">
                <span>Yes</span>
            </button>
            </div>

    </div>
</div>
</div> -->
