
<div class="flex flex-col md:flex-row gap-x-4 w-full gap-y-4">

<div class="md:w-2/3 w-full">
  <!-- Button trigger modal -->
<div class="w-full flex md:flex-row flex-col  gap-4 justify-start px-4 items-center py-4 md:pl-10">
  <button
  (click)="newSection()"
  type="button"
  class="flex items-center rounded bg-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
  <i class="material-icons float-left mr-2">drive_file_rename_outline</i>
  Create New Page
</button>
<div>
  <div class="md:ml-8 ">
    <input type="checkbox" checked="checked" class="checkbox checkbox-sm" [(ngModel)]="showHiddenSections" name="showHiddenSections" (change)="toggleSectionView(showHiddenSections)"/>
    <span class="ml-2 " [(ngModel)]="showHiddenSections">Show hidden sections</span>
  </div>
</div>

</div>

<div class="md:hidden block px-5 pb-4">
  <div *ngIf="pageSections"  class=" flex md:flex-row flex-col gap-y-4 md:space-x-4 justify-center w-full">
    <div class="flex flex-col gap-y-4  w-full bg-white shadow-md px-4 md:px-6 py-4 md:min-h-32 rounded-md">

        <p class="font-bold text-base text-center md:text-left text-customTeal">Manage Homepage View</p>
   
      <div class="space-x-2 flex  justify-center">
        <button
        class="flex items-center rounded border-2 bg-customTeal text-white border-customteal px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-customteal transition duration-150 ease-in-out hover:border-customteal-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-customteal-600 focus:border-customteal-600 focus:text-customteal-600 focus:outline-none focus:ring-0 active:border-customteal-700 active:text-customteal-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
        (click)="navigateToLandingPageSetting()" style="margin-bottom: 5px;">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" class="w-4 h-4 stroke-white ">
          <path stroke-linecap="round" stroke-linejoin="round" d="M11.42 15.17 17.25 21A2.652 2.652 0 0 0 21 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 1 1-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 0 0 4.486-6.336l-3.276 3.277a3.004 3.004 0 0 1-2.25-2.25l3.276-3.276a4.5 4.5 0 0 0-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437 1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008Z" />
        </svg>
        Manage Page View </button>   

      </div>
    </div>
  </div>
</div>


<div class="flex md:flex-row flex-col-reverse md:space-x-4 ">
  <!-- Landing page customization -->
<!-- Show or hide menu -->

<!-- List of section -->
<div class="flex flex-wrap md:mx-auto justify-center md:justify-start  mx-auto px-2 md:px-0  gap-y-4 " >
  
<div class="flex justify-center flex-wrap gap-y-4 mx-auto">
  <div class="w-36 mx-2 bg-white md:w-56 h-40 shadow-md rounded-md"
  data-te-ripple-color="light"
  id="home"
  [routerLink]="['/admin/app-setup']"
  >

  <div  class="rounded-md bg-customTeal" >
    <div class="w-full  p-3 md:p-5  rounded-md h-28   cursor-pointer hover:shadow-md shadow-md">
     
      <div class="text-lg font-bold pt-14 text-white" >App Setup</div>
    </div>   
  </div>
  
  <div class="md:px-5 px-3 py-2">
    <div class="form-control">
      <label class="label cursor-pointer">
        <span class="text-base font-bold">App Setup</span> 
        <!-- <input type="checkbox" class="toggle  checked:bg-customTeal" [checked]="section.isEnabled" (change)="checkedChange(section)" name="visibility"/> -->
      </label>
    </div>
  </div>
  </div>
  <div *ngFor="let section of pageSections.appSections; let i = index"
  [ngClass]="{'hidden' : (section.originalName == 'Services' && (serviceType == 'Product' || serviceType =='None'))  ||  (section.originalName =='Products' && (serviceType == 'Service' || serviceType =='None')), 'hidden': section.name == 'AndroidPwaApp' || section.name == 'IOSPwaApp' || section.originalName == 'Contact Us' || section.name == 'AppStore' || section.name == 'Hero'}"
  > 
  <ng-container *ngIf="section.isEnabled || (!section.isEnabled && showHiddenSections)">

    <!-- <ng-container *ngIf="!fromFNLeaders || (fromFNLeaders && !['Services','Products'].includes(section.originalName))"> -->
    <ng-container *ngIf="validSection(section)">

    <div *ngIf="!section.isCustomSection && checkProductAndServiceVisibility(section.originalName)" class="w-36 mx-2 bg-white md:w-56 h-40 shadow-md rounded-md"
    data-te-ripple-color="light"
    id="home"
    
    >

    <div  class="rounded-md bg-customTeal" (click)="navigateTo(section.originalName)">
      <div class="w-full  p-3 md:p-5  rounded-md h-28   cursor-pointer hover:shadow-md shadow-md" [ngClass]="{'bg-gray-200': !section.isEnabled, 'text-white ': section.isEnabled}"  >
       
        <div *ngIf="section.originalName!=='Login/Signup'"  class="text-lg font-bold pt-14" [ngClass]="{'text-gray-400 ': !section.isEnabled, 'text-white ': section.isEnabled}">
          {{truncateHTML(section.name, 15)}}
        </div>
        <div *ngIf="section.originalName=='Login/Signup'" class="text-lg font-bold pt-14" [ngClass]="{'text-gray-400 ': !section.isEnabled, 'text-white ': section.isEnabled}">
          <div  onclick="rename_login.showModal()" >
            {{truncateHTML(section.name, 15)}}
          </div>
        </div>
      </div>   
    </div>
    
    <div class="md:px-5 px-3 py-2">
      <div class="form-control">
        <label class="label cursor-pointer">
          <span class="text-base font-bold">Visibility</span> 
          <input type="checkbox" class="toggle  checked:bg-customTeal" [checked]="section.isEnabled" (change)="checkedChange(section)"  name="visibility"/>
        </label>
      </div>
    </div>
    </div>

    <div *ngIf="section.isCustomSection"  class="w-36 mr-4 bg-white md:w-56 h-40 shadow-md rounded-md"
    data-te-ripple-color="light"
    id="home" 
    >
    <div  class="rounded-md bg-customTeal"  (click)="customSectionRoute(section)">
      <div class="w-full  p-3 md:p-5  rounded-md h-28   cursor-pointer hover:shadow-md shadow-md" [ngClass]="{'bg-gray-200': !section.isEnabled}"  >
        <div class="flex w-full justify-end">

      
       <div>
        <a class="bg-white px-4 py-1 space-x-1 rounded-full shadow-md" (click)="$event.stopPropagation()" (click)="openDeleteCustomPageModal(section)"> <i class="material-icons text-gray-500 text-sm">delete</i> <span class="text-gray-600 text-base font-bold">Delete</span></a>
       </div>

        </div>

        <div class="text-lg font-bold pt-8 text-white" [ngClass]="{'text-gray-400': !section.isEnabled}"> {{truncateHTML(section.name, 15)}}</div>

      </div>
     
    </div>
    
    <div class="md:px-5 px-3 py-2">

      <div class="form-control">
        <label class="label cursor-pointer">
          <span class="text-base font-bold">Visibility</span> 
          <input type="checkbox" class="toggle  checked:bg-customTeal" [checked]="section.isEnabled" (change)="customSectionVisibility(section)" name="visibility2"/>
        </label>
      </div>

    </div>
    </div>
  </ng-container>
</ng-container>


</div>
</div>
</div>


</div>
</div>

<div class="md:w-1/3 w-full md:pr-5 px-4 flex flex-col gap-y-4">
  <div class="hidden md:block ">
    <div *ngIf=" pageSections"  class=" flex md:flex-row flex-col gap-y-4 md:space-x-4  w-full">
      <div class="flex flex-col gap-y-4  w-full bg-white shadow-md px-4 md:px-6 py-4 md:min-h-32 rounded-md">
        <div>
          <p class="font-bold text-base text-customTeal">Manage Home Page View</p>
        </div>
        <div class="space-x-2 flex ">
          <button
        class="flex items-center space-x-2 rounded border-2 bg-customTeal text-white border-customteal px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-customteal transition duration-150 ease-in-out hover:border-customteal-600  hover:bg-opacity-10 hover:text-customteal-600 focus:border-customteal-600 focus:text-customteal-600 focus:outline-none focus:ring-0 active:border-customteal-700 active:text-customteal-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
        (click)="navigateToLandingPageSetting()" style="margin-bottom: 5px;">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" class="w-4 h-4 stroke-white ">
          <path stroke-linecap="round" stroke-linejoin="round" d="M11.42 15.17 17.25 21A2.652 2.652 0 0 0 21 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 1 1-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 0 0 4.486-6.336l-3.276 3.277a3.004 3.004 0 0 1-2.25-2.25l3.276-3.276a4.5 4.5 0 0 0-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437 1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008Z" />
        </svg><span>Manage Page View </span></button>   
          <!-- <button
          class="flex items-center rounded border-2 border-customteal px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-customteal transition duration-150 ease-in-out hover:border-customteal-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-customteal-600 focus:border-customteal-600 focus:text-customteal-600 focus:outline-none focus:ring-0 active:border-customteal-700 active:text-customteal-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
           [ngClass]="{'  bg-customTeal text-white': (viewType == 'list')}"
          (click)="changeView('list');" style="margin-bottom: 5px;">
          <i class="material-icons mr-3  float-left"  [ngClass]="{'text-white': (viewType == 'list'),'text-customTeal': (viewType == 'card') }">list</i>List View </button>   
          <button
          class="flex items-center rounded border-2 border-customteal px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-customteal transition duration-150 ease-in-out hover:border-customteal-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-customteal-600 focus:border-customteal-600 focus:text-customteal-600 focus:outline-none focus:ring-0 active:border-customteal-700 active:text-customteal-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
          [ngClass]="{'  bg-customTeal text-white': (viewType == 'card')}"
          (click)="changeView('card');" style="margin-bottom: 5px;">
          <i class="material-icons mr-3 text-customTeal float-left" [ngClass]="{'text-white': (viewType == 'card'),'text-customTeal': (viewType == 'list') }">view_module</i>Card View </button>
           -->
          <!-- Button trigger modal -->
          <!-- Preivew button -->
  
          <!-- <app-customize-card></app-customize-card> -->
  
        </div>
      </div>
    </div>
  

  </div>

  <!-- Appointments collapse -->
  <div class=" collapse bg-white border border-solid z-0 border-gray-200 rounded-md shadow-md ">
    <input type="checkbox" class="peer" /> 
    <div class="collapse-title flex items-center justify-between text-primary-content peer-checked:bg-white peer-checked:text-secondary-content">
      <div class="flex space-x-2 items-center" >
        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 fill-customTeal" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zm64 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm128 0v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H336zM64 400v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H208zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H336c-8.8 0-16 7.2-16 16z"/></svg>
        <p class="font-bold text-customTeal ">Appointments</p>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 fill-customTeal " height="1em" viewBox="0 0 320 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"/></svg>
    </div>
    <div class="collapse-content bg-gray-300 text-primary-content peer-checked:bg-gray-100 peer-checked:text-secondary-content"> 
      <div>
        <app-homepage-appointments></app-homepage-appointments>
      </div>

    </div>
  </div>

  <!-- User activity collapse -->
  <div class=" collapse bg-white border border-solid z-0 border-gray-200 rounded-md shadow-md ">
    <input type="checkbox" class="peer" /> 
    <div class="collapse-title flex items-center justify-between text-primary-content peer-checked:bg-white peer-checked:text-secondary-content">
      <div class=" flex space-x-2 items-center">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 fill-customTeal" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"/></svg>
        <p class="font-bold text-customTeal ">User Activity</p>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 fill-customTeal " height="1em" viewBox="0 0 320 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"/></svg>
    </div>
    <div class="collapse-content bg-primary text-primary-content peer-checked:bg-gray-100 peer-checked:text-secondary-content"> 
      <div>
        <app-user-activity></app-user-activity>
      </div>
    </div>
  </div>

  <!-- Reminders collapse -->
  <div class=" collapse bg-white border border-solid z-0 border-gray-200 rounded-md shadow-md ">
    <input type="checkbox" class="peer" /> 
    <div class="collapse-title flex items-center justify-between text-primary-content peer-checked:bg-white peer-checked:text-secondary-content">
      <div class="flex items-center space-x-2">
        <span class="relative flex h-3 w-3">
          <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-customTeal opacity-75"></span>
          <span class="relative inline-flex rounded-full h-3 w-3 bg-customTeal"></span>
        </span>
        <p class="font-bold text-customTeal">Reminders</p>

      </div>
      <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 fill-customTeal " height="1em" viewBox="0 0 320 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"/></svg>
    </div>
    <div class="collapse-content bg-primary text-primary-content peer-checked:bg-gray-100 peer-checked:text-secondary-content"> 
      <div>
        <app-reminders></app-reminders>
      </div>
    </div>
  </div>
  <!-- Counter -->
  <div class=" collapse bg-white border border-solid z-0 border-gray-200 rounded-md shadow-md " *ngIf="!noCount">
    <input type="checkbox" class="peer" /> 
    <div class="collapse-title flex items-center justify-between text-primary-content peer-checked:bg-white peer-checked:text-secondary-content">
      <div class="flex space-x-2 items-center">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 fill-customTeal" height="1em" viewBox="0 0 576 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M304 240V16.6c0-9 7-16.6 16-16.6C443.7 0 544 100.3 544 224c0 9-7.6 16-16.6 16H304zM32 272C32 150.7 122.1 50.3 239 34.3c9.2-1.3 17 6.1 17 15.4V288L412.5 444.5c6.7 6.7 6.2 17.7-1.5 23.1C371.8 495.6 323.8 512 272 512C139.5 512 32 404.6 32 272zm526.4 16c9.3 0 16.6 7.8 15.4 17c-7.7 55.9-34.6 105.6-73.9 142.3c-6 5.6-15.4 5.2-21.2-.7L320 288H558.4z"/></svg>
        <span class="text-customTeal "><b>Counter</b></span>
       </div>
      <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 fill-customTeal " height="1em" viewBox="0 0 320 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"/></svg>
    </div>
    <div class="collapse-content bg-primary text-primary-content peer-checked:bg-gray-100 peer-checked:text-secondary-content"> 
             <!-- counter -->
             <div class=" bg-white w-full rounded-md mt-4  shadow-md h-max " *ngIf="!noCount">
               <hr>
               <div
               class=" flex justify-between px-8 py-4 bg-white rounded-lg mr-2 lg:mr-10">
                  <canvas baseChart width="30" height="20"
                   [data]="doughnutChartData"
                   [labels]="doughnutChartLabels"
                   [chartType]="doughnutChartType"
                   [colors]="doughnutChartColors"
                   [options]="doughnutChartOptions">
                  </canvas> 
               </div>
         
             </div>
    </div>
  </div>
</div>
</div>

<!-- toast  -->
<app-alert class="fixed top-0 flex justify-end w-full" [toastType]="toastType" [toastMessage]="toastMessage" [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>

<!-- Delete Modal -->
<app-delete-confirmation *ngIf="deleteCustomPageModalOpen" (deleteClicked)=deleteCustomSection(customSection) (cancelDeleteClicked)="deleteCustomPageModalOpen=false" deletedFromComponent=' page'></app-delete-confirmation>

<dialog id="rename_login" class="modal">
  <div class="modal-box rounded-md md:max-w-3xl">
    <h3 class="font-bold text-xl">Rename {{selectedSectionName}}</h3>
    <input type="text" id="sectionRename" [(ngModel)]="selectedSectionName" class="mt-1 px-3 py-2 bg-white border w-full shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-customTeal focus:ring-custborder-customTeal block rounded-md sm:text-sm focus:ring-1" [placeholder]="selectedSectionName" />
    <div class="modal-action w-full">
      <form method="dialog w-full">
        <div class="flex space-x-4">
          <button onclick="rename_login.close()" class="flex items-center ml-5 space-x-2 rounded bg-white px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal border border-customTeal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"> Close </button>
          <button (click)="saveChanges()" onclick="rename_login.close()"  class="flex items-center space-x-2 rounded bg-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"> Save </button>
        </div>
      </form>
    </div>
  </div>
</dialog>


<dialog id="empty_page" *ngIf="pageIsEmptyModal" class="modal modal-open">
  <div class="modal-box rounded-md flex flex-col justify-center">
    <div class="w-full flex justify-center mb-4" >
     <div class=" bg-green-50 rounded-full p-5 w-min " >
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 stroke-green-700">
        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
      </svg>      
     </div>
    </div>
     <h3 class="font-bold text-lg text-center mb-2 text-green-600">Create {{selectedSection.displayName}}</h3>
     <p class="py-4 text-center" > You cannot enable this section without adding at least one element. Please proceed by adding a new element to activate this section.</p>

 
     <div class="modal-action flex items-center justify-center gap-x-6 ">
       <label (click)="closePageIsEmptyModal()"
         class="flex items-center rounded border border-solid border-gray-200  bg-gray-100 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
         Cancel 
       </label>
       <label>
         <button
         (click)="createNewElement(selectedSection.originalName)" 
         class="flex items-center space-x-2 rounded bg-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
         type="button">
         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" class="size-4 stroke-white">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
        </svg>
        <span class="text-white">Create a new element</span>
         </button>
        
       </label>
     </div>
   </div>
</dialog>
