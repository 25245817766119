export const environment = {
    production: true,
  
    appTheme: {
      themeColor: '#0d47a1',
      fontColor: 'teal-text text-darken-4',
      adminThemeColor: '#0d47a1',
      adminFontColor: 'teal-text text-darken-4'
    },
  
    firstNation: getAppConfig(),
    
    firebase : getFirebaseConfig(),
  
    googleMapKey: {
      key: 'AIzaSyC8o5ZIQ2BmCfBXkbemb6QEDUaEceIEMaI'
    },
  
    pusher: {
      key: '51b3ca8b4eeca8b79cc2',
    },

    calenderAPIKey: {
      apiKey: 'AIzaSyDPGW7JZg0tDmzoks1vPRE-cQ44uiKzf3c',
      CLIENT_ID: '1021892666556-70scq2159knlgvuootspjovu8fu28g0v.apps.googleusercontent.com'
    },
  
    socialMedia: {
      key:"SM7YH55-AK5MH4Y-PF1WGX5-XYMR1YT",
      url: "https://app.ayrshare.com/api/",
      domain:'mynation',
      profileUrl:'https://profile.ayrshare.com/',
      privateKey:'-----BEGIN RSA PRIVATE KEY-----\nMIICWwIBAAKBgQCposQLmyEu2xhf61bsMkoo6tBK44xLsF4BXH2MiZSfeT0lgdlD\nvtuk1R2pCN4chtX54AuB7Mva7UPQLCtHuN7Q4BxMy3T7ggJ2Cct4HjoZnGCCDIt3\nH5KXZo47vM9Cxw27R/LmnIcU+kiUwsJv21UpWAtOZjutb8JE4P3pFg00mwIDAQAB\nAn8ROnautxKnr5jKJRyu4GynuzFsaa/WABOMD9iwbUiq6aVMKePm/XtRQlPA1yW3\nv/n7ZsXEbEhH89DvU40Ru7TlVz/vrfW+nWcpe6WL/3s+5XU7qO2haKaFo0RsFC02\nyewK87EhoYANBgEcCVNXU07JDQGuQhGmMcDcek4qs0JxAkEA6hMOoL8sc3O6aYfZ\nYyga0hIhhGldlLTUZtck0+gNxOPGGxSc3KzdbIbezx4H21DC2juWHcdjihPV//Vq\nBoKS1QJBALmGg8lFUz5V2kmIP8SjSdcSBsGfPZvKwCkspzHGyQqrnc0E2kN646Dn\nyT4gsFwXAH/cNa1hgmG3u19knItYAa8CQHdQa35otEHNVdIBG0A96x31e/xAsSDh\n4/rf47fYa1xXNl4LbcHWo690OBKfHiYezpPrF6PqRMTXQ5gLApZ67FkCQQCYNBkO\nGIfQjhKFXPgj1GqRvUI5uvCKTW4U2YNZPOJWlLDjL+X4kmsI6N+azqqhAmXwf7Kx\nyVtz244nFp2vW8YVAkEAiW7/rrRsskuqXewT9aaR/skFJBqnICA41PPd/kGU9ieO\nHMeGDF+3wDnCmig7Zlqxnw2ojf4+/KPURFt/r8KdRg==\n-----END RSA PRIVATE KEY-----'
    },

    mobileAppLinks: {
      playStore: 'https://play.google.com/store/apps/details?id=app.mobile.personal',
      appleStore: 'https://apps.apple.com/us/app/going-mobile/id1616538129'
    },

    fcm:getFCMConfig(),

    externalApi: {
      fcmApiUrl: "https://fcm.googleapis.com/"
    },
  };
  

  function getAppConfig() {
    let config;
    let request = new XMLHttpRequest();
    try {
        request.open('GET', 'https://api.goingmobile.app/api/AppConfig/GetAppConfig', false);  // `false` makes the request synchronous
        request.setRequestHeader("API-KEY" , 'F1BCBF46-1225-4E49-BA69-A047C78B364A-9038C86E-7135-4588-A65E-89448B4BF13A-1C3B0AEE');
        request.setRequestHeader("Url" , window.location.origin)
        request.send(null);
  
        if (request.status === 200) {
            config = request.responseText;
        }
  
        return JSON.parse(config);
    } catch (e) {
        console.error('environment:getConfig: unable to get app config : ', e);
    }
  
    return config;
  }

  function getFirebaseConfig() {
    let config;
    let request = new XMLHttpRequest();
    try {
        request.open('GET', 'https://api.goingmobile.app/api/AppConfig/GetFirebaseConfig', false);  // `false` makes the request synchronous
        request.setRequestHeader("API-KEY" , 'F1BCBF46-1225-4E49-BA69-A047C78B364A-9038C86E-7135-4588-A65E-89448B4BF13A-1C3B0AEE');
        request.setRequestHeader("Url" , window.location.origin);
        request.send(null);
  
        if (request.status === 200) {
          console.log(window.location.origin)
            config = request.responseText;
        }
  
        return JSON.parse(config);
    } catch (e) {
        console.error('environment:getConfig: unable to get firebase config : ', e);
    }
  
    return config;
  }

  function getFCMConfig() {
    let config;
    let request = new XMLHttpRequest();
    try {
        request.open('GET', 'https://api.goingmobile.app/api/AppConfig/GetFCMConfig', false);  // `false` makes the request synchronous
        request.setRequestHeader("API-KEY" , 'F1BCBF46-1225-4E49-BA69-A047C78B364A-9038C86E-7135-4588-A65E-89448B4BF13A-1C3B0AEE');
        request.setRequestHeader("Url" , window.location.origin)
        request.send(null);
  
        if (request.status === 200) {
            config = request.responseText;
        }
  
        return JSON.parse(config);
    } catch (e) {
        console.error('environment:getConfig: unable to get app config : ', e);
    }
  
    return config;
  }
  