import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { HelperService } from 'src/app/services/helper/helper';

@Component({
  selector: 'app-product-list-default',
  templateUrl: './product-list-default.component.html',
  styleUrls: ['./product-list-default.component.css']
})
export class ProductListDefaultComponent implements OnInit {
  
  @Input() products; 
  @Input() itemNumber;
  currentUser: any;
  isAdmin = false;

  constructor(
    private helperServices: HelperService,
    private authService: AuthService,
    private router: Router

  ) { }

  ngOnInit(): void {
    this.currentUser = this.authService.getGlobalUser();

    if (this.currentUser && this.currentUser.role === 'Admin'){
      this.isAdmin = true;
    }
  }
  truncateHTML(text: any, limit): string {
    return this.helperServices.truncateHTML(text, limit);
   }

   getInnerText(text){
    return this.helperServices.getInnerText(text);
  }
  navigateDetails(product){
    if (this.currentUser){
      if (this.currentUser.role === 'User'){
        this.router.navigate(['/user/view-product', product.id]);
      }
      if (this.currentUser.role === 'Admin'){
        this.router.navigate(['/admin/products', product.id]);
      }
      if (this.currentUser.role === 'Employee'){
        this.router.navigate(['/employee/products', product.id]);
      }
    }
    // check if it is this one
      else{
          this.router.navigate(['/view-product', product.id]);
      }
  }

}
