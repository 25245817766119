<div class="avatar" *ngIf="data.img">
    <div class="w-14 rounded-full" [ngClass]="data.imgWidth ? ' w-[' + data.imgWidth + ']' : 'w-14'">
        <img loading="lazy" alt="Image" [src]="data.img">
        <!-- [ngClass]="'w-full flex flex-row gap-4 justify-around w-[' + data.imgWidth + ']'" -->

    </div>
</div>
<div class="avatar placeholder" *ngIf="!data.img || data.img ==''">
    <div [ngClass]="getCustomCss()">
        <span class="text-white">{{data.text[0] | titlecase}}</span>
    </div>
</div>