import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from "@angular/fire/compat/firestore";

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private db: AngularFirestore) { }
  reportPost(model: any){
    const newContent = {
      id: this.db.createId(),
      postId: model.postId,
      reason: model.reason,
      reportedBy: model.reportedBy,
      postOwner: model.postOwner,
      timeStamp: model.timeStamp,
      status: 'Unresolved'
    };
    return this.db.collection('Reports').doc(newContent.id).set(newContent);
  }

  getReportsByPostId(postId){
    return this.db.collection('Reports', ref => ref.where('postId', '==', postId));
  }

  getUnresolvedPosts(){
    return this.db.collection('Reports', ref => ref.where('status', '==', 'Unresolved'));
  }

  getReportByReporterAndPostId(reporterId, postId){
    return this.db.collection('Reports', ref => ref.where('postId', '==', postId)
                        .where('reportedBy', '==', reporterId));
  }
}
