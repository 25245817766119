<div class="flex flex-col w-full my-2 bg-white h-max p-4 rounded-lg space-y-4 " *ngIf="currentUser">
    <div class="flex space-x-4 items-center">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"  class="w-6 h-6 stroke-gray-400">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
        </svg>
        
        <p class=" font-bold text-xl text-gray-500 ">{{hasSchedule ? 'Update' : 'Add'}} Business hours </p>
    </div>
    <form [formGroup]="scheduleForm" (ngSubmit)="scheduleChange(scheduleForm.value)">
        <p class=" text-sm">Select work days you will be active on: </p>
        <div *ngIf="schedules" class="space-y-2">
            <div  formArrayName="schedules" *ngFor="let schedule of schedules.controls; let i=index">
                <div [formGroupName]="i" class="flex flex-row justify-between ">
                    <div class="md:w-2/12 w-5/12 flex flex-col">
                        <input class=" font-bold py-1 my-2 bg-none" disabled formControlName="day" />
                    </div>
                    <div class="md:w-8/12 w-7/12 flex md:flex-row flex-col md:justify-between justify-center space-y-2 md:space-y-0">
                    <!-- Start time -->
                    <div class="    md:w-4/12  ml-3 md:ml-0">
                        <input type="time" step="300" class="input input-bordered" value="09:00:00"
                        formControlName="startTime" (change)="scheduleChange(scheduleForm.value)"/>
                    </div>
                    <!-- End time -->
                    <div class="md:w-4/12  ml-3 md:ml-0">
                        <input type="time" step="300" class="input input-bordered" value="05:00:00"
                            formControlName="endTime" (change)="scheduleChange(scheduleForm.value)"/>
                    </div>
                    <!-- Availability -->
                    <p class="md:w-3/12 ml-3 md:ml-0 mb-5 md:mb-0">
                        <label>
                            <input type="checkbox" class="filled-in" formControlName="unAvailable"
                                (click)="updateDaySchedule(i)" (change)="scheduleChange(scheduleForm.value)"/>
                            <span>Unavailable</span>
                        </label>
                    </p>
                    </div>
                </div>
            </div>
        </div>
      </form>
  </div>
 