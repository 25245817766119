import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ImageModel, ImageUploaderResponseModel } from 'src/app/models/ImageModel';
import { Location} from '@angular/common';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { PermissionService } from 'src/app/services/firebase/permission.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { HelperService } from 'src/app/services/helper/helper';

@Component({
  selector: 'app-welcome-setting',
  templateUrl: './welcome-setting.component.html',
  styleUrls: ['./welcome-setting.component.css']
})
export class WelcomeSettingComponent implements OnInit {
  @Input() calledFromEmptyDashboard:string;
  @Output() closeCollapse = new EventEmitter<string>();
  @Output() welcomePopupCreated = new EventEmitter<string>();
  @Output() skip = new EventEmitter<string>();
  @Output() previewClosed = new EventEmitter<string>();
  @Output() previewSelected = new EventEmitter<string>();

  signupModalOpen = false;
  
  welcomeRef: any;
  isMobile = false;
  editModalOpen: any;
  base64ImageWelcomePopup:any;
  imageChangedEventWelcomePopup: any;
  cropModalOpenWelcomePopup = false;
  mediaList = [];
  uploadWelcomeFileModalOpen = false;
  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  base64Image = '';
  imgErrorMsg: any;
  usePopupBase64=false
  pageSections: any;
  isNewSetting = true;
  welcomePreview = false;
  addOpeningTimeModal = false;
  currentUser: any;
  permission: any;
  perm: any;
  permSpecial:any;
  hasAccess = false;
  @Output() backClicked = new EventEmitter();
  imageUrl = "";
  imageChangeEvt: any = "";
  cropImgPreview: any;
  imageChangedEvent: any = '';
  binDoc: File;
  useBase64=false
  mediaLink
  mobileBase64Image
  uploadedFiles: File[]  = [];

  constructor(private settingsService: AppSettingsService, private router: Router, private location: Location,
    private permissionService: PermissionService,
    private authService: AuthService,
    private helperService: HelperService) { if (typeof window['Capacitor'] !== 'undefined' && window['Capacitor']['platform'] !== 'web' ) {
          this.isMobile = true;
        }}

  ngOnInit(): void {
    this.currentUser = this.authService.getGlobalUser();
    if(this.currentUser.role=='Admin') this.hasAccess = true;
    this.settingsService.getAppSettingsList().valueChanges().subscribe(
      (sliderSettings: any) => {
        if (sliderSettings) {
          if (sliderSettings.length > 0) {
            this.isNewSetting = false;
            this.pageSections = sliderSettings[0];
            const welcomeSection = sliderSettings[0].appSections.filter(s => s.originalName === 'Welcome Popup');
            if (welcomeSection && welcomeSection.length > 0){
              this.welcomeRef = welcomeSection[0];
              this.welcomeRef.description = this.welcomeRef.description ? this.welcomeRef.description: '';
            }
          }
        }
      })
      this.permissionService.getPermissionByEmployeeId(this.currentUser.uid).valueChanges().subscribe(permission => {
        if (permission && permission.length > 0){
          this.perm = permission[0]
          this.permSpecial = this.perm.permission[4];
          if(this.permSpecial.welcomePopup) this.hasAccess = true
          
        }
      });
  }
  onTitleChange(value: string) {
    this.welcomeRef.title = value;
  }

  onDescriptionChange(value: string) {
    this.welcomeRef.description = value;
  }

  confirmUpdate(updatedRef){
    if(this.welcomeRef.openingTime){
      this.addOpeningTimeModal = false;
      this.editModalOpen = true;
    }
    else{
      this.addOpeningTimeModal = true;
    }
  }
  validateImage(name: any) {
    return this.helperService.validateImage(name);
   }

  // welcome popup image modal
  fileChangeEventWelcomePopup(event, welcomeRef){
    this.welcomeRef = welcomeRef;
    this.imageChangedEvent = event;

    if (!this.validateImage(event[0].name)) {
      this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';
      this.imageUrl ='';
      return;
    }
    this.binDoc = event[0];
    const reader = new FileReader();
    reader.readAsDataURL(event[0]);
    reader.onload = () => {
      this.base64Image = reader.result as string;
      this.useBase64 = true;
      this.processCroppedImageWelcomePopup(this.base64Image);
    };
  }
  onFilesUploaded(files:File[]){
    this.uploadedFiles = files;
    console.log('Files uploaded:', files)
  }
  removeWelcomeImage(){
    this.welcomeRef.imgUrl = '';
    this.base64ImageWelcomePopup = '';
  }

  saveWelcomePageUpdate(){
    this.closeEditModal();
    if(this.mediaList.length > 0){
      this.uploadWelcomeFileModalOpen = true;
    } else {
   
        this.saveChanges();
        
    }
  }

  getTheUrlWelcome(savedUrls){
    this.uploadWelcomeFileModalOpen = false;
    this.welcomeRef.imgUrl = savedUrls.imageURL;
    this.saveChanges();
  }

  closeEditModal(){
    this.editModalOpen = false;
    this.cropModalOpenWelcomePopup = false;
    this.addOpeningTimeModal = false;

  }

  saveChanges() {
    this.mediaList = [];
    this.closeEditModal();
    this.pageSections.appSections.forEach(pSection => {
      if (pSection.name === 'WelcomePopup'){
        pSection.isEnabled = true;
      }
    });
    if (this.pageSections) {
      this.settingsService.updateAppSettings(this.pageSections).then(x => {
        this.toast({ html: 'Changes Saved Successfully.', classes: 'green' });
        if(this.calledFromEmptyDashboard == 'Popup'){
          this.closeCollapseFromEmptydashboard()
          this.welcomePopupCreated.emit('welcomePopupCreated');

        }
      });
    }
  }

  closeCollapseFromEmptydashboard(){
    this.closeCollapse.emit('welcomePopup');

  }

  onPopupImageUpload(response: ImageUploaderResponseModel){
    if (response.error){
      this.imgErrorMsg = response.errorMessage
      return
    }  
    this.imageChangedEventWelcomePopup = 'data:image/jpeg;base64,' + response.imgBase64;
    this.usePopupBase64=true
    this.cropModalOpenWelcomePopup = true;
  }

  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
      this.backBtnClicked();
    }, 2000);
  }

  processCroppedImageWelcomePopup(event){
    this.base64ImageWelcomePopup = event;
    this.cropModalOpenWelcomePopup = false;
    this.base64Image = event;
    let base64result = this.base64Image.split(',')[1];
    this.usePopupBase64 = false;
    const img = new ImageModel();
   
    if(this.isMobile){
      img.imgBase64 = this.mobileBase64Image;
      this.mediaList.push({type:'Image', file: img});
    }else{
      img.imgBase64 = base64result; 
      this.mediaList.push({type:'Image', file: img});
    }        
 
  }

  backBtnClicked(){
    if(this.calledFromEmptyDashboard){
      this.closeCollapse.emit('welcomePopup');
      this.backClicked.emit()
    }else{
      this.location.back();
    }
  }
  previewChanges(){
    // this.signupModalOpen = true;
    if(this.calledFromEmptyDashboard == 'Popup'){
      this.previewSelected.emit()
    }
  }

  navigateToRegisterPageClicked(){
    this.signupModalOpen = false;
    this.router.navigate(['/register']);
  }
  navigateToBuildPageClicked(){
    this.signupModalOpen = false;
    this.router.navigate(['/get-app']);
  }
  closeSignupModal(){
    this.signupModalOpen = false;
  }
  skipSetup(){
    this.skip.emit();
  }
  closeCroppingModal(){
    this.cropModalOpenWelcomePopup = false;
  }
  onImageUpload(response:ImageUploaderResponseModel) {
    if (response.error){
      this.imgErrorMsg = response.errorMessage
      this.mediaLink.patchValue('');
      return
    }

    this.binDoc = response.binDoc;
    this.imageChangedEvent = 'data:image/jpeg;base64,' + response.imgBase64;
    this.mobileBase64Image = response.imgBase64;
    this.useBase64=true
    this.cropModalOpenWelcomePopup = true;
 }

 closePreviewModal(){
  if(this.calledFromEmptyDashboard == 'Popup'){
    this.previewClosed.emit()
  }
}
}

