<div *ngIf="settings && !(pageName == 'Login' || pageName=='Register' || pageName=='ForgotPassword'|| pageName=='Contact')" >
    <div *ngFor="let section of settings">
        <footer *ngIf="section.originalName=='Footer' && section.isEnabled" class="bg-customTeal text-white z-50">
            <div>
                <div class="flex flex-col py-10 justify-between md:flex-row md:w-full space-y-8 md:space-y-0 px-0 md:px-16">
                    <!-- contact -->
                    <div class="flex justify-center md:justify-start" *ngIf="section.phone || section.contactEmail">    
                        <div class="">
                            <p class="text-md text-center md:text-left font-bold mb-2 text-white">CONTACT</p>
                            <!-- phone number -->
                            <div *ngIf="section.phone" class="flex mt-2 space-x-2  md:mt-6">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20"
                                fill="currentColor">
                                    <path
                                    d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                                </svg>
                                <a [href]="'tel:' + section.phone" class="hover:text-darkBlue text-white">{{section.phone }}</a>
                            </div>
                            <div *ngIf="section.contactEmail" class="flex space-x-2 mt-2  ">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20"
                                    fill="currentColor">
                                    <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                                </svg>
                                <a href='mailto:{{section.contactEmail}}' lass="hover:text-darkBlue text-white">{{section.contactEmail}}</a>
                            </div>
                            
                            <!-- <div *ngIf="section.fax" class="mt-2 space-x-2">
                                <i class="material-icons text-white">fax</i>
                                <a [href]="'tel:' + section.fax" class="hover:text-darkBlue text-white">{{section.fax }}</a>
                            </div> -->

                        </div>
                    </div>

                    <!-- address -->
                    <div class="" *ngIf="section.address || section.province || section.city || section.country || section.postalCode">
                        <div class="md:text-left text-center">
                            <p class="text-md font-bold mb-2 text-white">ADDRESS</p>
                            <p class="hover:text-darkBlue text-white">{{section.address}}</p>
                            <p class="hover:text-darkBlue text-white">{{section.province}}</p>
                            <p class="hover:text-darkBlue text-white">{{section.city}} {{section.country}}</p>
                            <p class="hover:text-darkBlue text-white">{{section.postalCode}}</p>
                        </div>
                    </div>

                    <!-- follow -->
                    <div class="">
                        <ng-container *ngIf="socialMediaModel">
                            <p class=" text-md font-bold mb-2 md:text-left text-center text-white ">FOLLOW</p>
                            <div class="flex justify-center md:justify-start space-x-2 md:mt-6 ">
                                <div class="max-w-max flex items-center">
                                    <a class="cursor-pointer" *ngIf="socialMediaModel.facebook"
                                    [href]="socialMediaModel.facebook" target="_blank"><i
                                        class="fa fa-facebook prefix text-white text-2xl mr-3"></i>
                                    </a>
                                    <a class="cursor-pointer pr-2 text-2xl" *ngIf="socialMediaModel.twitter"
                                        [href]="socialMediaModel.twitter" target="_blank" >
                                        <svg color="white" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path  fill="currentcolor" d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg>
                                    </a>
                                    <a class="cursor-pointer" *ngIf="socialMediaModel.instagram"
                                        [href]="socialMediaModel.instagram" target="_blank"><i
                                            class="fa fa-instagram prefix text-2xl text-white mr-3"></i>
                                    </a>
                                    <a class="cursor-pointer" *ngIf="socialMediaModel.youtube"
                                        [href]="socialMediaModel.youtube" target="_blank"><i
                                            class="fa fa-youtube prefix text-white text-2xl mr-3"></i>
                                    </a>
                                    <a class="cursor-pointer" *ngIf="socialMediaModel.linkedin"
                                        [href]="socialMediaModel.linkedin" target="_blank"><i
                                            class="fa fa-linkedin prefix text-white text-2xl mr-3"></i>
                                    </a>
                                    <a class="cursor-pointer" *ngIf="socialMediaModel.pinterest"
                                        [href]="socialMediaModel.pinterest" target="_blank"><i
                                            class="fa fa-pinterest prefix text-white text-2xl mr-3"></i>
                                    </a>
                                    <a class="cursor-pointer" *ngIf="socialMediaModel.tiktok"
                                    [href]="socialMediaModel.tiktok" target="_blank">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" class="fill-white w-5 h-5 mr-3" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"/></svg>
                             
                                </a>
                                </div>        
                            </div>
                            
                        </ng-container>
                    </div>

                    <!-- get the app -->
                    <!-- <div class="">
                        <ng-container *ngIf="appStore">
                            <div class="items-center" *ngIf="appStore.length > 0 && appStore[0].isEnabled">
                                <h5 class="text-md font-bold text-center md:text-left text-white ">GET THE APP</h5>
                                <div class="flex justify-center md:justify-start">
                                    <div>
                                        <a class="flex mt-3 w-48 h-14 bg-black text-white rounded-xl items-center justify-center hover:cursor-pointer"
                                        [href]="appleStore" target="_blank">
                                        <div class="mr-3">
                                            <svg viewBox="0 0 384 512" width="30">
                                                <path fill="currentColor"
                                                    d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z" />
                                            </svg>
                                        </div>
                                        <div>
                                            <div class="text-xs">Download on the</div>
                                            <div class="text-2xl font-semibold font-sans -mt-1">App Store</div>
                                        </div>
                                    </a>
    
                                    <a class="flex mt-3 w-48 h-14 bg-black text-white rounded-lg items-center justify-center hover:cursor-pointer"
                                        [href]="playStore" target="_blank">
                                        <div class="mr-3">
                                            <svg viewBox="30 336.7 120.9 129.2" width="30">
                                                <path fill="#FFD400"
                                                    d="M119.2,421.2c15.3-8.4,27-14.8,28-15.3c3.2-1.7,6.5-6.2,0-9.7  c-2.1-1.1-13.4-7.3-28-15.3l-20.1,20.2L119.2,421.2z" />
                                                <path fill="#FF3333"
                                                    d="M99.1,401.1l-64.2,64.7c1.5,0.2,3.2-0.2,5.2-1.3  c4.2-2.3,48.8-26.7,79.1-43.3L99.1,401.1L99.1,401.1z" />
                                                <path fill="#48FF48"
                                                    d="M99.1,401.1l20.1-20.2c0,0-74.6-40.7-79.1-43.1  c-1.7-1-3.6-1.3-5.3-1L99.1,401.1z" />
                                                <path fill="#3BCCFF"
                                                    d="M99.1,401.1l-64.3-64.3c-2.6,0.6-4.8,2.9-4.8,7.6  c0,7.5,0,107.5,0,113.8c0,4.3,1.7,7.4,4.9,7.7L99.1,401.1z" />
                                            </svg>
                                        </div>
                                        <div>
                                            <div class="text-xs">GET IT ON</div>
                                            <div class="text-xl font-semibold font-sans -mt-1">Google Play</div>
                                        </div>
                                    </a>
                                    </div>
                                </div>
                                
                            </div>
                        </ng-container>

                    </div> -->
                
                </div>    
            </div>
            <div class="text-center pb-1">
                <p>
                    <!-- © Copyright {{year}} App All Rights Reserved -->
                    Designed by <a href={{domainName}} target="_blank" class="cursor-pointer underline font-bold">{{isNationApp ? "mynation.app" : "goingmobile.app"}}</a>
                </p>
            </div>
        </footer>
    </div>
</div>



