 import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MemberMainComponent } from './member-main/member-main.component';
import { UserRoutingModule } from './user-routing.module';
import { PersonalInformationComponent } from './profile/personal-information/personal-information.component';
import { SharedModule } from '../shared/shared.module';
import { ProfileMainComponent } from './profile/profile-main/profile-main.component';
import { DocumentsComponent } from './profile/documents/documents.component';
import { DocumentDetailComponent } from './profile/documents/document-detail/document-detail.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MemberNameStarPipe } from 'src/app/Pipes/MemberNameStarPipe';
// import { TextMaskModule } from 'angular2-text-mask';
import { MyInboxComponent } from './member-messaging/my-inbox/my-inbox.component';
import { MyDiscussionComponent } from './member-messaging/my-discussion/my-discussion.component';

import { MessagingComponent } from './messaging/messaging.component';

//import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { CertificateComponent } from './profile/certificate/certificate.component';
import { AddCertificateComponent } from './profile/certificate/add-certificate/add-certificate.component';
import { EditCertificateComponent } from './profile/certificate/edit-certificate/edit-certificate.component';
import { ActionItemPipe } from 'src/app/Pipes/ActionItemPipe';
// import { NgxSliderModule } from '@angular-slider/ngx-slider';
// import { AgmCoreModule } from '@agm/core';
import { MemberHomeComponent } from './member-home/member-home.component';
import { VerificationComponent } from './verification/verification.component';
import { MyChatsComponent } from './chat/my-chats/my-chats.component';
import { ViewChatComponent } from './chat/view-chat/view-chat.component';
import { EmployeeList } from './employee/employee-list/employee-list.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { EmployeeAppointmentComponent } from './employee/employee-appointment/employee-appointment.component';
import { ViewProfileComponent } from './profile/view-profile/view-profile.component';
import { PollsComponent } from './polls/polls.component';
import { PollResultComponent } from './polls/poll-result/poll-result.component';
import { PollDetailComponent } from './polls/poll-detail/poll-detail.component';
import { AppointmentsComponent } from './appointments/appointments.component';
import { AppointmentRescheduleComponent } from '../shared/appointment-reschedule/appointment-reschedule.component';
import { ServiceProductComponent } from './service-product/service-product.component';
import { UserNotificationsComponent } from './user-notifications/user-notifications.component';
import { CartComponent } from './cart/cart.component';
import { CartItemComponent } from './cart/cart-item/cart-item.component';
import { PaymentStatusComponent } from './payment-status/payment-status.component';
import { BookAppointmentComponent } from '../shared/book-appointment/book-appointment.component';
import { ProfileFormComponent } from './profile-form/profile-form.component';
import { ViewFormComponent } from './profile-form/view-form/view-form.component';
import { GetVerifiedComponent } from './get-verified/get-verified.component';
import { SignatureModule } from '@syncfusion/ej2-angular-inputs';
import { ManageAppComponent } from './manage-app/manage-app.component';
// import { ChartsModule } from 'ng2-charts';
import { AuthModule } from '../auth/auth.module';
import { CreateNewAppComponent } from './create-new-app/create-new-app.component';
import { AppDetailComponent } from './app-detail/app-detail.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { ManageSubscriptionsComponent } from './subscriptions/manage-subscriptions/manage-subscriptions.component';
import { SubscriptionSuccessComponent } from './subscriptions/subscription-success/subscription-success.component';
import { SubscriptionFailComponent } from './subscriptions/subscription-fail/subscription-fail.component';


@NgModule({
  declarations: [
    MemberMainComponent,
    PersonalInformationComponent,
    ProfileMainComponent,
    DocumentsComponent,
    DocumentDetailComponent,
    MemberNameStarPipe,
    // SettingsComponent,
    MyInboxComponent,
    MyDiscussionComponent,
    MessagingComponent,
    CertificateComponent,
    AddCertificateComponent,
    EditCertificateComponent,
    ActionItemPipe,
    MemberHomeComponent,
    VerificationComponent,
    MyChatsComponent,
    ViewChatComponent,
    EmployeeList,
    EmployeeAppointmentComponent,
    ViewProfileComponent,
    PollsComponent,
    PollResultComponent,
    PollDetailComponent,
    AppointmentsComponent,
    AppointmentRescheduleComponent,
    ServiceProductComponent,
    UserNotificationsComponent,
    CartComponent,
    CartItemComponent,
    PaymentStatusComponent,
    BookAppointmentComponent,
    ProfileFormComponent,
    ViewFormComponent,
    GetVerifiedComponent,
    ManageAppComponent,
    CreateNewAppComponent,
    AppDetailComponent,
    ConfirmationModalComponent,
    ManageSubscriptionsComponent,
    SubscriptionSuccessComponent,
    SubscriptionFailComponent,
    // SendMessageFromUsersComponent
    // ShortNumberPipe,
    // SafeUrlPipe,
  ],
  imports: [
    CommonModule,
    UserRoutingModule,
    ReactiveFormsModule,
    SharedModule,
    FormsModule,
    // ChartsModule,
    // AngularMyDatePickerModule ,
    // TextMaskModule,
    // AngularMyDatePickerModule,
    SignatureModule,
    //  AgmCoreModule.forRoot({
    //   apiKey: environment.googleMapKey.key
    // }),
    // NgxSliderModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    AuthModule
  ],
  // exports: [
  //   SendMessageFromUsersComponent,
  //   // ... other components and directives
  // ],
})

export class UserModule { }
