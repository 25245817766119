import { Component, ElementRef, EventEmitter, NgZone, OnInit, Output, ViewChild } from '@angular/core';
// import { MapsAPILoader, MouseEvent } from '@agm/core';

@Component({
  selector: 'app-locate-map',
  templateUrl: './locate-map.component.html',
  styleUrls: ['./locate-map.component.css']
})
export class LocateMapComponent implements OnInit {
  // google maps zoom level
  zoom: number = 8;
  
  // initial center position for the map
  lat: number = 51.673858;
  lng: number = 7.815982;

  markers: marker[] = [
	  {
		  lat: 51.673858,
		  lng: 7.815982,
		  label: 'A',
		  draggable: true
	  },
	  // {
		//   lat: 51.373858,
		//   lng: 7.215982,
		//   label: 'B',
		//   draggable: false
	  // },
	  // {
		//   lat: 51.723858,
		//   lng: 7.895982,
		//   label: 'C',
		//   draggable: true
	  // }
  ]

    title: string = 'AGM project';
  latitude: number;
  longitude: number;
  // zoom: number;
  address: string;
  private geoCoder;

  // @ViewChild('search')
  // public searchElementRef: ElementRef;
  @ViewChild('searchInput')
  public searchElementRef: ElementRef;

  @Output() lati = new EventEmitter();
  @Output() long = new EventEmitter();
  @Output() location = new EventEmitter();
  

  // constructor(private mapsAPILoader: MapsAPILoader,
  //   private ngZone: NgZone) { }

  constructor(
    private ngZone: NgZone) { }

  ngOnInit(): void {
    // this.mapsAPILoader.load().then(() => {
    //   this.setCurrentLocation();
    //   this.geoCoder = new google.maps.Geocoder;
    //   this.initAutocomplete();
    // });
  }

  initAutocomplete() {
    // console.log("IT IS HERE")
    // if (!google || !google.maps || !google.maps.places) {
    //   console.error('Google Maps API or Places library not available');
    //   return;
    // }

    // const autocomplete = new google.maps.places.Autocomplete(
    //   this.searchElementRef.nativeElement,
    //   {
    //     types: ['geocode'],
    //   }
    // );
    //console.log("*****the auto complete",autocomplete);

    // autocomplete.addListener('place_changed', () => {
    //   this.ngZone.run(() => {
    //     const place: google.maps.places.PlaceResult = autocomplete.getPlace();

    //     if (place.geometry === undefined || place.geometry === null) {
    //       return;
    //     }
    //     console.log("*****the place",place);

    //     // Update map location based on search result
    //     this.latitude = place.geometry.location.lat();
    //     this.longitude = place.geometry.location.lng();
    //     this.zoom = 12;
    //     this.getAddress(this.latitude, this.longitude);
    //   });
    // });
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      console.log("LONGITUDE", this.longitude, " LATITUDE", this.latitude)

      });
    }
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
          console.log("ADDRESS---->",this.address)
          this.location.emit(this.address);
          return this.address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    
    });
  }

  clickedMarker(label: string, index: number) {
    console.log(`clicked the marker: ${label || index}`)
  }
  
  mapClicked($event: MouseEvent) {
    // this.markers.push({
    //   lat: $event.coords.lat,
    //   lng: $event.coords.lng,
    //   draggable: true
    // });
    console.log("THE MARKERS", this.markers);
  }
  
  markerDragEnd($event: MouseEvent) {
    // console.log('dragEnd', $event, "LAT", $event.coords.lat, "LONG", $event.coords.lng);
    // this.latitude = $event.coords.lat;
    // this.longitude = $event.coords.lng;
    this.getAddress(this.latitude,this.longitude);
    const theAddress = this.getAddress(this.latitude,this.longitude);
    console.log("LAT",this.latitude, "LONG", this.longitude);
    this.lati.emit(this.latitude);
    this.long.emit(this.longitude);
    

  }
  closeClicked(){
    //this.close.emit();
  }
  // markerDragEnd(m: marker, $event: MouseEvent) {
  //   console.log('dragEnd', $event);
    
  //   // Update the latitude and longitude of the dragged marker
  //   m.lat = $event.coords.lat;
  //   m.lng = $event.coords.lng;

  //   // Log the updated coordinates
  //   console.log('Updated Latitude:', m.lat);
  //   console.log('Updated Longitude:', m.lng);
  // }
}

// just an interface for type safety.
interface marker {
	lat: number;
	lng: number;
	label?: string;
	draggable: boolean;
}