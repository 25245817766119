
<!-- toast -->
<div *ngFor="let item of appointmentNotifications; let i = index;">
  <ng-container *ngIf="!item.isSeen">
    <app-notification-alert class="fixed  flex justify-end w-full z-[2000]"
    [toastType]="toastType" [toastMessage]="toastMessage" 
    [ngClass]="{'visible': openToast, 'invisible': !openToast}"
    [ngStyle]="{'top': i*80 + 'px'}"
    (closeClicked)="closeModal(item)"
    (toastClicked)="toastContainerClicked(item)"

    > </app-notification-alert>
  </ng-container>


</div>
<app-notification-alert *ngIf="appointmentNotifications && appointmentNotifications.length > 4" class="fixed top-80 flex justify-end w-full z-[2000]"  toastType="seeMore"  [ngClass]="{'visible': openToast, 'invisible': !openToast}"
  (seeAllClicked)="showAll()"> </app-notification-alert> 
  

<div *ngIf="!isLogged" class="z-50">
  <app-header  [themeColor]="themeColor" [navbarOption]="navbarOption" [nationLogo]="nationLogo" [rightOrLeftSidebar]="rightOrLeftSidebar" [appName]="appName"></app-header>
</div>
<router-outlet>
    <app-loading ></app-loading>
</router-outlet>
<div *ngIf="!isLogged">
  <app-footer></app-footer>
</div>

<ngx-guided-tour></ngx-guided-tour>


<!-- <app-online-status
  [onlineStatusMessage]="connectionStatusMessage"
  [onlineStatus]="connectionStatus">
</app-online-status> -->

<!-- <ng-picker-overlay
  apikey="AlIW5XToVRT2qVS1UhHhNz">
</ng-picker-overlay> -->