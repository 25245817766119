<div *ngIf="!profileSelect" class="w-full bg-[#F1F1F1] px-5 py-6 md:px-14 lg:py-8">
    <div *ngIf="hasProfile" >     

        <div class="flex pt-12 lg:pt-24" *ngIf="!showProfile">
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 w-full gap-4">
                <div class="w-full  bg-base-100 rounded-md shadow-md p-4 
                md:py-6" *ngFor="let s of userProfile; let li=index">
                    <div (click)="toDashboard(li)" class=" grid grid-cols-1 justify-items-start md:justify-items-center">
                        <div class="text-center text-xl font-bold pb-6 lg:pb-12">
                            {{s.title}}
                        </div>
                        <div class="flex flex-row lg:flex-col">
                            <!-- <div class="flex-basis 2/3">123</div> -->
                            <div class="flex-basis 1/3">
                                
                            <div class="p-1 text-socialMediaPrimary hover:font-bold"><i class="material-icons mr-4 float-left">link</i>
                                <a (click)="linkSocialMedia(s.profileKey)"
                                >Link an account</a>
                            </div>
                            </div>
                        </div>
                    </div>
    
                </div>
            </div>
        </div>

        <!-- <div *ngIf="showProfile">
            <div class="mb-2 md:mb-0">
                <p class='pb-7'>Hello your on.</p>
                <div class="flex flex-col  md:flex-row gap-x-6 gap-y-3">
                    <div class="">
                        <select (change)="profileChange($event)" class="select select-bordered w-full max-w-xs">
                            <option disabled selected>{{profileKey.title}}</option>
                            <option *ngFor="let p of userProfile;let li=index" [value]="li">{{p.title}}</option>
                          </select>
                    </div>
                    <div>
                        <button class="btn btn-ghost" (click)="openProfileModal()">  <i class="text-socialMediaPrimary material-icons mr-3 float-left">add</i>New Profile </button>
                    </div>

                </div>
            </div>
            <app-social-media-dashboard [profileKey]="profileKey.profileKey"></app-social-media-dashboard>

        </div> -->
        
    </div>

    <div *ngIf="!hasProfile">
        does not exist pfoile page
    </div>
</div>

<div *ngIf="profileSelect">
    <div class="w-full bg-[#F1F1F1] px-5 py-6 md:px-14 lg:py-8">
        <div class="block w-full flex justify-between pb-6 md:hidden">
          <div>
            <img
                loading="lazy"
                
                class="object-cover h-16" 
                
                style="width: 120px; height: 40px;"
    
                data-src="https://api.goingmobile.app/media/personal-app-assets/img/webp/Sensuous-logo-01.webp"
                                            
                srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/Sensuous-logo-01.webp"
                                            
                fallback="https://api.goingmobile.app/media/personal-app-assets/img/png/Sensuous logo-01.png"
                                            
                alt="Sensuous logo."> 
          </div>
          <div>
           <a [routerLink]="['settings']"
              ><i class="material-icons hover:text-socialMediaPrimary hover:font-bold">settings</i></a>
          </div>
        </div>
        <!-- <div *ngIf="user" > -->
          <!-- Welcome <span class="text-socialMediaPrimary">{{user.GivenName}}</span>
        <span class="text-base font-normal"> we're glad your here</span></div> -->
        <router-outlet></router-outlet>
    
      </div>
</div>