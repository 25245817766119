<div class="flex flex-col md:flex-row  gap-4 md:mx-10">
  <!--side nav -->
  <div class="flex md:mx-0 mx-4 flex-col bg-white h-min px-6 py-4 md:w-1/5 space-y-2 rounded-md border border-solid border-gray-300">
      <a (click)="changeTab('profile')" class="cursor-pointer" [ngClass]="{'font-bold text-customTeal': tab=='profile'}">Profile</a>
      <a (click)="changeTab('schedule')" class="cursor-pointer" [ngClass]="{'font-bold text-customTeal': tab=='schedule'}">Working Schedule</a>
      <a (click)="changeTab('service')" class="cursor-pointer" [ngClass]="{'font-bold text-customTeal': tab=='service'}">Services</a>
      <a (click)="changeTab('setting')" class="cursor-pointer" [ngClass]="{'font-bold text-customTeal': tab=='setting'}">Setting</a>


  </div>
  <!--content -->
  <div class="bg-white w-full  md:w-4/5 px-8 py-4">
    <!-- profile -->
    <div *ngIf="tab == 'profile'">
      <div class="flex  md:flex-row flex-col justify-center items-center md:items-start md:justify-between">
<div>
  <!-- image avatar -->
  <div class="flex md:flex-row items-center flex-col md:justify-start justify-center gap-4 w-full ">
    <div class="avatar">
      <div class="md:w-32 md:h-32 w-24 h-24 rounded-full" *ngIf="employee.profileImgLink">
        <img loading="lazy" [src]="employee.profileImgLink" />
      </div>
    </div>
    <div class="avatar placeholder" *ngIf="!employee.profileImgLink && initial">
      <div class="bg-neutral-focus text-neutral-content w-24 h-24 mt-4 md:w-32 md:h-32 rounded-full -mt-2">
        <span class="text-5xl">{{initial[0] | uppercase }}</span>
      </div>
    </div>

    <!-- job title -->
    <div class="flex flex-col md:justify-start justify-center space-y-2">
      <p class="font-bold text-xl pt-4 md:text-start text-center">{{GivenName}} {{LastName}}</p>
      <div class="flex flex-col items-center md:justify-start justify-center md:flex-row space-y-2 md:space-x-9 w-full  md:space-y-0">
        <div class="flex items-center space-x-3 w-max" *ngIf="employee.jobTitle">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-4 h-4 fill-gray-600">
            <path
              d="M184 48H328c4.4 0 8 3.6 8 8V96H176V56c0-4.4 3.6-8 8-8zm-56 8V96H64C28.7 96 0 124.7 0 160v96H192 320 512V160c0-35.3-28.7-64-64-64H384V56c0-30.9-25.1-56-56-56H184c-30.9 0-56 25.1-56 56zM512 288H320v32c0 17.7-14.3 32-32 32H224c-17.7 0-32-14.3-32-32V288H0V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V288z" />
          </svg>
            <p class="font-bold text-gray-600">{{employee.jobTitle}}</p>
        </div>
      </div>
          <!-- phone & email -->
<div class="flex md:flex-row flex-col md:justify-start justify-center gap-4 items-center">
  <div class="flex space-x-2 items-center" *ngIf="cellPhone">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-4 h-4 fill-gray-600">
      <path
        d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
    </svg>
    <p class=" " *ngIf="cellPhone">{{cellPhone}}</p>
  </div>
  <div class="flex space-x-2 items-center">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-4 h-4 fill-gray-600">
      <path
        d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
    </svg>
    <p class=" ">{{Email}}</p>
  </div>
  
</div>
   
      </div>
      <div>
      </div>
    </div>


</div>
        <!-- edit button -->
    <div>
      <button (click)="editEmployee()"
      *ngIf="isAdmin || (permissionEmp && (permissionSpecialEmp.editEmployees ))"
      class="border border-gray-300 px-6 flex items-center py-2 bg-white text-customTeal font-bold  rounded-full shadow-lg">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
        class="w-3 h-3 mr-2 fill-gray-600" >
        <path
          d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z" />
      </svg>
      <p class="text-gray-600"> Edit</p>
    </button>
    </div>
      </div>

           <!-- message and show employee -->
           <div class="flex md:flex-row flex-col gap-4 items-center py-6">
            <div class="flex items-end h-full ">
              <button (click)="openSendMessageModal()" *ngIf="isAdmin || (permissionEmp && permissionEmp.contactUsers)"
              class="border border-gray-300 px-6 flex items-center py-2 bg-white text-customTeal font-bold  rounded-full shadow-lg">
        
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-2 stroke-gray-600" fill="none" viewBox="0 0 24 24"
                  stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                </svg>
                <p class="text-gray-600"> Send Message</p>
              </button>
            </div>
            <button (click)="openShowEmployee()"
            *ngIf="isAdmin || (permissionSpecialEmp && permissionSpecialEmp.editEmployees)"
            class="py-2 text-customTeal hover:underline cursor-pointer font-bold  rounded-md"
            [ngClass]="{'border-red-600 text-red-600': employee.ShowEmployee ==='No'}">
            <span *ngIf="employee.ShowEmployee !== 'No'">Show Employee to {{employee.ShowEmployee}}</span>
            <span *ngIf="employee.ShowEmployee ==='No'">Don't Show Employee</span> </button>
    
           </div>


           <ng-container *ngIf="employee && !isCurrentUserAdmin">
            <div  class="px-6 py-2 bg-white flex   w-full rounded-md  gap-2 items-center border border-solid border-red-400"
              *ngIf="(employee.status && employee.status == 'Disabled')  && (isAdmin || (permissionEmp && permissionEmp.enableDisableEmp))">
              <svg class="w-4 h-4 fill-red-700" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2025 Fonticons, Inc.--><path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480L40 480c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24l0 112c0 13.3 10.7 24 24 24s24-10.7 24-24l0-112c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></svg>
              <span class=" text-gray-600">Reason for disabiling: </span>
              <span class="font-bold">{{employee.reason}}</span>
            </div>
    
               
          </ng-container>   
           <hr>
  
      <div class="rounded-md space-y-4 py-6">
        <div class="flex flex-col md:flex-row space-y-2 md:space-y-0 justify-between ">
          <p class="font-bold text-center md:text-start">{{GivenName}}'s Details</p>
 
        </div>
        <p class="text-center md:text-start" *ngIf="employee.biography">{{truncateHTML(employee.biography,500)}}</p>
        <div *ngIf=" !employee.biography"
          class="mb-2 mt-2 flex text-gray-400 text-center md:text-start">{{GivenName}} haven't filled out their Biography.</div>
      
      </div>
    </div>

    <!-- working schedule -->
    <div *ngIf="tab == 'schedule'">

      <div class=" rounded-md space-y-4 ">
        <div class="flex flex-col md:flex-row space-y-2 md:space-x-12 items-center justify-between">

          <p class="font-bold text-xl">Working Schedule</p>
          <button [routerLink]="['/admin/employee-schedule', employee.uid]"
            class="border border-gray-300 px-6 flex items-center py-2 bg-white text-customTeal font-bold  rounded-full shadow-lg"
            *ngIf="isAdmin && employee">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" 
            class="h-4 w-4 mr-2 fill-gray-600">
              <path
                d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z" />
            </svg>
            <p class="text-gray-600">  {{EmpSchedule.length > 0 ? 'Update Schedule' : 'Add Schedule'}}</p>
           
          </button>
        </div>

        <div *ngIf="EmpSchedule.length == 0">
          <div class="mb-2 mt-2 flex text-justify justify-center text-gray-400">
            {{GivenName}} didn't add their schedule.</div>
        </div>

        <div class="flex flex-wrap border border-solid rounded-md border-gray-300  md:divide-x-2" *ngIf="EmpSchedule.length != 0">
          <div class="p-4" *ngFor="let sched of EmpSchedule">
            <p class="text-gray-600">{{sched.day}}</p>
            <p class="font-bold">{{sched.startTime}} - {{sched.endTime}}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- service -->
    <div *ngIf="tab == 'service'">
      <div class="rounded-md space-y-4">
        <div class="flex flex-col md:flex-row md:justify-between items-center">
          <p class="font-bold text-xl">Services</p>
          <button (click)="linkService()" 
          class="border border-gray-300 px-6 flex items-center py-2 bg-white text-customTeal font-bold  rounded-full shadow-lg"
           *ngIf="isAdmin">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" fill="black"
            class="h-4 w-4 mr-2 fill-gray-600">
              <path
                d="M579.8 267.7c56.5-56.5 56.5-148 0-204.5c-50-50-128.8-56.5-186.3-15.4l-1.6 1.1c-14.4 10.3-17.7 30.3-7.4 44.6s30.3 17.7 44.6 7.4l1.6-1.1c32.1-22.9 76-19.3 103.8 8.6c31.5 31.5 31.5 82.5 0 114L422.3 334.8c-31.5 31.5-82.5 31.5-114 0c-27.9-27.9-31.5-71.8-8.6-103.8l1.1-1.6c10.3-14.4 6.9-34.4-7.4-44.6s-34.4-6.9-44.6 7.4l-1.1 1.6C206.5 251.2 213 330 263 380c56.5 56.5 148 56.5 204.5 0L579.8 267.7zM60.2 244.3c-56.5 56.5-56.5 148 0 204.5c50 50 128.8 56.5 186.3 15.4l1.6-1.1c14.4-10.3 17.7-30.3 7.4-44.6s-30.3-17.7-44.6-7.4l-1.6 1.1c-32.1 22.9-76 19.3-103.8-8.6C74 372 74 321 105.5 289.5L217.7 177.2c31.5-31.5 82.5-31.5 114 0c27.9 27.9 31.5 71.8 8.6 103.9l-1.1 1.6c-10.3 14.4-6.9 34.4 7.4 44.6s34.4 6.9 44.6-7.4l1.1-1.6C433.5 260.8 427 182 377 132c-56.5-56.5-148-56.5-204.5 0L60.2 244.3z" />
            </svg>

            <p class="text-gray-600">Link Service</p>
          </button>
        </div>

        <div *ngIf="EmpService.length == 0 && EmpServiceUnder==0">
          <div class="mb-2 flex justify-center text-center md:text-start text-gray-400">
            {{GivenName}} is not assigned to any service.</div>
        </div>
        <br />

        <div class="flex flex-wrap md:justify-start justify-center  gap-4" *ngIf="EmpService.length != 0">
          <div *ngFor="let service of selectedServiceObj">
            <div class=" flex p-4 bg-customTeal px-6 py-2 w-full md:w-min  rounded-md justify-between ">
              <p class="font-bold text-white"> {{truncateHTML(service.serviceName | titlecase, 50)}}</p>
            </div>
          </div>
        </div>

        <div class="flex flex-wrap md:justify-start justify-center gap-4" *ngIf="EmpServiceUnder.length != 0">
          <div *ngFor="let service of EmpServiceUnder">
            <div class=" flex p-4 bg-current px-6 py-2 border w-full md:w-min rounded-md justify-between ">
              <p class="font-bold text-white">{{truncateHTML(service.serviceTitle | titlecase, 50)}}</p>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- settings -->
    <div *ngIf="tab == 'setting'" class="space-y-4">

      <p class="font-bold text-xl text-center md:text-start">Setting</p>
      
      <!-- give access -->
      <div class="flex flex-col md:justify-start justify-center items-center md:items-start space-y-2">
        <div >
          <p class="font-bold text-center md:text-start">Manage Permission</p>
          <p class="text-center md:text-start">Easily control and customize user access levels for enhanced security and functionality.</p>
        </div>
        <div *ngIf=" isAdmin || (permSpecial && (permSpecial.permissionAccess))">
          <button (click)="giveAccess()" *ngIf="isAdmin && !isCurrentUserAdmin"
            class="bg-customTeal  px-3 md:px-6 py-2 text-white font-bold  rounded-md">
            <i class="material-icons material-symbols-outlined text-white w-1 h-1 pr-8 float-left">task_alt</i>
            Give Access
          </button>
        </div>
      </div>

      <hr>


            <!-- reset password -->
            <div class="flex flex-col md:justify-start justify-center items-center md:items-start space-y-2">
              <div >
                <p class="font-bold text-center md:text-start">Reset password</p>
                <p class="text-center md:text-start">Quickly update password to regain secure access to your account.</p>
              </div>
              <div  >
                <button (click)="openResetModal()" *ngIf="(isAdmin || (permissionEmp && permissionEmp.resetPass))"
                  class="border-2 border-red-400 px-3 md:px-6 py-2 text-red-400 font-bold  rounded-md">
                  <i class="material-icons material-symbols-outlined text-red-500 w-1 h-1 pr-8 float-left">lock_reset</i>
                  Reset Password
                </button>
              </div>
            </div>      
            <hr>


                <!-- disable password -->
                <div class="flex flex-col md:justify-start justify-center items-center md:items-start space-y-2">
                  <div >
                    <p class="font-bold text-center md:text-start">Disable Account</p>
                    <p class="text-center md:text-start">Temporarily deactivate access or functionality as needed with a single click.</p>
                  </div>
                  <div >
                    <div class=" "
                    *ngIf="employee.status === 'Enabled'  && (isAdmin || (permissionEmp && permissionEmp.enableDisableEmp))">
                    <button (click)="openDisableModal()"
                      class=" px-4 md:px-6  mr-6 md:mr-14 py-2 bg-red-600 text-white rounded-lg w-max h-min text-sm md:text-base font-bold hover:bg-darkBlue focus:outline-none  ">
                      <span>Disable</span>
                    </button>
                  </div>
                        <div class=" "
                        *ngIf="employee.status !== 'Enabled' && (isAdmin || (permissionEmp && permissionEmp.enableDisableEmp))">
                        <button (click)="openEnableModal()"
                          class=" px-4 md:px-6  mr-6 md:mr-14 py-2 bg-customTeal text-white rounded-lg w-max h-min text-sm md:text-base font-bold hover:bg-darkBlue focus:outline-none  ">
                          <span>Enable</span>
                        </button>
                      </div>
                  </div>
                </div>      



  

    </div>

    


  </div>
</div>




<div class="flex">


  <div class="w-full" *ngIf="!isAccess">

    <div class=" bg-gray-100 w-full " *ngIf="employee">
   

      <!-- toast -->
      <app-alert class="fixed top-2 right-1 flex" [toastType]="toastType" [toastMessage]="toastMessage"
        [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>

        <app-modal-box [isOpen]="openContactModal" size="large" (close)="openContactModal=false" title="Send Message">
          <app-message-send [userLogged]="employee" (cancelSendClicked)="openContactModal=false"
          (sendClicked)="onSendClicked($event)">
        </app-message-send>
        </app-modal-box>

        <app-modal-box [isOpen]="showEmployeeModal" size="medium" (close)="showEmployeeModal=false" title="Show Employee">
          <div class="modal-content">
            <p class="font-bold mb-1"> Show Employees?</p>
            <p class="mb-2">
              Please choose your preference on how to show the employee is it to public, subscribe's only or you dont
              want
              to show the employee at all.
            </p>
            <hr />

            <div class="flex  space-x-8 mt-2 items-baseline">
              <div class="form-control">
                <label class="label cursor-pointer space-x-2">
                  <input type="radio" class="radio checked:bg-customTeal" [checked]="publicShow"
                    (click)="togglePublic($event)" />

                  <span class="label-text">Public</span>
                </label>
              </div>
              <div class="form-control">
                <label class="label cursor-pointer space-x-2">
                  <input type="radio" class="radio checked:bg-customTeal" [checked]="SubscribeShow"
                    (click)="toggleSubscribe($event)" />
                  <span class="label-text">Subscriber's Only</span>
                </label>
              </div>
              <div class="form-control">
                <label class="label cursor-pointer space-x-2">
                  <input type="radio" class="radio checked:bg-customTeal" [checked]="noShow"
                    (click)="toggleDonotshow($event)" />
                  <span class="label-text">Don't show</span>
                </label>
              </div>
            </div>
            <div class="modal-action items-center justify-end m-2 mt-4">
              <app-button (click)="showEmployeeModal=false" [disabled]="!contactForm.valid" *ngIf="contactMsg !== 'directMessage'" class="justify-end items-end" 
              [customConfig]="{ text: 'cancel', type: 'button', buttonType: 'secondaryButton' }"
             ></app-button>
             <app-button (click)="closeShowEmployee()" [disabled]="!contactForm.valid" *ngIf="contactMsg !== 'directMessage'" class="justify-end items-end" 
             [customConfig]="{ text: 'Save', type: 'button', buttonType: 'primaryButton' }"
            ></app-button>
            </div>
          </div>
        </app-modal-box>

        <app-modal-box [isOpen]="openLinkServiceModal" size="medium" (close)="openLinkServiceModal=false" title="Link Service">
          <div>
            <h3 class="font-bold text-lg  mb-2">Assign {{GivenName}} {{LastName}} to a Service</h3>
            <p class=" mt-3 mb-2">Assign the employee to the list of the Services you want them in.</p>
            <hr />
            <div class="flex  flex-wrap gap-2 mt-3">
              <div *ngFor="let item of service; let i = index">
                <button (click)="selectServices(item)" class="border-2 border-customTeal rounded-full py-1 px-6  my-2"
                  [ngClass]="{'bg-customTeal text-white' : isServiceClickedmethod(item)}">
                  {{truncateHTML(item.serviceName, 50)}}
                </button>
              </div>
            </div>

            <div class="modal-action items-center justify-end m-2 mt-4">
              <app-button (click)="openLinkServiceModal=false" [disabled]="!contactForm.valid" *ngIf="contactMsg !== 'directMessage'" class="justify-end items-end" 
              [customConfig]="{ text: 'cancel', type: 'button', buttonType: 'secondaryButton' }"
             ></app-button>
             <app-button (click)="assignToService()" [disabled]="!contactForm.valid" *ngIf="contactMsg !== 'directMessage'" class="justify-end items-end" 
             [customConfig]="{ text: 'Save', type: 'button', buttonType: 'primaryButton' }"
            ></app-button>
            </div>
          </div>
        </app-modal-box>
 

      <div id="my-modal-biography" class="modal" *ngIf="openBiographyModal"
        [ngClass]="{'modal-open': openBiographyModal, 'modal-action': !openBiographyModal }">

        <div class="modal-box w-10/12 max-w-3xl">
          <div class="modal-content">
            <div *ngIf="employee.profileImgLink" class="flex justify-center cursor-pointer">
              <img loading="lazy" [src]="employee.profileImgLink" class="w-16 rounded-full flex justify-center items-center" />
            </div>
            <div *ngIf="!employee.profileImgLink" class="avatar placeholder flex justify-center">
              <div class="bg-neutral-focus text-neutral-content rounded-full w-16">
                <span class="text-3xl">{{GivenName[0]}}</span>
              </div>
            </div>
            <p class="text-center font-bold" *ngIf="employee.jobTitle"> {{employee.jobTitle}}</p>
            <h3 class="font-bold text-lg text-center text-customTeal my-2"> {{GivenName}} {{LastName}} Biography</h3>
            <hr />
            <p class="text-center text-justify mt-2 mb-2">{{sliced}}</p>
            <div class="modal-action items-center justify-center m-4">
              <label for="my-modal-biography" (click)="closeBiographyModal()"
                class="text-customTeal font-bold">close</label>
            </div>
          </div>
        </div>

      </div>

      <app-modal-box [isOpen]="disableModal" *ngIf="selectedSection" (close)="disableModal=false" title ="Disable Account">

      </app-modal-box>

      <!-- Disable Account -->
      <app-modal modalTitle="Disable Account" btnName="Disable" *ngIf="disableModal"
        (cancelBtnClicked)="disableModal=false" (btnClicked)="openDisableStopModal($event)">
        <hr />
        <p class="text-center mt-3">Are you sure you want to Disable {{GivenName}} {{LastName}}'s Account?</p>
        <p class="py-2 text-center">Users with a disabled accounts won't be able to sign in.</p>
        <p class="py-2 px-10 text-gray-400 font-bold text-sm">Reason: </p>

        <textarea [(ngModel)]="reason" (input)="reason = $event.target.value"
          class="h-24 mx-4 md:mx-8 flex justify-center items-center block p-2.5 w-5/6 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 dark:placeholder-gray-400 dark:text-white"></textarea>

      </app-modal>

      <!-- Disable Account -->
      <app-modal modalTitle="Disable Account" btnName="Yes,Disable" *ngIf="disableStopModal"
        (cancelBtnClicked)="disableStopModal=false" (btnClicked)="suspendEmployee($event)">
        <hr />
        <p class="text-center mt-3">Are you sure you want to Disable {{GivenName}} {{LastName}}'s Account?
        </p>
        <p class="py-2 text-center font-bold">Users with a disabled accounts won't be able to sign in.</p>
      </app-modal>

      <!-- Enable Account -->
      <app-modal modalTitle="Enable Account" btnName="Enable" *ngIf="enableModal" (cancelBtnClicked)="enableModal=false"
        (btnClicked)="restoreEmployee($event)">
        <hr />
        <p class="text-center mt-3">Are you sure you want to Enable this Account?</p>
        <p class="py-2 text-center">Users with a enabled accounts will be able to sign in again.</p>
      </app-modal>

      <!-- Reset Modal -->
      <app-modal modalTitle="Reset {{GivenName}}'s password" btnName="Reset" *ngIf="resetModal"
        (cancelBtnClicked)="resetModal=false" (btnClicked)="resetPassword($event)">
        <p class="text-center mt-3 mb-2">Are you sure you want to reset {{GivenName}}'s password?</p>
        <hr />
      </app-modal>

      <!-- Delete Confirmation -->
      <app-modal modalTitle="Delete My Account" btnName="Yes" *ngIf="confirmDeleteModal"
        (cancelBtnClicked)="confirmDeleteModal=false" (btnClicked)="openSecondModalTwo($event)">
        <hr />
        <p class="text-center mt-3"> <span class="font-bold">{{GivenName | titlecase}},</span> are you sure you want to
          delete
          your account?</p>
        <p class="text-center mt-3">If you delete your account you will not be able to login again to {{appName}}.
          <span class="font-bold">Do you want to proceed?</span>
        </p>
      </app-modal>

      <!-- Delete Confirmation for the second time -->
      <app-modal modalTitle="Delete My Account" btnName="Yes" *ngIf="confirmSecondModal"
        (cancelBtnClicked)="confirmSecondModal=false" (btnClicked)="openThreeModal($event)">
        <hr />
        <p class="text-center mt-3"> Once you confirm you will not be able to log in to {{appName}}</p>
        <p class="text-center mt-3"> <span class="font-bold">Do you want to proceed?</span></p>

      </app-modal>

      <!-- Delete Confirmation for the third time -->
      <app-modal modalTitle="Delete My Account" btnName="Delete" *ngIf="confirmThreeModal"
        (cancelBtnClicked)="confirmThreeModal=false" (btnClicked)="beforeDelete($event)">
        <hr />
        <p class="text-center mt-3"> Are you sure?</p>
      </app-modal>

      <div id="my-modal-delete" *ngIf="beforeDeleteModal" class="modal"
        [ngClass]="{'modal-open': beforeDeleteModal, 'modal-action': !beforeDeleteModal }">
        <div class="modal-box">
          <div class="modal-content">
            <h3 class="font-bold text-lg text-center mb-2">Your Account has been deleted</h3>
            <hr />
            <p class="text-center mt-3"> Have a great day.</p>
          </div>
        </div>
      </div>

    </div>
    <br />

  </div>

</div>

<app-modal-box [isOpen]="isAccess" size="xxl" (close)="isAccess=false" title="Manage Permission">
  <div class="flex items-center justify-center w-full" >
    <div class="font-body min-h-screen max-h-max pt-6 w-5/6 items-center">
      <div class="flex flex-col mx-4 md:mx-12 m-2 bg-white h-max p-8 rounded-lg space-y-4 ">
        <p class="px-4 font-bold text-lg">Grant Access</p>
        <!-- <div class="space-x-2"><input type="checkbox" class="checkbox checked:bg-customTeal mr-4 space-x-2"
            [checked]="allPriviledge" (click)="toggleAllPrivilege()" />
          Give Admin Privilege</div> -->
  
        <!-- Member Management -->
        <div tabindex="0" class="collapse collapse-arrow bg-gray-200 border border-base-300 bg-base-100 rounded-box mb-3"
          [ngClass]="{'collapse-open':collapsibleStates['Member'], 'collapse-close':!collapsibleStates['Member']}">
          <input type="checkbox" class="peer" (click)="selectCollapseToOpen('Member')" />
          <div class="collapse-title border-2 rounded-lg font-medium text-lg text-black">
            Member Management
          </div>
          <div class="collapse-content">
            <div class="flex flex-col w-full bg-white px-4 py-8 space-y-8 rounded-md">
  
              <div class="space-x-2 flex items-center"><input type="checkbox"
                  class="checkbox checked:bg-customTeal mr-4 space-x-2" [checked]="allMemberPriviledge"
                  (click)="toggleAllMemberPrivilege()" />
                Give Member Managment Privilege access</div>
  
              <div class="space-y-4">
                <div class="flex flex-col md:flex-row gap-y-4 gap-x-32 ">
                  <div class="flex flex-col space-y-1 md:w-1/3">
                    <p class="text-sm text-darkGray font-bold">Subscribers</p>
                    <label class="flex flex-row w-full space-x-2 label cursor-pointer">
                      <input type="checkbox" class="checkbox checked:bg-customTeal autofill:bg-yellow-200"
                        [checked]="permMember.inviteSub" (click)="togglePermission('inviteSub', 0)" />
                      <!-- <span class="label-text">Invite Subscribers</span> -->
                      <span class="w-full label-text">Add Clients</span>
                    </label>
  
                    <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                      <input type="checkbox" class="checkbox checked:bg-customTeal"
                        [checked]="permMember.enableDisableSub" (click)="togglePermission('enableDisableSub', 0)"  />
                      <span class="w-full label-text">Enable/Disable Subscribers</span>
                    </label>
  
                  </div>
                  <div class="flex flex-col space-y-1 md:w-1/3">
                    <p class="text-sm text-darkGray font-bold">Employee</p>
                    <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                      <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permMember.inviteEmp"
                        (click)="togglePermission('inviteEmp', 0)" />
                      <span class="w-full label-text">Invite Employees</span>
                    </label>
                    <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                      <input type="checkbox" class="checkbox checked:bg-customTeal"
                        [checked]="permMember.enableDisableEmp" (click)="togglePermission('enableDisableEmp', 0)" />
                      <span class="w-full label-text">Enable/Disable Employees</span>
                    </label>
                    <label class="flex flex-row w-full space-x-2 label cursor-pointer">
                      <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permMember.scheduleEmp"
                        (click)="togglePermission('scheduleEmp', 0)" />
                      <span class="w-full label-text">Manage Schedule</span>
                    </label>
  
                    <!-- <label class="label cursor-pointer space-x-4">
                      <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="perm.individualProfile"
                        (click)="toggleIndividualProfile($event)" />
                      <span class="label-text">Edit Profile</span>
                    </label> -->
                 
                  </div>
                  <div class="flex flex-col space-y-1 md:w-1/3" *ngIf="isVerifyUsers">
                    <p class="text-sm text-darkGray font-bold">Requests</p>
                    <label class="flex flex-row w-full space-x-2 label cursor-pointer">
                      <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permMember.acceptRequest"
                        (click)="togglePermission('acceptRequest', 0)" />
                      <span class="w-full label-text">Accept Requests</span>
                    </label>
                    <label class="flex flex-row w-full space-x-2 label cursor-pointer">
                      <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permMember.declineRequest"
                        (click)="togglePermission('declineRequest', 0)" />
                      <span class="w-full label-text">Decline Requests</span>
                    </label>
                  </div>
                </div>
              </div>
  
  
              <div class="space-y-4">
                <hr>
  
                <div class="flex flex-col md:flex-row gap-y-4 gap-x-32 ">
                  
  
                  <div class="flex flex-col space-y-1 md:w-1/3">
                    <p class="text-sm text-darkGray font-bold">Set Password and Contacting Users</p>
                    <label class="flex flex-row w-full space-x-2 label cursor-pointer">
                      <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permMember.resetPass"
                        (click)="togglePermission('resetPass', 0)" />
                      <span class="w-full label-text mr-24">Reset Password</span>
                    </label>
  
                    <label class="flex flex-row w-full space-x-2 label cursor-pointer">
                      <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permMember.contactUsers"
                        (click)="togglePermission('contactUsers', 0)" />
                      <span class="w-full label-text mr-24">Contact Users</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <!-- Post/Events/Services/Products -->
        <div tabindex="0" class="collapse collapse-arrow bg-gray-200 border border-base-300 bg-base-100 rounded-box mb-3"
          [ngClass]="{'collapse-open': collapsibleStates['Post'], 'collapse-close': !collapsibleStates['Post']}">
          <input type="checkbox" class="peer" (click)="selectCollapseToOpen('Post')" />
          <div class="collapse-title border-2 rounded-lg font-medium text-lg text-black">
            Posts / Events / Services / Products
          </div>
  
          <div class="collapse-content">
            <div class="flex flex-col w-full bg-white px-4 py-8 space-y-8 rounded-md">
              <div class="space-x-2 flex items-center"><input type="checkbox"
                  class="checkbox checked:bg-customTeal mr-4 space-x-2" [checked]="allPESPriviledge"
                  (click)="toggleAllPESPrivilege()" />
                Give Posts/Events/Services/Products Privilege access</div>
  
              <div class="space-y-4">
                <div class="flex flex-col md:flex-row gap-y-4 gap-x-32 ">
                  <div class="flex flex-col space-y-1 md:w-1/3">
                    <p class="text-sm text-darkGray font-bold">Posts</p>
                    <label class="flex flex-row w-full space-x-2 label cursor-pointer">
                      <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permPES.createPosts"
                        (click)="togglePermissionPES('createPosts', 1)" />
                      <span class="w-full label-text">Create Posts</span>
                    </label>
  
                    <label class="flex flex-row w-full space-x-2 label cursor-pointer">
                      <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permPES.deletePost"
                        (click)="togglePermissionPES('deletePost', 1)" />
                      <span class="w-full label-text">Delete Post</span>
                    </label>
  
                    <label class="flex flex-row w-full space-x-2 label cursor-pointer">
                      <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permPES.editPost"
                        (click)="togglePermissionPES('editPost', 1)" />
                      <span class="w-full label-text">Edit Post</span>
                    </label>
  
                  </div>
  
                  <div class="flex flex-col space-y-1 md:w-1/3">
                    <p class="text-sm text-darkGray font-bold">Service</p>
                    <label class="flex flex-row w-full space-x-2 label cursor-pointer">
                      <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permPES.createServices"
                        (click)="togglePermissionPES('createServices', 1)" />
                      <span class="w-full label-text">Create Service</span>
                    </label>
  
                    <label class="flex flex-row w-full space-x-2 label cursor-pointer">
                      <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permPES.deleteServices"
                        (click)="togglePermissionPES('deleteServices', 1)" />
                      <span class="label-text w-full">Delete Service</span>
                    </label>
  
                    <label class="flex flex-row w-full space-x-2 label cursor-pointer">
                      <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permPES.editServices"
                        (click)="togglePermissionPES('editServices', 1)" />
                      <span class="w-full label-text">Edit Service</span>
                    </label>
  
                  </div>
  
                  <div class="flex flex-col space-y-1 md:w-1/3">
                    <p class="text-sm text-darkGray font-bold">Service Category</p>
                    <label class="flex flex-row w-full space-x-2 label cursor-pointer">
                      <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permPES.addServiceCategory"
                        (click)="togglePermissionPES('addServiceCategory', 1)" />
                      <span class="w-full label-text">Add Category</span>
                    </label>
  
                    <label class="flex flex-row w-full space-x-2 label cursor-pointer">
                      <input type="checkbox" class="checkbox checked:bg-customTeal"
                        [checked]="permPES.editServiceCategory" (click)="togglePermissionPES('editServiceCategory', 1)" />
                      <span class="w-full label-text">Edit/Delete Category</span>
                    </label>
                  </div>
  
                </div>
              </div>
  
              <div class="space-y-4">
                <hr>
                <div class="flex flex-col md:flex-row gap-y-4 gap-x-32">
                  <div class="flex flex-col space-y-1 md:w-1/3">
                    <p class="text-sm text-darkGray font-bold">Events</p>
  
                    <label class="flex flex-row w-full space-x-2 label cursor-pointer">
                      <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permPES.createEvents"
                        (click)="togglePermissionPES('createEvents', 1)" />
                      <span class="w-full label-text">Create Events</span>
                    </label>
  
                    <label class="flex flex-row w-full space-x-2 label cursor-pointer">
                      <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permPES.editEvents"
                        (click)="togglePermissionPES('editEvents', 1)" />
                      <span class="w-full label-text">Edit Events</span>
                    </label>
  
                    <label class="flex flex-row w-full space-x-2 label cursor-pointer">
                      <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permPES.deleteEvents"
                        (click)="togglePermissionPES('deleteEvents', 1)" />
                      <span class="w-full label-text">Delete Events</span>
                    </label>
                  </div>
  
                  <div class="flex flex-col space-y-1 md:w-1/3">
                    <p class="text-sm text-darkGray font-bold">Products</p>
  
                    <label class="flex flex-row w-full space-x-2 label cursor-pointer">
                      <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permPES.createProducts"
                        (click)="togglePermissionPES('createProducts', 1)" />
                      <span class="label-text w-full">Create Products</span>
                    </label>
  
                    <label class="flex flex-row w-full space-x-2 label cursor-pointer">
                      <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permPES.deleteProducts"
                        (click)="togglePermissionPES('deleteProducts', 1)" />
                      <span class="label-text w-full">Delete Products</span>
                    </label>
  
                    <label class="flex flex-row w-full space-x-2 label cursor-pointer">
                      <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permPES.editProducts"
                        (click)="togglePermissionPES('editProducts', 1)" />
                      <span class="label-text w-full">Edit Products</span>
                    </label>
                  </div>
  
                  <div class="flex flex-col space-y-1 md:w-1/3">
                    <p class="text-sm text-darkGray font-bold">Products Category</p>
                    <label class="flex flex-row w-full space-x-2 label cursor-pointer">
                      <input type="checkbox" class="checkbox checked:bg-customTeal"
                        [checked]="permPES.addCategoryProducts" (click)="togglePermissionPES('addCategoryProducts', 1)" />
                      <span class="w-full label-text">Add Category</span>
                    </label>
  
                    <label class="flex flex-row w-full space-x-2 label cursor-pointer">
                      <input type="checkbox" class="checkbox checked:bg-customTeal"
                        [checked]="permPES.editCategoryProducts" (click)="togglePermissionPES('editCategoryProducts', 1)" />
                      <span class="label-text w-full">Edit/delete Category</span>
                    </label>
  
                  </div>
                </div>
              </div>
  
  
            </div>
          </div>
  
        </div>
  
        <!-- Analytics -->
        <div tabindex="0" class="collapse collapse-arrow bg-gray-200 border border-base-300 bg-base-100 rounded-box mb-3"
          [ngClass]="{'collapse-open':collapsibleStates['Analytics'], 'collapse-close':!collapsibleStates['Analytics']}">
          <input type="checkbox" class="peer" (click)="selectCollapseToOpen('Analytics')" />
          <div class="collapse-title border-2 rounded-lg font-medium text-lg text-black">
            Analytics
          </div>
          <div class="collapse-content">
            <div class="flex flex-col w-full bg-white px-4 py-8 space-y-8 rounded-md">
              <div class="space-y-4">
                <div class="flex flex-col md:flex-row gap-y-4 gap-x-32 ">
                  <div class="flex flex-col space-y-1 space-x-2">
                    <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                      <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="perm.viewAnalytics"
                        (click)="toggleViewAnalytics()" />
                      <span class="label-text w-full font-bold text-md">View Analytics</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
  
        </div>
  
        <!-- Social Media -->
        <div tabindex="0" class="collapse collapse-arrow bg-gray-200 border border-base-300 bg-base-100 rounded-box mb-3"
          [ngClass]="{'collapse-open':collapsibleStates['Social'], 'collapse-close':!collapsibleStates['Social']}">
          <input type="checkbox" class="peer" (click)="selectCollapseToOpen('Social')" />
          <div class="collapse-title border-2 rounded-lg font-medium text-lg text-black">
            Social Media
          </div>
  
          <div class="collapse-content">
            <div class="flex flex-col w-full bg-white px-4 py-8 space-y-8 rounded-md">
              <div class="space-x-2 flex items-center"><input type="checkbox"
                  class="checkbox checked:bg-customTeal mr-4 space-x-2" [checked]="allSocialPriviledge"
                  (click)="toggleAllSocialPrivilege()" />
                Give Social Media Privilege access</div>
              <div class="space-y-4">
                <div class="flex flex-col md:flex-row gap-y-4 gap-x-32 ">
                  <div class="flex flex-col space-y-1 md:w-1/3">
                    <p class="text-sm text-darkGray font-bold">Account</p>
  
                    <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                      <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permSocial.linkAccount"
                        (click)="togglePermissionSocial('linkAccount', 3)" />
                      <span class="label-text w-full">Link Accounts</span>
                    </label>
  
                    <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                      <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permSocial.unlinkAccount"
                        (click)="togglePermissionSocial('unlinkAccount', 3)" />
                      <span class="label-text w-full">Unlink Accounts</span>
                    </label>
  
                  </div>
                  <div class="flex flex-col space-y-1 md:w-1/3">
                    <p class="text-sm text-darkGray font-bold">Social Media Posts</p>
                    <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                      <input type="checkbox" class="checkbox checked:bg-customTeal"
                        [checked]="permSocial.createSocialPost" (click)="togglePermissionSocial('createSocialPost', 3)" />
                      <span class="label-text w-full">Create Posts</span>
                    </label>
  
                    <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                      <input type="checkbox" class="checkbox checked:bg-customTeal"
                        [checked]="permSocial.deleteSocialPost" (click)="togglePermissionSocial('deleteSocialPost', 3)" />
                      <span class="label-text w-full">Delete Posts</span>
                    </label>
  
                    <label class="flex flex-row w-full space-x-2 label cursor-pointer">
                      <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permSocial.editSocialPost"
                        (click)="togglePermissionSocial('editSocialPost', 3)" />
                      <span class="label-text w-full">Edit Posts</span>
                    </label>
                  </div>
  
                  <div class="flex flex-col space-y-1 md:w-1/3">
                    <p class="text-sm text-darkGray font-bold">Social Media Analytics</p>
                    <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                      <input type="checkbox" class="checkbox checked:bg-customTeal"
                        [checked]="permSocial.socialViewAnalytics" (click)="togglePermissionSocial('socialViewAnalytics', 3)" />
                      <span class="label-text w-full">View Analytics</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="space-y-4">
                <div class="flex flex-col md:flex-row gap-y-4 gap-x-32 ">
                  <div class="flex flex-col space-y-1 md:w-1/3">
                    <p class="text-sm text-darkGray font-bold">Profile</p>
  
                    <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                      <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permSocial.createProfile"
                        (click)="togglePermissionSocial('createProfile', 3)" />
                      <span class="label-text w-full">Create Profile</span>
                    </label>
  
                    <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                      <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permSocial.deleteProfile"
                        (click)="togglePermissionSocial('deleteProfile', 3)" />
                      <span class="label-text w-full">Delete Profile</span>
                    </label>
  
                  </div>
  
                </div>
              </div>
            </div>
          </div>
  
        </div>
  
        <!-- Chat -->
        <div tabindex="0" class="collapse collapse-arrow bg-gray-200 border border-base-300 bg-base-100 rounded-box mb-3"
          [ngClass]="{'collapse-open': collapsibleStates['Chat'], 'collapse-close':!collapsibleStates['Chat']}">
          <input type="checkbox" class="peer" (click)="selectCollapseToOpen('Chat')" />
          <div class="collapse-title border-2 rounded-lg font-medium text-lg text-black">
            Chats
          </div>
  
          <div class="collapse-content">
            <div class="flex flex-col w-full bg-white px-4 py-8 space-y-8 rounded-md">
              <div class="space-x-2 flex items-center"><input type="checkbox"
                  class="checkbox checked:bg-customTeal mr-4 space-x-2" [checked]="allChatPriviledge"
                  (click)="toggleAllChatPrivilege()" />
                Give Chat Privilege access</div>
              <div class="space-y-4">
                <div class="flex flex-col md:flex-row gap-y-4 gap-x-32 ">
                  <div class="flex flex-col space-y-1 md:w-1/3">
                    <p class="text-sm text-darkGray font-bold">Chat</p>
  
                    <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                      <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permChat.privateChat"
                        (click)="togglePermissionChat('privateChat',5)" />
                      <span class="label-text w-full">Private Chat</span>
                    </label>
  
                    <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                      <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permChat.groups"
                        (click)="togglePermissionChat('groups',5)" />
                      <span class="label-text w-full">Groups</span>
                    </label>
  
                  </div>
                  <div class="flex flex-col space-y-1 md:w-1/3">
                    <p class="text-sm text-darkGray font-bold">Create</p>
                    <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                      <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permChat.createGroup"
                        (click)="togglePermissionChat('createGroup',5)" />
                      <span class="label-text w-full">Create Groups</span>
                    </label>
  
                    <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                      <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permChat.newEmployee"
                        (click)="togglePermissionChat('newEmployee',5)" />
                      <span class="label-text w-full">New Employee Chat</span>
                    </label>
  
  
                  </div>
  
                </div>
              </div>
  
            </div>
          </div>
        </div>
  
        <!-- Custom pages -->
        <div tabindex="0" class="collapse collapse-arrow bg-gray-200 border border-base-300 bg-base-100 rounded-box mb-3"
        [ngClass]="{'collapse-open': collapsibleStates['CustomPage'], 'collapse-close':!collapsibleStates['CustomPage']}">
        <input type="checkbox" class="peer" (click)="selectCollapseToOpen('CustomPage')" />
        <div class="collapse-title border-2 rounded-lg font-medium text-lg text-black">
          Custom pages
        </div>
  
        <div class="collapse-content">
          <div class="flex flex-col w-full bg-white px-4 py-8 space-y-8 rounded-md">
            <div class="space-x-2 flex items-center"><input type="checkbox"
                class="checkbox checked:bg-customTeal mr-4 space-x-2" [checked]="allCustomPagePrivilege"
                (click)="toggleAllCustomPagePrivilege()" />
              Give Custom Page privilege access</div>
            <div class="space-y-4">
              <div class="flex flex-col md:flex-row gap-y-4 gap-x-32 ">
                <div class="flex flex-col space-y-1 md:w-1/3">
                  <p class="text-sm text-darkGray font-bold">Pages</p>
                  <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                    <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permCustomPage.viewPage"
                        (click)="togglePermissionCustomPage('viewPage',6)" />
                    <span class="label-text w-full">View custom pages</span>
                </label>
                  <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                    <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permCustomPage.createPage"
                      (click)="togglePermissionCustomPage('createPage',6)" />
                    <span class="label-text w-full">Create custom pages</span>
                  </label>
  
                  <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                    <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permCustomPage.editPage"
                      (click)="togglePermissionCustomPage('editPage',6)" />
                    <span class="label-text w-full">Edit custom pages</span>
                  </label>
                  <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                    <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permCustomPage.deletePage"
                      (click)="togglePermissionCustomPage('deletePage',6)" />
                    <span class="label-text w-full">Delete custom pages</span>
                  </label>
  
                </div>
                <div class="flex flex-col space-y-1 md:w-1/3">
                  <p class="text-sm text-darkGray font-bold">Items</p>
                  <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                    <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permCustomPage.viewItem"
                        (click)="togglePermissionCustomPage('viewItem',6)" />
                    <span class="label-text w-full">View page items</span>
                  </label>
                  <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                    <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permCustomPage.createItem"
                      (click)="togglePermissionCustomPage('createItem',6)" />
                    <span class="label-text w-full">Create page Items</span>
                  </label>
  
                  <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                    <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permCustomPage.editItem"
                      (click)="togglePermissionCustomPage('editItem',6)" />
                    <span class="label-text w-full">Edit page items</span>
                  </label>
                  <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                    <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permCustomPage.deleteItem"
                      (click)="togglePermissionCustomPage('deleteItem',6)" />
                    <span class="label-text w-full">Delete page items</span>
                  </label>
  
                </div>
  
              </div>
            </div>
  
          </div>
        </div>
  
        </div>
        <br />
  
        <p class="px-4 font-bold text-lg">Grant Access for Special Privilege</p>
  
        <!-- <div class="space-x-2 items-center"><input type="checkbox" class="checkbox checked:bg-customTeal mr-4 space-x-2"
            [checked]="allSpecialPriviledge" (click)="toggleAllSpecialPrivilege()" />
          Give All Special Privilege</div> -->
  
        <div tabindex="0" class="collapse collapse-arrow bg-gray-200 border border-base-300 bg-base-100 rounded-box mb-3"
          [ngClass]="{'collapse-open':collapsibleStates['Special'], 'collapse-close':!collapsibleStates['Special']}">
          <input type="checkbox" class="peer" (click)="selectCollapseToOpen('Special')" />
          <div class="collapse-title border-2 rounded-lg font-medium text-lg text-black">
            Special Application Setups
          </div>
  
          <div class="collapse-content">
            <div class="flex flex-col w-full bg-white px-4 py-8 space-y-8 rounded-md">
              <div class="space-y-4">
                <div class="flex flex-col md:flex-row gap-y-4 gap-x-4 ">
                  <div class="flex flex-col space-y-1 md:w-1/4">
                    <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                      <input type="checkbox" class="checkbox checked:bg-customTeal"
                        [checked]="permSpecial.landingPageSetup" (click)="togglePermissionSpecial('landingPageSetup',4)" />
                      <span class="label-text w-full">Landing Page Setup</span>
                    </label>
                  </div>
                  <div class="flex flex-col space-y-1 md:w-1/4">
                    <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                      <input type="checkbox" class="checkbox checked:bg-customTeal"
                        [checked]="permSpecial.aboutUsPageSetup" (click)="togglePermissionSpecial('aboutUsPageSetup',4)" />
                      <span class="label-text w-full">About Us Setup</span>
                    </label>
                  </div>
  
                  <!-- <div class="flex flex-col space-y-1 md:w-1/4">
                    <label class="label cursor-pointer ">
                      <input type="checkbox" class="checkbox checked:bg-customTeal"
                        [checked]="permSpecial.registrationSetup" (click)="toggleRegistrationSetup($event)" />
                      <span class="label-text">Sign In/ Login Setup</span>
                    </label>
                  </div> -->
  
                  <div class="flex flex-col space-y-1 md:w-1/4">
                    <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                      <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permSpecial.subInfo"
                        (click)="togglePermissionSpecial('subInfo',4)" />
                      <span class="label-text w-full">Customize Subscriber's Information</span>
                    </label>
                  </div>
  
                  <div class="flex flex-col space-y-1 md:w-1/4">
                    <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                      <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permSpecial.appSetup"
                        (click)="togglePermissionSpecial('appSetup',4)" />
                      <span class="label-text w-full">App Setup</span>
                    </label>
                  </div>
  
                </div>
  
                <hr />
  
                <div class="flex flex-col md:flex-row gap-y-4 gap-x-4 ">
                  <div class="flex flex-col space-y-1 md:w-1/4">
                    <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                      <input type="checkbox" class="checkbox checked:bg-customTeal"
                        [checked]="permSpecial.accountVerification" (click)="togglePermissionSpecial('accountVerification',4)" />
                      <span class="label-text w-full">Account Verification Requests</span>
                    </label>
                  </div>
                  <div class="flex flex-col space-y-1 md:w-1/4">
                    <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                      <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permSpecial.editClients"
                        (click)="togglePermissionSpecial('editClients',4)" />
                      <span class="label-text w-full">Edit Clients</span>
                    </label>
                  </div>
  
                  <div class="flex flex-col space-y-1 md:w-1/4">
                    <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                      <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permSpecial.editEmployees"
                        (click)="togglePermissionSpecial('editEmployees',4)" />
                      <span class="label-text w-full">Edit Employees</span>
                    </label>
                  </div>
  
                  <div class="flex flex-col space-y-1 md:w-1/4">
                    <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                      <input type="checkbox" class="checkbox checked:bg-customTeal"
                        [checked]="permSpecial.permissionAccess" (click)="togglePermissionSpecial('permissionAccess',4)" />
                      <span class="w-full label-text">Give Permission Access</span>
                    </label>
                  </div>
                  <div class="flex flex-col space-y-1 md:w-1/4">
                    <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                      <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permSpecial.calendar"
                        (click)="togglePermissionSpecial('calendar',4)" />
                      <span class="w-full label-text">Calendar</span>
                    </label>
  
                    <!-- 
                      <label class="label cursor-pointer ">
                        <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permSpecial.calendar"
                        (click)="toggleCalendar()"/>
                        <span class="label-text">Calendar</span>
                      </label> -->
  
                  </div>
  
                  <div class="flex flex-col space-y-1 md:w-1/4">
                    <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                      <input type="checkbox" class="checkbox checked:bg-customTeal" [checked]="permSpecial.newPage"
                        (click)="togglePermissionSpecial('newPage',4)" />
                      <span class="label-text w-full">Create new Page</span>
                    </label>
                  </div>
                </div>
  
                <hr />
                <div class="flex flex-col md:flex-row gap-y-4 gap-x-4 ">
  
                  <div class="flex flex-col space-y-1 md:w-1/4">
                    <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                      <input type="checkbox" class="checkbox checked:bg-customTeal"
                        [checked]="permSpecial.welcomePopup" (click)="togglePermissionSpecial('welcomePopup',4)" />
                      <span class="label-text w-full">Welcome Popup</span>
                    </label>
                  </div>
  
                  <div class="flex flex-col space-y-1 md:w-1/4">
                    <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                      <input type="checkbox" class="checkbox checked:bg-customTeal"
                        [checked]="permSpecial.importAndDelete" (click)="togglePermissionSpecial('importAndDelete',4)" />
                      <span class="label-text w-full">Import/Delete Contact</span>
                    </label>
                  </div>
  
                  <div class="flex flex-col space-y-1 md:w-1/4">
                    <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                      <input type="checkbox" class="checkbox checked:bg-customTeal"
                        [checked]="permSpecial.editFAQ" (click)="togglePermissionSpecial('editFAQ',4)" />
                      <span class="label-text w-full">Edit FAQ</span>
                    </label>
                  </div>
  
                  <div class="flex flex-col space-y-1 md:w-1/4">
                    <label class="flex flex-row w-full space-x-2 label cursor-pointer ">
                      <input type="checkbox" class="checkbox checked:bg-customTeal"
                        [checked]="permSpecial.deleteFAQ" (click)="togglePermissionSpecial('deleteFAQ',4)" />
                      <span class="label-text w-full">Delete FAQ</span>
                    </label>
                  </div>
  
  
                </div>
  
              </div>
            </div>
          </div>
  
  
        </div>
  
      </div>
      <br />
   

      <div class="modal-action items-center justify-end m-2 mt-4">
        <app-button (click)="isAccess=false"  class="justify-end items-end" 
        [customConfig]="{ text: 'cancel', type: 'button', buttonType: 'secondaryButton' }"
       ></app-button>
       <app-button (click)="savePermission()" class="justify-end items-end" 
       [customConfig]="{ text: 'Save', type: 'button', buttonType: 'primaryButton' }"
      ></app-button>
      </div>
    </div>
  
    <!-- Save permissions for the Employee -->
    <app-modal modalTitle="{{GivenName}} {{LastName}} 's Permissions" btnName="Save" *ngIf="openPermissionModal"
      (cancelBtnClicked)="openPermissionModal=false" (btnClicked)="savePermission($event)">
      <p class="text-md text-center">Employees who are given permissions will be able to access the given privileges.
      </p>
      <hr />
      <p class="text-md text-center my-2">Are you sure you want to save the changes?</p>
    </app-modal>
  
  </div>
</app-modal-box>




<app-alert class="fixed top-2 right-1 flex" [toastType]="toastType" [toastMessage]="toastMessage"
  [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>