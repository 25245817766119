<div class="flex flex-col">
    <div class="flex space-x-2 md:space-x-8 pb-2 " *ngIf="isAdmin || (permChat && permChat.createGroup)">
        <!-- <div class="flex-row text-center text-md">
            <button (click)="onCreateGroup()"
                class="font-medium border-2 border-customTeal text-customTeal cursor-pointer px-2 py-1 md:px-8 md:py-1 rounded-full">
                Create Group</button>
        </div> -->
         <button (click)="onCreateGroup()"
    class="flex items-center space-x-2 rounded bg-customTeal px-2 md:px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
          
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"   stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
          </svg>
          
        <span class="text-white">Create a Group</span>
    </button>
    </div>
    <div class="flex flex-col space-y-4 md:flex-row w-full md:space-x-6 md:space-y-0 ">
        <div class="hidden md:flex md:flex-row space-x-6 mb-4 w-full">
            <!-- left chat list -->
            <app-chats-list class="flex flex-col w-1/3"
                [chatType]="'group'" (groupSelected)=onGroupSelect($event)>
                <!-- [isGroup]=true (groupSelected)=onGroupSelect($event)> -->
            </app-chats-list>
            <!-- Message displaying and sending area -->
            <app-messages class="flex flex-col w-2/3"
                [chatType]="'group'" [chatSelected]='groupSelected' [selectedConv]='selectedConv'
                (sendGroup)=onMessageSent($event) (editGroup)=onGroupEdit($event)>
            </app-messages>
        </div>


        <div class="flex flex-col md:hidden space-x-6 mb-4 w-full ">
            <!-- left chat list -->
            <app-chats-list *ngIf="!showMessages"
                class="flex flex-col w-full"
                [chatType]="'group'" (groupSelected)=onMobileGroupSelect($event)>
                <!-- [isGroup]=true   (groupSelected)=onMobileGroupSelect($event) > -->
            </app-chats-list>
            <!-- Message displaying and sending area -->
            <app-messages *ngIf="showMessages"
                class="flex flex-col w-full" [chatType]="'group'"
                [chatSelected]='groupSelected' [selectedConv]='selectedConv' (sendGroup)=onMessageSent($event)
                (editGroup)=onGroupEdit($event) [showBack]="true" (back)="onBack($event)">
            </app-messages>
        </div>
    </div>
</div>
<div id="createModal" *ngIf="createModalOpen" class="modal"
    [ngClass]="{'modal-open': createModalOpen, 'modal-action': !createModalOpen }">
    <div class="modal-box px-6 rounded-md max-w-3xl">

        <form class="" [formGroup]="newGroupForm" (ngSubmit)="createGroup(newGroupForm.value)">
            <div class="">
            <div class="pb-3 flex justify-between">
                <h3 class="font-bold text-lg">{{ onEdit ? 'Edit': 'Create'}} Group</h3>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" (click)="closeModal()" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                  </svg>
                  
            </div>                
            <hr>

                <br />
                <div class="flex flex-col gap-y-2">
                    <ng-container *ngIf="step == 1">
                        <div class="space-y-4">
                            
                        <div class="">
                            <label for=" " class="text-base text-gray-600 font-semibold"> Name <span class="text-gray-400">*</span></label>
                            
                            <input id="name" type="text" formControlName="name" required placeholder="Group name"
                                class="focus:invalid:border-red-500 py-3 px-3  input input-bordered border-gray-300 w-full"
                                autocomplete="off" />

                            <div *ngIf="(name.dirty || name.touched) && name.invalid">
                                <small *ngIf="name.errors.required" class="text-red-600">
                                    Group name is required.
                                </small>
                            </div>
                        </div>

                        <div class="">
                            <label for=" " class="text-base text-gray-600 font-semibold"> Description </label>

                            <input id="name" type="text" formControlName="description"
                                placeholder="Group description"
                                class="focus:invalid:border-red-500 py-3 px-3  input input-bordered border-gray-300 w-full"
                                autocomplete="off" />

                        </div>

                        <div class="space-y-2">
                            <label for=" " class="text-base text-gray-600 font-semibold"> Image </label>
                            
                            <div class="w-full md:max-w-x flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
                                <div *ngIf="!isMobile"                                                                                                                                                                                                                                                                                                                                                        
                                    class="md:w-1/2 flex justify-center content-center w-full h-44 max-w-x border border-solid border-gray-200 bg-white rounded-lg gap-x-3">

                                    <label class="custom-file-upload  self-center flex flex-col">
                                        <i
                                            class="fa fa-plus-circle text-customTeal text-3xl text-center self-center"></i>
                                        <input type="file" type="file" accept="image/*"
                                            (change)="handleImageInput($event.target.files)">
                                        <span class="block font-bold pt-1">Upload Images</span>
                                    </label>
                                </div>

                                <div class="flex p-3" *ngIf="isMobile">
                                    <app-image-uploader class="justify-start items-start p-3"  (onImageUpload)=onImageUpload($event) [customConfig]="{'upload':false}"></app-image-uploader>
                                    
                                    <!-- <button type="button"
                                        class="py-2 px-8 modal-button gap-2 text-black border-2 border-customTeal rounded-lg w-full"
                                        type="button" (click)="handleMobilePhotoInput()">
                                        Upload Image
                                    </button> -->
                                </div>
                                <ng-container *ngIf="!onEdit">

                                    <div class="relative" *ngIf="base64Image " >
                                        <img loading="lazy" [src]="base64Image" class="object-cover  w-32 h-32 rounded-md cover"
                                            alt="Image">
                                        <button (click)="removeFile()" type="button"
                                            class="absolute top-0 translate-x-24 -translate-y-4 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                                            <i class="fa fa-trash text-md"></i>
                                        </button>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="onEdit">

                                    <div class="relative" *ngIf="base64Image">
                                        <img loading="lazy" [src]="base64Image"  class="object-cover  w-32 h-32 rounded-md cover"
                                            alt="Image">
                                        <button (click)="removeFile()" type="button"
                                            class="absolute top-0 translate-x-24 -translate-y-4 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                                            <i class="fa fa-trash text-md"></i>
                                        </button>
                                    </div>

                                    <div class="relative" *ngIf="profilePicture.value && profilePicture.value != ''">
                                        <img loading="lazy" [src]="profilePicture.value"  class="object-cover  w-32 h-32 rounded-md cover"
                                            alt="Image">
                                        <button (click)="removeSavedFile()" type="button"
                                            class="absolute top-0 translate-x-24 -translate-y-4 rounded-full bg-white w-10 h-10 border-2 border-veryLightGray hover:border-4  hover:border-customTeal shadow-md">
                                            <i class="fa fa-trash text-md"></i>
                                        </button>
                                    </div>
                                </ng-container>

                                <p class="text-red-700 py-2" *ngIf="imgErrorMsg">
                                    <strong><small><i class="material-icons">error_outline</i>
                                            {{imgErrorMsg}}</small></strong>
                                </p>
                            </div>
                        </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="step == 2">
                        <div class="flex flex-col md:flex-row gap-y-2 md:gap-x-2">
                            <div class="w-full">
                                <div class="space-y-2">
                                  
                                    <label for=" " class="text-base text-gray-600 font-semibold"> Add Users </label>

                                    <input placeholder="Search User" class="input input-bordered input-md w-full"
                                        type="search" autocomplete="off" formControlName="searchQuery"
                                        (ngModelChange)="searchChat()" (focus)="openUserSuggestion()" />
                                </div>

                                <div class="space-y-2" *ngIf="users.value.length > 0">
                                    <div class="flex flex-wrap  ">
                                        <!-- button -->
                                        <div *ngFor="let user of users.value;let i=index">
                                            <button type="button"
                                                class="border-2 border-customTeal rounded-full py-1 px-4 mx-2 my-2"
                                                [ngClass]="{'border-gray-300': user.uid === currentUser.uid}"
                                               >
                                                <span>{{user.firstName}} {{user.lastName}}</span>
                                                <span *ngIf="user.uid !== currentUser.uid"  (click)="removeUserFromGroup(i,true)">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline"
                                                        fill="none" viewBox="0 0 24 24" stroke="currentColor"
                                                        stroke-width="2">
                                                        <path stroke-linecap="round" stroke-linejoin="round"
                                                            d="M6 18L18 6M6 6l12 12" />
                                                    </svg>
                                                </span>

                                            </button>
                                        </div>

                                    </div>
                                </div>
                                <div *ngFor="let x of filteredUser">
                                    <!-- user suggestions chats -->
                                    <div class="flex space-x-4" (click)="selectUser(x)">
                                        <div class=" w-full">
                                            <div class="hover:cursor-pointer py-3 border-b">
                                                <app-chat-info [data]="getUserData(x)" class="hover:cursor-pointer">
                                                </app-chat-info>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </ng-container>

                </div>
            </div>
            <!-- <div class="modal-action">
                <button type="submit" [disabled]="!newGroupForm.valid"
                    class="btn bg-gray-400 text-customTeal border border-customTeal" style="margin-right: 10px;">
                    <span>Create</span>
                </button>
                <a (click)="closeCreateModal()" class="modal-close btn bg-customTeal text-white btn-flat">Cancel</a>
            </div> -->

            <div class="flex flex-wrap md:space-x-5 pt-10 items-center md:flex-row gap-3 md:gap-0 justify-between w-full ">
                <div class="flex flex-col gap-y-3 md:gap-y-0 md:flex-row md:space-x-5 ">
                    <div class="form-control flex flex-row gap-x-5 ">
                   <div>
                    <button type="button" (click)="closeModal()"
                    class="flex items-center text-customTeal rounded bg-white px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal border border-customTeal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
                        Cancel
                    </button>
                   </div>
                    </div>
                </div>

               <div class="flex gap-2">
                <div class="flex flex-col gap-y-3 md:gap-y-0 md:flex-row md:gap-x-5 ">
                    <div class="form-control flex flex-row gap-x-5 ">
                        <button type="button" (click)="nextStep()"
                            class="flex items-center space-x-2 rounded bg-customTeal px-2 md:px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                            [ngClass]="{'bg-[#cccccc] text-[#666666] border-none': !newGroupForm.valid}"
                            [disabled]="!newGroupForm.valid">
                            {{step ==1 ? 'Next' : 'Previous'}}
                        </button>
                    </div>

                </div>
                <div class="flex flex-row md:gap-x-5 " *ngIf="step ==2">
                    <div class="form-control flex flex-row gap-x-5 ">
                        <button type="submit"
                            class="flex items-center space-x-2 rounded bg-customTeal px-2 md:px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                            [ngClass]="{'bg-[#cccccc] text-[#666666] border-none': !newGroupForm.valid}"
                            [disabled]="!newGroupForm.valid">
                            Save
                        </button>
                    </div>

                </div>
               </div>
            </div>
        </form>

    </div>

</div>


<!-- toast -->
<app-alert class="fixed top-0 flex justify-end w-full" [toastType]="toastType" [toastMessage]="toastMessage"
    [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>