import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { UserService } from 'src/app/services/firebase/user.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl } from '@angular/forms';
import { ImageModel } from './../../../models/ImageModel';
import { MediaService } from 'src/app/services/api/media.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Subscription } from 'rxjs';
import { UserState } from 'src/app/state/app.state';
import { Store } from '@ngrx/store';
import { selectAppSettings } from 'src/app/state/app.selectors';

@Component({
  selector: 'app-admin-profile',
  templateUrl: './admin-profile.component.html',
  styleUrls: ['./admin-profile.component.css']
})
export class AdminProfileComponent implements OnInit {

  GivenName: any;
  LastName: any;
  // cellPhone: any;
  Email: any;
  JobTitle: any;
  currentUser: any;
  EditProfileForm: UntypedFormGroup;
  isEdit = false;
  user: any;
  mediaType: any;
  binDoc = undefined;
  base64Image = undefined;
  mobileBase64Image: any;
  isMobile = false;
  model: any;
  imgURL: any;
  imgErrorMsg: any;
  pdfErrorMsg: any;
  loggedUser: any;
  id: any;
  confirmDeleteModal: boolean;
  confirmSecondModal: boolean;
  confirmThreeModal: boolean;
  beforeDeleteModal : boolean = false;

  public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')',
  ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];


  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;

  appName: any;
  names: any;

  saveModal: boolean;

  appSettings$: Subscription;
  constructor(private route: ActivatedRoute,
              private userService: UserService,
              private fireMemberService: FireMembersService,
              private authService: AuthService,
              private router: Router,
              private fb: UntypedFormBuilder,
              private afAuth: AngularFireAuth,
              private mediaService: MediaService,
              public store: Store<UserState>,) {
                this.appSettings$ = this.store.select(selectAppSettings).subscribe((settings) => { this.appName = settings.appName; })
  }

  ngOnDistroy(){
    this.appSettings$.unsubscribe();
  }

  ngOnInit(): void {
    this.currentUser = this.authService.getGlobalUser();
    this.id = this.currentUser.uid;

    this.EditProfileForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      cellPhone: [''],
      email: ['', [Validators.required, Validators.email]],
    });

    this.userService.getUserById(this.currentUser.uid).valueChanges().subscribe(
      users => {
        this.user = users[0];
        this.base64Image = this.user.profileImgLink ? this.user.profileImgLink : undefined
        this.EditProfileForm.patchValue({
          firstName: this.user.GivenName ? this.user.GivenName : this.currentUser.GivenName,
          lastName: this.user.LastName ? this.user.LastName : this.currentUser.LastName,
          email: this.user.Email ? this.user.Email : this.currentUser.Email,
          cellPhone: this.user.cellPhone ? this.user.cellPhone : this.currentUser.cellPhone

        });
      }
    );


  }

  edit() {
    this.isEdit = true;
    // this.router.navigate(['/admin/admin-profile-settings'])
  }
  SubmitRequest(model: any) {
    this.mediaType = 'Photo';
    this.currentUser.GivenName = model.firstName;
    this.currentUser.LastName = model.lastName;
    this.currentUser.Email = model.email;
    this.currentUser.cellPhone = model.cellPhone;
    this.closeSaveModal();

    if (this.isMobile) {
      if (this.mobileBase64Image){
        const img = new ImageModel();
        img.imgBase64 = this.mobileBase64Image;
        this.mediaService.uploadImage(img).subscribe(upload => {
          if (upload) {
            this.currentUser.profileImgLink = upload.imgLink;
            this.imgURL = upload.imgLink;
          }
        });
      } else {
        this.toast({ html: 'User Updated Successfully.', classes: 'green', type: 'success' });
        setTimeout(() => {
            this.userService.updateUser(this.currentUser).then(res => {
            });
          }
        , 2000);
      }

    } else {
      if (this.binDoc) {
        const reader = new FileReader();
        reader.readAsDataURL(this.binDoc);
        reader.onload = () => {
          this.base64Image = reader.result as string;
        };
        const img = new ImageModel();
        img.imgBase64 = this.base64Image.replace(/^data:image\/[a-z]+;base64,/, '');
        this.mediaService.UploadBinImage(this.binDoc).subscribe(
          upload => {
            this.currentUser.profileImgLink = upload.imgLink;
            this.imgURL = upload.imgLink;
            this.toast({ html: 'User Updated Successfully.', classes: 'green', type: 'success' });
            setTimeout(() => {
                this.userService.updateUser(this.currentUser).then(res => {
                });
              }
            , 2000);


          });

      } else {
      this.toast({ html: 'User Updated Successfully.', classes: 'green', type: 'success' });
      setTimeout(() => {
          this.userService.updateUser(this.currentUser).then(res => {
          });
        }
      , 2000);
      }

    }

  }

  private validateImage(name: any) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() === 'png') {
      return true;
    } else if (ext.toLowerCase() === 'jpg') {
      return true;
    } else if (ext.toLowerCase() === 'jpeg') {
      return true;
    } else {
      return false;
    }
  }
  uploadPhoto() {
    this.mediaType = 'Photo';
  }

  removeFile() {
    this.binDoc = undefined;
    this.base64Image = undefined;
    this.imgLink.patchValue('');
  }

  handleFileInput(files: FileList) {
    if (!this.validateImage(files[0].name)) {
      this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';
      return;
    }
    this.binDoc = files[0];
    this.pdfErrorMsg = undefined;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.base64Image = reader.result as string;
    };
  }
  openDeleteModalOne(){
    this.confirmDeleteModal = true;
  }
  closeDeleteModalOne(){
    this.confirmDeleteModal = false;
  }
  openSecondModalTwo(){
    this.closeDeleteModalOne();
    this.confirmSecondModal = true;
  }
  closeSecondModalTwo(){
    this.confirmSecondModal = false;
  }
  openThreeModal(){
    this.closeSecondModalTwo();
    this.confirmThreeModal = true;
  }
  closeThreeModal(){
    this.confirmThreeModal = false;
  }

  beforeDelete(){
    this.closeThreeModal();
    this.beforeDeleteModal = true
    setTimeout(() => {
      this.beforeDeleteModal = false
      //setTimeout(() => {
      this.deleteUser(this.currentUser);
      //}, 1000)
    }, 2500);
  }

  deleteUser(currentUser){
   // this.closeDeleteModal();
      this.afAuth.currentUser.then(user=>{
        this.userService.deleteUserAccount(currentUser.uid);
        user?.delete();
        setTimeout(() => {
          this.router.navigate(['/'])
        },800)
      }).catch((error)=>{
        this.toast({ html:`${{error}}`, classes: 'red', type: 'failure' });
      });
  
     }
  
  openSaveModal(){
    this.saveModal = true;
  }
  closeSaveModal(){
    this.saveModal = false;
  }



  get firstName() { return this.EditProfileForm.get('firstName'); }
  get lastName() { return this.EditProfileForm.get('lastName'); }
  get email() { return this.EditProfileForm.get('email'); }
  get cellPhone() { return this.EditProfileForm.get('cellPhone'); }
  get imgLink() { return this.EditProfileForm.get('imgLink'); }

  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
    }, 2000);
  }
}
