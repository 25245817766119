<!-- <div class="flex items-center justify-center pt-4">
    <div class="flex flex-col space-y-1 w-full lg:w-4/6">
        <div>
            <div class="flex flex-row space-x-2 w-full ">
                <div>
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.625 10.5H14.875V15.75H9.625V10.5Z" fill="black"/>
                        <path d="M16.625 3.5H14.875V1.75H13.125V3.5H7.875V1.75H6.125V3.5H4.375C3.40987 3.5 2.625 4.28487 2.625 5.25V17.5C2.625 18.4651 3.40987 19.25 4.375 19.25H16.625C17.5901 19.25 18.375 18.4651 18.375 17.5V5.25C18.375 4.28487 17.5901 3.5 16.625 3.5ZM16.6259 17.5H4.375V7H16.625L16.6259 17.5Z" fill="black"/>
                        </svg>
                </div>
                <h1 class="text-xl">Feed</h1>  
            </div>
            <div class="divider p-0"></div>
        </div>


        <app-create-feed></app-create-feed>

    </div>
</div> -->
<div>
    reviews page
    google reviews

<div class="w-full">
    <iframe width="100%" height="300" src="https://widget.elfsig.ht/f817c567-d7ad-4a49-afce-b85c46acdb96" title="W3Schools Free Online Web Tutorials"></iframe>

</div>
</div>