import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef} from '@angular/core';
import { FormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ImageModel } from 'src/app/models/ImageModel';
import { MediaService } from 'src/app/services/api/media.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { MessagingService } from 'src/app/services/firebase/messaging.service';
import { UserService } from 'src/app/services/firebase/user.service';



declare var M: any;
declare var $: any;

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css'],

})
export class MessagsComponent implements OnInit {

  @ViewChild('messagesContainer') private messagesContainer: ElementRef
  @ViewChild('messagesForm') private messagesForm: ElementRef

  

  @Input() chatSelected
  @Input() selectedConv
  @Input() showBack
  @Input() showHeader

  // @Input() isGroup
  @Input() chatType
  @Output() sendGroup = new EventEmitter<{ id: string }>();
  @Output() sendPrivate = new EventEmitter<{ title: string }>();
  @Output() sendEmployee = new EventEmitter<{ title: string }>();
  @Output() editGroup = new EventEmitter<{ edit: Boolean }>();
  @Output() back = new EventEmitter();

  messages = []
  loadingMessages = false
  messageForm
  currentUser
  id
  usersList = []
  isAdmin = false
  editModalOpen = false
  attachModalOpen = false
  editGroupForm: UntypedFormGroup
  step = 1
  attachFile = false
  binDocList = []
  selectedFilesForDisplay = []
  base64Image = undefined;
  attachmentFile = undefined;
  caption = ''
  loadingImages = false


  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  constructor(
    private messagingService: MessagingService,
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private userService: UserService,
    private mediaService: MediaService,


  ) {
  }

  ngOnInit() {

    console.log("Message")
    this.currentUser = this.authService.getGlobalUser();
    if (this.currentUser) {
      this.id = this.currentUser.uid

      if (this.currentUser.role == "Admin") {
        this.isAdmin = true;


      }
    }

    if (this.showHeader == undefined || this.showHeader){
      this.showHeader=true
    }

    this.messageForm = this.fb.group({
      attachFile: [null],
      message: ['', [Validators.required]]
    });



  }
  get message() { return this.messageForm.get('message') }

  scrollToBottom(): void {
    try {
        this.messagesContainer.nativeElement.scrollTop = this.messagesContainer.nativeElement.scrollHeight;
        this.messagesForm.nativeElement.scrollIntoView({behavior:"smooth"})
    } catch(err) {
      console.log(err)
     }                 
}

  ngOnChanges() {
    // if(this.sele)
    console.log("the change")
    if (this.chatSelected) {
      this.loadingMessages = true;


      this.messagingService.getMessageByChatId(this.selectedConv.id).valueChanges().subscribe(messages => {
        this.loadingMessages = false;

        if (messages) {
          console.log("what is this message", messages)
          this.messages = messages.sort((a, b) => (a as any).timeStamp.toDate() - (b as any).timeStamp.toDate());
          this.loadingMessages = false;

          // let users = this.chatType === 'employee' ? this.selectedConv.employees : this.selectedConv.users
          if (this.selectedConv.users && this.selectedConv.users.length > 0) {
            this.userService.getUserFromList(this.selectedConv.users).valueChanges().subscribe(x => {
              if (x && x.length > 0) {
                this.usersList = x
              }
            })
          }
        this.scrollToBottom();


          //get all users related to the message
        }
        else {
          this.messages = [];
          this.loadingMessages = false;
        }
      });
    } else {
      this.messages = [];
      this.loadingMessages = false;
    }
    // this.scrollToBottom();


  }

  backClicked() {
    this.back.emit(true)
  }

  onAttachFile() {
    this.attachModalOpen = true
    this.attachFile = true
    this.caption = this.message.value
    this.message.patchValue('')
  }

  onSelectFile(files: FileList) {

    let FileSize = files[0].size / 1024 / 1024; // in MiB
    if (FileSize > 300) {
      this.toast({ html: 'Selected file size exceeds 300 MiB.', classes: 'red', type: 'failure' });

      return;
    }


    let ext = this.validateInput(files[0].name)
    if (!ext) {
      // this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';
      // this.toast({ html: 'Selected file size exceeds 300 MiB.', classes: 'rounded' });
      return;
    }
    let attachment = { type: ext, name: files[0].name };

    if (ext == 'image') {
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        this.base64Image = reader.result as string;

        // let base64Image = reader.result as string;
        // img.imgBase64 = base64Image.replace(/^data:image\/[a-z]+;base64,/, '')

        attachment['link'] = this.base64Image.replace(/^data:image\/[a-z]+;base64,/, '')
        this.selectedFilesForDisplay.push(attachment);
        this.onAttachFile()


      };
    } else {
      attachment['link'] = files[0]
      this.selectedFilesForDisplay.push(attachment);
      this.onAttachFile()

      if (ext == 'video') {
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = () => {
          this.attachmentFile = reader.result as string;
        };
      }

    }

  }
  cancelAttach() {
    this.message.patchValue(this.caption)
    this.caption = ''
    this.closeAttachModal()

  }
  closeAttachModal() {
    this.attachModalOpen = false
    this.selectedFilesForDisplay = []
    this.base64Image = undefined
  }

  private validateInput(name: any) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (['png', 'jpg', 'jpeg'].includes(ext.toLowerCase())) {
      return 'image';
    } else if (ext.toLowerCase() === 'mp4' || ext.toLowerCase() === 'mkv') {
      return 'video';
    } else if (ext.toLowerCase() === 'pdf') {
      return 'pdf';
    } else {
      return false;
    }
  }

  sendAttachment() {
    this.message.patchValue(this.caption)
    this.caption = ''
    this.send(this.messageForm.value)
    this.closeAttachModal()

  }

  send(values) {

    if (this.selectedFilesForDisplay.length > 0) {
      this.uploadFiles(values)
    } else {
      if (!values.message || values.message == '') return
      values.attachment = { type: 'none', link: '', name: '' };

      this.sendMessage(values)
    }

  }


  uploadFiles(values) {
    values.attachment = this.selectedFilesForDisplay[0]
    // values.attachment
    if (values.attachment.type == 'image') {
      if (values.attachment.link) {
        this.loadingImages = true
        const img = new ImageModel();
        img.imgBase64 = values.attachment.link
        this.mediaService.uploadImage(img).subscribe(upload => {
          this.loadingImages = false

          if (upload) {

            values.attachment.link = upload.imgLink
            this.sendMessage(values)
          }
        }
        );
      }
    } else if (values.attachment.type == 'video') {
      if (values.attachment.link) {
        this.loadingImages = true

        this.mediaService.uploadVideo(values.attachment.link).subscribe(
          upload => {
            this.loadingImages = false

            if (upload) {
              values.attachment.link = upload.imgLink
              this.sendMessage(values)

            }
          }
        );
      }

    } else if (values.attachment.type == 'pdf') {
      if (values.attachment.link) {
        this.loadingImages = true

        this.mediaService.uploadPDF(values.attachment.link).subscribe(
          upload => {
            this.loadingImages = false


            if (upload) {
              values.attachment.link = upload.imgLink
              this.sendMessage(values)
            }
          }
        );
      }
    }
  }

  sendMessage(values) {
    if (this.chatType === 'group') {
      this.sendGroup.emit(values)
    } else if (this.chatType == 'private') {
      this.sendPrivate.emit(values)
    } else if (this.chatType === 'employee') {
      this.sendEmployee.emit(values)

    }
    this.selectedFilesForDisplay = []
    this.messageForm.reset();
  }
  onEditGroup() {
    // this.editModalOpen = true
    // this.editGroupForm.patchValue({
    //   name: this.selectedConv.name,
    //   description: this.selectedConv.description,
    //   profilePicture: this.selectedConv.profilePicture
    // })
    this.editGroup.emit({ edit: true })
  }

  closeEditModal() {
    this.editModalOpen = false
  }

  updateGroup(values) {
  }

  getMessageData(message) {
    let data = {
      "imgWidth": 14,
      "message": message.message,
      "timestamp": message.timeStamp,
      "attachment": message.attachment,
      "ownMessage": message.fromId == this.id ? true : false
    }
    if (['group', 'employee'].includes(this.chatType)) {
      return this.getGroupMessage(data,message)
      // if (this.usersList) {
      //   if this.isAdmin
      // let user = this.usersList.find(user => user.uid == message.fromId)
      //   data['img'] = user ? user.profileImgLink : '',
      //   data['text'] = user ? user.GivenName || user.FirstName : ''

    } else {
      // let user = this.selectedConv.user_1.id == message.fromId ? this.selectedConv.user_1 : this.selectedConv.user_2
      // // let user = this.selectedConv.user_1.id == this.id ? this.selectedConv.user_1 : this.selectedConv.user_2
      // data['img'] = user.profileImgLink || '',
      // data['text'] = user.GivenName || user.FirstName || ''
      return this.getPrivateMessage(data,message)
    }
    return data

  }

  getGroupMessage(data,message){
    // if (this.isAdmin && message.fromId === this.currentUser.uid){
    //   data['img'] = this.currentUser ? this.currentUser.profileImgLink : '',
    //   data['text'] = this.currentUser ? this.currentUser.GivenName || this.currentUser.FirstName : ''
    // }else{
      let user = this.usersList.find(user => user.uid === message.fromId)
      data['img'] = user ? user.profileImgLink : '',
      data['text'] = user ? user.GivenName || user.FirstName : ''
    // }

    return data

  }

  getPrivateMessage(data,message){
    let user = this.selectedConv.user_1.id == message.fromId ? this.selectedConv.user_1 : this.selectedConv.user_2
    // let user = this.selectedConv.user_1.id == this.id ? this.selectedConv.user_1 : this.selectedConv.user_2
    data['img'] = user.profileImgLink || '',
    data['text'] = user.GivenName || user.FirstName || ''
    return data

  }
  getEmployeeMessage(data,message){
    return data

  }

  getGroupData() {
    // let userCount = this.selectedConv.users ? this.selectedConv.users.length : this.selectedConv.employees.length

    return {
      "img": this.selectedConv.profilePicture || '',
      "imgWidth": 14,
      "text": this.selectedConv.name,
      "hasSecondaryText": true,
      "secondaryText": `${this.selectedConv.users.length} Members`
    }


  }
  getPrivateData() {
    // let user = this.selectedConv.user_2.id ===this.currentUser.uid ?
    return {
      "img": this.selectedConv.user_2.profileImgLink || '',
      "imgWidth": 14,
      "text": `${this.selectedConv.user_2.GivenName || this.selectedConv.user_2.FirstName} ${this.selectedConv.user_2.LastName}`,
      "hasSecondaryText": false,
    }
  }

  toast(obj) {
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green';
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
    }, 2000);
  }


}
