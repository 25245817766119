import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { AutomationService } from 'src/app/services/backend-automation/automation.service';

@Component({
  selector: 'app-update-firebase-rules',
  templateUrl: './update-firebase-rules.component.html',
  styleUrls: ['./update-firebase-rules.component.css']
})
export class UpdateFirebaseRulesComponent implements OnInit {

  firebaseRulesForm: UntypedFormGroup;


  constructor(private fb: UntypedFormBuilder,
    private automationService: AutomationService
    ) { }

  ngOnInit(): void {
    this.firebaseRulesForm = this.fb.group({
      collectionName: ['', [Validators.required,]],
      readAuth:[false],
      writeAuth:[false]

    })

  }
  get collectionName() { return this.firebaseRulesForm.get('collectionName'); }
  get readAuth() { return this.firebaseRulesForm.get('readAuth'); }
  get writeAuth() { return this.firebaseRulesForm.get('writeAuth'); }


  
  // private validateName(): ValidatorFn {
  //   return (control: AbstractControl): { [key: string]: any } => {
  //     let existingURL = [];
  //     this.alternativeUrl = []
  //     existingURL = this.existingRequests.filter(req => req.url === control.value);
  //     if (existingURL.length > 0) {

  //       this.alternativeUrl = this.generateRandomUrl(control.value)

  //       return { urlExists: true };
  //     }
  //     else {
  //       return null;
  //     }
  //   };
  // }

  updateRules(model){
    this.automationService.updateFirebaseRules(model).subscribe((res) => {
    }, (err) => {
    })
    

  }
}
