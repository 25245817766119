import { Component, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { CustomSectionService } from 'src/app/services/firebase/custom-section.service';
import { HelperService } from 'src/app/services/helper/helper';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from "src/app/services/firebase/auth.service";
import { PermissionService } from "src/app/services/firebase/permission.service";
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectAppSettings } from 'src/app/state/app.selectors';

@Component({
  selector: 'app-edit-custom-section',
  templateUrl: './edit-custom-section.component.html',
  styleUrls: ['./edit-custom-section.component.css']
})
export class EditCustomSectionComponent implements OnInit {


  editSectionForm: UntypedFormGroup;
  editModalOpen = false;
  cancelModalOpen = false;
  optionsList = [
    {
      optionName: '',
      originalOptionName: 'Title',
      isRequired: true,
      optionDescription: ''
    },
    {
      optionName: '',
      originalOptionName: 'Description',
      isRequired: false,
      optionDescription: ''
    },
    // {
    //   optionName: '',
    //   originalOptionName: 'Lists',
    //   isRequired: false,
    //   optionDescription: ''
    // },
    // {
    //   optionName: '',
    //   originalOptionName: 'Price',
    //   isRequired: false,
    //   optionDescription: ''
    // },
    // {
    //   optionName: '',
    //   originalOptionName: 'Start Time',
    //   isRequired: false,
    //   optionDescription: ''
    // },
    // {
    //   optionName: '',
    //   originalOptionName: 'Location',
    //   isRequired: false,
    //   optionDescription: ''
    // },
    // {
    //   optionName: '',
    //   originalOptionName: 'End Time',
    //   isRequired: false,
    //   optionDescription: ''
    // },
    {
      optionName: '',
      originalOptionName: 'Comment',
      isRequired: false,
      optionDescription: ''
    },
    {
      optionName: '',
      originalOptionName: 'Link',
      isRequired: false,
      optionDescription: ''
    },    {
      optionName: '',
      originalOptionName: 'Image Upload',
      isRequired: false,
      optionDescription: ''
    },
    {
      optionName: '',
      originalOptionName: 'Video Upload',
      isRequired: false,
      optionDescription: ''
    },
    {
      optionName: '',
      originalOptionName: 'PDF Upload',
      isRequired: false,
      optionDescription: ''
    },
  ];
  appSettings: any;
  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  sectionId: any;
  sectionDetail: any;
  optionNames = [];
  originalSectionName='';
  currentUser: any;
  permCustomPage: any;
  perm: any;
  haveAccessPermission = false;
  appSettings$: Subscription;
  constructor(private fb: FormBuilder,private route: ActivatedRoute, 
    public appSettingsService: AppSettingsService,
    private customSectionService: CustomSectionService, 
    private helperService: HelperService,
    private location: Location,
    private authService: AuthService,
    private permissionService: PermissionService,
    private store: Store) { 
      this.appSettings$ = this.store.select(selectAppSettings).subscribe((settings) => {
        this.appSettings = settings;
      });
    }

  ngOnInit(): void {
    this.currentUser = this.authService.getGlobalUser();
    if(this.currentUser){
      if(this.currentUser.role === 'Employee'){
        this.permissionService
        .getPermissionByEmployeeId(this.currentUser.uid)
        .valueChanges()
        .subscribe((perm: any) => {
          if (perm.length > 0) {
            this.perm = perm[0];
            this.permCustomPage = this.perm.permission[6];
            if (this.permCustomPage.editPage) {
              this.haveAccessPermission = true;
            }
          }
        });
      }
      if(this.currentUser.role === 'Admin'){
        this.haveAccessPermission = true;
      }
    }
    this.route.paramMap.subscribe(params => {
      this.sectionId = params.get('id');
    });
    this.editSectionForm = this.fb.group({
      sectionTitle: ['', Validators.required],
      sectionDesign: [''],
      options: this.fb.array([], [Validators.required]),
      addToMenu: [false], 
      enableInLandingPage: [true]
    });
    this.customSectionService.getCustomSectionById(this.sectionId).valueChanges().subscribe(section =>{
      if(section){
        this.sectionDetail = section[0];
        this.originalSectionName = this.sectionDetail.sectionTitle;
        let previousOptions = [...this.sectionDetail.options];
        this.sectionDetail.options = [];
        const checkArray: UntypedFormArray = this.editSectionForm.get('options') as UntypedFormArray;
        previousOptions.forEach(sec => {
          this.optionNames.push(sec.originalOptionName);
          this.sectionDetail.options.push(sec.originalOptionName);
          checkArray.push(new UntypedFormControl(sec));

        })
    

        this.editSectionForm.patchValue({
          sectionTitle: this.sectionDetail.sectionTitle,
          sectionDesign: this.sectionDetail.sectionDesign,
          // options: this.sectio`nDetail.options,
          addToMenu: this.sectionDetail.addToMenu ? this.sectionDetail.addToMenu : false, 
          enableInLandingPage: this.sectionDetail.enableInLandingPage  ? this.sectionDetail.enableInLandingPage : false
        })
      }
    });

  }

  get sectionTitle(){ return this.editSectionForm.get('sectionTitle'); }
  get sectionDesign(){ return this.editSectionForm.get('sectionDesign'); }
  get options() { return this.editSectionForm.get('options'); }
  get enableInLandingPage() { return this.editSectionForm.get('enableInLandingPage'); }
  
  updateSection(value){
    this.sectionDetail.sectionTitle = value.sectionTitle,
    this.sectionDetail.sectionDesign = value.sectionDesign,
    this.sectionDetail.options = value.options,
    this.sectionDetail.addToMenu = value.addToMenu, 
    this.sectionDetail.enableInLandingPage = value.enableInLandingPage
  
    this.editModalOpen = false;
    value.sectionTitle = this.helperService.getInnerText(value.sectionTitle);
    if(this.appSettings){
      this.customSectionService.updateCustomSection(this.sectionDetail).then(() => {
        this.appSettings.appSections.forEach(element => {
          if(element.originalName == this.sectionDetail.originalName){
            element.name = value.sectionTitle
          }
        });
        this.appSettingsService.updateAppSettings(this.appSettings).then(()=>{
          this.toast({html: 'Section Detailes Updated successfully!', classes: 'green', type: 'success'});
        })
      }).catch(err => {
        this.toast({html: err.message, classes: 'red'});
      });
    }
  }

  onCheckboxChange(e: any) {
    let option ={};
    const checkArray: UntypedFormArray = this.editSectionForm.get('options') as UntypedFormArray;
    if (e.target.checked) {
      option = this.optionsList.filter(opt => opt.originalOptionName === e.target.value);
      checkArray.push(new UntypedFormControl(option[0]));
    } else {
      let i: number = 0;
      this.sectionDetail.options = this.sectionDetail.options.filter(optName => {
        if (optName !==  e.target.value){
          return optName;
        }
      });

      checkArray.controls.forEach((item: any) => {
        if (item.value.originalOptionName == e.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
      this.backClicked();
    }, 2000);
  }
  
  closeCreateModal(){
    this.editModalOpen = false;
  }
  
  closeCancelModal(){
    this.cancelModalOpen = false;
  }
  
  backClicked(){
    this.location.back();
  }
}
