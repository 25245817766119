import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { AuthService } from 'src/app/services/firebase/auth.service';

@Component({
  selector: 'app-service-product',
  templateUrl: './service-product.component.html',
  styleUrls: ['./service-product.component.css']
})
export class ServiceProductComponent implements OnInit {

  currentUser:any;
  settings: any;
  productsRoute
  serviceRoute
  constructor(private appSettingsService : AppSettingsService,
              private authService: AuthService,
              private router: Router) { }

  ngOnInit(): void {

    this.currentUser = this.authService.getGlobalUser();

    this.appSettingsService.getAppSettingsList().valueChanges().subscribe((settings) => {
      if (settings.length > 0){
        this.settings = settings[0];
        if(this.settings.isBusinessApp){
          this.productsRoute = this.settings.hasProductsCategory ? '../../user/product-catagory' : '../../user/view-products'
          this.serviceRoute = this.settings.hasServicesCategory ? '../../user/service-category' : '../../user/services'
          //this.serviceRoute = 'user/services'

          if(this.settings.serviceType != 'Both'){
              this.navigate()
          }
        }
      }
    });
  }
  navigate(){
    let route
    if(this.settings.serviceType == 'Service'){
      if (this.settings.hasServicesCategory){
        route = 'user/service-category' //was user/services
      }else{
        route = 'user/services' //was user/services

      }
    }else{
      if (this.settings.hasProductsCategory){
        route = 'user/product-catagory'
      }else{
        route = 'user/view-products'

      }
    }
    this.router.navigate([route])

  }
  clicked(){
  }


  }


