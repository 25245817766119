<app-firebase-loading *ngIf="loading"></app-firebase-loading>

<div class="px-5" *ngIf="!loading">
  <!-- Top buttons -->
  <div  class="flex flex-col md:flex-row-reverse mb-6 justify-between">
    <div class="flex py-2">
      <button class="btn-custom-solid float-right" [routerLink]="['/employee/schedule']">
        <i class="material-icons w-2 h-2 pr-8 float-left">event</i>Schedule
      </button>
      <button *ngIf="appointmentsList" class="btn-custom-bordered ml-3 md:ml-5 float-right" (click)="toggleView()">
        <i class="material-icons w-2 h-2 pr-8 float-left">visibility</i>
        {{isCalendarView ? 'List View' : 'Calender View'}}
      </button>

    </div>
  </div>

  <!-- No appointments found -->
  <div *ngIf="!appointmentsList">
    <div class="flex items-center justify-center">
      <img loading="lazy" data-src="../../../../assets/icons/Illustrations/Appointment-Illustration.png"
          class="block w-64 flex items-center justify-center">
  </div>
  No appointments found!
  </div>

  <!-- Appointment List -->
  <div *ngIf="appointmentsList">
    <!-- Calendar View -->
    <div *ngIf="isCalendarView">
      <div>

        <div class="row col s12">
          <div class="text-center">
            <span class="text-center text-gray-700 text-lg font-bold">Click on booked time slots to display booking detail.</span>
          </div>
          <div class="flex flex-row my-3 ">
            <div class="md:w-2/6 w-3/12 flex flex-col">
              <div class="mb-2">Booked Appointment</div>
              <div class="w-1/2 segment booked-segment"> </div>
            </div>
            <!-- <div class="md:w-1/6 w-1/12"></div>
            <div class="md:w-2/6 w-3/12 flex flex-col">
              <div class="mb-2">No Appointment</div>
              <div class="w-1/2 segment unavailable-segment"> </div>
            </div> -->

          </div>

        <div class="flex justify-center text-customTeal font-bold text-lg">
          <mwl-calendar-header
          [(view)]="view"
          [(viewDate)]="viewDate"
          class="">
        </mwl-calendar-header>

        </div>

          <div class="mb-10">
            <div [ngSwitch]="view">
              <mwl-calendar-week-view
                *ngSwitchCase="CalendarView.Week"
                [viewDate]="viewDate"
                [events]="events"
                [weekStartsOn]="[weekStartsOn]"
                [refresh]="refresh"
                (eventClicked)="eventClicked($event)"
                (eventTimesChanged)="eventTimesChanged($event)"
                [hourSegments]="4"
                [dayStartHour]="9"
                [dayEndHour]="17">
              </mwl-calendar-week-view>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- List View -->
    <div *ngIf="!isCalendarView">
      <!-- List View Header -->
      <div class=" flex flex-col space-y-2 mx-2 lg:mx-10">
        <div class=" flex flex-row items-center  mt-2 p-2 md:space-x-6">
          <div class="w-5/12 hidden lg:block">
              Name
          </div>
          <div class="w-2/12 hidden lg:block">
              <p class=" text-sm">Date</p>
          </div>
          <div class="w-4/12 hidden lg:block">
              <p class=" text-sm">Message </p>
          </div>
          <div class="w-1/12 hidden lg:block">

          </div>

        </div>
      </div>

      <!-- Appointment List -->
      <div class="flex flex-col space-y-2 mx-2 lg:mx-10">
        <div class=" flex flex-row bg-white items-center  mt-2 p-2 rounded-md   border-2 md:space-x-6"
        *ngFor="let appt of appointmentsList | paginate: { itemsPerPage: 10,
          currentPage: page}; let i= index ">
          <div class="w-5/12 flex">
            <div class="flex items-center space-x-3">
              <div class="break-words">{{truncateHTML(appt.serviceName, 40)}}</div>
            </div>
          </div>
          <div class="w-2/12">
            <p class=" text-sm">{{(appt.startTime.toDate()) | date }}</p>
          </div>
          <div *ngIf="appt.message" class="w-4/12">
            <p class=" text-sm">{{truncateHTML(appt.message, 50)}}</p>
          </div>

          <div *ngIf="!appt.message" class="w-4/12 ">
            <p class=" text-sm">--</p>
          </div>
          <div class="w-1/12 cursor-pointer" [routerLink]="['/employee/appointment-detail', appt.id]">
            <i class="material-icons">arrow_circle_right</i>
          </div>

        </div>
        <br />
        <div>
            <pagination-controls class="text-right mr-20" (pageChange)="page = $event"></pagination-controls>
        </div>
      </div>

    </div>
  </div>

</div>

<!-- Appointment Details modal -->
<div id="appointmentDetailsModal" class="modal">
  <div class="modal-content row">
    <div class="row">
      <div class="center">
        <span>Appointment Details</span>
      </div>
      <div>
        <div *ngIf="appointmentMadeBy">
          <p>
            <strong>Appointment made by:</strong>
            <span *ngIf="appointmentMadeBy.GivenName">{{appointmentMadeBy.GivenName}}</span>
            <span *ngIf="appointmentMadeBy.LastName">{{appointmentMadeBy.LastName}}</span>
          </p>
          <p  *ngIf="appointmentMadeBy.Email">
            <strong>Email: </strong>
            <span>{{appointmentMadeBy.Email}}</span>
          </p>

        </div>
        <div *ngIf="selectedService">
          <p *ngIf="selectedService.serviceName">
            <strong>Service: </strong>
            <span>{{selectedService.serviceName}}</span>
          </p>
          <p  *ngIf="selectedService.serviceTime">
            <strong>Time: </strong>
            <span>{{selectedService.serviceTime}}</span>
          </p>
          <p *ngIf="selectedService.serviceDescription">
            <strong>Description: </strong>
            <span>{{selectedService.serviceDescription}}</span>
          </p>

        </div>
      </div>
      <br />
    </div>

    <button type="button" class="col s12 blue waves-effect btn"
      style="margin-bottom: 15px;" (click)="closeModal()" >
      <i class="material-icons right">add_circle_outline</i> Close
    </button>
  </div>

  <div class="modal-footer">
    <div class="row" style="padding: 0 5%;"></div>
  </div>
</div>

<!--Delete user confirmation modal-->
<div id="my-modal-users" *ngIf="deleteModalOpen" class="modal"
  [ngClass]="{'modal-open': deleteModalOpen, 'modal-action': !deleteModalOpen }">
  <div class="modal-box">
    <div class="modal-content">
      <h3 class="font-bold text-lg text-center mb-1">Delete Appointment</h3>
      <hr />
      <h3 class="font-bold text-md text-center mt-2">Are you sure you want to Delete this Appointment?</h3>
      <p class="py-4 text-center">Users who will be notified of deletion with the reason provided.</p>
      <form [formGroup]="deleteAppointmentForm" (ngSubmit)="deleteAppointment(deleteAppointmentForm.value)">
        <p class="font-bold">Reason <span class="text-red-500">*</span></p>
        <textarea id="reason" name="reason" type="text"
          formControlName="reason"
          class="border rounded py-1 w-full h-24 resize-y text-gray-700  bg-veryLightGray"
          [ngClass]="{
          'border-red-500': (reason.dirty || reason.touched) && reason.invalid,
          'border-gray-200': !((reason.dirty || reason.touched) && reason.invalid)
        }"></textarea>
        <div *ngIf="(reason.dirty || reason.touched) && reason.invalid">
          <small *ngIf="reason.errors.required" class="text-error italic"> Reason is required. </small>
        </div>
        <div class="modal-action items-center justify-between mx-6">
          <button (click)="closeDeleteModal()"
            class="font-bold text-customTeal border-none">Cancel</button>
          <button
            class="btn-custom-solid-red" type="submit" [disabled] = "!deleteAppointmentForm.valid"
            [ngClass]="{'bg-[#cccccc] text-[#666666] border-gray-300': !deleteAppointmentForm.valid}">Delete</button>
        </div>
      </form>
    </div>
  </div>
</div>

<div id="my-modal-taskDetail" class="modal" *ngIf="taskDetail"
  [ngClass]="{'modal-open': taskDetail, 'modal-action': !taskDetail }">

  <div class="modal-box max-w-3xl">
    <div class="modal-content">
      <div (click)="closeTaskDetail()"
      class="flex justify-end "><i class="material-icons text-white font-bold px-4 py-4 flex justify-end bg-gray-500 rounded-full">close</i></div>

      <h3 class="font-bold text-xl text-center text-customTeal my-2"> Task Detail </h3>
       <p class="text-lg text-center">Task assign by the Admin</p>
      <hr />

      <div class="flex mx-16 justify-between">
        <p><span class="flex flex-col"><p class="font-bold mt-2">Start-Time</p> <p>{{selectedTask.reminderTime}}</p></span></p>
        
        <p><span class="flex flex-col"><p class="font-bold mt-2">End-Time</p><p>{{selectedTask.endReminderTime}}</p></span></p>
      </div>

      <p class="text-md text-center my-2"> <span class="font-bold text-lg">Task:-</span> {{selectedTask.taskDescription}}</p>

    </div>
  </div>

</div>

