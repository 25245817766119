import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { UserService } from 'src/app/services/firebase/user.service';
import { Router } from '@angular/router';
import { InviteService } from 'src/app/services/firebase/invite.service';
import { EmailService } from 'src/app/services/api/email.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { SMSService } from 'src/app/services/api/sms.service';
import { PermissionService } from 'src/app/services/firebase/permission.service';
import { CustomAdminEmailModel } from 'src/app/models/emailModel';
import { SMSModel } from 'src/app/models/SMSModel';
import { FCMTokenService } from 'src/app/services/firebase/FCMToken.service';
import { NotificationModel } from 'src/app/models/Messages.Model';
import { HelperService } from 'src/app/services/helper/helper';
import { FCMPushNotificationsService } from 'src/app/services/firebase/FCMPushNotifications.service';
import { Store } from '@ngrx/store';
import { selectAppSettings } from 'src/app/state/app.selectors';

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrl: './employees.component.css'
})
export class EmployeesComponent implements OnInit {
  @Input() employeeOrDepartment: string;
  @Input() showInvitedEmployeesList: boolean;
  @Output() closeCollapse = new EventEmitter<string>();

  selectedModel: any;
  themeColor = environment.appTheme.adminThemeColor;
  showMsg = false;
  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  isUpdating = false;
  // modal
  deleteModalOpen = false;
  allUsersByRole: any = [];
  invitedEmployees: any = [];
  totalEmpLength: any;
  totalInvitesLength: any;
  showInvitedEmployees = false;
  search_text: any;
  isEmployee = false;
  email: any;
  userType: any;
  currentUser: any;
  host = window.location.hostname !== 'localhost' ? 'https://' + window.location.hostname + '/register-from-invite/' : 'http://localhost:4200/register-from-invite/';
  inviteAdminForm: UntypedFormGroup;
  employees: any;
  selectAllcheckedEmp = false;
  selectedEmployees = [];
  currentEmployee: any;
  isMobileCheckedEmp = false;
  beforeFilterEmp = [];
  checkEmployee = false;
  contactEmployeeForm: UntypedFormGroup;
  isSMSemp = false;
  Message: any;
  disable = false;
  // modals
  employeeInviteModalOpen = false;
  contactEmployeeModal = false;
  storeEmail = [];
  inviteGlobal: any;
  role: any;
  isAdmin = false;
  perm: any;
  permission: any;
  model: any;
  greetings = '';
  signOff = '';
  message: any;
  deptOrEmp: any;


  updateModalOpen: any;
  permMember: any;
  permSocial: any;
  permPES: any;
  permSpecial: any;
  permAnalytics: any;
  admins = [];

  //this are the arrays used to filter Email/SMS/Both/ and Verified Users
  contact: any;
  SMSPreference = [];
  emailPreference = [];
  bothPreference = [];
  verifiedPreference = [];

  sentSMSCount: number = 0;
  adminCounter: any;
  adminSMSCounter: number = 0;
  adminEmailCounter: number = 0;

  userCounter: any;
  userSMSCounter: number = 0;
  userEmailCounter: number = 0;

  counterGlobal: any;
  counterGlobalSec: any;
  counterUpdate: any;

  currentDate = new Date();
  isLimitSMS: boolean = false;
  isLimitEmail: boolean = false;
  isLimitPushNotification: boolean = false;
  isPushNotification: boolean = false;
  notificationType = 'sms'
  availablePhone: any;
  isNationApp = environment.firstNation.Origin == "FNLeaders" ? true : false;
  emailInviteAgain : String = '';
  appSettings$: any;

  constructor(
    private location: Location,
    private userService: UserService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private inviteService: InviteService,
    private emailService: EmailService,
    private authService: AuthService,
    private sMSService: SMSService,
    private permissionService: PermissionService,
    private fcmService: FCMTokenService,
    private helperService: HelperService,
    private fcmPushService: FCMPushNotificationsService,
    private store: Store
  ) { 
    this.appSettings$ = this.store.select(selectAppSettings).subscribe((settings) => { 
      this.model = this.helperService.cloneDeep(settings);          
      this.greetings = this.model.greetings ? this.model.greetings : '';
      this.signOff = this.model.signOff ? this.model.signOff : '';
      this.email = this.greetings + ' \n \n \n \n' + this.signOff; // this will properly display the greetings and signoff on the textarea that comes from the admin
      this.message = '\n \n' + this.model.messageSMS ? this.model.messageSMS : '';
      this.availablePhone = this.model.availablePhone ? this.model.availablePhone: '';

      
    });
  }

  ngOnInit(): void {

    this.currentUser = this.authService.getGlobalUser();
   

    if (this.currentUser) {
      this.role = this.currentUser.role;
      if (this.role === 'Admin') {
        this.isAdmin = true;
      }

      this.permissionService.getPermissionByEmployeeId(this.currentUser.uid).valueChanges().subscribe(perm => {
        if (perm && perm.length !== 0) {
          this.perm = perm[0];
          //this.permission = this.perm.permission[0];

          [this.permMember, this.permPES, this.permAnalytics, this.permSocial, this.permSpecial] = this.perm.permission;
        }
      });
    }

    this.inviteAdminForm = this.fb.group({
      adminInviteEmails: this.fb.array([this.fb.control('', [Validators.required, Validators.email])]),
      firstName: [''],
      lastName: [''],
      jobTitle: ['', [Validators.required]],
    });


    this.contactEmployeeForm = this.fb.group({
      message: [''],
      subject: [''],
      emailbody: [''],
      contact: ['Email'],
    });
    this.contactEmployeeForm.patchValue({
      emailbody: this.email,
      message: this.message
    });
    this.showInvitedEmployees = this.showInvitedEmployeesList ? this.showInvitedEmployeesList : this.showInvitedEmployees;

    if (!this.showInvitedEmployees) {
      this.fetchEmployees();
    }
    else{
      this.onFilterInvitedEmployees();
    }


  }
  backClicked() {
    this.location.back();
  }

  toast(obj) {
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green';
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
    }, 2000);
  }

  addAdminInviteEmail() {
    this.adminInviteEmails.push(this.fb.control('', Validators.required));
  }

  sendInvite(formValue: any): void {
    let allInviteEmails: any;
    if (formValue.inviteEmails) {
      this.userType = 'User';
      allInviteEmails = formValue.inviteEmails;
    }
    else if (formValue.adminInviteEmails) {
      this.userType = 'Employee';
      allInviteEmails = formValue.adminInviteEmails;
    }

    if (allInviteEmails) {
      if (allInviteEmails.length > 0) {
        allInviteEmails.forEach(inviteEmail => {
          // check if user exists
          const timestamp = new Date();
          this.closeEmpModal();
          this.userService.getUserByEmail(inviteEmail).valueChanges().subscribe(user => {
            if (user) {
              if (user.length === 0) {
                this.inviteService.getInviteByEmail(inviteEmail).valueChanges().subscribe(invite => {
                  if (invite) {
                    if (invite.length === 0) {
                      this.inviteService.saveInvite({
                        inviteEmail, timestamp, userType: this.userType,
                        firstName: formValue.firstName, lastName: formValue.lastName,
                        jobTitle: formValue.jobTitle
                      });
                    }
                  }
                });

              } else {
                this.toast({ html: 'This User exists', classes: 'red', type: 'failure' });
              }
            }
          });

        });
      }
    }

    let hostname;
    let newUserInviteEmail;
    let appName;
    let FirstName;
    let LastName;
    let jobtitle;
    // allInviteEmails.forEach(email => {
    this.inviteService.getInviteByEmail(allInviteEmails[0]).valueChanges().subscribe((invite: any) => {
      if (invite.length === 1) {
        this.inviteGlobal = invite;
        hostname = `${this.host}${this.inviteGlobal[0].id}`;
        newUserInviteEmail = allInviteEmails[0];
        appName = this.currentUser.GivenName ? this.currentUser.GivenName : '';
        FirstName = this.inviteGlobal[0].firstName;
        LastName = this.inviteGlobal[0].lastName;
        jobtitle = this.inviteGlobal[0].jobTitle;

        if (this.inviteGlobal[0].userType === 'Employee') {
          this.emailService.sendEmployeeInvite({
            hostname, inviteEmail: newUserInviteEmail, appName,
            host: this.host, id: this.inviteGlobal[0].id, firstName: FirstName, lastName: LastName, jobTitle: jobtitle
          }, this.currentUser);
          this.toast({ html: 'Invitation email sent Successfully', classes: 'green', type: 'success' });

        }
        else {
          this.emailService.sendUserInvite({
            hostname, inviteEmail: newUserInviteEmail, appName, host: this.host, id: this.inviteGlobal[0].id
          }, this.currentUser);

          this.toast({ html: 'Invitation email sent Successfully', classes: 'green', type: 'success' });
        }
      }

    });
    // });
    // toast({html: 'User invitation link sent', classes: 'green'});
    this.inviteAdminForm.reset();
  }

  selectEmployee(i) {
    this.currentEmployee = i;
  }

  // suspendEmployee() {
  //   this.allUsersByRole[this.currentEmployee].status = 'Disabled'
  //   this.userService.updateUser(this.allUsersByRole[this.currentEmployee]).then(res => {
  //   });
  // }

  // restoreEmployee() {
  //   this.allUsersByRole[this.currentEmployee].status = 'Enabled';
  //   this.userService.updateUser(this.allUsersByRole[this.currentEmployee]).then(() => {
  //   });
  // }

  // Added
  viewEmployeeProfile(userModel: any) {
    if (this.isAdmin) {
      this.router.navigate(['/admin/employee-detail/', userModel.uid]);
    } else {
      this.router.navigate(['/employee/employee-detail/', userModel.uid]);
    }

  }

  openEmpInvite() {
    if (this.isAdmin) {
      this.router.navigate(['/admin/employee-form/']);
    } else {
      this.router.navigate(['/employee/employee-form/']);
    }
    // this.employeeInviteModalOpen = true;
  }

  openContactEmployee() {
    this.contactEmployeeModal = true;
  }

  closeContactEmployee() {
    this.contactEmployeeModal = false;
  }

  closeEmpModal() {
    this.employeeInviteModalOpen = false;
    this.inviteAdminForm.reset();
  }

  SMSselectedEmp(type) {
    if (type === "sms") {
      this.notificationType = 'sms'

    }
    else if (type === "email") {
      this.notificationType = 'email'

    }
    else {
      this.notificationType = 'push'

    }
    this.isSMSemp = !this.isSMSemp;
  }

  selectedAllEmployee() {
    this.selectAllcheckedEmp = !this.selectAllcheckedEmp;

    // merge the admin and employees array
    let mergedUsers = [...this.allUsersByRole, ...this.admins];

    if (this.selectAllcheckedEmp) {

      this.disable = false;

      // iterate over the selected all users for generating tokens
      for (let i = 0; i < mergedUsers?.length; i++) {
        if (!this.selectedEmployees.includes(mergedUsers[i])) {
          // check cellphone number
          if (mergedUsers[i].cellPhone === "") {
            this.disable = true;
          }
          this.selectedEmployees.push(mergedUsers[i])
        }
      }
      // this.selectedEmployees = this.allUsersByRole;
      this.checkEmployee = true;
    }
    else {
      this.selectedEmployees = [];
      this.checkEmployee = false;
    }
    // after grabbing selected subscribers , check if they have registation token or not
    if (this.selectedEmployees?.length) {
      this.fetchRegistrationTokens('multiple', null);
    }
  }

  selectedEmployee(item) {

    // merge the admin and employees array
    let mergedUsers = [...this.allUsersByRole, ...this.admins];

    if (!this.selectedEmployees.includes(item)) {
      this.selectedEmployees.push(item);
      if (this.selectedEmployees.length === mergedUsers?.length) {
        this.selectAllcheckedEmp = true;
      }
      else {
        this.selectAllcheckedEmp = false;
      }

      // fetch tokens
      this.fetchRegistrationTokens('single', this.selectedEmployees.length - 1)
    }
  }

  // iterate over the selected users and fetch associated registration tokens
  fetchRegistrationTokens(type, index) {
    // merge the admin and employees array
    let mergedUsers = [...this.allUsersByRole, ...this.admins];
    if (type == 'multiple') {
      for (let i = 0; i < mergedUsers?.length; i++) {
        let user = mergedUsers[i];
        // fetch fcm topics so that we see, this.an asssociate them with the subscribers  
        this.fcmService.getTokenByUserId(user?.uid).valueChanges().pipe().subscribe(tokens => {
          if (tokens && tokens.length > 0) {

            this.selectedEmployees[i].token = tokens;

          }
        });
      }
    }
    else {
      // fetch fcm topics so that we c, this.an asssociate them with the subscribers  
      this.fcmService.getTokenByUserId(this.selectedEmployees[index]?.uid).valueChanges().pipe().subscribe(tokens => {
        if (tokens && tokens.length > 0) {
          this.selectedEmployees[index].token = tokens;
        }
      });
    }
  }

  onMobileEmployeeFilter() {
    this.isMobileCheckedEmp = !this.isMobileCheckedEmp;
    if (this.beforeFilterEmp.length === 0) {
      this.beforeFilterEmp = [...this.allUsersByRole];
    }
    this.allUsersByRole = this.beforeFilterEmp;

    if (this.isMobileCheckedEmp) {
      this.allUsersByRole = this.allUsersByRole.filter((employees) => {
        return (employees.cellPhone !== '');
      });
    }
  }

  onFilterInvitedEmployees() {
    // this.toggleInviteBoolean();

    this.showInvitedEmployees = true;
    this.allUsersByRole = [];

    // get invited users
    this.userService.getInvitedUsers().valueChanges().subscribe(invites => {
      if (invites.length > 0) {
        this.invitedEmployees = invites;
        this.totalInvitesLength = invites.length;
      }
    });

  }

  inviteAgain(email){
    this.emailInviteAgain = email;
    this.inviteService.getInviteByEmail(email).valueChanges().subscribe(invite => {
      if (invite && invite.length > 0) {
          let hostname;
          let email;
          let appName;
          let FirstName
          let LastName
          let jobtitle
          let extension
          let cellPhone
          let workPhone
          let appointment
          let permission

          this.inviteGlobal = invite;
          hostname = `${this.host}${this.inviteGlobal[0].id}`;
          email = email;
          appName = this.currentUser.GivenName ? this.currentUser.GivenName : '';
          FirstName = this.inviteGlobal[0].firstName
          LastName = this.inviteGlobal[0].lastName
          jobtitle = this.inviteGlobal[0].jobTitle
          extension = this.inviteGlobal[0].extension
          cellPhone = this.inviteGlobal[0].cellPhone
          workPhone = this.inviteGlobal[0].workPhone
          appointment = this.inviteGlobal[0].appointment
          permission = this.inviteGlobal[0].permission

          this.emailService.sendEmployeeInvite({
            hostname, inviteEmail: this.emailInviteAgain, appName, host: this.host, id: this.inviteGlobal[0].id, firstName : FirstName, lastName : LastName, jobTitle: jobtitle,
            cellPhone:cellPhone, workPhone:workPhone, appointment:appointment, extension, 
          },this.currentUser);
          this.toast({ html: 'Invitation email sent Successfully', classes: 'green', type: 'success' });

      }
    });

  }

  // get employees list
  fetchEmployees() {
    // this.toggleInviteBoolean();
    this.showInvitedEmployees = false;
    this.invitedEmployees = [];

    this.userService.getUserByRole('Employee').valueChanges().subscribe(employees => {
      if (employees.length > 0) {
        this.allUsersByRole = employees;
        this.employees = employees;
        this.totalEmpLength = employees.length;
        for (let i = 0; i < this.totalEmpLength; i++) {
          if (this.allUsersByRole[i].role === 'Employee') {
            this.isEmployee = true;
          }
        }
      }
    });
    this.userService.getUserByRole('Admin').valueChanges().subscribe(admins => {
      if (admins.length > 0) {
        this.admins = admins;
      }
    });

  }

  clearFilters() {
    this.beforeFilterEmp = [];
    this.isMobileCheckedEmp = false;
    this.allUsersByRole = this.employees;
  }

  async sendEmployees(subject, body, message) {
    if (this.notificationType == 'email') {
      const emailModel = new CustomAdminEmailModel();
      emailModel.subject = subject;
      emailModel.isNationApp = this.isNationApp;
      emailModel.body = body;

      emailModel.body += '<br/> <br/> <br/> <span style="color:blue; margin-top: 10px;"> </span> ' + this.currentUser.GivenName + " " + this.currentUser.LastName + ' <br/> ';
      if (this.currentUser.jobTitle) {
        emailModel.body += '<span style="color:blue;"> </span> ' + this.currentUser.jobTitle + ' <br/> ';
      }
      emailModel.body += '<span style="color:blue;"> </span> ' + this.currentUser.Email + ' <br/> ';
      emailModel.body += '<span style="color:blue;"> </span> ' + this.currentUser.cellPhone + ' <br/> ';
      this.storeEmail = [];

      for (let i = 0; i < this.selectedEmployees.length; i++) {
        this.storeEmail.push(this.selectedEmployees[i].Email);
      }

      emailModel.emailList = this.storeEmail;

      this.closeContactEmployee();
      // custom send email used
      emailModel.host = "smtp.gmail.com",
      emailModel.adminEmail = "mkproject.app@gmail.com",
      emailModel.appPassword = "ytklyidomvqfmtky",
      // this.emailService.sendCustomEmail(emailModel).subscribe((res) => {
      //   if (res) {
      //     this.toast({ html: "Email sent successfully!", classes: 'green', type: 'success' });
      //   }
      //   else {
      //     this.toast({ html: 'Error sending Email', classes: 'red', type: 'error' });
      //   }
      // });

      this.emailService.sendEmail(emailModel).subscribe((res) => {
        if (res) {
          this.toast({ html: "Email sent successfully!", classes: 'green', type: 'success' });
        }
        else {
          this.toast({ html: 'Error sending Email', classes: 'red', type: 'error' });
        }
      });
    }
    else if (this.notificationType == 'push') {
      let storePushNotification = [];

      const notification = new NotificationModel();
      notification.title = subject;
      notification.body = this.truncateHTML(body, "200");

      for (let i = 0; i < this.selectedEmployees.length; i++) {
        storePushNotification.push(this.selectedEmployees[i].uid);
      }

      this.closeContactEmployee();

      if (storePushNotification?.length > 0) {

        await this.fcmPushService.sendNotificationToUsers(notification, storePushNotification);
        this.toast({ html: 'The Push Notfication is successfully sent', classes: 'green', type: "success" });
      }
      else {
        this.toast({ html: 'The selected users do not want to recieve in app notification', classes: 'green', type: "success" });
      }

    }
    else if (this.notificationType == 'sms') {
      const smsModel = new SMSModel();
      smsModel.body = message;
      smsModel.from = this.availablePhone;
      for (let i = 0; i < this.selectedEmployees.length; i++) {
        if (this.selectedEmployees[i].cellPhone) {
          smsModel.phoneNumberList.push(this.selectedEmployees[i].cellPhone)
        }
      }
      this.closeContactEmployee();
      this.sMSService.sendSMS(smsModel).subscribe((res) => {
        if (res) {
          this.toast({ html: "SMS sent successfully!", classes: 'green', type: 'success' });
        }
        else {
          this.toast({ html: 'Error sending SMS', classes: 'red', type: 'error' });
        }
      });
    }
  }

  // truncate html
  truncateHTML(text: string, limit: string): string {
    return this.helperService.truncateHTML(text, limit);
  }

  reset() {
    this.contactEmployeeForm.patchValue({
      subject: '',
      emailbody: this.greetings + ' <br /> <br />  <br /> <br />' + this.signOff,
      messageSMS: this.model.messageSMS,
    });
  }

  get subject() { return this.contactEmployeeForm.get('subject'); }
  get messageSMS() { return this.contactEmployeeForm.get('message'); }
  get emailbody() { return this.contactEmployeeForm.get('emailbody'); }

  get adminInviteEmails() { return this.inviteAdminForm.get('adminInviteEmails') as UntypedFormArray; }
  get firstName() { return this.inviteAdminForm.get('firstName'); }
  get lastName() { return this.inviteAdminForm.get('lastName'); }
  get jobTitle() { return this.inviteAdminForm.get('jobTitle'); }

}
