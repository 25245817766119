
import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation, Injectable, ChangeDetectorRef, TemplateRef } from '@angular/core';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { UserService } from 'src/app/services/firebase/user.service';
import { Location, formatDate} from '@angular/common';
import { ScheduleService } from 'src/app/services/firebase/employee-schedule.service';
import { ActivatedRoute } from '@angular/router';
import { AppointmentService } from 'src/app/services/firebase/employee-appointment.service';
import { EmployeeServicesService } from 'src/app/services/firebase/employee-services.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { EmployeeAppointmentModel } from 'src/app/models/AppointmentModel';
import { isAfter, isBefore, addMinutes, max, maxTime } from 'date-fns';
import { CalendarEvent, CalendarView,
  DAYS_OF_WEEK, CalendarDateFormatter, DateFormatterParams, CalendarWeekViewBeforeRenderEvent, CalendarMonthViewDay
} from 'angular-calendar';
import {
  subMonths, addMonths, addDays, addWeeks, subDays, subWeeks, startOfMonth,
  endOfMonth, startOfWeek, endOfWeek, startOfDay, endOfDay,
} from 'date-fns';
import { EmailService } from 'src/app/services/api/email.service';
import { SMSService } from 'src/app/services/api/sms.service';
import { SMSModel } from 'src/app/models/SMSModel';
import { environment } from 'src/environments/environment';
import { AppSettingsComponent } from '../../admin/settings/app-settings/app-settings.component';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { EmailModel, CustomAdminEmailModel } from 'src/app/models/emailModel';
import { Store } from '@ngrx/store';
import { selectAppSettings } from 'src/app/state/app.selectors';
import { HelperService } from 'src/app/services/helper/helper';

@Injectable()
export class CustomDateFormatter extends CalendarDateFormatter {
  public weekViewHour({ date, locale }: DateFormatterParams): string {
    return formatDate(date, 'h:mm a', locale);
  }
  public weekViewColumnHeader({ date, locale }: DateFormatterParams): string {
    return formatDate(date, 'EEE', locale);
  }
}

type CalendarPeriod = 'day' | 'week' | 'month';
function addPeriod(period: CalendarPeriod, date: Date, amount: number): Date {
  return {
    day: addDays,
    week: addWeeks,
    month: addMonths,
  }[period](date, amount);
}

function subPeriod(period: CalendarPeriod, date: Date, amount: number): Date {
  return {
    day: subDays,
    week: subWeeks,
    month: subMonths,
  }[period](date, amount);
}

function startOfPeriod(period: CalendarPeriod, date: Date): Date {
  return {
    day: startOfDay,
    week: startOfWeek,
    month: startOfMonth,
  }[period](date);
}

function endOfPeriod(period: CalendarPeriod, date: Date): Date {
  return {
    day: endOfDay,
    week: endOfWeek,
    month: endOfMonth,
  }[period](date);
}
@Component({
  selector: 'app-appointment-reschedule',
templateUrl: './appointment-reschedule.component.html',
styleUrls: ['./appointment-reschedule.component.css'],
changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      .cal-week-view .cal-time-events .cal-day-column {
        margin-right: 10px;
      }

      .cal-week-view .cal-hour {
        width: calc(100% + 10px);
      }
      .cal-disabled {
      background-color: #eee;
      pointer-events: none;
      }
      .cal-disabled .cal-day-number {
        opacity: 0.1;
      }
    `,
  ],
  providers: [{
  provide: CalendarDateFormatter,
  useClass: CustomDateFormatter,
  }]
})

export class AppointmentRescheduleComponent implements OnInit {

  loggedUser: any;
  // loading = false;
  employeeId: any;
  employeeSchedule: any;
  excludeDays: any;
  appointmentsList: any;
  serviceId: any;
  service: any;
  selectedServiceTime: any;
  allEmployees = [];
  selectedEmployee: any;
  schedule: any;
  view: CalendarView = CalendarView.Week;
  CalendarView = CalendarView;
  events: CalendarEvent[] = [];
  viewDate: Date = new Date();
  refresh = new Subject<void>();
  weekStartsOn = DAYS_OF_WEEK.MONDAY;
  minDate: Date = subDays(new Date(), 1);
  maxDate: Date = addMonths(new Date(), 1);
  employeeServices: any = [];
  rescheduleAppointmentForm: UntypedFormGroup;
  selectedService = { serviceName: 'Click to select a service' };
  // selectedServiceTime: any;
  unAvailableTimeSlot: any;
  unAvailableTimes = [];
  scheduleModalOpen = false;
  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  serviceDuration = 15;
  selectedServiceEndTime: any;
  prevBtnDisabled = false;
  nextBtnDisabled = false;
  // headerTemplate: TemplateRef<any>;
  minStartingTime = 9;
  maxEndingTime = 17;
  appointmentId: any;
  appointment: any;
  
  domain = environment.firstNation.name;
  // domainName = "https://"+this.domain +".goingmobile.app"
  isNationApp = environment.firstNation.Origin == "FNLeaders" ? true : false;

  domainName = this.isNationApp ? "https://" + this.domain + ".mynation.app" : "https://" + this.domain + ".goingmobile.app";
  
  adminsEmail = [];
  adminsPhone = [];
  createModalOpen = false;
  appSettings: any;
  availablePhone: any;

  appSettings$: Subscription;
  constructor(
    private location: Location,
    private authService: AuthService,
    private scheduleService: ScheduleService,
    private appointmentService: AppointmentService,
    private route: ActivatedRoute,
    private empService: EmployeeServicesService,
    private fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private userService: UserService,
    private emailService: EmailService,
    private SMSservice: SMSService,
    private appSettingsService: AppSettingsService,
    private store: Store,
    private helperService: HelperService,
  ) {
    this.appSettings$ = this.store.select(selectAppSettings).subscribe((settings) => {
      this.appSettings = this.helperService.cloneDeep(settings);
      this.availablePhone = this.appSettings.availablePhone ? this.appSettings.availablePhone : '';
    
    });
    this.loggedUser = this.authService.getGlobalUser();
    this.getService();
    this.dateOrViewChanged();
    this.refresh.next();
  }

  ngOnInit(): void {
    this.rescheduleAppointmentForm = this.fb.group({
      // appointmentDate: [, [Validators.required]],
      message: [],
      startTime: [, [Validators.required]],
      endTime: [, [Validators.required]]

    });
    this.refresh.next();
    this.userService.getUserByRole("Admin").valueChanges().subscribe((admins: any) =>{
      if(admins && admins.length){
        for(let i = 0; i < admins.length; i++){
          this.adminsEmail.push(admins[i].Email);
          if(admins[i].cellPhone != "" || admins[i].cellPhone != undefined){
          this.adminsPhone.push(admins[i].cellPhone)
          }
        }
      }

    })
  }

  get message() { return this.rescheduleAppointmentForm.get('message'); }
  get startTime() { return this.rescheduleAppointmentForm.get('startTime'); }
  get endTime() { return this.rescheduleAppointmentForm.get('endTime'); }

  openServiceDropdown() {

  }
  serviceSelected(service) {
    this.selectedService = service;
    const endTime = this.getEndTIme(this.startTime.value, service.serviceTime);
    const unAvaiableTimes = this.checkDateForAppointment(this.selectedServiceTime, endTime);
    const day = this.employeeSchedule.schedule.filter((day, index) => index === endTime.getDay());
    const dayEndTime = day[0].endTime.split(':');
    let [dayEndTimeHour,dayEndTimeMinute] = [parseInt(dayEndTime[0]) ,parseInt(dayEndTime[1])];

    const dayy = new Date(endTime);
    dayy.setHours(dayEndTimeHour, dayEndTimeMinute, 0, 0);

    if (isAfter(endTime, dayy)){
      this.unAvailableTimeSlot = true;
      this.endTime.patchValue('');
    }
    else if (unAvaiableTimes.length > 0) {
      this.unAvailableTimeSlot = true;
      this.endTime.patchValue('');

    } else {
      this.unAvailableTimeSlot = false;
      this.endTime.patchValue(endTime);
    }
    this.service.patchValue(service.serviceName);
  }
  getEndTIme(date, minutes) {
    return new Date(date.getTime() + minutes * 60000);
  }

  createEvent(appointment) {
    const data = {
      start: appointment.startTime.toDate(),
      end: appointment.endTime.toDate(),
      title: `${appointment.serviceName}`,
      color: {
        primary: '#d0d4d7',
        secondary: '#d0d4d7'
      },
      // title: `${appointment.service}: ${appointment.endTime.toDate().toLocaleTimeString('en-US')} -
      // ${appointment.endTime.toDate().toLocaleTimeString('it-IT')}`
    };
    return data;
  }

  eventClicked({ event }: { event: CalendarEvent }): void {


  }

  hourSegmentClicked(event): void {
    const date = event.date;

    if (this.unAvailableTimes.includes(date)){
      // this.toast({ html: 'Employee not available at that time', classes: 'red', type: 'failure' });
      return;
    }
    const today = new Date();
    // today.setHours(0, 0, 0, 0)
    if (isBefore(date, today)){
      // this.toast({ html: 'Can\'t create appointment for past date!', classes: 'red', type: 'failure' });
      return;
    }


    const scheduleExists = this.appointmentsList.filter(a => date.getTime() >= (a.startTime.toDate().getTime() - 1000)
      && date.getTime() + 1000 <= a.endTime.toDate().getTime());

    if (scheduleExists.length <= 0) {
      this.selectedServiceTime = event.date;
      this.startTime.patchValue(this.selectedServiceTime);
      this.selectedServiceEndTime = addMinutes(this.selectedServiceTime, this.serviceDuration);
      this.endTime.patchValue(this.selectedServiceEndTime);

      this.scheduleModalOpen = false;
    } else {
      // this.toast({ html: 'Appointment already exists', classes: 'red', type: 'failure' });


    }

  }

  dateOutsideSchedule(date){
    const day = this.employeeSchedule.schedule.filter((day, index) => index === date.getDay());
    const startTime = day[0].startTime.split(':');
    let [scheduleHour,scheduleMinute] = [parseInt(startTime[0]) ,parseInt(startTime[1])];

    if (date.getHours() < scheduleHour){
      return true;
    }else if ((date.getHours() === scheduleHour) && date.getMinutes() < scheduleMinute){
      return true;
    }
    return false;

  }


  checkDateForAppointment(startTime, endTime) {
    const l = this.appointmentsList.filter(a => endTime.getDay() === a.startTime.toDate().getDay());
    const unBookableTimes = l.filter(a => isAfter(a.startTime.toDate().getTime(), startTime.getTime())
      && isBefore(a.startTime.toDate().getTime(), endTime.getTime()));

    return unBookableTimes;

  }

  beforeViewRender(body: CalendarWeekViewBeforeRenderEvent): void {
    body.hourColumns.forEach(hourCol => {
      if (this.employeeSchedule) {
        if (this.employeeSchedule.schedule.length > 0) {

          hourCol.hours.forEach(hour => {

            hour.segments.forEach(segment => {
              if (isBefore(segment.date, new Date())){
              this.unAvailableTimes.push(segment.date);
              segment.cssClass = 'unavailable';
            }
            }
          );

          });
        }}});

    body.hourColumns.forEach(hourCol => {

      if (this.employeeSchedule) {
        if (this.employeeSchedule.schedule.length > 0) {

          const day = this.employeeSchedule.schedule.filter(
            (day, index) =>
              {
                return index === hourCol.date.getDay() && !day.unAvailable;
              });
          const unavailableDay = this.employeeSchedule.schedule.filter(
            (day, index) =>
              {  return index === hourCol.date.getDay() && day.unAvailable;

              }
          );
          // unavailable day
          if (unavailableDay.length > 0) {
            hourCol.hours.forEach(hour => {

              hour.segments.forEach(segment => {
                  this.unAvailableTimes.push(segment.date);
                  segment.cssClass = 'unavailable';
              });
            });
          }
          // available slot
          if (day.length > 0){
              let startTime = day[0].startTime.split(':');
              let [scheduleHour,scheduleMinute] = [parseInt(startTime[0]) ,parseInt(startTime[1])];

              let endTime = day[0].endTime.split(':');
              let [endScheduleHour,endScheduleMinute] = [parseInt(endTime[0]) ,parseInt(endTime[1])];

              hourCol.hours.forEach(hour => {

                hour.segments.forEach(segment => {
                  if (segment.date.getHours() < scheduleHour){
                    this.unAvailableTimes.push(segment.date);
                    segment.cssClass = 'unavailable';

                  }
                  else if ((segment.date.getHours() === scheduleHour) && (segment.date.getMinutes() < scheduleMinute)){
                    this.unAvailableTimes.push(segment.date);

                    segment.cssClass = 'unavailable';

                  }

                  if (segment.date.getHours() > endScheduleHour){
                    this.unAvailableTimes.push(segment.date);
                    segment.cssClass = 'unavailable';

                  }
                  else if ((segment.date.getHours() === endScheduleHour) && (segment.date.getMinutes() >= endScheduleMinute)){
                    this.unAvailableTimes.push(segment.date);

                    segment.cssClass = 'unavailable';

                  }
                });
              });





          }

        }
      }


    });
  }

  getService(){
    this.route.paramMap.subscribe(params => {
      this.appointmentId = params.get('id');
    });
    if (this.appointmentId){
      this.appointmentService.getAppointmentById(this.appointmentId).valueChanges().subscribe((appointment: any) => {
        if (appointment){
          if (appointment.length > 0 &&
            (this.loggedUser.role === 'Admin' ||
            this.loggedUser.uid === appointment[0].employeeId ||
            this.loggedUser.uid === appointment[0].userId )){
            this.appointment = appointment[0];
            if (this.appointment){
              this.empService.getServiceById(this.appointment.serviceId).valueChanges().subscribe(service => {
                if (service.length > 0){
                  this.service = service[0];
                  this.serviceDuration = (this.service.serviceHour * 60) + this.service.serviceMinute;
                  this.userService.getUserById(this.appointment.employeeId).valueChanges().subscribe(employee => {
                    if (employee.length > 0 ){
                      this.allEmployees.push(employee[0]);
                      this.selectedEmployee = employee[0];
                      if (this.selectedEmployee){
                        const startTimes = []; const endTimes = [];
                        let end: number;

                        this.scheduleService.getScheduleByEmployeeId(this.selectedEmployee.uid).valueChanges().subscribe(s => {
                          if (s && s.length > 0) {
                            // this.loading = false;
                            this.employeeSchedule = s[0];
                            this.employeeSchedule.schedule.forEach(sched => {
                              if (sched.startTime){
                                startTimes.push(sched.startTime);
                              }
                              if (sched.endTime){
                                endTimes.push(sched.endTime);
                              }
                            });
                            end = parseInt(this.sortTime(endTimes));
                            this.maxEndingTime =  end < 24 ? end + 1 : end; // adding 1 hour max time if it is bleow 24 hours
                            this.minStartingTime =  parseInt(this.sortTimeAscending(startTimes)) ;

                            this.cdr.detectChanges();
                            // this.refresh.next();
                            this.excludeDays = this.employeeSchedule.schedule.map((day, index) => {
                              if (day.unAvailable !== true) { return false; }
                              return index;
                            }).filter(day => day !== false);

                            this.appointmentService.getAppointmentByEmployeeId(this.selectedEmployee.uid).valueChanges().subscribe(a => {
                              if (a.length > 0) {
                                const today = new Date();
                                today.setHours(0, 0, 0, 0);

                                this.appointmentsList = a;
                                // this.appointmentsList = this.appointmentsList.filter(a => a.startTime.toDate() >= today);
                                this.recreateEventsList();
                              } else {
                                this.appointmentsList = [];
                              }
                            });
                            this.events = [...this.events];

                            // employee services
                            this.empService.getServicesWithTime().valueChanges().subscribe((services: any) => {
                              if (services.length > 0) {
                                services.forEach(service => {
                                  if (service.assignedTo.includes(this.employeeId)){
                                    this.employeeServices.push(service);
                                  }
                                });
                                // this.employeeServices = services;
                              }
                            });
                            this.cdr.detectChanges();
                          }
                          // this.loading = false;
                        });
                      }
                      this.cdr.detectChanges();
                    }});
                  // get assigned employee
                  // if (this.service.assignedTo && this.service.assignedTo.length > 0){
                  //   this.service.assignedTo.forEach(empId => {
                  //     this.userService.getUserById(empId).valueChanges().subscribe(employee => {
                  //       if (employee.length > 0 ){
                  //         this.allEmployees.push(employee[0]);
                  //         this.cdr.detectChanges();
                  //       }
                  //     });
                  //   });

                  //   this.cdr.detectChanges();
                  // } else {
                  //   // appoint to the owner/admin if no employee
                  //   this.userService.getAllAdmins().valueChanges().subscribe((admins: any) => {
                  //     if (admins.length > 0){
                  //       this.allEmployees = [];
                  //       admins.forEach(admin => {
                  //         this.userService.getUserById(admin.uid).valueChanges().subscribe(employee => {
                  //           if (employee.length > 0){

                  //             this.allEmployees.push(employee[0]);
                  //             this.cdr.detectChanges();
                  //           }
                  //         });
                  //       });

                  //       this.cdr.detectChanges();
                  //     }
                  //   });
                  // }

                  this.cdr.detectChanges();
                }
              });

            }

          }
        }
      });
    }

  }

  recreateEventsList() {
    this.events = [];
    this.appointmentsList.forEach(appointment => {
      const event = this.createEvent(appointment);
      this.events.push(event);
    });
    this.cdr.detectChanges();
  }

  closeModal() {
    this.message.patchValue('');
    this.startTime.patchValue('');
    this.endTime.patchValue('');
    setTimeout(() => {
      // $('.modal').modal('close');
      // $('select').formSelect();
      // $('.dropdown-trigger').dropdown();
    }, 50);
    // this.selectedService=

  }

  rescheduleAppointment(value) {
    const newAppointment = {id: '',
                            startTime: '',
                            endTime: '',
                            rescheduled: false,
                            rescheduleReason: '',
                            rescheduledBy: ''
                          };
    // new EmployeeAppointmentModel();
    // newAppointment.employeeId = this.selectedEmployee.uid;
    newAppointment.id = this.appointment.id;
    newAppointment.startTime = this.selectedServiceTime;
    newAppointment.endTime = this.selectedServiceEndTime;
    // newAppointment.serviceId = this.service.id;
    // newAppointment.serviceName = this.service.serviceName;
    newAppointment.rescheduled = true;
    newAppointment.rescheduleReason = value.message;
    newAppointment.rescheduledBy = this.loggedUser.uid;

    this.appointmentService.updateAppointment(newAppointment).then(appointment => {
            
      const emailModel = new CustomAdminEmailModel();
      emailModel.subject = `Appointment Reschedule`;
      emailModel.isNationApp = this.isNationApp;

      emailModel.body = `\n An appointment has been rescheduled by ${this.loggedUser.GivenName} ${this.loggedUser.LastName} to ${new Date(newAppointment.startTime)}`;
      emailModel.emailList = this.adminsEmail;
      // custom send email used
      emailModel.host = "smtp.gmail.com",
      emailModel.adminEmail = "mkproject.app@gmail.com",
      emailModel.appPassword = "ytklyidomvqfmtky",
      // this.emailService.sendCustomEmail(emailModel).subscribe((res) => {
      //   if (res) {
      //   }
      //   else {
      //   }
      // })

      this.emailService.sendEmail(emailModel).subscribe((res) => {
        if (res) {
        }
        else {
        }
      })

      const smsModel = new SMSModel();
      smsModel.body = `Appointment Reschedule \nAn appointment has been rescheduled by ${this.loggedUser.GivenName} ${this.loggedUser.LastName} to ${new Date(newAppointment.startTime)}`;

      if (environment.firstNation.name !== "nationalchief" && this.appSettings.general === 'existingNumber') {
        smsModel.body += `\nTo stop receiving emails please login to ${this.domainName} \n`
        smsModel.body += `Go to your preferences and disable Email, SMS or Both. \n`
      }else if(this.appSettings.general === 'buyNum' && this.appSettings.customizeUnsubscribe){
        smsModel.body += this.appSettings.unSubscribe;
      }

     /* if (environment.firstNation.name !== "nationalchief") {
        smsModel.body += `\nTo stop receiving emails please login to ${this.domainName} \n`
        smsModel.body += `Go to your preferences and disable Email, SMS or Both. \n`
      }*/
      
      // smsModel.phoneNumberList = this.adminsPhone;
      smsModel.phoneNumberList = ['7808411880']
      smsModel.from = this.availablePhone;
      
      this.SMSservice.sendSMS(smsModel).subscribe((res) => {
        if (res) {
          this.toast({ html: 'SMS Message sent Successfully', classes: 'green', type: 'success' });
        }
        else {
          this.toast({ html: 'Error occured sending SMS', classes: 'red', type: 'failure' });
        }
      });

      this.toast({ html: 'Appointment Successfully Rescheduled!', classes: 'green', type: 'success', redirect: true});

    }).catch(err => {
      this.toast({ html: 'Error updating Appointment', classes: 'red', type: 'failure', redirect: true });
    });
  }

  backClicked() {
    // this.location.back();
    this.scheduleModalOpen = false;
  }

  selectEmployee(id){
    const startTimes = []; const endTimes = [];
    let end: number;
    this.userService.getUserById(id).valueChanges().subscribe(employee => {
      if (employee.length > 0 ){
        this.selectedEmployee = employee[0];

        this.scheduleService.getScheduleByEmployeeId(this.selectedEmployee.uid).valueChanges().subscribe(s => {
          if (s && s.length > 0) {
            // this.loading = false;
            this.employeeSchedule = s[0];
            this.employeeSchedule.schedule.forEach(sched => {
              if (sched.startTime){
                startTimes.push(sched.startTime);
              }
              if (sched.endTime){
                endTimes.push(sched.endTime);
              }
            });
            end = parseInt(this.sortTime(endTimes));
            this.maxEndingTime =  end < 24 ? end + 1 : end; // adding 1 hour max time if it is bleow 24 hours
            this.minStartingTime =  parseInt(this.sortTimeAscending(startTimes)) ;

            this.cdr.detectChanges();
            // this.refresh.next();
            this.excludeDays = this.employeeSchedule.schedule.map((day, index) => {
              if (day.unAvailable !== true) { return false; }
              return index;
            }).filter(day => day !== false);

            this.appointmentService.getAppointmentByEmployeeId(this.selectedEmployee.uid).valueChanges().subscribe(a => {
              if (a.length > 0) {
                const today = new Date();
                today.setHours(0, 0, 0, 0);

                this.appointmentsList = a;
                // this.appointmentsList = this.appointmentsList.filter(a => a.startTime.toDate() >= today);
                this.recreateEventsList();
              } else {
                this.appointmentsList = [];
              }
            });
            this.events = [...this.events];

            // employee services
            this.empService.getServicesWithTime().valueChanges().subscribe((services: any) => {
              if (services.length > 0) {
                services.forEach(service => {
                  if (service.assignedTo.includes(this.employeeId)){
                    this.employeeServices.push(service);
                  }
                });
                // this.employeeServices = services;
              }
            });
            this.cdr.detectChanges();
          }
          // this.loading = false;
        });
      }
    });
  }
sortTime(arr){
    return arr.reduce((a, b) => a <= b ? b : a );
}
sortTimeAscending(arr){
  return arr.reduce((a, b) => a >= b ? b : a );
}
  chooseDateAndTimeClicked(){
    this.scheduleService.getScheduleByEmployeeId(this.selectedEmployee.uid).valueChanges().subscribe(s => {
      if (s && s.length > 0) {
        this.employeeSchedule = s[0];
        this.excludeDays = this.employeeSchedule.schedule.map((day, index) => {
          if (day.unAvailable !== true) { return false; }
          return index;
        }).filter(day => day !== false);


        this.appointmentService.getAppointmentByEmployeeId(this.selectedEmployee.uid).valueChanges().subscribe(a => {
          if (a.length > 0) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            this.appointmentsList = a;
          } else {
            this.appointmentsList = [];
          }
        });

      }
    });


    this.scheduleModalOpen = true;
  }

  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
      if (obj.redirect){
        this.location.back();
      }
    }, 2000);
  }
  increment(): void {
    this.changeDate(addPeriod(this.view, this.viewDate, 1));
  }

  decrement(): void {
    this.changeDate(subPeriod(this.view, this.viewDate, 1));
  }

  today(): void {
    this.changeDate(new Date());
  }

  dateIsValid(date: Date): boolean {
    return date >= this.minDate && date <= this.maxDate;
  }

  changeDate(date: Date): void {
    this.viewDate = date;
    this.dateOrViewChanged();
  }

  dateOrViewChanged(): void {
    this.prevBtnDisabled = !this.dateIsValid(
      endOfPeriod(this.view, subPeriod(this.view, this.viewDate, 1))
    );
    this.nextBtnDisabled = !this.dateIsValid(
      startOfPeriod(this.view, addPeriod(this.view, this.viewDate, 1))
    );
    if (this.viewDate < this.minDate) {
      this.changeDate(this.minDate);
    } else if (this.viewDate > this.maxDate) {
      this.changeDate(this.maxDate);
    }
  }

  beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {
    body.forEach((day) => {
      if (!this.dateIsValid(day.date)) {
        day.cssClass = 'cal-disabled';
      }
    });
  }
  closeCreateModal(){
    this.createModalOpen = false;
  }
  }
