import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from "@angular/fire/compat/firestore";
import { NotificationsService } from './notifications.service';
@Injectable({
  providedIn: 'root'
})
export class AppointmentService {

  constructor(private db: AngularFirestore) { }
  createEmployeeAppointment(model: any){
    const newContent = {
      id: this.db.createId(),
      employeeId: model.employeeId,
      userId: model.userId,
      // appointmentDate: model.appointmentDate,
      timeStamp: new Date(),
      endTime: model.endTime,
      startTime: model.startTime,
      serviceName: model.serviceName,
      serviceId: model.serviceId,
      message: model.message,
      rescheduled: false,
      rescheduleReason: '',
      userName : model.userName,
      employeeName : model.employeeName,
      GivenName: model.givenName ? model.givenName : '',
      LastName: model.lastName ? model.lastName : '',
      Email: model.email ? model.email : '',
      cellPhone: model.phoneNumber ? model.phoneNumber : '',
      bookedBy: model.bookedBy ? model.bookedBy : ''
    };
    return this.db.collection('EmployeeAppointment').doc(newContent.id).set(newContent)
      .then((docRef: any) => {
        // After saving appointment, save the notification
        const notification = {
          message: `Appointment created for ${newContent.GivenName} at ${newContent.timeStamp}`,
          timestamp: new Date(),
          appointmentId: newContent.id,
          timeStamp: new Date(),
          owner: newContent.employeeId,
          type: 'Appointment',
          refrenceId: newContent.id,
          createdById: newContent.bookedBy,
          createdByFullName: newContent.GivenName + ' ' + newContent.LastName
        
        };
        this.addNotification(notification); // Save notification
      });
    
  }
  addNotification(model){
    const newContent = {
      id: this.db.createId(),
      owner: model.owner,
      type: model.type,
      timeStamp: model.timeStamp,
      appointmentId: model.appointmentId? model.appointmentId : '',
      refrenceId: model.refrenceId,
      createdById: model.createdById,
      createdByFullName: model.createdByFullName,
      isSeen: false
    };
    
    return this.db.collection('Notifications').doc(newContent.id).set(newContent);
  }
  createAppointmentSetting(model: any){
    const newContent = {
      id: this.db.createId(),
      canReschedule: model.canReschedule,
      sendEmailReminder: model.sendEmailReminder,
      sendSMSReminder: model.sendSMSReminder,
      sendEmailConfirmation: model.sendEmailConfirmation,
      sendSMSConfirmation: model.sendSMSConfirmation,
      canCancel: model.canCancel,
      canCancelBeforeHr: model.canCancelBeforeHr,
      sendReminderBeforeHr: model.sendReminderBeforeHr,
      canRescheduleBeforeHr: model.canRescheduleBeforeHr,
      cancelOption: model.cancelOption,
      appointmentBgTaskColor: model.appointmentBgTaskColor,
      appointmentBgNoteColor: model.appointmentBgNoteColor,
      appointmentBgApptColor: model.appointmentBgApptColor,
      appointmentTextColor: model.appointmentTextColor,
      sendReminderNTABeforeHr: model.sendReminderNTABeforeHr,
      sendNTAEmailReminder: model.sendNTAEmailReminder,
      sendNTASMSReminder: model.sendNTASMSReminder,  
      appointmentFontSize: model.appointmentFontSize ? model.appointmentFontSize : '',

      
    };
    return this.db.collection('AppointmentSettings').doc(newContent.id).set(newContent);
  }
  getAppointmentSetting(){
    return this.db.collection('AppointmentSettings');
  }
  updateAppointmentSetting(model: any){
    return this.db.collection('AppointmentSettings').doc(model.id).update(model);
  }
  getAppointmentByEmployeeId(employeeId){
    return this.db.collection('EmployeeAppointment', ref => ref.where('employeeId', '==', employeeId));
  }

  getAppointmentByUserId(userId){
    return this.db.collection('EmployeeAppointment', ref => ref.where('userId', '==', userId));
  }

  getAllAppointments(){
    return this.db.collection('EmployeeAppointment');
  }

  getAppointmentOfEmployeeByDate(employeeId){
    const startOfDay = new Date();
    startOfDay.setHours(0, 0, 0, 0);
    return this.db.collection('EmployeeAppointment', ref => ref.where('employeeId', '==', employeeId).where('startTime','>=',startOfDay));
  }

  updateAppointment(model: any){
    return this.db.collection('EmployeeAppointment').doc(model.id).update(model);
  }
  getAppointmentById(appointmentId){
    return this.db.collection('EmployeeAppointment', ref => ref.where('id', '==', appointmentId));
  }

  deleteAppointment(model: any){
    return this.db.collection('EmployeeAppointment').doc(model.id).delete();
  }

}
