import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { Location} from '@angular/common';
@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.css']
})
export class BreadcrumbsComponent implements OnInit {

  @Input() selectedElementType: any;
  @Input() elementName: any;
  @Input() pageType: any;
  currentUser: any;
  constructor(private authService: AuthService,
    private router: Router,
    private location: Location) { }

  ngOnInit(): void {
    this.currentUser = this.authService.getGlobalUser();
  }

  routeToHomepage(){
    if(this.currentUser){
      if(this.currentUser.role=='Admin'){
        this.router.navigate(['/admin/dashboard'])
      }
      if(this.currentUser.role=='Employee'){
        this.router.navigate(['/employee/dashboard'])
      }
      if(this.currentUser.role=='User'){
        this.router.navigate(['/user/homepage'])
      }

    }

  }
  backClicked(){
    this.location.back();
  }

}
