<div class="w-full min-h-screen pt-6 font-body max-h-max">
    <div
        class="flex flex-col p-4 mt-2 space-y-4 bg-white rounded-lg m-4 md:m-0 md:w-4/6 h-max md:p-12 md:translate-x-1/4">
        <div class="mt-4 text-xl text-center font-bold text-customTeal md:text-left  md:mt-0">Customize customer form
        </div>

        <p class="flex items-center gap-2 text-xs text-customTeal">
            <i class="text-sm text-white rounded-full bg-customTeal material-icons h-fit">add</i>
            If you have not specified the field name to be used during the import of contacts, this icon will be
            displayed
        </p>

        <div class="flex flex-row items-end justify-end w-full gap-2">
            <button (click)="openFieldAddModal()"
                class="flex items-center space-x-2 rounded bg-customTeal px-2 md:px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    class="w-4 h-4 stroke-white">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                </svg>
                <span>Add Field</span>
            </button>
        </div>


        <div class="flex p-4 mt-2 w-full">
            <div class="flex my-3 mb-8 space-x-10 w-full">
                <div class="grid w-full grid-cols-1 md:grid-cols-2 gap-3 px-4 md:px-0">
                    <div class="flex md:w-full" *ngFor="let field of baseFields; let i= index"
                    [ngClass]="{'flex-row-reverse justify-end items-center gap-1' : field.type === 'checkbox', 'flex-col space-y-2' : field.type != 'checkbox', 'col-span-2': field.fullWidth}">
                        <label class="flex items-center gap-1 text-xs font-bold text-gray-500">{{field?.name}}
                            <span class="text-red-500 " *ngIf="field?.required">*</span>
                            <button type="button" *ngIf="field?.active" (click)="openEditPage(field)">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="currentColor" class="w-4 h-4 stroke-customTeal">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                                </svg>
                            </button>

                            <!-- delete -->
                            <button *ngIf="field?.active && !field?.default"
                                (click)="showDeleteConfirmationModal(field)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-trash2-fill" viewBox="0 0 16 16">
                                    <path
                                        d="M2.037 3.225A.7.7 0 0 1 2 3c0-1.105 2.686-2 6-2s6 .895 6 2a.7.7 0 0 1-.037.225l-1.684 10.104A2 2 0 0 1 10.305 15H5.694a2 2 0 0 1-1.973-1.671zm9.89-.69C10.966 2.214 9.578 2 8 2c-1.58 0-2.968.215-3.926.534-.477.16-.795.327-.975.466.18.14.498.307.975.466C5.032 3.786 6.42 4 8 4s2.967-.215 3.926-.534c.477-.16.795-.327.975-.466-.18-.14-.498-.307-.975-.466z" />
                                </svg>
                            </button>

                        </label>
                        <select *ngIf="field.type === 'dropdown'"
                            class="w-full px-3 py-3 leading-tight text-gray-700 border rounded focus:outline-gray-200 focus:shadow-outline text-[13px]">
                            <option *ngFor="let option of field.options" [value]="option">{{ option?.option }}
                            </option>
                        </select>
                        <textarea *ngIf="field.type == 'textarea'"
                            class="w-full px-3 py-3 leading-tight col-span-2 text-gray-700 border rounded focus:outline-gray-200 focus:shadow-outline text-[13px]"
                             [placeholder]="field?.type"></textarea>

                        <input  *ngIf="field.type != 'dropdown' && field.type != 'textarea'"
                            class="px-3 py-3 leading-tight text-gray-700 border rounded focus:outline-gray-200 focus:shadow-outline text-[13px]"
                            [type]="field?.type" [placeholder]="field?.type">
                    </div>
                </div>
            </div>
        </div>
        <hr />



    </div>
</div>

<!-- show confirmation modal -->
<div class="modal modal-open" *ngIf="showDeleteModal">
    <div class="modal-box">
        <h3 class="mb-2 text-xl font-bold text-center">Delete Confirmation</h3>

        <!-- Modal Body -->
        <div class="modal-body" >
            <p class="gap-3 mb-1 text-sm font-bold text-center font">Are you certain that you wish to proceed with deleting this item? 
                Please note that this action cannot be reversed.</p>
           
        </div>
        <div class="items-center justify-between modal-action">
            <label (click)="closeDeleteConfirmationModal()"
                class="p-2 font-bold text-customTeal hover:border-customTeal hover:rounded-md hover:font-normal hover:border-2 hover:p-2">
                Cancel
            </label>
            <label>
                <button class="px-4 py-2 text-white rounded-md bg-customTeal border-customTeal" type="button"
                    (click)="deleteField()">Continue</button>
            </label>
        </div>
    </div>
</div>