import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private accountUser= new BehaviorSubject('')

  constructor() { }

  currentAccount=this.accountUser.asObservable();
  
  get account() {
    return this.accountUser.value;
  }
  updateProfileKey(accountUser){
    this.accountUser.next(accountUser)
}

}
