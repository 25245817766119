export class PrivateConversationsModel {
    id : '';
    dateCreated : Date;
    user_1: {};
    user_2: {};
    lastMessage: {};
    // unreadCount: 0;
  }
export class GroupConversationsModel {
    id : '';
    dateCreated : Date;
    name : '';
    description : '';
    users: any[];
    usersInfo: any[];
    isPrivate: false;
    profilePicture: '';
    lastMessage: {};
    admin: '';
    previousMembers:[];
    unreadCount: any[];
}

export class EmployeeConversationsModel {
  id : '';
  dateCreated : Date;
  name : '';
  description : '';
  users: any[];
  profilePicture: '';
  lastMessage: {};
  admin: '';
  previousMembers:[];
  unreadCount: any[];
  usersInfo: any[];
  // unreadCount: 0;
}