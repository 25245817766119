<div  class="flex  md:py-0 flex-col-reverse md:flex-row items-center mx-auto mt-6 space-y-0 md:space-y-0 md:pl-20 md:pr-0 md:mb-16">
    <div *ngIf="socialMediaModel" class="flex md:flex-col gap-y-4 py-10 md:py-0 space-x-4 md:space-x-0  md:px-10 ">
      <a class="cursor-pointer " *ngIf="socialMediaModel.facebook"
          [href]="socialMediaModel.facebook" target="_blank"><i
              class="fa fa-facebook prefix  text-xl mr-3 hover:text-customTeal hover:animate-pulse"></i>
      </a>
      <a class="cursor-pointer" *ngIf="socialMediaModel.twitter"
          [href]="socialMediaModel.twitter" target="_blank"><i
              class="fa fa-twitter prefix  text-xl mr-3 hover:text-customTeal hover:animate-pulse"></i>
      </a>
      <a class="cursor-pointer" *ngIf="socialMediaModel.instagram"
          [href]="socialMediaModel.instagram" target="_blank"><i
              class="fa fa-instagram prefix text-xl  mr-3 hover:text-customTeal hover:animate-pulse"></i>
      </a>
      <a class="cursor-pointer" *ngIf="socialMediaModel.youtube"
          [href]="socialMediaModel.youtube" target="_blank"><i
              class="fa fa-youtube prefix  text-xl mr-3 hover:text-customTeal hover:animate-pulse"></i>
      </a>
      <a class="cursor-pointer" *ngIf="socialMediaModel.linkedin"
          [href]="socialMediaModel.linkedin" target="_blank"><i
              class="fa fa-linkedin prefix  text-xl mr-3 hover:text-customTeal hover:animate-pulse"></i>
      </a>
      <a class="cursor-pointer" *ngIf="socialMediaModel.pinterest"
          [href]="socialMediaModel.pinterest" target="_blank"><i
              class="fa fa-pinterest prefix  text-xl mr-3 hover:text-customTeal hover:animate-pulse"></i>
      </a>
    </div>

      <div *ngIf="landingPageMain"  class="flex flex-col md:w-4/12 mx-5 lg:mx-0 pt-6 md:border-l-8 md:border-customTeal md:pl-6 space-y-6 ">
          <div class="w-11/12">
            <h1 class="max-w-lg text-2xl font-bold text-center mb-5  md:text-4xl md:text-left">
              <div [innerHTML]="landingPageMain.title"></div>
            </h1>
            <div class="text-darkGray  text-justify">
              <div [innerHTML]="landingPageMain.description">
              </div>
            </div>
            <!-- <div  *ngIf="appStore">
              <div *ngIf="appStore.length > 0 && appStore[0].isEnabled" class=" flex flex-col items-center lg:space-x-6 lg:flex-row">
                <a [href]="appleStore" target="_blank" class="flex mt-3 w-48 h-14 bg-black text-white rounded-xl items-center justify-center">
                    <div class="mr-3">
                        <svg viewBox="0 0 384 512" width="30">
                            <path fill="currentColor" d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"/>
                        </svg>
                    </div>
                    <div>
                        <div class="text-xs">Download on the</div>
                        <div class="text-2xl font-semibold font-sans -mt-1">App Store</div>
                    </div>
                  </a>

                <a [href]="playStore" target="_blank" class="flex mt-3 w-48 h-14 bg-black text-white rounded-lg items-center justify-center">
                    <div class="mr-3">
                        <svg viewBox="30 336.7 120.9 129.2" width="30">
                            <path fill="#FFD400" d="M119.2,421.2c15.3-8.4,27-14.8,28-15.3c3.2-1.7,6.5-6.2,0-9.7  c-2.1-1.1-13.4-7.3-28-15.3l-20.1,20.2L119.2,421.2z"/>
                            <path fill="#FF3333" d="M99.1,401.1l-64.2,64.7c1.5,0.2,3.2-0.2,5.2-1.3  c4.2-2.3,48.8-26.7,79.1-43.3L99.1,401.1L99.1,401.1z"/>
                            <path fill="#48FF48" d="M99.1,401.1l20.1-20.2c0,0-74.6-40.7-79.1-43.1  c-1.7-1-3.6-1.3-5.3-1L99.1,401.1z"/>
                            <path fill="#3BCCFF" d="M99.1,401.1l-64.3-64.3c-2.6,0.6-4.8,2.9-4.8,7.6  c0,7.5,0,107.5,0,113.8c0,4.3,1.7,7.4,4.9,7.7L99.1,401.1z"/>
                        </svg>
                    </div>
                    <div>
                        <div class="text-xs">GET IT ON</div>
                        <div class="text-xl font-semibold font-sans -mt-1">Google Play</div>
                    </div>
                  </a>

            </div>
            </div> -->
          </div>

      </div>
      <div  *ngIf="landingPageMain"  class="md:w-8/12 hover:grayscale">
        <img loading="lazy" *ngIf="landingPageMain.logo && !landingPageMain.videoUrl" class="w-full md:object-cover md:h-[40rem] " [src]="landingPageMain.logo" alt="" srcset="">
        <ng-container *ngIf="landingPageMain.logo && landingPageMain.videoUrl">
          <video autoplay *ngIf="landingPageMain.videoUrl " class="w-full hidden md:block" controls [poster]="landingPageMain.logo">
            <source [src]="landingPageMain.videoUrl | safeUrl"  >
            Your browser does not support HTML video.
          </video>

          <ng-container>
            <a [href]="landingPageMain.videoUrl" target="_blank"  class="w-full md:hidden block">
              <video autoplay *ngIf="landingPageMain.videoUrl " class="w-full md:hidden block" [poster]="landingPageMain.logo">
                <source [src]="landingPageMain.videoUrl | safeUrl"  >
                Your browser does not support HTML video.
              </video>
            </a>
          </ng-container>


        </ng-container>

        <ng-container *ngIf="!landingPageMain.logo && landingPageMain.videoUrl">
          <video autoplay *ngIf="landingPageMain.videoUrl " class="w-full"  controls >
            <source [src]="landingPageMain.videoUrl | safeUrl"  >
            Your browser does not support HTML video.
          </video>
        </ng-container>
    </div>
  </div>