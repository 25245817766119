
<div *ngIf="settings " class="bg-gray-100 min-h-screen">
  <div class="flex flex-row-reverse ml-10 mt-6">
    <ng-container *ngIf="isAdmin && !(calledFrom == 'onBoarding')">
      <!-- <button class="lg:mr-24 border-2 border-customTeal rounded-md px-5 py-2 " (click)="toggleFilter()"><i class="material-icons float-left mr-2">tune</i>Filter</button> -->
      <button [routerLink]="['/admin/create-service']" 
        class="flex items-center space-x-2 rounded bg-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 fill-white " height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/></svg>
        <span>Add New</span>  
      </button>
    </ng-container>
  </div>

  <div *ngIf="isAdmin && !(calledFrom == 'onBoarding')" >
    <button 
    type="button"
    class="inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
   (click)="openMoveModal=true"
    class="flex items-center mx-5 space-x-2 rounded bg-customTeal px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
    <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
      <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
      <path d="M0 0h24v24H0z" fill="none"></path>
    </svg>
    <span>Arrange Items</span>  
    
  </button>
  </div>
  <div class="mt-5"  *ngIf="existingService.length==0">
    <div class="flex items-center justify-center">
        <img loading="lazy" data-src="../../../../assets/icons/Illustrations/Service Illustration.png"
        class="block w-64 items-center justify-center">
    </div>
    <ng-container *ngIf="!isAdmin">
      <div class="text-customTeal text-lg text-center font-bold">No {{servicesName}} found.</div>
      <div class="text-gray-400 text-sm text-center font-bold">{{servicesName}}  will appeare here once added!</div>
    </ng-container>
    <ng-container *ngIf="isAdmin">
      <div class="text-customTeal text-lg text-center font-bold">What are the {{servicesName}}  you provide?</div>
      <div class="text-gray-400 text-sm text-center font-bold">Why not let the world know!</div>
    </ng-container>
  </div>

  <!-- <div class="mt-16 flex flex-col" *ngIf="existingService.length > 0">
    <div class="flex flex-wrap gap-x-14
    mx-auto">
      <div class="form-control w-full bg-white shadow-sm  pb-10"
      *ngFor="let service of getSortedList(existingService) | paginate: { itemsPerPage: 8, currentPage: p }" [ngStyle]="{'background-color': service.backgroundColor}"
      >
      <div class="flex md:flex-row " *ngIf="service.imgLink">
        <div class="w-4/12" *ngIf="service.imgLink">
          <img *ngIf="service.imgLink" [src]="service.imgLink" class="object-cover w-full"/>
        </div>
        <div class="w-8/12">
          <div class="bg-customTeal p-5 mb-10 cursor-pointer" (click)="redirectToservice(service.id)">
            <div class="pl-28 text-3xl text-white"> {{truncateHTML(service.serviceName, 35)}} </div>
          </div>
          <div *ngIf="service.allServices" class="flex flex-wrap">
            <ng-container *ngFor="let ser of service.allServices" class="">
              <div class="md:w-3/12 w-full mb-5">
                <div tabindex="0" class="mx-5 collapse collapse-plus border border-base-300 bg-gray-200 rounded-box ">
                  <input type="checkbox" class="peer" />
                  <div  class="collapse-title text-xl font-medium cursor-pointer" >
                    {{ser.serviceTitle}}
                  </div>
                  <div class="collapse-content">
                    <p>$ {{ser.servicePrice}}</p>
                    <p class="italic">{{ser.serviceUnderDescription}}</p>
                  </div>
                </div>
              </div>


            </ng-container>
          </div>
          <div *ngIf="!service.allServices && service.serviceDescription">
            {{service.serviceDescription}}
          </div>
          <div *ngIf="service.price">{{service.price}}</div>
        </div>
      </div>
      <div  *ngIf="!service.imgLink">
        <div class="bg-customTeal p-5 mb-10 cursor-pointer" (click)="redirectToservice(service.id)">
          <div class="pl-28 text-3xl text-white"> {{truncateHTML(service.serviceName, 35)}} </div>
        </div>
        <div *ngIf="service.allServices" class="flex flex-wrap">
          <ng-container *ngFor="let ser of service.allServices" class="">
            <div class="md:w-3/12 w-full mb-5">
              <div tabindex="0" class="mx-5 collapse collapse-plus border border-base-300 bg-gray-200 rounded-box ">
                <input type="checkbox" class="peer" />
                <div  class="collapse-title text-xl font-medium cursor-pointer" >
                  {{ser.serviceTitle}}
                </div>
                <div class="collapse-content">
                  <p>$ {{ser.servicePrice}}</p>
                  <p class="italic">{{ser.serviceUnderDescription}}</p>
                </div>
              </div>
            </div>
      
      
          </ng-container>
        </div>
        <div *ngIf="!service.allServices && service.serviceDescription">
          {{service.serviceDescription}}
        </div>
      </div>

    </div>
    </div>
    <div class="w-full text-center ">
      <pagination-controls (pageChange)="p = $event"></pagination-controls>
    </div>
  </div> -->

<div *ngIf="existingService">
  <div class="flex flex-wrap gap-x-4  w-full  md:gap-y-8 gap-y-4 px-4 md:gap-x-6 justify-center  mx-auto mt-5 ">

    <div
    (click)="redirectToservice(service.id)"
    class="w-full   md:mx-4  md:mb-10 flex    border bg-white md:w-[341px] md:h-[300px] drop-shadow-md rounded-lg hover:scale-100 hover:ease-in hover:duration-300"
    *ngFor="let service of getSortedList(existingService)" 
     [ngStyle]="{'background-color': service.backgroundColor}"
    >
    <div class=" flex flex-col w-full   px-4  py-4  md:px-6   md:h-max border border-gray-200    rounded-lg hover:scale-100 hover:ease-in hover:duration-300">
            
      <ng-container>
        <div  class="w-full ">
          <div *ngIf="service.imgLink && !service.videoLink" (click)="redirectToservice(service.id)" class="w-full md:h-48 rounded-xl cursor-pointer">
    
            <div class="flex justify-center md:h-48 " *ngIf="service.imgLink" >
              <img [src]="service.imgLink" class="md:w-56 md:h-full w-full rounded-lg md:object-contain" >
            </div>
          </div>
          <div>
            <div *ngIf="service.videoLink && service.imgLink" (click)="redirectToservice(service.id)"  class="w-full h-40 md:h-48 rounded-xl cursor-pointer">
              
              <video controls *ngIf="service.videoLink != ''" [src]="service.videoLink" class="object-cover" alt="Logo" [poster]="service.imgLink"></video>           
            </div>
            <div *ngIf="service.videoLink && !service.imgLink" (click)="redirectToservice(service.id)"  class="w-full h-40 md:h-48 rounded-xl cursor-pointer">
              <video controls *ngIf="service.videoLink != ''" [src]="service.videoLink" class="object-cover" alt="Logo"></video>           
            </div>
          </div>
          <div class="break-words">
            <h6 [routerLink]="['/admin/service', service.id]"  class=" cursor-pointer max-w-sm text-xl font-bold py-2 text-center" [ngStyle]="{'color':service.titleColor}">{{truncateHTML(getInnerText(service.serviceName),40)}}</h6>
          </div>

        </div>
      </ng-container>
      
      <div *ngIf="service.price">
        <div *ngIf=" service.price!='dontInclude'">
          <div *ngIf="service.price > 0" href="" class="justify-center text-customTeal text-center font-bold ">
            <ng-container *ngIf="currency"> {{service.price | currency: currency:'symbol-narrow'}}</ng-container>
            <ng-container *ngIf="!currency">{{service.price | currency: 'USD':'symbol-narrow'}}</ng-container>
          </div>
        </div>
      </div>
      <!-- <div *ngIf="!service.allServices || service.allServices.length==0" [routerLink]="['/view-service', service.id]">  -->
        <!-- <div *ngIf="service.serviceDescription">
          {{truncateHTML(getInnerText(service.serviceDescription), 90)}}
        </div> -->
        
      <!-- </div>  -->
    
    </div>
<!-- 
    <div *ngIf="service.allServices && service.allServices.length > 0" class=" flex flex-col w-full items-center  px-4  py-4  md:px-6 h-min border border-gray-200    rounded-lg hover:scale-100 hover:ease-in hover:duration-300 ">
      <ng-container>
        <div [routerLink]="['/view-service', service.id]" class="w-full">
          <div *ngIf="service.imgLink && !service.videoLink" [routerLink]="['/admin/service', service.id]"  class="w-full h-40 md:h-48 rounded-xl cursor-pointer">
            <div class="flex justify-center h-40 md:h-48 " *ngIf="service.imgLink" >
              <img [src]="service.imgLink" class="md:w-56 h-full w-full rounded-lg" style="object-fit: contain;">
            </div>
          </div>
          <div>
            <div *ngIf="service.videoLink && service.imgLink" (click)="redirectToservice(service.id)"  class="w-full h-40 md:h-48 rounded-xl cursor-pointer">
              <video controls *ngIf="service.videoLink != ''" [src]="service.videoLink" class="object-cover" alt="Logo" [poster]="service.imgLink"></video>           
            </div>
            <div *ngIf="service.videoLink && !service.imgLink" (click)="redirectToservice(service.id)"  class="w-full h-40 md:h-48 rounded-xl cursor-pointer">
              <video controls *ngIf="service.videoLink != ''" [src]="service.videoLink" class="object-cover" alt="Logo"></video>           
            </div>
          </div>  
          <div class="break-words">
            <h4 [routerLink]="['/admin/service', service.id]"  class=" cursor-pointer max-w-sm font-bold py-2" [ngStyle]="{'color':service.titleColor}">{{truncateHTML(getInnerText(service.serviceName),60)}}</h4>
          </div>
        </div>
      </ng-container>
      <div *ngIf="service.allServices" class="flex flex-col w-full pt-4">
        <ng-container *ngFor="let ser of service.allServices | slice:0:2;" class="w-full">
          <div class="flex flex-col w-full mb-5">
            <div tabindex="0" class="w-full collapse collapse-plus rounded-sm border border-base-300 shadow-sm bg-gray-100 ">
              <input type="checkbox" class="peer" />
              <div  class="collapse-title cursor-pointer font-bold text-customTeal" >
                {{truncateHTML(ser.serviceTitle, 60)}}
              </div>
              <div class="collapse-content">
                <p class="font-bold text-customTeal">$ {{ser.servicePrice}}</p>
                <p class="italic">{{truncateHTML(ser.serviceUnderDescription,60)}}</p>
                <button *ngIf="ser.hasAppointment==true && currentUser" class="btn-custom-solid float-right" (click)="BookServiceAppointment(service,ser)">Book</button>
      
             </div>
            </div>
          </div>
        </ng-container>
        <div class="flex justify-end" (click)="redirectToservice(service.id)" *ngIf="service.allServices.length > 2 ">
          <p class="text-sm font-bold text-customTeal">Show all</p>

        </div>
      </div>  
    </div> -->

  </div>
 
</div>
  <div class="w-full text-center ">
    <app-pagination [totalItems]="total" [itemsPerPage]="itemsPerPage" [currentPage]="p" (currentPageChange)="pageChangeEvent($event)"></app-pagination>
  </div>
</div>


</div>

<!-- toast -->
<app-alert class="fixed top-2 right-1 flex" [toastType]="toastType" [toastMessage]="toastMessage" [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>
  
<div *ngIf="isAdmin">
<app-move-elements *ngIf="openMoveModal"  [elementsList]="existingService" sectionType="Services" (closeClicked)="openMoveModal=false"></app-move-elements>
</div>