import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiKeyManagerService } from './apiKeyManager.Service';

@Injectable({
  providedIn: 'root'
})
export class FirstNationsService {

  constructor(private httpClient: HttpClient, private keyManager: ApiKeyManagerService) { }  
  url = environment.firstNation.apiUrl + 'api/firstnations/';

  getFirstNations(): Observable<any> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    return this.httpClient.get<any>(this.url + 'GetFirstNations', { headers });
  }

  getFirstNationLeaders() {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    return this.httpClient.get<any>(this.url + 'getfirstnations', { headers });
  }


}
 