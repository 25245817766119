import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AreasOfInterestService {
  collectionRef: AngularFirestoreCollection<any>;
  documentRef: AngularFirestoreDocument<any>;

  constructor(private db: AngularFirestore) { }

  saveAreasOfInterest(model: any) {
    model.id = this.db.createId();
    return this.db.collection('AreasOfInterest').doc(model.id).set(model);
  }

  updateAreasOfInterest(model: any) {
    return this.db.collection('AreasOfInterest').doc(model.id).update(model);
  }

  getAreasOfInterest() {
    return this.db.collection('AreasOfInterest');
  }
  getMyAreasOfInterest(userId: any){
    return this.db.collection('UserAreasOfInterest', ref => ref.where('uid', '==', userId));
  }
  // my interest areas
  saveMyAreas(model: any) {
    model.id = this.db.createId();
    return this.db.collection('UserAreasOfInterest').doc(model.id).set(model);
  }

  updateMyAreas(model: any)  {
    return this.db.collection('UserAreasOfInterest').doc(model.id).update(model);
  }

  addUserToArea(id: any, userId) {
    this.documentRef = this.db.doc('AreasOfInterest/' + id);
    const item = this.documentRef.valueChanges();
    item.pipe(take(1)).subscribe(i => {
      if (i) {
        i.membersList.push(userId);
        this.updateAreasOfInterest(i);
      }
    });
  }

  removeUserFromArea(id: any, userId) {
    this.documentRef = this.db.doc('AreasOfInterest/' + id);
    const item = this.documentRef.valueChanges();
    item.pipe(take(1)).subscribe(i => {
      if (i && i.membersList) {
        if (i.membersList.includes(userId)) {
          const index = i.membersList.indexOf(i.membersList.find(selected => selected === id));
          i.membersList.splice(index, 1);

          this.updateAreasOfInterest(i);
        }
      }
    });
  }
  

  deleteArea(model: any)  {
    return this.db.collection('AreasOfInterest').doc(model.id).delete();
  }

}
