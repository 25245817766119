<div class="flex flex-col md:flex-row justify-between gap-y-3 gap-x-8 p-4 md:p-12 ">
  <div class="flex flex-col space-y-4 md:w-2/3">

    <!-- DATE FILTER DROPDOWN -->
    <div class="flex justify-center md:justify-end">
      <div tabindex="0" class="ml-3 collapse collapse-arrow w:3/12 md:w-3/12 ">
        <input type="checkbox" [(ngModel)]="dateFilterActive">
        <div class="collapse-title font-medium bg-white">
          <div class="flex flex-row ">

            <i class="material-icons mr-4 float-left">calendar_today</i>

            <span><b>Date</b></span>

          </div>

        </div>
        <div class="collapse-content bg-white">

          <div>
            <button (click)="dailyChannels()"
              class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100 ">
              <span><b>Daily</b></span>
            </button>
            <hr />
          </div>
          <div>
            <button (click)="weeklyChannels()"
              class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100">
              <span><b>Last 7 days</b></span>
            </button>
            <hr />
          </div>
          <div>
            <button (click)="monthlyChannels()"
              class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg  hover:bg-gray-100 ">
              <span><b>Last 30 days</b></span>
            </button>
            <hr />
          </div>
          <div>
            <button (click)="customDateForChannels()"
              class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg  hover:bg-gray-100 ">
              <span><b>Between dates</b></span>
            </button>
            <hr />
          </div>
        </div>
      </div>
    </div>
    <!-- END DATE FILTER DROPDOWN -->

    <!-- DATE PICKER -->

    <!-- NEW DATE PICKER START-->

    <div *ngIf="filterByDateChannel">
      <div class="w-full  bg-white md:px-10 px-2  py-6 space-y-2 shadow-sm">
        <div class="flex space-x-4 items-center">
          <svg width="28" height="28" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
            <path
              d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zm64 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm128 0v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H336zM64 400v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H208zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H336c-8.8 0-16 7.2-16 16z" />
          </svg>
          <div class="w-10/12">
            <p class="font-bold text-lg">Date </p>
            <p class="text-xs text-gray-500">Enter the start date, end date to filter the channel</p>
          </div>
        </div>
        <div class="flex justify-center">
          <div class="w-full ">
            <div class=" w-full md:w-1/2">
              <div class="w-full px-3 md:px-0 md:mb-0">
                <label class="label">
                  <span class="label-text text-[#4F4F4F] text-base font-bold leading-5">Start Date
                    <span class="text-red-500">*</span>
                  </span>
                </label>
              </div>

              <div class="w-full  px-3 md:mb-0">
                <div class=" bg-gray-100 p-1 rounded-lg" [formGroup]="createCalenderForm">
                  <input id="analyticsStartDate" formControlName="analyticsStartDate" type="date"
                    class="input w-full  rounded-sm bg-gray-100 text-gray-700" [ngClass]="{
                      'border-red-500': (analyticsStartDate.dirty || analyticsStartDate.touched) && analyticsStartDate.invalid,
                      'border-gray-200': !((analyticsStartDate.dirty || analyticsStartDate.touched) && analyticsStartDate.invalid)
                    }" (change)="dateChangeStartDate()">
                </div>
              </div>
            </div>




          </div>

          <br />

          <div class="w-full">
            <div class="w-full md:w-1/2 ">
              <div class="w-full px-3 md:px-0 md:mb-0">
                <label class="label">
                  <span class="label-text text-[#4F4F4F] text-base font-bold leading-5">End Date
                    <span class="text-red-500">*</span>
                  </span>
                </label>
              </div>
              <div class="w-full  px-3 md:mb-0">
                <div class=" bg-gray-100 p-1 rounded-lg" [formGroup]="createCalenderForm">
                  <input id="analyticsEndDate" formControlName="analyticsEndDate" type="date"
                    class="input w-full leading-tight rounded-sm bg-gray-100 text-gray-700" [ngClass]="{
                'border-red-500': (analyticsEndDate.dirty || analyticsEndDate.touched) && analyticsEndDate.invalid,
                'border-gray-200': !((analyticsEndDate.dirty || analyticsEndDate.touched) && analyticsEndDate.invalid)
              }" (change)="dateChangeEndDate()">
                </div>
              </div>
            </div>


          </div>

          <!-- Errors -->
          <div>

          </div>

        </div>
        <p *ngIf="dateInvalid" class="text-red-600">Please enter the dates correctly. End date should be greater than
          Start date.</p>
        <div class="flex-wrap text-center  text-md rounded-full items-center justify-center mx-4 mt-6 ">
          <button
            class="font-medium cursor-pointer border-2 border-customTeal px-3 md:px-6 py-2 md:py-1 rounded-full md:font-bold"
            (click)="customDateUpdateChannel(analyticsStartDate, analyticsEndDate)">Apply</button>
        </div>
      </div>
    </div>

    <!-- NEW DATE PICKER END -->

    <!-- END DATE PICKER -->



    <div class="flex bg-white rounded-md py-10 justify-evenly">
      <div class="flex-col  text-center">
        <div class="tooltip" data-tip="Users who clicked on unpaid listings that appear in search engine results.">
          <i class="material-icons flex justify-end">info</i>

        </div>
        <p class="font-bold text-customTeal">{{organicSearchSession}}</p>
        <p class="text-xs ">Organic Search</p>
        <p class="text-xs ">(Users)</p>

      </div>
      <div class="flex-col text-center">
        <div class="tooltip" data-tip="Users who use your app through social media and networking sites.">
          <i class="material-icons flex justify-end">info</i>

        </div>
        <p class="font-bold text-customTeal">{{socialInteractionSess}}</p>
        <p class="text-xs ">Social</p>
        <p class="text-xs ">(Users)</p>

      </div>
      <div class="flex-col text-center">
        <div class="tooltip"
          data-tip="When a user hits a bookmark, writes or pastes your website address into their browser.">
          <i class="material-icons flex justify-end">info</i>

        </div>
        <p class="font-bold text-customTeal">{{directSessions}}</p>
        <p class="text-xs ">Direct</p>
        <p class="text-xs ">(Users)</p>

      </div>
      <div class="flex-col text-center">
        <div class="tooltip"
          data-tip="Individuals that access your app after clicking on a link from another website (other than search engines).">
          <i class="material-icons flex justify-end">info</i>

        </div>
        <p class="font-bold text-customTeal">{{referalSessions}}</p>
        <p class="text-xs ">Referal</p>
        <p class="text-xs ">(Users)</p>

      </div>
    </div>

    <div class="mx-2 lg:mx-2 mt-10"><strong>Source and Device Analytics</strong></div>

    <div>
      <!-- SOURCE AND DEVICE GRAPH -->
      <div class="flex mt-2">
        <div *ngIf="startDate && endDate && !isUndefinedSourceDevice()" class="w-full bg-white rounded-lg">
          <div>
            <!-- <canvas baseChart chartType="bar" [datasets]="sourceDeviceChart.datasets"
              [labels]="sourceDeviceChart.labels" [options]="sourceDeviceChart.options" legend="true">
            </canvas> -->

            <ngx-charts-bar-vertical-2d
  [results]="dataVBC1"
  [view]="viewVBC"
  [animations]="animationsVBC"
  [legend]="legendVBC"
  [xAxis]="xAxisVBC"
  [yAxis]="yAxisLabelVBC"
  [showYAxisLabel]="showYAxisLabelVBC"
  [yAxisLabel]="yAxisLabelVBC"
  [dataLabelFormatting]="dataLabelFormatterVBC"
  [barPadding]="20">
</ngx-charts-bar-vertical-2d>
          </div>

        </div>

        <div class="flex mt-2">
          <div *ngIf="startDate && endDate && isUndefinedSourceDevice()  && totalUsers == 0 "
            class="w-full bg-white rounded-lg">
            <div class="relative w-full h-auto  overflow-hidden"
              *ngIf="startDate && endDate && isUndefinedSourceDevice()  && totalUsers == 0 ">
              <p class="absolute py-2.5  inset-x-0 text-center mt-10   leading-4 ">Sorry, seems like there isn't
                enough data for analysis.</p>
              <p class="absolute py-10 inset-x-0 mt-10 text-center">Graph will be available after few visits.</p>
              <img 

                loading="lazy"

                class="object-cover w-full h-auto"

                data-src="https://api.goingmobile.app/media/personal-app-assets/img/webp/graph.webp"
                            
                srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/graph.webp"
                            
                fallback="https://api.goingmobile.app/media/personal-app-assets/img/png/graph.png"
                            
                alt="Null data graph">

            </div>
          </div>


        </div>

      </div>
      <!--  -->
    </div>

  </div>

  <div class="flex flex-col md:w-2/6 gap-y-4">
    <div class="bg-white rounded-md p-6 space-y-6">
      <p class="text-center text-lg font-bold">Traffic Source</p>

      <div class="mx-2 lg:mx-8 mt-10">
        <div *ngIf="startDate && endDate && !isUndefinedSource()"
          class="w-full lg:w-full bg-white rounded-lg  mr-2 lg:mr-10">

          <!-- <canvas baseChart width="300" height="150" [data]="doughnutChart2Data" [labels]="doughnutChart2Labels"
            [chartType]="doughnutChart2Type" [options]="doughnutChart2Options" [plugins]="doughnutChart2Plugins"
            [legend]="doughnutChart2Legend" [colors]="doughnutChart2Colors">
          </canvas> -->

          <ngx-charts-pie-chart
  [view]="[400, 400]"
  [scheme]="{domain: ['#5AA454', '#A10A28', '#C7B42C', '#FF5733', '#33FF57']}"
  [results]="dataVBC2"
  [legend]="false"
  [labels]="true"
  [doughnut]="true"
  [legendPosition]="'top'"
  [legendTitle]="''"
  [labelFormatting]="pieChartLabel.bind(dataVBC2, source_data)"
  [animations]="false"
  [arcWidth]="0.35">
</ngx-charts-pie-chart>

        </div>

        <div class=" justify-center w-full mt-8 bg-white rounded-md p-6 space-y-6"
          *ngIf="startDate && endDate && isUndefinedSource() && totalUsers == 0 ">
          <img 
                loading="lazy"

                class="object-cover w-10 h-10 mt-3 md:mt-0  md:w-20 md:h-20 rounded-md cover m-auto"

                data-src="https://api.goingmobile.app/media/personal-app-assets/img/webp/websiteTrafficSource.webp"
                            
                srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/websiteTrafficSource.webp"
                            
                fallback="https://api.goingmobile.app/media/personal-app-assets/img/png/websiteTrafficSource.png"
                            
                alt="No traffic source">
                
          <p class="text-center">Your site's traffic source will be displayed here.</p>
        </div>

      </div>

    </div>
    <div class="bg-white rounded-md p-6 space-y-6">
      <p class="text-center text-lg font-bold">Devices by Subscribers</p>
      <!-- <p class="text-center text-lg font-bold">Total Visitors - {{totalActiveUsers}}</p> -->
      <p class="text-center text-lg font-bold">Total Visitors - {{directSessions}}</p>

      <div class="mx-2 lg:mx-8 mt-10">
        <div *ngIf="startDate && endDate && !isUndefinedDeviceCategory()"
          class="w-full lg:w-full bg-white rounded-lg  mr-2 lg:mr-10">

          <!-- <canvas baseChart width="300" height="150" [data]="doughnutChartData" [labels]="doughnutChartLabels"
            [chartType]="doughnutChartType" [options]="doughnutChartOptions" [plugins]="doughnutChartPlugins"
            [legend]="doughnutChartLegend" [colors]="doughnutChartColors">
          </canvas> -->

          <ngx-charts-pie-chart
  [view]="[400, 400]"
  [scheme]="{domain: ['#5AA454', '#A10A28', '#C7B42C', '#FF5733', '#33FF57']}"
  [results]="dataVBC2"
  [legend]="false"
  [labels]="true"
  [doughnut]="false"
  [legendPosition]="'top'"
  [legendTitle]="''"
  [labelFormatting]="pieChartLabel.bind(dataVBC3, device_data)"
  [animations]="false"
  [arcWidth]="0.35">
</ngx-charts-pie-chart>

        </div>


        <div class=" justify-center w-full mt-8 bg-white rounded-md p-6 space-y-6"
          *ngIf="startDate && endDate && isUndefinedDeviceCategory() && totalUsers == 0 ">

          <img 
                loading="lazy"
                
                class="object-cover w-10 h-10 mt-3 md:mt-0  md:w-20 md:h-20 rounded-md cover m-auto"

                data-src="https://api.goingmobile.app/media/personal-app-assets/img/webp/device.webp"
                            
                srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/device.webp"
                            
                fallback="https://api.goingmobile.app/media/personal-app-assets/img/png/device.png"
                            
                alt="No device">
                
          <p class="text-center">Device used to visit your site will show up here.</p>
        </div>

      </div>

    </div>
  </div>

</div>


<div>

</div>