import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SMSModel } from 'src/app/models/SMSModel';
import { ChatGptResponseModel } from 'src/app/models/chatGptModel';
import { EmailModel, CustomAdminEmailModel } from 'src/app/models/emailModel';
import { EmailService } from 'src/app/services/api/email.service';
import { SMSService } from 'src/app/services/api/sms.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { UserService } from 'src/app/services/firebase/user.service';
import { HelperService } from 'src/app/services/helper/helper';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-send-message-from-users',
  templateUrl: './send-message-from-users.component.html',
  styleUrls: ['./send-message-from-users.component.css']
})
export class SendMessageFromUsersComponent implements OnInit {

  @Input() userLogged;
  @Output() cancelSendClicked = new EventEmitter();
  @Output() sendClicked: EventEmitter<any> = new EventEmitter<any>();

  contactForm: UntypedFormGroup;
  contactMsg: String = ' ';

  domain = environment.firstNation.name;
  // domainName = "https://" + this.domain + ".goingmobile.app"
  isNationApp = environment.firstNation.Origin == "FNLeaders" ? true : false;

  domainName = this.isNationApp ? "https://" + this.domain + ".mynation.app" : "https://" + this.domain + ".goingmobile.app";
  
  greetings: any;
  signOff: any;
  email: any;
  messageForSMS: any;
  availablePhone: any;

  isLimitEmail: boolean = false;
  isLimitSMS: boolean = false;
  employees = [];
  admins = [];
  all = [];

  search_text:any = '';
  sendingTo: any;
  currentUser: any;

  constructor(private fb: UntypedFormBuilder,
              private userService: UserService,
              private emailService: EmailService,
              private authService: AuthService,
              private smsService: SMSService,
              private appSettingsService: AppSettingsService,
              private helperService: HelperService) { }

  ngOnInit(): void {

    this.currentUser = this.authService.getGlobalUser();

    console.log("the search text", this.search_text);
    console.log("user logged", this.userLogged)
    this.contactForm = this.fb.group({
      to:[''],
      contact: [this.contactMsg],
      subject: [''],
      emailbody: [''],
      messageSMS: ['']
    });
    
    console.log("Got into this componenet   SEND-MESSAGE-FROM-USERS")

    this.appSettingsService.getAppSettingsList().valueChanges().subscribe((settings:any) => {
      if(settings && settings.length != 0){
        this.availablePhone = settings[0].availablePhone;
      }
    })

    this.userService.getUserByRole('Employee').valueChanges().subscribe((employees) => {
        if(employees && employees.length){
          this.employees = employees;
        }
    });

    this.userService.getUserByRole('Admin').valueChanges().subscribe((admins) => {
       if(admins && admins.length){
        this.admins = admins;
        // console.log("the admins", this.admins)
        let indexOfAdmin = -1;
        this.admins.forEach((admin)=>{
          indexOfAdmin++;
          if(admin.Email === 'admin@goingmobile.app'){
            const index = this.admins.indexOf(admin);
            const x = this.admins.splice(index, 1);
            // console.log("GOT IT", admin, "THE INDEX", index, "Counter",indexOfAdmin);
          }
        })
      }
    })
    

  }

  toSendTo(){
      this.all = [...this.employees,...this.admins];
    console.log("There is all the employee and admins", this.all);
  }

  clickToSend(value){
    this.search_text = value.GivenName + " " + value.LastName;
    console.log("This is clicked to send to", value.GivenName + " " + value.LastName);
    this.sendingTo = value;
    console.log("----->>>>>>>on the send to",value);
  }

  sendMessage(model){
    // const sgMail = require('@sendgrid/mail');
    // sgMail.setApiKey("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");

    console.log("print the model", model.to,"MODEL", model);
    if(this.contactMsg == 'SMS'){
      const smsModel = new SMSModel();
      smsModel.body = "Message from "+ this.currentUser.GivenName + ", \n";
      smsModel.body += this.helperService.truncateHTML(model.messageSMS, "1000");
      smsModel.body += '\n'+ this.currentUser.Email;
      smsModel.body += '\n'+ this.currentUser.cellPhone;

      // smsModel.from = '+1'+this.currentUser.cellPhone;
      smsModel.from = this.availablePhone;
      console.log("the sending to", this.sendingTo.cellPhone);
      smsModel.phoneNumberList.push(this.sendingTo.cellPhone);
      // smsModel.phoneNumberList.push("3069945839");


      console.log("the sms Model", smsModel);
      this.smsService.sendSMS(smsModel).subscribe((res) => {
        if(res){
          console.log("It responded",res)
        }else{
        console.log("the response", res);
      }
      })
      
    }else{
      // const emailModel = new EmailModel();
      // emailModel.subject = model.subject;
      // emailModel.isNationApp = this.isNationApp;
      // emailModel.body = model.to + ", <br/>"
      // emailModel.body += model.emailbody;
      // emailModel.emailList= [this.sendingTo.Email];
      // emailModel.from = this.currentUser.Email;
      // emailModel.role = "User";
      // console.log("this is the emailModel", emailModel);
      // this.emailService.sendEmail(emailModel).subscribe((x) =>{
      //   console.log("the response",x)
      // });

      // Use the custom email to send - testing
      const emailModel  = new CustomAdminEmailModel();
      emailModel.subject = model.subject;
      emailModel.body = model.to + ", <br/>"
      emailModel.body += model.emailbody;
      emailModel.emailList = [this.sendingTo.Email]
      // emailModel.from = this.currentUser.Email;
      emailModel.role = "User";
      emailModel.host = "smtp.gmail.com";
      emailModel.adminEmail =  "mkproject.app@gmail.com";
      emailModel.appPassword = "ytklyidomvqfmtky";
      emailModel.sendFrom = "mkproject.app@gmail.com"

      this.emailService.sendCustomEmail(emailModel).subscribe((x) =>{
        console.log("the response",x)
      });


    }
  }
  cancelSendBtnClicked(){
    this.cancelSendClicked.emit();
    // console.log("Cancel the Buttton", this.cancelSendClicked.emit());
  }

  sendBtnClicked(response){
    this.cancelSendClicked.emit();

    this.sendClicked.emit(response);
    // console.log("send button clicked",response);
    // console.log("what to emit",this.sendClicked.emit(response));
  }

  SMSselected() {
    this.contactMsg = 'SMS'
  }
  emailSelected() {
    this.contactMsg = 'Email'
  }

  get emailbody() { return this.contactForm.get('emailbody'); }
  get subject() { return this.contactForm.get('subject'); }
  get messageSMS() { return this.contactForm.get('messageSMS'); }
  get contact() { return this.contactForm.get('contact'); }
  get to() { return this.contactForm.get('to'); }

}
