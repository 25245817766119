import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { firstValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/firebase/auth.service';

@Injectable({
  providedIn: 'root'
})
export class InviteService {
  constructor(private db: AngularFirestore) { }

  postsListRef: AngularFirestoreCollection<any>;
  postRef: AngularFirestoreDocument<any>;

  saveInvite(model: any) {
    const newContent = {
      id: this.db.createId(),
      inviteEmail: model.inviteEmail ? model.inviteEmail : '',
      timeStamp: model.timestamp,
      userType: model.userType,
      status: 'Pending',
      firstName: model.firstName ? model.firstName : '',
      lastName: model.lastName ? model.lastName : '',
      jobTitle: model.jobTitle ? model.jobTitle : '',
      depart: model.depart ? model.depart : '',
      cellPhone: model.cellPhone ? model.cellPhone : '',
      workPhone: model.workPhone ? model.workPhone : '',
      extension: model.extension ? model.extension : '',
      appointment: model.appointment ? model.appointment : false,
      contact: model.contact ? model.contact : [],
      profileImgLink: model.profileImgLink ? model.profileImgLink : '',
      showEmployee: model.showEmployee ? model.showEmployee : 'No',
      DOB: model.DOB ? model.DOB : '',
      greetings: model.greetings ? model.greetings : '',
      signOff: model.signOff ? model.signOff : '',
      messageSMS: model.messageSMS ? model.messageSMS : '',
      permission: model.permission ? model.permission : {},
      schedule: model.schedule ? model.schedule : [],
      recieveAppointmentEmail: model.recieveAppointmentEmail ? model.recieveAppointmentEmail : true,
      recieveAppointmentSMS: model.recieveAppointmentSMS ? model.recieveAppointmentSMS : true,
    };
    return this.db.collection('UserInvites').doc(newContent.id).set(newContent);
  }

  getInviteByEmail(userEmail) {
    return this.db.collection('UserInvites', ref => ref.where('inviteEmail', '==', userEmail));
  }
  getInviteByPhoneNo(userPhoneNo) {
    return this.db.collection('UserInvites', ref => ref.where('phoneNo', '==', userPhoneNo));
  }
  getPendingInvites() {
    return this.db.collection('UserInvites', ref => ref.where('status', '==', 'Pending'));
  }

  getInviteById(inviteId: any) {
    return this.db.collection('UserInvites', ref => ref.where('id', '==', inviteId));
  }

  // getInviteByIdWithSubscription(inviteId: any): Promise<any> {
  //   console.log("under inite id with ............... ")
  //   const query = this.db.collection('UserInvites', ref => ref.where('id', '==', inviteId)).get();
  //   return firstValueFrom(query);
  // }

  getInviteByIdWithSubscription(inviteId: any): Promise<any> {
    console.log("under invite id with ............... ");

    const query = this.db.collection('UserInvites', ref => ref.where('id', '==', inviteId)).get();

    return firstValueFrom(query).then((querySnapshot) => {
      if (querySnapshot.empty) {
        return null; // or throw an error, or handle the case when no documents are found
      }

      const inviteData = querySnapshot.docs.map(doc => doc.data());
      return inviteData; // assuming you expect a single result
    });
  }

  updateInvite(invite) {
    return this.db.collection('UserInvites').doc(invite.id).update({ status: 'Registered' });
  }

  updateInviteWithOutSubscription(invite: any): Promise<void> {
    return this.db.collection('UserInvites').doc(invite.id).update({ status: 'Registered' });
  }

  allInvite() {
    return this.db.collection('/UserInvites');
  }
  deleteInvite(inviteId: any) {
    //return this.db.collection('/RequestToJoin').doc(id).delete();
    return this.db.collection('/UserInvites').doc(inviteId).delete();
  }
}
