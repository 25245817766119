

<div *ngIf="user">
<div class="row">
<div class="row col s12  inner-wrapper"> 
  <div class="card-image">
    <div class="background-img">

      <div *ngIf="!isMobile">
        <div class="background-with-color" (click)="myInput.click()" class="hide-on-med-and-up center">
          <div *ngIf="!coverPhotoLink">
            <img loading="lazy" data-src="">
          </div>
          <img loading="lazy" *ngIf="coverPhotoLink" #file [src]="coverPhotoLink" (click)="myInput.click()"
            style="width: 100%; height: 120px;" />
          <div style="display: inline-block">
            <input #myInput id="file" name="file" (change)="handleCoverPhotoImageInput($event.target.files)" type="file"
              hidden />
          </div>
        </div>

        <div class="background-with-color-big hide-on-small-only show-on-medium-and-up" (click)="myInput.click()">
          <div *ngIf="!coverPhotoLink"></div>
          <img loading="lazy" *ngIf="coverPhotoLink" #file [src]="coverPhotoLink" (click)="myInput.click()"
            style="width: 100%; height: 200px;" />
          <div style="display: inline-block">
            <input #myInput id="file" name="file" (change)="handleCoverPhotoImageInput($event.target.files)" type="file"
              hidden />
          </div>
        </div>
      </div>

      <div *ngIf="isMobile">
        <div class="background-with-color" (click)="handleMobilePhotoInput()" class="hide-on-med-and-up center">
          <div *ngIf="!coverPhotoLink">
            <img loading="lazy" data-src="">
          </div>
          <img loading="lazy" *ngIf="coverPhotoLink" #file [src]="coverPhotoLink" (click)="handleMobilePhotoInput()"
            style="width: 100%; height: 120px;" />

        </div>

        <div class="background-with-color-big hide-on-small-only show-on-medium-and-up" (click)="handleMobilePhotoInput()">
          <div *ngIf="!coverPhotoLink"></div>
          <img loading="lazy" *ngIf="coverPhotoLink" #file [src]="coverPhotoLink" (click)="handleMobilePhotoInput()"
            style="width: 100%; height: 200px;" />

          </div>
        </div>

      </div>

      <div *ngIf="!isMobile">
      <div class="prof-img hide-on-med-and-up center">
        <div *ngIf="!imgLink"  class="center">
          <img
                loading="lazy"

                class="background-img-avatar"  #file 

                (click)="myInputB.click()"
                
                style="width: 120px; border: white solid 3px; border-radius: 50%;"
    
                data-src="https://api.goingmobile.app/media/personal-app-assets/img/webp/profile.webp"
                                            
                srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/profile.webp"
                                            
                fallback="https://api.goingmobile.app/media/personal-app-assets/img/jpg/profile.jpg"
                                            
                alt="ananymous profile."> 
          <div style="display: inline-block">
            <input #myInputB id="file" name="file" (change)="handleImageInput($event.target.files)" type="file" hidden />
          </div>
        </div>
        <div  *ngIf="imgLink"  class="hide-on-med-and-up center">
          <img loading="lazy" class="background-img-avatar"  #file  (click)="myInputB.click()" [src]="imgLink"  style="width: 120px; height: 120px; border: white solid 3px; border-radius: 50%;"/>
          <div style="display: inline-block">
            <input #myInputB id="file" name="file" (change)="handleImageInput($event.target.files)" type="file" hidden />
          </div>
        </div>
      </div>

      <div class="prof-img-big hide-on-small-only show-on-medium-and-up center">
        <div *ngIf="!imgLink" class="">
          <img
          loading="lazy"

          class="background-img-avatar"  #file 

          (click)="myInputB.click()"
          
          style="width: 120px; border: white solid 3px; border-radius: 50%;"

          data-src="https://api.goingmobile.app/media/personal-app-assets/img/webp/profile.webp"
                                      
          srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/profile.webp"
                                      
          fallback="https://api.goingmobile.app/media/personal-app-assets/img/jpg/profile.jpg"
                                      
          alt="ananymous profile."> 
          <div style="display: inline-block">
            <input #myInputB id="file" name="file" (change)="handleImageInput($event.target.files)" type="file" hidden />
          </div>
        </div>
        <div  *ngIf="imgLink" class="hide-on-small-only show-on-medium-and-up center">
          <img loading="lazy" class="background-img-avatar"  #file  (click)="myInputB.click()" [src]="imgLink"  style="width: 140px; height: 140px; border: white solid 3px; border-radius: 50%;"/>
          <div style="display: inline-block">
            <input #myInputB id="file" name="file" (change)="handleImageInput($event.target.files)" type="file" hidden />
          </div>
        </div>
      </div>
      </div>


      <div *ngIf="isMobile">
        <div class="prof-img hide-on-med-and-up center">
          <div *ngIf="!imgLink"  class="center">
          <img
          loading="lazy"

          class="background-img-avatar"  #file 

          (click)="handleProfileMobilePhotoInput()"
          
          style="width: 120px; border: white solid 3px; border-radius: 50%;"

          data-src="https://api.goingmobile.app/media/personal-app-assets/img/webp/profile.webp"
                                      
          srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/profile.webp"
                                      
          fallback="https://api.goingmobile.app/media/personal-app-assets/img/jpg/profile.jpg"
                                      
          alt="ananymous profile."> 

          </div>
          <div  *ngIf="imgLink"  class="hide-on-med-and-up center">
            <img loading="lazy" class="background-img-avatar"  #file  (click)="handleProfileMobilePhotoInput()" [src]="imgLink"  style="width: 120px; height: 120px; border: white solid 3px; border-radius: 50%;"/>

          </div>
        </div>

        <div class="prof-img-big hide-on-small-only show-on-medium-and-up center">
          <div *ngIf="!imgLink" class="">
            <img
            loading="lazy"

            class="background-img-avatar"  #file 
  
            (click)="handleProfileMobilePhotoInput()"
            
            style="width: 140px; border: white solid 3px; border-radius: 50%;"
  
            data-src="https://api.goingmobile.app/media/personal-app-assets/img/webp/profile.webp"
                                        
            srcset="https://api.goingmobile.app/media/personal-app-assets/img/webp/profile.webp"
                                        
            fallback="https://api.goingmobile.app/media/personal-app-assets/img/jpg/profile.jpg"
                                        
            alt="ananymous profile."> 

          </div>
          <div  *ngIf="imgLink" class="hide-on-small-only show-on-medium-and-up center">
            <img loading="lazy" class="background-img-avatar"  #file  (click)="handleProfileMobilePhotoInput()" [src]="imgLink"  style="width: 140px; height: 140px; border: white solid 3px; border-radius: 50%;"/>

          </div>
        </div>

        </div>



  </div>
</div>

</div>
<div class="section hide-on-small-only"></div>

<div class="row col s12">


  <ul class="collapsible">

    <li>
      <div class="collapsible-header"><i class="material-icons grey-text text-darken-2">photo_camera</i><strong>Photo Gallery</strong></div>
      <div class="collapsible-body">
        

       <div class="row" >
         <div *ngIf="images">
          <gallery *ngIf="images.length > 0" [items]="images"></gallery>
         </div>
    
       
        
          <a class="btn-floating btn-small waves-effect waves-light blue right"  style="margin-top: 15px;" #file  (click)="myInputc.click()"><i class="material-icons" style="display: block;">photo_camera</i></a>
          <div style="display: inline-block">
            <input #myInputc id="file" name="file" (change)="handleImageGalleryInput($event.target.files)" type="file" hidden />
          </div>
         </div>



      </div>
    </li>

    <li  class="active">
      <div class="collapsible-header"><i class="material-icons green-text text-darken-2">info</i><strong>Personal Information</strong></div>
      <div class="collapsible-body">
        <form [formGroup]="updateProfileForm">
          <div class="row">
              <div class="input-field col s12 m6">
                <i class="material-icons prefix green-text text-darken-2">account_circle</i>
                  <input id="GivenName" type="text" formControlName='GivenName' class="validate" />
                  <label class="active" for="GivenName" >Given Name</label>
              </div>
              <div class="input-field col s12 m6">
                <i class="material-icons prefix green-text text-darken-2">account_circle</i>
                  <input id="MiddleName" type="text" formControlName='MiddleName' class="validate" />
                  <label class="active" for="MiddleName" >Middle Name</label>
              </div>
              <div class="input-field col s12 m6">
                <i class="material-icons prefix green-text text-darken-2">account_circle</i>
                <input id="LastName" type="text" placeholder="Last Name" formControlName="LastName" class="validate">
                <label class="active" for="LastName">Last Name</label>
              </div>

              <div class="input-field col s12 m6">
                <i class="material-icons prefix green-text text-darken-2">add_location</i>
                  <input id="City" type="text" formControlName='City'/>
                  <label class="active" for="City" >City</label>
              </div>


              <div class="input-field col s12 m6">
                  <i class="material-icons prefix green-text text-darken-2">phone</i>
                  <input id="cellPhone" type="text" formControlName='cellPhone'  />
                  <label class="active" for="cellPhone">Phone Number</label>
              </div>

              <div class="input-field col s12 m6">
                  <i class="material-icons prefix green-text text-darken-2">email</i>
                  <input id="Email" type="text" formControlName='Email' placeholder="Email"/>
                  <label class="active" for="Email" >Email</label>
              </div>

              <div *ngIf="user">
                <div class="input-field col s12 m6" *ngIf="user.role == 'User'">
                <p><i class="material-icons orange-text text-darken-2">wc</i>Account Type <span class="red-text">*</span></p>
                <div class="row col s12">
                  <br/>
                  <div class="col s6">
                    <label>
                      <input type="radio" value=false formControlName="isBusinessAcc"  (click)="clickPersonalAccount()"/>
                      <span>Personal</span>
                    </label>
                  </div>
                  <div class="col s6">
                    <label>
                      <input type="radio" value=true formControlName="isBusinessAcc" (click)="clickBusinessAccount()" />
                      <span>Business</span>
                    </label>
                  </div>
                </div>
              </div>


              </div>

          </div>
      </form>
      </div>
    </li>



    <li>
      <div class="collapsible-header"><i class="material-icons teal-text">mark_email_unread</i> <strong>Contact</strong></div>
      <div class="collapsible-body">
       <div class="row">

        <div class="row" *ngIf="user">
          <div class="row col s12">
            <a (click)="openContactAndBasicInfoModal()" class="green-text"><i class="material-icons">add_circle_outline</i><strong>Add New
              Contact Info</strong> </a>
           </div>


          <div *ngIf="user.contactAndBasicInfo">

              <div *ngFor="let contact of user.contactAndBasicInfo" class="col s12 m6">
                <div class="row card-panel col s12">
                  <div class="row col s12">
                    <br />

                    <span *ngIf="contact.mobilePhone"><strong class="pink-text">Mobile : </strong> {{contact.mobilePhone}} </span>
                    <br />
                    <span *ngIf="contact.workPhone1"><strong class="pink-text">Work : </strong> {{contact.workPhone1}} </span>
                    <br />
                    <span *ngIf="contact.website"><strong class="pink-text">Website : </strong> {{contact.website}} </span>

                  </div>

                  <div class="row col s12">
                    <button class="btn-small red right btn-floating" (click)="deleteContact(contact)"><i class="material-icons"
                        style="display: block;">delete</i></button>
                    <button class="btn-small blue right btn-floating" style="margin-right: 15px;"
                      (click)="editContact(contact)"><i class="material-icons" style="display: block;">edit</i></button>
                  </div>
                </div>
              </div>



          </div>

          <div class="col s12">

            <div class=" switch">
              <label>
                ` <span><strong class="black-text">Show to public </strong></span>
                <span class="red-text" style="margin-left: 25px;"><strong>No</strong> </span>
                <input type="checkbox" [checked]="showContact" (change)="checkedShowContact()">
                <span class="lever"></span>
                <span class="green-text"><strong>Yes</strong></span>
              </label>
            </div>

          </div>
        </div>

        <!-- <div *ngIf="user">
          <div *ngIf="user.contactAndBasicInfo">

            <div class="switch">
              <label>
                ` <span>Show to public </span>
                  <span>No</span>
                  <input type="checkbox" [checked]="showContact" (change)="checkedShowContact()">
                  <span class="lever"></span>
                  <span>Yes</span>
              </label>
          </div>
            <div *ngFor="let contact of user.contactAndBasicInfo">
              <div class="card">
                <div class="card-content">
                  <p *ngIf="contact.email" ><i class="material-icons">email</i>{{contact.email}}</p>
                  <p *ngIf="contact.mobilePhone"><i class="material-icons">phone_iphone</i>{{contact.mobilePhone}}</p>
                  <p *ngIf="contact.languages"><i class="material-icons">language</i>{{contact.languages}}</p>
                  <p *ngIf="contact.website"><i class="material-icons">public</i>{{contact.website}}</p>
                  <p *ngIf="contact.socialLink"><i class="material-icons">link</i>{{contact.socialLink}}</p>
                  <p *ngIf="contact.politicalView"><i class="material-icons">description</i>{{contact.politicalView}}</p>
                  <p *ngIf="contact.religiousView"><i class="material-icons">description</i>{{contact.religiousView}}</p>
                  <p *ngIf="contact.workPhone1"><i class="material-icons green">phone</i>{{contact.workPhone1}}</p>
                  <p *ngIf="contact.workPhone2"><i class="material-icons pink">phone</i>{{contact.workPhone2}}</p>
                </div>
                <div class="card-action">
                  <div class="row" >
                    <button class="btn col s6" (click)="editContact(contact)"><i class="material-icons left">edit</i>Edit</button>
                    <button class="btn col s6" (click)="deleteContact(contact)"><i class="material-icons left">delete</i>Delete</button>
                  </div>
                </div>
              </div>




            </div>

          </div>
        </div> -->

       </div>
      </div>
    </li>



<li>
  <div class="collapsible-header"><i class="material-icons purple-text text-lighten-2">share</i><strong>Social Media</strong> </div>
  <div class="collapsible-body">
    <div class="row" *ngIf="user">
      <form [formGroup]="socialMediaForm">
      <!--  instagram  -->
      <div class="input-field col s12">
        <i class="fa fa-instagram prefix " style="color: #8a3ab9;"></i>
        <input id="userInsta" type="text" formControlName='instagramAcc' class="materialize-textarea" (input)="showInstagramPrivacy()">
        <label class="active" for="userInsta">Instagram account</label>
        <div *ngIf="(getInstagramAcc?.dirty || getInstagramAcc?.touched) && getInstagramAcc?.invalid">
          <small *ngIf="getInstagramAcc.errors.pattern" class="red-text"> Wrong Instagram url format </small>
        </div>
      </div>
      <div>

        <div class="input-field col s12" *ngIf="showInstagramAcc">
          <p>Show Instagram Account To :</p>
          <div class="col s12 m6 l4">
            <label>
              <input type="radio" value="Public" formControlName="showInstagramAcc" />
              <span class="text"><strong>General Public</strong></span>
            </label>
          </div>
          <div class="col s12 m6 l4">
            <label>
              <input type="radio" value="Friends" formControlName="showInstagramAcc" />
              <span class="blue-text"><strong>Friends Only</strong></span>
            </label>
          </div>
          <div class="col s12 m6 l4">
            <label>
              <input type="radio" value = 'Private' formControlName="showInstagramAcc" />
              <span class="red-text"><strong>Do Not Show</strong></span>
            </label>
          </div>

        </div>
      </div>

      <!--  twitter  -->
      <div class="input-field col s12">
        <i class="fa fa-twitter prefix" style="color: #1DA1F2;"></i>
        <input id="userTwitter" type="text" formControlName='twitterAcc' class="materialize-textarea" (input)="showTwitterPrivacy()">
        <label class="active" for="userTwitter">Twitter account</label>
        <div *ngIf="(getTwitterAcc?.dirty || getTwitterAcc?.touched) && getTwitterAcc?.invalid">
          <small *ngIf="getTwitterAcc.errors.pattern" class="red-text"> Wrong Twitter url format </small>
        </div>
      </div>
      <div class="input-field col s12" *ngIf="showTwitterAcc">
        <p>Show Twitter Account To :</p>
        <div class="col s12 m6 l4">
          <label>
            <input type="radio" value="Public" formControlName="showTwitterAcc" />
            <span class="text"><strong>General Public</strong></span>
          </label>
        </div>
        <div class="col s12 m6 l4">
          <label>
            <input type="radio" value="Friends" formControlName="showTwitterAcc" />
            <span class="blue-text"><strong>Friends Only</strong></span>
          </label>
        </div>
        <div class="col s12 m6 l4">
          <label>
            <input type="radio" value = 'Private' formControlName="showTwitterAcc" />
            <span class="red-text"><strong>Do Not Show</strong></span>
          </label>
        </div>

      </div>

      <!--  linked in  -->
      <div class="input-field col s12">
        <i class="fa fa-linkedin prefix " style="color: #0077b5;" ></i>
        <input id="userLinkedin" type="text" formControlName='linkedinAcc' class="materialize-textarea" (input)="showLinkedinPrivacy()">
        <label class="active" for="userLinkedin">Linkedin account</label>
        <div *ngIf="(getLinkedinAcc?.dirty || getLinkedinAcc?.touched) && getLinkedinAcc?.invalid">
          <small *ngIf="getLinkedinAcc.errors.pattern" class="red-text"> Wrong Linkedin url format </small>
        </div>
      </div>
      <div class="input-field col s12" *ngIf="showLinkedinAcc">
        <p>Show Linkedin Account To :</p>
        <div class="col s12 m6 l4">
          <label>
            <input type="radio" value="Public" formControlName="showLinkedinAcc" />
            <span class="text"><strong>General Public</strong></span>
          </label>
        </div>
        <div class="col s12 m6 l4">
          <label>
            <input type="radio" value="Friends" formControlName="showLinkedinAcc" />
            <span class="blue-text"><strong>Friends Only</strong></span>
          </label>
        </div>
        <div class="col s12 m6 l4">
          <label>
            <input type="radio" value = 'Private' formControlName="showLinkedinAcc" />
            <span class="red-text"><strong>Do Not Show</strong></span>
          </label>
        </div>

      </div>

      <!--  youtube  -->
      <div class="input-field col s12">
        <i class="fa fa-youtube prefix red-text"></i>
        <input id="userYoutube" type="text" formControlName='youtubeAcc' class="materialize-textarea" (input)="showYoutubePrivacy()">
        <label class="active" for="userYoutube">Youtube account</label>
        <div *ngIf="(getYoutubeAcc?.dirty || getYoutubeAcc?.touched) && getYoutubeAcc?.invalid">
          <small *ngIf="getYoutubeAcc.errors.pattern" class="red-text"> Wrong Youtube url format </small>
        </div>
      </div>
      <div class="input-field col s12" *ngIf="showYoutubeAcc">
        <p>Show Youtube Account To :</p>
        <div class="col s12 m6 l4">
          <label>
            <input type="radio" value="Public" formControlName="showYoutubeAcc" />
            <span class="text"><strong>General Public</strong></span>
          </label>
        </div>
        <div class="col s12 m6 l4">
          <label>
            <input type="radio" value="Friends" formControlName="showYoutubeAcc" />
            <span class="blue-text"><strong>Friends Only</strong></span>
          </label>
        </div>
        <div class="col s12 m6 l4">
          <label>
            <input type="radio" value = 'Private' formControlName="showYoutubeAcc" />
            <span class="red-text"><strong>Do Not Show</strong></span>
          </label>
        </div>

      </div>
    </form>
    </div>
  </div>
</li>



    <li>
      <div class="collapsible-header"><i class="material-icons pink-text">assured_workload</i><strong>Work</strong></div>
      <div class="collapsible-body">
       <div class="row" *ngIf="user">

        <div class="row col s12">
          <a (click)="openWorkplaceModal()" class="green-text"><i class="material-icons">add_circle_outline</i><strong>Add New
            Work Experience</strong> </a>
        </div>


          <div *ngIf="user.workplaces">

              <div *ngFor="let workplace of user.workplaces" class="col s12 m6">
                <div class="row card-panel col s12">
                  <div class="row col s12">
                    <br />
                    <span *ngIf="workplace.company"><strong class="pink-text">Company : </strong> {{workplace.company}} </span> <br />
                    <span *ngIf="workplace.position"><strong class="pink-text">Position : </strong> {{workplace.position}} </span>
                    <br />
                    <span *ngIf="workplace.cityOrTown"><strong class="pink-text">City : </strong> {{workplace.cityOrTown}} </span>
                    <br />
                    <span *ngIf="workplace.description"><strong class="pink-text">Job Description : </strong>
                      {{workplace.description}} </span> <br />
                    <span *ngIf="workplace.fromDate"><strong class="pink-text">Duration : </strong> {{workplace.fromDate}} -
                      {{workplace.toDate}} </span>

                  </div>

                  <div class="row col s12">
                    <button class="btn-small red right btn-floating" (click)="deleteWorkplace(workplace)"><i class="material-icons"
                        style="display: block;">delete</i></button>
                    <button class="btn-small blue right btn-floating" style="margin-right: 15px;"
                      (click)="editWorkplace(workplace)"><i class="material-icons" style="display: block;">edit</i></button>
                  </div>
                </div>
              </div>



          </div>
          <div class="col s12">
            <br />
            <div class=" switch">
              <label>
                ` <span><strong class="black-text">Show to public </strong></span>
                <span class="red-text" style="margin-left: 25px;"><strong>No</strong> </span>
                <input type="checkbox" [checked]="showWorkplace" (change)="checkedShowWorkplace()">
                <span class="lever"></span>
                <span class="green-text"><strong>Yes</strong></span>
              </label>
            </div>

          </div>
        </div>
      </div>
    </li>

    <li>
      <div class="collapsible-header"><i class="material-icons blue-text">auto_stories</i><strong>Education</strong></div>
      <div class="collapsible-body">
        <div class="row">
          <div *ngIf="user">
            <div class="row col s12">
              <a (click)="openEducationModal()" class="green-text"><i class="material-icons">add_circle_outline</i><strong>Add
                education</strong> </a>
            </div>
            <div *ngIf="user.educations">

              <div *ngFor="let education of user.educations" class="col s12 m6">

                <div class="row card-panel col s12">

                  <div class="row col s12">
                    <br />
                    <span *ngIf="education.school"><strong class="blue-text">School : </strong> {{education.school}} </span>
                    <br />
                    <span *ngIf="education.level"><strong class="blue-text">Grade : </strong> {{education.level}} </span>
                    <br />
                    <span *ngIf="education.fromDate"><strong class="blue-text">Duration : </strong> {{education.fromDate}} -
                      {{education.toDate}} </span>
                  </div>

                  <div class="row col s12">
                    <button class="btn-small red right btn-floating" (click)="deleteEducation(education)"><i
                        class="material-icons" style="display: block;">delete</i></button>
                    <button class="btn-small blue right btn-floating" style="margin-right: 15px;"
                      (click)="editEducation(education)"><i class="material-icons" style="display: block;">edit</i></button>
                  </div>



                </div>

              </div>
            </div>

            <div class="col s12">
              <br />
              <div class=" switch">
                <label>
                  ` <span><strong class="black-text">Show to public </strong></span>
                  <span class="red-text" style="margin-left: 25px;"><strong>No</strong> </span>
                  <input type="checkbox" [checked]="showEducation" (change)="checkedShowEducation()">
                  <span class="lever"></span>
                  <span class="green-text"><strong>Yes</strong></span>
                </label>
              </div>


            </div>

          </div>
        </div>
      </div>
    </li>


  </ul>

</div>

<div class="row col s12">
  <div class="col l1 "></div>
  <div class="col s12">
    <button title="Search" (click)="onUpdateUserProfile()" class = "col s12 btn waves-effect green" style="margin-bottom: 15px;" [disabled]="!updateProfileForm.valid">
            <i class="material-icons right">cloud_upload</i> Update Profile
    </button>
    <a [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backClicked()"><i
        class="material-icons left">arrow_back_ios_new</i> Back To My Profile</a>
  </div>
  <div class="col l1"></div>
</div>
</div>

<div id="imgModal" class="modal">
  <div class="modal-content">
    <img loading="lazy" [src]="imgLinkToBeEdited" style="width: 100px; height: 100px;" />
  </div>
  <div class="modal-footer">
    <button class="btn" (click)="makeImgProfile()">Make Profile</button>
    <button class="btn" (click)="removeImage()">Remove Image</button>
    <button class="btn modal-close waves-effect waves-green">Close</button>
  </div>
</div>


<div id="addPlacesLivedModal" class="modal">
  <div class="modal-content">
    <form [formGroup]="placesLivedForm" (submit)="addPlacesLived(placesLivedForm.value)">
      <div class="input-field col s12">
        <i class="material-icons prefix purple-text">location_city</i>
        <input id="city" type="text" formControlName='city' class="validate"/>
        <label class="active" for="city" >City </label>
      </div>
      <div class="input-field col s12">
        <i class="material-icons prefix purple-text">date_range</i>
        <input id="fromdate" type="date" formControlName='dateMoved' class="validate"/>
        <label class="active" for="fromdate" >Date Moved </label>
      </div>

      <div class="modal-footer">
        <button class="btn green" type="submit" [disabled]="!placesLivedForm.valid">Save</button>
        <button class="btn red modal-close" type="button">Cancel</button>
      </div>

    </form>
  </div>

</div>

<div id="addContactAndBasicInfoModal" class="modal">
  <div class="modal-content">
    <form [formGroup]="contactAndBasicInfoForm" (submit)="addContactAndBasicInfo(contactAndBasicInfoForm.value)">

      <div class="input-field col s12 m6">
        <i class="material-icons prefix purple-text">email</i>
        <input id="email" type="text" formControlName='email' class="validate"/>
        <label class="active" for="email" >Email </label>
      </div>

      <div class="input-field col s12 m6">
        <i class="material-icons prefix purple-text">phone_iphone</i>
        <input id="phoneno" type="text" formControlName='mobilePhone' class="validate"/>
        <label class="active" for="phoneno" >Mobile </label>
      </div>

      <div class="input-field col s12 m6">
        <i class="material-icons prefix purple-text">public</i>
        <input id="website" type="text" formControlName='website' class="validate"/>
        <label class="active" for="webiste" >Website </label>
      </div>

      <div class="input-field col s12 m6">
        <i class="material-icons prefix purple-text">call</i>
        <input id="workPhone1" type="text" formControlName='workPhone1' class="validate"  />
        <label class="active" for="workPhone1" >Phone 1 </label>
      </div>

      <div class="input-field col s12 m6">
        <i class="material-icons prefix purple-text">call</i>
        <input id="workPhone2" type="text" formControlName='workPhone2' class="validate"  />
        <label class="active" for="workPhone2" >Phone 2 </label>
      </div>

      <div class="modal-footer">
        <div class="row">
        <button class="btn" type="submit" style="margin-right: 15px;" [disabled]="!contactAndBasicInfoForm.valid">Save Changes</button>
        <button class="btn modal-close" type="button">Cancel</button>
      </div>
      </div>

    </form>
  </div>
</div>



<div id="addWorkplaceModal" class="modal">
  <form [formGroup]="workplaceForm" (submit)="addWorkplace(workplaceForm.value)">
  <div class="modal-content">
    <h6>Work Experience</h6>
    <br/>
    <div class="row col s12">
      <div class="input-field col s12 m6">
        <i class="material-icons prefix purple-text">business</i>
        <input id="workplacename" type="text" formControlName='company' class="validate"/>
        <label  for="workplacename" >Company Name</label>
      </div>

      <div class="input-field col s12 m6">
        <i class="material-icons prefix purple-text">attribution</i>
        <input id="title" type="text" formControlName='position' class="validate"/>
        <label  for="title" >Job Title</label>
      </div>

      <div class="input-field col s12 m6">
        <i class="material-icons prefix purple-text">location_on</i>
        <input id="worklocation" type="text" formControlName='cityOrTown' class="validate"/>
        <label  for="worklocation" >City / Town</label>
      </div>

      <div class="input-field col s12 m6">
        <i class="material-icons prefix purple-text">description</i>
        <input id="description" type="text" formControlName='description' class="validate"/>
        <label  for="description" >Description</label>
      </div>
      <div class="input-field col s12 m6">
        <i class="material-icons prefix purple-text">date_range</i>
        <input id="fromdate" type="text" formControlName='fromDate' class="validate datepicker"/>
        <label  for="fromdate" >From </label>
      </div>



      <div class="input-field col s12 m6">
        <i class="material-icons prefix purple-text">date_range</i>
        <input id="enddate" type="text" formControlName='toDate' class="validate datepicker"/>
        <label  for="enddate" >End date</label>
      </div>

      <div class="col s12">
        <label>
          <input type="checkbox" formControlName='currentlyWorkingHere' />
          <span>I currently work here</span>
        </label>
      </div>
    </div>

  </div>
  <div class="modal-footer">
    <div class="row">
      <button class="btn green" type="submit" style="margin-right: 15px;" [disabled]="!workplaceForm.valid">Save changes</button>
      <button class="btn red modal-close" type="button">Cancel</button>
    </div>
  </div>
  </form>
</div>

<div id="addEducationModal" class="modal">
  <form [formGroup]="educationForm" (submit)="addEducation(educationForm.value)">
  <div class="modal-content">
    <h6>Education</h6>
    <div class="input-field col s12">
      <i class="material-icons prefix purple-text">apartment</i>
      <input id="school" type="text" formControlName='school' class="validate"/>
      <label  for="school" >School Name</label>
    </div>

    <div class="input-field col s12">
      <i class="material-icons prefix purple-text">school</i>
      <input id="level" type="text" formControlName='level' class="validate"/>
      <label for="level" >Level / Grade</label>
    </div>

    <div class="input-field col s12">
      <i class="material-icons prefix purple-text">account_circle</i>
      <input id="fromdateschool" type="date" formControlName='fromDate' class="validate"/>
      <label  for="fromdateschool" >From </label>
    </div>

    <div class="input-field col s12">
      <i class="material-icons prefix purple-text">date_range</i>
      <input id="enddate" type="date" formControlName='toDate' class="validate"/>
      <label  for="enddate" >End date</label>
    </div>

    <div class="input-field col s12">
      <input type="checkbox" formControlName='learningHere' />
      <label  for="learningHere" >I currently Study here</label>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row">
      <button class="btn green" type="submit" style="margin-right: 15px;" [disabled]="!educationForm.valid">Save Changes</button>
      <button class="btn red modal-close" type="button">Cancel</button>
    </div>
  </div>
  </form>
</div>