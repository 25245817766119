import { AreasOfInterestService } from '../../../../services/firebase/areas-of-interest.service';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Form, FormBuilder, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { Posts } from 'src/app/models/PostsModel';
import { PostsService } from 'src/app/services/firebase/posts.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/internal/operators/filter';
// import { Router } from '@angular/router';
import { ImageModel, ImageUploaderResponseModel } from 'src/app/models/ImageModel';
import { Location} from '@angular/common';
import { DepartmentService } from 'src/app/services/firebase/department.service';
import { ProductsServiceMenuService } from 'src/app/services/api/data.service';
import { FCMPushNotificationsService } from 'src/app/services/firebase/FCMPushNotifications.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { HelperService } from 'src/app/services/helper/helper';
import { PermissionService } from 'src/app/services/firebase/permission.service';
import { ShareDataModel } from 'src/app/models/SocialMediaPostModel';
import { NotificationModel } from 'src/app/models/Messages.Model';
import { UserService } from 'src/app/services/firebase/user.service';
// declare var navigator: any;
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectAppSettings } from 'src/app/state/app.selectors';
import { ChangeVisibilityService } from 'src/app/services/firebase/change-visibility.service';

@Component({
  selector: 'app-create-blog',
  templateUrl: './create-blog.component.html',
  styleUrls: ['./create-blog.component.css']
})
export class CreateBlogComponent implements OnInit {

  @Input() calledFromEmptyDashboard:string;
  @Output() closeCollapse = new EventEmitter<string>();
  @Output() blogCreated = new EventEmitter<string>();
  @Output() skip = new EventEmitter<string>();
  @Output() previewClosed = new EventEmitter<string>();
  @Output() previewSelected = new EventEmitter<string>();
  @Output() goToTopOfNextPage = new EventEmitter();

  imageUrl = '';
  NewPostForm: UntypedFormGroup;
  selectedFilesForDisplay: any = [];
  currentUser: any;
  id: any;
  base64Image: any;
  binDoc: File;
  isMobile = false;
  mobileBase64Image: any;
  interestAreas = [];
  selectedInterestAreas = [];
  departments = [];
  selectedDepartments = [];
   // toast
   

  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  videoUpload: File = null;
  videoLink: string= null;
  imageLink: string = null;
  blogsName = 'Blogs';
  attachmentFile: any;
  cancelModalOpen = false;
  notifSettings;
  route = '';
  permission: any;
  haveAccessPermission = false;
  preview = false;
  modelToPreview:any = {}
  createModalOpen = false;
  imgErrorMsg: any;
  attachedVideo: any;
  cropModalOpen = false;
  imageChangeEvt: any = "";
  cropImgPreview: any;
  imageChangedEvent: any = '';
  videoType ='';
  uploadedDocument : File;
  newPostValues: any = Posts;
  shareToSocialMedia=false
  shareData: ShareDataModel = new ShareDataModel()
  perm:any;
  permMember:any;
  permSocial:any;
  permPES:any;
  permSpecial:any;
  permAnalytics:any;
  useBase64=false
  allUsers=[]
  videoUploaded = false;
  blogLinkClicked = false;
  uploadingMedia = false;
  uploadFileModalOpen = false;
  uploadStatus = 'Pending';
  mediaList = [];
  returnedMediaLinks = {
    imageURL: '',
    videoUrl: '',
    pdfUrl: ''
  };
  pageSections: any;
  blogsRef: any;
  onLoading = false;
  appSettings$: Subscription;


  constructor(private fb: UntypedFormBuilder, private router: Router,
              private authService: AuthService,
              private postsService: PostsService,
              private location: Location,
              private areasOfInterestService: AreasOfInterestService,
              private departService: DepartmentService,
              private productsServiceMenuService: ProductsServiceMenuService,
              private pushNotificationsService: FCMPushNotificationsService,
              private helperService: HelperService,
              private permissionService: PermissionService,
              private userService: UserService,
               private settingsService: AppSettingsService,
              private store: Store,
              private changeVisibilityService: ChangeVisibilityService

              ) {
                this.appSettings$ = this.store.select(selectAppSettings).subscribe((settings) => { 
                  this.pageSections = settings;          
                  this.blogsRef = this.pageSections.appSections.filter(s => s.originalName === 'Blogs')[0];           
                
                });
                if (typeof window['Capacitor'] !== 'undefined' && window['Capacitor']['platform'] !== 'web' ) {
                  this.isMobile = true;
                }
                 // googleAnalyticsService.startTracking();
              // to check which route when navigating
              router.events.pipe(filter(event => event instanceof NavigationEnd)
              ).subscribe((event: NavigationEnd) => {
                if (location.path() !== '') {
                  this.route = location.path();
                  // gtag('event', 'page_view', { page_path: event.urlAfterRedirects});

                }
              });

  }

  ngOnInit(): void {
    this.currentUser = this.authService.getGlobalUser();
    if (this.currentUser) {
      this.id = this.currentUser.uid;
      if (this.currentUser.role === 'Employee'){
        this.permissionService.getPermissionByEmployeeId(this.currentUser.uid).valueChanges().subscribe((perm: any) => {
          if (perm && perm.length > 0){
            this.perm = perm[0];
            this.permMember = this.perm.permission[0]
            this.permPES = this.perm.permission[1]
            this.permAnalytics = this.perm.permission[2];
            this.permSocial = this.perm.permission[3];
            this.permSpecial = this.perm.permission[4];
            if (this.permPES.createPosts){
              this.haveAccessPermission = true;
            }
          }
        });
      }
      if (this.currentUser.role === 'Admin'){
        this.haveAccessPermission = true;
      }
    }
    this.NewPostForm = this.fb.group({
      postTitle: ['', Validators.required],
      message: ['', Validators.required],
      sharedLink: [''],
      shareWith: ['', Validators.required],
      commentable: [true],
      readingTime: [1],
      opened: ['']
    });

    this.areasOfInterestService.getAreasOfInterest().valueChanges().subscribe((areas: any) => {
      if (areas.length > 0){
        this.interestAreas = areas;
      }
    });
    this.productsServiceMenuService.blogsName.subscribe((blogsname) => {
      this.blogsName = blogsname;
    });

    this.departService.getDepartment().valueChanges().subscribe(a => {
      if (a) {
        this.departments = a;
      }
    });
    if(this.currentUser && this.currentUser.role == 'Employee'){
      this.settingsService.getPushNotificationSettingsList().valueChanges().subscribe(x=>{
        if(x && x.length > 0){
          this.notifSettings=x[0]
        }
      })
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.previewChanges(this.NewPostForm.value)
  }

  get message(){ return this.NewPostForm.get('message'); }
  get postTitle(){ return this.NewPostForm.get('postTitle'); }
  get shareWith(){ return this.NewPostForm.get('shareWith'); }
  get mediaLink() { return this.NewPostForm.get('mediaLink'); }
  get sharedLink() { return this.NewPostForm.get('sharedLink'); }
  get opened(){ return this.NewPostForm.get('opened'); }

  truncateHTML(text: string, limit: string): string {
    return this.helperService.truncateHTML(text,limit)
  }

  sendPushNotification(model){
    // let title = this.truncateHTML(model.postTitle,'200')
    let notification = new NotificationModel()
    notification.title='Blog'
    notification.body=this.truncateHTML(model.postTitle,'200')
    this.pushNotificationsService.sendNotificationByRole(notification,'User')

    // this.pushNotificationsService.sendNotificationToAllTheMembers('Blog',title,'User_Notification')

    // send Notif to admin
    if(this.notifSettings && this.currentUser && this.currentUser.role === "Employee"){
      this.sendPushNotificationToOwner()
    }
  }

  sendPushNotificationToOwner(){
    let conditions=["blog","employeeChange"]
    if(this.helperService.checkNotificationForOwner(this.notifSettings,conditions)){
      let  createdByFullName= this.currentUser.GivenName + ' ' + this.currentUser.LastName

      let notification = new NotificationModel()
      notification.title='Employee Created a Blog'
      notification.body=`${createdByFullName} created a new blog post`
      this.pushNotificationsService.sendNotificationByRole(notification,'Admin')

      // this.pushNotificationsService.sendNotificationToAllTheMembers('Employee Created a Blog',`${createdByFullName} created a new blog post`,'Owner_Notification')
      
    }
  }

  getVideoType(url: string){
    const types = new Map([["jpg", "img"], ["gif", "img"], ["mp4", "video"], ["3gp", "video"], ["ogp", "video"]])
    const extension = url.split(".")[1];
    const element = document.createElement(types.get(extension));
  }

  newPost(value) {
    this.createModalOpen = false;
    this.onLoading = true
    const newPost = new Posts();
    
    newPost.createdById = this.id;
    newPost.postTitle = value.postTitle;
    newPost.sharedLink = value.sharedLink;
    newPost.message = value.message;
    newPost.shareWith = value.shareWith;
    newPost.timeStamp = new Date();
    newPost.areas = this.selectedInterestAreas;
    newPost.departments = this.selectedDepartments;
    newPost.commentable = value.commentable;
    newPost.readingTime = this.calculateWordCount(value.message);
    newPost.mediaLink = this.imageLink;
    newPost.videoLink = this.videoLink;
    newPost.opened = value.opened ? value.opened : [];
    newPost.mediaUpdates = {
      image: '',
      video: '',
      pdf: ''
    }
    newPost.reactions = {
    likes: {
        count: 0,
        userId: [],
        usernames: []
      }
    };
    newPost.comments = [];

    const newAttachment = this.selectedFilesForDisplay[0];
    this.newPostValues = newPost;
    if(this.mediaList.length > 0){
        this.uploadFileModalOpen = true;
    }

    if(this.mediaList.length === 0){
      this.createPost(this.newPostValues)
    }

    if (this.newPostValues.mediaLink && this.newPostValues.mediaLink !== ''){
      this.shareData.imageUrls = [this.newPostValues.mediaLink]
    }
  }

  createPost(newPost){
    if(this.calledFromEmptyDashboard){
      this.changeVisibility();
    }
    
    this.postsService.createPost(newPost).then(() => {
      this.uploadFileModalOpen = false;
      this.sendPushNotification(newPost)

      if(newPost.shareWith.toLowerCase()=== 'public'){
        if(this.calledFromEmptyDashboard){
          this.changeVisibility();
        }
        this.shareToSocialMedia=true
        this.sharePost(newPost)
      }
      this.toast({html: 'Successfully  posted a blog!', classes: 'green', type: 'success'});
      this.onLoading = false
      if(this.calledFromEmptyDashboard == 'Blog'){
        this.closeCollapse.emit('createBlog');
        this.blogCreated.emit('blogCreated');
        this.goToTopOfNextPage.emit();
      }

    }).catch(err => {
      this.toast({html: 'Can\'t create blog!', classes: 'red', type: 'failure'});
      this.onLoading = false
    });

  }

  changeVisibility(){
    this.changeVisibilityService.updateAppSections('Blogs');
  }

  cancelShare(){
    this.shareToSocialMedia=false
    if(this.calledFromEmptyDashboard != 'Blog'){
      this.backClicked()
    }
    // else if(this.calledFromEmptyDashboard == 'Blog'){
    //   this.closeCollapse.emit('renameBlog');
    // }
  }

  sharePost(newPost){
    this.shareData.share=true
    this.shareData.post = newPost.message
    if (newPost.videoLink && newPost.videoLink !== ''){
      this.shareData.videoUrls = [newPost.videoLink]
    }

    if (newPost.mediaLink && newPost.mediaLink !== ''){
      this.shareData.imageUrls = [newPost.mediaLink]
    }
  }

  cancelFile(i: any) {
    this.selectedFilesForDisplay.splice(i, 1);
  }

  // identify type of input given
  private validateInput(name: any) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() === 'png') {
      return 'image';
    } else if (ext.toLowerCase() === 'jpg') {
      return 'image';
    } else if (ext.toLowerCase() === 'jpeg') {
      return 'image';
    } else if (ext.toLowerCase() === 'mp4' || ext.toLowerCase() === 'mkv') {
      return 'video';
    } else if (ext.toLowerCase() === 'pdf') {
      return 'pdf';
    } else {
      return false;
    }
  }

  // when file is selected
  onSelectFiles(files: FileList) {
    const FileSize = files[0].size / 1024 / 1024; // in MiB
    if (FileSize > 300) {
      this.toast({ html: 'Selected file size exceeds 300 MiB.', classes: 'red', type: 'failure' });
      return;
    }

    const ext = this.validateInput(files[0].name);

    if (!ext){
      return;
    }
    const attachment = {type: ext, name: files[0].name };
    if (ext === 'image'){
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        this.base64Image = reader.result as string;
        attachment['link'] = this.base64Image.replace(/^data:image\/[a-z]+;base64,/, '');
        this.selectedFilesForDisplay.push(attachment);
      };
    } else {
      attachment['link'] = files[0];
      this.selectedFilesForDisplay.push(attachment);
      if (ext === 'video'){
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = () => {
          this.attachmentFile = reader.result as string;
        };
      }
    }
  }

  onImageUpload(response:ImageUploaderResponseModel) {
    if (response.error){
      this.imgErrorMsg = response.errorMessage
      this.mediaLink.patchValue('');
      return
    }

    if (response.isMobile){
      this.binDoc = response.binDoc;
      this.imageChangedEvent = 'data:image/jpeg;base64,' + response.imgBase64;
      this.mobileBase64Image = response.imgBase64;
      this.useBase64=true
      // this.cropModalOpen = true;
    }
    else{
      this.base64Image = response.imgBase64;
      this.useBase64 = true;
      this.processCroppedImage(this.base64Image);
    }


 }

  handleVideoInput(files: FileList) {
    this.videoUploaded = true;
    this.videoUpload = files[0];
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.attachedVideo = reader.result as string;
    };
    this.mediaList.push({type:'Video', file: this.videoUpload});
  }

  validateImage(name: any) {
    return this.helperService.validateImage(name);
   }

  dataURItoBlob(dataURI) {
    return this.helperService.dataURItoBlob(dataURI);
  }

  backClicked(){
    this.location.back();
  }

  onShareChange(userType){
    if(userType ==='Public'){
      this.selectedDepartments=[]
      this.selectedInterestAreas=[]
    } else if(userType === 'User'){
      this.selectedDepartments=[]
    }
  }

  onChange(event, interest){
  if (interest === 'selectAll'){
    this.selectedInterestAreas = [];
    if (event.target.checked){
     this.interestAreas.forEach(element => {
       this.selectedInterestAreas.push(element.id);
      });
    } else {
      this.selectedInterestAreas = [];
    }
  } 
  else {
    if (this.selectedInterestAreas.find(selected => selected === interest.id )) {
      const index = this.selectedInterestAreas.indexOf(this.selectedInterestAreas.find(selected => selected === interest.id));
      this.selectedInterestAreas.splice(index, 1);
    } else {
      this.selectedInterestAreas.push(interest.id);
    }
  }
  }

  onDepartmentChange(event, department){
    if (department === 'selectAll'){
      this.selectedDepartments = [];
      if (event.target.checked){
      this.departments.forEach(element => {
        this.selectedDepartments.push(element.id);
        });
      } else {
        this.selectedDepartments = [];
      }
    } else {
    if (this.selectedDepartments.find(selected => selected === department.id )) {
      const index = this.selectedDepartments.indexOf(this.selectedDepartments.find(selected => selected === department.id));
      this.selectedDepartments.splice(index, 1);
    } else {
      this.selectedDepartments.push(department.id);
    }
    }
  }
  
  removeImage(){    
    this.base64Image = undefined;
    this.mediaList = this.mediaList.filter((file)=> {
      if(file.type !== 'Image'){
        return file;
      }
    })
  }

  removeVideo(){
    this.videoUpload = undefined;
    this.attachedVideo = undefined;
    this.mediaList = this.mediaList.filter((file)=> {
      if(file.type !== 'Video'){
        return file;
      }
    })
  }
  
  additionalLink(){
    this.blogLinkClicked = !this.blogLinkClicked;
  }

  calculateWordCount(text) {
    return this.helperService.calculateWordCount(text);
  }
  
  openCancelModal(){
    this.cancelModalOpen = true;
  }

  closeCancelModal(){
    this.cancelModalOpen = false;
    if(this.calledFromEmptyDashboard == 'Blog'){
      this.closeCollapse.emit('createBlog');
    }
  }

  closeCreateModal(){
    this.createModalOpen = false;
  }

  closeCroppingModal(){    
    this.cropModalOpen = false;
    this.useBase64 = false;
  }

  fileChangeEvent(event){
    this.imageChangedEvent = event;
    // this.cropModalOpen = true;

    if (!this.validateImage(event[0].name)) {
      this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';
      this.imageUrl ='';
      return;
    }
    this.binDoc = event[0];
    const reader = new FileReader();
    reader.readAsDataURL(event[0]);
    reader.onload = () => {
      this.base64Image = reader.result as string;
      this.useBase64 = true;
      this.processCroppedImage(this.base64Image);
    };
  }

  getTheUrlsSaved(event){
    this.returnedMediaLinks = event;
    this.uploadFileModalOpen = false;
    this.newPostValues.mediaLink = this.returnedMediaLinks.imageURL;
    this.newPostValues.videoLink = this.returnedMediaLinks.videoUrl;
    this.newPostValues.pdfLink = this.returnedMediaLinks.pdfUrl;
    
    this.createPost(this.newPostValues);
  }

  processCroppedImage(event){
    this.cropModalOpen = false;
    this.base64Image = event;
    let base64result = this.base64Image.split(',')[1];
    this.useBase64 = false;
    const img = new ImageModel();
   
    if(this.isMobile){
      img.imgBase64 = this.mobileBase64Image;
      this.mediaList.push({type:'Image', file: img});
    }else{
      img.imgBase64 = base64result; 
      this.mediaList.push({type:'Image', file: img});
    }        
  }

  previewChanges(value){
    // this.modelToPreview = {};
    this.modelToPreview.createdById = this.id;
    this.modelToPreview.postTitle = value.postTitle;
    this.modelToPreview.sharedLink = value.sharedLink;
    this.modelToPreview.message = value.message;
    this.modelToPreview.shareWith = value.shareWith;
    // this.modelToPreview.timeStamp = Date.now();
    this.modelToPreview.areas = this.selectedInterestAreas;
    this.modelToPreview.departments = this.selectedDepartments;
    this.modelToPreview.commentable = value.commentable;
    this.modelToPreview.opened = value.opened;
    this.modelToPreview.readingTime = this.calculateWordCount(value.message);
    this.modelToPreview.rreactions = {likes: {usernames: [], count: 0, userId: []}}
    if (this.base64Image){
      this.modelToPreview.mediaLink = this.base64Image;
    }
    if (this.attachmentFile){
      this.modelToPreview.mediaLink = this.attachmentFile;
    }
    if (this.attachedVideo){
      this.modelToPreview.videoLink = this.attachedVideo;
    }
    // this.preview = true;
    // return this.modelToPreview
    if(this.calledFromEmptyDashboard == 'Blog'){
      this.previewSelected.emit()
    }
  }

  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
      if(!this.shareToSocialMedia){
        this.backClicked();
      }
    }, 2000);
  }
  skipSetup(){
    this.skip.emit();
  }

  closePreviewModal(){
    if(this.calledFromEmptyDashboard == 'Blog'){
      this.previewClosed.emit()
    }
  }
}
