import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ImageModel } from 'src/app/models/ImageModel';
import { MediaService } from 'src/app/services/api/media.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { UserService } from 'src/app/services/firebase/user.service';
import MyCustomUploadAdapterPlugin from '../../admin/uploadAdapter.js';
import { ClientAnswersService } from 'src/app/services/firebase/client-answers.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {
  GivenName: any;
  LastName: any;
  Email: any;
  JobTitle: any;
  currentUser: any;
  EditProfileForm: UntypedFormGroup;
  isEdit = false;
  user: any;
  mediaType: any;
  binDoc = undefined;
  base64Image = undefined;
  mobileBase64Image: any;
  isMobile = false;
  model: any;
  imgURL: any;
  imgErrorMsg: any;
  pdfErrorMsg: any;
  loggedUser: any;
  id: any;
  currentUserId: any;
  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  biographyForm: UntypedFormGroup;

  //to UnSubscribe
  employeeUser: any;
  removePicture: boolean;


  public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')',
  ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

  editorConfig = {
    removePlugins: ['EasyImage', 'Image', 'ImageCaption', 'ImageStyle', 'ImageToolbar', 'ImageUpload', 'MediaEmbed'],
    extraPlugins: [MyCustomUploadAdapterPlugin]
  };

  saveChangeModal: boolean = false;
  updateModal: boolean = false;

  constructor(private mediaService: MediaService,
              private route: ActivatedRoute,
              private userService: UserService,
              private authService: AuthService,
              private router: Router,
              private location: Location,
              private fb: UntypedFormBuilder,
              private clientAnswerService: ClientAnswersService) { }

  ngOnInit(): void {
    this.currentUser = this.authService.getGlobalUser();
    this.route.paramMap.subscribe(params => {
      this.currentUserId = params.get('id');
    });

    this.EditProfileForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      cellPhone: [''],
      email: ['', [Validators.required, Validators.email]],
      imgLink:[''],

    });

    this.biographyForm = this.fb.group({
      biography: [''],
    });

    this.userService.getUserById(this.currentUserId).valueChanges().subscribe(
      users => {
        this.user = users[0];
        this.EditProfileForm.patchValue({
          firstName: this.user.GivenName ? this.user.GivenName : this.currentUser.GivenName,
          lastName: this.user.LastName ? this.user.LastName : this.currentUser.LastName,
          email: this.user.Email ? this.user.Email : this.currentUser.Email,
          cellPhone: this.user.cellPhone ? this.user.cellPhone : this.currentUser.cellPhone,
          imgLink: this.user.profileImgLink ? this.user.profileImgLink : '',

        })

        this.biographyForm.patchValue({
          biography: this.user.biography ? this.user.biography : '',
        });
      }
    );

  }

  edit() {
    this.isEdit = true;
    // admin-profile-settings have to be deleted
    // this.router.navigate(['/admin/admin-profile-settings'])
  }

  public onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
  }

  SubmitRequest(model: any) {
    this.mediaType = 'Photo';
    this.currentUser.GivenName = model.firstName;
    this.currentUser.LastName = model.lastName;
    this.currentUser.Email = model.email;
    this.currentUser.cellPhone = model.cellPhone;
      // if (this.binDoc) {
    if (this.isMobile) {
          const img = new ImageModel();
          img.imgBase64 = this.mobileBase64Image;
          this.mediaService.uploadImage(img).subscribe(upload => {
            if (upload) {
              this.currentUser.profileImgLink = upload.imgLink;
              this.imgURL = upload.imgLink;
            }
          });
        } else {
          if (this.binDoc) {
            const reader = new FileReader();
            reader.readAsDataURL(this.binDoc);
            reader.onload = () => {
              this.base64Image = reader.result as string;
            };
            // setTimeout(() => {
            const img = new ImageModel();
            img.imgBase64 = this.base64Image.replace(/^data:image\/[a-z]+;base64,/, '');
            this.mediaService.UploadBinImage(this.binDoc).subscribe(
              upload => {
                this.currentUser.profileImgLink = upload.imgLink;
                this.imgURL = upload.imgLink;
                this.loggedUser = this.currentUser;
                this.userService.updateUser(this.loggedUser).then(res => {
                  // wont get here since it reloads db/refreshed
                  this.toast({html: 'Your Profile has been Updated!', classes: 'green', type: 'success'});
                });
              });

            // }, 30);
          }
        }
      // }

    if (this.currentUser){
      this.userService.updateUser(this.currentUser).then(res => {
        // wont get here since it reloads db/refreshed
        this.toast({html: 'Your Profile has been Updated!', classes: 'green', type: 'success'});
      });
    }


  }

  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
      // this.backClicked();
    }, 2000);
  }
  SubmitForm(model){
    this.employeeUser = this.userService.getUserById(this.currentUserId).valueChanges().subscribe(
      user => {
        if(user.length > 0){
          this.user = user[0];
          this.user.biography = model.biography;
        }
        this.employeeUser.unsubscribe();
        this.userService.updateUser(this.user).then(res => { })
      }
    )
    
  }

  private validateImage(name: any) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() === 'png') {
      return true;
    } else if (ext.toLowerCase() === 'jpg') {
      return true;
    } else if (ext.toLowerCase() === 'jpeg') {
      return true;
    } else {
      return false;
    }
  }
  uploadPhoto() {
    this.mediaType = 'Photo';
  }

  removeFile() {
    this.binDoc = undefined;
    this.base64Image = undefined;
    this.imgLink.patchValue('');
  }

  handleFileInput(files: FileList) {
    if (!this.validateImage(files[0].name)) {
      this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';
      // this.imgLink.patchValue('');
      return;
    }
    this.binDoc = files[0];
    this.pdfErrorMsg = undefined;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.base64Image = reader.result as string;
    };
  }

  truncateHTML(text: string, limit: string): string {
    const charlimit = +limit;
    if (!text || text.length <= charlimit) {
      return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ');
    }
    return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ').substring(0, charlimit) + '...';
  }



  deleteUser(currentUser){
    this.authService.deleteUser(currentUser);
    this.clientAnswerService.deleteClient(currentUser);
    this.userService.deleteUserAccount(currentUser.uid);
  }

  removeModal(){
    this.removePicture = true;
  }
  closeRemoveModal(){
    this.removePicture = false;
  }
  saveModal(){
    this.saveChangeModal = true;
  }
  closeModal(){
    this.saveChangeModal = false;
  }

  get firstName() { return this.EditProfileForm.get('firstName'); }
  get lastName() { return this.EditProfileForm.get('lastName'); }
  get email() { return this.EditProfileForm.get('email'); }
  get cellPhone() { return this.EditProfileForm.get('cellPhone'); }
  get imgLink() { return this.EditProfileForm.get('imgLink'); }
  get biography() { return this.biographyForm.get('biography'); }

}
