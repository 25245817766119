import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-welcome-popup',
  templateUrl: './welcome-popup.component.html',
  styleUrls: ['./welcome-popup.component.css']
})
export class WelcomePopupComponent implements OnInit {

  @Input() section;
  @Input() nationOfuse;
  constructor() { }
  @Output() closeClicked = new EventEmitter();
  @Output() navigateToRegisterPage = new EventEmitter();
  @Output() navigateToBuildPage = new EventEmitter();

  ngOnInit(): void {
  }
  closeSignupModal(){
    this.closeClicked.emit();
  }
  navigateToRegisterPageClicked(){
    this.navigateToRegisterPage.emit();
  }
  navigateToBuildPageClicked(){
    this.navigateToBuildPage.emit();
  }
}
