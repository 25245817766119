
<div *ngIf="!calledFromComponent || calledFromComponent!=='onboarding'">
  <button onclick="my_modal_1.showModal()" 
  class="flex gap-2 items-center md:ml-5 text-customTeal space-x-2 rounded bg-white px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal border border-customTeal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
  >
    <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 fill-customTeal stroke-customTeal" viewBox="0 0 448 512"><path d="M254 52.8C249.3 40.3 237.3 32 224 32s-25.3 8.3-30 20.8L57.8 416H32c-17.7 0-32 14.3-32 32s14.3 32 32 32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32h-1.8l18-48H303.8l18 48H320c-17.7 0-32 14.3-32 32s14.3 32 32 32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H390.2L254 52.8zM279.8 304H168.2L224 155.1 279.8 304z"/></svg>
    
      <span>Rename {{name.value}}</span>  
  </button>

  <dialog id="my_modal_1" class="modal">
    <div class="modal-box rounded-md md:max-w-3xl">
      <h3 class="font-bold text-xl">Rename {{name.value}}</h3>
      <form [formGroup]="renameForm" (ngSubmit)="saveChanges()">
        <input type="text" id="sectionRename" formControlName="name"
          class="mt-1 px-3 py-2 bg-white border w-full input input-bordered block rounded-md  "
          [placeholder]="name.value"
          [ngClass]="{
            'border-red-500': (name.dirty || name.touched) && name.invalid,
            'border-gray-200': !((name.dirty || name.touched) && name.invalid)
          }" />
        <div *ngIf="(name.dirty || name.touched) && name.invalid">
          <small *ngIf="name.errors.required" class="text-red-500 italic"> Section Name is required. </small>
          <small *ngIf="name.errors.maxlength" class="text-red-500 italic"> Enter upto 30 characters. </small>
        </div>
        <div class="modal-action w-full">
          <form method="dialog w-full">
            <div class="flex space-x-4">
              <button onclick="my_modal_1.close()" class="flex items-center ml-5 space-x-2 rounded bg-white px-6 pb-2 pt-2.5 text-base font-medium uppercase leading-normal border border-customTeal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
                Close
              </button>
              <button [disabled]="name.invalid"
              [ngClass]="{'bg-[#cccccc] text-[#666666]': !name.valid}"
            
              (click)="saveChanges()" onclick="my_modal_1.close()"  class="flex items-center space-x-2 rounded bg-customTeal px-6 pb-2 pt-2.5 text-base font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 textbase ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
               Save
              </button>
            </div>
          </form>
        </div>
      </form>
      
    </div>
  </dialog>

</div>

<div *ngIf="calledFromComponent=='onboarding'">
  <div class="modal-box rounded-md shadow-none md:max-w-3xl">
    <h3 class="font-bold text-xl">Rename {{name.value}}</h3>
    <form [formGroup]="renameForm" (ngSubmit)="saveChanges()">
      <input type="text" id="sectionRename" formControlName="name"
      class="mt-1 px-3 py-2 bg-white border w-full input input-bordered block rounded-md  "
      [placeholder]="name.value"
      [ngClass]="{
        'border-red-500': (name.dirty || name.touched) && name.invalid,
        'border-gray-200': !((name.dirty || name.touched) && name.invalid)
      }"
      />
      <div *ngIf="(name.dirty || name.touched) && name.invalid">
        <small *ngIf="name.errors.required" class="text-red-500 italic"> Section Name is required. </small>
        <small *ngIf="name.errors.maxlength" class="text-red-500 italic"> Enter upto 30 characters. </small>
      </div>
      <div class="modal-action w-full">
        <form method="dialog w-full">
          <div class="flex space-x-4">
            <button [disabled]="name.invalid" 
            (click)="saveChanges()" 
            [ngClass]="{'bg-[#cccccc] text-[#666666]': !name.valid}"
            class="flex items-center space-x-2 text-base rounded bg-customTeal px-6 pb-2 pt-2.5 font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
              Save
            </button>
            </div>
        </form>
      </div>
    </form>
    
  </div>
</div>



<!-- toast -->
<app-alert class="z-50 fixed top-0 flex justify-end w-full" [toastType]="toastType" [toastMessage]="toastMessage" [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>
