import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { EnvironmentService } from 'src/app/services/helper/env';
import {LandingPageComponent} from '../../../shared/landing-page/landing-page.component'

@Component({
  selector: 'app-success-card',
  templateUrl: './success-card.component.html',
  styleUrls: ['./success-card.component.css']
})
export class SuccessCardComponent implements OnInit {
  @Input() name;
  @Input() title;
  @Input() type;
  @Input() displayName: string = '';
  @Input() serviceType: string = '';
  @Input() appointmentType: string = '';
  @Input() sectionPreview: string = '';
  @Input() customSectionID;
  @Output() progressEvent = new EventEmitter<string>();
  @Output() closeModal = new EventEmitter<string>();
  @Output() keepAdding = new EventEmitter<string>();
  @Output() preview = new EventEmitter<string>();
  @Output() setup = new EventEmitter<string>();
  @Output() goToTopOfNextPage = new EventEmitter();


  @ViewChild(LandingPageComponent) childComponent: LandingPageComponent;
  element: HTMLElement
 
  nextStep  
  nextStepName
  closeButton
  openButton
  fromNationApp = this.envService.fromFNLeaders
  showPages = true
  messages = {
    'Content': 'Content Created',
    'Socials': 'Socials Added',
    'Contact': 'Contact Added',
    'Employee': 'Employee Invited',
    'employeeInvited': 'Employee Invited',
    'appointmentSetting': 'Appointment Setup Saved',
    'generalSetupSaved': 'General Setup Saved',
    'brandSetupSaved': 'Brand Setup Saved',
    'customPageCreated': 'Custom Page Created',
    'carouselCreated': 'Carousel Image Added',
    'welcomePopupCreated': 'Welcome Setting Saved',
    'footerCreated': 'Contact Info Saved',
    'socialsCreated': 'Social Media Links Saved',
    'aboutCreated': this.displayName+' Added',
    'eventCreated': this.displayName+' Created',
    'serviceCreated': this.displayName+' Created',
    'productCreated': this.displayName+' Created',
    'blogCreated': this.displayName+' Created',
    'customElementCreated':'custom element created',
    'smsCustomized': 'SMS customization saved',
    'emailCustomized': 'Email customization saved',
    'faqCreated': "FAQ Added",
    'seoCreated': "SEO Saved",
    'chooseNumber':'SMS setting saved',
    'email': 'Email signature saved',
    'smsCustom': 'SMS content customization saved',
    'sms': 'SMS signature saved',
    'seo': 'SEO Setup saved'
    
  };
  sectionsThatCanBeRenamed = ['aboutCreated', 'eventCreated', 'serviceCreated', 'productCreated', 'blogCreated'];

  stepsMapping= {
    'heroCreated': { step: 'popup', name: 'Welcome Popup' },
    'carouselCreated': { step: 'popup', name: 'Welcome Popup' },
    'welcomePopupCreated': { step: 'contact', name: 'Contact Information' },
    'footerCreated': { step: 'linkSocial', name: 'Link Social Media' },
    'socialsCreated': { step: 'employee', name: 'Employee' },
    'seoCreated': { step: 'slider', name: 'Carousel Setup'},
    'employeeInvited': { step: 'appointment', name: 'Appointment Setting' },
    'Services': { step: 'addService', name: 'Add New' },
    'serviceCreated': { step: 'Event', name: 'Events' },
    'About Us': { step: 'addAbout', name: 'Add New' },
    'Products': { step: 'addProduct', name: 'Add New' },
    'productCreated': { step: 'Service', name: 'Services' },
    'Events': { step: 'addEvent', name: 'Add New' },
    'eventCreated': { step: 'Blog', name: 'Blogs' },
    'Blogs': { step: 'addBlog', name: 'Add New' },
    'blogCreated': { step: 'Audience', name: 'Audience Engagement' },
    'chooseNumber': {step: 'smsCustom', name: 'Email signature'},
    // 'email': {step: 'sms', name: 'SMS content customization'},
    'smsCustom': { step: 'smsCustom', name: 'SMS signature' },
    'seo': {step:'seo', name:'SEO Setup'},
    'sms': { step: 'FAQ', name: 'Add FAQ with answers' },
    'faqCreated': { step: 'Custompage', name: 'Add Custom page'},
    'aboutCreated': { step: 'Product', name:  this.serviceType === 'Product' || this.serviceType === 'Product' ? this.serviceType : "Events" },
    'generalSetupSaved': { step: 'generalSetup', name: 'Brand Setup' },
    'brandSetupSaved': { step: 'seo', name: 'Seo Settings' },
    'customPageCreated': { step: 'addCustom', name: 'Add New' },
    'customElementCreated': { step: 'social', name: 'Social Media Integration' },
    'appointmentSetting': { step: 'employee', name: 'About Us' },
  };
  constructor(
    private envService: EnvironmentService

  ) { }

  ngOnInit(): void {

 console.log('apppp ', this.appointmentType)
  }
  ngOnChanges(changes: SimpleChanges) {
    // Handle changes to yourInputData here
    if (changes.name) {
      if(this.name=='aboutCreated'){
        // this.nextStep = 'Event';
        // this.nextStepName = 'Events';
        switch (true) {
          case this.fromNationApp || this.serviceType === 'None':
            this.nextStep = 'Event';
            this.nextStepName = 'Events';
            break;
        
          case this.serviceType === 'Product' || this.serviceType === 'Both':
            this.nextStep = 'Product';
            this.nextStepName = 'Products';
            break;
        
          default:
            this.nextStep = 'Service';
            this.nextStepName = 'Services';
        }
      } else {
        const mappingResult = this.stepsMapping[this.name];
        if (mappingResult) {
  
          this.nextStep = mappingResult.step;
          this.nextStepName = mappingResult.name;
        }
      }

    }
    this.appointmentType = this.appointmentType
  }

  returnNewName(name){
    if(name=='aboutCreated'){
      return this.displayName + ' Added ';
    } else {
      return this.displayName + ' Created';
    }
  }
  previewSection(){
    this.preview.emit()
    this.goToTopOfNextPage.emit()
  }

  nextStepActivated(){
    this.progressEvent.emit(this.nextStep);
    this.goToTopOfNextPage.emit()
  }
  closeOpenModal(){
    this.closeModal.emit()
  }
  keepAddingEvent(){
    this.keepAdding.emit(this.name)
    this.goToTopOfNextPage.emit()
  }
  showPage(name){
    this.showPages = true;
    this.setup.emit(this.name)
    this.goToTopOfNextPage.emit()
  }
}
