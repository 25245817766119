import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';


@Injectable({
  providedIn: 'root'
})

export class GetAppService {

  constructor(private db: AngularFirestore) { }
  //
  saveRequest(model: any) {
    model.id = this.db.createId();
    return this.db.collection('AppRequest').doc(model.id).set(model);
  }

  updateRequest(model: any) {
    return this.db.collection('AppRequest').doc(model.id).update(model);
  }

  getRequests() {
    return this.db.collection('AppRequest');
  }

  getRequestById(requestId: any){
    return this.db.collection('AppRequest', ref => ref.where('id', '==', requestId));
  }

  getRequestByUrl(requestUrl: any){
    return this.db.collection('AppRequest', ref => ref.where('url', '==', requestUrl));
  }

  getRequestByEmail(requestEmail: any){
    return this.db.collection('AppRequest', ref => ref.where('email', '==', requestEmail));
  }
  
  getRequestByUserId(userId: string){
    return this.db.collection('AppRequest', ref => ref.where('userId', '==', userId));
  }

  getPendingRequestsByEmail(requestEmail: any){
    return this.db.collection('AppRequest', ref => ref.where('email', '==', requestEmail).where('status',  '==' , 'Pending'));
  }
  getAcceptedRequestByEmail(requestEmail: any){
    return this.db.collection('AppRequest', ref => ref.where('email', '==', requestEmail).where('status', '==', 'Accepted'));
  }
  getRejectedRequestsByEmail(requestEmail: any){
    return this.db.collection('AppRequest', ref => ref.where('email', '==', requestEmail).where('status', '==', 'Rejected'));
  }
  deleteAppRequest(id: string) {
    return this.db.collection('AppRequest').doc(id).delete();
  }

}
