import { Component, OnInit } from '@angular/core';
import { ContactService } from 'src/app/services/firebase/contact.service';
import { read, utils } from 'xlsx';
import { ToastrService } from "ngx-toastr";
import { Router } from '@angular/router';
import { LoadingService } from 'src/app/services/loader/loading.service';

@Component({
  selector: 'app-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.css']
})
export class ContactListComponent implements OnInit {

  contacts: any[] = []
  email: boolean = false;
  name: boolean = false;
  tel: boolean = false;
  address: boolean = false;
  icon: boolean = false;
  results: boolean = false;
  rawResults: boolean = false;
  multiple: boolean = true;
  supported: boolean = false;
  newContacts: any[] = [];
  originalContacts: any = []
  existingContacts: any[] = [];
  contactHeaders: any = [];
  checkContact = false;

  selectedContacts: any = [];


  activePage: any = 1;
  availableTableHeader: any[] = [];

  showModal: any = false;
  selectedContact: any = null;
  otherContactFields: any = null;
  disable = false;
  numOfHeaders: number = 4;

  searchKey: any;
  selectAllcheckedContact = false;
  showConfirmationModal = false;

  tableHeaders: any[] = [];

  constructor(private contactService: ContactService, private toastr: ToastrService, private router: Router, private loaderService: LoadingService) {

  }

  ngOnInit(): void {
    this.supported = ('contacts' in navigator && 'ContactsManager' in window);

    // fetch contacts header
    this.fetchContactHeaders();

    // fetch contacts
    this.fetchContacts();

    // fetch contacts header
    this.fetchContactInformation();



    if (this.supported) {
      this.checkProperties();
    }
  }

  async checkProperties() {

    let newNavigator: any = navigator;
    const supportedProperties = await newNavigator.contacts.getProperties();

    if (supportedProperties.includes('name')) {
      this.name = true;
    }
    if (supportedProperties.includes('email')) {
      this.email = true;
    }
    if (supportedProperties.includes('tel')) {
      this.tel = true;
    }
    if (supportedProperties.includes('address')) {
      this.address = true
    }
    if (supportedProperties.includes('icon')) {
      this.icon = true
    }
  }

  async getContacts() {

    const props = [];
    if (this.name) props.push('name');
    if (this.email) props.push('email');
    if (this.tel) props.push('tel');
    if (this.address) props.push('address');
    if (this.icon) props.push('icon');


    const opts = { multiple: this.multiple };

    let newNavigator: any = navigator;

    try {
      const contacts = await newNavigator.contacts.select(props, opts);

      // iterate over the contacts list 
      if (contacts?.length > 0) {
        for (let i = 0; i < contacts?.length; i++) {
          let contact = contacts[i];
          this.contacts.push({
            name: contact?.name,
            email: contact?.email,
            tel: contact?.tel,
          });

        }
      }

      // handleResults(contacts);
    } catch (ex) {
    }

  }

  // fetch contact headers
  fetchContactHeaders() {
    this.contactService.getContactHeader().valueChanges().subscribe((response: any) => {

      let headersLength = response?.length;

      this.tableHeaders = []

      let headerFromEach = this.numOfHeaders < headersLength ? 1 : Math.round(this.numOfHeaders / headersLength);

      // iterate over the response and structure the tables header
      for (let i = 0; i < response?.length; i++) {

        let headers = response[i]?.headers;
        this.tableHeaders = [...this.tableHeaders, ...headers.slice(0, headerFromEach)];

      }


    }, (err) => {
    })
  }



  // mask phone number
  maskPhoneNumber(phone: any) {
    const format = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
    const numericString = phone.replace(/\D/g, '');

    let maskedString = '';
    let numericIndex = 0;

    format.forEach(char => {
      if (typeof char === 'string') {
        maskedString += char;
      } else {
        maskedString += numericString.charAt(numericIndex);
        numericIndex++;
      }
    });

    return maskedString;
  }

  // check if a contact contains email or phone 
  checkEmailAndPhone(contact) {
    let phone = false;
    let email = false;
    let currentStatus = null

    // use object keys
    Object.keys(contact).map((key) => {
     
       if (contact[key]?.isEmail) {
        email = true
      }
      else if (contact[key]?.isPhoneNumber) {
        phone = true
      }

    })


    // check the available contact methods - email/phone
    if (phone && email) {
      currentStatus = "both"
    }
    else if (phone) {
      currentStatus = "phone"
    }
    else if (email) {
      currentStatus = "email"
    }

    return currentStatus;


  }


  viewContactDetail(contact: any) {
    this.router.navigate(['/admin/contact-detail/', contact.id]);

  }


  // fethc contacts
  fetchContacts() {

    this.showLoader();

    this.contactService.getContacts().subscribe((querySnapshot) => {
      let contacts = [];

      querySnapshot.forEach((doc) => {
        contacts.push({ id: doc.id, ...doc.data() })
      });

      this.existingContacts = contacts
      this.originalContacts = contacts;

      this.hideLoader();



    }, (err) => {
      this.hideLoader();

    });
  }

  filterContacts() {

    this.searchKey = (this.searchKey).toLowerCase();

    this.existingContacts = this.originalContacts.filter((contact) => {
      let rowFound = false;

      Object.keys(contact).forEach((key, index) => {
        if(contact[key]?.value){
          if(((contact[key]?.value).toString())?.toLowerCase()?.includes(this.searchKey))
          {
            rowFound = true
          }
        }
      });
      return rowFound
    });
  }

  // fethc contacts
  fetchContactInformation() {
    // show loader
    this.showLoader();

    // get contact information
    this.contactService.getContactInformation().valueChanges().subscribe((contacts: any) => {
      if (contacts?.length) {
        this.contactHeaders = contacts[0]?.headers;
      }
      this.hideLoader();

    }, (err) => {
      this.hideLoader();

    });
  }

  // open modal
  openModal(selectedContact) {
    this.selectedContact = selectedContact;

    this.showModal = true;
  }

  // show Loader
  showLoader() {
    this.loaderService.show()
  }

  // hide loader
  hideLoader() {
    this.loaderService.hide();
  }

  // close modal
  closeModal() {

    this.showModal = false;
  }

  // toast message handler
  successMessage(message) {
    this.toastr.success(message, "Success");
  }

  errorMessage(message) {
    this.toastr.error(message, "Error");
  }

  // open contacts form
  openContactsForm() {
    this.router.navigate(['/admin/contact-form/']);
  }

  generatePascalCase(value) {
    if (!value) return '';

    // Convert the string to Pascal case
    const words = value.toLowerCase().split(' ');
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
    return words.join('');
  }

  getCreatedDate(date: any) {
    return new Date(date).toDateString()
  }

  handleSearchContact() {

    this.filterContacts();
  }

  selectedAllContacts() {

    this.selectAllcheckedContact = !this.selectAllcheckedContact;

    if (this.selectAllcheckedContact) {

      this.disable = false;
      this.selectedContacts = this.existingContacts;


      // this.selectedEmployees = this.allUsersByRole;
      this.checkContact = true;

    }
    else {
      this.selectedContacts = [];
      this.checkContact = false;
    }
  }

  selectedContactFromList(item) {


    if (!this.selectedContacts.includes(item)) {
      this.selectedContacts.push(item);
      if (this.selectedContacts.length === this.existingContacts?.length) {
        this.selectAllcheckedContact = true;
      }
      else {
        this.selectAllcheckedContact = false;
      }
    }
    else {
      let contact = this.selectedContacts.indexOf(item);
      this.selectedContacts.splice(contact, 1);
      this.selectAllcheckedContact = false;
    }

  }

  deleteContacts() {
    this.showLoader();

    // save it to the database
    this.contactService.deleteContactsData(this.selectedContacts).then((response) => {
      this.fetchContacts();
      this.hideLoader();

      this.showConfirmationModal = false;
      

      if(this.selectedContacts?.length === this.originalContacts?.length)
      {
        this.deleteContactHeaders();
      }

      this.selectedContacts = [];

      // hide modal
      this.showConfirmationModal = false;

    }).catch((err) => {
      this.errorMessage("Something went wrong when deleting contacts!")
      this.hideLoader();

      // hide modal
      this.showConfirmationModal = false;
    });


  }

  // if the user deletes all the contact, delete the contact headers
  deleteContactHeaders(){
     // save it to the database
     this.contactService.dropContactHeaders();

  }


}
