import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-close-modal',
  templateUrl: './close-modal.component.html',
  styleUrls: ['./close-modal.component.css']
})
export class CloseModalComponent implements OnInit {

  @Output() closeBtnClicked = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }
  closeModal(){
    this.closeBtnClicked.emit();
  }

}
