import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ProductsService } from 'src/app/services/firebase/products.service';
import { environment } from 'src/environments/environment';
// import { toast } from 'materialize-css';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

// declare var $: any;
// declare var M: any;

@Component({
  selector: 'app-manage-product-category',
  templateUrl: './manage-product-category.component.html',
  styleUrls: ['./manage-product-category.component.css']
})
export class ManageProductCategoryComponent implements OnInit {

  productCategoriesModel: any = [];
  selectedModel: any;
  productCategoryForm: UntypedFormGroup;
  themeColor = environment.appTheme.adminThemeColor;
  showMsg = false;
  currentUserId: any;
  selectedProductsUnderCategory: any;
  createModalOpen = false;
  editModalOpen = false;
  deleteModalOpen = false;
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;

  constructor(private productsService: ProductsService, private formBuilder: UntypedFormBuilder, private location: Location,
              private router: Router) { }

  ngOnInit() {
    this.productCategoryForm = this.formBuilder.group({
      name: ['', [Validators.required]],
    });

    this.productsService.getProductCategoryList().valueChanges().subscribe(a => {
      if (a) {
        if (a.length > 0){
          a.forEach((product: any) => {

          this.productsService.getProductUnderCategory(product.id).valueChanges().subscribe((puc: any) => {
            this.productCategoriesModel.push({category: product, productsUnder: puc, noOfProducts: puc.length});
          });
          });
        }
        this.showMsg = true;
      }
    });
  }

  get name() { return this.productCategoryForm.get('name'); }

  addCategory(){
    this.selectedModel = undefined;
    this.productCategoryForm.patchValue({'name': ''});
    this.productCategoriesModel = undefined;
    this.selectedProductsUnderCategory = undefined;
    this.createModalOpen = true;
  }

  selectCategory(area: any, productsUnder) {
    this.selectedProductsUnderCategory = productsUnder;
    if (area) {
      this.selectedModel = area;
      this.productCategoryForm.patchValue({'name': area.name});
      this.editModalOpen = true;
    }
  }

  manageCategory(model: any) {
     // if ! then add

    this.editModalOpen = false;
    if (!this.selectedModel) {
      const newArea = {
        id: '',
        name: model.name,
      };

      //  get by name to check if exists
      this.productsService.createProductCategory(newArea).then( saved => {
        // this.backClicked();
      });

    } else {
      // if === then update
      const newArea = {
        id: this.selectedModel.id,
        name: model.name,
      };

      this.productsService.updateProductCategory(newArea).then( saved => {
        this.toast({ html: 'Product Category Successfully Saved', classes: 'green', type: 'success' });
        // this.backClicked();
      });
    }

  }
  deleteCategory(category){
    this.productsService.deleteProductCategory(category).then(() => {
      this.toast({html: 'Category deleted.', classes: 'green', type: 'success'});
      // this.backClicked();
    });

  }
  openDeleteModal(){
    this.deleteModalOpen = true;
  }
  closeDeleteModal(){
    this.deleteModalOpen = false;
  }

  backClicked(){
    this.location.back();
  }
  deleteProductUnder(item){
    this.openDeleteModal();
    this.productsService.deleteProduct(item).then(() => {
      this.toast({html: 'Product deleted successfully', classes: 'green', type: 'success'});
    });
  }
  editProductUnder(item){
    this.closeEditModal();
    this.router.navigate(['/admin/edit-product', item.id]);
  }
  closeEditModal(){
    this.editModalOpen = false;
  }
  closeCreateModal(){
    this.createModalOpen = false;
  }
  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
    }, 2000);
  }
}
