import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductsService } from './../../../services/firebase/products.service';
import { ProductsServiceMenuService } from 'src/app/services/api/data.service';
import { PermissionService } from 'src/app/services/firebase/permission.service';
import {
  Modal,
  Collapse,
  Ripple,
  initTE,
} from "tw-elements";
import { HelperService } from 'src/app/services/helper/helper';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { selectAppSettings } from 'src/app/state/app.selectors';
@Component({
  selector: 'app-view-product-admin',
  templateUrl: './view-product.component.html',
  styleUrls: ['./view-product.component.css']
})
export class ViewProductComponent implements OnInit {

  productId: any;
  product: any;
  isMobile = false;
  currentUser: any;
  // modal
  deleteModalOpen = false;
  showCollapseElement = false
  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  productsName;
  catagoryName;
  selectedProduct:any;
  // permission
  perm: any;
  permission: any;
  isAdmin: boolean;
  currency;
  hasProductsCategory = false;
  settings: any;

  permMember:any;
  permSocial:any;
  permPES:any;
  permSpecial:any;
  permAnalytics:any;
  productDescription: any;
  textLimit = true
  appSettings$: Subscription;
  
  constructor(private route: ActivatedRoute, private location: Location,
              private productsService: ProductsService,
              private authService: AuthService,
              private router: Router,
              private appSettingService: AppSettingsService,
              private productsServiceMenuService: ProductsServiceMenuService,
              private permissionService: PermissionService,
              private helperService: HelperService,
              private store: Store) {
      this.appSettings$ = this.store.select(selectAppSettings).subscribe((settings) => {
        this.settings = settings;
        this.hasProductsCategory = this.settings.hasProductsCategory ? this.settings.hasProductsCategory : false;
      });
    if (typeof window['cordova'] !== 'undefined') {
      this.isMobile = true;
    }
  }

  ngOnInit(): void {
    initTE({  Modal, Collapse, Ripple });
    this.currentUser = this.authService.getGlobalUser();
    if (this.currentUser){

      if (this.currentUser.role === 'Admin'){
        this.isAdmin = true;
      }

      this.permissionService.getPermissionByEmployeeId(this.currentUser.uid).valueChanges().subscribe(perm => {
        if (perm && perm.length !== 0){
          this.perm = perm[0];
          //this.permission = this.perm.permission[0];
          this.permMember = this.perm.permission[0]
          this.permPES = this.perm.permission[1]
          this.permAnalytics = this.perm.permission[2];
          this.permSocial = this.perm?.permission[3];
          this.permSpecial = this.perm.permission[4];
        }
      });
    }
    this.appSettingService.getAppSettingsList().valueChanges().subscribe(menu => {
      if (menu && menu.length > 0){
        this.settings = menu[0];
        this.hasProductsCategory = this.settings.hasProductsCategory ? this.settings.hasProductsCategory : false;
      }
    });
    this.route.paramMap.subscribe(params => {
      this.productId = params.get('id');
    });

    this.productsService.getProductById(this.productId).valueChanges().subscribe(products => {
      if (products.length > 0 ){
        this.product = products[0];
        this.productDescription = this.product.productDescription 
      }
    });

    this.productsServiceMenuService.productsName.subscribe(p => {
      this.productsName = p;
    });
    this.productsServiceMenuService.productsCategoryName.subscribe(p => {
      this.catagoryName = p;
    });

    this.productsServiceMenuService.currencyName.subscribe(c => {
      this.currency = c;
    });
  }
  backClicked(){
    this.location.back();
  }
  getUrl(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);

    if (match && match[2].length === 11) {
      const youtubeUrl = 'https://www.youtube.com/embed/' + match[2];
      return youtubeUrl;
    } else {
        return url;
    }
  }

  openDeleteModal(){
    this.deleteModalOpen = true;
  }
  closeDeleteModal(){
    this.deleteModalOpen = false;
  }
  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
      if(obj.redirect){
        if (this.currentUser.role === 'Admin'){
          if (this.hasProductsCategory){
            this.router.navigate(['/admin/product-catagory']);
          } else {
            this.router.navigate(['/admin/view-products']);
  
          }
        }
        if (this.currentUser.role === 'Employee'){
          if (this.hasProductsCategory){
            this.router.navigate(['/employee/product-catagory']);
  
          } else {
            this.router.navigate(['/employee/view-products']);
  
          }
        }
      }
    }, 2000);
  }

  deleteProduct(){
    this.closeDeleteModal();
    this.productsService.deleteProduct(this.product).then(() => {
      this.toast({html: 'Product deleted successfully', classes: 'red', type: 'failure', redirect:true});
    });
  }

  navigateEdit(product){
    if (this.isAdmin){
      this.router.navigate(['/admin/edit-product', product.id]);
    }
    else if (this.currentUser.role === 'Employee'){
      this.router.navigate(['employee/edit-product', product.id]);
    }
  }
  truncateHTML(text: string, limit: string): string {
    return this.helperService.truncateHTML(text, limit);
  }
  openDeleteToast(){
    this.toast({html: 'Comment Deleted!', classes: 'red', type: 'failure', redirect: false});
  }
}
