

<!-- <div class="flex px-4 md:px-16 md:mx-auto w-full  justify-center items-center ">
    <div *ngFor="let section of sections | slice:0:1" >
        <div *ngFor="let item of section.items " [routerLink]="['/about']" class="cursor-pointer  flex  shadow-md  items-center w-full  p-6  bg-transparent  ">
            <div  *ngIf="item.imgUrl && item.imgUrl != ''" class="w-[300px]   cursor-pointer">
                <img [src]="item.imgUrl" alt="" class="w-full  md:h-96  mt-2 object-cover ">
            </div>
        
            <div class=" flex-col p-4 space-y-5 md:space-y-0 md:w-9/12 w-full break-words">
              <h2 class="pt-4 text-xl md:text-lg  font-bold">
                {{truncateHTML(getInnerText(section.sectionTitle),40) | titlecase }} 
              </h2>
              <p>
                {{item.description}}
              </p>
            </div>
        </div>
    </div>
</div> -->



<!-- <div class="flex md:flex-row flex-col px-4 md:px-16 md:mx-auto w-full  justify-center items-center  max-w-max m-10 p-10">
    <p class="text-3xl text-customTeal md:w-[380px] =">{{name}}</p>
    <div class="flex flex-col gap-y-2 w-full">
        <div *ngFor="let section of sections | slice:0:3" >
            <div *ngFor="let item of section.items " [routerLink]="['/about']" class="cursor-pointer  flex border gap-x-6  border-solid w-full md:w-[680px] border-gray-200 bg-white shadow-md  items-center p-2   ">
                <div  *ngIf="item.imgUrl && item.imgUrl != ''" class="  cursor-pointer">
                    <img [src]="item.imgUrl" alt="" class="w-24 h-16 object-cover ">
                </div>
            
                <div class="w-full break-words">
                  <h2 class="md:text-lg  font-bold">
                    {{truncateHTML(getInnerText(section.sectionTitle),40) | titlecase }} 
                  </h2>
                </div>
            </div>
        </div>
    </div>
 
</div> -->


<div class="w-full">
  <div class="flex flex-col p-6 md:py-6 md:mx-auto w-full gap-y-8 justify-center items-center bg-customTeal shadow-md">
  <p class="text-2xl font-bold  text-center text-white">{{name}}</p>
  
  <div class="flex flex-col md:flex-row gap-6 w-full">
    <div *ngFor="let section of sections | slice:0:3; index as i" class="flex md:flex-row flex-col  gap-4" >
      <div *ngFor="let item of section.items " [routerLink]="['/about']" class="cursor-pointer w-full flex md:flex-row flex-col  gap-4   ">
          <div  *ngIf="item.imgUrl && item.imgUrl != ''" class=" bg-white p-2 cursor-pointer">
              <img [src]="item.imgUrl" alt="" class="w-96 h-52 object-contain ">
          </div>
      
          <div class="w-full break-words flex flex-col gap-y-2">
            <h2 class="md:text-xl  font-bold text-white">
              {{truncateHTML(getInnerText(section.sectionTitle),40) | titlecase }} 
            </h2>
            <h2 class="md:text-base  text-white">
              {{truncateHTML(getInnerText(item.description),350) | titlecase }} 
            </h2>
            <a href="" class="flex space-x-2 items-center ">
                <span class="text-white text-sm">Learn More</span>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"  class=" stroke-white w-4 h-4">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25" />
                  </svg>
                  
            </a>
          </div>
      </div>
      <!-- <div class="md:pl-0.5 bg-white h-0.5 md:h-64 " [ngClass]="{'hidden': i==2}">

      </div> -->
  </div>
  </div>
</div>
</div>