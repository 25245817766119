import { Component, OnInit } from '@angular/core';
import { EmailService } from 'src/app/services/api/email.service';
import { MediaService } from 'src/app/services/api/media.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { DepartmentService } from 'src/app/services/firebase/department.service';
import { InviteService } from 'src/app/services/firebase/invite.service';
import { UserService } from 'src/app/services/firebase/user.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import MyCustomUploadAdapterPlugin from '../../../admin/uploadAdapter.js';
import { ImageModel } from 'src/app/models/ImageModel';
import { Location } from '@angular/common';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { HelperService } from 'src/app/services/helper/helper';
import { EmployeeScheduleModel } from 'src/app/models/ScheduleModel';
import { ScheduleService } from 'src/app/services/firebase/employee-schedule.service';
import { PushNotificationSettingsService } from 'src/app/services/firebase/push-notification-settings.service';

@Component({
  selector: 'app-employee-edit',
  templateUrl: './employee-edit.component.html',
  styleUrls: ['./employee-edit.component.css']
})
export class EmployeeEditComponent implements OnInit {

  currentUser: any;
  loggedInUser: any;
  id: any;
  employeeEditForm: UntypedFormGroup;
  department: any;
  departmentClicked: any;
  userGlobal: any;

  publicChecked: boolean = false;
  SubscribeChecked: boolean = false;
  donotShowChecked: boolean = false;
  EmployeePublicPreference = [];

  //phone
  publicPhoneChecked: boolean = false;
  SubscribePhoneChecked: boolean = false;
  donotShowPhoneChecked: boolean = false;

  //work
  publicWorkChecked: boolean = false;
  SubscribeWorkChecked: boolean = false;
  donotShowWorkChecked: boolean = false;

  //image
  mediaType: any;
  binDoc = undefined;
  base64Image = undefined;
  mobileBase64Image: any;
  isMobile = false;
  model: any;
  imgURL: any;
  imgErrorMsg: any;
  pdfErrorMsg: any;
  profileImgLink: any;

  // default notification preference
  notifSettings
  newSettings: boolean
  defaultNotifications = {
    id: '',
    userId: '',
    settings: [
      {
        "displayName": "Appointment Request",
        "name": "appointment",
        "isEnabled": true
      },
      {
        "displayName": "Direct Message",
        "name": "chat",
        "isEnabled": false
      }
    ],
  }

  //toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;

  employeeUser: any;
  employeeGlobal: any;

  updateModal: boolean = false;
  confirmationModal: boolean = false;

  public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')',
    ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public extensionMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ')',
    ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  editorConfig = {
    removePlugins: ['EasyImage', 'Image', 'ImageCaption', 'ImageStyle', 'ImageToolbar', 'ImageUpload', 'MediaEmbed'],
    extraPlugins: [MyCustomUploadAdapterPlugin]
  };

  beforeDeleteModal: boolean;
  confirmDeleteModal: boolean;
  confirmSecondModal: boolean;
  confirmThreeModal: boolean;
  deleteModal: boolean;

  role: any;
  appointmentChecked: boolean = false;
  newSchedule: any;
  existingSchedule: any;
  defaultSchedule: any;
  constructor(private fb: UntypedFormBuilder,
    private departService: DepartmentService,
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
    private mediaService: MediaService,
    private route: ActivatedRoute,
    public afAuth: AngularFireAuth,
    public scheduleService: ScheduleService,
    public helperService: HelperService,
    public notificationSettingService: PushNotificationSettingsService) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });
    this.currentUser = this.authService.getGlobalUser();
    this.role = this.currentUser.role;

    this.employeeEditForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required]],
      jobTitle: [''],
      depart: [''],
      extension: [''],
      cellPhone: [''],
      workPhone: [''],
      biography: [''],
      appointment: [false],
      imgLink: [''],
      userType: ['Employee'],
      toggleWork:[''],
      togglePhoneNumber:[''],
      toggleEmail:[''],
      showEmployee:[''],
      signOff: [''],
      messageSMS: [''],
      recieveAppointmentSMS: [false],
      recieveAppointmentEmail: [false],
    });

    this.departService.getDepartment().valueChanges().subscribe(department => {
      if (department && department.length != 0) {
        this.department = department;
      }
    });

    this.employeeGlobal = this.userService.getUserById(this.id).valueChanges().subscribe(user => {
      if (user && user.length > 0) {
        this.userGlobal = user[0];
        if (!this.userGlobal.contact || this.userGlobal.contact.length == 0) {
          this.userGlobal.contact = [{ Email: '', Phone: '', Work: '', }]
        }

        this.employeeEditForm.patchValue({
          firstName: this.userGlobal.GivenName ? this.userGlobal.GivenName : '',
          lastName: this.userGlobal.LastName ? this.userGlobal.LastName : '',
          email: this.userGlobal.Email ? this.userGlobal.Email : '',
          cellPhone: this.userGlobal.cellPhone ? this.userGlobal.cellPhone : '',
          workPhone: this.userGlobal.workPhone ? this.userGlobal.workPhone : '',
          jobTitle: this.userGlobal.jobTitle ? this.userGlobal.jobTitle : '',
          depart: this.userGlobal.department ? this.userGlobal.department : '',
          appointment: this.userGlobal.appointment ? this.userGlobal.appointment : false,
          biography: this.userGlobal.biography ? this.userGlobal.biography : '',
          extension: this.userGlobal.extension ? this.userGlobal.extension : '',
          toggleEmail: this.userGlobal.contact[0].Email,
          togglePhoneNumber: this.userGlobal.contact[0].Phone,
          toggleWork: this.userGlobal.contact[0].Work,
          signOff: this.userGlobal.signOff ? this.userGlobal.signOff : '',
          messageSMS: this.userGlobal.messageSMS ? this.userGlobal.messageSMS : '',
          imgLink: this.userGlobal.profileImgLink ? this.userGlobal.profileImgLink : '',
          showEmployee: this.userGlobal.showEmployee ? this.userGlobal.showEmployee : 'public',
          recieveAppointmentEmail: this.userGlobal.recieveAppointmentEmail ? this.userGlobal.recieveAppointmentEmail : true,
          recieveAppointmentSMS: this.userGlobal.recieveAppointmentSMS ? this.userGlobal.recieveAppointmentSMS : true,
        });

      }
      this.employeeGlobal.unsubscribe();
    });

    // fetch push notification settings
    this.fetchPushNotificationSettings();
  }

  public onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
  }

  // fetch app settings
  fetchPushNotificationSettings() {
    this.notificationSettingService.getPushNotificationSettingsList(this.id).valueChanges().subscribe(x => {
      if (x && x.length > 0) {
        this.notifSettings = x[0]
        // this.notifSettings=this.defaultNotifications

        this.newSettings = false
      } else {
        this.newSettings = true
        this.notifSettings = this.defaultNotifications
      }
    })
  }

  save() {
    this.notifSettings.userId = this.id;
    if (this.newSettings) {
      this.notificationSettingService.savePushNotificationSettings(this.notifSettings).then(x => {
        this.toast({ html: 'Successfully  Updated Push Notification!', classes: 'green', type: 'success' });
      })
    } else {
      this.notificationSettingService.updatePushNotificationSettings(this.notifSettings).then(x => {
        this.toast({ html: 'Successfully  Updated Push Notification!', classes: 'green', type: 'success' });
      })
    }
  }

  SubmitEditForm(model) {
  
    this.userGlobal.contact[0].Email = model.toggleEmail;
    this.userGlobal.contact[0].Phone = model.togglePhoneNumber;
    this.userGlobal.contact[0].Work = model.toggleWork;

    this.employeeUser = this.userService.getUserById(this.id).valueChanges().subscribe(user => {
      if (user.length > 0) {
        this.loggedInUser = user[0];
        this.loggedInUser.GivenName = model.firstName;
        this.loggedInUser.LastName = model.lastName;
        this.loggedInUser.cellPhone = model.cellPhone;
        this.loggedInUser.jobTitle = model.jobTitle;
        this.loggedInUser.workPhone = model.workPhone;
        this.loggedInUser.biography = model.biography;
        this.loggedInUser.appointment = this.appointmentChecked ? this.appointmentChecked : false;
        this.loggedInUser.department = model.depart ? model.depart : '';
        this.loggedInUser.extension = model.extension;
        this.loggedInUser.contact = this.userGlobal.contact;
        this.loggedInUser.signOff = model.signOff;
        this.loggedInUser.messageSMS = model.messageSMS;
        this.loggedInUser.showEmployee = model.showEmployee
        this.loggedInUser.recieveAppointmentEmail = model.recieveAppointmentEmail;
        this.loggedInUser.recieveAppointmentSMS = model.recieveAppointmentSMS;
        if(this.loggedInUser.appointment){

          if(this.existingSchedule){
            this.existingSchedule.employeeId = this.loggedInUser.uid;
            this.scheduleService.updateSchedule(this.existingSchedule)
          }
          else if(this.newSchedule){
            this.newSchedule.employeeId = this.loggedInUser.uid;
            this.scheduleService.createEmployeeSchedule(this.newSchedule)
          }      
          else{
            if(this.defaultSchedule){
              this.newSchedule = {schedule: [], employeeId: this.loggedInUser.uid , id: ''}
              this.newSchedule.schedule = this.defaultSchedule.value.schedules;
              this.scheduleService.getScheduleByEmployeeId(this.loggedInUser.uid).valueChanges().subscribe((schedule: any) => {
                if(schedule && schedule.length > 0){
                  this.newSchedule.id = schedule[0].id;
                  this.scheduleService.updateSchedule(this.existingSchedule)
                } else {
                  this.scheduleService.createEmployeeSchedule(this.newSchedule)
                }
              })
            }
          }
        }
        if (this.isMobile) {
          const img = new ImageModel();
          img.imgBase64 = this.mobileBase64Image;
          this.mediaService.uploadImage(img).subscribe(upload => {
            if (upload) {
              this.loggedInUser.profileImgLink = upload.imgLink;
              this.imgURL = upload.imgLink;
              this.userService.updateUser(this.loggedInUser).then(res => { });
              //this.openUpdateModal();
            }
          });
        } else {
          if (this.binDoc) {
            const reader = new FileReader();
            reader.readAsDataURL(this.binDoc);
            reader.onload = () => {
              this.base64Image = reader.result as string;
            };
            const img = new ImageModel();
            img.imgBase64 = this.base64Image.replace(/^data:image\/[a-z]+;base64,/, '');
            this.mediaService.UploadBinImage(this.binDoc).subscribe(
              upload => {
                this.loggedInUser.profileImgLink = upload.imgLink;
                this.imgURL = upload.imgLink;
                this.userService.updateUser(this.loggedInUser).then(res => { });
                //this.openUpdateModal();
              });
          }
        }
        //this.toast({ html: 'The Employee is Successfully Edited', classes: 'green', type: 'success' });
        this.userService.updateUser(this.loggedInUser).then(async res => {
          // save notification setting of the user/employee
          await this.save();
          // open update modal 
          this.openUpdateModal();
        });

      }
      this.closeConfirmationModal();
      this.employeeUser.unsubscribe();
    });
    this.openUpdateModal();
    //this.router.navigate(['/admin/employees/']);
  }
  selectDepartment(department) {
    this.departmentClicked = department;
  }

  private validateImage(name: any) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() === 'png') {
      return true;
    } else if (ext.toLowerCase() === 'jpg') {
      return true;
    } else if (ext.toLowerCase() === 'jpeg') {
      return true;
    } else {
      return false;
    }
  }
  uploadPhoto() {
    this.mediaType = 'Photo';
  }

  removeFile() {
    this.binDoc = undefined;
    this.base64Image = undefined;
    this.imgLink.patchValue('');
  }
  handleFileInput(files: FileList) {
    if (!this.validateImage(files[0].name)) {
      this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';
      //this.imgLink.patchValue('');
      return;
    }
    this.binDoc = files[0];
    this.pdfErrorMsg = undefined;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.base64Image = reader.result as string;
    };
  }
  openConfirmationModal() {
    this.confirmationModal = true;
  }
  closeConfirmationModal() {
    this.confirmationModal = false;
  }
  openUpdateModal() {
    this.updateModal = true;
    setTimeout(() => {
      this.updateModal = false;
      this.router.navigate(['/admin/employees']);

    }, 2000);
  }
  openDeleteModal() {
    this.deleteModal = true;
  }

  closeDeleteModal() {
    this.deleteModal = false;
  }
  openDeleteModalOne() {
    this.confirmDeleteModal = true;
  }
  closeDeleteModalOne() {
    this.confirmDeleteModal = false;
  }
  openSecondModalTwo() {
    this.closeDeleteModalOne();
    this.confirmSecondModal = true;
  }
  closeSecondModalTwo() {
    this.confirmSecondModal = false;
  }
  openThreeModal() {
    this.closeSecondModalTwo();
    this.confirmThreeModal = true;
  }
  closeThreeModal() {
    this.confirmThreeModal = false;
  }

  toggleAppointment(event) {
    this.appointmentChecked = event.target.checked;
  }

  beforeDelete() {
    this.closeThreeModal();
    this.beforeDeleteModal = true
    setTimeout(() => {
      this.beforeDeleteModal = false
      // setTimeout(() => {
      this.deleteUser(this.currentUser);
      //}, 1000)
    }, 2500);
  }

  deleteUser(currentUser) {
    //this.authService.deleteUser(currentUser);
    this.closeDeleteModal();
    this.afAuth.currentUser.then(user => {
      this.userService.deleteUserAccount(currentUser.uid).then(() => {
        user?.delete();
      })
      user?.delete();
      setTimeout(() => {
        this.router.navigate(['/'])
      }, 1000)
    }).catch((error) => {
      this.toast({ html: `${{ error }}`, classes: 'red', type: 'failure' });
    });

  }

  toast(obj){
    this.helperService.toast(obj);
  }

  // toast(obj) {
  //   this.toastMessage = obj.html;
  //   this.toastClass = obj.classes ? obj.classes : 'green';
  //   this.toastType = obj.type ? obj.type : 'success';
  //   this.openToast = true;
  //   setTimeout(() => {
  //     this.openToast = false;
  //     // this.backClicked();
  //   }, 2000);
  // }

  initializeSchedule(data){
    this.defaultSchedule = data;
  }

  updateSchedule(data){
    const employeeSchedule = {
        employeeId: this.currentUser.uid,
        schedule: this.helperService.validateSchedule(data.data.schedules),
        id: data.savedEmployeeSchedule.id
    };
    this.existingSchedule = employeeSchedule;
  }

  createSchedule(data){
    const employeeSchedule = new EmployeeScheduleModel();
    employeeSchedule.employeeId = this.currentUser.id;
    employeeSchedule.schedule = this.helperService.validateSchedule(data.schedules);
    this.newSchedule = employeeSchedule;
  }
  
  get firstName() { return this.employeeEditForm.get('firstName'); }
  get lastName() { return this.employeeEditForm.get('lastName'); }
  get email() { return this.employeeEditForm.get('email'); }
  get cellPhone() { return this.employeeEditForm.get('cellPhone'); }
  get workPhone() { return this.employeeEditForm.get('workPhone'); }
  get extension() { return this.employeeEditForm.get('extension') }
  get biography() { return this.employeeEditForm.get('biography'); }
  get appointment() { return this.employeeEditForm.get('appointment'); }
  get jobTitle() { return this.employeeEditForm.get('jobTitle'); }
  get depart() { return this.employeeEditForm.get('depart'); }
  get imgLink() { return this.employeeEditForm.get('imgLink'); }
  get signOff() { return this.employeeEditForm.get('signOff'); }
  get messageSMS() { return this.employeeEditForm.get('messageSMS'); }
  get toggleEmail() { return this.employeeEditForm.get('toggleEmail'); }
  get toggleWork() { return this.employeeEditForm.get('toggleWork'); }
  get togglePhoneNumber() { return this.employeeEditForm.get('togglePhoneNumber'); }
  get showEmployee() { return this.employeeEditForm.get('showEmployee'); }
  get recieveAppointmentEmail() { return this.employeeEditForm.get('recieveAppointmentEmail'); }
  get recieveAppointmentSMS() { return this.employeeEditForm.get('recieveAppointmentSMS'); }

}
