

<div class=" flex flex-col max-w-5xl md:mx-auto mx-4" *ngIf="haveAccessPermission">
    <div class="flex flex-col lg:flex-row gap-5  w-full py-3 " *ngIf="sectionDetail">
        <div class="w-full mx-auto">
            <form [formGroup]="editSectionForm"  (ngSubmit)="updateSection(editSectionForm.value)" >
                <div class="flex flex-col gap-y-4  mx-auto">
                    <!-- Section Name -->
                    <div class="form-control w-full bg-white md:px-10 px-2 py-6 space-y-2 shadow-sm ">
                        <div class="text-center font-bold text-xl">Edit Section</div>
                        <hr/>
                        <br/>
                        <div class="flex space-x-4 items-center">
                    
                            <svg width="36" height="36" viewBox="0 0 55 55" fill="blue"  class="text-gray-400 " xmlns="http://www.w3.org/2000/svg">
                              <path d="M9 12H45.6667V16.5833H9V12ZM9 21.1667H31.9167V25.75H9V21.1667Z" fill="black"/>
                              <rect x="9" y="30.3333" width="22.9167" height="4.58333" fill="black"/>
                              <path d="M44.4785 22.375C44.0226 22.375 43.5855 22.5561 43.2631 22.8784C42.9408 23.2007 42.7597 23.6379 42.7597 24.0938C42.7597 24.5496 42.9408 24.9868 43.2631 25.3091C43.5855 25.6314 44.0226 25.8125 44.4785 25.8125C44.9343 25.8125 45.3715 25.6314 45.6938 25.3091C46.0162 24.9868 46.1972 24.5496 46.1972 24.0938C46.1972 23.6379 46.0162 23.2007 45.6938 22.8784C45.3715 22.5561 44.9343 22.375 44.4785 22.375ZM44.066 27.8406C42.7024 27.9552 38.9785 30.9229 38.9785 30.9229C38.7493 31.0948 38.8181 31.0833 39.0014 31.4042C39.1847 31.7135 39.1618 31.7365 39.3795 31.5875C39.6087 31.4385 39.9868 31.1979 40.617 30.8083C43.0462 29.25 41.0066 32.8479 39.9639 38.9094C39.5514 41.9115 42.2556 40.3646 42.9545 39.9063C43.642 39.4594 45.4868 38.1875 45.6701 38.0615C45.9222 37.8896 45.7389 37.7521 45.5441 37.4656C45.4066 37.2708 45.2691 37.4083 45.2691 37.4083C44.5243 37.901 43.1608 38.9323 42.9774 38.2792C42.7597 37.626 44.1576 33.1458 44.9254 30.0635C45.0514 29.3302 45.3951 27.726 44.066 27.8406V27.8406Z" fill="black"/>
                              <rect x="9" y="38.3542" width="22.9167" height="4.58333" fill="black"/>
                              </svg>
              
                            <div class="w-10/12">
                              <p class="font-bold text-lg">Section Name <span class="text-red-500 text-sm">*</span></p>
                              <p class="text-xs text-gray-500">This is the section name that appears in the menu as well as the titles.</p>
                            </div>
                        </div>
              
                          <app-editor [control]="sectionTitle" class="bg-gray-100 " [inputType]="'title'"></app-editor>
                          <div *ngIf="(sectionTitle.dirty || sectionTitle.touched) && sectionTitle.invalid">
                            <small *ngIf="sectionTitle.errors.required" class="text-red-500 italic"> Section name is required. </small>
                          </div>
                    </div>
    
                    <!-- Fields that the section must include -->
                    <div class="form-control w-full bg-white md:px-10 px-2 py-6 space-y-2 shadow-sm ">
                        <div class="flex space-x-4 items-center">
                    
                            <svg width="36" height="36" viewBox="0 0 55 55" fill="blue"  class="text-gray-400 " xmlns="http://www.w3.org/2000/svg">
                              <path d="M9 12H45.6667V16.5833H9V12ZM9 21.1667H31.9167V25.75H9V21.1667Z" fill="black"/>
                              <rect x="9" y="30.3333" width="22.9167" height="4.58333" fill="black"/>
                              <path d="M44.4785 22.375C44.0226 22.375 43.5855 22.5561 43.2631 22.8784C42.9408 23.2007 42.7597 23.6379 42.7597 24.0938C42.7597 24.5496 42.9408 24.9868 43.2631 25.3091C43.5855 25.6314 44.0226 25.8125 44.4785 25.8125C44.9343 25.8125 45.3715 25.6314 45.6938 25.3091C46.0162 24.9868 46.1972 24.5496 46.1972 24.0938C46.1972 23.6379 46.0162 23.2007 45.6938 22.8784C45.3715 22.5561 44.9343 22.375 44.4785 22.375ZM44.066 27.8406C42.7024 27.9552 38.9785 30.9229 38.9785 30.9229C38.7493 31.0948 38.8181 31.0833 39.0014 31.4042C39.1847 31.7135 39.1618 31.7365 39.3795 31.5875C39.6087 31.4385 39.9868 31.1979 40.617 30.8083C43.0462 29.25 41.0066 32.8479 39.9639 38.9094C39.5514 41.9115 42.2556 40.3646 42.9545 39.9063C43.642 39.4594 45.4868 38.1875 45.6701 38.0615C45.9222 37.8896 45.7389 37.7521 45.5441 37.4656C45.4066 37.2708 45.2691 37.4083 45.2691 37.4083C44.5243 37.901 43.1608 38.9323 42.9774 38.2792C42.7597 37.626 44.1576 33.1458 44.9254 30.0635C45.0514 29.3302 45.3951 27.726 44.066 27.8406V27.8406Z" fill="black"/>
                              <rect x="9" y="38.3542" width="22.9167" height="4.58333" fill="black"/>
                              </svg>
              
                            <div class="w-10/12">
                              <p class="font-bold text-lg">Check options that the section must include <span class="text-red-500 text-sm">*</span></p>
                              <p class="text-xs text-gray-500">Check all the options that you want to be included while creating the section.</p>
                            </div>

                        </div>
                        <div>
                            <ng-container *ngIf="optionsList && sectionDetail">
                                <div *ngFor="let data of optionsList; let i=index">
                                    <label>
                                      <input type="checkbox" class="checkbox" 
                                      [value]="data.originalOptionName" 
                                      (change)="onCheckboxChange($event)" 
                                      [checked]="optionNames.includes(data.originalOptionName)"
                                      />
                                      {{data.originalOptionName}}
                                    </label>
                                  </div>
                                  <!-- <p class="text-red-500 italic" *ngIf="this.editSectionForm.controls['options'].errors?.['required']">
                                    Select atleast one value to be included in your section.
                                  </p> -->
                            </ng-container>
                        </div>
                        <div>

                        </div>
              
                          
                    </div>

                    <!-- choose design for the list -->
                    <!-- <div class="form-control w-full bg-white md:px-10 px-2 py-6 space-y-2 shadow-sm">
    
                        <div class="flex space-x-4 items-center">
                            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                width="40" height="40" viewBox="0 0 329.000000 299.000000"
                                preserveAspectRatio="xMidYMid meet">

                                <g transform="translate(0.000000,299.000000) scale(0.100000,-0.100000)"
                                fill="#000000" stroke="none">
                                <path d="M3166 2943 c-173 -173 -574 -916 -593 -1100 -7 -65 11 -97 70 -124
                                85 -38 126 -17 201 104 157 252 414 961 404 1114 -4 64 -22 65 -82 6z"/>
                                <path d="M163 2439 c-49 -24 -99 -77 -119 -125 -12 -27 -14 -182 -14 -866 0
                                -790 1 -835 19 -873 10 -22 34 -54 52 -72 66 -62 70 -63 479 -63 l370 0 0 -74
                                0 -74 -44 -6 c-34 -5 -52 -16 -77 -43 -28 -33 -31 -41 -26 -86 3 -35 11 -56
                                31 -75 l26 -27 500 0 499 0 30 29 c75 72 36 183 -69 201 l-40 7 0 73 0 74 383
                                3 382 3 43 28 c52 34 99 105 107 161 3 22 5 199 3 393 l-3 352 -54 -34 c-30
                                -19 -58 -40 -63 -47 -4 -7 -8 -109 -8 -225 l0 -213 -1210 0 -1210 0 0 690 c0
                                769 -2 748 65 775 30 13 187 15 1165 13 l1130 -3 27 -28 c25 -24 29 -37 33
                                -105 l5 -77 48 99 c26 54 47 103 47 108 0 20 -44 70 -84 96 l-41 27 -1170 3
                                -1170 2 -42 -21z m2227 -1706 c89 -52 50 -193 -54 -193 -60 0 -106 47 -106
                                107 1 82 88 129 160 86z"/>
                                <path d="M377 2123 c-4 -3 -7 -91 -7 -195 l0 -188 355 0 355 0 0 195 0 195
                                -348 0 c-192 0 -352 -3 -355 -7z"/>
                                <path d="M1244 2117 c-3 -8 -4 -52 -2 -98 l3 -84 553 -3 552 -2 0 100 0 100
                                -550 0 c-450 0 -552 -2 -556 -13z"/>
                                <path d="M1240 1800 l0 -60 555 0 555 0 0 60 0 60 -555 0 -555 0 0 -60z"/>
                                <path d="M2518 1673 c-67 -72 -106 -207 -89 -312 5 -33 14 -68 19 -77 7 -13
                                11 -10 22 15 14 35 60 78 123 118 92 57 127 115 110 185 -7 28 -19 38 -73 65
                                -36 18 -70 33 -76 33 -5 0 -22 -12 -36 -27z"/>
                                <path d="M374 1556 c-3 -7 -4 -35 -2 -62 l3 -49 710 0 710 0 0 60 0 60 -708 3
                                c-595 2 -708 0 -713 -12z"/>
                                <path d="M1925 1557 c-3 -7 -4 -138 -3 -292 l3 -280 213 -3 212 -2 0 295 0
                                295 -210 0 c-161 0 -212 -3 -215 -13z m335 -72 c0 -3 -27 -43 -61 -90 l-61
                                -86 -17 23 c-29 38 -101 146 -101 152 0 4 54 6 120 6 66 0 120 -2 120 -5z
                                m-200 -131 c30 -43 49 -82 46 -90 -7 -18 -96 -144 -102 -144 -2 0 -4 70 -4
                                155 0 85 2 155 5 155 2 0 27 -34 55 -76z m220 -79 c0 -85 -3 -155 -7 -155 -12
                                1 -103 141 -103 159 0 9 21 43 45 75 25 33 45 63 45 68 0 4 5 8 10 8 6 0 10
                                -58 10 -155z m-80 -122 l61 -88 -117 -3 c-64 -1 -119 -1 -121 2 -4 4 107 175
                                114 176 1 0 29 -39 63 -87z"/>
                                <path d="M374 1326 c-3 -7 -4 -35 -2 -62 l3 -49 710 0 710 0 0 60 0 60 -708 3
                                c-595 2 -708 0 -713 -12z"/>
                                <path d="M374 1096 c-3 -7 -4 -35 -2 -62 l3 -49 710 0 710 0 0 60 0 60 -708 3
                                c-595 2 -708 0 -713 -12z"/>
                                </g>
                            </svg>

                           
                            <div class="w-10/12">
                                <p class="font-bold text-lg">Choose Design <span class="text-red-500 text-sm">*</span></p>
                                <p class="text-xs text-gray-500">Choose design of the listing page.</p>
                            </div>
                        </div>   
              
                        <div class="w-min">
                            <div class="flex">
                                <div class="form-control ">
                                    <label class=" label cursor-pointer">
                                    <input type="radio" [checked]="" formControlName="sectionDesign" value="Design1"
                                        class="radio checked:bg-customTeal"/>
                                    <span class=" ml-4 font-normal text-black">Design 1</span>
                                    </label>
                                </div>
                            </div>
                            <div class="form-control">
                                <div>
                                    <label class="label cursor-pointer">
                                    <input type="radio" formControlName="sectionDesign" value="Design2"
                                        class="radio checked:bg-customTeal"/>
                                    <span class="ml-4 font-normal text-black">Design 2</span>
                                    </label>
                                </div>
                            </div>
                            <div class="form-control">
                                <div>
                                    <label class="label cursor-pointer">
                                    <input type="radio" formControlName="sectionDesign" value="Design3"
                                        class="radio checked:bg-customTeal"/>
                                    <span class=" ml-4 font-normal text-black">Design 3</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    
                    <!--Add this to the menu-->
                    <div class="form-control w-full bg-white md:px-10 px-2 py-6 space-y-2 shadow-sm ">
                        <div class="flex space-x-4 items-center">
                            <svg width="36" height="36" viewBox="0 0 55 55" fill="blue"  class="text-gray-400 " xmlns="http://www.w3.org/2000/svg">
                              <path d="M9 12H45.6667V16.5833H9V12ZM9 21.1667H31.9167V25.75H9V21.1667Z" fill="black"/>
                              <rect x="9" y="30.3333" width="22.9167" height="4.58333" fill="black"/>
                              <path d="M44.4785 22.375C44.0226 22.375 43.5855 22.5561 43.2631 22.8784C42.9408 23.2007 42.7597 23.6379 42.7597 24.0938C42.7597 24.5496 42.9408 24.9868 43.2631 25.3091C43.5855 25.6314 44.0226 25.8125 44.4785 25.8125C44.9343 25.8125 45.3715 25.6314 45.6938 25.3091C46.0162 24.9868 46.1972 24.5496 46.1972 24.0938C46.1972 23.6379 46.0162 23.2007 45.6938 22.8784C45.3715 22.5561 44.9343 22.375 44.4785 22.375ZM44.066 27.8406C42.7024 27.9552 38.9785 30.9229 38.9785 30.9229C38.7493 31.0948 38.8181 31.0833 39.0014 31.4042C39.1847 31.7135 39.1618 31.7365 39.3795 31.5875C39.6087 31.4385 39.9868 31.1979 40.617 30.8083C43.0462 29.25 41.0066 32.8479 39.9639 38.9094C39.5514 41.9115 42.2556 40.3646 42.9545 39.9063C43.642 39.4594 45.4868 38.1875 45.6701 38.0615C45.9222 37.8896 45.7389 37.7521 45.5441 37.4656C45.4066 37.2708 45.2691 37.4083 45.2691 37.4083C44.5243 37.901 43.1608 38.9323 42.9774 38.2792C42.7597 37.626 44.1576 33.1458 44.9254 30.0635C45.0514 29.3302 45.3951 27.726 44.066 27.8406V27.8406Z" fill="black"/>
                              <rect x="9" y="38.3542" width="22.9167" height="4.58333" fill="black"/>
                              </svg>
                            <div class="w-10/12">
                              <p class="font-bold text-lg">Add Section to menu <span class="text-red-500 text-sm">*</span></p>
                              <p class="text-xs text-darkGray">
                                It is not recommended to have to much sections on the menu.
                              </p>
                            </div>
                        </div>
                        <br/>

                        <!-- Add section to menu -->
                        <div class="flex space-x-4 mb-4">
                            <div class="w-full lg:w-1/2">
                                <div>
                                    <div class="font-bold">Would you like to add section to the menu?
                                    </div>
                                    <p class="hidden md:block text-xs text-darkGray">Allows the section name to appear under the sidebar menu.</p>
                                </div>
                            </div>
                            <!-- toggle -->
                            <div class="w-full lg:w-1/2 flex md:justify-end justify-center">
                                <div>
                                    <div class="form-control">
                                    <label class="label cursor-pointer">
                                        <input type="checkbox" class="toggle checked:bg-customTeal" formControlName="addToMenu"/>
                                        <span class="label-text pr-2 font-bold ">YES</span>
                                    </label>
                                    </div>
                                </div>
            
                            </div>
                        </div>

                        <hr/>
                        <!-- Add to landing page -->
                        <div class="flex space-x-4 mt-4">
                            <div class="w-full lg:w-1/2">
                                <div>
                                    <div class="font-bold">Would you like to add section to the landing page?
                                    </div>
                                    <p class="hidden md:block text-xs text-darkGray">Allows the section to appear in the landing page.</p>
                                </div>
                            </div>
                            <!-- toggle -->
                            <div class="w-full lg:w-1/2 flex md:justify-end justify-center">
                                <div>
                                    <div class="form-control">
                                    <label class="label cursor-pointer">
                                        <input type="checkbox" class="toggle checked:bg-customTeal" formControlName="enableInLandingPage" />
                                        <span class="label-text pr-2 font-bold ">YES</span>
                                    </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Submit form -->
                    <div class="flex flex-col  md:flex-row justify-end">
                        <button type="button"  (click)="editModalOpen=true" [disabled]="!editSectionForm.valid"
                            class="py-2 px-10 modal-button mr-5 gap-2 w-max  bg-customTeal rounded-lg text-white font-bold"
                            [ngClass]="{'bg-[#cccccc] text-[#666666]': !editSectionForm.valid}">Update Section</button>
                        <button type="button"  (click)="cancelModalOpen=true"  class="text-black font-bold rounded-lg py-2 px-10 w-max border-solid border bg-white border-gray-400">Cancel</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    
</div>
<div *ngIf="!haveAccessPermission">
    <app-no-access></app-no-access>
</div>


<!-- create Modal -->
<app-edit-confirmation *ngIf="editModalOpen" (cancelClicked)=closeCreateModal() (saveBtnClicked)= updateSection(editSectionForm.value) editedFromComponent='Custom Section' componentName="Section"></app-edit-confirmation>

<!-- Cancel Modal -->
<app-cancel-confirmation *ngIf="cancelModalOpen" (cancelClicked)=backClicked() (continueClicked)=closeCancelModal() canceledFromAction="Editing" canceledFromComponent="Section"></app-cancel-confirmation>

<!-- toast -->
<app-alert class="fixed top-0 flex justify-end w-full" [toastType]="toastType" [toastMessage]="toastMessage" [ngClass]="{'visible': openToast, 'invisible': !openToast}"> </app-alert>
