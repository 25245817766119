import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { MediaService } from 'src/app/services/api/media.service';

@Component({
  selector: 'app-file-upload-modal',
  templateUrl: './file-upload-modal.component.html',
  styleUrls: ['./file-upload-modal.component.css']
})
export class FileUploadModalComponent implements OnInit {

  uploadImageStatus = 'Pending';
  uploadVideoStatus = 'Pending';
  uploadPDFStatus = 'Pending';
  @Input() mediaList;
  @Output() returnSavedMediaURLs = new EventEmitter();
  imageUrl = '';
  videoUrl = '';
  pdfUrl = '';
  uploadedFileTypes = [];

  constructor(private mediaService: MediaService,public router: Router,private elementRef: ElementRef, private renderer: Renderer2) { }

  ngOnInit(): void {
    this.mediaList.forEach(element => {
      this.uploadedFileTypes.push(element.type)
    });
    let waitUntilVideoUpload = new Promise((res, rej) => {
      if (this.uploadedFileTypes.includes('Video')){
        this.mediaList.forEach(element => {
          if(element.type==='Video'){
            this.uploadVideoStatus = 'InProgress';
            this.mediaService.uploadVideo(element.file).subscribe(
              upload => {
                if(upload){
                  this.uploadVideoStatus = 'Done';
                  this.videoUrl = upload.imgLink;
                  res(this.videoUrl);
                  this.uploadVideoStatus = 'Done';   
                }
                else {
                  this.uploadVideoStatus = 'NotUploaded';
                  rej("Can't Upload Video");   
                }          
              }
            )
          } 
        });
      }else {
          rej("No Video");
        }
    });

    let waitUntilImageUpload = new Promise((res, rej) => {
      if(this.uploadedFileTypes.includes('Image')){
        this.mediaList.forEach(element => {
          if(element.type==='Image'){
            this.mediaService.uploadImage(element.file).subscribe(
              upload => {
                if(upload){
                  this.uploadImageStatus = 'Done';
                  this.imageUrl = upload.imgLink;
                  res(this.imageUrl);
                  this.uploadImageStatus = 'Done';   
                }
                else {
                  rej("Can't Upload Image");   
                  this.uploadImageStatus = 'NotUploaded';   
                }          
              }
            ) 
          }
        });
      } else {
        rej("No Image");
      }

    });


    waitUntilVideoUpload.then((videoUrl: string)=>{       // Video saved successfully
      this.videoUrl = videoUrl;
      waitUntilImageUpload.then((imageUrl: string) => {   //  check if image is uploaded as well(video and img success)
        this.imageUrl = imageUrl;
        this.returnSavedMediaURLList();
      }).catch(err => {   // video and image not successfull image is not succcessfull
        this.returnSavedMediaURLList();
      })

    }).catch(err=> {
      waitUntilImageUpload.then((imageUrl: string) => {   //  check if image is uploaded as well(video and img success)
        this.imageUrl = imageUrl;
        this.returnSavedMediaURLList();
      }).catch(err => {   // video and image not successfull image is not succcessfull
        this.returnSavedMediaURLList();
      })
    })

  }

  ngAfterViewInit() {
    this.setCustomColor();
  }
  private setCustomColor() {
    const elements = this.elementRef.nativeElement.getElementsByClassName('customColor');

    for (const element of elements) {
      this.renderer.setStyle(element, 'fill', '#2271b2');
    }
  }

  returnSavedMediaURLList(){
    this.returnSavedMediaURLs.emit({
      imageURL: this.imageUrl,
      videoUrl: this.videoUrl,
      pdfUrl: this.pdfUrl
    });
  }

}
