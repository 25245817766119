<div class="flex flex-wrap" *ngIf="notifications">
    <div class="bg-white lg:w-7/12 lg:mx-auto p-5"   *ngFor="let notif of notifications">
    <app-notification [notification]="notif"></app-notification>
    <hr/>
  </div>

</div>
<!-- <div class="lg:w-1/3 w-full mx-auto" *ngIf="!notifications" >
<div class="card bg-base-100 shadow-xl m-5">
  <i class="material-icons text-6xl text-center text-gray-300 mt-5">notifications_off</i>
  <div class="card-body items-center text-center">
    <h2 class="card-title">No new notifications</h2>
    <div class="card-actions mt-4">
      <button class="btn  bg-customTeal text-white border-none px-7" [routerLink]="['/admin/dashboard']">Okay</button>
    </div>
  </div>
</div>
</div> -->

<div class="mt-5"  *ngIf="!notifications">
    <div class="flex items-center justify-center">
        <img  loading="lazy" data-src="../../../../assets/icons/Illustrations/notification Illustration.png"
        class="block w-64 flex items-center justify-center">
    </div>
    <div class="text-customTeal text-lg text-center font-bold">{{name}} did not share any new Notifications.</div>
        <div class="text-gray-400 text-sm text-center font-bold"> Check back later!</div>
<div>