import { Component, OnInit } from '@angular/core';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.css']
})
export class TemplatesComponent implements OnInit {
  
      // toast
      toastMessage: any;
      toastClass: any;
      toastType: any;
      openToast = false;
  
  previewTemplate = false
  appSettings:any
  pageSections: any
  landingPageTemplate: any;

  constructor(
    private settingsService: AppSettingsService, 
  ) { }

  ngOnInit(): void {
    this.settingsService.getAppSettingsList().valueChanges().subscribe((m: any) => {
      if (m && m.length > 0) {
          this.appSettings = m[0];
          this.landingPageTemplate = this.appSettings.landingPageTemplate ? this.appSettings.landingPageTemplate : 'default';
      }
    });
  }
  selectTemplate(templateType){
    this.landingPageTemplate = templateType
    this.appSettings.landingPageTemplate = this.landingPageTemplate
    this.settingsService.updateAppSettings(this.appSettings).then(() => {
      this.toast({ html: 'Successfully Updated!!', classes: 'green', type: 'success'});
    });
  }
  
  toast(obj){
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green' ;
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
    }, 2000);
  }
}
