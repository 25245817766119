import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { AuthService } from 'src/app/services/firebase/auth.service';

@Component({
  selector: 'app-service-product',
  templateUrl: './service-product.component.html',
  styleUrls: ['./service-product.component.css']
})
export class ServiceProductComponent implements OnInit {

  settings;
  productsRoute;
  serviceRoute;
  constructor(private appSettingsService: AppSettingsService,
              private authService: AuthService,
              private activatedRoute: ActivatedRoute,
              private router: Router) { }

  ngOnInit(): void {

    this.appSettingsService.getAppSettingsList().valueChanges().subscribe((settings) => {
      if (settings.length > 0){
        this.settings = settings[0];
        if (this.settings.isBusinessApp){
          this.productsRoute = this.settings.hasProductsCategory ? '../product-catagory' : '../products';
          this.serviceRoute = this.settings.hasServicesCategory ? '../service-category' : '../services';

          if (this.settings.serviceType !== 'Both'){
              this.navigate();
          }
        }
      }
    });
  }

  navigate(){
    let route;
    if (this.settings.serviceType === 'Service'){
      if (this.settings.hasServicesCategory){
        route = 'service-category';
      }else{
        route = 'services';

      }
    }else{
      if (this.settings.hasProductsCategory){
        route = 'product-catagory';
      }else{
        // get list of products
        route = 'products';

      }
    }
    this.router.navigate([route])
  }
}
